import React from 'react';

import * as Colors from '../Earhart/Colors';

export default function ({
  size = '24',
  color = Colors.FIN.Lt.Emphasis.Medium,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M10 4L9 5H6v2h12V5h-3l-1-1h-4zM7 8v10c0 1.102.898 2 2 2h6c1.102 0 2-.898 2-2V8H7z"
      />
    </svg>
  );
}
