import React from 'react';

export default function ({ size = '24', color = '#363D46', style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      focusable={false}
      className="icon-tick"
      style={style}
    >
      <path
        className="fill"
        fill={color}
        fillRule="evenodd"
        d="M9.643 16L5 11.714l1.393-1.285 3.25 3L16.607 7 18 8.286z"
      />
    </svg>
  );
}
