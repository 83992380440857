export const getDaysLeftCopy = (daysRemaining: number) => {
  if (daysRemaining === 1) {
    return `${daysRemaining} day left`;
  }

  return `${daysRemaining} days left`;
};

export const getBillingCycleTypeCopy = (isPlanBilledMonthly: boolean) =>
  isPlanBilledMonthly ? 'Monthly' : 'Annually';

export const getSeatsCopy = (usedSeats: number) =>
  usedSeats === 1 ? 'seat' : 'seats';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  currency: 'USD',
});

export const formatTotalPayment = (totalPayment: number) =>
  currencyFormatter.format(totalPayment);

const dateFormatter = Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

export const formatNextChargeDate = (nextChargeDate: string) =>
  dateFormatter.format(new Date(nextChargeDate));

export const getLastFourDigitsOfCreditCard = (creditCard: string) =>
  creditCard.slice(-4);

export const getUpgradePath = (
  isEnterprisePlan: boolean,
  isProPlan: boolean,
) => {
  if (isEnterprisePlan) {
    return '/thankyou?plan=enterprise';
  }

  if (isProPlan) {
    return '/thankyou?plan=pro';
  }

  return '/thankyou?plan=starter';
};
