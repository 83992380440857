import React from 'react';

import BaseIcon from '@float/ui/deprecated/Earhart/Icons/Icon/BaseIcon';

function Sync18({ color, style }) {
  return (
    <BaseIcon width="18" height="18" viewBox="0 0 18 18" style={style}>
      <path
        fill={color}
        d="M15 9.75c.414 0 .75.336.75.75s-.336.75-.75.75H4.811l3.22 3.22c.292.293.292.767 0 1.06-.294.293-.768.293-1.061 0l-4.5-4.5-.064-.072-.005-.006.069.078c-.035-.034-.065-.071-.092-.11l-.031-.051-.023-.044c-.009-.017-.017-.036-.024-.055l-.011-.031-.02-.067-.007-.036c-.008-.043-.012-.09-.012-.136l.004.077-.004-.06V10.5v-.017c0-.02.002-.04.004-.06.002-.021.005-.04.009-.06l.007-.034c.005-.024.012-.046.019-.068l.011-.03c.007-.02.015-.039.024-.057l.023-.043c.01-.018.02-.035.032-.051l.022-.031c.042-.056.092-.106.147-.148l-.078.069c.034-.035.071-.065.11-.092l.051-.031.044-.023c.017-.009.036-.017.055-.024l.031-.011.067-.02.036-.007c.043-.008.09-.012.136-.012l-.077.004.06-.004H3zM9.97 2.47c.293-.293.767-.293 1.06 0l4.5 4.5c.025.024.048.05.069.078l.023.032.031.051.023.044c.009.017.017.036.024.055l.011.031.02.067.007.036c.008.043.012.09.012.136l-.004-.074c.002.015.003.03.003.046l.001.028v.03l-.004.043c-.002.022-.005.043-.009.064l-.007.034c-.005.024-.012.046-.019.068l-.011.03c-.007.02-.015.039-.024.057l-.023.043c-.01.018-.02.035-.032.051-.026.039-.056.076-.09.11l.068-.078c-.042.055-.092.105-.147.147l-.032.023-.051.031-.044.023c-.017.009-.036.017-.055.024l-.031.011-.067.02-.036.007c-.043.008-.09.012-.136.012l.073-.004c-.014.002-.029.003-.044.003L15 8.25H3c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h10.189l-3.22-3.22c-.266-.266-.29-.683-.072-.976z"
        transform="translate(-269 -574) translate(269 574)"
      />
    </BaseIcon>
  );
}

function Sync24({ color, style }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        className="fill"
        fill={color}
        fillRule="evenodd"
        d="M20 13a1 1 0 0 1 0 2H6.415l4.292 4.293a1 1 0 0 1-1.414 1.414l-6-6-.073-.082a1.005 1.005 0 0 1-.007-.008l.08.09a1.008 1.008 0 0 1-.282-.559A.997.997 0 0 1 3 14l.004.09A1.006 1.006 0 0 1 3 14.02V14a1.02 1.02 0 0 1 .125-.484.878.878 0 0 1 .088-.133c.05-.063.107-.12.17-.17l-.09.08a1.008 1.008 0 0 1 .559-.282A.997.997 0 0 1 4 13l-.09.004.072-.004H4zm-6.707-9.707a1 1 0 0 1 1.414 0l6 6A1.006 1.006 0 0 1 21 10l-.004-.086.003.054L21 10a1.018 1.018 0 0 1-.146.52 1.035 1.035 0 0 1-.147.187l.08-.09A1.006 1.006 0 0 1 20 11l.085-.004a1.006 1.006 0 0 1-.052.003L20 11H4a1 1 0 0 1 0-2h13.585l-4.292-4.293a1 1 0 0 1-.083-1.32z"
      />
    </svg>
  );
}

export default function ({ size = '24', color = '#FFF', style = {} }) {
  if (size == 24) return <Sync24 color={color} style={style} />;
  if (size == 18) return <Sync18 color={color} style={style} />;
  throw Error('Unsupported size');
}
