import { get, post } from './send';

export const fetchReport = (url: URL, options: { cache?: boolean } = {}) => {
  // URLs longer than 2k chars are truncated by the browser
  // see https://linear.app/float-com/issue/CS-1254/report-not-loading
  if (url.searchParams.has('query') && url.toString().length > 2000) {
    const body = url.searchParams.get('query');
    url.searchParams.delete('query');
    return post(
      `${url.pathname}?${url.searchParams.toString()}`.substring(1),
      body,
      {
        version: '',
        hostname: url.origin,
        jwt: true,
        timeout: 15000,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        ignoreEncoding: true,
        ...options,
      },
    );
  }

  return get(`${url.pathname}${url.search}`.substring(1), null, {
    version: '',
    hostname: url.origin,
    jwt: true,
    timeout: 15000,
    headers: {
      Accept: 'application/json',
    },
    ...options,
  });
};
