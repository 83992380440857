import styled from 'styled-components';

import { TimerEntryList } from '../TimerEntryList';
import { LogTimeInputAndDate } from './components/LogTimeInputAndDate';
import { LogTimeInputAndTimer } from './components/LogTimeInputAndTimer';

export const StyledLogTimeInputAndTimer = styled(LogTimeInputAndTimer)`
  margin-bottom: 0px;
`;

export const StyledTimerEntryList = styled(TimerEntryList)`
  margin-top: 8px;
  margin-bottom: 16px;
`;

export const StyledLogTimeInputAndDate = styled(LogTimeInputAndDate)``;
