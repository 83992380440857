import React from 'react';
import { t, Trans } from '@lingui/macro';

import { ToggleButton, ToggleGroup } from '@float/ui/deprecated/Toggle';

import { ViewSortType, ViewTypeFilter } from '../hooks/useViewsOptions';
import { DropdownSelect } from './DropdownSelect';
import { AccessControlProps } from './types';
import { ViewsProFeatureTag } from './ViewsProFeatureTag';

import * as styles from './ViewSelectorToolbar.css';

export function ViewSelectorToolbar(props: {
  acl: AccessControlProps;
  sortType: ViewSortType;
  onSortChange: (value: ViewSortType) => void;
  viewTypeFilter: ViewTypeFilter;
  onViewTypeFilterChange: (value: ViewTypeFilter) => void;
}) {
  function handleViewTypeFilter(value: ViewTypeFilter | undefined) {
    if (value) props.onViewTypeFilterChange(value);
  }

  const isStarterPlan = props.acl.isStarterPlan;
  const viewType = isStarterPlan ? 'personal' : props.viewTypeFilter;

  return (
    <div className={styles.toolbar}>
      <DropdownSelect<ViewSortType>
        aria-label={t`Sort order`}
        options={[
          { value: 'recent', label: t`Recent` },
          { value: 'a-z', label: t`A-Z` },
          { value: 'z-a', label: t`Z-A` },
        ]}
        value={props.sortType}
        onChange={(option) => props.onSortChange(option.value)}
      />
      <ToggleGroup<ViewTypeFilter>
        type="single"
        appearance="pill"
        size="tiny"
        color="nav"
        value={viewType}
        onChange={handleViewTypeFilter}
        disableUnselect
        disabled={isStarterPlan}
      >
        {!isStarterPlan && (
          <ToggleButton value="all">
            <Trans>All</Trans>
          </ToggleButton>
        )}
        <ToggleButton value="personal">
          <Trans>Personal</Trans>
        </ToggleButton>
        <ToggleButton value="shared" calloutId="shared-views-callout">
          <Trans>Shared</Trans>
        </ToggleButton>
        {isStarterPlan && <ViewsProFeatureTag />}
      </ToggleGroup>
    </div>
  );
}
