import { t } from '@lingui/macro';
import { createSelector } from 'reselect';

import { ReduxState, ReduxStateStrict } from '@float/common/reducers/lib/types';
import { TimeoffType } from '@float/types/timeoffType';

// OPTIMIZATION: Instead of processing the entire input
// we use a proxy to process only the accessed ids
function lazyMapValues<K extends string | number, V, R>(
  map: Record<K, V>,
  getter: (value: V) => R,
) {
  return new Proxy(map, {
    get(_, prop) {
      if (prop in map) {
        return getter(map[prop as K]);
      }

      return undefined;
    },
    has(_, prop) {
      return prop in map;
    },
    ownKeys() {
      return Object.keys(map);
    },
    getOwnPropertyDescriptor() {
      return {
        enumerable: true,
        configurable: true,
        writable: false,
      };
    },
  }) as unknown as Readonly<Record<K, R>>;
}

export const peopleNamesMapSelector = createSelector(
  [(state: ReduxStateStrict) => state.people.people],
  (map) => lazyMapValues(map, (person) => person.name),
);

export const clientNamesMapSelector = createSelector(
  [(state: ReduxStateStrict) => state.clients.clients],
  (map) => lazyMapValues(map, (client) => client.client_name),
);

export const projectNamesMapSelector = createSelector(
  [(state: ReduxStateStrict) => state.projects.projects],
  (map) => lazyMapValues(map, (project) => project.project_name),
);

export const phaseNamesMapSelector = createSelector(
  [(state: ReduxStateStrict) => state.phases.phases],
  (map) => lazyMapValues(map, (phase) => phase.phase_name),
);

export const accountNamesMapSelector = createSelector(
  [(state: ReduxStateStrict) => state.accounts.accounts],
  (map) => lazyMapValues(map, (account) => account.name),
);

export const timeoffTypeNamesMapSelector = createSelector(
  [(state: ReduxState) => state.timeoffTypes.timeoffTypes],
  (map) =>
    lazyMapValues(
      map,
      (timeoffType: TimeoffType) => timeoffType.timeoff_type_name,
    ),
);

export const statusTypeNamesMapSelector = createSelector(
  [(state: ReduxState) => state.statusTypes.statusTypes],
  (map) =>
    lazyMapValues(
      map,
      (statusType) => statusType.status_type_name || t`Custom`,
    ),
);

export const departmentNamesMapSelector = createSelector(
  [(state: ReduxStateStrict) => state.departments.departments],
  (map) => lazyMapValues(map, (department) => department.name),
);
