import React from 'react';

export const IconQuestion = ({
  size = '16',
  color = '#8290AA',
}: {
  size?: string;
  color?: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.6666 16C25.6666 21.3388 21.3387 25.6667 16 25.6667C10.6612 25.6667 6.33331 21.3388 6.33331 16C6.33331 10.6613 10.6612 6.33334 16 6.33334C21.3387 6.33334 25.6666 10.6613 25.6666 16Z"
        stroke={color}
        className="stroke"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 13.3333C13 13.3333 13.3333 10.3333 16 10.3333C18.6667 10.3333 19 12 19 13.3333C19 14.3351 18.4355 15.3369 17.3064 15.7732C16.6195 16.0386 16 16.597 16 17.3333V17.6667"
        stroke={color}
        className="stroke"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6666 21.3333C16.6666 21.7015 16.3682 22 16 22C15.6318 22 15.3333 21.7015 15.3333 21.3333C15.3333 20.9651 15.6318 20.6667 16 20.6667C16.3682 20.6667 16.6666 20.9651 16.6666 21.3333Z"
        stroke={color}
        className="stroke"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
