import { BaseEditor, Element, Transforms } from 'slate';

import { toNodesFrom } from './utils/nodes';

export function LinkifyPlugin<T extends BaseEditor>(editor: T) {
  const { isInline, insertText, insertNode } = editor;

  editor.isInline = (element: Element) => {
    return 'type' in element && element.type === 'link'
      ? true
      : isInline(element);
  };

  editor.insertText = (text) => {
    const children = toNodesFrom(text);

    if (Array.isArray(children)) {
      children.forEach((child) => {
        insertNode(child);

        if ('type' in child && child.type === 'link') {
          Transforms.move(editor, { unit: 'offset' });
        }
      });
    } else {
      insertText(children);
    }
  };

  return editor;
}
