import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get, has } from 'lodash';
import styled from 'styled-components';

import teamworkImage from '@float/web/assets/images/teamwork_logo.svg';
import {
  clearTeamworkPreload,
  fetchTeamworkCoInts,
  fetchTeamworkConfig,
  fetchTeamworkOAuthLink,
  fetchTeamworkPreload,
  retryTeamworkPeopleSync,
  updateTeamworkConfig,
} from '@float/web/integrations/actions/teamwork';

import Subsection from '../shared/Subsection';

const TeamworkLogo = styled.img`
  display: block;
  max-width: 144px;
`;

class TeamworkSubsection extends Component {
  render() {
    const { integration } = this.props;

    const ancillaryErrors = get(integration, 'coInts[0].ancillary.errors', {});
    const requiresReauth =
      has(integration, 'coInts[0]') &&
      !get(integration, 'coInts[0].ancillary.oauth2', false);
    const authenticationErrorMessage = (
      <>
        Failed to{' '}
        {get(integration, 'coInts[0].ancillary.last_action', 'connect')}.
        Teamwork Administrator permissions are required.
        <br />
        Please try again with the correct permissions.
      </>
    );

    let error;
    if (integration.preloadError) {
      error = {
        error: integration.preloadError,
        message: get(integration, 'preloadError.TEAMWORK_NOT_ADMIN')
          ? authenticationErrorMessage
          : 'There was a problem loading your data. Please retry.',
      };
    }
    if (ancillaryErrors.TEAMWORK_NOT_ADMIN) {
      error = {
        editLink: true,
        message: authenticationErrorMessage,
      };
    }

    return (
      <Subsection
        label="Teamwork"
        type="teamwork"
        logo={<TeamworkLogo src={teamworkImage} />}
        authenticationErrorMessage={authenticationErrorMessage}
        error={error}
        requiresReauth={requiresReauth}
        {...this.props}
      >
        Import and sync your{' '}
        <a
          href="https://www.teamwork.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Teamwork
        </a>{' '}
        people and projects to kick start your scheduling.{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://support.float.com/en/articles/4192949-teamwork"
        >
          Learn more
        </a>
        .
      </Subsection>
    );
  }
}

const mapStateToProps = (state) => ({
  integration: state.integrations.teamwork,
});

const mapDispatchToProps = (dispatch) => ({
  fetchOAuthLink: () => dispatch(fetchTeamworkOAuthLink()),
  fetchCoInts: () => dispatch(fetchTeamworkCoInts()),
  fetchPreload: () => dispatch(fetchTeamworkPreload()),
  retryPeopleSync: (coIntId) => dispatch(retryTeamworkPeopleSync(coIntId)),
  fetchConfig: (coIntId) => dispatch(fetchTeamworkConfig(coIntId)),
  updateConfig: (coInt, config, options) =>
    dispatch(updateTeamworkConfig(coInt, config, options)),
  clearPreload: () => dispatch(clearTeamworkPreload()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamworkSubsection);
