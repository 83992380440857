import React from 'react';
import { t } from '@lingui/macro';

import { formatDate } from '@float/common/lib/timeoff/helpers/formatDate';
import { Person } from '@float/types/person';
import { TimeoffType } from '@float/types/timeoffType';
import VirtualSelect from '@float/ui/deprecated/VirtualSelect/VirtualSelect';

import { useTimeoffTypesSelect } from './hooks/useTimeoffTypesSelect';

export type TimeoffSelectProps = {
  endDate: Date;
  onChange: (nextTimeoffType: TimeoffType) => void;
  personId?: Person['people_id'];
  timeoffTypeId: TimeoffType['timeoff_type_id'];
  timeoffTypes: TimeoffType[];
};

export const TimeoffSelect = (props: TimeoffSelectProps) => {
  const { onChange, timeoffTypeId, timeoffTypes, personId, endDate } = props;

  const { options, setTimeoffType } = useTimeoffTypesSelect({
    endDate: formatDate(endDate),
    onChange,
    personId,
    timeoffTypes,
  });

  return (
    <VirtualSelect
      // @ts-expect-error The VirtualSelect are inferred from js and height is missing
      autoFocus
      clearInputOnDropdownOpen={false}
      label={t`Time off`}
      nonNullable
      onChange={setTimeoffType}
      options={options}
      placeholder=""
      value={timeoffTypeId}
      visibleItems={6}
    />
  );
};
