export const PEOPLE_LOAD_START = 'people/LOAD_START';
export const PEOPLE_LOAD_FAILED = 'people/LOAD_FAILED';
export const PEOPLE_LOAD_FINISH = 'people/LOAD_FINISH';

export const PEOPLE_UPDATED = 'people/UPDATED';
export const PEOPLE_DELETED = 'people/DELETED';
export const PERSON_PROJECTS_UPDATE = 'people/PERSON_PROJECTS_UPDATE';
export const PEOPLE_PROJECT_ADDED = 'people/PROJECT_ADDED';
export const PEOPLE_BULK_UPDATED = 'people/BULK_UPDATED';
export const PEOPLE_BULK_DELETED = 'people/BULK_DELETED';
export const PEOPLE_IMPORTED = 'people/IMPORTED';

export const PERSON_UPDATE_AUTO_EMAIL = 'person/UPDATE_AUTO_EMAIL';
