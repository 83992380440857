import React from 'react';
import { t } from '@lingui/macro';

import { isViewApplied as isViewAppliedSelector } from '@float/common/selectors/views';
import { useAppSelector } from '@float/common/store';
import { IconPlusSmall } from '@float/ui/deprecated/Earhart/Icons';
import { ClearFiltersButton } from '@float/web/searchV2/components/ClearFiltersButton';
import { SaveView } from '@float/web/searchV2/components/SaveView';
import { FilterButton, FilterToken } from 'components/SearchFilters';

import { SearchFilterTokensProps } from '../types';

import * as styles from './styles.css';

export const ResponsiveFilterTokensContent = (
  props: SearchFilterTokensProps,
) => {
  const {
    addFilter,
    addFilterRef,
    clearFilters,
    editingFilterIndex,
    filters,
    onAddClick,
    onFilterClick,
    removeFilter,
    shouldRenderMeFilter,
    showDropdown,
    toggleMeFilter,
  } = props;
  const isViewApplied = useAppSelector(isViewAppliedSelector);

  return (
    <div className={styles.container}>
      {shouldRenderMeFilter && (
        <FilterToken
          key="me"
          filter={{ type: 'me', val: '' }}
          onRemove={toggleMeFilter}
          index={0}
        />
      )}
      {filters.map((filter, i) => (
        <FilterToken
          key={i}
          onRemove={() => removeFilter(filter)}
          onClick={onFilterClick}
          onChange={addFilter}
          filter={filter}
          prevFilter={filters[i - 1]}
          index={i}
          forceNoOrBetweenTypes={isViewApplied}
          wrapValues
        />
      ))}
      <FilterButton
        active={showDropdown && editingFilterIndex === null}
        variant="icon"
        ref={addFilterRef}
        onClick={onAddClick}
        filter={filters[filters.length - 1]}
        aria-label={t`Add filters`}
      >
        <IconPlusSmall />
      </FilterButton>
      {Boolean(filters.length) && <SaveView />}
      <ClearFiltersButton onClick={clearFilters} />
    </div>
  );
};
