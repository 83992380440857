import { getJWTAccessToken } from '@float/common/actions/jwt';
import { fetchTaskLinks } from '@float/web/pmSidebar/actions';

import api from '../api';
import { createFetchOAuthLink } from './common';
import {
  RETRY_PEOPLE_SYNC,
  RETRY_PEOPLE_SYNC_FAILURE,
  RETRY_PEOPLE_SYNC_SUCCESS,
} from './teamwork';
import {
  ADD_TRELLO_CO_INT,
  ADD_TRELLO_CO_INT_FAILURE,
  ADD_TRELLO_CO_INT_SUCCESS,
  CLEAR_TRELLO_PRELOAD,
  FETCH_TRELLO_CO_INTS,
  FETCH_TRELLO_CO_INTS_FAILURE,
  FETCH_TRELLO_CO_INTS_SUCCESS,
  FETCH_TRELLO_CONFIG,
  FETCH_TRELLO_CONFIG_FAILURE,
  FETCH_TRELLO_CONFIG_SUCCESS,
  FETCH_TRELLO_OAUTH_LINK,
  FETCH_TRELLO_OAUTH_LINK_FAILURE,
  FETCH_TRELLO_OAUTH_LINK_SUCCESS,
  FETCH_TRELLO_PRELOAD,
  FETCH_TRELLO_PRELOAD_FAILURE,
  LINK_TRELLO,
  LINK_TRELLO_FAILURE,
  LINK_TRELLO_SUCCESS,
  REMOVE_TRELLO_CO_INT,
  REMOVE_TRELLO_CO_INT_FAILURE,
  REMOVE_TRELLO_CO_INT_SUCCESS,
  UPDATE_TRELLO_CONFIG,
  UPDATE_TRELLO_CONFIG_FAILURE,
  UPDATE_TRELLO_CONFIG_SUCCESS,
} from './trello.constants';

export * from './trello.constants';

const TRELLO_TYPE = 'trello';

export const fetchTrelloOAuthLink = createFetchOAuthLink({
  types: [TRELLO_TYPE],
  fetchType: FETCH_TRELLO_OAUTH_LINK,
  successType: FETCH_TRELLO_OAUTH_LINK_SUCCESS,
  failureType: FETCH_TRELLO_OAUTH_LINK_FAILURE,
});

export const fetchTrelloCoInts = ({ getLinks = false } = {}) => {
  return async (dispatch, getState) => {
    try {
      const loadState =
        getState().integrations.trello.fetchTrelloCoIntsLoadState;
      if (loadState === 'LOADING') {
        return;
      }

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: FETCH_TRELLO_CO_INTS });
      const coInts = await api(accessToken).requestCoInts({
        type: TRELLO_TYPE,
      });

      dispatch({
        type: FETCH_TRELLO_CO_INTS_SUCCESS,
        coInts,
      });

      if (getLinks) {
        const coIntId = coInts.length && coInts[0].integrations_co_id;
        if (coIntId) {
          dispatch(fetchTaskLinks(coIntId));
        }
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: FETCH_TRELLO_CO_INTS_FAILURE });
    }
  };
};

export const fetchTrelloPreload = (coIntId, options) => {
  return async (dispatch, getState) => {
    try {
      const loadState = getState().integrations.trello.preloadLoadState;
      if (loadState === 'LOADING') {
        return;
      }

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: FETCH_TRELLO_PRELOAD });
      const { success } = await api(accessToken).requestPreload({
        type: TRELLO_TYPE,
        coIntId,
        ...options,
      });
      if (!success) {
        dispatch({ type: FETCH_TRELLO_PRELOAD_FAILURE });
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: FETCH_TRELLO_PRELOAD_FAILURE });
    }
  };
};

export const clearTrelloPreload = () => async (dispatch) =>
  dispatch({ type: CLEAR_TRELLO_PRELOAD });

export const linkTrelloAccount =
  (trelloSetupToken) => async (dispatch, getState) => {
    try {
      const loadState = getState().integrations.trello.linkTrelloLoadState;
      if (loadState === 'LOADING') {
        return;
      }

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: LINK_TRELLO });
      const { success } = await api(accessToken).linkTrelloAccount({
        clientKey: trelloSetupToken,
      });
      if (!success) {
        dispatch({ type: LINK_TRELLO_FAILURE });
      }
      dispatch({ type: LINK_TRELLO_SUCCESS });
      dispatch(fetchTrelloCoInts());
    } catch (e) {
      console.error(e);
      dispatch({ type: LINK_TRELLO_FAILURE });
    }
  };

export const fetchTrelloConfig = (coIntId) => async (dispatch, getState) => {
  try {
    const loadState = getState().integrations.trello.fetchConfigLoadState;
    if (loadState === 'LOADING') {
      return;
    }

    const accessToken = await dispatch(getJWTAccessToken());

    dispatch({ type: FETCH_TRELLO_CONFIG });
    const trelloConfig = await api(accessToken).getCoIntConfig({ coIntId });
    dispatch({
      type: FETCH_TRELLO_CONFIG_SUCCESS,
      trelloConfig,
    });
  } catch (e) {
    console.error(e);
    dispatch({ type: FETCH_TRELLO_CONFIG_FAILURE });
  }
};

export const updateTrelloConfig = (
  coInt,
  config,
  { startInitialImport, resync = false, token } = {},
) => {
  return async (dispatch, getState) => {
    try {
      const loadState = getState().integrations.trello.updateConfigLoadState;
      if (loadState === 'LOADING') {
        return;
      }

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: UPDATE_TRELLO_CONFIG, startInitialImport });
      const trelloConfig = await api(accessToken).updateCoIntConfig({
        coIntId: coInt && coInt.integrations_co_id,
        config,
        startInitialImport,
        resync,
        token,
        type: TRELLO_TYPE,
      });

      dispatch({
        type: UPDATE_TRELLO_CONFIG_SUCCESS,
        trelloConfig,
        initialImport: startInitialImport,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: UPDATE_TRELLO_CONFIG_FAILURE });
    }
  };
};

export const addTrelloCoInt = () => {
  return async (dispatch, getState) => {
    try {
      const loadState = getState().integrations.trello.addCoIntLoadState;
      if (loadState === 'LOADING') {
        return;
      }

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: ADD_TRELLO_CO_INT });
      dispatch({ type: FETCH_TRELLO_PRELOAD });

      const { success } = await api(accessToken).addCoInt({
        type: TRELLO_TYPE,
      });

      if (!success) {
        throw Error('Add trello company integration error on API request');
      }

      dispatch({
        type: ADD_TRELLO_CO_INT_SUCCESS,
      });

      dispatch(fetchTrelloCoInts());
    } catch (e) {
      console.error(e);
      dispatch({ type: ADD_TRELLO_CO_INT_FAILURE });
    }
  };
};

export const removeTrelloCoInt = (coIntId, archiveData = false) => {
  return async (dispatch, getState) => {
    try {
      const loadState = getState().integrations.trello.removeCoIntLoadState;
      if (loadState === 'LOADING') {
        return;
      }

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: REMOVE_TRELLO_CO_INT });

      const { success } = await api(accessToken).removeCoInt({
        coIntId,
        archiveData,
      });

      if (!success) {
        throw Error('Remove trello company integration error on API request');
      }

      dispatch({ type: REMOVE_TRELLO_CO_INT_SUCCESS });

      dispatch(fetchTrelloCoInts());
    } catch (e) {
      console.error(e);
      dispatch({ type: REMOVE_TRELLO_CO_INT_FAILURE });
    }
  };
};

export const retryTrelloPeopleSync = (coIntId) => {
  return async (dispatch, getState) => {
    try {
      const loadState = getState().integrations.trello.retryPeopleSyncLoadState;
      if (loadState === 'LOADING') {
        return;
      }

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: RETRY_PEOPLE_SYNC });

      const { success } = await api(accessToken).retryPeopleSync({ coIntId });

      if (!success) {
        throw Error('Retry People Sync error on API request');
      }

      dispatch({ type: RETRY_PEOPLE_SYNC_SUCCESS });

      dispatch(fetchTrelloCoInts());
    } catch (e) {
      console.error(e);
      dispatch({ type: RETRY_PEOPLE_SYNC_FAILURE });
    }
  };
};
