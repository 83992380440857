import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Icons, StyledTab, Tab } from '@float/ui/deprecated';
import { UnreadIndicator } from '@float/web/components/Notifications/NewIndicator';
import { closeNotificationFeed } from '@float/web/store/notifications/notifications.actions';

const Wrapper = styled.div`
  display: flex;
  padding: 0 18px 0 21px;
`;

const Spacer = styled.div`
  flex: 1 1 auto;
`;

const CloseIcn = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const TabsContainer = styled.div`
  ${StyledTab} {
    min-height: 50px;
    padding: 15px 18px 0;
    align-items: flex-start;
    ${(p) => p.theme.antialias}
  }
`;

const newIndicatorStyles = {
  marginLeft: 6,
  verticalAlign: 2,
};

const ActivityHeader = ({
  selectedTab,
  defaultTab,
  isOpen,
  onTabChange,
  onHide,
  closeFeed,
}) => {
  const switchTab = useCallback(
    (tab) => {
      if (typeof onTabChange === 'function') {
        onTabChange(tab);
      }
    },
    [onTabChange],
  );

  const switchToNotifications = () => {
    switchTab('notifications');
  };

  const onClose = () => {
    closeFeed();
    onHide();
  };

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => switchTab(defaultTab), 100);
    }
  }, [isOpen, switchTab, defaultTab]);

  return (
    <Wrapper>
      <TabsContainer>
        <Tab
          label={
            <span>
              Notifications
              <UnreadIndicator style={newIndicatorStyles} fadeOut={isOpen} />
            </span>
          }
          color="charcoalGrey"
          active={selectedTab === 'notifications'}
          onClick={switchToNotifications}
        />
      </TabsContainer>

      <Spacer />
      <CloseIcn onClick={onClose}>
        <Icons.CloseSmall fillColor="blueGrey" />
      </CloseIcn>
    </Wrapper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  closeFeed: () => dispatch(closeNotificationFeed()),
});

export default connect(null, mapDispatchToProps)(ActivityHeader);
