import {
  FilterMatcher,
  FiltersContext,
  FiltersEntity,
  FilterTypes,
} from '../../types';
import {
  getDeparmentsIdsByName,
  getPeopleIdsByClient,
  getPeopleIdsByManager,
  getPeopleIdsByPhase,
  getPeopleIdsByProject,
  getPeopleIdsByProjectOwner,
  getPeopleIdsByProjectStatus,
  getPeopleIdsByProjectTag,
  getPeopleIdsByTask,
  getPeopleIdsByTimeoff,
  getPeopleIdsByTimeoffStatus,
} from './lib/extractors';
import { matchPersonByType, matchString } from './lib/matchers';

export class PeopleFilterMatcher implements FilterMatcher<'people'> {
  ids: Record<string, Set<number | string | undefined> | undefined> = {};
  type: FilterTypes;
  context: FiltersContext<'people'>;
  values: string[];
  partialMatch: boolean;
  forceMatch = false;

  constructor(
    context: FiltersContext<'people'>,
    type: FilterTypes,
    values: string[],
    partialMatch = false,
  ) {
    this.context = context;
    this.type = type;
    this.values = values;
    this.partialMatch = partialMatch;
  }

  private getIdsByValue(value: string) {
    switch (this.type) {
      case 'client': {
        return getPeopleIdsByClient(this.context, value, this.partialMatch);
      }
      case 'department': {
        return getDeparmentsIdsByName(this.context, value, this.partialMatch);
      }
      case 'manager': {
        return getPeopleIdsByManager(this.context, value, this.partialMatch);
      }
      case 'phase': {
        return getPeopleIdsByPhase(this.context, value, this.partialMatch);
      }
      case 'project': {
        return getPeopleIdsByProject(this.context, value, this.partialMatch);
      }
      case 'projectStatus': {
        return getPeopleIdsByProjectStatus(this.context, value);
      }
      case 'projectTag': {
        return getPeopleIdsByProjectTag(this.context, value, this.partialMatch);
      }
      case 'projectOwner': {
        return getPeopleIdsByProjectOwner(
          this.context,
          value,
          this.partialMatch,
        );
      }
      case 'task': {
        return getPeopleIdsByTask(this.context, value, this.partialMatch);
      }
      case 'timeoff': {
        return getPeopleIdsByTimeoff(this.context, value, this.partialMatch);
      }
      case 'timeoffStatus': {
        return getPeopleIdsByTimeoffStatus(this.context, value);
      }
      case 'me':
      case 'person':
      case 'personTag':
      case 'personType':
      case 'taskStatus':
      case 'jobTitle': {
        return new Set<number>();
      }
    }

    return new Set<number>();
  }

  matches(entity: FiltersEntity<'people'>): boolean {
    for (const value of this.values) {
      if (!this.ids[value]) {
        this.ids[value] = this.getIdsByValue(value);
      }

      if (this.matchesByValue(entity, value)) {
        return true;
      }
    }

    return false;
  }

  private matchesByValue(
    person: FiltersEntity<'people'>,
    value: string,
  ): boolean {
    const ids = this.ids[value]!;

    switch (this.type) {
      case 'me':
        return person.people_id === this.context.user.people_id;
      case 'client':
      case 'manager':
      case 'phase':
      case 'projectTag':
      case 'projectStatus':
      case 'projectOwner':
      case 'task':
      case 'timeoff':
      case 'timeoffStatus': {
        return ids.has(person.people_id);
      }
      case 'project': {
        if (value === 'none') {
          return (
            this.context.search.peopleWithProjects.has(person.people_id) ===
            false
          );
        }

        return ids.has(person.people_id);
      }

      case 'department': {
        return ids.has(person.department_id);
      }
      case 'jobTitle':
        if (value === 'none') {
          return !person.job_title;
        }
        return matchString(person.job_title, value, this.partialMatch);
      case 'role':
        return matchString(
          person.role?.role_id ? `${person?.role?.role_id}` : 'none',
          value,
          this.partialMatch,
        );
      case 'person':
        return matchString(person.name, value, this.partialMatch);
      case 'personTag': {
        for (const tag of person.tags || []) {
          if (matchString(tag.name, value, this.partialMatch)) return true;
        }

        return false;
      }
      case 'personType': {
        return matchPersonByType(person, value);
      }
      case 'taskStatus': {
        const statuses =
          this.context.search.peopleTaskStatuses[person.people_id];

        return statuses?.has(value);
      }
    }

    return true;
  }
}
