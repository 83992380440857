import { createSelector } from 'reselect';

import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { getSearchFilteredPeopleIds } from '@float/common/search/selectors/people';
import { getSearchFilteredProjectsIds } from '@float/common/search/selectors/projects';
import { getPhases } from '@float/common/selectors/phases';
import { getAreDatesOverlapping } from '@float/libs/datesRepeated/getAreDatesOverlapping';

export const getTimeRangePhaseIdsByPerson = createSelector(
  [
    getPhases,
    getSearchFilteredProjectsIds,
    getSearchFilteredPeopleIds,
    (
      _: ReduxStateStrict,
      params: {
        endDate: string;
        startDate: string;
      },
    ) => params,
  ],
  (phases, projectsIds, peopleIds, params) => {
    const result = new Map<number, Set<number>>();

    for (const phase of phases) {
      if (!projectsIds.has(phase.project_id)) {
        continue;
      }

      if (
        getAreDatesOverlapping(
          params.startDate,
          phase.start_date,
          params.endDate,
          phase.end_date,
        )
      ) {
        continue;
      }

      for (const peopleId of phase.people_ids) {
        if (peopleIds.has(peopleId)) {
          const list = result.get(peopleId);

          if (list) {
            list.add(phase.phase_id);
          } else {
            result.set(peopleId, new Set([phase.phase_id]));
          }
        }
      }
    }

    return result;
  },
);
