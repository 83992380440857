import React from 'react';
import { useFormContext } from 'react-hook-form';
import { t } from '@lingui/macro';

import { getAccessibleTemplates } from '@float/common/selectors/projects';
import { useAppDispatch, useAppSelectorStrict } from '@float/common/store';
import { ProjectTemplate } from '@float/types';
import { Select } from '@float/ui/components/Select';
import { IconPlusCircle } from '@float/ui/icons/essentials/IconPlusCircle';
import { IconTemplatePlus } from '@float/ui/icons/essentials/IconTemplatePlus';
import {
  showProjectSidePanel,
  showTemplateSidePanel,
} from '@float/web/sidePanel/actions';
import type { OptionProps } from '@float/ui/components/Select';

import { ProjectFormData } from '../types';

import * as styles from './TemplateField.styles.css';

function TemplateColor(props: { color?: string }) {
  if (!props.color) return null;

  return (
    <div
      className={styles.templateColor}
      style={{ backgroundColor: props.color }}
    />
  );
}

const CREATE_TEMPLATE_VALUE = '-1';

export function TemplateField() {
  const dispatch = useAppDispatch();
  const { getValues, resetField } = useFormContext<ProjectFormData>();
  const templateId = getValues('templateId');
  const templates: ProjectTemplate[] = useAppSelectorStrict(
    getAccessibleTemplates,
  );

  const options = React.useMemo(() => {
    const result: OptionProps[] = templates.map((template) => ({
      label: template.project_name,
      value: `${template.project_template_id}`,
      icon: <TemplateColor color={`#${template.color}`} />,
    }));
    result.push(
      { separator: true },
      {
        label: t`Create new`,
        value: CREATE_TEMPLATE_VALUE,
        icon: <IconPlusCircle className={styles.createTemplateIcon} />,
      },
    );
    return result;
  }, [templates]);

  const handleChange = React.useCallback(
    (value: string) => {
      if (value === CREATE_TEMPLATE_VALUE) {
        dispatch(showTemplateSidePanel());
        return;
      }
      const projectName = getValues('project.project_name');
      resetField('project.project_name', { defaultValue: projectName });
      setTimeout(() =>
        dispatch(
          showProjectSidePanel({
            hideDelete: true,
            hideTasksTab: false,
            templateId: parseInt(value, 10),
            projectName,
          }),
        ),
      );
    },
    [dispatch, getValues, resetField],
  );

  const placeholder = t`Templates`;

  return (
    <Select
      aria-label={placeholder}
      hideCaret
      icon={IconTemplatePlus}
      value={`${templateId || ''}`}
      onChange={handleChange}
      options={options}
      optionsLabel={t`Select template`}
      placeholder={placeholder}
    />
  );
}
