import React from 'react';

export default function ({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="16"
      viewBox="0 0 13 16"
      className={className}
    >
      <path
        fill="#000"
        fillRule="nonzero"
        d="M6.5 0a1.21 1.21 0 0 0-1.219 1.2v.556C3.18 2.29 1.625 4.164 1.625 6.4v4.8l-1.247.925H.376A.797.797 0 0 0 0 12.8c0 .442.364.8.813.8h11.375a.806.806 0 0 0 .812-.8.797.797 0 0 0-.376-.675l-1.249-.925V6.4c0-2.236-1.554-4.11-3.656-4.644V1.2C7.719.538 7.173 0 6.5 0zM4.875 14.4c0 .88.731 1.6 1.625 1.6s1.625-.72 1.625-1.6h-3.25z"
      />
    </svg>
  );
}
