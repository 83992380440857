import {
  FETCH_LOCK_LOGGED_TIME_CONFIG_FAILURE,
  FETCH_LOCK_LOGGED_TIME_CONFIG_NOT_FOUND,
  FETCH_LOCK_LOGGED_TIME_CONFIG_SUCCESS,
  UPSERT_LOCK_LOGGED_TIME_CONFIG,
  UPSERT_LOCK_LOGGED_TIME_CONFIG_FAILURE,
  UPSERT_LOCK_LOGGED_TIME_CONFIG_SUCCESS,
} from '../actions';

const lockLoggedTimeConfig = (
  state = { loaded: false, isUpdating: false, updateComplete: false },
  action,
) => {
  switch (action.type) {
    case FETCH_LOCK_LOGGED_TIME_CONFIG_SUCCESS:
      return {
        loaded: true,
        ...action.payload,
      };
    case FETCH_LOCK_LOGGED_TIME_CONFIG_FAILURE:
    case FETCH_LOCK_LOGGED_TIME_CONFIG_NOT_FOUND:
      return {
        ...state,
        error: action.error,
      };
    case UPSERT_LOCK_LOGGED_TIME_CONFIG:
      return {
        ...state,
        isUpdating: true,
      };
    case UPSERT_LOCK_LOGGED_TIME_CONFIG_SUCCESS:
      return {
        isUpdating: false,
        updateComplete: true,
        ...action.payload,
      };
    case UPSERT_LOCK_LOGGED_TIME_CONFIG_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default lockLoggedTimeConfig;
