import React from 'react';
import cn from 'classnames';

import BaseIcon from './BaseIcon';

import * as styles from './styles/IconActiveTimer.css';

export const IconActiveTimer = (props) => (
  <BaseIcon {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m17 7 .75-.75M12 5.75V4m0 0H9.75M12 4h2.25m5 9.25a7.25 7.25 0 1 1-14.5 0 7.25 7.25 0 0 1 14.5 0Z"
    />
    <path
      stroke="#000"
      className={cn(styles.pathSeconds)}
      strokeLinecap="round"
      d="M12 13V8"
    />
  </BaseIcon>
);
