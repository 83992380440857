import { connect } from 'react-redux';
import { modalManagerConnect } from 'modalManager/modalManagerHoc';

import withUserCanSee from '../../../components/decorators/withUserCanSee';
import { deleteAccount } from '../../actions/accounts';
import Guests from '../../components/Guests';
import {
  filterAcceptedGuests,
  filterInvitedGuests,
} from '../../reducers/accounts';
import { getEntityById } from '../../reducers/reducerHelpers';

const mapStateToProps = (state) => {
  return {
    guestsCount: filterAcceptedGuests(state.settingsAccounts.entities).length,
    guestInvitesCount: filterInvitedGuests(state.settingsAccounts.entities)
      .length,
    getAccountById: (id) =>
      getEntityById(id, state.settingsAccounts.entities, 'account_id'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClickConfirmDelete: (id) => dispatch(deleteAccount(id)),
});

const StatefulGuests = connect(
  mapStateToProps,
  mapDispatchToProps,
)(modalManagerConnect(withUserCanSee(Guests)));

export default StatefulGuests;
