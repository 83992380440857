import React from 'react';
import { FieldPath } from 'react-hook-form';
import { t } from '@lingui/macro';

import { InputField } from '../components/InputField';
import { ProjectFormData } from '../types';

export function MilestoneNameField(props: {
  autoFocus: boolean;
  name: FieldPath<ProjectFormData>;
}) {
  return (
    <InputField
      name={props.name}
      label={t`Milestone name`}
      hideLabel
      placeholder={t`Add a milestone name`}
      autoFocus={props.autoFocus}
      padding="emptySides"
    />
  );
}
