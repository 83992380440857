import styled from 'styled-components';

import { StyledButton } from '@float/ui/deprecated/Button/styles';
import { StyledTextButton } from '@float/ui/deprecated/Earhart/Buttons/TextButton/styles';
import {
  Core,
  FIN,
  Primary,
  Secondary,
} from '@float/ui/deprecated/Earhart/Colors';
import { Tag } from '@float/ui/deprecated/Earhart/Tags/Tag';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

export const StyledTeamAccountFeaturesWrapper = styled.section`
  display: flex;
  gap: 22px;
  margin-top: 24px;
`;

export const StyledPlanWrapper = styled.div`
  border-radius: 16px;
  border: 1px solid #d6deeb;
  display: flex;
  flex-direction: column;
  min-width: 282px;
  padding: 24px 24px 78px;
  position: relative;
  width: 33.33%;

  &[aria-current='true'] {
    border: none;
    box-shadow:
      0px 14.5px 22.5px rgba(9, 30, 66, 0.15),
      0px 0px 0.8px rgba(95, 106, 126, 0.15);
  }

  img {
    align-self: start;
  }

  header {
    display: flex;
    align-items: center;
  }

  ${StyledButton} {
    margin: 8px 0 8px;
    width: 100%;
  }

  ${StyledTextButton} {
    align-self: center;
    width: auto;
    margin: 8px auto;
  }
`;

export const StyledHeader = styled.header`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 4px;
  margin-top: 8px;
`;

export const StyledCurrentTag = styled(Tag)`
  margin-left: 8px;
`;

export const StyledDaysRemaining = styled.span`
  ${Typography.TextXS.R400};

  margin-left: 8px;

  color: ${Core.Text.Secondary};
`;

export const StyledFeatureListSection = styled.section`
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  padding: 16px;

  ${StyledTextButton} {
    margin-bottom: 0;
  }
`;

export const StyledFeatureList = styled.ul`
  li > svg {
    margin-right: 8px;
    // To compensate for the whitespace around the icon
    margin-left: -2px;
    flex-shrink: 0;
  }

  li {
    align-items: flex-start;
    display: flex;
    margin-bottom: 8px;
  }

  li span {
    ${Typography.TextXS.R400};
    color: ${FIN.Lt.Emphasis.Medium};
  }
`;

export const StyledFeatureListHeader = styled.header`
  ${Typography.Label12.SB600};
  color: ${FIN.Lt.Emphasis.Medium};
  margin-bottom: 16px;
`;

export const StyledStarterPlanWrapper = styled(StyledPlanWrapper)`
  &[aria-current='true'] {
    background: ${Secondary.Orange.Light[2]};
  }

  ${StyledCurrentTag} {
    background: ${Secondary.Orange.Light[4]};
    color: ${Secondary.Orange.Light[11]};
  }

  ${StyledFeatureListSection} {
    background: ${Secondary.Orange.Light[2]};
  }

  &[aria-current='true'] ${StyledFeatureListSection} {
    background: ${Secondary.Orange.Light[4]};

    margin-top: 8px;
  }
`;

export const StyledProPlanWrapper = styled(StyledPlanWrapper)`
  &[aria-current='true'] {
    background: ${Primary.Flue.Light[2]};
  }

  ${StyledCurrentTag} {
    background: ${Primary.Flue.Light[4]};
    color: ${Primary.Flue.Light[11]};
  }

  ${StyledFeatureListSection} {
    background: ${Primary.Flue.Light[2]};
  }

  &[aria-current='true'] ${StyledFeatureListSection} {
    background: ${Primary.Flue.Light[4]};

    margin-top: 8px;
  }
`;
export const StyledEnterprisePlanWrapper = styled(StyledPlanWrapper)`
  &[aria-current='true'] {
    background: ${Secondary.Teal.Light[2]};
  }

  ${StyledCurrentTag} {
    background: ${Secondary.Teal.Light[4]};
    color: ${Secondary.Teal.Light[11]};
  }

  ${StyledFeatureListSection} {
    background: ${Secondary.Teal.Light[2]};
  }

  &[aria-current='true'] ${StyledFeatureListSection} {
    background: ${Secondary.Teal.Light[4]};

    margin-top: 8px;
  }
`;

export const StyledLinkButton = styled(StyledButton)`
  align-self: center;
  margin: 0 auto 4px;
  padding: 2px 4px;
  width: auto;
`;

export const StyledPlanTitle = styled.h2`
  ${Typography.DisplayXS.SB600};

  align-items: center;
  display: flex;
  color: ${FIN.Lt.Emphasis.High};
`;

export const StyledPlanSubTitle = styled.h3`
  ${Typography.TextM.R400};

  color: ${FIN.Lt.Emphasis.Medium};
`;
