import React, { useEffect, useRef } from 'react';
import Flip from 'gsap/Flip';

import { StyledOverlay } from './styles';

export const StepOverlay = (props) => {
  const { className, anchor } = props;

  const { rect } = anchor || {};

  const ref = useRef();

  useEffect(() => {
    const delay = 0.25;

    if (rect) {
      ref.current.classList.add('with-overlay');

      // animate from left to right on first highlight
      if (!ref.current.style.top) {
        ref.current.style.top = `${rect.top}px`;
        ref.current.style.left = `-${rect.width}px`;
        ref.current.style.width = `${rect.width}px`;
        ref.current.style.height = `${rect.height}px`;
      }

      // FLIP transition from current to next step
      const state = Flip.getState(ref.current);

      ref.current.style.top = `${rect.top}px`;
      ref.current.style.left = `${rect.left}px`;
      ref.current.style.width = `${rect.width}px`;
      ref.current.style.height = `${rect.height}px`;

      Flip.from(state, { duration: 0.5, delay, ease: 'power3.inOut' });
    } else if (ref.current.style.top) {
      ref.current.style = null;
      ref.current.classList.remove('with-overlay');
    }
  }, [rect]);

  return <StyledOverlay ref={ref} className={className} />;
};
