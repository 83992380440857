import { get } from 'lodash';

import { FloatAppPlatform } from '@float/constants/app';
import { config } from '@float/libs/config';
import { AccountType, CurrentUser, Person, Project } from '@float/types';

import { userCanViewOthers } from '../../../lib/rights';
import { PROJECT_RELATED_TYPES } from '../../helpers';
import { Candidate } from './types';

export function getPersonStatuses(person: Person) {
  if (person.people_type_id === 3) return ['Placeholder'];

  const statuses = [];
  statuses.push(person.employee_type === 1 ? 'Full-time' : 'Part-time');

  if (person.people_type_id === 1) statuses.push('Employee');
  if (person.people_type_id === 2) statuses.push('Contractor');

  return statuses;
}

export function isCandidateVisibleForUser(
  candidate: Candidate,
  user: CurrentUser,
  {
    people,
    projects,
    peopleTasks,
    userPrefs,
  }: {
    people: { people: Record<number, Person> };
    projects: { projects: Record<number, Project> };
    peopleTasks: Record<number, Set<string>>;
    userPrefs: { mobile_suv?: boolean };
  },
) {
  if (!user) return false;

  const userPeopleId = user.people_id;

  if (config.platform === FloatAppPlatform.Mobile && userPrefs.mobile_suv) {
    if (candidate.type === 'projectOwner') {
      return candidate.accountIds.some((id) => id === user.account_id);
    }
    if (candidate.type === 'person') {
      return userPeopleId
        ? candidate.ids.some((id) => id === userPeopleId)
        : false;
    }
    const person = userPeopleId ? people.people[userPeopleId] : null;

    if (candidate.type === 'department') {
      return candidate.id == person?.department_id;
    }

    if (candidate.type === 'personTag') {
      const tags = person?.tags ?? [];
      return tags.some((tag) => tag.name === candidate.val);
    }

    if (candidate.type === 'jobTitle') {
      return person?.job_title === candidate.val;
    }

    if (candidate.type === 'personType') {
      const statuses = person ? getPersonStatuses(person) : [];
      return statuses.some((status) => status === candidate.val);
    }
  }

  // Account owners, admins, billing view everything
  if (
    [AccountType.AccountOwner, AccountType.Admin, AccountType.Billing].includes(
      user.account_type_id,
    )
  )
    return true;

  // If you have view rights and aren't restricted to a dept, view everything
  const canViewOthers = userCanViewOthers(user);
  if (canViewOthers && !user.department_filter?.length) return true;

  // If you don't have view rights, don't show non-project related things
  if (!canViewOthers && !PROJECT_RELATED_TYPES.includes(candidate.type)) {
    return candidate.type === 'savedSearch';
  }

  // If you're a member, project manager or department manager with a department filter,
  // you see most things except for people in other departments and the other depts.
  if (user.department_filter_all?.length) {
    if (candidate.type === 'department') {
      return user.department_filter_all.includes(candidate.id);
    }

    if (candidate.type === 'person') {
      return candidate.ids.some((id) => {
        const deptId = get(people, `people.${id}.department_id`);
        return deptId && user.department_filter_all?.includes(deptId);
      });
    }

    return true;
  }

  // Everything that follows is a member with no view rights and no dept filter,
  // which means that they only see things that are explicitly relevant to them.
  if (candidate.type === 'project') {
    if (!candidate.allPeopleIds || !userPeopleId) return false;
    return candidate.allPeopleIds.includes(userPeopleId);
  }

  if (candidate.type === 'projectTag') {
    if (!userPeopleId) return false;

    return candidate.projectIds.some((projectId) =>
      projects.projects[projectId].people_ids.includes(userPeopleId),
    );
  }

  if (candidate.type === 'phase') {
    if (!candidate.allPeopleIds || !userPeopleId) return false;
    return candidate.allPeopleIds.includes(userPeopleId);
  }

  if (candidate.type === 'client') {
    return !!userPeopleId && candidate.peopleIds.includes(userPeopleId);
  }

  if (
    candidate.type === 'taskStatus' ||
    candidate.type === 'projectStatus' ||
    candidate.type === 'timeoff' ||
    candidate.type === 'timeoffStatus' ||
    candidate.type === 'projectOwner'
  ) {
    // We don't have an easy way to filter for these attributes so we'll show
    // them on the dropdown even though it might result in zero matches.
    return true;
  }

  if (candidate.type === 'task') {
    if (!userPeopleId) return false;

    const personTasks = peopleTasks[userPeopleId];
    return personTasks?.has(candidate.val);
  }

  throw Error(
    `Unhandled mismatch between PROJECT_RELATED_TYPES and ${candidate.type}`,
  );
}
