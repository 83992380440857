import { CompanyPreferences } from '@float/types/companyPreferences';

export const hasReachedPeopleSeatsLimit = (
  companyPrefs: CompanyPreferences | undefined,
  peopleToAdd: number = 0,
) => {
  if (!companyPrefs) return true;

  const { p_limit, people_count } = companyPrefs;

  const peopleLimit = p_limit > 0 ? p_limit : Infinity;
  const peopleCount = people_count ?? 0;

  if (peopleToAdd === 0) {
    return peopleCount >= peopleLimit;
  }

  return peopleCount + peopleToAdd > peopleLimit;
};
