import React, { Component } from 'react';

import WorkDays from '../../../components/elements/WorkDays';

class WorkDaysField extends Component {
  render() {
    return (
      <WorkDays
        key={this.props.sourceWorkDayHoursResetCount}
        firstDay={this.props.firstDay}
        value={this.props.workDaysHours}
        onChange={this.props.setWorkDaysHours}
        hoursStep={this.props.hoursStep}
      />
    );
  }
}

export default WorkDaysField;
