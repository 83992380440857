import { stringCompare } from '@float/common/lib/sort';
import { Department } from '@float/types/department';

import { SearchAutocompleteQueryItem } from '.';
import { normalize } from '../../helpers';

type DepartmentFilter = Extract<
  SearchAutocompleteQueryItem,
  { type: 'department' }
>;
type DepartmentFilters = DepartmentFilter[];

export const getOrderedDepartments = (
  departments: DepartmentFilters,
): DepartmentFilters => {
  const rootDepartments = [];
  const childrenMap = new Map<number, DepartmentFilters>();

  for (const department of departments) {
    if (department.parent_id) {
      const list = childrenMap.get(department.parent_id);

      if (!list) childrenMap.set(department.parent_id, [department]);
      else list.push(department);
    } else {
      rootDepartments.push(department);
    }
  }

  const result: DepartmentFilters = [];

  addChildrenDepartments(result, rootDepartments, childrenMap);

  return result;
};

function addChildrenDepartments(
  result: DepartmentFilters,
  departments: DepartmentFilters,
  childrenMap: Map<number, DepartmentFilters>,
) {
  // Applying the sort on smaller groups of data is faster then sorting the
  // entire departments list at once
  departments.sort((a, b) =>
    stringCompare(a.val.toUpperCase(), b.val.toUpperCase()),
  );

  for (const department of departments) {
    result.push(department);

    const children = childrenMap.get(department.id);

    children && addChildrenDepartments(result, children, childrenMap);
  }
}

function isDepartment(
  candidate: SearchAutocompleteQueryItem,
): candidate is DepartmentFilter {
  return candidate.type === 'department';
}

export const addParentDepartmentToCandidates = (
  departments: Record<number, Department>,
  filtered: SearchAutocompleteQueryItem[],
) => {
  const filteredById = new Set<number>();

  for (const candidate of filtered) {
    if (isDepartment(candidate)) {
      filteredById.add(candidate.id);
    }
  }

  for (const candidate of filtered) {
    if (isDepartment(candidate) && candidate.parent_id !== null) {
      const parentDept = departments[candidate.parent_id];

      // Only add parent deparment if not in candidates list,
      // and not already added by another sub-department
      if (parentDept && !filteredById.has(parentDept.id)) {
        filteredById.add(parentDept.id);
        filtered.push({
          type: 'department',
          id: parentDept.id,
          val: parentDept.name,
          normalizedVal: normalize(parentDept.name),
          parent_id: null, // we support 1 level of sub-departments
        });
      }
    }
  }

  return filtered;
};
