import React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import cn from 'classnames';

import * as styles from './styles.css';

export type ModalTitleProps = DialogPrimitive.DialogTitleProps;

export const ModalTitle = (props: ModalTitleProps) => {
  const { children, className } = props;

  return (
    <DialogPrimitive.Title className={cn(styles.title, className)}>
      {children}
    </DialogPrimitive.Title>
  );
};
