import React from 'react';

import { StyledAnchor, StyledButton, StyledLink } from './styles';

const SettingsNavButton = (props) => {
  const { icon, children, active, ...other } = props;

  let StyledTag = StyledButton;

  if (other.to) {
    StyledTag = StyledLink;
  } else if (other.href) {
    StyledTag = StyledAnchor;
  }

  return (
    <StyledTag {...other} data-active={active}>
      {icon && React.createElement(icon)}
      <span>{children}</span>
    </StyledTag>
  );
};

SettingsNavButton._styles = { StyledButton };

export default SettingsNavButton;
