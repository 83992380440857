import styled from 'styled-components';

import { ModalTopGreySection } from '@float/ui/deprecated';

export const StyledModalInput = styled(ModalTopGreySection)`
  display: flex;

  input {
    height: 40px;
  }
`;
