import { createSelectorCreator, weakMapMemoize } from 'reselect';

export function isShallowEqualSet(a: Set<unknown>, b: Set<unknown>) {
  if (a.size !== b.size) return false;

  for (const value of a) {
    if (!b.has(value)) {
      return false;
    }
  }

  return true;
}

// A normal selector with an extra shallow check on Set results
// Useful to keep referential identity on selectors that return Sets with primitive values
// As any "memoization" check should be used with care
export const createSelectorWithShallowEqualSetResultCheck =
  createSelectorCreator(weakMapMemoize, {
    resultEqualityCheck: (a: unknown, b: unknown) => {
      if (a instanceof Set && b instanceof Set) {
        return isShallowEqualSet(a, b);
      }

      return false;
    },
  });
