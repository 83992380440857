import { PersonCell } from '@float/types';

import { CellFns, CellOpts, DateUtils } from '../types';

export function sortPersonCellPerTypeAndPriority(
  cell: PersonCell,
  opts: CellOpts,
  getPriority: CellFns['getPriority'],
  collator: Intl.Collator,
  dates: DateUtils,
) {
  const { rowId } = cell;

  cell.items.sort((aWrapper, bWrapper) => {
    // Put full-day items at the end
    if (!aWrapper.h && bWrapper.h) return 1;
    if (aWrapper.h && !bWrapper.h) return -1;
    if (!aWrapper.h && !bWrapper.h) return 0;

    const a: any = aWrapper.entity;
    const b: any = bWrapper.entity;

    // Put tentative timeoffs at the end
    if (a.timeoff_id && a.status === 1 && b.status !== 1) {
      return 1;
    }
    if (a.status !== 1 && b.timeoff_id && b.status === 1) {
      return -1;
    }

    // Sort tasks/timeoffs with start time below ones that don't
    if (a.start_time && !b.start_time) {
      return 1;
    }
    if (!a.start_time && b.start_time) {
      return -1;
    }
    if (a.start_time && b.start_time) {
      return a.start_time.localeCompare(b.start_time);
    }

    // If one of the items has a lower value for priority, move it up
    const aPriority = getPriority(rowId, a);
    const bPriority = getPriority(rowId, b);
    if (aPriority !== bPriority) return aPriority - bPriority;

    // Tasks get sorted above timeoffs
    if (a.task_id && b.timeoff_id) return -1;
    if (b.task_id && a.timeoff_id) return 1;

    let { start_date: aStart, end_date: aEnd } = a;
    let { start_date: bStart, end_date: bEnd } = b;

    if (opts && opts.useSplitSort) {
      if (a.preSplitStart) {
        aStart = a.preSplitStart;
        aEnd = a.preSplitEnd;
      }
      if (b.preSplitStart) {
        bStart = b.preSplitStart;
        bEnd = b.preSplitEnd;
      }
    }

    // Entities that start on an earlier date get sorted higher
    if (aStart < bStart) return -1;
    if (aStart > bStart) return 1;

    // Longer entities get sorted higher
    const aLength = dates.diffDays(aStart, aEnd);
    const bLength = dates.diffDays(bStart, bEnd);
    if (aLength > bLength) return -1;
    if (aLength < bLength) return 1;

    // Default to creation date if everything else is the same
    return collator.compare(a.created, b.created);
  });
}
