import React from 'react';

const refreshPage = () => {
  window.location.reload();
};

const Refresh = ({ children, className = '' }) => (
  <span className={`refresh ${className}`} onClick={refreshPage}>
    {children}
  </span>
);

export default Refresh;
