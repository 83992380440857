import { createSelector } from 'reselect';

import {
  getUser,
  selectDatesManager,
} from '@float/common/selectors/currentUser';
import { getHolidaysRawList } from '@float/common/selectors/holidays';
import { getOneOffDays } from '@float/common/selectors/oneOffs';
import { getFullTimeoffsList } from '@float/common/selectors/timeoffs';
import { forEachEntityDate } from '@float/libs/datesRepeated/forEachEntityDate';
import { Person } from '@float/types/person';

import { getWorkHours } from './getWorkHours';

/**
 * OPTIMIZATION
 *
 * Index the full day timeoffs by date and pepole, this way the isWorkDay check
 * don't need to iterate all the timeoff at each invocation.
 */
const selectFullDayTimeoffTwoDimensionsIndex = createSelector(
  [selectDatesManager, getFullTimeoffsList],
  (dates, timeoffs) => {
    type DateNum = number;
    type PersonId = number;
    type TimeoffId = number;

    const timeoffsByDateMap = new Map<DateNum, Map<PersonId, TimeoffId[]>>();

    for (const timeoff of timeoffs) {
      const isFullDayOff = timeoff.full_day || timeoff.region_holiday_id;

      if (!isFullDayOff) continue;

      forEachEntityDate(dates, timeoff, (dateNum) => {
        let timeoffsByPeopleIdMap = timeoffsByDateMap.get(dateNum);

        if (!timeoffsByPeopleIdMap) {
          timeoffsByPeopleIdMap = new Map();
          timeoffsByDateMap.set(dateNum, timeoffsByPeopleIdMap);
        }

        for (const personId of timeoff.people_ids) {
          const timeoffIds = timeoffsByPeopleIdMap.get(personId);

          if (!timeoffIds) {
            timeoffsByPeopleIdMap.set(personId, [timeoff.timeoff_id]);
          } else {
            timeoffIds.push(timeoff.timeoff_id);
          }
        }
      });
    }

    return timeoffsByDateMap;
  },
);

export const selectIsWorkDayGetter = createSelector(
  [
    getUser,
    selectDatesManager,
    getOneOffDays,
    getHolidaysRawList,
    selectFullDayTimeoffTwoDimensionsIndex,
  ],
  (user, dates, oneoffs, holidays, fullDayTimeoffDateIndex) =>
    (person: Person, date: string, excludeTimeoffId?: number) => {
      // Check if the person starts working after the date
      if (person.start_date && date < person.start_date) return false;

      // Check if the person ends working before the date
      if (person.end_date && date > person.end_date) return false;

      // If the day is a one-off, even if it's an holiday/weekend it counts as working day
      const isOneOffDay = oneoffs.some(
        ({ people_id, date: offDayDate }) =>
          people_id === person.people_id && date === offDayDate,
      );

      if (isOneOffDay) {
        return true;
      }

      // Check if it is a non-working day
      const hours = getWorkHours(dates, user, person, date);

      if (hours === 0) return false;

      // Check if it is a company holiday
      const isHoliday = holidays.some(
        ({ date: holidayDate }) => date === holidayDate,
      );

      if (isHoliday) return false;

      const dateNum = dates.toNum(date);

      const timeoffsByPerson = fullDayTimeoffDateIndex.get(dateNum);

      // Check if it is a full day timeoff
      for (const timeoffId of timeoffsByPerson?.get(person.people_id) ?? []) {
        if (excludeTimeoffId && timeoffId === excludeTimeoffId) continue;

        return false;
      }

      return true;
    },
);
