import { LastAllocatedTaskHistoryEntry } from '../reducers/lastAllocatedTask/types';

export const LOAD_HISTORY = 'lastAllocatedTask/LOAD_HISTORY' as const;

export const loadHistoryAction = (
  history: LastAllocatedTaskHistoryEntry[],
) => ({
  type: LOAD_HISTORY,
  payload: history,
});

export type LastAllocatedTaskAction = ReturnType<typeof loadHistoryAction>;
