import React from 'react';
import { useWatch } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';

import { BudgetPriority, BudgetType } from '@float/types/project';
import IconSquareCheck from '@float/ui/deprecated/Icons/icon-square-check';

import { SidePanelAddRowButton } from '../../components/SidePanelTableSection/SidePanelAddRowButton';
import { SidePanelEmptyTable } from '../../components/SidePanelTableSection/SidePanelEmptyTable';
import { SidePanelTableRows } from '../../components/SidePanelTableSection/SidePanelTableRows';
import { StatusIndicator } from '../../components/StatusIndicator';
import { createEmptyTaskRecord } from '../../helpers/createRowEntities';
import { useProjectACL } from '../../hooks/useProjectACL';
import { useProjectOrPhaseFieldValue } from '../../hooks/useProjectOrPhaseFieldValue';
import { ProjectFormData } from '../../types';
import { ProjectTaskRow } from './ProjectTaskRow';
import { ProjectTasksTableHeader } from './ProjectTasksTableHeader';

import {
  emptyRowPadding,
  sidePanelTableWrapper,
} from '../../components/SidePanelTableSection/styles.css';

export const ProjectTasksTable = () => {
  const acl = useProjectACL();

  const isActive = useProjectOrPhaseFieldValue('active');
  const projectTasks = useWatch<ProjectFormData, 'tasks'>({
    name: 'tasks',
  });

  const budgetType = useWatch<ProjectFormData, 'project.budget_type'>({
    name: 'project.budget_type',
  });

  const budgetPriority = useWatch<ProjectFormData, 'project.budget_priority'>({
    name: 'project.budget_priority',
  });

  const isBudgetPerTask = budgetPriority === BudgetPriority.Task;

  const isFeeBudget = budgetType === BudgetType.TotalFee;
  const isHoursBudget = budgetType === BudgetType.TotalHours;

  const hasBudgetAccess = isHoursBudget || (acl.canSeeBudget && isFeeBudget);

  const showBudget = isBudgetPerTask && hasBudgetAccess;

  const hasNoTasks = !projectTasks.length;
  const lastTask = projectTasks[projectTasks.length - 1];
  const addButtonDisabled =
    !isActive ||
    (!hasNoTasks && !lastTask?.task_name && !lastTask?.unnamedTask);

  return (
    <div className={sidePanelTableWrapper}>
      {hasNoTasks ? (
        <SidePanelEmptyTable<'tasks'>
          label={t`Task`}
          data={createEmptyTaskRecord}
          className={emptyRowPadding}
          disabled={addButtonDisabled}
        >
          <IconSquareCheck />
          <StatusIndicator active={false} />
          <Trans>Add a task</Trans>
        </SidePanelEmptyTable>
      ) : (
        <div role="table">
          <ProjectTasksTableHeader />
          <SidePanelTableRows
            name={'tasks'}
            watchUpdate={'task_name'}
            rowRenderer={(field, index) => (
              <ProjectTaskRow
                key={field.id}
                index={index}
                addDisabled={addButtonDisabled}
                budgetType={showBudget ? budgetType : undefined}
              />
            )}
          />
        </div>
      )}
      <SidePanelAddRowButton<'tasks'>
        data={createEmptyTaskRecord}
        buttonText={t`Add a task`}
        disabled={addButtonDisabled}
      />
    </div>
  );
};
