import * as Comlink from 'comlink';

import { config } from '@float/libs/config';

import SearchWorker from './worker/main.worker?worker';
import type { SearchWorkerApiLayer } from './worker/SearchWorkerApiLayer';

export async function spawnSearchWorker() {
  const worker = Comlink.wrap<SearchWorkerApiLayer>(
    new SearchWorker(),
    // Using a typecast here to remove what Comlink.wrap adds to SearchWorkerApiLayer
    // and keep this type valid on all the platform (comlink is mocked on timer and mobile)
  ) as unknown as SearchWorkerApiLayer;

  await worker.isReady();

  await worker.initializeEnvironment(config.serialize());

  return worker;
}
