import React from 'react';
import { t } from '@lingui/macro';
import * as Dialog from '@radix-ui/react-dialog';

import { stopPropagation as stopPropagationEvent } from '@float/libs/utils/events/stopPropagation';
import { Tooltip } from '@float/ui/components/Tooltip';
import { Button } from '@float/ui/deprecated';
import { IconTrash } from '@float/ui/icons/essentials/IconTrash';

import * as styles from './ConfirmDeleteModal.styles.css';

type ConfirmDeleteModalProps = {
  cancelLabel?: React.ReactNode;
  confirmLabel?: React.ReactNode;
  deleteLabel?: React.ReactNode;
  message?: React.ReactNode;
  onCancel?: () => void;
  onDelete?: () => void | Promise<void>;
  title?: React.ReactNode;
  // When the modal is opened from within a parent element that also has a click event listener, interacting
  // with the modal content triggers the parent listener. This option stops that effect.
  stopPropagation?: boolean;
};

export const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
  cancelLabel = t`Cancel`,
  confirmLabel = t`Click to confirm you want to proceed`,
  deleteLabel = t`Delete`,
  message,
  onCancel = () => {},
  onDelete = () => {},
  title,
  stopPropagation,
}) => {
  const [deleteConfirmed, setDeleteConfirmed] = React.useState(false);

  const onDeleteClick = () => {
    if (onDelete) {
      const promise = onDelete();
      if (promise?.then) {
        return promise.then(onCancel);
      }
      onCancel();
      return promise;
    }
  };

  const onOpenChange = (open: boolean) => {
    if (!open) {
      onCancel();
    }
  };

  return (
    <Dialog.Root open modal onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Content
          className={styles.confirmDeleteContent}
          onClick={stopPropagation ? stopPropagationEvent : undefined}
        >
          {title && (
            <Dialog.Title className={styles.confirmDeleteHeading}>
              {title}
            </Dialog.Title>
          )}

          {message}

          <button
            className={styles.confirmDeleteButton}
            role="button"
            onClick={() => setDeleteConfirmed(!deleteConfirmed)}
            disabled={deleteConfirmed}
            aria-disabled={deleteConfirmed}
          >
            <div className={styles.confirmDeleteIconWrapper}>
              <IconTrash color="#fff" />
            </div>
            {confirmLabel}
          </button>

          <div className={styles.buttonRow}>
            {!deleteConfirmed ? (
              <Tooltip
                content={t`Click the bin icon above to activate this button`}
                placement="top"
              >
                <Button
                  appearance="danger"
                  disabled={!deleteConfirmed}
                  onClick={onDeleteClick}
                >
                  {deleteLabel}
                </Button>
              </Tooltip>
            ) : (
              <Button
                appearance="danger"
                disabled={!deleteConfirmed}
                onClick={onDeleteClick}
              >
                {deleteLabel}
              </Button>
            )}
            <Button appearance="secondary" autoFocus onClick={onCancel}>
              {cancelLabel}
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
