import { useFormContext } from 'react-hook-form';

import { FormType, ProjectFormData } from '../types';

export function checkIsPhasePanel(type: FormType) {
  return type === FormType.Phase || type === FormType.PhaseTemplate;
}

export function useIsPhasePanel() {
  const { getValues } = useFormContext<ProjectFormData>();
  const type = getValues('type');
  return checkIsPhasePanel(type);
}
