import React from 'react';
import { get, isEmpty, map, reduce, sortBy } from 'lodash';
import { createSelector } from 'reselect';

import PersonAvatar from '@float/common/components/elements/PersonAvatar';

export const getPeopleOptions = createSelector(
  [(state) => state.people.people],
  (peopleMap) => {
    const peopleByDept = sortBy(
      reduce(
        peopleMap,
        (acc, p) => {
          const dept = get(p, 'department.name', 'No department');
          const deptId = get(p, 'department.department_id', 0);

          if (!acc[dept]) {
            acc[dept] = {
              name: dept,
              value: deptId,
              options: [],
            };
          }

          acc[dept].options.push({
            value: p.people_id,
            label: p.name,
            icon: <PersonAvatar personId={p.people_id} readOnly size="xs" />,
          });

          return acc;
        },
        {},
      ),
      (g) => g.name.toLowerCase(),
    );

    peopleByDept.forEach((dept) => {
      dept.options = sortBy(dept.options, (o) => o.label.toLowerCase());
    });

    return peopleByDept;
  },
);

export const getAccountOptions = createSelector(
  [(state) => state.accounts.accounts],
  (accountsMap) => {
    return sortBy(
      map(accountsMap, (a) => ({
        value: a.account_id,
        label: a.name,
        icon: <PersonAvatar accountId={a.account_id} readOnly size="xs" />,
      }))
        // remove accounts without label
        .filter(({ label }) => !isEmpty(label)),
      (p) => p.label.toLowerCase(),
    );
  },
);
