import {
  CLEAR_JWT,
  GET_JWT,
  GET_JWT_FAILURE,
  GET_JWT_SUCCESS,
} from '../actions';

const DEFAULT_STATE = {
  accessToken: null,
  refreshToken: null,
  currentCompanyId: null,
  expiry: 0,
};

export default function reducer(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case GET_JWT: {
      return {
        ...state,
        loadState: 'LOADING',
      };
    }

    case GET_JWT_SUCCESS: {
      // eslint-disable-next-line no-unused-vars
      const { ...payload } = action;
      if (!(payload.accessToken || payload.refreshToken)) {
        return state.loadState === 'LOADING'
          ? { ...state, loadState: 'LOAD_SUCCESS' }
          : state;
      }
      return { ...state, loadState: 'LOAD_SUCCESS', ...payload };
    }

    case CLEAR_JWT: {
      return {
        loadState: null,
        ...DEFAULT_STATE,
      };
    }

    case GET_JWT_FAILURE: {
      return {
        ...state,
        loadState: 'LOAD_FAILED',
      };
    }

    default: {
      return state;
    }
  }
}
