import { useAppSelectorStrict } from '@float/common/store';

export type SeatCapacity = {
  canAddSeats: (seatsToAdd: number) => boolean;
  seatsAvailable: number;
  seatsCapacity: number;
  seatsTaken: number;
};

export const useSeatCapacity = () => {
  const { p_limit: peopleLimit, people_count: peopleCount } =
    useAppSelectorStrict((state) => state.companyPrefs);

  return {
    canAddSeats: (seatsToAdd: number) =>
      peopleLimit - peopleCount - seatsToAdd > 0,
    seatsAvailable: peopleLimit - peopleCount,
    seatsCapacity: peopleLimit,
    seatsTaken: peopleCount,
  };
};
