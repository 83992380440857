import { CurrentUser } from '@float/types/account';
import { CompanyPreferences } from '@float/types/companyPreferences';
import { TimeFormatPreference } from '@float/types/timeFormatPreference';

export const getIs24HoursTimeFormat = (
  userPrefs: CurrentUser['prefs'],
  companyPrefs: CompanyPreferences,
) => {
  const isUserTimeFormatSet =
    typeof userPrefs.time_format_24h?.value !== 'undefined' &&
    [
      TimeFormatPreference.TwelveHourFormat,
      TimeFormatPreference.TwentyFourHourFormat,
    ].includes(userPrefs.time_format_24h?.value);

  if (isUserTimeFormatSet)
    return (
      userPrefs.time_format_24h?.value ===
      TimeFormatPreference.TwentyFourHourFormat
    );

  return Boolean(companyPrefs.time_format_24h);
};
