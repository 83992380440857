import React from 'react';

import VirtualSelect from '@float/ui/deprecated/VirtualSelect/VirtualSelect';

const TimezoneField = ({ options, fieldLink }) => {
  return (
    <VirtualSelect
      label="Time zone"
      visibleItems={6}
      nonNullable
      onChange={(e) => fieldLink.onChange({ target: e })}
      options={options}
      value={fieldLink.value?.value}
      size="large"
      secondaryText="The time zone setting is used for notifications by default."
    />
  );
};

export default TimezoneField;
