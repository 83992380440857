import React, { createElement, ReactElement } from 'react';

import { CellsMap, TimeRange, TopCellId } from '@float/types';

import { Cycles } from '../Cell/Cycles';
import { ScheduleActions } from '../Cell/MainCell/types';
import { EMPTY_OBJECT } from './Window.constants';
import { Bound, PinTargetRenderer, TopCellRenderer } from './Window.types';

const STICKY_COL_OVERSCAN_COUNT = 8;

export type BuildTopRowParams = {
  actions: ScheduleActions;
  baseColOffset: number;
  bounds: Bound[];
  cells: CellsMap;
  colStart: number;
  colStop: number;
  colWidth: number;
  dates: DatesManager;
  dayWidth: number;
  disableTooltips: { current: boolean };
  numCols: number;
  renderers: {
    pinTarget: PinTargetRenderer;
    top: TopCellRenderer;
  };
  renderersConfig: {
    pinTarget?: {
      isSideMenuDisplayed: boolean;
    };
  };
  scrollWrapperRef: React.MutableRefObject<HTMLElement | undefined>;
  showCycles?: boolean;
  suvPersonId: number | null;
  logMyTimeView?: boolean;
  timeRange: TimeRange;
  totalHeight: number;
};

export const buildTopRow = ({
  actions,
  baseColOffset,
  bounds,
  cells,
  colStart,
  colStop,
  colWidth,
  dates,
  dayWidth,
  disableTooltips,
  numCols,
  renderers,
  renderersConfig,
  logMyTimeView,
  suvPersonId,
  showCycles,
  timeRange,
  totalHeight,
}: BuildTopRowParams) => {
  const top: ReactElement<unknown>[] = [
    createElement(renderers.pinTarget, {
      key: 'pin-target-left',
      className: 'pin-target-left',
      isSideMenuDisplayed: renderersConfig?.pinTarget?.isSideMenuDisplayed,
    }),
    createElement(renderers.pinTarget, {
      key: 'pin-target-right',
      className: 'pin-target-right',
    }),
  ];

  const topColumnStart = Math.max(
    baseColOffset,
    colStart - STICKY_COL_OVERSCAN_COUNT,
  );

  const topColumnEnd = Math.min(
    colStop + STICKY_COL_OVERSCAN_COUNT,
    baseColOffset + numCols,
  );

  if (showCycles) {
    const startDate = dates.fromDescriptor(topColumnStart, 0) ?? '';
    const endDate = dates.fromDescriptor(topColumnStart + numCols, 0) ?? '';

    top.push(
      <Cycles
        key="cycles"
        offset={colWidth * (topColumnStart - baseColOffset)}
        startDate={startDate}
        endDate={endDate}
      />,
    );
  }

  function createTopCell(i: number) {
    const key: TopCellId = `top:${i}`;

    top.push(
      createElement(renderers.top, {
        key,
        rowGroupTop: bounds[0]?.offset || 0,
        rowIdx: 0,
        colIdx: i,
        dayWidth,
        totalHeight,
        style: {
          left: colWidth * (i - baseColOffset),
          width: colWidth,
        },
        cell: cells[key] || EMPTY_OBJECT,
        actions,
        disableTooltips: disableTooltips.current,
        suvPersonCell: logMyTimeView
          ? cells[`${'logged_time'}-${suvPersonId}:${i}`]
          : undefined,
      }),
    );
  }

  for (let i = topColumnStart; i < topColumnEnd; i++) {
    createTopCell(i);
  }

  // if time range is outside of "scroll range", make sure we still render it
  if (timeRange.start_date) {
    const [timeRangeStart] = dates.toDescriptor(timeRange.start_date);
    if (timeRangeStart > topColumnEnd || timeRangeStart < topColumnStart) {
      createTopCell(timeRangeStart);
    }
  }

  return top;
};
