/* eslint-disable check-file/filename-naming-convention */
import { SettingsState } from '@float/common/reducers/lib/types';
import { AnyEntity } from '@float/types';

export const DEFAULT_SORT_TABLE = 'DEFAULT_SORT_TABLE';

export const getSortData = (
  state: SettingsState<any>,
  tableName = DEFAULT_SORT_TABLE,
) => {
  const { sortProp, sortDir } = state.sortableTables[tableName];
  if (sortProp) {
    return {
      property: sortProp,
      direction: sortDir,
    };
  }
  return null;
};

type ColumnValue = number | string | null;
type ColumnPropertyGetter<T extends AnyEntity> =
  | keyof T
  | ((entity: T) => ColumnValue);
export type ColumnsConfig<T extends AnyEntity> = (
  | ColumnPropertyGetter<T>
  | ColumnPropertyGetter<T>[]
)[];

const getTableRowData = <T extends AnyEntity>(
  propNames: ColumnsConfig<T>,
  entity: T,
): (ColumnValue | ColumnValue[])[] => {
  const getColumnValue = (
    entity: T,
    propName: ColumnPropertyGetter<T>,
  ): ColumnValue => {
    if (typeof propName === 'function') {
      return propName(entity);
    }

    const value = entity[propName];

    if (typeof value === 'string' || typeof value === 'number') {
      return value;
    }

    return null;
  };

  const forEachColumn = (
    propName: ColumnPropertyGetter<T> | ColumnPropertyGetter<T>[],
  ): ColumnValue | ColumnValue[] => {
    if (Array.isArray(propName)) {
      return propName.map((propNamePart) =>
        getColumnValue(entity, propNamePart),
      );
    }
    return getColumnValue(entity, propName);
  };

  return propNames.map(forEachColumn);
};

type TableRowFactory<T extends AnyEntity> = (
  entity: T,
  stateSlice?: SettingsState<T>,
) => null | {
  isEdited: boolean;
  isDeletable: boolean;
  id: string;
  entity: T;
  data: (ColumnValue | ColumnValue[])[];
};

export const getTableRowCreator =
  <T extends AnyEntity>(
    idProp: keyof T,
    isDeletable: (entity: T) => boolean,
    propNames: ColumnsConfig<T>,
  ): TableRowFactory<T> =>
  (entity: T) => {
    if (!entity) return null;
    return {
      isEdited: false,
      isDeletable: isDeletable(entity),
      id: entity[idProp] as string,
      entity,
      data: getTableRowData(propNames, entity),
    };
  };

export const getEntityById = <T extends AnyEntity>(
  id: keyof T,
  entities: T[],
  idProp: keyof T,
) => entities.find((entity) => entity[idProp] === id);

export const getTableRows = <T extends AnyEntity>(
  state: SettingsState<T>,
  getTableRow: TableRowFactory<T>,
  idProp: keyof T,
  tableName = DEFAULT_SORT_TABLE,
  filterEntities?: (entities: T[]) => T[],
) => {
  const entities = filterEntities
    ? filterEntities(state.entities)
    : state.entities;
  const { sortProp, sortDir } = state.sortableTables[tableName];
  if (sortProp) {
    return (
      state.sortProps[sortProp][sortDir].ids
        .flatMap((id: any) => {
          const entity = getEntityById(id, entities, idProp);
          if (entity) {
            return [getTableRow(entity, state)];
          }
          return [];
        })
        // filter out nulls as sortedIds contains all ids, not only ids of
        // entities supposed to be displayed here
        .filter((entity) => entity !== null)
    );
  }
  return entities.map((entity) => getTableRow(entity, state));
};
