import * as styles from '../styles.css';

const LOGO_WIDTH = 244;
const LOGO_HEIGHT = 178;
const LOGO_ASPECT_RATIO = LOGO_HEIGHT / LOGO_WIDTH;

const WORDMARK_WIDTH = 590;
const WORDMARK_ASPECT_RATIO = LOGO_HEIGHT / WORDMARK_WIDTH;

const GAP_WIDTH = 45;
const GAP_ASPECT_RATIO = LOGO_HEIGHT / GAP_WIDTH;

export const useFloatLogoSize = (
  width: number | undefined,
  height: number | undefined,
  wordmark: boolean,
) => {
  const inlineVars: Record<string, string> = {};

  let logoFullWidth;

  if (wordmark) {
    logoFullWidth = LOGO_WIDTH + GAP_WIDTH + WORDMARK_WIDTH;
  } else {
    logoFullWidth = LOGO_WIDTH;
  }

  const logWidthRatio = LOGO_WIDTH / logoFullWidth;
  const wordmarkWidthRatio = WORDMARK_WIDTH / logoFullWidth;
  const gapWidthRatio = GAP_WIDTH / logoFullWidth;

  let currentLogoWidth;
  let currentLogoHeight;
  let currentWordmarkWidth;
  let currentWordmarkHeight;
  let currentGapWidth;
  let currentGapHeight;

  const withWidth = width && width > 0;
  const withHeight = height && height > 0;

  if (withWidth) {
    currentLogoWidth = Math.ceil(width * logWidthRatio);
    currentWordmarkWidth = Math.ceil(width * wordmarkWidthRatio);
    currentGapWidth = Math.ceil(width * gapWidthRatio);

    currentLogoHeight = Math.ceil(currentLogoWidth * LOGO_ASPECT_RATIO);
    currentWordmarkHeight = currentLogoHeight;
    currentGapHeight = currentLogoHeight;

    inlineVars[styles.width] = `${width}px`;
  }

  if (!withWidth && withHeight) {
    currentLogoHeight = height;
    currentWordmarkHeight = height;
    currentGapHeight = height;

    currentLogoWidth = Math.ceil(currentLogoHeight / LOGO_ASPECT_RATIO);
    currentWordmarkWidth = Math.ceil(
      currentWordmarkHeight / WORDMARK_ASPECT_RATIO,
    );
    currentGapWidth = Math.ceil(currentGapHeight / GAP_ASPECT_RATIO);

    inlineVars[styles.height] = `${height}px`;
  }

  inlineVars[styles.logoWidth] = `${currentLogoWidth}px`;
  inlineVars[styles.logoHeight] = `${currentLogoHeight}px`;
  inlineVars[styles.wordmarkWidth] = `${currentWordmarkWidth}px`;
  inlineVars[styles.wordmarkHeight] = `${currentWordmarkHeight}px`;
  inlineVars[styles.gapWidth] = `${currentGapWidth}px`;
  inlineVars[styles.gapHeight] = `${currentGapHeight}px`;

  return inlineVars;
};
