import { projects } from '@float/common/api3/projects';

export async function fetchProjectShiftDates(projectId: number) {
  const res = await projects.getProject({
    id: projectId,
    query: {
      expand: 'shift_dates',
    },
  });

  // Using the non null assertion because using `expand: 'shift_dates'`
  // ensures that the shift_dates are provided
  const shiftDates = res.shift_dates!;

  return {
    startDate: shiftDates.start_date,
    endDate: shiftDates.end_date,
  };
}
