import React from 'react';

export default function ({ color, size = 12, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 12 12"
      {...props}
    >
      <path
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeLinejoin="round"
        d="M4 8.5l-2.5 2v-8a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H4z"
      />
    </svg>
  );
}
