import safeStringify from 'fast-safe-stringify';
import { last } from 'lodash';

import { AnyLoadedEntity, CellKey, DatesManager } from '@float/types';

import { RowMetas } from '../../useRowMetas.helpers';

type Props = {
  dates: DatesManager;
  rowMetas: RowMetas;
  hasTimeTracking: boolean;
};

export function createGetApplicableCellsKeys(props: Props) {
  const {
    dates: { toNum, max },
    rowMetas,
    hasTimeTracking,
  } = props;

  function getColIdx(date: string) {
    const num = toNum(date);

    // Discarding negative values, as they would break useCells
    // and won't be reachable on the Schedule anyway as it can happen
    // only if the date is before 2010-01-01
    if (num < 0) {
      return -1;
    }

    // OPTIMIZATION: Using a bitwise operator to eliminate decimals.
    //
    // This is safe here because:
    //   - We are doing an early return on negative values
    //   - 32-bit integers are ok in this range since we are calculating
    //     the number of weeks since 2010-01-01
    return (num / 7) | 0;
  }

  return function getApplicableCellKeys(entity?: AnyLoadedEntity) {
    if (!entity || hasTruthyProperty(entity, 'disabled')) return [];

    // Normalize our entities to always have start_date and end_date fields
    if (hasTruthyProperty(entity, 'logged_time_id')) {
      entity.start_date = entity.date;
      entity.end_date = entity.date;

      // OPTIMIZATION: Early return when finding a logged time
      // to skip the below checks
      const id = entity.people_id;
      const dateIdx = getColIdx(entity.date);

      if (dateIdx < 0) {
        return [];
      }

      if (entity.reference_date) {
        const referenceIdx = getColIdx(entity.reference_date);

        if (referenceIdx !== dateIdx) {
          return [
            `person-${id}:${referenceIdx}`,
            `logged_time-${id}:${referenceIdx}`,
            `person-${id}:${dateIdx}`,
            `logged_time-${id}:${dateIdx}`,
          ];
        }
      }

      return [`person-${id}:${dateIdx}`, `logged_time-${id}:${dateIdx}`];
    } else if (hasTruthyProperty(entity, 'oneoff_id')) {
      entity.start_date = entity.date;
      entity.end_date = entity.date;
    } else if (hasTruthyProperty(entity, 'holiday_id')) {
      entity.start_date = entity.date;
    } else if (
      hasTruthyProperty(entity, 'milestone_id') &&
      !entity.start_date
    ) {
      entity.start_date = entity.date;
    }

    let startDateIdx;

    try {
      startDateIdx = getColIdx(entity.start_date);
    } catch (err) {
      // Log to debug the Unable to generate error
      // https://linear.app/float-com/issue/CS-1662/team-123287-weve-got-a-problem-issue
      console.error('dates.toDescriptor failed on ', safeStringify(entity));
      throw err;
    }

    // Entities with a start date lower than the FLOAT_EPOCH are not supported in the schedule
    // https://github.com/floatschedule/js-common-npm/blob/main/lib/dates.js#L28
    // Fixes https://linear.app/float-com/issue/CS-1435/error-when-logging-in-to-docu-x-team-account
    if (startDateIdx < 0) {
      return [];
    }

    // Determine which weeks (cells) this entity will be present on. Note that
    // the end ranges used here are inclusive since a entity that starts and
    // ends on the same day still has a width of 1. Also note that blindly using
    // repeat_end_date results in more cells than strictly necessary.
    let repeatEndDate = entity.end_date;

    if (
      hasTruthyProperty(entity, 'repeat_state') &&
      hasTruthyProperty(entity, 'repeat_end_date')
    ) {
      const lastRepeatEnd = last(entity.repeatInstances)?.end_date;
      repeatEndDate = lastRepeatEnd
        ? max(entity.repeat_end_date!, lastRepeatEnd)
        : max(entity.repeat_end_date!, entity.end_date);
    }

    const start = startDateIdx;
    const end = getColIdx(repeatEndDate);

    const cellKeys = new Set<CellKey>();

    if (
      hasTruthyProperty(entity, 'people_id') &&
      !hasTruthyProperty(entity, 'people_ids')
    ) {
      const id = entity.people_id;

      for (let i = start; i <= end; i++) {
        cellKeys.add(`person-${id}:${i}`);

        if (hasTimeTracking) {
          cellKeys.add(`logged_time-${id}:${i}`);
        }
      }
    } else if (hasTruthyProperty(entity, 'people_ids')) {
      // Exclude phases
      if (!hasTruthyProperty(entity, 'phase_name')) {
        for (const id of entity.people_ids) {
          for (let i = start; i <= end; i++) {
            cellKeys.add(`person-${id}:${i}`);

            if (hasTimeTracking) {
              cellKeys.add(`logged_time-${id}:${i}`);
            }
          }
        }
      }
    }

    if (
      hasTruthyProperty(entity, 'region_holiday_id') ||
      hasTruthyProperty(entity, 'time_range_id')
    ) {
      for (let i = start; i <= end; i++) {
        cellKeys.add(`top:${i}`);
      }
    }

    if (hasTruthyProperty(entity, 'holiday_id')) {
      const rowIds: CellKey[] = [];

      rowMetas.forEach((_, rowId) => {
        if (rowId.startsWith('person')) {
          rowIds.push(rowId as CellKey);

          if (hasTimeTracking) {
            rowIds.push(rowId.replace('person-', 'logged_time-') as CellKey);
          }
        }
      });

      for (let i = start; i <= end; i++) {
        cellKeys.add(`top:${i}`);

        for (const rowId of rowIds) {
          cellKeys.add(`${rowId}:${i}`);
        }
      }
    }

    if (hasTruthyProperty(entity, 'milestone_id')) {
      const id = entity.project_id;
      for (let i = start; i <= end; i++) {
        cellKeys.add(`project-${id}:${i}`);
        cellKeys.add(`top:${i}`);
      }
    }

    // Since phase_id exists on tasks, we want to make sure we're only
    // capturing actual phase entities here and not task entities that
    // belong to a phase.
    if (
      !hasTruthyProperty(entity, 'task_id') &&
      !hasTruthyProperty(entity, 'milestone_id') &&
      hasTruthyProperty(entity, 'phase_id') &&
      hasTruthyProperty(entity, 'project_id')
    ) {
      const id = entity.project_id;

      for (let i = start; i <= end; i++) {
        cellKeys.add(`project-${id}:${i}`);
      }
    }

    // @ts-expect-error isProjectRow is a non-standard property, probably coming from internal mutations
    if (entity.isProjectRow && hasTruthyProperty(entity, 'project_id')) {
      const id = entity.project_id;

      for (let i = start; i <= end; i++) {
        cellKeys.add(`project-${id}:${i}`);
      }
    }

    return Array.from(cellKeys);
  };
}

/**
 * Used hasTruthyProperty as a compromise between performance and type validation
 * This way we don't spend time on "in" checks but still get the type filtering
 */
function hasTruthyProperty<K extends string>(
  entity: AnyLoadedEntity,
  key: K,
): entity is Extract<AnyLoadedEntity, Record<K, unknown>> {
  // @ts-expect-error Using a falsy check to avoid impacts on performance
  return entity[key];
}
