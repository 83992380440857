import { css } from 'styled-components';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

export const inputStyles = css`
  input {
    ${Typography.TextXL.M500};

    display: flex;
    align-items: center;
    width: 130px;
    max-width: 100%;

    color: ${Colors.FIN.Lt.Emphasis.High};

    background: none !important;

    border: 0;
    border-radius: 0;
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: ${Colors.FIN.Lt.Stroke.Stroke2};

    padding: 0 !important;

    box-shadow: none !important;

    &:hover {
      border-bottom-color: ${Colors.FIN.Lt.Stroke.Stroke3};
    }

    &:focus {
      border-bottom-color: ${Colors.FIN.Lt.Emphasis.Primary};
    }
  }
`;
