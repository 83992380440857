import React, { forwardRef, useCallback } from 'react';

import { StyledContent, StyledItem } from './styles';

const Item = forwardRef((props, ref) => {
  const { value, icon, isActive = false, onClick } = props;

  // Input events
  const onClickCallback = useCallback(() => {
    if (onClick) onClick(value);
  }, [onClick, value]);

  // Render

  return (
    <StyledItem ref={ref} isActive={isActive} onClick={onClickCallback}>
      <StyledContent>
        {value}
        {icon && React.createElement(icon)}
      </StyledContent>
    </StyledItem>
  );
});

export default Item;
