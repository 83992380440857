import React from 'react';
import { connect } from 'react-redux';

import { getUser } from '@float/common/selectors/currentUser';
import { PROMPTS } from '@float/constants/prompts';
import { updatePrompts } from '@float/web/store/onboardingManager/actions';
import TourTip from 'components/elements/TourTip';

import ChartVirtualSelect from './ChartVirtualSelect';

const ID = PROMPTS.reportsMode;

const selectStyle = { marginRight: 10 };

const wrapperStyle = { textAlign: 'center', maxWidth: 150 };

function ModeOptions({
  value,
  options,
  hasLoggedTime,
  alreadySeen,
  markAsSeen,
  onChange,
}) {
  const handleChange = (val) => {
    onChange(val);
    if (!alreadySeen) {
      markAsSeen();
    }
  };

  const el = (
    <ChartVirtualSelect
      value={value}
      options={options}
      width={220}
      style={selectStyle}
      onChange={handleChange}
    />
  );

  if (alreadySeen || !hasLoggedTime) {
    return el;
  }

  return (
    <TourTip
      promptKey={ID}
      hideCloseButton
      tooltipProps={{
        className: 'hover',
        content: (
          <TourTip.Wrapper style={wrapperStyle}>
            <TourTip.Header>
              Switch views to see Time tracking reports.
            </TourTip.Header>
            <TourTip.Button onClick={markAsSeen}>Got it</TourTip.Button>
          </TourTip.Wrapper>
        ),
        placement: 'bottom',
      }}
    >
      <div>{el}</div>
    </TourTip>
  );
}

const mapStateToProps = (state) => {
  const { prompts } = state.legacyOnboarding;
  const isAccountOwner = +getUser(state).account_tid === 1;

  // If user has not seen the new "create task" tip,
  // then they either completed previous onboarding or
  // haven't created a task yet via new onboarding flow.
  // In either of these cases, don't show this "mode" tip.
  const hasSeenCreateTaskTip = prompts.includes(PROMPTS.welcomeAddTask);

  return {
    alreadySeen:
      !isAccountOwner ||
      !hasSeenCreateTaskTip ||
      state.legacyOnboarding.prompts.includes(ID),
  };
};

const mapDispatchToProps = (dispatch) => ({
  markAsSeen: () => dispatch(updatePrompts(ID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModeOptions);
