import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { addPeopleToProject } from '@float/common/actions/people';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { Button, Modal, VirtualSelect } from '@float/ui/deprecated';

class AssignProjectModal extends React.Component {
  state = {
    loader: false,
  };

  getFirstProject = (props = this.props) => {
    const firstProject = props.projectsByClient[0].items[0];
    return {
      selectedProjectId: firstProject.project_id,
      selectedProjectName: firstProject.name,
    };
  };

  constructor(props) {
    super(props);
    this.state = this.getFirstProject(props);
  }

  handleChangeProject = (selected) => {
    if (!selected) {
      this.setState(this.getFirstProject());
    } else {
      this.setState({
        selectedProjectId: get(selected, 'value'),
        selectedProjectName: get(selected, 'label'),
      });
    }
  };

  submit = async (e) => {
    prevent(e);
    const projectId = this.state.selectedProjectId;

    this.setState({ loader: true });
    this.props
      .addPeopleToProject(
        this.props.data.map((id) => Number(id)),
        projectId,
      )
      .then(() => {
        if (this.props.onSuccess) {
          this.props.onSuccess();
        }
      });
  };

  cancel = (e) => {
    prevent(e);
    this.props.hide();
  };

  render() {
    const projectsByClientOpts = this.props.projectsByClient
      .map((pbc) => ({
        name: pbc.name,
        options: pbc.items
          .filter((i) => i.active)
          .map((i) => ({
            hideClearIcon: true,
            value: i.project_id,
            label: i.project_name,
          })),
      }))
      .filter((i) => i.options.length);

    return (
      <Modal isOpen onClose={this.props.hide}>
        <Modal.Header>
          <Modal.Title>Assign to project</Modal.Title>
        </Modal.Header>
        <form onSubmit={this.submit}>
          <Modal.Body>
            <VirtualSelect
              visibleItems={6}
              autoFocus
              clearInputOnDropdownOpen={false}
              groupedOptions={projectsByClientOpts}
              value={this.state.selectedProjectId}
              onChange={this.handleChangeProject}
            />
          </Modal.Body>

          <Modal.Actions>
            <Button type="submit" loader={this.state.loader}>
              Assign to project
            </Button>
            <Button appearance="secondary" onClick={this.cancel}>
              Cancel
            </Button>
          </Modal.Actions>
        </form>
      </Modal>
    );
  }
}

const mapDispatchToProps = { addPeopleToProject };

export default connect(null, mapDispatchToProps)(AssignProjectModal);
