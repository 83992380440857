export function getErrorMessageFromException(ex) {
  const defaultMessage = 'An error occurred';
  let errorMessage = defaultMessage;

  if (typeof ex === 'string') {
    errorMessage = ex;
  } else if (ex?.message) {
    errorMessage = ex.message;
  } else if (ex.length) {
    const err = ex.find((e) => e.message);
    errorMessage = err?.message;
  }

  if (ex?.status === 500) {
    // ensure an internal error doesn't show on UI
    errorMessage = defaultMessage;
  }

  return errorMessage || defaultMessage;
}
