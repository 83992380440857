import { Phase, PhaseInputData, ProjectStatus } from '@float/types';

import { phases as PhasesAPI } from '../../api3/phases';
import { ReduxStateStrict } from '../../reducers/lib/types';
import { AppDispatchStrict } from '../../store';
import { PhaseApiPayload, PHASES_UPDATED } from './phases';

const mapChangesToPhasePayload = (data: Partial<PhaseInputData>) => {
  const payload: Partial<PhaseApiPayload> = {};

  for (const key of Object.keys(data) as Array<keyof PhaseInputData>) {
    switch (key) {
      case 'phase_name':
        payload.name = data[key];
        break;
      case 'notes':
        payload.notes = data[key];
        break;
      case 'active':
      case 'non_billable':
        payload[key] = data[key] ? 1 : 0;
        break;
      case 'status':
        payload[key] = data[key];
        payload['tentative'] = data[key] === ProjectStatus.Tentative ? 1 : 0;
        break;
      case 'notes_meta':
      case 'color':
      case 'end_date':
      case 'start_date':
      case 'budget_total':
      case 'default_hourly_rate':
        // @ts-expect-error Typescript doesn't like this kind of dynamic logic
        // but this way it is easier to understand this function logic.
        payload[key] = data[key];
        break;
      default:
        break;
    }
  }

  return payload;
};

export function updatePhaseFromSidePanel(
  id: Phase['phase_id'],
  input: Partial<PhaseInputData>,
  team?: PhaseApiPayload['phase_team'],
) {
  return async (
    dispatch: AppDispatchStrict,
    getState: () => ReduxStateStrict,
  ) => {
    const data = mapChangesToPhasePayload(input);

    if (team) {
      data.phase_team = team;
    }

    const response = await PhasesAPI.updatePhase({
      id,
      data,
      query: {
        expand: 'phase_team',
      },
    });

    dispatch({
      type: PHASES_UPDATED,
      phases: [response],
      prevPhases: [getState().phases.phases[id]],
    });

    return response;
  };
}
