import { connect } from 'react-redux';

import Filter, {
  DEFAULT_YEAR,
} from '../../components/Holidays/YearTableFilter';
import { getHolidaysYears } from '../../reducers/holidays';

export { DEFAULT_YEAR };

const mapYearsForDropdown = (years) => {
  if (!years.includes(DEFAULT_YEAR)) {
    years.push(DEFAULT_YEAR);
    years.sort();
  }
  return years.map((year) => ({
    label: year,
    value: year,
  }));
};

const mapStateToProps = (state) => {
  return {
    options: mapYearsForDropdown(getHolidaysYears(state.settingsHolidays)),
  };
};

const mapDispatchToProps = (dispatch) => ({});

const YearTableFilter = connect(mapStateToProps, mapDispatchToProps)(Filter);

export default YearTableFilter;
