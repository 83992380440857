import React from 'react';
import { Plural, t, Trans } from '@lingui/macro';

import { formatRate } from '@float/common/lib/rates/rates';
import { Person } from '@float/types/person';

export type GetRateChangeConfirmDataProps = {
  ids: number[];
  entities: Record<number, Person>;
  fields: Pick<Person, 'default_hourly_rate'>;
};

export const getRateChangeConfirmData = ({
  ids,
  entities,
  fields,
}: GetRateChangeConfirmDataProps) => {
  const peopleSelected = ids.map((id) => entities[id]).filter(Boolean);

  const isUsingRoleRate = fields.default_hourly_rate === null;

  const peopleWithRateChange = peopleSelected.filter(
    (person) =>
      formatRate(person.default_hourly_rate) !== fields.default_hourly_rate,
  );

  const numPeopleWithRateChange = peopleWithRateChange.length;
  const hasPeopleWithRateChange = numPeopleWithRateChange > 0;

  // No people with rate change found
  if (!hasPeopleWithRateChange) return null;

  if (isUsingRoleRate) {
    return {
      title: t`Confirm rate change`,
      message: (
        <p>
          <Trans>
            You are changing the custom Person rate for{' '}
            {numPeopleWithRateChange}{' '}
            <Plural
              value={numPeopleWithRateChange}
              one="Person"
              other="People"
            />{' '}
            to the default hourly rate of their current Role.
            <br />
            <br />
            New rates do not override rates set at the Project, or Phase level.
          </Trans>
        </p>
      ),
    };
  }

  return {
    title: t`Confirm rate change`,
    message: (
      <p>
        <Trans>
          You are changing the custom Person rate for {numPeopleWithRateChange}{' '}
          <Plural value={numPeopleWithRateChange} one="Person" other="People" />
          .<br />
          <br />
          New rates do not override rates set at the Project, or Phase level.
        </Trans>
      </p>
    ),
  };
};
