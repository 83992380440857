import { select, t } from '@lingui/macro';
import format from 'date-fns/format';
import getWeekOfMonth from 'date-fns/getWeekOfMonth';

import { RepeatIntervalKey } from '@float/common/components/taskModals/constants';

export const getRepeatIntervalDescription = (
  repeatState: RepeatIntervalKey,
) => {
  const value = select(String(repeatState), {
    // `select` requires indices to be strings
    '0': "Doesn't repeat",
    '1': 'Weekly',
    '2': 'Monthly',
    '3': 'Every 2 weeks',
    '4': 'Every 3 weeks',
    '5': 'Every 6 weeks',
    '6': 'Every 2 months',
    '7': 'Every 3 months',
    '8': 'Every 6 months',
    '9': 'Yearly',
    other: "Doesn't repeat",
  });

  return value;
};

const getWeekNumberLabel = (date: Date) => {
  const weekOfMonth = getWeekOfMonth(date);

  return select(String(weekOfMonth), {
    // `select` requires indices to be strings
    '1': 'first',
    '2': 'second',
    '3': 'third',
    '4': 'fourth',
    '5': 'fifth',
    other: '',
  });
};

export const REPEAT_STATES_DESCRIBED_BY_DAY = [1, 3, 4, 5];
export const REPEAT_STATES_DESCRIBED_WEEK_AND_DAY = [2, 6, 7, 8];

export const getRepeatingLabel = (
  repeatState: RepeatIntervalKey,
  startDate: Date,
) => {
  const repeatStateDescription = getRepeatIntervalDescription(repeatState);

  if (!repeatState) {
    return repeatStateDescription;
  }

  const monthName = format(startDate, 'MMMM');
  const dayOfWeek = format(startDate, 'EEEE');
  const weekOfMonth = getWeekNumberLabel(startDate);

  let repeatDayLabel;

  if (REPEAT_STATES_DESCRIBED_BY_DAY.includes(repeatState)) {
    repeatDayLabel = t`${repeatStateDescription} on ${dayOfWeek}`;
  } else if (REPEAT_STATES_DESCRIBED_WEEK_AND_DAY.includes(repeatState)) {
    repeatDayLabel = t`${repeatStateDescription} on the ${weekOfMonth} ${dayOfWeek}`;
  } else {
    repeatDayLabel = t`${repeatStateDescription} on the ${weekOfMonth} ${dayOfWeek} of ${monthName}`;
  }

  return repeatDayLabel;
};
