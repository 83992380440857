import { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import { ThunkDispatch } from 'redux-thunk';

import { fetchUser, initCurrentUser } from '@float/common/actions/currentUser';
import { fetchCompanies } from '@float/common/actions/currentUser/fetchCompanies';
import { ensureDepartmentsLoaded } from '@float/common/actions/departments';
import { getJWTAccessToken } from '@float/common/actions/jwt';
import { fetchLockLoggedTimeConfig } from '@float/common/actions/lockLoggedTime';
import { ensureTemplatesLoaded } from '@float/common/actions/projects';
import { ensureContextLoaded } from '@float/common/actions/search';
import { ensureStatusTypesLoaded } from '@float/common/actions/statusTypes';
import { ensureTimeoffTypesLoaded } from '@float/common/actions/timeoffTypes';
import { ensureActiveTimerLoaded } from '@float/common/actions/timer';
import { loadInitialView } from '@float/common/actions/views';
import { ReduxState } from '@float/common/reducers/lib/types';
import {
  AppDispatchStrict,
  useAppDispatch,
  useAppSelector,
} from '@float/common/store';
import { searchResolveStartAction } from '@float/common/store/searchResults/searchResults.actions';
import { useOnMount } from '@float/libs/hooks/useOnMount';
import { useWarningSnackbar } from '@float/ui/deprecated/Snackbar/useWarningSnackbar';
import { appLoaded } from '@float/web/actions/app';
import { WebAppDispatch } from '@float/web/lib/store';
import manageModal from '@float/web/modalManager/manageModalActionCreator';
import { requestNotificationsFeedPage } from '@float/web/store/notifications/notifications.actions';

import { FetchFailedError, getPortalFailedDataFetch } from './fetchFailed';

async function load(
  dispatch: AppDispatchStrict,
  isSharedLink: boolean,
  showErrorMessage: (message: string) => void,
) {
  const token = await dispatch(getJWTAccessToken());

  if (!token) return;

  if (isSharedLink) {
    await dispatch(initCurrentUser());
  } else {
    await dispatch(fetchUser());
  }

  dispatch(searchResolveStartAction());

  const requests: unknown[] = [
    dispatch(ensureContextLoaded()),
    dispatch(ensureTimeoffTypesLoaded()),
    dispatch(ensureStatusTypesLoaded()),
  ];

  if (isSharedLink) {
    requests.push(dispatch(initCurrentUser()));
  } else {
    requests.push(
      dispatch(fetchCompanies()),
      dispatch(ensureDepartmentsLoaded()),
      dispatch(fetchLockLoggedTimeConfig()),
      dispatch(loadInitialView()).catch(() => {
        showErrorMessage(t`Failed to load the selected View`);
      }),
      dispatch(ensureTemplatesLoaded()),
      // We fetch the active timer without applying a time based pagination
      // in order to ensure that it is always loaded
      dispatch(ensureActiveTimerLoaded()),
      (dispatch as WebAppDispatch)(requestNotificationsFeedPage({ page: 1 })),
    );
  }

  await Promise.all(requests);
}

async function retry(
  dispatch: ThunkDispatch<ReduxState, any, any>,
  isSharedLink: boolean,
  showErrorMessage: (message: string) => void,
) {
  // Hides the error modal that shows up when an API fails
  dispatch(
    manageModal({
      visible: false,
      modalType: 'errorModal',
      modalSettings: undefined,
    }),
  );

  await load(dispatch, isSharedLink, showErrorMessage);
}

export function usePortalDataLoad(isSharedLink: boolean) {
  const dispatch = useAppDispatch();
  const showWarningSnackbar = useWarningSnackbar();

  function showErrorMessage(message: string) {
    showWarningSnackbar(message);
  }

  const [loading, setLoading] = useState(true);

  useOnMount(() => {
    let mounted = true;

    (async () => {
      await load(dispatch, isSharedLink, showErrorMessage);

      // A load failure triggers the unmount
      if (mounted) {
        setLoading(false);
        dispatch(appLoaded());
      }
    })();

    return () => {
      mounted = false;
    };
  });

  function handleRetry() {
    return retry(dispatch, isSharedLink, showErrorMessage);
  }

  const failed = useAppSelector((state: ReduxState) => {
    const failed = getPortalFailedDataFetch(state);

    if (failed.length) {
      // if the app is loaded, the error might have been triggered during the
      // offline data refetch process
      if (state.app.loaded) {
        return true;
      }

      // If App is not already loaded, stop the rendering and trigger the ErrorBoundary
      throw new FetchFailedError(failed, handleRetry);
    }

    return false;
  });

  useEffect(() => {
    if (failed) {
      dispatch(
        manageModal({
          visible: true,
          modalType: 'errorModal',
          modalSettings: {
            error: {
              title: "We're having trouble loading your data",
              message:
                'Check your network connection, if the error persists, please contact our support team.',
            },
            props: {
              newModal: true,
              submitColor: 'green',
              submitLabel: 'Try again',
              cancelColor: 'gray',
              cancelLabel: 'Close',
              textSize: 'big',
              onSubmit: handleRetry,
            },
          },
        }),
      );
    }

    // This a "trigger effect" related to the failed state
  }, [failed]);

  return loading;
}
