export const FETCH_TIMEOFFS = 'FETCH_TIMEOFFS';
export const FETCH_TIMEOFFS_SUCCESS = 'FETCH_TIMEOFFS_SUCCESS';
export const FETCH_TIMEOFFS_FAILURE = 'FETCH_TIMEOFFS_FAILURE';
export const CREATE_TIMEOFF = 'CREATE_TIMEOFF';
export const CREATE_TIMEOFF_SUCCESS = 'CREATE_TIMEOFF_SUCCESS';
export const CREATE_TIMEOFF_FAILURE = 'CREATE_TIMEOFF_FAILURE';
export const UPDATE_TIMEOFF = 'UPDATE_TIMEOFF';
export const UPDATE_TIMEOFF_SUCCESS = 'UPDATE_TIMEOFF_SUCCESS';
export const UPDATE_TIMEOFF_FAILURE = 'UPDATE_TIMEOFF_FAILURE';
export const DELETE_TIMEOFF = 'DELETE_TIMEOFF';
export const DELETE_TIMEOFF_SUCCESS = 'DELETE_TIMEOFF_SUCCESS';
export const DELETE_TIMEOFF_FAILURE = 'DELETE_TIMEOFF_FAILURE';
export const INSERT_TIMEOFF = 'INSERT_TIMEOFF';
export const INSERT_TIMEOFF_SUCCESS = 'INSERT_TIMEOFF_SUCCESS';
export const INSERT_TIMEOFF_FAILURE = 'INSERT_TIMEOFF_FAILURE';
export const REPLACE_TIMEOFF = 'REPLACE_TIMEOFF';
export const REPLACE_TIMEOFF_SUCCESS = 'REPLACE_TIMEOFF_SUCCESS';
export const REPLACE_TIMEOFF_FAILURE = 'REPLACE_TIMEOFF_FAILURE';
export const DELETE_TIMEOFF_TYPE = 'DELETE_TIMEOFF_TYPE';
export const DELETE_TIMEOFF_TYPE_SUCCESS = 'DELETE_TIMEOFF_TYPE_SUCCESS';
export const DELETE_TIMEOFF_TYPE_FAILURE = 'DELETE_TIMEOFF_TYPE_FAILURE';
export const ADD_TIMEOFF_TYPE = 'ADD_TIMEOFF_TYPE';
export const ADD_TIMEOFF_TYPE_SUCCESS = 'ADD_TIMEOFF_TYPE_SUCCESS';
export const ADD_TIMEOFF_TYPE_FAILURE = 'ADD_TIMEOFF_TYPE_FAILURE';
export const UPDATE_TIMEOFF_TYPE = 'UPDATE_TIMEOFF_TYPE';
export const UPDATE_TIMEOFF_TYPE_SUCCESS = 'UPDATE_TIMEOFF_TYPE_SUCCESS';
export const UPDATE_TIMEOFF_TYPE_FAILURE = 'UPDATE_TIMEOFF_TYPE_FAILURE';
