import { useMemo } from 'react';

import { getShouldAllowDraftStatus } from '@float/common/lib/acl/getShouldAllowDraftStatus';
import { useAppSelectorStrict } from '@float/common/store';
import { CurrentUser } from '@float/types';
import { getProjectsMapRaw } from '@float/web/selectors';

export function useShouldAllowDraftStatus(
  ids: number[],
  currentUser: CurrentUser,
): boolean {
  const projects = useAppSelectorStrict(getProjectsMapRaw);

  const selectedProjects = useMemo(() => {
    return ids.map((id) => projects[id]).filter(Boolean);
  }, [ids, projects]);

  return useMemo(
    () => getShouldAllowDraftStatus(currentUser, selectedProjects),
    [selectedProjects, currentUser],
  );
}
