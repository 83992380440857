import React, { memo, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { config } from '@float/libs/config';
import useMedia from '@float/libs/web/hooks/useMedia';
import EH from '@float/ui/deprecated/Earhart';

import IconSpinner from '../Icons/icon-spinner';
import * as styled from './styles';

const AUTO_CLOSE_MS = 3000;

export type SnackbarProps = {
  id?: string;
  message: string;
  index?: number;
  showClose?: boolean;
  triggerClose?: boolean;
  persist?: boolean;
  loader?: boolean;
  className?: string;
  iconLeft?: React.JSXElementConstructor<unknown>;
  iconRight?: React.JSXElementConstructor<{ color: string }>;
  linkText?: string;
  linkTo?: string;
  linkAriaLabel?: string;
  autoCloseMs?: number;
  closeOnLinkClick?: boolean;
  placement?: string;
  sidebarWidth?: number;
  style?: React.CSSProperties;
  space?: number;
  onLinkClick?: () => void;
  onClose?: (id?: string) => void;
};

function Snackbar({
  id,
  message,
  index = 0,
  showClose = false,
  triggerClose = false,
  persist = false,
  loader = false,
  className = '',
  iconLeft: IconLeft,
  iconRight: IconRight,
  linkText,
  linkTo,
  linkAriaLabel,
  autoCloseMs = AUTO_CLOSE_MS,
  closeOnLinkClick = true,
  placement = 'bottom',
  sidebarWidth = 60,
  style,
  space = 40,
  onLinkClick,
  onClose,
}: SnackbarProps) {
  const [closing, setClosing] = useState(false);
  const media = useMedia();
  const isMobile = !config.isNativeTimerApp && media === 'small';

  const onCloseClick = useCallback(() => {
    setClosing(true);

    if (onClose) {
      setTimeout(() => {
        onClose(id);
      }, 125);
    }
  }, [id, onClose]);

  const onCtaClick = () => {
    if (onLinkClick) {
      onLinkClick();
    }

    if (closeOnLinkClick) {
      onCloseClick();
    }
  };

  useEffect(() => {
    let autoCloseTimer: ReturnType<typeof setTimeout> | undefined;
    if (!persist) {
      autoCloseTimer = setTimeout(() => {
        onCloseClick();
      }, autoCloseMs);
    }
    return () => {
      if (autoCloseTimer) {
        clearTimeout(autoCloseTimer);
      }
    };
  }, [id, persist, index, autoCloseMs, onCloseClick]);

  useEffect(() => {
    if (triggerClose) {
      onCloseClick();
    }
  }, [triggerClose, onCloseClick]);

  if (!message) {
    return null;
  }

  const hasLeftIcon = !!IconLeft;
  const hasRightIcon = showClose || IconRight;

  return (
    <styled.Container
      className={cx(className, {
        mobile: isMobile,
      })}
      // @ts-expect-error Missing types on styles
      index={index}
      closing={closing}
      hasRightIcon={hasRightIcon}
      hasLeftIcon={hasLeftIcon}
      placement={placement}
      space={space}
      sidebarWidth={sidebarWidth}
      style={style}
    >
      {IconLeft && (
        <styled.IconLeft>
          <IconLeft />
        </styled.IconLeft>
      )}
      {loader && (
        <styled.Spinner>
          <IconSpinner />
        </styled.Spinner>
      )}
      <styled.Text>{message}</styled.Text>
      {linkText &&
        (linkTo ? (
          <styled.Link
            as={Link}
            to={linkTo}
            aria-label={linkAriaLabel}
            onClick={onCtaClick}
          >
            {linkText}
          </styled.Link>
        ) : (
          <styled.Link aria-label={linkAriaLabel} onClick={onCtaClick}>
            {linkText}
          </styled.Link>
        ))}
      {hasRightIcon && (
        <styled.IconRight onClick={onCloseClick}>
          {showClose ? (
            <EH.Icons.IconClose color="#ffffff" />
          ) : IconRight ? (
            <IconRight color="white" />
          ) : null}
        </styled.IconRight>
      )}
    </styled.Container>
  );
}

export default memo(Snackbar);
