import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import useUpdateEffect from 'react-use/esm/useUpdateEffect';
import { BaseEditor, Editor, Node, Transforms } from 'slate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';
import styled from 'styled-components';

import { getPeopleWithAccount } from '@float/common/selectors/people';
import { useAppSelector } from '@float/common/store';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import { filter } from '@float/ui/deprecated/Earhart/RichText/utils/filter';
import { FieldWrapper } from '@float/ui/deprecated/Input/styles';
import { FieldLabel } from '@float/ui/deprecated/Label';

import Element from './components/element';
import { SlateEditor } from './components/SlateEditor';
import { useMentions } from './hooks/useMentions';
import { linkifyOnKeyDown } from './plugins/utils/linkifyOnKeyDown';
import { linkifyAndInsertFragments } from './RichText.helpers';
import {
  StyledInput,
  StyledInputContainer,
  StyledTextAreaWrapper,
  StyledVirtualList,
} from './styles';
import { createEditor } from './utils/createEditor';
import { getInitialValue } from './utils/getInitialValue';

export const ReziseHandleWrapper = styled.div`
  width: calc(100% + 6px);
  margin-bottom: -4px;
  padding-bottom: 4px;

  min-height: 64px;

  [data-slate-placeholder] {
    color: ${Colors.Core.Text.Subdued};
  }

  .slate-editor {
    outline: none;
  }

  overflow: auto;
  resize: vertical;
`;

type RichTextProps = {
  autoFocus?: boolean;
  background?: string;
  className?: string;
  inputClassName?: string;
  label?: string;
  maxLength?: number;
  mentionsData?: unknown;
  mentionsEnabled?: boolean;
  mentionsTrigger?: string;
  mentionsValueField?: string;
  mentionsWithAvatar?: boolean;
  onBlur?: () => void;
  onChange: (value: { text: string; children: Descendant[] }) => void;
  onFocus?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onTabKey?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  readOnly?: boolean;
  style?: React.CSSProperties;
  textColor?: string;
  value: string | null;
  valueMeta?: NotesMeta;
};

export type RichTextHandle = {
  editor: BaseEditor & ReactEditor & HistoryEditor;
  focus: () => void;
};

function resetEditorSelection(editor: BaseEditor) {
  editor.selection = null;
}

export const RichText = forwardRef<RichTextHandle, RichTextProps>(
  (props, ref) => {
    const {
      className,
      inputClassName,
      style,
      label = '',
      placeholder = '',
      value = '',
      valueMeta = null,
      readOnly = false,
      maxLength = 1500,
      onChange,
      // onEscape,
      onBlur,
      onKeyDown,
      onTabKey,
      mentionsEnabled = false,
      mentionsData = null,
      mentionsTrigger = '@',
      mentionsValueField = 'name',
      mentionsWithAvatar = true,
      autoFocus = false,
      onFocus,
      textColor,
      background,
    } = props;

    const virtualListRef = useRef();
    const styledTextWrapperRef = useRef<HTMLDivElement>(null);

    const focused = useRef(false);
    const people = useAppSelector(getPeopleWithAccount);

    const [editor] = useState(() => createEditor({ maxLength }));

    const mentions = useMentions(
      filter,
      mentionsEnabled,
      (mentionsData || people || []) as { account_id: string }[],
      mentionsTrigger,
      mentionsValueField,
      virtualListRef,
      editor,
    );

    const [initialValue, setInitialValue] = useState<
      (Descendant | { type: string; children: Node[] })[]
    >(() => getInitialValue(value, valueMeta, maxLength));

    const renderElement = useCallback(
      (props: object) => <Element {...props} />,
      [],
    );

    const setFocused = useCallback((state: boolean) => {
      focused.current = state;

      if (focused.current) {
        styledTextWrapperRef.current?.classList.add('focused');
      } else {
        styledTextWrapperRef.current?.classList.remove('focused');
      }
    }, []);

    const handleMouseDown = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        if (readOnly) return;

        // because the input doesn't fill the whole
        // StyledTextAreaWrapper area, we are making sure
        // the the input get the focus when this get clicked
        if (e.currentTarget == e.target) {
          setTimeout(() => {
            ReactEditor.focus(editor);
            Transforms.select(editor, Editor.end(editor, []));
          }, 0);
        }
      },
      [editor, readOnly],
    );

    const handleFocus = useCallback(() => {
      setFocused(true);
      if (onFocus) {
        onFocus();
      }
    }, [setFocused, onFocus]);

    const handleTabFocus = useCallback(() => {
      ReactEditor.focus(editor);
      Transforms.select(editor, Editor.end(editor, []));
    }, [editor]);

    const handleTabKey = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (onTabKey) onTabKey(e);
      },
      [onTabKey],
    );

    const handleBlur = useCallback(() => {
      setFocused(false);

      // linkify on blur in case there are links that weren't picked up while typing
      linkifyAndInsertFragments(editor);
      resetEditorSelection(editor);

      if (onBlur) {
        onBlur();
      }
    }, [editor, onBlur, setFocused]);

    const handleChange = useCallback(
      (data: { text: string; children: Descendant[] }) => {
        if (onChange) onChange(data);
        if (mentions && mentions.enabled) mentions.handleChange();
      },
      [onChange, mentions],
    );

    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (onKeyDown) onKeyDown(e);

        if (mentions && mentions.enabled) mentions.handleKeyDown(e);

        linkifyOnKeyDown(e, editor);
      },
      [editor, mentions, onKeyDown],
    );

    const handleSelection = useCallback(
      (item: unknown, index: number, e: React.MouseEvent<HTMLDivElement>) => {
        // prevent editor from losing focus when
        // clicking on one option from the list
        if (e) e.preventDefault();

        if (mentions && mentions.enabled) mentions.handleSelection(item);
      },
      [mentions],
    );

    useImperativeHandle(ref, () => ({
      editor,
      focus: () => {
        setTimeout(() => {
          ReactEditor.focus(editor);
          Transforms.select(editor, Editor.end(editor, []));
        }, 0);
      },
    }));

    // update editor when value changes without user interaction
    useUpdateEffect(() => {
      if (value !== undefined && value !== null && !focused.current) {
        setInitialValue(getInitialValue(value, valueMeta, maxLength));
      }
    }, [value, valueMeta]);

    useUpdateEffect(() => {
      if (!focused.current) {
        Transforms.delete(editor, {
          at: {
            anchor: Editor.start(editor, []),
            focus: Editor.end(editor, []),
          },
        });

        Transforms.insertFragment(editor, initialValue as Node[], {
          at: Editor.start(editor, []),
        });
      }
    }, [initialValue]);

    // make sure we linkify when initialValue is set or changes
    useEffect(() => {
      linkifyAndInsertFragments(editor);
    }, [editor, initialValue]);

    return (
      <StyledInputContainer className={className} style={style}>
        <FieldWrapper>
          <FieldLabel>{label}</FieldLabel>
          <StyledTextAreaWrapper
            ref={styledTextWrapperRef}
            onMouseDown={handleMouseDown}
            readOnly={readOnly}
            background={background}
            textColor={textColor}
            withValue={!!value}
          >
            <ReziseHandleWrapper onMouseDown={handleMouseDown}>
              <StyledInput>
                <SlateEditor
                  autoFocus={autoFocus}
                  className={inputClassName}
                  editor={editor}
                  value={initialValue}
                  placeholder={placeholder}
                  readOnly={readOnly}
                  renderElement={renderElement}
                  focused={focused}
                  onKeyDown={handleKeyDown}
                  onFocus={handleFocus}
                  onTabFocus={handleTabFocus}
                  onTabKey={handleTabKey}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </StyledInput>
            </ReziseHandleWrapper>
          </StyledTextAreaWrapper>
        </FieldWrapper>

        {createPortal(
          <CSSTransition
            in={
              (mentions &&
                mentions.enabled &&
                mentions.target &&
                mentions.results.length > 0) ??
              undefined
            }
            classNames="virtual-list"
            timeout={300}
            unmountOnExit
          >
            <StyledVirtualList
              ref={virtualListRef}
              // @ts-expect-error VirtualList is not typed
              items={mentions.results}
              defaultHighlightedItem={0}
              onSelection={handleSelection}
              withAvatar={mentionsWithAvatar}
              field={mentionsValueField}
            />
          </CSSTransition>,
          document.body,
        )}
      </StyledInputContainer>
    );
  },
);
