import { Action } from 'redux';

import { Milestone } from '@float/types';

export const MILESTONES_LOAD_START = 'milestones/LOAD_START';
export const MILESTONES_LOAD_FAILED = 'milestones/LOAD_FAILED';
export const MILESTONES_LOAD_FINISH = 'milestones/LOAD_FINISH';
export const PROJECT_MILESTONES_LOAD_FINISH =
  'milestones/PROJECT_MILESTONES_LOAD_FINISH';
export const MILESTONES_BULK_UPDATE = 'milestones/BULK_UPDATE';
export const MILESTONES_UPDATE_START = 'milestones/UPDATE_START';
export const MILESTONES_UPDATE_FAILED = 'milestones/UPDATE_FAILED';
export const MILESTONES_UPDATE_FINISH = 'milestones/UPDATE_FINISH';
export const MILESTONES_CREATE_FINISH = 'milestones/CREATE_FINISH';
export const MILESTONES_REMOVE_START = 'milestones/REMOVE_START';
export const MILESTONES_REMOVE_FAILED = 'milestones/REMOVE_FAILED';
export const MILESTONES_REMOVE_FINISH = 'milestones/REMOVE_FINISH';

export type MilestoneLoadStartAction = Action<typeof MILESTONES_LOAD_START>;
export type MilestoneLoadFailedAction = Action<typeof MILESTONES_LOAD_FAILED>;
export type MilestoneLoadFinishAction = Action<
  typeof MILESTONES_LOAD_FINISH
> & {
  milestones: Milestone[];
};
export type ProjectMilestonesLoadFinishAction = Action<
  typeof PROJECT_MILESTONES_LOAD_FINISH
> & {
  milestones: Milestone[];
};
export type MilestoneBulkUpdateAction = Action<
  typeof MILESTONES_BULK_UPDATE
> & {
  result: (Milestone & { milestone_name?: string })[];
};
export type MilestoneUpdateStartAction = Action<
  typeof MILESTONES_UPDATE_START
> & {
  milestone: MilestoneApiPayload;
};
export type MilestoneUpdateFailedAction = Action<
  typeof MILESTONES_UPDATE_FAILED
> & {
  milestone: Milestone;
};
export type MilestoneUpdateFinishAction = Action<
  typeof MILESTONES_UPDATE_FINISH
> & {
  milestone: Milestone;
};
export type MilestoneCreateFinishAction = Action<
  typeof MILESTONES_CREATE_FINISH
> & {
  milestone: Milestone;
  temporaryId?: number;
};
export type MilestoneRemoveStartAction = Action<
  typeof MILESTONES_REMOVE_START
> & {
  milestoneId: number;
};
export type MilestoneRemoveFailedAction = Action<
  typeof MILESTONES_REMOVE_FAILED
> & {
  milestone: Milestone;
};
export type MilestoneRemoveFinishAction = Action<
  typeof MILESTONES_REMOVE_FINISH
>;
export type MilestoneAction =
  | MilestoneLoadStartAction
  | MilestoneLoadFailedAction
  | MilestoneLoadFinishAction
  | ProjectMilestonesLoadFinishAction
  | MilestoneBulkUpdateAction
  | MilestoneUpdateStartAction
  | MilestoneUpdateFailedAction
  | MilestoneUpdateFinishAction
  | MilestoneCreateFinishAction
  | MilestoneRemoveStartAction
  | MilestoneRemoveFailedAction
  | MilestoneRemoveFinishAction;

export type MilestoneModalPayload = {
  id?: number;
  temporaryId?: number;
  milestone_id?: number;
  project_id: number;
  phase_id?: number;
  name: string;
  description?: string;
  date?: string;
  startDate?: string;
  start_date?: string;
  endDate?: string;
  end_date?: string;
};

export type MilestoneApiPayload = {
  id: number | undefined;
  milestone_id: number | undefined;
  name: string;
  project_id: number;
  phase_id: number | undefined;
  date: string | undefined;
  end_date: string | undefined;
};

export type MilestoneModalEditPayload = Omit<
  MilestoneModalPayload,
  'milestone_id' | 'project_id'
> & {
  project_id: string | number;
  milestone_id: number;
  force_skip_af?: boolean;
};
