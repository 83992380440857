import { get } from 'lodash';

const isPlaceholder = (person) => {
  return person.people_type_id == 3;
};

export const stringCompare = (a, b) => (a > b ? 1 : a === b ? 0 : -1);

export const sortPeople = (sorter) => (a, b) => {
  const first = sorter.dir === 'asc' ? a : b;
  const second = sorter.dir === 'asc' ? b : a;

  if (sorter.type === 'job_title') {
    const val1 = first.job_title;
    const val2 = second.job_title;

    if (val1 && !val2) return -1;
    if (!val1 && val2) return 1;
    const result = val1 && val2 && val1.localeCompare(val2);
    if (result) return result;
  }

  if (sorter.type === 'department') {
    const val1 = get(first, 'department.name');
    const val2 = get(second, 'department.name');

    if (val1 && !val2) return -1;
    if (!val1 && val2) return 1;
    const result = val1 && val2 && val1.localeCompare(val2);
    if (result) return result;
  }

  // Note that we use a and b instead of first and second here to force
  // placeholders to the bottom regardless of sort direction.
  if (isPlaceholder(a) && !isPlaceholder(b)) {
    return 1;
  } else if (!isPlaceholder(a) && isPlaceholder(b)) {
    return -1;
  }

  if (sorter.type === 'avail' && sorter.availabilities) {
    // Note that avail desc means availability high to low, which actually means
    // we want the person with the largest number of free hours at the top,
    // which means we need to reverse the order of the comparison here.
    return (
      sorter.availabilities[first.people_id] -
      sorter.availabilities[second.people_id]
    );
  }

  return first.name.localeCompare(second.name);
};

export const sortPeopleCustom = (keys) => (a, b) => {
  for (const key of keys) {
    const first = get(a, key);
    const second = get(b, key);

    if (first != null && second != null && first !== second) {
      const firstType = typeof first;

      if (firstType === 'string') {
        return first.localeCompare(second);
      } else if (firstType === 'number') {
        return first - second;
      } else if (firstType === 'boolean') {
        if (first === second) {
          return 0;
        } else if (first) {
          return -1;
        } else if (second) {
          return 1;
        }
      }
    }
  }

  return 0;
};

export const sortProjects = (projects, dir, type, dates) => {
  const getSortKey = (p) => {
    const sortVal = (() => {
      if (type === 'client_name') {
        return p.client_name.toLowerCase();
      }

      if (type === 'start_date') {
        return dates[p.project_id] && dates[p.project_id].start_date;
      }

      if (type === 'end_date') {
        return dates[p.project_id] && dates[p.project_id].end_date;
      }

      return '';
    })();

    return `${sortVal}${(p.project_name || '').toLowerCase()}`;
  };

  const keys = new Map();

  for (const project of projects) {
    keys.set(project.project_id, getSortKey(project));
  }

  const modifier = dir === 'desc' ? -1 : 1;

  return projects.sort((a, b) => {
    const compare = stringCompare(
      keys.get(a.project_id),
      keys.get(b.project_id),
    );

    return compare * modifier;
  });
};
