import React, { memo } from 'react';

import { StyledDotCalloutContainer } from './styles';

export const DotCallout = memo((props) => {
  const { className, style } = props;

  return (
    <StyledDotCalloutContainer className={className} style={style}>
      <div />
      <div />
    </StyledDotCalloutContainer>
  );
});
