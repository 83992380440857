import { useState } from 'react';
import { toFriendlyTime } from '@floatschedule/activity-format-npm';

import { addMinutes } from '@float/common/lib/time';

import { getHoursPerDay } from '../helpers/getHoursPerDay';
import { InputAllocationTimeRangeProps } from '../InputAllocationTimeRange';

const MINUTES_IN_ONE_HOUR = 60;
const DEFAULT_START_TIME = '10:00';

export type UseInputAllocationTimeRangeParams = Pick<
  InputAllocationTimeRangeProps,
  'startTime' | 'hoursPerDay' | 'is24HoursTimeFormat' | 'onChange'
>;

export const useInputAllocationTimeRange = ({
  startTime,
  hoursPerDay,
  is24HoursTimeFormat,
  onChange,
}: UseInputAllocationTimeRangeParams) => {
  const [startTimeInternalValue, setStartTimeInternalValue] = useState(
    startTime || DEFAULT_START_TIME,
  );

  const [endTimeInternalValue, setEndTimeInternalValue] = useState(() => {
    // Number of minutes needs to be a whole number
    const minutesAfterStartTime = Math.round(
      (hoursPerDay || 0) * MINUTES_IN_ONE_HOUR,
    );

    return addMinutes(startTimeInternalValue, minutesAfterStartTime);
  });

  const [hasEndTimeManuallyChanged, setHasEndTimeManuallyChanged] =
    useState(false);

  const startTimeInternalValueFormatted = toFriendlyTime(
    startTimeInternalValue,
    is24HoursTimeFormat,
  );
  const endTimeInternalValueFormatted = toFriendlyTime(
    endTimeInternalValue,
    is24HoursTimeFormat,
  );

  const handleStartTimeChange = (value: string | null) => {
    if (!value || value === startTimeInternalValue) return;

    let startTimeValue = value;
    let endTimeValue = endTimeInternalValue;

    // Start time can't be later than 23:58
    if (startTimeValue > '23:58') startTimeValue = '23:58';

    // Add an hour to start time
    if (!hasEndTimeManuallyChanged) {
      // Number of minutes needs to be a whole number
      const minutesAfterStartTime = Math.round(
        (hoursPerDay || 0) * MINUTES_IN_ONE_HOUR,
      );

      endTimeValue = addMinutes(startTimeValue, minutesAfterStartTime);
    }

    // Check the end time is same or before start time
    if (endTimeValue <= startTimeValue)
      endTimeValue = addMinutes(startTimeValue, 1);

    const hoursPerDayUpdated = getHoursPerDay({
      startTime: startTimeValue,
      endTime: endTimeValue,
    });

    setStartTimeInternalValue(startTimeValue);
    setEndTimeInternalValue(endTimeValue);

    onChange({
      startTime: startTimeValue,
      endTime: endTimeValue,
      hoursPerDay: hoursPerDayUpdated,
    });
  };

  const handleEndTimeChange = (value: string | null) => {
    if (!value || value === endTimeInternalValue) return;

    let startTimeValue = startTimeInternalValue;
    let endTimeValue = value;

    // End time can't be before 00:01, to allow at least a minute to a range
    if (endTimeValue < '00:01') endTimeValue = '00:01';

    if (endTimeValue <= startTimeValue)
      startTimeValue = addMinutes(endTimeValue, -1);

    const hoursPerDay = getHoursPerDay({
      startTime: startTimeValue,
      endTime: endTimeValue,
    });

    setStartTimeInternalValue(startTimeValue);
    setEndTimeInternalValue(endTimeValue);

    setHasEndTimeManuallyChanged(
      !hasEndTimeManuallyChanged && endTimeValue !== endTimeInternalValue,
    );

    onChange({
      startTime: startTimeValue,
      endTime: endTimeValue,
      hoursPerDay,
    });
  };

  return {
    startTimeInternalValue,
    startTimeInternalValueFormatted,
    endTimeInternalValue,
    endTimeInternalValueFormatted,
    handleStartTimeChange,
    handleEndTimeChange,
  };
};
