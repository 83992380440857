import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  CellMeasurer,
  CellMeasurerCache,
} from '@floatschedule/react-virtualized';
import useUpdateEffect from 'react-use/esm/useUpdateEffect';

import { StyledList, StyledListWrapper } from './styles';
import VirtualListItem from './VirtualListItem';

const VirtualList = forwardRef((props, ref) => {
  const {
    className,
    appearance = 'flue',
    disabled = false,
    padding = 6,
    items = [],
    itemHeight = 40,
    itemGutter = 4,
    visibleItems = 6,
    defaultSelectedItem = -1,
    defaultHighlightedItem = -1,
    highlightOnHover = false,
    onSelection,
    withAvatar = false,
    field,
  } = props;

  // ---------------------
  // Refs
  // ---------------------

  const listWrapperRef = useRef();
  const listRef = useRef();
  const cellMeasurerCache = useRef(
    new CellMeasurerCache({
      defaultHeight: itemHeight,
      fixedWidth: true,
    }),
  );

  // ---------------------
  // State
  // ---------------------

  const [mouseEnabled, setMouseEnabled] = useState(false);

  const [highlightedItem, setHighlightedItem] = useState(
    defaultHighlightedItem,
  );

  // ---------------------
  // Navigation
  // ---------------------

  const goTo = useCallback(
    (index) => {
      const next = index % items.length;

      setHighlightedItem(next);

      return next;
    },
    [items],
  );

  const next = useCallback(() => {
    setMouseEnabled(false);

    return goTo(highlightedItem + 1);
  }, [goTo, highlightedItem]);

  const previous = useCallback(() => {
    setMouseEnabled(false);

    return goTo(highlightedItem <= 0 ? items.length - 1 : highlightedItem - 1);
  }, [highlightedItem, goTo, items]);

  const enableMouse = useCallback(() => {
    setMouseEnabled(true);

    window.removeEventListener('mousemove', enableMouse);
  }, []);

  // ---------------------
  // Item
  // ---------------------

  const onItemHover = useCallback(
    (item, index) => {
      goTo(index);
    },
    [goTo],
  );

  const onItemLeave = useCallback(() => {
    if (!highlightOnHover && mouseEnabled) goTo(-1);
  }, [highlightOnHover, mouseEnabled, goTo]);

  const onItemClick = useCallback(
    (item, index, e) => {
      if (onSelection) onSelection(item, index, e);
    },
    [onSelection],
  );

  const itemRenderer = useCallback(
    ({ index, key, parent, style }) => {
      const selected = defaultSelectedItem === index;
      const highlighted = highlightedItem === index;

      const itemStyles = {
        '--item-gutter': index < items.length - 1 ? itemGutter : 0,
        ...style,
      };

      return (
        <CellMeasurer
          key={key}
          cache={cellMeasurerCache.current}
          columnIndex={0}
          parent={parent}
          rowIndex={index}
        >
          {({ registerChild }) => (
            <VirtualListItem
              ref={registerChild}
              style={itemStyles}
              index={index}
              item={items[index]}
              selected={selected}
              highlighted={highlighted}
              height={itemHeight}
              withAvatar={withAvatar}
              field={field}
              onHover={onItemHover}
              onLeave={onItemLeave}
              onClick={onItemClick}
            />
          )}
        </CellMeasurer>
      );
    },
    [
      defaultSelectedItem,
      highlightedItem,
      items,
      onItemHover,
      onItemLeave,
      onItemClick,
      itemHeight,
      itemGutter,
      withAvatar,
      field,
    ],
  );

  // ---------------------
  // Effects
  // ---------------------

  useImperativeHandle(ref, () => ({
    next,
    previous,
    goTo,
    select: () => {
      onItemClick(items[highlightedItem], highlightedItem);
    },
    el: listWrapperRef.current,
  }));

  useEffect(() => {
    cellMeasurerCache.current.clearAll();
  }, [itemHeight, itemGutter]);

  useUpdateEffect(() => {
    setHighlightedItem(0);
  }, [items.length]);

  useUpdateEffect(() => {
    const isOutOfBounds =
      highlightedItem < 0 || highlightedItem > items.length - 1;

    if (!mouseEnabled && !isOutOfBounds) {
      const increment = itemHeight + itemGutter;
      const offset = listRef.current.getOffsetForRow({
        index: highlightedItem,
      });
      const offsetIndex = offset / increment;

      let index;

      // make sure it snaps to the position
      if (offsetIndex !== highlightedItem) {
        index = Math.ceil(offsetIndex);
      } else {
        index = highlightedItem;
      }

      listRef.current.scrollToPosition(index * increment);
    }
  }, [highlightedItem]);

  useEffect(() => {
    // this is needed to fix VirtualListItems loosing focus while typing
    // this happens, for example, when listing Mentions suggestions on the
    // RichText component if the mouse sits on top of the VirtualList, on
    // some browsers, during typing the onMouseEnter and onMouseLeave events
    // (from VirtualListItems) will fire unexpectedly.
    //
    // the only way I managed to fix it was to disable the VirtualList while
    // typing and enable as soon the mouse moves
    if (!mouseEnabled) {
      window.addEventListener('mousemove', enableMouse);
    }

    return () => {
      window.removeEventListener('mousemove', enableMouse);
    };
  }, [mouseEnabled, enableMouse]);

  // ---------------------
  // Calc
  // ---------------------

  const getHeight = useMemo(() => {
    const totalItems = (items && items.length) || 0;

    const total =
      visibleItems > 0 && visibleItems < totalItems ? visibleItems : totalItems;

    return total * (itemHeight + itemGutter) - itemGutter + padding * 2;
  }, [items, itemHeight, itemGutter, padding, visibleItems]);

  // ---------------------
  // Render
  // ---------------------

  return (
    <StyledListWrapper
      ref={listWrapperRef}
      className={className}
      $appearance={appearance}
      $disabled={disabled || !mouseEnabled}
    >
      <StyledList
        style={{
          '--padding': padding,
        }}
        ref={listRef}
        tabIndex={null}
        width={
          (listWrapperRef.current && listWrapperRef.current.clientWidth) || 220
        }
        height={getHeight}
        deferredMeasurementCache={cellMeasurerCache.current}
        rowCount={(items && items.length) || 0}
        rowHeight={cellMeasurerCache.current.rowHeight}
        rowRenderer={itemRenderer}
      />
    </StyledListWrapper>
  );
});

export default VirtualList;
