import { getPeopleByDepartment, getSubdepartments } from 'selectors';
import {
  getAccounts,
  getManagersByPeopleId,
  getPeopleMapRaw,
  isActiveAndNotSelf,
} from 'selectors/people';

import { ReduxState } from '@float/common/reducers/lib/types';
import { useAppSelector } from '@float/common/store';
import { Account, Person } from '@float/types';

export type ManagerDraft = {
  account_type: Account['account_type'];
  management_group?: Account['management_group'];
  account_id?: Account['account_id'];
};

// This hook can be used during the account creation, so some fields from account may be missing
// e.g. like account_id
export function useDirectManagedPeople(
  manager: ManagerDraft,
  // This parameter should be provided when updating a person that doesn't have
  // already an associated account
  managerPeopleId?: number,
) {
  const accounts: ReduxState['accounts']['accounts'] =
    useAppSelector(getAccounts);
  const people: Record<number, Person> = useAppSelector(getPeopleMapRaw);
  const subDepartments = useAppSelector(getSubdepartments);
  const peopleByDepartment: Record<number, Person[]> = useAppSelector(
    getPeopleByDepartment,
  );
  const managersByPeopleId: Record<number, number[]> = useAppSelector(
    getManagersByPeopleId,
  );

  const managed: Account['management_group'] = Object.assign(
    { departments: [], people: [] },
    manager.management_group,
  );

  const managedDepartments = new Set(
    managed.departments.concat(
      managed.departments.flatMap((depId) => subDepartments[depId]),
    ),
  );

  const managedPeopleViaDepartment = new Map<number, Person>();

  for (const departmentId of managedDepartments) {
    for (const person of peopleByDepartment[departmentId] || []) {
      if (
        isActiveAndNotSelf(manager, person) &&
        person.people_id !== managerPeopleId
      ) {
        const managers = managersByPeopleId[person.people_id] || [];

        // If the person has a direct manager it doesn't count in the
        // people managed by department
        const hasDirectManager = managers.some((managerId) => {
          const personManager = accounts[managerId];
          const managedPeople = personManager?.management_group?.people || [];

          return managedPeople.some((personId) => {
            return person.people_id === personId;
          });
        });

        if (!hasDirectManager) {
          managedPeopleViaDepartment.set(person.people_id, person);
        }
      }
    }
  }

  const peopleManagedDirectly = managed.people.filter((peopleId) => {
    const person = people[peopleId];
    if (!person) {
      return false;
    }

    const canDisplayPerson =
      isActiveAndNotSelf(manager, person) &&
      person.people_id !== managerPeopleId;

    if (!canDisplayPerson) {
      return false;
    }

    const isManagedViwDepartment = managedPeopleViaDepartment.has(
      person.people_id,
    );

    if (isManagedViwDepartment) {
      return false;
    }

    return true;
  });

  return {
    allDepartments: Array.from(managedDepartments),
    departments: managed.departments,
    people: {
      departments: Array.from(managedPeopleViaDepartment.values()),
      people: peopleManagedDirectly,
    },
  };
}
