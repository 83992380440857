import {
  ACCOUNTS_DELETE,
  ACCOUNTS_UPDATE,
} from '@float/common/actions/accounts';
import {
  ADD_CLIENT_SUCCESS,
  CLIENTS_BULK_UPDATED,
  DELETE_CLIENT_SUCCESS,
  UPDATE_CLIENT_SUCCESS,
} from '@float/common/actions/clients';
import { UPDATE_COMPANY_PREFS_SUCCESS } from '@float/common/actions/companyPrefs';
import {
  ADD_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_SUCCESS,
  DEPARTMENTS_BULK_UPDATED,
  UPDATE_DEPARTMENT_SUCCESS,
} from '@float/common/actions/departments';
import {
  ADD_HOLIDAY_SUCCESS,
  DELETE_HOLIDAY_SUCCESS,
  UPDATE_HOLIDAY_SUCCESS,
} from '@float/common/actions/holidays';
import {
  LOGGED_TIME_CREATED,
  LOGGED_TIME_DELETED,
} from '@float/common/actions/loggedTimes';
import {
  MILESTONES_BULK_UPDATE,
  MILESTONES_REMOVE_START,
  MILESTONES_UPDATE_START,
} from '@float/common/actions/milestones';
import { ONEOFF_CREATED, ONEOFF_DELETED } from '@float/common/actions/oneOffs';
import {
  PEOPLE_BULK_DELETED,
  PEOPLE_BULK_UPDATED,
  PEOPLE_DELETED,
  PEOPLE_IMPORTED,
  PEOPLE_UPDATED,
} from '@float/common/actions/people';
import {
  PHASES_BULK_UPDATED,
  PHASES_DELETED,
  PHASES_UPDATED,
} from '@float/common/actions/phases/phases';
import {
  PROJECTS_BULK_CLONED,
  PROJECTS_BULK_DELETED,
  PROJECTS_BULK_UPDATED,
  PROJECTS_DATES_UPDATED,
  PROJECTS_DELETED,
  PROJECTS_IMPORTED,
  PROJECTS_UPDATED,
  TEMPLATES_DELETED,
  TEMPLATES_UPDATED,
} from '@float/common/actions/projects';
import { STATUS_DELETED, STATUS_UPDATED } from '@float/common/actions/statuses';
import { STATUS_TYPE_UPDATED } from '@float/common/actions/statusTypes';
import {
  TAGS_CREATED,
  TAGS_DELETED,
  TAGS_UPDATED,
} from '@float/common/actions/tags';
import {
  BATCH_DELETE_TASK_SUCCESS,
  BATCH_UPSERT_TASK_SUCCESS,
  CREATE_TASK_META_SUCCESS,
  CREATE_TASK_SUCCESS,
  DELETE_TASK_META_SUCCESS,
  DELETE_TASK_SUCCESS,
  INSERT_TASK_SUCCESS,
  MERGE_TASK_META_SUCCESS,
  REPLACE_TASK_SUCCESS,
  UPDATE_TASK,
  UPDATE_TASK_META_SUCCESS,
} from '@float/common/actions/tasks';
import {
  ADD_TIMEOFF_TYPE_SUCCESS,
  CREATE_TIMEOFF_SUCCESS,
  DELETE_TIMEOFF_SUCCESS,
  DELETE_TIMEOFF_TYPE_SUCCESS,
  INSERT_TIMEOFF_SUCCESS,
  REPLACE_TIMEOFF_SUCCESS,
  UPDATE_TIMEOFF,
  UPDATE_TIMEOFF_TYPE_SUCCESS,
} from '@float/common/actions/timeoffsConstants';
import { BUDGETS_LOAD_FINISH } from '@float/common/store/budgets/budgets.actions';

import {
  ASANA_IMPORT_SUCCESS,
  FETCH_ASANA_CO_INTS_SUCCESS,
  FETCH_ASANA_PRELOAD_FAILURE,
  FETCH_ASANA_PRELOAD_SUCCESS,
} from '../integrations/actions/asana';
import { FETCH_EXT_CALENDARS_SUCCESS } from '../integrations/actions/calendar';
import {
  FETCH_JIRA_CO_INTS_SUCCESS,
  FETCH_JIRA_PRELOAD_FAILURE,
  FETCH_JIRA_PRELOAD_SUCCESS,
  JIRA_IMPORT_SUCCESS,
} from '../integrations/actions/jira';
import {
  FETCH_TEAMWORK_CO_INTS_SUCCESS,
  FETCH_TEAMWORK_PRELOAD_FAILURE,
  FETCH_TEAMWORK_PRELOAD_SUCCESS,
  TEAMWORK_IMPORT_SUCCESS,
} from '../integrations/actions/teamwork';
import {
  FETCH_TRELLO_CO_INTS_SUCCESS,
  FETCH_TRELLO_PRELOAD_FAILURE,
  FETCH_TRELLO_PRELOAD_SUCCESS,
  TRELLO_IMPORT_SUCCESS,
} from '../integrations/actions/trello';
import {
  ITEMS_LIVE_UPDATE,
  TASK_LINKS_LIVE_UPDATE,
} from '../pmSidebar/actions';

export const VALID_ENTITY_TYPES = [
  'company',
  'role',
  'project',
  'people',
  'person',
  'timeoff',
  'department',
  'client',
  'timeoff_type',
  'holidays',
  'task',
  'tags',
  'oneoff_days',
  'milestones',
  'ext_calendar',
  'company_integration_teamwork',
  'company_integration_jira',
  'company_integration_asana',
  'company_integration_trello',
  'status',
  'status_type_co',
  'logged_time',
  'task_link',
  'sidebar_item',
  'project_dates',
  'task_meta',
  'phase',
  'budget_utilization',
  'account_co',
  'log_time_lock',
  'project_template',
  'view',
  'role',
];

export const ACTION_TYPES = {
  COMPANY_UPDATE: UPDATE_COMPANY_PREFS_SUCCESS,
  PROJECT_CREATE: PROJECTS_UPDATED,
  PROJECT_UPDATE: PROJECTS_UPDATED,
  PROJECT_DELETE: PROJECTS_DELETED,
  PROJECT_IMPORT: PROJECTS_IMPORTED,
  PROJECT_DUPLICATE: PROJECTS_UPDATED,
  PROJECT_DATES_UPDATE: PROJECTS_DATES_UPDATED,
  PROJECT_BULK_UPDATE: PROJECTS_BULK_UPDATED,
  PROJECT_BULK_CLONE: PROJECTS_BULK_CLONED,
  PROJECT_BULK_DELETE: PROJECTS_BULK_DELETED,
  PROJECT_TEMPLATE_CREATE: TEMPLATES_UPDATED,
  PROJECT_TEMPLATE_UPDATE: TEMPLATES_UPDATED,
  PROJECT_TEMPLATE_DELETE: TEMPLATES_DELETED,
  PEOPLE_CREATE: PEOPLE_UPDATED,
  PEOPLE_UPDATE: PEOPLE_UPDATED,
  PEOPLE_UPDATE_VIA_PROJECT: PEOPLE_UPDATED,
  PEOPLE_DELETE: PEOPLE_DELETED,
  PEOPLE_IMPORT: PEOPLE_IMPORTED,
  PEOPLE_BULK_UPDATE: PEOPLE_BULK_UPDATED,
  PEOPLE_BULK_DELETE: PEOPLE_BULK_DELETED,
  TIMEOFF_CREATE: CREATE_TIMEOFF_SUCCESS,
  TIMEOFF_UPDATE: UPDATE_TIMEOFF,
  TIMEOFF_DELETE: DELETE_TIMEOFF_SUCCESS,
  DEPARTMENT_CREATE: ADD_DEPARTMENT_SUCCESS,
  DEPARTMENT_UPDATE: UPDATE_DEPARTMENT_SUCCESS,
  DEPARTMENT_BULK_UPDATE: DEPARTMENTS_BULK_UPDATED,
  DEPARTMENT_DELETE: DELETE_DEPARTMENT_SUCCESS,
  CLIENT_CREATE: ADD_CLIENT_SUCCESS,
  CLIENT_UPDATE: UPDATE_CLIENT_SUCCESS,
  CLIENT_BULK_UPDATE: CLIENTS_BULK_UPDATED,
  CLIENT_DELETE: DELETE_CLIENT_SUCCESS,
  TIMEOFF_TYPE_CREATE: ADD_TIMEOFF_TYPE_SUCCESS,
  TIMEOFF_TYPE_UPDATE: UPDATE_TIMEOFF_TYPE_SUCCESS,
  TIMEOFF_TYPE_DELETE: DELETE_TIMEOFF_TYPE_SUCCESS,
  HOLIDAYS_CREATE: ADD_HOLIDAY_SUCCESS,
  HOLIDAYS_UPDATE: UPDATE_HOLIDAY_SUCCESS,
  HOLIDAYS_DELETE: DELETE_HOLIDAY_SUCCESS,
  TASK_CREATE: CREATE_TASK_SUCCESS,
  TASK_UPDATE: UPDATE_TASK,
  TASK_DELETE: DELETE_TASK_SUCCESS,
  TASK_META_CREATE: CREATE_TASK_META_SUCCESS,
  TASK_META_UPDATE: UPDATE_TASK_META_SUCCESS,
  TASK_META_DELETE: DELETE_TASK_META_SUCCESS,
  TASK_META_MERGE: MERGE_TASK_META_SUCCESS,
  TAGS_CREATE: TAGS_CREATED,
  TAGS_UPDATE: TAGS_UPDATED,
  TAGS_DELETE: TAGS_DELETED,
  ONEOFF_DAYS_CREATE: ONEOFF_CREATED,
  ONEOFF_DAYS_DELETE: ONEOFF_DELETED,
  TASK_INSERT: INSERT_TASK_SUCCESS,
  TASK_REPLACE: REPLACE_TASK_SUCCESS,
  TIMEOFF_INSERT: INSERT_TIMEOFF_SUCCESS,
  TIMEOFF_REPLACE: REPLACE_TIMEOFF_SUCCESS,
  MILESTONES_CREATE: MILESTONES_UPDATE_START,
  MILESTONES_REPLACE: MILESTONES_UPDATE_START,
  MILESTONES_UPDATE: MILESTONES_UPDATE_START,
  MILESTONES_DELETE: MILESTONES_REMOVE_START,
  MILESTONES_BULK_UPDATE: MILESTONES_BULK_UPDATE,
  TASK_BATCH_UPSERT: BATCH_UPSERT_TASK_SUCCESS,
  TASK_BATCH_DELETE: BATCH_DELETE_TASK_SUCCESS,

  // Integrations
  // - ext calendars
  EXT_CALENDAR_SYNC_COMPLETE: FETCH_EXT_CALENDARS_SUCCESS,
  // - jira
  COMPANY_INTEGRATION_JIRA_PRELOAD_COMPLETE: FETCH_JIRA_PRELOAD_SUCCESS,
  COMPANY_INTEGRATION_JIRA_PRELOAD_FAILED: FETCH_JIRA_PRELOAD_FAILURE,
  COMPANY_INTEGRATION_JIRA_IMPORT_COMPLETE: JIRA_IMPORT_SUCCESS,
  COMPANY_INTEGRATION_JIRA_COINT_CHANGED: FETCH_JIRA_CO_INTS_SUCCESS,
  // - teamwork
  COMPANY_INTEGRATION_TEAMWORK_SYNC_COMPLETE: FETCH_TEAMWORK_CO_INTS_SUCCESS,
  COMPANY_INTEGRATION_TEAMWORK_PRELOAD_COMPLETE: FETCH_TEAMWORK_PRELOAD_SUCCESS,
  COMPANY_INTEGRATION_TEAMWORK_PRELOAD_FAILED: FETCH_TEAMWORK_PRELOAD_FAILURE,
  COMPANY_INTEGRATION_TEAMWORK_IMPORT_COMPLETE: TEAMWORK_IMPORT_SUCCESS,
  COMPANY_INTEGRATION_TEAMWORK_COINT_CHANGED: FETCH_TEAMWORK_CO_INTS_SUCCESS,
  // - asana
  COMPANY_INTEGRATION_ASANA_SYNC_COMPLETE: FETCH_ASANA_CO_INTS_SUCCESS,
  COMPANY_INTEGRATION_ASANA_PRELOAD_COMPLETE: FETCH_ASANA_PRELOAD_SUCCESS,
  COMPANY_INTEGRATION_ASANA_PRELOAD_FAILED: FETCH_ASANA_PRELOAD_FAILURE,
  COMPANY_INTEGRATION_ASANA_IMPORT_COMPLETE: ASANA_IMPORT_SUCCESS,
  COMPANY_INTEGRATION_ASANA_COINT_CHANGED: FETCH_ASANA_CO_INTS_SUCCESS,
  // - trello
  COMPANY_INTEGRATION_TRELLO_SYNC_COMPLETE: FETCH_TRELLO_CO_INTS_SUCCESS,
  COMPANY_INTEGRATION_TRELLO_PRELOAD_COMPLETE: FETCH_TRELLO_PRELOAD_SUCCESS,
  COMPANY_INTEGRATION_TRELLO_PRELOAD_FAILED: FETCH_TRELLO_PRELOAD_FAILURE,
  COMPANY_INTEGRATION_TRELLO_IMPORT_COMPLETE: TRELLO_IMPORT_SUCCESS,
  COMPANY_INTEGRATION_TRELLO_COINT_CHANGED: FETCH_TRELLO_CO_INTS_SUCCESS,

  STATUS_CREATE: STATUS_UPDATED,
  STATUS_UPDATE: STATUS_UPDATED,
  STATUS_REPLACE: STATUS_UPDATED,
  STATUS_DELETE: STATUS_DELETED,
  STATUS_TYPE_CO_CREATE: STATUS_TYPE_UPDATED,
  STATUS_TYPE_CO_UPDATE: STATUS_TYPE_UPDATED,
  LOGGED_TIME_CREATE: LOGGED_TIME_CREATED,
  LOGGED_TIME_UPDATE: LOGGED_TIME_CREATED,
  LOGGED_TIME_DELETE: LOGGED_TIME_DELETED,
  TASK_LINK_CREATE: TASK_LINKS_LIVE_UPDATE,
  TASK_LINK_UPDATE: TASK_LINKS_LIVE_UPDATE,
  TASK_LINK_DELETE: TASK_LINKS_LIVE_UPDATE,
  SIDEBAR_ITEM_CREATE: ITEMS_LIVE_UPDATE,
  SIDEBAR_ITEM_UPDATE: ITEMS_LIVE_UPDATE,
  SIDEBAR_ITEM_DELETE: ITEMS_LIVE_UPDATE,
  PHASE_CREATE: PHASES_UPDATED,
  PHASE_UPDATE: PHASES_UPDATED,
  PHASE_DELETE: PHASES_DELETED,
  PHASES_BULK_UPDATE: PHASES_BULK_UPDATED,
  BUDGET_UTILIZATION_UPDATE: BUDGETS_LOAD_FINISH,
  ACCOUNT_CO_CREATE: ACCOUNTS_UPDATE,
  ACCOUNT_CO_UPDATE: ACCOUNTS_UPDATE,
  ACCOUNT_CO_DELETE: ACCOUNTS_DELETE,
};
