import React from 'react';
import { t, Trans } from '@lingui/macro';

import { NavIconBtn } from '@float/common/components/NavIconBtn';
import { List } from '@float/ui/primitives/List';

import NoSavedViewsEmptyState from '../assets/NoSavedViewsEmptyState.png';
import NoSavedViewsEmptyState2x from '../assets/NoSavedViewsEmptyState@2x.png';

import * as styles from './NoViewsScreen.css';

type NoViewsScreenProps = {
  onAddFilterClick: () => void;
};

export const NoViewsScreen = React.forwardRef<
  HTMLDivElement,
  NoViewsScreenProps
>(({ onAddFilterClick, ...props }, ref) => {
  return (
    <List.Box {...props} ref={ref} className={styles.container}>
      <img
        width="170"
        height="120"
        srcSet={`${NoSavedViewsEmptyState} 1x, ${NoSavedViewsEmptyState2x} 2x`}
        src={NoSavedViewsEmptyState}
        alt={t`No saved Views`}
      />
      <div className={styles.title}>
        <Trans>No saved Views</Trans>
      </div>
      <div className={styles.description}>
        <Trans>
          Create your first View by adding a filter, then clicking “Save”
        </Trans>
      </div>
      <NavIconBtn isPrimary onClick={onAddFilterClick}>
        <Trans>Add a filter</Trans>
      </NavIconBtn>
    </List.Box>
  );
});
