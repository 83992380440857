import React from 'react';

import { Modal } from '../Modal';
import ProgressBar from './ProgressBar';

const ProgressModal = ({
  title = 'Please wait ...',
  progress,
  height = 181,
  autotune = true,
  onClose,
}) => {
  return (
    <Modal
      noAnimation
      isOpen
      shouldCloseOnEsc={false}
      shouldCloseOnBgClick={false}
      onClose={onClose}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          minHeight: height,
          alignItems: 'center',
          display: 'flex',
          paddingTop: 0,
        }}
      >
        <ProgressBar
          value={progress}
          autotune={autotune}
          style={{ margin: 'auto' }}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ProgressModal;
