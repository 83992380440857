import { useReducer } from 'react';

import { measureInteractivity } from '../perfMonitoring/interactivity';
import { dispatchViewLoad } from '../perfMonitoring/pageLoad';
import { ProjectsOverviewTableDataRaw } from './ProjectsOverview/parser/table/types';

export type RawTableData = ProjectsOverviewTableDataRaw & {
  tasks: unknown;
};
export type RawChartData = {
  datapoints: unknown;
  budgets: unknown;
  baseline: unknown;
};

type LoadError = Error & {
  code?: string;
  timeout?: boolean;
};

type State = {
  hasAttemptedFetch: boolean;
  isChartLoading: boolean;
  isTableLoading: boolean;
  isChartTimeout: boolean;
  isTableTimeout: boolean;
  chartError: null | LoadError;
  tableError: null | LoadError;
  rawChartData: null | RawChartData;
  rawTableData: null | RawTableData;
  showChartSpinner: boolean;
  showTableSpinner: boolean;
};

export type ReportsLocalReducerAction =
  | {
      type: 'START_FETCH' | 'SHOW_TABLE_SPINNER' | 'SHOW_CHART_SPINNER';
    }
  | {
      type: 'TABLE_LOAD_FINISH';
      data: RawTableData;
    }
  | {
      type: 'CHART_LOAD_FINISH';
      data: RawChartData;
    }
  | {
      type: 'TABLE_LOAD_FAIL' | 'CHART_LOAD_FAIL';
      err: LoadError;
    };

export const INITIAL_STATE: State = {
  hasAttemptedFetch: false,
  isChartLoading: false,
  isTableLoading: false,
  isChartTimeout: false,
  isTableTimeout: false,
  chartError: null,
  tableError: null,
  rawChartData: null,
  rawTableData: null,
  showChartSpinner: false,
  showTableSpinner: false,
};

function reportsStateReducer(state: State, action: ReportsLocalReducerAction) {
  if (action.type === 'START_FETCH') {
    return {
      ...INITIAL_STATE,
      hasAttemptedFetch: true,
      isChartLoading: true,
      isTableLoading: true,
      rawTableData: state.rawTableData,
      rawChartData: null,
    };
  }

  if (action.type === 'TABLE_LOAD_FINISH') {
    return {
      ...state,
      isTableLoading: false,
      rawTableData: action.data,
    };
  }

  if (action.type === 'CHART_LOAD_FINISH') {
    return {
      ...state,
      isChartLoading: false,
      rawChartData: action.data,
    };
  }

  if (action.type === 'TABLE_LOAD_FAIL') {
    const { err } = action;
    const timeout = !(
      ((err.code && err.code !== 'INVALID_DATE_RANGE') || !err.code) &&
      !err.timeout
    );

    return {
      ...state,
      isTableLoading: false,
      rawTableData: null,
      tableError: err,
      isTableTimeout: timeout,
    };
  }

  if (action.type === 'CHART_LOAD_FAIL') {
    const { err } = action;
    const timeout = !(
      ((err.code && err.code !== 'INVALID_DATE_RANGE') || !err.code) &&
      !err.timeout
    );

    return {
      ...state,
      isChartLoading: false,
      rawChartData: null,
      chartError: err,
      isChartTimeout: timeout,
    };
  }

  if (action.type === 'SHOW_TABLE_SPINNER') {
    return {
      ...state,
      showTableSpinner: true,
    };
  }

  if (action.type === 'SHOW_CHART_SPINNER') {
    return {
      ...state,
      showChartSpinner: true,
    };
  }

  throw Error(`Unknown action type [${action.type}]`);
}

function trackReportsLoadStart() {
  measureInteractivity.start('reports-data-load');
}

function trackReportsLoaded() {
  measureInteractivity.complete('reports-data-load');
  dispatchViewLoad('reports');
}

export function useReportsStateReducer() {
  const [state, dispatch] = useReducer(reportsStateReducer, INITIAL_STATE);

  function middleware(action: ReportsLocalReducerAction) {
    if (action.type === 'START_FETCH') {
      trackReportsLoadStart();
    }

    if (action.type === 'TABLE_LOAD_FINISH') {
      trackReportsLoaded();
    }

    return dispatch(action);
  }

  return [state, middleware] as const;
}
