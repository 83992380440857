import React from 'react';

import { getDisplayNotesExcerpt } from '@float/common/lib/notes';
import {
  normalizeTime,
  TIME_FORMAT,
  toFriendlyTime,
} from '@float/common/lib/time';
import { isFullDayTimeoff } from '@float/common/lib/timeoffs';
import { SerenaState } from '@float/common/selectors/serena';
import { RowMetaItem } from '@float/common/serena/Data/useRowMetas.helpers';
import { config } from '@float/libs/config';
import { CellItem, CompanyPreferences } from '@float/types';
import { Icons } from '@float/ui/deprecated';

import { addStartTimeElement } from './box/BoxElements/addStartTimeElement';
import { isArchived } from './box/utils/isArchived';

import * as styles from './EntityItemTooltip.css';

const getTotalHours = (hoursPd: number, length: number) =>
  parseFloat((hoursPd * length).toFixed(2));

const linkIneligibleReasons = {
  NOT_TASK: 'You can only link tasks together',
  IS_SELF: null,
  HAS_PARENT: 'This task already has a parent',
  IS_SYNCED: 'Synced tasks cannot be linked',
  IS_REPEAT: 'Repeating tasks cannot be linked',
  IS_IN_PAST: 'Tasks with an earlier start date cannot be linked',
  DIFF_PHASE: 'Linked tasks must be in the same phase',
  DIFF_PROJECT: 'Linked tasks must be in the same project',
};

export function getLinkIneligibleTooltip(
  error: keyof typeof linkIneligibleReasons,
) {
  return (
    <div className={styles.linkEligibilityTooltip}>
      {linkIneligibleReasons[error]}
    </div>
  );
}

export default function getTooltipContent(
  item: CellItem<'timeoff'> | CellItem<'task'>,
  rowMeta: RowMetaItem | undefined,
  reduxData: SerenaState,
  companyPrefs: CompanyPreferences,
) {
  const elements = [];
  if (item.type === 'timeoff') {
    const { entity } = item;

    const timeoffType = reduxData.timeoffTypes[entity.timeoff_type_id];
    if (timeoffType) {
      let name = timeoffType.timeoff_type_name;
      if (!timeoffType.active) {
        name = `${name} (Archived)`;
      }

      if (entity.start_time) {
        name = `${toFriendlyTime(entity.start_time, companyPrefs)} - ${name}`;
      }

      elements.push(name);
    }
  }

  if (item.type === 'task') {
    const { entity } = item;

    const project = reduxData.projects[entity.project_id];
    const phase = entity.phase_id
      ? reduxData.phases[entity.phase_id]
      : undefined;

    let clientElement = null;
    if (project) {
      clientElement =
        project.client_name !== 'No Client' ? project.client_name : null;
      if (isArchived(project, phase)) {
        if (clientElement) {
          clientElement += ' (Archived)';
        } else {
          clientElement = '(Archived)';
        }
      }
    }
    if (entity.name) {
      elements.push(entity.name);
    } else if (phase && phase.phase_name) {
      elements.push(phase.phase_name);
    }
    if (project && project.project_name) elements.push(project.project_name);
    if (clientElement) elements.push(clientElement);
    addStartTimeElement(entity, elements, companyPrefs);
  }

  const { entity } = item;

  let hours = 0;

  if (entity.hours) {
    hours = entity.hours;
  }

  if (isFullDayTimeoff(entity) && rowMeta && item.x) {
    hours = rowMeta.getDailyWorkHours(entity.start_date)[item.x];
  }

  // @entity.length
  const length = entity.length ?? 1;
  hours = getTotalHours(hours, length);
  let entityNotes = '';

  if ('notes' in entity && entity.notes) {
    entityNotes = entity.notes;
  } else if ('timeoff_notes' in entity && entity.timeoff_notes) {
    entityNotes = entity.timeoff_notes;
  }

  const integrationStatus =
    'integration_status' in entity ? entity.integration_status : undefined;
  const repeatState =
    'repeat_state' in entity ? entity.repeat_state : undefined;
  const rootTaskId =
    // @ts-expect-error root_task_id is defined as string, but we are using it as a number here
    'root_task_id' in entity ? (entity.root_task_id as number) : undefined;
  const status = 'status' in entity ? entity.status : undefined;

  return (
    <div
      className={styles.bottomHoverTooltip}
      data-isTimeoff={item.type === 'timeoff'}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className={styles.tooltipLeft}>
        {elements.join(' / ')}
        {entityNotes ? (
          <p className={styles.tooltipLeftContent}>
            {getDisplayNotesExcerpt({ notes: entityNotes })}
          </p>
        ) : null}
      </div>
      <div className={styles.tooltipRight}>
        {integrationStatus === 1 && (
          <Icons.SyncWhite
            className={styles.tooltipRightSvg}
            key="integration"
            color="#868686"
          />
        )}
        {Boolean(repeatState && repeatState > 0) && (
          <Icons.SerenaRepeat
            className={styles.tooltipRightSvg}
            key="repeat"
            color="#868686"
          />
        )}
        {Boolean(rootTaskId && rootTaskId > 0) && (
          <Icons.Link
            className={styles.tooltipRightSvg}
            key="link"
            color="#868686"
            size={12}
          />
        )}
        {status === 3 && (
          <Icons.SerenaComplete
            className={styles.tooltipRightSvg}
            key="complete"
            color="#868686"
          />
        )}
        <div className={styles.tooltipHours}>
          {normalizeTime(
            hours,
            config.isNativeTimerApp ? TIME_FORMAT.time : TIME_FORMAT.decimal,
          )}
          h
        </div>
      </div>
    </div>
  );
}
