import { Person } from '@float/types/person';

export type PersonInsightRange = {
  start: number;
  end: number;
};

/**
 * Calculate if a date falls within the selected time range and the person start/end dates
 */
export function isOutsideRange(range: PersonInsightRange, date: number) {
  return date < range.start || date > range.end;
}

export function getPersonInsightRange(
  dates: DatesManager,
  person: Person,
  startDate: number,
  endDate: number,
): PersonInsightRange {
  let start = startDate;
  let end = endDate;

  if (person.start_date) {
    start = Math.max(startDate, dates.toNum(person.start_date));
  }

  if (person.end_date) {
    end = Math.min(endDate, dates.toNum(person.end_date));
  }

  return { start, end };
}
