import { ReactNode } from 'react';

import { SVGIconComponent } from '../../primitives/SVGIcon';

export enum AlertBarVariantType {
  Critical = 'critical',
  Learn = 'learn',
  Success = 'success',
  Warning = 'warning',
}

export enum AlertBarVariantDisplay {
  Inline = 'inline',
  Block = 'block',
}

export type AlertBarProps = React.HTMLAttributes<HTMLDivElement> & {
  icon?: SVGIconComponent;
  message: string | ReactNode;
  showClose?: boolean;
  onClickClose?: () => void;
  type?: AlertBarVariantType;
  display?: AlertBarVariantDisplay;
};
