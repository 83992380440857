import { CellHelpersProps } from '../types';

export function getMinHeightHours({
  hourHeight,
  singleUserView,
  suvSingleDay,
}: Pick<CellHelpersProps, 'hourHeight' | 'singleUserView' | 'suvSingleDay'>) {
  let minItemHeightHours = 2;
  if (hourHeight === 16 || hourHeight === 32) minItemHeightHours = 1;
  if (hourHeight === 56 || hourHeight === 64) minItemHeightHours = 0.25;
  if (singleUserView || suvSingleDay) minItemHeightHours = 0.5;
  return minItemHeightHours;
}
