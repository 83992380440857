import { createSelector } from 'reselect';

import { getProjectsMapRaw } from './getProjectsMapRaw';

// OPTIMIZATION: Object.values might become slow for big inputs.
// In the big accounts the projects state easily grows over the 5k properties.

export const getProjectsRawList = createSelector(
  [getProjectsMapRaw],
  (projects) => Object.values(projects),
);
