import { createSelector } from 'reselect';

import { Person } from '@float/types/person';
import { Task } from '@float/types/task';
import { Timeoff } from '@float/types/timeoff';

import { selectDatesManager } from '../currentUser';
import { selectIsWorkDayGetter } from './isWorkDay';

type TaskPayload = Pick<Task, 'start_date' | 'end_date'>;
type TimeoffPayload = Pick<Timeoff, 'start_date' | 'end_date' | 'timeoff_id'>;

export const selectEntityWorkDaysDurationGetter = createSelector(
  [selectDatesManager, selectIsWorkDayGetter],
  (dates, isWorkDay) =>
    (entity: TaskPayload | TimeoffPayload, person: Person) => {
      const ignoreTimeoff =
        'timeoff_id' in entity ? entity.timeoff_id : undefined;
      const start = dates.toNum(entity.start_date);
      const end = dates.toNum(entity.end_date);

      let duration = 0;

      for (let dateNum = start; dateNum <= end; dateNum++) {
        const date = dates.fromNum(dateNum);

        if (isWorkDay(person, date, ignoreTimeoff)) {
          duration += 1;
        }
      }

      return duration;
    },
);
