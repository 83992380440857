import React, { useRef } from 'react';
import { FieldValues } from 'react-hook-form';
import cn from 'classnames';

import { noop } from '@float/libs/utils/noop';
import { useBlockOutsideInteraction } from '@float/ui/hooks/useBlockOutsideInteraction';
import {
  QuickInputProps,
  QuickInputTooltip,
  styles,
  useQuickInputController,
} from '@float/ui/primitives/QuickInput';

import { QuickInputFieldWrapper } from '../../primitives/QuickInput/components/QuickInputFieldWrapper';

export interface QuickInputFieldProps<F extends FieldValues>
  extends QuickInputProps<F> {}

/**
 * An input field with some built-in handling for keyboard events / default values
 * designed to be use for presentational values that are also editable.
 *
 * Can be dynamically themed at run time.
 *
 * Supports a compact `variant`.
 *
 * For a version with more validation and event handling use the QuickInputForm components.
 */
export function QuickInputField<F extends FieldValues>({
  defaultValue,
  disabled,
  maxLength,
  minLength,
  name,
  onBlur = noop,
  onChange = noop,
  onKeyDown,
  required = false,
  rules,
  themeOverrides,
  tooltipPlacement,
  blockOutsideInteraction,
  variant = 'base',
  className,
  prefix,
  suffix,
  readOnly,
  inputMode,
  ...rest
}: QuickInputFieldProps<F>) {
  const inputElementRef = useRef<HTMLInputElement>(null);

  const { wrapperProps, inputProps, tooltipProps } = useQuickInputController({
    defaultValue,
    disabled,
    inputElementRef,
    maxLength,
    minLength,
    name,
    onBlur,
    onChange,
    onKeyDown,
    prefix,
    required,
    rules,
    suffix,
    readOnly,
    inputMode,
  });

  const wrapperRef = useRef(null);
  useBlockOutsideInteraction(wrapperRef, blockOutsideInteraction && !readOnly);

  return (
    <QuickInputTooltip {...tooltipProps} placement={tooltipPlacement}>
      <QuickInputFieldWrapper
        {...wrapperProps}
        className={className}
        themeOverrides={themeOverrides}
        variant={variant}
        ref={wrapperRef}
      >
        <input
          {...rest}
          {...inputProps}
          className={cn([styles.input, styles.paddingVariants[variant]])}
        />
      </QuickInputFieldWrapper>
    </QuickInputTooltip>
  );
}
