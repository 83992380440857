import { isArray } from 'lodash';

import { moment } from '@float/libs/moment';

import API3 from '../api3';
import { getJWTAccessToken } from './jwt';
import { ensureViewsLoaded } from './views';

export const FETCH_COMPANY_PREFS = 'FETCH_COMPANY_PREFS';
export const FETCH_COMPANY_PREFS_SUCCESS = 'FETCH_COMPANY_PREFS_SUCCESS';
export const FETCH_COMPANY_PREFS_FAILURE = 'FETCH_COMPANY_PREFS_FAILURE';

export const UPDATE_COMPANY_PREFS = 'UPDATE_COMPANY_PREFS';
export const UPDATE_COMPANY_PREFS_SUCCESS = 'UPDATE_COMPANY_PREFS_SUCCESS';
export const UPDATE_COMPANY_PREFS_FAILURE = 'UPDATE_COMPANY_PREFS_FAILURE';

export const SET_TIME_TRACKING = 'SET_TIME_TRACKING';
export const SET_PLUS_PACK = 'SET_PLUS_PACK';

const fetchedCompanyPrefs = (companyPrefs) => ({
  type: FETCH_COMPANY_PREFS_SUCCESS,
  companyPrefs,
});

export const updatedCompanyPrefs = (inputData, jsonData) => {
  if (inputData instanceof FormData) {
    let currencySymbol = inputData.get('currency_symbol');
    currencySymbol = currencySymbol === 'null' ? null : currencySymbol;

    return {
      type: UPDATE_COMPANY_PREFS_SUCCESS,
      inputData: {
        timezone: inputData.get('timezones'),
        currency: inputData.get('currency'),
        sso_required: inputData.get('company[sso_required]'),
        domains: inputData.get('company[domains]'),
        currency_symbol: currencySymbol,
        start_work_week: Number(inputData.get('start_work_week')),
        work_days_hours: inputData
          .getAll('work_days_hours[]')
          .map((day) => Number(day)),
        hide_non_wk_days: inputData.get('hide_non_wk_days'),
        time_format_24h: inputData.get('time_format_24h'),
        share_link_enabled: inputData.get('share_link_enabled'),
        share_link_all_members_enabled: inputData.get(
          'share_link_all_members_enabled',
        ),
      },
      jsonData,
    };
  }

  return {
    type: UPDATE_COMPANY_PREFS_SUCCESS,
    inputData,
    jsonData,
  };
};

export const updateCompanyPrefs = (data) => {
  return (dispatch, getState) => {
    return API3.updateCompanyPrefs({ data }).then((json) => {
      const startWorkWeek = data.get('start_work_week');
      const oldStartWorkWeek = getState().companyPrefs.start_work_week;

      if (startWorkWeek != oldStartWorkWeek) {
        moment.updateLocale('en', { week: { dow: +startWorkWeek } });
      }
      data.set('currency_symbol', json.currency_symbol);
      data.set('share_link_enabled', json.share_link_enabled);
      data.set(
        'share_link_all_members_enabled',
        json.share_link_all_members_enabled,
      );
      const jsonData = {
        work_days_hours: json.work_days_hours,
        work_days_hours_history: json.work_days_hours_history,
      };
      dispatch(updatedCompanyPrefs(data, jsonData));
    });
  };
};

export const updateCompanyPrefsNoRequest = (payload) => ({
  type: UPDATE_COMPANY_PREFS_SUCCESS,
  noRequest: true,
  payload,
});

export const updateCompanyPrefsV2 = (data) => {
  return (dispatch) => {
    return API3.updateCompanyPrefsV2({ data }).then((json) => {
      dispatch(updateCompanyPrefsNoRequest(data));
    });
  };
};

export const updateCompanyPrompts = (prompts) => {
  return async (dispatch) => {
    prompts = isArray(prompts) ? prompts : [prompts];

    return API3.saveCompanyPrompts(prompts).then((json) => {
      dispatch(updateCompanyPrefsNoRequest({ prompts: json.prompts }));
    });
  };
};

export const updateCompanyShareLinkPrefs = (data) => {
  return (dispatch) => {
    return API3.updateCompanyShareLinkPrefs({ data }).then((json) => {
      dispatch(updatedCompanyPrefs(json));
    });
  };
};

export const updateSecurityPrefs = (data) => {
  return (dispatch, getState) => {
    const companyPrefs = getState().companyPrefs;

    return API3.updateSecurityPrefs({ data }).then((json) => {
      if (typeof json.domains === 'string') {
        json.domains = json.domains.split(',');
      }
      dispatch(
        updatedCompanyPrefs({
          ...companyPrefs,
          ...json,
        }),
      );
    });
  };
};

export const startTimeTrackingTrial = () => {
  return async (dispatch, getState) => {
    await API3.startTimeTrackingTrial();

    getJWTAccessToken(true)(dispatch, getState);
    dispatch({
      type: SET_TIME_TRACKING,
      time_tracking: 2,
      time_tracking_end: moment().add(31, 'day').format('YYYY-MM-DD'),
    });
  };
};

export const cancelTimeTrackingTrial = () => {
  return async (dispatch, getState) => {
    await API3.cancelTimeTrackingTrial();

    getJWTAccessToken(true)(dispatch, getState);
    dispatch({
      type: SET_TIME_TRACKING,
      time_tracking: getState().companyPrefs.is_paid_plan ? -1 : 0,
    });
  };
};

export const startProTrial = () => {
  return async (dispatch) => {
    await API3.startProTrial();

    await dispatch(getJWTAccessToken(true));
    dispatch({
      type: SET_TIME_TRACKING,
      time_tracking: 2,
      time_tracking_end: moment().add(31, 'day').format('YYYY-MM-DD'),
    });
    dispatch({
      type: SET_PLUS_PACK,
      plus_pack: 2,
      plus_pack_end: moment().add(31, 'day').format('YYYY-MM-DD'),
    });

    await Promise.all([
      dispatch(fetchCompanyPrefs()),
      dispatch(ensureViewsLoaded(true)), // Re-fetch the Views data to ensure that all the Shared Views are restored
    ]);
  };
};

export const cancelProTrial = () => {
  return async (dispatch, getState) => {
    await API3.cancelProTrial();
    await dispatch(getJWTAccessToken(true));

    dispatch({
      type: SET_TIME_TRACKING,
      time_tracking: getState().companyPrefs.is_paid_plan ? -1 : 0,
    });
    dispatch({
      type: SET_PLUS_PACK,
      plus_pack: getState().companyPrefs.is_paid_plan ? -1 : 0,
    });

    await Promise.all([
      dispatch(fetchCompanyPrefs()),
      dispatch(ensureViewsLoaded(true)), // Re-fetch the Views data to ensure that all the Shared Views are downgraded
    ]);
  };
};
export const updateTimeTracking = ({ isEnabled }) => {
  return async (dispatch, getState) => {
    const timeTracking = isEnabled ? 1 : -1;
    const data = new FormData();
    data.append('Company[time_tracking]', timeTracking.toString());

    try {
      await API3.updateTimeTracking({ data });

      getJWTAccessToken(true)(dispatch, getState);
      dispatch({
        type: SET_TIME_TRACKING,
        time_tracking: timeTracking,
      });
    } catch (e) {
      throw new Error('Failed to update company settings');
    }
  };
};

export const validateCompanyHostname = (hostname) => {
  return (dispatch, getState) => {
    return API3.validateCompanyHostname({
      data: { new_host: hostname, ajax: 1 },
    });
  };
};

export const updateCompanyNameHostname = ({ name, hostname }) => {
  return (dispatch, getState) => {
    const data = new FormData();
    data.append('Company[company_name]', name);
    data.append('Company[hostname]', hostname);
    return API3.updateCompanyNameHostname({ data }).then((json) => {
      dispatch(updateCompanyPrefsNoRequest(json.company));
      return json;
    });
  };
};

export const fetchCompanyPrefs = () => {
  return (dispatch, getState) => {
    return API3.fetchCompanyPrefs().then((json) => {
      dispatch(fetchedCompanyPrefs(json));
    });
  };
};

// Used for mobile app, since it's being fetched via /token request
export const setCompanyPrefs = (companyPrefs) => {
  return (dispatch) => {
    dispatch(fetchedCompanyPrefs(companyPrefs));
  };
};
