import React, { ReactNode } from 'react';

import { AnchorValue } from '@float/libs/web/hooks/useAnchor';

import { StepNav } from '../StepNav';
import { StyledContent, StyledTooltipNotification } from './styles';

export type StepBaseProps = {
  children?: ReactNode;
  anchor: AnchorValue;
  step: number;
  stepsTotal: number;
  goNext: () => void;
  skipTour: () => void;
};

export const StepBase = (props: StepBaseProps) => {
  const { children, anchor, step, stepsTotal, goNext, skipTour } = props;

  const { config, ...anchorData } = anchor;

  const onClickClose = () => {
    if (skipTour) skipTour();
  };

  return (
    <StyledTooltipNotification
      anchor={anchorData}
      arrowPosition={config?.arrowPosition}
      alignment={config?.alignment}
      position={config?.position}
      onClose={onClickClose}
      data-transition-timeout="0.65"
    >
      <StyledContent>{children}</StyledContent>
      <StepNav step={step} stepsTotal={stepsTotal} onClickNext={goNext} />
    </StyledTooltipNotification>
  );
};
