import styled from 'styled-components';

import EH from '@float/ui/deprecated/Earhart';

export const StyledRow = styled.div`
  color: ${EH.Colors.FIN.Lt.Emphasis.High};

  display: flex;
  align-items: center;

  & + & {
    margin-top: 20px;
  }

  h4 {
    ${EH.Typography.TextXXL.SB600};

    flex: 1;
  }

  p {
    ${EH.Typography.TextM.R400};
  }
`;

export const StyledTd = styled.td`
  > span,
  > div {
    vertical-align: middle;
  }

  > * + * {
    margin-left: 16px;
  }
`;
