import React, { ComponentProps } from 'react';
import { t } from '@lingui/macro';
import { RecipeVariants } from '@vanilla-extract/recipes';
import cn from 'classnames';

import { capitalize } from '@float/libs/utils/string/capitalize';
import { TimeoffType } from '@float/types/timeoffType';

import * as styles from './styles.css';

export type TimeoffSelectReadOnlyProps = {
  timeoffType: TimeoffType;
} & NonNullable<RecipeVariants<typeof styles.timeOffReadOnly>> &
  Pick<ComponentProps<'div'>, 'className'>;

export const TimeoffSelectReadOnly = (props: TimeoffSelectReadOnlyProps) => {
  const { timeoffType, size, className } = props;

  let timeoffTypeName = capitalize(timeoffType.timeoff_type_name);

  if (!timeoffType.active) {
    timeoffTypeName = t`${timeoffTypeName} (Archived)`;
  }

  return (
    <span className={cn(className, styles.timeOffReadOnly({ size }))}>
      {timeoffTypeName}
    </span>
  );
};
