import { createSelectorWithShallowEqualSetResultCheck } from '../lib/createSelectorWithShallowEqualSetResultCheck';
import { getProjectsRawList } from './getProjectsRawList';

// OPTIMIZATION: A stable set of the loaded project ids
// Good when we need a source of project ids that gets revalidated only
// when ids gets added/removed
// Used to filter logged times without revalidating them when budgets are updated
export const getProjectsIdsSet = createSelectorWithShallowEqualSetResultCheck(
  [getProjectsRawList],
  (projects) => {
    const ids = new Set<number>();

    for (const project of projects) {
      if (project) {
        ids.add(project.project_id);
      }
    }

    return ids;
  },
);

export const getArchivedProjectsIdsSet =
  createSelectorWithShallowEqualSetResultCheck(
    [getProjectsRawList],
    (projects) => {
      const ids = new Set<number>();

      for (const project of projects) {
        if (project?.active === false) {
          ids.add(project.project_id);
        }
      }

      return ids;
    },
  );
