import React, { StrictMode, useMemo } from 'react';
import { t } from '@lingui/macro';
import styled from 'styled-components';

import { displayHoursFormatIsTime } from '@float/common/lib/timer/displayHoursFormat';
import { formatDecimalHoursAsClockTime } from '@float/common/lib/timer/formatDecimalHoursAsClockTime';
import { getLoggedTimeRangeInsights } from '@float/common/serena/Data/insights/selectors/getLoggedTimeRangeInsights';
import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import { useAppSelectorWithParams } from '@float/common/store';
import { CurrentUser } from '@float/types';
import { EH, Icons } from '@float/ui/deprecated';
import { Button } from '@float/ui/deprecated/Earhart/Buttons';
import Spinner from '@float/ui/deprecated/Loader/Spinner';

import { useLogAllTasksInTimeRange } from '../hooks/useLogAllTasksInTimeRange';

const Header = styled.div`
  color: ${(p) => p.theme.emphasisHigh};
  text-align: right;
  font-size: 20px;
  letter-spacing: -0.1px;
  display: flex;
  flex-shrink: 0;
  min-width: 137px;
  justify-content: flex-end;

  strong {
    font-weight: 600;
    margin-right: 5px;
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

const LogButton = styled(Button)`
  ${EH.Typography.Label14.SB600};
  flex-shrink: 0;
  margin-left: 16px;
  padding: 6px;
  min-width: 119px;
  justify-content: center;

  svg {
    margin-right: 4px;
  }

  &.loading {
    opacity: 0.8;

    #loader {
      stroke: #fff;
    }
  }

  span {
    margin: 0 !important;
  }

  .stroke {
    stroke: #fff;
  }

  &.disabled {
    .stroke {
      stroke: ${(p) => p.theme.stroke3};
    }
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

function assertNonNullPersonId(value: number | null): asserts value is number {
  if (value === null) {
    throw new Error(`suvPersonId should not be null in LoggedTimeHeader`);
  }
}

export type LoggedTimeHeaderProps = {
  prefs: CurrentUser['prefs'];
};

export function LoggedTimeHeader({ prefs }: LoggedTimeHeaderProps) {
  const { dates, suvWeek, suvPersonId } = useScheduleContext();

  assertNonNullPersonId(suvPersonId);

  const range = useMemo(() => {
    const startDate = dates.fromDescriptor(suvWeek, 0);
    const endDate = dates.addDays(
      dates.fromDescriptor(suvWeek + 1, 0),
      -1,
    ) as string;

    return {
      startDate,
      endDate,
    };
  }, [dates, suvWeek]);

  const insights = useAppSelectorWithParams(getLoggedTimeRangeInsights, range);

  const personInsights = insights.byPerson[suvPersonId];

  const logged = personInsights?.logged ?? 0;
  const unlogged = personInsights?.h ?? 0;

  const { logAllTasksInTimeRange, isLoading } = useLogAllTasksInTimeRange();

  const logAllTime = () => {
    if (!suvPersonId) return;

    const start = dates.fromDescriptor(suvWeek, 0);
    const end = dates.addDays(
      dates.fromDescriptor(suvWeek + 1, 0),
      -1,
    ) as string;

    logAllTasksInTimeRange({
      peopleIds: [suvPersonId],
      start,
      end,
    });
  };

  const loggedHoursValue = useMemo(() => {
    return displayHoursFormatIsTime(prefs)
      ? formatDecimalHoursAsClockTime(logged)
      : `${logged}h`;
  }, [prefs, logged]);

  const unloggedHoursValue = useMemo(() => {
    return displayHoursFormatIsTime(prefs)
      ? formatDecimalHoursAsClockTime(unlogged)
      : `${unlogged}h`;
  }, [prefs, unlogged]);

  const noTimeToLog = unlogged === 0;
  const showLoader = !noTimeToLog && isLoading;

  return (
    <StrictMode>
      <Header data-testid="logged-header">
        <strong>{loggedHoursValue}</strong> logged
      </Header>
      {/* @ts-expect-error Button isn't typed */}
      <LogButton
        size="small"
        className={showLoader ? 'loading' : ''}
        icon={showLoader ? Spinner : Icons.NavLogTime}
        onClick={logAllTime}
        disabled={noTimeToLog}
      >
        {noTimeToLog
          ? t`${unloggedHoursValue} to log`
          : t`Log ${unloggedHoursValue}`}
      </LogButton>
    </StrictMode>
  );
}
