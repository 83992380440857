import { Phase } from '@float/types/phase';
import { Project } from '@float/types/project';
import { getEntityStatusLabel } from '@float/ui/helpers/label/entityStatusLabel';

export const getGroupLabelForFunctions = (
  groupBy: string,
  item: Project | Phase,
) => {
  let groupLabel = undefined;
  // Only one case supported for now, but more columns will be added
  switch (groupBy) {
    case 'status':
      if (!('phase_id' in item)) {
        groupLabel = getEntityStatusLabel(item.status);
      }
      break;
  }

  return groupLabel;
};
