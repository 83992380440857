import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { TextTooltip, TextTooltipProps } from './TextTooltip';

const LOGGED_TIME_TOOLTIP_DELAY = 1250;

export type TooltipWithWarningProps = TextTooltipProps & {
  enabled: boolean;
  shouldWarningForcedToBeHidden: boolean;
  shouldWarningBeVisible: boolean;
  warningMessage: ReactNode;
};

const getTooltipProps = (
  isVisible: boolean,
  content: ReactNode,
  contentWarning: ReactNode,
  isWarningVisible: boolean,
  forceHide: boolean,
) => {
  let config: Omit<TextTooltipProps, 'children'> = {
    open: isVisible || isWarningVisible,
    content: isWarningVisible ? contentWarning : content,
  };

  if (isWarningVisible && forceHide) {
    config.open = false;
  }

  if (isWarningVisible) {
    config = {
      ...config,
      className: 'hover',
      placement: 'right',
      textAlign: 'left',
    };
  } else if (isVisible) {
    config = {
      ...config,
      delay: LOGGED_TIME_TOOLTIP_DELAY,
      placement: 'bottom',
    };
  }

  return config;
};

const TooltipWithWarning: React.FC<TooltipWithWarningProps> = ({
  children,
  content,
  enabled,
  shouldWarningForcedToBeHidden,
  shouldWarningBeVisible,
  warningMessage,
  ...rest
}: TooltipWithWarningProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isWarningVisible, setIsWarningVisible] = useState(
    shouldWarningBeVisible,
  );

  const cancelWarningTimeout = useRef<ReturnType<typeof setTimeout>>();

  const config = getTooltipProps(
    isVisible,
    content,
    warningMessage,
    isWarningVisible,
    shouldWarningForcedToBeHidden,
  );

  const onOpenChange = useCallback(
    (open: boolean) => {
      if (!enabled) return;

      if (open && !isWarningVisible) {
        setIsVisible(true);
      } else if (!open) {
        setIsVisible(false);
      }
    },
    [enabled, isWarningVisible],
  );

  useEffect(() => {
    if (shouldWarningBeVisible) {
      if (cancelWarningTimeout.current)
        clearTimeout(cancelWarningTimeout.current);

      setIsVisible(false);
      setIsWarningVisible(true);

      cancelWarningTimeout.current = setTimeout(() => {
        setIsWarningVisible(false);
      }, 2000);
    }
  }, [shouldWarningBeVisible]);

  return (
    <TextTooltip {...rest} {...config} onOpenChange={onOpenChange}>
      {children}
    </TextTooltip>
  );
};

export { TooltipWithWarning };
