import { some } from 'lodash';

type HasCollapsedProjectProps = {
  expandedProjectIds?: Record<number, boolean>;
  projectId?: number;
};

export function hasCollapsedProject({
  expandedProjectIds,
  projectId,
}: HasCollapsedProjectProps) {
  if (projectId) {
    // checking specific project
    return !expandedProjectIds || !expandedProjectIds[projectId];
  }

  const isAnyProjectExpanded = some(expandedProjectIds, (val) => val);
  return !isAnyProjectExpanded;
}
