import React from 'react';

import { SidePanelInput } from '../components/SidePanelInput';

import * as styles from './NameField.css';

type NameFieldProps = {
  autoFocus?: boolean;
  name?: 'project.project_name' | 'phase.phase_name';
  placeholder?: string;
};

export function NameField({
  name = 'project.project_name',
  autoFocus,
  placeholder,
}: NameFieldProps) {
  return (
    <SidePanelInput
      autoFocus={autoFocus}
      name={name}
      placeholder={placeholder}
      className={styles.projectTitleInput}
      // setting this in the boolean-shorthand way for native input elements throws a warning
      transparent="true"
      size="lg"
    />
  );
}
