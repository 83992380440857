import { LoggedTime, Task, TaskReference } from '@float/types';

import { DateUtils } from '../types';

export function hasLoggedTime(
  task: Task,
  x: number,
  loggedTime: (LoggedTime | TaskReference)[],
  dates: DateUtils,
  colIdx: number,
) {
  const date = dates.fromDescriptor(colIdx, x);

  return loggedTime.some((lt) => {
    return lt.task_id == task.task_id && lt.reference_date === date;
  });
}
