import { sumOperation } from '@float/libs/utils/floats';
import { CellItem, Timeoff } from '@float/types';

import { getEntityMeta } from '../entityMeta';
import { CellRenderArgs } from '../types';
import { getNonWorkSubCols } from './getNonWorkSubCols';
import { isInLockPeriod } from './isInLockPeriod';

export function addTimeoffs(args: CellRenderArgs) {
  const {
    cell,
    cellKey,
    cells,
    fns,
    props,
    firstCellDay,
    lastCellDay,
    minItemHeightHours,
  } = args;

  const {
    bimaps,
    dates,
    leftHiddenDays,
    lockPeriodDates,
    maps,
    rightHiddenDays,
  } = props;
  const { calcEntityLength, getAllInstances } = cells._helpers;
  const { colIdx, rowId } = cell;
  const { getHorizontalDimensions } = fns;
  const { timeoff: timeoffsBimap } = bimaps;
  const { timeoff: timeoffsMap } = maps;

  const timeoffs = timeoffsBimap.getRev(cellKey).map((id) => timeoffsMap[id]);

  timeoffs.forEach((timeoff) => {
    // @entity.length
    if (!timeoff.length) {
      timeoff.length = calcEntityLength(cells, rowId, timeoff);
    }

    const timeoffInstances = getAllInstances(timeoff) as Timeoff[];

    timeoffInstances.forEach((t: Timeoff, instanceCount: number) => {
      // Skip tentative time offs on time tracking timeline
      if (t.status === 1) {
        return;
      }
      const { x, w } = getHorizontalDimensions(t, firstCellDay, lastCellDay);

      // Timeoffs with region_holiday_id are treated as holidays, not timeoffs
      if (t.region_holiday_id) {
        const inLock = isInLockPeriod(x, lockPeriodDates, dates, colIdx);
        t.isInLockPeriod = inLock;
        const cellItem: CellItem<'timeoff'> = {
          cellKey,
          rowId,
          key: `${cellKey}:timeoff:${t.timeoff_id}:${instanceCount}`,
          isPlaceholder: getEntityMeta(t, 'isPlaceholder'),
          type: 'timeoff',
          entity: t,
          entityId: t.timeoff_id,
          date: dates.fromNum(firstCellDay + x),
          x,
          w,
          isInLockPeriod: inLock,
        };

        if (w > 0) cell.items.push(cellItem);
        return;
      }

      // @entity.length
      t.length = timeoff.length;

      const nonWorks = getNonWorkSubCols(
        rowId,
        firstCellDay,
        x,
        w,
        dates,
        cells,
        fns.isWorkDay,
        timeoff,
      );

      let curX = x;
      let curW = 0;
      let part = 0;

      // Tasks get split over non-work days, holidays, timeoffs, etc, which
      // means that any given task might be associated with more than one
      // cell item.
      do {
        while (nonWorks.includes(curX) && curX < x + w) {
          curX++;
        }

        while (curX + curW < x + w && !nonWorks.includes(curX + curW)) {
          curW++;
        }

        if (curW > 0) {
          const cellItem: CellItem<'timeoff'> = {
            cellKey,
            rowId,
            key: `${cellKey}:timeoff:${t.timeoff_id}:${curX}:${instanceCount}`,
            isPlaceholder: getEntityMeta(t, 'isPlaceholder'),
            type: 'timeoff',
            entity: t,
            entityId: t.timeoff_id,
            isStart: part === 0 && dates.toNum(t.start_date) >= firstCellDay,
            isEnd:
              dates.toNum(t.end_date) <= lastCellDay &&
              !nonWorks.includes(curX + curW),
            date: dates.fromDescriptor(colIdx, curX),
            x: curX,
            w: curW,
            isInLockPeriod: isInLockPeriod(x, lockPeriodDates, dates, colIdx),
          };

          if (!t.full_day) {
            cellItem.h = Math.max(t.hours!, minItemHeightHours!);

            for (let i = curX; i < curX + curW; i++) {
              cell.dayHours[i] = sumOperation(cell.dayHours[i], t.hours!);
            }
          }

          cell.items.push(cellItem);
        }

        curX = curX + curW + 1;
        curW = 0;
        part++;
      } while (curX < 7 - leftHiddenDays - rightHiddenDays);
    });
  });
}
