import React, { PropsWithChildren } from 'react';
import cn from 'classnames';

import * as styles from './styles.css';

type SidePanelTableHeaderRowProps = {
  className?: string;
};

export const SidePanelTableHeaderRow = (
  props: PropsWithChildren<SidePanelTableHeaderRowProps>,
) => {
  return (
    <div role="row" className={cn(styles.tableHeaderRow, props.className)}>
      {props.children}
    </div>
  );
};
