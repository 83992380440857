import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M4.75 19.25 12 4.75l7.25 14.5-7.25-3.5-7.25 3.5Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M12 15.5v-2.75"
    />
  </BaseIcon>
);
