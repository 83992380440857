import {
  ADD_CLIENT,
  ADD_CLIENT_FAILURE,
  ADD_CLIENT_SUCCESS,
  DELETE_CLIENT,
  DELETE_CLIENT_FAILURE,
  DELETE_CLIENT_SUCCESS,
  UPDATE_CLIENT,
  UPDATE_CLIENT_FAILURE,
  UPDATE_CLIENT_SUCCESS,
} from '@float/common/actions/clients';

import {
  addedEntityCreator,
  addEntityCreator,
  deletedEntityCreator,
  deleteEntityCreator,
  failedAddingEntityCreator,
  failedDeletingEntityCreator,
  failedFetchingEntitiesCreator,
  failedUpdatingEntityCreator,
  fetchedEntitiesCreator,
  fetchEntitiesCreator,
  shouldFetchEntitiesCreator,
  sortEntitiesCreator,
  updatedEntityCreator,
  updateEntityCreator,
} from './creatorHelpers';
import { fetchedProjects } from './projects';

export const SORT_CLIENTS = 'SORT_CLIENTS';

export const FETCH_CLIENTS = 'FETCH_CLIENTS';
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS';
export const FETCH_CLIENTS_FAILURE = 'FETCH_CLIENTS_FAILURE';

const SINGULAR = 'client';
const PLURAL = 'clients';
const ID_PROP = 'client_id';

export const addedClient = addedEntityCreator(ADD_CLIENT_SUCCESS, (entity) => ({
  ...entity,
  project_count: 0,
}));

const failedAddingClient = failedAddingEntityCreator(ADD_CLIENT_FAILURE);

export const addClient = addEntityCreator(
  ADD_CLIENT,
  SINGULAR,
  addedClient,
  failedAddingClient,
);

// Handling DELETE_CLIENT_SUCCESS side-effects (delete projects, tasks etc.)
// via live updates. Hence changed type below to avoid double dispatches using
// that action type (for acting user), which can cause race conditions.
const deletedClient = deletedEntityCreator(`SETTINGS_${DELETE_CLIENT_SUCCESS}`);

const failedDeletingClient = failedDeletingEntityCreator(DELETE_CLIENT_FAILURE);

export const deleteClient = deleteEntityCreator(
  DELETE_CLIENT,
  SINGULAR,
  PLURAL,
  deletedClient,
  failedDeletingClient,
  ID_PROP,
);

export const updatedClient = updatedEntityCreator(UPDATE_CLIENT_SUCCESS);

const failedUpdatingClient = failedUpdatingEntityCreator(UPDATE_CLIENT_FAILURE);

export const updateClient = updateEntityCreator(
  UPDATE_CLIENT,
  SINGULAR,
  updatedClient,
  failedUpdatingClient,
);

const fetchedClients = fetchedEntitiesCreator(
  FETCH_CLIENTS_SUCCESS,
  (entities, getState, page, queryParams, dispatch) => {
    entities.forEach(
      (entity) => entity.projects && dispatch(fetchedProjects(entity.projects)),
    );
    return entities.map((entity) => ({
      ...entity,
      project_count: entity.project_count || 0,
    }));
  },
);

const failedFetchingClients = failedFetchingEntitiesCreator(
  FETCH_CLIENTS_FAILURE,
);

const shouldFetchClients = shouldFetchEntitiesCreator(PLURAL);

export const fetchClients = fetchEntitiesCreator(
  FETCH_CLIENTS,
  PLURAL,
  shouldFetchClients,
  fetchedClients,
  failedFetchingClients,
  // need to know projects count
  { expand: 'project_count', internal_pagination: true },
);

export const sortClients = sortEntitiesCreator(SORT_CLIENTS, fetchClients);
