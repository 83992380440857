import React, { StrictMode } from 'react';
import { format } from 'date-fns';

import { parseFloatDate } from '@float/libs/dates';

import { CELL_OVERFLOW_PX } from '../Cycles.constants';
import { buildMonthOffsets } from '../helpers/buildMonthOffsets';

import * as styles from '../Cycles.css';

// @test-export
export const getMonthIndicatorDimensions = (
  month: { offset: number; firstDayOfMonth: Date },
  dayWidth: number,
) => {
  const offsetLeft = month.offset * dayWidth;

  // We need a 1px offset to get this properly centered
  // in relation to the schedule cells
  const left = offsetLeft - CELL_OVERFLOW_PX + 1;
  const width = dayWidth + CELL_OVERFLOW_PX * 2;
  return {
    left,
    width,
  };
};

export type CycleSingleMonthIndicatorsProps = {
  dayWidth: number;
  leftStickyBoundary: number;
  monthOffset: { offset: number; firstDayOfMonth: Date };
  scrollWrapperRef: React.MutableRefObject<HTMLElement | null | undefined>;
  setActiveMonth: (activeMonth: DateString) => void;
};

export const CycleSingleMonthIndicator: React.FC<
  CycleSingleMonthIndicatorsProps
> = ({ dayWidth, monthOffset }) => {
  const { left, width } = getMonthIndicatorDimensions(monthOffset, dayWidth);

  return (
    <div
      style={{
        left,
        width,
        minWidth: width,
      }}
      className={styles.cycleMonthIndicator}
    >
      {format(monthOffset.firstDayOfMonth, 'MMM')}
    </div>
  );
};

export type CycleMonthIndicatorsProps = {
  dayWidth: number;
  endDate: DateString;
  leftStickyBoundary: number;
  scrollWrapperRef: React.MutableRefObject<HTMLElement | null | undefined>;
  setActiveMonth: (activeMonth: DateString) => void;
  startDate: DateString;
};

export const CycleMonthIndicators: React.FC<CycleMonthIndicatorsProps> = ({
  dayWidth,
  endDate,
  leftStickyBoundary,
  scrollWrapperRef,
  setActiveMonth,
  startDate,
}) => {
  const monthOffsets = buildMonthOffsets(
    parseFloatDate(startDate),
    parseFloatDate(endDate),
  );

  return (
    <StrictMode>
      <div className={styles.cycleMonthList}>
        {monthOffsets.map((monthOffset) => (
          <CycleSingleMonthIndicator
            dayWidth={dayWidth}
            leftStickyBoundary={leftStickyBoundary}
            setActiveMonth={setActiveMonth}
            scrollWrapperRef={scrollWrapperRef}
            key={monthOffset.firstDayOfMonth.toString()}
            monthOffset={monthOffset}
          />
        ))}
      </div>
    </StrictMode>
  );
};
