import useMount from 'react-use/esm/useMount';

const MAX_ITEMS = 25;

export const useListFromClipboard = (onPaste: (values: string[]) => void) => {
  useMount(() => {
    const onPasteHandler = (e: ClipboardEvent) => {
      const data = e.clipboardData?.items || [];

      for (let i = 0; i < data.length; i += 1) {
        if (data[i].kind === 'string' && data[i].type.match('^text/plain')) {
          data[i].getAsString((str: string) => {
            const values = str
              .split('\n')
              .map((value) => value.trim())
              .filter((value) => value !== '')
              .splice(0, MAX_ITEMS);

            if (values.length > 1) {
              e.preventDefault();
              onPaste(values);
            }
          });
        }
      }
    };

    document.addEventListener('paste', onPasteHandler);

    return () => {
      document.removeEventListener('paste', onPasteHandler);
    };
  });
};
