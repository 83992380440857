import React from 'react';

import { formatHours } from '../utils/formatHours';
import { MainCellItem } from './types';

import { hourElement, singleRowIcons } from '../../EntityItemStyles.css';

interface SingleRowProps {
  item: MainCellItem;
  fullDay: boolean;
  iconElements: React.ReactNode;
}

export const SingleRow: React.FC<SingleRowProps> = ({
  item,
  fullDay,
  iconElements,
}) => {
  return (
    <>
      {!fullDay && (
        <div className={hourElement}>{formatHours(item.entity)}</div>
      )}

      <div className={singleRowIcons}>{iconElements}</div>
    </>
  );
};
