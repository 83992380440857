import { keyBy, omit } from 'lodash';

import { RolesAction, RolesState } from '@float/types';

import {
  ADD_ROLE_SUCCESS,
  DELETE_ROLE_SUCCESS,
  FETCH_ROLES,
  FETCH_ROLES_SUCCESS,
  UPDATE_ROLE_SUCCESS,
} from '../actions';

export const DEFAULT_STATE: RolesState = {
  roles: {},
  loadState: 'UNLOADED',
};

export const roles = (
  state = DEFAULT_STATE,
  action: RolesAction,
): RolesState => {
  switch (action.type) {
    case FETCH_ROLES:
      return {
        ...state,
        loadState: 'LOADING',
      };

    case FETCH_ROLES_SUCCESS:
      return {
        roles: keyBy(action.entities, 'id'),
        loadState: 'LOADED',
      };

    case ADD_ROLE_SUCCESS:
    case UPDATE_ROLE_SUCCESS: {
      return {
        loadState: 'LOADED',
        roles: {
          ...state.roles,
          [action.payload.id]: {
            ...action.payload,
          },
        },
      };
    }

    case DELETE_ROLE_SUCCESS: {
      const { id } = action;
      if (!id) {
        return state;
      }
      return {
        ...state,
        roles: omit(state.roles, id),
      };
    }

    default: {
      return state;
    }
  }
};
