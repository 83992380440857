import { useEffect, useReducer } from 'react';

import { AppStore, useAppStore } from '@float/common/store';

import { getProjectData } from '../helpers/getProjectData';
import { getProjectDataFromTemplate } from '../helpers/getProjectDataFromTemplate';
import { fetchProjectData, fetchTemplateData } from '../store/actions';
import { Action, reducer, State } from '../store/reducer';
import { ProjectIdOrTemplateId } from '../types';

type ProjectStateType = ReturnType<typeof getProjectData>;

function getInitialData(store: AppStore, ids?: ProjectIdOrTemplateId) {
  if (ids?.templateId) {
    return getProjectDataFromTemplate(store, ids.templateId);
  }

  return getProjectData(store, ids);
}

/**
 *
 * This hook fetches the project data in one go.
 *
 * It doesn't subscribes to the Redux state because updating the returned data
 * triggeres a state reset on the form component.
 */
export function useGetProjectData(ids?: ProjectIdOrTemplateId) {
  const { projectId, templateId } = ids || {};
  const store = useAppStore();
  const [state, dispatch] = useReducer<
    React.Reducer<State<ProjectStateType>, Action<ProjectStateType>>,
    ProjectIdOrTemplateId | undefined
  >(reducer<ProjectStateType>, ids, (ids) => ({
    data: getInitialData(store, ids),
    status: 'loading',
  }));

  // useUpdateEffect would skip the one and only time this hook runs for new projects
  // meaning it would be stuck permanently in the loading state.
  useEffect(() => {
    if (projectId || templateId) return;
    // Quick path when navigating to a new project
    dispatch({
      type: 'LOADED',
      payload: getProjectData(store, ids),
    });
  }, [projectId, templateId]);

  useEffect(() => {
    if (!projectId) return;

    let canceled = false;

    const load = async () => {
      dispatch({
        type: 'LOADING',
      });

      try {
        await fetchProjectData(store, projectId);

        // Check to avoid out-of-order updates
        if (!canceled) {
          dispatch({
            type: 'LOADED',
            payload: getProjectData(store, { projectId, templateId }),
          });
        }
      } catch (err) {
        dispatch({
          type: 'ERROR',
        });
      }
    };

    load();

    return () => {
      canceled = true;
    };
  }, [projectId, templateId, store]);

  useEffect(() => {
    if (!templateId) return;

    let canceled = false;

    const load = async () => {
      dispatch({
        type: 'LOADING',
      });

      try {
        await fetchTemplateData(store, templateId);

        // Check to avoid out-of-order updates
        if (!canceled) {
          dispatch({
            type: 'LOADED',
            payload: getProjectDataFromTemplate(store, templateId),
          });
        }
      } catch (err) {
        dispatch({
          type: 'ERROR',
        });
      }
    };

    load();

    return () => {
      canceled = true;
    };
  }, [templateId, store]);

  return {
    data: state.data,
    isLoading: state.status === 'loading',
    isError: state.status === 'error',
  };
}
