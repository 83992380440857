import {
  feeFormatter,
  getLinkifiedValue,
  logTimeEditFormatter,
  tooltipFormatter,
} from 'reports/helpers/tableFormatters';

import { toCents } from '@float/common/lib/budget';
import { moment } from '@float/libs/moment';
import { sumOperation } from '@float/libs/utils/floats';

export default function getTimetrackingTable(ctx, raw) {
  const { people, project, phases, hasPhases } = ctx;
  const hasFeeColumns = project.budget_type === 2 || project.budget_type === 3;

  const headers = [
    {
      label: 'Date',
      width: 140,
      align: 'flex-start',
      formatter: (val) => (val ? moment(val).format('DD MMM YYYY') : ''),
    },
    { label: 'Task', width: 200, align: 'flex-start' },
    { label: 'Person', width: 230, align: 'flex-start' },
    {
      label: 'Notes',
      grow: 1,
      align: 'flex-start',
      formatter: tooltipFormatter,
    },
    { label: 'Logged', width: 100, formatter: logTimeEditFormatter(ctx) },
  ];

  if (hasFeeColumns) {
    headers.splice(4, 0, { label: 'Fee', width: 100, formatter: feeFormatter });
  }

  if (hasPhases) {
    headers.splice(1, 0, {
      label: 'Phase',
      align: 'flex-start',
      width: 170,
    });
  }

  if (!raw || !raw.totals) {
    return { headers, rows: [] };
  }

  const rows = [];
  let totalFee = 0;
  let totalHours = 0;

  raw.totals.forEach((item) => {
    if (item.type !== 'logged_time') return;
    if (item.date >= ctx.loggedTimeBoundary) return;

    const fee =
      hasFeeColumns && item.rate
        ? (toCents(item.rate) * item.hours.scheduled) / 100
        : '';

    const data = [
      item.date,
      item.name,
      people[item.person_id].name,
      getLinkifiedValue(item.note),
      { sortVal: item.hours.scheduled, entity: item },
    ];

    if (hasFeeColumns) {
      data.splice(4, 0, fee);
    }

    if (hasPhases) {
      data.splice(1, 0, phases[item.phase_id]?.phase_name || 'No Phase');
    }

    rows.push({ data });

    if (fee) {
      totalFee = (toCents(totalFee) + toCents(fee)) / 100;
    }
    totalHours = sumOperation(totalHours, item.hours.scheduled);
  });

  const footer = [
    { label: '' },
    { label: '' },
    { label: '' },
    { label: '' },
    { label: totalHours },
  ];

  if (hasFeeColumns) {
    footer.splice(4, 0, { label: hasFeeColumns ? totalFee : '' });
  }

  if (hasPhases) {
    footer.splice(1, 0, { label: '' });
  }

  return { headers, rows, footer };
}
