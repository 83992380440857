import { matchFilteredEntity } from '@float/common/search/helpers/matchFilteredEntity';
import { SerenaState } from '@float/common/selectors/serena';
import { CellItem } from '@float/types/cells';
import { PersonProjectRow, PersonRow, ProjectRow } from '@float/types/rows';

export function isGhostTask(
  row: PersonRow | PersonProjectRow | ProjectRow,
  item: CellItem<'task' | 'timeoff' | 'loggedTime'>,
  reduxData: SerenaState,
) {
  // If we are on project plan and the task and the row project
  // are different, this is a ghost task
  if (
    item.type !== 'timeoff' &&
    'projectId' in row &&
    row.projectId &&
    row.projectId !== item.entity.project_id
  ) {
    return true;
  }

  // Items with temporary ids are those that are directly relevant to the
  // operation the user's currently trying to do, such as split, and we don't
  // want to render them as ghosts.
  if ('temporaryId' in item.entity && item.entity.temporaryId) return false;

  if (!reduxData.filters.length) return false;

  return !matchFilteredEntity(
    reduxData.filteredEntities,
    item.type,
    item.entityId,
  );
}

export function isLoggedItemGhostTask(
  item: CellItem<'loggedTime'>,
  row: PersonRow | PersonProjectRow | ProjectRow,
  reduxData: SerenaState,
) {
  if (
    'projectId' in row &&
    row.projectId &&
    row.projectId !== item.entity.project_id
  ) {
    return true;
  }

  if (!reduxData.filters.length) return false;

  if (item.isTaskReference) {
    return !matchFilteredEntity(
      reduxData.filteredEntities,
      'task',
      item.entity.task_id,
    );
  }

  return !matchFilteredEntity(
    reduxData.filteredEntities,
    item.type,
    item.entityId,
  );
}
