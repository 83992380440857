import React from 'react';

export default function ({
  color,
  style,
  size = 12,
  ...props
}: {
  size?: number;
  color?: string;
} & React.SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 12 12"
      style={style}
      {...props}
    >
      <path
        fill={color}
        fillRule="nonzero"
        d="M6 1a5 5 0 1 1 0 10A5 5 0 0 1 6 1zM2.887 6.377l2.358 2.359 4.057-4.057-.85-.849-3.158 3.257-1.558-1.559-.85.85z"
      />
    </svg>
  );
}
