import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useRef,
  useState,
} from 'react';
import useUpdateEffect from 'react-use/esm/useUpdateEffect';

import { formatAsClockTime } from '@float/common/lib/timer/formatAsClockTime';
import { formatAsDuration } from '@float/common/lib/timer/formatAsDuration';
import { TimeFormat } from '@float/types';

import { addMinutesToClockTime } from '../helpers/addMinutesToClockTime';
import { addMinutesToDuration } from '../helpers/addMinutesToDuration';
import { clampTime } from '../helpers/clampTime';
import { isValidInputTimeFormat } from '../helpers/isValidInputTimeFormat';
import { InputTimeChangeEvent, InputTimeType } from '../types';

export const useInputTime = (
  value: string,
  timeFormat: TimeFormat,
  timeType: InputTimeType,
  onUpdate: InputTimeChangeEvent | undefined,
  onChange: InputTimeChangeEvent | undefined,
  onSubmit: InputTimeChangeEvent,
  loop: boolean,
  min?: string | undefined,
  max?: string | undefined,
) => {
  const originalValue = useRef(value);

  const isClockTimeType = timeType === 'clock';

  const formatFn = isClockTimeType ? formatAsClockTime : formatAsDuration;

  const addMinutes = useCallback(
    (value: string, minutesToAdd: number) =>
      isClockTimeType
        ? addMinutesToClockTime(value, timeFormat, minutesToAdd, loop, min, max)
        : addMinutesToDuration(value, timeFormat, minutesToAdd, loop, min, max),
    [isClockTimeType, timeFormat, loop, min, max],
  );

  const [inputValue, setInputValue] = useState(() => {
    if (value) {
      return formatFn(value, timeFormat);
    }

    return value;
  });

  // controlled vs uncontrolled state
  const currentValue = onChange ? value : inputValue;

  function isCompleted(value: string, timeFormat: TimeFormat) {
    return value === formatFn(value, timeFormat);
  }

  function updateValue(newValue: string) {
    const hasChanged = currentValue !== newValue;

    if (hasChanged) {
      if (onUpdate) {
        onUpdate(newValue, isCompleted(newValue, timeFormat));
      }

      if (onChange) {
        onChange(newValue, isCompleted(newValue, timeFormat));
      } else {
        setInputValue(newValue);
      }
    }
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (!isValidInputTimeFormat(e.target.value, timeFormat, timeType)) return;

    let { value } = e.target;

    if (value.length > currentValue.length) {
      if (/^\d{2}$/.test(value)) {
        value += ':';
      } else if (/^\d{3}$/.test(value)) {
        value = [value.slice(0, 2), ':', value.slice(2)].join('');
      }
    }

    updateValue(value);
  }

  function handleBlur() {
    const value = currentValue || originalValue.current;

    let newValue = formatFn(value, timeFormat);

    newValue = clampTime(newValue, timeType, timeFormat, min, max);

    updateValue(newValue);
  }

  function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    let newValue;

    const amount = e.shiftKey ? 10 : 1;

    if (e.key === 'ArrowUp') {
      newValue = addMinutes(currentValue, amount * 1);
    }

    if (e.key === 'ArrowDown') {
      newValue = addMinutes(currentValue, amount * -1);
    }

    if (e.key === 'Escape') {
      e.currentTarget.blur();
    }

    if (e.key === 'Enter') {
      handleBlur();
    }

    if (newValue) {
      e.preventDefault();

      updateValue(newValue);
    }
  }

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();

      const value = currentValue || originalValue.current;

      onSubmit(value, isCompleted(value, timeFormat));
    }
  };

  useUpdateEffect(() => {
    setInputValue(formatFn(value, timeFormat));
  }, [value, timeFormat, formatFn]);

  return {
    handleBlur,
    handleChange,
    handleKeyDown,
    handleKeyUp,
    currentValue,
    setInputValue,
  };
};
