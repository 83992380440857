import { getJWTAccessToken } from '@float/common/actions/jwt';
import { fetchTaskLinks } from '@float/web/pmSidebar/actions';

import api from '../api';
import {
  ADD_JIRA_CO_INT,
  ADD_JIRA_CO_INT_FAILURE,
  ADD_JIRA_CO_INT_SUCCESS,
  CLEAR_JIRA_PRELOAD,
  FETCH_JIRA_CO_INTS,
  FETCH_JIRA_CO_INTS_FAILURE,
  FETCH_JIRA_CO_INTS_SUCCESS,
  FETCH_JIRA_CONFIG,
  FETCH_JIRA_CONFIG_FAILURE,
  FETCH_JIRA_CONFIG_SUCCESS,
  FETCH_JIRA_PRELOAD,
  FETCH_JIRA_PRELOAD_FAILURE,
  LINK_JIRA,
  LINK_JIRA_FAILURE,
  LINK_JIRA_SUCCESS,
  REMOVE_JIRA_CO_INT,
  REMOVE_JIRA_CO_INT_FAILURE,
  REMOVE_JIRA_CO_INT_SUCCESS,
  UPDATE_JIRA_CONFIG,
  UPDATE_JIRA_CONFIG_FAILURE,
  UPDATE_JIRA_CONFIG_SUCCESS,
} from './jira.constants';
import {
  RETRY_PEOPLE_SYNC,
  RETRY_PEOPLE_SYNC_FAILURE,
  RETRY_PEOPLE_SYNC_SUCCESS,
} from './teamwork';

export * from './jira.constants';

const JIRA_TYPE = 'jira';

export const fetchJiraCoInts = ({ getLinks = false } = {}) => {
  return async (dispatch, getState) => {
    try {
      const loadState = getState().integrations.jira.fetchJiraCoIntsLoadState;
      if (loadState === 'LOADING') {
        return;
      }

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: FETCH_JIRA_CO_INTS });
      const coInts = await api(accessToken).requestCoInts({
        type: JIRA_TYPE,
      });

      dispatch({
        type: FETCH_JIRA_CO_INTS_SUCCESS,
        coInts,
      });

      if (getLinks) {
        const coIntId = coInts.length && coInts[0].integrations_co_id;
        if (coIntId) {
          dispatch(fetchTaskLinks(coIntId));
        }
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: FETCH_JIRA_CO_INTS_FAILURE });
    }
  };
};

export const fetchJiraPreload = (coIntId) => {
  return async (dispatch, getState) => {
    try {
      const loadState = getState().integrations.jira.preloadLoadState;
      if (loadState === 'LOADING') {
        return;
      }

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: FETCH_JIRA_PRELOAD });
      const { success } = await api(accessToken).requestPreload({
        type: JIRA_TYPE,
        coIntId,
      });
      if (!success) {
        dispatch({ type: FETCH_JIRA_PRELOAD_FAILURE });
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: FETCH_JIRA_PRELOAD_FAILURE });
    }
  };
};

export const clearJiraPreload = () => async (dispatch) =>
  dispatch({ type: CLEAR_JIRA_PRELOAD });

export const linkJiraAccount =
  (jiraSetupToken) => async (dispatch, getState) => {
    try {
      const loadState = getState().integrations.jira.linkJiraLoadState;
      if (loadState === 'LOADING') {
        return;
      }

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: LINK_JIRA });
      const { success } = await api(accessToken).linkJiraAccount({
        clientKey: jiraSetupToken,
      });
      if (!success) {
        dispatch({ type: LINK_JIRA_FAILURE });
      }
      dispatch({ type: LINK_JIRA_SUCCESS });
      dispatch(fetchJiraCoInts());
    } catch (e) {
      console.error(e);
      dispatch({ type: LINK_JIRA_FAILURE });
    }
  };

export const fetchJiraConfig = (coInt) => async (dispatch, getState) => {
  try {
    const loadState = getState().integrations.jira.fetchConfigLoadState;
    if (loadState === 'LOADING') {
      return;
    }

    const accessToken = await dispatch(getJWTAccessToken());

    dispatch({ type: FETCH_JIRA_CONFIG });
    const jiraConfig = await api(accessToken).getCoIntConfig({
      coIntId: coInt.integrations_co_id,
    });
    dispatch({
      type: FETCH_JIRA_CONFIG_SUCCESS,
      jiraConfig,
    });
  } catch (e) {
    console.error(e);
    dispatch({ type: FETCH_JIRA_CONFIG_FAILURE });
  }
};

export const updateJiraConfig = (
  coInt,
  config,
  { startInitialImport, resync } = {},
) => {
  return async (dispatch, getState) => {
    try {
      const loadState = getState().integrations.jira.updateConfigLoadState;
      if (loadState === 'LOADING') {
        return;
      }

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: UPDATE_JIRA_CONFIG, startInitialImport });
      const jiraConfig = await api(accessToken).updateCoIntConfig({
        coIntId: coInt.integrations_co_id,
        config,
        startInitialImport,
        resync,
        type: JIRA_TYPE,
      });

      dispatch({
        type: UPDATE_JIRA_CONFIG_SUCCESS,
        jiraConfig,
        initialImport: startInitialImport,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: UPDATE_JIRA_CONFIG_FAILURE });
    }
  };
};

export const addJiraCoInt = () => {
  return async (dispatch, getState) => {
    try {
      const loadState = getState().integrations.jira.addCoIntLoadState;
      if (loadState === 'LOADING') {
        return;
      }

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: ADD_JIRA_CO_INT });
      dispatch({ type: FETCH_JIRA_PRELOAD });

      const { success } = await api(accessToken).addCoInt({
        type: JIRA_TYPE,
      });

      if (!success) {
        throw Error('Add jira company integration error on API request');
      }

      dispatch({
        type: ADD_JIRA_CO_INT_SUCCESS,
      });

      dispatch(fetchJiraCoInts());
    } catch (e) {
      console.error(e);
      dispatch({ type: ADD_JIRA_CO_INT_FAILURE });
    }
  };
};

export const removeJiraCoInt = (coIntId, archiveData = false) => {
  return async (dispatch, getState) => {
    try {
      const loadState = getState().integrations.jira.removeCoIntLoadState;
      if (loadState === 'LOADING') {
        return;
      }

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: REMOVE_JIRA_CO_INT });

      const { success } = await api(accessToken).removeCoInt({
        coIntId,
        archiveData,
      });

      if (!success) {
        throw Error('Remove jira company integration error on API request');
      }

      dispatch({ type: REMOVE_JIRA_CO_INT_SUCCESS });

      dispatch(fetchJiraCoInts());
    } catch (e) {
      console.error(e);
      dispatch({ type: REMOVE_JIRA_CO_INT_FAILURE });
    }
  };
};

export const retryJiraPeopleSync = (coIntId) => {
  return async (dispatch, getState) => {
    try {
      const loadState =
        getState().integrations.teamwork.retryPeopleSyncLoadState;
      if (loadState === 'LOADING') {
        return;
      }

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: RETRY_PEOPLE_SYNC });

      const { success } = await api(accessToken).retryPeopleSync({ coIntId });

      if (!success) {
        throw Error('Retry People Sync error on API request');
      }

      dispatch({ type: RETRY_PEOPLE_SYNC_SUCCESS });

      dispatch(fetchJiraCoInts());
    } catch (e) {
      console.error(e);
      dispatch({ type: RETRY_PEOPLE_SYNC_FAILURE });
    }
  };
};
