import { noop } from '@float/libs/utils/noop';

export const getInteractionHandlers = ({
  defaultValue,
  inputElementRef,
  onBlur = noop,
  onKeyDown = noop,
  required,
  setInputValue,
}: {
  defaultValue?: string;
  inputElementRef: { current: HTMLInputElement | null };
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  required: boolean;
  setInputValue: (value: string) => void;
}) => {
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const inputElement = inputElementRef.current;

    if (inputElement) {
      // Remove leading and trailing whitespace
      const sanitizedInputValue = inputElement.value.trim();

      if (!sanitizedInputValue && required && defaultValue) {
        setInputValue(defaultValue);
      } else if (sanitizedInputValue !== inputElement.value) {
        setInputValue(sanitizedInputValue);
      }
    }

    onBlur(e);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const inputElement = inputElementRef.current;
    if (e.key === 'Escape') {
      if (defaultValue) {
        setInputValue(defaultValue);
      } else {
        setInputValue('');
      }

      inputElement?.blur();
    }
    onKeyDown(e);
  };

  const handleWrapperClick = () => {
    inputElementRef.current?.focus();
  };

  return {
    handleBlur,
    handleKeyDown,
    handleWrapperClick,
  };
};
