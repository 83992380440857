import { createSelector } from 'reselect';

import { filterAndKeyBy } from '@float/common/lib/keyBy';
import { getSearchFilteredProjectsIds } from '@float/common/search/selectors/projects';
import { Phase } from '@float/types';

import { createSelectorWithShallowEqualSetResultCheck } from '../lib/createSelectorWithShallowEqualSetResultCheck';
import { getPhases } from '../phases';
import { getProjectsMapRaw } from './getProjectsMapRaw';

export const getSearchFilteredActiveProjectsIds =
  // OPTIMIZATION: When the results of consecutive calls are equals
  // keep the same referential identity to reduce the recoputations of the consumers
  createSelectorWithShallowEqualSetResultCheck(
    [getSearchFilteredProjectsIds, getProjectsMapRaw],
    (ids, projects) => {
      const set = new Set<number>();

      for (const id of ids) {
        if (projects[id].active) {
          set.add(id);
        }
      }

      return set;
    },
  );

export const getProjectFilteredPhasesMap = createSelector(
  [getPhases, getSearchFilteredActiveProjectsIds],
  (phases: Phase[], projects) =>
    filterAndKeyBy(phases, 'phase_id', (phase) =>
      projects.has(phase.project_id),
    ),
);
