import { isUndefined } from 'lodash';

export const formatToDbString = (
  decimalSep: string,
  thousandSep: string,
  minusSign: string,
  amount: number | string | null | undefined,
) => {
  if (amount === null) return null;
  if (isUndefined(amount)) return undefined;

  if (typeof amount === 'number') {
    amount = String(amount);
  }

  if (typeof amount !== 'string') {
    throw Error('Budgets MUST be strings');
  }

  if (!amount) {
    // empty string
    return '0';
  }

  const thousands = new RegExp(`\\${thousandSep}`, 'g');
  const decimal = new RegExp(`\\${decimalSep}`, 'g');

  // Sometimes people are using a comma as the decimal separator. ( ex 16,25 )
  // We should check for this case, otherwise the comma will be stripped
  // and not replaced by a correct decimal character.
  amount = handleCommaAndDotAmbiguty(thousandSep, decimalSep, amount);

  return (
    amount
      .replace(thousands, '')
      .replace(decimal, '.')
      // E.g. Swedish locale uses this minus sign - https://www.compart.com/en/unicode/U+2212
      .replace(minusSign, '-')
  );
};

const handleCommaAndDotAmbiguty = (
  thousandSep: string,
  decimalSep: string,
  amount: string,
) => {
  if (thousandSep !== ',') {
    return amount;
  }

  const decimal = new RegExp(`\\${decimalSep}`, 'g');
  const alreadyHasDecimal = decimal.test(amount);
  if (alreadyHasDecimal) {
    return amount;
  }

  const thousands = new RegExp(`\\${thousandSep}`, 'g');
  const hasThousandSep = thousands.test(amount);
  if (!hasThousandSep) {
    return amount;
  }

  const splited = amount.split(thousandSep);
  const numbersAfterLastComma = splited.pop()!;
  if (numbersAfterLastComma.length === 2) {
    amount = `${splited.join(
      thousandSep,
    )}${decimalSep}${numbersAfterLastComma}`;
  }

  if (numbersAfterLastComma.length === 1) {
    amount = `${splited.join(
      thousandSep,
    )}${decimalSep}${numbersAfterLastComma}`;
  }

  return amount;
};
