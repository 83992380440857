import styled from 'styled-components';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

import { StyledRow } from '../styles';

export const StyledColumnRow = styled(StyledRow)`
  display: flex;

  align-items: center;

  > :first-child {
    flex: 1;

    margin-right: 16px;
  }

  a {
    ${Typography.Label14.SB600};

    color: ${Colors.FIN.Lt.Buttons.Primary.Default.Bg};

    &:hover {
      color: ${Colors.FIN.Lt.Buttons.Primary.Hover.Bg};
    }
  }
`;
