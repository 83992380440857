import React, { memo } from 'react';
import cn from 'classnames';

import * as styles from './styles.css';

export type BlinkingTimeProps = {
  time: string;
  active?: boolean;
};

const BlinkingTime = memo(({ time, active = true }: BlinkingTimeProps) => {
  const [hours, minutes] = time.split(':');

  return (
    <>
      <span>{hours}</span>
      <span className={cn(styles.colon, { [styles.blink]: active })}>:</span>
      <span>{minutes}</span>
    </>
  );
});

export { BlinkingTime };
