import React, { PropsWithChildren, ReactNode } from 'react';

import PageHeader from './PageHeader';
import { StyledBody, StyledContent } from './styles';

export interface BodyProps {
  header: string;
  subheader: string;
  planUpsellBanner?: ReactNode;
  headerWidget?: ReactNode;
  fluid?: boolean;
  className?: string;
}

const Body = (props: PropsWithChildren<BodyProps>) => {
  const {
    children,
    header,
    subheader,
    planUpsellBanner = null,
    headerWidget,
    fluid = false,
    className = '',
  } = props;

  return (
    <StyledBody className={`${className}`} fluid={fluid}>
      {headerWidget}

      <PageHeader header={header} subheader={subheader} />

      {planUpsellBanner}

      <StyledContent>{children}</StyledContent>
    </StyledBody>
  );
};

export default Body;
