import React from 'react';

import { StepBase } from '../../StepBase';
import {
  StyledList,
  StyledListItem,
  StyledParagraph,
  StyledTitle,
} from '../../StepBase/styles';

const QuickControls = (props) => {
  const { data, userSpecs, ...otherProps } = props;

  return (
    <StepBase {...otherProps} id={data.id}>
      <StyledTitle>Customise your view</StyledTitle>
      <StyledParagraph>
        Set your preferences to see the timeline as you like it:
      </StyledParagraph>
      <StyledList>
        <StyledListItem>Choose between days, weeks, months</StyledListItem>

        {!userSpecs.canOnlyViewThemself && (
          <StyledListItem>Adjust row height settings</StyledListItem>
        )}
      </StyledList>
    </StepBase>
  );
};

export default QuickControls;
