import Cookies from 'js-cookie';

const getDevToolsEnabledFromCookies = () => {
  return Cookies.get('float-devtools-enabled') === '1';
};

export const markDevToolsAsEanbled = () => {
  // Using cookies as a mean to store a value accessible to all the float domains.
  // This way once the devTools are enabled they will be on for every team origin.
  Cookies.set('float-devtools-enabled', '1', {
    expires: 365,
    domain: '.float.com',
  });
};

export const devToolsEnabled =
  // Devtools are enabled if we are loading a development build
  // or if the development build has been loaded at least once
  process.env.NODE_ENV === 'development' || getDevToolsEnabledFromCookies();
