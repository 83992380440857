import React, { useContext, useMemo, useState } from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import cn from 'classnames';

import { preventDefault } from '@float/libs/utils/events/preventDefault';
import { noop } from '@float/libs/utils/noop';

import * as styles from './DropdownActions.css';

export type DropdownActionsProps = {
  align?: DropdownMenuPrimitive.DropdownMenuContentProps['align'];
  children?: React.ReactNode;
  className?: string;
  content?: React.ReactNode;
  side?: DropdownMenuPrimitive.DropdownMenuContentProps['side'];
  size?: 'medium' | 'small';
};

const DropdownContext = React.createContext<{
  setOpen: (isOpen: boolean) => void;
}>({ setOpen: noop });

export const DropdownActions = (props: DropdownActionsProps) => {
  const { align = 'start', side = 'bottom', size = 'medium' } = props;

  const [isOpen, setOpen] = useState(false);

  const ctx = useMemo(() => ({ setOpen }), []);

  return (
    <DropdownContext.Provider value={ctx}>
      <DropdownMenuPrimitive.Root onOpenChange={setOpen} open={isOpen}>
        <DropdownMenuPrimitive.Trigger className={props.className} asChild>
          {props.children}
        </DropdownMenuPrimitive.Trigger>
        <DropdownMenuPrimitive.Portal>
          <DropdownMenuPrimitive.Content
            align={align}
            className={styles.menu({ size })}
            loop
            onCloseAutoFocus={preventDefault}
            side={side}
            sideOffset={5}
          >
            {props.content}
          </DropdownMenuPrimitive.Content>
        </DropdownMenuPrimitive.Portal>
      </DropdownMenuPrimitive.Root>
    </DropdownContext.Provider>
  );
};

export type ItemProps = {
  className?: string;
  appearance?: 'default' | 'danger';
  children?: React.ReactNode;
  onClick?: DropdownMenuPrimitive.DropdownMenuItemProps['onClick'];
};

const Item = (props: ItemProps) => {
  const { appearance = 'default' } = props;
  const { setOpen } = useContext(DropdownContext);

  return (
    <DropdownMenuPrimitive.Item
      {...props}
      className={cn(
        props.className,
        styles.item({
          appearance,
        }),
      )}
      onClick={(e) => {
        setOpen(false);
        props.onClick?.(e);
      }}
    >
      {props.children}
    </DropdownMenuPrimitive.Item>
  );
};

const Separator = (
  props: DropdownMenuPrimitive.DropdownMenuSeparatorProps &
    React.RefAttributes<HTMLDivElement>,
) => {
  return (
    <DropdownMenuPrimitive.Separator
      className={cn(props.className, styles.separator)}
    />
  );
};

const Group = DropdownMenuPrimitive.Group;

DropdownActions.Item = Item;
DropdownActions.Separator = Separator;
DropdownActions.Group = Group;
