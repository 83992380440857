export enum Resources {
  IntercomHash = 'account/thirdparty/intercom',
  AccessPermissions = 'access',
  LinearCyclesIntegrationConfig = 'svc/integ/cycles/config',
  MyCompanies = 'company-api/my-companies',
  PageData = 'admin-api/page-data',
  MeApi = 'me-api',
  MePageData = 'me-api/page-data',
  SearchAutocompleteQuery = 'svc/search-api/query',
  SearchResolve = 'svc/search-api/resolve',
  SharePageData = 'share-api/page-data',
  SharePageExchangeToken = 'share-api/exchange-token',
  Timeframes = 'timeframes',
}

// Resync has its own path, but LinearCyclesIntegrationConfig should use this as its cache key
export const LINEAR_CYCLES_INTEGRATION_CONFIG_RESYNC_PATH =
  'svc/integ/cycles/resync';
