export enum FloatApp {
  Web = 'web',
  DesktopTimer = 'desktop-timer',
  Mobile = 'mobile',
}

export enum FloatAppPlatform {
  Web = 'web',
  Electron = 'electron',
  Mobile = 'mobile',
}
