import { useLocation } from 'react-router-dom';
import { t } from '@lingui/macro';
import { useManageModal } from 'modalManager/useManageModal';

import { IconCalendarTimer } from '@float/ui/icons/essentials/IconCalendarTimer';

import { useProject } from './useProject';

export function useShiftTimelineMenuItem() {
  const project = useProject();
  const { pathname } = useLocation();
  const { manageModalFromPanel } = useManageModal();

  const isSchedulePage = pathname === '/';
  if (!isSchedulePage) return undefined;

  function handleShiftTimeline() {
    manageModalFromPanel({
      visible: true,
      modalType: 'projectShiftModal',
      modalSettings: { project },
    });
  }

  return {
    value: t`Shift timeline`,
    icon: IconCalendarTimer,
    onClick: handleShiftTimeline,
  };
}
