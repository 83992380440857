import { StyleRule } from '@vanilla-extract/css';
import { css } from 'styled-components';

export const E400 = css`
  box-shadow:
    0px 10px 18px rgba(9, 30, 66, 0.15),
    0px 0px 1px rgba(95, 106, 126, 0.15);
`;

export const VE_E400: StyleRule = {
  boxShadow:
    '0px 10px 18px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(95, 106, 126, 0.15)',
};
