import {
  ensurePhaseLoaded,
  ensureProjectLoaded,
  ensureTemplateLoaded,
  fetchMilestonesForProject,
} from '@float/common/actions';
import { fetchTaskMetas } from '@float/common/actions/taskMetas';
import { AppDispatchStrict, AppStore } from '@float/common/store';

import { PhaseEditData } from '../types';

export async function fetchProjectData(
  store: AppStore,
  projectId: number | undefined,
) {
  if (!projectId) return;

  const { dispatch } = store;

  await Promise.all([
    dispatch(ensureProjectLoaded(projectId)),
    dispatch(fetchTaskMetas(projectId)),
    dispatch(fetchMilestonesForProject(projectId)),
  ]);
}

export async function fetchPhaseData(
  dispatch: AppDispatchStrict,
  ids?: PhaseEditData,
) {
  if (!ids) return;

  const promises = [];
  if (ids?.phaseId) {
    promises.push(dispatch(ensurePhaseLoaded(ids.phaseId)));
  }
  if (ids?.projectId) {
    promises.push(dispatch(ensureProjectLoaded(ids.projectId)));
  }

  if (ids?.phaseId && ids?.projectId) {
    promises.push(
      ...[
        dispatch(fetchTaskMetas(ids.projectId)),
        dispatch(fetchMilestonesForProject(ids.projectId)),
      ],
    );
  }

  await Promise.all(promises);
}

export async function fetchTemplateData(store: AppStore, templateId: number) {
  if (!templateId) return;

  const { dispatch } = store;

  await dispatch(ensureTemplateLoaded(templateId));
}
