export const joinUrl = ({
  url,
  hostname,
  version,
  prepend,
  preventRelativeHostname,
  type,
}) => {
  if (version === 'f3') {
    if (/^https:\/\/api.(.+\.)?float.com/.test(hostname)) {
      url = `/v3/${url}`;
    } else {
      url = `/svc/api3/v3/${url}`;
    }
  } else if (type === 'static') {
    url = `/${url}`;
  } else if (prepend) {
    return preventRelativeHostname
      ? `${hostname}${version || ''}/${url}`
      : `${hostname}${version}/${url}`;
  } else {
    url = `/${url}`;
  }
  return preventRelativeHostname ? `${hostname}${url}` : url;
};
