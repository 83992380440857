import React, { PropsWithChildren } from 'react';

import { SidePanelClose } from './SidePanelClose';

import * as styles from './styles.css';

type SidePanelHeaderProps = PropsWithChildren & {
  showCloseButton?: boolean;
  onClose?: () => void;
};

export const SidePanelHeader: React.FC<SidePanelHeaderProps> = ({
  children,
  showCloseButton = true,
  onClose,
}) => {
  return (
    <div className={styles.sidePanelHeader}>
      <div className={styles.sidePanelHeaderContent}>{children}</div>
      {showCloseButton && <SidePanelClose onClose={onClose} />}
    </div>
  );
};
