import { BudgetType } from '@float/types/project';
import { RangeBarCellValue } from '@float/ui/deprecated/AccordionTable/types';
import { ProjectPercentageModes } from '@float/ui/deprecated/Chart/types';

import { ReportsProject, SimplifiedReportsProject } from '../../table/types';
import { getRelevantBreakdownEntry } from './budget';

export function getRangeBar(
  projectPercentageMode: ProjectPercentageModes,
  reportsProject: ReportsProject | SimplifiedReportsProject,
  onlyBillable = false,
): RangeBarCellValue[] {
  if (reportsProject.budget === null || reportsProject.budget === undefined) {
    return [];
  }

  if (
    reportsProject.budget_type === BudgetType.HourlyFee ||
    reportsProject.budget_type === BudgetType.Disabled
  )
    return [];

  const fee = getRelevantBreakdownEntry(reportsProject, {
    percentageMode: projectPercentageMode,
    useBillable: onlyBillable,
    useFees: reportsProject.budget_type !== BudgetType.TotalHours,
  });

  if (fee > reportsProject.budget) {
    return [{ color: 'red', asPercent: true, value: 100 }];
  }

  if (reportsProject.budget === 0) {
    return [{ color: 'lightGrey', asPercent: true, value: 100 }];
  }

  const logged = fee / reportsProject.budget;
  const remaining = 1 - logged;

  return [
    {
      color:
        projectPercentageMode === ProjectPercentageModes.LOGGED
          ? 'purple'
          : 'teal',
      asPercent: true,
      value: logged * 100,
    },
    {
      color: 'lightGrey',
      asPercent: true,
      value: remaining * 100,
    },
  ];
}
