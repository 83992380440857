import { PromptId } from '@float/types/prompts';

export const getPromptsFromUrl = () => {
  const urlParams = new URLSearchParams(window.location?.search);

  if (urlParams.has('prompts')) {
    return urlParams
      .get('prompts')!
      .split(',')
      .filter((prompt) => prompt !== '' && !isNaN(parseInt(prompt)))
      .map((prompt) => Number(prompt)) as Array<PromptId>;
  }

  return null;
};
