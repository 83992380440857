import {
  failedFetchingEntitiesCreator,
  fetchedEntitiesCreator,
  fetchEntitiesCreator,
  shouldFetchEntitiesCreator,
} from './creatorHelpers';

export const FETCH_TIMEZONES = 'FETCH_TIMEZONES';
export const FETCH_TIMEZONES_SUCCESS = 'FETCH_TIMEZONES_SUCCESS';
export const FETCH_TIMEZONES_FAILURE = 'FETCH_TIMEZONES_FAILURE';

const PLURAL = 'timezones';

const fetchedTimezones = fetchedEntitiesCreator(FETCH_TIMEZONES_SUCCESS);

const failedFetchingTimezones = failedFetchingEntitiesCreator(
  FETCH_TIMEZONES_FAILURE,
);

const shouldFetchTimezones = shouldFetchEntitiesCreator(PLURAL);

export const fetchTimezones = fetchEntitiesCreator(
  FETCH_TIMEZONES,
  PLURAL,
  shouldFetchTimezones,
  fetchedTimezones,
  failedFetchingTimezones,
);
