import { moment } from '@float/libs/moment';
import { TimeString } from '@float/types/datesManager';

type GetHoursPerDayParams = {
  startTime: TimeString;
  endTime: TimeString;
};

export const getHoursPerDay = (params: GetHoursPerDayParams) => {
  const { startTime, endTime } = params;

  const start = moment(`2010-01-01 ${startTime}`);
  const end = moment(`2010-01-01 ${endTime}`);

  const minutes = end.diff(start, 'minutes');

  if (minutes < 0) return 0;

  return Number((minutes / 60).toFixed(2));
};
