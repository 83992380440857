import React, { useEffect, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import styled from 'styled-components';

import BottomWeekDays from '@float/common/components/DateSlider/BottomWeekDays';
import { moment } from '@float/libs/moment';

const BottomDatePickerWrapper = styled(animated.div)`
  user-select: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 100%;
  position: absolute;
`;

const WeekWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 100%;
  background: white;
  border-top: 1px solid #cccccc;
  height: 100%;
  position: absolute;
  left: ${(p) => p.colIdx * p.width}px;
`;

function useWeeks(day, dates, leftHiddenDays, rightHiddenDays) {
  const [weeks, setWeeks] = useState({});

  useEffect(() => {
    function getDaysInWeek(colIdx) {
      const firstDay = dates.fromDescriptor(colIdx, 0);
      let start = moment(firstDay).startOf('week');
      let end = moment(start).add(6, 'days');
      start = start.add(leftHiddenDays, 'days');
      end = end.add(-rightHiddenDays, 'days');

      const days = [];

      while (start.isSameOrBefore(end)) {
        days.push(start.format('YYYY-MM-DD'));
        start = start.add(1, 'day');
      }

      return days;
    }

    const [colIdx] = dates.toDescriptor(day);
    for (let i = colIdx - 1; i <= colIdx + 1; i++) {
      if (!weeks[i]) {
        const days = getDaysInWeek(i);
        setWeeks((prev) => ({ ...prev, [i]: days }));
      }
    }
  }, [day, dates, weeks, leftHiddenDays, rightHiddenDays]);

  return weeks;
}

export default function DateSlider(props) {
  const { dates, day, setDay, leftHiddenDays, rightHiddenDays, width } = props;
  const [colIdx, subCol] = dates.toDescriptor(day);
  const manuallyAdjustedRef = useRef(false);

  const weeks = useWeeks(day, dates, leftHiddenDays, rightHiddenDays);

  const [{ xy }, set] = useSpring(() => {
    return {
      xy: [colIdx * width, 0],
    };
  });

  // If the user resizes their browser small enough to trigger the single day
  // view, we need to adjust the xy position of the bottom slider based on the
  // current width.
  useEffect(() => {
    set({ xy: [colIdx * width, 0] });
  }, [width]); // eslint-disable-line

  useEffect(() => {
    if (manuallyAdjustedRef.current) {
      manuallyAdjustedRef.current = false;
      return;
    }

    set({ xy: [colIdx * width, 0] });
  }, [colIdx]); // eslint-disable-line

  const bind = useDrag(
    ({ event, down, delta, last }) => {
      if (down) {
        set({ xy: [colIdx * width - delta[0], 0] });
      }

      if (last) {
        if (delta[0] < -2 || delta[0] > 2) {
          event.preventDefault();
        }

        if (delta[0] > 40) {
          set({ xy: [(colIdx - 1) * width, 0] });
          manuallyAdjustedRef.current = true;
          setDay(dates.fromDescriptor(colIdx - 1, subCol));
        } else if (delta[0] < -40) {
          set({ xy: [(colIdx + 1) * width, 0] });
          manuallyAdjustedRef.current = true;
          setDay(dates.fromDescriptor(colIdx + 1, subCol));
        } else {
          // The user didn't drag far enough to trigger a week swipe, abort.
          set({ xy: [colIdx * width, 0] });
        }
      }
    },
    { event: { passive: false, capture: true } },
  );

  return (
    <BottomDatePickerWrapper
      {...bind()}
      style={{
        left: xy.interpolate((x, y) => `-${x}px`),
      }}
    >
      <WeekWrapper colIdx={colIdx - 1} width={width}>
        <BottomWeekDays
          setActiveDay={setDay}
          activeDay={day}
          days={weeks[colIdx - 1]}
        />
      </WeekWrapper>
      <WeekWrapper colIdx={colIdx} width={width}>
        <BottomWeekDays
          setActiveDay={setDay}
          activeDay={day}
          days={weeks[colIdx]}
        />
      </WeekWrapper>
      <WeekWrapper colIdx={colIdx + 1} width={width}>
        <BottomWeekDays
          setActiveDay={setDay}
          activeDay={day}
          days={weeks[colIdx + 1]}
        />
      </WeekWrapper>
    </BottomDatePickerWrapper>
  );
}
