import { useMemo } from 'react';

import { Rights } from '@float/common/lib/acl';
import { CurrentUser, SavedView } from '@float/types';

import { AccessControlProps } from '../components/types';

export type UseViewsACLProps = {
  user: CurrentUser;
  views: SavedView[];
  changes?: Partial<SavedView>;
  isStarterPlan: boolean;
};

function userCanDelete(
  entity: SavedView,
  props: Pick<UseViewsACLProps, 'user'>,
) {
  return Rights.canDeleteView(props.user, {
    entity,
  });
}

function userCanEdit(
  entity: SavedView,
  props: Pick<UseViewsACLProps, 'user' | 'changes'>,
) {
  return Rights.canUpdateView(props.user, {
    entity,
    changes: props.changes,
  });
}

function userCanShare(
  entity: SavedView,
  props: Pick<UseViewsACLProps, 'user' | 'views'>,
) {
  if (entity.personal === false) return false;

  const canShare = Rights.canUpdateView(props.user, {
    entity,
    changes: {
      personal: false,
    },
  });

  if (!canShare) return false;

  const name = entity.name.toLowerCase();

  return props.views.every((view) => {
    if (view.id === entity.id) return true;

    return view.name.toLowerCase() !== name;
  });
}

export function useViewsACL({
  user,
  views,
  isStarterPlan,
}: UseViewsACLProps): AccessControlProps {
  return useMemo(
    () => ({
      canDelete(entity) {
        return userCanDelete(entity, { user });
      },
      canEdit(entity, changes) {
        return userCanEdit(entity, { user, changes });
      },
      canShare(entity) {
        return !isStarterPlan && userCanShare(entity, { user, views });
      },
      isStarterPlan,
    }),
    [user, views, isStarterPlan],
  );
}
