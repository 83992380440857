import React from 'react';
import { connect } from 'react-redux';

import { DropdownSelect } from '@float/ui/deprecated';

import { changeFilter, changeSort, FILTER_TYPES } from '../actions';

export const DROPDOWN_TYPES = {
  SORT: 'SORT',
  FILTER: 'FILTER',
};

function PmSidebarDropdown({
  type = DROPDOWN_TYPES.SORT,
  sortValue,
  filterValue,
  options,
  onChangeSort,
  onChangeFilter,
}) {
  const onChangeFunc =
    type === DROPDOWN_TYPES.SORT ? onChangeSort : onChangeFilter;
  const value = type === DROPDOWN_TYPES.SORT ? sortValue : filterValue;

  return (
    <DropdownSelect
      value={value}
      options={options}
      align="bottom-left"
      tickSelectedOption
      tickRight
      onChange={onChangeFunc}
      truncateAt={180}
    />
  );
}

const mapStateToProps = (state) => ({
  sortValue: state.pmSidebar.sortBy,
  filterValue: state.pmSidebar.filter[FILTER_TYPES.DROPDOWN].value,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeSort: ({ value, order }) => dispatch(changeSort(value, order)),
  onChangeFilter: ({ key, value }) =>
    dispatch(changeFilter({ filterType: FILTER_TYPES.DROPDOWN, key, value })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PmSidebarDropdown);
