import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';
import { Trans } from '@lingui/macro';
import { Transition, TransitionRef } from 'TransitionManager';

import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { OnboardingPrompt } from '@float/web/store/onboardingManager/types';

import { useAnimatedElements } from './hooks/useAnimatedElements';
import { useOnEnterAnimation } from './hooks/useOnEnterAnimation';
import { useOnExitAnimation } from './hooks/useOnExitAnimation';
import { SuccessCheck } from './SuccessCheck';
import { SuccessIllustration } from './SuccessIllustration';

import * as styles from './styles.css';

export const OnboardingSurveySuccess = forwardRef<
  TransitionRef,
  OnboardingPrompt
>((props, ref) => {
  const { transitionId, onClickNext } = props;

  const transitionRef = useRef<TransitionRef>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const { animatedElements } = useAnimatedElements(contentRef);

  const leave = useCallback(() => {
    if (onClickNext) onClickNext({ fromOnboardingSurvey: true }, false);
  }, [onClickNext]);

  const { onEnter } = useOnEnterAnimation(animatedElements, leave);
  const { onExit } = useOnExitAnimation(animatedElements);

  useImperativeHandle(ref, () => ({
    ...(transitionRef.current as TransitionRef),
    onEnter,
    onExit,
  }));

  return (
    <Transition ref={transitionRef} id={transitionId}>
      <div className={styles.success} ref={contentRef}>
        <div className={styles.content}>
          <div className={styles.row}>
            <h1 className={styles.h1}>
              {featureFlags.isFeatureEnabled(
                FeatureFlag.OnboardingExampleData,
              ) ? (
                <Trans>Let’s jump in...</Trans>
              ) : (
                <Trans>Nice work! Let’s jump in...</Trans>
              )}
            </h1>

            <SuccessCheck />
          </div>

          <div className={styles.row}>
            <SuccessIllustration />
          </div>
        </div>
      </div>
    </Transition>
  );
});
