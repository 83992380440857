import React, { Component, useState } from 'react';
import { t } from '@lingui/macro';

import Button from '@float/ui/deprecated/Button/Button';

import ModalActions from './ModalActions/ModalActions';
import ModalBody from './ModalBody/ModalBody';
import Modal from './ModalDialog/ModalDialog';
import ModalHeader from './ModalHeader/ModalHeader';
import ModalTitle from './ModalTitle/ModalTitle';
import { ConfirmOptions } from './types';

import * as styles from './styles.css';

type ConfirmProps = ConfirmOptions & {
  close: () => void;
};

const Confirm: React.FC<ConfirmProps> = ({
  title,
  message,
  confirmButtonAppearance,
  confirmLabel,
  cancelLabel,
  hideConfirm,
  hideCancel,
  showLoaderOnConfirm,
  onConfirm,
  onCancel,
  close,
  center,
}) => {
  const [loader, setLoader] = useState(false);

  return (
    <Modal
      isOpen={true}
      shouldCloseOnBgClick={false}
      shouldCloseOnEsc={true}
      center={center}
      onClose={onCancel}
    >
      {title && (
        <ModalHeader className={styles.confirmHeader}>
          <ModalTitle>{title}</ModalTitle>
        </ModalHeader>
      )}
      {message && (
        <ModalBody className={styles.confirmBody}>{message}</ModalBody>
      )}
      <ModalActions className={styles.confirmActions}>
        {!hideConfirm && (
          <Button
            appearance={confirmButtonAppearance}
            autoFocus
            loader={loader}
            onClick={() => {
              if (showLoaderOnConfirm) {
                setLoader(true);
              } else {
                close();
              }
              if (onConfirm) {
                onConfirm();
              }
            }}
          >
            {confirmLabel}
          </Button>
        )}
        {!hideCancel && (
          <Button appearance="secondary" onClick={onCancel}>
            {cancelLabel}
          </Button>
        )}
      </ModalActions>
    </Modal>
  );
};

const getDefaultOptions = () => ({
  message: t`Are you sure you want to do this?`,
  confirmLabel: t`OK`,
  cancelLabel: t`Cancel`,
  hideConfirm: false,
  hideCancel: false,
  showLoaderOnConfirm: false,
});

export type WithConfirmExtraProps = {
  confirm: (options: ConfirmOptions) => void;
  confirmClose: () => void;
};

type ConfirmWrapperProps = {
  innerRef?: React.RefObject<unknown>;
};

export const withConfirm = <P extends WithConfirmExtraProps>(
  Comp: React.ComponentType<P>,
) => {
  return class WithConfirm extends Component<
    Omit<P, keyof WithConfirmExtraProps> & ConfirmWrapperProps,
    { isOpen: boolean }
  > {
    confirmOptions: ConfirmOptions = {
      ...getDefaultOptions(),
      onConfirm: () => {},
    };

    state = {
      isOpen: false,
    };

    confirm = (options: ConfirmOptions) => {
      this.confirmOptions = {
        ...getDefaultOptions(),
        ...options,
      };
      this.setState({ isOpen: true });
    };

    close = () => {
      this.setState({ isOpen: false });
    };

    onCancel = () => {
      this.close();
      if (typeof this.confirmOptions.onCancel === 'function') {
        this.confirmOptions.onCancel();
      }
    };

    render() {
      const { innerRef, ...props } = this.props;
      return (
        <>
          <Comp
            {...(props as P)}
            ref={innerRef}
            confirm={this.confirm}
            confirmClose={this.close}
          />
          {this.state.isOpen && (
            <Confirm
              {...this.confirmOptions}
              close={this.close}
              onCancel={this.onCancel}
            />
          )}
        </>
      );
    }
  };
};

export default withConfirm;
