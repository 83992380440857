import React, { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import styled from 'styled-components';

import logoSrc from '@float/web/assets/images/asana_logo.svg';
import {
  clearAsanaPreload,
  fetchAsanaCoInts,
  fetchAsanaConfig,
  fetchAsanaOAuthLink,
  fetchAsanaPreload,
  retryAsanaPeopleSync,
  updateAsanaConfig,
} from '@float/web/integrations/actions/asana';

import Subsection from '../shared/Subsection';

const Logo = styled.img`
  display: block;
  max-width: 131px;
`;

function AsanaSubsection(props) {
  const [error, setError] = useState(null);
  const { integration } = props;
  const { coInts } = integration;

  if (coInts && coInts.length) {
    const coInt = coInts[0];
    if (_.has(coInt, 'ancillary.errors.ASANA_NOT_ORG_MEMBER')) {
      setError({
        editLink: true,
        message:
          "To connect the Asana integration, you need to be an 'Organization Member' of your Asana account.",
      });
    } else if (error) {
      setError(null);
    }
  }

  return (
    <Subsection
      label="Asana"
      type="asana"
      logo={<Logo src={logoSrc} />}
      authenticationErrorMessage="To connect the Asana integration, you need to be an 'Organization Member' of your Asana account."
      error={error}
      {...props}
    >
      Import and sync your{' '}
      <a
        href="https://www.asana.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Asana
      </a>{' '}
      people and projects to kick start your scheduling.{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://support.float.com/en/articles/4278155-asana"
      >
        Learn more
      </a>
      .
    </Subsection>
  );
}

const mapStateToProps = (state) => ({
  integration: state.integrations.asana,
});

const mapDispatchToProps = (dispatch) => ({
  fetchOAuthLink: () => dispatch(fetchAsanaOAuthLink()),
  fetchCoInts: () => dispatch(fetchAsanaCoInts()),
  fetchPreload: (coIntId) => dispatch(fetchAsanaPreload(coIntId)),
  retryPeopleSync: (coIntId) => dispatch(retryAsanaPeopleSync(coIntId)),
  fetchConfig: (coIntId) => dispatch(fetchAsanaConfig(coIntId)),
  updateConfig: (coInt, config, options) =>
    dispatch(updateAsanaConfig(coInt, config, options)),
  clearPreload: () => dispatch(clearAsanaPreload()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AsanaSubsection);
