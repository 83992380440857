import { saveAs } from 'file-saver';
import { map } from 'lodash';

import { moment } from '@float/libs/moment';

function formatDate(str, format = 'DD MMM YYYY') {
  return moment(str).format(format);
}

function buildHeaderRows(companyName, extraData) {
  const filters = extraData.filters
    ? map(extraData.filters, (val, key) => `${key}: ${val}`).join(', ')
    : 'None';

  const start = formatDate(extraData.dateRange.start);
  const end = formatDate(extraData.dateRange.end);

  return [
    [companyName],
    ['Exported on', '', formatDate(undefined, 'DD MMM YYYY LT')],
    [],
    ['Date range', '', `${start} - ${end}`],
    ['Filters', '', filters],
    [],
  ];
}

function buildActivityRowHeader() {
  return ['Date/Time', 'Actioned by', 'Activity'];
}

function generateRows(result, companyName, extraData) {
  return [
    ...buildHeaderRows(companyName, extraData),
    buildActivityRowHeader(),
    ...result,
  ];
}

function getSheetData(result, companyName, extraData) {
  const filename = `float-activity-${moment().format('YYYYMMDD-HHmmss')}.csv`;
  return { filename, sheetData: generateRows(result, companyName, extraData) };
}

export async function exportCsv(result, companyName, extraData = {}) {
  return import('xlsx').then((XLSX) => {
    const { filename, sheetData } = getSheetData(
      result,
      companyName,
      extraData,
    );

    const ws = XLSX.utils.aoa_to_sheet(sheetData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Float');
    const wbout = XLSX.write(wb, { type: 'array', bookType: 'csv' });

    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), filename);
  });
}
