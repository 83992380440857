import React from 'react';
import styled from 'styled-components';

const SvgContainer = styled.div<{ mb?: string }>`
  width: 100%;

  margin-bottom: ${(p) => (p.mb ? `${p.mb}px` : '0px')};
`;

export const DowngradeToStarterIcon = () => (
  <SvgContainer mb="16">
    <svg xmlns="http://www.w3.org/2000/svg" width="112" height="91" fill="none">
      <rect width="88" height="88" fill="#F3F9FF" rx="16" />
      <rect
        width="62.6"
        height="18.6"
        x="5.2"
        y="44.2"
        fill="#96C7F2"
        stroke="#242C39"
        strokeWidth=".4"
        rx="9.3"
      />
      <rect
        width="62.6"
        height="18.6"
        x="19.2"
        y="25.2"
        fill="#60AAEA"
        stroke="#242C39"
        strokeWidth=".4"
        rx="9.3"
      />
      <path
        fill="#E5484D"
        stroke="#000"
        strokeWidth=".8"
        d="M84.795 59.264 71.246 85.629C70.22 87.625 71.67 90 73.915 90h26.731c2.23 0 3.681-2.347 2.683-4.342L90.147 59.293c-1.1-2.197-4.229-2.215-5.352-.03Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth=".8"
        d="m86.372 79.6-.942-13.2h4.14l-.942 13.2h-2.256Z"
      />
      <circle
        cx="87.5"
        cy="83.5"
        r="2.1"
        fill="#fff"
        stroke="#000"
        strokeWidth=".8"
      />
    </svg>
  </SvgContainer>
);

export const DowngradeFromEnterpriseIcon = () => (
  <SvgContainer mb="16">
    <svg xmlns="http://www.w3.org/2000/svg" width="112" height="92" fill="none">
      <rect width="88" height="88" y="1" fill="#F1FCFA" rx="16" />
      <rect
        width="45.527"
        height="13.527"
        x="19.418"
        y="52.691"
        fill="#A5E4D4"
        stroke="#242C39"
        strokeWidth=".291"
        rx="6.764"
      />
      <rect
        width="45.527"
        height="13.527"
        x="29.6"
        y="38.873"
        fill="#77D3BC"
        stroke="#242C39"
        strokeWidth=".291"
        rx="6.764"
      />
      <rect
        width="45.527"
        height="13.527"
        x="12.873"
        y="25.055"
        fill="#57C1A6"
        stroke="#242C39"
        strokeWidth=".291"
        rx="6.764"
      />
      <path
        fill="#E5484D"
        stroke="#000"
        strokeWidth=".8"
        d="M84.795 60.264 71.246 86.629C70.22 88.625 71.67 91 73.915 91h26.731c2.23 0 3.681-2.347 2.683-4.342L90.147 60.293c-1.1-2.197-4.229-2.215-5.352-.03Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth=".8"
        d="m86.372 80.6-.942-13.2h4.14l-.942 13.2h-2.256Z"
      />
      <circle
        cx="87.5"
        cy="84.5"
        r="2.1"
        fill="#fff"
        stroke="#000"
        strokeWidth=".8"
      />
    </svg>
  </SvgContainer>
);

export const UpgradeToProIcon = () => (
  <SvgContainer>
    <svg xmlns="http://www.w3.org/2000/svg" width="112" height="96" fill="none">
      <rect width="88" height="88" fill="#F3F9FF" rx="16" />
      <rect
        width="62.6"
        height="18.6"
        x="5.2"
        y="44.2"
        fill="#96C7F2"
        stroke="#242C39"
        strokeWidth=".4"
        rx="9.3"
      />
      <rect
        width="62.6"
        height="18.6"
        x="19.2"
        y="25.2"
        fill="#60AAEA"
        stroke="#242C39"
        strokeWidth=".4"
        rx="9.3"
      />
      <circle
        cx="90"
        cy="79"
        r="16.6"
        fill="#40C4AA"
        stroke="#000"
        strokeWidth=".8"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="3"
        d="m83 81.548 3.795 3.979c.573.601 1.615.287 1.83-.516C89.637 81.206 92.18 75.685 97 74"
      />
    </svg>
  </SvgContainer>
);

export const UpgradeToEnterpriseIcon = () => (
  <SvgContainer>
    <svg
      width="112"
      height="96"
      viewBox="0 0 112 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="1" width="88" height="88" rx="16" fill="#F1FCFA" />
      <circle
        cx="90"
        cy="79"
        r="16.6"
        fill="#40C4AA"
        stroke="black"
        strokeWidth="0.8"
      />
      <path
        d="M83 81.5484L86.795 85.527C87.3682 86.128 88.4104 85.814 88.6242 85.0115C89.6379 81.2064 92.1797 75.6845 97 74"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <rect
        x="19.4182"
        y="52.6909"
        width="45.5273"
        height="13.5273"
        rx="6.76364"
        fill="#A5E4D4"
        stroke="#242C39"
        strokeWidth="0.290909"
      />
      <rect
        x="29.6"
        y="38.8727"
        width="45.5273"
        height="13.5273"
        rx="6.76364"
        fill="#77D3BC"
        stroke="#242C39"
        strokeWidth="0.290909"
      />
      <rect
        x="12.8727"
        y="25.0545"
        width="45.5273"
        height="13.5273"
        rx="6.76364"
        fill="#57C1A6"
        stroke="#242C39"
        strokeWidth="0.290909"
      />
    </svg>
  </SvgContainer>
);
