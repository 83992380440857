import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M5.477 17.5h2.546c.401 0 .727-.34.727-.759V7.26c0-.42-.326-.759-.727-.759H5.477c-.401 0-.727.34-.727.759v9.482c0 .42.326.759.727.759Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M15.977 17.5h2.546c.401 0 .727-.34.727-.759V7.26c0-.42-.326-.759-.727-.759h-2.546c-.401 0-.727.34-.727.759v9.482c0 .42.326.759.727.759Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12.5 18a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12.5 6a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12.5 10a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12.5 14a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
    />
  </BaseIcon>
);
