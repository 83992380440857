import React, { PropsWithChildren, useContext } from 'react';
import {
  FieldArrayPath,
  FieldValues,
  useFieldArray,
  UseFieldArrayReturn,
} from 'react-hook-form';

import { ProjectFormData } from '../types';

const UseFieldArrayContext = React.createContext<UseFieldArrayReturn<
  FieldValues,
  FieldArrayPath<FieldValues>
> | null>(null);

type UseFieldContextProps = {
  name: FieldArrayPath<ProjectFormData>;
};

/**
 * useFieldArray hook maintains its own unique state so it cannot be called with the same name in
 * multiple parts of the component tree.
 * @param name a field array path within ProjectFormData
 * @returns fieldArrayMethods when useFieldArrayContext is called
 */
export const UseFieldArrayProvider: React.FC<
  PropsWithChildren<UseFieldContextProps>
> = ({ name, children }) => {
  const fieldArrayMethods = useFieldArray<FieldValues, string>({
    name,
  });

  return (
    <UseFieldArrayContext.Provider value={fieldArrayMethods}>
      {children}
    </UseFieldArrayContext.Provider>
  );
};

// Ideally the types will allow us to use it exactly like useFieldArray signature
export function useFieldArrayContext<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends
    FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
>(): UseFieldArrayReturn<TFieldValues, TFieldArrayName> {
  const fieldArrayContext = useContext<
    UseFieldArrayReturn<TFieldValues, TFieldArrayName>
  >(
    //@ts-expect-error throwing ts error because context initializes as null
    UseFieldArrayContext,
  );

  if (!fieldArrayContext) {
    throw new Error(
      'useFieldArrayContext has to be used within <UseFieldArrayProvider>',
    );
  }

  return fieldArrayContext;
}
