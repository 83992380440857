import React, { forwardRef } from 'react';
import cx from 'classnames';

import { noop } from '@float/libs/utils/noop';

import { StyledInput } from './styles';

const InputText = forwardRef((props, ref) => {
  const {
    type = 'text',
    defaultValue = '',
    className,
    alignment = 'left',
    onChange = noop,
    ...other
  } = props;

  const onChangeCallback = (e) => {
    onChange(e.target.value);
  };

  return (
    <StyledInput
      className={cx(className, 'eh-input')}
      ref={ref}
      type={type}
      alignment={alignment}
      defaultValue={defaultValue}
      onChange={onChangeCallback}
      {...other}
    />
  );
});

export default InputText;
