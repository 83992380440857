import React from 'react';
import { isEmpty, without } from 'lodash';

import { moment } from '@float/libs/moment';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { ButtonAction } from '@float/ui/components/ButtonAction';
import { Button, DatePicker, Input, Modal } from '@float/ui/deprecated';
import * as Icons from '@float/ui/deprecated/Earhart/Icons';

import {
  formatMilestoneDate,
  getSortedMilestones,
} from '../ProjectModal.helpers';
import { MilestoneEditModal } from './components/MilestoneEditModal';
import {
  ButtonWrapper,
  DateContainer,
  InputWrapper,
  NoMilestones,
  ScrollableList,
} from './MilestonesFragment.styles';

function handleChangeMilestoneStartDate(date) {
  this.setState((ps) => {
    const endDate = date.isAfter(ps.milestoneAdd.endDate)
      ? date.clone()
      : ps.milestoneAdd.endDate;

    return {
      milestoneAdd: { ...ps.milestoneAdd, startDate: date, endDate },
    };
  });
}

function handleChangeMilestoneEndDate(date) {
  this.setState((ps) => {
    const startDate = date.isBefore(ps.milestoneAdd.startDate)
      ? date.clone()
      : ps.milestoneAdd.startDate;

    return {
      milestoneAdd: { ...ps.milestoneAdd, startDate, endDate: date },
    };
  });
}

function addMilestone(evt) {
  prevent(evt);
  if (!this.state.milestoneAdd.name) return;

  this.setState(
    (ps) => ({
      milestoneAdd: {
        ...ps.milestoneAdd,
        name: '',
      },
      form: {
        ...ps.form,
        newMilestones: [
          ...ps.form.newMilestones,
          {
            milestone_id: `${Date.now()}`,
            isNew: true,
            name: ps.milestoneAdd.name,
            date: ps.milestoneAdd.startDate.format('YYYY-MM-DD'),
            end_date: ps.milestoneAdd.endDate.format('YYYY-MM-DD'),
          },
        ],
      },
    }),
    () => this.milestoneNameRef.current.focusInput(),
  );
}

function removeMilestone(milestone) {
  return (evt) => {
    prevent(evt);
    this.setState((ps) => ({
      form: {
        ...ps.form,
        newMilestones: ps.form.newMilestones.filter(
          (m) => m.milestone_id !== milestone.milestone_id,
        ),
      },
      promptModal: null,
    }));
  };
}

function editMilestone(milestone) {
  return (evt) => {
    prevent(evt);

    // Note: When creating a new project, we won't have a project id yet. We'll
    // set -1 so that validation inside the modal passes, and we'll replace it
    // with the correct id when we save.
    const projectId = milestone.project_id || -1;

    this.setPromptModal(
      <MilestoneEditModal
        id={milestone.milestone_id}
        projectId={projectId}
        phaseId={milestone.phase_id}
        name={milestone.name}
        startDate={moment(milestone.date)}
        endDate={moment(milestone.end_date)}
        onCancel={() => this.setPromptModal(null)}
        onUpdate={(updatedMilestone) => {
          this.setState(
            (ps) => {
              const m = ps.form.newMilestones.find(
                (ms) => ms.milestone_id === updatedMilestone.id,
              );

              const newMilestone = {
                ...m,
                name: updatedMilestone.name,
                date: updatedMilestone.startDate.format('YYYY-MM-DD'),
                end_date: updatedMilestone.endDate.format('YYYY-MM-DD'),
              };

              const newMilestones = [
                ...without(ps.form.newMilestones, m),
                newMilestone,
              ];

              return { form: { ...ps.form, newMilestones } };
            },
            () => this.setPromptModal(null),
          );
        }}
        onRemove={removeMilestone.bind(this)(milestone)}
      />,
    );
  };
}

export default function MilestonesFragment() {
  const milestones = getSortedMilestones(this.state.form.newMilestones);

  return (
    <>
      <Modal.TopGreySection style={{ display: 'flex', flexWrap: 'wrap' }}>
        <InputWrapper>
          <Input
            ref={this.milestoneNameRef}
            label="Milestone name"
            autoFocus
            value={this.state.milestoneAdd.name}
            onChange={(evt) => {
              const { value } = evt.currentTarget;
              this.setState((ps) => ({
                milestoneAdd: {
                  ...ps.milestoneAdd,
                  name: value,
                },
              }));
            }}
          />
        </InputWrapper>
        <DateContainer>
          <DatePicker
            label="From"
            value={this.state.milestoneAdd.startDate}
            onChange={handleChangeMilestoneStartDate.bind(this)}
          />
        </DateContainer>
        <DateContainer style={{ marginRight: 0 }}>
          <DatePicker
            label="To"
            value={this.state.milestoneAdd.endDate}
            onChange={handleChangeMilestoneEndDate.bind(this)}
          />
        </DateContainer>
        <ButtonWrapper>
          <Button onClick={addMilestone.bind(this)}>Add milestone</Button>
        </ButtonWrapper>
      </Modal.TopGreySection>

      <ScrollableList>
        {milestones.map((milestone) => (
          <li key={milestone.milestone_id}>
            <div className="text-content">
              <span className="name">{milestone.name}</span>
              <br />
              <span className="date">{formatMilestoneDate(milestone)}</span>
            </div>
            <div className="controls">
              <ButtonAction
                icon={Icons.IconPen}
                onClick={editMilestone.bind(this)(milestone)}
              />
              <ButtonAction
                icon={Icons.IconClose}
                onClick={removeMilestone.bind(this)(milestone)}
              />
            </div>
          </li>
        ))}
        {isEmpty(milestones) && (
          <NoMilestones>
            There are no milestones for this{' '}
            {this.isPhase ? 'phase' : 'project'}.
          </NoMilestones>
        )}
      </ScrollableList>
    </>
  );
}
