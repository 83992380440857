import { fastObjectSpread } from '@float/common/lib/fast-object-spread';
import { omit } from '@float/common/lib/omit';
import { TagGroup } from '@float/types';

import {
  TAGS_GROUPS_CREATED,
  TAGS_GROUPS_DELETED,
  TAGS_GROUPS_LOAD_FAILED,
  TAGS_GROUPS_LOAD_FINISHED,
  TAGS_GROUPS_LOAD_STARTED,
  TAGS_GROUPS_UPDATED,
} from '../actions';

export type TagsGroupsState = {
  tagsGroups: Record<number, TagGroup>;
  loadState: 'UNLOADED' | 'LOADED' | 'LOAD_FAILED' | 'LOADING';
};

export const DEFAULT_STATE: TagsGroupsState = {
  tagsGroups: {},
  loadState: 'UNLOADED',
};

export type TagsGroupsAction =
  | {
      type: typeof TAGS_GROUPS_LOAD_STARTED;
    }
  | {
      type: typeof TAGS_GROUPS_LOAD_FAILED;
    }
  | {
      type: typeof TAGS_GROUPS_LOAD_FINISHED;
      tagsGroups: TagGroup[];
    }
  | {
      type: typeof TAGS_GROUPS_UPDATED;
      tagsGroups: TagGroup[];
    }
  | {
      type: typeof TAGS_GROUPS_CREATED;
      tagsGroups: TagGroup[];
    }
  | {
      type: typeof TAGS_GROUPS_DELETED;
      tagsGroups: TagGroup[];
    };

function assignTagsGroups(map: Record<number, TagGroup>, list: TagGroup[]) {
  for (const tagGroup of list) {
    map[tagGroup.id] = tagGroup;
  }

  return map;
}

export function tagsGroupsReducer(
  state = DEFAULT_STATE,
  action: TagsGroupsAction,
): TagsGroupsState {
  switch (action.type) {
    case TAGS_GROUPS_LOAD_STARTED: {
      return {
        ...state,
        loadState: 'LOADING',
      };
    }

    case TAGS_GROUPS_LOAD_FAILED: {
      return {
        ...state,
        loadState: 'LOAD_FAILED',
      };
    }

    case TAGS_GROUPS_LOAD_FINISHED: {
      if (!action.tagsGroups?.length)
        return {
          ...state,
          loadState: 'LOADED',
        };

      return {
        ...state,
        loadState: 'LOADED',
        tagsGroups: assignTagsGroups(fastObjectSpread(), action.tagsGroups),
      };
    }

    case TAGS_GROUPS_UPDATED:
    case TAGS_GROUPS_CREATED: {
      return {
        ...state,
        tagsGroups: assignTagsGroups(
          fastObjectSpread(state.tagsGroups),
          action.tagsGroups,
        ),
      };
    }

    case TAGS_GROUPS_DELETED: {
      const tagsGroupsToRemove = action.tagsGroups.map((tagGroup) =>
        tagGroup.id.toString(),
      );

      return {
        ...state,
        tagsGroups: omit(state.tagsGroups, tagsGroupsToRemove),
      };
    }

    default: {
      return state;
    }
  }
}
