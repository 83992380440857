export const TWELVE_HOUR_FORMAT = 'hh:mmaaa';
export const TWENTY_FOUR_HOUR_FORMAT = 'HH:mm';

// https://www.redcort.com/its-about-time/hours-and-minutes-versus-decimal-time
export const DECIMAL_HOUR_TO_MINUTE: { [key: number]: number } = {
  0: 0,
  0.01: 1,
  0.02: 1,
  0.03: 2,
  0.04: 2,
  0.05: 3,
  0.06: 4,
  0.07: 4,
  0.08: 5,
  0.09: 5,
  0.1: 6,
  0.11: 7,
  0.12: 7,
  0.13: 8,
  0.14: 8,
  0.15: 9,
  0.16: 10,
  0.17: 10,
  0.18: 11,
  0.19: 11,
  0.2: 12,
  0.21: 13,
  0.22: 13,
  0.23: 14,
  0.24: 14,
  0.25: 15,
  0.26: 16,
  0.27: 16,
  0.28: 17,
  0.29: 17,
  0.3: 18,
  0.31: 19,
  0.32: 19,
  0.33: 20,
  0.34: 20,
  0.35: 21,
  0.36: 22,
  0.37: 22,
  0.38: 23,
  0.39: 23,
  0.4: 24,
  0.41: 25,
  0.42: 25,
  0.43: 26,
  0.44: 26,
  0.45: 27,
  0.46: 28,
  0.47: 28,
  0.48: 29,
  0.49: 29,
  0.5: 30,
  0.51: 31,
  0.52: 31,
  0.53: 32,
  0.54: 32,
  0.55: 33,
  0.56: 34,
  0.57: 34,
  0.58: 35,
  0.59: 35,
  0.6: 36,
  0.61: 37,
  0.62: 37,
  0.63: 38,
  0.64: 38,
  0.65: 39,
  0.66: 40,
  0.67: 40,
  0.68: 41,
  0.69: 41,
  0.7: 42,
  0.71: 43,
  0.72: 43,
  0.73: 44,
  0.74: 44,
  0.75: 45,
  0.76: 46,
  0.77: 46,
  0.78: 47,
  0.79: 47,
  0.8: 48,
  0.81: 49,
  0.82: 49,
  0.83: 50,
  0.84: 50,
  0.85: 51,
  0.86: 52,
  0.87: 52,
  0.88: 53,
  0.89: 53,
  0.9: 54,
  0.91: 55,
  0.92: 55,
  0.93: 56,
  0.94: 56,
  0.95: 57,
  0.96: 58,
  0.97: 58,
  0.98: 59,
  0.99: 59,
  1: 60,
};

export const MINUTE_TO_DECIMAL_HOUR: { [key: number]: number } = {
  0: 0,
  1: 0.02,
  2: 0.04,
  3: 0.05,
  4: 0.07,
  5: 0.09,
  6: 0.1,
  7: 0.12,
  8: 0.14,
  9: 0.15,
  10: 0.17,
  11: 0.19,
  12: 0.2,
  13: 0.22,
  14: 0.24,
  15: 0.25,
  16: 0.27,
  17: 0.29,
  18: 0.3,
  19: 0.32,
  20: 0.34,
  21: 0.35,
  22: 0.37,
  23: 0.39,
  24: 0.4,
  25: 0.42,
  26: 0.44,
  27: 0.45,
  28: 0.47,
  29: 0.49,
  30: 0.5,
  31: 0.52,
  32: 0.54,
  33: 0.55,
  34: 0.57,
  35: 0.59,
  36: 0.6,
  37: 0.62,
  38: 0.64,
  39: 0.65,
  40: 0.67,
  41: 0.69,
  42: 0.7,
  43: 0.72,
  44: 0.74,
  45: 0.75,
  46: 0.77,
  47: 0.79,
  48: 0.8,
  49: 0.82,
  50: 0.84,
  51: 0.85,
  52: 0.87,
  53: 0.89,
  54: 0.9,
  55: 0.92,
  56: 0.94,
  57: 0.95,
  58: 0.97,
  59: 0.99,
  60: 1,
};
