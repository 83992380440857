import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';

const useNavigation = () => {
  const history = useHistory();
  const location = useLocation();

  const withSearchQuery = (url) => {
    // We need to ensure we don't alter search query
    // as to not alter what is displayed on the screen.
    return qs.stringifyUrl({
      url,
      query: qs.parse(location.search),
    });
  };

  const goTo = (url) => {
    if (location.pathname === url) return;

    history.replace(withSearchQuery(url));
  };

  return { goTo };
};

export default useNavigation;
