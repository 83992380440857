import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import manageModal from 'modalManager/manageModalActionCreator';
import { showProjectSidePanel } from 'sidePanel/actions';

import { useAppDispatch, useAppStoreStrict } from '@float/common/store';
import { useSnackbar } from '@float/ui/deprecated';
import { updateDates } from '@float/web/actions/app';

import withOnMount from '../../decorators/withOnMount';
import activityItemClick from './activityItemClick';
import { StyledTable } from './styles';
import { useActivityTableItems } from './useActivityTableItems';

const OnMountTable = withOnMount(StyledTable);

export function ActivityTable(props) {
  const maxExportLength = 1000;

  const { filters, exporting, onExportReady } = props;
  const dispatch = useAppDispatch();

  const history = useHistory();

  const { showSnackbar } = useSnackbar();

  const dispatchManageModal = useCallback(
    (...args) => dispatch(manageModal(...args)),
    [dispatch],
  );

  const dispatchShowSidePanel = useCallback(
    (...args) => dispatch(showProjectSidePanel(...args)),
    [dispatch],
  );

  const dispatchUpdateDates = useCallback(
    (...args) => dispatch(updateDates(...args)),
    [dispatch],
  );

  const store = useAppStoreStrict();

  const onItemClick = useCallback(
    (item) => {
      activityItemClick(
        item,
        dispatchManageModal,
        dispatchUpdateDates,
        onItemClick,
        history,
        showSnackbar,
        dispatchShowSidePanel,
        store,
      );
    },
    [
      dispatchManageModal,
      dispatchUpdateDates,
      history,
      showSnackbar,
      dispatchShowSidePanel,
      store,
    ],
  );

  const {
    fetchNextPage,
    rows,
    sortOrder,
    updateSortOrder,
    hasMore,
    isFetching,
  } = useActivityTableItems(onItemClick, filters);

  useEffect(() => {
    if (exporting) {
      if (hasMore && rows.length < maxExportLength) {
        fetchNextPage();
      } else {
        const maxRows = rows.slice(0, maxExportLength);

        const data = maxRows.map((r) => [
          r.data[0],
          r.data[1].props.person.name,
          r.data[4],
        ]);
        onExportReady(data);
      }
    }
  }, [exporting, rows, hasMore, fetchNextPage, onExportReady]);

  return (
    <OnMountTable
      headNames={['Date/Time', 'Actioned by', 'Activity', 'Type']}
      headNameKeys={['createdAt']}
      recordNames={['activity', 'activities']}
      sort={{
        property: 'createdAt',
        direction: sortOrder,
      }}
      displayRowCount={false}
      columnsCount={4}
      onLastRowVisible={fetchNextPage}
      isLoading={isFetching}
      rows={rows}
      rowActions={[]}
      onClickHeader={updateSortOrder}
      columnsWidth={['15%', '15%', '70%', '100px']}
    />
  );
}
