import React from 'react';
import { t } from '@lingui/macro';

import { getClientOptions } from '@float/common/selectors/projects/projects';
import { useWebAppSelector } from '@float/web/lib/store';

import { ComboboxField } from '../components/ComboboxField';

export function ClientField() {
  const options = useWebAppSelector(getClientOptions);
  return (
    <ComboboxField
      name="project.client_id"
      label={t`Client`}
      options={options}
      creatable
    />
  );
}
