/* eslint-disable react-compiler/react-compiler */
/** We use a ref to track the change which goes against the Rules of React */
/** Given the small scope of this hook, we can safely ignore the compiler rule */

import { useRef } from 'react';

export function useHasChangedOnce(value: unknown) {
  const initialValue = useRef(value).current;
  const hasChangedOnceSinceMount = useRef(false);

  if (initialValue !== value) {
    hasChangedOnceSinceMount.current = true;
  }

  return hasChangedOnceSinceMount.current;
}
