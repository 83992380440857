import React from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';

import { stopPropagation } from '@float/libs/utils/events/stopPropagation';
import { Notification, NotificationTypes } from '@float/types/notification';
import { ScrollArea } from '@float/ui/primitives/ScrollArea';

import { useNotificationsData } from '../../hooks/useNotificationsData';
import { NotificationItem } from '../NotificationItem';
import { NotificationItemDetails } from '../NotificationItemDetails';
import { NotificationItemMention } from '../NotificationItemMention';
import { NotificationItemText } from '../NotificationItemText';
import { useInfiniteScroll } from './hooks/useInfiniteScroll';

import * as styles from './NotificationsList.css';

type Props = {
  loadMore?: () => void;
  notifications: ReturnType<typeof useNotificationsData>['notifications'];
  onClickNotification: (notification: Notification<NotificationTypes>) => void;
};

export const NotificationsList = (props: Props) => {
  const { loadMore, notifications, onClickNotification } = props;

  const scrollAreaContentRef = React.useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: notifications.length,
    getScrollElement: () => scrollAreaContentRef.current,
    estimateSize: () => 45,
    overscan: 5,
  });

  const virtualItems = virtualizer.getVirtualItems();

  useInfiniteScroll({
    loadMore,
    virtualizer,
  });

  return (
    <ScrollArea.Root type="hover">
      <ScrollArea.Content
        appearance="secondary"
        className={styles.content}
        data-testid="virtual-list"
        onClickScrollbar={stopPropagation}
        ref={scrollAreaContentRef}
      >
        <div
          className={styles.wrapper}
          style={{ height: virtualizer.getTotalSize() }}
        >
          <div
            role="list"
            className={styles.listWrapper}
            style={{
              transform: `translateY(${virtualItems[0]?.start ?? 0}px)`,
            }}
          >
            {virtualItems.map((virtualRow) => {
              const notification = notifications[virtualRow.index];

              const accountId = notification.actioned_by.account_id;
              const color = notification.color;
              const disabled = notification.baseTitleArgs?.disabledLink;
              const entity = notification.after_data;
              const history = notification.displayHistory;
              const seen = !notification.unseen;
              const timestamp = notification.actioned_timestamp || 0;
              const type = notification.item_type;

              const appearance = type === 'timeoff' ? 'stripe' : 'solid';
              const hasHistory = history && history.length > 0;
              const onClick = () => onClickNotification(notification);

              return (
                <NotificationItem
                  accountId={accountId}
                  appearance={appearance}
                  color={color}
                  data-index={String(virtualRow.index)}
                  disabled={disabled}
                  key={virtualRow.key}
                  onClick={onClick}
                  ref={virtualizer.measureElement}
                  role="listitem"
                  seen={seen}
                  timestamp={timestamp}
                >
                  <NotificationItemText notification={notification} />
                  <NotificationItemMention type={type} entity={entity} />
                  {hasHistory && <NotificationItemDetails history={history} />}
                </NotificationItem>
              );
            })}
          </div>
        </div>
      </ScrollArea.Content>
    </ScrollArea.Root>
  );
};
