import { useCallback } from 'react';

import { updateCompanyPrompts } from '@float/common/actions/companyPrefs';
import { PROMPTS } from '@float/constants/prompts';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import {
  useWebAppDispatchStrict,
  useWebAppSelectorStrict,
} from '@float/web/lib/store';
import {
  setPromptData,
  updatePrompts,
} from '@float/web/store/onboardingManager/actions';
import { getAllSurveyPrompts } from '@float/web/store/onboardingManager/selectors/survey';
import { OnboardingManagerNextFunction } from '@float/web/store/onboardingManager/types';

export function useOnboardingManagerNextFunction() {
  const dispatch = useWebAppDispatchStrict();

  const allSurveyPrompts = useWebAppSelectorStrict(getAllSurveyPrompts);

  const next = useCallback<OnboardingManagerNextFunction>(
    (prompt, data, saveOnServer = true) => {
      // track prompt data on the store for navigation purposes
      if (data) {
        dispatch(setPromptData(prompt, data));
      }

      const prompts = [prompt];

      // if example data feature is enabled when completing the onboarding survey
      // we set the rest of the survey and the schedule call outs as seen to avoid
      // this prompts to come up if we ever disable the example data feature
      if (
        featureFlags.isFeatureEnabled(FeatureFlag.OnboardingExampleData) &&
        prompt === PROMPTS.welcomeGetToKnowYou
      ) {
        prompts.push(PROMPTS.welcomeAddProject);
        prompts.push(PROMPTS.welcomeAddTask);
        prompts.push(PROMPTS.welcomeAddPerson);
        prompts.push(PROMPTS.addFirstTask);
        prompts.push(PROMPTS.helpGuide);
      }

      // mark prompts as seen
      dispatch(updatePrompts(prompts, saveOnServer));

      // save account owner prompts as company prompts
      // https://linear.app/float-com/issue/CS-36
      if (
        saveOnServer &&
        (allSurveyPrompts.includes(prompt) || prompt === PROMPTS.addFirstTask)
      ) {
        dispatch(updateCompanyPrompts(prompts));
      }
    },
    [dispatch, allSurveyPrompts],
  );

  return next;
}
