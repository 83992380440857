import styled, { css } from 'styled-components';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Elevations from '@float/ui/deprecated/Earhart/Elevations';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

export const StyledProjectTasks = styled.div`
  position: relative;

  width: 540px;
  height: 423px;

  > img {
    position: absolute;

    z-index: 0;

    &.book {
      top: 94%;
      left: 40%;
    }

    &.spring {
      top: 2%;
      left: 75%;

      transform: translateY(-100%);
    }
  }
`;

export const StyledH1 = styled.h1`
  ${Typography.DisplayXS.M500};

  display: inline-block;

  width: 100%;
  min-height: 30px;

  color: ${Colors.FIN.Lt.Emphasis.Disabled};

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledTaskList = styled.div`
  width: 100%;

  margin-top: 24px;
`;

export const StyledLabel = styled.h3`
  ${Typography.Label12.M500};

  color: ${Colors.FIN.Lt.Emphasis.Low};
`;

export const StyledModal = styled.div`
  ${Elevations.E500};

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  border-radius: 8px;

  padding: 32px;

  background: ${Colors.FIN.Lt.Emphasis.White};

  z-index: 1;

  ${({ $highlighted }) => {
    if ($highlighted) {
      return css`
        ${StyledH1} {
          color: ${Colors.FIN.Lt.Emphasis.High};
        }
      `;
    }
  }}
`;
