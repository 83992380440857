/**
 * To be deprecated once all the onboarding prompts have been migrated
 * to use the new OnboardingManager reducer
 */

import { uniq } from 'lodash';

import { PromptId } from '@float/types/prompts';
import { WebAppActions } from '@float/web/lib/store';
import { UPDATE_PROMPTS_SUCCESS } from '@float/web/store/onboardingManager/actions';

export type OnboardingState = {
  prompts: PromptId[];
  loadState: 'LOADED';
};

const DEFAULT_STATE: OnboardingState = {
  prompts: [],
  loadState: 'LOADED',
};

const onboarding = (state = DEFAULT_STATE, action: WebAppActions) => {
  switch (action.type) {
    case UPDATE_PROMPTS_SUCCESS:
      if (
        action.prompts &&
        action.prompts.length &&
        action.prompts.every((id: PromptId) => state.prompts.includes(id))
      ) {
        return state;
      }

      return {
        ...state,
        prompts: uniq([...state.prompts, ...action.prompts]),
      };

    default:
      return state;
  }
};

export default onboarding;
