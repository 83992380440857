import { motion } from 'framer-motion';
import styled, { css, keyframes } from 'styled-components';

import { E400 } from '@float/ui/deprecated/Earhart/Elevations';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeInZoom = keyframes`
  from {
    opacity: 0;
    transform: scale(0.95, 0.95);
  }

  to {
    opacity: 1;
    transform: scale(1, 1);
  }
`;

export const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;

  *,
  *:before,
  *:after {
    box-sizing: border-box;
    ${(p) => p.theme.antialias};
  }
`;

export const StyledModalBg = styled.div<{
  noAnimation?: boolean;
  noBgTransition?: boolean;
}>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  position: fixed;
  touch-action: none;
  background-color: rgb(243, 242, 245, 40%);
  -webkit-tap-highlight-color: transparent;
  ${(p) =>
    !p.noAnimation &&
    !p.noBgTransition &&
    css`
      will-change: opacity;
      animation: ${fadeIn} 100ms ease-out forwards;
    `};
`;

const dialogBackground = css`
  --dialog-bg-color: #fff;

  border-radius: 16px;
  background-color: var(--dialog-bg-color);
`;

export const StyledModalDialog = styled(motion.div)<{
  marginTop?: number;
  fitContent?: boolean;
  width?: number | string;
  noAnimation?: boolean;
  noBackground?: boolean;
}>`
  display: flex;
  position: relative;
  flex-direction: column;
  color: ${(props) => props.theme.charcoalGrey};
  margin: ${(props) => props.marginTop}px auto;
  border-radius: 16px;

  ${E400}

  ${({ width, fitContent }) =>
    fitContent
      ? `
    width: fit-content;
  `
      : `
    width: 100%;
    max-width: ${typeof width === 'number' ? `${width}px` : width};
    @media (max-width: ${width}px) {
      margin: 0;
      border-radius: 0;
    }
  `}

  ${(p) =>
    !p.noAnimation &&
    css`
      will-change: opactiy, transform;
      animation: ${fadeInZoom} 200ms ease-out forwards;
    `};

  ${(p) => (!p.noBackground ? dialogBackground : null)}
`;

export const StyledModalMotionWrapper = styled(motion.div)`
  ${dialogBackground}
`;

export const StyledModalScrollingContainer = styled.div<{
  center?: boolean;
  width?: number;
}>`
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;

  ${(p) =>
    p.center &&
    css`
      display: flex;
      align-items: center;

      [data-focus-lock-disabled] {
        width: 100%;
        max-width: ${(props: { width?: number }) => props.width}px;
        margin: 0 auto;
      }
    `};
`;
