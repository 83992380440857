import { formatAmount } from '@float/common/lib/budget';
import { Role } from '@float/types/role';

export const getRateInputPlaceholderValue = (
  selectedRoleId: Role['id'] | null | undefined,
  roles: Record<Role['id'], Role>,
) => {
  const missingValuePlaceholder = '0';

  if (!selectedRoleId) return missingValuePlaceholder;

  const selectedRole = roles[selectedRoleId];

  return selectedRole && typeof selectedRole.default_hourly_rate === 'string'
    ? formatAmount(null, selectedRole.default_hourly_rate)
    : missingValuePlaceholder;
};
