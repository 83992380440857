export function getDiagonalStripeGradient(
  color: string | number,
  angle: string | number,
) {
  return `linear-gradient(
    ${angle}deg,
    ${color} 12.5%,
    transparent 12.5%,
    transparent 50%,
    ${color} 50%,
    ${color} 62.5%,
    transparent 62.5%,
    transparent
  )`;
}
