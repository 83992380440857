import { useCallback } from 'react';

import API3 from '@float/common/api3';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { logger } from '@float/libs/logger';

import { SupportEffort, SupportEffortType } from '../types';

export const useCreateExampleData = () => {
  const createExampleData = useCallback((supportEffort: SupportEffortType) => {
    if (
      featureFlags.isFeatureEnabled(FeatureFlag.OnboardingExampleData) &&
      supportEffort !== SupportEffort.No
    ) {
      return new Promise<void>((resolve, reject) => {
        return API3.createExampleData()
          .then(resolve)
          .catch((error) => {
            logger.error(
              'Encountered an error while fetching example data',
              error,
            );
            reject(error);
          });
      });
    }

    return Promise.resolve();
  }, []);

  return { createExampleData };
};
