import styled from 'styled-components';

import EH from '@float/ui/deprecated/Earhart';

export const StyledCard = styled(EH.Cards.Card)`
  h5 {
    ${EH.Typography.TextXXL.SB600};
    color: ${EH.Colors.FIN.Lt.Emphasis.High};
  }
`;

interface StyledRowProps {
  alignItems?: string;
  justifyContent?: string;
  marginTop?: number;
}

export const StyledRow = styled.div<StyledRowProps>`
  display: flex;
  flex-direction: row;
  ${(p) => p.alignItems && `align-items: ${p.alignItems}`};
  ${(p) => p.justifyContent && `justify-content: ${p.justifyContent}`};
  &:not(:first-child) {
    ${(p) => `margin-top: ${p.marginTop || 24}px`};
  }
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 12px 0 0;
`;

interface StyledRowHeaderProps extends StyledRowProps {
  disabled?: boolean;
}

export const StyledRowHeader = styled(StyledRow)<StyledRowHeaderProps>`
  display: flex;
  align-items: center;
  *:first-child {
    flex: 1;
  }
  h5 {
    color: ${(p) =>
      p.disabled
        ? EH.Colors.FIN.Lt.Emphasis.Low
        : EH.Colors.FIN.Lt.Emphasis.High};
  }
`;

interface StyledLabelProps {
  disabled?: boolean;
}

export const StyledLabel = styled.span<StyledLabelProps>`
  ${EH.Typography.Label14.R400};
  color: ${(p) =>
    p.disabled
      ? EH.Colors.FIN.Lt.Emphasis.Low
      : EH.Colors.FIN.Lt.Emphasis.High};
`;

interface StyledLinkProps {
  disabled?: boolean;
}

export const StyledLink = styled.a<StyledLinkProps>`
  ${EH.Typography.Label14.SB600};
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  color: ${(p) =>
    p.disabled
      ? EH.Colors.FIN.Lt.Emphasis.Low
      : EH.Colors.FIN.Lt.Emphasis.Primary};
  &:hover {
    ${(p) =>
      p.disabled
        ? EH.Colors.FIN.Lt.Emphasis.Low
        : EH.Colors.FIN.Lt.Buttons.Primary.Hover.Bg};
  }
  &:active {
    ${(p) =>
      p.disabled
        ? EH.Colors.FIN.Lt.Emphasis.Low
        : EH.Colors.FIN.Lt.Buttons.Primary.Pressed.Bg};
  }

  pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
`;

interface StyledTitleProps {
  disabled?: boolean;
}

export const StyledTitle = styled.h3<StyledTitleProps>`
  ${EH.Typography.Label15.SB600};
  display: flex;
  color: ${(p) =>
    p.disabled
      ? EH.Colors.FIN.Lt.Emphasis.Low
      : EH.Colors.FIN.Lt.Emphasis.High};
`;

export const StyledDropdown = styled(EH.Dropdowns.Dropdown)`
  width: 100px;
  button {
    width: 100%;
  }
`;
