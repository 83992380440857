import React, { forwardRef, useState } from 'react';

import { StyledPill, StyledPillContainer, StyledPillToggle } from './styles';

interface Props {
  className?: string;
  checked: boolean;
  onChange?: (value: boolean) => void;
  values?: Array<any>;
}
const PillToggle = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { className, checked, values, onChange } = props;

  const [isChecked, setIsChecked] = useState(checked);

  const onClick = () => {
    const newChecked = !isChecked;

    setIsChecked(newChecked);

    if (onChange) {
      if (values) {
        onChange(newChecked ? values[1] : values[0]);
      } else {
        onChange(newChecked);
      }
    }
  };

  return (
    <StyledPillToggle
      ref={ref}
      className={className}
      checked={isChecked}
      onClick={onClick}
    >
      <StyledPillContainer>
        <StyledPill />
      </StyledPillContainer>
    </StyledPillToggle>
  );
});

export default PillToggle;
