import { keyBy, omit } from 'lodash';

import { Client, RawClient } from '@float/types/client';

import {
  ADD_CLIENT_SUCCESS,
  CLIENT_LOAD_FAILED,
  CLIENT_LOAD_FINISH,
  CLIENT_LOAD_START,
  CLIENTS_BULK_UPDATED,
  DELETE_CLIENT_SUCCESS,
  UPDATE_CLIENT_SUCCESS,
} from '../actions/clients';

const DEFAULT_STATE: ClientsState = {
  clients: {},
  loadState: 'UNLOADED' as const,
};

type ClientsState = {
  clients: Record<Client['client_id'], Client>;
  loadState: 'UNLOADED' | 'LOADED' | 'LOADING' | 'LOAD_FAILED';
};

type ClientsAction =
  | {
      type: typeof CLIENT_LOAD_START;
    }
  | {
      type: typeof CLIENT_LOAD_FAILED;
    }
  | {
      type: typeof CLIENT_LOAD_FINISH;
      clients: RawClient[];
    }
  | {
      type: typeof ADD_CLIENT_SUCCESS | typeof UPDATE_CLIENT_SUCCESS;
      payload: {
        client_id: RawClient['client_id'];
        name: RawClient['name'];
      };
    }
  | {
      type: typeof DELETE_CLIENT_SUCCESS;
      id: RawClient['client_id'];
    }
  | {
      type: typeof CLIENTS_BULK_UPDATED;
      result: Client[];
    };

const clients = (state = DEFAULT_STATE, action: ClientsAction) => {
  switch (action.type) {
    case CLIENT_LOAD_START: {
      return {
        ...state,
        loadState: 'LOADING' as const,
      };
    }
    case CLIENT_LOAD_FAILED: {
      return {
        ...state,
        loadState: 'LOAD_FAILED' as const,
      };
    }
    case CLIENT_LOAD_FINISH: {
      const { clients = [] } = action;
      const newClients: Record<Client['client_id'], Client> = {};

      clients.forEach((c) => {
        newClients[c.client_id] = Object.assign({}, clients[c.client_id], {
          client_id: c.client_id,
          client_name: c.name,
        });
      });

      return {
        ...state,
        loadState: 'LOADED' as const,
        clients: newClients,
      };
    }

    case ADD_CLIENT_SUCCESS:
    case UPDATE_CLIENT_SUCCESS: {
      return {
        ...state,
        clients: {
          ...state.clients,
          [action.payload.client_id]: {
            client_id: action.payload.client_id,
            client_name: action.payload.name,
          },
        },
      };
    }

    case DELETE_CLIENT_SUCCESS: {
      const { id } = action;
      if (!id) {
        return state;
      }
      return {
        ...state,
        clients: omit(state.clients, id),
      };
    }

    case CLIENTS_BULK_UPDATED: {
      const { result } = action;
      if (!result || !result.length) {
        return state;
      }

      const newClients = keyBy(
        result.map((c) => ({
          client_id: +c.client_id,
          client_name: c.client_name,
        })),
        'client_id',
      );

      return {
        ...state,
        clients: {
          ...state.clients,
          ...newClients,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default clients;
