import React, { useId } from 'react';
import { FieldPath } from 'react-hook-form';

import { ProjectFormData } from '../../types';
import { FormField, FormFieldPaddingTypes } from '../FormFieldsSection';
import { SidePanelInput } from '../SidePanelInput';

export type InputFieldProps = {
  name: FieldPath<ProjectFormData>;
  label: string;
  placeholder?: string;
  readOnly?: boolean;
  hideLabel?: boolean;
  autoFocus?: boolean;
  className?: string;
  padding?: FormFieldPaddingTypes;
  valueOverride?: string;
  suffix?: string;
  prefix?: string;
  blockOutsideInteraction?: boolean;
};

export function InputField(props: InputFieldProps) {
  const id = useId();

  return (
    <FormField
      label={props.label}
      htmlFor={id}
      readOnly={props.readOnly}
      hideLabel={props.hideLabel}
      className={props.className}
      padding={props.padding}
      stopClickPropagation
    >
      <SidePanelInput
        id={id}
        name={props.name}
        placeholder={props.placeholder}
        readOnly={props.readOnly}
        autoFocus={props.autoFocus}
        valueOverride={props.valueOverride}
        suffix={props.suffix}
        prefix={props.prefix}
        blockOutsideInteraction={props.blockOutsideInteraction}
      />
    </FormField>
  );
}
