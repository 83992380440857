import React, { ReactNode } from 'react';
import { format, parseISO } from 'date-fns';

import Icons from '@float/ui/deprecated/Icons';

export type LockIndicatorProps = {
  currentDate: string;
  lockPeriodLatest?: string | null;
  lockPeriodNext?: string | null;
  alternateRendering?: ReactNode;
  color?: string;
  className?: string;
};

const LockIndicator = ({
  currentDate,
  lockPeriodLatest,
  lockPeriodNext,
  alternateRendering = null,
  color,
  className,
}: LockIndicatorProps) => {
  const isSameDay = (date: string, predicate: string) =>
    format(parseISO(date), 'yyyy-MM-dd') ===
    format(parseISO(predicate), 'yyyy-MM-dd');

  const isLocked = lockPeriodLatest && isSameDay(currentDate, lockPeriodLatest);
  const isUnlocked = lockPeriodNext && isSameDay(currentDate, lockPeriodNext);

  const matchLockOrUnlocked = isLocked || isUnlocked;

  return matchLockOrUnlocked ? (
    <div className={className}>
      {(isLocked && <Icons.LockOutline size={16} color={color} />) ||
        (isUnlocked && <Icons.UnlockOutline />)}
    </div>
  ) : (
    alternateRendering
  );
};

export default LockIndicator;
