import React from 'react';
import { isUndefined } from 'lodash';
import styled from 'styled-components';

import CheckerboardLight from '@float/common/assets/images/checkerboard-light.png';
import { formatAmount } from '@float/common/lib/budget';
import { MID_TEAL, TEAL } from '@float/ui/deprecated/Chart';

import {
  ItemHeader,
  ItemLabel,
  ItemSubLabel,
  ItemUnit,
  LegendKeySquare,
  ReportsTotalsCardMobileWrap,
  ReportsTotalsItem,
} from '../styles';

const Legend = styled.div`
  margin: 0px auto;
  align-self: flex-end;
  ${(p) => p.showTentative && 'margin-top: 28px;'}
  ${(p) => p.bottomMargin && 'margin-bottom: 20px;'}
`;

const Flex = styled.div`
  display: flex;
`;

const LegendKey = styled(Flex)`
  width: 120px;
`;

const RangeBarItemLabel = styled(ItemLabel)`
  ${({ percent, theme }) =>
    percent &&
    `
    font-weight: normal;
    letter-spacing: -0.2px;
    text-align: right;
    width: 50px;
    color: ${theme.blueGrey};
  `}

  ${({ total, theme }) =>
    total &&
    `
    font-weight: normal;
    color: ${theme.charcoalGrey};
    text-align: right;
    width: 80px;
  `}
`;

export function BillableVsNonBillable({
  title,
  total,
  perc,
  colors = [TEAL, MID_TEAL, `url(${CheckerboardLight}),#c8c8c8`],
  combined,
  billable,
  billablePerc,
  nonBillable,
  nonBillablePerc,
  tentative,
  tentativePerc,
  bottomMargin,
  noBorderRight,
}) {
  const showTentative = !isUndefined(tentative) && tentative > 0;
  return (
    <ReportsTotalsCardMobileWrap borderRight={!noBorderRight} flexGrow={2}>
      <ReportsTotalsItem width={'initial'}>
        <ItemLabel>{title}</ItemLabel>
        {!isUndefined(perc) && <ItemSubLabel>{perc}%</ItemSubLabel>}
        <ItemHeader>
          {total.toLocaleString()}
          <ItemUnit>h</ItemUnit>
        </ItemHeader>
      </ReportsTotalsItem>
      <Legend showTentative bottomMargin={bottomMargin}>
        <Flex>
          <LegendKey>
            <LegendKeySquare
              className={combined ? 'combined' : ''}
              color={colors[0]}
            />
            <RangeBarItemLabel normal>Billable</RangeBarItemLabel>
          </LegendKey>
          <RangeBarItemLabel total>
            {formatAmount(1, billable)}
          </RangeBarItemLabel>{' '}
          <RangeBarItemLabel percent>
            {billablePerc.toLocaleString()}%
          </RangeBarItemLabel>
        </Flex>
        <Flex>
          <LegendKey>
            <LegendKeySquare color={colors[1]} />
            <RangeBarItemLabel normal>Non-billable</RangeBarItemLabel>
          </LegendKey>
          <RangeBarItemLabel total>
            {formatAmount(1, nonBillable)}
          </RangeBarItemLabel>
          <RangeBarItemLabel percent>
            {nonBillablePerc.toLocaleString()}%
          </RangeBarItemLabel>
        </Flex>
        {showTentative && (
          <Flex>
            <LegendKey>
              <LegendKeySquare color={colors[2]} />
              <RangeBarItemLabel normal>Tentative (all)</RangeBarItemLabel>
            </LegendKey>
            <RangeBarItemLabel total>
              {formatAmount(0, tentative)}
            </RangeBarItemLabel>
            <RangeBarItemLabel percent>
              {tentativePerc.toLocaleString()}%
            </RangeBarItemLabel>
          </Flex>
        )}
      </Legend>
    </ReportsTotalsCardMobileWrap>
  );
}
