import {
  NumberFormatOptions,
  NumberFormatter,
  NumberParser,
} from '@internationalized/number';

import { config } from '@float/libs/config';

// The intention is to just prevent the user from entering more than 2 decimal digits.
// But since it's a text field, there can be some extraneous ways to fill in more than 2.
// When we clip the number to two decimals, we don't want to do any rounding, hence
// this manual helper.
function truncNumber<V extends number | null>(
  value: V,
  maximumFractionDigits: number,
) {
  if (value === null) {
    return value;
  }

  const m = Math.pow(10, maximumFractionDigits);
  // Using Number.EPSILON is too precise to fix the floating-point errors
  // that user-inputted numbers are likely to encounter
  const EPSILON = 1e-10;
  return Math.trunc(value * m + EPSILON) / m;
}

// helper function for formatting numbers into strings
export function formatNumber(
  value: number | null,
  options: NumberFormatOptions = {},
) {
  if (value === null) {
    return '';
  }

  if (typeof value === 'string') {
    return value;
  }

  const formatter = new NumberFormatter(config.locale, {
    maximumFractionDigits: 2,
    ...options,
  });
  return formatter.format(
    truncNumber(value, options.maximumFractionDigits ?? 2),
  );
}

export function parseNumber(value: string | number | null | undefined) {
  if (typeof value === 'number') return value;
  const parser = new NumberParser(config.locale, {});
  return value ? parser.parse(value) : null;
}
