import React from 'react';

// Note: This SVG uses a namespace in certain rules, which isn't supported by
// React, so we need to generate the HTML ourselves

const SVG = `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
    <defs>
        <path id="a" d="M8 8c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(4 4)">
        <mask id="b" fill="#fff">
            <use xlink:href="#a"/>
        </mask>
        <g fill="#7B8A9F" mask="url(#b)">
            <path d="M-17-17h50v50h-50v-50z"/>
        </g>
    </g>
</svg>
`;

export default function (props) {
  return <div dangerouslySetInnerHTML={{ __html: SVG }} />;
}
