import {
  createMilestone,
  removeMilestone,
  updateMilestone,
} from '@float/common/actions/milestones/milestones';
import { diffEntityListPayload } from '@float/common/lib/diffEntityPayload';
import { useAppDispatchStrict } from '@float/common/store';
import { Phase, Project } from '@float/types';

import { ProjectMilestoneRecord } from '../types';

export const useMilestoneSave = () => {
  const dispatch = useAppDispatchStrict();

  const handleMilestoneUpsert = (
    milestone: ProjectMilestoneRecord,
    projectId: Project['project_id'],
    phaseId?: Phase['phase_id'],
  ) => {
    if (!milestone.milestone_id) {
      return dispatch(
        createMilestone({
          ...milestone,
          project_id: projectId,
          phase_id: phaseId,
        }),
      );
    }

    return dispatch(
      updateMilestone({
        ...milestone,
        project_id: projectId,
        phase_id: phaseId,
      }),
    );
  };

  const handleBulkMilestonesUpsert = async (
    milestones: ProjectMilestoneRecord[],
    projectId: Project['project_id'],
    phaseId?: Phase['phase_id'],
  ) => {
    const errors = [];

    // The upsert calls are being processed sequentially in order to retain the given order
    // (The api3 response is sorted sequentially by id, which is incrementals)
    for (const milestone of milestones) {
      try {
        await handleMilestoneUpsert(milestone, projectId, phaseId);
      } catch (_err) {
        errors.push(_err);
      }
    }

    if (errors.length) {
      throw new AggregateError(errors, 'Bulk milestones upsert failed');
    }
  };

  const handleMilestoneBulkDelete = async (milestones: number[]) => {
    await Promise.all(
      milestones.map((id) => dispatch(removeMilestone({ milestone_id: id }))),
    );
  };

  const handleUpdate = (
    update: ProjectMilestoneRecord[],
    currentValues: ProjectMilestoneRecord[],
    projectId: Project['project_id'],
    phaseId?: Phase['phase_id'],
  ) => {
    const diff = diffEntityListPayload(update, currentValues, 'milestone_id');

    if (diff) {
      const promises = [];

      if (diff.add) {
        promises.push(handleBulkMilestonesUpsert(diff.add, projectId, phaseId));
      }
      if (diff.del) {
        promises.push(handleMilestoneBulkDelete(diff.del));
      }
    }
  };

  return {
    handleBulkMilestonesUpsert,
    handleUpdate,
  };
};
