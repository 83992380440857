import React from 'react';
import { Trans } from '@lingui/macro';

import noTemplatesSVGSrc from './assets/no-templates.svg';

import * as styles from './styles.css';

const NoTemplatesCreated = () => {
  return (
    <div className={styles.errorWrapper}>
      <img src={noTemplatesSVGSrc} />

      <h2>
        <Trans>No templates created yet</Trans>
      </h2>
      <p>
        <Trans>Select `New template` to create your first template</Trans>
      </p>
    </div>
  );
};

export { NoTemplatesCreated };
