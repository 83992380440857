import React from 'react';

import VirtualSelect from '@float/ui/deprecated/VirtualSelect/VirtualSelect';

export default function ChartVirtualSelect({
  value,
  options,
  onChange,
  width,
  style = {},
}) {
  return (
    <VirtualSelect
      value={value}
      options={options}
      autoSize={true}
      minWidth={width}
      visibleItems={options.length}
      nonNullable
      searchable={false}
      autoSelectOnFocus={false}
      clearInputOnDropdownOpen={false}
      onChange={onChange}
      style={style}
    />
  );
}
