import React, { ComponentProps } from 'react';
import cn from 'classnames';

import * as styles from './styles.css';

type Props = Pick<ComponentProps<'div'>, 'className'>;

export const SkeletonRectangle = (props: Props) => {
  const { className } = props;

  return (
    <div
      role="presentation"
      aria-label="skeleton-rectangle"
      className={cn(className, styles.skeletonRectangle)}
    />
  );
};
