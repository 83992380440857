import { fastDictionary } from '@float/common/lib/fast-object-spread';
import { Milestone, Phase } from '@float/types';

export function getSortKey(entity: Phase | Milestone) {
  return isMilestone(entity)
    ? `milestone-${entity.milestone_id}`
    : `phase-${entity.phase_id}`;
}

export function buildProjectRowSortData(items: Array<Phase | Milestone>) {
  const sortData: Record<string, number> = fastDictionary();

  items.sort((a, b) => {
    // Make sure that milestones are always rendered below phases
    if (isMilestone(a) && !isMilestone(b)) return 1;
    if (isMilestone(b) && !isMilestone(a)) return -1;

    // Items with a lower start_date are rendered first
    if (a.start_date < b.start_date) return -1;
    if (b.start_date < a.start_date) return 1;

    // Items with a higher end_date are rendered first
    if (a.end_date > b.end_date) return -1;
    if (b.end_date > a.end_date) return 1;

    // Then compare by name & id
    if (getItemName(a) < getItemName(b)) return -1;
    if (getItemName(b) < getItemName(a)) return 1;
    return getItemId(a) - getItemId(b);
  });

  items.forEach((item, idx) => {
    let y = 0;

    const filledPositions = new Set();

    for (let i = 0; i < idx; i++) {
      const otherItem = items[i];

      if (overlapsDays(item, otherItem)) {
        const sortKey = getSortKey(otherItem);

        if (sortKey) {
          const otherItemY = sortData[sortKey];

          if (otherItemY === y) {
            while (filledPositions.has(++y)) {}
          } else {
            filledPositions.add(otherItemY);
          }
        }
      }
    }

    sortData[getSortKey(item)] = y;
  });

  return sortData;
}
function isMilestone(item: Phase | Milestone): item is Milestone {
  return 'milestone_id' in item;
}
function getItemName(item: Phase | Milestone) {
  return isMilestone(item) ? item.name : item.phase_name;
}
function getItemId(item: Phase | Milestone) {
  return isMilestone(item) ? item.milestone_id : item.phase_id;
}
function overlapsDays(a: Phase | Milestone, b: Phase | Milestone) {
  return !(a.end_date < b.start_date || b.end_date < a.start_date);
}
