import styled, { css } from 'styled-components';

import * as Animation from '../../Animation';
import * as Colors from '../../Colors';
import * as Typography from '../../Typography';

export const StyledInput = styled.input`
  // eh-input is a className to force styled-components to override
  // the global and legacy styles in /ui/Theme/GlobalStyles/LegacyStyle.js
  &.eh-input {
    ${Typography.Label14.SB600};

    display: inline-flex;

    padding: 10px 16px;

    appearance: none;
    outline: none;

    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0);
    border-radius: 5px;

    color: ${Colors.FIN.Lt.Buttons.Secondary.Default.Label};

    background-color: ${Colors.FIN.Lt.Buttons.Secondary.Default.Bg};

    transition-property: background-color, color, border-color;
    transition-duration: ${Animation.Settings.Duration.Short};
    transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

    box-sizing: content-box;

    ${({ alignment }) =>
      alignment &&
      css`
        text-align: ${alignment};
      `}

    ${({ disabled }) =>
      // if not disabled
      !disabled &&
      css`
        &:hover {
          background-color: ${Colors.FIN.Lt.Buttons.Secondary.Hover.Bg};

          // override global and legacy styles
          color: ${Colors.FIN.Lt.Buttons.Secondary.Default.Label};
          border-color: rgba(0, 0, 0, 0);
        }

        &:focus {
          border-color: ${Colors.FIN.Lt.Emphasis.Primary};

          background-color: ${Colors.FIN.Lt.Surface.Surf1};

          // override global and legacy styles
          color: ${Colors.FIN.Lt.Buttons.Secondary.Default.Label};
          box-shadow: none;
        }
      `};

    ${({ disabled }) =>
      // if disabled
      disabled &&
      css`
        color: ${Colors.FIN.Lt.Emphasis.Low};

        background-color: ${Colors.FIN.Lt.Buttons.Primary.Disabled.Bg};

        cursor: not-allowed;

        &:hover {
          // override global and legacy styles
          color: ${Colors.FIN.Lt.Emphasis.Low};
          border-color: rgba(0, 0, 0, 0);
        }
      `};
  }
`;
