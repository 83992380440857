import React from 'react';

import PersonAvatar from '@float/common/components/elements/PersonAvatar';

const avatarStyle = {
  position: 'absolute',
  top: 10,
  left: 17,
};

export const ActivityAvatar = ({
  accountId,
  personId,
}: {
  accountId: string;
  personId?: string;
}) => (
  <PersonAvatar
    accountId={accountId}
    personId={personId}
    size="xs"
    readOnly
    style={avatarStyle}
  />
);
