import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

// Note: react-router throws warnings for custom props
export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 0 5px;
  ${(p) => p.theme.antialias}
  ${Typography.Label16.M500};

  color: ${Colors.FIN.Lt.Emphasis.Medium};
  svg {
    path {
      fill: ${Colors.FIN.Lt.Emphasis.Medium};
    }
  }

  &:hover {
    color: ${Colors.FIN.Lt.Emphasis.Primary} !important;
    svg {
      path {
        fill: ${Colors.FIN.Lt.Emphasis.Primary};
      }
    }
  }

  @media (max-width: 1220px) {
    padding: 0 10px;
  }
`;

export const ActiveLink = styled(StyledLink)`
  position: relative;
  color: ${Colors.FIN.Lt.Emphasis.Primary} !important;

  &::after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    border-radius: 2px;
    background: ${Colors.FIN.Lt.Emphasis.Primary};
    bottom: 0px;
    left: 0px;
  }

  svg {
    path {
      fill: ${Colors.FIN.Lt.Emphasis.Primary};
    }
  }
`;

const checkIsMatchingPathname = ({ location, to }) => {
  const isMatchingPathname =
    to == '/' ? to === location.pathname : location.pathname.startsWith(to);

  return isMatchingPathname;
};

const NavLink = ({ isActive = false, to, children, style, onClick }) => {
  const location = useLocation();
  const isMatchPathname = to
    ? checkIsMatchingPathname({ location, to })
    : false;
  const LinkComponent = isActive || isMatchPathname ? ActiveLink : StyledLink;

  return (
    <LinkComponent
      as={to ? undefined : 'a'}
      to={to}
      style={style}
      onClick={onClick}
    >
      {children}
    </LinkComponent>
  );
};

export default NavLink;
