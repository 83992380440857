export const trimTextForReadibility = (
  text: string,
  trimAt: number,
  ellipsis = '...',
) => {
  if (text.trim() === '')
    return { intro: '', remaining: '', trimmed: true, value: '' };

  // we don't want to cut words, find next empty space after trim position
  let trimPosition = text.indexOf(' ', trimAt);

  // return full text if text is too small
  trimPosition = trimPosition >= trimAt ? trimPosition : text.length;

  // if trimPosition matches a punctuation character,
  // start cropping more until it's outside punctuation
  // e.g. ..., !!!, !?, etc
  if (trimPosition >= trimAt) {
    let position = trimPosition;

    while (
      position > 0 &&
      /[^\w\s]$/gi.test(text.substring(position - 1, position))
    ) {
      position -= 1;
    }

    trimPosition = position;
  }

  // set a 3 times ratio limit on the trimPosition
  // e.g., if a text is a long phrase without spaces
  if (trimPosition / trimAt > 3) {
    trimPosition = trimAt;
  }

  const intro = text.substring(0, trimPosition);
  const remaining = text.substring(trimPosition);

  // if there's remaining content by default it should trim
  let shouldTrim = remaining !== '';

  // if remaining content are only punctuation characters do not trim
  if (shouldTrim) {
    const hasPunctuation = remaining.match(/[^\w\s]+/g);
    shouldTrim = !(hasPunctuation && hasPunctuation.join() === remaining);
  }

  return {
    intro,
    remaining,
    trimmed: shouldTrim,
    value: shouldTrim ? intro + (remaining ? ellipsis : '') : text,
  };
};
