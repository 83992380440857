import { useEffect } from 'react';
import { useWebAppSelector } from 'lib/store';

import { useAppDispatch } from '@float/common/store';

import {
  commitPanelTentativeOverride,
  removePanelTentativeOverride,
  updatePreventPanelOverride,
} from './actions';
import { getSidePanelTentativeOverride } from './selectors';
import { PanelType } from './types';

export function useSidePanelConflictManager(
  preventPanelOverride: boolean,
  panelType: PanelType,
) {
  const tentativeOverride = useWebAppSelector(getSidePanelTentativeOverride);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updatePreventPanelOverride(preventPanelOverride, panelType));
  }, [dispatch, preventPanelOverride, panelType]);

  function cancel() {
    dispatch(removePanelTentativeOverride());
  }

  function commit() {
    dispatch(commitPanelTentativeOverride());
  }

  return {
    active: tentativeOverride,
    cancel,
    commit,
  };
}
