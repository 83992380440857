import { CurrentUser, FilterToken } from '@float/types';

import {
  isNot,
  isOr,
  normalize,
  PERSON_RELATED_TYPES,
  PROJECT_RELATED_TYPES,
} from '../helpers';
import {
  FiltersContext,
  FiltersDataType,
  FiltersEntity,
  GroupedFilterToken,
  VirtualFilterTypes,
} from '../types';
import { ContainsMatcher, FILTER_MATCHERS } from './filters';

const getMeFilterValues = (user: CurrentUser) => {
  return user.people_id ? [user.people_id.toString()] : [];
};

const getFilterValues = (filter: FilterToken, user: CurrentUser) => {
  const tokens = filter.val;

  if (filter.type === 'me') {
    return getMeFilterValues(user);
  }

  return Array.isArray(tokens)
    ? tokens.map(normalize)
    : [normalize(tokens as string)];
};

function getFilterMatcher<T extends FiltersDataType>(
  dataType: T,
  context: FiltersContext<T>,
  filterType: VirtualFilterTypes,
  val: string[],
) {
  const FilterMatcher = FILTER_MATCHERS[dataType];

  if (filterType === 'contains') {
    return new ContainsMatcher(FilterMatcher as any, context, val);
  }

  return new FilterMatcher(context as any, filterType, val);
}

// Converts a linear filters expression e.g. A = 1 AND B = 2 OR C = 3 AND D = 4
// into one grouped by precedence. E.g. ((A = 1 AND B = 2) OR (C = 3 AND D = 4))
// The result is represented by a 2d array. E.g. [ [{ A: 1}, {B: 2 }], [{ C: 3 }, { D: 4 }] ]
function getAppliedFiltersGroups<T extends FiltersDataType>(
  dataType: T,
  searchFilters: FilterToken[],
  context: FiltersContext<T>,
) {
  const { me } = context;

  let appliedFilters = searchFilters;

  // If Me filter is on, do not filter by people related criteria
  if (me) {
    appliedFilters = searchFilters.filter(
      (x) => !PERSON_RELATED_TYPES.includes(x.type),
    );
  }

  const groupedFilters: GroupedFilterToken[][] = [[]];
  let currentGroup: GroupedFilterToken[] = groupedFilters[0];

  for (const filter of appliedFilters) {
    if (currentGroup.length && isOr(filter.operator)) {
      currentGroup = [];
      groupedFilters.push(currentGroup);
    }

    const values = getFilterValues(filter, context.user);
    const matcher = getFilterMatcher(dataType, context, filter.type, values);

    currentGroup.push({
      ...filter,
      values,
      matcher,
    });
  }

  if (me) {
    const values = getMeFilterValues(context.user);

    const meFilter: GroupedFilterToken = {
      type: 'me',
      values,
      matcher: getFilterMatcher(dataType, context, 'me', values),
    };

    groupedFilters.forEach((group) => {
      if (!group.some((filter) => filter.type === 'me')) {
        group.push(meFilter);
      }
    });
  }

  return groupedFilters;
}

function notOperatorAccepted<T extends FiltersDataType>(
  type: VirtualFilterTypes,
  entity: FiltersEntity<T>,
  dataType: T,
) {
  if (dataType === 'tasks' || dataType === 'timeoffs') {
    // If a multi-assign task/timeoff is on both person A and person B,
    // and we have a "NOT A" filter applied, we still want the task to
    // be included for person B. Therefore, we don't apply
    // person-related NOT filter logic to tasks or timeoffs.
    if (PERSON_RELATED_TYPES.includes(type)) {
      return (
        (entity as FiltersEntity<'tasks' | 'timeoffs'>).people_ids?.length <= 1
      );
    }

    return true;
  }

  // If we're searching by an exclusion of project related keys, we don't
  // want to completely filter out people, because we do want to show
  // them on the schedule if present on other projects.
  if (dataType === 'people' && PROJECT_RELATED_TYPES.includes(type)) {
    return false;
  }

  // If we're searching by an exclusion of project related keys, we don't
  // want to completely filter out projects, because we do want to show
  // them on the schedule with other people if any.
  if (dataType === 'projects' && PERSON_RELATED_TYPES.includes(type)) {
    return false;
  }

  // If we're searching by an exclusion of taskStatus, we don't
  // want to completely filter out projects.
  // E.g. when is filtering by "taskStatus not Completed" we want just to
  // make all the non-completed tasks more evident.
  if (dataType === 'projects' && type === 'taskStatus') {
    return false;
  }

  return true;
}

const getFilteredData = <T extends FiltersDataType, ID extends string | number>(
  dataType: T,
  context: FiltersContext<T>,
  data: FiltersEntity<T>[],
  searchFilters: FilterToken[] = [],
  getEntityId: (entity: FiltersEntity<T>) => ID,
): Set<ID> => {
  const { me } = context;

  const noFilters = searchFilters.length === 0 && !me;

  // This maps [{ type, val }, { type, val2 }, ...] to { type: [val, val2], type2: [val3] }
  const groupedFilters = getAppliedFiltersGroups(
    dataType,
    searchFilters,
    context,
  );

  const result = new Set<ID>();

  data.forEach((entity) => {
    if (entity === undefined) return false;

    if (noFilters && !me) {
      result.add(getEntityId(entity));
      return;
    }

    const match = groupedFilters.some((filterGroup) => {
      return filterGroup.every((filter) => {
        if (!filter.values.length && filter.type !== 'me') return true;

        if (filter.matcher.forceMatch) return true;

        if (isNot(filter.operator)) {
          if (notOperatorAccepted(filter.type, entity, dataType)) {
            return filter.matcher.matches(entity) === false;
          }

          return true;
        }

        return filter.matcher.matches(entity);
      });
    });

    if (match) {
      result.add(getEntityId(entity));
    }
  });

  return result;
};

export const forPeople = (
  context: FiltersContext<'people'>,
  people: FiltersEntity<'people'>[],
  filters: FilterToken[],
) => {
  return getFilteredData(
    'people',
    context,
    people,
    filters,
    (entity) => entity.people_id,
  );
};

export const forProjects = (
  context: FiltersContext<'projects'>,
  projects: FiltersEntity<'projects'>[],
  filters: FilterToken[],
) => {
  return getFilteredData(
    'projects',
    context,
    projects,
    filters,
    (entity) => entity.project_id,
  );
};

export const forTasks = (
  context: FiltersContext<'tasks'>,
  tasks: FiltersEntity<'tasks'>[],
  filters: FilterToken[],
) => {
  return getFilteredData(
    'tasks',
    context,
    tasks,
    filters,
    (entity) => entity.task_id,
  );
};

export const forTimeoffs = (
  context: FiltersContext<'timeoffs'>,
  timeoffs: FiltersEntity<'timeoffs'>[],
  filters: FilterToken[],
) => {
  return getFilteredData(
    'timeoffs',
    context,
    timeoffs,
    filters,
    // Casting to string because timeoffs are part of the filteredEntities
    // and aligning all the filteredEntities types to string helps on reducing
    // possible false negatives related to the id types
    (entity) => String(entity.timeoff_id),
  );
};

export const forLoggedTimes = (
  context: FiltersContext<'loggedTimes'>,
  loggedTimes: FiltersEntity<'loggedTimes'>[],
  filters: FilterToken[],
) => {
  return getFilteredData(
    'loggedTimes',
    context,
    loggedTimes,
    filters,
    (entity) => entity.logged_time_id,
  );
};
