import { toDash } from '@float/libs/utils/strings';
import { RawRole, Role } from '@float/types';

import {
  makeRequest,
  MakeRequestParams,
  Payload,
  PayloadAndID,
} from './makeRequest';

const RESOURCE = 'roles';

export type RoleApiPayload = Pick<RawRole, 'name' | 'default_hourly_rate'>;

export const roles = {
  /**
   * Retrieve a role
   * @param id: role id
   */
  getRole: async (
    params: MakeRequestParams<undefined, Role['id']>,
  ): Promise<RawRole> =>
    makeRequest({
      ...params,
      resource: toDash(RESOURCE),
      method: 'GET',
    }),

  /**
   * Retrieve roles
   */
  getRoles: async (params?: MakeRequestParams<undefined, undefined>) => {
    const [roles] = await makeRequest<[data: RawRole[]]>({
      ...params,
      resource: toDash(RESOURCE),
      method: 'GET',
    });

    return roles;
  },

  /**
   * Creates new role
   * @param data: new role attributes
   */
  createRole: async (params: Payload<RoleApiPayload>): Promise<RawRole> =>
    makeRequest<RawRole, RoleApiPayload>({
      ...params,
      resource: toDash(RESOURCE),
      method: 'POST',
    }),

  /**
   * Updates role
   * @param id: id of role to update
   * @param data: new role attributes
   */
  updateRole: async (
    params: PayloadAndID<RoleApiPayload, number>,
  ): Promise<RawRole> =>
    makeRequest({
      ...params,
      resource: toDash(RESOURCE),
      method: 'PATCH',
    }),

  /**
   * Deletes role
   * @param id: id of role to delete
   */
  deleteRole: async (params: { id: RawRole['id'] }): Promise<void> =>
    makeRequest({
      ...params,
      resource: toDash(RESOURCE),
      method: 'DELETE',
    }),
};
