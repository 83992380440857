import React from 'react';
import { t } from '@lingui/macro';

import { Button } from '@float/ui/deprecated/Button/Button';
import { IconRefresh } from '@float/ui/icons/essentials/IconRefresh';

type SyncButtonProps = {
  disabled: boolean;
  hasSyncedAtLeastOnce: boolean;
  isUrlDefined: boolean;
  onClick: () => void;
};

export const SyncButton = ({
  disabled,
  hasSyncedAtLeastOnce,
  isUrlDefined,
  onClick,
}: SyncButtonProps) => {
  let content: React.ReactNode = t`Sync calendar`;
  let icon = null;
  let appearance = 'primary';

  if (hasSyncedAtLeastOnce) {
    content = t`Manual resync`;
    icon = IconRefresh;
  }

  if (hasSyncedAtLeastOnce && !isUrlDefined) {
    content = t`Delete`;
    icon = null;
    appearance = 'danger';
  }

  return (
    <Button
      disabled={disabled}
      icon={icon}
      onClick={onClick}
      appearance={appearance}
    >
      {content}
    </Button>
  );
};
