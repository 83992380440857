import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { selectDatesManager } from '@float/common/selectors/currentUser';
import { getActiveFilters } from '@float/common/selectors/views';
import { ScheduleRowList } from '@float/types/rows';

import {
  getMilestonesEntries,
  getProjectsScheduleData,
} from './getProjectsData.helpers';
import { buildWeekHeader } from './helpers';

export function getProjectsDataByWeek(payload: {
  rows: ScheduleRowList;
  state: ReduxStateStrict;
  startDate: string;
  endDate: string;
}) {
  const { state } = payload;

  const dates = selectDatesManager(state);

  // The export is by week, so we increase the range to contain the
  // start and end of the selected weeks
  const [startCol] = dates.toDescriptor(payload.startDate);
  const [endCol] = dates.toDescriptor(payload.endDate);
  dates.ensureRange(startCol, endCol + 1);

  const start = dates.toNum(dates.fromDescriptor(startCol, 0));
  const end = dates.toNum(dates.fromDescriptor(endCol + 1, 0)) - 1;

  const startDate = dates.fromNum(start);
  const endDate = dates.fromNum(end);

  const filters = getActiveFilters(state);

  function getWeekStartDate(date: string) {
    const [week] = dates.toDescriptor(date);

    return dates.fromDescriptor(week, 0);
  }

  const scheduleData = getProjectsScheduleData({
    endDate,
    // Aggregating by week
    getDateAggregationIndex: getWeekStartDate,
    rows: payload.rows,
    startDate,
    state: payload.state,
  });

  const milestones = getMilestonesEntries({
    endDate: payload.endDate,
    startDate: payload.startDate,
    state: payload.state,
    data: scheduleData,
  });

  const result = {
    header: buildWeekHeader(startCol, endCol, dates, {
      filters,
    }),
    scheduleData,
    milestones,
  };

  return result;
}
