import { createSelector } from 'reselect';

import {
  FullLoggedTime,
  LoggedTime,
  LoggedTimeDeletePlaceholder,
  SearchLoggedTime,
} from '@float/types';

import { ReduxState, ReduxStateStrict } from '../reducers/lib/types';

export function isSearchLoggedTime(
  loggedTime: FullLoggedTime | SearchLoggedTime | LoggedTimeDeletePlaceholder,
): loggedTime is SearchLoggedTime {
  return loggedTime.data_type === 'search';
}

export function isLoggedTimeDeletePlaceholder(
  loggedTime: FullLoggedTime | SearchLoggedTime | LoggedTimeDeletePlaceholder,
): loggedTime is LoggedTimeDeletePlaceholder {
  return loggedTime.data_type === 'delete_placeholder';
}

export function isFullLoggedTime(
  loggedTime:
    | FullLoggedTime
    | SearchLoggedTime
    | LoggedTimeDeletePlaceholder
    | undefined,
): loggedTime is FullLoggedTime {
  if (!loggedTime) return false;

  return loggedTime.data_type === 'full';
}

export const getLoggedHours = (state: ReduxState) =>
  state.loggedTimes.loggedHours;

export const getLoggedTimesRawMap = (state: ReduxStateStrict) =>
  state.loggedTimes.loggedTimes;

export const getLoggedTime = createSelector(
  [getLoggedTimesRawMap, (_, loggedTimeId: string) => loggedTimeId],
  (loggedTimes, loggedTimeId) => {
    const loggedTime = loggedTimes[loggedTimeId];

    if (isFullLoggedTime(loggedTime)) return loggedTime;

    return undefined;
  },
);

export const getLoggedTimesListRaw = createSelector(
  [getLoggedTimesRawMap],
  (loggedTimes) => Object.values(loggedTimes),
);

export const getFullLoggedTimesList = createSelector(
  [getLoggedTimesListRaw],
  (loggedTimes) =>
    loggedTimes.filter(
      (loggedTime): loggedTime is LoggedTime => loggedTime.data_type === 'full',
    ),
);
