import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';

export const calculatePlaceholdersDowngrade = (props: {
  currentPeopleCount: number;
  currentPeopleLimit: number;
  currentPlaceholderCount: number;
  nextPlaceholderLimit: number;
}) => {
  if (!featureFlags.isFeatureEnabled(FeatureFlag.PlaceholdersPackaging)) {
    return {
      canDowngrade: true,
      freePlaceholdersToPaidSeats: 0,
      exceedingPlaceholders: 0,
    };
  }

  const {
    currentPeopleCount,
    currentPeopleLimit,
    currentPlaceholderCount,
    nextPlaceholderLimit,
  } = props;

  const freePlaceholdersToPaidSeats = Math.max(
    0,
    currentPlaceholderCount - nextPlaceholderLimit,
  );

  const newPeopleCount = currentPeopleCount + freePlaceholdersToPaidSeats;
  const exceedingPlaceholders = Math.max(
    0,
    newPeopleCount - currentPeopleLimit,
  );

  return {
    canDowngrade: currentPeopleLimit >= newPeopleCount,
    freePlaceholdersToPaidSeats,
    exceedingPlaceholders,
  };
};
