import { FilterCategory } from '@float/common/search/types';

import { SearchFilterDropdownData } from '../data';
import { SearchFilterDropdownCategoryItem } from '../types';

export function getCategoryConfig(
  type: FilterCategory,
): SearchFilterDropdownCategoryItem {
  const category = SearchFilterDropdownData.find((item) => item.value === type);

  if (category) return category;

  // to debug the occurence of https://rollbar.com/float/fe-web-app/items/4879/
  throw new Error('No category found for ' + type);
}
