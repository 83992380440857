import { search } from '@float/common/api3/search';
import { FilterToken, FilterTypes } from '@float/types/view';

import { getLocalFilters } from '../helpers';
import { FILTER_TYPE_TO_REMOTE_TYPE } from './constants';

function toArray(val: string | string[]) {
  return Array.isArray(val) ? val : [val];
}

async function lookupFilterValue(type: FilterTypes, value: string) {
  const field = FILTER_TYPE_TO_REMOTE_TYPE[type];
  const localFilter = getLocalFilters().find(
    (f) => f.type === type && f.val === value,
  );

  if (localFilter?.id !== undefined) {
    return [localFilter.id];
  }

  if (localFilter?.lookupValue !== undefined) {
    return search.lookup(localFilter.lookupValue, field);
  }

  // All timeoff types is a special filter defined on the client code
  // When used we transform it into all the timeoff types ids
  if (type === 'timeoff' && value === '*') {
    const [allTimeoffTypes] = await search.query({
      query: '',
      filter: {
        key: 'category',
        value: FILTER_TYPE_TO_REMOTE_TYPE.timeoff,
      },
    });

    return allTimeoffTypes.flatMap((result) => result.ids);
  }

  return search.lookup(value, field);
}

/** Converts the filter values into a list of ids */
export async function lookupFilter(filter: FilterToken): Promise<number[]> {
  const val = toArray(filter.val);
  const type = filter.type;

  if (type === 'contains') return [];

  const results = await Promise.all(
    val.map((value) => lookupFilterValue(type, value)),
  );

  return results.flat();
}
