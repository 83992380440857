import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { moment } from '@float/libs/moment';
import { DateRangePicker } from '@float/ui/deprecated';
import { rangeOpts } from '@float/ui/deprecated/DateRangePicker/config';

class ReportsDatePicker extends Component {
  stopPropagation(evt) {
    evt.stopPropagation();
  }

  render() {
    const { currentUser } = this.props;
    const { startDate, endDate, rangeMode } = this.props.settings;

    // TODO firstOfWeek does not affect DatePicker right now
    // we're going to implement more robust checking of
    // company-wide settings, requires refactoring weekend CSS class
    const firstOfWeek = get(currentUser, 'start_work_week');
    const momentRange = moment.range(moment(startDate), moment(endDate));

    return (
      <DateRangePicker
        rangeMode={rangeMode && String(rangeMode)}
        firstOfWeek={firstOfWeek}
        rangeOpts={this.props.rangeOpts || rangeOpts}
        value={momentRange}
        onChange={this.props.onDateRangeChange}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
});

export default connect(mapStateToProps)(ReportsDatePicker);
