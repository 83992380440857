import React from 'react';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';

import BaseIcon from './BaseIcon';

export default function ({
  className,
  color = Colors.FIN.Lt.Emphasis.High,
}: {
  className?: string;
  color?: string;
}) {
  return (
    <BaseIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 5.5C6.05964 5.5 5.5 6.05964 5.5 6.75V8.25C5.5 8.94036 6.05964 9.5 6.75 9.5H17.25C17.9404 9.5 18.5 8.94036 18.5 8.25V6.75C18.5 6.05964 17.9404 5.5 17.25 5.5H6.75ZM4 6.75C4 5.23122 5.23122 4 6.75 4H17.25C18.7688 4 20 5.23122 20 6.75V8.25C20 9.76878 18.7688 11 17.25 11H6.75C5.23122 11 4 9.76878 4 8.25V6.75ZM6.75 14.5C6.05964 14.5 5.5 15.0596 5.5 15.75V17.25C5.5 17.9404 6.05964 18.5 6.75 18.5H8.25C8.94036 18.5 9.5 17.9404 9.5 17.25V15.75C9.5 15.0596 8.94036 14.5 8.25 14.5H6.75ZM4 15.75C4 14.2312 5.23122 13 6.75 13H8.25C9.76878 13 11 14.2312 11 15.75V17.25C11 18.7688 9.76878 20 8.25 20H6.75C5.23122 20 4 18.7688 4 17.25V15.75ZM14.5 15.75C14.5 15.0596 15.0596 14.5 15.75 14.5H17.25C17.9404 14.5 18.5 15.0596 18.5 15.75V17.25C18.5 17.9404 17.9404 18.5 17.25 18.5H15.75C15.0596 18.5 14.5 17.9404 14.5 17.25V15.75ZM15.75 13C14.2312 13 13 14.2312 13 15.75V17.25C13 18.7688 14.2312 20 15.75 20H17.25C18.7688 20 20 18.7688 20 17.25V15.75C20 14.2312 18.7688 13 17.25 13H15.75Z"
        fill={color}
      />
    </BaseIcon>
  );
}
