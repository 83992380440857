import { SETTINGS_UPDATE_AUTO_EMAIL } from '@float/common/actions/settings-legacy';
import request from '@float/common/lib/request';

import { parseEmailPrefs } from './helpers';

export const saveScheduleEmailPreferences = (prefs) => {
  const str = `noticesUpdate?${encodeURI(parseEmailPrefs(prefs))}`;
  return request.get(str, null, { version: '', hostname: '' });
};

export const sendScheduleEmail = (prefs) => {
  const str = `emailNotices?${encodeURI(parseEmailPrefs(prefs))}`;
  return request.get(str, null, { version: '', hostname: '' });
};

export const updateRecurringEmailSettings = ({ auto_email = 1 }) => {
  return (dispatch) => {
    dispatch({ type: SETTINGS_UPDATE_AUTO_EMAIL, auto_email });
  };
};

export const saveLogTimeEmailPreferences = (prefs) => {
  return request.post('admin-api/log-time-prefs', prefs, {
    version: '',
    hostname: '',
  });
};
