import { isEmpty } from 'lodash';

import { TemplateDates } from '@float/types/project';

import { ProjectFormData } from '../types';

function mapMilestoneOffsetsToDates(
  milestones: ProjectFormData['milestones'],
  templateMilestones: TemplateDates['milestones'],
) {
  return milestones?.map((milestone) => {
    const templateMilestoneId = milestone.template_milestone_id;
    if (!templateMilestoneId) return milestone;

    const templateMilestone = templateMilestones?.[templateMilestoneId];
    if (!templateMilestone) return milestone;

    return {
      ...milestone,
      duration: undefined,
      parent_offset: undefined,
      template_milestone_id: undefined,
      date: templateMilestone.start_date,
      end_date: templateMilestone.end_date,
    };
  });
}

export function mapProjectOffsetsToDates(
  currentValues: ProjectFormData,
  templateDates: TemplateDates,
) {
  const { project, phases, milestones } = currentValues;
  const updates: Partial<ProjectFormData> = {};

  if (templateDates.end_date) {
    updates.project = {
      ...project,
      duration: undefined,
      end_date: templateDates.end_date,
    };
  }

  if (!isEmpty(templateDates?.milestones)) {
    updates.milestones = mapMilestoneOffsetsToDates(
      milestones,
      templateDates.milestones,
    );
  }

  if (!isEmpty(templateDates?.phases)) {
    updates.phases = phases?.map((phase) => {
      const templatePhaseId = phase.template_phase_id;
      if (!templatePhaseId) return phase;

      const templatePhase = templateDates.phases?.[templatePhaseId];
      if (!templatePhase) return phase;

      const result = {
        ...phase,
        duration: undefined,
        parent_offset: undefined,
        template_phase_id: undefined,
        start_date: templatePhase.start_date,
        end_date: templatePhase.end_date,
      };

      if (result.fullPhaseData) {
        const { phase: phaseInfo, milestones } = result.fullPhaseData;
        phaseInfo.duration = undefined;
        phaseInfo.parent_offset = undefined;
        phaseInfo.template_phase_id = undefined;
        phaseInfo.start_date = templatePhase.start_date;
        phaseInfo.end_date = templatePhase.end_date;
        result.fullPhaseData.milestones = mapMilestoneOffsetsToDates(
          milestones,
          templatePhase.milestones,
        );
      }

      return result;
    });
  }

  return updates;
}
