import { padTo2Digits } from '@float/common/lib/number/padTo2Digits';
import { TimeFormat } from '@float/types';

function formatAsDuration(value: string, timeFormat: TimeFormat) {
  let [hours = 0, minutes = 0] = value.split(':').map(Number);

  hours = isNaN(hours) ? 0 : hours;
  minutes = isNaN(minutes) ? 0 : minutes;

  const maxHours = parseInt(timeFormat, 10);
  hours = Math.min(maxHours, hours);
  if (hours >= maxHours) minutes = 0;
  if (minutes >= 60) minutes = 59;

  const newValue = `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;

  return newValue;
}

export { formatAsDuration };
