import React, { useRef } from 'react';
import cn from 'classnames';

import { focusInput } from '@float/common/lib/dom/focusInput';
import { useFocusTrap } from '@float/common/lib/hooks/useFocusTrap';
import { TimeFormat, Timer } from '@float/types';
import { IconWatch } from '@float/ui/deprecated/Earhart/Icons';
import { useOnClickOutside } from '@float/ui/deprecated/hooks/useOnClickOutside';

import { TimerEntryRowActions } from './components/TimerEntryRowActions';
import { TimerEntryRowEditActions } from './components/TimerEntryRowEditActions';
import {
  ITimerEntryRowInputs,
  TimerEntryRowInputs,
} from './components/TimerEntryRowInputs';
import { useTimerEntryRow } from './hooks/useTimerEntryRow';

import * as styles from './styles.css';

export interface TimerEntryRowProps {
  active?: boolean;
  className?: string;
  disabled?: boolean;
  hidden?: boolean;
  timeFormat?: TimeFormat;
  timer: Timer;
  deleteTimer: (id: string) => void;
  updateTimer: (timer: Timer) => void;
}

const TimerEntryRow = (props: TimerEntryRowProps) => {
  const {
    active = false,
    className,
    disabled = false,
    hidden = false,
    timeFormat = '12',
    timer,
    deleteTimer,
    updateTimer,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);

  const timerInputsRef = useRef<ITimerEntryRowInputs | null>(null);

  const onDidStartEditing = () => {
    const inputLogged = timerInputsRef.current?.inputLoggedRef.current;

    focusInput(inputLogged);
  };

  const onDidStopEditing = () => {
    window.getSelection()?.removeAllRanges();
  };

  const {
    error,
    isDisabled,
    isEditing,
    isSubmitting,
    onClickCancel,
    onClickConfirm,
    onClickDelete,
    onClickEdit,
    onEndTimeChange,
    onLoggedTimeChange,
    onStartTimeChange,
    timerLogged,
    timerStart,
    timerEnd,
  } = useTimerEntryRow(
    timer,
    timeFormat,
    active,
    disabled,
    deleteTimer,
    updateTimer,
    onDidStartEditing,
    onDidStopEditing,
  );

  useFocusTrap(containerRef, 'input, button', isEditing);

  useOnClickOutside(onClickCancel, [containerRef]);

  return (
    <div
      className={cn(
        className,
        styles.container,
        { [styles.state.disabled]: isDisabled },
        { [styles.state.editing]: isEditing },
        { [styles.state.hidden]: hidden },
        { [styles.state.submiting]: isSubmitting },
      )}
      ref={containerRef}
      data-testid={timer?._id}
    >
      <div className={cn(styles.content)}>
        <IconWatch className={styles.iconWatch} size="18" />
        <TimerEntryRowInputs
          className={styles.timerEntryRowInputs}
          isEditing={isEditing}
          onEndTimeChange={onEndTimeChange}
          onLoggedTimeChange={onLoggedTimeChange}
          onStartTimeChange={onStartTimeChange}
          ref={timerInputsRef}
          timeFormat={timeFormat}
          timerEnd={timerEnd}
          timerLogged={timerLogged}
          timerStart={timerStart}
        />
        <TimerEntryRowActions
          className={styles.timerEntryRowActions}
          isDisabled={isEditing}
          onClickDelete={onClickDelete}
          onClickEdit={onClickEdit}
        />
      </div>

      {/* Edit overlay */}
      <TimerEntryRowEditActions
        className={styles.timerEntryRowEditActions}
        errorMessage={error?.message}
        isEditing={isEditing}
        isSubmitting={isSubmitting}
        onClickCancel={onClickCancel}
        onClickConfirm={onClickConfirm}
      />
    </div>
  );
};

export { TimerEntryRow };
