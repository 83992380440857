import React from 'react';
import { connect } from 'react-redux';
import { t } from '@lingui/macro';
import { isUndefined } from 'lodash';
import styled from 'styled-components';

import { bulkUpdateProjects } from '@float/common/actions/projects';
import { Rights } from '@float/common/lib/acl';
import { formatToDbString } from '@float/common/lib/budget';
import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { getUser } from '@float/common/selectors/currentUser';
import {
  MONETARY_BUDGET_TYPES,
  NON_MONETARY_BUDGET_TYPES,
} from '@float/constants/budgets';
import { CurrentUser } from '@float/types/account';
import { BudgetPriority, BudgetType, Project } from '@float/types/project';
import { CurrencyInput, Input, VirtualSelect } from '@float/ui/deprecated';
import {
  budgetOptions,
  rateOptions,
} from '@float/web/components/modals/ProjectModal/ProjectForm/InfoFragment';
import { WebAppDispatch } from '@float/web/lib/store';

const Row = styled.div`
  display: flex;
  margin-bottom: 22px;
`;

const Col = styled.div`
  width: 120px;
  margin-left: 40px;

  &:first-child {
    width: 340px;
    margin-left: 0;
  }
`;

const isBudgetTotalValid = Input.validators.number(9);
const isHourlyRateValid = Input.validators.number(6);
const isHourlyFeeType = (budgetRateType: 0 | 1) => budgetRateType === 1;

function getDefaultValue<K extends keyof Project>({
  projects,
  ids,
  key,
}: {
  ids: number[];
  projects: Record<number, Project>;
  key: K;
}) {
  const defaultValue = projects[ids[0]][key];
  if (
    !isUndefined(defaultValue) &&
    ids.every((id) => projects[id][key] === defaultValue)
  ) {
    return defaultValue;
  }
  return null;
}

const validBulkOptions = budgetOptions.filter((option) => {
  // We don't support updating the budget_priority on the bulk updates
  // so we are showing only the project-related options
  return option.budget_priority === BudgetPriority.Project;
});

type Value = {
  budget_type: BudgetType | null | undefined;
  budget_total: string | null | undefined;
  default_hourly_rate: string | null | undefined;
};

type Payload = {
  budget_type: BudgetType | undefined;
  budget: string | null | undefined;
  default_hourly_rate?: string | null;
};

type Props = {
  currentUser: CurrentUser;
  ids: number[];
  projects: Record<number, Project>;
  onChange: (value: Value) => void;
  value: Value;
  bulkUpdateProjects: (ids: number[], fields: Payload) => void;
};

class Budget extends React.Component<Props> {
  budgetOptionsForUser = Rights.canViewBudget(this.props.currentUser)
    ? validBulkOptions
    : validBulkOptions.filter((bo) =>
        NON_MONETARY_BUDGET_TYPES.includes(bo.value),
      );

  initialValue: Value = {
    budget_total: null,
    budget_type: null,
    default_hourly_rate: null,
  };

  componentDidMount() {
    this.setDefaultValue();
  }

  setDefaultValue = () => {
    const { ids, projects, onChange } = this.props;
    this.initialValue = {
      budget_type: getDefaultValue({ projects, ids, key: 'budget_type' }),
      budget_total: getDefaultValue({ projects, ids, key: 'budget_total' }),
      default_hourly_rate: getDefaultValue({
        projects,
        ids,
        key: 'default_hourly_rate',
      }),
    };

    onChange(this.initialValue);
  };

  onUpdate = async () => {
    const { value } = this.props;

    if (value.budget_type === null) {
      return Promise.reject({ message: 'No changes made.' });
    }

    const fields: Payload = {
      budget_type: value.budget_type,
      budget: formatToDbString(value.budget_total),
    };

    if (value.default_hourly_rate !== this.initialValue.default_hourly_rate) {
      fields.default_hourly_rate = formatToDbString(value.default_hourly_rate);
    }

    return this.props.bulkUpdateProjects(this.props.ids, fields);
  };

  onBudgetTypeChange = ({ value }: { value: BudgetType }) => {
    this.props.onChange({
      budget_type: value,
      budget_total: '0',
      default_hourly_rate: null,
    });
  };

  onBudgetTotalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: budget_total } = e.target;

    if (!isBudgetTotalValid(budget_total)) {
      return;
    }

    this.props.onChange({
      ...this.props.value,
      budget_total,
    });
  };

  onRateTypeChange = ({ value: rate_type }: { value: 0 | 1 }) => {
    this.props.onChange({
      ...this.props.value,
      default_hourly_rate: rate_type === 0 ? null : '0',
    });
  };

  onHourlyRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: default_hourly_rate } = e.target;
    if (!isHourlyRateValid(default_hourly_rate)) {
      return;
    }

    this.props.onChange({
      ...this.props.value,
      default_hourly_rate,
    });
  };

  render() {
    const { currentUser } = this.props;
    const { budget_type, budget_total, default_hourly_rate } = this.props.value;
    const budgetRateType = default_hourly_rate === null ? 0 : 1;

    return (
      <>
        <Row>
          <Col>
            <VirtualSelect
              // @ts-expect-error VirtualSelect doesn't have types
              label="Budget"
              visibleItems={6}
              nonNullable
              value={budget_type}
              options={this.budgetOptionsForUser}
              onChange={this.onBudgetTypeChange}
            />
          </Col>

          {budget_type === 1 && (
            <Col>
              <Input
                required
                label={t`Hours`}
                ariaLabel={t`Hours`}
                placeholder="0"
                value={budget_total == '0' ? '' : budget_total}
                onChange={this.onBudgetTotalChange}
              />
            </Col>
          )}

          {budget_type === 2 && (
            <Col>
              <CurrencyInput
                required
                label={t`Total`}
                currentUser={currentUser}
                value={budget_total}
                onChange={this.onBudgetTotalChange}
              />
            </Col>
          )}
        </Row>

        {!!budget_type && MONETARY_BUDGET_TYPES.includes(budget_type) && (
          <Row>
            <Col>
              <VirtualSelect
                // @ts-expect-error VirtualSelect doesn't have types
                label={t`Rate`}
                nonNullable
                visibleItems={6}
                value={budgetRateType}
                options={rateOptions}
                onChange={this.onRateTypeChange}
              />
            </Col>

            {isHourlyFeeType(budgetRateType) && (
              <Col>
                <CurrencyInput
                  required
                  currentUser={currentUser}
                  label="Per Hour"
                  value={default_hourly_rate}
                  onChange={this.onHourlyRateChange}
                />
              </Col>
            )}
          </Row>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: ReduxStateStrict) => ({
  projects: state.projects.projects,
  currentUser: getUser(state),
});

const mapDispatchToProps = (dispatch: WebAppDispatch) => ({
  bulkUpdateProjects: (ids: number[], fields: Payload) =>
    dispatch(bulkUpdateProjects(ids, fields)),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(Budget);
