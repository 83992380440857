import { sortByDateAndName } from '@float/common/lib/itemSort';
import { getTaskMetasOptions } from '@float/common/selectors/taskMetas';
import { AppStore } from '@float/common/store';
import { TaskMeta } from '@float/types/task';
import {
  getMilestonesByPhaseId,
  getPhaseById,
  getProjectById,
} from '@float/web/selectors';

import { FormType, PhaseEditData, ProjectFormInitialData } from '../types';

export function getPhaseData(
  store: AppStore,
  panelPayload?: PhaseEditData,
): ProjectFormInitialData {
  const state = store.getState();

  const phase = getPhaseById(state, panelPayload?.phaseId) || undefined;
  const project = getProjectById(state, panelPayload?.projectId);
  const milestones = sortByDateAndName(
    getMilestonesByPhaseId(state, panelPayload?.phaseId),
    'date',
    'name',
  );
  const tasks = getTaskMetasOptions(
    state.taskMetas.taskMetas,
    phase?.project_id,
    phase?.phase_id,
    null,
  );

  return {
    type: panelPayload?.isTemplate ? FormType.PhaseTemplate : FormType.Phase,
    phase,
    project,
    milestones,
    tasks: tasks as TaskMeta[],
    phases: [],
  };
}
