import React, { ChangeEvent, KeyboardEvent, useState } from 'react';

import { normalizeColor } from '@float/libs/colors/utils/normalizeColor';
import { Input } from '@float/ui/deprecated/Input';

const isValidColor = (value: string) => normalizeColor(value) === value;

export const ColorPickerInput = (props: {
  id?: string;
  value?: string;
  onChange: (value: string) => void;
  onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
}) => {
  const [value, setValue] = useState(props.value);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    let { value } = e.target;

    if (value.charAt(0) === '#') value = value.substring(1);

    if (isValidColor(value)) {
      props.onChange(value);
    }

    setValue(e.target.value);
  }

  return (
    <Input
      inputId={props.id}
      size="large"
      value={value}
      onChange={handleChange}
      onKeyDown={props.onKeyDown}
    />
  );
};
