import styled from 'styled-components';

type ColorCellProps = {
  color: string;
  clickable?: boolean;
};

export const ColorCell = styled.div<ColorCellProps>`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: ${(props) => props.color};

  ${({ clickable }) =>
    clickable &&
    `
    cursor: pointer;
  `}
`;
