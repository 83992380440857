import React, { StrictMode } from 'react';
import { useQuery } from '@tanstack/react-query';

import { Resources } from '@float/common/api3/constants';
import { requestMePageData } from '@float/common/api3/pageData';

import { FullPageLoader } from '../FullPageLoader';
import TeamExist from './TeamExist/TeamExist';

export const SignupExist = (props: object) => {
  const { data, isLoading } = useQuery({
    queryKey: [Resources.MePageData],
    queryFn: requestMePageData,
  });

  return (
    <main className="page-multi-account-login">
      <StrictMode>
        <FullPageLoader isLoading={isLoading}>
          <TeamExist {...props} email={data?.maEmail} />
        </FullPageLoader>
      </StrictMode>
    </main>
  );
};
