import React from 'react';
import { t, Trans } from '@lingui/macro';

import { Person } from '@float/types/person';
import { Project } from '@float/types/project';
import {
  ModalConfirm,
  ModalConfirmAppearance,
} from '@float/ui/components/Modals/ModalConfirm';
import { Modal, ModalProps } from '@float/ui/primitives/Modal';

import { ModalSwitchPersonRenderProps } from '../../ModalSwitchPerson.types';
import { useSwitchPersonConfirm } from './useSwitchPersonConfirm';

export type ModalSwitchPersonConfirmProps = {
  modalProps: ModalProps;
  onSwitch?: ModalSwitchPersonRenderProps['onSwitch'];
  onSwitchComplete: () => void;
  person: Person | undefined;
  project: Project | undefined;
  selectedPersonId: Person['people_id'] | undefined;
};

export const ModalSwitchPersonConfirm = (
  props: ModalSwitchPersonConfirmProps,
) => {
  const {
    modalProps,
    person = undefined,
    project = undefined,
    selectedPersonId = undefined,
    onSwitch,
    onSwitchComplete,
  } = props;

  const personName = person?.name;
  const projectName = project?.project_name;

  const title = t`Delete existing logged time on ${projectName}?`;
  const description = t`Switching People will delete all logged time for "${personName}" on ${projectName}. Deleted logged time cannot be restored.`;

  const { onClickConfirm } = useSwitchPersonConfirm({
    person,
    project,
    selectedPersonId,
    onSwitch,
    onSwitchComplete,
  });

  return (
    <ModalConfirm
      appearance={ModalConfirmAppearance.Danger}
      description={description}
      modalProps={modalProps}
      primaryButtonLabel={t`Delete logged time and switch person`}
      title={title}
      twoStepConfirmation={true}
      onClickConfirm={onClickConfirm}
    >
      <Modal.P>
        <Trans>
          Switching people will delete all logged time for "{personName}" on{' '}
          {projectName}.
        </Trans>
      </Modal.P>

      <Modal.P color="critical">
        <Trans>Deleted logged time cannot be restored.</Trans>
      </Modal.P>
    </ModalConfirm>
  );
};
