import { isLegacyPlaceholderType } from '@float/common/lib/people/isLegacyPlaceholderType';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { Person } from '@float/types/person';

/**
 * Custom hook to manage the state of the placeholder option based on the given
 * person's information. It determines whether the placeholder option should be
 * enabled or not.
 *
 * The Placeholder option should be enabled unless:
 *
 *   - Placeholder Packaging feature is enabled AND
 *   - Current person has an email and/or access AND
 *   - Initial person is not a legacy placeholder.
 *
 * @param {UsePlaceholderOptionStateArgs} props - The props object containing
 * the person and initialPerson properties.
 * @param {Person} props.initialPerson - The person object representing the
 * initial state.
 * @param {Person} props.person - The person object representing the current
 * state.
 * @returns {Object} An object containing isPlaceholderOptionEnabled property.
 */

export type UsePlaceholderOptionStateArgs = {
  initialPerson: Person;
  person: Person;
};

export const usePlaceholderOptionState = (props: {
  initialPerson: Person;
  person: Person;
}) => {
  const { initialPerson, person } = props;

  let isPlaceholderOptionEnabled = true;

  const isPlaceholdersPackagingFeatureEnabled = featureFlags.isFeatureEnabled(
    FeatureFlag.PlaceholdersPackaging,
  );
  const currentPersonHasEmailAndOrAccess = !!person.email || !!person.account;
  const initialPersonIsLegacyPlaceholder = isLegacyPlaceholderType({
    person: initialPerson,
  });

  if (
    isPlaceholdersPackagingFeatureEnabled &&
    currentPersonHasEmailAndOrAccess &&
    !initialPersonIsLegacyPlaceholder
  ) {
    isPlaceholderOptionEnabled = false;
  }

  return { isPlaceholderOptionEnabled };
};
