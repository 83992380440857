import React from 'react';
import { connect } from 'react-redux';
import { differenceBy, intersectionBy } from 'lodash';
import { getPeopleTagByLabel, getProjectTagByLabel, getUser } from 'selectors';
import { DEFAULT_TAG_COLOR, getTagLabel, TAG_TYPE } from 'selectors/tags';

import { Rights } from '@float/common/lib/acl';
import { MultiSelect } from '@float/ui/deprecated';

class Tags extends React.Component {
  componentDidMount() {
    this.setDefaultValue();
  }

  onAdd = (tag) => {
    const { value, onChange } = this.props;
    onChange([...(value || []), { ...tag }]);
  };

  onRemove = (tag) => {
    const { value, onChange } = this.props;
    onChange(value.filter((t) => t !== tag));
  };

  setDefaultValue = () => {
    const { type, ids, entities, onChange } = this.props;
    const tagsPerEntity = ids.map((id) => {
      const entity = entities[id];
      if (entity && entity.tags && entity.tags.length) {
        return entity.tags.map((t) => {
          const tagName = typeof t === 'string' ? t : t.name;

          return {
            label: tagName,
            name: tagName,
            type: type === 'project' ? TAG_TYPE.PROJECT : TAG_TYPE.PEOPLE,
            color: this.getTagColor(tagName),
          };
        });
      }
      return null;
    });

    const defaultValue =
      tagsPerEntity.length === 1
        ? tagsPerEntity[0]
        : intersectionBy(...tagsPerEntity, getTagLabel);

    this.initialTags = defaultValue && defaultValue.length ? defaultValue : [];
    onChange(this.initialTags);
  };

  getValue = () => {
    const { value } = this.props;

    const add = differenceBy(value, this.initialTags, getTagLabel);
    const del = differenceBy(this.initialTags, value, getTagLabel);

    return {
      add: add.map(getTagLabel),
      del: del.map(getTagLabel),
    };
  };

  getTagColor = (tagName) => {
    const { type, peopleTagByLabel, projectTagByLabel } = this.props;
    const tagByLabel =
      type === 'project' ? projectTagByLabel : peopleTagByLabel;

    return tagByLabel[tagName]?.color
      ? `#${tagByLabel[tagName].color}`
      : DEFAULT_TAG_COLOR;
  };

  render() {
    const { type, value, options, currentUser } = this.props;
    const tagType = type === 'project' ? TAG_TYPE.PROJECT : TAG_TYPE.PEOPLE;
    const canCreateTags = Rights.canCreateTags(currentUser, {
      tag: { type: tagType },
    });

    return (
      <MultiSelect
        creatable={canCreateTags}
        visibleItems={6}
        values={value}
        onAdd={this.onAdd}
        onRemove={this.onRemove}
        options={options.map((tag) => {
          return {
            ...tag,
            name: tag.label,
            color: this.getTagColor(tag.label),
          };
        })}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: getUser(state),
  peopleTagByLabel: getPeopleTagByLabel(state),
  projectTagByLabel: getProjectTagByLabel(state),
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(Tags);
