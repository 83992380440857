import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { CompanyPreferences } from '@float/types/companyPreferences';
import { Person } from '@float/types/person';
import { hasReachedPeopleSeatsLimit } from 'components/modals/helpers/hasReachedPeopleSeatsLimit';
import { hasReachedPlaceholderSeatsLimit } from 'components/modals/helpers/hasReachedPlaceholderSeatsLimit';

export const canActivatePerson = (
  person: Person,
  companyPrefs: CompanyPreferences,
) => {
  const hasPeopleSeats = !hasReachedPeopleSeatsLimit(companyPrefs, 1);

  if (featureFlags.isFeatureEnabled(FeatureFlag.PlaceholdersPackaging)) {
    const isPlaceholder = person.people_type_id === 3;
    const hasPlaceholderSeats = !hasReachedPlaceholderSeatsLimit(
      companyPrefs,
      1,
    );

    return (isPlaceholder && hasPlaceholderSeats) || hasPeopleSeats;
  }

  return hasPeopleSeats;
};
