import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { Icons } from '@float/ui/deprecated';
import { useOnClickOutside } from '@float/ui/deprecated/hooks/useOnClickOutside';
import { updatePrompts } from '@float/web/store/onboardingManager/actions';

import { StyledNotification } from './styles';

let skipClosing = false;

const Notification = ({
  id,
  children,
  className,
  xy = ['left', 'bottom'],
  show = true,
  showOnlyOnce = false,
  width,
  style = {},
  animation = 'slide', // 'slide', 'zoom', 'fade', false
  arrow = null,
  dark = false,
  showClose = true,
  markAsSeen,
  closeOnBgClick = false,
  onClick,
  onClose,
  onCloseClick,
}) => {
  const [isShowing, setShowing] = useState(false);

  const close = useCallback(() => {
    if (isShowing) {
      setShowing(false);
      markAsSeen(id);
      if (typeof onClose === 'function') {
        onClose();
      }
    }
  }, [id, markAsSeen, isShowing, setShowing, onClose]);

  const closeClick = (e) => {
    prevent(e);
    if (typeof onCloseClick === 'function') {
      onCloseClick();
    }
    close();
  };

  // eslint-disable-next-line
  useEffect(() => {
    if (isShowing && !show) {
      if (skipClosing) {
        skipClosing = false;
        return;
      }
      close();
      return;
    }

    if (!isShowing && show) {
      setShowing(show);
      if (show && showOnlyOnce) {
        skipClosing = true;
        markAsSeen(id);
      }
    }
  });

  useOnClickOutside(() => {
    if (closeOnBgClick) {
      close();
    }
  }, []);

  return (
    <CSSTransition
      in={isShowing}
      classNames={xy[0]}
      timeout={500}
      unmountOnExit
    >
      <StyledNotification
        className={`${xy[0]} ${xy[1]} ${className || ''}`}
        x={xy[0]}
        y={xy[1]}
        width={width}
        style={style}
        animation={animation}
        arrow={arrow}
        dark={dark}
        onClick={onClick}
      >
        {showClose && <Icons.CloseSmall onClick={closeClick} />}
        {children}
      </StyledNotification>
    </CSSTransition>
  );
};

const mapDispatchToProps = (dispatch) => ({
  markAsSeen: (promptId) => dispatch(updatePrompts(promptId)),
});

export default connect(undefined, mapDispatchToProps)(Notification);
