import React from 'react';
import { Trans } from '@lingui/macro';

import { BudgetType } from '@float/types/project';
import { Tag } from '@float/ui/components/Tag';

export type BudgetTagProps = {
  className?: string;
  value: BudgetType;
};

export function BudgetTag(props: BudgetTagProps) {
  let budgetTypeLabel = <Trans>No budget</Trans>;

  switch (props.value) {
    case BudgetType.TotalHours:
      budgetTypeLabel = <Trans>Hours-based</Trans>;
      break;
    case BudgetType.TotalFee:
      budgetTypeLabel = <Trans>Fee-based</Trans>;
      break;
    case BudgetType.HourlyFee:
      budgetTypeLabel = <Trans>Hourly fee</Trans>;
      break;
  }

  return (
    <Tag color="primary" className={props.className}>
      {budgetTypeLabel}
    </Tag>
  );
}
