import { useMemo } from 'react';

import { PhaseInputData } from '@float/types';

import { PhaseEditData } from '../types';

// If editing an existing phase, we want to carry forward the changes
// made in the project form's phase row, if any, to the phase panel.
// We want to do this after form initialization to mark the form
// as dirty, so that hitting the "Update" button saves these values.
export function usePhaseFormValuesOverrides(panelPayload?: PhaseEditData) {
  const isEditing = Boolean(panelPayload?.phaseId);
  const { projectPhaseRecord } = panelPayload || {};
  const unsavedProjectInfo = panelPayload?.unsavedProject?.project;

  return useMemo(() => {
    // No phase or project changes to override
    if (!projectPhaseRecord && !unsavedProjectInfo) return undefined;

    // If creating a new phase, these values are already seeded in the
    // phase form's default values, so we don't need to override them.
    if (isEditing === false) return undefined;

    let overrides: Partial<PhaseInputData> = {};

    // If editing an existing phase, override phase form values with
    // changes in project form's phase row, marking phase form dirty.
    if (projectPhaseRecord) {
      overrides = { ...projectPhaseRecord };
    }

    // changes to project status must cascade to phases
    if (unsavedProjectInfo?.status !== undefined) {
      overrides.status = unsavedProjectInfo.status;
    }

    // phases in a non-billable project must be non-billable
    if (unsavedProjectInfo?.non_billable) {
      overrides.non_billable = true;
    }

    return overrides;
  }, [isEditing, projectPhaseRecord, unsavedProjectInfo]);
}
