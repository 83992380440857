import { padTo2Digits } from '../number/padTo2Digits';
import { decimalHoursToMinutes } from './decimalHoursToMinutes';

export const formatDecimalHoursAsClockTime = (value: number) => {
  const mins = decimalHoursToMinutes(value);
  const hrs = Math.floor(mins / 60);

  const hours = padTo2Digits(hrs);
  const minutes = padTo2Digits(mins % 60);

  const clockTime = `${hours}:${minutes}`;

  return clockTime;
};
