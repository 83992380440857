import { formatAmount } from '../budget/helpers/formatAmount';

export type CurrencyConfig = {
  prefix?: string;
  suffix?: string;
};

export const formatRate = (value: string | null | undefined) => {
  return formatAmount(undefined, value, {});
};

export const formatRateWithCurrencySymbol = (
  value: string | null | undefined,
  currencyConfig: CurrencyConfig,
  options: {
    useSpacing: boolean;
  } = { useSpacing: true },
) => {
  if (!value) return value;

  const valueFormatted = formatRate(value);
  const spacing = options.useSpacing ? ' ' : '';

  if (!currencyConfig.prefix && !currencyConfig.suffix) {
    return valueFormatted;
  } else if (currencyConfig.prefix) {
    return `${currencyConfig.prefix}${spacing}${valueFormatted}`;
  } else if (currencyConfig.suffix) {
    return `${valueFormatted}${spacing}${currencyConfig.suffix}`;
  }
};
