import React, { ChangeEventHandler } from 'react';
import { Trans } from '@lingui/macro';

import { Input } from '@float/ui/deprecated/Input';

export const MergeTasksInput: React.FC<{
  isUnnamedTask: boolean;
  newTaskName: string;
  onChange: ChangeEventHandler;
}> = ({ isUnnamedTask, newTaskName, onChange }) => {
  return isUnnamedTask ? (
    <p>
      <Trans>
        Allocations from the selected tasks will be merged to appear as <br />
        <strong>No task allocation</strong>.
      </Trans>
    </p>
  ) : (
    <Input
      autoFocus
      inputId="mergeTaskNameField"
      label="New Task Name"
      value={newTaskName}
      onChange={onChange}
    />
  );
};
