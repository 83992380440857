import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon {...props}>
    <defs>
      <linearGradient
        id="icon-activity-gradient"
        x1="17.09"
        x2="35.691"
        y1="18.081"
        y2="36.123"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5DD0CE" />
        <stop offset="1" stopColor="#1B7CEF" />
      </linearGradient>
    </defs>

    <path
      stroke="url(#icon-activity-gradient)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
      d="M39 23.05h-5.8L28.85 36.1 20.15 10 15.8 23.05H10"
    />
  </BaseIcon>
);
