import * as amplitude from '@amplitude/analytics-browser';

import { config } from '@float/libs/config';

import {
  AMPLITUDE_API_KEY_DEVELOPMENT,
  AMPLITUDE_API_KEY_STAGING_AND_PRODUCTION,
} from './tracking.constants';

export const getAmplitudeApiKey = () => {
  return config.isStagingOrProduction
    ? AMPLITUDE_API_KEY_STAGING_AND_PRODUCTION
    : AMPLITUDE_API_KEY_DEVELOPMENT;
};

export const initAmplitude = () => {
  const apiKey = getAmplitudeApiKey();

  amplitude.init(apiKey, {
    defaultTracking: {
      attribution: false,
      pageViews: false,
      sessions: false,
      formInteractions: false,
      fileDownloads: false,
    },
    cookieOptions: {
      secure: true,
    },
  });
};
