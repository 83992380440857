import { diffEntityListPayload } from '@float/common/lib/diffEntityPayload';
import { ProjectTeamMemberData } from '@float/types';

import { UnsavedProjectDataForPhase } from '../types';

type TeamDiff = UnsavedProjectDataForPhase['teamDiff'];

export function getProjectOnlyTeamMembers(team: ProjectTeamMemberData[]) {
  return team.filter((p) => !p.isAssignedToPhaseOnly);
}

export function getProjectTeamDiff(
  current?: ProjectTeamMemberData[],
  previous?: ProjectTeamMemberData[],
): TeamDiff | undefined {
  if (!current || !previous) return undefined;

  const currentProjectOnlyTeam = getProjectOnlyTeamMembers(current);
  const previousProjectOnlyTeam = getProjectOnlyTeamMembers(previous);

  return diffEntityListPayload(
    currentProjectOnlyTeam,
    previousProjectOnlyTeam,
    'people_id',
  );
}
