import styled, { css } from 'styled-components';

import * as Colors from '../Earhart/Colors';
import { respondTo } from '../Theme/helpers/responsive/respondTo';

export const PageBody = styled.div`
  background: ${Colors.TCore.Surface.Surf1};
  height: calc(100vh - 60px);
  overflow: auto;
  margin-right: 16px;
  border-radius: 12px 12px 0px 0px;

  ${respondTo('small')(css`
    margin-right: 0px;
    border-radius: 0px;
  `)}

  @media print {
    height: auto;
  }
`;
