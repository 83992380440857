import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

import { getErrorMessageFromException } from '@float/common/lib/utils';
import { Button, Input, withSnackbar } from '@float/ui/deprecated';
import { IconWarningTriangle } from '@float/ui/deprecated/Earhart/Icons';

const Form = styled.form<{ hasSecondaryText: boolean }>`
  display: flex;
  align-items: ${({ hasSecondaryText }) =>
    hasSecondaryText ? `center` : 'flex-end'};

  width: 67.5%;

  > * + * {
    margin-left: 10px;
  }
`;

export interface AddEntryProps {
  isLoading: boolean;
  onSubmit: (value: string) => Promise<any>;
  placeholder?: string;
  secondaryText?: string;
  maxLength?: number;
  showSnackbar: Function;
}

export function AddEntry(props: AddEntryProps) {
  const [inputVal, setInputVal] = useState('');

  const formattedVal = inputVal.trim();
  const isDisabled = props.isLoading || !formattedVal.length;

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (!inputVal) return;

    props
      .onSubmit(formattedVal)
      .then(() => {
        props.showSnackbar(`${formattedVal} added.`);
        setInputVal('');
      })
      .catch((err) => {
        props.showSnackbar(getErrorMessageFromException(err), {
          className: 'error',
          iconLeft: IconWarningTriangle,
          showClose: true,
        });
      });
  };

  return (
    <Form onSubmit={onSubmit} hasSecondaryText={!!props.secondaryText}>
      <Input
        label={props.placeholder}
        value={inputVal}
        secondaryText={props.secondaryText}
        maxLength={props.maxLength}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setInputVal(e.target.value)
        }
      />
      <Button loader={props.isLoading} disabled={isDisabled}>
        Add
      </Button>
    </Form>
  );
}

export default withSnackbar(AddEntry);
