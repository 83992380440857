import { difference } from 'lodash';

import request from '@float/common/lib/request';
import { ACTIVITY_ITEMS_PER_PAGE } from '@float/constants/activity';
import { WebAppDispatch } from '@float/web/lib/store';
import { WebAppStateStrict } from '@float/web/reducers/types';

import { receivedActivity } from '../../activity/actions';
import { getNotifications } from './notifications.selectors';

export const NOTIFICATIONS_FETCH_BEGIN = 'NOTIFICATIONS_FETCH_BEGIN';
export const NOTIFICATIONS_FETCH_SUCCESS = 'NOTIFICATIONS_FETCH_SUCCESS';
export const NOTIFICATIONS_SEEN = 'NOTIFICATIONS_SEEN';
export const NOTIFICATION_FEED_CLOSED = 'NOTIFICATION_FEED_CLOSED';

const itemsPerPage = ACTIVITY_ITEMS_PER_PAGE;

export const receivedNotifications =
  ({
    items,
    page,
    perPage,
    isLiveUpdate,
  }: {
    // @todo EXP-589: Improve notifications types
    // https://linear.app/float-com/issue/EXP-589
    items: Array<any>;
    page: number;
    perPage: number;
    isLiveUpdate?: boolean;
  }) =>
  (dispatch: WebAppDispatch) => {
    // @todo EXP-589: Improve notifications types
    // https://linear.app/float-com/issue/EXP-589
    const activities: Array<any> = [];

    items.forEach((notif) => {
      if (notif.activity) {
        activities.push({
          ...notif.activity,
          unseen: !notif.seen,
        });
      }
    });

    if (activities.length) {
      dispatch(
        // @ts-expect-error receivedActivity has no types
        receivedActivity({
          items: activities,
          specialName: 'notificationFeed',
          page,
        }),
      );
    }

    dispatch({
      type: NOTIFICATIONS_FETCH_SUCCESS,
      items,
      page,
      perPage,
      isLiveUpdate,
    });
  };

/**
 * Fetches a page of notifications for the notification feed.
 */
export const requestNotificationsFeedPage = ({ page }: { page: number }) => {
  return async (
    dispatch: WebAppDispatch,
    getState: () => WebAppStateStrict,
  ) => {
    const { shared_link_view: isSharedLink } = getState().currentUser;
    if (isSharedLink) {
      return Promise.resolve(null);
    }
    const { loadState } = getState().notifications;

    if (loadState === 'LOADING') {
      return Promise.resolve(null);
    }

    dispatch({ type: NOTIFICATIONS_FETCH_BEGIN });

    return request
      .get(
        'svc/nf/notifications',
        {
          page,
          'per-page': itemsPerPage,
        },
        { hostname: '', version: '', jwt: true },
      )
      .then(({ items }) => {
        dispatch(
          receivedNotifications({
            items,
            page,
            perPage: itemsPerPage,
          }),
        );
        return items;
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

/**
 * @deprecated
 * This function automatically increments the page number each time it is called
 * and is currently used by the live updates recovery function only.
 * It should be migrated to use `requestNotificationsFeedPage` to handle
 * explicit page requests for better control and consistency.
 */
export const requestNotifications = ({ toClear = false } = {}) => {
  return async (
    dispatch: WebAppDispatch,
    getState: () => WebAppStateStrict,
  ) => {
    const { shared_link_view: isSharedLink } = getState().currentUser;
    if (isSharedLink) {
      return Promise.resolve(null);
    }
    const { page: currentPage, loadState } = getState().notifications;
    const previousPage = toClear ? 0 : currentPage;
    if (loadState === 'LOADING') {
      return Promise.resolve(null);
    }

    dispatch({ type: NOTIFICATIONS_FETCH_BEGIN });

    const page = previousPage + 1;
    return request
      .get(
        'svc/nf/notifications',
        {
          page,
          'per-page': itemsPerPage,
        },
        { hostname: '', version: '', jwt: true },
      )
      .then(({ items }) => {
        dispatch(receivedNotifications({ items, page, perPage: itemsPerPage }));
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const notificationsSeen = ({
  ids,
  isLiveUpdate = false,
}: {
  ids: string[];
  isLiveUpdate?: boolean;
}) => ({
  type: NOTIFICATIONS_SEEN,
  ids,
  isLiveUpdate,
});

export const markNotificationsAsSeen =
  (ids: string[]) => (dispatch: WebAppDispatch) => {
    return request
      .put(
        'svc/nf/notifications',
        {
          ids,
        },
        { hostname: '', version: '', jwt: true },
      )
      .then((response) => {
        if (response && typeof response.updated !== 'undefined') {
          dispatch(notificationsSeen({ ids }));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

const getNotificationsNotAlreadySeen = (state: WebAppStateStrict) => {
  const notifications = getNotifications(state);
  const { pendingUpdate } = state.notifications;
  let ids = notifications.filter((n) => !n.seen).map((x) => x.nf_id);

  if (ids && ids.length && pendingUpdate && pendingUpdate.items) {
    const idsAlreadyMarkedAsSeen = Object.keys(pendingUpdate.items);
    ids = difference(ids, idsAlreadyMarkedAsSeen);
  }
  return ids;
};

const notificationFeedClosed = () => ({ type: NOTIFICATION_FEED_CLOSED });

export const closeNotificationFeed =
  () => (dispatch: WebAppDispatch, getState: () => WebAppStateStrict) => {
    dispatch(notificationFeedClosed());

    const state = getState();
    const ids = getNotificationsNotAlreadySeen(state);
    if (ids && ids.length) {
      dispatch(markNotificationsAsSeen(ids)).then(() => {
        dispatch(notificationFeedClosed());
      });
    }
  };
