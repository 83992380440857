import React from 'react';
import { t, Trans } from '@lingui/macro';

import { SavedView } from '@float/types';
import { ConfirmModal } from 'components/modals/ConfirmModal';

export type ConfirmDeleteViewModalProps = {
  onClose: () => void;
  onConfirm: () => void;
  view: SavedView;
};

export function ConfirmDeleteViewModal(props: ConfirmDeleteViewModalProps) {
  const isSharedView = props.view.personal === false;
  const viewName = props.view.name;

  const message = isSharedView ? (
    <Trans>
      Delete shared View “<b>{viewName}</b>”? This will delete the View for your
      entire team.
    </Trans>
  ) : (
    <Trans>
      Delete View “<b>{viewName}</b>”?
    </Trans>
  );

  return (
    <ConfirmModal
      title={t`Delete View`}
      message={message}
      confirmLabel={t`Delete`}
      confirmAppearance="danger"
      cancelLabel={t`Cancel`}
      onConfirm={props.onConfirm}
      onClose={props.onClose}
    ></ConfirmModal>
  );
}
