import { Dispatch } from 'react';
import { connect } from 'react-redux';

import { ReduxState } from '@float/common/reducers/lib/types';
import { TimeoffType } from '@float/types';
import Table from '@float/web/settingsV2/components/Table';
import withOnMount from '@float/web/settingsV2/decorators/withOnMount';
import {
  ACCOUNT_SETTINGS_TIMEOFF_TYPES_TABLE,
  getSortData,
  getTableRowCreator,
  getTableRows,
} from '@float/web/settingsV2/reducers/reducerHelpers';
import {
  areBeingFetched as timeoffTypesAreBeingFetched,
  lastFetchError as timeoffTypesLastFetchError,
} from '@float/web/settingsV2/reducers/timeoffTypes';

import {
  fetchTimeoffTypes,
  sortTimeoffTypes,
} from '../../actions/timeoffTypes';

const getTimeoffTypeData = getTableRowCreator<TimeoffType>(
  'timeoff_type_id',
  () => false,
  [
    ['color', 'timeoff_type_name'],
    'balance_type',
    (entity) => {
      const balance = entity['balance_days'];
      const shouldBeCastedToFloat = typeof balance === 'string';
      if (shouldBeCastedToFloat) {
        return parseFloat(balance);
      }

      return balance;
    },
  ],
);

const isLoading = (state: ReduxState) =>
  timeoffTypesAreBeingFetched(state.settingsTimeoffTypes);

const loadError = (state: ReduxState) =>
  timeoffTypesLastFetchError(state.settingsTimeoffTypes);

const mapStateToProps = (state: ReduxState) => {
  return {
    sort: getSortData(
      state.settingsTimeoffTypes,
      ACCOUNT_SETTINGS_TIMEOFF_TYPES_TABLE,
    ),
    rows: getTableRows(
      state.settingsTimeoffTypes,
      getTimeoffTypeData,
      'timeoff_type_id',
      ACCOUNT_SETTINGS_TIMEOFF_TYPES_TABLE,
    ),
    companyId: state.currentUser.cid,
    isLoading: isLoading(state),
    loadError: loadError(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    onComponentDidMount: () => {
      return dispatch(fetchTimeoffTypes(ACCOUNT_SETTINGS_TIMEOFF_TYPES_TABLE));
    },
    onClickHeader: (property: keyof TimeoffType, direction: 'asc' | 'desc') =>
      dispatch(
        sortTimeoffTypes(
          property,
          direction,
          ACCOUNT_SETTINGS_TIMEOFF_TYPES_TABLE,
        ),
      ),
    onSubmit: () => null,
  };
};

const TimeoffTypesTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOnMount(Table));

export default TimeoffTypesTable;
