import { FieldArray } from 'react-hook-form';

import { ProjectFormData } from '../types';
import { useFieldArrayContext } from './useFieldArrayContext';

// Required UX is to add new empty rows when the user presses the enter key
// Previously this was handled by the form-submit behaviour but quick inputs override that behaviour
// This event handler will attach to the row that is being edited and listens for the
// Enter key to add a new row under the right conditions.
export const useAddOnEnterKeyDown = ({
  canAdd,
  data,
}: {
  canAdd: boolean;
  data: () => FieldArray<ProjectFormData>;
}) => {
  const { append } = useFieldArrayContext<ProjectFormData>();
  const onEnterKeyDown = (e: React.KeyboardEvent) => {
    if (canAdd && e.key === 'Enter') {
      append(data());
    }
  };

  return {
    onEnterKeyDown,
  };
};
