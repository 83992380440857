import { connect } from 'react-redux';
import { modalManagerConnect } from 'modalManager/modalManagerHoc';

import {
  createTag,
  deleteTag,
  ensureTagsLoaded,
  updateTag,
} from '@float/common/actions/tags';
import { getUser } from '@float/common/selectors/currentUser';
import {
  getAdminPeopleTags,
  getAdminProjectTags,
} from '@float/common/selectors/tags';

import withUserCanSee from '../../../components/decorators/withUserCanSee';
import { deleteAccount } from '../../actions/accounts';
import { fetchPeople } from '../../actions/people';
import { fetchProjects } from '../../actions/projects';
import Tags from '../../components/Tags';
import withOnMount from '../../decorators/withOnMount';

const fetchInitialData = (dispatch) => {
  dispatch(fetchPeople());
  dispatch(ensureTagsLoaded(true));
  dispatch(fetchProjects());
};

const mapStateToProps = (state) => {
  const { loadState: projectLoadState } = state.projects;
  const { loadState: peopleLoadState } = state.people;
  const { loadState: tagsLoadState } = state.tags;

  const isLoading = [projectLoadState, peopleLoadState, tagsLoadState].includes(
    'LOADING',
  );
  const loadError = [projectLoadState, peopleLoadState, tagsLoadState].includes(
    'LOAD_FAILED',
  );

  return {
    currentUser: getUser(state),
    isLoading,
    isLoadingPeopleTag: [peopleLoadState, tagsLoadState].includes('LOADING'),
    isLoadingProjectTag: [projectLoadState, tagsLoadState].includes('LOADING'),
    loadError,
    accounts: state.accounts.accounts,
    projectTags: getAdminProjectTags(state),
    peopleTags: getAdminPeopleTags(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  createTag: (tag) => dispatch(createTag(tag)),
  updateTag: (tag) => dispatch(updateTag(tag)),
  deleteTag: (tag) => dispatch(deleteTag(tag)),
  onClickConfirmDelete: (id) => dispatch(deleteAccount(id)),
  onComponentDidMount: () => fetchInitialData(dispatch),
});

const StatefulTags = connect(
  mapStateToProps,
  mapDispatchToProps,
)(modalManagerConnect(withUserCanSee(withOnMount(Tags))));

export default StatefulTags;
