import React, { forwardRef } from 'react';

import { ProjectTask } from './ProjectTask';
import {
  StyledH1,
  StyledLabel,
  StyledModal,
  StyledProjectTasks,
  StyledTaskList,
} from './styles';

import BookSvgSource from './assets/book.svg';
import SpringSvgSource from './assets/spring.svg';

const totalTasks = Array.from({ length: 5 }, (v, i) => i);

export const ProjectTasks = forwardRef((props, ref) => {
  const { className, projectName, projectTasks, ...remainingProps } = props;

  const hasProjectName = !!projectName;

  return (
    <StyledProjectTasks ref={ref} className={className} {...remainingProps}>
      <StyledModal $highlighted={hasProjectName}>
        <StyledH1>{hasProjectName ? projectName : 'Project name'}</StyledH1>

        <StyledTaskList>
          <StyledLabel>Tasks</StyledLabel>

          <ul>
            {totalTasks.map((index) => {
              const task = (projectTasks && projectTasks[index]) || null;

              return <ProjectTask key={index} task={task} />;
            })}
          </ul>
        </StyledTaskList>
      </StyledModal>

      <img className="spring" src={SpringSvgSource} width={175} height={151} />
      <img className="book" src={BookSvgSource} width={249} height={193} />
    </StyledProjectTasks>
  );
});
