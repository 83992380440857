import React from 'react';
import { connect } from 'react-redux';
import { getDepartmentOptions, getDepartments, getUser } from 'selectors';

import { addDepartment } from '@float/common/actions/departments';
import { IconArrowDownRight } from '@float/ui/deprecated/Earhart/Icons';
import VirtualSelect from '@float/ui/deprecated/VirtualSelect/VirtualSelect';

class Department extends React.Component {
  getValue = async () => {
    const { value, addDepartment } = this.props;
    if (typeof value === 'string') {
      const res = await addDepartment({ name: value });
      return res.payload.department_id;
    }

    return value;
  };

  handleChange = ({ value }) => {
    this.props.onChange(value);
  };

  render() {
    return (
      <VirtualSelect
        label="Department"
        visibleItems={6}
        creatable
        clearInputOnDropdownOpen={false}
        value={this.props.value}
        onChange={this.handleChange}
        options={this.props.departmentOptions.map((o) => {
          const department = this.props.departments[o.value];
          return {
            ...o,
            icon: department.parent_id && <IconArrowDownRight size={16} />,
          };
        })}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: getUser(state),
  departments: getDepartments(state),
  departmentOptions: getDepartmentOptions(state),
});

const mapDispatchToProps = (dispatch) => ({
  addDepartment: (data) => dispatch(addDepartment(data)),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(Department);
