import { css } from 'styled-components';

import * as Colors from '../Colors';

export default css`
  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-track {
    border-width: 4px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0);
    border-radius: 999px;
    background-color: ${Colors.FIN.Lt.Surface.Surf2};
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-thumb {
    border-width: 4px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0);
    border-radius: 999px;
    background-color: ${Colors.FIN.Lt.Surface.Surf5};
    background-clip: padding-box;
  }
`;
