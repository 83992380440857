import React from 'react';

import DropdownSelect from '../DropdownSelect/DropdownSelect';
import IconTick from '../Icons/icon-tick';
import * as styled from './styles';

const SortOption = ({ label, dir }) => (
  <>
    <styled.Value>{label}</styled.Value>
    <styled.Label style={{ marginLeft: 6 }}>
      {dir === 'desc' ? (
        <span>Z&nbsp;&mdash;&nbsp;A</span>
      ) : (
        <span>A&nbsp;&mdash;&nbsp;Z</span>
      )}
    </styled.Label>
  </>
);

const getOptions = (value, columns) => {
  const selectedValue = `${value.type}-${value.dir || 'asc'}`;
  const options = [];
  const sortableColumns = columns.filter((c) => c.title);
  sortableColumns.forEach((c) => {
    ['asc', 'desc'].forEach((dir) => {
      options.push({
        label: <SortOption label={c.title} dir={dir} />,
        value: `${c.key}-${dir}`,
        iconRight: selectedValue === `${c.key}-${dir}` && <IconTick />,
      });
    });
  });
  return options;
};

const renderValue = (value, columns) => {
  const { type: sortBy, dir: sortDir } = value;
  const column = columns.find((c) => c.key === sortBy);
  if (!column) {
    return null;
  }
  return <SortOption label={column.title} dir={sortDir} />;
};

const Sort = ({ value, columns, onChange }) => {
  return (
    <DropdownSelect
      className="sort mobile"
      minSelectWidth={220}
      options={getOptions(value, columns)}
      onChange={onChange}
    >
      <styled.Action>
        <styled.ActionLink>
          <styled.Label>Sorted:</styled.Label>
          <styled.Value>{renderValue(value, columns)}</styled.Value>
        </styled.ActionLink>
      </styled.Action>
    </DropdownSelect>
  );
};

export default Sort;
