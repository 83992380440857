import { combineReducers } from 'redux';

import { ModalAction, ModalState, ModalType } from './types';

export const MODAL_MANAGER_SHOW = 'modalManager_SHOW';
export const MODAL_MANAGER_HIDE = 'modalManager_HIDE';
export const MODAL_MANAGER_UPDATE = 'modalManager_UPDATE';

const _defaultModalState: ModalState = { showing: false };

function makeModalManagerReducer(modalType: ModalType) {
  return {
    [modalType]: function modalManagerReducers(
      state = _defaultModalState,
      action: ModalAction,
    ) {
      if (action.modalType !== modalType) {
        return state;
      }

      switch (action.type) {
        case MODAL_MANAGER_SHOW: {
          const { modalSettings } = action;
          return {
            ...state,
            showing: true,
            modalSettings,
            skipSidePanelAutoClose: action.skipSidePanelAutoClose,
          };
        }
        case MODAL_MANAGER_HIDE: {
          return {
            ...state,
            showing: false,
            skipSidePanelAutoClose: false,
          };
        }
        case MODAL_MANAGER_UPDATE: {
          const { modalConfig: update } = action;
          return {
            ...state,
            showing: update.visible ?? state.showing,
            modalSettings:
              update.modalSettings && state.modalSettings
                ? {
                    ...state.modalSettings,
                    ...update.modalSettings,
                  }
                : state.modalSettings,
            skipSidePanelAutoClose:
              update.skipSidePanelAutoClose ?? state.skipSidePanelAutoClose,
          };
        }
        default: {
          return state;
        }
      }
    },
  };
}

const reducers = [
  'projectModal',
  'phaseModal',
  'projectShiftModal',
  'personModal',
  'switchPersonModal',
  'taskModal',
  'errorModal',
  'ConfirmModal',
  'myInfoModal',
  'notificationSettingsModal',
  'tourModal',
  'noSessionModal',
  'todayButton',
  'upgradePlanModal',
  'introModal',
  'deleteBookmarkModal',
  'shareModal',
  'notificationsModal',
  'sortScheduleModal',
  'titleTipModal',
  'scheduleCSVExportModal',
  'updateRepeatingModal',
  'deleteMultiAssignModal',
  'emailNotificationsModal',
  'emailScheduleModal',
  'guestAccountModal',
  'shortcutModal',
  'milestoneModal',
  'logTimeModal',
  'integCalendarEditModal',
  'calendarIntegrationsModal',
  'shareLinkModal',
  'integrationCalendarDisconnectedModal',
  'marOptinModal',
  'marOptinModalMigrating',
  // Modals migrated to @ui/primitives/Modal
  'ModalSwitchPerson',
  'ModalProjectFromTemplate',
  'ModalCreateDraftProject',
].reduce((accum, reducerKey) => {
  return {
    ...accum,
    ...makeModalManagerReducer(reducerKey as ModalType),
  };
}, {});

export default combineReducers(reducers);
