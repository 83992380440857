import React, { useCallback, useEffect, useRef, useState } from 'react';
import { cloneDeep, find, findLast, isEqual } from 'lodash';

import { moment } from '@float/libs/moment';
import {
  DatePicker,
  FieldLabel,
  Popover,
  VirtualSelect,
} from '@float/ui/deprecated';

import WorkDay from './WorkDay';
import {
  DescriptionLine,
  HistoryLine,
  List,
  PointInTimeApplyToWrapper,
  PointInTimeDateWrapper,
  Wrapper,
} from './WorkDays.styled';
import HistoryTooltip from './WorkDaysTooltip';

const APPLY_TO_OPTIONS = [
  { label: 'All past and future days', value: 0 },
  { label: 'A specific date onward', value: 1 },
];

const WORK_DAYS = [
  { label: 'Sunday' },
  { label: 'Monday' },
  { label: 'Tuesday' },
  { label: 'Wednesday' },
  { label: 'Thursday' },
  { label: 'Friday' },
  { label: 'Saturday' },
];

function isNotStartOfWeek(firstDay, date) {
  return date.day() != firstDay;
}

export default ({
  value,
  onChange,
  firstDay = 0,
  width,
  hideApplyTo,
  style,
  hoursStep,
}) => {
  // This variable represents the initial state of the form with any deletes
  // processed by pressing the X in the history popup.
  const baseValue = useRef(value);
  const removedHistories = useRef([]);

  // We want to store the initial days (whether it applies to all or if it's
  // from history) so that we can track the dirty state of the form.
  const initialDays = useRef(undefined);
  if (typeof initialDays.current === 'undefined') {
    if (value.allDays) {
      initialDays.current = value.allDays;
    } else {
      const thisWeekStart = moment().startOf('week').format('YYYY-MM-DD');
      const curHistory = value.history.find((k) => thisWeekStart >= k[0]);
      initialDays.current = curHistory[1];
    }
  }

  const [dayHours, setDayHours] = useState(initialDays.current);
  const [applyToOption, setApplyToOption] = useState(0);
  const [applyToDate, setApplyToDate] = useState(null);
  const [focusedMap, setFocusedMap] = useState({});

  const handleDayFocus = useCallback(
    (day) => {
      setFocusedMap({
        ...focusedMap,
        [day]: true,
      });
    },
    [focusedMap],
  );

  const handleDayBlur = useCallback(
    (day) => {
      setFocusedMap({
        ...focusedMap,
        [day]: false,
      });
    },
    [focusedMap],
  );

  const dirty = !isEqual(initialDays.current, dayHours);

  let hasNonWorkDayBeenAdded = dayHours.some(
    (h, idx) => h == 0 && initialDays.current[idx] > 0,
  );

  if (removedHistories.current.length && !hasNonWorkDayBeenAdded) {
    removedHistories.current.forEach(([date, history]) => {
      const newHistory = baseValue.current.history.find((k) => date >= k[0]);
      if (newHistory[1].some((h, idx) => h == 0 && history[idx] > 0)) {
        hasNonWorkDayBeenAdded = true;
      }
    });
  }

  baseValue.current.hasNonWorkDayBeenAdded = hasNonWorkDayBeenAdded;

  useEffect(() => {
    if (!dirty) {
      onChange(baseValue.current);
      return;
    }

    if (applyToOption == 0) {
      onChange({
        allDays: dayHours,
        history: null,
        hasNonWorkDayBeenAdded: baseValue.current.hasNonWorkDayBeenAdded,
      });
      return;
    }

    // The new history is based on the current form value + the previous
    // histories - any deleted entries.
    let newHistory = cloneDeep(baseValue.current.history);
    if (!newHistory) {
      newHistory = [['1970-01-01', initialDays.current]];
    }

    const date = applyToDate.format('YYYY-MM-DD');
    const dateIdx = newHistory.findIndex((h) => h[0] == date);
    if (dateIdx > -1) {
      newHistory[dateIdx][1] = dayHours;
    } else {
      newHistory.push([date, dayHours]);
      newHistory.sort().reverse();
    }

    if (!isEqual(value.history, newHistory)) {
      onChange({
        allDays: null,
        history: newHistory,
        hasNonWorkDayBeenAdded: baseValue.current.hasNonWorkDayBeenAdded,
      });
    }
  }, [applyToOption, applyToDate, dayHours, value.history]); // eslint-disable-line

  const onToggleDay = useCallback(
    (dayIndex) => {
      handleDayBlur(dayIndex);
      setDayHours((prev) => {
        const newArr = [...prev];
        const val = prev[dayIndex] > 0 ? 0 : 8;
        newArr.splice(dayIndex, 1, val);
        return newArr;
      });
    },
    [handleDayBlur],
  );

  const showHistory =
    !value.allDays && value.history && value.history.length > 0;
  let descriptionLine;
  if (showHistory) {
    const fmt = (date, daysToSubtract = 0) =>
      moment(date).subtract(daysToSubtract, 'day').format('MMM DD, YYYY');
    const dateForDescription = moment(applyToDate || moment()).format(
      'YYYY-MM-DD',
    );

    const prevHistory = find(
      value.history,
      (h) => dateForDescription >= h[0] && h[0] !== '1970-01-01',
    );
    const nextHistory = findLast(
      value.history,
      (h) => dateForDescription < h[0],
    );

    if (prevHistory && nextHistory) {
      descriptionLine = `from ${fmt(prevHistory[0])} to ${fmt(
        nextHistory[0],
        1,
      )}`;
    } else if (prevHistory) {
      descriptionLine = `from ${fmt(prevHistory[0])}`;
    } else if (nextHistory) {
      descriptionLine = `to ${fmt(nextHistory[0], 1)}`;
    }
  }

  const removeEntry = useCallback(
    (dateToRemove) => {
      const idx = baseValue.current.history.findIndex(
        (h) => h[0] === dateToRemove,
      );

      if (idx == -1) {
        return;
      }

      const removedHistory = baseValue.current.history.splice(idx, 1);
      removedHistories.current.push(removedHistory[0]);

      // We need to create a new object to trigger a React render
      baseValue.current = cloneDeep(baseValue.current);

      if (baseValue.current.history?.length == 1) {
        baseValue.current.allDays = baseValue.current.history[0][1];
      }

      onChange(baseValue.current);
    },
    [onChange],
  );

  return (
    <Wrapper wrapperWidth={width} style={style}>
      <FieldLabel>
        Work days
        {showHistory && (
          <>
            : <DescriptionLine>{descriptionLine}</DescriptionLine>
          </>
        )}
      </FieldLabel>
      <List>
        {WORK_DAYS.map((day, i) => (
          <WorkDay
            key={i}
            index={i}
            onChangeHours={(val) => {
              setDayHours((prev) => {
                const newArr = [...prev];
                newArr.splice(i, 1, val);
                return newArr;
              });
            }}
            onToggleDay={() => onToggleDay(i)}
            isFirst={i === 0}
            label={day.label}
            hours={dayHours[i]}
            hoursStep={hoursStep}
            isSelected={dayHours[i] !== 0 || focusedMap[i]}
            onFocus={() => {
              handleDayFocus(i);
            }}
            onBlur={() => {
              handleDayBlur(i);
            }}
          />
        ))}
      </List>
      {showHistory && (
        <HistoryLine>
          <Popover
            placement="top-end"
            maxWidth={530}
            content={
              <HistoryTooltip
                history={value.history}
                removeEntry={removeEntry}
                currentApplyToDate={
                  applyToDate && applyToDate.format('YYYY-MM-DD')
                }
              />
            }
          >
            <div className="view-history">View changes</div>
          </Popover>
        </HistoryLine>
      )}
      {dirty && !hideApplyTo && (
        <>
          <PointInTimeApplyToWrapper>
            <VirtualSelect
              label="Apply to"
              visibleItems={6}
              nonNullable
              onChange={(val) => {
                setApplyToOption(val.value);
                setApplyToDate(
                  val.value === 1 ? moment().startOf('week') : null,
                );
              }}
              options={APPLY_TO_OPTIONS}
              value={applyToOption}
            />
          </PointInTimeApplyToWrapper>
          {applyToOption === 1 && (
            <PointInTimeDateWrapper>
              <DatePicker
                label="From"
                value={moment(applyToDate)}
                onChange={(date) => {
                  if (isNotStartOfWeek(firstDay, date)) {
                    date = date.startOf('week');
                  }

                  setApplyToDate(date);
                }}
                disableDayFn={(date) => isNotStartOfWeek(firstDay, date)}
                firstOfWeek={firstDay}
              />
            </PointInTimeDateWrapper>
          )}
        </>
      )}
    </Wrapper>
  );
};
