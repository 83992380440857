import { groupBy } from 'lodash';

import { getJWTAccessToken } from '@float/common/actions/jwt';

import api from '../../integrations/api';
import { loadedActionTypes } from '../constants';
import { integrationTypes } from '../integrationTypes';
import {
  getActivePmIntegration,
  getPmSidebarVisibility,
} from '../reducers/selectors';
import { FILTER_TYPES, LOAD_STATE } from './constants';

export * from './constants';

export const TOGGLE_PM_SIDEBAR = 'TOGGLE_PM_SIDEBAR';
export const CHANGE_SORT = 'PM_SIDEBAR/CHANGE_SORT';
export const CHANGE_FILTER = 'PM_SIDEBAR/CHANGE_FILTER';
export const TOGGLE_GROUP = 'PM_SIDEBAR/TOGGLE_GROUP';
export const INTEGRATIONS_LOAD_BEGIN = 'PM_SIDEBAR/INTEGRATIONS_LOAD_BEGIN';
export const INTEGRATIONS_LOAD_SUCCESS = 'PM_SIDEBAR/INTEGRATIONS_LOAD_SUCCESS';
export const INTEGRATIONS_LOAD_FAILURE = 'PM_SIDEBAR/INTEGRATIONS_LOAD_FAILURE';
export const ITEMS_LOAD_BEGIN = 'PM_SIDEBAR/ITEMS_LOAD_BEGIN';
export const ITEMS_LOAD_SUCCESS = 'PM_SIDEBAR/ITEMS_LOAD_SUCCESS';
export const ITEMS_LOAD_FAILURE = 'PM_SIDEBAR/ITEMS_LOAD_FAILURE';
export const ITEMS_LIVE_UPDATE = 'PM_SIDEBAR/ITEMS_LIVE_UPDATE';
export const TASK_LINKS_LOAD_BEGIN = 'PM_SIDEBAR/TASK_LINKS_LOAD_BEGIN';
export const TASK_LINKS_LOAD_SUCCESS = 'PM_SIDEBAR/TASK_LINKS_LOAD_SUCCESS';
export const TASK_LINKS_LOAD_FAILURE = 'PM_SIDEBAR/TASK_LINKS_LOAD_FAILURE';
export const TASK_LINKS_LIVE_UPDATE = 'PM_SIDEBAR/TASK_LINKS_LIVE_UPDATE';

export const togglePmSidebar = (visible) => {
  return {
    type: TOGGLE_PM_SIDEBAR,
    visible,
  };
};

export const changeSort = (sortBy, order) => ({
  type: CHANGE_SORT,
  sortBy,
  order,
});

export const changeFilter = ({
  filterType = FILTER_TYPES.SEARCH,
  key,
  value,
}) => ({
  type: CHANGE_FILTER,
  filterType,
  key,
  value,
});

export const groupToggle = (value) => ({
  type: TOGGLE_GROUP,
  value,
});

export const fetchSidebarData = () => async (dispatch, getState) => {
  const fullState = getState();
  const { loadState, sortBy } = fullState.pmSidebar;

  const { coIntId, type } = getActivePmIntegration(fullState);
  if (!coIntId || [LOAD_STATE.LOADING].includes(loadState)) {
    return;
  }

  const { isSidebarOpen } = getPmSidebarVisibility(fullState);
  if (!isSidebarOpen) {
    return;
  }

  dispatch({ type: ITEMS_LOAD_BEGIN });

  // init sortBy if first time or not available in sort options
  const { defaultSort, sortOptions = [] } = integrationTypes[type] || {};
  if (
    defaultSort &&
    (!sortBy || !sortOptions.find((item) => item.value === sortBy))
  ) {
    const { value, order } = defaultSort;
    dispatch(changeSort(value, order));
  }

  try {
    const accessToken = await dispatch(getJWTAccessToken());
    const response = await api(accessToken).getSidebarData({ coIntId });
    dispatch({
      type: ITEMS_LOAD_SUCCESS,
      response,
      fullState,
    });
  } catch (err) {
    console.log('Error fetching sidebar data', err);
    dispatch({ type: ITEMS_LOAD_FAILURE });
  }
};

export const fetchTaskLinks = (coIntId) => async (dispatch, getState) => {
  const { taskLinksLoadState: loadState } = getState().pmSidebar;
  if (!coIntId || [LOAD_STATE.LOADING, LOAD_STATE.LOADED].includes(loadState)) {
    return;
  }

  dispatch({ type: TASK_LINKS_LOAD_BEGIN });

  try {
    const accessToken = await dispatch(getJWTAccessToken());
    const response = await api(accessToken).getTaskLinks(coIntId);
    dispatch({ type: TASK_LINKS_LOAD_SUCCESS, response });
  } catch (err) {
    console.log('Error fetching PM task links', err);
    dispatch({ type: TASK_LINKS_LOAD_FAILURE });
  }
};

export const fetchAllIntegrations = ({ getLinks = false } = {}) => {
  return async (dispatch, getState) => {
    try {
      if (getState()?.currentUser?.shared_link_view) {
        return null;
      }
      const { integrationsLoadState: loadState } = getState().pmSidebar;
      if (loadState === LOAD_STATE.LOADING) {
        return;
      }

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: INTEGRATIONS_LOAD_BEGIN });
      const allCoInts = await api(accessToken).requestCoInts();
      dispatch({ type: INTEGRATIONS_LOAD_SUCCESS });

      const coIntsByType = groupBy(allCoInts, 'type');
      Object.entries(coIntsByType).forEach(([key, coInts]) => {
        const type = loadedActionTypes[key];
        if (coInts.length) {
          dispatch({ type, coInts });

          if (getLinks) {
            const coIntId = coInts.length && coInts[0].integrations_co_id;
            if (coIntId) {
              dispatch(fetchTaskLinks(coIntId));
            }
          }
        }
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: INTEGRATIONS_LOAD_FAILURE });
    }
  };
};
