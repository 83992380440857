import { createSelector } from 'reselect';

import {
  getUser,
  selectDatesManager,
} from '@float/common/selectors/currentUser';
import { getPeopleMapRaw } from '@float/common/selectors/people';
import { CurrentUser } from '@float/types/account';
import { Person } from '@float/types/person';

import { getWorkHours } from './getWorkHours';

export type MinWorkHoursInRangeParams = {
  start_date: string;
  end_date: string;
  people: Person[];
  dates: DatesManager;
  user: CurrentUser;
};

function getMinWorkHoursInRange({
  start_date,
  end_date,
  people,
  dates,
  user,
}: MinWorkHoursInRangeParams) {
  let minWorkHours = Number.POSITIVE_INFINITY;

  if (!people) return minWorkHours;

  const start = dates.toNum(start_date);
  const end = dates.toNum(end_date);

  for (const person of people) {
    for (let i = start; i <= end; i++) {
      const workHours = getWorkHours(dates, user, person, dates.fromNum(i));

      if (workHours && workHours < minWorkHours) {
        minWorkHours = workHours;
      }
    }
  }

  return minWorkHours;
}

export const selectMinWorkHoursInRangeGetter = createSelector(
  [getPeopleMapRaw, getUser, selectDatesManager],
  (peopleMap, user, dates) =>
    (peopleIds: number[], startDate: string, endDate: string) => {
      const people = [];

      for (const id of peopleIds) {
        const person = peopleMap[id];

        if (person) people.push(person);
      }

      return getMinWorkHoursInRange({
        end_date: endDate,
        start_date: startDate,
        dates,
        people,
        user,
      });
    },
);
