import React from 'react';

export default function (props) {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.7826 35.4493C46.7826 36.3974 46.4102 37.3067 45.7473 37.9771C45.0844 38.6475 44.1853 39.0242 43.2478 39.0242H19.0696L12 46.1739V17.5749C12 16.6268 12.3724 15.7175 13.0353 15.0471C13.6982 14.3766 14.5973 14 15.5348 14H43.2478C44.1853 14 45.0844 14.3766 45.7473 15.0471C46.4102 15.7175 46.7826 16.6268 46.7826 17.5749V35.4493Z"
        fill="#E1F0FF"
        stroke="#2E5FE8"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.913 43.3684C25.913 44.3083 26.2715 45.2097 26.9097 45.8743C27.5478 46.5389 28.4133 46.9123 29.3157 46.9123H45.1947L52 54V22.6957"
        stroke="#344765"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.1875 26.7328C22.1875 27.7419 21.3694 28.56 20.3603 28.56C19.3512 28.56 18.5331 27.7419 18.5331 26.7328C18.5331 25.7237 19.3512 24.9056 20.3603 24.9056C21.3694 24.9056 22.1875 25.7237 22.1875 26.7328Z"
        stroke="#2E5FE8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.7951 26.7328C30.7951 27.7419 29.977 28.56 28.9679 28.56C27.9588 28.56 27.1407 27.7419 27.1407 26.7328C27.1407 25.7237 27.9588 24.9056 28.9679 24.9056C29.977 24.9056 30.7951 25.7237 30.7951 26.7328Z"
        stroke="#2E5FE8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.4027 26.7328C39.4027 27.7419 38.5847 28.56 37.5756 28.56C36.5665 28.56 35.7484 27.7419 35.7484 26.7328C35.7484 25.7237 36.5665 24.9056 37.5756 24.9056C38.5847 24.9056 39.4027 25.7237 39.4027 26.7328Z"
        stroke="#2E5FE8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
