import { forEach, map } from 'lodash';
import {
  filterLinkFormatter,
  rangeBarFormatter,
} from 'reports/helpers/tableFormatters';

import { formatAmount } from '@float/common/lib/budget/helpers/formatAmount';
import { canSeeTimeoffBalance } from '@float/common/lib/rights';

import { getBalanceRangeBar } from '../table.helpers';
import { getPctValue } from './_percentages';
import { TeamCapacityTableContext, TeamCapacityTableDataRaw } from './types';

type BreakdonwItem = {
  days: number;
  hours: number;
  balance: number;
  hideBalance: boolean;
  children: Record<number, Omit<BreakdonwItem, 'children'>>;
};

function breakdown(
  ctx: TeamCapacityTableContext,
  raw: TeamCapacityTableDataRaw,
) {
  const { isSinglePersonView, timeoffBalance } = ctx;
  const byTimeoff: Record<string, BreakdonwItem> = {};
  const byHoliday: Record<string, BreakdonwItem> = {};

  const balanceInitedForPerson: Record<string, boolean> = {};
  raw.timeoff.forEach((item) => {
    const container = item.holiday ? byHoliday : byTimeoff;
    const balanceContainer = timeoffBalance[item.name];

    if (!container[item.name]) {
      container[item.name] = {
        days: 0,
        hours: 0,
        balance: 0,
        hideBalance: false,
        children: {},
      };
    }

    const record = container[item.name];

    // We want to aggregate the children by person in multi-person mode. There
    // is no aggregation in single-person mode
    forEach(item.people, (val, key) => {
      const personId = parseInt(key);
      const shouldShowBalance = canSeeTimeoffBalance(
        ctx.people[personId],
        ctx.user,
      );
      const personBalance =
        balanceContainer?.peopleMap?.[personId]?.eligibleDays ?? 0;
      record.days += val.days;
      record.hours += val.hours;
      if (shouldShowBalance) {
        // TODO: Remove this workaround once report-api will return total balance w/o subtracted days
        const id = `${item.name}_${personId}`;
        if (!balanceInitedForPerson[id]) {
          record.balance += personBalance;
          balanceInitedForPerson[id] = true;
        }
      } else {
        record.hideBalance = true;
      }

      if (!isSinglePersonView) {
        if (!record.children[personId]) {
          record.children[personId] = {
            days: 0,
            hours: 0,
            balance: 0,
            hideBalance: true,
          };
        }
        record.children[personId].days += val.days;
        record.children[personId].hours += val.hours;
        if (shouldShowBalance) {
          record.children[personId].hideBalance = false;
          if (!record.children[personId].balance) {
            record.children[personId].balance = personBalance;
          }
        }
      }
    });
  });

  return { byTimeoff, byHoliday };
}

function getTimeoffsTable(
  ctx: TeamCapacityTableContext,
  raw: TeamCapacityTableDataRaw,
) {
  const { people, timeoffTypes } = ctx;

  const headers = [
    {
      label: 'Time off',
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    { label: 'Balance', width: 100 },
    { label: 'Days', width: 100 },
    { label: 'Hours', width: 100 },
    { label: '%', width: 100 },
    {
      label: '',
      width: 135,
      formatter: rangeBarFormatter,
      allowOverflow: true,
    },
  ];

  if (!raw) return { headers, rows: [] };
  const { byTimeoff } = breakdown(ctx, raw);

  const rows = map(byTimeoff, (o, holidayName) => {
    const timeOffType = timeoffTypes.find(
      (tt) => tt.timeoff_type_name === holidayName,
    );
    const withBalance = Boolean(timeOffType && timeOffType.balance_type > 0);

    return {
      id: holidayName,
      data: [
        holidayName || 'No name',
        withBalance && !o.hideBalance ? o.balance : '',
        formatAmount(null, o.days),
        o.hours,
        withBalance && !o.hideBalance ? getPctValue(ctx, o, 'balance') : '',
        withBalance && !o.hideBalance ? getBalanceRangeBar(o) : '',
      ],
      children: map(o.children, (c, key) => {
        const personId = parseInt(key);

        return {
          data: [
            people[personId].name,
            withBalance && !c.hideBalance ? c.balance : '',
            formatAmount(null, c.days),
            c.hours,
            withBalance && !c.hideBalance ? getPctValue(ctx, c, 'balance') : '',
            '',
          ],
        };
      }),
    };
  });

  return { headers, rows };
}

function getHolidaysTable(
  ctx: TeamCapacityTableContext,
  raw: TeamCapacityTableDataRaw,
) {
  const { people } = ctx;

  const headers = [
    {
      label: 'Holidays',
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    { label: 'Days', width: 100 },
    { label: 'Hours', width: 100 },
  ];

  if (!raw) return { headers, rows: [] };
  const { byHoliday } = breakdown(ctx, raw);

  const rows = map(byHoliday, (o, timeoffName) => {
    return {
      id: timeoffName,
      data: [
        timeoffName || 'No name',
        { sortVal: o.days, val: formatAmount(null, o.days) },
        o.hours,
      ],
      children: map(o.children, (c, key) => {
        const personId = parseInt(key);

        return {
          data: [
            people[personId]?.name,
            { sortVal: c.days, val: formatAmount(null, c.days) },
            c.hours,
          ],
        };
      }),
    };
  });

  return { headers, rows };
}

export function getTables(
  ctx: TeamCapacityTableContext,
  raw: TeamCapacityTableDataRaw,
) {
  return [getHolidaysTable(ctx, raw), getTimeoffsTable(ctx, raw)];
}
