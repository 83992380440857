import {
  CooldownCycleConfig,
  CycleVariant,
  StandardCycleConfig,
} from '../Cycles.types';

export const getIsCooldownCycle = (
  cycleConfig: StandardCycleConfig | CooldownCycleConfig,
): cycleConfig is CooldownCycleConfig => {
  return cycleConfig.variant === CycleVariant.Cooldown;
};

export const getIsStandardCycle = (
  cycleConfig: StandardCycleConfig | CooldownCycleConfig,
): cycleConfig is StandardCycleConfig => {
  return !getIsCooldownCycle(cycleConfig);
};
