import React, { ComponentProps, memo } from 'react';
import cn from 'classnames';
import { AnimatePresence, motion, MotionProps } from 'framer-motion';

import { SkeletonRectangle } from '@float/ui/components/Skeleton';

import * as styles from './styles.css';

type Props = {
  description?: string;
  isLoading?: boolean;
} & Pick<ComponentProps<'div'>, 'className'>;

const transition: MotionProps = {
  animate: { opacity: 1, transition: { duration: 0.15 } },
  exit: { opacity: 0 },
  initial: { opacity: 0 },
};

export const VirtualSelectOptionDescription = memo((props: Props) => {
  const { description, isLoading = false, className } = props;

  return (
    <div className={cn(className, styles.optionDescription)}>
      <AnimatePresence initial={false} mode="popLayout">
        {description && !isLoading && (
          <motion.div
            {...transition}
            className={styles.optionDescriptionText}
            key="description"
          >
            <span>{description}</span>
          </motion.div>
        )}

        {isLoading && (
          <motion.div
            {...transition}
            className={styles.textSkeleton}
            key="skeleton"
          >
            <SkeletonRectangle className={styles.skeleton} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
});
