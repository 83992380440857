import React from 'react';

export default function ({ style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={style}
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="12" cy="12" r="12" fill="#00AC00" />
        <path fill="#FFF" d="M10 17.95L5 13l2-2 3 3 7-7 2 2z" />
      </g>
    </svg>
  );
}
