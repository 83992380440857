import datesCreator from '@float/libs/dates';

import { buildMilestones, buildWeekHeader, isFilteredOut } from './helpers';
import { ProjectBreakdownPayload, ProjectsBreakdownData } from './types';

export function breakdownProjectsByWeek(payload: ProjectBreakdownPayload) {
  const dates = datesCreator(payload.serializedDates);
  const [startCol] = dates.toDescriptor(payload.startDate);
  const [endCol] = dates.toDescriptor(payload.endDate);
  dates.ensureRange(startCol, endCol);

  const { rows, cells, phases } = payload;

  function buildScheduleData() {
    const data: ProjectsBreakdownData = {
      projectOrder: [],
      projects: {},
    };

    for (const row of rows) {
      if (row.type === 'project') {
        const project = row.data;

        data.projectOrder.push(project.project_id);

        data.projects[project.project_id] = {
          id: project.project_id,
          name: project.project_name,
          client:
            project.client_name === 'No Client' ? '' : project.client_name,
          peopleOrder: [],
          people: {},
          totals: {},
        };
      } else if ('projectId' in row) {
        const project = data.projects[row.projectId];
        const person = row.data;
        project.peopleOrder.push(person.people_id);
        project.people[person.people_id] = {
          id: person.people_id,
          name: person.name,
          jobTitle: person.job_title,
          department: (person.department && person.department.name) || '',
          tasks: {},
        };
      }
    }

    Object.values(cells).forEach((cell) => {
      if (
        typeof cell !== 'object' ||
        !('rowId' in cell) ||
        typeof cell.rowId !== 'string'
      ) {
        return;
      }

      if (!cell.rowId.startsWith('person')) return;
      if (!cell.items || !cell.items.length) return;

      const personId = cell.rowId.substring('person-'.length);
      const cellDate = dates.fromDescriptor(cell.colIdx, 0);

      cell.items.forEach((item) => {
        if (item.type !== 'task') return;
        if (isFilteredOut(payload, item)) return;

        const project = data.projects[item.entity.project_id];
        if (!project) return;

        const person = project.people[personId];
        if (!person) return;

        const phaseId = item.entity.phase_id || '';
        const key = `${item.entity.name}:${item.entity.notes || ''}:${phaseId}`;
        if (!person.tasks[key]) {
          person.tasks[key] = {
            phase: (phaseId && phases[phaseId]?.phase_name) || 'No phase',
            name: item.entity.name,
            notes: item.entity.notes,
            dateData: {},
          };
        }

        if (!person.tasks[key].dateData[cellDate]) {
          person.tasks[key].dateData[cellDate] = 0;
        }

        const hours = item.w * item.entity.hours;
        person.tasks[key].dateData[cellDate] += hours;

        if (!project.totals[cellDate]) {
          project.totals[cellDate] = 0;
        }

        project.totals[cellDate] += hours;
      });
    });

    return data;
  }

  // ---------------------------------------------------------------------------

  const data = buildScheduleData();
  const result = {
    header: buildWeekHeader(startCol, endCol, dates, payload),
    scheduleData: data,
    milestones: buildMilestones(cells, startCol, endCol, data),
  };

  return result;
}
