import styled from 'styled-components';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';

export const Text = styled.span`
  ${({ emphasis }: { emphasis: 'low' | 'medium' | 'high' }) => {
    let style = '';

    switch (emphasis) {
      case 'low':
        style += `color: ${Colors.FIN.Lt.Emphasis.Low};\n`;
        break;
      case 'medium':
        style += `color: ${Colors.FIN.Lt.Emphasis.Medium};\n`;
        break;
      case 'high':
        style += `color: ${Colors.FIN.Lt.Emphasis.High};\n`;
        break;
    }

    return style;
  }}
`;

export const createTextColorCSS = (color: string) => {
  return `
    color: ${color};
    svg {
      --svg-icon-color: ${color};

      .stroke {
        stroke: ${color};
      }

      .fill {
        fill: ${color};
      }
    }
  `;
};
