import { BudgetPhase, BudgetProject, BudgetTask } from '@float/types/budget';

import {
  BUDGETS_LOAD_FAILED,
  BUDGETS_LOAD_FINISH,
  BUDGETS_LOAD_START,
  BudgetsAction,
  BudgetUsage,
} from './budgets.actions';

export type BudgetsState = {
  tasks: Record<number, BudgetTask>;
  phases: Record<number, BudgetPhase>;
  projects: Record<number, BudgetProject>;
  loadState: 'UNLOADED' | 'LOADED' | 'LOAD_FAILED' | 'LOADING';
};

export const DEFAULT_STATE: BudgetsState = {
  tasks: {},
  phases: {},
  projects: {},
  loadState: 'UNLOADED',
};

/**
 * Converts budget usage data from string to numbers
 * is executed on data load for performance reasons
 */
type BudgetUsageItemKey = 'task_meta_id' | 'phase_id' | 'project_id';

function parseBudgetUsage<K extends BudgetUsageItemKey>(
  budgetData: BudgetUsage<string> & { [key in K]: number },
  key: K,
) {
  const result = {
    [key]: budgetData[key],
    budget_total: budgetData.budget_total
      ? parseFloat(budgetData.budget_total)
      : null,
    budget_used: budgetData.budget_used
      ? parseFloat(budgetData.budget_used)
      : null,
    budget_logged: budgetData.budget_logged
      ? parseFloat(budgetData.budget_logged)
      : null,
    budget_logged_remaining: budgetData.budget_logged_remaining
      ? parseFloat(budgetData.budget_logged_remaining)
      : null,
    budget_remaining: budgetData.budget_remaining
      ? parseFloat(budgetData.budget_remaining)
      : null,
  } as BudgetUsage<number | null> & { [key in K]: number };

  return result;
}

export const budgetsReducer = (
  state = DEFAULT_STATE,
  action: BudgetsAction,
): BudgetsState => {
  switch (action.type) {
    case BUDGETS_LOAD_START: {
      return {
        ...state,
        loadState: 'LOADING',
      };
    }
    case BUDGETS_LOAD_FAILED: {
      return {
        ...state,
        loadState: 'LOAD_FAILED',
      };
    }
    case BUDGETS_LOAD_FINISH: {
      const budgets = action.budgets;

      if (!budgets || !budgets.length) return state;

      const tasksById: BudgetsState['tasks'] = {};
      const phasesById: BudgetsState['phases'] = {};
      const projectsById: BudgetsState['projects'] = {};

      budgets.forEach((project) => {
        const projectBudgetUsage = parseBudgetUsage(project, 'project_id');

        projectsById[project.project_id] = projectBudgetUsage;

        project?.tasks?.forEach((task) => {
          tasksById[task.task_meta_id] = parseBudgetUsage(task, 'task_meta_id');
        });

        project?.phases?.forEach((phase) => {
          phasesById[phase.phase_id] = parseBudgetUsage(phase, 'phase_id');

          phase?.tasks?.forEach((task) => {
            tasksById[task.task_meta_id] = parseBudgetUsage(
              task,
              'task_meta_id',
            );
          });
        });
      });

      return {
        ...state,
        tasks: {
          ...state.tasks,
          ...tasksById,
        },
        phases: {
          ...state.phases,
          ...phasesById,
        },
        projects: {
          ...state.projects,
          ...projectsById,
        },
        loadState: 'LOADED',
      };
    }
    default: {
      return state;
    }
  }
};
