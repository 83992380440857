import React from 'react';
import { connect } from 'react-redux';
import { compact } from 'lodash';
import styled from 'styled-components';

import { Rights } from '@float/common/lib/rights';
import { getUser } from '@float/common/selectors/currentUser';
import { MetaSymbol, Modal } from '@float/ui/deprecated';
import modalManagerHoc from '@float/web/modalManager/modalManagerHoc';

const textOnly = ['Drag', 'Click'];

const Key = styled.div`
  text-align: center;
  padding: 2px ${({ extraPadding }) => (extraPadding ? '8px' : '5px')};
  min-width: 26px;
  margin-left: 2px;
  height: 24px;
  font-size: 14px;
  border-radius: 4px;
  box-shadow: ${({ theme }) => `0 1px 0 0 ${theme.medGrey}`};
  background-color: ${({ theme }) => theme.grey};
`;

const Plus = styled(Key)`
  background-color: white;
  box-shadow: none;
  font-weight: bold;
  min-width: 16px;
`;

const Column = styled.div`
  margin-top: 20px;
  width: 30%;
`;

const ColumnHeader = styled.h3`
  margin-bottom: 15px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const KeyLabel = styled.div``;

const KeyImage = styled.div`
  display: flex;
`;

function getRows(rows) {
  return compact(rows).map(([label, keyText, otherKeys], i) => {
    return (
      <Row key={`${keyText}-${i}`}>
        <KeyLabel> {label} </KeyLabel>
        <KeyImage>
          {' '}
          <Key extraPadding={keyText.length > 1}> {keyText} </Key>
          {otherKeys &&
            otherKeys.map((keyText, j) => {
              const key = `${keyText}-${j}`;
              return keyText === '+' ? (
                <Plus key={key}>+</Plus>
              ) : textOnly.includes(keyText) ? (
                <div key={key}>{keyText}</div>
              ) : (
                <Key key={key} extraPadding={keyText.length > 1}>
                  {' '}
                  {keyText}{' '}
                </Key>
              );
            })}
        </KeyImage>
      </Row>
    );
  });
}

function KeyboardShortcutModal(props) {
  return (
    <Modal
      width={770}
      marginTop={150}
      isOpen
      closeIconColor="midGrey"
      onClose={() => {
        props.manageModal({
          visible: false,
          modalType: 'shortcutModal',
        });
      }}
    >
      <div style={{ padding: '20px 30px', fontSize: '16px' }}>
        <h2>Keyboard shortcuts</h2>
        <Row>
          <Column>
            <ColumnHeader> General </ColumnHeader>
            {getRows([
              ['Show shortcuts', '?'],
              ['Close pop-ups', 'Esc'],
              ['Undo', MetaSymbol, ['+', 'Z']],
              ['Search', 'F'],
              ['Me', 'M'],
              ['Mentions', '@'],
            ])}
          </Column>
          <Column>
            <ColumnHeader> Schedule </ColumnHeader>
            {getRows([
              ['Add / Edit', 'A'],
              ['Split', 'S'],
              ['Link', 'L'],
              ['Complete', 'C'],
              ['Log', 'O'],
              ['Delete', 'D'],
              ['Duplicate', 'Shift', ['+', 'Drag']],
              ['Multi-select', 'Shift', ['+', 'Click']],
              ['Go to today', '/'],
              ['Navigate', '←', ['→', '↑', '↓']],
            ])}
          </Column>
          <Column>
            <ColumnHeader> Add </ColumnHeader>
            {getRows([
              Rights.canCreateTask(props.user) && ['Allocate time', 'T'],
              Rights.canCreateProject(props.user) && ['Log time', 'G'],
              Rights.canCreateTimeoff(props.user) && ['Add time off', 'I'],
              Rights.canCreateLoggedtime(props.user) && ['Add person', 'E'],
              Rights.canCreatePeople(props.user) && ['Add project', 'P'],
            ])}
          </Column>
        </Row>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    user: getUser(state),
  };
};

const Comp = connect(mapStateToProps)(KeyboardShortcutModal);

export default modalManagerHoc({
  Comp,
  modalType: 'shortcutModal',
});
