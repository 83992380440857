import React from 'react';
import { t, Trans } from '@lingui/macro';

import { TextToggle } from '@float/ui/deprecated/Earhart/Toggles';

import * as styles from './EventsTimeZoneSelector.css';

export interface EventsTimeZoneSelectorProps {
  value: boolean;
  onChange: (value: boolean) => void;
}

const getOptions = () => [
  { value: false, label: t`Team setting` },
  { value: true, label: t`My calendar` },
];

export const EventsTimeZoneSelector = (props: EventsTimeZoneSelectorProps) => {
  const { value, onChange } = props;

  return (
    <div className={styles.main}>
      <label className={styles.label}>
        <Trans>Show events in time zone from</Trans>
      </label>
      <TextToggle<boolean>
        variant="tiny"
        options={getOptions()}
        value={value}
        onChange={({ value }) => onChange(value)}
      />
    </div>
  );
};
