import React from 'react';

import { CycleConfig } from '../Cycles.types';
import { getIsStandardCycle } from '../helpers/cycleVariants';
import { StickyCycleTitle } from './CycleTitle';

import * as styles from '../Cycles.css';

export type CycleTitleListProps = {
  cycles: CycleConfig[];
  dayWidth: number;
  leftStickyBoundary: number;
};

export const CycleTitleList: React.FC<CycleTitleListProps> = ({
  cycles,
  dayWidth,
  leftStickyBoundary,
}) => {
  return (
    <div className={styles.cycleTitleList}>
      {cycles.filter(getIsStandardCycle).map((cycleConfig) => (
        <StickyCycleTitle
          key={cycleConfig.key}
          cycleConfig={cycleConfig}
          dayWidth={dayWidth}
          leftStickyBoundary={leftStickyBoundary}
        />
      ))}
    </div>
  );
};
