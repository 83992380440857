import React from 'react';

/* NOTE: When using withListeners, it must directly wrap the child component
 * so that it can access the registered ref. Take care when composing this HOC
 * to respect that restriction */

export default (listeners) => (WrappedComponent) =>
  class extends React.Component {
    wrappedRef = React.createRef();

    componentDidMount() {
      this.boundListeners = listeners.map(({ eventType, testFn, handler }) => {
        const tryHandler = this.createTryHandler(handler, testFn);
        document.addEventListener(eventType, tryHandler);
        return [eventType, tryHandler];
      });
    }

    componentWillUnmount() {
      this.boundListeners.forEach(([eventType, tryHandler]) => {
        document.removeEventListener(eventType, tryHandler);
      });
    }

    createTryHandler = (handler, testFn) => (e) => {
      if (
        (!testFn || testFn(e)) &&
        typeof this.wrappedRef.current[handler] === 'function'
      ) {
        this.wrappedRef.current[handler](e);
      }
    };

    render() {
      return <WrappedComponent ref={this.wrappedRef} {...this.props} />;
    }
  };
