import { BudgetPriority } from '@float/types/project';
import { ProjectTaskRecord, TaskMeta } from '@float/types/task';

import { ProjectPhaseRecord } from '../../types';

export type GetProjectCalculatedBudgetParams = {
  isProjectNonBillable: boolean;
  budgetPriority?: BudgetPriority;
  formTasks: ProjectTaskRecord[];
  formPhases: ProjectPhaseRecord[];
  projectAllTasks?: TaskMeta[];
};

type TaskBudgetMeta = Pick<TaskMeta, 'billable' | 'budget'>;

function mapToTaskBudgetMeta(tasks?: TaskBudgetMeta[]) {
  if (!tasks?.length) return [];

  return tasks.map((task) => ({
    billable: task.billable,
    budget: task.budget,
  }));
}

function getTasksAssignedToPhases(
  formPhases: ProjectPhaseRecord[],
  projectAllTasks?: TaskMeta[],
): TaskBudgetMeta[] {
  // This will add persisted phase tasks to the result.
  const result: TaskBudgetMeta[] =
    projectAllTasks?.filter((taskMeta) => Boolean(taskMeta.phase_id)) || [];

  for (const formPhase of formPhases) {
    // This will add unsaved phase tasks to the result.
    // E.g. a new phase in an new project, or in a template.
    if (formPhase.fullPhaseData?.tasks) {
      result.push(...mapToTaskBudgetMeta(formPhase.fullPhaseData.tasks));
    }
  }

  return result;
}

export const getProjectCalculatedBudget = ({
  isProjectNonBillable,
  budgetPriority,
  formPhases,
  formTasks,
  projectAllTasks,
}: GetProjectCalculatedBudgetParams) => {
  if (!budgetPriority) return null;

  // Calculated budget from phases
  if (budgetPriority === BudgetPriority.Phase) {
    const budgetTotalCalculatedFromFormPhases =
      formPhases.reduce(
        (acc, phase) =>
          phase.budget_total
            ? acc + parseFloat(phase.budget_total as unknown as string)
            : acc,
        0,
      ) || 0;

    return budgetTotalCalculatedFromFormPhases;
  }

  // Calculated budget from form tasks and phases tasks
  if (budgetPriority === BudgetPriority.Task) {
    // If the project is non billable the total budget should not filter out
    // non-billable tasks, because all tasks are considered billable
    // https://linear.app/float-com/document/budget-utilization-and-calculation-expected-behavior-july-1-2024-05bb3dcbbfd3#1092cc1d-31e4-4056-aa2f-8cc71b3b680c
    const shouldFilterOutNonBillableTasks = !isProjectNonBillable;

    const tasksAssignedToPhases = getTasksAssignedToPhases(
      formPhases,
      projectAllTasks,
    );

    const tasksAssignedToProjectIncludedInBudgetCalculation = formTasks.filter(
      (task) =>
        shouldFilterOutNonBillableTasks ? Boolean(task.billable) : true,
    );

    const tasksAssignedToPhasesIncludedInBudgetCalculation =
      tasksAssignedToPhases.filter((taskMeta) =>
        shouldFilterOutNonBillableTasks ? Boolean(taskMeta.billable) : true,
      );

    const budgetTotalCalculatedFromFormTasks =
      tasksAssignedToProjectIncludedInBudgetCalculation.reduce((acc, task) => {
        const taskBudget = typeof task.budget === 'number' ? task.budget : 0;

        return taskBudget + acc;
      }, 0);

    const budgetTotalCalculatedFromProjectPhasesTasks =
      tasksAssignedToPhasesIncludedInBudgetCalculation.reduce(
        (acc, taskMeta) => {
          const taskBudget =
            typeof taskMeta.budget === 'number' ? taskMeta.budget : 0;

          return taskBudget + acc;
        },
        0,
      ) || 0;

    return (
      budgetTotalCalculatedFromFormTasks +
      budgetTotalCalculatedFromProjectPhasesTasks
    );
  }

  return null;
};
