import { randomColor } from '@float/common/lib/utils';
import { email as isValidEmail } from '@float/libs/validate';
import {
  areStringsSame,
  containsAnyOf,
  equalsAnyOf,
  isNdigitOrLess,
  mapImportHeaderToKeys,
  parseRow,
  refineNumericalValue,
  startsWithAnyOf,
} from '@float/web/importCsv/validateImportRow';

const getKeyFromHeader = (key) => {
  const lower = key.toLowerCase().trim();
  if (
    // we do not want to match project owner email
    equalsAnyOf(lower, ['project name', 'project']) ||
    startsWithAnyOf(lower, 'project name')
  ) {
    return 'name';
  }

  if (lower.startsWith('client')) {
    return 'client';
  }

  if (lower.startsWith('notes')) {
    return 'notes';
  }

  if (lower.startsWith('tags')) {
    return 'tags';
  }

  if (containsAnyOf(lower, ['budget', 'hours'])) {
    return 'budget';
  }

  if (startsWithAnyOf(lower, ['non-billable', 'non billable'])) {
    return 'non_billable';
  }

  if (containsAnyOf(lower, ['owner', 'email'])) {
    return 'owner_email';
  }

  if (containsAnyOf(lower, ['all pms', 'edit rights'])) {
    return 'common';
  }

  return null;
};

const refineValue = (value, key) => {
  switch (key) {
    case 'tags':
      return value
        .split(',')
        .map((x) => x.trim())
        .filter((y) => y);
    case 'budget':
      return refineNumericalValue(value);
    case 'non_billable':
    case 'common': {
      const val = value.toLowerCase().trim() || 0;
      return equalsAnyOf(val, ['y', 'yes', '1']) ? 1 : 0;
    }
    case 'owner_email':
      return value ? value.toLowerCase().trim() : null;
    default:
      return value.trim() || null;
  }
};

const getClientName = (proj) => {
  if (proj.client_name && proj.client_name.toLowerCase() !== 'no client') {
    return ` / ${proj.client_name}`;
  }
  return '';
};

const isDuplicate = (row, projects) => {
  const proj = projects.find(
    (x) =>
      areStringsSame(row.name, x.project_name) &&
      areStringsSame(row.client, x.client_name, 'no client'),
  );
  return proj ? `${proj.project_name}${getClientName(proj)}` : false;
};

const getRowErrors = (row, accounts) => {
  const errors = [];
  if (!row.name) {
    errors.push('Please enter a project name');
  }

  if (row.budget && !isNdigitOrLess(row.budget, 9)) {
    errors.push('Budget must be less than 9 numbers');
  }

  if (row.owner_email) {
    if (!isValidEmail(row.owner_email)) {
      errors.push('Please enter a valid email');
    } else if (
      !accounts.find((x) => areStringsSame(x.email, row.owner_email))
    ) {
      errors.push(
        'Can’t match this email to a team member with access permission',
      );
    }
  }

  return errors;
};

export const mapProjectHeaderToKeys = (headers) => {
  return mapImportHeaderToKeys({ headers, getKeyFromHeader });
};

export const validateProjectRow = (row, { keyMap, projects, accounts }) => {
  const data = parseRow({ row, keyMap, refineValue });
  data.color = randomColor();
  const errors = getRowErrors(data, accounts);
  const duplicateRef = isDuplicate(data, projects);
  return { data, errors, duplicateRef };
};
