export const fastDictionary = () => Object.create(null);

// An object spread that returns a prototypeless Object
// which has faster writes when used as a map with many keys
export const fastObjectSpread = (...sources: any[]) => {
  const result = fastDictionary();

  for (const item of sources) {
    for (const key of Object.keys(item)) {
      result[key] = item[key];
    }
  }

  return result;
};
