import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon {...props} viewBox="0 0 16 16">
    <path
      d="M9.83334 12.8333L12.8333 10L9.83334 7.16667"
      stroke="#000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3333 10H5.83334C4.36058 10 3.16668 8.80609 3.16668 7.33333V3.16667"
      stroke="#000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </BaseIcon>
);
