import React from 'react';

import Button from '@float/ui/deprecated/Button/Button';
import * as EarhartIcons from '@float/ui/deprecated/Earhart/Icons';
import Icons from '@float/ui/deprecated/Icons';
import { Modal } from '@float/ui/deprecated/Modal';
import ProgressModal from '@float/ui/deprecated/ProgressBar/ProgressModal';

import AvatarImage from './AvatarImage';
import dataURItoBlob from './dataUriToBlob';
import HiddenFileInput from './HiddenFileInput';
import ReactAvatarEditor from './react-avatar-editor-forked';
import { AvatarEditorContainer, ZoomFactorContainer } from './styles';

class AvatarEditor extends React.Component {
  state = {
    isEditing: !!this.props.newAvatarFile,
    isSaving: false,
    progress: 0,
    newAvatarFile: this.props.newAvatarFile,
    zoomFactor: 1.0,
  };

  static defaultProps = {
    onClose: () => {},
  };

  setEditorRef = (el) => {
    this.editor = el;
  };

  setZoomFactor = (evt) => {
    this.setState({ zoomFactor: +evt.target.value });
  };

  getImage = () => {
    return this.state.newAvatarFile || this.props.imageUrl;
  };

  onSave = async () => {
    const { onSave, onClose } = this.props;
    if (this.editor && typeof onSave === 'function') {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      const canvas = this.editor.getImageScaledToCanvas();
      const dataUrl = canvas.toDataURL('image/png');
      this.setState({ isSaving: true, progress: 10 });
      await onSave({ dataUrl, blob: dataURItoBlob(dataUrl) });
      this.setState({ progress: 100 });
    }
    setTimeout(() => onClose(), 500);
  };

  onRemove = () => {
    const { onSave, onClose } = this.props;
    if (typeof onSave === 'function') {
      onSave({});
    }
    onClose();
  };

  onFileSelect = (newAvatarFile) => {
    this.setState({ newAvatarFile, isEditing: true });
  };

  renderEditModaActions = () => {
    return (
      <>
        <Button onClick={this.onSave}>Save</Button>
        <Button appearance="secondary" onClick={this.props.onClose}>
          Cancel
        </Button>
      </>
    );
  };

  renderDefaultModeActions = () => {
    return (
      <>
        <Button as="label">
          <span>Change photo</span>
          <HiddenFileInput
            accept="image/png, image/jpg, image/jpeg"
            onChange={this.onFileSelect}
          />
        </Button>
        <Button
          appearance="danger"
          icon={EarhartIcons.IconTrash}
          onClick={this.onRemove}
        >
          Remove
        </Button>
      </>
    );
  };

  renderSavingView = () => {
    return (
      <ProgressModal
        title="Update profile photo"
        progress={this.state.progress}
        height={560}
        onClose={this.props.onClose}
      />
    );
  };

  render() {
    if (this.state.isSaving) {
      return this.renderSavingView();
    }

    const { isEditing, zoomFactor } = this.state;
    const image = this.getImage();

    return (
      <Modal isOpen={true} onClose={this.props.onClose}>
        <Modal.Header>
          <Modal.Title>Update profile photo</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: 150 }}>
          <AvatarEditorContainer>
            {isEditing ? (
              <ReactAvatarEditor
                ref={this.setEditorRef}
                image={image}
                width={200}
                height={200}
                borderRadius={100}
                border={[96, 7]}
                scale={zoomFactor}
                color={[0, 0, 0, 0.7]}
              />
            ) : (
              <AvatarImage name={this.props.name} imageUrl={image} size="xl" />
            )}
          </AvatarEditorContainer>
          {isEditing && (
            <ZoomFactorContainer>
              <Icons.Canvas width="16" height="13" />
              <input
                type="range"
                value={zoomFactor}
                onChange={this.setZoomFactor}
                min="0.8"
                max="2.2"
                step=".1"
              />
              <Icons.Canvas />
            </ZoomFactorContainer>
          )}
        </Modal.Body>
        <Modal.Actions style={{ justifyContent: 'center', paddingBottom: 50 }}>
          {isEditing
            ? this.renderEditModaActions()
            : this.renderDefaultModeActions()}
        </Modal.Actions>
      </Modal>
    );
  }
}

export default AvatarEditor;
