import React, { CSSProperties } from 'react';

import colors from '@float/ui/deprecated/Theme/colors';

export default function ({
  color = colors.emphasisMedium,
  style,
}: {
  color?: string;
  style?: CSSProperties;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={style}
    >
      <g fill="none">
        <path
          d="M15.25 8C15.25 9.79493 13.7949 11.25 12 11.25C10.2051 11.25 8.75 9.79493 8.75 8C8.75 6.20507 10.2051 4.75 12 4.75C13.7949 4.75 15.25 6.20507 15.25 8Z"
          stroke={color}
          className="stroke"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.25 19.25H6.94949C5.77001 19.25 4.88985 18.2103 5.49082 17.1954C6.36244 15.7234 8.23932 14 12.25 14"
          stroke={color}
          className="stroke"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 14.75V19.25"
          stroke={color}
          className="stroke"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.25 17L14.75 17"
          stroke={color}
          className="stroke"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
