import React from 'react';
import { useSelector } from 'react-redux';
import { AccessCell, ManagesTag } from 'manage/people-v2/People';

import { getUser } from '@float/common/selectors/currentUser';
import { useAppDispatch, useAppSelector } from '@float/common/store';
import { moment } from '@float/libs/moment';
import Table from '@float/web/settingsV2/components/Table';
import withOnMount from '@float/web/settingsV2/decorators/withOnMount';
import {
  areBeingFetched as accountsAreBeingFetched,
  lastFetchError as accountsLastFetchError,
  filterAcceptedGuests,
} from '@float/web/settingsV2/reducers/accounts';
import {
  areBeingFetched as projectsAreBeingFetched,
  lastFetchError as projectsLastFetchError,
} from '@float/web/settingsV2/reducers/projects';
import {
  ACCOUNT_SETTINGS_GUESTS_TABLE,
  getSortData,
  getTableRows,
} from '@float/web/settingsV2/reducers/reducerHelpers';
import { getSettingsAccountsMap } from '@float/web/settingsV2/selectors/accounts';

import { fetchAccounts, sortAccounts } from '../../actions/accounts';
import { SETTINGS_TABLE_DATE_FORMAT } from '../../settingsV2.constants';

const getAccountData = (entity) => {
  if (!entity) return null;
  const id = entity.account_id;
  return {
    isDeletable: false,
    isEdited: false,
    entity,
    id,
    data: [
      [entity.name, entity.email],
      // calculated based on account_type
      entity.account_type_name.replace('Observer', 'Member'),
      entity.project_count,
      moment(entity.created).format(SETTINGS_TABLE_DATE_FORMAT),
    ],
  };
};

const GuestsTable = withOnMount(Table);

export default (props) => {
  const dispatch = useAppDispatch();
  const rows = useAppSelector((state) => {
    const rawRows = getTableRows(
      state.settingsAccounts,
      getAccountData,
      'account_id',
      ACCOUNT_SETTINGS_GUESTS_TABLE,
      filterAcceptedGuests,
    );

    return rawRows;
  });

  const settingsAccounts = useSelector(getSettingsAccountsMap);

  const user = useAppSelector(getUser);
  const sort = useAppSelector((state) =>
    getSortData(state.settingsAccounts, ACCOUNT_SETTINGS_GUESTS_TABLE),
  );

  const isLoading = useAppSelector(
    (state) =>
      accountsAreBeingFetched(state.settingsAccounts) ||
      projectsAreBeingFetched(state.settingsProjects),
  );

  const loadError = useAppSelector(
    (state) =>
      accountsLastFetchError(state.settingsAccounts) ||
      projectsLastFetchError(state.settingsProjects),
  );

  return (
    <GuestsTable
      headNames={['Name', 'Access', 'Manages', 'Joined']}
      rows={rows.map((row) => {
        return {
          ...row,
          display: [
            null,
            <AccessCell
              key={row.id}
              accounts={settingsAccounts}
              accountId={row.id}
            />,
            <ManagesTag key={row.id} managerAccountId={row.id} />,
            null,
          ],
        };
      })}
      recordNames={['guest', 'guests']}
      displayRowCount={false}
      rowActions={[
        {
          id: 'edit',
          label: 'Edit',
        },
      ]}
      columnsWidth={['35%', '25%', '15%', '15%', '10%']}
      companyId={user.cid}
      sort={sort}
      isLoading={isLoading}
      loadError={loadError}
      onClickHeader={(property, direction) =>
        dispatch(
          sortAccounts(property, direction, ACCOUNT_SETTINGS_GUESTS_TABLE),
        )
      }
      onLastRowVisible={() =>
        dispatch(fetchAccounts(ACCOUNT_SETTINGS_GUESTS_TABLE))
      }
      {...props}
    />
  );
};
