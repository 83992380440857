import styled from 'styled-components';

import EH from '@float/ui/deprecated/Earhart';

export const StyledSection = styled.section`
  display: flex;
  justify-content: space-between;

  padding: 16px;
  border-radius: 16px;
  margin-top: 24px;

  background-color: ${EH.Colors.Primary.Flue.Light['2']};
`;

export const StyledContainer = styled.div`
  display: flex;

  div:first-child {
    margin-left: 0;
  }
`;

export const StyledColumn = styled.div`
  h4 {
    ${EH.Typography.TextXXL.M500};
    line-height: 28px;

    font-family: Lexend;
    color: ${EH.Colors.FIN.Lt.Emphasis.High};

    display: flex;
    align-items: center;
  }

  p {
    ${EH.Typography.TextM.R400};

    color: ${EH.Colors.FIN.Lt.Emphasis.High};
  }

  a {
    ${EH.Typography.TextM.SB600};

    color: ${EH.Colors.FIN.Lt.Buttons.Primary.Default.Bg};

    &:hover {
      color: ${EH.Colors.FIN.Lt.Buttons.Primary.Hover.Bg};
    }
  }
`;
export const StyledLabel = styled.div`
  ${EH.Typography.Label12.M500};

  display: flex;
  flex-direction: column;
  justify-content: center;

  color: ${EH.Colors.TCore.Emp.Medium11};

  margin-left: 16px;
`;

export const StyledSVGIconContainer = styled.div`
  display: flex;
  align-items: center;

  height: 100%;

  margin-right: 16px;
`;
