import React, { MouseEventHandler } from 'react';
import { useWatch } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';

import { NavIconBtn } from '@float/common/components/NavIconBtn';
import { Tooltip } from '@float/ui/components/Tooltip';
import {
  IWithModalConfirmDelete,
  withModalConfirmDelete,
} from '@float/web/modalManager/hoc/withModalConfirmDelete';

import { useFieldArrayContext } from '../../hooks/useFieldArrayContext';
import { ProjectFormData } from '../../types';
import { useDeleteTaskTooltip } from './DeleteTasks.hooks';
import { showModalConfirmTaskDelete } from './helpers/showModalConfirmTaskDelete';
import { useSelectedTasks, useTaskDeleteImpact } from './index.hooks';

type DeleteTasksProps = IWithModalConfirmDelete;

export const DeleteTasksComponent = (props: DeleteTasksProps) => {
  const { confirmDelete, confirmDeleteClose } = props;
  const tasks = useWatch<ProjectFormData, 'tasks'>({
    name: 'tasks',
  });
  const { remove } = useFieldArrayContext<ProjectFormData, 'tasks'>();
  const selected = useSelectedTasks(tasks);
  const impact = useTaskDeleteImpact(selected.tasks);

  const onBulkDelete = () => {
    remove(selected.indices);
    confirmDeleteClose();
  };

  const onClickDeleteAll: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    if (impact) {
      showModalConfirmTaskDelete({
        confirmDelete,
        impact,
        onDelete: onBulkDelete,
        tasks: selected.tasks,
      });
    } else {
      onBulkDelete();
    }
  };
  const { disabledTooltipActive, disabledTooltipContent } =
    useDeleteTaskTooltip(Boolean(impact));
  return (
    <React.Fragment>
      <Tooltip open={disabledTooltipActive} content={disabledTooltipContent}>
        <NavIconBtn
          type="button"
          aria-label={t`Delete selected tasks`}
          disabled={
            !selected.indices.length || disabledTooltipActive === undefined
          }
          onClick={onClickDeleteAll}
        >
          <Trans>Delete</Trans>
        </NavIconBtn>
      </Tooltip>
    </React.Fragment>
  );
};

export const DeleteTasks = withModalConfirmDelete(DeleteTasksComponent);
