import { keyBy } from 'lodash';

import request from '../lib/request';

export const STATUS_TYPES_LOAD_START = 'statusTypes/LOAD_START';
export const STATUS_TYPES_LOAD_FAILED = 'statusTypes/LOAD_FAILED';
export const STATUS_TYPES_LOAD_FINISH = 'statusTypes/LOAD_FINISH';

export const STATUS_TYPE_UPDATED = 'statusTypes/UPDATED';

const fetchStatusTypes = async () => {
  const res = await request.get('status-type-co', null, { version: 'f3' });
  return keyBy(res, 'status_type_id');
};

export const ensureStatusTypesLoaded = (forceLoad = false) => {
  return async (dispatch, getState) => {
    const { loadState: currentLoadState } = getState().statusTypes;

    if (!forceLoad && currentLoadState === 'LOADED') return;
    if (currentLoadState === 'LOADING') return;

    try {
      dispatch({ type: STATUS_TYPES_LOAD_START });
      const statusTypes = await fetchStatusTypes(forceLoad);
      dispatch({ type: STATUS_TYPES_LOAD_FINISH, statusTypes });
    } catch (e) {
      console.error(e);
      dispatch({ type: STATUS_TYPES_LOAD_FAILED });
    }
  };
};

export const updateStatusType = (data) => async (dispatch) => {
  try {
    const statusType = await request.post('status-type-co', data, {
      version: 'f3',
    });
    dispatch({
      type: STATUS_TYPE_UPDATED,
      statusType: {
        ...data,
        ...statusType,
      },
    });
  } catch (err) {
    return (err && err.message) || 'An error occurred.';
  }
  return null;
};
