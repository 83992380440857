import { StyleRule } from '@vanilla-extract/css';

const common: StyleRule = {
  fontStyle: 'normal',
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '-0.3px',
};

export const VE_R400: StyleRule = {
  ...common,
  fontWeight: 'normal',
};

export const VE_M500: StyleRule = {
  ...common,
  fontWeight: 500,
};

export const VE_SB600: StyleRule = {
  ...common,
  fontWeight: 600,
};
