export function delay(ms: number) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(undefined), ms);
  });
}

/**
 * Map over promises concurrently
 *
 * It's like a Promise.all but with a concurrency limit
 */
export async function promiseMap<P>(
  payloads: P[],
  mapFn: (payload: P) => Promise<any>,
  opts: {
    concurrency: number;
  },
) {
  const results = Array.from({ length: payloads.length });
  const concurrency = opts.concurrency;
  let i = 0;

  async function run() {
    let currI = i++;

    while (currI < payloads.length) {
      results[currI] = await mapFn(payloads[currI]);

      currI = i++;
    }
  }

  await Promise.all(payloads.slice(0, concurrency).map(run));

  return results;
}
