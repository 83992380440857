import React from 'react';

export default function ({ size = 24 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
    >
      <circle
        cx="12"
        cy="12"
        r="9.5"
        fill="#FFF"
        fillRule="evenodd"
        stroke="#CBCBCB"
      />
    </svg>
  );
}
