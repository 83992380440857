import React, { ReactNode } from 'react';
import {
  Arrow,
  Content,
  HoverCardContentProps,
  Portal,
  Root,
  Trigger,
} from '@radix-ui/react-hover-card';

import { preventDefault } from '@float/libs/utils/events/preventDefault';

import * as styles from './styles.css';

export type HoverCardProps = {
  className?: string;
  title: ReactNode;
  content: ReactNode;
  children: ReactNode;
  placement?: HoverCardContentProps['side'];
  align?: HoverCardContentProps['align'];
  alignOffset?: HoverCardContentProps['alignOffset'];
  distance?: HoverCardContentProps['sideOffset'];
  collisionPadding?: HoverCardContentProps['collisionPadding'];
};

export const HoverCardInfo = React.forwardRef<HTMLDivElement, HoverCardProps>(
  (props, ref) => {
    return (
      <Root>
        <Trigger asChild>{props.children}</Trigger>
        <Portal>
          <Content
            ref={ref}
            className={styles.content}
            side={props.placement}
            align={props.align}
            alignOffset={props.alignOffset}
            sideOffset={props.distance}
            collisionPadding={5}
            // Avoids that the click on the trigger dismiss the hover card
            // we don't need this because the HoverCard gets dismissed on mouse out anyway
            onPointerDownOutside={preventDefault}
          >
            <div className={styles.title}>{props.title}</div>
            {props.content}
            <Arrow className={styles.arrow} />
          </Content>
        </Portal>
      </Root>
    );
  },
);
