import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { assignInlineVars } from '@vanilla-extract/dynamic';

import {
  getIsCompactDensity,
  getProjectRowEntityHeight,
  getProjectRowEntityTop,
} from '@float/common/components/Schedule/Window/projectRow.helpers';
import { SerenaState } from '@float/common/selectors/serena';
import { useCombinedRefs } from '@float/libs/hooks/useCombinedRefs';
import { moment } from '@float/libs/moment';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { CellItem } from '@float/types';
import { TextTooltip } from '@float/ui/deprecated/Tooltip/TextTooltip';

import { ScheduleActions } from '../types';
import { TOOLTIP_DELAY, usePinnedText } from './helpers';
import { MilestoneCircle } from './MilestoneCircle';

import * as styles from './MilestoneItem.css';
import {
  projectItemHoverTooltip,
  projectItemHoverTooltipContent,
} from './styles.css';

const MARGIN = 4;

const textTopStyles = (value: number) =>
  assignInlineVars({ [styles.textPinnedTopVar]: `${value}px` });

type Props = {
  actions: ScheduleActions;
  dayWidth: number;
  hourHeight: number;
  item: CellItem<'milestone'>;
  reduxData: SerenaState;
  scrollWrapperRef: React.MutableRefObject<HTMLElement | undefined>;
  wrapperRef: React.RefObject<HTMLDivElement>;
};

const MilestoneItem = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { item, dayWidth, hourHeight, reduxData, actions, ...rest } = props;
  const boxRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const { x = 0, w, y = 0, isStart, isEnd } = item;
  const project = reduxData.projects[item.entity.project_id];
  const phase = reduxData.phases[item.entity.phase_id];

  const left = x * dayWidth + (isStart ? MARGIN : 1);
  const top = getProjectRowEntityTop({ hourHeight, y });
  const height = getProjectRowEntityHeight({ hourHeight, margin: MARGIN * 2 });
  const isCompactDensity = getIsCompactDensity(hourHeight);
  const { visibleLength } = item.entity;
  const width = visibleLength * dayWidth - MARGIN * 2 + 1;

  const pinned = usePinnedText({
    item,
    scrollWrapperRef: props.scrollWrapperRef,
    wrapperRef: props.wrapperRef,
    boxRef,
    textRef,
  });

  const combinedRefs = useCombinedRefs([ref, boxRef]);

  if (!project) return null;
  if (!isStart) return null;

  const dragVersion = (
    <div className={styles.box} style={{ width, height }}>
      <MilestoneCircle project={project} phase={phase} />
      {(dayWidth !== 27 || w > 1) && (
        <div className={styles.text}>{item.entity.name}</div>
      )}
    </div>
  );

  const textTop = !isCompactDensity && pinned ? top + 2 : top;
  const RawTextElement = (
    <div
      className={styles.text}
      style={textTopStyles(textTop)}
      data-pinned={pinned}
      ref={textRef}
    >
      <MilestoneCircle project={project} phase={phase} />
      <span className={styles.textSpan}>
        {(dayWidth !== 27 || visibleLength > 1) && item.entity.name}
      </span>
    </div>
  );

  const wrapperEl = props.wrapperRef.current;

  const TextElement =
    pinned && wrapperEl
      ? createPortal(
          RawTextElement,
          wrapperEl.parentElement!.getElementsByClassName('pin-target')[0],
        )
      : RawTextElement;

  return (
    <div
      {...rest}
      className={styles.box}
      ref={combinedRefs}
      style={{
        left,
        top,
        width,
        height,
      }}
      onMouseDown={(event) => {
        event.stopPropagation();
        if (event.button !== 0) return;
        if (!boxRef.current) return;

        const { x: offsetX, y: offsetY } =
          boxRef.current.getBoundingClientRect();

        actions.setDragItem({
          item,
          element: dragVersion,
          offsetX,
          offsetY,
          clientX: event.clientX,
          clientY: event.clientY,
        });
      }}
    >
      {actions.isItemEditable(item) && isStart && (
        <div
          className={styles.leftResize}
          onMouseDown={(e) => {
            if (e.button !== 0) return prevent(e);
            e.stopPropagation();
            actions.onItemResizeStart(item, 'L');
          }}
        />
      )}
      {isStart && TextElement}
      {actions.isItemEditable(item) && isEnd && (
        <div
          className={styles.rightResize}
          onMouseDown={(e) => {
            if (e.button !== 0) return prevent(e);
            e.stopPropagation();
            actions.onItemResizeStart(item, 'R');
          }}
        />
      )}
    </div>
  );
});

const formatDate = (date: unknown) => moment(date).format('DD MMM YYYY');

function getTooltipContent(item: CellItem<'milestone'>) {
  const { entity: ms } = item;
  return (
    <div className={projectItemHoverTooltip}>
      <header>
        Milestone: {formatDate(ms.date)}
        {ms.date !== ms.end_date && ` - ${formatDate(ms.end_date)}`}
      </header>
      <span className={projectItemHoverTooltipContent}>{ms.name}</span>
    </div>
  );
}

type MilestoneItemWithTooltipProps = Props & {
  boundaryRef: React.MutableRefObject<Element | Element[] | undefined>;
};

function MilestoneItemWithTooltip(props: MilestoneItemWithTooltipProps) {
  const { actions, item, reduxData, boundaryRef } = props;

  const [open, setOpen] = useState(false);

  const project = reduxData.projects[item.entity.project_id];
  if (!project) {
    return null;
  }

  return (
    <TextTooltip
      content={getTooltipContent(item)}
      placement="bottom"
      delay={TOOLTIP_DELAY}
      distance={5}
      open={open}
      collisionBoundary={boundaryRef?.current}
      onOpenChange={(open) => {
        if (open) {
          if (actions.isItemTooltipEnabled(item)) {
            setOpen(true);
          }
        } else {
          setOpen(false);
        }
      }}
    >
      {React.createElement(MilestoneItem, props)}
    </TextTooltip>
  );
}

export default MilestoneItemWithTooltip;
