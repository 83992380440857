import React from 'react';
import cn from 'classnames';

import {
  TimersWithViewMore,
  TimersWithViewMoreProps,
} from './components/TimersWithViewMore';

import * as styles from './styles.css';

export type TimerEntryListProps = {
  className?: string;
} & TimersWithViewMoreProps;

function TimerEntryList(props: TimerEntryListProps) {
  const { className, maxVisible, timers } = props;

  return (
    <div className={cn(styles.container, className)}>
      <TimersWithViewMore maxVisible={maxVisible} timers={timers} />
    </div>
  );
}

export { TimerEntryList };
