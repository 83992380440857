import { useState } from 'react';
import { dequal as isEqual } from 'dequal';
import useCustomCompareEffect from 'react-use/esm/useCustomCompareEffect';

import { useAppStore } from '../store';
import { searchService } from './service';
import {
  SearchWorkerSelectors,
  SearchWorkerSelectorsKeys,
  SelectorParameters,
} from './service/worker/searchSelectors';

/**
 * Runs a selector inside the SearchService.
 *
 * In the web app this means that the selector will run in the WebWorker where
 * we hold the search state.
 * 
 * Example:
 
 const isTasked = useSearchSelectorWithParams(
    'isPersonTaskedInTheProject',
    [projectId, peopleId],
    true, 
 );

 */
export function useSearchSelectorWithParams<
  S extends SearchWorkerSelectorsKeys,
  V,
>(
  selectorKey: S,
  args: SelectorParameters<SearchWorkerSelectors[S]>,

  // The selector is processed in async so we need
  // a fallback value to provide on the initial render
  defaultValue: V,
  enabled = true,
) {
  const [value, setValue] = useState<ReturnType<SearchWorkerSelectors[S]> | V>(
    defaultValue,
  );

  const store = useAppStore();

  // Using a deep comparison on the args array
  // to reduce the subscribe/unsubscribe roundtrips with the Worker
  useCustomCompareEffect(
    () => {
      if (!enabled) return;

      let canceled = false;

      function handler(value: ReturnType<SearchWorkerSelectors[S]>) {
        if (canceled) return; // Avoid concurrency issues on args update

        setValue(value);
      }

      const unsubscribePromise = searchService.subscribeToSelector(
        store,
        selectorKey,
        handler,
        args,
      );

      return () => {
        canceled = true;

        unsubscribePromise.then((unsubscribe) => {
          unsubscribe();
        });
      };
    },
    [selectorKey, args, enabled],
    isEqual,
  );

  return value;
}
