import styled from 'styled-components';

import * as Colors from '../Colors';

export default styled.div<{ size: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Colors.Core.Surface.Icon};

  ${({ size = 32 }) => `
    width: ${size}px;
    height: ${size}px;
    border-radius: ${size}px;
  `}
`;
