import { BudgetPriority } from '@float/types/project';
import { ProjectTaskRecord } from '@float/types/task';

export type GetPhaseCalculatedBudgetParams = {
  isProjectNonBillable: boolean;
  budgetPriority?: BudgetPriority;
  formTasks: ProjectTaskRecord[];
};

export const getPhaseCalculatedBudget = ({
  isProjectNonBillable,
  budgetPriority,
  formTasks,
}: GetPhaseCalculatedBudgetParams) => {
  // Calculated budget from form tasks
  if (budgetPriority === BudgetPriority.Task) {
    // If the project is non billable the total budget should not filter out
    // non-billable tasks, because all tasks are considered billable
    // https://linear.app/float-com/document/budget-utilization-and-calculation-expected-behavior-july-1-2024-05bb3dcbbfd3#1092cc1d-31e4-4056-aa2f-8cc71b3b680c
    const shouldFilterOutNonBillableTasks = !isProjectNonBillable;

    const tasksIncludedInBudgetCalculation = formTasks.filter((task) =>
      shouldFilterOutNonBillableTasks ? Boolean(task.billable) : true,
    );

    const budgetTotalCalculatedFromFormTasks =
      tasksIncludedInBudgetCalculation.reduce((acc, task) => {
        const taskBudget = typeof task.budget === 'number' ? task.budget : 0;

        return taskBudget + acc;
      }, 0);

    return budgetTotalCalculatedFromFormTasks;
  }

  return null;
};
