import { t } from '@lingui/macro';

import { updateProject } from '@float/common/actions';
import { getErrorMessageFromException } from '@float/common/lib/getErrorMessageFromException';
import { useAppDispatchStrict } from '@float/common/store';
import { Person } from '@float/types/person';
import { Project } from '@float/types/project';
import { useSnackbar } from '@float/ui/deprecated';

export type UseSwitchPersonConfirmProps = {
  person?: Person;
  project?: Project;
  selectedPersonId?: Person['people_id'];
  onSwitch?: (selectedPersonId: Person['people_id']) => void;
  onSwitchComplete: () => void;
};

export const useSwitchPersonConfirm = (props: UseSwitchPersonConfirmProps) => {
  const { selectedPersonId, onSwitch, person, project, onSwitchComplete } =
    props;

  const { showSnackbar } = useSnackbar();
  const dispatch = useAppDispatchStrict();

  const switchPerson = ({
    projectId,
    from,
    to,
  }: {
    projectId: Project['project_id'];
    from: Person['people_id'];
    to: Person['people_id'];
  }) => {
    const patch = { project_team: { sub: { [from]: to } } };

    // @ts-expect-error update project action needs strict dispatch and state types
    return dispatch(updateProject(projectId, patch));
  };

  const onClickConfirm = async () => {
    if (!selectedPersonId || !project || !person) return;

    try {
      await switchPerson({
        projectId: project.project_id,
        from: person.people_id,
        to: selectedPersonId,
      });

      const name = project!.project_name;

      showSnackbar(t`${name} updated`);

      if (onSwitch) onSwitch(selectedPersonId);
      if (onSwitchComplete) onSwitchComplete();
    } catch (error) {
      throw new Error(getErrorMessageFromException(error));
    }
  };

  return {
    onClickConfirm,
  };
};
