import React from 'react';
import { plural, Trans } from '@lingui/macro';

import { searchService } from '@float/common/search/service';
import { AppStoreStrict } from '@float/common/store';
import { trimTextForReadibility } from '@float/libs/utils/string/trimTextForReadibility';
import { Project } from '@float/types/project';
import { Modal } from '@float/ui/primitives/Modal';
import { IWithModalConfirmDelete } from '@float/web/modalManager/hoc/withModalConfirmDelete';

import { IBulkUpdate } from '../../people-v2/types';

const Content = (props: { deletedProject: string }) => {
  const { deletedProject } = props;

  return (
    <>
      <Modal.P>
        <Trans>Deleting {deletedProject} will:</Trans>
      </Modal.P>
      <Modal.List>
        <Modal.ListItem>
          <Trans>Delete associated tasks and allocated time</Trans>
        </Modal.ListItem>
        <Modal.ListItem>
          <Trans>Delete all Phases and Milestones</Trans>
        </Modal.ListItem>
      </Modal.List>

      <Modal.P color="critical">
        <Trans>Deleted projects cannot be restored.</Trans>
      </Modal.P>
    </>
  );
};

export const showModalConfirmProjectDelete = async (
  ids: number[],
  projects: Project[],
  store: AppStoreStrict,
  confirmDelete: IWithModalConfirmDelete['confirmDelete'],
  bulkUpdate: (props: IBulkUpdate) => void,
) => {
  const deletedProject = plural(ids.length, {
    one: `"${projects[ids[0]].project_name}"`,
    other: 'these # projects',
  });

  const title = plural(ids.length, {
    one: `Delete "${projects[ids[0]].project_name}" project?`,
    other: 'Delete # projects?',
  });

  const projectsWithTasks = await searchService.getSelectorValue(
    store.getState(),
    'getProjectsWithTasks',
    [],
  );

  const isTwoStep = ids.some((id) => projectsWithTasks.has(Number(id)));

  const message = <Content deletedProject={deletedProject} />;

  const projectNameTrimmed = trimTextForReadibility(
    projects[ids[0]].project_name,
    22,
  ).value;
  const confirmButtonLabel = plural(ids.length, {
    one: `Delete ${projectNameTrimmed}`,
    other: 'Delete # projects',
  });

  const onDelete = () => {
    return bulkUpdate({ ids, messageSuffix: 'deleted' });
  };

  confirmDelete({
    deleteLabel: confirmButtonLabel,
    message,
    onDelete,
    title,
    twoStep: isTwoStep,
  });
};
