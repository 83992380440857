import { toDash } from '@float/libs/utils/strings';
import {
  ProjectTemplate,
  RawProjectTemplate,
  TemplateDates,
} from '@float/types';

import { ProjectTemplateV3Map } from '../actions/projects';
import request from '../lib/request';
import { makeRequest, MakeRequestParams } from './makeRequest';

const RESOURCE = 'projectTemplates';

/**
 * Retrieve a project template
 * @param id: project_template_id
 * @return project template data
 */
export const projectTemplates = {
  getProjectTemplate: async (
    params: MakeRequestParams<
      undefined,
      ProjectTemplate['project_template_id']
    >,
  ): Promise<RawProjectTemplate> =>
    makeRequest({
      ...params,
      resource: toDash(RESOURCE),
      method: 'GET',
    }),

  /**
   * Retrieves all project templates
   * @param archived boolean set to true to get only archived events
   * @return array of project template data
   */
  getAllTemplates: (): Promise<RawProjectTemplate[]> =>
    request.get('project-templates', null, {
      version: 'f3',
    }),

  /**
   * Creates a new template
   * @param data: new project template attributes
   */
  createProjectTemplate: async (
    params: MakeRequestParams<ProjectTemplateV3Map>,
  ): Promise<RawProjectTemplate> =>
    makeRequest({
      ...params,
      resource: toDash(RESOURCE),
      method: 'POST',
    }),

  /**
   * Updates a template
   * @param id: id of template to update
   * @param data: new template attributes
   */
  updateProjectTemplate: async (
    params: MakeRequestParams<
      ProjectTemplateV3Map,
      ProjectTemplate['project_template_id']
    >,
  ): Promise<RawProjectTemplate> =>
    makeRequest({
      ...params,
      resource: toDash(RESOURCE),
      method: 'PATCH',
    }),

  /**
   * Deletes a template
   * @param id: id of template to delete
   */
  deleteProjectTemplate: async (
    params: MakeRequestParams<
      undefined,
      ProjectTemplate['project_template_id']
    >,
  ): Promise<void> =>
    makeRequest({
      ...params,
      resource: toDash(RESOURCE),
      method: 'DELETE',
    }),

  /**
   * Retrieves dates based on offsets and durations stored in the template
   * @param templateId id of the template to use as reference for generating dates
   */
  getDatesFromOffsets: (
    templateId: ProjectTemplate['project_template_id'],
    anchorDate: string,
  ): Promise<TemplateDates> =>
    request.post(
      `project-templates/${templateId}/calculate-dates`,
      {
        anchor_entity: {
          type: 'project',
          anchor_date_type: 'start_date',
          anchor_date_value: anchorDate,
        },
      },
      {
        version: 'f3',
      },
    ),
};
