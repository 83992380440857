import React, { PropsWithChildren } from 'react';

const RenderFirst = (props: PropsWithChildren) => {
  const children = React.Children.toArray(props.children);

  if (children.length > 0) {
    const firstChild = children[0];

    if (React.isValidElement(firstChild)) {
      return firstChild;
    }
  }

  return children;
};

export { RenderFirst };
