import { padStart } from 'lodash';

export const getTrelloSearchContext = ({
  projectsOptions,
  itemsOptions,
  keysOptions = [],
}) => {
  const searchContext = [];

  if (projectsOptions.length) {
    searchContext.push({
      name: 'Board',
      value: 'projectName',
      options: projectsOptions,
    });
  }

  if (keysOptions.length) {
    searchContext.push({
      name: 'List',
      value: 'fullKey',
      options: keysOptions,
    });
  }

  if (itemsOptions.length) {
    searchContext.push({
      name: 'Card',
      value: 'searchableName',
      options: itemsOptions,
    });
  }

  return searchContext;
};

function floatToString(float) {
  const fixedMax = 15;
  const wholeMax = 8;
  return padStart(
    float.toFixed(fixedMax).replace('.', ''),
    fixedMax + wholeMax,
    '0',
  );
}

export const getItemPosition = (item) => {
  const { projectId, cardPosition = 0 } = item;
  const { listPosition = 0 } = item.list;
  const position = `${padStart(projectId, 9, '0')}.${floatToString(
    listPosition,
  )}.${floatToString(cardPosition)}`;
  return position;
};
