import React from 'react';

import { noop } from '@float/libs/utils/noop';
import { Button } from '@float/ui/deprecated';

type ChangePlanTierButtonsProps = {
  canStartPaidTrial?: boolean;
  isFreeTrial?: boolean;
  isPaidTrial?: boolean;
  isHigherTierThanActivePlan: boolean;
  isLowerTierThanActivePlan: boolean;
  isEnterpriseUpgrade?: boolean;
  onUpgradeClick?: () => void;
  onPaidTrialClick?: () => void;
  onTrialClick?: () => void;
  onDowngradeClick?: () => void;
  onDisableClick?: () => void;
};

export const ChangePlanTierButtons = ({
  canStartPaidTrial = false,
  isFreeTrial = false,
  isPaidTrial = false,
  isHigherTierThanActivePlan,
  isLowerTierThanActivePlan,
  isEnterpriseUpgrade = false,
  onUpgradeClick = noop,
  onPaidTrialClick = noop,
  onTrialClick = noop,
  onDowngradeClick = noop,
  onDisableClick = noop,
}: ChangePlanTierButtonsProps) => {
  if (
    isHigherTierThanActivePlan &&
    !isFreeTrial &&
    !canStartPaidTrial &&
    !isEnterpriseUpgrade
  ) {
    return <Button onClick={onUpgradeClick}>Upgrade</Button>;
  }

  // Unpaid Pro trial
  if (isHigherTierThanActivePlan && isFreeTrial && !isEnterpriseUpgrade) {
    return <Button onClick={onTrialClick}>Try for free</Button>;
  }

  // Paid Pro trial
  if (
    isHigherTierThanActivePlan &&
    !isFreeTrial &&
    canStartPaidTrial &&
    !isEnterpriseUpgrade
  ) {
    return <Button onClick={onPaidTrialClick}>Try for free</Button>;
  }

  // Upgrade to Enterprise
  if (isHigherTierThanActivePlan && isEnterpriseUpgrade) {
    return (
      <Button as="a" href="https://www.float.com/talk-to-sales" target="_blank">
        Talk to Sales
      </Button>
    );
  }

  // Paid plan downgrade
  if (isLowerTierThanActivePlan && !isFreeTrial && isPaidTrial) {
    return (
      <Button appearance="secondary" onClick={onDisableClick}>
        Downgrade
      </Button>
    );
  }
  // Other Paid plan downgrade
  if (isLowerTierThanActivePlan && !isFreeTrial && !isPaidTrial) {
    return (
      <Button appearance="secondary" onClick={onDowngradeClick}>
        Downgrade
      </Button>
    );
  }

  // Trial plan downgrade
  if (isLowerTierThanActivePlan && isFreeTrial) {
    return (
      <Button appearance="secondary" onClick={onDisableClick}>
        Downgrade
      </Button>
    );
  }

  return <></>;
};
