import React from 'react';
import cn from 'classnames';

import { IconPen, IconTrash } from '@float/ui/deprecated/Earhart/Icons';

import * as styles from './styles.css';

interface ITimerEntryRowActions {
  className?: string;
  isDisabled?: boolean;
  onClickEdit: () => void;
  onClickDelete: () => void;
}

const TimerEntryRowActions = (props: ITimerEntryRowActions) => {
  const { className, isDisabled, onClickEdit, onClickDelete } = props;

  const isTabbable = isDisabled ? -1 : 0;

  const onClickEditHandler = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.currentTarget.blur();

    onClickEdit();
  };

  const onClickDeleteHandler = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.currentTarget.blur();

    onClickDelete();
  };

  return (
    <div className={cn(className, styles.container)}>
      <button
        className={styles.button}
        tabIndex={isTabbable}
        onClick={onClickEditHandler}
      >
        <IconPen className={styles.icon} size={20} />
      </button>
      <button
        className={styles.button}
        tabIndex={isTabbable}
        onClick={onClickDeleteHandler}
      >
        <IconTrash className={styles.icon} size={20} />
      </button>
    </div>
  );
};

export { TimerEntryRowActions };
