import React from 'react';

export default function ({ size = '24' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 19L12 10"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 5.5C11.5 5.22386 11.7239 5 12 5C12.2761 5 12.5 5.22386 12.5 5.5C12.5 5.77614 12.2761 6 12 6C11.7239 6 11.5 5.77614 11.5 5.5Z"
        stroke="white"
      />
    </svg>
  );
}
