import { ACTIVE_MONTH_INDICATOR_TOTAL_COMPENSATION_LEFT } from '../Cycles.constants';
import { CycleVariant, StandardCycleConfig } from '../Cycles.types';
import { ActiveCycleIcon } from '../icons/ActiveCycleIcon';
import { CompletedCycleIcon } from '../icons/CompletedCycleIcon';
import { UpcomingCycleIcon } from '../icons/UpcomingCycleIcon';

const MIN_DAYS_NEEDED_TO_RENDER_TITLE = 5;
const MIN_DAYS_NEEDED_TO_RENDER_ICON = 1;

export const getTitleDimensions = (
  cycleConfig: StandardCycleConfig,
  dayWidth: number,
  leftStickyBoundary: number,
) => {
  const compensatedLeftStickyBoundary =
    leftStickyBoundary + ACTIVE_MONTH_INDICATOR_TOTAL_COMPENSATION_LEFT;

  const cycleIsLongEnoughToRenderTitle =
    cycleConfig.spanDays >= MIN_DAYS_NEEDED_TO_RENDER_TITLE;

  const cycleIsLongEnoughToRenderIcon = cycleIsLongEnoughToRenderTitle
    ? true
    : cycleConfig.spanDays >= MIN_DAYS_NEEDED_TO_RENDER_ICON;

  let spaceBetweenCycles;

  if (cycleConfig.daysFromEndOfPreviousCycle <= 0) {
    spaceBetweenCycles = 0;
  } else {
    spaceBetweenCycles = cycleConfig.daysFromEndOfPreviousCycle * dayWidth;
  }

  const widthOfCycle = cycleConfig.totalDays * dayWidth;

  return {
    compensatedLeftStickyBoundary,
    cycleIsLongEnoughToRenderIcon,
    cycleIsLongEnoughToRenderTitle,
    spaceBetweenCycles,
    widthOfCycle,
  };
};

export const getCycleTitleIconAndActiveState = (
  variant: Omit<CycleVariant, CycleVariant.Cooldown>,
) => {
  let CycleIcon = CompletedCycleIcon;

  let activeVariant: 'active' | 'inactive' = 'inactive';

  switch (variant) {
    case CycleVariant.Current:
      CycleIcon = ActiveCycleIcon;
      activeVariant = 'active';
      break;
    case CycleVariant.Upcoming:
      CycleIcon = UpcomingCycleIcon;
      break;
    case CycleVariant.Completed:
      CycleIcon = CompletedCycleIcon;
  }

  return { CycleIcon, activeVariant };
};
