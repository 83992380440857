import React from 'react';

export default function ({ size = '24', color = '#868D92', style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      style={style}
    >
      <path
        fill={color}
        className="fill"
        fillRule="evenodd"
        d="M20 13h-7v7h-1.91v-7H4v-2h7V4h2v7h7z"
      />
    </svg>
  );
}
