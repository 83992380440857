import React, { useState } from 'react';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { Button, Row } from '@float/ui/deprecated';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  text-align: center;

  > button {
    margin: 20px 0 10px;
  }

  img {
    max-width: 190px;
  }

  &.projects {
    img {
      position: relative;
      top: 32px;
    }
  }
`;

export default function ImportPrompt({
  className = '',
  label,
  img,
  onClick,
  onClose,
}) {
  const [closed, setClosed] = useState(false);

  const onDismiss = () => {
    setClosed(true);
    onClose();
  };

  if (closed) {
    return null;
  }

  return (
    <Container className={className}>
      {img && (
        <div>
          <img src={img} />
        </div>
      )}
      <Button onClick={onClick}>{label}</Button>
      <Row justifyContent="center">
        <Button appearance="clear" onClick={onDismiss}>
          <Trans>No, thanks</Trans>
        </Button>
      </Row>
    </Container>
  );
}
