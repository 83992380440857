import React from 'react';
import cs from 'classnames';

import * as styles from './Field.css';

export type FieldProps = {
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export const Field = ({ children, className, ...attributes }: FieldProps) => {
  return (
    <div className={cs(styles.field, className)} {...attributes}>
      {children}
    </div>
  );
};
