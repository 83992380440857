/**
 * @file useNotificationsPanelController.js
 *
 * This module defines a custom React hook `useNotificationsPanelController`
 * which provides logic for handling clicks on various activity notification
 * items.
 *
 * This hook is a conversion of a legacy implementation found in
 * `ActivityItemClick.js`. The business logic was kept untouched as much as
 * possible. In some places we are using '// @ts-expect-error legacy code' to
 * avoid touching the business logic.
 *
 * The port allows for better integration with React's functional component
 * architecture, using hooks like `useWebAppStoreStrict`, `useHistory`,
 * `useManageModal`, and `useSidePanel` to handle state management and UI
 * interactions.
 *
 * The hook returns an object containing:
 * - `onClickNotification`: A function to handle click events on different types
 *   of notification items such as tasks, people, projects, phases, and
 *   milestones.
 * - `onClickMore`: A function to handle the click event for opening the
 *   notification settings modal.
 */

import { useState } from 'react';
import { useHistory } from 'react-router';
import { isBulkOp } from '@floatschedule/activity-format-npm';
import { isArray } from 'lodash';
import {
  getMilestonesMap,
  getPeopleMap,
  getPhasesMapRaw,
  getProjectsMap,
  getStatuses,
  getTasksMap,
  getTimeoffsMap,
  getUser,
} from 'selectors';

import { useIsPeopleManager } from '@float/common/lib/acl/hooks/useIsPeopleManager';
import { CurrentUser } from '@float/types/account';
import { GenericActivity } from '@float/types/activity';
import { Project } from '@float/types/project';
import { useSnackbar } from '@float/ui/deprecated';
import { TextToggleOption } from '@float/ui/deprecated/Earhart/Toggles/TextToggle';
import {
  useWebAppSelectorStrict,
  useWebAppStoreStrict,
} from '@float/web/lib/store';
import { useManageModal } from '@float/web/modalManager/useManageModal';
import { WebAppStateStrict } from '@float/web/reducers/types';
import { PanelType } from '@float/web/sidePanel/types';
import { useSidePanel } from '@float/web/sidePanel/useSidePanel';

import { NotificationsFilter } from '../NotificationsPanel.types';

function determineCanEdit(
  type: GenericActivity['item_type'],
  projectId: Project['project_id'],
  { currentUser, projects }: WebAppStateStrict,
) {
  if (type === 'task') {
    return projects.projects[projectId].project_id;
  }
  const isObserver = +(currentUser && currentUser.account_tid) === 4;
  return !isObserver;
}

const getItemId = (
  item: GenericActivity,
  type: GenericActivity['item_type'],
): string | number | null => {
  if (item.item_id) {
    return item.item_id;
  }

  if (item.after_data && item.after_data[`${type}_id`]) {
    return item.after_data[`${type}_id`] as string | number;
  }

  return null;
};

const getTaskId = (item: GenericActivity) => getItemId(item, 'task');

const getTimeoffId = (item: GenericActivity) => getItemId(item, 'timeoff');

const getStatusId = (item: GenericActivity) => getItemId(item, 'status');

export const useNotificationsPanelController = () => {
  const store = useWebAppStoreStrict();
  const history = useHistory();
  const { manageModal } = useManageModal();
  const { showSnackbar } = useSnackbar();
  const { showProjectSidePanel } = useSidePanel(PanelType.ProjectPanel);

  const isPeopleManager = useIsPeopleManager();
  const user: CurrentUser = useWebAppSelectorStrict(getUser);
  const [currentFilter, setCurrentFilter] = useState(NotificationsFilter.All);

  const onFilterUpdate = (option: TextToggleOption<NotificationsFilter>) => {
    setCurrentFilter(option.value);
  };

  const onClickNotification = (item: GenericActivity) => {
    if (isBulkOp(item)) {
      return;
    }

    const state = store.getState();

    let model, editing;

    switch (item.item_type) {
      case 'task':
      case 'timeoff':
      case 'status': {
        // Task/Timeoff click handling:
        // Due to coupling of tasks and schedule,
        // We will navigate to the schedule on click of a task or timeoff
        // The schedule handles refetching the tasks, we just fire off a handler
        // updateDates, which changes the range of schedule data
        // TODO Refactor this so it can open tasks on any page.
        const currentLocation = history.location;
        if (currentLocation.pathname !== '/') {
          // Navigate to schedule
          history.push({
            pathname: '/',
            search: history.location.search,
          });
        }
        // Find the task, timeoff or status
        let id;
        if (item.item_type === 'timeoff') {
          id = getTimeoffId(item);
          if (id) model = getTimeoffsMap(state)[id];
        } else if (item.item_type === 'status') {
          id = getStatusId(item);
          if (id) model = getStatuses(state)[id];
        } else {
          id = getTaskId(item);
          if (id) model = getTasksMap(state)[id];
        }

        // If we don't have the task or the task hasn't been processed to have a start_date, we'll load it using share url.
        if (!model || !('start_date' in model && model.start_date)) {
          history.push({
            pathname: '/',
            search: history.location.search,
            hash: `${item.item_type}-${id}`,
          });
          return;
        }
        // We have the task, proceed to open the modal.
        if (item.item_type === 'timeoff') {
          // @ts-expect-error legacy code
          model.isTimeoff = true;
        } else if (item.item_type === 'status') {
          // @ts-expect-error legacy code
          model.isStatus = true;
        }

        if (
          !('people_ids' in model && model.people_ids) &&
          'people_id' in model &&
          model.people_id
        ) {
          const { people_id } = model;
          // @ts-expect-error legacy code
          model.people_ids = isArray(people_id) ? people_id : [people_id];
        }

        const canEdit = determineCanEdit(
          item.item_type,
          ('project_id' in model && model.project_id) as Project['project_id'],
          state,
        );
        editing = item.activity_type !== 'delete' && canEdit;
        // @ts-expect-error legacy code
        model.editing = editing;

        manageModal({
          visible: true,
          modalType: 'taskModal',
          modalSettings: {
            // @ts-expect-error legacy code
            taskOrTimeoff: model,
            fromActivity: true,
          },
        });

        break;
      }

      case 'people': {
        const peopleMap = getPeopleMap(state);
        if (item.item_id) model = peopleMap[item.item_id];

        if (!model) {
          showSnackbar('Person not found.');
          return;
        }
        editing = item.activity_type !== 'delete' && model.canEdit;

        // This is an ugly situation.
        // people needs to be wrapped like this
        // for application logic and markup like this elsewhere:
        // (people) => { const {people} = people; };
        let people = model || item.after_data;
        if (!('people' in people && people.people)) {
          // @ts-expect-error legacy code
          people = { people };
        }

        manageModal({
          visible: true,
          modalType: 'personModal',
          modalSettings: {
            // @ts-expect-error legacy code
            person: item.item_id && peopleMap[item.item_id],
            isEditable: editing,
          },
        });
        break;
      }

      case 'project': {
        if (item.item_id) model = getProjectsMap(state)[item.item_id];

        if (!model) {
          showSnackbar('Project not found.');
          return;
        }

        showProjectSidePanel({
          hideDelete: false,
          hideTasksTab: false,
          projectId: model.project_id,
        });
        break;
      }

      case 'phase': {
        if (item.item_id)
          model = getPhasesMapRaw(state)[item.item_id as number];

        if (!model) {
          showSnackbar('Phase not found.');
          return;
        }

        const project = getProjectsMap(state)[model.project_id];
        // @ts-expect-error legacy code
        model.tags = project?.tags || [];

        manageModal({
          visible: true,
          modalType: 'phaseModal',
          modalSettings: {
            phase: model,
            projectId: model.project_id,
            editing: project?.canEdit,
          },
        });
        break;
      }

      case 'milestones': {
        if (item.item_id)
          model = getMilestonesMap(state)[item.item_id as number];

        if (!model) {
          showSnackbar('Milestone not found');
          return;
        }

        manageModal({
          visible: true,
          modalType: 'milestoneModal',
          modalSettings: {
            // @ts-expect-error legacy code
            milestone: model,
          },
        });
        break;
      }

      // @ts-expect-error legacy code
      case 'holiday':
      // @ts-expect-error legacy code
      // eslint-disable-next-line no-fallthrough
      case 'holidays':
        return window.location.replace('/admin/timeoffs');

      // @ts-expect-error legacy code
      case 'user':
        return window.location.replace('/admin/guests');
      default:
        return console.warn('Unhandled action');
    }
  };

  const onClickMore = () => {
    manageModal({
      visible: true,
      modalType: 'notificationSettingsModal',
    });
  };

  return {
    currentFilter,
    onClickMore,
    onClickNotification,
    onFilterUpdate,
    isFilterVisible: !!user.timeoff_approvals && isPeopleManager,
  };
};
