/**
 * Makes sure that the cursor is placed at the end
 * of the input field when focusing it
 *
 * @param input Input field element
 */

export const focusInput = (input: HTMLInputElement | undefined | null) => {
  if (input) {
    const size = input.value.length;

    input.selectionStart = size;
    input.selectionEnd = size;

    input.focus();
  }
};
