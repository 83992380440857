import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';

import { getUser } from '@float/common/selectors/currentUser';
import { Person } from '@float/types/person';
import { CurrencyInput, Input } from '@float/ui/deprecated';
import { TextToggle } from '@float/ui/deprecated/Earhart/Toggles';
import { TextToggleOption } from '@float/ui/deprecated/Earhart/Toggles/TextToggle';
import { Field } from '@float/ui/primitives/Field/Field';
import { FieldLabel } from '@float/ui/primitives/FieldLabel/FieldLabel';
import { useWebAppSelectorStrict } from '@float/web/lib/store';

import * as styles from './Rate.css';

const isValid = Input.validators.number(6);

type PersonRateValue = Person['default_hourly_rate'];

export type RateProps = {
  value: PersonRateValue;
  onChange: (value: PersonRateValue) => void;
};

export const Rate = (props: RateProps) => {
  const { value = 0, onChange } = props;

  const currentUser = useWebAppSelectorStrict(getUser);

  const [isUsingRoleRate, setIsUsingRoleRate] = useState(false);

  const rateToggleOptions = [
    {
      value: false,
      label: t`Hourly rate`,
    },
    {
      value: true,
      label: t`Role rate`,
    },
  ];

  const handleToggleChange = (option: TextToggleOption<boolean>) => {
    setIsUsingRoleRate(option.value);

    // Setting default_hourly_rate to null so the rate would be inferred from the role
    if (option.value === true) {
      onChange(null);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();

    if (!isValid(value)) {
      return;
    }

    onChange(value);
  };

  return (
    <div className={styles.wrapper}>
      <Field className={styles.fieldToggle}>
        <FieldLabel>
          <Trans>Rate type</Trans>
        </FieldLabel>
        <TextToggle<boolean>
          options={rateToggleOptions}
          value={isUsingRoleRate}
          onChange={handleToggleChange}
        />
      </Field>

      {!isUsingRoleRate && (
        <Field className={styles.fieldRate}>
          <FieldLabel>
            <Trans>New hourly rate</Trans>
          </FieldLabel>
          <CurrencyInput
            currentUser={currentUser}
            value={value}
            onChange={handleInputChange}
          />
        </Field>
      )}
    </div>
  );
};
