import React from 'react';

import { AutoLogTimeFrequency } from '@float/types/autoLogTime';
import Button from '@float/ui/deprecated/Earhart/Buttons/Button';
import InputNumber, {
  InputNumberPatterns,
} from '@float/ui/deprecated/Earhart/Inputs/InputNumber';
import { OnOffToggle } from '@float/ui/deprecated/Earhart/Toggles/OnOffToggle';

import { getIntervalCopy } from '../helpers';
import {
  StyledCard,
  StyledColumn,
  StyledDropdown,
  StyledLabel,
  StyledLink,
  StyledRow,
  StyledRowHeader,
  StyledTitle,
} from '../styles';

type AutoTimeLoggingSettingsCardProps = {
  frequency: AutoLogTimeFrequency;
  interval: number;
  initialInterval: number;
  isActive: boolean;
  isDisabled: boolean;
  isUpdateEnabled: boolean;
  onActiveStateChange: (newActiveState: boolean) => void;
  onFrequencyChange: (newFrequency: { value: AutoLogTimeFrequency }) => void;
  onIntervalChange: (newInterval: number) => void;
  onUpdateClicked: () => void;
};

export const AutoTimeLoggingSettingsCard = ({
  frequency,
  interval,
  initialInterval,
  isActive,
  isDisabled,
  isUpdateEnabled,
  onActiveStateChange,
  onFrequencyChange,
  onIntervalChange,
  onUpdateClicked,
}: AutoTimeLoggingSettingsCardProps) => {
  return (
    <StyledCard>
      <StyledRowHeader disabled={isDisabled || !isActive}>
        <h5>Automatic time logging</h5>

        <OnOffToggle
          isOn={isActive}
          onChange={onActiveStateChange}
          disabled={isDisabled}
        />
      </StyledRowHeader>

      <StyledRow>
        <StyledLabel disabled={isDisabled || !isActive}>
          Select the interval and frequency of when to log suggested time{' '}
          <StyledLink
            disabled={isDisabled || !isActive}
            href="https://support.float.com/en/articles/3616990-time-tracking"
            target="_blank"
          >
            See example
          </StyledLink>
        </StyledLabel>
      </StyledRow>

      <StyledRow>
        <StyledTitle disabled={isDisabled || !isActive}>Frequency</StyledTitle>
      </StyledRow>

      <StyledRow justifyContent="flex-start" alignItems="center" marginTop={12}>
        <StyledColumn>
          <StyledLabel disabled={isDisabled || !isActive}>
            Automatically log suggested time
          </StyledLabel>
        </StyledColumn>

        <StyledColumn>
          <InputNumber
            /*
            // TypeScript is inferring the wrong props
            // @ts-expect-error  */
            alignment="center"
            aria-label="Automatic time logging interval"
            defaultValue={initialInterval}
            maxLength="2"
            min="1"
            max="99"
            disabled={isDisabled || !isActive}
            style={{ width: '2ch' }}
            onChange={onIntervalChange}
            withPattern={InputNumberPatterns.IntegerPositive}
          />
        </StyledColumn>

        <StyledColumn>
          <StyledLabel disabled={isDisabled || !isActive}>
            {getIntervalCopy(interval)}
          </StyledLabel>
        </StyledColumn>

        <StyledColumn>
          <StyledDropdown
            /*
            // TypeScript is inferring the wrong props
            // @ts-expect-error  */
            options={['Week', 'Month']}
            aria-label="Automatic time logging frequency"
            mapToValues={[
              AutoLogTimeFrequency.week,
              AutoLogTimeFrequency.month,
            ]}
            value={frequency}
            onChange={onFrequencyChange}
            disabled={isDisabled || !isActive}
          />
        </StyledColumn>
      </StyledRow>

      <StyledRow>
        {/* @ts-expect-error – Typescript is inferring the wrong props  */}
        <Button
          size="large"
          onClick={onUpdateClicked}
          disabled={!isUpdateEnabled}
        >
          Update
        </Button>
      </StyledRow>
    </StyledCard>
  );
};
