import React from 'react';
import {
  FieldPathValue,
  get,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import * as Popover from '@radix-ui/react-popover';
import useToggle from 'react-use/esm/useToggle';

import DateRangePicker from '@float/common/components/DatePicker/DateRangePicker';
import { moment } from '@float/libs/moment';
import { stopPropagation } from '@float/libs/utils/events/stopPropagation';
import useMedia from '@float/libs/web/hooks/useMedia';
import { TooltipVariants } from '@float/ui/components/Tooltip';
import { PaginationArrow } from '@float/ui/deprecated/DateRangePicker/DateRangePicker';
import { QuickInputTooltip } from '@float/ui/primitives/QuickInput';

import { useDateRange } from '../../hooks/useDateRange';
import { ProjectFormData } from '../../types';
import { FormField, FormFieldPaddingTypes } from '../FormFieldsSection';
import { AnchorDateInfo } from './AnchorDateInfo';
import { DateRangeButtonText } from './DateRangeButtonText';

import * as styles from './styles.css';

export type DateRangeFields =
  | 'project.start_date'
  | 'project.end_date'
  | `phases.${number}.start_date`
  | `phases.${number}.end_date`
  | 'phase.start_date'
  | 'phase.end_date';

export type DateRangeFieldProps<
  S extends DateRangeFields,
  E extends DateRangeFields,
> = {
  start: S;
  end: E;
  label: string;
  disableDayFn?: (date: Moment) => boolean;
  isStartDateSelectable?: (date: Moment) => boolean;
  isEndDateSelectable?: (date: Moment) => boolean;
  isEndDateLoading?: boolean;
  hideLabel?: boolean;
  hideIcon?: boolean;
  disabled?: boolean;
  alignOffset?: number;
  duration?: number | null;
  onAnchorDateChange?: (date: string) => void;
  padding?: FormFieldPaddingTypes;
  'data-testid'?: string;
};

export function DateRangeField<
  S extends DateRangeFields,
  E extends DateRangeFields,
>(props: DateRangeFieldProps<S, E>) {
  const { setValue } = useFormContext<ProjectFormData>();
  const [open, toggle] = useToggle(false);
  const dateRange = useDateRange({
    startKey: props.start,
    endKey: props.end,
  });

  const startDate = useWatch<ProjectFormData, S>({
    name: props.start,
  });
  const endDate = useWatch<ProjectFormData, E>({
    name: props.end,
  });

  const { errors } = useFormState({ name: props.end });
  const hasErrors = Boolean(get(errors, props.end));

  const start = startDate ? moment(startDate) : moment();
  const end = endDate ? moment(endDate) : moment();
  const range = moment.range(start, end);
  const isSettingAnchorDate = props.onAnchorDateChange !== undefined;
  const isMobile = useMedia() === 'small';

  const onSelectStart = (data: Moment) => {
    const newValue = data.format('YYYY-MM-DD');
    setValue(props.start, newValue as FieldPathValue<ProjectFormData, S>, {
      shouldDirty: true,
    });

    if (isSettingAnchorDate) {
      toggle(false);
      props.onAnchorDateChange!(newValue);
      return;
    }

    dateRange.onStartChange(newValue);
  };

  const onSelect = ({ start, end }: { start: Moment; end: Moment }) => {
    onSelectStart(start);
    setValue(
      props.end,
      end.format('YYYY-MM-DD') as FieldPathValue<ProjectFormData, E>,
      {
        shouldDirty: true,
      },
    );
    dateRange.onEndChange(end.format('YYYY-MM-DD'));
    toggle(false);
  };

  return (
    <FormField
      label={props.label}
      className={styles.container}
      hideLabel={props.hideLabel}
      padding={props.padding}
    >
      <Popover.Root open={open} onOpenChange={(open) => toggle(open)} modal>
        <QuickInputTooltip
          open={hasErrors}
          name={props.end}
          variant={TooltipVariants.error}
          placement="left"
        >
          <Popover.Trigger asChild>
            <button
              onClick={stopPropagation}
              className={styles.datePickerButton({
                status: hasErrors ? 'error' : 'default',
              })}
              title={props.label}
              disabled={props.disabled}
              data-testid={props['data-testid']}
            >
              <DateRangeButtonText
                start={startDate}
                end={endDate}
                hideIcon={props.hideIcon}
                duration={props.duration}
                isEndDateLoading={props.isEndDateLoading}
              />
            </button>
          </Popover.Trigger>
        </QuickInputTooltip>
        <Popover.Portal>
          <Popover.Content
            align={isSettingAnchorDate ? 'start' : 'end'}
            alignOffset={props.alignOffset}
            sideOffset={2}
            onOpenAutoFocus={(e) => e.preventDefault()}
            onClick={stopPropagation}
          >
            <div
              className={styles.datePickerContainer({
                mode: isSettingAnchorDate ? 'anchorDate' : 'default',
              })}
              data-testid="date-range-picker"
            >
              {isSettingAnchorDate && <AnchorDateInfo />}
              <DateRangePicker
                firstOfWeek={0}
                numberOfCalendars={isSettingAnchorDate || isMobile ? 1 : 2}
                paginationArrowComponent={PaginationArrow}
                selectionType="range"
                singleDateRange={true}
                value={range}
                onSelect={onSelect}
                onSelectStart={onSelectStart}
                disableDayFn={props.disableDayFn}
                isStartDateSelectable={props.isStartDateSelectable}
                isEndDateSelectable={props.isEndDateSelectable}
                isMobile={isMobile}
              />
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </FormField>
  );
}
