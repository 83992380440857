import { RumEventCallback } from './types';

const EFFECTIVE_TYPE_VALUE = {
  'slow-2g': 1,
  '2g': 2,
  '3g': 3,
  '4g': 4,
} as const;

export type EffectiveType = keyof typeof EFFECTIVE_TYPE_VALUE;

// https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation
export function trackConnectionQuality(callback: RumEventCallback) {
  const effectiveType: EffectiveType =
    // @ts-expect-error the NetworkInformation type doesn't contain effectiveType
    navigator.connection?.effectiveType;

  if (effectiveType) {
    const value = EFFECTIVE_TYPE_VALUE[effectiveType];

    callback({
      name: 'connection',
      value,
      delta: value,
    });
  }
}
