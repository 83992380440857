import React, { StrictMode } from 'react';
import { t, Trans } from '@lingui/macro';

import { type CurrencyConfig } from '@float/common/lib/rates/rates';
import { ConfirmModal } from 'components/modals/ConfirmModal';

import { getConfirmPrimaryMessage } from './ModalConfirmRateChange.helpers';

export type ModalConfirmRateChangeProps = {
  rateFrom?: string | null;
  rateTo?: string | null;
  numPeopleAffected: number;
  currencyConfig: CurrencyConfig;
  onCancel: () => void;
  onConfirm: () => void;
};

export const ModalConfirmRateChange = (props: ModalConfirmRateChangeProps) => {
  const {
    rateFrom,
    rateTo,
    numPeopleAffected,
    currencyConfig,
    onConfirm,
    onCancel,
  } = props;

  const confirmPrimaryMessage = getConfirmPrimaryMessage({
    rateFrom,
    rateTo,
    numPeopleAffected,
    currencyConfig,
  });

  return (
    <StrictMode>
      <ConfirmModal
        title={t`Confirm default hourly Role rate change`}
        message={
          <p>
            {confirmPrimaryMessage}
            <br />
            <br />
            <Trans>
              Role rates do not override rates set at the Person, Project, or
              Phase level.
            </Trans>
          </p>
        }
        confirmLabel={t`Confirm`}
        cancelLabel={t`Cancel`}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    </StrictMode>
  );
};
