import React, { forwardRef, HTMLProps, MouseEvent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import Icons from '@float/ui/deprecated/Icons';

import {
  StyledIconContainer,
  StyledSpinnerContainer,
  StyledTextButton,
} from './styles';

export type TextButtonProps = HTMLProps<HTMLButtonElement> & {
  className?: string;
  appearance?: 'flay-fill' | 'flay-underline' | 'flue-fill' | 'flue-underline';
  state?: 'default' | 'active';
  iconNoScalingStroke?: boolean;
  children: ReactNode;
  icon?: React.FC<{ iconNoScalingStroke?: boolean }>;
  iconRight?: React.FC<{ iconNoScalingStroke?: boolean }>;
  as?: any;
  loader?: { loading: boolean; loaded: boolean } | boolean;
  textRight?: boolean;
  calloutId?: string;

  // as={Link}
  to?: string;

  // as="a"
  href?: string;
};

export const TextButton = forwardRef<HTMLButtonElement, TextButtonProps>(
  (props, ref) => {
    const {
      className = '',
      appearance = 'flay-fill',
      state = 'default',
      disabled = false,
      icon: Icon,
      iconRight: IconRight,
      iconNoScalingStroke = true,
      as,
      loader,
      tabIndex,
      style,
      onClick,
      calloutId,
      ...rest
    } = props;

    const loading = Boolean(
      typeof loader === 'boolean' ? loader === true : loader?.loading,
    );
    const loaded = Boolean(typeof loader !== 'boolean' && loader?.loaded);

    const onClickHandler = (e: MouseEvent<HTMLButtonElement>) => {
      e.currentTarget.blur();

      if (onClick) onClick(e);
    };

    return (
      <StyledTextButton
        $flayFill={appearance === 'flay-fill'}
        $flayUnderline={appearance === 'flay-underline'}
        $flueFill={appearance === 'flue-fill'}
        $flueUnderline={appearance === 'flue-underline'}
        $iconLeft={!!Icon}
        $iconRight={!!IconRight || loading || loaded}
        $disabled={disabled}
        ref={ref}
        className={cx(className, { active: state === 'active' })}
        as={as}
        loader={loader}
        tabIndex={tabIndex}
        disabled={disabled}
        style={style}
        onClick={loader || !onClick ? undefined : onClickHandler}
        data-callout-id={calloutId}
        {...(as === 'a' || as === Link ? rest : { type: 'button' })}
      >
        {Icon && (
          <StyledIconContainer>
            <Icon iconNoScalingStroke={iconNoScalingStroke} />
          </StyledIconContainer>
        )}

        <span>{props.children}</span>

        {loading && (
          <StyledSpinnerContainer>
            <Icons.Spinner />
          </StyledSpinnerContainer>
        )}

        {loaded && (
          <StyledIconContainer>
            <Icons.TickAnimated />
          </StyledIconContainer>
        )}

        {IconRight && (
          <StyledIconContainer>
            <IconRight iconNoScalingStroke={iconNoScalingStroke} />
          </StyledIconContainer>
        )}

        {props.textRight && (
          <StyledIconContainer>{props.textRight}</StyledIconContainer>
        )}
      </StyledTextButton>
    );
  },
);

export default TextButton;
