import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { WebAppGlobalState } from 'types';

import { updateSecurityPrefs } from '@float/common/actions/companyPrefs';

import withOnMount from '../../../decorators/withOnMount';
import { SamlAuthenticationSection } from './component';

const mapStateToProps = (state: WebAppGlobalState) => {
  const hasTimeTracking = state.companyPrefs.time_tracking > 0;
  const hasPlusPack = state.companyPrefs.plus_pack > 0;

  return {
    sso: state.currentUser.companySso,
    companyId: state.currentUser.cid,
    ssoRequired: state.companyPrefs.sso_required,
    hasTimeTracking,
    hasPlusPack,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: (data: {
    sso_required: string;
    saml_endpoint: string;
    issuer_url: string;
    certificate: string;
  }) => {
    const formData = new FormData();
    formData.append('company[sso_required]', data.sso_required);
    formData.append('saml[saml_endpoint]', data.saml_endpoint);
    formData.append('saml[issuer_url]', data.issuer_url);
    formData.append('saml[certificate]', data.certificate);
    dispatch(updateSecurityPrefs(formData) as unknown as AnyAction);
  },
});

const StatefulSamlAuthenticationSection = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOnMount(SamlAuthenticationSection));

export default StatefulSamlAuthenticationSection;
