import {
  AutoLogTimeFrequency,
  AutoLogTimeSettings,
} from '@float/types/autoLogTime';
import {
  LockLoggedTimeFrequency,
  LockLoggedTimeSettings,
} from '@float/types/lockLoggedTime';

export const getAreTimeSettingsValid = (
  autoLogTimeSettings: AutoLogTimeSettings,
  lockLoggedTimeSettings: LockLoggedTimeSettings,
) => {
  const { isActive: autoLogTimeIsActive, frequency: autoLogTimeFrequency } =
    autoLogTimeSettings;
  const {
    isActive: lockLoggedTimeIsActive,
    frequency: lockLoggedTimeIsFrequency,
  } = lockLoggedTimeSettings;

  if (!autoLogTimeIsActive || !lockLoggedTimeIsActive) {
    return true;
  }

  if (
    autoLogTimeFrequency === AutoLogTimeFrequency.month &&
    lockLoggedTimeIsFrequency === LockLoggedTimeFrequency.week
  ) {
    return false;
  }

  return true;
};

export const getIntervalCopy = (interval: number) =>
  `${interval !== 1 ? 'days' : 'day'} after the end of every`;
