import {
  ADD_PERSON_SUCCESS,
  DELETE_PERSON_SUCCESS,
  FETCH_PEOPLE,
  FETCH_PEOPLE_FAILURE,
  FETCH_PEOPLE_SUCCESS,
  UPDATE_PERSON_SUCCESS,
} from '../actions/people';
import {
  DEFAULT_SORT_TABLE,
  getInitialSortableTableState,
  getInitialSortPropsState,
  getInitialStateCreator,
  reducerCreator,
} from './reducerHelpers';

export const lastFetchError = (people) => people.lastFetchError;

export const areBeingFetched = (people) => people.pagesBeingFetched.length > 0;

export const getPeopleByDepartment = (people, department_id) => {
  return people.entities.filter(
    (person) =>
      person.department && person.department.department_id == department_id,
  );
};

const getInitialState = getInitialStateCreator(
  Object.assign(
    {
      sortableTables: {
        [DEFAULT_SORT_TABLE]: getInitialSortableTableState('people_id', 'asc'),
      },
    },
    getInitialSortPropsState(['people_id']),
  ),
);

const people = () =>
  reducerCreator(
    {
      ADD_ENTITY_SUCCESS: ADD_PERSON_SUCCESS,
      DELETE_ENTITY_SUCCESS: DELETE_PERSON_SUCCESS,
      UPDATE_ENTITY_SUCCESS: UPDATE_PERSON_SUCCESS,
      FETCH_ENTITIES: FETCH_PEOPLE,
      FETCH_ENTITIES_SUCCESS: FETCH_PEOPLE_SUCCESS,
      FETCH_ENTITIES_FAILURE: FETCH_PEOPLE_FAILURE,
    },
    'people_id',
    getInitialState,
  );

export default people;
