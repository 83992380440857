import React, { ForwardedRef } from 'react';

export default React.forwardRef(
  (
    {
      size = '24',
      color = '#868D92',
      className = '',
    }: { size?: string; color?: string; className?: string },
    ref: ForwardedRef<SVGSVGElement>,
  ) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        className={`icon-pencil ${className}`}
        focusable={false}
      >
        <path
          fill={color}
          fillRule="evenodd"
          d="M15.313 6a.786.786 0 0 0-.54.234L12.756 8.25l3.002 3 2.018-2.016c.299-.298.299-.829 0-1.054l-1.947-1.946A.733.733 0 0 0 15.313 6zm-3.308 3.07L6 15v3h3.003l5.934-6-2.932-2.93z"
        />
      </svg>
    );
  },
);
