import React from 'react';

import { withUserCanSee } from '@float/web/components/decorators/withUserCanSee';
import { MainSideNav } from '@float/web/components/Nav/SideNav';

import { SideNav } from '../../SideNav';

const UserSideNav = withUserCanSee(SideNav);

export const AccountSettingsNavigation = ({
  shouldShowNavigation,
}: {
  shouldShowNavigation: boolean;
}) => {
  if (shouldShowNavigation) {
    return (
      <>
        {/* @ts-expect-error Component has not been converted to TS */}
        <MainSideNav />
        {/* @ts-expect-error Component has not been converted to TS */}
        <UserSideNav className="sideNav" />
      </>
    );
  }

  return null;
};
