import { msg } from '@lingui/macro';

export const getDaysRemainingText = (
  remainingDays: number,
  withDate: boolean,
) => {
  const messages = {
    balanceNegative: msg`{days} over`,
    balanceNegativeWithDate: msg`{days} over as of <toggle>{date}</toggle>`,
    balancePositive: msg`{days} remaining`,
    balancePositiveWithDate: msg`{days} remaining as of <toggle>{date}</toggle>`,
  };

  const messageType = remainingDays >= 0 ? 'Positive' : 'Negative';

  if (withDate) {
    return messages[`balance${messageType}WithDate`];
  }

  return messages[`balance${messageType}`];
};
