import { BaseEditor, Editor, Range, Transforms } from 'slate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';

import { getLinks } from './get-links';
import { getWordUnderCaret } from './getWordUnderCaret';
import { isLinkActive } from './is-link-active';
import { wrapLink } from './wrap-link';

/**
 * Wrap node in an anchor tag when previous word is an URL
 *
 * @param {KeyboardEvent} e KeyboardEvent object
 * @param {Editor} editor slate editor
 */

const linkifyOnKeyDown = (
  e: React.KeyboardEvent<HTMLInputElement>,
  editor: BaseEditor & ReactEditor & HistoryEditor,
) => {
  if (isLinkActive(editor)) {
    // move outside the link
    if (e.key === ' ') {
      const { selection } = editor;
      const [, end] = Range.edges(selection as Range);
      const [node] = Editor.node(editor, selection as Range);

      const length = 'text' in node ? node.text.length : 0;

      if (end.offset >= length) {
        Transforms.move(editor, { unit: 'offset' });
      }
    }
    return;
  }

  if (e.key !== 'Enter' && e.key !== ' ' && e.key !== ',') {
    return;
  }

  const { selection } = editor;

  if (selection && Range.isCollapsed(selection)) {
    const [start] = Range.edges(selection);

    const [word, wordAnchorOffset] = getWordUnderCaret(
      Editor.string(editor, start.path),
      selection,
    );

    const links = getLinks(word);

    if (!links) {
      return;
    }

    links.forEach((link) => {
      const index = word.indexOf(link.value);
      const lastIndex = index + link.value.length;

      const target = {
        anchor: {
          path: start.path,
          offset: wordAnchorOffset + index,
        },
        focus: {
          path: start.path,
          offset: wordAnchorOffset + lastIndex,
        },
      };

      wrapLink(editor, link, target);
    });
  }
};

export { linkifyOnKeyDown };
