import React, { Suspense, useEffect, useRef } from 'react';
import { Route, Switch } from 'react-router-dom';
import { t } from '@lingui/macro';

import { useAppDispatch, useAppSelector } from '@float/common/store';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { unmountSettingsV2 } from '@float/web/actions/app';
import ActivityWrapper from '@float/web/activity/components';
import withTitle from '@float/web/components/decorators/withTitle';
import withUserCanSee from '@float/web/components/decorators/withUserCanSee';
import KeyboardShortcutModal from '@float/web/components/legacyOnboarding/KeyboardShortcutModal/KeyboardShortcutModal';
import CalendarIntegrationsModal from '@float/web/components/modals/CalendarIntegrationsModal/CalendarIntegrationsModal';
import { ManagedConfirmModal } from '@float/web/components/modals/ConfirmModal';
import MilestoneModal from '@float/web/components/modals/MilestoneModal';
import MyInfoModal from '@float/web/components/modals/MyInfoModal/MyInfoModal';
import ProjectModal from '@float/web/components/modals/ProjectModal';
import PhaseModal from '@float/web/components/modals/ProjectModal/PhaseModal';
import NotificationSettingsModal from '@float/web/components/Nav/NotificationSettingsModal';
import PersonModal from '@float/web/manage/people-v2/modals/PersonModal/PersonModal';
import { TagsV2Container as AccountTagsV2 } from '@float/web/settingsV2/containers/TagsV2/TagsV2.container';
import { PageNotFound } from '@float/web/views/PageNotFound';

import AccountBilling from '../../containers/Billing';
import AccountGuests from '../../containers/Guests';
import { Roles as AccountRoles } from '../../containers/Roles/Roles.container';
import AccountStatus from '../../containers/Status';
import AccountTags from '../../containers/Tags';
import AccountTimeoffs from '../../containers/Timeoffs';
import AccountClients from '../Clients/Clients';
import AccountDepartments from '../Departments/Departments';
import AccountGeneral from '../General';
import AccountIntegrations from '../Integrations';
import AccountNotifications from '../Notifications';
import AccountPreferences from '../Preferences';
import AccountSecurity from '../Security';
import AccountTeamActivity from '../TeamActivity';
import { TimeTracking as AccountTimeTracking } from '../TimeTracking';
import { useShowNavigationState } from './AccountSettings.hooks';
import { StyledContainer, StyledRouteWrapper } from './AccountSettings.styles';
import { AccountSettingsNavigation } from './components/AccountSettingsNavigation';

const UserAccountGeneral = withUserCanSee(AccountGeneral);
const UserAccountNotifications = withUserCanSee(AccountNotifications);

const AccountSettings = (props) => {
  // Hooks
  const reduxDispatch = useAppDispatch();
  const pageBodyRef = useRef();

  const { shouldShowNavigation, hideNavigation } = useShowNavigationState();

  // Selectors

  const shouldRedirect = useAppSelector((state) => {
    const access = state.currentUser && state.currentUser.access;
    return access === 'Observer' || access === 'Member';
  });

  // Props

  const { isLoading, userCanSee } = props;

  // Functions
  const UserAccountIntegrations = withUserCanSee(AccountIntegrations);

  const redirect = () => {
    window.location.replace('/');
  };

  useEffect(() => {
    return () => {
      reduxDispatch(unmountSettingsV2());
    };
  }, [reduxDispatch]);

  useEffect(() => {
    if (shouldRedirect) {
      redirect();
    }
  }, [shouldRedirect]);

  // Render

  return (
    <>
      <MyInfoModal />
      <CalendarIntegrationsModal />
      <NotificationSettingsModal />
      <PersonModal />
      <ProjectModal />
      <MilestoneModal />
      <PhaseModal />
      <KeyboardShortcutModal />
      <ManagedConfirmModal />
      <ActivityWrapper disablePointerEvents={!props.pointerEvents} />

      {isLoading ? null : (
        <StyledContainer>
          <AccountSettingsNavigation
            shouldShowNavigation={shouldShowNavigation}
          />
          <StyledRouteWrapper ref={pageBodyRef}>
            <Switch>
              <Route
                path="/admin/general"
                render={withTitle(t`General | Float`, UserAccountGeneral)}
              />

              <Route
                path="/admin/notifications"
                render={withTitle(
                  t`Notifications | Float`,
                  UserAccountNotifications,
                )}
              />

              <Route
                path="/admin/preferences"
                render={withTitle(
                  t`Hours & currency | Float`,
                  AccountPreferences,
                )}
              />

              <Route
                path="/admin/billing"
                render={withTitle(t`Billing | Float`, AccountBilling)}
              />

              <Route
                path="/admin/api"
                render={withTitle(
                  t`Integrations | Float`,
                  UserAccountIntegrations,
                )}
              />

              <Route
                path="/admin/security"
                render={withTitle(t`Security | Float`, AccountSecurity)}
              />

              <Route
                path="/admin/guests"
                render={withTitle(t`Guests | Float`, AccountGuests)}
              />

              <Route
                path="/admin/timeoffs"
                render={withTitle(t`Time offs | Float`, AccountTimeoffs)}
              />

              <Route
                path="/admin/status"
                render={withTitle(t`Status | Float`, AccountStatus)}
              />

              {userCanSee.adminForTags &&
                !featureFlags.isFeatureEnabled(FeatureFlag.TagGroups) && (
                  <Route
                    path="/admin/tags"
                    render={(routerProps) =>
                      withTitle(
                        t`Tags | Float`,
                        AccountTags,
                      )({
                        ...routerProps,
                        pageBodyRef,
                        userCanSee,
                      })
                    }
                  />
                )}

              {userCanSee.adminForTags &&
                featureFlags.isFeatureEnabled(FeatureFlag.TagGroups) && (
                  <Route
                    path="/admin/tags"
                    render={(routerProps) =>
                      withTitle(
                        t`Tags | Float`,
                        AccountTagsV2,
                      )({
                        ...routerProps,
                        pageBodyRef,
                        userCanSee,
                      })
                    }
                  />
                )}

              <Route
                path="/admin/departments"
                render={(routerProps) =>
                  withTitle(
                    t`Departments | Float`,
                    AccountDepartments,
                  )({
                    ...routerProps,
                    pageBodyRef,
                    userCanSee,
                  })
                }
              />

              <Route
                path="/admin/roles"
                render={withTitle(t`Roles | Float`, AccountRoles)}
              />
              <Route
                path="/admin/clients"
                render={withTitle(t`Clients | Float`, AccountClients)}
              />

              <Route
                path="/admin/activity"
                render={withTitle(t`Activity | Float`, AccountTeamActivity)}
              />

              {/* Note: Always show LockLoggedTime settings for cross/up sell opportunity,
                  but disable features when lock logged time is disabled in company prefs.
              */}
              <Route
                path="/admin/time-tracking"
                render={withTitle(t`Time tracking | Float`, (props) => (
                  <Suspense fallback="">
                    <AccountTimeTracking {...props} />
                  </Suspense>
                ))}
              />
              <Route
                path="*"
                render={() => <PageNotFound onRender={hideNavigation} />}
              />
            </Switch>
          </StyledRouteWrapper>
        </StyledContainer>
      )}
    </>
  );
};

export default withUserCanSee(AccountSettings);
