import 'vest/enforce/isURL';
import 'vest/enforce/compounds';

import { useForm } from 'react-hook-form';
import { vestResolver } from '@hookform/resolvers/vest';
import { t } from '@lingui/macro';
import isNil from 'lodash/isNil';
import { create, enforce, test } from 'vest';

import {
  LinearCyclesIntegrationDeletePayload,
  LinearCyclesIntegrationUpdatePayload,
} from '@float/common/api3/linearCyclesIntegration';
import { LinearCyclesIntegrationConfig } from '@float/types/linearCyclesIntegration';

// @test-export
export const linearIntegrationFormSuite = create((data: LinearFormPayload) => {
  test('feedUrl', t`Feed URL is not formatted correctly`, () => {
    // The the value must be empty (if deleting the integration) or an URL
    enforce(data.feedUrl).anyOf(enforce.isEmpty(), enforce.isURL());
  });
});

const resolver = vestResolver<LinearFormPayload, unknown>(
  linearIntegrationFormSuite,
);

export type LinearFormPayload = {
  feedUrl: string | undefined;
};

export const useLinearIntegrationFormControls = ({
  currentIntegrationConfig,
  upsertConfig,
  deleteConfig,
  syncConfig,
  updateActivationStatus,
}: {
  currentIntegrationConfig: LinearCyclesIntegrationConfig | null | undefined;
  upsertConfig: (payload: LinearCyclesIntegrationUpdatePayload) => void;
  deleteConfig: (payload: LinearCyclesIntegrationDeletePayload) => void;
  syncConfig: (payload: LinearCyclesIntegrationDeletePayload) => void;
  updateActivationStatus: (payload: {
    active: boolean;
    version: number;
  }) => void;
}) => {
  const form = useForm<LinearFormPayload>({
    values: {
      feedUrl: currentIntegrationConfig?.feedUrl ?? '',
    },
    defaultValues: {
      feedUrl: currentIntegrationConfig?.feedUrl ?? '',
    },
    resolver,
    // User will almost always be copy/pasting the value here, so there's
    // no need to wait for blur to validate
    mode: 'onChange',
    reValidateMode: 'onChange',
    delayError: 500,
  });

  const handleToggleClick = () => {
    if (currentIntegrationConfig) {
      updateActivationStatus({
        active: !currentIntegrationConfig.active,
        version: currentIntegrationConfig.version,
      });
    }
  };

  const handleSyncClick = () => {
    const feedUrl = form.getValues('feedUrl');
    const feedUrlHasChanged = feedUrl !== currentIntegrationConfig?.feedUrl;
    const version = currentIntegrationConfig?.version;
    const hasVersion = !isNil(version);

    if (!feedUrl && hasVersion) {
      deleteConfig({
        version,
      });
    } else if (feedUrl && feedUrlHasChanged) {
      upsertConfig({
        feedUrl: feedUrl,
        version,
      });
    } else if (hasVersion) {
      syncConfig({ version });
    }

    form.reset(undefined);
  };

  return { form, handleToggleClick, handleSyncClick };
};
