import React, { HTMLAttributes, PropsWithChildren } from 'react';
import { motion } from 'framer-motion';

export const PopUpSpring = {
  type: 'spring',
  damping: 22,
  stiffness: 300,
} as const;

const initial = { opacity: 0, scale: 0.95 };
const animate = { opacity: 1, scale: 1 };

export const AnimatePopUp = (
  props: PropsWithChildren & HTMLAttributes<HTMLDivElement>,
) => {
  const { children, className } = props;

  return (
    <motion.div
      animate={animate}
      className={className}
      initial={initial}
      transition={PopUpSpring}
    >
      {children}
    </motion.div>
  );
};
