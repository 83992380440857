import { saveAs } from 'file-saver';
import { MomentInput } from 'moment';

import { FRIENDLY_NAMES, TYPE_TO_CATEGORY } from '@float/common/search/helpers';
import {
  BreakdownData,
  ItemData,
} from '@float/common/serena/Data/transformers/types';
import { moment } from '@float/libs/moment';
import { Project } from '@float/types/project';
import { TimeoffType } from '@float/types/timeoffType';
import { BaseFilterToken } from '@float/types/view';

import {
  getClientNameForSchedule,
  getSortedTasks,
  mapTaskNameForSchedule,
} from './ExportCSV.helpers';
import {
  CsvParsedData,
  CsvParsedPeopleData,
  CsvParsedProjectsData,
} from './parseData';

export type ExtraData = {
  companyName: string;
  timeoffTypes: Record<string, TimeoffType>;
  viewBy: string | undefined;
  viewType: 'people' | 'projects';
  projects: Record<number, Project>;
  filters: BaseFilterToken[];
};

function formatDate(date: MomentInput) {
  return moment.utc(date).format('DD MMM YYYY');
}

function getFriendlyName(type: string) {
  const category: keyof typeof FRIENDLY_NAMES = TYPE_TO_CATEGORY[type];

  return FRIENDLY_NAMES[category];
}

function buildHeaderRows(extraData: ExtraData) {
  const { companyName } = extraData;

  const filterRow = extraData.filters.length
    ? `Filters: ${extraData.filters
        .map((f) => {
          const cat = `${getFriendlyName(f.type)}`;
          return `${cat}: ${f.operator || ''}${f.val}`;
        })
        .join(', ')}`
    : `No filters`;

  return [
    [companyName],
    [moment().format('DD MMM YYYY LT')],
    [],
    [filterRow],
    [],
  ];
}

function buildPersonRowHeader(result: CsvParsedPeopleData) {
  return [
    'Name',
    'Role',
    'Department',
    'Task',
    'Project',
    'Client',
    'Time off',
    'Notes',
    ...result.header.dates.map(formatDate),
  ];
}

function buildProjectRowHeader(result: CsvParsedProjectsData) {
  return [
    'Project',
    'Phase',
    'Client',
    'Name',
    'Role',
    'Department',
    'Task',
    'Notes',
    ...result.header.dates.map(formatDate),
  ];
}

function getSortedTimeoffs(entities: ItemData[]) {
  return entities.sort((a, b) => {
    if (a.name === '(All others)') return 1;
    if (b.name === '(All others)') return -1;
    return String(a.name).localeCompare(String(b.name));
  });
}

function buildPersonRow(
  data: BreakdownData,
  result: CsvParsedData,
  extraData: ExtraData,
) {
  const basePersonRow = [
    data.person.name,
    data.person.jobTitle,
    data.person.department,
  ];

  const rows = [];

  getSortedTasks(mapTaskNameForSchedule(Object.values(data.tasks))).forEach(
    (t) => {
      rows.push([
        ...basePersonRow,
        t.name,
        t.project,
        t.client,
        '',
        t.notes,
        ...result.header.dates.map((date) => t.dateData[date] || 0),
      ]);
    },
  );

  getSortedTimeoffs(Object.values(data.timeoffs)).forEach((t) => {
    rows.push([
      ...basePersonRow,
      '',
      '',
      '',
      extraData.timeoffTypes[String(t.name)]
        ? extraData.timeoffTypes[String(t.name)].timeoff_type_name
        : t.name,
      t.notes,
      ...result.header.dates.map((date) => t.dateData[date] || 0),
    ]);
  });

  if (rows.length === 0) {
    rows.push([
      ...basePersonRow,
      '',
      '',
      '',
      '',
      '',
      ...result.header.dates.map((date) => 0),
    ]);
  }

  rows.push([
    'SCHEDULED',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ...result.header.dates.map((date) => data.aggregate[date].total || 0),
  ]);

  rows.push([
    'CAPACITY',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ...result.header.dates.map((date) => data.aggregate[date].capacity || 0),
  ]);

  rows.push([]);

  return rows;
}

function buildProjectRows(
  projectId: number,
  { header, scheduleData }: CsvParsedProjectsData,
) {
  const project = scheduleData.projects[projectId];
  const rows = [];

  project.peopleOrder.forEach((personId) => {
    const person = project.people[personId];

    const tasks = getSortedTasks(
      mapTaskNameForSchedule(Object.values(person.tasks)),
    );

    if (tasks.length) {
      tasks.forEach((t) => {
        rows.push([
          project.name,
          t.phase,
          getClientNameForSchedule(project.client),
          person.name,
          person.jobTitle,
          person.department,
          t.name,
          t.notes,
          ...header.dates.map((date) => t.dateData[date] || 0),
        ]);
      });
    } else {
      rows.push([
        project.name,
        '',
        getClientNameForSchedule(project.client),
        person.name,
        person.jobTitle,
        person.department,
        '',
        '',
        ...header.dates.map((date) => 0),
      ]);
    }
  });

  rows.push([
    '',
    '',
    '',
    'TOTAL',
    '',
    '',
    '',
    '',
    ...header.dates.map((date) => project.totals[date] || 0),
  ]);

  rows.push([]);

  return rows;
}

function buildHolidayRows(result: CsvParsedPeopleData) {
  if (!result.holidays.length) return [];

  const rows = [
    ['Holiday', 'Start Date', 'End Date'],
    ...result.holidays.map((h) => [
      h.name,
      formatDate(h.start_date),
      formatDate(h.end_date),
    ]),
  ];

  return rows;
}

function buildMilestoneRows(
  result: CsvParsedProjectsData,
  extraData: ExtraData,
) {
  if (!result.milestones.length) return [];

  const rows = [
    ['Project', 'Client', 'Milestone', 'Start Date', 'End Date'],
    ...result.milestones.map((h) => {
      const project = extraData.projects[h.project_id];
      return [
        project.project_name,
        project.client_name === 'No Client' ? '' : project.client_name,
        h.name,
        formatDate(h.start_date),
        formatDate(h.end_date),
      ];
    }),
  ];

  return rows;
}

function convertPeople(result: CsvParsedPeopleData, extraData: ExtraData) {
  return [
    ...buildHeaderRows(extraData),
    buildPersonRowHeader(result),
    ...result.scheduleData.flatMap((d) => buildPersonRow(d, result, extraData)),
    ...buildHolidayRows(result),
  ];
}

function convertProjects(result: CsvParsedProjectsData, extraData: ExtraData) {
  const { projectOrder } = result.scheduleData;
  return [
    ...buildHeaderRows(extraData),
    buildProjectRowHeader(result),
    ...projectOrder.flatMap((id) => buildProjectRows(id, result)),
    ...buildMilestoneRows(result, extraData),
  ];
}

function getSheetData(result: CsvParsedData, extraData: ExtraData) {
  const { viewBy, viewType } = extraData;

  const sheetData =
    viewType === 'people'
      ? convertPeople(result as CsvParsedPeopleData, extraData)
      : convertProjects(result as CsvParsedProjectsData, extraData);

  const filename = `float-${viewType}-${moment().format('YYYYMMDD-HHmmss')}-${
    result.header.dates.length
  }${viewBy === 'week' ? 'w' : 'd'}.csv`;

  return { filename, sheetData };
}

export async function convertToCsv(
  result: CsvParsedData,
  extraData: ExtraData,
) {
  return import('xlsx').then((XLSX) => {
    const { filename, sheetData } = getSheetData(result, extraData);

    const ws = XLSX.utils.aoa_to_sheet(sheetData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Float');
    const wbout = XLSX.write(wb, { type: 'array', bookType: 'csv' });

    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), filename);
  });
}
