import { DragItemError } from '@float/common/types/components/DragItem';

export const handleIsItemDraggableError = (isItemDraggable: DragItemError) => {
  if (typeof isItemDraggable === 'object') {
    return isItemDraggable;
  }

  return {
    isDraggable: isItemDraggable,
    message: null,
  };
};
