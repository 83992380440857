import React, { useState } from 'react';
import * as Popover from '@radix-ui/react-popover';

import { CurrentUser, SavedView } from '@float/types';

import { NoViewsScreen } from './components/NoViewsScreen';
import { ViewsErrorScreen } from './components/ViewsErrorScreen';
import { ViewsSelectorLabel } from './components/ViewsSelectorLabel';
import { ViewsSelectorMenu } from './components/ViewsSelectorMenu';
import { useViewsACL } from './hooks/useViewsACL';

export type ViewsSelectorProps = {
  views: SavedView[];
  currentView: SavedView | null;
  user: CurrentUser;
  loading: boolean;
  error: boolean;
  isStarterPlan: boolean;
  onViewSelect: (view: SavedView) => void;
  onEdit: (view: SavedView) => void;
  onPersonalChange: (view: SavedView, personal: boolean) => void;
  onPinChange: (view: SavedView, pinned: boolean) => void;
  onDelete: (view: SavedView) => void;
  onRetry: () => void;
  onAddFilterClick: () => void;
};

export function ViewsSelector({
  views,
  currentView,
  error,
  loading,
  isStarterPlan,
  onPersonalChange,
  onDelete,
  onEdit,
  onRetry,
  onViewSelect,
  onPinChange,
  onAddFilterClick,
  user,
}: ViewsSelectorProps) {
  const [open, setOpen] = useState(false);
  const acl = useViewsACL({ user, views, isStarterPlan });

  function handleOpenChange(open: boolean) {
    setOpen(open);
  }

  function handleViewSelect(view: SavedView | undefined) {
    if (view) onViewSelect(view);
    setOpen(false);
  }

  function handleEdit(view: SavedView) {
    onEdit(view);
    setOpen(false);
  }

  function handlePersonalChange(view: SavedView, personal: boolean) {
    onPersonalChange(view, personal);
  }

  function handleDelete(view: SavedView) {
    onDelete(view);
    setOpen(false);
  }

  function handleAddFilterActionClick() {
    onAddFilterClick();
    setOpen(false);
  }

  return (
    <Popover.Root open={open} onOpenChange={handleOpenChange}>
      <Popover.Trigger asChild>
        <ViewsSelectorLabel
          data-product-tour-id="views-selector"
          view={currentView}
          onPersonalChange={handlePersonalChange}
          onDelete={handleDelete}
          onEdit={handleEdit}
          onPinChange={onPinChange}
          acl={acl}
        />
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content sideOffset={5} align="start" asChild>
          {error ? (
            <ViewsErrorScreen onRetry={onRetry} />
          ) : views.length === 0 && !loading ? (
            <NoViewsScreen onAddFilterClick={handleAddFilterActionClick} />
          ) : (
            <ViewsSelectorMenu
              views={views}
              selected={currentView?.id}
              onViewSelect={handleViewSelect}
              onPersonalChange={handlePersonalChange}
              onDelete={handleDelete}
              onEdit={handleEdit}
              onPinChange={onPinChange}
              onAddFilterClick={handleAddFilterActionClick}
              acl={acl}
              loading={loading}
            />
          )}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
