import React, { ComponentProps } from 'react';

import { getDaysRequestedText } from '@float/common/lib/timeoff/helpers/getDaysRequestedText';

import * as styles from './TimeoffRequestedDays.css';

type Props = ComponentProps<'span'> & {
  isTimeoffRequest: boolean;
  requestedDays: number;
};

export const TimeoffRequestedDays = (props: Props) => {
  const { isTimeoffRequest, requestedDays } = props;

  const text = getDaysRequestedText(requestedDays, isTimeoffRequest);

  return <span className={styles.span}>{text}</span>;
};
