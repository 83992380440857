import React, { useMemo } from 'react';

import PersonAvatar from '@float/common/components/elements/PersonAvatar';
import { getAccounts } from '@float/common/selectors/people';
import { useAppSelector } from '@float/common/store';

export function useProjectOwnerOptions() {
  const accounts = useAppSelector((state) => getAccounts(state));

  const options = useMemo(() => {
    const options = Object.values(accounts).map((a) => ({
      value: a.account_id,
      label: a.name || '',
      icon: <PersonAvatar accountId={a.account_id} readOnly size="xs" />,
    }));

    options.sort((a, b) =>
      a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
    );

    return options;
  }, [accounts]);

  return options;
}
