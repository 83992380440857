import { formatClockTimeAsMs } from '@float/common/lib/time';
import { formatAsDuration } from '@float/common/lib/timer/formatAsDuration';
import { formatMsAsClockTime } from '@float/common/lib/timer/formatMsAsClockTime';
import { TimeFormat } from '@float/types';

function addMinutesToDuration(
  value: string,
  timeFormat: TimeFormat,
  minutesToAdd: number = 1,
  wrap: boolean = false,
  min?: string | undefined,
  max?: string | undefined,
) {
  const maxTimeFormatHours = parseInt(timeFormat, 10);
  const maxTimeFormatHoursInMs = maxTimeFormatHours * 60 * 60 * 1000;

  const msToAdd = minutesToAdd * 60 * 1000;

  const time = formatAsDuration(value, timeFormat);
  const timeInMs = formatClockTimeAsMs(time);

  const minTimeInMs = min ? formatClockTimeAsMs(min) : 0;
  const maxTimeInMs = max ? formatClockTimeAsMs(max) : maxTimeFormatHoursInMs;

  let newTime = timeInMs + msToAdd;

  if (newTime < minTimeInMs) {
    if (wrap) {
      if (minutesToAdd === -1) {
        newTime = maxTimeInMs;
      } else {
        newTime = maxTimeFormatHoursInMs + newTime;
        newTime = Math.min(newTime, maxTimeInMs);
      }
    } else {
      newTime = minTimeInMs;
    }
  } else if (newTime > maxTimeInMs) {
    if (wrap) {
      if (minutesToAdd === 1) {
        newTime = minTimeInMs;
      } else {
        newTime = newTime % maxTimeFormatHoursInMs;
        newTime = Math.max(newTime, minTimeInMs);

        if (newTime > maxTimeInMs) {
          newTime = minTimeInMs;
        }
      }
    } else {
      newTime = maxTimeInMs;
    }
  }

  return formatMsAsClockTime(newTime);
}

export { addMinutesToDuration };
