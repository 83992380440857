import React, { forwardRef, HTMLProps, MouseEvent, ReactNode } from 'react';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { RecipeVariants } from '@vanilla-extract/recipes';

import PersonAvatar from '@float/common/components/elements/PersonAvatar';
import { useTimeFormatPreference } from '@float/common/hooks/useTimeFormatPreference';
import { hexToHslCss } from '@float/libs/colors/conversion/hexToHslCss';
import { noop } from '@float/libs/utils/noop';
import { Account } from '@float/types/account';
import { Notification, NotificationTypes } from '@float/types/notification';
import { TimeAgo } from '@float/ui/primitives/TimeAgo';

import * as styles from './NotificationItem.css';

type Props = {
  ['data-index']?: string;
  accountId: Account['account_id'];
  appearance: NonNullable<RecipeVariants<typeof styles.wrapper>>['appearance'];
  children: ReactNode;
  color: Notification<NotificationTypes>['color'];
  disabled?: boolean;
  onClick?: () => void;
  role?: HTMLProps<HTMLDivElement>['role'];
  seen?: boolean;
  timestamp: number;
};

export const NotificationItem = forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const {
      accountId,
      appearance,
      children,
      color,
      disabled = false,
      onClick = noop,
      seen = true,
      timestamp,
      role,
      'data-index': dataIndex,
    } = props;

    const borderHslColor = hexToHslCss(color);
    const timeFormat = useTimeFormatPreference();

    const onClickHandler = (e: MouseEvent<HTMLDivElement>) => {
      if (!disabled) onClick(e);
    };

    return (
      <div
        ref={ref}
        className={styles.wrapper({ appearance, seen })}
        onClick={onClickHandler}
        role={role}
        data-index={dataIndex}
      >
        <div
          role="presentation"
          title={borderHslColor}
          className={styles.border}
          style={assignInlineVars({ [styles.borderColorHsl]: borderHslColor })}
        />

        <div className={styles.content}>
          <div className={styles.avatar}>
            <PersonAvatar accountId={accountId} size="sm2" readOnly />
          </div>

          <div className={styles.text}>{children}</div>

          <div className={styles.time}>
            <TimeAgo timestamp={timestamp} timeFormat={timeFormat} />
          </div>
        </div>
      </div>
    );
  },
);
