import React, { ReactNode } from 'react';

import { ButtonAction } from '@float/ui/components/ButtonAction';
import {
  DropdownActions,
  ItemProps,
  pushToRight,
} from '@float/ui/deprecated/DropdownActions';
import { IconKebabDots } from '@float/ui/icons/essentials/IconKebabDots';

export type TaskActionMenuProps = {
  content: ReactNode;
  options: Array<{
    label: string;
    appearance: ItemProps['appearance'];
    onSelect: () => void;
  }>;
};

export function TaskActionsMenu(props: TaskActionMenuProps) {
  const { options } = props;

  return (
    <DropdownActions
      side="bottom"
      align="end"
      className={pushToRight}
      content={
        <>
          {options.map((option) => (
            <DropdownActions.Item
              key={option.label}
              appearance={option.appearance || 'default'}
              onClick={option.onSelect}
            >
              {option.label}
            </DropdownActions.Item>
          ))}
        </>
      }
    >
      <ButtonAction icon={IconKebabDots} />
    </DropdownActions>
  );
}
