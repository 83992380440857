import React from 'react';

export default function () {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 11.3181C6 9.66126 7.34315 8.31812 9 8.31812H25C26.6568 8.31812 28 9.66126 28 11.3181V15.3181C28 16.9749 26.6568 18.3181 25 18.3181H9C7.34315 18.3181 6 16.975 6 15.3181V11.3181ZM32 11.3181C32 9.66126 33.3432 8.31812 35 8.31812H47C48.6569 8.31812 50 9.66126 50 11.3181V15.3181C50 16.9749 48.6569 18.3181 47 18.3181H35C33.3432 18.3181 32 16.975 32 15.3181V11.3181ZM13.0001 22.3182C11.3432 22.3182 10.0001 23.6613 10.0001 25.3182V29.3181C10.0001 30.975 11.3432 32.3181 13.0001 32.3181H43C44.6569 32.3181 46 30.975 46 29.3181V25.3182C46 23.6613 44.6569 22.3182 43 22.3182H13.0001ZM6 39.3178C6 37.6609 7.34315 36.3178 9 36.3178H17C18.6568 36.3178 20 37.6609 20 39.3178V43.3178C20 44.9746 18.6568 46.3178 17 46.3178H9C7.34315 46.3178 6 44.9746 6 43.3178V39.3178ZM26.9999 36.3178C25.3431 36.3178 23.9999 37.6609 23.9999 39.3178V43.3178C23.9999 44.9746 25.3431 46.3178 26.9999 46.3178H46.9999C48.6567 46.3178 49.9999 44.9746 49.9999 43.3178V39.3178C49.9999 37.6609 48.6567 36.3178 46.9999 36.3178H26.9999Z"
        fill="#1A90DC"
      />
    </svg>
  );
}
