import React from 'react';
import { t, Trans } from '@lingui/macro';
import { ModalConfig, useManageModal } from 'modalManager/useManageModal';
import { useSidePanel } from 'sidePanel/useSidePanel';

import { updateProjectFromSidePanel } from '@float/common/actions/projects/updateProjectFromSidePanel';
import { useAppDispatchStrict } from '@float/common/store';
import { IconDownload as IconArchive } from '@float/ui/icons/essentials/IconDownload';
import { IconUpload as IconActivate } from '@float/ui/icons/essentials/IconUpload';
import { PanelType } from '@float/web/sidePanel/types';

import { useSidePanelSnackbar } from '../../../../hooks/useSidePanelSnackbar';
import { useProject } from './useProject';

import { confirmBodyInner } from '../../styles.css';

type ProjectActiveToggleProps = {
  projectId: number;
  projectName?: string;
  projectActive: boolean;
  manageModal: (config: ModalConfig) => void;
  afterUpdate: () => void;
};

type ConfirmModalProps = { projectName?: string };

function ConfirmArchiveMessage({ projectName = 'project' }: ConfirmModalProps) {
  return (
    <div className={confirmBodyInner}>
      <strong>
        <Trans>Archiving "{projectName}" will:</Trans>
      </strong>
      <div>
        <div>
          &nbsp;&bull;&nbsp;
          <Trans>Prevent any further time tracking against the project</Trans>
        </div>
        <div>
          &nbsp;&bull;&nbsp;
          <Trans>
            Keep all historical data in a read-only format on the schedule
          </Trans>
        </div>
      </div>
      <p>
        <Trans>Archived projects may be restored from the Projects page.</Trans>
      </p>
    </div>
  );
}

function ConfirmActiveMessage({ projectName = 'project' }: ConfirmModalProps) {
  return (
    <div className={confirmBodyInner}>
      <strong>
        <Trans>Moving "{projectName}" to active will:</Trans>
      </strong>
      <div>
        <div>
          &nbsp;&bull;&nbsp;
          <Trans>Enable time tracking</Trans>
        </div>
        <div>
          &nbsp;&bull;&nbsp;
          <Trans>Make the historical data on the schedule editable</Trans>
        </div>
      </div>
    </div>
  );
}

function useActiveToggle() {
  const dispatch = useAppDispatchStrict();

  async function toggleActive(
    projectId: number,
    active: boolean,
    next?: () => void,
  ) {
    await dispatch(updateProjectFromSidePanel(projectId, { active: !active }));
    if (next) next();
  }

  function confirmThenUpdate(props: ProjectActiveToggleProps) {
    const { projectId, projectName, projectActive, manageModal, afterUpdate } =
      props;
    let title: string;
    let message: React.ReactNode;
    let confirmLabel: string;
    let confirmAppearance: string;

    if (projectActive) {
      title = t`Archive ${projectName}`;
      message = <ConfirmArchiveMessage projectName={projectName} />;
      confirmLabel = t`Archive project`;
      confirmAppearance = 'danger';
    } else {
      title = t`Move project to active`;
      message = <ConfirmActiveMessage projectName={projectName} />;
      confirmLabel = t`Move to active`;
      confirmAppearance = 'primary';
    }

    manageModal({
      visible: true,
      modalType: 'ConfirmModal',
      modalSettings: {
        title,
        message,
        confirmLabel,
        confirmAppearance,
        onConfirm: () => toggleActive(projectId, projectActive, afterUpdate),
      },
    });
  }

  return { confirmThenUpdate };
}

export function useActiveStatusMenuItem() {
  const project = useProject();
  const { confirmThenUpdate } = useActiveToggle();
  const { manageModalFromPanel } = useManageModal();
  const { panelPayload, hideSidePanel, showProjectSidePanel } = useSidePanel(
    PanelType.ProjectPanel,
  );
  const { showSuccessSnackbar } = useSidePanelSnackbar();

  function handleActiveToggle() {
    confirmThenUpdate({
      projectId: project.project_id,
      projectName: project.project_name,
      projectActive: project.active,
      manageModal: manageModalFromPanel,
      afterUpdate: () => {
        const projectName = project.project_name;
        const message = project.active
          ? t`“${projectName}” archived.`
          : t`“${projectName}” activated.`;

        hideSidePanel();

        showSuccessSnackbar(message, {
          onLinkClick: () => {
            showProjectSidePanel(panelPayload);
          },
        });
      },
    });
  }

  const value = project.active ? t`Archive` : t`Move project to active`;

  return {
    value,
    icon: project.active ? IconArchive : IconActivate,
    onClick: handleActiveToggle,
  };
}
