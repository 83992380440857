import { useAppSelectorStrict } from '@float/common/store';
import { RepeatState } from '@float/types/repeatState';
import { selectDatesManager } from '@float/web/selectors';

import {
  getNextRepeatStartDate,
  getNumberOfRepeatTimes,
} from '../EditTaskModal.helpers';
import { AllocationTimeSectionPayload } from '../EditTaskModal.types';

export type UseAllocationRepeatControlsParams = {
  startDate: Date;
  repeatState: RepeatState;
  repeatEndDate: Date | null;
  onChange: (payload: AllocationTimeSectionPayload) => void;
};

export const useAllocationRepeatControls = ({
  startDate,
  repeatState,
  repeatEndDate,
  onChange,
}: UseAllocationRepeatControlsParams) => {
  const dates = useAppSelectorStrict(selectDatesManager);

  const repeatTimes = getNumberOfRepeatTimes(
    startDate,
    repeatEndDate,
    repeatState,
    dates,
  );

  const handleRepeatIntervalChange = (newRepeatState: RepeatState) => {
    const wasRepeating = Boolean(repeatState);
    const isRepeating = Boolean(newRepeatState);

    const hasToRecalculateNewRepeatEndDate = isRepeating && !wasRepeating;

    // If the allocation was not repeating previously, we need to re-calculate
    // the next repeat state
    if (hasToRecalculateNewRepeatEndDate) {
      const nextRepeatStartDate = getNextRepeatStartDate(
        startDate,
        newRepeatState,
        dates,
      );

      onChange({
        repeatState: newRepeatState,
        repeatEndDate: nextRepeatStartDate,
      });
    } else {
      onChange({
        repeatState: newRepeatState,
      });
    }
  };

  const handleRepeatEndDateChange = (repeatEndDate: Moment) => {
    onChange({
      repeatEndDate: repeatEndDate.toDate(),
    });
  };

  return {
    repeatTimes,
    handleRepeatIntervalChange,
    handleRepeatEndDateChange,
  };
};
