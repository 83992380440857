import React from 'react';
import { FormProvider } from 'react-hook-form';

import { getAccurateNameByAccountId } from '@float/common/selectors/people/name';
import { useAppSelectorStrict } from '@float/common/store';

import { LinearIntegrationForm } from './components/LinearIntegrationForm';
import { useLinearIntegrationData } from './hooks/useLinearIntegrationData';
import { useLinearIntegrationFormControls } from './hooks/useLinearIntegrationFormControls';
import { getLastSyncDetails } from './LinearCycleIntegration.helpers';

export const LinearCycleIntegration = () => {
  const {
    deleteConfig,
    integrationConfig,
    isLoading,
    syncConfig,
    updateActivationStatus,
    upsertConfig,
  } = useLinearIntegrationData();

  const userNamesByAccountIds = useAppSelectorStrict(
    getAccurateNameByAccountId,
  );

  const { lastSyncUserName, lastSyncTime } = getLastSyncDetails(
    integrationConfig,
    userNamesByAccountIds,
  );

  const { form, handleToggleClick, handleSyncClick } =
    useLinearIntegrationFormControls({
      currentIntegrationConfig: integrationConfig,
      upsertConfig,
      deleteConfig,
      syncConfig,
      updateActivationStatus,
    });

  return (
    <FormProvider {...form}>
      <LinearIntegrationForm
        hasConfig={Boolean(integrationConfig)}
        isEnabled={Boolean(integrationConfig?.active)}
        isLoading={isLoading}
        isValid={form.formState.isValid}
        lastSyncTime={lastSyncTime}
        lastSyncUserName={lastSyncUserName}
        onSyncClick={handleSyncClick}
        onToggle={handleToggleClick}
      />
    </FormProvider>
  );
};
