import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { moment } from '@float/libs/moment';
import {
  Button,
  DatePicker,
  Input,
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  ModalTitle,
  useSnackbar,
  withConfirm,
} from '@float/ui/deprecated';
import * as Icons from '@float/ui/deprecated/Earhart/Icons';
import { required, useInput } from '@float/ui/deprecated/helpers/formHooks';
import {
  addHoliday,
  deleteHoliday,
  updateHoliday,
} from '@float/web/settingsV2/actions/holidays';

const DateContainer = styled.div`
  display: flex;
  input {
    font-size: 18px !important;
    height: 40px;
  }
`;

function useDate(defaultDate) {
  const [value, setValue] = useState(defaultDate);
  const onChange = (newDate) => setValue(newDate);
  return { value, setValue, onChange };
}

function useValidDatePair(startDate, endDate) {
  useEffect(() => {
    if (startDate.value.toDate() > endDate.value.toDate()) {
      endDate.setValue(startDate.value);
    }
  }, [startDate, endDate]);
}

const defaultDate = () => ({
  name: '',
  date: new Date(),
  end_date: new Date(),
});

function HolidayModal({
  newHoliday,
  holiday,
  dispatchUpdateHoliday,
  dispatchDeleteHoliday,
  confirm,
  close,
}) {
  if (!holiday) {
    holiday = defaultDate();
  }
  const { showSnackbar } = useSnackbar();
  const name = useInput(holiday.name, {
    validate: required('Name is required.'),
  });
  const endDate = useDate(moment(holiday.end_date));
  const startDate = useDate(moment(holiday.date));
  const title = !newHoliday ? 'Edit holiday' : 'Add new holiday';
  useValidDatePair(startDate, endDate);

  function submit() {
    if (!name.validate()) return;
    confirm({
      title,
      message: `Are you sure? Any allocations already scheduled on these dates will be removed.`,
      onCancel: close,
      onConfirm: () => {
        const id = !newHoliday && holiday.holiday_id;
        const data = {
          name: name.value,
          date: startDate.value.format('Y-MM-DD'),
          end_date: endDate.value.format('Y-MM-DD'),
        };
        dispatchUpdateHoliday(data, id)
          .then(() => {
            close();
            showSnackbar(`${data.name} ${id ? 'updated' : 'added'}.`);
          })
          .catch((errors) => {
            const error = errors.length ? errors[0] : errors;
            confirm({
              title,
              message: error.message,
              hideCancel: true,
              onConfirm: () => {},
            });
          });
      },
    });
  }

  return (
    <Modal isOpen={true} onEnter={submit} onClose={close}>
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form noValidate onSubmit={submit}>
          <section className="inputs">
            <Input
              autoFocus
              label="Name *"
              style={{ marginBottom: '20px' }}
              {...name}
            />
            <DateContainer>
              <DatePicker label="Start date" {...startDate} />
              <div style={{ width: '20px' }} />
              <DatePicker
                label="End date"
                minimumDate={startDate.value}
                {...endDate}
              />
            </DateContainer>
          </section>
        </form>
      </ModalBody>
      <ModalActions>
        <Button onClick={submit}>{!newHoliday ? 'Update' : 'Add new'}</Button>
        <Button appearance="secondary" onClick={close}>
          Cancel
        </Button>
        {!newHoliday && (
          <Button
            appearance="clear-danger"
            style={{ marginLeft: 'auto' }}
            iconRight={Icons.IconTrash}
            tabIndex="-1"
            onClick={() =>
              confirm({
                title: 'Delete Holiday',
                message: `Are you sure?`,
                onConfirm: () =>
                  dispatchDeleteHoliday(holiday.holiday_id).then(() => {
                    close();
                    showSnackbar(`${holiday.name} deleted.`);
                  }),
                onCancel: close,
              })
            }
          >
            Delete
          </Button>
        )}
      </ModalActions>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdateHoliday: (data, id) =>
    dispatch(id ? updateHoliday(id, data) : addHoliday(data)),
  dispatchDeleteHoliday: (id) => dispatch(deleteHoliday(id)),
});

export default withConfirm(connect(null, mapDispatchToProps)(HolidayModal));
