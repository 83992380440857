import React from 'react';

export default function () {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.4878 5C38.488 5 39.2988 5.8108 39.2988 6.81098C39.2988 7.7278 38.6175 8.4855 37.7336 8.60542L37.4878 8.62195H36.2805V11.3065C47.8979 13.054 56.8049 23.0783 56.8049 35.183C56.8049 36.0404 56.7602 36.8874 56.673 37.7218C54.9297 35.889 51.7787 34.5 49.1285 34.5C45.2335 34.5 41.3385 37.5 41.3385 40.6912V43.7059C39.6693 43.7059 38 45.3117 38 46.9176V57C38 57.6269 38.1105 58.1802 38.297 58.6674C36.4881 59.1002 34.6001 59.3293 32.6585 59.3293C19.3229 59.3293 8.51221 48.5186 8.51221 35.183C8.51221 23.0783 17.4191 13.054 29.0366 11.3065V8.62195H27.8293C26.8291 8.62195 26.0183 7.81115 26.0183 6.81098C26.0183 5.89415 26.6996 5.13645 27.5836 5.01653L27.8293 5H37.4878ZM21.7195 24.2439C22.3624 23.601 23.3685 23.5425 24.0774 24.0685L24.2806 24.2439L31.7204 31.6837C32.0196 31.6037 32.3341 31.5611 32.6585 31.5611C34.6589 31.5611 36.2805 33.1827 36.2805 35.183C36.2805 37.1834 34.6589 38.805 32.6585 38.805C30.6582 38.805 29.0366 37.1834 29.0366 35.183C29.0366 34.8586 29.0792 34.544 29.1593 34.2448L21.7195 26.805C21.0122 26.0978 21.0122 24.9511 21.7195 24.2439ZM56.2342 42.5976C56.2342 39.4629 53.0074 37 49.7805 37C46.5537 37 43.3269 39.4629 43.3269 42.5976V45.9561C41.944 45.9561 40.561 47.2995 40.561 48.6429V56.4795C40.561 58.0468 41.944 59.3902 43.3269 59.3902H56.0037C57.6171 59.3902 59.0001 58.0468 59.0001 56.7034V48.8668C59.0001 47.2995 57.6171 45.9561 56.2342 45.9561V42.5976ZM46.3232 42.5976C46.3232 40.8063 47.9366 39.6868 49.7805 39.6868C51.6245 39.6868 53.2379 40.8063 53.2379 42.5976V45.9561H46.3232V42.5976Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="11.0367"
          y1="4.6599"
          x2="55.6786"
          y2="59.5906"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7C71EC" />
          <stop offset="1" stopColor="#C17DCE" />
        </linearGradient>
      </defs>
    </svg>
  );
}
