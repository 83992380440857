import React from 'react';

import { Person } from '@float/types';
import { useModalLocal } from '@float/ui/hooks/useModalLocal';
import { withModal } from '@float/web/modalManager/withModal';

import { ModalSwitchPersonConfirm } from './components/ModalSwitchPersonConfirm';
import { ModalSwitchPersonSelect } from './components/ModalSwitchPersonSelect';
import { ModalSwitchPersonProps } from './ModalSwitchPerson.types';

const ModalComponent = (props: ModalSwitchPersonProps) => {
  const {
    manageModal,
    modalProps,
    modalControls,
    modalSettings: { person, project, onSwitch },
  } = props;

  const onClickSwitchPerson = (selectedPersonId: Person['people_id']) => {
    modalConfirm.controls.openModal({ selectedPersonId });
  };

  const onSwitchComplete = () => {
    modalControls.closeModal();
    modalConfirm.controls.closeModal();
  };

  const onModalConfirmationExitCompleted = () => {
    if (!open) {
      modalControls.destroyModal();

      manageModal({
        visible: false,
        modalType: 'ModalSwitchPerson',
      });
    }
  };

  const modalConfirm = useModalLocal<{
    selectedPersonId: Person['people_id'];
  }>({
    onExitComplete: onModalConfirmationExitCompleted,
  });

  // temporarily close modal "switch person change" if modal confirm is open
  const open = Boolean(modalProps.open && !modalConfirm.props.open);

  return (
    <>
      <ModalSwitchPersonSelect
        modalProps={{
          ...modalProps,
          animateBackground:
            !modalConfirm.controls.present && modalControls.present,
          open,
        }}
        person={person}
        project={project}
        onClickSwitchPerson={onClickSwitchPerson}
      />

      {modalConfirm.controls.present && (
        <ModalSwitchPersonConfirm
          modalProps={{
            ...modalConfirm.props,
            animateBackground: !modalProps.open,
          }}
          selectedPersonId={modalConfirm.renderProps?.selectedPersonId}
          person={person}
          project={project}
          onSwitch={onSwitch}
          onSwitchComplete={onSwitchComplete}
        />
      )}
    </>
  );
};

export const ModalSwitchPerson = withModal({
  Comp: ModalComponent,
  modalType: 'ModalSwitchPerson',
});
