import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M17.3 6.8 6.7 17.1m.1-10.3 10.4 10.4"
    />
  </BaseIcon>
);
