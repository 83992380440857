import React from 'react';
import { Plural } from '@lingui/macro';

import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';

import { Placeholders } from '../../constants';
import { ChangePlanTierButtons } from './ChangePlanTierButtons';
import { ExternalLink } from './ExternalLink';
import { HintPrompt } from './HintPromp';
import { PlanCheckIcon } from './PlanCheckIcon';
import { PlanHeader } from './PlanHeader';
import { PricePerSeat } from './PricePerSeat';
import {
  StyledFeatureList,
  StyledFeatureListHeader,
  StyledFeatureListSection,
  StyledPlanSubTitle,
  StyledStarterPlanWrapper,
} from './styles';

import starterIcon from './assets/starterIcon.svg';

export type StarterPlanProps = {
  isActivePlan: boolean;
  isFreeTrial?: boolean;
  isPaidTrial?: boolean;
  isHigherTierThanActivePlan: boolean;
  isLowerTierThanActivePlan: boolean;
  price: string;
  openModal: (modal: string) => void;
};

export const StarterPlan = ({
  isActivePlan,
  isFreeTrial,
  isPaidTrial,
  isHigherTierThanActivePlan,
  isLowerTierThanActivePlan,
  price,
  openModal,
}: StarterPlanProps) => {
  return (
    <StyledStarterPlanWrapper aria-current={isActivePlan}>
      <img src={starterIcon} alt="Starter Plan Icon" />

      <PlanHeader isActivePlan={isActivePlan}>Starter</PlanHeader>

      <StyledPlanSubTitle>Best for smaller teams</StyledPlanSubTitle>

      <PricePerSeat price={price} />

      <ChangePlanTierButtons
        isFreeTrial={isFreeTrial}
        isPaidTrial={isPaidTrial}
        isHigherTierThanActivePlan={isHigherTierThanActivePlan}
        isLowerTierThanActivePlan={isLowerTierThanActivePlan}
        onDowngradeClick={() => openModal('downgrade-starter')}
        onDisableClick={() =>
          openModal(isPaidTrial ? 'disable-paid-pro-trial' : 'disable')
        }
      />

      <StyledFeatureListSection>
        <StyledFeatureListHeader>What you’ll get</StyledFeatureListHeader>
        <StyledFeatureList>
          <li>
            <PlanCheckIcon />
            <span>Unlimited projects</span>
          </li>

          <li>
            <PlanCheckIcon />
            <span>
              Capacity management and <br />
              forecasting
              <HintPrompt content="Time off approval, local public holidays, calendar integrations" />
            </span>
          </li>

          {featureFlags.isFeatureEnabled(FeatureFlag.PlaceholdersPackaging) && (
            <li>
              <PlanCheckIcon />
              <span>
                <Plural
                  value={Placeholders.Starter}
                  one="# Placeholder"
                  other="# Placeholders"
                />
              </span>
            </li>
          )}

          <li>
            <PlanCheckIcon />
            <span>Resource scheduling</span>
          </li>

          <li>
            <PlanCheckIcon />
            <span>Project planning</span>
          </li>

          <li>
            <PlanCheckIcon />
            <span>Mobile app and integrations</span>
          </li>

          <li>
            <PlanCheckIcon />
            <span>24 hour/5 day support</span>
          </li>
        </StyledFeatureList>
        <ExternalLink href="https://www.float.com/pricing">
          View full feature list
        </ExternalLink>
      </StyledFeatureListSection>
    </StyledStarterPlanWrapper>
  );
};
