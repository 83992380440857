import { ReduxState } from '../reducers/lib/types';

export const getLastAllocatedTaskHistory = (state: ReduxState) =>
  state.lastAllocatedTask.history;

export const getLastAllocatedTaskForProject = (
  history: ReduxState['lastAllocatedTask']['history'],
  projectId: number,
  phaseId: number,
  peopleIds: number[],
) => {
  const peopleIdsSet = new Set(peopleIds);

  const entry = history.find(
    (entry) =>
      entry.projectId === projectId &&
      entry.phaseId === phaseId &&
      peopleIdsSet.has(entry.personId),
  );

  return entry ? entry.taskMetaId : null;
};
