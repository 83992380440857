import React, { Fragment, useState } from 'react';
import { t } from '@lingui/macro';
import { compact } from 'lodash';
import { compose } from 'redux';
import {
  getDepartmentOptions,
  getDepartments,
  getSubdepartments,
  getUser,
} from 'selectors';
import {
  getAccounts,
  getDepartmentManagersByPeopleId,
  getDirectManagersByPeopleId,
  getPeopleByAccountId,
  isActiveAndNotSelf,
} from 'selectors/people';
import styled from 'styled-components';

import Avatar from '@float/common/components/Avatar/Avatar';
import { useAppSelector } from '@float/common/store';
import {
  Col,
  EH,
  ModalContentPlaceholder,
  ModalTopGreySection,
  Row,
  Spacer,
  VirtualSelect,
  withConfirm,
} from '@float/ui/deprecated';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import { IconArrowDownRight } from '@float/ui/deprecated/Earhart/Icons';
import Tag from '@float/ui/deprecated/Earhart/Tags/Tag';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

import { useDirectManagedPeople } from './useDirectManagedPeople';

const ListTitle = styled.span`
  ${Typography.Label14.M500};
  color: ${Colors.TCore.Emp.High12};
`;

const PeopleName = styled.span`
  ${Typography.Label14.M500};
  color: ${Colors.TCore.Emp.High12};
`;

const Clickable = styled.div`
  display: inline-flex;
  cursor: pointer;
`;

const PersonRow = styled(Row)`
  padding: 8px;
  background: ${Colors.Primary.Flue.Light[3]};
  border-radius: 6px;
`;

const canViewDepartment = (user, departmentId) => {
  const isAdmin = user.account.account_type === 2;
  const isAccountOwner = user.account.account_type === 1;
  if (isAdmin || isAccountOwner) {
    return true;
  }

  const canViewEveryone = !user.account.department_filter_set;
  if (canViewEveryone) {
    return true;
  }

  const canViewDepartment =
    user.account.department_filter_all.includes(departmentId);
  return canViewDepartment;
};

const canEditManagedDepartment = (user, person, departmentId) => {
  if (!canViewDepartment(user, departmentId)) {
    return false;
  }

  const isAdmin = user.account.account_type === 2;
  const isAccountOwner = user.account.account_type === 1;
  const isBilling = user.account.account_type === 5;
  if (isAdmin || isAccountOwner || isBilling) {
    return true;
  }

  return person.management_group?.departments?.includes(departmentId);
};

const canEditManagedPerson = (user, manager, person) => {
  const isAdmin = user.account.account_type === 2;
  const isAccountOwner = user.account.account_type === 1;
  const isBilling = user.account.account_type === 5;
  if (isAdmin || isAccountOwner || isBilling) {
    return true;
  }

  const personDepartment = person.department_id;
  if (personDepartment) {
    if (canEditManagedDepartment(user, manager, personDepartment)) {
      return true;
    }
  }

  const managesPerson = manager.management_group?.people.includes(
    person.people_id,
  );
  return managesPerson;
};

const PersonPeople = (props) => {
  const user = useAppSelector(getUser);
  const directManagersByPeopleId = useAppSelector(getDirectManagersByPeopleId);
  const subdepartments = useAppSelector(getSubdepartments);
  const departments = useAppSelector(getDepartments);
  const people = useAppSelector((state) => state.people.people);
  const accounts = useAppSelector(getAccounts);
  const peopleByAccountId = useAppSelector(getPeopleByAccountId);
  const departmentOptions = useAppSelector(getDepartmentOptions);

  const departmentManagersByPeopleId = useAppSelector(
    getDepartmentManagersByPeopleId,
  );

  const directManaged = useDirectManagedPeople(props.account, props.peopleId);

  const managed = Object.assign(
    {
      departments: [],
      people: [],
    },
    props.account.management_group,
  );

  const isReadOnly = !props.onChange;

  const updateManaged = (nextGroup) => {
    if (isReadOnly) return;

    props.onChange({
      management_group: {
        ...managed,
        ...nextGroup,
      },
    });
  };

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const showConfirm = ({ message, onConfirm }) => {
    setIsConfirmOpen(true);
    setTimeout(() => {
      props.confirm({
        title: 'Confirm',
        message,
        confirmLabel: 'Proceed',
        cancelLabel: 'Cancel',
        onCancel: () => {
          setIsConfirmOpen(false);
        },
        onConfirm: () => {
          setIsConfirmOpen(false);
          onConfirm();
        },
      });
    });
  };

  const toggleManagedEntity = (option) => {
    const isPerson = !!option.people_id;
    if (isPerson) {
      const isManagedDirectlyByCurrentUser = managed.people.includes(
        option.value,
      );
      if (isManagedDirectlyByCurrentUser) {
        removeManagedItem(option);
      } else {
        const personDirectManager = directManagersByPeopleId[option.value];
        const currentManagerId = personDirectManager?.[0];
        const nextManagerId = props.account.account_id;
        const managerChanged =
          currentManagerId && nextManagerId != currentManagerId;

        if (managerChanged) {
          const nextManagerName =
            props.personName ||
            peopleByAccountId[nextManagerId]?.name ||
            accounts[nextManagerId]?.name;
          const managedPersonName = people[option.value]?.name;
          showConfirm({
            message: nextManagerName
              ? t`${managedPersonName} will now be managed by ${nextManagerName}.`
              : t`${managedPersonName} has already a manager. Do you want to change it?`,
            onConfirm: () => {
              updateManaged({
                people: [...managed.people, option.value],
              });
            },
          });
        } else {
          updateManaged({
            people: [...managed.people, option.value],
          });
        }
      }
    } else {
      const isManagedbyDepartment = managed.departments.includes(option.value);
      if (isManagedbyDepartment) {
        removeManagedItem(option);
      } else {
        const departmentId = option.value;
        const isManagedViaParent =
          directManaged.allDepartments.includes(departmentId);
        if (isManagedViaParent) {
          return;
        }

        updateManaged({
          departments: managed.departments
            .filter((depId) => !subdepartments[departmentId].includes(depId))
            .concat(departmentId),
        });
      }
    }
  };

  const removeManagedItem = (option) => {
    const isPerson = !!option.people_id;
    const entityId = option.people_id || option.id || option.value;
    if (isPerson) {
      const departmentManagers = departmentManagersByPeopleId[entityId] ?? [];
      const hasDepartmentManagers = departmentManagers.length > 0;
      if (hasDepartmentManagers) {
        const managedPerson = people[entityId];
        const nextManagers = departmentManagers.map((accountId) => {
          return peopleByAccountId[accountId] || accounts[accountId];
        });

        // Message will look like:
        // John Doe will now be managed by DMA.
        // John Doe will now be managed by DMA & DMB.
        // John Doe will now be managed by DMA,DM 1, DM 2 & DB.
        return showConfirm({
          message: `
            ${managedPerson.name} will now be managed by ${nextManagers
              .map((manager, i) => {
                const isFirst = i === 0;
                const isLast = i === nextManagers.length - 1;

                if (isFirst) {
                  return manager.name;
                }

                if (isLast) {
                  return ` & ${manager.name}`;
                }

                return `, ${manager.name}`;
              })
              .join('')}.
          `,
          onConfirm: () => {
            updateManaged({
              people: managed.people.filter(
                (peopleId) => peopleId !== entityId,
              ),
            });
          },
        });
      } else {
        return updateManaged({
          people: managed.people.filter((peopleId) => peopleId !== entityId),
        });
      }
    }

    updateManaged({
      departments: managed.departments.filter(
        (departmentId) => departmentId !== entityId,
      ),
    });
  };

  const renderPersonRow = (person, onDelete) => {
    return (
      <PersonRow alignItems="center">
        <Avatar
          name={person.name}
          imageUrl={person.avatar || person.avatar_file}
          readOnly
          size="xs"
        />
        <Spacer xSize={12} />
        <PeopleName>{person.name}</PeopleName>
        {onDelete && (
          <>
            <Spacer axis="x" />
            <Clickable onClick={() => onDelete(person)}>
              <EH.Icons.IconClose />
            </Clickable>
          </>
        )}
      </PersonRow>
    );
  };

  const isEmpty =
    directManaged.departments.length == 0 &&
    directManaged.people.departments.length == 0 &&
    directManaged.people.people.length == 0;

  return (
    <>
      {!isReadOnly && (
        <ModalTopGreySection>
          <VirtualSelect
            label="Manager of"
            noMargin
            shouldHideOnClickOutside={!isConfirmOpen}
            visibleItems={6}
            keepFocusAfterSelect
            placeholder={
              props.onlyDepartments
                ? 'Type and select departments'
                : 'Type and select departments and people'
            }
            groupedOptions={compact([
              {
                name: 'Departments & sub-departments',
                options: departmentOptions
                  .filter((option) => {
                    const departmentId = option.value;
                    const canAdd = canEditManagedDepartment(
                      user,
                      props.account,
                      departmentId,
                    );
                    return canAdd;
                  })
                  .map((option) => {
                    const parentDepartment =
                      departments[option.value]?.parent_id;
                    return {
                      ...option,
                      icon: parentDepartment && (
                        <IconArrowDownRight size="16" />
                      ),
                      indent: true,
                      checked: directManaged.allDepartments.includes(
                        option.value,
                      ),
                    };
                  }),
              },
              !props.onlyDepartments && {
                name: 'People',
                options: Object.values(people).flatMap((person) => {
                  const canDisplayPerson = isActiveAndNotSelf(
                    props.account,
                    person,
                  );
                  if (!canDisplayPerson) {
                    return [];
                  }

                  const isAlreadyManaged =
                    directManaged.people.departments.find(
                      (managedPerson) =>
                        managedPerson.people_id === person.people_id,
                    );
                  if (isAlreadyManaged) {
                    return [];
                  }

                  const canAdd = canEditManagedPerson(
                    user,
                    props.account,
                    person,
                  );
                  if (!canAdd) {
                    return [];
                  }

                  return [
                    {
                      ...person,
                      indent: true,
                      checked: directManaged.people.people.includes(
                        person.people_id,
                      ),
                      // disabled: checkIsManagedViaDepartment(person),
                      selectedLabel: person.name,
                      label: person.name,
                      value: person.people_id,
                      icon: (
                        <Avatar
                          name={person.name}
                          imageUrl={person.avatar_file}
                          readOnly
                          size="xs"
                        />
                      ),
                    },
                  ];
                }),
              },
            ])}
            onChange={toggleManagedEntity}
          />
        </ModalTopGreySection>
      )}
      <ModalContentPlaceholder isCentered={isEmpty}>
        {isEmpty &&
          'The list of people they manage (approving and entering time off)'}
        {directManaged.departments.length > 0 && (
          <Row alignItems="flex-start">
            <Col alignItems="flex-start">
              <Row flexWrap="wrap" style={{ gap: 8 }}>
                <ListTitle>Department</ListTitle>
                {directManaged.departments.map((departmentId) => {
                  const department = departments[departmentId];
                  const canEdit =
                    !isReadOnly &&
                    canEditManagedDepartment(user, props.account, departmentId);
                  const deleteTagProps = canEdit && {
                    icon: EH.Icons.IconClose,
                    clickIconCallback: () => removeManagedItem(department),
                  };

                  return (
                    <Tag
                      key={departmentId}
                      appearance="dynamic"
                      size="large"
                      trailingIcon
                      {...(deleteTagProps || {})}
                    >
                      {department.name}
                    </Tag>
                  );
                })}
              </Row>
              <Spacer size={8} />
              {directManaged.people.departments.map((person, i) => {
                const isFirst = i == 0;
                return (
                  <Fragment key={i}>
                    {!isFirst && <Spacer size={8} />}
                    {renderPersonRow(person)}
                  </Fragment>
                );
              })}
              <Spacer size={16} />
            </Col>
          </Row>
        )}
        {!props.onlyDepartments && directManaged.people.people.length > 0 && (
          <Row alignItems="flex-start">
            <Col alignItems="flex-start">
              <ListTitle>People</ListTitle>
              {directManaged.people.people.map((peopleId) => {
                const person = people[peopleId];
                const canEdit =
                  !isReadOnly &&
                  canEditManagedPerson(user, props.account, person);
                return (
                  <Fragment key={peopleId}>
                    <Spacer size={8} />
                    {renderPersonRow(person, canEdit && removeManagedItem)}
                  </Fragment>
                );
              })}
            </Col>
          </Row>
        )}
      </ModalContentPlaceholder>
    </>
  );
};

export default compose(withConfirm)(PersonPeople);
