import API3 from '@float/common/api3';
import { trackEvent } from '@float/common/lib/intercom';
import request from '@float/common/lib/request';
import { setUserTimeFormatPref } from '@float/common/lib/time';
import { AppDispatchStrict } from '@float/common/store';
import { logger } from '@float/libs/logger';
import { TimeFormatValue } from '@float/types';
import { UserTimeFormatPreference } from '@float/types/timeFormatPreference';

import { USER_PREFS_UPDATE, V3_PREFS } from './constants';

export enum PeopleProjectViewStatus {
  Active = '1',
  All = '2',
  Archived = '0',
}

export const updateUserPrefLocally = (
  key: (typeof V3_PREFS)[number],
  val: unknown,
) => ({
  type: USER_PREFS_UPDATE,
  key,
  val,
});

export const saveUserPref = async (
  key: (typeof V3_PREFS)[number],
  val: unknown,
) => {
  if (V3_PREFS.includes(key)) {
    return API3.saveUserPrefs({ [key]: val });
  }

  // TODO: APA 2019-10-09: Address this todo after after 2020-01-01
  // By now, any V1 prefs left in the system should have surfaced to Rollbar.
  // If this warning doesn't exist, it's safe to remove the V1 stuff from this
  // file.
  logger.warn(`Unexpected V1 pref encountered [${key}]`);

  return request.get(
    'setUserPrefs',
    { ajax: 1, pref: key, val },
    { version: '', hostname: '' },
  );
};

type StronglyTypedUpdateUserPrefKeys =
  | 'duration_display_format'
  | 'me_filter'
  | 'mobile_hide_non_wk_days'
  | 'mobile_sked_view'
  | 'mobile_sked_zoom'
  | 'people_sort_dir'
  | 'people_view'
  | 'sked_view_type'
  | 'time_format_24h'; // Add more keys as needed

type WeaklyTypedUpdateUserPrefKeys = Exclude<
  (typeof V3_PREFS)[number],
  StronglyTypedUpdateUserPrefKeys
>;

type DurationDisplayFormatPreference = {
  label: string;
  value: TimeFormatValue;
};

type StronglyTypedUpdateUserPrefArguments =
  | ['duration_display_format', DurationDisplayFormatPreference]
  | ['me_filter', boolean]
  | ['mobile_hide_non_wk_days', 0 | 1]
  | ['mobile_sked_view', 0 | 1 | 2]
  | ['mobile_sked_zoom', 0 | 1]
  | ['people_sort_dir', 'asc' | 'desc']
  | ['people_view', string]
  | ['proj_view', PeopleProjectViewStatus]
  | ['sked_view_type', 'people' | 'projects']
  | ['time_format_24h', UserTimeFormatPreference]
  | ['project_from_scratch', boolean]; // Add more key-value pairs as needed
type WeaklyTypedUpdateUserPrefArguments = [
  WeaklyTypedUpdateUserPrefKeys,
  { value: unknown; label: string } | '',
];

export type UpdateUserPrefArguments =
  | StronglyTypedUpdateUserPrefArguments
  | WeaklyTypedUpdateUserPrefArguments;

// `updateUserPref` handles pretty heterogeneous data, and it would probably make sense
// to refactor it to accept a single argument like { key, data } to simplify the typings
// once the rest of the code is converted to TS.

export const updateUserPref = (...args: UpdateUserPrefArguments) => {
  return async (dispatch: AppDispatchStrict) => {
    const [key, val] = args;
    let apiPayload: unknown = val;

    if (key === 'time_format_24h') {
      setUserTimeFormatPref(val);

      // Time format update only supports `0 | 1 | 2` as a payload
      // but we're passing in `{ label, value }` to `updateUserPref`
      apiPayload = val.value;
    }

    dispatch(updateUserPrefLocally(key, val));

    await saveUserPref(key, apiPayload);

    if (key === 'project_from_scratch') {
      const message = val
        ? 'Always Create From Scratch'
        : 'Undo Always Create From Scratch';

      trackEvent(message);
    }
  };
};
