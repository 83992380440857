import React from 'react';
import cn from 'classnames';

import { noop } from '@float/libs/utils/noop';
import { IconCheck, IconCloseLarge } from '@float/ui/deprecated/Earhart/Icons';
import IconSpinner from '@float/ui/deprecated/Icons/icon-spinner';

import * as styles from './styles.css';

export interface ITimerEntryRowEditActions {
  className?: string;
  isEditing?: boolean;
  isSubmitting?: boolean;
  errorMessage?: string | undefined;
  onClickConfirm?: () => void;
  onClickCancel?: () => void;
}

const TimerEntryRowEditActions = (props: ITimerEntryRowEditActions) => {
  const {
    className,
    isEditing = false,
    isSubmitting = false,
    onClickConfirm = noop,
    onClickCancel = noop,
  } = props;

  const errorMessage = '';

  const onClickConfirmHandler = (
    e: React.SyntheticEvent<HTMLButtonElement>,
  ) => {
    e.currentTarget.blur();

    onClickConfirm();
  };

  const onClickCancelHandler = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.currentTarget.blur();

    onClickCancel();
  };

  return (
    <div className={cn(className, styles.container)}>
      <div className={styles.backgroundActive} />

      <div className={styles.iconsGroup}>
        <button
          className={styles.button}
          tabIndex={isEditing ? 0 : -1}
          onClick={onClickConfirmHandler}
        >
          {isSubmitting ? (
            <IconSpinner
              className={cn(styles.icon, styles.spinner)}
              size="16"
            />
          ) : (
            <IconCheck className={styles.icon} />
          )}
        </button>

        <button
          className={styles.button}
          tabIndex={isEditing ? 0 : -1}
          onClick={onClickCancelHandler}
        >
          <IconCloseLarge className={styles.icon} />
        </button>
      </div>

      {errorMessage && <div className={styles.errors}>{errorMessage}</div>}
    </div>
  );
};

export { TimerEntryRowEditActions };
