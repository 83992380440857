import { isPlainObject } from 'lodash';

export const tokenIsRefreshing = (params) => {
  if (!params) params = {};
  if (typeof params.body === 'string') {
    return params.body.includes('refresh_token');
  } else if (isPlainObject(params.body)) {
    return params.body.refresh_token;
  }
  return false;
};
