// HACK: Items are to be grouped by project id, and the groups need to be collapsible.
// react-virtualized does a poor job of collapsing too many rows of dynamic heights.
// (CellMeasurer causes far too many scrollToIndex state updates & the stack overflows).
// Treating "collapsing" behavior as search instead leads to much better performance.
// So effectively collapsing a project is treated as excluding items with that projectId
// from results. Here's the ugly part - in order to render the group header, we let
// the first item per project remain in the results, which gets hidden while rendering.
// This keeps rendering relatively performant and the sorting logic clean.

import { isEmpty } from 'lodash';

function getGroupFilter(collapsedGroups) {
  let groupFilter = () => true;

  const includedProjectGroups = {};
  const excludeProjectIds = Object.keys(collapsedGroups).map((x) => +x);
  if (excludeProjectIds.length) {
    groupFilter = (x) => {
      // Let first item per project remain in results.
      // Read "HACK" comment above for details.
      const isNewProject = !includedProjectGroups[x.projectId];
      includedProjectGroups[x.projectId] = true;
      return isNewProject || !excludeProjectIds.includes(x.projectId);
    };
  }

  return groupFilter;
}

export default function filterSidebarItems({ items, filter, collapsedGroups }) {
  const keyVals = Object.values(filter).filter((o) => !isEmpty(o));
  if (!keyVals.length && !Object.keys(collapsedGroups).length) {
    return items;
  }

  const groupFilter = getGroupFilter(collapsedGroups);
  if (!keyVals.length) {
    return items.filter(groupFilter);
  }

  // first filter by search criteria
  const filteredItems = items.filter((item) =>
    keyVals.every(({ key, value }) => {
      if (key === 'myProjects') {
        return item.myProject;
      }
      if (key === 'assignedToMe') {
        return item.assignedToMe;
      }

      if (key === 'contains') {
        const lowerValue = value.toLowerCase();
        return [
          item.projectName,
          item.personName,
          item.searchableName,
          item.epic,
        ].some((text) => (text || '').toLowerCase().includes(lowerValue));
      }

      if (typeof value === 'object') {
        return Object.entries(value).every(([operator, opValue]) => {
          switch (operator) {
            case '$exists':
              return Boolean(item[key]) === opValue;
            case '$regex':
              return new RegExp(...opValue).test(item[key]);
            default:
              return true;
          }
        });
      }

      return item[key] && item[key].toLowerCase() === value.toLowerCase();
    }),
  );

  // then filter by collapsed groups
  return filteredItems.filter(groupFilter);
}
