import { fastDictionary } from './fast-object-spread';

// The keyBy method keys the collection by the given key.
// The returned collection is a fastDictionary, a prototypeless Object
// which has faster writes when used as a map with many keys.
export const keyBy = <V extends object, K extends keyof V>(
  values: V[],
  key: K,
) => {
  const result = fastDictionary() as Record<
    V[K] extends number ? number : string,
    V
  >;

  for (const value of values) {
    // @ts-expect-error non-string values are automatically casted to string
    // since this code is performance sensitve we turn off the typecheck instead
    // of using explicit casting
    result[value[key]] = value;
  }

  return result;
};

// Filter an array and return a keyed record
// Useful for performance sensitive code paths where we want to avoid
// allocating an intermediate array
export const filterAndKeyBy = <
  V extends object,
  K extends keyof V,
  R extends V = V,
>(
  values: V[],
  key: K,
  predicate: ((value: V) => value is R) | ((value: V) => boolean),
) => {
  const result = fastDictionary() as Record<
    V[K] extends number ? number : string,
    R
  >;

  for (const value of values) {
    if (predicate(value)) {
      // @ts-expect-error non-string values are automatically casted to string
      // since this code is performance sensitve we turn off the typecheck instead
      // of using explicit casting
      result[value[key]] = value;
    }
  }

  return result;
};
