import styled from 'styled-components';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

export const StyledNav = styled.div`
  position: relative;

  display: flex;
  justify-content: flex-end;

  margin: 0 8px 8px;

  box-sizing: border-box;
`;

export const StyledPagination = styled.div`
  ${Typography.Label13.M500};

  position: absolute;

  width: 100%;
  height: 100%;

  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  color: ${Colors.FIN.Lt.Emphasis.Medium};

  pointer-events: none;

  p {
    pointer-events: auto;
  }
`;
