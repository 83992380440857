import { datesCreator } from '@floatschedule/js-common-npm/dist/lib/dates';

import { DateCreatorConfig } from '@float/types';

let cache: DateCreatorConfig | null = null;

export function datesCreatorWithGlobalCache(config: DateCreatorConfig) {
  const { mondayStart = false } = config;

  if (!cache || cache.mondayStart !== mondayStart) {
    cache = {
      mondayStart,
      numToDate: [],
      dateToParts: {},
    };
  }

  return datesCreator({
    ...config,
    ...cache,
  });
}
