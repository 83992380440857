const STORAGE_KEY = 'debugTaskNames';

let enabled: boolean;

export const getIsDebugTaskNamesEnabled = () => {
  // OPTIMIZATION: we read from localStorage once to avoid
  // possible performance issues related to reading on an hard disk
  if (enabled === undefined) {
    enabled = Boolean(localStorage.getItem(STORAGE_KEY));
  }

  return enabled;
};

export const setDebugTaskNames = (value: boolean) => {
  enabled = value;
  localStorage.setItem(STORAGE_KEY, value ? 'true' : '');
};
