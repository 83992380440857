import React, { forwardRef } from 'react';
import cn from 'classnames';

import { SVGIcon, SVGIconComponentProps } from '../../primitives/SVGIcon';

import * as styles from './IconSpinner.css';

type IconSpinnerProps = SVGIconComponentProps & {
  animated?: boolean;
};

export const IconSpinner = forwardRef<SVGSVGElement, IconSpinnerProps>(
  (props, ref) => {
    const { className, animated, ...otherProps } = props;

    return (
      <SVGIcon
        ref={ref}
        className={cn({ [styles.animated]: animated }, className)}
        size="24"
        viewBox="0 0 24 24"
        {...otherProps}
      >
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeWidth="1.5"
          d="M19.25 12a7.25 7.25 0 1 0-3.365 6.121"
        />
      </SVGIcon>
    );
  },
);
