import { FieldValues } from 'react-hook-form';
import { t } from '@lingui/macro';

import { ValidationRules } from './QuickInput.types';

const DEFAULT_MAX_LENGTH = 255;

export const getStandardValidationRules = <F extends FieldValues>({
  disabled,
  required,
  maxLength = DEFAULT_MAX_LENGTH,
  minLength,
  additionalRules,
}: {
  disabled?: boolean | undefined;
  required?: boolean | undefined;
  maxLength?: number | undefined;
  minLength?: number | undefined;
  additionalRules?: ValidationRules<F> | undefined;
}): ValidationRules<F> => ({
  disabled,
  required: required ? { value: true, message: t`Cannot be empty` } : undefined,
  maxLength: { value: maxLength, message: t`${maxLength} characters max` },
  minLength: minLength
    ? { value: minLength, message: t`Minimum ${minLength} characters` }
    : undefined,
  ...additionalRules,
});
