import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { FIN } from '@float/theme';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';
import { respondTo } from '@float/ui/deprecated/Theme/helpers/responsive/respondTo';

import Checkbox from '../Checkbox/Checkbox';
import DropdownSelect from '../DropdownSelect/DropdownSelect';

const { StyledDropdownOptions, StyledDropdownSelect, StyledDropdownLabel } =
  DropdownSelect._styles;
const { CheckboxLabel } = Checkbox._styles;

const sticky = css`
  position: sticky;
  z-index: 2;
`;

const mobileFlex = css`
  ${respondTo('small')(css`
    padding: 0 20px !important;
    width: 100%;
    left: 0;
    align-items: flex-start;
    justify-content: center;
  `)}
`;

export const HoverLinks = styled.div`
  display: flex;
  background: ${({ theme }) => theme.flayLight6};
  padding: 2px;
  border-radius: 8px;

  ${respondTo('small')(css`
    height: 24px;
    position: absolute;
    right: 20px;
    bottom: 15px;
    top: auto;
    transform: none;
    visibility: visible;
  `)}
`;

export const HoverLinkIcon = styled.span`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;

  .fill {
    fill: ${({ theme }) => theme.emphasisMedium};
  }
  .stroke {
    stroke: ${({ theme }) => theme.emphasisMedium};
  }

  &.disabled {
    cursor: not-allowed;

    .fill {
      fill: ${({ theme }) => theme.emphasisLow};
    }
    .stroke {
      stroke: ${({ theme }) => theme.emphasisLow};
    }
  }

  a {
    display: flex;
    align-items: center;
  }

  &,
  a {
    color: ${(p) => p.theme.blueGrey};
  }

  &:not(.disabled):hover {
    border-radius: 6px;
    background: ${({ theme }) => theme.flayLight7};

    .fill {
      fill: ${({ theme }) => theme.emphasisHigh};
    }
    .stroke {
      stroke: ${({ theme }) => theme.emphasisHigh};
    }
  }

  ${respondTo('small')(css`
    &.project,
    &.schedule {
      height: auto;
      width: 85px;
      border-right: 0;
    }
  `)}
`;

type TextColorProps = {
  ellipsis?: boolean;
  $primary?: boolean;
  disabled?: boolean;
};

const textColor = css<TextColorProps>`
  ${({ ellipsis = true }) =>
    ellipsis &&
    `
    overflow-x: hidden;
    text-overflow: ellipsis;
  `}

  ${({ $primary }) =>
    $primary
      ? `${Typography.Label13.M500};
    color: ${Colors.FIN.Lt.Emphasis.High};
    svg {
      --svg-icon-color: ${FIN.Color.Icon.Secondary};
    }
    `
      : `
    ${Typography.Label13.R400};
    color: ${Colors.FIN.Lt.Emphasis.Medium};
    svg {
      --svg-icon-color: ${FIN.Color.Icon.Secondary};
    }
    `};

  ${({ disabled }) => disabled && `color: ${Colors.FIN.Lt.Emphasis.Disabled};`};
`;

export const Text = styled.div`
  white-space: nowrap;
  ${textColor}
`;

export const HoverLink = styled(Link)`
  display: inline-flex;
  align-items: center;

  padding-top: 1px;
  ${textColor}

  overflow-y: hidden;

  &[href]:hover {
    border-bottom: 1px solid;
    border-color: ${({ $primary, disabled }) =>
      $primary
        ? Typography.Label14.M500
        : disabled
          ? Colors.FIN.Lt.Emphasis.Disabled
          : Colors.FIN.Lt.Emphasis.Medium};

    // compensate for border-bottom to ensure text isn't jumping when it's hovered.
    margin-bottom: -1px;
  }
`;

export const Table = styled.div`
  width: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: calc(60px + var(--app-margin-top, 0px));
  left: 60px;
  bottom: 0;

  &,
  & * {
    box-sizing: border-box;
  }

  ${(p) => p.theme.antialias}

  ${respondTo('small')(css`
    width: 100%;
    left: 0px;
  `)}

  @media print {
    position: static;
  }
`;

const cellStyles = css`
  height: 100%;
  width: 20%;
  display: flex;
  align-items: center;
  margin-right: 15px;
  padding-left: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.2px;
  text-align: left;
  color: ${({ theme }) => theme.emphasisMedium};

  ${respondTo('small')(css`
    width: 100% !important;
  `)}
`;

export const Cell = styled.div`
  ${cellStyles}
`;

const HeaderCellEmphasisHigh = css`
  color: ${({ theme }) => theme.emphasisHigh};
  svg {
    .stroke {
      stroke: ${({ theme }) => theme.emphasisHigh};
    }
    .fill {
      fill: ${({ theme }) => theme.emphasisHigh};
    }
  }
`;

type HeaderCellProps = {
  isSortedBy: boolean;
};
export const HeaderCell = styled.div<HeaderCellProps>`
  ${cellStyles}
  color: ${({ theme }) => theme.emphasisMedium};
  ${({ isSortedBy }) => isSortedBy && HeaderCellEmphasisHigh}
  font-weight: 600;

  // In some cases, the sort icon overflows a few pixel into the next header cell
  // we want to ensure the sort icon is not cropped
  overflow: visible;
`;

export const HeaderCellLabelWrapper = styled.div<{ cursorNotAllowed: boolean }>`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  [data-callout-id] {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    pointer-events: none;
  }

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
      &:hover {
        ${HeaderCellEmphasisHigh};
      }
    `}

  ${({ cursorNotAllowed }) =>
    cursorNotAllowed &&
    css`
      cursor: not-allowed;
    `}
`;

export const Tags = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .more-tags-link {
    outline: none !important;
  }
`;

export const TagsContainer = styled.div`
  position: relative;

  display: flex;

  span + span {
    margin-left: 4px;
  }
`;

export const MoreTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  align-items: center;

  > span:not([style*='display: none']) + span {
    margin-left: 4px;
  }
`;

export const RowInner = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;

  .outline {
    outline-color: ${Colors.FIN.Lt.Surface.Surf1};
  }

  &.odd {
    background: ${Colors.FIN.Lt.Surface.Surf2};
    .outline {
      outline-color: ${Colors.FIN.Lt.Surface.Surf2};
    }
  }

  &:hover {
    ${({ onClick }) =>
      onClick &&
      `
      background: ${Colors.FIN.Lt.Surface.Surf4} !important;
      .outline {
        outline-color: ${Colors.FIN.Lt.Surface.Surf4};
      }
    `}
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  ${(p) => (typeof p.onClick === 'function' ? 'cursor: pointer;' : '')}

  ${HoverLinks} {
    display: none;
  }

  &:hover {
    ${HoverLinks} {
      display: flex;
    }
  }

  ${respondTo('small')(css`
    ${RowInner} {
      padding-left: 17px;
      padding-right: 100px;

      .people-section & {
        padding-left: 90px;
      }

      padding-top: 12px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      ${Cell} {
        height: 20px;
      }
    }
  `)}
`;

export const RowBackground = styled.div`
  position: absolute;
  z-index: 1;
  top: -4px;
  width: 100%;
  height: 100%;

  &.odd {
    /* background: ${({ theme }) => theme.surface2}; */
  }
`;

export const RowColorIndicator = styled.div`
  position: relative;
  top: 0px;
  z-index: 3;
  margin-right: -4px;
  width: 4px;
  height: 100%;
  border-radius: 4px;
  background: ${({ color }) => color};
`;

export const TableBodyWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
  overflow: auto;
`;

export const TableBody = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 33px;

  .ReactVirtualized__Grid {
    margin-bottom: 40px;
  }

  ${respondTo('small')(css`
    width: 100%;
    margin-left: 0;
    padding: 0 0px;
  `)}

  @media print {
    & > div,
    .ReactVirtualized__List,
    .ReactVirtualized__Grid__innerScrollContainer {
      width: 100% !important;
      max-width: none !important;
    }
  }
`;

export const TableHeader = styled(TableBody)`
  top: 0;
  height: auto;
  ${sticky}

  ${respondTo(
    'large',
    '<',
  )(css`
    padding: 0px 33px;
  `)}

  ${Row} {
    background-color: ${({ theme }) => theme.surface1};
  }
`;

export const TableColumnsShadow = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 20px;
  opacity: ${(p: { $visible: boolean }) => (p.$visible ? 1 : 0)};

  transition: opacity 0.15s ease-in-out;

  overflow: hidden;

  pointer-events: none;

  &:before {
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    height: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;
    content: '';
  }
`;

export const TableColumns = styled(Row)`
  position: relative;

  border: 0;

  .icon-chevron-down-sm path {
    stroke: ${(p) => p.theme.charcoalGrey};
  }

  &.no-select {
    ${HeaderCell}:first-child {
      margin-right: 25px;
    }
  }

  ${mobileFlex}
`;

export const TableActions = styled(TableColumns)`
  justify-content: space-between;
  padding: 16px 0 12px 0;

  ${CheckboxLabel} {
    color: ${Colors.FIN.Lt.Emphasis.Medium};

    &.selected {
      color: ${Colors.FIN.Lt.Emphasis.High};
    }
  }

  ${respondTo('small')(css`
    padding: 0 0 4px !important;
    left: auto;
    width: 100%;
    height: auto;

    ${StyledDropdownSelect}, ${StyledDropdownLabel} {
      width: 100%;
      height: 44px;
    }

    ${StyledDropdownLabel} {
      padding: 0px 20px;
      div {
        padding: 0px;
      }
    }

    ${StyledDropdownOptions} {
      left: 66px;
    }

    button.desktop {
      display: none;
    }

    .sort button {
      display: block;
    }
  `)}

  @media print {
    display: none;
  }
`;

export const TableActionsInner = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  &.selected-actions {
    margin-right: auto;
    margin-left: 22px;
    display: flex;
    justify-content: flex-start;
  }

  &.unselected-actions {
    margin-left: auto;
  }

  ${respondTo('small')(css`
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 4px;

    &.unselected-actions {
      display: flex !important;
    }
  `)}
`;

export const Group = styled(Row)`
  justify-content: flex-start;
  border-left: 0;
  border-right: 0;

  ${mobileFlex}

  ${Cell}:only-child {
    width: 100%;
  }
`;

export const GroupByTitle = styled.div`
  ${Typography.Label14.SB600};
  color: ${Colors.FIN.Lt.Emphasis.High};
  margin-left: 10px;
`;

export const Action = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  [data-tippy-root] {
    transform: translate3d(40px, 50px, 0px) !important;
  }

  ${respondTo('small')(// Incorrect types in styled-components
  // @ts-expect-error
  css`
    width: 100%;
    padding: 0 20px;
    height: 44px;
    align-items: center;
    display: flex;
    border-bottom: 1px solid ${(p) => p.theme.grey};

    &:last-of-type {
      border-bottom: 0;
    }
  `)}
`;

export const ActionLink = styled.span`
  ${Typography.TextL.M500};
  color: ${Colors.FIN.Lt.Emphasis.Primary};
  border-bottom: 1px dashed;
  display: flex;
  align-items: center;
  outline: none;
  min-height: 28px;

  .stroke {
    stroke: ${Colors.FIN.Lt.Emphasis.Primary};
  }

  &:hover {
    color: ${Colors.Primary.Flue.Light[11]};

    .stroke {
      stroke: ${Colors.Primary.Flue.Light[11]};
    }
  }

  &:active {
    color: ${Colors.Primary.Flue.Light[12]};

    .stroke {
      stroke: ${Colors.Primary.Flue.Light[12]};
    }
  }
`;

export const Label = styled.span`
  color: ${(p) => p.theme.blueGrey};
  margin-right: 4px;
`;

export const Value = styled.span``;

export const OptionsContainer = styled.div`
  display: flex;
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  width: 175px;
`;

export const EmptyCell = styled.span`
  color: ${({ disabled }: { disabled?: boolean }) =>
    disabled ? Colors.FIN.Lt.Emphasis.Disabled : Colors.FIN.Lt.Emphasis.Medium};
`;

export const Footer = styled.div`
  position: absolute;
  top: 50px;
`;

export const ActionsGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TableCountLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  margin-left: 18px;
  color: ${({ theme }) => theme.emphasisHigh};
`;
