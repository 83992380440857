import React from 'react';
import { Trans } from '@lingui/macro';
import cs from 'classnames';

import * as styles from './BudgetLabel.css';

export const BudgetLabelNotUtilizedWarning = () => {
  return (
    <label className={cs(styles.budgetLabel, styles.budgetLabelCritical)}>
      <Trans>Budget is not utilized by non-billable allocations</Trans>
    </label>
  );
};
