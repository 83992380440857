import {
  NumberFormatOptions,
  NumberFormatter,
} from '@internationalized/number';

import { config } from '@float/libs/config';
import { CurrentUser } from '@float/types/account';
import { CurrencyProps } from '@float/types/companyPreferences';

import { NO_CURRENCY_ISO_CODE } from '../api3/currencies';

const USD_CONFIG: NumberFormatOptions = {
  currency: 'USD',
  style: 'currency',
};

export const formatCurrency = (
  amount: number,
  locale: string | string[] | undefined,
  isoCurrencyCode?: string,
) => {
  const nf = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: isoCurrencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  let result = nf.format(amount);

  if (isoCurrencyCode === NO_CURRENCY_ISO_CODE) {
    result = result.replace(/XXX\s/, '');
  }

  return result;
};

/**
 * Some countries place their currency symbols at the front of the number and others at the back.
 * This code checks that by formatting 10 in the local currency and seeing where the code places the symbol
 * E.g. 10 € vs ￥10
 */
export const getIsCurrencyPrefix = (locale: CurrentUser['locale']) => {
  return new NumberFormatter(locale, USD_CONFIG).format(10).endsWith('0');
};

export const formatReadOnlyCurrency = (value: number, props: CurrencyProps) => {
  const formatter = new NumberFormatter(config.locale, {
    maximumFractionDigits: 2,
  });
  if (props.prefix) {
    return `${props.prefix} ${formatter.format(value)}`;
  }

  if (props.suffix) {
    return `${formatter.format(value)} ${props.suffix}`;
  }

  return formatter.format(value);
};
