import { isUndefined } from 'lodash';

import { entityLoggable } from '@float/common/lib/rights';
import { config } from '@float/libs/config';
import { sumOperation } from '@float/libs/utils/floats';
import { CellItem, LoggedTimeCell, Task } from '@float/types';

import { CellRenderArgs } from '../types';
import { getNonWorkSubCols } from './getNonWorkSubCols';
import { getTaskReference } from './getTaskReference';
import { hasLoggedTime } from './hasLoggedTime';
import { isInLockPeriod } from './isInLockPeriod';

export function addSuggestedTasks(args: CellRenderArgs) {
  const {
    cellKey,
    cells,
    firstCellDay,
    fns,
    lastCellDay,
    loggedTime,
    minItemHeightHours,
    opts,
    props,
  } = args;

  const cell = args.cell as LoggedTimeCell;

  const {
    bimaps,
    dates,
    leftHiddenDays,
    lockPeriodDates,
    maps,
    rightHiddenDays,
    rowMetas,
  } = props;

  const { calcEntityLength, getAllInstances } = cells._helpers;
  const { colIdx, rowId } = cell;
  const {
    isOneOffDay,
    getApplicableCellKeys,
    getHorizontalDimensions,
    shouldComputeTaskLength,
  } = fns;
  const {
    loggedTime: loggedTimeBimap,
    oneOff: oneOffsBimap,
    task: tasksBimap,
  } = bimaps;
  const {
    loggedTime: loggedTimeMap,
    task: tasksMap,
    taskReference: taskReferenceWeakMap,
  } = maps;

  // A map of shape { `${taskId}-${peopleId}`: true|false }
  // to avoid redundant access checksin the following loop.
  const isLoggable: Record<string, boolean> = {};
  const tasks = tasksBimap.getRev(cellKey).map((id) => tasksMap[id]);

  tasks.forEach((task) => {
    if (task.status == 1) {
      // In the time tracking view, we hide tentative tasks
      return;
    }
    const project = cells._helperData.projects[task.project_id];
    if (project && !project.active) {
      // We don't want to build task references for archived projects
      return;
    }
    const phase = task.phase_id && cells._helperData.phases[task.phase_id];
    if (phase && !phase.active) {
      // We don't want to build task references for archived phases
      return;
    }

    // @entity.length
    if (!task.length && shouldComputeTaskLength(cellKey, task, opts)) {
      task.length = calcEntityLength(cells, rowId, task);
    }

    const taskInstances = getAllInstances(task) as Task[];

    taskInstances.forEach((t: Task, instanceCount: number) => {
      const { x, w } = getHorizontalDimensions(t, firstCellDay, lastCellDay);
      // @entity.length
      t.length = task.length;
      const nonWorks = getNonWorkSubCols(
        rowId,
        firstCellDay,
        x,
        w,
        dates,
        cells,
        fns.isWorkDay,
      );
      let curX = x;
      while (curX < x + w && curX < 7 - leftHiddenDays - rightHiddenDays) {
        if (nonWorks.includes(curX)) {
          curX++;
          continue;
        }
        if (hasLoggedTime(t, curX, loggedTime!, dates, colIdx)) {
          curX++;
          continue;
        }
        const isTaskInLockPeriod = isInLockPeriod(
          curX,
          lockPeriodDates,
          dates,
          colIdx,
        );
        if (isTaskInLockPeriod) {
          curX++;
          continue;
        }
        const entity = getTaskReference(
          t,
          curX,
          taskReferenceWeakMap,
          loggedTimeMap,
          loggedTimeBimap,
          rowId,
          colIdx,
          dates,
          rowMetas,
          isTaskInLockPeriod,
          getApplicableCellKeys,
        );
        const taskKey = `${entity.task_id}-${entity.people_id}`;

        if (isUndefined(isLoggable[taskKey])) {
          isLoggable[taskKey] = entityLoggable(entity, cells._helperData);
        }
        const cellItem: CellItem<'loggedTime'> = {
          cellKey,
          rowId,
          key: `${cellKey}:task:${t.task_id}:${curX}:${instanceCount}`,
          isPlaceholder: entity.isPlaceholder,
          type: 'loggedTime',
          isTaskReference: true,
          canEdit: isLoggable[taskKey],
          entity,
          entityId: entity.logged_time_id,
          isStart: true,
          isEnd: true,
          date: dates.fromDescriptor(colIdx, curX),
          x: curX,
          w: 1,
          h:
            config.fixedHourHeight ??
            Math.max(entity.hours, minItemHeightHours!),
          isInLockPeriod: isTaskInLockPeriod,
        };
        cell.dayHours[curX] = sumOperation(cell.dayHours[curX], entity.hours);
        cell.items.push(cellItem);
        const oneOffs = oneOffsBimap.getRev(cellKey);
        if (isOneOffDay(oneOffs, [], dates.fromNum(firstCellDay + curX))) {
          if (!cell.items.some((i) => i.type === 'oneOff' && i.x === curX)) {
            cell.items.push({
              cellKey,
              key: `${cellKey}:oneOff:${curX}`,
              type: 'oneOff',
              entity: {},
              x: curX,
              w,
            } as CellItem<'oneOff'>);
          }
        }
        curX++;
      }
    });
  });
}
