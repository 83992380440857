import styled from 'styled-components';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

export const Grid = styled.div`
  display: flex;
  flex-direction: ${({ collapsed }) => (collapsed ? 'column' : 'row')};
  align-items: ${({ collapsed }) => (collapsed ? 'center' : 'flex-start')};
  flex-wrap: wrap;
`;

export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  border-radius: 6px;
`;

export const GridItemText = styled.span`
  ${Typography.Label16.M500};
  color: ${Colors.Core.Lt.Blue.Primary};
`;
