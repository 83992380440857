import { t } from '@lingui/macro';

// TODO: Get rid of the these numerical indexes and use strings instead
// https://linear.app/float-com/issue/PI-275/refactor-budgets-types-to-get-rid-of-numerical-enum

// Must be a function to allow for Lingui translations
export const getBudgetTypesToLabelMap = () =>
  // Note: The values "1.1" and "2.1" are only for uniqueness.
  // They are not actually a persistable values in the DB.
  ({
    0: t`No budget`,
    1: t`Total hours`,
    1.1: t`Hours by phase`,
    1.2: t`Hours by task`,
    2: t`Total fee`,
    2.1: t`Fee by phase`,
    2.2: t`Fee by task`,
    3: t`Hourly fee`,
  });

export const NON_MONETARY_BUDGET_TYPES = [0, 1];

export const MONETARY_BUDGET_TYPES = [2, 3];
