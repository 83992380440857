import { css, FlattenSimpleInterpolation } from 'styled-components';

import ResizeService from '@float/libs/web/resizeService';
import {
  Breakpoint,
  breakpoints,
} from '@float/libs/web/responsive/breakpoints';

let bodyComputedStyle: CSSStyleDeclaration;
let currentBreakpoint: Breakpoint;

export const mediaBridge = (element: HTMLElement = document.body) => {
  bodyComputedStyle = window.getComputedStyle(element);

  const value =
    // In the unit test environment this may return `undefined`
    bodyComputedStyle.getPropertyValue('--current-breakpoint') ?? '';

  return value.replace(/"|'/g, '') as Breakpoint;
};

const updateBodyComputedStyle = () => {
  currentBreakpoint = mediaBridge();
};

const updateWindowHeight = () => {
  const { documentElement } = document;
  documentElement.style.setProperty(
    '--screen-height',
    `${window.innerHeight}px`,
  );
};

updateBodyComputedStyle();
updateWindowHeight();

// update on window resize
ResizeService.add(updateBodyComputedStyle);
ResizeService.add(updateWindowHeight);

type BreakpointStyles = {
  [key in Breakpoint]: (
    styles: FlattenSimpleInterpolation,
  ) => FlattenSimpleInterpolation;
};

export const media = Object.keys(breakpoints).reduce(
  (accumulator: Partial<BreakpointStyles>, label) => {
    accumulator[label as Breakpoint] = (styles: FlattenSimpleInterpolation) => {
      const breakpoint = breakpoints[label as Breakpoint];

      return css`
        @media ${breakpoint} {
          ${styles.join('')}
        }
      `;
    };

    return accumulator;
  },
  {},
);

export const getMedia = () => currentBreakpoint || mediaBridge();

export const isMedia = (media: Breakpoint) => getMedia() === media;
export const isMediaSmall = () => getMedia() === 'small';
export const isMediaMedium = () => getMedia() === 'medium';
export const isMediaLarge = () => getMedia() === 'large';
export const isMediaXLarge = () => getMedia() === 'xlarge';
