import React, { useContext, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { isBoolean } from 'lodash';
import { getProjectsMap } from 'selectors';

import PersonAvatar from '@float/common/components/elements/PersonAvatar';
import {
  getDimensions,
  TOOLTIP_DELAY,
} from '@float/common/components/Schedule/Cell/MainCell/Item/helpers';
import { useCellStyles } from '@float/common/lib/cellStylesManager/hooks/useCellStyles';
import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import { useAppSelector } from '@float/common/store';
import { moment } from '@float/libs/moment';
import { trimTextForReadibility } from '@float/libs/utils/string/trimTextForReadibility';
import { TextTooltip } from '@float/ui/deprecated';

import JiraSidebarItemTypeIcon from '../jira/JiraSidebarItemTypeIcon';
import { SidebarContext } from './PmSidebar.context';
import * as styled from './styles';

const dateToString = (date) => date.format('YYYY-MM-DD');

function getDragItemHours({ logTimeView, rowMetas }) {
  if (logTimeView) {
    return +localStorage.getItem('logTimeDefaultHours') || 2;
  }

  const companyWorkHours = rowMetas.get('_company').getDailyWorkHours() || [];
  const itemHours = companyWorkHours.find((hrs) => hrs > 0);
  if (itemHours) {
    return itemHours;
  }

  // Just to have a decent height when being dragged
  const DEFAULT_HOURS = 8;
  return DEFAULT_HOURS;
}

function getDragItem({
  item,
  type,
  logTimeView,
  fallbackPersonId,
  isMember,
  rowMetas,
}) {
  const days = logTimeView ? 1 : item.days;
  const hours = getDragItemHours({ logTimeView, rowMetas });

  const referenceDate = new Date();
  const start = moment(referenceDate).startOf('day');
  const end = moment(referenceDate)
    .startOf('day')
    .add(days - 1, 'days');
  const start_date = dateToString(start);
  const end_date = dateToString(end);
  const name = (item.name || '').substr(0, 150);
  const notes = (item.description || '').substr(0, 1500);
  const assigneePersonId =
    (isMember ? fallbackPersonId : item.assigneePeopleId) || fallbackPersonId;

  const dragItem = {
    type: logTimeView ? 'loggedTime' : 'task',
    isSidebarItem: true,
    restrictToPersonId: isMember ? assigneePersonId : null,
    days,
    h: hours,
    entity: {
      isSidebarItem: true,
      isPlaceholder: true,
      sidebarItemType: type,
      name,
      notes: logTimeView ? null : notes,
      task_name: name,
      project_id: item.projectId,
      start_date,
      end_date,
      hours: item.hours || hours,
      people_ids: [assigneePersonId], // people_ids needed for drag placeholders to work?
      isStart: true,
      length: days,
      pmKey: item.extResourceId,
      priority_info: {},
      ...(isBoolean(item.billable) && { billable: +item.billable }),
    },
  };

  if (logTimeView) {
    dragItem.entity.people_id = assigneePersonId;
    dragItem.entity.date = start_date;
  }

  return dragItem;
}

function PmSidebarItemTooltip({ item }) {
  return (
    <styled.Tooltip>
      {item.assigneePeopleId && (
        <styled.TooltipPerson>
          <PersonAvatar personId={item.assigneePeopleId} size="xs" readOnly />
          <styled.TooltipPersonName>{item.personName}</styled.TooltipPersonName>
        </styled.TooltipPerson>
      )}
      <styled.TooltipItemName>{item.name}</styled.TooltipItemName>
      {item.key && (
        <styled.TooltipItemKey>
          {item.issueType && (
            <JiraSidebarItemTypeIcon issueType={item.issueType} />
          )}
          {item.key}
        </styled.TooltipItemKey>
      )}
      {item.isScheduled && (
        <styled.TooltipFooter>
          <styled.TooltipItemScheduled>
            <styled.TooltipItemIndicator />
            <span>Scheduled</span>
          </styled.TooltipItemScheduled>
        </styled.TooltipFooter>
      )}
    </styled.Tooltip>
  );
}

function PmSidebarItemContent({
  item,
  collapsedGroups,
  innerRef,
  onMouseDown,
}) {
  const itemName = item.listDisplayName || item.name;
  const { value: name } = useMemo(
    () => trimTextForReadibility(itemName, 80),
    [itemName],
  );

  const isCollapsed = collapsedGroups[item.projectId];

  const { backgroundColor, color } = useCellStyles(item, item.projectColor);

  if (isCollapsed) {
    return null;
  }

  return (
    <TextTooltip
      content={<PmSidebarItemTooltip item={item} />}
      placement="bottom"
      maxWidth={340}
      delay={TOOLTIP_DELAY}
      distance={-2}
    >
      <styled.ListItem
        ref={innerRef}
        style={{ backgroundColor, color }}
        onMouseDown={onMouseDown}
        hasAvatar={item.assigneePeopleId}
        hasIssueKey={item.key}
      >
        <styled.Name>{name}</styled.Name>
        {(item.assigneePeopleId || item.key) && (
          <styled.AvatarKey>
            {item.assigneePeopleId && (
              <PersonAvatar
                style={{ marginRight: 10 }}
                personId={item.assigneePeopleId}
                size="xs"
                readOnly
              />
            )}
            {item.key && (
              <styled.IssueKey>
                {item.issueType && (
                  <JiraSidebarItemTypeIcon
                    issueType={item.issueType}
                    color={color}
                  />
                )}
                {item.key}
              </styled.IssueKey>
            )}
          </styled.AvatarKey>
        )}
        {item.isScheduled && (
          <styled.Indicator style={{ backgroundColor: color }} />
        )}
        {item.hours && <styled.Hours>{item.hours}h</styled.Hours>}
      </styled.ListItem>
    </TextTooltip>
  );
}

function PmSidebarDraggableItem({ item, isMember, collapsedGroups }) {
  const { actions, type } = useContext(SidebarContext);
  const {
    dayWidth,
    hourHeight,
    suvPersonId,
    suvSingleDay,
    logTimeView,
    rowMetas,
  } = useScheduleContext();
  const projectsMap = useAppSelector(getProjectsMap);
  const { backgroundColor, color } = useCellStyles(item, item.projectColor);

  const boxRef = useRef(null);

  const onMouseDown = (event) => {
    event.stopPropagation();
    if (event.button !== 0) return;
    if (!boxRef.current.contains(event.target)) {
      // The user clicked on a tooltip
      return;
    }

    const isTaskTentative = item.status == 1;
    const isProjectTentative =
      item.projectId && projectsMap[item.projectId]?.tentative;

    // Skip DnD for tentative projects
    if (logTimeView && (isTaskTentative || isProjectTentative)) {
      return;
    }

    const rect = boxRef.current.getBoundingClientRect();

    // Edge uses left/top for unknown reasons
    const offsetX = rect.x || rect.left;
    const offsetY = rect.y || rect.top;

    const dragItem = getDragItem({
      item,
      type,
      logTimeView,
      fallbackPersonId: suvPersonId,
      isMember,
      rowMetas,
    });
    const dimensions = getDimensions(
      dragItem,
      dayWidth,
      hourHeight,
      suvSingleDay,
    );
    const height = Math.max(rect.height, dimensions.height);
    const dragVersionWidth = dayWidth * Math.min(5, dragItem.entity.length) - 7;
    const dragVersion = (
      <styled.ListItemDrag
        style={{
          backgroundColor,
          color,
          height: dimensions.height,
          width: dragVersionWidth,
        }}
      >
        {item.name}
      </styled.ListItemDrag>
    );

    // If user clicks towards right half of sidebar item, then
    // adjust the offset so dragItem starts from point of click.
    let clientX = event.clientX;
    if (clientX - offsetX > 100) {
      clientX = offsetX + 30;
    }

    actions.setDragItem({
      item: dragItem,
      element: dragVersion,
      offsetX,
      offsetY,
      clientX,
      clientY: event.clientY,
      height,
      shiftKey: event.shiftKey,
    });
  };

  return (
    <PmSidebarItemContent
      item={item}
      collapsedGroups={collapsedGroups}
      innerRef={boxRef}
      onMouseDown={onMouseDown}
    />
  );
}

function PmSidebarItem({ item, isMember, collapsedGroups }) {
  const { actions } = useContext(SidebarContext);
  const isSchedulePage = Boolean(actions);

  if (isSchedulePage) {
    return (
      <PmSidebarDraggableItem
        item={item}
        isMember={isMember}
        collapsedGroups={collapsedGroups}
      />
    );
  }

  return <PmSidebarItemContent item={item} collapsedGroups={collapsedGroups} />;
}

const mapStateToProps = (state) => ({
  collapsedGroups: state.pmSidebar.collapsedGroups,
  isMember: +state.currentUser.account_tid === 4,
});

export default connect(mapStateToProps)(PmSidebarItem);
