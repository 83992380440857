import styled from 'styled-components';

import EH from '@float/ui/deprecated/Earhart';

export const StyledNav = styled.nav`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;

  min-width: 300px;
  height: calc(var(--screen-height) - var(--app-margin-top, 0px));

  border-width: 1px;
  border-style: solid;
  border-color: ${EH.Colors.FIN.Lt.Stroke.Stroke1};
  border-top: none;
  border-left: none;

  background-color: ${EH.Colors.FIN.Lt.Surface.Surf2};

  &,
  * {
    box-sizing: border-box;
  }

  header {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;

    padding: 24px;
    padding-bottom: 0;

    margin-bottom: 30px;

    h2 {
      ${EH.Typography.TextXXL.SB600};

      color: ${EH.Colors.FIN.Lt.Emphasis.High};
    }

    a + h2 {
      margin-left: 10px;
    }
  }

  h5 {
    ${EH.Typography.Label12.SB600};

    color: ${EH.Colors.FIN.Lt.Emphasis.Low};
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & + ul {
      margin-top: 32px;
    }

    li {
      width: 100%;

      a {
        padding-right: 4px;
      }
    }

    li + li {
      margin-bottom: 2px;
    }

    li:first-child {
      margin-bottom: 10px;
    }

    li:last-child {
      margin-bottom: 0px;
    }
  }
`;

export const StyledScroll = styled.div`
  position: relative;

  width: 100%;

  flex-grow: 1;
`;

export const StyledScrollContent = styled.div`
  ${EH.Scrollbar.Default};

  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  padding: 0 24px;

  overflow-y: auto;
`;

export const StyledFreeTrialUpgradeWrapper = styled.div`
  width: 100%;

  padding: 24px 24px 22px 24px;
`;
