import { t } from '@lingui/macro';

export enum AccountType {
  AccountOwner = 1,
  Admin = 2,
  ProjectManager = 3, // Deprecated
  Member = 4,
  Billing = 5,
  DepartmentManager = 6, // Deprecated
  Manager = 7,
}

// Must be a function to allow for Lingui translations
export const getAccountTypeToLabelMap = () =>
  ({
    [AccountType.AccountOwner]: t`Account Owner`,
    [AccountType.Admin]: t`Admin`,
    [AccountType.ProjectManager]: t`Project Manager`,
    [AccountType.Member]: t`Member`,
    [AccountType.Billing]: t`Billing`,
    [AccountType.DepartmentManager]: t`Department Manager`,
    [AccountType.Manager]: t`Manager`,
  }) as const;
