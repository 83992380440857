import * as React from 'react';
import { AccordionItem as AccordionItemPrimitive } from '@radix-ui/react-accordion';

import * as styles from './styles.css';

export const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionItemPrimitive>,
  React.ComponentPropsWithoutRef<typeof AccordionItemPrimitive>
>((props, forwardedRef) => (
  <AccordionItemPrimitive
    {...props}
    ref={forwardedRef}
    className={styles.accordionItem}
  />
));

AccordionItem.displayName = 'AccordionItem';
