import React from 'react';

import Link from '../link';
import Mention from '../mention';

const Element = (props) => {
  const { attributes, children, element } = props;

  switch (element.type) {
    case 'link':
      return <Link {...props} />;

    case 'mention':
      return <Mention {...props} />;

    default:
      return <p {...attributes}>{children}</p>;
  }
};

export default Element;
