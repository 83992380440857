import React from 'react';
import { t } from '@lingui/macro';

import { Button } from '@float/ui/components/Button';
import { IconChevronDown } from '@float/ui/icons/essentials/IconChevronDown';
import { IconChevronUp } from '@float/ui/icons/essentials/IconChevronUp';

import { usePhaseNavigation } from '../../hooks/usePhaseNavigation';
import { ProjectFormData } from '../../types';

import * as styles from './styles.css';

export const PhaseNavigation: React.FC<{
  projectId: ProjectFormData['projectId'];
}> = ({ projectId }) => {
  const { index, phases, phaseId, onClickNextPhase, onClickPrevPhase } =
    usePhaseNavigation(projectId);
  if (phases.length <= 1 || !phaseId) return null;
  return (
    <div className={styles.phaseNavigation}>
      <span>{`${index}/${phases.length}`}</span>
      <Button
        iconEnd={IconChevronUp}
        appearance="ghost-flay"
        size="xsmall"
        iconOnlyLabel={t`Previous phase`}
        onClick={onClickPrevPhase}
        disabled={index === 1}
        type="button"
      />
      <Button
        iconEnd={IconChevronDown}
        appearance="ghost-flay"
        size="xsmall"
        iconOnlyLabel={t`Next phase`}
        onClick={onClickNextPhase}
        disabled={index === phases.length}
        type="button"
      />
    </div>
  );
};
