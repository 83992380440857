import { useEffect, useState } from 'react';

import {
  useViewportContext,
  useVisibleTasks,
} from '@float/common/components/Schedule/util/ViewportContext';
import {
  PersonProjectRow,
  PersonRow,
  ProjectRow,
} from '@float/common/serena/Data/useScheduleRows';
import { CellsMap } from '@float/types';

import { MainCellItem } from '../../box/BoxElements/types';

type Point = {
  x: number;
  y: number;
};

function getMidpoint(from: Point, to: Point) {
  return {
    x: (from.x + to.x) / 2,
    y: (from.y + to.y) / 2,
  };
}

// See: https://stackoverflow.com/questions/18655135
function bezierMidpoint(A: Point, D: Point, B: Point, C: Point) {
  const E = getMidpoint(A, B);
  const F = getMidpoint(B, C);
  const G = getMidpoint(C, D);
  const H = getMidpoint(E, F);
  const J = getMidpoint(F, G);
  const K = getMidpoint(H, J);
  return K;
}

function bezier(from: Point, to: Point) {
  const cp1 = {
    x: (from.x + to.x) * 0.5,
    y: from.y,
  };

  const cp2 = {
    x: (from.x + to.x) * 0.5,
    y: to.y,
  };

  if (from.y !== to.y && cp1.x - from.x < 33) {
    cp1.x = Math.max(cp1.x, from.x + 53);
    cp2.x = Math.min(cp2.x, to.x - 73);
  }

  return [cp1, cp2];
}

export function getPath(from: Point, to: Point, { straightLine = false } = {}) {
  const curveParts = [`M ${from.x},${from.y}`, `${to.x},${to.y}`];

  if (!straightLine) {
    const [cp1, cp2] = bezier(from, to);
    curveParts.splice(1, 0, `C ${cp1.x} ${cp1.y}`, `${cp2.x} ${cp2.y}`);
  }

  return curveParts.join(' ');
}

function getArrowPath(p: Point) {
  return `M ${p.x - 8},${p.y - 4} L${p.x - 8},${p.y + 4} ${p.x + 1},${p.y} z`;
}

export function useLinkArrowCurve(
  cells: CellsMap,
  item: MainCellItem,
  linkedArrowTargetRef:
    | React.MutableRefObject<SVGSVGElement | null>
    | undefined,
  wrapperRef: React.RefObject<HTMLDivElement> | undefined,
  row?: PersonRow | PersonProjectRow | ProjectRow,
) {
  const [arrow, setArrow] = useState<{ from: Point; to: Point } | undefined>(
    undefined,
  );
  const { from, to } = arrow || {};

  // As row heights change due to the user scrolling overtime into view or
  // adjusting the height on visible entities, we need to redraw arrows in case
  // they changed. If this becomes a performance problem, refactor these
  // statements to be more narrow.
  const { boundaryCol, heightsUpdatedAt, rowsUpdatedAt, setParentLinkCount } =
    useViewportContext();
  const visibleTasks = useVisibleTasks();

  const parent = cells?._helpers.getLinkParent(item);
  const parentVisibility = !!visibleTasks[parent?.entityId];

  useEffect(() => {
    const clearArrow = () => setArrow(undefined);

    if (!linkedArrowTargetRef?.current) return clearArrow();
    if (!wrapperRef?.current) return clearArrow();

    if (!('people_ids' in item.entity)) return clearArrow();
    if (
      row?.type === 'person' &&
      item.entity.people_ids?.length > 1 &&
      item.entity.people_ids[0] != row.data.people_id
    ) {
      return clearArrow();
    }
    if (!item._boxRef) return clearArrow();
    if (!item.isStart) return clearArrow();

    const parentElement = parent?._boxRef?.closest('.ItemWrapper');
    const parentCellElement = parentElement?.closest('.MainCell-Wrapper');
    if (!parentCellElement?.offsetParent) return clearArrow();

    const newFrom = {
      y:
        Number(
          parentCellElement.offsetParent.getAttribute('data-translate-y'),
        ) +
        parentElement.offsetTop +
        parentElement.offsetHeight / 2,
      x:
        parentElement.offsetLeft +
        parentElement.offsetWidth +
        parentCellElement.offsetLeft,
    };

    const myElement = item._boxRef?.closest('.ItemWrapper') as HTMLElement;
    const newTo = {
      x: wrapperRef.current.offsetLeft + myElement.offsetLeft,
      y:
        Number(
          // @ts-expect-error quite a dynamic access, can't ensure safety with static checks
          wrapperRef.current.offsetParent.getAttribute('data-translate-y'),
        ) +
        myElement.offsetTop +
        myElement.offsetHeight / 2,
    };

    setArrow((prev) => {
      if (
        prev?.from?.y !== newFrom.y ||
        prev?.from?.x !== newFrom.x ||
        prev?.to?.y !== newTo.y ||
        prev?.to?.x !== newTo.x
      ) {
        return { from: newFrom, to: newTo };
      }
      return prev;
    });
    // eslint-disable-next-line
  }, [
    item,
    parent,
    boundaryCol,
    heightsUpdatedAt,
    rowsUpdatedAt,
    parentVisibility,
  ]);

  const entity = item.entity;

  const parentTaskId =
    'parent_task_id' in entity ? entity.parent_task_id : null;

  useEffect(() => {
    if (!arrow) return;
    if (!parentTaskId) return;

    setParentLinkCount((prev) => ({
      ...prev,
      [parentTaskId]: (prev[parentTaskId] || 0) + 1,
    }));

    return () => {
      setParentLinkCount((prev) => ({
        ...prev,
        [parentTaskId]: prev[parentTaskId] - 1,
      }));
    };
  }, [arrow, parentTaskId, setParentLinkCount]);

  if (!parent || !from || !to) return null;

  const path = getPath(from, to);
  const arrowPath = getArrowPath(to);
  const [cp1, cp2] = bezier(from, to);
  const midpoint = bezierMidpoint(from, to, cp1, cp2);

  return { parent, path, arrowPath, midpoint };
}
