import styled from 'styled-components';

import Button from '../../Button/Button';
import { StyledModalBody } from '../ModalBody/styles';

export const StyledModalActions = styled.div`
  padding: 32px;
  display: flex;
  align-items: center;

  ${StyledModalBody} & {
    padding-left: 0;
    padding-right: 0;
  }

  ${Button.Styled} {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;
