import API3 from '@float/common/api3';
import { TimerGlobalState } from '@float/common/reducers/lib/types';
import { AppDispatch } from '@float/common/store';
import { logger } from '@float/libs/logger';
import { Timer } from '@float/types';

import { LOGGED_TIME_CREATED } from '../loggedTimes';
import { TIMER_STOP, TIMER_UPDATE } from './constants';

export const stopTimer = (timerId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const stoppedTimer = await API3.stopTimer(timerId);
      dispatch({ type: LOGGED_TIME_CREATED, loggedTime: stoppedTimer });
      dispatch({
        type: TIMER_STOP,
        timerId,
        timer: {
          ...stoppedTimer.timer,
          end_timestamp: stoppedTimer.timer.end_timestamp ?? Date.now(),
        },
      });
    } catch (e) {
      logger.error('There was an error while stopping a timer', e);
    }
  };
};

export const stopTimerFromLiveUpdate = (updatedTimer: Timer) => {
  return (dispatch: AppDispatch, getState: () => TimerGlobalState) => {
    dispatch({
      type: TIMER_UPDATE,
      timer: updatedTimer,
    });

    dispatch({ type: LOGGED_TIME_CREATED, loggedTime: updatedTimer });
  };
};
