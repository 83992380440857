import { LoadDataMap } from '@float/types';

import { UseCellsAction } from '../types';

export function shouldIgnoreMissing(
  action: Extract<UseCellsAction, { type: 'LOAD_DATA' | 'LOAD_BULK_DATA' }>,
  dataType: keyof LoadDataMap,
) {
  const { ignoreMissing } = action;

  if (typeof ignoreMissing === 'object' && ignoreMissing !== null) {
    return ignoreMissing[dataType] === true;
  }

  return Boolean(ignoreMissing);
}
