import React, { useId } from 'react';
import { FieldPath } from 'react-hook-form';

import { useCurrencyProps } from '@float/common/hooks/useCurrencyProps';

import { ProjectFormData } from '../../types';
import { FormField } from '../FormFieldsSection';
import { SidePanelNumericInput } from '../SidePanelNumericInput';

export type CurrencyInputFieldProps = {
  name: FieldPath<ProjectFormData>;
  label: string;
  placeholder?: string;
  readOnly?: boolean;
  hideLabel?: boolean;
  fieldClassName?: string;
  className?: string;
  valueOverride?: string;
  compact?: boolean;
  disabled?: boolean;
  blockOutsideInteraction?: boolean;
};

export function CurrencyInputField(props: CurrencyInputFieldProps) {
  const id = useId();
  const currencyProps = useCurrencyProps();

  return (
    <FormField
      label={props.label}
      htmlFor={id}
      readOnly={props.readOnly}
      hideLabel={props.hideLabel}
      className={props.fieldClassName}
      stopClickPropagation
    >
      <SidePanelNumericInput
        {...currencyProps}
        className={props.className}
        id={id}
        name={props.name}
        placeholder={'0'}
        readOnly={props.readOnly}
        valueOverride={props.valueOverride}
        compact={props.compact}
        disabled={props.disabled}
        blockOutsideInteraction={props.blockOutsideInteraction}
      />
    </FormField>
  );
}
