import { forEach, keyBy, pick } from 'lodash';

import {
  Person,
  RawTask,
  RawTimeoff,
  SearchTimeoff,
  Timeoff,
} from '@float/types';

import {
  CREATE_TIMEOFF,
  CREATE_TIMEOFF_FAILURE,
  CREATE_TIMEOFF_SUCCESS,
  DELETE_TIMEOFF_SUCCESS,
  DELETE_TIMEOFF_TYPE_SUCCESS,
  FETCH_TIMEOFFS,
  FETCH_TIMEOFFS_FAILURE,
  FETCH_TIMEOFFS_SUCCESS,
  INSERT_TASK_SUCCESS,
  INSERT_TIMEOFF_SUCCESS,
  PEOPLE_BULK_DELETED,
  PEOPLE_DELETED,
  PEOPLE_UPDATED,
  REPLACE_TASK_SUCCESS,
  REPLACE_TIMEOFF_SUCCESS,
  SEARCH_CONTEXT_LOAD_FINISH,
  UPDATE_TASK,
  UPDATE_TIMEOFF,
  UPDATE_TIMEOFF_FAILURE,
} from '../actions';
import { formatTimeoff, formatTimeoffId } from '../lib/formatters';
import { omit, omitBy } from '../lib/omit';

const isRegionHoliday = (item: Timeoff) => !!item.region_holiday_id;
const isPartOfPersonHoliday = (item: Timeoff, ids: string[] = []) =>
  !!item.region_holiday_id && ids.includes(item.region_holiday_id);

export type TimeoffsState = {
  timeoffs: Record<number | string, SearchTimeoff | Timeoff>;
  isLoading: boolean;
  fullyHydrated: boolean;
  timeoffError?: unknown;
};

export const DEFAULT_STATE: TimeoffsState = {
  timeoffs: {},
  isLoading: false,
  fullyHydrated: false,
};

export const formatTimeoffFieldsFromOptimisticUpdate = (
  timeoff: RawTimeoff | Timeoff,
) => {
  const formatted = pick(timeoff, [
    'created',
    'created_by',
    'full_day',
    'modified',
    'modified_by',
    'priority_info',
    'people_ids',
    'repeat_state',
    'timeoff_id',
    'timeoff_notes',
    'notes_meta',
    'timeoff_tasks',
    'timeoff_type_id',
    'timeoff_type_name',
    'start_date',
    'end_date',
    'repeat_end_date',
    'status',
    'status_request',
    'status_creator_id',
    'status_note',
  ]);

  let hours: number | null = null;

  // TODO: clean up - make ETM / DnD structures
  if (!timeoff.full_day) {
    if ('hours_pd' in timeoff) {
      hours = timeoff.hours_pd || timeoff.hours;
    } else {
      hours = timeoff.hours;
    }
  }

  return {
    ...formatted,
    data_type: 'full',
    people_ids: formatted.people_ids || [],
    hours,
    start_time: timeoff.start_time || '',
  } as Timeoff;
};

const deleteTimeoffs = (
  timeoffs: TimeoffsState['timeoffs'],
  data: { timeoff_id: number; people_id?: number }[],
  replacesTempId: boolean,
) => {
  const updates: (SearchTimeoff | Timeoff)[] = [];
  const idsToDelete: string[] = [];

  for (const { timeoff_id, people_id } of data) {
    const timeoff = timeoffs[timeoff_id];

    if (people_id && timeoff && timeoff.people_ids.length > 1) {
      if (replacesTempId) {
        updates.push({
          ...timeoff,
          people_ids: timeoff.people_ids.filter((id) => id != people_id),
          replacesTempId: timeoff.timeoff_id,
        });
      } else {
        updates.push({
          ...timeoff,
          people_ids: timeoff.people_ids.filter((id) => id != people_id),
        });
      }
    } else {
      idsToDelete.push(timeoff_id.toString());
    }
  }

  if (idsToDelete.length) {
    timeoffs = omit(timeoffs, idsToDelete);
  }

  for (const timeoff of updates) {
    timeoffs[timeoff.timeoff_id] = timeoff;
  }

  return timeoffs;
};

const replaceTimeoffs = (
  timeoffs: TimeoffsState['timeoffs'],
  data: RawTimeoff[],
  temporaryId?: string,
) => {
  for (const timeoff of data) {
    if (timeoff.timeoff_id) {
      const formatted = formatTimeoff(timeoff);

      if (temporaryId) {
        formatted.replacesTempId = temporaryId;
      }

      timeoffs[timeoff.timeoff_id] = formatted;
    }
  }

  return timeoffs;
};

export type TimeoffAction =
  | {
      type: typeof SEARCH_CONTEXT_LOAD_FINISH;
      rebuild?: boolean;
      context: {
        timeoffs: {
          ids: Record<number, { peopleIds: number[] }>;
          val: string;
        }[];
      };
    }
  | {
      type: typeof CREATE_TIMEOFF;
      item: RawTimeoff;
    }
  | {
      type: typeof CREATE_TIMEOFF_SUCCESS;
      item: RawTimeoff;
    }
  | {
      type: typeof CREATE_TIMEOFF_FAILURE;
      item: RawTimeoff;
    }
  | {
      type: typeof UPDATE_TASK;
      item: RawTask;
    }
  | {
      type: typeof UPDATE_TIMEOFF;
      item: RawTimeoff;
      isLiveUpdate: boolean;
    }
  | {
      type: typeof UPDATE_TIMEOFF_FAILURE;
      item: RawTimeoff;
      previous: Timeoff;
    }
  | {
      type: typeof DELETE_TIMEOFF_SUCCESS;
      item: Timeoff | RawTimeoff;
    }
  | {
      type: typeof FETCH_TIMEOFFS;
    }
  | {
      type: typeof FETCH_TIMEOFFS_SUCCESS;
      shouldRefresh?: boolean;
      skipFormatting: true;
      items: Record<number, Timeoff>;
    }
  | {
      type: typeof FETCH_TIMEOFFS_SUCCESS;
      shouldRefresh?: boolean;
      rebuild?: boolean;
      skipFormatting: false;
      items: Record<number, RawTimeoff>;
    }
  | {
      type: typeof FETCH_TIMEOFFS_FAILURE;
      error: unknown;
    }
  | {
      type:
        | typeof REPLACE_TIMEOFF_SUCCESS
        | typeof REPLACE_TASK_SUCCESS
        | typeof INSERT_TASK_SUCCESS
        | typeof INSERT_TIMEOFF_SUCCESS;
      response: {
        timeoff?: RawTimeoff[];
        delete?: {
          timeoff?: { timeoff_id: number; people_id?: number }[];
        };
      };
      item?: RawTimeoff;
    }
  | {
      type: typeof PEOPLE_DELETED;
      person?: {
        people_id: number;
      };
    }
  | {
      type: typeof PEOPLE_BULK_DELETED;
      ids: number[];
    }
  | {
      type: typeof PEOPLE_UPDATED;
      isNew?: boolean;
      regionHolidayIds?: string[];
      person?: Partial<Person> & {
        people_id: number;
        avatar_file?: string;
      };
    }
  | {
      type: typeof DELETE_TIMEOFF_TYPE_SUCCESS;
      id: number;
    };

const timeoffs = (state = DEFAULT_STATE, action: TimeoffAction) => {
  switch (action.type) {
    case SEARCH_CONTEXT_LOAD_FINISH: {
      const timeoffs = action.rebuild ? {} : { ...state.timeoffs };

      for (const { val, ids } of action.context.timeoffs) {
        for (const [tid, timeoff] of Object.entries(ids)) {
          if (!timeoffs[tid]) {
            timeoffs[tid] = {
              data_type: 'search',
              timeoff_id: Number(tid),
              timeoff_type_name: val,
              people_ids: timeoff.peopleIds,
            };
          }
        }
      }

      return {
        ...state,
        timeoffs,
      };
    }

    case CREATE_TIMEOFF: {
      const newTimeoffs = { ...state.timeoffs };
      const formatted = formatTimeoff(action.item);

      newTimeoffs[action.item.createdTs] = formatted;

      return { ...state, timeoffs: newTimeoffs };
    }

    case CREATE_TIMEOFF_SUCCESS: {
      const newTimeoffs = omit(state.timeoffs, [`${action.item.createdTs}`]);

      const formatted = formatTimeoff(action.item);
      formatted.replacesTempId = action.item.createdTs;
      newTimeoffs[formatted.timeoff_id] = formatted;

      return { ...state, timeoffs: newTimeoffs };
    }
    case CREATE_TIMEOFF_FAILURE: {
      return {
        ...state,
        timeoffs: omit(state.timeoffs, [`${action.item.createdTs}`]),
      };
    }
    case UPDATE_TASK: {
      if (!action.item?.priority_update?.timeoffs) {
        return state;
      }

      if (!action.item.people_id) {
        return state;
      }

      const priorityUpdate = action.item.priority_update.timeoffs;
      const peopleId = action.item.people_id;

      const timeoffs = { ...state.timeoffs };

      for (const [timeoffId, priority] of Object.entries(priorityUpdate)) {
        const timeoff = timeoffs[timeoffId];

        if (timeoff && timeoff.data_type !== 'search') {
          const priorityInfo = timeoff.priority_info ?? {};

          timeoffs[timeoff.timeoff_id] = {
            ...timeoff,
            priority_info: {
              ...priorityInfo,
              [peopleId]: priority,
            },
          };
        }
      }

      return {
        ...state,
        timeoffs,
      };
    }
    case UPDATE_TIMEOFF: {
      if (!action.item) {
        console.log('Trying to update timeoff without item');
        return state;
      }

      const priorityUpdate =
        (action.item.priority_update && action.item.priority_update.timeoffs) ||
        {};
      const formatFn = action.isLiveUpdate
        ? formatTimeoff
        : formatTimeoffFieldsFromOptimisticUpdate;

      const newTimeoffs = { ...state.timeoffs };

      const timeoffId = formatTimeoffId(action.item);

      newTimeoffs[timeoffId] = formatFn(action.item, timeoffId);

      forEach(priorityUpdate, (val, key) => {
        const timeoff = newTimeoffs[key];

        if (timeoff && timeoff.data_type !== 'search') {
          newTimeoffs[key] = {
            ...formatFn(timeoff),
            priority: val,
          };
        }
      });

      return {
        ...state,
        timeoffs: newTimeoffs,
      };
    }
    case UPDATE_TIMEOFF_FAILURE: {
      const newTimeoffs = { ...state.timeoffs };
      newTimeoffs[formatTimeoffId(action.item)] = action.previous;
      return {
        ...state,
        timeoffs: newTimeoffs,
      };
    }
    case DELETE_TIMEOFF_SUCCESS: {
      if (!action.item?.timeoff_id) {
        return state;
      }
      return {
        ...state,
        timeoffs: omit(state.timeoffs, [formatTimeoffId(action.item)]),
      };
    }
    case FETCH_TIMEOFFS:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_TIMEOFFS_SUCCESS: {
      const newTimeoffs = action.shouldRefresh ? {} : { ...state.timeoffs };

      if (action.skipFormatting) {
        Object.assign(newTimeoffs, action.items);
      } else {
        forEach(action.items, (t) => {
          const prevTimeoff = newTimeoffs[t.timeoff_id];

          if (
            action.rebuild ||
            !prevTimeoff ||
            prevTimeoff.data_type === 'search' ||
            !prevTimeoff.start_date
          ) {
            newTimeoffs[t.timeoff_id] = formatTimeoff(t);
          }
        });
      }
      return {
        ...state,
        timeoffs: newTimeoffs,
        isLoading: false,
        fullyHydrated: true,
      };
    }
    case FETCH_TIMEOFFS_FAILURE:
      return {
        ...state,
        timeoffError: action.error,
        isLoading: false,
      };
    case REPLACE_TIMEOFF_SUCCESS:
    case INSERT_TIMEOFF_SUCCESS:
    case REPLACE_TASK_SUCCESS:
    case INSERT_TASK_SUCCESS: {
      const deletions = action.response.delete?.timeoff || [];
      const updates = action.response.timeoff || [];

      if (!deletions.length && !updates.length) {
        return state;
      }

      const replacesTempId =
        action.type !== REPLACE_TIMEOFF_SUCCESS ||
        Boolean(action.response.timeoff?.length);

      let timeoffs = deleteTimeoffs(
        { ...state.timeoffs },
        deletions,
        replacesTempId,
      );

      timeoffs = replaceTimeoffs(timeoffs, updates, action.item?.temporaryId);

      return {
        ...state,
        timeoffs,
      };
    }
    case PEOPLE_DELETED: {
      if (!action.person?.people_id) {
        return state;
      }
      const peopleId = action.person.people_id;
      const filtered = Object.values(state.timeoffs).filter(
        (timeoff) =>
          timeoff.people_ids.length > 1 || timeoff.people_ids[0] != peopleId,
      );
      return {
        ...state,
        timeoffs: keyBy(filtered, 'timeoff_id'),
      };
    }
    case PEOPLE_BULK_DELETED: {
      const ids = (action.ids || []).map((id) => Number(id));
      if (!ids || !ids.length) {
        return state;
      }
      const filtered = Object.values(state.timeoffs).filter(
        (timeoff) =>
          timeoff.people_ids.length > 1 || !ids.includes(timeoff.people_ids[0]),
      );
      return {
        ...state,
        timeoffs: keyBy(filtered, 'timeoff_id'),
      };
    }
    case DELETE_TIMEOFF_TYPE_SUCCESS: {
      const { id: timeoffTypeId = null } = action;
      if (!timeoffTypeId) {
        return state;
      }

      const timeoffs = omitBy(state.timeoffs, (timeoff) => {
        if (timeoff.data_type !== 'search') {
          return timeoff.timeoff_type_id == timeoffTypeId;
        }

        return false;
      });

      return {
        ...state,
        timeoffs,
      };
    }
    case PEOPLE_UPDATED: {
      const {
        isNew,
        regionHolidayIds,
        person: { people_id = null } = {},
      } = action;
      if (!isNew) {
        return state;
      }

      const timeoffs = { ...state.timeoffs };

      for (const timeoff of Object.values(timeoffs)) {
        if (
          timeoff.data_type !== 'search' &&
          isRegionHoliday(timeoff) &&
          isPartOfPersonHoliday(timeoff, regionHolidayIds) &&
          people_id &&
          !timeoff.people_ids.includes(people_id)
        ) {
          timeoffs[timeoff.timeoff_id] = {
            ...timeoff,
            people_ids: timeoff.people_ids.concat(people_id),
          };
        }
      }

      return {
        ...state,
        timeoffs,
      };
    }
    default:
      return state;
  }
};

export default timeoffs;
