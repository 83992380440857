import {
  ADD_TRELLO_CO_INT,
  ADD_TRELLO_CO_INT_FAILURE,
  ADD_TRELLO_CO_INT_SUCCESS,
  CLEAR_TRELLO_PRELOAD,
  FETCH_TRELLO_CO_INTS,
  FETCH_TRELLO_CO_INTS_FAILURE,
  FETCH_TRELLO_CO_INTS_SUCCESS,
  FETCH_TRELLO_CONFIG,
  FETCH_TRELLO_CONFIG_FAILURE,
  FETCH_TRELLO_CONFIG_SUCCESS,
  FETCH_TRELLO_OAUTH_LINK,
  FETCH_TRELLO_OAUTH_LINK_FAILURE,
  FETCH_TRELLO_OAUTH_LINK_SUCCESS,
  FETCH_TRELLO_PRELOAD,
  FETCH_TRELLO_PRELOAD_FAILURE,
  FETCH_TRELLO_PRELOAD_SUCCESS,
  LINK_TRELLO,
  LINK_TRELLO_FAILURE,
  LINK_TRELLO_SUCCESS,
  REMOVE_TRELLO_CO_INT,
  REMOVE_TRELLO_CO_INT_FAILURE,
  REMOVE_TRELLO_CO_INT_SUCCESS,
  TRELLO_IMPORT_SUCCESS,
  UPDATE_TRELLO_CONFIG,
  UPDATE_TRELLO_CONFIG_FAILURE,
  UPDATE_TRELLO_CONFIG_SUCCESS,
} from '../actions/trello';

const DEFAULT_STATE = {
  oauthLink: null,
  coInts: [],
  preloadData: {},
};

export default function teamwork(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    /* FETCH OAUTH LINK */

    case FETCH_TRELLO_OAUTH_LINK: {
      return {
        ...state,
        oauthLinkLoadState: 'LOADING',
      };
    }

    case FETCH_TRELLO_OAUTH_LINK_SUCCESS: {
      return {
        ...state,
        oauthLinkLoadState: 'LOAD_SUCCESS',
        oauthLink: action.oauthLink,
      };
    }

    case FETCH_TRELLO_OAUTH_LINK_FAILURE: {
      return {
        ...state,
        oauthLinkLoadState: 'LOAD_FAILED',
      };
    }

    /* FETCH COINT LIST */

    case FETCH_TRELLO_CO_INTS: {
      return {
        ...state,
        coIntsLoadState: 'LOADING',
      };
    }

    case FETCH_TRELLO_CO_INTS_SUCCESS: {
      return {
        ...state,
        coIntsLoadState: 'LOAD_SUCCESS',
        coInts: action.coInts,
      };
    }

    case FETCH_TRELLO_CO_INTS_FAILURE: {
      return {
        ...state,
        coIntsLoadState: 'LOAD_FAILED',
      };
    }

    /* TRELLO IMPORT BEHAVIORS */
    case TRELLO_IMPORT_SUCCESS: {
      return {
        ...state,
        coIntsLoadState: 'LOAD_SUCCESS',
        coInts: action.coInts,
        showImport: action.initialImport,
        initialImportStarted: false,
      };
    }

    /* FETCH PRELOAD DATA */

    case FETCH_TRELLO_PRELOAD: {
      return {
        ...state,
        preloadLoadState: 'LOADING',
      };
    }

    case CLEAR_TRELLO_PRELOAD: {
      return {
        ...state,
        preloadLoadState: null,
        preloadData: null,
      };
    }

    case FETCH_TRELLO_PRELOAD_SUCCESS: {
      return {
        ...state,
        preloadLoadState: 'LOAD_SUCCESS',
        preloadData: action.preloadData,
      };
    }

    case FETCH_TRELLO_PRELOAD_FAILURE: {
      return {
        ...state,
        preloadLoadState: 'LOAD_FAILED',
      };
    }

    /* LINK TRELLO ACCOUNT */

    case LINK_TRELLO: {
      return {
        ...state,
        linkTrelloLoadState: 'LOADING',
      };
    }

    case LINK_TRELLO_SUCCESS: {
      return {
        ...state,
        linkTrelloLoadState: 'LOAD_SUCCESS',
      };
    }

    case LINK_TRELLO_FAILURE: {
      return {
        ...state,
        linkTrelloLoadState: 'LOAD_FAILED',
      };
    }

    /* FETCH TRELLO CONFIG */

    case FETCH_TRELLO_CONFIG: {
      return {
        ...state,
        fetchConfigLoadState: 'LOADING',
      };
    }

    case FETCH_TRELLO_CONFIG_SUCCESS: {
      return {
        ...state,
        fetchConfigLoadState: 'LOAD_SUCCESS',
        trelloConfig: action.trelloConfig,
      };
    }

    case FETCH_TRELLO_CONFIG_FAILURE: {
      return {
        ...state,
        fetchConfigLoadState: 'LOAD_FAILED',
      };
    }

    /* UPDATE TRELLO CONFIG */

    case UPDATE_TRELLO_CONFIG: {
      return {
        ...state,
        updateConfigLoadState: 'LOADING',
        initialImportStarted: action.startInitialImport,
      };
    }

    case UPDATE_TRELLO_CONFIG_SUCCESS: {
      return {
        ...state,
        updateConfigLoadState: 'LOAD_SUCCESS',
        trelloConfig: action.trelloConfig,
      };
    }

    case UPDATE_TRELLO_CONFIG_FAILURE: {
      return {
        ...state,
        updateConfigLoadState: 'LOAD_FAILED',
      };
    }

    /* ADD TRELLO COINT */

    case ADD_TRELLO_CO_INT: {
      return {
        ...state,
        addCoIntLoadState: 'LOADING',
      };
    }

    case ADD_TRELLO_CO_INT_SUCCESS: {
      return {
        ...state,
        addCoIntLoadState: 'LOAD_SUCCESS',
      };
    }

    case ADD_TRELLO_CO_INT_FAILURE: {
      return {
        ...state,
        addCoIntLoadState: 'LOAD_FAILED',
      };
    }

    case REMOVE_TRELLO_CO_INT: {
      return {
        ...state,
        removeCoIntLoadState: 'LOADING',
      };
    }

    case REMOVE_TRELLO_CO_INT_SUCCESS: {
      return {
        ...state,
        removeCoIntLoadState: 'LOAD_SUCCESS',
      };
    }

    case REMOVE_TRELLO_CO_INT_FAILURE: {
      return {
        ...state,
        removeCoIntLoadState: 'LOAD_FAILED',
      };
    }

    default: {
      return state;
    }
  }
}
