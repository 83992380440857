import React from 'react';

export default function ({ size = '14', color = '#E62768' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 14 14"
    >
      <path
        fill={color}
        d="M11 0c1.657 0 3 1.343 3 3v8c0 1.657-1.343 3-3 3H3c-1.657 0-3-1.343-3-3V3c0-1.657 1.343-3 3-3h8zM7 4C5.343 4 4 5.343 4 7s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3z"
      />
    </svg>
  );
}
