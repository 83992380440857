import React from 'react';
import { plural, Trans } from '@lingui/macro';

import { searchService } from '@float/common/search/service';
import { AppStoreStrict } from '@float/common/store';
import { trimTextForReadibility } from '@float/libs/utils/string/trimTextForReadibility';
import { Person } from '@float/types/person';
import { Modal } from '@float/ui/primitives/Modal';
import { IWithModalConfirmDelete } from '@float/web/modalManager/hoc/withModalConfirmDelete';

import { IBulkUpdate } from '../types';

const Content = (props: {
  deletedPerson: string;
  isDeletingAccountOwner: boolean;
}) => {
  const { deletedPerson, isDeletingAccountOwner } = props;

  return (
    <>
      <Modal.P>
        <Trans>
          Archived people do not contribute to your subscription fee. You can
          restore Archived people on the People page.
        </Trans>
      </Modal.P>
      <Modal.P>
        <Trans>Deleting {deletedPerson} will:</Trans>
      </Modal.P>
      <Modal.List>
        <Modal.ListItem>
          <Trans>
            Delete their currently assigned allocations across all projects
          </Trans>
        </Modal.ListItem>
        <Modal.ListItem>
          <Trans>
            Delete their previously logged hours against allocations across all
            projects
          </Trans>
        </Modal.ListItem>
      </Modal.List>

      <Modal.P color="critical">
        <Trans>Deleted people cannot be restored.</Trans>
      </Modal.P>
      {isDeletingAccountOwner && (
        <Modal.P>
          <Trans>
            <strong>Note</strong>: Deleting yourself from the schedule will
            delete all your scheduled and logged allocations. You will keep your
            account access rights and appear as a Guest in the Account Settings.
          </Trans>
        </Modal.P>
      )}
    </>
  );
};

export const showModalConfirmDelete = async (
  ids: number[],
  people: Person[],
  store: AppStoreStrict,
  confirmDelete: IWithModalConfirmDelete['confirmDelete'],
  bulkUpdate: (props: IBulkUpdate) => void,
) => {
  const deletedPerson = plural(ids.length, {
    one: `"${people[ids[0]].name}"`,
    other: '# people',
  });

  const isDeletingAccountOwner = ids.some(
    (id) => people[id]?.account?.account_type === 1,
  );

  const title = plural(ids.length, {
    one: `Delete "${people[ids[0]].name}"?`,
    other: 'Delete # people?',
  });

  const personNameTrimmed = trimTextForReadibility(
    people[ids[0]].name,
    22,
  ).value;
  const confirmButtonLabel = plural(ids.length, {
    one: `Delete ${personNameTrimmed}`,
    other: 'Delete # people',
  });

  const message = (
    <Content
      deletedPerson={deletedPerson}
      isDeletingAccountOwner={isDeletingAccountOwner}
    />
  );

  const peopleWithTasks = await searchService.getSelectorValue(
    store.getState(),
    'getPeopleWithTasks',
    [],
  );

  const isTwoStep = ids.some((id) => peopleWithTasks.has(Number(id)));

  const onDelete = () => {
    return bulkUpdate({ ids, messageSuffix: 'deleted' });
  };

  confirmDelete({
    deleteLabel: confirmButtonLabel,
    message,
    onDelete,
    title,
    twoStep: isTwoStep,
  });
};
