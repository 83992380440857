import { memoizeWithArgs } from 'proxy-memoize';

import { DEFAULT_COLOR } from '@float/constants/colors';

import { ReduxState } from '../reducers/lib/types';

export const getCellItemColor = memoizeWithArgs(
  (
    state: ReduxState,
    phaseId: number | undefined,
    projectId: number | undefined,
  ) => {
    const defaultColor = DEFAULT_COLOR;

    const phase = phaseId ? state.phases.phases[phaseId] : undefined;
    const project = projectId ? state.projects.projects[projectId] : undefined;

    return (phase?.color || project?.color || defaultColor).toLowerCase();
  },
);
