import styled from 'styled-components';

export const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

export const Description = styled.div`
  font-size: 16px;
  line-height: 1.31;
  margin-top: 5px;
`;

export const SmallText = styled.div`
  font-size: 14px;
  line-height: 1.29;
`;

export const Cards = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 11px 0 50px;
`;

export const Card = styled.div`
  width: 50%;
  max-width: 245px;
  min-height: 280px;
  padding: 0 22px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: solid 1px ${(p) => p.theme.grey};
  cursor: pointer;
  color: ${(p) => p.theme.charcoalGrey};
  text-align: center;
  text-decoration: none !important;

  ${Title} {
    margin: 20px 0 10px;
  }

  &:hover {
    border-color: ${(p) => p.theme.medGrey};
  }
`;

export const Img = styled.img`
  margin: 30px auto 0;
  display: block;
  height: 90px;
`;

export const Content = styled.div`
  font-size: 16px;
  line-height: 1.31;
  margin: 5px 0 42px;

  p {
    margin-bottom: 11px;
  }

  strong {
    font-size: 18px;
    margin-bottom: 5px;
    display: block;
  }

  .icon-attach path {
    fill: #fff;
  }
`;

export const Table = styled.div`
  color: ${(p) => p.theme.blueGrey};
  font-size: 14px;
  margin: 30px 0 10px;
`;

export const Tr = styled.div`
  display: flex;
  min-height: 44px;
  padding: 10px 0;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.lightGrey};
`;

export const Td = styled.div`
  &:first-child {
    width: 100px;
    padding-left: 20px;
  }

  &:last-child {
    flex-grow: 1;
    color: ${(p) => (p.duplicate ? p.theme.charcoalGrey : p.theme.red)};
  }
`;

export const Th = styled(Td)`
  color: ${(p) => p.theme.blueGrey} !important;
`;
