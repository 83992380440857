import React from 'react';

export default function () {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.75 8.25H18.25C18.8023 8.25 19.25 7.9651 19.25 7.61366V5.38633C19.25 5.0349 18.8023 4.75 18.25 4.75H5.75C5.19772 4.75 4.75 5.0349 4.75 5.38634V7.61367C4.75 7.9651 5.19772 8.25 5.75 8.25Z"
        className="stroke"
        stroke="#344765"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.75 19.25H18.25C18.8023 19.25 19.25 18.6395 19.25 17.8864V13.1136C19.25 12.3605 18.8023 11.75 18.25 11.75H5.75C5.19772 11.75 4.75 12.3605 4.75 13.1136V17.8864C4.75 18.6395 5.19772 19.25 5.75 19.25Z"
        className="stroke"
        stroke="#344765"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export const CompactControl = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M5.75 7.25H18.25C18.8023 7.25 19.25 7.0465 19.25 6.79547V5.20452C19.25 4.9535 18.8023 4.75 18.25 4.75H5.75C5.19772 4.75 4.75 4.9535 4.75 5.20453V6.79548C4.75 7.0465 5.19772 7.25 5.75 7.25Z"
        stroke="#2E5FE8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M5.75 13.25H18.25C18.8023 13.25 19.25 13.0465 19.25 12.7955V11.2045C19.25 10.9535 18.8023 10.75 18.25 10.75H5.75C5.19772 10.75 4.75 10.9535 4.75 11.2045V12.7955C4.75 13.0465 5.19772 13.25 5.75 13.25Z"
        stroke="#2E5FE8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M5.75 19.25H18.25C18.8023 19.25 19.25 19.0465 19.25 18.7955V17.2045C19.25 16.9535 18.8023 16.75 18.25 16.75H5.75C5.19772 16.75 4.75 16.9535 4.75 17.2045V18.7955C4.75 19.0465 5.19772 19.25 5.75 19.25Z"
        stroke="#2E5FE8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ConfortableControl = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M5.75 9.75H18.25C18.8023 9.75 19.25 9.343 19.25 8.84095V5.65905C19.25 5.157 18.8023 4.75 18.25 4.75H5.75C5.19772 4.75 4.75 5.157 4.75 5.65905V8.84095C4.75 9.343 5.19772 9.75 5.75 9.75Z"
        stroke="#2E5FE8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M5.75 19.25H18.25C18.8023 19.25 19.25 18.843 19.25 18.3409V15.159C19.25 14.657 18.8023 14.25 18.25 14.25H5.75C5.19772 14.25 4.75 14.657 4.75 15.1591V18.341C4.75 18.843 5.19772 19.25 5.75 19.25Z"
        stroke="#2E5FE8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SpaciousControl = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M5.75 10.75H18.25C18.8023 10.75 19.25 10.2616 19.25 9.65914V5.84086C19.25 5.23839 18.8023 4.75 18.25 4.75H5.75C5.19772 4.75 4.75 5.2384 4.75 5.84086V9.65914C4.75 10.2616 5.19772 10.75 5.75 10.75Z"
        stroke="#344765"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M5.75 19.25H18.25C18.8023 19.25 19.25 18.7616 19.25 18.1591V14.3409C19.25 13.7384 18.8023 13.25 18.25 13.25H5.75C5.19772 13.25 4.75 13.7384 4.75 14.3409V18.1591C4.75 18.7616 5.19772 19.25 5.75 19.25Z"
        stroke="#344765"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
