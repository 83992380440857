import { ajaxForm } from './request/ajax-form';
import { del } from './request/del';
import { form } from './request/form';
import { put } from './request/put';
import { get, post, send } from './request/send';
import { upload } from './request/upload';
import { uploadAws } from './request/upload-aws';

export { fetchReport } from './request/fetchReport';
export { fetchWrapper } from './request/fetchWrapper';

const request = {
  get,
  post,
  put,
  del,
  form,
  ajaxForm,
  uploadAws,
  upload,
  send,
};

export default request;
