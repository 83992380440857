import React from 'react';

export default function ({ size = 24, color = '#FFA000', style = {} }) {
  return (
    <svg
      style={style}
      width={size}
      height={size}
      viewBox={`0 0 40 40`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8333 25.4167C9.22246 25.4167 7.91663 24.1109 7.91663 22.5V11.25C7.91663 9.40907 9.40901 7.91669 11.25 7.91669H22.5C24.1108 7.91669 25.4166 9.22252 25.4166 10.8334"
        stroke="#2E5FE8"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.8334 32.0833H17.9167C16.0758 32.0833 14.5834 30.5909 14.5834 28.75V17.9166C14.5834 16.0757 16.0758 14.5833 17.9167 14.5833H28.75C30.591 14.5833 32.0834 16.0757 32.0834 17.9166V21.6666"
        stroke="#2E5FE8"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.3334 24.5833V32.0833"
        stroke="#2E5FE8"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.0834 28.3333L24.5834 28.3333"
        stroke="#2E5FE8"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
