import styled from 'styled-components';

import * as Colors from '../Earhart/Colors';
import * as Typography from '../Earhart/Typography';

export const SecondaryText = styled.div`
  ${Typography.Label12.R400};

  color: ${Colors.FIN.Lt.Emphasis.Medium};

  margin-top: 6px;
  padding-bottom: 2px;
`;

export const ErrorText = styled(SecondaryText)`
  display: inline-flex;
  align-items: center;
  color: ${Colors.FIN.Lt.Danger.Default};

  svg {
    --svg-icon-color: ${Colors.FIN.Lt.Danger.Default};
  }
`;

export const ActionText = styled.button`
  font-size: 14px;
  color: ${(p) => p.theme.blueGrey};
  line-height: 18px;
  letter-spacing: -0.2px;
  text-decoration: underline;
`;

export const PlainText = styled.span`
  font-size: 16px;
  color: #242c39;
  font-weight: 500;
  line-height: 16px;
`;
