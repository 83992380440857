/**
 * Safari will silently decline to print in some circumstance when called
 * via `window.print()` so we have to use a hack.
 */
export const print = () => {
  try {
    if (!document.execCommand('print', false)) {
      window.print();
    }
  } catch {
    window.print();
  }
};
