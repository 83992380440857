import { useCallback, useEffect, useMemo } from 'react';

function useFocusTrap(
  parentElementRef: React.RefObject<HTMLElement>,
  selector: string,
  enabled: boolean,
) {
  const parentElement = parentElementRef.current;

  const focusableElements = useMemo(() => {
    return parentElement?.querySelectorAll<HTMLElement>(selector);
  }, [parentElement, selector]);

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key !== 'Tab' || !enabled) return;

      if (focusableElements && focusableElements.length) {
        const total = focusableElements.length;

        const firstElement = focusableElements[0];
        const lastElement = focusableElements[total - 1];

        // if going forward by pressing tab and lastElement is active shift focus to first focusable element
        if (!e.shiftKey && document.activeElement === lastElement) {
          if (firstElement instanceof HTMLInputElement) {
            firstElement.select();
          } else {
            firstElement.focus();
          }

          e.preventDefault();

          return;
        }

        // if going backward by pressing tab and firstElement is active shift focus to last focusable element
        if (e.shiftKey && document.activeElement === firstElement) {
          if (lastElement instanceof HTMLInputElement) {
            lastElement.select();
          } else {
            lastElement.focus();
          }
          e.preventDefault();
        }
      }
    },
    [enabled, focusableElements],
  );

  useEffect(() => {
    const { current } = parentElementRef;
    current?.addEventListener('keydown', onKeyDown);

    return () => {
      current?.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown, parentElementRef]);

  return null;
}

export { useFocusTrap };
