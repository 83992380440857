import React from 'react';
import { FieldPath, useWatch } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';

import { BudgetPriority, BudgetType } from '@float/types/project';
import * as Accordion from '@float/ui/components/Accordion';
import { Tag } from '@float/ui/components/Tag';

import { SectionCounterTag } from '../../components/SectionCounterTag';
import { SectionErrorTag } from '../../components/SectionErrorTag';
import { SidePanelAddRowButton } from '../../components/SidePanelTableSection/SidePanelAddRowButton';
import { SidePanelEmptyTable } from '../../components/SidePanelTableSection/SidePanelEmptyTable';
import { createEmptyPhaseRecord } from '../../helpers/createRowEntities';
import { AccordionEntry } from '../../hooks/useAccordionState';
import { useFieldArrayContext } from '../../hooks/useFieldArrayContext';
import { ProjectFormData } from '../../types';
import { ProjectPhasesTable } from './ProjectPhasesTable';

import {
  emptyLine,
  sidePanelTableWrapper,
} from '../../components/SidePanelTableSection/styles.css';
import {
  projectAccordionSummary,
  projectSectionHeader,
  showWhenCollapsed,
} from '../../styles.css';
import * as styles from './styles.css';

function ProjectPhasesTitle() {
  return (
    <div className={projectSectionHeader}>
      <Trans>Phases</Trans>
      <SectionCounterTag name="phases" />
    </div>
  );
}

function ProjectPhasesInfo() {
  const budgetType = useWatch<ProjectFormData, 'project.budget_type'>({
    name: 'project.budget_type',
  });
  const budgetPriority = useWatch<ProjectFormData, 'project.budget_priority'>({
    name: 'project.budget_priority',
  });

  const budgetPerPhase = budgetPriority === BudgetPriority.Phase;
  const budgetPerTask = budgetPriority === BudgetPriority.Task;

  let budgetTag = '';

  if (budgetPerPhase) {
    if (budgetType === BudgetType.TotalHours) {
      budgetTag = t`Hours by phase`;
    } else if (budgetType === BudgetType.TotalFee) {
      budgetTag = t`Fee by phase`;
    }
  }

  if (budgetPerTask) {
    if (budgetType === BudgetType.TotalHours) {
      budgetTag = t`Hours by task`;
    } else if (budgetType === BudgetType.TotalFee) {
      budgetTag = t`Fee by task`;
    }
  }

  return (
    <div className={projectAccordionSummary}>
      {budgetTag && (
        <div className={showWhenCollapsed}>
          <Tag color="primary" data-testid="phase-budget-tag">
            {budgetTag}
          </Tag>
        </div>
      )}
      <SectionErrorTag fields={['phases']} />
    </div>
  );
}

function ProjectPhaseEmptyMessage() {
  return (
    <>
      <Trans>Add a phase</Trans>
      <div className={styles.emptyPhaseColumns}>
        <div className={emptyLine} />
        <div className={emptyLine} />
      </div>
    </>
  );
}

export const ProjectPhasesSection = () => {
  const { fields } = useFieldArrayContext();
  const lastPhaseName = useWatch<ProjectFormData, FieldPath<ProjectFormData>>({
    name: `phases.${fields.length - 1}.phase_name`,
  });
  const isProjectActive = useWatch<ProjectFormData, FieldPath<ProjectFormData>>(
    {
      name: 'project.active',
    },
  );

  const isEmptyState = fields.length === 0;
  const isAddingANewRow = lastPhaseName === '';
  const addDisabled = !isProjectActive || isAddingANewRow;

  return (
    <Accordion.Item value={AccordionEntry.phases}>
      <Accordion.Header
        className={styles.header}
        info={<ProjectPhasesInfo />}
        data-callout-id="project-setup-phases"
      >
        <ProjectPhasesTitle />
      </Accordion.Header>
      <Accordion.Content>
        <div className={sidePanelTableWrapper}>
          {isEmptyState ? (
            <SidePanelEmptyTable<'phases'>
              className={styles.emptyPhase}
              label={t`Phase`}
              data={createEmptyPhaseRecord}
              disabled={addDisabled}
            >
              <ProjectPhaseEmptyMessage />
            </SidePanelEmptyTable>
          ) : (
            <ProjectPhasesTable addDisabled={addDisabled} />
          )}
          <SidePanelAddRowButton<'phases'>
            data={createEmptyPhaseRecord}
            buttonText={t`Add a phase`}
            disabled={addDisabled}
          />
        </div>
      </Accordion.Content>
    </Accordion.Item>
  );
};
