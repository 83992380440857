export const DEFAULT_STATUS_COLOR = '#14bfff';

export const DEFAULT_PALETTE = [
  '3451b2',
  'd23197',
  'ecadd4',
  '793aaf',
  'd3b4ed',
  '067a6f',
  '69d9c1',
  '0b752e',
  '34c754',
  'd24023',
  'ed5f00',
  'ffb224',
  'f7ce00',
  '173074',
  '0078a1',
  '2e5fe8',
  '5eb0ef',
  '68ddfd',
  '242c39',
  '344765',
  'bfcbdc',
  '706f6c',
  '776750',
  'cbbda4',
];

export const DEFAULT_COLOR = '0095D8';
