import React from 'react';

import Loader from '@float/common/components/elements/Loader';

import { StyledRow } from '../styles';

const LoaderRow = () => (
  <StyledRow key="loader-row" className="loader-row">
    <td colSpan={100}>
      <Loader />
    </td>
  </StyledRow>
);

export default LoaderRow;
