import React from 'react';

import { Checkbox } from '@float/ui/deprecated/Checkbox';
import Icons from '@float/ui/deprecated/Icons';
import { Popover } from '@float/ui/deprecated/Tooltip/Popover';

import * as styled from './styles';

const renderSelectedLabel = (options) => {
  const labels = [];
  options.forEach((option) => {
    if (option.isSelected) {
      labels.push(option.label);
    }
  });
  return labels.join(', ');
};

const Options = ({ value }) => {
  return (
    <styled.Options>
      {value.map((o) => (
        <Checkbox
          key={o.label}
          label={o.label}
          value={o.isSelected}
          onChange={o.onChange}
        />
      ))}
    </styled.Options>
  );
};

const Viewing = ({ optionsLeft = [], optionsRight = [], actionLeft }) => {
  return (
    <styled.Action>
      {actionLeft}
      <Popover
        arrow={false}
        placement="bottom"
        align="start"
        animation={false}
        distance={10}
        content={
          <styled.OptionsContainer>
            <Options value={optionsLeft} />
            <Options value={optionsRight} />
          </styled.OptionsContainer>
        }
      >
        <styled.ActionLink>
          <styled.Value>
            {renderSelectedLabel([...optionsLeft, ...optionsRight])}
          </styled.Value>
          <Icons.DownSmall className="desktop" />
        </styled.ActionLink>
      </Popover>
    </styled.Action>
  );
};

export default Viewing;
