import React from 'react';

import * as Icons from '@float/ui/deprecated/Earhart/Icons';

const DownloadCell = ({ cell = {} }) => (
  <td>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={cell.href}
      title={cell.text}
    >
      <Icons.IconDownload />
    </a>
  </td>
);

export default DownloadCell;
