import React from 'react';

import { Icons } from '@float/ui/deprecated';

import PmSidebarDropdown, { DROPDOWN_TYPES } from './PmSidebarDropdown';
import * as styled from './styles';

export default function PmSidebarHeader({
  title,
  filterOptions,
  sortOptions,
  onClose,
  type,
}) {
  return (
    <styled.Header>
      <styled.HeaderTopRow>
        <styled.Title>{title}</styled.Title>
        <styled.Close onClick={onClose}>
          <Icons.CloseSmall fillColor="blueGrey" />
        </styled.Close>
      </styled.HeaderTopRow>

      <styled.HeaderBottomRow>
        {filterOptions && (
          <PmSidebarDropdown
            type={DROPDOWN_TYPES.FILTER}
            options={filterOptions}
          />
        )}
        <PmSidebarDropdown type={DROPDOWN_TYPES.SORT} options={sortOptions} />
      </styled.HeaderBottomRow>
    </styled.Header>
  );
}
