import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { t } from '@lingui/macro';
import { Moment } from 'moment';

import DateRangePicker from '@float/common/components/DatePicker/DateRangePicker';
import { getDateRangeText as _getDateRangeText } from '@float/common/lib/utils';
import { getUser } from '@float/common/selectors/currentUser';
import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import { useAppSelectorStrict, useAppStoreStrict } from '@float/common/store';
import { moment } from '@float/libs/moment';
import {
  getInitialTimeRange,
  getTimeRangeCacheValue,
  getTimeRangeOpts,
  MAX_DAYS,
} from '@float/libs/timeRange';
import { EH, Icons, Popover } from '@float/ui/deprecated';
import { PaginationArrow } from '@float/ui/deprecated/DateRangePicker/DateRangePicker';

import { InsightsControlUnit } from './subcomponents/InsightsControlUnit/InsightsControlUnit';
import {
  DateRangePickerWrapper,
  TimeRange,
  TimeRangePickerBg,
  TimeRangeText,
} from './TimeRangeInsights.styles';
import type {
  InsightsPreferredUnit,
  TimeRangeOption,
  TimeRangePickerEntity,
} from './types';

import * as styles from './TimeRangePicker.css';

const wrapperStyle = {
  outline: 'none',
  marginRight: 4,
};

function getDateRangeText(
  start: string,
  end: string,
  rangeMode: string,
  includeYear = false,
) {
  if (rangeMode !== 'custom') {
    const opt = getTimeRangeOpts().find((x) => x.value === rangeMode);
    if (opt) return opt.label;
  }
  return _getDateRangeText({ start, end, includeYear });
}

type TimeRangePickerProps = {
  component: React.ReactNode;
  start: Moment | string;
  end: Moment | string;
  rangeMode: string;
  insightsPreferredUnit: InsightsPreferredUnit;
  hasInsightsPreferredUnitToggle: boolean;
  onInsightsPreferredUnitChange: (metric: InsightsPreferredUnit) => void;
  onChange: (start: Moment, end: Moment, mode: string) => void;
};

function TimeRangePicker({
  component,
  start,
  end,
  rangeMode,
  insightsPreferredUnit,
  hasInsightsPreferredUnitToggle,
  onInsightsPreferredUnitChange,
  onChange,
}: TimeRangePickerProps) {
  const [showing, setShowing] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [maxDate, setMaxDate] = useState<Date | null>(null);
  const [minDate, setMinDate] = useState<Date | null>(null);

  const firstOfWeek = useAppSelectorStrict(
    (state) => state.companyPrefs.start_work_week,
  );
  const momentRange = moment.range(moment(start), moment(end));

  const closePicker = () => {
    setShowing(false);
    setShowPicker(false);
  };

  const changeDate = (start: Moment, end: Moment, mode: string) => {
    onChange(start, end, mode);
    setMaxDate(null);
    setMinDate(null);
    closePicker();
  };

  const onPresetChange = (opt: TimeRangeOption) => {
    if (opt.value === 'custom' || !opt.start || !opt.end) {
      setShowPicker(true);
      return;
    }

    changeDate(opt.start(), opt.end(), opt.value);
  };

  const onDateRangeChange = ({
    start,
    end,
  }: {
    start: Moment;
    end: Moment;
  }) => {
    changeDate(start, end, 'custom');
  };

  const onDateRangeSelectStart = (date: Moment) => {
    const maxDate = date.clone().add(MAX_DAYS, 'days').toDate();
    const minDate = date.clone().subtract(MAX_DAYS, 'days').toDate();
    setMaxDate(maxDate);
    setMinDate(minDate);
  };

  const onBgClick = () => {
    if (showPicker) {
      setShowPicker(false);
      return;
    }
    closePicker();
  };

  const renderOptionLabel = (option: TimeRangeOption, selected: boolean) => {
    return option.value === 'custom' && selected
      ? getDateRangeText(start.toString(), end.toString(), rangeMode, true)
      : option.label;
  };

  return (
    <>
      <Popover
        className="menu overflow"
        placement="bottom"
        align="start"
        distance={5}
        arrow={false}
        content={
          <>
            <EH.List
              // @ts-expect-error providing 'value' causes Property 'value' does not exist on type 'IntrinsicAttributes & RefAttributes<any>'
              value={rangeMode}
              options={getTimeRangeOpts()}
              minWidth="200px"
              renderLabel={renderOptionLabel}
              onClick={onPresetChange}
            />
            {hasInsightsPreferredUnitToggle && (
              <div className={styles.wrapper}>
                <div className={styles.separator} />
                <footer className={styles.footer}>
                  <InsightsControlUnit
                    value={insightsPreferredUnit}
                    onChange={(unit) => {
                      onInsightsPreferredUnitChange(unit);
                    }}
                  />
                </footer>
              </div>
            )}
            {showPicker && (
              <DateRangePickerWrapper>
                <DateRangePicker
                  numberOfCalendars={1}
                  selectionType="range"
                  singleDateRange
                  paginationArrowComponent={PaginationArrow}
                  neverDisablePagination
                  firstOfWeek={firstOfWeek}
                  value={momentRange}
                  maximumDate={maxDate}
                  minimumDate={minDate}
                  onSelect={onDateRangeChange}
                  onSelectStart={onDateRangeSelectStart}
                />
              </DateRangePickerWrapper>
            )}
          </>
        }
        open={showing}
        onOpenChange={(open) => {
          if (open) {
            setShowing(true);
          } else {
            setShowPicker(false);
            setShowing(false);
          }
        }}
      >
        <div
          className="time-range-picker-wrapper"
          data-callout-id="date-range-callout"
          style={wrapperStyle}
        >
          {component}
        </div>
      </Popover>
      {showing &&
        createPortal(<TimeRangePickerBg onClick={onBgClick} />, document.body)}
    </>
  );
}

type TimeRangePickerWrapperProps = {
  insightsPreferredUnit: InsightsPreferredUnit;
  hasInsightsPreferredUnitToggle: boolean;
  onInsightsPreferredUnitChange: (metric: InsightsPreferredUnit) => void;
  onChange: (
    originalEntity: TimeRangePickerEntity,
    newEntity: TimeRangePickerEntity,
  ) => void;
};

export function TimeRangePickerWrapper({
  insightsPreferredUnit,
  hasInsightsPreferredUnitToggle,
  onInsightsPreferredUnitChange,
  onChange,
}: TimeRangePickerWrapperProps) {
  const store = useAppStoreStrict();

  const handleChange = useCallback(
    (
      startMoment: Moment,
      endMoment: Moment,
      rangeMode: string,
      force = false,
    ) => {
      const start = startMoment.format('YYYY-MM-DD');
      const end = endMoment.format('YYYY-MM-DD');
      const originalEntity = getTimeRangeCacheValue(getUser(store.getState()));
      originalEntity.time_range_id = 1;

      if (
        force ||
        start !== originalEntity.start_date ||
        end !== originalEntity.end_date ||
        rangeMode !== originalEntity.range_mode
      ) {
        const newEntity = {
          ...originalEntity,
          disabled: false,
          start_date: start,
          end_date: end,
          range_mode: rangeMode,
        };

        onChange(originalEntity, newEntity);
      }
    },
    [onChange, store],
  );

  const { timeRange } = useScheduleContext();

  const { start_date: start, end_date: end, range_mode: rangeMode } = timeRange;

  // If the user leaves his browser open overnight, we need to update
  // "time range" if a preset is chosen like "Today", "This week" etc.
  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (rangeMode !== 'custom') {
      intervalId = setInterval(() => {
        const newTimeRange = getInitialTimeRange(getUser(store.getState()));
        if (
          newTimeRange.start_date !== start ||
          newTimeRange.end_date !== end
        ) {
          handleChange(
            moment(newTimeRange.start_date),
            moment(newTimeRange.end_date),
            newTimeRange.range_mode,
            true,
          );
        }
      }, 60 * 1000);
    }

    return () => intervalId && clearInterval(intervalId);
  }, [rangeMode, start, end, handleChange, store]);

  const label = (
    <TimeRange isCustom={rangeMode === 'custom'}>
      <TimeRangeText>
        {start && end
          ? getDateRangeText(start, end, rangeMode)
          : t`Select dates`}
      </TimeRangeText>
      <Icons.DownSmall />
    </TimeRange>
  );

  return (
    <TimeRangePicker
      component={label}
      start={start}
      end={end}
      rangeMode={rangeMode}
      onChange={handleChange}
      insightsPreferredUnit={insightsPreferredUnit}
      onInsightsPreferredUnitChange={onInsightsPreferredUnitChange}
      hasInsightsPreferredUnitToggle={hasInsightsPreferredUnitToggle}
    />
  );
}
