export const REPEAT_INTERVAL = {
  0: "Doesn't repeat",
  1: 'Weekly',
  2: 'Monthly',
  3: 'Every 2 weeks',
  4: 'Every 3 weeks',
  5: 'Every 6 weeks',
  6: 'Every 2 months',
  7: 'Every 3 months',
  8: 'Every 6 months',
  9: 'Yearly',
} as const;

export type RepeatIntervalKey = keyof typeof REPEAT_INTERVAL;
export type RepeatIntervalDescription =
  (typeof REPEAT_INTERVAL)[RepeatIntervalKey];

export const REPEAT_INTERVAL_ORDER: RepeatIntervalKey[] = [
  0, 1, 3, 4, 2, 5, 6, 7, 8, 9,
];
