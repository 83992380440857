import React, { ComponentType, FC, ReactElement } from 'react';

import {
  ModalConfirm,
  ModalConfirmAppearance,
} from '@float/ui/components/Modals/ModalConfirm';
import { IUseModalLocal, useModalLocal } from '@float/ui/hooks/useModalLocal';

export type ModalConfirmDeleteConfig = {
  deleteLabel?: string;
  description?: string;
  message?: ReactElement;
  onDelete: () => void;
  title: string;
  twoStep: boolean;
};

export type IWithModalConfirmDelete = {
  confirmDelete: (config: ModalConfirmDeleteConfig) => void;
  confirmDeleteClose: () => void;
};

export const Modal = (modal: IUseModalLocal<ModalConfirmDeleteConfig>) => {
  const { renderProps } = modal;

  if (!renderProps) return null;

  const { deleteLabel, description, message, onDelete, title, twoStep } =
    renderProps;

  return (
    <ModalConfirm
      appearance={ModalConfirmAppearance.Danger}
      description={description || ''}
      modalProps={modal.props}
      onClickConfirm={onDelete}
      title={title}
      twoStepConfirmation={twoStep}
      primaryButtonLabel={deleteLabel}
    >
      {message}
    </ModalConfirm>
  );
};

export const withModalConfirmDelete = <P extends object>(
  Comp: ComponentType<P>,
): FC<Omit<P, keyof IWithModalConfirmDelete>> => {
  return (props) => {
    const modal = useModalLocal<ModalConfirmDeleteConfig>();

    const onConfirmDeleteHandler = (config: ModalConfirmDeleteConfig) => {
      modal.controls.openModal(config);
    };

    const onConfirmDeleteCloseHandler = () => {
      modal.controls.closeModal();
    };

    return (
      <>
        <Comp
          {...(props as P)}
          confirmDelete={onConfirmDeleteHandler}
          confirmDeleteClose={onConfirmDeleteCloseHandler}
        />

        {modal.controls.present && <Modal {...modal} />}
      </>
    );
  };
};
