import React from 'react';
import { Plural, plural, Trans } from '@lingui/macro';

import { trimTextForReadibility } from '@float/libs/utils/string/trimTextForReadibility';
import { ProjectTaskRecord } from '@float/types/task';
import { Modal } from '@float/ui/primitives/Modal';
import { IWithModalConfirmDelete } from '@float/web/modalManager/hoc/withModalConfirmDelete';

type DeleteImpact = { scheduledTasks: number; loggedTasks: number } | false;
type DeleteMessageProps = {
  tasks: ProjectTaskRecord[];
  deleteImpact: DeleteImpact;
};

const DeleteMessage = (props: DeleteMessageProps) => {
  const { tasks, deleteImpact } = props;

  const taskName = tasks[0].task_name ? `"${tasks[0].task_name}"` : 'this task';

  return (
    <>
      <Modal.P>
        <Plural
          value={tasks.length}
          one={`Deleting ${taskName} will:`}
          other={'Deleting these # tasks will:'}
        />
      </Modal.P>

      <Modal.List>
        {deleteImpact && deleteImpact.scheduledTasks > 0 && (
          <Modal.ListItem>
            <Plural
              value={deleteImpact.scheduledTasks}
              one={'Delete # allocation'}
              other={'Delete # allocations'}
            />
          </Modal.ListItem>
        )}
        {deleteImpact && deleteImpact.loggedTasks > 0 && (
          <Modal.ListItem>
            <Plural
              value={deleteImpact.loggedTasks}
              one={'Delete # logged time allocation'}
              other={'Delete # logged time allocations'}
            />
          </Modal.ListItem>
        )}
      </Modal.List>

      <Modal.P color="critical">
        <Trans>Deleted tasks cannot be restored.</Trans>
      </Modal.P>
    </>
  );
};

type ShowModalConfirmTaskDeleteProps = Pick<
  IWithModalConfirmDelete,
  'confirmDelete'
> & {
  impact: DeleteImpact;
  tasks: ProjectTaskRecord[];
  onDelete: () => void;
};

export const showModalConfirmTaskDelete = (
  props: ShowModalConfirmTaskDeleteProps,
) => {
  const { impact, tasks, confirmDelete, onDelete } = props;

  const titleTaskName = plural(tasks.length, {
    one: tasks[0].task_name ? `"${tasks[0].task_name}" task` : 'this task',
    other: '# tasks',
  });

  const title = plural(tasks.length, {
    one: `Delete ${titleTaskName}?`,
    other: 'Delete # tasks?',
  });

  const taskNameTrimmed = tasks[0].task_name
    ? `${trimTextForReadibility(tasks[0].task_name, 22).value}`
    : 'task';

  const buttonDeleteLabel = plural(tasks.length, {
    one: `Delete ${taskNameTrimmed}`,
    other: 'Delete # tasks',
  });

  confirmDelete({
    deleteLabel: buttonDeleteLabel,
    message: <DeleteMessage tasks={tasks} deleteImpact={impact} />,
    onDelete,
    title,
    twoStep: !!impact,
  });
};
