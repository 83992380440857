import { ReactElement, ReactNode } from 'react';
import { t } from '@lingui/macro';

import { useAppDispatchDecorator } from '@float/common/store';
import { IconCheck, IconTrash } from '@float/ui/deprecated/Earhart/Icons';
import { BaseIconProps } from '@float/ui/deprecated/Earhart/Icons/Icon/BaseIcon';
import IconWarningTriangle from '@float/ui/deprecated/Earhart/Icons/Icon/IconWarningTriangle';
import useSnackbar from '@float/ui/deprecated/Snackbar/useSnackbar';

import { undo } from '../undo/actions';

export type SnackbarMessages<P> = {
  loading?: (props: P) => ReactNode;
  success?: (props: P) => ReactNode;
  undoLoading?: (props: P) => ReactNode;
  undoSuccess?: (props: P) => ReactNode;
  undoError?: (props: P) => ReactNode;
  error: (props: P) => ReactNode;
};

const DEFAULT_AUTO_CLOSE_MS = 5000;
const ERROR_AUTO_CLOSE_MS = 10000;

const SNACKBAR_ID = 'undoable-snackbar';

export function useSnackbarWithUndoableAction<P>(
  config: SnackbarMessages<P>,
  type: 'default' | 'delete' = 'default',
) {
  const snackbar = useSnackbar();
  const undoLastAction = useAppDispatchDecorator(undo);

  function showSnackbar(
    message: ReactNode,
    options: {
      iconLeft?: (props: BaseIconProps) => ReactElement;
      loader?: boolean;
      persist?: boolean;
      linkText?: string;
      onLinkClick?: () => void;
      autoCloseMs?: number;
    },
  ) {
    snackbar.showSnackbar(message, {
      loader: false,
      persist: false,
      // We undo the last action, so we want to show only one snackbar at time
      id: SNACKBAR_ID,
      autoCloseMs: DEFAULT_AUTO_CLOSE_MS,
      closeOnLinkClick: false,
      ...options,
    });
  }

  function closeSnackbar() {
    snackbar.closeSnackbar(SNACKBAR_ID);
  }

  async function handleUndo(props: P) {
    if (!config.undoLoading) return;
    if (!config.undoSuccess) return;
    if (!config.undoError) return;

    showSnackbar(config.undoLoading(props), {
      loader: true,
      persist: true,
    });

    try {
      await undoLastAction();

      showSnackbar(config.undoSuccess(props), {
        iconLeft: IconCheck,
        linkText: undefined,
      });
    } catch (err) {
      showSnackbar(config.undoError(props), {
        iconLeft: IconWarningTriangle,
        linkText: undefined,
      });
    }
  }

  const showLoadingSnackbar = (props: P) => {
    if (config.loading) {
      showSnackbar(config.loading(props), {
        iconLeft: undefined,
        loader: true,
        persist: true,
        linkText: undefined,
      });
    }
  };

  const showSuccessSnackbar = (props: P) => {
    if (!config.success) return;

    showSnackbar(config.success(props), {
      iconLeft: type === 'delete' ? IconTrash : IconCheck,
      linkText: config.undoSuccess ? t`Undo` : undefined,
      onLinkClick: () => handleUndo(props),
    });
  };

  const showErrorSnackbar = (props: P, retry: () => Promise<unknown>) => {
    showSnackbar(config.error(props), {
      iconLeft: IconWarningTriangle,
      onLinkClick: retry,
      linkText: t`Retry`,
      autoCloseMs: ERROR_AUTO_CLOSE_MS,
    });
  };

  return {
    showLoadingSnackbar,
    showErrorSnackbar,
    showSuccessSnackbar,
    closeSnackbar,
  };
}
