import React, { MouseEvent } from 'react';
import { Plural } from '@lingui/macro';

import { noop } from '@float/libs/utils/noop';
import { TextButton } from '@float/ui/deprecated/Earhart/Buttons';
import { IconArrowDown, IconArrowUp } from '@float/ui/deprecated/Earhart/Icons';

import { container } from './styles.css';

type ViewMoreToggleProps = {
  hiddenTimersCount: number;
  opened: boolean;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

function ViewMoreToggle(props: ViewMoreToggleProps) {
  const { hiddenTimersCount, opened, onClick = noop } = props;
  return (
    <div className={container}>
      <TextButton
        appearance="flay-underline"
        iconRight={opened ? IconArrowUp : IconArrowDown}
        onClick={onClick}
      >
        {opened ? (
          <Plural
            value={hiddenTimersCount}
            one={'View # less time entry'}
            other={'View # less time entries'}
          />
        ) : (
          <Plural
            value={hiddenTimersCount}
            one={'View # more time entry'}
            other={'View # more time entries'}
          />
        )}
      </TextButton>
    </div>
  );
}

export { ViewMoreToggle };
