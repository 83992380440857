import React from 'react';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';

import BaseIcon from './BaseIcon';

export default function ({
  className,
  color = Colors.FIN.Lt.Emphasis.High,
}: {
  className?: string;
  color?: string;
}) {
  return (
    <BaseIcon className={className}>
      <path
        d="M14.25 19.25V11.5M18.25 11.5V19.25V11.5ZM5.75 19.25V11.5V19.25ZM9.75 19.25V11.5V19.25Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 4.75L19.25 11.25H4.75L12 4.75Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.75 19.25H19.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
}
