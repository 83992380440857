import React from 'react';

export default function ({ fill = '#1A90DC', className }) {
  return (
    <svg
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.99998 0.5L0.454529 3.40909V7.77273C0.454529 11.8091 3.24726 15.5836 6.99998 16.5C10.7527 15.5836 13.5454 11.8091 13.5454 7.77273V3.40909L6.99998 0.5ZM6.99998 4.86364C8.01817 4.86364 9.03635 5.66364 9.03635 6.68182V7.77273C9.47271 7.77273 9.90907 8.20909 9.90907 8.71818V11.2636C9.90907 11.7 9.47271 12.1364 8.96362 12.1364H4.96362C4.52726 12.1364 4.09089 11.7 4.09089 11.1909V8.64545C4.09089 8.20909 4.52726 7.77273 4.96362 7.77273V6.68182C4.96362 5.66364 5.9818 4.86364 6.99998 4.86364ZM6.99998 5.73636C6.41817 5.73636 5.90907 6.1 5.90907 6.68182V7.77273H8.09089V6.68182C8.09089 6.1 7.5818 5.73636 6.99998 5.73636Z"
        fill="#363D46"
      />
    </svg>
  );
}
