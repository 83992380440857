import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  getPeopleMap,
  getPhasesMapRaw,
  getProjectsMap,
  getTeamCapacityHighlights,
  getUser,
} from 'selectors';

import { setPlaceholder } from '@float/common/actions/search';
import Loader from '@float/common/components/elements/Loader';
import { trackEvent } from '@float/common/lib/analytics';
import { handleFail } from '@float/common/lib/errors';
import { useSearchFiltersAnalyticsTracking } from '@float/common/lib/hooks/useSearchFiltersAnalyticsTracking';
import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { getActiveFilters } from '@float/common/selectors/views';
import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import { AppDispatchStrict } from '@float/common/store';
import { moment } from '@float/libs/moment';
import { FilterToken } from '@float/types';
import { Account, CurrentUser } from '@float/types/account';
import { Person } from '@float/types/person';
import { Phase } from '@float/types/phase';
import { EnhancedProject } from '@float/types/project';
import {
  AccordionTable,
  BarChart,
  Tab,
  useSnackbar,
  withConfirm,
} from '@float/ui/deprecated';
import { AccordionTableSortConfig } from '@float/ui/deprecated/AccordionTable/types';
import {
  ChartComparisonModes,
  ProjectPercentageModes,
} from '@float/ui/deprecated/Chart/types';
import { WithConfirmExtraProps } from '@float/ui/deprecated/Modal/withConfirm';

import { NoResults } from '../components/ErrorPages';
import SectionError from '../components/SectionError';
import getSearchFiltersString from '../helpers/getSearchFiltersString';
import { useEnsureReportContextLoaded } from '../helpers/useEnsureReportContextLoaded';
import { ReportGlobalControls } from '../ReportGlobalControls';
import { LoaderContainer, TabContainer } from '../styles';
import { CsvExportConfig } from '../types';
import { useReportsFetcher } from '../useReportsFetcher';
import { useReportsStateReducer } from '../useReportsStateReducer';
import SummaryBar from './components/SummaryBar';
import parseChartData from './parser/chart';
import exportChartCsv from './parser/csv/chart';
import exportTableCsv from './parser/csv/table';
import parseTableData from './parser/table';
import { ProjectsOverviewTableExportContext } from './parser/table/types';

type ReportSettings = {
  comparisonMode: ChartComparisonModes;
  endDate: DateString | Date;
  projectsPercentageMode: ProjectPercentageModes;
  startDate: DateString | Date;
  timeUnit: string;
};

type ProjectsOverviewProps = {
  accounts: Record<number, Account>;
  dispatch: AppDispatchStrict;
  hideTeamModeFilters: boolean;
  highlights: Record<string, unknown>;
  loggedTimeUpdateCount: number;
  memberViewSelf: unknown;
  people: Record<number, Person>;
  phases: Record<number, Phase>;
  projects: Record<number, EnhancedProject>;
  printMode: boolean;
  reportsSettings: {
    settings: ReportSettings;
    updateSettings: (settings: Partial<ReportSettings>) => void;
    createSortConfig: (name: string) => AccordionTableSortConfig;
  };
  searchFilters: FilterToken[];
  setCsvExportConfig: (settings: CsvExportConfig) => void;
  setPrintModeHeader: (settings: {
    title: string;
    searchFiltersString: string;
  }) => void;
  timeoffApprovalsEnabled: boolean;
  timeTrackingEnabled: boolean | number;
  user: CurrentUser;
  width: number;
  wrapperRef: React.MutableRefObject<HTMLElement | null>;
} & WithConfirmExtraProps;

function ProjectsOverview(props: ProjectsOverviewProps) {
  const {
    width,
    reportsSettings: { settings, updateSettings, createSortConfig },
    setCsvExportConfig,
    searchFilters,
    confirm,
    printMode,
    setPrintModeHeader,
    wrapperRef,
    timeoffApprovalsEnabled,
  } = props;

  const searchFiltersString = getSearchFiltersString(searchFilters);

  const { dates } = useScheduleContext();
  const [state, dispatch] = useReportsStateReducer();
  const [activeTab, setActiveTab] = useState('projects');

  useEffect(() => {
    if (settings.comparisonMode === ChartComparisonModes.SCHEDULED) return;

    if (!props.timeTrackingEnabled) {
      updateSettings({
        comparisonMode: ChartComparisonModes.SCHEDULED,
      });
    }
  }, [props.timeTrackingEnabled, updateSettings, settings.comparisonMode]);

  // Fetch lifecycle -----------------------------------------------------------

  const reportParams = useMemo(
    () => ({
      ...settings,
      timeoffApprovalsEnabled,
    }),
    [settings, timeoffApprovalsEnabled],
  );

  useReportsFetcher({
    endpoint: 'projects',
    dispatch,
    settings: reportParams,
    loggedTimeUpdateCount: props.loggedTimeUpdateCount,
  });

  useEnsureReportContextLoaded(settings.startDate, settings.endDate);

  useEffect(() => {
    if (!state.isTableTimeout && !state.isChartTimeout) return;

    const err = state.isTableTimeout ? state.tableError : state.chartError;

    confirm({
      title: err?.message || 'Report taking too long',
      message: 'Please narrow the date range and try again.',
      hideCancel: true,
    });
  }, [state, confirm]);

  // Parse table data ----------------------------------------------------------

  let loggedTimeBoundary = '1970-01-01';
  if (props.timeTrackingEnabled) {
    loggedTimeBoundary =
      settings.comparisonMode === ChartComparisonModes.COMBINED
        ? moment().format('YYYY-MM-DD')
        : '9999-01-01';
  }

  const tableData = useMemo(() => {
    return parseTableData(
      {
        dates,
        updateSettings,
        user: props.user,
        accounts: props.accounts,
        people: props.people,
        projects: props.projects,
        phases: props.phases,
        timeTrackingEnabled: props.timeTrackingEnabled,
        mode: settings.comparisonMode,
        projectsPercentageMode: settings.projectsPercentageMode,
        loggedTimeBoundary,
      },
      state.rawTableData,
    );
  }, [
    dates,
    updateSettings,
    state.rawTableData,
    props.user,
    props.accounts,
    props.people,
    props.projects,
    props.phases,
    props.timeTrackingEnabled,
    settings.comparisonMode,
    settings.projectsPercentageMode,
    loggedTimeBoundary,
  ]);

  // Parse chart data ----------------------------------------------------------

  const { loggedTimeBoundaryIdx, chartData, chartTotals, chartDataKey } =
    useMemo(() => {
      const data = parseChartData(
        { dates, settings, loggedTimeBoundary },
        state.rawChartData?.datapoints,
        props.highlights,
      );

      return {
        loggedTimeBoundary,
        loggedTimeBoundaryIdx: data.loggedTimeBoundaryIdx,
        chartData: data.chartData,
        chartTotals: data.chartTotals,
        chartDataKey: String(Date.now()),
      };
    }, [
      dates,
      settings,
      state.rawChartData,
      loggedTimeBoundary,
      props.highlights,
    ]);

  // Bind export CSV and print hooks -------------------------------------------

  const { showSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    const ctx = {
      settings,
      timeTrackingEnabled: props.timeTrackingEnabled,
      user: props.user,
      searchFiltersString,
      people: props.people,
      projects: props.projects,
      phases: props.phases,
      loggedTimeBoundary,
    };

    const handleExport =
      (
        fn: (
          context: ProjectsOverviewTableExportContext,
          ...rest: unknown[]
        ) => Promise<unknown>,
        ...rest: unknown[]
      ) =>
      async () => {
        const id = showSnackbar('Exporting', { loader: true, persist: true });
        try {
          await fn(ctx, ...rest);
        } catch (e) {
          console.error(e);
          handleFail(
            null,
            'There was an error exporting the requested data. Please refresh the page and try again.',
          );
        } finally {
          closeSnackbar(id);
        }
      };

    const options = [
      {
        title: 'Export chart data',
        fn: handleExport(exportChartCsv, chartData, chartTotals),
      },
      {
        title: 'Export table data',
        fn: handleExport(exportTableCsv, state.rawTableData),
      },
    ];

    setCsvExportConfig({ options });
  }, [
    state.rawTableData,
    settings,
    chartData,
    chartTotals,
    showSnackbar,
    closeSnackbar,
    setCsvExportConfig,
    props.user,
    props.timeTrackingEnabled,
    props.projects,
    props.people,
    props.phases,
    searchFiltersString,
    loggedTimeBoundary,
  ]);

  useEffect(() => {
    setPrintModeHeader({
      title: 'Projects',
      searchFiltersString,
    });
  }, [searchFiltersString, setPrintModeHeader]);

  const reduxDispatch = props.dispatch;
  useEffect(() => {
    const noun = tableData.projects.rows.length === 1 ? 'project' : 'projects';
    // @TODO(PI-92)
    // @ts-expect-error - Refactor Search redux to use StrictState
    reduxDispatch(setPlaceholder(`${tableData.projects.rows.length} ${noun}`));
  }, [tableData.projects.rows.length]); // eslint-disable-line

  useEffect(() => {
    trackEvent('report-viewed', { type: 'Projects' });
  }, []);

  useSearchFiltersAnalyticsTracking('report-filtered', {
    additionalProps: {
      type: 'Projects',
    },
  });

  // ---------------------------------------------------------------------------

  if (
    !state.isChartLoading &&
    !state.isTableLoading &&
    !state.isChartTimeout &&
    !state.isTableTimeout &&
    !state.chartError &&
    !state.tableError &&
    isEmpty(chartData)
  ) {
    return <NoResults isProjectDetails={false} />;
  }

  return (
    <>
      <div>
        {!printMode && (
          // @ts-expect-error – ReportGlobalControls has not been converted to typescript
          <ReportGlobalControls
            hideTeamModeFilters={props.hideTeamModeFilters}
            memberViewSelf={props.memberViewSelf}
            reportsSettings={props.reportsSettings}
            timeTrackingEnabled={props.timeTrackingEnabled}
          />
        )}
        {state.chartError && <SectionError height="268" topMargin />}
        {!state.chartError &&
          (state.isChartLoading && state.showChartSpinner ? (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          ) : (
            <BarChart
              key={`${chartDataKey}:${printMode}:${width}`}
              width={printMode ? 1000 : width}
              unit={settings.timeUnit}
              mode={settings.comparisonMode}
              items={chartData}
              loggedTimeBoundary={loggedTimeBoundary}
              loggedTimeBoundaryIdx={loggedTimeBoundaryIdx}
              noTimeoff
              noCapacity
              noUnscheduled
            />
          ))}
      </div>

      <SummaryBar
        totals={chartTotals}
        mode={settings.comparisonMode}
        minWidth={printMode ? 1000 : width}
        timeTrackingEnabled={props.timeTrackingEnabled}
      />

      {state.tableError && <SectionError height="100" topMargin={false} />}
      {!state.tableError &&
        (state.isTableLoading && state.showTableSpinner ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <div style={{ minWidth: printMode ? 1000 : width }}>
            <TabContainer>
              <Tab
                noBorder
                color="charcoalGrey"
                label="Projects"
                onClick={() => setActiveTab('projects')}
                active={activeTab === 'projects'}
                counter={tableData.projects.rows.length}
              />
              <Tab
                noBorder
                color="charcoalGrey"
                label="Clients"
                onClick={() => setActiveTab('clients')}
                active={activeTab === 'clients'}
                counter={tableData.clients.rows.length}
              />
            </TabContainer>

            {activeTab === 'projects' && (
              <AccordionTable
                wrapperRef={wrapperRef}
                style={{ zoom: printMode ? 0.77 : 1 }}
                data={tableData.projects}
                sortConfig={createSortConfig('po-projects')}
                noResultsMessage={
                  !state.isTableLoading &&
                  'No projects scheduled in this date range'
                }
              />
            )}
            {activeTab === 'clients' && (
              <AccordionTable
                wrapperRef={wrapperRef}
                style={{ zoom: printMode ? 0.77 : 1 }}
                data={tableData.clients}
                sortConfig={createSortConfig('po-clients')}
                noResultsMessage={
                  !state.isTableLoading &&
                  'No Clients scheduled in this date range'
                }
              />
            )}
          </div>
        ))}
    </>
  );
}

const mapStateToProps = (state: ReduxStateStrict) => ({
  accounts: state.accounts.accounts,
  people: getPeopleMap(state),
  projects: getProjectsMap(state),
  phases: getPhasesMapRaw(state),
  timeTrackingEnabled:
    state.companyPrefs.time_tracking && state.companyPrefs.time_tracking > 0,
  timeoffApprovalsEnabled: !!state.companyPrefs.timeoff_approvals,
  user: getUser(state),
  highlights: getTeamCapacityHighlights(state) as Record<string, unknown>,
  searchFilters: getActiveFilters(state),
});

export default connect(mapStateToProps)(withConfirm(ProjectsOverview));
