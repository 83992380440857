import styled from 'styled-components';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

export const StyledLogTimeTimerControls = styled.div<{
  hasActiveTimer: boolean | undefined;
  hasTimerEntries: boolean;
  $loading?: boolean;
}>`
  ${Typography.TextS.M500};

  display: flex;
  align-items: center;

  color: ${Colors.Core.Text.Default};

  width: 100%;

  opacity: ${(props) => (props.$loading ? 0.6 : 1)};

  div + div {
    margin-left: 16px;
  }
`;
