import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';

import { projectTemplates } from '@float/common/api3/projectTemplates';

import { mapProjectOffsetsToDates } from '../helpers/mapProjectOffsetsToDates';
import { ProjectFormData } from '../types';

type Duration = ProjectFormData['project']['duration'];

export function useProjectAnchorDate() {
  const [anchorDate, setAnchorDate] = useState<string>('');
  const { getValues, reset } = useFormContext<ProjectFormData>();
  const templateId = getValues('templateId') ?? 0;

  const { data: templateDates, isLoading } = useQuery({
    queryKey: ['getDatesFromOffsets', templateId, anchorDate],
    enabled: Boolean(templateId && anchorDate),
    queryFn: () => projectTemplates.getDatesFromOffsets(templateId, anchorDate),
  });

  const handleAnchorDateChange = useCallback((date: string) => {
    setAnchorDate(date);
  }, []);

  useEffect(() => {
    if (!templateDates) return;

    const current = getValues();
    const updates = mapProjectOffsetsToDates(current, templateDates);
    if (isEmpty(updates)) return;

    reset({ ...current, ...updates }, { keepDefaultValues: true });
  }, [templateDates, getValues, reset]);

  const projectDuration = getValues('project.duration') as Duration;
  const canSetAnchorDate =
    typeof projectDuration === 'number' && projectDuration > 0;

  return {
    areDatesLoading: isLoading,
    projectDuration,
    handleAnchorDateChange: canSetAnchorDate
      ? handleAnchorDateChange
      : undefined,
  };
}
