import React, { useCallback } from 'react';

import { StyledLink } from './styles';

const Link = ({ attributes, children, element }) => {
  const onClick = useCallback(() => {
    window.open(element.href, '_blank');
  }, [element]);

  return (
    <StyledLink {...attributes} href={element.href} onMouseDown={onClick}>
      {children}
    </StyledLink>
  );
};

export default Link;
