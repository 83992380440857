import React, { CSSProperties, forwardRef, ReactNode } from 'react';

import Icons from '../../Icons';
import { StyledModalHeader } from './styles';

type ModalHeaderProps = {
  children: ReactNode;
  className?: string;
  color?: string;
  onClose?: () => void;
  withCloseIcon?: boolean;
  style?: CSSProperties;
};

const ModalHeader = forwardRef<HTMLDivElement, ModalHeaderProps>(
  (
    {
      children,
      className,
      color = 'white',
      onClose,
      withCloseIcon,
      style = {},
    },
    ref,
  ) => {
    return (
      <StyledModalHeader
        ref={ref}
        color={color}
        className={className}
        style={style}
        withCloseIcon={withCloseIcon}
      >
        {children}
        {withCloseIcon && <Icons.CloseDynamic onClick={onClose} />}
      </StyledModalHeader>
    );
  },
);

export default ModalHeader;
