import styled, { css } from 'styled-components';

import * as Animation from '../../../Animation';
import * as Colors from '../../../Colors';
import * as Typography from '../../../Typography';

export const StyledContent = styled.div`
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  z-index: 1;

  > svg {
    --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Medium};

    opacity: 0;

    transform: scale(0);

    transition-property: transform, opacity;
    transition-duration: ${Animation.Settings.Duration.Short};
    transition-timing-function: ${Animation.Settings.Easing.InOutBounce};

    margin-left: 8px;
  }
`;

export const StyledItem = styled.li`
  ${Typography.Label14.M500};

  position: relative;

  padding: 10px 12px;

  min-width: 140px;

  background-color: rgba(0, 0, 0, 0);

  border-radius: 6px;

  cursor: pointer;

  color: ${Colors.FIN.Lt.Emphasis.Medium};

  transition-property: color;
  transition-duration: ${Animation.Settings.Duration.Short};
  transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

  & + & {
    margin-top: 4px;
  }

  &:after {
    content: '';

    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border-radius: 6px;

    background-color: ${Colors.FIN.Lt.Surface.Surf4};

    transition-property: transform, background-color, opacity;
    transition-duration: ${Animation.Settings.Duration.Normal},
      ${Animation.Settings.Duration.Short}, ${Animation.Settings.Duration.Short};
    transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

    opacity: 0;

    transform: scale(0.9);

    z-index: 0;
  }

  &:hover {
    color: ${Colors.FIN.Lt.Emphasis.High};

    &:after {
      opacity: 1;
      transform: none;
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${Colors.FIN.Lt.Emphasis.Primary};

      background-color: rgba(0, 0, 0, 0);

      ${StyledContent} {
        > svg {
          opacity: 1;

          transform: none;
        }
      }
    `};
`;
