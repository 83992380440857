import { batch } from 'react-redux';
import { getModelFromActivity } from '@floatschedule/activity-format-npm';
import { cloneDeep, isArray, isEmpty } from 'lodash';

import { getUser } from '@float/common/selectors/currentUser';

import { receivedActivity } from '../activity/actions';
import { ACTION_TYPES } from './constants';
import getAction from './getAction';
import { isInitiatedByCurrentUser, isUserAuthorizedToView } from './helpers';

function getIsReplace({ item_type: itemType, activity_type: activityType }) {
  return (
    ['task', 'timeoff'].includes(itemType) ||
    (itemType === 'logged_time' && activityType === 'delete')
  );
}

function getIsImport({ item_type: itemType, activity_mode: activityMode }) {
  return activityMode === 'import' && ['people', 'project'].includes(itemType);
}

function getIsSelfActionedSplit(activity, liveUpdateMetadata, currentUser) {
  return (
    activity.activity_mode === 'split' &&
    isInitiatedByCurrentUser(liveUpdateMetadata, currentUser)
  );
}

export const shouldInsertActivityInFeed = (afItem, fullState) => {
  const user = getUser(fullState);
  return afItem && afItem._id && isUserAuthorizedToView(afItem, user);
};

export const insertActivities = (items) => (dispatch, getState) => {
  if (items && !isEmpty(items)) {
    const afItems = isArray(items) ? items : [items];
    dispatch(
      receivedActivity({
        items: cloneDeep(afItems),
        specialName: 'mainActivityFeed',
        total: afItems.length,
        page: 1,
        needsLatest: true,
        groupBatchOnly: true,
      }),
    );
  }
};

export const handleBatchActivities =
  (activities, liveUpdateMetadata) => (dispatch, getState) => {
    const fullState = getState();
    const actionTypes = ACTION_TYPES;
    const activitiesToShowInFeed = [];
    const total = activities.length;
    const actions = [];
    let activity, itemType, action, actionType, model;

    const replace = {
      result: {
        task: [],
        timeoff: [],
        delete: {
          task: [],
          timeoff: [],
          logged_time: [],
        },
      },
    };
    const importData = { people: { result: [] }, project: { result: [] } };
    let isReplace, isImport;

    for (let i = total - 1; i > -1; i--) {
      activity = activities[i];
      if (!activity) continue;

      itemType = activity.item_type;

      if (shouldInsertActivityInFeed(activity, fullState)) {
        activitiesToShowInFeed.push(activity);
      }

      if (
        getIsSelfActionedSplit(
          activity,
          liveUpdateMetadata,
          fullState.currentUser,
        )
      ) {
        // This is handled directly the caller of split to be able to correctly
        // replace the temporary task in schedule.
        continue;
      }

      isReplace = getIsReplace(activity);
      isImport = !isReplace && getIsImport(activity);
      if (isReplace || isImport) {
        model = getModelFromActivity(activity);
      }

      if (isReplace) {
        replace.shouldRun = true;

        // Logged time models are returned as array. This line
        // below makes the logic that follows consistent.
        model = isArray(model) ? model : [model];

        if (activity.activity_type === 'delete') {
          replace.result.delete[itemType].push(...model);
        } else {
          replace.result[itemType].push(...model);
        }
        continue;
      }

      if (isImport) {
        importData[itemType].shouldRun = true;
        importData[itemType].result.push(model);
        continue;
      }

      actionType =
        actionTypes[`${itemType}_${activity.activity_type}`.toUpperCase()];
      if (!actionType) {
        continue;
      }

      action = getAction({ actionType, afItem: activity, fullState });
      if (action) {
        actions.push(action);
      }
    }

    if (replace.shouldRun) {
      actions.push({
        type: actionTypes.TASK_REPLACE,
        response: replace.result,
      });
    }

    Object.keys(importData).forEach((type) => {
      if (importData[type].shouldRun) {
        actions.push({
          type: actionTypes[`${type}_import`.toUpperCase()],
          result: importData[type].result,
        });
      }
    });

    if (actions.length) {
      if (actions.length === 1) {
        dispatch(actions[0]);
      } else {
        batch(() => {
          actions.forEach((a) => {
            dispatch(a);
          });
        });
      }
    }

    if (activitiesToShowInFeed.length) {
      dispatch(insertActivities(activitiesToShowInFeed));
    }
  };
