type GetIsFullDayParams = {
  startTime?: string | null;
  hoursPerDay: number | null | undefined;
  minimumWorkHoursInRange: number;
};

export const getIsFullDay = ({
  startTime,
  hoursPerDay,
  minimumWorkHoursInRange,
}: GetIsFullDayParams) => {
  // Hours per day is not a valid number
  if (typeof hoursPerDay !== 'number') return false;

  const hasSpecificTimeSet =
    typeof startTime === 'string' && startTime.trim().length > 0;

  // When specific time is set the value is full day when number of hours exactly matches
  // the minimum work hours in the time range
  if (hasSpecificTimeSet) {
    return hoursPerDay === minimumWorkHoursInRange;
  }

  // When the specific time is unset we also consider a full day when hours per day is more than or equal
  // to minimum work hours in the time range
  return hoursPerDay >= minimumWorkHoursInRange;
};
