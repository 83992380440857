import React from 'react';

import BaseIcon from '@float/ui/deprecated/Earhart/Icons/Icon/BaseIcon';

export default (props) => (
  <BaseIcon size="91" viewBox="0 0 91 91" {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
      d="M43.432 65.493h-15.08c-1.556 0-2.78-1.292-2.47-2.817.856-4.212 3.653-11.66 12.724-11.66 1.86 0 3.456.155 4.826.689m9.651-11.03c4.189 0 6.894-3.396 6.894-7.584s-2.705-7.583-6.893-7.583m-6.895 7.583a7.583 7.583 0 1 1-15.166 0 7.583 7.583 0 0 1 15.167 0ZM60 48.583v20.834M70.416 59H49.583"
    />
  </BaseIcon>
);
