import styled from 'styled-components';

import colors from '../Theme/colors';

export default styled.div`
  width: ${(p) => p.size || 14}px;
  height: ${(p) => p.size || 14}px;
  border-radius: 3px;
  display: inline-block;
  position: relative;

  &.teal {
    background-color: ${colors.teal};
  }

  &.purple {
    background-color: ${colors.purple};
  }

  &.mid-teal {
    background-color: ${colors.midTeal};
  }

  &.light-purple {
    background-color: ${colors.lightPurple};
  }

  &.combined {
    overflow: hidden;
    background-color: ${colors.teal};
    border-top-color: ${colors.teal};
    border-left-color: ${colors.teal};

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: ${colors.purple};
      transform: skewY(-45deg);
      transform-origin: left top;
    }
  }

  &.diagonal {
    background: ${() => colors.timeoff};
  }
`;
