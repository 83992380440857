import { useCallback } from 'react';

import { useAppDispatch } from '@float/common/store';
import { Person } from '@float/types/person';
import { Phase } from '@float/types/phase';
import { Project, ProjectTemplate } from '@float/types/project';

import manageModalAction from './manageModalActionCreator';

type ModalSettings = {
  confirmAppearance?: string;
  confirmLabel?: string;
  editing?: boolean;
  duplicating?: boolean;
  error?: unknown;
  fromTemplate?: number;
  hideCancel?: boolean;
  isAdding?: boolean;
  isEditable?: boolean;
  isTemplate?: boolean;
  message?: React.ReactNode;
  noBgTransition?: boolean;
  onConfirm?: () => void;
  onSwitch?: (val: number) => void;
  person?: Person;
  phase?: Phase;
  props?: unknown;
  project?: Project | ProjectTemplate | Partial<Project>;
  projectId?: number;
  shouldCloseOnEsc?: boolean;
  term?: string | string[];
  title?: string;
};

export type ModalConfig = {
  skipSidePanelAutoClose?: boolean;
  visible: boolean;
  modalType: string;
  modalSettings?: ModalSettings;
};

export function useManageModal() {
  const dispatch = useAppDispatch();

  const manageModal = useCallback(
    (modalConfig: ModalConfig) => {
      dispatch(manageModalAction(modalConfig));
    },
    [dispatch],
  );

  const manageModalFromPanel = useCallback(
    (modalConfig: ModalConfig) => {
      dispatch(
        manageModalAction({
          ...modalConfig,
          skipSidePanelAutoClose: true,
        }),
      );
    },
    [dispatch],
  );

  return {
    manageModal,
    manageModalFromPanel,
  };
}
