export type IsTodayVisibleParams = {
  dayWidth: number;
  isLogMyTimeView: boolean;
  isLogTimeView: boolean;
  scrollWrapper: HTMLDivElement;
};

export const isTodayVisible = (params: IsTodayVisibleParams) => {
  const { dayWidth, isLogMyTimeView, isLogTimeView, scrollWrapper } = params;

  let isVisible = false;

  if (isLogMyTimeView || isLogTimeView) {
    const sideColumnSize = window.innerWidth <= 1010 ? 64 : 260;

    const todayTopCell = scrollWrapper.querySelector('[data-is-today]');

    let todayTopCellPosition;

    if (todayTopCell) {
      todayTopCellPosition = todayTopCell.getBoundingClientRect();
      isVisible =
        todayTopCellPosition.x > sideColumnSize &&
        todayTopCellPosition.x < window.innerWidth - dayWidth / 2;
    }
  }

  return isVisible;
};
