import React, { useRef, useState } from 'react';

import { useResizeObserver } from '@float/libs/web/hooks/useResizeObserver';
import EH from '@float/ui/deprecated/Earhart';
import { TextTooltip } from '@float/ui/deprecated/Tooltip/TextTooltip';

import * as styled from './styles';
import { Tag } from './Tag';
import { useVisibleTags } from './useVisibleTags';

const DEFAULT_WIDTH = 187;

export const Tags = ({
  className = '',
  ellipsis = true,
  tags = [],
  appearance = 'static',
  size = 'small',
  shouldResize = false,
}) => {
  const tagsContainerRef = useRef();

  const [containerWidth, setContainerWidth] = useState(DEFAULT_WIDTH);

  useResizeObserver(
    tagsContainerRef,
    ({ contentRect }) => {
      setContainerWidth(contentRect.width ?? 0);
    },
    {
      enabled: shouldResize,
    },
  );

  const { visible, hidden } = useVisibleTags({ tags, containerWidth });

  if (!tags.length) return null;

  const withMore = hidden.length > 0;

  return (
    <styled.Tags
      className={className}
      ref={tagsContainerRef}
      ellipsis={ellipsis}
    >
      <styled.TagsContainer>
        {visible.map((tag, index) => (
          <Tag
            key={index}
            tag={tag}
            appearance={appearance}
            size={size}
            maxWidth={tag.maxWidth}
          />
        ))}

        {withMore && (
          <TextTooltip
            key="more-tags"
            placement="top"
            content={
              <styled.MoreTags>
                {hidden.map((tag, index) => {
                  return (
                    <Tag
                      key={index}
                      tag={tag}
                      appearance={appearance}
                      size={size}
                    />
                  );
                })}
              </styled.MoreTags>
            }
          >
            <EH.Tags.Tag appearance={appearance} size={size}>
              +{hidden.length}
            </EH.Tags.Tag>
          </TextTooltip>
        )}
      </styled.TagsContainer>
    </styled.Tags>
  );
};

export default Tags;
