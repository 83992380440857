import React, { ReactNode } from 'react';

import {
  StyledCurrentTag,
  StyledDaysRemaining,
  StyledHeader,
  StyledPlanTitle,
} from './styles';

type PlanHeaderProps = {
  isActivePlan: boolean;
  isPaidTrial?: boolean;
  daysRemaining?: number | null;
  children?: ReactNode;
};

export const PlanHeader: React.FC<PlanHeaderProps> = ({
  isActivePlan,
  isPaidTrial,
  daysRemaining,
  children,
}) => (
  <StyledHeader>
    <StyledPlanTitle>{children}</StyledPlanTitle>
    {isActivePlan && !isPaidTrial && (
      <StyledCurrentTag size="medium">Current</StyledCurrentTag>
    )}
    {isActivePlan && isPaidTrial && (
      <StyledCurrentTag size="medium">30 day trial</StyledCurrentTag>
    )}
    {daysRemaining && (
      <StyledDaysRemaining>
        {daysRemaining} {daysRemaining === 1 ? 'day' : 'days'} left
      </StyledDaysRemaining>
    )}
  </StyledHeader>
);
