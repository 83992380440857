import { LiveUpdate } from '@float/types/liveUpdate';

const STORAGE_KEY = 'liveUpdatesDebug';

let enabled: boolean;

export const getIsLiveUpdatesDebugEnabled = () => {
  // OPTIMIZATION: we read from localStorage once to avoid
  // possible performance issues related to reading on an hard disk
  if (enabled === undefined) {
    enabled = Boolean(localStorage.getItem(STORAGE_KEY));
  }

  return enabled;
};

export const setLiveUpdatesDebug = (value: boolean) => {
  enabled = value;
  localStorage.setItem(STORAGE_KEY, value ? 'true' : '');
};

export const debugLogLiveUpdateMessage = (value: LiveUpdate) => {
  if (getIsLiveUpdatesDebugEnabled()) {
    // using the deconstruct to keep it in the production build
    const { log } = console;

    const headingStyle =
      'background-color: yellow; color: black; padding: 1px 2px; margin-bottom: 4px; font-style: italic; border: 1px solid hotpink';

    const type = 'source' in value ? value.source : value.notification.type;
    const content = 'source' in value ? value.event : value.notification;

    log(`%cLive update: ${type ?? 'batch'}`, headingStyle, content);
  }
};
