import { moment } from '@float/libs/moment';

const FLOAT_MIN_YEAR = 1971;
const FLOAT_MAX_YEAR = 2099;

export default function InputHandler(attr, inputAttr, rangeValidator) {
  return function (e) {
    const inputValue = e.currentTarget.value;
    const newState = { [inputAttr]: inputValue };
    // 3rd argument as true: strict moment parsing
    const possibleMomentValue = moment(inputValue, 'DD MMM YYYY', true);
    if (
      possibleMomentValue.isValid() &&
      possibleMomentValue.year() > FLOAT_MIN_YEAR &&
      possibleMomentValue.year() < FLOAT_MAX_YEAR &&
      (this.props.minimumDate
        ? possibleMomentValue >= this.props.minimumDate
        : true)
    ) {
      if (rangeValidator && !rangeValidator(attr, possibleMomentValue)) {
        if (attr === 'start') {
          // Set the end to start
          newState.end = possibleMomentValue.clone();
          newState.endInputValue = this.format(newState.end);
        } else {
          // Set the start to the end
          newState.start = possibleMomentValue.clone();
          newState.startInputValue = this.format(newState.start);
        }
      }

      // Here we check if the user has lock period access to the
      // given date, and if not we noremalize the input to 1 day after
      // the latest lock period end
      if (
        this.hasLockPeriodAccess &&
        !this.hasLockPeriodAccess(possibleMomentValue)
      ) {
        newState[attr] = moment(this.props.lockPeriod?.latest).add(1, 'day');
        newState.inputValue = this.format(newState[attr]);
      } else {
        newState[attr] = possibleMomentValue;
      }
    }
    this.setState(newState);
  };
}
