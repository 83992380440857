import { padTo2Digits } from '../number/padTo2Digits';

export const formatMsAsClockTime = (
  ms: number,
  withSeconds: boolean = false,
) => {
  const millisecs = Math.max(ms, 0);
  const secs = Math.floor(millisecs / 1000);
  const mins = Math.floor(secs / 60);
  const hrs = Math.floor(mins / 60);

  const hours = padTo2Digits(hrs);
  const minutes = padTo2Digits(mins % 60);

  let clockTime = `${hours}:${minutes}`;

  if (withSeconds) {
    const seconds = padTo2Digits(secs % 60);

    clockTime += `:${seconds}`;
  }

  return clockTime;
};
