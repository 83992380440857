import { cloneDeep, get, isEmpty, isUndefined, keyBy } from 'lodash';

import socket from '@float/common/lib/liveUpdates/socket';
import { FloatAppPlatform } from '@float/constants/app';
import { config } from '@float/libs/config';

import API3 from '../../api3';
import { promiseMap } from '../../lib/promise';
import request from '../../lib/request';
import {
  getAccounts,
  getDirectManagersByPeopleId,
  getPeopleMap,
} from '../../selectors/people';
import { ACCOUNTS_UPDATE } from '../accounts';
import { UPDATE_USER } from '../currentUser';
import { getJWTAccessToken } from '../jwt';
import { updateProject } from '../projects';
import { sanitizeFetchedPerson } from './helpers/sanitizeFetchedPerson';
import {
  PEOPLE_BULK_DELETED,
  PEOPLE_BULK_UPDATED,
  PEOPLE_DELETED,
  PEOPLE_LOAD_FAILED,
  PEOPLE_LOAD_FINISH,
  PEOPLE_LOAD_START,
  PEOPLE_PROJECT_ADDED,
  PEOPLE_UPDATED,
  PERSON_PROJECTS_UPDATE,
  PERSON_UPDATE_AUTO_EMAIL,
} from './types';
import { updateNewRolePlaceholder } from './updateNewRolePlaceholder';
import { updatePlanCount } from './updatePlanCount';

export * from './types';

// -----------------------------------------------------------------------------
// !!! Helpers -----------------------------------------------------------------
// -----------------------------------------------------------------------------

function fetchPerson(personId) {
  const abortController = new AbortController();
  const { signal } = abortController;

  return {
    promise: API3.getPerson({ id: personId }, signal),
    abortController,
  };
}

async function fetchPeople() {
  const res = await API3.getAllPeople();

  // If there are no people `response` will be empty
  if (isEmpty(res)) return {};

  const people = res.map((p) => sanitizeFetchedPerson(p));
  return keyBy(people, 'people_id');
}

// -----------------------------------------------------------------------------
// !!! Exported Action Creators ------------------------------------------------
// -----------------------------------------------------------------------------

export function deletePerson(id) {
  return async (dispatch) => {
    await API3.deletePerson({ id });
    dispatch({ type: PEOPLE_DELETED, person: { people_id: Number(id) } });
    dispatch(updatePlanCount);
  };
}

export function fetchPersonAccount(person) {
  return async (dispatch) => {
    if (person.account_id) {
      const account = await API3.getAccountV3({
        id: person.account_id,
        query: {
          expand: 'management_group',
        },
      });
      dispatch({ type: ACCOUNTS_UPDATE, account });
      return account;
    }
  };
}

export const applyRequestMarFeaturePatch = (reqV3) => {
  delete reqV3.managed_departments;
  delete reqV3.view_rights;
  delete reqV3.edit_rights;
  delete reqV3.budget_rights;
};

export const ensureOnlyOneDirectManager = (account, dispatch, getState) => {
  if (!account) return;

  const managedPeople = account.management_group?.people || [];
  const managesPeople = managedPeople.length > 0;
  if (!managesPeople) {
    return;
  }

  const updateAccounts = getOtherManagersWithoutAlreadyManagedPeople(
    account.account_id,
    account.management_group?.people,
    getState(),
  );

  // Upate App Store
  updateAccounts.forEach((accountUpdate) => {
    dispatch({
      type: ACCOUNTS_UPDATE,
      account: accountUpdate,
    });
  });

  // Upate Setting Store
  // Ensure that app and settings data are in sync.
  updateAccounts.forEach((accountUpdate) => {
    dispatch({
      type: 'UPDATE_ACCOUNT_SUCCESS',
      id: account.account_id,
      payload: accountUpdate,
    });
  });
};

export const getOtherManagersWithoutAlreadyManagedPeople = (
  currentManagerId,
  managedPeople,
  fullState,
) => {
  const accounts = getAccounts(fullState);
  const directManagersByPeopleId = getDirectManagersByPeopleId(fullState);

  return managedPeople.flatMap((managedPersonId) => {
    const managers = directManagersByPeopleId[managedPersonId] || [];

    return managers.map((accountId) => {
      const isCurrentAccountUpdate = currentManagerId === accountId;
      if (isCurrentAccountUpdate) return;

      const nextAccount = cloneDeep(accounts[accountId]);
      const managed = nextAccount.management_group;

      managed.people = managed.people.filter(
        (peopleId) => peopleId !== managedPersonId,
      );

      return nextAccount;
    });
  });
};

export function updatePersonProjectsAction(personId, { add, del }) {
  return {
    type: PERSON_PROJECTS_UPDATE,
    personId,
    update: { add, del },
  };
}

export function updatePersonProjects(personId, { add, del }) {
  return async (dispatch, getState) => {
    // no records, no actions
    if (!add.length && !del.length) return;

    const requests = add.map((id) => ({
      id,
      data: {
        project_team: { add: [{ people_id: personId }] },
      },
      query: {
        expand: 'project_team,project_dates',
      },
    }));

    const { projects } = getState().projects;

    requests.push(
      ...del.map((id) => ({
        id,
        data: {
          project_team: {
            del: [personId],
          },
          active: projects[id].active,
        },
        query: {
          expand: 'project_team,project_dates',
        },
      })),
    );

    // TODO replace this workaround with a batched API
    // https://linear.app/float-com/issue/CORE-191/
    await promiseMap(requests, API3.updateProject, { concurrency: 5 });

    dispatch(updatePersonProjectsAction(personId, { add, del }));
  };
}

export function addPeopleToProject(peopleIds, projectId) {
  return async (dispatch, getState) => {
    const state = getState();
    const project = get(state, `projects.projects.${projectId}`);
    const patch = { project_team: { add: [] } };

    const addedPeopleIds = [];

    peopleIds.forEach((personId) => {
      if (!project.people_ids.includes(personId)) {
        patch.project_team.add.push({ people_id: personId });
        addedPeopleIds.push(personId);
      }
    });

    if (!addedPeopleIds.length) {
      return;
    }

    await dispatch(updateProject(projectId, patch));
    dispatch({
      type: PEOPLE_PROJECT_ADDED,
      peopleIds: addedPeopleIds,
      projectId,
    });
  };
}

export function uploadAvatar(
  { personId = null, accountId, myInfo },
  file,
  onProgress,
) {
  return async (dispatch, getState) => {
    if (personId) {
      accountId = get(getState(), `people.people.${personId}.account_id`, null);
    }

    const currentUser = get(getState(), `currentUser`, null);
    const params = [`people_id=${personId}`, `account_id=${accountId}`];

    if (myInfo) {
      params.push('avatar_type=myinfo');
    } else {
      params.push('avatar_type=undefined');
    }

    const url = `svc/api3/v3/avatars?${params.join('&')}`;
    const accessToken = await dispatch(getJWTAccessToken());
    const opts = { onProgress, headers: {} };
    opts.headers.Authorization = `Bearer ${accessToken}`;
    opts.headers['X-Token-Type'] = 'JWT';
    opts.headers['notify-uuid'] = socket.uuid;
    if (config.platform === FloatAppPlatform.Mobile) {
      opts.hostname = config.api.hostname;
      opts.headers['content-type'] = 'image/png';
    }

    // omitting cookies is a challenge here because the underlying request here is XHR instead of fetch
    // https://stackoverflow.com/questions/29942623/is-it-possible-to-force-an-xmlhttprequest-not-to-send-cookies-in-chromium
    const res = await request.upload(url, file, opts);
    const avatar = `${res.filename}?${Date.now()}`;

    if (accountId) {
      dispatch({
        type: ACCOUNTS_UPDATE,
        account: {
          account_id: accountId,
          avatar,
        },
      });
    }

    if (personId) {
      dispatch({
        type: PEOPLE_UPDATED,
        person: {
          people_id: personId,
          avatar_file: avatar,
        },
      });
    }

    if (myInfo || accountId === currentUser.account_id) {
      dispatch({
        type: UPDATE_USER,
        user: {
          avatar_file: avatar,
        },
      });
    }
  };
}

export function removeAvatar({ personId = null, accountId, myInfo }) {
  return async (dispatch, getState) => {
    if (personId) {
      accountId = get(getState(), `people.people.${personId}.account_id`, null);
    }

    const currentUserAccountId = get(
      getState(),
      `currentUser.account_id`,
      null,
    );

    const body = JSON.stringify({
      people_id: personId,
      account_id: accountId,
      avatar_type: myInfo ? 'myinfo' : null,
    });

    const res = await request.send(
      'avatars',
      {
        method: 'delete',
        body,
        headers: {
          Accept: 'application/json, text/javascript, */*; q=0.01',
          'Content-Type': 'application/json',
        },
      },
      { version: 'f3' },
    );

    if (accountId) {
      dispatch({
        type: ACCOUNTS_UPDATE,
        account: {
          account_id: accountId,
          avatar: res.filename,
        },
      });
    }

    if (personId) {
      dispatch({
        type: PEOPLE_UPDATED,
        person: {
          people_id: personId,
          avatar_file: res.filename,
        },
      });
    }

    if (myInfo || currentUserAccountId === accountId) {
      dispatch({
        type: UPDATE_USER,
        user: {
          avatar_file: res.filename,
        },
      });
    }
  };
}

export const ensurePeopleLoaded = (forceLoad = false) => {
  return async (dispatch, getState) => {
    const { loadState: currentLoadState } = getState().people;

    if (!forceLoad && currentLoadState === 'LOADED') return;
    if (currentLoadState === 'LOADING') return; // There's already an in-flight load request

    try {
      dispatch({ type: PEOPLE_LOAD_START });
      const people = await fetchPeople();
      dispatch({ type: PEOPLE_LOAD_FINISH, people });
    } catch (e) {
      console.error(e);
      dispatch({ type: PEOPLE_LOAD_FAILED });
    }
  };
};

export const fetchPersonFullNotes = (personId) => {
  return (dispatch) => {
    const req = fetchPerson(personId);

    req.promise
      .then((response) => {
        const { notes } = response;

        dispatch({
          type: PEOPLE_UPDATED,
          person: { people_id: personId, notes },
        });
      })
      .catch((e) => {
        console.warn(e);
      });

    return req;
  };
};

export const updateScheduleEmailPrefs = ({ personId, ...prefs }) => {
  return (dispatch) => {
    dispatch({ type: PERSON_UPDATE_AUTO_EMAIL, personId, ...prefs });
  };
};

export const bulkUpdatePeople = (ids, fields) => {
  return async (dispatch, getState) => {
    if (isEmpty(fields)) {
      return Promise.reject({ message: 'No changes made.' });
    }

    const response = await API3.bulkUpdate({ type: 'people', ids, fields });
    const people = getPeopleMap(getState());

    dispatch({
      type: PEOPLE_BULK_UPDATED,
      people: ids.map((id) => people[id]),
      fields,
    });

    // https://linear.app/float-com/issue/EXP-386 - When bulk updating people to
    // or from Placeholder we need to update the new_role_placeholder
    dispatch(updateNewRolePlaceholder(response));

    if (!isUndefined(fields.active)) {
      dispatch(updatePlanCount);
    }
    return response;
  };
};

export const bulkDeletePeople = (ids) => {
  return async (dispatch, getState) => {
    if (ids.length === 1) {
      return dispatch(deletePerson(ids[0]));
    }
    const response = await API3.bulkUpdate({
      type: 'people',
      ids,
      action: 'bulk-delete',
    });
    dispatch({ type: PEOPLE_BULK_DELETED, ids });
    dispatch(updatePlanCount);
    return response;
  };
};
