import React from 'react';

import { getAssignedProjects, ProjectsList } from './PersonProjects';

let self;

export default function renderPersonProjectsReadOnly(that) {
  self = that;

  const assignedProjects = getAssignedProjects(
    self.state.form,
    self.props.projects,
  );

  return <ProjectsList projects={assignedProjects} readOnly />;
}
