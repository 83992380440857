/**
 * Avoid using the `capitalize` function for rendering purposes and prefer
 * instead the CSS `text-transform` property, which is locale aware.
 *
 * See: https://developer.mozilla.org/en-US/docs/Web/CSS/text-transform
 */

export const capitalize = (str: string) => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};
