import { WebAppState } from 'reducers/types';
import { createSelector } from 'reselect';
import { SidePanelState } from 'sidePanel/types';

const _getSidePanelState = (state: WebAppState) => {
  return state.sidePanel;
};

const _formatSidePanelState = ({ open, openPanels }: SidePanelState) => ({
  sidePanelOpen: open,
  openPanels,
});

export const getSidePanelState = createSelector(
  _getSidePanelState,
  _formatSidePanelState,
);

export const getSidePanelTentativeOverride = createSelector(
  _getSidePanelState,
  (sidePanel: SidePanelState) =>
    sidePanel.openPanels.find((panel) => panel.panelStatus?.tentativeOverride)
      ?.panelStatus?.tentativeOverride || null,
);
