import { createSelector } from 'reselect';

import { filterAndKeyBy } from '@float/common/lib/keyBy';
import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { getIsProjectPlanView } from '@float/common/selectors/appInfo/scheduleView';
import { selectDatesManager } from '@float/common/selectors/currentUser';
import { getFullTasksList } from '@float/common/selectors/tasks';
import { ScheduleVisibleDataWindow } from '@float/common/serena/redux/reducers';
import { Task, TaskStatusEnum } from '@float/types';

import { selectVisibleDataWindow } from './selectVisibleDataWindow';

const getTaskIsInsideRange = (
  dates: DatesManager,
  { start, end }: ScheduleVisibleDataWindow,
  { start_date, end_date, repeat_end_date }: Task,
) => {
  const taskStart = dates.toNum(start_date);
  let taskEnd = dates.toNum(end_date);

  if (repeat_end_date) {
    const duration = taskEnd - taskStart;
    taskEnd = dates.toNum(repeat_end_date) + duration;
  }

  return taskStart <= end && start <= taskEnd;
};
function getIsTaskVisible(
  dates: DatesManager,
  task: Task,
  visibleDataWindow: ScheduleVisibleDataWindow,
  isProjectPlanView: boolean,
) {
  if (task.status === TaskStatusEnum.Draft && !isProjectPlanView) return false;

  if (task.root_task_id) {
    // Include all the linked tasks even if they are not visible
    // see: https://linear.app/float-com/issue/CS-1755/
    return true;
  }

  // Is the person in one of the visible rows?
  if (!task.people_ids.some((id) => visibleDataWindow.peopleIds.has(id)))
    return false;

  // Is the task inside the columns range?
  return getTaskIsInsideRange(dates, visibleDataWindow, task);
}

export const selectVisibleTasks = createSelector(
  [
    selectVisibleDataWindow,
    selectDatesManager,
    getFullTasksList,
    (state: ReduxStateStrict) => state.tasks.fullyHydrated,
    getIsProjectPlanView,
  ],
  (visibleDataWindow, dates, tasks, fullyHydrated, isProjectPlanView) => {
    if (!fullyHydrated) return undefined;
    if (visibleDataWindow === null) return {};

    return filterAndKeyBy(tasks, 'task_id', (task) =>
      getIsTaskVisible(dates, task, visibleDataWindow, isProjectPlanView),
    );
  },
);
