import * as cellDataTransformers from '@float/common/serena/Data/transformers';
import { getPeopleDataByDay } from '@float/common/serena/Data/transformers/getPeopleDataByDay';
import { getPeopleDataByWeek } from '@float/common/serena/Data/transformers/getPeopleDataByWeek';
import { getProjectsDataByDay } from '@float/common/serena/Data/transformers/getProjectsDataByDay';
import { getProjectsDataByWeek } from '@float/common/serena/Data/transformers/getProjectsDataByWeek';
import { BreakdownPayload } from '@float/common/serena/Data/transformers/types';
import { Phase } from '@float/types/phase';

export type Payload = BreakdownPayload & {
  phases: Record<number, Phase>;
  viewBy: string | undefined;
  viewType: 'people' | 'projects';
  areNewParseModulesEnabled?: boolean;
};

export function parseData(payload: Payload) {
  const { viewBy, viewType, areNewParseModulesEnabled, ...rest } = payload;

  if (areNewParseModulesEnabled) {
    if (viewType === 'people') {
      if (viewBy === 'week') return getPeopleDataByWeek(rest);
      if (viewBy === 'day') return getPeopleDataByDay(rest);
    }
    if (viewType === 'projects') {
      if (viewBy === 'week') return getProjectsDataByWeek(rest);
      if (viewBy === 'day') return getProjectsDataByDay(rest);
    }
  }
  if (viewType === 'people') {
    if (viewBy === 'week')
      return cellDataTransformers.breakdownPeopleByWeek(rest);
    if (viewBy === 'day')
      return cellDataTransformers.breakdownPeopleByDay(rest);
  }
  if (viewType === 'projects') {
    if (viewBy === 'week')
      return cellDataTransformers.breakdownProjectsByWeek(rest);
    if (viewBy === 'day')
      return cellDataTransformers.breakdownProjectsByDay(rest);
  }

  throw Error(`Unknown transformation requested ${viewType}:${viewBy}`);
}

export type CsvParsedData = ReturnType<typeof parseData>;
export type CsvParsedPeopleData =
  | ReturnType<typeof cellDataTransformers.breakdownPeopleByDay>
  | ReturnType<typeof cellDataTransformers.breakdownPeopleByWeek>;

export type CsvParsedProjectsData =
  | ReturnType<typeof cellDataTransformers.breakdownProjectsByDay>
  | ReturnType<typeof cellDataTransformers.breakdownProjectsByWeek>;
