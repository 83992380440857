import styled, { css } from 'styled-components';

const commonTextStyles = css`
  line-height: 20px;
  letter-spacing: -0.1px;
`;

export const Section = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
`;

export const SectionHeading = styled.div`
  font-weight: bold;
  letter-spacing: -0.1px;
  margin-bottom: 10px;
  ${commonTextStyles}
`;

export const SectionContent = styled.div`
  font-size: 16px;
  ${commonTextStyles}
`;

export const SectionWrapper = styled.div`
  display: flex;
`;

export const SectionButton = styled.div`
  min-width: 168px;
  display: flex;
  justify-content: flex-end;
`;
