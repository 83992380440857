import React, {
  CSSProperties,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  PropsWithChildren,
} from 'react';
import cn from 'classnames';

import * as styles from './styles.css';

type SidePanelTableRowProps = {
  className?: string;
  onClick?: MouseEventHandler;
  onBlur?: FocusEventHandler;
  onKeyDown?: KeyboardEventHandler;
  'data-testid'?: string;
  hover?: boolean;
  large?: boolean;
  style?: CSSProperties;
};

export const SidePanelTableRow = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<SidePanelTableRowProps>
>((props: PropsWithChildren<SidePanelTableRowProps>, ref) => {
  return (
    <div
      ref={ref}
      role="row"
      className={cn(
        styles.tableRow({
          selectors: props.hover ? 'hover' : 'default',
        }),
        props.className,
      )}
      data-testid={props['data-testid']}
      onClick={props.onClick}
      onBlur={props.onBlur}
      onKeyDown={props.onKeyDown}
      style={props.style}
    >
      {props.children}
    </div>
  );
});
