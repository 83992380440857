import styled from 'styled-components';

const ProjectColor = styled.div`
  min-width: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #${(p) => p.color};
`;

export default ProjectColor;
