import { ReactNode, useCallback, useState } from 'react';

export interface IUseDragTooltipWarning {
  isWarningForcedToBeHidden: boolean;
  isWarningVisible: boolean;
  onItemClick?: () => void;
  onItemDragChange: (dragging: boolean) => void;
  onItemDragError: (error: string) => void;
  warningMessage: ReactNode;
}

export function useDragTooltipWarning(): IUseDragTooltipWarning {
  const [isForcedToBeHidden, setIsForcedToBeHidden] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState('');

  const onItemDragChange = useCallback((dragging: boolean) => {
    if (!dragging) {
      setIsVisible(false);
    }
  }, []);

  const onItemDragError = useCallback(
    (message: string) => {
      if (!isVisible) {
        setIsVisible(true);
        setMessage(message);
        setIsForcedToBeHidden(false);
      }
    },
    [isVisible],
  );

  const onItemClick = useCallback(() => {
    setIsForcedToBeHidden(true);
  }, []);

  return {
    isWarningForcedToBeHidden: isForcedToBeHidden,
    isWarningVisible: isVisible,
    onItemClick,
    onItemDragChange,
    onItemDragError,
    warningMessage: message,
  };
}
