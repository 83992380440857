import { getDiagonalStripeGradient } from '@float/libs/web/utils/getDiagonalStripeGradient';

const colors = {
  blue: '#1a90dc',
  darkBlue: '#0970ae',
  darkerBlue: '#065A8C',
  lightBlue: '#abddf9',
  lighterBlueActive: '#CEE7FE',
  lighterBlue: '#E1F0FF',
  blueGrey: '#868d92',
  charcoalGrey: '#363d46',
  slateGrey: '#5b6064',
  medGrey: '#c1c1c1',
  midGreyDarker: '#B8B9BA',
  midGrey: '#d8d8d8',
  grey: '#e2e2e2',
  lightGrey: '#ebebeb',
  superLightGrey: '#f7f7f7',
  green: '#00ac00',
  accentYellow: '#ffca69',
  lightGreen: '#46eb46',
  darkYellow: '#dc9112',
  orange: '#ffb53a',
  darkOrange: '#ff8d22',
  orangeYellow: '#f5a623',
  red: '#e62768',
  darkRed: '#b21047',
  lightAqua: '#19d2da',
  aqua: '#00a8ae',
  darkAqua: '#0eb0b7',
  teal: '#2bccc3',
  midTeal: '#60ede5',
  lightTeal: '#a5fcf7',
  purple: '#9b8cff',
  lightPurple: '#d6d1f8',
  darkPurple: '#363d46',
  black25: 'rgba(0, 0, 0, .25)',
  black12: 'rgba(0, 0, 0, 0.12)',
  white: '#ffffff',
  timeoff: getDiagonalStripeGradient('#c8c8c8', -45),
  surface50: '#F5F6F7',
  surface900: '#171a1d',
  transparent: 'transparent',

  // Design system colors
  primary: '#2E5FE8',
  flueLight3: '#EAF4FD',
  flueLight4: '#E1F0FF',
  flueLight5: '#CEE7FE',
  flueLight6: '#B7D9F8',
  flueLight9: '#2E5FE8',
  flueLight10: '#254CBA',
  flueLight11: '#173074',
  flayLight3: '#F3F2F5',
  flayLight4: '#EDEBF0',
  flayLight5: '#DCE2EB',
  flayLight6: '#D6DEEB',
  flayLight7: '#BFCBDC',
  flayLight10: '#637A9A',
  flayLight11: '#344765',
  flayLight12: '#242C39',
  secondaryHover: '#EDEBF0',
  primaryPressed: '#173074',
  emphasisLow: '#8290AA',
  emphasisMedium: '#344765',
  emphasisHigh: '#242C39',
  primaryFlayLight4: '#EDEBF0',
  stroke1: '#EDEBF0',
  stroke2: '#DCE2EB',
  stroke3: '#A6B3C7',
  stroke4: '#637A9A',
  surface1: '#FFFFFF',
  surface2: '#F8F7F9',
  surface3: '#F3F2F5',
  surface4: '#EDEBF0', // TODO: We've got to find a better naming convention. E.g. EDEBF0 has 5 names here.
  surface5: '#DCE2EB',
  surface6: '#D6DEEB',
  secondaryRedLight4: '#FFE5E5',
  secondaryRedLight11: '#CD2B31',
  orangeBg: '#FFF1E7', // TODO: earhart - use name from figma
  orangeText: '#BD4B00', // TODO: earhart - use name from figma
} as const;

function textColorFor(color: string) {
  switch (color) {
    case 'lightGrey':
    case 'midGrey':
    case 'grey':
    case 'medGrey':
      return colors.charcoalGrey;
    case 'superLightGrey':
      return colors.blueGrey;
    case 'white':
    case 'flayLight3':
      return colors.emphasisHigh;
    default:
      return colors.white;
  }
}

/**
 * @deprecated Use `@float/common/earhart/colors` instead
 *
 * importing this file might cause navigator not found errors in webpack
 */
export default {
  ...colors,
  textColorFor,
};
