import React, { ElementType, ReactNode, StrictMode } from 'react';
import cn from 'classnames';

import * as styles from './styles.css';

type ButtonCardComponentProps<T> = {
  as?: T;
  children?: ReactNode;
  label: string;
  description: ReactNode;
};

export type ButtonCardProps<T extends ElementType> =
  React.ComponentPropsWithoutRef<T> & ButtonCardComponentProps<T>;

export const ButtonCard = <T extends React.ElementType = 'button'>({
  as,
  ...props
}: ButtonCardComponentProps<T> &
  Omit<
    React.ComponentPropsWithoutRef<T>,
    keyof ButtonCardComponentProps<T>
  >) => {
  const { className, onClick, children, label, description, ...rest } = props;

  const Component = as || 'button';

  return (
    <StrictMode>
      <Component
        className={cn(className, styles.button)}
        onClick={onClick}
        aria-label={label}
        {...rest}
      >
        <div className={styles.illustration}>{children}</div>
        <p className={styles.label}>{label}</p>
        <p className={styles.description}>{description}</p>
      </Component>
    </StrictMode>
  );
};
