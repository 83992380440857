import styled from 'styled-components';

import { Icons } from '@float/ui/deprecated';

const SectionErrorWrapper = styled.div`
  min-height: 245px;
  height: ${(p) => p.height}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(p) => p.theme.superLightGrey};
  ${(p) => p.topMargin && 'margin-top: 35px;'}

  svg {
    margin-bottom: 15px;
  }

  h3 {
    font-size: 21px;
    font-weight: bold;
    color: ${(p) => p.theme.charcoalGrey};
    margin-bottom: 10px;
  }

  p {
    font-size: 15px;
    color: ${(p) => p.theme.blueGrey};
  }
`;

function SectionError({ height, topMargin }) {
  return (
    <SectionErrorWrapper height={height} topMargin={topMargin}>
      <Icons.ErrorTriangle />
      <h3>Oops, looks like this section isn’t loading.</h3>
      <p>Please try refreshing the page.</p>
    </SectionErrorWrapper>
  );
}

export default SectionError;
