import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useCombinedRefs } from '@float/libs/hooks/useCombinedRefs';

import InputText from '../InputText';

const INPUT_EVENTS = [
  'input',
  'keydown',
  'keyup',
  'mousedown',
  'mouseup',
  'select',
  'contextmenu',
  'drop',
];

const InputNumber = forwardRef((props, ref) => {
  const { defaultValue, withPattern = InputNumber.Patterns.Float } = props;

  const innerRef = useRef();
  const combinedRef = useCombinedRefs([ref, innerRef]);

  const [currentValue, setCurrentValue] = useState(defaultValue);

  // Events
  const onInputBlur = (e) => {
    if (
      e.target.min &&
      (parseInt(e.target.value, 10) < parseInt(e.target.min, 10) ||
        e.target.value === '')
    ) {
      e.target.value = e.target.min;
      setCurrentValue(e.target.value);
    } else if (
      e.target.max &&
      parseInt(e.target.value, 10) > parseInt(e.target.max, 10)
    ) {
      e.target.value = e.target.max;
      setCurrentValue(e.target.value);
    }
  };

  // Validator

  const isPattern = useCallback(
    (value) => (withPattern ? withPattern(value) : true),
    [withPattern],
  );

  const inputFilter = useCallback(
    function () {
      if (isPattern(this.value)) {
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
      } else if (this.hasOwnProperty('oldValue')) {
        this.value = this.oldValue;
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      } else {
        this.value = '';
      }

      setCurrentValue(this.value);
    },
    [isPattern],
  );

  // Hooks

  useEffect(() => {
    const element = innerRef.current;

    INPUT_EVENTS.forEach(function (event) {
      element && element.addEventListener(event, inputFilter);
    });

    return () => {
      INPUT_EVENTS.forEach(function (event) {
        element && element.removeEventListener(event, inputFilter);
      });
    };
  }, [inputFilter]);

  // Render

  return (
    <InputText
      ref={combinedRef}
      type="text"
      value={currentValue}
      onBlur={onInputBlur}
      {...props}
    />
  );
});

// Pattern examples https://jsfiddle.net/emkey08/zgvtjc51
export const InputNumberPatterns = {
  Integer: (value) => /^-?\d*$/.test(value),
  IntegerPositive: (value) => /^\d*$/.test(value),
  Float: (value) => /^-?\d*[.,]?\d{0,2}$/.test(value),
};

InputNumber.Patterns = InputNumberPatterns;

export default InputNumber;
