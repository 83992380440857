import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { PhaseFormData, ProjectPhaseRecord } from '@float/types/phase';

import { useFieldArrayContext } from '../../../hooks/useFieldArrayContext';
import { ProjectFormData } from '../../../types';

function mapPhaseFormDataToPhaseRecord(data: PhaseFormData) {
  const { phase } = data;
  const result: ProjectPhaseRecord = {
    active: phase.active,
    budget_total: phase.budget_total,
    color: phase.color,
    end_date: phase.end_date ?? '',
    phase_id: phase.phase_id ?? undefined,
    phase_name: phase.phase_name,
    start_date: phase.start_date ?? '',
    non_billable: phase.non_billable,
    duration: phase.duration ?? null,
    parent_offset: phase.parent_offset ?? null,
    template_phase_id: phase.template_phase_id,
  };

  const isNewProject = !phase.project_id;
  if (isNewProject) {
    // For projects being newly created, store details gathered from phase panel.
    // Upon project form submission, all phase details will be sent to the server.
    result.fullPhaseData = data;
  }

  return result;
}

export function usePhaseInfoUpdateHandler() {
  const { update } = useFieldArrayContext<ProjectFormData, 'phases'>();
  const { resetField } = useFormContext<ProjectFormData>();

  const handlePhaseInfoUpdate = useCallback(
    (index: number, data: PhaseFormData) => {
      const updatedProjectPhaseRow = mapPhaseFormDataToPhaseRecord(data);

      // remounts the phase row with updated values
      update(index, updatedProjectPhaseRow);

      // updates default values for this phase row so that it doesn't
      // contribute to the form being evaluated as dirty
      resetField(`phases.${index}`, { defaultValue: updatedProjectPhaseRow });
    },
    [update, resetField],
  );

  return handlePhaseInfoUpdate;
}
