import { useEffect } from 'react';
import { useManageModal } from 'modalManager/useManageModal';

export function useNoSession({
  isSharedLink,
  loading,
}: {
  isSharedLink: boolean;
  loading: boolean;
}) {
  const { manageModal } = useManageModal();

  useEffect(() => {
    const callback = () => {
      if (!isSharedLink) {
        if (loading) {
          window.location.replace('/login');
        } else {
          manageModal({ visible: true, modalType: 'noSessionModal' });
        }
      }
    };

    window.addEventListener('noSession', callback);

    return () => window.removeEventListener('noSession', callback);
  });
}
