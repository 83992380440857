import { isArray, isEmpty, isUndefined, sortBy } from 'lodash';

import { formatAmount, isAmountEmpty } from '@float/common/lib/budget';
import { userCanSeeBudgets } from '@float/common/selectors/projects';
import { moment } from '@float/libs/moment';
import { BudgetPriority } from '@float/types/project';

import { getPersonRate } from './ProjectForm/helpers/getPersonRate';

const DATE_FORMAT = 'D MMM YYYY';

export const getSortedMilestones = (milestones) =>
  sortBy(milestones, (m) => `${m.date}${m.end_date}${m.name}`);

export const formatMilestoneDate = (milestone) => {
  const start = moment(milestone.date).format(DATE_FORMAT);
  const end = milestone.end_date
    ? moment(milestone.end_date).format(DATE_FORMAT)
    : null;
  return start === end || end === null ? start : `${start} - ${end}`;
};

export const getFilteredTeam = (project, currentUser, people) => {
  if (isEmpty(project.people_ids)) return [];

  return sortBy(
    project.people_ids
      .map((id) => people[id])
      .filter((p) => {
        if (!p || !p.active) return false;
        if (!currentUser.department_filter_all?.length) return true;
        return currentUser.department_filter_all.includes(p.department_id);
      }),
    (p) => p.name.toLowerCase(),
  );
};

export const getPersonProjectRate = (
  person,
  project,
  { withCurrencySymbol = true } = {},
) => {
  const budgetType = withCurrencySymbol ? project.budget_type : null;
  if (project.people_rates[person.people_id]) {
    return formatAmount(budgetType, project.people_rates[person.people_id], {
      inLocale: true,
    });
  }

  if (person.default_hourly_rate) {
    return formatAmount(budgetType, person.default_hourly_rate);
  }

  return '0';
};

export async function tryPersisting(fn, { onError, showSnackbar } = {}) {
  try {
    return await fn();
  } catch (err) {
    if (onError) {
      return onError();
    }
    if (showSnackbar) {
      let message = err && err.message;
      if (!message && isArray(err)) {
        message = (err.find((x) => x.message) || {}).message;
      }
      showSnackbar(message || 'An error occurred.');
    }
    return null;
  }
}

export function validate(entity, peopleMap) {
  const formErrors = {};
  const isPhase = !isUndefined(entity.phase_name);
  const isBudgetPerProject =
    !isPhase && entity.budget_priority === BudgetPriority.Project;
  const isBudgetPerPhase =
    isPhase && entity.budget_priority === BudgetPriority.Phase;
  const canSeeBudgets = userCanSeeBudgets(this.props.currentUser);

  const shouldValidateTotal =
    canSeeBudgets &&
    [1, 2].includes(entity.budget_type) &&
    ((isPhase && isBudgetPerPhase) || (!isPhase && isBudgetPerProject));

  // allow zero on phase budgets, but not on project budgets
  const isTotalEmpty = isPhase
    ? isAmountEmpty(entity.budget_total)
    : !+entity.budget_total;

  if (shouldValidateTotal && isTotalEmpty) {
    formErrors.budget_total = ['Required.'];
  }

  const isHourly = entity.budget_type === 3;
  const isVariableRate =
    canSeeBudgets &&
    (isHourly || entity.budget_type === 2) &&
    entity.default_hourly_rate === null;

  if (isHourly && !isVariableRate && !+entity.default_hourly_rate) {
    formErrors.default_hourly_rate = ['Required.'];
  }

  if (isVariableRate) {
    if (
      entity.project_team?.set?.some((x) => {
        const person = peopleMap[x.people_id];
        if (!person || !person.active) {
          // Skip the rate requirement if the users are archived as they
          // don't render on the team member list
          return false;
        }

        return isAmountEmpty(getPersonRate.call(this, x.people_id));
      })
    ) {
      formErrors.people_rates = [
        'Please add hourly rates for all team members.',
      ];
    }
  }

  // Same validation helper is being used for both projects and phases so need to add this check
  if (entity.hasOwnProperty('phase_name') && !entity.phase_name) {
    formErrors.phase_name = ['Required.'];
  }

  if (entity.hasOwnProperty('project_name') && !entity.project_name) {
    formErrors.project_name = ['Required.'];
  }

  if (!isEmpty(formErrors)) {
    this.setFormErrors(formErrors);
    return false;
  }

  return true;
}

export function isCurrentUserOwner() {
  const { admin_id: currentUserAccountId, account_tid: accountType } =
    this.props.currentUser;
  // Department Manager sees all?
  const isAdminOrHigher = [1, 2, 5, 6].some((x) => x == accountType);
  const project = this.initialProject || this.project;
  return isAdminOrHigher || project?.project_manager == currentUserAccountId;
}
