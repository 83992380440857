import React from 'react';
import cs from 'classnames';
import { isNaN, isNil, padStart } from 'lodash';

import { getProjectBudgetStatusText } from '@float/common/lib/budget';
import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { BudgetProject } from '@float/types/budget';
import { Phase } from '@float/types/phase';
import { BudgetType, EnhancedProject } from '@float/types/project';
import { TextTooltip } from '@float/ui/deprecated';

import * as styles from './ProjectBudgetBar.css';

type ProjectBudgetBarProps = {
  budgets: ReduxStateStrict['budgets'];
  project: EnhancedProject;
  phase: Phase;
};

export const ProjectBudgetBar = (props: ProjectBudgetBarProps) => {
  const {
    budgets,
    project: { canSeeBudget, budget_type, active },
  } = props;

  if (!active) return null;
  if (!canSeeBudget || !budget_type) return null;

  let budget_remaining, budget_total;
  if (props.phase) {
    const budgetUsage = budgets?.phases[props.phase.phase_id] || {};

    budget_total = budgetUsage.budget_total || 0;
    budget_remaining = budgetUsage.budget_remaining || 0;
  } else {
    const budgetUsage = budgets?.projects[props.project.project_id] || {};

    budget_total = budgetUsage.budget_total || 0;
    budget_remaining = budgetUsage.budget_remaining || 0;
  }

  budget_remaining = isNil(budget_remaining) ? budget_total : budget_remaining;

  const overBudget = [BudgetType.TotalHours, BudgetType.TotalFee].includes(
    budget_type,
  )
    ? budget_remaining < 0
    : false;

  let utilization = ((budget_total - budget_remaining) * 100) / budget_total;

  if (isNaN(utilization)) {
    utilization = 0;
  }

  const budgetText = getProjectBudgetStatusText({
    canSeeBudget,
    budget_type,
    budget_remaining,
    budget_total,
    suppressPercentage: false,
  });

  return (
    <TextTooltip content={budgetText} className="hint">
      <div className={styles.wrapper}>
        <div
          className={cs(
            styles.utilization,
            overBudget && styles.utilizationOverBudget,
          )}
          style={{ width: `${utilization}%` }}
        />
      </div>
    </TextTooltip>
  );
};

export const getBudgetSortString = (
  project: EnhancedProject,
  projectBudget: BudgetProject,
  dir = 'asc',
) => {
  const { canSeeBudget, budget_type } = project;
  const { budget_total = 0, budget_used = 0 } = projectBudget;

  let percent = 0;

  const prefix =
    // keep projects with budget on top of list
    (!budget_type && dir === 'asc') || (budget_type && dir === 'desc')
      ? '0'
      : 'z';

  if (canSeeBudget) {
    if (
      [BudgetType.TotalHours, BudgetType.TotalFee].includes(budget_type || 0)
    ) {
      if (budget_total) {
        percent = Math.floor(((budget_used || 0) * 100) / budget_total);
      } else {
        percent = 100;
      }
    } else if (budget_type === BudgetType.HourlyFee) {
      percent = 100;
    }
  }

  return `${prefix}${padStart(`${percent}`, 8, '0')}`;
};
