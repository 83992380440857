import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  &:not(.no-hover) {
    &:hover,
    &.active {
      path {
        fill: #fff;
      }
    }
  }
`;

export default function ({ size = '24', className = '', color = '#ABDDF9' }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      className={className}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M19 3c1.093 0 2 .907 2 2v14c0 1.093-.907 2-2 2H5c-1.093 0-2-.907-2-2V5c0-1.093.907-2 2-2h14zm-6 2H5v14h8V5zm6 0h-4v2h4V5zm0 4h-4v2h4V9zm0 4h-4v2h4v-2zm0 4h-4v2h4v-2z"
      />
    </Svg>
  );
}
