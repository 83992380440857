import { Person, Phase, Project, Role } from '@float/types';

/**
 *
 * Gets person's resolved rate
 *
 *  Resolution order:
 *
 *   Phase person rate specified in the phase
 *   Phase default hourly rate
 *   Project person rate specified in the phase
 *   Project default hourly rate
 *   Person default hourly rate
 *   Person role default hourly rate
 */

export type GetPersonResolvedRateOptions = {
  person: Person;
  project?: Project | null;
  phase?: Phase | null;
  role?: Role | null;
};

export const getPersonResolvedRate = ({
  person,
  project,
  phase,
  role,
}: GetPersonResolvedRateOptions) => {
  const personId = person.people_id;

  const phaseDefaultRate = phase?.default_hourly_rate;
  const phasePersonRate = phase?.people_rates?.[personId];

  const projectDefaultRate = project?.default_hourly_rate;
  const projectPersonRate = project?.people_rates?.[personId];

  const personDefaultRate = person.default_hourly_rate;

  const personRoleDefaultRate = role?.default_hourly_rate;

  if (phaseDefaultRate) {
    return parseFloat(phaseDefaultRate);
  }
  if (phasePersonRate) {
    return parseFloat(phasePersonRate);
  }

  if (projectDefaultRate) {
    return parseFloat(projectDefaultRate);
  }

  if (projectPersonRate) {
    return parseFloat(projectPersonRate);
  }

  if (personDefaultRate) {
    return parseFloat(personDefaultRate);
  }

  if (personRoleDefaultRate) {
    return parseFloat(personRoleDefaultRate);
  }

  return null;
};
