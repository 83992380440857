import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon {...props}>
    <defs>
      <linearGradient
        id="icon-lock-clock-gradient"
        x1="17.09"
        x2="35.691"
        y1="18.081"
        y2="36.123"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5DD0CE" />
        <stop offset="1" stopColor="#1B7CEF" />
      </linearGradient>
    </defs>

    <path
      fill="url(#icon-lock-clock-gradient)"
      fillRule="evenodd"
      d="M9.716 6.616a1.25 1.25 0 1 1 1.768 1.768l-1 1a1.25 1.25 0 1 1-1.768-1.768l1-1Zm26.9 0a1.25 1.25 0 0 1 1.768 0l1 1a1.25 1.25 0 0 1-1.768 1.768l-1-1a1.25 1.25 0 0 1 0-1.768ZM11.25 22.5c0-7.318 5.932-13.25 13.25-13.25 4.615 0 8.679 2.358 11.053 5.94a1.25 1.25 0 1 0 2.084-1.38C34.82 9.558 29.987 6.75 24.5 6.75c-8.698 0-15.75 7.052-15.75 15.75 0 6.134 3.507 11.446 8.619 14.047a1.25 1.25 0 1 0 1.133-2.229A13.25 13.25 0 0 1 11.25 22.5Zm14.5-8a1.25 1.25 0 1 0-2.5 0v7.292l-4.393 2.636a1.25 1.25 0 1 0 1.286 2.144l5-3 .607-.364V14.5Zm1.382 13.622c.003-.503.022-1.05.1-1.574.126-.868.429-1.83 1.195-2.616.842-.862 2.123-1.482 3.959-1.482 1.836 0 3.116.62 3.958 1.482.767.786 1.07 1.748 1.197 2.616.076.524.095 1.072.099 1.574a2.192 2.192 0 0 1 1.88 2.17v5.179a3.133 3.133 0 0 1-3.133 3.133h-8.004a3.133 3.133 0 0 1-3.133-3.133v-5.18c0-1.105.818-2.019 1.882-2.17Zm2.5-.022c.004-.428.018-.817.073-1.19.085-.582.251-.965.512-1.232.329-.337.944-.728 2.169-.728s1.84.39 2.169.728c.26.267.427.65.512 1.232.055.373.069.762.072 1.19h-5.506Zm-1.882 2.5v4.87c0 .35.284.634.633.634h8.005c.35 0 .633-.283.633-.633V30.6H27.75Z"
      clipRule="evenodd"
    />
  </BaseIcon>
);
