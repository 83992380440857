import { isNewPlaceholderType } from '@float/common/lib/people/isNewPlaceholderType';
import { Person } from '@float/types/person';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';

/**
 * Determines whether the email field should be disabled and its color based on
 * the provided person object.
 *
 * @param {Object} props - The properties object containing person and
 * initialPerson.
 * @param {Person} props.person - The current person object.
 * comparison.
 * @returns {Object} An object containing the email field state.
 * @property {string} emailColor - The color of the email field.
 * @property {boolean} isEmailDisabled - Indicates whether the email field is
 * disabled.
 */

type UseEmailFieldStateProps = { person: Person };

export const useEmailFieldState = (props: UseEmailFieldStateProps) => {
  const { person } = props;

  const isPersonNewPlaceholderType = isNewPlaceholderType({
    person,
  });

  const hasAcceptedInvite = !!(person.account && person.account.accepted);
  const isGuest = !!person.guest;

  const isEmailDisabled =
    hasAcceptedInvite || isGuest || isPersonNewPlaceholderType;
  const emailColor = isPersonNewPlaceholderType
    ? Colors.FIN.Lt.Emphasis.Disabled
    : Colors.FIN.Lt.Emphasis.Medium;

  return { emailColor, isEmailDisabled };
};
