import API3 from '@float/common/api3';
import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { RawTaskMeta, TaskMeta } from '@float/types';

import {
  bulkDeleteTaskMeta,
  createTaskMeta,
  deleteTaskMeta,
  mergeTaskMeta,
  updateTaskMeta,
} from '../api3/tasks';
import { AppDispatchStrict } from '../store';
import { DELETE_TASK_META_SUCCESS, UPDATE_TASK_META_SUCCESS } from './tasks';

export const TASK_META_LOAD_START = 'TASK_META_LOAD_START';
export const TASK_META_LOAD_FAILED = 'TASK_META_LOAD_FAILED';
export const TASK_META_LOAD_FINISH = 'TASK_META_LOAD_FINISH';

export const mapRawTaskMetaToTaskMeta = (
  taskMetaRaw: RawTaskMeta,
): TaskMeta => {
  return {
    ...taskMetaRaw,
    budget: taskMetaRaw.budget ? parseFloat(taskMetaRaw.budget) : null,
  };
};

export type TaskMetaLoadStartAction = {
  type: typeof TASK_META_LOAD_START;
  projectId: number;
};

export type TaskMetaLoadFailedAction = {
  type: typeof TASK_META_LOAD_FAILED;
  projectId: number;
};

export type TaskMetaLoadFinishAction = {
  type: typeof TASK_META_LOAD_FINISH;
  projectId: number;
  taskMetas: TaskMeta[];
};

export const fetchTaskMetas =
  (projectId: number | undefined) =>
  async (dispatch: AppDispatchStrict, getState: () => ReduxStateStrict) => {
    if (!projectId) return;

    const { loadState } = getState().taskMetas;

    if (loadState[projectId] === 'LOADING') return;

    try {
      dispatch({
        type: TASK_META_LOAD_START,
        projectId,
      });

      const taskMetasRaw = await API3.getTaskMeta({ project_id: projectId });
      const taskMetas = taskMetasRaw.map(mapRawTaskMetaToTaskMeta);

      dispatch({
        type: TASK_META_LOAD_FINISH,
        taskMetas,
        projectId,
      });
    } catch (e) {
      dispatch({
        type: TASK_META_LOAD_FAILED,
        projectId,
      });

      throw e;
    }
  };

export const prefetchTaskMetas =
  (projectId: number | undefined) =>
  async (dispatch: AppDispatchStrict, getState: () => ReduxStateStrict) => {
    if (!projectId) return;

    const { loadState } = getState().taskMetas;

    if (
      loadState[projectId] === 'LOADING' ||
      loadState[projectId] === 'LOADED'
    ) {
      return;
    }

    try {
      dispatch({
        type: TASK_META_LOAD_START,
        projectId,
      });

      const taskMetasRaw = await API3.getTaskMeta(
        {
          project_id: projectId,
        },
        {
          type: 'prefetch',
        },
      );
      const taskMetas = taskMetasRaw.map(mapRawTaskMetaToTaskMeta);

      dispatch({
        type: TASK_META_LOAD_FINISH,
        taskMetas,
        projectId,
      });
    } catch (e) {
      // Not re-throwing because:
      // 1. This is a prefetch, so the failure does not compromise the usability of the app
      // 2. We call this function alot, re-throwing means that we would track the error and flood the logs

      dispatch({
        type: TASK_META_LOAD_FAILED,
        projectId,
      });
    }
  };

export const refreshTaskMetas =
  (projectId: number) =>
  async (dispatch: AppDispatchStrict, getState: () => ReduxStateStrict) => {
    const { loadState } = getState().taskMetas;

    if (loadState[projectId] === 'LOADED') {
      return fetchTaskMetas(projectId)(dispatch, getState);
    }
  };

export const updateTaskMetaAction = (
  params: Pick<TaskMeta, 'billable' | 'budget' | 'task_name'>,
  id: TaskMeta['task_meta_id'],
) => {
  return async (dispatch: AppDispatchStrict) => {
    const payloadRaw = await updateTaskMeta(params, id);
    const payload = mapRawTaskMetaToTaskMeta(payloadRaw);

    dispatch({
      type: UPDATE_TASK_META_SUCCESS,
      item: payload,
    });
    return payload;
  };
};

export const createTaskMetaAction = (
  params: Pick<TaskMeta, 'billable' | 'budget' | 'project_id' | 'task_name'> & {
    phase_id?: TaskMeta['phase_id'];
  },
) => {
  return async (dispatch: AppDispatchStrict) => {
    const payloadRaw = await createTaskMeta(params);
    const payload = mapRawTaskMetaToTaskMeta(payloadRaw);

    dispatch({
      type: UPDATE_TASK_META_SUCCESS,
      item: payload,
    });
    return payload;
  };
};

export const deleteTaskMetaAction = (id: TaskMeta['task_meta_id']) => {
  return async (dispatch: AppDispatchStrict) => {
    const payload = await deleteTaskMeta(id);
    dispatch({
      type: DELETE_TASK_META_SUCCESS,
      ids: [id],
    });
    return payload;
  };
};

export const bulkDeleteTaskMetaAction = (ids: TaskMeta['task_meta_id'][]) => {
  return async (dispatch: AppDispatchStrict) => {
    const payload = await bulkDeleteTaskMeta(ids);
    dispatch({
      type: DELETE_TASK_META_SUCCESS,
      ids,
    });
    return payload;
  };
};

export const mergeTaskMetasAction = (params: {
  task_meta_ids: number[];
  task_name: string;
  billable: 0 | 1;
  budget: TaskMeta['budget'];
}) => {
  return async (dispatch: AppDispatchStrict) => {
    const payload = await mergeTaskMeta(params);
    dispatch({
      type: UPDATE_TASK_META_SUCCESS,
      item: payload,
    });
    return payload;
  };
};
