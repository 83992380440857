import { useRef } from 'react';

export const useNavigationGuardController = ({
  closeModal,
  openModal,
  history,
}: {
  closeModal: () => void;
  openModal: () => void;
  history: { push: (path: string) => void };
}) => {
  const isNavigationConfirmed = useRef(false);
  const nextLocation = useRef('');

  // If it returns `false` the attempted React-Router navigation is blocked
  // and if `true` the navigation is permitted
  const handleBlockedNavigation = (location: { pathname: string }) => {
    if (!isNavigationConfirmed.current) {
      openModal();
      nextLocation.current = location.pathname;
      return false;
    }

    return true;
  };

  const handleNavigationConfirm = () => {
    closeModal();
    isNavigationConfirmed.current = true;
    history.push(nextLocation.current);
  };

  return { handleNavigationConfirm, handleBlockedNavigation };
};
