import { CellsMap, LoggedTimeRowId, PersonRowId, Timeoff } from '@float/types';

import { CellFns, DateUtils } from '../types';

export function getNonWorkSubCols(
  rowId: LoggedTimeRowId | PersonRowId,
  firstCellDay: number,
  x: number,
  w: number,
  dates: DateUtils,
  cells: CellsMap,
  isWorkDay: CellFns['isWorkDay'],
  ignoreTimeoff?: Timeoff,
) {
  const nonWorks: number[] = [];
  for (let i = firstCellDay + x; i < firstCellDay + x + w; i++) {
    if (!isWorkDay(cells, rowId, dates.fromNum(i), ignoreTimeoff)) {
      nonWorks.push(i - firstCellDay);
    }
  }
  return nonWorks;
}
