import React, { ComponentProps } from 'react';
import { AnimatePresence, motion, MotionProps } from 'framer-motion';

import { noop } from '@float/libs/utils/noop';
import { DateString } from '@float/types/datesManager';
import {
  TimeoffBalanceType,
  TimeoffPreviewMode,
} from '@float/types/timeoffType';

import { TimeoffRemainingDays } from './components/TimeoffRemainingDays/TimeoffRemainingDays';
import { TimeoffRequestedDays } from './components/TimeoffRequestedDays/TimeoffRequestedDays';

type Props = ComponentProps<'div'> & {
  date: Date | DateString;
  isTimeoffRequest: boolean;
  onClickDate?: () => void;
  previewMode?: TimeoffPreviewMode;
  remainingDays: number | null;
  requestedDays: number;
  type: TimeoffBalanceType;
};
const transition: MotionProps = {
  animate: { opacity: 1, transition: { duration: 0.15 } },
  exit: { opacity: 0, transition: { duration: 0.15 } },
  initial: { opacity: 0 },
};

export const TimeoffBalanceView = (props: Props) => {
  const {
    date,
    isTimeoffRequest,
    onClickDate = noop,
    previewMode,
    remainingDays,
    requestedDays,
    type,
  } = props;

  return (
    <>
      <TimeoffRequestedDays
        isTimeoffRequest={isTimeoffRequest}
        requestedDays={requestedDays}
      />
      <AnimatePresence>
        {remainingDays !== null && (
          <motion.span {...transition}> / </motion.span>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {remainingDays !== null && (
          <motion.span {...transition}>
            <TimeoffRemainingDays
              date={date}
              previewMode={previewMode}
              onClickDate={onClickDate}
              remainingDays={remainingDays}
              withDate={
                type === TimeoffBalanceType.AccruedPerAnniversary ||
                type === TimeoffBalanceType.AccruedPerYear
              }
            />
          </motion.span>
        )}
      </AnimatePresence>
    </>
  );
};
