import React from 'react';
import { t } from '@lingui/macro';

import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';

import { StepBase } from '../../StepBase';
import {
  StyledImage,
  StyledParagraph,
  StyledTitle,
} from '../../StepBase/styles';

import scheduleNoTTDesktopWithViewsImageSource from './images/ScheduleDesktopNoTTWithViews.jpg';
import scheduleNoTTDesktopWithViewsPPImageSource from './images/ScheduleDesktopNoTTWithViewsPP.png';
import scheduleDesktopWithViewsImageSource from './images/ScheduleDesktopWithViews.jpg';
import scheduleDesktopWithViewsPPImageSource from './images/ScheduleDesktopWithViewsPP.png';
import scheduleMobileImageSource from './images/ScheduleMobile.jpg';
import scheduleNoTTMobileImageSource from './images/ScheduleMobileNoTT.jpg';
import scheduleNoTTWithPPMobileImageSource from './images/ScheduleMobileNoTTWithPP.png';
import scheduleMobileWithPPImageSource from './images/ScheduleMobileWithPP.png';

function getDescription(hasTimeTracking, isProjectPlanInNavEnabled) {
  if (isProjectPlanInNavEnabled) {
    return hasTimeTracking
      ? t`Change your view from people, to the time logged on tasks.`
      : t`Schedule work based on your team’s capacity.`;
  }

  return hasTimeTracking
    ? t`Change your view from people, to projects, or the time logged on tasks.`
    : t`Change your view from people to projects.`;
}

function getImageSource(hasTimeTracking, isMobile, isProjectPlanInNavEnabled) {
  if (isProjectPlanInNavEnabled) {
    if (isMobile) {
      return hasTimeTracking
        ? scheduleMobileWithPPImageSource
        : scheduleNoTTWithPPMobileImageSource;
    }

    return hasTimeTracking
      ? scheduleDesktopWithViewsPPImageSource
      : scheduleNoTTDesktopWithViewsPPImageSource;
  }

  if (isMobile) {
    return hasTimeTracking
      ? scheduleMobileImageSource
      : scheduleNoTTMobileImageSource;
  }

  return hasTimeTracking
    ? scheduleDesktopWithViewsImageSource
    : scheduleNoTTDesktopWithViewsImageSource;
}

const ScheduleContent = (props) => {
  const { anchor, userSpecs } = props;
  const isProjectPlanInNavEnabled = featureFlags.isFeatureEnabled(
    FeatureFlag.ProjectPlanInMainNav,
  );

  const imageSource = getImageSource(
    userSpecs.hasTimeTracking,
    anchor.id === 'schedule-mobile',
    isProjectPlanInNavEnabled,
  );

  return (
    <>
      <StyledImage>
        <img src={imageSource} width="100%" height="100%" alt="Schedule" />
      </StyledImage>
      <StyledTitle>Set your focus</StyledTitle>
      <StyledParagraph>
        {getDescription(userSpecs.hasTimeTracking, isProjectPlanInNavEnabled)}
      </StyledParagraph>
      <StyledParagraph>See what you need, without distraction.</StyledParagraph>
    </>
  );
};

const Schedule = (props) => {
  return (
    <StepBase {...props}>
      <ScheduleContent anchor={props.anchor} userSpecs={props.userSpecs} />
    </StepBase>
  );
};

export default Schedule;
