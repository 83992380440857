import { useCallback } from 'react';
import gsap from 'gsap';
import { TransitionRef } from 'TransitionManager';

import { AnimatedElements } from './useAnimatedElements';

export const useOnEnterAnimation = (
  animatedElements: AnimatedElements | undefined,
  leave: () => void,
) => {
  const onEnter = useCallback<TransitionRef['onEnter']>(
    ({ isAppearing, delay: appearDelay }) => {
      const delay = isAppearing ? appearDelay : 0;

      return new Promise<void>((success) => {
        if (animatedElements) {
          gsap.set(animatedElements.header, { y: 30, opacity: 0 });

          gsap.set(animatedElements.checkBG, { scale: 0, opacity: 0 });
          gsap.set(animatedElements.checkHalo, {
            scale: 0,
            opacity: 1,
          });
          gsap.set(animatedElements.checkSVG, {
            scale: 0.5,
            transformOrigin: 'center',
          });
          gsap.set(animatedElements.checkSVGPath, {
            strokeDashoffset: -80,
          });

          const people = animatedElements?.people as Array<Element>;
          gsap.set(people[0], {
            y: 50,
            x: -100,
            opacity: 0,
          });

          gsap.set(people[1], {
            y: 50,
            x: 100,
            opacity: 0,
          });

          gsap.set(animatedElements.icons, {
            y: 'random(50, 100)',
            opacity: 0,
          });
        }

        window.requestIdleCallback(() => {
          if (animatedElements?.people) {
            gsap.to(animatedElements.people, {
              x: 0,
              y: 0,
              opacity: 1,
              duration: 0.65,
              delay,
              ease: 'power3.out',
              clearProps: 'all',
            });

            gsap.to(animatedElements.icons, {
              y: 0,
              opacity: 1,
              duration: 0.85,
              delay,
              ease: 'back.out(1.5)',
              clearProps: 'all',
              stagger: {
                each: 0.05,
                from: 'random',
              },
            });

            gsap.to(animatedElements.checkHalo, {
              scale: 1.6,
              opacity: 0,
              duration: 1.4,
              delay: delay + 0.4,
              ease: 'power4.out',
              clearProps: 'all',
            });

            gsap.to(animatedElements.checkBG, {
              scale: 1,
              opacity: 1,
              duration: 1,
              delay: delay + 0.45,
              ease: 'back.out(1.5)',
              clearProps: 'all',
            });

            gsap.to(animatedElements.checkSVG, {
              scale: 1,
              transformOrigin: 'center',
              duration: 1,
              delay: delay + 0.75,
              ease: 'back.out(1.5)',
              clearProps: 'all',
            });

            gsap.to(animatedElements.checkSVGPath, {
              strokeDashoffset: 0,
              duration: 1,
              delay: delay + 0.75,
              ease: 'power4.out',
              clearProps: 'all',
            });

            gsap.to(animatedElements.header, {
              y: 0,
              opacity: 1,
              duration: 1,
              delay: delay + 0.75,
              ease: 'back.out',
              clearProps: 'all',
            });

            gsap.to(animatedElements.checkCounterSVGCircle, {
              strokeDashoffset: 0,
              duration: 1,
              delay: delay + 0.75,
              ease: 'power4.inOut',
              overwrite: true,
              onComplete: () => {
                success();
                leave();
              },
            });
          }
        });
      });
    },
    [animatedElements, leave],
  );

  return { onEnter };
};
