import React, { useState } from 'react';
import { Trans } from '@lingui/macro';

import { calculatePlaceholdersDowngrade } from '@float/common/lib/calculatePlaceholdersDowngrade';
import { STARTER_MONTHLY, STARTER_YEARLY } from '@float/common/lib/pricing';
import { AddOnStatus } from '@float/types';
import {
  Button,
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  ModalTitle,
  withConfirm,
} from '@float/ui/deprecated';
import { Placeholders } from '@float/web/settingsV2/components/constants';
import { onChatClick } from 'components/Nav/helpers';

import {
  DowngradeFromEnterpriseIcon,
  DowngradeToStarterIcon,
} from '../common/PlanChangeIcons';
import { DowngradeAlert } from '../DowngradeAlert/DowngradeAlert';
import { StyledModalBody, StyledModalHeader } from './_styles';
import { DefaultModalProps } from './types';

const DowngradeFromEnterpriseFeatures = (props: {
  freePlaceholdersToPaidSeats: number;
}) => (
  <ul>
    {props.freePlaceholdersToPaidSeats > 0 && (
      <li>{props.freePlaceholdersToPaidSeats} of your included Placeholders</li>
    )}
    <li>In-built time tracking</li>
    <li>180 days audit log</li>
    <li>Single Sign On (SSO)</li>
    <li>Custom contracts and SLAs</li>
    <li>Priority 1:1 support</li>
    <li>Manual payment options</li>
    <li>Shared Views</li>
  </ul>
);

export const DowngradeFromProFeatures = (props: {
  freePlaceholdersToPaidSeats?: number;
}) => (
  <ul>
    {props.freePlaceholdersToPaidSeats !== undefined &&
      props.freePlaceholdersToPaidSeats > 0 && (
        <li>
          {props.freePlaceholdersToPaidSeats} of your included Placeholders
        </li>
      )}
    <li>In-built time tracking</li>
    <li>180 days audit log</li>
    <li>Single Sign On (SSO)</li>
    <li>Shared Views</li>
  </ul>
);

type DowngradeModalProps = DefaultModalProps & {
  skipConfirm?: boolean;
  isEnterprisePlan: boolean;
};

export const DowngradeModal = withConfirm(
  ({
    user,
    onSubmit,
    close,
    confirm,
    skipConfirm,
    isEnterprisePlan,
  }: DowngradeModalProps) => {
    const [loading, setLoading] = useState(false);
    const isMonthly = user.plan_type === 'monthly';
    const DowngradeIcon = isEnterprisePlan
      ? DowngradeFromEnterpriseIcon
      : DowngradeToStarterIcon;
    const currentPlan = isEnterprisePlan ? 'Enterprise' : 'Pro';

    function submit() {
      if (!confirm) {
        setLoading(true);
        return onSubmit().then(() => {
          setLoading(false);
          close();
        });
      }

      const onConfirm = () => {
        setLoading(true);

        const formData = new FormData();
        formData.append('Company[time_tracking]', `${AddOnStatus.DISABLED}`);
        if (isMonthly) {
          formData.append('Company[plan_id]', STARTER_MONTHLY.plan_id);
        } else {
          formData.append('Company[plan_id]', STARTER_YEARLY.plan_id);
        }

        onSubmit(formData)
          .then(close)
          .catch(() => {
            confirm({
              title: `Plan Update`,
              message: `Sorry, your payment was declined. Please update your card
                        details or contact your bank for more information.`,
              hideCancel: true,
              onConfirm: close,
            });
          });
      };

      if (skipConfirm) {
        onConfirm();
        return;
      }

      confirm({
        title: 'Confirm your new plan',
        message: `Are you sure? Your new plan will commence immediately. You will
      be charged the new amount in your next billing cycle.`,
        onConfirm,
        onCancel: close,
      });
    }

    const { canDowngrade, freePlaceholdersToPaidSeats, exceedingPlaceholders } =
      calculatePlaceholdersDowngrade({
        currentPeopleCount: user.people_count || 0,
        currentPeopleLimit: user.p_limit || 0,
        currentPlaceholderCount: user.placeholder_people || 0,
        nextPlaceholderLimit: Placeholders.Starter,
      });

    return (
      <Modal isOpen={true} onEnter={submit} onClose={close}>
        <ModalHeader>
          <DowngradeIcon />
          <StyledModalHeader>
            <ModalTitle>
              <Trans>Downgrade to Starter</Trans>
            </ModalTitle>
            <p>
              <Trans>
                Your new plan will start immediately. You will be charged the
                new amount in your next billing cycle.
              </Trans>
            </p>
          </StyledModalHeader>
        </ModalHeader>
        <ModalBody>
          <StyledModalBody>
            <h3>
              <Trans>
                Switching from {currentPlan}? You'll lose access to:
              </Trans>
            </h3>
            {isEnterprisePlan ? (
              <DowngradeFromEnterpriseFeatures
                freePlaceholdersToPaidSeats={freePlaceholdersToPaidSeats}
              />
            ) : (
              <DowngradeFromProFeatures
                freePlaceholdersToPaidSeats={freePlaceholdersToPaidSeats}
              />
            )}
          </StyledModalBody>
          {!canDowngrade && (
            <>
              <br />
              <DowngradeAlert
                exceedingPlaceholders={exceedingPlaceholders}
                downgradingToPlan="Starter"
              />
            </>
          )}
        </ModalBody>
        <ModalActions>
          <Button loader={loading} onClick={submit} disabled={!canDowngrade}>
            Downgrade
          </Button>
          <Button appearance="secondary" onClick={close}>
            Cancel
          </Button>
          <Button
            appearance="clear"
            style={{ marginLeft: 'auto', marginRight: 0 }}
            onClick={onChatClick}
          >
            Message support
          </Button>
        </ModalActions>
      </Modal>
    );
  },
);
