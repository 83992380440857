import { createSelector } from 'reselect';

import { ReduxStateStrict } from '../reducers/lib/types';

export const getTimeoffTypes = createSelector(
  [(state: ReduxStateStrict) => state.timeoffTypes.timeoffTypes],
  (timeoffTypes) => Object.values(timeoffTypes),
);

export const getHaveTimeoffTypesBeenLoaded = (state: ReduxStateStrict) =>
  state.timeoffTypes.loadState === 'LOADED';

export const getTimeoffTypesMap = (state: ReduxStateStrict) =>
  state.timeoffTypes.timeoffTypes;
