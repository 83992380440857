import { Transforms } from 'slate';

import { toLinkFragment } from './fragments';

const wrapLink = (editor, link, target) => {
  const node = toLinkFragment(link);

  setTimeout(() => {
    Transforms.wrapNodes(editor, node, { at: target, split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }, 0);
};

export { wrapLink };
