import API3 from '@float/common/api3';

export const FETCH_APIKEY = 'FETCH_APIKEY';
export const FETCH_APIKEY_SUCCESS = 'FETCH_APIKEY_SUCCESS';
export const FETCH_APIKEY_FAILURE = 'FETCH_APIKEY_FAILURE';

const fetchedApiKey = (json) => ({
  type: FETCH_APIKEY_SUCCESS,
  payload: json,
});

const failedFetchingApiKey = (error) => ({
  type: FETCH_APIKEY_FAILURE,
  error,
});

export const fetchApiKey =
  (refresh = false) =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_APIKEY,
    });
    return API3.getApiKey({ query: { refresh } })
      .then((jsonPair) => {
        dispatch(fetchedApiKey(jsonPair[0]));
      })
      .catch((error) => {
        dispatch(failedFetchingApiKey(error));
        // propagate error further
        throw new Error(`Failed fetching api key: ${error}.`);
      });
  };
