import { ReduxState } from '@float/common/reducers/lib/types';
import { getQueryStringFromFilters } from '@float/common/search/helpers';

import { getCurrentViewId } from './getCurrentViewId';

export const getViewAndFiltersQueryString = (state: ReduxState) => {
  const viewId = getCurrentViewId(state);
  const filtersQS = getQueryStringFromFilters(state.search.filters);

  if (viewId) return `view=${viewId}&${filtersQS}`;

  return filtersQS;
};
