import { CellsWrapper } from '@float/common/serena/ScheduleContext.types';
import { RepeatedDate } from '@float/libs/datesRepeated/getRepeatedDates';
import { DatesManager } from '@float/types/datesManager';
import { Person } from '@float/types/person';
import { Timeoff } from '@float/types/timeoff';

export const calculateRepeatDays = (
  helpers: CellsWrapper['cells']['_helpers'],
  cells: CellsWrapper['cells'],
  hoursPd: number,
  personId: Person['people_id'] | undefined,
  repeats: RepeatedDate[],
  dates: DatesManager,
  ignoreTimeoffId?: Timeoff['timeoff_id'] | null,
): number => {
  let repeatDaysTotal = 0;

  repeats.forEach((repeat) => {
    const repeatDays = helpers.getTotalDaysInRange({
      cells,
      hours_pd: hoursPd,
      people_id: personId,
      start_date: dates.fromNum(repeat.start),
      end_date: dates.fromNum(repeat.end),
      ignoreTimeoff: {
        timeoff_id: ignoreTimeoffId,
      },
    });

    repeatDaysTotal += repeatDays;
  });

  return repeatDaysTotal;
};
