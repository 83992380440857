import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@float/common/store';

import { fetchBillingInfo } from '../../actions/account/billingInfo';
import Body from '../Body';
import RestrictedMessage from '../RestrictedMessage';
import AccountOwner from './AccountOwner';
import DeleteTeam from './DeleteTeam';
import StopSubscription from './StopSubscription';
import TeamNameURL from './TeamNameURL';

const GeneralSettings = ({
  userCanSee,
}: {
  userCanSee: { general: boolean };
}) => {
  const dispatch = useAppDispatch();

  const billingInfo = useAppSelector((state) => state.settingsBillingInfo);

  useEffect(() => {
    dispatch(fetchBillingInfo());
  }, [dispatch]);

  if (!userCanSee.general) {
    return <RestrictedMessage />;
  }

  // Trial accounts cannot be paused
  const pauseSubscriptionEnabled = !billingInfo.is_trial;

  return (
    <Body header="General" subheader="Set your plan, owner, and team name.">
      <AccountOwner />
      <TeamNameURL />

      {billingInfo.isLoading ? null : pauseSubscriptionEnabled ? (
        <StopSubscription />
      ) : (
        <DeleteTeam />
      )}
    </Body>
  );
};

export default GeneralSettings;
