import { onCLS, onFID, onINP, onLCP, onTTFB } from 'web-vitals';

import { sendToGoogleAnalytics } from '../lib/tracking/googleTagManager';
import { trackConnectionQuality } from './connection';
import { trackInteractions } from './interactivity';
import { trackMemoryUsage } from './memory';
import { trackPageLoad } from './pageLoad';
import { RumEventData } from './types';

//From https://github.com/GoogleChrome/web-vitals/blob/91b304aed70f310a9cd9a0d6f6c9f6a4f86b3f5a/src/lib/generateUniqueID.ts
const pageLoadId = `v2-${Date.now()}-${
  Math.floor(Math.random() * (9e12 - 1)) + 1e12
}`;

function sendMetric(metric: RumEventData) {
  sendToGoogleAnalytics('event', metric.name, {
    event_category: 'Web Vitals',
    // The `id` value will be unique to the current page load. When sending
    // multiple values from the same page (e.g. for CLS), with BigQuery we can
    // compute a total by grouping on this ID
    event_label: pageLoadId,
    // Google Analytics metrics must be integers, so the value is rounded.
    // For CLS the value is first multiplied by 1000 for greater precision
    // (note: increase the multiplier for greater precision if needed).
    value: Math.round(
      metric.name === 'CLS' ? metric.value * 1000 : metric.value,
    ),
    // Use a non-interaction event to avoid affecting bounce rate.
    non_interaction: true,

    // OPTIONAL: any additional params or debug info here.
    // See: https://web.dev/debug-web-vitals-in-the-field/
    // metric_rating: 'good' | 'ni' | 'poor',
    // debug_info: '...',
    // ...

    transport_type: 'beacon',
  });
}

onTTFB(sendMetric);
onCLS(sendMetric);
onFID(sendMetric);
onLCP(sendMetric);
onINP(sendMetric);
trackMemoryUsage(sendMetric);
trackPageLoad(sendMetric);
trackInteractions(sendMetric);
trackConnectionQuality(sendMetric);
