import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  AccessPermissionAction,
  AccessPermissionItem,
  useRequestAccessPermissions,
} from '@float/common/api3/accessPermissions';

import { FullPageLoader } from '../FullPageLoader';

export const PermissionGuard = ({
  action,
  children,
  item,
  loaderComponent: LoaderComponent = FullPageLoader,
  shouldRedirectIfNotPermitted,
}: {
  action: AccessPermissionAction;
  children: React.ReactNode;
  item: AccessPermissionItem;
  loaderComponent?: React.ElementType;
  shouldRedirectIfNotPermitted?: boolean;
}) => {
  const {
    data: { hasAccess, path },
    isLoading,
  } = useRequestAccessPermissions({
    item,
    action,
  });

  const history = useHistory();

  useEffect(() => {
    if (shouldRedirectIfNotPermitted && !isLoading && !hasAccess) {
      history.push(path ?? '/');
    }
  }, [hasAccess, history, isLoading, path, shouldRedirectIfNotPermitted]);

  if (isLoading) {
    return <LoaderComponent />;
  }

  if (hasAccess) {
    return <>{children}</>;
  }

  return null;
};
