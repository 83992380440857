import React from 'react';
import cn from 'classnames';

import * as styles from './styles.css';

export type FormFieldsSectionProps = {
  children: React.ReactNode;
};

export function FormFieldsSection(props: FormFieldsSectionProps) {
  return <div className={styles.container}>{props.children}</div>;
}

export type FormFieldPaddingTypes = 'default' | 'margin' | 'emptySides';

export type FormFieldProps = {
  label: string;
  htmlFor?: string;
  children: React.ReactNode;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  className?: string;
  hideLabel?: boolean;
  alignLabel?: 'center' | 'start';
  stopClickPropagation?: boolean;
  compact?: boolean;
  padding?: FormFieldPaddingTypes;
};

export const FormField = React.forwardRef<HTMLDivElement, FormFieldProps>(
  (props, ref) => {
    return (
      <>
        <label
          className={cn(
            styles.label({
              align: props.alignLabel,
            }),
            props.hideLabel && styles.visuallyHidden,
          )}
          htmlFor={props.htmlFor}
        >
          {props.label}
        </label>
        <div
          ref={ref}
          className={cn(
            styles.control({
              padding: props.padding ?? 'default',
            }),
            props.className,
          )}
          onBlur={props.onBlur}
          onClick={(event) => {
            if (props.stopClickPropagation) {
              event.stopPropagation();
            }
          }}
        >
          {props.children}
        </div>
      </>
    );
  },
);

export function FormFieldRow(props: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <div className={cn(styles.colspan2, props.className)}>{props.children}</div>
  );
}
