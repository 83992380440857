import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import {
  selectDatesManager,
  selectHiddenDays,
} from '@float/common/selectors/currentUser';
import { getActiveFilters } from '@float/common/selectors/views';
import { ScheduleRowList } from '@float/types/rows';

import {
  getMilestonesEntries,
  getProjectsScheduleData,
} from './getProjectsData.helpers';
import { buildDayHeader } from './helpers';

export function getProjectsDataByDay(payload: {
  rows: ScheduleRowList;
  state: ReduxStateStrict;
  startDate: string;
  endDate: string;
}) {
  const { state } = payload;

  const dates = selectDatesManager(state);

  const [startCol] = dates.toDescriptor(payload.startDate);
  const [endCol] = dates.toDescriptor(payload.endDate);
  dates.ensureRange(startCol, endCol);

  const filters = getActiveFilters(state);

  const scheduleData = getProjectsScheduleData({
    endDate: payload.endDate,
    rows: payload.rows,
    startDate: payload.startDate,
    state: payload.state,
  });

  const milestones = getMilestonesEntries({
    endDate: payload.endDate,
    startDate: payload.startDate,
    state: payload.state,
    data: scheduleData,
  });

  const { leftHiddenDays, rightHiddenDays } = selectHiddenDays(state);

  const result = {
    header: buildDayHeader(startCol, endCol, dates, {
      endDate: payload.endDate,
      startDate: payload.startDate,
      filters,
      leftHiddenDays,
      rightHiddenDays,
    }),
    scheduleData,
    milestones,
  };

  return result;
}
