import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { t } from '@lingui/macro';

import { BudgetPriority, BudgetType } from '@float/types/project';

import { ToggleField } from '../components/ToggleField';
import { ProjectFormData } from '../types';

export type BudgetPriorityProps = {
  hasBudgetByTaskSupport: boolean;
  onChange?: (value: BudgetPriority) => void;
};

export function BudgetPriorityField(props: BudgetPriorityProps) {
  const { hasBudgetByTaskSupport, onChange } = props;
  const type = useWatch<ProjectFormData, 'project.budget_type'>({
    name: 'project.budget_type',
  });

  const options = useMemo(
    () =>
      hasBudgetByTaskSupport
        ? [
            {
              value: BudgetPriority.Project,
              label: t`Project`,
            },
            {
              value: BudgetPriority.Phase,
              label: t`Phase`,
            },
            {
              value: BudgetPriority.Task,
              label: t`Task`,
            },
          ]
        : [
            {
              value: BudgetPriority.Project,
              label: t`Project`,
            },
            {
              value: BudgetPriority.Phase,
              label: t`Phase`,
            },
          ],
    [hasBudgetByTaskSupport],
  );

  return (
    <ToggleField
      name="project.budget_priority"
      label={type === BudgetType.TotalHours ? t`Hours by` : t`Fee by`}
      options={options}
      onChange={onChange}
    />
  );
}
