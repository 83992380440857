import React from 'react';

export default function () {
  return (
    <svg
      width="11px"
      height="10px"
      viewBox="0 0 11 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(0.000000, -1.000000)" fill="#FFFFFF">
          <g>
            <rect x="0" y="1" width="11" height="7" rx="1" />
            <polygon points="2 7 7 7 2 11" />
          </g>
        </g>
      </g>
    </svg>
  );
}
