import { t } from '@lingui/macro';
import { showTemplateSidePanel } from 'sidePanel/actions';

import { useAppDispatch } from '@float/common/store';
import { IconFilePlus } from '@float/ui/icons/essentials/IconFilePlus';

import { useSavedProjectFormData } from './useProject';

export function useTemplateFromProjectMenuItem() {
  const { projectId } = useSavedProjectFormData();
  const dispatch = useAppDispatch();

  function handleCreateTemplate() {
    if (!projectId) return;

    dispatch(showTemplateSidePanel({ projectId }));
  }

  return {
    value: t`Create template from project`,
    icon: IconFilePlus,
    onClick: handleCreateTemplate,
  };
}
