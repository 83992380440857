import React from 'react';
import styled from 'styled-components';

import { FieldLabel } from '@float/ui/deprecated/Label';
import { SecondaryText } from '@float/ui/deprecated/Text';

import { InputWrapper } from '../Input/styles';
import Checkbox from './Checkbox';

const Wrapper = styled.div`
  margin-bottom: 0;
`;

class CheckboxField extends React.Component {
  render() {
    const { label, option, secondaryText, style, ...rest } = this.props;
    return (
      <Wrapper style={style}>
        <FieldLabel>{label}</FieldLabel>
        <InputWrapper noBorder>
          <Checkbox label={option} {...rest} />
        </InputWrapper>
        {secondaryText && <SecondaryText>{secondaryText}</SecondaryText>}
      </Wrapper>
    );
  }
}

export default CheckboxField;
