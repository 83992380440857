import React from 'react';

import { ReportsCheckboxTooltip } from './CheckboxTooltip';

const peopleItems = [
  'Employees',
  'Active',
  'Contractors',
  'Archived',
  'Placeholders',
];

export const TeamMode = function ({
  updateSettings,
  activeMode,
  activeTeamModes,
  memberViewSelf,
}) {
  if (memberViewSelf) {
    return null;
  }

  return (
    <ReportsCheckboxTooltip
      updateSettings={updateSettings}
      twoColumn
      items={peopleItems}
      placement="bottom-start"
      checked={{
        Active: activeMode == '1' || activeMode == '2',
        Archived: activeMode == '0' || activeMode == '2',
        Employees: activeTeamModes.includes(0),
        Contractors: activeTeamModes.includes(1),
        Placeholders: activeTeamModes.includes(2),
      }}
      outerLabel={'People'}
      innerLabel={'People:'}
    />
  );
};

export function getTeamModeString({ activeMode, activeTeamModes }) {
  if (activeMode == 2 && activeTeamModes.length == 3) {
    return 'All';
  }

  const ret = [];

  if (activeMode == 1 || activeMode == 2) ret.push('Active');
  if (activeMode == 0 || activeMode == 2) ret.push('Archived');
  if (activeTeamModes.includes(0)) ret.push('Employees');
  if (activeTeamModes.includes(1)) ret.push('Contractors');
  if (activeTeamModes.includes(2)) ret.push('Placeholders');

  return ret.join(', ');
}
