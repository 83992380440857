import { useEffect, useRef, useState } from 'react';
import { forEach, isEqual } from 'lodash';

import { CurrentUser, Person, RowId } from '@float/types';

import {
  createGetDailyWorkHours,
  createGetNonWorkDayItems,
  createGetOvertimeItems,
  createLockedDayItems,
  RowMetaItem,
  RowMetas,
} from './useRowMetas.helpers';

type UseRowMetasCtx = {
  mondayStart: boolean;
  leftHiddenDays: number;
  rightHiddenDays: number;
};

export default function useRowMetas(
  ctx: UseRowMetasCtx,
  user: Person | CurrentUser,
  allPeople: Record<string, Person>,
) {
  const { mondayStart, leftHiddenDays, rightHiddenDays } = ctx;
  const [meta, setMeta] = useState(() => new RowMetas());
  const contextRef = useRef({ mondayStart, leftHiddenDays, rightHiddenDays });

  useEffect(() => {
    const { current: ctx } = contextRef;
    let wasChanged = false;

    // If any of these things change, invalidate and rebuild all cells.
    const fullRebuild =
      ctx.mondayStart !== mondayStart ||
      ctx.leftHiddenDays !== leftHiddenDays ||
      ctx.rightHiddenDays !== rightHiddenDays;

    if (fullRebuild) {
      ctx.mondayStart = mondayStart;
      ctx.leftHiddenDays = leftHiddenDays;
      ctx.rightHiddenDays = rightHiddenDays;
    }

    meta.forEach((person, id) => {
      if (id.charAt(0) === '_') return;
      if (!allPeople[person.personId]) {
        wasChanged = true;
        meta.delete(id);
      }
    });

    const company = {
      _serializedParams: {
        person: null,
        mondayStart,
        leftHiddenDays,
        rightHiddenDays,
        user,
      },
    } as RowMetaItem;
    company.getDailyWorkHours = createGetDailyWorkHours(company);

    meta.set('_company', company);

    forEach(allPeople, (person, personId) => {
      const rowId: RowId = `person-${personId}`;
      const value = meta.get(rowId);

      // Identify any person-specific elements that affect the validity of cells
      // and trigger a rebuild if any of those have changed.
      const needsRebuild =
        !value ||
        !isEqual(value.work_days_hours, person.work_days_hours) ||
        !isEqual(
          value.work_days_hours_history,
          person.work_days_hours_history,
        ) ||
        value.start_date !== person.start_date ||
        value.end_date !== person.end_date ||
        value.people_type_id !== person.people_type_id;

      if (fullRebuild || needsRebuild) {
        wasChanged = true;
        const newValue = {
          personId: Number(personId),
          version: value ? value.version + 1 : 0,
          work_days_hours: person.work_days_hours, // Only used for equality check
          work_days_hours_history: person.work_days_hours_history, // Only used for equality check
          start_date: person.start_date,
          end_date: person.end_date,
          people_type_id: person.people_type_id,
          _serializedParams: {
            person,
            mondayStart,
            leftHiddenDays,
            rightHiddenDays,
            user,
          },
        } as RowMetaItem;

        newValue.getDailyWorkHours = createGetDailyWorkHours(newValue);
        newValue.getNonWorkDayItems = createGetNonWorkDayItems(newValue);
        newValue.getOvertimeItems = createGetOvertimeItems(newValue);
        newValue.getLockedDayItems = createLockedDayItems(newValue);

        meta.set(rowId, newValue);
      }
    });

    if (wasChanged) {
      // Although we're mutating the object, we want to setState with a new
      // object reference to trigger a re-render anywhere rowMetas is used.
      setMeta(meta.clone());
    }
  }, [
    meta,
    allPeople,
    user.work_days_hours,
    mondayStart,
    leftHiddenDays,
    rightHiddenDays,
    user,
  ]);

  return meta;
}
