import React from 'react';

export default function ({ size = '60' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      focusable={false}
      viewBox="0 0 60 60"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <circle fill="#EBEBEB" cx="30" cy="30" r="30" />
          <path
            d="M8.46,50.8813255 L8.46,50.8739231 C8.45988173,50.8728502 8.45994083,50.871871 8.46,50.8708918 L8.46,50.85 C9.03176918,41.7916759 23.8348582,42.8428774 25.5853248,38.137 C25.7313248,36.525 25.6763248,35.4 25.6763248,33.927 C24.9443248,33.544 23.5843248,31.101 23.3643248,29.039 C22.7903248,28.992 21.8863248,28.432 21.6203248,26.221 C21.4783248,25.034 22.0463248,24.366 22.3913248,24.156 C20.4503248,16.686 21.5183248,10.163 30.3723248,9.99999997 C32.5853248,9.99999997 34.2883248,10.591 34.9533248,11.759 C41.4153248,12.657 39.4753248,21.347 38.5413248,24.156 C38.8873248,24.366 39.4553248,25.034 39.3123248,26.221 C39.0473248,28.432 38.1423248,28.992 37.5683248,29.039 C37.3473248,31.102 36.0393248,33.544 35.3093248,33.927 C35.3093248,35.4 35.2533248,36.525 35.3993248,38.137 C37.1497913,42.8428774 50.9788643,41.7916759 51.5466416,50.85 L51.55,50.85 L51.55,50.8710071 C46.0966145,56.5006831 38.4569619,60 30,60 C21.5482061,60 13.9127228,56.5049586 8.46,50.8813255 Z"
            fill="#868D92"
          />
        </g>
      </g>
    </svg>
  );
}
