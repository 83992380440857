import React, { ReactNode } from 'react';
import cx from 'classnames';

import { AnyEntity } from '@float/types';
import {
  ColorPickerDropdown,
  Row,
  Spacer,
  withSnackbar,
} from '@float/ui/deprecated';
import { IconWarningTriangle } from '@float/ui/deprecated/Earhart/Icons';
import { CurrencyInput, Input } from '@float/ui/deprecated/Input';

import { ColorCell } from './cell/ColorCell';
import { EditEntryActions } from './EditEntryActions';
import { StyledRow } from './styles';

export type EditEntryRow<Entity> = {
  id: number;
  data: (string | number)[];
  entity: Entity;
  isEdited: boolean;
  isDeletable: boolean;

  editConfig?: any;
};
export type EditEntryProps = {
  row: EditEntryRow<AnyEntity>;
  icon?: ReactNode;
  style?: Record<string, string>;
  className?: string;
  virtualized?: boolean;
  onSubmit: (
    row: EditEntryRow<AnyEntity>,
    editedRow: string[],
  ) => Promise<void>;
  onClickCancel: (id?: number) => Promise<void>;
  showSnackbar: (
    message: string,
    config?: Record<string, string | Function | boolean>,
  ) => void;
  getCellProps: (index: number) => object;

  config: any;
};

type EditEntryState = {
  editedRow: string[];
};
export class EditEntryComponent extends React.Component<
  EditEntryProps,
  EditEntryState
> {
  constructor(props: EditEntryProps) {
    super(props);

    this.state = {
      editedRow: [...props.row.data].map((entry) =>
        typeof entry === 'number' ? entry.toString() : entry,
      ),
    };
  }

  onClickSave = () => {
    const { row, onSubmit, config } = this.props;
    const { editedRow } = this.state;

    const shouldDefaultValidate = !config?.disableDefaultValidation;
    if (shouldDefaultValidate) {
      const canEditMultipleCells = !!row.editConfig;
      const hasChanged = editedRow.some(
        (editedCell, i) => editedCell !== row.data[i],
      );

      if (!hasChanged) {
        this.props.onClickCancel();
        return;
      }

      const isValid = !canEditMultipleCells
        ? editedRow[0].length > 0
        : editedRow.every((editedCell: string, i: number) => {
            const editConfig = row.editConfig && row.editConfig[i];

            const isStringCell = typeof editedCell === 'string';
            if ((!editConfig && isStringCell) || editConfig === 'text') {
              return editedCell.length > 0;
            }

            return true;
          });

      if (!isValid) {
        return;
      }
    }

    onSubmit(row, editedRow)
      .then(() => {
        if (config?.disableDefaultSnackbar) {
          return;
        }

        this.props.showSnackbar?.(`${editedRow[0]} updated.`);
      })
      .catch((errors: Error[]) => {
        if (config?.disableDefaultSnackbar) {
          return;
        }

        if (!Array.isArray(errors)) errors = [errors];

        errors.map((err) => {
          this.props.showSnackbar?.(`Error: ${err.message}`, {
            className: 'error',
            iconLeft: IconWarningTriangle,
            showClose: true,
          });
        });
      });
  };

  onClickCancel = () => {
    const { row, onClickCancel } = this.props;
    onClickCancel(row.id);
  };

  updateCell = (i: number, value: string) => {
    const { editedRow } = this.state;
    const nextEditedRow = [...editedRow];
    nextEditedRow[i] = value;
    this.setState({
      editedRow: nextEditedRow,
    });
  };

  renderEditableCell = (cellIdx: number) => {
    const { config, row } = this.props;

    const cell = row.data[cellIdx];
    const editConfig = row.editConfig && row.editConfig[cellIdx];
    const isEditable = editConfig || cellIdx === 0;
    if (!isEditable) {
      return <span>{cell}</span>;
    }

    const { editedRow } = this.state;
    const value =
      typeof editedRow[cellIdx] === 'undefined' ? cell : editedRow[cellIdx];

    const isTextEdit =
      !editConfig || editConfig === 'text' || editConfig.type === 'text';

    if (isTextEdit) {
      return (
        <Input
          autoFocus={cellIdx === 0}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            this.updateCell(cellIdx, e.target.value);
          }}
          placeholder={editConfig?.placeholder}
          maxLength={editConfig?.maxLength ?? config?.maxLength}
        />
      );
    }

    const isCurrencyEdit =
      !!editConfig &&
      (editConfig === 'currency' || editConfig.type === 'currency');

    if (isCurrencyEdit) {
      return (
        <CurrencyInput
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value.trim();

            this.updateCell(cellIdx, value);
          }}
          placeholder={editConfig?.placeholder}
          currentUser={editConfig.currentUser}
          allowZero={true}
        />
      );
    }

    const isColorEdit = editConfig === 'color' || editConfig.type === 'color';
    if (isColorEdit) {
      const { editedRow } = this.state;
      const color = editedRow[cellIdx] || cell;

      return (
        <ColorPickerDropdown
          disableCustomColors
          value={cell.toString()}
          defaultColors={[
            'E1F0FF',
            'E7E5EB',
            'FFE8D7',
            'FFF8BB',
            'DDF3E4',
            'EEEADD',
            'FCE5F3',
            'D8F3F6',
            'F3E7FC',
            'F4E9DD',
            'E4F7C7',
          ]}
          onChange={(change) => {
            this.updateCell(cellIdx, change);
          }}
        >
          <ColorCell clickable color={`#${color}`} />
        </ColorPickerDropdown>
      );
    }

    return null;
  };

  render() {
    const { row, config, icon, style, className, getCellProps, virtualized } =
      this.props;

    return (
      <StyledRow
        as={virtualized ? 'div' : 'tr'}
        key={row.id}
        style={style}
        className={cx('active', className)}
      >
        {row.data.map((cell, i: number) => {
          const cellProps = getCellProps(i);
          const isLastCell = i === row.data.length - 1;
          return React.createElement(
            virtualized ? 'div' : 'td',
            {
              key: i,
              className: cx('cell', 'editing', { last: isLastCell }),
              ...cellProps,
            },
            <Row alignItems="center">
              {i === 0 && icon && (
                <>
                  <Spacer size={12} />
                  {icon}
                  <Spacer size={8} />
                </>
              )}
              {this.renderEditableCell(i)}
            </Row>,
          );
        })}
        <EditEntryActions
          virtualized={Boolean(virtualized)}
          confirmLabel={config?.confirmLabel}
          onClickSave={this.onClickSave}
          onClickCancel={this.onClickCancel}
        />
      </StyledRow>
    );
  }
}

export const EditEntry = withSnackbar(EditEntryComponent);
