import { ReduxState } from '@float/common/reducers/lib/types';
import { AppDispatch, AppDispatchStrict } from '@float/common/store';
import {
  BudgetPriority,
  ProjectStatus,
  ProjectTemplate,
  RawProjectTemplate,
} from '@float/types';

import { projectTemplates as ProjectTemplatesAPI } from '../../api3/projectTemplates';
import { trackEvent } from '../../lib/analytics';
import { sanitizeFetchedProject } from '../projects.helpers';
import {
  TEMPLATE_LOADED,
  TEMPLATES_DELETED,
  TEMPLATES_LOAD_FAILED,
  TEMPLATES_LOAD_FINISH,
  TEMPLATES_LOAD_START,
  TEMPLATES_UPDATED,
} from './actionTypes';
import { TemplateInputData } from './types';

export const TEMPLATE_LIMITS = {
  total: 50,
  milestones: 200,
  phases: 200,
  tasks: 200,
};

export const mapTemplateToV3 = (newProject: TemplateInputData) => ({
  client_id: newProject.client_id === 0 ? null : newProject.client_id,
  color: newProject.color,
  tags: newProject.tags,
  project_manager: Number(newProject.project_manager),
  name: newProject.project_name,
  notes: newProject.description,
  notes_meta: newProject.notes_meta,
  active: newProject.active ? 1 : 0,
  all_pms_schedule: newProject.common ? 1 : 0,
  non_billable: newProject.non_billable ? 1 : 0,
  status: newProject.status,
  tentative: newProject.status === ProjectStatus.Tentative ? 1 : 0,
  locked_task_list: newProject.locked_task_list ? 1 : 0,
  team_people: newProject.team_people
    ? JSON.stringify(newProject.team_people)
    : null,
  milestones: newProject.milestones
    ? JSON.stringify(newProject.milestones)
    : null,
  phases: newProject.phases
    ? JSON.stringify(
        newProject.phases.map((p) => {
          const { color, ...rest } = p;
          return p.color ? p : rest;
        }),
      )
    : null,
  task_metas: newProject.task_metas
    ? JSON.stringify(newProject.task_metas)
    : null,
  budget_priority: newProject.budget_priority,
  budget_type: newProject.budget_type,
  budget_total:
    newProject.budget_priority === BudgetPriority.Project
      ? newProject.budget_total
      : null,
  default_hourly_rate: newProject.default_hourly_rate,
  duration: newProject.duration,
});

export type ProjectTemplateV3Map = ReturnType<typeof mapTemplateToV3>;

async function fetchTemplate(
  templateId: ProjectTemplate['project_template_id'],
) {
  return ProjectTemplatesAPI.getProjectTemplate({
    id: templateId,
  });
}

export const ensureTemplateLoaded = (
  templateId: ProjectTemplate['project_template_id'],
) => {
  return async (dispatch: AppDispatch, getState: () => ReduxState) => {
    const template = await fetchTemplate(templateId);
    dispatch({ type: TEMPLATE_LOADED, template });

    return getState().projects.templates[templateId];
  };
};

export const ensureTemplatesLoaded = () => {
  return async (dispatch: AppDispatchStrict) => {
    dispatch({ type: TEMPLATES_LOAD_START });
    try {
      const templates = await ProjectTemplatesAPI.getAllTemplates();
      dispatch({ type: TEMPLATES_LOAD_FINISH, templates });
    } catch (e) {
      console.error(e);
      dispatch({ type: TEMPLATES_LOAD_FAILED });
    }
  };
};

export function createTemplate(newTemplate: TemplateInputData) {
  return async (dispatch: AppDispatchStrict) => {
    const data = mapTemplateToV3(newTemplate);
    const response = await ProjectTemplatesAPI.createProjectTemplate({
      data,
      query: {
        expand: 'project_team,project_dates',
      },
    });
    const template = sanitizeFetchedProject(response) as RawProjectTemplate;
    dispatch({ type: TEMPLATES_UPDATED, template });
    trackEvent('Template added');
    return template;
  };
}

export function updateTemplate(
  id: ProjectTemplate['project_template_id'],
  newTemplate: TemplateInputData,
) {
  return async (dispatch: AppDispatchStrict) => {
    const data = mapTemplateToV3(newTemplate);
    const response = await ProjectTemplatesAPI.updateProjectTemplate({
      id,
      data,
      query: {
        expand: 'project_team,project_dates',
      },
    });
    const template = sanitizeFetchedProject(response);
    dispatch({ type: TEMPLATES_UPDATED, template });
    trackEvent('Template updated');
    return template;
  };
}

export function deleteTemplate(id: ProjectTemplate['project_template_id']) {
  return async (dispatch: AppDispatchStrict) => {
    await ProjectTemplatesAPI.deleteProjectTemplate({ id });
    dispatch({ type: TEMPLATES_DELETED, templateId: +id });
  };
}
