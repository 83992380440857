import { connect } from 'react-redux';

import { fetchApiKey } from '../../actions/account/jwt';
import TokenSection from '../../components/Integrations/TokenSection';
import withOnMount from '../../decorators/withOnMount';

const mapStateToProps = (state) => ({
  token: state.settingsJwt.apiKey,
  companyId: state.currentUser.cid,
});

const mapDispatchToProps = (dispatch) => ({
  onComponentDidMount: () => dispatch(fetchApiKey()),
  onClick: () => dispatch(fetchApiKey(true)),
});

const TokenSectionWithOnMount = withOnMount(TokenSection);
const StatefulTokenSection = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TokenSectionWithOnMount);

export default StatefulTokenSection;
