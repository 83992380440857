import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  clearTrelloPreload,
  fetchTrelloCoInts,
  removeTrelloCoInt,
  updateTrelloConfig,
} from 'integrations/actions/trello';
import { get, isEmpty } from 'lodash';

import Setup from '../shared/Setup';

// functions to map between Trello schema to generic schema
const transformTrelloPreload = (preload) => {
  if (!preload) return preload;
  const { boards: projects, members: people, ...others } = preload;
  return { projects, people, ...others };
};

const transformTrelloConfig = (config) => {
  if (!config) return config;
  const {
    boardChoices,
    memberChoices,
    membersExcluded,
    futureBoards,
    futureMembers,
    ...others
  } = config;
  return {
    projectChoices: boardChoices,
    peopleChoices: memberChoices,
    projectsExcluded: membersExcluded,
    futureProjects: futureBoards,
    futurePeople: futureMembers,
    ...others,
  };
};

const transformIntegrationConfig = ({
  projectChoices,
  peopleChoices,
  futureProjects,
  futurePeople,
  projectsExcluded,
  ...others
}) => ({
  boardChoices: projectChoices,
  memberChoices: peopleChoices,
  membersExcluded: projectsExcluded,
  futureBoards: futureProjects,
  futureMembers: futurePeople,
  ...others,
});

function TrelloSetup(props) {
  const { integration, fetchCoInts, ...others } = props;
  const coInt = get(integration, 'coInts[0]');
  useEffect(() => {
    /* eslint-disable no-use-before-define */
    if (
      isEmpty(integration.coInts) &&
      integration.coIntsLoadState !== 'LOADING'
    ) {
      fetchCoInts();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Setup
      type="trello"
      label="Trello"
      preloadData={transformTrelloPreload(integration.preloadData)}
      config={transformTrelloConfig(integration.trelloConfig)}
      coInt={coInt}
      isUpdating={integration.updateConfigLoadState === 'LOADING'}
      calculateExcludedSelections={true}
      labels={{
        project: 'board',
        projects: 'boards',
        person: 'member',
        people: 'members',
        issues: 'cards',
      }}
      {...others}
    />
  );
}

const mapStateToProps = (state) => ({
  integration: state.integrations.trello,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCoInts: () => dispatch(fetchTrelloCoInts()),
  updateConfig: (coInt, config, options) =>
    dispatch(
      updateTrelloConfig(
        coInt,
        config && transformIntegrationConfig(config),
        options,
      ),
    ),
  removeCoInt: (coIntId, archiveData) =>
    dispatch(removeTrelloCoInt(coIntId, archiveData)),
  clearPreload: () => dispatch(clearTrelloPreload()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TrelloSetup);
