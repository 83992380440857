export const getIsAnyQueryPending = (queryStatuses: Array<boolean | void>) =>
  queryStatuses.includes(true);

type OptimisticMutationResolverConfig<V> =
  | {
      isPending: boolean;
      variables: V;
      isPartialUpdate?: false;
    }
  | {
      isPending: boolean;
      variables: Partial<V>;
      isPartialUpdate: true;
    };

/**
 * Determines optimistic data for a query that has one or more possible mutations.
 *
 * If any of the mutations provided are pending the method will return the mutation variables for that
 * mutation or, if `isPartialUpdate` is true, the mutation variables mixed in to the `currentQueryData`.
 *
 * If there are multiple pending queries, only the first one will be used.
 *
 * If there are no pending queries then `currentQueryData` is returned.
 *
 * @see https://tanstack.com/query/v4/docs/framework/react/guides/optimistic-updates
 *
 * @example
 *
 *   const { data } = useQuery({ queryKey: [SomeQueryKey] });
 *
 *   const {
 *     isPending: isPendingUpdate,
 *     variables: updateVariables,
 *    } = useMutation({ mutationKey: [SomeQueryKey], mutationFn: update });
 *
 *   const {
 *     isPending: isPendingDelete,
 *    } = useMutation({ mutationKey: [SomeQueryKey], mutationFn: delete });
 *
 *   const optimisticData = resolveOptimisticDataFromMutationVariables(
 *     data,
 *     [
 *       {
 *         isPending: isPendingUpdate,
 *         variables: updateVariables,
 *         isPartialUpdate: true,
 *       },
 *       { isPending: isPendingDelete, variables: null },
 *     ],
 *   );
 *
 *   return (
 *      <span>
 *        Data: {optimisticData}
 *      </span>
 *   );
 *
 */

export const resolveOptimisticDataFromMutations = <D>(
  currentQueryData: D,
  mutations: OptimisticMutationResolverConfig<D>[],
) => {
  let resolvedData = currentQueryData;

  for (const mutation of mutations) {
    if (mutation.isPending) {
      if (mutation.isPartialUpdate) {
        resolvedData = { ...currentQueryData, ...mutation.variables };
      } else {
        resolvedData = mutation.variables;
      }

      break;
    }
  }

  return resolvedData;
};
