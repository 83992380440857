import styled, { css } from 'styled-components';

import { StyledAvatar } from '@float/common/components/Avatar/styles';
import * as Animation from '@float/ui/deprecated/Earhart/Animation';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

export const StyledAvatarGroup = styled.div`
  display: inline-flex;
`;

export const StyledContainer = styled.div`
  position: relative;
`;

export const StyledHit = styled.div<{ $withTranslateOnHover?: boolean }>`
  position: absolute;

  top: 0;
  left: 0;

  z-index: 0;

  cursor: ${({ onClick }) => (onClick ? 'pointer' : null)};

  ${StyledAvatar} {
    transition-property: transform;
    transition-duration: ${Animation.Settings.Duration.Normal};
    transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

    pointer-events: none;

    &:after {
      --border-size: 1px;

      position: absolute;

      content: '';

      top: calc(var(--border-size) * -1);
      left: calc(var(--border-size) * -1);

      width: calc(100% + (var(--border-size) * 2));
      height: calc(100% + (var(--border-size) * 2));

      border-width: var(--border-size);
      border-style: solid;
      border-color: ${Colors.Primary.Flue.Light[9]};
      border-radius: 50%;

      box-sizing: border-box;

      transform: scale(0.8);

      transition-property: transform;
      transition-duration: ${Animation.Settings.Duration.Normal};
      transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

      z-index: -1;
    }
  }

  ${({ onClick, $withTranslateOnHover }) =>
    onClick &&
    css`
      &:hover {
        z-index: 999;

        ${StyledAvatar} {
          transform: ${$withTranslateOnHover && 'translateY(-20%)'};

          &:after {
            transform: none;
          }
        }
      }
    `}
`;

export const StyledMore = styled.div`
  ${Typography.Label11.M500};

  display: flex;

  align-items: center;
  justify-content: center;

  border-radius: 50%;

  color: ${Colors.Primary.Flue.Light[9]};

  background-color: ${Colors.Primary.Flue.Light[4]};

  z-index: 1;
`;
