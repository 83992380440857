import React from 'react';
import { connect } from 'react-redux';
import { isObject } from 'lodash';
import styled from 'styled-components';

import { trackIntegrationEvent } from '@float/common/lib/analytics';
import { getTimeAgo, popupWindow } from '@float/common/lib/utils';
import { preventDefaultAndStopPropagation } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { Button, Hr, withConfirm } from '@float/ui/deprecated';
import {
  fetchExtCalendars,
  fetchOAuthLinks,
  resyncExtCalendar,
} from '@float/web/integrations/actions/calendar';
import manageModal from '@float/web/modalManager/manageModalActionCreator';
import { getProjectsMap } from '@float/web/selectors';

import IntegrationCalendarEdit from './IntegrationCalendarEdit';
import { IntegrationName } from './Integrations.constants';
import {
  SectionButton,
  SectionHeading,
  SectionWrapper,
} from './Integrations.styles';

import googleCalendarImage from './gcal.png';
import outlookImage from './outlook.svg';

const StatusContainer = styled.div`
  min-height: 40px;
  display: flex;
  align-items: center;
  flex-basis: 100%;
  margin: 5px 0 35px;
`;

const Status = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-grow: 1;
  border: 1px solid ${(p) => p.theme.grey};
  border-radius: 4px;
  font-size: 16px;
  color: #868d92;
  height: 100%;

  padding: 0 16px;

  strong {
    flex-basis: 100%;
  }
`;

const StatusDetail = styled.div`
  flex-basis: 100%;
  ${(p) => p.theme.text.small}
  font-size: 16px;
`;

const StatusError = styled(StatusDetail)`
  color: ${(p) => p.theme.red};
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const GoogleCalendarLogo = styled.img`
  display: block;
  width: 213px;
`;

const OutlookLogo = styled.img`
  display: block;
  width: 131px;
`;

const IntegrationTypes = {
  GCAL: 'google:calendar',
  OUTLOOK: 'office365:calendar',
};

const ErrorMessages = {
  DEFAULT: 'There was a problem establishing a connection. Please try again.',
  RESOURCE_NOT_DISCOVERED:
    "Connection failed. Calendars couldn't be discovered because they may be hosted in on-premises Exchange servers. Please contact your admin.",
  ODATA_ACCESS_DISABLED:
    'Connection failed. Float cannot access your calendar data. Please ask your admin to allow access for Float through the EWS App Access Policy',
};

class IntegrationCalendar extends React.Component {
  state = {
    isEditModalOpen: false,
    oauthPending: false,
    connectionError: null,
  };

  componentDidMount() {
    window.addEventListener('message', this.handleWindowMessage);
    this.props.fetchExtCalendars();
  }

  componentDidUpdate(prevProps) {
    const {
      extCalendars,
      fetchExtCalendarsLoadState,
      oauthLinkLoadState,
      oauthLinks,
    } = this.props.calendar;
    const { extCalendars: prevExtCalendars } = prevProps.calendar;
    const gec = this.getExtCalendar(extCalendars);
    const prevGec = this.getExtCalendar(prevExtCalendars);
    if (
      gec &&
      (!prevGec || gec.inboundResourceId !== prevGec.inboundResourceId)
    ) {
      this.setState({
        integrationType: gec.type,
      });
    }

    // only fetch OAuth link after fetchExtCalendars has finished
    if (fetchExtCalendarsLoadState === 'LOAD_SUCCESS' && !oauthLinkLoadState) {
      // AND
      // no extCalendar comes back
      const noExtCalendars =
        !gec && (prevGec || !Array.isArray(prevExtCalendars));
      // or extCalendar requires Reauth
      const requiresReauth = gec && gec.requiresReauth && !prevGec;
      // or noCalendar is available and no link is available
      const requiresLinks = !gec && Object.keys(oauthLinks).length === 0;

      if (noExtCalendars || requiresReauth || requiresLinks) {
        this.props.fetchOAuthLinks();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleWindowMessage);
    clearInterval(this.oauthPopupClosedCheck);
    this.oauthPopupClosedCheck = null;
    if (this.popup && !this.popup.closed) {
      this.popup.close();
    }
  }

  getExtCalendar = (extCalendars) => (extCalendars || [])[0];

  handleWindowMessage = (event) => {
    // only handle window message for oauth, otherwise exit.
    const { type, floatIntegrationsOAuth, success, error } = event.data || {};
    const extCalendar = this.getExtCalendar(this.props.calendar.extCalendars);
    if (!floatIntegrationsOAuth) {
      return;
    }
    this.setState({ oauthPending: false });
    if (success) {
      this.setState({ connectionError: null });
      if (extCalendar && extCalendar.requiresReauth) {
        const extCalendarId = extCalendar.ext_calendar_id;
        this.props.resyncExtCalendar({ extCalendarId });
      } else {
        this.showEditModal({ extCalendar, type });
      }
    } else {
      if (isObject(error)) {
        this.setState({
          connectionError: {
            type,
            message:
              ErrorMessages[error.code] ||
              error.message ||
              ErrorMessages.DEFAULT,
          },
        });
      }
    }
    if (this.popup) {
      this.popup.close();
    }
  };

  onConnectAccountClick = (e, type, isReconnect = false) => {
    preventDefaultAndStopPropagation(e);

    const oauthLink = this.props.calendar.oauthLinks[type];
    if (oauthLink && (!this.popup || this.popup.closed)) {
      this.popup = popupWindow(
        oauthLink,
        `${IntegrationName[type]}_OAuth`,
        600,
        700,
      );
      trackIntegrationEvent(
        type,
        isReconnect ? 'Integration reconnect started' : 'Integration started',
        this.props.user.cid,
      );

      // if popup is closed by user, set oauthPending to false
      if (!this.oauthPopupClosedCheck) {
        this.oauthPopupClosedCheck = setInterval(() => {
          if (this.popup.closed) {
            this.setState({ oauthPending: false });
            clearInterval(this.oauthPopupClosedCheck);
            this.oauthPopupClosedCheck = null;
          }
        }, 500);
      }

      this.setState({ oauthPending: true, integrationType: type });
    }
  };

  showEditModal = ({ type, extCalendar }) => {
    this.props.manageModal({
      visible: true,
      modalType: 'integCalendarEditModal',
      modalSettings: {
        integrationType: type || extCalendar.type,
        extCalendar,
      },
    });
  };

  closeEditModal = (shouldCloseParent) => {
    this.props.manageModal({
      visible: false,
      modalType: 'integCalendarEditModal',
    });
    if (shouldCloseParent) {
      this.props.closeParentModal();
    }
  };

  getCalendarName = (extResId) => {
    const { calendarList = [] } = this.props.calendar;
    const calendar = calendarList.find((cal) => cal.value === extResId);
    if (calendar) {
      return calendar.label;
    }
    return null;
  };

  getStatusMessage = (extCalendar) => {
    const { synced, status } = extCalendar;
    if (this.popup && !this.popup.closed && status > 3)
      return 'Reconnecting...';
    if (synced && status > 2) return `Last synced: ${getTimeAgo(synced, true)}`;
    return 'Syncing...';
  };

  renderStatus = (extCalendar) => {
    const { status, calendarListLoadState, type, requiresReauth } = extCalendar;

    let statusError = null;
    if ([4, 5].includes(status) && (!this.popup || this.popup.closed)) {
      const calendarErrorTexts = {
        4: 'There was an issue syncing your calendar. Please check your connection.', // SYNC_ERROR
        5: 'There is an issue with your calendar connection. Please reconnect.', // CONN_ERROR_RECONNECT
      };
      statusError = calendarErrorTexts[status] || calendarErrorTexts[4];
    } else if (calendarListLoadState === 'LOAD_FAILED') {
      // unable to retrieve calendar names (auth issue with 3rd party API)
      statusError =
        "Looks like there's an issue with your connection. Please reconnect.";
    }
    const reconnect = requiresReauth || calendarListLoadState === 'LOAD_FAILED';
    const statusDetail = this.getStatusMessage(extCalendar);

    return (
      <StatusContainer>
        <Status>
          {statusError ? (
            <StatusError>{statusError}</StatusError>
          ) : (
            <StatusDetail>{statusDetail}</StatusDetail>
          )}
        </Status>
        <ActionsContainer>
          {extCalendar && reconnect && (
            <Button
              appearance="clear"
              title="Reconnect calendar"
              onClick={(e) => this.onConnectAccountClick(e, type, true)}
              disabled={this.popup && !this.popup.closed}
              style={{
                marginLeft: '10px',
                textUnderlinePosition: 'under',
              }}
            >
              Reconnect
            </Button>
          )}
          {extCalendar && !reconnect && (
            <Button
              appearance="clear"
              title="Edit calendar"
              onClick={() => this.showEditModal({ extCalendar })}
              style={{
                marginLeft: '10px',
              }}
            >
              Edit
            </Button>
          )}
        </ActionsContainer>
      </StatusContainer>
    );
  };

  renderCalendarSection = (
    type,
    { extCalendar, loading, disabled, loader, connectionErrorMsg },
  ) => {
    const Logo = {
      'google:calendar': <GoogleCalendarLogo src={googleCalendarImage} />,
      'office365:calendar': <OutlookLogo src={outlookImage} />,
    };
    return (
      <>
        <SectionWrapper style={extCalendar ? { flexWrap: 'wrap' } : {}}>
          <div>
            {connectionErrorMsg && (
              <StatusError>{connectionErrorMsg}</StatusError>
            )}
          </div>
          {extCalendar ? (
            <>
              <SectionHeading>
                Sync with {IntegrationName[extCalendar.type]}
              </SectionHeading>
              {this.renderStatus(extCalendar)}
            </>
          ) : (
            <SectionWrapper
              style={{
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              {Logo[type]}
              <SectionButton>
                <Button
                  onClick={(e) => this.onConnectAccountClick(e, type)}
                  disabled={disabled}
                  loader={loader}
                >
                  {loading ? 'Loading...' : 'Connect'}
                </Button>
              </SectionButton>
            </SectionWrapper>
          )}
        </SectionWrapper>
      </>
    );
  };

  render() {
    const {
      oauthLinkLoadState,
      fetchExtCalendarsLoadState,
      extCalendars = [],
      calendarListLoadState,
    } = this.props.calendar;
    const { oauthPending, integrationType, connectionError } = this.state;

    const connectButtonLoading = [
      oauthLinkLoadState,
      fetchExtCalendarsLoadState,
      calendarListLoadState,
    ].some((state) => state === 'LOADING');

    const connectButtonDisabled = oauthPending || connectButtonLoading;

    const extCalendar = extCalendars && extCalendars[0];
    if (extCalendar) {
      extCalendar.calendarListLoadState = calendarListLoadState;
    }

    return (
      <div style={{ paddingBottom: '0' }}>
        {!extCalendar ? (
          <>
            <SectionHeading>
              Which calendar do you want to sync with Float?
            </SectionHeading>
            <Hr style={{ margin: '25px 0' }} />
            {this.renderCalendarSection(IntegrationTypes.GCAL, {
              loading: connectButtonLoading,
              disabled: connectButtonDisabled,
              loader:
                connectButtonDisabled &&
                integrationType === IntegrationTypes.GCAL,
              connectionErrorMsg:
                connectionError &&
                connectionError.type === IntegrationTypes.GCAL &&
                connectionError.message,
            })}
            <Hr style={{ margin: '25px 0' }} />
            {this.renderCalendarSection(IntegrationTypes.OUTLOOK, {
              loading: connectButtonLoading,
              disabled: connectButtonDisabled,
              loader:
                connectButtonDisabled &&
                integrationType === IntegrationTypes.OUTLOOK,
              connectionErrorMsg:
                connectionError &&
                connectionError.type === IntegrationTypes.OUTLOOK &&
                connectionError.message,
            })}
            <Hr style={{ margin: '25px 0' }} />
          </>
        ) : (
          <>
            {this.renderCalendarSection(extCalendar.type, {
              extCalendar: extCalendars[0],
            })}
          </>
        )}
        <IntegrationCalendarEdit onClose={this.closeEditModal} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  calendar: state.integrations.calendar,
  user: state.currentUser,
  projectsMap: getProjectsMap(state),
});

const mapDispatchToProps = {
  fetchOAuthLinks,
  fetchExtCalendars,
  resyncExtCalendar,
  manageModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withConfirm(IntegrationCalendar));
