import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M10.5 19.25H5.75V6.75a2 2 0 0 1 2-2h8.5a2 2 0 0 1 2 2V12"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M17 14.75v4.5"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M19.25 17h-4.5"
    />
    <path fill="#000" d="M12.25 19.25h-7.5Z" />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M12.25 19.25h-7.5"
    />
    <path fill="#000" d="M10 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" />
    <path fill="#000" d="M10 15a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" />
    <path fill="#000" d="M14 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" />
    <path fill="#000" d="M14 15a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" />
  </BaseIcon>
);
