import React from 'react';

import { moment } from '@float/libs/moment';
import Table from '@float/web/settingsV2/components/Table';
import { isHolidayInYear } from '@float/web/settingsV2/reducers/holidays';
import {
  ACCOUNT_SETTINGS_HOLIDAYS_TABLE,
  getTableRows,
} from '@float/web/settingsV2/reducers/reducerHelpers';

import { SETTINGS_TABLE_DATE_FORMAT } from '../../settingsV2.constants';

const getHolidayData = (entity, state) => {
  if (!entity) return null;
  const id = entity.holiday_id;
  return {
    isEdited: false,
    isDeletable: false,
    id,
    data: [
      entity.name,
      moment(entity.date).format(SETTINGS_TABLE_DATE_FORMAT),
      moment(entity.end_date).format(SETTINGS_TABLE_DATE_FORMAT),
    ],
  };
};

class HolidaysFilteredByYearTable extends React.Component {
  constructor(props) {
    super(props);
    this.filterByYear = this.filterByYear.bind(this);
  }

  filterByYear(entities) {
    const { year } = this.props;
    return entities.filter((entity) => {
      return isHolidayInYear(entity, year);
    });
  }

  render() {
    const { holidaysState, ...rest } = this.props;
    return (
      <Table
        rows={getTableRows(
          holidaysState,
          getHolidayData,
          'holiday_id',
          ACCOUNT_SETTINGS_HOLIDAYS_TABLE,
          this.filterByYear,
        )}
        {...rest}
      />
    );
  }
}

export default HolidaysFilteredByYearTable;
