import React, { useMemo } from 'react';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { ScaleBand } from 'd3-scale';
import { max } from 'lodash';

import { MARGIN } from '@float/common/components/Schedule/Cell/MainCell/Item/PhaseItem';
import {
  buildProjectRowSortData,
  getSortKey,
} from '@float/common/serena/Data/useCells/helpers/buildProjectRowSortData';
import { moment } from '@float/libs/moment';
import { Phase, Project } from '@float/types';
import { formatTickDate } from '@float/ui/deprecated/Chart/BarChart/BarChart';
import { CHART_TYPES, UNITS } from '@float/ui/deprecated/Chart/constants';

import { PHASE_BAR_HEIGHT, PhaseBar } from './PhaseBar';

import * as styles from './PhaseBar.css';

type PhaseBarsProps = {
  barChartScale: ScaleBand<string> | null;
  dates: DatesManager;
  endDate: string;
  phases: Phase[];
  project: Project;
  startDate: string;
  type: (typeof CHART_TYPES)[keyof typeof CHART_TYPES];
  unit: (typeof UNITS)[keyof typeof UNITS];
  width: number;
};

const MARGINS = {
  [CHART_TYPES.BAR]: 75,
  [CHART_TYPES.LINE]: 109,
};

const containerStyles = (width: number, maxY: number) =>
  assignInlineVars({
    [styles.containerHeight]: `${(maxY + 1) * (PHASE_BAR_HEIGHT + MARGIN)}px`,
    [styles.containerWidth]: `${width}px`,
  });

export default function PhaseBars(props: PhaseBarsProps) {
  const { type, unit, dates, phases, project, barChartScale: xScale } = props;

  const width = props.width - MARGINS[type];

  // ensure dates are in the correct format
  const startDate = moment(props.startDate).format('YYYY-MM-DD');
  const endDate = moment(props.endDate).format('YYYY-MM-DD');
  const numDays = dates.toNum(endDate) - dates.toNum(startDate);
  const dayWidthPx = width / numDays;

  function calculateLineChartDimensions(phase: Phase) {
    let fadeStart = false;
    let fadeEnd = false;

    let x =
      (dates.toNum(phase.start_date) - dates.toNum(startDate)) * dayWidthPx;
    let w =
      (dates.toNum(phase.end_date) - dates.toNum(phase.start_date)) *
      dayWidthPx;

    if (x < 0) {
      w += x;
      x = 0;
      fadeStart = true;
    }

    if (x + w > width) {
      w = width - x;
      fadeEnd = true;
    }

    return { x, w, fadeStart, fadeEnd };
  }

  function calculateBarChartDimensions(phase: Phase) {
    if (!xScale?.domain()?.length) return null;

    let fadeStart = false;
    let fadeEnd = false;

    const isMonthMode = unit === UNITS.MONTH;
    const startTickDate = formatTickDate(phase.start_date, isMonthMode);
    const endTickDate = formatTickDate(phase.end_date, isMonthMode);

    // In day mode, we want to line up the phase bars with the center of each
    // bar chart bar.
    let startAdjustment = 0.5;
    let endAdjustment = 0.5;

    if (isMonthMode) {
      // In month mode, adjust the start to represent how far into the month
      // the start/end dates were
      const startMoment = moment(phase.start_date);
      const endMoment = moment(phase.end_date);
      startAdjustment = startMoment.date() / startMoment.daysInMonth();
      endAdjustment = endMoment.date() / endMoment.daysInMonth();
    }

    let x = (xScale(startTickDate) ?? 0) + xScale.bandwidth() * startAdjustment;
    if (isNaN(x)) {
      x = 0;
      fadeStart = true;
    }

    let w = (xScale(endTickDate) ?? 0) + xScale.bandwidth() * endAdjustment - x;
    if (isNaN(w)) {
      w = width - x;
      fadeEnd = true;
    }

    return { x, w, fadeStart, fadeEnd };
  }

  const { phaseItems, sortData, maxY } = useMemo(() => {
    const items = phases.filter((i) => {
      return i.end_date > startDate && i.start_date < endDate;
    });

    const sortData = buildProjectRowSortData(items);

    return {
      phaseItems: items,
      sortData,
      maxY: max(Object.values(sortData)) || 0,
    };
  }, [phases, startDate, endDate]);

  return (
    <div
      className={styles.container({ chartType: type })}
      style={{ ...containerStyles(width, maxY) }}
    >
      {phaseItems.map((phase) => {
        const dimensions =
          type === CHART_TYPES.BAR
            ? calculateBarChartDimensions(phase)
            : calculateLineChartDimensions(phase);

        if (!dimensions) return null;

        const { x, w, fadeStart, fadeEnd } = dimensions;
        const y = sortData[getSortKey(phase)];

        return (
          <PhaseBar
            key={phase.phase_id}
            phase={phase}
            project={project}
            fadeStart={fadeStart}
            fadeEnd={fadeEnd}
            x={x}
            y={y}
            w={w}
          />
        );
      })}
    </div>
  );
}
