import { SerenaState } from 'selectors';

import { CurrentUser, Person } from '@float/types';

export type Row =
  | { type: 'string'; data: unknown }
  | { type: 'person'; data: Person };

type Predicate = (person: Person, user: CurrentUser) => boolean;

export const findPersonRowMatch =
  (predicate: Predicate) =>
  (rows: Row[], { user, people }: Pick<SerenaState, 'people' | 'user'>) => {
    for (const row of rows) {
      if (row.type === 'person' && predicate(row.data, user)) {
        return people[row.data.people_id];
      }
    }

    return null;
  };
