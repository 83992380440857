import React from 'react';
import { Trans } from '@lingui/macro';
import { SerenaState } from 'selectors';

import { TASK_EDIT_MODES } from '@float/common/components/Schedule/util/ContextMenu';
import { TaskEditModes } from '@float/common/components/Schedule/util/ContextMenu/types';
import { DragItemError } from '@float/common/types/components/DragItem';
import {
  AnyEntity,
  CellItem,
  CellItemTypes,
  CellItemWithEntity,
  RowId,
} from '@float/types';

import { doesItemContainTimers } from './doesItemContainTimers';

export type Selection = {
  type: CellItemTypes;
  entityId: string | number;
  rowId: RowId;
  fullDayTimeoff: boolean;
  entity: AnyEntity;
};

export type IsItemDraggableParams = {
  item: CellItemWithEntity;
  serenaState: SerenaState;
  actionMode: TaskEditModes;
  selectedItems: Record<string, Selection>;
  isItemEditable: (item: CellItem | CellItemWithEntity) => boolean;
};

export const isItemDraggable = (
  params: IsItemDraggableParams,
): DragItemError => {
  const { item, serenaState, actionMode, selectedItems, isItemEditable } =
    params;

  const { entity } = item;

  // is project NOT active
  const projectId = 'project_id' in entity && entity.project_id;

  if (projectId) {
    const project = serenaState.projects[projectId];

    if (project && !project.active) {
      return false;
    }
  }

  // is action mode NOT add/edit
  if (actionMode !== TASK_EDIT_MODES.ADD_EDIT) {
    return false;
  }

  // is multiple selection with repeated cell items
  if (
    Object.keys(selectedItems).length > 0 &&
    'repeat_state' in item.entity &&
    item.entity.repeat_state
  ) {
    return false;
  }

  // is logged time with timer events associated
  if (doesItemContainTimers(item, serenaState.timers)) {
    return {
      isDraggable: false,
      message: (
        <Trans>
          Time logged with a timer cannot be
          <br />
          moved to a different day.
        </Trans>
      ),
    };
  }

  return isItemEditable(item);
};
