const SANITIZE_MAP = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#x27;',
  '/': '&#x2F;',
  '`': '&grave;',
};

export function sanitize(string) {
  if (!string) return string;
  const reg = /[&<>"'/]/gi;
  return string.replace(reg, (match) => SANITIZE_MAP[match]);
}
