import React from 'react';
import { Link } from 'react-router-dom';
import { Plural, Trans } from '@lingui/macro';

import {
  AlertBar,
  AlertBarVariantType,
} from '@float/ui/components/AlertBar/AlertBar';
import { IconWarningTriangle } from '@float/ui/icons/essentials/IconWarningTriangle';

export type AddFormWarningProps = {
  seatsAvailable: number;
};

const getAlertMessage = (seatsAvailable: number) => {
  switch (seatsAvailable) {
    case 0:
      return (
        <span>
          <Trans>
            You've reached the maximum number of active people for your plan.
            The <strong>Account Owner</strong> can upgrade your plan from the{' '}
            <Link to="/admin/billing">Team Settings</Link>.
          </Trans>
        </span>
      );
      break;

    default:
      return (
        <span>
          <Trans>
            Adding more than{' '}
            <strong>
              <Plural
                value={seatsAvailable}
                one={'# person'}
                other={'# people'}
              />
            </strong>{' '}
            will exceed the maximum number of active people for your plan. To
            upgrade your plan, go to{' '}
            <Link to="/admin/billing">Team Settings</Link>.
          </Trans>
        </span>
      );
  }
};
export const AddFormWarning = (props: AddFormWarningProps) => {
  const { seatsAvailable } = props;

  return (
    <AlertBar
      icon={IconWarningTriangle}
      message={getAlertMessage(seatsAvailable)}
      type={AlertBarVariantType.Warning}
      role="alert"
    />
  );
};
