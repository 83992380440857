import { createSelector } from 'reselect';

import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { Account } from '@float/types';

import { getSubdepartments } from '../departments';
import { computeManagersByPeopleId } from '../lib/computeManagersByPeopleId';
import { getAccounts, getPeopleMapRaw, isActiveAndNotSelf } from './helpers';
import { getPeopleByDepartment, getPeopleMap } from './people';

export const getManagerAndAdminAccounts = createSelector(
  [getAccounts],
  (accounts) => {
    return Object.values(accounts).reduce(
      (acc, account) => {
        const isAdminOrHigher = [1, 2, 5].some(
          (x) => x === account.account_type,
        );
        const isManager = account.account_type === 7;
        if (!(isAdminOrHigher || isManager)) {
          return acc;
        }

        const optionIdx = isAdminOrHigher ? 1 : 0;
        acc[optionIdx].options.push(account);
        return acc;
      },
      [
        { name: 'Managers', value: 'Managers', options: [] as Account[] },
        { name: 'Admins', value: 'Admins', options: [] as Account[] },
      ],
    );
  },
);

const getManagerAccounts = createSelector([getAccounts], (accounts) => {
  return Object.values(accounts).filter((account) => {
    const managed = account.management_group;
    return (
      Boolean(managed?.people?.length) || Boolean(managed?.departments?.length)
    );
  });
});

export const getManagedPeopleIdByAccountId = createSelector(
  [getManagerAccounts, getPeopleMap, getPeopleByDepartment, getSubdepartments],
  (managerAccounts, people, peopleByDepartment, subDepartments) => {
    const managedPeopleIdByAccountId: Record<number, number[]> = {};
    const addManagedPeopleId = (accountId: number, personId: number) => {
      managedPeopleIdByAccountId[accountId] =
        managedPeopleIdByAccountId[accountId] || [];
      managedPeopleIdByAccountId[accountId].push(personId);
    };

    managerAccounts.forEach((account) => {
      const accountId = account.account_id;
      const managed = account.management_group;
      const managedPeople = new Set();

      // People managed directly
      managed?.people.forEach((personId) => {
        const canCount = isActiveAndNotSelf(account, people[personId]);
        if (canCount) {
          managedPeople.add(personId);
          addManagedPeopleId(accountId, personId);
        }
      });

      // People managed via their department
      managed?.departments.forEach((deptId) => {
        [deptId, ...(subDepartments[deptId] || [])].forEach((subDepId) => {
          const people = peopleByDepartment[subDepId] || [];
          people.forEach((person) => {
            const personId = person.people_id;
            const alreadyAdded = managedPeople.has(personId);
            if (alreadyAdded) {
              return;
            }
            const canCount = isActiveAndNotSelf(account, person);
            if (canCount) {
              managedPeople.add(personId);
              addManagedPeopleId(accountId, personId);
            }
          });
        });
      });
    });

    return managedPeopleIdByAccountId;
  },
);

export const selectPeopleManagedByAccounts = createSelector(
  [getManagedPeopleIdByAccountId, (_: ReduxStateStrict, ids: number[]) => ids],
  (managedPeopleIdByAccountId, ids) => {
    const result = new Set<number>();

    for (const accountId of ids) {
      const managedPeopleIds = managedPeopleIdByAccountId[accountId] || [];

      for (const personId of managedPeopleIds) {
        result.add(personId);
      }
    }

    return Array.from(result);
  },
);

export const getManagersByPeopleId = createSelector(
  [getManagedPeopleIdByAccountId],
  computeManagersByPeopleId,
);

export const getDirectManagersByPeopleId = createSelector(
  [getManagersByPeopleId, getAccounts],
  (managersByPeopleId, accounts) => {
    const directManagersByPeopleId: Record<number, number[]> = {};

    Object.entries(managersByPeopleId).forEach(([peopleId, managers]) => {
      const pid = parseInt(peopleId);
      directManagersByPeopleId[pid] = [];

      managers.forEach((managerAccountId) => {
        const managed = accounts[managerAccountId].management_group || {
          departments: [],
          people: [],
        };

        const isManagedDirectly = managed.people.includes(pid);
        if (isManagedDirectly) {
          directManagersByPeopleId[pid].push(managerAccountId);
        }
      });

      const isAlreadyDirectManaged = directManagersByPeopleId[pid].length > 0;
      if (isAlreadyDirectManaged) {
        return;
      }

      managers.forEach((managerAccountId) => {
        directManagersByPeopleId[pid].push(managerAccountId);
      });
    });

    return directManagersByPeopleId;
  },
);

export const getDepartmentManagersByPeopleId = createSelector(
  [getManagersByPeopleId, getAccounts, getPeopleMapRaw, getSubdepartments],
  (managersByPeopleId, accounts, people, subDepartments) => {
    const departmentManagerByPeopleId: Record<number, number[]> = {};

    Object.entries(managersByPeopleId).forEach(([peopleId, managers]) => {
      const pid = parseInt(peopleId);
      const person = people[pid];

      // not managed via department since doesn't belong to one.
      if (!person.department_id) {
        return;
      }

      departmentManagerByPeopleId[pid] = [];
      managers.forEach((managerAccountId) => {
        const managed = accounts[managerAccountId].management_group || {
          departments: [],
          people: [],
        };

        const isManagedByDepartment = managed.departments.some((depId) => {
          const isManagedViaDepartment = person.department_id === depId;
          if (isManagedViaDepartment) {
            return true;
          }

          const isManagedViaSubDepartments =
            person.department_id &&
            subDepartments[depId].includes(person.department_id);

          if (isManagedViaSubDepartments) {
            return true;
          }

          return false;
        });

        if (isManagedByDepartment) {
          departmentManagerByPeopleId[pid].push(managerAccountId);
        }
      });
    });

    return departmentManagerByPeopleId;
  },
);

export const getDirectManagedPeopleByAccountId = createSelector(
  [getDirectManagersByPeopleId],
  computeManagersByPeopleId,
);
