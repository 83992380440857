import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useUpdateEffect } from 'react-use';
import gsap from 'gsap';

import { StyledAsideBG } from './styles';

export const AsideBg = forwardRef((props, ref) => {
  const { navigation } = props;

  const elRef = useRef();

  const [visible, setVisible] = useState(null);

  const onAppear = useCallback(({ delay, duration, ease }) => {
    return new Promise((success) => {
      gsap.set(elRef.current, { x: 20, opacity: 0 });

      window.requestIdleCallback(() => {
        gsap.to(elRef.current, {
          x: 0,
          opacity: 1,
          duration,
          ease,
          delay,
          clearProps: 'all',
          onComplete: success,
        });
      });
    });
  }, []);

  // animate out and in if on or out of the success screen accordingly
  useUpdateEffect(() => {
    // visible until the success page
    const visible = navigation.page !== navigation.totalPages - 1;

    setVisible(visible);
  }, [navigation]);

  useUpdateEffect(() => {
    const opacity = visible ? 1 : 0;
    const duration = visible ? 0.85 : 0.5;
    const ease = 'power3.out';

    window.requestIdleCallback(() => {
      gsap.killTweensOf(elRef.current);

      gsap.to(elRef.current, {
        opacity,
        duration,
        ease,
      });
    });
  }, [visible]);

  useEffect(() => {
    // start hidden
    gsap.set(elRef.current, { x: 30, opacity: 0 });
  }, []);

  useImperativeHandle(ref, () => ({
    onAppear,
  }));

  return <StyledAsideBG ref={elRef} />;
});
