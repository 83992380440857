import { Dispatch } from 'redux';

import { DepartmentsAction } from '@float/common/reducers/departments';
import { ReduxState, ReduxStateStrict } from '@float/common/reducers/lib/types';
import { RawDepartment } from '@float/types';

import API3 from '../api3';
import { AppDispatchStrict } from '../store';

export const DEPARTMENT_LOAD_START = 'DEPARTMENT_LOAD_START';
export const DEPARTMENT_LOAD_FAILED = 'DEPARTMENT_LOAD_FAILED';
export const DEPARTMENT_LOAD_FINISH = 'DEPARTMENT_LOAD_FINISH';

export const ADD_DEPARTMENT_SUCCESS = 'ADD_DEPARTMENT_SUCCESS';

export const DELETE_DEPARTMENT_SUCCESS = 'DELETE_DEPARTMENT_SUCCESS';

export const UPDATE_DEPARTMENT_SUCCESS = 'UPDATE_DEPARTMENT_SUCCESS';

export const DEPARTMENTS_BULK_UPDATED = 'DEPARTMENTS_BULK_UPDATED';

type DepartmentsDispatch = Dispatch<DepartmentsAction>;

export const ensureDepartmentsLoaded =
  (forceLoad?: boolean) =>
  async (dispatch: AppDispatchStrict, getState: () => ReduxStateStrict) => {
    const { loadState: currentLoadState } = getState().departments;

    if (!forceLoad && currentLoadState === 'LOADED') return;
    if (currentLoadState === 'LOADING') return; // There's already an in-flight load request

    try {
      dispatch({ type: DEPARTMENT_LOAD_START });
      const departments: RawDepartment[] = await API3.getAllDepartments();

      dispatch({ type: DEPARTMENT_LOAD_FINISH, departments });
      return departments;
    } catch (e) {
      dispatch({ type: DEPARTMENT_LOAD_FAILED });
    }
  };

export const addDepartment =
  (data: { name: string; parent_id?: number }) =>
  async (dispatch: DepartmentsDispatch, getState: () => ReduxState) => {
    const json: RawDepartment = await API3.createDepartment({
      data,
    });

    return dispatch({
      type: ADD_DEPARTMENT_SUCCESS,
      payload: json,
    });
  };

export const deleteDepartment =
  (id: number) =>
  async (dispatch: DepartmentsDispatch, getState: () => ReduxState) => {
    await API3.deleteDepartment({
      id,
    });

    return dispatch({
      type: DELETE_DEPARTMENT_SUCCESS,
      id,
    });
  };

export const updateDepartment =
  (
    id: number,
    data: {
      name: string;
      parent_id?: number;
    },
  ) =>
  async (dispatch: DepartmentsDispatch, getState: () => ReduxState) => {
    const response = await API3.updateDepartment({
      id,
      data,
    });

    return dispatch({
      type: UPDATE_DEPARTMENT_SUCCESS,
      payload: response,
    });
  };
