import { useState } from 'react';
import { t } from '@lingui/macro';

import { Person } from '@float/types/person';
import { Project, ProjectTeamMemberData } from '@float/types/project';
import { usePeopleByDept } from '@float/web/components/panelViews/Project/fields/ProjectTeamMemberField.hooks';

const usePeopleByDepartment = (project?: Project) => {
  const peopleOptions = usePeopleByDept(
    project?.people_ids.map((p) => ({
      people_id: p,
    })) as ProjectTeamMemberData[],
  );

  return peopleOptions;
};

export const useSwitchPersonSelect = (
  project?: Project,
  onClickSwitchPerson?: (newPersonId: Person['people_id']) => void,
) => {
  const peopleOptions = usePeopleByDepartment(project);

  const [error, setError] = useState<string[][] | undefined>(undefined);
  const [newPersonId, setNewPersonId] = useState<Person['people_id'] | null>(
    null,
  );

  const handleOnClickSwitchPerson = () => {
    if (!newPersonId) {
      setError([[t`This field is required.`]]);
      return;
    }

    if (onClickSwitchPerson) onClickSwitchPerson(newPersonId);
  };

  const handlePersonSelect = ({ value }: { value: Person['people_id'] }) => {
    setNewPersonId(value);
    setError(undefined);
  };

  return {
    error,
    handleOnClickSwitchPerson,
    handlePersonSelect,
    newPersonId,
    peopleOptions,
  };
};
