import React, { ComponentPropsWithRef, useEffect, useRef } from 'react';
import gsap from 'gsap';

export const WelcomeIllustration = (props: ComponentPropsWithRef<'svg'>) => {
  const { className } = props;

  const ref = useRef<SVGSVGElement>(null);

  useEffect(() => {
    const windows = Array.from(
      ref.current?.querySelectorAll('.window') || [],
    ).reverse();

    gsap.set(windows, { x: 20, y: -20 });
    gsap.set(windows.slice(1), { opacity: 0 });

    window.requestIdleCallback(() => {
      gsap.to(windows, {
        opacity: 1,
        x: 0,
        y: 0,
        stagger: 0.2,
        duration: 0.85,
        delay: 0.15,
        ease: 'back.out(1.4)',
      });
    });
  }, []);

  return (
    <svg
      ref={ref}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 521 280"
    >
      <g className="window">
        <path
          fill="#FBFBFB"
          stroke="#242C39"
          strokeWidth=".8"
          d="M403.1 46H140.8c-3.6 0-6.6 3-6.6 6.6v154c0 3.6 3 6.5 6.6 6.5h262.3c3.6 0 6.6-3 6.6-6.5v-154c0-3.7-3-6.6-6.6-6.6Z"
        />
      </g>

      <g className="window">
        <path
          fill="#FBFBFB"
          stroke="#242C39"
          strokeWidth=".8"
          d="M393.4 55.7H131.1c-3.6 0-6.5 3-6.5 6.5v154c0 3.7 2.9 6.6 6.5 6.6h262.3c3.7 0 6.6-3 6.6-6.6v-154c0-3.6-3-6.5-6.6-6.5Z"
        />
      </g>

      <g className="window">
        <path
          fill="#fff"
          stroke="#242C39"
          strokeWidth=".8"
          d="M121.4 67.3h262.4a6.6 6.6 0 0 1 6.5 6.5v154a6.6 6.6 0 0 1-6.5 6.6H121.4a6.6 6.6 0 0 1-6.5-6.6v-154a6.6 6.6 0 0 1 6.5-6.5Z"
        />
        <rect
          width="22.1"
          height="153"
          x="121.7"
          y="74.8"
          fill="#2E5FE8"
          rx="2.3"
        />
        <rect
          width="22.1"
          height="236.3"
          x="148.9"
          y="96.9"
          fill="#E9EAEC"
          rx="2.3"
          transform="rotate(-90 148.9 97)"
        />
        <rect
          width="125.8"
          height="49.3"
          x="148.9"
          y="227.8"
          fill="#C3C8D8"
          rx="2.3"
          transform="rotate(-90 148.9 227.8)"
        />
        <g>
          <g>
            <rect
              width="74"
              height="23"
              x="203.7"
              y="104.1"
              fill="#D0E8FF"
              stroke="#242C39"
              strokeWidth=".8"
              rx="2.6"
            />
          </g>
        </g>
        <g>
          <g>
            <rect
              width="74"
              height="23"
              x="220.6"
              y="165.3"
              fill="#DCFFD0"
              stroke="#242C39"
              strokeWidth=".8"
              rx="2.6"
            />
          </g>
        </g>
        <g>
          <g>
            <rect
              width="74"
              height="23"
              x="264.9"
              y="197.6"
              fill="#FFD0D0"
              stroke="#242C39"
              strokeWidth=".8"
              rx="2.6"
            />
          </g>
        </g>
        <g>
          <g>
            <rect
              width="74"
              height="23"
              x="302.2"
              y="165.3"
              fill="#D0E8FF"
              stroke="#242C39"
              strokeWidth=".8"
              rx="2.6"
            />
          </g>
        </g>
        <g>
          <g>
            <rect
              width="119.9"
              height="23"
              x="225.8"
              y="133"
              fill="#F8FFD0"
              stroke="#242C39"
              strokeWidth=".8"
              rx="2.6"
            />
          </g>
        </g>
      </g>

      <g className="bubble">
        <path
          fill="#40C4AA"
          fillRule="evenodd"
          d="M58.1 143.3c26.5 0 48-19 48-42.6S84.7 58 58.2 58s-48 19.1-48 42.7c0 9.6 3.6 18.8 10 26-1.6 5-6.3 10.4-16.7 9 2.5 2.4 12.1 6 30.2 1.6 7.6 4 16 6 24.5 6Z"
          clipRule="evenodd"
        />
        <path
          fill="#242C39"
          d="m20 126.7.6.2.1-.3-.2-.3-.5.4Zm-16.6 9.1v-.6l-1.9-.2 1.4 1.3.5-.5Zm30.2 1.6.3-.6h-.5l.2.6Zm72-36.7c0 23.1-21.2 42-47.5 42v1.3c26.8 0 48.7-19.3 48.7-43.3h-1.3ZM58 58.7c26.3 0 47.4 18.8 47.4 42h1.3c0-24-21.9-43.3-48.7-43.3v1.2Zm-47.4 42c0-23.2 21.1-42 47.4-42v-1.3c-26.8 0-48.7 19.3-48.7 43.3h1.3Zm9.8 25.6c-6.3-7-9.8-16.2-9.8-25.6H9.4c0 9.7 3.7 19.2 10.2 26.4l1-.8ZM3.3 136.4a17 17 0 0 0 12.1-2.3c2.5-1.7 4.3-4.3 5.2-7.2l-1.2-.4c-.8 2.7-2.5 5-4.7 6.6a15.8 15.8 0 0 1-11.3 2.1l-.1 1.2Zm30.1.4c-9 2.2-15.8 2.4-20.7 1.7-4.8-.6-7.8-2-9-3.1l-.8.9a19 19 0 0 0 9.7 3.5c5 .6 12 .4 21.1-1.8l-.3-1.2Zm24.7 6a52 52 0 0 1-24.2-6l-.6 1.1a53 53 0 0 0 24.8 6v-1.2Z"
        />
        <path
          fill="#40C4AA"
          stroke="#242C39"
          strokeWidth=".8"
          d="M63.5 102a3.2 3.2 0 1 0 6.3 0 3.2 3.2 0 0 0-6.3 0Zm-15.9 0a3.2 3.2 0 1 0 6.4 0 3.2 3.2 0 0 0-6.4 0Z"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M74 157.8a41 41 0 1 1 31.3-14.3c1.7 4.8 6.3 9.8 16.4 8.5-2.5 2.3-12 5.9-29.7 1.7a41 41 0 0 1-18 4.1Z"
          clipRule="evenodd"
        />
        <path
          fill="#242C39"
          d="m105.3 143.5-.6.2-.1-.3.2-.3.5.4Zm16.4 8.5v-.6l1.9-.2-1.4 1.3-.5-.5ZM92 153.7l-.2-.6h.4l-.2.6ZM33.6 117A40.4 40.4 0 0 0 74 157.2v1.2A41.6 41.6 0 0 1 32.3 117h1.3ZM74 76.7A40.4 40.4 0 0 0 33.6 117h-1.3A41.6 41.6 0 0 1 74 75.5v1.2Zm40.5 40.3A40.4 40.4 0 0 0 74 76.7v-1.2a41.6 41.6 0 0 1 41.7 41.4h-1.2Zm-9.7 26.1a40 40 0 0 0 9.7-26.1h1.2c0 9.9-3.5 19.4-10 27l-1-.9Zm17 9.5c-5.2.7-9-.3-11.8-2-2.5-1.7-4.3-4-5.3-6.9l1.1-.4a13 13 0 0 0 4.9 6.2c2.5 1.6 6 2.5 11 1.9l.1 1.2Zm-29.6.5c8.8 2 15.5 2.2 20.3 1.6a18 18 0 0 0 8.9-3.2l.8 1a18.6 18.6 0 0 1-9.5 3.4c-5 .6-11.8.5-20.7-1.6l.2-1.2ZM74 157.2c6.2 0 12.3-1.4 17.8-4l.5 1c-5.7 2.8-12 4.3-18.3 4.3v-1.3Z"
        />
        <path
          fill="#147D6F"
          stroke="#242C39"
          strokeWidth=".8"
          d="M61.6 116.7a3.2 3.2 0 1 1-6.3 0 3.2 3.2 0 0 1 6.3 0Zm15.9 0a3.2 3.2 0 1 1-6.4 0 3.2 3.2 0 0 1 6.4 0Zm15.8 0a3.2 3.2 0 1 1-6.4 0 3.2 3.2 0 0 1 6.4 0Z"
        />
        <path
          stroke="#fff"
          strokeDasharray="1.5 3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth=".8"
          d="M1 140c4.2 3.4 17.3 9.2 36.2 5.6"
        />
      </g>

      <g className="avatar">
        <path
          fill="#FEF2A4"
          stroke="#242C39"
          strokeWidth=".6"
          d="m416.4 129.5 14.3 33.5a1.7 1.7 0 0 0 3.1-.1l5.1-17.1.7-1 15.3-10.2a1.7 1.7 0 0 0-.7-3.1l-36-4.2a1.7 1.7 0 0 0-1.8 2.2Z"
        />
        <path
          fill="#FEF2A4"
          stroke="#242C39"
          strokeWidth=".6"
          d="M478.4 218.1a42.2 42.2 0 1 0 0-84.4 42.2 42.2 0 0 0 0 84.4Z"
        />
        <path
          fill="#CEE7FE"
          stroke="#242C39"
          strokeWidth=".8"
          d="M478.4 212.2a36.3 36.3 0 1 0 0-72.6 36.3 36.3 0 0 0 0 72.6Z"
        />
        <path
          fill="#fff"
          stroke="#242C39"
          strokeMiterlimit="10"
          strokeWidth=".8"
          d="M457.6 205.6s14.8-13.6 42-.3a35.8 35.8 0 0 1-42 .3Z"
        />
        <path
          fill="#FCC7AF"
          stroke="#242C39"
          strokeMiterlimit="10"
          strokeWidth=".8"
          d="M479.2 183h-2.3a6.8 6.8 0 0 0-6.8 6.9V205c0 3.8 3 6.8 6.8 6.8h2.3c3.7 0 6.8-3 6.8-6.8v-15c0-3.8-3-6.8-6.8-6.8Z"
        />
        <path
          fill="#FCC7AF"
          stroke="#242C39"
          strokeMiterlimit="10"
          strokeWidth=".8"
          d="M462 170.3a26.1 26.1 0 0 0 5.8 20c6.8 7.5 16.3 6.5 21.3 1a28 28 0 0 0 6.5-25c-2.3-11-16-15.6-24.5-11-6.8 3.7-8.3 9-9 15Z"
        />
        <g>
          <path fill="#FCC7AF" d="M491 174.7s3.6-4 7-1c3.6 3 1.1 9-4.1 8.5" />
          <path
            stroke="#242C39"
            strokeMiterlimit="10"
            strokeWidth=".8"
            d="M491 174.7s3.6-4 7-1c3.6 3 1.1 9-4.1 8.5"
          />
        </g>
        <path
          stroke="#242C39"
          strokeMiterlimit="10"
          strokeWidth=".8"
          d="M495.8 175.8a1.9 1.9 0 0 1-.2 3.1"
        />
        <path
          fill="#B56A19"
          stroke="#242C39"
          strokeMiterlimit="10"
          strokeWidth=".8"
          d="M476.7 155.8s8.3-1.1 11.4 9.6c3 10.2-3.4 13-1 18.5 4.3 10.8-5.2 13.2-4.5 19.3.6 6.2-1 8.9-1 8.9 7.8-.9 15-4 21-9 0 0 1-2-3.7-9.8-3-4.9 3.2-6.1 2.5-17-.4-5.4-3.2-27.2-23.1-25.3 0 0-13.5-3-16.4 11.8-3 14.8-7.6 22.3-4.5 28.4 3 6-7.1 8-7.1 8 5.9 6.8 14 11.4 23 12.7 0 0 2-1.8 0-7.9-2-6-3-7.5-3.2-11.6 0 0-9.5-7.7-7.8-20 1.8-12.4 7-17 14.4-16.6Z"
        />
      </g>
    </svg>
  );
};
