import React, { ReactNode } from 'react';

import { TextButton } from '@float/ui/deprecated/Earhart/Buttons/TextButton';

type ExternalLinkProps = {
  href: string;
  children?: ReactNode;
};

export const ExternalLink: React.FC<ExternalLinkProps> = ({
  href,
  children,
}) => (
  <TextButton as="a" appearance="flue-underline" target="_blank" href={href}>
    {children}
  </TextButton>
);
