import React, { ReactNode } from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import cn from 'classnames';

import {
  TooltipProvider as DeprecatedTooltipProvider,
  TextTooltip,
} from '@float/ui/deprecated/Tooltip/TextTooltip';

import { TooltipArrowIcon } from './TooltipArrowIcon';

import * as styles from './Tooltip.css';
import { TooltipVariants } from './Tooltip.css';

export { TooltipVariants };
// TODO(PI-81): Move TooltipProvider out of TextTooltip
export const TooltipProvider = DeprecatedTooltipProvider;

const DEFAULT_DELAY = 400;
const DEFAULT_DISTANCE = 4;

export type TooltipProps = {
  arrowContent?: ReactNode;
  className?: string;
  content?: ReactNode;
  children: ReactNode;
  placement?: TooltipPrimitive.TooltipContentProps['side'];
  distance?: TooltipPrimitive.TooltipContentProps['sideOffset'];
  collisionBoundary?: TooltipPrimitive.TooltipContentProps['collisionBoundary'];
  delay?: number;
  open?: boolean;
  onOpenChange?: TooltipPrimitive.TooltipProps['onOpenChange'];
  variant?: TooltipVariants;
};

export const TooltipExplainerContent: React.FC<{
  className?: string;
  children: ReactNode;
}> = ({ className, children }) => {
  return (
    <div className={cn(className, styles.explainerContent)}>{children}</div>
  );
};

export const Tooltip = ({
  children,
  delay = DEFAULT_DELAY,
  distance = DEFAULT_DISTANCE,
  variant,
  ...rest
}: TooltipProps) => {
  return (
    <TextTooltip
      {...rest}
      delay={delay}
      distance={distance}
      className={cn([styles.tooltip({ color: variant })])}
      arrowContent={
        <>
          <TooltipPrimitive.Arrow height={10} width={13} asChild>
            <>
              <TooltipArrowIcon className={styles.arrowOutline} />
              <TooltipArrowIcon className={styles.arrowBackground} />
            </>
          </TooltipPrimitive.Arrow>
        </>
      }
    >
      {children}
    </TextTooltip>
  );
};
