import * as React from 'react';
import cn from 'classnames';

import * as styles from './styles.css';

export function ShimmerCircle() {
  return <div className={styles.shimmerCircle}></div>;
}

export function ShimmerLine() {
  return <div className={styles.shimmerLine}></div>;
}

export function ShimmerContainer(props: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <div className={cn(styles.shimmerContainer, props.className)}>
      {props.children}
    </div>
  );
}
