import React, {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import gsap from 'gsap';
import Flip from 'gsap/Flip';

import { usePrevious } from '@float/common/lib/hooks/usePrevious';
import { useAppSelector } from '@float/common/store';
import * as Icons from '@float/ui/deprecated/Earhart/Icons';
import { getOnboardingSurveyConfig } from '@float/web/store/onboardingManager/selectors/survey';

import { NavigationStep } from './NavigationStep';
import {
  StyledHeader,
  StyledHeaderContainer,
  StyledList,
  StyledNavigationSeparator,
} from './styles';

const Steps = (props) => {
  const { navigation } = props;

  const onboardingSurveyConfig = useAppSelector(getOnboardingSurveyConfig);

  return Object.keys(onboardingSurveyConfig)
    .filter((step) => {
      return !onboardingSurveyConfig[step].excludeFromNavigation;
    })
    .map((step, index) => (
      <Fragment key={step}>
        {index > 0 && (
          <StyledNavigationSeparator>
            <Icons.IconChevronRightLarge />
          </StyledNavigationSeparator>
        )}

        <NavigationStep
          index={index + 1}
          step={step}
          active={index === navigation.page}
          completed={index < navigation.page}
        />
      </Fragment>
    ));
};

export const BreadcrumbNavigation = forwardRef((props, ref) => {
  const { className, navigation } = props;

  const elRef = useRef();
  const containerRef = useRef();

  const previousPage = usePrevious(navigation.page);

  const onAppear = useCallback(({ delay, duration, ease }) => {
    return new Promise((success) => {
      gsap.set(elRef.current, { x: -20, alpha: 0 });

      window.requestIdleCallback(() => {
        gsap.to(elRef.current, {
          x: 0,
          alpha: 1,
          duration,
          ease,
          delay,
          clearProps: 'all',
          onComplete: success,
        });
      });
    });
  }, []);

  useEffect(() => {
    let anim;

    // reaching last page (success)
    if (navigation.page === navigation.totalPages - 1) {
      anim = 'to-center';
    }

    // leaving success page (backwards)
    if (
      previousPage === navigation.totalPages - 1 &&
      navigation.page === navigation.totalPages - 2
    ) {
      anim = 'from-center';
    }

    if (anim) {
      window.requestIdleCallback(() => {
        const state = Flip.getState(containerRef.current);

        if (anim === 'to-center') elRef.current.classList.add('centered');
        if (anim === 'from-center') elRef.current.classList.remove('centered');

        Flip.from(state, { duration: 1, ease: 'power3.inOut' });
      });
    }
  }, [navigation, previousPage]);

  useImperativeHandle(ref, () => ({
    onAppear,
  }));

  return (
    <StyledHeader ref={elRef} className={className}>
      <StyledHeaderContainer ref={containerRef}>
        <StyledList>
          <Steps navigation={navigation} />
        </StyledList>
      </StyledHeaderContainer>
    </StyledHeader>
  );
});
