import { css } from 'styled-components';

export const LegacyStyle = css`
  a {
    color: #1a90dc;
    text-decoration: none;
    cursor: pointer;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 18px;
  }

  strong,
  fieldset label,
  form label {
    font-weight: bold;
  }

  input[type='email'],
  input[type='text'],
  input[type='number'],
  input[type='password'],
  input[type='search'],
  .input-text {
    outline: none;
    transition-property: background-color box-shadow border-color;
    transition-duration: 150ms;
    transition-timing-function: ease;
  }

  input.hidden {
    opacity: 0;
    width: 1px;
    height: 1px;
    position: absolute;
    z-index: -1;
  }

  hr {
    border-width: 1px 0 0 0;
    border-color: #f3f7fa;
    border-style: solid;
  }

  .app {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    margin-top: var(--app-margin-top, 0px);

    &.scrollable-wrap {
      overflow-y: scroll;
    }

    &-inner {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;

      &.static {
        position: static;
      }

      &.scrollable {
        overflow-y: scroll;
      }
    }
  }

  .app-inner {
    &.people,
    &.projects {
      background-color: #f7f7f7;
    }
  }

  button {
    background: transparent;
    border: 0;
    cursor: pointer;
  }

  button:focus {
    outline: 0;
  }

  .popover {
    position: absolute;
    background: #ffffff;
    border: 1px solid #d0d8df;
    padding: 10px;
    z-index: 1000002;
  }

  .popover li {
    cursor: pointer;
  }

  .search-autocomplete {
    position: absolute;
    left: 0;
    top: 0px;
    width: 315px;
    padding: 0;
  }

  .product-updates-item {
    display: flex;
    align-items: center;
    padding-top: unset !important;
    padding-bottom: unset !important;
  }

  .product-updates-a {
    margin-top: unset !important;
    min-height: 30px !important;
  }

  #HW_badge_cont {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    width: auto !important;
    height: auto !important;
  }

  #HW_badge {
    position: relative !important;
    height: 20px;
    width: 20px;
    top: unset !important;
    left: unset !important;
    line-height: 20px;
  }

  #HW_frame_cont {
    z-index: 10000 !important;
    margin-top: 13px !important;
    margin-left: 16px !important;
  }

  .product-updates-item-mobile {
    #HW_badge_cont.HW_visible {
      display: inline-block !important;
      vertical-align: middle;
      margin-left: -6px;
    }
  }
  .top-notify {
    position: fixed;
    top: 0;
    z-index: 50000;
    white-space: nowrap;
  }
  .top-notify > div {
    position: relative !important;
  }
  .top-notify.center {
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  #notification {
    white-space: normal;
  }
  #notification.has-icon {
    padding-left: 40px;
  }
  #notification.has-close {
    padding-right: 40px;
  }
  #notification strong {
    font-weight: 500;
  }
  #notification .fa.icon {
    font-size: 1rem;
    position: absolute;
    top: 10px;
  }
  #notification .fa.icon.left {
    left: 20px;
  }
  #notification .fa.icon.right {
    right: 20px;
    cursor: pointer;
  }
  #notification .refresh {
    color: #14d9bf;
    cursor: pointer;
  }
  #notification {
    position: fixed;
    top: 0px;
    z-index: 1000002;
    font-size: 0.85rem;
    color: #ffffff;
    padding: 10px 20px;
    background: rgba(0, 0, 0, 0.75);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    transform: translateY(-100%);
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  #notification.in {
    -webkit-animation: slideDown 150ms ease-out forwards;
    -moz-animation: slideDown 150ms ease-out forwards;
    animation: slideDown 150ms ease-out forwards;
  }
  #notification.out {
    -webkit-animation: slideUp 100ms ease-in forwards;
    -moz-animation: slideUp 100ms ease-in forwards;
    animation: slideUp 100ms ease-in forwards;
  }
  #notification[class*='ss-']:before {
    position: relative;
    top: 2px;
    margin-right: 5px;
  }
  #notification.ss-ban:before {
    color: #e94f33;
  }

  @keyframes slideUp {
    0% {
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-100%);
      -moz-transform: translateY(-100%);
      transform: translateY(-100%);
    }
  }

  @keyframes slideDown {
    0% {
      -webkit-transform: translateY(-100%);
      -moz-transform: translateY(-100%);
      transform: translateY(-100%);
    }
    100% {
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      transform: translateY(0);
    }
  }

  @keyframes popIn {
    0% {
      -webkit-transform: scale(0.95, 0.95);
      -moz-transform: scale(0.95, 0.95);
      transform: scale(0.95, 0.95);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      transform: scale(1, 1);
      opacity: 1;
    }
  }

  @keyframes popOut {
    0% {
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      transform: scale(1, 1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(0.8, 0.8);
      -moz-transform: scale(0.8, 0.8);
      transform: scale(0.8, 0.8);
      opacity: 0;
    }
  }

  .popup-outer {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(26, 144, 220, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    transition: background 400 ease;
  }

  .popup {
    position: absolute;
    padding: 20px 15px;
    box-shadow:
      0 1px 6px rgba(0, 0, 0, 0.2),
      0 0 1px rgba(0, 0, 0, 0.4);
    border: none;
    border-radius: 2px;
    background: white;
    box-sizing: border-box;
  }

  .popup-top {
    z-index: 1000010 !important;
  }

  .popup-outer.small {
    display: block;
    background: transparent;
    font-size: 12px;
    line-height: 18px;
    margin: 0;
  }

  .popup-outer.small .popup {
    position: absolute;
    padding: 20px 15px;
    box-shadow:
      0 1px 6px rgba(0, 0, 0, 0.2),
      0 0 1px rgba(0, 0, 0, 0.4);
    border: none;
    border-radius: 2px;
  }
  .popup.dialog {
    margin-left: 0;
    margin-top: 0;
  }
  .popup-base.in,
  .in.popup,
  .in.person-popup {
    animation: popIn 200ms ease forwards;
  }

  .popup-outer.small .popup p {
    text-align: center;
  }
  .popup-confirm.text-big p {
    font-size: 16px;
  }
  .popup-confirm p {
    word-break: break-word;
  }
  .popup-outer.small .popup footer {
    margin: 30px 0 0 !important;
    display: flex;
    justify-content: center;
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 40px 0 0;
  }
  .popup-outer.small.bottomArrow .popup:before {
    border-color: rgba(0, 0, 0, 0.18) transparent transparent transparent;
    bottom: auto;
    margin-top: 0;
    top: 100%;
  }
  .popup-outer.small .popup:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(208, 216, 223, 0);
    border-bottom-color: #d0d8df;
    border-width: 11px;
    margin-left: -11px;
  }
  .popup-outer.small.bottomArrow .popup:after {
    border-color: #fff transparent transparent transparent;
    bottom: auto;
    margin-top: 0;
    top: 100%;
  }
  .popup-outer.small .popup:after {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(208, 216, 223, 0);
    border-bottom-color: #fff;
    border-width: 10px;
    margin-left: -10px;
    margin-top: 1px;
  }

  #serena-portal {
    position: fixed;
    z-index: 1001;
  }
`;
