import { useEffect, useState } from 'react';

import { FETCH_COMPANY_PREFS_SUCCESS } from '@float/common/actions/companyPrefs';
import {
  FETCH_COMPANIES_SUCCESS,
  FETCH_USER_SUCCESS,
} from '@float/common/actions/currentUser';
import { useRequestSharePageData } from '@float/common/api3/pageData/pageData.hooks';
import { DO_NOT_USE_setGlobalPageData } from '@float/libs/unsafeGlobalData';
import { PageData } from '@float/types/pageData';
import { identifyUserToLogger } from '@float/web/lib/logger/initializeWebLogger';
import { useWebAppStoreStrict } from '@float/web/lib/store';
import { pushValueToGTM } from '@float/web/lib/tracking/googleTagManager';
import { useIdentifyUserToTrackingServices } from '@float/web/lib/tracking/tracking.hooks';
import { parsePrompts } from '@float/web/OnboardingManager/helpers/parsePrompts';
import { UPDATE_PROMPTS_SUCCESS } from '@float/web/store/onboardingManager/actions';

export const useBootstrapGlobalServicesForApp = (
  data: PageData,
): {
  isReady: boolean;
} => {
  const [isReady, setIsReady] = useState(false);
  useState(false);

  const store = useWebAppStoreStrict();

  useIdentifyUserToTrackingServices(
    data?.user?.user,
    data?.user?.company_prefs,
  );

  useEffect(() => {
    if (data?.user) {
      identifyUserToLogger(data.user);

      DO_NOT_USE_setGlobalPageData({
        currency: data.user.company_prefs.currency,
      });

      pushValueToGTM({
        people_count: data.user.company_prefs?.people_count,
        company_id: data.user.user?.cid,
      });

      store.dispatch({
        type: FETCH_USER_SUCCESS,
        user: data.user.user,
      });

      store.dispatch({
        type: FETCH_COMPANY_PREFS_SUCCESS,
        companyPrefs: data.user.company_prefs,
      });

      const prompts = parsePrompts(
        data.user.prompts,
        data.user.company_prefs.prompts,
      );

      store.dispatch({ type: UPDATE_PROMPTS_SUCCESS, prompts });

      store.dispatch({
        type: FETCH_COMPANIES_SUCCESS,
        companies: data.company.companies,
      });

      setIsReady(true);
    }
  }, [data, store]);

  return {
    isReady,
  };
};

export const useBootstrapGlobalServicesForShareApp = (): {
  isReady: boolean;
} => {
  const store = useWebAppStoreStrict();

  const { data, error } = useRequestSharePageData();

  useEffect(() => {
    if (error && 'redirectUrl' in error) {
      window.location.replace(error.redirectUrl);

      return;
    }
  });

  useEffect(() => {
    if (data) {
      DO_NOT_USE_setGlobalPageData({
        currency: data.company_prefs.currency,
      });

      store.dispatch({
        type: FETCH_USER_SUCCESS,
        user: data.user,
      });

      store.dispatch({
        type: FETCH_COMPANY_PREFS_SUCCESS,
        companyPrefs: data.company_prefs,
      });
    }
  }, [data, store]);

  return {
    isReady: Boolean(data),
  };
};
