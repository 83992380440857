import styled from 'styled-components';

import * as Animation from '@float/ui/deprecated/Earhart/Animation';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Elevations from '@float/ui/deprecated/Earhart/Elevations';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';
import TooltipNotification from '@float/ui/deprecated/TooltipNotification';

const offset = 10;

const offsetPerPosition = {
  top: `translateY(${offset * -1}%)`,
  right: `translateX(${offset}%)`,
  bottom: `translateY(${offset}%)`,
  left: `translateX(${offset * -1}%)`,
};

export const StyledTooltipNotification = styled(TooltipNotification)`
  position: absolute;

  pointer-events: auto;

  z-index: 10005;

  /**
   * Animation
   */

  &.enter,
  &.appear {
    opacity: 0;

    transform: ${({ position }) => offsetPerPosition[position]};
  }

  &.enter-active,
  &.appear-active {
    opacity: 1;
    transform: none;

    transition-delay: 0.25s;

    transition-property: opacity, transform;
    transition-duration: 0.25s, 0.35s;
    transition-timing-function: ${Animation.Settings.Easing.DefaultOut},
      ${Animation.Settings.Easing.OutBounce};
  }

  &.exit {
    opacity: 1;
    transform: none;
  }

  &.exit-active {
    opacity: 0;

    transform: ${({ position }) => offsetPerPosition[position]};

    transition-property: opacity, transform;
    transition-duration: 0.25s, 0.35s;
    transition-timing-function: ${Animation.Settings.Easing.DefaultOut};
  }
`;

export const StyledImage = styled.div`
  ${Elevations.E200};

  display: flex;

  aspect-ratio: 300 / 182;

  width: 100%;

  border-radius: 8px;

  margin-top: 24px;

  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const StyledTitle = styled.h1`
  ${Typography.DisplayXXS.SB600};

  color: ${Colors.FIN.Lt.Emphasis.Primary};
`;

export const StyledParagraph = styled.p`
  ${Typography.TextM.R400};

  color: ${Colors.FIN.Lt.Emphasis.High};
`;

export const StyledList = styled.ul`
  ${Typography.TextM.R400};

  display: flex;
  flex-direction: column;

  color: ${Colors.FIN.Lt.Emphasis.High};
`;

export const StyledListItem = styled.li`
  list-style-type: disc;
  list-style-position: outside;

  margin-left: 20px;

  line-height: 1.9;

  text-indent: 2px;
`;

export const StyledHighlightedWord = styled.span`
  ${Typography.TextM.SB600};

  color: ${Colors.FIN.Lt.Emphasis.Primary};
`;

export const StyledContent = styled.div`
  padding: 16px 24px 18px;

  ${StyledImage} + * {
    margin-top: 16px;
  }

  * + ${StyledParagraph}, * + ${StyledList} {
    margin-top: 8px;
  }
`;
