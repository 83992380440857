import React from 'react';

import {
  Notification,
  NotificationTypePayload,
  NotificationTypes,
} from '@float/types/notification';
import { TimeoffStatuses } from '@float/types/timeoffStatus';

import * as styles from './NotificationItemMention.css';

const getTaskNotes = (data: Notification<NotificationTypes>['after_data']) => {
  return (data as NotificationTypePayload['task'])?.task_notes;
};

const getTimeoffNotes = (
  data: Notification<NotificationTypes>['after_data'],
) => {
  return data?.status === TimeoffStatuses.CONFIRMED ||
    data?.status === TimeoffStatuses.DECLINED
    ? (data as NotificationTypePayload['timeoff'])?.status_note
    : (data as NotificationTypePayload['timeoff'])?.timeoff_notes;
};

const getProjectDesc = (
  data: Notification<NotificationTypes>['after_data'],
) => {
  // @ts-expect-error legacy code
  return (data as NotificationTypePayload['project'])?.project_desc;
};

const getPhaseNotes = (data: Notification<NotificationTypes>['after_data']) => {
  return (data as NotificationTypePayload['phase'])?.notes;
};

// Map item types to their respective note retrieval functions
const itemTypeNotesFieldMap = {
  task: getTaskNotes,
  timeoff: getTimeoffNotes,
  project: getProjectDesc,
  phase: getPhaseNotes,
};

interface NotificationItemMentionProps {
  type: NotificationTypes;
  entity: Notification<NotificationTypes>['after_data'];
}

export const NotificationItemMention = (
  props: NotificationItemMentionProps,
) => {
  const { type, entity } = props;

  // Retrieve the appropriate notes based on the item type
  const itemTypeNotes = itemTypeNotesFieldMap[type];
  const text = itemTypeNotes ? itemTypeNotes(entity) : undefined;

  // Retrieve metadata and determine if it should be ignored
  const metadata = entity?.notes_meta;
  const ignoreMetaData =
    type === 'timeoff' && entity?.status === TimeoffStatuses.DECLINED;

  // Determine if there is a mention
  const hasMention = !!text && (metadata || ignoreMetaData);

  // Render the mention if it exists
  return hasMention ? <div className={styles.mention}>{text}</div> : null;
};
