import React, { StrictMode } from 'react';

import { moment } from '@float/libs/moment';
import { IconCalendarPlus } from '@float/ui/deprecated/Earhart/Icons';
import Spinner from '@float/ui/deprecated/Loader/Spinner';
import { IconArrowLeft } from '@float/ui/icons/essentials/IconArrowLeft';

import { DurationField } from '../DurationField';

import * as styles from './styles.css';

export const DateRangeButtonText: React.FC<{
  start?: string | null;
  end?: string | null;
  hideIcon?: boolean;
  duration?: number | null;
  isEndDateLoading?: boolean;
}> = ({ start, end, hideIcon, duration, isEndDateLoading }) => {
  if (!start)
    return (
      <StrictMode>
        <IconCalendarPlus />
        <DurationField duration={duration} asPlaceholder />
      </StrictMode>
    );
  const startDate = moment(start).format('DD MMM YYYY');
  if (!end)
    return (
      <StrictMode>
        {!hideIcon && <IconCalendarPlus />} {startDate}
        {isEndDateLoading && (
          <>
            {' '}
            <IconArrowLeft size={16} className={styles.arrowIcon} />{' '}
            <Spinner width="16px" height="16px" />
          </>
        )}
      </StrictMode>
    );

  const endDate = moment(end).format('DD MMM YYYY');

  return (
    <StrictMode>
      {!hideIcon && <IconCalendarPlus />} {startDate}{' '}
      <IconArrowLeft size={16} className={styles.arrowIcon} /> {endDate}
    </StrictMode>
  );
};
