import { AnyEntity, LoggedTime } from '@float/types';

const animated = new Set<LoggedTime['logged_time_id']>();

export function requestEntranceAnimation(entity: LoggedTime) {
  animated.add(entity.logged_time_id);
}

export function getEntranceAnimation(entity: AnyEntity) {
  if (!('logged_time_id' in entity)) {
    return false;
  }

  const shouldAnimate = animated.has(entity.logged_time_id);

  if (shouldAnimate) {
    animated.delete(entity.logged_time_id);
  }

  return shouldAnimate;
}
