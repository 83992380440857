import { connect } from 'react-redux';

import { getVisibleSettings } from '@float/common/selectors/currentUser';

const mapStateToProps = (state) => {
  return {
    userCanSee: getVisibleSettings(state),
  };
};

export const withUserCanSee = (Component) =>
  connect(mapStateToProps)(Component);

export default withUserCanSee;
