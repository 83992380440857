import { LastAllocatedTaskAction } from '@float/common/actions/lastAllocatedTask';
import { formatTask } from '@float/common/lib/formatters';

import * as lastAllocatedTaskActions from '../../actions/lastAllocatedTask';
import * as taskActions from '../../actions/tasks';
import { TaskAction } from '../tasks';
import { LastAllocatedTaskState } from './types';

export const DEFAULT_STATE: LastAllocatedTaskState = {
  history: [],
};

const MAX_HISTORY_SIZE = 10000;

export const lastAllocatedTaskReducer = (
  state = DEFAULT_STATE,
  action: TaskAction | LastAllocatedTaskAction,
) => {
  switch (action.type) {
    case lastAllocatedTaskActions.LOAD_HISTORY: {
      return {
        history: action.payload,
      };
    }
    case taskActions.CREATE_TASK_SUCCESS:
    case taskActions.UPDATE_TASK_SUCCESS: {
      if ('isLiveUpdate' in action && action.isLiveUpdate) {
        return state;
      }

      const task = formatTask(action.item);

      const history = [...state.history];

      for (const personId of task.people_ids) {
        const entry = {
          projectId: task.project_id,
          phaseId: task.phase_id || 0,
          taskMetaId: task.task_meta_id,
          personId,
        };

        const index = history.findIndex(
          ({ projectId, phaseId, personId }) =>
            entry.projectId === projectId &&
            entry.phaseId === phaseId &&
            entry.personId === personId,
        );

        // If found, remove the entry from the current position
        // to move it on top of the history
        if (index > -1) {
          history.splice(index, 1);
        }

        history.unshift(entry);
      }

      return { history: history.slice(0, MAX_HISTORY_SIZE) };
    }

    default:
      return state;
  }
};
