import React, { CSSProperties, ReactNode } from 'react';

import * as styled from './styles';

type EmptyCellProps = {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  style?: CSSProperties;
};

const EmptyCell: React.FC<EmptyCellProps> = ({
  children,
  className,
  style,
  disabled,
}) => {
  return (
    <styled.EmptyCell
      className={className || ''}
      style={style}
      disabled={disabled}
    >
      {children || <span>&mdash;</span>}
    </styled.EmptyCell>
  );
};

export default EmptyCell;
