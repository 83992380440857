import { isArchived } from '@float/common/components/Schedule/Cell/MainCell/Item/box/utils/isArchived';
import { SerenaState } from '@float/common/selectors/serena';
import { AnyEntity } from '@float/types';

export const isItemEntityArchived = (
  entity: AnyEntity,
  serenaState: Pick<SerenaState, 'projects' | 'phases'>,
) => {
  const { projects, phases } = serenaState;

  const phaseId = 'phase_id' in entity && entity.phase_id;
  const projectId = 'project_id' in entity && entity.project_id;

  const phase = phaseId ? phases[phaseId] : undefined;
  const project = projectId ? projects[projectId] : undefined;

  return isArchived(project, phase);
};
