import React from 'react';
import classNames from 'classnames';

import {
  MeatballMenu,
  MeatballMenuProps,
} from '@float/ui/components/MeatballMenu';

import * as styles from './styles.css';

export function SidePanelMeatballMenu(props: MeatballMenuProps) {
  return (
    <MeatballMenu
      vertical
      {...props}
      className={classNames(props.className, styles.button)}
    />
  );
}
