import { isWhitespace } from './is-whitespace';

/**
 * Find the word beginning
 * @param {number} index - current search position
 * @param {string} text
 * @return {number} word beginning position
 */

const findWordBeginning = (index, text) => {
  if (index > 0 && !isWhitespace(text[index - 1])) {
    return findWordBeginning(index - 1, text);
  }
  return index;
};

export { findWordBeginning };
