import styled, { css } from 'styled-components';

import { activityItemHistoryLineWithComponents } from '@float/common/activity';
import { Theme } from '@float/ui/deprecated';

const ActivityColorWrapper = styled.div`
  display: inline-block;
`;

const ActivityColor = styled.div`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-bottom: -1px;
  margin-right: 1px;
  background-color: ${({ color }) => color};
`;

const Text = styled.span``;
const Bold = styled.b``;

const LineItem = styled.div`
  position: relative;
  ${(p) =>
    p.withPre &&
    css`
      padding: 0 0 0 12px;
    `}

  .bullet {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Spacer = styled.div`
  height: ${({ fromTask }) => (fromTask ? 0 : 6)}px;
`;

const Wrapper = styled.span``;

const SubItemWrap = styled.div`
  padding: 4px 20px 14px 50px;
  margin: 0 -20px 0 -50px;
  border-bottom: 1px solid ${Theme.grey};
`;

export default activityItemHistoryLineWithComponents({
  ActivityColorWrapper,
  ActivityColor,
  SubItemWrap,
  Text,
  Wrapper,
  Bold,
  LineItem,
  Spacer,
});
