import { t } from '@lingui/macro';

import { Activity } from '@float/types/activity';

export const getActivityType = (itemType: Activity['item_type']) => {
  const TYPES = [
    {
      types: ['project', 'phase', 'milestones'],
      label: t`Project`,
      color: 'pink',
    },

    {
      types: ['people', 'status'],
      label: t`Person`,
      color: 'blue',
    },

    {
      types: ['task', 'task_import', 'co_int_import'],
      label: t`Allocation`,
      color: 'tomato',
    },

    {
      types: ['client'],
      label: t`Client`,
      color: 'teal',
    },

    {
      types: ['timeoff'],
      label: t`Time off`,
      color: 'orange',
    },

    {
      types: ['department'],
      label: t`Department`,
      color: 'yellow',
    },

    {
      types: ['log_time_lock', 'holiday', 'holidays'],
      label: t`Team setting`,
      color: 'green',
    },

    {
      types: ['role'],
      label: t`Role`,
      color: 'brown',
    },

    {
      types: ['view'],
      label: t`View`,
      color: 'violet',
    },
  ];

  const type = TYPES.find((item) => {
    if (item.types.includes(itemType)) {
      return true;
    }

    return false;
  });

  return type;
};
