import React, { ComponentPropsWithRef } from 'react';
import { RecipeVariants } from '@vanilla-extract/recipes';
import cn from 'classnames';

import * as styles from './styles.css';

export type ModalParagraphVariants = NonNullable<
  RecipeVariants<typeof styles.p>
>;
export type ModalParagraphProps = {
  color?: ModalParagraphVariants['color'];
} & ComponentPropsWithRef<'p'>;

export const ModalParagraph = (props: ModalParagraphProps) => {
  const { color, children } = props;

  return <p className={cn(styles.p({ color }))}>{children}</p>;
};
