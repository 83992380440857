import styled from 'styled-components';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';

export const StyledAsideBG = styled.div`
  position: absolute;

  top: 0;
  right: 0;

  width: 100%;
  height: 100%;

  background-color: ${Colors.Primary.Flue.Light[2]};

  z-index: 0;
`;
