import { useMemo } from 'react';
import moment, { Moment } from 'moment';

import { CellsWrapper } from '@float/common/serena/ScheduleContext.types';
import { getRepeatedDates } from '@float/libs/datesRepeated/getRepeatedDates';
import { DatesManager } from '@float/types/datesManager';
import { Person } from '@float/types/person';
import { RepeatState } from '@float/types/repeatState';
import { Timeoff } from '@float/types/timeoff';
import { TimeoffBalanceType } from '@float/types/timeoffType';

export type UseTotalDaysProps = {
  cells: CellsWrapper['cells'];
  dates: DatesManager;
  endDate: Date | DateString;
  helpers: CellsWrapper['cells']['_helpers'];
  hoursPd: number;
  ignoreTimeoffId: Timeoff['timeoff_id'] | null | undefined;
  personId?: Person['people_id'];
  previewDate: Moment | null;
  previewRepeatEndDate: Moment | null;
  repeatEndDate: string | Date | null;
  repeatState: RepeatState;
  startDate: Date | DateString;
  timeoffBalanceType: TimeoffBalanceType;
};

export const useTotalDays = (props: UseTotalDaysProps) => {
  const {
    cells,
    dates,
    endDate,
    helpers,
    hoursPd,
    ignoreTimeoffId,
    personId,
    previewDate,
    previewRepeatEndDate,
    repeatEndDate,
    repeatState,
    startDate,
    timeoffBalanceType,
  } = props;

  return useMemo(() => {
    let totalDays = 0;

    if (
      previewDate &&
      previewRepeatEndDate &&
      timeoffBalanceType !== TimeoffBalanceType.Unlimited &&
      moment(startDate).isSameOrBefore(previewDate)
    ) {
      const start = moment(startDate).format('YYYY-MM-DD');
      const formattedEnd = moment(endDate).format('YYYY-MM-DD');

      const end = previewDate.isBefore(endDate)
        ? previewDate.format('YYYY-MM-DD')
        : formattedEnd;

      totalDays = helpers.getTotalDaysInRange({
        cells,
        hours_pd: hoursPd,
        people_id: personId,
        start_date: start,
        end_date: end,
        ignoreTimeoff: {
          timeoff_id: ignoreTimeoffId,
        },
      });

      if (repeatState && repeatEndDate) {
        const repeatEnd = previewRepeatEndDate.isBefore(repeatEndDate)
          ? previewRepeatEndDate.format('YYYY-MM-DD')
          : moment(repeatEndDate).format('YYYY-MM-DD');

        const repeats = getRepeatedDates(dates, {
          start_date: start,
          end_date: formattedEnd,
          repeat_state: repeatState,
          repeat_end_date: repeatEnd,
        });

        repeats.forEach((repeat) => {
          const repeatDays = helpers.getTotalDaysInRange({
            cells,
            hours_pd: hoursPd,
            people_id: personId,
            start_date: dates.fromNum(repeat.start),
            end_date: dates.fromNum(repeat.end),
            ignoreTimeoff: {
              timeoff_id: ignoreTimeoffId,
            },
          });
          totalDays += repeatDays;
        });
      }
    }
    return totalDays;
    // we want to run this hook specifically only when these props change
    // https://linear.app/float-com/issue/EXP-540/bug-time-off-balance-changes-when-adding-a-new-time-off
  }, [
    helpers.getTotalDaysInRange,
    dates,
    endDate,
    hoursPd,
    ignoreTimeoffId,
    personId,
    previewDate,
    previewRepeatEndDate,
    repeatEndDate,
    repeatState,
    startDate,
    timeoffBalanceType,
  ]);
};
