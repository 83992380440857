import { css } from 'styled-components';

// TODO: better way to handle this in styled-components without compromising ease of use?
// css``?

export const MarginHelpers = css`
  .mb-0 {
    margin-bottom: 0 !important;
  }

  .mt-1 {
    margin-top: 1rem !important;
  }

  .mt-2 {
    margin-top: 2rem !important;
  }

  .mt-3 {
    margin-top: 3.43rem !important;
  }

  .mb-1 {
    margin-bottom: 1rem !important;
  }

  .mb-2 {
    margin-bottom: 2rem !important;
  }

  .mb-3 {
    margin-bottom: 3.43rem !important;
  }

  .pb-0 {
    padding-bottom: 0 !important;
  }
`;
