import React from 'react';

import BaseIcon from './BaseIcon';

export const IconCheck = (props) => (
  <BaseIcon {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m18.25 7-8.594 9.5L5.75 12"
    />
  </BaseIcon>
);

export default IconCheck;
