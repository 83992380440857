import { createSelector } from 'reselect';

import { getProjects } from '../projects';

export const selectPeopleIdsByClientMap = createSelector(
  [getProjects],
  (projects) => {
    const map = new Map<number, Set<number>>();

    for (const project of projects) {
      if (project.client_id) {
        const peopleIds = map.get(project.client_id) ?? new Set();

        for (const personId of project.all_people_ids) {
          peopleIds.add(personId);
        }

        map.set(project.client_id, peopleIds);
      }
    }

    return map;
  },
);
