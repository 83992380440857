import React from 'react';

import bug from '../icons/icon-issue-type-bug';
import epic from '../icons/icon-issue-type-epic';
import story from '../icons/icon-issue-type-story';
import subtask from '../icons/icon-issue-type-subtask';
import task from '../icons/icon-issue-type-task';

const JIRA_ICON = {
  task,
  story,
  epic,
  bug,
  subtask,
  'sub-task': subtask,
};

export default function JiraSidebarItemTypeIcon({ issueType, color }) {
  if (!issueType) {
    return null;
  }

  const type = issueType.toLowerCase();
  const Icon = JIRA_ICON[type] || task;

  return <Icon color={color} />;
}
