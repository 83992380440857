import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import * as Animation from '../../Animation';
import * as Colors from '../../Colors';
import * as Typography from '../../Typography';

const styles = css`
  ${Typography.Label14.M500};

  position: relative;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  color: ${Colors.FIN.Lt.Emphasis.Medium};

  padding: 8px;

  outline: none;

  transition-property: color;
  transition-duration: ${Animation.Settings.Duration.Short};
  transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

  &:after {
    content: '';

    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border-radius: 8px;

    background-color: ${Colors.FIN.Lt.Surface.Surf4};

    transition-property: transform, background-color, opacity;
    transition-duration: ${Animation.Settings.Duration.Normal},
      ${Animation.Settings.Duration.Short}, ${Animation.Settings.Duration.Short};
    transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

    opacity: 0;

    transform: scale(0.9);

    z-index: 0;
  }

  &:hover,
  &:focus {
    color: ${Colors.FIN.Lt.Emphasis.High};

    &:after {
      opacity: 1;
      transform: none;
    }

    svg {
      --svg-icon-color: ${Colors.FIN.Lt.Emphasis.High};
    }
  }

  &:active {
    &:after {
      background-color: ${Colors.FIN.Lt.Surface.Surf5};

      opacity: 1;

      transform: none;
    }
  }

  &[data-active='true'] {
    color: ${Colors.FIN.Lt.Emphasis.High};

    &:after {
      background-color: ${Colors.FIN.Lt.Surface.Surf5};

      opacity: 1;

      transform: none;
    }

    svg {
      --svg-icon-color: ${Colors.FIN.Lt.Emphasis.High};
    }
  }

  > svg,
  > span {
    position: relative;
    transform: translateZ(0);
    z-index: 1;
  }

  > svg {
    --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Medium};

    path {
      transition-property: stroke, fill;
      transition-duration: ${Animation.Settings.Duration.Short};
      transition-timing-function: ${Animation.Settings.Easing.InOutCurve};
    }
  }

  > svg + span {
    margin-left: 16px;

    &:empty {
      display: none;
    }
  }
`;

export const StyledLink = styled(Link)`
  ${styles}
`;
export const StyledButton = styled.button`
  ${styles}
`;
export const StyledAnchor = styled.a`
  ${styles}
`;
