import styled from 'styled-components';

const StyledHeader = styled.header`
  display: flex;

  width: 100%;

  padding: var(--layout-safe-margins);

  box-sizing: border-box;
`;

const StyledHeaderLeft = styled.div`
  display: flex;
  align-items: center;

  flex-grow: 1;

  a {
    display: inline-flex;
  }
`;

const StyledHeaderRight = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`;

export { StyledHeader, StyledHeaderLeft, StyledHeaderRight };
