import React, {
  CSSProperties,
  isValidElement,
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  CellMeasurer,
  CellMeasurerCache,
  List,
  WindowScroller,
  // @ts-expect-error @floatschedule/react-virtualized doesn't have the ts typings
} from '@floatschedule/react-virtualized';
import { get, isNil, sortBy } from 'lodash';

import { usePrintContext } from '@float/common/contexts/PrintContext';
import { config } from '@float/libs/config';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import colors from '@float/ui/deprecated/Theme/colors';

import Icons from '../Icons';
import {
  Cell,
  CellContent,
  Container,
  FooterRow,
  HeaderCell,
  HeaderWrapper,
  NoResults,
  RowDetails,
  SubRowWrapper,
  Summary,
} from './styles';
import {
  AccordionTableCellFormatter,
  AccordionTableCellValue,
  AccordionTableData,
  AccordionTableHeaderConfig,
  AccordionTableRowConfig,
  AccordionTableSortConfig,
  SortDirection,
} from './types';

const createHeightsMeasurerCache = () =>
  new CellMeasurerCache({
    defaultHeight: 36,
    fixedWidth: true,
  });

function isTableCellElement(
  cell: AccordionTableCellValue | React.JSX.Element,
): cell is React.JSX.Element {
  return isValidElement(cell);
}

function getFormatter(
  formatters: Array<AccordionTableCellFormatter | undefined> = [],
  idx: number,
  isSubRow: boolean,
  cell: AccordionTableCellValue | React.JSX.Element,
) {
  const { locale } = config;

  const nf = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  const formatter = formatters[idx];

  if (isTableCellElement(cell)) {
    return cell;
  }

  if (formatter) {
    return formatter(cell, isSubRow);
  }

  if (typeof cell === 'number') {
    return nf.format(cell);
  }

  if (typeof cell === 'object') {
    return get(cell, 'val', null);
  }

  return cell;
}

function getRowOpenKey(row: string | AccordionTableRowConfig) {
  if (typeof row === 'object' && row.id) {
    return row.id;
  }

  return row;
}

type SetSortDirection = (
  sort: SortDirection | ((cur: SortDirection) => SortDirection),
) => void;

type HeaderProps = {
  headers: Array<AccordionTableHeaderConfig>;
  sortCol: number;
  setSortCol: (column: number) => void;
  setSortDir: SetSortDirection;
  setWidths: (widths: Array<number>) => void;
  allOpen: boolean;
  toggleAllOpen: () => void;
  isOpenable: boolean;
  disableAccordion: boolean;
};

function Header({
  headers,
  sortCol,
  setSortCol,
  setSortDir,
  setWidths,
  allOpen,
  toggleAllOpen,
  isOpenable,
  disableAccordion,
}: HeaderProps) {
  const headerWrapperRef = useRef<HTMLDivElement>(null);

  function setSort(header: AccordionTableHeaderConfig, idx: number) {
    return (evt: React.MouseEvent<Element>) => {
      if (
        header.preventSort ||
        (evt.target as Element).classList.contains('prevent-sort-action') ||
        (evt.target as Element).parentElement?.classList.contains(
          'prevent-sort-action',
        )
      ) {
        return;
      }

      if (sortCol === idx) {
        setSortDir((cur) => (cur === 'asc' ? 'desc' : 'asc'));
      } else {
        setSortCol(idx);
        setSortDir('asc');
      }
    };
  }

  useLayoutEffect(() => {
    const headerChildren = headerWrapperRef.current?.children;

    if (!headerChildren) return;

    const widths = [];
    for (const child of Array.from(headerChildren)) {
      widths.push(child.getBoundingClientRect().width);
    }

    setWidths(widths);
  }, [headers, setWidths]);

  return (
    <HeaderWrapper ref={headerWrapperRef}>
      {headers.map((header, idx) => {
        const { align, sortCol, label, width, grow, allowOverflow } = header;
        const activeSort = sortCol === (sortCol || idx);

        return (
          <HeaderCell
            key={idx}
            width={width}
            align={align}
            grow={grow}
            activeSort={activeSort}
            allowOverflow={allowOverflow}
          >
            {idx === 0 && !disableAccordion && (
              <Icons.DownSmall
                color={isOpenable ? colors.blueGrey : colors.lightGrey}
                style={{ transform: `rotate(${allOpen ? '0' : '-90deg'})` }}
                onClick={(e) => {
                  prevent(e);
                  toggleAllOpen();
                }}
              />
            )}
            <div className="label" onClick={setSort(header, sortCol || idx)}>
              {label}
            </div>
          </HeaderCell>
        );
      })}
    </HeaderWrapper>
  );
}

type SubRowProps = {
  row: AccordionTableRowConfig;
  widths: Array<number>;
  formatters?: Array<AccordionTableCellFormatter | undefined>;
  headers: Array<AccordionTableHeaderConfig>;
};

const SubRow = React.memo(
  ({ row, headers, widths, formatters }: SubRowProps) => {
    const { data } = row;
    return (
      <SubRowWrapper>
        {data.map((d, idx) => (
          <Cell
            key={idx}
            align={headers[idx].align}
            width={widths[idx]}
            isPercent={headers[idx].isPercent}
            value={d}
            allowOverflow={headers[idx].allowOverflow}
          >
            <CellContent>{getFormatter(formatters, idx, true, d)}</CellContent>
          </Cell>
        ))}
      </SubRowWrapper>
    );
  },
);

// Using the the entire row data as the key then changing that row data causes
// Incorrect rendering. For editable rows (e.g. timetracking), a string id is now
// included in the row data to use as the key.
type RowOpen = {
  get: (rowKey: string | AccordionTableRowConfig) => unknown;
};

type RowProps = {
  disableAccordion: boolean;
  formatters?: Array<AccordionTableCellFormatter | undefined>;
  headers: Array<AccordionTableHeaderConfig>;
  row: AccordionTableRowConfig;
  rowOpen: RowOpen;
  sortCol: number;
  sortDir: SortDirection;
  toggleOpen: (rowKey: string | AccordionTableRowConfig) => void;
  widths: Array<number>;
};

function Row({
  disableAccordion,
  formatters,
  headers,
  row,
  rowOpen,
  sortCol,
  sortDir,
  toggleOpen,
  widths,
}: RowProps) {
  const { data, children = [], id } = row;

  const isOpen = !!rowOpen.get(getRowOpenKey(row));
  const numChildren =
    children.length +
    children.reduce<number>((acc, childRow) => {
      if (!childRow.children?.length) return acc;
      return (acc += rowOpen.get(getRowOpenKey(childRow))
        ? childRow.children.length
        : 0);
    }, 0);

  const sortedChildren = useMemo(() => {
    const sorted = sortBy(children, [
      // Ensure rows with children rows apear at the top
      (c) => (sortDir === 'desc' ? (c.children ? 1 : 0) : c.children ? 0 : 1),
      // Sort by column
      (c) => {
        const cell = c.data[sortCol];

        // `sortBy` places `null at the end of the list and '' at the beginning
        // by normalizing the empty value we can keep them all at the end
        if (isNil(cell) || cell === '') return null;
        if (typeof cell === 'object') return cell.sortVal;
        if (typeof cell === 'number') return cell;
        return String(cell).toLowerCase();
      },
    ]);

    if (sortDir === 'desc') {
      sorted.reverse();
    }
    return sorted;
  }, [sortCol, sortDir, children]);

  const onSummaryClick = useCallback(() => {
    if (disableAccordion) return;
    if (!children.length) return;

    toggleOpen(id || row);
  }, [children, disableAccordion, toggleOpen, id, row]);

  return (
    <RowDetails numChildren={numChildren} isOpen={isOpen}>
      <Summary onClick={onSummaryClick} numChildren={children.length}>
        {data.map((cell, idx) => {
          const header = headers[idx];
          return (
            <Cell
              key={idx}
              width={widths[idx]}
              align={header.align}
              isPercent={header.isPercent}
              value={cell}
              allowOverflow={header.allowOverflow}
            >
              {idx === 0 && !disableAccordion && (
                <Icons.DownSmall
                  style={{
                    transform: isOpen ? 'rotate(0)' : 'rotate(-90deg)',
                  }}
                  color={
                    children.length > 0 ? colors.blueGrey : colors.lightGrey
                  }
                />
              )}
              <CellContent>
                {getFormatter(formatters, idx, false, cell)}
              </CellContent>
            </Cell>
          );
        })}
      </Summary>

      {isOpen &&
        sortedChildren.map((c, idx) => {
          if (c.children) {
            return (
              <Row
                key={idx}
                rowOpen={rowOpen}
                disableAccordion={disableAccordion}
                formatters={formatters}
                headers={headers}
                sortCol={sortCol}
                sortDir={sortDir}
                toggleOpen={toggleOpen}
                widths={widths}
                row={c}
              />
            );
          }

          return (
            <SubRow
              key={idx}
              row={c}
              headers={headers}
              widths={widths}
              formatters={formatters}
            />
          );
        })}
    </RowDetails>
  );
}

export type AccordionTableProps = {
  data: AccordionTableData;
  disableAccordion?: boolean;
  noResultsMessage: string | boolean;
  sortConfig?: AccordionTableSortConfig | null;
  style?: CSSProperties;
  wrapperRef: MutableRefObject<HTMLElement | null>;
  children?: ReactNode;
};

const AccordionTable = React.forwardRef<
  WindowScroller | { updateHeightsCache: () => void },
  AccordionTableProps
>(
  (
    {
      data,
      style,
      disableAccordion = false,
      noResultsMessage,
      sortConfig,
      wrapperRef,
    }: AccordionTableProps,
    ref,
  ) => {
    const [rowOpen, setRowOpen] = useState(new Map());
    const [sortCol, setSortCol] = useState(() => sortConfig?.get()?.col || 0);
    const [sortDir, setSortDir] = useState(
      () => sortConfig?.get()?.dir || 'asc',
    );
    const [widths, setWidths] = useState<Array<number>>([]);
    const [heightsCache, setHeightsCache] = useState(
      createHeightsMeasurerCache,
    );
    const windowScrollerRef = useRef<WindowScroller>(null);

    const { isPrinting } = usePrintContext();

    const updateHeightsCache = useCallback(() => {
      setHeightsCache(createHeightsMeasurerCache());
    }, []);

    // allow parent component to invoke both custom and WindowScroller's intrinsic methods via single ref.
    useImperativeHandle(ref, () => ({
      updateHeightsCache,
      updatePosition: () => windowScrollerRef.current?.updatePosition(),
    }));

    useEffect(() => {
      if (sortConfig) {
        const { col, dir } = sortConfig.get() || {};
        if (col !== sortCol || dir !== sortDir) {
          sortConfig.set(sortCol, sortDir);
        }
      }
    }, [sortConfig, sortCol, sortDir]);

    const formatters = useMemo(() => {
      if (data.formatters) return data.formatters;
      return data.headers.map((h) => h.formatter);
    }, [data]);

    const sortedData = useMemo(() => {
      const sorted = sortBy(data.rows, (c) => {
        const cellValue = c.data[sortCol];

        // `sortBy` places `null at the end of the list and '' at the beginning
        // by normalizing the empty value we can keep them all at the end
        if (isNil(cellValue) || cellValue === '') return null;

        if (typeof cellValue === 'object') return cellValue.sortVal;

        if (typeof cellValue === 'number') return cellValue;

        return String(cellValue).toLowerCase();
      });

      if (sortDir === 'desc') {
        sorted.reverse();
      }

      return sorted;
    }, [sortCol, sortDir, data.rows]);

    const allOpen = useMemo(() => {
      return (
        Boolean(sortedData?.length) &&
        sortedData
          .filter((r) => r.children?.length)
          .every((r) => !!rowOpen.get(getRowOpenKey(r)))
      );
    }, [sortedData, rowOpen]);

    const isOpenable = useMemo(() => {
      return sortedData.some((d) => d.children && d.children.length > 0);
    }, [sortedData]);

    const toggleOpen = useCallback(
      function toggleOpen(rowKey: string | AccordionTableRowConfig) {
        setRowOpen((prev) => {
          const newRowOpen = new Map(prev);
          const newRowKey = getRowOpenKey(rowKey);

          newRowOpen.set(newRowKey, !newRowOpen.get(newRowKey));
          return newRowOpen;
        });
        setTimeout(updateHeightsCache, 200);
      },
      [updateHeightsCache],
    );

    const rowRenderer = ({
      index,
      key,
      style,
      parent,
    }: {
      index: number;
      key: number | string;
      style: CSSProperties;
      parent: ReactNode;
    }) => {
      const r = sortedData[index];
      return (
        <CellMeasurer
          key={key}
          cache={heightsCache}
          columnIndex={0}
          parent={parent}
          rowIndex={index}
        >
          <div style={style}>
            <Row
              row={r}
              headers={data.headers}
              widths={widths}
              formatters={formatters}
              rowOpen={rowOpen}
              toggleOpen={toggleOpen}
              sortCol={sortCol}
              sortDir={sortDir}
              disableAccordion={disableAccordion}
            />
          </div>
        </CellMeasurer>
      );
    };

    function toggleAllOpen() {
      setRowOpen(() => {
        const result = new Map();

        if (!allOpen) {
          sortedData.forEach((r) => {
            result.set(r.id || r, true);
          });
        }

        return result;
      });
      setTimeout(updateHeightsCache, 200);
    }

    useEffect(() => {
      updateHeightsCache();
    }, [updateHeightsCache, sortCol, sortDir]);

    if (!sortedData.length && noResultsMessage) {
      return <NoResults>{noResultsMessage}</NoResults>;
    }

    const header = (
      <Header
        headers={data.headers}
        sortCol={sortCol}
        setSortCol={setSortCol}
        setSortDir={setSortDir}
        setWidths={setWidths}
        allOpen={allOpen}
        toggleAllOpen={toggleAllOpen}
        isOpenable={isOpenable}
        disableAccordion={disableAccordion}
      />
    );

    const footer = data.footer && (
      <FooterRow>
        {data.footer.map((h, idx) => (
          <HeaderCell
            key={idx}
            width={widths[idx]}
            allowOverflow={data.headers[idx].allowOverflow}
          >
            <CellContent>
              {getFormatter(formatters, idx, false, h.label)}
            </CellContent>
          </HeaderCell>
        ))}
      </FooterRow>
    );

    // Disable list virtualization in print mode
    if (isPrinting) {
      return (
        <Container style={style} data-testid="print-mode-container">
          {header}
          <div>
            {sortedData.map((r, index) => (
              <Row
                key={index}
                row={r}
                headers={data.headers}
                widths={widths}
                formatters={formatters}
                rowOpen={rowOpen}
                toggleOpen={toggleOpen}
                sortCol={sortCol}
                sortDir={sortDir}
                disableAccordion={disableAccordion}
              />
            ))}
          </div>

          {footer}
        </Container>
      );
    }

    return (
      <WindowScroller
        ref={windowScrollerRef}
        scrollElement={wrapperRef.current}
      >
        {({
          height,
          isScrolling,
          onChildScroll,
          scrollTop,
        }: {
          height: number;
          isScrolling: boolean;
          onChildScroll: () => void;
          scrollTop: number;
        }) => (
          <Container style={style}>
            {header}
            <div>
              <List
                key={sortCol + sortDir}
                autoHeight
                height={height || 0}
                width={1280}
                isScrolling={isScrolling}
                deferredMeasurementCache={heightsCache}
                rowCount={sortedData.length}
                rowHeight={heightsCache.rowHeight}
                rowRenderer={rowRenderer}
                scrollTop={scrollTop}
                overscanRowCount={2}
                onScroll={onChildScroll}
              />
            </div>
            {footer}
          </Container>
        )}
      </WindowScroller>
    );
  },
);

export default React.memo(AccordionTable);
