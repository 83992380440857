import { FocusEventHandler, KeyboardEventHandler } from 'react';
import { FieldPath, FieldValues, useFormContext } from 'react-hook-form';

import { useCombinedRefs } from '@float/libs/hooks/useCombinedRefs';
import { noop } from '@float/libs/utils/noop';
import { parseNumber } from '@float/ui/helpers/number/numberParser';

import { getInteractionHandlers } from '../helpers/getInteractionHandlers';
import { DEFAULT_MAX_LENGTH } from '../QuickInput.constants';
import { getStandardValidationRules } from '../QuickInput.helpers';
import { ValidationRules } from '../QuickInput.types';
import { useSetInputValue } from './useSetInputValue';
import { useValidationTooltip } from './useValidationTooltip';

export const useQuickInputController = <F extends FieldValues>({
  disabled,
  defaultValue,
  inputElementRef,
  name,
  maxLength = DEFAULT_MAX_LENGTH,
  minLength,
  onBlur,
  onChange,
  onKeyDown,
  onSetValue = noop,
  prefix,
  required,
  rules,
  suffix,
  readOnly,
  inputMode,
}: {
  disabled: boolean | undefined;
  defaultValue: string | undefined;
  inputElementRef: { current: HTMLInputElement | null };
  maxLength: number | undefined;
  minLength: number | undefined;
  name: FieldPath<F>;
  onBlur: FocusEventHandler;
  onChange: FocusEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler;
  onSetValue?: (value: string) => void;
  prefix?: string;
  required: boolean;
  rules: ValidationRules<F> | undefined;
  suffix?: string;
  readOnly?: boolean;
  inputMode?: React.HTMLAttributes<HTMLInputElement>['inputMode'];
}) => {
  const { setInputValue } = useSetInputValue(name, onSetValue);

  const { handleKeyDown, handleBlur, handleWrapperClick } =
    getInteractionHandlers({
      defaultValue,
      inputElementRef,
      onBlur,
      onKeyDown,
      required,
      setInputValue,
    });

  const { register, getFieldState } = useFormContext();
  const validationRules = getStandardValidationRules({
    disabled,
    required,
    minLength,
    maxLength,
    additionalRules: rules,
  });

  const { open, variant } = useValidationTooltip({
    hasErrors: Boolean(getFieldState(name).error),
    inputElementRef,
  });

  const tooltipProps = {
    open,
    name,
    variant,
  };

  const {
    ref: formCallBackRef,
    // We don't pass maxLength to the input because we're permissive with input size,
    // i.e. we show a validation message instead of truncating the input
    maxLength: _,
    ...registerProps
  } = register(name, {
    onBlur: handleBlur,
    onChange,
    ...validationRules,
    setValueAs: inputMode === 'numeric' ? parseNumber : undefined,
  });
  const combinedInputElementRef = useCombinedRefs([
    formCallBackRef,
    inputElementRef,
  ]);

  const inputProps = {
    ...registerProps,
    defaultValue,
    onKeyDown: handleKeyDown,
    ref: combinedInputElementRef,
    readOnly,
    inputMode,
  };

  const wrapperProps = {
    disabled,
    onWrapperClick: handleWrapperClick,
    prefix,
    suffix,
    readOnly,
  };

  return {
    inputProps,
    tooltipProps,
    wrapperProps,
  };
};
