import React, { forwardRef } from 'react';

import { IconCheck } from '../../../Icons';
import Item from '../Item';
import { StyledList } from './styles';

const List = forwardRef((props, ref) => {
  const { options = [], value, onChange, ...other } = props;

  // Render

  return (
    <StyledList ref={ref} {...other}>
      {options.map((option) => (
        <Item
          key={option}
          icon={IconCheck}
          isActive={value === option}
          onClick={onChange}
          value={option}
        />
      ))}
    </StyledList>
  );
});

export default List;
