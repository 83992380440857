import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  getActivePmIntegration,
  getDidPmSidebarLoadingFail,
  getIsPmSidebarFiltered,
  getIsPmSidebarLoading,
} from 'selectors';

import { fetchSidebarData, groupToggle } from '../actions';
import { integrationTypes } from '../integrationTypes';
import PmSidebarHeader from './PmSidebarHeader';
import PmSidebarItems from './PmSidebarItems';
import PmSidebarSearch from './PmSidebarSearch';
import * as styled from './styles';

function PmSidebar({
  title,
  type,
  item,
  items,
  loading,
  loadItems,
  searchTerm,
  sortOptions,
  filterOptions,
  error,
  onGroupClick,
  onClose,
}) {
  useEffect(() => {
    loadItems();
  }, [loadItems]);
  return (
    <styled.Wrapper>
      <PmSidebarHeader
        title={title}
        sortOptions={sortOptions}
        filterOptions={filterOptions}
        onClose={onClose}
        type={type}
      />
      <PmSidebarSearch />
      <PmSidebarItems
        title={title}
        items={items}
        loading={loading}
        searchTerm={searchTerm}
        error={error}
        component={item}
        onGroupClick={onGroupClick}
      />
    </styled.Wrapper>
  );
}

const mapStateToProps = (state) => {
  const { key, value } = state.pmSidebar.filter;
  const searchTerm = key ? value : false;
  const { type } = getActivePmIntegration(state);
  const { title, itemsTitle, sortOptions, filterOptions } =
    integrationTypes[type] || {};

  return {
    type,
    searchTerm,
    sortOptions,
    filterOptions,
    title: `${title} ${itemsTitle}`,
    loading: getIsPmSidebarLoading(state),
    error: getDidPmSidebarLoadingFail(state),
    items: getIsPmSidebarFiltered(state)
      ? state.pmSidebar.filteredItems
      : state.pmSidebar.items,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadItems: () => dispatch(fetchSidebarData()),
  onGroupClick: (projectId) => dispatch(groupToggle(projectId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PmSidebar);
