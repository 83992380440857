import React, { StrictMode } from 'react';
import { t } from '@lingui/macro';
import { motion } from 'framer-motion';

import { formatDateRangeWithYearIfNotCurrent } from '@float/common/lib/utils';

import { getIsCompactDensity } from '../../../Window/projectRow.helpers';
import { Arrow } from './Arrow';

import * as styles from './styles.css';

export type ProjectRowStickyDatesProps = {
  firstVisibleDate: string;
  lastVisibleDate: string;
  firstVisibleDateWithMargin: string;
  lastVisibleDateWithMargin: string;
  startDate?: string;
  endDate?: string;
  hourHeight: number;
  scrollToDate: (date: string) => void;
};

type StickyDateProps = {
  children?: React.ReactNode;
  density: 'compact' | 'default';
  onClick?: () => void;
};

export function StickyDateLeft(props: StickyDateProps) {
  return (
    <div className={styles.stickyLeft}>
      <div className={styles.stickyInner({ density: props.density })}>
        <Arrow label={t`Go to project start`} onClick={props.onClick} />
        <motion.span
          className={styles.stickyText}
          initial={{ translate: '-10px' }}
          animate={{ translate: 0 }}
        >
          {props.children}
        </motion.span>
      </div>
    </div>
  );
}

export function StickyDateRight(props: StickyDateProps) {
  return (
    <div className={styles.stickyRight}>
      <div className={styles.stickyInner({ density: props.density })}>
        <motion.span
          className={styles.stickyText}
          initial={{ translate: '10px' }}
          animate={{ translate: 0 }}
        >
          {props.children}
        </motion.span>
        <Arrow
          direction="right"
          label={t`Go to project end`}
          onClick={props.onClick}
        />
      </div>
    </div>
  );
}

export function ProjectRowStickyDates(props: ProjectRowStickyDatesProps) {
  const {
    firstVisibleDate,
    lastVisibleDate,
    firstVisibleDateWithMargin,
    lastVisibleDateWithMargin,
    startDate,
    endDate,
    scrollToDate,
  } = props;
  let StickyLeft: React.ReactNode = null;
  let StickyRight: React.ReactNode = null;
  const density = getIsCompactDensity(props.hourHeight) ? 'compact' : 'default';

  if (!startDate || !endDate) return null;

  const handleStartDateClick = () => {
    const targetDate = endDate < firstVisibleDate ? endDate : startDate;
    scrollToDate(targetDate);
  };

  const handleEndDateClick = () => {
    const targetDate = startDate > lastVisibleDate ? startDate : endDate;
    scrollToDate(targetDate);
  };

  if (startDate <= firstVisibleDate) {
    StickyLeft = (
      <StickyDateLeft density={density} onClick={handleStartDateClick}>
        {formatDateRangeWithYearIfNotCurrent(
          startDate,
          endDate <= firstVisibleDateWithMargin ? endDate : undefined,
        )}
      </StickyDateLeft>
    );
  }

  if (endDate >= lastVisibleDate) {
    StickyRight = (
      <StickyDateRight density={density} onClick={handleEndDateClick}>
        {formatDateRangeWithYearIfNotCurrent(
          startDate >= lastVisibleDateWithMargin ? startDate : undefined,
          endDate,
        )}
      </StickyDateRight>
    );
  }

  return (
    <StrictMode>
      {StickyLeft}
      {StickyRight}
    </StrictMode>
  );
}
