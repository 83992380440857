import { Range } from 'slate';

import { findMentionBeginning } from './find-mention-beginning';

/**
 * Find the underlying mention under selection
 * @param {string} text
 * @param {Range} selection
 */

const getMentionUnderCaret = (trigger, text, selection) => {
  const start = Range.isForward(selection) ? selection.anchor : selection.focus;

  let wordBeginningOffset = findMentionBeginning(trigger, start.offset, text);

  const range = {
    anchor: { ...start, offset: wordBeginningOffset - 1 },
    focus: start,
  };

  wordBeginningOffset = Math.min(wordBeginningOffset, range.focus.offset);

  return [
    text.slice(wordBeginningOffset, start.offset),
    wordBeginningOffset,
    range,
  ];
};

export { getMentionUnderCaret };
