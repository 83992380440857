import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { TaskAction } from '@float/common/reducers/tasks';
import { AppDispatchStrict } from '@float/common/store';
import { moment } from '@float/libs/moment';
import { RawTask, SearchTask, Task } from '@float/types/task';

import { formatTaskId } from '../../lib/formatters';
import request from '../../lib/request';
import { handleTaskBillableStatusChange } from './handleTaskBillableStatusChange';
import { formatTaskForRequest } from './helpers/formatTaskForRequest';

export const UPDATE_TASK = 'UPDATE_TASK';
export const UPDATE_TASK_BATCH = 'UPDATE_TASK_BATCH';
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const UPDATE_TASK_FAILURE = 'UPDATE_TASK_FAILURE';

export const cleanUpRepeatState = (item: Task) => {
  if (!item.repeat_state) {
    item.repeat_end_date = null;
    item.repeat_state = 0;
    return item;
  }
  const repeatEnd = moment(item.repeat_end_date);
  const endDate = moment(item.end_date);
  if (repeatEnd.isSameOrBefore(endDate, 'day')) {
    item.repeat_state = 0;
    item.repeat_end = null;
  }
  return item;
};

export const startUpdateTask = (task: RawTask): TaskAction => ({
  type: UPDATE_TASK,
  item: task,
});

export const updateTaskSuccess = (
  task: RawTask,
  previous: Task,
): TaskAction => ({
  type: UPDATE_TASK_SUCCESS,
  item: task,
  previous,
});

export const updateTaskFailure = (
  error: unknown,
  task: Task,
  previous: Task,
): TaskAction => ({
  type: UPDATE_TASK_FAILURE,
  error,
  item: task,
  previous,
});

export const updateTask =
  (
    task: Task,
    {
      originalEntity,
      taskWasntUpdated = false,
      isSplitTask = false,
    }: {
      originalEntity?: Task | SearchTask;
      taskWasntUpdated?: boolean;
      isSplitTask?: boolean;
    } = {},
  ) =>
  (dispatch: AppDispatchStrict, getState: () => ReduxStateStrict) => {
    const state = getState();
    const previous =
      originalEntity || state.tasks.tasks[parseInt(task.task_id, 10)];

    const id = formatTaskId(task);
    task = cleanUpRepeatState(task);
    task = handleTaskBillableStatusChange(
      previous,
      task,
      state.projects.projects,
      state.phases.phases,
    );

    dispatch(startUpdateTask(task as unknown as RawTask));

    const formattedTask = formatTaskForRequest(task as unknown as RawTask);
    const isLinkedTask = Boolean(formattedTask.root_task_id);

    let reqPromise: Promise<RawTask> = Promise.resolve(
      task as unknown as RawTask,
    );

    // cannot edit start or end dates of linked tasks via PUT request to /tasks
    if (isLinkedTask && !taskWasntUpdated) {
      reqPromise = request
        .put(`linked-tasks/${id}`, [formattedTask], {
          version: 'f3',
        })
        .then(([task]) => task);
    } else if (!taskWasntUpdated) {
      reqPromise = request.put(`tasks/${id}`, formattedTask, { version: 'f3' });
    }

    return reqPromise.then(
      (response) => {
        if (isSplitTask) {
          // early return after updating split tasks to avoid
          // adding standard reverse action onto the undo/redo stack
          return;
        }
        dispatch(updateTaskSuccess(response, previous as Task));
      },
      (error) => {
        dispatch(updateTaskFailure(error, task, previous as Task));
        return Promise.reject(error);
      },
    );
  };
