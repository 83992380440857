import React from 'react';
import { t } from '@lingui/macro';

import { usePrintContext } from '@float/common/contexts/PrintContext';
import {
  AccountType,
  CurrentUser,
  MemberAccessLevel,
} from '@float/types/account';
import EH from '@float/ui/deprecated/Earhart';

export type UserAccountAndPrefs = CurrentUser & {
  share_link_enabled: 0 | 1;
  share_link_all_members_enabled: 0 | 1;
};

type ShareProps = {
  user: UserAccountAndPrefs;
  actions: {
    showExportCSV: () => void;
    showShareLinkModal: () => void;
    showEmailNotificationsModal: () => void;
  };
  onClick: (action: () => void) => void;
};

function getACL(user: UserAccountAndPrefs) {
  const isMember = user.account_type_id === AccountType.Member;
  const isAccountOwner = user.account_type_id === AccountType.AccountOwner;
  const access = user?.account?.access;
  const canViewOnlyThemself =
    isMember &&
    (access === MemberAccessLevel.CanViewThemselfEditNone ||
      access === MemberAccessLevel.CanViewThemselfEditThemself);

  return {
    isObserver: isMember,
    isAccountOwner,
    canViewOnlyThemself,
  };
}

export default function Share({ user, actions, onClick }: ShareProps) {
  const { requestPrint } = usePrintContext();
  const { isObserver, isAccountOwner, canViewOnlyThemself } = getACL(user);
  const shareLinkAllMembersEnabled =
    user.share_link_enabled & user.share_link_all_members_enabled;

  const options = [
    {
      name: t`Export (.CSV for Excel)`,
      action: actions.showExportCSV,
    },
    {
      name: t`Print`,
      action: requestPrint,
    },
  ];

  if (isAccountOwner || (shareLinkAllMembersEnabled && !canViewOnlyThemself)) {
    options.unshift({
      name: t`Share a link`,
      action: actions.showShareLinkModal,
    });
  }

  if (!isObserver) {
    options.unshift({
      name: t`Email this schedule`,
      action: actions.showEmailNotificationsModal,
    });
  }

  const onClickOption = (data: { action: () => void }) => {
    onClick(data.action);
  };

  return (
    <EH.List
      // @ts-expect-error legacy component missing types
      appearance="flay"
      options={options}
      onClick={onClickOption}
    />
  );
}
