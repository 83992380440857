import React from 'react';

export const IconShortcuts = () => (
  <svg
    fill="none"
    width={48}
    height={48}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#96C7F2"
      stroke="#242C39"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".3"
      d="M40.5 13.1c0-2.7-2.2-4.8-4.9-4.8H20a4.9 4.9 0 0 0-5 4.7v16a5 5 0 0 0 4.9 4.9h15.7a5 5 0 0 0 4.9-5V13.2Z"
    />
    <path
      fill="#E1F0FF"
      stroke="#242C39"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".3"
      d="M35.3 20.6a5 5 0 0 0-5-4.9H14.7a4.9 4.9 0 0 0-4.8 5v15.7c0 2.7 2.1 4.9 4.8 4.9h15.8a5 5 0 0 0 4.9-5V20.7Z"
    />
    <path
      stroke="#242C39"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".3"
      d="M26.9 21a2.4 2.4 0 0 0-2.4 2.4v9.5a2.4 2.4 0 1 0 2.4-2.4h-9.5a2.4 2.4 0 1 0 2.4 2.4v-9.5a2.4 2.4 0 1 0-2.4 2.4h9.5a2.4 2.4 0 1 0 0-4.8Z"
    />
  </svg>
);
