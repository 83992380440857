import React, { forwardRef, HTMLAttributes, MouseEvent } from 'react';
import cn from 'classnames';

import { noop } from '@float/libs/utils/noop';

import * as styles from './styles.css';

export type ListItemProps = {
  selected?: boolean;
  disabled?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const ListItemRoot = forwardRef<HTMLDivElement, ListItemProps>((props, ref) => {
  const {
    children,
    className,
    onClick = noop,
    selected,
    disabled,
    ...rest
  } = props;

  function handleClick(e: MouseEvent<HTMLDivElement>) {
    e.currentTarget.blur();

    onClick(e);
  }

  return (
    <div
      aria-selected={selected}
      aria-disabled={disabled}
      tabIndex={disabled ? -1 : 0}
      {...rest}
      className={cn(styles.wrapper, className)}
      onClick={handleClick}
      ref={ref}
    >
      {children}
    </div>
  );
});

export { ListItemRoot };
