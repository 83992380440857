import { useMemo } from 'react';
import addWeeks from 'date-fns/addWeeks';

import { determineEntityStatus } from '@float/common/actions/lib/determineEntityStatus';
import { parseBudgetString } from '@float/common/lib/budget/helpers/parseBugdetString';
import { formatToFloatDate } from '@float/libs/dates';
import {
  BudgetPriority,
  BudgetType,
  Phase,
  PhaseInputData,
  Project,
  ProjectPhaseRecord,
  ProjectStatus,
} from '@float/types';

import {
  FormType,
  PhaseEditData,
  ProjectFormData,
  ProjectFormInitialData,
} from '../types';
import {
  DEFAULT_FORM_VALUES as DEFAULT_PROJECT_FORM_VALUES,
  mapMilestoneToRecord,
  mapProjectToFormValues,
  mapTaskToRecord,
} from './useProjectFormValues';
import { useSortedTeams } from './useSortedTeam';

export const DEFAULT_FORM_VALUES = {
  type: FormType.Phase,
  project: DEFAULT_PROJECT_FORM_VALUES.project,
  projectId: undefined,
  phase: {
    active: true,
    budget_total: null,
    budget_type: BudgetType.Disabled,
    budget_priority: BudgetPriority.Project,
    color: null,
    default_hourly_rate: null,
    end_date: formatToFloatDate(addWeeks(new Date(), 4)),
    non_billable: false,
    notes_meta: [],
    notes: '',
    phase_id: null,
    phase_name: '',
    project_id: null,
    status: ProjectStatus.Confirmed,
    start_date: formatToFloatDate(new Date()),
  },
  team: [],
  tasks: [],
  milestones: [],
  phases: [],
} satisfies ProjectFormData;

export function mapPhaseToFormValues(
  phase: Phase,
  project?: Project,
): PhaseInputData {
  const isNewPhase = !phase.phase_id;
  const default_hourly_rate = isNewPhase
    ? project?.default_hourly_rate
    : phase.default_hourly_rate;

  return {
    active: Boolean(phase.active),
    budget_total: parseBudgetString(phase.budget_total),
    color: phase.color,
    default_hourly_rate: parseBudgetString(default_hourly_rate),
    end_date: phase.end_date,
    non_billable: Boolean(phase.non_billable),
    notes_meta: phase.notes_meta || [],
    notes: phase.notes || '',
    phase_id: phase.phase_id,
    phase_name: phase.phase_name,
    project_id: phase.project_id,
    status: determineEntityStatus(phase),
    start_date: phase.start_date,
    template_phase_id: phase.template_phase_id,
    duration: phase.duration,
    parent_offset: phase.parent_offset,
  };
}

function excludePeopleRemovedFromProjectTeam(
  team: ProjectFormData['team'],
  unsavedProject?: PhaseEditData['unsavedProject'],
) {
  const removedPeopleIds = unsavedProject?.teamDiff?.del;
  if (!removedPeopleIds?.length) return team;

  return team.filter(
    ({ people_id: id }) => id !== null && !removedPeopleIds.includes(id),
  );
}

function getPhaseFormDefaultValues(
  formData: ProjectFormData,
  overrides?: ProjectPhaseRecord,
  unsavedProject?: PhaseEditData['unsavedProject'],
) {
  if (!overrides || !formData.phase) return formData;

  if (overrides.fullPhaseData) {
    // bring in previously saved phase panel data
    formData.phase = {
      ...formData.phase,
      ...overrides.fullPhaseData.phase,
    };
    formData.tasks = overrides.fullPhaseData.tasks;
    formData.milestones = overrides.fullPhaseData.milestones;
    formData.team = excludePeopleRemovedFromProjectTeam(
      overrides.fullPhaseData.team,
      unsavedProject,
    );
  }

  // bring in project phase row data
  formData.phase.active = overrides.active;
  formData.phase.budget_total = overrides.budget_total;
  formData.phase.color = overrides.color ?? null;
  formData.phase.end_date = overrides.end_date;
  formData.phase.non_billable = overrides.non_billable;
  formData.phase.phase_name = overrides.phase_name;
  formData.phase.start_date = overrides.start_date;

  // set project fields for budget inference and phase header nav
  if (unsavedProject?.project) {
    formData.project = {
      ...formData.project,
      ...unsavedProject.project,
    };

    // phases in non-billable project must be non-billable
    if (unsavedProject.project.non_billable) {
      formData.phase.non_billable = true;
    }

    // phases in a draft or tentative project must inherit the project status
    if (
      unsavedProject.project.status === ProjectStatus.Draft ||
      unsavedProject.project.status === ProjectStatus.Tentative
    ) {
      formData.phase.status = unsavedProject.project.status;
    }
  }

  // template fields
  if (formData.type === FormType.PhaseTemplate) {
    formData.phase.duration = overrides.duration ?? null;
    formData.phase.parent_offset = overrides.parent_offset ?? null;
  }

  return formData;
}

function getPhaseFormProjectInputData(
  project: Project | undefined,
  overrides: Partial<ProjectFormData['project']> | undefined,
  defaultValue: ProjectFormData['project'],
): ProjectFormData['project'] {
  if (!project) return defaultValue;

  const projectInputData = mapProjectToFormValues(project);
  if (!overrides) return projectInputData;

  return { ...projectInputData, ...overrides };
}

// Uses phase data to parse out form values for side panel
export function usePhaseFormValues(
  data: ProjectFormInitialData,
  panelPayload?: PhaseEditData,
): ProjectFormData {
  const { type, phase, project, tasks, milestones } = data;
  const { sortAndMapTeamRecords } = useSortedTeams();
  const phaseNonBillable = Boolean(
    project?.non_billable || phase?.non_billable,
  );
  const phaseStatus = determineEntityStatus(phase ?? project);
  const defaultValue = useMemo(
    () => ({
      ...DEFAULT_FORM_VALUES,
      project: {
        ...DEFAULT_FORM_VALUES.project,
        ...panelPayload?.unsavedProject?.project,
      },
      phase: {
        ...DEFAULT_FORM_VALUES.phase,
        start_date:
          panelPayload?.startDate || DEFAULT_FORM_VALUES.phase.start_date,
        end_date: panelPayload?.endDate || DEFAULT_FORM_VALUES.phase.end_date,
        color: phase?.color ?? null,
        default_hourly_rate: parseBudgetString(
          project?.default_hourly_rate ??
            DEFAULT_FORM_VALUES.phase.default_hourly_rate,
        ),
        non_billable: phaseNonBillable,
        status: phaseStatus,
      },
    }),
    // Used to set the fallback return value in case phase does not
    // exist (e.g. during creation). Dates come form `panelPayload`
    // (provided as params on mount) and `project` value changes
    // recompute the memoized return value at the end of of this hook.
    // So we don't need to include them in the depedency array here too.
    [],
  );

  const phaseTaskRecords = useMemo(() => tasks.map(mapTaskToRecord), [tasks]);

  const phaseTeam = useMemo(() => {
    if (!phase && !project) return [];

    // If creating a new phase, copy project team over to phase team
    const isNewPhase = !phase;
    const peopleIds = isNewPhase ? project?.people_ids : phase.people_ids;
    const peopleRates = isNewPhase ? project?.people_rates : phase.people_rates;

    if (!peopleIds?.length) return [];

    const sortedTeamRecords = sortAndMapTeamRecords(peopleIds, peopleRates);

    return excludePeopleRemovedFromProjectTeam(
      sortedTeamRecords,
      panelPayload?.unsavedProject,
    );
  }, [phase, project, panelPayload?.unsavedProject, sortAndMapTeamRecords]);

  const phaseMilestones = useMemo(
    () => milestones.map(mapMilestoneToRecord),
    [milestones],
  );

  return useMemo(() => {
    const phaseProject = getPhaseFormProjectInputData(
      project,
      panelPayload?.unsavedProject?.project,
      defaultValue.project,
    );

    if (!phase) {
      return getPhaseFormDefaultValues(
        {
          ...defaultValue,
          type,
          projectId: project?.project_id,
          project: phaseProject,
          team: phaseTeam,
        },
        panelPayload?.projectPhaseRecord,
        panelPayload?.unsavedProject,
      );
    }

    return {
      type,
      projectId: project?.project_id,
      phase: mapPhaseToFormValues(phase, project),
      tasks: phaseTaskRecords,
      milestones: phaseMilestones,
      team: phaseTeam,
      project: phaseProject,
      phases: defaultValue.phases,
    };
  }, [
    phase,
    project,
    defaultValue,
    phaseTaskRecords,
    phaseMilestones,
    phaseTeam,
    type,
    panelPayload?.projectPhaseRecord,
    panelPayload?.unsavedProject,
  ]);
}
