import { BaseEditor, createEditor as createBaseEditor } from 'slate';
import { HistoryEditor, withHistory } from 'slate-history';
import { ReactEditor, withReact } from 'slate-react';

import { LinkifyPlugin } from '../plugins/linkifyPlugin';
import { MaxLengthPlugin } from '../plugins/maxLengthPlugin';
import { MentionsPlugin } from '../plugins/mentionsPlugin';

export function createEditor(params: { maxLength: number }) {
  let editor = createBaseEditor();

  editor = MaxLengthPlugin(editor, { maxLength: params.maxLength });
  editor = MentionsPlugin(editor);
  editor = LinkifyPlugin(editor);
  editor = withReact(editor);
  editor = withHistory(editor);

  return editor as BaseEditor & ReactEditor & HistoryEditor;
}
