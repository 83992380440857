import React from 'react';

import { TaskMetaOption } from '@float/common/selectors/taskMetas';
import { Icons } from '@float/ui/deprecated';

type IconType = React.JSX.Element | null;

export function groupTaskNameOptions(taskNames: TaskMetaOption[] | undefined) {
  const billableOptions = [];
  const nonBillableOptions = [];

  for (const task of taskNames || []) {
    if (task.task_name === '') continue;

    const iconRight: IconType =
      (task.min_integration_status &&
        Number(task.min_integration_status) > 0 && (
          <Icons.SyncWhiteLrg size="24" color="#868d92" />
        )) ||
      null;

    const option = {
      label: task.task_name,
      value: task.task_name,
      iconRight,
    };

    if (task.billable === 1) {
      billableOptions.push(option);
    } else {
      nonBillableOptions.push(option);
    }
  }

  const optionGroups = [];

  if (billableOptions.length) {
    optionGroups.push({
      name: 'Billable',
      options: billableOptions,
    });
  }

  if (nonBillableOptions.length) {
    optionGroups.push({
      name: 'Non-Billable',
      options: nonBillableOptions,
    });
  }

  return optionGroups;
}
