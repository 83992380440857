import React from 'react';

export default function () {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.4388 35.5073C41.2764 34.8587 40.9458 34.2644 40.4803 33.7845C40.0147 33.3046 39.4308 32.956 38.7875 32.774C36.4368 32.2 27.0341 32.2 27.0341 32.2C27.0341 32.2 17.6314 32.2 15.2808 32.8286C14.6374 33.0107 14.0535 33.3592 13.588 33.8392C13.1224 34.3191 12.7918 34.9134 12.6294 35.562C12.1992 37.9476 11.9888 40.3676 12.0008 42.7916C11.9854 45.2339 12.1959 47.6724 12.6294 50.076C12.8084 50.7044 13.1465 51.2761 13.6109 51.7358C14.0754 52.1954 14.6505 52.5275 15.2808 52.7C17.6314 53.3286 27.0341 53.3286 27.0341 53.3286C27.0341 53.3286 36.4368 53.3286 38.7875 52.7C39.4308 52.518 40.0147 52.1694 40.4803 51.6895C40.9458 51.2095 41.2764 50.6152 41.4388 49.9666C41.8657 47.599 42.0761 45.1974 42.0675 42.7916C42.0828 40.3494 41.8723 37.9109 41.4388 35.5073Z"
        fill="#E1F0FF"
        stroke="#2E5FE8"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.9592 47.2606L31.8176 42.7916L23.9592 38.3226V47.2606Z"
        fill="#E1F0FF"
        stroke="#2E5FE8"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.2667 52.2C50.3285 52.2 52 50.5285 52 48.4667V21.2667C52 18.9103 50.0897 17 47.7333 17H29.3333C27.3161 17 25.6725 18.5999 25.6023 20.6M25.6023 20.6C25.6008 20.6443 25.6 25.8 25.6 25.8446L25.6023 20.6Z"
        stroke="#344765"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
