import React, { ReactNode } from 'react';

import { toFriendlyTime } from '@float/common/lib/time';
import { CompanyPreferences } from '@float/types/companyPreferences';

import { MainCellItem } from './types';

export function addStartTimeElement(
  entity: MainCellItem['entity'],
  elements: ReactNode[],
  companyPrefs: CompanyPreferences,
) {
  const startTime = 'start_time' in entity ? entity.start_time : null;

  if (startTime && elements.length) {
    if (React.isValidElement(elements[0])) {
      elements[0] = (
        <>
          {`${toFriendlyTime(startTime, companyPrefs)} - `}
          {elements[0]}
        </>
      );
    } else {
      elements[0] = `${toFriendlyTime(startTime, companyPrefs)} - ${
        elements[0]
      }`;
    }
  } else if (startTime) {
    elements.push(toFriendlyTime(startTime, companyPrefs));
  }
}
