import { Phase, Project } from '@float/types';

export function isArchived(
  project: Project | undefined,
  phase: Phase | undefined,
) {
  return Boolean(
    (project && !project.active) || (phase && phase.phase_id && !phase.active),
  );
}
