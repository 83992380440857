import styled from 'styled-components';

import { Core, FIN } from '@float/common/earhart/colors';
import { StyledButton } from '@float/ui/deprecated/Button/styles';
import { Card } from '@float/ui/deprecated/Earhart/Cards/Card';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

export const StyledCard = styled(Card)`
  min-width: 890px;
  padding: 24px;
`;

export const StyledBillingDetailsHeader = styled.h4`
  ${Typography.DisplayXS.M500};

  color: ${FIN.Lt.Emphasis.High};
  margin-bottom: 38px;
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 97px;
`;

export const StyledDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 56px;
`;

export const StyledDetailsSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  h5 {
    ${Typography.Label16.M500}
    color: ${Core.Text.Secondary};
  }
`;

export const StyledDetailsPrimaryContent = styled.p`
  ${Typography.DisplayXS.M500}

  margin: 4px 0;

  em {
    color: ${Core.Lt.Blue.Primary};
  }

  sup {
    vertical-align: text-top;
    ${Typography.TextL.M500}
  }

  sub {
    ${Typography.TextS14.M500}
  }
`;

export const StyledPaymentDetailsPrimaryContent = styled(
  StyledDetailsPrimaryContent,
)`
  sub {
    ${Typography.TextS14.R400}
  }
`;

export const StyledDetailsSecondaryContent = styled.p`
  ${Typography.TextXS.R400}
  color: ${Core.Text.Secondary};
`;

export const StyledCTAWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  ${StyledButton} {
    width: 100%;
  }
`;

export const StyledCTAContext = styled.h5`
  ${Typography.Label16.M500};
`;
