import { t } from '@lingui/macro';

export enum PersonType {
  EMPLOYEE = 1,
  CONTRACTOR = 2,
  PLACEHOLDER = 3,
}

// Must be a function to allow for Lingui translations
export const getPersonTypeToLabelMap = () => ({
  [PersonType.EMPLOYEE]: t`Employee`,
  [PersonType.CONTRACTOR]: t`Contractor`,
  [PersonType.PLACEHOLDER]: t`Placeholder`,
});
