import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { t } from '@lingui/macro';
import cn from 'classnames';
import { AnimatePresence } from 'framer-motion';

import videoPoster from './assets/glenn-welcome-you-to-float-poster.mp4';
import videoSource from './assets/glenn-welcome-you-to-float.mp4';
import { ButtonPlay } from './components/ButtonPlay';

import * as styles from './styles.css';

export type WelcomeVideoRef = {
  play: () => void;
};

export type WelcomeVideoProps = {
  onClickPlay: () => void;
  onVideoEnded?: () => void;
  className?: string;
};

export const WelcomeVideo = memo(
  forwardRef<WelcomeVideoRef, WelcomeVideoProps>((props, ref) => {
    const { className, onClickPlay, onVideoEnded } = props;

    const videoRef = useRef<HTMLVideoElement>(null);
    const videoPosterRef = useRef<HTMLVideoElement>(null);
    const [isPlaying, setPlaying] = useState(false);
    const [isPlaybackReady, setPlaybackReady] = useState(false);

    useImperativeHandle(ref, () => ({
      play() {
        if (videoRef.current?.paused) {
          videoRef.current?.play();

          // attempt to prevent automatic captions from appearing
          // https://linear.app/float-com/issue/CONF-5/replace-illustration-with-welcome-video#comment-bc83e596
          const tracks = videoRef.current?.textTracks;
          const track = tracks[0];
          if (track) track.mode = 'hidden';

          // to avoid the controls glitching in when the video starts
          // we enable them manually only when the user leaves the player
          if (!videoRef.current?.hasAttribute('controls')) {
            const onMouseLeave = () => {
              videoRef.current?.setAttribute('controls', 'controls');

              videoRef.current?.removeEventListener('mouseleave', onMouseLeave);
            };

            videoRef.current?.addEventListener('mouseleave', onMouseLeave);
          }
        } else {
          videoRef.current?.pause();
        }

        setPlaying(!videoRef.current?.paused);
      },
    }));

    const showPlayButton = () => {
      setPlaybackReady(true);
    };

    useEffect(() => {
      const videoPoster = videoPosterRef.current;

      videoPoster?.addEventListener('ended', showPlayButton);

      return () => {
        videoPoster?.removeEventListener('ended', showPlayButton);
      };
    });

    return (
      <div
        className={cn(className, styles.welcomeVideoWrapper, {
          [styles.playing]: isPlaying,
        })}
      >
        <div className={styles.posterWrapper}>
          <AnimatePresence>
            {isPlaybackReady && (
              <ButtonPlay className={styles.buttonPlay} onClick={onClickPlay} />
            )}
          </AnimatePresence>

          <video
            ref={videoPosterRef}
            autoPlay={true}
            className={styles.video}
            crossOrigin=""
            data-testid="video-poster"
            muted
            playsInline={true}
            src={videoPoster}
          />
        </div>

        <div className={styles.videoWrapper}>
          <video
            ref={videoRef}
            className={styles.video}
            aria-label={t`Glenn welcome you to Float`}
            controls={false}
            crossOrigin=""
            onEnded={onVideoEnded}
            playsInline
            preload="metadata"
            src={videoSource}
          />
        </div>
      </div>
    );
  }),
);
