import {
  RemoteEntityType,
  SearchResolveEntityKey,
} from '@float/common/api3/search.types';
import { SearchResultsState } from '@float/common/store/searchResults/searchResults.types';

import { FilterTypes } from '../types';

export const REMOTE_TYPE_TO_FILTER = {
  client: 'client',
  department: 'department',
  manager: 'manager',
  people: 'person',
  peopleTag: 'personTag',
  peopleType: 'personType',
  phase: 'phase',
  project: 'project',
  projectOwner: 'projectOwner',
  projectStatus: 'projectStatus',
  projectTag: 'projectTag',
  role: 'jobTitle',
  task: 'task',
  taskStatus: 'taskStatus',
  timeOffStatus: 'timeoffStatus',
  timeOffType: 'timeoff',
} satisfies Record<RemoteEntityType, FilterTypes>;

export const FILTER_TYPE_TO_REMOTE_TYPE = {
  client: 'client',
  department: 'department',
  jobTitle: 'role',
  manager: 'manager',
  me: 'people',
  person: 'people',
  personTag: 'peopleTag',
  personType: 'peopleType',
  phase: 'phase',
  project: 'project',
  projectOwner: 'projectOwner',
  projectStatus: 'projectStatus',
  projectTag: 'projectTag',
  role: 'role',
  task: 'task',
  taskStatus: 'taskStatus',
  timeoff: 'timeOffType',
  timeoffStatus: 'timeOffStatus',
} satisfies Record<FilterTypes, RemoteEntityType>;

export const RESOLVE_RESULT_TO_SEARCH_RESULT = {
  loggedTime: 'loggedTimes',
  people: 'people',
  project: 'projects',
  task: 'tasks',
  timeOff: 'timeoffs',
} satisfies Record<SearchResolveEntityKey, keyof SearchResultsState>;
