import { createSelector } from 'reselect';

import { filterAndKeyBy } from '@float/common/lib/keyBy';
import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { selectDatesManager } from '@float/common/selectors/currentUser';
import { getPhases } from '@float/common/selectors/phases';
import { ScheduleVisibleDataWindow } from '@float/common/serena/redux/reducers';
import { Phase } from '@float/types';

import { selectVisibleDataWindow } from './selectVisibleDataWindow';

const getPhaseIsInsideRange = (
  dates: DatesManager,
  { start, end }: ScheduleVisibleDataWindow,
  { start_date, end_date }: Phase,
) => {
  const taskStart = dates.toNum(start_date);
  const taskEnd = dates.toNum(end_date);

  return taskStart <= end && start <= taskEnd;
};

function getIsPhaseVisible(
  dates: DatesManager,
  phase: Phase,
  visibleDataWindow: ScheduleVisibleDataWindow,
) {
  // Is the project in one of the visible rows?
  if (!visibleDataWindow.projectsIds.has(phase.project_id)) return false;

  // Is the phase inside the columns range?
  return getPhaseIsInsideRange(dates, visibleDataWindow, phase);
}

export const selectVisiblePhases = createSelector(
  [
    selectVisibleDataWindow,
    selectDatesManager,
    getPhases,
    (state: ReduxStateStrict) => state.tasks.fullyHydrated,
  ],
  (visibleDataWindow, dates, phases) => {
    if (visibleDataWindow === null) return {};
    if (visibleDataWindow.projectsIds.size === 0) return {};

    return filterAndKeyBy(phases, 'phase_id', (phase) =>
      getIsPhaseVisible(dates, phase, visibleDataWindow),
    );
  },
);
