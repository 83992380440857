import React from 'react';

import VirtualSelect from '@float/ui/deprecated/VirtualSelect/VirtualSelect';

import linkState, { linkAt } from '../form/linkState';

const date = new Date();
export const DEFAULT_YEAR = String(date.getFullYear());

class YearTableFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      year: { label: DEFAULT_YEAR, value: DEFAULT_YEAR },
    };
    linkState(this, 'year', (x) =>
      this.props.options.find((year) => year.value == x),
    );
    this.onChange = this.onChange.bind(this);
  }

  onChange(x) {
    const { onChange } = this.props;
    const yearLink = linkAt(this, 'year');
    yearLink.onChange(x);
    onChange(yearLink.value.value);
  }

  render() {
    const { options, className = '' } = this.props;
    const yearLink = linkAt(this, 'year');

    return (
      <div className={className}>
        <VirtualSelect
          label="Year"
          visibleItems={6}
          nonNullable
          onChange={(e) => this.onChange({ target: e })}
          options={options}
          value={yearLink.value?.value}
        />
      </div>
    );
  }
}

export default YearTableFilter;
