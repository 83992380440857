import React from 'react';

import useMedia from '@float/libs/web/hooks/useMedia';

import { ResponsiveFilterTokens } from './ResponsiveFilterTokens';
import { SearchFilterTokensProps } from './types';
import { WideScreenFilterTokens } from './WideScreenFilterTokens';

export const SearchFilterTokens = (props: SearchFilterTokensProps) => {
  const breakpoint = useMedia();

  if (breakpoint === 'medium') {
    return <ResponsiveFilterTokens {...props} />;
  }

  return <WideScreenFilterTokens {...props} />;
};
