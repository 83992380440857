import { ReduxState } from '@float/common/reducers/lib/types';
import { Project } from '@float/types';

export type EditTaskModalProps = {
  lastDeletedTaskMetaIds: ReduxState['taskMetas']['lastDeletedIds'];
  projectsMap: ReduxState['projects']['projects'];
};

export type EditTaskModalState = {
  taskMode: 'task' | 'timeoff';
  project?: Project;
  taskMetaId: number | null;
};

export function getDerivedStateFromProps(
  props: EditTaskModalProps,
  state: EditTaskModalState,
) {
  // The component has not been mounted yet
  if (state.taskMode !== 'task') return null;

  // Got a live update on task metas
  if (state.taskMetaId && props.lastDeletedTaskMetaIds?.has(state.taskMetaId)) {
    return {
      taskMetaId: null,
      taskName: '',
    };
  }

  const projectId = state.project?.project_id;

  if (!projectId) return null;

  const project = props.projectsMap[projectId];

  // Got a live update on projects
  if (project && state.project !== project) {
    return { project };
  }

  return null;
}
