import { Phase, Project, SearchTask, Task } from '@float/types';

/**
 * A function that updates the task billable status
 * accordlingly to the new project billable status following the spec
 * described [here](https://linear.app/float-com/issue/CS-1104#comment-b35522bf)
 */
export function handleTaskBillableStatusChange(
  originalTask: Task | SearchTask,
  updatedTask: Task,
  projects: Record<number, Project>,
  phases: Record<number, Phase>,
): Task {
  if (!originalTask.project_id) return updatedTask;
  if (
    originalTask.project_id === updatedTask.project_id &&
    originalTask.phase_id === updatedTask.phase_id
  ) {
    return updatedTask;
  }

  // If a task is assigned to a phase, refer to the phase billable status, otherwise refer to the project billable status
  const currentBillableReference = originalTask.phase_id
    ? phases[originalTask.phase_id]
    : projects[originalTask.project_id];

  const nextBillableReference = updatedTask.phase_id
    ? phases[updatedTask.phase_id]
    : projects[updatedTask.project_id];

  const { billable } = updatedTask;

  const isCurrentReferenceBillable =
    currentBillableReference.non_billable === false;
  const isNextReferenceBillable = nextBillableReference.non_billable === false;

  if (
    billable === 0 &&
    isCurrentReferenceBillable === false &&
    isNextReferenceBillable === true
  ) {
    return {
      ...updatedTask,
      billable: 1,
    };
  }

  if (billable === 1 && isNextReferenceBillable === false) {
    return {
      ...updatedTask,
      billable: 0,
    };
  }

  return updatedTask;
}
