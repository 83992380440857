import { UserPrefs } from '@float/types/account';
import { CompanyPreferences } from '@float/types/companyPreferences';
import { TimeFormatPreference } from '@float/types/timeFormatPreference';

import { getIs24HoursTimeFormat } from '../lib/time/getIs24HoursTimeFormat';
import { getCompanyPrefs } from '../selectors/companyPrefs';
import { getPrefs } from '../selectors/currentUser';
import { useAppSelectorStrict } from '../store';

export const useTimeFormatPreference = () => {
  const userPrefs: UserPrefs = useAppSelectorStrict(getPrefs);
  const companyPrefs: CompanyPreferences =
    useAppSelectorStrict(getCompanyPrefs);

  return getIs24HoursTimeFormat(userPrefs, companyPrefs)
    ? TimeFormatPreference.TwentyFourHourFormat
    : TimeFormatPreference.TwelveHourFormat;
};
