import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { selectCompanyHostName } from '@float/common/selectors/companyPrefs';
import {
  useAppDispatchDecorator,
  useAppSelector,
  useAppSelectorStrict,
} from '@float/common/store';
import { selectJWTAccessToken } from '@float/common/store/jwt/jwt.selectors';
import { PageData } from '@float/types/pageData';
import { ping } from '@float/web/actions/app';
import { AppUpdatesNotification } from '@float/web/components/Notifications/AppUpdatesNotification';
import BrowserTabNotification from '@float/web/components/Notifications/BrowserTabNotification';
import LiveUpdates from '@float/web/liveUpdates';
import AsyncProcess from '@float/web/liveUpdates/asyncProcess/AsyncProcess';
import { fetchAllIntegrations } from '@float/web/pmSidebar/actions';
import AccountSettings from '@float/web/settingsV2/containers/AccountSettings';

import { FullPageLoader } from '../components/FullPageLoader';
import { Portal } from '../components/Portal';
import { SidePanelController } from '../components/SidePanel/SidePanelController';
import { useAppController } from './AppRouter.hooks';
import {
  useBootstrapGlobalServicesForApp,
  useBootstrapGlobalServicesForShareApp,
} from './hooks/bootstrap.hooks';

export const AppRouter = ({ data }: { data: PageData }) => {
  const { isReady } = useBootstrapGlobalServicesForApp(data);

  return (
    <FullPageLoader isLoading={!isReady}>
      <AppWithInitializedGlobalServices isSharedLink={false} />
    </FullPageLoader>
  );
};

export const ShareAppRouter = () => {
  const { isReady } = useBootstrapGlobalServicesForShareApp();

  return (
    <FullPageLoader isLoading={!isReady}>
      <AppWithInitializedGlobalServices isSharedLink={true} />
    </FullPageLoader>
  );
};

const AppWithInitializedGlobalServices = ({
  isSharedLink,
}: {
  isSharedLink: boolean;
}) => {
  const accessToken = useAppSelector(selectJWTAccessToken);
  const companyHostname = useAppSelectorStrict(selectCompanyHostName);
  const dispatchPing = useAppDispatchDecorator(ping);
  const dispatchFetchAllIntegrations =
    useAppDispatchDecorator(fetchAllIntegrations);

  useAppController({
    accessToken,
    companyHostname,
    fetchAllIntegrations: dispatchFetchAllIntegrations,
    ping: dispatchPing,
    isSharedLink,
  });

  return (
    <>
      <LiveUpdates />
      <AsyncProcess />
      <AppUpdatesNotification />
      <BrowserTabNotification />
      <Switch>
        <Route path="/admin" component={AccountSettings} />
        <Route component={Portal} />
      </Switch>
      <SidePanelController />
    </>
  );
};
