import React from 'react';
import { Trans } from '@lingui/macro';

import { Button } from '@float/ui/deprecated';
import {
  DropdownActions,
  pushToRight,
} from '@float/ui/deprecated/DropdownActions';
import { IconArrowDown, IconTrash } from '@float/ui/deprecated/Earhart/Icons';

export type GuestAccountActionsMenuProps = {
  onDelete: () => void;
};

export function GuestAccountActionsMenu(props: GuestAccountActionsMenuProps) {
  return (
    <DropdownActions
      side="top"
      align="end"
      className={pushToRight}
      content={
        <>
          <DropdownActions.Item appearance="danger" onClick={props.onDelete}>
            <Trans>Delete</Trans> <IconTrash />
          </DropdownActions.Item>
        </>
      }
    >
      <Button appearance="clear" iconRight={IconArrowDown}>
        <Trans>Actions</Trans>
      </Button>
    </DropdownActions>
  );
}
