import { useCallback } from 'react';
import gsap from 'gsap';
import { TransitionRef } from 'TransitionManager';

import { AnimatedElements } from './useAnimatedElements';

export const useOnExitAnimation = (
  animatedElements: AnimatedElements | undefined,
) => {
  const onExit = useCallback<TransitionRef['onExit']>(() => {
    const delay = 0;

    const duration = 0.5;
    const ease = 'power3.inOut';

    const { content } = animatedElements!;

    return new Promise<void>((success) => {
      gsap.killTweensOf(content);
      gsap.killTweensOf(Object.values(animatedElements!).flat());

      window.requestIdleCallback(() => {
        gsap.to(content, {
          y: 20,
          opacity: 0,
          duration,
          delay,
          ease,
          clearProps: 'all',
          onComplete: success,
        });
      });
    });
  }, [animatedElements]);

  return { onExit };
};
