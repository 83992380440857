import { useCellStyles } from '@float/common/lib/cellStylesManager/hooks/useCellStyles';
import { ReduxState } from '@float/common/reducers/lib/types';
import { getCellItemColor } from '@float/common/selectors/cells';
import { useAppSelector } from '@float/common/store';
import { CellItem, LoggedTime } from '@float/types';

export function useLoggedTimeStyles(loggedTime: LoggedTime | undefined) {
  const loggedTimeColor = useAppSelector((state: ReduxState) =>
    getCellItemColor(state, loggedTime?.phase_id, loggedTime?.project_id),
  );

  const cellItem = {
    type: 'loggedTime',
    isTaskReference: true,
  } as CellItem<'loggedTime'>;

  const { backgroundColor, color } = useCellStyles(cellItem, loggedTimeColor);

  return { backgroundColor, color };
}
