import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
  useStore,
} from 'react-redux';
import { Store } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { AllActions } from '@float/common/reducers';
import { WebAppState, WebAppStateStrict } from '@float/web/reducers/types';

import { OnboardingActions } from '../store/onboardingManager/actions';

export type WebAppActions = AllActions & OnboardingActions;

export type WebAppStore = Store<WebAppState, WebAppActions>;
export type WebAppDispatch = ThunkDispatch<WebAppState, unknown, WebAppActions>;

export const useWebAppSelector: TypedUseSelectorHook<WebAppState> = useSelector;
export const useWebAppSelectorStrict: TypedUseSelectorHook<WebAppStateStrict> =
  useSelector;
export const useWebAppDispatchStrict: () => WebAppDispatch = useDispatch;
export const useWebAppStoreStrict: () => WebAppStore = useStore;
