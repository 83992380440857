import {
  AnyEntity,
  CurrentUser,
  Person,
  Phase,
  Project,
  TimeoffType,
} from '@float/types';

import { Rights } from './rights';

export const canCreateTaskForPeople = (
  user: CurrentUser,
  people: Person[] | number[],
) => {
  if (!people?.length) {
    return Rights.canCreateTask(user);
  }

  return Rights.canCreateTask(user, {
    people,
    ignore: [
      'isParentProjectOwner',
      'onParentProjectTeam',
      'parentProjectIsCommon',
    ],
  });
};

export const canCreateTimeoffForPeople = (
  user: CurrentUser,
  people: Person[] | number[],
) => {
  if (!people?.length) {
    return Rights.canCreateTimeoff(user);
  }

  return Rights.canCreateTimeoff(user, {
    people,
    ignore: ['statusIsTentative'],
  });
};

// @deprecate This helper was made to quickly add ACL but it's usage is discouraged
// Try to use the specific rights checks
export const canUpdateEntity = (
  user: CurrentUser,
  ctx: {
    entity: AnyEntity;
    people?: Person[] | number[];
    timeoffType?: TimeoffType;
    project?: Project;
    phase?: Phase;
    person?: Person;
  },
) => {
  const entity = ctx.entity;

  const isTimeoff = 'timeoff_id' in entity;
  if (isTimeoff) {
    return Rights.canUpdateTimeoff(user, ctx);
  }

  const isLoggedTime = 'logged_time_id' in entity;
  if (isLoggedTime) {
    if (entity.isTaskReference) {
      return Rights.canCreateLoggedTime(user, ctx);
    }
    return Rights.canUpdateLoggedTime(user, ctx);
  }

  const isTask = 'task_id' in entity;
  if (isTask) {
    const checkPeopleViewable = ctx.people;
    if (checkPeopleViewable) {
      const canViewAssignees = Rights.canViewPeople(user, ctx);
      if (!canViewAssignees) {
        return false;
      }
    }

    return Rights.canUpdateTask(user, ctx);
  }

  const isStatus = 'status_id' in entity;
  if (isStatus) {
    return Rights.canUpdateStatus(user, ctx);
  }

  const isMilestone = 'milestone_id' in entity;
  if (isMilestone) {
    return Rights.canUpdateMilestones(user, ctx);
  }

  const isPhase = 'phase_id' in entity;
  if (isPhase) {
    return Rights.canUpdatePhase(user, ctx);
  }

  const isProject = 'project_id' in entity;
  if (isProject) {
    // @ts-expect-error quite generic code that doesn't work well with Typecript
    return Rights.canUpdateProject(user, ctx);
  }

  console.warn('Warning unhandled entity type', ctx.entity);
  return false;
};
