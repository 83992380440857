import React from 'react';
import styled from 'styled-components';

const HrWrapper = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(p) => p.theme.lightGrey};
  margin: 33px 0 31px;
  overflow: visible;
  position: relative;
`;

const HrContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${(p) => p.bg};
  color: ${(p) => p.theme.blueGrey};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  padding: 0 20px;
  ${(p) => p.theme.antialias}
`;

const Hr = ({ style, children, bg = '#fff' }) => {
  return (
    <HrWrapper style={style}>
      {children && <HrContent bg={bg}>{children}</HrContent>}
    </HrWrapper>
  );
};

export default Hr;
