import { CompanyPreferences } from '@float/types/companyPreferences';

export const hasReachedPlaceholderSeatsLimit = (
  companyPrefs: CompanyPreferences | undefined,
  placeholdersToAdd: number = 0,
) => {
  if (!companyPrefs) return true;

  const {
    placeholder_limit: currentPlaceholderLimit,
    placeholder_people: currentPlaceholderCount,
  } = companyPrefs;

  if (placeholdersToAdd === 0) {
    return currentPlaceholderCount >= currentPlaceholderLimit;
  }

  return currentPlaceholderCount + placeholdersToAdd > currentPlaceholderLimit;
};
