import { useCallback } from 'react';

import { PROMPTS } from '@float/constants/prompts';
import { ProjectStatus } from '@float/types/project';
import { ProjectPlanViewSource } from '@float/web/lib/tracking/trackProjectPlanView';
import { useManageModal } from '@float/web/modalManager/useManageModal';
import { usePromptTracker } from '@float/web/OnboardingManager/hooks/usePromptTracker';

import { useApplyProjectPlanFilter } from './useApplyProjectPlanFilter';

export const useModalCreateDraftProject = () => {
  const [draftStatusProjectCreated, setDraftStatusProjectCreated] =
    usePromptTracker(PROMPTS.modalCreateDraftProject);
  const { manageModalFromPanel } = useManageModal();
  const applyProjectPlanFilter = useApplyProjectPlanFilter();
  const onConfirm = useCallback(
    (projectName: string) => {
      return () => {
        applyProjectPlanFilter(
          projectName,
          ProjectPlanViewSource.FirstDraftModal,
        );
      };
    },
    [applyProjectPlanFilter],
  );
  const showDraftStatusProjectCreatedModal = useCallback(
    (draftStatus: ProjectStatus, projectName: string) => {
      if (draftStatus === ProjectStatus.Draft && !draftStatusProjectCreated) {
        manageModalFromPanel({
          visible: true,
          modalType: 'ModalCreateDraftProject',
          modalSettings: {
            onConfirm: onConfirm(projectName),
          },
        });
        setDraftStatusProjectCreated();
      }
    },
    [
      draftStatusProjectCreated,
      manageModalFromPanel,
      onConfirm,
      setDraftStatusProjectCreated,
    ],
  );
  return showDraftStatusProjectCreatedModal;
};
