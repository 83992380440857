export const getTimestampDelta = (
  startTimestamp: number,
  endTimestamp: number,
): number => {
  const startTimestampToDate = new Date(startTimestamp);
  const endTimestampToDate = new Date(endTimestamp);

  // setting seconds and millisenconds to zero, because:
  // 1. we are not considering neither of them on the UI, so this makes deltas more predictable
  // 2. same thing is being done on the backend when resolving logged durations (e.g. after timers being created/edited/deleted)
  //
  // e.g. A timer started at 10:00am and finished up at 10:01am will have a delta of minute (00:01),
  // even if the timer was actually started at 10:00:50 and finished at 10:01:10
  // Related linear issues: FT-1748, FT-1692, FT-1685
  startTimestampToDate.setSeconds(0, 0);
  endTimestampToDate.setSeconds(0, 0);

  const delta =
    Math.abs(endTimestampToDate.getTime() - startTimestampToDate.getTime()) +
    Number.EPSILON;

  return delta;
};
