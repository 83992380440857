import React from 'react';
import { isArray, isEmpty, omit } from 'lodash';

import API3 from '@float/common/api3';
import { getTempId } from '@float/common/lib/scheduleUtils.js';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { Button, Checkbox, Input, Modal } from '@float/ui/deprecated';
import { InboundCalendarVersion } from '@float/web/pmSidebar/constants';

import { tryPersisting } from '../ProjectModal.helpers';
import { EmptyTaskList } from './components/EmptyTaskList';
import { MergeTaskNames } from './components/MergeTaskNames';
import { Task } from './components/Task';
import { getVisibleTasks } from './helpers/getVisibleTasks';
import { sortTaskNames } from './helpers/sortTaskNames';
import {
  Actions,
  InputWrapper,
  MultiSelectActions,
  ScrollableList,
  StyledCheckboxField,
  TasksHeader,
  Title,
} from './TasksFragment.styles';

function exists({ taskName, ignore, cb }) {
  const taskNameUpper = taskName.trim().toUpperCase();
  const found = this.state.form.newTaskNames.some(
    (e) =>
      e !== ignore &&
      e.task_name &&
      e.task_name.trim().toUpperCase() === taskNameUpper,
  );
  if (found) {
    this.props.confirm({
      title: 'Task name already exists',
      message:
        'This task name already exists for this project. Please update and try again.',
      confirmLabel: 'OK',
      hideCancel: true,
      onConfirm: cb,
    });
  }
  return found;
}

async function persistTaskMeta(t) {
  const { project, phase, projectId } = this.props.modalSettings;
  const project_id = projectId || (project && project.project_id);
  const phase_id = phase && phase.phase_id;
  let taskMeta = {
    ...t,
    project_id,
    phase_id,
    billable: Number(t.billable),
  };

  if (this.isAdding() || this.isTemplate()) {
    // adding a project / phase
    if (!taskMeta.isNew) {
      // set random id to enable editing task-meta in this scenario
      taskMeta.task_meta_id = getTempId();
      taskMeta.isNew = true;
    }
    return taskMeta;
  }

  return tryPersisting(
    async () => {
      taskMeta = t.task_meta_id
        ? await API3.updateTaskMeta(
            { task_name: t.task_name, billable: Number(t.billable) },
            t.task_meta_id,
          )
        : await API3.createTaskMeta(taskMeta);
      return taskMeta;
    },
    { showSnackbar: this.showMessage },
  );
}

async function addTask(evt) {
  prevent(evt);
  if (!this.state.taskAdd || this.state.taskAdd === '0') {
    this.setState({ taskAddErrors: [''] });
    return;
  }
  if (exists.call(this, { taskName: this.state.taskAdd })) {
    return;
  }

  const newTaskMeta = await persistTaskMeta.call(this, {
    task_name: this.state.taskAdd,
    billable: !this.state.form.non_billable, // TODO: use saved project non_billable?
  });

  if (newTaskMeta) {
    this.setState(
      (ps) => ({
        taskAdd: '',
        form: {
          ...ps.form,
          // TODO: Enable or remove based on product decision
          // ...(!this.isPhase
          //   ? {
          //       locked_task_list:
          //         ps.form.locked_task_list == 0 && !ps.form.newTaskNames.length
          //           ? 1
          //           : ps.form.locked_task_list,
          //     }
          //   : null),
          newTaskNames: [...ps.form.newTaskNames, newTaskMeta],
        },
      }),
      () => this.taskAddNameRef.current.focusInput(),
    );
  }
}

async function editTask(req) {
  const taskMeta = await persistTaskMeta.call(this, req);

  if (taskMeta) {
    this.setState((ps) => {
      const t = ps.form.newTaskNames.find(
        (e) => e.task_meta_id === taskMeta.task_meta_id,
      );

      const newTaskNames = [...ps.form.newTaskNames];
      const taskMetaWithCounts = { ...t, ...taskMeta };
      newTaskNames.splice(newTaskNames.indexOf(t), 1, taskMetaWithCounts);

      return { form: { ...ps.form, newTaskNames } };
    });
  }
}

export function getDeleteImpact(items) {
  let tasks;
  if (items) {
    tasks = isArray(items) ? items : [items];
  } else {
    // all tasks (e.g. project / phase delete)
    tasks = this.state.allTaskNames || this.state.form.newTaskNames;
  }
  let scheduledTasks = 0;
  let loggedTasks = 0;
  const isTimeTrackingEnabled = this.props.currentUser.time_tracking > 0;

  tasks.forEach((task) => {
    if (task.count_tasks) {
      scheduledTasks += +task.count_tasks;
    }
    if (isTimeTrackingEnabled && task.count_logged_time) {
      loggedTasks += +task.count_logged_time;
    }
  });

  return scheduledTasks || loggedTasks
    ? { scheduledTasks, loggedTasks }
    : false;
}

function removeTask(task) {
  const onDelete = async () => {
    const isLocalEdit = this.isAdding() || this.isTemplate();
    const taskMeta = isLocalEdit
      ? task
      : await tryPersisting(
          async () => {
            await API3.deleteTaskMeta(task.task_meta_id);
            return task;
          },
          { showSnackbar: this.showMessage },
        );
    if (taskMeta) {
      // remove task from selectedTasks
      let newSelectedTasks = {};
      if (this.state.selectedTasks[task.task_name]) {
        newSelectedTasks = {
          selectedTasks: omit(this.state.selectedTasks, task.task_name),
        };
      }

      return this.setState((ps) => ({
        form: {
          ...ps.form,
          newTaskNames: ps.form.newTaskNames.filter(
            (t) => t.task_meta_id !== task.task_meta_id,
          ),
        },
        ...newSelectedTasks,
      }));
    }
  };

  if (!this.isTaskNameInUse(task)) {
    return onDelete();
  }

  return this.props.confirmDelete({
    title: 'Delete in-use task name',
    item: task.task_name || 'No task name',
    impact: getDeleteImpact.call(
      this,
      task,
      this.props.currentUser.time_tracking > 0,
    ),
    onDelete,
  });
}

function handleChangeTaskAddName(evt) {
  this.setState({ taskAdd: evt.currentTarget.value, taskAddErrors: [] });
}

function onTaskKeyDown(evt) {
  if (evt.keyCode === 13) {
    addTask.call(this, evt);
  }
}

function selectionChange(taskName, isSelected) {
  this.setState((ps) => {
    let selectedTask;
    if (isSelected) {
      selectedTask = this.state.form.newTaskNames.find(
        (x) => x.task_name === taskName,
      );
    }
    const selectedTasks =
      isSelected && selectedTask
        ? { ...ps.selectedTasks, [taskName]: selectedTask }
        : omit(ps.selectedTasks, taskName);
    return { selectedTasks };
  });
}

function deSelectAll() {
  this.setState({ selectedTasks: {} });
}

function bulkDelete(e) {
  prevent(e);
  const tasks = Object.values(this.state.selectedTasks);
  const allIds = tasks.map((t) => t.task_meta_id);
  const impact = getDeleteImpact.call(this, tasks);
  const onDelete = async () => {
    const success = await tryPersisting(
      async () => {
        const persistedIds = tasks
          .filter((t) => !t.isNew)
          .map((t) => t.task_meta_id);
        if (!persistedIds.length) return true; // no need to hit api
        return await API3.bulkDeleteTaskMeta(persistedIds);
      },
      { showSnackbar: this.showMessage },
    );

    if (success) {
      return this.setState((ps) => ({
        selectedTasks: {},
        form: {
          ...ps.form,
          newTaskNames: ps.form.newTaskNames.filter(
            (t) => !allIds.includes(t.task_meta_id),
          ),
        },
      }));
    }
  };

  if (!impact) {
    // no existing tasks / logged times
    return onDelete();
  }

  return this.props.confirmDelete({
    title: 'Delete in-use task names',
    item: `${tasks.length} task names`,
    impact,
    onDelete,
  });
}

function renderTaskName(task, readOnly = false) {
  const { form, selectedTasks } = this.state;
  return (
    <Task
      currentUser={this.props.currentUser}
      task={task}
      isTaskNameInUse={this.isTaskNameInUse}
      isBillableProject={!form.non_billable}
      isSelected={selectedTasks[task.task_name]}
      isReadOnly={readOnly}
      key={task.task_name}
      exists={exists.bind(this)}
      onUpdate={editTask.bind(this)}
      onRemove={removeTask.bind(this)}
      onSelectionChange={selectionChange.bind(this)}
    />
  );
}

function afterMerge(result) {
  const ids = Object.values(this.state.selectedTasks).map(
    (t) => t.task_meta_id,
  );
  return this.setState((ps) => ({
    selectedTasks: {},
    form: {
      ...ps.form,
      newTaskNames: [
        ...ps.form.newTaskNames.filter((t) => !ids.includes(t.task_meta_id)),
        result,
      ],
    },
  }));
}

function mergeTaskNames(e) {
  prevent(e);
  const tasks = Object.values(this.state.selectedTasks);
  if (tasks.length < 2) {
    return;
  }

  this.setState({
    promptModal: (
      <MergeTaskNames
        tasks={tasks}
        renderTask={renderTaskName.bind(this)}
        onClose={this.closePromptModal}
        onSuccess={afterMerge.bind(this)}
      />
    ),
  });
}

function toggleLockedTaskList() {
  this.setState({
    form: {
      ...this.state.form,
      locked_task_list: this.state.form.locked_task_list == 0 ? 1 : 0,
    },
  });
}

export default function TaskFragment() {
  const {
    selectedTasks,
    form: { ext_calendar_count: extCalendarCount },
  } = this.state;
  const renderTask = renderTaskName.bind(this);
  const selectedCount = Object.keys(selectedTasks).length;
  const isLocked = this.state.form.locked_task_list == 1 ? true : false;
  const visibleTasks = getVisibleTasks(
    this.state.form.newTaskNames,
    this.props.currentUser,
  );

  const currentTasks = sortTaskNames(visibleTasks);

  const hasTasks = !isEmpty(currentTasks);

  // Only show to checkbox on projects that are not synced with calendar
  // or project synced with a v2 calendar integration.
  // Linear: https://linear.app/float-com/issue/FT-1275/bug-calendar-integration-locked-list-projects
  const isV2CalendarExt =
    this.props.calendarExt?.[0]?.version === InboundCalendarVersion.V2;
  const canShowLockTaskListCheckbox = !extCalendarCount || isV2CalendarExt;

  return (
    <>
      <Modal.TopGreySection style={{ display: 'flex', flexWrap: 'wrap' }}>
        <InputWrapper>
          <Input
            ref={this.taskAddNameRef}
            label="Add a task name"
            autoFocus
            maxLength={150}
            value={this.state.taskAdd}
            errors={this.state.taskAddErrors}
            onChange={handleChangeTaskAddName.bind(this)}
            onKeyDown={onTaskKeyDown.bind(this)}
          />
          <Button onClick={addTask.bind(this)}>Add task</Button>
        </InputWrapper>
        {canShowLockTaskListCheckbox && (
          <>
            <StyledCheckboxField
              label={
                this.isPhase
                  ? 'Phase task list locked from project'
                  : 'Only Project Managers can add to this list'
              }
              readOnly={this.isPhase}
              value={isLocked}
              style={{ opacity: this.isPhase ? 0.5 : 1 }}
              onChange={toggleLockedTaskList.bind(this)}
              calloutId="group-by-task-lock-task-list"
            />
          </>
        )}
      </Modal.TopGreySection>

      {!hasTasks ? (
        <EmptyTaskList entityType={this.isPhase ? 'phase' : 'project'} />
      ) : (
        <ScrollableList>
          <TasksHeader>
            {isEmpty(selectedTasks) ? (
              <Title>&nbsp;</Title>
            ) : (
              <MultiSelectActions>
                <Checkbox
                  semiChecked={selectedCount < currentTasks.length}
                  value={selectedCount === currentTasks.length}
                  label={`${selectedCount} selected`}
                  onChange={deSelectAll.bind(this)}
                />
                <Actions>
                  <Button
                    appearance="secondary"
                    size="small"
                    disabled={selectedCount < 2}
                    onClick={mergeTaskNames.bind(this)}
                  >
                    Merge
                  </Button>
                  <Button
                    appearance="secondary"
                    size="small"
                    onClick={bulkDelete.bind(this)}
                  >
                    Delete
                  </Button>
                </Actions>
              </MultiSelectActions>
            )}
          </TasksHeader>
          {currentTasks.map((task) => renderTask(task))}
        </ScrollableList>
      )}
    </>
  );
}
