import { formatToFloatDate } from './parse';

// @test-export
export class TodayManager {
  #value: string | null = null;

  updateToday() {
    this.#value = formatToFloatDate(new Date());

    return this.#value;
  }

  getToday() {
    if (this.#value === null) {
      return this.updateToday();
    }

    return this.#value;
  }

  isToday(date: string) {
    return this.getToday() === date;
  }

  #timeout: ReturnType<typeof setInterval> | null = null;

  startTodayAutoUpdate() {
    this.stopTodayAutoUpdate();

    // If the user leaves his browser open overnight, we need to update "today".
    this.#timeout = setInterval(() => this.updateToday(), 60 * 1000);
  }

  stopTodayAutoUpdate() {
    if (this.#timeout) clearInterval(this.#timeout);
  }
}

export const todayManager = new TodayManager();
