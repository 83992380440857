import { useCallback, useState } from 'react';

import { TimeoffPreviewMode } from '@float/types/timeoffType';

/**
 * A custom hook for managing the view mode of an accrued timeoff.
 * It can be used to switch between displaying timeoff information for today or a future date.
 *
 * @param {TimeoffPreviewMode} initialMode - The initial preview mode to set.
 * @returns {Object} An object containing the current preview mode and a function to toggle it.
 * @property {TimeoffPreviewMode} previewMode - The current preview mode (Today or Future).
 * @property {() => void} togglePreviewMode - A function to switch between Today and Future preview modes.
 */

export const usePreviewMode = (initialMode: TimeoffPreviewMode) => {
  const [previewMode, setPreviewMode] =
    useState<TimeoffPreviewMode>(initialMode);

  const togglePreviewMode = useCallback(() => {
    setPreviewMode((prevMode) =>
      prevMode === TimeoffPreviewMode.Today
        ? TimeoffPreviewMode.Future
        : TimeoffPreviewMode.Today,
    );
  }, []);

  return { previewMode, togglePreviewMode };
};
