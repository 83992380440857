import React from 'react';
import { Trans } from '@lingui/macro';

import * as styles from './statusToggleButton.css';

export const DraftStatusMessage = () => {
  return (
    <span className={styles.readOnlyDraftStatus}>
      <Trans>This project is in Draft status</Trans>
    </span>
  );
};
