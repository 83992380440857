import { connect } from 'react-redux';
import { WebAppGlobalState } from 'types';

import { startProTrial } from '@float/common/actions/companyPrefs';
import { getUser } from '@float/common/selectors/currentUser';

import { updateBillingPlan } from '../../actions/account/billingInfo';
import { PlanUpsellBanner as PlanUpsellBannerComponent } from './PlanUpsellBanner';

const mapStateToProps = (state: WebAppGlobalState) => ({
  user: getUser(state),
  companyId: state.currentUser.cid,
  billingInfo: state.settingsBillingInfo,
});

const mapDispatchToProps = {
  startProTrial,
  updateBillingPlan,
};

export const PlanUpsellBanner = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlanUpsellBannerComponent);
