import React from 'react';
import styled, { ThemeConsumer } from 'styled-components';

import { TextTooltip } from '@float/ui/deprecated/Tooltip/TextTooltip';

const Svg = styled.svg`
  cursor: pointer;
  border-radius: 6px;

  &:hover {
    background-color: ${(p) => p.theme.flayLight5};
  }
  &:active {
    background-color: ${(p) => p.theme.flayLight7};
  }
  &:focus {
    outline: none;
  }
`;

/**
 *
 * @param {*} param0
 */
const IconCloseDynamic = ({
  onClick,
  hint = 'Cancel',
  color,
  strokeWidth = '1.5',
}) => {
  return (
    <ThemeConsumer>
      {(theme) => (
        <TextTooltip
          key="hint"
          className="hint"
          content={hint}
          placement="bottom"
          distance={10}
        >
          <Svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
          >
            <path
              d="M21.25 10.75L10.75 21.25"
              stroke={color ?? theme.emphasisHigh}
              strokeWidth={strokeWidth}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.75 10.75L21.25 21.25"
              stroke={color ?? theme.emphasisHigh}
              strokeWidth={strokeWidth}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </Svg>
        </TextTooltip>
      )}
    </ThemeConsumer>
  );
};

export default IconCloseDynamic;
