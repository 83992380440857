import { useEffect, useReducer } from 'react';
import { useUpdateEffect } from 'react-use';

import { useAppDispatchStrict, useAppStore } from '@float/common/store';

import { getPhaseData } from '../helpers/getPhaseData';
import { fetchPhaseData } from '../store/actions';
import { Action, reducer, State } from '../store/reducer';
import { PhaseEditData } from '../types';

type PhaseStateType = ReturnType<typeof getPhaseData>;

/**
 *
 * This hook fetches the phase data in one go.
 *
 * It doesn't subscribes to the Redux state because updating the returned data
 * triggeres a state reset on the form component.
 */
export function useGetPhaseData(ids?: PhaseEditData) {
  const store = useAppStore();
  const reduxDispatch = useAppDispatchStrict();

  const [state, dispatch] = useReducer<
    React.Reducer<State<PhaseStateType>, Action<PhaseStateType>>,
    PhaseEditData | undefined
  >(reducer<PhaseStateType>, ids, (ids) => ({
    data: getPhaseData(store, ids),
    status: ids?.projectId || ids?.phaseId ? 'loading' : 'loaded',
  }));

  useUpdateEffect(() => {
    if (ids?.phaseId && ids?.projectId) return;

    // Quick path when navigating to a new phase
    dispatch({
      type: 'LOADED',
      payload: getPhaseData(store, ids),
    });
  }, [ids]);

  useEffect(() => {
    if (!ids?.phaseId && !ids?.projectId) return;

    let canceled = false;

    const load = async () => {
      dispatch({
        type: 'LOADING',
      });

      try {
        await fetchPhaseData(reduxDispatch, ids);

        // Check to avoid out-of-order updates
        if (!canceled) {
          dispatch({
            type: 'LOADED',
            payload: getPhaseData(store, ids),
          });
        }
      } catch (err) {
        dispatch({
          type: 'ERROR',
        });
      }
    };

    load();

    return () => {
      canceled = true;
    };
  }, [ids, store, reduxDispatch]);

  return {
    data: state.data,
    isLoading: state.status === 'loading',
    isError: state.status === 'error',
  };
}
