import { Phase, Project } from '@float/types';

export type IsProjectOrPhaseTentativeParams = {
  phaseId?: number;
  project?: Project;
};
export type IsProjectOrPhaseTentativeState = {
  phaseId?: number;
  project?: Project;
};
export type IsProjectOrPhaseTentativeProps = {
  phasesOptions: Record<number, Phase[]>;
};

export const isProjectOrPhaseTentative = (
  params: IsProjectOrPhaseTentativeParams,
  state: IsProjectOrPhaseTentativeState,
  props: IsProjectOrPhaseTentativeProps,
) => {
  const phaseId = params.phaseId ?? state.phaseId;

  // if a phase exists, infer on the basis of phase alone
  if (phaseId) {
    return isPhaseTentative(params, state, props);
  }

  return isProjectTentative(params, state);
};

export const isProjectTentative = (
  params: { project?: Project },
  state: { project?: Project },
) => {
  const project = params.project ?? state.project;

  if (!project) return false;

  return Boolean(project.tentative);
};

export const isPhaseTentative = (
  params: IsProjectOrPhaseTentativeParams,
  state: IsProjectOrPhaseTentativeState,
  props: IsProjectOrPhaseTentativeProps,
) => {
  const phaseId = params.phaseId ?? state.phaseId;
  const project = params.project ?? state.project;

  if (!project) return false;

  const phases = props.phasesOptions[project.project_id] || [];

  const phase = phases.find((x) => Number(x.phase_id) === Number(phaseId));

  if (!phase) return false;

  return Boolean(phase.tentative);
};
