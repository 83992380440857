import React from 'react';

import * as styles from './styles';

export const Grid = ({ children, Empty = null, AddNew = null, collapsed }) => {
  return (
    <styles.Grid collapsed={collapsed} className="grid">
      {children}
      {AddNew && <AddNew />}
      {!children.length && <Empty />}
    </styles.Grid>
  );
};

export const GridItem = styles.GridItem;
export const GridItemText = styles.GridItemText;

export default Grid;
