const toTextFragment = (text) => ({
  text,
});

const toLinkFragment = (link) => ({
  type: 'link',
  href: link.href,
  children: [{ text: link.value }],
});

export { toTextFragment, toLinkFragment };
