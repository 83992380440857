import { trackEvent } from '@float/common/lib/analytics';
import { Person, Phase, Project, Role } from '@float/types';

// Payload spec is described in this issue
// https://linear.app/float-com/issue/BDG-859/implement-event-tracking-on-default-hourly-role-rates
export type TrackRateChangesPayload = {
  from_rate: string | null;
  to_rate: string | null;
  scope: 'role' | 'person' | 'project' | 'phase';
  page:
    | 'roles management'
    | 'person modal'
    | 'project side panel'
    | 'phase modal';
  role_id?: Role['id'];
  person_id?: Person['people_id'];
  project_id?: Project['project_id'];
  phase_id?: Phase['phase_id'];
};

export function trackRateChanges(payload: TrackRateChangesPayload) {
  if (payload.from_rate === payload.to_rate) return;

  trackEvent('Set hourly rate', payload);
}
