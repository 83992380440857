export const computeManagersByPeopleId = (
  accountManagedPeople: Record<number, number[]>,
) => {
  return Object.entries(accountManagedPeople).reduce(
    (managersForPerson, [managerId, managedPeopleIds]) => {
      managedPeopleIds.forEach((personId) => {
        managersForPerson[personId] = managersForPerson[personId] || [];

        const accountId = parseInt(managerId);

        managersForPerson[personId].push(accountId);
      });

      return managersForPerson;
    },
    {} as Record<number, number[]>,
  );
};
