import { useCallback, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { isLegacyPlaceholderType } from '@float/common/lib/people/isLegacyPlaceholderType';
import { Person } from '@float/types/person';

import { getPersonTypeOptions } from '../helpers/getPersonTypeOptions';
import { PersonTypeOption } from '../types';
import { usePlaceholderOptionState } from './usePlaceholderOptionState';

/**
 * Custom hook to manage the person types based on the given initial and current person's information.
 * This hook calculates and provides the person types available for selection, considering the base person type options
 * and whether the placeholder option should be disabled.
 *
 * The business logic is in the `usePlaceholderOptionState` hook.
 *
 * @param {Object} props - The props object containing the basePersonTypeOptions, initialPerson, and current person properties.
 * @param {PersonTypeOption[]} props.basePersonTypeOptions - The base options for person types.
 * @param {Person} props.initialPerson - The person object representing the initial state.
 * @param {Person} props.person - The person object representing the current state.
 * @returns {Object} An object containing the available personTypes.
 */

export type UsePersonTypesArgs = {
  basePersonTypeOptions: PersonTypeOption[];
  initialPerson: Person;
  person: Person;
};

export const usePersonTypes = (props: UsePersonTypesArgs) => {
  const { person, basePersonTypeOptions, initialPerson } = props;

  const isPersonLegacyPlaceholderType = isLegacyPlaceholderType({
    person: initialPerson,
  });
  const { isPlaceholderOptionEnabled } = usePlaceholderOptionState({
    person,
    initialPerson,
  });

  const showPlaceholderLegacyAlert = isPersonLegacyPlaceholderType;

  const refreshPersonTypesOptions = useCallback(() => {
    return getPersonTypeOptions({
      basePersonTypeOptions,
      isPlaceholderOptionEnabled,
      isLegacyPlaceholder: isPersonLegacyPlaceholderType,
    });
  }, [
    basePersonTypeOptions,
    isPlaceholderOptionEnabled,
    isPersonLegacyPlaceholderType,
  ]);

  const [personTypes, setPersonTypes] = useState(refreshPersonTypesOptions);

  useUpdateEffect(() => {
    setPersonTypes(refreshPersonTypesOptions);
  }, [refreshPersonTypesOptions]);

  return {
    personTypes,
    showPlaceholderLegacyAlert,
  };
};
