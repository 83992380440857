import { PeopleProjectViewStatus } from '@float/common/actions';
import { Person } from '@float/types/person';
import { Project } from '@float/types/project';

export const getFilteredProjects = (
  projects: Array<Project>,
  projectsView: PeopleProjectViewStatus,
  isMyProjectsOnly: boolean,
  accountId: Person['account_id'],
) => {
  const filteredProjectsCount = { active: 0, archived: 0 };

  const filteredProjects = projects.filter((project) => {
    const isMyProject = project.project_manager === accountId;
    const isActive = project.active;
    const isArchived = !project.active;

    if (isMyProjectsOnly && !isMyProject) return false;

    if (isActive) filteredProjectsCount.active++;
    if (isArchived) filteredProjectsCount.archived++;

    if (projectsView === PeopleProjectViewStatus.Active && isArchived)
      return false;
    if (projectsView === PeopleProjectViewStatus.Archived && isActive)
      return false;

    return true;
  });

  return { filteredProjects, filteredProjectsCount };
};
