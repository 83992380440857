import { memoize } from 'proxy-memoize';

import { ReduxState } from '@float/common/reducers/lib/types';

export const getUserHasInteractedWithViews = memoize((state: ReduxState) => {
  const accountId = state.currentUser.account_id;
  const views = state.views?.views || [];

  return views.some(
    (view) =>
      view.created_by === accountId ||
      view.modified_by === accountId ||
      view.lastUsed,
  );
});
