import styled, { css } from 'styled-components';

import * as Animation from '../Earhart/Animation';
import * as Colors from '../Earhart/Colors';
import * as Elevations from '../Earhart/Elevations';
import * as Typography from '../Earhart/Typography';

export const StyledDropdownLabel = styled.button`
  ${Typography.Label14.SB600};

  display: ${({ truncateAt }) => (truncateAt ? 'flex' : 'inline-flex')};
  align-items: center;
  justify-content: ${({ alignment }) => {
    if (alignment === 'left') return 'flex-start';
    if (alignment === 'center') return 'center';
    if (alignment === 'right') return 'flex-end';
  }};

  max-width: ${({ truncateAt }) => truncateAt && `${truncateAt}px`};

  color: ${Colors.FIN.Lt.Buttons.Secondary.Default.Label};
  background-color: ${Colors.FIN.Lt.Buttons.Secondary.Default.Bg};

  border-radius: 5px;

  padding: 10px 16px;

  transition-property: background-color, color;
  transition-duration: ${Animation.Settings.Duration.Short};
  transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

  user-select: none;

  svg {
    --svg-icon-color: ${Colors.FIN.Lt.Buttons.Secondary.Default.Label};
  }

  &:hover,
  &:focus {
    background-color: ${Colors.FIN.Lt.Buttons.Secondary.Hover.Bg};
  }

  &:active,
  &.active {
    background-color: ${Colors.FIN.Lt.Buttons.Secondary.Pressed.Bg};
  }
`;

export const StyledIcon = styled.span`
  position: relative;

  display: inline-block;

  width: 13px;
  height: 13px;

  margin-left: 5px;

  svg {
    position: absolute;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
  }
`;

export const StyledDropdownLabelText = styled.span`
  display: flex;
  align-items: center;

  ${({ truncateAt }) =>
    truncateAt &&
    css`
      display: inline;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

export const StyledDropdownOptions = styled.div`
  ${Elevations.E150};

  position: absolute;

  display: inline-flex;
  flex-direction: column;

  ${({ minSelectWidth }) => minSelectWidth && `min-width: ${minSelectWidth}px;`}

  border-radius: 6px;

  background-color: ${Colors.FIN.Lt.Emphasis.White};

  padding: 6px;

  opacity: 1;

  transition-property: transform, opacity;
  transition-duration: ${Animation.Settings.Duration.Short};
  transition-timing-function: ${Animation.Settings.Easing.InOutBounce};

  white-space: nowrap;

  z-index: 1000003;

  &.top-left {
    right: 0;
    top: 0;
    transform: translate(0, -100%);
  }

  &.bottom {
    left: 0;
    bottom: 0;
    transform: translate(0, 100%);
  }

  &.bottom-left {
    right: 0;
    bottom: ${(p) => (p.distance ? -1 * p.distance : 0)}px;
    transform: translate(0, 100%);
  }
`;

export const StyledDropdownOptionContent = styled.div`
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  z-index: 1;

  svg {
    --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Medium};

    transition-property: transform, opacity;
    transition-duration: ${Animation.Settings.Duration.Short};
    transition-timing-function: ${Animation.Settings.Easing.InOutBounce};
  }
`;

export const StyledDropdownOption = styled.div`
  ${Typography.Label14.M500};

  position: relative;

  border-radius: 6px;

  cursor: pointer;

  transition-property: color;
  transition-duration: ${Animation.Settings.Duration.Short};
  transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

  ${(p) => p.theme.ie11MinHeightFix}

  & + & {
    margin-top: 4px;
  }

  &:after {
    content: '';

    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border-radius: 6px;

    transition-property: transform, background-color, opacity;
    transition-duration: ${Animation.Settings.Duration.Normal},
      ${Animation.Settings.Duration.Short}, ${Animation.Settings.Duration.Short};
    transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

    opacity: 0;

    transform: scale(0.9);

    z-index: -1;
  }

  &:hover,
  &:focus {
    &:after {
      opacity: 1;
      transform: none;
    }
  }

  &.selected-value {
    ${StyledDropdownOptionContent} {
      svg {
        opacity: 1;

        transform: none;
      }
    }
  }

  a,
  a:link,
  a:visited {
    display: flex;
    flex-basis: 100%;
    color: ${(p) => p.theme.charcoalGrey};
    margin: 0 -15px;
    padding: 0 15px;
    min-height: 40px;
    align-items: center;
    ${(p) => p.theme.ie11MinHeightFix}
  }

  // appearances

  &.flay {
    color: ${Colors.FIN.Lt.Emphasis.Medium};

    background-color: rgba(0, 0, 0, 0);

    svg {
      --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Medium};
    }

    &:after {
      background-color: ${Colors.FIN.Lt.Surface.Surf4};
    }

    &:hover,
    &:focus {
      color: ${Colors.FIN.Lt.Emphasis.High};
    }

    &:active {
      color: ${Colors.FIN.Lt.Emphasis.High};

      &:after {
        background-color: ${Colors.FIN.Lt.Surface.Surf6};
      }
    }

    &.selected-value {
      color: ${Colors.FIN.Lt.Emphasis.Primary};

      background-color: rgba(0, 0, 0, 0);
    }
  }

  &.danger {
    color: ${Colors.Secondary.Red.Light[9]};

    background-color: rgba(0, 0, 0, 0);

    svg {
      --svg-icon-color: ${Colors.Secondary.Red.Light[9]};
    }

    &:after {
      background-color: ${Colors.Secondary.Red.Light[4]};
    }

    &:hover,
    &:focus {
      color: ${Colors.Secondary.Red.Light[9]};
    }

    &:active {
      color: ${Colors.Secondary.Red.Light[11]};

      &:after {
        background-color: ${Colors.Secondary.Red.Light[6]};
      }
    }
  }

  // if a color is provided in the option object,
  // use it to override the icon color
  svg {
    ${({ color }) =>
      color &&
      css`
        --svg-icon-color: ${color};
      `};
  }
`;

export const IconRight = styled.div`
  margin-left: auto;
  display: flex;
`;

export const IconLeft = styled.div`
  display: flex;
  align-items: center;
  svg {
    opacity: 1;
    transform: scale(1);
    margin-left: 0px;
  }
`;

export const StyledDropdownSelect = styled.div`
  position: relative;

  ${({ $appearance }) =>
    $appearance === 'clear' &&
    css`
      ${StyledDropdownLabel} {
        color: ${Colors.FIN.Lt.Buttons.Clear.Default.Label};
        background-color: ${Colors.FIN.Lt.Buttons.Clear.Default.Bg};

        svg {
          --svg-icon-color: ${Colors.FIN.Lt.Buttons.Clear.Default.Label};
        }

        &.withIconLeft {
          padding-left: 8px;
        }

        &.withIconRight {
          padding-right: 8px;
        }

        &:hover,
        &:focus {
          color: ${Colors.FIN.Lt.Buttons.Clear.Hover.Label};
          background-color: ${Colors.FIN.Lt.Buttons.Clear.Hover.Bg};

          svg {
            --svg-icon-color: ${Colors.FIN.Lt.Buttons.Clear.Hover.Label};
          }
        }

        &:active,
        &.active {
          color: ${Colors.FIN.Lt.Buttons.Clear.Pressed.Label};
          background-color: ${Colors.FIN.Lt.Buttons.Clear.Pressed.Bg};

          svg {
            --svg-icon-color: ${Colors.FIN.Lt.Buttons.Clear.Pressed.Label};
          }
        }
      }
    `};

  ${({ $appearance }) =>
    $appearance === 'text' &&
    css`
      ${StyledDropdownLabel} {
        ${Typography.Label12.SB600};

        color: ${Colors.FIN.Lt.Emphasis.Medium};
        background-color: rgba(0, 0, 0, 0);

        padding: 2px 6px;

        border-radius: 4px;

        ${StyledIcon} {
          margin-left: 2px;

          svg {
            --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Medium};

            width: 16px;
            height: 16px;

            min-width: 16px;
            min-height: 16px;
          }
        }

        &.withIconLeft {
          padding-left: 3px;
        }

        &.withIconRight {
          padding-right: 3px;
        }

        &:hover,
        &:focus {
          color: ${Colors.FIN.Lt.Emphasis.High};
          background-color: ${Colors.FIN.Lt.Buttons.Secondary.Hover.Bg};

          svg {
            --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Medium};
          }
        }

        &:active,
        &.active {
          color: ${Colors.FIN.Lt.Emphasis.High};
          background-color: ${Colors.FIN.Lt.Buttons.Secondary.Pressed.Bg};

          svg {
            --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Medium};
          }
        }
      }
    `};

  ${({ $appearance }) =>
    $appearance === 'empty' &&
    css`
      ${StyledDropdownLabel} {
        background-color: rgba(0, 0, 0, 0);

        padding: 0;

        ${StyledIcon} {
          margin-left: 0;
        }

        &.withIconLeft {
          padding-left: 0;
        }

        &.withIconRight {
          padding-right: 0;
        }

        &:hover,
        &:focus {
          background-color: rgba(0, 0, 0, 0);

          svg {
            --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Medium};
          }
        }

        &:active,
        &.active {
          background-color: rgba(0, 0, 0, 0);

          svg {
            --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Medium};
          }
        }
      }
    `};
`;
