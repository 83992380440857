import React from 'react';

import PreferencesForm from '../../containers/Preferences/PreferencesForm';
import Body from '../Body';

const Preferences = () => (
  <Body
    header="Hours &amp; currency"
    subheader="Update your team's scheduled working hours."
  >
    <PreferencesForm />
  </Body>
);

export default Preferences;
