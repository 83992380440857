import { t } from '@lingui/macro';

import { trimTextForReadibility } from '@float/libs/utils/string/trimTextForReadibility';
import { ProjectTemplate } from '@float/types/project';
import { SnackbarMethods } from '@float/ui/deprecated/Snackbar/useSnackbar';
import { IWithModalConfirmDelete } from '@float/web/modalManager/hoc/withModalConfirmDelete';

export const showModalConfirmTemplateDelete = (
  template: ProjectTemplate,
  confirmDelete: IWithModalConfirmDelete['confirmDelete'],
  confirmDeleteClose: () => void,
  deleteProjectTemplate: (
    templateId: ProjectTemplate['project_template_id'],
  ) => Promise<void>,
  showSnackbar: SnackbarMethods['showSnackbar'],
) => {
  const templateName = template.project_name;

  const onDelete = async () => {
    return new Promise<void>((resolve, reject) => {
      (async () => {
        try {
          await deleteProjectTemplate(template.project_template_id);
          confirmDeleteClose();
          showSnackbar(`${template.project_name} deleted.`);
          return resolve();
        } catch (err) {
          return reject(err);
        }
      })();
    });
  };

  const templateNameTrimmed = trimTextForReadibility(templateName, 22).value;

  confirmDelete({
    deleteLabel: t`Delete ${templateNameTrimmed}`,
    description: t`Existing projects created from this template will be unaffected.`,
    onDelete,
    title: t`Delete project template "${templateName}"?`,
    twoStep: false,
  });
};
