export enum IntegrationName {
  Asana = 'asana',
  Jira = 'jira',
  Teamwork = 'teamwork',
  Trello = 'trello',
}
export const ALL_INTEGRATIONS = Object.values(IntegrationName);
export type IntegrationStatus = Partial<Record<IntegrationName, number>>;
type IntegrationTenant = {
  tenantId: string;
  shortCode: string;
  tenantName: string;
};
type AncilllaryIntegrationError = {
  errors: {
    API_UNAUTHORIZED: boolean;
    TIME_SYNC_PRECONDITION_FAILED: boolean;
  };
  baseUrl: string;
  tenants: IntegrationTenant[];
  lastHash: string;
  last_action: string;
  import_stats: {
    people: number;
    projects: number;
  };
  reauthRetryCount: 0;
};
type CompanyIntegration = {
  account_id: number;
  ancillary: AncilllaryIntegrationError;
  company_id: number;
  created: string;
  integrations_co_id: number;
  modified: string;
  type: IntegrationName;
  status: number;
  synced: string;
};
type IntegrationConfig = {
  __v: number;
  _id: string;
  accountId: string;
  companyId: string;
  coIntId: number;
  created: string;
  futurePeople: boolean;
  futureProjects: boolean;
  peopleChoices: string[];
  peopleExcluded: string[];
  projectChoices: string[];
  projectsExcluded: string[];
  syncTime: boolean;
  updated: string;
};
type IntegrationLoadStates = 'LOADING' | 'LOAD_SUCCESS' | 'LOAD_FAILED';
export type Integration = {
  calendarList: any;
  coInts: CompanyIntegration[];
  coIntsLoadState: IntegrationLoadStates;
  config: IntegrationConfig;
  oauthLink: string;
  oauthLinks: any;
  oauthLinkLoadState: IntegrationLoadStates;
  preloadData: any;
  preloadError: any;
  preloadLoadState: IntegrationLoadStates;
  removeCoIntLoadState: IntegrationLoadStates;
  retryPeopleSyncLoadState: IntegrationLoadStates;
  updateConfigLoadState: IntegrationLoadStates;
};
