import React from 'react';

export default ({ variant = 'regular', size = 24, stroke = '#173074' }) => {
  const strokeWidth = variant === 'bold' ? 2.5 : 1.5;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="plus-icon"
    >
      <path
        className="stroke"
        d="M12 5.75V18.25"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M18.25 12H5.75"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
