import { StyleRule } from '@vanilla-extract/css';
import { css } from 'styled-components';

export const E150 = css`
  box-shadow: 0px 1px 10px rgba(37, 40, 43, 0.12);
`;

export const VE_E150: StyleRule = {
  boxShadow: '0px 1px 10px rgba(37, 40, 43, 0.12)',
};
