import request from '@float/common/lib/request';

export const submitImportData = ({ data, type, processId }) => {
  if (processId) {
    return request.put(`csv-imports/${type}/${processId}`, data, {
      version: 'f3',
    });
  }

  return request.post(`csv-imports/${type}`, data, {
    version: 'f3',
  });
};
