import { useEffect } from 'react';
import * as amplitude from '@amplitude/analytics-browser';

import { CompanyPreferences } from '@float/types/companyPreferences';
import { PageData } from '@float/types/pageData';

import { pushValueToGTM } from './googleTagManager';

export const useIdentifyUserToTrackingServices = (
  user?: PageData['user']['user'],
  companyPrefs?: CompanyPreferences,
) => {
  const accountId = user?.admin_id;

  useEffect(() => {
    if (user && companyPrefs) {
      pushValueToGTM({
        user_id: accountId,
      });

      window.Canny('identify', {
        appID: '61a572bc5763fd730d8d83b0',
        user: {
          email: user.email,
          name: user.name,
          id: Number(user.admin_id),
        },
      });

      amplitude.setUserId(user.admin_id + '-' + user.cid);

      const identifyEvent = new amplitude.Identify();

      identifyEvent.set('accountType', user.account_type_name ?? '');
      identifyEvent.set(
        'accountCreated',
        new Date(Number(user.created) * 1000).toString(),
      );
      identifyEvent.set('companyName', companyPrefs.company_name);
      identifyEvent.set('companyHostname', companyPrefs.company_hostname);

      amplitude.identify(identifyEvent);
    }
    // Passing the full `user` objects triggers an evaluation
    // every re-run so we check against admin_id and cid instead
  }, [user?.admin_id, user?.cid]);
};
