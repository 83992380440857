import { accumulateDecimalHours } from '@float/common/lib/timer/accumulateDecimalHours';
import { matchFilteredEntity } from '@float/common/search/helpers/matchFilteredEntity';
import { SerenaState } from '@float/common/selectors/serena';
import { config } from '@float/libs/config';
import { LoggedTimeCell, PersonCell } from '@float/types';

type CellItem = (PersonCell | LoggedTimeCell)['items'][0];

export function getLoggedTimeHours(
  items: CellItem[] | undefined,
  reduxData: Pick<SerenaState, 'filters' | 'filteredEntities'>,
  subCol?: number,
) {
  if (!items) return { logged: 0, unlogged: 0, tracked: 0 };

  function shouldCount(item: CellItem) {
    if (item.type !== 'loggedTime') return false;

    if (typeof subCol !== 'undefined') {
      if (item.x !== subCol) return false;
    }

    const key = item.entity.isTaskReference
      ? config.isNativeTimerApp
        ? 'tracked'
        : 'unlogged'
      : 'logged';

    if (!reduxData.filters.length || key === 'unlogged') {
      return key;
    }

    if (
      key === 'logged' &&
      matchFilteredEntity(reduxData.filteredEntities, item.type, item.entityId)
    ) {
      return key;
    }

    return false;
  }

  const result = { logged: 0, unlogged: 0, tracked: 0 };
  items.forEach((item) => {
    const key = shouldCount(item);

    const { entity } = item;

    if (key) {
      if ('hours' in entity && entity.hours) {
        result[key] = accumulateDecimalHours(result[key], entity.hours);
      }
    }
  });

  return result;
}
