import React from 'react';
import { t, Trans } from '@lingui/macro';
import cn from 'classnames';

import { stopPropagation } from '@float/libs/utils/events/stopPropagation';
import { Button } from '@float/ui/components/Button';
import { FloatLogo } from '@float/ui/components/FloatLogo';
import { TextToggle } from '@float/ui/deprecated/Earhart/Toggles';
import { TextToggleOption } from '@float/ui/deprecated/Earhart/Toggles/TextToggle';
import { IconKebabDots } from '@float/ui/icons/essentials/IconKebabDots';

import { NotificationsEmpty } from './components/NotificationsEmpty';
import { NotificationsList } from './components/NotificationsList';
import { useNotificationsData } from './hooks/useNotificationsData';
import { useNotificationsPanelController } from './hooks/useNotificationsPanelController';
import { NotificationsFilter } from './NotificationsPanel.types';

import * as styles from './NotificationsPanelView.css';

const options: TextToggleOption<NotificationsFilter>[] = [
  { value: NotificationsFilter.All, label: 'All' },
  { value: NotificationsFilter.Requests, label: 'Requests' },
];

type NotificationController = ReturnType<
  typeof useNotificationsPanelController
>;
type NotificationData = ReturnType<typeof useNotificationsData>;

type Props = {
  className?: string;
  currentFilter: NotificationController['currentFilter'];
  isFilterVisible: NotificationController['isFilterVisible'];
  isLoadingFirstPage: NotificationData['isLoadingFirstPage'];
  loadMore?: () => void;
  notifications: NotificationData['notifications'];
  onClickMore: NotificationController['onClickMore'];
  onClickNotification: NotificationController['onClickNotification'];
  onFilterUpdate: NotificationController['onFilterUpdate'];
};

export const NotificationsPanelView = (props: Props) => {
  const {
    className,
    currentFilter,
    isFilterVisible,
    isLoadingFirstPage,
    loadMore,
    notifications,
    onClickMore,
    onClickNotification,
    onFilterUpdate,
  } = props;

  const isEmpty = !isLoadingFirstPage && notifications.length === 0;
  const isLoaded = !isLoadingFirstPage && !isEmpty;

  return (
    <div className={cn(className, styles.notificationsPanelView)}>
      <div className={styles.header}>
        <h4 className={styles.title}>
          <Trans>Notifications</Trans>
        </h4>

        <div className={styles.headerRight}>
          {isFilterVisible && (
            <TextToggle<NotificationsFilter>
              onChange={onFilterUpdate}
              onClick={stopPropagation}
              options={options}
              toggle={true}
              value={currentFilter}
              variant="tabSwitch"
            />
          )}

          <Button
            appearance="clear-flay"
            aria-label={t`notifications settings`}
            iconEnd={IconKebabDots}
            onClick={onClickMore}
            size="icon"
          />
        </div>
      </div>

      <div className={styles.notificationsPanelViewContent}>
        {isLoadingFirstPage && <FloatLogo width={60} wordmark={false} />}
        {isEmpty && <NotificationsEmpty filter={currentFilter} />}
        {isLoaded && (
          <NotificationsList
            loadMore={loadMore}
            notifications={notifications}
            onClickNotification={onClickNotification}
          />
        )}
      </div>
    </div>
  );
};
