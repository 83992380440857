import { FieldError } from 'react-hook-form';

/**
 * Takes a single `FieldError` from `react-hook-form` and formats
 * so that it can easily be consumed by the Input, which expects
 * errors to be an array of error message strings
 */
export const adaptReactHookFormFieldError = (
  error: FieldError | undefined,
): string[] | undefined => {
  if (!error?.message) {
    return undefined;
  }

  return [error.message];
};
