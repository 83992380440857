import React from 'react';

import BaseIcon, { BaseIconProps } from './BaseIcon';

const IconWatch = (props: BaseIconProps) => (
  <BaseIcon {...props} viewBox="0 0 24 24">
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m17 7 .75-.75M12 4h2.25M12 5.75V4v1.75ZM12 4H9.75 12Zm-.29 5.48v3.74l2.53 2.59m5.01-2.56a7.25 7.25 0 1 1-14.5 0 7.25 7.25 0 0 1 14.5 0Z"
    />
  </BaseIcon>
);

export { IconWatch };
