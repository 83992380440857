import { MouseEventHandler } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { t } from '@lingui/macro';

import { useFieldArrayContext } from '../../hooks/useFieldArrayContext';
import { useIsPhasePanel } from '../../hooks/useIsPhasePanel';
import { ProjectFormData } from '../../types';
import { useSelectedTasks, useTaskDeleteImpact } from './index.hooks';

export const useBulkDelete = ({ onImpacted }: { onImpacted: () => void }) => {
  const tasks = useWatch<ProjectFormData, 'tasks'>({
    name: 'tasks',
  });
  const { remove } = useFieldArrayContext<ProjectFormData, 'tasks'>();
  const selected = useSelectedTasks(tasks);
  const impact = useTaskDeleteImpact(selected.tasks);
  const onBulkDelete = () => {
    remove(selected.indices);
  };
  const onClickDeleteAll: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    if (impact) {
      return onImpacted();
    }
    onBulkDelete();
  };

  return onClickDeleteAll;
};

export const useDeleteTaskTooltip = (allocationsImpacted: boolean) => {
  const { getValues } = useFormContext<ProjectFormData>();
  const isPhasePanel = useIsPhasePanel();
  const parent = isPhasePanel ? 'Phase' : 'Project';
  const parentActive = getValues(
    `${parent.toLowerCase() as 'phase' | 'project'}.active`,
  );
  if (!parentActive)
    return {
      disabledTooltipActive: undefined,
      disabledTooltipContent: t`${parent} is archived`,
    };
  if (allocationsImpacted)
    return {
      disabledTooltipActive: undefined,
      disabledTooltipContent: t`Bulk delete is not available for tasks with assigned time`,
    };
  return {
    disabledTooltipActive: false,
    disabledTooltipContent: '',
  };
};
