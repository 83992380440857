import { PAGE_LOAD_IDLE_CALLBACK_TIMEOUT } from './constants';
import { performanceMeasure } from './helpers';
import { trackResourceTimings } from './resources';
import { RumEventCallback } from './types';

type ViewLoadedDetail = {
  viewName: string;
};

export function dispatchViewLoad(viewName: string) {
  // Wait for the pending tasks to be completed
  requestIdleCallback(
    () => {
      dispatchEvent(
        new CustomEvent('view-loaded', {
          detail: {
            viewName,
          },
        }),
      );
    },
    {
      // We add a timeout to avoid that the idle callback is delayed too much
      timeout: PAGE_LOAD_IDLE_CALLBACK_TIMEOUT,
    },
  );
}

declare global {
  function addEventListener(
    type: 'view-loaded',
    listener: (event: CustomEvent<ViewLoadedDetail>) => void,
  ): void;
  function removeEventListener(
    type: 'view-loaded',
    listener: (event: CustomEvent<ViewLoadedDetail>) => void,
  ): void;
}

export function trackPageLoad(callback: RumEventCallback) {
  const initialPage = location.pathname;

  function handleViewLoaded(evt: CustomEvent<ViewLoadedDetail>) {
    const viewName = evt.detail.viewName;

    if (location.pathname === initialPage && viewName) {
      // Time elapsed since time origin https://developer.mozilla.org/en-US/docs/Web/API/DOMHighResTimeStamp#the_time_origin
      const value = performance.now();

      // Using measure to make the load time visible on the profiler
      performanceMeasure(`${viewName}:load`, 'navigationStart');

      callback({
        name: `${viewName}:load`,
        value,
        delta: value,
      });
    }

    unsubscribe();
  }

  // Track the resources to get a better insights of the page load times
  const unsubscribeFromResources = trackResourceTimings(callback);

  addEventListener('view-loaded', handleViewLoaded);

  const unsubscribe = () => {
    unsubscribeFromResources();
    removeEventListener('view-loaded', handleViewLoaded);
  };

  return unsubscribe;
}
