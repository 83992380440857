import { PromptId } from '@float/types/prompts';

export const isNavigatingBetween = (
  currentPrompt: PromptId | undefined,
  previousPrompt: PromptId | undefined,
  prompt1: PromptId,
  prompt2: PromptId,
) => {
  if (
    (previousPrompt === prompt1 && currentPrompt === prompt2) ||
    (previousPrompt === prompt2 && currentPrompt === prompt1) ||
    (previousPrompt === prompt1 && currentPrompt === prompt1) ||
    (previousPrompt === prompt2 && currentPrompt === prompt2)
  ) {
    return true;
  }

  return false;
};
