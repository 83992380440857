import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { CurrentUser, Project } from '@float/types';

import { getUserAccess } from '../rights';

export function getShouldAllowDraftStatus(
  currentUser: CurrentUser,
  projects: Project[] = [],
) {
  const isFeatureEnabled = featureFlags.isFeatureEnabled(
    FeatureFlag.DraftStatus,
  );

  if (!isFeatureEnabled) return false;

  const access = getUserAccess(currentUser);

  if (access.isAdminOrHigher()) return true;

  if (access.isMember()) return false;

  // If a list of projects is specified, then the user must be the owner of
  // every project in order to be allowed to set the draft status.
  const ownsEveryProject = projects.every((project) => {
    return project.project_manager === currentUser.account_id;
  });

  return ownsEveryProject;
}
