import * as Storage from 'idb-keyval';
import { Middleware } from 'redux';

import { loadHistoryAction } from '@float/common/actions/lastAllocatedTask';
import { CurrentUser } from '@float/types';

import { ReduxStateStrict } from '../lib/types';
import { LastAllocatedTaskHistoryEntry } from './types';

const getStorageKey = (accountId: number) => 'lastAllocatedTask-' + accountId;

export const indexedDbStrategy: StorageStrategy = {
  async loadHistory(accountId) {
    const data = await Storage.get(getStorageKey(accountId));

    return data || null;
  },

  saveHistory(accountId, history) {
    return Storage.set(getStorageKey(accountId), history);
  },
};

export type StorageStrategy = {
  loadHistory: (
    accountId: number,
  ) => Promise<LastAllocatedTaskHistoryEntry[] | null>;
  saveHistory: (
    accountId: number,
    history: LastAllocatedTaskHistoryEntry[],
  ) => Promise<void>;
};

export const lastAllocatedTaskMiddleware = (
  storageStrategy: StorageStrategy,
) => {
  const middleware: Middleware<{}, ReduxStateStrict> = (store) => {
    const user: CurrentUser = store.getState().currentUser;

    let history: LastAllocatedTaskHistoryEntry[] | null = null;
    let status: 'LOADING' | 'LOADED' | undefined = undefined;

    return (next) => async (action) => {
      const res = await next(action);

      if (status === undefined) {
        status = 'LOADING';
        storageStrategy.loadHistory(user.cid).then((fromStorage) => {
          if (fromStorage !== null) {
            store.dispatch(loadHistoryAction(fromStorage));
            history = fromStorage;
          }
          status = 'LOADED';
        });
      }

      // The read from storage is async but should be quite fast
      // there are no risks on missing updates
      if (status === 'LOADED') {
        const updatedHistory = store.getState().lastAllocatedTask.history;

        if (history !== updatedHistory) {
          storageStrategy.saveHistory(user.cid, updatedHistory);
          history = updatedHistory;
        }
      }

      return res;
    };
  };

  return middleware;
};
