import { mapValues, reduce } from 'lodash';

import { getPersonResolvedRate } from '@float/common/lib/rates/getPersonResolvedRate';
import { Person } from '@float/types/person';
import { Phase } from '@float/types/phase';
import { BudgetPriority, BudgetType, Project } from '@float/types/project';
import { Role } from '@float/types/role';
import { TaskMeta } from '@float/types/task';

import { AllocationCost, AllocationHours } from '../EditTaskModal.types';

type GetAllocationCostParams = {
  project?: Project | null;
  phase?: Phase | null;
  taskMeta?: TaskMeta | null;
  peopleMap: Record<number, Person>;
  rolesMap: Record<number, Role>;
};

/**
 * Gets allocation cost from allocation hours
 */
export const getAllocationCost = (
  allocationHours: AllocationHours,
  { project, phase, taskMeta, peopleMap, rolesMap }: GetAllocationCostParams,
): AllocationCost => {
  const hoursTotal = allocationHours.totalHours;
  const hoursByPerson = allocationHours.hoursByPerson;

  const isBillableProject = project && !project.non_billable;
  const isNonBillablePhase = phase && phase.non_billable;
  const isNonBillableTask =
    taskMeta &&
    typeof taskMeta.billable === 'number' &&
    taskMeta.billable === 0;

  const rateByPerson = mapValues(
    hoursByPerson,
    (personHours, personIdString) => {
      if (!project) {
        return 0;
      }

      if (isBillableProject && (isNonBillablePhase || isNonBillableTask)) {
        return 0;
      }

      if (project.budget_priority === BudgetPriority.Phase && !phase) {
        return 0;
      }

      if (
        project.budget_type === BudgetType.TotalHours ||
        project.budget_type === BudgetType.HourlyFee
      ) {
        return 1;
      }

      const personId = parseInt(personIdString);
      const person = peopleMap[personId];
      const personRoleId = person.role_id;
      const personRole = personRoleId ? rolesMap[personRoleId] : null;

      const personResolvedRate = getPersonResolvedRate({
        person,
        project,
        phase,
        role: personRole,
      });

      return personResolvedRate || 0;
    },
  );

  const rateTotal = reduce(
    rateByPerson,
    (total, current) => {
      return total + current;
    },
    0,
  );

  const costByPerson = mapValues(
    hoursByPerson,
    (personHours, personIdString) => {
      const personId = parseInt(personIdString);
      const personRate = rateByPerson[personId];

      const personCost = personHours * personRate;

      return personCost;
    },
  );

  const costTotal = reduce(
    costByPerson,
    (total, current) => {
      return total + current;
    },
    0,
  );

  return {
    hoursTotal,
    hoursByPerson,
    rateTotal,
    rateByPerson,
    costTotal,
    costByPerson,
    allocatedToProjectId: project?.project_id || null,
    allocatedToPhaseId: phase?.phase_id || null,
    allocatedToTaskMetaId: taskMeta?.task_meta_id || null,
  };
};
