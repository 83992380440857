import { isEmpty, isEqual } from 'lodash';

const NUMERIC_KEYS = ['hours', 'full_day'];
const EMPTY_CHECK_KEYS = ['taskIdsToUnlink'];
const DEEP_CHECK_KEYS = ['people_ids', 'priority_info', 'taskIdsToUnlink'];
export const IGNORABLE_KEYS = [
  'dragging',
  'curr_start',
  'curr_end',
  'prevPeople',
  'project',
  'length',
  'total_hours',
  'hours_pd',
  'person_name',
  'peopleItems',
  'timeoffType',
  'type',
  'client_name',
  'project_name',
  'people',
  'client_id', // A task will never have a client id change, only project id
  'end_time', // end time is dynamically calculated from start_time + hours
  // Serena properties:
  'originalStart',
  'originalEnd',
  'allInstances',
  'repeatInstances',
  'instanceCount',
  '_allInstancesMemoKey',
  'adjusted',
  'repeat_end_num',
  '_old_priority_info',
  '_old_priority',
];

export const getAllChangedFields = (next, prev) => {
  return Object.keys(next).filter((field) => {
    if (NUMERIC_KEYS.includes(field)) {
      const res = +next[field] !== +prev[field];
      return res;
    }
    if (EMPTY_CHECK_KEYS.includes(field)) {
      if (isEmpty(next[field]) && isEmpty(prev[field])) return false;
    }
    if (DEEP_CHECK_KEYS.includes(field)) {
      const res = !isEqual(next[field], prev[field]);
      return res;
    }
    const ignore = IGNORABLE_KEYS.includes(field);
    const bothEmpty = !next[field] && !prev[field];
    if (ignore || bothEmpty) return false;
    return next[field] !== prev[field];
  });
};
