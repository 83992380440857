import React, { CSSProperties } from 'react';

import { isFullDayTimeoff } from '@float/common/lib/timeoffs';
import { SerenaState } from '@float/common/selectors/serena';
import { CellItem } from '@float/types';
import { Icons } from '@float/ui/deprecated';

import { formatHours } from '../utils/formatHours';
import { getNumRows } from '../utils/getNumRows';
import { getIconElements } from './getIconElements';
import { BoxGetterProps } from './types';

import {
  narrowBoxInner,
  narrowHours,
  narrowText,
} from '../../EntityItemStyles.css';

interface BoxInnerNarrowProps {
  item: CellItem<'task'> | CellItem<'timeoff'>;
  reduxData: SerenaState;
  styles: CSSProperties;
  props: BoxGetterProps;
}

export const BoxInnerNarrow: React.FC<BoxInnerNarrowProps> = ({
  item,
  reduxData,
  styles,
  props,
}) => {
  const { color } = styles;
  const { hourHeight } = props;
  const { entity } = item;

  const numRows = getNumRows(entity, hourHeight) + 1;
  const fullDay = isFullDayTimeoff(entity);

  const elements = [];

  if (!fullDay) {
    elements.push(
      <div
        className={narrowHours}
        key="hours"
        data-more-than-one-row={numRows >= 2}
      >
        <div>{formatHours(entity, true)}</div>
      </div>,
    );
  }

  if (numRows >= 2) {
    const iconElements = getIconElements(
      item,
      color,
      undefined,
      reduxData.user,
    );
    if (item.type === 'task' && item.entity.integration_status === 1) {
      iconElements.unshift(<Icons.SyncWhite key="sync" color={color} />);
    }
    iconElements.reverse().splice(numRows - 1, iconElements.length);
    elements.push(...iconElements);
  }

  if (item.type === 'timeoff') {
    const type = reduxData.timeoffTypes[item.entity.timeoff_type_id];
    if (elements.length < numRows && type && type.timeoff_type_name) {
      elements.push(
        <div className={narrowText} key="t">
          {type.timeoff_type_name.substring(0, 1)}
        </div>,
      );
    }
  } else {
    const taskName = item.entity.name;

    if (elements.length < numRows && taskName) {
      elements.push(
        <div className={narrowText} key="n">
          {taskName.substring(0, 1)}
        </div>,
      );
    }
    if (elements.length < numRows && !taskName) {
      const phaseId = item.entity.phase_id;

      if (phaseId && reduxData.phases[phaseId]?.phase_name) {
        elements.push(
          <div className={narrowText} key="n">
            {reduxData.phases[phaseId].phase_name.substring(0, 1)}
          </div>,
        );
      }
    }

    const project = reduxData.projects[item.entity.project_id];

    // Sometimes project does not exist in local store. Probably an
    // intermediate render after a project delete op?
    if (project) {
      if (elements.length < numRows && project.project_name) {
        elements.splice(
          elements.length - (taskName ? 1 : 0),
          0,
          <div className={narrowText} key="pn">
            {project.project_name.substring(0, 1)}
          </div>,
        );
      }
      if (elements.length < numRows && project.client_name !== 'No Client') {
        elements.splice(
          elements.length -
            ((taskName ? 1 : 0) + (project.project_name ? 1 : 0)),
          0,
          <div className={narrowText} key="cn">
            {project.client_name.substring(0, 1)}
          </div>,
        );
      }
    }
  }

  return (
    <div
      className={narrowBoxInner}
      data-more-than-one-row={numRows >= 2}
      data-single-row={numRows === 1}
      data-full-day={Boolean(fullDay)}
      data-pinned-timeoff={item.type === 'timeoff' && !fullDay && item.y === 0}
    >
      {elements}
    </div>
  );
};
