import { createSelector } from 'reselect';

import { sortByDateAndName } from '@float/common/lib/itemSort';
import { Phase } from '@float/types';

import { getPhases } from './phases';

export const selectPhasesByProjectIdAndPersonId = createSelector(
  [
    getPhases,
    (_, id: number | undefined, _personId: number | undefined) => id,
    (_, _id: number | undefined, personId: number | undefined) => personId,
  ],
  (phases: Phase[], projectId, personId) => {
    if (!projectId || !personId) return [];

    return sortByDateAndName(
      phases.filter(
        (phase) =>
          phase.project_id === projectId &&
          phase.people_ids?.indexOf(personId) !== -1,
      ),
      'start_date',
      'phase_name',
    );
  },
);
