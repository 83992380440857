import { Task } from '@float/types/task';
import { Timeoff } from '@float/types/timeoff';

import { getRepeatedDates } from './getRepeatedDates';

export function forEachEntityDate(
  dates: DatesManager,
  entity: Pick<
    Task | Timeoff,
    'repeat_state' | 'repeat_end_date' | 'end_date' | 'start_date'
  >,
  callback: (dateNum: number) => void,
) {
  const start = dates.toNum(entity.start_date);
  const end = dates.toNum(entity.end_date);

  for (let d = start; d <= end; d++) {
    callback(d);
  }

  for (const { start, end } of getRepeatedDates(dates, entity)) {
    for (let d = start; d <= end; d++) {
      callback(d);
    }
  }
}
