import React, { ComponentType, FC } from 'react';
import { useSelector } from 'react-redux';
import useUpdateEffect from 'react-use/esm/useUpdateEffect';
import { createSelector } from 'reselect';

import { useModalLocal } from '@float/ui/hooks/useModalLocal';

import { useWebAppDispatchStrict } from '../lib/store';
import { WebAppState } from '../reducers/types';
import manageModalAction from './manageModalActionCreator';
import { ModalState, ModalType } from './types';
import { ModalConfig } from './useManageModal';

const selectModalManager = (state: WebAppState) => state.modalManager;
const selectSidePanelOpen = (state: WebAppState) => state.sidePanel.open;

const mapStateToProps = createSelector(
  [selectModalManager, selectSidePanelOpen],
  (managedModals: Record<ModalType, ModalState>, sidePanelOpen: boolean) => ({
    managedModals,
    sidePanelOpen,
  }),
);

const useModalManager = (modalType: ModalType) => {
  const dispatch = useWebAppDispatchStrict();
  const { managedModals, sidePanelOpen } = useSelector(mapStateToProps);

  const managedModal = managedModals[modalType];

  const { modalSettings, showing, skipSidePanelAutoClose } = managedModal;

  const open = (!sidePanelOpen || skipSidePanelAutoClose) && showing;

  const manageModal = (config: ModalConfig) => {
    dispatch(manageModalAction(config));
  };

  const onOpenChange = (open: boolean) => {
    if (!open) {
      manageModal({ visible: false, modalType });
    }
  };

  const modal = useModalLocal({ open, onOpenChange });

  useUpdateEffect(() => {
    if (open) {
      modal.controls.openModal();
    } else {
      modal.controls.closeModal();
    }
  }, [open]);

  return {
    // global modal manager
    manageModal,
    modalSettings,

    // local modal manager
    ...modal,
  };
};

type IWithModalManager = ReturnType<typeof useModalManager>;
type IWithModal<P> = {
  manageModal: IWithModalManager['manageModal'];
  modalControls: IWithModalManager['controls'];
  modalProps: IWithModalManager['props'];
  modalSettings: P;
};

const withModal = <P extends object>({
  Comp,
  modalType,
}: {
  Comp: ComponentType<P>;
  modalType: ModalType;
}): FC<Omit<P, keyof IWithModal<unknown>>> => {
  return (props) => {
    const modalManager = useModalManager(modalType);

    return (
      modalManager.controls.present && (
        <Comp
          {...(props as P)}
          manageModal={modalManager.manageModal}
          modalControls={modalManager.controls}
          modalProps={modalManager.props}
          modalSettings={modalManager.modalSettings}
        />
      )
    );
  };
};

export { withModal, IWithModal };
