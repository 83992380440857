import React from 'react';

export default function ({ size = '24', color = '#FFFFFF' }) {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      focusable={false}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M14,10 L18,10 C18.5522847,10 19,10.4477153 19,11 L19,13 C19,13.5522847 18.5522847,14 18,14 L14,14 L14,18 C14,18.5522847 13.5522847,19 13,19 L11,19 C10.4477153,19 10,18.5522847 10,18 L10,14 L6,14 C5.44771525,14 5,13.5522847 5,13 L5,11 C5,10.4477153 5.44771525,10 6,10 L10,10 L10,6 C10,5.44771525 10.4477153,5 11,5 L13,5 C13.5522847,5 14,5.44771525 14,6 L14,10 Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
