import { MutableRefObject, useEffect } from 'react';

/**
 * Exposes the ability to listen for click events outside a given element list
 */
export const useOnClickOutside = (
  handler: (event: Event) => void,
  refs: MutableRefObject<HTMLElement | null>[],
  enabled = true,
) => {
  useEffect(() => {
    if (!enabled) return;

    const listener = (event: Event) => {
      const target = event.target as HTMLElement;

      // Do nothing if the ref is set and clicking ref's element or descendent elements
      // Otherwise we assume we are attaching a global listener
      for (const ref of refs) {
        if (ref.current?.contains(target)) {
          return;
        }
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  });
};
