import React from 'react';
import { useWatch } from 'react-hook-form';

import { getPeopleMap } from '@float/common/selectors/people';
import { useAppSelector } from '@float/common/store';
import { Account } from '@float/types/account';

import { SidePanelTableColumn } from '../../components/SidePanelTableSection/SidePanelTableColumn';
import { SidePanelTableRow } from '../../components/SidePanelTableSection/SidePanelTableRow';
import { useRemoveOnBlur } from '../../hooks/useRemoveOnBlur';
import { ProjectFormData } from '../../types';
import { LimitedAccessTag } from './LimitedAccessTag';
import { ProjectTeamMemberInfo } from './ProjectTeamMemberInfo';
import { ProjectTeamMemberMenu } from './ProjectTeamMemberMenu';
import { ProjectTeamMemberRate } from './ProjectTeamMemberRate';
import {
  useShowHourlyRatesInfo,
  useShowLimitedAccessTag,
} from './ProjectTeamSection.hooks';

import * as styles from './styles.css';

type ProjectTeamMemberProps = {
  onTeamMemberEnterSelect: () => void;
  index: number;
  toggleDefaultRate: boolean;
};

export const ProjectTeamRow: React.FC<ProjectTeamMemberProps> = ({
  onTeamMemberEnterSelect,
  index,
  toggleDefaultRate,
}) => {
  // Watching this particular row for changes to the value. If an id is provided it means
  // the value has updated (won't re-render on it's own).
  const personId = useWatch<ProjectFormData>({
    name: `team.${index}.people_id`,
    exact: true,
  }) as number;
  const showRateColumn = useShowHourlyRatesInfo();
  const person = useAppSelector((state) => getPeopleMap(state)[personId]);
  const { showLimitedAccessTag, showRateField, isMemberOrPeopleManager } =
    useShowLimitedAccessTag({
      index,
      account: person?.account as Account,
    });

  const { ref: blurRef, onBlurHandler } = useRemoveOnBlur({
    isEmpty: !personId,
    index,
  });

  return (
    <SidePanelTableRow
      data-testid={`team_${index}`}
      className={styles.projectTeamGridTemplateColumns({
        gridTemplateColumns: showRateColumn ? 'rates' : 'default',
      })}
      onBlur={onBlurHandler}
      ref={blurRef as React.RefObject<HTMLDivElement>}
      large={!person}
      hover
    >
      <SidePanelTableColumn>
        <ProjectTeamMemberInfo
          index={index}
          person={person}
          showingRates={showRateColumn}
          onEnterSelect={onTeamMemberEnterSelect}
        />
      </SidePanelTableColumn>
      <SidePanelTableColumn>
        {showLimitedAccessTag && (
          <LimitedAccessTag
            personId={personId}
            index={index}
            isMemberOrPeopleManager={isMemberOrPeopleManager}
          />
        )}
      </SidePanelTableColumn>
      <SidePanelTableColumn active={showRateColumn}>
        {showRateField && (
          <ProjectTeamMemberRate
            index={index}
            person={person}
            toggleDefaultRate={toggleDefaultRate}
          />
        )}
      </SidePanelTableColumn>
      <SidePanelTableColumn>
        <ProjectTeamMemberMenu person={person} index={index} />
      </SidePanelTableColumn>
    </SidePanelTableRow>
  );
};
