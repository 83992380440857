import React from 'react';

export default function ({ color = '#FFFFFF' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="11"
      viewBox="0 0 11 11"
    >
      <path
        fill={color}
        fillRule="nonzero"
        d="M5.5 0a5.5 5.5 0 1 1 0 11 5.5 5.5 0 0 1 0-11zM2.075 5.915L4.67 8.51l4.462-4.462-.934-.934-3.474 3.583L3.009 4.98l-.934.934z"
      />
    </svg>
  );
}
