export const SUPPORT_ARTICLES = {
  security: 'https://support.float.com/en/articles/5777239-plus-pack',
  activityFeed: 'https://support.float.com/en/articles/1075182-activity-feed',
  timeTracking: 'https://support.float.com/en/articles/3616990-time-tracking',
};

export enum Placeholders {
  Starter = 1,
  Pro = 5,
  Enterprise = 30,
}
