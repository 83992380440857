import { useFormContext } from 'react-hook-form';

import { IUseModalLocal } from '@float/ui/hooks/useModalLocal';

import { ProjectFormData } from '../types';

export const useNonBillableChangeConfirmModal = (modal: IUseModalLocal) => {
  const { setValue, getValues, resetField } = useFormContext<ProjectFormData>();
  const onProjectBillableStateChange = (value: boolean) => {
    const phases = getValues('phases');
    const billablePhases = phases.filter((b) => !b.non_billable);
    if (value && billablePhases.length) {
      modal.controls.openModal();
    }
  };

  const resetBillableState = (state: boolean) => {
    if (!state) {
      resetField('project.non_billable');
    }
  };

  const updatePhasesBillable = () => {
    const phases = getValues('phases');
    phases.forEach((p, index) => {
      if (!p.non_billable) {
        setValue(`phases.${index}.non_billable`, true);
      }
    });
    modal.controls.closeModal();
  };

  return {
    onProjectBillableStateChange,
    resetBillableState,
    updatePhasesBillable,
  };
};
