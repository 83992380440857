import React from 'react';
import styled from 'styled-components';

import * as Animation from '@float/ui/deprecated/Earhart/Animation';

const offColorCircle = '#c8c8c8';
const offColorText = '#868d92';
const onColor = '#00ac00';
const errColor = '#e62768';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1px;
`;

const Circle = styled.div`
  min-height: 10px;
  min-width: 10px;
  border-radius: 5px;
  background-color: ${({ isOn, error }) =>
    error ? errColor : isOn ? onColor : offColorCircle};

  transition-property: background-color;
  transition-duration: ${Animation.Settings.Duration.Short};
  transition-timing-function: ${Animation.Settings.Easing.InOutCurve};
`;

const Text = styled.p`
  margin-left: 5px;
  font-size: 14px;
  color: ${({ isOn, error }) =>
    error ? errColor : isOn ? onColor : offColorText};

  line-height: 1;

  transition-property: color;
  transition-duration: ${Animation.Settings.Duration.Short};
  transition-timing-function: ${Animation.Settings.Easing.InOutCurve};
`;

export default function OnOffStatus({
  isOn = false,
  isError = false,
  customText,
  ...props
}) {
  return (
    <Wrapper {...props}>
      <Circle isOn={isOn} error={isError} />
      <Text isOn={isOn} error={isError}>
        {customText || (isError && 'Error') || (isOn ? 'on' : 'off')}
      </Text>
    </Wrapper>
  );
}
