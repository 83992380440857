import { useState } from 'react';

export enum AccordionEntry {
  info = 'info',
  budget = 'budget',
  team = 'team',
  phases = 'phases',
  tasks = 'tasks',
  milestones = 'milestones',
}

export function useAccordionState(entries: AccordionEntry[] = []) {
  const [state, setState] = useState<string[]>(
    entries.length ? entries : [AccordionEntry.info],
  );

  function setOpenState(entry: AccordionEntry, open: boolean) {
    const currentlyOpen = state.includes(entry);

    // No changes
    if (open === currentlyOpen) return;

    if (open) {
      setState(state.concat(entry));
    } else {
      setState(state.filter((v) => v !== entry));
    }
  }

  function onValueChange(value: string[]) {
    setState(value);
  }

  return {
    value: state,
    onValueChange,
    setOpenState,
  };
}
