import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Trans } from '@lingui/macro';
import useUpdateEffect from 'react-use/esm/useUpdateEffect';

import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { BudgetPriority, BudgetType } from '@float/types/project';
import { TaskMeta } from '@float/types/task';
import * as Accordion from '@float/ui/components/Accordion';

import { BudgetTag } from '../../components/BudgetTag';
import { FormFieldsSection } from '../../components/FormFieldsSection';
import { SectionErrorTag } from '../../components/SectionErrorTag';
import { BudgetPriorityField } from '../../fields/BudgetPriorityField';
import { BudgetTotalFee } from '../../fields/BudgetTotalFee';
import { BudgetTotalHours } from '../../fields/BudgetTotalHours';
import { BudgetTypeField } from '../../fields/BudgetTypeField';
import { AccordionEntry } from '../../hooks/useAccordionState';
import { usePopulateTeamDefaultHourlyRates } from '../../hooks/usePopulateTeamDefaultHourlyRates';
import { useProjectACL } from '../../hooks/useProjectACL';
import { isBudgetNotAccessible, ProjectFormData } from '../../types';
import { getProjectCalculatedBudget } from './ProjectBudgetSection.helpers';

import * as styles from '../../styles.css';

function isFeeBasedBudget(value?: BudgetType) {
  return value === BudgetType.HourlyFee || value === BudgetType.TotalFee;
}

type BudgetHeaderProps = {
  name?: 'project.budget_total' | 'phase.budget_total';
};

export function BudgetHeader({
  name = 'project.budget_total',
}: BudgetHeaderProps) {
  const budgetType = useWatch<ProjectFormData, 'project.budget_type'>({
    name: 'project.budget_type',
  });

  if (isBudgetNotAccessible(budgetType)) return null;

  return (
    <div className={styles.projectAccordionSummary}>
      <div className={styles.showWhenCollapsed}>
        <BudgetTag value={budgetType} />
      </div>
      <SectionErrorTag fields={[name]} />
    </div>
  );
}

const VALUE_UPDATE_OPTIONS = {
  shouldValidate: false,
  shouldDirty: true,
  shouldTouch: true,
};

export const ProjectBudgetSection = (props: {
  taskMetas?: TaskMeta[];
  onAccordionOpenChange: (accordon: AccordionEntry, open: boolean) => void;
}) => {
  const hasBudgetByTaskSupport = featureFlags.isFeatureEnabled(
    FeatureFlag.ProjectBudgetByTasks,
  );

  const acl = useProjectACL();

  const isProjectNonBillable = useWatch<
    ProjectFormData,
    'project.non_billable'
  >({
    name: 'project.non_billable',
  });

  const budgetType = useWatch<ProjectFormData, 'project.budget_type'>({
    name: 'project.budget_type',
  });

  const budgetPriority = useWatch<ProjectFormData, 'project.budget_priority'>({
    name: 'project.budget_priority',
  });

  const isCalculatedBudget =
    budgetPriority === BudgetPriority.Phase ||
    budgetPriority === BudgetPriority.Task;

  const phases = useWatch<ProjectFormData, 'phases'>({
    name: 'phases',
    // Enable if budget is set per phase. Also enable if budget set per task.
    // This is because we need phase tasks to calculate the total budget.
    disabled: !isCalculatedBudget,
  });

  const tasks = useWatch<ProjectFormData, 'tasks'>({
    name: 'tasks',
    disabled: budgetPriority !== BudgetPriority.Task,
  });

  const isFeeBudget = budgetType === BudgetType.TotalFee;
  const isHoursBudget = budgetType === BudgetType.TotalHours;

  const hasBudgetAccess = (isFeeBudget && acl.canSeeBudget) || isHoursBudget;

  const showBudgetPriority =
    budgetType === BudgetType.TotalHours || budgetType === BudgetType.TotalFee;

  const budgetTotalCalculated = getProjectCalculatedBudget({
    isProjectNonBillable,
    budgetPriority,
    formPhases: phases,
    formTasks: tasks,
    projectAllTasks: props.taskMetas,
  });

  const { setValue, setFocus } = useFormContext<ProjectFormData>();
  const { populateTeamDefaultHourlyRates } =
    usePopulateTeamDefaultHourlyRates();

  function handleTypeChange(value: BudgetType) {
    // Use budget by project by default
    setValue(
      'project.budget_priority',
      BudgetPriority.Project,
      VALUE_UPDATE_OPTIONS,
    );

    if (isFeeBasedBudget(value)) {
      populateTeamDefaultHourlyRates();
      props.onAccordionOpenChange(AccordionEntry.team, true);
    }
  }

  // Using an update effect for focusing the dependent field because:
  // - During the event handler the target fields are not rendered yet
  // - We want to focus the input only when the budget type has changes
  useUpdateEffect(() => {
    if (budgetType === BudgetType.TotalHours) {
      setFocus('project.budget_total');
    } else if (budgetType === BudgetType.TotalFee) {
      setFocus('project.budget_total');
    }
  }, [budgetType]);

  function handleBudgetPriorityChange(value: BudgetPriority) {
    if (value === BudgetPriority.Phase) {
      props.onAccordionOpenChange(AccordionEntry.phases, true);
    } else if (value === BudgetPriority.Task) {
      props.onAccordionOpenChange(AccordionEntry.tasks, true);
    }

    setValue('project.budget_priority', value);
  }

  return (
    <Accordion.Item value={AccordionEntry.budget}>
      <Accordion.Header
        className={styles.projectAccordionHeader}
        info={<BudgetHeader />}
      >
        <Trans>Budget</Trans>
      </Accordion.Header>
      <Accordion.Content>
        <FormFieldsSection>
          <BudgetTypeField onChange={handleTypeChange} />
          {showBudgetPriority && (
            <BudgetPriorityField
              onChange={handleBudgetPriorityChange}
              hasBudgetByTaskSupport={hasBudgetByTaskSupport}
            />
          )}
          {hasBudgetAccess && budgetType === BudgetType.TotalHours && (
            <BudgetTotalHours
              isReadOnly={isCalculatedBudget}
              readOnlyValue={budgetTotalCalculated}
            />
          )}
          {hasBudgetAccess && budgetType === BudgetType.TotalFee && (
            <BudgetTotalFee
              isReadOnly={isCalculatedBudget}
              readOnlyValue={budgetTotalCalculated}
            />
          )}
        </FormFieldsSection>
      </Accordion.Content>
    </Accordion.Item>
  );
};
