import { v4 as uuidv4 } from 'uuid';

import { logger } from '@float/libs/logger';
import { Tag } from '@float/types/tag';

import API3 from '../api3';
import { ReduxStateStrict } from '../reducers/lib/types';
import { DEFAULT_TAG_COLOR, TAG_TYPE } from '../selectors/tags';
import { AppDispatchStrict } from '../store';

export const TAGS_LOAD_START = 'tags/LOAD_START';
export const TAGS_LOAD_FAILED = 'tags/LOAD_FAILED';
export const TAGS_LOAD_FINISH = 'tags/LOAD_FINISH';
export const TAGS_UPDATED = 'tags/UPDATED';
export const TAGS_CREATED = 'tags/CREATED';
export const TAGS_DELETED = 'tags/DELETED';

export const ensureTagsLoaded =
  (forceLoad?: boolean) =>
  async (dispatch: AppDispatchStrict, getState: () => ReduxStateStrict) => {
    const { loadState: currentLoadState } = getState().tags;

    if (!forceLoad && currentLoadState === 'LOADED') return;
    if (currentLoadState === 'LOADING') return; // There's already an in-flight load request

    try {
      dispatch({ type: TAGS_LOAD_START });
      const res = await API3.getTags();

      dispatch({ type: TAGS_LOAD_FINISH, tags: res });
      return res;
    } catch (e) {
      logger.error(JSON.stringify(e), e);
      dispatch({ type: TAGS_LOAD_FAILED });
    }
  };

function onError(err: Error | Error[]) {
  if (err && Array.isArray(err)) return err;
  return (
    (err && err.message) || 'Encountered an error while calling the Tags API'
  );
}

export const createTag = (tag: Tag) => async (dispatch: AppDispatchStrict) => {
  return API3.createTag(tag)
    .then((payload) => {
      dispatch({ type: TAGS_CREATED, tags: [payload] });
    })
    .catch((err) => {
      return Promise.reject(onError(err));
    });
};

export const deleteTag = (tag: Tag) => async (dispatch: AppDispatchStrict) => {
  return API3.deleteTag(tag)
    .then(() => {
      dispatch({
        type: TAGS_DELETED,
        tags: [tag],
      });
    })
    .catch((err) => {
      return Promise.reject(onError(err));
    });
};

export const updateTag =
  (tag: Tag) =>
  async (dispatch: AppDispatchStrict, getState: () => ReduxStateStrict) => {
    const allTags = getState().tags.tags;

    return API3.updateTag(tag)
      .then((nextTag) => {
        dispatch({
          type: TAGS_UPDATED,
          tags: [nextTag],
          allTags,
        });
      })
      .catch((err) => {
        return Promise.reject(onError(err));
      });
  };

// Stores new tags created from the dropdown into the redux state
// Does not call the create API to the backend
// Required because new tags are created but not reloaded from the API upon creation
// And so the app cannot use the new tags without refreshing.
export const storeNewTag =
  (tag: string) =>
  async (dispatch: AppDispatchStrict, getState: () => ReduxStateStrict) => {
    const currentUser = getState().currentUser;
    dispatch({
      type: TAGS_CREATED,
      tags: [
        {
          name: tag,
          type: TAG_TYPE.PROJECT,
          color: DEFAULT_TAG_COLOR.slice(1),
          created_by: currentUser.account_id,
          modified_by: currentUser.account_id,
          // temporary id for FE
          tags_id: uuidv4(),
          group_id: null,
          created: '',
          modified: '',
        },
      ],
    });
  };
