import styled from 'styled-components';

export const StickyOverlayLayer = styled.div`
  z-index: 1001;
  position: sticky;
  top: 0px;
  left: 0px;
  width: 0px;
  height: 0px;
`;
