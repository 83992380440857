export enum ViewMoreActionType {
  TOGGLE = 'TOGGLE',
  TRANSITION_FINISHED = 'TRANSITION_FINISHED',
}

interface ViewMoreState {
  animating: boolean;
  closed: boolean;
  open: boolean;
}

interface ViewMoreAction {
  type: ViewMoreActionType;
}

function viewMoreReducer(state: ViewMoreState, action: ViewMoreAction) {
  const { type } = action;

  switch (type) {
    case ViewMoreActionType.TOGGLE:
      return {
        ...state,
        animating: true,
        closed: false,
        open: !state.open,
      };
    case ViewMoreActionType.TRANSITION_FINISHED:
      return {
        ...state,
        animating: false,
        closed: !state.open,
      };
    default:
      return state;
  }
}

export { viewMoreReducer };
