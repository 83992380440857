import { useEffect } from 'react';

import { MODES } from '@float/ui/deprecated/Chart/constants';

export const useEffectUpdateComparisonMode = (
  timeTrackingEnabled: boolean,
  isDraftProjectReport: boolean,
  settings: any,
  updateSettings: any,
) => {
  useEffect(() => {
    if (settings.comparisonMode === MODES.SCHEDULED) return;

    if (!timeTrackingEnabled || isDraftProjectReport) {
      updateSettings({
        comparisonMode: MODES.SCHEDULED,
      });
    }
  }, [
    timeTrackingEnabled,
    updateSettings,
    settings.comparisonMode,
    isDraftProjectReport,
  ]);
};
