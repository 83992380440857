import React from 'react';

export default function () {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.5185 2C33.4543 2 34.213 2.75863 34.213 3.69445C34.213 4.55228 33.5755 5.26123 32.7484 5.37343L32.5185 5.3889H31.389V7.90057C42.2589 9.53567 50.5927 18.915 50.5927 30.2407C50.5927 42.7183 40.4776 52.8334 28 52.8334C15.5224 52.8334 5.40735 42.7183 5.40735 30.2407C5.40735 18.9149 13.7412 9.53563 24.6112 7.90056V5.3889H23.4814C22.5456 5.3889 21.787 4.63027 21.787 3.69445C21.787 2.83662 22.4245 2.12767 23.2515 2.01547L23.4814 2H32.5185ZM17.7648 20.0055C18.3664 19.404 19.3077 19.3493 19.9711 19.8415L20.1611 20.0055L27.1221 26.9666C27.4021 26.8917 27.6964 26.8517 28.0001 26.8517C29.8717 26.8517 31.389 28.369 31.389 30.2406C31.389 32.1123 29.8717 33.6295 28.0001 33.6295C26.1284 33.6295 24.6112 32.1123 24.6112 30.2406C24.6112 29.9371 24.6511 29.6429 24.7259 29.363L17.7648 22.4018C17.1031 21.7401 17.1031 20.6673 17.7648 20.0055Z"
        fill="#1A90DC"
      />
    </svg>
  );
}
