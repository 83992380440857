import React from 'react';

import { copyToClipboard } from '@float/common/lib/copyToClipboard';

import { TaskActionsMenu } from './TaskActionsMenu';

export const getTaskActions = (self: any) => {
  const { readOnly, integrationSyncLocked, isPreviewingBeforeEdit, isNewTask } =
    self.state;

  if (
    (readOnly && !isPreviewingBeforeEdit) ||
    integrationSyncLocked ||
    isNewTask
  )
    return null;

  async function handleCopyURL(link: string) {
    const success = await copyToClipboard(link);

    if (success) {
      self.props.showSnackbar(`Link copied to clipboard.`);
    } else {
      self.props.showSnackbar(`Clipboard access denied.`);
    }
  }

  return (
    <TaskActionsMenu
      currentUser={self.props.user}
      entity={self.state.task}
      isTimeoff={self.isTimeoff()}
      onCopyURL={handleCopyURL}
      onDelete={self.deleteTask}
    />
  );
};
