import { RefObject, useLayoutEffect, useState } from 'react';

export type AnimatedElements = Record<
  string,
  Element | null | (Element | null)[]
>;

export const useAnimatedElements = (
  contentElement: RefObject<HTMLDivElement | null>,
) => {
  const [animatedElements, setAnimatedElements] = useState<
    AnimatedElements | undefined
  >();

  useLayoutEffect(() => {
    if (contentElement.current) {
      setAnimatedElements({
        contentElement: contentElement.current,
        header: contentElement.current.querySelector('h1'),
        checkBG: contentElement.current.querySelector('.check-bg'),
        checkHalo: contentElement.current.querySelector('.check-halo'),
        checkSVG: contentElement.current.querySelector('.check-svg'),
        checkSVGPath: contentElement.current.querySelector('.check-svg > path'),
        checkCounterSVG: contentElement.current.querySelector('.check-counter'),
        checkCounterSVGCircle: contentElement.current.querySelector(
          '.check-counter > circle',
        ),
        people: [
          contentElement.current.querySelector('#success-illu-boy'),
          contentElement.current.querySelector('#success-illu-girl'),
        ],
        icons: [
          contentElement.current.querySelector('#success-illu-light'),
          contentElement.current.querySelector('#success-illu-cup'),
          contentElement.current.querySelector('#success-illu-hands'),
          contentElement.current.querySelector('#success-illu-check'),
          contentElement.current.querySelector(
            '[data-animation="emoji"] > svg',
          ),
        ],
      });
    }
  }, [contentElement]);

  return { animatedElements };
};
