import { config } from '@float/libs/config';
import { CellsMap, LoggedTime, LoggedTimeRowId } from '@float/types';

import { addLoggedTime } from './helpers/addLoggedTime';
import { addSelection } from './helpers/addSelection';
import { addSuggestedTasks } from './helpers/addSuggestedTasks';
import { addTeamHolidays } from './helpers/addTeamHolidays';
import { addTimeoffs } from './helpers/addTimeoffs';
import { getMinHeightHours } from './helpers/getMinHeightHours';
import { sortCellItemsPerTypeAndPriority } from './helpers/sortCellItemsPerTypeAndPriority';
import { sortCellItemsY } from './helpers/sortCellItemsY';
import { CellFns, CellHelpersProps, CellOpts, CellRenderArgs } from './types';

// Using a collator is a bit faster than directly calling .localeCompare
const collator = new Intl.Collator(config.locale);

const DAY_HOURS = [0, 0, 0, 0, 0, 0, 0];

export function buildLogTimeCell(
  props: CellHelpersProps,
  cells: CellsMap,
  cellKey: LoggedTimeRowId,
  opts: CellOpts,
  fns: CellFns, // Move these functions in a module
) {
  const {
    maps,
    bimaps,
    leftHiddenDays,
    rightHiddenDays,
    hourHeight,
    singleUserView,
    suvSingleDay,
  } = props;

  const { loggedTime: loggedTimeMap } = maps;
  const { loggedTime: loggedTimeBimap } = bimaps;
  const { getPriority, overlaps } = fns;

  const cell = cells[cellKey];
  cell.dayHours = DAY_HOURS.slice(0, 7 - leftHiddenDays - rightHiddenDays);
  cell.loggedHours = DAY_HOURS.slice(0, 7 - leftHiddenDays - rightHiddenDays);

  const { colIdx } = cell;

  const firstCellDay = colIdx * 7 + leftHiddenDays;
  const lastCellDay = (colIdx + 1) * 7 - 1 - rightHiddenDays;
  const minItemHeightHours = getMinHeightHours({
    hourHeight,
    singleUserView,
    suvSingleDay,
  });

  const loggedTime: LoggedTime[] = [];

  for (const id of loggedTimeBimap.getRev(cellKey)) {
    const lt = loggedTimeMap[id];

    if (!lt.isTaskReference) {
      loggedTime.push(lt);
    }
  }

  const timersList = cells._helperData.timersList;

  const cellRenderArgs: CellRenderArgs = {
    cell,
    cellKey,
    cells,
    firstCellDay,
    fns,
    lastCellDay,
    loggedTime,
    minItemHeightHours,
    opts,
    props,
    timersList,
  };

  addTeamHolidays(cellRenderArgs);
  addTimeoffs(cellRenderArgs);
  addSuggestedTasks(cellRenderArgs);
  addLoggedTime(cellRenderArgs);
  addSelection(cellRenderArgs);

  // First, sort the cell items according to their type and priority
  sortCellItemsPerTypeAndPriority('logtime', cell, opts, getPriority, collator);

  // Then, set the appropriate y values for the cell items
  sortCellItemsY(cell, cells, overlaps);

  // Providing a stable-sorted version of cell.items results in smoother
  // animations since the DOM element positions will be unchanged.
  cell.items.sort((a, b) => collator.compare(a.key, b.key));

  return cell;
}
