import * as React from 'react';
import * as Accordion from '@radix-ui/react-accordion';

import * as styles from './styles.css';

export const AccordionRoot = React.forwardRef<
  React.ElementRef<typeof Accordion.Root>,
  React.ComponentPropsWithoutRef<typeof Accordion.Root>
>((props, forwardedRef) => (
  <Accordion.Root
    {...props}
    ref={forwardedRef}
    className={styles.accordionRoot}
  />
));

AccordionRoot.displayName = 'AccordionRoot';
