import { removeAvatar } from '@float/common/actions/people';
import { AppDispatch } from '@float/common/store';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { Person } from '@float/types/person';

import { isExampleAvatar } from './isExampleAvatar';

export const removeExampleAvatar = (
  person: Person,
  newPerson: Person,
  newAvatarURL: string | null,
  dispatch: AppDispatch,
) => {
  return new Promise<void>((resolve) => {
    if (
      featureFlags.isFeatureEnabled(FeatureFlag.OnboardingExampleData) &&
      person.name !== newPerson.name &&
      isExampleAvatar(newAvatarURL)
    ) {
      // @ts-expect-error removeAvatar has no types
      dispatch(removeAvatar({ personId: person.people_id })).finally(resolve);
    } else {
      resolve();
    }
  });
};
