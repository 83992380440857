import {
  ADD_PANEL,
  COMMIT_PANEL_TENTATIVE_OVERRIDE,
  HIDE_CURRENT_PANEL,
  HIDE_CURRENT_PANEL_WITH_WARNING,
  HIDE_SIDE_PANEL,
  REMOVE_PANEL_TENTATIVE_OVERRIDE,
  SHOW_SIDE_PANEL,
  UPDATE_PREVENT_PANEL_OVERRIDE,
} from 'sidePanel/actions';
import { SidePanelAction, SidePanelState } from 'sidePanel/types';

const _defaultSidePanelState: SidePanelState = {
  open: false,
  openPanels: [],
};

const sidePanelReducer = (
  state = _defaultSidePanelState,
  action: SidePanelAction,
): SidePanelState => {
  switch (action.type) {
    case SHOW_SIDE_PANEL: {
      // SHOW_SIDE_PANEL should be used when trying to open a new panel and removing all open panels.

      // 1. Check to see if any existing open fields have any unsaved changes.
      // This will also return undefined if there are no panels open.
      const hasUnsavedChanges = state.openPanels.find(
        (panel) => panel.panelStatus?.preventOverride,
      );

      // 2. If unsaved changes do not exist anywhere in the open panels (or no panels open), we can just open the new panel and close the open ones
      if (!hasUnsavedChanges) {
        return {
          open: true,
          openPanels: [
            {
              panelType: action.payload.panelType,
              panelPayload: action.payload.panelPayload,
              panelStatus: {},
            },
          ],
        };
      }

      // 3. Since unsaved changes do exist, we need to set the tentativeOverride value of the very bottom panel
      // to our new requested panel info so that if the user confirms the discard of unsaved changes, we can show the new panel.
      return {
        open: true,
        openPanels: [
          {
            ...state.openPanels[0],
            panelStatus: {
              preventOverride: true,
              tentativeOverride: action.payload,
            },
          },
          ...state.openPanels.slice(1, state.openPanels.length),
        ],
      };
    }

    case ADD_PANEL: {
      // ADD PANEL should be used when trying to show a panel on top of existing open panels (not necessarily at the top).

      // 1. Find index of panelType that the user is requestin to open from the payload in the openPanels array
      const index = state.openPanels.findIndex(
        (panel) => panel.panelType === action.payload.panelType,
      );

      // 2. If the index is -1, the panelType is not already open and there are no override conflicts to resolve
      // and we can just push the new panel into the list
      if (index === -1) {
        return {
          open: true,
          openPanels: [
            ...state.openPanels,
            {
              panelType: action.payload.panelType,
              panelPayload: action.payload.panelPayload,
              panelStatus: {},
            },
          ],
        };
      }

      // 3. If the panelType is already in the openPanels array, then we need to check for conflicts
      // Or dirty form states of all forms on top of it, since showing this new form will close out all those forms
      const hasOverrideConflict = state.openPanels
        .slice(index)
        .find((panel) => panel.panelStatus?.preventOverride);

      // 4. If conflict exists, we need to set the tentativeOverride value of the current panel trying to be shown
      // and then float the unsaved changes warning to the user.
      if (hasOverrideConflict) {
        return {
          open: true,
          openPanels: state.openPanels.map((panel) =>
            panel.panelType === action.payload.panelType
              ? {
                  ...panel,
                  panelStatus: {
                    preventOverride: true,
                    tentativeOverride: action.payload,
                  },
                }
              : panel,
          ),
        };
      }

      // 5. If no conflict exists, we can just show the new data in the existing panel and remove all the ones at the top.
      return {
        open: true,
        openPanels: [
          ...state.openPanels.slice(0, index),
          {
            panelType: action.payload.panelType,
            panelPayload: action.payload.panelPayload,
            panelStatus: {},
          },
        ],
      };
    }

    case HIDE_SIDE_PANEL: {
      // This is the action that should close all panels at once, which means we need to check
      // for preventOverride in all open panels before taking the action.
      const hasOverrideConflict = state.openPanels.find(
        (panel) => panel.panelStatus?.preventOverride,
      );

      if (state.open && action.payload.force === false && hasOverrideConflict) {
        return {
          ...state,
          openPanels: state.openPanels.map((panel) =>
            panel.panelType === hasOverrideConflict.panelType
              ? {
                  ...panel,
                  panelStatus: {
                    preventOverride: true,
                    tentativeOverride: {
                      isTentativeClose: true,
                    },
                  },
                }
              : panel,
          ),
        };
      }

      return {
        open: false,
        openPanels: [],
      };
    }

    case HIDE_CURRENT_PANEL: {
      // This action is only responsible for closing the very top panel, and not replacing it with another
      // so it can remain simple. The dirty state of the form should be caught locally at the hook level
      const openPanels = state.openPanels.slice(0, state.openPanels.length - 1);
      return {
        ...state,
        open: Boolean(openPanels.length),
        openPanels,
      };
    }

    case HIDE_CURRENT_PANEL_WITH_WARNING: {
      // This action is responsible for closing the top panel after
      // checking for unsaved changes - e.g. in "back to project" flow
      const currentPanelIndex = state.openPanels.length - 1;
      const currentPanel = state.openPanels[currentPanelIndex];
      const hasUnsavedChanges = currentPanel.panelStatus?.preventOverride;
      if (hasUnsavedChanges) {
        return {
          ...state,
          openPanels: state.openPanels.map((panel, index) =>
            index === currentPanelIndex
              ? {
                  ...panel,
                  panelStatus: {
                    preventOverride: true,
                    tentativeOverride: {
                      isTentativeClose: true,
                    },
                  },
                }
              : panel,
          ),
        };
      }

      const openPanels = state.openPanels.slice(0, currentPanelIndex);

      return {
        ...state,
        open: Boolean(openPanels.length),
        openPanels,
      };
    }

    case COMMIT_PANEL_TENTATIVE_OVERRIDE: {
      // This happens when the user confirms the unsaved changes dialog (meaning they are abandoning the unsaved changes)
      // We find the panel that has the tentativeOverride data and commit it to the panelPayload
      // Slice out any of panels on top of this panel.
      const index = state.openPanels.findIndex(
        (panel) => panel.panelStatus?.tentativeOverride,
      );

      if (index === -1) return state;

      const tentativeOverride =
        state.openPanels[index].panelStatus?.tentativeOverride;

      if (!tentativeOverride) return state;

      // isTenativeClose means the user wants to close the panel. so we remove it from the openPanel list.
      if ('isTentativeClose' in tentativeOverride) {
        const openPanels = state.openPanels.slice(0, index);
        return {
          open: Boolean(openPanels.length),
          openPanels,
        };
      }

      return {
        ...state,
        openPanels: [
          ...state.openPanels.slice(0, index),
          {
            panelType: tentativeOverride.panelType,
            panelPayload: tentativeOverride.panelPayload,
            panelStatus: {},
          },
        ],
      };
    }

    case REMOVE_PANEL_TENTATIVE_OVERRIDE: {
      // This action takes place when the user presses the cancel button on the unsaved changes dialog
      // Nothing too complicated here. Just need to remove tentativeOverride from the reducer state
      return {
        ...state,
        openPanels: state.openPanels.map((panel) =>
          panel?.panelStatus?.tentativeOverride
            ? {
                ...panel,
                panelStatus: {
                  ...panel.panelStatus,
                  tentativeOverride: undefined,
                },
              }
            : panel,
        ),
      };
    }

    case UPDATE_PREVENT_PANEL_OVERRIDE: {
      // This action updates the state if a form is dirtied
      // Relevant when an action is called from outside the form since the reducer
      // otherwise would not know of the local form state.
      return {
        ...state,
        openPanels: state.openPanels.map((panel) =>
          panel.panelType === action.payload.panelType
            ? {
                ...panel,
                panelStatus: {
                  ...panel.panelStatus,
                  preventOverride: action.payload.active,
                },
              }
            : panel,
        ),
      };
    }

    default:
      return state;
  }
};

export default sidePanelReducer;
