import { connect } from 'react-redux';
import { getVisibleSettings } from 'selectors';

import withOnMount from '../../decorators/withOnMount';
import SecuritySection from './component';

const mapStateToProps = (state) => ({
  plusPackEnabled: state.companyPrefs.plus_pack > 0,
  userCanSee: getVisibleSettings(state),
});

const StatefulAccountSecuritySection = connect(mapStateToProps)(
  withOnMount(SecuritySection),
);

export default StatefulAccountSecuritySection;
