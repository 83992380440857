import isNil from 'lodash/isNil';

import { formatAmount } from '@float/common/lib/budget/helpers/formatAmount';

export function getPersonRate(peopleId) {
  if (!isNil(this.state.form.people_rates?.[peopleId])) {
    return this.state.form.people_rates[peopleId];
  }

  if (this.props.people[peopleId].default_hourly_rate) {
    return formatAmount(null, this.props.people[peopleId].default_hourly_rate);
  } else if (this.props.people[peopleId]?.role?.default_hourly_rate) {
    return formatAmount(
      null,
      this.props.people[peopleId].role.default_hourly_rate,
    );
  }

  return '';
}
