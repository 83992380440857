import { encode } from './common/encode';
import { send } from './send';

export function put(url, args, opts = {}) {
  if (!opts) opts = {};
  if (!opts.contentType && opts.version === 'f3') {
    opts.contentType = 'application/json';
  }
  let body;
  if (opts.contentType === 'application/json' || opts.json) {
    body = JSON.stringify(args);
  } else if (opts.ignoreEncoding) {
    body = args;
  } else {
    body = encode(args);
  }
  const params = {
    method: opts.method || 'put',
    headers: {
      Accept: 'application/json, text/javascript, */*; q=0.01',
      'Content-Type': opts.contentType || 'application/x-www-form-urlencoded',
      ...opts.headers,
    },
    body,
  };
  return send(url, params, opts);
}
