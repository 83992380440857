import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { stringCompare } from '@float/common/lib/sort';
import { useAppStoreStrict } from '@float/common/store';
import {
  Person,
  Phase,
  ProjectPhaseRecord,
  ProjectTeamMemberData,
} from '@float/types';

import { getProjectOnlyTeamMembers } from '../../../helpers/getProjectTeamDiff';
import { ProjectFormData } from '../../../types';

function sortTeamRecordsByPersonName(
  team: ProjectFormData['team'],
  people: Record<number, Person>,
) {
  return team
    .filter((p) => p.people_id && people[p.people_id])
    .sort((a, b) =>
      stringCompare(people[a.people_id!].name, people[b.people_id!].name),
    );
}

function mapToPeopleIds(team: ProjectTeamMemberData[]) {
  return team.map((p) => p.people_id).filter((id) => id !== null);
}

function mapIdToPhaseTeamMember(id: number) {
  return {
    people_id: id,
    hourly_rate: null,
    isAssignedToPhaseOnly: true,
  };
}

type GetPhaseOnlyTeamMembers = {
  phases: Record<number, Phase>;
  phaseRecords: ProjectPhaseRecord[];
  projectTeam: ProjectTeamMemberData[];
};

function getPhaseOnlyTeamMembers(props: GetPhaseOnlyTeamMembers) {
  const projectTeamIdsSet = new Set(mapToPeopleIds(props.projectTeam));

  const phaseTeamMembers = props.phaseRecords.reduce((acc, phaseRecord) => {
    // Use phase details from the project form's phase record if available
    if (phaseRecord.fullPhaseData?.team) {
      phaseRecord.fullPhaseData?.team.forEach(({ people_id: id }) => {
        if (id !== null && !projectTeamIdsSet.has(id)) {
          acc.push(mapIdToPhaseTeamMember(id));
        }
      });
      return acc;
    }

    // Otherwise, get phase team from redux
    if (!phaseRecord.phase_id) return acc;

    const phasePeopleIds = props.phases[phaseRecord.phase_id]?.people_ids;
    phasePeopleIds?.forEach((id) => {
      if (!projectTeamIdsSet.has(id)) {
        acc.push(mapIdToPhaseTeamMember(id));
      }
    });

    return acc;
  }, [] as ProjectTeamMemberData[]);

  return phaseTeamMembers;
}

export function usePhaseTeamUpdateHandler() {
  const { getState } = useAppStoreStrict();
  const { getValues, setValue } = useFormContext<ProjectFormData>();

  const handlePhaseTeamUpdate = useCallback(() => {
    // Get current projet-level team - from project form state to include
    // any unsaved changes to the project-level team in project form
    const projectTeam = getProjectOnlyTeamMembers(getValues('team'));

    // Get people assigned to phase teams only
    const phaseTeam = getPhaseOnlyTeamMembers({
      phases: getState().phases.phases,
      phaseRecords: getValues('phases'),
      projectTeam,
    });

    const people = getState().people.people;

    const result = sortTeamRecordsByPersonName(
      projectTeam.concat(phaseTeam),
      people,
    );

    setValue('team', result);
  }, [getValues, getState, setValue]);

  return handlePhaseTeamUpdate;
}
