import React from 'react';
import createClass from 'create-react-class';

import DateRangePickerBemMixin from '../utils/DateRangePickerBemMixin';
import PureRenderMixin from '../utils/PureRenderMixin';

const CalendarDatePeriod = createClass({
  mixins: [DateRangePickerBemMixin, PureRenderMixin],
  displayName: 'CalendarDatePeriod',

  render() {
    const { color, period } = this.props;
    const modifiers = { [period]: true };
    let style;

    if (color) {
      style = { backgroundColor: color };
    }

    return <div style={style} className={this.cx({ modifiers })} />;
  },
});

export default CalendarDatePeriod;
