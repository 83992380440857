import { AccountType } from '@float/constants/accounts';
import { TIME_FORMAT_MAP } from '@float/constants/time';

import { AccountLoginMethod, SSOProvider } from './auth';
import { UserTimeFormatPreference } from './timeFormatPreference';
import { FilterToken } from './view';

/**
 * A 7-entry tuple with the number of hours for each day of the week
 */
export type WorkHoursPerDayOfWeek = [
  number,
  number,
  number,
  number,
  number,
  number,
  number,
];

export { AccountType };

export enum AccountTypeName {
  AccountOwner = 'AccountOwner',
  Admin = 'Admin',
  ProjectManager = 'ProjectManager', // Deprecated
  Member = 'Member',
  Billing = 'Billing',
  DepartmentManager = 'DepartmentManager', // Deprecated
  Manager = 'Manager',
}

export type ManagerAccountType =
  | AccountType.AccountOwner
  | AccountType.Admin
  | AccountType.ProjectManager
  | AccountType.Billing
  | AccountType.Manager;

export type MemberAccountType = AccountType.Member;

/**
 * The access field is a bit field.
 *
 * The right bit is for viewing, the left bit is for editing.
 */
export enum MemberAccessLevel {
  CanViewThemselfEditNone = 0, // 00
  CanViewEveryoneEditNone = 1, // 01
  CanViewThemselfEditThemself = 2, // 10
  CanViewEveryoneEditThemself = 3, // 11
}

export enum ManagerAccessLevel {
  None = 0,
  Projects = 1,
  Schedules = 2,
  People = 4,
  Budgets = 8,
}

type ManagerAccountAccess = {
  access: ManagerAccessLevel;
  account_type: ManagerAccountType;
  /**
   * @deprecated use account_type_id
   */
  account_type_id: ManagerAccountType;
  /**
   * @deprecated use account_type_id
   */
  account_tid: ManagerAccountType;
};

type MemberAccountAccess = {
  access: MemberAccessLevel;
  account_type: MemberAccountType;
  /**
   * @deprecated use account_type_id
   */
  account_type_id: MemberAccountType;
  /**
   * @deprecated use account_type_id
   */
  account_tid: MemberAccountType;
};

export type Account = {
  accepted: 0 | 1;
  account_id: number;
  active: 0 | 1;
  avatar: string;
  created: string; // Timestamp as string
  /**
   * @deprecated department_filter_id is deprecated in favour of department_filter
   */
  department_filter_id: number | null;
  department_filter: number[] | null;
  /**
   * (Frontend only)
   * department_filter_all is same as department_filter
   * but it also includes sub-departments.
   */
  department_filter_all: number[] | null;
  email: string;
  id?: number;
  login_method?: AccountLoginMethod | null;
  management_group: {
    departments: number[];
    people: number[];
  } | null;
  modified: string;
  name: string;
  people_id?: number | null;
  sso_email?: string;
  sso_type?: SSOProvider;
  timezone: string | null;
  locale?: string;

  // value filled trough a mutation inside the user selector
  allManagedDepartments?: number[];
} & (ManagerAccountAccess | MemberAccountAccess);

type ManagerUserAccess = {
  access: ManagerAccessLevel;
  account_type_id: ManagerAccountType;
  /**
   * @deprecated use account_type_id
   */
  account_type: ManagerAccountType;
  /**
   * @deprecated use account_type_id
   */
  account_tid: ManagerAccountType;
};

type MemberUserAccess = {
  access: MemberAccessLevel;
  account_type_id: MemberAccountType;
  /**
   * @deprecated use account_type_id
   */
  account_type: MemberAccountType;
  /**
   * @deprecated use account_type_id
   */
  account_tid: MemberAccountType;
};

export enum AddOnStatus {
  TRIAL_EXPIRED = -2,
  DISABLED = -1,
  NOT_ENABLED = 0,
  ENABLED = 1,
  TRIAL = 2,
  BETA = 3,
}

export enum SkedZoom {
  Compact = 1,
  Comfortable = 0,
  Spacious = 2,
}

export type UserCompany = {
  account_id: number;
  company_id: number;
  company_name: string;
  status: 'active' | string;
};

// UserPrefs should be in sync with these
// - https://github.com/floatschedule/api3/blob/develop/api/models/UserPrefs.php
// - https://github.com/floatschedule/float-javascript/blob/develop/common/src/actions/currentUser.js
export type UserPrefs = {
  custom_priority?: Record<number, number[]>;
  custom_sort_order?: 'asc' | 'desc';
  custom_sort_type?: 'name' | 'avail' | 'department' | 'job_title';
  duration_display_format?: {
    value: keyof typeof TIME_FORMAT_MAP;
    label: string;
  };
  me_filter?: boolean;
  mobile_hide_non_wk_days?: 0 | 1; // 0 = false / 1 = true
  mobile_sked_view?: 0 | 1 | 2; // 0 = (portrait: 1day / landscape: 1week) / 1 = (portrait: 3day / landscape: 1week) / 2 = (portrait: 1week / landscape: 2week)
  mobile_sked_zoom?: 0 | 1; // 0 = compact / 1 = spacious
  mobile_suv?: boolean;
  people_order?: Record<string, number>;
  people_sort_by?: string | undefined;
  people_sort_dir?: 'asc' | 'desc';
  people_view?: number;
  proj_view?: number;
  project_from_scratch?: boolean;
  projview_people_custom_priority?: Record<number, Record<number, number[]>>;
  projview_people_order?:
    | 'asc'
    | 'desc'
    | Record<number, Record<number, number>>;
  sked_custom_sort?: 0 | 1; // 0 = false / 1 = true
  sked_projview_sort_by?: string;
  sked_projview_sort_dir?: 'asc' | 'desc';
  sked_sort_by?: 'name' | 'avail' | 'department' | 'job_title';
  sked_sort_dir?: 'asc' | 'desc';
  sked_view?: string;
  sked_view_type?: 'people' | 'projects';
  sked_zoom?: SkedZoom;
  time_format_24h?: UserTimeFormatPreference;
  timeIncrementUnit?: string;
};

type UserPrefsUnformattedValues = {
  // Incremental DB correction: We have a few custom_priority pref values in the
  // DB stored as a 2d array as opposed to an object that it is expected to be.
  // This function corrects such data as and when encountered.
  custom_priority?: UserPrefs['custom_priority'] | number[][];
  projview_people_order?: UserPrefs['projview_people_order'] | Array<never>;
  projview_people_custom_priority?:
    | UserPrefs['projview_people_custom_priority']
    | Array<never>;
  // We have a few time_format_24h pref values in the
  // DB stored as a number instead of JSON value
  time_format_24h?: UserPrefs['time_format_24h'] | number;
};

export type RawUserPrefs = Omit<UserPrefs, keyof UserPrefsUnformattedValues> &
  UserPrefsUnformattedValues;

export type CurrentUser = {
  accepted: 0 | 1;
  account_id: number;
  account_type_name?: string;
  account: Account;
  accountLoaded?: boolean;
  active: 0 | 1;
  avatar_file: string;
  avatar: string;
  admin_id: number;
  cid: number;
  company_name: string;
  companySso?: { hostname?: string; version?: string; account_sso_id?: string };
  created: string; // Timestamp as string
  department_filter_all: Account['department_filter_all'];
  department_filter: Account['department_filter'];
  domains?: string[];
  email: string;
  hide_non_wk_days: boolean;
  ical?: string | null;
  is_paid_plan: boolean;
  locale: string;
  login_method: AccountLoginMethod | null;
  modified?: string;
  myCompanies: UserCompany[];
  name: string;
  p_limit?: number;
  people_id: number | null;
  people_count?: number;
  placeholder_limit?: number;
  placeholder_people?: number;
  plan_type: 'monthly' | 'yearly';
  plus_pack: AddOnStatus;
  prefs: UserPrefs;
  savedSearches: Record<string, FilterToken[] | Record<string, string[]>>;
  shared_link_view: boolean;
  shared_link_filters?: { project_id?: number };
  sso_email?: string;
  sso_required?: boolean | null;
  sso_type?: SSOProvider;
  start_work_week?: number;
  time_tracking: AddOnStatus;
  timeoff_approvals: boolean;
  timezone?: string;
  uuid?: string | null;
  work_days_hours: WorkHoursPerDayOfWeek;
  work_days_hours_history: [
    historyIndex: DateString,
    work_days_hours: WorkHoursPerDayOfWeek,
  ][];
} & (ManagerUserAccess | MemberUserAccess);

export type SharePageUser = {
  cid: number;
  shared_link_view: boolean;
  shared_link_filters: string[];
  prefs: [];
  account_type_id: AccountType;
  edit_rights: 0 | 1;
  view_rights: 0 | 1;
  access: MemberAccessLevel;
};
