import { BitsHandler } from './BitsHandler';

export const getAccount = (userOrAccount) => {
  const account = userOrAccount?.account ?? userOrAccount;
  return account;
};

export const getManageGroup = (account) => {
  const accountData = getAccount(account);

  return {
    people: accountData.management_group?.people ?? [],

    // departments AND their subdepartments
    departments: accountData.allManagedDepartments ?? [],
  };
};

export const getAccountType = (userOrAccount) => {
  const account = getAccount(userOrAccount);
  if (!account) return undefined;

  const accountType = +(
    account.account_type ||
    account.account_type_id ||
    account.account_tid
  );

  if (accountType === 5) {
    return 2;
  }

  return accountType;
};

export const getAccountId = (userOrAccount) => {
  const account = getAccount(userOrAccount);
  if (!account) return null;

  return account?.account_id || account?.account?.account_id || null;
};

export const getUserAccess = (user) => {
  const DEFAULT_ACCESS = 0;
  const currentAccount = getAccount(user);

  const isSharedLink = user.shared_link_view;

  if (isSharedLink) {
    // Shared link still relies on old view_rights and edit_rights
    // In order to make the vitior account config compatible with
    // rights.json, we'll have to convert its access rights to member access
    const bits = BitsHandler.from(32, 0);
    bits.set(0, currentAccount.view_rights);
    bits.set(1, currentAccount.edit_rights);
    return bits.toNumber();
  }

  return currentAccount?.access || DEFAULT_ACCESS;
};

/**
 *
 * @param {*} param0
 * @returns
 */
export const getAssignees = ({ people, person, entity }) => {
  // people may contiain some `null` values
  // https://rollbar.com/float/fe-web-app/items/2307/
  if (people) return people.filter(Boolean);
  if (person) return [person];

  if (entity) {
    if (entity.people_id) return [entity.people_id];
    // people_ids may contiain some `null` values
    return entity.people_ids?.filter(Boolean) || [];
  }

  return [];
};
