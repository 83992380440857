import React, { MouseEvent, useState } from 'react';
import { Trans } from '@lingui/macro';

import { Notification, NotificationTypes } from '@float/types/notification';

import { NotificationItemDetailsContent } from '../NotificationItemDetailsContent';

import * as styles from './NotificationItemDetails.css';

interface Props {
  expanded?: boolean;
  history: Notification<NotificationTypes>['displayHistory'];
}

export const NotificationItemDetails = (props: Props) => {
  const { history, expanded = false } = props;

  const [isExpanded, setIsExpanded] = useState(expanded);

  const onClickDetails = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <button className={styles.textLink} onClick={onClickDetails}>
        <Trans>Details</Trans>
      </button>

      {isExpanded ? (
        <NotificationItemDetailsContent hideableItems={history} />
      ) : null}
    </>
  );
};
