import { connect } from 'react-redux';
import { sortBy } from 'lodash';

import { ensureStatusTypesLoaded } from '@float/common/actions/statusTypes';
import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { AppDispatchStrict } from '@float/common/store';

import withUserCanSee from '../../../components/decorators/withUserCanSee';
import Status from '../../components/Status';

const mapStateToProps = (state: ReduxStateStrict) => ({
  statusTypes: sortBy(state.statusTypes.statusTypes, ['sort_order']),
});

const mapDispatchToProps = (dispatch: AppDispatchStrict) => ({
  onMount: () => dispatch(ensureStatusTypesLoaded()),
});

const StatefulStatus = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withUserCanSee(Status));

export default StatefulStatus;
