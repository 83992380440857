import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { t } from '@lingui/macro';

import { IconBillable } from '@float/ui/icons/essentials/IconBillable';
import { IconFilter } from '@float/ui/icons/essentials/IconFilter';
import { IconNonBillable } from '@float/ui/icons/essentials/IconNonBillable';
import { IconRefresh } from '@float/ui/icons/essentials/IconRefresh';
import { IconTrash2 } from '@float/ui/icons/essentials/IconTrash2';
import {
  IWithModalConfirmDelete,
  withModalConfirmDelete,
} from '@float/web/modalManager/hoc/withModalConfirmDelete';

import { SidePanelMeatballMenu } from '../../components/SidePanelMeatballMenu';
import { useFieldArrayContext } from '../../hooks/useFieldArrayContext';
import { useIsTemplate } from '../../hooks/useIsTemplate';
import { useProjectOrPhaseFieldValue } from '../../hooks/useProjectOrPhaseFieldValue';
import { useProjectScheduleFilter } from '../../hooks/useProjectScheduleFilter';
import { useWatchProjectOrPhaseFieldValue } from '../../hooks/useWatchProjectOrPhaseFieldValue';
import { ProjectFormData, ProjectTaskRecord } from '../../types';
import { showModalConfirmTaskDelete } from './helpers/showModalConfirmTaskDelete';
import { useTaskDeleteImpact } from './index.hooks';

type ProjectTaskMenuMenuProps = IWithModalConfirmDelete & {
  index: number;
};

export const ProjectTaskMenuComponent: React.FC<ProjectTaskMenuMenuProps> = ({
  index,
  confirmDelete,
  confirmDeleteClose,
}) => {
  const task = useWatch<ProjectFormData>({
    name: `tasks.${index}`,
  }) as ProjectTaskRecord;
  const isBillable = task?.billable === 1;

  // Re-render happens upstream (ProjectTaskRow.tsx) so this does not have to be a watch
  const isNonBillableEntity = useWatchProjectOrPhaseFieldValue('non_billable');
  const isActive = useProjectOrPhaseFieldValue('active');
  const isTemplate = useIsTemplate();

  const { setValue, clearErrors, trigger, getValues } = useFormContext();
  const onToggleBillable = () => {
    const newVal = isBillable ? 0 : 1;
    setValue(`tasks.${index}.billable`, newVal);
    setValue(`tasks.${index}.budget`, null);
  };

  const onConvertToNamedTask = () => {
    setValue(`tasks.${index}.unnamedTask`, false);
  };

  const showOnSchedule = useProjectScheduleFilter({
    key: 'task',
    value: task?.task_name,
  });

  const { remove } = useFieldArrayContext<ProjectFormData, 'tasks'>();
  const onDelete = () => {
    remove(index);
    // Need to clear the errors and revalidate the section because the tooltip location does not update when rows are removed
    clearErrors('tasks');
    trigger('tasks');

    confirmDeleteClose();
  };

  const impact = useTaskDeleteImpact(task);
  const billableMessage = isBillable
    ? t`Set as non-billable`
    : t`Set as billable`;

  const onClickDelete = () => {
    if (impact) {
      showModalConfirmTaskDelete({
        confirmDelete,
        impact,
        onDelete,
        tasks: [task],
      });
    } else {
      onDelete();
    }
  };

  const items = [
    {
      value: billableMessage,
      onClick: onToggleBillable,
      hide: isNonBillableEntity,
      icon: isBillable ? IconNonBillable : IconBillable,
    },
    {
      value: t`View using filter`,
      onClick: showOnSchedule,
      disabled: !getValues('projectId'),
      hide: isTemplate || getValues(`tasks.${index}.unnamedTask`),
      icon: IconFilter,
    },
    {
      value: t`Convert to named task`,
      onClick: onConvertToNamedTask,
      hide: !getValues(`tasks.${index}.unnamedTask`),
      icon: IconRefresh,
    },
    {
      value: t`Delete`,
      onClick: onClickDelete,
      disabled: !isActive,
      icon: IconTrash2,
    },
  ].filter((i) => !i.hide);

  return (
    <React.Fragment>
      <SidePanelMeatballMenu
        disabled={!task.task_name?.trim() && !task.task_meta_id}
        vertical
        items={items}
        trigger={{
          label: t`Task options`,
        }}
      />
    </React.Fragment>
  );
};

export const ProjectTaskMenu = withModalConfirmDelete(ProjectTaskMenuComponent);
