import React from 'react';
import { getPersonTypeOptions } from 'selectors';

import { useAppSelector } from '@float/common/store';
import VirtualSelect from '@float/ui/deprecated/VirtualSelect/VirtualSelect';

export default function PeopleType({ value, onChange }) {
  const options = useAppSelector(getPersonTypeOptions);

  const handleChange = ({ value }) => {
    onChange(value);
  };

  return (
    <VirtualSelect
      label="Type"
      visibleItems={6}
      nonNullable
      clearInputOnDropdownOpen={false}
      options={options}
      value={value}
      onChange={handleChange}
    />
  );
}
