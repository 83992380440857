import { SerenaState } from '@float/common/selectors/serena';
import { CellItemWithEntity } from '@float/types';

export const doesItemContainTimers = (
  item: CellItemWithEntity,
  timers: SerenaState['timers'],
) => {
  const { entity } = item;

  // TODO: item.isTaskReference should not be needed here,
  // instead we should look into why suggested tasks are coming with with timer
  // events associated: https://linear.app/float-com/issue/FT-1796
  return (
    item &&
    !item.isTaskReference &&
    'logged_time_id' in entity &&
    Object.values(timers).some(
      (timer) => timer.external_meta?.id === entity.logged_time_id,
    )
  );
};
