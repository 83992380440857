import React from 'react';

export default function ({ size = '24', color = '#868D92' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        fillRule="nonzero"
        d="M4 2a2 2 0 0 0-2 2v14h2V4h14V2H4zm4 4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2H8zm0 2h12v12H8V8z"
      />
    </svg>
  );
}
