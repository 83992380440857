import { PEOPLE_PROJECT_ADDED } from '@float/common/actions/people/people';
import { updateProjectFromSidePanel } from '@float/common/actions/projects/updateProjectFromSidePanel';
import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { getProjectById } from '@float/common/selectors/projects';
import { AppDispatchStrict } from '@float/common/store';
import { logger } from '@float/libs/logger';
import { Project } from '@float/types';

import { PROJECT_UPDATE_FAILED } from './actionTypes';
import { ProjectApiPayload } from './types';

export function updateProjectTeam(
  id: Project['project_id'],
  team: ProjectApiPayload['project_team'],
) {
  return async (
    dispatch: AppDispatchStrict,
    _getState: () => ReduxStateStrict,
  ) => {
    const project = getProjectById(_getState(), id);

    dispatch({
      type: PEOPLE_PROJECT_ADDED,
      peopleIds: team?.add?.map((member) => member.people_id as number),
      projectId: id,
    });

    try {
      await dispatch(updateProjectFromSidePanel(id, {}, team));
    } catch (error) {
      logger.error('Error updating project team', error);

      if (project) {
        dispatch({
          type: PROJECT_UPDATE_FAILED,
          prevValue: project,
        });
      }
    }
  };
}
