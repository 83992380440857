import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M15 13.25a4.25 4.25 0 1 0-4.154-3.346L4.75 16v3.25H8l.75-.75v-1.75h1.75l1.25-1.25v-1.75h1.75l.596-.596c.291.063.594.096.904.096Z"
    />
    <path stroke="#000" d="M16.5 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z" />
  </BaseIcon>
);
