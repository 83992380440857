import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useManageModal } from 'modalManager/useManageModal';

import { getIsFullMemberOrPeopleManager } from '@float/common/lib/acl/getIsFullMemberOrPeopleManager';
import {
  sortByDateAndName,
  sortByOffsetAndName,
} from '@float/common/lib/itemSort';
import { useSearchSelectorWithParams } from '@float/common/search/useSearchSelectorWithParams';
import { selectPhasesByProjectIdAndPersonId } from '@float/common/selectors/phases/selectPhasesByProjectIdAndPersonId';
import { Account } from '@float/types/account';
import { Person } from '@float/types/person';
import { BudgetType } from '@float/types/project';

import { useIsPhasePanel } from '../../hooks/useIsPhasePanel';
import { useIsTemplate } from '../../hooks/useIsTemplate';
import { useProjectACL } from '../../hooks/useProjectACL';
import { ProjectFormData, ProjectPhaseRecord } from '../../types';

export const useShowHourlyRatesInfo = () => {
  const budgetType = useWatch<ProjectFormData>({ name: 'project.budget_type' });
  const acl = useProjectACL();
  const showRateField =
    budgetType === BudgetType.HourlyFee || budgetType === BudgetType.TotalFee;
  return showRateField && acl.canSeeBudget;
};

export const useShowPersonModal = (person: Person | null) => {
  const { manageModalFromPanel } = useManageModal();
  const showPersonModal = () => {
    if (!person) return;
    manageModalFromPanel({
      visible: true,
      modalType: 'personModal',
      modalSettings: {
        person,
        editing: true,
        isEditable: person?.canEdit,
      },
    });
  };

  return showPersonModal;
};

export const useCheckPersonAllocation = (
  id: Person['people_id'] | undefined,
) => {
  const { getValues } = useFormContext<ProjectFormData>();
  const projectId = getValues('projectId');
  const phaseId = getValues('phase.phase_id');
  const isTemplate = useIsTemplate();
  const isPhase = useIsPhasePanel();
  const selectorKey = isPhase
    ? ('isPersonTaskedInThePhase' as const)
    : ('isPersonTaskedInTheProject' as const);
  const selectorParam = isPhase ? phaseId : projectId;
  const isPersonAllocated = useSearchSelectorWithParams(
    selectorKey,
    [selectorParam ?? undefined, id],
    false,
  );
  if (!id || isTemplate) return false;
  return isPersonAllocated;
};

// Opens up the switch person modal UI
export const useSwitchPersonModal = (person: Person | null, index: number) => {
  const { manageModalFromPanel } = useManageModal();
  const { getValues, setValue } = useFormContext<ProjectFormData>();
  const showSwitchPersonModal = () => {
    if (!person) return;
    const projectId = getValues('projectId');
    const projectName = getValues('project.project_name');
    const projectTeam = getValues('team');

    manageModalFromPanel({
      visible: true,
      modalType: 'ModalSwitchPerson',
      modalSettings: {
        project: {
          project_name: projectName,
          project_id: projectId,
          people_ids: projectTeam
            .map((p) => p.people_id)
            .filter((p): p is number => p !== null),
        },
        person,
        // Need to pass a handler to the modal to update the form after the switch
        onSwitch: (newPersonId: Person['people_id']) => {
          setValue(`team.${index}.people_id`, newPersonId, {
            shouldDirty: false,
            shouldValidate: false,
            shouldTouch: false,
          });
        },
      },
    });
  };
  return showSwitchPersonModal;
};

export const useShowLimitedAccessTag = (props: {
  index: number;
  account?: Account;
}) => {
  const { index, account } = props;
  // If member is on project team, no need to show
  const isAssignedToPhaseOnly = useWatch<ProjectFormData>({
    name: `team.${index}.isAssignedToPhaseOnly`,
    exact: true,
  });
  if (!isAssignedToPhaseOnly)
    return {
      showLimitedAccessTag: false,
      showRateField: true,
    };

  return {
    showLimitedAccessTag: true,
    showRateField: false,
    // Need to show a different tooltip message depending on access rights
    isMemberOrPeopleManager: getIsFullMemberOrPeopleManager(account),
  };
};

export const useTeamMemberPhases = (personId: number) => {
  const { getValues } = useFormContext<ProjectFormData>();
  const projectId = getValues('projectId');
  const phases = useSelector((state) =>
    selectPhasesByProjectIdAndPersonId(state, projectId, personId),
  );
  const phaseRecords = useWatch<ProjectFormData, 'phases'>({ name: 'phases' });
  const isTemplate = useIsTemplate();

  return useMemo(() => {
    const result = phaseRecords.filter((phaseRecord: ProjectPhaseRecord) => {
      const isAssignedToSavedPhase = phases.find(
        (p) => p.phase_id === phaseRecord.phase_id,
      );
      if (isAssignedToSavedPhase) return true;

      const isAssignedToUnsavedPhase = phaseRecord.fullPhaseData?.team?.find(
        (p) => p.people_id === personId,
      );
      return isAssignedToUnsavedPhase;
    });

    if (isTemplate) {
      return sortByOffsetAndName(result, 'phase_name');
    }

    return sortByDateAndName(result, 'start_date', 'phase_name');
  }, [isTemplate, personId, phaseRecords, phases]);
};
