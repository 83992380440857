import React from 'react';
import { Trans } from '@lingui/macro';

import { Notification, NotificationTypes } from '@float/types/notification';
import { TagStatic } from '@float/ui/components/TagStatic';

import { NotificationItemTitle } from '../NotificationItemTitle';
import { useTimeoffPendingRequestTag } from './hooks/useTimeoffPendingRequestTag';

import * as styles from './NotificationItemText.css';

interface Props {
  notification: Notification<NotificationTypes>;
}

export const NotificationItemText = (props: Props) => {
  const { notification } = props;

  const isTimeoffRequestPending = useTimeoffPendingRequestTag(notification);

  return (
    <span className={styles.text}>
      <NotificationItemTitle {...notification.baseTitleArgs} />
      {'  '}
      {isTimeoffRequestPending && (
        <TagStatic className={styles.tag} size="xxsmall">
          <Trans>Pending</Trans>
        </TagStatic>
      )}
    </span>
  );
};
