import styled, { css } from 'styled-components';

import * as Animation from '../../Animation';
import * as Colors from '../../Colors';

export const StyledPillContainer = styled.div`
  width: calc(100% - 12px);
  height: 100%;

  transition-property: transform;
  transition-duration: ${Animation.Settings.Duration.Normal};
  transition-timing-function: ${Animation.Settings.Easing.InOutCurve};
`;

export const StyledPill = styled.div`
  width: 12px;
  height: 12px;

  border-radius: 12px;

  background-color: white;
`;

export const StyledPillToggle = styled.div<{ checked: boolean }>`
  display: inline-flex;
  align-items: center;

  position: relative;

  width: 32px;
  height: 16px;

  border-radius: 8px;

  padding: 2px;

  cursor: pointer;

  transition-property: background-color;
  transition-duration: ${Animation.Settings.Duration.Normal};
  transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

  ${({ checked }) => {
    if (checked) {
      return css`
        background-color: ${Colors.FIN.Lt.Emphasis.Primary};
      `;
    }

    return css`
      background-color: ${Colors.FIN.Lt.Emphasis.Disabled};

      &:hover {
        background-color: ${Colors.FIN.Lt.Emphasis.Low};
      }
    `;
  }};

  ${StyledPillContainer} {
    transform: translateX(${({ checked }) => (checked ? '100%' : '0%')});
  }
`;
