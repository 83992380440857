import { FETCH_USER_SUCCESS } from '@float/common/actions';
import {
  VIEW_APPLIED,
  ViewAppliedAction,
} from '@float/common/actions/views/views';
import { config } from '@float/libs/config';
import { CurrentUser } from '@float/types';

import * as appInfoActions from '../../actions/appInfo';
import { AppInfoAction } from '../../actions/appInfo';
import { AppInfoState } from './types';

export const DEFAULT_STATE: AppInfoState = {
  type: config.isNativeTimerApp ? 'timer' : 'web',
  logTimeView: false,
  loadState: 'UNLOADED',
  viewType: 'people',
};

type Action =
  | AppInfoAction
  | ViewAppliedAction
  | {
      type: typeof FETCH_USER_SUCCESS;
      user: Partial<CurrentUser>;
    };

export const appInfoReducer = (
  state = DEFAULT_STATE,
  action: Action,
): AppInfoState => {
  switch (action.type) {
    case FETCH_USER_SUCCESS: {
      // initializing the state when the user is fetched
      // to configure the viewType before that the Schedule is rendered
      if (state.loadState === 'LOADED') return state;

      const { prefs } = action.user;

      if (!prefs) return state;

      return {
        ...state,
        loadState: 'LOADED',
        viewType: prefs.sked_view_type || 'people',
      };
    }

    case VIEW_APPLIED: {
      const page = action.view.settings.page;

      switch (page) {
        case 'log-time':
        case 'log-my-time': {
          return {
            ...state,
            viewType: 'people',
            logTimeView: true,
          };
        }
        case 'people-report':
        case 'schedule-people': {
          return {
            ...state,
            viewType: 'people',
            logTimeView: false,
          };
        }
        case 'projects-report':
        case 'project-plan': {
          return {
            ...state,
            viewType: 'projects',
            logTimeView: false,
          };
        }
      }

      return state;
    }

    case appInfoActions.SET_LOG_TIME_VIEW: {
      return {
        ...state,
        logTimeView: action.payload,
      };
    }

    case appInfoActions.SET_VIEW_TYPE: {
      return {
        ...state,
        viewType: action.payload,
      };
    }

    default:
      return state;
  }
};
