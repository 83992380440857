import { SerenaState } from 'selectors';

import { findFirstPersonTimeoffable } from './findFirstPersonTimeoffable';
import { Row } from './findPersonRowMatch';
import { getDefaultPerson } from './getDefaultPerson';

export function getDefaultPersonForTimeoffModal(
  rows: Row[],
  serenaState: Pick<SerenaState, 'allPeople' | 'people' | 'user' | 'filters'>,
) {
  const firstTaskablePerson = findFirstPersonTimeoffable(rows, serenaState);

  return getDefaultPerson(firstTaskablePerson, serenaState);
}
