import React, { ReactNode } from 'react';

import * as styles from './styles.css';

type ScheduleErrorProps = {
  children?: ReactNode;
};

const ScheduleError = (props: ScheduleErrorProps) => {
  const { children } = props;

  return <div className={styles.appErrorWrapper}>{children}</div>;
};

export { ScheduleError };
