import { AccountType } from '@float/constants/accounts';
import { CurrentUserAuthInfo } from '@float/types/auth';

import { Resources } from './constants';
import { makeRequest, MakeRequestPagination } from './makeRequest';

export type MeApiResponse = {
  name: string;
  email: string;
  access: string;
  admin_id: number;
  cid: number;
  company_name: string;
  account_tid: AccountType;
  uuid: number;
};

/**
 * Fetch the current user's auth info
 * without requiring a JWT token or Redux store
 */
export async function fetchCurrentUserAuthInfo(): Promise<CurrentUserAuthInfo> {
  const user = await makeRequest<MeApiResponse>({
    resource: Resources.MeApi,
    query: {
      expand: 'managers',
      json: '1',
    },
    pagination: MakeRequestPagination.Off,
  });

  return {
    cid: user.cid,
    admin_id: user.admin_id,
    name: user.name,
    account_type_id: user.account_tid,
  };
}
