import React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import cn from 'classnames';

import * as styles from './styles.css';

export type ModalDescriptionProps = DialogPrimitive.DialogDescriptionProps;

export const ModalDescription = (props: ModalDescriptionProps) => {
  const { children, className, hidden, asChild } = props;

  if (hidden) {
    return (
      <VisuallyHidden.Root asChild>
        <DialogPrimitive.Description>{children}</DialogPrimitive.Description>
      </VisuallyHidden.Root>
    );
  }

  if (asChild) {
    return <div className={cn(styles.description, className)}>{children}</div>;
  }

  return (
    <DialogPrimitive.Description className={cn(styles.description, className)}>
      {children}
    </DialogPrimitive.Description>
  );
};
