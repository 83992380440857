import {
  ADD_HOLIDAY,
  ADD_HOLIDAY_FAILURE,
  ADD_HOLIDAY_SUCCESS,
  DELETE_HOLIDAY,
  DELETE_HOLIDAY_FAILURE,
  DELETE_HOLIDAY_SUCCESS,
  UPDATE_HOLIDAY,
  UPDATE_HOLIDAY_FAILURE,
  UPDATE_HOLIDAY_SUCCESS,
} from '@float/common/actions/holidays';
import API3 from '@float/common/api3';

import {
  addedEntityCreator,
  addEntityCreator,
  deletedEntityCreator,
  deleteEntityCreator,
  failedAddingEntityCreator,
  failedDeletingEntityCreator,
  failedFetchingEntitiesCreator,
  failedUpdatingEntityCreator,
  fetchedEntitiesCreator,
  fetchEntitiesCreator,
  shouldFetchEntitiesCreator,
  sortEntitiesCreator,
  updatedEntityCreator,
  updateEntityCreator,
} from './creatorHelpers';

export const FETCH_HOLIDAYS = 'FETCH_HOLIDAYS';
export const FETCH_HOLIDAYS_SUCCESS = 'FETCH_HOLIDAYS_SUCCESS';
export const FETCH_HOLIDAYS_FAILURE = 'FETCH_HOLIDAYS_FAILURE';

export const SORT_HOLIDAYS = 'SORT_HOLIDAYS';

const SINGULAR = 'holiday';
const PLURAL = 'holidays';
const ID_PROP = 'holiday_id';

export const addedHoliday = addedEntityCreator(ADD_HOLIDAY_SUCCESS);

const failedAddingHoliday = failedAddingEntityCreator(ADD_HOLIDAY_FAILURE);

export const addHoliday = addEntityCreator(
  ADD_HOLIDAY,
  SINGULAR,
  addedHoliday,
  failedAddingHoliday,
);

const deletedHoliday = deletedEntityCreator(DELETE_HOLIDAY_SUCCESS);

const failedDeletingHoliday = failedDeletingEntityCreator(
  DELETE_HOLIDAY_FAILURE,
);

export const deleteHoliday = deleteEntityCreator(
  DELETE_HOLIDAY,
  SINGULAR,
  PLURAL,
  deletedHoliday,
  failedDeletingHoliday,
  ID_PROP,
);

const updatedHoliday = updatedEntityCreator(UPDATE_HOLIDAY_SUCCESS);

const failedUpdatingHoliday = failedUpdatingEntityCreator(
  UPDATE_HOLIDAY_FAILURE,
);

export const updateHoliday = updateEntityCreator(
  UPDATE_HOLIDAY,
  SINGULAR,
  updatedHoliday,
  failedUpdatingHoliday,
);

const fetchedHolidays = fetchedEntitiesCreator(
  FETCH_HOLIDAYS_SUCCESS,
  (entities) =>
    entities.map((entity) => ({
      ...entity,
      name: entity.name || entity.holiday_name,
    })),
);

const failedFetchingHolidays = failedFetchingEntitiesCreator(
  FETCH_HOLIDAYS_FAILURE,
);

const shouldFetchHolidays = shouldFetchEntitiesCreator(PLURAL);

export const fetchHolidays = fetchEntitiesCreator(
  FETCH_HOLIDAYS,
  PLURAL,
  shouldFetchHolidays,
  fetchedHolidays,
  failedFetchingHolidays,
  null,
  200,
);

export const fetchHoliday = (id) => {
  return (dispatch, getState) => {
    return API3.getHoliday({ id }).then((holiday) =>
      dispatch(fetchedHolidays([holiday])),
    );
  };
};

export const sortHolidays = sortEntitiesCreator(SORT_HOLIDAYS, fetchHolidays);
