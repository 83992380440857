import React, { ReactNode } from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import styled, { keyframes } from 'styled-components';

import EH from '@float/ui/deprecated/Earhart';
import colors from '@float/ui/deprecated/Theme/colors';

export type PopoverProps = {
  ariaLabel?: string;
  arrow?: boolean;
  className?: string;
  container?: HTMLElement;
  content: ReactNode;
  children: ReactNode;
  placement?: PopoverPrimitive.PopoverContentProps['side'];
  align?: PopoverPrimitive.PopoverContentProps['align'];
  distance?: PopoverPrimitive.PopoverContentProps['sideOffset'];
  collisionPadding?: PopoverPrimitive.PopoverContentProps['collisionPadding'];
  maxWidth?: number;
  zIndex?: number;
  open?: boolean;
  modal?: boolean;
  onOpenChange?: PopoverPrimitive.PopoverProps['onOpenChange'];
  onOpenAutoFocus?: PopoverPrimitive.PopoverContentProps['onOpenAutoFocus'];
  onContentClick?: PopoverPrimitive.PopoverContentProps['onClick'];
  onFocusOutside?: PopoverPrimitive.PopoverContentProps['onFocusOutside'];
  onPointerDownOutside?: PopoverPrimitive.PopoverContentProps['onPointerDownOutside'];
};

export const Popover = React.forwardRef<HTMLDivElement, PopoverProps>(
  (props, ref) => {
    return (
      <PopoverPrimitive.Root
        open={props.open}
        onOpenChange={props.onOpenChange}
        modal={props.modal}
      >
        <PopoverPrimitive.Trigger asChild>
          {props.children}
        </PopoverPrimitive.Trigger>
        <PopoverPrimitive.Portal container={props.container}>
          <PopoverContent
            aria-label={props.ariaLabel}
            ref={ref}
            className={props.className}
            side={props.placement}
            align={props.align}
            sideOffset={props.distance}
            collisionPadding={props.collisionPadding || 5}
            style={{
              maxWidth: props.maxWidth,
              zIndex: props.zIndex,
            }}
            onOpenAutoFocus={props.onOpenAutoFocus}
            onClick={props.onContentClick}
            onFocusOutside={props.onFocusOutside}
            onPointerDownOutside={props.onPointerDownOutside}
          >
            {props.content}
            {props.arrow !== false && (
              <PopoverArrow className={props.className} />
            )}
          </PopoverContent>
        </PopoverPrimitive.Portal>
      </PopoverPrimitive.Root>
    );
  },
);

const fade = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const PopoverContent = styled(PopoverPrimitive.Content)`
  background-color: white;
  text-align: center;
  color: ${colors.charcoalGrey};
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  outline: 0;
  filter: drop-shadow(0px 3px 5px rgba(9, 30, 66, 0.15));
  padding: 9px 14px;

  animation-name: ${fade};
  animation-duration: 275ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  user-select: none;
  will-change: transform, opacity;
  z-index: 9999;

  &.task-chain {
    border-radius: 10px;
    filter: drop-shadow(0px 6px 30px rgba(0, 0, 0, 0.12));
    padding: 16px;
    min-width: 200px;
    text-align: left;
  }

  &.menu {
    ${EH.Elevations.E150};
    border-radius: 10px;
    background-color: ${EH.Colors.FIN.Lt.Emphasis.White};
    padding: 0px;
    font-size: 14px;
    line-height: 14px;
    color: white;
    overflow: hidden;
  }

  &.overflow {
    overflow: visible !important;
  }

  &.float-nav-dropdown {
    border-radius: 0;
    padding: 0;

    @media all and (-ms-high-contrast: none) {
      min-width: 190px;
    }
  }

  &.datepicker {
    width: 300px;
    padding: 16px 24px 24px;
    box-sizing: border-box;
    color: ${EH.Colors.FIN.Lt.Emphasis.High};
    overflow: visible;
  }
`;

const PopoverArrow = styled(PopoverPrimitive.Arrow)`
  width: 15px;
  height: 5px;

  fill: white;

  &.hover {
    fill: #333;
  }

  &.hint {
    fill: ${colors.emphasisHigh};
  }

  &.clear {
    fill: transparent;
  }
`;
