/**
 * Converts an RGB color value to HSL. Conversion formula
 * adapted from {@link http://en.wikipedia.org/wiki/HSL_color_space}.
 * Assumes r, g, and b values in the `color` argument are contained
 * in the set [0, 255] and returns h, s, and l in the set [0, 1].
 *
 * @see {@link https://axonflux.com/handy-rgb-to-hsl-and-rgb-to-hsv-color-model-c}
 *
 * @param {Array} color The RGB color
 *
 * @returns {Array} The HSL representation
 */

export function rgbToHsl(
  color: [number, number, number],
): [number, number, number] {
  let [r, g, b] = color;

  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const l = (max + min) / 2;

  let h;
  let s;

  if (max == min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;

    if (l > 0.5) {
      s = d / (2 - max - min);
    } else {
      s = d / (max + min);
    }

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        h = 0;
        break;
    }

    h /= 6;
  }

  return [h, s, l];
}
