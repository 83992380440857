import { useCallback, useState } from 'react';
import { Moment } from 'moment';

import {
  formatDate,
  getDateDifferences,
  getInitialProRange,
  getInitialStarterRange,
  PRO_PLAN_MAX_RANGE,
  STARTER_PLAN_MAX_RANGE,
} from './DateRangeFilter';

export const useTeamActivityDateRange = ({
  isStarterPlan,
  isProPlan,
}: {
  isStarterPlan: boolean;
  isProPlan: boolean;
}) => {
  const initialStarterRange = getInitialStarterRange();
  const initialProRange = getInitialProRange();

  // Load the starter range as default, we can load more as needed
  const [rangeFilter, setRangeFilter] = useState(initialStarterRange);

  const onDateRangeChange = useCallback(
    (start: Moment, end: Moment, rangeMode: string) => {
      const dateDiff = getDateDifferences(end, start);

      if (isStarterPlan) {
        const isRangeBeyondStarterPlanLimit = dateDiff > STARTER_PLAN_MAX_RANGE;

        if (isRangeBeyondStarterPlanLimit) {
          return setRangeFilter(initialStarterRange);
        }
      } else if (isProPlan) {
        const isRangeBeyondProPlanLimit = dateDiff > PRO_PLAN_MAX_RANGE;

        if (isRangeBeyondProPlanLimit) {
          return setRangeFilter(initialProRange);
        }
      }

      return setRangeFilter({
        rangeMode,
        start: formatDate(start),
        end: formatDate(end),
      });
    },
    [initialStarterRange, initialProRange, isStarterPlan, isProPlan],
  );

  return {
    rangeFilter,
    onDateRangeChange,
  };
};
