import { useCallback, useEffect, useMemo, useState } from 'react';

import { trackEvent } from '@float/common/lib/analytics';
import { DateString, Timeoff } from '@float/types';

import { TimeoffRawItem } from '../types';

export interface LegacyTimeoff extends Timeoff {
  people: Record<number, object>;
}

type ReportTabs =
  | 'departments'
  | 'people'
  | 'projects'
  | 'roles'
  | 'tasks'
  | 'timeoffs'
  | 'timetracking';

/*
  Company specific holidays currently only come through the legacy data endpoint
  so we can't completely replace the legacy.timeoff data with data from the api3
  endpoint. So we are parsing the legacy data to have the same format as the api3
  data. This is just a stopgap solution before we implement the actual public holidays
  endpoint. (See PI-76 in linear)
*/
export const useAggregatedTimeoffs = (
  teamCapacityHighlights: Record<DateString, Timeoff[]>,
): Timeoff[] => {
  const aggregatedTimeoffData = useMemo(() => {
    if (!teamCapacityHighlights) return [];
    return Object.values(teamCapacityHighlights)
      .flat()
      .sort((a, b) => Date.parse(a.start_date) - Date.parse(b.start_date));
  }, [teamCapacityHighlights]);

  return aggregatedTimeoffData;
};

export const getPaidTimeoffs = (timeoffs?: TimeoffRawItem[] | null) => {
  if (!timeoffs) return [];
  // Need to be explicit to filter out objects that do not have the key, which we don't want here.
  return timeoffs.filter((t) => t.holiday == false);
};

export function useReportTab({
  isSinglePersonView,
}: {
  isSinglePersonView: boolean;
}) {
  const [activeTab, setActiveTab] = useState<ReportTabs>(
    isSinglePersonView ? 'projects' : 'people',
  );
  const [openedTabs, setOpenedTabs] = useState<
    Partial<Record<ReportTabs, boolean>>
  >({});

  // Resets activeTab when report mode is toggled, or when search filter applied.
  // https://linear.app/float-com/issue/FT-1914/bug-app-is-not-moving-to-project-section-when-filtering-by-person-name
  useEffect(() => {
    setActiveTab(isSinglePersonView ? 'projects' : 'people');
  }, [isSinglePersonView]);

  const handleTabClick = useCallback(
    (tab: ReportTabs) => {
      if (!openedTabs[tab]) {
        trackEvent('Team Capacity Report Tab Clicked', { tab });
        setOpenedTabs({ ...openedTabs, [tab]: true });
      }

      setActiveTab(tab);
    },
    [openedTabs],
  );

  return { activeTab, setActiveTab: handleTabClick };
}
