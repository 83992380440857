import React, { ChangeEvent, FocusEvent } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { RecipeVariants } from '@vanilla-extract/recipes';
import classNames from 'classnames';

import { noop } from '@float/libs/utils/noop';
import { QuickInputField } from '@float/ui/components/QuickInputField';
import { useNumericInput } from '@float/ui/hooks/useNumericInput';

import { BaseSidePanelInputProps } from '../BaseSidePanelInput';

import * as styles from './styles.css';

export type SidePanelNumericInputProps = Omit<
  BaseSidePanelInputProps,
  'value' | 'defaultValue' | 'size'
> & {
  valueOverride?: string;
  blockOutsideInteraction?: boolean;
  size?: NonNullable<
    RecipeVariants<typeof styles.sidePanelNumericInput>
  >['fontSize'];
};

export function SidePanelNumericInput(props: SidePanelNumericInputProps) {
  const {
    onChange = noop,
    onBlur = noop,
    valueOverride,
    compact,
    size,
    ...rest
  } = props;

  const { field } = useController({
    name: props.name,
  });
  const { setValue, getFieldState, clearErrors } = useFormContext();
  const numericInput = useNumericInput({
    onChange: (arg) => {
      setValue(props.name, arg, {
        shouldDirty: false,
        // Don't trigger validation if the field is empty.
        // This occurs when cascaded fields are cleared.
        // E.g. when budget type is changed to fee by phase.
        shouldValidate: arg !== null,
        shouldTouch: false,
      });
    },
    value: field.value,
  });

  function handleChange(evt: ChangeEvent<HTMLInputElement>) {
    numericInput.onChange(evt);

    onChange(evt);
  }

  function handleBlur(evt: FocusEvent<HTMLInputElement>) {
    numericInput.onBlur();

    onBlur(evt);
  }

  // Clear errors away from field when it switches to readOnly mode
  if (
    props.readOnly &&
    getFieldState(props.name).error?.message === 'Required'
  ) {
    clearErrors(props.name);
  }

  const value = valueOverride || numericInput.value;
  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      // Need to prevent the default submit behaviour
      e.preventDefault();
      (e.target as HTMLInputElement).blur();
    }
  };
  return (
    <QuickInputField
      {...rest}
      type="text"
      inputMode="numeric"
      onBlur={handleBlur}
      onKeyDown={onKeyDown}
      onChange={handleChange}
      value={value}
      variant={compact ? 'compact' : 'base'}
      blockOutsideInteraction={props.blockOutsideInteraction}
      className={classNames(
        rest.className,
        styles.sidePanelNumericInput({
          fontSize: size || 'md',
        }),
      )}
    />
  );
}
