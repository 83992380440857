import {
  canEditEntity,
  entityLoggable,
  milestoneEditable,
} from '@float/common/lib/rights';
import { SerenaState } from '@float/common/selectors/serena';
import { CellItem } from '@float/types';

import { isItemEntityArchived } from '../Cell/MainCell/Item/box/utils/isItemEntityArchived';

export function getIsItemEditable(
  reduxData: SerenaState,
  logTimeView: boolean,
  item: CellItem,
) {
  const isSidebarItem = 'isSidebarItem' in item && item.isSidebarItem;

  if (isSidebarItem || item.type === 'timeRange') {
    return true;
  }

  if (item.type === 'milestone') {
    const project = reduxData.projects[item.entity.project_id];
    return milestoneEditable(project, reduxData.user);
  }

  if (logTimeView && item.type === 'timeoff') return false;
  if (item.type === 'loggedTime') {
    if (item.isInLockPeriod && !entityLoggable(item.entity, reduxData, true)) {
      return false;
    }

    if (reduxData.user.people_id == item.entity.people_id) return true;
    return canEditEntity(item.entity, reduxData.user, reduxData);
  }

  if (!('entity' in item)) return false;

  if (isItemEntityArchived(item.entity, reduxData)) return false;

  const temporaryId =
    'temporaryId' in item.entity ? item.entity.temporaryId : undefined;
  const integrationStatus =
    'integration_status' in item.entity
      ? item.entity.integration_status
      : undefined;

  return (
    !temporaryId &&
    !integrationStatus &&
    canEditEntity(item.entity, reduxData.user, reduxData)
  );
}
