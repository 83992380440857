import { sumOperation } from '@float/libs/utils/floats';
import { CellsMap, LoggedTimeCell, PersonCell } from '@float/types';

import { CellFns } from '../types';

export function sortCellItemsY(
  cell: LoggedTimeCell | PersonCell,
  cells: CellsMap | undefined,
  overlaps: CellFns['overlaps'],
) {
  cell.items.forEach((ci, idx) => {
    ci.sortIdx = idx;

    if (!ci.h) return;

    ci.y = 0;
    for (let i = 0; i < idx; i++) {
      const otherItem = cell.items[i];
      if (!otherItem.h) continue;

      if (overlaps(ci, otherItem)) {
        ci.y = Math.max(ci.y, otherItem.y! + otherItem.h);
      }
    }

    const cellItemBottomEdge = sumOperation(ci.y, ci.h);
    if (cellItemBottomEdge > cell.height) {
      cell.height = cellItemBottomEdge;
    }
  });
}
