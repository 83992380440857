import { CurrentUser, TaskMeta } from '@float/types';

export function getVisibleTasks(
  taskNames: (TaskMeta & { removed?: boolean })[],
  currentUser: CurrentUser,
) {
  if (!taskNames || !taskNames.length) {
    return [];
  }

  const visibleTasks = taskNames.filter(({ removed }) => !removed);

  if (visibleTasks.every((task) => task.task_name === '')) return [];

  return visibleTasks;
}
