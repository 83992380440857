import API3 from '@float/common/api3';
import { ReduxState } from '@float/common/reducers/lib/types';
import { logger } from '@float/libs/logger';

import {
  TIMER_FETCH_BEGIN,
  TIMER_FETCH_ERROR,
  TIMER_FETCH_SUCCESS,
} from './constants';
import {
  Dispatch,
  TimerFetchBeginAction,
  TimerFetchErrorAction,
  TimerFetchSuccessAction,
} from './types';

export const fetchTimersFromRange = (start_date: string, end_date: string) => {
  return async (
    dispatch: Dispatch<
      TimerFetchBeginAction | TimerFetchErrorAction | TimerFetchSuccessAction
    >,
    getState: () => ReduxState,
  ) => {
    const {
      fetchedRange: {
        start_date: currentFetchedStartedDate,
        end_date: currentFetchedEndDate,
      },
    } = getState().timer;

    if (
      currentFetchedStartedDate &&
      start_date >= currentFetchedStartedDate &&
      currentFetchedEndDate &&
      end_date <= currentFetchedEndDate
    ) {
      return; // already fetched this range
    }

    try {
      dispatch({ type: TIMER_FETCH_BEGIN });
      const activeTimers = await API3.getInactiveTimers({
        start_date,
        end_date,
      });

      dispatch({
        type: TIMER_FETCH_SUCCESS,
        fetchedRange: { start_date, end_date },
        shouldRefresh: false,
        timers: activeTimers,
      });
    } catch (e) {
      logger.error(
        'There was an error while fetching a range of inactive timers',
        e,
      );
      dispatch({ type: TIMER_FETCH_ERROR });
    }
  };
};
