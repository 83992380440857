import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';

import {
  Button,
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  ModalTitle,
  withConfirm,
} from '@float/ui/deprecated';

import { DowngradeToStarterIcon } from '../common/PlanChangeIcons';
import { StyledModalBody } from './_styles';
import { DowngradeFromProFeatures } from './DowngradeModal';
import { DefaultModalProps } from './types';

export const DisableModal = withConfirm(
  ({ onSubmit, close, confirm }: DefaultModalProps) => {
    const [loading, setLoading] = useState(false);

    async function submit() {
      setLoading(true);

      if (!confirm) {
        return onSubmit()
          .then(() => {
            close();
            setLoading(false);
          })
          .catch(() => {});
      }

      try {
        await onSubmit();
        close();
      } catch (e) {
        confirm?.({
          title: t`Cancel Trial`,
          message: t`Sorry, there was an error disabling Pro trial. Please
          refresh the page to try again. If the error persists, please contact
          our support team.`,
          hideCancel: true,
          onConfirm: close,
        });
      }
    }

    return (
      <Modal isOpen={true} onEnter={submit} onClose={close}>
        <ModalHeader>
          <DowngradeToStarterIcon />
          <ModalTitle>
            <Trans>Downgrade to Starter</Trans>
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <StyledModalBody>
            <h3>
              <Trans>Switching from Pro? You'll lose access to:</Trans>
            </h3>
            <DowngradeFromProFeatures />
          </StyledModalBody>
        </ModalBody>
        <ModalActions>
          <Button loader={loading} onClick={submit}>
            <Trans>Disable Pro features</Trans>
          </Button>
          <Button appearance="secondary" onClick={close}>
            Cancel
          </Button>
        </ModalActions>
      </Modal>
    );
  },
);
