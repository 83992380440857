import React, { createElement, MouseEvent } from 'react';
import cn from 'classnames';

import * as Icons from '@float/ui/deprecated/Earhart/Icons';
import * as DropdownMenu from '@float/ui/primitives/DropdownMenu';

import { ListItemData } from '../../primitives/List/types';

import * as styles from './styles.css';

export type MeatballMenuProps = {
  className?: string;
  items: Array<ListItemData>;
  sideOffset?: number;
  trigger: { label: string; iconSize?: number };
  vertical?: boolean;
  disabled?: boolean;
};

export const MeatballMenu = (props: MeatballMenuProps) => {
  const { className, disabled, items, sideOffset, trigger, vertical } = props;

  const handleClick = (e: MouseEvent, option: ListItemData) => {
    e.stopPropagation();

    if (option.onClick) option.onClick(option);
  };

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger
        onClick={(event) => event.stopPropagation()}
        disabled={disabled}
        aria-disabled={disabled}
        aria-label={trigger.label}
        // Some designs seem to require the vertical 3 dots.
        // Not sure if it's best to modify this or just require design to use horizontal one
        className={cn(
          className,
          styles.button,
          vertical ? styles.rotate : null,
        )}
      >
        <Icons.IconMeatballHorizontal
          className={styles.icon}
          size={trigger.iconSize || 24}
        />
      </DropdownMenu.Trigger>

      <DropdownMenu.Content sideOffset={sideOffset} collisionPadding={10}>
        {items.map((item, index) => {
          if (item.separator)
            return <DropdownMenu.Separator key={`separator_${index}`} />;

          return (
            <DropdownMenu.Item
              key={item.value}
              onClick={(e) => handleClick(e, item)}
              disabled={item.disabled}
            >
              {item.icon && createElement(item.icon, { size: 20 })}
              {item.value}
            </DropdownMenu.Item>
          );
        })}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};
