import React, { PropsWithChildren } from 'react';
import cn from 'classnames';

import * as styles from './styles.css';

type SidePanelTableColumnProps = {
  className?: string;
  // inactive means we don't render the element
  active?: boolean;
  // hide sets visibility to hidden
  hide?: boolean;
  style?: React.CSSProperties;
};

export const SidePanelTableColumn: React.FC<
  PropsWithChildren<SidePanelTableColumnProps>
> = ({ className, active = true, hide = false, children, style }) => {
  return active ? (
    <div
      role="cell"
      className={cn(styles.tableItemWrapper, className)}
      style={style}
      aria-hidden={hide}
    >
      {children}
    </div>
  ) : null;
};
