import React, { useState } from 'react';

import { divisionOperation, multiplyOperation } from '@float/libs/utils/floats';

import { getNumberOfDigitsAfterDot } from '../../../helpers/getNumberOfDigitsAfterDot';
import { InputAllocationPercentageProps } from '../InputAllocationPercentage';

export type UseInputAllocationPercentageParams = Pick<
  InputAllocationPercentageProps,
  | 'hoursPerDay'
  | 'hoursTotal'
  | 'onChange'
  | 'numberOfAllocationDaysInDateRange'
  | 'numberOfWorkingHoursInDateRange'
>;

export const useInputAllocationPercentage = ({
  hoursPerDay,
  hoursTotal,
  numberOfAllocationDaysInDateRange,
  numberOfWorkingHoursInDateRange,
  onChange,
}: UseInputAllocationPercentageParams) => {
  const [percentageInternalValue, setPercentageInternalValue] = useState<
    number | null
  >(() => {
    const percentageInitialValue = divisionOperation(
      100 * (hoursPerDay || 0) * numberOfAllocationDaysInDateRange,
      numberOfWorkingHoursInDateRange,
    );

    return percentageInitialValue;
  });

  const [hoursTotalInternalValue, setHoursTotalInternalValue] = useState<
    number | null
  >(() => {
    const hoursTotalInitialValue = multiplyOperation(
      numberOfAllocationDaysInDateRange,
      hoursPerDay || 0,
    );

    return hoursTotalInitialValue;
  });

  const handlePercentageChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    const value = e.currentTarget.value
      ? Number(e.currentTarget.value)
      : e.currentTarget.value;

    // No changes, don't emit any value updates
    if (percentageInternalValue === value) return;

    // Empty input case
    if (typeof value === 'string') {
      const hoursTotal = numberOfWorkingHoursInDateRange;
      const hoursPerDay = hoursTotal / numberOfAllocationDaysInDateRange;

      onChange({
        hoursPerDay,
        hoursTotal,
      });

      setPercentageInternalValue(null);
      setHoursTotalInternalValue(hoursTotal);

      return;
    }

    const hoursTotal = (value * numberOfWorkingHoursInDateRange) / 100;
    const hoursPerDay = hoursTotal / numberOfAllocationDaysInDateRange;

    const percentage = value;

    onChange({
      hoursPerDay,
      hoursTotal,
    });

    setPercentageInternalValue(percentage);
    setHoursTotalInternalValue(hoursTotal);
  };

  const handleHoursTotalChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    const value = e.currentTarget.value
      ? Number(e.currentTarget.value)
      : e.currentTarget.value;

    // No changes, don't emit any value updates
    if (hoursTotalInternalValue === value) return;

    // Empty input case
    if (typeof value === 'string') {
      onChange({
        hoursPerDay: null,
        hoursTotal: null,
      });

      const hoursTotal = numberOfWorkingHoursInDateRange;
      const hoursPerDay = hoursTotal / numberOfAllocationDaysInDateRange;

      onChange({
        hoursPerDay,
        hoursTotal,
      });

      setPercentageInternalValue(100);
      setHoursTotalInternalValue(null);

      return;
    }

    // Disallow more than 2 digits after the dot
    if (getNumberOfDigitsAfterDot(value) > 2) return;

    // Disallow values greater than 9999
    if (value > 9999) return;

    const hoursTotal = value;
    const hoursPerDay = hoursTotal / numberOfAllocationDaysInDateRange;

    const percentage = (100 * hoursTotal) / numberOfWorkingHoursInDateRange;

    onChange({
      hoursPerDay,
      hoursTotal,
    });

    setPercentageInternalValue(percentage);
    setHoursTotalInternalValue(hoursTotal);
  };

  return {
    percentageInternalValue,
    hoursTotalInternalValue,
    handlePercentageChange,
    handleHoursTotalChange,
  };
};
