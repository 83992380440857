import React from 'react';

export default ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5003 13.2498C18.5003 16.7017 15.702 19.5 12.2501 19.5C8.79829 19.5 6 16.7017 6 13.2498C6 9.79798 8.79829 6.99969 12.2501 6.99969C15.702 6.99969 18.5003 9.79798 18.5003 13.2498Z"
      className="stroke"
      stroke="#344765"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.7509 8.74996L17.5009 7.99994"
      className="stroke"
      stroke="#344765"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.2503 5H14.5003M12.2503 6.75004V5V6.75004ZM12.2503 5H10.0002H12.2503Z"
      className="stroke"
      stroke="#344765"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 10V13.2232L14.1819 15.4547"
      className="stroke"
      stroke="#344765"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
