import { getDefaultPhaseForDate } from '@float/common/lib/scheduleUtils';
import { Phase, Task } from '@float/types';

export type GetDefaultPhaseParams = {
  phaseManuallyChosen: boolean;
  isTimeoffMode: boolean;
  projectId: number;
  phaseId: number;
  startDate: string;
  originalTask?: Task;
  isNewTask: boolean;
  phasesOptions: Record<number, Phase[]>;
};

export const getDefaultPhase = (params: GetDefaultPhaseParams) => {
  const phases = params.phasesOptions[params.projectId];
  const phaseManuallyChosen =
    params.phaseManuallyChosen &&
    phases?.some((phase) => phase.phase_id === params.phaseId);

  if (phaseManuallyChosen || params.isTimeoffMode) {
    return {
      phaseId: params.phaseId,
      phaseManuallyChosen: params.phaseManuallyChosen,
    };
  }

  if (
    !params.isNewTask &&
    params.originalTask?.project_id === params.projectId
  ) {
    return {
      phaseId: params.originalTask.phase_id,
      phaseManuallyChosen: true,
    };
  }

  return {
    phaseId: getDefaultPhaseForDate({ phases, start: params.startDate }),
    phaseManuallyChosen: false,
  };
};
