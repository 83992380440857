import { createSelector } from 'reselect';

import { ReduxState } from '../../reducers/lib/types';
import { getViewsList } from './views';

const getPersonalViewsNames = createSelector([getViewsList], (views) => {
  return new Set(
    views
      .filter((view) => view.personal)
      .map((view) => view.name.toLowerCase()),
  );
});

const getSharedViewsNames = createSelector([getViewsList], (views) => {
  return new Set(
    views
      .filter((view) => view.personal === false)
      .map((view) => view.name.toLowerCase()),
  );
});

export const getIsUniqueViewName = (
  state: ReduxState,
  name: string,
  personal: boolean,
) => {
  const names = personal
    ? getPersonalViewsNames(state)
    : getSharedViewsNames(state);

  return names.has(name.toLowerCase()) === false;
};
