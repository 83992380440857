import React, { PropsWithChildren } from 'react';
import cn from 'classnames';

import * as styles from './styles.css';

export const SidePanelActions: React.FC<
  PropsWithChildren<{ className?: string }>
> = ({ children, className }) => {
  return (
    <div className={cn(className, styles.sidePanelActions)}>{children}</div>
  );
};
