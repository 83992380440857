import React from 'react';
import { t } from '@lingui/macro';

import VirtualSelect from '@float/ui/deprecated/VirtualSelect/VirtualSelect';

type CurrencyFieldProps = {
  placement: string;
  options: unknown[];
  fieldLink: {
    onChange: (payload: { target: HTMLElement }) => void;
    value?: {
      value: unknown;
    };
  };
};

const CurrencyField = ({
  placement,
  options,
  fieldLink,
}: CurrencyFieldProps) => {
  return (
    <VirtualSelect
      // @ts-expect-error VirtualSelect has not been converted to TS
      label={t`Currency`}
      visibleItems={6}
      size="large"
      nonNullable
      onChange={(e: HTMLElement) => fieldLink.onChange({ target: e })}
      options={options}
      optionsPlacement={placement}
      value={fieldLink.value?.value}
    />
  );
};

export default CurrencyField;
