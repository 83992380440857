import { t } from '@lingui/macro';

import { getPluralDays } from './getPluralDays';

export const getDaysRequestedText = (
  requestedDays: number,
  isTimeoffRequest: boolean,
) => {
  const days = getPluralDays(requestedDays);

  if (isTimeoffRequest) return t`${days} requested`;

  return t`${days} planned`;
};
