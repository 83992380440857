export const createDropShadowFilter = (svg) => {
  const filter = svg
    .append('defs')
    .append('filter')
    .attr('id', 'drop-shadow')
    .attr('height', '350%')
    .attr('width', '350%');

  filter
    .append('feDropShadow')
    .attr('in', 'SourceAlpha')
    .attr('stdDeviation', 2.5)
    .attr('flood-opacity', 0.2)
    .attr('dx', 0)
    .attr('dy', 2)
    .attr('result', 'dropShadowOut');

  const feMerge = filter.append('feMerge');
  feMerge.append('feMergeNode').attr('in', 'dropShadowOut');
  feMerge.append('feMergeNode').attr('in', 'SourceGraphic');
};

export const createTimeoffStripes = (svg) => {
  const defs = svg.append('defs');

  defs
    .append('pattern')
    .attr('id', 'timeoff-stripe')
    .attr('patternUnits', 'userSpaceOnUse')
    .attr('width', 3)
    .attr('height', 3)
    .attr('patternTransform', 'rotate(45 0 0)')
    .append('line')
    .attr('x1', '0')
    .attr('y1', '0')
    .attr('x2', '0')
    .attr('y2', '10')
    .attr('style', 'stroke: black; stroke-width: 1');

  const checkerPattern = defs
    .append('pattern')
    .attr('id', 'tentative-checkerboard')
    .attr('patternUnits', 'userSpaceOnUse')
    .attr('width', 4)
    .attr('height', 4);

  checkerPattern
    .append('rect')
    .attr('x', '0')
    .attr('y', '0')
    .attr('width', 2)
    .attr('height', 2)
    .attr('style', 'fill: white');

  checkerPattern
    .append('rect')
    .attr('x', '2')
    .attr('y', '2')
    .attr('width', 2)
    .attr('height', 2)
    .attr('style', 'fill: white');
};
