import React from 'react';
import styled, { css } from 'styled-components';

import * as Colors from '../Earhart/Colors';
import * as Typography from '../Earhart/Typography';

export const Label = styled.div`
  padding-top: 2px;
  font-size: 14px;
  outline: none;
  color: ${({ color, theme }) => theme[color || 'emphasisMedium']};
`;

export const FieldLabel = styled.label`
  ${Typography.Label12.M500};

  display: block;

  color: ${Colors.FIN.Lt.Emphasis.Medium};

  margin-bottom: 8px;

  &:empty {
    display: none;
  }
`;

export const LinkLabel = styled.label`
  color: ${(p) => p.theme.emphasisMedium};
  cursor: pointer;
  text-decoration: underline;
  outline: none !important;
  ${(p) => p.theme.text.small}
`;

const CommonButtonLabelStyles = styled.div`
  white-space: nowrap;
  font-weight: 600;
  line-height: 0.75;
  margin-top: -2px;
`;

const BoldLabel = styled(CommonButtonLabelStyles)`
  font-size: 14px;
`;

const SmallLabel = styled(CommonButtonLabelStyles)`
  font-size: 14px;
  ${(p) =>
    p.normalWeight &&
    css`
      font-weight: 400;
      margin-top: 0;
    `};
`;

const BigLabel = styled(CommonButtonLabelStyles)`
  font-size: 18px;
`;

export const ButtonLabel = ({ size, ...rest }) => {
  switch (size) {
    case 'small':
      return <SmallLabel {...rest} />;
    case 'verySmall':
      return <SmallLabel {...rest} />;
    case 'big':
      return <BigLabel {...rest} />;
  }
  return <BoldLabel {...rest} />;
};
