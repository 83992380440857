import { isEqual } from 'lodash';

export function diffEntityPayload<V extends Record<string | number, unknown>>(
  a: V,
  b: V,
  idKey?: keyof V,
) {
  const diff: Partial<V> = {};
  let empty = true;

  for (const key of Object.keys(a) as Array<keyof V>) {
    if (key === idKey) {
      diff[key] = a[key];
    }

    if (!isEqual(a[key], b[key])) {
      empty = false;
      diff[key] = a[key];
    }
  }

  if (empty) return null;

  return diff;
}

export function diffEntityListPayload<
  V extends Record<string | number, unknown>,
  PK extends keyof V,
>(a: V[], b: V[], idKey: PK, skip?: PK[]) {
  const added = new Set<V[PK]>();
  const add = [];
  const del = [];

  for (const entity of a) {
    const id = entity[idKey];

    if (!id) {
      add.push(entity);
      continue;
    }

    added.add(id);

    const match = b.find((item) => item[idKey] === id);

    if (!match || !isEqual(entity, match)) {
      add.push(entity);
    }
  }

  for (const entity of b) {
    const id = entity[idKey];

    if (id && !added.has(id)) {
      del.push(id);
    }
  }

  if (add.length) {
    if (del.length) {
      return { add, del };
    }

    return { add };
  } else if (del.length) {
    return { del };
  }

  return undefined;
}
