import React, { useId } from 'react';
import { Controller, FieldPath } from 'react-hook-form';

import { DatePicker } from '@float/common/components/DatePicker';
import { moment } from '@float/libs/moment';
import { M500NoUnderline } from '@float/ui/deprecated/Earhart/Typography/Label13';

import { ProjectFormData } from '../../types';
import { FormField } from '../FormFieldsSection';

import * as styles from './styles.css';

export type DatePickerFieldProps = {
  name: FieldPath<ProjectFormData>;
  label: string;
  placeholder?: string;
  container?: HTMLElement;
  hideLabel?: boolean;
  autoFocus?: boolean;
  onChange?: (value: string) => void;
  readOnly?: boolean;
  disableDayFn?: (date: Moment) => void;
};

export function DatePickerField(props: DatePickerFieldProps) {
  const id = useId();

  return (
    <FormField
      label={props.label}
      htmlFor={id}
      hideLabel={props.hideLabel}
      className={styles.calendarButton}
      stopClickPropagation
    >
      <Controller
        name={props.name}
        render={({ field }) => (
          <DatePicker
            disabled={props.readOnly}
            inputId={id}
            name={props.name}
            container={props.container}
            onHidden={() => {
              field.onBlur();
            }}
            placeholder={props.placeholder}
            onChange={(date: Moment) => {
              const value = date.format('YYYY-MM-DD');
              field.onChange(value);
              props.onChange?.(value);
            }}
            value={moment(field.value)}
            inputTextStyle={M500NoUnderline}
            inputWidth={80}
            inputClassname={styles.calendarInput}
            disableDayFn={props.disableDayFn}
          />
        )}
      />
    </FormField>
  );
}
