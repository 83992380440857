import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { List } from '../List';

export const Root = DropdownMenu.Root;

export const Trigger = (
  props: React.ButtonHTMLAttributes<HTMLButtonElement>,
) => {
  return (
    <DropdownMenu.Trigger {...props}>{props.children}</DropdownMenu.Trigger>
  );
};

export const Content = (
  props: DropdownMenu.DropdownMenuContentProps &
    React.RefAttributes<HTMLDivElement>,
) => {
  return (
    <DropdownMenu.Portal>
      <DropdownMenu.Content sideOffset={5} align="start" loop {...props}>
        <List.Box>{props.children}</List.Box>
      </DropdownMenu.Content>
    </DropdownMenu.Portal>
  );
};

export const Item = (
  props: DropdownMenu.DropdownMenuItemProps &
    React.RefAttributes<HTMLDivElement>,
) => {
  return (
    <DropdownMenu.Item {...props} asChild>
      <List.Item.Root>{props.children}</List.Item.Root>
    </DropdownMenu.Item>
  );
};

export const Separator = (
  props: DropdownMenu.DropdownMenuSeparatorProps &
    React.RefAttributes<HTMLDivElement>,
) => {
  return (
    <DropdownMenu.Separator asChild>
      <List.Separator />
    </DropdownMenu.Separator>
  );
};

export const Group = DropdownMenu.Group;
