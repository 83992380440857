import React, { useState } from 'react';

import {
  ConfirmDeleteTeam,
  ConfirmPauseSubscription,
} from '@float/common/components/ConfirmDeleteTeam';
import { usePrevious } from '@float/common/lib/hooks/usePrevious';
import { useAppSelector } from '@float/common/store';
import { Button } from '@float/ui/deprecated';
import { Card } from '@float/ui/deprecated/Earhart/Cards';

import { StyledRow } from '../styles';
import { ComparisonTable } from './styles';

function StopSubscription() {
  const [modal, setModal] = useState<
    'pause-subscription' | 'delete-team' | null
  >(null);

  const companyName = useAppSelector(
    (state) => state.companyPrefs.company_name,
  ) as string;

  const previous = usePrevious(modal);

  return (
    <Card>
      {modal === 'pause-subscription' && (
        <ConfirmPauseSubscription
          onClose={() => setModal(null)}
          /** We want to skip the animation when moving from the delete-team modal */
          noAnimation={previous === 'delete-team'}
        />
      )}
      {modal === 'delete-team' && (
        <ConfirmDeleteTeam
          onClose={() => setModal(null)}
          onPauseClick={() => setModal('pause-subscription')}
          companyName={companyName}
        />
      )}
      <StyledRow>
        <h5>Don't need to use Float right now?</h5>
      </StyledRow>
      <StyledRow>
        <p>
          If you don't have any projects to manage right now and you'd like to
          stop paying for Float, you can pause your subscription for up to 12
          months. If Float just isn't right for you, you can delete your team
          hassle-free.
        </p>
      </StyledRow>
      <StyledRow>
        <ComparisonTable>
          <thead>
            <tr>
              <th>
                <h6>Pause subscription for up to 12 months for free</h6>
              </th>
              <th>
                <h6>Delete your team forever</h6>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>You won't be charged while your subscription is paused</td>
              <td>You'll never get charged for Float again</td>
            </tr>
            <tr>
              <td>
                You and your team will lose access while your subscription is
                paused. We'll keep all of your projects, people and data for up
                to 12 months
              </td>
              <td>
                Delete all of your projects, people and data immediately and
                permanently
              </td>
            </tr>
            <tr>
              <td>Pick up right where you left off at any time</td>
              <td>
                Start all over again when you need to plan & schedule projects
              </td>
            </tr>
            <tr>
              <td>
                We'll be happy to see you stick around! We'll continue to let
                you know about new improvements to Float
              </td>
              <td>We'll be sorry to see you go!</td>
            </tr>
            <tr>
              <td>
                <Button
                  appearance="tertiary"
                  onClick={() => setModal('pause-subscription')}
                >
                  Pause your subscription
                </Button>
              </td>
              <td>
                <Button
                  appearance="clear-danger"
                  onClick={() => setModal('delete-team')}
                >
                  Delete team
                </Button>
              </td>
            </tr>
          </tbody>
        </ComparisonTable>
      </StyledRow>
    </Card>
  );
}

export default StopSubscription;
