import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { AppDispatchStrict } from '@float/common/store';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { CompanyPreferences } from '@float/types/companyPreferences';
import { Person } from '@float/types/person';

import { UPDATE_COMPANY_PREFS_SUCCESS } from '../companyPrefs';

const getActivePeople = (people: Person[]) => {
  return people.reduce((total, p) => (p.active ? total + 1 : total), 0);
};

const getActivePlaceholder = (people: Person[]) => {
  return people.reduce(
    (total, p) => (p.active && p.people_type_id === 3 ? total + 1 : total),
    0,
  );
};

const getExceedingAmount = (number: number, max: number): number => {
  if (number <= max) {
    return 0;
  } else {
    return number - max;
  }
};

export const updatePlanCount = (
  dispatch: AppDispatchStrict,
  getState: () => ReduxStateStrict,
) => {
  const {
    people: { people },
    companyPrefs: { placeholder_limit: placeholderLimit },
  } = getState();

  const allPeople = Object.values(people);

  const placeholderCount = getActivePlaceholder(allPeople);
  const peopleCount = getActivePeople(allPeople);

  let payload: Partial<CompanyPreferences>;

  if (featureFlags.isFeatureEnabled(FeatureFlag.PlaceholdersPackaging)) {
    const exceedingPlaceholders = getExceedingAmount(
      placeholderCount,
      placeholderLimit,
    );

    payload = {
      people_count: peopleCount - placeholderCount + exceedingPlaceholders,
      placeholder_people: Math.min(placeholderCount, placeholderLimit),
    };
  } else {
    payload = {
      people_count: peopleCount,
    };
  }

  dispatch({
    type: UPDATE_COMPANY_PREFS_SUCCESS,
    noRequest: true,
    payload,
  });
};
