import styled, { css } from 'styled-components';

import LoaderEl from '@float/common/components/elements/Loader';
import { DropdownSelect } from '@float/ui/deprecated';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';
import { InputWrapper } from '@float/ui/deprecated/Input/styles';
import { RowHeader, RowLabel } from '@float/ui/deprecated/VirtualSelect/styles';

// TODO: earhart - functions header height - 112
export const Sidebar = styled.div`
  position: fixed;
  right: 0;
  top: 112px;
  bottom: 0;
  transform: translateX(${({ visible }) => (visible ? '0%' : '100%')});
  transition: transform 0.2s ease-in;
  width: 100%;
  max-width: 400px;
  height: calc(100% - 112px);
  z-index: 998;
  background: #fff;
  box-shadow: ${({ visible }) =>
    visible ? '-2px 2px 15px rgba(204, 204, 204, 0.5)' : 'none'};

  &,
  & * {
    box-sizing: border-box;
  }

  @media (max-width: 1010px) {
    top: 96px;
  }
`;

export const Wrapper = styled.div`
  height: 100%;
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const HeaderTopRow = styled.div`
  padding: 17px 18px 9px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderBottomRow = styled.div`
  padding: 0 18px 16px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${DropdownSelect._styles.StyledDropdownSelect} {
    & + ${DropdownSelect._styles.StyledDropdownSelect} {
      margin-left: 20px;
    }
  }
`;

export const Title = styled.span`
  ${Typography.Label14.SB600};

  color: ${(p) => p.theme.charcoalGrey};
`;

export const Close = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 20px;
`;

export const Loader = styled(LoaderEl)`
  position: static !important;
  margin: 15px auto 15px auto !important;

  &.first-load {
    margin: 0 !important;
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const List = styled.div`
  display: block;
  height: calc(100% - 146px);
  position: relative;

  & .ReactVirtualized__Grid {
    outline: none !important;
  }
`;

export const ListItem = styled.div`
  position: relative;

  display: flex;
  flex-flow: column;
  justify-content: space-between;

  max-width: 378px;
  min-height: 42px;
  margin: 0 12px 3px;
  border-radius: 3px;
  background: #eee;
  padding: 7px 56px 7px 10px;
  user-select: none;

  ${(p) =>
    p.onMouseDown &&
    css`
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    `}

  & * {
    pointer-events: none;
  }
  ${(p) =>
    p.hasAvatar &&
    css`
      min-height: 70px;
    `}
  ${(p) =>
    !p.hasAvatar &&
    p.hasIssueKey &&
    css`
      min-height: 62px;
    `}
`;

export const ListItemDrag = styled(ListItem)`
  padding: 5px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
`;

export const ListItemGroup = styled.div`
  min-height: 34px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.19px;
  padding: 8px 16px 9px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  user-select: none;
  ${(p) =>
    p.firstGroup &&
    css`
      min-height: 24px;
      padding: 0 16px;
    `}
`;

export const GroupIcon = styled.div`
  margin-right: 1px;
  width: 24px;
  height: 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
  font-size: 19px;
  color: ${(p) => p.theme.blueGrey};
  top: 2px;
  position: relative;
`;

export const AvatarKey = styled.div`
  display: flex;
  max-height: 24px;
  margin-top: 2px;
`;

const Dot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #fff;
`;

export const Indicator = styled(Dot)`
  position: absolute;
  top: 4px;
  right: 4px;
`;

export const Hours = styled.p`
  position: absolute;
  bottom: 4px;
  right: 8px;
`;

export const Name = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 1.15;
  ${(p) => p.theme.antialias}
`;

const icon = css`
  img,
  svg {
    width: 14px;
    margin-right: 5px;
  }
`;

export const IssueKey = styled(Name)`
  font-weight: normal;
  display: inline-flex;
  align-items: center;

  ${icon}
`;

export const Search = styled.div`
  margin: 0 auto 10px;
  width: 100%;
  max-width: 378px;

  ${InputWrapper} {
    padding-left: 8px;
    padding-bottom: 4px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    min-height: 36px;
  }

  .icon-search {
    position: relative;
    top: 1px;
    margin-right: 3px;
  }

  .icon-close-small {
    margin-right: 11px;
    margin-top: 2px;
  }

  ${RowHeader} {
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.12px;
    color: ${(p) => p.theme.blueGrey};
  }

  ${RowLabel} {
    font-size: 16px;
    font-weight: normal;
    letter-spacing: -0.27px;
    color: ${(p) => p.theme.charcoalGrey};

    & > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .input-container input {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.38;
    color: ${(p) => p.theme.charcoalGrey};
  }
`;

export const NoResults = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -75%);
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 1.38;
  color: ${(p) => p.theme.blueGrey};

  svg {
    margin-bottom: 10px;
  }

  .search-term {
    font-weight: bold;
  }
`;

export const Tooltip = styled.div`
  width: 310px;
  max-width: 100%;
  padding: 3px 0 6px;
  text-align: left;
  font-size: 13px;
  line-height: 1.15;
  color: ${(p) => p.theme.blueGrey};
`;

export const TooltipPerson = styled.div`
  margin-bottom: 6px;
  display: flex;
  align-items: center;
`;

export const TooltipPersonName = styled.span`
  margin-left: 10px;
  font-weight: bold;
  color: ${(p) => p.theme.charcoalGrey};
`;

export const TooltipItemName = styled.div`
  line-height: 1.23;
  color: ${(p) => p.theme.charcoalGrey};
`;

export const TooltipItemKey = styled.div`
  color: ${(p) => p.theme.blueGrey};
  margin-top: 5px;
  display: inline-flex;
  align-items: center;

  ${icon}
`;

export const TooltipFooter = styled.div`
  margin-top: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const TooltipItemScheduled = styled.div`
  margin-right: auto;
  display: flex;
  align-items: center;
`;

export const TooltipItemIndicator = styled(Dot)`
  width: 8px;
  height: 8px;
  background-color: #6fc058;
  margin-right: 5px;
`;
