import React from 'react';

import { useMarkAsSeenOnUnmount } from './hooks/useMarkAsSeenOnUnmount';
import { useNotificationsData } from './hooks/useNotificationsData';
import { useNotificationsPanelAnalytics } from './hooks/useNotificationsPanelAnalytics';
import { useNotificationsPanelController } from './hooks/useNotificationsPanelController';
import { NotificationsPanelView } from './NotificationsPanelView';

import * as styles from './NotificationsPanel.css';

export const NotificationsPanel = () => {
  const {
    currentFilter,
    isFilterVisible,
    onClickMore,
    onClickNotification,
    onFilterUpdate,
  } = useNotificationsPanelController();

  // load notifications feed data
  const { isLoadingFirstPage, loadMore, notifications } =
    useNotificationsData(currentFilter);

  // track notification panel analytics
  useNotificationsPanelAnalytics(notifications);

  // mark all notifications as seen on closing the notifications panel
  useMarkAsSeenOnUnmount();

  return (
    <>
      <NotificationsPanelView
        className={styles.notificationsPanelView}
        currentFilter={currentFilter}
        isFilterVisible={isFilterVisible}
        isLoadingFirstPage={isLoadingFirstPage}
        loadMore={loadMore}
        notifications={notifications}
        onClickMore={onClickMore}
        onClickNotification={onClickNotification}
        onFilterUpdate={onFilterUpdate}
      />
    </>
  );
};
