import React from 'react';

import { OnOffToggle } from '@float/ui/deprecated/Earhart/Toggles/OnOffToggle';

import {
  StyledCard,
  StyledLabel,
  StyledLink,
  StyledRow,
  StyledRowHeader,
} from '../styles';

type AdminTimeTrackingControlCardProps = {
  isTimeTrackingEnabled: boolean;
  isDisabled: boolean;
  onTimeTrackingChange: () => void;
};

export const AdminTimeTrackingControlCard = ({
  isTimeTrackingEnabled,
  isDisabled,
  onTimeTrackingChange,
}: AdminTimeTrackingControlCardProps) => {
  return (
    <StyledCard>
      <StyledRowHeader disabled={isDisabled || !isTimeTrackingEnabled}>
        <h5>Log time</h5>

        <OnOffToggle
          isOn={isTimeTrackingEnabled}
          onChange={onTimeTrackingChange}
          disabled={isDisabled}
        />
      </StyledRowHeader>

      <StyledRow>
        <StyledLabel disabled={isDisabled || !isTimeTrackingEnabled}>
          Enable your team to log their actual hours with pre-filled timesheets
          and our desktop timer app.{' '}
          <StyledLink
            disabled={!isTimeTrackingEnabled}
            href="https://support.float.com/en/articles/3616990-time-tracking"
            target="_blank"
          >
            Learn more
          </StyledLink>
        </StyledLabel>
      </StyledRow>
    </StyledCard>
  );
};
