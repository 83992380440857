import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import api3 from '@float/common/api3';
import { Person } from '@float/types/person';
import { RepeatState } from '@float/types/repeatState';
import { Timeoff } from '@float/types/timeoff';
import { TimeoffBalanceType, TimeoffType } from '@float/types/timeoffType';

import { roundToTwoDecimals } from '../../utils';
import { formatDate } from '../helpers/formatDate';

export type UseTimeoffBalanceDataProps = {
  endDate: Date | DateString;
  ignoreTimeoffId?: Timeoff['timeoff_id'] | null;
  personId?: Person['people_id'];
  repeatEndDate: Date | DateString | null;
  repeatState: RepeatState;
  timeoffType: TimeoffType;
};

type FetchTimeoffBalanceParams = {
  date: string;
  ignoreTimeoffId?: Timeoff['timeoff_id'] | null;
  peopleId?: Person['people_id'];
  timeoffTypeId: TimeoffType['timeoff_type_id'];
};

const fetchTimeoffBalance = async (params: FetchTimeoffBalanceParams) => {
  const res = await api3.getTimeoffBalance(params);
  return res?.balance ? roundToTwoDecimals(res.balance) : 0;
};

export const useTimeoffBalanceData = (props: UseTimeoffBalanceDataProps) => {
  const {
    endDate,
    ignoreTimeoffId,
    personId,
    repeatEndDate,
    repeatState,
    timeoffType,
  } = props;

  const {
    balance_type: balanceType,
    timeoff_type_id: timeoffTypeId,
    active,
  } = timeoffType;

  const todayDate = useMemo(() => formatDate(new Date().toString()), []);
  const previewAccruedEnd = useMemo(
    () =>
      formatDate(
        repeatState > RepeatState.NoRepeat && repeatEndDate
          ? repeatEndDate
          : endDate,
      ),
    [repeatEndDate, repeatState, endDate],
  );

  const shouldFetchAccrued =
    active &&
    (balanceType === TimeoffBalanceType.AccruedPerYear ||
      balanceType === TimeoffBalanceType.AccruedPerAnniversary);

  const shouldFetchEndOfPeriod =
    active &&
    (balanceType === TimeoffBalanceType.GrantedPerAnniversary ||
      balanceType === TimeoffBalanceType.GrantedPerYear);

  // balance for accrued time off type as of today
  const { data: todayBalance } = useQuery({
    queryKey: [
      'todayBalance',
      timeoffTypeId,
      todayDate,
      personId,
      ignoreTimeoffId,
    ],
    enabled: Boolean(shouldFetchAccrued),
    queryFn: () =>
      fetchTimeoffBalance({
        timeoffTypeId,
        date: todayDate,
        peopleId: personId,
        ignoreTimeoffId,
      }),
  });

  // balance for accrued time off type as of end date
  const { data: nextBalance } = useQuery({
    queryKey: [
      'nextBalance',
      timeoffTypeId,
      previewAccruedEnd,
      personId,
      ignoreTimeoffId,
    ],
    enabled: Boolean(shouldFetchAccrued),
    queryFn: () =>
      fetchTimeoffBalance({
        timeoffTypeId,
        date: previewAccruedEnd,
        peopleId: personId,
        ignoreTimeoffId,
      }),
  });

  // balance for granteed time off type
  const { data: endOfPeriodBalance } = useQuery({
    queryKey: [
      'endOfPeriodBalance',
      timeoffTypeId,
      endDate,
      personId,
      ignoreTimeoffId,
    ],
    enabled: Boolean(shouldFetchEndOfPeriod),
    queryFn: () =>
      fetchTimeoffBalance({
        timeoffTypeId,
        date: formatDate(endDate),
        peopleId: personId,
        ignoreTimeoffId,
      }),
    refetchOnMount: true,
  });

  return {
    today: todayBalance ?? null,
    next: nextBalance ?? null,
    endOfPeriod: endOfPeriodBalance ?? null,
  };
};
