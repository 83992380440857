import { createSelector } from 'reselect';

import { sortByDateAndName } from '@float/common/lib/itemSort';
import { Phase } from '@float/types';

import { getPhases } from './phases';

export const getPhasesByProjectId = createSelector(
  [getPhases, (_, id: number | undefined) => id],
  (phases: Phase[], projectId) => {
    if (!projectId) return [];

    return sortByDateAndName(
      phases.filter((phase) => phase.project_id === projectId),
      'start_date',
      'phase_name',
    );
  },
);
