import React from 'react';

import VirtualSelect from '@float/ui/deprecated/VirtualSelect/VirtualSelect';

export default function TimeFormatField(props) {
  const { fieldLink } = props;

  const options = [
    {
      label: '12-hour clock',
      value: 0,
    },
    {
      label: '24-hour clock',
      value: 1,
    },
  ];

  return (
    <VirtualSelect
      label="Time format"
      visibleItems={6}
      size="large"
      nonNullable
      onChange={(e) => fieldLink.onChange({ target: e })}
      options={options}
      value={fieldLink.value}
    />
  );
}
