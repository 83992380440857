export const PROJECT_COLUMNS = [
  {
    key: 'project',
    title: 'Project',
    width: '28%',
  },
  {
    key: 'phaseExpand',
    title: 'Phases',
    width: '56px',
    sortable: false,
  },
  {
    key: 'client',
    title: 'Client',
  },
  {
    key: 'tags',
    title: 'Tags',
    sortable: false,
  },
  {
    key: 'status',
    title: 'Status',
    width: '48px',
  },
  {
    key: 'notes',
    width: '24px',
  },
  {
    key: 'budget',
    title: 'Budget',
    width: '60px',
  },
  {
    key: 'nonBillableTag',
    width: '88px',
  },
  {
    key: 'start',
    title: 'Start',
    width: '88px',
  },
  {
    key: 'end',
    title: 'End',
    width: '88px',
  },
  {
    key: 'pm',
    title: 'Owner',
    width: '42px',
  },
];

export const PROJECT_STATUS = {
  ACTIVE: 1,
  ACTIVE_ARCHIVED: 2,
  ARCHIVED: 0,
};
