// API used to show up the measurements on the performance devTools
// Since we do not care about failures we silence the errors
export function performanceMarkStart(id: string) {
  try {
    performance.mark(`${id}-start`);
  } catch (err) {}
}

export function performanceMeasure(id: string, startMark?: string) {
  try {
    performance.mark(`${id}-end`);
    performance.measure(id, startMark ?? `${id}-start`, `${id}-end`);
  } catch (err) {}
}
