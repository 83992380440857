import React from 'react';

import * as styles from '../Cycles.css';

type CooldownCycleProps = {
  dayWidth: number;
  totalDays: number;
};

export const CooldownCycle: React.FC<CooldownCycleProps> = ({
  dayWidth,
  totalDays,
}) => {
  const width = dayWidth * totalDays;

  return (
    <div
      className={styles.singleCycleWrapper}
      style={{
        width,
        minWidth: width,
      }}
    >
      <div className={styles.cooldownCycleIndicatorSpan} />
    </div>
  );
};
