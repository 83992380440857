import styled, { css } from 'styled-components';

export default styled.div`
  position: absolute;
  width: auto;

  ${({ isTokenSearch }: { isTokenSearch: boolean }) =>
    isTokenSearch &&
    css`
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0;
      border: 0;
      border-radius: 4px;

      &,
      & input {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.1px;
      }

      & input {
        border-radius: 0 4px 4px 0;
      }

      & input:focus {
        outline: 0;
      }

      & .token-search-wrap {
        margin-left: 0;
        padding-left: 0;
        align-items: center;
      }

      & label {
        display: flex;
        align-items: center;
      }

      & .field-options button {
        padding: 0 !important;
        border: 0 !important;
        display: flex;
        align-items: center;
      }
    `}

  .token-search-wrap {
    position: relative;
    white-space: nowrap;
  }

  .token-search__flt-icon-activity {
    padding-left: 0 !important;
    margin-left: 0 !important;

    .flt-icon {
      margin-top: -6px;
    }
  }

  .token-search__flt-icon-save-search {
    border-right: none;
  }

  .field-options button {
    padding-left: 10px;
    border-left: 1px solid #f1f3f5;
  }

  .field-options button:first-child {
    border-left: none;
  }

  .popover.search-autocomplete {
    max-height: 450px;
  }

  .search-cats {
    padding: 0;
    overflow-y: hidden;

    .ReactVirtualized__List {
      height: auto !important;
      max-height: 410px;
    }

    .ReactVirtualized__Grid__innerScrollContainer {
      margin-bottom: 0;
      margin-top: 0px;

      li.cat-name {
        cursor: default;
        text-transform: uppercase;
        z-index: 10;
      }
    }
  }
`;
