import { isArray, reduce, transform } from 'lodash';

import {
  CATEGORY_TO_TYPE,
  OLD_TYPE_TO_TYPE,
  ONLY_INITIAL_QUERY,
} from './constants';
import {
  getValueAndOpFromEncodedStr,
  getValueAndOpFromStr,
} from './getValueAndOpFromStr';
import { isNot, isOr } from './operators';

export { isOp, OPERATOR_PRIMITIVES, isAnd, isNot, isOr } from './operators';
export {
  getValueAndOpFromStr,
  getValueAndOpFromEncodedStr,
} from './getValueAndOpFromStr';

export * from './constants';

export const TYPE_TO_CATEGORY = transform(
  CATEGORY_TO_TYPE,
  (acc, type, category) => {
    acc[type] = category;
  },
);

// eslint-disable-next-line
const containsOnlyAsciiRE = /^[\u0000-\u007f]*$/;

// make filters case and accent insensitive
// https://app.asana.com/0/1201161864415673/1201424028569010/f
/**
 *
 * @param {string | undefined | null} str
 * @returns  string
 */
export const normalize = (str) => {
  if (str == null || !str.toLowerCase) return '';

  str = str.trim().toLowerCase();

  if (!containsOnlyAsciiRE.test(str)) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  return str;
};

export const containsInitialQuery = (query) => {
  const shouldCleanUrl = ONLY_INITIAL_QUERY.some((key) => !!query[key]);
  return shouldCleanUrl;
};

export const cleanQueryFromInitialOnly = (query) => {
  const cleanedQuery = { ...query };

  ONLY_INITIAL_QUERY.forEach((type) => {
    delete cleanedQuery[type];
  });

  return cleanedQuery;
};

export const TYPE_TO_OLD_TYPE = transform(
  OLD_TYPE_TO_TYPE,
  (acc, type, oldType) => {
    acc[type] = oldType;
  },
);

export const TYPES = Object.keys(TYPE_TO_CATEGORY).filter(
  (f) => f !== 'savedSearch',
);
export const OLD_TYPES = Object.keys(OLD_TYPE_TO_TYPE);

export const KEYS = {
  enter: 13,
  backspace: 8,
  del: 46,
  tab: 9,
  escape: 27,
  up: 38,
  down: 40,
  left: 37,
  right: 39,
  modifiers: [16, 17, 18, 91, 92, 224],
};

function parsePreEarhartSavedSearchToFilters(val, oldType) {
  const searchObjFilters = [];
  const vals = Array.isArray(val) ? val : [val];
  const newType = OLD_TYPE_TO_TYPE[oldType];
  const andVals = [];
  const orVals = [];
  vals.forEach((v) => {
    const { val, operator } = getValueAndOpFromStr(v);
    if (operator) {
      andVals.push({ val, operator });
    } else {
      orVals.push(val);
    }
  });
  if (orVals.length) {
    const isSingleVal = orVals.length === 1;
    searchObjFilters.push({
      type: newType,
      val: isSingleVal ? orVals[0] : orVals,
      normalizedVal: isSingleVal ? normalize(orVals[0]) : orVals.map(normalize),
      operator: '',
    });
  }
  andVals.forEach(({ val, operator }) => {
    searchObjFilters.push({
      type: newType,
      val,
      normalizedVal: normalize(val),
      operator: operator === '+' ? '' : operator, // previous + is implied in new structure, but we do want to retain -
    });
  });
  return searchObjFilters;
}

/**
 * @param savedSearches
 * @returns {import('@float/types/view').SavedSearchFilterToken[]}
 */
export const savedSearchesToFilters = (savedSearches) => {
  return reduce(
    savedSearches,
    (acc, searchObj, name) => {
      const isPreEarhartFilter = !Array.isArray(searchObj);
      const filters = reduce(
        searchObj,
        (acc2, val, oldType) => {
          if (isPreEarhartFilter) {
            try {
              const searchObjFilters = parsePreEarhartSavedSearchToFilters(
                val,
                oldType,
              );
              return [...acc2, ...searchObjFilters];
            } catch (err) {
              return acc2;
            }
          }

          const { type, val: savedVal, operator = '' } = val;
          return [...acc2, { type, val: savedVal, operator }];
        },
        [],
      );

      return [
        ...acc,
        {
          type: 'savedSearch',
          val: name,
          normalizedVal: normalize(name),
          filters,
        },
      ];
    },
    [],
  );
};

export const PROJECT_RELATED_TYPES = [
  'project',
  'projectTag',
  'projectStatus',
  'projectOwner',
  'phase',
  'taskStatus',
  'client',
  'task',
  'timeoff',
  'timeoffStatus',
];

export function getFiltersFromQueryString(qs) {
  const filters = [];
  if (!qs?.length) return filters;

  const parts = (qs[0] === '?' ? qs.substr(1) : qs).split('&');
  let i = 0;
  parts.forEach((part) => {
    const pair = part.split('=');
    if (pair.length < 2) return;
    const [key, rawVal] = pair;
    let type;
    if (TYPES.includes(key)) type = key;
    if (OLD_TYPES.includes(key)) type = OLD_TYPE_TO_TYPE[key];
    if (!type) return;

    const { val: valStr, operator } = getValueAndOpFromEncodedStr(rawVal);
    const values = valStr.split(',').map(decodeURIComponent);
    const val = values.length === 1 ? values[0] : values;
    filters.push({
      type,
      val,
      operator,
      key: `${i++}-${type}-${values}`,
    });
  });
  return filters;
}

export const getQueryStringFromFilters = (filters) => {
  let query = '';
  filters.forEach((filter) => {
    const val = Array.isArray(filter.val)
      ? filter.val.map(encodeURIComponent).join(',')
      : encodeURIComponent(filter.val);
    const keyVal = `${filter.type}=${filter.operator || ''}${val}`;
    query += !query ? keyVal : `&${keyVal}`;
  });
  return query;
};

export function isFilteredBySinglePerson(filters) {
  let peopleCount = 0;
  const singlePersonFilter = filters.every(({ type, val, operator }) => {
    // If we have a top level OR / NOT operator,
    // we can't be sure we're filtering by one person.
    if (isOr(operator) || isNot(operator)) return false;

    if (type === 'person') {
      peopleCount++;
      if (peopleCount > 1) return false;
      if (isArray(val) && val.length > 1) return false;
    }

    return true;
  });

  return singlePersonFilter && peopleCount === 1;
}
