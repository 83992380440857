import * as Accordion from '@radix-ui/react-accordion';

import { AccordionContent } from './AccordionContent';
import { AccordionHeader } from './AccordionHeader';
import { AccordionItem } from './AccordionItem';
import { AccordionRoot } from './AccordionRoot';

const Root = AccordionRoot;
const Item = AccordionItem;
const Trigger = Accordion.AccordionTrigger;
const Header = AccordionHeader;
const Content = AccordionContent;

export { Root, Item, Trigger, Header, Content };
