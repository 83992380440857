import React from 'react';

import { mapHolidayV1ToV3 } from '@float/common/lib/formatters';
import * as Icons from '@float/ui/deprecated/Earhart/Icons';
import {
  notificationsSeen,
  receivedNotifications,
} from '@float/web/store/notifications/notifications.actions';

import Refresh from '../../components/elements/Refresh';
import * as helpers from '../helpers';

export const liveUpdateDispatchCreator = (dispatch) => (action) => {
  if (!action.type) return;
  return dispatch({ ...action, isLiveUpdate: true });
};

export const showRefreshStickyFooter = (message, showSnackbar) => {
  showSnackbar(`${message}. Please reload to view latest data.`, {
    persist: true,
    showClose: true,
    linkText: <Refresh>Refresh</Refresh>,
    closeOnLinkClick: false,
  });
};

export const showStickyNotice = (message, linkText, link, showSnackbar) => {
  showSnackbar(message, {
    linkText,
    persist: true,
    showClose: true,
    closeOnLinkClick: false,
    iconLeft: Icons.IconAlert,
    onLinkClick: () => {
      window.open(link, '_blank');
    },
  });
};

export const showRefreshModal = ({
  title = 'Team settings have changed.',
  message = 'Please reload your browser to see the latest updates.',
  manageModal,
}) => {
  manageModal({
    visible: true,
    modalType: 'ConfirmModal',
    modalSettings: {
      title,
      message,
      confirmLabel: 'Reload',
      onConfirm: () => window.location.reload(),
    },
  });
};

export const handleHolidaysImport = (data, ACTION_TYPES) => (dispatch) => {
  const { notification } = data;
  const { type: entityType, af_item: activityFeedItem } = notification;

  const countImported = (notification[entityType] || {}).count;
  if (!countImported || countImported < 1) {
    return;
  }

  const dispatchLiveUpdate = liveUpdateDispatchCreator(dispatch);
  const { after_data = [] } = activityFeedItem || {};
  const holidays = (after_data ?? []).map(mapHolidayV1ToV3);
  holidays.forEach((holiday) => {
    dispatchLiveUpdate({
      type: ACTION_TYPES.HOLIDAYS_CREATE,
      payload: holiday,
      id: holiday.holiday_id,
    });
  });
};

export const handleNotificationFeedMessage = (data) => (dispatch, getState) => {
  const { notification } = data;
  const { nf_item: notificationFeedItem, nf_ids_seen: notificationIdsSeen } =
    notification;

  if (notificationFeedItem) {
    dispatch(
      receivedNotifications({
        items: [notificationFeedItem],
        isLiveUpdate: true,
      }),
    );
    return true;
  }

  if (notificationIdsSeen) {
    if (!helpers.isInitiatedByCurrentUser(data, getState().currentUser)) {
      dispatch(
        notificationsSeen({ ids: notificationIdsSeen, isLiveUpdate: true }),
      );
    }
    return true;
  }

  return false;
};
