import React, {
  FocusEventHandler,
  KeyboardEventHandler,
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import cn from 'classnames';
import { Descendant } from 'slate';
import { Editable, ReactEditor, Slate } from 'slate-react';
import { EditableProps } from 'slate-react/dist/components/editable';

import { nodesToText } from '../../utils/nodesToText';

type TextAreaProps = React.TextareaHTMLAttributes<HTMLDivElement>;

export type SlateEditorProps = {
  className?: string;
  editor: ReactEditor;
  value: Descendant[];
  placeholder: string;
  readOnly?: boolean;
  focused: RefObject<boolean>;
  renderElement?: EditableProps['renderElement'];
  onKeyDown?: TextAreaProps['onKeyDown'];
  onFocus?: TextAreaProps['onFocus'];
  onTabFocus?: () => void;
  onTabKey?: KeyboardEventHandler<HTMLDivElement>;
  onBlur?: TextAreaProps['onBlur'];
  onChange: (value: { text: string; children: Descendant[] }) => void;
  autoFocus?: boolean;
};

export const SlateEditor = (props: SlateEditorProps) => {
  const {
    className,
    editor,
    value,
    placeholder,
    readOnly,
    focused,
    renderElement,
    onKeyDown,
    onFocus,
    onTabFocus,
    onTabKey,
    onBlur,
    onChange,
    autoFocus,
  } = props;

  const editorDOMElRef: MutableRefObject<HTMLElement | null> = useRef(null);
  const hadKeyboardEvent = useRef(false);

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === 'Tab' && typeof onTabKey === 'function') {
      onTabKey(e);
    }

    if (onKeyDown) onKeyDown(e);
  };

  const handleChange = () => {
    // do not propagate onChange if the field is not
    // in focus - that means the value was updated from
    // an external source already
    if (onChange && focused.current) {
      const data = {
        text: nodesToText(editor.children),
        children: editor.children,
      };

      onChange(data);
    }
  };

  // focused.current isn't true when the field is focsued and the user moves to another tab
  // see https://linear.app/float-com/issue/CS-1241#comment-08274183
  const handleBlur: FocusEventHandler<HTMLDivElement> = (e) => {
    onBlur?.(e);

    onChange({
      text: nodesToText(editor.children),
      children: editor.children,
    });
  };

  const onDocumentKeyDown = useCallback((e: KeyboardEvent) => {
    // Ignore keypresses if the user is holding down a modifier key.
    if (!e.altKey && !e.ctrlKey && !e.metaKey) {
      hadKeyboardEvent.current = true;
    }
  }, []);

  const onDocumentFocus = useCallback(
    (e: FocusEvent) => {
      // Prevent IE from focusing the document or HTML element.
      if (
        editorDOMElRef.current &&
        e.target !== document &&
        e.target == editorDOMElRef.current &&
        hadKeyboardEvent.current
      ) {
        onTabFocus?.();

        hadKeyboardEvent.current = false;
      }
    },
    [hadKeyboardEvent, onTabFocus],
  );

  const onDocumentMouseDown = useCallback(() => {
    hadKeyboardEvent.current = false;
  }, []);

  useEffect(() => {
    editorDOMElRef.current = document.querySelector('.slate-editor');

    if (onTabFocus) {
      document.addEventListener('keydown', onDocumentKeyDown, true);
      document.addEventListener('mousedown', onDocumentMouseDown, true);
      document.addEventListener('focus', onDocumentFocus, true);

      return () => {
        document.removeEventListener('keydown', onDocumentKeyDown, true);
        document.removeEventListener('mousedown', onDocumentMouseDown, true);
        document.removeEventListener('focus', onDocumentFocus, true);
      };
    }
  }, [onDocumentKeyDown, onDocumentMouseDown, onDocumentFocus, onTabFocus]);

  return (
    <Slate editor={editor} initialValue={value} onChange={handleChange}>
      <Editable
        className={cn('slate-editor notranslate', className)}
        readOnly={readOnly}
        renderElement={renderElement}
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
        onFocus={onFocus}
        onBlur={handleBlur}
        autoFocus={autoFocus}
      />
    </Slate>
  );
};
