import * as React from 'react';
import { AccordionContent as AccordionContentPrimitive } from '@radix-ui/react-accordion';
import cn from 'classnames';

import * as styles from './styles.css';

export const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionContentPrimitive>,
  React.ComponentPropsWithoutRef<typeof AccordionContentPrimitive>
>((props, forwardedRef) => (
  <AccordionContentPrimitive
    {...props}
    ref={forwardedRef}
    className={styles.accordionContent}
  >
    <div className={cn(styles.accordionContentInner, props.className)}>
      {props.children}
    </div>
  </AccordionContentPrimitive>
));

AccordionContent.displayName = 'AccordionContent';
