import React from 'react';
import styled from 'styled-components';

import {
  isRejectedTimeoff,
  isTentativeTimeoff,
} from '@float/common/lib/timeoffs';
import { moment } from '@float/libs/moment';
import { Input } from '@float/ui/deprecated/Input';

import {
  calculateRepeatTimes,
  calculateTotalHours,
  calculateWorkDays,
} from '../helpers/timeReducers';

let etmThis;

export const TimeSectionInput = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  margin-right: 20px;
`;

const HoursContainer = styled(TimeSectionInput)`
  width: ${(p) => p.width}px;
`;

const hasVaryingMinHours = () => {
  const { hasVaryingWorkHoursInRange } = etmThis.props;
  return (
    etmThis.isTimeoff() &&
    !isTentativeTimeoff({ status: etmThis.state.status }) &&
    !isRejectedTimeoff({ status: etmThis.state.status }) &&
    etmThis.state.isFullDay &&
    hasVaryingWorkHoursInRange({
      start_date: moment(etmThis.state.startDate).format('YYYY-MM-DD'),
      end_date: moment(etmThis.state.endDate).format('YYYY-MM-DD'),
      people_ids: etmThis.state.peopleIds,
    })
  );
};

export const beyondTwoDecimalPlaces = (input, value) => {
  const decimalPlaces = input.toString().split('.')[1];
  if (decimalPlaces && decimalPlaces.length > 2) {
    return true;
  }
  return false;
};

export const beyondThreeDecimalPlaces = (input, value) => {
  const decimalPlaces = input.toString().split('.')[1];
  if (decimalPlaces && decimalPlaces.length > 3) {
    return true;
  }
  return false;
};

const setHoursPd = ({ target: { value } }) => {
  if (beyondTwoDecimalPlaces(+value, +etmThis.state.hoursPd)) {
    return;
  }

  const { getMinWorkHoursInRange } = etmThis.props;
  const minHours = getMinWorkHoursInRange({
    start_date: moment(etmThis.state.startDate).format('YYYY-MM-DD'),
    end_date: moment(etmThis.state.endDate).format('YYYY-MM-DD'),
    people_ids: etmThis.state.peopleIds,
  });

  const isFullDay =
    etmThis.isTimeoff() && !hasVaryingMinHours() && minHours <= value;
  const newState = { ...etmThis.state, hoursPd: value, isFullDay };

  etmThis.setState(newState);
};

export const blurHoursPd = (self, cb) => {
  if (self) {
    etmThis = self;
  }
  let maxHours = 24;

  if (etmThis.isTimeoff()) {
    const { getMinWorkHoursInRange } = etmThis.props;
    const minHours = getMinWorkHoursInRange({
      start_date: moment(etmThis.state.startDate).format('YYYY-MM-DD'),
      end_date: moment(etmThis.state.endDate).format('YYYY-MM-DD'),
      people_ids: etmThis.state.peopleIds,
    });
    maxHours = Math.min(minHours, maxHours);
  }

  let hoursPd = etmThis.state.hoursPd;
  const step = 0.1;
  hoursPd = hoursPd - (Math.round(hoursPd % step) * 100) / 100;
  hoursPd = parseFloat(hoursPd.toFixed(4));

  if (hoursPd <= 0) {
    hoursPd = step;
  }
  if (maxHours && hoursPd > maxHours) {
    hoursPd = maxHours;
  }
  let newState = { ...etmThis.state, hoursPd };
  newState = calculateTotalHours(etmThis.props.calcEntityLength, newState);
  etmThis.setState(newState, cb);
};

const calculateNewEndDate = (newState) => {
  const { calcEntityEndDate } = etmThis.props;
  const newEnd = calcEntityEndDate({
    start_date: moment(newState.startDate).format('YYYY-MM-DD'),
    length: Math.floor(newState.totalHours / newState.hoursPd),
    people_ids: newState.peopleIds,
    timeoff_id: newState.task.timeoff_id,
  });

  newState.endDate = new Date(newEnd);

  return newState;
};

const setTotalHours = ({ target: { value } }) => {
  if (beyondTwoDecimalPlaces(+value, +etmThis.state.totalHours)) {
    return;
  }
  let newState = { ...etmThis.state, totalHours: value };
  if (etmThis.state.hoursPd && value >= etmThis.state.hoursPd) {
    newState = calculateNewEndDate(newState);
  }

  const { getMinWorkHoursInRange } = etmThis.props;
  const minHours = getMinWorkHoursInRange({
    start_date: moment(newState.startDate).format('YYYY-MM-DD'),
    end_date: moment(newState.endDate).format('YYYY-MM-DD'),
    people_ids: newState.peopleIds,
  });

  newState.isFullDay = minHours <= newState.hoursPd;

  newState = calculateWorkDays(etmThis.props.calcEntityLength, newState);

  etmThis.setState(newState);
};

export const blurTotalHours = (self, cb) => {
  if (self) {
    etmThis = self;
  }

  let newState = { ...etmThis.state };
  let { totalHours, hoursPd } = newState;
  //if below or equal to zero, set it to nearest step
  if (totalHours <= 0) {
    totalHours = 0.1;
  }
  //convert hours per day to number
  hoursPd = +hoursPd;

  //if total hours is less than hours per day, change hours per day to match
  if (totalHours <= +hoursPd) {
    hoursPd = totalHours;
  } else {
    //Else, round total hours to the nearest increment of hours per day
    totalHours = totalHours - (totalHours % hoursPd);
  }
  //changing hoursPd
  newState.hoursPd = hoursPd;
  //changing totalHours
  newState.totalHours = totalHours;
  //changes endDate, relies on totalHours and hoursPd
  newState = calculateNewEndDate(newState);
  //changes length, relies on endDate
  newState = calculateWorkDays(etmThis.props.calcEntityLength, newState);
  //changes repeatTimes, relies on endDate
  newState.repeatTimes = calculateRepeatTimes(etmThis.props.dates, newState);

  const { getMinWorkHoursInRange } = etmThis.props;

  const minHours = getMinWorkHoursInRange({
    start_date: moment(newState.startDate).format('YYYY-MM-DD'),
    end_date: moment(newState.endDate).format('YYYY-MM-DD'),
    people_ids: newState.peopleIds,
  });

  newState.isFullDay = minHours <= hoursPd;
  if (etmThis.isTimeoff() && newState.isFullDay) {
    newState.hoursPd = minHours;
    newState.totalHours = minHours * newState.length;
  }

  etmThis.setState(newState, cb);
};

const setHoursPerDayRef = (el) => {
  etmThis.hoursPerDayRef = el;
};

export const getHoursPerDayElem = (self) => {
  etmThis = self;
  const { readOnly, hoursPd, errors } = etmThis.state;

  return (
    <HoursContainer width={85}>
      <Input
        ref={setHoursPerDayRef}
        appearance="underline"
        type="number"
        label="Hours/day"
        hideArrows
        selectTextOnFocus
        placeholder={hasVaryingMinHours() ? '-' : undefined}
        value={hasVaryingMinHours() ? '' : `${hoursPd}`}
        min={0.25}
        step={0.25}
        errors={errors.hoursPd}
        readOnly={readOnly}
        noBorder={readOnly}
        onChange={setHoursPd}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            blurHoursPd(null, etmThis.saveOnEnterKeyPress);
          }
        }}
        onBlur={(e) => blurHoursPd()}
        isStaticPlaceholder
      />
    </HoursContainer>
  );
};

export const getTotalHoursElem = (self) => {
  etmThis = self;
  const { readOnly, hoursPd, totalHours, errors } = etmThis.state;

  return (
    <HoursContainer width={85}>
      <Input
        appearance="underline"
        type="number"
        label="Total hours"
        hideArrows
        selectTextOnFocus
        placeholder={hasVaryingMinHours() ? '-' : undefined}
        value={hasVaryingMinHours() ? '' : totalHours}
        min={hoursPd}
        step={hoursPd}
        errors={errors.hoursPd}
        readOnly={readOnly}
        noBorder={readOnly}
        onChange={setTotalHours}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            blurTotalHours(null, etmThis.saveOnEnterKeyPress);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'ArrowDown') {
            if (totalHours == hoursPd) {
              e.preventDefault();
              e.stopPropagation();

              const newValue = Math.max(0.01, Number(e.target.value) - 0.5);
              setHoursPd({ target: { value: newValue } });
              setTimeout(blurHoursPd);
            }
          }
        }}
        onBlur={(e) => blurTotalHours()}
        isStaticPlaceholder
      />
    </HoursContainer>
  );
};
