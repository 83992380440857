import styled, { css } from 'styled-components';

import * as Animation from '@float/ui/deprecated/Earhart/Animation';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

import { Label } from '../Label';

interface CheckboxContainerProps {
  $disabled?: boolean;
  readOnly?: boolean;
  $checked?: boolean;
  $semiChecked?: boolean;
  leftLabel?: boolean;
  rightLabel?: boolean;
}

export const CheckboxContainer = styled.div<CheckboxContainerProps>`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  box-sizing: border-box;

  &:before,
  &:after {
    position: absolute;

    top: 50%;
    left: 50%;

    width: 18px;
    height: 18px;

    content: '';

    border-radius: 3px;

    box-sizing: border-box;

    transform: translate(-50%, -50%);

    transition-property: border-color, opacity;
    transition-duration: ${Animation.Settings.Duration.Short};
    transition-timing-function: ${Animation.Settings.Easing.InOutCurve};
  }

  &:before {
    border-width: 2px;
    border-style: solid;
    border-color: ${Colors.FIN.Lt.Emphasis.Low};
  }

  &:after {
    background-color: ${Colors.FIN.Lt.Emphasis.Primary};

    opacity: 0;
  }

  ${({ $disabled }) =>
    $disabled &&
    `
    &:after {
      background-color: ${Colors.FIN.Lt.Emphasis.Low};
    }
  `}

  svg {
    position: relative;

    z-index: 1;

    path {
      transform: scale(0);
      transform-origin: 50% 50%;

      transition-property: transform;
      transition-duration: ${Animation.Settings.Duration.Short};
      transition-timing-function: ${Animation.Settings.Easing.InOutBounce};
    }
  }

  ${({ readOnly }) =>
    !readOnly &&
    css`
      &:hover {
        &:before {
          border-color: ${Colors.FIN.Lt.Emphasis.Primary};
        }
      }
    `}

  ${({ $checked }) =>
    $checked &&
    css`
      &:after {
        opacity: 1;
      }

      svg {
        path:nth-child(1) {
          transform: none;
        }
      }
    `};

  ${({ $checked, $semiChecked }) =>
    !$checked &&
    $semiChecked &&
    css`
      &:after {
        opacity: 1;
      }

      svg {
        path:nth-child(2) {
          transform: none;
        }
      }
    `};

  ${({ leftLabel }) => (leftLabel ? 'margin-left: 8px;' : '')}
  ${({ rightLabel, leftLabel }) =>
    rightLabel && !leftLabel ? 'margin-right: 8px;' : ''}
`;

interface CheckboxLabelProps {
  leftLabel?: boolean;
  readOnly?: boolean;
}

export const CheckboxLabel = styled(Label)<CheckboxLabelProps>`
  padding-top: 2px;
  font-size: 14px;
  outline: none;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: ${({ leftLabel }) => leftLabel && 'row-reverse'};

  ${Typography.Label14.R400}
  color: ${Colors.TCore.Emp.High12};

  width: fit-content;

  padding-top: 0;

  cursor: ${(p) => (p.readOnly ? 'default' : 'pointer')};

  ${({ readOnly }) =>
    !readOnly &&
    css`
      &:hover {
        ${CheckboxContainer} {
          &:before {
            border-color: ${Colors.FIN.Lt.Emphasis.Primary};
          }
        }
      }
    `}
`;
