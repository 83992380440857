import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getUser } from 'selectors';

import { useAppStore } from '@float/common/store';
import { CurrentUser } from '@float/types';

export type ViewType = 'people' | 'projects';

export function getLocalStorageKey(user: CurrentUser) {
  const cid = user?.cid || 'cid';
  const uid = user?.admin_id || 'uid';
  return `${cid}:${uid}:pagePrefs`;
}

function writeStorage(page: string, user: CurrentUser, value: ViewType) {
  const storageKey = getLocalStorageKey(user);

  const storedValue = localStorage.getItem(storageKey);
  const prev = storedValue ? JSON.parse(storedValue) : {};

  localStorage.setItem(
    storageKey,
    JSON.stringify({
      ...prev,
      [page]: {
        viewType: value,
      },
    }),
  );
}

function readStorage(page: string, user: CurrentUser): ViewType {
  const storageKey = getLocalStorageKey(user);
  const storedValue = localStorage.getItem(storageKey);

  if (typeof storedValue === 'string') {
    return JSON.parse(storedValue)[page]?.viewType || 'people';
  }

  return 'people';
}

export function useViewTypePrefs() {
  const { pathname } = useLocation();
  const store = useAppStore();
  const [viewTypePref, setViewTypePref] = useState(() =>
    readStorage(pathname, getUser(store.getState())),
  );

  useEffect(() => {
    setViewTypePref(readStorage(pathname, getUser(store.getState())));
  }, [pathname, store]);

  const updateViewTypePref = (value: ViewType) => {
    setViewTypePref(value);
    writeStorage(pathname, getUser(store.getState()), value);
  };

  return {
    viewTypePref,
    updateViewTypePref,
  };
}
