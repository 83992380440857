import React from 'react';
import { useFormContext } from 'react-hook-form';
import { t } from '@lingui/macro';

import { DateRangeField } from '../components/DateRangeField';
import { useProjectAnchorDate } from '../hooks/useProjectAnchorDate';
import { ProjectFormData } from '../types';
import { useProjectDates } from './ProjectDatesField.hooks';

export const ProjectDatesField = () => {
  const { getValues } = useFormContext<ProjectFormData>();
  const { disableDayFn, isStartDateSelectable, isEndDateSelectable } =
    useProjectDates();

  const { areDatesLoading, projectDuration, handleAnchorDateChange } =
    useProjectAnchorDate();

  return (
    <DateRangeField
      disabled={!getValues('project.active')}
      start={'project.start_date'}
      end={'project.end_date'}
      label={t`Dates`}
      padding="emptySides"
      disableDayFn={disableDayFn}
      isStartDateSelectable={isStartDateSelectable}
      isEndDateSelectable={isEndDateSelectable}
      isEndDateLoading={areDatesLoading}
      duration={projectDuration}
      onAnchorDateChange={handleAnchorDateChange}
      data-testid="project-dates-picker"
    />
  );
};
