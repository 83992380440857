import { useState } from 'react';

import { getDefaultPhaseForDate } from '@float/common/lib/scheduleUtils';
import { PhaseOption } from '@float/common/selectors/phases/phases';
import { LoggedTime, LoggedTimeTaskReference } from '@float/types';

export type UsePhaseStateParams = {
  isCreate: boolean;
  projectId: number;
  entity: LoggedTime | LoggedTimeTaskReference;
  onChange?: (phaseId: number) => void;
  phasesOptions: Record<string, PhaseOption[]>;
};

export function usePhaseState(params: UsePhaseStateParams) {
  const { entity, isCreate, onChange, phasesOptions, projectId } = params;

  const [phaseManuallyChosen, setPhaseManuallyChosen] = useState(
    Boolean(!isCreate || entity.isTaskReference || entity.phase_id),
  );

  const [phaseId, setPhaseId] = useState(() => {
    const phaseId = entity.phase_id;

    if (!phaseManuallyChosen && entity.date && phasesOptions?.[projectId]) {
      return getDefaultPhaseForDate({
        phases: phasesOptions[projectId],
        start: entity.date,
      });
    }

    return phaseId;
  });

  function handlePhaseIdUpdate(value: number) {
    if (value !== phaseId) onChange?.(value);

    setPhaseId(value);
  }

  function handleDateChange(date: string) {
    if (!phaseManuallyChosen) {
      const phaseId = getDefaultPhaseForDate({
        phases: phasesOptions[projectId],
        start: date,
      });
      setPhaseId(phaseId);
      onChange?.(phaseId);
    }
  }

  return {
    phaseId,
    setPhaseId: handlePhaseIdUpdate,
    phaseManuallyChosen,
    setPhaseManuallyChosen,
    onDateChange: handleDateChange,
  };
}
