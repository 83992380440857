import { omit } from 'lodash';

import { OneOffDay } from '@float/types';

import {
  ONEOFF_CREATED,
  ONEOFF_DELETED,
  ONEOFFS_LOAD_FAILED,
  ONEOFFS_LOAD_FINISH,
  ONEOFFS_LOAD_START,
} from '../actions';

export type OneOffState = {
  oneOffs: Record<number, OneOffDay>;
  loadState: 'UNLOADED' | 'LOADING' | 'LOAD_FAILED' | 'LOADED';
};

export const DEFAULT_STATE: OneOffState = {
  oneOffs: {},
  loadState: 'UNLOADED',
};

export type OneOffAction =
  | {
      type: typeof ONEOFFS_LOAD_START;
    }
  | {
      type: typeof ONEOFFS_LOAD_FAILED;
    }
  | {
      type: typeof ONEOFFS_LOAD_FINISH;
      rebuild?: boolean;
      oneOffs: Record<number, OneOffDay>;
    }
  | {
      type: typeof ONEOFF_CREATED;
      oneOff: OneOffDay;
    }
  | {
      type: typeof ONEOFF_DELETED;
      oneOff: OneOffDay;
    };

export default function reducer(
  state = DEFAULT_STATE,
  action: OneOffAction,
): OneOffState {
  switch (action.type) {
    case ONEOFFS_LOAD_START: {
      return {
        ...state,
        loadState: 'LOADING',
      };
    }

    case ONEOFFS_LOAD_FAILED: {
      return {
        ...state,
        loadState: 'LOAD_FAILED',
      };
    }

    case ONEOFFS_LOAD_FINISH: {
      return {
        ...state,
        loadState: 'LOADED',
        oneOffs: action.rebuild
          ? action.oneOffs
          : {
              ...state.oneOffs,
              ...action.oneOffs,
            },
      };
    }

    case ONEOFF_CREATED: {
      return {
        ...state,
        oneOffs: {
          ...state.oneOffs,
          [action.oneOff.oneoff_id]: action.oneOff,
        },
      };
    }

    case ONEOFF_DELETED: {
      return {
        ...state,
        oneOffs: omit(state.oneOffs, action.oneOff.oneoff_id),
      };
    }

    default: {
      return state;
    }
  }
}
