import React, { useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { AccessCell, ManagesTag } from 'manage/people-v2/People';

import { getUser } from '@float/common/selectors/currentUser';
import { useAppSelector } from '@float/common/store';
import { moment } from '@float/libs/moment';
import Table from '@float/web/settingsV2/components/Table';
import withOnMount from '@float/web/settingsV2/decorators/withOnMount';
import {
  areBeingFetched as accountsAreBeingFetched,
  lastFetchError as accountsLastFetchError,
  filterInvitedGuests,
} from '@float/web/settingsV2/reducers/accounts';
import {
  ACCOUNT_SETTINGS_INVITEES_TABLE,
  getSortData,
  getTableRows,
} from '@float/web/settingsV2/reducers/reducerHelpers';
import { getSettingsAccountsMap } from '@float/web/settingsV2/selectors/accounts';

import {
  fetchAccounts,
  resendAccountInvite,
  sortAccounts,
} from '../../actions/accounts';
import { SETTINGS_TABLE_DATE_FORMAT } from '../../settingsV2.constants';

const getAccountData = (entity) => {
  if (!entity) return null;
  const id = entity.account_id;
  return {
    isDeletable: false,
    isEdited: false,
    id,
    entity,
    data: [
      [entity.name, entity.email],
      // calculated based on account_type
      entity.account_type_name.replace('Observer', 'Member'),
      moment(entity.created).format(SETTINGS_TABLE_DATE_FORMAT),
    ],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onComponentDidMount: () =>
      dispatch(fetchAccounts(ACCOUNT_SETTINGS_INVITEES_TABLE)),
    onClickHeader: (property, direction) =>
      dispatch(
        sortAccounts(property, direction, ACCOUNT_SETTINGS_INVITEES_TABLE),
      ),
    onLastRowVisible: () =>
      dispatch(fetchAccounts(ACCOUNT_SETTINGS_INVITEES_TABLE)),
    onClickRowAction_resendinvite: (id) => dispatch(resendAccountInvite(id)),
  };
};

const GuestsTable = withOnMount(Table);

const GuestInvitesTable = (props) => {
  const user = useAppSelector(getUser);
  const rows = useAppSelector((state) =>
    getTableRows(
      state.settingsAccounts,
      getAccountData,
      'account_id',
      ACCOUNT_SETTINGS_INVITEES_TABLE,
      filterInvitedGuests,
    ),
  );

  const settingsAccounts = useSelector(getSettingsAccountsMap);

  const isLoading = useAppSelector((state) =>
    accountsAreBeingFetched(state.settingsAccounts),
  );
  const loadError = useAppSelector((state) =>
    accountsLastFetchError(state.settingsAccounts),
  );
  const sort = useAppSelector((state) =>
    getSortData(state.settingsAccounts, ACCOUNT_SETTINGS_INVITEES_TABLE),
  );

  const renderedRows = useMemo(() => {
    return rows.map((row) => {
      return {
        ...row,
        display: [
          null,
          <AccessCell
            key={row.id}
            accounts={settingsAccounts}
            accountId={row.id}
          />,
          <ManagesTag key={row.id} managerAccountId={row.id} />,
          moment(row.entity.created).format(SETTINGS_TABLE_DATE_FORMAT),
        ],
      };
    });
  }, [rows, settingsAccounts]);

  return (
    <GuestsTable
      headNames={['Name', 'Access', 'Manages', 'Added']}
      rows={renderedRows}
      recordNames={['invited guest', 'invited guests']}
      displayRowCount={false}
      columnsWidth={['35%', '25%', '15%', '15%', '10%']}
      rowActions={[
        {
          id: 'resendinvite',
          label: 'Resend Invite',
        },
        {
          id: 'edit',
          label: 'Edit',
        },
      ]}
      companyId={user.cid}
      sort={sort}
      isLoading={isLoading}
      loadError={loadError}
      {...props}
    />
  );
};

export default connect(null, mapDispatchToProps)(GuestInvitesTable);
