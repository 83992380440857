import { formatToDbString } from './formatToDbString';

export function parseBudgetString(
  budgetString: string | null | undefined,
): number | null {
  const formatted = formatToDbString(budgetString);

  if (!formatted) {
    return null;
  }

  return parseFloat(formatted);
}
