import React from 'react';
import styled from 'styled-components';

import { Icons, TextTooltip } from '@float/ui/deprecated';
import colors from '@float/ui/deprecated/Theme/colors';
import TaskChain from 'components/elements/TaskChain';

const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 20px 2px;
  align-content: center;

  & > span {
    margin-left: 11px;
    border-bottom: 1px solid;
    cursor: pointer;
  }
`;

const ContentWrapper = styled.div`
  max-height: 380px;
  overflow-y: auto;
`;

export default function getLinkedTaskHeaderElem(self) {
  const TooltipContent = (
    <ContentWrapper>
      {self.props.tasks[self.state.task.task_id] && (
        <TaskChain
          ctx={{
            tasks: self.props.tasks,
            projects: self.props.projectsMap,
            phases: self.props.phasesMap,
            ignoreTaskIds: self.state.taskIdsToUnlink,
          }}
          taskId={self.state.task.task_id}
          unlinkTask={(taskId) => {
            self.setState((ps) => ({
              taskIdsToUnlink: [...ps.taskIdsToUnlink, taskId],
            }));
          }}
          onAllUnlinked={() => {
            if (self.state.taskIdsToUnlink?.length) {
              self.setState({ isLinkedTask: false });
            }
          }}
          readOnly={self.isReadOnly()}
        />
      )}
    </ContentWrapper>
  );

  return (
    <Container>
      <Icons.Link size={20} color={colors.emphasisHigh} />
      <TextTooltip
        className="task-chain"
        delay={[0]}
        placement="top-start"
        content={TooltipContent}
      >
        <span>Linked allocations</span>
      </TextTooltip>
    </Container>
  );
}
