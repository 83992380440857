import { CellItem } from '@float/types';

import { CellRenderArgs } from '../types';

export function addTeamHolidays(args: CellRenderArgs) {
  const {
    cell,
    cellKey,

    fns,
    props,
    firstCellDay,
    lastCellDay,
  } = args;

  const { bimaps, maps } = props;
  const { getHorizontalDimensions } = fns;
  const { holiday: holidaysBimap } = bimaps;
  const { holiday: holidaysMap } = maps;

  holidaysBimap.getRev(cellKey).forEach((id) => {
    const h = holidaysMap[id];
    const { x, w } = getHorizontalDimensions(h, firstCellDay, lastCellDay);

    const cellItem: CellItem<'holiday'> = {
      cellKey,
      key: `${cellKey}:holiday:${h.holiday_id}`,
      type: 'holiday',
      entity: h,
      entityId: h.holiday_id,
      x,
      w,
    };

    if (w > 0) cell.items.push(cellItem);
  });
}
