import { shallowDiffers } from '@float/common/components/Schedule/util/diff';
import { ReduxState } from '@float/common/reducers/lib/types';
import { getUser } from '@float/common/selectors/currentUser';
import {
  selectMilestonesGroupedByProjectId,
  selectProjectFilteredMilestones,
} from '@float/common/selectors/milestones';
import { getSearchFilteredActivePeopleMap } from '@float/common/selectors/people';
import {
  getPhasesMapRaw,
  getProjectPhases,
} from '@float/common/selectors/phases';
import { getProjectsMap } from '@float/common/selectors/projects';
import { getTimersWithExternalMeta } from '@float/common/selectors/timer';
import { UseCellsAction } from '@float/common/serena/Data/useCells/types';
import { CellHelperData } from '@float/types';

export function handleCellsHelpersDataLoad(
  state: ReduxState,
  dispatch: (action: UseCellsAction) => void,
  lastHelperData: Partial<CellHelperData>,
) {
  const helperData = {
    projects: getProjectsMap(state),
    milestones: selectProjectFilteredMilestones(state),
    timeoffTypes: state.timeoffTypes.timeoffTypes,
    phases: getPhasesMapRaw(state),
    people: getSearchFilteredActivePeopleMap(state),
    user: getUser(state),
    timersList: getTimersWithExternalMeta(state),
  };

  if (!lastHelperData || shallowDiffers(helperData, lastHelperData)) {
    dispatch({
      type: 'LOAD_HELPER_DATA',
      data: {
        ...helperData,
        byProject: {
          phases: getProjectPhases(state),
          milestones: selectMilestonesGroupedByProjectId(state),
        },
      },
    });
  }

  return helperData;
}
