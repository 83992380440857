import React, { useMemo, useState } from 'react';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  WindowScroller,
} from '@floatschedule/react-virtualized';
import cx from 'classnames';
import { flip } from 'lodash';
import styled from 'styled-components';

import { Col, EH, Spacer } from '@float/ui/deprecated';

import { VirtualizedRow } from '../Table/Row';
import { StyledTableHeader } from '../Table/styles';
import { SortableHeader } from '../Table/Thead';

export const EmptyContentText = styled.span`
  ${EH.Typography.Label14.R400}
  color: ${EH.Colors.FIN.Lt.Emphasis.Medium};
`;

const TableHeader = styled(StyledTableHeader)`
  display: inline-flex;
`;

export const WindowScrollerTable = (props) => {
  const { rows, windowScrollRef, rowActions, tableHeaders } = props;

  const cache = useMemo(() => {
    return new CellMeasurerCache({
      fixedWidth: true,
    });
  }, []);

  const [, rerender] = React.useState();
  const [sortConfig, setSortConfig] = useState(() => ({
    property: tableHeaders[0].key,
    direction: 'asc',
  }));

  tableHeaders.reduce((acc, header, i) => {
    acc[header.key] = i;
    return acc;
  }, {});

  const sortedRows = useMemo(() => {
    if (!rows.length) {
      return [];
    }

    const indexByKey = tableHeaders.reduce((acc, header, i) => {
      acc[header.key] = i;
      return acc;
    }, {});

    const getData = (row) => row.data[indexByKey[sortConfig.property]] ?? '';
    const sortFn = (a, b) => {
      const left = getData(a);
      const right = getData(b);
      return left.localeCompare ? left.localeCompare(right) : left - right;
    };

    const sortedRows = [...rows].sort(
      sortConfig.direction === 'asc' ? sortFn : flip(sortFn),
    );

    // Since rows are not is same order anymore
    // we'll need to re-measure row heights.
    cache.clearAll();
    return sortedRows;
  }, [sortConfig, rows, tableHeaders, cache]);

  if (!rows.length) {
    return <EmptyContentText>{props.emptyText}</EmptyContentText>;
  }

  const tableColumnWidths = props.columnsWidth || ['50%', '40px'];

  return (
    <WindowScroller scrollElement={windowScrollRef.current}>
      {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
        <AutoSizer disableHeight>
          {({ width }) => (
            <div ref={registerChild} style={{ width }}>
              <div style={{ marginBottom: 0, width: '100%' }}>
                {tableHeaders.map((header, i) => {
                  return (
                    <TableHeader
                      as="div"
                      key={header.key}
                      $width={tableColumnWidths[i]}
                    >
                      <SortableHeader
                        name={header.label}
                        onSortChange={setSortConfig}
                        headerKey={header.key}
                        sortConfig={sortConfig}
                      />
                    </TableHeader>
                  );
                })}
              </div>
              <List
                autoHeight
                style={{
                  outline: 'none',
                }}
                height={height || 0}
                isScrolling={isScrolling}
                onScroll={onChildScroll}
                deferredMeasurementCache={cache}
                rowHeight={cache.rowHeight}
                rowCount={sortedRows.length}
                scrollTop={scrollTop}
                width={width}
                rowRenderer={({ index, style, parent }) => {
                  const isOdd = (index + 1) % 2 !== 0;
                  const row = sortedRows[index];
                  const isLastRow = index === sortedRows.length - 1;
                  const className = cx({
                    odd: isOdd,
                    even: !isOdd,
                  });

                  const rowProps = {
                    className,
                    rowIndex: index,
                    columnsWidth: tableColumnWidths,
                    rowActions,
                    recomputeHeights: () => {
                      // we need to rerender to ensure that the list
                      // reads from an up to date cache.
                      rerender({});
                      cache.clear(index);
                    },
                    row,
                  };

                  return (
                    <CellMeasurer
                      cache={cache}
                      columnIndex={0}
                      key={row.key}
                      rowIndex={index}
                      parent={parent}
                    >
                      {() => (
                        <Col style={style}>
                          {props.renderRow ? (
                            props.renderRow(row, rowProps, cache)
                          ) : (
                            <VirtualizedRow {...rowProps} />
                          )}
                          {!isLastRow && <Spacer size={8} />}
                        </Col>
                      )}
                    </CellMeasurer>
                  );
                }}
              />
            </div>
          )}
        </AutoSizer>
      )}
    </WindowScroller>
  );
};
