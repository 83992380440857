import { ItemData } from '@float/common/serena/Data/transformers/types';

export const getClientNameForSchedule = (clientName?: string | null) =>
  clientName || 'No Client';

export const mapTaskNameForSchedule = (entities: ItemData[]) =>
  entities.map((t) => ({
    ...t,
    client: getClientNameForSchedule(t.client),
  }));

export function getSortedTasks(entities: ItemData[]) {
  return entities.sort((a, b) => {
    if (a.name === '(All others)') return 1;
    if (b.name === '(All others)') return -1;

    if (String(a.client) !== String(b.client)) {
      return String(a.client).localeCompare(b.client ?? '');
    }

    if (String(a.project) !== String(b.project)) {
      return String(a.project).localeCompare(b.project ?? '');
    }

    return String(a.name).localeCompare(String(b.name));
  });
}
