import React from 'react';

import Theme from '@float/ui/deprecated/Theme/colors';

export default function SortCombo(props) {
  const isAsc = props.dir === 'asc';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fillRule="evenodd">
        <path
          d="M16 11L12 7 8 11z"
          fill={isAsc ? Theme.charcoalGrey : Theme.blueGrey}
        />
        <path
          d="M16 17L12 13 8 17z"
          transform="matrix(1 0 0 -1 0 30)"
          fill={isAsc ? Theme.blueGrey : Theme.charcoalGrey}
        />
      </g>
    </svg>
  );
}
