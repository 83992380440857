import styled, { css } from 'styled-components';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

import { FieldLabel } from '../Label';
import { InputTimeProps } from './types';

type StyledInputTimeProps = Pick<
  InputTimeProps,
  'size' | 'appearance' | 'fitToContent' | 'appearanceDisabled'
>;

export const StyledFieldLabel = styled(FieldLabel)`
  margin-bottom: 0;
`;

export const StyledInput = styled.input`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  font-family: inherit;
  font-size: inherit;

  color: inherit;
  background: none;

  border: 0;
  outline: none;
  padding: 0;

  box-shadow: none;
`;

export const StyledSpan = styled.span`
  display: inline-flex;
  align-items: center;

  padding: 0 1px 0 0;

  font-variant-numeric: tabular-nums;

  pointer-events: none;

  visibility: hidden;
`;

export const StyledInputTime = styled.span<StyledInputTimeProps>`
  position: relative;

  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;

  color: ${Colors.FIN.Lt.Emphasis.High};

  &,
  * {
    box-sizing: border-box;
  }

  ${({ fitToContent }) =>
    !fitToContent &&
    css`
      width: 100%;
    `};

  ${({ size }) =>
    (!size || size === 'medium') &&
    css`
      ${Typography.TextXL.M500};
      ${StyledInput},
      ${StyledSpan} {
        height: 40px;
      }
    `};

  ${({ size }) =>
    size === 'small' &&
    css`
      ${Typography.Label13.R400};
    `};

  ${({ appearance }) => {
    if (!appearance || appearance === 'primary') {
      return css`
        ${StyledInput}, ${StyledSpan} {
          border-bottom-width: 1px;
          border-bottom-style: solid;
          border-bottom-color: ${Colors.FIN.Lt.Stroke.Stroke2};

          &:not(:disabled) {
            &:hover {
              border-bottom-color: ${Colors.FIN.Lt.Stroke.Stroke3};
            }

            &:focus {
              border-bottom-color: ${Colors.FIN.Lt.Emphasis.Primary};
            }
          }
        }
      `;
    } else if (appearance === 'minimal-input') {
      return css`
        ${StyledInput}, ${StyledSpan} {
          color: ${Colors.FIN.Lt.Emphasis.High};
          background-color: #fff;

          border-radius: 4px;

          padding: 4px 5px;
        }
      `;
    }
  }};

  ${({ appearanceDisabled }) =>
    appearanceDisabled &&
    css`
      ${StyledInput}, ${StyledFieldLabel} {
        color: ${Colors.FIN.Lt.Emphasis.Disabled};
      }
    `};
`;
