import React, { useCallback, useState } from 'react';
import { t } from '@lingui/macro';
import { uniq } from 'lodash';

import { getUser } from '@float/common/selectors/currentUser';
import { useAppSelector } from '@float/common/store';
import { validateDomain } from '@float/libs/validate';
import { Button, MultiSelect } from '@float/ui/deprecated';

import { StyledButtonsWrapper, StyledContainer } from './styles';

const isDomainValid = (domain: string) => {
  return validateDomain(domain);
};

const withDefaultDomain = (domains: string[], defaultDomain: string) => {
  return !domains.some(
    (domain) => domain.toLowerCase() === defaultDomain.toLowerCase(),
  );
};

type ConfigureDomainRestictionProps = {
  onSubmit: (domains: string[]) => Promise<void>;
  onCancel: () => void;
  domains: string[];
  defaultDomain: string;
};

export const ConfigureDomainRestiction = ({
  onSubmit,
  onCancel,
  domains: prevDomains,
  defaultDomain,
}: ConfigureDomainRestictionProps) => {
  const user = useAppSelector(getUser);
  const [domains, setDomains] = useState(
    prevDomains.length ? prevDomains : [defaultDomain],
  );
  const [domainsError, setDomainsError] = useState<null | string>(null);
  const handleSubmit = useCallback(async () => {
    if (
      defaultDomain &&
      domains.length &&
      withDefaultDomain(domains, defaultDomain)
    ) {
      setDomainsError(
        t`${defaultDomain} must be contained in the allowed list of domains.`,
      );
      return;
    }
    if (domains.length > 15) {
      setDomainsError(t`A maximum of 15 domains are supported.`);
      return;
    }
    try {
      await onSubmit(domains.map((val) => val.trim()));
    } catch {
      setDomainsError(
        t`One or more existing members don't meet this domain restriction. Please review before trying again.`,
      );
    }
  }, [domains, onSubmit, defaultDomain]);

  const handleAddDomain = useCallback(
    (item: { value: string }) => {
      if (!isDomainValid(item.value)) {
        const itemValue = item.value;
        setDomainsError(t`${itemValue} is a not valid domain.`);
        return;
      }
      if (
        domains
          .map((item) => item.toLowerCase())
          .includes(item.value.toLowerCase())
      ) {
        return;
      }
      if (domainsError) {
        setDomainsError(null);
      }
      setDomains([...domains, item.value.toLowerCase()]);
    },
    [domains, domainsError],
  );

  const handleRemoveDomain = useCallback(
    (item: { value: string }) => {
      if (item.value === defaultDomain && user.account_tid !== 1) {
        return;
      }
      setDomains(domains.filter((domain) => domain !== item.value));
    },
    [domains, defaultDomain, user],
  );

  const domainsValues = uniq(domains.filter(Boolean));

  return (
    <StyledContainer>
      <MultiSelect
        size="large"
        label={t`Restrict sign ins to`}
        creatable
        autofocus
        sort={false}
        visibleItems={6}
        options={[]}
        values={domainsValues.map((t, i) => ({
          static: t === defaultDomain && user.account_tid !== 1,
          value: t,
          label: t,
        }))}
        errors={domainsError ? [domainsError] : []}
        onAdd={handleAddDomain}
        onRemove={handleRemoveDomain}
      />

      <StyledButtonsWrapper>
        <Button appearance="primary" size="large" onClick={handleSubmit}>
          {t`Save`}
        </Button>

        <Button appearance="secondary" size="large" onClick={onCancel}>
          {t`Cancel`}
        </Button>
      </StyledButtonsWrapper>
    </StyledContainer>
  );
};
