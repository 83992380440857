import React, { CSSProperties, ReactNode } from 'react';

import { StyledModalTitle } from './styles';

const ModalTitle = ({
  children,
  style,
  className,
}: {
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
}) => {
  return (
    <StyledModalTitle className={className} id="modal-title" style={style}>
      {children}
    </StyledModalTitle>
  );
};

export default ModalTitle;
