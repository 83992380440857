import React, { useMemo } from 'react';
import { t } from '@lingui/macro';
import { compact, intersection, uniq } from 'lodash';
import { getPeopleMap, getUser } from 'selectors';

import { Access as AccessACL, BitsHandler } from '@float/common/lib/acl';
import { formatRate } from '@float/common/lib/rates/rates';
import { useAppSelector } from '@float/common/store';
import { AccountType } from '@float/constants/accounts';
import BulkEditModal from 'components/elements/BulkEditModal/BulkEditModal';

import { checkIsPeopleManager } from '../PersonModal/access/PersonAccess';
import Access from './Access';
import Department from './Department';
import Manages from './Manages';
import PeopleType from './PeopleType';
import PublicHolidays from './PublicHolidays';
import { Rate } from './Rate';
import { getRateChangeConfirmData } from './Rate.helpers';
import { Role } from './Role';
import { getRoleChangeConfirmData } from './Role.helpers';
import Tags from './Tags';

function getFields() {
  const fields = {
    role_id: {
      label: t`Role`,
      render: Role,
      getConfirmData: getRoleChangeConfirmData,
    },
    department_id: {
      label: t`Department`,
      render: Department,
    },
    tags: {
      label: t`Tags`,
      render: Tags,
      defaultValue: [],
    },
    people_type_id: {
      label: t`Type`,
      render: PeopleType,
      getDefaultValueForMixedSelection: () => 1,
    },
    default_hourly_rate: {
      label: t`Rate`,
      render: Rate,
      defaultValue: (value) => formatRate(value),
      getConfirmData: getRateChangeConfirmData,
    },
    region_id: {
      label: t`Public holidays`,
      render: PublicHolidays,
    },
    access: {
      label: t`Access`,
      render: Access,
      getPayloadFromState: (data) => {
        if (!data) {
          return {};
        }

        const payload = {
          access: data.access,
          account_type_id: data.account_type,
          department_filter: data.department_filter_set
            ? data.department_filter
            : [],
        };

        return payload;
      },
      getDefaultValue: (entity) => {
        const account = entity.account || {};

        return {
          account_type: account.account_type || 4,
          access: account.access || 0,
          department_filter: account.department_filter || [],
          force_department_filter: compact([entity.department_id]),
          department_filter_set: account.department_filter?.length > 0,
        };
      },
      handleMismatch: {
        account_type: (initialEntity, currentEntity, initialValue) => {
          const order = [1, 2, 7, 4];
          const leftTypeIdx = order.indexOf(
            initialEntity.account?.account_type || 4,
          );
          const rightTypeIdx = order.indexOf(
            currentEntity.account?.account_type || 4,
          );

          if (leftTypeIdx > rightTypeIdx) {
            initialValue.account_type = order[leftTypeIdx];
          } else {
            initialValue.account_type = order[rightTypeIdx];
          }
        },
        department_filter: (initialEntity, currentEntity, initialValue) => {
          const departmentFilter = uniq(
            compact([
              ...initialValue.department_filter,
              currentEntity.department_id,
              initialEntity.department_id,
              ...intersection(
                initialEntity.account?.department_filter,
                currentEntity.account?.department_filter,
              ),
            ]),
          );

          initialValue.department_filter = departmentFilter;
        },
        force_department_filter: (
          initialEntity,
          currentEntity,
          initialValue,
        ) => {
          initialValue.force_department_filter = uniq(
            compact([
              ...initialValue.force_department_filter,
              currentEntity.department_id,
              initialEntity.department_id,
            ]),
          );
        },
        access: (initialEntity, currentEntity, initialValue) => {
          const initialAccess = initialEntity.account?.access || 0;
          const currentAccess = currentEntity.account?.access || 0;
          const commonBits = BitsHandler.getCommonBitsHandler(
            initialValue.access || 0,
            initialAccess,
            currentAccess,
          );
          initialValue.access = commonBits.toNumber();
        },
      },
    },
    management_group: {
      label: t`Manages`,
      render: Manages,
      getPayloadFromState: (data) => {
        return data.management_group;
      },
      getDefaultValue: (entity) => {
        const value = {
          ...(entity.account.management_group || {
            departments: [],
            people: [],
          }),
        };
        delete value.people;

        return {
          management_group: value,
        };
      },
      handleMismatch: {
        management_group: (initialEntity, currentEntity, initialValue) => {
          const left =
            initialEntity.account.management_group?.departments || [];
          const right =
            currentEntity.account.management_group?.departments || [];

          initialValue.management_group = {
            departments: intersection(left, right),
          };
        },
      },
    },
  };

  return fields;
}

export const BulkEditPeopleModal = (props) => {
  const fields = useMemo(() => getFields(), []);

  const currentUser = useAppSelector(getUser);
  const people = useAppSelector(getPeopleMap);

  const bulkEditPeople = useMemo(() => {
    return props.ids.map((pid) => people[pid]);
  }, [people, props.ids]);

  const allowedFields = useMemo(() => {
    const nextFields = { ...fields };

    for (const person of bulkEditPeople) {
      const canUpdateManages =
        checkIsPeopleManager(person.account) &&
        AccessACL.canUpdateAccountManagementGroup(currentUser, person);
      if (!canUpdateManages) {
        delete nextFields.management_group;
      }

      const hasAccount = !!person.account;
      const isAccountOwner =
        hasAccount && person.account.account_type === AccountType.AccountOwner;
      const canUpdateAccess = AccessACL.canUpdateBulkAccountAccess(
        currentUser,
        person,
      );
      if (!hasAccount || !canUpdateAccess || isAccountOwner) {
        delete nextFields.access;
      }

      const canSkip = !nextFields.access && !nextFields.management_group;
      if (canSkip) {
        break;
      }
    }

    return nextFields;
  }, [currentUser, bulkEditPeople, fields]);

  return (
    <BulkEditModal
      {...props}
      type="person"
      plural="people"
      entities={people}
      fields={allowedFields}
    />
  );
};
