export const hasMoreThanTwoDecimalPlaces = (input: string | number) => {
  const decimalPlaces = input.toString().split('.')[1];

  if (decimalPlaces && decimalPlaces.length > 2) {
    return true;
  }

  return false;
};

export const hasMoreThanThreeDecimalPlaces = (input: string | number) => {
  const decimalPlaces = input.toString().split('.')[1];

  if (decimalPlaces && decimalPlaces.length > 3) {
    return true;
  }

  return false;
};
