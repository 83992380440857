import React, { forwardRef } from 'react';
import cn from 'classnames';

import * as styles from './styles.css';

export const SuccessCheck = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div className={styles.successCheck} ref={ref}>
      <svg
        width="62"
        height="50"
        viewBox="0 0 62 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cn(styles.svg, 'check-svg')}
      >
        <path
          className={styles.path}
          d="M57.5625 4.75024L21.0391 45.1252L4.4375 26.0002"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="8"
        />
      </svg>

      <div className={cn(styles.checkHalo, 'check-bg')}>
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={cn(styles.svg, styles.svgCheckCounter, 'check-counter')}
        >
          <circle
            className={styles.circle}
            cx="20"
            cy="20"
            r="19"
            stroke="white"
            strokeWidth="2"
            vectorEffect="non-scaling-stroke"
          />
        </svg>
      </div>
      <div className={cn(styles.checkHalo, 'check-halo')} />
    </div>
  );
});
