import styled, { css } from 'styled-components';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

export const StyledTimerTextTime = styled.p<{ disabled: boolean }>`
  ${Typography.TextXL.M500};

  line-height: 1;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${Colors.FIN.Lt.Emphasis.Disabled};
    `};
`;

export const StyledTimerTextLabel = styled.p<{ disabled: boolean }>`
  color: ${Colors.Core.Text.Secondary};

  margin-top: 1px;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${Colors.FIN.Lt.Emphasis.Disabled};
    `};
`;
