import React from 'react';

import BaseIcon, { BaseIconProps } from './BaseIcon';

export default (props: BaseIconProps) => (
  <BaseIcon {...props} viewBox="0 0 16 16">
    <path
      d="M11.5 7L8 10.5L4.5 7"
      stroke="#242C39"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </BaseIcon>
);
