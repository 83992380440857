import {
  hexToRgbParts,
  rgbPartsIsBright,
  rgbPartsToHex,
} from '@float/common/lib/colors/utils/oldColorUtils';
import { SerenaState } from '@float/common/selectors/serena';
import { darken } from '@float/libs/colors/utils/darken';
import { lighten } from '@float/libs/colors/utils/lighten';

import { MainCellItem } from '../../box/BoxElements/types';

export function getLinkArrowColor(
  reduxData: SerenaState | undefined,
  item: MainCellItem,
) {
  const projectId =
    'project_id' in item.entity ? item.entity.project_id : undefined;
  const phaseId = 'phase_id' in item.entity ? item.entity.phase_id : undefined;

  let color = undefined;

  if (reduxData) {
    if (phaseId) {
      color = reduxData.phases[phaseId]?.color;
    } else if (projectId) {
      color = reduxData.projects[projectId]?.color;
    }
  }

  if (!color) return '#000000';

  const parts = hexToRgbParts(color).map((r) => parseInt(r, 16)) as [
    number,
    number,
    number,
  ];
  let adjustedColor;

  if (rgbPartsIsBright(...parts)) {
    adjustedColor = rgbPartsToHex(darken(parts, 0.2));
  } else {
    adjustedColor = rgbPartsToHex(lighten(parts, 0.3));
  }

  return adjustedColor;
}
