import React, { StrictMode } from 'react';

import { Person, Project, Tag, TagGroup } from '@float/types';

import { AdminTagsItem } from '../AdminTagsItem/AdminTagsItem';

import * as styles from './AdminTagGroup.css';

type Entity = Person | Project;

export type TagGroupProps = {
  id: number;
  tagsGroup: TagGroup | null;
  tagsWithEntities: {
    tag: Tag;
    entities: Entity[];
  }[];
};

export const AdminTagsGroup = (props: TagGroupProps) => {
  const { tagsGroup, tagsWithEntities } = props;

  const numTagEntitiesApplied = tagsWithEntities.reduce((val, item) => {
    return val + item.entities.length;
  }, 0);

  return (
    <StrictMode>
      <section className={styles.adminTagsGroup}>
        <header>
          {tagsGroup?.name || 'Ungrouped'} {numTagEntitiesApplied} Applied
        </header>
        <div className={styles.adminTagsGroupBody}>
          {tagsWithEntities.map((item) => (
            <AdminTagsItem key={item.tag.tags_id} {...item} />
          ))}
        </div>
      </section>
    </StrictMode>
  );
};
