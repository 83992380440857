import { keyBy } from 'lodash';

import request from '../lib/request';

export const TIMEOFF_TYPES_LOAD_START = 'timeoffTypes/LOAD_START';
export const TIMEOFF_TYPES_LOAD_FAILED = 'timeoffTypes/LOAD_FAILED';
export const TIMEOFF_TYPES_LOAD_FINISH = 'timeoffTypes/LOAD_FINISH';

const fetchTimeoffTypes = async () => {
  const res = await request.get(
    'timeoff-types',
    { 'per-page': 5000 },
    { version: 'f3' },
  );
  return keyBy(res, 'timeoff_type_id');
};

export const ensureTimeoffTypesLoaded = (forceLoad = false) => {
  return async (dispatch, getState) => {
    const { loadState: currentLoadState } = getState().timeoffTypes;

    if (!forceLoad && currentLoadState === 'LOADED') return;
    if (currentLoadState === 'LOADING') return; // There's already an in-flight load request

    try {
      dispatch({ type: TIMEOFF_TYPES_LOAD_START });
      const timeoffTypes = await fetchTimeoffTypes();
      dispatch({ type: TIMEOFF_TYPES_LOAD_FINISH, timeoffTypes });
    } catch (e) {
      console.error(e);
      dispatch({ type: TIMEOFF_TYPES_LOAD_FAILED });
    }
  };
};
