import {
  failedFetchingEntitiesCreator,
  fetchedEntitiesCreator,
  fetchEntitiesCreator,
  shouldFetchEntitiesCreator,
} from './creatorHelpers';

export const FETCH_ACCOUNT_TYPES = 'FETCH_ACCOUNT_TYPES';
export const FETCH_ACCOUNT_TYPES_SUCCESS = 'FETCH_ACCOUNT_TYPES_SUCCESS';
export const FETCH_ACCOUNT_TYPES_FAILURE = 'FETCH_ACCOUNT_TYPES_FAILURE';

const PLURAL = 'accountTypes';

const fetchedAccountTypes = fetchedEntitiesCreator(FETCH_ACCOUNT_TYPES_SUCCESS);

const failedFetchingAccountTypes = failedFetchingEntitiesCreator(
  FETCH_ACCOUNT_TYPES_FAILURE,
);

const shouldFetchAccountTypes = shouldFetchEntitiesCreator(PLURAL);

export const fetchAccountTypes = fetchEntitiesCreator(
  FETCH_ACCOUNT_TYPES,
  PLURAL,
  shouldFetchAccountTypes,
  fetchedAccountTypes,
  failedFetchingAccountTypes,
);

export function fetchAllAccountTypes() {
  return (dispatch, getState) => {
    return dispatch(fetchAccountTypes()).then(() => {
      const { pageCount, lastFetchedPage } = getState().accountTypes;
      const fetchPromises = [];
      for (let page = lastFetchedPage + 1; page <= pageCount; page++) {
        fetchPromises.push(dispatch(fetchAccountTypes(undefined, page)));
      }
      return Promise.all(fetchPromises);
    });
  };
}
