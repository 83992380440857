import React from 'react';

export default function ({ style, color = '#C1C1C1' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={style}
    >
      <g fill="none" fillRule="evenodd">
        <rect width="12" height="9" x="6" y="10" fill={color} rx="1" />
        <rect
          width="6"
          height="12"
          x="9"
          y="5"
          stroke={color}
          strokeWidth="2"
          rx="3"
        />
      </g>
    </svg>
  );
}
