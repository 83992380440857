import React from 'react';

export default ({ color }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 16V13.3333C23 9.46735 19.866 6.33334 16 6.33334C12.134 6.33334 8.99998 9.46735 8.99998 13.3333V16L6.33331 21.6667H25.6666L23 16Z"
        stroke={color ?? '#8290AA'}
        className="stroke"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 22.3333C12 22.3333 12 25.6667 16 25.6667C20 25.6667 20 22.3333 20 22.3333"
        stroke={color ?? '#8290AA'}
        className="stroke"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Bell2WithIndicator = ({ accentColor }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
    >
      <path
        className="fill"
        d="M18,24.095C18,24.6 17.789,25.085 17.414,25.442C17.039,25.799 16.53,26 16,26C15.47,26 14.961,25.799 14.586,25.442C14.211,25.085 14,24.6 14,24.095L18,24.095ZM22.966,13.911C22.988,14.129 23,14.349 23,14.571L23,20.286L25,22.191L25,23.143L7,23.143L7,22.191L9,20.286L9,14.571C9,11.619 11.03,9.019 14,8.181L14,7.905C14,7.4 14.211,6.915 14.586,6.558C14.961,6.201 15.47,6 16,6C16.53,6 17.039,6.201 17.414,6.558C17.789,6.915 18,7.4 18,7.905L18,8.181L18.003,8.182C18.089,11.059 20.204,13.43 22.966,13.911Z"
        fill="none"
      />
      <g transform="matrix(0.795438,0,0,0.795438,4.90949,1.6365)">
        <circle cx="24" cy="8" r="5" fill={accentColor || 'rgb(255,202,105)'} />
      </g>
    </svg>
  );
};
