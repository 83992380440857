import { moment } from '@float/libs/moment';
import { DO_NOT_USE_setGlobalPageData } from '@float/libs/unsafeGlobalData';

import {
  FETCH_COMPANY_PREFS_SUCCESS,
  SET_PLUS_PACK,
  SET_TIME_TRACKING,
  UPDATE_COMPANY_PREFS,
  UPDATE_COMPANY_PREFS_FAILURE,
  UPDATE_COMPANY_PREFS_SUCCESS,
} from '../actions';
import {
  convertWorkDaysHoursHistory,
  formatCompanyPrefs,
} from './companyPrefs.helpers';

/* time_tracking enum
TRIAL_EXPIRED = -2;
DISABLED      = -1; // Turned off by customer
NOT_ENABLED   =  0; // Not yet enabled/trialed
ENABLED       =  1; // A paying customer
TRIAL         =  2;
BETA          =  3;
*/

// TODO: Currently relevant for electron only. Maybe we need to update mobile also.
const updateGlobals = (state, cPrefs) => {
  const res = formatCompanyPrefs(state, cPrefs);
  const startWorkWeek = res.start_work_week || 0;

  moment.updateLocale('en', { week: { dow: startWorkWeek } });
};

const getDefaultState = () => {
  return formatCompanyPrefs({}, {});
};

/**
 * Because update action uses old form submit
 * with full page reload, we need to update based on input data,
 * not json returned by the server.
 * @param {Object} state
 * @param {Object} action
 * @param {Object} action.inputData
 */
const updatedCompanyPrefs = (
  state,
  { noRequest, payload, inputData, jsonData },
) => {
  if (noRequest) {
    const newState = {
      ...state,
      ...payload,
    };

    if (newState.work_days_hours_history) {
      if (typeof newState.work_days_hours_history === 'string') {
        newState.work_days_hours_history = JSON.parse(
          newState.work_days_hours_history,
        );
      }
      newState.work_days_hours_history = convertWorkDaysHoursHistory(
        newState.work_days_hours_history,
      );
    }

    return newState;
  }
  if (!inputData && !jsonData) {
    return state;
  }

  const newState = {
    ...state,
    timezone: inputData.timezones,
    currency: inputData.currency,
    sso_required: inputData.sso_required,
    domains: inputData.domains,
    currency_symbol: inputData.currency_symbol,
    start_work_week: +inputData.start_work_week,
    work_days_hours: inputData.work_days_hours,
    hide_non_wk_days: inputData.hide_non_wk_days,
    time_format_24h: inputData.time_format_24h,
    share_link_enabled: inputData.share_link_enabled,
    share_link_all_members_enabled: inputData.share_link_all_members_enabled,
  };

  DO_NOT_USE_setGlobalPageData({
    currency: inputData.currency,
  });

  if (jsonData) {
    newState.work_days_hours = jsonData.work_days_hours.map((h) => +h);
    newState.work_days_hours_history = convertWorkDaysHoursHistory(
      jsonData.work_days_hours_history,
    );
  }

  return newState;
};

const companyPrefs = (state = getDefaultState(), action) => {
  switch (action.type) {
    case FETCH_COMPANY_PREFS_SUCCESS: {
      updateGlobals(state, action.companyPrefs);
      return formatCompanyPrefs(state, action.companyPrefs);
    }
    case UPDATE_COMPANY_PREFS_SUCCESS:
      return updatedCompanyPrefs(state, action);
    case SET_TIME_TRACKING:
      return {
        ...state,
        time_tracking: action.time_tracking,
        time_tracking_end: action.time_tracking_end,
      };
    case SET_PLUS_PACK: {
      const nextState = {
        ...state,
        plus_pack: action.plus_pack,
        plus_pack_end: action.plus_pack_end,
      };
      // Clear the plus pack features state
      if (action.plus_pack < 1) {
        nextState.domains = [];
        nextState.sso_required = null;
      }
      return nextState;
    }
    case UPDATE_COMPANY_PREFS:
    case UPDATE_COMPANY_PREFS_FAILURE:
    default:
      return state;
  }
};

export default companyPrefs;
