import { getUser } from '@float/common/selectors/currentUser';
import { useAppSelector } from '@float/common/store';
import { AccountType } from '@float/types';

export function useIsManagerOrHigher() {
  const user = useAppSelector(getUser);

  switch (user.account_type_id) {
    case AccountType.AccountOwner:
    case AccountType.Admin:
    case AccountType.Billing:
    case AccountType.Manager:
      return true;
    default:
      return false;
  }
}
