import { saveAs } from 'file-saver';
import { forEach, isEmpty, some } from 'lodash';
import { getTaskStatusString } from 'reports/components/TabBarFilters/StatusFilter';
import { getTeamModeString } from 'reports/components/TabBarFilters/TeamMode';
import { getProjectHasTotalBudgetSupport } from 'reports/helpers/getProjectHasTotalBudgetSupport';
import { parseTags } from 'reports/helpers/tags';

import { getPercentage as _getPercentage } from '@float/common/lib/budget';
import { moment } from '@float/libs/moment';
import { BudgetType } from '@float/types/project';

import { aggregateByProject } from '../table/projects';

// For the purposes of CSV export, we always want the '%' symbol
function getPercentage(a, b) {
  return _getPercentage(a, b, true);
}

const COLUMNS = [
  ['Project', (d) => d.name || ' '],
  ['Client', (d) => d.client?.replace(/\r|\n/g, '') || ' '], // filter new line characters
  ['Tags', (d) => d.tags || ' '],
  ['Phase', (d) => d.phase_name || ' '],
  [
    'Budget fee',
    (d) =>
      d.budget_type === BudgetType.TotalFee ||
      d.budget_type === BudgetType.HourlyFee
        ? d.budget
        : ' ',
  ],
  [
    'Budget hrs',
    (d) => (d.budget_type === BudgetType.TotalHours ? d.budget : ' '),
  ],
  [
    'Scheduled fee',
    (d) =>
      d.budget_type === BudgetType.TotalFee ||
      d.budget_type === BudgetType.HourlyFee
        ? d.billableProject
          ? d.scheduled.billableFee
          : d.scheduled.fee
        : ' ',
  ],
  ['Scheduled hrs', (d) => d.scheduled.hours],
  [
    'Logged fee',
    (d) => {
      return d.budget_type === BudgetType.TotalFee ||
        d.budget_type === BudgetType.HourlyFee
        ? d.logged.fee
        : ' ';
    },
  ],
  ['Logged hrs', (d) => d.logged.hours],
  [
    'Budget used % (Total Scheduled)',
    (d) => {
      const hasTotalBudgetSupport = getProjectHasTotalBudgetSupport(d);

      if (!hasTotalBudgetSupport) return '0%';

      return getPercentage(
        d.billableProject ? d.scheduled.billableFee : d.scheduled.fee,
        d.budget,
      );
    },
  ],
  [
    'Budget used % (Total Logged)',
    (d, ctx) => {
      const hasTotalBudgetSupport = getProjectHasTotalBudgetSupport(d);

      if (!hasTotalBudgetSupport) return '0%';

      return getPercentage(
        d.billableProject ? d.logged.billableFee : d.logged.fee,
        d.budget,
      );
    },
  ],
];

function getSheetData(ctx, rawTableData) {
  const data = aggregateByProject(ctx, rawTableData);

  const columns = ctx.timeTrackingEnabled
    ? [...COLUMNS]
    : COLUMNS.filter((c) => !c[0].includes('Logged'));

  const hasPhases = some(data, (d) => !isEmpty(d.children));
  if (!hasPhases) {
    columns.splice(2, 1);
  }

  const headers = columns.map((c) => c[0]);
  const result = [headers];

  forEach(data, (d, projectId) => {
    d.client = ctx.projects[projectId].client_name;
    d.tags = parseTags(ctx.projects[projectId].tags, true);
    d.billableProject = !ctx.projects[projectId].non_billable;

    if (hasPhases) {
      if (
        isEmpty(d.children) ||
        (Object.keys(d.children).length === 1 && d.children[0])
      ) {
        const row = columns.map((c) => c[1]({ ...d, phase_name: ' ' }) || 0);
        result.push(row);
      } else {
        const row = columns.map((c) => c[1]({ ...d, phase_name: ' ' }) || 0);
        result.push(row);

        forEach(d.children, (child) => {
          const combined = {
            ...d,
            ...child,
            phase_name: ctx.phases[child.phase_id]?.phase_name || 'No Phase',
          };

          const row = columns.map((c) => c[1](combined) || 0);
          result.push(row);
        });
      }
    } else {
      const row = columns.map((c) => c[1](d) || 0);
      result.push(row);
    }
  });

  result.push([], []);

  result.push(
    [
      'Date range',
      moment(ctx.settings.startDate).format('DD MMM YYYY'),
      moment(ctx.settings.endDate).format('DD MMM YYYY'),
    ],
    ['Tasks', getTaskStatusString(ctx.settings)],
    ['People', getTeamModeString(ctx.settings)],
  );

  if (ctx.searchFiltersString) {
    result.push([ctx.searchFiltersString.replace('Filters:', 'Filters')]);
  }

  return result;
}

export default async function exportTableCsv(ctx, rawTableData) {
  return import('xlsx').then((XLSX) => {
    const { settings } = ctx;
    const startDate = moment(settings.startDate).format('YYYYMMDD');
    const endDate = moment(settings.endDate).format('YYYYMMDD');

    const filename = `Projects-Table-${startDate}-${endDate}.csv`;

    const sheetData = getSheetData(ctx, rawTableData);

    const ws = XLSX.utils.aoa_to_sheet(sheetData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Float');
    const wbout = XLSX.write(wb, { type: 'array', bookType: 'csv' });

    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), filename);
  });
}
