import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { t } from '@lingui/macro';

import { projectEditable } from '@float/common/lib/rights';
import { useAppSelectorStrict } from '@float/common/store';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { ProjectStatus } from '@float/types/project';
import {
  IconStatusConfirmed,
  IconStatusDraft,
  IconStatusTentative,
} from '@float/ui/components/StatusIcons';
import { getUser } from '@float/web/selectors';

import { useWatchProjectOrPhaseFieldValue } from '../hooks/useWatchProjectOrPhaseFieldValue';

export function useStatusFieldOptions(entity: 'project' | 'phase') {
  const { getValues } = useFormContext();
  const isPhasePanel = entity === 'phase';
  const isDraftStatusEnabled = featureFlags.isFeatureEnabled(
    FeatureFlag.DraftStatus,
  );

  const user = useAppSelectorStrict(getUser);
  const project = getValues('project');
  const isProjectEditable = projectEditable(project, user);
  const projectConfirmed = project.status === ProjectStatus.Confirmed;
  const status = useWatchProjectOrPhaseFieldValue('status');

  const payload = useMemo(() => {
    const draftOption = {
      value: ProjectStatus.Draft,
      label: t`Draft`,
      description: t`Admins and Project Owners can view and edit`,
      icon: <IconStatusDraft />,
      selected: status === ProjectStatus.Draft,
      calloutId: 'draftStatusOptionCallout',
    };

    // If the parent project is draft, the phase can only be draft
    if (
      isPhasePanel &&
      isDraftStatusEnabled &&
      project.status === ProjectStatus.Draft
    ) {
      return {
        options: [draftOption],
        readOnly: true,
      };
    }

    const tentativeOption = {
      value: ProjectStatus.Tentative,
      label: t`Tentative`,
      description: t`All team members can view but are not notified`,
      icon: <IconStatusTentative />,
      selected: status === ProjectStatus.Tentative,
    };

    // If parent project is tentative the phases can only be tentative
    if (isPhasePanel && project.status === ProjectStatus.Tentative) {
      return {
        options: [tentativeOption],
        readOnly: true,
      };
    }

    const options = [
      tentativeOption,
      {
        value: ProjectStatus.Confirmed,
        label: t`Confirmed`,
        description: t`Team members can view and receive notifications`,
        icon: <IconStatusConfirmed />,
        selected: status === ProjectStatus.Confirmed,
      },
    ];

    // Only include the draft option if there is correct access permission
    if (
      isProjectEditable &&
      isDraftStatusEnabled &&
      !(projectConfirmed && isPhasePanel)
    ) {
      options.unshift(draftOption);
    }

    return { options, readOnly: false };
  }, [
    isDraftStatusEnabled,
    isPhasePanel,
    isProjectEditable,
    project.status,
    projectConfirmed,
    status,
  ]);

  return payload;
}
