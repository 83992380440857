import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  userCanUpdateThemself,
  userCanViewOthers,
} from '@float/common/lib/rights';
import { getUser } from '@float/common/selectors/currentUser';
import { PROMPTS } from '@float/constants/prompts';
import { Button, Icons, Modal } from '@float/ui/deprecated';
import * as colors from '@float/ui/deprecated/Theme/colors';

const StyledHeading = styled.div`
  text-align: center;
  margin-bottom: 23px;
`;

const StyledTitle = styled.div`
  font-size: 24px;
  line-height: 1.25;
  font-weight: bold;
  text-align: center;
  margin-top: 3px;
  margin-bottom: 22px;
`;

const StyledCheckList = styled.ul`
  line-height: 1.7;
  margin-bottom: 28px;

  li {
    padding-left: 30px;
    position: relative;

    svg {
      position: absolute;
      top: 6px;
      left: 0;
    }
  }
`;

const modalStyle = {
  marginTop: 150,
};

const modalBodyStyle = {
  minHeight: 350,
  paddingBottom: 42,
};

const btnStyle = { width: '100%' };

const { green } = colors.default;

const getDepartmentName = (deptId, departments) => {
  const department = departments[deptId] || {};
  return department.name || '';
};

const getViewRights = (currentUser, departments) => {
  if (![3, 4, 6].includes(currentUser.account_tid)) {
    return "See your team's schedule";
  }

  if (currentUser.department_filter?.length === 1) {
    const deptName = getDepartmentName(
      currentUser.department_filter[0],
      departments,
    );
    return deptName
      ? `See the ${deptName} team's schedule`
      : "See your team's schedule";
  } else if (userCanViewOthers(currentUser)) {
    return "See your team's schedule";
  }
  return '';
};

const getAccessRightsIntro = ({
  currentUser,
  departments,
  timeTrackingEnabled,
}) => {
  const viewRights = getViewRights(currentUser, departments);
  let rights = [];

  switch (currentUser.account_tid) {
    case 1:
    case 2: // Admin
    case 6: // Department Manager
      rights = [
        'Manage your departments',
        viewRights,
        "Track your team's capacity",
      ];
      break;
    case 3: // Project manager
      rights = [
        'Create and schedule your projects',
        viewRights,
        "Track your team's capacity",
      ];
      break;
    case 5: // Billing
      rights = [
        'Create and schedule projects',
        viewRights,
        'Update plan and billing information',
      ];
      break;
    case 4:
      // Member
      rights = [
        userCanUpdateThemself(currentUser)
          ? 'Manage your tasks'
          : 'See your tasks',
        viewRights,
        'Get notified when new work is added',
      ];
      break;
    default:
      return [];
  }

  if (timeTrackingEnabled) {
    rights.push('Log time');
  }

  return rights;
};

const CheckListItem = ({ children }) => (
  <li>
    <Icons.Tick color={green} />
    {children}
  </li>
);

function NonAccountOwner({
  alreadySeen,
  companyName,
  currentUser,
  departments,
  timeTrackingEnabled,
  markAsSeen,
}) {
  if (alreadySeen) {
    return null;
  }

  const rights = getAccessRightsIntro({
    currentUser,
    departments,
    timeTrackingEnabled,
  });
  let firstName = currentUser.name.match(/[^\s]+/)[0];
  if (!firstName || firstName.toLowerCase() === '* new person') {
    firstName = '';
  } else {
    firstName = `${firstName}, `;
  }

  const onClose = () => {
    markAsSeen(PROMPTS.welcomeNonAccountOwner);
  };

  return (
    <Modal
      isOpen
      shouldCloseOnBgClick={false}
      shouldCloseOnEsc={false}
      width={460}
      style={modalStyle}
    >
      <Modal.Body style={modalBodyStyle}>
        <StyledHeading>
          <StyledTitle>
            Welcome {firstName}to
            {companyName.toLowerCase().startsWith('the ') ? ' ' : ' the '}
            {companyName} schedule!
          </StyledTitle>
          <p>It’s where all your team’s hard work is planned.</p>
        </StyledHeading>
        {rights && rights.length ? (
          <>
            <strong>You can use it to:</strong>
            <StyledCheckList>
              {rights.map((x, i) =>
                x ? <CheckListItem key={i}>{x}</CheckListItem> : null,
              )}
            </StyledCheckList>
          </>
        ) : null}
        <Button autoFocus style={btnStyle} onClick={onClose}>
          Get started
        </Button>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    companyName: state.companyPrefs.company_name,
    timeTrackingEnabled: state.companyPrefs.time_tracking > 0,
    currentUser: getUser(state),
    departments: state.departments.departments,
  };
};

export default connect(mapStateToProps)(NonAccountOwner);
