import React from 'react';
import { connect } from 'react-redux';
import { t, Trans } from '@lingui/macro';
import { bindActionCreators, compose } from 'redux';
import styled from 'styled-components';

import * as projectActions from '@float/common/actions/projects';
import { moment } from '@float/libs/moment';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import {
  Button,
  DatePicker,
  Modal,
  withConfirm,
  withSnackbar,
} from '@float/ui/deprecated';

import modalManagerHoc from '../../modalManager/modalManagerHoc';
import withListeners from '../decorators/withListeners';
import { fetchProjectShiftDates } from './ProjectShiftModal.helpers';

import * as styles from './ProjectShiftModal.styles.css';

const DateContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 150px;
  margin-right: 20px;
  margin-top: 20px;

  input {
    font-size: 18px !important;
  }
`;

class ProjectShiftModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      startDate: props.startDate,
      endDate: props.endDate,
      startDateUpdated: false,
      endDateUpdated: false,
    };
  }

  initialDates = null;

  getProjectId() {
    const { project } = this.props.modalSettings;

    return project?.project_id;
  }

  getProjectName() {
    const { project } = this.props.modalSettings;

    return project?.project_name;
  }

  componentDidMount() {
    const projectId = this.getProjectId();

    if (projectId) {
      fetchProjectShiftDates(projectId).then((shiftDates) => {
        const numWorkDays = this.props.calcEntityLength({
          start_date: shiftDates.startDate,
          end_date: shiftDates.endDate,
        });

        this.initialDates = shiftDates;

        this.setState({
          isLoading: false,
          startDate: moment(shiftDates.startDate),
          endDate: moment(shiftDates.endDate),
          numWorkDays,
        });
      });
    }
  }

  isNonWorkDay = (date) => {
    return !this.props.isWorkDay(date.format('YYYY-MM-DD'));
  };

  handleChangeStartDate = (date) => {
    this.setState((ps) => {
      const end = this.props.calcEntityEndDate({
        start_date: date.format('YYYY-MM-DD'),
        length: ps.numWorkDays,
      });

      return {
        startDate: date,
        endDate: moment(end),
        startDateUpdated: true,
        endDateUpdated: false,
      };
    });
  };

  handleChangeEndDate = (date) => {
    this.setState((ps) => {
      const start = this.props.calcEntityStartDate({
        end_date: date.format('YYYY-MM-DD'),
        length: ps.numWorkDays,
      });

      return {
        startDate: moment(start),
        endDate: date,
        startDateUpdated: false,
        endDateUpdated: true,
      };
    });
  };

  formatDate = (date) => {
    return date ? moment(date).format('YYYY-MM-DD') : null;
  };

  hideAfterCheckingForChanges = (e) => {
    prevent(e);
    if (this.state.readOnly) {
      this.hide();
      return;
    }
    const { initialDates } = this;
    const { startDate, endDate } = this.state;
    const changesMade =
      this.formatDate(initialDates.startDate) !== this.formatDate(startDate) ||
      this.formatDate(initialDates.endDate) !== this.formatDate(endDate);

    if (!changesMade) {
      this.hide();
    }

    this.props.confirm({
      title: t`You have unsaved changes.`,
      message: t`Are you sure you want to leave?`,
      confirmLabel: t`Leave`,
      cancelLabel: t`Cancel`,
      onConfirm: this.hide,
    });
  };

  onSubmit = (evt) => {
    prevent(evt);

    const projectId = this.getProjectId();
    const { startDate, endDate, startDateUpdated, endDateUpdated } = this.state;

    if (!projectId) return;

    const start_date = startDateUpdated ? startDate : null;
    const end_date = endDateUpdated ? endDate : null;
    this.setState({ isShifting: true });

    const projectName = this.getProjectName();

    this.props.actions
      .shiftProject(projectId, start_date, end_date)
      .then(() => {
        this.props.showSnackbar(t`${projectName} timeline updated.`);
        this.hide();
      })
      .catch((e) => {
        console.error(e);
        this.setState({ isShifting: false });
      });
  };

  onEscapePressed = () => {
    this.hideAfterCheckingForChanges();
  };

  hide = (evt) => {
    prevent(evt);
    this.setState({ isShifting: false });
    this.props.manageModal({
      visible: false,
      modalType: 'projectShiftModal',
    });
  };

  formId = `form-${Math.random()}`;

  render() {
    if (this.state.isLoading) return null;

    return (
      <Modal isOpen={true} onClose={this.hideAfterCheckingForChanges}>
        <Modal.Header>
          <Modal.Title>
            <Trans>Shift Project</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            className={styles.form}
            id={this.formId}
            onSubmit={this.onSubmit}
          >
            <p>
              <Trans>
                Update either the start or the end date to move allocations on
                this project. Completed items and all logged time will not be
                moved.
              </Trans>
            </p>
            <DateContainer>
              <DatePicker
                label="Start"
                disableDayFn={this.isNonWorkDay}
                value={this.state.startDate}
                onChange={this.handleChangeStartDate}
              />
            </DateContainer>
            <DateContainer style={{ marginRight: 0 }}>
              <DatePicker
                label="End"
                disableDayFn={this.isNonWorkDay}
                value={this.state.endDate}
                onChange={(date) => {
                  this.handleChangeEndDate(date);
                }}
              />
            </DateContainer>
          </form>
        </Modal.Body>
        <Modal.Actions>
          <Button
            type="submit"
            loader={this.state.isShifting}
            form={this.formId}
          >
            <Trans>Update</Trans>
          </Button>
          <Button
            type="button"
            appearance="secondary"
            onClick={this.hideAfterCheckingForChanges}
          >
            <Trans>Cancel</Trans>
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const enhance = withListeners([
  {
    eventType: 'keyup',
    testFn: (e) => e.keyCode === 27,
    handler: 'onEscapePressed',
  },
]);

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(
      { shiftProject: projectActions.shiftProject },
      dispatch,
    ),
  },
});

const Component = compose(withConfirm, withSnackbar)(ProjectShiftModal);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  modalManagerHoc({
    Comp: enhance(Component),
    modalType: 'projectShiftModal',
  }),
);
