import { useRef } from 'react';
import { useEvent } from 'react-use';

import * as helpers from './useSelectorKeyboard.helpers';

export function useSelectorKeyboard(onSelect: (index: number) => void) {
  const ref = useRef<HTMLDivElement>(null);

  useEvent('keydown', (evt: KeyboardEvent) => {
    const container = ref.current;

    if (!container) return;

    const currentElement = document.activeElement as HTMLElement | null;

    if (!helpers.isManagedElement(currentElement)) return;

    switch (evt.key) {
      case 'ArrowDown': {
        evt.preventDefault();

        const next = helpers.getNextElement(container, currentElement);

        helpers.focusElement(next);
        break;
      }
      case 'ArrowUp': {
        evt.preventDefault();

        const prev = helpers.getPrevElement(container, currentElement);

        helpers.focusElement(prev);
        break;
      }
      case 'Enter': {
        const options = helpers.getOptions(container);
        const index = options.indexOf(currentElement!);

        onSelect(Math.max(0, index));

        break;
      }
      // Space
      case ' ': {
        const options = helpers.getOptions(container);
        const index = options.indexOf(currentElement!);

        if (index > -1) {
          onSelect(index);
        }

        break;
      }
    }
  });

  return ref;
}
