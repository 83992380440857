import { t } from '@lingui/macro';

import {
  createProject as createProjectAction,
  createProjectAsync,
  updateProjectFromSidePanel,
} from '@float/common/actions';
import { diffEntityPayload } from '@float/common/lib/diffEntityPayload';
import { useAppDispatchStrict } from '@float/common/store';
import { addClient } from '@float/web/settingsV2/actions/clients';

import { getProjectTeamDiff } from '../helpers/getProjectTeamDiff';
import {
  getDefaultProjectName,
  mapToAsyncProjectApiPayload,
} from '../helpers/mapToAsyncProjectApiPayload';
import { HandleProjectCreateProps, HandleProjectUpdateProps } from '../types';

export function useProjectSave() {
  const dispatch = useAppDispatchStrict();

  async function handleCreateClient(project: { client_id?: number | string }) {
    // When the user creates a new client `client_id` is passed as a string
    if (typeof project.client_id === 'string') {
      const { payload: client } = await dispatch(
        addClient({ name: project.client_id }),
      );

      return client.client_id as number;
    }

    return project.client_id;
  }

  async function handleCreate(props: HandleProjectCreateProps) {
    const { project, team: project_team } = props;
    const clientId = await handleCreateClient(project);
    const res = await dispatch(
      createProjectAction(
        {
          ...project,
          client_id: clientId,
          project_name: project.project_name || getDefaultProjectName(),
          project_team,
        },
        props.options,
      ),
    );

    if (res) return res.project_id;

    throw new Error(t`Failed to create project`);
  }

  async function handleCreateAsync(props: HandleProjectCreateProps) {
    const clientId = await handleCreateClient(props.project);
    const payload = mapToAsyncProjectApiPayload(props, clientId);
    const processId = await dispatch(createProjectAsync(payload));

    if (processId) return processId;

    throw new Error(t`Failed to create project`);
  }

  async function handleUpdate(props: HandleProjectUpdateProps) {
    const { projectId, update, currentValues } = props;

    if (!currentValues) {
      await dispatch(
        updateProjectFromSidePanel(projectId, update.project, update.teamDiff),
      );
      return;
    }

    const projectDiff = diffEntityPayload(
      update.project,
      currentValues.project,
    );
    const teamDiff = getProjectTeamDiff(update.team, currentValues.team);

    if (!projectDiff && !teamDiff) return;

    const payload = projectDiff || {};

    const clientId = await handleCreateClient(payload);

    if (clientId) {
      payload.client_id = clientId;
    }

    await dispatch(updateProjectFromSidePanel(projectId!, payload, teamDiff));
  }

  return {
    handleCreate,
    handleCreateAsync,
    handleUpdate,
  };
}
