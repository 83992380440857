import React from 'react';
import modalManagerHoc from 'modalManager/modalManagerHoc';

import ConfirmModal from './ConfirmModal';

class ErrorModal extends React.Component {
  onClose = (evt) => {
    this.props.manageModal({
      visible: false,
      modalType: 'errorModal',
    });

    if (typeof this.props.modalSettings.onClose === 'function') {
      this.props.modalSettings.onClose();
    }
  };

  render() {
    const { error, props } = this.props.modalSettings;

    return (
      <ConfirmModal
        title={error.title}
        message={error.message}
        cancelColor="gray"
        cancelLabel="Close"
        ignoreSubmit={!props}
        {...props}
        close={this.onClose}
      />
    );
  }
}

export default modalManagerHoc({ Comp: ErrorModal, modalType: 'errorModal' });
