import React from 'react';

export default function ({ color = '#fff', className = '' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="13"
      viewBox="0 0 8 13"
      className={className}
    >
      <g className="fill" fill={color}>
        <g transform="translate(4.000000, 6.500000) scale(-1, 1) translate(-4.000000, -6.500000) ">
          <polygon points="8 1.5 6.50002441 0 2.44140629e-05 6.5 6.50002441 13 8 11.5 3.00002441 6.5" />
        </g>
      </g>
    </svg>
  );
}
