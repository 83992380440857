import React, { useRef } from 'react';

import { Rights } from '@float/common/lib/rights';
import { getPeopleMap } from '@float/common/selectors/people';
import { SerenaState } from '@float/common/selectors/serena';
import {
  PersonProjectRow,
  PersonRow,
  ProjectRow,
} from '@float/common/serena/Data/useScheduleRows';
import { useAppSelector } from '@float/common/store';
import { CellItem } from '@float/types';

import { lockedDayItem } from './LockedDayItem.css';

type LockedDayItemProps = {
  dayWidth: number;
  isEntityResizing?: boolean;
  item: CellItem<'lockedDay'>;
  reduxData: SerenaState;
  row: PersonRow | PersonProjectRow | ProjectRow;
  suvSingleDay?: string | null;
};

function LockedDayItem(props: LockedDayItemProps) {
  const { item, dayWidth, suvSingleDay, isEntityResizing, row, reduxData } =
    props;
  const { x = 0 } = item;
  const didMouseDown = useRef(false);

  const left = x * dayWidth + 1;
  let width: string | number = dayWidth - 1;

  if (suvSingleDay) {
    width = 'calc(100% - 1px)';
  }

  const peopleMap = useAppSelector(getPeopleMap);
  const person = row.type === 'person' && peopleMap[row.data.people_id];

  return (
    <div
      className={lockedDayItem}
      onMouseDown={(event) => {
        if (
          !Rights.canCreateLoggedTime(reduxData.user, {
            person,
            entity: {
              isInLockPeriod: true,
            },
            ignore: [
              'isLoggedTimeProjectOwner',
              'onLoggedTimeProjectTeam',
              'loggedTimeProjectIsCommon',
            ],
          })
        ) {
          event.stopPropagation();
        }

        if (event.button !== 0) return;
        didMouseDown.current = true;
      }}
      onMouseUp={() => {
        if (!didMouseDown.current) return;
        didMouseDown.current = false;
      }}
      data-is-entity-resizing={Boolean(isEntityResizing)}
      style={{
        left,
        width,
        top: suvSingleDay ? 0 : -1,
        height: suvSingleDay ? '100%' : '101%', // 101% is to account for the top: -1 just above.
      }}
    />
  );
}

export default LockedDayItem;
