import addDays from 'date-fns/addDays';
import differenceInDays from 'date-fns/differenceInDays';

import { moment } from '@float/libs/moment';

const isPartTime = (person) => {
  return person && person.employee_type === 0 && person.people_type_id != 3;
};

const parse = {
  getTaskModel(people, user, opts) {
    let hours_pd = 8;
    let startDate = opts.start_date
      ? moment(`${opts.start_date} 00:00:00`).toDate()
      : new Date();
    const endDate = opts.end_date
      ? moment(`${opts.end_date} 00:00:00`).toDate()
      : new Date();

    const diff = differenceInDays(endDate, startDate);
    let minHours = 0;
    const visitedDays = {};
    for (let i = 0; i <= diff; i++) {
      let hours;
      const currentDay = startDate.getDay();
      if (!visitedDays[currentDay]) {
        visitedDays[currentDay] = true;
        if (isPartTime(people)) {
          hours = people.work_days_hours[currentDay];
        } else {
          hours = user.work_days_hours[currentDay];
        }

        if (!minHours || (hours && minHours > hours)) {
          minHours = hours;
        }
      }
      startDate = addDays(startDate, 1);
    }
    if (minHours) {
      hours_pd = minHours;
    }

    const today = new Date();

    const res = {
      archived: 0,
      billable: null,
      created: today,
      creation_doy: moment().dayOfYear(),
      creator_id: user && user.id,
      is_owner: false,
      overtime: 0,
      sked_admin: true,
      start_time: null,
      end_time: null,
      start_yr: moment(today).year(),
      task_cal_days: 1,
      task_days: 1,
      name: '',
      notes: '',
      tentative: 0,
      length: 0,
      id: null,
      task_id: null,
      titles: [],
      total_hours: hours_pd,
      client_name: null,
      client_id: null,
      hours_pd,
      start_date: today,
      end_date: today,
      project: null,
      project_name: null,
      project_id: null,
      project_color: null,
      person_name: people && people.name,
      created_by: user && user.name,
      repeat_state: 0,
      status: 2,
      repeat_end_date: null,
      people_ids: people ? [people.people_id] : [],
      allocation_type: null,
      priority_info: {},
      ...opts,
    };
    Object.keys(opts || {}).forEach((key) => {
      res[key] = opts[key];
    });
    return res;
  },
};

export default parse;
