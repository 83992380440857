import React from 'react';

export default function ({ color = '#868D92', style = {} }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className="icon-chevron-down"
      focusable={false}
      style={style}
    >
      <path
        className="fill"
        fill={color}
        fillRule="evenodd"
        d="M16.6 9L12 13.6 7.4 9 6 10.4l6 6 6-6z"
      />
    </svg>
  );
}
