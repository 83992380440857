import React from 'react';

import BaseIcon, { BaseIconProps } from './BaseIcon';

export const IconMeatballHorizontal = (props: BaseIconProps) => (
  <BaseIcon {...props}>
    <path
      stroke="currentColor"
      d="M8.5 12C8.5 12.2761 8.27614 12.5 8 12.5C7.72386 12.5 7.5 12.2761 7.5 12C7.5 11.7239 7.72386 11.5 8 11.5C8.27614 11.5 8.5 11.7239 8.5 12Z"
    />
    <path
      stroke="currentColor"
      d="M12.5 12C12.5 12.2761 12.2761 12.5 12 12.5C11.7239 12.5 11.5 12.2761 11.5 12C11.5 11.7239 11.7239 11.5 12 11.5C12.2761 11.5 12.5 11.7239 12.5 12Z"
    />
    <path
      stroke="currentColor"
      d="M16.5 12C16.5 12.2761 16.2761 12.5 16 12.5C15.7239 12.5 15.5 12.2761 15.5 12C15.5 11.7239 15.7239 11.5 16 11.5C16.2761 11.5 16.5 11.7239 16.5 12Z"
    />
  </BaseIcon>
);
