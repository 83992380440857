import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import api3 from '@float/common/api3';
import { HttpError } from '@float/common/api3/makeRequest';
import { AutoLogTimeSettings } from '@float/types/autoLogTime';

const QUERY_KEY = ['autoLogTimeSettings'];

export function useGetAutoLogTimeSettings() {
  const { data, error } = useSuspenseQuery<
    AutoLogTimeSettings,
    HttpError,
    AutoLogTimeSettings,
    string[]
  >({
    queryKey: QUERY_KEY,
    queryFn: () => api3.getAutoLogTimeSettings(),
  });

  const queryClient = useQueryClient();

  function mutate(value: AutoLogTimeSettings) {
    queryClient.setQueryData(QUERY_KEY, value);
  }

  return { data, error, mutate };
}
