import styled from 'styled-components';

import EH from '@float/ui/deprecated/Earhart';

export const TrashCan = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;

  opacity: 0;

  cursor: pointer;

  transform: translateY(3px);

  transition-property: transform, opacity;
  transition-duration: ${EH.Animation.Settings.Duration.Short};
  transition-timing-function: ${EH.Animation.Settings.Easing.InOutCurve};
`;

export const StyledBackground = styled.div`
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  border-radius: 12px;

  z-index: 0;

  transition-property: transform;
  transition-duration: ${EH.Animation.Settings.Duration.Normal};
  transition-timing-function: ${EH.Animation.Settings.Easing.InOutCurve};

  &:before,
  &:after {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border-radius: 12px;

    content: '';

    z-index: 0;

    pointer-events: none;

    transition-property: transform, opacity, border-color;
    transition-duration: ${EH.Animation.Settings.Duration.Normal};
    transition-timing-function: ${EH.Animation.Settings.Easing.InOutCurve};
  }
`;

export const StyledContent = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  padding: 32px 20px;

  z-index: 1;

  h2 {
    ${EH.Typography.DisplayXXS.SB600};

    color: ${EH.Colors.FIN.Lt.Emphasis.High};

    transition-property: color;
    transition-duration: ${EH.Animation.Settings.Duration.Normal};
    transition-timing-function: ${EH.Animation.Settings.Easing.InOutCurve};

    & + * {
      margin-top: 10px;
    }
  }

  h3 {
    ${EH.Typography.TextS.M500};

    color: ${EH.Colors.FIN.Lt.Emphasis.Medium};

    & + * {
      margin-top: 15px;
    }
  }

  p {
    ${EH.Typography.TextS.M500};

    text-align: center;

    color: ${EH.Colors.Primary.Flay.Light[9]};

    a {
      color: ${EH.Colors.FIN.Lt.Emphasis.Medium};
    }
  }

  svg {
    path {
      transition-property: stroke;
      transition-duration: ${EH.Animation.Settings.Duration.Normal};
      transition-timing-function: ${EH.Animation.Settings.Easing.InOutCurve};
    }
  }

  .avatar-stack {
    & + * {
      margin-top: 6px;
    }
  }

  .icon-lock {
    --svg-icon-color: ${EH.Colors.Primary.Flay.Light[8]};

    position: absolute;

    top: 10px;
    left: 10px;
  }
`;

export const StyledCompanyCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: relative;

  border-radius: 12px;

  text-align: center;

  cursor: default;

  transition-property: transform;
  transition-duration: ${EH.Animation.Settings.Duration.Normal};
  transition-timing-function: ${EH.Animation.Settings.Easing.InOutCurve};

  &.active {
    cursor: pointer;
  }

  &.add {
    --svg-icon-color: ${EH.Colors.Core.Lt.Blue.Primary};

    cursor: pointer;

    ${StyledBackground} {
      overflow: hidden;

      &:before {
        border-width: 2px;
        border-style: dashed;
        border-color: ${EH.Colors.Core.Lt.Blue.Primary};

        transform: scale(1.005, 1.012);
      }
    }

    &:hover,
    &:focus {
      ${StyledBackground} {
        &:before {
          transform: none;
        }
      }
    }

    h2 {
      color: ${EH.Colors.Core.Lt.Blue.Primary};
    }
  }

  &:not(.add) {
    ${StyledBackground} {
      background-color: ${EH.Colors.FIN.Lt.Emphasis.White};

      &:before {
        ${EH.Elevations.E150};
      }

      &:after {
        ${EH.Elevations.E400};

        opacity: 0;
      }
    }

    &:hover,
    &:focus {
      transform: translateY(-2px);

      ${StyledBackground} {
        &:before {
          opacity: 0.25;
        }
        &:after {
          opacity: 0.5;
        }
      }
    }

    &[data-focus-visible-added] {
      ${StyledBackground} {
        border: 1px solid ${EH.Colors.Core.Lt.Blue.Primary};
      }
    }
  }

  &:not(.active):not(.add) {
    h2 {
      color: ${EH.Colors.Primary.Flay.Light[8]};
    }
  }

  &:hover ${TrashCan} {
    opacity: 1;
    transform: none;
  }
`;
