import React from 'react';

export const IconResources = () => (
  <svg
    fill="none"
    width={48}
    height={48}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#66AAE7"
      stroke="#242C39"
      strokeWidth=".33"
      d="M11.532 9.165h32.46a2.007 2.007 0 0 1 1.951 2.477L40.471 34.39a2.007 2.007 0 0 1-1.951 1.538H6.06a2.007 2.007 0 0 1-1.951-2.477l5.471-22.75a2.007 2.007 0 0 1 1.952-1.537Z"
    />
    <path
      fill="#66AAE7"
      stroke="#242C39"
      strokeWidth=".33"
      d="M16.51 19.668h6.38c.34 0 .631.243.692.577l4.068 22.26a.704.704 0 0 1-.692.83h-6.38a.704.704 0 0 1-.692-.577l-4.068-22.26a.704.704 0 0 1 .692-.83Z"
    />
    <path
      fill="#D9EDFF"
      stroke="#242C39"
      strokeWidth=".33"
      d="M15.636 19.668h6.38c.342 0 .634.245.694.581l3.947 22.26a.704.704 0 0 1-.694.826h-6.38a.704.704 0 0 1-.694-.581l-3.946-22.26a.704.704 0 0 1 .693-.826Z"
    />
    <path
      fill="#D9EDFF"
      stroke="#242C39"
      strokeWidth=".33"
      d="M10.228 9.165h32.46a2.007 2.007 0 0 1 1.951 2.477L39.168 34.39a2.007 2.007 0 0 1-1.952 1.538H4.756a2.007 2.007 0 0 1-1.95-2.477l5.47-22.75a2.007 2.007 0 0 1 1.952-1.537Z"
    />
    <path
      fill="#EEF7FF"
      stroke="#242C39"
      strokeWidth=".33"
      d="M10.522 10.24h31.641c.736 0 1.279.687 1.107 1.403l-4.263 17.803c-.123.512-.58.873-1.107.873H6.26a1.138 1.138 0 0 1-1.108-1.403l4.263-17.803c.123-.513.58-.874 1.107-.874Z"
    />
    <path
      fill="#EAFDDB"
      stroke="#242C39"
      strokeWidth=".33"
      d="M10.331 16.165h18.113a.27.27 0 0 1 .261.335L25.93 27.63a.27.27 0 0 1-.261.205H7.556a.27.27 0 0 1-.261-.335l2.775-11.13a.27.27 0 0 1 .261-.205Z"
    />
    <path
      fill="#E8D7FF"
      stroke="#242C39"
      strokeWidth=".33"
      d="M29.34 24.119h8.306a.27.27 0 0 1 .258.348l-.968 3.177a.27.27 0 0 1-.258.191h-8.123a.27.27 0 0 1-.262-.334l.786-3.177a.27.27 0 0 1 .261-.205Z"
    />
    <path
      fill="#E5484D"
      stroke="#242C39"
      strokeWidth=".33"
      d="M10.84 12.165h1.341a.27.27 0 0 1 .262.335l-.283 1.13a.27.27 0 0 1-.261.205h-1.343a.27.27 0 0 1-.261-.335l.283-1.13a.27.27 0 0 1 .261-.205Z"
    />
    <path
      fill="#9CE8D9"
      stroke="#242C39"
      strokeWidth=".33"
      d="M13.34 12.165h1.341a.27.27 0 0 1 .262.335l-.283 1.13a.27.27 0 0 1-.261.205h-1.343a.27.27 0 0 1-.261-.335l.283-1.13a.27.27 0 0 1 .261-.205Z"
    />
    <path
      fill="#fff"
      stroke="#242C39"
      strokeWidth=".33"
      d="M16.652 12.165H39.79a.835.835 0 0 1 0 1.67H16.243a.666.666 0 0 1-.654-.792c.098-.51.543-.878 1.063-.878Z"
    />
    <path
      stroke="#242C39"
      strokeLinecap="round"
      strokeWidth=".5"
      d="M30.5 16h9.402M30 18h9.5m-10 2H39m-10 2h9"
    />
    <path
      fill="#66AAE7"
      stroke="#242C39"
      strokeWidth=".33"
      d="M26.363 41.778h3.527c1.11 0 2.008.899 2.008 2.007v.05h-5.535v-2.057Z"
    />
    <path
      fill="#D9EDFF"
      stroke="#242C39"
      strokeWidth=".33"
      d="M17.423 41.778h11.513c1.108 0 2.007.899 2.007 2.007v.05H15.416v-.05c0-1.108.898-2.007 2.007-2.007Z"
    />
  </svg>
);
