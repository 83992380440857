import styled, { css } from 'styled-components';

import * as Animation from '@float/ui/deprecated/Earhart/Animation';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';
import { respondTo } from '@float/ui/deprecated/Theme/helpers/responsive/respondTo';
import TooltipNotification from '@float/ui/deprecated/TooltipNotification';

export const StyledTooltipNotification = styled(TooltipNotification)`
  position: absolute;

  top: calc(100% + 20px);
  left: 0;
`;

export const StyledContainer = styled.div<{ $left: number; $height: number }>`
  position: absolute;

  width: ${({ $left }) => `calc(100vw - ${$left}px)`};
  height: ${({ $height }) => `${$height}px`};

  top: 53px;
  left: ${({ $left }) => `${$left}px`};

  pointer-events: none;

  transform: translateX(-60px);

  &.enter,
  &.appear {
    ${StyledTooltipNotification} {
      opacity: 0;
      transform: translateY(20px);
    }
  }

  &.enter-active,
  &.appear-active {
    ${StyledTooltipNotification} {
      opacity: 1;
      transform: none;

      transition-property: opacity, transform;
      transition-duration: 0.25s, 0.35s;
      transition-timing-function: ${Animation.Settings.Easing.DefaultOut},
        ${Animation.Settings.Easing.OutBounce};
    }
  }

  &.exit {
    ${StyledTooltipNotification} {
      opacity: 1;
      transform: none;
    }
  }

  &.exit-active,
  &.exit-done {
    ${StyledTooltipNotification} {
      opacity: 0;
      transform: translateY(5px);

      transition-property: opacity, transform;
      transition-duration: 0.25s;
      transition-timing-function: ${Animation.Settings.Easing.DefaultOut};
    }
  }

  ${respondTo(
    'small',
    '<=',
  )(css`
    transform: none;
  `)}

  &,
  * {
    box-sizing: border-box;
  }
`;

export const StyledAddTaskContainer = styled.div`
  position: absolute;

  top: 50%;
  left: 105px;

  transform: translateY(-50%);
`;

export const StyledTooltipContent = styled.div`
  ${Typography.TextS14.R400};

  padding: 16px;

  color: ${Colors.FIN.Lt.Emphasis.High};
`;

export const StyledClickDrag = styled.div`
  position: relative;

  width: 54px;
  height: 54px;

  div {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    &:nth-child(1) {
      border-radius: 28px;

      background-color: ${Colors.Radix.Primary.Flue[5] + '25'};
    }

    &:nth-child(2) {
      top: 8px;
      left: 8px;

      width: calc(100% - 16px);
      height: calc(100% - 16px);

      border-radius: 20px;

      background-color: ${Colors.Radix.Primary.Flue[7]};
    }
  }
`;
