import React from 'react';
import { FieldArray, FieldArrayPath } from 'react-hook-form';

import { Button } from '@float/ui/components/Button';
import { IconPlusCircle } from '@float/ui/icons/essentials/IconPlusCircle';

import { useFieldArrayContext } from '../../hooks/useFieldArrayContext';
import { ProjectFormData } from '../../types';

type SidePanelAddRowButtonProps<N extends FieldArrayPath<ProjectFormData>> = {
  data: () => FieldArray<ProjectFormData, N>;
  buttonText: string;
  disabled?: boolean;
};

export function SidePanelAddRowButton<
  N extends FieldArrayPath<ProjectFormData>,
>(props: SidePanelAddRowButtonProps<N>) {
  const { append } = useFieldArrayContext<ProjectFormData, N>();
  const onAddHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    append(props.data());
  };

  return (
    <Button
      size="xsmall"
      appearance="clear-flay"
      iconStart={IconPlusCircle}
      style={{ width: 'fit-content' }}
      onClick={onAddHandler}
      disabled={props.disabled}
    >
      {props.buttonText}
    </Button>
  );
}
