import React, { useEffect, useRef } from 'react';
import { getRecentProjectColors } from 'selectors';

import { useAppSelector } from '@float/common/store';
import { DEFAULT_PALETTE } from '@float/constants/colors';
import { ColorPicker } from '@float/ui/deprecated';

const DEFAULT_COLOR = DEFAULT_PALETTE[0];

export default function Color({ value, onChange }) {
  const recentColors = useAppSelector(getRecentProjectColors);
  const initialValue = useRef(value);

  useEffect(() => {
    if (!initialValue.current) {
      initialValue.current = DEFAULT_COLOR;
      onChange(DEFAULT_COLOR);
    }
  }, [onChange]);

  return (
    <ColorPicker
      value={value || DEFAULT_COLOR}
      recentColors={recentColors}
      onChange={onChange}
    />
  );
}
