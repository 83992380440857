import React from 'react';

export default function ({ color = '#868D92' }) {
  return (
    <svg
      width="11px"
      height="10px"
      viewBox="0 0 11 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="1.3"
        d="M6.125 7.5L3 10V7.5H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v4.5a1 1 0 0 1-1 1H6.125z"
      />
    </svg>
  );
}
