import { MutableRefObject, useEffect, useState } from 'react';

export type UsePinnedArrowParams = {
  isStart?: boolean;
  scrollWrapperRef: MutableRefObject<HTMLElement | undefined>;
  boxRef: MutableRefObject<HTMLElement | null>;
  sideColumnSize: number;
};

export function usePinnedArrow({
  isStart,
  scrollWrapperRef,
  boxRef,
  sideColumnSize,
}: UsePinnedArrowParams) {
  const [pinned, setPinned] = useState({
    leftArrow: false,
    rightArrow: false,
    outsideViewport: false,
  });

  useEffect(() => {
    if (!isStart) return;

    const scrollWrapper = scrollWrapperRef.current;
    const box = boxRef.current;

    if (!scrollWrapper || !box) return;

    function handleIntersect(entries: IntersectionObserverEntry[]) {
      for (const entry of entries) {
        if (entry.intersectionRatio >= 1) {
          setPinned({
            leftArrow: false,
            rightArrow: false,
            outsideViewport: false,
          });
        } else if (entry.rootBounds) {
          setPinned({
            leftArrow: entry.boundingClientRect.x < entry.rootBounds.x,
            rightArrow: entry.boundingClientRect.x > entry.rootBounds.x,
            outsideViewport: entry.intersectionRatio === 0,
          });
        }
      }
    }

    const leftMargin = (sideColumnSize - 1) * -1;
    // Want to show the arrow only when the element is 1px outside of the wrapper
    const rightMargin = -1;

    const io = new IntersectionObserver(handleIntersect, {
      root: scrollWrapper,
      rootMargin: `0px ${rightMargin}px 0px ${leftMargin}px`,
      threshold: [0, 1],
    });

    io.observe(box);

    return () => io.disconnect();
  }, [isStart, scrollWrapperRef, boxRef, sideColumnSize]);

  return pinned;
}
