export enum TaskStatusDescription {
  Confirmed = 'Confirmed',
  Tentative = 'Tentative',
  Draft = 'Draft',
  Completed = 'Completed',
}

export enum TaskEditMode {
  ADD_EDIT = 'addEdit',
  INSERT = 'insert', // deprecated (removed from UI)
  SPLIT = 'split',
  REPLACE = 'replace', // deprecated (removed from UI)
  COMPLETE = 'complete',
  DELETE = 'delete',
  LINK = 'link',
  LOG_TIME = 'log_time',
}
