import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m9.75 12.75 1.25 1.5 3.25-4.5"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M11.8 19.5s6.3-2.9 6.3-7.25V7.175L11.8 5 5.5 7.175v5.075c0 4.35 6.3 7.25 6.3 7.25Z"
    />
  </BaseIcon>
);
