import { ReduxStateStrict } from '@float/common/reducers/lib/types';

export const getSearchResults = (state: ReduxStateStrict) => {
  // Check to verify that the SearchWorker is enabled and active
  if (state.searchResults?.active) {
    return state.searchResults;
  }

  return undefined;
};
