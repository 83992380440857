import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Editor, Range, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { trackEvent } from '@float/common/lib/gtm';
import { useRecentMentions } from '@float/common/lib/useRecentMentions';
import { KEYS } from '@float/common/search/helpers';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';

import { insertMention } from '../commands/insert-mention';
import { getMentionUnderCaret } from '../plugins/utils/get-mention-under-caret';

const NAV_KEYS = [KEYS.down, KEYS.up, KEYS.escape];

export const useMentions = <T extends { account_id: string }>(
  searchFor: (data: T[], field: string, search: string) => T[],
  enabled: boolean,
  data: T[] = [],
  trigger: string,
  field: string,
  virtualListRef: MutableRefObject<any>,
  editor: ReactEditor,
) => {
  const [target, setTarget] = useState<Range | null>(null);
  const [search, setSearch] = useState('');
  const [index, setIndex] = useState(0);

  const [recentMentions, addRecentMention] = useRecentMentions<T>(
    data,
    trigger,
  );

  const results = useMemo(() => {
    if (search === '' && recentMentions && recentMentions.length > 0) {
      return recentMentions;
    }

    if (searchFor) {
      return searchFor(data, field, search);
    }

    return data;
  }, [searchFor, data, field, search, recentMentions]);

  const handleChange = useCallback(() => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection);

      const [mention, mentionAnchorOffset, beforeRange] = getMentionUnderCaret(
        trigger,
        Editor.string(editor, start.path),
        selection,
      );

      if (mentionAnchorOffset > 0) {
        setTarget(beforeRange);
        setSearch(mention);
        setIndex(0);

        trackEvent('Hotkey', {
          shortcut: trigger,
        });

        return;
      }
    }

    setTarget(null);
  }, [editor, trigger]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (target && results.length > 0) {
        const { keyCode } = e;

        if (NAV_KEYS.includes(keyCode)) {
          prevent(e);
        }

        if (keyCode === KEYS.down) {
          setIndex(virtualListRef.current.next());
        }

        if (keyCode === KEYS.up) {
          setIndex(virtualListRef.current.previous());
        }

        if (keyCode === KEYS.tab || keyCode === KEYS.enter) {
          if (results[index]) {
            prevent(e);
            virtualListRef.current.select();
          }
        }

        if (keyCode === KEYS.escape) {
          setTarget(null);
        }
      }
    },
    [index, target, results, virtualListRef],
  );

  const handleSelection = useCallback(
    (item: unknown) => {
      if (item) {
        if (target) Transforms.select(editor, target);

        const { data } = insertMention(editor, item, trigger, field);

        if (addRecentMention) addRecentMention(data);
      }

      setTarget(null);

      if (!ReactEditor.isFocused(editor)) {
        setTimeout(() => {
          ReactEditor.focus(editor);
          Transforms.select(editor, Editor.end(editor, []));
        }, 0);
      }
    },
    [field, target, trigger, editor, addRecentMention],
  );

  useEffect(() => {
    if (!enabled) return;

    if (target && results.length > 0) {
      const { el } = virtualListRef.current;
      const domRange = ReactEditor.toDOMRange(editor, target);

      const rect = domRange.getBoundingClientRect();

      el.style.setProperty('--left', rect.left);
      el.style.setProperty('--top', rect.top + rect.height);
    }
  }, [results.length, index, search, target, virtualListRef, editor, enabled]);

  const values = useMemo(
    () => ({
      results,
      handleChange,
      handleKeyDown,
      handleSelection,
      target,
      index,
      enabled,
    }),
    [
      results,
      handleChange,
      handleKeyDown,
      handleSelection,
      target,
      index,
      enabled,
    ],
  );

  return values;
};
