import { useCallback, useState } from 'react';

import { useCallbackRef } from '../../../../libs/src/hooks/useCallbackRef';

export function useStateWithSubscribe<S>(
  initialState: S | (() => S),
  subscribeCallback: (state: S) => void,
): [S, (state: S) => void] {
  const subscribeCallbackRef = useCallbackRef(subscribeCallback);

  const [state, setState] = useState(initialState);

  const setStateWithSubscribe = useCallback(
    (value: S) => {
      setState(value);
      subscribeCallbackRef(value);
    },
    [subscribeCallbackRef],
  );

  return [state, setStateWithSubscribe];
}
