import { createSelector } from 'reselect';

import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { getSearchFilteredPeopleIds } from '@float/common/search/selectors/people';
import { selectDatesManager } from '@float/common/selectors/currentUser';
import { getFullTimeoffsList } from '@float/common/selectors/timeoffs';
import { Timeoff } from '@float/types/timeoff';

import { isRepeatableEntityWithinTheTimeRange } from '../helpers/isRepeatableEntityWithinTheTimeRange';
import { TimeRangeInsightsParams } from '../types';

export const getTimeRangeTimeoffsByPerson = createSelector(
  [
    getFullTimeoffsList,
    getSearchFilteredPeopleIds,
    selectDatesManager,
    (_: ReduxStateStrict, params: Omit<TimeRangeInsightsParams, 'type'>) =>
      params,
  ],
  (timeoffs, peopleIds, dates, params) => {
    const result = new Map<number, Timeoff[]>();

    for (const timeoff of timeoffs) {
      if (!isRepeatableEntityWithinTheTimeRange(timeoff, dates, params)) {
        continue;
      }

      for (const peopleId of timeoff.people_ids) {
        if (peopleIds.has(peopleId)) {
          const list = result.get(peopleId);

          if (list) {
            list.push(timeoff);
          } else {
            result.set(peopleId, [timeoff]);
          }
        }
      }
    }

    return result;
  },
);
