import { hexToHsl } from './hexToHsl';

export const hexToHslCss = (color: string | undefined) => {
  if (color) {
    const hsl = hexToHsl(color);

    if (hsl) {
      hsl[0] = Math.round(360 * hsl[0]);
      hsl[1] = Math.round(hsl[1] * 100);
      hsl[2] = Math.round(hsl[2] * 100);

      return `${hsl[0]}deg ${hsl[1]}% ${hsl[2]}%`;
    }
  }

  return undefined;
};
