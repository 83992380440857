import { getJWTAccessToken } from '@float/common/actions/jwt';

import api from '../api';
import {
  CLEAR_ASANA_PRELOAD,
  FETCH_ASANA_CO_INTS,
  FETCH_ASANA_CO_INTS_FAILURE,
  FETCH_ASANA_CO_INTS_SUCCESS,
  FETCH_ASANA_CONFIG,
  FETCH_ASANA_CONFIG_FAILURE,
  FETCH_ASANA_CONFIG_SUCCESS,
  FETCH_ASANA_OAUTH_LINK,
  FETCH_ASANA_OAUTH_LINK_FAILURE,
  FETCH_ASANA_OAUTH_LINK_SUCCESS,
  FETCH_ASANA_PRELOAD,
  FETCH_ASANA_PRELOAD_FAILURE,
  REMOVE_ASANA_CO_INT,
  REMOVE_ASANA_CO_INT_FAILURE,
  REMOVE_ASANA_CO_INT_SUCCESS,
  RETRY_ASANA_PEOPLE_SYNC,
  RETRY_ASANA_PEOPLE_SYNC_FAILURE,
  RETRY_ASANA_PEOPLE_SYNC_SUCCESS,
  UPDATE_ASANA_CONFIG,
  UPDATE_ASANA_CONFIG_FAILURE,
  UPDATE_ASANA_CONFIG_SUCCESS,
} from './asana.constants';
import { createFetchOAuthLink } from './common';

export * from './asana.constants';

const ASANA_TYPE = 'asana';

export const fetchAsanaOAuthLink = createFetchOAuthLink({
  types: [ASANA_TYPE],
  fetchType: FETCH_ASANA_OAUTH_LINK,
  successType: FETCH_ASANA_OAUTH_LINK_SUCCESS,
  failureType: FETCH_ASANA_OAUTH_LINK_FAILURE,
});

export const fetchAsanaCoInts = () => {
  return async (dispatch, getState) => {
    try {
      const loadState = getState().integrations.asana.fetchAsanaCoIntsLoadState;
      if (loadState === 'LOADING') {
        return;
      }

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: FETCH_ASANA_CO_INTS });
      const coInts = await api(accessToken).requestCoInts({
        type: ASANA_TYPE,
      });

      dispatch({
        type: FETCH_ASANA_CO_INTS_SUCCESS,
        coInts,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: FETCH_ASANA_CO_INTS_FAILURE });
    }
  };
};

export const fetchAsanaPreload = (coIntId) => {
  return async (dispatch, getState) => {
    try {
      const loadState =
        getState().integrations.asana.fetchAsanaPreloadLoadState;
      if (loadState === 'LOADING') {
        return;
      }

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: FETCH_ASANA_PRELOAD });
      const body = { type: ASANA_TYPE };
      if (coIntId) {
        body.coIntId = coIntId;
      }
      const { success } = await api(accessToken).requestPreload(body);
      if (!success) {
        dispatch({ type: FETCH_ASANA_PRELOAD_FAILURE });
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: FETCH_ASANA_PRELOAD_FAILURE });
    }
  };
};

export const clearAsanaPreload = () => async (dispatch) =>
  dispatch({ type: CLEAR_ASANA_PRELOAD });

export const fetchAsanaConfig = (coIntId) => async (dispatch, getState) => {
  try {
    const loadState = getState().integrations.asana.fetchConfigLoadState;
    if (loadState === 'LOADING') {
      return;
    }

    const accessToken = await dispatch(getJWTAccessToken());

    dispatch({ type: FETCH_ASANA_CONFIG });
    const body = coIntId ? { coIntId } : {};
    const config = await api(accessToken).getCoIntConfig(body);
    dispatch({
      type: FETCH_ASANA_CONFIG_SUCCESS,
      config,
    });
  } catch (e) {
    console.error(e);
    dispatch({ type: FETCH_ASANA_CONFIG_FAILURE });
  }
};

export const updateAsanaConfig = (
  coInt,
  config,
  { startInitialImport, resync } = {},
) => {
  return async (dispatch, getState) => {
    try {
      const loadState = getState().integrations.asana.updateConfigLoadState;
      if (loadState === 'LOADING') {
        return;
      }

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: UPDATE_ASANA_CONFIG, startInitialImport });
      const updatedConfig = await api(accessToken).updateCoIntConfig({
        coIntId: coInt && coInt.integrations_co_id,
        config,
        startInitialImport,
        resync,
        type: ASANA_TYPE,
      });

      dispatch({
        type: UPDATE_ASANA_CONFIG_SUCCESS,
        config: updatedConfig,
        initialImport: startInitialImport,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: UPDATE_ASANA_CONFIG_FAILURE });
    }
  };
};

export const removeAsanaCoInt = (coIntId, archiveData = false) => {
  return async (dispatch, getState) => {
    try {
      const loadState = getState().integrations.asana.removeCoIntLoadState;
      if (loadState === 'LOADING') {
        return;
      }

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: REMOVE_ASANA_CO_INT });

      const { success } = await api(accessToken).removeCoInt({
        coIntId,
        archiveData,
      });

      if (!success) {
        throw Error('Remove asana company integration error on API request');
      }

      dispatch({ type: REMOVE_ASANA_CO_INT_SUCCESS });

      dispatch(fetchAsanaCoInts());
    } catch (e) {
      console.error(e);
      dispatch({ type: REMOVE_ASANA_CO_INT_FAILURE });
    }
  };
};

export const retryAsanaPeopleSync = (coIntId) => {
  return async (dispatch, getState) => {
    try {
      const loadState = getState().integrations.asana.retryPeopleSyncLoadState;
      if (loadState === 'LOADING') {
        return;
      }

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: RETRY_ASANA_PEOPLE_SYNC });

      const { success } = await api(accessToken).retryPeopleSync({ coIntId });

      if (!success) {
        throw Error('Retry People Sync error on API request');
      }

      dispatch({ type: RETRY_ASANA_PEOPLE_SYNC_SUCCESS });

      dispatch(fetchAsanaCoInts());
    } catch (e) {
      console.error(e);
      dispatch({ type: RETRY_ASANA_PEOPLE_SYNC_FAILURE });
    }
  };
};
