import { WorkHoursPerDayOfWeek } from '@float/types/account';
import {
  CompanyPreferences,
  CompanyPreferencesFromAPI,
  WorkDayHoursHistory,
} from '@float/types/companyPreferences';

export const formatCompanyPrefs = (
  state: CompanyPreferences,
  cPrefs: CompanyPreferencesFromAPI,
): CompanyPreferences => {
  let is_paid_plan = false;
  let start_work_week = 0;
  if (cPrefs) {
    is_paid_plan = cPrefs.pay_status !== 0;
    if (typeof cPrefs.start_work_week !== 'undefined') {
      start_work_week = +cPrefs.start_work_week;
    }
  }

  let company_name = (state.company_name || cPrefs.company_name) ?? '';

  // Ensure numeric company names are parsed as string
  company_name = String(company_name);

  let work_days_hours = null;

  if (cPrefs?.work_days_hours) {
    work_days_hours = cPrefs.work_days_hours;

    if (typeof work_days_hours === 'string') {
      work_days_hours = JSON.parse(work_days_hours);
    }

    work_days_hours = work_days_hours.map(Number) as WorkHoursPerDayOfWeek;
  }

  let plusPack: 0 | 1 = 0;
  if (
    typeof document !== 'undefined' &&
    typeof document.location !== 'undefined'
  ) {
    const params = new URL(document.location.href).searchParams;

    const plusPackParam = params.get('plusPack');

    if (plusPackParam !== null) {
      plusPack = plusPackParam === '1' ? 1 : 0;
    } else {
      plusPack = cPrefs.plus_pack;
    }
  }

  const res = {
    ...state,
    ...cPrefs,
    company_name,
    start_work_week,
    timeoff_approvals: !!+cPrefs.timeoff_approvals,
    is_paid_plan,
    domains:
      typeof cPrefs.domains === 'string' ? cPrefs.domains.split(',') : [],
    plus_pack: plusPack, // -2 (Trial Expired), -1 (Disabled), 0 (Not enabled), 1 (Enabled) , 2 (Trial)
    people_count: Number(cPrefs.people_count),
    p_limit: Number(cPrefs.p_limit),
    work_days_hours,
  };

  if (
    res.work_days_hours_history &&
    !Array.isArray(res.work_days_hours_history)
  ) {
    res.work_days_hours_history = convertWorkDaysHoursHistory(
      res.work_days_hours_history,
    );
  }

  return res;
};

export function convertWorkDaysHoursHistory(history: {
  [k: string]: number[];
}): WorkDayHoursHistory {
  if (history && !Array.isArray(history)) {
    return Object.keys(history)
      .sort()
      .reverse()
      .map((k) => [k, history[k].map(Number)]) as WorkDayHoursHistory;
  }

  return history;
}
