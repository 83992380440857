import styled from 'styled-components';

export const LineChartContainer = styled.div`
  margin: 10px auto -11px;
  height: 304px;

  & > svg {
    overflow: visible;
    margin-bottom: 10px;
  }

  g.tick {
    line {
      display: none;
    }

    text {
      font-family: 'urw-din', 'Helvetica Neue', 'Helvetica', sans-serif;
      font-size: 14px;
      color: ${(p) => p.theme.blueGrey};

      &.today-label {
        font-weight: bold;
        color: ${(p) => p.theme.charcoalGrey};
      }
    }
  }

  text.reference-label {
    font-size: 11px;
    font-weight: 600;
    fill: white;
  }

  .grid g.tick line {
    display: initial;
    stroke: black;
    stroke-opacity: 0.12;
    shape-rendering: crispEdges;
  }

  .grid path {
    stroke-width: 0;
  }

  .data-circle,
  .data-line-vertical {
    opacity: 0;
    pointer-events: none;
  }

  rect.overlay {
    opacity: 0;
  }

  rect,
  circle {
    outline: none;
  }

  .marker {
    text-align: center;
    overflow: visible;
  }

  .marker-content {
    outline: none;
  }
`;
