import React from 'react';

export default function ({ className }: { className?: string }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.8335 7.83335C3.8335 7.46516 4.13197 7.16669 4.50016 7.16669H11.5002C11.8684 7.16669 12.1668 7.46516 12.1668 7.83335V11.5C12.1668 12.2364 11.5699 12.8334 10.8335 12.8334H5.16683C4.43045 12.8334 3.8335 12.2364 3.8335 11.5V7.83335Z"
        stroke="#344765"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.16648 7.00002V6.56231C5.16648 5.74331 5.13377 4.8659 5.61592 4.20387C5.9989 3.67802 6.70436 3.16669 7.99982 3.16669C9.33315 3.16669 10.1665 4.16669 10.1665 4.16669"
        stroke="#344765"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
