import React from 'react';

export default function () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#979797"
          fillRule="nonzero"
          d="M12 6c1.054 0 1.918.816 1.995 1.85L14 8v1.5h-1.5V8c0-.245-.177-.45-.41-.492L12 7.5H6c-.245 0-.45.177-.492.41L5.5 8v8c0 .245.177.45.41.492L6 16.5h6c.245 0 .45-.177.492-.41L12.5 16v-1.5H14V16c0 1.054-.816 1.918-1.85 1.995L12 18H6c-1.054 0-1.918-.816-1.995-1.85L4 16V8c0-1.054.816-1.918 1.85-1.995L6 6h6z"
        />
        <path
          stroke="#979797"
          strokeWidth="1.5"
          d="M17.5 8.5L21 12 17.5 15.5"
        />
        <path
          stroke="#979797"
          strokeLinecap="square"
          strokeWidth="1.5"
          d="M11 12L20 12"
        />
      </g>
    </svg>
  );
}
