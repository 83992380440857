import { moment } from '@float/libs/moment';
import {
  endOf,
  getLastMonth,
  getLastQuarter,
  getLastWeek,
  getThisMonth,
  getThisQuarter,
  getThisWeek,
  prevRangeEnd,
  prevRangeStart,
  startOf,
} from '@float/libs/timeRange';

const endOf12Weeks = () =>
  moment().startOf('week').add(12, 'weeks').subtract(1, 'day');

export const rangeOpts = [
  {
    label: `Next 12 weeks`,
    value: '0',
    start: startOf('week'),
    end: endOf12Weeks,
    type: 'week',
    range: [11, 'weeks'],
  },
  getThisWeek(),
  getThisMonth(),
  getThisQuarter(),
  {
    label: `This year`,
    value: '4',
    start: startOf('year'),
    end: endOf('year'),
    type: 'year',
    range: [1, 'year'],
  },
  getLastWeek(),
  getLastMonth(),
  getLastQuarter(),
  {
    label: `Last year`,
    value: '8',
    start: prevRangeStart,
    end: prevRangeEnd,
    type: 'year',
    range: [1, 'year'],
  },
];
