import React from 'react';

import BaseIcon, { BaseIconProps } from './BaseIcon';

const IconHolder = (props: BaseIconProps) => {
  return (
    <BaseIcon {...props} iconNoScalingStroke>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M11.266 5.541a6.501 6.501 0 1 0 7.193 7.193c-2.306-.094-4.101-.628-5.333-1.86-1.232-1.232-1.766-3.026-1.86-5.333Zm1.6 1.135 4.458 4.459c-1.46-.197-2.459-.643-3.137-1.322-.68-.678-1.125-1.677-1.322-3.137ZM4 12a8 8 0 0 1 8-8 .75.75 0 0 1 .53.22l7.25 7.25c.141.14.22.331.22.53a8 8 0 1 1-16 0Z"
        clipRule="evenodd"
      />
    </BaseIcon>
  );
};

export { IconHolder };
