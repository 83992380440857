import { normalize } from '@float/common/search/helpers';

const startsWith = (val, str) => {
  const str1 = normalize(val);
  const str2 = normalize(str);

  return str1.substr(0, str2.length).toUpperCase() == str2.toUpperCase();
};

/**
 * Filter object based on name prop
 *
 * @param {Array|Objet} obj source object
 * @param {String} field field name
 * @param {String} val value to search
 */

function filter(obj, field, val) {
  return obj.filter((o) => {
    const words = o[field]?.split(' ') || [];

    const matchesValue = startsWith(o[field], val);
    const matchesAnyWord = words.some((word) => startsWith(word, val));

    const found = matchesValue || matchesAnyWord;

    return found;
  });
}

export { filter };
