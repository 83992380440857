import { useState } from 'react';

import { noop } from '@float/libs/utils/noop';

/**
 * Generic modal visible state controls
 *
 * @example
 *
 * const {isModalVisible, closeModal, openModal} = useModalControls();
 *
 * return (
 *      <Button onClick={openModal}/>
 *      {isModalVisible && <Modal onClose={closeModal} />}
 * )
 */
type ModalControlsConfig = {
  isInitiallyVisible?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
};

export const useModalControls = (config: ModalControlsConfig = {}) => {
  const { isInitiallyVisible = false, onClose = noop, onOpen = noop } = config;
  const [isModalVisible, setIsModalVisible] = useState(isInitiallyVisible);

  const closeModal = () => {
    setIsModalVisible(false);
    onClose();
  };

  const openModal = () => {
    setIsModalVisible(true);
    onOpen();
  };

  return { isModalVisible, closeModal, openModal };
};
