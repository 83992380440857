import { MODES } from '@float/ui/deprecated/Chart';

import * as phasesTable from './table/phases';
import * as tasksTable from './table/tasks';
import * as teamTable from './table/team';
import getTimetrackingTable from './table/timetracking';

function getTasksTable(ctx, raw) {
  const { mode } = ctx;

  if (mode === MODES.SCHEDULED) return tasksTable.getScheduledTable(ctx, raw);
  if (mode === MODES.LOGGED) return tasksTable.getLoggedTable(ctx, raw);
  if (mode === MODES.COMPARE) return tasksTable.getCompareTable(ctx, raw);
  if (mode === MODES.COMBINED) return tasksTable.getCombinedTable(ctx, raw);
}

function getTeamTable(ctx, raw) {
  const { mode } = ctx;

  if (mode === MODES.SCHEDULED) return teamTable.getScheduledTable(ctx, raw);
  if (mode === MODES.LOGGED) return teamTable.getLoggedTable(ctx, raw);
  if (mode === MODES.COMPARE) return teamTable.getCompareTable(ctx, raw);
  if (mode === MODES.COMBINED) return teamTable.getCombinedTable(ctx, raw);
}

function getPhasesTable(ctx, raw) {
  const { mode } = ctx;

  if (mode === MODES.SCHEDULED) return phasesTable.getScheduledTable(ctx, raw);
  if (mode === MODES.LOGGED) return phasesTable.getLoggedTable(ctx, raw);
  if (mode === MODES.COMPARE) return phasesTable.getCompareTable(ctx, raw);
  if (mode === MODES.COMBINED) return phasesTable.getCombinedTable(ctx, raw);
}

export default function parseTableData(ctx, raw) {
  return {
    taskBillable: getTasksTable({ ...ctx, billable: true }, raw),
    taskNonBillable: getTasksTable({ ...ctx, billable: false }, raw),
    team: getTeamTable(ctx, raw),
    timetracking: getTimetrackingTable(ctx, raw),
    phaseBillable: getPhasesTable({ ...ctx, billable: true }, raw),
    phaseNonBillable: getPhasesTable({ ...ctx, billable: false }, raw),
  };
}
