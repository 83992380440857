import React from 'react';
import { Trans } from '@lingui/macro';

import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { TextButton } from '@float/ui/deprecated/Earhart/Buttons';

import {
  formatNextChargeDate,
  getBillingCycleTypeCopy,
  getLastFourDigitsOfCreditCard,
} from './BillingDetails.helpers';
import {
  StyledDetailsPrimaryContent,
  StyledDetailsSecondaryContent,
  StyledDetailsSection,
  StyledDetailsWrapper,
  StyledPaymentDetailsPrimaryContent,
} from './styles';

export const PaidBillingDetails = ({
  canEditBillingCycle,
  isPlanBilledMonthly,
  maskedCardNumber,
  nextChargeDate,
  onClickEditBillingCycle,
  onClickEditSeats,
  totalPayment,
  totalSeats,
  usedSeats,
  placeholderSeats,
  placeholderUsedSeats,
  upgradePath,
}: {
  canEditBillingCycle: boolean;
  isPlanBilledMonthly: boolean;
  maskedCardNumber: string;
  nextChargeDate: string;
  onClickEditBillingCycle: () => void;
  onClickEditSeats: () => void;
  totalPayment: string;
  totalSeats: number;
  usedSeats: number;
  placeholderSeats: number;
  placeholderUsedSeats: number;
  upgradePath: string;
}) => (
  <>
    <StyledDetailsWrapper>
      <StyledDetailsSection>
        <h5>Using / Paid seats</h5>
        <StyledDetailsPrimaryContent>
          {usedSeats}/{totalSeats}
        </StyledDetailsPrimaryContent>
        <StyledDetailsSecondaryContent>
          Schedule up to <strong>{totalSeats} people</strong>
          <TextButton appearance="flue-fill" onClick={onClickEditSeats}>
            Edit
          </TextButton>
        </StyledDetailsSecondaryContent>
      </StyledDetailsSection>
      {featureFlags.isFeatureEnabled(FeatureFlag.PlaceholdersPackaging) && (
        <StyledDetailsSection>
          <h5>
            <Trans>Placeholders</Trans>
          </h5>
          <StyledDetailsPrimaryContent>
            {placeholderUsedSeats}/{placeholderSeats}
          </StyledDetailsPrimaryContent>
          <StyledDetailsSecondaryContent>
            <Trans>Extras are billed as seats</Trans>
          </StyledDetailsSecondaryContent>
        </StyledDetailsSection>
      )}
      <StyledDetailsSection>
        <h5>Total</h5>
        <StyledDetailsPrimaryContent>
          <sup>$ </sup>
          {totalPayment}
          <sub> USD</sub>
        </StyledDetailsPrimaryContent>
        <StyledDetailsSecondaryContent>
          <strong>{getBillingCycleTypeCopy(isPlanBilledMonthly)}</strong>{' '}
          {canEditBillingCycle && (
            <TextButton
              appearance="flue-fill"
              onClick={onClickEditBillingCycle}
            >
              Edit
            </TextButton>
          )}
        </StyledDetailsSecondaryContent>
      </StyledDetailsSection>
    </StyledDetailsWrapper>
    <StyledDetailsWrapper>
      <StyledDetailsSection>
        <h5>Payment details</h5>
        {maskedCardNumber && (
          <StyledPaymentDetailsPrimaryContent>
            <sub>Card ending in</sub>{' '}
            {getLastFourDigitsOfCreditCard(maskedCardNumber)}
          </StyledPaymentDetailsPrimaryContent>
        )}
        {nextChargeDate && (
          <StyledDetailsSecondaryContent>
            Next charge <strong>{formatNextChargeDate(nextChargeDate)}</strong>{' '}
            <TextButton as="a" appearance="flue-fill" href={upgradePath}>
              Edit
            </TextButton>
          </StyledDetailsSecondaryContent>
        )}
      </StyledDetailsSection>
    </StyledDetailsWrapper>
  </>
);
