import React, { ContextType, createElement, ReactNode } from 'react';

import {
  ColorThemeContext,
  ColorThemeContextProvider,
} from './ColorThemeContext';
import { PrintContext, PrintContextProvider } from './PrintContext';

type ContextShapes = {
  printContext: ContextType<typeof PrintContext>;
  themeContext: ContextType<typeof ColorThemeContext>;
};

type Overrides = {
  [Key in keyof ContextShapes]?: Partial<ContextShapes[Key]>;
};

type ProviderConfig<K extends keyof ContextShapes = keyof ContextShapes> = [
  K,
  React.FC<{
    children?: ReactNode;
    overrides?: Overrides[K];
  }>,
];

// Note that the providers at the bottom will be highest in the tree
const ALL_PROVIDERS = [
  ['printContext', PrintContextProvider],
  ['colorThemeContext', ColorThemeContextProvider],
];

export const UIContextProviders: React.FC<{
  children: ReactNode;
  overrides?: Overrides;
}> = ({ children, overrides = {} }) => {
  let allContextsApplied = children;

  for (const [
    contextName,
    contextProvider,
  ] of ALL_PROVIDERS as ProviderConfig[]) {
    const contextOverrides = overrides[contextName];

    allContextsApplied = createElement(
      contextProvider,
      { overrides: contextOverrides },
      allContextsApplied,
    );
  }

  return allContextsApplied;
};
