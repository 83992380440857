import { useCallback, useEffect, useState } from 'react';
import { t } from '@lingui/macro';

import { multiplyOperation } from '@float/libs/utils/floats';
import { TimeString } from '@float/types/datesManager';
import { AllocationType } from '@float/types/task';

import { getIsSpecificTimeSet } from '../EditTaskModal.helpers';
import { AllocationTimeSectionPayload } from '../EditTaskModal.types';

export const DEFAULT_START_TIME = '10:00';

export type UseAllocationTimeToggleParams = {
  allocationType: AllocationType | null;
  hoursPerDay: number;
  startTime: TimeString | null;
  numberOfAllocationDays: number;
  numberOfWorkingHours: number;
  peopleIds: number[];
  isFixedEndDate: boolean;
  onChange: (payload: AllocationTimeSectionPayload) => void;
};

export enum AllocationTypeOptionValue {
  HOURS_PER_DAY = 'hoursPerDay',
  PERCENTAGE = 'percentage',
  SPECIFIC_TIME = 'specificTime',
}

export type AllocationTypeOption = {
  value: AllocationTypeOptionValue;
  label: string;
};

export const useAllocationTypeControls = ({
  allocationType,
  startTime,
  hoursPerDay,
  numberOfAllocationDays,
  numberOfWorkingHours,
  peopleIds,
  isFixedEndDate,
  onChange,
}: UseAllocationTimeToggleParams) => {
  // We need to preserve this value in state for switching from Hours/day to Specific time
  // because specific time for new task comes by default as one hour starting from 10:00
  // meanwhile the default value for hours per day is the minumum working
  // hours per day for the selected time range
  const [hoursPerDayInitialValue] = useState(() => hoursPerDay);

  const [
    allocationTypeOptionSelectedValue,
    setAllocationTypeOptionSelectedValue,
  ] = useState(() => {
    if (allocationType === AllocationType.Percentage)
      return AllocationTypeOptionValue.PERCENTAGE;

    if (startTime) return AllocationTypeOptionValue.SPECIFIC_TIME;

    return AllocationTypeOptionValue.HOURS_PER_DAY;
  });

  const isMoreThanOnePersonSelected = peopleIds.length > 1;

  const allocationTypeOptions: AllocationTypeOption[] = [
    {
      value: AllocationTypeOptionValue.HOURS_PER_DAY,
      label: t`Hours/day`,
    },
    {
      value: AllocationTypeOptionValue.PERCENTAGE,
      label: t`Percentage`,
    },
    {
      value: AllocationTypeOptionValue.SPECIFIC_TIME,
      label: t`Specific Time`,
    },
  ].filter((option) => {
    if (
      isMoreThanOnePersonSelected &&
      option.value === AllocationTypeOptionValue.PERCENTAGE
    )
      return false;

    return true;
  });

  const isAllocationByHours =
    allocationTypeOptionSelectedValue ===
    AllocationTypeOptionValue.HOURS_PER_DAY;

  const isAllocationByTime =
    allocationTypeOptionSelectedValue ===
    AllocationTypeOptionValue.SPECIFIC_TIME;

  const isAllocationByPercentage =
    allocationTypeOptionSelectedValue === AllocationTypeOptionValue.PERCENTAGE;

  const selectHoursPerDay = useCallback(() => {
    onChange({
      allocationType: isFixedEndDate
        ? AllocationType.HoursWithFixedDuration
        : AllocationType.Hours,
      hoursPerDay: hoursPerDayInitialValue,
      hoursTotal: multiplyOperation(
        hoursPerDayInitialValue,
        numberOfAllocationDays,
      ),
      startTime: null,
    });

    setAllocationTypeOptionSelectedValue(
      AllocationTypeOptionValue.HOURS_PER_DAY,
    );
  }, [
    onChange,
    isFixedEndDate,
    numberOfAllocationDays,
    hoursPerDayInitialValue,
  ]);

  const selectPercentage = useCallback(() => {
    const hoursPerDayValue = numberOfWorkingHours / numberOfAllocationDays;
    const hoursTotalValue = numberOfWorkingHours;

    onChange({
      allocationType: AllocationType.Percentage,
      hoursPerDay: hoursPerDayValue,
      hoursTotal: hoursTotalValue,
      startTime: null,
    });

    setAllocationTypeOptionSelectedValue(AllocationTypeOptionValue.PERCENTAGE);
  }, [onChange, numberOfWorkingHours, numberOfAllocationDays]);

  const selectSpecificTime = useCallback(() => {
    const hoursPerDayValue = 1;
    const hoursTotalValue = numberOfAllocationDays * hoursPerDayValue;
    const startTimeValue = getIsSpecificTimeSet(startTime)
      ? startTime
      : DEFAULT_START_TIME;

    onChange({
      allocationType: AllocationType.Hours,
      hoursPerDay: hoursPerDayValue,
      hoursTotal: hoursTotalValue,
      startTime: startTimeValue,
    });

    setAllocationTypeOptionSelectedValue(
      AllocationTypeOptionValue.SPECIFIC_TIME,
    );
  }, [onChange, numberOfAllocationDays, startTime]);

  // When people selection is changed and there are more than one person selected
  // we need to select hours per day option and disallow percentage option
  useEffect(() => {
    if (
      isMoreThanOnePersonSelected &&
      allocationTypeOptionSelectedValue === AllocationTypeOptionValue.PERCENTAGE
    ) {
      selectHoursPerDay();
    }
  }, [
    isMoreThanOnePersonSelected,
    allocationTypeOptionSelectedValue,
    selectHoursPerDay,
  ]);

  const handleAllocationTypeChange = ({ value }: AllocationTypeOption) => {
    // Emit the changes
    if (value === AllocationTypeOptionValue.HOURS_PER_DAY) {
      selectHoursPerDay();
    } else if (value === AllocationTypeOptionValue.PERCENTAGE) {
      selectPercentage();
    } else if (value === AllocationTypeOptionValue.SPECIFIC_TIME) {
      selectSpecificTime();
    }
  };

  return {
    isAllocationByTime,
    isAllocationByHours,
    isAllocationByPercentage,
    allocationTypeOptions,
    allocationTypeOptionSelectedValue,
    handleAllocationTypeChange,
    selectHoursPerDay,
    selectPercentage,
    selectSpecificTime,
  };
};
