import { useEffect } from 'react';

import { ensureHolidaysLoaded } from '@float/common/actions/holidays';
import { ensureMilestonesLoaded } from '@float/common/actions/milestones';
import { fetchTimeoffsWithDates } from '@float/common/actions/timeoffs';
import { useAppDispatchStrict } from '@float/common/store';

export const useEnsureReportContextLoaded = (
  startDate: string | Date,
  endDate: string | Date,
) => {
  const dispatch = useAppDispatchStrict();

  useEffect(() => {
    dispatch(ensureHolidaysLoaded());
    dispatch(fetchTimeoffsWithDates(startDate, endDate));
    dispatch(ensureMilestonesLoaded());
  }, [dispatch, startDate, endDate]);
};
