import React, { CSSProperties, ReactNode } from 'react';

import { stopPropagation } from '@float/libs/utils/events/stopPropagation';

import * as styled from './styles';

type HoverLinkProps = {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  primary?: boolean;
  style?: CSSProperties;
  to?: Location | string;
};

const HoverLink: React.FC<HoverLinkProps> = ({
  children,
  to,
  className,
  disabled = false,
  primary = false,
}) => {
  if (!to) {
    return <>{children}</>;
  }

  return (
    <styled.HoverLink
      className={className || ''}
      to={to}
      onClick={stopPropagation}
      disabled={disabled}
      $primary={primary}
    >
      {children}
    </styled.HoverLink>
  );
};

export default HoverLink;
