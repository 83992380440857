import { css } from 'styled-components';

import colors from './colors';
import typography from './typography';

export { default as GlobalStyles } from './GlobalStyles/GlobalStyles';

const ie11MinHeightFix = css`
  @media all and (-ms-high-contrast: none) {
    height: 1px;
  }
`;

/**
 * @deprecated - Use `@float/theme` instead, where possible
 */
export default {
  ...colors,
  ...typography,
  ie11MinHeightFix,
};
