import styled from 'styled-components';

import EH from '@float/ui/deprecated/Earhart';
import { StyledTextButton } from '@float/ui/deprecated/Earhart/Buttons/TextButton/styles';
import InvoicesTable from '@float/web/settingsV2/containers/Billing/InvoicesTable';

export const StyledRow = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : 'column')};

  color: ${EH.Colors.FIN.Lt.Emphasis.High};

  & + & {
    margin-top: 20px;
  }

  h4 {
    ${EH.Typography.TextXXL.SB600};
  }
`;

export const StyledPlanDetails = styled.dl`
  flex: 1;

  display: flex;
  flex-direction: row;

  .flt-account-settings__plan-details--loading {
    position: relative;
  }

  .popup-confirm {
    color: #868d92;
  }

  .flt-account-settings__plan-details__group {
    padding: 5px 0;
  }

  .flt-account-settings__plan-details__label {
    color: #14d4b7;
    margin-right: 20px;
  }

  .flt-account-settings__plan-details__cc-icon {
    display: inline-block;
    float: none !important;
    vertical-align: bottom;
  }
`;

export const StyledPlanDetailsColumn = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;

  &:first-child {
    min-width: 40%;
  }

  &:last-child {
    flex: 0.5;

    dd {
      white-space: nowrap;
    }
  }

  dt {
    ${EH.Typography.Label14.SB600};

    color: ${EH.Colors.FIN.Lt.Emphasis.Medium};
  }

  dd {
    display: inline-block;

    margin-top: 10px;

    > * + * {
      margin-top: 6px;
    }
  }
`;

export const StyledValue = styled.div`
  display: flex;
  align-items: flex-start;

  min-height: 25px;

  > span {
    ${EH.Typography.Label14.M500};

    line-height: 22px;

    color: ${EH.Colors.FIN.Lt.Emphasis.Primary};
  }

  h5 {
    ${EH.Typography.DisplayXS.M500};

    line-height: 0.72;

    color: ${EH.Colors.FIN.Lt.Emphasis.Primary};

    span {
      color: ${EH.Colors.FIN.Lt.Emphasis.Medium};
    }

    & + * {
      margin-left: 5px;
    }
  }

  h6 {
    ${EH.Typography.TextXXL.M500};

    line-height: 0.8;

    color: ${EH.Colors.FIN.Lt.Emphasis.High};
  }
`;

export const StyledInfo = styled.p`
  ${EH.Typography.Label12.R400}

  color: ${EH.Colors.FIN.Lt.Emphasis.Medium};

  strong {
    ${EH.Typography.Label12.M500};
  }

  ${StyledTextButton} {
    margin: -2px -3px -2px -6px;
  }
`;

export const StyledInvoicesTable = styled(InvoicesTable)`
  th {
    &:last-child {
      width: 7%;
      text-align: center;
    }
  }

  td {
    &:last-child {
      text-align: center;

      a {
        &:hover {
          svg {
            --svg-icon-color: ${EH.Colors.FIN.Lt.Emphasis.Primary};
          }
        }

        svg {
          --svg-icon-color: ${EH.Colors.FIN.Lt.Emphasis.Medium};

          transition-property: fill, stroke;
          transition-duration: ${EH.Animation.Settings.Duration.Normal};
          transition-timing-function: ${EH.Animation.Settings.Easing
            .InOutCurve};
        }
      }
    }
  }
`;

export const PlusPackCheckboxLabel = styled.span`
  color: #363d46;
  font-size: 18px;
`;
