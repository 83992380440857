import { zipObject } from 'lodash';

import { getJWTAccessToken } from '@float/common/actions/jwt';
import {
  PROJECTS_LOAD_SYNC_ICON_FAILED,
  PROJECTS_LOAD_SYNC_ICONS,
  PROJECTS_SET_SYNC_ICONS,
} from '@float/common/actions/projects';
import { ReduxState } from '@float/common/reducers/lib/types';
import { AppDispatch } from '@float/common/store';
import { IntegrationName } from '@float/web/settingsV2/components/Integrations/types';

import api from '../api';

export const createFetchOAuthLink =
  ({
    types,
    fetchType,
    successType,
    failureType,
  }: {
    types: IntegrationName[];
    fetchType: string;
    successType: string;
    failureType: string;
  }) =>
  () => {
    return async (dispatch: AppDispatch, getState: () => ReduxState) => {
      try {
        const loadState = getState().integrations.calendar.oauthLinkLoadState;
        if (loadState === 'LOADING') {
          return;
        }

        const accessToken = await dispatch(getJWTAccessToken());

        dispatch({ type: fetchType });
        const resps: Array<{ url: string }> = await Promise.all(
          types.map((type) => api(accessToken).requestOAuthLink({ type })),
        );

        type DispatchObjType = {
          type: typeof successType;
          oauthLinks?: ReturnType<typeof zipObject<string>>;
          oauthLink?: string;
        };

        const dispatchObj: DispatchObjType = {
          type: successType,
        };

        if (resps.length > 1) {
          dispatchObj.oauthLinks = zipObject(
            types,
            resps.map((resp) => resp.url),
          );
        } else {
          dispatchObj.oauthLink = resps[0].url;
        }
        dispatch(dispatchObj);
      } catch (e) {
        console.error(e);
        dispatch({ type: failureType });
      }
    };
  };

export const fetchIntegrationProjectMeta = (coIntId: number) => {
  return async (dispatch: AppDispatch, getState: () => ReduxState) => {
    const state = getState().projects.syncIcon;
    if (state && state[coIntId] && state[coIntId].loadState === 'LOADING') {
      return;
    }

    dispatch({ type: PROJECTS_LOAD_SYNC_ICONS, coIntId });
    try {
      const accessToken = await dispatch(getJWTAccessToken());
      const data = await api(accessToken).fetchIntegrationProjectMeta(coIntId);
      dispatch({ type: PROJECTS_SET_SYNC_ICONS, coIntId, data });
    } catch (e) {
      console.error(e);
      dispatch({ type: PROJECTS_LOAD_SYNC_ICON_FAILED, coIntId });
    }
  };
};
