export default function (attr, inputAttr, rangeValidator) {
  return function (e) {
    let value, submit;
    switch (e.key) {
      case 'Enter':
        // Use current value to check validation and submit
        value = this.state[attr].clone();
        submit = true;
        break;
      case 'ArrowLeft':
        value = this.state[attr].clone().subtract(1, 'day');
        break;
      case 'ArrowDown':
        value = this.state[attr].clone().add(1, 'week');
        break;
      case 'ArrowUp':
        value = this.state[attr].clone().subtract(1, 'week');
        break;
      case 'ArrowRight':
        value = this.state[attr].clone().add(1, 'day');
        break;
      case 'Tab':
        if (typeof this.props.onTabKey === 'function') {
          this.props.onTabKey(e);
        }
        submit = true;
        break;
      case 'Escape':
        this.hide();
        this.blurInput();
        e.stopPropagation();
        return;
    }
    if (value) {
      // Do not arrow to date that is out of bounds
      if (this.props.minimumDate && value.diff(this.props.minimumDate) < 0) {
        return;
      }
      // Do not arrow to date that is disabled
      if (this.props.disableDayFn && this.props.disableDayFn(value)) {
        return;
      }
      // Do not arrow to date that is in a locked period or access is denied
      if (this.hasLockPeriodAccess && !this.hasLockPeriodAccess(value)) {
        return;
      }
      // Don't allow range overlap if DateRangePicker
      if (rangeValidator && !rangeValidator(attr, value)) {
        return;
      }

      if (submit) {
        // Submit current range after validation
        if (this.onHidden) {
          this.onHidden();
        } else {
          this.hide();
        }
      } else {
        // Set new range changed by arrow keys
        this.setState({
          [attr]: value,
          [inputAttr]: this.format(value),
        });
      }
    }
  };
}
