import React, { MouseEvent } from 'react';

import { useActiveTimerDuration } from '@float/common/lib/hooks/timer/useActiveTimerDuration';
import { Timer } from '@float/types';

import { TimerButton } from './components/TimerButton';
import { TimerText } from './components/TimerText';
import { StyledLogTimeTimerControls } from './styles';

type LogTimeTimerControlsProps = {
  activeTimer: Timer | null;
  canStartTimer: boolean;
  loading?: boolean;
  onClickTimerButton?: (e: MouseEvent<HTMLButtonElement>) => void;
  timerEntriesCount?: number;
};

const LogTimeTimerControls = (props: LogTimeTimerControlsProps) => {
  const {
    activeTimer,
    canStartTimer,
    onClickTimerButton,
    timerEntriesCount = 0,
    loading,
  } = props;

  const time = useActiveTimerDuration(activeTimer);
  const disabled = !canStartTimer || loading;

  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (onClickTimerButton) onClickTimerButton(e);
  };

  return (
    <StyledLogTimeTimerControls
      hasActiveTimer={Boolean(activeTimer)}
      hasTimerEntries={timerEntriesCount > 0}
      $loading={loading}
    >
      <div>
        <TimerButton
          disabled={disabled}
          notAllowed={!canStartTimer}
          onClick={onClick}
          started={Boolean(activeTimer)}
        />
      </div>
      <div>
        <TimerText
          activeTimer={Boolean(activeTimer)}
          disabled={disabled}
          time={time}
          timerEntriesCount={timerEntriesCount}
        />
      </div>
    </StyledLogTimeTimerControls>
  );
};

export { LogTimeTimerControls };
