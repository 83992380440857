// Due to limitations on the hermes engine we can't polyfill
// Promise.withResolvers() so we are using this helper function
// to achieve the same feature
// see: https://github.com/facebook/hermes/issues/1009#issuecomment-1970168611
export function promiseWithResolvers<R>() {
  let resolve = (_: R) => {};
  let reject = (_: unknown) => {};

  const promise = new Promise<R>((_resolve, _reject) => {
    resolve = _resolve;
    reject = _reject;
  });

  return {
    promise,
    resolve,
    reject,
  };
}
