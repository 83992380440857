import { ButtonVariants } from '@float/ui/components/Button/types';

import { ModalConfirmAppearance, ModalConfirmProps } from '../types';

export const getButtonAppearance = (
  modalAppearance: ModalConfirmProps['appearance'],
): {
  primary: ButtonVariants['appearance'];
  secondary: ButtonVariants['appearance'];
} => {
  let primary: ButtonVariants['appearance'] = 'primary';
  const secondary: ButtonVariants['appearance'] = 'secondary-flay';

  switch (modalAppearance) {
    case ModalConfirmAppearance.Danger: {
      primary = 'critical-solid';
      break;
    }
    case ModalConfirmAppearance.SoftDanger: {
      primary = 'critical';
    }
  }

  return { primary, secondary };
};
