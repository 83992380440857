import { isDateInLockPeriod } from '../../../util/lockPeriod';
import { DateUtils, LockPeriodDates } from '../types';

function isInLockPeriod(
  x: number,
  lockPeriodDates: LockPeriodDates | undefined,
  dates: DateUtils,
  colIdx: number,
) {
  if (!lockPeriodDates || !lockPeriodDates.latest) {
    return false;
  }

  return isDateInLockPeriod(
    new Date(dates.fromDescriptor(colIdx, x)),
    new Date(lockPeriodDates.latest),
  );
}

export { isInLockPeriod };
