export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';

export const UPDATE_USER = 'UPDATE_USER';

export const USER_PREFS_UPDATE = 'userPrefs/UPDATE';
export const USER_PREFS_MULTI_UPDATE = 'userPrefs/MULTI_UPDATE';

export const CHANGE_ACCOUNT_OWNER = 'CHANGE_ACCOUNT_OWNER';
export const CHANGE_ACCOUNT_OWNER_SUCCESS = 'CHANGE_ACCOUNT_OWNER_SUCCESS';
export const CHANGE_ACCOUNT_OWNER_FAILURE = 'CHANGE_ACCOUNT_OWNER_FAILURE';

export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';

export const UPDATE_COMPANY_SSO_SUCCESS = 'UPDATE_COMPANY_SSO_SUCCESS';
export const FETCH_COMPANY_SSO_SUCCESS = 'FETCH_COMPANY_SSO_SUCCESS';

export const V3_PREFS = [
  'sked_sort_by',
  'sked_sort_dir',
  'sked_custom_sort',
  'sked_view_type',
  'sked_projview_sort_by',
  'sked_projview_sort_dir',
  'people_order',
  'projview_people_order',
  'custom_priority',
  'projview_people_custom_priority',
  'custom_sort_order',
  'custom_sort_type',
  'people_sort_by',
  'people_sort_dir',
  'people_view',
  'proj_sort_by',
  'proj_sort_dir',
  'proj_view',
  'sked_zoom',
  'sked_view',
  'me_filter',
  'project_from_scratch',
  'notif_my_tasks',
  'notif_all_tasks',
  'notif_timeoffs',
  'notif_timeoff_approvals',
  'logtime_email_active',
  'time_format_24h',
  'duration_display_format',
  'mobile_sked_view',
  'mobile_sked_zoom',
  'mobile_hide_non_wk_days',
  'mobile_suv',
  'manage_tab_shown',
  'manage_col_shown',
] as const;
