import React, { ReactNode } from 'react';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { RecipeVariants } from '@vanilla-extract/recipes';
import cn from 'classnames';

import { SVGIconComponent } from '@float/ui/primitives/SVGIcon';

import * as styles from './TagStatic.css';

type Props = {
  backgroundColor?: string;
  children?: ReactNode;
  className?: string;
  foregroundColor?: string;
  iconEnd?: SVGIconComponent;
  iconStart?: SVGIconComponent;
  size?: NonNullable<RecipeVariants<typeof styles.tagStatic>>['size'];
};

export const TagStatic = (props: Props) => {
  const {
    backgroundColor,
    children,
    className,
    foregroundColor,
    iconEnd,
    iconStart,
    size,
  } = props;

  return (
    <span
      style={assignInlineVars({
        [styles.backgroundColor]: backgroundColor,
        [styles.foregroundColor]: foregroundColor,
      })}
      className={cn(
        className,
        styles.tagStatic({
          size,
          iconStart: !!iconStart,
          iconEnd: !!iconEnd,
        }),
      )}
    >
      {iconStart && (
        <span className={styles.icon}>
          {React.createElement(iconStart, { className: styles.svgIcon })}
        </span>
      )}

      <span className={styles.text}>
        <span className={styles.overflow}>{children}</span>
      </span>

      {iconEnd && (
        <span className={styles.icon}>
          {React.createElement(iconEnd, { className: styles.svgIcon })}
        </span>
      )}
    </span>
  );
};
