import { sortByDateAndName } from '@float/common/lib/itemSort';
import { getProjectTaskMetas } from '@float/common/selectors/taskMetas';
import { AppStore } from '@float/common/store';
import { TaskMeta } from '@float/types/task';
import {
  getAllMilestonesByProjectId,
  getPhasesByProjectId,
  getProjectById,
} from '@float/web/selectors';

import { FormType, ProjectFormInitialData } from '../types';

/**
 * Retrieves project data with phase details such as phase milestones and tasks.
 * @param store - The application store.
 * @param ids - The project ID or template ID.
 * @returns The initial data for the project form.
 */
export function getProjectDataWithPhaseDetails(
  store: AppStore,
  projectId?: number,
): ProjectFormInitialData {
  const state = store.getState();

  const project = getProjectById(state, projectId);
  const phases = getPhasesByProjectId(state, projectId);
  const milestones = sortByDateAndName(
    getAllMilestonesByProjectId(state, projectId),
    'date',
    'name',
  );
  const tasks = getProjectTaskMetas(state, projectId);

  return {
    type: FormType.Project,
    project,
    phases,
    milestones,
    tasks: tasks as TaskMeta[],
  };
}
