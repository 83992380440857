export enum ProjectPercentageModes {
  SCHEDULED = 'scheduled',
  LOGGED = 'logged',
}

export enum ChartComparisonModes {
  COMBINED = 'combined',
  LOGGED = 'logged',
  SCHEDULED = 'scheduled',
  COMPARE = 'compare',
}
