const getEntryLabel = (timerEntriesCount: number) =>
  timerEntriesCount === 1 ? 'entry' : 'entries';

export const getTimerEntriesLabel = (timerEntriesCount: number) => {
  if (timerEntriesCount > 0) {
    const entryLabel = getEntryLabel(timerEntriesCount);

    return `${timerEntriesCount} ${entryLabel} logged`;
  }

  return 'No time entries';
};
