import { CHANGE_FILTER, CHANGE_SORT, TOGGLE_GROUP } from '../actions';

const MONITORED_ACTIONS = [CHANGE_SORT, CHANGE_FILTER, TOGGLE_GROUP];
const DEBOUNCE_TM = 1000;
const STORAGE_KEY = 'pmSidebar';

let currentPmSidebar;
let updatePending = false;

const persistState = () => {
  const { sortBy, sortOrder, filter, collapsedGroups } = currentPmSidebar;
  const persist = JSON.stringify({
    sortBy,
    sortOrder,
    filter,
    collapsedGroups,
  });
  localStorage.setItem(STORAGE_KEY, persist);
  updatePending = false;
};

export const getPersistedState = () => {
  const persisted = localStorage.getItem(STORAGE_KEY);
  if (persisted) {
    return JSON.parse(persisted);
  }
  return {};
};

const persistPmSidebarPrefs = (store) => (next) => async (action) => {
  if (!MONITORED_ACTIONS.includes(action.type)) {
    return next(action);
  }

  const res = await next(action);
  currentPmSidebar = store.getState().pmSidebar;

  if (!updatePending) {
    setTimeout(persistState, DEBOUNCE_TM);
  }

  return res;
};

export default persistPmSidebarPrefs;
