import React, {
  Component,
  CSSProperties,
  ForwardedRef,
  KeyboardEvent,
  MouseEvent,
  MutableRefObject,
  ReactNode,
} from 'react';
import { createPortal } from 'react-dom';
import { LayoutGroup, LayoutProps } from 'framer-motion';

import { stopPropagation } from '@float/libs/utils/events/stopPropagation';
import Hotkeys from '@float/ui/deprecated/Hotkeys/Hotkeys';

import { ModalCloseContext } from '../ModalClose/ModalCloseContext';
import {
  StyledModal,
  StyledModalBg,
  StyledModalDialog,
  StyledModalMotionWrapper,
  StyledModalScrollingContainer,
} from './styles';

export type ModalDialogProps = {
  isOpen: boolean;
  shouldCloseOnBgClick?: boolean;
  shouldCloseOnEsc?: boolean;
  width?: number;
  fitContent?: boolean;
  marginTop?: number;
  closeIconColor?: string;
  className?: string;
  id?: string;
  center?: boolean;
  centerOffsetX?: number;
  dialogClassName?: string;
  noBgTransition?: boolean;
  noAnimation?: boolean;
  innerRef?: ForwardedRef<HTMLDivElement | null>;
  focusFirstEl?: boolean;
  style?: CSSProperties;
  layoutAnimation?: LayoutProps['layout'];
  children: ReactNode;
  onClose?: () => void;
  onBgClick?: () => void;
  onEnter?: () => void;
};

export const MODAL_DEFAULT_WIDTH = 560;

// Matching side panel animation - 200ms easeInOutQuint.
// We can convert this to a prop in the future if it's
// required to be customizable based on the consumer.
const MODAL_OFFSET_TRANSITION = { duration: 0.2, ease: [0.86, 0, 0.07, 1] };

class Modal extends Component<ModalDialogProps> {
  static defaultProps = {
    isOpen: false,
    shouldCloseOnBgClick: true,
    shouldCloseOnEsc: true,
    width: MODAL_DEFAULT_WIDTH,
    fitContent: false,
    marginTop: 48,
    closeIconColor: 'white',
    center: false,
  };

  modal: MutableRefObject<HTMLDivElement | null>;
  firstFocusableEl: HTMLElement | null = null;
  lastFocusableEl: HTMLElement | null = null;

  constructor(props: ModalDialogProps) {
    super(props);
    this.modal = React.createRef();
  }

  componentDidMount() {
    // If the simplistic focus trapping doesn't cut it going forward, then explore ally.js
    // https://github.com/medialize/ally.js/blob/master/docs/tutorials/accessible-dialog.md
    if (!this.modal.current) return;
    const focusableEls = this.modal.current.querySelectorAll(
      'input:not([tabindex="-1"]), textarea:not([tabindex="-1"]), button:not([tabindex="-1"])',
    );
    if (!focusableEls || focusableEls.length) {
      this.firstFocusableEl = focusableEls[0] as HTMLElement;
      this.lastFocusableEl = focusableEls[
        focusableEls.length - 1
      ] as HTMLElement;
      if (this.props.focusFirstEl) {
        this.firstFocusableEl.focus();
      }
    }

    document.body.classList.add('modal-open');
  }

  componentWillUnmount() {
    this.firstFocusableEl = null;
    this.lastFocusableEl = null;

    // TODO: clean up
    //  This is a dirty hack for working around this issue - https://app.asana.com/0/1108154850414966/1113038644436298/f
    //  Needs to be cleaned up after figuring out the root cause for: "android soft keyboard hiding on mobile
    //  web when opening modals on schedule page which has a decent number of tasks / timeoffs"
    const modals = document.getElementsByClassName('float-ui-modal');
    if (!modals || modals.length < 2) {
      document.body.classList.remove('modal-open');
    }
  }

  onClose = () => {
    const { onClose } = this.props;
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  onKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    // tab
    if (e.keyCode === 9 && this.firstFocusableEl) {
      if (e.shiftKey) {
        if (document.activeElement === this.firstFocusableEl) {
          this.lastFocusableEl?.focus();
          e.preventDefault();
        }
        return;
      }

      if (document.activeElement === this.lastFocusableEl) {
        this.firstFocusableEl.focus();
        e.preventDefault();
      }
    }
  };

  mouseDownX: number = 0;
  mouseDownY: number = 0;

  onBgMouseDown = (e: MouseEvent<HTMLElement>) => {
    if (!this.props.shouldCloseOnBgClick) {
      return;
    }
    this.mouseDownX = e.screenX;
    this.mouseDownY = e.screenY;
  };

  onBgMouseUp = (e: MouseEvent<HTMLElement>) => {
    if (!this.props.shouldCloseOnBgClick) {
      return;
    }

    // do not close if user has dragged mouse (significantly)
    // e.g. use case: https://app.asana.com/0/1118169589435567/1118271554626826/f
    const xMovement = Math.abs(e.screenX - this.mouseDownX);
    const yMovement = Math.abs(e.screenY - this.mouseDownY);

    if (xMovement > 5 || yMovement > 5) {
      return;
    }

    // Exclude all the clicks not targeting the backgorund
    if (e.currentTarget !== e.target) return;

    if (typeof this.props.onBgClick === 'function') {
      this.props.onBgClick();
      return;
    }

    this.onClose();
  };

  ref = (el: HTMLDivElement) => {
    this.modal.current = el;

    if (this.props.innerRef) {
      if (typeof this.props.innerRef === 'function') {
        this.props.innerRef(el);
      } else {
        this.props.innerRef.current = el;
      }
    }
  };

  render() {
    if (!this.props.isOpen) {
      return null;
    }

    const keyMap: Record<string, () => void> = {};
    if (this.props.shouldCloseOnEsc) {
      keyMap.Escape = this.onClose;
    }
    if (this.props.onEnter) {
      keyMap.Enter = this.props.onEnter;
    }

    // We detect the layout animation mode using 'in' to avoid adding the motion
    // wrapper where we don't use it and let the user toggle the animations without
    // recreating the modal dom tree
    const layoutAnimationMode = 'layoutAnimation' in this.props;

    const children = layoutAnimationMode ? (
      <LayoutGroup>
        <StyledModalMotionWrapper layout={this.props.layoutAnimation}>
          {this.props.children}
        </StyledModalMotionWrapper>
      </LayoutGroup>
    ) : (
      this.props.children
    );

    const animate =
      typeof this.props.centerOffsetX === 'number'
        ? { translate: `${this.props.centerOffsetX}px` }
        : undefined;

    return createPortal(
      <ModalCloseContext.Provider value={this.onClose}>
        <StyledModal
          id={this.props.id}
          ref={this.ref}
          aria-modal="true"
          role="dialog"
          tabIndex={-1}
          aria-labelledby="modal-title"
          className={`float-ui-modal ${this.props.className || ''}`}
          onKeyDown={this.onKeyDown}
          onClick={stopPropagation}
        >
          <Hotkeys allowInput keyMap={keyMap} />
          <StyledModalBg noBgTransition={this.props.noBgTransition} />
          <StyledModalScrollingContainer
            center={this.props.center}
            onMouseDown={this.onBgMouseDown}
            onMouseUp={this.onBgMouseUp}
            data-testid="modal-scrolling-container"
          >
            <StyledModalDialog
              className={this.props.dialogClassName}
              fitContent={this.props.fitContent}
              width={this.props.width}
              marginTop={this.props.marginTop}
              noAnimation={this.props.noAnimation}
              style={this.props.style}
              noBackground={layoutAnimationMode}
              animate={animate}
              transition={MODAL_OFFSET_TRANSITION}
            >
              {children}
            </StyledModalDialog>
          </StyledModalScrollingContainer>
        </StyledModal>
      </ModalCloseContext.Provider>,
      document.body,
    );
  }
}

export default React.forwardRef<HTMLDivElement | null, ModalDialogProps>(
  (props, ref) => {
    return <Modal {...props} innerRef={ref} />;
  },
);
