import { hexToRgb } from './hexToRgb';
import { rgbToHsl } from './rgbToHsl';

/**
 * Converts an HEX color value to HSL.
 *
 * @param {string} color The HEX value (e.g., #fff, fff, FFFFFF)
 *
 * @returns {Array} The HSL representation
 */

export function hexToHsl(color: string) {
  const rgb = hexToRgb(color);

  return rgb && rgbToHsl(rgb);
}
