import React from 'react';

import { CurrentUser } from '@float/types';
import IconLock from '@float/ui/deprecated/Earhart/Icons/Icon/IconLock';
import Bell3 from '@float/ui/deprecated/Icons/icon-bell3';
import Bell3Reject from '@float/ui/deprecated/Icons/icon-bell3-reject';
import Link from '@float/ui/deprecated/Icons/icon-link';
import SerenaComplete from '@float/ui/deprecated/Icons/icon-serena-complete';
import SerenaNotes from '@float/ui/deprecated/Icons/icon-serena-notes';
import SerenaRepeat from '@float/ui/deprecated/Icons/icon-serena-repeat';

import { MainCellItem } from './types';

export function getIconElements(
  item: MainCellItem,
  color: React.CSSProperties['color'],
  suvSingleDay: string | null | undefined,
  user: CurrentUser,
) {
  const { entity } = item;

  const elements = [];

  if ('timeoff_id' in entity && entity.timeoff_id && user.timeoff_approvals) {
    if (entity.status === -1) {
      elements.push(
        <Bell3Reject
          key="bell"
          color={color}
          style={{ marginTop: -2 }}
          size={suvSingleDay ? 12 : 15}
          data-testid="icon-bell-reject"
        />,
      );
    } else if (entity.status === 1 && !!entity.status_request) {
      elements.push(
        <Bell3
          key="bell"
          color={color}
          style={{ marginTop: -2 }}
          size={suvSingleDay ? 12 : 15}
          data-testid="icon-bell"
        />,
      );
    }
  }

  if ('repeat_state' in entity && entity.repeat_state! > 0) {
    elements.push(
      <SerenaRepeat
        key="repeat"
        color={color}
        size={suvSingleDay ? 15 : 12}
        data-testid="icon-repeat"
      />,
    );
  }

  if ('root_task_id' in entity && entity.root_task_id) {
    elements.push(
      <Link
        key="link"
        color={color}
        size={suvSingleDay ? 15 : 12}
        data-testid="icon-link"
      />,
    );
  }

  if (
    ('notes' in entity && entity.notes && entity.notes.length) ||
    ('timeoff_notes' in entity &&
      entity.timeoff_notes &&
      entity.timeoff_notes.length)
  ) {
    elements.push(
      <SerenaNotes
        key="notes"
        color={color}
        size={suvSingleDay ? 15 : 12}
        data-testid="icon-notes"
      />,
    );
  }

  if ('status' in entity && entity.status === 3) {
    elements.push(
      <SerenaComplete
        key="complete"
        color={color}
        size={suvSingleDay ? 15 : 12}
        data-testid="icon-complete"
      />,
    );
  }

  if (
    item.type === 'loggedTime' &&
    item.isInLockPeriod &&
    'isResizingVertically' in item.entity &&
    !item.entity.isResizingVertically
  ) {
    elements.push(
      <IconLock
        key="locked-logged"
        color={color}
        size={15}
        data-testid="icon-lock"
      />,
    );
  }

  return elements;
}
