import React from 'react';
import { t } from '@lingui/macro';

import { ProjectStatus } from '@float/types/project';
import { ModalConfirm } from '@float/ui/components/Modals/ModalConfirm';
import { getEntityStatusLabel } from '@float/ui/helpers/label/entityStatusLabel';
import { ModalProps } from '@float/ui/primitives/Modal';

import { EntityStatusMessage } from './EntityStatusMessage';

type ProjectStatusConfirmModalProps = {
  entity: 'project' | 'phase';
  prev: ProjectStatus;
  next: ProjectStatus;
  modalProps: ModalProps;
  onStatusChangeConfirm: () => void;
  onStatusChangeCancel: (state: boolean) => void;
};

export const EntityStatusConfirmModal = ({
  entity,
  prev,
  next,
  modalProps,
  onStatusChangeCancel,
  onStatusChangeConfirm,
}: ProjectStatusConfirmModalProps) => {
  if (prev === next || typeof prev !== 'number' || typeof next !== 'number')
    return;
  const nextStatus = getEntityStatusLabel(next);
  const title = t`Update ${entity} status to ${nextStatus}`;
  return (
    <ModalConfirm
      modalProps={modalProps}
      title={title}
      onOpenChange={onStatusChangeCancel}
      onClickConfirm={onStatusChangeConfirm}
    >
      <EntityStatusMessage prev={prev} next={next} entity={entity} />
    </ModalConfirm>
  );
};
