import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Trans } from '@lingui/macro';
import { assignInlineVars } from '@vanilla-extract/dynamic';

import { Radix } from '@float/common/earhart/colors';
import * as Accordion from '@float/ui/components/Accordion';
import { Tag } from '@float/ui/components/Tag';

import { EntityStatusTag } from '../../components/EntityStatusTag';
import { FormFieldsSection } from '../../components/FormFieldsSection';
import { SectionErrorTag } from '../../components/SectionErrorTag';
import { BillableField } from '../../fields/BillableField';
import { NotesField } from '../../fields/NotesField';
import { PhaseDatesField } from '../../fields/PhaseDatesField';
import {
  PhaseDurationField,
  PhaseOffsetField,
} from '../../fields/PhaseDurationOffsetField';
import { PhaseProjectBudgetField } from '../../fields/PhaseProjectBudgetField';
import { StatusField } from '../../fields/StatusField';
import { AccordionEntry } from '../../hooks/useAccordionState';
import { useIsTemplate } from '../../hooks/useIsTemplate';
import { ProjectFormData } from '../../types';

import { datePickerActiveColor } from '../../components/DateRangeField/styles.css';
import * as styles from '../../styles.css';

function PhaseInfoHeader() {
  const isNonBillable = useWatch<ProjectFormData>({
    name: 'phase.non_billable',
  });

  return (
    <div className={styles.projectAccordionSummary}>
      <div className={styles.showWhenCollapsed}>
        <EntityStatusTag entity="phase" />
        {isNonBillable ? (
          <Tag color="primary">
            <Trans>Non-billable</Trans>
          </Tag>
        ) : (
          <Tag color="primary">
            <Trans>Billable</Trans>
          </Tag>
        )}
      </div>
      <SectionErrorTag fields={['phase.end_date']} />
    </div>
  );
}

export function PhaseInfoSection() {
  const isTemplate = useIsTemplate();
  const { getValues } = useFormContext<ProjectFormData>();
  const projectNonBillable = Boolean(getValues('project.non_billable'));
  return (
    <Accordion.Item value={AccordionEntry.info}>
      <Accordion.Header
        className={styles.projectAccordionHeader}
        info={<PhaseInfoHeader />}
      >
        <Trans>Info</Trans>
      </Accordion.Header>
      <Accordion.Content
        style={assignInlineVars({
          [datePickerActiveColor]: Radix.Primary.Flay['6'],
        })}
      >
        <FormFieldsSection>
          <StatusField name="phase.status" />
          <BillableField
            name="phase.non_billable"
            isBillableDisabled={projectNonBillable}
          />
          <PhaseProjectBudgetField />
          {isTemplate ? (
            <>
              <PhaseOffsetField />
              <PhaseDurationField />
            </>
          ) : (
            <PhaseDatesField name="phase" />
          )}
          <NotesField name="phase.notes" meta="phase.notes_meta" />
        </FormFieldsSection>
      </Accordion.Content>
    </Accordion.Item>
  );
}
