import { t } from '@lingui/macro';

import {
  createTemplate as createTemplateAction,
  TemplateInputData,
  updateTemplate as updateTemplateAction,
} from '@float/common/actions';
import {
  diffEntityListPayload,
  diffEntityPayload,
} from '@float/common/lib/diffEntityPayload';
import { getTempId } from '@float/common/lib/scheduleUtils';
import { useAppDispatchStrict } from '@float/common/store';
import { TemplatePhase } from '@float/types/phase';
import { BudgetPriority, ProjectStatus } from '@float/types/project';
import { TemplateTask } from '@float/types/task';
import { addClient } from '@float/web/settingsV2/actions/clients';

import {
  DEFAULT_MILESTONE_DURATION,
  DEFAULT_OFFSET,
  DEFAULT_PHASE_DURATION,
  getMinimumRequiredDuration,
} from '../lib/projectValidationHelpers';
import { ProjectFormData } from '../types';

type SupportedFields = 'project' | 'team' | 'tasks' | 'milestones' | 'phases';

type HandleCreateProps = Pick<ProjectFormData, SupportedFields>;

function mapTemplateTeam(team: ProjectFormData['team']) {
  const result = [];
  for (const person of team) {
    if (person.isAssignedToPhaseOnly) continue;

    result.push({
      id: Number(person.people_id),
      hourly_rate: Number(person.hourly_rate),
    });
  }

  return result;
}

function mapTemplateMilestones(milestones: ProjectFormData['milestones']) {
  return milestones.map((m) => ({
    milestone_id: `${m.milestone_id || getTempId()}`,
    name: m.name,
    duration:
      typeof m.duration === 'number' ? m.duration : DEFAULT_MILESTONE_DURATION,
    parent_offset:
      typeof m.parent_offset === 'number' ? m.parent_offset : DEFAULT_OFFSET,
  }));
}

function mapTemplatePhases(
  phases: ProjectFormData['phases'],
  project: ProjectFormData['project'],
) {
  return phases.map((p) => {
    const result: TemplatePhase = {
      phase_id: `${p.phase_id || getTempId()}`,
      name: p.phase_name,
      color: p.color,
      duration:
        typeof p.duration === 'number' ? p.duration : DEFAULT_PHASE_DURATION,
      parent_offset:
        typeof p.parent_offset === 'number' ? p.parent_offset : DEFAULT_OFFSET,
    };
    if (
      project.budget_priority === BudgetPriority.Phase &&
      p.budget_total !== null
    ) {
      result.budget_total = p.budget_total;
    }

    // Attach data from phase panel if available
    if (p.fullPhaseData) {
      const { phase, team, tasks, milestones } = p.fullPhaseData;
      result.notes = phase.notes;
      result.non_billable = phase.non_billable ? 1 : 0;
      result.status = phase.status;
      result.tentative = phase.status === ProjectStatus.Tentative ? 1 : 0;
      result.default_hourly_rate = phase.default_hourly_rate ?? undefined;
      result.team_people = mapTemplateTeam(team);
      result.task_metas = mapTemplateTasks(tasks, project);
      result.milestones = mapTemplateMilestones(milestones);
    }

    // Ensure the phase duration is at least the sum of the milestones
    result.duration = getMinimumRequiredDuration({
      initial: { duration: result.duration },
      milestones: result.milestones,
    });

    // phases in a non-billable project must be non-billable
    if (project?.non_billable) result.non_billable = 1;

    // phases in a draft or tentative project must inherit the project status
    if (
      project?.status === ProjectStatus.Draft ||
      project?.status === ProjectStatus.Tentative
    ) {
      result.status = project.status;
      result.tentative = project.status === ProjectStatus.Tentative ? 1 : 0;
    }

    return result;
  });
}

function mapTemplateTasks(
  tasks: ProjectFormData['tasks'],
  project: ProjectFormData['project'],
) {
  return tasks.map((t) => {
    const result: TemplateTask = {
      task_meta_id: t.task_meta_id || Number(getTempId()),
      task_name: t.task_name,
      billable: project.non_billable ? 0 : t.billable,
    };

    if (project.budget_priority === BudgetPriority.Task && t.budget !== null) {
      result.budget = t.budget;
    }

    return result;
  });
}

function sanitizeTemplateDuration(template: TemplateInputData) {
  const userHasDefinedDuration = template.duration !== null;
  if (userHasDefinedDuration) {
    // Ensure the project duration is at least the sum of the phases and milestones
    template.duration = getMinimumRequiredDuration({
      initial: {
        duration: template.duration ?? DEFAULT_PHASE_DURATION,
      },
      milestones: template.milestones,
      phases: template.phases,
    });
  }
}

export function useTemplateSave() {
  const dispatch = useAppDispatchStrict();

  async function handleCreateClient(template: { client_id?: number | string }) {
    // When the user creates a new client `client_id` is passed as a string
    if (typeof template.client_id === 'string') {
      const { payload: client } = await dispatch(
        addClient({ name: template.client_id }),
      );

      return client.client_id as number;
    }

    return template.client_id;
  }

  async function handleCreate(props: HandleCreateProps) {
    const { project, team, tasks, milestones, phases } = props;
    const clientId = await handleCreateClient(project);
    const newTemplate: TemplateInputData = {
      ...project,
      duration: project.duration ?? null, // passing null allows the backend to calculate the duration
      client_id: clientId,
      project_name: project.project_name,
      team_people: mapTemplateTeam(team),
      task_metas: mapTemplateTasks(tasks, project),
      milestones: mapTemplateMilestones(milestones),
      phases: mapTemplatePhases(phases, project),
    };

    sanitizeTemplateDuration(newTemplate);

    const res = await dispatch(createTemplateAction(newTemplate));

    if (res) return res.project_template_id;

    throw new Error(t`Failed to create template`);
  }

  async function handleUpdate(
    templateId: number,
    update: Pick<ProjectFormData, SupportedFields>,
    currentValues: Pick<ProjectFormData, SupportedFields>,
  ) {
    const projectDiff = diffEntityPayload(
      update.project,
      currentValues.project,
    );
    const teamDiff = diffEntityListPayload(
      update.team,
      currentValues.team,
      'people_id',
    );

    const tasksDiff = diffEntityListPayload(
      update.tasks,
      currentValues.tasks,
      'task_meta_id',
    );

    const milestonesDiff = diffEntityListPayload(
      update.milestones,
      currentValues.milestones,
      'milestone_id',
    );

    const phasesDiff = diffEntityListPayload(
      update.phases,
      currentValues.phases,
      'phase_id',
    );

    if (
      !projectDiff &&
      !teamDiff &&
      !tasksDiff &&
      !milestonesDiff &&
      !phasesDiff
    )
      return;

    const clientId = await handleCreateClient(update.project);

    const modifiedTemplate = {
      ...update.project,
      client_id: clientId,
      team_people: mapTemplateTeam(update.team),
      task_metas: mapTemplateTasks(update.tasks, update.project),
      milestones: mapTemplateMilestones(update.milestones),
      phases: mapTemplatePhases(update.phases, update.project),
    };

    sanitizeTemplateDuration(modifiedTemplate);

    await dispatch(updateTemplateAction(templateId, modifiedTemplate));
  }

  return {
    handleCreate,
    handleUpdate,
  };
}
