import React, { Component } from 'react';

export default (WrappedComponent) =>
  class extends Component {
    constructor(props) {
      super(props);
      this.state = { isLoading: true };
      this.toggleLoading = this.toggleLoading.bind(this);
    }
    componentDidMount() {
      const { onComponentDidMount } = this.props;
      if (!onComponentDidMount) {
        this.toggleLoading();
        return;
      }
      const maybePromise = this.props.onComponentDidMount();
      if (maybePromise instanceof Promise) {
        maybePromise.then(() => this.setState({ isLoading: false }));
      } else {
        this.toggleLoading();
      }
    }

    toggleLoading(isLoading) {
      if (typeof isLoading !== 'boolean') isLoading = !this.state.isLoading;
      this.setState({ isLoading });
    }
    render() {
      return (
        <WrappedComponent
          isLoading={this.state.isLoading || this.props.isLoading}
          toggleLoading={this.toggleLoading}
          {...this.props}
        />
      );
    }
  };
