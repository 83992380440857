import React from 'react';
import { Trans } from '@lingui/macro';

import { TagStatic } from '@float/ui/components/TagStatic';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';

import * as styles from './AddonTag.css';

const AddonTag = (props: { isColumn: boolean }) => {
  const { isColumn = false } = props;

  return (
    <span className={styles.addonTag({ isColumn })}>
      <TagStatic
        foregroundColor={Colors.Primary.Flue.Light[9]}
        backgroundColor={Colors.Primary.Flue.Light[4]}
        size="small"
      >
        <Trans>Pro</Trans>
      </TagStatic>
    </span>
  );
};

export { AddonTag };
