import { Variant } from 'framer-motion';

interface FramerVariants<C> {
  [key: string]: (custom: C) => Variant;
}

interface ItemAnimationData {
  animateBackground: boolean;
}

export const overlayMotionConfig: FramerVariants<ItemAnimationData> = {
  initial: () => ({
    opacity: 0,
  }),
  animate: ({ animateBackground }) => {
    return {
      opacity: 1,
      transition: {
        type: 'tween',
        ease: 'easeOut',
        duration: animateBackground ? 0.25 : 0,
      },
    };
  },
  exit: ({ animateBackground }) => {
    return {
      opacity: 0,
      transition: {
        type: 'tween',
        ease: 'easeOut',
        duration: animateBackground ? 0.15 : 0,
      },
    };
  },
};

export const modalMotionConfig = {
  initial: {
    opacity: 0,
    scale: 0.96,
  },
  transition: {
    scale: {
      type: 'spring',
      bounce: 0.35,
      duration: 0.55,
    },
  },
  animate: { opacity: 1, scale: 1 },
  exit: {
    opacity: 0,
    scale: 0.96,
    transition: {
      duration: 0.15,
    },
  },
};
