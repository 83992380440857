const defaultSortOptions = [
  {
    label: 'Newest',
    selectedLabel: 'Sort by: Newest',
    value: 'extCreatedAt',
    order: 'desc',
  },
  {
    label: 'Not scheduled',
    selectedLabel: 'Sort by: Not scheduled',
    value: 'scheduleStatus',
    order: 'asc',
  },
  {
    label: 'Assignee',
    selectedLabel: 'Sort by: Assignee',
    value: 'personName',
    order: 'asc',
  },
];

export const integrationTypes = {
  jira: {
    title: 'Jira',
    itemsTitle: 'Issues',
    defaultSort: { ...defaultSortOptions[0] },
    sortOptions: [...defaultSortOptions],
    filterOptions: [
      {
        label: 'All issue types',
        selectedLabel: 'Show: All issue types',
        value: null,
        key: null,
      },
      {
        label: 'Epics',
        selectedLabel: 'Show: Epics',
        value: 'Epic',
        key: 'issueType',
      },
      {
        label: 'Stories',
        selectedLabel: 'Show: Stories',
        value: 'Story',
        key: 'issueType',
      },
      {
        label: 'Bugs',
        selectedLabel: 'Show: Bugs',
        value: 'Bug',
        key: 'issueType',
      },
      {
        label: 'Tasks',
        selectedLabel: 'Show: Tasks',
        value: 'Task',
        key: 'issueType',
      },
      {
        label: 'Sub-tasks',
        selectedLabel: 'Show: Sub-tasks',
        value: { $regex: ['^sub-?task$', 'i'] },
        key: 'issueType',
      },
    ],
  },
  teamwork: {
    title: 'Teamwork',
    itemsTitle: 'Tasks',
    defaultSort: { ...defaultSortOptions[0] },
    sortOptions: [...defaultSortOptions],
    filterOptions: [
      {
        label: 'Task & Sub-tasks',
        selectedLabel: 'Task & Sub-tasks',
        value: null,
        key: null,
      },
      {
        label: 'Tasks',
        selectedLabel: 'Show: Tasks',
        value: { $exists: false },
        key: 'parentId',
      },
      {
        label: 'Sub-tasks',
        selectedLabel: 'Show: Sub-tasks',
        value: { $exists: true },
        key: 'parentId',
      },
    ],
  },
  asana: {
    title: 'Asana',
    itemsTitle: 'Tasks',
    generateTaskLink: ({ baseUrl, taskLink, project }) =>
      `${baseUrl}/${project.ext_resource_id}/${taskLink.ext_resource_id}`,
    defaultSort: { ...defaultSortOptions[0] },
    sortOptions: [...defaultSortOptions],
    filterOptions: [
      {
        label: 'Task & Sub-tasks',
        selectedLabel: 'Task & Sub-tasks',
        value: null,
        key: null,
      },
      {
        label: 'Tasks',
        selectedLabel: 'Show: Tasks',
        value: { $exists: false },
        key: 'parentId',
      },
      {
        label: 'Sub-tasks',
        selectedLabel: 'Show: Sub-tasks',
        value: { $exists: true },
        key: 'parentId',
      },
    ],
  },
  trello: {
    title: 'Trello',
    itemsTitle: 'Cards',
    generateTaskLink: ({ taskLink }) => taskLink.custom_url,
    defaultSort: {
      value: 'position',
      order: 'asc',
    },
    sortOptions: [
      {
        label: 'Newest',
        selectedLabel: 'Sort by: Newest',
        value: 'extCreatedAt',
        order: 'desc',
      },
      {
        label: 'Not scheduled',
        selectedLabel: 'Sort by: Not scheduled',
        value: 'scheduleStatus',
        order: 'asc',
      },
      {
        label: 'Same as Trello',
        selectedLabel: 'Sort by: Same as Trello',
        value: 'position',
        order: 'asc',
      },
    ],
  },
};
