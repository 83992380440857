import React from 'react';
import { format, parse } from 'date-fns';
import { getPeople } from 'selectors';

import PersonAvatar from '@float/common/components/elements/PersonAvatar';
import { ReduxState } from '@float/common/reducers/lib/types';
import { useAppSelector } from '@float/common/store';
import { Person } from '@float/types';

import {
  TimeoffRequestHeaderWrapper,
  TimeoffRequestLabel,
  TimeoffRequestSpan,
} from './styles';

export const TimeoffRequestHeader = (props: {
  senderAccountId: number;
  requested: string;
}) => {
  const person = useAppSelector((state: ReduxState) => {
    const people = getPeople(state);

    return (
      // The account_id could reference a deleted account
      people.find((p) => p.account_id === props.senderAccountId) as
        | Person
        | undefined
    );
  });

  const requestedDate = format(
    parse(props.requested.slice(0, 10), 'yyyy-MM-dd', new Date()),
    'MMM dd yyyy',
  );

  const personId = person?.people_id;
  const name = person?.name || 'Deleted account';

  return (
    <TimeoffRequestHeaderWrapper>
      <PersonAvatar size="s" readOnly personId={personId} tooltip={false} />
      <TimeoffRequestLabel>{name}</TimeoffRequestLabel>
      <TimeoffRequestSpan>sent request {requestedDate}</TimeoffRequestSpan>
    </TimeoffRequestHeaderWrapper>
  );
};
