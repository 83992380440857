import { Person } from '@float/types/person';

import { PEOPLE_STATUS } from '../People.constants';

export type PeopleByQuickFilter = {
  active: Person[];
  archived: Person[];
  withAccess: Person[];
};
export const getFilteredPeople = (
  people: Array<Person>,
  peopleView: number,
  accessMode: number,
) => {
  const filteredPeopleCount = { active: 0, archived: 0 };

  const filteredPeople = people.filter((person) => {
    const hasAccess = person.account_id;
    const isActive = person.active;
    const isArchived = !person.active;

    if (accessMode && !hasAccess) return false;

    if (isActive) filteredPeopleCount.active++;
    if (isArchived) filteredPeopleCount.archived++;

    if (peopleView === PEOPLE_STATUS.ACTIVE && isArchived) return false;
    if (peopleView === PEOPLE_STATUS.ARCHIVED && isActive) return false;

    return true;
  });

  return { filteredPeople, filteredPeopleCount };
};
