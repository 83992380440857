import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M8.95833 11.0417H5.625L11.0417 3.95837V8.95837H14.375L8.95833 16.0417V11.0417Z"
      stroke="#000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </BaseIcon>
);
