import React from 'react';

export default function (props) {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.1724 41.9655C49.8386 41.9655 52 39.8041 52 37.1379V18.5172C52 15.4702 49.5298 13 46.4828 13H27.8621C25.1959 13 23.0345 15.1614 23.0345 17.8276"
        stroke="#344765"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.9655 29.5517C40.9655 26.5046 38.4953 24.0345 35.4482 24.0345H17.5172C14.4701 24.0345 12 26.5046 12 29.5517V47.4827C12 50.5298 14.4701 53 17.5172 53H35.4482C38.4953 53 40.9655 50.5298 40.9655 47.4827V29.5517Z"
        fill="#E1F0FF"
        stroke="#2E5FE8"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.6 29.8C30.8574 29.8 30.1452 30.095 29.6201 30.6201C29.095 31.1452 28.8 31.8574 28.8 32.6V43.8C28.8 44.5426 29.095 45.2548 29.6201 45.7799C30.1452 46.305 30.8574 46.6 31.6 46.6C32.3426 46.6 33.0548 46.305 33.5799 45.7799C34.105 45.2548 34.4 44.5426 34.4 43.8C34.4 43.0574 34.105 42.3452 33.5799 41.8201C33.0548 41.295 32.3426 41 31.6 41H20.4C19.6574 41 18.9452 41.295 18.4201 41.8201C17.895 42.3452 17.6 43.0574 17.6 43.8C17.6 44.5426 17.895 45.2548 18.4201 45.7799C18.9452 46.305 19.6574 46.6 20.4 46.6C21.1426 46.6 21.8548 46.305 22.3799 45.7799C22.905 45.2548 23.2 44.5426 23.2 43.8V32.6C23.2 31.8574 22.905 31.1452 22.3799 30.6201C21.8548 30.095 21.1426 29.8 20.4 29.8C19.6574 29.8 18.9452 30.095 18.4201 30.6201C17.895 31.1452 17.6 31.8574 17.6 32.6C17.6 33.3426 17.895 34.0548 18.4201 34.5799C18.9452 35.105 19.6574 35.4 20.4 35.4H31.6C32.3426 35.4 33.0548 35.105 33.5799 34.5799C34.105 34.0548 34.4 33.3426 34.4 32.6C34.4 31.8574 34.105 31.1452 33.5799 30.6201C33.0548 30.095 32.3426 29.8 31.6 29.8Z"
        stroke="#344765"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
