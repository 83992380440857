import React from 'react';

import { devToolsEnabled } from './devToolsEnabled';

const DevTools = React.lazy(() => import('./DevTools'));

export function MountDevToolsIfEnabled() {
  if (!devToolsEnabled) {
    return null;
  }

  return <DevTools />;
}
