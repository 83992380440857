import React from 'react';

export default ({
  color,
  size,
}: {
  color?: string;
  size?: string | number;
}) => (
  <svg
    width={size || '32'}
    height={size || '32'}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.73 24.3333L25.33 24.3333"
      className="stroke"
      stroke={color || '#2E5FE8'}
      strokeWidth="3.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.80005 7.66666L15.3 7.66666"
      className="stroke"
      stroke={color || '#2E5FE8'}
      strokeWidth="3.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.80005 16L25.3 16"
      className="stroke"
      stroke={color || '#2E5FE8'}
      strokeWidth="3.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
