import React from 'react';
import { useWatch } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';

import { BudgetType } from '@float/types/project';
import { Tag } from '@float/ui/components/Tag';

import { CheckboxField } from '../../components/CheckboxField';
import { CurrencyInputField } from '../../components/CurrencyInputField';
import { DurationInputField } from '../../components/DurationInputField';
import { SidePanelTableColumn } from '../../components/SidePanelTableSection/SidePanelTableColumn';
import { SidePanelTableRow } from '../../components/SidePanelTableSection/SidePanelTableRow';
import { TaskNameField } from '../../fields/TaskNameField';
import { createEmptyTaskRecord } from '../../helpers/createRowEntities';
import { useAddOnEnterKeyDown } from '../../hooks/useAddOnEnterKeyDown';
import { useRemoveOnBlur } from '../../hooks/useRemoveOnBlur';
import { useWatchProjectOrPhaseFieldValue } from '../../hooks/useWatchProjectOrPhaseFieldValue';
import { ProjectFormData } from '../../types';
import { ProjectTaskIndictator } from './ProjectTaskIndicator';
import { ProjectTaskMenu } from './ProjectTaskMenu';

import { control } from '../../components/FormFieldsSection/styles.css';
import * as styles from './styles.css';

type ProjectTeamMemberProps = {
  index: number;
  budgetType?: BudgetType.TotalFee | BudgetType.TotalHours;
  addDisabled: boolean;
};

export const ProjectTaskRow: React.FC<ProjectTeamMemberProps> = ({
  index,
  budgetType,
  addDisabled,
}) => {
  const task = useWatch<ProjectFormData, `tasks.${number}`>({
    name: `tasks.${index}`,
  });
  const isNonBillableEntity = useWatchProjectOrPhaseFieldValue('non_billable');

  const isNonBillableTask = !task?.billable;

  const showNonBillableTag = isNonBillableTask && !isNonBillableEntity;

  // When the user focuses away from an empty row it should be automatically removed
  // It should not be removed when:
  // 1. The task has a name (so not empty)
  // 2. Task has an id (so even if name is empty it is a persisted row)
  // 3. Its the task row that represents the untasked allocations or
  // 4. Its the newly merged task
  const isEmpty =
    !task.task_name?.trim() &&
    !task.task_meta_id &&
    !task.unnamedTask &&
    !task.merge;

  const hasFeeBudget = budgetType === BudgetType.TotalFee;
  const hasHoursBudget = budgetType === BudgetType.TotalHours;
  const hasBudget = hasFeeBudget || hasHoursBudget;

  const { ref: blurRef, onBlurHandler } = useRemoveOnBlur({
    isEmpty,
    index,
  });
  const { onEnterKeyDown } = useAddOnEnterKeyDown({
    canAdd: !addDisabled && !isEmpty,
    data: createEmptyTaskRecord,
  });

  return (
    <SidePanelTableRow
      data-testid={`task_${index}`}
      ref={blurRef as React.RefObject<HTMLDivElement>}
      onBlur={onBlurHandler}
      onKeyDown={onEnterKeyDown}
      className={styles.tasksGridTemplateColumns}
    >
      <SidePanelTableColumn className={styles.taskCheckbox}>
        <CheckboxField
          name={`tasks.${index}.isSelected`}
          disabled={!task?.task_name && !task?.unnamedTask}
          label={t`Select task`}
          hideLabel
        />
      </SidePanelTableColumn>
      <SidePanelTableColumn className={styles.taskAllocationIndicator}>
        <ProjectTaskIndictator task={task} />
      </SidePanelTableColumn>
      <SidePanelTableColumn>
        <TaskNameField
          autoFocus={!task?.task_name}
          index={index}
          unnamedTask={task.unnamedTask}
        />
      </SidePanelTableColumn>
      <SidePanelTableColumn>
        {showNonBillableTag && (
          <div className={control({ padding: 'default' })}>
            <Tag className={styles.taskTagNonbillable} size="small">
              <Trans>Non-billable</Trans>
            </Tag>
          </div>
        )}
        {!showNonBillableTag && hasBudget && (
          <>
            {hasHoursBudget && (
              <DurationInputField
                name={`tasks.${index}.budget`}
                label={t`Task budget`}
                hideLabel
              />
            )}
            {hasFeeBudget && (
              <CurrencyInputField
                name={`tasks.${index}.budget`}
                label={t`Task budget`}
                hideLabel
              />
            )}
          </>
        )}
      </SidePanelTableColumn>

      <SidePanelTableColumn>
        <ProjectTaskMenu index={index} />
      </SidePanelTableColumn>
    </SidePanelTableRow>
  );
};
