import { FeatureFlag } from '@float/libs/featureFlags';

const STORAGE_KEY = 'featureFlags';

export const getAreFeatureFlagsOverridesEnabled = () =>
  Boolean(localStorage.getItem(STORAGE_KEY));

export const getFeatureFlagsOverrides = (): Partial<
  Record<FeatureFlag, boolean>
> => {
  const rawValue = localStorage.getItem(STORAGE_KEY);

  if (rawValue) return JSON.parse(rawValue);

  return {};
};

export const setFeatureFlagsOverrides = (
  value: Partial<Record<FeatureFlag, boolean>>,
) => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(value));
};

export const disableFeatureFlagOverrides = () => {
  localStorage.removeItem(STORAGE_KEY);
};
