import { getLoggedTime } from 'selectors';

import { useAppSelectorWithParams } from '@float/common/store';
import { LoggedTime, Timer } from '@float/types';

export function useTimerLoggedTime(
  timer: Timer | null,
): LoggedTime | undefined {
  return useAppSelectorWithParams(getLoggedTime, timer?.external_meta.id || '');
}
