import React from 'react';

export default function ({ size = '20', color = '#242C39' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.41671 12.7083C4.61129 12.7083 3.95837 12.0554 3.95837 11.25V5.62498C3.95837 4.70451 4.70457 3.95831 5.62504 3.95831H11.25C12.0555 3.95831 12.7084 4.61123 12.7084 5.41665"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.29163 8.95835C7.29163 8.03788 8.03782 7.29169 8.95829 7.29169H14.375C15.2954 7.29169 16.0416 8.03788 16.0416 8.95835V14.375C16.0416 15.2955 15.2954 16.0417 14.375 16.0417H8.95829C8.03782 16.0417 7.29163 15.2955 7.29163 14.375V8.95835Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
