import { createSelector } from 'reselect';

import { getPeopleWithAccount } from './people';

/**
 * Account.name and People.name can differ if someone update the person's name via modal for example.
 */
export const getAccurateNameByAccountId = createSelector(
  [getPeopleWithAccount],
  (peopleWithAccount) => {
    return peopleWithAccount.reduce(
      (acc, current) => {
        acc[current.account_id] = current.name;
        return acc;
      },
      {} as Record<number, string>,
    );
  },
);
