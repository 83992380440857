import React, { useRef } from 'react';

import {
  PersonProjectRow,
  PersonRow,
  ProjectRow,
} from '@float/common/serena/Data/useScheduleRows';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { CellItem } from '@float/types';

import { ScheduleActions } from '../types';

import { box } from './NonWorkDayItem.css';

type NonWorkDayItemProps = {
  item: CellItem<'nonWorkDay'>;
  row: PersonRow | PersonProjectRow | ProjectRow;
  actions: ScheduleActions;
  dayWidth: number;
  suvSingleDay?: string | null;
};

function NonWorkDayItem(props: NonWorkDayItemProps) {
  const { item, dayWidth, actions, row, suvSingleDay } = props;
  const { x = 0, w } = item;
  const didMouseDown = useRef(false);

  const left = x * dayWidth + 1;
  let width: string | number = w * dayWidth - 1;

  if (suvSingleDay) {
    width = 'calc(100% - 1px)';
  }

  return (
    <div
      className={box}
      onClick={prevent}
      onMouseDown={(event) => {
        event.stopPropagation();
        if (event.button !== 0) return;
        didMouseDown.current = true;
      }}
      onMouseUp={(event) => {
        if (!didMouseDown.current) return;
        didMouseDown.current = false;
        actions.onNonWorkDayClick(event, row, suvSingleDay);
      }}
      style={{
        left,
        width,
        top: suvSingleDay ? 0 : -1,
        height: suvSingleDay ? '100%' : '101%', // 101% is to account for the top: -1 just above.
      }}
    />
  );
}

export default NonWorkDayItem;
