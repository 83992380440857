import React, { useLayoutEffect, useRef, useState } from 'react';

import { shallowDiffers } from '@float/common/components/Schedule/util/diff';
import { useRowsPositionManager } from '@float/common/components/Schedule/Window/useRowsPositionManager';
import { useCallbackRef } from '@float/libs/hooks/useCallbackRef';
import { noop } from '@float/libs/utils/noop';
import { CellsMap } from '@float/types/cells';
import { ScheduleRowList } from '@float/types/rows';

export type ColRange = {
  colStart: number;
  colStop: number;
};

export type RowRange = {
  rowStart: number;
  rowStop: number;
};

export const COL_OVERSCAN = 1;

export type WindowRangesProps = {
  scrollWrapperRef: React.MutableRefObject<HTMLElement | null | undefined>;
  colWidth: number;
  containerWidth: number;
  baseColOffset: number;
  numCols: number;
  onVisibleRangeChange?: (value: ColRange & RowRange) => void;
  onScroll: ((x: number, y: number) => void) | undefined;
  hourHeight: number;
  containerHeight: number;
  containerY: number;
  scrollbarSize: number;
  rows: ScheduleRowList;
  cells: CellsMap;
  numVisibleWeeks: number;
  viewType: 'people' | 'projects' | undefined;
  singleUserView: boolean;
  animations: { enable: () => void; disable: () => void };
  suvWeek: number | undefined;
};

function toBoundaryCol(col: number, numVisibleWeeks: number) {
  return Math.floor(col / numVisibleWeeks) * numVisibleWeeks;
}

/**
 * The intent of this hook is to:
 * - Track the visible rows and columns of the Schedule
 * - Adjust the scroll position so when we scroll horizontally the visible rows
 *   aren't subjected to changes due to their height updates
 */
export function useWindowRanges(props: WindowRangesProps) {
  const {
    scrollWrapperRef,
    colWidth,
    containerWidth,
    baseColOffset,
    numCols,
    onVisibleRangeChange = noop,
    onScroll = noop,
    hourHeight,
    containerHeight,
    containerY,
    scrollbarSize,
    rows,
    cells,
    numVisibleWeeks,
    viewType,
    singleUserView,
    animations,
    suvWeek,
  } = props;

  const [colRange, setColRange] = useState({
    colStart: baseColOffset,
    colStop: baseColOffset + numCols,
  });

  const [rowRange, setRowRange] = useState({
    rowStart: 0,
    rowStop: 0,
  });

  const [scrollTop, setScrollTop] = useState(0);

  const boundaryCol = toBoundaryCol(colRange.colStart, numVisibleWeeks);
  const rowsPositionManager = useRowsPositionManager({
    boundaryCol,
    hourHeight,
    containerHeight,
    containerY,
    scrollbarSize,
    rows,
    cells,
    numVisibleWeeks,
    viewType,
    suvWeek,
    singleUserView,
    scrollTop,
  });

  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const previousScrollLeft = useRef(0);
  const updateRanges = useCallbackRef(() => {
    if (!scrollWrapperRef || !scrollWrapperRef.current) return;

    const el = scrollWrapperRef.current;

    let visibleRangeChanged = false;

    // colRange is independent of horizontal position, so just set it
    const x = el.scrollLeft;
    const start = Math.floor(x / colWidth);
    const stop = Math.ceil((x + containerWidth) / colWidth);
    const colStart = baseColOffset + Math.max(0, start - COL_OVERSCAN);
    const colStop = baseColOffset + Math.min(numCols, stop + COL_OVERSCAN);
    const newColRange = { colStart, colStop };

    if (shallowDiffers(colRange, newColRange)) {
      visibleRangeChanged = true;
      setColRange(newColRange);
    }

    const newRowRange = rowsPositionManager.getRowRange(el.scrollTop);

    if (shallowDiffers(rowRange, newRowRange)) {
      visibleRangeChanged = true;
      setRowRange(newRowRange);
    }

    onScroll(el.scrollLeft, el.scrollTop);

    if (visibleRangeChanged) {
      onVisibleRangeChange({
        colStart: newColRange.colStart,
        colStop: newColRange.colStop,
        rowStart: newRowRange.rowStart,
        rowStop: newRowRange.rowStop,
      });
      setScrollTop(el.scrollTop);
    }

    // Blocking the animations when scrolling horizontally
    // to keep the first visible row always the same
    if (previousScrollLeft.current === x) {
      return;
    }

    previousScrollLeft.current = x;

    if (timerRef.current === null) {
      animations.disable();
    } else {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      animations.enable();
      timerRef.current = null;
    }, 500);
  });

  // Respond to onScroll event and set visibleRange and isScrolling
  useLayoutEffect(() => {
    if (!scrollWrapperRef || !scrollWrapperRef.current) return;

    const el = scrollWrapperRef.current;

    // Whenever one of the deps here changes, we want to go ahead and re-render
    // to make sure that a browser size or view option change is reflected.
    updateRanges();

    el.addEventListener('scroll', updateRanges, { passive: true });

    return () => {
      el.removeEventListener('scroll', updateRanges);
    };
  }, [scrollWrapperRef, updateRanges, rowsPositionManager]);

  // Note that this is using a layout effect as we want to synchronize the
  // adjustment of the scrollTop with the new row offsets and heights.
  useLayoutEffect(() => {
    if (!rowsPositionManager.delta) return;
    if (!scrollWrapperRef.current) return;

    const top = scrollWrapperRef.current.scrollTop + rowsPositionManager.delta;

    scrollWrapperRef.current.scrollTo({ top });
  }, [animations, rowsPositionManager.delta, scrollWrapperRef]);

  return {
    colRange,
    rowRange,
    rowsPositionManager,
    boundaryCol,
  };
}
