import React, { ComponentPropsWithRef } from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';

export type ModalCloseProps = ComponentPropsWithRef<'button'>;

export const ModalClose = (props: ModalCloseProps) => {
  const { children, className } = props;

  return (
    <DialogPrimitive.Close className={className} asChild>
      {children}
    </DialogPrimitive.Close>
  );
};
