import { unitOfTime } from 'moment';

import { moment } from '@float/libs/moment';

export const startOf = (unit: unitOfTime.StartOf) => () =>
  moment().startOf(unit);
export const endOf = (unit: unitOfTime.StartOf) => () => moment().endOf(unit);
export const endOfXUnits =
  (x = 2, units: unitOfTime.DurationConstructor = 'weeks') =>
  () =>
    moment().startOf('week').add(x, units).subtract(1, 'day');

const rangeOpts = [
  {
    label: 'Next 12 weeks',
    value: '0',
    start: startOf('week'),
    end: endOfXUnits(12, 'weeks'),
    type: 'week',
    range: [11, 'weeks'],
  },
  {
    label: 'This week',
    value: '1',
    start: startOf('week'),
    end: endOf('week'),
    type: 'week',
    range: [1, 'week'],
  },
  {
    label: 'This month',
    value: '2',
    start: startOf('month'),
    end: endOf('month'),
    type: 'month',
    range: [1, 'month'],
  },
  {
    label: 'This quarter',
    value: '3',
    start: startOf('quarter'),
    end: endOf('quarter'),
    type: 'quarter',
    range: [1, 'quarter'],
  },
  {
    label: 'This year',
    value: '4',
    start: startOf('year'),
    end: endOf('year'),
    type: 'year',
    range: [1, 'year'],
  },
  {
    label: 'Last week',
    value: '5',
    start: () => moment().startOf('week').subtract(1, 'day').startOf('week'),
    end: endOfXUnits(0, 'week'),
    type: 'week',
    range: [1, 'week'],
  },
  {
    label: 'Last month',
    value: '6',
    start: () => moment().startOf('month').subtract(1, 'day').startOf('month'),
    end: () => moment().startOf('month').subtract(1, 'day').endOf('month'),
    type: 'month',
    range: [1, 'month'],
  },
  {
    label: 'Last quarter',
    value: '7',
    start: () =>
      moment().startOf('quarter').subtract(1, 'day').startOf('quarter'),
    end: () => moment().startOf('quarter').subtract(1, 'day').endOf('quarter'),
    type: 'quarter',
    range: [1, 'quarter'],
  },
  {
    label: 'Last year',
    value: '8',
    start: () => moment().startOf('year').subtract(1, 'day').startOf('year'),
    end: () => moment().startOf('year').subtract(1, 'day').endOf('year'),
    type: 'year',
    range: [1, 'year'],
  },
];

export default rangeOpts;
