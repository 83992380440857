export const preventDefaultAndStopPropagation = (evt?: {
  preventDefault: () => void;
  stopPropagation: () => void;
}) => {
  if (evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
};

/**
 * @deprecated - use `preventDefaultAndStopPropagation`
 */
export const prevent = preventDefaultAndStopPropagation;
