import React from 'react';
import formatDate from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { get, omit, uniq } from 'lodash';
import {
  getAccessOptions,
  getDepartmentOptions,
  getSubdepartments,
  getUser,
} from 'selectors';
import styled from 'styled-components';

import {
  getManagerAccess,
  setMemberAccess,
} from '@float/common/lib/acl/access';
import request from '@float/common/lib/request';
import { useAppSelector } from '@float/common/store';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import {
  EH,
  Input,
  ModalTopGreySection,
  SecondaryText,
  Spacer,
  VirtualSelect,
} from '@float/ui/deprecated';

import {
  AdminViewRights,
  BillingViewRights,
  ManagerRightsControls,
  MemberRightsControls,
} from './LimitedAccessRights';

export const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const HalfSection = styled(Section)`
  width: 46%;
  margin-bottom: 22px;
`;

export const checkCanManage = (account) => {
  const isAdmin = account?.account_type === 2;
  const isBilling = account?.account_type === 5;
  const isAccountOwner = account?.account_type === 1;
  const isManager = account?.account_type === 7;
  if (isAdmin || isAccountOwner || isBilling || isManager) {
    return true;
  }

  return false;
};

export const checkIsPeopleManager = (account) => {
  if (!account) return false;

  const accountType = account.account_type || account.account_type_id;
  const isAdmin = accountType === 2;
  const isBilling = accountType === 5;
  const isAccountOwner = accountType === 1;
  if (isAdmin || isAccountOwner || isBilling) {
    return true;
  }

  const isManager = accountType === 7;
  const isPeopleManager = getManagerAccess(account).isPeopleManager;
  return isManager && isPeopleManager;
};

let self;

const isAccountLimitedByDep = (account) => {
  const accountType = account.account_type || account.account_type_id;
  const isLimitedRightsAccount = [3, 4, 6, 7].includes(accountType);
  return isLimitedRightsAccount && !!account.department_filter?.length;
};

export const getStateDeltaOnAccessChange = (props) => {
  const { currentUser, account, accountType, user, departmentOptions } = props;

  if (!accountType) {
    return null;
  }

  const initialAccount = self?.initialAccount;
  const existingAccount = account || initialAccount || {};
  const nextAccount = {
    ...existingAccount,
    account_type: accountType,
  };

  const actorLimitedByDept = isAccountLimitedByDep(currentUser);
  const initialAccountLimitedByDep = initialAccount
    ? isAccountLimitedByDep(initialAccount)
    : actorLimitedByDept;

  nextAccount.department_filter_set =
    actorLimitedByDept && initialAccountLimitedByDep;
  if (nextAccount.department_filter_set) {
    if (initialAccountLimitedByDep && initialAccount?.department_filter) {
      nextAccount.department_filter = initialAccount.department_filter;
    } else {
      if (user.department_id) {
        nextAccount.department_filter = [user.department_id];
      } else if (departmentOptions?.length) {
        nextAccount.department_filter = [departmentOptions[0].value];
      }
    }
  } else {
    nextAccount.department_filter = [];
  }

  // We need to fill the department_filter with department that are required to be there
  // This attribute is used in the context of bulk department_filter updates.
  if (nextAccount.force_department_filter) {
    nextAccount.department_filter = uniq(
      nextAccount.force_department_filter,
      nextAccount.department_filter,
    );
  }

  switch (accountType) {
    case 4: // Member
      nextAccount.access = setMemberAccess(0, 'canView', true);
      nextAccount.access = setMemberAccess(nextAccount.access, 'canEdit', true);
      break;
    case 7: // Manager
      nextAccount.access = 0;
      break;
    case 2: // Admin
      // nextAccount.department_filter_set = false;
      // nextAccount.department_filter = [];
      break;
  }

  return nextAccount;
};

async function resendInvite(evt) {
  prevent(evt);

  const account_id = get(self.state.form, 'account.account_id');
  if (!account_id) throw Error('Cannot resend invite to non-existing acct');

  await request.post(
    'resendInvite',
    { account_id },
    { version: '', hostname: '' },
  );

  self.showMessage(`Invite for ${self.state.form.name} resent.`);
}

export const AccessEmptyPlaceholder = styled.div`
  ${EH.Typography.TextS14.R400};
  color: ${EH.Colors.TCore.Emp.High12};
`;

export const PersonAccessControls = (props) => {
  const { account } = props;

  const accountType = account.account_type;
  const isAdmin = accountType === 2;
  const isAccountOwner = accountType === 1;
  if (isAccountOwner || isAdmin) {
    return <AdminViewRights {...props} />;
  }

  const isBilling = accountType === 5;
  if (isBilling) {
    return <BillingViewRights {...props} />;
  }

  const isManager = accountType === 7;
  if (isManager) {
    return <ManagerRightsControls {...props} />;
  }

  const isMember = accountType === 4;
  if (isMember) {
    return <MemberRightsControls {...props} />;
  }

  return (
    <AccessEmptyPlaceholder>
      {
        'Once access rights are selected, your teammate will receive an email invitation to join your team.'
      }
    </AccessEmptyPlaceholder>
  );
};

export const PersonAccess = (props) => {
  const { formErrors = {}, user, account = {}, initialPerson } = props;

  const accessOptions = useAppSelector(getAccessOptions);
  const departmentOptions = useAppSelector(getDepartmentOptions);
  const subdepartments = useAppSelector(getSubdepartments);
  const currentUser = useAppSelector(getUser);
  const accountType = account.account_type || 0;

  return (
    <>
      <ModalTopGreySection>
        {accountType == 1 ? (
          <Input readOnly label="Access" value="Account Owner" noBorder />
        ) : (
          <VirtualSelect
            label="Access"
            placeholder={props.guestMode ? 'Select a role' : 'No access rights'}
            autoFocus={!props.guestMode}
            hideClearIcon={props.hideClearAccess}
            visibleItems={6}
            clearInputOnDropdownOpen={false}
            value={accountType}
            options={accessOptions.flatMap((option, i) => {
              // Billing is only visible in guest mode by Account Owner and billing
              const isOwnerOrBilling = [1, 5].includes(
                currentUser.account_type_id,
              );
              if (
                option.value === 5 &&
                (!props.guestMode || !isOwnerOrBilling)
              ) {
                return [];
              }

              const selectableOption = {
                ...option,
                selected: accountType === option.value,
              };

              // Guest Member will not behave the same as normal memebers
              // That's why its descsription is different
              if (option.value === 4 && props.guestMode) {
                selectableOption.description = 'Can view schedule';
              }

              return [selectableOption];
            })}
            onChange={({ value: accountType }) => {
              const nextFormState = getStateDeltaOnAccessChange({
                currentUser,
                user,
                departmentOptions,
                account,
                accountType,
              });

              props.onChange(nextFormState);
            }}
            errors={formErrors.account_type}
          />
        )}
      </ModalTopGreySection>
      <PersonAccessControls
        guestMode={props.guestMode}
        initialPerson={initialPerson}
        account={account}
        formErrors={formErrors}
        onChange={(updates) => {
          const nextAccount = omit(
            {
              ...account,
              ...updates,
            },
            ['name', 'email', 'message'],
          );

          const isLimitedByDepartment = !!nextAccount.department_filter_set;
          if (isLimitedByDepartment) {
            // Ensure that user department stays visible to the user

            const minDepartmentIds =
              nextAccount.force_department_filter ||
              (user?.department_id ? [user?.department_id] : null);
            if (minDepartmentIds) {
              const allCurrentViewableDepartments = new Set(
                nextAccount.department_filter.flatMap((depId) => [
                  depId,
                  ...(subdepartments[depId] ? subdepartments[depId] : []),
                ]),
              );

              minDepartmentIds.forEach((minDepId) => {
                const isAlreadyThere =
                  allCurrentViewableDepartments.has(minDepId);
                if (!isAlreadyThere) {
                  nextAccount.department_filter.unshift(minDepId);
                }
              });

              // remove all-sub departments their parent departments
              // are also in the list
              const subDepartmentsToDelete = new Set();
              nextAccount.department_filter.forEach((depId) => {
                if (subdepartments[depId]) {
                  subdepartments[depId].forEach((subDepId) => {
                    subDepartmentsToDelete.add(subDepId);
                  });
                }
              });

              nextAccount.department_filter =
                nextAccount.department_filter.filter((depId) => {
                  return !subDepartmentsToDelete.has(depId);
                });
            }

            // Ensure that we remove managed departments
            // if that department is not visible anymore
            const managed = nextAccount.management_group;
            const isManagingDepartments = managed?.departments.length > 0;
            if (isManagingDepartments) {
              managed.departments = managed.departments.filter((departmentId) =>
                nextAccount.department_filter.includes(departmentId),
              );
            }
          }

          props.onChange(nextAccount);
        }}
      />
    </>
  );
};

export default function renderPersonAccess(that) {
  self = that;

  const { formErrors, form } = self.state;
  const account = form.account || {};
  const isNew = !self.isExistingAccount();
  const isAccountOwner = account?.account_type === 1;

  return (
    <>
      <PersonAccess
        formErrors={formErrors}
        initialPerson={self.initialPerson}
        user={form}
        account={account}
        isNew={!self.isExistingAccount()}
        onChange={(nextAccount) => self.setFormState({ account: nextAccount })}
      />
      {!isNew && !isAccountOwner && !account.accepted && (
        <>
          <Spacer size={32} />
          <SecondaryText>
            Invited on {formatDate(parseISO(account.created), 'dd MMM yyyy')}.
            <EH.Buttons.TextButton
              appearance="flay-underline"
              style={{ marginLeft: 10 }}
              onClick={resendInvite}
            >
              Resend invite
            </EH.Buttons.TextButton>
          </SecondaryText>
        </>
      )}
    </>
  );
}
