import { measureInteractivity } from '@float/web/perfMonitoring/interactivity';
import type { EditTaskModal } from 'components/schedule/task/EditTaskModal';

function getTrackingID(modal: EditTaskModal) {
  // @ts-expect-error The types are inferred from js
  if (modal.state.isNewTask) {
    if (modal.isTimeoffMode()) {
      return 'CREATE_TIME_OFF';
    }

    if (modal.isStatusMode()) {
      return 'CREATE_STATUS';
    }

    return 'CREATE_TASK';
  }

  if (modal.isTimeoffMode()) {
    return 'UPDATE_TIME_OFF';
  }

  if (modal.isStatusMode()) {
    return 'UPDATE_STATUS';
  }

  return 'UPDATE_TASK';
}

export function trackTaskModalInteractivity(modal: EditTaskModal) {
  const trackingId = getTrackingID(modal);

  measureInteractivity.start(trackingId);

  return () => {
    measureInteractivity.complete(trackingId);
  };
}
