import React, { StrictMode } from 'react';
import { Trans } from '@lingui/macro';

import {
  Button,
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '@float/ui/deprecated';

import * as styles from './ModalCannotDeleteRole.css';

export type ModalCannotDeleteRoleProps = {
  roleName: string;
  onClose: () => void;
};

export const ModalCannotDeleteRole = (props: ModalCannotDeleteRoleProps) => {
  const { roleName, onClose } = props;

  return (
    <StrictMode>
      <Modal isOpen={true} onEnter={onClose} onClose={onClose}>
        <ModalHeader>
          <ModalTitle style={{ fontSize: 22 }}>
            <Trans>Delete Role</Trans>
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p className={styles.confirmation}>
            <strong>{roleName}</strong>{' '}
            <Trans>
              cannot be deleted because there are people assigned to it. To move
              people to another Role, go to the People page, select the people
              you wish to edit, and assign them a different Role.
            </Trans>
          </p>
        </ModalBody>
        <ModalActions>
          <Button onClick={onClose}>
            <Trans>Okay</Trans>
          </Button>
        </ModalActions>
      </Modal>
    </StrictMode>
  );
};
