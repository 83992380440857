import styled, { css } from 'styled-components';

import * as Animation from '@float/ui/deprecated/Earhart/Animation';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

export const StyledIcon = styled.div`
  --svg-icon-color: var(--list-item-label-color);

  transition-property: stroke, fill;
  transition-duration: ${Animation.Settings.Duration.Short};
  transition-timing-function: ${Animation.Settings.Easing.InOutCurve};
`;

export const StyledIconCheck = styled.div`
  ${Typography.Label14.R400};

  --svg-icon-color: var(--list-item-label-color);

  color: var(--list-item-label-color);

  opacity: 0;

  transform: scale(0);

  transition-property: transform, opacity;
  transition-duration: ${Animation.Settings.Duration.Short};
  transition-timing-function: ${Animation.Settings.Easing.InOutBounce};
`;

export const StyledLabel = styled.div`
  ${Typography.Label14.M500};

  flex: 1;

  color: var(--list-item-label-color);

  transition-property: color;
  transition-duration: ${Animation.Settings.Duration.Short};
  transition-timing-function: ${Animation.Settings.Easing.InOutCurve};
`;

export const StyledTitle = styled.div`
  ${Typography.Label16.SB600};
  color: ${Colors.FIN.Lt.Emphasis.High};
  padding: 8px;
  text-align: left;
`;

export const StyledHr = styled.div`
  border-top: 1px solid ${Colors.FIN.Lt.Stroke.Stroke2};
  margin: 0px 8px;
`;

export const StyledItem = styled.div<{
  withIcon?: boolean;
  withIconCheck?: boolean;
  withShortcut?: boolean;
  active?: boolean;
}>`
  --list-item-label-color: var(--list-item-default-label-color);
  --list-item-bg-color: var(--list-item-default-bg-color);

  position: relative;

  display: flex;
  justify-content: flex-start;

  height: 40px;

  padding-left: 10px;
  padding-right: 10px;

  ${({ withIcon }) =>
    withIcon &&
    css`
      padding-left: 8px;
    `}

  ${({ withIconCheck }) =>
    withIconCheck &&
    css`
      padding-right: 8px;
    `}

  &:after {
    content: '';

    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border-radius: 6px;

    background-color: var(--list-item-bg-color);

    transition-property: transform, background-color, opacity;
    transition-duration: ${Animation.Settings.Duration.Normal},
      ${Animation.Settings.Duration.Short}, ${Animation.Settings.Duration.Short};
    transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

    opacity: 0;

    transform: scale(0.9);

    z-index: 0;
  }

  ${StyledIcon},
  ${StyledLabel},
  ${StyledIconCheck} {
    position: relative;

    display: flex;
    align-items: center;

    z-index: 1;

    pointer-events: none;
  }

  ${StyledIcon} {
    margin-right: 12px;
  }

  ${StyledIconCheck} {
    margin-left: 12px;
  }

  ${({ active }) =>
    active &&
    css`
      --list-item-label-color: var(--list-item-hover-label-color);
      --list-item-bg-color: var(--list-item-default-bg-color);

      &:hover {
        --list-item-bg-color: var(--list-item-hover-bg-color);
      }

      &:active {
        --list-item-label-color: var(--list-item-pressed-label-color);
        --list-item-bg-color: var(--list-item-pressed-bg-color);
      }

      ${StyledIconCheck} {
        opacity: 1;
        transform: none;
      }
    `}

  ${({ withShortcut }) =>
    withShortcut &&
    css`
      ${StyledIconCheck} {
        width: 24px;
        justify-content: center;

        opacity: 1;
        transform: none;
      }
    `}

  // interaction states

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
      &:hover {
        --list-item-label-color: var(--list-item-hover-label-color);
        --list-item-bg-color: var(--list-item-hover-bg-color);

        &:after {
          opacity: 1;
          transform: none;
        }
      }

      &:active {
        --list-item-label-color: var(--list-item-pressed-label-color);
        --list-item-bg-color: var(--list-item-pressed-bg-color);

        &:after {
          opacity: 1;
          transform: none;
        }
      }
    `}
`;

export const StyledList = styled.ul<{
  minWidth?: string;
  appearance?: 'flay' | 'flue';
}>`
  display: flex;
  flex-direction: column;

  border: 0;
  border-radius: 10px;

  padding: 6px;
  margin: 0;

  list-style-type: none;

  z-index: 10002 !important;

  min-width: ${({ minWidth }) => minWidth};

  &,
  & * {
    box-sizing: border-box;
  }

  ${StyledItem} {
    & + ${StyledItem} {
      margin-top: 4px;
    }
    & + ${StyledHr} {
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }

  // appearances

  ${({ appearance }) =>
    appearance === 'flay' &&
    css`
      ${StyledItem} {
        --list-item-default-label-color: ${Colors.FIN.Lt.Emphasis.Medium};
        --list-item-default-bg-color: rgba(0, 0, 0, 0);

        --list-item-hover-label-color: ${Colors.FIN.Lt.Emphasis.High};
        --list-item-hover-bg-color: ${Colors.FIN.Lt.Surface.Surf4};

        --list-item-pressed-label-color: ${Colors.FIN.Lt.Emphasis.High};
        --list-item-pressed-bg-color: ${Colors.FIN.Lt.Surface.Surf6};

        --list-item-active-label-color: ${Colors.Primary.Flue.Light[11]};
        --list-item-active-bg-color: rgba(0, 0, 0, 0);
      }
    `}

  ${({ appearance }) =>
    appearance === 'flue' &&
    css`
      ${StyledItem} {
        --list-item-default-label-color: ${Colors.FIN.Lt.Emphasis.Medium};
        --list-item-default-bg-color: rgba(0, 0, 0, 0);

        --list-item-hover-label-color: ${Colors.Primary.Flue.Light[9]};
        --list-item-hover-bg-color: ${Colors.Primary.Flue.Light[4]};

        --list-item-pressed-label-color: ${Colors.Primary.Flue.Light[11]};
        --list-item-pressed-bg-color: ${Colors.Primary.Flue.Light[6]};

        --list-item-active-label-color: ${Colors.Primary.Flue.Light[11]};
        --list-item-active-bg-color: rgba(0, 0, 0, 0);
      }
    `}
`;
