import { FeatureFlagUpdatePayload } from '@float/libs/featureFlags/types';

export const getFeatureFlagChangeHandler =
  ({
    onGentleRefresh,
    onStrongRefresh,
  }: {
    onGentleRefresh: () => void;
    onStrongRefresh: () => void;
  }) =>
  (settings: FeatureFlagUpdatePayload) => {
    let strongRefreshRequired = false;
    let gentleRefreshRequired = false;

    Object.values(settings).forEach(({ current, previous }) => {
      if (previous === 'strong-refresh') {
        // Switching from 'strong-refresh' to any other state
        // should not show a prompt
        return;
      }

      if (current === 'gentle-refresh' && previous !== 'gentle-refresh') {
        gentleRefreshRequired = true;
        return;
      }

      if (current === 'strong-refresh') {
        strongRefreshRequired = true;
        return;
      }
    });

    if (strongRefreshRequired) {
      onStrongRefresh();
      return;
    }

    if (gentleRefreshRequired) {
      onGentleRefresh();
    }
  };
