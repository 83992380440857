import React from 'react';
import { t } from '@lingui/macro';
import { PanelType } from 'sidePanel/types';
import { useSidePanel } from 'sidePanel/useSidePanel';

import { useDeferredLoadingState } from './hooks/useDeferredLoadingState';
import { useGetTemplateData } from './hooks/useGetTemplateData';
import { useHasChangedOnce } from './hooks/useHasChangedOnce';
import { useProjectFormValues } from './hooks/useProjectFormValues';
import { useSidePanelSnackbar } from './hooks/useSidePanelSnackbar';
import { useTemplateSave } from './hooks/useTemplateSave';
import { ProjectPanelView } from './ProjectPanelView';
import { ProjectFormData, TemplateData } from './types';

export const TemplatePanelController: React.FC = () => {
  const { sidePanelOpen, hideSidePanel, showTemplateSidePanel, panelPayload } =
    useSidePanel<TemplateData>(PanelType.TemplatePanel);
  const { showSnackbar, showSuccessSnackbar } = useSidePanelSnackbar();

  const templateId = panelPayload?.templateId;
  const { data, isLoading } = useGetTemplateData(panelPayload);
  const values = useProjectFormValues(data);

  const templateSave = useTemplateSave();

  const isEditing = Boolean(templateId);

  async function handleSubmit({
    project,
    team,
    tasks,
    milestones,
    phases,
  }: ProjectFormData) {
    const formData = { project, team, tasks, milestones, phases };
    const name = project.project_name;
    if (templateId) {
      try {
        await templateSave.handleUpdate(templateId, formData, values);
        showSuccessSnackbar(t`“${name}” updated.`, {
          onLinkClick: () => {
            showTemplateSidePanel(panelPayload);
          },
        });
      } catch (err) {
        showSnackbar(t`“${name}” update failed.`);
        throw err;
      }
    } else {
      try {
        const templateId = await templateSave.handleCreate(formData);
        showSnackbar(t`“${name}” added.`);
        showSuccessSnackbar(t`“${name}” added.`, {
          onLinkClick: () => {
            showTemplateSidePanel({
              ...panelPayload,
              projectId: undefined,
              templateId,
            });
          },
        });
      } catch (err) {
        showSnackbar(t`“${name}” creation failed.`);
        throw err;
      }
    }
  }

  const templateIdHasChanged = useHasChangedOnce(templateId);

  return (
    <ProjectPanelView
      key={templateId || 'new'} // Reset the component state when navigating templates
      animate={!templateIdHasChanged || !sidePanelOpen} // We don't want to animate when navigating templates
      isOpen={sidePanelOpen}
      isEditing={isEditing}
      isLoading={useDeferredLoadingState(isLoading)}
      values={values}
      onClose={hideSidePanel}
      onSubmit={handleSubmit}
    />
  );
};
