import React from 'react';
import { format } from 'date-fns';

import { parseFloatDate } from '@float/libs/dates';

import { ACTIVE_MONTH_INDICATOR_OFFSET_LEFT } from '../Cycles.constants';

import * as styles from '../Cycles.css';

export type CycleActiveMonthIndicatorProps = {
  activeMonth: DateString;
  leftStickyBoundary: number;
};

export const CycleActiveMonthIndicator: React.FC<
  CycleActiveMonthIndicatorProps
> = ({ activeMonth, leftStickyBoundary }) => {
  return (
    <div
      style={{
        left: leftStickyBoundary + ACTIVE_MONTH_INDICATOR_OFFSET_LEFT,
      }}
      className={styles.cycleActiveMonthStickyWrapper}
    >
      <div className={styles.cycleActiveMonthTopOffsetWrapper}>
        <span className={styles.cycleActiveMonthIndicator}>
          {format(parseFloatDate(activeMonth), 'MMM yyyy')}
        </span>
      </div>
    </div>
  );
};
