import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { Account, Department, Person, Role } from '@float/types';

export const getAccounts = (state: ReduxStateStrict) => state.accounts.accounts;

export const getPeopleMapRaw = (state: ReduxStateStrict) => state.people.people;

export const isActiveAndNotSelf = (
  managerAccount?: { account_id?: Account['account_id'] },
  person?: Person,
) => {
  if (!person || !managerAccount) {
    return false;
  }

  const isSelf =
    Boolean(person.account_id) &&
    person.account_id === managerAccount?.account_id;

  const isActive = !!person?.active;
  return !isSelf && isActive;
};

export const getDepartmentForPerson = (
  person: Person,
  departments: Record<number, Department>,
): Person['department'] => {
  const dept = person.department_id
    ? departments[person.department_id]
    : undefined;
  if (dept) {
    return {
      department_id: person.department_id,
      name: dept.name,
    };
  }
  return person.department || null;
};

export const getRoleForPerson = (
  person: Person,
  roles: Record<number, Role>,
): Person['role'] | null => {
  if (person.role_id && roles[person.role_id]) {
    return {
      role_id: person.role_id,
      name: roles[person.role_id].name,
      default_hourly_rate: roles[person.role_id].default_hourly_rate,
    };
  }
  return null;
};

export const getHavePeopleBeenLoaded = (state: ReduxStateStrict) =>
  state.people.loadState === 'LOADED';

export const getHavePeopleWithContextBeenLoaded = (state: ReduxStateStrict) =>
  state.search.contextState === 'LOADED' && state.people.loadState === 'LOADED';
