import { createSelector } from 'reselect';

import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { getUser } from '@float/common/selectors/currentUser';
import { createSelectorWithShallowEqualSetResultCheck } from '@float/common/selectors/lib/createSelectorWithShallowEqualSetResultCheck';
import { getPeopleMapRaw } from '@float/common/selectors/people';
import { getProjects } from '@float/common/selectors/projects';
import { getMeFilter } from '@float/common/selectors/search';
import { getActiveFilters } from '@float/common/selectors/views';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { Person, Phase } from '@float/types';

import { SearchReducerContext } from '../../search/types';
import { getSearchResults } from '../../store/searchResults/searchResults.selectors';
import { forProjects } from '../core';
import { isAllowedToViewProject } from '../permissions/isAllowedToViewProject';
import { getSearchDerivedContext } from './derivedContext';

const selectAccessibleProjectsList = createSelector(
  [getProjects, getUser, getPeopleMapRaw],
  (projects, user, people) =>
    projects.filter((project) =>
      isAllowedToViewProject({ user, people }, project),
    ),
);

const selectAccessibleProjectsIdsSet = createSelector(
  [selectAccessibleProjectsList],
  (projects) => new Set(projects.map((project) => project.project_id)),
);

// OPTIMIZATION: When the results of consecutive calls are equals
// keep the same referential identity to reduce the recoputations of the consumers
const selectSearchFilteredProjectsIdsFromSearchCore =
  createSelectorWithShallowEqualSetResultCheck(
    [
      getUser,
      getSearchDerivedContext,
      getActiveFilters,
      getMeFilter,
      (state: ReduxStateStrict) => state.clients.clients,
      getPeopleMapRaw,
      (state: ReduxStateStrict) => state.projects.projects,
      (state: ReduxStateStrict) => state.departments.departments,
      selectAccessibleProjectsList,
      (state: ReduxStateStrict) => state.accounts.accounts,
      (state: ReduxStateStrict) => state.phases.phases,
      (state: ReduxStateStrict) => state.tasks.tasks,
    ],
    (
      user,
      search: SearchReducerContext,
      filters,
      me: boolean,
      clients,
      people: Record<number, Person>,
      projects,
      departments,
      projectsArray,
      accounts,
      phases: Record<number, Phase>,
      tasks,
    ) => {
      const context = {
        user,
        clients,
        search,
        people,
        departments,
        projects,
        me,
        accounts,
        phases,
        tasks,
      };

      return forProjects(context, projectsArray, filters);
    },
  );

const selectSearchFilteredProjectsIdsFromSearchWorkerResults =
  createSelectorWithShallowEqualSetResultCheck(
    [getSearchResults],
    (searchResults) => {
      // Check on the searchResults state
      // It is undefined when the search is processed with selectors (Search worker disabled)
      // or when this selector is executed inside the Search worker
      if (searchResults) {
        return searchResults.projects;
      }

      return null;
    },
  );

const selectSearchFilteredProjectsIdsFromSearchResolve =
  createSelectorWithShallowEqualSetResultCheck(
    [getActiveFilters, getSearchResults, selectAccessibleProjectsIdsSet],
    (filters, searchResults, accessibleProjectsSet) => {
      if (filters.length && searchResults) {
        // ACL checks are not executed by SBL
        // So we intersect the SBL results with our ACL results
        return accessibleProjectsSet.intersection(searchResults.projects);
      }

      // If there are no filters we want to return all the projects
      // that the user can read
      return accessibleProjectsSet;
    },
  );

// OPTIMIZATION: Don't use `createSelector` here to run only the selector that we need
// instead of running al the dependecies eagerly
export const getSearchFilteredProjectsIds = (state: ReduxStateStrict) => {
  if (
    // On the WebWorker the featureFlags are not available
    // and we don't need to check the FF there because with SBL
    // the search won't run there
    featureFlags.isReady &&
    featureFlags.isFeatureEnabled(FeatureFlag.SearchBeyondLimits)
  ) {
    return selectSearchFilteredProjectsIdsFromSearchResolve(state);
  }

  const searchWorkerResults =
    selectSearchFilteredProjectsIdsFromSearchWorkerResults(state);

  if (searchWorkerResults) {
    return searchWorkerResults;
  }

  return selectSearchFilteredProjectsIdsFromSearchCore(state);
};

export const getSearchFilteredProjects = createSelector(
  [getSearchFilteredProjectsIds, getProjects],
  (ids, projects) => {
    return projects.filter((project) => ids.has(project.project_id));
  },
);
