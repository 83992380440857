import React, { useId, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { t } from '@lingui/macro';

import { GroupedOptions } from '@float/types/options';
import { VirtualSelect } from '@float/ui/deprecated';
import { useAddDepartment } from '@float/web/components/panelViews/Project/fields/ProjectTeamMemberField.hooks';
import { useSetPersonDefaultHourlyRate } from '@float/web/components/panelViews/Project/hooks/useSetPersonDefaultHourlyRate';

export const TeamMemberField = ({
  onChange,
  groupedOptions,
}: {
  onChange: (e?: React.BaseSyntheticEvent) => Promise<void>;
  groupedOptions: GroupedOptions<number>[];
}) => {
  const id = useId();
  const ref = useRef<HTMLDivElement>(null);

  const addDepartment = useAddDepartment(onChange);
  const { setPersonDefaultHourlyRate } = useSetPersonDefaultHourlyRate();

  return (
    <Controller
      name={'team.0.people_id'}
      render={({ field }) => (
        <VirtualSelect
          // @ts-expect-error VirtualSelect doesn't have types
          autoFocus
          forcedOpen
          size="medium"
          visibleItems={5}
          optionsPlacement="bottom"
          containerRef={ref}
          avoidCollisions
          optionsOverflowEllipse
          placeholder={t`Search team members`}
          groupedOptions={groupedOptions}
          value={field.value}
          onChange={({ value }: typeof field.value) => {
            field.onChange(value ?? null);
            setPersonDefaultHourlyRate(0, value);
            onChange();
          }}
          onInputBlur={field.onBlur}
          inputId={id}
          onSelectGroupAll={addDepartment}
          style={{ width: '244px' }}
        />
      )}
    />
  );
};
