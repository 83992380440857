import React from 'react';

import { Button } from '@float/ui/deprecated';

const UploadButton = ({
  label = 'Upload CSV file',
  icon,
  loader,
  onChange,
}) => {
  return (
    <Button
      appearance="secondary"
      as="label"
      icon={icon}
      loader={loader}
      disabled={loader}
    >
      {label}
      {!loader && (
        <input
          name="file"
          accept="text/csv"
          type="file"
          className="hidden"
          onChange={onChange}
        />
      )}
    </Button>
  );
};

export default UploadButton;
