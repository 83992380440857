import { toUiFormat } from '@floatschedule/js-common-npm/dist/lib/notes';
import { Descendant, Node } from 'slate';

import { truncateAt } from '@float/libs/utils/strings';
import { NotesMeta } from '@float/types';

import { toNodesFrom } from '../plugins/utils/nodes';

export function getInitialValue(
  value: string | null,
  valueMeta: NotesMeta | null,
  maxLength: number,
): (Descendant | { type: string; children: Node[] })[] {
  if (value && Array.isArray(valueMeta) && valueMeta.length > 0) {
    return toUiFormat({ notes: value, notes_meta: valueMeta });
  }

  if (!value || typeof value === 'string') {
    const text = value ? truncateAt(value, maxLength) : '';
    const nodes = toNodesFrom(text);

    return [
      {
        type: 'paragraph',
        children: nodes,
      },
    ];
  }

  return value;
}
