import { Person } from '@float/types/person';
import { Task } from '@float/types/task';

export function getPriority(entity: Task, person: Person) {
  if (entity.priority_info) {
    const personId = person.people_id;

    if (entity.priority_info[personId]) {
      return entity.priority_info[personId];
    }
  }

  return 0;
}
