import React, {
  ChangeEvent,
  CSSProperties,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import { Rights } from '@float/common/lib/acl';
import { getUser } from '@float/common/selectors/currentUser';
import { TaskMetaOption } from '@float/common/selectors/taskMetas';
import { useAppSelectorStrict } from '@float/common/store';
import { Project } from '@float/types';
import VirtualSelect from '@float/ui/deprecated/VirtualSelect/VirtualSelect';
import { VirtualSelectOption } from '@float/ui/deprecated/VirtualSelect/VirtualSelect.types';

import { TaskNameLabel } from './TaskNameLabel';
import { groupTaskNameOptions } from './TaskNameSelect.helpers';

export type TaskNameSelectOnChangeParams = {
  isCreate?: boolean;
  taskName: string;
  taskMetaId: number | null;
  nonBillable: boolean;
};

export type TaskNameSelectProps = {
  isFirstTask?: boolean;
  autoFocus?: boolean;
  onChange: (option: TaskNameSelectOnChangeParams) => void;
  onInputChange?: (value: string) => void;
  onInputMouseDown?: () => void;
  onBlur?: () => void;
  project?: Project;
  onEnter?: () => void;
  taskErrors?: unknown;
  taskNames?: TaskMetaOption[];
  value: string;
  style?: CSSProperties;
  placeholder?: string;
  hideDropdownIcon?: boolean;
};

export type TaskNameSelectRef = { focus: () => void };

export const TaskNameSelect = forwardRef<
  TaskNameSelectRef,
  TaskNameSelectProps
>((props, forwardedRef) => {
  const {
    isFirstTask,
    autoFocus,
    onBlur,
    onChange,
    onInputChange,
    onInputMouseDown,
    project,
    onEnter,
    taskErrors,
    taskNames,
    value,
    placeholder,
    hideDropdownIcon,
  } = props;

  const isLocked = project?.locked_task_list === 1;

  const isNullable =
    !isLocked || taskNames?.find((taskMeta) => taskMeta.task_name === '');

  const user = useAppSelectorStrict(getUser);

  const canCreateTaskMeta = Rights.canCreateTaskMeta(user, { project });

  const options = useMemo(() => groupTaskNameOptions(taskNames), [taskNames]);

  const ref = useRef<HTMLDivElement>(null);

  useImperativeHandle(forwardedRef, () => ({
    focus() {
      ref.current?.querySelector('input')?.focus();
    },
  }));

  function handleInputChange({ target }: ChangeEvent<HTMLInputElement>) {
    if (canCreateTaskMeta && onInputChange) {
      onInputChange(target.value);
    }
  }

  function handleChange(option: VirtualSelectOption) {
    const taskMeta = taskNames?.find((item) => item.task_name === option.label);

    onChange({
      isCreate: Boolean(option.isCreate),
      taskName: option.label,
      taskMetaId: taskMeta?.task_meta_id || null,
      nonBillable: taskMeta?.billable === 0,
    });
  }

  return (
    <VirtualSelect
      ref={ref}
      // @ts-expect-error The VirtualSelect are inferred from js and height is missing
      label={<TaskNameLabel hidden={isFirstTask} locked={isLocked} />}
      largeText
      hideClearIcon={!isNullable}
      creatable={canCreateTaskMeta}
      clearInputOnDropdownOpen={false}
      style={props.style}
      value={value}
      groupedOptions={options}
      visibleItems={6}
      onInputChange={handleInputChange}
      onChange={handleChange}
      onEnter={onEnter}
      onInputMouseDown={onInputMouseDown}
      errors={taskErrors}
      autoFocus={autoFocus ?? !value}
      onInputBlur={onBlur}
      placeholder={placeholder}
      height={0}
      hideDropdownIcon={hideDropdownIcon || options.length === 0}
    />
  );
});
