import React from 'react';
import cn from 'classnames';

import { getPaddingVariant } from './helpers';
import { NavIconBtnProps } from './types';

import * as styles from './styles.css';

export const NavIconBtn = React.forwardRef<HTMLButtonElement, NavIconBtnProps>(
  (
    {
      children,
      icon,
      iconLeft,
      iconRight,
      isPrimary,
      noBorder,
      className,
      ...props
    },
    ref,
  ) => {
    const padding = getPaddingVariant({ icon, iconLeft, iconRight });

    const content = icon ? (
      icon
    ) : (
      <>
        {iconLeft}
        {children}
        {iconRight}
      </>
    );

    return (
      <button
        {...props}
        ref={ref}
        className={cn(
          className,
          styles.navButton({
            color: isPrimary ? 'primary' : 'default',
            background: isPrimary ? 'primary' : 'default',
            border: isPrimary || noBorder ? 'transparent' : 'default',
            padding,
          }),
        )}
      >
        {content}
      </button>
    );
  },
);
