import { moment } from '@float/libs/moment';

import { formatTimeoffId } from '../lib/formatters';
import request from '../lib/request';
import { cleanUpRepeatState, replaceTaskSuccess } from './tasks';
import {
  CREATE_TIMEOFF,
  CREATE_TIMEOFF_FAILURE,
  CREATE_TIMEOFF_SUCCESS,
  DELETE_TIMEOFF,
  DELETE_TIMEOFF_FAILURE,
  DELETE_TIMEOFF_SUCCESS,
  FETCH_TIMEOFFS,
  FETCH_TIMEOFFS_FAILURE,
  FETCH_TIMEOFFS_SUCCESS,
  INSERT_TIMEOFF,
  INSERT_TIMEOFF_FAILURE,
  INSERT_TIMEOFF_SUCCESS,
  REPLACE_TIMEOFF,
  REPLACE_TIMEOFF_FAILURE,
  REPLACE_TIMEOFF_SUCCESS,
  UPDATE_TIMEOFF,
  UPDATE_TIMEOFF_FAILURE,
  UPDATE_TIMEOFF_SUCCESS,
} from './timeoffsConstants';

const DATE_FORMAT = 'YYYY-MM-DD';

const formatDate = (date) => moment(date).format(DATE_FORMAT);

const formatTimeoffForRequest = (timeoff) => {
  const notes = timeoff.timeoff_notes || '';
  const notes_meta = timeoff.notes_meta || null;
  // TODO: optimize - if hours equals user's work day hours,
  // set full_day regardless (higher up the chain)
  const hours = timeoff.full_day ? undefined : parseFloat(timeoff.hours);

  // http://dev.float.com/api_reference.html#!/Time_Off/updateTimeoff
  const res = {
    timeoff_type_id: timeoff.timeoff_type_id,
    start_date: formatDate(timeoff.start_date),
    end_date: formatDate(timeoff.end_date),
    hours,
    timeoff_notes: notes,
    notes_meta,
    repeat_state: timeoff.repeat_state,
    priority: timeoff.priority,
    priority_info: timeoff.priority_info || {},
    repeat_end: timeoff.repeat_end_date
      ? formatDate(timeoff.repeat_end_date)
      : null,
    full_day: +timeoff.full_day,
    people_ids: timeoff.people_ids,
    start_time: timeoff.start_time,
    status: timeoff.status || 2, // Convert 0 to 2 - confirmed
    status_creator_id: timeoff.status_creator_id,
    status_note: timeoff.status_note,
  };

  // We want to force status_request for second part of the splitted timeoff.
  if (timeoff.isSplitting && timeoff.temporaryId) {
    res.status_request = timeoff.status_request;
  }

  if (res.repeat_end <= res.end_date) {
    res.repeat_state = 0;
    delete res.repeat_end;
  }

  Object.keys(res.priority_info).forEach((personId) => {
    if (!res.people_ids.some((id) => id == personId)) {
      delete res.priority_info[personId];
    }
  });

  return res;
};

const formatTimeoffForCreateRequest = (timeoff) => {
  const payload = formatTimeoffForRequest(timeoff);
  delete payload.timeoff_id;
  return payload;
};

const failedFetchedTimeoffs = (error) => ({
  type: FETCH_TIMEOFFS_FAILURE,
  error,
});

export const fetchTimeoffs = () => ({
  type: FETCH_TIMEOFFS,
});

export const fetchedTimeoffs = (items, rebuild) => ({
  type: FETCH_TIMEOFFS_SUCCESS,
  items,
  rebuild,
});

export const fetchTimeoffsWithDates =
  (start, end, rebuild) => async (dispatch) => {
    dispatch(fetchTimeoffs());

    try {
      const response = await request.get(
        'timeoffs/all',
        {
          start_date:
            typeof start === 'string' ? start : start.format('YYYY-MM-DD'),
          end_date: typeof end === 'string' ? end : end.format('YYYY-MM-DD'),
        },
        { version: 'f3', includeHeaders: true },
      );

      dispatch(fetchedTimeoffs(response.data, rebuild));
    } catch (error) {
      dispatch(failedFetchedTimeoffs(error));
    }
  };

const startCreateTimeoff = (timeoff) => ({
  type: CREATE_TIMEOFF,
  item: timeoff,
});

const createTimeoffSuccess = (timeoff, undoBatchId) => ({
  type: CREATE_TIMEOFF_SUCCESS,
  item: timeoff,
  undoBatchId,
});

const createTimeoffFailure = (timeoff) => ({
  type: CREATE_TIMEOFF_FAILURE,
  item: timeoff,
});

export const generateId = () => {
  return `${Date.now()}${Math.random()}`;
};

export const createTimeoff =
  (timeoff, { undoBatchId } = {}) =>
  (dispatch, getState) => {
    const createdTs = timeoff.temporaryId || generateId();
    // const state = getState();
    // const peopleTasksMap = getPeopleTasksMap(state);
    // const user = getUser(state);
    // timeoff = updateToPartDayIfNeeded(timeoff, peopleTasksMap, user);

    const optimisticInstance = { ...timeoff, timeoff_id: createdTs, createdTs };
    dispatch(startCreateTimeoff(optimisticInstance));
    return request
      .post('timeoffs', formatTimeoffForCreateRequest(timeoff), {
        version: 'f3',
      })
      .then(
        (response) => {
          const item = { ...response, createdTs };
          dispatch(createTimeoffSuccess(item, undoBatchId));
        },
        (error) => {
          dispatch(createTimeoffFailure(optimisticInstance));
          return Promise.reject(error);
        },
      );
  };

const startUpdateTimeoff = (item, previous) => ({
  type: UPDATE_TIMEOFF,
  item,
  previous,
});

const updateTimeoffSuccess = (item, previous, undoBatchId) => ({
  type: UPDATE_TIMEOFF_SUCCESS,
  item,
  previous,
  undoBatchId,
});

const updateTimeoffFailure = (error, timeoff, previous) => ({
  type: UPDATE_TIMEOFF_FAILURE,
  error,
  item: timeoff,
  previous,
});

export const updateTimeoff =
  (timeoff, { originalEntity, taskWasntUpdated = false, undoBatchId } = {}) =>
  async (dispatch, getState) => {
    const id = formatTimeoffId(timeoff);
    const state = getState();
    const previous =
      originalEntity ||
      state.timeoffs.timeoffs[parseInt(timeoff.timeoff_id, 10)];
    // const peopleTasksMap = getPeopleTasksMap(state);
    // const user = getUser(state);

    timeoff = cleanUpRepeatState(timeoff);

    // TODO: this is temporary since full day handling will change after
    //  execution of this - https://app.asana.com/0/1124931775288939/1128241059864507/f
    // const skipIntersectingTasksCheck =
    //   ['start_date', 'end_date'].every(key =>
    //     previous[key].isSame(timeoff[key], 'day'),
    //   ) && isEqual(previous.people_ids, timeoff.people_ids);
    // timeoff = updateToPartDayIfNeeded(
    //   timeoff,
    //   peopleTasksMap,
    //   user,
    //   skipIntersectingTasksCheck,
    // );

    dispatch(startUpdateTimeoff(timeoff, previous));
    const reqPromise = !taskWasntUpdated
      ? request.put(`timeoffs/${id}`, formatTimeoffForRequest(timeoff), {
          version: 'f3',
        })
      : Promise.resolve(timeoff);
    return reqPromise.then(
      (response) =>
        dispatch(
          updateTimeoffSuccess(
            {
              ...response,
              priority_info: timeoff.priority_info,
            },
            previous,
            undoBatchId,
          ),
        ),
      (error) => {
        dispatch(updateTimeoffFailure(error, timeoff, previous));
        return Promise.reject(error);
      },
    );
  };

const deleteTimeoffBegin = (item) => ({
  type: DELETE_TIMEOFF,
  item,
});

const deleteTimeoffSuccess = (item) => ({
  type: DELETE_TIMEOFF_SUCCESS,
  item,
});

const deleteTimeoffFailure = (item) => ({
  type: DELETE_TIMEOFF_FAILURE,
  item,
});

export const deleteTimeoff = (timeoff) => (dispatch) => {
  dispatch(deleteTimeoffBegin(timeoff));
  return request
    .del(`timeoffs/${timeoff.timeoff_id}`, null, { version: 'f3' })
    .then(
      () => dispatch(deleteTimeoffSuccess(timeoff)),
      () => dispatch(deleteTimeoffFailure(timeoff)),
    );
};

const insertOrReplaceTimeoff = (timeoff, mode) => {
  return request.post(
    `schedule/${mode}/timeoff`,
    formatTimeoffForRequest(timeoff),
    {
      version: 'f3',
    },
  );
};

const insertTimeoffBegin = (timeoff) => ({
  type: INSERT_TIMEOFF,
  timeoff,
});

const insertTimeoffSuccess = (response, item) => ({
  type: INSERT_TIMEOFF_SUCCESS,
  response,
  item,
});

const insertTimeoffFailure = (error, onError) => {
  if (typeof onError === 'function') {
    onError();
  }
  return { type: INSERT_TIMEOFF_FAILURE, error };
};

export const insertTimeoff = (timeoff, onError) => (dispatch) => {
  dispatch(insertTimeoffBegin(timeoff));
  return insertOrReplaceTimeoff(timeoff, 'insert', onError).then(
    (response) => dispatch(insertTimeoffSuccess(response, timeoff)),
    (error) => {
      dispatch(insertTimeoffFailure(error, onError));
      return Promise.reject(error);
    },
  );
};

const replaceTimeoffBegin = (timeoff) => ({
  type: REPLACE_TIMEOFF,
  timeoff,
});

const replaceTimeoffSuccess = (response, item) => ({
  type: REPLACE_TIMEOFF_SUCCESS,
  response,
  item,
});

const replaceTimeoffFailure = (error, onError) => {
  if (typeof onError === 'function') {
    onError();
  }
  return { type: REPLACE_TIMEOFF_FAILURE, error };
};

export const replaceTimeoff = (timeoff, onError) => (dispatch, getState) => {
  dispatch(replaceTimeoffBegin(timeoff));

  const state = getState();
  const previous = state.timeoffs.timeoffs[parseInt(timeoff.timeoff_id, 10)];
  return insertOrReplaceTimeoff(timeoff, 'replace', onError).then(
    (response) => {
      // If no items were deleted when creating this timeoff, then it is
      // equivalent to just creating a single full day timeoff. The reason
      // behind handling this is to support undo for creating a single full
      // day timeoff (whereas a replace operation affecting other items cannot
      // be undone).
      if (!response.delete) {
        const createdTimeoff = response.timeoff[0];
        createdTimeoff.previous = previous;
        dispatch(createTimeoffSuccess(response.timeoff[0]));
        dispatch(replaceTaskSuccess(response, timeoff));
      } else {
        dispatch(replaceTimeoffSuccess(response, timeoff));
      }
    },
    (error) => {
      dispatch(replaceTimeoffFailure(error, onError));
      return Promise.reject(error);
    },
  );
};

export const fetchTimeoff = (id) => (dispatch) => {
  return request
    .get(`timeoffs/${id}`, null, { version: 'f3' })
    .then((timeoff) => {
      dispatch(fetchedTimeoffs([timeoff], true));
      return timeoff;
    });
};
