import React from 'react';

export function ExpandSmall() {
  return (
    <svg
      width="10px"
      height="14px"
      viewBox="0 0 10 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          className="stroke"
          transform="translate(1.000000, 1.000000)"
          stroke="#868D92"
          strokeWidth="1.5"
        >
          <path d="M8,4 L4,0 L0,4 M8,8 L4,12 L0,8"></path>
        </g>
      </g>
    </svg>
  );
}

export default function () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#868D92"
          strokeWidth="1.5"
          d="M16 10l-4-4-4 4M16 14l-4 4-4-4"
        />
      </g>
    </svg>
  );
}
