import { createAction } from '@reduxjs/toolkit';

import {
  getTimeRangeCacheKey,
  getTimeRangeMode,
  getTimeRangeOpts,
} from '@float/libs/timeRange';
import { TimeRange } from '@float/types/cells';

import { trackEvent } from '../lib/analytics';
import { ReduxStateStrict } from '../reducers/lib/types';
import { getTimeRangeInsightsData } from '../serena/Data/insights/getTimeRangeInsightsData';
import { AppDispatchStrict } from '../store';

export const updateTimeRange = (newRange: TimeRange) => {
  return async (_: AppDispatchStrict, getState: () => ReduxStateStrict) => {
    const key = getTimeRangeCacheKey(getState().currentUser);
    const { start_date, end_date } = newRange;
    const range_mode = (newRange.range_mode = getTimeRangeMode(newRange));
    const value = JSON.stringify({ start_date, end_date, range_mode });
    localStorage.setItem(key, value);

    const opt = getTimeRangeOpts().find((x) => x.value == range_mode);
    trackEvent('Time range preset set', { type: opt?.label || '' });
  };
};

export const removeTimeRange = () => {
  return async (_: AppDispatchStrict, getState: () => ReduxStateStrict) => {
    const key = getTimeRangeCacheKey(getState().currentUser);
    localStorage.removeItem(key);
    localStorage.setItem(key, JSON.stringify({ disabled: true }));
    trackEvent('Time range removed');
  };
};

export const insightsUpdated = createAction<
  ReturnType<typeof getTimeRangeInsightsData>,
  'INSIGHTS_UPDATED'
>('INSIGHTS_UPDATED');

export const insightsDisabled = createAction('INSIGHTS_DISABLED');
