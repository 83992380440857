import React from 'react';
import { Trans } from '@lingui/macro';

import { SidePanelTableHeaderRow } from '../../components/SidePanelTableSection/SidePanelTableHeaderRow';
import { SidePanelTableHeading } from '../../components/SidePanelTableSection/SidePanelTableHeading';

import * as styles from './styles.css';

export const ProjectMilestonesTableHeader = () => {
  return (
    <SidePanelTableHeaderRow className={styles.milestoneGridTemplateColumns}>
      <SidePanelTableHeading>
        <Trans>Milestone</Trans>
      </SidePanelTableHeading>
      <SidePanelTableHeading>
        <Trans>Date</Trans>
      </SidePanelTableHeading>
    </SidePanelTableHeaderRow>
  );
};
