import React, { useMemo } from 'react';
import { getPhasesMapRaw } from 'selectors';
import styled from 'styled-components';

import { useAppSelector } from '@float/common/store';
import { Phase } from '@float/types';
import { DropdownSelect } from '@float/ui/deprecated';

const PhaseColor = styled.span<{ value?: string }>`
  display: inline-block;

  width: 13px;
  height: 13px;

  min-width: 13px;
  min-height: 13px;

  border-radius: 3px;

  background-color: ${(p) => (p.value ? `#${p.value}` : p.theme.grey)};

  margin-right: 5px;
  margin-top: -2px;

  vertical-align: middle;
`;

const getPhaseName = (phase: Phase) => {
  if (phase) {
    if (!phase.active) {
      return `${phase.phase_name} (Archived)`;
    }

    return phase.phase_name;
  }

  return 'No phase';
};

const useDispayPhaseLabel = (phaseId: string | number) => {
  const phaseLabel = useAppSelector((state) => {
    const phases = getPhasesMapRaw(state);
    const phaseName = getPhaseName(phases[Number(phaseId)]);
    return phaseName;
  });

  return phaseLabel;
};

export type PhaseDropdownOption = {
  value: string | number;
  color?: string;
  label: string;
};

interface PhaseDropdownProps {
  options: PhaseDropdownOption[];
  value: PhaseDropdownOption['value'];
  projectColor: string;
  onChange: (option: PhaseDropdownOption) => void;
  style?: React.CSSProperties;
}

export default function PhaseDropdown(props: PhaseDropdownProps) {
  const { options = [], value, projectColor, onChange, style } = props;

  const phaseName = useDispayPhaseLabel(value);
  const phaseColor = useMemo(() => {
    const phaseOption = options?.find((o) => o.value == value);
    return phaseOption?.color || projectColor;
  }, [options, projectColor, value]);

  if (!options || !options.length) return null;

  return (
    <DropdownSelect
      appearance="text"
      style={style}
      value={value || 0}
      options={options}
      onChange={onChange}
      tickSelectedOption
      tickRight
      showDownIconWhenNoValue
      showChildrenAsSelectedLabel
      withIconLeft
      truncateAt={220}
    >
      <PhaseColor value={phaseColor} />
      {phaseName}
    </DropdownSelect>
  );
}
