import { isUndefined } from 'lodash';

import {
  ensureOnlyOneDirectManager,
  fetchPersonAccount,
} from '@float/common/actions/people';
import API3 from '@float/common/api3';
import { getAccountTypeToLabelMap } from '@float/constants/accounts';

import { getEntityById } from '../reducers/reducerHelpers';
import {
  addedEntityCreator,
  addEntityCreator,
  deletedEntityCreator,
  deleteEntityCreator,
  failedAddingEntityCreator,
  failedDeletingEntityCreator,
  failedFetchingEntitiesCreator,
  failedUpdatingEntityCreator,
  fetchAllEntitiesCreator,
  fetchedEntitiesCreator,
  fetchEntitiesCreator,
  shouldFetchEntitiesCreator,
  sortEntitiesCreator,
  updatedEntityCreator,
  updateEntityCreator,
} from './creatorHelpers';

export const ADD_ACCOUNT = 'ADD_ACCOUNT';
export const ADD_ACCOUNT_SUCCESS = 'ADD_ACCOUNT_SUCCESS';
export const ADD_ACCOUNT_FAILURE = 'ADD_ACCOUNT_FAILURE';

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE';

export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_FAILURE = 'UPDATE_ACCOUNT_FAILURE';

export const FETCH_ACCOUNTS = 'FETCH_ACCOUNTS';
export const FETCH_ACCOUNTS_SUCCESS = 'FETCH_ACCOUNTS_SUCCESS';
export const FETCH_ACCOUNTS_FAILURE = 'FETCH_ACCOUNTS_FAILURE';
export const CLEAR_FETCHED_ACCOUNTS = 'CLEAR_FETCHED_ACCOUNTS';

export const SORT_ACCOUNTS = 'SORT_ACCOUNTS';

const SINGULAR = 'account';
const PLURAL = 'accounts';
const ID_PROP = 'account_id';
const GUEST_SINGULAR = 'guest';

const extendSettingsAccountEntity = (entity, state, payload) => {
  const options = {
    defaultToGuest: true,
    ...(payload || {}),
  };

  const account = {
    ...entity,
    account_type_name: getAccountTypeToLabelMap()[entity.account_type],
    project_count: entity.project_count || 0,
  };

  if (options.defaultToGuest) {
    account.accepted = entity.accepted || null;
    account.guest = isUndefined(entity.guest) ? 1 : entity.guest;
  }

  return account;
};

const addedAccount = addedEntityCreator(
  ADD_ACCOUNT_SUCCESS,
  extendSettingsAccountEntity,
  true,
  SINGULAR,
  ID_PROP,
);

const failedAddingAccount = failedAddingEntityCreator(ADD_ACCOUNT_FAILURE);

export const addAccount = addEntityCreator(
  ADD_ACCOUNT,
  SINGULAR,
  addedAccount,
  failedAddingAccount,
);

const defaultUpdatedAccount = updatedEntityCreator(
  UPDATE_ACCOUNT_SUCCESS,
  extendSettingsAccountEntity,
  true,
  SINGULAR,
);

export const updatedAccount =
  (id, payload, inputData) => async (dispatch, getState) => {
    const update = await dispatch(
      defaultUpdatedAccount(id, payload, inputData),
    );

    // Note that we want to dispatch the account update first because if a
    // person has an account_id, we definitely want to be able to look it up.
    const nextAccount = await dispatch(fetchPersonAccount({ account_id: id }));
    ensureOnlyOneDirectManager(nextAccount, dispatch, getState);
    return update;
  };

const failedUpdatingAccount = failedUpdatingEntityCreator(
  UPDATE_ACCOUNT_FAILURE,
);

export const updateAccount = updateEntityCreator(
  UPDATE_ACCOUNT,
  SINGULAR,
  updatedAccount,
  failedUpdatingAccount,
);

export const updateGuest = updateEntityCreator(
  UPDATE_ACCOUNT,
  GUEST_SINGULAR,
  updatedAccount,
  failedUpdatingAccount,
);

const deletedAccount = deletedEntityCreator(DELETE_ACCOUNT_SUCCESS);

const failedDeletingAccount = failedDeletingEntityCreator(
  DELETE_ACCOUNT_FAILURE,
);

export const deleteAccount = deleteEntityCreator(
  DELETE_ACCOUNT,
  SINGULAR,
  PLURAL,
  deletedAccount,
  failedDeletingAccount,
  ID_PROP,
);

const fetchedAccounts = fetchedEntitiesCreator(
  FETCH_ACCOUNTS_SUCCESS,
  (entities, getState, page, queryParams, dispatch) => {
    return entities.map((entity) => ({
      ...entity,
      account_type_name: getAccountTypeToLabelMap()[entity.account_type],
      project_count: entity.project_count,
    }));
  },
);

const failedFetchingAccounts = failedFetchingEntitiesCreator(
  FETCH_ACCOUNTS_FAILURE,
);

const shouldFetchAccounts = shouldFetchEntitiesCreator(PLURAL);

export const fetchAccounts = fetchEntitiesCreator(
  FETCH_ACCOUNTS,
  PLURAL,
  shouldFetchAccounts,
  fetchedAccounts,
  failedFetchingAccounts,
  {
    expand: 'accepted,guest,project_count,management_group',
    'per-page': 500,
  },
);

export const sortAccounts = sortEntitiesCreator(SORT_ACCOUNTS, fetchAccounts);

export const fetchAllAccounts = fetchAllEntitiesCreator(PLURAL, fetchAccounts);

export const clearFetchedAccounts = () => {
  return {
    type: CLEAR_FETCHED_ACCOUNTS,
  };
};

export const resendAccountInvite = (id) => {
  return (dispatch, getState) => {
    // We use old-style /resendInvite action.
    const data = new FormData();
    data.append(ID_PROP, id);
    return API3.resendAccountInvite({ data }).then(() => ({
      id,
      entity: getEntityById(id, getState().settingsAccounts.entities, ID_PROP),
    }));
  };
};
