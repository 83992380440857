import { BaseActivity } from './activity';
import { Phase } from './phase';
import { Project } from './project';
import { RawTask } from './task';
import { Timeoff } from './timeoff';

export enum NotificationType {
  Task = 'task',
  Timeoff = 'timeoff',
  Project = 'project',
  Phase = 'phase',
}

export const NotificationTypeKeys = [
  'task',
  'timeoff',
  'project',
  'phase',
] as const;

export type NotificationTypes = (typeof NotificationTypeKeys)[number];

export type NotificationTypePayload = {
  task: RawTask;
  timeoff: Timeoff;
  project: Project;
  phase: Phase;
};

export type Notification<T extends NotificationTypes> = BaseActivity & {
  unseen: boolean;
} & (
    | {
        after_data: NotificationTypePayload[T];
        before_data: null;
        item_type: T;
        activity_type: 'create';
      }
    | {
        after_data: NotificationTypePayload[T];
        before_data: Partial<NotificationTypePayload[T]>;
        item_type: T;
        activity_type: 'update' | 'bulk_update';
      }
    | {
        before_data: NotificationTypePayload[T];
        after_data: null;
        item_type: T;
        activity_type: 'delete' | 'bulk_delete';
      }
  );

export type NotificationStateItem = {
  nf_id: string;
  af_id: string;
  seen: boolean;
};

export type NotificationsState = {
  hasMore: boolean;
  items: Record<string, NotificationStateItem>;
  loadState: 'UNLOADED' | 'LOADED' | 'LOAD_FAILED' | 'LOADING';
  new: number;
  page: number;
  pendingUpdate: {
    unread: boolean;
    items: Record<string, NotificationStateItem>;
  };
  unread: boolean;
};
