import { isNot, isOr } from '@float/common/search/helpers';
import {
  BaseFilterToken,
  FilterOperators,
  RawFilterOperators,
  RawViewFilter,
} from '@float/types';

export function toBaseOperator(
  operator?: RawFilterOperators | '',
): FilterOperators | '' {
  if (operator === 'nin') {
    return '-';
  }

  return '';
}

export function toRawOperator(
  operator?: BaseFilterToken['operator'],
): RawFilterOperators {
  if (operator?.startsWith('|')) {
    if (isNot(operator.slice(1))) {
      return 'nin';
    }
  }

  if (isNot(operator)) {
    return 'nin';
  }

  return 'in';
}

function toRawFilter(filter: BaseFilterToken): RawViewFilter {
  return {
    type: filter.type,
    operator: toRawOperator(filter.operator),
    values: Array.isArray(filter.val) ? filter.val : [filter.val],
  };
}

export function toRawFilters(filters: BaseFilterToken[]): RawViewFilter[] {
  const rawFilters: RawViewFilter[] = [];
  const annulledTypes = new Set<RawViewFilter['type']>();

  for (const filter of filters) {
    const rawFilter = toRawFilter(filter);

    if (isOr(filter.operator?.charAt(0))) {
      const prevRawFilter = rawFilters.find(({ type }) => type === filter.type);

      if (prevRawFilter) {
        // Mixing OR + different operators is not supported
        if (prevRawFilter.operator !== rawFilter.operator) {
          annulledTypes.add(rawFilter.type);
        } else {
          prevRawFilter.values.push(...rawFilter.values);
        }
      } else {
        rawFilters.push(rawFilter);
      }
    } else {
      rawFilters.push(rawFilter);
    }
  }

  return rawFilters.filter(
    ({ type, values }) => !annulledTypes.has(type) || values.length === 0,
  );
}
