export const FETCH_JIRA_CO_INTS = 'jira_integration/FETCH_CO_INTS';
export const FETCH_JIRA_CO_INTS_SUCCESS =
  'jira_integration/FETCH_CO_INTS_SUCCESS';
export const JIRA_IMPORT_SUCCESS = 'jira_integration/JIRA_IMPORT_SUCCESS';
export const FETCH_JIRA_CO_INTS_FAILURE =
  'jira_integration/FETCH_JIRA_CO_INTS_FAILURE';
export const FETCH_JIRA_PRELOAD = 'jira_integration/FETCH_JIRA_PRELOAD';
export const FETCH_JIRA_PRELOAD_SUCCESS =
  'jira_integration/FETCH_JIRA_PRELOAD_SUCCESS';
export const FETCH_JIRA_PRELOAD_FAILURE =
  'jira_integration/FETCH_JIRA_PRELOAD_FAILURE';
export const LINK_JIRA = 'jira_integration/LINK_JIRA';
export const LINK_JIRA_SUCCESS = 'jira_integration/LINK_JIRA_SUCCESS';
export const LINK_JIRA_FAILURE = 'jira_integration/LINK_JIRA_FAILURE';
export const FETCH_JIRA_CONFIG = 'jira_integration/FETCH_JIRA_CONFIG';
export const FETCH_JIRA_CONFIG_SUCCESS =
  'jira_integration/FETCH_JIRA_CONFIG_SUCCESS';
export const FETCH_JIRA_CONFIG_FAILURE =
  'jira_integration/FETCH_JIRA_CONFIG_FAILURE';
export const CLEAR_JIRA_PRELOAD = 'jira_integration/CLEAR_JIRA_PRELOAD';
export const UPDATE_JIRA_CONFIG = 'jira_integration/UPDATE_JIRA_CONFIG';
export const UPDATE_JIRA_CONFIG_SUCCESS =
  'jira_integration/UPDATE_JIRA_CONFIG_SUCCESS';
export const UPDATE_JIRA_CONFIG_FAILURE =
  'jira_integration/UPDATE_JIRA_CONFIG_FAILURE';
export const ADD_JIRA_CO_INT = 'jira_integration/ADD_JIRA_CO_INT';
export const ADD_JIRA_CO_INT_SUCCESS =
  'jira_integration/ADD_JIRA_CO_INT_SUCCESS';
export const ADD_JIRA_CO_INT_FAILURE =
  'jira_integration/ADD_JIRA_CO_INT_FAILURE';
export const REMOVE_JIRA_CO_INT = 'jira_integration/REMOVE_JIRA_CO_INT';
export const REMOVE_JIRA_CO_INT_SUCCESS =
  'jira_integration/REMOVE_JIRA_CO_INT_SUCCESS';
export const REMOVE_JIRA_CO_INT_FAILURE =
  'jira_integration/REMOVE_JIRA_CO_INT_FAILURE';
