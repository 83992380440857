import React from 'react';
import { useFormContext } from 'react-hook-form';
import { t } from '@lingui/macro';

import { IconArrowLeft } from '@float/ui/icons/essentials/IconArrowLeft';

import { DatePickerField } from '../components/DatePickerField';
import { DurationField } from '../components/DurationField';
import { useDateRange } from '../hooks/useDateRange';
import { useProjectOrPhaseFieldValue } from '../hooks/useProjectOrPhaseFieldValue';
import { ProjectFormData, ProjectMilestoneRecord } from '../types';

import * as styles from '../sections/ProjectMilestonesSection/styles.css';

type MilestoneDatesFieldProps = {
  container: HTMLElement | null;
  index: number;
  showingDateRange: boolean;
};

type Duration = ProjectMilestoneRecord['duration'];

export const MilestoneDatesField: React.FC<MilestoneDatesFieldProps> = ({
  container,
  index,
  showingDateRange,
}) => {
  const { getValues } = useFormContext<ProjectFormData>();
  const isActive = useProjectOrPhaseFieldValue('active');
  const startDate = `milestones.${index}.date` as const;
  const endDate = `milestones.${index}.end_date` as const;
  const duration = getValues(`milestones.${index}.duration`) as Duration;
  const dateRange = useDateRange({
    startKey: startDate,
    endKey: endDate,
  });

  const showDuration = duration !== undefined && duration !== null;
  if (showDuration) {
    return <DurationField duration={duration} />;
  }

  if (showingDateRange) {
    return (
      <div className={styles.milestoneDateFields}>
        <DatePickerField
          container={container!}
          name={startDate}
          label={t`Start date`}
          hideLabel
          onChange={dateRange.onStartChange}
          readOnly={!isActive}
        />
        <IconArrowLeft
          size={16}
          className={styles.milestoneDateIcon}
          data-disabled={!isActive}
        />
        <DatePickerField
          container={container!}
          name={endDate}
          label={t`End date`}
          hideLabel
          onChange={dateRange.onEndChange}
          readOnly={!isActive}
        />
      </div>
    );
  }

  const handleSingleDateChange = (value: string) =>
    dateRange.onStartChange(value, true);

  return (
    <DatePickerField
      container={container!}
      name={startDate}
      label={t`Start date`}
      hideLabel
      onChange={handleSingleDateChange}
      readOnly={!isActive}
    />
  );
};
