export enum Direction {
  Up = 'Up',
  Down = 'Down',
}

export const calculateNextIndex = (
  options: Array<Partial<{ disabled: boolean }>>,
  currentIndex: number,
  direction: Direction,
) => {
  const maxIterations = options.length;
  const maxIndex = options.length - 1;

  let index: number = 0;

  if (direction === Direction.Up) {
    index = Math.max(0, (currentIndex || maxIndex + 1) - 1);
  } else if (direction === Direction.Down) {
    index = (currentIndex + 1) % (maxIndex + 1);
  }

  let currentIteration = 0;

  while (options[index].disabled) {
    if (direction === Direction.Up) {
      index = Math.max(0, (index || maxIndex + 1) - 1);
    } else if (direction === Direction.Down) {
      index = (index + 1) % (maxIndex + 1);
    }

    currentIteration++;
    if (currentIteration >= maxIterations) {
      // Break out of the loop to prevent infinite iterations
      index = currentIndex;
      break;
    }
  }

  return index;
};
