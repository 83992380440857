import React from 'react';
import createClass from 'create-react-class';

import DateRangePickerBemMixin from '../utils/DateRangePickerBemMixin';
import PureRenderMixin from '../utils/PureRenderMixin';

const CalendarSelection = createClass({
  mixins: [DateRangePickerBemMixin, PureRenderMixin],
  displayName: 'CalendarSelection',

  render() {
    const { modifier, pending } = this.props;
    const modifiers = { [modifier]: true };
    const states = {
      pending,
    };

    return <div className={this.cx({ states, modifiers })} />;
  },
});

export default CalendarSelection;
