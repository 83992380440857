import React from 'react';
import { t } from '@lingui/macro';

import { getTimeAgo } from '@float/common/lib/utils';
import { capitalize } from '@float/libs/utils/string/capitalize';
import { Account, Activity, Person } from '@float/types';

import { ActivityAvatar } from './ActivityAvatar';
import ActivityItemHistoryLine from './ActivityItemHistoryLine';
import {
  ActivityEmptyLine,
  ActivityListItem,
  SubItemWrap,
} from './TaskModalActivity.styles';

type HistoryLogEntry = {
  sharedId: string;
  actionedById: string;
  actionedByName: string;
  actionedTimestamp: string;
  ignoreFromHistory: boolean;
  displayActivity: string;
};

// @test-export
export function checkShouldUsernameBeRendered(
  index: number,
  activityType: 'update' | unknown,
  previousCommentIsNotEmpty: boolean,
  userNameForCurrentAction: string,
  userNameForPreviousAction: string,
) {
  if (index !== 0 && activityType === 'update') {
    if (previousCommentIsNotEmpty) {
      return true;
    }

    // SEE(CS-1354)
    if (userNameForCurrentAction !== userNameForPreviousAction) {
      return true;
    }
  }

  return false;
}

/**
 * Tries to get the user name using the following steps:
 * 1. Extract the name from the accounts data. It is our first choice since it reflects the latest updates on the user name.
 * 2. Extract the name from the activities list. The user may have been deleted, so we get the name from the activity that is a sort of snapshot.
 * 3. Use the fallbackName when provided
 * 4. Show the id
 * Update to show fallbackName if invalid id is provided (CS-1366).
 */
export const getUserNameByAccountId = (
  activities: Activity[],
  accounts: Record<number, Account>,
  people: Person[],
  id: number,
  fallbackName = t`Deleted user`,
) => {
  if (!id) return fallbackName;
  let account = accounts[id];
  if (account && account.name) {
    return account.name;
  }

  if (!activities || !activities.length) {
    activities = [];
  }

  const cachedAccounts = activities.map((a) => a.actioned_by);
  account = cachedAccounts.find(
    (x) => x.account_id === id,
  ) as unknown as Account;

  if (account && account.name) {
    return account.name;
  }

  const person = people.find((p) => p.account_id === id);

  return (person && person.name) || fallbackName || id || '';
};

export function buildDisplayHistoryItems(
  activityType: 'update' | unknown,
  activityId: string,
  displayHistory: Array<Array<HistoryLogEntry>>,
  getUserNameByAccountId: (id: string, name: string) => string,
) {
  let previousCommentExists = false;
  let userNameForPreviousAction = '';

  return displayHistory
    .map((dataLayer, index) => {
      if (!dataLayer.length) return null;

      const result = [];

      const {
        sharedId,
        actionedById,
        actionedByName,
        actionedTimestamp,
        ignoreFromHistory,
        displayActivity,
      } = dataLayer[0];

      const previousCommentIsNotEmpty = index === 1 || previousCommentExists;

      const userNameForCurrentAction = getUserNameByAccountId(
        actionedById,
        actionedByName,
      );

      const userNameShouldBeRendered = checkShouldUsernameBeRendered(
        index,
        activityType,
        previousCommentIsNotEmpty,
        userNameForCurrentAction,
        userNameForPreviousAction,
      );

      if (userNameShouldBeRendered) {
        userNameForPreviousAction = userNameForCurrentAction;

        result.push(
          <ActivityListItem key={`${activityId}${index}`}>
            <ActivityAvatar accountId={actionedById} />
            <span className="ignore-bold">
              {capitalize(displayActivity)} by
            </span>
            <strong>{` ${userNameForCurrentAction} `}</strong>
            <span className="ignore-bold">{getTimeAgo(actionedTimestamp)}</span>
          </ActivityListItem>,
        );
      }

      previousCommentExists = result.length > 0;

      if (ignoreFromHistory) {
        result.push(
          <ActivityEmptyLine key={`historyLineItem_${sharedId}_${index}`} />,
        );
      } else {
        result.push(
          <ActivityListItem key={`historyLineItem_${sharedId}_${index}`}>
            <span className="with-bold">
              <ActivityItemHistoryLine
                dataLayer={dataLayer}
                SubItemWrap={SubItemWrap}
                withPre
                thinAction
              />
            </span>
          </ActivityListItem>,
        );
      }
      return result;
    })
    .filter(Boolean);
}
