import { Task } from '@float/types/task';
import { Timeoff } from '@float/types/timeoff';

import { getAreDatesOverlapping } from './getAreDatesOverlapping';

/**
 * A speculative check on wether a date range may fall into one of the
 * entity repetitions
 *
 * Used as optimization to skip the repeated dates iteration when returns false
 */
export function speculativelyCheckIfRepeatableEntityIntersectsWithDateRange(
  entity: Pick<Task | Timeoff, 'repeat_end_date' | 'start_date' | 'end_date'>,
  dates: DatesManager,
  startDate: string,
  endDate = startDate,
) {
  if (entity.repeat_end_date) {
    const duration =
      dates.toNum(entity.end_date) - dates.toNum(entity.start_date);

    const repeatEnd = dates.addDays(entity.repeat_end_date, duration) as string;

    return getAreDatesOverlapping(
      entity.start_date,
      repeatEnd,
      startDate,
      endDate,
    );
  }

  return false;
}
