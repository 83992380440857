import { isFullDayTimeoff } from '@float/common/lib/timeoffs';
import datesCreator from '@float/libs/dates';

import { RowMetaItem } from '../useRowMetas.helpers';
import { buildHolidays, buildWeekHeader, registerItem } from './helpers';
import { BreakdownData, PeopleBreakdownPayload } from './types';

function getBaseCapacity(rowMeta: RowMetaItem, firstCellDay: string) {
  let sum = 0;

  const dailyWorkHours = rowMeta.getDailyWorkHours(firstCellDay);
  for (let i = 0; i < dailyWorkHours.length; i++) {
    sum += dailyWorkHours[i];
  }

  return sum;
}

export function breakdownPeopleByWeek(payload: PeopleBreakdownPayload) {
  const dates = datesCreator(payload.serializedDates);
  const [startCol] = dates.toDescriptor(payload.startDate);
  const [endCol] = dates.toDescriptor(payload.endDate);
  dates.ensureRange(startCol, endCol);

  const { rows, cells, rowMetas } = payload;

  function getWeekCapacity(
    cellStart: string,
    rowMeta: RowMetaItem,
    baseCapacity: number,
  ) {
    const cellEnd = dates.addDays(cellStart, 6);

    if (rowMeta.start_date && rowMeta.start_date > cellEnd) return 0;
    if (rowMeta.end_date && rowMeta.end_date < cellStart) return 0;
    if (
      (!rowMeta.start_date || rowMeta.start_date <= cellStart) &&
      (!rowMeta.end_date || rowMeta.end_date >= cellEnd)
    ) {
      return baseCapacity;
    }

    const personStart = rowMeta.start_date
      ? dates.max(rowMeta.start_date, cellStart)
      : cellStart;
    const personEnd = rowMeta.end_date
      ? dates.min(rowMeta.end_date, cellEnd)
      : cellEnd;

    const [, startDay] = dates.toDescriptor(personStart);
    const [, endDay] = dates.toDescriptor(personEnd);

    let sum = 0;
    for (let i = startDay; i <= endDay; i++) {
      sum += rowMeta.getDailyWorkHours(cellStart)[i];
    }
    return sum;
  }

  function buildScheduleData() {
    const peopleRows = rows.filter((r) => r.type === 'person');

    return peopleRows.map(({ data: person }) => {
      const rowMeta = rowMetas.get(`person-${person.people_id}`)!;
      const data: BreakdownData = {
        person: {
          name: person.name,
          jobTitle: person.job_title,
          department: (person.department && person.department.name) || '',
        },
        tasks: {},
        timeoffs: {},
        aggregate: {},
      };

      for (let i = startCol; i <= endCol; i++) {
        const cell = cells[`person-${person.people_id}:${i}`];
        const cellDate = dates.fromDescriptor(i, 0);
        const baseCapacity = getBaseCapacity(rowMeta, cellDate);
        const dailyWorkHours = rowMeta.getDailyWorkHours(cellDate);

        if (!data.aggregate[cellDate]) {
          data.aggregate[cellDate] = {
            capacity: getWeekCapacity(cellDate, rowMeta, baseCapacity),
            total: 0,
          };
        }

        if (!cell || !cell.items || !cell.items.length) continue;

        cell.items.forEach((item) => {
          if (item.type === 'task') {
            const taskData = registerItem(payload, data, item);

            if (!taskData.dateData[cellDate]) {
              taskData.dateData[cellDate] = 0;
            }

            const hours = item.w * item.entity.hours;
            taskData.dateData[cellDate] += hours;
            data.aggregate[cellDate].total += hours;
            data.aggregate[cellDate].capacity -= hours;
          }

          if (item.type === 'timeoff' && !item.entity.region_holiday_id) {
            const timeoffData = registerItem(payload, data, item);

            if (!timeoffData.dateData[cellDate]) {
              timeoffData.dateData[cellDate] = 0;
            }

            if (isFullDayTimeoff(item.entity)) {
              for (let d = item.x!; d < item.x! + item.w; d++) {
                timeoffData.dateData[cellDate] += dailyWorkHours[d];
                data.aggregate[cellDate].total += dailyWorkHours[d];
                data.aggregate[cellDate].capacity -= dailyWorkHours[d];
              }
            } else if (item.entity.hours) {
              const hours = item.w * item.entity.hours;
              timeoffData.dateData[cellDate] += hours;
              data.aggregate[cellDate].total += hours;
              data.aggregate[cellDate].capacity -= hours;
            }
          }

          if (
            item.type === 'holiday' ||
            (item.type === 'timeoff' && item.entity.region_holiday_id)
          ) {
            for (let d = item.x!; d < item.x! + item.w; d++) {
              data.aggregate[cellDate].capacity -= dailyWorkHours[d];
            }
          }

          if (item.type === 'oneOff') {
            // Hours scheduled on one-off days should not adjust capacity. Since
            // we ignored this above when iterating through tasks/timeoffs,
            // we need to adjust.
            data.aggregate[cellDate].capacity += cell.dayHours[item.x!];
          }

          if (data.aggregate[cellDate].capacity < 0) {
            // We never want to report a negative capacity, which can result
            // if there's overtime in a given time period.
            data.aggregate[cellDate].capacity = 0;
          }
        });
      }

      return data;
    });
  }

  // ---------------------------------------------------------------------------

  const result = {
    header: buildWeekHeader(startCol, endCol, dates, payload),
    scheduleData: buildScheduleData(),
    holidays: buildHolidays(cells, startCol, endCol),
  };

  return result;
}
