import React from 'react';
import { t } from '@lingui/macro';

import { ModalConfirm } from '@float/ui/components/Modals/ModalConfirm';
import { IWithModal, withModal } from '@float/web/modalManager/withModal';

import {
  ModalCreateDraftProjectSettings,
  useModalCreateDraftProjectControls,
} from './ModalCreateDraftProject.hooks';

const ModalComponent = (props: IWithModal<ModalCreateDraftProjectSettings>) => {
  const { open, onOpenChange, onConfirm } =
    useModalCreateDraftProjectControls(props);
  return (
    <ModalConfirm
      modalProps={{
        open,
      }}
      title={t`Planning projects in Draft Status`}
      description={t`Scheduled entities for Draft projects only appear on the Project Plan screen within the project itself.`}
      primaryButtonLabel={t`Plan project`}
      secondaryButtonLabel={t`Got it`}
      onClickConfirm={onConfirm}
      onOpenChange={onOpenChange}
    />
  );
};

export const ModalCreateDraftProject = withModal({
  Comp: ModalComponent,
  modalType: 'ModalCreateDraftProject',
});
