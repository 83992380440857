import React from 'react';

import { Tooltip } from '@float/ui/components/Tooltip';

import * as styles from './styles.css';

type StatusIndicatorProps = {
  active: boolean;
  activeTooltip?: string;
  inactiveTooltip?: string;
};

export const StatusIndicator: React.FC<StatusIndicatorProps> = ({
  active,
  activeTooltip = '',
  inactiveTooltip = '',
}) => {
  return (
    <Tooltip content={active ? activeTooltip : inactiveTooltip}>
      <div
        className={styles.statusIndicator}
        data-state={active ? 'active' : 'inactive'}
      />
    </Tooltip>
  );
};
