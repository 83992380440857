import React, { useMemo } from 'react';
import { t } from '@lingui/macro';

import { ToggleField } from '../components/ToggleField';

type BillableFieldProps = {
  name?: 'project.non_billable' | 'phase.non_billable';
  isBillableDisabled?: boolean;
  onChange?: (value: boolean) => void;
};

export function BillableField({
  name = 'project.non_billable',
  isBillableDisabled,
  onChange,
}: BillableFieldProps) {
  const options = useMemo(
    () => [
      {
        value: false,
        label: t`Billable`,
        disabled: isBillableDisabled,
      },
      {
        value: true,
        label: t`Non-billable`,
      },
    ],
    [isBillableDisabled],
  );

  return (
    <ToggleField
      name={name}
      label={t`Billable`}
      options={options}
      onChange={onChange}
    />
  );
}
