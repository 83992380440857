import styled from 'styled-components';

import EH from '@float/ui/deprecated/Earhart';

export const StyledRow = styled.div`
  color: ${EH.Colors.FIN.Lt.Emphasis.High};

  & + & {
    margin-top: 20px;
  }

  h5 {
    ${EH.Typography.TextXXL.SB600};
  }

  p {
    ${EH.Typography.TextM.R400};
  }
`;
