import { map } from 'lodash';
import {
  filterLinkFormatter,
  pctFormatter,
} from 'reports/helpers/tableFormatters';

import { getPercentage } from '@float/common/lib/budget';

import { getEmptyTaskLabel } from './getEmptyTaskLabel';

function breakdown(ctx, raw) {
  const { projects, isSinglePersonView } = ctx;
  const byTask = {};

  raw.totals.forEach((item) => {
    if (item.type !== 'task' && item.type !== 'logged_time') return;

    if (!projects[item.project_id]) {
      console.error('No project found', { item });
      return null;
    }

    if (!byTask[item.name]) {
      byTask[item.name] = {
        scheduled: 0,
        billable: 0,
        nonbillable: 0,
        logged: {
          scheduled: 0,
          billable: 0,
          nonbillable: 0,
          overtime: 0,
        },
        combined: {
          scheduled: 0,
          billable: 0,
          nonbillable: 0,
          overtime: 0,
        },
        future: {
          scheduled: 0,
          billable: 0,
          nonbillable: 0,
          overtime: 0,
        },
        timeoff: 0,
        overtime: 0,
        children: {},
      };
    }

    const record = byTask[item.name];

    // We want to aggregate the children by person in multi-person mode but
    // by project in single-person mode
    const key = isSinglePersonView
      ? projects[item.project_id].long_name
      : item.person_id;
    if (!key) {
      console.error(item);
      throw Error('Expected item.person_id');
    }

    if (!record.children[key]) {
      record.children[key] = {
        key,
        scheduled: 0,
        billable: 0,
        nonbillable: 0,
        logged: {
          scheduled: 0,
          billable: 0,
          nonbillable: 0,
        },
        combined: {
          scheduled: 0,
          billable: 0,
          nonbillable: 0,
        },
        timeoff: 0,
      };
    }

    const child = record.children[key];

    // Record refers to the parent row (people).
    // Child refers to the child row (project/timeoff).
    // The "root" suffix is used to get either the task/logged time data
    if (item.type === 'task' || item.type === 'logged_time') {
      if (item.type === 'logged_time' && item.date >= ctx.loggedTimeBoundary) {
        return;
      }

      const childRoot = item.type === 'task' ? child : child.logged;
      const recordRoot = item.type === 'task' ? record : record.logged;

      childRoot.scheduled += item.hours.scheduled;
      recordRoot.scheduled += item.hours.scheduled;
      record.combined.scheduled +=
        item.type === 'task' ? item.hours.future : item.hours.scheduled;
      record.future.scheduled += item.hours.future;
      child.combined.scheduled +=
        item.type === 'task' ? item.hours.future : item.hours.scheduled;

      if (item.billable) {
        childRoot.billable += item.hours.scheduled;
        recordRoot.billable += item.hours.scheduled;
        record.combined.billable +=
          item.type === 'task' ? item.hours.future : item.hours.scheduled;
        record.future.billable += item.hours.future;
        child.combined.billable +=
          item.type === 'task' ? item.hours.future : item.hours.scheduled;
      } else {
        childRoot.nonbillable += item.hours.scheduled;
        recordRoot.nonbillable += item.hours.scheduled;
        record.combined.nonbillable +=
          item.type === 'task' ? item.hours.future : item.hours.scheduled;
        record.future.nonbillable += item.hours.future;
        child.combined.nonbillable +=
          item.type === 'task' ? item.hours.future : item.hours.scheduled;
      }
    }
  });

  return byTask;
}

export function getScheduledTable(ctx, raw) {
  const { people, isSinglePersonView } = ctx;

  const headers = [
    {
      label: 'Name',
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    { label: 'Scheduled', width: 100 },
    { label: 'Billable', width: 100 },
    { label: 'Non-billable', width: 100 },
    {
      label: 'Billable %',
      width: 120,
      isPercent: true,
      formatter: pctFormatter,
    },
  ];

  if (!raw) return { headers, rows: [] };

  const byTask = breakdown(ctx, raw);

  const rows = map(byTask, (o, taskName) => {
    return {
      id: taskName,
      data: [
        taskName || getEmptyTaskLabel(ctx),
        o.scheduled,
        o.billable,
        o.nonbillable,
        getPercentage(o.billable, o.scheduled),
      ],
      children: map(o.children, (c) => {
        const name = isSinglePersonView ? c.key : people[c.key].name;
        return {
          data: [
            name,
            c.scheduled,
            c.billable,
            c.nonbillable,
            getPercentage(c.billable, c.scheduled),
          ],
        };
      }),
    };
  });

  return { headers, rows };
}

export function getLoggedTable(ctx, raw) {
  const { people, isSinglePersonView } = ctx;

  const headers = [
    {
      label: 'Name',
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    { label: 'Logged', width: 100 },
    { label: 'Billable', width: 100 },
    { label: 'Non-billable', width: 100 },
    {
      label: 'Billable %',
      width: 120,
      isPercent: true,
      formatter: pctFormatter,
    },
  ];

  if (!raw) return { headers, rows: [] };

  const byTask = breakdown(ctx, raw);

  const rows = map(byTask, (o, taskName) => {
    return {
      id: taskName,
      data: [
        taskName || getEmptyTaskLabel(ctx),
        o.logged.scheduled,
        o.logged.billable,
        o.logged.nonbillable,
        getPercentage(o.logged.billable, o.logged.scheduled),
      ],
      children: map(o.children, (c) => {
        const name = isSinglePersonView ? c.key : people[c.key].name;
        return {
          data: [
            name,
            c.logged.scheduled,
            c.logged.billable,
            c.logged.nonbillable,
            getPercentage(c.logged.billable, c.logged.scheduled),
          ],
        };
      }),
    };
  });

  return { headers, rows };
}

export function getCompareTable(ctx, raw) {
  const { people, isSinglePersonView } = ctx;

  const headers = [
    {
      label: 'Name',
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    { label: 'Scheduled', width: 200 },
    { label: 'Logged', width: 200 },
    { label: 'Difference', width: 200 },
  ];

  if (!raw) return { headers, rows: [] };

  const byTask = breakdown(ctx, raw);

  const rows = map(byTask, (o, taskName) => {
    return {
      id: taskName,
      data: [
        taskName || getEmptyTaskLabel(ctx),
        o.scheduled,
        o.logged.scheduled,
        o.scheduled - o.logged.scheduled,
      ],
      children: map(o.children, (c) => {
        const name = isSinglePersonView ? c.key : people[c.key].name;
        return {
          data: [
            name,
            c.scheduled,
            c.logged.scheduled,
            c.scheduled - c.logged.scheduled,
          ],
        };
      }).filter(Boolean),
    };
  });

  return { headers, rows };
}

export function getCombinedTable(ctx, raw) {
  const { people, isSinglePersonView } = ctx;

  const headers = [
    {
      label: 'Name',
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    { label: 'Past logged + Future sched.', width: 200 },
    { label: 'Billable', width: 100 },
    { label: 'Non-billable', width: 100 },
    {
      label: 'Billable %',
      width: 120,
      isPercent: true,
      formatter: pctFormatter,
    },
  ];

  if (!raw) return { headers, rows: [] };

  const byTask = breakdown(ctx, raw);

  const rows = map(byTask, (o, taskName) => {
    return {
      id: taskName,
      data: [
        taskName || getEmptyTaskLabel(ctx),
        o.combined.scheduled,
        o.combined.billable,
        o.combined.nonbillable,
        getPercentage(o.combined.billable, o.combined.scheduled),
      ],
      children: map(o.children, (c) => {
        const name = isSinglePersonView ? c.key : people[c.key].name;
        return {
          data: [
            name,
            c.combined.scheduled,
            c.combined.billable,
            c.combined.nonbillable,
            getPercentage(c.combined.billable, c.combined.scheduled),
          ],
        };
      }),
    };
  });

  return { headers, rows };
}
