import { Phase } from '@float/types/phase';
import { Project, ProjectStatus } from '@float/types/project';

export function determineEntityStatus(entity: Project | Phase | undefined) {
  if (!entity) return ProjectStatus.Confirmed;

  if (typeof entity.status === 'number') {
    return entity.status;
  }
  return entity.tentative ? ProjectStatus.Tentative : ProjectStatus.Confirmed;
}
