import { isBulkOp } from '@floatschedule/activity-format-npm';
import { isArray } from 'lodash';
import {
  getMilestonesMap,
  getPeopleMap,
  getPhasesMapRaw,
  getProjectsMap,
  getStatuses,
  getTasksMap,
  getTimeoffsMap,
} from 'selectors';

function determineCanEdit(type, projectId, { currentUser, projects }) {
  if (type === 'task') {
    return projects.projects[projectId].project_id;
  }
  const isObserver = +(currentUser && currentUser.account_tid) === 4;
  return !isObserver;
}

const getItemId = (item, type) => {
  if (item.item_id) {
    return item.item_id;
  }
  if (item.after_data && item.after_data[`${type}_id`]) {
    return item.after_data[`${type}_id`];
  }
  return null;
};

const getTaskId = (item) => getItemId(item, 'task');

const getTimeoffId = (item) => getItemId(item, 'timeoff');

const getStatusId = (item) => getItemId(item, 'status');

export default function clickActivity(
  item,
  manageModal,
  _updateDates,
  _retry,
  history,
  showSnackbar,
  showProjectSidePanel,
  store,
) {
  if (isBulkOp(item)) {
    return;
  }

  const state = store.getState();
  let model, editing;
  switch (item.item_type) {
    case 'task':
    case 'timeoff':
    case 'status': {
      // Task/Timeoff click handling:
      // Due to coupling of tasks and schedule,
      // We will navigate to the schedule on click of a task or timeoff
      // The schedule handles refetching the tasks, we just fire off a handler
      // updateDates, which changes the range of schedule data
      // TODO Refactor this so it can open tasks on any page.
      const currentLocation = history.location;
      if (currentLocation.pathname !== '/') {
        // Navigate to schedule
        history.push(`/${currentLocation.search}`);
      }
      // Find the task, timeoff or status
      let id;
      if (item.item_type === 'timeoff') {
        id = getTimeoffId(item);
        model = getTimeoffsMap(state)[id];
      } else if (item.item_type === 'status') {
        id = getStatusId(item);
        model = getStatuses(state)[id];
      } else {
        id = getTaskId(item);
        model = getTasksMap(state)[id];
      }

      // If we don't have the task or the task hasn't been processed to have a start_date, we'll load it using share url.
      if (!model || !model.start_date) {
        history.push(`/#${item.item_type}-${id}`);
        return;
      }
      // We have the task, proceed to open the modal.
      if (item.item_type === 'timeoff') {
        model.isTimeoff = true;
      } else if (item.item_type === 'status') {
        model.isStatus = true;
      }

      if (!model.people_ids && model.people_id) {
        const { people_id } = model;
        model.people_ids = isArray(people_id) ? people_id : [people_id];
      }

      const canEdit = determineCanEdit(item.item_type, model.project_id, state);
      editing = item.activity_type !== 'delete' && canEdit;
      model.editing = editing;
      manageModal({
        visible: true,
        modalType: 'taskModal',
        modalSettings: {
          taskOrTimeoff: model,
          fromActivity: true,
        },
      });

      break;
    }

    case 'people': {
      const peopleMap = getPeopleMap(state);
      model = peopleMap[item.item_id];

      if (!model) {
        showSnackbar('Person not found.');
        return;
      }
      editing = item.activity_type !== 'delete' && model.canEdit;

      // This is an ugly situation.
      // people needs to be wrapped like this
      // for application logic and markup like this elsewhere:
      // (people) => { const {people} = people; };
      let people = model || item.after_data;
      if (!people.people) {
        people = { people };
      }
      manageModal({
        visible: true,
        modalType: 'personModal',
        modalSettings: {
          person: peopleMap[item.item_id],
          isEditable: editing,
        },
      });
      break;
    }

    case 'project': {
      model = getProjectsMap(state)[item.item_id];
      if (!model) {
        showSnackbar('Project not found.');
        return;
      }
      showProjectSidePanel({
        projectId: model.project_id,
      });
      break;
    }

    case 'phase': {
      model = getPhasesMapRaw(state)[item.item_id];
      if (!model) {
        showSnackbar('Phase not found.');
        return;
      }

      const project = getProjectsMap(state)[model.project_id];
      model.tags = project?.tags || [];
      manageModal({
        visible: true,
        modalType: 'phaseModal',
        modalSettings: {
          phase: model,
          projectId: model.project_id,
          editing: project?.canEdit,
        },
      });
      break;
    }

    case 'milestones': {
      model = getMilestonesMap(state)[item.item_id];
      if (!model) {
        showSnackbar('Milestone not found');
        return;
      }

      manageModal({
        visible: true,
        modalType: 'milestoneModal',
        modalSettings: {
          milestone: model,
        },
      });
      break;
    }

    case 'holiday':
    case 'holidays':
      return window.location.replace('/admin/timeoffs');
    case 'user':
      return window.location.replace('/admin/guests');
    default:
      return console.warn('Unhandled action');
  }
}
