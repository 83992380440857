import styled from 'styled-components';

import { Flex, Spacer } from '@float/ui/deprecated';
import { createTextColorCSS } from '@float/ui/deprecated/Earhart';

export const EarhartButton = styled(Flex)`
  flex: unset;
  font-weight: ${({ weight = 500 }) => weight};
  font-size: ${({ size }) => {
    switch (size) {
      case 'small':
        return 12;
      default:
        return 14;
    }
  }}px;
  line-height: 17px;
  text-align: center;
  height: 100%;
  letter-spacing: -0.2px;
  border-radius: 8px;
  background: transparent;
  cursor: pointer;
  padding: ${({ equalPadding }) => (equalPadding ? '8px' : '8px 12px')};

  ${({
    theme,
    emphasisHigh,
    isActivable = true,
    isActive,
    isActiveLight,
    baseTextColor,
    hoverBgColor,
    hoverTextColor,
    mouseDownBgColor,
    mouseDownTextColor,
    activeBgColor,
    activeTextColor,
  }) => {
    let style = '';
    const baseColor = emphasisHigh ? theme.emphasisHigh : theme.emphasisMedium;
    style += createTextColorCSS(
      baseTextColor ? theme[baseTextColor] : baseColor,
    );
    const hoverBg = isActivable ? theme.flueLight5 : theme.surface4;
    const hoverText =
      theme[hoverTextColor] ||
      (isActivable ? theme.flueLight9 : theme.emphasisHigh);
    const hoverStyle = `
      background: ${hoverBgColor ? theme[hoverBgColor] : hoverBg};
      ${createTextColorCSS(hoverText)}
    `;

    const mouseDownBg = isActivable ? theme.flueLight6 : theme.surface6;
    const mouseDownText =
      theme[mouseDownTextColor] ||
      (isActivable ? theme.primaryPressed : theme.emphasisHigh);
    const mouseDownStyle = `
      background: ${mouseDownBgColor ? theme[mouseDownBgColor] : mouseDownBg};
      ${createTextColorCSS(mouseDownText)}
    `;

    if (!isActive) {
      style += `
      &:hover, &.hover {
        ${hoverStyle}
      }

      &:active {
        ${mouseDownStyle}
      }
    `;
    }

    if (isActivable) {
      if (isActive || isActiveLight) {
        style += createTextColorCSS(theme.flueLight9);
      }

      if (isActive) {
        style += `background: ${
          activeBgColor ? theme[activeBgColor] : theme.flueLight3
        };`;
        style += createTextColorCSS(
          activeTextColor ? theme[activeTextColor] : theme.flueLight9,
        );
      }
    }

    return style;
  }}
`;

// create a debug container, position: absolute
// so main Controls stay centered
// https://app.asana.com/0/1201258914201276/1201429313737196
export const StyledDebugContainer = styled.div`
  position: absolute;

  padding-right: 8px;

  transform: translateX(-100%);
`;

export const Group = styled.div`
  display: flex;
  flex-direction: row;
  opacity: 0;
  transition: opacity 0.2s ease;

  &:first-child {
    position: relative;
  }

  & + & {
    margin-left: 8px;
  }

  div:first-child:not(${StyledDebugContainer}) {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  div:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  @media (max-width: 1010px) {
    .today {
      height: 35px;
    }

    .today {
      border-radius: 5px;
      margin-right: 3px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
  bottom: 32px;
  right: ${(p) => (p.isSidebarOpen ? 432 : 32)}px;

  pointer-events: none;
  z-index: 100;
  user-select: none;
  outline: none;
  will-change: right;
  transition: right 0.2s ease-out;
  filter: drop-shadow(0px 10px 18px rgba(9, 30, 66, 0.15))
    drop-shadow(0px 0px 1px rgba(95, 106, 126, 0.15));

  div {
    outline: none;
  }

  ${Group} {
    pointer-events: all;
    flex-shrink: 0;
  }

  ${EarhartButton} {
    &.arrow {
      width: 40px;
    }
  }

  @media (max-width: 1010px) {
    padding-left: 0;
    bottom: 16px;

    ${EarhartButton} {
      padding: 8px;
    }
  }

  @media print {
    display: none;
  }
`;

export const Control = styled.div`
  color: white;
  line-height: 45px;
  letter-spacing: -0.2px;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  user-select: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 17px;

  svg.icon-chevron-down {
    margin-right: -5px;
  }

  &.disabled {
    pointer-events: none;
    color: #a3a7a9;
  }

  &.minical {
    width: 111px;
  }

  @media (max-width: 1010px) {
    & > ${Spacer} {
      display: none;
    }
  }
`;

export const EarhartControl = styled(Control)`
  background: white;
  padding: 4px;
  border-radius: 8px !important;
  color: ${({ theme }) => theme.emphasisHigh};

  svg {
    .stroke {
      stroke: ${({ theme }) => theme.emphasisHigh};
    }
    .fill {
      fill: ${({ theme }) => theme.emphasisHigh};
    }
  }

  &:hover {
    background: white;
  }
`;
