import { omit } from 'lodash';

import { sortByName } from '@float/common/lib/itemSort';
import { AppStore } from '@float/common/store';
import { Milestone } from '@float/types/milestone';
import { PhaseWithTemplateFields } from '@float/types/phase';
import { Project } from '@float/types/project';
import { RawTaskMeta } from '@float/types/task';

import { FormType, ProjectFormInitialData } from '../../types';
import { getProjectMilestonesFromTemplateMilestones } from './getProjectMilestonesFromTemplateMilestones';
import { getProjectPeopleIdsFromTemplatePeopleIds } from './getProjectPeopleIdsFromTemplatePeopleIds';
import { getProjectPhasesFromTemplatePhases } from './getProjectPhasesFromTemplatePhases';
import { getProjectTasksFromTemplateTasks } from './getProjectTasksFromTemplateTasks';

function shouldIncludeOffsets(project: Project) {
  // For backward compatibility. Existing templates might have phases/milestones
  // without appropriate durations/offsets captured in the template. For such
  // templates, we need to omit setting offsets in this derived project entities.
  const offsetsDataAvailable =
    project.duration !== undefined && project.duration !== null;

  return offsetsDataAvailable;
}

export function getProjectDataFromTemplate(
  store: AppStore,
  templateId: number,
): ProjectFormInitialData {
  const state = store.getState();

  const template = state.projects.templates[templateId] || {};
  const tasks = sortByName(
    getProjectTasksFromTemplateTasks(template.task_metas as RawTaskMeta[]),
    'task_name',
  );
  const project = omit(
    template,
    'phases',
    'milestones',
    'task_metas',
    'team_people',
    'project_name',
    'creator_id',
    'project_template_id',
  ) as Project;

  const includeOffsets = shouldIncludeOffsets(project);

  project.project_name = '';
  project.project_manager = state.currentUser.account_id;
  project.duration = includeOffsets ? project.duration : null;
  project.people_ids = getProjectPeopleIdsFromTemplatePeopleIds(
    template.people_ids,
    state.people.people,
    state.currentUser,
  );

  const milestones = getProjectMilestonesFromTemplateMilestones(
    template.milestones as Milestone[],
    includeOffsets,
  );
  const phases = getProjectPhasesFromTemplatePhases(
    template.phases as PhaseWithTemplateFields[],
    includeOffsets,
  );

  return {
    type: FormType.Project,
    templateId,
    project,
    tasks,
    milestones,
    phases,
  };
}
