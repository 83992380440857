import { TimeFormat } from '@float/types';

import { InputTimeType } from '../types';

function isValidInputTimeFormat(
  value: string,
  format: TimeFormat,
  type: InputTimeType,
) {
  // hh:mm
  if (format === '24' || (format === '12' && type === 'duration')) {
    return /^\d{0,2}:?\d{0,2}$/gi.test(value);
  }

  // hh:mm(am|pm)
  return /^\d{0,2}:?\d{0,2}(a|p)?m?$/gi.test(value);
}

export { isValidInputTimeFormat };
