import React from 'react';
import { useFormContext } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';

import { ToggleButton, ToggleGroup } from '@float/ui/deprecated';

import { FormFieldsSection } from '../../components/FormFieldsSection';
import { DefaultHourlyRateField } from '../../fields/DefaultHourlyRateField';
import { useIsPhasePanel } from '../../hooks/useIsPhasePanel';
import { ProjectFormData } from '../../types';

import * as styles from './styles.css';

export function RateTypeField(props: {
  toggleDefaultRate: boolean;
  setToggleDefaultRate: (val: boolean) => void;
}) {
  const isPhase = useIsPhasePanel();
  const { setValue } = useFormContext<ProjectFormData>();
  const name = `${isPhase ? 'phase' : 'project'}.default_hourly_rate` as const;

  const options = [
    {
      value: false,
      label: t`Different hourly rates`,
    },
    {
      value: true,
      label: t`Same hourly rates for everyone`,
    },
  ];

  return (
    <FormFieldsSection>
      <label className={styles.projectTeamSectionLabel}>
        <Trans>Rates per person</Trans>
      </label>
      <ToggleGroup
        className={styles.projectRateToggle}
        type="single"
        size="tiny"
        value={props.toggleDefaultRate}
        disableUnselect
        onChange={(value) => {
          if (!value) {
            setValue(name, null);
          }
          props.setToggleDefaultRate(Boolean(value));
        }}
      >
        {options.map((option, i) => (
          <ToggleButton key={i} value={option.value}>
            {option.label}
          </ToggleButton>
        ))}
      </ToggleGroup>
      {props.toggleDefaultRate && <DefaultHourlyRateField name={name} />}
    </FormFieldsSection>
  );
}
