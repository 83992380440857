import React from 'react';
import { Trans } from '@lingui/macro';

import { Rights } from '@float/common/lib/acl';
import { getProjectsMap } from '@float/common/selectors/projects';
import { useAppSelector } from '@float/common/store';
import { CurrentUser, Phase } from '@float/types';
import { Button } from '@float/ui/deprecated';
import {
  DropdownActions,
  pushToRight,
} from '@float/ui/deprecated/DropdownActions';
import { IconArrowDown, IconTrash } from '@float/ui/deprecated/Earhart/Icons';

export type PhaseActionsMenuProps = {
  phase: Phase;
  projectId: number;
  currentUser: CurrentUser;
  onActiveChange: (active: boolean) => void;
  onDelete: () => void;
};

export function PhaseActionsMenu(props: PhaseActionsMenuProps) {
  const { currentUser, phase, projectId, onActiveChange, onDelete } = props;
  const project = useAppSelector((state) => getProjectsMap(state)[projectId]);

  const isProjectActive = project.active;
  const canDelete = Rights.canDeletePhase(currentUser, {
    project,
    phase,
  });

  if (!isProjectActive && !canDelete) return null;

  return (
    <DropdownActions
      side="top"
      align="end"
      className={pushToRight}
      content={
        <>
          {isProjectActive && (
            <DropdownActions.Item onClick={() => onActiveChange(!phase.active)}>
              {phase.active ? (
                <Trans>Move to archive</Trans>
              ) : (
                <Trans>Move to active</Trans>
              )}
            </DropdownActions.Item>
          )}
          {canDelete && (
            <DropdownActions.Item appearance="danger" onClick={onDelete}>
              <Trans>Delete</Trans> <IconTrash />
            </DropdownActions.Item>
          )}
        </>
      }
    >
      <Button appearance="clear" iconRight={IconArrowDown}>
        <Trans>Actions</Trans>
      </Button>
    </DropdownActions>
  );
}
