import React, { Fragment } from 'react';
import {
  booleanLabels,
  noInfoKeys,
  removedWithValues,
} from '@floatschedule/activity-format-npm';

import { withComponents } from '../../lib/monorepo-helpers';

function getBulkUpdateSuffix(activityType, fieldKey) {
  let suffix;
  if (
    activityType === 'bulk_update' &&
    !['added_', 'tentative', 'non_billable', 'common'].some((x) =>
      fieldKey.startsWith(x),
    )
  ) {
    suffix = 'updated:';
  } else if (activityType === 'bulk_delete') {
    suffix = 'deleted:';
  }
  return suffix;
}

const ActivityItemHistoryLine = ({
  dataLayer,
  withPre,
  thinAction,
  components: {
    ActivityColorWrapper,
    ActivityColor,
    SubItemWrap,
    LineItem,
    Wrapper,
    Text,
    Bold,
    Spacer,
  },
}) => {
  function colorizeStr(key, str) {
    if (key !== 'color') {
      return <Bold>{str}</Bold>;
    }
    const fullColor = `#${str}`;
    return (
      <ActivityColorWrapper>
        <ActivityColor color={fullColor} />
        <Bold>{fullColor}</Bold>
      </ActivityColorWrapper>
    );
  }

  const jsx = dataLayer.reduce(
    (
      accum,
      {
        fieldKey,
        nextPrevData,
        prevPeopleName,
        nextPeopleName,
        template,
        prettierKey,
        activityType,
        prettyPhrase,
      },
      i,
    ) => {
      const { nextPrettier, prevPrettier } = nextPrevData || {};

      const key = `item_${fieldKey}`;
      if (fieldKey === 'notes_meta') return accum;
      if (fieldKey === 'people_name') return [];
      let innerContent;
      switch (template) {
        case 'personUpdate': {
          innerContent = (
            <Fragment>
              {prevPeopleName && (
                <Fragment>
                  <Text>{' from '}</Text>
                  <Bold>{prevPeopleName}</Bold>
                </Fragment>
              )}
              <Text>{' to '}</Text>
              <Bold>{nextPeopleName}</Bold>
            </Fragment>
          );
          break;
        }
        case 'usePrettyPhrase': {
          innerContent = (
            <Fragment>
              <Text>{prettyPhrase}</Text>
            </Fragment>
          );
          break;
        }
        case 'fromTo': {
          innerContent = noInfoKeys.includes(fieldKey) ? (
            <Text>{' updated'}</Text>
          ) : (
            <Fragment>
              <Text>{' from '}</Text>
              {colorizeStr(fieldKey, prevPrettier)}
              <Text>{' to '}</Text>
              {colorizeStr(fieldKey, nextPrettier)}
            </Fragment>
          );
          break;
        }
        case 'adding': {
          let suffix = getBulkUpdateSuffix(activityType, fieldKey);
          if (!suffix) {
            suffix = booleanLabels[fieldKey]
              ? booleanLabels[fieldKey][0]
              : 'added';
          }

          innerContent = (
            <Fragment>
              <Text>{` ${suffix} `}</Text>
              {!noInfoKeys.includes(fieldKey) &&
                colorizeStr(fieldKey, nextPrettier)}
            </Fragment>
          );
          break;
        }
        case 'removing':
        default: {
          innerContent = (
            <Fragment>
              <Text>{` ${
                booleanLabels[fieldKey] ? booleanLabels[fieldKey][1] : 'removed'
              } `}</Text>
              {!booleanLabels[fieldKey] &&
                removedWithValues.includes(fieldKey) && (
                  <Bold>
                    {typeof prevPrettier === 'object'
                      ? prevPrettier.name
                      : prevPrettier}
                  </Bold>
                )}
            </Fragment>
          );
          break;
        }
      }

      if (!innerContent) {
        return accum;
      }
      const comment = (
        <LineItem key={key} withPre={withPre || thinAction}>
          {withPre || thinAction ? (
            <Text className="bullet">&bull;</Text>
          ) : (
            <Text />
          )}
          <Text>{prettierKey}</Text>
          {innerContent}
        </LineItem>
      );

      if (i !== 0 && comment) {
        accum.push(<Spacer fromTask={withPre} key={`space_${fieldKey}`} />);
      }
      return accum.concat(comment);
    },
    [],
  );
  if (!jsx.length) {
    return null;
  }
  if (SubItemWrap) {
    return <SubItemWrap>{jsx}</SubItemWrap>;
  }
  return <Wrapper>{jsx}</Wrapper>;
};

export default withComponents(ActivityItemHistoryLine);
