import React from 'react';

import { PersonAccess } from '../PersonModal/access/PersonAccess';

export default function Access({ value, onChange }) {
  return (
    <PersonAccess
      isNew={false}
      hideClearAccess={true}
      user={{}}
      account={value || {}}
      onChange={(nextAccount) => {
        onChange(nextAccount);
      }}
    />
  );
}
