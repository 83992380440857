import React from 'react';
import { FieldPath, get, useFormState } from 'react-hook-form';

import { Tag } from '@float/ui/components/Tag';
import { IconWarningTriangle } from '@float/ui/icons/essentials/IconWarningTriangle';

import { ProjectFormData } from '../../types';

export function SectionErrorTag(props: {
  fields: FieldPath<ProjectFormData>[];
}) {
  const { errors } = useFormState();
  const fieldErrors = props.fields.find((name) => get(errors, name));

  if (!fieldErrors) return null;

  return (
    <Tag color="critical" size="icon" data-testid="section-error-tag">
      <IconWarningTriangle />
    </Tag>
  );
}
