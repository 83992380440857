export function buildReportsURL(baseUrl: string, reportQuery: string[][]) {
  const url = new URL(baseUrl);

  reportQuery.forEach(
    ([key, value], i) =>
      (url.search += `${i ? '&' : ''}${key}=${encodeURIComponent(value)}`),
  );

  return url.toString();
}
