import {
  ADD_CLIENT,
  ADD_CLIENT_SUCCESS,
  DELETE_CLIENT_SUCCESS,
  UPDATE_CLIENT_SUCCESS,
} from '@float/common/actions/clients';

import {
  FETCH_CLIENTS,
  FETCH_CLIENTS_FAILURE,
  FETCH_CLIENTS_SUCCESS,
  SORT_CLIENTS,
} from '../actions/clients';
import {
  ACCOUNT_SETTINGS_CLIENTS_TABLE,
  getInitialSortableTableState,
  getInitialSortPropsState,
  getInitialStateCreator,
  reducerCreator,
} from './reducerHelpers';

export const lastFetchError = (state) => state.lastFetchError;

export const isBeingEdited = (entity, state) =>
  state.beingEditedIds.indexOf(entity.client_id) > -1;

export const areBeingFetched = (state) => state.pagesBeingFetched.length > 0;

const getInitialState = getInitialStateCreator(
  Object.assign(
    {
      sortableTables: {
        [ACCOUNT_SETTINGS_CLIENTS_TABLE]: getInitialSortableTableState(
          'name',
          'asc',
        ),
      },
    },
    getInitialSortPropsState(['name', 'project_count']),
  ),
);

// must be enclosed in a function for constants to be available for evaluation
const clients = reducerCreator(
  {
    ADD_ENTITY: ADD_CLIENT,
    ADD_ENTITY_SUCCESS: ADD_CLIENT_SUCCESS,
    DELETE_ENTITY_SUCCESS: DELETE_CLIENT_SUCCESS,
    UPDATE_ENTITY_SUCCESS: UPDATE_CLIENT_SUCCESS,
    SORT_ENTITIES: SORT_CLIENTS,
    FETCH_ENTITIES: FETCH_CLIENTS,
    FETCH_ENTITIES_SUCCESS: FETCH_CLIENTS_SUCCESS,
    FETCH_ENTITIES_FAILURE: FETCH_CLIENTS_FAILURE,
  },
  'client_id',
  getInitialState,
);

export default clients;
