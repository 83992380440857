import { RawUserPrefs, UserPrefs } from '@float/types/account';

function sanitizeCustomPriorityPref(
  customPriority: RawUserPrefs['custom_priority'],
  peopleOrder: RawUserPrefs['people_order'],
) {
  // Incremental DB correction: We have a few custom_priority pref values in the
  // DB stored as a 2d array as opposed to an object that it is expected to be.
  // This function corrects such data as and when encountered.

  if (!Array.isArray(customPriority)) return customPriority;

  const customPriorityMap: Record<number, number[]> = {};
  customPriority.forEach((ids, i) => {
    if (Array.isArray(ids) && ids.length) {
      const _ids = ids.filter((id) => peopleOrder?.[id] == i);
      if (_ids.length) customPriorityMap[i] = _ids;
    }
  });
  return customPriorityMap;
}

function valueOrEmpty<V>(val: V) {
  if (!val || Array.isArray(val)) return {};
  return val;
}

export function sanitizeUserPrefs(prefs: RawUserPrefs): UserPrefs {
  const custom_priority = sanitizeCustomPriorityPref(
    prefs.custom_priority,
    prefs.people_order,
  );

  // TODO: earhart - check why BE sends an array if empty.
  // Protect against null or empty array responses for the following
  // props which are always expected to be an object (key value).
  const projview_people_order = valueOrEmpty(prefs.projview_people_order);
  const projview_people_custom_priority = valueOrEmpty(
    prefs.projview_people_custom_priority,
  );

  let time_format_24h = prefs.time_format_24h;
  // We have a few time_format_24h pref values in the
  // DB stored as a number instead of JSON value
  if (typeof time_format_24h === 'number') {
    time_format_24h = { label: '', value: time_format_24h };
  }

  return {
    ...prefs,
    custom_priority,
    projview_people_order,
    projview_people_custom_priority,
    time_format_24h,
  };
}
