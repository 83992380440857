import { useEffect, useReducer } from 'react';

import { fetchOffsets } from '@float/common/actions';
import { AppStore, useAppStore } from '@float/common/store';

import { getTemplateData } from '../helpers/getTemplateData';
import { getTemplateDataFromProject } from '../helpers/getTemplateDataFromProject';
import { fetchProjectData, fetchTemplateData } from '../store/actions';
import { Action, reducer, State } from '../store/reducer';
import { ProjectIdOrTemplateId } from '../types';

type TemplateStateType = ReturnType<typeof getTemplateData>;

function getInitialData(store: AppStore, ids?: ProjectIdOrTemplateId) {
  if (ids?.projectId) {
    return getTemplateDataFromProject(store, ids.projectId);
  }

  return getTemplateData(store, ids?.templateId);
}

/**
 *
 * This hook fetches project template data in one go.
 *
 * It doesn't subscribes to the Redux state because updating the returned data
 * triggeres a state reset on the form component.
 */
export function useGetTemplateData(ids?: ProjectIdOrTemplateId) {
  const { projectId, templateId } = ids || {};
  const store = useAppStore();
  const [state, dispatch] = useReducer<
    React.Reducer<State<TemplateStateType>, Action<TemplateStateType>>,
    ProjectIdOrTemplateId | undefined
  >(reducer<TemplateStateType>, ids, (ids) => ({
    data: getInitialData(store, ids),
    status: ids ? 'loading' : 'loaded',
  }));

  // useUpdateEffect would skip the one and only time this hook runs for new projects
  // meaning it would be stuck permanently in the loading state.
  useEffect(() => {
    if (templateId || projectId) return;

    // Quick path when navigating to a new template
    dispatch({
      type: 'LOADED',
      payload: getTemplateData(store, templateId),
    });
  }, [templateId, projectId]);

  useEffect(() => {
    if (!templateId) return;

    let canceled = false;

    const load = async () => {
      dispatch({
        type: 'LOADING',
      });

      try {
        await fetchTemplateData(store, templateId);

        // Check to avoid out-of-order updates
        if (!canceled) {
          dispatch({
            type: 'LOADED',
            payload: getTemplateData(store, templateId),
          });
        }
      } catch (err) {
        dispatch({
          type: 'ERROR',
        });
      }
    };

    load();

    return () => {
      canceled = true;
    };
  }, [templateId, store]);

  useEffect(() => {
    if (!projectId) return;

    let canceled = false;

    const load = async () => {
      dispatch({
        type: 'LOADING',
      });

      try {
        const results = await Promise.all([
          fetchProjectData(store, projectId),
          fetchOffsets(projectId),
        ]);
        const offsets = results.length === 2 ? results[1] : undefined;

        // Check to avoid out-of-order updates
        if (!canceled) {
          dispatch({
            type: 'LOADED',
            payload: getTemplateDataFromProject(store, projectId, offsets),
          });
        }
      } catch (err) {
        dispatch({
          type: 'ERROR',
        });
      }
    };

    load();

    return () => {
      canceled = true;
    };
  }, [projectId, store]);

  return {
    data: state.data,
    isLoading: state.status === 'loading',
    isError: state.status === 'error',
  };
}
