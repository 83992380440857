type ErrorWithMessage = {
  error: string;
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object' &&
    error !== null &&
    'error' in error &&
    typeof (error as Record<string, unknown>).error === 'string'
  );
}

export function getRequestError(error: unknown) {
  if (isErrorWithMessage(error)) return error.error;

  return 'Something went wrong, wait for some minutes and try again';
}
