import { clamp, cloneDeep, get, isFinite, isNumber } from 'lodash';
import manageModal from 'modalManager/manageModalActionCreator';

import { fetchLinkedEntities } from '@float/common/actions/tasks';
import {
  handleConnectorClick,
  handleLinkCancel,
  handleLinkClick,
  isEligibleLinkTarget,
} from '@float/common/components/Schedule/actions/_handleLinkInteraction';
import { isItemEntityArchived } from '@float/common/components/Schedule/Cell/MainCell/Item/box/utils/isItemEntityArchived';
import { TASK_EDIT_MODES } from '@float/common/components/Schedule/util/ContextMenu';
import { getIsItemEditable } from '@float/common/components/Schedule/util/getIsItemEditable';
import { SCROLL_ADJUSTMENT_COLS } from '@float/common/components/Schedule/Window/useInfiniteSizerEffect';
import { Rights } from '@float/common/lib/acl';
import parse from '@float/common/lib/parse';
import {
  entityCompleteable,
  entityLoggable,
  milestoneEditable,
  personTaskable,
  personTaskableWithProject,
} from '@float/common/lib/rights';
import { matchFilteredEntity } from '@float/common/search/helpers/matchFilteredEntity';
import { isDateInLockPeriod } from '@float/common/serena/util/lockPeriod';
import { now } from '@float/common/serena/util/timer';
import { todayManager } from '@float/libs/dates';
import { getTimeRangeCacheValue } from '@float/libs/timeRange';
import { measureInteractivity } from '@float/web/perfMonitoring/interactivity';

import { handlePersonCellClick } from './helpers/handlePersonCellClick';
import { isItemDraggable } from './helpers/isItemDraggable';
import { isTodayVisible as isTodayVisibleHelper } from './helpers/isTodayVisible';
import _persistChanges from './persistChanges';

const SIDEBAR_WIDTH = 390;

export default function createScheduleActions(props, nonScheduleActions) {
  const {
    rows,
    cells,
    mousePositionRef,
    dragInfo,
    autoscroller,
    reduxData,
    reduxDispatch,
    dates,
    dispatch,
    resizeInfo,
    animations,
    disableTooltips,
    cornerWidth,
    containerX = 0,
    dayWidth,
    setFetchDataEnabled,
    hourHeight,
    setDragItem,
    viewType,
    forceUpdate,
    scrollWrapperRef,
    boundsHelperRef,
    personCardDragInfo,
    setDraggedRowId,
    draggedRowGroupRef,
    baseColOffset,
    setBaseColOffset,
    numCols,
    setNumCols,
    numDays,
    visibleRangeRef,
    selectedItems,
    setSelectedItems,
    actionMode,
    splitInfo,
    height,
    suvSingleDay,
    logTimeView,
    logMyTimeView,
    suvPersonId,
    setSuvPersonId,
    setContextMenuPosition,
    linkInfo,
    setActionMode,
    setLinkInfo,
    setTimeRange,
  } = props;

  // cornerHeight will be 48
  // TopCell is 52 (4 additional px)
  // Top (functions) nav is 60
  const cornerHeight = props.cornerHeight + 4 + 60;

  function isRowEditable(row) {
    if (!row || !row.type) return false;
    const projectId = row.projectId || row.data.project_id;
    const project = reduxData.projects[projectId];

    if (row.type === 'project') {
      return milestoneEditable(project, reduxData.user);
    }

    if (row.type === 'person') {
      if (project) {
        return personTaskableWithProject(
          row.data,
          reduxData.user,
          project,
          logTimeView,
        );
      }

      return personTaskable(row.data, reduxData.user, logTimeView);
    }

    return false;
  }

  function duplicateItem(item) {
    const dup = cloneDeep(item);
    const tempId = `${Date.now()}${Math.random()}`;

    dup.entityId = tempId;
    dup.entity.temporaryId = tempId;

    delete dup.allInstances;
    delete dup.repeatInstances;
    delete dup.instanceCount;

    delete dup.entity.root_task_id;
    delete dup.entity.parent_task_id;

    if (dup.type === 'task') {
      dup.entity.originalId = dup.entity.task_id;
      dup.entity.task_id = tempId;
    } else if (dup.type === 'timeoff') {
      dup.entity.originalId = dup.entity.timeoff_id;
      dup.entity.timeoff_id = tempId;
    } else if (dup.type === 'loggedTime') {
      dup.entity.originalId = dup.entity.logged_time_id;
      dup.entity.logged_time_id = tempId;
    } else if (dup.type === 'milestone') {
      dup.entity.originalId = dup.entity.milestone_id;
      dup.entity.milestone_id = tempId;
    }

    return dup;
  }

  const persistChanges = _persistChanges(props, nonScheduleActions);

  const onTimeRangeChange = (originalEntity, entity, isRemove) => {
    const change = {
      type: 'timeRange',
      id: Number(entity.time_range_id),
      entity,
      originalEntity,
      isRemove,
    };
    persistChanges([change]);
    dispatch({ type: 'LOAD_DATA', dataType: 'timeRange', data: [entity] });
    setTimeRange(entity);
  };

  function addMilestoneOn(date, event) {
    if (logTimeView) return;
    if (reduxData.user.account_type_id === 4) return;

    nonScheduleActions.showEditMilestoneModal(
      {
        name: '',
        date,
        end_date: date,
      },
      {
        allowProjectSelection: true,
        allowPhaseSelection: true,
        event,
      },
    );
  }

  function isInLockPeriod(row, date) {
    return (
      isDateInLockPeriod(
        new Date(date),
        new Date(reduxData?.loggedTimeLockPeriodDates?.latest),
      ) && !entityLoggable({ people_id: row?.data?.people_id }, reduxData, true)
    );
  }

  function isItemEditable(item) {
    return getIsItemEditable(reduxData, logTimeView, item);
  }

  const scheduleActions = {
    persistChanges,

    isItemEditable,
    isRowEditable,

    isItemDraggable: (item) =>
      isItemDraggable({
        item,
        serenaState: reduxData,
        actionMode,
        selectedItems,
        isItemEditable,
      }),

    isItemCompleteable(item) {
      return (
        item.type === 'task' &&
        item.entity.status !== 1 &&
        !item.entity.repeat_state &&
        reduxData.projects[item.entity.project_id]?.active &&
        entityCompleteable(item.entity, reduxData.user, reduxData)
      );
    },

    isGhostItem(item) {
      if (!reduxData.filters.length) return false;

      return !matchFilteredEntity(
        reduxData.filteredEntities,
        item.type,
        item.entityId,
      );
    },

    onCellMouseDown() {
      const { current: smp } = mousePositionRef;
      const row = rows[smp.rowIdx];

      if (actionMode === TASK_EDIT_MODES.SPLIT) {
        scheduleActions.confirmSplitItem();
        return;
      }

      if (actionMode === TASK_EDIT_MODES.LINK) {
        handleLinkCancel(props);
        return;
      }

      if (!isRowEditable(row)) {
        return;
      }

      // if (
      //   row.isLogTimeRow &&
      //   isInLockPeriod(row, dates.fromDescriptor(smp.colIdx, smp.subCol))
      // ) {
      //   return;
      // }

      const initialEntity = {
        start_date: dates.fromDescriptor(smp.colIdx, smp.subCol),
        end_date: dates.fromDescriptor(smp.colIdx, smp.subCol),
      };

      if (row.type === 'person') {
        initialEntity.people_id = row.data.people_id;
        initialEntity.projectViewProjectId = row.projectId;
      }

      if (row.type === 'project') {
        initialEntity.project_id = row.data.project_id;
        initialEntity.isProjectRow = true;
      }

      const upHandler = (e) => {
        const { entity } = dragInfo.current;

        if (entity) {
          if (logTimeView) {
            if (entity.people_id) {
              const { project_id } = reduxData.defaultDropdownProject;
              nonScheduleActions.showLogTimeModal({
                entity: {
                  date: entity.start_date,
                  project_id: entity.projectViewProjectId || project_id,
                  people_id: entity.people_id,
                },
              });
            }
          } else {
            if (row.type === 'project') {
              if (entity.start_date == entity.end_date) {
                nonScheduleActions.showEditMilestoneModal(
                  {
                    project_id: entity.project_id,
                    name: '',
                    date: entity.start_date,
                    end_date: entity.end_date,
                  },
                  {
                    allowPhaseSelection: true,
                    event: e,
                  },
                );
                // cannot create new phases if the project is non editable
                // fixes https://rollbar.com/float/fe-web-app/items/2162/
              } else if (row.data.canEdit) {
                nonScheduleActions.showEditPhaseModal(row.data, {
                  phase_name: '',
                  start_date: entity.start_date,
                  end_date: entity.end_date,
                });
              }
            }

            if (row.type === 'person') {
              const selection = cells._helpers.getCurrentSelection();
              const person = reduxData.allPeople[row.data.people_id];
              const project = entity.projectViewProjectId
                ? reduxData.projects[entity.projectViewProjectId]
                : undefined;

              handlePersonCellClick({
                selection,
                person,
                project,
                nonScheduleActions,
                user: reduxData.user,
              });
            }
          }
        }

        autoscroller.disableHorizontal();
        dispatch({ type: 'REMOVE_SELECTION' });
        dragInfo.current = {};
      };

      dragInfo.current = {
        isCreating: true,
        startMousePosition: smp,
        entity: initialEntity,
      };

      autoscroller.enableHorizontal();
      dispatch({
        type: 'SET_SELECTION',
        entity: initialEntity,
        rowId: rows[smp.rowIdx].id,
      });
      window.addEventListener('mouseup', upHandler, { once: true });
    },

    onCellDrag() {
      const { current: mpr } = mousePositionRef;
      const { startMousePosition: smp } = dragInfo.current;

      if (!rows[smp.rowIdx]) {
        dragInfo.current = {};
        return;
      }

      if (mpr !== dragInfo.current.endMousePosition) {
        dragInfo.current.endMousePosition = mpr;

        const start = dates.fromDescriptor(smp.colIdx, smp.subCol);
        const end = dates.fromDescriptor(mpr.colIdx, mpr.subCol);
        const isCreatingTimeRange = dragInfo.current.entity.time_range_id;
        if (logTimeView && !isCreatingTimeRange) {
          // In log time view, we only support creating a single record at a
          // time. Therefore, we only want to highlight one cell at a time.
          dragInfo.current.entity = {
            ...dragInfo.current.entity,
            start_date: end,
            end_date: end,
          };
        } else {
          dragInfo.current.entity = {
            ...dragInfo.current.entity,
            start_date: start < end ? start : end,
            end_date: start > end ? start : end,
          };
        }

        dispatch({
          type: 'SET_SELECTION',
          rowId: rows[smp.rowIdx].id,
          entity: dragInfo.current.entity,
        });
      }
    },

    onNonWorkDayClick(event, row, date) {
      const { current: smp } = mousePositionRef;

      if (!date && smp && typeof smp.rowIdx !== 'undefined') {
        row = rows[smp.rowIdx];
        date = dates.fromDescriptor(smp.colIdx, smp.subCol);
      }

      if (!date) {
        // The user clicked on a non-work day without a known mouseposition.
        // The only way I can think of for this to happen is if they refresh the
        // page and click without moving the mouse. In this case, do nothing -
        // they'll most likely move the mouse a little and try again.
        return;
      }

      // If the item falls within a lock period, we ignore the click event
      if (row.isLogTimeRow && isInLockPeriod(row, date)) {
        return;
      }

      if (actionMode === TASK_EDIT_MODES.SPLIT) {
        scheduleActions.confirmSplitItem();
        return;
      }

      if (actionMode === TASK_EDIT_MODES.LINK) {
        handleLinkCancel(props);
        return;
      }

      if (!isRowEditable(row)) return;

      // Only tasks can be added to non-work days. We only need to check
      // if current user has the rights to create a task for that person.
      const canCreateTaskForPerson = row.isLogTimeRow
        ? Rights.canCreateLoggedTime(reduxData.user, {
            person: row.data,
            ignore: [
              'isLoggedTimeProjectOwner',
              'onLoggedTimeProjectTeam',
              'loggedTimeProjectIsCommon',
            ],
          })
        : Rights.canCreateTaskForPeople(reduxData.user, [row.data]);
      if (!canCreateTaskForPerson) return;

      if (row.data.start_date && row.data.start_date > date) return;
      if (row.data.end_date && row.data.end_date < date) return;

      const error = logTimeView
        ? { message: 'Log time on a non-work day?' }
        : { message: 'Schedule on a non-work day?' };

      const cell = {
        coords: {
          x: event.clientX,
          y: event.clientY,
        },
      };

      const modalProps = {
        submitColor: 'green',
        cancelColor: 'gray',
        submitLabel: 'Yes',
        onSubmit: () => {
          if (logTimeView) {
            const { project_id } = reduxData.defaultDropdownProject;

            nonScheduleActions.showLogTimeModal({
              entity: {
                date,
                project_id: row.projectId || project_id,
                people_id: row.data.people_id,
              },
            });
          } else {
            // We need to set the current day to a one-off day so that the
            // entity date/length calculation functions in ETM are aware that
            // it's a scheduleable day. We'll persist this change on save, or
            // undo on hide.
            const tentativeChanges = [];
            const tempId = `${Date.now()}${Math.random()}`;
            tentativeChanges.push({
              type: 'oneOff',
              id: tempId,
              isCreate: true,
              entity: {
                date,
                people_id: row.data.people_id,
                oneoff_id: tempId,
              },
            });
            cells._helpers.loadTentativeChanges(tentativeChanges);

            nonScheduleActions.showAddTaskModal(
              reduxData.allPeople[row.data.people_id],
              {
                tentativeChanges,
                start_date: date,
                end_date: date,
                offWork: date,
                project: row.projectId
                  ? reduxData.projects[row.projectId]
                  : undefined,
              },
            );
          }
        },
        cell,
        textSize: 'big',
        newButtons: true,
      };

      reduxDispatch(
        manageModal({
          visible: true,
          modalType: 'errorModal',
          useLegacy: true,
          modalSettings: {
            error,
            props: modalProps,
          },
        }),
      );
    },

    onItemMouseDown(item) {
      dragInfo.current.startMousePosition = mousePositionRef.current;
    },

    linkDelete(item) {
      handleConnectorClick(props, item);
    },

    onItemClick(item, event) {
      if (item.type === 'timeRange') {
        const cls = event?.target?.classList;
        // We want to avoid handling clicks on pinned arrows
        const clickedOnTimeRange = cls && cls.contains('time-range-box');
        if (clickedOnTimeRange) {
          const { startMousePosition: smp } = dragInfo.current;
          const date = dates.fromDescriptor(smp.colIdx, smp.subCol);
          return addMilestoneOn(date, event);
        }
        return;
      }

      if (actionMode === TASK_EDIT_MODES.LINK) {
        handleLinkClick(props, item);
        return;
      }

      if (
        actionMode === TASK_EDIT_MODES.SPLIT &&
        splitInfo.current.duplicateItem
      ) {
        scheduleActions.confirmSplitItem();
        return;
      }

      if (
        actionMode === TASK_EDIT_MODES.SPLIT &&
        splitInfo.current.duplicateItem
      ) {
        scheduleActions.confirmSplitItem();
        return;
      }

      if (
        actionMode === TASK_EDIT_MODES.SPLIT &&
        !splitInfo.current.item &&
        item.type !== 'milestone'
      ) {
        nonScheduleActions.showSingleDaySplitModal();
        return;
      }

      if (item.entity.temporaryId) {
        return;
      }

      if (item.type === 'loggedTime') {
        const isReference = item.entity.isTaskReference;
        const isEditable = isItemEditable(item);
        const isArchived = isItemEntityArchived(item.entity, reduxData);

        if (isReference && !isEditable) return;

        if (
          actionMode === TASK_EDIT_MODES.LOG_TIME &&
          isReference &&
          isEditable
        ) {
          const changes = [
            {
              type: item.type,
              id: item.entityId,
              entity: item.entity,
            },
          ];
          scheduleActions.persistChanges(changes);
          return;
        }

        if (actionMode === TASK_EDIT_MODES.DELETE && isEditable) {
          // open the log time modal instead, when attempting to delete
          // logged entries associateed with archived projects / phases
          // this is the same behaviour currently for allocations
          // https://linear.app/float-com/issue/WIN-357
          if (isArchived) {
            nonScheduleActions.showLogTimeModal(item);
            return;
          }
          // -

          const changes = [
            {
              type: item.type,
              id: item.entityId,
              originalEntity: cloneDeep(item.entity),
              entity: {
                ...item.entity,
                hours: 0,
              },
            },
          ];
          scheduleActions.persistChanges(changes);
          return;
        }

        if (actionMode === TASK_EDIT_MODES.ADD_EDIT) {
          nonScheduleActions.showLogTimeModal(item);
        }

        return;
      }

      if (item.type === 'task' && actionMode === TASK_EDIT_MODES.COMPLETE) {
        if (scheduleActions.isGhostItem(item)) return;
        if (scheduleActions.isItemCompleteable(item)) {
          const originalEntity = cloneDeep(item.entity);
          item.entity.status = item.entity.status === 2 ? 3 : 2;

          const changes = [
            {
              type: item.type,
              id: item.entityId,
              entity: item.entity,
              originalEntity,
            },
          ];

          scheduleActions.persistChanges(changes);
          return;
        }
      }

      if (
        !/(milestone|phase|timeRange)/.test(item.type) &&
        actionMode === TASK_EDIT_MODES.DELETE
      ) {
        if (scheduleActions.isGhostItem(item)) return;
        if (
          !isItemEntityArchived(item.entity, reduxData) &&
          scheduleActions.isItemEditable(item)
        ) {
          const changes = [
            {
              type: item.type,
              id: item.entityId,
              entity: item.entity,
              originalEntity: cloneDeep(item.entity),
              isRemove: true,
              clickedOnPersonId: item.rowId?.split('-')[1],
            },
          ];

          scheduleActions.persistChanges(changes);
          return;
        }
      }

      if (item.type === 'milestone') {
        const project = reduxData.projects[item.entity.project_id];
        if (milestoneEditable(project, reduxData.user)) {
          nonScheduleActions.showEditMilestoneModal(item.entity, {
            allowPhaseSelection: true,
            readOnly: logTimeView,
          });
        }
        return;
      }

      if (item.type === 'phase') {
        const project = reduxData.projects[item.entity.project_id];

        // Note: The permission check for milestone and phase is the same
        if (milestoneEditable(project, reduxData.user)) {
          nonScheduleActions.showEditPhaseModal(
            project,
            item.entity,
            logTimeView,
          );
        }

        return;
      }

      const isTimeoff = item.type === 'timeoff';
      const isStatus = item.type === 'status';

      const model = {
        ...item.entity,
        isTimeoff,
        isStatus,
        forceReadOnly: !!logTimeView,
        clickedOnPersonId: item.rowId?.split('-')[1],
      };

      if (isStatus) {
        model.people_ids = [model.people_id];
        delete model.people_id;
      }

      if (!isTimeoff && !isStatus) {
        model.project = reduxData.projects[item.entity.project_id];
      }

      model.offWork =
        !logTimeView &&
        !item.entity.repeat_state &&
        item.entity.start_date === item.entity.end_date &&
        cells._helpers.isOneOffDay(item.cellKey, item.entity.start_date);

      nonScheduleActions.showEditTaskModal(model);
    },

    onItemDragStart({ item, shiftKey }) {
      const smp = dragInfo.current.startMousePosition;
      if (!smp || !isFinite(smp.subCol)) {
        return false;
      }

      let items = [item];

      Object.keys(selectedItems).forEach((i) => {
        const selectedItem = selectedItems[i];
        const { type, entityId } = selectedItem;
        if (!items.some((i2) => i2.type === type && i2.entityId == entityId)) {
          items.push(selectedItem);
        }
      });

      let currentRowIndex = mousePositionRef.current.rowIdx;
      let row = rows[currentRowIndex];

      if (item.isSidebarItem) {
        if (item.restrictToPersonId) {
          const prefix = logTimeView ? 'logged_time' : 'person';
          const restrictedRowId = `${prefix}-${item.restrictToPersonId}`;
          currentRowIndex = rows.findIndex((x) => x.id === restrictedRowId);
          row = rows[currentRowIndex];
        }

        if (row.type === 'project') {
          return false;
        }

        const rowProjectId = row.projectId;
        if (rowProjectId && rowProjectId !== item.entity.project_id) {
          return false;
        }

        items[0] = duplicateItem(items[0]);

        // Setting the base values here gives the most predictable
        // placeholder positioning when dragging a sidebar item.
        const start = dates.fromDescriptor(smp.colIdx, smp.subCol);
        items[0].baseValues = {
          length: item.days,
          start_date: start,
          end_date: dates.addDays(start, item.days - 1),
        };
      } else if (shiftKey) {
        for (let i = 0; i < items.length; i++) {
          items[i] = duplicateItem(items[i]);
        }

        cells._helpers.loadChanges(items);
      }

      items = items.map((i, idx) => {
        const dragItem = {
          type: i.type,
          entityId: i.entityId,
          rowId: i.rowId,
          fullDayTimeoff: i.fullDayTimeoff,
          entity: i.entity,
          baseValues: i.baseValues,
        };

        if (idx === 0) {
          dragItem.isStart = item.isStart;
          dragItem.colIdx = smp.colIdx;
          dragItem.instanceCount = i.entity.instanceCount;
          dragItem.activeDragged = true;
        }

        return dragItem;
      });

      dragInfo.current = {
        items,
        dayDelta: smp.subCol - item.x,
        dayStart: dates.fromDescriptor(smp.colIdx, smp.subCol),
        curRowIdx: currentRowIndex,
        priorityUpdates: {},
        originalProjectId: row.projectId,
        originalPeopleId: row.peopleId,
      };

      autoscroller.enable();
      disableTooltips.current = true;

      if (items[0].entity?.root_task_id) {
        // If dragInfo.current.pending holds a promise by the time the user
        // stops dragging, there's a slight UI flicker. Clear the value after
        // the promise resolves to prevent this in most cases.
        dragInfo.current.pending = reduxDispatch(
          fetchLinkedEntities(items[0].entityId),
        ).then(
          () => {
            dragInfo.current.pending = undefined;
          },
          (err) => {
            logger.error('Failed to fetch linked entities', err);
          },
        );
      }

      return true;
    },

    onItemDrag(dragX) {
      const mpr = mousePositionRef.current;
      const di = dragInfo.current;

      const prevRowIdx = di.curRowIdx;
      const curDay = dates.fromDescriptor(mpr.colIdx, mpr.subCol);
      const dayDelta = dates.diffDays(di.dayStart, curDay);

      const candidate = rows[mpr.rowIdx];
      const singleItem = di.items && di.items.length === 1 && di.items[0];
      const isSidebarItem = get(singleItem, 'entity.isSidebarItem');

      if (singleItem && isRowEditable(candidate)) {
        if (viewType === 'projects') {
          if (
            candidate.type === 'person' &&
            candidate.projectId === di.originalProjectId
          ) {
            di.curRowIdx = mpr.rowIdx;
          }
        } else {
          di.curRowIdx = mpr.rowIdx;
        }

        if (logTimeView && candidate.peopleId !== di.originalPeopleId) {
          di.curRowIdx = prevRowIdx;
        }
      }

      const isUnitializedSidebarItem =
        isSidebarItem && !singleItem.hasLeftSidebar;

      if (
        !di.direction ||
        di.dayDelta !== dayDelta ||
        di.curRowIdx !== prevRowIdx ||
        isUnitializedSidebarItem
      ) {
        di.previousDayDelta = di.dayDelta;
        di.dayDelta = dayDelta;

        if (di.dayDelta > di.previousDayDelta) {
          di.direction = 'R';
        } else if (di.dayDelta < di.previousDayDelta || !di.direction) {
          di.direction = 'L';
        }

        if (isUnitializedSidebarItem) {
          const hasLeftSidebar = dragX < window.innerWidth - SIDEBAR_WIDTH;
          if (!hasLeftSidebar) {
            return;
          }

          di.items[0].hasLeftSidebar = true;
          cells._helpers.loadChanges(di.items);
        }

        measureInteractivity.start('DRAG_ENTITY');
        dispatch({
          type: 'DRAG_ENTITY',
          items: di.items,
          direction: di.direction,
          dayDelta,
          rowId: rows[di.curRowIdx].id,
        });
      }
    },

    async onItemDragStop() {
      autoscroller.disable();

      if (dragInfo.current.pending) {
        await dragInfo.current.pending;

        // Wait a couple of ticks to let the loaded data make its way into cells
        await new Promise((resolve) => setTimeout(resolve, 50));
      }

      measureInteractivity.complete('DRAG_ENTITY');
      dispatch({
        type: 'DRAG_ENTITY_STOP',
        items: dragInfo.current.items,
      });

      dragInfo.current = {};
      disableTooltips.current = false;
    },

    onSidebarItemDragStop() {
      autoscroller.disable();

      const { current: smp } = mousePositionRef;
      const row = rows[smp.rowIdx];

      const [item] = dragInfo.current.items;

      if (item.hasLeftSidebar) {
        const { entity } = item;

        if (entity) {
          if (row.type === 'person') {
            const person = reduxData.allPeople[row.data.people_id];
            if (logTimeView) {
              entity.people_id = person.people_id;
            } else {
              const task = parse.getTaskModel(person, reduxData.user, {
                start_date: entity.start_date,
                end_date: entity.end_date,
                project: entity.project_id
                  ? reduxData.projects[entity.project_id]
                  : undefined,
              });
              item.h = entity.hours = isNumber(entity.hours)
                ? entity.hours
                : task.hours_pd;
            }
          }
        }

        dispatch({
          type: 'DRAG_ENTITY_STOP',
          items: [item],
        });
      }

      dragInfo.current = {};
      disableTooltips.current = false;
    },

    onItemResizeStart(item, edge) {
      const shouldStartResizing = item && resizeInfo.current.item !== item;
      if (shouldStartResizing) {
        const upHandler = () => {
          animations.enable();
          disableTooltips.current = false;

          window.removeEventListener('mousemove', moveHandler);

          measureInteractivity.complete('RESIZE_ENTITY');

          dispatch({
            type: 'RESIZE_ENTITY_STOP',
            item: resizeInfo.current.item,
            originalEntity: resizeInfo.current.originalEntity,
          });

          autoscroller.disable();
          resizeInfo.current = {};
        };

        const moveHandler = (e) => {
          mousePositionRef.current.y = e.clientY - cornerHeight;
          scheduleActions.onItemResize();
        };

        const smp = mousePositionRef.current;

        animations.disable();
        disableTooltips.current = true;
        if (edge === 'R' || edge === 'L') {
          autoscroller.enable();
        }

        resizeInfo.current = {
          item,
          originalEntity: cloneDeep(item.entity),
          edge,
          startMouseY: smp.y,
          hourDelta: 0,
          dayDelta: 0,
          dayStart:
            edge === 'R' ? item.entity.end_date : item.entity.start_date,
        };

        // Since we resize the task live and don't have a floating version
        // (like we do with Dragitem) that sits under the user's mouse, we
        // need to bind the mouseup handler on the window so that the event
        // fires even if the user doesn't have their mouse directly on the
        // task they're resizing when they let go.
        window.addEventListener('mouseup', upHandler, { once: true });

        window.addEventListener('mousemove', moveHandler);
      }
    },

    onItemResize() {
      const mpr = mousePositionRef.current;
      const ri = resizeInfo.current;

      if (ri.edge === 'B') {
        const roundUnit = 1 / 0.25;
        // Enable this if we want to have a configurable resize options
        // const roundUnit = 1 / getStepValue(reduxData.user.time_increment_unit);
        // We want to update not jump from 5 to 4.8 and just reduce sensivity.
        const hourHeightReaction = hourHeight;

        // Enable this if we want to have a flexible resize
        // const hourHeightReaction = reduxData.user.time_increment_unit <= 6 && hourHeight < 10
        //   ? 10
        //   : hourHeight;
        const hourDelta =
          Math.floor(
            ((mpr.y - ri.startMouseY) / hourHeightReaction) * roundUnit,
          ) / roundUnit;

        if (Number.isNaN(hourDelta)) {
          throw Error(`Bad hourDelta,
            mpr.y:[${mpr.y}],
            ri.startMouseY:[${ri.startMouseY}],
            hourHeight:[${hourHeight}]`);
        }

        if (ri.hourDelta !== hourDelta) {
          ri.hourDelta = hourDelta;

          dispatch({
            type: 'RESIZE_ENTITY_VERTICAL',
            item: ri.item,
            originalEntity: ri.originalEntity,
            hourDelta,
          });
        }

        measureInteractivity.start('RESIZE_ENTITY');
        return;
      }

      const curDay = dates.fromDescriptor(mpr.colIdx, mpr.subCol);
      const dayDelta = dates.diffDays(ri.dayStart, curDay);

      if (ri.dayDelta !== dayDelta) {
        ri.dayDelta = dayDelta;

        measureInteractivity.start('RESIZE_ENTITY');

        dispatch({
          type: 'RESIZE_ENTITY_HORIZONTAL',
          item: ri.item,
          originalEntity: ri.originalEntity,
          edge: ri.edge,
          dayDelta,
        });
      }
    },

    onPersonCardDragStart(row, rowGroupTop, rowHeight) {
      personCardDragInfo.current = {
        startScrollTop: scrollWrapperRef.current.scrollTop,
        startY: rowGroupTop,
        row,
        destIdx: rows.indexOf(row),
        rowHeight,
        downward: true,
        prevDelta: 0,
        prevScrollTop: scrollWrapperRef.current.scrollTop,
      };

      setDraggedRowId(row.key);

      autoscroller.enableVertical(() => {
        // If the user has a card held and has triggered autoscroll, we want to
        // resort while autoscroll is happening.
        scheduleActions.onPersonCardDrag([
          0,
          personCardDragInfo.current.prevDelta,
        ]);
      });
    },

    onPersonCardDrag(delta) {
      if (!draggedRowGroupRef.current) return;

      const { current: pcdi } = personCardDragInfo;
      const { prevDelta, prevScrollTop } = pcdi;
      const { bounds } = boundsHelperRef.current;
      const { scrollTop } = scrollWrapperRef.current;

      let y = delta[1] + scrollTop - pcdi.startScrollTop + pcdi.startY;
      y = clamp(y, 0, bounds[bounds.length - 1].offset);

      if (delta[1] !== prevDelta || scrollTop !== prevScrollTop) {
        pcdi.downward = delta[1] + scrollTop > prevDelta + prevScrollTop;
        pcdi.prevDelta = delta[1]; // eslint-disable-line
        pcdi.prevScrollTop = scrollTop;
      }

      draggedRowGroupRef.current.style.transform = `translate(0, ${y}px)`;
      draggedRowGroupRef.current.style.transition = 'none';

      let { destIdx } = pcdi;

      if (pcdi.downward) {
        // When moving downward, use the bottom edge of the card compared to the
        // middle of the next card.
        let b = bounds[destIdx + 1];
        while (
          destIdx < bounds.length - 1 &&
          y + pcdi.rowHeight > b.offset + b.size / 2
        ) {
          destIdx++;
          b = bounds[destIdx + 1];
        }
      } else {
        // When moving upward, use the top edge of the card compared to the
        // middle of the previous card.
        let b = bounds[destIdx - 1];
        while (destIdx > 0 && b.offset + b.size / 2 > y) {
          destIdx--;
          b = bounds[destIdx - 1];
        }
      }

      if (destIdx !== pcdi.destIdx) {
        const nextIdx = pcdi.downward ? pcdi.destIdx + 1 : pcdi.destIdx - 1;
        const draggingOverProject = rows[nextIdx]?.type === 'project';
        if (draggingOverProject) {
          return;
        }
        // Note that this mutation is "safe" because useScheduleRows prevents
        // rows from being updated as long as there's a draggedRowId. When the
        // object reference to rows changes, the whole schedule re-renders, so
        // we need to do this manually while the user is dragging for speed.
        const [row] = rows.splice(pcdi.destIdx, 1);
        pcdi.destIdx = destIdx;
        rows.splice(pcdi.destIdx, 0, row);
        rows._customSortUpdatedAt = now();
        forceUpdate();
      }
    },

    onPersonCardDragStop() {
      autoscroller.disableVertical();

      if (draggedRowGroupRef.current) {
        draggedRowGroupRef.current.style.transform = '';
        draggedRowGroupRef.current.style.transition = '';
      }

      nonScheduleActions.updatePersonCustomPosition(
        personCardDragInfo.current.row.key,
        personCardDragInfo.current.destIdx,
      );

      personCardDragInfo.current = null;
      setDraggedRowId(null);
    },

    onPersonCardClick(person) {
      nonScheduleActions.showPersonModal(person);
    },

    trackMouse(
      colIdx,
      rowIdx,
      x,
      y,
      cellY,
      offsetLeft,
      rawY,
      clientX,
      clientY,
    ) {
      x = x - cornerWidth - containerX;
      y = y - cornerHeight;

      if (x > dayWidth * numDays) {
        // Phase items are rendered only on the first cell and overflow, which
        // means that the mousemove event fired from the first cell.
        const colAdjustment = Math.floor(x / (dayWidth * numDays));
        colIdx += colAdjustment;
        x -= colAdjustment * dayWidth * numDays;
      }

      const subCol = Math.floor(x / dayWidth);

      mousePositionRef.current.rawX = offsetLeft + x;
      mousePositionRef.current.rawY = rawY - cornerHeight;
      mousePositionRef.current.clientX = clientX;
      mousePositionRef.current.clientY = clientY;

      const oldPosition = mousePositionRef.current;
      let newPosition = oldPosition;

      if (
        oldPosition.colIdx !== colIdx ||
        oldPosition.rowIdx !== rowIdx ||
        oldPosition.subCol !== subCol
      ) {
        newPosition = {
          colIdx,
          rowIdx,
          subCol,
          eligibleLinkTarget: mousePositionRef.current.eligibleLinkTarget,
        };
        mousePositionRef.current = newPosition;
      }

      mousePositionRef.current.y = y;

      if (dragInfo.current.items && dragInfo.current.items.length === 1) {
        cellY -= cornerHeight;
        const cellHour = Math.floor((cellY / hourHeight) * 4) / 4;

        if (dragInfo.current.cellHour !== cellHour) {
          dragInfo.current.cellHour = cellHour;
          dispatch({
            type: 'DRAG_SORT',
            item: dragInfo.current.items[0],
            rowId: rows[rowIdx].id,
            colIdx,
            cellHour,
            priorityUpdates: dragInfo.current.priorityUpdates,
            direction: dragInfo.current.direction,
          });
        }
      }

      if (dragInfo.current.isCreating) {
        scheduleActions.onCellDrag();
      }

      if (actionMode === TASK_EDIT_MODES.SPLIT) {
        if (splitInfo.current.item && oldPosition !== newPosition) {
          const si = splitInfo.current;
          const day = dates.fromDescriptor(colIdx, subCol);
          const end = si.duplicateItem
            ? si.duplicateItem.entity.end_date
            : si.item.entity.end_date;
          if (
            splitInfo.current.item.rowId !== rows[rowIdx]?.id ||
            day < si.item.entity.start_date ||
            day > end
          ) {
            scheduleActions.clearSplitItem();
          } else {
            scheduleActions.splitItem();
          }
        }
      }
    },

    clearSplitItem() {
      dispatch({
        type: 'SPLIT',
        item: splitInfo.current.item,
        duplicateItem: splitInfo.current.duplicateItem,
        day: null,
      });
      splitInfo.current = {};
    },

    confirmSplitItem() {
      const si = splitInfo.current;
      if (!si.duplicateItem) return;

      dispatch({
        type: 'SPLIT_CONFIRM',
        item: si.item,
        originalEntity: si.originalEntity,
        duplicateItem: si.duplicateItem,
      });

      splitInfo.current = {};
    },

    splitItem() {
      const si = splitInfo.current;
      const mpr = mousePositionRef.current;

      if (!si.item) return;
      if (si.item.entity.repeat_state) return;
      if (si.item.entity.temporaryId) return;

      if (si.item.type === 'task') {
        if (!reduxData.projects[si.item.entity.project_id].active) return;
      }

      if (!scheduleActions.isItemEditable(si.item)) {
        return;
      }

      if (!si.duplicateItem) {
        si.originalEntity = cloneDeep(si.item.entity);
        si.duplicateItem = duplicateItem(si.item);
        si.item.entity.preSplitStart = si.originalEntity.start_date;
        si.item.entity.preSplitEnd = si.originalEntity.end_date;
        si.duplicateItem.entity.preSplitStart = si.originalEntity.start_date;
        si.duplicateItem.entity.preSplitEnd = si.originalEntity.end_date;
      }

      if (si.mousePosition !== mpr) {
        si.mousePosition = mpr;

        const day = dates.fromDescriptor(mpr.colIdx, mpr.subCol);
        if (day > si.duplicateItem.entity.end_date) {
          // If the user triggered split while an item was set but they were
          // hovering outside of it, we want to skip doing any splitting.
          return;
        }

        dispatch({
          type: 'SPLIT',
          item: si.item,
          duplicateItem: si.duplicateItem,
          day,
        });
      }
    },

    trackHoveredItem(item) {
      if (actionMode === TASK_EDIT_MODES.LINK) {
        if (!linkInfo?.base) return;
        const mpr = mousePositionRef.current;

        if (item && isEligibleLinkTarget(linkInfo, item)[0]) {
          mpr.eligibleLinkTarget = cells._helpers.getLinkTargetItem(item);
        } else {
          mpr.eligibleLinkTarget = null;
        }

        return;
      }

      if (!item) return;

      let isDifferentItem = true;

      if (
        splitInfo.current.item &&
        splitInfo.current.item.type === item.type &&
        splitInfo.current.item.entityId === item.entityId
      ) {
        isDifferentItem = false;
      }

      if (
        splitInfo.current.duplicateItem &&
        splitInfo.current.duplicateItem.type === item.type &&
        splitInfo.current.duplicateItem.entityId === item.entityId
      ) {
        isDifferentItem = false;
      }

      if (splitInfo.current.duplicateItem && isDifferentItem) {
        scheduleActions.clearSplitItem();
      }

      if (isDifferentItem) {
        // Single-day tasks are not splittable
        if (item.entity.start_date !== item.entity.end_date) {
          splitInfo.current.item = item;
        }
      }

      if (actionMode === TASK_EDIT_MODES.SPLIT) {
        scheduleActions.splitItem();
      }
    },

    scrollToCol(colIdx, subCol = 0) {
      let bco = baseColOffset;
      let nc = numCols;
      let needsAdjustment = false;

      // We keep the base canvas size small on initial load to make dragging on
      // the scrollbar easier and less twitchy. useInfiniteSizerEffect takes
      // care of adjusting the left and right available area as you scroll, but
      // if the user wants to jump really far, we might not be able to scroll
      // immediately. In this case, we have to adjust the bounds and then
      // perform the scroll.
      if (bco > colIdx) {
        while (bco > colIdx) {
          bco -= SCROLL_ADJUSTMENT_COLS;
        }
        setBaseColOffset(Math.max(0, bco));
        needsAdjustment = true;
      } else if (bco + nc < colIdx) {
        while (bco + nc < colIdx) {
          nc += SCROLL_ADJUSTMENT_COLS;
        }
        setNumCols(nc);
        needsAdjustment = true;
      }

      function performScroll() {
        if (!scrollWrapperRef.current) return;

        const scrollLeft =
          numDays * dayWidth * (colIdx - bco) + dayWidth * subCol + 1;

        scrollWrapperRef.current.scrollLeft = scrollLeft;
      }

      if (needsAdjustment) {
        setFetchDataEnabled(false);
        setTimeout(performScroll, 0);
      } else {
        performScroll();
      }
    },

    scrollWeeks(numWeeks) {
      scheduleActions.scrollToCol(
        visibleRangeRef.current.colStart + numWeeks + 1,
      );
    },

    scrollSuvPersonId(num) {
      const {
        activePeople,
        user: { account_tid },
      } = reduxData;

      const canChoosePerson = account_tid != 4;
      if (!canChoosePerson) return;

      const curIdx = activePeople.findIndex((p) => p.people_id == suvPersonId);
      const newIdx = clamp(curIdx + num, 0, activePeople.length - 1);
      const newPersonId = activePeople[newIdx].people_id;

      setSuvPersonId(newPersonId);
    },

    scrollRows(num) {
      if (logMyTimeView) {
        scheduleActions.scrollSuvPersonId(num);
        return;
      }
      scrollWrapperRef.current.scrollTop += num * (height / 2);
    },

    scrollToToday() {
      const [colIdx, subCol] = dates.toDescriptor(todayManager.getToday());
      scheduleActions.scrollToCol(colIdx, dayWidth === 245 ? subCol : 0);
    },

    isItemSelected(item) {
      return !!selectedItems[`${item.type}-${item.entityId}`];
    },

    hideResize() {
      return (
        Object.entries(selectedItems).length > 0 ||
        actionMode !== TASK_EDIT_MODES.ADD_EDIT
      );
    },

    toggleItemSelected(item) {
      // Repeat entities are not multiselectable
      if (item.entity.repeat_state) return;

      // Linked tasks are not multiselectable
      if (item.entity.root_task_id) return;

      if (item.entity.project_id) {
        // Tasks on archived projects are not multiselectable
        if (!reduxData.projects[item.entity.project_id].active) {
          return;
        }

        // You have to be able to edit an item to multiselect it
        if (!scheduleActions.isItemEditable(item)) {
          return;
        }
      }

      const key = `${item.type}-${item.entityId}`;
      setSelectedItems((prev) => {
        const newItems = { ...prev };
        if (prev[key]) {
          delete newItems[key];
        } else {
          newItems[key] = {
            type: item.type,
            entityId: item.entityId,
            rowId: item.rowId,
            fullDayTimeoff: item.type === 'timeoff' && item.entity.full_day,
            entity: item.entity,
          };
        }
        return newItems;
      });
    },

    getOverrideCursor(item) {
      if (item.type === 'milestone') return 'pointer';

      if (item.entity.project_id) {
        const isArchived = isItemEntityArchived(item.entity, reduxData);
        if (isArchived) {
          return 'pointer';
        }
      }

      if (actionMode === TASK_EDIT_MODES.COMPLETE) {
        if (item.type === 'timeoff') return 'pointer';
        if (!scheduleActions.isItemCompleteable(item)) return 'pointer';
      }

      if (actionMode === TASK_EDIT_MODES.DELETE) {
        if (!scheduleActions.isItemEditable(item)) return 'pointer';
      }

      if (actionMode === TASK_EDIT_MODES.SPLIT) {
        if (item.entity.repeat_state) return 'pointer';
        if (item.entity.temporaryId) return null;
        if (!scheduleActions.isItemEditable(item)) return 'pointer';
      }

      if (
        actionMode === TASK_EDIT_MODES.INSERT ||
        actionMode === TASK_EDIT_MODES.REPLACE
      ) {
        if (item.entity && item.entity.temporaryId) return 'default';
      }

      if (
        actionMode === TASK_EDIT_MODES.INSERT ||
        actionMode === TASK_EDIT_MODES.REPLACE ||
        actionMode === TASK_EDIT_MODES.ADD_EDIT
      ) {
        // Insert and replace take effect on the cells behind tasks, not on
        // tasks themselves.
        return 'pointer';
      }

      return null;
    },

    isItemTooltipEnabled(item) {
      return (
        !suvSingleDay &&
        !dragInfo.current.items &&
        !resizeInfo.current.item &&
        actionMode === TASK_EDIT_MODES.ADD_EDIT
      );
    },

    logTime(item, val) {
      const changes = [
        {
          type: item.type,
          id: item.entityId,
          originalEntity: cloneDeep(item.entity),
          entity: {
            ...item.entity,
            hours: val,
          },
        },
      ];

      return scheduleActions.persistChanges(changes);
    },

    logAllTimeOnDay(cell, x) {
      const changes = cell.items
        .filter((i) => i.x === x && i.entity.isTaskReference)
        .filter((i) => isItemEditable(i))
        .map((item) => ({
          type: item.type,
          id: item.entityId,
          entity: item.entity,
        }));

      if (!changes?.length) {
        return Promise.resolve();
      }

      return scheduleActions.persistChanges(changes);
    },

    onContextMenu(event) {
      event.preventDefault();
      event.stopPropagation();

      // Prevent anything from happening if the user accidentally
      // right clicks while they're dragging or resizing.
      if (dragInfo.current.items) return;
      if (resizeInfo.current.item) return;

      setContextMenuPosition({
        left: event.clientX,
        top: event.clientY,
      });
    },

    onTimeRangeChange,

    onTimeRangePresetChange(originalEntity, entity) {
      scheduleActions.onTimeRangeChange(originalEntity, entity);
      const [colIdx] = dates.toDescriptor(entity.start_date);
      scheduleActions.scrollToCol(colIdx);
    },

    onTimeRangeCellMouseDown() {
      const { current: smp } = mousePositionRef;

      const initialEntity = {
        time_range_id: 1,
        start_date: dates.fromDescriptor(smp.colIdx, smp.subCol),
        end_date: dates.fromDescriptor(smp.colIdx, smp.subCol),
        range_mode: 'custom',
      };

      const upHandler = (e) => {
        const { entity, endMousePosition, startMousePosition } =
          dragInfo.current;

        if (
          entity &&
          Math.abs(endMousePosition?.rawX - startMousePosition?.rawX) > 10
        ) {
          const originalEntity = getTimeRangeCacheValue(reduxData.user);
          onTimeRangeChange(originalEntity, entity);
        } else {
          addMilestoneOn(initialEntity.start_date, e);
        }

        autoscroller.disableHorizontal();
        dispatch({ type: 'REMOVE_SELECTION' });
        dragInfo.current = {};
      };

      dragInfo.current = {
        isCreating: true,
        startMousePosition: smp,
        entity: initialEntity,
      };

      autoscroller.enableHorizontal();
      dispatch({
        type: 'SET_SELECTION',
        entity: initialEntity,
        rowId: 0,
      });
      window.addEventListener('mouseup', upHandler, { once: true });
    },
    onTimeRangeRemove(originalEntity) {
      onTimeRangeChange(originalEntity, {}, true);
    },
    isTodayVisible() {
      return isTodayVisibleHelper({
        dayWidth,
        isLogMyTimeView: logMyTimeView,
        isLogTimeView: logTimeView,
        scrollWrapper: scrollWrapperRef.current,
      });
    },

    setActionMode,
    setDragItem,
    linkInfo,
    setLinkInfo,
    actionMode,
  };

  return scheduleActions;
}
