import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  getPeopleByDepartment,
  getPrefs,
  getProjectsMap,
  getUser,
} from 'selectors';
import styled from 'styled-components';

import { updateUserPref } from '@float/common/actions/currentUser';
import { Access } from '@float/common/lib/acl';
import { isNewPlaceholderType } from '@float/common/lib/people/isNewPlaceholderType';
import { useAppDispatch, useAppSelector } from '@float/common/store';
import { Colors, Row, Tab } from '@float/ui/deprecated';
import TooltipNotification from '@float/ui/deprecated/TooltipNotification';

import { checkIsPeopleManager } from './access/PersonAccess';
import { getAssignedProjects } from './PersonProjects';
import { useDirectManagedPeople } from './useDirectManagedPeople';

const StyledTooltipNotification = styled(TooltipNotification)`
  position: absolute;

  top: calc(100% + 20px);
  transform: translateX(-60%);
  left: 0;
  z-index: 10;
  padding: 10px;
  font-size: 14px;
  align-items: center;
  display: flex;
  font-weight: 500;
  color: #032a96;
  opacity: ${(p) => (p.fadeOut ? 0 : 1)};
  transition: opacity 0.5s ease-in-out;
  pointer-events: none;
`;

const ManagersTabContent = ({ account, managersTabIsAppeared, ...rest }) => {
  const dispatch = useAppDispatch();
  const managerTabPrefUpdated = useRef(null);
  const directManaged = useDirectManagedPeople(account);

  const managesTabShownPref = useAppSelector(getPrefs).manage_tab_shown;
  const [shouldShowTooltip] = useState(!managesTabShownPref);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    if (
      !managerTabPrefUpdated.current &&
      shouldShowTooltip &&
      managersTabIsAppeared &&
      !managesTabShownPref
    ) {
      managerTabPrefUpdated.current = true;
      dispatch(updateUserPref('manage_tab_shown', true));
    }
  }, [managesTabShownPref, dispatch, shouldShowTooltip, managersTabIsAppeared]);

  useEffect(() => {
    if (shouldShowTooltip && managersTabIsAppeared) {
      setTimeout(() => {
        setFadeOut(true);
      }, 4000);
    }
  }, [shouldShowTooltip, managersTabIsAppeared]);

  let notification = null;
  if (shouldShowTooltip && managersTabIsAppeared) {
    notification = (
      <StyledTooltipNotification
        position="bottom"
        fadeOut={fadeOut}
        bgColor={Colors.flueLight3}
        borderColor="#1990dc"
        alignment="end"
        width={280}
        showClose={false}
        closeOnBgClick={true}
        arrowPosition="77%"
      >
        Select people they manage from tab
      </StyledTooltipNotification>
    );
  }

  return (
    <Tab
      label="Manages"
      tooltip={notification}
      counter={
        directManaged.people.departments.length +
        directManaged.people.people.length
      }
      {...rest}
    />
  );
};

const useShowManagerTooltip = (isPeopleManager) => {
  const isPeopleManagerRef = useRef(isPeopleManager);
  const [managersTabIsAppeared, setManagersTabIsAppeared] = useState(false);

  useEffect(() => {
    const shouldShowTooltip = isPeopleManager;
    if (!shouldShowTooltip) {
      return;
    }

    const setShowPeopleManagerTab = () => {
      isPeopleManagerRef.current = true;
      setManagersTabIsAppeared(true);
    };

    const initalIsPeopleManager = isPeopleManagerRef.current;
    const justBecamePeopleManager = !initalIsPeopleManager && isPeopleManager;
    if (justBecamePeopleManager) {
      setShowPeopleManagerTab();
      return;
    }

    if (initalIsPeopleManager) {
      // if is already people manager, wait for modal to be fully open
      // before attempting to show the tooltip.
      setTimeout(setShowPeopleManagerTab, 300);
      return;
    }
  }, [isPeopleManager]);

  return managersTabIsAppeared;
};

const ManagedPeopleTab = connect((state) => ({
  people: state.people.people,
  peopleByDepartment: getPeopleByDepartment(state),
}))((props) => {
  const { account, ...rest } = props;
  const currentUser = useAppSelector(getUser);
  const isPeopleManager = checkIsPeopleManager(account);
  const managersTabIsAppeared = useShowManagerTooltip(
    isPeopleManager &&
      Access.canUpdateAccountManagementGroup(currentUser, { account }),
  );

  if (!isPeopleManager) {
    return null;
  }

  return (
    <ManagersTabContent
      managersTabIsAppeared={managersTabIsAppeared}
      account={account}
      peopleByDepartment={props.peopleByDepartment}
      {...rest}
    />
  );
});

const PersonProjectTab = connect((state) => ({
  allProjects: getProjectsMap(state),
}))((props) => {
  const { person, allProjects, ...rest } = props;
  const assignedProjects = getAssignedProjects(person, allProjects);
  return <Tab label="Projects" counter={assignedProjects.length} {...rest} />;
});

export const PersonModalTabs = (props) => {
  const { form, formErrors, activeTab, guestMode } = props;

  const isAccessTabDisabled = isNewPlaceholderType({ person: form });

  if (guestMode) {
    return (
      <Row flexWrap="wrap" alignItems="center">
        <Tab
          label="Access"
          active={activeTab === 'access'}
          onClick={() => props.onTabChange('access')}
          hasError={['account_type', 'department_filter'].some(
            (key) => formErrors[key]?.length,
          )}
        />
        <ManagedPeopleTab
          account={form?.account || {}}
          active={activeTab === 'manages'}
          onClick={() => props.onTabChange('manages')}
        />
      </Row>
    );
  }

  return (
    <Row flexWrap="wrap" alignItems="center">
      <Tab
        label="Info"
        active={activeTab === 'info'}
        onClick={() => props.onTabChange('info')}
        hasError={formErrors['default_hourly_rate']?.length}
      />
      <Tab
        label="Access"
        disabled={isAccessTabDisabled}
        active={activeTab === 'access'}
        onClick={() => props.onTabChange('access')}
        hasError={['account_type', 'department_filter'].some(
          (key) => formErrors[key]?.length,
        )}
      />
      <Tab
        label="Availability"
        active={activeTab === 'availability'}
        onClick={() => props.onTabChange('availability')}
      />
      <PersonProjectTab
        active={activeTab === 'projects'}
        onClick={() => props.onTabChange('projects')}
        person={form || {}}
      />
      <ManagedPeopleTab
        account={form?.account || {}}
        active={activeTab === 'manages'}
        onClick={() => props.onTabChange('manages')}
      />
    </Row>
  );
};
