import React from 'react';
import { Trans } from '@lingui/macro';
import { isAfter } from 'date-fns';
import { usePromptTracker } from 'OnboardingManager/hooks/usePromptTracker';

import { isPaidPlan } from '@float/common/selectors/companyPrefs';
import { PROMPTS } from '@float/constants/prompts';
import { parseFloatDate } from '@float/libs/dates';
import { AnchorData, Button, Callout } from '@float/ui/deprecated';
import { useWebAppSelectorStrict } from '@float/web/lib/store';

import * as styles from './AllocationByTotalHoursCallout.css';

const anchor: AnchorData = {
  anchors: [
    {
      id: 'allocation-by-total-hours-callout',
      config: {
        arrowPosition: '350px',
        position: 'bottom',
        alignment: 'end',
      },
    },
  ],
};

export const AllocationByTotalHoursCallout = () => {
  const [done, setDone] = usePromptTracker(PROMPTS.allocationByTotalHours);
  const isNonTrialAccount = useWebAppSelectorStrict(isPaidPlan);

  const today = new Date();

  const expireDate = parseFloatDate('2024-12-01');

  const isExpired = isAfter(today, expireDate);

  const visible = !done && !isExpired && isNonTrialAccount;

  return (
    <Callout
      visible={visible}
      anchorData={anchor}
      onClose={setDone}
      className={styles.wrapper}
    >
      <Callout.Content className={styles.content}>
        <Callout.Title>
          <Trans>Evenly allocate over a time period</Trans>
        </Callout.Title>

        <Callout.Paragraph className={styles.description}>
          <Trans>
            When you select the new fixed end date option, Float will
            automatically spread your hours allocation evenly over your
            specified date range.{' '}
            <a
              href="https://support.float.com/en/articles/4188692-allocate-time#h_daa569e059"
              target="_blank"
              className={styles.learnMoreLink}
              rel="noreferrer"
            >
              Learn more
            </a>
            .
          </Trans>
        </Callout.Paragraph>
      </Callout.Content>

      <Callout.ActionBar className={styles.actionBar}>
        <Button appearance="primary" onClick={setDone}>
          <Trans>Got it</Trans>
        </Button>
      </Callout.ActionBar>
    </Callout>
  );
};
