import React from 'react';
import { Trans } from '@lingui/macro';

import { NavIconBtn } from '@float/common/components/NavIconBtn';

export function ClearFiltersButton({ onClick }: { onClick: () => void }) {
  return (
    <NavIconBtn noBorder onClick={onClick}>
      <Trans>Clear all</Trans>
    </NavIconBtn>
  );
}
