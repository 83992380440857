import React from 'react';

import BaseIcon from './BaseIcon';

export default React.forwardRef((props, ref) => {
  return (
    <BaseIcon {...props} ref={ref}>
      <path
        className="stroke"
        d="M12.25 8V16.5"
        stroke="#2E5FE8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M16.5 12.25H8"
        stroke="#2E5FE8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
});
