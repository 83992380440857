import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';

import {
  clearTeamworkPreload,
  fetchTeamworkCoInts,
  removeTeamworkCoInt,
  updateTeamworkConfig,
} from '@float/web/integrations/actions/teamwork';

import Setup from '../shared/Setup';

function TeamworkSetup(props) {
  const { integration, fetchCoInts, ...others } = props;
  const coInt = get(integration, 'coInts[0]');
  useEffect(() => {
    if (
      isEmpty(integration.coInts) &&
      integration.coIntsLoadState !== 'LOADING'
    ) {
      fetchCoInts();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Setup
      type="teamwork"
      label="Teamwork"
      preloadData={integration.preloadData}
      config={integration.config}
      coInt={coInt}
      isUpdating={integration.updateConfigLoadState === 'LOADING'}
      {...others}
    />
  );
}

const mapStateToProps = (state) => ({
  integration: state.integrations.teamwork,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCoInts: () => dispatch(fetchTeamworkCoInts()),
  updateConfig: (coInt, config, options) =>
    dispatch(updateTeamworkConfig(coInt, config, options)),
  removeCoInt: (coIntId, archiveData) =>
    dispatch(removeTeamworkCoInt(coIntId, archiveData)),
  clearPreload: () => dispatch(clearTeamworkPreload()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamworkSetup);
