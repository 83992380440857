import React from 'react';
import { connect } from 'react-redux';
import { getPublicHolidaysOptions } from 'selectors';

import VirtualSelect from '@float/ui/deprecated/VirtualSelect/VirtualSelect';
import { ensurePublicHolidaysLoaded } from '@float/web/settingsV2/actions/publicHolidays';

class PublicHolidays extends React.Component {
  componentDidMount() {
    this.initialValue = this.props.value;
    this.props.ensurePublicHolidaysLoaded();
  }

  handleChange = ({ value }) => {
    this.props.onChange(value);
  };

  render() {
    return (
      <VirtualSelect
        label="Public holidays"
        visibleItems={6}
        clearInputOnDropdownOpen={false}
        options={this.props.options}
        value={this.props.value}
        onChange={this.handleChange}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  options: getPublicHolidaysOptions(state),
});

const mapDispatchToProps = (dispatch) => ({
  ensurePublicHolidaysLoaded: () => dispatch(ensurePublicHolidaysLoaded()),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(PublicHolidays);
