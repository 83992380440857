import { clamp } from 'lodash';

function normalizeHours(value: number | null) {
  if (value === null) return 0.1;

  const step = 0.1;
  value = value - (Math.round(value % step) * 100) / 100;
  value = parseFloat(value.toFixed(2));

  if (value <= 0) {
    value = step;
  }
  return clamp(value, 0, 24);
}

export { normalizeHours };
