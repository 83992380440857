import React from 'react';

import { toDbFormat } from '@float/common/lib/notes';
import { RichText } from '@float/ui/deprecated/Earhart/RichText';

const style = { marginBottom: 16 };

let etmThis;

const setNotes = (data) => {
  const dbFormat = toDbFormat(data.children);

  etmThis.setState({ notes: data.text, notes_meta: dbFormat.notes_meta });
};

const setStatusNotes = (data) => {
  etmThis.setState({ status_note: data.text });
};

const getNotesElemReadOnly = (notes, notes_meta, label) => {
  if (!notes) {
    return null;
  }

  return (
    <RichText
      ref={etmThis.notesRef}
      label={
        label ?? (etmThis.isApproveRejectMode() ? 'Requestor notes' : 'Notes')
      }
      value={notes}
      valueMeta={notes_meta}
      readOnly
      mentionsEnabled
      style={etmThis.isApproveRejectMode() ? { marginBottom: 0 } : style}
    />
  );
};

const mentioned = (meta, user) => {
  return (
    meta?.some((item) => item.data?.account_id == user.account_id) ?? false
  );
};

export const getNotesElem = (self) => {
  etmThis = self;

  const { task, notes, notes_meta, status_note } = etmThis.state;
  const { user } = etmThis.props;

  if (
    etmThis.isReadOnly() &&
    !etmThis.state.isPreviewingBeforeEdit &&
    !etmThis.isApproveRejectMode() &&
    !!task.status_request &&
    !mentioned(notes_meta, user)
  ) {
    return null;
  }

  if (
    (etmThis.isReadOnly() && !etmThis.state.isPreviewingBeforeEdit) ||
    etmThis.state.integrationSyncLocked ||
    etmThis.isNotesExcerpt()
  ) {
    return <>{getNotesElemReadOnly(notes, notes_meta)}</>;
  }

  if (etmThis.isApproveRejectMode()) {
    return (
      <>
        {getNotesElemReadOnly(notes, notes_meta)}
        <RichText
          ref={etmThis.notesRef}
          label="Add a response"
          readOnly={etmThis.state.isPreviewingBeforeEdit}
          value={status_note}
          valueMeta={null}
          onChange={setStatusNotes}
          maxLength={1500}
          mentionsEnabled
          style={style}
        />
      </>
    );
  }

  if (etmThis.isTimeoff()) {
    return (
      <>
        <RichText
          ref={etmThis.notesRef}
          readOnly={etmThis.state.isPreviewingBeforeEdit}
          label={
            etmThis.state.isPreviewingBeforeEdit ||
            etmThis.isEditingRequestedTimeoff()
              ? 'Requestor notes'
              : 'Notes'
          }
          value={notes}
          valueMeta={notes_meta}
          onChange={setNotes}
          maxLength={1500}
          mentionsEnabled
          style={
            etmThis.state.isPreviewingBeforeEdit ? { marginBottom: 0 } : style
          }
        />
        {getNotesElemReadOnly(status_note, null, 'Response Notes')}
      </>
    );
  }

  return (
    <RichText
      ref={etmThis.notesRef}
      label="Notes"
      value={notes}
      valueMeta={notes_meta}
      onChange={setNotes}
      maxLength={1500}
      mentionsEnabled
      style={style}
      placeholder="Add details specific to this allocation"
    />
  );
};
