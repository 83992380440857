import { cloneDeep, isEmpty, min, omit, pull } from 'lodash';

import { updateMultiUserPrefs } from '@float/common/actions';

async function updateProjectPersonCustomPosition({
  reduxData,
  reduxDispatch,
  rows,
  row,
  destIdx,
}) {
  const { data: person } = row;
  const { projectId } = row;
  const personId = person.people_id;

  // Start with a blank slate if the user just triggered custom sorting from a
  // view where they weren't custom sorting.
  const { prefs } = reduxData.user;
  const people_order = cloneDeep(prefs.projview_people_order || {});
  const custom_priority = cloneDeep(
    prefs.projview_people_custom_priority || {},
  );

  // Remove the person from the custom priority map if he was present
  if (
    person.projectOrder?.[projectId] != null &&
    custom_priority[projectId]?.[person.order]
  ) {
    pull(custom_priority[projectId][person.order], personId);
    if (isEmpty(custom_priority[projectId][person.order])) {
      delete custom_priority[projectId][person.order];
    }
  }

  // Assign the new order to this person
  const toTop = rows[destIdx - 1].type === 'project';
  const order = toTop
    ? rows[destIdx + 1].data.projectOrder[projectId] - 1
    : rows[destIdx - 1].data.projectOrder[projectId];
  if (!people_order[projectId]) people_order[projectId] = {};
  people_order[projectId][personId] = order;

  if (!custom_priority[projectId]) {
    custom_priority[projectId] = {};
  }

  // Add the person above the target person to custom_priority so that we have
  // a target to place our target below.
  if (!custom_priority[projectId][order]) {
    custom_priority[projectId][order] = [];
    if (!toTop) {
      custom_priority[projectId][order].push(rows[destIdx - 1].data.people_id);
    }
    custom_priority[projectId][order].push(personId);
  } else {
    const aboveIndex = custom_priority[projectId][order].indexOf(
      rows[destIdx - (toTop ? 0 : 1)].data.people_id,
    );
    custom_priority[projectId][order].splice(aboveIndex + 1, 0, personId);
  }

  await reduxDispatch(
    updateMultiUserPrefs({
      projview_people_order: people_order,
      projview_people_custom_priority: custom_priority,
    }),
  );
}

export function resetProjectPeopleSort({ reduxData, reduxDispatch }) {
  return (projectId) => {
    const { prefs } = reduxData.user;
    return reduxDispatch(
      updateMultiUserPrefs({
        projview_people_order: omit(prefs.projview_people_order, [projectId]),
        projview_people_custom_priority: omit(
          prefs.projview_people_custom_priority,
          [projectId],
        ),
      }),
    );
  };
}

export function updatePersonCustomPosition({ reduxData, reduxDispatch, rows }) {
  return async (rowKey, destIdx) => {
    const row = rows.find((r) => r.key === rowKey);
    if (row.projectId) {
      return await updateProjectPersonCustomPosition({
        reduxData,
        reduxDispatch,
        rows,
        row,
        destIdx,
      });
    }

    const { data: person } = row;
    const id = person.people_id;

    // Start with a blank slate if the user just triggered custom sorting from a
    // view where they weren't custom sorting.
    const { prefs } = reduxData.user;
    let people_order = {};
    let custom_priority = {};
    if (prefs.sked_custom_sort) {
      people_order = cloneDeep(prefs.people_order || {});
      custom_priority = cloneDeep(prefs.custom_priority || {});
    }

    // Remove the person from the custom priority map if he was present
    if (person.order != null) {
      pull(custom_priority[person.order], id);
      if (isEmpty(custom_priority[person.order])) {
        delete custom_priority[person.order];
      }
    }

    // Assign the new order to this person
    const toTop = destIdx === 0;
    const order = toTop
      ? min(rows.map((r) => r.data.order)) - 1
      : rows[destIdx - 1].data.order;
    people_order[id] = order;

    // Add the person above the target person to custom_priority so that we have
    // a target to place our target below.
    if (!custom_priority[order]) {
      custom_priority[order] = [];
      if (!toTop) {
        custom_priority[order].push(rows[destIdx - 1].data.people_id);
      }
      custom_priority[order].push(id);
    } else {
      const aboveIndex = custom_priority[order].indexOf(
        rows[destIdx - (toTop ? 0 : 1)].data.people_id,
      );
      custom_priority[order].splice(aboveIndex + 1, 0, id);
    }

    await reduxDispatch(
      updateMultiUserPrefs({
        people_order,
        custom_priority,
        custom_sort_order: prefs.sked_sort_dir,
        custom_sort_type: prefs.sked_sort_by,
        sked_custom_sort: 1,
      }),
    );
  };
}
