import React from 'react';

export default function () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="11"
      viewBox="0 0 11 11"
    >
      <path
        fill="#868D92"
        fillRule="nonzero"
        d="M2.075 5.915l.934-.934 1.715 1.715 3.474-3.583.934.934L4.67 8.51z"
      />
    </svg>
  );
}
