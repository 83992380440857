import React from 'react';
import styled from 'styled-components';

import { Icons } from '@float/ui/deprecated';

const TaskNameLabelWrapper = styled.div<{ $hidden?: boolean }>`
  display: flex;
  align-items: center;

  visibility: ${({ $hidden }) => ($hidden ? 'hidden' : null)};

  svg {
    margin-left: 4px;
  }
`;

export function TaskNameLabel({
  hidden,
  locked,
}: {
  hidden?: boolean;
  locked?: boolean;
}) {
  return (
    <TaskNameLabelWrapper $hidden={hidden} aria-hidden={hidden}>
      <span>Task</span>
      {locked && <Icons.LockOutline size={16} />}
    </TaskNameLabelWrapper>
  );
}
