import { LoggedTimeCell, PersonCell } from '@float/types';

import { CellFns, CellOpts, DateUtils } from '../types';
import { sortLogTimePerTypeAndPriority } from './sortLogTimeCellPerTypeAndPriority';
import { sortPersonCellPerTypeAndPriority } from './sortPersonCellPerTypeAndPriority';

export function sortCellItemsPerTypeAndPriority(
  type: 'logtime' | 'person',
  cell: LoggedTimeCell | PersonCell,
  opts: CellOpts,
  getPriority: CellFns['getPriority'],
  collator: Intl.Collator,
  dates?: DateUtils,
) {
  if (type === 'logtime') {
    return sortLogTimePerTypeAndPriority(
      cell as LoggedTimeCell,
      getPriority,
      collator,
    );
  }

  return sortPersonCellPerTypeAndPriority(
    cell as PersonCell,
    opts,
    getPriority,
    collator,
    dates,
  );
}
