import { createSelector } from 'reselect';

import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { BudgetPhase, BudgetProject } from '@float/types/budget';

export const getStatusTypes = (state: ReduxStateStrict) =>
  state.statusTypes.statusTypes;

export const selectProjectsBudgets = (
  state: ReduxStateStrict,
): Record<string, BudgetProject> => state.budgets.projects;

export const selectPhasesBudgets = (
  state: ReduxStateStrict,
): Record<string, BudgetPhase> => state.budgets.phases;

export const selectProjectBudget = createSelector(
  [selectProjectsBudgets, (_, projectId: number | undefined) => projectId],
  (projectsBudgets, projectId) => {
    if (!projectId) return null;

    return projectsBudgets[projectId] ?? null;
  },
);

export const selectPhaseBudget = createSelector(
  [selectPhasesBudgets, (_, phaseId: number | undefined) => phaseId],
  (phasesBudgets, phaseId) => {
    if (!phaseId) return null;

    return phasesBudgets[phaseId] ?? null;
  },
);
