import { isUndefined, sortBy } from 'lodash';

import { moment } from '@float/libs/moment';

// task meta can also be created by calendar integrations
export function isTaskMetaUserCreated({
  min_integration_status: min,
  max_integration_status: max,
} = {}) {
  return (isUndefined(min) && isUndefined(max)) || !+min || !+max;
}

export function getDefaultPhaseForDate({ phases, start }) {
  if (!phases || !phases.length) {
    return 0;
  }

  const startDate = moment(start).format('YYYY-MM-DD');
  const sortedPhases = sortBy(phases, 'start_date').reverse();
  const phase = sortedPhases.find((p) => p.start_date <= startDate);

  // Limited rights users who are only in a phase team are not
  // allowed to create task or log time outside of their phases.
  const isNoPhaseAllowed = sortedPhases.some((x) => x.phase_id === 0);
  const fallbackPhaseId = isNoPhaseAllowed ? 0 : sortedPhases[0].phase_id;

  return phase?.phase_id || fallbackPhaseId;
}

export const maxEntityLength = 365 * 2;
export const maxYearlyEntityLength = 365 * 5;
export const getTaskMaxLength = (repeatState) => {
  if (repeatState === 9) {
    return maxYearlyEntityLength;
  }
  return maxEntityLength;
};

export const isRepeatOverlap = (repeatState, length) => {
  return (
    (repeatState === 1 && length >= 7) ||
    (repeatState === 2 && length >= 7 * 4) ||
    (repeatState === 3 && length >= 7 * 2) ||
    (repeatState === 4 && length >= 7 * 3) ||
    (repeatState === 5 && length >= 7 * 6) ||
    (repeatState === 6 && length >= 7 * 8) ||
    (repeatState === 7 && length >= 7 * 12) ||
    (repeatState === 8 && length >= 7 * 24) ||
    (repeatState === 9 && length >= 7 * 48)
  );
};

export function getTempId() {
  return `${Math.random()}${Date.now()}`.substring(2, 18);
}
