import { ConfirmConfig } from 'manage/people-v2/People';

import { CurrentUser } from '@float/types';

export const showTimeToUpgradeConfirmModal = ({
  currentUser,
  confirm,
  onCancel,
}: {
  currentUser?: CurrentUser;
  confirm: (confirmConfig: ConfirmConfig) => void;
  onCancel?: () => undefined;
}) => {
  if (!confirm || !currentUser) {
    console.error(
      'showTimeToUpgradeConfirmModal Error: Expected confirm or currentUser in props',
    );
  }
  const accountTypeId = Number((currentUser as CurrentUser).account_tid);
  const canUpgrade = accountTypeId === 1 || accountTypeId === 5;

  confirm({
    title: 'Time to upgrade!',
    confirmLabel: 'Upgrade',
    message:
      "You've reached the maximum number of active people for your plan. The Account Owner can upgrade your plan from the Team Settings.",
    onConfirm: () => {
      window.location = '/admin/billing' as string & Location;
    },
    onCancel,
    hideConfirm: !canUpgrade,
    cancelLabel: !canUpgrade ? 'OK' : 'Close',
  });
};
