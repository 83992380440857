import React from 'react';

import { TextToggle, TextToggleOption } from '../TextToggle';

export type OnOffToggleProps = {
  disabled?: boolean;
  isOn: boolean;
  onChange: (isOn: boolean) => void;
};

const options: TextToggleOption<boolean>[] = [
  { value: false, label: 'Off' },
  { value: true, label: 'On' },
];

export const OnOffToggle = ({
  disabled = false,
  isOn,
  onChange,
}: OnOffToggleProps) => {
  const handleChange = ({ value }: TextToggleOption<boolean>) => {
    onChange(value);
  };

  return (
    <TextToggle<boolean>
      options={options}
      value={isOn}
      onChange={handleChange}
      toggle
      disabled={disabled}
    />
  );
};
