import React, { ComponentPropsWithRef } from 'react';
import cn from 'classnames';

import { List } from '@float/ui/primitives/List';

import {
  SearchFilterDropdownCategoryItem,
  SearchFilterListProps,
} from '../../../../types';

import * as styles from './styles.css';

type CategoryItemProps = {
  onClick: SearchFilterListProps['onClick'];
  category: SearchFilterDropdownCategoryItem;
  selected: boolean;
} & Omit<ComponentPropsWithRef<'div'>, 'onClick'>;

const CategoryItem = (props: CategoryItemProps) => {
  const { className, selected, onClick, category } = props;

  const { value, label, icon, shortLabel } = category;

  const onClickHandler = () => {
    onClick(value);
  };

  return (
    <List.Item.Root
      aria-label={shortLabel || label}
      className={cn(styles.category, className)}
      key={value}
      onClick={onClickHandler}
      role="listitem"
      selected={selected}
    >
      <List.Item.Icon icon={icon} size={20} />
      <List.Item.Value>{shortLabel || label}</List.Item.Value>
    </List.Item.Root>
  );
};

export { CategoryItem };
