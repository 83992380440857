import React, { StrictMode } from 'react';

import { Person, Project, Tag } from '@float/types';

import * as styles from './AdminTagsItem.css';

type Entity = Person | Project;

export type AdminTagsItemProps = {
  tag: Tag;
  entities: Entity[];
};

export const AdminTagsItem = (props: AdminTagsItemProps) => {
  const { tag, entities } = props;

  return (
    <StrictMode>
      <div className={styles.adminTagsItem}>
        {tag.name} ({entities.length})
      </div>
    </StrictMode>
  );
};
