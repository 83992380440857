import styled from 'styled-components';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

export const CardRow = styled.div`
  color: ${Colors.FIN.Lt.Emphasis.High};

  display: flex;
  align-items: center;

  & + & {
    margin-top: 24px;
  }

  h5 {
    ${Typography.TextXXL.SB600};
  }

  p {
    ${Typography.TextM.R400};
  }
`;
