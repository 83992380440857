export const toDash = (str: string) =>
  str.replace(/([A-Z])/g, (s) => `-${s.toLowerCase()}`);

export const toDashCase = (str: string) => str.replace(/\s+/g, '-');

export const ellipsis = (str: string, length: number) => {
  if (str.length > length) {
    return `${str.substr(0, length - 3)}...`;
  }
  return str;
};

export const truncateAt = (text: string, position: number) => {
  if (text.length > position) {
    return text.substr(0, position);
  }

  return text;
};

export const getInitials = (name: string | undefined) => {
  name = (name || '').trim();
  if (!name || !name.length) {
    return '';
  }

  const parts = name.split(' ').filter(Boolean);
  if (!parts.length) {
    return name[0].toUpperCase();
  }

  const initials = [];
  if (parts.length > 0) {
    initials.push(parts[0][0]);
  }

  if (parts.length > 1) {
    initials.push(parts[1][0]);
  }

  return initials.join('').toUpperCase();
};
