import React from 'react';

import { moment } from '@float/libs/moment';
import { ReadOnlyField, ToggleGroup } from '@float/ui/deprecated';

import PersonNotes from '../components/personNotes';
import { employeeTypeOptions, WorkDaysSection } from './PersonAvailability';

let self;

function getWorkDaysOptionsFromHistory(that = self) {
  const history = that.state.form.work_days_hours_history;
  if (!history?.length) return [];
  const thisWeekStart = moment().startOf('week').format('YYYY-MM-DD');
  const curHistory = history.find((k) => thisWeekStart >= k[0]);
  return curHistory?.length > 1 ? curHistory[1] : [];
}

function getWorkDaysOptions(that = self) {
  const workDaysHours =
    that.state.form.work_days_hours || getWorkDaysOptionsFromHistory(that);
  if (!workDaysHours?.length) return [];

  const opts = [
    { value: 0, label: 'S' },
    { value: 1, label: 'M' },
    { value: 2, label: 'T' },
    { value: 3, label: 'W' },
    { value: 4, label: 'T' },
    { value: 5, label: 'F' },
    { value: 6, label: 'S' },
  ];

  return opts
    .map((item, i) => ({
      ...item,
      subLabel: `${workDaysHours[i]}h`,
    }))
    .filter((x, i) => !!workDaysHours[i]);
}

function setNotes(notes) {
  self.setFormState({ notes });
}

export default function renderPersonAvailabilityReadOnly(that) {
  self = that;
  const { allRegions } = self.state;
  const {
    start_date: start,
    end_date: end,
    employee_type,
    notes,
    region_id,
    people_id: personId,
  } = self.state.form;
  const region =
    allRegions &&
    allRegions.length &&
    allRegions.find((r) => r.value === region_id);

  return (
    <>
      <ReadOnlyField
        label="Start date"
        value={
          start ? moment.utc(start).format('DD MMM YYYY') : <span>&mdash;</span>
        }
        {...self.readOnlyProps}
      />
      <ReadOnlyField
        label="End date"
        value={
          end ? moment.utc(end).format('DD MMM YYYY') : <span>&mdash;</span>
        }
        {...self.readOnlyProps}
      />
      {!self.isPlaceholder() && (
        <div style={self.readOnlyProps.wrapperStyle}>
          <ToggleGroup
            value={employee_type}
            options={employeeTypeOptions}
            readOnly
          />
        </div>
      )}
      {self.isPartTime() && !self.isPlaceholder() && (
        <WorkDaysSection style={self.readOnlyProps.wrapperStyle}>
          <ToggleGroup
            type="multiple"
            readOnly
            hideIfReadOnlyAndNotChecked={false}
            label="Works these days"
            value={[0, 1, 2, 3, 4, 5, 6]}
            options={getWorkDaysOptions(self)}
          />
        </WorkDaysSection>
      )}
      {region && (
        <ReadOnlyField
          label="Public holidays"
          value={region.label}
          {...self.readOnlyProps}
        />
      )}
      {notes && (
        <PersonNotes
          personId={personId}
          value={notes}
          onChange={setNotes}
          readOnly
        />
      )}
    </>
  );
}
