import { Transforms } from 'slate';

const insertMention = (editor, data, trigger, field) => {
  let savedData;

  // if @ mention trigger, no need to pass the whole object
  // we only care about account_id and name
  if (trigger === '@') {
    const { account_id, people_id, name } = data;

    savedData = { name };

    if (account_id) savedData.account_id = account_id;
    if (people_id) savedData.people_id = people_id;
  }

  const mention = {
    type: 'mention',
    data: savedData || data,
    trigger,
    children: [{ text: `${trigger}${data[field]}` }],
  };

  Transforms.insertNodes(editor, mention);
  Transforms.move(editor);

  return mention;
};

export { insertMention };
