import styled from 'styled-components';

import * as Animation from '@float/ui/deprecated/Earhart/Animation';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';
import TooltipNotification from '@float/ui/deprecated/TooltipNotification';

import { DotCallout } from '../DotCallout';

const offset = 10;

const offsetPerPosition = {
  top: `translateY(${offset * -1}%)`,
  right: `translateX(${offset}%)`,
  bottom: `translateY(${offset}%)`,
  left: `translateX(${offset * -1}%)`,
};

export const StyledTooltipContent = styled.div`
  ${Typography.TextS14.M500};

  padding: 10px 16px;

  color: ${Colors.Radix.Primary.Flue[11]};
`;

export const StyledDotCallout = styled(DotCallout)`
  position: absolute;
`;

export const StyledTooltipNotification = styled(TooltipNotification)`
  position: absolute;

  /**
   * Animation
   */

  &.enter,
  &.appear {
    opacity: 0;

    transform: ${({ position }) => offsetPerPosition[position]};
  }

  &.enter-active,
  &.appear-active {
    opacity: 1;
    transform: none;

    transition-delay: 0.45s;

    transition-property: opacity, transform;
    transition-duration: 0.25s, 0.35s;
    transition-timing-function: ${Animation.Settings.Easing.DefaultOut},
      ${Animation.Settings.Easing.OutBounce};
  }

  &.exit {
    opacity: 1;
    transform: none;
  }

  &.exit-active {
    opacity: 0;

    transform: ${({ position }) => offsetPerPosition[position]};

    transition-property: opacity, transform;
    transition-duration: 0.15s;
    transition-timing-function: ${Animation.Settings.Easing.DefaultOut};
  }
`;
