import React, { useRef } from 'react';
import { t } from '@lingui/macro';

import { useOnMount } from '@float/libs/hooks/useOnMount';
import { Input, TimePicker } from '@float/ui/deprecated';

import { useInputAllocationTimeRange } from './hooks/useInputAllocationTimeRange';

import * as styles from './InputAllocationTimeRange.css';

export type InputAllocationTimeRangeProps = {
  startTime: string | null;
  hoursPerDay: number | null;
  isReadOnly: boolean;
  is24HoursTimeFormat: boolean;
  onChange: (data: {
    startTime: string;
    endTime: string;
    hoursPerDay: number;
  }) => void;
};

export const InputAllocationTimeRange = (
  props: InputAllocationTimeRangeProps,
) => {
  const { startTime, hoursPerDay, isReadOnly, is24HoursTimeFormat, onChange } =
    props;

  const inputStartTimeRef = useRef<{ focusInput: () => void }>();

  // When this component appears in the DOM we want it to focus,
  // so user can start typing without an extra click
  useOnMount(() => {
    if (isReadOnly) return;

    inputStartTimeRef.current?.focusInput();
  });

  const {
    startTimeInternalValue,
    startTimeInternalValueFormatted,
    endTimeInternalValue,
    endTimeInternalValueFormatted,
    handleStartTimeChange,
    handleEndTimeChange,
  } = useInputAllocationTimeRange({
    startTime,
    hoursPerDay,
    is24HoursTimeFormat,
    onChange,
  });

  if (isReadOnly) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.column}>
          <Input
            label={t`Time`}
            value={startTimeInternalValueFormatted}
            appearance="underline"
            readOnly
            noBorder
            ariaLabel={t`Start time`}
          />
        </div>
        <div className={styles.column}>
          <Input
            label="&nbsp;"
            value={endTimeInternalValueFormatted}
            appearance="underline"
            readOnly
            noBorder
            ariaLabel={t`End time`}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.column}>
        <TimePicker
          ref={inputStartTimeRef}
          // TimePicker 24/12h format comes from the store
          label={t`Time`}
          value={startTimeInternalValue}
          onChange={handleStartTimeChange}
          ariaLabel={t`Start time`}
        />
      </div>
      <div className={styles.column}>
        <TimePicker
          // TimePicker 24/12h format comes from the store
          label="&nbsp;"
          value={endTimeInternalValue}
          onChange={handleEndTimeChange}
          ariaLabel={t`End time`}
        />
      </div>
    </div>
  );
};
