import { useStateCallback } from '@float/common/lib/hooks/useStateCallback';
import { NOTES_EXCERPT_LENGTH } from '@float/common/lib/notes';
import request from '@float/common/lib/request';
import { useOnMount } from '@float/libs/hooks/useOnMount';
import {
  LoggedTime,
  LoggedTimeTaskReference,
  SearchTask,
  Task,
} from '@float/types';

function fetchLoggedTime(id: string): Promise<LoggedTime> {
  return request.get(`logged-time/${id}`, undefined, {
    version: 'f3',
  });
}

function fetchTask(id: string | number): Promise<Task> {
  return request.get(`tasks/${id}`, undefined, {
    version: 'f3',
  });
}

// @test-export
export function chooseEntityOrTaskRef(
  entity: LoggedTime | LoggedTimeTaskReference,
  taskRef?: Task | SearchTask | null,
) {
  // Use the `entity` param if it's a `LoggedTime` and not a `LoggedTimeTaskReference`,
  // or if there's no `taskRef`, or the `taskRef` doesn't have notes
  // https://linear.app/float-com/issue/CS-973/task-notes-getting-deleted-after-a-day
  // https://linear.app/float-com/issue/CS-1344/unable-to-update-allocation-notes-on-the-log-team-page
  if (
    (!entity.isTaskReference && entity.notes) ||
    !taskRef ||
    !('notes' in taskRef)
  ) {
    return entity;
  }

  return taskRef;
}

export function useNotes(
  entity: LoggedTime | LoggedTimeTaskReference,
  taskRef?: Task | SearchTask | null,
): [string | null, (notes: string, cb?: () => void) => void] {
  const task = chooseEntityOrTaskRef(entity, taskRef);
  const [notes, setNotes] = useStateCallback(task.notes);

  useOnMount(() => {
    const taskNotes = task.notes;
    if (!taskNotes) return;

    const isNotesExcerpt = taskNotes.length === NOTES_EXCERPT_LENGTH;

    if (isNotesExcerpt) {
      const { task_id } = task;
      const logged_time_id =
        'logged_time_id' in task ? task.logged_time_id : null;

      if (logged_time_id) {
        fetchLoggedTime(logged_time_id).then((res) => {
          if (res.notes) setNotes(res.notes);
        });
      } else if (task_id) {
        fetchTask(task_id).then((res) => {
          setNotes(res.notes);
        });
      }
    }
  });

  return [notes, setNotes];
}
