import { SearchAutocompleteCategory } from '@float/common/search/helpers';

import { getSubCategoryConfig } from '../components/SearchFilters';

export function getSearchPlaceholder(
  expandedCategory: SearchAutocompleteCategory,
  count?: number,
) {
  if (count) {
    const subCategoryConfig = getSubCategoryConfig(expandedCategory);
    const label = subCategoryConfig.label;
    if (label) {
      if (count === 1) return `Search 1 ${label}`;
      const plural = subCategoryConfig.plural;
      if (plural) return `Search ${count} ${plural}`;
      return `Search ${count} ${label}s`;
    }
  }

  const { label, plural } = getSubCategoryConfig(expandedCategory);

  if (plural) {
    return `Search ${plural}`;
  }

  return `Search ${label}s`;
}
