import {
  createMilestone as createMilestoneAPI,
  deleteMilestone as deleteMilestoneAPI,
  fetchMilestones,
  updateMilestone as updateMilestoneAPI,
} from '@float/common/api3/milestones';
import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { AppDispatchStrict } from '@float/common/store';
import { moment } from '@float/libs/moment';

import { trackEvent } from '../../lib/analytics';
import {
  MilestoneModalEditPayload,
  MilestoneModalPayload,
  MILESTONES_CREATE_FINISH,
  MILESTONES_LOAD_FAILED,
  MILESTONES_LOAD_FINISH,
  MILESTONES_LOAD_START,
  MILESTONES_REMOVE_FAILED,
  MILESTONES_REMOVE_FINISH,
  MILESTONES_REMOVE_START,
  MILESTONES_UPDATE_FAILED,
  MILESTONES_UPDATE_FINISH,
  MILESTONES_UPDATE_START,
} from './types';

const formatDate = (date?: string) =>
  date ? moment(date).format('YYYY-MM-DD') : date;

export const ensureMilestonesLoaded =
  (forceLoad = false) =>
  (dispatch: AppDispatchStrict, getState: () => ReduxStateStrict) => {
    const { loadState: currentLoadState } = getState().milestones;

    if (!forceLoad && currentLoadState === 'LOADED') return;
    if (currentLoadState === 'LOADING') return; // There's already an in-flight load request

    dispatch({ type: MILESTONES_LOAD_START });

    return fetchMilestones().then(
      (milestones) => dispatch({ type: MILESTONES_LOAD_FINISH, milestones }),
      () => dispatch({ type: MILESTONES_LOAD_FAILED }),
    );
  };

const mapMilestoneToPayload = <
  P extends MilestoneModalPayload | MilestoneModalEditPayload,
>(
  milestone: P,
) => {
  const { startDate, endDate, project_id, ...rest } = milestone;
  const date = startDate || rest.start_date || rest.date;
  const end_date = endDate || rest.end_date;

  return {
    id: rest.id,
    milestone_id: 'milestone_id' in rest ? rest.milestone_id : undefined,
    name: rest.name,
    project_id:
      typeof project_id === 'number' ? project_id : parseInt(project_id),
    phase_id: rest.phase_id,
    date: typeof date === 'string' ? date : formatDate(date),
    end_date: typeof end_date === 'string' ? end_date : formatDate(end_date),
  };
};

export const createMilestone =
  (milestone: MilestoneModalPayload) => (dispatch: AppDispatchStrict) => {
    const payload = mapMilestoneToPayload(milestone);
    return createMilestoneAPI(payload).then((resp) => {
      dispatch({
        type: MILESTONES_CREATE_FINISH,
        milestone: resp,
        temporaryId: milestone.temporaryId,
      });
      trackEvent('Milestone added', { inPhase: +resp.phase_id ? 1 : 0 });
    });
  };

export const updateMilestone =
  (milestone: MilestoneModalEditPayload) =>
  (dispatch: AppDispatchStrict, getState: () => ReduxStateStrict) => {
    const id = milestone.id || milestone.milestone_id;
    const payload = mapMilestoneToPayload(milestone);
    const previous = getState().milestones.milestones[id];
    dispatch({ type: MILESTONES_UPDATE_START, milestone: payload });

    return updateMilestoneAPI(payload, id).then(
      (resp) =>
        dispatch({
          type: MILESTONES_UPDATE_FINISH,
          milestone: resp,
        }),
      () => dispatch({ type: MILESTONES_UPDATE_FAILED, milestone: previous }),
    );
  };

export const removeMilestone =
  (milestone: { milestone_id: number; force_skip_af?: boolean }) =>
  (dispatch: AppDispatchStrict, getState: () => ReduxStateStrict) => {
    const { milestone_id: id, force_skip_af } = milestone;
    const previous = getState().milestones.milestones[id];
    dispatch({ type: MILESTONES_REMOVE_START, milestoneId: id });
    return deleteMilestoneAPI(id, force_skip_af).then(
      () => dispatch({ type: MILESTONES_REMOVE_FINISH }),
      () => dispatch({ type: MILESTONES_REMOVE_FAILED, milestone: previous }),
    );
  };
