import React, { Component } from 'react';

import Input from './Input';

export default class HoursInput extends Component {
  handleChangeHours = (e) => {
    const { limit = 24 } = this.props;
    const val = e.currentTarget.value;
    let hours = val != '' ? +val : '';
    if (hours > limit) {
      hours = limit;
    }
    if (hours) {
      hours = parseFloat(hours.toFixed(2));
    }

    this.props.onChange(hours);
  };

  handleBlurHours = (e) => {
    // Handle empty field with blur
    let { value } = this.props;
    if (!value) {
      this.props.onChange(0);
    }
    if (value[value.length - 1] == '.') {
      value = parseInt(value, 10);
      this.props.onChange(value);
    }
    this.props.onBlur();
  };

  render() {
    const {
      value,
      limit,
      maxWidth,
      minHeight,
      min = 1,
      step = 0.5,
      onFocus,
      suffix,
    } = this.props;

    return (
      <Input
        onChange={this.handleChangeHours}
        onBlur={this.handleBlurHours}
        value={value}
        type="number"
        maxWidth={maxWidth}
        minHeight={minHeight}
        min={min}
        step={step}
        max={limit}
        suffix={suffix}
        hideArrows
        onFocus={onFocus}
      />
    );
  }
}
