import React, { StrictMode } from 'react';

import { useLinearCyclesIntegrationConfig } from '@float/common/api3/linearCyclesIntegration';
import { SerenaState } from '@float/common/selectors/serena';
import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import { TopCell } from '@float/types';

import buildItem from '../MainCell/buildItem';
import { ScheduleActions } from '../MainCell/types';

import * as styles from './TopBar.css';

type TopBarProps = {
  cell: TopCell;
  dateRange: DateString[];
  reduxData?: SerenaState;
  wrapperRef: React.RefObject<HTMLDivElement>;
  dayWidth: number;
  totalHeight: number;
  colIdx: number;
  actions: ScheduleActions;
};

export const TopBar: React.FC<TopBarProps> = (props) => {
  const { cell, dateRange, wrapperRef } = props;
  const {
    dates,
    scrollWrapperRef,
    boundaryRef,
    printMode,
    suvSingleDay,
    singleUserView,
    logTimeView,
    logMyTimeView,
    viewType,
    cellsWrapper: { cells },
  } = useScheduleContext();

  const { linearCyclesIntegrationConfig } = useLinearCyclesIntegrationConfig();

  if (logMyTimeView) {
    return <div className={styles.topBarWrapper} />;
  }

  const shouldShowWeekIndicators = !linearCyclesIntegrationConfig?.active;

  const Inner: ReturnType<typeof buildItem>[] = [];

  const startDate = dateRange[0];
  const endDate = dateRange[dateRange.length - 1];

  const startMonth = dates.toMonthShort(startDate);
  const endMonth = dates.toMonthShort(endDate);

  let weekStr = startMonth;

  if (startMonth !== endMonth) {
    weekStr = `${weekStr} - ${endMonth}`;
  }

  if (cell && cell.items) {
    cell.items.forEach((i, itemIdx) => {
      Inner.push(
        buildItem(props, i, {
          boundaryRef,
          wrapperRef,
          scrollWrapperRef,
          printMode,
          suvSingleDay,
          singleUserView,
          logTimeView,
          itemIdx,
          viewType,
          cells,
          dates,
        }),
      );
    });
  }

  return (
    <StrictMode>
      <div className={styles.topBarWrapper}>
        {shouldShowWeekIndicators && (
          <>
            <div className={styles.weekOfYear}>
              {dates.toWeekOfYear(startDate)}
            </div>
            <div className={styles.weekString}>{weekStr}</div>
            <div className={styles.dividerBar} />
          </>
        )}
        {Inner}
      </div>
    </StrictMode>
  );
};
