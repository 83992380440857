import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';

import {
  clearJiraPreload,
  fetchJiraCoInts,
  removeJiraCoInt,
  updateJiraConfig,
} from '@float/web/integrations/actions/jira';

import Setup from '../shared/Setup';

const transformJiraPreload = (preload) => {
  if (!preload) return preload;
  const { users: people, ...others } = preload;
  return { people, ...others };
};

function JiraSetup(props) {
  const { integration, fetchCoInts, ...others } = props;
  const coInt = get(integration, 'coInts[0]');
  useEffect(() => {
    /* eslint-disable no-use-before-define */
    if (
      isEmpty(integration.coInts) &&
      integration.coIntsLoadState !== 'LOADING'
    ) {
      fetchCoInts();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Setup
      type="jira"
      label="Jira"
      preloadData={transformJiraPreload(integration.preloadData)}
      config={integration.jiraConfig}
      coInt={coInt}
      isUpdating={integration.updateConfigLoadState === 'LOADING'}
      showEstimateSection
      {...others}
    />
  );
}

const mapStateToProps = (state) => ({
  integration: state.integrations.jira,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCoInts: () => dispatch(fetchJiraCoInts()),
  updateConfig: (coInt, config, options) =>
    dispatch(updateJiraConfig(coInt, config, options)),
  removeCoInt: (coIntId, archiveData) =>
    dispatch(removeJiraCoInt(coIntId, archiveData)),
  clearPreload: () => dispatch(clearJiraPreload()),
});

export default connect(mapStateToProps, mapDispatchToProps)(JiraSetup);
