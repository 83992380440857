import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { ModalConfig } from 'modalManager/useManageModal';

import { AppDispatch } from '@float/common/store';
import { useSnackbar } from '@float/ui/deprecated';

import { modalManagerConnect } from '../modalManager/modalManagerHoc';
import {
  fetchUpdatesMissedWhileOffline,
  happenedVeryRecently,
} from './recovery';

const snackbarId = 'conn-status-snackbar';

type ConnectionStatusProps = {
  online: boolean;
  connectionLostAt: number | Date | undefined;
  getUpdatesMissedWhileOffline: () => Promise<boolean>;
  manageModal: (config: ModalConfig) => void;
};

function ConnectionStatusComponent({
  online,
  connectionLostAt,
  getUpdatesMissedWhileOffline,
  manageModal,
}: ConnectionStatusProps) {
  const initialRender = useRef(true);
  const { showSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    let timer: NodeJS.Timeout;

    if (online) {
      const tooRecent = happenedVeryRecently(connectionLostAt);
      showSnackbar(
        `Internet connection established.${
          tooRecent ? '' : ' Loading changes…'
        }`,
        {
          id: snackbarId,
          className: 'success',
          loader: !tooRecent,
          persist: true,
          showClose: tooRecent,
        },
      );

      // Trigger data re-fetch after a couple of seconds to give connection
      // a chance to stabilize, thereby avoiding failed recovery requests.

      timer = setTimeout(() => {
        getUpdatesMissedWhileOffline()
          .then((success) => {
            if (!success) {
              manageModal({
                visible: true,
                modalType: 'ConfirmModal',
                modalSettings: {
                  title: 'Your schedule might be out of date.',
                  message: 'Please reload your browser to see the latest data.',
                  confirmLabel: 'Reload',
                  onConfirm: () => window.location.reload(),
                },
              });
            }
          })
          .finally(() => {
            closeSnackbar(snackbarId);
          });
      }, 3000);
    } else {
      showSnackbar(
        'Sorry, there’s no internet connection . We’ll try again in a second…',
        {
          id: snackbarId,
          persist: true,
          loader: true,
          className: 'warning',
        },
      );
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [
    online,
    connectionLostAt,
    getUpdatesMissedWhileOffline,
    manageModal,
    showSnackbar,
    closeSnackbar,
  ]);

  return null;
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getUpdatesMissedWhileOffline: async () => {
    const result = await dispatch(fetchUpdatesMissedWhileOffline());

    return Boolean(result);
  },
});

export const ConnectionStatus = connect(
  null,
  mapDispatchToProps,
)(modalManagerConnect(ConnectionStatusComponent));
