import { get as _get } from 'lodash';

import { config } from '@float/libs/config';

import {
  CHANGE_ACCOUNT_OWNER_SUCCESS,
  FETCH_COMPANY_SSO_SUCCESS,
  FETCH_USER_SUCCESS,
  savedSearchesLoadedAction,
  SEARCH_CONTEXT_LOAD_FINISH,
  SEARCH_UPDATE_SAVED_SEARCHES,
  UPDATE_COMPANY_PREFS_SUCCESS,
  UPDATE_COMPANY_SSO_SUCCESS,
  USER_PREFS_MULTI_UPDATE,
  USER_PREFS_UPDATE,
} from '../actions';
import { FETCH_COMPANIES_SUCCESS, UPDATE_USER } from '../actions/currentUser';
import { getIsSharedLinkViewForSingleProject } from '../search/permissions/isAllowedToViewProject';

// @test-export
export const getDefaultState = () => {
  const user = {};
  const prefs = {
    sked_view_type: undefined,
  };
  const myCompanies = {
    companies: [],
  };

  const shared_link_view = config.isSharedView;

  if (shared_link_view) {
    prefs.sked_zoom = config.sharedViewScheduleDensity;
  }

  return {
    ...user,
    shared_link_view,
    prefs,
    savedSearches: {},
    companySso: {},
    accountLoaded: false,
    myCompanies,
    locale: config.locale,
  };
};

/**
 * Because of the window.loadData type, the JS inference of this reducer
 * is different between Mobile and Web, making the TS checks pass for the former
 * and fail for the latter
 *
 * With this jsdoc definition we are aligning the definitions to be unkonwn
 *
 * @param {unknown} state
 * @param {unknown} action
 * @returns
 */
const user = (state = getDefaultState(), action) => {
  switch (action.type) {
    case UPDATE_USER:
    case FETCH_USER_SUCCESS: {
      let sked_view_type = state.prefs.sked_view_type;

      if (getIsSharedLinkViewForSingleProject(action.user)) {
        sked_view_type = 'projects';
      }

      return {
        ...state,
        ...action.user,
        prefs: {
          ..._get(state, 'prefs', {}),
          ..._get(action, 'user.prefs', {}),
          sked_view_type,
        },
      };
    }

    case FETCH_COMPANIES_SUCCESS: {
      return {
        ...state,
        myCompanies: {
          companies: action.companies,
        },
      };
    }

    case FETCH_COMPANY_SSO_SUCCESS:
    case UPDATE_COMPANY_SSO_SUCCESS:
      return {
        ...state,
        companySso: action.payload,
      };

    case UPDATE_COMPANY_PREFS_SUCCESS: {
      const companyName = _get(action, 'payload.company_name');
      if (!companyName) return state;
      return {
        ...state,
        user: {
          ...state.user,
          company_name: companyName,
        },
      };
    }

    case CHANGE_ACCOUNT_OWNER_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case USER_PREFS_UPDATE: {
      return {
        ...state,
        prefs: {
          ...state.prefs,
          [action.key]: action.val,
        },
      };
    }

    case USER_PREFS_MULTI_UPDATE: {
      return {
        ...state,
        prefs: {
          ...state.prefs,
          ...action.prefsObj,
        },
      };
    }

    case savedSearchesLoadedAction.type: {
      return {
        ...state,
        savedSearches: action.payload,
      };
    }

    case SEARCH_CONTEXT_LOAD_FINISH: {
      return {
        ...state,
        savedSearches: action.context.savedSearches,
      };
    }

    case SEARCH_UPDATE_SAVED_SEARCHES: {
      return {
        ...state,
        savedSearches: action.savedSearches,
      };
    }

    default:
      return state;
  }
};

export default user;
