import { connect } from 'react-redux';
import { getAccounts, getPeople, getUser } from 'selectors';

import { requestActivity } from './requestActivity';

const mapStateToProps = (state) => {
  const modalActivity = state.activityData.activityCollections.modal;
  return {
    modalActivity: modalActivity?.items || [],
    allModalActivity: modalActivity?.allFetched || [],
    accounts: getAccounts(state),
    people: getPeople(state),
    user: getUser(state),
  };
};

const mapDispatchToProps = {
  requestTaskIdActivity: (specialFilterer) => (dispatch) => {
    dispatch(
      requestActivity({
        specialName: 'modal',
        toClear: true,
        specialFilterer,
      }),
    );
  },

  // `parseCurrentActivity` is not actually a dispatch function
  // `taskActivityConnect` is used in a couple places with duplicate code.
  // (EditTaskModal.coffee and ObserveTaskModal.jsx)
  // Merging that code has some difficulty because one is .coffee.
  // Rather than add to the redundancy, `parseCurrentActivity`
  // lives here for now.
  // `parseCurrentActivity` is also relevant to the rest of
  // the logic in this module.
  parseCurrentActivity: (task, modalActivity) => () => {
    if (!task) {
      return [];
    }
    let currentActivity = [];
    if (
      task.task_id ||
      task.timeoff_id ||
      task.status_id ||
      task.logged_time_id
    ) {
      const activity = modalActivity || [];
      const isLoggedTime = task.logged_time_id;

      currentActivity = activity.filter((item) => {
        if (item.item_type === 'task' && !isLoggedTime) {
          return +item.item_id === +task.task_id;
        } else if (item.item_type === 'timeoff') {
          return +item.item_id === +task.timeoff_id;
        } else if (item.item_type === 'status') {
          return +item.item_id === +task.status_id;
        } else if (item.item_type === 'logged_time') {
          return item.item_id === task.logged_time_id;
        }
        return false;
      });
    }
    return currentActivity;
  },
};

export default connect(mapStateToProps, mapDispatchToProps);
