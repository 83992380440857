import { keyBy } from 'lodash';

import request from '../lib/request';

export const ONEOFFS_LOAD_START = 'oneoffs/LOAD_START';
export const ONEOFFS_LOAD_FAILED = 'oneoffs/LOAD_FAILED';
export const ONEOFFS_LOAD_FINISH = 'oneoffs/LOAD_FINISH';
export const ONEOFF_CREATED = 'oneoffs/CREATED';
export const ONEOFF_DELETED = 'oneoffs/DELETED';

const DATE = 'YYYY-MM-DD';

export const fetchOneOffs = (start, end, rebuild) => async (dispatch) => {
  const startDate = typeof start === 'string' ? start : start.format(DATE);
  const endDate = typeof end === 'string' ? end : end.format(DATE);

  try {
    dispatch({ type: ONEOFFS_LOAD_START });

    const res = await request.get(
      `oneoff-days?start_date=${startDate}&end_date=${endDate}&per-page=5000`,
      undefined,
      {
        version: 'f3',
      },
    );
    const oneOffs = keyBy(res, 'oneoff_id');

    dispatch({ type: ONEOFFS_LOAD_FINISH, oneOffs, rebuild });
  } catch (e) {
    console.error(e);
    dispatch({ type: ONEOFFS_LOAD_FAILED });
  }
};

export const createOneOff = (oneOffDay) => async (dispatch) => {
  const { oneoff_id } = oneOffDay;
  const payload = {
    date: oneOffDay.date,
    people_id: oneOffDay.people_id,
  };

  const res = await request.post('oneoff-days', payload, { version: 'f3' });

  return dispatch({
    type: ONEOFF_CREATED,
    oneOff: { ...res, replacesTempId: oneoff_id },
  });
};

export const removeOneOff = (oneOff) => async (dispatch) => {
  await request.del(`oneoff-days/${oneOff.oneoff_id}`, {}, { version: 'f3' });
  dispatch({ type: ONEOFF_DELETED, oneOff });
};
