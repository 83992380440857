import { Reducer, useReducer } from 'react';

import { CsvParsedData } from '../parseData';

export enum ExportCSVStep {
  Pending = 'pending',
  AwaitingFetch = 'awaitingFetch',
  AwaitingParse = 'awaitingParse',
  AwaitingCsvConversion = 'awaitingCsvConversion',
  Finished = 'finished',
}

export enum ExportCSVActionType {
  ExportTriggered = 'exportTriggered',
  FetchComplete = 'fetchComplete',
  ParseError = 'parseError',
  ParseComplete = 'parseComplete',
  CsvConversionComplete = 'csvConversionComplete',
  Reset = 'reset',
}

export type ExportCSVState = {
  step: ExportCSVStep;
  result?: CsvParsedData;
  rangesToFetch: number[];
  error?: string;
  csv?: string[];
};

export type ExportCSVAction =
  | { type: ExportCSVActionType.ExportTriggered; rangesToFetch: number[] }
  | { type: ExportCSVActionType.FetchComplete }
  | { type: ExportCSVActionType.ParseError; error: unknown }
  | {
      type: ExportCSVActionType.ParseComplete;
      result: CsvParsedData;
    }
  | {
      type: ExportCSVActionType.CsvConversionComplete;
      csv: string[];
      result: string[];
    }
  | { type: ExportCSVActionType.Reset };

const INITIAL_STATE: ExportCSVState = {
  step: ExportCSVStep.Pending,
  rangesToFetch: [],
};

const stateMachine: Reducer<ExportCSVState, ExportCSVAction> = (
  state,
  action,
) => {
  switch (action.type) {
    case ExportCSVActionType.ExportTriggered: {
      if (action.rangesToFetch.length) {
        return {
          step: ExportCSVStep.AwaitingFetch,
          rangesToFetch: action.rangesToFetch,
        };
      } else {
        return {
          step: ExportCSVStep.AwaitingParse,
          rangesToFetch: [],
        };
      }
    }

    case ExportCSVActionType.FetchComplete: {
      return {
        step: ExportCSVStep.AwaitingParse,
        rangesToFetch: [],
      };
    }

    case ExportCSVActionType.ParseError: {
      return {
        ...INITIAL_STATE,
        error: (action.error as { error: string }).error,
      };
    }

    case ExportCSVActionType.ParseComplete: {
      return {
        step: ExportCSVStep.AwaitingCsvConversion,
        result: action.result,
        rangesToFetch: [],
      };
    }

    case ExportCSVActionType.CsvConversionComplete: {
      return {
        step: ExportCSVStep.Finished,
        csv: action.csv,
        result: state.result,
        rangesToFetch: [],
      };
    }

    case ExportCSVActionType.Reset: {
      return { ...INITIAL_STATE };
    }
  }
};

export const useExportCSVStateMachine = () => {
  return useReducer(stateMachine, INITIAL_STATE);
};
