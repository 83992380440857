import { keyBy, omit } from 'lodash';

import {
  STATUS_DELETED,
  STATUS_UPDATED,
  STATUSES_LOAD_FAILED,
  STATUSES_LOAD_FINISH,
  STATUSES_LOAD_START,
} from '../actions';
import { formatStatus } from '../lib/formatters';

const DEFAULT_STATE = {
  statuses: {},
  loadState: 'UNLOADED',
};

const replace = (state, action) => {
  const {
    status: statusesToUpdate = [],
    delete: { status: statusesToDelete = [] } = {},
  } = action.response || {};
  const newStatuses = { ...state.statuses };
  statusesToDelete.forEach(({ status_id }) => {
    delete newStatuses[status_id];
  });

  statusesToUpdate.forEach((status) => {
    if (status.status_id) {
      newStatuses[status.status_id] = formatStatus(status);
      if (action.item && action.item.temporaryId) {
        newStatuses[status.task_id].replacesTempId = action.item.temporaryId;
      }
    }
  });

  if (!statusesToUpdate.length && !statusesToDelete.length) {
    return state;
  }

  return { ...state, statuses: newStatuses };
};

export default function reducer(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case STATUSES_LOAD_START: {
      return {
        ...state,
        loadState: 'LOADING',
      };
    }

    case STATUSES_LOAD_FAILED: {
      return {
        ...state,
        loadState: 'LOAD_FAILED',
      };
    }

    case STATUSES_LOAD_FINISH: {
      if (!action.statuses || !action.statuses.length) {
        return state;
      }
      const prevStatuses = action.shouldRefresh ? {} : state.statuses;
      const statuses = action.statuses.map(formatStatus);
      const statusesMap = keyBy(statuses, 'status_id');
      return {
        ...state,
        loadState: 'LOADED',
        statuses: action.rebuild
          ? statusesMap
          : {
              ...prevStatuses,
              ...statusesMap,
            },
      };
    }

    case STATUS_UPDATED:
      return replace(state, action);

    case STATUS_DELETED: {
      return {
        ...state,
        statuses: omit(state.statuses, action.id),
      };
    }

    default: {
      return state;
    }
  }
}
