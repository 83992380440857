import React, { KeyboardEvent, ReactNode, useMemo, useRef } from 'react';

import { DEFAULT_PALETTE } from '@float/constants/colors';
import { useControllableState } from '@float/libs/hooks/useControllableState';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { Popover } from '@float/ui/deprecated/Tooltip/Popover';

import DEFAULT_COLORS_HIGH_CONTRAST from '../default-colors-high-contrast';
import { ColorPickerMenu } from './ColorPickerMenu';

const KEYS = {
  enter: 13,
  tab: 9,
  escape: 27,
};

export type ColorPickerDropdownProps = {
  highContrastDefaultColors?: boolean;
  disableCustomColors?: boolean;
  value: string;
  defaultColors?: string[];
  clientColors?: string[];
  recentColors?: string[];
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  onChange: (value: string) => void;
  children: ReactNode;
  modal?: boolean;
};

// Needs to be a forwardRef in order to be able to receive
// ref from parent components. E.g. form control
export const ColorPickerDropdown = React.forwardRef(
  (props: ColorPickerDropdownProps, _ref) => {
    const [open, setOpen] = useControllableState({
      prop: props.open,
      defaultProp: false,
      onChange: props.onOpenChange,
    });

    const defaultColors = useMemo(
      () =>
        props.defaultColors
          ? props.defaultColors
          : props.highContrastDefaultColors
            ? DEFAULT_COLORS_HIGH_CONTRAST
            : DEFAULT_PALETTE,
      // We want to evaluate the default colors only once
      [],
    );

    const initialColorRef = useRef(props.value || defaultColors[0]);

    const hideDropdown = () => {
      if (!/^[0-9a-f]{3,6}$/i.test(props.value)) {
        const firstDefaultColor = defaultColors[0];
        props.onChange(firstDefaultColor);
      }

      setOpen(false);
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.keyCode === KEYS.enter) {
        prevent(e);
        setOpen(false);
      }

      if (e.keyCode === KEYS.tab) {
        setOpen(false);
      }

      if (e.keyCode === KEYS.escape) {
        prevent(e);
        props.onChange(initialColorRef.current);
        setOpen(false);
      }
    };

    return (
      <Popover
        placement="bottom"
        className="menu overflow"
        open={open}
        onOpenChange={setOpen}
        onContentClick={(e) => {
          e.stopPropagation();
        }}
        distance={5}
        modal={props.modal}
        content={
          <ColorPickerMenu
            disableCustomColors={props.disableCustomColors}
            value={props.value}
            colors={defaultColors}
            handleKeyDown={handleKeyDown}
            clientColors={props.clientColors}
            recentColors={props.recentColors}
            onChange={props.onChange}
            onSelect={hideDropdown}
          />
        }
      >
        {props.children}
      </Popover>
    );
  },
);
