import {
  createView,
  deleteView,
  updateView,
  VIEW_CREATED,
  VIEW_DELETED,
  VIEW_UPDATED,
  ViewCreatedAction,
  ViewDeletedAction,
  ViewUpdatedAction,
} from '@float/common/actions/views';
import { AppDispatchStrict } from '@float/common/store';

type Actions = ViewCreatedAction | ViewDeletedAction | ViewUpdatedAction;

export function handleViewActions(
  action: Actions,
  dispatch: AppDispatchStrict,
) {
  switch (action.type) {
    case VIEW_CREATED: {
      return dispatch(deleteView(action.view.id));
    }
    case VIEW_UPDATED: {
      if (action.undoPayload) {
        return dispatch(updateView(action.undoPayload.id, action.undoPayload));
      }
      break;
    }
    case VIEW_DELETED: {
      if (action.undoPayload) {
        return dispatch(createView(action.undoPayload));
      }
      break;
    }
  }
}
