import { config } from '@float/libs/config';
import {
  AccountType,
  CurrentUser,
  EnhancedProject,
  Person,
  Project,
  ProjectStatus,
} from '@float/types';

import { getManagerAccess } from '../../lib/acl/access';
import { userCanOnlyViewThemself } from '../../lib/rights';

function isProjectManager(user: CurrentUser) {
  return (
    user.account_type_id === AccountType.Manager &&
    user.account &&
    getManagerAccess(user.account).isProjectManager
  );
}

function isProjectOwner(user: CurrentUser, project: EnhancedProject) {
  return user.account_id && project.project_manager === user.account_id;
}

export function getIsSharedLinkViewForSingleProject(
  user: CurrentUser,
  project?: Project,
) {
  const linkProjectId = user.shared_link_filters?.project_id;

  const result = config.isSharedView && Boolean(linkProjectId);

  if (!project) return result;

  return result && linkProjectId === project.project_id;
}

function isAllowedToViewDraftProject(
  user: CurrentUser,
  draftProject: EnhancedProject,
) {
  if (getIsSharedLinkViewForSingleProject(user, draftProject)) return true;

  switch (user.account_type_id) {
    case AccountType.Member:
      return false;
    case AccountType.AccountOwner:
    case AccountType.Admin:
    case AccountType.Billing:
      return true;
    default:
      break;
  }

  if (isProjectOwner(user, draftProject)) return true;

  if (isProjectManager(user) && draftProject.common) return true;

  return false;
}

export const isAllowedToViewProject = (
  context: { user: CurrentUser; people: Record<number, Person> },
  project: EnhancedProject,
) => {
  if (!project) return false;

  const { user, people } = context;

  if (project.status === ProjectStatus.Draft) {
    return isAllowedToViewDraftProject(user, project);
  }

  if (isProjectOwner(user, project)) {
    return true;
  }

  const canOnlyViewOwnSchedule = userCanOnlyViewThemself(user);

  if (canOnlyViewOwnSchedule) {
    return Boolean(
      user.people_id && project.all_people_ids.includes(user.people_id),
    );
  }

  const canViewEveryone = !user.department_filter?.length;

  if (canViewEveryone) return true;

  if (isProjectManager(user) && project.common) return true;

  // The user is part of the project
  if (user.people_id && project.all_people_ids.includes(user.people_id)) {
    return true;
  }

  // At least of the project members is part of one of departments managed by user
  for (const id of project.all_people_ids) {
    const dept = people[id]?.department_id;

    if (dept && user.account?.department_filter_all?.includes(dept)) {
      return true;
    }
  }

  return false;
};
