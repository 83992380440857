import React from 'react';

import * as Icons from '@float/ui/deprecated/Earhart/Icons';

import { StyledBullet, StyledListItem } from './styles';

export const NavigationStep = (props) => {
  const { step, index, active = false, completed = false } = props;

  return (
    <StyledListItem key={step} $active={active} $completed={completed}>
      <StyledBullet>
        <span>
          <Icons.IconCheck />
        </span>
        <span>{index}</span>
      </StyledBullet>
      {step}
    </StyledListItem>
  );
};
