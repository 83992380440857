import React, { useId } from 'react';
import { useFormContext } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';

import * as styles from './styles.css';

export type ViewNameInputProps = {
  isUniqueViewName: (name: string) => boolean;
};

export function ViewNameInput(props: ViewNameInputProps) {
  const id = useId();

  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <input
        className={styles.nameInput}
        placeholder={t`Enter name of View`}
        autoComplete="off"
        aria-invalid={errors.name ? 'true' : 'false'}
        aria-describedby={errors.name ? `name-error-${id}` : undefined}
        {...register('name', {
          required: true,
          maxLength: 255,
          validate: {
            unique: props.isUniqueViewName,
          },
        })}
      />
      {errors.name?.type === 'required' && (
        <div
          data-testid="required-error"
          className={styles.error}
          id={`name-error-${id}`}
        >
          <Trans>You must enter a name before saving</Trans>
        </div>
      )}
      {errors.name?.type === 'unique' && (
        <div
          data-testid="unique-error"
          className={styles.error}
          id={`name-error-${id}`}
        >
          <Trans>A View with this name already exists</Trans>
        </div>
      )}
      {errors.name?.type === 'maxLength' && (
        <div
          data-testid="maxLength-error"
          className={styles.error}
          id={`name-error-${id}`}
        >
          <Trans>Name must be 255 characters or less</Trans>
        </div>
      )}
    </div>
  );
}
