import { trackEvent as trackGtmEvent } from './gtm';
import { trackCompanyData, trackEvent as trackIntercomEvent } from './intercom';

function getPage(options = {}) {
  const { pathname } = options;
  if (!pathname || typeof pathname !== 'string') return null;
  const page = pathname.substr(1);
  return !page ? 'timeline' : page;
}

// Sends requests both to Amplitude (via GTM) and Intercom
export function trackEvent(eventName, eventData, options) {
  const page = getPage(options);
  if (page && !eventData.page) {
    eventData.page = page;
  }
  trackGtmEvent(eventName, eventData);
  trackIntercomEvent(eventName, eventData);
}

export function trackIntegrationEvent(type, eventName, eventData, cid) {
  trackEvent(eventName, { type, ...(eventData || {}) });
  trackCompanyData(eventName, type, cid);
}

export const getTrackingFields = (prev, next, fieldsList) => {
  return fieldsList.reduce((total, field) => {
    total[field] = next[field];
    if (prev[field] !== next[field]) {
      total[`${field}Changed`] = true;
    }
    return total;
  }, {});
};
