import React from 'react';
import { connect } from 'react-redux';

import { updateCompanyPrefsNoRequest } from '@float/common/actions/companyPrefs';

import RestrictedMessage from '../RestrictedMessage';
import { updateRecurringEmailSettings } from './actions';
import Notifications from './component';

const mapStateToProps = (state) => {
  return {
    companyPrefs: state.companyPrefs,
    timeTrackingEnabled: state.companyPrefs.time_tracking > 0,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateCompanyNotificationPrefs: (prefs) =>
    dispatch(updateCompanyPrefsNoRequest(prefs)),
  updatePeopleNotificationPrefs: (prefs) =>
    dispatch(updateRecurringEmailSettings(prefs)),
});

const NotificationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notifications);

export default ({ userCanSee }) => {
  if (!userCanSee.general) {
    return <RestrictedMessage />;
  }

  return <NotificationsContainer />;
};
