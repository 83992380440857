import React, { forwardRef, Fragment } from 'react';

import * as Icons from '@float/ui/deprecated/Earhart/Icons';

import {
  StyledHr,
  StyledIcon,
  StyledIconCheck,
  StyledItem,
  StyledLabel,
  StyledList,
  StyledTitle,
} from './styles';

const List = forwardRef((props, ref) => {
  const {
    className,
    appearance = 'flay',
    options = [],
    value = null,
    minWidth = null,
    title,
    onClick,
    renderLabel,
    ...rest
  } = props;

  const onClickHandler = (e) => {
    const index = e.currentTarget.getAttribute('data-index');

    if (onClick) onClick(options[index]);
  };

  const getLabel = (option, selected) => {
    if (typeof renderLabel === 'function') {
      return renderLabel(option, selected);
    }
    // some options use name, others use label, and others use element
    return option.name || option.label || option.element || option.title;
  };

  return (
    <StyledList
      ref={ref}
      appearance={appearance}
      className={className}
      minWidth={minWidth}
      {...rest}
    >
      {title && <StyledTitle>{title}</StyledTitle>}
      {options.map((data, i, arr) => {
        if (data.render) {
          const Component = data;
          return <Component key={`c-${i}`} />;
        }
        const isFirstItem = i == 0;
        const isLastItem = i === arr.length - 1;
        const isNextItemSeparator = !!(arr[i + 1] === List.Separator);
        if (data === List.Separator) {
          const renderSeprator =
            !isFirstItem && !isLastItem && !isNextItemSeparator;
          if (renderSeprator) {
            return <List.Separator key={`separator-${i}`} />;
          }

          return null;
        }

        const withIcon = !!data.icon;
        const withShortcut = !!data.shortcut || !!data.hotkey;
        const withIconCheck =
          !withShortcut && (data.selected !== undefined || value !== null);

        const shortcut = data.shortcut || data.hotkey;

        // some options come with selected prop, others need to be calculated based on current value
        const isActive =
          data.selected ||
          (value !== null && value == data.value) ||
          (value !== null && value == data.mode);

        const label = getLabel(data, isActive);
        if (!label) return null;

        const isClickable = data.clickable || data.clickable === undefined;

        const itemProps = Object.assign(
          {
            ['data-index']: i,
          },
          isClickable && {
            onClick: onClickHandler,
          },
        );

        return (
          <Fragment key={data.key || data.value || label}>
            <StyledItem
              active={isActive}
              withIcon={withIcon}
              withShortcut={withShortcut}
              withIconCheck={withIconCheck}
              {...itemProps}
            >
              {withIcon && <StyledIcon>{data.icon}</StyledIcon>}

              <StyledLabel>{label}</StyledLabel>

              {withIconCheck && (
                <StyledIconCheck>
                  <Icons.IconCheck />
                </StyledIconCheck>
              )}

              {withShortcut && (
                <StyledIconCheck>{shortcut.toUpperCase()}</StyledIconCheck>
              )}
            </StyledItem>
          </Fragment>
        );
      })}
    </StyledList>
  );
});

List.Separator = StyledHr;

export default List;
