import styled from 'styled-components';

import * as Animation from '@float/ui/deprecated/Earhart/Animation';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';

const { Duration, Easing } = Animation.Settings;

export const StyledLink = styled.a`
  position: relative;

  line-height: 1.4;

  color: ${Colors.FIN.Lt.Emphasis.Primary};

  padding: 0;
  margin: 0;

  vertical-align: baseline;

  transition-property: color;
  transition-duration: ${Duration.Normal}, ${Duration.Short}, ${Duration.Short};
  transition-timing-function: ${Easing.InOutCurve};

  &:hover {
    color: ${Colors.FIN.Lt.Buttons.Primary.Hover.Bg};

    text-decoration: underline;
  }
`;
