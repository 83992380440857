import React from 'react';
import { Link } from 'react-router-dom';

import { stopPropagation } from '@float/libs/utils/events/stopPropagation';
import EH from '@float/ui/deprecated/Earhart';

import { Appearance, Size } from '../Earhart/Tags/Tag';

type TagConfig =
  | 'string'
  | {
      name?: string;
      label?: string | number;
      color?: string;
      to?: { pathname: string; query: string };
      onClick?: () => void;
    };

const getLabelFromTag = (tag: TagConfig) =>
  typeof tag === 'string' ? tag : tag.label || tag.name;

const getColorFromTag = (tag: TagConfig) => {
  if (typeof tag === 'string') {
    return null;
  }

  if (tag.color) {
    return `#${tag.color}`;
  }

  return undefined;
};

type TagProps = {
  appearance?: Appearance;
  color?: string;
  maxWidth?: number;
  size?: Size;
  tag: TagConfig;
};

export const Tag = React.forwardRef<
  HTMLSpanElement,
  TagProps & Omit<JSX.IntrinsicElements['span'], 'ref'>
>(({ tag, color, appearance, size, maxWidth }: TagProps, ref) => {
  const label = getLabelFromTag(tag);
  const tagColor = getColorFromTag(tag) || color;
  const onClick = typeof tag !== 'string' && tag.onClick;
  const to = typeof tag !== 'string' && tag.to;

  return (
    <EH.Tags.Tag
      ref={ref}
      key={label}
      appearance={appearance}
      color={tagColor}
      size={size}
      onClick={onClick || stopPropagation}
      data-label={label}
      style={maxWidth ? { maxWidth } : undefined}
    >
      {to ? <Link to={to}>{label}</Link> : label}
    </EH.Tags.Tag>
  );
});
