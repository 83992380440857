import * as React from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import cn from 'classnames';

import { IconChevronDown } from '../../icons/essentials/IconChevronDown';

import * as styles from './styles.css';

export const AccordionHeader = React.forwardRef<
  React.ElementRef<typeof Accordion.Header>,
  React.ComponentPropsWithoutRef<typeof Accordion.Header> & {
    info?: React.ReactNode;
    isLoading?: boolean;
    title?: string;
  }
>(({ info, children, title, className, isLoading, ...props }, forwardedRef) => {
  // The accordion can be opened:
  // - By clicking on the header
  // - By clicking on the top-left button
  return (
    <Accordion.Trigger asChild>
      <Accordion.Header
        className={cn(styles.accordionHeaderWrapper, className)}
        {...props}
        data-disabled={isLoading}
        ref={forwardedRef}
      >
        <Accordion.Trigger title={title} className={styles.accordionTrigger}>
          <React.Fragment>
            {children}
            <IconChevronDown className={styles.accordionChevron} aria-hidden />
          </React.Fragment>
        </Accordion.Trigger>
        {isLoading ? null : info}
      </Accordion.Header>
    </Accordion.Trigger>
  );
});
