export const ACTIVITY_SEEN_UPDATE = 'ACTIVITY_SEEN_UPDATE';

// allSeenItemsCache is a dictionary of seen activitiy item arrays assigned to company ids.
// i.e.
/*
  {
    someCompanyId: [
      'someActivityId', 'someOtherActivityId'
    ],
    someOtherCompany : [
      'anotherActivityId'
    ]
  }
*/
// allSeenItemsCache is synced with `localStorage.setItem('seenItems')`.
// localStorage is finite so each company array is limited to 500 latest seen items.
// allSeenItemsCache[relevantCompanyId] is turned into a dictionary
// within the application's front end store for easier lookups.
// i.e.
/*
  {
    someActivityId: true,
    someOtherActivityId: true
  }
*/

let allSeenItemsCache;
function getLocalStorageSeenItems(companyId) {
  if (allSeenItemsCache) {
    return allSeenItemsCache[companyId] || [];
  }
  let seenItems = localStorage.getItem('seenItems');
  seenItems = seenItems ? JSON.parse(seenItems) : {};
  allSeenItemsCache = seenItems;
  return seenItems[companyId] || [];
}

function setLocalStorageSeenItems(companyId, items) {
  allSeenItemsCache[companyId] = items.slice(0, 500);
  localStorage.setItem('seenItems', JSON.stringify(allSeenItemsCache));
}

function makeArrayCollectionOfUniques(arr) {
  const dict = {};
  const array = arr.filter((item) => {
    if (!dict[item]) {
      dict[item] = true;
      return true;
    }
    return false;
  });
  return {
    array,
    dict,
  };
}

export const updateSeenActivities = (items = []) => {
  return (dispatch, getState) => {
    const state = getState();
    const { currentUser } = state;
    const { account_id } = currentUser;
    const itemsIds = items.map((item) => item.activity_id);
    const companyId = state.companyPrefs.uuid;
    const oldSeenItems = getLocalStorageSeenItems(companyId);
    const combinedOldAndNew = itemsIds.concat(oldSeenItems);
    const collectionOfUniques = makeArrayCollectionOfUniques(combinedOldAndNew);
    setLocalStorageSeenItems(companyId, collectionOfUniques.array);
    dispatch({
      type: ACTIVITY_SEEN_UPDATE,
      seen: collectionOfUniques.dict,
      currentUserAccountId: account_id,
    });
  };
};

export const retrieveActivitySeen = () => {
  return (dispatch, getState) => {
    const { currentUser, companyPrefs } = getState();
    const { account_id } = currentUser;
    const companyId = companyPrefs.uuid;
    const oldSeenItems = getLocalStorageSeenItems(companyId);
    dispatch({
      type: ACTIVITY_SEEN_UPDATE,
      seen: makeArrayCollectionOfUniques(oldSeenItems).dict,
      currentUserAccountId: account_id,
    });
  };
};
