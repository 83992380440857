import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isUndefined } from 'lodash';
import modalManagerHoc from 'modalManager/modalManagerHoc';
import { getNotificationPrefs } from 'selectors';
import styled from 'styled-components';

import {
  updateUserPref,
  updateUserTimezone,
} from '@float/common/actions/currentUser';
import { updateScheduleEmailPrefs } from '@float/common/actions/people';
import timezones from '@float/common/lib/timezones';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { Checkbox, Label, Modal, VirtualSelect } from '@float/ui/deprecated';
import { TextButton } from '@float/ui/deprecated/Earhart/Buttons';
import { saveScheduleEmailPreferences } from '@float/web/settingsV2/components/Notifications/actions';
import withUserCanSee from 'components/decorators/withUserCanSee';

import NotificationSlackButton from '../../slack/NotificationSlackButton';

import slackImage from '../../assets/images/slack_logo.svg';

const { CheckboxLabel } = Checkbox._styles;

const StyledTitle = styled.div`
  max-width: 480px;
`;

const StyledSection = styled.div`
  margin-top: 20px;
  color: ${(p) => p.theme.charcoalGrey};

  ${CheckboxLabel} {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledSectionTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;

const StyledHr = styled.hr`
  border-color: ${(p) => p.theme.grey};
  margin: 21px 0 20px;
`;

const SlackLogo = styled.img`
  display: block;
  width: 116px;
  margin-top: 4px;
  margin-bottom: 22px;
`;

const StatusText = styled.p`
  margin-top: -11px;
  color: #868d92;
  font-size: 14px;
`;

const UserTimezone = styled.div`
  display: flex;

  ${Label} {
    line-height: 1.29;
    letter-spacing: -0.2px;
    padding-top: 0;
    margin-right: 6px;
    display: flex;
    align-items: center;
  }
`;

const NotificationSettingsModal = ({
  userCanSee,
  personId,
  scheduleEmailEnabled,
  timeTrackingFeatureEnabled,
  timeTrackingEmailEnabled: timeTrackingEnabledProp,
  scheduleEmailFrequency,
  timeTrackingEmailFrequency,
  notificationPrefs,
  manageModal,
  updateScheduleEmailOptin,
  updatePref,
  updateTimezone,
  timeoffApprovals,
  ...props
}) => {
  const [timeTrackingEmailEnabled, setTimeTrackingEmailEnabled] = useState(
    timeTrackingEnabledProp,
  );

  const [timezone, setTimezone] = useState(props.timezone);
  const [timezoneLabel, setTimezoneLabel] = useState(
    () => timezones.find((o) => o.value === props.timezone)?.label || '',
  );

  const history = useHistory();

  const canTasksBeScheduledForThisPerson = !!personId;

  const hide = (evt) => {
    prevent(evt);
    manageModal({
      visible: false,
      modalType: 'notificationSettingsModal',
    });
  };

  const changeNotifPref = (key, isChecked) => {
    updatePref(key, isChecked ? 1 : 0);
  };

  const save = ({ newEmailEnabled }) => {
    const auto_email = newEmailEnabled ? 1 : -2;
    saveScheduleEmailPreferences({
      auto_email,
      people: [personId],
      action: 'yt1=Save',
    });
    updateScheduleEmailOptin({
      personId,
      auto_email,
    });
  };

  const onEmailEnabledChanged = (value) => {
    save({ newEmailEnabled: value });
  };

  const onReminderEnabledChanged = (value) => {
    setTimeTrackingEmailEnabled(value);
    updatePref('logtime_email_active', value ? 1 : 0);
  };

  const onTimezoneChange = (opt) => {
    setTimezone(opt.value);
    setTimezoneLabel(opt.label);
    updateTimezone(opt.value);
  };

  const onClickUpdateFrequency = (e) => {
    e.preventDefault();

    history.push('/admin/notifications');

    hide(e);
  };

  return (
    <Modal isOpen onClose={hide}>
      <Modal.Header>
        <Modal.Title>Notifications</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          paddingBottom: 32,
          minHeight: canTasksBeScheduledForThisPerson ? 594 : 0,
        }}
      >
        <StyledTitle>
          Float notifications ensure you're always working off the latest
          schedule.{' '}
          <a
            href="http://support.float.com/en/articles/3412688-notifications"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </a>
          .
        </StyledTitle>

        {canTasksBeScheduledForThisPerson && (
          <>
            <StyledHr />

            <StyledSection>
              <StyledSectionTitle>
                <span>Email me...</span>
                {userCanSee.general && (
                  <TextButton
                    appearance="flue-fill"
                    onClick={onClickUpdateFrequency}
                  >
                    Update frequency
                  </TextButton>
                )}
              </StyledSectionTitle>
              <Checkbox
                label={`My ${
                  scheduleEmailFrequency === 2 ? 'daily' : 'weekly'
                } schedule`}
                value={scheduleEmailEnabled}
                readOnly={scheduleEmailFrequency === 0}
                title={
                  scheduleEmailFrequency === 0
                    ? 'Disabled by team admin'
                    : undefined
                }
                onChange={onEmailEnabledChanged}
              />
              {timeTrackingFeatureEnabled && (
                <Checkbox
                  label={`A ${
                    timeTrackingEmailFrequency === 2 ? 'daily' : 'weekly'
                  } reminder to log time`}
                  value={timeTrackingEmailEnabled}
                  onChange={onReminderEnabledChanged}
                />
              )}
              <UserTimezone dropdownWidth={timezoneLabel.length * 9}>
                <Label>before 7am on</Label>
                <VirtualSelect
                  nonNullable
                  clearInputOnDropdownOpen={false}
                  visibleItems={6}
                  value={timezone}
                  options={timezones}
                  onChange={onTimezoneChange}
                />
              </UserTimezone>
            </StyledSection>
          </>
        )}

        <>
          <StyledHr style={{ margin: '31px 0 -3px' }} />

          <StyledSection>
            <StyledSectionTitle>Notify me when...</StyledSectionTitle>
            {canTasksBeScheduledForThisPerson && (
              <Checkbox
                label="Allocations assigned to me are updated"
                value={notificationPrefs.myTasks}
                onChange={(value) => changeNotifPref('notif_my_tasks', value)}
              />
            )}
            <Checkbox
              label="Allocations on any projects I own are updated"
              value={notificationPrefs.allTasks}
              onChange={(value) => changeNotifPref('notif_all_tasks', value)}
            />
            <Checkbox
              label="Anyone on a project I own schedules time off"
              value={notificationPrefs.timeoffs}
              onChange={(value) => changeNotifPref('notif_timeoffs', value)}
            />
            {timeoffApprovals && (
              <Checkbox
                label="Anyone in the team I manage schedules time off"
                value={notificationPrefs.timeoffApprovals}
                onChange={(value) =>
                  changeNotifPref('notif_timeoff_approvals', value)
                }
              />
            )}
          </StyledSection>
        </>
        {canTasksBeScheduledForThisPerson && (
          <>
            <StyledHr style={{ margin: '31px 0 -3px' }} />
            <StyledSection>
              <SlackLogo src={slackImage} />
              <StatusText>
                Receive your schedule in Slack and get a message when it
                changes.
              </StatusText>
              <NotificationSlackButton />
            </StyledSection>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const {
    admin_id: accountId,
    people_id: personId,
    prefs: userPrefs,
    timezone: accountTimezone,
  } = state.currentUser;
  const person = (personId && state.people.people[personId]) || {};
  let {
    timezone: companyTimezone,
    timeoff_approvals: timeoffApprovals,
    time_tracking: timeTrackingFeature,
    email_notice_active: scheduleEmailFrequency,
    logtime_email_active: timeTrackingEmailFrequency = 0, // disabled by default
  } = state.companyPrefs;
  scheduleEmailFrequency =
    scheduleEmailFrequency == -1 ? 0 : Number(scheduleEmailFrequency);
  timeTrackingEmailFrequency =
    timeTrackingEmailFrequency == -1 ? 0 : Number(timeTrackingEmailFrequency);
  const timeTrackingEmailEnabled = isUndefined(userPrefs.logtime_email_active)
    ? timeTrackingEmailFrequency > 0
    : Number(userPrefs.logtime_email_active) !== 0;
  const scheduleEmailEnabled =
    scheduleEmailFrequency > 0 && Number(person.auto_email || 0) > -2;

  return {
    personId,
    scheduleEmailEnabled,
    scheduleEmailFrequency,
    timeTrackingEmailEnabled,
    timeTrackingEmailFrequency,
    timeoffApprovals,
    notificationPrefs: getNotificationPrefs(state),
    timeTrackingFeatureEnabled: timeTrackingFeature > 0,
    timezone: accountTimezone || companyTimezone,
    accountId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateScheduleEmailOptin: (prefs) =>
    dispatch(updateScheduleEmailPrefs(prefs)),
  updatePref: (key, value) => dispatch(updateUserPref(key, value)),
  updateTimezone: (value) => dispatch(updateUserTimezone(value)),
});

export default modalManagerHoc({
  Comp: connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withUserCanSee(NotificationSettingsModal)),
  modalType: 'notificationSettingsModal',
});
