import React from 'react';
import { t, Trans } from '@lingui/macro';

import { Button } from '@float/ui/deprecated';
import { Tag } from '@float/ui/deprecated/Earhart/Tags';
import { HoverCardInfo } from '@float/ui/deprecated/HoverCardInfo';

import ProFeatureIllustration from '../assets/ProFeature.svg';

import * as styles from './ViewsProFeatureTag.css';

export function ViewsProFeatureTag() {
  return (
    <HoverCardInfo
      title={<Trans>Shared Views</Trans>}
      align="start"
      placement="right"
      alignOffset={-35}
      distance={2}
      content={
        <div className={styles.content}>
          <img
            className={styles.img}
            width="129"
            height="124"
            src={ProFeatureIllustration}
            alt={t`Pro Feature`}
          />
          <div className={styles.text}>
            Get aligned and enhance focus by sharing customized Views with your
            Team.
          </div>
          <div className={styles.text}>
            Upgrade your plan to get shared Views.
          </div>
          <div className={styles.actions}>
            <Button
              as="a"
              href="https://support.float.com/en/articles/8282472-views?from=views_cta"
              target="_blank"
              appearance="clear"
              size="small"
            >
              <Trans>Learn more</Trans>
            </Button>
            <Button
              as="a"
              href="https://www.float.com/pricing/?from=views_cta"
              target="_blank"
              appearance="primary"
              size="small"
            >
              <Trans>View plans</Trans>
            </Button>
          </div>
        </div>
      }
    >
      <Tag color="blue" size="small" interactive>
        <Trans>Pro</Trans>
      </Tag>
    </HoverCardInfo>
  );
}
