import React, { forwardRef, useRef, useState } from 'react';
import useUpdateEffect from 'react-use/esm/useUpdateEffect';

import { useOnClickOutside } from '@float/ui/deprecated/hooks/useOnClickOutside';

import { IconArrowDown } from '../../Icons';
import { StyledButton, StyledContainer, StyledList } from './styles';

const getDefaultValue = ({ options, mapToValues, value }) => {
  if (value) {
    if (mapToValues.length > 0) {
      const index = mapToValues.indexOf(value);

      if (index > -1) {
        return options[index];
      }
    }

    return value;
  }

  if (mapToValues.length > 0) return mapToValues[0];
  if (options.length > 0) return options[0];

  return null;
};

const Dropdown = forwardRef((props, ref) => {
  const {
    options = [],
    mapToValues = [],
    value = null,
    disabled = false,
    onChange,
    ...other
  } = props;

  const listRef = useRef();
  const buttonRef = useRef();

  const [currentValue, setCurrentValue] = useState(
    getDefaultValue({ options, mapToValues, value }),
  );

  const [isOpen, setIsOpen] = useState(false);

  // Input events

  const onClick = () => {
    setIsOpen(!isOpen);
  };

  const onListChange = (value) => {
    setCurrentValue(value);

    setIsOpen(false);
  };

  // Hooks

  useOnClickOutside(
    (event) => {
      // if the list is open and the click is outside of the list,
      // but not on the dropdown button then close the list
      if (isOpen && !buttonRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    },
    [listRef],
  );

  useUpdateEffect(() => {
    if (disabled && isOpen) setIsOpen(false);
  }, [disabled]);

  useUpdateEffect(() => {
    if (onChange) {
      const label = currentValue;
      const value =
        mapToValues.length > 0
          ? mapToValues[options.indexOf(currentValue)]
          : currentValue;

      onChange({ label, value });
    }
  }, [currentValue]);

  // Render

  return (
    <StyledContainer ref={ref} {...other}>
      <StyledButton
        ref={buttonRef}
        appearance="secondary"
        icon={IconArrowDown}
        iconSize={20}
        trailingIcon
        disabled={disabled}
        onClick={onClick}
        isActive={isOpen}
      >
        {currentValue}
      </StyledButton>

      <StyledList
        ref={listRef}
        options={options}
        value={currentValue}
        onChange={onListChange}
        isOpen={isOpen}
      />
    </StyledContainer>
  );
});

export default Dropdown;
