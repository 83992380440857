import jwt_decode from 'jwt-decode';
import { isEmpty, omit, pick } from 'lodash';

import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { AppDispatchStrict } from '@float/common/store';
import {
  AccountType,
  getAccountTypeToLabelMap,
} from '@float/constants/accounts';
import { logger } from '@float/libs/logger';
import { Account, CurrentUser, RawUserPrefs } from '@float/types/account';
import { AccountCompany, AccountLoginMethod } from '@float/types/auth';

import API3 from '../../api3';
import { updateUserTrackingData } from '../../lib/intercom';
import { setUserTimeFormatPref } from '../../lib/time';
import { getJWTAccessToken } from '../jwt';
import {
  CHANGE_ACCOUNT_OWNER,
  CHANGE_ACCOUNT_OWNER_FAILURE,
  CHANGE_ACCOUNT_OWNER_SUCCESS,
  FETCH_COMPANY_SSO_SUCCESS,
  FETCH_USER_SUCCESS,
  USER_PREFS_MULTI_UPDATE,
  V3_PREFS,
} from './constants';
import { saveUserPref, updateUserPrefLocally } from './currentUser';
import { sanitizeUserPrefs } from './currentUser.helpers';

export * from './currentUser';

export * from './constants';

export type CurrentUserAction =
  | ReturnType<typeof updateUserPrefLocally>
  | ReturnType<typeof fetchedUser>
  | ReturnType<typeof changedAccountOwner>
  | ReturnType<typeof failedChangingAccountOwner>
  | {
      type: typeof CHANGE_ACCOUNT_OWNER;
    }
  | { type: typeof USER_PREFS_MULTI_UPDATE; prefsObj: Record<string, unknown> };

export const updateMultiUserPrefs = (prefsObj: Record<string, unknown>) => {
  return async (
    dispatch: AppDispatchStrict,
    getState: () => ReduxStateStrict,
  ) => {
    dispatch({ type: USER_PREFS_MULTI_UPDATE, prefsObj });

    const v3Prefs = pick(prefsObj, V3_PREFS);
    const v1Prefs = omit(prefsObj, V3_PREFS);

    if (!isEmpty(v1Prefs)) {
      const keys = Object.keys(v1Prefs).join(', ');
      logger.warn(`Unexpected V1 prefs encountered [${keys}]`);
    }

    if (!getState().currentUser.shared_link_view) {
      await API3.saveUserPrefs(v3Prefs);
    }
    for (const key of Object.keys(v1Prefs)) {
      await saveUserPref(
        key as (typeof V3_PREFS)[number],
        v1Prefs[key] as unknown,
      );
    }
  };
};

const fetchedUser = (user: Partial<CurrentUser>) => ({
  type: FETCH_USER_SUCCESS,
  user,
});

const changedAccountOwner = (json: object) => ({
  type: CHANGE_ACCOUNT_OWNER_SUCCESS,
  payload: json,
});

const failedChangingAccountOwner = (error: Error) => ({
  type: CHANGE_ACCOUNT_OWNER_FAILURE,
  error,
});

export const changeAccountOwner =
  (id: string) => (dispatch: AppDispatchStrict) => {
    dispatch({
      type: CHANGE_ACCOUNT_OWNER,
    });
    const data = new FormData();
    data.append('Company[account_owner_id]', id);
    return API3.changeAccountOwner({ data })
      .then((json) => {
        dispatch(changedAccountOwner(json.user));
      })
      .catch((error) => {
        dispatch(failedChangingAccountOwner(error));
        // propagate error further
        throw new Error(`Failed changing account owner: ${error}.`);
      });
  };

export const fetchCompanySso =
  () => (dispatch: AppDispatchStrict, getState: () => ReduxStateStrict) => {
    if (getState().currentUser?.companySso?.account_sso_id) {
      return Promise.resolve();
    }
    return API3.fetchCompanySso().then((json) =>
      dispatch({
        type: FETCH_COMPANY_SSO_SUCCESS,
        payload: json,
      }),
    );
  };

export const updateCurrentUserInfo =
  (id: string, data: { name: string; email: string }) =>
  async (dispatch: AppDispatchStrict, getState: () => ReduxStateStrict) => {
    const res = await API3.updateAccount({ id, data });

    dispatch({
      type: FETCH_USER_SUCCESS,
      user: {
        name: data.name,
        email: data.email,
      },
    });

    return res;
  };

export const updateUserTimezone =
  (timezone: string) =>
  async (dispatch: AppDispatchStrict, getState: () => ReduxStateStrict) => {
    const accountId = getState().currentUser.admin_id;
    await API3.updateAccountV3({ id: accountId, data: { timezone } });
    dispatch({ type: FETCH_USER_SUCCESS, user: { timezone } });
  };

// In the shared-link scenario, `currentUser` gets initialized from `loadData`.
// Hence that data isn't available to the search worker store by default.
// This action is used to relay this information to the worker store.
// TODO: Revisit this after Float2 decoupling
// https://linear.app/float-com/issue/PI-333/[post-release]-check-initcurrentuser-and-remove-if-needed
export const initCurrentUser =
  () =>
  async (dispatch: AppDispatchStrict, getState: () => ReduxStateStrict) => {
    const user = getState().currentUser;
    dispatch(fetchedUser(user));
  };

export const fetchUser =
  () =>
  async (dispatch: AppDispatchStrict, getState: () => ReduxStateStrict) => {
    let [me, account, prefs] = await Promise.all([
      API3.fetchCurrentUser(),
      API3.getAccountV3({
        id: getState().currentUser.admin_id,
        query: {
          expand: 'sso_email,sso_type,accepted,people_id',
        },
      }),
      API3.getUserPrefs(),
    ]);

    const accessToken = await dispatch(getJWTAccessToken());

    let tokenData: { account?: { login_method?: AccountLoginMethod } } = {};

    try {
      tokenData = jwt_decode(accessToken);
    } catch (e) {
      // If request failed or token wasn't processed correctly, we should handle fail properly.
      // The "Check your internet connection" modal from request.js will show up in this case.
    }

    const user: Partial<CurrentUser> = {
      // From account call
      accountLoaded: true,
      accepted: Number(account.accepted) as 0 | 1,
      account_id: Number(account.account_id),
      account_type_id: Number(account.account_type),
      account_type_name:
        getAccountTypeToLabelMap()[account.account_type as AccountType],
      created: account.created,
      modified: account.modified,
      department_filter: account.department_filter,
      email: account.email,
      name: account.name,
      people_id: account.people_id ? Number(account.people_id) : null,
      sso_email: account.sso_email,
      sso_type: account.sso_type,
      avatar_file: account.avatar,
      // PP
      login_method: tokenData?.account?.login_method ?? null,

      // From me call
      access: me.access,
      account_tid: Number(me.account_tid),
      admin_id: me.admin_id,
      cid: me.cid,
      company_name: me.company_name,
      // PP
      ical: me.ical,
      uuid: me.uuid,
      timezone: undefined,

      // From prefs call
      prefs: sanitizeUserPrefs(prefs),
    };

    logger.identify({
      id: user.account_id as number,
      companyId: user.cid,
      companyName: user.company_name,
    });

    if (account.timezone) {
      user.timezone = account.timezone;
    }
    dispatch(fetchedUser(user));
    if (user?.prefs?.time_format_24h) {
      setUserTimeFormatPref(user?.prefs?.time_format_24h);
    }

    updateUserTrackingData('isGuest', !user.people_id);
  };

export const setUserFromAccountData =
  ({
    account,
    company,
    prefs,
  }: {
    account: Account;
    company: AccountCompany;
    prefs: RawUserPrefs;
  }) =>
  async (dispatch: AppDispatchStrict) => {
    const sanitizedPrefs = sanitizeUserPrefs(prefs);

    dispatch(
      fetchedUser({
        account,
        // From account call
        accountLoaded: true,
        accepted: 1,
        account_id: account.id,
        account_type_id: account.account_type_id,
        account_type_name: getAccountTypeToLabelMap()[account.account_type_id],
        department_filter: account.department_filter,
        email: account.email,
        name: account.name,
        people_id: account.people_id ? Number(account.people_id) : null,
        sso_email: account.sso_email,
        sso_type: account.sso_type,
        avatar_file: account.avatar,
        // PP
        login_method: account.login_method,

        // From me call
        access: account.access,
        account_tid: account.account_type_id,
        admin_id: account.id,
        cid: company.company_id,
        company_name: company.company_name,
        // PP
        plus_pack: company.plus_pack ? +company.plus_pack : company.plus_pack, // -2 (Trial Expired), -1 (Disabled), 0 (Not enabled), 1 (Enabled) , 2 (Trial)
        // Time off approvals
        timeoff_approvals: Boolean(company.company_prefs.timeoff_approvals),
        sso_required: company.sso_required,
        ical: null,
        uuid: company.uuid,

        domains: company.domains || [],

        time_tracking: company.time_tracking
          ? +company.time_tracking
          : company.time_tracking,

        // From prefs call
        prefs: sanitizedPrefs,
      } as Partial<CurrentUser>),
    );

    if (sanitizedPrefs.time_format_24h) {
      setUserTimeFormatPref(sanitizedPrefs.time_format_24h);
    }
  };
