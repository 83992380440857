import { Editor, Node, NodeEntry, Path, Text, Transforms } from 'slate';

import { hasLink } from './plugins/utils/hasLink';
import { toNodesFrom } from './plugins/utils/nodes';

// @test-export
export const isLinkableText = (
  node: Node | null,
  parentNode: NodeEntry | null,
) => {
  return Boolean(
    Text.isText(node) &&
      hasLink(node.text) &&
      parentNode &&
      'type' in parentNode[0] &&
      parentNode[0].type !== 'link',
  );
};

export const linkifyAndInsertFragments = (editor: Editor) => {
  const textNodes = Editor.nodes(editor, {
    at: [],
    match: (node: Node, path: Path) => {
      const parentNode =
        path.length > 0 ? Editor.node(editor, Path.parent(path)) : null;

      return isLinkableText(node, parentNode);
    },
  });

  for (const node of textNodes) {
    const firstNode = node[0];
    const text = 'text' in firstNode ? firstNode.text : '';
    const nodes = toNodesFrom(text);

    Transforms.select(editor, node[1]);
    Editor.insertFragment(editor, nodes);
  }
};
