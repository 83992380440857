import { ReduxState } from '@float/common/reducers/lib/types';
import { UseCellsAction } from '@float/common/serena/Data/useCells/types';

export function handleSetFetchedRanges(
  state: ReduxState,
  dispatch: (action: UseCellsAction) => void,
  lastFetchedRanges: number[],
) {
  const { fetchedRanges } = state.schedule;

  if (lastFetchedRanges !== fetchedRanges) {
    dispatch({ type: 'SET_FETCHED_RANGES', fetchedRanges });
  }

  return fetchedRanges;
}
