import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { ProjectStatus } from '@float/types/project';

import { ProjectFormData } from '../types';
import { useIsTemplate } from './useIsTemplate';

function isProjectStatus(value: unknown): value is ProjectStatus {
  return Object.values(ProjectStatus).includes(value as ProjectStatus);
}

/*
 * When a project is edited, `status` updates cascade to its phases (on BE).
 * This hook emulates the same cascade behavior in "project create" and
 * "template create/edit" flows while the form is being interacted with.
 */
export function useStatusChangeHandler() {
  const { setValue, getValues } = useFormContext<ProjectFormData>();

  const isTemplate = useIsTemplate();
  const isNewProject = !getValues('projectId');
  const enabled = isNewProject || isTemplate;

  const handleProjectStatusChange = useCallback(
    (value: unknown) => {
      if (!enabled || !isProjectStatus(value)) return;

      const phases = getValues('phases');
      phases.forEach((p, index) => {
        const phaseStatus = p.fullPhaseData?.phase?.status;
        if (phaseStatus === undefined) return;

        if (phaseStatus === value) return;

        setValue(`phases.${index}.fullPhaseData.phase.status`, value);
      });
    },
    [enabled, getValues, setValue],
  );

  return handleProjectStatusChange;
}
