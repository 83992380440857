import addWeeks from 'date-fns/addWeeks';

import {
  sortByDateAndName,
  sortByOffsetAndName,
} from '@float/common/lib/itemSort';
import { formatToFloatDate } from '@float/libs/dates';
import { PhaseWithTemplateFields, TemplatePhase } from '@float/types/phase';

function resetMilestoneIds(milestones: TemplatePhase['milestones']) {
  return milestones?.map((m) => ({
    ...m,
    template_milestone_id: m.milestone_id,
    milestone_id: '0',
  }));
}

function resetTaskMetaIds(taskMetas: TemplatePhase['task_metas']) {
  return taskMetas?.map((t) => ({ ...t, task_meta_id: 0 }));
}

export function getProjectPhasesFromTemplatePhases(
  phases: PhaseWithTemplateFields[] | undefined,
  includeOffsets: boolean,
): PhaseWithTemplateFields[] {
  if (!phases?.length) return [];

  const result: PhaseWithTemplateFields[] = [];
  for (const phase of phases) {
    if (!phase.phase_name) continue;

    const item: PhaseWithTemplateFields = {
      ...phase,
      active: true,
      phase_id: 0,
      start_date: formatToFloatDate(new Date()),
      end_date: formatToFloatDate(addWeeks(new Date(), 4)),
    };

    if (includeOffsets) {
      item.start_date = '';
      item.end_date = '';
      item.duration = phase.duration;
      item.parent_offset = phase.parent_offset;
      item.template_phase_id = `${phase.phase_id}`;
      item.milestones = resetMilestoneIds(phase.milestones);
      item.task_metas = resetTaskMetaIds(phase.task_metas);
    }

    result.push(item);
  }

  return includeOffsets
    ? sortByOffsetAndName(result, 'phase_name')
    : sortByDateAndName(result, 'start_date', 'phase_name');
}
