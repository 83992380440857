export enum AutoLogTimeFrequency {
  week = 'week',
  month = 'month',
}

export type AutoLogTimeSettings = {
  isActive: boolean; // Is Auto Log Time enabled?
  interval: number; // Integer – How many days after the end of the period is time auto-logged?
  frequency: AutoLogTimeFrequency; // At what frequency is time auto-logged?
};
