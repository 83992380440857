import React from 'react';
import { BillableVsNonBillable } from 'reports/components/SummaryBarParts';

import { getPercentage } from '@float/common/lib/budget';
import { LIGHT_PURPLE, PURPLE } from '@float/ui/deprecated/Chart';

import { ReportsTotalsContainer } from '../../styles';

function SummaryBar({ totals, mode, minWidth, timeTrackingEnabled }) {
  const loggedTotal = totals.logged.total;
  const loggedBillable = totals.logged.billable;
  const loggedBillablePerc = getPercentage(
    totals.logged.billable,
    totals.logged.total,
  );
  const loggedNonBillable = totals.logged.nonbillable;
  const loggedNonBillablePerc = getPercentage(
    totals.logged.nonbillable,
    totals.logged.total,
  );

  const scheduledTotal = totals.scheduled;
  const scheduledBillable = totals.billable;
  const scheduledBillablePerc = getPercentage(
    totals.billable,
    totals.scheduled,
  );
  const scheduledNonBillable = totals.nonbillable;
  const scheduledNonBillablePerc = getPercentage(
    totals.nonbillable,
    totals.scheduled,
  );
  const scheduledTentative =
    totals.tentative.billable + totals.tentative.nonbillable;
  const scheduledTentativePerc = getPercentage(
    totals.tentative.billable + totals.tentative.nonbillable,
    totals.scheduled,
  );

  return (
    <ReportsTotalsContainer style={{ minWidth }}>
      <BillableVsNonBillable
        title="Scheduled"
        total={scheduledTotal}
        billable={scheduledBillable}
        billablePerc={scheduledBillablePerc}
        nonBillable={scheduledNonBillable}
        nonBillablePerc={scheduledNonBillablePerc}
        tentative={scheduledTentative}
        tentativePerc={scheduledTentativePerc}
        bottomMargin={!scheduledTentative}
        noBorderRight={!timeTrackingEnabled}
      />
      {!!timeTrackingEnabled && (
        <BillableVsNonBillable
          title="Logged"
          colors={[PURPLE, LIGHT_PURPLE]}
          total={loggedTotal}
          billable={loggedBillable}
          billablePerc={loggedBillablePerc}
          nonBillable={loggedNonBillable}
          nonBillablePerc={loggedNonBillablePerc}
          bottomMargin
          noBorderRight
        />
      )}
    </ReportsTotalsContainer>
  );
}

export default SummaryBar;
