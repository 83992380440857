import { saveAs } from 'file-saver';
import { forEach, get, sortBy } from 'lodash';
import { getTaskStatusString } from 'reports/components/TabBarFilters/StatusFilter';
import { getTeamModeString } from 'reports/components/TabBarFilters/TeamMode';
import { parseTags } from 'reports/helpers/tags';

import { moment } from '@float/libs/moment';

import { breakdown } from '../table/timetracking';
import { getExportDepartmentName } from './table';

const COLUMNS = [
  ['Date', (d) => d.date],
  ['Client', (d) => d.client?.replace(/\r|\n/g, '') || ' '], // filter new lines
  ['Project', (d) => d.project],
  ['Phase', (d) => d.phase],
  ['Task', (d) => d.task || ' '],
  ['Logged fee', (d) => (d.feeCents ? d.feeCents / 100 : ' ')],
  ['Logged Billable hours', (d) => d.billableHours],
  ['Logged Non-billable hours', (d) => d.nonbillableHours],
  ['Notes', (d) => d.notes?.trim() || ' '],
];

function getSheetData(ctx, rawTableData) {
  let columns = [...COLUMNS];

  if (!ctx.singlePerson) {
    columns = [
      ['Person', (d, r) => get(ctx.people, `${r.personId}.name`, ' ')],
      ['Role', (d, r) => get(ctx.people, `${r.personId}.job_title`) || ' '],
      ['Department', (d, r) => getExportDepartmentName(ctx, r.personId)],
      [
        'Tags',
        (d, r) => parseTags(get(ctx.people, `${r.personId}.tags`)) || ' ',
      ],
      ...columns,
    ];
  }

  const data = breakdown(ctx, rawTableData);

  const headers = columns.map((c) => c[0]);
  const result = [headers];

  forEach(data.byPerson, (parent) => {
    sortBy(parent.children, 'date').forEach((d) => {
      const row = columns.map((c) => c[1](d, parent) || 0);
      result.push(row);
    });
  });

  const totalsRow = [
    'Total',
    ...Array(ctx.singlePerson ? 4 : 8).fill(''),
    data.totals.feeCents ? data.totals.feeCents / 100 : '',
    data.totals.billableHours,
    data.totals.nonbillableHours,
  ];

  result.push(totalsRow);

  result.push([], []);

  result.push(
    [
      'Date range',
      moment(ctx.settings.startDate).format('DD MMM YYYY'),
      moment(ctx.settings.endDate).format('DD MMM YYYY'),
    ],
    ['Tasks', getTaskStatusString(ctx.settings)],
    ['People', getTeamModeString(ctx.settings)],
  );

  if (ctx.searchFiltersString) {
    result.push([ctx.searchFiltersString.replace('Filters:', 'Filters')]);
  }

  return result;
}

export default async function exportTableCsv(ctx, rawTableData) {
  return import('xlsx').then((XLSX) => {
    const { singlePerson, settings } = ctx;
    const startDate = moment(settings.startDate).format('YYYYMMDD');
    const endDate = moment(settings.endDate).format('YYYYMMDD');

    const filename = `${
      singlePerson || 'People'
    }-LoggedTime-${startDate}-${endDate}.csv`;

    const sheetData = getSheetData(ctx, rawTableData);

    const ws = XLSX.utils.aoa_to_sheet(sheetData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Float');
    const wbout = XLSX.write(wb, { type: 'array', bookType: 'csv' });

    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), filename);
  });
}
