import React, { ReactNode, useCallback, useState } from 'react';
import { omit } from 'lodash';

import { Popover, PopoverProps } from './Popover';
import { TextTooltip, TextTooltipProps } from './TextTooltip';

type TooltipWithHintAndMenuProps = {
  hint: string;
  menu: (setShouldDisplayMenu: (value: boolean) => void) => ReactNode;
  menuProps?: PopoverProps;
  hintProps?: TextTooltipProps;
  onOpenChange?: (open: boolean) => void;
  onHide?: () => void;
  children: (
    open: boolean,
    showMenu: () => void,
    hideMenu: () => void,
    toggleMenu: () => void,
  ) => ReactNode;
} & Omit<PopoverProps, 'children' | 'content'>;

/**
 * @deprecated Use the DropdownMenu component instead
 */
export const TooltipWithHintAndMenu = (props: TooltipWithHintAndMenuProps) => {
  const { hint, menu, onOpenChange, onHide, ...tippyProps } = props;
  const [shouldDisplayMenu, setShouldDisplayMenu] = useState(false);

  const showMenu = useCallback(() => {
    if (!shouldDisplayMenu) {
      setShouldDisplayMenu(true);
      onOpenChange?.(true);
      onHide?.();
    }
  }, [onOpenChange, onHide, shouldDisplayMenu]);

  const hideMenu = useCallback(() => {
    setShouldDisplayMenu(false);
    onOpenChange?.(false);
  }, [onOpenChange]);

  const toggleMenu = useCallback(() => {
    setShouldDisplayMenu(!shouldDisplayMenu);
    onOpenChange?.(!shouldDisplayMenu);
  }, [shouldDisplayMenu, onOpenChange]);

  const additionalProps = omit(tippyProps, ['menuProps', 'hintProps']);
  if (shouldDisplayMenu) {
    return (
      <Popover
        key="menu"
        {...additionalProps}
        open={shouldDisplayMenu}
        content={menu(setShouldDisplayMenu)}
        className="menu overflow"
        onOpenChange={(open) => {
          if (!open) {
            hideMenu();
          }
        }}
        onContentClick={hideMenu}
        {...tippyProps.menuProps}
      >
        {props.children(true, showMenu, hideMenu, toggleMenu)}
      </Popover>
    );
  }

  return (
    <TextTooltip
      key="hint"
      {...additionalProps}
      className="hint"
      content={hint}
      {...tippyProps.hintProps}
    >
      {props.children(false, showMenu, hideMenu, toggleMenu)}
    </TextTooltip>
  );
};
