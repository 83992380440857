export function uploadAws(url, file) {
  return new Promise((resolve, reject) => {
    const awsReq = new XMLHttpRequest();
    awsReq.open('PUT', url, true);
    awsReq.setRequestHeader('Content-Type', file.type);
    awsReq.onload = () => {
      if (awsReq.status === 200) {
        resolve();
      }
    };
    awsReq.onerror = (err) => {
      reject(err);
    };
    awsReq.send(file);
  });
}
