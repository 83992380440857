import React from 'react';

import { balanceOptions } from './modals/TimeoffTypeModal';

const BalanceTableCell = ({ cell }) => {
  const balance = balanceOptions.find((item) => item.value == cell);

  return <td>{balance && balance.label}</td>;
};

export default BalanceTableCell;
