import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { GroupedOptions } from '@float/types/options';
import { ProjectTeamMemberData } from '@float/types/project';
import { UseFieldArrayProvider } from '@float/web/components/panelViews/Project/hooks/useFieldArrayContext';

import { TeamMemberField } from './TeamMemberField';
import { useAddFormSubmit } from './TeamMemberQuickAddForm.hooks';

export const TeamMemberQuickAddForm = ({
  projectId,
  peopleOptionsByDept,
}: {
  projectId: number;
  peopleOptionsByDept: GroupedOptions<number>[];
}) => {
  const methods = useForm<{ team: ProjectTeamMemberData[] }>({
    defaultValues: { team: [] },
    values: { team: [] },
    resetOptions: {
      keepDirtyValues: false,
    },
    mode: 'onSubmit',
    shouldFocusError: true,
  });

  const submitHandler = useAddFormSubmit({
    onSubmit: methods.reset,
    projectId,
  });
  const handleSubmit = methods.handleSubmit(submitHandler);

  return (
    <FormProvider {...methods}>
      <UseFieldArrayProvider name="team">
        <form>
          <TeamMemberField
            onChange={handleSubmit}
            groupedOptions={peopleOptionsByDept}
          />
        </form>
      </UseFieldArrayProvider>
    </FormProvider>
  );
};
