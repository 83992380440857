import React from 'react';

import IntegrationCalendar from './IntegrationCalendar';
import IntegrationIcal from './IntegrationIcal';

export default function Integrations(props) {
  return (
    <>
      <IntegrationCalendar closeParentModal={props.closeParentModal} />
      <IntegrationIcal ical={props.currentUser.ical} />
    </>
  );
}
