import React from 'react';

export default function () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="17"
      viewBox="0 0 20 17"
    >
      <g fill="#868D92" fillRule="nonzero" transform="translate(-43 -5)">
        <path d="M45 6v13h16v-2H47V6z" />
        <path d="M58 9l-3 4-3.006-2.025L49 15h12v-3z" />
      </g>
    </svg>
  );
}
