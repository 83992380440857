import { isArray, isNumber } from 'lodash';

import { toNum } from '@float/common/lib/budget';

export const parseRow = ({ row, keyMap, refineValue }) => {
  const data = {};
  Object.keys(row).forEach((header) => {
    const key = keyMap[header];
    if (key) {
      data[key] = refineValue(row[header], key);
    }
  });
  return data;
};

export const mapImportHeaderToKeys = ({ headers, getKeyFromHeader }) => {
  const headerToKey = {};
  headers.forEach((header) => {
    const key = getKeyFromHeader(header);
    if (key) {
      headerToKey[header] = key;
    }
  });
  return headerToKey;
};

export const isNdigitOrLess = (value, length) =>
  isNumber(value) && `${Math.floor(value)}`.length <= length;

export const areStringsSame = (a, b, fallback = '') => {
  const lowerA = (a || fallback).toLowerCase().trim();
  const lowerB = (b || fallback).toLowerCase().trim();
  return lowerA === lowerB;
};

const getOptionsArray = (options) => (isArray(options) ? options : [options]);

export const startsWithAnyOf = (value, options) =>
  getOptionsArray(options).some((x) => value.startsWith(x));

export const containsAnyOf = (value, options) =>
  getOptionsArray(options).some((x) => value.indexOf(x) > -1);

export const equalsAnyOf = (value, options) =>
  getOptionsArray(options).some((x) => value === x);

export function refineNumericalValue(value) {
  const num = toNum(value);
  if (isNaN(num)) {
    // Incorrect inputs (e.g. alphanumeric) should trigger a validation error,
    //  hence returning `value` below. But empty strings should be interpreted
    //  as null without raising a validation error. Hence, returning null last.
    return value || null;
  }
  return num;
}
