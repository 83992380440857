const prefix = 'asana_integration';

export const FETCH_ASANA_OAUTH_LINK = `${prefix}/FETCH_OAUTH_LINK`;
export const FETCH_ASANA_OAUTH_LINK_SUCCESS = `${prefix}/FETCH_OAUTH_LINK_SUCCESS`;
export const FETCH_ASANA_OAUTH_LINK_FAILURE = `${prefix}/FETCH_OAUTH_LINK_FAILURE`;

export const FETCH_ASANA_CO_INTS = `${prefix}/FETCH_CO_INTS`;
export const FETCH_ASANA_CO_INTS_SUCCESS = `${prefix}/FETCH_CO_INTS_SUCCESS`;
export const FETCH_ASANA_CO_INTS_FAILURE = `${prefix}/FETCH_CO_INTS_FAILURE`;

export const ASANA_IMPORT_SUCCESS = `${prefix}/IMPORT_SUCCESS`;

export const FETCH_ASANA_PRELOAD = `${prefix}/FETCH_PRELOAD`;
export const FETCH_ASANA_PRELOAD_SUCCESS = `${prefix}/FETCH_PRELOAD_SUCCESS`;
export const FETCH_ASANA_PRELOAD_FAILURE = `${prefix}/FETCH_PRELOAD_FAILURE`;
export const CLEAR_ASANA_PRELOAD = `${prefix}/CLEAR_PRELOAD`;

export const FETCH_ASANA_CONFIG = `${prefix}/FETCH_CONFIG`;
export const FETCH_ASANA_CONFIG_SUCCESS = `${prefix}/FETCH_CONFIG_SUCCESS`;
export const FETCH_ASANA_CONFIG_FAILURE = `${prefix}/FETCH_CONFIG_FAILURE`;

export const UPDATE_ASANA_CONFIG = `${prefix}/UPDATE_CONFIG`;
export const UPDATE_ASANA_CONFIG_SUCCESS = `${prefix}/UPDATE_CONFIG_SUCCESS`;
export const UPDATE_ASANA_CONFIG_FAILURE = `${prefix}/UPDATE_CONFIG_FAILURE`;

export const REMOVE_ASANA_CO_INT = `${prefix}/REMOVE_CO_INT`;
export const REMOVE_ASANA_CO_INT_SUCCESS = `${prefix}/REMOVE_CO_INT_SUCCESS`;
export const REMOVE_ASANA_CO_INT_FAILURE = `${prefix}/REMOVE_CO_INT_FAILURE`;

export const RETRY_ASANA_PEOPLE_SYNC = `${prefix}/RETRY_PEOPLE_SYNC`;
export const RETRY_ASANA_PEOPLE_SYNC_SUCCESS = `${prefix}/RETRY_PEOPLE_SYNC_SUCCESS`;
export const RETRY_ASANA_PEOPLE_SYNC_FAILURE = `${prefix}/RETRY_PEOPLE_SYNC_FAILURE`;
