import { forEach, keyBy } from 'lodash';
import { createSelector } from 'reselect';

import { getDatesFromRange } from '@float/libs/dates';
import { moment } from '@float/libs/moment';
import { CustomHoliday } from '@float/types/customHoliday';
import { Milestone } from '@float/types/milestone';
import { Timeoff } from '@float/types/timeoff';

import { ReduxStateStrict } from '../reducers/lib/types';
import { getSearchFilteredProjects } from '../search/selectors/projects';
import { getMilestones } from './milestones';
import { getPhasesMapRaw } from './phases';
import { getFullTimeoffsList } from './timeoffs';

const formatDate = (date: string | Date | Moment | null) =>
  moment(date).format('YYYY-MM-DD');

type Higlight =
  | Timeoff
  | CustomHoliday
  | (Milestone & {
      color: string;
      project_name: string;
    });

const addHighlight = (
  dateKey: string,
  map: Record<string, Higlight[]>,
  item: Higlight,
) => {
  if (!map[dateKey]) {
    map[dateKey] = [];
  }

  map[dateKey].push(item);
};

const getCustomHolidays = (state: ReduxStateStrict) => state.holidays.holidays;
const getRegionalHolidays = createSelector([getFullTimeoffsList], (timeoffs) =>
  timeoffs.filter((timeoff) => Boolean(timeoff.region_holiday_id)),
);

export const getTeamCapacityHighlights = createSelector(
  [
    getCustomHolidays,
    getRegionalHolidays,
    getMilestones,
    getSearchFilteredProjects,
    getPhasesMapRaw,
  ],
  (customHolidays, regionalHolidays, milestones, projects, phasesMap) => {
    const returnData: Record<string, Higlight[]> = {};

    forEach(milestones, (milestone) => {
      const project = projects.find(
        (x) => x.project_id == milestone.project_id,
      );
      if (!project) {
        return;
      }
      const { project_name, color, active } = project;
      if (!active) {
        return;
      }
      const data = {
        ...milestone,
        project_name,
        color,
      };

      if (milestone.phase_id && phasesMap?.[milestone.phase_id]) {
        data.color = phasesMap[milestone.phase_id].color;
      }

      getDatesFromRange(
        moment(milestone.date),
        moment(milestone.end_date),
        (date) => {
          addHighlight(formatDate(date), returnData, data);
        },
      );
    });

    forEach(customHolidays, (holiday) => {
      getDatesFromRange(
        moment(holiday.date),
        moment(holiday.end_date),
        (date) => {
          addHighlight(formatDate(date), returnData, holiday);
        },
      );
    });

    forEach(regionalHolidays, (timeoff) => {
      if (!timeoff.region_holiday_id) {
        return;
      }
      getDatesFromRange(
        moment(timeoff.start_date),
        moment(timeoff.end_date),
        (date) => {
          addHighlight(formatDate(date), returnData, timeoff);
        },
      );
    });
    return returnData;
  },
);

export const getHolidaysRawList = createSelector(
  [(state: ReduxStateStrict) => state.holidays.holidays],
  (holidays) => Object.values(holidays),
);

export const getHolidays = createSelector([getHolidaysRawList], (holidays) =>
  holidays.map((holiday) => {
    const date = moment(holiday.date);
    return {
      ...holiday,
      date,
      end_date: moment(holiday.end_date),
      formatted: date.format('YYYY-MM-DD'),
    };
  }),
);

export const getHolidaysMap = createSelector([getHolidays], (holidays) =>
  keyBy(holidays, 'formatted'),
);
