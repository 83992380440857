import React from 'react';

export default function () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="#868D92"
        fillRule="nonzero"
        d="M21.635 10.357l-2.57-.42a7.362 7.362 0 0 0-.613-1.48l1.5-2.138a.435.435 0 0 0-.049-.558l-1.69-1.69a.435.435 0 0 0-.562-.045L15.545 5.54a7.293 7.293 0 0 0-1.492-.62l-.448-2.56a.435.435 0 0 0-.428-.36h-2.392a.435.435 0 0 0-.429.365L9.94 4.91a7.325 7.325 0 0 0-1.494.614l-2.1-1.5a.435.435 0 0 0-.56.046l-1.69 1.69a.434.434 0 0 0-.049.557L5.525 8.43c-.263.476-.473.98-.625 1.504l-2.536.422a.435.435 0 0 0-.363.43v2.39c0 .211.151.392.359.429l2.535.45c.152.522.361 1.026.625 1.504l-1.496 2.092a.436.436 0 0 0 .046.56l1.69 1.692c.15.15.385.17.557.049l2.118-1.483c.475.261.977.469 1.498.62l.424 2.547c.034.21.216.363.428.363h2.392c.21 0 .39-.151.427-.359l.455-2.56a7.311 7.311 0 0 0 1.491-.624l2.133 1.496c.173.122.407.1.557-.049l1.69-1.691c.15-.151.17-.39.046-.562l-1.521-2.112c.258-.47.462-.966.612-1.482l2.573-.451a.435.435 0 0 0 .36-.429v-2.39a.434.434 0 0 0-.365-.43zM12 15.043a3.043 3.043 0 1 1 0-6.086 3.043 3.043 0 0 1 0 6.086z"
      />
    </svg>
  );
}
