import React, { useState } from 'react';

import { CurrentUser } from '@float/types/account';
import {
  Button,
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '@float/ui/deprecated';
import { onChatClick } from 'components/Nav/helpers';

import {
  UpgradeToEnterpriseIcon,
  UpgradeToProIcon,
} from '../common/PlanChangeIcons';
import { getUpgradePrice } from '../TeamAccountFeaturesNew/helpers';
import { StyledErrorMesasge, StyledModalBody } from './_styles';

export type UpgradeModalProps = {
  user: CurrentUser;
  onSubmit: (formData?: FormData) => Promise<void>;
  close: () => void;
  isEnterpriseUpgrade?: boolean;
  upgradeFrom: string;
  isMonthly?: boolean;
  isPaidTrial?: boolean;
};

const UpgradeErrorMessage = () => (
  <StyledErrorMesasge>
    Sorry, your payment was declined. Please update your card details or contact
    your bank for more information.
  </StyledErrorMesasge>
);

const UpgradeModal = ({
  onSubmit,
  close,
  isEnterpriseUpgrade,
  upgradeFrom,
  isMonthly,
  isPaidTrial,
}: UpgradeModalProps) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const planName = isEnterpriseUpgrade ? 'Enterprise' : 'Pro';
  const UpgradeIcon = isEnterpriseUpgrade
    ? UpgradeToEnterpriseIcon
    : UpgradeToProIcon;
  const advancedFetaureCTA = isEnterpriseUpgrade
    ? 'security features'
    : 'account management controls';
  const upgradePricePerMonth = getUpgradePrice({
    upgradeFrom,
    isMonthly,
    isEnterpriseUpgrade,
  });

  async function submit() {
    setLoading(true);
    try {
      await onSubmit();
      setLoading(false);
      close();
    } catch {
      setLoading(false);
      setHasError(true);
    }
  }

  return (
    <Modal isOpen={true} onEnter={submit} onClose={close}>
      <ModalHeader>
        <UpgradeIcon />
        <ModalTitle style={{ fontSize: 22 }}>Upgrade to {planName}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <StyledModalBody>
          <p>
            Get immediate access to more advanced {advancedFetaureCTA} for your
            team.
          </p>
          <p>
            {isPaidTrial
              ? `After 30 days your account will be charged an additional `
              : `Your account will be charged an additional `}
            {upgradePricePerMonth} per person per month. We'll create a prorated
            transaction for any price changes to cover the remainder of this
            billing cycle.
          </p>
          <p>Note: prices exclude taxes and discounts.</p>
          {hasError && <UpgradeErrorMessage />}
        </StyledModalBody>
      </ModalBody>
      <ModalActions>
        <Button loader={loading} onClick={submit}>
          Upgrade
        </Button>
        <Button appearance="secondary" onClick={close}>
          Cancel
        </Button>
        <Button
          appearance="clear"
          style={{ marginLeft: 'auto', marginRight: 0 }}
          onClick={onChatClick}
        >
          Message support
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default UpgradeModal;
