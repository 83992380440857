/*

- modalManagerHoc -
Wrap a component to make it a modal.
Also injects props of `manageModal` and `managedModals`.
Accepts an object { Comp: SomeComp, modalType: 'somemodalType'}.
Make sure to add that 'somemodalType' to modalManagerReducers.js.
Returns a function - that function is passed a component.

- modalManagerConnect -
Only injects props of `manageModal` and `managedModals`.

- props.manageModal -
Hide, show, and pass data for modals.

- props.managedModals -
Object containing various modals states

----- How to make a component a modal -----

-- YOUR MODAL COMPONENT FILE --
import modalManagerHoc from 'modalManager/modalManagerHoc';

class MyCoolModalComp extends Component{
  close() {
    this.manageModal({
      visible: false,
      modalType: 'myCoolModal',
    });
  }
  render() {
    return (
      <div>
        <h2>{this.props.someParam}</h2>
        <h3>{this.props.managedModal.modalSettings.someOtherParam || 'whatever'}</h3>
      </div>
    );
  }
}

export default modalManagerHoc({ Comp: MyCoolModalComp, modalType: 'myCoolModal'})


-- Add you modal to some JSX --
import MyCoolModal from 'MyCooolModal';

<Container>
  <MyCoolModal someParam="foo" />
  <MyCoolModal someParam="bar" />
</Container>


-- Open or close your modal anywhere --

class MyControls extends Component{
  constructor(){
    super();
    this.openFoo = this.openFoo.bind(this);
    this.openBar = this.openBar.bind(this);
    this.closeModals = this.closeModals.bind(this);
  }
  openFoo(){
    this.props.manageModal({
      visible: true,
      modalType: 'myCoolModal',
      modalSettings: { someOtherParam: 'Okay' }
    });
  }
  openBar(){
    this.props.manageModal({
      visible: true,
      modalType: 'myCoolModal',
    });
  }
  closeModal() {
    this.props.manageModal({
      visible: false,
      modalType: 'myCoolModal',
    });
    this.props.manageModal({
      visible: false,
      modalType: 'myCoolModal',
    });
  }
  render() {
    return (
      <div>
        <div onClick={this.openFoo}>
          foo is currently
          {this.props.managedModals.showing ? 'opened' : 'closed'}
          -- open foo
        </div>
        <div onClick={this.openBar}>
          bar is currently
          {this.props.managedModals.bar.showing ? 'opened' : 'closed'}
          -- open bar
        </div>
        <div onClick={this.closeModal}>close both modals</div>
      </div>
    );
  }
}

export default modalManagerConnect(MyControls);

*/

import React, { Component } from 'react';
import { connect } from 'react-redux';

import manageModal, { updateModal } from './manageModalActionCreator';

const mapStateToProps = ({ modalManager, sidePanel }) => {
  return { managedModals: modalManager, sidePanelOpen: sidePanel.open };
};

const mapDispatchToProps = {
  manageModal,
  updateModal,
  showModal: (modalType, modalSettings = {}) => {
    return manageModal({
      visible: true,
      modalType,
      modalSettings,
    });
  },
};

export const modalManagerConnect = connect(mapStateToProps, mapDispatchToProps);

const modalManagerHoc = ({ Comp, modalType }) => {
  class Wrapped extends Component {
    render() {
      const { showing, modalSettings, skipSidePanelAutoClose } =
        this.props.managedModals[modalType];

      const isVisible =
        (!this.props.sidePanelOpen || skipSidePanelAutoClose) && showing;

      if (!isVisible) return null;

      return <Comp {...this.props} modalSettings={modalSettings} />;
    }
  }
  return modalManagerConnect(Wrapped);
};
export default modalManagerHoc;
