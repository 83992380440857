import { fetchExtCalendars } from 'integrations/actions/calendar';

import { capitalize } from '@float/libs/utils/string/capitalize';

const snackbarConfig = {
  persist: true,
  showClose: true,
  linkText: 'Review settings',
};

function getErrorMessage(type) {
  const typeString = type === 'office365' ? 'Outlook' : capitalize(type);
  return `We've detected a problem with your ${typeString} connection.`;
}

const handleIntegrationSyncError =
  ({ type, result, showSnackbar, manageModal }) =>
  (dispatch, getState) => {
    const { scope, error } = result;
    const message = error || getErrorMessage(type);
    if (scope === 'user') {
      let modalType;
      if (type === 'slack') {
        modalType = 'notificationSettingsModal';
      } else {
        dispatch(fetchExtCalendars());
        modalType = 'calendarIntegrationsModal';
      }
      showSnackbar(message, {
        ...snackbarConfig,
        onLinkClick: () => manageModal({ visible: true, modalType }),
      });
      return;
    }

    const { currentUser } = getState();
    const isAccountOwner = currentUser.account_tid == 1;
    if (!isAccountOwner) {
      return;
    }

    dispatch({
      type: `${type.toLowerCase()}_integration/FETCH_CO_INTS_SUCCESS`,
      coInts: [result.coInt],
    });

    showSnackbar(message, {
      ...snackbarConfig,
      linkTo: '/admin/api',
      id: `${scope}:${type}:${message}`,
    });
  };

export default handleIntegrationSyncError;
