import {
  LiveUpdate,
  LiveUpdateWithSource,
  TimerLiveUpdate,
  ViewLiveUpdate,
} from '@float/types';

export function isLiveUpdateWithSource(
  update: LiveUpdate,
): update is LiveUpdateWithSource {
  return Boolean((update as TimerLiveUpdate).source);
}

export function isViewLiveUpdate(update: LiveUpdate): update is ViewLiveUpdate {
  return (
    update.hasOwnProperty('notification') &&
    (update as ViewLiveUpdate).notification?.type === 'view'
  );
}

export const isTimerLiveUpdate = (
  update: LiveUpdate,
): update is TimerLiveUpdate =>
  isLiveUpdateWithSource(update) && update.source === 'timer-event-publisher';
