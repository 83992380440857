import { MePageData, PageData, SharePageData } from '@float/types/pageData';

import { Resources } from '../constants';
import { makeRequest, MakeRequestPagination } from '../makeRequest';

export const requestPageData = (gclid: string = ''): Promise<PageData> =>
  makeRequest<PageData>({
    resource: Resources.PageData,
    method: 'GET',
    pagination: MakeRequestPagination.Off,
    query: {
      gclid,
    },
  });

export const requestMePageData = (): Promise<MePageData> =>
  makeRequest<MePageData>({
    resource: Resources.MePageData,
    method: 'GET',
    pagination: MakeRequestPagination.Off,
  });

export const requestSharePageData = (token: string): Promise<SharePageData> => {
  return makeRequest<SharePageData, { token: string }>({
    resource: Resources.SharePageData,
    method: 'POST',
    pagination: MakeRequestPagination.Off,
    data: {
      token,
    },
  });
};
