import React from 'react';

export default ({ color = 'black', size = 20, style, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      style={style}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.79183 13.75H15.2085V12.5H4.79183V13.75ZM15.4168 13.9583V15.2083H16.6668V13.9583H15.4168ZM15.2085 15.4167H4.79183V16.6667H15.2085V15.4167ZM4.5835 15.2083V13.9583H3.3335V15.2083H4.5835ZM4.79183 15.4167C4.67677 15.4167 4.5835 15.3234 4.5835 15.2083H3.3335C3.3335 16.0137 3.98641 16.6667 4.79183 16.6667V15.4167ZM15.4168 15.2083C15.4168 15.3234 15.3236 15.4167 15.2085 15.4167V16.6667C16.0139 16.6667 16.6668 16.0137 16.6668 15.2083H15.4168ZM15.2085 13.75C15.3236 13.75 15.4168 13.8432 15.4168 13.9583H16.6668C16.6668 13.1529 16.0139 12.5 15.2085 12.5V13.75ZM4.79183 12.5C3.98641 12.5 3.3335 13.1529 3.3335 13.9583H4.5835C4.5835 13.8432 4.67677 13.75 4.79183 13.75V12.5Z"
        fill={color}
      />
      <path
        d="M14.5832 12.7083C14.5832 13.0535 14.863 13.3333 15.2082 13.3333C15.5533 13.3333 15.8332 13.0535 15.8332 12.7083H14.5832ZM4.1665 12.7083C4.1665 13.0535 4.44633 13.3333 4.7915 13.3333C5.13668 13.3333 5.4165 13.0535 5.4165 12.7083H4.1665ZM14.5832 10.8333V12.7083H15.8332V10.8333H14.5832ZM5.4165 12.7083V10.8333H4.1665V12.7083H5.4165ZM9.99984 6.25C12.5312 6.25 14.5832 8.30203 14.5832 10.8333H15.8332C15.8332 7.61168 13.2215 5 9.99984 5V6.25ZM9.99984 5C6.77818 5 4.1665 7.61168 4.1665 10.8333H5.4165C5.4165 8.30203 7.46853 6.25 9.99984 6.25V5Z"
        fill={color}
      />
      <path
        d="M9.99984 4.16659V4.99992C10.4601 4.99992 10.8332 4.62682 10.8332 4.16659H9.99984ZM9.99984 4.16659H9.1665C9.1665 4.62682 9.53959 4.99992 9.99984 4.99992V4.16659ZM9.99984 4.16659V3.33325C9.53959 3.33325 9.1665 3.70635 9.1665 4.16659H9.99984ZM9.99984 4.16659H10.8332C10.8332 3.70635 10.4601 3.33325 9.99984 3.33325V4.16659Z"
        fill={color}
      />
    </svg>
  );
};
