import isNetworkError from 'is-network-error';
import { LogArgument } from 'rollbar';

const ignoreIfNetworkError = (args: LogArgument[]) => {
  for (const arg of args) {
    if (isNetworkError(arg)) {
      return true;
    }
  }

  return false;
};

const ignoreIfNotSupportedBrowser = (supportedBrowsers?: RegExp) => {
  if (supportedBrowsers && !supportedBrowsers.test(navigator.userAgent)) {
    return true;
  }

  return false;
};

export const getCheckIgnoreHandler =
  (supportedBrowsers?: RegExp) => (_: boolean, args: LogArgument[]) =>
    ignoreIfNetworkError(args) ||
    ignoreIfNotSupportedBrowser(supportedBrowsers);
