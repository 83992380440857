import isNumber from 'lodash/isNumber';

import { moment } from '@float/libs/moment';
import { RawTask } from '@float/types/task';

import { NOTES_EXCERPT_LENGTH } from '../../../lib/notes';

const DATE_FORMAT = 'YYYY-MM-DD';

const formatDate = (date: string) => moment(date).format(DATE_FORMAT);

export const formatTaskForRequest = (task: RawTask) => {
  const name = task.name || '';
  const hours = parseFloat(`${task.hours}` || `${task.hours_pd}`) || 8;

  if (task.people_id) {
    console.error('Task in error:', task);
    throw new Error('Expected people_ids, not people_id');
  }

  // http://dev.float.com/api_reference.html#!/Tasks/updateTask
  const res: RawTask = {
    task_id: task.task_id,
    project_id: task.project_id,
    phase_id: task.phase_id,
    start_date: formatDate(task.start_date),
    end_date: formatDate(task.end_date),
    start_time: task.start_time || null,
    hours,
    people_ids: task.people_ids,
    status: task.status,
    priority_info: task.priority_info || {},
    repeat_state: task.repeat_state,
    repeat_end_date: task.repeat_end_date
      ? formatDate(task.repeat_end_date)
      : null,
    integration_status: task.integration_status,
    root_task_id: task.root_task_id,
    parent_task_id: task.parent_task_id,
    allocation_type: task.allocation_type,
  } as RawTask;

  if (isNumber(task.billable)) {
    res.billable = task.billable;
  }

  if (task.task_meta_id) {
    res.task_meta_id = task.task_meta_id;
  } else {
    res.name = name;
  }

  if (res.repeat_end_date && res.repeat_end_date <= res.end_date) {
    res.repeat_state = 0;
    delete res.repeat_end_date;
  }

  Object.keys(res.priority_info).forEach((personId) => {
    if (!res.people_ids?.some((id) => id == Number(personId))) {
      delete res.priority_info[Number(personId)];
    }
  });

  if (task.actionMode) {
    // e.g. split - in which case, side-effects like project
    // dates / budget usage recalculation shouldn't run on BE
    res.actionMode = task.actionMode;
  }

  const notes = task.notes || '';
  const notes_meta = task.notes_meta || null;
  // TODO: Hack. Not the best way to deduce whether or not
  // to include notes in the PUT payload. Clean this up.
  const isNotesExcerpt = notes.length === NOTES_EXCERPT_LENGTH;
  if (!isNotesExcerpt) {
    res.notes = notes;
    res.notes_meta = notes_meta;
  }

  return res;
};
