import { Dispatch } from 'react';

import API3 from '@float/common/api3';
import { logger } from '@float/libs/logger';
import { Timer } from '@float/types';

import { LOGGED_TIME_UPDATED } from '../loggedTimes';
import { TIMER_UPDATE } from './constants';
import { LoggedTimeUpdatedAction, TimerUpdateAction } from './types';

export const updateTimer = (
  timer: Pick<Timer, '_id' | 'start_timestamp' | 'end_timestamp'>,
) => {
  return async (
    dispatch: Dispatch<TimerUpdateAction | LoggedTimeUpdatedAction>,
  ) => {
    try {
      const loggedTime = await API3.updateTimer(timer);
      const { timer: loggedTimeTimer } = loggedTime;

      dispatch({ type: LOGGED_TIME_UPDATED, loggedTime });
      dispatch({
        type: TIMER_UPDATE,
        timer: loggedTimeTimer,
      });
    } catch (e) {
      logger.error('There was an error while updating a timer', e);
    }
  };
};

export const updateTimerFromLiveUpdate = (timer: Timer) => {
  return async (dispatch: Dispatch<TimerUpdateAction>) => {
    dispatch({
      type: TIMER_UPDATE,
      timer: timer,
    });
  };
};
