export const readOnlyMapping = {
  'tasks/all': 'read-only/tasks',
  'tasks/': 'read-only/tasks?id=',
  tags: 'read-only/tags',
  'timeoffs/all': 'read-only/timeoffs',
  'projects/all': 'read-only/projects',
  'people/all': 'read-only/people',
  accounts: 'read-only/accounts',
  'accounts/all': 'read-only/accounts',
  'phases/all': 'read-only/phases',
  milestones: 'read-only/milestones',
  holidays: 'read-only/holidays',
  status: 'read-only/status',
  'oneoff-days': 'read-only/oneoff-days',
  'timeoff-types': 'read-only/timeoff-types',
  'linked-tasks': 'read-only/linked-tasks',
  roles: 'read-only/roles',
};
