import { keyBy, map } from 'lodash';
import { createSelector } from 'reselect';

import { DEFAULT_STATUS_COLOR } from '@float/constants/colors';

import { ReduxStateStrict } from '../reducers/lib/types';

export const getStatusTypes = (state: ReduxStateStrict) =>
  state.statusTypes.statusTypes;

export const getStatuses = createSelector(
  [(state: ReduxStateStrict) => state.statuses.statuses, getStatusTypes],
  (statuses, statusTypes) => {
    const newStatuses = map(statuses, (status) => {
      const statusType = statusTypes[status.status_type_id] || {};
      return {
        ...status,
        status_type_name: statusType.status_type_name,
        color: statusType.color || DEFAULT_STATUS_COLOR,
      };
    });
    return keyBy(newStatuses, 'status_id');
  },
);
