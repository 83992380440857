import styled from 'styled-components';

import EH from '@float/ui/deprecated/Earhart';

export const StyledRow = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : 'column')};

  color: ${EH.Colors.FIN.Lt.Emphasis.High};

  & + & {
    margin-top: 20px;
  }

  h4 {
    ${EH.Typography.TextXXL.SB600};
  }
`;

export const StyledColumn = styled.div`
  flex: 0.5;

  display: flex;
  flex-direction: column;

  & + & {
    margin-left: 24px;
  }
`;

export const StyledColumnContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  margin-top: 14px;
`;
