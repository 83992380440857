import React from 'react';

import { capitalize } from '@float/libs/utils/string/capitalize';

export default ({ person, projects, isPhase, ...rest }) => {
  const type = isPhase ? 'phase' : 'project';
  const these = projects.length === 1 ? `this ${type}` : `these ${type}s`;
  const title = capitalize(`${type}${projects.length === 1 ? '' : 's'}`);

  return {
    title: `Remove from ${title}?`,
    message: (
      <>
        <p>
          <span>You're about to remove </span>
          <strong>{person.name}</strong>
          <span> from {these}.</span>
        </p>
        {projects.length > 1 && (
          <ul style={{ margin: '1em 0' }}>
            {projects.map((pr) => (
              <li key={pr.project_id}>
                <b>{pr.project_name}</b>
              </li>
            ))}
          </ul>
        )}
        <p>
          All scheduled and logged tasks allocated to this person for {these}{' '}
          will be deleted.
        </p>
      </>
    ),
    ...rest,
  };
};
