import { isNot, isOr } from '@float/common/search/helpers';
import { FilterToken } from '@float/types';

// A fast and simple hash function to limit the string size
function hashLongStrings(value: string) {
  if (value.length <= 12) return value;

  let hash = 0;

  for (let i = 0; i < value.length; i++) {
    const char = value.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32bit integer
  }

  return new Uint32Array([hash])[0].toString(36).slice(0, 8);
}

function coerseToArray<T>(value: T | T[]) {
  return Array.isArray(value) ? value : [value];
}

function usesOrBetweenDifferentTypes(filters: FilterToken[]) {
  return filters.some(
    (filter, i) =>
      isOr(filter.operator) && filters[i - 1]?.type !== filter.type,
  );
}

type FilterData = {
  type: FilterToken['type'];
  value: string;
  isInlineOr: boolean;
  isOr: boolean;
  isNot: boolean;
};

function transformFiltersToAnalyticsProps(filters: FilterToken[], me: boolean) {
  const res: FilterData[] = [];

  for (const filter of filters) {
    const value = coerseToArray(filter.val).map(hashLongStrings).join(';');

    res.push({
      type: filter.type,
      value,
      isInlineOr: Array.isArray(filter.val) && filter.val.length > 1,
      isOr: isOr(filter.operator),
      isNot: isNot(filter.operator),
    });
  }

  if (me) {
    res.push({
      type: 'me',
      value: 'true',
      isInlineOr: false,
      isNot: false,
      isOr: false,
    });
  }

  return res;
}

export function getFiltersAnalyticsProps(filters: FilterToken[], me: boolean) {
  return {
    filters: transformFiltersToAnalyticsProps(filters, me),
    usesOrBetweenTypes: usesOrBetweenDifferentTypes(filters),
  };
}
