import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M5.75 10.75h12.5c.552 0 1-.488 1-1.09V5.84c0-.602-.448-1.09-1-1.09H5.75c-.552 0-1 .488-1 1.09v3.82c0 .602.448 1.09 1 1.09Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M5.75 19.25h12.5c.552 0 1-.488 1-1.09v-3.82c0-.602-.448-1.09-1-1.09H5.75c-.552 0-1 .488-1 1.09v3.82c0 .602.448 1.09 1 1.09Z"
    />
  </BaseIcon>
);
