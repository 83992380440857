import React, { useRef } from 'react';
import { connect } from 'react-redux';

import ImportModal from '@float/web/importCsv/ImportModal/ImportModal';

import { mapProjectHeaderToKeys, validateProjectRow } from './projectImport';

function ImportProjectsModal({
  processId,
  errors,
  duplicates,
  success,
  projects,
  accounts,
  onSave,
  onClose,
}) {
  const meta = useRef({});

  const beforeValidate = (row) => {
    meta.current = {
      keyMap: mapProjectHeaderToKeys(Object.keys(row)),
      projects: Object.values(projects),
      accounts: Object.values(accounts),
    };
  };

  const validateRow = (row) => {
    return validateProjectRow(row, meta.current);
  };

  return (
    <ImportModal
      type="project"
      processId={processId}
      errors={errors}
      duplicates={duplicates}
      success={success}
      validateRow={validateRow}
      beforeValidate={beforeValidate}
      onSave={onSave || onClose}
      onCancel={onClose}
    />
  );
}

const mapStateToProps = (state) => ({
  accounts: state.accounts.accounts,
});

export default connect(mapStateToProps)(ImportProjectsModal);
