import React, { memo, useMemo } from 'react';

import { ToggleGroup } from '@float/ui/deprecated';

type SearchFilterToggleIsNotProps = {
  value: string | undefined;
  showSelectedOnly?: boolean;
  onChange: (value: string) => void;
};

const operatorOptions = [
  { label: 'is', value: '' },
  { label: 'is not', value: '-' },
];

const SearchFilterToggleIsNot = memo((props: SearchFilterToggleIsNotProps) => {
  const { value, showSelectedOnly = false, onChange } = props;

  const options = useMemo(() => {
    if (showSelectedOnly)
      return operatorOptions.filter((x) => !!x.value === !!value);

    return operatorOptions;
  }, [value, showSelectedOnly]);

  const onChangeHandler = (value: string | undefined) => {
    if (value !== undefined) onChange(value);
  };

  return (
    <ToggleGroup
      appearance="stitched"
      color="flay"
      disableUnselect
      onChange={onChangeHandler}
      options={options}
      size="tiny"
      type="single"
      value={value || ''}
    />
  );
});

export { SearchFilterToggleIsNot };
