import { t } from '@lingui/macro';

import { PersonTypeOption } from '../types';

/**
 * Modifies the provided base person type options to reflect whether
 * 'Placeholder' option should be disable or presented as a legacy.
 *
 * @param {GetPersonTypeArgs} data - The data object containing basePersonTypeOptions, isPlaceholderOptionEnabled, and isLegacyPlaceholder.
 * @param {PersonTypeOption[]} data.basePersonTypeOptions - The array of base person type options to be modified.
 * @param {boolean} data.isPlaceholderOptionEnabled - Indicates whether placeholder options are disabled.
 * @param {boolean} data.isLegacyPlaceholder - Indicates whether the person is a legacy placeholder.
 * @returns {PersonTypeOption[]} An array of modified person type options considering the placeholder options' status and legacy placeholder status.
 */

export type GetPersonTypeArgs = {
  basePersonTypeOptions: PersonTypeOption[];
  isPlaceholderOptionEnabled: boolean;
  isLegacyPlaceholder: boolean;
};

export const getPersonTypeOptions = (data: GetPersonTypeArgs) => {
  const {
    basePersonTypeOptions,
    isPlaceholderOptionEnabled,
    isLegacyPlaceholder,
  } = data;

  if (!isPlaceholderOptionEnabled || isLegacyPlaceholder) {
    return basePersonTypeOptions.map((option) => {
      if (option.value === 3) {
        return {
          ...option,
          label: isLegacyPlaceholder
            ? option.label + ' ' + t`(legacy)`
            : option.label,
          disabled: !isPlaceholderOptionEnabled || option.disabled,
          description: !isPlaceholderOptionEnabled
            ? t`Can’t have email or Access Rights`
            : undefined,
        };
      }

      return option;
    });
  }

  return basePersonTypeOptions;
};
