import { connect } from 'react-redux';
import { upperFirst } from 'lodash';

import { moment } from '@float/libs/moment';

import { fetchAllInvoices, sortInvoices } from '../../actions/account/invoices';
import Table from '../../components/Table';
import withOnMount from '../../decorators/withOnMount';
import {
  areBeingFetched as invoicesAreBeingFetched,
  lastFetchError as invoicesLastFetchError,
} from '../../reducers/account/invoices';
import { getSortData, getTableRows } from '../../reducers/reducerHelpers';
import { SETTINGS_TABLE_DATE_FORMAT } from '../../settingsV2.constants';
import { formatAmountToUSDCurrency } from './utils';

const getInvoiceData = (entity) => {
  if (!entity) return null;
  const id = entity.invoice_id;

  const status = {
    text:
      entity.state == 'collected'
        ? 'Paid'
        : upperFirst(entity.state.toLowerCase()),
  };

  if (status.text === 'Paid') status.color = 'green';

  return {
    isEdited: false,
    isDeletable: false,
    id,
    data: [
      id,
      entity.billed_on
        ? moment(entity.billed_on).format(SETTINGS_TABLE_DATE_FORMAT)
        : 'no transaction',
      moment(entity.due_on).format(SETTINGS_TABLE_DATE_FORMAT),
      status,
      `$${formatAmountToUSDCurrency(
        entity.total / 100,
        !!((entity.total / 100) % 1),
        true,
      )} USD`,
      {
        href: entity.download_link,
        text: 'Download PDF',
      },
    ],
  };
};

const isLoading = (state) => invoicesAreBeingFetched(state.settingsInvoices);

const loadError = (state) => invoicesLastFetchError(state.settingsInvoices);

const mapStateToProps = (state) => {
  return {
    rows: getTableRows(state.settingsInvoices, getInvoiceData, 'invoice_id'),
    sort: getSortData(state.settingsInvoices),
    companyId: state.currentUser.cid,
    isLoading: isLoading(state),
    loadError: loadError(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onComponentDidMount: () => dispatch(fetchAllInvoices()),
    onClickHeader: (property, direction) =>
      dispatch(sortInvoices(property, direction)),
    onClickEdit: (id) => null,
    onClickCancel: (id) => null,
    onClickDelete: (id) => null,
    onSubmit: (row, name) => null,
  };
};

const InvoicesTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOnMount(Table));

export default InvoicesTable;
