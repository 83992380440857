import React from 'react';

import { StyledErrorRow } from '../styles';

const ErrorRow = () => (
  <StyledErrorRow>
    <td colSpan={100}>
      We're having trouble connecting. Please refresh your browser and try
      again.
    </td>
  </StyledErrorRow>
);

export default ErrorRow;
