import toNumber from 'lodash/toNumber';

// https://app.asana.com/0/1201161864415673/1202122070548185/f
// These helpers are to fix discrepancies in calculations between floating points.
// In Javascript, for example:
//   * 17.42 - 7.5 returns 9.920000000000002, where you would expect 9.92
//   * 1.4 + 0.4 returns 1.7999999999999998, where you would expect 1.8
// These extra decimal places are enough to skew data points after several passes.
// More about this: https://programmer.group/javascript-plus-and-minus-crisis-why-is-this-happening.html

export function cutDecimalValues(value: number) {
  return Math.round(value * 100) / 100;
}

export function sumOperation(a: string | number, b: string | number) {
  return cutDecimalValues(toNumber(a) + toNumber(b));
}

export function subtractOperation(a: string | number, b: string | number) {
  return cutDecimalValues(toNumber(a) - toNumber(b));
}

export function divisionOperation(a: string | number, b: string | number) {
  return cutDecimalValues(toNumber(a) / toNumber(b));
}

export function multiplyOperation(a: string | number, b: string | number) {
  return cutDecimalValues(toNumber(a) * toNumber(b));
}
