import { getFilteredEntities } from '@float/common/search/selectors/filteredEntities';
import { getUser } from '@float/common/selectors/currentUser';
import { getLockPeriodDates } from '@float/common/selectors/lockLoggedTime';
import { getPhasesMapRaw } from '@float/common/selectors/phases';
import { getProjectsMap } from '@float/common/selectors/projects';
import { getActiveFilters } from '@float/common/selectors/views';
import { useAppSelectorStrict } from '@float/common/store';

export function useTopCellSerenaData() {
  const user = useAppSelectorStrict(getUser);
  const phases = useAppSelectorStrict(getPhasesMapRaw);
  const projects = useAppSelectorStrict(getProjectsMap);
  const filters = useAppSelectorStrict(getActiveFilters);
  const filteredEntities = useAppSelectorStrict(getFilteredEntities);
  const loggedTimeLockPeriodDates = useAppSelectorStrict(getLockPeriodDates);

  return {
    user,
    phases,
    projects,
    filters,
    filteredEntities,
    loggedTimeLockPeriodDates,
  };
}
