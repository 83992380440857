import { AnyEntity } from '@float/types';

// Attributes used for temp states
type EntityMetaInfo = {
  adjusted?: boolean;
  temporaryId?: string;
  replacesTempId?: string;
  isSplitting?: boolean;
  isResizingVertically?: boolean;
  isResizingHorizontally?: boolean;
  isPlaceholder?: boolean;
  isTaskReference?: boolean;
  isTimeoff?: boolean;
  min_end_date?: string | null;
  max_end_date?: string | null;
  min_start_date?: string | null;
  max_start_date?: string | null;
  start?: number;
  repeatInstances?: {
    start_date: string;
    end_date: string;
  }[];
  _allInstancesMemoKey?: { start_date: string; end_date: string }[];
  _old_priority?: number;
  _old_priority_info?: Record<number, number>;
  _dragInProgress?: boolean;
  isSidebarItem?: boolean;
  disabled?: boolean;
  isProjectRow?: boolean;
  taskIdsToUnlink?: string[];
};

export function setEntityMeta<K extends keyof EntityMetaInfo>(
  entity: AnyEntity,
  key: K,
  value: EntityMetaInfo[K],
) {
  // this is to maintain backward compatibility
  // once this has been spread all over the codebase we will switch to WeakMaps
  // @ts-expect-error
  entity[key] = value;
}

export function getEntityMeta<K extends keyof EntityMetaInfo>(
  entity: AnyEntity,
  key: K,
): EntityMetaInfo[K] {
  // @ts-expect-error
  return entity[key];
}
