import { sortBy } from 'lodash';

export const getSortedTags = (tags = []) => {
  return sortBy(
    tags.map((t) => t.name),
    (t) => t.toLowerCase(),
  );
};

export function formatDomains(domains) {
  switch (domains.length) {
    case 1:
      return domains[0];
    case 2:
      return `${domains[0]} or ${domains[1]}`;
    default:
      return `${domains.slice(0, -1).join(', ')} or ${
        domains[domains.length - 1]
      }`;
  }
}
