import { get } from 'lodash';
import { createSelector } from 'reselect';

export const getAllSeen = createSelector(
  [(state) => state.activityData],
  (activityData) => {
    return get(
      activityData,
      'activityCollections.mainActivityFeed.allSeen',
      true,
    );
  },
);
