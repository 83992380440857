import { getUser } from '@float/common/selectors/currentUser';
import { useAppSelectorStrict } from '@float/common/store';

import { checkIsPeopleManager } from '../access';

export const useIsPeopleManager = () => {
  const user = useAppSelectorStrict(getUser);

  return checkIsPeopleManager(user.account);
};
