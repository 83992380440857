import { AppStore } from '@float/common/store';
import { Activity } from '@float/types/activity';

import { isClickableActivity } from '../activityItemClick';
import { formatGenericActivityText } from './formatGenericActivityText';
import { formatViewActivityText } from './formatViewActivityText';
import { ItemClickCallback } from './types';

export function formatActivityText(
  item: Activity,
  onItemClick: ItemClickCallback,
  store: AppStore,
) {
  if (item.item_type === 'view') {
    return formatViewActivityText(item);
  }

  return formatGenericActivityText(item, onItemClick, (item) =>
    isClickableActivity(item, store),
  );
}
