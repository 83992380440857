import { StyleRule } from '@vanilla-extract/css';
import { css } from 'styled-components';

export const E300 = css`
  box-shadow:
    0px -7px 12px rgba(165, 159, 190, 0.06),
    2px 9px 18px rgba(9, 30, 66, 0.1),
    0px 0px 1px rgba(9, 30, 66, 0.15);
`;

export const VE_E300: StyleRule = {
  boxShadow:
    '0px -7px 12px rgba(165, 159, 190, 0.06), 2px 9px 18px rgba(9, 30, 66, 0.1), 0px 0px 1px rgba(9, 30, 66, 0.15)',
};
