import { useMemo } from 'react';
import { useFormState } from 'react-hook-form';

import { Project } from '@float/types/project';
import { ProjectFormData } from 'components/panelViews/Project/types';

export function useProject() {
  const { defaultValues } = useFormState<ProjectFormData>();
  if (!defaultValues) {
    throw new Error('useProject must be used within a ProjectForm');
  }

  return useMemo(
    () => ({
      project_id: defaultValues.projectId,
      ...defaultValues.project,
    }),
    [defaultValues],
  ) as Project;
}

export function useSavedProjectFormData() {
  const { defaultValues } = useFormState<ProjectFormData>();
  if (!defaultValues) {
    throw new Error('useProject must be used within a ProjectForm');
  }

  return defaultValues as ProjectFormData;
}
