import { BaseEditor, Node, Transforms } from 'slate';

export function MaxLengthPlugin<T extends BaseEditor>(
  editor: T,
  options = { maxLength: 1500 },
) {
  const { maxLength } = options;
  const { normalizeNode, insertBreak } = editor;

  const toText = (nodes: Node[]) => {
    return nodes.map((n) => Node.string(n)).join('\n');
  };

  editor.normalizeNode = (entry) => {
    const text = toText(editor.children);

    if (text.length > maxLength) {
      Transforms.delete(editor, {
        distance: text.length - maxLength,
        reverse: true,
      });
      return;
    }

    normalizeNode(entry);
  };

  editor.insertBreak = () => {
    const text = toText(editor.children);

    if (text.length >= maxLength) {
      return;
    }

    insertBreak();
  };

  editor.insertSoftBreak = () => {
    const text = toText(editor.children);

    if (text.length >= maxLength) {
      return;
    }

    insertBreak();
  };

  return editor;
}
