import styled from 'styled-components';

export const StyledForm = styled.form`
  display: flex;
  align-items: flex-end;

  > * + * {
    margin-left: 10px;
  }
`;
