import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m13.75 6.75 5.5 5.25-5.5 5.25M4.75 12H19"
    />
  </BaseIcon>
);
