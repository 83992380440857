import React, { forwardRef, HTMLAttributes, PropsWithChildren } from 'react';
import { AnimationProps, motion } from 'framer-motion';

export const AnimateListItemTransition = {
  damping: 24,
  opacity: { delay: 0.05 },
  stiffness: 300,
  type: 'spring',
} as const;

const initial = {
  opacity: 0,
  transform: 'translateY(-8px) scale(0.95)',
} as AnimationProps['initial'];

const animate = {
  opacity: 1,
  transform: 'translateY(0px) scale(1)',
  transition: AnimateListItemTransition,
} as AnimationProps['animate'];

const exit = {
  opacity: 0,
  transform: 'translateY(-8px) scale(0.95)',
} as AnimationProps['exit'];

export const AnimateListItem = forwardRef<
  HTMLDivElement,
  PropsWithChildren & HTMLAttributes<HTMLDivElement>
>((props, ref) => {
  const { children, className } = props;

  return (
    <motion.div
      ref={ref}
      className={className}
      initial={initial}
      animate={animate}
      exit={exit}
      layout="position"
    >
      {children}
    </motion.div>
  );
});
