import useLocalStorage from 'react-use/esm/useLocalStorage';

const cacheKey = 'settings.roles.sort';

export type TableSortOptions = {
  sortBy: 'name' | 'default_hourly_rate' | 'people_count';
  sortDir: 'asc' | 'desc';
};

export const useRowsTableSort = () => {
  const tableSortDefaultValue: TableSortOptions = {
    sortBy: 'name',
    sortDir: 'asc',
  };

  const [tableSort, setTableSort] = useLocalStorage<TableSortOptions>(
    cacheKey,
    tableSortDefaultValue,
  );

  return {
    tableSort,
    setTableSort,
  };
};
