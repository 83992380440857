import { ReduxState } from '@float/common/reducers/lib/types';

import { trackEvent } from '../../lib/gtm';

export function trackPersonDepartmentChanges(
  person: { people_id?: number; department_id?: number },
  { people, departments }: ReduxState,
) {
  const hasSubDepartment = (departmentId: number | undefined) =>
    Boolean(departmentId && departments.departments[departmentId]?.parent_id);

  // is a new person?
  if (!person.people_id) {
    if (person.department_id) {
      trackEvent('person-assigned-to-department', {
        subDepartment: hasSubDepartment(person.department_id),
      });
    }

    return;
  }

  const previous = people.people[person.people_id];

  if (!previous) return;

  if (previous.department_id !== person.department_id) {
    if (person.department_id) {
      trackEvent('person-assigned-to-department', {
        subDepartment: hasSubDepartment(person.department_id),
      });
    }

    if (previous.department_id) {
      trackEvent('person-removed-from-department', {
        subDepartment: hasSubDepartment(previous.department_id),
      });
    }
  }
}
