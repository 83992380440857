import React from 'react';

export default function ({ size = '120' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 120 120"
    >
      <defs>
        <linearGradient id="prefix__b" x1="50%" x2="50%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#EEE" />
          <stop offset="100%" stopColor="#C1C1C1" />
        </linearGradient>
        <linearGradient id="prefix__d" x1="50%" x2="50%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#F7F7F7" />
          <stop offset="100%" stopColor="#E2E2E2" />
        </linearGradient>
        <circle id="prefix__a" cx="60" cy="60" r="60" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__c" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <use fill="url(#prefix__b)" xlinkHref="#prefix__a" />
        <g mask="url(#prefix__c)">
          <g>
            <path
              fill="#A2A7AA"
              d="M12.294 11.43L12.294 23.463 0 17.447z"
              transform="translate(32 21.176)"
            />
            <path
              stroke="#868D92"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3.61"
              d="M27.199 81.818L27.199 105.882M40.723 81.818L40.723 105.882"
              transform="translate(32 21.176)"
            />
            <path
              fill="#F7F7F7"
              d="M21.727 0h3.294c5.316 0 10.203 2.922 12.719 7.605l23.149 43.09c2.194 4.102 5.228 7.717 8.913 10.62l11.746 9.254c3.374 2.659 3.908 7.49 1.192 10.793-1.49 1.81-3.736 2.863-6.11 2.863H28.12c-10.185 0-18.441-8.08-18.441-18.049V11.791C9.68 5.279 15.074 0 21.727 0z"
              transform="translate(32 21.176)"
            />
            <path
              fill="url(#prefix__d)"
              d="M23.732 44.879l6.771 21.297c2.58 9.51 15.029 18.049 25.214 18.049H28.63c-10.185 0-18.441-8.08-18.441-18.049V44.88h13.543z"
              transform="translate(32 21.176)"
            />
            <path
              stroke="#868D92"
              strokeLinecap="round"
              strokeWidth="1.324"
              d="M17.056 14.439c0 1.66 1.376 3.008 3.074 3.008h0c1.697 0 3.074-1.347 3.074-3.008"
              transform="translate(32 21.176)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
