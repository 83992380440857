import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { trackOnboardingPrompt } from 'OnboardingManager/helpers/analytics';
import { TransitionManager, TransitionNode } from 'TransitionManager';

import { PROMPTS } from '@float/constants/prompts';
import { useWebAppSelectorStrict } from '@float/web/lib/store';
import {
  OnboardingManagerNextFunction,
  OnboardingPromptNextFunction,
  OnboardingSurveyStatus,
} from '@float/web/store/onboardingManager/types';

import { AsideBase } from './components/AsideBase';
import { AsideBg } from './components/AsideBg';
import { BreadcrumbNavigation } from './components/BreadcrumbNavigation';
import { OnboardingSurveySuccess } from './components/OnboardingSurveySuccess';
import { isNavigatingBetween } from './helpers/isNavigatingBetween';
import { AddPeople } from './prompts/AddPeople';
import { AddPeopleAside } from './prompts/AddPeople/AddPeopleAside';
import { AddProject } from './prompts/AddProject';
import { AddProjectAside } from './prompts/AddProject/AddProjectAside';
import { GetToKnowYou } from './prompts/GetToKnowYou';
import { GetToKnowYouAside } from './prompts/GetToKnowYou/GetToKnowYouAside';
import { SurveyGoals } from './prompts/SurveyGoals';

import * as styles from './styles.css';

export type OnboardingPromptProps = {
  next: OnboardingManagerNextFunction;
};

export type OnboardingSurveyProps = OnboardingPromptProps & {
  status: OnboardingSurveyStatus;
};

// To force the Survey to render add ?prompts to the URL
export const OnboardingSurvey = memo((props: OnboardingSurveyProps) => {
  const { status, next } = props;
  const { navigation } = status;

  const [asidePrompt, setAsidePrompt] = useState(navigation.prompt);
  const [previousPrompt, setPreviousPrompt] = useState(navigation.prompt);
  const [peopleCount, setPeopleCount] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);
  const breadcrumbRef = useRef();
  const asideBGRef = useRef();

  const previousState = useWebAppSelectorStrict(
    (state) => state.onboardingManager?.promptData,
  );

  // Interaction

  const onClickNext = useCallback<OnboardingPromptNextFunction>(
    (data, saveOnServer) => {
      // analytics
      trackOnboardingPrompt(navigation.prompt, data!);

      // if leaving the success screen, animate it out and only then complete survey
      if (navigation.prompt === PROMPTS.onboardingSurveySuccess) {
        gsap.to(containerRef.current, {
          opacity: 0,
          scale: 0.98,
          duration: 0.5,
          ease: 'power4.inOut',
          onComplete: () => {
            if (navigation.prompt) next(navigation.prompt, data, saveOnServer);
          },
        });
      } else {
        if (navigation.prompt) next(navigation.prompt, data, saveOnServer);
      }
    },
    [next, navigation],
  );

  // Animation / transitions

  const onTransition = useCallback(
    (transition: TransitionNode, done: () => void) => {
      const { component, callback, isAppearing } = transition;

      const delay = isAppearing ? 0.75 : 0;
      const ease = 'power3.out';
      const duration = 0.85;

      // 1 is next, -1 is previous
      const direction = navigation.previousPage < navigation.page ? 1 : -1;

      if (isAppearing) {
        // @ts-expect-error no types
        breadcrumbRef.current?.onAppear({ duration, ease, delay });
        // @ts-expect-error no types
        asideBGRef.current?.onAppear({ duration, ease, delay });
      }

      if (component && component[callback]) {
        component[callback]({
          isAppearing,
          duration,
          ease,
          delay,
          direction,
        }).then(done);
      } else {
        done();
      }
    },
    [navigation],
  );

  useEffect(() => {
    if (
      isNavigatingBetween(
        navigation.prompt,
        previousPrompt,
        PROMPTS.welcomeAddProject,
        PROMPTS.welcomeAddTask,
      ) ||
      isNavigatingBetween(
        navigation.prompt,
        previousPrompt,
        PROMPTS.welcomeGetToKnowYou,
        PROMPTS.onboardingSurveyGoals,
      )
    ) {
      return;
    }

    setAsidePrompt(navigation.prompt);
  }, [previousPrompt, navigation.prompt]);

  useEffect(() => {
    if (previousPrompt !== navigation.prompt) {
      setPreviousPrompt(navigation.prompt);
    }
  }, [navigation.prompt, previousPrompt, setPreviousPrompt]);

  return (
    <div className={styles.container} ref={containerRef}>
      {navigation.totalPages > 2 && (
        <BreadcrumbNavigation
          // @ts-expect-error no types
          className={styles.breadcrumbNav}
          ref={breadcrumbRef}
          navigation={navigation}
        />
      )}

      {/* Content */}

      <TransitionManager keyID={navigation.prompt} onTransition={onTransition}>
        {navigation.prompt === PROMPTS.welcomeGetToKnowYou && (
          <GetToKnowYou
            transitionId={navigation.prompt}
            onClickNext={onClickNext}
            previousState={previousState[navigation.prompt]}
          />
        )}

        {navigation.prompt === PROMPTS.onboardingSurveyGoals && (
          <SurveyGoals
            transitionId={navigation.prompt}
            onClickNext={onClickNext}
            previousState={previousState[navigation.prompt]}
          />
        )}

        {navigation.prompt === PROMPTS.welcomeAddProject && (
          <AddProject
            // @ts-expect-error no types
            transitionId={navigation.prompt}
            onClickNext={onClickNext}
            previousState={previousState[navigation.prompt]}
          />
        )}

        {navigation.prompt === PROMPTS.welcomeAddPerson && (
          <AddPeople
            // @ts-expect-error no types
            transitionId={navigation.prompt}
            onClickNext={onClickNext}
            previousState={previousState[navigation.prompt]}
            setPeopleCount={setPeopleCount}
          />
        )}

        {navigation.prompt === PROMPTS.onboardingSurveySuccess && (
          <OnboardingSurveySuccess
            transitionId={navigation.prompt}
            onClickNext={onClickNext}
            previousState={previousState[navigation.prompt]}
          />
        )}
      </TransitionManager>

      {/* Illustrations */}

      <div className={styles.aside}>
        <div className={styles.asideSticky}>
          <div className={styles.asideInner}>
            <TransitionManager keyID={asidePrompt} onTransition={onTransition}>
              {(asidePrompt === PROMPTS.welcomeGetToKnowYou ||
                asidePrompt === PROMPTS.onboardingSurveyGoals) && (
                <GetToKnowYouAside transitionId={`${asidePrompt}-aside`} />
              )}

              {(asidePrompt === PROMPTS.welcomeAddProject ||
                asidePrompt === PROMPTS.welcomeAddTask) && (
                // @ts-expect-error no types
                <AddProjectAside transitionId={`${asidePrompt}-aside`} />
              )}

              {asidePrompt === PROMPTS.welcomeAddPerson && (
                <AddPeopleAside
                  // @ts-expect-error no types
                  transitionId={`${asidePrompt}-aside`}
                  peopleCount={peopleCount}
                />
              )}

              {asidePrompt === PROMPTS.onboardingSurveySuccess && (
                // empty aside component, so there's a matching aside for each content
                // and that way the transition timings match
                // @ts-expect-error no types
                <AsideBase transitionId={`${asidePrompt}-aside`} />
              )}
            </TransitionManager>
          </div>

          {
            // @ts-expect-error no types
            <AsideBg ref={asideBGRef} navigation={navigation} />
          }
        </div>
      </div>
    </div>
  );
});
