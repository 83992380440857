import React, { forwardRef, ReactNode } from 'react';
import cx from 'classnames';

import { CardColors, StyledCard } from './styles';

type CardProps = {
  className?: string;
  children: ReactNode;
  color?: string;
  id?: string;
};

const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ className, children, color = CardColors.WHITE, id }, ref) => {
    return (
      <StyledCard
        ref={ref}
        id={id}
        $color={color}
        className={cx('eh-card', className)}
      >
        {children}
      </StyledCard>
    );
  },
);

export { Card, CardColors };

export default Card;
