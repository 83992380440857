import { RefObject, useCallback, useEffect } from 'react';

/**
 * A custom React hook to make sure the focus outline style is shown when a
 * button receives focus from a non-mouse interaction. (works with
 * @ui/styles/helpers/focusOutline)
 *
 * Context:
 *
 * Our new Modal primitive, based on the Radix Dialog, have this built-in
 * functionality that on open it will focus the first interactive element
 * inside. This is a good accessibility pattern
 * (https://www.w3.org/WAI/ARIA/apg/patterns/dialog-modal/) that we should keep.
 *
 * Our Confirmation modal, for example, one of the CTAs will automatically be
 * focused, and in that circumstance we want the focus outline to be visible. I
 * noticed though, that our @ui/Button does not render the focus outline in that
 * situation. It is focused, but the styles don't get applied.
 *
 * This seems to be related with some limitation around the :focus-visible
 * selector. It could easily be fixed by using the :focus selector instead, but
 * that would introduce the unintended behaviour for which the :focus-visible
 * was created. E.g. no focus outline on mouse clicks.
 * (https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible)
 *
 * @param {React.RefObject<HTMLElement>} element - Reference to the button
 * element
 */

export const useButtonAutoFocus = (element: RefObject<HTMLElement>) => {
  const onMouseDown = useCallback(() => {
    element.current?.classList.add('active');

    window.addEventListener(
      'mouseup',
      () => {
        element.current?.classList.remove('active');
        element.current?.classList.remove('auto-focus');
      },
      { once: true },
    );
  }, [element]);

  const onFocus = useCallback(() => {
    element.current?.classList.add('auto-focus');
  }, [element]);

  const onBlur = useCallback(() => {
    element.current?.classList.remove('auto-focus');
  }, [element]);

  useEffect(() => {
    const el = element.current;

    el?.addEventListener('mousedown', onMouseDown);
    el?.addEventListener('focus', onFocus);
    el?.addEventListener('blur', onBlur);

    return () => {
      el?.removeEventListener('mousedown', onMouseDown);
      el?.removeEventListener('focus', onFocus);
      el?.removeEventListener('blur', onBlur);
    };
  }, [element, onMouseDown, onFocus, onBlur]);
};
