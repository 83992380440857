import React from 'react';

import VirtualSelect from '@float/ui/deprecated/VirtualSelect/VirtualSelect';

function Dropdown(props) {
  const { onChange, value, placeholder, options, ...others } = props;
  return (
    <VirtualSelect
      appearance="color"
      size="large"
      visibleItems={6}
      onChange={onChange}
      placeholder={placeholder}
      options={options}
      value={value}
      clearInputOnDropdownOpen={false}
      {...others}
    ></VirtualSelect>
  );
}

export default Dropdown;
