import * as LaunchDarkly from 'launchdarkly-js-client-sdk';

import { FloatDeploymentEnvironment } from '@float/constants/environment';
import { featureFlags } from '@float/libs/featureFlags';
import { CurrentUserAuthInfo } from '@float/types/auth';

import { LaunchDarklyWebAdapter } from './LaunchDarklyWebAdapter.class';

export async function initializeFeatureFlagsClient(
  user: CurrentUserAuthInfo | undefined,
  environment: FloatDeploymentEnvironment,
  initialize = LaunchDarkly.initialize,
) {
  return featureFlags.initialize(
    new LaunchDarklyWebAdapter(user, environment, initialize),
  );
}
