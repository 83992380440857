import React from 'react';

export const TooltipArrowIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="13"
      height="10"
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.5 1 L6.5 8.5 L1.5 1" strokeLinecap="round" />
    </svg>
  );
};
