import React, { StrictMode, useMemo } from 'react';

import { useGetTimeframes } from '@float/common/api3/timeframes';
import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import { parseFloatDate } from '@float/libs/dates';
import { Timeframe } from '@float/types/timeframes';

import { EmptyCycles } from './components/Cycle';
import { CycleActiveMonthIndicator } from './components/CycleActiveMonthIndicator';
import { CycleList } from './components/CycleList';
import { CycleMonthIndicators } from './components/CycleMonthIndicators';
import { CycleTitleList } from './components/CycleTitleList';
import { buildAllCycles } from './helpers/buildAllCycles';
import { useActiveMonth } from './hooks/useActiveMonth';

import * as styles from './Cycles.css';

export type CyclesViewProps = {
  baseColOffset: number;
  dayWidth: number;
  dates: DatesManager;
  endDate: DateString;
  leftStickyBoundary: number;
  numDays: number;
  offset?: number;
  scrollWrapperRef: React.MutableRefObject<HTMLElement | null | undefined>;
  startDate: DateString;
  timeframes?: Timeframe[];
};

// @test-export
export const CyclesView: React.FC<CyclesViewProps> = React.memo(
  ({
    baseColOffset,
    dayWidth,
    dates,
    endDate,
    leftStickyBoundary,
    numDays,
    offset = 0,
    scrollWrapperRef,
    startDate,
    timeframes = [],
  }) => {
    const parsedCycles = useMemo(() => {
      const today = new Date();

      const parsedCycles = buildAllCycles(
        timeframes,
        parseFloatDate(startDate),
        parseFloatDate(endDate),
        today,
      );

      return parsedCycles;
    }, [endDate, startDate, timeframes]);

    const { activeMonth, setActiveMonth } = useActiveMonth({
      baseColOffset,
      dates,
      dayWidth,
      numDays,
      scrollWrapperRef,
    });

    return (
      <StrictMode>
        <>
          <CycleActiveMonthIndicator
            activeMonth={activeMonth}
            leftStickyBoundary={leftStickyBoundary}
          />
          <div
            style={{ left: offset, width: `calc(100% - ${offset}px)` }}
            className={styles.allCyclesWrapper}
          >
            {parsedCycles.length ? (
              <>
                <CycleList cycles={parsedCycles} dayWidth={dayWidth} />
                <CycleTitleList
                  cycles={parsedCycles}
                  dayWidth={dayWidth}
                  leftStickyBoundary={leftStickyBoundary}
                />
              </>
            ) : (
              <EmptyCycles />
            )}

            <CycleMonthIndicators
              dayWidth={dayWidth}
              endDate={endDate}
              leftStickyBoundary={leftStickyBoundary}
              scrollWrapperRef={scrollWrapperRef}
              setActiveMonth={setActiveMonth}
              startDate={startDate}
            />
          </div>
        </>
      </StrictMode>
    );
  },
);

export type CyclesProps = {
  endDate: DateString;
  leftStickyBoundary?: number;
  offset: number;
  startDate: DateString;
};

export const Cycles: React.FC<CyclesProps> = ({
  endDate,
  leftStickyBoundary = 260,
  offset,
  startDate,
}) => {
  const { data: timeframes } = useGetTimeframes();
  const { baseColOffset, dates, dayWidth, numDays, scrollWrapperRef } =
    useScheduleContext();

  return (
    <StrictMode>
      <CyclesView
        baseColOffset={baseColOffset}
        dayWidth={dayWidth}
        dates={dates}
        numDays={numDays}
        endDate={endDate}
        leftStickyBoundary={leftStickyBoundary}
        offset={offset}
        scrollWrapperRef={scrollWrapperRef}
        startDate={startDate}
        timeframes={timeframes ?? undefined}
      />
    </StrictMode>
  );
};
