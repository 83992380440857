import React, { useRef } from 'react';

import {
  PersonProjectRow,
  PersonRow,
  ProjectRow,
} from '@float/common/serena/Data/useScheduleRows';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { CellItem } from '@float/types';

import { ScheduleActions } from '../types';

import { baseLayer, box } from './HolidayItem.css';

type HolidayItemProps = {
  item: CellItem<'holiday'> | CellItem<'timeoff'>;
  row: PersonRow | PersonProjectRow | ProjectRow;
  actions: ScheduleActions;
  dayWidth: number;
  suvSingleDay?: string | null;
};

function HolidayItem(props: HolidayItemProps) {
  const { item, dayWidth, actions, row, suvSingleDay } = props;
  const { x = 0, w } = item;
  const didMouseDown = useRef(false);

  const left = x * dayWidth + 1;
  const width = w * dayWidth - 1;

  return (
    <div
      className={baseLayer}
      onClick={prevent}
      onMouseDown={(event) => {
        event.stopPropagation();
        if (event.button !== 0) return;
        didMouseDown.current = true;
      }}
      onMouseUp={(event) => {
        if (!didMouseDown.current) return;
        didMouseDown.current = false;
        actions.onNonWorkDayClick(event, row, suvSingleDay);
      }}
      style={{
        left,
        top: suvSingleDay ? 0 : -1,
        width,
        height: '100%',
      }}
    >
      <div className={box} />
    </div>
  );
}

export default HolidayItem;
