import styled, { css } from 'styled-components';

import * as Animation from '@float/ui/deprecated/Earhart/Animation';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

export const IconContainer = styled.div`
  display: flex;
  align-items: center;

  ${({ isRight }) => (isRight ? 'margin-left: 5px' : 'margin-right: 5px')};

  svg {
    width: 20px;
    height: 20px;

    path {
      transition-property: stroke, fill;
      transition-duration: ${Animation.Settings.Duration.Short};
      transition-timing-function: ${Animation.Settings.Easing.InOutCurve};
    }
  }
`;

export const SpinnerContainer = styled(IconContainer)`
  width: 16px;
  height: 16px;
  padding: 0 2px;

  svg {
    animation: rotate 0.6s linear infinite;
  }
`;

export const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  min-width: ${({ $minWidth }) => ($minWidth ? $minWidth : null)};

  border-radius: 6px;

  cursor: ${(p) => (p.loader?.loading ? 'default' : 'pointer')};

  outline: none;

  box-sizing: border-box;

  transition-property: background-color, color, border-color;
  transition-duration: ${Animation.Settings.Duration.Short};
  transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

  ${(p) => p.theme.antialias};
  ${(p) => p.theme.ie11MinHeightFix};

  ${({ $appearance, disabled }) =>
    $appearance === 'primary' &&
    css`
      color: ${Colors.FIN.Lt.Emphasis.White};
      background-color: ${Colors.FIN.Lt.Buttons.Primary.Default.Bg};
      border: none;

      ${IconContainer} {
        svg {
          --svg-icon-color: ${Colors.FIN.Lt.Emphasis.White};
        }
      }

      // interaction states

      ${!disabled &&
      css`
        &:hover,
        &:focus {
          background-color: ${Colors.FIN.Lt.Buttons.Primary.Hover.Bg};
        }

        &:active,
        &.active,
        &[data-state='open'] {
          background-color: ${Colors.FIN.Lt.Buttons.Primary.Pressed.Bg};
        }
      `};

      ${disabled &&
      css`
        color: ${Colors.FIN.Lt.Buttons.Primary.Disabled.Label};
        background-color: ${Colors.FIN.Lt.Buttons.Primary.Disabled.Bg};

        ${IconContainer} {
          svg {
            --svg-icon-color: ${Colors.FIN.Lt.Buttons.Primary.Disabled.Label};
          }
        }

        ${SpinnerContainer} {
          svg {
            g {
              stroke: ${Colors.FIN.Lt.Buttons.Primary.Disabled.Label};
            }
          }
        }
      `};
    `};

  ${({ $appearance, disabled }) =>
    $appearance === 'secondary' &&
    css`
      color: ${Colors.FIN.Lt.Buttons.Secondary.Default.Label};
      background-color: ${Colors.FIN.Lt.Buttons.Secondary.Default.Bg};

      ${IconContainer} {
        svg {
          --svg-icon-color: ${Colors.FIN.Lt.Buttons.Secondary.Default.Label};
        }
      }

      ${SpinnerContainer} {
        svg {
          g {
            stroke: ${Colors.FIN.Lt.Buttons.Secondary.Default.Label};
          }
        }
      }

      // interaction states

      ${!disabled &&
      css`
        &:hover,
        &:focus {
          background-color: ${Colors.FIN.Lt.Buttons.Secondary.Hover.Bg};
        }

        &:active,
        &.active,
        &[data-state='open'] {
          background-color: ${Colors.FIN.Lt.Buttons.Secondary.Pressed.Bg};
        }
      `};

      ${disabled &&
      css`
        color: ${Colors.FIN.Lt.Buttons.Secondary.Disabled.Label};
        background-color: ${Colors.FIN.Lt.Buttons.Secondary.Disabled.Bg};

        ${IconContainer} {
          svg {
            --svg-icon-color: ${Colors.FIN.Lt.Buttons.Secondary.Disabled.Label};
          }
        }

        ${SpinnerContainer} {
          svg {
            g {
              stroke: ${Colors.FIN.Lt.Buttons.Secondary.Disabled.Label};
            }
          }
        }
      `};
    `};

  ${({ $appearance, disabled }) =>
    $appearance === 'tertiary' &&
    css`
      color: ${Colors.FIN.Lt.Buttons.Tertiary.Default.Label};
      background-color: ${Colors.FIN.Lt.Buttons.Tertiary.Default.Bg};

      ${IconContainer} {
        svg {
          --svg-icon-color: ${Colors.FIN.Lt.Buttons.Tertiary.Default.Label};
        }
      }

      ${SpinnerContainer} {
        svg {
          g {
            stroke: ${Colors.FIN.Lt.Buttons.Tertiary.Default.Label};
          }
        }
      }

      // interaction states

      ${!disabled &&
      css`
        &:hover,
        &:focus {
          color: ${Colors.FIN.Lt.Buttons.Tertiary.Hover.Label};
          background-color: ${Colors.FIN.Lt.Buttons.Tertiary.Hover.Bg};

          ${IconContainer} {
            svg {
              --svg-icon-color: ${Colors.FIN.Lt.Buttons.Tertiary.Hover.Label};
            }
          }

          ${SpinnerContainer} {
            svg {
              g {
                stroke: ${Colors.FIN.Lt.Buttons.Tertiary.Hover.Label};
              }
            }
          }
        }

        &:active,
        &.active,
        &[data-state='open'] {
          color: ${Colors.FIN.Lt.Buttons.Tertiary.Pressed.Label};
          background-color: ${Colors.FIN.Lt.Buttons.Tertiary.Pressed.Bg};

          ${IconContainer} {
            svg {
              --svg-icon-color: ${Colors.FIN.Lt.Buttons.Tertiary.Pressed.Label};
            }
          }

          ${SpinnerContainer} {
            svg {
              g {
                stroke: ${Colors.FIN.Lt.Buttons.Tertiary.Pressed.Label};
              }
            }
          }
        }
      `};

      ${disabled &&
      css`
        color: ${Colors.FIN.Lt.Buttons.Tertiary.Disabled.Label};
        background-color: ${Colors.FIN.Lt.Buttons.Tertiary.Disabled.Bg};

        ${IconContainer} {
          svg {
            --svg-icon-color: ${Colors.FIN.Lt.Buttons.Tertiary.Disabled.Label};
          }
        }

        ${SpinnerContainer} {
          svg {
            g {
              stroke: ${Colors.FIN.Lt.Buttons.Tertiary.Disabled.Label};
            }
          }
        }
      `};
    `};

  ${({ $appearance, disabled }) =>
    $appearance === 'ghost' &&
    css`
      color: ${Colors.FIN.Lt.Buttons.Ghost.Default.Label};
      background-color: ${Colors.FIN.Lt.Buttons.Ghost.Default.Bg};

      border-width: 1px;
      border-style: solid;
      border-color: ${Colors.FIN.Lt.Buttons.Ghost.Default.stroke};

      ${IconContainer} {
        svg {
          --svg-icon-color: ${Colors.FIN.Lt.Buttons.Ghost.Default.Label};
        }
      }

      ${SpinnerContainer} {
        svg {
          g {
            stroke: ${Colors.FIN.Lt.Buttons.Ghost.Default.Label};
          }
        }
      }

      // interaction states

      ${!disabled &&
      css`
        &:hover,
        &:focus {
          color: ${Colors.FIN.Lt.Buttons.Ghost.Hover.Label};
          background-color: ${Colors.FIN.Lt.Buttons.Ghost.Hover.Bg};
          border-color: ${Colors.FIN.Lt.Buttons.Ghost.Hover.stroke};

          ${IconContainer} {
            svg {
              --svg-icon-color: ${Colors.FIN.Lt.Buttons.Ghost.Hover.Label};
            }
          }

          ${SpinnerContainer} {
            svg {
              g {
                stroke: ${Colors.FIN.Lt.Buttons.Ghost.Hover.Label};
              }
            }
          }
        }

        &:active,
        &.active,
        &[data-state='open'] {
          color: ${Colors.FIN.Lt.Buttons.Ghost.Pressed.Label};
          background-color: ${Colors.FIN.Lt.Buttons.Ghost.Pressed.Bg};
          border-color: ${Colors.FIN.Lt.Buttons.Ghost.Pressed.stroke};

          ${IconContainer} {
            svg {
              --svg-icon-color: ${Colors.FIN.Lt.Buttons.Ghost.Pressed.Label};
            }
          }

          ${SpinnerContainer} {
            svg {
              g {
                stroke: ${Colors.FIN.Lt.Buttons.Ghost.Pressed.Label};
              }
            }
          }
        }
      `};

      ${disabled &&
      css`
        color: ${Colors.FIN.Lt.Buttons.Ghost.Disabled.Label};
        background-color: ${Colors.FIN.Lt.Buttons.Ghost.Disabled.Bg};
        border-color: ${Colors.FIN.Lt.Buttons.Ghost.Disabled.stroke};

        ${IconContainer} {
          svg {
            --svg-icon-color: ${Colors.FIN.Lt.Buttons.Ghost.Disabled.Label};
          }
        }

        ${SpinnerContainer} {
          svg {
            g {
              stroke: ${Colors.FIN.Lt.Buttons.Ghost.Disabled.Label};
            }
          }
        }
      `}
    `};

  ${({ $appearance, disabled }) =>
    $appearance === 'clear' &&
    css`
      color: ${Colors.FIN.Lt.Buttons.Clear.Default.Label};
      background-color: ${Colors.FIN.Lt.Buttons.Clear.Default.Bg};

      ${IconContainer} {
        svg {
          --svg-icon-color: ${Colors.FIN.Lt.Buttons.Clear.Default.Label};
        }
      }

      ${SpinnerContainer} {
        svg {
          g {
            stroke: ${Colors.FIN.Lt.Buttons.Clear.Default.Label};
          }
        }
      }

      // interaction states

      ${!disabled &&
      css`
        &:hover,
        &:focus {
          color: ${Colors.FIN.Lt.Buttons.Clear.Hover.Label};
          background-color: ${Colors.FIN.Lt.Buttons.Clear.Hover.Bg};

          ${IconContainer} {
            svg {
              --svg-icon-color: ${Colors.FIN.Lt.Buttons.Clear.Hover.Label};
            }
          }

          ${SpinnerContainer} {
            svg {
              g {
                stroke: ${Colors.FIN.Lt.Buttons.Clear.Hover.Label};
              }
            }
          }
        }

        &:active,
        &.active,
        &[data-state='open'],
        &[data-state='open'] {
          color: ${Colors.FIN.Lt.Buttons.Clear.Pressed.Label};
          background-color: ${Colors.FIN.Lt.Buttons.Clear.Pressed.Bg};

          ${IconContainer} {
            svg {
              --svg-icon-color: ${Colors.FIN.Lt.Buttons.Clear.Pressed.Label};
            }
          }

          ${SpinnerContainer} {
            svg {
              g {
                stroke: ${Colors.FIN.Lt.Buttons.Clear.Pressed.Label};
              }
            }
          }
        }
      `};

      ${disabled &&
      css`
        color: ${Colors.FIN.Lt.Buttons.Clear.Disabled.Label};
        background-color: ${Colors.FIN.Lt.Buttons.Clear.Disabled.Bg};

        ${IconContainer} {
          svg {
            --svg-icon-color: ${Colors.FIN.Lt.Buttons.Clear.Disabled.Label};
          }
        }

        ${SpinnerContainer} {
          svg {
            g {
              stroke: ${Colors.FIN.Lt.Buttons.Clear.Disabled.Label};
            }
          }
        }
      `};
    `};

  ${({ $appearance, disabled }) =>
    $appearance === 'clear-danger' &&
    css`
      color: ${Colors.FIN.Lt.Buttons.ClearDanger.Default.Label};
      background-color: ${Colors.FIN.Lt.Buttons.ClearDanger.Default.Bg};

      ${IconContainer} {
        svg {
          --svg-icon-color: ${Colors.FIN.Lt.Buttons.ClearDanger.Default.Label};
        }
      }

      ${SpinnerContainer} {
        svg {
          g {
            stroke: ${Colors.FIN.Lt.Buttons.ClearDanger.Default.Label};
          }
        }
      }

      // interaction states

      ${
        !disabled &&
        css`
          &:hover,
          &:focus {
            color: ${Colors.FIN.Lt.Buttons.ClearDanger.Hover.Label};
            background-color: ${Colors.FIN.Lt.Buttons.ClearDanger.Hover.Bg};

            ${IconContainer} {
              svg {
                --svg-icon-color: ${Colors.FIN.Lt.Buttons.ClearDanger.Hover
                  .Label};
              }
            }

            ${SpinnerContainer} {
              svg {
                g {
                  stroke: ${Colors.FIN.Lt.Buttons.ClearDanger.Hover.Label};
                }
              }
            }
          }

          &:active,
          &.active,
          &[data-state='open'] {
            color: ${Colors.FIN.Lt.Buttons.ClearDanger.Pressed.Label};
            background-color: ${Colors.FIN.Lt.Buttons.ClearDanger.Pressed.Bg};

            ${IconContainer} {
              svg {
                --svg-icon-color: ${Colors.FIN.Lt.Buttons.ClearDanger.Pressed
                  .Label};
              }
            }

            ${SpinnerContainer} {
              svg {
                g {
                  stroke: ${Colors.FIN.Lt.Buttons.ClearDanger.Pressed.Label};
                }
              }
            }
          }
        `
      };

      ${
        disabled &&
        css`
        color: ${Colors.FIN.Lt.Buttons.ClearDanger.Disabled.Label};
        background-color: ${Colors.FIN.Lt.Buttons.ClearDanger.Disabled.Bg};

        ${IconContainer} {
          svg {
            --svg-icon-color: ${Colors.FIN.Lt.Buttons.ClearDanger.Disabled.Label};
          }
        }

        ${SpinnerContainer} {
          svg {
            g {
              stroke: ${Colors.FIN.Lt.Buttons.ClearDanger.Disabled.Label};
            }
          }
        `
      };
      }
    `};

  ${({ $appearance, disabled }) =>
    $appearance === 'secondary-dark' &&
    css`
      color: ${Colors.FIN.Lt.Buttons.SecondaryDark.Default.Label};
      background-color: ${Colors.FIN.Lt.Buttons.SecondaryDark.Default.Bg};

      ${IconContainer} {
        svg {
          --svg-icon-color: ${Colors.FIN.Lt.Buttons.SecondaryDark.Default.Bg};
        }
      }

      ${SpinnerContainer} {
        svg {
          g {
            stroke: ${Colors.FIN.Lt.Buttons.SecondaryDark.Default.Bg};
          }
        }
      }

      // interaction states

      ${!disabled &&
      css`
        &:hover,
        &:focus {
          background-color: ${Colors.FIN.Lt.Buttons.SecondaryDark.Hover.Bg};
        }

        &:active,
        &.active,
        &[data-state='open'] {
          background-color: ${Colors.FIN.Lt.Buttons.SecondaryDark.Pressed.Bg};
        }
      `};

      ${disabled &&
      css`
        color: ${Colors.FIN.Lt.Buttons.SecondaryDark.Disabled.Label};
        background-color: ${Colors.FIN.Lt.Buttons.SecondaryDark.Disabled.Bg};

        ${IconContainer} {
          svg {
            --svg-icon-color: ${Colors.FIN.Lt.Buttons.SecondaryDark.Disabled
              .Label};
          }
        }

        ${SpinnerContainer} {
          svg {
            g {
              stroke: ${Colors.FIN.Lt.Buttons.Secondary.Disabled.Label};
            }
          }
        }
      `};
    `};

  ${({ $appearance, disabled }) =>
    $appearance === 'danger-outline' &&
    css`
    color: ${Colors.FIN.Lt.Buttons.ClearDanger.Default.Label};
    border: 1px solid ${Colors.FIN.Lt.Buttons.ClearDanger.Default.Label};
    background-color: ${Colors.FIN.Lt.Buttons.ClearDanger.Default.Bg};

    ${IconContainer} {
      svg {
        --svg-icon-color: ${Colors.FIN.Lt.Buttons.ClearDanger.Default.Label};
      }
    }

    ${SpinnerContainer} {
      svg {
        g {
          stroke: ${Colors.FIN.Lt.Buttons.ClearDanger.Default.Label};
        }
      }
    }

    // interaction states

    ${
      !disabled &&
      css`
        &:hover,
        &:focus {
          color: ${Colors.FIN.Lt.Buttons.ClearDanger.Hover.Label};
          background-color: ${Colors.FIN.Lt.Buttons.ClearDanger.Hover.Bg};

          ${IconContainer} {
            svg {
              --svg-icon-color: ${Colors.FIN.Lt.Buttons.ClearDanger.Hover
                .Label};
            }
          }

          ${SpinnerContainer} {
            svg {
              g {
                stroke: ${Colors.FIN.Lt.Buttons.ClearDanger.Hover.Label};
              }
            }
          }
        }

        &:active,
        &.active,
        &[data-state='open'] {
          color: ${Colors.FIN.Lt.Buttons.ClearDanger.Pressed.Label};
          background-color: ${Colors.FIN.Lt.Buttons.ClearDanger.Pressed.Bg};

          ${IconContainer} {
            svg {
              --svg-icon-color: ${Colors.FIN.Lt.Buttons.ClearDanger.Pressed
                .Label};
            }
          }

          ${SpinnerContainer} {
            svg {
              g {
                stroke: ${Colors.FIN.Lt.Buttons.ClearDanger.Pressed.Label};
              }
            }
          }
        }
      `
    };

    ${
      disabled &&
      css`
      color: ${Colors.FIN.Lt.Buttons.ClearDanger.Disabled.Label};
      background-color: ${Colors.FIN.Lt.Buttons.ClearDanger.Disabled.Bg};

      ${IconContainer} {
        svg {
          --svg-icon-color: ${Colors.FIN.Lt.Buttons.ClearDanger.Disabled.Label};
        }
      }

      ${SpinnerContainer} {
        svg {
          g {
            stroke: ${Colors.FIN.Lt.Buttons.ClearDanger.Disabled.Label};
          }
        }
      `
    };
    }
  `}

  ${({ $appearance, disabled }) =>
    $appearance === 'danger' &&
    css`
      color: ${Colors.FIN.Lt.Buttons.Danger.Default.Label};
      background-color: ${Colors.FIN.Lt.Buttons.Danger.Default.Bg};

      ${IconContainer} {
        svg {
          --svg-icon-color: ${Colors.FIN.Lt.Buttons.Danger.Default.Label};
        }
      }

      ${SpinnerContainer} {
        svg {
          g {
            stroke: ${Colors.FIN.Lt.Buttons.Danger.Default.Label};
          }
        }
      }

      // interaction states

      ${!disabled &&
      css`
        &:hover,
        &:focus {
          color: ${Colors.FIN.Lt.Buttons.Danger.Hover.Label};
          background-color: ${Colors.FIN.Lt.Buttons.Danger.Hover.Bg};

          ${IconContainer} {
            svg {
              --svg-icon-color: ${Colors.FIN.Lt.Buttons.Danger.Hover.Label};
            }
          }

          ${SpinnerContainer} {
            svg {
              g {
                stroke: ${Colors.FIN.Lt.Buttons.Danger.Hover.Label};
              }
            }
          }
        }

        &:active,
        &.active,
        &[data-state='open'] {
          color: ${Colors.FIN.Lt.Buttons.Danger.Pressed.Label};
          background-color: ${Colors.FIN.Lt.Buttons.Danger.Pressed.Bg};

          ${IconContainer} {
            svg {
              --svg-icon-color: ${Colors.FIN.Lt.Buttons.Danger.Pressed.Label};
            }
          }

          ${SpinnerContainer} {
            svg {
              g {
                stroke: ${Colors.FIN.Lt.Buttons.Danger.Pressed.Label};
              }
            }
          }
        }
      `};

      ${disabled &&
      css`
        color: ${Colors.FIN.Lt.Buttons.Danger.Disabled.Label};
        background-color: ${Colors.FIN.Lt.Buttons.Danger.Disabled.Bg};

        ${IconContainer} {
          svg {
            --svg-icon-color: ${Colors.FIN.Lt.Buttons.Danger.Disabled.Label};
          }
        }

        ${SpinnerContainer} {
          svg {
            g {
              stroke: ${Colors.FIN.Lt.Buttons.Danger.Disabled.Label};
            }
          }
        }
      `};
    `};

  ${({ $size, $iconLeft, $iconRight }) =>
    $size === 'xsmall' &&
    css`
      ${Typography.Label12.M500};

      line-height: 1;

      height: 24px;

      padding: 4px 6px;

      ${$iconLeft &&
      css`
        padding-left: 2px;
      `};

      ${$iconRight &&
      css`
        padding-right: 2px;
      `};

      ${IconContainer} {
        ${({ isRight }) =>
          isRight ? 'margin-left: 3px' : 'margin-right: 3px'};
      }
    `};

  ${({ $size, $iconLeft, $iconRight }) =>
    $size === 'small' &&
    css`
      ${Typography.Label12.M500};

      line-height: 1;

      height: 32px;

      padding: 8px 12px;

      ${$iconLeft &&
      css`
        padding-left: 8px;
      `};

      ${$iconRight &&
      css`
        padding-right: 8px;
      `};
    `};

  ${({ $size, $iconLeft, $iconRight }) =>
    $size === 'medium' &&
    css`
      ${Typography.Label14.M500};

      line-height: 1;

      height: 40px;

      padding: 10px 16px;

      ${$iconLeft &&
      css`
        padding-left: 10px;
      `};

      ${$iconRight &&
      css`
        padding-right: 10px;
      `}
    `};

  ${({ $size, $iconLeft, $iconRight }) =>
    $size === 'large' &&
    css`
      ${Typography.Label14.M500};

      line-height: 1;

      height: 44px;

      padding: 12px 18px;

      ${$iconLeft &&
      css`
        padding-left: 12px;
      `};

      ${$iconRight &&
      css`
        padding-right: 12px;
      `};
    `};

  ${({ $size, $iconLeft, $iconRight }) =>
    $size === 'xlarge' &&
    css`
      ${Typography.Label18.M500};

      line-height: 1;

      height: 50px;

      padding: 15px 20px;

      ${$iconLeft &&
      css`
        padding-left: 15px;
      `};

      ${$iconRight &&
      css`
        padding-right: 15px;
      `};
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `};

  .icon-chevron-down {
    position: relative;
    top: 0;
  }
`;
