import { readOnlyMapping } from './constants';

export const convertToReadOnlyPath = (url: string) => {
  let readOnlyUrl: string | undefined = undefined;

  Object.entries(readOnlyMapping).forEach(([original, replacement]) => {
    if (!readOnlyUrl && url.includes(`v3/${original}`)) {
      readOnlyUrl = url.replace(`v3/${original}`, `v3/${replacement}`);
    }
  });

  return readOnlyUrl || url;
};
