import { connect } from 'react-redux';
import {
  hideSidePanel,
  showPhaseSidePanel,
  showProjectSidePanel,
} from 'sidePanel/actions';
import { SidePanelState } from 'sidePanel/types';

const mapStateToProps = ({ sidePanel }: { sidePanel: SidePanelState }) => {
  return {
    sidePanelOpen: sidePanel.open,
    panelType: sidePanel.openPanels.at(-1)?.panelType,
  };
};

const mapDispatchToProps = {
  hideSidePanel,
  showPhaseSidePanel,
  showProjectSidePanel,
};

export const sidePanelConnect = connect(mapStateToProps, mapDispatchToProps);
