import React from 'react';

import { StyledNoRows } from '../styles';

const NoRowsRow = ({ recordNames, emptyMessage }) => {
  return (
    <StyledNoRows key="no-rows-row">
      {emptyMessage || <td colSpan={100}>There are no {recordNames[1]}.</td>}
    </StyledNoRows>
  );
};

export default NoRowsRow;
