import React, { Component } from 'react';
import modalManagerHoc from 'modalManager/modalManagerHoc';

const displayBlockStyle = { display: 'block' };
class NoSession extends Component {
  hide() {
    this.props.manageModal({ visible: false, modalType: 'noSessionModal' });
  }
  prevent(evt) {
    evt.preventDefault();
    return evt.stopPropagation();
  }
  refresh() {
    return window.location.reload();
  }
  goToLogin() {
    return window.location.replace('/login');
  }
  render() {
    return (
      <div
        style={{ zIndex: 99999 }}
        className="popup-outer display-fixed"
        onClick={this.prevent}
      >
        <div
          style={displayBlockStyle}
          className="popup-upgrade popup dialog"
          onClick={this.prevent}
        >
          <header className="upgrade-header">
            <h1>Session Expired</h1>
          </header>
          <div className="field-group">
            Are you still there? To make sure you're viewing the latest
            information please reload the page.
          </div>
          <footer>
            <button className="inputButton green" onClick={this.refresh}>
              Reload
            </button>
            <button className="inputButton" onClick={this.goToLogin}>
              Sign out
            </button>
          </footer>
        </div>
      </div>
    );
  }
}

export default modalManagerHoc({
  Comp: NoSession,
  modalType: 'noSessionModal',
});
