import styled from 'styled-components';

import * as Animation from '@float/ui/deprecated/Earhart/Animation';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import { IconPlay } from '@float/ui/deprecated/Earhart/Icons';

export const StyledButton = styled.button<{
  notAllowed?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;

  border-radius: 36px;

  background-color: ${Colors.Core.Icon.Default};

  transition-property: background-color, opacity;
  transition-duration: ${Animation.Settings.Duration.Short};
  transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

  &:hover,
  &:focus {
    opacity: 0.75;
  }

  &:active {
    opacity: 0.5;
  }

  &:disabled {
    opacity: 1;
    background-color: ${Colors.FIN.Lt.Buttons.Primary.Disabled.Label};
    cursor: ${({ notAllowed }) => (notAllowed ? 'not-allowed' : 'default')};
  }

  &:focus-visible {
    outline: 1px solid ${Colors.FIN.Lt.Emphasis.Primary};
  }

  svg {
    --svg-icon-color: #fff;

    path {
      stroke-width: 1.5;
      shape-rendering: optimizeSpeed;
    }
  }
`;

export const StyledIconPlay = styled(IconPlay)`
  // compensate so the play icon looks centred to the eye
  margin-left: 3px;
`;
