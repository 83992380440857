import React from 'react';

export default function ({ size = '24', color = '#868D92' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      className="icon-activity"
      focusable={false}
    >
      <g fill="none" fillRule="evenodd">
        <path fill={color} d="M13 2L6 14h6v8l7-12h-6z" />
      </g>
    </svg>
  );
}
