import React from 'react';

import { getDisplayNotesExcerpt } from '@float/common/lib/notes';
import { displayHoursFormatIsTime } from '@float/common/lib/timer/displayHoursFormat';
import { formatDecimalHoursAsClockTime } from '@float/common/lib/timer/formatDecimalHoursAsClockTime';
import { SerenaState } from '@float/common/selectors/serena';
import { config } from '@float/libs/config';
import { CellItem } from '@float/types';

import { isArchived } from './box/utils/isArchived';

import * as styles from './LoggedTimeItemTooltip.css';

export default function getTooltipContent(
  item: CellItem<'loggedTime'>,
  reduxData: SerenaState,
) {
  const { entity } = item;
  const elements = [];

  const project = reduxData.projects[entity.project_id];
  const phase = entity.phase_id ? reduxData.phases[entity.phase_id] : undefined;

  let clientElement = null;
  if (project) {
    clientElement =
      project.client_name !== 'No Client' ? project.client_name : null;
    if (isArchived(project, phase)) {
      if (clientElement) {
        clientElement += ' (Archived)';
      } else {
        clientElement = '(Archived)';
      }
    }
  }
  if ('name' in entity && entity.name) {
    elements.push(entity.name);
  } else if (phase && phase.phase_name) {
    elements.push(phase.phase_name);
  }
  if (project && project.project_name) elements.push(project.project_name);
  if (clientElement) elements.push(clientElement);
  const shouldDisplayAsTime =
    config.isNativeTimerApp || displayHoursFormatIsTime(reduxData.user.prefs);

  const hoursStr = shouldDisplayAsTime
    ? formatDecimalHoursAsClockTime(entity.hours)
    : `${entity.hours}h`;

  return (
    <div
      className={styles.bottomHoverTooltip}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className={styles.tooltipLeft}>
        {elements.join(' / ')}
        {entity.notes && entity.notes.length && (
          <p className={styles.tooltipLeftContent}>
            {getDisplayNotesExcerpt({ notes: entity.notes })}
          </p>
        )}
      </div>
      <div className={styles.tooltipRight}>
        <div className={styles.tooltipHours}>{hoursStr}</div>
      </div>
    </div>
  );
}
