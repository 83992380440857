import * as amplitude from '@amplitude/analytics-browser';

// TODO: Consolidate these functions into a Analytics service
// https://linear.app/float-com/issue/PI-356/implement-a-configurable-analytics-service

export const trackEvent = (
  event: string,
  property: Record<string, unknown> | undefined,
) => {
  amplitude.track(event, property);
};

export const updateUserTrackingData = (
  key: string,
  value: string | number | boolean,
) => {
  const identifyEvent = new amplitude.Identify();

  identifyEvent.set(key, value);

  amplitude.identify(identifyEvent);
};
