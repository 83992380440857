import cachedFetch from '../cachedFetch';

export const fetchWrapper = ({
  url,
  options,
  cache = false,
}: {
  url: string;
  options?: RequestInit;
  cache?: boolean;
}) => {
  const fetchFn = cache ? cachedFetch : fetch;

  return fetchFn(url, options);
};
