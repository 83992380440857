import React, { useId } from 'react';
import { Controller, ControllerRenderProps } from 'react-hook-form';

import { MultiSelect } from '@float/ui/deprecated';

import { ProjectFormData } from '../../types';
import { FormField } from '../FormFieldsSection';

type MultiSelectFields = 'project.tags';

export type ComboboxFieldProps<N extends MultiSelectFields> = {
  name: N;
  label: string;
  options: { value: string; label: string }[];
  mapSelectedOptions: (value: string[]) => {
    value: string;
    label: string;
  }[];
  creatable?: boolean;
  onCreate?: (value: string) => void;
};

export function MultiSelectField<N extends MultiSelectFields>(
  props: ComboboxFieldProps<N>,
) {
  const id = useId();

  const getHandleAdd =
    (field: ControllerRenderProps<ProjectFormData, N>) =>
    (option: { value: string; isCreate?: boolean }) => {
      field.onChange(field.value.concat(option.value));
      if (option.isCreate && props.onCreate) {
        props.onCreate(option.value);
      }
    };
  const getHandleRemove =
    (field: ControllerRenderProps<ProjectFormData, N>) =>
    (option: { value: string }) => {
      field.onChange(field.value.filter((value) => option.value !== value));
    };

  return (
    <FormField label={props.label} htmlFor={id}>
      <Controller<ProjectFormData, N>
        name={props.name}
        render={({ field }) => (
          // TODO Create a new combobox component
          // https://linear.app/float-com/issue/FT-2003/spike-define-a-strategy-the-new-combobox-variants
          <MultiSelect
            creatable={props.creatable}
            visibleItems={6}
            options={props.options}
            values={props.mapSelectedOptions(field.value)}
            onAdd={getHandleAdd(field)}
            onRemove={getHandleRemove(field)}
            inputId={id}
            hideLabel
          />
        )}
      />
    </FormField>
  );
}
