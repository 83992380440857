import { createSelector } from 'reselect';

import { ReduxStateStrict as ReduxState } from '@float/common/reducers/lib/types';
import { BaseFilterToken } from '@float/types/view';

import { getViewById } from './views';

export const isViewApplied = (state: ReduxState) =>
  Boolean(state?.views?.appliedViewId);

export const getCurrentViewId = (state: ReduxState) =>
  state?.views?.appliedViewId ?? null;

export const getCurrentView = (state: ReduxState) => {
  const currentViewId = getCurrentViewId(state);

  if (!currentViewId) return null;

  return getViewById(state, currentViewId) ?? null;
};

const EMPTY_FILTERS: BaseFilterToken[] = [];

export const getCurrentViewFilters = (state: ReduxState) => {
  const currentView = getCurrentView(state);

  return currentView ? currentView.filters : EMPTY_FILTERS;
};

export const getCurrentViewContainsMeFilter = createSelector(
  [getCurrentViewFilters],
  (filters) => {
    return filters.some((filter) => filter.type === 'me');
  },
);
