// Check if two date ranges are intersecting
export function getAreDatesOverlapping(
  startDateA: string,
  endDateA: string,
  startDateB: string,
  endDateB: string,
) {
  // Check if a starts before b ends and b starts before a ends
  return startDateA <= endDateB && startDateB <= endDateA;
}
