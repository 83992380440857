import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { get, includes, isEmpty, reduce, sortBy, uniq, without } from 'lodash';
import styled from 'styled-components';

import API3 from '@float/common/api3';
import Loader from '@float/common/components/elements/Loader';
import PersonAvatar from '@float/common/components/elements/PersonAvatar';
import { moment } from '@float/libs/moment';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import {
  Button,
  Checkbox,
  EH,
  ErrorText,
  Input,
  Modal,
  Tab,
  Theme,
  useSnackbar,
  VirtualSelect,
  withConfirm,
} from '@float/ui/deprecated';
import { required, useInput } from '@float/ui/deprecated/helpers/formHooks';
import { ScrollableList } from '@float/web/components/modals/ProjectModal/ProjectForm/TeamFragment.styles';
import { getPeopleMap } from '@float/web/selectors';

import { PublicHolidaysActionsMenu } from './PublicHolidaysActionsMenu';

const defaultRegion = { label: 'New York', value: 'US-NY' };

const personModalHeaderStyle = {
  paddingBottom: 0,
  paddingRight: 32,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
};

const NameInputContainer = styled.div`
  flex-basis: 100%;
  padding-right: 72px;

  input {
    width: 100%;
  }
`;

const HolidayItem = styled.li`
  ${EH.Typography.TextM.R400};

  display: flex;
  align-items: center;

  color: ${EH.Colors.FIN.Lt.Emphasis.Medium};

  border-bottom: 1px solid ${EH.Colors.FIN.Lt.Stroke.Stroke2};

  line-height: 42px;

  .holiday-checkbox {
    width: 29px;

    & > div {
      height: 30px;
    }
  }

  &.holiday-header,
  &.custom-holiday-header {
    margin-top: -6px;
    font-weight: 500;
    letter-spacing: 0.12px;

    .holiday-name {
      padding-left: 0;
      margin-right: 38px;
    }
  }

  &.custom-holiday-header {
    margin-top: 15px;
  }

  &.custom-holiday {
    cursor: pointer;
  }

  .holiday-name {
    color: ${EH.Colors.FIN.Lt.Emphasis.High};

    cursor: pointer;
    padding: 0 9px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    flex-grow: 1;
  }
  .year-1,
  .year-2 {
    align-self: flex-start;
  }
  .year-1 {
    min-width: 112px;
  }
  .year-2 {
    min-width: 85px;
  }
`;

function CountrySelector({ options, country, setCountry }) {
  return (
    <>
      <div
        style={{
          width: '48%',
        }}
      >
        <VirtualSelect
          nonNullable
          visibleItems={6}
          label="Country"
          value={country.value}
          options={options}
          clearInputOnDropdownOpen={false}
          ellipsis
          onChange={(val) => {
            if (val.value !== country.value) {
              setCountry(val);
            }
          }}
        />
      </div>
    </>
  );
}

function RegionSelector({ options, region, setRegion, setName }) {
  return (
    <>
      <div
        style={{
          width: '48%',
        }}
      >
        <VirtualSelect
          nonNullable
          visibleItems={6}
          label="Region"
          value={region.value}
          options={options}
          clearInputOnDropdownOpen={false}
          ellipsis
          onChange={(val) => {
            if (val.value !== region.value) {
              setRegion(val);
              setName(val.label);
            }
          }}
        />
      </div>
    </>
  );
}

const avatarProps = {
  readOnly: true,
  size: 'xs',
};

function getPeopleByDept(people, team) {
  const peopleByDept = sortBy(
    reduce(
      people,
      (acc, p) => {
        if (!p.active || team.includes(p.people_id)) return acc;

        const dept = get(p, 'department.name', 'No department');
        const deptId = get(p, 'department.department_id', 0);

        if (!acc[dept]) {
          acc[dept] = {
            name: dept,
            value: deptId,
            options: [],
          };
        }

        acc[dept].options.push({
          value: p.people_id,
          label: p.name,
          icon: <PersonAvatar personId={p.people_id} {...avatarProps} />,
        });

        return acc;
      },
      {},
    ),
    (g) => g.name.toLowerCase(),
  );

  peopleByDept.forEach((dept) => {
    dept.options = sortBy(dept.options, (o) => o.label.toLowerCase());
  });

  if (peopleByDept.length) {
    peopleByDept.unshift({
      name: '',
      value: -1,
      options: [
        {
          label: 'All team members',
        },
      ],
    });
  }
  return peopleByDept;
}

function formatHolidayDate(holidayDates) {
  const dates = (
    Array.isArray(holidayDates) ? holidayDates : [holidayDates]
  ).filter(Boolean);
  if (dates.length) {
    return dates.map((d) => {
      const dateStr = moment(d).format('ddd, DD MMM');
      return <div key={dateStr}>{dateStr}</div>;
    });
  }
  return null;
}

export function findLocation(countries, countryCode, regionCode, regionName) {
  const country = countries.find((c) => c.code === countryCode);
  const region = country.regions.find((location) => {
    if (location.value === regionCode) return true;
    if (location.label === regionName) {
      // fix for stale location codes
      // https://app.asana.com/0/388486760637802/1201052355427786/f
      location.value = regionCode;
      return true;
    }
    return false;
  });
  return [country, region];
}

function PublicHolidaysModalComp({
  people,
  confirm,
  onClose,
  setCustomHolidayModalProps,
  ...regionProps
}) {
  const editingId = regionProps.region_id;
  const [activeTab, setActiveTab] = useState('holidays');
  const { countries, defaultCountry, defaultRegions } = regionProps;
  let foundCountry = defaultCountry;
  let foundRegion = defaultRegion;
  let foundRegions = defaultCountry.regions;

  if (regionProps.country_code && regionProps.location_code) {
    [foundCountry, foundRegion] = findLocation(
      countries,
      regionProps.country_code,
      regionProps.location_code,
      regionProps.name,
    );
    foundRegions = foundCountry.regions;
  }

  const { showSnackbar } = useSnackbar();
  const [country, setCountry] = useState(foundCountry || defaultCountry);
  const [region, setRegion] = useState(foundRegion || defaultRegion);
  const [regions, setRegions] = useState(foundRegions || defaultRegions);
  const [team, setTeam] = useState(regionProps.people_ids || []);
  const [holidays, setHolidays] = useState();
  const [customHolidays, setCustomHolidays] = useState();
  const [selectedHolidays, setSelectedHolidays] = useState(
    regionProps.holiday_ids || [],
  );
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [holidaysErrors, setHolidaysErrors] = useState([]);
  const [peopleErrors, setPeopleErrors] = useState([]);
  const name = useInput(regionProps.name || region.label || '', {
    validate: required('Please add a name.'),
  });
  const peopleByDept = getPeopleByDept(people, team);

  const thisYear = moment().format('YYYY');
  const nextYear = moment().add(1, 'year').format('YYYY');

  function setName(value) {
    name.onChange({ target: { value } });
  }

  function setCountryAndRegions(newCountry) {
    setCountry(newCountry);
    setRegions(newCountry.regions);
    const selectedRegion = newCountry.regions[0];
    setRegion(selectedRegion);
    setName(selectedRegion.label);
  }

  async function fetchHolidays() {
    setLoading(true);

    const allHolidayArr = await API3.getRegionalHolidays(
      country.value,
      region.value,
    );
    const holidayArr = sortBy(
      allHolidayArr.filter(
        (h) => h.type !== 'custom', // && (h[thisYear].length || h[nextYear].length),
      ),
      (h) => {
        const firstDateThisYear = get(h, `[${thisYear}][0]`, '');
        const firstDateNextYear = get(h, `[${nextYear}][0]`, '');
        const part1 =
          firstDateThisYear ||
          firstDateNextYear?.replace(nextYear, thisYear) ||
          'z';
        const part2 =
          firstDateNextYear ||
          firstDateThisYear?.replace(thisYear, nextYear) ||
          'z';
        return `${part1}${part2}`;
      },
    );
    const customHolidayArr = allHolidayArr.filter((h) => h.type === 'custom');

    setLoading(false);
    setHolidays(holidayArr);

    if (typeof customHolidays === 'undefined') {
      setCustomHolidays(customHolidayArr);
    }

    // Select all holidays by default in a new region
    const matchesInitialLocation =
      foundCountry.value === country.value &&
      foundRegion.value === region.value;

    // If new holiday or location different from editing modal
    let newSelectedHolidays;
    if (!editingId || !matchesInitialLocation) {
      // select all holidays for the new location
      newSelectedHolidays = holidayArr
        .filter((h) => h.type !== 'Local holiday')
        .map((h) => h.region_holiday_id);
    } else {
      newSelectedHolidays = regionProps.holiday_ids;
    }

    const previouslyCheckedCustomHolidays = selectedHolidays.filter((id) =>
      (customHolidays || []).some((ch) => ch.region_holiday_id === id),
    );

    setSelectedHolidays([
      ...newSelectedHolidays,
      ...previouslyCheckedCustomHolidays,
    ]);
  }

  function handleDelete() {
    confirm({
      title: 'Delete public holidays',
      message: `Are you sure?`,
      onConfirm: () => {
        API3.deleteRegionalHolidays(editingId).then(() => {
          onClose();
          showSnackbar(`${name.value} deleted.`);
        });
      },
    });
  }

  useEffect(() => {
    setHolidays();
    fetchHolidays();
  }, [region, country]); // eslint-disable-line

  useEffect(() => {
    if (team.length && peopleErrors.length) {
      setPeopleErrors([]);
    }

    if (selectedHolidays.length && holidaysErrors) {
      setHolidaysErrors([]);
    }
  }, [team, selectedHolidays]); // eslint-disable-line

  function addTeamMember(selected) {
    if (selected.groupValue === -1) {
      // User chose to add ALL people
      setTeam(Object.keys(people).map(Number));
      return;
    }
    setTeam([...team, selected.value]);
  }

  function addDepartment(groupOptions) {
    if (groupOptions.length) {
      setTeam(uniq([...team, ...groupOptions.map((go) => Number(go.value))]));
    }
  }

  function removeTeamMember(selected) {
    setTeam(without(team, selected.people_id));
  }

  function validate() {
    let isValid = true;
    let message = null;

    if (!team.length) {
      message = 'Please add at least one person.';
      setPeopleErrors([message]);
      isValid = false;
    }
    if (!selectedHolidays.length) {
      message = 'Please select at least one holiday.';
      setHolidaysErrors([message]);
      isValid = false;
    }

    if (message) {
      showSnackbar(message);
    }

    return isValid;
  }

  function formatCustomHolidayPayload(item) {
    return {
      region_holiday_id: String(item.region_holiday_id).includes('.')
        ? undefined
        : item.region_holiday_id,
      name: item.name,
      dates: {
        [thisYear]: item[thisYear],
        [nextYear]: item[nextYear],
      },
    };
  }

  async function addCustomHolidays(regionId, toAdd) {
    // returns an object with keys equal to client-side generated ids and
    // values equal to the real ids created by the server
    const res = await Promise.all(
      toAdd.map((e) =>
        API3.createCustomRegionalHoliday(
          regionId,
          formatCustomHolidayPayload(e),
        ),
      ),
    );

    return toAdd.reduce((acc, e, idx) => {
      acc[e.region_holiday_id] = res[idx].region_holiday_id;
      return acc;
    }, {});
  }

  async function updateCustomHolidays(regionId, toUpdate) {
    return Promise.all(
      toUpdate.map((e) =>
        API3.updateCustomRegionalHoliday(
          regionId,
          formatCustomHolidayPayload(e),
        ),
      ),
    );
  }

  async function deleteCustomHolidays(regionId, toDelete) {
    return Promise.all(
      toDelete.map((e) =>
        API3.deleteCustomRegionalHoliday(
          regionId,
          formatCustomHolidayPayload(e),
        ),
      ),
    );
  }

  async function submit(e) {
    e.preventDefault();

    if (saving) return;
    if (!validate()) return;

    const customHolidaysPayloads = {
      toAdd: [],
      toUpdate: [],
      toDelete: [],
    };

    customHolidays.forEach((ch) => {
      // Temporary client-side generated IDs will always have a dot in them
      if (String(ch.region_holiday_id).includes('.')) {
        if (!ch.isDelete) {
          customHolidaysPayloads.toAdd.push(ch);
        }
        return;
      }

      if (ch.isDelete) {
        customHolidaysPayloads.toDelete.push(ch);
        return;
      }

      if (ch.isUpdate) {
        customHolidaysPayloads.toUpdate.push(ch);
        return;
      }
    });

    let method = 'createRegionalHolidays';

    const data = {
      name: name.value,
      country_code: country.value,
      location_code: region.value,
      people_ids: team,
      holiday_ids: selectedHolidays,
    };

    if (editingId) {
      data.region_id = editingId;
      method = 'updateRegionalHolidays';
    } else {
      // When creating a new region, we bundle the custom holidays directly
      // in the payload and exclude them from holiday_ids. We do this because
      // the endpoints to modify custom holidays require a region_id, which we
      // won't have until we create the region.
      data.holiday_ids = data.holiday_ids.filter(
        (x) => !String(x).includes('.'),
      );
      data.custom = customHolidaysPayloads.toAdd.map((p) => {
        return {
          ...formatCustomHolidayPayload(p),
          active: selectedHolidays.includes(p.region_holiday_id),
        };
      });
    }

    try {
      setSaving(true);

      // If we're updating an existing region, custom holidays go through their
      // own endpoints.
      if (editingId) {
        const [newlyAddedIds] = await Promise.all([
          addCustomHolidays(editingId, customHolidaysPayloads.toAdd),
          updateCustomHolidays(editingId, customHolidaysPayloads.toUpdate),
          deleteCustomHolidays(editingId, customHolidaysPayloads.toDelete),
        ]);

        // We have to make sure that the selected holiday ids array holds the
        // value returned from the create call for any custom holidays.
        data.holiday_ids = data.holiday_ids.map((id) => {
          return String(id).includes('.') ? newlyAddedIds[id] : id;
        });
      }

      await API3[method](data);
      setSaving(false);
      onClose(true);
      showSnackbar(`${name.value} ${editingId ? 'updated' : 'added'}.`);
    } catch (err) {
      setSaving(false);
      if (err.length) {
        setHolidaysErrors(err.map((x) => x.message));
      } else {
        setHolidaysErrors(err);
      }
    }
  }

  const readOnly = false;

  function handleCustomHolidaySubmit(item) {
    setTimeout(() => setCustomHolidayModalProps(null), 0);

    if (item.isDelete) {
      setSelectedHolidays((prev) => without(prev, item.region_holiday_id));
    }

    const existingIdx = customHolidays.findIndex(
      (i) => i.region_holiday_id === item.region_holiday_id,
    );

    if (existingIdx === -1) {
      setSelectedHolidays((prev) => [...prev, item.region_holiday_id]);
      return setCustomHolidays([...customHolidays, item]);
    }

    const newArr = [...customHolidays];
    newArr[existingIdx] = item;
    setCustomHolidays(newArr);
  }

  function handleCustomHolidayAdd() {
    setCustomHolidayModalProps({
      close: () => setCustomHolidayModalProps(null),
      submit: handleCustomHolidaySubmit,
      thisYear,
      nextYear,
    });
  }

  const filteredCustomHolidays =
    customHolidays && customHolidays.filter((ch) => !ch.isDelete);

  return (
    <Modal isOpen onClose={onClose}>
      <form noValidate onSubmit={submit}>
        <Modal.Header style={personModalHeaderStyle}>
          <>
            <NameInputContainer>
              {readOnly ? (
                <Modal.Title style={{ lineHeight: '40px' }}>{name}</Modal.Title>
              ) : (
                <Input
                  {...name}
                  placeholder="Name"
                  autoFocus
                  noBorder
                  size="xlarge"
                  maxLength={125}
                />
              )}
            </NameInputContainer>
            <>
              <Tab
                label="Holidays"
                style={{ minHeight: 54 }}
                active={activeTab === 'holidays'}
                counter={selectedHolidays.length}
                hasError={holidaysErrors.length}
                onClick={() => setActiveTab('holidays')}
              />
              <Tab
                label="People"
                style={{ minHeight: 54 }}
                active={activeTab === 'people'}
                counter={team.length}
                hasError={peopleErrors.length}
                onClick={() => setActiveTab('people')}
              />
            </>
          </>
        </Modal.Header>
        <Modal.Body style={{ minHeight: '400px' }}>
          {activeTab === 'holidays' && (
            <>
              <Modal.TopGreySection>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <CountrySelector
                    options={countries}
                    country={country}
                    setCountry={setCountryAndRegions}
                  />
                  <RegionSelector
                    options={regions}
                    region={region}
                    setRegion={(r) => {
                      setRegion(r);
                    }}
                    setName={(n) => {
                      setName(n);
                    }}
                  />
                </div>
                {!holidaysErrors.length ? null : (
                  <ErrorText>{holidaysErrors[0]}</ErrorText>
                )}
              </Modal.TopGreySection>
              {loading ? (
                <Loader className="text-sm" style={{ marginTop: 0 }} />
              ) : (
                <>
                  <HolidayItem className="holiday-header">
                    <span className="holiday-name">Holiday</span>
                    <span className="year-1">{thisYear}</span>
                    <span className="year-2">{nextYear}</span>
                  </HolidayItem>
                  {holidays &&
                    holidays.map((holiday, i) => {
                      const selected = includes(
                        selectedHolidays,
                        holiday.region_holiday_id,
                      );
                      function toggleHoliday() {
                        if (!selected) {
                          setSelectedHolidays([
                            ...selectedHolidays,
                            holiday.region_holiday_id,
                          ]);
                        } else {
                          setSelectedHolidays(
                            without(
                              selectedHolidays,
                              holiday.region_holiday_id,
                            ),
                          );
                        }
                      }

                      return (
                        <HolidayItem key={holiday.region_holiday_id}>
                          <Checkbox
                            className="holiday-checkbox"
                            onChange={toggleHoliday}
                            value={selected}
                          />
                          <span
                            className="holiday-name"
                            onClick={toggleHoliday}
                          >
                            {holiday.name}
                          </span>
                          <span className="year-1">
                            {formatHolidayDate(holiday[thisYear])}
                          </span>
                          <span className="year-2">
                            {formatHolidayDate(holiday[nextYear])}
                          </span>
                        </HolidayItem>
                      );
                    })}
                  {holidays && holidays.length === 0 && (
                    <Modal.EmptyMessage>
                      No holidays found for this location.
                    </Modal.EmptyMessage>
                  )}
                  {filteredCustomHolidays &&
                    filteredCustomHolidays.length > 0 && (
                      <>
                        <HolidayItem className="custom-holiday-header">
                          <span className="holiday-name">Custom Holiday</span>
                          <span className="year-1">{thisYear}</span>
                          <span className="year-2">{nextYear}</span>
                        </HolidayItem>
                        {filteredCustomHolidays.map((ch, i) => {
                          const selected = includes(
                            selectedHolidays,
                            ch.region_holiday_id,
                          );
                          function toggleHoliday() {
                            if (!selected) {
                              setSelectedHolidays([
                                ...selectedHolidays,
                                ch.region_holiday_id,
                              ]);
                            } else {
                              setSelectedHolidays(
                                without(selectedHolidays, ch.region_holiday_id),
                              );
                            }
                          }
                          return (
                            <HolidayItem
                              key={ch.region_holiday_id}
                              className="custom-holiday"
                              onClick={(evt) => {
                                setCustomHolidayModalProps({
                                  close: () => setCustomHolidayModalProps(null),
                                  submit: handleCustomHolidaySubmit,
                                  thisYear,
                                  nextYear,
                                  region_holiday_id: ch.region_holiday_id,
                                  name: ch.name,
                                  [thisYear]: ch[thisYear],
                                  [nextYear]: ch[nextYear],
                                });
                              }}
                            >
                              <Checkbox
                                className="holiday-checkbox"
                                value={selected}
                                onClick={(evt) => {
                                  prevent(evt);
                                  toggleHoliday();
                                }}
                              />
                              <span className="holiday-name">{ch.name}</span>
                              <span className="year-1">
                                {formatHolidayDate(ch[thisYear])}
                              </span>
                              <span className="year-2">
                                {formatHolidayDate(ch[nextYear])}
                              </span>
                            </HolidayItem>
                          );
                        })}
                      </>
                    )}
                </>
              )}
            </>
          )}
          {activeTab === 'people' && (
            <>
              <Modal.TopGreySection>
                <VirtualSelect
                  hideClearIcon
                  keepFocusAfterSelect
                  label="Add a team member"
                  visibleItems={6}
                  autoFocus
                  groupedOptions={peopleByDept}
                  errors={peopleErrors}
                  onChange={addTeamMember}
                  onSelectGroupAll={addDepartment}
                />
              </Modal.TopGreySection>
              <ScrollableList style={{ marginBottom: 0 }} noTopBorder>
                {team
                  .map((id) => people[id])
                  .map((member) => {
                    return (
                      <li key={member.people_id}>
                        <span className="avatar-image">
                          <PersonAvatar
                            personId={member.people_id}
                            {...avatarProps}
                          />
                        </span>
                        <span className="name">{member.name}</span>
                        <div className="controls">
                          <span
                            onClick={() => {
                              removeTeamMember(member);
                            }}
                            className="icon-close-small"
                          >
                            <EH.Icons.IconClose />
                          </span>
                        </div>
                      </li>
                    );
                  })}
                {isEmpty(team) && (
                  <Modal.EmptyMessage>
                    There are no people assigned to these holidays.
                  </Modal.EmptyMessage>
                )}
              </ScrollableList>
            </>
          )}
        </Modal.Body>
        <Modal.Actions style={{ paddingTop: 35 }}>
          {!loading && (
            <>
              <Button type="submit" loader={saving}>
                {editingId ? 'Update ' : 'Select '}holidays
              </Button>
              <Button
                appearance="secondary"
                style={{ marginRight: 0 }}
                onClick={onClose}
              >
                Cancel
              </Button>
              {editingId ? (
                <PublicHolidaysActionsMenu
                  onDelete={handleDelete}
                  onCustomHolidayAdd={handleCustomHolidayAdd}
                />
              ) : (
                <Button
                  style={{
                    marginLeft: 'auto',
                  }}
                  textColor={Theme.blueGrey}
                  iconRight={EH.Icons.IconPlus}
                  iconRightProps={{ size: 20 }}
                  iconRightVerticalCenter
                  tabIndex="-1"
                  onClick={(evt) => {
                    prevent(evt);
                    setCustomHolidayModalProps({
                      close: () => setCustomHolidayModalProps(null),
                      submit: handleCustomHolidaySubmit,
                      thisYear,
                      nextYear,
                    });
                  }}
                >
                  Add custom holiday
                </Button>
              )}
            </>
          )}
        </Modal.Actions>
      </form>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  people: getPeopleMap(state),
});

export const PublicHolidaysModal = connect(mapStateToProps)(
  withConfirm(PublicHolidaysModalComp),
);
