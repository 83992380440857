import * as LaunchDarkly from 'launchdarkly-js-client-sdk';

import { FloatDeploymentEnvironment } from '@float/constants/environment';
import {
  FeatureFlagChangeHandler,
  FeatureFlagClientAdapter,
} from '@float/libs/featureFlags/types';
import { getFeatureFlagsOverrides } from '@float/libs/web/devtools/featureFlagsOverrides';
import { CurrentUserAuthInfo } from '@float/types/auth';

import { getLaunchDarklyClientId } from './helpers';

const getContext = (
  user: CurrentUserAuthInfo | undefined,
  environment: FloatDeploymentEnvironment,
) => {
  if (user) {
    return {
      kind: 'user',
      key: `${user.cid}-${user.admin_id}`,
      environment,
      name: user.name,
      accountId: user.admin_id,
      accountType: user.account_type_id,
      companyId: user.cid,
    };
  }

  return {
    kind: 'user',
    environment,
    key: 'NONE',
    name: 'NONE',
    accountId: 'NONE',
    accountType: 'NONE',
    companyId: 'NONE',
  };
};

export class LaunchDarklyWebAdapter implements FeatureFlagClientAdapter {
  private client: LaunchDarkly.LDClient;
  private environment: FloatDeploymentEnvironment;

  private _isReady = false;

  public get isReady() {
    return this._isReady;
  }

  constructor(
    user: CurrentUserAuthInfo | undefined,
    environment: FloatDeploymentEnvironment,
    initialize: typeof LaunchDarkly.initialize,
  ) {
    this.environment = environment;

    const context = getContext(user, this.environment);

    this.client = initialize(getLaunchDarklyClientId(environment), context);
  }

  public async initialize() {
    await this.client.waitUntilReady();

    const overrides = getFeatureFlagsOverrides();

    this._isReady = true;

    return { overrides };
  }

  public getFlagVariant(key: string) {
    return this.client.variation(key);
  }

  public async identify(user: CurrentUserAuthInfo) {
    const context = getContext(user, this.environment);
    await this.client.identify(context);
  }

  public addChangeHandler(handler: FeatureFlagChangeHandler) {
    this.client.on('change', handler);
  }

  public removeChangeHandler(handler: FeatureFlagChangeHandler) {
    this.client.off('change', handler);
  }
}
