import React, { ComponentPropsWithRef } from 'react';
import cn from 'classnames';

import * as styles from './styles.css';

export type ModalFooterProps = ComponentPropsWithRef<'footer'>;

export const ModalFooter = (props: ModalFooterProps) => {
  const { children, className, ...attributes } = props;

  return (
    <footer className={cn(styles.footer, className)} {...attributes}>
      {children}
    </footer>
  );
};
