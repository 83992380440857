import { cloneDeep } from 'lodash';

import { GetAction, UseCellsReducerProps, UseCellsState } from './types';

export function linkConfirm(
  props: Pick<UseCellsReducerProps, 'maps'>,
  state: UseCellsState,
  action: GetAction<'LINK_CONFIRM'>,
): UseCellsState {
  const { maps } = props;
  const { cells } = state;

  const { parent, child } = action;

  const parentId = String(parent.entityId);

  const parentEntity = maps[parent.type][parentId];
  const childEntity = maps[child.type][child.entityId];
  const originalChildEntity = cloneDeep(childEntity);

  childEntity.root_task_id = parentEntity
    ? parentEntity.root_task_id || parentEntity.task_id
    : parentId;

  childEntity.parent_task_id = parentId;

  return {
    ...state,
    cells,
    pendingChanges: [],
    changes: [
      ...state.changes,
      {
        type: child.type,
        id: child.entityId,
        entity: childEntity,
        originalEntity: originalChildEntity,
      },
    ],
  };
}

export function linkDelete(
  props: UseCellsReducerProps,
  state: UseCellsState,
  action: GetAction<'LINK_DELETE'>,
): UseCellsState {
  const { maps } = props;
  const { cells } = state;

  const { item } = action;
  const entity = maps[item.type][item.entityId];
  const originalEntity = cloneDeep(entity);

  entity.parent_task_id = null;

  return {
    ...state,
    cells,
    pendingChanges: [],
    changes: [
      ...state.changes,
      {
        type: item.type,
        id: item.entityId,
        entity,
        originalEntity,
      },
    ],
  };
}
