export function toClipboardItem(text: string) {
  return new ClipboardItem({
    'text/plain': Promise.resolve(text).then(
      (text) => new Blob([text], { type: 'text/plain' }),
    ),
  });
}

export async function copyToClipboard(text: string) {
  if (!navigator.clipboard) {
    return false;
  }

  try {
    // Use ClipboardItem if supported. This is needed for Safari.
    if (window.ClipboardItem) {
      await navigator.clipboard.write([toClipboardItem(text)]);
    } else {
      await navigator.clipboard.writeText(text);
    }
    return true;
  } catch (e) {
    return false;
  }
}
