import React from 'react';

export default function () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="#868D92">
        <path d="M10 5.5v13c0 .276.224.5.5.5h3c.276 0 .5-.224.5-.5v-13c0-.276-.224-.5-.5-.5h-3c-.276 0-.5.224-.5.5zM16 9.5v9c0 .276.224.5.5.5h3c.276 0 .5-.224.5-.5v-9c0-.276-.224-.5-.5-.5h-3c-.276 0-.5.224-.5.5zM4 13.5v5c0 .276.224.5.5.5h3c.276 0 .5-.224.5-.5v-5c0-.276-.224-.5-.5-.5h-3c-.276 0-.5.224-.5.5z" />
      </g>
    </svg>
  );
}
