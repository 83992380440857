import React from 'react';
import { t } from '@lingui/macro';

import { formatNumber } from '@float/ui/helpers/number/numberParser';

import { CurrencyInputField } from '../components/CurrencyInputField';

export function BudgetTotalFee({
  isReadOnly,
  readOnlyValue,
}: {
  isReadOnly: boolean;
  readOnlyValue: number | null;
}) {
  const hasReadOnlyValue = typeof readOnlyValue === 'number';

  return (
    <CurrencyInputField
      name="project.budget_total"
      label={t`Total fee`}
      placeholder={t`0`}
      readOnly={isReadOnly}
      valueOverride={hasReadOnlyValue ? formatNumber(readOnlyValue) : ''}
    />
  );
}
