import { fetchMilestones } from '@float/common/api3/milestones';
import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { getAllMilestonesByProjectId } from '@float/common/selectors/milestones';
import { AppDispatchStrict } from '@float/common/store';

import { PROJECT_MILESTONES_LOAD_FINISH } from './types';

export const fetchMilestonesForProject =
  (projectId: number) =>
  async (dispatch: AppDispatchStrict, getState: () => ReduxStateStrict) => {
    const { loadState: currentLoadState } = getState().milestones;

    if (currentLoadState === 'LOADED' || currentLoadState === 'LOADING') {
      return getAllMilestonesByProjectId(getState(), projectId);
    }

    const data = await fetchMilestones({ project_id: projectId });

    dispatch({ type: PROJECT_MILESTONES_LOAD_FINISH, milestones: data });

    return data;
  };
