import React from 'react';

export default function ({ size = '15', color = '#fff' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 15 15"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g stroke={color}>
          <circle strokeWidth="2" cx="6.5" cy="6.5" r="5.5" />
          <path d="M6.5,4 L6.5,9" />
          <path d="M4,6.5 L9,6.5" />
          <path
            d="M10.5,10.5 L13.5,13.5"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </g>
      </g>
    </svg>
  );
}
