import React from 'react';

export default function WarningCircle({ style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      style={style}
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                fill="#E62768"
                fillRule="nonzero"
                d="M2.188 2.188c2.916-2.917 7.69-2.917 10.606 0 2.917 2.916 2.917 7.69 0 10.606-2.917 2.917-7.69 2.917-10.606 0-2.917-2.917-2.917-7.69 0-10.606z"
                transform="translate(-310 -382) translate(310 382) translate(1.51 1.51)"
              />
              <path
                fill="#FFF"
                d="M4.839 7.582L5.9 8.643 10.143 4.4 9.082 3.339z"
                transform="translate(-310 -382) translate(310 382) translate(1.51 1.51) rotate(-45 7.49 5.99)"
              />

              <path
                fill="#FFF"
                d="M7.491 10.18L6.43 11.241 7.491 12.302 8.552 11.241z"
                transform="translate(-310 -382) translate(310 382) translate(1.51 1.51) rotate(-45 7.49 11.24)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
