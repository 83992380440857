import React from 'react';
import * as Select from '@radix-ui/react-select';

import { IconCheck } from '@float/ui/icons/essentials/IconCheck';

import { SelectableOptionProps } from './types';

import * as styles from './styles.css';

const SELECTION_KEYS = ['Enter', ' '];

export function Option(props: SelectableOptionProps) {
  const { value, label, icon, onSelect } = props;

  const handlePointerUp = React.useCallback(() => {
    onSelect?.(value);
  }, [value, onSelect]);

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (SELECTION_KEYS.includes(event.key)) {
        onSelect?.(value);
      }
    },
    [value, onSelect],
  );

  return (
    <Select.Item
      className={styles.item}
      value={value}
      onPointerUp={handlePointerUp}
      onKeyDown={handleKeyDown}
    >
      {icon && <span className={styles.iconLeft}>{icon}</span>}

      <span className={styles.itemContent}>
        <Select.ItemText>{label}</Select.ItemText>
      </span>

      <Select.ItemIndicator className={styles.iconRight}>
        <IconCheck />
      </Select.ItemIndicator>
    </Select.Item>
  );
}
