import manageModal from 'modalManager/manageModalActionCreator';

import { createLoggedTime } from '@float/common/actions/loggedTimes';
import request from '@float/common/lib/request';

export async function showLogTimeEditModal(reduxDispatch, id) {
  const entity = await request.get(`logged-time/${id}`, undefined, {
    version: 'f3',
  });

  if (!entity) throw Error(`Unable to find logged time with id ${id}`);

  reduxDispatch(
    manageModal({
      visible: true,
      modalType: 'logTimeModal',
      modalSettings: {
        entity,
      },
    }),
  );
}

export async function onUpdateLoggedTime(reduxDispatch, payload) {
  if (payload.length !== 1 || !payload[0].entity) {
    console.error('Unexpected payload', payload);
    throw Error('Unexpected payload');
  }

  const { entity } = payload[0];

  const loggedTime = {
    logged_time_id: entity.logged_time_id,
    reference_date: entity.reference_date,
    people_id: entity.people_id,
    phase_id: entity.phase_id,
    project_id: entity.project_id,
    billable: entity.billable,
    priority: entity.priority,
    date: entity.date,
    hours: Number(entity.hours),
    task_id: entity.task_id,
    task_name: entity.task_name,
    notes: entity.notes,
  };

  return reduxDispatch(createLoggedTime(loggedTime));
}
