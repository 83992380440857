import React from 'react';
import { Trans } from '@lingui/macro';

import { SidePanelTableHeaderRow } from '../../components/SidePanelTableSection/SidePanelTableHeaderRow';
import { SidePanelTableHeading } from '../../components/SidePanelTableSection/SidePanelTableHeading';

import * as styles from '../ProjectMilestonesSection/styles.css';

type TemplateMilestonesTableHeaderProps = {
  showingDuration: boolean;
};

export function TemplateMilestonesTableHeader(
  props: TemplateMilestonesTableHeaderProps,
) {
  return (
    <SidePanelTableHeaderRow
      className={styles.templateMilestoneGridTemplateColumns}
    >
      <SidePanelTableHeading>
        <Trans>Milestone</Trans>
      </SidePanelTableHeading>
      <SidePanelTableHeading>
        <Trans>Start after</Trans>
      </SidePanelTableHeading>
      <SidePanelTableHeading>
        {props.showingDuration && (
          <span data-testid="milestone-duration-header">
            <Trans>Duration</Trans>
          </span>
        )}
      </SidePanelTableHeading>
    </SidePanelTableHeaderRow>
  );
}
