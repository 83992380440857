import { NavIconBtnProps } from './types';

export function getPaddingVariant(
  props: Pick<NavIconBtnProps, 'icon' | 'iconLeft' | 'iconRight'>,
) {
  if (props.icon) {
    return 'none' as const;
  }

  if (props.iconRight && props.iconLeft) {
    return 'bothIcons' as const;
  }

  if (props.iconRight && !props.iconLeft) {
    return 'rightIcon' as const;
  }

  if (props.iconLeft && !props.iconRight) {
    return 'leftIcon' as const;
  }

  return 'noIcons' as const;
}
