export const sanitizeFetchedPerson = (response, fullState) => {
  const person = { ...response };

  if (person.work_days_hours != null) {
    if (!Array.isArray(person.work_days_hours)) {
      person.work_days_hours = JSON.parse(person.work_days_hours);
    }
    person.work_days_hours = person.work_days_hours.map(Number);
  }

  if (person.work_days_hours_history) {
    delete person.work_days_hours;
    person.work_days_hours_history = Object.keys(person.work_days_hours_history)
      .sort()
      .reverse()
      .map((k) => [k, person.work_days_hours_history[k].map(Number)]);
  }

  if (typeof person.department !== 'undefined') {
    if (person.department && person.department.department_id) {
      person.department_id = person.department.department_id;
    } else {
      person.department_id = null;
    }
    delete person.department;
  }

  if (person.role_id) {
    person.role = {
      role_id: person.role_id,
    };
  }

  // TODO APA: This should be enforced on the back-end
  if (person.account_id) {
    person.account_id = Number(person.account_id);
  }

  // TODO APA: This should be enforced on the back-end
  if (typeof person.name !== 'string') {
    person.name = String(person.name);
  }

  // TODO APA: This should be enforced on the back-end
  if (person.tags?.length) {
    person.tags.forEach((tag) => {
      if (typeof tag.name !== 'string') {
        tag.name = String(tag.name);
      }
    });
  } else {
    // try to merge tags from previous state
    if (fullState) {
      const prevPerson = fullState.people?.people?.[person.people_id];
      if (prevPerson?.tags?.length) {
        person.tags = [...prevPerson.tags];
      }
    }

    if (!person.tags?.length) {
      person.tags = [];
    }
  }

  return person;
};
