import { LastAllocatedTaskHistoryEntry } from '@float/common/reducers/lastAllocatedTask';
import { getLastAllocatedTaskForProject } from '@float/common/selectors/lastAllocatedTask';
import { TaskMetaOption } from '@float/common/selectors/taskMetas';

export type EditTaskModalProps = {
  lastAllocatedTaskHistory: LastAllocatedTaskHistoryEntry[];
  modalSettings: {
    isNewTask: boolean;
  };
};

export type EditTaskModalState = {
  peopleIds: number[];
  initialHours: number;
};

export function handleTaskMetasUpdate(
  taskMetas: TaskMetaOption[],
  projectId: number,
  phaseId: number | undefined,
  props: EditTaskModalProps,
  state: EditTaskModalState,
) {
  if (!props.modalSettings.isNewTask) {
    return {
      taskName: '',
      taskMetaId: null,
    };
  }

  const taskMetaId = getLastAllocatedTaskForProject(
    props.lastAllocatedTaskHistory,
    projectId,
    phaseId || 0,
    state.peopleIds,
  );

  const taskMeta = taskMetas.find((meta) => meta.task_meta_id === taskMetaId);
  const nonBillable = taskMeta?.billable === 0;

  return {
    nonBillable,
    initialTotalHours: nonBillable ? 0 : state.initialHours,
    taskName: taskMeta?.task_name || '',
    taskMetaId: taskMeta?.task_meta_id || null,
  };
}
