import API3 from '@float/common/api3';
import { AppDispatchStrict } from '@float/common/store';
import { logger } from '@float/libs/logger';

import { TIMER_FETCH_SUCCESS } from './constants';

export function ensureActiveTimerLoaded() {
  return async (dispatch: AppDispatchStrict) => {
    try {
      const activeTimers = await API3.getActiveTimers();

      dispatch({
        type: TIMER_FETCH_SUCCESS,
        shouldRefresh: false,
        timers: activeTimers,
      });
    } catch (e) {
      logger.error('Failed to fetch active timers', e);
    }
  };
}
