import {
  isArray,
  isDate,
  isEmpty,
  isNumber,
  keyBy,
  mapValues,
  orderBy,
} from 'lodash';

import { FETCH_USER_SUCCESS } from '@float/common/actions/currentUser';
import {
  PROJECTS_BULK_DELETED,
  PROJECTS_BULK_UPDATED,
  PROJECTS_DELETED,
  PROJECTS_UPDATED,
} from '@float/common/actions/projects/actionTypes';
import { TOGGLE_ACTIVITY_FEED } from '@float/web/activity/actions';

import {
  CHANGE_FILTER,
  CHANGE_SORT,
  FILTER_TYPES,
  INTEGRATIONS_LOAD_BEGIN,
  INTEGRATIONS_LOAD_FAILURE,
  INTEGRATIONS_LOAD_SUCCESS,
  ITEMS_LIVE_UPDATE,
  ITEMS_LOAD_BEGIN,
  ITEMS_LOAD_FAILURE,
  ITEMS_LOAD_SUCCESS,
  LOAD_STATE,
  TASK_LINKS_LIVE_UPDATE,
  TASK_LINKS_LOAD_BEGIN,
  TASK_LINKS_LOAD_FAILURE,
  TASK_LINKS_LOAD_SUCCESS,
  TOGGLE_GROUP,
  TOGGLE_PM_SIDEBAR,
} from '../actions';
import getFilteredItems from '../actions/filterSidebarItems';
import processSidebarItems from '../actions/processSidebarItems';
import { getPersistedState } from './middleware';
import { getChangesFromProjectUpdate, processChanges } from './processChanges';

const DEFAULT_SORT_BY = 'extCreatedAt';
const DEFAULT_SORT_ORDER = 'desc';

const INITIAL_STATE = {
  visible: false,
  loadState: LOAD_STATE.UNLOADED,
  items: [],
  filteredItems: [],
  sortBy: undefined,
  sortOrder: undefined,
  page: 0,
  hasMore: true,
  searchContext: [],
  collapsedGroups: {},
  taskLinksLoadState: LOAD_STATE.UNLOADED,
  taskLinks: {},
  integrationsLoadState: LOAD_STATE.UNLOADED,
  ...getPersistedState(),
  filter: mapValues(FILTER_TYPES, () => ({})),
  DO_NOT_USE_userAdminId: undefined,
  DO_NOT_USE_userAccountTid: undefined,
};

const sortByProjectModified = (x) => x.projectModified;

const sortByProjectId = (x) => +x.projectId;

const sortByField = (field) => (x) => {
  const value = x[field];
  if (isDate(value)) {
    return value.getTime();
  }

  if (!isNaN(value)) {
    return +value;
  }

  return value;
};

const getSortedItems = (items, sortBy, order = 'asc') => {
  const sort = [sortByProjectModified, sortByProjectId, sortByField(sortBy)];
  const sortOrder = ['desc', 'desc', order];
  if (sortBy !== DEFAULT_SORT_BY) {
    sort.push(sortByField(DEFAULT_SORT_BY));
    sortOrder.push(DEFAULT_SORT_ORDER);
  }
  return orderBy(items, sort, sortOrder);
};

const isFiltered = (state) =>
  Boolean(
    Object.values(state.filter).some((keyVal) => !isEmpty(keyVal)) ||
      Object.keys(state.collapsedGroups).length,
  );

const pmSidebar = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // TODO: Deprecate this case and the `userAdminId` and `userAccountTid` state properties
    // https://linear.app/float-com/issue/PI-242/remove-user-data-from-lastupdated-and-pmsidebar-reducers
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        DO_NOT_USE_userAdminId: action.user.admin_id,
        DO_NOT_USE_userAccountTid: action.user.account_tid,
      };
    case TOGGLE_PM_SIDEBAR:
      return {
        ...state,
        visible: action.visible || !state.visible,
      };

    case TOGGLE_ACTIVITY_FEED:
      return {
        ...state,
        visible: false,
      };

    case CHANGE_SORT: {
      const items = getSortedItems(state.items, action.sortBy, action.order);
      const filteredItems = getSortedItems(
        state.filteredItems,
        action.sortBy,
        action.order,
      );

      return {
        ...state,
        items,
        filteredItems,
        sortBy: action.sortBy,
        sortOrder: action.order,
      };
    }

    case CHANGE_FILTER: {
      const { filterType, key, value } = action;
      const keyVal = key ? { key, value } : {};
      const newState = {
        ...state,
        filter: { ...state.filter, [filterType]: keyVal },
      };
      newState.filteredItems = isFiltered(newState)
        ? getFilteredItems(newState)
        : [];

      return newState;
    }

    case TOGGLE_GROUP: {
      const { value } = action;
      const collapsedGroups = { ...state.collapsedGroups };
      if (collapsedGroups[value]) {
        delete collapsedGroups[value];
      } else {
        collapsedGroups[value] = true;
      }
      const newState = { ...state };
      newState.collapsedGroups = collapsedGroups;
      newState.filteredItems = getFilteredItems(newState);
      return newState;
    }

    case ITEMS_LOAD_BEGIN:
      return { ...state, loadState: LOAD_STATE.LOADING };

    case ITEMS_LOAD_SUCCESS: {
      const { response, fullState } = action;
      const { items, type, searchContext, relevant } = processSidebarItems(
        response,
        fullState,
      );
      const loadState = LOAD_STATE.LOADED;
      const newState = {
        ...state,
        hasMore: false,
        page: 1,
        loadState,
        searchContext,
        relevant,
        type,
      };
      newState.items = getSortedItems(
        items,
        newState.sortBy,
        newState.sortOrder,
      );

      if (isFiltered(newState)) {
        newState.filteredItems = getFilteredItems(newState);
      }

      return newState;
    }

    case ITEMS_LOAD_FAILURE:
      return { ...state, loadState: LOAD_STATE.LOAD_FAILED };

    case ITEMS_LIVE_UPDATE: {
      const { changeType: type, item, projectIds, fullState } = action;
      let items;

      if (projectIds && type === 'delete') {
        return processChanges(
          state,
          { projectDeletes: projectIds },
          {
            admin_id: state.DO_NOT_USE_userAdminId,
            account_tid: state.DO_NOT_USE_userAccountTid,
          },
        );
      } else if (item && !isNumber(item)) {
        const changedItems = isArray(item) ? item : [item];
        const changedItemsMap = keyBy(changedItems, 'extResourceId');
        if (type === 'delete') {
          items = state.items.filter((x) => !changedItemsMap[x.extResourceId]);
        } else {
          const itemsMap = keyBy(state.items, 'extResourceId');
          // update existing items
          items = state.items.map((x) =>
            !changedItemsMap[x.extResourceId]
              ? x
              : { ...x, ...changedItemsMap[x.extResourceId] },
          );
          // insert new items
          items.push(...changedItems.filter((x) => !itemsMap[x.extResourceId]));
        }
      }

      if (!items) {
        return state;
      }

      const {
        items: processedItems,
        searchContext,
        relevant,
      } = processSidebarItems(items, fullState);
      items = processedItems;
      const newState = { ...state, searchContext, relevant };
      newState.items = getSortedItems(
        processedItems,
        newState.sortBy,
        newState.sortOrder,
      );
      if (isFiltered(newState)) {
        newState.filteredItems = getFilteredItems(newState);
      }
      return newState;
    }

    case TASK_LINKS_LOAD_BEGIN:
      return { ...state, taskLinksLoadState: LOAD_STATE.LOADING };

    case TASK_LINKS_LOAD_SUCCESS: {
      return {
        ...state,
        taskLinksLoadState: LOAD_STATE.LOADED,
        taskLinks: keyBy(action.response, 'task_id'),
      };
    }

    case TASK_LINKS_LOAD_FAILURE:
      return { ...state, taskLinksLoadState: LOAD_STATE.LOAD_FAILED };

    case TASK_LINKS_LIVE_UPDATE: {
      const { changeType: type, item } = action;
      const items = isArray(item) ? item : [item];
      const newTaskLinks = { ...state.taskLinks };
      items.forEach((item) => {
        if (type === 'delete') {
          delete newTaskLinks[item.task_id];
        } else {
          newTaskLinks[item.task_id] = item;
        }
      });
      return {
        ...state,
        taskLinks: newTaskLinks,
      };
    }

    case PROJECTS_UPDATED: {
      const changes = getChangesFromProjectUpdate(action, {
        admin_id: state.DO_NOT_USE_userAdminId,
        account_tid: state.DO_NOT_USE_userAccountTid,
      });
      return processChanges(state, changes, {
        admin_id: state.DO_NOT_USE_userAdminId,
        account_tid: state.DO_NOT_USE_userAccountTid,
      });
    }

    case PROJECTS_DELETED: {
      return processChanges(
        state,
        { projectDeletes: [action.projectId] },
        {
          admin_id: state.DO_NOT_USE_userAdminId,
          account_tid: state.DO_NOT_USE_userAccountTid,
        },
      );
    }

    case PROJECTS_BULK_DELETED: {
      return processChanges(
        state,
        {
          projectDeletes: action.ids.map((id) => +id),
        },
        {
          admin_id: state.DO_NOT_USE_userAdminId,
          account_tid: state.DO_NOT_USE_userAccountTid,
        },
      );
    }

    case PROJECTS_BULK_UPDATED: {
      const { fields, ids } = action;
      if (fields.active === 0) {
        return processChanges(
          state,
          {
            projectDeletes: ids.map((id) => +id),
          },
          {
            admin_id: state.DO_NOT_USE_userAdminId,
            account_tid: state.DO_NOT_USE_userAccountTid,
          },
        );
      }
      return state;
    }

    case INTEGRATIONS_LOAD_BEGIN:
      return { ...state, integrationsLoadState: LOAD_STATE.LOADING };

    case INTEGRATIONS_LOAD_SUCCESS:
      return { ...state, integrationsLoadState: LOAD_STATE.LOADED };

    case INTEGRATIONS_LOAD_FAILURE:
      return { ...state, integrationsLoadState: LOAD_STATE.LOAD_FAILED };

    default:
      return state;
  }
};

export default pmSidebar;
