import { accumulateDecimalHours } from '@float/common/lib/timer/accumulateDecimalHours';
import { subtractDecimalHours } from '@float/common/lib/timer/subtractDecimalHours';
import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { getFilteredEntities } from '@float/common/search/selectors/filteredEntities';
import {
  getUser,
  selectDatesManager,
} from '@float/common/selectors/currentUser';
import { getHolidaysRawList } from '@float/common/selectors/holidays';
import { getProjectsMap } from '@float/common/selectors/projects';
import { getWorkHours } from '@float/common/selectors/schedule/getWorkHours';
import { selectIsWorkDayGetter } from '@float/common/selectors/schedule/isWorkDay';
import { getTimeoffsListRaw } from '@float/common/selectors/timeoffs';
import { getActiveFilters } from '@float/common/selectors/views';
import { forEachEntityDate } from '@float/libs/datesRepeated/forEachEntityDate';
import { getAreDatesOverlapping } from '@float/libs/datesRepeated/getAreDatesOverlapping';
import { passthrough } from '@float/libs/utils/noop';
import { ScheduleRowList } from '@float/types/rows';

import { getTimeRangeTasksByPerson } from '../insights/selectors/getTimeRangeTasksByPerson';
import { getTimeRangeTimeoffsByPerson } from '../insights/selectors/getTimeRangeTimeoffsByPerson';
import {
  addHoursWithDecimalHoursAccumulation,
  HolidayEntry,
  registerItem,
} from './helpers';
import { BreakdownData, ItemData } from './types';

export function getPeopleScheduleData(payload: {
  rows: ScheduleRowList;
  state: ReduxStateStrict;
  startDate: string;
  endDate: string;
  getDateAggregationIndex?: (date: string) => string;
}) {
  const {
    rows,
    state,
    startDate,
    endDate,
    getDateAggregationIndex = passthrough,
  } = payload;

  const dates = selectDatesManager(state);

  const start = dates.toNum(startDate);
  const end = dates.toNum(endDate);

  const user = getUser(state);
  const tasksByPerson = getTimeRangeTasksByPerson(state, {
    endDate,
    startDate,
  });

  const timeoffsByPerson = getTimeRangeTimeoffsByPerson(state, {
    endDate,
    startDate,
  });

  const filters = getActiveFilters(state);
  const projects = getProjectsMap(state);
  const filteredEntities = getFilteredEntities(state);

  const getIsWorkDay = selectIsWorkDayGetter(state);

  const peopleRows = rows.filter((r) => r.type === 'person');

  const scheduleData = peopleRows.map(({ data: person }) => {
    const data: BreakdownData = {
      person: {
        name: person.name,
        jobTitle: person.job_title,
        department: (person.department && person.department.name) || '',
      },
      tasks: {},
      timeoffs: {},
      aggregate: {},
    };

    const registerItemToCurrentPerson = registerItem.bind(
      null,
      {
        projects,
        filteredEntities,
        filters,
      },
      data,
    );

    const workDaysInRange = new Set<string>();

    // Create all the initial entries based on the selected export range
    // and aggregation type
    for (let d = start; d <= end; d++) {
      const date = dates.fromNum(d);

      data.aggregate[getDateAggregationIndex(date)] = {
        capacity: 0,
        total: 0,
      };
    }

    // Collect all the work days inside of the selected range
    // and compute the capacity based on the work hours
    // This already considers full day timeoffs, regional holidays
    // holidays and one offs
    for (let d = start; d <= end; d++) {
      const date = dates.fromNum(d);

      if (getIsWorkDay(person, date)) {
        const hours = getWorkHours(dates, user, person, date);
        const index = getDateAggregationIndex(date);

        const entry = data.aggregate[index];

        entry.capacity = accumulateDecimalHours(entry.capacity, hours);
        workDaysInRange.add(date);
      }
    }

    // Iterate all the timeoff days inside of the range
    for (const entity of timeoffsByPerson.get(person.people_id) || []) {
      // Regional holidays are handled separately in the holidays export
      // we have already considered them when calculating the work days
      if (entity.region_holiday_id) {
        continue;
      }

      let timeoffData: ItemData | null = null;

      if (entity.full_day) {
        // Full day timeoffs are part of the total hours
        // but the capacity has already been subtracted when calculating
        // the work days
        forEachEntityDate(dates, entity, (dateNum) => {
          const date = dates.fromNum(dateNum);

          // We can't use workDaysInRange because the days with a full
          // timeoff are considered non-work days
          // We use the getIsWorkDay check excluding this timeoff to see
          // if it happens on a work day.
          if (dateNum < start || dateNum > end) return;
          if (!getIsWorkDay(person, date, entity.timeoff_id)) return;

          const hours = getWorkHours(dates, user, person, date);

          const index = getDateAggregationIndex(date);

          if (!timeoffData) {
            timeoffData = registerItemToCurrentPerson({
              type: 'timeoff',
              entity,
              entityId: entity.timeoff_id,
            });
          }

          addHoursWithDecimalHoursAccumulation(timeoffData, index, hours);

          const entry = data.aggregate[index];

          entry.total = accumulateDecimalHours(entry.total, hours);
        });
      } else {
        // Calculate all the partial day timeoffs
        forEachEntityDate(dates, entity, (dateNum) => {
          const date = dates.fromNum(dateNum);

          if (!workDaysInRange.has(date)) return;

          const hours = entity.hours;

          // Check if is outside range or if is a non-work day
          if (!workDaysInRange.has(date)) return;
          if (!hours) return;

          const index = getDateAggregationIndex(date);

          if (!timeoffData) {
            timeoffData = registerItemToCurrentPerson({
              type: 'timeoff',
              entity,
              entityId: entity.timeoff_id,
            });
          }

          addHoursWithDecimalHoursAccumulation(timeoffData, index, hours);
          const entry = data.aggregate[index];

          entry.total = accumulateDecimalHours(entry.total, hours);
          entry.capacity = subtractDecimalHours(entry.capacity, hours);
        });
      }
    }

    // Collect the allocations
    for (const entity of tasksByPerson.get(person.people_id) || []) {
      let taskData: ItemData | null = null;

      forEachEntityDate(dates, entity, (dateNum) => {
        const date = dates.fromNum(dateNum);

        // Check if is outside range or if is a non-work day
        if (!workDaysInRange.has(date)) return;

        const index = getDateAggregationIndex(date);

        if (!taskData) {
          taskData = registerItemToCurrentPerson({
            type: 'task',
            entity,
            entityId: entity.task_id,
          });
        }

        addHoursWithDecimalHoursAccumulation(taskData, index, entity.hours);
        const entry = data.aggregate[index];

        entry.total = accumulateDecimalHours(entry.total, entity.hours);
        entry.capacity = subtractDecimalHours(entry.capacity, entity.hours);
      });
    }

    for (let d = start; d <= end; d++) {
      const date = dates.fromNum(d);

      const index = getDateAggregationIndex(date);

      // We never want to report a negative capacity, which can result
      // if there's overtime in a given time period.
      if (data.aggregate[index].capacity < 0) {
        data.aggregate[index].capacity = 0;
      }
    }

    return data;
  });

  return scheduleData;
}

export function getHolidayEntries(payload: {
  state: ReduxStateStrict;
  startDate: string;
  endDate: string;
}) {
  const { startDate, endDate, state } = payload;

  const holidays: HolidayEntry[] = [];

  for (const entity of getTimeoffsListRaw(state)) {
    if (!('region_holiday_id' in entity)) continue;

    if (
      !getAreDatesOverlapping(
        entity.start_date,
        entity.end_date,
        startDate,
        endDate,
      )
    ) {
      continue;
    }

    holidays.push({
      name: entity.timeoff_notes,
      start_date: entity.start_date,
      end_date: entity.end_date,
    });
  }

  for (const entity of getHolidaysRawList(state)) {
    if (
      !getAreDatesOverlapping(entity.date, entity.end_date, startDate, endDate)
    ) {
      continue;
    }

    holidays.push({
      name: entity.name,
      start_date: entity.date,
      end_date: entity.end_date,
    });
  }

  holidays.sort((a, b) => {
    if (a.start_date !== b.start_date) {
      return String(a.start_date).localeCompare(b.start_date);
    }

    if (a.end_date !== b.end_date) {
      return -1 * String(a.end_date).localeCompare(b.end_date);
    }

    return String(a.name)
      .toLowerCase()
      .localeCompare(String(b.name).toLowerCase());
  });

  return holidays;
}
