import { CurrentUser } from '@float/types';

import { TIME_FORMAT, TIME_FORMAT_MAP } from '../time';

export const getHoursDisplayFormat = (prefs: CurrentUser['prefs']) => {
  // Show time if no preference is set
  if (!prefs.duration_display_format) {
    return TIME_FORMAT.time;
  }
  return TIME_FORMAT_MAP[prefs.duration_display_format.value];
};

export const displayHoursFormatIsTime = (prefs: CurrentUser['prefs']) => {
  return getHoursDisplayFormat(prefs) === TIME_FORMAT.time;
};

export const displayHoursFormatIsDecimal = (prefs: CurrentUser['prefs']) => {
  return getHoursDisplayFormat(prefs) === TIME_FORMAT.decimal;
};
