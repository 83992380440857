import { useMemo, useState } from 'react';

import { normalize } from '@float/common/search/helpers';
import { SavedView } from '@float/types';

const matchAllViewTypes = (_: SavedView) => true;
const matchPersonalViewTypes = (view: SavedView) => view.personal;
const matchSharedViewTypes = (view: SavedView) => !view.personal;

/**
 * Decorate a compare function by giving priority to the pinned stat
 */
const compareByPinnedState =
  (compareWhenEqual: (a: SavedView, b: SavedView) => number) =>
  (a: SavedView, b: SavedView) =>
    a.pinned === b.pinned
      ? compareWhenEqual(a, b)
      : Number(b.pinned) - Number(a.pinned);

const getMostRecentValue = (view: SavedView) =>
  view.lastUsed ? view.lastUsed : '-' + view.created;

const compareByMostRecent = compareByPinnedState((a: SavedView, b: SavedView) =>
  getMostRecentValue(b).localeCompare(getMostRecentValue(a)),
);

const compareByNameAsc = compareByPinnedState((a: SavedView, b: SavedView) =>
  a.name.localeCompare(b.name),
);

const compareByNameDesc = compareByPinnedState((a: SavedView, b: SavedView) =>
  b.name.localeCompare(a.name),
);

const filterAndSortViews = (
  views: SavedView[],
  filter: string,
  viewType: ViewTypeFilter,
  sortOrder: ViewSortType,
) => {
  const normalizedFilter = normalize(filter);

  let viewTypeMatcher = matchAllViewTypes;

  if (viewType === 'personal') {
    viewTypeMatcher = matchPersonalViewTypes;
  } else if (viewType === 'shared') {
    viewTypeMatcher = matchSharedViewTypes;
  }

  const result = views.filter(
    (view) =>
      viewTypeMatcher(view) && normalize(view.name).includes(normalizedFilter),
  );

  let compare = compareByMostRecent;

  if (sortOrder === 'a-z') {
    compare = compareByNameAsc;
  } else if (sortOrder === 'z-a') {
    compare = compareByNameDesc;
  }

  result.sort(compare);

  return result;
};

export type ViewTypeFilter = 'all' | 'personal' | 'shared';
export type ViewSortType = 'recent' | 'a-z' | 'z-a';

export function useViewsOptions(views: SavedView[]) {
  const [viewNameFilter, setViewNameFilter] = useState('');
  const [viewTypeFilter, setViewTypeFilter] = useState<ViewTypeFilter>('all');
  const [viewSortType, setViewSortType] = useState<ViewSortType>('recent');

  const options = useMemo(
    () =>
      filterAndSortViews(views, viewNameFilter, viewTypeFilter, viewSortType),
    [views, viewNameFilter, viewTypeFilter, viewSortType],
  );

  return {
    options,
    setViewNameFilter,
    viewNameFilter,
    setViewTypeFilter,
    viewTypeFilter,
    setViewSortType,
    viewSortType,
  };
}
