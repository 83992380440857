import styled from 'styled-components';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

export const ComparisonTable = styled.table`
  padding-top: 4px;

  td,
  th {
    ${Typography.TextM.R400};

    padding: 16px 0;
    width: 50%;

    &:first-child {
      padding-right: 16px;
    }

    &:last-child {
      padding-left: 16px;
    }

    vertical-align: top;
  }

  th {
    padding-top: 0;
  }

  tr {
    border-top: 1px solid ${Colors.FIN.Lt.Stroke.Stroke2};

    &:first-child {
      border-width: 0;
    }

    &:last-child td {
      padding-bottom: 0;

      /* The last button is aligned to the other column text */
      /* So we remove the left padding to let the button padding take the left space */
      padding-left: 0;
    }
  }
`;
