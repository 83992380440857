import React, { useRef } from 'react';
import cn from 'classnames';

import { deleteTimer as deleteTimerAction } from '@float/common/actions/timer/deleteTimer';
import { updateTimer as updateTimerAction } from '@float/common/actions/timer/updateTimer';
import { getTimeFormat } from '@float/common/lib/time';
import { getCompanyPrefs } from '@float/common/selectors/companyPrefs';
import { useAppDispatchDecorator } from '@float/common/store';
import { Timer } from '@float/types';
import { useWebAppSelector } from '@float/web/lib/store';

import { TimerEntryRow } from '../../../TimerEntryRow';
import { ViewMoreToggle } from './components/ViewMoreToggle';
import { useTimersWithViewMoreController } from './useTimersWithViewMoreController';

import * as styles from './styles.css';

const DEFAULT_MAX_VISIBLE = 3;

export type TimersWithViewMoreProps = {
  maxVisible?: number;
  timers?: Array<Timer> | undefined;
};

function TimersWithViewMore(props: TimersWithViewMoreProps) {
  const { maxVisible = DEFAULT_MAX_VISIBLE, timers = [] } = props;

  const companyPrefs = useWebAppSelector(getCompanyPrefs);

  const timeFormat = getTimeFormat(companyPrefs);
  const deleteTimer = useAppDispatchDecorator(deleteTimerAction);
  const updateTimer = useAppDispatchDecorator(updateTimerAction);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const firstItem = wrapperRef.current?.querySelector('*:first-child');

  const {
    onClickViewMore,
    hiddenTimersCount,
    visibleTimersCount,
    state,
    wrapperHeight,
  } = useTimersWithViewMoreController({ timers, maxVisible, firstItem });

  return (
    <div>
      <div
        className={cn(styles.wrapper, {
          [styles.noOverflow]: state.animating,
        })}
        ref={wrapperRef}
        style={{ height: wrapperHeight }}
      >
        {timers.map((timer, index) => {
          const hasClosed = state.closed;

          const isHidden = index >= visibleTimersCount && hasClosed;
          const isLastItem = index === visibleTimersCount - 1;
          const isLastItemWhenClosed = isLastItem && hasClosed;

          return (
            <TimerEntryRow
              className={cn(styles.timerEntryRow, { isLastItemWhenClosed })}
              hidden={isHidden}
              key={timer._id}
              timeFormat={timeFormat}
              timer={timer}
              updateTimer={updateTimer}
              deleteTimer={deleteTimer}
            />
          );
        })}
      </div>

      {hiddenTimersCount > 0 && (
        <ViewMoreToggle
          hiddenTimersCount={hiddenTimersCount}
          onClick={onClickViewMore}
          opened={state.open}
        />
      )}
    </div>
  );
}

export { TimersWithViewMore };
