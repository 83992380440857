import { t } from '@lingui/macro';

import { ProjectStatus } from '@float/types/project';

export const getProjectStatusMessages = () => ({
  [`${ProjectStatus.Draft}${ProjectStatus.Tentative}`]: {
    main: [
      t`Make all planned allocations, phases, and milestones visible on the Schedule`,
    ],
    timeTrackingEnabled: undefined,
    accessNote: false,
  },
  [`${ProjectStatus.Draft}${ProjectStatus.Confirmed}`]: {
    main: [
      t`Make all planned allocations, phases, and milestones visible on the Schedule`,
      t`Notify everyone on the project team`,
    ],
    timeTrackingEnabled: t`Allow the project team to log time against the project`,
    accessNote: false,
  },
  [`${ProjectStatus.Tentative}${ProjectStatus.Draft}`]: {
    main: [
      t`Change all planned allocations, phases, and milestones to Draft on the Project plan`,
    ],
    timeTrackingEnabled: undefined,
    accessNote: true,
  },
  [`${ProjectStatus.Tentative}${ProjectStatus.Confirmed}`]: {
    main: [t`Notify the project team about project updates`],
    timeTrackingEnabled: t`Allow the project team to log time against the project`,
    accessNote: false,
  },
  [`${ProjectStatus.Confirmed}${ProjectStatus.Draft}`]: {
    main: [
      t`Change all planned allocations, phases, and milestones to Draft on the Project plan`,
      t`Hide this project from the Schedule`,
    ],
    timeTrackingEnabled: t`Prevent any time entries from being logged against the project`,
    accessNote: true,
  },
  [`${ProjectStatus.Confirmed}${ProjectStatus.Tentative}`]: {
    main: [
      t`Change all planned allocations, phases, and milestones to Tentative on the Schedule`,
    ],
    timeTrackingEnabled: t`Prevent new time entries from being logged against the project`,
    accessNote: false,
  },
});

export const getPhaseStatusMessages = () => ({
  [`${ProjectStatus.Tentative}${ProjectStatus.Confirmed}`]: {
    main: [t`Notify the project team about updates on the phase`],
    timeTrackingEnabled: t`Allow the project team to log time against the phase`,
    accessNote: false,
  },
  [`${ProjectStatus.Confirmed}${ProjectStatus.Tentative}`]: {
    main: [
      t`Update all confirmed allocations and milestones on the phase to Tentative on the Schedule`,
    ],
    timeTrackingEnabled: t`No new logged time entries can be made on the phase`,
    accessNote: false,
  },
});
