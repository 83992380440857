import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  button + button {
    margin-left: 10px;
  }
`;
