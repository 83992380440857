import {
  addedEntityCreator,
  deletedEntityCreator,
  deleteEntityCreator,
  failedDeletingEntityCreator,
  failedFetchingEntitiesCreator,
  fetchedEntitiesCreator,
  fetchEntitiesCreator,
  shouldFetchEntitiesCreator,
} from './creatorHelpers';

export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS';

export const FETCH_PROJECTS = 'FETCH_PROJECTS';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_FAILURE = 'FETCH_PROJECTS_FAILURE';

export const DELETE_PROJECT = 'DELETE_PROJECT';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE';

const SINGULAR = 'project';
const PLURAL = 'projects';
const ID_PROP = 'project_id';

export const addedProject = addedEntityCreator(ADD_PROJECT_SUCCESS);

export const deletedProject = deletedEntityCreator(DELETE_PROJECT_SUCCESS);

const failedDeletingProject = failedDeletingEntityCreator(
  DELETE_PROJECT_FAILURE,
);

export const deleteProject = deleteEntityCreator(
  DELETE_PROJECT,
  SINGULAR,
  PLURAL,
  deletedProject,
  failedDeletingProject,
  ID_PROP,
);

export const fetchedProjects = fetchedEntitiesCreator(FETCH_PROJECTS_SUCCESS);

const failedFetchingProjects = failedFetchingEntitiesCreator(
  FETCH_PROJECTS_FAILURE,
);

const shouldFetchProjects = shouldFetchEntitiesCreator();

export const fetchProjects = fetchEntitiesCreator(
  FETCH_PROJECTS,
  PLURAL,
  shouldFetchProjects,
  fetchedProjects,
  failedFetchingProjects,
);
