import { t } from '@lingui/macro';
import { forEach, isUndefined } from 'lodash';
import {
  amountFormatter,
  filterLinkFormatter,
  hoursFormatter,
  pctFormatter,
  rangeBarFormatter,
} from 'reports/helpers/tableFormatters';

import { formatAmount, toCents } from '@float/common/lib/budget';
import { userCanSeeBudgets } from '@float/common/selectors/projects';
import { Phase } from '@float/types/phase';
import { BudgetPriority } from '@float/types/project';
import {
  AccordionTableCellValue,
  AccordionTableData,
  AccordionTableRowConfig,
} from '@float/ui/deprecated/AccordionTable/types';

import {
  getBudgetDataObject,
  getFeeOrHoursAsPercentageOfBudget,
} from './helpers/budget';
import { getDoesBudgetUseFees } from './helpers/getDoesBudgetUseFees';
import { getPhaseRowsForProject } from './helpers/getPhaseRowsForProject';
import { getRangeBar } from './helpers/getRangeBar';
import { getPctHeader } from './PercentageSelector';
import {
  ProjectsOverviewTableContext,
  ProjectsOverviewTableDataRaw,
  ReportsProject,
} from './types';

export function aggregateByProject(
  ctx: ProjectsOverviewTableContext,
  raw: ProjectsOverviewTableDataRaw | null,
) {
  const { projects, phases } = ctx;
  const byProject: Record<number, ReportsProject> = {};

  if (!raw) return {};

  function getRecord(projectId: number) {
    if (!byProject[projectId]) {
      const project = projects[projectId];

      byProject[projectId] = {
        id: projectId,
        budget: null,
        budget_type: null,
        name: project.project_name,
        scheduled: { fee: 0, hours: 0, billableFee: 0, billableHours: 0 },
        logged: { fee: 0, hours: 0, billableFee: 0, billableHours: 0 },
        children: {},
      };
    }

    return byProject[projectId];
  }

  function initPhaseChild(
    phase: Phase | { phase_id: number; project_id: number },
  ) {
    const c = {
      phase_id: phase.phase_id,
      budget: raw?.phases[phase.phase_id]?.budget,
      budget_type: byProject[phase.project_id].budget_type,
      scheduled: { fee: 0, hours: 0, billableFee: 0, billableHours: 0 },
      logged: { fee: 0, hours: 0, billableFee: 0, billableHours: 0 },
      children: {},
    };
    if (isUndefined(c.budget)) {
      c.budget = null;
    }
    return c;
  }

  for (const _projectId in raw.budgets) {
    const projectIdForBudget = Number(_projectId);
    const budget = raw.budgets[projectIdForBudget];
    const project = projects[projectIdForBudget];

    if (!project) {
      console.error('No project found', projectIdForBudget, budget);
      return;
    }

    const record = getRecord(projectIdForBudget);
    record.budget = budget.budget;
    record.budget_type = budget.type;
  }

  forEach(phases, (phase) => {
    // Only include phases for projects returned in report-api response,
    // honoring search filters if any.
    if (phase && byProject[phase.project_id]) {
      getRecord(phase.project_id).children[phase.phase_id] =
        initPhaseChild(phase);
    }
  });

  raw.totals.forEach((item) => {
    if (!item.project_id) return;
    if (!projects[item.project_id]) {
      console.error('No project found', item);
      return;
    }

    if (!byProject[item.project_id]) {
      console.error('Expected initialized object', { item, raw });
      return;
    }

    const record = byProject[item.project_id];
    if (item.phase_id === 0 && !record.children[item.phase_id]) {
      const noPhase = { phase_id: 0, project_id: item.project_id };
      record.children[item.phase_id] = initPhaseChild(noPhase);
    }

    const child = !isUndefined(item.phase_id) && record.children[item.phase_id];

    if (item.type === 'task') {
      record.scheduled.hours += item.hours.scheduled;

      const fee = toCents(item.hours.scheduled * item.rate);
      record.scheduled.fee = (toCents(record.scheduled.fee) + fee) / 100;
      if (item.billable) {
        record.scheduled.billableHours += item.hours.scheduled;
        record.scheduled.billableFee =
          (toCents(record.scheduled.billableFee) + fee) / 100;
      }

      if (child) {
        child.scheduled.hours += item.hours.scheduled;
        child.scheduled.fee = (toCents(child.scheduled.fee) + fee) / 100;

        if (item.billable) {
          child.scheduled.billableHours += item.hours.scheduled;
          child.scheduled.billableFee =
            (toCents(child.scheduled.billableFee) + fee) / 100;
        }
      }
    } else if (item.type === 'logged_time') {
      if (item.date >= ctx.loggedTimeBoundary) {
        return;
      }

      record.logged.hours += item.hours.scheduled;
      const fee = toCents(item.hours.scheduled * item.rate);
      record.logged.fee = (toCents(record.logged.fee) + fee) / 100;
      if (item.billable) {
        record.logged.billableHours += item.hours.scheduled;
        record.logged.billableFee =
          (toCents(record.logged.billableFee) + fee) / 100;
      }

      if (child) {
        child.logged.hours += item.hours.scheduled;
        child.logged.fee = (toCents(child.logged.fee) + fee) / 100;
        if (item.billable) {
          child.logged.billableHours += item.hours.scheduled;
          child.logged.billableFee =
            (toCents(child.logged.billableFee) + fee) / 100;
        }
      }
    }
  });

  return byProject;
}

export function getProjectsTable(
  ctx: ProjectsOverviewTableContext,
  rawData: ProjectsOverviewTableDataRaw | null,
): AccordionTableData {
  const { projects } = ctx;

  const headers = [
    {
      label: t`Project`,
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    {
      label: t`Client`,
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    {
      label: t`Managed by`,
      align: 'flex-start',
      width: 150,
    },
    { label: t`Budget`, width: 130, formatter: amountFormatter },
    { label: '', width: 130 }, // Fee column
    {
      label: t`Scheduled`,
      width: 60,
      allowOverflow: true,
      formatter: hoursFormatter,
    },
    { label: '', width: 130 }, // Fee column
    {
      label: t`Logged`,
      width: 60,
      allowOverflow: true,
      formatter: hoursFormatter,
    },
    {
      label: getPctHeader(ctx),
      width: 120,
      formatter: pctFormatter,
    },
    {
      label: '',
      width: 130,
      formatter: rangeBarFormatter,
      allowOverflow: true,
    },
  ];

  if (!ctx.timeTrackingEnabled) {
    // Hide the Logged columns if the user doesn't have time tracking
    headers.splice(6, 2);
  }

  const canSeeBudget = userCanSeeBudgets(ctx.user);
  const byProject = aggregateByProject(ctx, rawData);

  const rows: AccordionTableRowConfig[] = [];
  forEach(byProject, (project, id) => {
    if (!projects[project.id]) return null;

    const budgetUsesFees = getDoesBudgetUseFees(project.budget_type);
    const isBillableProject = !projects[project.id].non_billable;
    const canSeeFees = canSeeBudget && budgetUsesFees;

    const managerId = projects[project.id].project_manager;
    const manager = ctx.accounts[managerId];

    const rangeBar = getRangeBar(
      ctx.projectsPercentageMode,
      project,
      isBillableProject,
    );

    const projectLink = `project-report-link::${project.name}`;
    const projectClient =
      projects[project.id].client_name === 'No Client'
        ? t`No Client`
        : `client::${projects[project.id].client_name}`;

    const projectManagerName = manager?.name;

    const projectBudget = canSeeBudget ? getBudgetDataObject(project) : '';
    const projectFees = canSeeFees
      ? formatAmount(
          project.budget_type,
          isBillableProject
            ? project.scheduled.billableFee
            : project.scheduled.fee,
        )
      : '';

    const projectScheduledHours = project.scheduled.hours;
    const projectBudgetUsagePercent = getFeeOrHoursAsPercentageOfBudget(
      project,
      {
        percentageMode: ctx.projectsPercentageMode,
        useBillable: isBillableProject,
        useFees: budgetUsesFees,
      },
    );

    const data: AccordionTableCellValue[] = [
      projectLink,
      projectClient,
      projectManagerName,
      projectBudget,
      projectFees,
      projectScheduledHours,
      projectBudgetUsagePercent,
      // @TODO(PI-91)
      // @ts-expect-error - We need to refactor the types to account for the RangeBar types
      rangeBar,
    ];

    if (ctx.timeTrackingEnabled) {
      const projectLoggedFee = canSeeFees
        ? formatAmount(project.budget_type, project.logged.fee)
        : '';
      const projectLoggedHours = project.logged.hours;

      // Add the logged columns if the user has time tracking
      data.splice(6, 0, projectLoggedFee, projectLoggedHours);
    }

    const phaseRows = getPhaseRowsForProject(project.children, ctx.phases, {
      budgetType: project.budget_type,
      canSeeFees,
      hasBudgetPerPhase:
        projects[project.id].budget_priority === BudgetPriority.Phase,
      isBillableProject,
      isTimeTrackingEnabled: ctx.timeTrackingEnabled,
      percentageMode: ctx.projectsPercentageMode,
    });

    rows.push({ id, data, children: phaseRows });
  });

  // @TODO(PI-91)
  // @ts-expect-error - We need to refactor the types to account for the RangeBar types
  return { headers, rows };
}
