import React from 'react';
import { Trans } from '@lingui/macro';
import { getDepartmentsByName } from 'selectors';

import { TYPE_TO_CATEGORY } from '@float/common/search/helpers';
import { useAppSelector } from '@float/common/store';
import { FilterToken } from '@float/types';

import { getSubCategoryConfig } from '../categories/config';

const getDefaultFilterLabel = (filter: FilterToken) => {
  const cat = filter.type === 'me' ? 'me' : TYPE_TO_CATEGORY[filter.type];

  return getSubCategoryConfig(cat).label;
};

const DepartmentFilterLabel = ({ filter }: { filter: FilterToken }) => {
  const departmentsByName = useAppSelector(getDepartmentsByName);

  const departmentNames = Array.isArray(filter.val) ? filter.val : [filter.val];
  const isOnlySubDepartments = departmentNames.every((name) => {
    const department = departmentsByName[name];
    return department?.parent_id;
  });

  if (isOnlySubDepartments) {
    return <Trans>Sub-department</Trans>;
  }

  return <>{getDefaultFilterLabel(filter)}</>;
};

export const FilterLabel = ({ filter }: { filter: FilterToken }) => {
  switch (filter.type) {
    case 'department': {
      return <DepartmentFilterLabel filter={filter} />;
    }

    default: {
      return <>{getDefaultFilterLabel(filter)}</>;
    }
  }
};
