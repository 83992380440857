import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Descendant } from 'slate';

import { toDbFormat } from '@float/common/lib/notes';
import { NotesMeta } from '@float/types/task';
import { RichText } from '@float/ui/deprecated/Earhart/RichText';

import { FormField } from '../FormFieldsSection';

import * as styles from './styles.css';

export type RichTextFields = 'project.description' | 'phase.notes';
export type RichTextMetaFields = 'project.notes_meta' | 'phase.notes_meta';

export type RichTextFieldProps<
  N extends RichTextFields,
  M extends RichTextMetaFields,
> = {
  name: N;
  meta: M;
  label: string;
};

export function RichTextField<
  N extends RichTextFields,
  M extends RichTextMetaFields,
>(props: RichTextFieldProps<N, M>) {
  const { setValue } = useFormContext<{
    project: { notes_meta: NotesMeta };
    phase: { notes_meta: NotesMeta };
  }>();
  const notesMeta = useWatch({ name: props.meta });

  return (
    <FormField label={props.label} alignLabel="start">
      <Controller
        name={props.name}
        render={({ field }) => (
          <RichText
            inputClassName={styles.textbox}
            value={field.value}
            onChange={(value: { text: string; children: Descendant[] }) => {
              field.onChange(value.text);
              setValue(
                props.meta as RichTextMetaFields,
                toDbFormat(value.children).notes_meta,
              );
            }}
            onBlur={field.onBlur}
            valueMeta={notesMeta}
            maxLength={1500}
            mentionsEnabled
          />
        )}
      />
    </FormField>
  );
}
