import { DEFAULT_STATUS_COLOR } from '@float/constants/colors';

export const getStatusColor = (color) => {
  if (color) {
    if (color.startsWith('#')) {
      return color;
    }

    return `#${color}`;
  }

  return DEFAULT_STATUS_COLOR;
};
