import React from 'react';

export default function () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#868D92" fillRule="nonzero">
          <g>
            <g transform="translate(-381 -1073) translate(299 1061) translate(50 11)">
              <g>
                <g>
                  <path
                    d="M6 5L14 5 14 8 6 8zM3 10L14 10 14 13 3 13zM0 0L14 0 14 3 0 3z"
                    transform="translate(32 1) translate(5 5)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
