import React from 'react';
import { connect } from 'react-redux';

import { useAppSelector } from '@float/common/store';
import { getOnboardingStatus } from '@float/web/store/legacyOnboarding/selectors';
import { updatePrompts } from '@float/web/store/onboardingManager/actions';

import AddPhase from './AddPhase';
import NonAccountOwner from './NonAccountOwner';

function WelcomeTips({ markAsSeen, viewType }) {
  const status = useAppSelector(getOnboardingStatus);

  if (status.showAddPhase && viewType === 'projects') {
    return <AddPhase markAsSeen={markAsSeen} />;
  }

  if (status.showNonAccountOwner) {
    return <NonAccountOwner markAsSeen={markAsSeen} />;
  }

  return null;
}

const mapDispatchToProps = (dispatch) => ({
  markAsSeen: (id) => dispatch(updatePrompts(id)),
});

export default connect(undefined, mapDispatchToProps)(WelcomeTips);
