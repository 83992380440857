export enum Routes {
  SignupExist = '/signup/exist',
  SelectCompany = '/select-company',
  ReactivateSubscription = '/me/reactivate-subscription',
  SharedLinkView = '/share',
}

export const AUTH_ROUTES = [
  Routes.SignupExist,
  Routes.SelectCompany,
  Routes.ReactivateSubscription,
];
