import SockJS from 'sockjs-client';
import { v4 as uuidv4 } from 'uuid';

import { FloatAppPlatform } from '@float/constants/app';
import { config } from '@float/libs/config';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { logger } from '@float/libs/logger';
import { CurrentUser } from '@float/types';

const INITIAL_RECONNECT_TIME = 1000;

type App = {
  reconnectCount: number;
  reconID?: ReturnType<typeof setTimeout>;
  sockjs?: WebSocket;
  sockjsConn?: boolean;
};

const app: App = {
  reconnectCount: 0,
};

let shouldReconnectOnClose = false;

type Init = (conf: {
  jwt: string | null;
  currentUser: CurrentUser;
  onSocketMessageReceive: (message: string) => void;
}) => void;

const init: Init = ({ jwt, currentUser, onSocketMessageReceive }) => {
  shouldReconnectOnClose = true;

  const sessionUuid = `${generateCID(currentUser)}-${uuidv4()}`;
  socket.uuid = sessionUuid;

  if (app.sockjs && sockjs_status === 'connected') {
    return app.sockjs.close();
  }

  if (sockjs_status === 'disconnected') {
    sockjs_status = 'connecting';
    socket.path = `${config.api.socketServer}${config.api.socketPath}/`;

    app.sockjs = new SockJS(socket.path);

    app.sockjs.onmessage = (e) => {
      if (e.type !== 'message') return;
      const msg = JSON.parse(e.data);

      const { event } = msg;
      if (event === 'data') {
        return;
      }
      if (event === 'start_status') {
        return;
      }
      if (event) {
        onSocketMessageReceive(msg);
      }
    };

    app.sockjs.onopen = () => {
      sockjs_status = 'connected';
      app.sockjsConn = true;
      reconTime = INITIAL_RECONNECT_TIME;

      return socket.sockjs_send?.('initConn', jwt);
    };

    app.sockjs.onclose = () => {
      sockjs_status = 'disconnected';
      app.sockjsConn = false;

      if (!shouldReconnectOnClose || sessionUuid !== socket.uuid) {
        return;
      }

      const reconnect = () => {
        if (!shouldReconnectOnClose || sessionUuid !== socket.uuid) {
          return;
        }
        console.warn(`WebSockets: Attempting to reconnect to ${socket.path}`);
        app.reconnectCount++;
        socket.init({ currentUser, jwt, onSocketMessageReceive });
        reconTime *= 2;
      };

      if (app.reconID) {
        clearTimeout(app.reconID);
      }
      app.reconID = setTimeout(reconnect, reconTime);

      return app.reconID;
    };

    app.sockjs.onerror = (e) => {
      if (featureFlags.isFeatureEnabled(FeatureFlag.WebSocketMonitoring)) {
        logger.error('WebSocket Error', e);
      }
    };
  } else if (sockjs_status === 'connected') {
    app.sockjsConn = true;
    clearTimeout(app.reconID);
  }

  socket.sockjs_send = (event, data) =>
    app.sockjs?.send(
      JSON.stringify({
        event,
        uuid: socket.uuid,
        data,
      }),
    );

  if (config.platform === FloatAppPlatform.Web) {
    window.addEventListener('onbeforeunload', ensureConnectionClosed);
  }
};

type Socket = {
  uuid?: string;
  init: Init;
  path?: string;
  sockjs_send?: (event: string, data?: string | null) => void;
};

export const socket: Socket = {
  init: init,
};

let reconTime = INITIAL_RECONNECT_TIME;
let sockjs_status: 'disconnected' | 'connected' | 'connecting' = 'disconnected';

const generateCID = (user: CurrentUser) => `${user.cid}-${user.admin_id}`;

export const ensureConnectionClosed = () => {
  const isConnected = sockjs_status === 'connected';

  shouldReconnectOnClose = false;
  sockjs_status = 'disconnected';
  app.sockjsConn = false;

  if (isConnected) {
    socket.sockjs_send?.('close', socket.uuid);
    app.sockjs?.close();
  }
};

export default socket;
