export function createViewLastUsedStorage() {
  let data: Record<number, string> | null = null;

  // Lazily read from local storage at the first get request
  function readLocalStorage(): Record<number, string> {
    const value = localStorage.getItem('viewLastUsed') ?? '{}';

    try {
      return JSON.parse(value);
    } catch (err) {}

    return {};
  }

  return {
    get(id: number) {
      if (data === null) {
        data = readLocalStorage();
      }

      return data[id];
    },
    update(id: number) {
      if (data === null) {
        data = readLocalStorage();
      }

      data[id] = new Date().toJSON();
      localStorage.setItem('viewLastUsed', JSON.stringify(data));
    },
  };
}

export const viewLastUsedStorage = createViewLastUsedStorage();
