import React, { forwardRef, HTMLAttributes } from 'react';

import * as styles from './styles.css';

export type ListItemActionsProps = HTMLAttributes<HTMLElement>;

const ListItemActions = forwardRef<HTMLDivElement, ListItemActionsProps>(
  (props, ref) => {
    const { children } = props;

    return (
      <div ref={ref} className={styles.actions}>
        {children}
      </div>
    );
  },
);

export { ListItemActions };
