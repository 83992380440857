import styled from 'styled-components';

import EH from '@float/ui/deprecated/Earhart';

export const StyledCard = styled(EH.Cards.Card)`
  h5 {
    ${EH.Typography.TextXXL.SB600};
    color: ${EH.Colors.FIN.Lt.Emphasis.High};
  }
`;

interface StyledRowProps {
  alignItems?: string;
  justifyContent?: string;
  marginTop?: number;
}

export const StyledRow = styled.div<StyledRowProps>`
  display: flex;
  flex-direction: row;
  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent}`};
  &:not(:first-child) {
    ${({ marginTop }) => `margin-top: ${marginTop || 24}px`};
  }
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 12px 0 0;
`;

interface StyledRowHeaderProps extends StyledRowProps {
  disabled?: boolean;
}

export const StyledRowHeader = styled(StyledRow)<StyledRowHeaderProps>`
  display: flex;
  align-items: center;

  *:first-child {
    flex: 1;
  }

  h5 {
    color: ${({ disabled }) =>
      disabled
        ? EH.Colors.FIN.Lt.Emphasis.Low
        : EH.Colors.FIN.Lt.Emphasis.High};
  }
`;

interface StyledLabelProps {
  disabled?: boolean;
}

export const StyledLabel = styled.span<StyledLabelProps>`
  ${EH.Typography.Label14.R400};
  color: ${({ disabled }) =>
    disabled ? EH.Colors.FIN.Lt.Emphasis.Low : EH.Colors.FIN.Lt.Emphasis.High};
`;

interface StyledLinkProps {
  disabled?: boolean;
}

export const StyledLink = styled.a<StyledLinkProps>`
  ${EH.Typography.Label14.SB600};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  color: ${({ disabled }) =>
    disabled
      ? EH.Colors.FIN.Lt.Emphasis.Low
      : EH.Colors.FIN.Lt.Emphasis.Primary};

  &:hover {
    ${({ disabled }) =>
      disabled
        ? EH.Colors.FIN.Lt.Emphasis.Low
        : EH.Colors.FIN.Lt.Buttons.Primary.Hover.Bg};
  }

  &:active {
    ${({ disabled }) =>
      disabled
        ? EH.Colors.FIN.Lt.Emphasis.Low
        : EH.Colors.FIN.Lt.Buttons.Primary.Pressed.Bg};
  }

  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

interface StyledTitleProps {
  disabled?: boolean;
}

export const StyledTitle = styled.h3<StyledTitleProps>`
  ${EH.Typography.Label15.SB600};
  display: flex;
  color: ${({ disabled }) =>
    disabled ? EH.Colors.FIN.Lt.Emphasis.Low : EH.Colors.FIN.Lt.Emphasis.High};
`;

export const StyledDropdown = styled(EH.Dropdowns.Dropdown)`
  width: 100px;

  button {
    width: 100%;
  }
`;
