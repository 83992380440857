import React from 'react';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import cn from 'classnames';

import { MARGIN } from '@float/common/components/Schedule/Cell/MainCell/Item/PhaseItem';
import { useProjectColor } from '@float/common/components/Schedule/hooks/useProjectColor';
import { Phase, Project, ProjectStatus } from '@float/types';

import * as styles from './PhaseBar.css';

type PhaseBarProps = {
  phase: Phase;
  project: Project;
  x: number;
  y: number;
  w: number;
  fadeStart: boolean;
  fadeEnd: boolean;
};

type Colors = {
  backgroundColor?: string;
  borderColor?: string;
  textColor?: string;
};

export const PHASE_BAR_HEIGHT = 28;

const phaseBarStyles = (colors: Colors, x: number, w: number, y: number) =>
  assignInlineVars({
    color: colors.textColor ?? '',
    [styles.phaseColorVar]: colors.backgroundColor ?? '',
    [styles.phaseBorderColorVar]: colors.borderColor ?? '',
    [styles.phaseTransparentBgColorVar]: '#FFF',
    [styles.phaseBarWidth]: `${w}px`,
  });

function getFadeVariant(fadeStart: boolean, fadeEnd: boolean) {
  if (fadeStart && fadeEnd) return styles.FadeVariants.Both;

  if (fadeStart) return styles.FadeVariants.Start;

  if (fadeEnd) return styles.FadeVariants.End;

  return undefined;
}

export function PhaseBar(props: PhaseBarProps) {
  const { phase, project, x, y, w, fadeStart, fadeEnd } = props;
  const colors = useProjectColor(project, phase);
  const status = phase.status ?? project.status ?? ProjectStatus.Confirmed;

  return (
    <div
      className={cn(
        styles.box({ status }),
        styles.phaseBar({ fade: getFadeVariant(fadeStart, fadeEnd) }),
      )}
      style={{
        left: x,
        width: w,
        top: y * PHASE_BAR_HEIGHT + MARGIN,
        ...phaseBarStyles(colors, x, w, y),
      }}
    >
      {phase.phase_name}
    </div>
  );
}
