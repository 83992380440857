import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { TooltipContentProps } from '@radix-ui/react-tooltip';

import { Tooltip, TooltipVariants } from '@float/ui/components/Tooltip';

import {
  DEFAULT_TOOLTIP_PLACEMENT,
  TOOLTIP_DELAY,
  TOOLTIP_DISTANCE,
} from '../QuickInput.constants';

export interface QuickInputTooltipProps {
  children: React.ReactNode;
  name: string;
  open: boolean;
  placement?: TooltipContentProps['side'];
  variant: TooltipVariants;
}

export const QuickInputTooltip = function ({
  children,
  name,
  open,
  placement = DEFAULT_TOOLTIP_PLACEMENT,
  variant,
}: QuickInputTooltipProps) {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <Tooltip
      content={<ErrorMessage name={name} errors={errors} />}
      delay={TOOLTIP_DELAY}
      distance={TOOLTIP_DISTANCE}
      open={open}
      placement={placement}
      variant={variant}
    >
      {children}
    </Tooltip>
  );
};
