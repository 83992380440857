import { useFormContext } from 'react-hook-form';

import { FormType, ProjectFormData } from '../types';

export function checkIsTemplate(type: FormType) {
  return type === FormType.ProjectTemplate || type === FormType.PhaseTemplate;
}

export function useIsTemplate() {
  const { getValues } = useFormContext<ProjectFormData>();
  const type = getValues('type');
  return checkIsTemplate(type);
}
