import styled from 'styled-components';

import EH from '@float/ui/deprecated/Earhart';

export const StyledModalBody = styled.div`
  h3 {
    font-size: 18px;
    font-weight: 600;

    color: ${(p) => p.theme.flayLight12};
  }

  strong {
    font-weight: 600;

    color: ${(p) => p.theme.flayLight11};
  }

  ul {
    font-size: 16px;
    line-height: 21px;
    margin-top: 9px;
    margin-left: 19px;

    li {
      list-style-type: initial;
      margin-top: 10px;
    }
  }

  a {
    color: ${(p) => p.theme.blue};
    font-size: 16px;
    text-decoration: none;
    margin-top: 8px;
    font-size: 16px;
    display: block;
  }

  p {
    &:first-child {
      margin-top: 0px;
    }
    color: ${(p) => p.theme.flayLight11};
    font-size: 18px;
    letter-spacing: -0.2px;
    margin-top: 24px;
  }
`;

export const StyledErrorMesasge = styled.span`
  ${EH.Typography.TextM.R400}
  display: inline-block;
  margin-top: 24px;
  color: ${(p) => p.theme.red};
`;

export const StyledModalHeader = styled.div`
  p {
    margin-top: 12px;
    color: ${EH.Colors.Core.Text.Secondary};

    ${EH.Typography.TextXL.R400};
  }
`;
