import React, { useState } from 'react';
import { noop } from 'lodash';

import { formatClockTimeAsHours } from '@float/common/lib/time';
import { displayHoursFormatIsDecimal } from '@float/common/lib/timer/displayHoursFormat';
import { formatDecimalHoursAsClockTime } from '@float/common/lib/timer/formatDecimalHoursAsClockTime';
import { TimerGlobalState } from '@float/common/reducers/lib/types';
import { getLoggedTimeActiveTimer } from '@float/common/selectors/timer';
import { useAppSelector } from '@float/common/store';
import { logger } from '@float/libs/logger';
import { InputTime } from '@float/ui/deprecated/InputTime';

import { LogTimeInputProps } from '../../types';
import { LogTimeHours } from '../LogTimeHours';
import { LogTimeTimerControls } from '../LogTimeTimerControls';
import { StyledBox, StyledBoxItem, StyledModalInput } from './styles';

function LogTimeInputAndTimer(props: LogTimeInputProps) {
  const {
    canStartTimer,
    className,
    entity,
    hours,
    isMobile,
    setHours,
    submit,
    suggestedHours,
    timers,
    user,
  } = props;

  const activeTimer = useAppSelector((state: TimerGlobalState) =>
    getLoggedTimeActiveTimer(state, entity.logged_time_id),
  );

  const [loading, setLoading] = useState(false);

  const displayAsDecimal = displayHoursFormatIsDecimal(user.prefs);
  const loggedTimeInputValue = formatDecimalHoursAsClockTime(hours || 0);
  const loggedTimeIsDisabled = Boolean(activeTimer);
  const suggestedTime = formatDecimalHoursAsClockTime(suggestedHours);
  const suggestedTimeIsDisabled =
    Boolean(!suggestedHours) || Boolean(activeTimer);
  const timerEntriesCount = timers?.length || 0;

  const onChangeLoggedTimeHours = (hrs: number) => {
    setHours(hrs);

    if (hrs !== null) {
      localStorage.setItem('logTimeDefaultHours', hrs.toString());
    }
  };

  const onChangeLoggedTimeClockTime = (time: string, isCompleted: boolean) => {
    if (isCompleted) {
      const hrs = formatClockTimeAsHours(time);

      onChangeLoggedTimeHours(hrs);
    }
  };

  const onSubmitLoggedTime = (hours: string | number) => {
    let hrs = hours;

    if (typeof hrs === 'string') {
      hrs = formatClockTimeAsHours(hours as string);
    }

    submit(hrs);
  };

  const onClickTimerButton = async () => {
    setLoading(true);

    if (!activeTimer) {
      try {
        await props.onTimerStart();
      } catch (err) {
        logger.error(
          'Encountered an error invoking the "onTimerStart" callback',
          err,
        );
      }
    } else {
      try {
        await props.onTimerStop(activeTimer);
      } catch (err) {
        logger.error(
          'Encountered an error invoking the "onTimerStop" callback',
          err,
        );
      }
    }

    setLoading(false);
  };

  return (
    <StyledModalInput className={className}>
      <StyledBox>
        <StyledBoxItem>
          {displayAsDecimal ? (
            <LogTimeHours
              autoFocus={isMobile}
              disabled={loggedTimeIsDisabled}
              onChange={onChangeLoggedTimeHours}
              onSubmit={onSubmitLoggedTime}
              value={hours}
            />
          ) : (
            <InputTime
              appearanceDisabled={loggedTimeIsDisabled}
              autoFocus={isMobile}
              disabled={loggedTimeIsDisabled}
              min="00:01"
              label="Logged"
              onUpdate={onChangeLoggedTimeClockTime}
              onSubmit={onSubmitLoggedTime}
              timeFormat="24"
              timeType="duration"
              value={loggedTimeInputValue}
            />
          )}
        </StyledBoxItem>

        <StyledBoxItem>
          {displayAsDecimal ? (
            <LogTimeHours
              label="Suggested"
              onChange={noop}
              onSubmit={noop}
              readOnly={true}
              noBorder={true}
              disabled={suggestedTimeIsDisabled}
              value={suggestedHours}
            />
          ) : (
            <InputTime
              appearance="ghost"
              appearanceDisabled={suggestedTimeIsDisabled}
              label="Suggested"
              readOnly
              timeFormat="24"
              timeType="duration"
              value={suggestedTime}
            />
          )}
        </StyledBoxItem>
      </StyledBox>

      <StyledBox>
        <LogTimeTimerControls
          activeTimer={activeTimer}
          canStartTimer={canStartTimer}
          loading={loading}
          onClickTimerButton={onClickTimerButton}
          timerEntriesCount={timerEntriesCount}
        />
      </StyledBox>
    </StyledModalInput>
  );
}

export { LogTimeInputAndTimer };
