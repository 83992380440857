import differenceInDays from 'date-fns/differenceInDays';

import { Timeframe } from '@float/types/timeframes';

/**
 * Check if a given `Timeframe` has a start date after or on the provided
 * `startDate`
 */
export const getDoesTimeframeStartInBounds = (
  timeframe: Timeframe,
  startDate: Date,
) => {
  const daysBetweenStartDateAndStartOfFirstTimeframe = differenceInDays(
    timeframe.startDate,
    startDate,
  );

  const timeFrameStartsInBounds =
    daysBetweenStartDateAndStartOfFirstTimeframe >= 0;

  return timeFrameStartsInBounds;
};

/**
 * If a given `Timeframe` has a start date after or on the provided `startDate`
 * returns the `Timeframe.startDate`, otherwise return the passed in `startDate`
 */
export const getEffectiveTimeframeStartDate = (
  timeframe: Timeframe,
  startDate: Date,
) => {
  const startsInBounds = getDoesTimeframeStartInBounds(timeframe, startDate);

  return startsInBounds ? timeframe.startDate : startDate;
};

export const getIsTimeFrameInRage = (
  timeframe: Timeframe,
  startDate: Date,
  endDate: Date,
) => timeframe.startDate <= endDate && timeframe.endDate >= startDate;

export const getIndexOfFirstTimeFrameInRange = (
  timeframes: Timeframe[],
  startDate: Date,
  endDate: Date,
) =>
  timeframes.findIndex((timeframe) =>
    getIsTimeFrameInRage(timeframe, startDate, endDate),
  );

export const getIndexOfLastTimeFrameInRange = (
  timeframes: Timeframe[],
  startDate: Date,
  endDate: Date,
) =>
  timeframes.findLastIndex((timeframe) =>
    getIsTimeFrameInRage(timeframe, startDate, endDate),
  );
