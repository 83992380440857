import { Task } from '@float/types/task';
import { Timeoff } from '@float/types/timeoff';

export type RepeatableEntity = Pick<
  Task | Timeoff,
  'repeat_state' | 'repeat_end_date' | 'end_date' | 'start_date'
>;

export type RepeatedDate = {
  start: number;
  end: number;
};

export function getRepeatedDates(
  dates: DatesManager,
  entity: RepeatableEntity,
): RepeatedDate[] {
  if (!entity.repeat_state || !entity.repeat_end_date) {
    return [];
  }

  const length = dates.toNum(entity.end_date) - dates.toNum(entity.start_date);

  const repeats = dates.getRepeatStarts(
    entity.repeat_state,
    entity.start_date,
    entity.repeat_end_date,
  );

  return repeats.map((repetitionStart) => {
    const start = dates.toNum(repetitionStart);
    const end = start + length;

    return { start, end };
  });
}
