import styled, { css } from 'styled-components';

import * as Animation from '@float/ui/deprecated/Earhart/Animation';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

// Table

export const StyledTable = styled.table`
  border-collapse: separate;
  border-spacing: 0 8px;

  margin-top: -8px;
  margin-bottom: -8px;

  width: 100%;

  a {
    transition-property: color;
    transition-duration: ${Animation.Settings.Duration.Short};
    transition-timing-function: ${Animation.Settings.Easing.InOutCurve};
  }
`;

// Header

export const StyledTableHeader = styled.th`
  ${Typography.Label12.SB600};

  line-height: 2;

  color: ${Colors.FIN.Lt.Emphasis.High};

  vertical-align: middle;

  width: ${({ $width }) => $width};

  &:first-child {
    padding-left: 16px;
  }

  &:last-child {
    padding-right: 10px;
  }

  &.actions:last-child {
    width: 1%;
    white-space: nowrap;
  }

  .row-count {
    color: #868d92;
    padding-left: 5px;
    font-weight: normal;
  }

  a {
    color: ${Colors.FIN.Lt.Emphasis.Medium};

    &:hover {
      color: ${Colors.FIN.Lt.Emphasis.Primary};
    }
  }
`;

export const StyledSortButton = styled.a`
  --svg-icon-color: ${Colors.FIN.Lt.Emphasis.High};

  display: inline-flex;
  align-items: center;

  vertical-align: middle;

  margin-bottom: 2px;

  transition-property: fill, stroke;
  transition-duration: ${Animation.Settings.Duration.Short};
  transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

  &:hover {
    --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Primary};
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

// Actions

export const StyledAction = styled.a`
  position: relative;

  display: inline-flex;

  opacity: 0;

  padding: 4px;

  pointer-events: none;

  transition-property: opacity, pointer-events;
  transition-duration: ${Animation.Settings.Duration.Normal};
  transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

  &:after {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    content: '';

    border-radius: 6px;

    background-color: ${Colors.Primary.Flay.Light[7]};

    opacity: 0;

    transform: scale(0.9, 0.9);

    z-index: 0;

    transition-property: transform, background-color, opacity;
    transition-duration: ${Animation.Settings.Duration.Normal};
    transition-timing-function: ${Animation.Settings.Easing.InOutCurve};
  }

  svg {
    --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Medium};

    position: relative;

    z-index: 1;

    transition-property: stroke, fill;
    transition-duration: ${Animation.Settings.Duration.Normal};
    transition-timing-function: ${Animation.Settings.Easing.InOutCurve};
  }

  &:hover,
  &.active {
    &:after {
      opacity: 1;
      transform: none;
    }

    &,
    &.delete,
    &.cancel {
      svg {
        --svg-icon-color: ${Colors.FIN.Lt.Emphasis.High};
      }
    }

    &.update {
      svg {
        --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Primary};
      }
    }
  }

  &:active {
    s &:after {
      background-color: ${Colors.Primary.Flay.Light[8]};
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &.delete,
  &.cancel {
    svg {
      --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Low};
    }
  }
`;

export const StyledNoAction = styled.div`
  height: 32px;
`;

// Columns

export const StyledColumnAction = styled.td`
  text-align: right;

  white-space: nowrap;

  > * {
    vertical-align: middle;
  }
`;

// Rows

export const StyledNoRows = styled.tr`
  ${Typography.Label14.R400};

  color: ${Colors.FIN.Lt.Emphasis.Medium};
`;

export const StyledErrorRow = styled(StyledNoRows)`
  color: ${Colors.FIN.Lt.Danger.Default};
`;

const RowColors = {
  Odd: Colors.FIN.Lt.Surface.Surf2,
  Even: 'transparent',
  Hover: Colors.FIN.Lt.Surface.Surf4,
  Active: Colors.FIN.Lt.Surface.Surf4,
};

export const StyledRow = styled.tr`
  position: relative;

  &.clickable-row {
    cursor: pointer;
  }

  &.trigger-fetch {
    height: 30px;
  }

  &:nth-child(odd),
  &.odd {
    td,
    .cell {
      background-color: ${RowColors.Odd};
      .outline {
        outline-color: ${RowColors.Odd};
      }
    }
  }

  &.even {
    td,
    .cell {
      background-color: ${RowColors.Even};
      .outline {
        outline-color: ${RowColors.Even};
      }
    }
  }

  ${({ enableHover }) =>
    enableHover &&
    css`
      &:hover {
        td,
        .cell {
          background-color: ${RowColors.Hover};
          .outline {
            outline-color: ${RowColors.Hover};
          }
        }

        ${StyledAction} {
          opacity: 1;
          pointer-events: auto;
        }
      }
    `}

  &.active {
    td,
    .cell {
      background-color: ${RowColors.Hover};
      .outline {
        outline-color: ${RowColors.Hover};
      }
    }

    ${StyledAction} {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &.active {
    td,
    .cell {
      background-color: ${RowColors.Active};
      .outline {
        outline-color: ${RowColors.Active};
      }
    }
  }

  &.loader-row {
    border-bottom: 0;
    height: 50px;

    td {
      background-color: transparent !important;
    }

    .loader {
      position: static;
      margin: 20px auto;
      left: 0 !important;
      top: 0 !important;
    }
  }

  td,
  .cell {
    ${Typography.TextM.R400};

    color: ${Colors.FIN.Lt.Emphasis.Medium};
    svg {
      --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Medium};
    }

    background-color: rgba(0, 0, 0, 0);
    .outline {
      outline-color: rgba(0, 0, 0, 0);
    }

    transition-property: background-color;
    transition-duration: ${Animation.Settings.Duration.Normal};
    transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

    padding: 10px 0px;
    white-space: pre-wrap;
    overflow-wrap: anywhere;

    &:first-child,
    &:last-child {
      padding: 10px 10px 10px 16px;
    }

    &:first-child {
      ${Typography.TextM.M500};

      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;

      color: ${Colors.FIN.Lt.Emphasis.High};
    }

    &:last-child {
      padding-left: 0;
      border-bottom-right-radius: 12px;
      border-top-right-radius: 12px;
    }

    &.editing {
      padding-top: 4px;
      padding-left: 4px;
      padding-bottom: 4px;
      height: 52px;

      .input-text {
        height: 42px;
      }
    }

    &.multiline li:not(:first-child) {
      ${Typography.TextM.R400};
    }

    .input-text {
      display: flex;
      flex: 1;

      ${Typography.TextM.R400};

      color: ${Colors.FIN.Lt.Emphasis.High};

      border-width: 1px;
      border-style: solid;
      border-color: ${Colors.FIN.Lt.Stroke.Stroke2};

      appearance: none;
      outline: none;

      padding: 0 11px;
      margin: 0;

      width: 100%;
      height: 100%;

      border-radius: 5px;
    }
  }
`;

export const StyledVirtualizedRow = styled.div`
  width: 100%;

  ${StyledRow} {
    display: flex;
    width: 100%;
    height: 52px;
    border-radius: 6px;

    background-color: rgba(0, 0, 0, 0);

    transition-property: background-color;
    transition-duration: ${Animation.Settings.Duration.Normal};
    transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

    ${StyledColumnAction} {
      position: absolute;
      right: 0px;
    }

    .cell {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      height: 100%;
      background-color: transparent;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 10px;
      }
    }
  }

  &.odd {
    ${StyledRow} {
      background-color: ${RowColors.Odd};
      .outline {
        outline-color: ${RowColors.Odd};
      }
    }
  }

  &.even {
    ${StyledRow} {
      background-color: ${RowColors.Even};

      .outline {
        outline-color: ${RowColors.Even};
      }
    }
  }

  &:hover,
  &.active {
    ${StyledRow} {
      background-color: ${RowColors.Hover};

      .outline {
        outline-color: ${RowColors.Hover};
      }
    }
  }

  &.active {
    ${StyledRow} {
      background-color: ${RowColors.Active};

      .outline {
        outline-color: ${RowColors.Active};
      }
    }
  }
`;
