import { useEffect } from 'react';
import { Virtualizer } from '@tanstack/react-virtual';

type Props = {
  loadMore?: () => void;
  virtualizer: Virtualizer<HTMLDivElement, Element>;
};

export const useInfiniteScroll = (props: Props) => {
  const { virtualizer, loadMore } = props;

  const virtualItems = virtualizer.getVirtualItems();
  const total = virtualizer.options.count;

  useEffect(() => {
    const lastItem = virtualItems.at(-1);

    if (!lastItem) {
      return;
    }

    if (lastItem.index >= total - 1) {
      if (loadMore) loadMore();
    }
  }, [loadMore, total, virtualItems]);
};
