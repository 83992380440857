import React from 'react';

export default function () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="16"
      viewBox="0 0 18 16"
    >
      <path
        fill="none"
        fillRule="evenodd"
        stroke="#C1C1C1"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.333 11L5 15v-4H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-4.667z"
      />
    </svg>
  );
}
