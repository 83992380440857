import React, { useEffect, useMemo, useState } from 'react';

import { getEntranceAnimation } from '@float/common/lib/animateEntrance';
import { entityLoggable } from '@float/common/lib/rights';
import { isFullDayTimeoff } from '@float/common/lib/timeoffs';
import { SerenaState } from '@float/common/selectors/serena';
import {
  PersonProjectRow,
  PersonRow,
  ProjectRow,
} from '@float/common/serena/Data/useScheduleRows';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { CellItem } from '@float/types';
import { Icons, useSnackbar } from '@float/ui/deprecated';
import { useDragTooltipWarning } from '@float/ui/deprecated/Tooltip/hooks/useDragTooltipWarning';

import { ScheduleActions } from '../types';
import { BaseEntityLayer, BaseEntityLayerProps } from './BaseEntityLayer';
import { BoxLoggedTimeItem } from './box/BoxLoggedTimeItem';
import GhostLayer from './GhostLayer';
import { isLoggedItemGhostTask } from './GhostLayer/helpers/isGhostTask';
import { getDimensions } from './helpers';
import getTooltipContent from './LoggedTimeItemTooltip';

import { lockedMessage } from './LoggedTimeItem.css';
import { bottomResize } from './styles.css';

type Props = {
  actions: ScheduleActions;
  dayWidth: number;
  hourHeight: number;
  item: CellItem<'loggedTime'>;
  logMyTimeView?: boolean;
  onItemClick?: () => void;
  onItemDragChange?: (dragging: boolean) => void;
  onItemDragError?: (error: string) => void;
  printMode?: boolean;
  reduxData: SerenaState;
  row: PersonRow | PersonProjectRow | ProjectRow;
  suvSingleDay?: string | null;
  tooltipWithWarningProps?: BaseEntityLayerProps['tooltipWithWarningProps'];
};

const LoggedTimeItem = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    actions,
    dayWidth,
    hourHeight,
    item,
    logMyTimeView,
    onItemClick,
    onItemDragChange,
    onItemDragError,
    printMode,
    reduxData,
    row,
    suvSingleDay,
    tooltipWithWarningProps,
  } = props;
  const { isStart, isEnd, entity, type } = item;
  const [animateEntrance, setAnimateEntrance] = useState(() =>
    getEntranceAnimation(item.entity),
  );
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (animateEntrance) {
      setAnimateEntrance(false);
    }
  }, [animateEntrance]);

  const projectId =
    'project_id' in item.entity ? item.entity.project_id : undefined;
  const project = projectId ? reduxData.projects[projectId] : undefined;

  if (!project) return null;

  // The BaseLayer is used by many cell entities (Task, Ghost, LoggedTime, LinkedTasks)
  // and since we introduced Compliant Task Colors (PSQ1-287) the default white
  // background-color is not needed / suits all instances. Until we refactor/rewrite
  // all the task entities, I am adding this prop to optinionally set the base layer
  // transparent as needed — in the future I belive we will be able to drop this
  // and the above 'background-color: white' default style.
  const transparent = type === 'loggedTime';

  const dimensions = getDimensions(item, dayWidth, hourHeight, suvSingleDay);

  const isEditable = actions.isItemEditable(item);

  const isLocked =
    item.isInLockPeriod && !entityLoggable(entity, reduxData, true);

  if (isLoggedItemGhostTask(item, row, reduxData)) {
    return (
      <GhostLayer
        ref={ref}
        actions={actions}
        dimensions={dimensions}
        isEnd={isEnd}
        isStart={isStart}
        item={item}
      />
    );
  }

  return (
    <BaseEntityLayer
      {...props}
      tooltipWithWarningProps={tooltipWithWarningProps}
      ref={ref}
      rounded
      isStart={isStart}
      isEnd={isEnd}
      style={{
        ...dimensions,
        opacity: animateEntrance ? 0.5 : 1,
      }}
      transparent={transparent}
      data-floatid={item.key}
    >
      <BoxLoggedTimeItem
        actions={actions}
        dayWidth={dayWidth}
        hourHeight={hourHeight}
        item={item}
        logMyTimeView={logMyTimeView}
        printMode={printMode}
        reduxData={reduxData}
        suvSingleDay={suvSingleDay}
        isEditable={isEditable}
        user={reduxData.user}
        onItemDragChange={onItemDragChange}
        onItemDragError={onItemDragError}
        onItemClick={onItemClick}
      />
      {(isEditable || isLocked) &&
        !actions.hideResize() &&
        !isFullDayTimeoff(entity) && (
          <div
            className={bottomResize}
            onMouseDown={(e) => {
              if (e.button !== 0) return prevent(e);
              e.stopPropagation();

              if (isLocked) {
                showSnackbar(
                  <span>
                    <Icons.LockOutline
                      className={lockedMessage}
                      color="#fff"
                      size={16}
                    />
                    These dates are locked. Contact the Project owner to update.
                  </span>,
                  { id: 'llt' },
                );
                return;
              }

              actions.onItemResizeStart(item, 'B');
            }}
          />
        )}
    </BaseEntityLayer>
  );
});

type LoggedTimeItemWithTooltipsProps = Props & {
  boundaryRef: React.MutableRefObject<Element | Element[] | undefined>;
};

function LoggedTimeItemWithTooltips(props: LoggedTimeItemWithTooltipsProps) {
  const { actions, item, reduxData, boundaryRef } = props;
  const { isItemTooltipEnabled } = actions;

  const content = useMemo(
    () => getTooltipContent(item, reduxData),
    [item, reduxData],
  );

  const isTooltipEnabled = isItemTooltipEnabled(item);

  const {
    isWarningForcedToBeHidden,
    isWarningVisible,
    onItemClick,
    onItemDragChange,
    onItemDragError,
    warningMessage,
  } = useDragTooltipWarning();

  const tooltipWithWarningProps = {
    collisionBoundary: boundaryRef?.current,
    content,
    enabled: isTooltipEnabled,
    shouldWarningForcedToBeHidden: isWarningForcedToBeHidden,
    shouldWarningBeVisible: isWarningVisible,
    warningMessage,
  };

  return (
    <LoggedTimeItem
      {...props}
      tooltipWithWarningProps={tooltipWithWarningProps}
      onItemDragChange={onItemDragChange}
      onItemClick={onItemClick}
      onItemDragError={onItemDragError}
    />
  );
}

export default React.memo(LoggedTimeItemWithTooltips);
