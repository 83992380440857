import { moment } from '@float/libs/moment';

export const formatTickDate = (d, isMonthMode = false) => {
  const pattern = isMonthMode ? 'MMM YYYY' : 'D MMM YYYY';
  return moment(d).format(pattern);
};

export const getTimeoffHours = (d, isLogged) => {
  if (isLogged) {
    return Math.max(d.timeoff - d.tentative.timeoff, 0);
  }
  return d.timeoff;
};
