import React, { useMemo } from 'react';

import {
  EarhartButton,
  EarhartControl,
} from '@float/common/components/Schedule/util/Controls/styles';
import { hasCollapsedProject } from '@float/common/serena/util/hasCollapsedProject';
import useWindowSize from '@float/libs/web/hooks/useWindowSize';
import { Icons } from '@float/ui/deprecated';

import * as styles from './ProjectsCollapseToggle.css';

export default function ProjectsCollapseToggle({
  expandedProjectIds,
  totalHeight,
  onClick,
}: {
  expandedProjectIds: Record<number, boolean>;
  totalHeight: number;
  onClick: () => void;
}) {
  const { height: windowHeight } = useWindowSize();
  const style = useMemo(() => {
    if (totalHeight + 176 > windowHeight!) return { bottom: 24 };
    return { top: totalHeight + 130 };
  }, [totalHeight, windowHeight]);

  const showExpand = hasCollapsedProject({
    expandedProjectIds,
  });

  return (
    <EarhartControl
      className={styles.wrapper}
      style={style}
      onClick={() => onClick()}
    >
      {/* @ts-expect-error EarhartButton doesn't have types */}
      <EarhartButton className={styles.content} equalPadding emphasisHigh>
        {showExpand ? (
          <>
            <Icons.ExpandSmall />
            <span className={styles.text}>Expand</span>
          </>
        ) : (
          <>
            <Icons.CollapseSmall />
            <span className={styles.text}>Collapse</span>
          </>
        )}
      </EarhartButton>
    </EarhartControl>
  );
}
