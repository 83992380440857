import { format } from 'date-fns';

/**
 * @deprecated
 *
 * This method uses Date to parse the dateString which is too unpredictable.
 *
 * See: https://github.com/floatschedule/float-javascript/wiki/Date-parsing-and-timezones
 *
 * Use `formatAsFloatDate` from `@float/libs/dates/parse` instead.
 */
export const formatDate = (dateString: Date | DateString): string => {
  try {
    return format(new Date(dateString), 'yyyy-MM-dd');
  } catch (error) {
    return 'Invalid Date';
  }
};
