import React, { ReactNode } from 'react';
import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group';

import { useControllableState } from '@float/libs/hooks/useControllableState';

import { ToggleValue } from '../types';

type ToggleSingleGroupProps<V extends ToggleValue> = {
  value?: V;
  defaultValue?: V;
  onChange?: (value: V | undefined) => void;
  disabled?: boolean;
  readOnly?: boolean;
  disableUnselect?: boolean;
  children?: ReactNode;
  className?: string;
  'aria-labelledby'?: string;
};

export function ToggleSingleGroup<V extends ToggleValue>(
  props: ToggleSingleGroupProps<V>,
) {
  const [value, setValue] = useControllableState({
    prop: serializeValue(props.value),
    defaultProp: serializeValue(props.defaultValue),
    onChange(value) {
      if (props.onChange) {
        props.onChange(deserializeValue<V>(value));
      }
    },
  });

  function handleValueChange(value: string) {
    if (props.readOnly) return;
    if (props.disabled) return;
    if (value === '' && props.disableUnselect) return;

    setValue(value);
  }

  return (
    <ToggleGroupPrimitive.Root
      type="single"
      value={value || ''}
      onValueChange={handleValueChange}
      disabled={props.disabled}
      aria-labelledby={props['aria-labelledby']}
      className={props.className}
    >
      {props.children}
    </ToggleGroupPrimitive.Root>
  );
}
function serializeValue<V extends ToggleValue>(value: V | undefined) {
  if (value !== undefined) return JSON.stringify(value);

  return undefined;
}
function deserializeValue<V extends ToggleValue>(value: string): V {
  return value !== '' ? JSON.parse(value) : undefined;
}
