import { trackEvent } from '@float/common/lib/gtm';
import { getFiltersAnalyticsProps } from '@float/common/search/helpers/getFiltersAnalyticsProps';
import { SavedSearchFilterToken } from '@float/common/search/types';

export function trackSavedSearchApply(
  token: Pick<SavedSearchFilterToken, 'type' | 'filters' | 'val'>,
) {
  trackEvent('saved-filters-apply', {
    ...getFiltersAnalyticsProps(token.filters, false),
    savedSearchName: token.val,
  });
}
