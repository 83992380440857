import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import {
  getPeopleWithTasks,
  getProjectsWithTasks,
  getTaskedPeopleByProjectId,
  isPersonTaskedInThePhase,
  isPersonTaskedInTheProject,
  isProjectWithTasks,
} from '@float/common/selectors/search';

import { selectReportsFetchUrl } from '../../selectors/getReportsFetchUrl/selectReportsFetchUrl';
import { getSearchAutocompleteResults } from '../../selectors/getSearchAutocompleteResults';

export type SelectorParameters<T extends (...args: any) => any> = T extends (
  arg1: ReduxStateStrict,
  ...args: infer P
) => any
  ? P
  : never;

export type SearchWorkerSelectors = typeof searchSelectors;
export type SearchWorkerSelectorsKeys = keyof SearchWorkerSelectors;

// The map of the selectors that can be processed inside the SearchWorker

// OPTIMIZATION: If we were using Comlink.proxy instead the processing would
// happen in the main thread because proxy works only as a bridge and don't
// change the ownership of the function
export const searchSelectors = {
  selectReportsFetchUrl,
  getSearchAutocompleteResults,
  getPeopleWithTasks,
  getProjectsWithTasks,
  getTaskedPeopleByProjectId,
  isPersonTaskedInThePhase,
  isPersonTaskedInTheProject,
  isProjectWithTasks,
};
