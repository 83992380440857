import { t } from '@lingui/macro';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Resources } from '@float/common/api3/constants';
import {
  deleteLinearCyclesIntegrationConfig,
  getLinearCyclesIntegrationConfig,
  resyncLinearCyclesIntegrationConfig,
  upsertLinearCyclesIntegrationConfig,
} from '@float/common/api3/linearCyclesIntegration';
import { trackEvent } from '@float/common/lib/analytics';
import {
  getIsAnyQueryPending,
  resolveOptimisticDataFromMutations,
} from '@float/libs/utils/query';
import { LinearCyclesIntegrationConfig } from '@float/types/linearCyclesIntegration';
import { useSnackbar } from '@float/ui/deprecated/Snackbar/useSnackbar';
import { useDeferredLoadingState } from '@float/web/components/panelViews/Project/hooks/useDeferredLoadingState';

export const getActivationErrorMessage = (isActivating?: boolean) => {
  if (isActivating) {
    return t`There was a problem activating the Linear Cycle integration`;
  }

  return t`There was a problem deactivating the Linear Cycle integration`;
};

export const getActivationSuccessMessage = (isActivating?: boolean) => {
  if (isActivating) {
    return t`Linear calendar sync activated`;
  }

  return t`Linear calendar sync deactivated`;
};

export function useLinearIntegrationData() {
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { data: integrationConfig = null, isLoading: isLoadingGet } = useQuery({
    queryKey: [Resources.LinearCyclesIntegrationConfig],
    queryFn: getLinearCyclesIntegrationConfig,
  });

  const syncMutation = useMutation({
    mutationFn: resyncLinearCyclesIntegrationConfig,
    onError: () => {
      showSnackbar(
        t`There was a problem with synching the Linear Cycle integration`,
        { className: 'error', showClose: true },
      );
    },
    onSuccess: (result) => {
      queryClient.setQueryData(
        [Resources.LinearCyclesIntegrationConfig],
        result,
      );
      showSnackbar(t`Linear calendar synced`, { showClose: true });
    },
  });

  const upsertMutation = useMutation({
    mutationFn: upsertLinearCyclesIntegrationConfig,
    onError: () => {
      showSnackbar(
        t`There was a problem with updating the Linear Cycle integration. Make sure you are using the correct feed URL`,
        { className: 'error', showClose: true },
      );
    },
    onSuccess: (result) => {
      queryClient.setQueryData(
        [Resources.LinearCyclesIntegrationConfig],
        result,
      );
      trackEvent('Linear cycles integration connected');
      showSnackbar(t`Linear calendar synced`, { showClose: true });
    },
  });

  const activationStatusMutation = useMutation({
    mutationFn: upsertLinearCyclesIntegrationConfig,
    onError: (_, variables) => {
      showSnackbar(getActivationErrorMessage(variables.active), {
        className: 'error',
        showClose: true,
      });
    },
    onSuccess: (result) => {
      queryClient.setQueryData(
        [Resources.LinearCyclesIntegrationConfig],
        result,
      );
      showSnackbar(getActivationSuccessMessage(result.active), {
        showClose: true,
      });
    },
  });

  const deleteMutation = useMutation({
    mutationFn: deleteLinearCyclesIntegrationConfig,
    onError: () => {
      showSnackbar(
        t`There was a problem with deleting the Linear Cycle integration`,
        { className: 'error', showClose: true },
      );
    },
    onSuccess: (result) => {
      queryClient.setQueryData(
        [Resources.LinearCyclesIntegrationConfig],
        result,
      );
      trackEvent('Linear cycles integration disconnected');
      showSnackbar(t`Linear integration deleted`, { showClose: true });
    },
  });

  const isAnyQueryPending = getIsAnyQueryPending([
    isLoadingGet,
    deleteMutation.isPending,
    upsertMutation.isPending,
    activationStatusMutation.isPending,
    syncMutation.isPending,
  ]);

  const isLoading = useDeferredLoadingState(isAnyQueryPending);

  const pendingIntegrationConfig = resolveOptimisticDataFromMutations<
    LinearCyclesIntegrationConfig | null | undefined
  >(integrationConfig, [
    {
      isPending: syncMutation.isPending,
      variables: syncMutation.variables,
      isPartialUpdate: true,
    },
    {
      isPending: upsertMutation.isPending,
      variables: upsertMutation.variables,
      isPartialUpdate: true,
    },
    {
      isPending: activationStatusMutation.isPending,
      variables: activationStatusMutation.variables,
      isPartialUpdate: true,
    },
    { isPending: deleteMutation.isPending, variables: null },
  ]);

  return {
    deleteConfig: deleteMutation.mutate,
    integrationConfig: pendingIntegrationConfig,
    isLoading,
    syncConfig: syncMutation.mutate,
    updateActivationStatus: activationStatusMutation.mutate,
    upsertConfig: upsertMutation.mutate,
  };
}
