import React, { useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { ReduxState } from '@float/common/reducers/lib/types';
import { Person } from '@float/types';
import ImportModal from '@float/web/importCsv/ImportModal/ImportModal';

import { mapPeopleHeaderToKeys, validatePeopleRow } from './peopleImport';

interface ImportPeopleModalProps extends PropsFromRedux {
  processId?: number;
  errors?: unknown[]; // Update the type as needed
  duplicates?: unknown[]; // Update the type as needed
  success?: boolean;
  noBgTransition: boolean;
  initialStep: string;
  onSave?: () => void;
  onClose: () => void;
}

function ImportPeopleModal({
  processId,
  errors,
  duplicates,
  success,
  people,
  noBgTransition,
  initialStep,
  onSave,
  onClose,
}: ImportPeopleModalProps) {
  const meta = useRef<{
    keyMap: { [key: string]: string };
    people: Person[]; // Update the type as needed
  }>({ keyMap: {}, people: [] });

  const beforeValidate = (row: { [key: string]: string }) => {
    meta.current = {
      keyMap: mapPeopleHeaderToKeys(Object.keys(row)) as {
        [key: string]: string;
      },
      people: Object.values(people),
    };
  };

  const validateRow = (row: { [key: string]: string }) => {
    return validatePeopleRow(row, meta.current);
  };

  return (
    <ImportModal
      type="people"
      processId={processId}
      errors={errors}
      duplicates={duplicates}
      success={success}
      beforeValidate={beforeValidate}
      validateRow={validateRow}
      onSave={onSave || onClose}
      onCancel={onClose}
      initialStep={initialStep}
      noBgTransition={noBgTransition}
    />
  );
}

const mapStateToProps = (state: ReduxState) => ({
  people: state.people.people,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ImportPeopleModal);
