import {
  CellItem,
  CellsMap,
  TimeRange,
  TopCell,
  TopCellId,
} from '@float/types/cells';
import { Phase } from '@float/types/phase';

import { getEntityMeta } from './entityMeta';
import { SELECTION_ID } from './selection';
import { CellFns, CellHelpersProps } from './types';

function buildTimeRangeCell(
  props: CellHelpersProps,
  cells: CellsMap,
  cellKey: TopCellId,
  fns: CellFns, // Move these functions in a module
) {
  const { getHorizontalDimensions } = fns;
  const { dates, maps, bimaps, leftHiddenDays, rightHiddenDays } = props;

  const cell = cells[cellKey];
  const { colIdx } = cell;
  const firstCellDay = colIdx * 7 + leftHiddenDays;
  const lastCellDay = (colIdx + 1) * 7 - 1 - rightHiddenDays;
  const maxVisibleSubCol = 7 - rightHiddenDays - leftHiddenDays - 1;

  const { timeRange: timeRangeMap, selection: selectionsMap } = maps;

  const { timeRange: timeRangeBimap, selection: selectionsBimap } = bimaps;

  // TODO: earhart - duplicated from buildProjectCell
  function setVisibleLength(entity: Phase | TimeRange) {
    const [endCol, endSubCol] = dates.toDescriptor(entity.end_date);
    const [startCol, startSubCol] = dates.toDescriptor(entity.start_date);

    entity.visibleLength =
      dates.diffDays(entity.start_date, entity.end_date) + 1;

    for (let col = startCol; col <= endCol; col++) {
      // Any middle column will have all of its left and right days suppressed
      if (startCol < col) entity.visibleLength -= leftHiddenDays;
      if (endCol > col) entity.visibleLength -= rightHiddenDays;

      if (col === startCol) {
        if (startSubCol < 0) entity.visibleLength += startSubCol;
        if (startSubCol > maxVisibleSubCol) {
          // We already subtracted the right hidden days for this col, but we
          // might have been overly aggressive. Adjust.
          entity.visibleLength += startSubCol - 1 - maxVisibleSubCol;
        }
      }

      if (col === endCol) {
        if (endSubCol < 0) entity.visibleLength -= endSubCol + 1;
        if (endSubCol > maxVisibleSubCol) {
          entity.visibleLength -= endSubCol - maxVisibleSubCol;
        }
      }
    }
  }

  timeRangeBimap.getRev(cellKey).forEach((id) => {
    const t = timeRangeMap[id];
    const { x, w } = getHorizontalDimensions(t, firstCellDay, lastCellDay);
    const [startCol, startSubCol] = dates.toDescriptor(t.start_date);

    // @entity.length
    if (!t.length) {
      t.length = dates.diffDays(t.start_date, t.end_date) + 1;
    }

    setVisibleLength(t);

    const isStart =
      (colIdx === startCol && startSubCol <= maxVisibleSubCol) ||
      (colIdx === startCol + 1 && startSubCol > maxVisibleSubCol);

    const cellItem: CellItem<'timeRange'> = {
      cellKey,
      key: `${cellKey}:timeRange:${t.time_range_id}`,
      type: 'timeRange' as const,
      entity: t,
      entityId: t.time_range_id,
      x,
      w,
      isStart,
      isEnd: isStart,
      isPlaceholder: getEntityMeta(t, 'isPlaceholder'),
    };

    if (w > 0) cell.items.push(cellItem);

    if (!isStart) {
      const [startCol] = dates.toDescriptor(t.start_date);
      cell.dependsOnCols.push(startCol);
    }
  });

  selectionsBimap.getRev(cellKey).forEach((id) => {
    const s = selectionsMap[id];
    const { x, w } = getHorizontalDimensions(s, firstCellDay, lastCellDay);

    const cellItem: CellItem<'selection'> = {
      cellKey,
      key: `${cellKey}:selection:${SELECTION_ID}`,
      type: 'selection',
      entity: s,
      entityId: SELECTION_ID,
      x,
      w,
    };

    if (w > 0) cell.items.push(cellItem);
  });

  return cell;
}

export function buildTopCell(
  props: CellHelpersProps,
  cells: CellsMap,
  cellKey: TopCellId,
  fns: CellFns, // Move these functions in a module
) {
  const { dates, maps, bimaps, leftHiddenDays, rightHiddenDays } = props;

  const {
    timeoff: timeoffsMap,
    holiday: holidaysMap,
    milestone: milestonesMap,
  } = maps;

  const {
    timeoff: timeoffsBimap,
    holiday: holidaysBimap,
    milestone: milestonesBimap,
  } = bimaps;

  const cell = cells[cellKey] as TopCell;
  const { colIdx } = cell;
  const firstCellDay = colIdx * 7 + leftHiddenDays;
  const lastCellDay = (colIdx + 1) * 7 - 1 - rightHiddenDays;

  const highlights: TopCell['highlights'] = [];

  holidaysBimap.getRev(cellKey).forEach((id) => {
    const h = holidaysMap[id];
    for (
      let i = Math.max(firstCellDay, dates.toNum(h.start_date));
      i <= Math.min(lastCellDay, dates.toNum(h.end_date));
      i++
    ) {
      const x = i - firstCellDay;
      (highlights[x] || (highlights[x] = [])).push(h);
    }
  });

  // Public holidays exist as timeoffs with a region_holiday_id. For
  // highlights purposes, treat them the same as holidays.
  timeoffsBimap.getRev(cellKey).forEach((id) => {
    const h = timeoffsMap[id];

    if (!h.region_holiday_id) return;

    for (
      let i = Math.max(firstCellDay, dates.toNum(h.start_date));
      i <= Math.min(lastCellDay, dates.toNum(h.end_date));
      i++
    ) {
      const x = i - firstCellDay;
      (highlights[x] || (highlights[x] = [])).push(h);
    }
  });

  milestonesBimap.getRev(cellKey).forEach((id) => {
    const m = milestonesMap[id];

    for (
      let i = Math.max(firstCellDay, dates.toNum(m.start_date));
      i <= Math.min(lastCellDay, dates.toNum(m.end_date));
      i++
    ) {
      const x = i - firstCellDay;
      (highlights[x] || (highlights[x] = [])).push(m);
    }
  });

  cell.highlights = highlights;

  buildTimeRangeCell(props, cells, cellKey, fns);

  return cell;
}
