import {
  addedEntityCreator,
  addEntityCreator,
  deletedEntityCreator,
  deleteEntityCreator,
  failedAddingEntityCreator,
  failedDeletingEntityCreator,
  failedUpdatingEntityCreator,
  getApiMethod,
  updatedEntityCreator,
  updateEntityCreator,
} from '@float/common/actions/lib/creatorHelpers';
import API3 from '@float/common/api3';

import { DEFAULT_SORT_TABLE } from '../reducers/reducerHelpers';

export {
  failedUpdatingEntityCreator,
  updatedEntityCreator,
  updateEntityCreator,
  failedDeletingEntityCreator,
  deletedEntityCreator,
  deleteEntityCreator,
  failedAddingEntityCreator,
  addedEntityCreator,
  addEntityCreator,
  getApiMethod,
};

export const getEntityById = (id, entities, idProp) =>
  entities.find((entity) => entity[idProp] === id);

export const sortEntitiesCreator =
  (type, fetchAction) =>
  (property, direction, tableName = DEFAULT_SORT_TABLE) => {
    return (dispatch, getState) => {
      dispatch({
        type,
        tableName,
        property,
        direction,
      });
      return dispatch(fetchAction(tableName, 1));
    };
  };

export const fetchedEntitiesCreator =
  (type, entityMapper) =>
  (
    entities,
    pageCount,
    totalCount,
    page,
    queryParams,
    tableName = DEFAULT_SORT_TABLE,
    freshFetch,
  ) => {
    return (dispatch, getState) => {
      entityMapper = entityMapper || ((mappedEntities) => mappedEntities);
      const enrichedEntities = entityMapper(
        entities,
        getState,
        page,
        queryParams,
        dispatch,
      );
      dispatch({
        type,
        pageCount,
        totalCount,
        entities: enrichedEntities,
        page,
        tableName,
        freshFetch: false,
      });
    };
  };

export const failedFetchingEntitiesCreator = (type) => (error, page) => {
  return {
    type,
    error,
    page,
  };
};

export const shouldFetchEntitiesCreator =
  () => (state, page, mergedQueryParams, sortProp, sortDir) => {
    const { pageCount } = state;
    const { lastFetchedPage } = state.sortProps[sortProp][sortDir];
    return (
      pageCount === -1 ||
      (page > lastFetchedPage && lastFetchedPage < pageCount)
    );
  };

const PREFIXED_NAMES = [
  'accessRights',
  'jwt',
  'billingInfo',
  'clients',
  'departments',
  'projects',
  'people',
  'roles',
  'accounts',
  'timeoffTypes',
  'holidays',
  'publicHolidays',
  'myCompanies',
  'accountTypes',
  'invoices',
  'timezones',
  'currencies',
];

export const getMappedPluralName = (name) => {
  if (PREFIXED_NAMES.includes(name)) {
    return `settings${name.charAt(0).toUpperCase()}${name.slice(1)}`;
  }
  return name;
};

export const fetchEntitiesCreator =
  (
    type,
    pluralName,
    shouldFetch,
    successAction,
    failureAction,
    apiQueryParams1,
    pageSize = 50,
  ) =>
  (tableName = DEFAULT_SORT_TABLE, page, apiQueryParams2, freshFetch) => {
    return (dispatch, getState) => {
      const baseState = getState()[getMappedPluralName(pluralName)];
      const { sortDir, sortProp } = baseState.sortableTables[tableName];
      page = page || baseState.sortProps[sortProp][sortDir].lastFetchedPage + 1;
      const mergedQueryParams = {
        'per-page': pageSize,
        ...apiQueryParams1,
        ...apiQueryParams2,
        page,
        sort: (sortDir == 'desc' ? '-' : '') + sortProp,
      };
      if (
        !freshFetch &&
        !shouldFetch(baseState, page, mergedQueryParams, sortProp, sortDir)
      ) {
        return Promise.resolve();
      }
      dispatch({
        type,
        page,
      });
      return API3[getApiMethod('get', pluralName)]({
        jwt: getState().jwt,
        query: {
          ...mergedQueryParams,
        },
      })
        .then((jsonWithHeaders) => {
          return dispatch(
            successAction(
              // result json
              jsonWithHeaders[0],
              // x-pagination-page-count
              jsonWithHeaders[1],
              // x-pagination-total-count
              jsonWithHeaders[2],
              page,
              mergedQueryParams,
              tableName,
              freshFetch,
            ),
          );
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(failureAction(error, page));
          // propagate error further
          throw new Error(
            `Failed fetching ${pluralName} at page ${page}: ${error}.`,
          );
        });
    };
  };
/**
 * Use with care! It's not recommended to call fetchAllEntities action.
 * @param {*} pluralName
 * @param {*} fetchEntitiesAction
 */
export const fetchAllEntitiesCreator =
  (pluralName, fetchEntitiesAction) =>
  (tableName = DEFAULT_SORT_TABLE, apiQueryParams2) => {
    return (dispatch, getState) => {
      let baseState = getState()[getMappedPluralName(pluralName)];
      return Promise.resolve(
        baseState.pageCount === -1
          ? dispatch(fetchEntitiesAction(tableName, apiQueryParams2)).then(
              () => getState()[getMappedPluralName(pluralName)].pageCount,
            )
          : baseState.pageCount,
      ).then((pageCount) => {
        const fetchPromises = [];
        baseState = getState()[getMappedPluralName(pluralName)];
        const { sortDir, sortProp } = baseState.sortableTables[tableName];
        const { lastFetchedPage } = baseState.sortProps[sortProp][sortDir];
        for (let p = lastFetchedPage + 1; p <= pageCount; p++) {
          fetchPromises.push(
            dispatch(fetchEntitiesAction(tableName, apiQueryParams2)),
          );
        }
        return Promise.all(fetchPromises);
      });
    };
  };
