export function getRoundedPercentage(numerator: number, denominator: number) {
  if (!denominator) {
    return 0;
  }

  const percentage = (numerator * 100) / denominator;

  return Math.round(percentage);
}

/**
 * @deprecated Percentage formatting should be applied via i18n.
 * See: https://developer.mozilla.org/en-US/docs/Web/JavaScriptReference/Global_Objects/Intl/NumberFormat/NumberFormat#style
 **/
export function getPercentage(
  numerator: number,
  denominator: number,
  includeSymbol = false,
  abs = false,
) {
  let value = getRoundedPercentage(numerator, denominator);

  if (abs) {
    value = Math.abs(value);
  }

  return includeSymbol ? `${value}%` : value;
}
