import React from 'react';
import { Trans } from '@lingui/macro';

import { useMyCompanies } from '@float/common/api3/myCompanies';

import AddCompanyCard from './CompanyCard/AddCompanyCard';
import CompanyCard from './CompanyCard/CompanyCard';
import CompanySelectHeader from './CompanySelectHeader/CompanySelectHeader';
import {
  StyledCompanyGroup,
  StyledCompanyList,
  StyledContainer,
  StyledContent,
} from './styles';

export default function CompanySelect() {
  const { data, isLoading } = useMyCompanies();

  const activeCompanies = data.companies.filter((c) => c.status === 'active');
  const inactiveCompanies = data.companies.filter((c) => c.status !== 'active');

  return (
    <main className="page-multi-account-login page-company-select">
      <StyledContainer>
        <CompanySelectHeader className="header" />
        {!isLoading && (
          <StyledContent>
            <StyledCompanyGroup>
              <StyledCompanyList>
                {activeCompanies.map((c) => (
                  <CompanyCard
                    key={c.company_id}
                    company={c}
                    email={data.email}
                  />
                ))}
                <AddCompanyCard name={data.name} email={data.email} />
              </StyledCompanyList>
            </StyledCompanyGroup>

            {inactiveCompanies.length > 0 && (
              <StyledCompanyGroup>
                <p>
                  <Trans>Inactive teams</Trans>
                </p>
                <StyledCompanyList>
                  {inactiveCompanies.map((c) => (
                    <CompanyCard
                      key={c.company_id}
                      company={c}
                      email={data.email}
                    />
                  ))}
                </StyledCompanyList>
              </StyledCompanyGroup>
            )}
          </StyledContent>
        )}
      </StyledContainer>
    </main>
  );
}
