import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { noop } from '@float/libs/utils/noop';

/**
 * Imperatively sets the value on an input element, checks
 * validation, and runs an `onUpdate` callback.
 *
 * Used by `useInputWithWidthAutoScaling` to make sure the
 * the input is resized every time the value is updated.
 */
export const useSetInputValue = (
  name: string,
  onSetValue: (value: string) => void = noop,
) => {
  const { setValue } = useFormContext();
  const setInputValue = useCallback(
    (value: string) => {
      setValue(name, value, { shouldValidate: true });

      onSetValue(value);
    },
    [name, onSetValue, setValue],
  );

  return { setInputValue };
};
