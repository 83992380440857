import React, { ReactNode } from 'react';
import cn from 'classnames';

import * as styles from './styles.css';

export type TagProps = {
  className?: string;
  children?: ReactNode;
  size?: 'default' | 'compact' | 'small' | 'large' | 'icon';
  color?: 'default' | 'primary' | 'secondary' | 'critical' | 'disabled';
  'data-testid'?: string;
};

export const Tag = React.forwardRef<HTMLSpanElement, TagProps>(
  (
    { size = 'default', className, children, color, 'data-testid': testId },
    ref,
  ) => {
    return (
      <span
        ref={ref}
        className={cn(
          styles.tag({
            size,
            color,
          }),
          className,
        )}
        data-testid={testId}
      >
        {children}
      </span>
    );
  },
);
