import styled from 'styled-components';

import { Checkbox } from '@float/ui/deprecated';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

export const ActivityCircle = styled.div`
  background-color: ${(p) => (p.isInUse ? p.theme.green : p.theme.grey)};
  width: 8px;
  height: 8px;
  border-radius: 8px;
  display: inline-block;
  align-self: center;
  position: relative;
  top: -2px;
  left: -2px;
`;

const MoreOptions = styled.div`
  position: relative;
  top: 2px;

  display: none;

  width: 24px;
  height: 24px;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;

  min-height: 44px;

  box-sizing: border-box;

  border-top: 1px solid ${Colors.FIN.Lt.Stroke.Stroke2};

  &:last-child {
    border-bottom: 1px solid ${Colors.FIN.Lt.Stroke.Stroke2};
  }

  ${Checkbox._styles.CheckboxContainer} {
    height: 32px;
  }

  &:not(.read-only):hover,
  &.selected {
    .cb-or-status {
      ${Checkbox._styles.CheckboxLabel} {
        display: inline-block;
      }
    }

    ${ActivityCircle} {
      display: none;
    }
  }

  &:not(.read-only):hover ${MoreOptions} {
    display: block;
  }

  div.name {
    ${Typography.TextM.R400};

    color: ${Colors.FIN.Lt.Emphasis.High};

    display: flex;
    align-items: center;

    margin-left: 18px;

    flex-grow: 1;

    span {
      width: calc(100% - 24px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .cb-or-status {
    position: relative;
    margin-right: 16px;
    width: 8px;

    ${Checkbox._styles.CheckboxLabel} {
      position: absolute;
      left: -8px;
      top: -16px;
      display: none;
      height: 32px;
    }
  }

  div.controls {
    position: relative;
    display: flex;
    align-items: center;
    height: 42px;
    margin-left: auto;

    > * + * {
      margin-left: 2px;
    }
  }

  span.billable-label {
    margin-left: auto;
    color: ${(p) => p.theme.blueGrey};
    font-size: 14px;
    letter-spacing: -0.2px;
    margin-right: 10px;
  }

  span.icon-pencil {
    margin-left: 10px;
    margin-right: 0;
  }

  span.icon-pencil,
  span.icon-close-small {
    align-self: center;
  }

  span.save {
    margin: 0 24px 0 10px;
    min-width: 34px;
    font-size: 14px;
    line-height: 40px;
    cursor: pointer;
    color: ${(p) => p.theme.blueGrey};
    text-decoration: underline;
    &.disabled {
      color: ${(p) => p.theme.slateGrey};
    }
  }

  .non-billable-tag {
    min-width: 71px;
    height: 20px;
    border-radius: 10px;
    border: solid 1px ${(p) => p.theme.midGrey};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    color: ${(p) => p.theme.blueGrey};
    margin: 0 34px 0 6px;
  }

  .icon-pencil,
  .icon-close-small {
    cursor: pointer;
    margin-right: 10px;
    margin-left: auto;
    display: flex;
  }

  .name-wrapper {
    width: calc(100% - 24px);
    position: relative;

    &.error:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: ${(p) => p.theme.red};
    }

    .icon-error {
      position: absolute;
      right: 0;
      top: 4px;
    }
  }

  .name-input {
    width: 100%;

    border: none;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: ${(p) => p.theme.blueGrey};
    }
  }
`;
