import { parse } from '@float/common/lib/time';
import { CompanyPreferences, DateString, Person } from '@float/types';

export function getHistoricalWorkHours(
  workDayHoursHistory: Person['work_days_hours_history'],
  day: DateString,
): number[] | undefined {
  for (let i = 0; i < workDayHoursHistory.length; i++) {
    const entry = workDayHoursHistory[i];
    if (day >= entry[0] && entry[1]) {
      return entry[1];
    }
  }
}

export function getTimeBasedWorkHours(
  day: DateString | null,
  person: Person | null,
  user: Pick<CompanyPreferences, 'work_days_hours_history' | 'work_days_hours'>,
) {
  let work_days_hours;

  if (day && person && person.work_days_hours_history) {
    work_days_hours = getHistoricalWorkHours(
      person.work_days_hours_history,
      day,
    );
    if (work_days_hours) {
      return work_days_hours;
    }
  }

  if (person && !person.work_days_hours_history && person.work_days_hours) {
    return person.work_days_hours;
  }

  if (day && user.work_days_hours_history) {
    work_days_hours = getHistoricalWorkHours(user.work_days_hours_history, day);
    if (work_days_hours) {
      return work_days_hours;
    }
  }

  if (user.work_days_hours) {
    return user.work_days_hours;
  }

  throw Error(`Data error - unable to find work_days_hours for ${day}`);
}

export function getPersonDailyHours(
  day: DateString,
  person: Person | null,
  user: Person,
) {
  // Obtaint the relevant work hours schedule for the person
  let workDayHours;
  try {
    workDayHours = getTimeBasedWorkHours(day, person, user);
  } catch {
    // if there is no daily hour info in the app, just default to 8 hours
    // since the user is probably a full time employee
    workDayHours = [0, 8, 8, 8, 8, 8, 0];
  }

  const date = parse(day);
  return workDayHours[date.getDay()];
}
