import React from 'react';
import { useWatch } from 'react-hook-form';
import { t } from '@lingui/macro';

import {
  getClientColors,
  getRecentColors,
} from '@float/common/selectors/colors';
import { useAppSelectorWithParams } from '@float/common/store';
import { SidePanelHeader } from 'components/SidePanel/SidePanelHeader';

import { ArchivedTag } from '../../components/ArchivedTag';
import {
  ShimmerCircle,
  ShimmerContainer,
  ShimmerLine,
} from '../../components/Shimmer';
import { ColorField } from '../../fields/ColorField';
import { NameField } from '../../fields/NameField';
import { TemplateField } from '../../fields/TemplateField';
import { useIsTemplate } from '../../hooks/useIsTemplate';
import { ProjectFormData } from '../../types';
import { TemplateActionsMenu } from '../TemplateHeaderSection/TemplateActionsMenu';
import { ProjectActionsMenu } from './ProjectActionsMenu';

import * as styles from './styles.css';

export function ProjectHeaderSection(props: {
  isEditing: boolean;
  isLoading?: boolean;
  isArchived?: boolean;
}) {
  const clientId = useWatch<ProjectFormData, 'project.client_id'>({
    name: 'project.client_id',
  });
  const isTemplate = useIsTemplate();
  const isCreating = !props.isEditing;
  const isCreatingProject = isCreating && !isTemplate;
  const isEditingProject = props.isEditing && !isTemplate;
  const isArchivedProject = props.isArchived && !isTemplate;
  const isEditingTemplate = props.isEditing && isTemplate;
  const clientColors = useAppSelectorWithParams(
    getClientColors,
    clientId as number,
  );
  const recentColors = useAppSelectorWithParams(getRecentColors);
  return (
    <div data-callout-id="project-setup-welcome">
      <SidePanelHeader>
        {props.isLoading ? (
          <ShimmerContainer className={styles.projectShimmerWrapper}>
            <ShimmerCircle />
            <ShimmerLine />
          </ShimmerContainer>
        ) : (
          <>
            <ColorField
              aria-label={t`Project color`}
              autoFocus={props.isEditing}
              name="project.color"
              clientColors={clientColors}
              recentColors={recentColors}
            />
            <NameField
              autoFocus={isCreating}
              placeholder={isTemplate ? t`Template name` : t`Project name`}
            />
            {isArchivedProject && (
              <ArchivedTag data-testid="project-archive-tag" size="large" />
            )}
            {isCreatingProject && <TemplateField />}
            {isEditingProject && <ProjectActionsMenu />}
            {isEditingTemplate && <TemplateActionsMenu />}
          </>
        )}
      </SidePanelHeader>
    </div>
  );
}
