import { useMemo } from 'react';

import { datesCreatorWithGlobalCache } from '@float/libs/dates/datesCreatorWithGlobalCache';

export default function useDates(
  mondayStart: boolean,
  leftHiddenDays: number,
  rightHiddenDays: number,
) {
  const dates = useMemo(() => {
    return datesCreatorWithGlobalCache({
      mondayStart,
      leftHiddenDays,
      rightHiddenDays,
    });
  }, [mondayStart, leftHiddenDays, rightHiddenDays]);

  return dates;
}
