import React from 'react';
import { useWatch } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';

import { IconSearch } from '@float/ui/icons/essentials/IconSearch';

import { SidePanelAddRowButton } from '../../components/SidePanelTableSection/SidePanelAddRowButton';
import { SidePanelEmptyTable } from '../../components/SidePanelTableSection/SidePanelEmptyTable';
import { SidePanelTableHeaderRow } from '../../components/SidePanelTableSection/SidePanelTableHeaderRow';
import { SidePanelTableHeading } from '../../components/SidePanelTableSection/SidePanelTableHeading';
import { SidePanelTableRows } from '../../components/SidePanelTableSection/SidePanelTableRows';
import { createEmptyTeamMemberRecord } from '../../helpers/createRowEntities';
import { useFieldArrayContext } from '../../hooks/useFieldArrayContext';
import { useProjectOrPhaseFieldValue } from '../../hooks/useProjectOrPhaseFieldValue';
import { ProjectFormData } from '../../types';
import { ProjectTeamRow } from './ProjectTeamRow';
import { useShowHourlyRatesInfo } from './ProjectTeamSection.hooks';

import {
  emptyRowPadding,
  sidePanelTableWrapper,
} from '../../components/SidePanelTableSection/styles.css';
import * as styles from './styles.css';

const TableHeader: React.FC = () => {
  const showRateColumn = useShowHourlyRatesInfo();
  return (
    <SidePanelTableHeaderRow
      className={styles.projectTeamGridTemplateColumns({
        gridTemplateColumns: showRateColumn ? 'rates' : 'default',
      })}
    >
      <SidePanelTableHeading>
        <Trans>Name</Trans>
      </SidePanelTableHeading>
      <SidePanelTableHeading />
      <SidePanelTableHeading active={showRateColumn}>
        <Trans>Rate</Trans>
      </SidePanelTableHeading>
      <SidePanelTableHeading />
    </SidePanelTableHeaderRow>
  );
};

export const ProjectTeamTable = (props: { toggleDefaultRate: boolean }) => {
  const projectTeam = useWatch<ProjectFormData, 'team'>({
    name: 'team',
  });
  const isActive = useProjectOrPhaseFieldValue('active');
  const showNoTeam = !projectTeam.length;
  const addButtonDisabled =
    !isActive ||
    (!showNoTeam && !projectTeam[projectTeam.length - 1]?.people_id);

  const { append } = useFieldArrayContext<ProjectFormData, 'team'>();

  function handleTeamMemberEnterSelect() {
    append(createEmptyTeamMemberRecord());
  }

  function handleSubmit(evt: React.FormEvent<HTMLElement>) {
    evt.preventDefault();
    if (!addButtonDisabled) {
      append(createEmptyTeamMemberRecord());
    }
  }

  return (
    <form className={sidePanelTableWrapper} onSubmit={handleSubmit}>
      {showNoTeam ? (
        <SidePanelEmptyTable<'team'>
          label={t`Name`}
          data={createEmptyTeamMemberRecord}
          className={emptyRowPadding}
          disabled={addButtonDisabled}
        >
          <IconSearch />
          <Trans>Search for team members</Trans>
        </SidePanelEmptyTable>
      ) : (
        <div role="table" data-testid="project-team-table">
          <TableHeader />
          <SidePanelTableRows
            name={'team'}
            watchUpdate={'people_id'}
            rowRenderer={(field, index) => (
              <ProjectTeamRow
                key={field.id}
                index={index}
                onTeamMemberEnterSelect={handleTeamMemberEnterSelect}
                toggleDefaultRate={props.toggleDefaultRate}
              />
            )}
          />
        </div>
      )}
      <SidePanelAddRowButton<'team'>
        data={createEmptyTeamMemberRecord}
        buttonText={t`Assign a team member`}
        disabled={addButtonDisabled}
      />
    </form>
  );
};
