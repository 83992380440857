import React from 'react';
import styled from 'styled-components';

import { capitalize } from '@float/libs/utils/string/capitalize';
import { EH, Icons, TextTooltip } from '@float/ui/deprecated';

export const StyledSyncIcon = styled.div`
  display: flex;
  padding: 0 10px;
  outline: none;

  svg {
    --svg-icon-color: ${({ disabled }) =>
      disabled
        ? EH.Colors.FIN.Lt.Emphasis.Disabled
        : EH.Colors.FIN.Lt.Emphasis.Low};
  }
`;

// TODO Check this one
function SyncIcon({ integrationType, itemType, disabled }) {
  const title = capitalize(`${integrationType} ${itemType}`);

  return (
    <TextTooltip content={title} className="hint">
      <StyledSyncIcon disabled={disabled}>
        <Icons.SyncWhiteLrg size={18} />
      </StyledSyncIcon>
    </TextTooltip>
  );
}

export default SyncIcon;
