import { BaseEditor, BaseElement } from 'slate';

export function MentionsPlugin<T extends BaseEditor>(editor: T) {
  const { isInline, isVoid } = editor;

  editor.isInline = (element: BaseElement) => {
    return 'type' in element && element.type === 'mention'
      ? true
      : isInline(element);
  };

  editor.isVoid = (element: BaseElement) => {
    return 'type' in element && element.type === 'mention'
      ? true
      : isVoid(element);
  };

  return editor;
}
