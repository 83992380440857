import { LoggedTime, Project, Task, TaskReference } from '@float/types';
import { integrationTypes } from '@float/web/pmSidebar/integrationTypes';
import { PmTaskLinkMap } from '@float/web/pmSidebar/reducers/types';

/**
 * Returns the url where it's possible to see the task on the third integration
 * @param {Object} task
 * @param {string} pmBaseUrl
 * @param {string | undefined} pmIntegrationType
 * @param {Object} taskLinksMap
 * @param {boolean} options.isLoggedTime
 * @returns {string}
 */
export function getPMUrl(
  task: Task | LoggedTime | TaskReference,
  project: Project | undefined,
  pmBaseUrl: string | undefined,
  pmIntegrationType: keyof typeof integrationTypes | undefined,
  taskLinksMap: PmTaskLinkMap,
  options = { isLoggedTime: false },
) {
  let pmUrl: string | null = null;
  if (task.task_id && pmBaseUrl) {
    const taskLink = taskLinksMap[task.task_id];
    if (taskLink && taskLink.ext_resource_id) {
      const integration = pmIntegrationType
        ? integrationTypes[pmIntegrationType]
        : null;

      if (project && integration && 'generateTaskLink' in integration) {
        const { isLoggedTime } = options;

        pmUrl = integration.generateTaskLink({
          // @ts-expect-error generateTaskLink type is inferred from JS
          baseUrl: pmBaseUrl,
          task,
          taskLink,
          project,
          isLoggedTime,
        });
      } else {
        pmUrl = `${pmBaseUrl}/${taskLink.ext_resource_id}`;
      }
    }
  }
  return pmUrl;
}
