import React from 'react';
import { t, Trans } from '@lingui/macro';

import { NotificationsFilter } from '../../NotificationsPanel.types';
import { NotificationsEmptyIllustration } from './components/NotificationsEmptyIllustration';

import * as styles from './NotificationsEmpty.css';

type Props = { filter: NotificationsFilter };

const getEmptyHeadingCopy = (isFilteredByRequests: NotificationsFilter) => {
  if (isFilteredByRequests === NotificationsFilter.Requests) {
    return t`You currently have no requests`;
  }

  return t`You currently have no notifications`;
};

export const NotificationsEmpty = (props: Props) => {
  const { filter } = props;

  return (
    <div className={styles.empty}>
      <NotificationsEmptyIllustration />

      <h3 className={styles.emptyHeading}>{getEmptyHeadingCopy(filter)}</h3>

      <p className={styles.emptyDesc}>
        <Trans>We hope you enjoy this peace and quiet.</Trans>
      </p>
    </div>
  );
};
