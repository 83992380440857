import React, { forwardRef, KeyboardEventHandler } from 'react';

import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { List } from '@float/ui/primitives/List';

import * as styles from './styles.css';

type SearchFilterInputProps = {
  isLoading?: boolean;
  setInputValue: (value: string) => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
} & Pick<HTMLInputElement, 'placeholder' | 'value'>;

const SearchFilterInput = forwardRef<HTMLInputElement, SearchFilterInputProps>(
  (props, ref) => {
    const { placeholder, value, setInputValue, onKeyDown, isLoading } = props;

    return (
      <List.SearchField
        autoComplete="off"
        className={styles.search}
        id="search-value"
        onKeyDown={onKeyDown}
        onSubmit={prevent}
        onValueChange={setInputValue}
        placeholder={placeholder}
        ref={ref}
        value={value}
        isLoading={isLoading}
      />
    );
  },
);

export { SearchFilterInput };
