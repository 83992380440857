import { useQuery } from '@tanstack/react-query';

import api3 from '@float/common/api3';
import { roundToTwoDecimals } from '@float/common/lib/utils';
import { Person } from '@float/types/person';
import { TimeoffBalanceType } from '@float/types/timeoffType';

type FetchTimeoffTypesBalanceParams = {
  endDate: string;
  peopleId: Person['people_id'];
};

const fetchTimeoffTypesBalance = async (
  params: FetchTimeoffTypesBalanceParams,
) => {
  const res = await api3.getTimeoffTypesBalance(params);
  return res;
};

export const useTimeoffTypesBalance = (props: {
  endDate: string;
  personId: Person['people_id'] | undefined;
}) => {
  const { endDate, personId } = props;

  // only query the balance if personId is defined
  const isQueryEnabled = !!personId;

  const { data } = useQuery({
    queryKey: ['timeoffTypesBalance', personId, endDate],
    queryFn: () =>
      fetchTimeoffTypesBalance({
        endDate,
        peopleId: personId!,
      }),
    enabled: isQueryEnabled,
    refetchOnMount: true,
  });

  if (data) {
    return data
      .filter(
        (timeoffTypeBalance) =>
          timeoffTypeBalance.balanceType !== TimeoffBalanceType.Unlimited,
      )
      .map((timeoffTypeBalance) => {
        return {
          id: timeoffTypeBalance.id,
          balanceType: timeoffTypeBalance.balanceType,
          daysAvailable: roundToTwoDecimals(timeoffTypeBalance.people.balance),
        };
      });
  }

  return undefined;
};
