import React, { useState } from 'react';
import { Trans } from '@lingui/macro';

import { NavIconBtn } from '@float/common/components/NavIconBtn';
import {
  getActivePage,
  getActiveSortOptions,
  getAllActiveSearchFilters,
} from '@float/common/selectors/views';
import { useAppSelector } from '@float/common/store';
import { useWarningSnackbar } from '@float/ui/deprecated/Snackbar/useWarningSnackbar';
import { IconLayers } from '@float/ui/icons/essentials/IconLayers';

import { ViewsModalContainer } from '../ViewsModalContainer';
import { FiltersValidationErrorMessage } from './FiltersValidationErrorMessage';
import { validateFilters } from './validateFilters';

export function SaveView() {
  const [open, setOpen] = useState(false);
  const showSnackbar = useWarningSnackbar();

  const filters = useAppSelector(getAllActiveSearchFilters);
  const page = useAppSelector(getActivePage);
  const scheduleSort = useAppSelector(getActiveSortOptions);

  function handleClick() {
    const error = validateFilters(filters);

    if (error) {
      showSnackbar(<FiltersValidationErrorMessage error={error} />);
    } else {
      setOpen(true);
    }
  }

  return (
    <>
      <NavIconBtn iconLeft={<IconLayers />} onClick={handleClick}>
        <Trans>Save</Trans>
      </NavIconBtn>
      {open && (
        <ViewsModalContainer
          onOpenChange={setOpen}
          defaultValues={{
            filters,
            settings: {
              page,
              scheduleSort,
            },
          }}
        />
      )}
    </>
  );
}
