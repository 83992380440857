import React from 'react';

export default function (props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#868D92">
          <g>
            <g transform="translate(-352 -1073) translate(299 1061) translate(50 11)">
              <g>
                <path
                  d="M18 13L13 13 13 18 11.09 18 11.09 13 6 13 6 11 11 11 11 6 13 6 13 11 18 11z"
                  transform="translate(3 1)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
