import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Transition } from 'TransitionManager/Transition';

import { useAppSelector } from '@float/common/store';
import { getLastUpdatedProjectData } from '@float/web/store/onboardingManager/selectors/lastUpdatedProject';

import { StyledProjectTasks } from './styles';

export const AddProjectAside = forwardRef((props, ref) => {
  const { transitionId } = props;

  const projectData = useAppSelector(getLastUpdatedProjectData);

  const transitionRef = useRef();
  const elRef = useRef();

  useImperativeHandle(ref, () => ({
    ...transitionRef.current,
  }));

  return (
    <Transition ref={transitionRef} id={transitionId}>
      <StyledProjectTasks
        ref={elRef}
        projectName={projectData.project_name}
        projectTasks={projectData.tasks}
      />
    </Transition>
  );
});
