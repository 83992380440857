import React from 'react';
import { useFormContext } from 'react-hook-form';
import { t } from '@lingui/macro';
import { assignInlineVars } from '@vanilla-extract/dynamic';

import { Radix } from '@float/common/earhart/colors';

import { DateRangeField } from '../components/DateRangeField';
import { DurationField } from '../components/DurationField';
import { useProjectOrPhaseFieldValue } from '../hooks/useProjectOrPhaseFieldValue';
import { ProjectFormData, ProjectPhaseRecord } from '../types';
import { usePhaseDates } from './PhaseDatesField.hooks';
import type { PhaseFieldName } from './PhaseDatesField.hooks';

import { datePickerActiveColor } from '../components/DateRangeField/styles.css';

type Duration = ProjectPhaseRecord['duration'];

// Need this to align the date picker to the edge of the row
const PHASE_ROW_CALENDAR_OFFSET = -40;

export const PhaseDatesField = ({ name }: { name: PhaseFieldName }) => {
  const { getValues } = useFormContext<ProjectFormData>();
  const { disableDayFn, isStartDateSelectable, isEndDateSelectable } =
    usePhaseDates(name);
  const panelActive = useProjectOrPhaseFieldValue('active');
  const fieldActive = getValues(`${name}.active`);
  const disabled = !panelActive || !fieldActive;
  const isFieldInPhaseForm = name === 'phase';
  const dateRangeComponent = (
    <DateRangeField
      disabled={disabled}
      padding="margin"
      start={`${name}.start_date`}
      end={`${name}.end_date`}
      label={t`Dates`}
      hideLabel={!isFieldInPhaseForm}
      hideIcon={!isFieldInPhaseForm}
      disableDayFn={disableDayFn}
      isStartDateSelectable={isStartDateSelectable}
      isEndDateSelectable={isEndDateSelectable}
      alignOffset={isFieldInPhaseForm ? undefined : PHASE_ROW_CALENDAR_OFFSET}
      data-testid="phase-dates-picker"
    />
  );

  const duration = getValues(`${name}.duration`) as Duration;
  const showDuration = duration !== undefined && duration !== null;
  if (showDuration) {
    return (
      <DurationField duration={duration} hideLabel={!isFieldInPhaseForm} />
    );
  }

  if (isFieldInPhaseForm) return dateRangeComponent;

  return (
    <span
      data-testid="phaseRowDatesField"
      style={assignInlineVars({
        [datePickerActiveColor]: Radix.Primary.Flay['6'],
      })}
    >
      {dateRangeComponent}
    </span>
  );
};
