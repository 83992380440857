import React from 'react';
import cx from 'classnames';

import Row from './Row';
import ErrorRow from './row/ErrorRow';
import InfiniteScrollRow from './row/InfiniteScrollRow';
import LoaderRow from './row/LoaderRow';
import NoRowsRow from './row/NoRowsRow';

const Tbody = ({
  rows,
  recordNames,
  isLoading,
  loadError,
  infiniteScrollRowRef,
  hasHiddenBody,
  emptyMessage,
  ...rest
}) => {
  let jsx;

  if (loadError) {
    jsx = <ErrorRow />;
  } else if (rows.length) {
    jsx = rows.map((row) => {
      return <Row key={row.id} row={row} recordNames={recordNames} {...rest} />;
    });

    if (isLoading) {
      jsx.push(<LoaderRow key="loader" />);
    } else {
      jsx.push(
        <InfiniteScrollRow key="scroll" rowRef={infiniteScrollRowRef} />,
      );
    }
  } else if (isLoading) {
    jsx = <LoaderRow />;
  } else {
    jsx = <NoRowsRow recordNames={recordNames} emptyMessage={emptyMessage} />;
  }

  return (
    <tbody
      className={cx({
        hidden: hasHiddenBody,
      })}
    >
      {jsx}
    </tbody>
  );
};

export default Tbody;
