const globalName = '__float';

const devGlobals = new Map();

const exposeGlobal = (name, value) => {
  if (!window[globalName]) {
    window[globalName] = devGlobals;
  }

  devGlobals.set(name, value);
};

const readGlobal = (name) => devGlobals.get(name);

export { exposeGlobal, readGlobal };
