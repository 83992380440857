import {
  SnackbarMessages,
  useSnackbarWithUndoableAction,
} from './useSnackbarWithUndoableAction';

/**
 * Decorates an async action to fire a snackbar notification for the
 * loading/success/error state, giving the user the ability to undo a successful
 * action or to retry a failed one.
 *
 * @example

const DELETE_ITEM_MESSAGES: SnackbarMessages<string> = {
  error: () => t`There was an issue while trying to delete the item`,
  loading: (item) => t`Deleting “${item}” item`,
  success: (item) => t`“${item}” deleted`,
  undoError: (item) => t`“${item}” item delete restore failed`,
  undoLoading: (item) => t`Restoring the item “${item}”`,
  undoSuccess: (item) => t`“${item}” restored`,
};
 
function DeleteItemButton() {
   const handleDelete = useFailsafeActionDecorator(
    DELETE_ITEM_MESSAGES,
    (item: string) => dispatch(deleteItem(delete)),
    'delete'
  );

  return <button onClick={handleDelete}>Delete item</button>
}

 * Note that if the loading message is not provided the loading snackbar will not be rendered.
 */
export function useFailsafeActionDecorator<P, R = unknown>(
  messages: SnackbarMessages<P>,
  action: (props: P) => Promise<R>,
  type: 'default' | 'delete' = 'default',
) {
  const { showLoadingSnackbar, showErrorSnackbar, showSuccessSnackbar } =
    useSnackbarWithUndoableAction(messages, type);

  return async function decoratedAction(props: P) {
    showLoadingSnackbar(props);

    try {
      const res = await action(props);

      showSuccessSnackbar(props);

      return res;
    } catch (err) {
      showErrorSnackbar(props, () => decoratedAction(props));
      throw err;
    }
  };
}
