import React from 'react';
import { Plural, t, Trans } from '@lingui/macro';

import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';

import { Placeholders } from '../../constants';
import { ChangePlanTierButtons } from './ChangePlanTierButtons';
import { ExternalLink } from './ExternalLink';
import { HintPrompt } from './HintPromp';
import { PlanCheckIcon } from './PlanCheckIcon';
import { PlanHeader } from './PlanHeader';
import { PricePerSeat } from './PricePerSeat';
import {
  StyledFeatureList,
  StyledFeatureListHeader,
  StyledFeatureListSection,
  StyledPlanSubTitle,
  StyledProPlanWrapper,
} from './styles';

import proIcon from './assets/proIcon.svg';

export type ProPlanProps = {
  canStartPaidTrial?: boolean;
  daysRemaining?: number | null;
  isActivePlan: boolean;
  isFreeTrial: boolean;
  isPaidTrial: boolean;
  isHigherTierThanActivePlan: boolean;
  isLowerTierThanActivePlan: boolean;
  price: string;
  openModal: (modal: string) => void;
};

export const ProPlan = ({
  canStartPaidTrial = false,
  daysRemaining,
  isActivePlan,
  isFreeTrial,
  isPaidTrial,
  isHigherTierThanActivePlan,
  isLowerTierThanActivePlan,
  price,
  openModal,
}: ProPlanProps) => {
  return (
    <StyledProPlanWrapper aria-current={isActivePlan}>
      <img src={proIcon} alt={t`Pro Plan Icon`} />

      <PlanHeader
        isActivePlan={isActivePlan}
        isPaidTrial={isPaidTrial}
        daysRemaining={daysRemaining}
      >
        <Trans>Pro</Trans>
      </PlanHeader>

      <StyledPlanSubTitle>
        <Trans>Best for growing teams</Trans>
      </StyledPlanSubTitle>

      <PricePerSeat price={price} />

      <ChangePlanTierButtons
        canStartPaidTrial={canStartPaidTrial}
        isFreeTrial={isFreeTrial}
        isPaidTrial={isPaidTrial}
        isHigherTierThanActivePlan={isHigherTierThanActivePlan}
        isLowerTierThanActivePlan={isLowerTierThanActivePlan}
        onUpgradeClick={() => openModal('upgrade-pro')}
        onTrialClick={() => openModal('trial')}
        onPaidTrialClick={() => openModal('trial-pro-paid')}
        onDowngradeClick={() => openModal('downgrade-pro')}
        onDisableClick={() => openModal('disable-pro')}
      />

      <StyledFeatureListSection>
        <StyledFeatureListHeader>
          <Trans>All the Starter features plus</Trans>
        </StyledFeatureListHeader>
        <StyledFeatureList>
          <li>
            <PlanCheckIcon />
            <span>
              <Trans>In-built time tracking</Trans>
            </span>
          </li>
          <li>
            <PlanCheckIcon />
            <span>
              <Trans>Compare estimated vs. actual hours</Trans>
            </span>
          </li>
          {featureFlags.isFeatureEnabled(FeatureFlag.PlaceholdersPackaging) && (
            <li>
              <PlanCheckIcon />
              <span>
                <Plural
                  value={Placeholders.Pro}
                  one="# Placeholder"
                  other="# Placeholders"
                />
              </span>
            </li>
          )}
          <li>
            <PlanCheckIcon />
            <span>
              <Trans>Pre-filled timesheets</Trans>
            </span>
          </li>
          <li>
            <PlanCheckIcon />
            <span>
              <Trans>Desktop timer app (Beta)</Trans>
            </span>
          </li>
          <li>
            <PlanCheckIcon />
            <span>
              <Trans>Save custom Views</Trans>
            </span>
          </li>
          <li>
            <PlanCheckIcon />
            <span>
              <Trans>Coming soon: Project estimating</Trans>
            </span>
          </li>
          <li>
            <PlanCheckIcon />
            <span>
              <Trans>Lock logged time</Trans>
              <HintPrompt content="Make logged time uneditable" />
            </span>
          </li>
          <li>
            <PlanCheckIcon />
            <span>
              <Trans>180 day audit log</Trans>
            </span>
          </li>
          <li>
            <PlanCheckIcon />
            <span>
              <Trans>Single Sign On (SSO)</Trans>
              <HintPrompt content="Google and SAML options" />
            </span>
          </li>
        </StyledFeatureList>
        <ExternalLink href="https://www.float.com/pricing">
          <Trans>View full feature list</Trans>
        </ExternalLink>
      </StyledFeatureListSection>
    </StyledProPlanWrapper>
  );
};
