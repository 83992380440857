import React from 'react';

import ChartBar from '../Icons/icon-chart-bar';
import ChartLine from '../Icons/icon-chart-line';
import colors from '../Theme/colors';

export const TEAL = colors.teal;
export const MID_TEAL = colors.midTeal;
export const PURPLE = colors.purple;
export const LIGHT_PURPLE = colors.lightPurple;

export const UNITS = {
  DAY: 'day',
  MONTH: 'month',
  WEEK: 'week',
} as const;

/**
 * @deprecated Use ChartComparisonModes
 */
export const MODES = {
  COMBINED: 'combined',
  LOGGED: 'logged',
  SCHEDULED: 'scheduled',
  COMPARE: 'compare',
} as const;

export const CHART_TYPES = {
  BAR: 'bar',
  LINE: 'line',
} as const;

export const PCT_MODES = {
  SCHEDULED_CAPACITY: 'scheduled_capacity',
  BILLABLE_CAPACITY: 'billable_capacity',
  BILLABLE_SCHEDULED: 'billable_scheduled',
} as const;

/**
 * @deprecated Use ProjectPercentageModes
 */
export const PROJ_PCT_MODES = {
  SCHEDULED: 'scheduled',
  LOGGED: 'logged',
} as const;

export const UNIT_OPTIONS = [
  { label: 'Days', value: UNITS.DAY },
  { label: 'Weeks', value: UNITS.WEEK },
  { label: 'Months', value: UNITS.MONTH },
] as const;

export const MODE_OPTIONS = [
  { label: 'Logged vs Scheduled', value: MODES.COMPARE },
  { label: 'Past logged + Future scheduled', value: MODES.COMBINED },
  { label: 'Logged', value: MODES.LOGGED },
  { label: 'Scheduled', value: MODES.SCHEDULED },
] as const;

export const CHART_TYPE_OPTIONS = [
  {
    icon: <ChartBar />,
    label: 'Bar chart',
    value: CHART_TYPES.BAR,
  },
  {
    icon: <ChartLine />,
    label: 'Line chart',
    value: CHART_TYPES.LINE,
  },
] as const;
