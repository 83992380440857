export const TIMER_CREATE = 'TIMER_CREATE';
export const TIMER_DELETE = 'TIMER_DELETE';
export const TIMER_FETCH_BEGIN = 'TIMER_FETCH_BEGIN';
export const TIMER_FETCH_ERROR = 'TIMER_FETCH_ERROR';
export const TIMER_FETCH_SUCCESS = 'TIMER_FETCH_SUCCESS';
export const TIMER_START = 'TIMER_START';
export const TIMER_STOP = 'TIMER_STOP';
export const TIMER_UPDATE = 'TIMER_UPDATE';
export const TIMER_WARNING_HIDE = 'TIMER_WARNING_HIDE';
export const TIMER_WARNING_SHOW = 'TIMER_WARNING_SHOW';
