import React from 'react';
import { Trans } from '@lingui/macro';

import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { Button } from '@float/ui/deprecated/Button/Button';

import { getDaysLeftCopy, getSeatsCopy } from './BillingDetails.helpers';
import {
  StyledCTAContext,
  StyledCTAWrapper,
  StyledDetailsPrimaryContent,
  StyledDetailsSecondaryContent,
  StyledDetailsSection,
  StyledDetailsWrapper,
} from './styles';

export const TrialBillingDetails = ({
  trialDays,
  trialDaysRemaining,
  usedSeats,
  placeholderSeats,
  placeholderUsedSeats,
  isProPlan,
}: {
  trialDays: number;
  trialDaysRemaining: number;
  usedSeats: number;
  placeholderSeats: number;
  placeholderUsedSeats: number;
  isProPlan?: boolean;
}) => (
  <>
    <StyledDetailsWrapper>
      <StyledDetailsSection>
        <h5>Trialing</h5>
        <StyledDetailsPrimaryContent>
          <em>
            {usedSeats} <sub>{getSeatsCopy(usedSeats)}</sub>
          </em>
        </StyledDetailsPrimaryContent>
        <StyledDetailsSecondaryContent>
          Add unlimited seats during your trial
        </StyledDetailsSecondaryContent>
      </StyledDetailsSection>
      {featureFlags.isFeatureEnabled(FeatureFlag.PlaceholdersPackaging) && (
        <StyledDetailsSection>
          <h5>
            <Trans>Placeholders</Trans>
          </h5>
          <StyledDetailsPrimaryContent>
            <em>
              {placeholderUsedSeats}/{placeholderSeats}
            </em>
          </StyledDetailsPrimaryContent>
          <StyledDetailsSecondaryContent>
            <Trans>Extras are billed as seats</Trans>
          </StyledDetailsSecondaryContent>
        </StyledDetailsSection>
      )}
      <StyledDetailsSection>
        <h5>Total</h5>
        <StyledDetailsPrimaryContent>
          <em>{trialDays}-day free trial</em>
        </StyledDetailsPrimaryContent>
        <StyledDetailsSecondaryContent>
          {getDaysLeftCopy(trialDaysRemaining)}
        </StyledDetailsSecondaryContent>
      </StyledDetailsSection>
    </StyledDetailsWrapper>

    <StyledDetailsWrapper>
      <StyledCTAWrapper>
        <StyledCTAContext>Let’s make this official!</StyledCTAContext>
        <Button as="a" href={`/thankyou?plan=${isProPlan ? 'pro' : 'starter'}`}>
          Pay now
        </Button>
      </StyledCTAWrapper>
    </StyledDetailsWrapper>
  </>
);
