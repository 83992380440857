import React from 'react';

export default function () {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66667 4.66618L2 7.33285L4.66667 9.99951"
        stroke="#2E5FE8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 11.3335V10.0002C14 9.29292 13.719 8.61464 13.219 8.11455C12.7189 7.61445 12.0406 7.3335 11.3333 7.3335H2"
        stroke="#2E5FE8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
