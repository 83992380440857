import React, {
  ChangeEventHandler,
  CSSProperties,
  MouseEventHandler,
  useCallback,
} from 'react';

import BottomHoverTooltip from '../Tooltip/BottomHoverTooltip';
import { CheckboxContainer, CheckboxLabel } from './styles';

import * as styles from './styles.css';

interface CheckboxProps {
  value?: boolean;
  label?: string;
  semiChecked?: boolean;
  leftLabel?: string;
  className?: string;
  readOnly?: boolean;
  title?: string;
  style?: CSSProperties;
  onChange?: (checked: boolean) => void;
  onClick?: MouseEventHandler;
  tabIndex?: number;
  disabled?: boolean;
  calloutId?: string;
  hideLabel?: boolean;
}

/**
 * @deprecated use `fin/components/Checkbox` instead
 * The new Checkbox component has a slightly different API and does not support
 * all functionality that this one has. If you need to extend its functionality
 * feel free to do it, or raise a question with Interfaces team.
 */
const Checkbox = (props: CheckboxProps) => {
  const {
    value,
    label,
    semiChecked,
    leftLabel,
    className,
    readOnly,
    title,
    style,
    onChange,
    onClick,
    tabIndex,
    disabled,
    calloutId,
    hideLabel,
  } = props;

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (!onChange) return;
      if (readOnly) return;

      onChange(!!event.target?.checked);
    },
    [onChange, readOnly],
  );

  const handleOnClick: MouseEventHandler<HTMLLabelElement> = useCallback(
    (event) => {
      if (!onClick) return;
      if (readOnly) return;

      onClick(event);
    },
    [onClick, readOnly],
  );

  return (
    <BottomHoverTooltip title={title} placement="right">
      <CheckboxLabel
        as="label"
        className={className}
        leftLabel={!!leftLabel}
        readOnly={readOnly}
        style={style}
        onClick={handleOnClick}
      >
        <CheckboxContainer
          $checked={value}
          $disabled={disabled}
          $semiChecked={semiChecked}
          readOnly={readOnly}
          rightLabel={!!label}
          leftLabel={!!leftLabel}
          data-callout-id={calloutId}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="8"
            fill="none"
          >
            <path
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.667"
              d="m1.384 4.832 2.125 2.126L9.117 1.35"
            />

            <path
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.667"
              d="M2 4h6"
            />
          </svg>
        </CheckboxContainer>
        <span className={hideLabel ? styles.visuallyHidden : ''}>{label}</span>
        <input
          type="checkbox"
          checked={!!value}
          style={{ opacity: 0, width: 0 }}
          readOnly={readOnly}
          onChange={handleOnChange}
          tabIndex={tabIndex}
          data-lpignore
          disabled={disabled}
        />
      </CheckboxLabel>
    </BottomHoverTooltip>
  );
};

Checkbox._styles = { CheckboxContainer, CheckboxLabel };

export default Checkbox;
