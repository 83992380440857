import React, { ComponentPropsWithRef } from 'react';
import cn from 'classnames';

import * as styles from './styles.css';

export type ModalListProps = ComponentPropsWithRef<'p'>;

export const ModalList = (props: ModalListProps) => {
  const { children } = props;

  return <ul className={cn(styles.list)}>{children}</ul>;
};

export const ModalListItem = (props: ModalListProps) => {
  const { children } = props;

  return <li className={cn(styles.item)}>{children}</li>;
};
