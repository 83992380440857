import {
  LOGGED_TIME_BULK_CREATED,
  LOGGED_TIME_CREATED,
  LOGGED_TIME_DELETED,
  LOGGED_TIME_UPDATED,
} from '@float/common/actions/loggedTimes';
import {
  PHASES_UPDATED,
  SHIFT_PHASE_TIMELINE,
} from '@float/common/actions/phases';
import {
  CREATE_TASK_FAILURE,
  CREATE_TASK_SUCCESS,
  DELETE_TASK_FAILURE,
  DELETE_TASK_SUCCESS,
  LINKED_TASKS_UPDATE_SUCCESS,
  SPLIT_TASK,
  SPLIT_TASK_DELETE_SUCCESS,
  SPLIT_TASK_START,
  UPDATE_TASK_FAILURE,
  UPDATE_TASK_SUCCESS,
} from '@float/common/actions/tasks';
import {
  CREATE_TIMEOFF_FAILURE,
  CREATE_TIMEOFF_SUCCESS,
  DELETE_TIMEOFF_FAILURE,
  DELETE_TIMEOFF_SUCCESS,
  UPDATE_TIMEOFF_FAILURE,
  UPDATE_TIMEOFF_SUCCESS,
} from '@float/common/actions/timeoffsConstants';
import {
  VIEW_CREATED,
  VIEW_DELETED,
  VIEW_UPDATED,
} from '@float/common/actions/views/views';
import { AllActions } from '@float/common/reducers';

export const ACTIONS_SUPPORTING_UNDO = [
  CREATE_TASK_SUCCESS,
  DELETE_TASK_SUCCESS,
  UPDATE_TASK_SUCCESS,
  SPLIT_TASK_START,
  SPLIT_TASK,
  SPLIT_TASK_DELETE_SUCCESS,
  CREATE_TIMEOFF_SUCCESS,
  DELETE_TIMEOFF_SUCCESS,
  UPDATE_TIMEOFF_SUCCESS,
  LOGGED_TIME_CREATED,
  LOGGED_TIME_UPDATED,
  LOGGED_TIME_DELETED,
  SHIFT_PHASE_TIMELINE,
  (action: AllActions) => {
    return (
      action.type === PHASES_UPDATED &&
      action.phases?.length === 1 &&
      action.prevPhases?.length === 1
    );
  },
  LINKED_TASKS_UPDATE_SUCCESS,
  LOGGED_TIME_BULK_CREATED,
  VIEW_CREATED,
  VIEW_DELETED,
  VIEW_UPDATED,
];

export const ACTIONS_SUPPORTING_UNDO_FAILURE = [
  CREATE_TASK_FAILURE,
  DELETE_TASK_FAILURE,
  UPDATE_TASK_FAILURE,
  CREATE_TIMEOFF_FAILURE,
  DELETE_TIMEOFF_FAILURE,
  UPDATE_TIMEOFF_FAILURE,
];
