import React from 'react';
import { Trans } from '@lingui/macro';

import { moment } from '@float/libs/moment';

import * as styles from '../styles.css';

type LastSyncedAtProps = {
  lastSyncUserName: string | undefined;
  lastSyncTime: string | undefined;
};

export const LastSyncedAt = ({
  lastSyncUserName,
  lastSyncTime,
}: LastSyncedAtProps) => {
  if (!lastSyncTime || !lastSyncUserName) {
    return null;
  }

  const timeDescription = moment(lastSyncTime).fromNow();

  return (
    <div className={styles.lastSyncedDescription}>
      <Trans>
        Last synced{' '}
        <span className={styles.lastSyncedDescriptionEmphasized}>
          {timeDescription} by {lastSyncUserName}
        </span>
      </Trans>
    </div>
  );
};
