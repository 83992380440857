import { parseISO } from 'date-fns';
import { createSelector } from 'reselect';

import { LockLoggedTimeItem } from '@float/types/lockLoggedTime';

import { ReduxStateStrict } from '../reducers/lib/types';

const normalizeDateStr = (date: string | undefined) => {
  // Remove time part 2020-05-05
  if (typeof date === 'string' && date.length > 10) {
    return date.slice(0, 10);
  }
  return date;
};

const findCurrentLockPeriod = (calendar: LockLoggedTimeItem[]) => {
  let currentLockPeriod = null;

  if (calendar?.length) {
    const calendarLockPeriodDesc = [...calendar].reverse();

    for (const l in calendarLockPeriodDesc) {
      const lockPeriodHasTriggered =
        parseISO(calendarLockPeriodDesc[l].triggerDate).getTime() < Date.now();
      const isFirstLockPeriod =
        parseInt(l) === calendarLockPeriodDesc.length - 1;

      if (lockPeriodHasTriggered || isFirstLockPeriod) {
        currentLockPeriod = calendarLockPeriodDesc[l];
        break;
      }
    }
  }

  return currentLockPeriod;
};

const findLatestLockPeriod = (calendar?: LockLoggedTimeItem[]) => {
  if (!calendar) {
    return null;
  }
  const currentLockPeriod = findCurrentLockPeriod(calendar);

  return normalizeDateStr(currentLockPeriod?.endDate) ?? null;
};

const findNextLockPeriod = (calendar?: LockLoggedTimeItem[]) => {
  if (!calendar) {
    return null;
  }
  const currentLockPeriod = findCurrentLockPeriod(calendar);
  const nextLockPeriod = calendar[calendar.indexOf(currentLockPeriod!) + 1];

  return normalizeDateStr(nextLockPeriod?.endDate) ?? null;
};

export const getLockPeriodDates = createSelector(
  [
    (state: ReduxStateStrict) => state.lockLoggedTime?.config?.active,
    (state: ReduxStateStrict) => state.lockLoggedTime?.config?.calendar,
  ],
  (active, cal) => {
    const calendar = active ? cal : undefined;

    return {
      latest: findLatestLockPeriod(calendar),
      next: findNextLockPeriod(calendar),
    };
  },
);
