import { config } from '@float/libs/config';

export const formatAmountToUSDCurrency = (
  amount,
  includeCents = true,
  withoutCurrencySymbol = false,
) => {
  const isWhole = (amount / 100) % 1 === 0;
  const minimumFractionDigits = isWhole || !includeCents ? 0 : 2;
  const nf = new Intl.NumberFormat(config.locale, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits,
    maximumFractionDigits: 2,
  });

  // Ability to customise the currency by returning only the values
  // https://app.asana.com/0/0/1201335282055625/f
  // https://app.asana.com/0/0/1201335282055626/f
  if (withoutCurrencySymbol) {
    const parts = nf.formatToParts(amount);

    const index = parts.findIndex((part) => part.type === 'currency');
    if (index > -1) {
      parts.splice(index, 1);
    }

    return parts.map((part) => part.value).join('');
  }

  return nf.format(amount);
};
