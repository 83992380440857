import React from 'react';
import styled from 'styled-components';

import IconSpinner from '../Icons/icon-spinner';

const SpinnerContainer = styled.div`
  height: 20px;
  display: inline-flex;
  vertical-align: -4px;
  justify-content: center;
  align-items: center;

  svg {
    animation: rotate 0.6s linear infinite;
  }
`;

export type SpinnerProps = {
  width?: string | number;
  height?: string | number;
  className?: string;
  style?: React.CSSProperties;
};

const Spinner = ({
  width = 'auto',
  height = 'auto',
  className = '',
  style = {},
}: SpinnerProps) => {
  return (
    <SpinnerContainer style={{ width, height, ...style }} className={className}>
      <IconSpinner color="#068edf" />
    </SpinnerContainer>
  );
};

Spinner._styles = { SpinnerContainer };

export default Spinner;
