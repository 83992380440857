import React from 'react';

import { linkArrowTarget } from './styles.css';

const LinkArrowTarget = ({
  linkedArrowTargetRef,
  totalHeight,
  totalWidth,
  cornerWidth,
}: {
  linkedArrowTargetRef: React.MutableRefObject<SVGSVGElement>;
  totalHeight: number;
  totalWidth: number;
  cornerWidth: number;
}) => {
  return (
    <svg
      className={linkArrowTarget}
      ref={linkedArrowTargetRef}
      style={{
        left: cornerWidth,
        width: totalWidth,
        height: totalHeight,
      }}
    >
      <defs>
        <clipPath id="linkedTaskLineClip">
          <rect
            width={5000}
            height={totalHeight}
            x="0"
            y="0"
            style={{
              fill: 'blue',
              opacity: 0.2,
            }}
          ></rect>
        </clipPath>
      </defs>
    </svg>
  );
};

export default LinkArrowTarget;
