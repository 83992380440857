import { useActiveStatusMenuItem } from './useActiveStatus';
import { useDeleteMenuItem } from './useDelete';
import { useDuplicateMenuItem } from './useDuplicate';
import { useProjectReportMenuItem } from './useProjectReport';
import { useShiftTimelineMenuItem } from './useShiftTimeline';
import { useTemplateFromProjectMenuItem } from './useTemplateFromProject';
import { useViewUsingFilterMenuItem } from './useViewUsingFilter';

function isDefined<T>(argument: T | undefined): argument is T {
  return argument !== undefined;
}

export function useMenuItems() {
  const viewUsingFilter = useViewUsingFilterMenuItem();
  const viewProjectReport = useProjectReportMenuItem();
  const duplicateMenuItem = useDuplicateMenuItem();
  const shiftTimelineMenuItem = useShiftTimelineMenuItem();
  const templateMenuItem = useTemplateFromProjectMenuItem();
  const activeStatusMenuItem = useActiveStatusMenuItem();
  const deleteMenuItem = useDeleteMenuItem();

  const items = [
    viewUsingFilter,
    viewProjectReport,
    duplicateMenuItem,
    shiftTimelineMenuItem,
    templateMenuItem,
    activeStatusMenuItem,
    deleteMenuItem,
  ].filter(isDefined);

  return items;
}
