import {
  FilterMatcher,
  FiltersContext,
  FiltersDataType,
  FiltersEntity,
  FilterTypes,
} from '../../types';

/**
 * This is the list of the filters currently managed by this module
 *
 * NOTE: me, personType, projectStatus, taskStatus & timeoffStatus are not included
 */
const CONTAINS_FILTER_TYPES: FilterTypes[] = [
  'client',
  'department',
  'jobTitle',
  'manager',
  'person',
  'personTag',
  'phase',
  'project',
  'projectOwner',
  'projectTag',
  'task',
  'timeoff',
];

export class ContainsMatcher<T extends FiltersDataType>
  implements FilterMatcher<T>
{
  matchers: FilterMatcher<T>[] = [];
  forceMatch = false;

  constructor(
    FilterMatcher: FilterMatcher<T>,
    context: FiltersContext<T>,
    values: string[],
  ) {
    for (const type of CONTAINS_FILTER_TYPES) {
      this.matchers.push(
        // @ts-expect-error
        new FilterMatcher(context, type, values, true),
      );
    }
  }

  matches(entity: FiltersEntity<T>): boolean {
    for (const matcher of this.matchers) {
      if (!matcher.forceMatch && matcher.matches(entity)) {
        return true;
      }
    }

    return false;
  }
}
