import {
  addedEntityCreator,
  failedFetchingEntitiesCreator,
  fetchedEntitiesCreator,
  fetchEntitiesCreator,
  getMappedPluralName,
  shouldFetchEntitiesCreator,
  sortEntitiesCreator,
} from '../creatorHelpers';

export const ADD_INVOICE_SUCCESS = 'ADD_INVOICE_SUCCESS';

export const FETCH_INVOICES = 'FETCH_INVOICES';
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';
export const FETCH_INVOICES_FAILURE = 'FETCH_INVOICES_FAILURE';

export const SORT_INVOICES = 'SORT_INVOICES';

const PLURAL = 'invoices';

export const addedInvoice = addedEntityCreator(ADD_INVOICE_SUCCESS);

const fetchedInvoices = fetchedEntitiesCreator(FETCH_INVOICES_SUCCESS);

const failedFetchingInvoices = failedFetchingEntitiesCreator(
  FETCH_INVOICES_FAILURE,
);

const shouldFetchInvoices = shouldFetchEntitiesCreator(PLURAL);

export const fetchInvoices = fetchEntitiesCreator(
  FETCH_INVOICES,
  PLURAL,
  shouldFetchInvoices,
  fetchedInvoices,
  failedFetchingInvoices,
);

export const sortInvoices = sortEntitiesCreator(SORT_INVOICES, fetchInvoices);

export function fetchAllInvoices() {
  return (dispatch, getState) => {
    return dispatch(fetchInvoices()).then(() => {
      const { pageCount, lastFetchedPage } =
        getState()[getMappedPluralName(PLURAL)];
      const fetchPromises = [];
      for (let page = lastFetchedPage + 1; page <= pageCount; page++) {
        fetchPromises.push(dispatch(fetchInvoices(undefined, page)));
      }
      return Promise.all(fetchPromises);
    });
  };
}
