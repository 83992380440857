import * as Comlink from 'comlink';
import { Dispatch, MiddlewareAPI } from 'redux';

import {
  SEARCH_CONTEXT_LOAD_FINISH,
  SEARCH_DERIVE_CONTEXT,
  setFilters,
} from '@float/common/actions';
import { AllActions } from '@float/common/reducers';
import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { searchServiceReady } from '@float/common/reducers/search';
import { searchResultsUpdateAction } from '@float/common/store/searchResults/searchResults.actions';
import { logger } from '@float/libs/logger';

import { getFiltersFromQueryString } from '../helpers';
import { SearchWorkerApiLayer } from './worker/SearchWorkerApiLayer';
import type { SearchResults } from './worker/subscribeToSearchResults';

export const searchWorkerResultMiddleware =
  (
    workerApi: Omit<SearchWorkerApiLayer, '#store'>,
    nonCloneableActions: Set<string>,
  ) =>
  (store: MiddlewareAPI<Dispatch<AllActions>, ReduxStateStrict>) => {
    workerApi.subscribeToSearchResults(
      Comlink.proxy((results: Partial<SearchResults>) => {
        store.dispatch(searchResultsUpdateAction(results));
      }),
    );

    async function dispatchToWorker(action: unknown) {
      try {
        await workerApi.dispatch(action);
      } catch (error) {
        const type =
          typeof action === 'object' && action && 'type' in action
            ? action.type
            : 'UNKOWN_ACTION';

        // Logging the error for better debugging
        logger.error(`Failed to dispatch ${type} to the Search worker`, error);
      }
    }

    // On the worker we can't read the querystring, so we send the initial state
    // for filters from here
    dispatchToWorker(setFilters(getFiltersFromQueryString(location.search)));

    return (next: (action: unknown) => unknown) =>
      async (action: AllActions) => {
        if (typeof action !== 'object') {
          return next(action);
        }

        // We don't want to pass it either to the search store or the main store
        // when the search worker is enabled
        if (action.type === SEARCH_DERIVE_CONTEXT) {
          return;
        }

        // Don't send the search results action to the worker
        // to avoid a useless roundtrip
        if (searchResultsUpdateAction.match(action)) {
          return next(action);
        }

        // These ones might have some functions inside and it's not useful for the search updates
        if (nonCloneableActions.has(action.type)) {
          return next(action);
        }

        if (action.type === SEARCH_CONTEXT_LOAD_FINISH) {
          dispatchToWorker(action);
          next(searchServiceReady());
          return;
        }

        dispatchToWorker(action);

        return next(action);
      };
  };
