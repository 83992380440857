import React from 'react';
import { t } from '@lingui/macro';
import { memoize } from 'lodash';

import { moment } from '@float/libs/moment';
import { DateRangePicker } from '@float/ui/deprecated';

class Range {
  label: string;
  type: moment.unitOfTime.StartOf;
  value: string;
  range: [number, moment.unitOfTime.DurationConstructor];

  constructor(
    label: string,
    value: string,
    type: moment.unitOfTime.DurationConstructor,
    count = 1,
  ) {
    this.label = label;
    this.value = value;
    this.type = type;
    this.range = [count, type];
  }

  start = () => {
    return moment().startOf(this.type);
  };

  end = () => {
    return this.start().endOf(this.type);
  };
}

class PrevRange extends Range {
  constructor(
    label: string,
    value: string,
    type: moment.unitOfTime.DurationConstructor,
    count = 1,
  ) {
    super(label, value, type, count);
  }

  start = () => {
    const [count, type] = this.range;
    return moment().subtract(count, type).startOf(type);
  };
}

const getDateRangeOpts = memoize(() => [
  new Range(t`This week`, '0', 'week'),
  new Range(t`This month`, '1', 'month'),
  new Range(t`This quarter`, '2', 'quarter'),
  new Range(t`This year`, '3', 'year'),
  new PrevRange(t`Last week`, '4', 'week'),
  new PrevRange(t`Last month`, '5', 'month'),
  new PrevRange(t`Last quarter`, '6', 'quarter'),
  new PrevRange(t`Last year`, '7', 'year'),
]);

export const STARTER_PLAN_MAX_RANGE = 7;
export const PRO_PLAN_MAX_RANGE = 180;

export const getDateDifferences = (d1: moment.Moment, d2: moment.Moment) =>
  d1.diff(d2, 'days');

export const formatDate = (d: moment.Moment) => moment(d).format('YYYY-MM-DD');

export const getInitialStarterRange = () => {
  const now = moment();
  return {
    rangeMode: 'custom',
    end: formatDate(now),
    start: formatDate(now.subtract(STARTER_PLAN_MAX_RANGE, 'days')),
  };
};

export const getInitialProRange = () => {
  const now = moment();
  return {
    rangeMode: 'custom',
    end: formatDate(now),
    start: formatDate(now.subtract(PRO_PLAN_MAX_RANGE, 'days')),
  };
};

export type DateRangeFilterProps = {
  rangeMode: string;
  start: Date;
  end: Date;
  firstOfWeek: number;
  onChange: () => unknown;
};

export function DateRangeFilter(props: DateRangeFilterProps) {
  const { rangeMode, start, end, firstOfWeek, onChange } = props;
  return (
    <DateRangePicker
      rangeMode={rangeMode}
      firstOfWeek={firstOfWeek}
      rangeOpts={getDateRangeOpts()}
      value={moment.range(start, end)}
      onChange={onChange}
    />
  );
}
