import React from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';
import { bindActionCreators } from 'redux';

import { removeAvatar, uploadAvatar } from '@float/common/actions';
import Avatar from '@float/common/components/Avatar/Avatar';
import { isPlaceholderAvatar } from '@float/common/lib/url';
import { withSnackbar } from '@float/ui/deprecated';

import { getNewPlaceholderType } from './helpers/getNewPlaceholderType';

const getPersonAvatar = (p) => p.avatar_file || p.avatar;

/*
  PersonAvatar accepts either a personId or an accountId (in that order of preference)
  and displays the person's (or account's) avatar.
 */

class PersonAvatar extends React.Component {
  state = {
    name: null,
    avatar: null,
    newAvatar: null,
    hasRemovedAvatar: false,
  };

  static getDerivedStateFromProps(props, state) {
    const { accountId, personId, overrideName } = props;
    let personFromStore = null;
    let accountFromStore = null;
    if (personId) {
      personFromStore = props.people[personId];
    }
    if (accountId) {
      personFromStore = find(props.people, (p) => p.account_id == accountId);
      if (!personFromStore) {
        accountFromStore = props.accounts[accountId];
      }
    }

    if (personFromStore) {
      return {
        personId: personFromStore.people_id,
        name: overrideName || personFromStore.name,
        avatar: getPersonAvatar(personFromStore),
      };
    }

    if (accountFromStore) {
      return {
        accountId: accountFromStore.account_id,
        name: overrideName || accountFromStore.name,
        avatar: getPersonAvatar(accountFromStore),
      };
    }

    if (overrideName !== null) {
      return {
        name: overrideName,
      };
    }

    return null;
  }

  getAvatarUrl = () => {
    const { avatar, newAvatar } = this.state;
    if (newAvatar) {
      return newAvatar;
    }
    return isPlaceholderAvatar(avatar) ? undefined : avatar;
  };

  isExistingPerson = () => !!(this.state.personId || this.state.accountId);

  hasRemovedAvatar = (state = this.state) => state.hasRemovedAvatar;

  onAvatarChange = async ({ dataUrl, blob }) => {
    const newState = { newAvatar: dataUrl, newAvatarBlob: blob };
    if (!dataUrl) {
      // removed
      newState.hasRemovedAvatar = true;
      newState.avatar = null;
    }
    this.setState(newState);
    if (this.isExistingPerson()) {
      await this.save({ state: newState });
    }
  };

  save = async (params) => {
    const { account_id, person_id, myInfo, state } = params || {};
    const { newAvatarBlob } = state || this.state;
    const requestData = {
      personId: this.state.personId || person_id,
      accountId: this.state.accountId || account_id,
      myInfo,
    };
    let updateAction = null;
    if (newAvatarBlob) {
      await this.props.actions.uploadAvatar(requestData, newAvatarBlob);
      updateAction = 'updated';
    } else if (this.hasRemovedAvatar(state)) {
      await this.props.actions.removeAvatar(requestData);
      updateAction = 'deleted';
    }
    if (updateAction) {
      this.setState({ newAvatarBlob: null, hasRemovedAvatar: false });
      this.props.showSnackbar(`Avatar ${updateAction}.`);
    }
  };

  render() {
    const { personId, accountId, overrideIsNewPlaceholderType, ...rest } =
      this.props; // eslint-disable-line

    return (
      <Avatar
        name={this.state.name}
        imageUrl={this.getAvatarUrl()}
        onChange={this.onAvatarChange}
        isNewPlaceholderType={
          overrideIsNewPlaceholderType ??
          getNewPlaceholderType(personId, this.props.people)
        }
        {...rest}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  accounts: state.accounts.accounts,
  people: state.people.people,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(
      {
        uploadAvatar,
        removeAvatar,
      },
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withSnackbar(PersonAvatar));
