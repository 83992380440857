import React from 'react';

export default React.forwardRef(
  ({ style, color = '#868D92', className }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        style={style}
        className={`icon-chevron-down-sm ${className || ''}`}
        ref={ref}
      >
        <path
          fill="none"
          fillRule="evenodd"
          stroke={color}
          strokeWidth="1.5"
          d="M2 10 l 4 -4 4 4"
        />
      </svg>
    );
  },
);
