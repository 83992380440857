import React from 'react';
import { assignInlineVars } from '@vanilla-extract/dynamic';

import { SerenaState } from '@float/common/selectors/serena';

import { getStatus, useProjectColor } from '../../../hooks/useProjectColor';

import * as styles from './MilestoneItem.css';

type MilestoneCircleProps = {
  project?: SerenaState['projects'][number];
  phase?: SerenaState['phases'][number];
};

const milestoneColorStyles = (bgColor?: string, borderColor?: string) =>
  assignInlineVars({
    [styles.milestoneColorVar]: bgColor ?? '',
    [styles.milestoneBorderColorVar]: borderColor ?? '',
  });

export function MilestoneCircle(props: MilestoneCircleProps) {
  const { project, phase } = props;
  const status = getStatus(project, phase);
  const { backgroundColor, borderColor } = useProjectColor(project, phase);

  return (
    <div
      className={styles.milestoneCircle({ status })}
      style={milestoneColorStyles(backgroundColor, borderColor)}
    />
  );
}
