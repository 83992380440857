import { createSelector } from 'reselect';

import { getRepeatedDates } from '@float/libs/datesRepeated/getRepeatedDates';
import { Task } from '@float/types/task';
import { Timeoff } from '@float/types/timeoff';

import { selectDatesManager } from '../currentUser';
import { getPeopleMapRaw } from '../people';
import { selectEntityWorkDaysDurationGetter } from './getEntityWorkDaysDuration';

export const selectEntityTotalWorkHoursGetter = createSelector(
  [selectDatesManager, selectEntityWorkDaysDurationGetter, getPeopleMapRaw],
  (dates, getEntityWorkDaysDuration, people) => (entity: Task | Timeoff) => {
    let totalHours = 0;

    const hoursByPerson = entity.people_ids.reduce(
      (acc, id) => {
        acc[id] = 0;
        return acc;
      },
      {} as Record<number, number>,
    );

    for (const personId of entity.people_ids) {
      const person = people[personId];
      const timeoff_id = 'timeoff_id' in entity ? entity.timeoff_id : undefined;

      if (!person) continue;

      const workDays = getEntityWorkDaysDuration(entity, person);
      const hours = (entity.hours ?? 0) * workDays;

      totalHours += hours;
      hoursByPerson[personId] = hours;

      for (const { start, end } of getRepeatedDates(dates, entity)) {
        const workDays = getEntityWorkDaysDuration(
          {
            start_date: dates.fromNum(start),
            end_date: dates.fromNum(end),
            timeoff_id,
          },
          person,
        );
        const hours = (entity.hours ?? 0) * workDays;

        totalHours += hours;
        hoursByPerson[personId] += hours;
      }
    }

    return { totalHours, hoursByPerson };
  },
);
