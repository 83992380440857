import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';

import { Icons, Popover } from '@float/ui/deprecated';
import { ProjectPercentageModes } from '@float/ui/deprecated/Chart/types';
import { RowLabel } from '@float/ui/deprecated/VirtualSelect/styles';

import {
  IconWrapper,
  PercentageSelectContainer,
  PercentageSelectWrapper,
} from './percentages.styles';
import { ProjectsOverviewTableContext } from './types';

function PercentageSelector({ ctx }: { ctx: ProjectsOverviewTableContext }) {
  const [open, setOpen] = useState(false);
  const { updateSettings, projectsPercentageMode } = ctx;

  let shortLabel = '';
  if (projectsPercentageMode === ProjectPercentageModes.LOGGED) {
    shortLabel = t`Logged %`;
  } else {
    shortLabel = t`Sched. %`;
  }

  return (
    <div style={{ display: 'flex' }}>
      {shortLabel}
      <Popover
        arrow={false}
        placement="bottom"
        className="float-nav-dropdown"
        onOpenChange={setOpen}
        open={open}
        distance={0}
        content={
          <PercentageSelectWrapper
            onClick={() => {
              setOpen(false);
            }}
          >
            <RowLabel
              onClick={(e) =>
                updateSettings({
                  projectsPercentageMode: ProjectPercentageModes.SCHEDULED,
                })
              }
            >
              <Trans>Total scheduled % of budget</Trans>
            </RowLabel>
            <RowLabel
              onClick={(e) =>
                updateSettings({
                  projectsPercentageMode: ProjectPercentageModes.LOGGED,
                })
              }
            >
              <Trans>Total logged % of budget</Trans>
            </RowLabel>
          </PercentageSelectWrapper>
        }
      >
        <PercentageSelectContainer>
          <IconWrapper>
            <Icons.DownSmall className="prevent-sort-action" />
          </IconWrapper>
        </PercentageSelectContainer>
      </Popover>
    </div>
  );
}

export function getPctHeader(ctx: ProjectsOverviewTableContext) {
  const { timeTrackingEnabled } = ctx;

  if (!timeTrackingEnabled) {
    return t`Sched. %`;
  }

  return <PercentageSelector ctx={ctx} />;
}
