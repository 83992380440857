import {
  ADD_TIMEOFF_TYPE,
  ADD_TIMEOFF_TYPE_FAILURE,
  ADD_TIMEOFF_TYPE_SUCCESS,
  DELETE_TIMEOFF_TYPE_SUCCESS,
  UPDATE_TIMEOFF_TYPE,
  UPDATE_TIMEOFF_TYPE_FAILURE,
  UPDATE_TIMEOFF_TYPE_SUCCESS,
} from '@float/common/actions/timeoffsConstants';

import {
  FETCH_TIMEOFF_TYPES,
  FETCH_TIMEOFF_TYPES_FAILURE,
  FETCH_TIMEOFF_TYPES_SUCCESS,
  SORT_TIMEOFF_TYPES,
} from '../actions/timeoffTypes';
import {
  ACCOUNT_SETTINGS_TIMEOFF_TYPES_TABLE,
  getInitialSortableTableState,
  getInitialSortPropsState,
  getInitialStateCreator,
  reducerCreator,
} from './reducerHelpers';

export const lastFetchError = (state) => state.lastFetchError;

export const isBeingEdited = (entity, state) =>
  state.beingEditedIds.indexOf(entity.timeoff_type_id) > -1;

export const areBeingFetched = (state) => state.pagesBeingFetched.length > 0;

const getInitialState = getInitialStateCreator(
  Object.assign(
    {
      sortableTables: {
        [ACCOUNT_SETTINGS_TIMEOFF_TYPES_TABLE]: getInitialSortableTableState(
          'timeoff_type_name',
          'asc',
        ),
      },
    },
    getInitialSortPropsState(['timeoff_type_name']),
  ),
);

// must be enclosed in a function for constants to be available for evaluation
const timeoffTypes = reducerCreator(
  {
    ADD_ENTITY: ADD_TIMEOFF_TYPE,
    ADD_ENTITY_SUCCESS: ADD_TIMEOFF_TYPE_SUCCESS,
    ADD_ENTITY_FAILURE: ADD_TIMEOFF_TYPE_FAILURE,
    DELETE_ENTITY_SUCCESS: DELETE_TIMEOFF_TYPE_SUCCESS,
    UPDATE_ENTITY: UPDATE_TIMEOFF_TYPE,
    UPDATE_ENTITY_SUCCESS: UPDATE_TIMEOFF_TYPE_SUCCESS,
    UPDATE_ENTITY_FAILURE: UPDATE_TIMEOFF_TYPE_FAILURE,
    SORT_ENTITIES: SORT_TIMEOFF_TYPES,
    FETCH_ENTITIES: FETCH_TIMEOFF_TYPES,
    FETCH_ENTITIES_SUCCESS: FETCH_TIMEOFF_TYPES_SUCCESS,
    FETCH_ENTITIES_FAILURE: FETCH_TIMEOFF_TYPES_FAILURE,
  },
  'timeoff_type_id',
  getInitialState,
);

export default timeoffTypes;
