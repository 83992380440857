import { SavedView } from '@float/types';

import {
  RemoveAllFiltersAction,
  SEARCH_REMOVE_ALL_FILTERS,
} from '../actions/search';
import {
  INITIAL_VIEW_LOADED,
  VIEW_APPLIED,
  VIEW_CREATED,
  VIEW_DELETED,
  VIEW_UNAPPLY,
  VIEW_UPDATED,
  VIEWS_LOAD_FAILED,
  VIEWS_LOAD_FINISH,
  VIEWS_LOAD_START,
  ViewsAction,
} from '../actions/views/views';

export type ViewsState = {
  views: SavedView[];
  loadState: 'LOAD_FAILED' | 'LOADING' | 'LOADED' | 'INITIAL';
  appliedViewId?: number;
};

export const DEFAULT_STATE: ViewsState = {
  views: [],
  loadState: 'INITIAL',
};

export function viewsReducer(
  state = DEFAULT_STATE,
  action: ViewsAction | RemoveAllFiltersAction,
): ViewsState {
  switch (action.type) {
    case VIEWS_LOAD_START: {
      return {
        ...state,
        loadState: 'LOADING',
      };
    }

    case VIEWS_LOAD_FINISH: {
      return {
        ...state,
        loadState: 'LOADED',
        views: action.views,
      };
    }

    case VIEWS_LOAD_FAILED: {
      return {
        ...state,
        loadState: 'LOAD_FAILED',
      };
    }

    case INITIAL_VIEW_LOADED: {
      return {
        ...state,
        views: [action.view],
        appliedViewId: action.view.id,
      };
    }

    case VIEW_CREATED: {
      return {
        ...state,
        views: state.views.concat(action.view),
      };
    }

    case VIEW_DELETED: {
      return {
        ...state,
        views: state.views.filter((view) => view.id !== action.id),
      };
    }

    case VIEW_UPDATED: {
      const views = state.views.map((view) => {
        if (view.id === action.view.id) return action.view;

        return view;
      });

      return {
        ...state,
        views,
      };
    }

    case VIEW_APPLIED: {
      const views = state.views.map((view) => {
        if (view.id === action.view.id)
          return {
            ...view,
            lastUsed: new Date().toJSON(),
          };

        return view;
      });

      return {
        ...state,
        views,
        appliedViewId: action.view.id,
      };
    }

    case SEARCH_REMOVE_ALL_FILTERS:
    case VIEW_UNAPPLY: {
      return {
        ...state,
        appliedViewId: undefined,
      };
    }

    default: {
      return state;
    }
  }
}
