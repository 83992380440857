import { BudgetPriority } from '@float/types/project';

import { ProjectFormData } from '../types';

export function parseProjectFormDataBeforeSave(currentValues: ProjectFormData) {
  const { phases, project, team } = currentValues;
  // budget_totals for phases are maintained on the form context even when budget per phase is not set
  // to true. So they need to be scrubbed here.
  const phasesData =
    project.budget_priority === BudgetPriority.Phase
      ? phases
      : phases.map((p) => ({
          ...p,
          budget_total: null,
        }));

  const projectTeamExcludingPeopleAssignedToPhaseOnly = team.filter(
    (p) => !p.isAssignedToPhaseOnly,
  );

  return {
    phases: phasesData,
    team: projectTeamExcludingPeopleAssignedToPhaseOnly,
  };
}
