import React, { useMemo } from 'react';

import PersonAvatar from '@float/common/components/elements/PersonAvatar';
import { trimTextForReadibility } from '@float/libs/utils/string/trimTextForReadibility';
import { ButtonAction } from '@float/ui/components/ButtonAction';
import { Button, DropdownSelect, EH } from '@float/ui/deprecated';

import {
  ProjectBlockActionsContainer,
  TemplateBlockItem,
  TemplateBlockItemActions,
  TemplateBlockItemAuthor,
  TemplateBlockItemAuthorInfo,
  TemplateBlockItemAuthorInfoLabel,
  TemplateBlockItemAuthorSpan,
  TemplateBlockItemHeader,
  TemplateBlockItemTitle,
  TemplateBlockPersonLabel,
  TemplateContent,
} from './styles';

export const TemplateBlock = ({
  title,
  author = {},
  color,
  onUseClick,
  onEditClick,
  onDeleteClick,
  isColumn,
  isEditable,
}) => {
  const templateTitle = useMemo(() => {
    const { value } = trimTextForReadibility(title, 35);
    return value;
  }, [title]);

  const showEdit = Boolean(isEditable && onEditClick);

  return (
    <TemplateBlockItem
      color={color}
      isColumn={isColumn}
      isEditable
      shouldShowActions
      onClick={isColumn ? onUseClick : null}
    >
      <TemplateContent>
        <TemplateBlockItemHeader>
          <TemplateBlockItemTitle>{templateTitle}</TemplateBlockItemTitle>
          {isEditable && !isColumn && (
            <ProjectBlockActionsContainer>
              <DropdownSelect
                appearance="empty"
                align="bottom-left"
                tabIndex={-1}
                options={[
                  {
                    label: 'Delete template',
                    withDivider: true,
                    iconRight: <EH.Icons.IconTrash />,
                    appearance: 'danger',
                    onSelect: () => {
                      onDeleteClick();
                    },
                  },
                ]}
              >
                <ButtonAction icon={EH.Icons.IconKebabDots} />
              </DropdownSelect>
            </ProjectBlockActionsContainer>
          )}
        </TemplateBlockItemHeader>

        {!isColumn && (
          <>
            <TemplateBlockItemAuthor>
              <TemplateBlockItemAuthorInfo>
                <PersonAvatar accountId={author.id} size="s" readOnly />
                <TemplateBlockItemAuthorInfoLabel>
                  <TemplateBlockItemAuthorSpan>
                    Created by
                  </TemplateBlockItemAuthorSpan>
                  <TemplateBlockPersonLabel>
                    {author.name}
                  </TemplateBlockPersonLabel>
                </TemplateBlockItemAuthorInfoLabel>
              </TemplateBlockItemAuthorInfo>
            </TemplateBlockItemAuthor>

            <TemplateBlockItemActions isColumn={isColumn} isEditable={showEdit}>
              <Button size="small" type="submit" onClick={onUseClick}>
                Use template
              </Button>
              {showEdit && (
                <Button
                  appearance="tertiary"
                  size="small"
                  type="submit"
                  onClick={onEditClick}
                >
                  Edit template
                </Button>
              )}
            </TemplateBlockItemActions>
          </>
        )}
      </TemplateContent>
    </TemplateBlockItem>
  );
};
