import { saveAs } from 'file-saver';
import { sortBy } from 'lodash';
import { getTaskStatusString } from 'reports/components/TabBarFilters/StatusFilter';
import { getTeamModeString } from 'reports/components/TabBarFilters/TeamMode';

import { moment } from '@float/libs/moment';

const COLUMNS = [
  ['Date', (d) => d.date],
  ['Client', (d) => d.client?.replace(/\r|\n/g, '') || ' '], // filter new lines
  ['Project', (d) => d.project || ' '],
  ['Phase', (d) => d.phase || ' '],
  ['Task', (d) => d.name || ' '],
  ['Person', (d) => d.person || ' '],
  ['Logged fee', (d) => (d.feeCents ? d.feeCents / 100 : ' ')],
  ['Logged Billable hours', (d) => (d.billable ? d.hours.scheduled : 0)],
  ['Logged Non-billable hours', (d) => (d.billable ? 0 : d.hours.scheduled)],
  ['Notes', (d) => d.note || ' '],
];

function getSheetData(ctx, rawTableData) {
  const columns = [...COLUMNS];
  const { project } = ctx;
  const hasFeeColumns = project.budget_type === 2 || project.budget_type === 3;
  if (!hasFeeColumns) {
    columns.splice(6, 1);
  }

  let result = [];
  let totalFeeCents = 0;
  let totalBillableHours = 0;
  let totalNonbillableHours = 0;

  const hasPhases = rawTableData.totals.some((i) => i.phase_id);
  if (!hasPhases) {
    columns.splice(3, 1);
  }

  rawTableData.totals.forEach((item) => {
    if (item.type !== 'logged_time') return;

    const expandedItem = {
      ...item,
      feeCents: item.billable ? item.hours.scheduled * item.rate * 100 : '',
      client: ctx.project.client_name,
      project: ctx.project.project_name,
      person: ctx.people[item.person_id].name,
      phase: ctx.phases[item.phase_id]?.phase_name || 'No Phase',
    };

    const row = columns.map((c) => c[1](expandedItem) || 0);
    result.push(row);

    if (expandedItem.feeCents) {
      totalFeeCents += expandedItem.feeCents;
    }

    if (expandedItem.billable) {
      totalBillableHours += item.hours.scheduled;
    } else {
      totalNonbillableHours += item.hours.scheduled;
    }
  });

  // Sort by date
  result = sortBy(result, 0);

  const headers = columns.map((c) => c[0]);
  result.unshift(headers);

  const totals = [
    'Total',
    ' ',
    ' ',
    ' ',
    ' ',
    ' ',
    totalFeeCents ? totalFeeCents / 100 : ' ',
    totalBillableHours,
    totalNonbillableHours,
    ' ',
  ];

  if (!hasFeeColumns) {
    totals.splice(6, 1);
  }

  if (!hasPhases) {
    totals.splice(3, 1);
  }

  result.push(totals);

  result.push([], []);

  result.push(
    [
      'Date range',
      moment(ctx.settings.startDate).format('DD MMM YYYY'),
      moment(ctx.settings.endDate).format('DD MMM YYYY'),
    ],
    ['Tasks', getTaskStatusString(ctx.settings)],
    ['People', getTeamModeString(ctx.settings)],
  );

  if (ctx.searchFiltersString) {
    result.push([ctx.searchFiltersString.replace('Filters:', 'Filters')]);
  }

  return result;
}

export default async function exportTableCsv(ctx, rawTableData) {
  return import('xlsx').then((XLSX) => {
    const { project, settings } = ctx;
    const startDate = moment(settings.startDate).format('YYYYMMDD');
    const endDate = moment(settings.endDate).format('YYYYMMDD');

    const projectName =
      `${project.client_name}-${project.project_name}`.replaceAll(' ', '-');

    const filename = `${projectName}-LoggedTime-${startDate}-${endDate}.csv`;

    const sheetData = getSheetData(ctx, rawTableData);

    const ws = XLSX.utils.aoa_to_sheet(sheetData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Float');
    const wbout = XLSX.write(wb, { type: 'array', bookType: 'csv' });

    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), filename);
  });
}
