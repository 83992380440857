import React from 'react';
import {
  getProjectsTagsValues,
  getProjectTagByLabel,
  TAG_TYPE,
} from 'selectors/tags';

import { Rights } from '@float/common/lib/acl';
import { useAppSelector } from '@float/common/store';
import { MultiSelect } from '@float/ui/deprecated';
import { getUser } from '@float/web/selectors';

import { getColoredTags } from '../helpers/getColoredTags';

export function useTagsMultiselectOptions(values: string[]) {
  const currentUser = useAppSelector(getUser);
  const projectTagByLabel = useAppSelector(getProjectTagByLabel);
  const projectTagsValues = useAppSelector(getProjectsTagsValues);

  return {
    creatable: Rights.canCreateTags(currentUser, {
      tag: { type: TAG_TYPE.PROJECT },
    }),
    options: getColoredTags(projectTagsValues, projectTagByLabel),
    values: getColoredTags(values, projectTagByLabel),
  };
}

export function TagsMultiselect(props: {
  errors?: unknown[];
  values: string[];
  onAdd: (option: { value: string }) => void;
  onRemove: (option: { value: string }) => void;
}) {
  const { creatable, options, values } = useTagsMultiselectOptions(
    props.values,
  );

  return (
    <MultiSelect
      creatable={creatable}
      visibleItems={6}
      options={options}
      values={values}
      errors={props.errors}
      onAdd={props.onAdd}
      onRemove={props.onRemove}
      style={{ marginBottom: 22 }}
    />
  );
}
