import React, { Fragment } from 'react';
import cn from 'classnames';

import { FilterToken as FilterTokenValue } from '@float/types';

import { FilterValue } from './FilterValue';

import * as styles from '../styles.css';

export function SearchValues({
  filter,
  color,
}: {
  filter: FilterTokenValue;
  color: styles.PaletteColor;
}) {
  const lastIndex = filter.val.length - 1;

  // TODO: Hack. On reports, "projectStatus = Mine" needs to always be an AND.
  // Find a better approach to handle this.
  // https://linear.app/float-com/issue/PI-68/remove-the-hack-of-projectstatus-mine-on-reports-page
  const mineScenario =
    filter.val.length &&
    filter.val[lastIndex] === 'Mine' &&
    window.location.pathname === '/report';

  const val = filter.val;

  if (!Array.isArray(val)) {
    return <FilterValue type={filter.type} val={val} />;
  }

  return (
    <>
      {val.map((val, i) => {
        const showAnd = mineScenario && i === lastIndex - 1;
        return (
          <Fragment key={val}>
            <div className={styles.subToken()}>
              <FilterValue type={filter.type} val={val} />
            </div>
            {showAnd && (
              <div
                className={cn(
                  styles.tokenOperator,
                  styles.subToken({
                    textColor: color,
                  }),
                )}
              >
                and
              </div>
            )}
            {!showAnd && i !== lastIndex && (
              <div
                className={cn(
                  styles.tokenOperator,
                  styles.subToken({
                    textColor: color,
                  }),
                )}
              >
                or
              </div>
            )}
          </Fragment>
        );
      })}
    </>
  );
}
