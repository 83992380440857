import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Dispatch } from 'redux';

import { AllActions } from '@float/common/reducers';
import { AppDispatch } from '@float/common/store';
import { DatesManager } from '@float/types';

import { RESET_SCHED_RANGE } from '../redux/reducers';
import { ScheduleDataFetcher } from './ScheduleDataFetcher';
import { ScheduleDataFetcherAPI } from './ScheduleDataFetcher/api';
import { getRangeStart } from './ScheduleDataFetcher/helpers';

// Exported for testing only
export const _resetFetchRanges = (
  dispatch: AppDispatch,
  fetchedRangesRef: MutableRefObject<number[]>,
) => {
  dispatch({
    type: RESET_SCHED_RANGE,
  });
  fetchedRangesRef.current = [];
};

export function useScheduleDataFetcherGeneric(
  api: ScheduleDataFetcherAPI,
  reduxDispatch: Dispatch<AllActions>,
  reduxSchedule: { fetchedRanges: number[] },
  dates: DatesManager,
  weeksPerFetch: number,
) {
  // Although we want to keep the fetched ranges in Redux to remove the need
  // to re-fetch data when we come back to the schedule from a different tab,
  // we want to track the fetched ranges synchronously in this data fetcher
  // to prevent duplicate requests from going out in case there's a delay with
  // dispatching the update to Redux.
  const fetchedRangesRef = useRef([...reduxSchedule.fetchedRanges]);

  const sdf = useState(
    () =>
      new ScheduleDataFetcher(
        api,
        dates,
        fetchedRangesRef,
        weeksPerFetch,
        reduxDispatch,
      ),
  )[0];

  useEffect(() => {
    if (dates !== sdf.dates) {
      sdf.setDates(dates);
    }
  }, [dates, sdf]);

  const isColumnFetched = useCallback(
    (columnIndex: number) => {
      const range = getRangeStart(columnIndex, weeksPerFetch);
      return reduxSchedule.fetchedRanges.includes(range);
    },
    [reduxSchedule.fetchedRanges, weeksPerFetch],
  );

  const resetFetchedRanges = useCallback(() => {
    _resetFetchRanges(reduxDispatch, fetchedRangesRef);
  }, [reduxDispatch]);

  return useMemo(
    () => ({
      ensureRangeFetched: sdf.ensureRangeFetched,
      isColumnFetched,
      weeksPerFetch,
      hasFetchedRange: reduxSchedule.fetchedRanges.length > 0,
      fetchedRanges: reduxSchedule.fetchedRanges,
      resetFetchedRanges,
    }),
    [
      isColumnFetched,
      reduxSchedule.fetchedRanges,
      sdf.ensureRangeFetched,
      weeksPerFetch,
      resetFetchedRanges,
    ],
  );
}

export type ScheduleDataFetcherResult = ReturnType<
  typeof useScheduleDataFetcherGeneric
>;
