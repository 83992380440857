import { isWhitespace } from './is-whitespace';

/**
 * Find the mention beginning
 * @param {number} index - current search position
 * @param {string} text
 * @return {number} mention beginning position
 */

const isMentionTrigger = (trigger, value) => {
  const mentionTriggerRegex = new RegExp(`${trigger}`);

  return mentionTriggerRegex.test(value);
};

const findMentionBeginning = (trigger, index, text) => {
  if (index > 0 && !isMentionTrigger(trigger, text[index - 1])) {
    return findMentionBeginning(trigger, index - 1, text);
  }

  if (index > 0 && (!text[index - 2] || !isWhitespace(text[index - 2]))) {
    return findMentionBeginning(trigger, index - 1, text);
  }

  if (index === 0 && text[index] && isMentionTrigger(trigger, text[index])) {
    return 1;
  }

  return index;
};

export { findMentionBeginning };
