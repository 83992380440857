import React, { useEffect, useState } from 'react';

import {
  Button,
  ErrorText,
  Input,
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  ModalTitle,
  ToggleGroup,
  useSnackbar,
} from '@float/ui/deprecated';
import { required, useInput } from '@float/ui/deprecated/helpers/formHooks';

import { SamlModalTitle } from '../styles';

const signInOptions = [
  { value: 1, label: 'Require SSO for sign in' },
  { value: 0, label: 'Allow password sign in' },
];

function SamlModal({ sso, onSubmit, close, ssoRequired: currentSsoRequired }) {
  const [errorText, setErrorText] = useState('');
  const { showSnackbar } = useSnackbar();

  const endpoint = useInput(sso.endpoint, {
    validate: required('Endpoint cannot be blank.'),
  });
  const issuer_url = useInput(sso.issuer_url, {
    validate: required('Issuer cannot be blank.'),
  });
  const certificate = useInput(sso.certificate, {
    validate: required('Certificate cannot be blank.'),
  });
  const [loading, setLoading] = useState(false);
  const [ssoRequired, setSsoRequired] = useState(currentSsoRequired);

  async function submit() {
    const allBlank = !endpoint.value && !issuer_url.value && !certificate.value;

    if (!allBlank) {
      const endpointValid = endpoint.validate();
      const issuerValid = issuer_url.validate();
      const certificateValid = certificate.validate();
      if (!endpointValid || !issuerValid || !certificateValid) return;
    }

    setLoading(true);

    try {
      await onSubmit({
        saml_endpoint: endpoint.value,
        issuer_url: issuer_url.value,
        certificate: certificate.value,
        sso_required: ssoRequired,
      });
      close();
      showSnackbar('SAML SSO settings updated.');
    } catch (e) {
      if (e.status == '400') {
        setLoading(false);
        setErrorText(e.description);
        return;
      }

      throw e;
    }
  }

  const withValues = endpoint.value && issuer_url.value && certificate.value;

  useEffect(() => {
    if (!withValues && ssoRequired === 'saml') {
      // currentSsoRequired === 'saml' ? null : currentSsoRequired
      setSsoRequired(null);
    }
  }, [withValues, ssoRequired, currentSsoRequired]);

  return (
    <Modal isOpen={true} onEnter={submit} onClose={close}>
      <ModalHeader>
        <ModalTitle>Configure SAML SSO</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form>
          <Input
            autoFocus
            label="SAML 2.0 Endpoint URL (HTTP)"
            placeholder="https://companyname.onelogin.com/trust/http-post/sso/546600"
            style={{ marginBottom: '20px' }}
            {...endpoint}
          />
          <Input
            label="Identity Provider Issuer"
            placeholder="https://app.onelogin.com/saml/metadata/546600"
            style={{ marginBottom: '20px' }}
            {...issuer_url}
          />
          <Input
            label="X.509 Certificate"
            placeholder="Paste your entire X.509 certificate here"
            style={{ marginBottom: '20px' }}
            {...certificate}
          />
          <SamlModalTitle>How your team will sign in</SamlModalTitle>
          <ToggleGroup
            style={{ marginBottom: 20 }}
            value={ssoRequired === 'saml' ? 1 : 0}
            options={signInOptions}
            disabled={!withValues}
            appearance="stitched"
            disableUnselect
            onChange={(value) => {
              // value === 1 ? 'saml' : currentSsoRequired === 'saml' ? null : currentSsoRequired
              setSsoRequired(value === 1 ? 'saml' : null);
            }}
          />
          <p>
            Once authenticated, a new SAML button is added to Float login page
            and your team can sign in using your identity provider.{' '}
            {ssoRequired === 'saml' ? (
              <>
                Mobile app users will only be able to sign in by requesting a
                magic link.
              </>
            ) : null}
            {ssoRequired !== 'saml' ? (
              <>
                <strong>Note</strong>: They will continue to be able to sign in
                using their email and password.
              </>
            ) : null}
          </p>
          {errorText && <ErrorText>{errorText}</ErrorText>}
        </form>
      </ModalBody>
      <ModalActions>
        <Button loader={loading} onClick={submit}>
          Save
        </Button>
        <Button appearance="secondary" onClick={close}>
          Cancel
        </Button>
      </ModalActions>
    </Modal>
  );
}

export default SamlModal;
