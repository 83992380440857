import { isNot, isOr } from '@float/common/search/helpers';
import { BaseFilterToken } from '@float/types';

export type FiltersValidationError =
  | 'no-or-between-different-types'
  | 'no-or-between-different-operators';

export function validateFilters(
  filters: BaseFilterToken[],
): FiltersValidationError | null {
  for (let i = 1; i < filters.length; i++) {
    const prev = filters[i - 1];
    const curr = filters[i];

    if (isOr(curr.operator)) {
      if (prev.type !== curr.type) {
        return 'no-or-between-different-types';
      }

      if (isNot(prev.operator) !== isNot(curr.operator)) {
        return 'no-or-between-different-operators';
      }
    }
  }

  return null;
}
