import React from 'react';

export default function ({ size = '24', color = '#ffffff' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" />
      <g clipPath="url(#clip0_7171_10285)">
        <path
          d="M9.75 10.75C9.75 10.1977 10.1977 9.75 10.75 9.75H21.25C21.8023 9.75 22.25 10.1977 22.25 10.75V21.25C22.25 21.8023 21.8023 22.25 21.25 22.25H10.75C10.1977 22.25 9.75 21.8023 9.75 21.25V10.75Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7171_10285">
          <rect
            width="24"
            height="24"
            fill={color}
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
