import { isEmpty } from 'lodash';

import { getProjectTeamDiff } from '../../helpers/getProjectTeamDiff';
import { getDefaultProjectName } from '../../helpers/mapToAsyncProjectApiPayload';
import { ProjectFormData, UnsavedProjectDataForPhase } from '../../types';

type GetUnsavedFieldsProps = {
  projectId: number;
  isTemplate: boolean;
  currentValues: ProjectFormData | undefined;
  defaultValues: ProjectFormData | undefined;
};

const PROJECT_FIELDS_OF_INTEREST_TO_PHASE_PANEL: Array<
  keyof UnsavedProjectDataForPhase['project']
> = [
  'project_name',
  'color',
  'budget_type',
  'budget_priority',
  'budget_total',
  'status',
  'non_billable',
];

function getProjectInfoDiff(props: GetUnsavedFieldsProps) {
  const result: Partial<UnsavedProjectDataForPhase['project']> = {};
  const isNewProject = !props.projectId;

  PROJECT_FIELDS_OF_INTEREST_TO_PHASE_PANEL.forEach((key) => {
    const defaultValue = props.defaultValues?.project[key];
    const currentValue = props.currentValues?.project[key];

    if (isNewProject || defaultValue !== currentValue) {
      // @ts-expect-error - key is a valid key of UnsavedProjectDataForPhase
      result[key] = currentValue;
    }
  });

  const isProjectNameEmpty = !result.project_name;
  if (isNewProject && isProjectNameEmpty) {
    result.project_name = getDefaultProjectName(props.isTemplate);
  }

  return isEmpty(result) ? undefined : result;
}

export function getUnsavedProjectFieldsForPhasePanel(
  props: GetUnsavedFieldsProps,
) {
  const result: Partial<UnsavedProjectDataForPhase> = {};

  const infoDiff = getProjectInfoDiff(props);
  const teamDiff = getProjectTeamDiff(
    props.currentValues?.team,
    props.defaultValues?.team,
  );

  if (infoDiff) result.project = infoDiff;
  if (teamDiff) result.teamDiff = teamDiff;

  return isEmpty(result) ? undefined : result;
}
