import { BudgetUsage } from './budget';
import { Person } from './person';
import { Phase } from './phase';
import { NotesMeta } from './task';

export const enum BudgetType {
  Disabled = 0,
  TotalHours = 1,
  TotalFee = 2,
  HourlyFee = 3,
}

export const enum BudgetPriority {
  Project = 0,
  Phase = 1,
  Task = 2,
}

export enum ProjectStatus {
  Draft = 0,
  Tentative = 1,
  Confirmed = 2,
}

export type Project = {
  active: boolean;
  all_pms_schedule: boolean;
  budget_total?: string | null;
  budget_type?: BudgetType;
  budget_priority?: BudgetPriority;
  calculated_end_date?: string | null;
  calculated_start_date?: string | null;
  canEdit: boolean;
  client_id?: number;
  client_name: string;
  // TODO: Make the color type nullable
  // https://linear.app/float-com/issue/PSQ1-577/typescript-make-the-color-attribute-in-project-nullable
  color: string;
  common: boolean;
  created?: string;
  creator_id?: number;
  default_hourly_rate?: string | null;
  description?: string;
  end_date?: string;
  ext_calendar_count?: number;
  ext_resource_id?: number;
  integrations_co_id?: number;
  locked_task_list: 0 | 1;
  long_name: string;
  modified?: string;
  non_billable: boolean;
  notes_meta?: NotesMeta;
  people_ids: number[];
  people_rates: Record<number, string | null>;
  phases?: unknown;
  project_id: number;
  project_manager: number;
  project_name: string;
  project_team: string[];
  start_date?: string;
  status: ProjectStatus;
  tags: string[];
  /**
   * @deprecated Use `status`
   */
  tentative: boolean;
  set_dates?: {
    start_date?: string;
    end_date?: string;
  };
  // Template data
  duration?: number | null;
};

export type EnhancedProject = Project & {
  all_people_ids: number[];
  long_name: string;
  canSeeBudget: boolean;
  isMoneyBudgetType: boolean;
};

export type RawProject = {
  active: 0 | 1;
  all_pms_schedule: 0 | 1;
  budget_total?: number;
  budget_type?: BudgetType;
  budget_priority?: BudgetPriority;
  budget?: number;
  budgets?: number;
  calculated_end_date?: string | null;
  calculated_start_date?: string | null;
  client_id?: number;
  color?: string;
  created: string;
  default_hourly_rate?: number;
  end_date?: string;
  ext_calendar_count?: number;
  ext_resource_id?: number;
  integrations_co_id?: number;
  locked_task_list: 0 | 1;
  modified: string;
  name?: string;
  non_billable?: 0 | 1;
  notes_meta?: NotesMeta;
  notes?: string;
  people_ids?: number[];
  phases?: unknown;
  project_id: number;
  project_dates?: {
    start_date?: string;
    end_date?: string;
  };
  set_dates?: {
    start_date?: string;
    end_date?: string;
  };
  shift_dates?: {
    start_date: string;
    end_date: string;
  };
  project_manager?: string;
  project_name?: string;
  project_team?: { people_id: Person['people_id']; hourly_rate: number }[];
  start_date?: string;
  status: ProjectStatus;
  tags?: string[];
  /**
   * @deprecated Use `status`
   */
  tentative?: 0 | 1;
};

export type RawProjectTemplate = {
  active: 0 | 1;
  all_pms_schedule: 0 | 1;
  budget_total?: number;
  budget_type?: BudgetType;
  budget_priority?: BudgetPriority;
  budget?: number;
  client_id?: number;
  color?: string;
  created: string;
  creator_id: number;
  default_hourly_rate?: string | null;
  duration?: number;
  end_date?: string;
  ext_calendar_count?: number;
  ext_resource_id?: number;
  integrations_co_id?: number;
  locked_task_list: 0 | 1;
  milestones?: unknown;
  phases?: unknown;
  modified: string;
  name?: string;
  non_billable?: 0 | 1;
  notes?: string;
  people_ids?: number[];
  project_dates?: {
    start_date?: string;
    end_date?: string;
  };
  project_manager?: string;
  project_name?: string;
  project_template_id: number;
  start_date?: string;
  status: ProjectStatus;
  tags?: string[];
  task_metas?: unknown;
  team_people?: { id: number; hourly_rate: number }[];
  /**
   * @deprecated Use `status`
   */
  tentative?: 0 | 1;
};

export type ProjectTemplate = {
  active: boolean;
  budget_priority?: BudgetPriority;
  budget_total: string | null;
  budget_type?: BudgetType;
  client_id?: number;
  color?: string;
  common: boolean;
  created: string;
  creator_id: number;
  default_hourly_rate: string | null;
  description?: string;
  end_date?: string;
  ext_calendar_count?: number;
  ext_resource_id?: number;
  integrations_co_id?: number;
  locked_task_list: 0 | 1;
  milestones?: unknown;
  phases?: unknown;
  modified: string;
  non_billable?: boolean;
  notes_meta?: NotesMeta;
  people_ids: number[];
  people_rates?: Record<number, string | null>;
  project_manager?: number;
  project_name: string;
  project_template_id: number;
  start_date?: string;
  status: ProjectStatus;
  tags?: string[];
  task_metas?: unknown;
  team_people: unknown;
  /**
   * @deprecated Use `status`
   */
  tentative?: boolean;
  duration?: number;
};

export type ProjectOption = {
  label: string;
  value: string;
  client_name: string;
  selectedLabel: string;
  color: string;
};

export type ProjectOptions = {
  name: string;
  value: string;
  options: Array<ProjectOption>;
};

export type Offset = {
  duration?: number | null;
  parent_offset?: number | null;
};

type PhaseOffset = Offset & {
  milestones?: Record<number, Offset>;
};

export type ProjectOffsets = {
  duration?: number;
  phases?: Record<number, PhaseOffset>;
  milestones?: Record<number, Offset>;
};

type DateRange = {
  start_date: string;
  end_date: string;
};

type PhaseDateRange = DateRange & {
  milestones?: Record<string, DateRange>;
};

export type TemplateDates = DateRange & {
  phases?: Record<string, PhaseDateRange>;
  milestones?: Record<string, DateRange>;
};

export type ProjectTeamMemberData = {
  people_id: Person['people_id'] | null;
  hourly_rate?: number | string | null;
  phase_id?: Phase['phase_id'] | null;
  isAssignedToPhaseOnly?: boolean;
};

export type ProjectBudgetUsage = BudgetUsage<number | null> & {
  project_id: number;
};
