import mitt, { EventType, Handler } from 'mitt';

const emitter = mitt();
export function emitEvent<T>(event: EventType, payload: T) {
  emitter.emit(event, payload);
}

export function onEmitEvent(event: EventType, callback: Handler) {
  emitter.on(event, callback);
}
