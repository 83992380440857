import { forEach } from 'lodash';
import { createSelector } from 'reselect';

import { getDatesFromRange } from '@float/libs/dates';
import { moment } from '@float/libs/moment';
import { CustomHoliday, DateString, Timeoff } from '@float/types';

import { ReduxState } from '../reducers/lib/types';
import { getFullTimeoffsList } from './timeoffs';

const formatDate = (date: Moment) => moment(date).format('YYYY-MM-DD');

// Company holidays
const getCustomHolidays = (state: ReduxState) => state.holidays.holidays;

// Differs depending on employee's region
const getRegionalHolidays = createSelector([getFullTimeoffsList], (timeoffs) =>
  timeoffs.filter((timeoff) => Boolean(timeoff.region_holiday_id)),
);

// Helper to add holiday timeoff records to the payload.
const addHoliday = (
  dateKey: DateString,
  map: Record<DateString, (Timeoff | CustomHoliday)[]>,
  item: Timeoff | CustomHoliday,
) => {
  if (!map[dateKey]) {
    map[dateKey] = [];
  }
  map[dateKey].push(item);
};

export const getAllHolidays = createSelector(
  [getCustomHolidays, getRegionalHolidays],
  (customHolidays, regionalHolidays) => {
    const returnData = {};

    forEach(customHolidays, (holiday) => {
      if (!holiday.start_date && !holiday.date) return;

      // Adds the holiday for every date in the range if it is more than a single day
      getDatesFromRange(
        moment(holiday.start_date || holiday.date),
        moment(holiday.end_date || holiday.start_date || holiday.date),
        (date) => {
          if (!date) return;
          addHoliday(formatDate(date), returnData, holiday);
        },
      );
    });

    forEach(regionalHolidays, (timeoff) => {
      // @ts-expect-error checking for any potential legacy data which might not have start_date
      if (!timeoff.start_date && !timeoff.date) return;

      getDatesFromRange(
        // @ts-expect-error checking for any potential legacy data which might not have start_date
        moment(timeoff.start_date || timeoff.date),
        // @ts-expect-error checking for any potential legacy data which might not have start_date
        moment(timeoff.end_date || timeoff.start_date || timeoff.date),
        (date) => {
          if (!date) return;
          addHoliday(formatDate(date), returnData, timeoff);
        },
      );
    });

    return returnData;
  },
);
