export type GetToKnownYouOption = {
  data: string;
  default?: boolean;
  desc?: string;
  label: string;
};

export type GetToKnownYouDataType = Record<
  'teamSize' | 'supportEffort' | 'goals',
  GetToKnownYouOption[]
>;

export enum SupportEffort {
  Low = 'Low',
  High = 'High',
  No = 'No',
}

export enum Goals {
  Resourcing = 'Resourcing',
  Reporting = 'Reporting',
  ProjectScoping = 'ProjectScoping',
  TimeTracking = 'TimeTracking',
}

export type SupportEffortType = keyof typeof SupportEffort;
