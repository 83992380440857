import { Task } from '@float/types/task';

import { InsightsEntry } from '../types';
import { createInsightsEntry } from './createInsightsEntry';

/**
 * Updates the project allocation time using the given entity
 */
export function updateProjectInsights(
  result: Record<number, InsightsEntry & Record<number, InsightsEntry>>,
  entity: Task,
  personId: number,
) {
  const projectId = entity.project_id;
  if (!projectId) return;

  let byProject = result[projectId];
  if (!byProject) {
    byProject = result[projectId] = createInsightsEntry();
  }
  if (!byProject[personId]) {
    byProject[personId] = createInsightsEntry();
  }

  byProject.h = (byProject.h ?? 0) + entity.hours;
  byProject[personId].h = (byProject[personId].h ?? 0) + entity.hours;
}
