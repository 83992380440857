import React, { useEffect } from 'react';

import { dispatchViewLoad } from '@float/web/perfMonitoring/pageLoad';
import type { ScheduleDataFetcherResult } from '@float/common/serena/Data/useScheduleDataFetcherGeneric';
import type { CellsMap } from '@float/types/cells';

type Params = {
  visibleRangeRef: React.RefObject<
    { colStart: number; colStop: number } | undefined
  >;
  cells: CellsMap;
  fetcher: ScheduleDataFetcherResult;
};

export function useScheduleViewLoadedRum({
  visibleRangeRef,
  cells,
  fetcher,
}: Params) {
  useEffect(() => {
    const visibleRange = visibleRangeRef.current;

    if (
      visibleRange &&
      cells._allLoaded &&
      fetcher.isColumnFetched(visibleRange.colStart) &&
      fetcher.isColumnFetched(visibleRange.colStop)
    ) {
      dispatchViewLoad('Schedule');
    }
  }, [cells._allLoaded, fetcher, visibleRangeRef]);
}
