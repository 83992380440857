import React, { ReactNode } from 'react';
import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group';

import { useControllableState } from '@float/libs/hooks/useControllableState';

import { ToggleValue } from '../types';

type ToggleMultiGroupProps<V extends ToggleValue> = {
  value?: V[];
  defaultValue?: V[];
  onChange?: (value: V[]) => void;
  disabled?: boolean;
  readOnly?: boolean;
  children?: ReactNode;
  className?: string;
  'aria-labelledby'?: string;
};

export function ToggleMultiGroup<V extends ToggleValue>(
  props: ToggleMultiGroupProps<V>,
) {
  const [value, setValue] = useControllableState({
    prop: serializeArray(props.value),
    defaultProp: serializeArray(props.defaultValue),
    onChange(value) {
      if (props.onChange) {
        props.onChange(deserializeArray(value));
      }
    },
  });

  function handleValueChange(value: string[]) {
    if (props.readOnly) return;
    if (props.disabled) return;

    setValue(value);
  }

  return (
    <ToggleGroupPrimitive.Root
      type="multiple"
      className={props.className}
      value={value || []}
      onValueChange={handleValueChange}
      disabled={props.disabled}
      aria-labelledby={props['aria-labelledby']}
    >
      {props.children}
    </ToggleGroupPrimitive.Root>
  );
}
function serializeArray<V extends ToggleValue>(value: V[] | undefined) {
  if (value) return value.map((value) => JSON.stringify(value));

  return undefined;
}
function deserializeArray<V extends ToggleValue>(value: string[]): V[] {
  return value.map((value) => JSON.parse(value));
}
