import React, { ReactNode } from 'react';

import { logger } from '@float/libs/logger';
import { lightThemeClass } from '@float/theme';
import { RouteUnsupportedError } from '@float/web/lib/routing';
import { PageNotFound } from '@float/web/views/PageNotFound';

import { FetchFailedError } from '../Portal/fetchFailed';
import { AppErrorData } from './components/AppErrorData';
import { AppErrorGeneric } from './components/AppErrorGeneric';

type State =
  | { hasError: false }
  | {
      type: 'failed-fetch';
      error: FetchFailedError;
      hasError: true;
      retrying?: boolean;
    }
  | {
      type: 'route-unsupported';
      hasError: true;
    }
  | {
      type: 'generic';
      hasError: true;
    };

export class AppErrorBoundary extends React.Component<{
  children?: ReactNode;
}> {
  state: State = { hasError: false };

  componentDidCatch(error: unknown, info: unknown) {
    if (error instanceof FetchFailedError) {
      // Let's track what data is failing to load
      logger.error(
        'A FetchFailedError was encountered at the top level error boundary',
        error,
        {
          context: {
            info,
            failedData: error.failedData,
          },
        },
      );
    } else if (error instanceof RouteUnsupportedError) {
      return; //  The `PageNotFound` component handles logging the error
    } else {
      logger.error(
        'An error was encountered at the top level error boundary',
        error,
        {
          context: {
            info,
          },
        },
      );
    }
  }

  static getDerivedStateFromError(error: unknown) {
    if (error instanceof FetchFailedError) {
      return { type: 'failed-fetch', error, hasError: true };
    }

    if (error instanceof RouteUnsupportedError) {
      return { type: 'route-unsupported', error, hasError: true };
    }

    return { type: 'generic', hasError: true };
  }

  retryFetch = async () => {
    if (this.state.hasError && this.state.type === 'failed-fetch') {
      this.setState({ retrying: true });

      await this.state.error.retry();

      this.setState({ hasError: false, retrying: false });
    }
  };

  render() {
    if (this.state.hasError) {
      if (this.state.type === 'failed-fetch') {
        return (
          <div className={lightThemeClass}>
            <AppErrorData
              onClickTryAgain={this.retryFetch}
              isRetrying={this.state.retrying}
            />
          </div>
        );
      }

      if (this.state.type === 'route-unsupported') {
        return <PageNotFound />;
      }

      return (
        <div className={lightThemeClass}>
          <AppErrorGeneric />
        </div>
      );
    }

    return this.props.children;
  }
}
