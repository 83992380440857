export const enum TimeFormatPreference {
  Unset = 0,
  TwelveHourFormat = 1,
  TwentyFourHourFormat = 2,
}

export type UserTimeFormatPreference = {
  value: TimeFormatPreference;
  label: string;
};
