import React from 'react';
import { compose } from 'redux';

import { EH, withConfirm, withSnackbar } from '@float/ui/deprecated';

import linkState, {
  isValid,
  linkAt,
  validateLinks,
} from '../../form/linkState';
import { StyledRow } from '../styles';
import Form from './Form';

class AccountOwner extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultFieldState();

    this.onSubmit = this.onSubmit.bind(this);
    this.onClickConfirm = this.onClickConfirm.bind(this);
    this.mapAccountOwnerViewState = this.mapAccountOwnerViewState.bind(this);

    linkState(this, 'account_owner', this.mapAccountOwnerViewState);
  }

  onSubmit(e) {
    e.preventDefault();

    const { account_owner } = this.state;

    if (!validateLinks(this)) {
      return;
    }

    this.props.confirm({
      title: 'Transfer ownership',
      message: `Account ownership will transfer to ${account_owner.label}.
          You will be updated to Admin status and returned to the schedule.`,
      onConfirm: this.onClickConfirm,
      onCancel: this.onClickClosePopup,
    });
  }

  onClickConfirm() {
    const { onSubmit } = this.props;
    const { account_owner } = this.state;

    this.setState({ isLoading: true });

    // successful submission destroys this view as it's only visible by owners
    onSubmit(account_owner.value)
      .then(() => {
        this.props.showSnackbar('Account Owner changed.');
        window.location.href = '/';
      })
      .catch(() => this.setState({ isLoading: false }));
  }

  mapAccountOwnerViewState(x) {
    return this.props.accounts.find((account) => account.value == x);
  }

  getDefaultFieldState() {
    const { account_owner } = this.props;
    return {
      account_owner,
    };
  }

  localValuesUnchanged() {
    const { account_owner } = this.props;
    return this.state.account_owner.value == account_owner.value;
  }

  render() {
    const { accounts } = this.props;
    const { isLoading } = this.state;

    return (
      <EH.Cards.Card>
        <StyledRow>
          <h5>Account Owner</h5>
        </StyledRow>

        <StyledRow>
          <p>
            The Account Owner is the only person who can manage the settings on
            this page, access the API key, and manage the account integrations.
            Only the Account Owner and guests with Billing access rights can
            upgrade/downgrade your plan.
          </p>
        </StyledRow>

        <StyledRow>
          <Form
            accounts={accounts}
            onSubmit={this.onSubmit}
            fieldLink={linkAt(this, 'account_owner')}
            disabled={
              this.localValuesUnchanged() || !isValid(this) || isLoading
            }
          />
        </StyledRow>
      </EH.Cards.Card>
    );
  }
}

export default compose(withConfirm, withSnackbar)(AccountOwner);
