import {
  easeInOutBounce,
  easeInOutCurve,
  easeInOutQuad,
  easeInQuad,
  easeOutBounce,
  easeOutQuad,
} from '@float/libs/styles/easing';

const Settings = {
  Duration: {
    Instant: '0.05s',
    Short: '0.15s',
    Slow: '0.2s',
    Normal: '0.3s',
    Long: '1s',
  },

  Easing: {
    DefaultIn: easeInQuad,
    DefaultOut: easeOutQuad,
    DefaultInOut: easeInOutQuad,

    InOutCurve: easeInOutCurve,

    InOutBounce: easeInOutBounce,
    OutBounce: easeOutBounce,
  },
};

export { Settings };
