/* eslint-disable react-compiler/react-compiler */
/** Pretty dynamic component, the compiler won't do much anyway */

import React, { isValidElement, useCallback, useRef } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { TransitionManagerProps, TransitionNode, TransitionRef } from './types';

export * from './types';
export { Transition } from './Transition';

export const TransitionManager = (props: TransitionManagerProps) => {
  'use no memo';
  const { keyID, children, onTransition } = props;

  const transition = useRef<
    Record<
      string | number,
      {
        animationCallback?: TransitionNode['callback'];
        isAppearing?: boolean;
      }
    >
  >({});
  const transitionElementRefs = useRef<Record<string | number, TransitionRef>>(
    {},
  );

  const setRef = useCallback((ref: TransitionRef) => {
    const id = ref && ref.transitionId;

    if (id) {
      transitionElementRefs.current[id] = ref;

      // init transition ref
      if (!transition.current[id]) transition.current[id] = {};
    }
  }, []);

  const getTransition = useCallback((node: HTMLElement) => {
    const { transitionId } = node.dataset;

    if (transitionId) {
      const { animationCallback: callback, isAppearing } =
        transition.current[transitionId];

      const component = transitionElementRefs.current[transitionId];

      return { component, callback, isAppearing };
    }

    return {};
  }, []);

  // Animation / transitions

  const addEndListener = useCallback(
    (node: HTMLElement, done: () => void) => {
      const transition = getTransition(node) as TransitionNode;

      if (onTransition) {
        onTransition(transition, done);
      } else {
        done();
      }
    },
    [onTransition, getTransition],
  );

  const onEnter = useCallback((node: HTMLElement, isAppearing: boolean) => {
    if (!node) return;

    const { transitionId } = node.dataset;

    if (transitionId) {
      transition.current[transitionId].animationCallback = 'onEnter';
      transition.current[transitionId].isAppearing = isAppearing;
    }
  }, []);

  const onEntered = useCallback(() => {}, []);

  const onExit = useCallback((node: HTMLElement) => {
    if (!node) return;

    const { transitionId } = node.dataset;

    if (transitionId) {
      transition.current[transitionId].animationCallback = 'onExit';
      delete transition.current[transitionId].isAppearing;
    }
  }, []);

  const onExited = useCallback(() => {
    // console.log('onExited', node);
  }, []);

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={keyID}
        addEndListener={addEndListener}
        onEnter={onEnter}
        onEntered={onEntered}
        onExit={onExit}
        onExited={onExited}
        appear
        unmountOnExit
      >
        <>
          {React.Children.map(children, (child) => {
            if (child && isValidElement(child)) {
              return React.cloneElement(child as JSX.Element, {
                ref: setRef,
              });
            }

            return child;
          })}
        </>
      </CSSTransition>
    </SwitchTransition>
  );
};
