import { FETCH_ASANA_CO_INTS_SUCCESS } from '@float/web/integrations/actions/asana.constants';
import { FETCH_JIRA_CO_INTS_SUCCESS } from '@float/web/integrations/actions/jira.constants';
import { FETCH_TEAMWORK_CO_INTS_SUCCESS } from '@float/web/integrations/actions/teamwork.constants';
import { FETCH_TRELLO_CO_INTS_SUCCESS } from '@float/web/integrations/actions/trello.constants';

export const InboundCalendarVersion = {
  V1: 1,
  V2: 2,
};

export const integrationTitles = {
  jira: 'Jira',
  teamwork: 'Teamwork',
  asana: 'Asana',
  trello: 'Trello',
};

export const loadedActionTypes = {
  jira: FETCH_JIRA_CO_INTS_SUCCESS,
  teamwork: FETCH_TEAMWORK_CO_INTS_SUCCESS,
  asana: FETCH_ASANA_CO_INTS_SUCCESS,
  trello: FETCH_TRELLO_CO_INTS_SUCCESS,
};
