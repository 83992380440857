import { createSelector } from 'reselect';

import { accumulateDecimalHours } from '@float/common/lib/timer/accumulateDecimalHours';
import { subtractDecimalHours } from '@float/common/lib/timer/subtractDecimalHours';
import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import {
  getUser,
  selectDatesManager,
} from '@float/common/selectors/currentUser';
import { getSearchFilteredActivePeople } from '@float/common/selectors/people';
import { getSearchFilteredActiveProjectsIds } from '@float/common/selectors/projects/projectsFiltered';
import { getWorkHours } from '@float/common/selectors/schedule/getWorkHours';
import { selectIsWorkDayGetter } from '@float/common/selectors/schedule/isWorkDay';
import { forEachEntityDate } from '@float/libs/datesRepeated/forEachEntityDate';
import { PersonType } from '@float/types/person';
import { TaskStatusEnum } from '@float/types/taskStatus';

import { createInsightsEntry } from '../helpers/createInsightsEntry';
import { updateProjectInsights } from '../helpers/updateProjectInsights';
import { InsightsEntry, TimeRangeInsightsParams } from '../types';
import { getTimeRangeTasksByPerson } from './getTimeRangeTasksByPerson';
import { getTimeRangeTimeoffsByPerson } from './getTimeRangeTimeoffsByPerson';

export const getTimeRangeInsights = createSelector(
  [
    (_: ReduxStateStrict, params: TimeRangeInsightsParams) => params,
    selectDatesManager,
    getSearchFilteredActivePeople,
    getSearchFilteredActiveProjectsIds,
    getUser,
    getTimeRangeTasksByPerson,
    getTimeRangeTimeoffsByPerson,
    selectIsWorkDayGetter,
  ],
  // Using a named function to have it visible in the performance profiling traces
  function getTimeRangeInsights(
    params,
    dates,
    people,
    projectIds,
    user,
    tasks,
    timeoffs,
    getIsWorkDay,
  ) {
    const { startDate, endDate, type } = params;
    const isProjectPlanView = type === 'project';

    const total = createInsightsEntry();
    const byPerson: Record<number, InsightsEntry> = {};
    const byProject: Record<
      number,
      InsightsEntry & Record<number, InsightsEntry>
    > = {};

    for (const person of people) {
      const isPlaceholder = person.people_type_id === PersonType.PLACEHOLDER;

      const workDaysInRange = new Set<string>();

      const byDate: Record<string, InsightsEntry> = {};
      const data = createInsightsEntry();

      if (isPlaceholder && type === 'person') {
        byPerson[person.people_id] = data;
        continue;
      }

      const startDateNum = dates.toNum(startDate);
      const endDateNum = dates.toNum(endDate);

      for (let i = startDateNum; i <= endDateNum; i += 1) {
        const date = dates.fromNum(i);

        const hours = getWorkHours(dates, user, person, date);

        byDate[date] = createInsightsEntry();
        byDate[date].capacity = 0;

        if (getIsWorkDay(person, date)) {
          byDate[date].capacity = hours;
          data.capacity = accumulateDecimalHours(data.capacity, hours);
          data.totalCapacity = accumulateDecimalHours(
            data.totalCapacity,
            hours,
          );
          workDaysInRange.add(date);
        }
      }

      for (const entity of timeoffs.get(person.people_id) || []) {
        forEachEntityDate(dates, entity, (dateNum) => {
          const date = dates.fromNum(dateNum);

          if (!workDaysInRange.has(date)) return;

          const isFullDayOff = entity.full_day || entity.region_holiday_id;

          if (isFullDayOff) return;

          // This is a partial timeoff, so we use the entity hours as hours reference
          const hours = entity.hours;

          // subtracting from the remaining person capacity
          data.capacity = subtractDecimalHours(data.capacity, hours || 0);

          byDate[date].capacity = subtractDecimalHours(
            byDate[date].capacity,
            hours || 0,
          );

          // subtracting from the person total capacity since time off days aren't calculated as part of
          // person total capacity in the time range
          data.totalCapacity = subtractDecimalHours(
            data.totalCapacity,
            hours || 0,
          );
        });
      }

      for (const entity of tasks.get(person.people_id) || []) {
        forEachEntityDate(dates, entity, (dateNum) => {
          // Draft tasks are only visible in the project plan view
          if (!isProjectPlanView && entity.status === TaskStatusEnum.Draft)
            return;

          const date = dates.fromNum(dateNum);

          // Already marked an off day.
          if (!workDaysInRange.has(date)) return;

          // On project plan, consider the allocation only if the project matches the current filters
          if (isProjectPlanView && !projectIds.has(entity.project_id)) {
            return;
          }

          data.h = accumulateDecimalHours(data.h, entity.hours);
          data.capacity = subtractDecimalHours(data.capacity, entity.hours);
          byDate[date].capacity = subtractDecimalHours(
            byDate[date].capacity,
            entity.hours,
          );

          // If we are calculating the project insights update the byProject data
          if (isProjectPlanView) {
            updateProjectInsights(byProject, entity, person.people_id);
          }
        });
      }

      // Calculate overtime values
      for (let i = startDateNum; i <= endDateNum; i += 1) {
        const date = dates.fromNum(i);

        if (!workDaysInRange.has(date)) continue;

        // Every day with a negative capacity should be considered overtime
        if (byDate[date].capacity < 0) {
          data.overtime = accumulateDecimalHours(
            data.overtime,
            byDate[date].capacity,
          );
          data.capacity = subtractDecimalHours(
            data.capacity,
            byDate[date].capacity,
          );
          byDate[date].capacity = 0;
        }
      }

      byPerson[person.people_id] = data;
      total.h = accumulateDecimalHours(total.h, data.h);
      total.logged = accumulateDecimalHours(total.logged, data.logged);
      total.capacity = accumulateDecimalHours(total.capacity, data.capacity);
      total.totalCapacity = accumulateDecimalHours(
        total.totalCapacity,
        data.totalCapacity,
      );
      total.overtime = accumulateDecimalHours(total.overtime, data.overtime);
    }

    return {
      byPerson,
      byProject,
      total,
    };
  },
);
