import { Rights } from '@float/common/lib/acl';
import { CurrentUser, Person, Project, Selection } from '@float/types';

export type HandlePersonCellClickParams = {
  selection: Selection;
  person: Person;
  user: CurrentUser;
  project?: Project;
  nonScheduleActions: {
    showAddTaskModal: (
      person: Person,
      params: {
        start_date: string;
        end_date: string;
        project?: Project;
      },
    ) => void;
    showAddTimeoffModal: (
      person: Person,
      params: {
        start_date: string;
        end_date: string;
      },
    ) => void;
  };
};

export function handlePersonCellClick(params: HandlePersonCellClickParams) {
  const { selection, person, project, user, nonScheduleActions } = params;

  const canCreateTask = Rights.canCreateTaskForPeople(user, [person]);
  const canCreateTimeoff = Rights.canCreateTimeoffForPeople(user, [person]);

  if (canCreateTask) {
    nonScheduleActions.showAddTaskModal(person, {
      start_date: selection.start_date,
      end_date: selection.end_date,
      project,
    });
  } else if (canCreateTimeoff) {
    nonScheduleActions.showAddTimeoffModal(person, {
      start_date: selection.start_date,
      end_date: selection.end_date,
    });
  }
}
