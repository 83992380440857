import {
  searchSelectors,
  SearchWorkerSelectors,
  SearchWorkerSelectorsKeys,
  SelectorParameters,
} from './searchSelectors';
import { SearchStore } from './searchStore';

export function subscribeToSelector<S extends SearchWorkerSelectorsKeys>(
  store: SearchStore,
  selectorKey: S,
  callback: (value: ReturnType<SearchWorkerSelectors[S]>) => void,
  args: SelectorParameters<SearchWorkerSelectors[S]>,
) {
  let prevResult: unknown = null;

  const handler = () => {
    const state = store.getState();

    // @ts-expect-error Typescript doesn't like the mix of Parameters and spread argument
    // because it requires tuples when doing spread
    const result = searchSelectors[selectorKey](state, ...args) as ReturnType<
      SearchWorkerSelectors[S]
    >;

    if (result !== prevResult) {
      callback(result);
    }

    prevResult = result;
  };

  const unsubscribe = store.subscribe(handler);

  handler();

  return unsubscribe;
}
