import { FRIENDLY_NAMES, TYPE_TO_CATEGORY } from '@float/common/search/helpers';

export default function getSearchFiltersString(searchFilters) {
  if (!searchFilters.length) return '';

  const friendly = searchFilters.map((f) => {
    const cat = FRIENDLY_NAMES[TYPE_TO_CATEGORY[f.type]];
    return `${cat}: ${f.operator || ''}${f.val}`;
  });

  return `Filters: ${friendly.join(', ')}`;
}
