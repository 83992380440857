import React from 'react';

export default function () {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.6463 5.3335H21.0203C11.1923 5.3335 5.33325 11.1925 5.33325 21.0205V43.6465C5.33325 53.4745 11.1923 59.3335 21.0203 59.3335H43.6463C53.4743 59.3335 59.3333 53.4745 59.3333 43.6465V21.0205C59.3333 11.1925 53.4743 5.3335 43.6463 5.3335ZM26.8523 40.1635L20.7773 46.2385C20.3723 46.6435 19.8593 46.8325 19.3463 46.8325C18.8333 46.8325 18.2933 46.6435 17.9153 46.2385L15.8903 44.2135C15.0803 43.4305 15.0803 42.1345 15.8903 41.3515C16.6733 40.5685 17.9423 40.5685 18.7523 41.3515L19.3463 41.9455L23.9902 37.3015C24.7733 36.5185 26.0423 36.5185 26.8523 37.3015C27.6353 38.0845 27.6353 39.3805 26.8523 40.1635ZM26.8523 21.2635L20.7773 27.3385C20.3723 27.7435 19.8593 27.9325 19.3463 27.9325C18.8333 27.9325 18.2933 27.7435 17.9153 27.3385L15.8903 25.3135C15.0803 24.5305 15.0803 23.2345 15.8903 22.4515C16.6733 21.6685 17.9423 21.6685 18.7523 22.4515L19.3463 23.0455L23.9902 18.4015C24.7733 17.6185 26.0423 17.6185 26.8523 18.4015C27.6353 19.1845 27.6353 20.4805 26.8523 21.2635ZM47.3452 44.8075H33.1703C32.0633 44.8075 31.1453 43.8895 31.1453 42.7825C31.1453 41.6755 32.0633 40.7575 33.1703 40.7575H47.3452C48.4792 40.7575 49.3702 41.6755 49.3702 42.7825C49.3702 43.8895 48.4792 44.8075 47.3452 44.8075ZM47.3452 25.9075H33.1703C32.0633 25.9075 31.1453 24.9895 31.1453 23.8825C31.1453 22.7755 32.0633 21.8575 33.1703 21.8575H47.3452C48.4792 21.8575 49.3702 22.7755 49.3702 23.8825C49.3702 24.9895 48.4792 25.9075 47.3452 25.9075Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="8.03333"
          y1="4.99583"
          x2="51.5708"
          y2="62.7083"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7C71EC" />
          <stop offset="1" stopColor="#C17DCE" />
        </linearGradient>
      </defs>
    </svg>
  );
}
