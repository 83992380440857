import React, {
  forwardRef,
  RefObject,
  useImperativeHandle,
  useRef,
} from 'react';
import cn from 'classnames';

import { noop } from '@float/libs/utils/noop';
import { TimeFormat } from '@float/types';
import { InputTime } from '@float/ui/deprecated/InputTime';
import { InputTimeChangeEvent } from '@float/ui/deprecated/InputTime/types';

import * as styles from './styles.css';

type TimerEntryRowInputsProps = {
  className?: string;
  isEditing?: boolean;
  onEndTimeChange?: InputTimeChangeEvent;
  onLoggedTimeChange?: InputTimeChangeEvent;
  onStartTimeChange?: InputTimeChangeEvent;
  timeFormat: TimeFormat;
  timerEnd: string;
  timerLogged: string;
  timerStart: string;
};

export interface ITimerEntryRowInputs {
  inputLoggedRef: RefObject<HTMLInputElement>;
}

const TimerEntryRowInputs = forwardRef<
  ITimerEntryRowInputs,
  TimerEntryRowInputsProps
>((props, ref) => {
  const {
    className,
    isEditing,
    onEndTimeChange = noop,
    onLoggedTimeChange = noop,
    onStartTimeChange = noop,
    timeFormat,
    timerEnd,
    timerLogged,
    timerStart,
  } = props;

  const disabled = !isEditing;

  const inputLoggedRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    inputLoggedRef,
  }));

  return (
    <div className={cn(className, styles.container)}>
      <InputTime
        appearance="ghost"
        className={cn(styles.inputLoggedTime)}
        disabled={disabled}
        fitToContent={true}
        loop={false}
        onChange={onLoggedTimeChange}
        ref={inputLoggedRef}
        size="small"
        timeFormat="24"
        timeType="clock"
        value={timerLogged}
      />

      <p className={styles.label}>logged</p>

      <InputTime
        appearance="ghost"
        className={cn(styles.inputTime)}
        disabled={disabled}
        fitToContent={true}
        loop={false}
        onChange={onStartTimeChange}
        size="small"
        timeFormat={timeFormat}
        timeType="clock"
        value={timerStart}
      />

      <span className={styles.divider}>-</span>

      <InputTime
        appearance="ghost"
        className={cn(styles.inputTime)}
        disabled={disabled}
        fitToContent={true}
        loop={false}
        onChange={onEndTimeChange}
        size="small"
        timeFormat={timeFormat}
        timeType="clock"
        value={timerEnd}
      />
    </div>
  );
});

export { TimerEntryRowInputs };
