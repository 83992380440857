import React, { useLayoutEffect, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { useGesture } from 'react-use-gesture';
import useMount from 'react-use/esm/useMount';

import { trackEvent } from '@float/common/lib/gtm';

import { DragItemValue, ScheduleActions } from '../types';
import { handleIsItemDraggableError } from './helpers/handleIsItemDraggableError';

import * as styles from './DragItem.css';

const ACCIDENTAL_DRAG_DISTANCE = 1;

function DragItem({
  actions,
  dragItem,
}: {
  actions: ScheduleActions;
  dragItem: DragItemValue;
}) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const props = useSpring({
    opacity: isDragging && !dragItem.invisible ? 0.74 : 0,
    shadow: 8,
    from: {
      opacity: isDragging && !dragItem.invisible ? 1 : 0,
      shadow: 1,
    },
    config: {
      duration: 120,
    },
    reset: isDragging,
  });

  // @ts-expect-error isSidebarItem isn't on every item type
  const { isSidebarItem } = dragItem.item || {};

  const bind = useGesture({
    onDrag: ({ delta, distance, xy, event, dragging }) => {
      if (dragItem.type === 'person-card') {
        if (distance > ACCIDENTAL_DRAG_DISTANCE && !isDragging) {
          actions.onPersonCardDragStart(
            dragItem.row,
            dragItem.rowGroupTop,
            dragItem.rowHeight,
          );
          setIsDragging(true);
        }

        if (distance > ACCIDENTAL_DRAG_DISTANCE) {
          actions.onPersonCardDrag(delta);
        }
        return;
      }

      if (distance === 0 && !isDragging) {
        actions.onItemMouseDown(dragItem.item);
      }

      if (distance > ACCIDENTAL_DRAG_DISTANCE) {
        if (!actions.isItemEditable(dragItem.item)) {
          return;
        }

        if (dragItem.onItemDragChange) dragItem.onItemDragChange(dragging);

        const dragError = handleIsItemDraggableError(
          actions.isItemDraggable(dragItem.item),
        );

        if (!dragError.isDraggable) {
          if (dragItem.onItemDragError && dragging) {
            dragItem.onItemDragError(dragError.message);
          }

          return;
        }
      }

      if (distance > ACCIDENTAL_DRAG_DISTANCE && !isDragging) {
        if (event?.shiftKey) {
          trackEvent('Hotkey', {
            shortcut: 'Shift+Drag',
          });
        }

        const dragStarted = actions.onItemDragStart({
          item: dragItem.item,
          shiftKey: event?.shiftKey,
        });

        if (!dragStarted) return;

        setIsDragging(true);
      }

      if (distance > ACCIDENTAL_DRAG_DISTANCE) {
        const [x, y] = delta;
        const deltaY = dragItem.item?.type === 'timeRange' ? 24 : y;

        if (wrapperRef.current) {
          wrapperRef.current.style.transform = `translate3d(${x}px,${deltaY}px, 0)`;
        }

        actions.onItemDrag(xy[0]);
      }
    },

    // @ts-expect-error The types are saying we shouldn't get event and distance
    // but checking the runtime we have those
    onDragEnd: ({ event, distance }) => {
      if (!isDragging && distance <= ACCIDENTAL_DRAG_DISTANCE) {
        if (dragItem.type === 'person-card') {
          actions.onPersonCardClick(dragItem.person);
        } else if (!isSidebarItem) {
          if (dragItem.shiftKey) {
            trackEvent('Hotkey', {
              shortcut: 'Shift+Click',
            });
            actions.toggleItemSelected(dragItem.item);
          } else {
            if (dragItem.onItemClick) dragItem.onItemClick();
            actions.onItemClick(dragItem.item, event);
          }
        }
      } else if (isDragging) {
        setIsDragging(false);
        if (dragItem.type === 'person-card') {
          actions.onPersonCardDragStop();
        } else if (isSidebarItem) {
          actions.onSidebarItemDragStop();
        } else {
          actions.onItemDragStop();
        }
      }

      actions.setDragItem(null);
    },
  });

  useMount(() => {
    if (wrapperRef.current) {
      wrapperRef.current.style.transform = `translate3d(0, 0, 0)`;
    }
  });

  useLayoutEffect(() => {
    if (!dragItem.element) return;
    if (!wrapperRef.current) return;

    const { clientX, clientY } = dragItem;

    // Manually trigger a mousedown event on this newly created element,
    // which effectively simulates the user having clicked on this to begin
    // with. All subsequent actions use this DragItem. We do this to render a
    // visible DragItem even if the user has scrolled the original parent cell
    // of that item outside of the virtualized window bounds.
    const mockEvent = new MouseEvent('mousedown', {
      bubbles: true,
      clientX,
      clientY,
    });
    wrapperRef.current.dispatchEvent(mockEvent);
  }, [dragItem]);

  const { clientX, clientY, offsetX, offsetY, width, height } = dragItem;

  return (
    <animated.div
      ref={wrapperRef}
      className={styles.dragItemWrapper}
      {...bind()}
      style={{
        position: 'fixed',
        boxShadow: props.shadow.interpolate(
          (s) => `rgba(0, 0, 0, 0.18) 0px ${s}px ${2 * (s as number)}px 0px`,
        ),
        opacity: props.opacity,
        left: offsetX,
        top: offsetY,
        width,
        height,
        transformOrigin: `${clientX - offsetX}px ${clientY - offsetY}px`,
        zIndex: 1000,
      }}
    >
      {dragItem.element}
    </animated.div>
  );
}

export default React.memo(DragItem);
