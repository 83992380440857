import React, { forwardRef, useRef } from 'react';
import useUpdateEffect from 'react-use/esm/useUpdateEffect';

import { useCombinedRefs } from '@float/libs/hooks/useCombinedRefs';
import { noop } from '@float/libs/utils/noop';

import { isAMPMTime } from './helpers/isAMPMTime';
import { useInputTime } from './hooks/useInputTime';
import {
  StyledFieldLabel,
  StyledInput,
  StyledInputTime,
  StyledSpan,
} from './styles';
import { InputTimeProps } from './types';

const InputTime = forwardRef<HTMLInputElement, InputTimeProps>((props, ref) => {
  const {
    appearance,
    appearanceDisabled,
    autoFocus,
    className,
    disabled,
    fitToContent,
    label,
    loop = true,
    max,
    min,
    onUpdate,
    onChange,
    onSubmit = noop,
    readOnly,
    size,
    style = {},
    timeFormat = '12',
    timeType = 'duration',
    value = '',
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const sizeRef = useRef<HTMLSpanElement>(null);

  const combinedRef = useCombinedRefs([ref, inputRef]);

  const { handleBlur, handleChange, handleKeyDown, handleKeyUp, currentValue } =
    useInputTime(
      value,
      timeFormat,
      timeType,
      onUpdate,
      onChange,
      onSubmit,
      loop,
      min,
      max,
    );

  useUpdateEffect(() => {
    handleBlur();
  }, [timeFormat, timeType]);

  return (
    <StyledInputTime
      appearance={appearance}
      appearanceDisabled={appearanceDisabled}
      className={className}
      fitToContent={fitToContent}
      size={size}
      style={style}
    >
      {label && <StyledFieldLabel as="span">{label}</StyledFieldLabel>}

      <StyledSpan ref={sizeRef} aria-hidden="true">
        {isAMPMTime(timeType, timeFormat) ? '00:00am' : '00:00'}
      </StyledSpan>

      <StyledInput
        autoFocus={autoFocus}
        disabled={disabled || readOnly}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        readOnly={readOnly}
        ref={combinedRef}
        type="text"
        value={currentValue}
        aria-label={label}
      />
    </StyledInputTime>
  );
});

export { InputTime };
