import { getProjectById } from '@float/common/selectors/projects';
import { AppDispatchStrict, AppStoreStrict } from '@float/common/store';
import {
  ProjectPhaseRecord,
  ProjectStatus,
  ProjectTeamMemberData,
} from '@float/types';

import { phases as PhasesAPI } from '../../api3/phases';
import { PhaseApiPayload, PHASES_UPDATED } from './phases';

type ProjectPhaseRecordWithProjectId = ProjectPhaseRecord & {
  project_id?: number;
};

export function mapPhaseRecordToPhasePayload(
  data: Partial<ProjectPhaseRecordWithProjectId>,
) {
  const payload: Partial<PhaseApiPayload> = {};

  for (const key of Object.keys(data) as Array<
    keyof ProjectPhaseRecordWithProjectId
  >) {
    switch (key) {
      case 'active':
        payload[key] = data[key] ? 1 : 0;
        break;
      case 'non_billable':
        payload[key] = data[key] ? 1 : 0;
        break;
      case 'phase_name':
        payload.name = data[key];
        break;
      case 'temp_id':
        break;
      // These are handled separately to make Typescript happy
      case 'budget_total':
        payload[key] = data[key];
        break;
      case 'color':
      case 'end_date':
      case 'start_date':
        payload[key] = data[key];
        break;
      case 'project_id':
      case 'phase_id':
        payload[key] = data[key];
        break;
    }
  }

  return payload;
}

export function storePhaseRecord(
  phaseRecord: Partial<ProjectPhaseRecordWithProjectId>,
  projectTeam?: ProjectTeamMemberData[],
) {
  return async (
    dispatch: AppDispatchStrict,
    getState: AppStoreStrict['getState'],
  ) => {
    const id = phaseRecord.phase_id;
    const data = mapPhaseRecordToPhasePayload(phaseRecord);

    if (!id) {
      if (projectTeam) {
        // Copying the Project Team on the Phase team
        // on phase creation
        const phaseTeam: ProjectTeamMemberData[] = [];

        for (const member of projectTeam) {
          if (member.people_id) {
            phaseTeam.push({
              people_id: member.people_id,
              hourly_rate: member.hourly_rate,
            });
          }
        }

        data.phase_team = phaseTeam;
      }

      const project = getProjectById(getState(), phaseRecord.project_id);

      if (project) {
        data.non_billable = project.non_billable ? 1 : 0;
        data.status = project.status;
        data.tentative = project.status === ProjectStatus.Tentative ? 1 : 0;
      }

      const response = await PhasesAPI.createPhase({
        data,
        query: {
          expand: 'phase_team',
        },
      });

      dispatch({ type: PHASES_UPDATED, phases: [response] });

      return response;
    }

    const response = await PhasesAPI.updatePhase({
      id,
      data,
      query: {
        expand: 'phase_team',
      },
    });

    dispatch({
      type: PHASES_UPDATED,
      phases: [response],
      prevPhases: [getState().phases.phases[id]],
    });

    return response;
  };
}
