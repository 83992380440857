import React from 'react';

export default function ({ size = '24', color = '#868D92' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      className="icon-attach"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M18.218 3.161a4.015 4.015 0 0 0-5.657 0L3.72 12c-2.139 2.14-2.139 5.639 0 7.778 2.14 2.14 5.64 2.14 7.779 0l8.132-8.132-1.414-1.414-8.132 8.132a3.485 3.485 0 0 1-4.95 0 3.485 3.485 0 0 1 0-4.95l8.839-8.839c.79-.79 2.039-.79 2.828 0 .79.79.79 2.04 0 2.829L9.38 14.828a.487.487 0 0 1-.707 0 .487.487 0 0 1 0-.707l6.717-6.717-1.414-1.414-6.718 6.717a2.515 2.515 0 0 0 0 3.536 2.515 2.515 0 0 0 3.536 0l7.425-7.425a4.015 4.015 0 0 0 0-5.657z"
      />
    </svg>
  );
}
