export const PROJECT_ROW_ENTITY_HEIGHT_PX = 28;
export const COMPACT_PROJECT_ROW_ENTITY_HEIGHT_PX = 23;

type PositionCalcProps = {
  hourHeight: number;
  margin?: number;
  y?: number;
};

const COMPACT_HOUR_HEIGHT = 8;

export function getIsCompactDensity(hourHeight: number) {
  return hourHeight === COMPACT_HOUR_HEIGHT;
}

export function getProjectRowEntityTop(props: PositionCalcProps) {
  const { hourHeight, y = 0 } = props;

  const entityHeight = getIsCompactDensity(hourHeight)
    ? COMPACT_PROJECT_ROW_ENTITY_HEIGHT_PX
    : PROJECT_ROW_ENTITY_HEIGHT_PX;

  const margin = entityHeight + 2;
  return y * entityHeight + margin;
}

export function getProjectRowEntityHeight(props: PositionCalcProps) {
  const { hourHeight, margin = 0 } = props;

  const entityHeight = getIsCompactDensity(hourHeight)
    ? COMPACT_PROJECT_ROW_ENTITY_HEIGHT_PX
    : PROJECT_ROW_ENTITY_HEIGHT_PX;

  return entityHeight - margin;
}

// @test-export
export type GetProjectRowHeightProps = {
  hourHeight: number;
  maxCellSize: number;
  minRowSize: number;
};

export function getProjectRowHeight(props: GetProjectRowHeightProps) {
  const { hourHeight, maxCellSize, minRowSize } = props;
  const itemHeight = getProjectRowEntityHeight({ hourHeight });

  // If project row has more than 2 rows of milestones/phases,
  // add extra buffer to accomodate the height of project dates
  const buffer = maxCellSize > minRowSize ? itemHeight + 2 : 3;

  // If project row has less than 3 rows of milestones/phases,
  // set the minimum row height to 3 to accommodate project,
  // client, and budget info in project card
  return Math.max(maxCellSize, 3) * itemHeight + buffer;
}
