import React from 'react';
import { connect } from 'react-redux';

import VirtualSelect from '@float/ui/deprecated/VirtualSelect/VirtualSelect';
import { getClientOptions } from '@float/web/selectors';
import { addClient } from '@float/web/settingsV2/actions/clients';

class Client extends React.Component {
  getValue = async () => {
    const { value, addClient } = this.props;
    if (typeof value === 'string') {
      const res = await addClient({ name: value });
      return res.payload.client_id;
    }

    return value;
  };

  handleChange = ({ value }) => {
    this.props.onChange(value);
  };

  render() {
    return (
      <VirtualSelect
        label="Client"
        placeholder="No Client"
        visibleItems={6}
        creatable
        clearInputOnDropdownOpen={false}
        options={this.props.options}
        value={this.props.value}
        onChange={this.handleChange}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  options: getClientOptions(state),
});

const mapDispatchToProps = (dispatch) => ({
  addClient: (data) => dispatch(addClient(data)),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(Client);
