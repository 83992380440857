import React, { CSSProperties, ReactNode, useContext } from 'react';
import * as ToggleGroup from '@radix-ui/react-toggle-group';

import { ToggleGroupContext } from './components/ToggleGroupContext';
import { ToggleValue } from './types';

import * as styles from './styles.css';

export type ToggleButtonProps<V extends ToggleValue> = {
  children: ReactNode;
  value: V;
  readOnly?: boolean;
  style?: CSSProperties;
  subLabel?: ReactNode;
  disabled?: boolean;
  calloutId?: string;
};

export const ToggleButton = <V extends ToggleValue>({
  children,
  value,
  readOnly,
  style,
  subLabel,
  disabled,
  calloutId,
}: ToggleButtonProps<V>) => {
  const group = useContext(ToggleGroupContext);

  const isReadOnly = readOnly || group.readOnly;

  return (
    <ToggleGroup.Item
      style={style}
      className={styles.toggleButton({
        color: group.color,
        size: group.size,
        appearance: group.appearance,
      })}
      disabled={disabled}
      aria-readonly={isReadOnly}
      data-hide-unchecked={group.hideIfReadOnlyAndNotChecked}
      value={JSON.stringify(value)}
      data-callout-id={calloutId}
    >
      {children}
      {subLabel && <span className={styles.subLabel}>{subLabel}</span>}
    </ToggleGroup.Item>
  );
};
