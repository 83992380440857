import React from 'react';

import { Button, Modal, withConfirm } from '@float/ui/deprecated';

const DisconnectModal = ({ name, removeAndClose, onClose }) => {
  return (
    <Modal isOpen>
      <Modal.Header>
        <Modal.Title>Disconnect your {name} Integration</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        What would you like to do with the projects and people you imported from{' '}
        {name}?
        <Modal.Actions>
          <Button onClick={() => removeAndClose(false)}>
            Disconnect and keep
          </Button>
          <Button onClick={() => removeAndClose(true)}>
            Disconnect and archive
          </Button>
          <Button appearance="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal.Body>
    </Modal>
  );
};

export default withConfirm(DisconnectModal);
