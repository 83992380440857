import { WebAppState } from 'reducers/types';
import {
  addPanel,
  backToProjectPanel,
  closeCurrentPanel,
  hideCurrentPanelWithWarning,
  hideSidePanel,
  showPhaseSidePanel,
  showProjectSidePanel,
  showTemplateSidePanel,
} from 'sidePanel/actions';
import { getSidePanelState } from 'sidePanel/selectors';

import { useAppDispatchDecorator } from '@float/common/store';

import { ProjectEditData } from '../components/panelViews/Project/types';
import { useWebAppSelector } from '../lib/store';
import { PanelType } from './types';

export const useSidePanel = <T = ProjectEditData>(panelType?: PanelType) => {
  const { ...sidePanelState } = useWebAppSelector((state) =>
    getSidePanelState(state as WebAppState),
  );
  // Return the specific panelPayload for the panelType from openPanels
  const panel = sidePanelState.openPanels.find(
    (panel) => panel.panelType === panelType,
  );
  const getCurrentPanel = () => {
    const currentPanel = sidePanelState.openPanels.at(-1);
    return currentPanel;
  };
  return {
    ...sidePanelState,
    panelPayload: (panel?.panelPayload as T) ?? ({} as T),
    getCurrentPanel,
    addPanel: useAppDispatchDecorator(addPanel),
    hideSidePanel: useAppDispatchDecorator(hideSidePanel),
    hideCurrentPanelWithWarning: useAppDispatchDecorator(
      hideCurrentPanelWithWarning,
    ),
    showProjectSidePanel: useAppDispatchDecorator(showProjectSidePanel),
    showPhaseSidePanel: useAppDispatchDecorator(showPhaseSidePanel),
    showTemplateSidePanel: useAppDispatchDecorator(showTemplateSidePanel),
    closeCurrentPanel: useAppDispatchDecorator(closeCurrentPanel),
    backToProjectPanel: useAppDispatchDecorator(backToProjectPanel),
  };
};
