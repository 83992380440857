import React from 'react';

import { Border } from './Border';
import { useProjectRowDates } from './ProjectRowDates.hooks';

import * as styles from './styles.css';

export type ProjectRowDatesProps = {
  colIdx: number;
  dayWidth: number;
  endDate: string;
  startDate: string;
};

export function ProjectRowDates(props: ProjectRowDatesProps) {
  const {
    datesWithinViewPort,
    startDateText,
    endDateText,
    startPosition,
    endPosition,
    density,
  } = useProjectRowDates(props);

  if (!datesWithinViewPort) return null;

  const StartBorder = startPosition && (
    <Border data-testid="project-start-border" left={startPosition} />
  );
  const EndBorder = endPosition && (
    <Border data-testid="project-end-border" left={endPosition} />
  );

  const StartDate = startDateText && (
    <div
      className={styles.nonStickyDate({ density })}
      style={{ left: startPosition }}
    >
      {startDateText}
    </div>
  );

  const EndDate = endDateText && (
    <div
      className={styles.nonStickyDate({ density, position: 'end' })}
      style={{ left: endPosition }}
    >
      {endDateText}
    </div>
  );

  return (
    <>
      {StartBorder}
      {EndBorder}
      {StartDate}
      {EndDate}
    </>
  );
}
