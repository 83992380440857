import { TIME_FORMAT } from '@float/common/lib/time';
import { formatDecimalHoursAsClockTime } from '@float/common/lib/timer/formatDecimalHoursAsClockTime';

import { MainCellItem } from '../BoxElements/types';

export function formatHours(
  entity: MainCellItem['entity'],
  isNarrow?: boolean,
  timeFormat?: string,
) {
  if (!('hours' in entity)) return null;

  let { hours } = entity;
  // @entity.length
  const entityLength = 'length' in entity && entity.length ? entity.length : 1;

  const displayFormat = timeFormat || TIME_FORMAT.decimal;

  if (hours === undefined || hours === null) return hours;

  if ('isResizingHorizontally' in entity && entity.isResizingHorizontally) {
    hours = Math.round(hours * entityLength * 100) / 100;
  }

  if (displayFormat === TIME_FORMAT.decimal) {
    if (isNarrow) {
      hours = Math.floor(hours * 10) / 10;
    }

    const isBelow10AndNotFloat = hours < 10 && Math.floor(hours) === hours;
    const useHoursSuffix = !isNarrow || isBelow10AndNotFloat;
    const str = `${hours}${useHoursSuffix ? 'h' : ''}`;

    return str;
  }

  return formatDecimalHoursAsClockTime(hours);
}
