export class BitsHandler {
  bits = [];

  constructor(size, number) {
    this.bits = Array.from({ length: size }).fill('0'); // 32 bits

    (number || 0)
      .toString(2)
      .split('')
      .reverse()
      .forEach((bit, j) => {
        const bitIdx = this.bits.length - 1 - j;
        this.bits[bitIdx] = bit;
      });
  }

  static from(size, number) {
    return new BitsHandler(size, number);
  }

  static getCommonBitsHandler(accessA, ...compareWith) {
    const initialAccess = BitsHandler.from(32, accessA);
    const compareAccess = compareWith.map((access) =>
      BitsHandler.from(32, access).toArray(),
    );

    initialAccess.toArray().forEach((bit, idx) => {
      for (const access of compareAccess) {
        const isSame = bit === access[idx];
        if (!isSame) {
          initialAccess.set(32 - idx - 1, 0);
        }
      }
    });

    return initialAccess;
  }

  toArray() {
    return this.bits.map(Number);
  }

  toNumber() {
    const nextBits = this.bits.join('');
    const nextAccess = parseInt(nextBits, 2);
    return nextAccess;
  }

  getFriendlyBitIdex(index) {
    const bitIdx = this.bits.length - 1 - index;
    return bitIdx;
  }

  get(index) {
    const bitIdx = this.getFriendlyBitIdex(index);
    return this.bits[bitIdx - 0] === '1';
  }

  set(index, bool) {
    const bitIdx = this.getFriendlyBitIdex(index);
    const bit = bool ? '1' : '0';
    return (this.bits[bitIdx - 0] = bit);
  }
}
