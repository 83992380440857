import styled from 'styled-components';

import { hidableListWithComponents } from '@float/common/activity';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import ActivityItemHistoryLine from '@float/web/activity/components/ActivityItemHistoryLine';

import { NotificationItemTitle } from '../NotificationItemTitle';

const StyledHideableList = styled.ul`
  list-style: none;
  padding: 0;
  margin-left: 0;
`;

const HideableListItem = styled.li`
  color: ${Colors.Core.Text.Subdued};
  overflow: hidden;
  text-overflow: ellipsis;
`;

/**
 * @todo
 *
 * Detach from `hidableListWithComponents`.
 * @url https://linear.app/float-com/issue/EXP-603
 *
 * This component should be refactored to be a standalone visual component.
 * Additionally, ensure that a Storybook entry is created for it to facilitate
 * visual testing and documentation.
 **/

export const NotificationItemDetailsContent = hidableListWithComponents({
  ActivityItemHistoryLine,
  ActivityItemTitle: NotificationItemTitle,
  HideableList: StyledHideableList,
  HideableListItem,
});
