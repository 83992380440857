import React from 'react';

export default function ({ size = '24', color = '#868D92' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        fillRule="nonzero"
        d="M8 5a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm8 0a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm-8 8c-3 0-7 1.464-7 3.5V19h14v-2.5c0-2.036-4-3.5-7-3.5zm8 0c-.317 0-.647.02-.979.05C16.203 13.917 17 15.06 17 16.5V19h6v-2.5c0-2.036-4-3.5-7-3.5z"
      />
    </svg>
  );
}
