import { createContext } from 'react';

import { ToggleAppearance, ToggleColor, ToggleSize } from '../types';

export type ToggleGroupContextValue = {
  readOnly: boolean;
  color: ToggleColor;
  size: ToggleSize;
  appearance: ToggleAppearance;
  disableUnselect: boolean;
  hideIfReadOnlyAndNotChecked: boolean;
};

export const ToggleGroupContext = createContext<ToggleGroupContextValue>({
  readOnly: false,
  color: 'default',
  size: 'medium',
  appearance: 'pill',
  disableUnselect: false,
  hideIfReadOnlyAndNotChecked: false,
});
