export * from './requestActivity';
export * from './updateSeenActivities';
export * from './updateActivityPage';

export const TOGGLE_ACTIVITY_FEED = 'TOGGLE_ACTIVITY_FEED';

export const toggleActivityFeed = (visible) => {
  return {
    type: TOGGLE_ACTIVITY_FEED,
    visible,
  };
};
