import { TimeDuration } from '@float/types';

export function calculateDuration(
  startTimestamp: number,
  endTimestamp: number,
): TimeDuration {
  const diff = Math.abs(endTimestamp - startTimestamp);
  const milliseconds = diff % 1000;
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  return {
    days,
    hours: hours % 24,
    minutes: minutes % 60,
    seconds: seconds % 60,
    milliseconds,
  };
}
