import { t } from '@lingui/macro';

import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';

import { Goals, SupportEffort } from './types';

export const getGetToKnownYouConfig = () => {
  const withExampleData = featureFlags.isFeatureEnabled(
    FeatureFlag.OnboardingExampleData,
  );

  return {
    teamSize: [
      {
        label: '1 to 10',
        data: '10',
      },
      {
        label: '11 to 100',
        data: '100',
      },
      {
        label: '101 to 250',
        data: '250',
      },
      {
        label: '251 to 1,000',
        data: '1000',
      },
      {
        label: '1,001+',
        data: '1001',
      },
    ],

    supportEffort: [
      {
        label: t`New to Float`,
        desc: t`Guide my setup`,
        data: SupportEffort.Low,
      },
      {
        label: t`Need an expert`,
        desc: t`A team member will email you`,
        data: SupportEffort.High,
      },
      {
        label: t`Already use Float`,
        desc: withExampleData
          ? t`Start with a blank Schedule`
          : t`I know what I'm doing`,
        data: SupportEffort.No,
      },
    ],

    goals: [
      {
        label: t`Resourcing`,
        desc: t`I need to schedule my people against ongoing or net new work`,
        data: Goals.Resourcing,
      },
      {
        label: t`Reporting`,
        desc: t`I need to review my team's utilization and forecast availability`,
        data: Goals.Reporting,
      },
      {
        label: t`Project scoping`,
        desc: t`I need to scenario plan resources for upcoming and potential projects`,
        data: Goals.ProjectScoping,
      },
      {
        label: t`Time tracking`,
        desc: t`I need to log time against a client or project to track my hours`,
        data: Goals.TimeTracking,
      },
    ],
  };
};
