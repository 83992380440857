import { FocusEventHandler, useEffect, useRef } from 'react';

import { ProjectFormData } from '../types';
import { useFieldArrayContext } from './useFieldArrayContext';

export const useRemoveOnBlur = ({
  isEmpty,
  index,
}: {
  isEmpty: boolean;
  index: number;
}) => {
  const { remove } = useFieldArrayContext<ProjectFormData>();
  const ref = useRef<HTMLElement>(null);
  const handleOutsideClick: EventListener = (e) => {
    if (ref.current && !ref.current.contains(e.target as Node) && isEmpty) {
      remove(index);
    }
  };

  const onBlurHandler: FocusEventHandler = (e) => {
    if (
      isEmpty &&
      ref.current &&
      !ref.current.contains(e.relatedTarget as Node) &&
      e.relatedTarget?.getAttribute('role') !== 'dialog'
    ) {
      remove(index);
    }
  };

  useEffect(() => {
    if (isEmpty) {
      window.addEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  });

  return {
    ref,
    onBlurHandler,
  };
};
