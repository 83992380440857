import React, { useCallback } from 'react';

import { Avatar } from '@float/common/components/Avatar';

import { StyledItem, StyledLabel } from './styles';

const VirtualListItem = React.forwardRef((props, ref) => {
  const {
    style = {},
    index = null,
    item = null,
    selected = false,
    highlighted = false,
    height = 0,
    withAvatar = 0,
    onHover,
    onLeave,
    onClick,
    field = 'name',
  } = props;

  const onMouseEnter = useCallback(() => {
    if (onHover) onHover(item, index);
  }, [onHover, item, index]);

  const onMouseLeave = useCallback(() => {
    if (onLeave) onLeave(item, index);
  }, [onLeave, item, index]);

  const onMouseDown = useCallback(
    (e) => {
      if (onClick) onClick(item, index, e);
    },
    [onClick, item, index],
  );

  return (
    <div style={style}>
      <StyledItem
        style={{ '--height': height }}
        $selected={selected}
        $highlighted={highlighted}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseDown={onMouseDown}
      >
        {withAvatar && (
          <Avatar
            name={item.name}
            imageUrl={item.avatar_file}
            size="xs"
            readOnly
          />
        )}

        {item.icon && item.icon}
        <StyledLabel>{item[field]}</StyledLabel>
      </StyledItem>
    </div>
  );
});

export default VirtualListItem;
