/* eslint-disable react-compiler/react-compiler */
/** We wanto to avoid using the compiler here because it would break the hook */
import { useEffect, useRef } from 'react';

const usePrevious = <T>(value: T): T | undefined => {
  'use no memo';
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export { usePrevious };
