import styled, { css } from 'styled-components';

import * as Animation from '@float/ui/deprecated/Earhart/Animation';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';
import { respondTo } from '@float/ui/deprecated/Theme/helpers/responsive/respondTo';

export const StyledBullet = styled.span`
  --svg-icon-color: ${Colors.FIN.Lt.Emphasis.White};

  ${Typography.TextM.SB600};

  position: relative;

  display: inline-flex;

  justify-content: center;
  align-items: center;

  vertical-align: middle;

  width: 32px;
  height: 32px;

  min-width: 32px;
  min-height: 32px;

  border-radius: 16px;

  margin-right: 12px;

  color: ${Colors.FIN.Lt.Emphasis.White};
  background-color: ${Colors.FIN.Lt.Emphasis.Low};

  overflow: hidden;

  transition-property: background-color, color;
  transition-duration: ${Animation.Settings.Duration.Normal};
  transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

  span {
    position: absolute;

    display: flex;

    justify-content: center;
    align-items: center;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    &:nth-child(1) {
      border-radius: 16px;

      background-color: ${Colors.Radix.Secondary.Emerald[10]};

      transition-property: opacity, transform;
      transition-duration: ${Animation.Settings.Duration.Normal};
      transition-timing-function: ${Animation.Settings.Easing.InOutCurve};
      transition-delay: 0.15s, 1.15s;

      opacity: 0;
      transform: scale(0);

      z-index: 10;

      svg {
        path {
          transition-property: stroke-dashoffset;
          transition-duration: ${Animation.Settings.Duration.Normal};
          transition-timing-function: ${Animation.Settings.Easing.InOutCurve};
          transition-delay: 0.25s;

          stroke-dasharray: 20;
          stroke-dashoffset: 20;
        }
      }
    }

    &:nth-child(2) {
      transition-property: opacity;
      transition-duration: ${Animation.Settings.Duration.Normal};
      transition-timing-function: ${Animation.Settings.Easing.InOutCurve};
    }
  }
`;

export const StyledListItem = styled.li`
  ${Typography.TextS.M500};

  display: inline-block;

  color: ${Colors.FIN.Lt.Emphasis.Low};

  ${respondTo(
    'small',
    '<=',
  )(css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `)};

  ${({ $active }) => {
    if ($active) {
      return css`
        color: ${Colors.FIN.Lt.Emphasis.High};

        ${respondTo(
          'small',
          '<=',
        )(css`
          overflow: visible;
        `)};

        ${StyledBullet} {
          background-color: ${Colors.FIN.Lt.Emphasis.Primary};
        }
      `;
    }
  }}

  ${({ $completed }) => {
    if ($completed) {
      return css`
        color: ${Colors.FIN.Lt.Emphasis.Low};

        ${StyledBullet} {
          span {
            &:nth-child(1) {
              opacity: 1;
              transform: none;

              transition-delay: 0.15s, 0.15s;

              svg {
                path {
                  stroke-dashoffset: 40;
                }
              }
            }

            &:nth-child(2) {
              opacity: 0;
            }
          }
        }
      `;
    }
  }}
`;
