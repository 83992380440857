import datesCreator from '@float/libs/dates';

import {
  addHours,
  buildDayHeader,
  buildHolidays,
  registerItem,
} from './helpers';
import { BreakdownData, PeopleBreakdownPayload } from './types';

export function breakdownPeopleByDay(payload: PeopleBreakdownPayload) {
  const dates = datesCreator(payload.serializedDates);
  const [startCol] = dates.toDescriptor(payload.startDate);
  const [endCol] = dates.toDescriptor(payload.endDate);
  dates.ensureRange(startCol, endCol);

  const { rows, cells, rowMetas } = payload;

  function buildScheduleData() {
    const peopleRows = rows.filter((r) => r.type === 'person');

    return peopleRows.map(({ data: person }) => {
      const rowMeta = rowMetas.get(`person-${person.people_id}`)!;

      const data: BreakdownData = {
        person: {
          name: person.name,
          jobTitle: person.job_title,
          department: (person.department && person.department.name) || '',
        },
        tasks: {},
        timeoffs: {},
        aggregate: {},
      };

      for (let i = startCol; i <= endCol; i++) {
        const cell = cells[`person-${person.people_id}:${i}`];
        const firstCellDay = dates.fromDescriptor(i, 0);
        const dailyWorkHours = rowMeta.getDailyWorkHours(firstCellDay);
        const numCellDays = dailyWorkHours.length;

        for (let d = 0; d < numCellDays; d++) {
          const date = dates.fromDescriptor(i, d);
          if (date < payload.startDate) continue;
          if (date > payload.endDate) continue;

          if (!data.aggregate[date]) {
            data.aggregate[date] = {
              capacity: dailyWorkHours[d],
              total: 0,
            };
          }
        }

        if (!cell || !cell.items || !cell.items.length) continue;

        cell.items.forEach((item) => {
          if (item.type === 'task') {
            const taskData = registerItem(payload, data, item);

            for (let d = item.x!; d < item.x! + item.w; d++) {
              const date = dates.fromDescriptor(i, d);
              if (date < payload.startDate) continue;
              if (date > payload.endDate) continue;

              addHours(taskData, date, item.entity.hours);
              data.aggregate[date].total += item.entity.hours;
              data.aggregate[date].capacity -= item.entity.hours;
            }
          }

          if (item.type === 'timeoff' && !item.entity.region_holiday_id) {
            const timeoffData = registerItem(payload, data, item);

            for (let d = item.x!; d < item.x! + item.w; d++) {
              const date = dates.fromDescriptor(i, d);
              if (date < payload.startDate) continue;
              if (date > payload.endDate) continue;

              const hours = item.entity.full_day
                ? dailyWorkHours[d]
                : item.entity.hours;

              if (hours) {
                addHours(timeoffData, date, hours);
                data.aggregate[date].total += hours;
                data.aggregate[date].capacity -= hours;
              }
            }
          }

          if (
            item.type === 'holiday' ||
            (item.type === 'timeoff' && item.entity.region_holiday_id)
          ) {
            for (let d = item.x!; d < item.x! + item.w; d++) {
              const date = dates.fromDescriptor(i, d);
              if (date < payload.startDate) continue;
              if (date > payload.endDate) continue;

              data.aggregate[date].capacity -= dailyWorkHours[d];
            }
          }

          if (item.type === 'oneOff') {
            // Hours scheduled on one-off days should not adjust capacity. Since
            // we ignored this above when iterating through tasks/timeoffs,
            // we need to adjust.
            const date = dates.fromDescriptor(i, item.x!);
            if (date >= payload.startDate && date <= payload.endDate) {
              data.aggregate[date].capacity += cell.dayHours[item.x!];
            }
          }

          for (let d = 0; d < numCellDays; d++) {
            const date = dates.fromDescriptor(i, d);
            if (date < payload.startDate) continue;
            if (date > payload.endDate) continue;

            // We never want to report a negative capacity, which can result
            // if there's overtime in a given time period.
            if (data.aggregate[date].capacity < 0) {
              data.aggregate[date].capacity = 0;
            }
          }
        });
      }

      return data;
    });
  }

  // ---------------------------------------------------------------------------

  const result = {
    header: buildDayHeader(startCol, endCol, dates, {
      endDate: payload.endDate,
      filters: payload.filters,
      leftHiddenDays: payload.serializedDates.leftHiddenDays,
      rightHiddenDays: payload.serializedDates.rightHiddenDays,
      startDate: payload.startDate,
    }),
    scheduleData: buildScheduleData(),
    holidays: buildHolidays(cells, startCol, endCol),
    payload,
  };

  return result;
}
