import { Person } from '@float/types/person';
import { TimeoffBalanceType, TimeoffType } from '@float/types/timeoffType';

import request from '../lib/request';

type TimeoffTypeBalanceResponse = {
  id: TimeoffType['timeoff_type_id'];
  name: TimeoffType['timeoff_type_name'];
  balanceType: TimeoffBalanceType;
  people: {
    balance: number;
    eligibleDays: number;
  };
};

export const timeoffTypes = {
  getTimeoffTypesBalance: (params: {
    peopleId: Person['people_id'];
    endDate: string;
  }): Promise<TimeoffTypeBalanceResponse[]> => {
    return request.get(
      `svc/ra/reports/timeoffs/balance/people/${params.peopleId}?date=${params.endDate}`,
      null,
      {
        version: '',
        contentType: 'application/json',
        json: true,
        jwt: true,
        hostname: '',
      },
    );
  },
};
