import React, { forwardRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { t, Trans } from '@lingui/macro';

import {
  TaskNameLabel,
  TaskNameSelect,
  TaskNameSelectRef,
} from '@float/common/components/TaskNameSelect';
import { fadeIn } from '@float/common/lib/animation.css';
import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { CurrencyProps } from '@float/types/companyPreferences';
import { Project } from '@float/types/project';
import { TaskMeta } from '@float/types/task';
import { integrationTypes } from '@float/web/pmSidebar/integrationTypes';

import { BudgetLabel } from '../../components/BudgetLabel/BudgetLabel';
import { BudgetLabelNotUtilizedWarning } from '../../components/BudgetLabel/BudgetLabelNotUtilizedWarning';
import { AllocationCost } from '../../EditTaskModal.types';
import {
  getIsBudgetLabelNotUtilizedWarningVisible,
  getIsBudgetLabelVisible,
} from './AllocationTaskSection.helpers';
import {
  OnboardingAddATaskLabel,
  TaskNameContainer,
  ViewInPM,
} from './AllocationTaskSection.styles';

export type AllocationTaskSectionProps = {
  allocationCost: AllocationCost;
  allocationCostInitial: AllocationCost;
  budgets: ReduxStateStrict['budgets'];
  currencyProps: CurrencyProps;
  pmUrl?: string;
  pmIntegrationType?: string;
  integrationTypes?: unknown;
  project?: Project;
  errors: unknown;
  integrationSyncLocked: boolean;
  isFirstTask: boolean;
  taskName: string;
  taskMetas: TaskMeta[];
  taskMetaId: TaskMeta['task_meta_id'] | null;
  shouldStatusElementsNotRender: boolean;
  forceShowEditTaskField: boolean;
  userCanSeeBudgets: boolean;
  setState: (state: unknown) => void;
  renderReadOnlyInput: (...args: any) => React.ReactNode;
  isReadOnly: () => boolean;
  getIsLegacyCalendarEvent: () => boolean;
  onEnter: () => void;
};

export const AllocationTaskSection = forwardRef<
  TaskNameSelectRef,
  AllocationTaskSectionProps
>((props, taskRef) => {
  const {
    allocationCost,
    allocationCostInitial,
    budgets,
    currencyProps,
    pmUrl,
    pmIntegrationType,
    project,
    errors,
    integrationSyncLocked,
    isFirstTask,
    taskName,
    taskMetaId,
    taskMetas,
    shouldStatusElementsNotRender,
    forceShowEditTaskField,
    userCanSeeBudgets,
    setState,
    isReadOnly,
    getIsLegacyCalendarEvent,
    renderReadOnlyInput,
    onEnter,
  } = props;

  const taskBudget = taskMetaId ? budgets.tasks[taskMetaId] : null;
  const taskMeta =
    taskMetas.find((item) => item.task_meta_id === taskMetaId) || null;

  const readOnly =
    isReadOnly() || (integrationSyncLocked && getIsLegacyCalendarEvent());

  // @ts-expect-error errors are not typed
  const taskErrors = errors.task;

  const integrationName =
    pmIntegrationType &&
    // @ts-expect-error is too loose
    integrationTypes[pmIntegrationType] &&
    // @ts-expect-error pmIntegrationType is too loose
    integrationTypes[pmIntegrationType]?.title;

  const currentTaskName = taskName;

  const hasTasks = taskMetas.some((taskMetas) => taskMetas.task_name !== '');
  const canUpdateTask = forceShowEditTaskField || hasTasks || currentTaskName;

  const shouldShowTaskSelect = (canUpdateTask || currentTaskName) && !readOnly;

  const isTaskBudgetLabelVisible = getIsBudgetLabelVisible({
    userCanSeeBudgets,
    project,
    taskMeta,
  });

  const isTaskBudgetLabelNotUtilizedWarningVisible =
    getIsBudgetLabelNotUtilizedWarningVisible({
      userCanSeeBudgets,
      project,
      taskMeta,
    });

  function handleInputChange(value: string) {
    setState({ taskMetaId: null, taskName: value, nonBillable: false });
  }

  function handleInputMouseDown() {
    setState({ isFirstTask: false });
  }

  function handleBlur() {
    if (!currentTaskName) setState({ forceShowEditTaskField: false });
  }

  function handleOnChange(option: {
    taskMetaId: number | null;
    taskName: string;
    nonBillable: boolean;
  }) {
    const { taskMetaId, taskName, nonBillable } = option;

    setState({
      errors: {
        // @ts-expect-error errors not typed
        ...errors,
        task: [],
      },
      taskMetaId,
      taskName,
      nonBillable,
    });
  }

  const getReadOnlyTaskNameElem = () => {
    if (!taskName) {
      return null;
    }

    const isReadOnly = project?.locked_task_list === 1;

    return renderReadOnlyInput(
      <TaskNameLabel locked={isReadOnly} />,
      taskName,
      {
        noMargin: !shouldStatusElementsNotRender,
        style: { flex: '1 1 100%' },
      },
    );
  };

  if (!shouldShowTaskSelect) {
    return (
      <TaskNameContainer>
        {currentTaskName && getReadOnlyTaskNameElem()}
        {pmUrl && (
          <ViewInPM
            as="a"
            href={pmUrl}
            target="_blank"
            compact={readOnly && shouldStatusElementsNotRender}
          >
            View in {integrationName}
          </ViewInPM>
        )}
      </TaskNameContainer>
    );
  }

  return (
    <TaskNameContainer className={fadeIn}>
      <CSSTransition in={isFirstTask} timeout={200} appear unmountOnExit>
        <OnboardingAddATaskLabel>
          {featureFlags.isFeatureEnabled(FeatureFlag.OnboardingExampleData) ? (
            <Trans>
              Add your own Task for this Allocation eg. planning, delivery
            </Trans>
          ) : (
            <Trans>Enter a task name to the project task list</Trans>
          )}
        </OnboardingAddATaskLabel>
      </CSSTransition>

      <TaskNameSelect
        ref={taskRef}
        project={project}
        taskErrors={taskErrors}
        value={currentTaskName}
        taskNames={taskMetas}
        isFirstTask={isFirstTask}
        onEnter={onEnter}
        onInputChange={handleInputChange}
        onInputMouseDown={handleInputMouseDown}
        onChange={handleOnChange}
        onBlur={handleBlur}
        autoFocus={false}
        style={{ flexBasis: '100%' }}
        placeholder={
          hasTasks
            ? t`Choose a task to allocate`
            : t`Task name eg. planning, delivery`
        }
      />
      {isTaskBudgetLabelVisible && (
        <BudgetLabel
          budget={taskBudget}
          budgetType={project?.budget_type}
          currencyProps={currencyProps}
          allocationCost={allocationCost}
          allocationCostInitial={allocationCostInitial}
        />
      )}
      {isTaskBudgetLabelNotUtilizedWarningVisible && (
        <BudgetLabelNotUtilizedWarning />
      )}
      {pmUrl && (
        <ViewInPM
          as="a"
          href={pmUrl}
          target="_blank"
          compact={readOnly && shouldStatusElementsNotRender}
        >
          View in {integrationName}
        </ViewInPM>
      )}
    </TaskNameContainer>
  );
});
