import styled from 'styled-components';

import EH from '@float/ui/deprecated/Earhart';

export const NoTaskNamesWrapper = styled.div`
  position: relative;
  ${EH.Typography.TextL.R400};
  color: ${EH.Colors.FIN.Lt.Emphasis.Low};
  margin-top: 4px;
  height: 254px;
`;
export const NoTaskBackground = styled.div`
  position: absolute;
  z-index: 1;
  left: 0px;
  top: 0px;
  width: 100%;
`;
export const EmptyMessageWrapper = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 254px;

  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.6) 29.69%,
    #ffffff 53.12%
  );
`;
export const EmptyMessageTitle = styled.span`
  text-align: center;
  ${EH.Typography.Label16.M500};
  color: ${EH.Colors.Core.Text.Default};
  margin-bottom: 8px;
`;
export const EmptyMessageBody = styled.span`
  text-align: center;
  max-width: 305px;

  ${EH.Typography.Label16.R400};
  color: ${EH.Colors.Core.Text.Default};
`;
