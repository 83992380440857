import { useMemo } from 'react';

import { Person } from '@float/types/person';
import { TimeoffType } from '@float/types/timeoffType';

import { formatTimeoffTypeAsOption } from '../helpers/formatTimeoffTypeAsOption';
import { useTimeoffTypesBalance } from './useTimeoffTypesBalance';

type Props = {
  endDate: string;
  onChange: (nextTimeoffType: TimeoffType) => void;
  personId?: Person['people_id'];
  timeoffTypes: TimeoffType[];
};

type TimeOffTypeOption = {
  label: TimeoffType['timeoff_type_name'];
  value: TimeoffType['timeoff_type_id'];
};

export const useTimeoffTypesSelect = (props: Props) => {
  const { endDate, onChange, personId, timeoffTypes } = props;

  const timeoffTypesBalance = useTimeoffTypesBalance({ endDate, personId });

  // the memoization is needed to avoid the VirtualSelect to re-render unnecessarily
  // https://linear.app/float-com/issue/EXP-530/bug-time-off-options-missing-when-selecting-the-date-first-in
  const options = useMemo(
    () =>
      timeoffTypes
        .filter((type) => type.active)
        .map((type) =>
          formatTimeoffTypeAsOption(type, timeoffTypesBalance, personId),
        )
        .sort((a, b) => a.label.localeCompare(b.label)),
    [timeoffTypes, timeoffTypesBalance, personId],
  );

  const setTimeoffType = (timeoffTypeOption: TimeOffTypeOption) => {
    const { value: id } = timeoffTypeOption;

    const timeoffType = timeoffTypes.find((t) => t.timeoff_type_id === id);

    if (timeoffType) {
      onChange(timeoffType);
    }
  };

  return { setTimeoffType, options };
};
