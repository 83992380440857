import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getProjectsByClientId } from 'selectors';

import Table from '@float/web/settingsV2/components/Table';
import withOnMount from '@float/web/settingsV2/decorators/withOnMount';
import {
  areBeingFetched as clientsAreBeingFetched,
  lastFetchError as clientsLastFetchError,
} from '@float/web/settingsV2/reducers/clients';
import {
  areBeingFetched as projectsAreBeingFetched,
  lastFetchError as projectsLastFetchError,
} from '@float/web/settingsV2/reducers/projects';
import {
  ACCOUNT_SETTINGS_CLIENTS_TABLE,
  getSortData,
  getTableRowCreator,
  getTableRows,
} from '@float/web/settingsV2/reducers/reducerHelpers';

import {
  deleteClient,
  fetchClients,
  sortClients,
  updateClient,
} from '../../actions/clients';

const getClientData = getTableRowCreator('client_id', () => true, [
  'name',
  'project_count',
]);

const isLoading = (state) =>
  clientsAreBeingFetched(state.settingsClients) ||
  projectsAreBeingFetched(state.settingsProjects);

const loadError = (state) =>
  clientsLastFetchError(state.settingsClients) ||
  projectsLastFetchError(state.settingsProjects);

const deleteClientWithProjects = (id) => (dispatch) => {
  return dispatch(deleteClient(id)).catch((error) => console.error(error));
};

const mapStateToProps = (state) => {
  const rows = getTableRows(
    state.settingsClients,
    getClientData,
    'client_id',
    ACCOUNT_SETTINGS_CLIENTS_TABLE,
  ).map((row) => {
    const clientNameEncoded = encodeURIComponent(row.entity.name);
    const numProjects = row.entity.project_count;

    const projectsLink =
      numProjects > 0 ? (
        <Link to={`/projects?client=${clientNameEncoded}`}>{numProjects}</Link>
      ) : (
        0
      );

    return {
      ...row,
      display: [null, projectsLink],
    };
  });

  return {
    sort: getSortData(state.settingsClients, ACCOUNT_SETTINGS_CLIENTS_TABLE),
    rows,
    projects: state.settingsProjects,
    projectsByClient: getProjectsByClientId(state),
    companyId: state.currentUser.cid,
    isLoading: isLoading(state),
    loadError: loadError(state),
    totalCount: state.settingsClients.totalCount,
  };
};

let fetchedClients = false;

const mapDispatchToProps = (dispatch) => {
  return {
    onComponentDidMount: () => {
      // Clicking on another tab and coming back to clients causes a remount
      // Avoid this by only fetching on mount once per session
      if (fetchedClients) {
        return;
      }
      fetchedClients = true;
      dispatch(
        fetchClients(
          ACCOUNT_SETTINGS_CLIENTS_TABLE,
          undefined,
          undefined,
          true,
        ),
      );
    },
    onClickHeader: (property, direction) =>
      dispatch(
        sortClients(property, direction, ACCOUNT_SETTINGS_CLIENTS_TABLE),
      ),
    onClickDelete: (id) => dispatch(deleteClientWithProjects(id)),
    onSubmit: async (row, update) => {
      try {
        await dispatch(updateClient(row.id, { name: update[0] }));
      } catch (e) {
        throw new Error(`"${update[0]}" already exists.`);
      }
    },
    onLastRowVisible: () =>
      dispatch(fetchClients(ACCOUNT_SETTINGS_CLIENTS_TABLE)),
  };
};

const ClientsTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOnMount(Table));

export default ClientsTable;
