import { getJWTAccessToken } from '@float/common/actions/jwt';

import api from '../api';
import { createFetchOAuthLink } from './common';
import {
  ADD_TEAMWORK_CO_INT,
  ADD_TEAMWORK_CO_INT_FAILURE,
  ADD_TEAMWORK_CO_INT_SUCCESS,
  CLEAR_TEAMWORK_PRELOAD,
  FETCH_TEAMWORK_CO_INTS,
  FETCH_TEAMWORK_CO_INTS_FAILURE,
  FETCH_TEAMWORK_CO_INTS_SUCCESS,
  FETCH_TEAMWORK_CONFIG,
  FETCH_TEAMWORK_CONFIG_FAILURE,
  FETCH_TEAMWORK_CONFIG_SUCCESS,
  FETCH_TEAMWORK_OAUTH_LINK,
  FETCH_TEAMWORK_OAUTH_LINK_FAILURE,
  FETCH_TEAMWORK_OAUTH_LINK_SUCCESS,
  FETCH_TEAMWORK_PRELOAD,
  FETCH_TEAMWORK_PRELOAD_FAILURE,
  REMOVE_TEAMWORK_CO_INT,
  REMOVE_TEAMWORK_CO_INT_FAILURE,
  REMOVE_TEAMWORK_CO_INT_SUCCESS,
  RETRY_PEOPLE_SYNC,
  RETRY_PEOPLE_SYNC_FAILURE,
  RETRY_PEOPLE_SYNC_SUCCESS,
  UPDATE_TEAMWORK_CONFIG,
  UPDATE_TEAMWORK_CONFIG_FAILURE,
  UPDATE_TEAMWORK_CONFIG_SUCCESS,
} from './teamwork.constants';

export * from './teamwork.constants';

const CO_INT_TYPE = 'teamwork';

export const fetchTeamworkOAuthLink = createFetchOAuthLink({
  types: [CO_INT_TYPE],
  fetchType: FETCH_TEAMWORK_OAUTH_LINK,
  successType: FETCH_TEAMWORK_OAUTH_LINK_SUCCESS,
  failureType: FETCH_TEAMWORK_OAUTH_LINK_FAILURE,
});

export const fetchTeamworkCoInts = () => {
  return async (dispatch, getState) => {
    try {
      const loadState =
        getState().integrations.teamwork.fetchTeamworkCoIntsLoadState;
      if (loadState === 'LOADING') {
        return;
      }

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: FETCH_TEAMWORK_CO_INTS });
      const coInts = await api(accessToken).requestCoInts({
        type: CO_INT_TYPE,
      });

      dispatch({
        type: FETCH_TEAMWORK_CO_INTS_SUCCESS,
        coInts,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: FETCH_TEAMWORK_CO_INTS_FAILURE });
    }
  };
};

export const addTeamworkCoInt = (formInput) => {
  return async (dispatch, getState) => {
    try {
      const { syncType, importExisting } = formInput;

      const loadState = getState().integrations.teamwork.addCoIntLoadState;
      if (loadState === 'LOADING') {
        return;
      }

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: ADD_TEAMWORK_CO_INT });

      const { success } = await api(accessToken).addCoInt({
        syncType,
        importExisting,
        type: CO_INT_TYPE,
      });

      if (!success) {
        throw Error('Add Teamwork company integration error on API request');
      }

      dispatch({
        type: ADD_TEAMWORK_CO_INT_SUCCESS,
      });

      dispatch(fetchTeamworkCoInts());
    } catch (e) {
      console.error(e);
      dispatch({ type: ADD_TEAMWORK_CO_INT_FAILURE });
    }
  };
};

export const removeTeamworkCoInt = (coIntId, archiveData) => {
  return async (dispatch, getState) => {
    try {
      const loadState = getState().integrations.teamwork.removeCoIntLoadState;
      if (loadState === 'LOADING') {
        return;
      }

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: REMOVE_TEAMWORK_CO_INT });

      const { success } = await api(accessToken).removeCoInt({
        coIntId,
        archiveData,
      });

      if (!success) {
        throw Error('Remove Teamwork company integration error on API request');
      }

      dispatch({ type: REMOVE_TEAMWORK_CO_INT_SUCCESS });

      dispatch(fetchTeamworkCoInts());
    } catch (e) {
      console.error(e);
      dispatch({ type: REMOVE_TEAMWORK_CO_INT_FAILURE });
    }
  };
};

export const retryTeamworkPeopleSync = (coIntId) => {
  return async (dispatch, getState) => {
    try {
      const loadState =
        getState().integrations.teamwork.retryPeopleSyncLoadState;
      if (loadState === 'LOADING') {
        return;
      }

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: RETRY_PEOPLE_SYNC });

      const { success } = await api(accessToken).retryPeopleSync({ coIntId });

      if (!success) {
        throw Error('Retry People Sync error on API request');
      }

      dispatch({ type: RETRY_PEOPLE_SYNC_SUCCESS });

      dispatch(fetchTeamworkCoInts());
    } catch (e) {
      console.error(e);
      dispatch({ type: RETRY_PEOPLE_SYNC_FAILURE });
    }
  };
};

export const fetchTeamworkPreload = () => {
  return async (dispatch, getState) => {
    try {
      const loadState = getState().integrations.teamwork.preloadLoadState;
      if (loadState === 'LOADING') {
        return;
      }

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: FETCH_TEAMWORK_PRELOAD });
      const { success } = await api(accessToken).requestPreload({
        type: CO_INT_TYPE,
      });
      if (!success) {
        dispatch({ type: FETCH_TEAMWORK_PRELOAD_FAILURE });
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: FETCH_TEAMWORK_PRELOAD_FAILURE });
    }
  };
};

export const updateTeamworkConfig = (
  coInt,
  config,
  { startInitialImport, resync } = {},
) => {
  return async (dispatch, getState) => {
    try {
      const loadState = getState().integrations.teamwork.updateConfigLoadState;
      if (loadState === 'LOADING') {
        return;
      }

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: UPDATE_TEAMWORK_CONFIG, startInitialImport });
      const updatedConfig = await api(accessToken).updateCoIntConfig({
        coIntId: coInt && coInt.integrations_co_id,
        config,
        startInitialImport,
        resync,
        type: CO_INT_TYPE,
      });

      dispatch({
        type: UPDATE_TEAMWORK_CONFIG_SUCCESS,
        config: updatedConfig,
        initialImport: startInitialImport,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: UPDATE_TEAMWORK_CONFIG_FAILURE });
    }
  };
};

export const fetchTeamworkConfig = (coIntId) => async (dispatch, getState) => {
  try {
    const loadState = getState().integrations.teamwork.fetchConfigLoadState;
    if (loadState === 'LOADING') {
      return;
    }

    const accessToken = await dispatch(getJWTAccessToken());

    dispatch({ type: FETCH_TEAMWORK_CONFIG });
    const config = await api(accessToken).getCoIntConfig({
      coIntId,
    });
    dispatch({
      type: FETCH_TEAMWORK_CONFIG_SUCCESS,
      config,
    });
  } catch (e) {
    console.error(e);
    dispatch({ type: FETCH_TEAMWORK_CONFIG_FAILURE });
  }
};

export const clearTeamworkPreload = () => async (dispatch) =>
  dispatch({ type: CLEAR_TEAMWORK_PRELOAD });
