import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { Trans } from '@lingui/macro';
import { isEmpty } from 'lodash';
import styled from 'styled-components';

import {
  TaskNameLabel,
  TaskNameSelect,
} from '@float/common/components/TaskNameSelect';
import { fadeIn } from '@float/common/lib/animation.css';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { InputAlert, LinkLabel } from '@float/ui/deprecated';
import { integrationTypes } from '@float/web/pmSidebar/integrationTypes';

import { shouldStatusElementsNotRender } from './statusToggleButtons';

let etmThis;

const TaskNameContainer = styled.div`
  position: relative;
  width: 100%;
`;

const ViewInPM = styled(LinkLabel)`
  position: absolute;
  right: 0;
  bottom: ${(p) => (p.compact ? -20 : -34)}px;
  color: ${(p) => p.theme.blueGrey} !important;
`;

const OnboardingAddATaskLabel = styled(InputAlert)`
  &.exit {
    opacity: 1;
  }

  &.exit-active {
    opacity: 0;
    transition: opacity 100ms ease-out;
  }
`;

const getReadOnlyTaskNameElem = () => {
  const { taskName } = etmThis.state;
  if (!taskName) {
    return null;
  }

  const isReadOnly = etmThis.state.project?.locked_task_list === 1;
  return etmThis.renderReadOnlyInput(
    <TaskNameLabel locked={isReadOnly} />,
    taskName,
    {
      noMargin: !shouldStatusElementsNotRender(etmThis),
      style: { flex: '1 1 100%' },
    },
  );
};

/**
 * @deprecated use web/src/components/schedule/task/sections/AllocationTaskSection/AllocationTaskSection.tsx instead
 *
 * this file is kept for gradual customers rolouts when using budget by task to avoid of accidental runtime errors after refactoring
 *
 */
export const getTaskNameElem = (self) => {
  etmThis = self;
  const { pmUrl, project, errors, integrationSyncLocked } = etmThis.state;
  const readOnly =
    etmThis.isReadOnly() ||
    (integrationSyncLocked && etmThis.getIsLegacyCalendarEvent());
  const taskErrors = errors.task;
  const integrationName =
    integrationTypes[etmThis.props.pmIntegrationType] &&
    integrationTypes[etmThis.props.pmIntegrationType].title;

  const pmLink = pmUrl && (
    <ViewInPM
      as="a"
      href={pmUrl}
      target="_blank"
      compact={readOnly && shouldStatusElementsNotRender(etmThis)}
    >
      View in {integrationName}
    </ViewInPM>
  );

  const currentTaskName = etmThis.state.taskName;

  const hasTasks = self.getHasTasksOptions();
  const taskMetas = self.getTaskMetas();

  const canUpdateTask =
    self.state.forceShowEditTaskField || hasTasks || currentTaskName;

  function handleInputChange(value) {
    self.setState({ taskMetaId: null, taskName: value, nonBillable: false });
  }

  function handleInputMouseDown() {
    self.setState({ isFirstTask: false });
  }

  function handleBlur() {
    if (!currentTaskName) self.setState({ forceShowEditTaskField: false });
  }

  function handleOnChange(option) {
    const { taskMetaId, taskName, nonBillable } = option;

    etmThis.setState({
      errors: {
        ...etmThis.state.errors,
        task: [],
      },
      taskMetaId,
      taskName,
      nonBillable,
    });
  }

  const shouldShowTaskSelect = (canUpdateTask || currentTaskName) && !readOnly;

  if (!shouldShowTaskSelect) {
    return (
      <TaskNameContainer hasError={!isEmpty(errors)}>
        {currentTaskName && getReadOnlyTaskNameElem()}
        {pmLink}
      </TaskNameContainer>
    );
  }

  return (
    <TaskNameContainer className={fadeIn} hasError={!isEmpty(errors)}>
      <CSSTransition
        in={etmThis.state.isFirstTask}
        timeout={200}
        appear
        unmountOnExit
      >
        <OnboardingAddATaskLabel>
          {featureFlags.isFeatureEnabled(FeatureFlag.OnboardingExampleData) ? (
            <Trans>
              Add your own Task for this Allocation eg. planning, delivery
            </Trans>
          ) : (
            <Trans>Enter a task name to the project task list</Trans>
          )}
        </OnboardingAddATaskLabel>
      </CSSTransition>

      <TaskNameSelect
        ref={self.taskRef}
        project={project}
        taskErrors={taskErrors}
        value={currentTaskName}
        taskNames={taskMetas}
        isFirstTask={self.state.isFirstTask}
        onEnter={self.saveOnEnterKeyPress}
        onInputChange={handleInputChange}
        onInputMouseDown={handleInputMouseDown}
        onChange={handleOnChange}
        onBlur={handleBlur}
        autoFocus={false}
        style={{ flexBasis: '100%' }}
        placeholder={
          hasTasks
            ? 'Choose a task to allocate'
            : 'Task name eg. planning, delivery'
        }
      />
      {pmLink}
    </TaskNameContainer>
  );
};
