import { toDash } from '@float/libs/utils/strings';
import { Phase, Project, RawPhase, RawTask } from '@float/types';

import { MilestoneApiPayload } from '../actions/milestones';
import { PhaseApiPayload } from '../actions/phases';
import request from '../lib/request';
import { fetchAllPages } from './helpers/fetchAllPages';
import { makeRequest, MakeRequestParams } from './makeRequest';

const RESOURCE = 'phases';

export const phases = {
  /**
   * Retrieve a phase
   * @param id: phase id
   */
  getPhase: async (
    params: MakeRequestParams<undefined, Phase['phase_id']>,
  ): Promise<RawPhase> =>
    makeRequest({
      ...params,
      resource: toDash(RESOURCE),
      method: 'GET',
    }),

  /**
   * Retrieves all phases
   * @param archived boolean set to true to get only archived events
   */
  getAllPhases: async ({
    archived = false,
    active = false,
    project_id = 0,
  } = {}): Promise<RawPhase[]> => {
    const q: Record<string, string> = { lean: '1' };
    if (archived) q['archived'] = '1';
    if (active) q['active'] = '1';
    if (project_id) q['project_id'] = String(project_id);

    return fetchAllPages('phases/all', q);
  },

  /**
   * Retrieves the date range limits for the phase
   * @param id number id of the phase
   */
  getPhaseRangeLimit: (id: number) =>
    request.get(`phases/${id}/rangelimit`, null, { version: 'f3' }),

  /**
   * Creates new phase
   * @param data: new phase attributes
   */
  createPhase: async (
    params: MakeRequestParams<Partial<PhaseApiPayload>>,
  ): Promise<RawPhase> =>
    makeRequest({
      ...params,
      resource: toDash(RESOURCE),
      method: 'POST',
    }),

  /**
   * Updates phase
   * @param id: id of phase to update
   * @param data: new phase attributes
   */
  updatePhase: async (
    params: MakeRequestParams<Partial<PhaseApiPayload>, Phase['phase_id']>,
  ): Promise<RawPhase> =>
    makeRequest({
      ...params,
      resource: toDash(RESOURCE),
      method: 'PATCH',
    }),

  /**
   * Deletes phase
   * @param id: id of phase to delete
   */
  deletePhase: async (
    params: MakeRequestParams<undefined, Phase['phase_id']>,
  ): Promise<void> =>
    makeRequest({
      ...params,
      resource: toDash(RESOURCE),
      method: 'DELETE',
    }),

  /**
   * Shifts the timeline of the phase. Tasks and other items
   * that are already completed are not affected
   * @param data.start_date new start date
   * @param data.end_date new end date
   * @param id: phase id
   * @returns data that has been affected (milestone, tasks that are copied over)
   */
  shiftPhase: ({
    id,
    data: { start_date, end_date },
  }: MakeRequestParams<
    { start_date?: string | null; end_date?: string | null },
    Project['project_id']
  >): Promise<{
    phase: RawPhase;
    milestone: MilestoneApiPayload[];
    task: RawTask[];
  }> => {
    const dates: {
      start_date?: string;
      end_date?: string;
    } = {};
    if (start_date) {
      dates.start_date = start_date;
    }
    if (end_date) {
      dates.end_date = end_date;
    }
    return request.post(`phases/${id}/shift`, dates, { version: 'f3' });
  },
};
