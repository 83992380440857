import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { Person, PersonType } from '@float/types/person';
import { Placeholder } from '@float/types/placeholder';

type IsNewPlaceholderTypeProps = {
  person: Person;
};

export const isNewPlaceholderType = (props: IsNewPlaceholderTypeProps) => {
  const { person } = props;

  const isPlaceholdersPackagingFeatureEnabled = featureFlags.isFeatureEnabled(
    FeatureFlag.PlaceholdersPackaging,
  );

  return (
    isPlaceholdersPackagingFeatureEnabled &&
    person.people_type_id === PersonType.PLACEHOLDER &&
    person.new_role_placeholder !== Placeholder.Legacy
  );
};
