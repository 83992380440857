import styled, { createGlobalStyle, css } from 'styled-components';

// These are all smaller that the "assetsInlineLimit" and so are inlined
// see https://vitejs.dev/config/build-options.html#build-assetsinlinelimit
import CompleteCursor from '@float/common/assets/cursors/cursor-complete.svg';
import DeleteCursor from '@float/common/assets/cursors/cursor-delete.svg';
import InsertCursor from '@float/common/assets/cursors/cursor-insert.svg';
import LinkCursor from '@float/common/assets/cursors/cursor-link.svg';
import LogTimeCursor from '@float/common/assets/cursors/cursor-log-time.svg';
import ReplaceCursor from '@float/common/assets/cursors/cursor-replace.svg';
import SplitCursor from '@float/common/assets/cursors/cursor-split.svg';
import { Styles as WRPStyles } from '@float/common/components/WeekRangePicker';
import { config } from '@float/libs/config';

import BG from './util/bgimage';
import { TASK_EDIT_MODES } from './util/ContextMenu';

const CURSORS = {
  [TASK_EDIT_MODES.INSERT]: InsertCursor,
  [TASK_EDIT_MODES.SPLIT]: SplitCursor,
  [TASK_EDIT_MODES.REPLACE]: ReplaceCursor,
  [TASK_EDIT_MODES.COMPLETE]: CompleteCursor,
  [TASK_EDIT_MODES.DELETE]: DeleteCursor,
  [TASK_EDIT_MODES.LINK]: LinkCursor,
  [TASK_EDIT_MODES.LOG_TIME]: LogTimeCursor,
};

export function getCursor(actionMode) {
  const mapper = CURSORS;
  return mapper[actionMode];
}

function getGridBackground(dayWidth) {
  if (dayWidth === 27) return null;
  if (dayWidth === 90) return null;
  if (dayWidth === 245) return null;

  return css`
    background: rgba(0, 0, 0, 0)
      linear-gradient(90deg, #edebf0 1px, #ffffff 1px, #ffffff) repeat scroll 0%
      0% / ${dayWidth}px ${dayWidth}px;
  `;
}

export const GlobalStyleOverrides = createGlobalStyle`
  ${() =>
    config.isSafari &&
    `
      html {
        -webkit-user-select: none;
      }
  `}
`;

export const ScrollWrapper = styled.div`
  height: calc(
    var(--screen-height) - var(--app-margin-top, 0px) -
      ${(p) => p.headerHeight}px
  );
  width: 100%;
  overflow: auto;
  will-change: transform;
  background: white;
  position: relative;
  user-select: none;

  #schedule-container.single-user-schedule & {
    width: calc(100vw - 59px);
  }

  #schedule-container.log-time-view & {
    ${(p) => p.dayWidth !== 140 && 'overflow-x: hidden;'}
  }

  @media (max-width: 767px) {
    #schedule-container.single-user-schedule & {
      width: 100%;
      overflow-x: auto;
    }
  }

  @media print {
    height: inherit;
    width: 80in;
  }

  ${(p) =>
    p.disableAnimation &&
    `
      ${RowGroup} {
        transition: none !important;
      }
  `}

  ${() =>
    config.isSafari &&
    `
      ${RowGroup} {
        transition: none !important;
      }
  `}
`;

export const ScheduleContainer = styled.div`
  position: absolute;
  top: -4px;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  div {
    box-sizing: border-box;
  }

  @media (max-width: 850px) {
    ${WRPStyles.RangeLabelContainer} {
      display: none;
    }
  }

  @media (max-width: 980px) {
    ${WRPStyles.TodayButton},
    ${WRPStyles.ArrowsContainer} {
      display: none;
    }

    ${WRPStyles.DateContainer} {
      padding-left: 0;
    }
  }

  @media print {
    position: relative;
  }
`;

export const GridContainer = styled.div`
  background: url(${(p) => BG[`${p.dayWidth}x${p.numDays}`]});
  background-size: ${(p) => p.dayWidth * p.numDays}px
    ${(p) => p.dayWidth * p.numDays}px;
  padding-left: 260px;
  background-position: 260px 0;
  box-sizing: content-box !important;
  border-bottom: 1px solid ${(p) => p.theme.stroke1};

  ${(p) => getGridBackground(p.dayWidth)}

  #schedule-container.single-user-schedule & {
    padding-left: 0px !important;
    background-position: 0 !important;
  }

  @media (max-width: 1010px) {
    padding-left: 64px;
    background-position: 64px 0;
  }

  @media print {
    background: white;
  }
`;

export const StickyTop = styled.div`
  top: 0;
  position: sticky;
  position: -webkit-sticky;
  background: #f7f7f7;
  width: calc(100% - 1px);
  z-index: 1000;
  height: 53px;

  @media print {
    position: absolute;
    left: 260px;
    margin-top: 0px;

    #schedule-container.single-user-schedule & {
      left: 0px;
    }
  }
`;

export const RowGroup = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  transition:
    height 0.25s ease,
    transform 0.25s ease;
  border-top: 1px solid ${({ theme }) => theme.stroke1};

  ${(p) => p.rowType === 'project' && 'box-sizing: content-box !important;'}
  ${(p) => p.isAnyRowDragging && 'border-bottom: 1px solid #cccccc'};
  ${(p) =>
    p.isDragging &&
    css`
      box-shadow: rgba(0, 0, 0, 0.18) 0px 1px 12px 1px;
      background: url(${BG[`${p.dayWidth}x${p.numDays}`]});
      background-size: ${p.dayWidth * p.numDays}px ${p.dayWidth * p.numDays}px;
    `}
`;

export const ContentWrapper = styled.div`
  position: absolute;
  top: 53px;
  left: 260px;
  height: ${(p) => p.height - p.scrollbarSize}px;
  width: calc(100vw - 60px - ${(p) => p.scrollbarSize}px);

  #schedule-container.single-user-schedule & {
    left: 0px !important;
  }

  & > ${RowGroup}:first-child {
    border-top: 0;
  }

  @media screen and (max-width: 1010px) {
    left: 64px;
  }
`;

export const TooltipBoundary = styled.div`
  position: absolute;
  top: ${(p) => p.headerHeight}px;
  left: 260px;
  width: calc(100vw - 260px);
  height: calc(
    var(--screen-height) - var(--app-margin-top, 0px) -
      ${(p) => p.headerHeight}px
  );

  #schedule-container.single-user-schedule & {
    left: 0px !important;
    width: calc(100vw - 60px) !important;
  }

  @media screen and (max-width: 1010px) {
    left: 64px;
    width: calc(100vw - 64px);
  }
`;

export const Content = styled.div`
  position: relative;
`;

export const PinTarget = styled.div`
  height: 0;
  position: sticky;
  left: ${({ isSideMenuDisplayed }) => (isSideMenuDisplayed ? 0 : 260)}px;
  width: 260px;
  z-index: 10000000;
  pointer-events: none;

  @media (max-width: 1010px) {
    left: ${({ isSideMenuDisplayed }) => (isSideMenuDisplayed ? 0 : 64)}px;
    width: 64px;
  }

  &.pin-target-left,
  &.pin-target-right {
    width: 120px;
    height: 24px;
    top: 27px;
  }

  &.pin-target-right {
    left: 100%;
    display: flex;
    justify-content: flex-end;
    transform: translate(-100%, -100%);
  }
`;

export const PlaceholderRow = styled.div`
  position: absolute;
  left: 0;
  background: #ebebeb;
  opacity: 1;
  z-index: 0;
`;

export const SingleUserViewRow = styled.div`
  height: 80px;
  width: 100%;
  background: white;
  border-bottom: 1px solid #cccccc;
  display: flex;
  align-items: center;
  padding: 0 20px;
  transition: width 0.25s ease;
  will-change: width;

  ${(p) =>
    p.isSidebarOpen &&
    css`
      width: calc(100% - 400px);

      @media (max-width: 1365px) {
        ${WRPStyles.TodayButton},
        ${WRPStyles.ArrowsContainer} {
          display: none;
        }

        ${WRPStyles.DateContainer} {
          padding-left: 0;
        }
      }

      @media (max-width: 1200px) {
        ${WRPStyles.RangeLabelContainer} {
          display: none;
        }
      }

      @media (max-width: 1150px) {
        width: 100%;
      }
    `}
`;

export const SideCellShadowLayer = styled.div`
  position: absolute;
  overflow: hidden;
  z-index: 1;
  width: 12px;
  height: 100%;
  top: 0px;
  left: 260px;
  pointer-events: none;
  transition:
    height 0.25s ease,
    transform 0.25s ease;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0px;
    left: -12px;
    width: 12px;
    height: 100%;
    box-shadow:
      0px -2px 4px rgb(0 0 0 / 2%),
      4px -1px 6px rgb(9 30 66 / 7%);
  }

  @media screen and (max-width: 1010px) {
    left: 64px;
  }
`;

export const TimeRangeInsightsContainer = styled.div`
  display: flex;
  ${(p) => !p.singleUserView && 'margin-left: auto;'}

  @media screen and (max-width: 1010px) {
    position: relative;
    top: -20px;
    left: 50px;
  }
`;
