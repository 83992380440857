import { useEffect, useState } from 'react';
import { get } from 'lodash';

import { trackEvent } from '@float/common/lib/gtm';

let priority = 1;

function getKeyAlias(e: KeyboardEvent) {
  const { key } = e;
  if (typeof key !== 'string') {
    return key;
  }
  // Don't try to match if the key is a meta key
  if (e.altKey || key === 'Meta' || key === 'Control') return;
  // consistent delete key value for win/mac
  if (key === 'Backspace' || key === 'Delete') {
    return 'delete';
  }
  // match uppercase characters as lowercase
  if (key.length === 1) {
    return key.toLowerCase();
  }
  return key;
}

const getShortcutKey = (event: KeyboardEvent) => {
  const keyAlias = getKeyAlias(event);
  if (!keyAlias) {
    return null;
  }

  let shortCut = '';
  if (event.metaKey || event.ctrlKey) {
    shortCut += 'cmd+';
  }
  // Only requirement for shift currently is redo (cmd+shift+z)
  // So in all other cases, try to match as an uppercase letter
  if (event.shiftKey && (event.key === 'z' || event.key === 'Z')) {
    shortCut += 'shift+';
  }
  shortCut += keyAlias;
  return shortCut;
};

const isEventFromInput = (event: KeyboardEvent) => {
  const elem = get(event, ['srcElement']) as HTMLElement | null;
  const tag = get(event, ['srcElement', 'tagName']);

  const isFromInput =
    tag === 'INPUT' ||
    tag === 'TEXTAREA' ||
    (elem && elem.hasAttribute('contenteditable'));

  return isFromInput;
};

type KeyMap = Record<string, () => void>;

type HotkeysProps = {
  ignorePriority?: boolean;
  allowInput?: boolean;
  noOverride?: boolean;
  keyMap: KeyMap;
};

export default function Hotkeys(props: HotkeysProps) {
  const [localPriority, setLocalPriority] = useState<number | undefined>();

  function handleKeyDown(e: KeyboardEvent) {
    if (localPriority !== priority && !props.ignorePriority) return;
    if (!props.allowInput && isEventFromInput(e)) return;

    const shortcut = getShortcutKey(e);
    if (shortcut) {
      const callback = get(props, ['keyMap', shortcut]);
      if (callback) {
        e.preventDefault();
        callback();
        trackEvent('Hotkey', {
          shortcut,
        });
      }
    }
  }

  // eslint-disable-next-line
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    if (!props.noOverride) priority += 1;
    if (!localPriority) setLocalPriority(priority);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      if (!props.noOverride) priority -= 1;
    };
  });

  return null;
}
