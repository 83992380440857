import styled from 'styled-components';

import EH from '@float/ui/deprecated/Earhart';

export const StyledRow = styled.div`
  color: ${EH.Colors.FIN.Lt.Emphasis.High};

  display: flex;
  align-items: center;

  & + & {
    margin-top: 20px;
  }

  h5 {
    ${EH.Typography.TextXXL.SB600};
  }

  p {
    ${EH.Typography.TextM.R400};
  }
`;

export const StyledTabItem = styled.li`
  flex: 1;

  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
