import React, { Fragment } from 'react';

import { withComponents } from '../../lib/monorepo-helpers';

const ActivityItemTitle = ({
  actionBy,
  actionLabel,
  actionTarget,
  actionComplete,
  actionLink,
  withFor,
  actionFrom,
  dates = [],
  postFix,
  components: { TopTitle, Text, StrongLabel, ActionLabel },
}) => {
  return (
    <TopTitle>
      <Text>
        {actionBy}{' '}
        <ActionLabel complete={actionComplete}>{actionLabel}</ActionLabel>{' '}
        {!actionComplete &&
          (withFor || actionFrom ? (
            <StrongLabel>{actionTarget}</StrongLabel>
          ) : (
            <ActionLabel>{actionTarget}</ActionLabel>
          ))}
        {!actionComplete &&
          !!actionLink &&
          (withFor ? <Text>{` ${withFor} `}</Text> : <Text>{': '}</Text>)}
        <Text>{actionLink}</Text>
        {!!dates.length && <Text>{dates.length > 1 ? ' from ' : ' on '}</Text>}
        <Text>{dates.join(' - ')}</Text>
        {actionFrom && (
          <Fragment>
            <Text>{' from '}</Text>
            <StrongLabel>{actionFrom}</StrongLabel>
          </Fragment>
        )}
        {!!postFix && <Text>{postFix}</Text>}
      </Text>
    </TopTitle>
  );
};

export default withComponents(ActivityItemTitle);
