const cache = {};
const timers = {};
const defaultExpiryInSeconds = 10;

export const purgeCachedResponse = (key) => {
  if (key) {
    if (timers[key]) {
      clearTimeout(timers[key]);
      delete timers[key];
    }
    delete cache[key];
  }
};

export const setCachedResponse = ({
  key,
  value,
  expiry = defaultExpiryInSeconds,
}) => {
  if (key) {
    if (timers[key]) {
      clearTimeout(timers[key]);
    }
    cache[key] = typeof value === 'string' ? value : JSON.stringify(value);
    timers[key] = setTimeout(() => purgeCachedResponse(key), expiry * 1000);
  }
};

const cachedFetch = (url, params) => {
  let expiry = defaultExpiryInSeconds;
  if (typeof params === 'number') {
    expiry = params;
    params = undefined;
  } else if (typeof params === 'object') {
    expiry = params.seconds || expiry;
  }

  const key = url;
  const cached = cache[key];

  if (cached) {
    const response = new Response(new Blob([cached]));
    return Promise.resolve(response);
  }

  return fetch(url, params).then((response) => {
    if (response.status === 200) {
      const ct = response.headers.get('Content-Type');
      if (ct && (ct.match(/application\/json/i) || ct.match(/text\//i))) {
        // If we don't clone the response, it will be consumed by the time it's returned.
        // This way we're being un-intrusive.
        response
          .clone()
          .text()
          .then((content) => {
            setCachedResponse({ key, value: content, expiry });
          });
      }
    }
    return response;
  });
};

export default cachedFetch;
