import API3 from '@float/common/api3';
import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { logger } from '@float/libs/logger';

import { getViewIdFromSearchParams } from '../../lib/getViewIdFromSearchParams';
import { AppDispatchStrict as AppDispatch } from '../../store';
import { formatView, INITIAL_VIEW_LOADED } from './views';

export const loadInitialView =
  () => async (dispatch: AppDispatch, getState: () => ReduxStateStrict) => {
    const loadState = getState().views?.loadState;

    if (loadState === 'LOADING') return;
    if (loadState === 'LOADED') return;

    const id = getViewIdFromSearchParams(location.search);

    if (id === null) return;

    try {
      const data = await API3.getView({ id });
      const view = formatView(data);

      dispatch({
        type: INITIAL_VIEW_LOADED,
        view,
      });
    } catch (err) {
      logger.error('Failed to fetch initial view', err);

      throw err;
    }
  };
