import React from 'react';

const onFileUpload = (files, onChange) => {
  if (typeof onChange !== 'function') {
    return;
  }

  const file = files && files.length && files[0];
  if (file) {
    onChange(file);
  }
};

const HiddenFileInput = ({ accept = 'image/*', onChange }) => {
  return (
    <input
      type="file"
      accept={accept}
      style={{ display: 'none' }}
      onChange={(evt) => onFileUpload(evt.currentTarget.files, onChange)}
    />
  );
};

export default HiddenFileInput;
