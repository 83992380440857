import {
  ADD_PROJECT_SUCCESS,
  DELETE_PROJECT_SUCCESS,
  FETCH_PROJECTS,
  FETCH_PROJECTS_FAILURE,
  FETCH_PROJECTS_SUCCESS,
} from '../actions/projects';
import {
  DEFAULT_SORT_TABLE,
  getInitialSortableTableState,
  getInitialSortPropsState,
  getInitialStateCreator,
  reducerCreator,
} from './reducerHelpers';

export const lastFetchError = (state) => state.lastFetchError;

export const areBeingFetched = (state) => state.pagesBeingFetched.length > 0;

export const getProjectsByManager = (state, account_id) => {
  return state.entities.filter(
    (project) => project.project_manager === account_id,
  );
};

const getInitialState = getInitialStateCreator(
  Object.assign(
    {
      sortableTables: {
        [DEFAULT_SORT_TABLE]: getInitialSortableTableState('project_id', 'asc'),
      },
    },
    getInitialSortPropsState(['project_id']),
  ),
);

const projects = () =>
  reducerCreator(
    {
      ADD_ENTITY_SUCCESS: ADD_PROJECT_SUCCESS,
      DELETE_ENTITY_SUCCESS: DELETE_PROJECT_SUCCESS,
      FETCH_ENTITIES: FETCH_PROJECTS,
      FETCH_ENTITIES_SUCCESS: FETCH_PROJECTS_SUCCESS,
      FETCH_ENTITIES_FAILURE: FETCH_PROJECTS_FAILURE,
    },
    'project_id',
    getInitialState,
  );

export default projects;
