import React, { CSSProperties, forwardRef, ReactNode } from 'react';

import { StyledModalActions } from './styles';

const ModalActions = forwardRef<
  HTMLDivElement,
  { children?: ReactNode; style?: CSSProperties; className?: string }
>(({ children, style = {}, className }, ref) => {
  return (
    <StyledModalActions className={className} ref={ref} style={style}>
      {children}
    </StyledModalActions>
  );
});

export default ModalActions;
