import { useQuery } from '@tanstack/react-query';

import { Resources } from '../constants';
import { makeRequest } from '../makeRequest';

export enum AccessPermissionItem {
  UIAdminBillingResumeSubscription = 'UI.Admin.Billing.ResumeSubscription',
}

export enum AccessPermissionAction {
  View = 'view',
}

export type RequestAccessPermissionParams = {
  item: AccessPermissionItem;
  action: AccessPermissionAction;
};

export const requestAccessPermissions = async (
  params: RequestAccessPermissionParams,
): Promise<{ hasAccess: boolean; path: string | null }> => {
  const results = await makeRequest<
    [] | { path: string },
    RequestAccessPermissionParams
  >({
    data: params,
    resource: Resources.AccessPermissions,
    method: 'POST',
  });

  return Array.isArray(results)
    ? {
        hasAccess: true,
        path: null,
      }
    : { hasAccess: false, path: results.path };
};

export const useRequestAccessPermissions = (
  params: RequestAccessPermissionParams,
) => {
  const { data, ...rest } = useQuery({
    queryKey: [Resources.AccessPermissions],
    queryFn: () => requestAccessPermissions(params),
  });

  return {
    data: data ?? { hasAccess: false, path: '/' },
    ...rest,
  };
};
