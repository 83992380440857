import { sortBy } from 'lodash';
import { createSelector } from 'reselect';

export const getPublicHolidaysOptions = createSelector(
  (state) => state.publicHolidays.regions,
  (regions) => {
    return sortBy(
      regions.map((r) => ({
        value: r.region_id,
        label: r.name,
      })),
      (o) => o.label.toLowerCase(),
    );
  },
);

export const getDefaultRegion = createSelector(
  (state) => state.publicHolidays.regions,
  (regions) => regions.find((r) => r.is_default) || regions[0],
);
