import {
  FloatBuildMode,
  FloatDeploymentEnvironment,
} from '@float/constants/environment';

// @test-export
export const getServiceUrl = (service: string, environment: string) => {
  const { protocol } = window.location;
  if (environment !== FloatDeploymentEnvironment.Prod) {
    return `${protocol}//${service}.${environment}.float.com`;
  }
  return `${protocol}//${service}.float.com`;
};

export const getSocketServer = (environment: string) => {
  const { protocol } = window.location;
  const isSecure = protocol === 'https:';
  const isLocal = environment === 'local';
  const securePort = isLocal ? 8443 : 443;
  const port = isSecure ? securePort : 8888;

  return `${getServiceUrl('float-notify', environment)}:${port}`;
};

export const getBuildMode = (hostname: string) =>
  /(float.local|local-app.float|dws-app.float|local.float|dws.float)/.test(
    hostname,
  )
    ? FloatBuildMode.Development
    : FloatBuildMode.Production;
