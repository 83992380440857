import { useState } from 'react';
import {
  FieldArrayPath,
  FieldArrayWithId,
  get,
  useFormState,
  useWatch,
} from 'react-hook-form';

import { useFieldArrayContext } from '../../hooks/useFieldArrayContext';
import { ProjectFormData } from '../../types';

const FILTER_COUNT = 10;

export function useShowMoreRows<N extends FieldArrayPath<ProjectFormData>>(
  name: N,
  watchUpdate: keyof FieldArrayWithId<ProjectFormData, N, 'id'>,
) {
  const { fields } = useFieldArrayContext<ProjectFormData, N>();
  const { errors } = useFormState();
  const hasErrorInFartherRow =
    get(errors, name)?.findIndex(Boolean) > FILTER_COUNT - 1;

  // After 10 rows, rest are hidden and  a show more button is shown
  // Eventually this behaviour will be updated to display the full list in a larger view
  // TODO implement logic to display people team list in full view - FT-2272
  // https://linear.app/float-com/issue/FT-2272/ui-create-full-view-for-people-team-list
  const [showFirstTen, setShowFirstTen] = useState(
    fields.length > FILTER_COUNT && !hasErrorInFartherRow,
  );

  const showingFirstTen = showFirstTen && !hasErrorInFartherRow;

  // The input form for new rows is one of the rows (very last row)
  // Need to check if it would be hidden, and if it is, render it separately outside of the loop
  const hasForm = !useWatch<ProjectFormData>({
    name: `${name}.${fields.length - 1}.${String(
      watchUpdate,
    )}` as FieldArrayPath<ProjectFormData>,
  });

  return {
    fields: showingFirstTen ? fields.slice(0, FILTER_COUNT) : fields,
    inputField: showingFirstTen && hasForm && fields[fields.length - 1],
    inputFieldIndex: fields.length - 1,
    showButton: !hasForm && showingFirstTen,
    onClickShow: () => setShowFirstTen(false),
  };
}
