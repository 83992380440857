import React from 'react';
import { connect } from 'react-redux';
import {
  getNonTentativeProjectsOptions,
  getPhasesMapRaw,
  getPhasesOptions,
  getProjectPhases,
  getProjectsMap,
} from 'selectors';

import {
  createMilestone,
  removeMilestone,
  updateMilestone,
} from '@float/common/actions/milestones';
import { moment } from '@float/libs/moment';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { withConfirm } from '@float/ui/deprecated';

import modalManagerHoc, {
  modalManagerConnect,
} from '../../modalManager/modalManagerHoc';
import { MilestoneEditModal } from './ProjectModal/ProjectForm/components/MilestoneEditModal';

class MilestoneModal extends React.Component {
  showingConfirmation = false;

  state = {
    show: false,
  };

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.modalSettings.milestone !== this.props.modalSettings.milestone
    ) {
      this.initialize();
    }
  }

  initialize() {
    const { milestone } = this.props.modalSettings;
    if (this.isAddingMilestoneOnNonWorkDay(milestone)) {
      this.showConfirmationPopup();
    } else {
      this.setState({ show: true });
    }
  }

  handleRemove = () => {
    return this.props.confirm({
      title: 'Remove this milestone?',
      message: <p>Are you sure you want to remove this project milestone?</p>,
      confirmLabel: 'Yes, remove',
      onConfirm: () => {
        this.removeMilestone();
      },
    });
  };

  createOrUpdateMilestone = (milestone) => {
    if (milestone.id || milestone.milestone_id) {
      this.props.updateMilestone(milestone);
    } else {
      this.props.createMilestone(milestone);
    }
  };

  removeMilestone = () => {
    this.props.removeMilestone(this.props.modalSettings.milestone);
    this.hide();
  };

  handleSubmit = (milestone) => {
    this.createOrUpdateMilestone(milestone);
    this.hide();
  };

  hide = (evt) => {
    prevent(evt);
    this.props.manageModal({
      visible: false,
      modalType: 'milestoneModal',
    });
  };

  isNonWorkDay = (date) => {
    return !this.props.isWorkDay(date.format('YYYY-MM-DD'));
  };

  isAddingMilestoneOnNonWorkDay = (milestone) => {
    const { milestone_id, date, end_date } = milestone;
    const isAdding = !milestone_id;
    if (isAdding) {
      return date === end_date && this.isNonWorkDay(moment(date));
    }
    return false;
  };

  showConfirmationPopup() {
    if (this.showingConfirmation) return;

    this.showingConfirmation = true;
    const { event } = this.props.modalSettings;
    const cell = {
      coords: {
        x: event.clientX,
        y: event.clientY < 140 ? 140 : event.clientY, // make sure popup isn't cropped from top
      },
    };
    const modalProps = {
      submitColor: 'green',
      cancelColor: 'gray',
      submitLabel: 'Yes',
      onSubmit: () => {
        this.setState({ show: true });
        this.showingConfirmation = false;
      },
      onCancel: () => {
        this.showingConfirmation = false;
        this.hide();
      },
      cell,
      textSize: 'big',
      newButtons: true,
    };
    this.props.manageModal({
      visible: true,
      modalType: 'errorModal',
      useLegacy: true,
      modalSettings: {
        error: { message: 'Schedule on a non-work day?' },
        props: modalProps,
      },
    });
  }

  render() {
    if (!this.state.show) return null;

    const { milestone, readOnly } = this.props.modalSettings;
    return (
      <MilestoneEditModal
        id={milestone.milestone_id}
        projectId={milestone.project_id}
        phaseId={milestone.phase_id}
        name={milestone.name}
        startDate={moment(milestone.date)}
        endDate={moment(milestone.end_date)}
        isNonWorkDay={this.isNonWorkDay}
        onUpdate={this.handleSubmit}
        onCancel={this.hide}
        onRemove={this.handleRemove}
        allowProjectSelection={this.props.modalSettings.allowProjectSelection}
        allowPhaseSelection={this.props.modalSettings.allowPhaseSelection}
        projects={this.props.projects}
        phases={this.props.phases}
        projectPhases={this.props.projectPhases}
        projectsOptions={this.props.projectsOptions}
        phasesOptions={this.props.phasesOptions}
        readOnly={readOnly}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  projects: getProjectsMap(state),
  phases: getPhasesMapRaw(state),
  projectPhases: getProjectPhases(state),
  projectsOptions: getNonTentativeProjectsOptions(state),
  phasesOptions: getPhasesOptions(state),
});

const mapDispatchToProps = (dispatch) => ({
  createMilestone: (milestone) => {
    dispatch(createMilestone(milestone));
  },
  updateMilestone: (milestone) => {
    dispatch(updateMilestone(milestone));
  },
  removeMilestone: (milestone) => {
    dispatch(removeMilestone(milestone));
  },
});

const Component = modalManagerConnect(withConfirm(MilestoneModal));

export default modalManagerHoc({
  Comp: connect(mapStateToProps, mapDispatchToProps)(Component),
  modalType: 'milestoneModal',
});
