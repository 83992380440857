import { RawLoggedTime } from '@float/types/loggedTime';
import { RawTask } from '@float/types/task';
import { RawTimeoff } from '@float/types/timeoff';

import {
  LOGGED_TIME_BULK_CREATED,
  LOGGED_TIME_CREATED,
} from '../../actions/loggedTimes';
import { CREATE_TASK_SUCCESS, SPLIT_TASK } from '../../actions/tasks';
import { CREATE_TIMEOFF_SUCCESS } from '../../actions/timeoffsConstants';
import {
  searchResultsUpdateAction,
  SearchResultsUpdateAction,
} from './searchResults.actions';

export const DEFAULT_STATE = {
  active: false,
  tasks: new Set<string>(),
  loggedTimes: new Set<string>(),
  timeoffs: new Set<string>(),
  people: new Set<number>(),
  projects: new Set<number>(),
};

type SearchResultsAction =
  | SearchResultsUpdateAction
  | {
      type: typeof CREATE_TASK_SUCCESS;
      item: RawTask;
      isLiveUpdate?: boolean;
    }
  | {
      type: typeof SPLIT_TASK;
      newTask?: RawTask;
    }
  | {
      type: typeof CREATE_TIMEOFF_SUCCESS;
      item: RawTimeoff;
    }
  | {
      type: typeof LOGGED_TIME_CREATED | typeof LOGGED_TIME_BULK_CREATED;
      loggedTime: RawLoggedTime | RawLoggedTime[];
      createdAt: string;
    };

export function searchResults(
  state = DEFAULT_STATE,
  action: SearchResultsAction,
) {
  switch (action.type) {
    case searchResultsUpdateAction.type: {
      return {
        ...state,
        ...action.payload,
        active: true,
      };
    }

    // When a new task, timeoff or logged time is created
    // we optimistically add them to the search results
    // to avoid glitches in the Schedule during the creation process.
    case CREATE_TASK_SUCCESS: {
      if (!state.active) {
        return state;
      }

      const tasks = new Set(state.tasks);
      tasks.add(String(action.item.task_id));

      return { ...state, tasks };
    }

    case SPLIT_TASK: {
      if (!state.active || !action.newTask) {
        return state;
      }

      const tasks = new Set(state.tasks);
      tasks.add(String(action.newTask.task_id));

      return { ...state, tasks };
    }

    case CREATE_TIMEOFF_SUCCESS: {
      if (!state.active) {
        return state;
      }

      const timeoffs = new Set(state.timeoffs);
      timeoffs.add(String(action.item.timeoff_id));

      return { ...state, timeoffs };
    }

    case LOGGED_TIME_CREATED:
    case LOGGED_TIME_BULK_CREATED: {
      if (!state.active) {
        return state;
      }

      const loggedTimes = new Set(state.loggedTimes);

      const items = Array.isArray(action.loggedTime)
        ? action.loggedTime
        : [action.loggedTime];

      for (const item of items) {
        loggedTimes.add(String(item.logged_time_id));
      }

      return { ...state, loggedTimes };
    }
  }

  return state;
}
