import React from 'react';
import { Trans } from '@lingui/macro';

import { IconCalendarTimer } from '@float/ui/icons/essentials/IconCalendarTimer';

import * as styles from './styles.css';

export function AnchorDateInfo() {
  return (
    <div className={styles.anchorDateInfo}>
      <IconCalendarTimer />
      <span className={styles.anchorDateInfoText}>
        <Trans>
          Select a start date to to set other dates based on offsets and
          durations
        </Trans>
      </span>
    </div>
  );
}
