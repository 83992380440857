import { useQuery } from '@tanstack/react-query';

import { Company } from '@float/types/company';

import { Resources } from './constants';
import { makeRequest, MakeRequestPagination } from './makeRequest';

export type MyCompanies = {
  companies: Company[];
  email: string;
  name: string;
};

export const requestMyCompanies = (): Promise<MyCompanies> =>
  makeRequest<MyCompanies>({
    resource: Resources.MyCompanies,
    method: 'GET',
    pagination: MakeRequestPagination.Off,
  });

export function useMyCompanies() {
  const { data, ...rest } = useQuery({
    queryKey: [Resources.MyCompanies],
    queryFn: requestMyCompanies,
  });

  return {
    data: data ?? {
      companies: [],
      email: '',
      name: '',
    },
    ...rest,
  };
}
