import { forEach, map, transform } from 'lodash';

import {
  filterLinkFormatter,
  pctFormatter,
  rangeBarFormatter,
} from '../../../helpers/tableFormatters';
import { DualRangeBarFormatter } from '../DualRangBarFormatter';
import {
  getCombinedRangeBar,
  getLoggedRangeBar,
  getScheduledRangeBar,
} from '../table.helpers';
import { getPctHeader, getPctValue } from './_percentages';
import { buildDepartmentNameCell } from './departments';

function breakdown(ctx, raw) {
  const { projects } = ctx;

  const byPeople = transform(raw.capacity, (acc, cap, personId) => {
    acc[personId] = {
      capacity: cap,
      scheduled: 0,
      billable: 0,
      nonbillable: 0,
      tentative: {
        billable: 0,
        nonbillable: 0,
      },
      logged: {
        scheduled: 0,
        billable: 0,
        nonbillable: 0,
        overtime: 0,
      },
      combined: {
        scheduled: 0,
        billable: 0,
        nonbillable: 0,
        overtime: 0,
      },
      future: {
        scheduled: 0,
        billable: 0,
        nonbillable: 0,
        overtime: 0,
        tentative: {
          billable: 0,
          nonbillable: 0,
        },
      },
      timeoff: 0,
      overtime: 0,
      children: {},
    };
  });

  forEach(raw.overtime, (ot, personId) => {
    if (byPeople[personId]) {
      byPeople[personId].overtime = ot.total;
      byPeople[personId].logged.overtime = ot.logged;
      byPeople[personId].combined.overtime = ot.logged + ot.future;
    }
  });

  raw.totals.forEach((item) => {
    const record = byPeople[item.person_id];
    if (!record) return;

    if (item.project_id && !projects[item.project_id]) {
      console.error('No project found', { item });
      return;
    }

    // We want to aggregate the children by either the project id
    // or the timeoff name.
    let key;
    if (item.type === 'timeoff') {
      key = `timeoff:${item.name}`;
    } else if (item.type === 'task' || item.type === 'logged_time') {
      key = `task:${item.project_id}`;
    } else {
      throw Error('Unknown type', item);
    }

    if (!record.children[key]) {
      record.children[key] = {
        type: item.type,
        project_id: item.project_id,
        timeoffName: item.type === 'timeoff' && item.name,
        scheduled: 0,
        billable: 0,
        nonbillable: 0,
        logged: {
          scheduled: 0,
          billable: 0,
          nonbillable: 0,
        },
        combined: {
          scheduled: 0,
          billable: 0,
          nonbillable: 0,
        },
        timeoff: 0,
      };
    }

    const child = record.children[key];

    // Record refers to the parent row (people).
    // Child refers to the child row (project/timeoff).
    // The "root" suffix is used to get either the task/logged time data
    if (item.type === 'timeoff') {
      child.timeoff += item.hours.scheduled;
      record.timeoff += item.hours.scheduled;
    } else if (item.type === 'task' || item.type === 'logged_time') {
      if (item.type === 'logged_time' && item.date >= ctx.loggedTimeBoundary) {
        return;
      }

      const childRoot = item.type === 'task' ? child : child.logged;
      const recordRoot = item.type === 'task' ? record : record.logged;

      childRoot.scheduled += item.hours.scheduled;
      recordRoot.scheduled += item.hours.scheduled;
      record.combined.scheduled +=
        item.type === 'task' ? item.hours.future : item.hours.scheduled;
      record.future.scheduled += item.hours.future;
      child.combined.scheduled +=
        item.type === 'task' ? item.hours.future : item.hours.scheduled;

      if (item.billable) {
        childRoot.billable += item.hours.scheduled;
        recordRoot.billable += item.hours.scheduled;
        record.combined.billable +=
          item.type === 'task' ? item.hours.future : item.hours.scheduled;
        record.future.billable += item.hours.future;
        child.combined.billable +=
          item.type === 'task' ? item.hours.future : item.hours.scheduled;

        if (item.tentative) {
          record.tentative.billable += item.hours.scheduled;
          record.future.tentative.billable +=
            item.type === 'task' ? item.hours.future : item.hours.scheduled;
        }
      } else {
        childRoot.nonbillable += item.hours.scheduled;
        recordRoot.nonbillable += item.hours.scheduled;
        record.combined.nonbillable +=
          item.type === 'task' ? item.hours.future : item.hours.scheduled;
        record.future.nonbillable += item.hours.future;
        child.combined.nonbillable +=
          item.type === 'task' ? item.hours.future : item.hours.scheduled;

        if (item.tentative) {
          record.tentative.nonbillable += item.hours.scheduled;
          record.future.tentative.nonbillable +=
            item.type === 'task' ? item.hours.future : item.hours.scheduled;
        }
      }
    }
  });

  return byPeople;
}

export function getScheduledTable(ctx, raw) {
  const { people, projects, departments } = ctx;

  const headers = [
    {
      label: 'Person',
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    {
      label: 'Department',
      align: 'flex-start',
      width: 150,
      formatter: filterLinkFormatter,
    },
    { label: 'Capacity', width: 100 },
    { label: 'Scheduled', width: 100 },
    { label: 'Billable', width: 100 },
    { label: 'Non-billable', width: 100 },
    { label: 'Time off', width: 80 },
    { label: 'Overtime', width: 100 },
    {
      label: getPctHeader(ctx, 'scheduled'),
      width: 120,
      formatter: pctFormatter,
    },
    {
      label: '',
      width: 135,
      formatter: rangeBarFormatter,
      allowOverflow: true,
    },
  ];

  if (!raw) return { headers, rows: [] };

  const byPeople = breakdown(ctx, raw);

  const rows = map(byPeople, (o, id) => {
    const person = people[id];

    // https://rollbar.com/float/fe-web-app/items/4583/
    if (!person) return null;

    return {
      id,
      data: [
        `person::${person.name}`,
        buildDepartmentNameCell(departments[person.department_id]),
        o.capacity,
        o.scheduled,
        o.billable,
        o.nonbillable,
        o.timeoff,
        o.overtime,
        getPctValue(ctx, o, 'scheduled'),
        getScheduledRangeBar(o),
      ],
      children: map(o.children, (c) => {
        const name = c.project_id
          ? projects[c.project_id].long_name
          : c.timeoffName;
        return {
          id: name,
          data: [
            name,
            '',
            '-',
            c.type === 'timeoff' ? '-' : c.scheduled,
            c.type === 'timeoff' ? '-' : c.billable,
            c.type === 'timeoff' ? '-' : c.nonbillable,
            c.type === 'timeoff' ? c.timeoff : '-',
            '-',
            '',
            '',
          ],
        };
      }),
    };
  }).filter((row) => row !== null);

  return { headers, rows };
}

export function getLoggedTable(ctx, raw) {
  const { people, projects, departments } = ctx;

  const headers = [
    {
      label: 'Person',
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    {
      label: 'Department',
      align: 'flex-start',
      width: 150,
      formatter: filterLinkFormatter,
    },
    { label: 'Capacity', width: 100 },
    { label: 'Logged', width: 100 },
    { label: 'Billable', width: 100 },
    { label: 'Non-billable', width: 100 },
    { label: 'Time off', width: 80 },
    { label: 'Overtime', width: 100 },
    {
      label: getPctHeader(ctx, 'logged'),
      width: 120,
      formatter: pctFormatter,
    },
    {
      label: '',
      width: 135,
      formatter: rangeBarFormatter,
      allowOverflow: true,
    },
  ];

  if (!raw) return { headers, rows: [] };

  const byPeople = breakdown(ctx, raw);

  const rows = map(byPeople, (o, id) => {
    const person = people[id];
    return {
      id,
      data: [
        `person::${person.name}`,
        buildDepartmentNameCell(departments[person.department_id]),
        o.capacity,
        o.logged.scheduled,
        o.logged.billable,
        o.logged.nonbillable,
        o.timeoff,
        o.logged.overtime,
        getPctValue(ctx, o, 'logged'),
        getLoggedRangeBar(o),
      ],
      children: map(o.children, (c, idx) => {
        const name = c.project_id
          ? projects[c.project_id].long_name
          : c.timeoffName;
        return {
          id: name,
          data: [
            name,
            '',
            '-',
            c.type === 'timeoff' ? '-' : c.logged.scheduled,
            c.type === 'timeoff' ? '-' : c.logged.billable,
            c.type === 'timeoff' ? '-' : c.logged.nonbillable,
            c.type === 'timeoff' ? c.timeoff : '-',
            '-',
            '',
            '',
          ],
        };
      }),
    };
  });

  return { headers, rows };
}

export function getCompareTable(ctx, raw) {
  const { people, projects, departments } = ctx;

  const headers = [
    {
      label: 'Person',
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    {
      label: 'Department',
      align: 'flex-start',
      width: 150,
      formatter: filterLinkFormatter,
    },
    { label: 'Capacity', width: 100 },
    { label: 'Scheduled', width: 200 },
    { label: 'Logged', width: 200 },
    { label: 'Difference', width: 200 },
    {
      label: '',
      width: 135,
      formatter: DualRangeBarFormatter,
      allowOverflow: true,
    },
  ];

  if (!raw) return { headers, rows: [] };

  const byPeople = breakdown(ctx, raw);

  const rows = map(byPeople, (o, id) => {
    const person = people[id];

    if (!person) return;

    return {
      id,
      data: [
        `person::${person.name}`,
        buildDepartmentNameCell(departments[person.department_id]),
        o.capacity,
        o.scheduled,
        o.logged.scheduled,
        o.scheduled - o.logged.scheduled,
        [getLoggedRangeBar(o), getScheduledRangeBar(o)],
      ],
      children: map(o.children, (c) => {
        if (c.type === 'timeoff') return null;

        const name = c.project_id
          ? projects[c.project_id].long_name
          : c.timeoffName;

        return {
          id: name,
          data: [
            name,
            '',
            '-',
            c.scheduled,
            c.logged.scheduled,
            c.scheduled - c.logged.scheduled,
            '',
          ],
        };
      }).filter(Boolean),
    };
  }).filter(Boolean);

  return { headers, rows };
}

export function getCombinedTable(ctx, raw) {
  const { people, projects, departments } = ctx;

  const headers = [
    {
      label: 'Person',
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    {
      label: 'Department',
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    { label: 'Capacity', width: 80 },
    { label: 'Past logged + Future sched.', width: 200 },
    { label: 'Billable', width: 80 },
    { label: 'Non-billable', width: 100 },
    { label: 'Time off', width: 80 },
    { label: 'Overtime', width: 80 },
    {
      label: getPctHeader(ctx, 'combined'),
      width: 120,
      formatter: pctFormatter,
    },
    {
      label: '',
      width: 135,
      formatter: rangeBarFormatter,
      allowOverflow: true,
    },
  ];

  if (!raw) return { headers, rows: [] };

  const byPeople = breakdown(ctx, raw);

  const rows = map(byPeople, (o, id) => {
    const person = people[id];

    // sometimes person is undefined (maybe happens when a person has just delated & the reports returns a stale capacity)
    // https://rollbar.com/float/fe-web-app/items/2179/
    if (!person) return null;

    return {
      id,
      data: [
        `person::${person.name}`,
        buildDepartmentNameCell(departments[person.department_id]),
        o.capacity,
        o.combined.scheduled,
        o.combined.billable,
        o.combined.nonbillable,
        o.timeoff,
        o.combined.overtime,
        getPctValue(ctx, o, 'combined'),
        getCombinedRangeBar(o),
      ],
      children: map(o.children, (c) => {
        const name = c.project_id
          ? projects[c.project_id].long_name
          : c.timeoffName;
        return {
          id: name,
          data: [
            name,
            '',
            '-',
            c.type === 'timeoff' ? '-' : c.combined.scheduled,
            c.type === 'timeoff' ? '-' : c.combined.billable,
            c.type === 'timeoff' ? '-' : c.combined.nonbillable,
            c.type === 'timeoff' ? c.timeoff : '-',
            '-',
            '',
            '',
          ],
        };
      }),
    };
  }).filter(Boolean);

  return { headers, rows };
}
