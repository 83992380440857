import { AGClientSocket } from 'socketcluster-client';
import {
  ActorRefFromLogic,
  assign,
  emit,
  not,
  raise,
  sendTo,
  setup,
} from 'xstate';

import { SearchResolveParams, SearchResolveResult } from '../../api/resolveApi';
import { RETRY_BASE_TIMEOUT } from './constants';
import {
  contextSubscribeLogic,
  ContextSubscribeOutEvents,
  searchResolveApiLogic,
} from './SearchRemoteResolveMachine.logic';
import { SocketAuthenticationMachine } from './SocketAuthenticationMachine';

export const SearchRemoteResolveMachine = setup({
  types: {
    context: {} as {
      authMachine: ActorRefFromLogic<typeof SocketAuthenticationMachine>;
      contextId: string | null;
      searchResolveParams: SearchResolveParams | null;
      socket: AGClientSocket;
      resolveAttempts: number;
      revalidating: boolean;
    },
    input: {} as {
      socket: AGClientSocket;
    },
    events: {} as
      | {
          // A new JWT token has been issued
          // so we forward it to the Context distributor WebSocket
          type: 'authTokenReceived';
          payload: string;
        }
      | { type: 'socketAuthSuccessful' }
      | {
          // We need to start a new search flow
          type: 'searchParamsUpdated';
          payload: SearchResolveParams;
        }
      | {
          // When there are no filters
          // we don't rely on the remote resolution
          // so we turn it off
          type: 'searchParamsUpdatedWithNoFilters';
        }
      | {
          // The current resolution has been successful!
          type: 'searchResolveSuccessful';
        }
      | {
          type: 'searchContextRevalidationRequested';
        }
      | ContextSubscribeOutEvents,
    emitted: {} as {
      // We've got new results, so we send them to our spectators
      type: 'searchResultsUpdated';
      payload: SearchResolveResult['result'];
    },
  },
  actors: {
    searchResolve: searchResolveApiLogic,
    contextSubscribe: contextSubscribeLogic,
    authMachine: SocketAuthenticationMachine,
  },
  guards: {
    isSocketAuthenticated: ({ context: { authMachine } }) =>
      authMachine.getSnapshot().matches('authenticated'),
  },
  delays: {
    resolveRetryTimeout: ({ context }) =>
      context.resolveAttempts * RETRY_BASE_TIMEOUT,
  },
}).createMachine({
  context: ({ input, spawn }) => ({
    contextId: null,
    searchResolveParams: null,
    authMachine: spawn('authMachine', {
      input: {
        socket: input.socket,
      },
      id: 'authMachine',
    }),
    socket: input.socket,
    resolveAttempts: 1,
    // Using a context variable to track if the current resolution is a revalidation
    // to make sure that the failure retries are also considered revalidations
    revalidating: false,
  }),
  initial: 'waiting',
  on: {
    // Authenticate to the socket using the latest JWT
    authTokenReceived: {
      actions: sendTo('authMachine', ({ event }) => ({
        type: 'authenticate',
        payload: event.payload,
      })),
    },
    // A searchParamsUpdated request needs to be handled at every state
    // so whatever the machine is doing he must cancel it
    // and go into the resolving state
    searchParamsUpdated: {
      actions: assign({
        searchResolveParams: ({ event }) => event.payload,
        revalidating: false,
        resolveAttempts: 1,
      }),
      target: '.resolving',
      reenter: true,
    },
    // When switching to a local resolution
    // we don't care about any pending result
    // and go back to waiting
    searchParamsUpdatedWithNoFilters: {
      actions: assign({
        searchResolveParams: null,
      }),
      target: '.waiting',
    },
  },
  states: {
    // Wait for the global transitions to take effect
    waiting: {},
    resolveFailed: {
      after: {
        resolveRetryTimeout: {
          target: 'resolving',
        },
      },
    },
    resolving: {
      invoke: {
        id: 'searchResolve',
        src: 'searchResolve',
        input: ({
          context: { searchResolveParams, revalidating, contextId },
        }) => {
          if (searchResolveParams && revalidating) {
            return {
              ...searchResolveParams,
              contextId: contextId ?? undefined,
            };
          }
          return searchResolveParams;
        },
        onDone: {
          actions: [
            assign({
              contextId: ({ event }) => event.output.contextId,
              resolveAttempts: 1,
              revalidating: false,
            }),
            emit(({ event }) => ({
              type: 'searchResultsUpdated',
              payload: event.output.result,
            })),
            raise({
              type: 'searchResolveSuccessful',
            }),
          ],
        },
        onError: {
          target: 'resolveFailed',
          actions: assign({
            resolveAttempts: ({ context }) => context.resolveAttempts + 1,
          }),
        },
      },
      on: {
        searchResolveSuccessful: [
          {
            guard: 'isSocketAuthenticated',
            target: 'subscribed',
          },
          {
            guard: not('isSocketAuthenticated'),
            target: 'waitingForSocketAuth',
          },
        ],
      },
    },
    waitingForSocketAuth: {
      on: {
        socketAuthSuccessful: {
          target: 'subscribed',
        },
        searchContextRevalidationRequested: {
          target: 'resolving',
          actions: assign({
            // We track revalidation through this flag
            // to pass the contextId to the resolve request
            revalidating: true,
          }),
        },
      },
    },
    subscribed: {
      invoke: {
        src: 'contextSubscribe',
        input: ({ context }) => ({
          socket: context.socket,
          contextId: context.contextId,
        }),
      },
      on: {
        // When the context expires we need to request a new search resolution
        searchContextExpired: {
          target: 'resolving',
          actions: assign({
            revalidating: false,
          }),
        },
        searchContextRevalidationRequested: {
          target: 'resolving',
          actions: assign({
            revalidating: true,
          }),
        },
        searchResultsUpdated: {
          // Got some updates, let's notify our spectators!
          actions: emit(({ event }) => ({
            type: 'searchResultsUpdated',
            payload: event.payload,
          })),
        },
      },
    },
  },
});
