import { toLongFriendlyTime } from '@float/common/lib/time';
import { moment } from '@float/libs/moment';
import { CompanyPreferences } from '@float/types/companyPreferences';

export function formatTimestamp(
  timestamp: number | undefined,
  companyPrefs: CompanyPreferences,
) {
  const date = moment(timestamp);
  return (
    date.format('MMM DD YYYY, ') +
    toLongFriendlyTime(date.format('HH:mm'), companyPrefs)
  );
}
