import { useLocation } from 'react-router';

import { TAG_TYPE } from '@float/common/selectors/tags';

export function useAdminTagsType() {
  const location = useLocation();

  if (location.pathname.startsWith('/admin/tags/projects')) {
    return TAG_TYPE.PROJECT;
  }

  // Return people type by default
  return TAG_TYPE.PEOPLE;
}
