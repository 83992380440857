import React from 'react';

import { ReportsCheckboxTooltip } from './CheckboxTooltip';

export const StatusFilter = function ({
  updateSettings,
  status,
  innerLabel,
  outerLabel,
  items,
}) {
  const checked = items.reduce(
    (acc, key) => ({ ...acc, [key]: status.includes(key) }),
    {},
  );

  return (
    <ReportsCheckboxTooltip
      placement="bottom-start"
      updateSettings={updateSettings}
      items={items}
      outerLabel={outerLabel}
      innerLabel={innerLabel}
      checked={checked}
    />
  );
};

export function getTaskStatusString({ taskStatus, max = 3 }) {
  if (taskStatus.length == max) return 'All';

  return taskStatus.join(', ');
}
