import { entityEditable } from '@float/common/lib/rights';

import { TASK_EDIT_MODES } from '../util/ContextMenu';

export function isEligibleLinkSource(reduxData, item) {
  const target = item.entity;

  if (item.type !== 'task') return false;
  if (target.integration_status > 0) return false;
  if (target.repeat_state > 0) return false;

  return entityEditable(item.entity, reduxData.user, reduxData);
}

export function isEligibleLinkTarget(linkInfo, item) {
  if (item.type !== 'task') return [false, 'NOT_TASK'];

  const source = linkInfo.base.entity;
  const target = item.entity;

  if (target.task_id == source.task_id) return [false, 'IS_SELF'];
  if (target.parent_task_id) return [false, 'HAS_PARENT'];
  if (target.integration_status > 0) return [false, 'IS_SYNCED'];
  if (target.repeat_state > 0) return [false, 'IS_REPEAT'];
  if (target.start_date < source.start_date) return [false, 'IS_IN_PAST'];

  // We've already checked the project permissions on the link source,
  // so we just have to ensure they're using the same project.
  if (target.project_id != source.project_id) return [false, 'DIFF_PROJECT'];
  if (source.phase_id !== target.phase_id) return [false, 'DIFF_PHASE'];

  return [true];
}

export function isLinkRemovable(reduxData, item) {
  if (item.type !== 'task') return false;

  if (!reduxData.projects[item.entity.project_id]?.active) {
    return false;
  }

  return entityEditable(item.entity, reduxData.user, reduxData);
}

export function handleLinkCancel(props) {
  const { setActionMode } = props;
  setActionMode(TASK_EDIT_MODES.ADD_EDIT);
}

export function handleLinkClick(props, item) {
  const {
    cells,
    dispatch,
    linkInfo,
    setLinkInfo,
    mousePositionRef,
    reduxData,
  } = props;

  if (!linkInfo) {
    if (isEligibleLinkSource(reduxData, item)) {
      setLinkInfo({ base: cells._helpers.getLinkSourceItem(item) });
      return;
    } else {
      return handleLinkCancel(props);
    }
  }

  if (!isEligibleLinkTarget(linkInfo, item)[0]) {
    return handleLinkCancel(props);
  }

  dispatch({
    type: 'LINK_CONFIRM',
    parent: linkInfo.base,
    child: item,
  });

  setLinkInfo(null);
  mousePositionRef.current.eligibleLinkTarget = null;
}

export function handleConnectorClick(props, item) {
  const { dispatch } = props;

  dispatch({
    type: 'LINK_DELETE',
    item,
  });
}
