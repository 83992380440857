import { connect } from 'react-redux';
import manageModal from 'modalManager/manageModalActionCreator';

import request from '@float/common/lib/request';

const SLACK_LOAD_OAUTH_ERROR = 'SLACK_LOAD_OAUTH_ERROR';
const SLACK_LOAD_OAUTH_SUCCESS = 'SLACK_LOAD_OAUTH_SUCCESS';
const SLACK_LOAD_OAUTH_START = 'SLACK_LOAD_OAUTH_START';

function queryServer({ code, redirectUri }) {
  const params = code
    ? {
        'slack-onboarding-code': JSON.stringify({
          code,
          redirect_uri: redirectUri,
        }),
      }
    : { 'slack-lookup': true };
  return request.get('activities', params, { version: '', hostname: '' });
}

export const slackRequestOAuth = (code, redirectUri) => {
  return (dispatch, getState) => {
    const state = getState();
    if (state.slack.loading || code === state.slack.code) {
      return Promise.resolve(code);
    }
    dispatch({
      type: 'SLACK_LOAD_OAUTH_START',
      code,
    });
    return queryServer({ code, redirectUri })
      .then((response) => {
        dispatch({
          type: 'SLACK_LOAD_OAUTH_SUCCESS',
          code,
          response,
        });
        return code ? 'success' : code;
      })
      .catch((error) => {
        dispatch({
          type: 'SLACK_LOAD_OAUTH_ERROR',
          code,
          error,
        });
        return 'error';
      });
  };
};

export const slackReducer = (
  state = {
    loading: false,
    response: null,
    error: null,
    code: null,
  },
  action,
) => {
  switch (action.type) {
    case SLACK_LOAD_OAUTH_ERROR: {
      return {
        loading: false,
        code: action.code,
        response: action.response,
        error: null,
      };
    }
    case SLACK_LOAD_OAUTH_SUCCESS: {
      return {
        loading: false,
        code: action.code,
        response: action.response,
        error: null,
      };
    }
    case SLACK_LOAD_OAUTH_START: {
      return {
        loading: true,
        code: action.code,
        response: null,
        error: null,
      };
    }
    default:
      return state;
  }
};

export const slackConnect = connect(
  (state) => {
    return {
      cid: state.currentUser && state.currentUser.cid,
      slack: state.slack,
    };
  },
  {
    slackRequestOAuth,
    manageModal,
  },
);
