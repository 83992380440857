import { getAreDatesOverlapping } from '@float/libs/datesRepeated/getAreDatesOverlapping';
import { speculativelyCheckIfRepeatableEntityIntersectsWithDateRange } from '@float/libs/datesRepeated/speculativelyCheckIfRepeatableEntityIntersectsWithDateRange';
import { Task } from '@float/types/task';
import { Timeoff } from '@float/types/timeoff';

import { TimeRangeInsightsParams } from '../types';

// Check if a repeable entity may fall within the time range
export function isRepeatableEntityWithinTheTimeRange(
  entity: Task | Timeoff,
  dates: DatesManager,
  params: Omit<TimeRangeInsightsParams, 'type'>,
) {
  const { startDate, endDate } = params;

  // OPTIMIZATION: Computing the repeated state here would be too slow
  // so we go for the "worst case" where the last repeat state falls right
  // on the repeat_end_date.
  //
  // Meant to be used inside the time range insights selectors where we check
  // if every date matches the selected range
  if (entity.repeat_end_date) {
    return speculativelyCheckIfRepeatableEntityIntersectsWithDateRange(
      entity,
      dates,
      startDate,
      endDate,
    );
  }

  return getAreDatesOverlapping(
    entity.start_date,
    entity.end_date,
    startDate,
    endDate,
  );
}
