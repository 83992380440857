import { shouldShowActivityByDefault } from '@float/common/lib/utils';
import { TOGGLE_PM_SIDEBAR } from '@float/web/pmSidebar/actions';

import {
  ACTIVITY_FETCH_RECEIVED,
  ACTIVITY_FETCHING,
  ACTIVITY_HALT,
  ACTIVITY_SEEN_UPDATE,
  RESET_ACTIVITY,
  TOGGLE_ACTIVITY_FEED,
  UPDATE_ACTIVITY_PAGE,
} from '../actions';
import determineActivityItemsSeen from './determineActivityItemsSeen';

const activity = (
  state = {
    activityCollections: {},
    seen: {},
    visible: shouldShowActivityByDefault(),
  },
  action,
) => {
  const { seen } = state;
  let activityCollections = { ...state.activityCollections };
  let specialCollection = {};
  if (action.specialName) {
    specialCollection = activityCollections[action.specialName] || {};
  }
  switch (action.type) {
    case TOGGLE_ACTIVITY_FEED: {
      return {
        ...state,
        visible: action.visible || !state.visible,
      };
    }
    case TOGGLE_PM_SIDEBAR:
      return {
        ...state,
        visible: false,
      };
    case ACTIVITY_FETCHING: {
      activityCollections[action.specialName] = {
        ...specialCollection,
        isFetching: true,
      };
      return {
        ...state,
        activityCollections,
      };
    }
    case ACTIVITY_FETCH_RECEIVED: {
      const { allFetched, currentUserAccountId } = action;
      const grouped = action.grouped || [];

      const seenDetermination = determineActivityItemsSeen({
        items: grouped,
        allSeenItems: seen,
        currentUserAccountId,
      });

      activityCollections[action.specialName] = {
        ...specialCollection,
        isFetching: false,
        hasMore: action.hasMore,
        allFetched,
        items: seenDetermination.items,
        allSeen: seenDetermination.allSeen,
        initialFetchComplete: true,
      };

      const latestActivityTimestamp =
        typeof action.latestActivityTimestamp === 'undefined'
          ? state.latestActivityTimestamp
          : action.latestActivityTimestamp;

      return {
        ...state,
        activityCollections,
        latestActivityTimestamp,
      };
    }
    case ACTIVITY_HALT: {
      activityCollections[action.specialName] = {
        ...specialCollection,
        halted: true,
      };
      return {
        ...state,
        activityCollections,
      };
    }
    case RESET_ACTIVITY: {
      activityCollections[action.specialName] = {
        ...activityCollections[action.specialName],
        isFetching: false,
        filterer: action.filterer || specialCollection.filterer,
        allFetched: [],
        page: 1,
        initialFetchComplete: false,
        initialTotal: null,
        halted: false,
        sortOrder: action.sortOrder || specialCollection.sortOrder,
      };
      return {
        ...state,
        activityCollections,
      };
    }
    case UPDATE_ACTIVITY_PAGE: {
      activityCollections[action.specialName] = {
        ...specialCollection,
        page: action.page,
      };
      return {
        ...state,
        activityCollections,
      };
    }

    case ACTIVITY_SEEN_UPDATE: {
      activityCollections = Object.keys(activityCollections).reduce(
        (accum, companyKey) => {
          const items = activityCollections[companyKey].items || [];
          const { seen, currentUserAccountId } = action;
          const grouped = determineActivityItemsSeen({
            items,
            allSeenItems: seen,
            currentUserAccountId,
          });
          accum[companyKey] = {
            ...activityCollections[companyKey],
            items: grouped.items,
            allSeen: grouped.allSeen,
          };
          return accum;
        },
        {},
      );
      return {
        ...state,
        activityCollections,
        seen: action.seen,
      };
    }
    default:
      return state;
  }
};

export default activity;
