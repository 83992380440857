import React from 'react';

import { ReduxState } from '@float/common/reducers/lib/types';
import { getTimersByLoggedTime } from '@float/common/selectors/timer';
import { useAppSelector } from '@float/common/store';

import { StyledLogTimeInputAndTimer, StyledTimerEntryList } from './styles';
import { LogTimeInputProps } from './types';

function LogTimeInputs(props: LogTimeInputProps) {
  const timers = useAppSelector((state: ReduxState) =>
    props.entity.logged_time_id
      ? getTimersByLoggedTime(state, props.entity.logged_time_id, true)
      : [],
  );

  return (
    <>
      <StyledLogTimeInputAndTimer {...props} timers={timers} />
      <StyledTimerEntryList timers={timers} />
    </>
  );
}

export { LogTimeInputs };
