import { connect } from 'react-redux';

import { updateCompanyPrefsV2 } from '@float/common/actions/companyPrefs';

import withUserCanSee from '../../../components/decorators/withUserCanSee';
import Timeoffs from '../../components/Timeoffs';
import { getEntityById } from '../../reducers/reducerHelpers';

const mapStateToProps = (state) => {
  return {
    timeoffApprovalsEnabled: state.companyPrefs.timeoff_approvals,
    getTimeoffTypeById: (id) =>
      getEntityById(id, state.settingsTimeoffTypes.entities, 'timeoff_type_id'),
    getHolidayById: (id) =>
      getEntityById(id, state.settingsHolidays.entities, 'holiday_id'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: async ({ timeoff_approvals }) => {
    await dispatch(updateCompanyPrefsV2({ timeoff_approvals }));
  },
});

const StatefulTimeoffs = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withUserCanSee(Timeoffs));

export default StatefulTimeoffs;
