import React from 'react';
import styled from 'styled-components';

import { ProjectStatus } from '@float/types/project';
import { Label, ToggleButton, ToggleGroup } from '@float/ui/deprecated';

import { DraftStatusMessage } from './DraftStatusMessage';

let etmThis;

const StatusToggleButtons = styled.div`
  margin: 0 0 16px;
  background-color: var(--dialog-bg-color);
`;

const setStatus = (value) => {
  etmThis.setState({ status: value || 2 });
};

const tentativeProjectOrPhaseBreakout = () => {
  const phaseExists = Boolean(etmThis.state.phaseId);
  const isProjectTentative = !phaseExists && etmThis.isProjectTentative();
  const isPhaseTentative = phaseExists && etmThis.isPhaseTentative();
  if (isProjectTentative || isPhaseTentative) {
    return (
      <Label>
        This {isProjectTentative ? 'project' : 'phase'} is tentative
      </Label>
    );
  }
  return null;
};

const tentativeProjectOrTaskBreakout = () => {
  const breakout = tentativeProjectOrPhaseBreakout();
  if (breakout) {
    return breakout;
  }
  if (etmThis.state.status === 1) {
    return <Label>This task is tentative</Label>;
  }
  return null;
};

export const shouldStatusElementsNotRender = (that = etmThis) => {
  const { status, readOnly, isEditingTheirSchedule } = that.state;
  if (!that.isActiveProject()) {
    return true;
  }
  return !isEditingTheirSchedule && readOnly && status === 2;
};

export const getTaskStatusToggleButtons = (self) => {
  etmThis = self;
  if (etmThis.state.project?.status === ProjectStatus.Draft) {
    return <DraftStatusMessage />;
  }
  if (shouldStatusElementsNotRender()) {
    return <div style={{ width: '100%', marginBottom: 12 }} />;
  }

  const { status, readOnly, isEditingTheirSchedule } = etmThis.state;

  return readOnly && isEditingTheirSchedule ? (
    <StatusToggleButtons readOnly={readOnly}>
      {tentativeProjectOrTaskBreakout() || (
        <>
          <ToggleGroup value={status} onChange={setStatus}>
            <ToggleButton value={3}>Completed</ToggleButton>
          </ToggleGroup>
        </>
      )}
    </StatusToggleButtons>
  ) : (
    <StatusToggleButtons readOnly={readOnly}>
      {tentativeProjectOrPhaseBreakout() || (
        <>
          <ToggleGroup value={status} readOnly={readOnly} onChange={setStatus}>
            <ToggleButton value={3}>Completed</ToggleButton>
            <ToggleButton value={1}>Tentative</ToggleButton>
          </ToggleGroup>
        </>
      )}
    </StatusToggleButtons>
  );
};

const setTimeoffStatus = (value) => {
  etmThis.setState({ status: value || 2 });
};

export const getTimeoffStatusToggleButtons = (self) => {
  etmThis = self;

  const { status, readOnly } = etmThis.state;
  if (readOnly) {
    if (etmThis.state.status == 1) {
      return (
        <StatusToggleButtons readOnly={readOnly}>
          <Label>This time off is tentative</Label>
        </StatusToggleButtons>
      );
    }
    return null;
  }

  if (
    etmThis.isRequestingTimeoff() ||
    etmThis.isEditingRequestedTimeoff() ||
    etmThis.isApproveRejectMode()
  ) {
    return null;
  }

  return (
    <StatusToggleButtons readOnly={readOnly}>
      <>
        <ToggleGroup
          value={status}
          readOnly={readOnly}
          onChange={setTimeoffStatus}
        >
          <ToggleButton value={1}>Tentative</ToggleButton>
        </ToggleGroup>
      </>
    </StatusToggleButtons>
  );
};
