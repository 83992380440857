import styled, { css } from 'styled-components';

import * as Colors from '../../Colors';
import * as Typograhy from '../../Typography';

export const CardColors = {
  WHITE: 'white',
  BLUE: 'blue',
};

export const StyledCard = styled.div<{ $color?: string }>`
  ${Typograhy.TextM.R400};

  display: flex;
  flex-direction: column;

  border-style: solid;

  color: ${Colors.FIN.Lt.Emphasis.High};

  ${({ $color }) =>
    $color === CardColors.WHITE &&
    css`
      border-width: 1px;
      border-radius: 16px;
      border-color: ${Colors.FIN.Lt.Stroke.Stroke2};

      padding: 32px;
    `}

  ${({ $color }) =>
    $color === CardColors.BLUE &&
    css`
      border-width: 2px;
      border-radius: 5px;
      border-color: ${Colors.Primary.Flue.Light[9]};

      background-color: ${Colors.Primary.Flue.Light[3]};

      padding: 24px;
    `}
`;
