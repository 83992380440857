import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M18.182 7H6.318C5.59 7 5 7.59 5 8.318v7.91c0 .727.59 1.317 1.318 1.317h11.864c.728 0 1.318-.59 1.318-1.318V8.318C19.5 7.59 18.91 7 18.182 7Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M5 10.955h14.5"
    />
  </BaseIcon>
);
