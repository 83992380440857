import React, { useState } from 'react';
import { connect } from 'react-redux';
import { has, isString } from 'lodash';
import styled from 'styled-components';

import trelloLogoImage from '@float/web/assets/images/trello_logo.svg';
import {
  clearTrelloPreload,
  fetchTrelloCoInts,
  fetchTrelloConfig,
  fetchTrelloOAuthLink,
  fetchTrelloPreload,
  retryTrelloPeopleSync,
  updateTrelloConfig,
} from '@float/web/integrations/actions/trello';

import Subsection from '../shared/Subsection';

const TrelloLogo = styled.img`
  display: block;
  max-width: 96px;
`;

const transformTrelloConfig = (config) => {
  if (!config) return config;
  const {
    boardChoices,
    memberChoices,
    membersExcluded,
    futureBoards,
    futureMembers,
    ...others
  } = config;
  return {
    projectChoices: boardChoices,
    peopleChoices: memberChoices,
    projectsExcluded: membersExcluded,
    futureProjects: futureBoards,
    futurePeople: futureMembers,
    ...others,
  };
};

const transformIntegrationConfig = ({
  projectChoices,
  peopleChoices,
  futureProjects,
  futurePeople,
  projectsExcluded,
  ...others
}) => ({
  boardChoices: projectChoices,
  memberChoices: peopleChoices,
  membersExcluded: projectsExcluded,
  futureBoards: futureProjects,
  futureMembers: futurePeople,
  ...others,
});

function TrelloSubsection(props) {
  const [error, setError] = useState(null);
  const { integration } = props;
  const { coInts } = integration;
  if (coInts && coInts.length) {
    const coInt = coInts[0];
    if (has(coInt, 'ancillary.errors.ADD_COINT_ERROR')) {
      setError({
        editLink: true,
        message: 'There was a problem connecting to Trello. Please retry.',
      });
    } else if (error) {
      setError(null);
    }
  }

  return (
    <Subsection
      label="Trello"
      type="trello"
      logo={<TrelloLogo src={trelloLogoImage} />}
      isAuthValid={(event) => event && event.origin === 'https://trello.com'}
      isAuthSuccess={(event) => event && event.data && isString(event.data)}
      isAuthError={() => false}
      appendAuthInfo={(event) => ({ token: event.data })}
      authenticationErrorMessage="Failed to connect to Trello. Please try again."
      error={error}
      config={transformTrelloConfig(integration.config)}
      {...props}
    >
      Import {'&'} sync boards, cards and people from{' '}
      <a target="_blank" rel="noopener noreferrer" href="https://trello.com/">
        Trello.{' '}
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://support.float.com/en/articles/4335544-trello"
      >
        Learn more
      </a>
      .
    </Subsection>
  );
}

const mapStateToProps = (state) => ({
  integration: state.integrations.trello,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCoInts: () => dispatch(fetchTrelloCoInts()),
  updateConfig: (coInt, config, options) =>
    dispatch(
      updateTrelloConfig(
        coInt,
        config && transformIntegrationConfig(config),
        options,
      ),
    ),
  clearPreload: () => dispatch(clearTrelloPreload()),
  fetchPreload: (coIntId, options) =>
    dispatch(fetchTrelloPreload(coIntId, options)),
  fetchConfig: (coIntId) => dispatch(fetchTrelloConfig(coIntId)),
  retryPeopleSync: (coIntId) => dispatch(retryTrelloPeopleSync(coIntId)),
  fetchOAuthLink: () => dispatch(fetchTrelloOAuthLink()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TrelloSubsection);
