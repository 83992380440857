import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon {...props} viewBox="0 0 32 32">
    <path
      d="M7.49481 19.9502L8.96254 20.1801C10.046 20.3497 10.8198 21.3421 10.74 22.4601L10.6319 23.9754C10.6004 24.4169 10.8355 24.8325 11.225 25.0248L12.3106 25.5596C12.7001 25.7519 13.1652 25.6798 13.4834 25.3802L14.5742 24.3493C15.3784 23.5889 16.6194 23.5889 17.4246 24.3493L18.5154 25.3802C18.8335 25.681 19.2976 25.7519 19.6882 25.5596L20.7758 25.0237C21.1643 24.8325 21.3984 24.418 21.367 23.9777L21.2587 22.4601C21.179 21.3421 21.9527 20.3497 23.0362 20.1801L24.5039 19.9502C24.9312 19.8837 25.2756 19.5561 25.3722 19.1244L25.6399 17.9237C25.7366 17.492 25.5654 17.0441 25.2084 16.7949L23.9832 15.9368C23.0792 15.3032 22.8031 14.066 23.3491 13.094L24.0892 11.7773C24.3044 11.3939 24.2688 10.9138 23.999 10.568L23.2483 9.60465C22.9784 9.25882 22.5291 9.116 22.1155 9.24594L20.696 9.69057C19.6472 10.0192 18.5291 9.46825 18.1259 8.42542L17.582 7.01529C17.4224 6.60289 17.033 6.33225 16.5994 6.33332L15.3962 6.33654C14.9626 6.33761 14.5742 6.61041 14.4167 7.02389L13.8864 8.4179C13.4875 9.46718 12.3642 10.0224 11.3122 9.69164L9.83396 9.22769C9.41925 9.09666 8.9678 9.24057 8.69798 9.58854L7.95256 10.553C7.68274 10.902 7.6502 11.3832 7.86962 11.7666L8.62658 13.0865C9.18408 14.0594 8.9111 15.3085 8.0019 15.9454L6.79138 16.7938C6.43442 17.0441 6.2633 17.492 6.35989 17.9226L6.62761 19.1233C6.72314 19.5561 7.0675 19.8837 7.49481 19.9502Z"
      stroke="#242C39"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.1215 13.8785C19.2928 15.05 19.2928 16.95 18.1215 18.1215C16.95 19.2928 15.05 19.2928 13.8785 18.1215C12.7072 16.95 12.7072 15.05 13.8785 13.8785C15.05 12.7072 16.95 12.7072 18.1215 13.8785Z"
      stroke="#242C39"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </BaseIcon>
);
