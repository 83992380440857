import React from 'react';

export default function ({ color, size = 12, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 12 12"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke={color} strokeLinecap="round">
        <path d="M3 8.5h-.5a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1h-4" />
        <path strokeLinejoin="round" d="M7 10.5l-2-2 2-2" />
      </g>
    </svg>
  );
}
