import React, { useEffect, useRef } from 'react';

import {
  RichText,
  RichTextHandle,
} from '@float/ui/deprecated/Earhart/RichText';

type PersonNotesProps = {
  autoFocus?: boolean;
  onChange?: (value: string) => void;
  value: string;
  readOnly?: boolean;
};

const PersonNotes = (props: PersonNotesProps) => {
  const { autoFocus, onChange, value, readOnly } = props;

  const richTextRef = useRef<RichTextHandle | null>(null);

  const onRichTextChange = (value: { text: string }) => {
    if (onChange) {
      onChange(value.text);
    }
  };

  const focus = () => {
    richTextRef.current?.focus();
  };

  useEffect(() => {
    if (autoFocus) focus();
  }, [autoFocus]);

  return (
    <RichText
      ref={richTextRef}
      label="Notes"
      onChange={onRichTextChange}
      value={value}
      maxLength={1500}
      mentionsEnabled={false}
      readOnly={readOnly}
    />
  );
};

export default PersonNotes;
