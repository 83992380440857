import styled from 'styled-components';

import { DropdownSelect, EH } from '@float/ui/deprecated';

const { StyledDropdownLabel } = DropdownSelect._styles;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : 'column')};

  color: ${EH.Colors.FIN.Lt.Emphasis.High};

  & + & {
    margin-top: 20px;
  }

  h4 {
    ${EH.Typography.TextXXL.SB600};
  }

  h5 {
    ${EH.Typography.TextL.SB600};
  }

  h6 {
    ${EH.Typography.TextM.M500};
  }

  h5 + p {
    margin-top: 2px;
  }

  p {
    ${EH.Typography.TextM.R400};

    color: ${EH.Colors.FIN.Lt.Emphasis.Medium};
  }

  a {
    ${EH.Typography.TextM.SB600};

    color: ${EH.Colors.FIN.Lt.Buttons.Primary.Default.Bg};

    &:hover {
      color: ${EH.Colors.FIN.Lt.Buttons.Primary.Hover.Bg};
    }
  }
`;

export const StyledColumn = styled.div`
  flex: 0.5;

  display: flex;
  flex-direction: column;
`;

export const StyledColumnContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  margin-top: 14px;
`;

export const StyledColumnContentWithDropdown = styled(StyledColumnContent)`
  > * + * {
    margin-left: 5px;
  }

  ${StyledDropdownLabel} {
    display: flex;
    justify-content: space-between;
    min-width: 140px;
  }
`;
