import React from 'react';

import { Candidate } from '@float/common/search/selectors/getSearchAutocompleteResults/types';
import {
  getDepartments,
  getParentDepartments,
  getSubdepartments,
} from '@float/common/selectors/departments';
import { useAppSelector } from '@float/common/store';

import { Item, ItemProps } from '../Item';

type ItemDepartmentProps = Pick<ItemProps, 'item'> &
  Partial<ItemProps> & {
    selectedValues: string[];
    onDeselect: (item: Candidate) => void;
    onReplace: (item: {
      type: Candidate['type'];
      add: string[];
      remove: string[];
    }) => void;
    isSelected: boolean;
  };

export const ItemDepartment = (props: ItemDepartmentProps) => {
  const {
    checked,
    className,
    highlighted,
    isSelectable,
    isSelected,
    item,
    onDelete,
    onDeselect,
    onReplace,
    onSelect,
    selectedValues,
  } = props;

  const parentDepartments = useAppSelector(getParentDepartments);
  const subdepartmentsByDepId = useAppSelector(getSubdepartments);
  const departments = useAppSelector(getDepartments);

  const isParentDepartmentSelected =
    'id' in item &&
    parentDepartments[item.id]?.some((parentId) => {
      const parentDep = departments[parentId];
      return selectedValues.includes(parentDep.name);
    });

  const toggleCheckbox = () => {
    if (isParentDepartmentSelected) {
      // parent department is already selected
      // which includes sub-departments in the results
      return;
    }

    if (isSelected) {
      return onDeselect(item);
    }

    const subDepartments =
      ('id' in item && subdepartmentsByDepId[item.id]) || [];

    onReplace({
      type: 'department',
      add: [item.val],
      remove: [item.val, ...subDepartments.map((id) => departments[id].name)],
    });
  };

  return (
    <Item
      checked={checked || isParentDepartmentSelected}
      className={className}
      highlighted={highlighted}
      isCheckboxDisabled={isParentDepartmentSelected}
      isCheckboxReadOnly={isParentDepartmentSelected}
      isSelectable={isSelectable}
      item={item}
      onClickCheckBox={toggleCheckbox}
      onDelete={onDelete}
      onSelect={onSelect}
    />
  );
};
