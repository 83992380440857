import React from 'react';
import { t } from '@lingui/macro';

import { DurationInputField } from '../components/DurationInputField';

export function ProjectDurationField() {
  return (
    <DurationInputField
      name="project.duration"
      label={t`Duration`}
      placeholder=""
      unit="days"
    />
  );
}
