import React from 'react';

import { Core } from '@float/common/earhart/colors';

export const UpcomingCycleIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="7.99949" cy="1.26316" r="1.26316" fill={Core.Icon.Subdued} />
    <circle cx="7.99949" cy="14.7368" r="1.26316" fill={Core.Icon.Subdued} />
    <circle
      cx="11.3686"
      cy="2.16594"
      r="1.26316"
      transform="rotate(30 11.3686 2.16594)"
      fill={Core.Icon.Subdued}
    />
    <circle
      cx="4.63227"
      cy="13.8344"
      r="1.26316"
      transform="rotate(30 4.63227 13.8344)"
      fill={Core.Icon.Subdued}
    />
    <circle
      cx="13.8345"
      cy="4.63176"
      r="1.26316"
      transform="rotate(60 13.8345 4.63176)"
      fill={Core.Icon.Subdued}
    />
    <circle
      cx="2.16656"
      cy="11.3686"
      r="1.26316"
      transform="rotate(60 2.16656 11.3686)"
      fill={Core.Icon.Subdued}
    />
    <circle
      cx="13.835"
      cy="11.3686"
      r="1.26316"
      transform="rotate(120 13.835 11.3686)"
      fill={Core.Icon.Subdued}
    />
    <circle
      cx="2.16512"
      cy="4.63178"
      r="1.26316"
      transform="rotate(120 2.16512 4.63178)"
      fill={Core.Icon.Subdued}
    />
    <circle
      cx="11.3682"
      cy="13.834"
      r="1.26316"
      transform="rotate(150 11.3682 13.834)"
      fill={Core.Icon.Subdued}
    />
    <circle
      cx="4.63192"
      cy="2.16558"
      r="1.26316"
      transform="rotate(150 4.63192 2.16558)"
      fill={Core.Icon.Subdued}
    />
    <circle cx="14.7378" cy="7.99997" r="1.26316" fill={Core.Icon.Subdued} />
    <circle cx="1.26316" cy="7.99997" r="1.26316" fill={Core.Icon.Subdued} />
    <path
      d="M6.80636 4.88307L11.074 7.34704C11.4551 7.56698 11.4551 8.11683 11.074 8.33678L6.80636 10.8007C6.42541 11.0207 5.94922 10.7457 5.94922 10.3058V5.37795C5.94922 4.93807 6.42541 4.66313 6.80636 4.88307Z"
      fill={Core.Icon.Subdued}
    />
  </svg>
);
