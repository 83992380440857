import { t } from '@lingui/macro';
import { map } from 'lodash';

import { formatAmount } from '@float/common/lib/budget';
import { Phase } from '@float/types/phase';
import { BudgetType } from '@float/types/project';
import { AccordionTableRowConfig } from '@float/ui/deprecated/AccordionTable/types';
import { ProjectPercentageModes } from '@float/ui/deprecated/Chart/types';

import { SimplifiedReportsProject } from '../types';
import {
  getBudgetDataObject,
  getFeeOrHoursAsPercentageOfBudget,
} from './budget';
import { getDoesBudgetUseFees } from './getDoesBudgetUseFees';
import { getRangeBar } from './getRangeBar';

export function getPhaseRowsForProject(
  phaseSummaries: Record<number, SimplifiedReportsProject>,
  phases: Record<number, Phase | undefined>,
  {
    budgetType,
    canSeeFees,
    hasBudgetPerPhase,
    isBillableProject,
    isTimeTrackingEnabled,
    percentageMode,
  }: {
    budgetType: number | null | undefined;
    canSeeFees: boolean;
    hasBudgetPerPhase?: boolean;
    isBillableProject: boolean;
    isTimeTrackingEnabled: number | boolean;
    percentageMode: ProjectPercentageModes;
  },
): AccordionTableRowConfig[] {
  // @TODO(PI-91)
  // @ts-expect-error - We need to refactor the types to account for the RangeBar types
  return map(phaseSummaries, (phaseSummary, phaseId) => {
    const phase = phases[Number(phaseId)];
    const ProjectHasHourlyFeeBudget = budgetType === BudgetType.HourlyFee;
    const ProjectHasFeeByPhaseBudget =
      budgetType === BudgetType.TotalFee && hasBudgetPerPhase;
    const ProjectHasHoursByPhaseBudget =
      budgetType === BudgetType.TotalHours && hasBudgetPerPhase;

    const showBudgetColumn =
      ProjectHasHourlyFeeBudget ||
      (ProjectHasHoursByPhaseBudget && phase) ||
      (ProjectHasFeeByPhaseBudget && phase); // Don't on empty phases for Fee By Phase budgets

    const columns = [
      phase ? phase.phase_name : t`No Phase`,
      '',
      '',
      showBudgetColumn ? getBudgetDataObject(phaseSummary) : '',
      canSeeFees
        ? formatAmount(
            phaseSummary.budget_type,
            isBillableProject
              ? phaseSummary.scheduled.billableFee
              : phaseSummary.scheduled.fee,
          )
        : '',
      phaseSummary.scheduled.hours,
      getFeeOrHoursAsPercentageOfBudget(phaseSummary, {
        percentageMode,
        useBillable: isBillableProject,
        useFees: getDoesBudgetUseFees(budgetType),
      }),
      getRangeBar(percentageMode, phaseSummary, isBillableProject),
    ];

    if (isTimeTrackingEnabled) {
      // Add the logged columns if the user has time tracking
      columns.splice(
        6,
        0,
        canSeeFees
          ? formatAmount(phaseSummary.budget_type, phaseSummary.logged.fee)
          : '',
        phaseSummary.logged.hours,
      );
    }

    return { data: columns };
  });
}
