/**
 * Converts HEX to RGB format
 *
 * @see {@link https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb/5624139#5624139}
 *
 * @param {string} color The color value in hexadecimal format
 *
 * @returns {(Array|null)} Returns an array with red, green and
 * blue values respectively (e.g., `[r,g,b]`), or null if the argument
 * is invalid.
 *
 * @example
 * // returns [255, 255, 255]
 * hexToRgb('fff')
 * @example
 * // returns [255, 255, 255]
 * hexToRgb('#FFF')
 * @example
 * // returns [0, 0, 0]
 * hexToRgb('000000')
 *
 */

export function hexToRgb(color: string): [number, number, number] | null {
  // expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const hex = color.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
}
