import { combineReducers } from 'redux';

import asana from './asana';
import calendar from './calendar';
import jira from './jira';
import teamwork from './teamwork';
import trello from './trello';

export default combineReducers({
  calendar,
  teamwork,
  jira,
  asana,
  trello,
});
