import React from 'react';

import { fadeIn } from '@float/common/lib/animation.css';
import { TextButton } from '@float/ui/deprecated/Earhart/Buttons';

export type ProjectNoTaskLineProps = {
  entity?: { phase_id?: number };
  canEdit: boolean;
  onClickEdit?: () => void;
  skipAddTaskFade?: boolean;
};

export function ProjectAddTask({
  entity,
  canEdit,
  onClickEdit,
  skipAddTaskFade,
}: ProjectNoTaskLineProps) {
  if (!entity) return null;

  return (
    <>
      {canEdit && (
        <TextButton
          className={skipAddTaskFade ? '' : fadeIn}
          appearance="flue-fill"
          onClick={(e) => {
            e.preventDefault();
            return onClickEdit?.();
          }}
          calloutId="group-by-task-no-task"
        >
          Add task
        </TextButton>
      )}
    </>
  );
}
