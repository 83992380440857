import React, { useRef } from 'react';
import { t } from '@lingui/macro';

import { useCombinedRefs } from '@float/libs/hooks/useCombinedRefs';
import { useRect } from '@float/libs/web/hooks/useRect';
import { SavedView } from '@float/types';
import { List } from '@float/ui/primitives/List';
import { ListBoxProps } from '@float/ui/primitives/List/components/ListBox';
import { ScrollArea } from '@float/ui/primitives/ScrollArea';
import { useScrollAreaState } from 'components/SearchFilterDropdown/hooks/useScrollAreaState';

import { SearchFilterInput } from '../../SearchFilterDropdown/components/SearchFilterInput';
import { useSelectorKeyboard } from '../hooks/useSelectorKeyboard';
import { useViewsOptions } from '../hooks/useViewsOptions';
import { AccessControlProps } from './types';
import { ViewSelectorToolbar } from './ViewSelectorToolbar';
import { ViewsSelectorContent } from './ViewsSelectorContent';

import * as styles from './ViewsSelectorMenu.css';

export type ViewsSelectorMenuProps = {
  views: SavedView[];
  acl: AccessControlProps;
  loading: boolean;
  selected?: number;
  onViewSelect: (view: SavedView) => void;
  onEdit: (view: SavedView) => void;
  onPersonalChange: (view: SavedView, personal: boolean) => void;
  onDelete: (view: SavedView) => void;
  onPinChange: (view: SavedView, pinned: boolean) => void;
  onAddFilterClick: () => void;
} & ListBoxProps;

export const ViewsSelectorMenu = React.forwardRef<
  HTMLDivElement,
  ViewsSelectorMenuProps
>(
  (
    {
      onViewSelect,
      onPersonalChange,
      onDelete,
      onEdit,
      onPinChange,
      onAddFilterClick,
      acl,
      views,
      loading,
      selected,
      ...props
    },
    forwardedRef,
  ) => {
    const {
      viewNameFilter,
      setViewNameFilter,
      options,
      setViewTypeFilter,
      viewTypeFilter,
      setViewSortType,
      viewSortType,
    } = useViewsOptions(views);

    const ref = useSelectorKeyboard((index) => {
      onViewSelect(options[index]);
    });

    const containerRef = useCombinedRefs([forwardedRef, ref]);
    const listRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    const rect = useRect(listRef);
    const scrollAreaState = useScrollAreaState(rect);

    return (
      <List.Box {...props} ref={containerRef} className={styles.menu}>
        <SearchFilterInput
          placeholder={t`Search Views`}
          setInputValue={setViewNameFilter}
          value={viewNameFilter}
        />

        <List.Separator />

        <ViewSelectorToolbar
          acl={acl}
          sortType={viewSortType}
          viewTypeFilter={viewTypeFilter}
          onSortChange={setViewSortType}
          onViewTypeFilterChange={setViewTypeFilter}
        />

        <ScrollArea.Root
          data-scrollbar-state={scrollAreaState.scrollbarState}
          style={{ height: scrollAreaState.height }}
          type="auto"
        >
          <ScrollArea.Content ref={contentRef}>
            <div
              ref={listRef}
              className={styles.listbox}
              role="listbox"
              tabIndex={-1}
            >
              <ViewsSelectorContent
                acl={acl}
                loading={loading}
                onAddFilterClick={onAddFilterClick}
                onDelete={onDelete}
                onEdit={onEdit}
                onPersonalChange={onPersonalChange}
                onPinChange={onPinChange}
                onViewSelect={onViewSelect}
                options={options}
                selected={selected}
                viewNameFilter={viewNameFilter}
                viewTypeFilter={viewTypeFilter}
              />
            </div>
          </ScrollArea.Content>
        </ScrollArea.Root>
      </List.Box>
    );
  },
);
