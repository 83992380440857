import format from 'date-fns/format';

import { DEFAULT_DATESTRING_FORMAT } from '@float/constants/dates';

import { EditTaskModalEntityLengthGetter } from '../../schedule/task/EditTaskModal.types';

export type GetWorkDayEntity = {
  start_date: Date;
  end_date: Date;
  people_ids: number[];
  timeoff_id?: number;
};

export const getWorkDays = (
  getEntityLength: EditTaskModalEntityLengthGetter,
  entity: GetWorkDayEntity,
) => {
  return (
    getEntityLength({
      start_date: format(entity.start_date, DEFAULT_DATESTRING_FORMAT),
      end_date: format(entity.end_date, DEFAULT_DATESTRING_FORMAT),
      people_ids: entity.people_ids,
      timeoff_id: entity.timeoff_id,
    }) ?? 1
  );
};
