import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { CompanyPreferences } from '@float/types/companyPreferences';

import { hasReachedPeopleSeatsLimit } from './hasReachedPeopleSeatsLimit';
import { hasReachedPlaceholderSeatsLimit } from './hasReachedPlaceholderSeatsLimit';

export const hasSeatsAvailable = (companyPrefs: CompanyPreferences) => {
  const canAddPeople = !hasReachedPeopleSeatsLimit(companyPrefs);

  if (featureFlags.isFeatureEnabled(FeatureFlag.PlaceholdersPackaging)) {
    const canAddPlaceholders = !hasReachedPlaceholderSeatsLimit(companyPrefs);

    return canAddPeople || canAddPlaceholders;
  }

  return canAddPeople;
};
