import {
  CSSProperties,
  useCallback,
  useContext,
  useLayoutEffect,
  useState,
} from 'react';
import { isEqual } from 'lodash';

import CellColorsCache from '@float/common/lib/cellColors/cache/cellColorsCache';
import { CellItem, ProjectStatus, TimeoffType } from '@float/types';

import { GeneratedColors } from '../../cellColors/worker/cellColors';
import { CellStylesManagerContext } from '../CellStylesManager';
import { getTimeOffStyles } from '../styles/timeOffStyles';

export type CellStylesConfig = {
  actionMode: string;
  cursor: string | null;
  isLinking: boolean;
  isLinkOrigin: boolean;
  isPrintMode: boolean;
  isSelected: boolean;
  isSelectedAsLink: boolean;
  timeOffType: TimeoffType | undefined;
  isEligibleLinkSource: undefined | boolean;
  isEligibleLinkTarget: undefined | boolean;
};

type Item =
  | CellItem<'task' | 'timeoff' | 'loggedTime' | 'phase'>
  | { type: 'project'; entity: { status: ProjectStatus } };

const defaultCellStyles = {
  display: 'none',
};

function getDefaultStyles(item: Item, config?: CellStylesConfig) {
  // Timeoffs styles are generated via fast path
  if (item.type === 'timeoff') {
    const styles = getTimeOffStyles(null, item, config) as CSSProperties;

    if (config && config.cursor) styles.cursor = config.cursor;

    return styles;
  }

  return defaultCellStyles;
}

export function useCellStyles(
  item: Item,
  color: string,
  config?: CellStylesConfig,
) {
  const manager = useContext(CellStylesManagerContext);

  const [cellStyles, setCellStyles] = useState<CSSProperties | null>(() =>
    getDefaultStyles(item, config),
  );

  const calculateNextStyles = useCallback(
    (data: GeneratedColors) => {
      const nextCellStyles = manager.getCellStyles(data, item, config);

      if (!isEqual(cellStyles, nextCellStyles)) {
        setCellStyles(nextCellStyles);

        if (color) {
          CellColorsCache.set(color, data);
        }
      }
    },
    [cellStyles, color, item, config, manager],
  );

  useLayoutEffect(() => {
    if (item.type === 'timeoff') {
      const styles = getDefaultStyles(item, config);

      if (!isEqual(styles, cellStyles)) {
        setCellStyles(styles);
      }
    }
    // We want to update the styles when the item changes
  }, [item]);

  useLayoutEffect(() => {
    if (item.type === 'timeoff') return;

    // calculate or pick from cache the results and pass it to generate the styles
    const cached = CellColorsCache.get(color);

    if (cached) {
      calculateNextStyles(cached);
    } else {
      const { cancel } = manager.queueCellColorsCalculation(
        color,
        calculateNextStyles,
      );

      return cancel;
    }
  }, [color, item, calculateNextStyles, manager]);

  return cellStyles ?? {};
}
