import { omit } from 'lodash';

import {
  ADD_TIMEOFF_TYPE_SUCCESS,
  DELETE_TIMEOFF_TYPE_SUCCESS,
  TIMEOFF_TYPES_LOAD_FAILED,
  TIMEOFF_TYPES_LOAD_FINISH,
  UPDATE_TIMEOFF_TYPE_SUCCESS,
} from '../actions';

const DEFAULT_STATE = {
  timeoffTypes: {},
  loadState: 'UNLOADED',
};

const timeoffTypes = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case TIMEOFF_TYPES_LOAD_FAILED: {
      return {
        ...state,
        loadState: 'LOAD_FAILED',
        timeoffTypes: {},
      };
    }

    case TIMEOFF_TYPES_LOAD_FINISH: {
      return {
        ...state,
        loadState: 'LOADED',
        timeoffTypes: action.timeoffTypes,
      };
    }

    case UPDATE_TIMEOFF_TYPE_SUCCESS:
    case ADD_TIMEOFF_TYPE_SUCCESS: {
      const { payload: timeoffType = {} } = action;
      if (!timeoffType || !timeoffType.timeoff_type_id) {
        return state;
      }

      return {
        ...state,
        timeoffTypes: {
          ...state.timeoffTypes,
          [timeoffType.timeoff_type_id]: timeoffType,
        },
      };
    }

    case DELETE_TIMEOFF_TYPE_SUCCESS: {
      const { id = null } = action;
      if (!id) {
        return state;
      }

      return {
        ...state,
        timeoffTypes: omit(state.timeoffTypes, id),
      };
    }

    default: {
      return state;
    }
  }
};

export default timeoffTypes;
