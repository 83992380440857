import React from 'react';
import { t } from '@lingui/macro';
import { getVisibleSettings } from 'selectors';

import { useAppSelector } from '@float/common/store';
import { Card } from '@float/ui/deprecated/Earhart/Cards';

import AddClient from '../../containers/Clients/AddClient';
import ClientsTable from '../../containers/Clients/ClientsTable';
import Body from '../Body';
import RestrictedMessage from '../RestrictedMessage';
import { DeleteMessage } from './DeleteMessage';
import { StyledRow } from './styles';

const Clients = () => {
  const userCanSee = useAppSelector(getVisibleSettings);

  if (!userCanSee.clients) {
    return <RestrictedMessage />;
  }

  return (
    <Body
      header={t`Clients`}
      subheader={t`Add, edit and remove the Clients you group your Projects into.`}
    >
      <Card>
        <StyledRow>
          <AddClient placeholder={t`Add a Client`} />
        </StyledRow>

        <StyledRow>
          <ClientsTable
            recordNames={['client', 'clients']}
            displayRowCount
            headNames={['Clients', 'Projects']}
            headNameKeys={['name', 'project_count']}
            confirmDeleteProps={DeleteMessage}
            rowActions={[
              {
                id: 'edit',
                label: t`Edit`,
              },
              {
                id: 'delete',
                label: t`Delete`,
              },
            ]}
          />
        </StyledRow>
      </Card>
    </Body>
  );
};

export default Clients;
