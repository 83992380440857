import request from '../lib/request';

/**
 * Request for making bulk updates or deletes
 * TODO probably need to update this separate the update and delete routes
 * for easier testability
 * @param type the resource to update. Currently being used with people and projects
 * @param action either bulk-update or bulk-delete
 * @param ids ids of the resources being updated/deleted
 * @param count this parameter might be deprecated
 * @returns
 */
export const bulkUpdate = ({
  type,
  action = 'bulk-update',
  ids,
  fields,
  count,
}: {
  type: string;
  action?: 'bulk-update' | 'bulk-delete';
  ids: number[];
  fields?: Record<string, unknown>;
  count?: number;
}): Promise<{ fields?: Record<string, unknown>; project_ids?: string[] }> => {
  const idsKey = type.startsWith('project') ? 'project_ids' : `${type}_ids`;
  const params =
    action === 'bulk-delete' ? ids : { [idsKey]: ids, fields, count };

  return request.post(`${type}/${action}`, params, { version: 'f3' });
};
