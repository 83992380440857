import styled, { css } from 'styled-components';

import { respondTo } from '@float/ui/deprecated/Theme/helpers/responsive/respondTo';

export const StyledBody = styled.section<{ fluid: boolean }>`
  flex-grow: 1;

  max-width: ${({ fluid }) => (fluid ? 'auto' : '764px')};

  margin: 0 auto;

  ${(props) =>
    respondTo('xlarge')(css`
      max-width: ${props.fluid ? 'auto' : '1110px'};
    `)}

  .eh-card {
    margin-top: 24px;

    &.plus-pack-upgrade {
      margin-top: 16px;
    }
  }
`;

export const StyledContent = styled.div`
  padding: 0;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
`;
