import React, { useEffect, useState } from 'react';
import zxcvbn from 'zxcvbn';

import { passwordScoreVariant } from './styles.css';

// Define types for the function parameters and return value
const getPasswordScoreVariant = (
  index: number,
  score: number,
  minimumScore: number,
  password: string,
): string => {
  if (password !== '' && index <= score) {
    return score >= minimumScore
      ? passwordScoreVariant.secure
      : passwordScoreVariant.insecure;
  }
  return passwordScoreVariant.noPassword;
};

// Define type for the PasswordStrengthMeter component props
interface PasswordStrengthMeterProps {
  password: string;
}

const PasswordStrengthMeter: React.FC<PasswordStrengthMeterProps> = ({
  password,
}) => {
  const [score, setScore] = useState<number>(0);
  const minimumScore = 3;

  useEffect(() => {
    const result = zxcvbn(password);
    setScore(result.score);
  }, [password]);

  return (
    <div
      id="password-strength-bar-container"
      style={{ marginTop: '7px', display: 'flex' }}
    >
      {Array.from({ length: 5 }, (_, index) => (
        <div
          key={index}
          className={getPasswordScoreVariant(
            index,
            score,
            minimumScore,
            password,
          )}
          data-testid={`password-strength-bar-${index}`}
        />
      ))}
    </div>
  );
};

export default PasswordStrengthMeter;
