import React, { StrictMode } from 'react';
import { plural, t } from '@lingui/macro';
import { useClickAway, useToggle } from 'react-use/esm';

import { Tooltip, TooltipVariants } from '@float/ui/components/Tooltip';

import { FormField } from '../FormFieldsSection';

import * as styles from './styles.css';

type DurationFieldProps = {
  duration?: number | null;
  hideLabel?: boolean;
  asPlaceholder?: boolean;
};

export function DurationField(props: DurationFieldProps) {
  const { hideLabel = true } = props;
  const container = React.useRef(null);
  const [showWarning, toggleShowWarning] = useToggle(false);

  useClickAway(container, () => {
    toggleShowWarning(false);
  });

  if (props.duration === undefined || props.duration === null) {
    return null;
  }

  const component = (
    <span className={styles.placeholder}>
      {plural(props.duration, { one: '# day', other: '# days' })}
    </span>
  );

  if (props.asPlaceholder) return component;

  return (
    <StrictMode>
      <Tooltip
        content={t`Add project start date to edit these dates`}
        open={showWarning}
        placement="left"
        variant={TooltipVariants.warning}
      >
        <FormField
          label={t`Duration`}
          hideLabel={hideLabel}
          padding="emptySides"
        >
          <div
            ref={container}
            className={styles.field}
            onClick={() => toggleShowWarning()}
          >
            {component}
          </div>
        </FormField>
      </Tooltip>
    </StrictMode>
  );
}
