import React, { StrictMode } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import cx from 'classnames';

import * as styles from './TabsLink.css';

export type TabsLinkProps = {
  children: React.ReactNode;
  className?: string;
} & NavLinkProps;

export const TabsLink = ({ className, children, ...rest }: TabsLinkProps) => (
  <StrictMode>
    <NavLink
      exact
      className={cx(styles.tabsLink, className)}
      activeClassName="active"
      {...rest}
    >
      {children}
    </NavLink>
  </StrictMode>
);
