import { CurrentUser, Person } from '@float/types';

import { userCanViewOthers } from '../../lib/rights';

export const isAllowedToViewPerson = (
  context: { user: CurrentUser },
  person: Person,
) => {
  const { user } = context;

  const canViewOthers = userCanViewOthers(user);
  if (!canViewOthers) {
    return user.people_id === person.people_id;
  }

  if (!user.account?.department_filter_all?.length) {
    return true;
  }

  // users should always be allowed to view themselves
  if (person.people_id === user.people_id) {
    return true;
  }

  return (
    !!person.department_id &&
    user.account?.department_filter_all.includes(person.department_id)
  );
};
