import React from 'react';
import { t } from '@lingui/macro';

import { BlinkingTime } from '@float/common/components/BlinkingTime';

import { getTimerEntriesLabel } from '../../helpers/getTimerEntriesLabel';
import { StyledTimerTextLabel, StyledTimerTextTime } from './styles';

type TimerTextProps = {
  time: string;
  timerEntriesCount?: number;
  activeTimer?: boolean;
  disabled?: boolean;
};

const TimerText = (props: TimerTextProps) => {
  const {
    time,
    timerEntriesCount = 0,
    activeTimer = false,
    disabled = false,
  } = props;

  const label = activeTimer
    ? t`Timer running...`
    : getTimerEntriesLabel(timerEntriesCount);

  const hasNoActiveTimerNorTimeEntries =
    !activeTimer && timerEntriesCount === 0;

  return (
    <>
      <StyledTimerTextTime disabled={disabled}>
        <BlinkingTime time={time} active={activeTimer} />
      </StyledTimerTextTime>
      <StyledTimerTextLabel disabled={hasNoActiveTimerNorTimeEntries}>
        {label}
      </StyledTimerTextLabel>
    </>
  );
};
export { TimerText };
