import React from 'react';

export default function ({ color = '#FFFFFF', size = 11, style = {} }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      style={style}
    >
      <g fill="none" fillRule="evenodd" stroke={color} strokeWidth="1.3">
        <path d="M8.557 3H9a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4" />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4 .5L6.5 3 4 5.5"
        />
      </g>
    </svg>
  );
}
