import React, { MouseEvent } from 'react';

import { PeopleProjectViewStatus } from '@float/common/actions';
import { noop } from '@float/libs/utils/noop';
import { Button } from '@float/ui/components/Button';
import { Checkbox } from '@float/ui/components/Checkbox';
import { CheckboxCheckedState } from '@float/ui/components/Checkbox/types';
import { IconFolderPlus } from '@float/ui/icons/essentials/IconFolderPlus';
import { IconUserPlus } from '@float/ui/icons/essentials/IconUserPlus';

import {
  ActiveArchivedFilter,
  ActiveArchivedFilterOption,
} from './components/ActiveArchivedFilter';

import * as styles from './styles.css';

export type ManageQuickFiltersProps = {
  activeCount: number;
  archivedCount: number;
  canCreate: boolean;
  checkboxLabel: string;
  createLabel: string;
  currentCheckboxValue: boolean;
  currentFilterValue: PeopleProjectViewStatus;
  iconCreate: typeof IconFolderPlus | typeof IconUserPlus;
  onCheckboxChange: (checked: boolean) => void;
  onClickCreate: (e: MouseEvent) => void;
  onFilterChanged: (option: ActiveArchivedFilterOption) => void;
};

export const ManageQuickFilters = (props: ManageQuickFiltersProps) => {
  const {
    activeCount,
    archivedCount,
    canCreate,
    checkboxLabel,
    createLabel,
    currentCheckboxValue,
    currentFilterValue,
    iconCreate: IconCreate,
    onCheckboxChange,
    onClickCreate,
    onFilterChanged = noop,
  } = props;

  const onFilterChangedHandler = (option: ActiveArchivedFilterOption) => {
    onFilterChanged(option);
  };

  const onCheckboxChangeHandler = (checked: CheckboxCheckedState) => {
    onCheckboxChange(Boolean(checked));
  };

  return (
    <div className={styles.quickFilters}>
      {canCreate && (
        <Button
          appearance="clear-flay"
          aria-label={createLabel}
          className={styles.buttonCreate}
          iconEnd={IconCreate}
          onClick={onClickCreate}
          size="xsmall"
        />
      )}

      <div className={styles.filters}>
        <ActiveArchivedFilter
          activeCount={activeCount}
          archivedCount={archivedCount}
          onChange={onFilterChangedHandler}
          value={currentFilterValue}
        />

        <span className={styles.divider}>&nbsp;</span>

        <Checkbox
          checked={currentCheckboxValue}
          label={checkboxLabel}
          onChange={onCheckboxChangeHandler}
        />
      </div>
    </div>
  );
};
