import styled, { css } from 'styled-components';

import EH from '@float/ui/deprecated/Earhart';

export const StyledBanner = styled.div`
  flex-direction: column;

  align-items: flex-start;

  border-radius: 5px;

  padding: 16px 24px;

  transition-property: background-color;
  transition-duration: ${EH.Animation.Settings.Duration.Short};
  transition-timing-function: ${EH.Animation.Settings.Easing.InOutCurve};

  background-color: ${EH.Colors.Primary.Flue.Light[4]};

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${EH.Colors.FIN.Lt.Surface.Surf2};
    `}

  p {
    ${EH.Typography.Label14.R400};

    transition-property: color;
    transition-duration: ${EH.Animation.Settings.Duration.Short};
    transition-timing-function: ${EH.Animation.Settings.Easing.InOutCurve};

    color: ${EH.Colors.Primary.Flue.Light[11]};

    ${({ disabled }) =>
      disabled &&
      css`
        color: ${EH.Colors.FIN.Lt.Emphasis.Disabled};
      `}
  }
`;
