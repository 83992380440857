import { AGClientSocket } from 'socketcluster-client';
import { assign, raise, sendParent, setup } from 'xstate';

import { RETRY_BASE_TIMEOUT } from './constants';

export const SocketAuthenticationMachine = setup({
  types: {
    context: {} as {
      authToken: string;
      socket: AGClientSocket;
      attempts: number;
    },
    input: {} as {
      socket: AGClientSocket;
    },
    events: {} as
      | {
          type: 'authenticate';
          payload: string;
        }
      | {
          type: 'socketAuthSuccessful';
        }
      | {
          type: 'socketAuthFailed';
        },
  },
  delays: {
    retryTimeout: ({ context }) => {
      return context.attempts * RETRY_BASE_TIMEOUT;
    },
  },
}).createMachine({
  context: ({ input }) => ({
    authToken: '',
    socket: input.socket,
    attempts: 1,
  }),
  initial: 'unauthenticated',
  on: {
    authenticate: {
      actions: [
        assign({ authToken: ({ event }) => event.payload }),
        async ({ self, context: { socket }, event }) => {
          const { isAuthenticated } = await socket.authenticate(event.payload);

          if (isAuthenticated) {
            self.send({
              type: 'socketAuthSuccessful',
            });
          } else {
            self.send({
              type: 'socketAuthFailed',
            });
          }
        },
      ],
    },
    socketAuthSuccessful: {
      target: '.authenticated',
    },
    socketAuthFailed: {
      target: '.authFailed',
    },
  },
  states: {
    unauthenticated: {},
    authenticated: {
      entry: [
        sendParent({ type: 'socketAuthSuccessful' }),
        assign({ attempts: 1 }),
      ],
    },
    authFailed: {
      after: {
        retryTimeout: {
          actions: [
            assign({ attempts: ({ context }) => context.attempts + 1 }),
            raise(({ context }) => ({
              type: 'authenticate',
              payload: context.authToken,
            })),
          ],
        },
      },
    },
  },
});
