import { cloneDeep, keyBy } from 'lodash';

import {
  ADD_HOLIDAY_SUCCESS,
  DELETE_HOLIDAY_SUCCESS,
  HOLIDAYS_LOAD_FAILED,
  HOLIDAYS_LOAD_FINISH,
  PEOPLE_IMPORTED,
  PEOPLE_UPDATED,
  UPDATE_HOLIDAY_SUCCESS,
} from '../actions';

const DEFAULT_STATE = {
  holidays: {},
  loadState: 'UNLOADED',
};

export default function reducer(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case HOLIDAYS_LOAD_FINISH: {
      return {
        ...state,
        loadState: 'LOADED',
        holidays: keyBy(action.holidays, 'holiday_id'),
      };
    }

    case HOLIDAYS_LOAD_FAILED: {
      return {
        ...state,
        loadState: 'LOAD_FAILED',
        holidays: {},
      };
    }

    case ADD_HOLIDAY_SUCCESS:
    case UPDATE_HOLIDAY_SUCCESS: {
      const { payload: holiday } = action;
      const newHolidays = { ...state.holidays };
      newHolidays[holiday.holiday_id] = holiday;
      return { ...state, holidays: newHolidays };
    }

    case DELETE_HOLIDAY_SUCCESS: {
      const holidayId = action.id || action.payload?.holiday_id;
      if (!holidayId) return state;

      const newHolidays = { ...state.holidays };
      delete newHolidays[holidayId];
      return { ...state, holidays: newHolidays };
    }

    case PEOPLE_IMPORTED:
    case PEOPLE_UPDATED: {
      // Serena keys off object equality in Redux to determine when to apply an
      // update. When a person is added, we need to reload all holidays so that
      // they get associated with the new person.
      return cloneDeep(state);
    }

    default: {
      return state;
    }
  }
}
