import React from 'react';
import { Trans } from '@lingui/macro';

import { NoResultsIllustration } from '@float/ui/deprecated/Illustrations/NoResultsIllustration';

import { onChatClick } from '../../../Nav/helpers';
import { isGoogleTranslateActive } from '../../isGoogleTranslateActive';
import { AppErrorPage } from '../AppErrorPage';

const AppErrorGeneric = () => (
  <AppErrorPage>
    <NoResultsIllustration />
    <h2>
      <Trans>We've got a problem!</Trans>
    </h2>
    <p>
      <Trans>
        An error is preventing this page from loading. Please refresh the page
        to try again.
        <br />
        If the error persists, please contact our{' '}
        <a
          role="button"
          tabIndex={0}
          onClick={onChatClick}
          title="Support team chat"
        >
          support team
        </a>
        .
      </Trans>
    </p>
    {isGoogleTranslateActive() && (
      <p>
        <Trans>
          Browser auto-translate is on. This can cause display issues. Try
          switching it off in your browser's settings.{'  '}
          <a
            href="https://support.float.com/en/articles/28950-supported-languages"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </a>
        </Trans>
      </p>
    )}
  </AppErrorPage>
);

export { AppErrorGeneric };
