import { get } from 'lodash';

import {
  ADD_ROLE,
  ADD_ROLE_FAILURE,
  ADD_ROLE_SUCCESS,
  DELETE_ROLE,
  DELETE_ROLE_FAILURE,
  DELETE_ROLE_SUCCESS,
  FETCH_ROLES,
  FETCH_ROLES_FAILURE,
  FETCH_ROLES_SUCCESS,
  SORT_ROLES,
  UPDATE_ROLE,
  UPDATE_ROLE_FAILURE,
  UPDATE_ROLE_SUCCESS,
} from '@float/constants/roles';
import { RawRole, Role, RolesAction } from '@float/types';

import api from '../api3';
import { RoleApiPayload } from '../api3/roles';
import { trackEvent } from '../lib/gtm';
import { ReduxState, ReduxStateStrict } from '../reducers/lib/types';
import { getRoles } from '../selectors/roles';
import { AppDispatch, AppDispatchStrict } from '../store';
import { trackRateChanges } from './lib/trackRateChanges';

export {
  FETCH_ROLES,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_FAILURE,
  ADD_ROLE,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAILURE,
  UPDATE_ROLE,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAILURE,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE,
  SORT_ROLES,
};

export const ensureRolesLoaded =
  () =>
  async (dispatch: AppDispatchStrict, getState: () => ReduxStateStrict) => {
    const { loadState: currentLoadState } = getState().roles;

    if (currentLoadState === 'LOADING') return; // There's already an in-flight load request

    try {
      dispatch({ type: FETCH_ROLES });

      const roles = await api.getRoles();

      dispatch({ type: FETCH_ROLES_SUCCESS, entities: roles });

      return roles;
    } catch (e) {
      dispatch({ type: FETCH_ROLES_FAILURE });
    }
  };

export type AddRoleOptions = {
  from?: 'roles-management' | 'people-management-bulk' | 'people-management';
  trackEvent?: boolean;
};

export const addRole = (payload: RoleApiPayload, options?: AddRoleOptions) => {
  return async (dispatch: AppDispatchStrict) => {
    const response = await api.createRole({ data: payload });

    dispatch(addRoleSuccess(response, options));

    return response;
  };
};

export const addRoleSuccess = (
  payload: RawRole,
  options?: AddRoleOptions,
): RolesAction => {
  if (options?.trackEvent) {
    trackEvent('Role added', {
      name: payload.name,
      from: options?.from,
    });
  }

  return {
    type: ADD_ROLE_SUCCESS,
    payload,
  };
};

type UpdateRoleOptions = {
  from?: string;
  trackEvent?: boolean;
};

export const updateRole = (
  id: Role['id'],
  payload: RoleApiPayload,
  options?: UpdateRoleOptions,
) => {
  return async (dispatch: AppDispatchStrict) => {
    const response = await api.updateRole({ id, data: payload });

    dispatch(updateRoleSuccess(response, options));

    return response;
  };
};

export const updateRoleSuccess = (
  payload: RawRole,
  options?: UpdateRoleOptions,
) => {
  return (dispatch: AppDispatchStrict, getState: () => ReduxStateStrict) => {
    if (options?.trackEvent) {
      trackEvent('Role updated', {
        name: payload.name,
        role_id: payload.id,
        modified_by: payload.modified_by,
      });

      const from_rate =
        getState().roles.roles[payload.id]?.default_hourly_rate || null;

      const to_rate = payload.default_hourly_rate || null;

      trackRateChanges({
        from_rate,
        to_rate,
        scope: 'role',
        page: 'roles management',
        role_id: payload.id,
      });
    }

    return dispatch({
      type: UPDATE_ROLE_SUCCESS,
      payload,
    });
  };
};

type DeleteRoleOptions = {
  trackEvent?: boolean;
};

export const deleteRole = (id: Role['id'], options?: DeleteRoleOptions) => {
  return async (dispatch: AppDispatchStrict) => {
    await api.deleteRole({ id });

    dispatch(deleteRoleSuccess(id));
  };
};

export const deleteRoleSuccess = (
  id: Role['id'],
  options?: DeleteRoleOptions,
) => {
  return (dispatch: AppDispatchStrict) => {
    if (options?.trackEvent) {
      trackEvent('Role deleted', {
        id,
      });
    }

    return dispatch({
      type: DELETE_ROLE_SUCCESS,
      id,
    });
  };
};

export const assignRoleToJobTitleWithDeps =
  (addRoleFunc: (payload: { name: string; from: string }) => unknown) =>
  (person: { role_id?: string | number | null; job_title?: string | null }) =>
  async (dispatch: AppDispatch, getState: () => ReduxState) => {
    const roles = getRoles(getState());
    const roleId = get(person, 'role_id');

    if (typeof roleId === 'number') {
      person.job_title = roles[roleId].name;
    } else if (typeof roleId === 'string') {
      try {
        await dispatch(addRoleFunc({ name: roleId, from: 'people-modal' }));
        person.job_title = roleId;
      } catch {
        throw new Error(`Error: "${roleId}" already exists`);
      }
    } else if (roleId === null) {
      person.job_title = null;
    }
  };

export const assignRoleToJobTitle = assignRoleToJobTitleWithDeps(addRole);
