import React, { useCallback } from 'react';
import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
  useStore,
} from 'react-redux';
import type { Store } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';

import type { AllActions, AllActionsStrict } from './reducers';
import type { ReduxState, ReduxStateStrict } from './reducers/lib/types';

export type AppDispatchStrict = ThunkDispatch<
  ReduxStateStrict,
  unknown,
  AllActionsStrict
>;
export type AppStoreStrict = Store<ReduxStateStrict, AllActionsStrict>;

/**
 * @deprecated use `AppDispatchStrict` instead
 */
export type AppDispatch = ThunkDispatch<ReduxState, unknown, AllActions>;
/**
 * @deprecated use `AppStoreStrict` instead
 */
export type AppStore = Store<ReduxState, AllActions>;

/**
 * Pass a function to wrap it with our global Redux dispatch.
 * Useful doing away with unnecessary function wrapping and reducing
 * test complexity.
 *
 * @example
 *
 * // Before
 * const dispatch = useAppDispatch();
 * const dispatchUpdate = (id: string) => dispatch(doUpdate(id));
 *
 * // After
 * const dispatchUpdate = useAppDispatchDecorator(doUpdate);
 *
 * Note that type safety is preserved, and the returned function will have the same
 * signature as the input function.
 */
export function useAppDispatchDecorator<
  A extends ReadonlyArray<unknown>,
  R,
  D = AppDispatchStrict,
  S = ReduxStateStrict,
>(
  // Adding a second method signature to support action creators
  fn:
    | ((...params: A) => (dispatch: D) => R)
    | ((...params: A) => (dispatch: D, getState: () => S) => R)
    | ((...params: A) => R),
) {
  const dispatch = useAppDispatch();
  return useCallback(
    (...args: [...params: A]) => {
      return dispatch(fn(...args)) as R;
    },
    [fn, dispatch],
  );
}

/**
 * Same idea as `useAppDispatchDecorator` – Pass a function and its arguments
 * to wrap it with our global Redux selector.
 *
 * @example
 *
 * // Before
 * const project = useAppSelector((state) => getUserFromProject(state, projectId, userId));
 *
 * // After
 * const project = useAppSelectorWithParams(getUserFromProject, projectId, userId);
 */
export function useAppSelectorWithParams<
  A extends ReadonlyArray<unknown>,
  R,
  S = ReduxStateStrict,
>(fn: (state: S, ...params: A) => R, ...args: A) {
  return useSelector<S, R>((state) => fn(state, ...args));
}

/**
 * @deprecated use `useAppDispatchStrict` instead
 */
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppDispatchStrict: () => AppDispatchStrict = useDispatch;

/**
 * @deprecated use `useAppSelectorStrict` instead
 */
export const useAppSelector: TypedUseSelectorHook<ReduxState> = useSelector;
export const useAppSelectorStrict: TypedUseSelectorHook<ReduxStateStrict> =
  useSelector;

/**
 * @deprecated use `useAppStoreStrict` instead
 */
export const useAppStore: () => AppStore = useStore;
export const useAppStoreStrict: () => AppStoreStrict = useStore;

export function provideStoreHoc<P extends { store: AppStoreStrict }>(
  Component: React.ComponentType<P>,
) {
  return (props: Omit<P, 'store'>) => {
    const store = useAppStoreStrict();
    return <Component {...(props as P)} store={store} />;
  };
}
