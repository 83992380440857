import { IWithModal } from '@float/web/modalManager/withModal';

export type ModalCreateDraftProjectSettings = {
  onConfirm: () => void;
};

export const useModalCreateDraftProjectControls = (
  props: IWithModal<ModalCreateDraftProjectSettings>,
) => {
  const { manageModal, modalProps, modalSettings } = props;

  const onClose = () => {
    manageModal({
      visible: false,
      modalType: 'ModalCreateDraftProject',
    });
  };
  const onOpenChange = (isModalOpen: boolean) => {
    if (!isModalOpen) {
      onClose();
    }
  };
  const onConfirm = () => {
    modalSettings.onConfirm();
    onClose();
  };
  return {
    open: modalProps.open,
    onOpenChange,
    onConfirm,
  };
};
