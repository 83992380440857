import posthog from 'posthog-js';

const POSTHOG_API_KEY = 'phc_jEB2IBNDFvYV0bAHA3VHl7jEoa5WcsJkQAsQhsntkBF';

export const initPostHog = () => {
  posthog.init(POSTHOG_API_KEY, {
    api_host: 'https://us.i.posthog.com',
    person_profiles: 'identified_only',
  });
};
