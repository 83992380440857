import React from 'react';

export default function ({ color = '#fff', className = '' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="13"
      viewBox="0 0 8 13"
      className={className}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g className="fill" fill={color}>
          <polygon points="7.99997559 1.5 6.5 0 0 6.5 6.5 13 7.99997559 11.5 3 6.5" />
        </g>
      </g>
    </svg>
  );
}
