import React from 'react';

import { StyledH2, StyledH4 } from './styles';

const PageHeader = ({ header, subheader }) => (
  <>
    <StyledH2>{header}</StyledH2>
    <StyledH4>{subheader}</StyledH4>
  </>
);

export default PageHeader;
