import React from 'react';

export default function ({ width = '32', height = '26', color = '#979797' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 26"
      className="icon-canvas"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(1.000000, 1.000000)"
          stroke={color}
          strokeWidth="2"
        >
          <g>
            <path d="M2,0 L28,0 C29.1045695,-2.02906125e-16 30,0.8954305 30,2 L30,22 C30,23.1045695 29.1045695,24 28,24 L2,24 C0.8954305,24 1.3527075e-16,23.1045695 0,22 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 Z" />
            <polyline points="0 20 9 11 17 19 22 14 29.5 21.5" />
            <circle cx="23.5" cy="6.5" r="2.5" />
          </g>
        </g>
      </g>
    </svg>
  );
}
