/** 
 * Represents the deployment environment (for the web app)
 * or deployment channel (for mobile/desktop).
 
 * Derived from the related Float API subdomains.
 */
export enum FloatDeploymentEnvironment {
  Develop = 'develop',
  Local = 'local',
  Prod = 'prod',
  Staging = 'staging',
}

export const FLOAT_DEPLOYMENT_ENVIRONMENTS = Object.values(
  FloatDeploymentEnvironment,
);

export const FLOAT_PRODUCTION_AND_STAGING_DEPLOYMENT_ENVIRONMENTS = [
  FloatDeploymentEnvironment.Prod,
  FloatDeploymentEnvironment.Staging,
];

// Derived from conventional NODE_ENV values
export enum FloatBuildMode {
  Development = 'development',
  Production = 'production',
  Test = 'test',
}

export const FLOAT_BUILD_MODES = Object.values(FloatBuildMode);
