import React from 'react';
import { connect } from 'react-redux';
import { getPeopleTagOptions } from 'selectors';

import Tags from 'components/elements/BulkEditModal/Tags';

const PeopleTags = React.forwardRef((props, ref) => {
  const { people, options, ...rest } = props;
  return <Tags ref={ref} entities={people} options={options} {...rest} />;
});

const mapStateToProps = (state) => ({
  options: getPeopleTagOptions(state),
  people: state.people.people,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  PeopleTags,
);
