import { Milestone } from '@float/types';

import request from '../lib/request';

export type MilestoneParams = {
  id: number | undefined;
  milestone_id: number | undefined;
  name: string;
  project_id: number;
  phase_id: number | undefined;
  date: string | undefined;
  end_date: string | undefined;
};

export function fetchMilestones(params: { project_id?: number } = {}) {
  return request.get(
    'milestones',
    { ...params, 'per-page': 8000 },
    { version: 'f3' },
  ) as Promise<Milestone[]>;
}

export function createMilestone(params: MilestoneParams): Promise<Milestone> {
  return request.post(`milestones`, params, { version: 'f3' });
}

export function updateMilestone(
  params: MilestoneParams,
  id: Milestone['milestone_id'],
): Promise<Milestone> {
  return request.put(`milestones/${id}`, params, { version: 'f3' });
}

export function deleteMilestone(
  id: Milestone['milestone_id'],
  force_skip_af?: boolean,
) {
  return request.del(
    `milestones/${id}`,
    force_skip_af ? { force_skip_af } : null,
    {
      version: 'f3',
    },
  );
}
