import { ReduxStateStrict } from '@float/common/reducers/lib/types';

import {
  searchSelectors,
  SearchWorkerSelectors,
  SearchWorkerSelectorsKeys,
  SelectorParameters,
} from './searchSelectors';

export async function getSelectorValue<S extends SearchWorkerSelectorsKeys>(
  state: ReduxStateStrict,
  selectorKey: S,
  args: SelectorParameters<SearchWorkerSelectors[S]>,
) {
  // @ts-expect-error Typescript doesn't like the mix of Parameters and spread argument
  // because it requires tuples when doing spread
  return searchSelectors[selectorKey](state, ...args) as ReturnType<
    SearchWorkerSelectors[S]
  >;
}
