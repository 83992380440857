import { memoizeWithArgs } from 'proxy-memoize';

import { ReduxState } from '@float/common/reducers/lib/types';
import { useAppSelector } from '@float/common/store';
import { LoggedTime, Task, TaskReference } from '@float/types';
import { getPMUrl } from '@float/web/components/taskModals/helpers';
import { integrationTypes } from '@float/web/pmSidebar/integrationTypes';
import {
  getActivePmIntegration,
  getPmTaskLinks,
} from '@float/web/pmSidebar/reducers/selectors';
import {
  PmActiveIntegration,
  PmTaskLinkMap,
} from '@float/web/pmSidebar/reducers/types';

const getPmIntegrationInfo = memoizeWithArgs(
  (state: ReduxState, entity: Task | LoggedTime | TaskReference) => {
    const activeIntegration = getActivePmIntegration(state) as
      | PmActiveIntegration
      | {};
    const taskLinksMap: PmTaskLinkMap = getPmTaskLinks(state);

    const baseUrl =
      'baseUrl' in activeIntegration ? activeIntegration.baseUrl : undefined;
    const type =
      'type' in activeIntegration ? activeIntegration.type : undefined;

    const project = state.projects.projects[entity.project_id];

    const pmUrl = getPMUrl(entity, project, baseUrl, type, taskLinksMap, {
      isLoggedTime: true,
    });

    const integrationType = type
      ? integrationTypes[type as keyof typeof integrationTypes]
      : undefined;
    const integrationName = integrationType && integrationType.title;

    return {
      url: pmUrl,
      integrationName,
    };
  },
);

export const usePmIntegrationInfo = (entity: LoggedTime | TaskReference) =>
  useAppSelector((state) => getPmIntegrationInfo(state, entity));
