import { getClientsTable } from './table/clients';
import { getProjectsTable } from './table/projects';
import {
  ProjectsOverviewTableContext,
  ProjectsOverviewTableDataRaw,
} from './table/types';

export default function parseTableData(
  ctx: ProjectsOverviewTableContext,
  rawData: ProjectsOverviewTableDataRaw | null,
) {
  return {
    projects: getProjectsTable(ctx, rawData),
    clients: getClientsTable(ctx, rawData),
  };
}
