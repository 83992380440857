import { Control, useWatch } from 'react-hook-form';

import { Rights } from '@float/common/lib/acl';
import { userCanChangeProjectEditRights } from '@float/common/lib/rights';
import { getUser } from '@float/common/selectors/currentUser';
import {
  getProjectById,
  userCanSeeBudgets,
} from '@float/common/selectors/projects';
import {
  useAppSelectorStrict,
  useAppSelectorWithParams,
} from '@float/common/store';

import { ProjectFormData } from '../types';

export function useProjectACL(control?: Control<ProjectFormData>) {
  const projectId = useWatch<ProjectFormData, 'projectId'>({
    name: 'projectId',
    control,
  });

  // Getting the project data from the store to apply the ACL checks on the starting value of the project
  // (e.g. changing owner may otherwise disable the canChangeEditRights right)
  const project = useAppSelectorWithParams(getProjectById, projectId);

  const canSeeBudget = useAppSelectorStrict((state) =>
    userCanSeeBudgets(getUser(state)),
  );

  const canDelete = useAppSelectorStrict((state) =>
    Rights.canDeleteProject(getUser(state), { project }),
  );

  const canChangeEditRights = useAppSelectorStrict((state) =>
    userCanChangeProjectEditRights(getUser(state), project),
  );

  return {
    canDelete,
    canSeeBudget,
    canChangeEditRights,
  };
}
