import React, { createElement, FunctionComponent } from 'react';
import { AnimatePresence } from 'framer-motion';

import { PanelType } from '../../sidePanel/types';
import { useSidePanel } from '../../sidePanel/useSidePanel';
import { PhasePanelController } from '../panelViews/Project/PhasePanelController';
import { ProjectPanelController } from '../panelViews/Project/ProjectPanelController';
import { TemplatePanelController } from '../panelViews/Project/TemplatePanelController';

const PANEL_TYPES: Record<PanelType, FunctionComponent> = {
  phasePanel: PhasePanelController,
  projectPanel: ProjectPanelController,
  templatePanel: TemplatePanelController,
};

const Panel: React.FC<{ panelType: PanelType }> = ({ panelType }) => {
  const { openPanels, sidePanelOpen } = useSidePanel();

  if (
    !sidePanelOpen ||
    !openPanels.find((panel) => panel.panelType === panelType)
  ) {
    return null;
  }
  return createElement(PANEL_TYPES[panelType]);
};

export const SidePanelController: React.FC = () => {
  return (
    <>
      <AnimatePresence>
        {Object.keys(PANEL_TYPES).map((type) => (
          <Panel key={type} panelType={type as PanelType} />
        ))}
      </AnimatePresence>
    </>
  );
};
