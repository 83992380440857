import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { getUser } from 'selectors';

import { CurrentUser, Person } from '@float/types';

export type UseStartTimerRightsParams = {
  currentLoggedTimeDate: string;
  currentLoggedTimePerson: Person | CurrentUser | undefined;
  hasTimers: boolean;
};

export function useStartTimerRights(params: UseStartTimerRightsParams) {
  const { currentLoggedTimeDate, currentLoggedTimePerson, hasTimers } = params;

  const currentUser = useSelector(getUser) as CurrentUser;

  const today = format(Date.now(), 'yyyy-MM-dd');

  // FT-1702
  const isLoggedTimeNotTodayWithTimerEvents =
    today !== currentLoggedTimeDate && hasTimers;

  // FT-1465
  const isLoggedTimeFromAnotherUser =
    currentUser.people_id !== currentLoggedTimePerson?.people_id;

  return {
    canStartTimer:
      !isLoggedTimeNotTodayWithTimerEvents && !isLoggedTimeFromAnotherUser,
  };
}
