// TODO: These vendor files will be refactored in the next tech debt cycle to remove deprecated conventions

import React from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import calendar from 'calendar';
import createClass from 'create-react-class';
import Immutable from 'immutable';

import { moment } from '@float/libs/moment';

import CalendarDate from './calendar/CalendarDate';
import CalendarMonth from './calendar/CalendarMonth';
import Legend from './Legend';
import PaginationArrow from './PaginationArrow';
import DateRangePickerBemMixin from './utils/DateRangePickerBemMixin';
import isMomentRange from './utils/isMomentRange';

const absoluteMinimum = moment(new Date(-8640000000000000 / 2)).startOf('day');
const absoluteMaximum = moment(new Date(8640000000000000 / 2)).startOf('day');

function noop() {}

const DateRangePicker = createClass({
  mixins: [DateRangePickerBemMixin, PureRenderMixin],
  displayName: 'DateRangePicker',

  getDefaultProps() {
    const date = new Date();
    // TODO: Where is initialDate used?
    const initialDate = new Date(
      // returns the year of a date as a four digit number
      date.getFullYear(),
      // returns the month of a date as a number (0-11)
      date.getMonth(),
      // returns the day as a number (1-31)
      date.getDate(),
    );

    return {
      onChangeNativeSelect: null,
      bemNamespace: null,
      bemBlock: 'DateRangePicker',
      className: '',
      numberOfCalendars: 1,
      firstOfWeek: 0,
      disableNavigation: false,
      nextLabel: '',
      previousLabel: '',
      initialDate,
      initialFromValue: true,
      locale: moment().locale(),
      selectionType: 'range',
      singleDateRange: false,
      stateDefinitions: {
        __default: {
          color: null,
          selectable: true,
          label: null,
        },
      },
      selectedLabel: 'Your selected dates',
      defaultState: '__default',
      dateStates: [],
      showLegend: false,
      onSelect: noop,
      paginationArrowComponent: PaginationArrow,
      isStartDateSelectable: (date) => true,
      isEndDateSelectable: (date) => true,
    };
  },

  // Construct a year/month object pair of corresponding attribute for date
  getYearMonth(date, isEndCalendar) {
    if (!moment.isMoment(date)) {
      return undefined;
    }

    const yearAttr = isEndCalendar ? 'endYear' : 'year';
    const monthAttr = isEndCalendar ? 'endMonth' : 'month';

    return { [yearAttr]: date.year(), [monthAttr]: date.month() };
  },

  getYearMonthProps(props) {
    const { selectionType, value, initialYear, initialMonth } = props;
    if (!(moment.isMoment(value) || isMomentRange(value))) {
      return { year: initialYear, month: initialMonth };
    }

    if (selectionType === 'single') {
      return this.getYearMonth(value);
    }

    return this.getYearMonth(value.start);
  },

  getInitialState() {
    const now = new Date();
    const { initialYear, initialMonth, initialFromValue, value } = this.props;
    let year = now.getFullYear();
    let month = now.getMonth();
    let endYear;
    let endMonth;

    if (Number.isInteger(initialYear) && Number.isInteger(initialMonth)) {
      year = initialYear;
      month = initialMonth;
    }

    // sets the year and month of the start moment if range
    if (initialFromValue && (moment.isMoment(value) || isMomentRange(value))) {
      // yearMonth is set to { year: date.year(), month: date.month() };
      // where date is the props.value.start moment of range or props.value if single
      const yearMonth = this.getYearMonthProps(this.props);
      month = yearMonth.month;
      year = yearMonth.year;
      if (isMomentRange(value)) {
        const validVisibleEnd = value.end.clone();
        if (
          month === validVisibleEnd.month() &&
          year === validVisibleEnd.year()
        ) {
          validVisibleEnd.add(1, 'month');
        }
        endYear = validVisibleEnd.year();
        endMonth = validVisibleEnd.month();
      }
    }

    return {
      year,
      month,
      endYear,
      endMonth,
      selectedStartDate: null,
      highlightedDate: null,
      highlightRange: null,
      hideSelection: false,
      enabledRange: this.getEnabledRange(this.props),
      dateStates: this.getDateStates(this.props),
    };
  },

  componentDidUpdate() {
    const newRange = this.getEnabledRange(this.props);
    if (
      this.state.enabledRange.start.diff(newRange.start, 'days') !== 0 ||
      this.state.enabledRange.end.diff(newRange.end, 'days') !== 0
    ) {
      this.setState({ enabledRange: newRange });
    }
  },

  getEnabledRange(props) {
    const min = props.minimumDate
      ? moment(props.minimumDate).startOf('day')
      : absoluteMinimum;
    const max = props.maximumDate
      ? moment(props.maximumDate).startOf('day')
      : absoluteMaximum;

    return moment.range(min, max);
  },

  getDateStates(props) {
    const { dateStates, defaultState, stateDefinitions } = props;
    const actualStates = [];
    const minDate = absoluteMinimum;
    const maxDate = absoluteMaximum;
    let dateCursor = moment(minDate).startOf('day');

    const defs = Immutable.fromJS(stateDefinitions);

    dateStates.forEach(function (s) {
      const r = s.range;
      const start = r.start.startOf('day');
      const end = r.end.startOf('day');

      if (!dateCursor.isSame(start, 'day')) {
        actualStates.push({
          state: defaultState,
          range: moment.range(dateCursor, start),
        });
      }
      actualStates.push(s);
      dateCursor = end;
    });

    actualStates.push({
      state: defaultState,
      range: moment.range(dateCursor, maxDate),
    });

    // sanitize date states
    return Immutable.List(actualStates).map(function (s) {
      const def = defs.get(s.state);
      return Immutable.Map({
        range: s.range,
        state: s.state,
        selectable: def.get('selectable', true),
        color: def.get('color'),
      });
    });
  },

  isDateDisabled(date) {
    const outsideMinMax = !this.state.enabledRange.contains(date);
    if (this.props.disableDayFn) {
      const customDisabledDay = this.props.disableDayFn(date);
      return customDisabledDay || outsideMinMax;
    }
    return outsideMinMax;
  },

  isDateSelectable(date) {
    return (
      this.dateRangesForDate(date).some((r) => r.get('selectable')) &&
      (!this.props.hasLockPeriodAccess || this.props.hasLockPeriodAccess(date))
    );
  },

  nonSelectableStateRanges() {
    return this.state.dateStates.filter((d) => !d.get('selectable'));
  },

  dateRangesForDate(date) {
    return this.state.dateStates.filter((d) => d.get('range').contains(date));
  },

  sanitizeRange(range, forwards) {
    /* Truncates the provided range at the first intersection
     * with a non-selectable state. Using forwards to determine
     * which direction to work
     */
    const blockedRanges = this.nonSelectableStateRanges().map((r) =>
      r.get('range'),
    );
    let intersect;

    if (forwards) {
      intersect = blockedRanges.find((r) => range.intersect(r));
      if (intersect) {
        return moment.range(range.start, intersect.start);
      }
    } else {
      intersect = blockedRanges.findLast((r) => range.intersect(r));

      if (intersect) {
        return moment.range(intersect.end, range.end);
      }
    }

    if (range.start.isBefore(this.state.enabledRange.start)) {
      return moment.range(this.state.enabledRange.start, range.end);
    }

    if (range.end.isAfter(this.state.enabledRange.end)) {
      return moment.range(range.start, this.state.enabledRange.end);
    }

    return range;
  },

  highlightRange(range) {
    this.setState({
      highlightedRange: range,
      highlightedDate: null,
    });
    if (typeof this.props.onHighlightRange === 'function') {
      this.props.onHighlightRange(range, this.statesForRange(range));
    }
  },

  onUnHighlightDate() {
    this.setState({
      highlightedDate: null,
    });
  },

  onSelectDate(date) {
    const { selectionType, weekSelection } = this.props;
    const { selectedStartDate } = this.state;

    if (selectionType === 'range') {
      // Conditional for end date selection
      if (
        selectedStartDate &&
        !this.isDateDisabled(date) &&
        this.props.isEndDateSelectable(date)
      ) {
        this.completeRangeSelection(this.props.isMobile ? date : null);
      } else if (weekSelection) {
        this.completeRangeSelection(date);
        // Conditional for start date selection
      } else if (
        date &&
        !this.isDateDisabled(date) &&
        this.isDateSelectable(date) &&
        this.props.isStartDateSelectable(date)
      ) {
        this.startRangeSelection(date);
        if (this.props.singleDateRange) {
          this.highlightRange(moment.range(date, date));
        }
      }
    } else {
      if (!this.isDateDisabled(date) && this.isDateSelectable(date)) {
        this.completeSelection();
      }
    }
  },

  onHighlightDate(date) {
    const { selectionType } = this.props;
    const { selectedStartDate } = this.state;

    let datePair;
    let range;
    let forwards;

    if (selectionType === 'range') {
      if (selectedStartDate) {
        datePair = Immutable.List.of(selectedStartDate, date).sortBy((d) =>
          d.unix(),
        );
        range = moment.range(datePair.get(0), datePair.get(1));
        forwards = range.start.unix() === selectedStartDate.unix();
        range = this.sanitizeRange(range, forwards);
        this.highlightRange(range);
      } else if (!this.isDateDisabled(date) && this.isDateSelectable(date)) {
        this.highlightDate(date);
      }
    } else {
      if (!this.isDateDisabled(date) && this.isDateSelectable(date)) {
        this.highlightDate(date);
      }
    }
  },

  startRangeSelection(date) {
    this.setState({
      hideSelection: true,
      selectedStartDate: date,
    });
    if (typeof this.props.onSelectStart === 'function') {
      this.props.onSelectStart(moment(date));
    }
  },

  statesForDate(date) {
    return this.state.dateStates
      .filter((d) => date.within(d.get('range')))
      .map((d) => d.get('state'));
  },

  statesForRange(range) {
    if (range.start.isSame(range.end, 'day')) {
      return this.statesForDate(range.start);
    }
    return this.state.dateStates
      .filter((d) => d.get('range').intersect(range))
      .map((d) => d.get('state'));
  },

  completeSelection() {
    const highlightedDate = this.state.highlightedDate;
    if (highlightedDate) {
      this.setState({
        hideSelection: false,
        highlightedDate: null,
      });
      this.props.onSelect(highlightedDate, this.statesForDate(highlightedDate));
    }
  },

  completeRangeSelection(date) {
    if (this.props.weekSelection && date) {
      this.setState({
        selectedStartDate: null,
        highlightedRange: null,
        highlightedDate: null,
        hideSelection: false,
      });
      this.props.onSelect(date);
      return;
    }

    // Highlights don't work on mobile so we need to manually set the end date on click
    const range = this.props.isMobile
      ? {
          ...this.state.highlightedRange,
          end: date,
        }
      : this.state.highlightedRange;

    if (
      range &&
      (!range.start.isSame(range.end, 'day') || this.props.singleDateRange)
    ) {
      this.setState({
        selectedStartDate: null,
        highlightedRange: null,
        highlightedDate: null,
        hideSelection: false,
      });
      this.props.onSelect(range, this.statesForRange(range));
    }
  },

  highlightDate(date) {
    this.setState({
      highlightedDate: date,
    });
    if (typeof this.props.onHighlightDate === 'function') {
      this.props.onHighlightDate(date, this.statesForDate(date));
    }
  },
  // Refers to the first day of the month of either the start calendar or end calendar
  getMonthDate(isEndCalendar) {
    const yearAttr = isEndCalendar ? 'endYear' : 'year';
    const monthAttr = isEndCalendar ? 'endMonth' : 'month';
    return moment(new Date(this.state[yearAttr], this.state[monthAttr], 1));
  },

  // Is date visible on single calendar
  isVisibleSingle(date) {
    const yearMonth = this.getYearMonth(date);
    const isSameYear = yearMonth.year === this.state.year;
    const isSameMonth = yearMonth.month === this.state.month;
    return isSameYear && isSameMonth;
  },

  // Is date visible on either calendar
  isVisibleRange(date) {
    const year = date.year();
    const month = date.month();
    const isOnStartCalendar =
      year === this.state.year && month === this.state.month;
    const isOnEndCalendar =
      year === this.state.endYear && month === this.state.endMonth;
    return isOnStartCalendar || isOnEndCalendar;
  },

  valueIsVisible(props) {
    const { value, selectionType } = props;

    if (selectionType === 'single') {
      return this.isVisibleSingle(value);
    }

    return this.isVisibleRange(value.start) && this.isVisibleRange(value.end);
  },

  canMoveBack(isEndCalendar) {
    // Refers to the visible first day of the month of start or end calendar
    const monthDate = this.getMonthDate(isEndCalendar);
    const oneMonthEarlier = monthDate.subtract(1, 'days');
    // If we're viewing the earliest enabled range, we can't go back further
    if (oneMonthEarlier.isBefore(this.state.enabledRange.start)) {
      return false;
    }

    if (this.props.numberOfCalendars === 1) {
      return true;
    }

    // If we're the end calendar moving back, we need to check against start calendar
    if (isEndCalendar) {
      const startMoment = this.getMonthDate();
      const newEndMoment = moment(
        new Date(oneMonthEarlier.year(), oneMonthEarlier.month(), 1),
      );
      // If end calendar is now before or equal start date, we can't go back further
      if (newEndMoment.diff(startMoment) <= 0) {
        return false;
      }
    }

    return true;
  },

  canMoveForward(isEndCalendar) {
    if (this.props.weekSelection) return true;
    // Refers to the visible first day of the month of start or end calendar
    const monthDate = this.getMonthDate(isEndCalendar);
    const oneMonthLater = monthDate.add(1, 'month');
    // If we're viewing the latest enabled range, we can't advance further

    if (oneMonthLater.isAfter(this.state.enabledRange.end)) {
      return false;
    }

    if (this.props.numberOfCalendars === 1) {
      return true;
    }

    // If we're the start calendar moving forward, we need to check against end calendar
    if (!isEndCalendar) {
      const newStartMoment = moment(
        new Date(oneMonthLater.year(), oneMonthLater.month(), 1),
      );
      const endMoment = this.getMonthDate(true);
      // If start calendar is now after or equal end date, we can't advance further
      if (endMoment.diff(newStartMoment) <= 0) {
        return false;
      }
    }
    return true;
  },

  moveBack(isEndCalendar) {
    let monthDate;

    if (this.canMoveBack(isEndCalendar)) {
      monthDate = this.getMonthDate(isEndCalendar);
      monthDate.subtract(1, 'months');
      this.setState(this.getYearMonth(monthDate, isEndCalendar));
    }
  },

  moveForward(isEndCalendar) {
    let monthDate;

    if (this.canMoveForward(isEndCalendar)) {
      monthDate = this.getMonthDate(isEndCalendar);
      monthDate.add(1, 'months');
      this.setState(this.getYearMonth(monthDate, isEndCalendar));
    }
  },

  changeYear(newYear, isEndCalendar) {
    let { enabledRange, month } = this.state;

    if (
      moment({ years: newYear, months: month, date: 1 }).unix() <
      enabledRange.start.unix()
    ) {
      month = enabledRange.start.month();
    }

    if (
      moment({ years: newYear, months: month + 1, date: 1 }).unix() >
      enabledRange.end.unix()
    ) {
      month = enabledRange.end.month();
    }

    if (isEndCalendar) {
      let startMoment = this.getMonthDate();
      const newEndMoment = moment(new Date(newYear, this.state.endMonth, 1));
      // If end calendar is now before or equal start date, move start date
      if (newEndMoment.diff(startMoment) <= 0) {
        startMoment = newEndMoment.clone().subtract(1, 'month');
      }
      this.setState({
        endMonth: newEndMoment.month(),
        endYear: newEndMoment.year(),
        month: startMoment.month(),
        year: startMoment.year(),
      });
    } else {
      const newStartMoment = moment(new Date(newYear, this.state.month, 1));
      let endMoment = this.getMonthDate(true);
      // If start calendar is now after or equal end date, move end calendar
      if (endMoment.diff(newStartMoment) <= 0) {
        endMoment = newStartMoment.clone().add(1, 'month');
      }

      this.setState({
        month: newStartMoment.month(),
        year: newStartMoment.year(),
        endMonth: endMoment.month(),
        endYear: endMoment.year(),
      });
    }

    if (this.props.onChangeNativeSelect) {
      this.props.onChangeNativeSelect();
    }
  },

  changeMonth(newMonth, isEndCalendar) {
    if (isEndCalendar) {
      let startMoment = this.getMonthDate();
      const newEndMoment = moment(new Date(this.state.endYear, newMonth, 1));
      // If end calendar is now before or equal start date, move start date
      if (newEndMoment.diff(startMoment) <= 0) {
        startMoment = newEndMoment.clone().subtract(1, 'month');
      }
      this.setState({
        endMonth: newEndMoment.month(),
        endYear: newEndMoment.year(),
        month: startMoment.month(),
        year: startMoment.year(),
      });
    } else {
      const newStartMoment = moment(new Date(this.state.year, newMonth, 1));
      let endMoment = this.getMonthDate(true);
      // If start calendar is now after or equal end date, move end calendar
      if (endMoment.diff(newStartMoment) <= 0) {
        endMoment = newStartMoment.clone().add(1, 'month');
      }

      this.setState({
        month: newStartMoment.month(),
        year: newStartMoment.year(),
        endMonth: endMoment.month(),
        endYear: endMoment.year(),
      });
    }
    if (this.props.onChangeNativeSelect) {
      this.props.onChangeNativeSelect();
    }
  },

  rangesOverlap(rangeA, rangeB) {
    if (
      rangeA.overlaps(rangeB) ||
      rangeA.contains(rangeB.start) ||
      rangeA.contains(rangeB.end)
    ) {
      return true;
    }
    return false;
  },

  renderCalendar(index) {
    let {
      bemBlock,
      bemNamespace,
      firstOfWeek,
      selectionType,
      value,
      paginationArrowComponent: PaginationArrowComponent,
    } = this.props;

    let {
      dateStates,
      enabledRange,
      hideSelection,
      highlightedDate,
      highlightedRange,
    } = this.state;

    const isEndCalendar = index === 1;

    const monthDate = this.getMonthDate(isEndCalendar);

    // For range, this is the start moment's first day of the month
    const year = monthDate.year();
    const month = monthDate.month();
    const key = `${index}-${year}-${month}`;

    const cal = new calendar.Calendar(firstOfWeek);
    const monthDates = Immutable.fromJS(
      cal.monthDates(monthDate.year(), monthDate.month()),
    );
    const monthStart = monthDates.first().first();
    const monthEnd = monthDates.last().last();
    const monthRange = moment.range(monthStart, monthEnd);

    if (moment.isMoment(value) && !monthRange.contains(value)) {
      value = null;
    } else if (isMomentRange(value) && !this.rangesOverlap(monthRange, value)) {
      value = null;
    }

    if (
      !moment.isMoment(highlightedDate) ||
      !monthRange.contains(highlightedDate)
    ) {
      highlightedDate = null;
    }

    if (
      !isMomentRange(highlightedRange) ||
      !this.rangesOverlap(monthRange, highlightedRange)
    ) {
      highlightedRange = null;
    }

    const props = {
      bemBlock,
      bemNamespace,
      dateStates,
      enabledRange,
      firstOfWeek,
      hideSelection,
      highlightedDate,
      highlightedRange,
      isEndCalendar,
      key,
      selectionType,
      value,
      firstOfMonth: monthDate,
      onMonthChange: this.changeMonth,
      onYearChange: this.changeYear,
      onSelectDate: this.onSelectDate,
      onHighlightDate: this.onHighlightDate,
      onUnHighlightDate: this.onUnHighlightDate,
      dateRangesForDate: this.dateRangesForDate,
      dateComponent: CalendarDate,
      isDateDisabled: this.isDateDisabled,
      locale: this.props.locale,
      isDateInLockPeriod: this.props.isDateInLockPeriod,
      hasLockPeriodAccess: this.props.hasLockPeriodAccess,
      lockPeriod: this.props.lockPeriod,
      customSelectionComponent: this.props.customSelectionComponent,
    };

    return (
      <React.Fragment
        key={`daterange-picker-${isEndCalendar ? 'end' : 'start'}`}
      >
        <PaginationArrowComponent
          key="pagination-left"
          left={true}
          onClick={() => {
            this.moveBack(isEndCalendar);
          }}
          isEndCalendar={isEndCalendar}
          disabled={!this.canMoveBack(isEndCalendar)}
        />
        <CalendarMonth {...props} />
        <PaginationArrowComponent
          key="pagination-right"
          left={false}
          onClick={() => {
            this.moveForward(isEndCalendar);
          }}
          isEndCalendar={isEndCalendar}
          disabled={!this.canMoveForward(isEndCalendar)}
        />
      </React.Fragment>
    );
  },

  render: function () {
    let {
      className,
      numberOfCalendars,
      stateDefinitions,
      selectedLabel,
      showLegend,
      helpMessage,
    } = this.props;

    const calendars = Immutable.Range(0, numberOfCalendars).map(
      this.renderCalendar,
    );
    className = this.cx({ element: null }) + ' ' + className;

    return (
      <div className={className.trim()}>
        {calendars.toJS()}
        {helpMessage ? (
          <span className={this.cx({ element: 'HelpMessage' })}>
            {helpMessage}
          </span>
        ) : null}
        {showLegend ? (
          <Legend
            stateDefinitions={stateDefinitions}
            selectedLabel={selectedLabel}
          />
        ) : null}
      </div>
    );
  },
});

export default DateRangePicker;
