import { trackEvent as trackEventInAmplitude } from './amplitude';

// pass eventCategory, eventLabel, eventValue in eventData for feeding this into GA event tracking
export function trackEvent(
  eventName: string,
  eventData: Record<string, unknown> = {},
) {
  const { dataLayer } = window;

  if (dataLayer) {
    dataLayer.push({
      event: 'trackEvent',
      eventName,
      eventData,
      eventCallback: () => {
        // https://www.simoahava.com/gtm-tips/remember-to-flush-unused-data-layer-variables/
        dataLayer.push({ eventName: undefined, eventData: undefined });
      },
    });
  }

  trackEventInAmplitude(eventName, eventData);
}
