import { TimeDuration } from '@float/types';

function durationToHHMM(duration: TimeDuration) {
  let minutes = duration.minutes;
  minutes += Math.floor(duration.seconds / 60);
  minutes += Math.floor(duration.milliseconds / (60 * 1000));

  let hours = Math.floor(minutes / 60);

  minutes = minutes % 60;

  hours += duration.hours;
  hours += duration.days * 24;
  hours += Math.floor(duration.minutes / 60);

  const hh = String(hours).padStart(2, '0');
  const mm = String(minutes).padStart(2, '0');

  return hh + ':' + mm;
}

export { durationToHHMM };
