import React from 'react';
import { t } from '@lingui/macro';

import useMedia from '@float/libs/web/hooks/useMedia';
import { SavedView } from '@float/types';
import { IconLayers } from '@float/ui/icons/essentials/IconLayers';
import { IconShare } from '@float/ui/icons/essentials/IconShare';

import { AccessControlProps } from './types';
import { ViewEditActions } from './ViewEditActions';

import * as styles from './ViewsSelectorLabel.css';

export type ViewsSelectorLabelProps = {
  acl: AccessControlProps;
  className?: string;
  onDelete: (view: SavedView) => void;
  onEdit: (view: SavedView) => void;
  onPersonalChange: (view: SavedView, personal: boolean) => void;
  onPinChange: (view: SavedView, pinned: boolean) => void;
  view: SavedView | null;
};

export const ViewsSelectorLabel = React.forwardRef<
  HTMLButtonElement,
  ViewsSelectorLabelProps
>(
  (
    {
      acl,
      view,
      onEdit,
      onPersonalChange,
      onDelete,
      className,
      onPinChange,
      ...props
    },
    ref,
  ) => {
    const breakpoint = useMedia();

    if (!view || breakpoint === 'medium') {
      return (
        <button
          {...props}
          ref={ref}
          className={styles.noViewButton}
          aria-label={t`Open the view selector`}
          data-callout-id="views-introduction-callout"
        >
          <IconLayers />
        </button>
      );
    }

    // Using this structure to trigger the dropdowns with different buttons
    return (
      <div className={styles.container}>
        <button
          {...props}
          ref={ref}
          className={styles.innerButton}
          aria-label={t`Open the view selector`}
          data-callout-id="views-introduction-callout"
        >
          {view.personal ? (
            <IconLayers title={t`Personal View`} />
          ) : (
            <IconShare title={t`Shared View`} />
          )}
          <div className={styles.viewName}>{view.name}</div>
        </button>
        <ViewEditActions
          acl={acl}
          className={styles.actionsButton}
          onDelete={onDelete}
          onEdit={onEdit}
          onPersonalChange={onPersonalChange}
          onPinChange={onPinChange}
          sideOffset={5}
          view={view}
        />
      </div>
    );
  },
);
