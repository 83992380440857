import { moment } from '@float/libs/moment';

import {
  APP_LOADED,
  SET_HELP_MENU_VISIBILITY,
  SET_PAGE_TITLE,
  SET_POINTER_EVENTS,
  SET_PRINT_MODE,
  SET_VIEW_TYPE,
  TRIGGER_ACTION,
  UPDATE_DATES,
  UPDATE_END,
  UPDATE_START,
} from '../actions/app';
import { RECONNECTION_SUCCESSFUL } from '../liveUpdates/recovery';

const DAYS = 90;
const WEEK_LENGTH = 7;
const WEEKS = Math.ceil(DAYS / WEEK_LENGTH) + 7;

const initialStart = moment()
  .startOf('day')
  .subtract(6, 'weeks')
  .startOf('week');
const initialEnd = initialStart.clone().add(WEEKS, 'weeks').endOf('week');

const defaultState = {
  loaded: false,
  start: initialStart,
  end: initialEnd,
  printMode: false,
  pointerEvents: true,
  actionToTrigger: null,
  helpMenuVisible: false,
};

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case TRIGGER_ACTION: {
      return {
        ...state,
        actionToTrigger: action.actionType,
      };
    }

    case APP_LOADED: {
      return {
        ...state,
        loaded: true,
      };
    }

    case UPDATE_START: {
      return {
        ...state,
        start: action.start,
      };
    }

    case UPDATE_END: {
      return {
        ...state,
        end: action.end,
      };
    }

    case UPDATE_DATES: {
      return {
        ...state,
        start: action.start || state.start,
        end: action.end || state.end,
      };
    }

    case SET_PRINT_MODE: {
      return {
        ...state,
        printMode: action.printMode,
      };
    }

    case SET_POINTER_EVENTS: {
      return {
        ...state,
        pointerEvents: action.pointerEvents,
      };
    }

    case SET_PAGE_TITLE:
      return {
        ...state,
        pageTitle: action.title,
      };

    case RECONNECTION_SUCCESSFUL:
      return {
        ...state,
        reconnectedAt: new Date(),
      };

    case SET_VIEW_TYPE:
      return {
        ...state,
        viewType: action.viewType,
      };

    case SET_HELP_MENU_VISIBILITY:
      return { ...state, helpMenuVisible: action.visible };

    default: {
      return state;
    }
  }
}
