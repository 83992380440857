import React from 'react';
import { Trans } from '@lingui/macro';

import { Project } from '@float/types/project';

import * as styles from './styles.css';

export const DraftStatusProject = ({
  projectName,
}: {
  projectName: Project['project_name'];
}) => {
  return (
    <>
      <span className={styles.draftStatusProjectLabel}>
        <Trans>Project</Trans>
      </span>
      <span className={styles.draftStatusProjectTitle}>{projectName}</span>
    </>
  );
};
