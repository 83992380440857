import { useWatch } from 'react-hook-form';

import { ProjectInputData } from '@float/common/actions';
import { PhaseInputData } from '@float/types/phase';

import { ProjectFormData } from '../types';
import { useIsPhasePanel } from './useIsPhasePanel';

export type CommonKeys = Extract<keyof ProjectInputData, keyof PhaseInputData>;

/**
 * Retrieves the value of a specific field from the project or phase form data.
 * Watches the value and updates the return value on change.
 *
 * @template K - The type of the common fields between `ProjectInputData` and `PhaseInputData`.
 * @param {K} name - The name of the field to retrieve the value for.
 * @returns {ProjectInputData[K]} - The value of the specified field.
 */
export function useWatchProjectOrPhaseFieldValue<K extends CommonKeys>(
  name: K,
): ProjectInputData[K] {
  const isPhasePanel = useIsPhasePanel();
  const parent = isPhasePanel ? 'phase' : 'project';
  const value = useWatch<ProjectFormData, `phase.${K}` | `project.${K}`>({
    name: `${parent}.${name}`,
  });
  return value;
}
