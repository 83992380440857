import request from '../lib/request';

export const HOLIDAYS_LOAD_START = 'holidays/LOAD_START';
export const HOLIDAYS_LOAD_FAILED = 'holidays/LOAD_FAILED';
export const HOLIDAYS_LOAD_FINISH = 'holidays/LOAD_FINISH';

export const ADD_HOLIDAY = 'ADD_HOLIDAY';
export const ADD_HOLIDAY_SUCCESS = 'ADD_HOLIDAY_SUCCESS';
export const ADD_HOLIDAY_FAILURE = 'ADD_HOLIDAY_FAILURE';

export const DELETE_HOLIDAY = 'DELETE_HOLIDAY';
export const DELETE_HOLIDAY_SUCCESS = 'DELETE_HOLIDAY_SUCCESS';
export const DELETE_HOLIDAY_FAILURE = 'DELETE_HOLIDAY_FAILURE';

export const UPDATE_HOLIDAY = 'UPDATE_HOLIDAY';
export const UPDATE_HOLIDAY_SUCCESS = 'UPDATE_HOLIDAY_SUCCESS';
export const UPDATE_HOLIDAY_FAILURE = 'UPDATE_HOLIDAY_FAILURE';

const fetchHolidays = async () => {
  const res = await request.get(
    'holidays',
    { 'per-page': 5000 },
    { version: 'f3' },
  );
  return res;
};

export const ensureHolidaysLoaded = (forceLoad = false) => {
  return async (dispatch, getState) => {
    const { loadState: currentLoadState } = getState().holidays;

    if (!forceLoad && currentLoadState === 'LOADED') return;
    if (currentLoadState === 'LOADING') return; // There's already an in-flight load request

    try {
      dispatch({ type: HOLIDAYS_LOAD_START });
      const holidays = await fetchHolidays();
      dispatch({ type: HOLIDAYS_LOAD_FINISH, holidays });
    } catch (e) {
      console.error(e);
      dispatch({ type: HOLIDAYS_LOAD_FAILED });
    }
  };
};
