import React from 'react';
import { connect } from 'react-redux';
import { isUndefined } from 'lodash';
import { getAssignablePmOptions } from 'selectors';

import PersonAvatar from '@float/common/components/elements/PersonAvatar';
import { Checkbox, VirtualSelect } from '@float/ui/deprecated';

const dropdownStyle = { marginBottom: 20 };

function getDefaultValue({ projects, ids, key, fallback = null }) {
  const defaultValue = projects[ids[0]][key];
  if (
    !isUndefined(defaultValue) &&
    ids.every((id) => projects[id][key] === defaultValue)
  ) {
    return defaultValue;
  }
  return fallback;
}

class ProjectOwner extends React.Component {
  componentDidMount() {
    this.setDefaultValue();
  }

  getFields = async () => {
    const { value } = this.props;
    const fields = {};

    if (value.project_manager !== this.initialValue.project_manager) {
      fields.creator_id = value.project_manager;
    }

    if (value.common !== this.initialValue.common) {
      fields.common = +value.common;
    }
    return fields;
  };

  setDefaultValue = () => {
    const { ids, projects, options, onChange } = this.props;
    this.initialValue = {
      project_manager: getDefaultValue({
        projects,
        ids,
        key: 'project_manager',
        fallback: options[0].value,
      }),
      common: getDefaultValue({
        projects,
        ids,
        key: 'common',
        fallback: false,
      }),
    };
    onChange(this.initialValue);
  };

  onPmChange = ({ value: project_manager }) => {
    this.props.onChange({ ...this.props.value, project_manager });
  };

  onCommonChange = (common) => {
    this.props.onChange({ ...this.props.value, common });
  };

  render() {
    const { value, options } = this.props;

    const optionsWithAvatars = options.map((o) => ({
      value: o.value,
      label: o.label,
      icon: (
        <PersonAvatar accountId={o.account.account_id} readOnly size="xs" />
      ),
    }));

    return (
      <>
        <VirtualSelect
          label="Project Owner"
          style={dropdownStyle}
          visibleItems={6}
          nonNullable
          options={optionsWithAvatars}
          value={value.project_manager}
          onChange={this.onPmChange}
        />
        <Checkbox
          label="All Project Managers have edit rights"
          value={value.common}
          onChange={this.onCommonChange}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  projects: state.projects.projects,
  options: getAssignablePmOptions(state),
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  ProjectOwner,
);
