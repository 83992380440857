import { useEffect } from 'react';

type EventListenerOptions =
  | boolean
  | {
      capture?: boolean;
      once?: boolean;
      passive?: boolean;
      signal?: AbortSignal;
    };

/**
 * Utility hook for adding event listeners to anything that matches the
 * `addEventListener/removeEventListener` interface.
 *
 * Takes care of cleanup and reduces need for additional unit testing.
 *
 * Is mockable, see `./__mocks__/useEventListenerOnElement`.
 *
 * For global event subscriptions use `useEvent` from `react-use`.
 */

export function useEventListenerOnElement<Event, Listener>(
  target: {
    current?: {
      addEventListener: (
        event: Event,
        listener: Listener,
        eventListenerOptions?: boolean | EventListenerOptions,
      ) => void | null;
      removeEventListener: (event: Event, listener: Listener) => void | null;
    } | null;
  },
  event: Event,
  listener: Listener,
  eventListenerOptions?: boolean | EventListenerOptions,
  options?: { disabled: boolean },
): void {
  const isDisabled = options?.disabled;

  useEffect(() => {
    if (isDisabled) {
      return;
    }

    const { current } = target;

    current?.addEventListener(event, listener, eventListenerOptions);

    return () => {
      current?.removeEventListener(event, listener);
    };
  }, [target, event, listener, eventListenerOptions, isDisabled]);
}
