import differenceInDays from 'date-fns/differenceInDays';

import { Timeframe } from '@float/types/timeframes';

import { CycleConfig, CycleEdgeCase, CycleVariant } from '../Cycles.types';
import { buildSingleCycle } from './buildSingleCycle';
import {
  getDoesTimeframeStartInBounds,
  getIndexOfFirstTimeFrameInRange,
  getIndexOfLastTimeFrameInRange,
} from './dateBoundaries';
import { getTotalDays } from './getTotalDays';

// Note that we're mutating the `allCycles` array here – not a great pattern in
// general, but it keeps the code cleaner and the methods are not public
const addCooldownBetweenCyclesIfNeeded = (
  allCycles: CycleConfig[],
  currentTimeframe: Timeframe,
  nextTimeFrame: Timeframe,
) => {
  const daysBetweenTimeframes = differenceInDays(
    nextTimeFrame.startDate,
    currentTimeframe.endDate,
  );

  if (daysBetweenTimeframes > 1) {
    allCycles.push({
      variant: CycleVariant.Cooldown,
      totalDays: daysBetweenTimeframes - 1,
      key: `${currentTimeframe.name}-cooldown`,
    });
  }
};

const addFirstCycle = (
  index: number,
  allCycles: CycleConfig[],
  timeframes: Timeframe[],
  startDate: Date,
  endDate: Date,
  today: Date,
) => {
  const firstTimeframe = timeframes[index];
  const firstCycle = buildSingleCycle(index, timeframes, startDate, today);

  const daysBetweenStartDateAndStartOfFirstTimeframe = differenceInDays(
    firstTimeframe.startDate,
    startDate,
  );
  const daysBetweenEndDateAndEndOfFirstTimeframe = differenceInDays(
    firstTimeframe.endDate,
    endDate,
  );

  const isTimeFrameStartInBounds = getDoesTimeframeStartInBounds(
    firstTimeframe,
    startDate,
  );
  const isTimeframeStartOutOfBounds = !isTimeFrameStartInBounds;

  const isTimeframeEndOutOfBounds =
    daysBetweenEndDateAndEndOfFirstTimeframe > 0;

  // We are using property mutations here to adjust the cycle parameters based
  // on edge cases. The `buildAllCycles` module fully "owns" the cycles data at
  // this point in the execution so these mutations are safe. Once ownership
  // is relinquished to the rendering components, mutations are NOT safe.

  if (isTimeframeStartOutOfBounds && isTimeframeEndOutOfBounds) {
    firstCycle.edgeCase = CycleEdgeCase.StartsAndEndsOutOfBounds;
    firstCycle.totalDays = getTotalDays(endDate, startDate);
    firstCycle.spanDays = firstCycle.totalDays; // No start or end indicator, so `spanLength` === `length`

    allCycles.push(firstCycle);

    return;
  }

  if (isTimeframeStartOutOfBounds) {
    firstCycle.edgeCase = CycleEdgeCase.StartsOutOfBounds;
    firstCycle.totalDays = getTotalDays(firstTimeframe.endDate, startDate);
    firstCycle.spanDays = firstCycle.totalDays - 1; // Only one day is used up for the end indicator

    allCycles.push(firstCycle);

    return;
  }

  if (isTimeFrameStartInBounds && firstTimeframe.startDate > startDate) {
    allCycles.push({
      variant: CycleVariant.Cooldown,
      totalDays: daysBetweenStartDateAndStartOfFirstTimeframe,
      key: `pre-${firstTimeframe.name}-cooldown`,
    });
  }

  if (isTimeframeEndOutOfBounds) {
    firstCycle.edgeCase = CycleEdgeCase.EndsOutOfBounds;
    firstCycle.totalDays = getTotalDays(endDate, firstTimeframe.startDate);
    firstCycle.spanDays = firstCycle.totalDays - 1; // Only one day is used up for the start indicator

    allCycles.push(firstCycle);

    return;
  }

  allCycles.push(firstCycle);
};

const addLastCycle = (
  index: number,
  allCycles: CycleConfig[],
  timeframes: Timeframe[],
  startDate: Date,
  endDate: Date,
  today: Date,
) => {
  const lastTimeframeInRange = timeframes[index];
  const lastCycle = buildSingleCycle(index, timeframes, startDate, today);

  const daysBetweenEndDateAndEndOfTimeframe = differenceInDays(
    lastTimeframeInRange.endDate,
    endDate,
  );
  const isLastTimeframeEndOutOfBounds = daysBetweenEndDateAndEndOfTimeframe > 0;

  if (isLastTimeframeEndOutOfBounds) {
    lastCycle.edgeCase = CycleEdgeCase.EndsOutOfBounds;
    lastCycle.totalDays = differenceInDays(
      endDate,
      lastTimeframeInRange.startDate,
    );
    lastCycle.spanDays = lastCycle.totalDays - 1; // One day is used up for the start indicator
  }

  allCycles.push(lastCycle);
};

/**
 * Uses the provided Timeframes and dates to derive an array of CycleConfigs (including
 * empty "cooldown" cycles). These CycleConfigs are passed to the Cycles component to be
 * rendered sequentially.
 *
 * @param timeframes
 * @param startDate - The start of the time period we want to render the cycles in
 * @param endDate - The end of the time period we want to render the cycles in
 * @param today
 */

export const buildAllCycles = (
  timeframes: Timeframe[],
  startDate: Date,
  endDate: Date,
  today: Date,
): CycleConfig[] => {
  const allCycles: CycleConfig[] = [];

  const indexOfFirstTimeframeInRange = getIndexOfFirstTimeFrameInRange(
    timeframes,
    startDate,
    endDate,
  );

  const thereAreNoTimeframesInRange = indexOfFirstTimeframeInRange === -1;

  if (thereAreNoTimeframesInRange) {
    return allCycles;
  }

  const indexOfLastTimeframeInRange = getIndexOfLastTimeFrameInRange(
    timeframes,
    startDate,
    endDate,
  );

  addFirstCycle(
    indexOfFirstTimeframeInRange,
    allCycles,
    timeframes,
    startDate,
    endDate,
    today,
  );

  const onlyOneTimeFrameIsInRange =
    indexOfFirstTimeframeInRange === indexOfLastTimeframeInRange;

  if (onlyOneTimeFrameIsInRange) {
    return allCycles;
  }

  const firstTimeframeInRange = timeframes[indexOfFirstTimeframeInRange];
  const secondTimeframeIndex = indexOfFirstTimeframeInRange + 1;

  addCooldownBetweenCyclesIfNeeded(
    allCycles,
    firstTimeframeInRange,
    timeframes[secondTimeframeIndex],
  );

  // Add all the cycles between the first and last timeframes
  for (let i = secondTimeframeIndex; i < indexOfLastTimeframeInRange; i++) {
    const cycle = buildSingleCycle(i, timeframes, startDate, today);

    allCycles.push(cycle);
    addCooldownBetweenCyclesIfNeeded(
      allCycles,
      timeframes[i],
      timeframes[i + 1],
    );
  }

  addLastCycle(
    indexOfLastTimeframeInRange,
    allCycles,
    timeframes,
    startDate,
    endDate,
    today,
  );

  return allCycles;
};
