import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { vestResolver } from '@hookform/resolvers/vest';
import { t, Trans } from '@lingui/macro';
import { updatePreventPanelOverride } from 'sidePanel/actions';
import { useSidePanelCloseInteractionController } from 'sidePanel/useSidePanelCloseInteractionController';

import { useAppDispatchDecorator } from '@float/common/store';
import { TaskMeta } from '@float/types/task';
import { AccordionRoot } from '@float/ui/components/Accordion/AccordionRoot';
import { Button } from '@float/ui/deprecated';
import { PanelType } from '@float/web/sidePanel/types';
import { SidePanel } from 'components/SidePanel/SidePanel';
import { SidePanelActions } from 'components/SidePanel/SidePanelActions';
import { SidePanelContent } from 'components/SidePanel/SidePanelContent';

import { UnsavedChangesConfirmModal } from '../../modals/UnsavedChangesConfirmModal';
import { AccordionEntry, useAccordionState } from './hooks/useAccordionState';
import { UseFieldArrayProvider } from './hooks/useFieldArrayContext';
import { usePanelViewVisibility } from './hooks/usePanelViewVisibility';
import { getProjectFormValidationSuite } from './lib/projectFormValidationSuite';
import { ProjectBudgetSection } from './sections/ProjectBudgetSection/ProjectBudgetSection';
import { ProjectHeaderSection } from './sections/ProjectHeaderSection';
import { ProjectInfoSection } from './sections/ProjectInfoSection/ProjectInfoSection';
import { ProjectMilestonesSection } from './sections/ProjectMilestonesSection';
import { ProjectPhasesSection } from './sections/ProjectPhasesSection';
import { ProjectTasksSection } from './sections/ProjectTasksSection';
import { ProjectTeamSection } from './sections/ProjectTeamSection';
import { ProjectFormData } from './types';

import * as styles from './styles.css';

export type ProjectPanelViewProps = {
  onClose: () => void;
  isOpen: boolean;
  onSubmit: (values: ProjectFormData, defaultValues: ProjectFormData) => void;
  values: ProjectFormData;
  taskMetas?: TaskMeta[];
  isEditing?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  delayError?: number;
  animate?: boolean;
  openSections?: AccordionEntry[];
};

export const ProjectPanelView: React.FC<ProjectPanelViewProps> = ({
  values,
  taskMetas,
  openSections = [],
  animate,
  isEditing = false,
  isLoading = false,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const updateOverride = useAppDispatchDecorator(updatePreventPanelOverride);

  const resolver = useMemo(
    () =>
      vestResolver<ProjectFormData, undefined>(
        getProjectFormValidationSuite(values.type),
        undefined,
        {
          mode: 'sync',
        },
      ),
    [values.type],
  );

  const methods = useForm<ProjectFormData>({
    defaultValues: values,
    values,
    resetOptions: {
      keepDirtyValues: true,
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    resolver,
  });

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting },
    trigger,
  } = methods;

  const shouldBlockCloseInteraction = isDirty;

  const { isTemplate, isBudgetSectionVisible, isPhasesSectionVisible } =
    usePanelViewVisibility({
      type: values.type,
      budgetType: values.project.budget_type,
      budgetPriority: values.project.budget_priority,
      control: methods.control,
    });
  const panelType = isTemplate
    ? PanelType.TemplatePanel
    : PanelType.ProjectPanel;
  const closeInteraction = useSidePanelCloseInteractionController(
    onClose,
    shouldBlockCloseInteraction,
    panelType,
  );

  const handleFormSubmit = handleSubmit(async (data: ProjectFormData) => {
    try {
      await onSubmit(data, methods.formState.defaultValues as ProjectFormData);
    } catch {
      // Do not close side panel if there are errors
      return;
    }

    // Check if the form is valid
    // we can't rely on formState as it doesn't have getters
    if (await trigger()) {
      updateOverride(false, panelType);
      onClose();
    }
  });

  function handleOpenChange(isOpen: boolean) {
    if (!isOpen) {
      closeInteraction.handleCloseButtonClick();
    }
  }

  const accordion = useAccordionState(openSections);
  const showSubmitActions = !isLoading;
  const isArchived = !values.project.active;

  return (
    <>
      <SidePanel
        onOpenChange={handleOpenChange}
        isOpen={isOpen}
        animate={animate}
        formType={values.type}
      >
        <FormProvider {...methods}>
          <div className={styles.projectFormWrapper}>
            <SidePanelContent>
              <form onSubmit={handleFormSubmit} autoComplete="off">
                <ProjectHeaderSection
                  isEditing={isEditing}
                  isLoading={isLoading}
                  isArchived={isArchived}
                />
              </form>
              <div
                className={styles.projectAccordionWrapper}
                data-loading={isLoading}
              >
                <AccordionRoot
                  type="multiple"
                  value={accordion.value}
                  onValueChange={accordion.onValueChange}
                >
                  <form onSubmit={handleFormSubmit} autoComplete="off">
                    <ProjectInfoSection />
                  </form>
                  {isBudgetSectionVisible && (
                    <form onSubmit={handleFormSubmit} autoComplete="off">
                      <ProjectBudgetSection
                        taskMetas={taskMetas}
                        onAccordionOpenChange={accordion.setOpenState}
                      />
                    </form>
                  )}
                  <UseFieldArrayProvider name="team">
                    <ProjectTeamSection isLoading={isLoading} />
                  </UseFieldArrayProvider>
                  {isPhasesSectionVisible && (
                    <UseFieldArrayProvider name="phases">
                      <ProjectPhasesSection />
                    </UseFieldArrayProvider>
                  )}
                  <ProjectTasksSection />
                  <ProjectMilestonesSection />
                </AccordionRoot>
              </div>
            </SidePanelContent>
            {showSubmitActions ? (
              <SidePanelActions className={styles.sidePanelActions}>
                <Button
                  size="large"
                  type="button"
                  appearance="secondary"
                  onClick={closeInteraction.handleCloseButtonClick}
                >
                  <Trans>Cancel</Trans>
                </Button>
                <Button
                  size="large"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={handleFormSubmit}
                >
                  <SubmitLabel isEditing={isEditing} isTemplate={isTemplate} />
                </Button>
              </SidePanelActions>
            ) : (
              // To enable submit on enter when an input is on focus
              <input
                type="submit"
                className={styles.hidden}
                disabled={isSubmitting}
              />
            )}
          </div>
        </FormProvider>
        {closeInteraction.isBlocked && (
          <UnsavedChangesConfirmModal
            onConfirm={closeInteraction.handleLeavePromptConfirm}
            onCancel={closeInteraction.handleLeavePromptCancel}
          />
        )}
      </SidePanel>
    </>
  );
};

function SubmitLabel(props: { isEditing: boolean; isTemplate: boolean }) {
  const { isEditing, isTemplate } = props;

  if (isTemplate) {
    return isEditing ? t`Update template` : t`Create template`;
  }

  return isEditing ? t`Update project` : t`Create project`;
}
