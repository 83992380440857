import {
  FilterMatcher,
  FiltersContext,
  FiltersEntity,
  FilterTypes,
} from '../../types';
import {
  getAccountsIdsByName,
  getClientsIdsByName,
  getDeparmentsIdsByName,
  getPeopleIdsByJobTitle,
  getPeopleIdsByManager,
  getPeopleIdsByName,
  getPeopleIdsByTag,
  getPeopleIdsByType,
  getPhasesIdsByName,
  getProjectsIdsByName,
  getProjectsIdsByTag,
} from './lib/extractors';
import {
  matchProjectByStatus,
  matchString,
  matchTaskByStatus,
  UNNAMED_TASK,
} from './lib/matchers';

export class LoggedTimesFilterMatcher implements FilterMatcher<'loggedTimes'> {
  ids: Record<string, Set<number | string | undefined> | undefined> = {};
  type: FilterTypes;
  context: FiltersContext<'loggedTimes'>;
  values: string[];
  partialMatch: boolean;
  forceMatch = false;

  constructor(
    context: FiltersContext<'loggedTimes'>,
    type: FilterTypes,
    values: string[],
    partialMatch = false,
  ) {
    this.context = context;
    this.type = type;
    this.values = values;
    this.partialMatch = partialMatch;
  }

  private getIdsByValue(value: string) {
    switch (this.type) {
      case 'client': {
        return getClientsIdsByName(this.context, value, this.partialMatch);
      }
      case 'department': {
        return getDeparmentsIdsByName(this.context, value, this.partialMatch);
      }
      case 'jobTitle': {
        return getPeopleIdsByJobTitle(this.context, value, this.partialMatch);
      }
      case 'manager': {
        return getPeopleIdsByManager(this.context, value, this.partialMatch);
      }
      case 'me': {
        const people_id = this.context.user.people_id;
        return new Set<number>(people_id ? [people_id] : []);
      }
      case 'person': {
        return getPeopleIdsByName(this.context, value, this.partialMatch);
      }
      case 'personTag': {
        return getPeopleIdsByTag(this.context, value, this.partialMatch);
      }
      case 'personType': {
        return getPeopleIdsByType(this.context, value);
      }
      case 'phase': {
        return getPhasesIdsByName(this.context, value, this.partialMatch);
      }
      case 'project': {
        return getProjectsIdsByName(this.context, value, this.partialMatch);
      }
      case 'projectOwner': {
        return getAccountsIdsByName(this.context, value, this.partialMatch);
      }
      case 'projectTag': {
        return getProjectsIdsByTag(this.context, value, this.partialMatch);
      }
      case 'projectStatus':
      case 'task':
      case 'taskStatus':
      case 'timeoff':
      case 'timeoffStatus': {
        return new Set<number>();
      }
    }

    return new Set<number>();
  }

  matches(entity: FiltersEntity<'loggedTimes'>): boolean {
    for (const value of this.values) {
      if (!this.ids[value]) {
        this.ids[value] = this.getIdsByValue(value);
      }

      if (this.matchesByValue(entity, value)) {
        return true;
      }
    }

    return false;
  }

  private matchesByValue(
    loggedTime: FiltersEntity<'loggedTimes'>,
    value: string,
  ): boolean {
    const ids = this.ids[value];

    if (!ids) return true;

    switch (this.type) {
      case 'client': {
        const project = this.context.projects[loggedTime.project_id];

        if (!project) return false;

        return ids.has(project.client_id);
      }
      case 'department': {
        const person = this.context.people[loggedTime.people_id];

        if (person && ids.has(person.department_id)) return true;

        return false;
      }
      case 'jobTitle':
      case 'me':
      case 'manager':
      case 'person':
      case 'personTag':
      case 'personType': {
        return ids.has(loggedTime.people_id);
      }
      case 'phase': {
        return ids.has(loggedTime.phase_id);
      }
      case 'project':
      case 'projectTag': {
        return ids.has(loggedTime.project_id);
      }
      case 'projectOwner': {
        const project = this.context.projects[loggedTime.project_id];

        if (!project) return false;

        return ids.has(project.project_manager);
      }
      case 'projectStatus': {
        const project = this.context.projects[loggedTime.project_id];

        if (!project) return false;

        return matchProjectByStatus(project, this.context.user, value);
      }
      case 'task': {
        if (value === UNNAMED_TASK) return !loggedTime.task_name;

        return matchString(loggedTime.task_name, value, this.partialMatch);
      }
      case 'taskStatus': {
        const task =
          loggedTime.task_id && this.context.tasks[loggedTime.task_id];

        if (task) return matchTaskByStatus(task, value);

        return false;
      }
      case 'timeoff':
      case 'timeoffStatus':
        return false;
    }

    return true;
  }
}
