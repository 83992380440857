import { useCallback } from 'react';

import { parseBudgetString } from '@float/common/lib/budget/helpers/parseBugdetString';
import { stringCompare } from '@float/common/lib/sort';
import { useAppSelectorStrict } from '@float/common/store';
import { Phase, PhaseWithTemplateFields } from '@float/types/phase';
import { ProjectTeamMemberData } from '@float/types/project';
import { getPeopleMap } from '@float/web/selectors';

export const useSortedTeams = () => {
  const people = useAppSelectorStrict(getPeopleMap);
  const sortAndMapTeamRecords = useCallback(
    (
      peopleIds: number[] | undefined,
      peopleRates: Record<number, string | null> | undefined,
    ) => {
      if (!peopleIds?.length) return [];
      return peopleIds
        .filter((p) => people[p])
        .sort((a, b) => stringCompare(people[a].name, people[b].name))
        .map((people_id) => ({
          people_id,
          hourly_rate: peopleRates?.[people_id]
            ? parseBudgetString(peopleRates[people_id])
            : null,
        }));
    },
    [people],
  );

  const parsePhaseTeamRecords = (
    phases: PhaseWithTemplateFields[],
    projectTeamIds: number[] = [],
  ) => {
    const phaseTeamRecords: ProjectTeamMemberData[] = [];
    if (!phases?.length) return phaseTeamRecords;
    const phaseTeamIdSet = phases.reduce((acc, curr) => {
      if (curr.people_ids?.length) {
        return acc.union(new Set(curr.people_ids));
      }
      if (curr.team_people?.length) {
        const phasePeopleIds = curr.team_people.map((p) => p.id);
        return acc.union(new Set(phasePeopleIds));
      }
      return acc;
    }, new Set<number>());
    const phaseTeamIdsIterator = phaseTeamIdSet
      .difference(new Set(projectTeamIds))
      .values();
    for (const people_id of phaseTeamIdsIterator) {
      phaseTeamRecords.push({
        people_id,
        hourly_rate: null,
        isAssignedToPhaseOnly: true,
      });
    }
    return phaseTeamRecords;
  };

  const parseProjectTeamRecords = (
    peopleIds: number[] | undefined,
    peopleRates: Record<number, string | null> | undefined,
  ): ProjectTeamMemberData[] => {
    if (!peopleIds?.length) return [];
    return peopleIds.map((people_id) => ({
      people_id,
      hourly_rate: peopleRates?.[people_id]
        ? parseBudgetString(peopleRates[people_id])
        : null,
    }));
  };

  const sortAndMapProjectAndPhaseTeamRecords = useCallback(
    (
      peopleIds: number[] | undefined,
      peopleRates: Record<number, string | null> | undefined,
      phases: Phase[],
    ) => {
      const phaseTeamRecords = parsePhaseTeamRecords(phases, peopleIds);
      const projectTeamRecords = parseProjectTeamRecords(
        peopleIds,
        peopleRates,
      );
      return projectTeamRecords
        .concat(phaseTeamRecords)
        .filter((p) => p.people_id && people[p.people_id])
        .sort((a, b) =>
          stringCompare(people[a.people_id!].name, people[b.people_id!].name),
        );
    },
    [people],
  );

  return {
    sortAndMapTeamRecords,
    sortAndMapProjectAndPhaseTeamRecords,
  };
};
