import { compact } from 'lodash';

export const PEOPLE_STATUS = {
  ACTIVE: 1,
  ACTIVE_ARCHIVED: 2,
  ARCHIVED: 0,
};

export const PEOPLE_COLUMNS = compact([
  {
    key: 'name',
    title: 'Name',
    width: '22.36%',
  },
  {
    key: 'role',
    title: 'Role',
  },
  {
    key: 'department',
    title: 'Department',
  },
  {
    key: 'access',
    title: 'Access',
  },
  {
    key: 'manages',
    title: 'Manages',
    callout: 'mar-new-manage-col',
  },
  {
    key: 'tags',
    title: 'Tags',
    sortable: false,
  },
  {
    key: 'statusTags',
    title: 'Type',
    sortable: false,
    width: '165px',
  },
]);
