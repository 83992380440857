import styled, { css } from 'styled-components';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import Tag from '@float/ui/deprecated/Earhart/Tags/Tag';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

import Input from '../Input/Input';

export const LabelWrapper = styled.div`
  ${Typography.Label12.M500};

  display: flex;

  color: ${Colors.FIN.Lt.Emphasis.Medium};

  margin-bottom: 8px;

  span {
    margin-right: 5px;
  }
`;

export const TokenWrapper = styled(Tag)`
  border: 1px solid ${(p) => (p.border ? 'black' : p.theme.lightGrey)};

  z-index: 1;

  ${(p) => p.isClickable && 'cursor: pointer;'};
`;

export const MultiSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${Input._styles.InputWrapper} {
    padding: 0;

    .input-container {
      flex: 1;

      &:nth-child(1) {
        input {
          text-indent: 12px;
        }
      }

      input {
        height: 32px;
      }
    }
  }

  &.medium {
    ${Input._styles.InputWrapper} {
      padding-right: 4px;
      padding-bottom: 4px;

      > * {
        margin-left: 4px;
        margin-top: 4px;
      }
    }
  }

  &.large {
    ${Input._styles.InputWrapper} {
      padding-right: 6px;
      padding-bottom: 6px;

      > * {
        margin-left: 6px;
        margin-top: 6px;
      }
    }
  }

  ${(p) =>
    p.highlightedIndex !== null &&
    css`
      caret-color: transparent;
    `}

  @media all and (-ms-high-contrast: none) {
    ${Input._styles.InputWrapper}, .input-container {
      height: auto;
    }

    .input-container {
      position: relative;
      padding-right: 36px;

      .icon {
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        bottom: 2px;
        right: 10px;
      }
    }
  }
`;
