import { checkMatchRequiredBits } from './checkMatchRequiredBits';
import { getAccountType, getUserAccess } from './helpers';

const checkFullyImplemented = (specJSON, conditions) => {
  const enums = getRightsEnumFunctionNames(specJSON);
  const missingEnums = enums.filter((enumName) => !conditions[enumName]);

  if (missingEnums.length) {
    throw new Error('Rights JSON is missing enum: ' + missingEnums.join(', '));
  }
};

export const getRightsEnumFunctionNames = (specJSON) => {
  const extractWhenEnums = (when) => {
    const enums = [];

    when.forEach((condition) => {
      if (Array.isArray(condition)) {
        enums.push(...extractWhenEnums(condition));
      } else if (typeof condition == 'string') {
        enums.push(condition);
      }
    });

    return enums;
  };

  return [
    ...new Set(
      Object.values(specJSON).reduce((acc, model) => {
        if (model.when) {
          acc.push(...extractWhenEnums(model.when));
        }

        const modelActions = Object.values(model);
        modelActions.forEach((conditionsByAccountId) => {
          if (conditionsByAccountId.when) {
            acc.push(...extractWhenEnums(conditionsByAccountId.when));
          }

          Object.values(conditionsByAccountId).forEach((conditions) => {
            if (conditions.when) {
              acc.push(...extractWhenEnums(conditions.when));
            }
          });
        });

        return acc;
      }, []),
    ),
  ];
};

const buildDecoratedConditions = (conditions) => {
  return Object.entries(conditions).reduce((acc, [name, fn]) => {
    acc[name] = (user, ctx) => {
      const shouldIgnore = ctx?.ignore?.includes(name);
      if (shouldIgnore) {
        return true;
      }

      return fn(user, ctx);
    };

    return acc;
  }, {});
};

export const buildPreConditions = (conditions) => {
  const preConditionsByRef = Object.entries(conditions).reduce(
    (acc, [name, fn]) => {
      const maybeCondByName = conditions[`pre_${name}`];
      if (maybeCondByName) {
        acc.set(fn, maybeCondByName);
      }

      return acc;
    },
    new Map(),
  );

  return Object.entries(conditions).reduce((acc, [name, fn]) => {
    if (preConditionsByRef.has(fn)) {
      acc[name] = preConditionsByRef.get(fn);
    }

    const maybeCondByName = conditions[`pre_${name}`];
    if (maybeCondByName) {
      acc[name] = maybeCondByName;
    }

    return acc;
  }, {});
};

export const createSpecInterpreter = (specJSON, conditions = {}) => {
  const decoratedConditions = buildDecoratedConditions(conditions);
  const preConditions = buildPreConditions(conditions);

  const checkIsAccessCondition = (conditionObj) => {
    return typeof conditionObj?.access === 'number';
  };

  const checkIsMatchingRequiredAccess = (conditionObj, user) => {
    const required = conditionObj.access;
    const current = getUserAccess(user);
    return checkMatchRequiredBits(current, required);
  };

  const handleEnumCondition = (conditionName, user, context) => {
    const conditionCallback = conditions[conditionName];
    if (!conditionCallback) {
      return false;
    }

    const isMaybeMode = !context;
    const maybeCondition = preConditions[conditionName];
    if (isMaybeMode) {
      if (maybeCondition) {
        return maybeCondition(user);
      }

      const onlyReliesOnUserObj = conditionCallback.length <= 1;
      if (!onlyReliesOnUserObj) {
        return true;
      }
    }

    const satisfiesPreCondition = !maybeCondition || maybeCondition(user);
    if (!satisfiesPreCondition) {
      return false;
    }

    const decoratedCond = decoratedConditions[conditionName];
    return decoratedCond(user, context || {});
  };

  const handleWhen = (user, conditions, context, andor = 'some') => {
    if (!conditions || !conditions.length) return true;

    return conditions[andor]((condition) => {
      const isAnd = Array.isArray(condition);
      if (isAnd) {
        return handleWhen(user, condition, context, 'every');
      }

      const isObjectCond = checkIsAccessCondition(condition);
      if (isObjectCond) {
        return checkIsMatchingRequiredAccess(condition, user);
      }

      return handleEnumCondition(condition, user, context);
    });
  };

  const createRightsHandler = (rightsConfig, actionName) => (user, context) => {
    const accountType = getAccountType(user);
    if (!accountType) {
      return false;
    }

    const actionConfig = rightsConfig[actionName];

    const when = rightsConfig?.when || actionConfig?.when;
    const doesMatchWhen = handleWhen(user, when, context);
    if (!doesMatchWhen) {
      return false;
    }

    const isAlwaysAllowed = actionConfig?.alwaysAllow?.includes(accountType);
    if (isAlwaysAllowed) {
      return true;
    }

    const isAccessCond = checkIsAccessCondition(actionConfig);
    if (isAccessCond) {
      return checkIsMatchingRequiredAccess(actionConfig, user);
    }

    const hasAccountTypeRule =
      actionConfig?.[accountType] || rightsConfig?.[accountType];
    if (hasAccountTypeRule) {
      const checkAccountTypeRule = createRightsHandler(
        actionConfig || rightsConfig,
        accountType,
      );

      return checkAccountTypeRule(user, context);
    }

    const isDefaultAllowed = !actionConfig;
    if (isDefaultAllowed) {
      return true;
    }

    if (!hasAccountTypeRule) {
      return false;
    }

    const isAllowed = doesMatchWhen;
    return !!isAllowed;
  };

  const defaultRightHandler = (user) => {
    const accountType = getAccountType(user);
    if (!accountType) {
      return false;
    }

    return true;
  };

  const baseObj = {
    checkFullyImplemented: checkFullyImplemented.bind(
      null,
      specJSON,
      conditions,
    ),
  };
  return new Proxy(baseObj, {
    get: (target, prop, receiver) => {
      const handler = Reflect.get(target, prop, receiver);

      if (typeof handler === 'undefined' && prop.startsWith('can')) {
        const words = prop.slice(3).split(/(?=[A-Z])/);
        const action = words.shift();
        const entityType = words.join('');
        const modelConfig = specJSON[entityType];
        if (!entityType || !modelConfig) {
          if (process.env.NODE_ENV === 'development') {
            console.error('No supported entity type found', entityType, action);
          }
          return defaultRightHandler;
        }

        const actionName = action.toLowerCase();
        return createRightsHandler(modelConfig, actionName);
      }

      return handler;
    },
  });
};
