import React from 'react';

export const IconFloatCommunity = () => (
  <svg
    fill="none"
    width={48}
    height={48}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="6.026"
      height="13.767"
      x="24.791"
      y="9.213"
      fill="#96C7F2"
      stroke="#242C39"
      strokeWidth=".33"
      rx="2.906"
    />
    <rect
      width="6.026"
      height="13.767"
      x="16.906"
      y="24.982"
      fill="#96C7F2"
      stroke="#242C39"
      strokeWidth=".33"
      rx="2.906"
    />
    <rect
      width="6.026"
      height="13.767"
      x="9.165"
      y="22.98"
      fill="#96C7F2"
      stroke="#242C39"
      strokeWidth=".33"
      rx="2.906"
      transform="rotate(-90 9.165 22.98)"
    />
    <rect
      width="6.026"
      height="13.767"
      x="24.791"
      y="31.008"
      fill="#96C7F2"
      stroke="#242C39"
      strokeWidth=".33"
      rx="2.906"
      transform="rotate(-90 24.791 31.008)"
    />
    <path
      fill="#96C7F2"
      stroke="#242C39"
      strokeWidth=".33"
      d="M32.724 22.98v-3.12a2.916 2.916 0 0 1 2.917-2.906 2.916 2.916 0 0 1 2.917 2.906v.214a2.906 2.906 0 0 1-2.906 2.906h-2.928Zm-9.888-7.79v-3.12a2.916 2.916 0 0 0-2.917-2.905 2.916 2.916 0 0 0-2.917 2.906v.214a2.906 2.906 0 0 0 2.906 2.905h2.928ZM15 24.982v3.12a2.916 2.916 0 0 1-2.918 2.906 2.915 2.915 0 0 1-2.917-2.906v-.214a2.906 2.906 0 0 1 2.906-2.906h2.928Zm9.934 7.838v3.119a2.916 2.916 0 0 0 2.918 2.906 2.916 2.916 0 0 0 2.917-2.906v-.214a2.906 2.906 0 0 0-2.906-2.906h-2.929Z"
    />
  </svg>
);
