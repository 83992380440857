import styled from 'styled-components';

import { activityItemTitleWithComponents } from '@float/common/activity';

const TopTitle = styled.span``;
const ActionLabel = styled.span``;
const StrongLabel = styled.strong``;
const Text = styled.span``;

/**
 * @todo
 *
 * Detach from `activityItemTitleWithComponents`.
 * @url https://linear.app/float-com/issue/EXP-583/
 *
 * This component should be refactored to be a standalone visual component.
 * Additionally, ensure that a Storybook entry is created for it to facilitate
 * visual testing and documentation.
 **/

export const NotificationItemTitle = activityItemTitleWithComponents({
  TopTitle,
  ActionLabel,
  StrongLabel,
  Text,
});
