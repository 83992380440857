import React from 'react';
import { Trans } from '@lingui/macro';

import * as Accordion from '@float/ui/components/Accordion';

import { SectionCounterTag } from '../../components/SectionCounterTag';
import { SectionErrorTag } from '../../components/SectionErrorTag';
import { AccordionEntry } from '../../hooks/useAccordionState';
import { UseFieldArrayProvider } from '../../hooks/useFieldArrayContext';
import { ProjectMilestonesTable } from './ProjectMilestonesTable';

import {
  projectAccordionHeader,
  projectAccordionSummary,
  projectSectionHeader,
} from '../../styles.css';

function ProjectMilestonesTitle() {
  return (
    <div className={projectSectionHeader}>
      <Trans>Milestones</Trans>
      <SectionCounterTag name="milestones" />
    </div>
  );
}

function InfoHeader() {
  return (
    <div className={projectAccordionSummary}>
      <SectionErrorTag fields={['milestones']} />
    </div>
  );
}

export const ProjectMilestonesSection = () => {
  return (
    <Accordion.Item value={AccordionEntry.milestones}>
      <Accordion.Header
        className={projectAccordionHeader}
        info={<InfoHeader />}
      >
        <ProjectMilestonesTitle />
      </Accordion.Header>
      <Accordion.Content>
        <UseFieldArrayProvider name="milestones">
          <ProjectMilestonesTable />
        </UseFieldArrayProvider>
      </Accordion.Content>
    </Accordion.Item>
  );
};
