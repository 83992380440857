import { ProjectTasks } from 'OnboardingManager/components/OnboardingSurvey/components/ProjectTasks';
import styled from 'styled-components';

export const StyledProjectTasks = styled(ProjectTasks)`
  position: absolute;

  top: 50%;
  left: 60px;

  transform: translateY(-50%);
`;
