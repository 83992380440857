import React, { StrictMode } from 'react';
import { t } from '@lingui/macro';
import cn from 'classnames';

import { noop } from '@float/libs/utils/noop';

import { IconCloseSmall } from '../../icons/essentials/IconCloseSmall';
import { AlertBarProps } from './types';

import * as styles from './styles.css';

export * from './types';

export const AlertBar = (props: AlertBarProps) => {
  const {
    className,
    icon: Icon,
    message,
    onClickClose = noop,
    showClose,
    type,
    display,
  } = props;

  const onlyText = !Icon && !showClose;

  return (
    <StrictMode>
      <div
        className={cn(styles.alertBar({ type, display, onlyText }), className)}
      >
        {Icon && (
          <Icon
            className={cn(styles.iconLeft)}
            aria-hidden={true}
            data-testid="icon"
          />
        )}

        <p className={styles.message} role="alert">
          {message}
        </p>

        {showClose && (
          <button
            type="button"
            className={styles.iconClose}
            onClick={onClickClose}
            aria-label={t`Close`}
          >
            <IconCloseSmall />
          </button>
        )}
      </div>
    </StrictMode>
  );
};
