import { memoize, memoizeWithArgs } from 'proxy-memoize';
import { createSelector } from 'reselect';

import { TimerGlobalState } from '@float/common/reducers/lib/types';
import { Timer, TimerWithLoggedTimeReference } from '@float/types/timer';

export const getActiveTimer = memoize((state: TimerGlobalState) =>
  state.timer.timers.find(
    (timer) =>
      timer.active &&
      timer.external_meta.account_id === state.currentUser.account_id,
  ),
);

export const getLoggedTimeActiveTimer = memoizeWithArgs(
  (state: TimerGlobalState, loggedTimeId: string) => {
    const timer = getActiveTimer(state);

    return timer && timer.external_meta.id === loggedTimeId ? timer : null;
  },
);

export const getTimersWithExternalMeta = createSelector(
  [
    (state: TimerGlobalState) => state.loggedTimes.loggedTimes,
    (state) => state.timer?.timers,
  ],
  (selectedLoggedTimes, selectedTimers): TimerWithLoggedTimeReference[] => {
    if (!selectedTimers) return [];

    return selectedTimers.map((timer: Timer) => {
      if (timer.external_meta) {
        return {
          ...timer,
          external_meta: {
            ...timer.external_meta,
            loggedTime: selectedLoggedTimes[timer.external_meta.id],
          },
        };
      }
    });
  },
);

export const getTimersWithExternalMetaMap = createSelector(
  [getTimersWithExternalMeta],
  (timers = []) => {
    return timers.reduce((total: Record<string, Timer>, next: Timer) => {
      total[next._id!] = next;
      return total;
    }, {});
  },
);

export const getTimers = createSelector(
  [(state: TimerGlobalState) => state.timer],
  (timer) => {
    return timer.timers;
  },
);

export const getLoggedTimeHasTimers = createSelector(
  [getTimers, (_, loggedTimeId: string | undefined) => loggedTimeId],
  (timers, loggedTimeId) => {
    if (!loggedTimeId) return false;

    return timers.some(
      (timer) => loggedTimeId === timer.external_meta?.id && !timer.deleted,
    );
  },
);

export const getTimerById = createSelector(
  [
    (state: TimerGlobalState) => state.timer,
    (_: TimerGlobalState, id: string) => id,
  ],
  (timer, id: string) => {
    return timer.timers.find((timer) => timer._id === id);
  },
);

export const getTimersByLoggedTime = createSelector(
  getTimers,
  (_: TimerGlobalState, loggedTimeId: string) => loggedTimeId,
  (_: TimerGlobalState, loggedTimeId: string, sorted?: boolean) => sorted,
  (timers, loggedTimeId, sorted = false) => {
    const timersByLoggedTimeId = timers.filter(
      (timer) => timer.external_meta?.id === loggedTimeId && !timer.active,
    );

    if (sorted) {
      return timersByLoggedTimeId.sort((a, b) => {
        return b.start_timestamp! - a.start_timestamp!;
      });
    }

    return timersByLoggedTimeId;
  },
);

export const getTimerWarning = createSelector(
  [(state: TimerGlobalState) => state.timer],
  (timer) => {
    return timer.warning;
  },
);

export const getTimersMap = createSelector([getTimers], (timers) => {
  return timers.reduce<Record<string, Timer>>((total, next) => {
    if (next._id) total[next._id] = next;
    return total;
  }, {});
});
