import React from 'react';
import { t } from '@lingui/macro';

import { RichTextField } from '../components/RichTextField';

type NotesFieldProps = {
  name?: 'project.description' | 'phase.notes';
  meta?: 'project.notes_meta' | 'phase.notes_meta';
};

export function NotesField({
  name = 'project.description',
  meta = 'project.notes_meta',
}: NotesFieldProps) {
  return <RichTextField name={name} meta={meta} label={t`Notes`} />;
}
