import { PROMPTS } from '@float/constants/prompts';
import {
  REPLAY_PRODUCT_TOUR,
  SET_PROMPT,
  SET_PROMPT_DATA,
  UPDATE_PROMPTS_SUCCESS,
} from '@float/web/store/onboardingManager/actions';

const DEFAULT_STATE = {
  promptsUserHasDone: [],
  navigateToPrompt: null,
  promptData: {},
};

const onboardingManager = (state = DEFAULT_STATE, action) => {
  let prompts;
  let promptData;

  switch (action.type) {
    case UPDATE_PROMPTS_SUCCESS:
      if (
        action.prompts &&
        action.prompts.length &&
        action.prompts.every((id) => state.promptsUserHasDone.includes(id)) &&
        state.navigateToPrompt === null
      ) {
        return state;
      }

      prompts = action.prompts.reduce((previous, prompt) => {
        if (
          !state.promptsUserHasDone.includes(prompt) &&
          !previous.includes(prompt)
        ) {
          previous.push(prompt);
        }
        return previous;
      }, []);

      return {
        ...state,
        navigateToPrompt: null,
        promptsUserHasDone: [...state.promptsUserHasDone, ...prompts],
      };

    case SET_PROMPT:
      return { ...state, navigateToPrompt: action.prompt };

    case SET_PROMPT_DATA:
      promptData = { ...state.promptData };

      promptData[action.prompt] = action.data;

      return { ...state, promptData };

    case REPLAY_PRODUCT_TOUR:
      prompts = state.promptsUserHasDone.filter(
        (prompt) => prompt !== PROMPTS.productTour,
      );

      promptData = { ...state.promptData };

      promptData[PROMPTS.productTour] = { fromReplay: true };

      return {
        ...state,
        promptsUserHasDone: prompts,
        promptData,
      };

    default:
      return state;
  }
};

export default onboardingManager;
