import React from 'react';
import { getModelFromActivity } from '@floatschedule/activity-format-npm';
import { t, Trans } from '@lingui/macro';
import { isUndefined, pick } from 'lodash';
import { getPeopleMap, getUser } from 'selectors';

import { handleClientDeletedNotification } from '@float/common/actions/clients';
import { ensureOnlyOneDirectManager } from '@float/common/actions/people';
import { updatePlanCount } from '@float/common/actions/people/updatePlanCount';
import { refreshTaskMetas } from '@float/common/actions/taskMetas';
import { asyncProcessEvent } from '@float/common/lib/asyncProcess';
import { isTimerLiveUpdate } from '@float/common/lib/liveUpdates/helpers';
import {
  handleTimerAddAndDeleteUpdates,
  handleTimerStartAndStopUpdates,
  handleTimerUpdateUpdates,
} from '@float/common/lib/liveUpdates/updateHandlers';
import { ReduxState } from '@float/common/reducers/lib/types';
import { AppDispatch } from '@float/common/store';
import { debugLogLiveUpdateMessage } from '@float/libs/web/devtools/liveUpdatesDebug';
import {
  GenericLiveUpdateNotification,
  LiveUpdate,
  LiveUpdateMetadata,
  LiveUpdateWithNotification,
  NotificationEntity,
  NotificationMetaData,
} from '@float/types';
import { SnackbarMethods } from '@float/ui/deprecated/Snackbar/useSnackbar';
import { fetchSidebarData } from '@float/web/pmSidebar/actions';

import {
  handleBatchActivities,
  insertActivities,
  shouldInsertActivityInFeed,
} from '../activities';
import { ACTION_TYPES, VALID_ENTITY_TYPES } from '../constants';
import getAction from '../getAction';
import handleIntegrationSyncError from '../handleIntegrationSyncError';
import * as helpers from '../helpers';
import {
  handleHolidaysImport,
  handleNotificationFeedMessage,
  liveUpdateDispatchCreator,
  showRefreshModal,
  showRefreshStickyFooter,
  showStickyNotice,
} from './actions.helpers';
import { handleCompanyMessage } from './handleCompanyMessage';
import { handleRoleLiveUpdate } from './handleRoleLiveUpdate';
import { handleViewLiveUpdate } from './handleViewLiveUpdate';
import { ManageModalFn } from './types';

export const onSocketMessageReceive =
  (
    message: string | { data: LiveUpdateWithNotification },
    manageModal: ManageModalFn,
    showSnackbar: SnackbarMethods['showSnackbar'],
  ) =>
  (dispatch: AppDispatch, getState: () => ReduxState) => {
    const msg = typeof message === 'string' ? JSON.parse(message) : message;
    const data: LiveUpdate =
      typeof msg.data === 'string' ? JSON.parse(msg.data) : msg.data;
    const metadata: LiveUpdateMetadata | null = isTimerLiveUpdate(data)
      ? null
      : pick(data, 'admin', 'author_uuid', 'external_source');

    debugLogLiveUpdateMessage(data);

    handleTimerStartAndStopUpdates({
      data,
      dispatch,
    });

    handleTimerAddAndDeleteUpdates({
      data,
      dispatch,
    });

    handleTimerUpdateUpdates({
      data,
      dispatch,
    });

    // @TODO(PI-71): Remove these coercions
    const notification = (data as LiveUpdateWithNotification)
      .notification as GenericLiveUpdateNotification;

    if (!notification) return;

    const {
      type: eventType,
      action = 'update',
      af_item: activityFeedItem = {
        after_data: { fields: [] },
        item_id: undefined,
        item_ids: [],
        length: 0,
      },
      nf_item: notificationFeedItem,
      nf_ids_seen: notificationIdsSeen,
    } = notification as GenericLiveUpdateNotification;

    const item =
      // @TODO(PI-71): Remove this coercion
      (notification as GenericLiveUpdateNotification)
        .item as NotificationMetaData<NotificationEntity>;

    if (activityFeedItem && (activityFeedItem as { length?: number })?.length) {
      dispatch(handleBatchActivities(activityFeedItem, metadata));
      return;
    }

    if (action === 'announcement' && notification.message) {
      showStickyNotice(
        notification.message,
        notification.linkText,
        notification.link,
        showSnackbar,
      );
      return;
    }

    if (action === 'refresh' && eventType === 'maintenance') {
      manageModal({
        visible: true,
        modalType: 'ConfirmModal',
        modalSettings: {
          title: t`Float is currently down for scheduled maintenance`,
          message: (
            <p>
              <Trans>
                We'll be back up and running shortly. We appreciate your
                patience. Please see{' '}
                <a
                  href="https://status.float.com"
                  target="_blank"
                  title="Float Status"
                  rel="noreferrer"
                >
                  status.float.com
                </a>{' '}
                for updates.
              </Trans>
            </p>
          ),
          confirmLabel: t`Reload`,
          onConfirm: () => window.location.reload(),
          hideCancel: true,
          shouldCloseOnEsc: false,
        },
      });
      return;
    }

    if (eventType === 'recovery' && notification.request_reload) {
      showRefreshModal({
        title: t`Your schedule is out of date`,
        manageModal,
      });
      return;
    }

    if (notificationFeedItem || notificationIdsSeen) {
      dispatch(handleNotificationFeedMessage(data));
      return;
    }

    if (action === 'integration_sync_error') {
      dispatch(
        handleIntegrationSyncError({
          type: eventType,
          result: notification.result,
          showSnackbar,
          manageModal,
        }),
      );
    }

    if (action === 'async_process') {
      asyncProcessEvent(notification.result);
      return;
    }

    if (!eventType) {
      return;
    }

    if (VALID_ENTITY_TYPES.includes(eventType.toLowerCase())) {
      const state = getState();
      const user = getUser(state);
      let actionType =
        ACTION_TYPES[
          `${eventType}_${action}`.toUpperCase() as keyof typeof ACTION_TYPES
        ];
      const dispatchLiveUpdate = liveUpdateDispatchCreator(dispatch);

      if (helpers.accessRightsChanged(user, notification)) {
        showRefreshModal({
          title: t`Your access rights have changed`,
          manageModal,
        });
        return;
      }

      if (!helpers.isUserAuthorizedToView(activityFeedItem, user)) {
        return;
      }

      if (shouldInsertActivityInFeed(activityFeedItem, state)) {
        dispatch(insertActivities(activityFeedItem));
      }

      if (
        helpers.isInitiatedByCurrentUser(metadata, user) &&
        !helpers.shouldNotificationBeProcessedForCurrentUser(data)
      ) {
        return;
      }

      if (action === 'import') {
        if (eventType === 'holidays') {
          dispatch(handleHolidaysImport(data, ACTION_TYPES));
          return;
        }

        if (['people', 'project'].includes(eventType)) {
          // single item imported, treat as update
          actionType =
            ACTION_TYPES[
              `${eventType.toUpperCase()}_UPDATE` as keyof typeof ACTION_TYPES
            ];
        }
      }

      if (action === 'replace' && ['task', 'timeoff'].includes(eventType)) {
        if (notification.result && notification.result.request_reload) {
          showRefreshStickyFooter(t`Schedule has changed`, showSnackbar);
          return;
        }
        // single item affected, treat as create/update
        if (activityFeedItem) {
          const activityType =
            (activityFeedItem as { activity_type?: string }).activity_type ||
            'update';
          actionType =
            ACTION_TYPES[
              `${eventType}_${activityType}`.toUpperCase() as keyof typeof ACTION_TYPES
            ];
        }
      }

      if (
        ['batch_upsert', 'batch_delete'].includes(action) ||
        (action === 'replace' && notification.result)
      ) {
        dispatchLiveUpdate({
          type: actionType,
          response: notification.result,
          item,
        });
        return;
      }

      if (['bulk_update', 'bulk_delete', 'bulk_clone'].includes(action)) {
        const ids =
          activityFeedItem &&
          (activityFeedItem as { item_ids: number[] }).item_ids;
        switch (eventType.toLowerCase()) {
          case 'people': {
            if (action === 'bulk_delete') {
              dispatchLiveUpdate({
                type: ACTION_TYPES.PEOPLE_BULK_DELETE,
                ids,
              });
              dispatchLiveUpdate(updatePlanCount);
              break;
            }

            const { fields } = (
              activityFeedItem as {
                after_data: { fields: { active?: boolean } };
              }
            ).after_data;

            const people = getPeopleMap(getState());
            dispatchLiveUpdate({
              type: ACTION_TYPES.PEOPLE_BULK_UPDATE,
              people: ids.map((id) => people[id]),
              fields,
            });

            if (!isUndefined(fields.active)) {
              dispatchLiveUpdate(updatePlanCount);
            }
            break;
          }
          case 'project': {
            if (action === 'bulk_delete') {
              dispatchLiveUpdate({
                type: ACTION_TYPES.PROJECT_BULK_DELETE,
                ids,
              });
              break;
            }

            const { fields } = (
              activityFeedItem as {
                after_data: { fields: { active?: boolean } };
              }
            ).after_data;
            dispatchLiveUpdate({
              type: ACTION_TYPES.PROJECT_BULK_UPDATE,
              ids,
              fields,
            });
            break;
          }
          case 'milestones': {
            if (action === 'bulk_update') {
              dispatchLiveUpdate({
                type: ACTION_TYPES.MILESTONES_BULK_UPDATE,
                result: notification.result,
              });
            }
            break;
          }
          case 'phase': {
            if (action === 'bulk_update') {
              dispatchLiveUpdate({
                type: ACTION_TYPES.PHASES_BULK_UPDATE,
                result: notification.result,
              });
            }
            break;
          }
          case 'department': {
            if (action === 'bulk_update') {
              dispatchLiveUpdate({
                type: ACTION_TYPES.DEPARTMENT_BULK_UPDATE,
                result: notification.result,
              });
            }
            break;
          }
          case 'client': {
            if (action === 'bulk_update') {
              dispatchLiveUpdate({
                type: ACTION_TYPES.CLIENT_BULK_UPDATE,
                result: notification.result,
              });
            }
            break;
          }
          case 'role': {
            if (action === 'bulk_update') {
              handleRoleLiveUpdate(notification, dispatch);
            }
            break;
          }
          default:
            break;
        }
        return;
      }

      const actionToDispatch = getAction({
        actionType,
        afItem: activityFeedItem,
        fullState: state,
      });

      if (actionToDispatch) {
        dispatch(actionToDispatch);

        if (eventType.toLowerCase() === 'account_co') {
          ensureOnlyOneDirectManager(
            actionToDispatch.account,
            dispatch,
            getState,
          );
        }

        if (eventType.toLowerCase() === 'people') {
          dispatchLiveUpdate(updatePlanCount);
        }
        return;
      }

      if (
        eventType.toLowerCase().startsWith('company_integration_') &&
        action === 'sync_complete'
      ) {
        dispatch(fetchSidebarData());
      }

      switch (eventType.toLowerCase()) {
        case 'company':
          return dispatch(handleCompanyMessage(data, actionType, manageModal));
        case 'client': {
          const client = getModelFromActivity(activityFeedItem);
          if (actionType === ACTION_TYPES.CLIENT_DELETE) {
            dispatch(handleClientDeletedNotification(client));
          } else {
            dispatchLiveUpdate({
              type: actionType,
              payload: client,
              id: client.client_id,
            });
          }
          break;
        }
        case 'ext_calendar': {
          dispatchLiveUpdate({
            type: actionType,
            extCalendars: item.extCalendars,
          });
          break;
        }

        case 'company_integration_jira':
        case 'company_integration_trello':
        case 'company_integration_teamwork':
        case 'company_integration_asana': {
          // ensure coInts aren't archived or invalid
          const coInts = [item.coInt || item.companyInt].filter(
            (item) => !!item && item.status > 0,
          );
          dispatchLiveUpdate({
            type: actionType,
            coInts,
            preloadData: item.preloadData,
            initialImport: item.initialImport,
            error: item.error,
          });
          break;
        }
        case 'sidebar_item':
        case 'task_link': {
          dispatchLiveUpdate({
            type: actionType,
            changeType: action,
            item: notification.item,
            projectIds: notification.project_ids, // might be present in case of delete
            fullState: state,
          });
          break;
        }
        case 'project_dates': {
          dispatchLiveUpdate({
            type: actionType,
            projects: notification.projects,
          });
          break;
        }
        case 'task_meta': {
          dispatchLiveUpdate({
            type: actionType,
            item: notification.item,
            ids: notification.ids,
          });

          // Triggering a refresh as notification.item !== TaskMeta
          if (item && item?.project_id) {
            dispatch(refreshTaskMetas(item.project_id));
          }
          break;
        }
        case 'budget_utilization': {
          setTimeout(() => {
            // TODO: Clean-up. Hack applied here for the "duplicate" scenario.
            //  Budget LU can sometimes come in a few ms ahead of the LU
            //  containing newly created phases. That can cause budget utilization
            //  to wrongly show as zero.
            helpers.cacheBudgetResponse(notification.budgets);
            dispatchLiveUpdate({
              type: actionType,
              budgets: notification.budgets,
            });
          }, 100);
          break;
        }
        case 'tags': {
          const { tags } = state.tags;
          const updatedTags =
            notification.tags ||
            notification?.ids?.map((tagId) => {
              return tags[tagId];
            });

          dispatchLiveUpdate({
            type: actionType,
            tags: updatedTags?.filter(Boolean) ?? [],
            allTags: tags,
          });
          break;
        }
        case 'log_time_lock':
          showRefreshModal({ manageModal });
          break;
        case 'view':
          return handleViewLiveUpdate(notification, dispatch, getState);
        case 'role':
          return handleRoleLiveUpdate(notification, dispatch);
        default:
          break;
      }
    }
  };
