import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import { AppDispatch } from '@float/common/store';
import { Hotkeys } from '@float/ui/deprecated';

import { redo, undo } from './actions';

function Container(props: { undo: () => void; redo: () => void }) {
  const keyMap = useMemo(
    () => ({
      'cmd+z': props.undo,
      'cmd+shift+z': props.redo,
    }),
    [props.undo, props.redo],
  );

  return <Hotkeys noOverride keyMap={keyMap} />;
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  undo: () => dispatch(undo()),
  redo: () => dispatch(redo()),
});

export default connect(undefined, mapDispatchToProps)(Container);
