import React from 'react';
import cx from 'classnames';

import Cell from './Cell';
import { EditEntry } from './EditEntry';
import RowActions from './RowActions';
import { StyledRow, StyledVirtualizedRow } from './styles';

class Row extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inEditMode: false,
    };
  }

  onSubmit = (...args) => {
    const actions = this.getActions();
    const editConfig = actions.find((action) => action.id === 'edit');

    const callback = editConfig?.onSubmit || this.props.onSubmit;
    return callback(...args).then(() => this.disableEditMode());
  };

  activateEditMode = () => {
    this.props?.enableTableEditMode?.(this.props.row.entity.id);
    this.setState({
      inEditMode: true,
    });
  };

  disableEditMode = () => {
    this.props?.disableTableEditMode?.();
    this.setState({
      inEditMode: false,
    });
  };

  getCellProps = (cellIdx) => {
    const { columnsWidth } = this.props;
    return {
      key: cellIdx,
      style: {
        width: columnsWidth?.[cellIdx],
      },
    };
  };

  getActions() {
    const { rowActions, row } = this.props;
    return row.action || rowActions;
  }

  render() {
    const {
      row,
      style = {},
      className = '',
      cellRenderers = [],
      onRowClick,
      columnsCount,
      virtualized,
      forceEditMode,
      ...rest
    } = this.props;
    const { inEditMode } = this.state;

    if (forceEditMode || inEditMode) {
      const actions = this.getActions();
      const editConfig = actions.find((action) => action.id === 'edit');

      return (
        <EditEntry
          virtualized={virtualized}
          row={row}
          icon={this.props.icon}
          config={editConfig?.config}
          onSubmit={this.onSubmit}
          onClickCancel={editConfig?.onCancel || this.disableEditMode}
          style={style}
          className={className}
          getCellProps={this.getCellProps}
        />
      );
    }

    const columns = this.props.display || row.display || row.data;
    const maxColumnIndex = (columnsCount || columns.length) - 1;
    return (
      <StyledRow
        as={virtualized ? 'div' : 'tr'}
        style={style}
        className={cx(className, {
          'clickable-row': onRowClick && row.isClickable,
        })}
        {...(onRowClick && { onClick: () => onRowClick(row) })}
        enableHover={!this.props.inTableEditMode}
      >
        {columns.map((cell, i) => {
          const isLastCell = i === row.data.length - 1;
          const cellDisplay = cell || row.data[i];
          const CellRenderer = cellRenderers[i] || Cell;

          return i > maxColumnIndex ? null : (
            <CellRenderer
              virtualized={virtualized}
              className={cx({ last: isLastCell })}
              {...this.getCellProps(i)}
              cell={cellDisplay}
              icon={i == 0 && this.props.icon}
            />
          );
        })}

        <RowActions
          virtualized={virtualized}
          row={row}
          onClickEdit={this.activateEditMode}
          {...rest}
        />
      </StyledRow>
    );
  }
}

export const VirtualizedRow = React.forwardRef((props, ref) => {
  const { style, className, ...rest } = props;
  return (
    <StyledVirtualizedRow ref={ref} className={className} style={style}>
      <Row className={className} {...rest} virtualized />
    </StyledVirtualizedRow>
  );
});

export default Row;
