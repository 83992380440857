import React from 'react';

import { Input, PlainText } from '@float/ui/deprecated';

type InputReadOnlyProps = {
  label: React.ReactNode;
  inline: boolean;
  value: string;
  noMargin: boolean;
  style: React.CSSProperties;
};

export const InputReadOnly: React.FC<InputReadOnlyProps> = ({
  style = {},
  inline,
  value,
  label,
  noMargin,
  ...rest
}) => {
  if (inline) {
    return <PlainText style={{ marginRight: 10 }}>{value}</PlainText>;
  }

  return (
    <Input
      appearance="underline"
      label={label}
      value={value}
      readOnly
      noBorder
      style={noMargin ? style : { marginBottom: 21, ...style }}
      {...rest}
    />
  );
};
