import React from 'react';

import EH from '@float/ui/deprecated/Earhart';

import { StyledContainer } from './styles';

const FreeTrialUpgradeComponent = (props) => {
  const { daysBeforeExpire, planUnknown, isTrial, hasTimeTracking } = props;

  const upgradePath = `/thankyou?plan=${hasTimeTracking ? 'pro' : 'starter'}`;

  if (planUnknown || !isTrial) return null;

  return (
    <StyledContainer>
      {daysBeforeExpire > 0 ? (
        <>
          <p>
            Your free trial has{' '}
            <strong>
              {daysBeforeExpire} {daysBeforeExpire === 1 ? 'day' : 'days'} left
            </strong>
            .
          </p>

          <EH.Buttons.Button href={upgradePath}>
            Upgrade to a paid plan
          </EH.Buttons.Button>
        </>
      ) : (
        <>
          <p>Your free trial has ended. Time to upgrade!</p>
          <EH.Buttons.Button href={upgradePath}>
            Upgrade to a paid plan
          </EH.Buttons.Button>
        </>
      )}
    </StyledContainer>
  );
};

export default FreeTrialUpgradeComponent;
