import React, { ReactNode } from 'react';
import { plural, Trans } from '@lingui/macro';

import { formatRateWithCurrencySymbol } from '@float/common/lib/rates/rates';
import type { CurrencyConfig } from '@float/common/lib/rates/rates';

export type GetConfirmPrimaryMessageProps = {
  rateFrom?: string | null;
  rateTo?: string | null;
  numPeopleAffected: number;
  currencyConfig: CurrencyConfig;
};

/**
 * Returns the primary confirm message
 *
 * It returns ReactNode, since later we might need to style the currency symbols with some tags
 */
export const getConfirmPrimaryMessage = ({
  rateFrom,
  rateTo,
  numPeopleAffected,
  currencyConfig,
}: GetConfirmPrimaryMessageProps): ReactNode => {
  const isRateFromSet = typeof rateFrom === 'string' && rateFrom.length > 0;
  const isRateToSet = typeof rateTo === 'string' && rateTo.length > 0;

  const rateFromFormatted = isRateFromSet
    ? formatRateWithCurrencySymbol(rateFrom, currencyConfig, {
        useSpacing: false,
      })
    : '';
  const rateToFormatted = isRateToSet
    ? formatRateWithCurrencySymbol(rateTo, currencyConfig, {
        useSpacing: false,
      })
    : '';

  const peopleLabel = plural(numPeopleAffected, {
    one: '# Person',
    other: '# People',
  });

  if (isRateFromSet && isRateToSet) {
    return (
      <Trans>
        You are changing the default hourly Role rate from {rateFromFormatted}{' '}
        to {rateToFormatted} for {peopleLabel} assigned to this Role.
      </Trans>
    );
  } else if (isRateFromSet && !isRateToSet) {
    return (
      <Trans>
        You are changing the default hourly Role rate from {rateFromFormatted}{' '}
        to <i>no rate defined</i> for {peopleLabel} assigned to this Role.
      </Trans>
    );
  } else if (!isRateFromSet && isRateToSet) {
    return (
      <Trans>
        You are changing the default hourly Role rate to {rateToFormatted} for{' '}
        {peopleLabel} assigned to this Role.
      </Trans>
    );
  } else {
    return (
      <Trans>
        You are changing the default hourly Role rate for {peopleLabel} assigned{' '}
        to this Role.
      </Trans>
    );
  }
};
