import { ReduxState } from '@float/common/reducers/lib/types';
import { getIsProjectPlanView } from '@float/common/selectors/appInfo/scheduleView';
import { selectSearchFilteredMilestonesMap } from '@float/common/selectors/milestones';
import {
  getPeopleFilteredLoggedTimes,
  getPeopleFilteredTimeoffs,
} from '@float/common/selectors/people/peopleFiltered';
import { getArchivedProjectsIdsSet } from '@float/common/selectors/projects/getProjectsIdsSet';
import { getProjectFilteredPhasesMap } from '@float/common/selectors/projects/projectsFiltered';
import { getStatuses } from '@float/common/selectors/statuses';
import { getFullTasksMap } from '@float/common/selectors/tasks';
import { UseCellsAction } from '@float/common/serena/Data/useCells/types';
import { config } from '@float/libs/config';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { LoadDataMap, Phase, Task } from '@float/types';

import { selectIsWindowingEnabled } from './helpers/selectIsWindowingEnabled';
import { selectVisibleLoggedTimes } from './helpers/selectVisibleLoggedTimes';
import { selectVisiblePhases } from './helpers/selectVisiblePhases';
import { selectVisibleTasks } from './helpers/selectVisibleTasks';

const computeDiff = (current: LoadDataMap, last: LoadDataMap) => {
  const keys = Object.keys(current) as Array<keyof LoadDataMap>;
  const diff: Record<string, unknown> = {};

  for (const key of keys) {
    if (current[key] && last[key] !== current[key]) {
      diff[key] = current[key];
    }
  }

  return diff as Partial<LoadDataMap>;
};

// Using this constant to keep the referential identity between executions
const EMPTY_OBJECT = {};

export type LastBulkDataLoad = {
  data?: LoadDataMap;
  archivedProjectsIds?: Set<number>;
};

/**
 * Loads all the data required for the cells generation
 */
export function handleCellsBulkDataLoad(
  state: ReduxState,
  dispatch: (action: UseCellsAction) => void,
  lastDataLoad: LastBulkDataLoad,
) {
  // OPTIMIZATION: In the web app we load phases only
  // on the project plan view to improve the initial page load
  const shouldLoadPhases = getIsProjectPlanView(state) || config.isMobileApp;

  const windowingEnabled =
    featureFlags.isFeatureEnabled(FeatureFlag.ScheduleDataWindowing) &&
    selectIsWindowingEnabled(state);

  let loggedTime: LoadDataMap['loggedTime'] = EMPTY_OBJECT;

  if (windowingEnabled) {
    loggedTime = selectVisibleLoggedTimes(state);
  } else {
    loggedTime = getPeopleFilteredLoggedTimes(state);
  }

  let task: Record<string, Task> | undefined = undefined;
  let phase: Record<number, Phase> = EMPTY_OBJECT;

  if (windowingEnabled) {
    task = selectVisibleTasks(state);
    phase = selectVisiblePhases(state);
  } else {
    if (state.tasks.fullyHydrated) {
      // We need to load the full task map to make the linked tasks work even if
      // they are not part of the schedule results
      // see: https://linear.app/float-com/issue/CS-1755/
      task = getFullTasksMap(state);
    }

    // Phases are required only in the Project plan view, and are filtered by projects
    // because they are never visible if the parent project is not visible
    if (shouldLoadPhases) {
      phase = getProjectFilteredPhasesMap(state);
    }
  }

  const bulkDataToLoad: LoadDataMap = {
    oneOff: state.oneOffs.oneOffs,
    holiday: state.holidays.holidays,
    milestone: selectSearchFilteredMilestonesMap(state),
    phase,
    status: getStatuses(state),
    task,
    // We filter the loggedTimes/timeoffs by people because:
    //  1. Every Schedule view ends up having people rows
    //  2. When a person is filtered out it is never visible in the Schedule
    //  3. When a person row is not rendered, it's tasks are not rendered
    //  4. LoggedTimes and timeoffs are visible even if filtered out (in "ghost" mode)
    timeoff: getPeopleFilteredTimeoffs(state),
    loggedTime,
  };

  const archivedProjectsIds = getArchivedProjectsIdsSet(state);

  const diff = computeDiff(bulkDataToLoad, lastDataLoad.data || {});

  if (archivedProjectsIds !== lastDataLoad.archivedProjectsIds) {
    // In the log time view we need to hide the suggested tasks related to the archived projects
    // so when a project becomes archived we revalidate the logged time cells
    diff.loggedTime = bulkDataToLoad.loggedTime;
  }

  // It applies a diff check to regenerate only the cells related to the changed entitites
  if (Object.keys(diff).length) {
    dispatch({
      type: 'LOAD_BULK_DATA',
      dataMap: diff,
      lazyProcessing: true,
      ignoreMissing: {
        // LoggedTime is always additive - deleting them visually means creating
        // or updating a LoggedTime record with zero hours. Additionally, we store
        // task references (which are ephemeral) in the same logged time maps.
        // Therefore, there's never a scenario where we want to delete records
        // as a result of a Redux update.
        loggedTime: true,
      },
    });
  }

  return { data: bulkDataToLoad, archivedProjectsIds };
}
