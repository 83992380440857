import { Rights } from '@float/common/lib/acl';
import { CurrentUser } from '@float/types/account';
import { Project } from '@float/types/project';

export const checkSelectedDeleteAccess = (
  selected: Project['project_id'][],
  projects: Record<Project['project_id'], Project>,
  currentUser: CurrentUser,
) => {
  return selected.every((id) => {
    const project = projects[id];
    // Only archived projects can be deleted
    if (project && project.active) return false;
    return Boolean(
      project && Rights.canDeleteProject(currentUser, { project }),
    );
  });
};
