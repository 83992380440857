import React, { PropsWithChildren } from 'react';

import * as styles from './styles.css';

export const SidePanelContent: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={styles.sidePanelContent} id="sidePanelContent">
      {children}
    </div>
  );
};
