import { getModelFromActivity } from '@floatschedule/activity-format-npm';

import { ensureViewsLoaded } from '@float/common/actions/views';
import { AppDispatch } from '@float/common/store';
import { CompanyPreferences, LiveUpdate } from '@float/types';

import { liveUpdateDispatchCreator, showRefreshModal } from './actions.helpers';
import { ManageModalFn } from './types';

const CHANGES_THAT_NEED_REFRESH = new Set<keyof CompanyPreferences>([
  'time_tracking',
  'timezone',
  'start_work_week',
  'timeoff_approvals',
  'work_days_hours',
  'hide_non_wk_days',
]);

export const handleCompanyMessage =
  (data: LiveUpdate, actionType: string, manageModal: ManageModalFn) =>
  (dispatch: AppDispatch) => {
    if (!data) return;
    if (!('notification' in data)) return;
    if (data.notification.type !== 'company') return;

    const { notification } = data;
    if (notification.request_reload) {
      showRefreshModal({ manageModal });
      return;
    }

    const { af_item: activityFeedItem } = notification;
    const dispatchLiveUpdate = liveUpdateDispatchCreator(dispatch);
    const payload = getModelFromActivity(activityFeedItem);

    dispatchLiveUpdate({
      type: actionType,
      payload,
      inputData: payload,
      noRequest: true,
    });

    const changes = Object.keys(activityFeedItem.before_data) as Array<
      keyof CompanyPreferences
    >;

    if (changes.some((change) => CHANGES_THAT_NEED_REFRESH.has(change))) {
      showRefreshModal({ manageModal });
    }

    if (changes.includes('plan_level')) {
      return dispatch(ensureViewsLoaded(true));
    }
  };
