import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon {...props}>
    <defs>
      <linearGradient
        id="icon-resource-planning-gradient"
        x1="15.189"
        x2="38.254"
        y1="15.108"
        y2="41.655"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5DD0CE" />
        <stop offset="1" stopColor="#1B7CEF" />
      </linearGradient>
    </defs>

    <path
      fill="url(#icon-feature-gradient-a)"
      d="M4 6a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3V6Z"
    />
    <path
      fill="url(#icon-feature-gradient-b)"
      d="M3 21a3 3 0 0 1 3-3h26a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-4Z"
    />
    <path
      fill="url(#icon-resource-planning-gradient)"
      d="M28 32a3 3 0 0 0-3 3v4a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-4a3 3 0 0 0-3-3H28Z"
    />
  </BaseIcon>
);
