import React, {
  ComponentType,
  forwardRef,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { TooltipContentProps } from '@radix-ui/react-tooltip';

import { TextTooltip } from '@float/ui/deprecated/Tooltip/TextTooltip';

import {
  StyledIcon,
  StyledLabel,
  StyledSpan,
  StyledTooltipLabel,
} from './styles';

export type Size = 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large';
export type Appearance = 'dynamic' | 'static';
export type Color =
  | 'flue'
  | 'flay'
  | 'green'
  | 'pink'
  | 'blue'
  | 'brown'
  | 'tomato'
  | 'teal'
  | 'orange'
  | 'yellow'
  | 'violet';

export type TagProps = {
  className?: string;
  appearance?: Appearance;
  size?: Size;
  icon?: ComponentType;
  color?: Color | string;
  trailingIcon?: boolean;
  style?: React.CSSProperties;
  clickIconCallback?: () => void;
  onClick?: () => void;
  children?: ReactNode;
  interactive?: boolean;
  'data-testid'?: string;
  iconLabel?: string;
};

export const Tag = forwardRef<
  HTMLSpanElement,
  TagProps & Omit<JSX.IntrinsicElements['span'], 'ref'>
>(
  (
    {
      className,
      appearance = 'static',
      size = 'medium',
      icon,
      color = 'flay',
      children,
      trailingIcon,
      style,
      interactive,
      clickIconCallback,
      onClick,
      ...rest
    },
    ref,
  ) => {
    const labelRef = useRef<HTMLSpanElement>(null);

    const [tooltipDisabled, setTooltipDisabled] = useState(true);

    useEffect(() => {
      const el = labelRef.current;

      if (!el) return;

      const ro = new ResizeObserver(() => {
        const isDisabled = el.offsetWidth === el.scrollWidth;

        setTooltipDisabled(isDisabled);
      });

      ro.observe(el);

      return () => {
        ro.disconnect();
      };
    }, []);

    const [Wrapper, wrapperProps] = tooltipDisabled
      ? [React.Fragment, {}]
      : [
          TextTooltip,
          {
            placement: 'top' as TooltipContentProps['side'],
            content: <StyledTooltipLabel>{children}</StyledTooltipLabel>,
          },
        ];

    return (
      <Wrapper {...wrapperProps}>
        <StyledSpan
          as={interactive ? 'button' : 'span'}
          ref={ref}
          appearance={appearance}
          isClickable={!!onClick}
          isClickableIcon={!!clickIconCallback}
          withIcon={!!icon}
          size={size}
          color={color}
          className={className}
          trailingIcon={trailingIcon}
          style={style}
          onClick={onClick}
          {...rest}
        >
          {icon && (
            <StyledIcon onClick={clickIconCallback} data-testid="RemoveTag">
              {React.createElement(icon)}
            </StyledIcon>
          )}
          <StyledLabel ref={labelRef}>{children}</StyledLabel>
        </StyledSpan>
      </Wrapper>
    );
  },
);

export default Tag;
