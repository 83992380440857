import React, { useState } from 'react';

import { useOnMount } from '@float/libs/hooks/useOnMount';

const fontList = ['Lexend', 'Inter'];

/**
 * Fonts are fetched only when referenced.
 *
 * In order to reduce the probability of FOUT after the loading screen
 * we trigger the custom fonts fetching ASAP
 *
 * Since we are using Google Fonts as an external resource, rel=preload is not an option
 * because we don't know the font URL.
 */
export function FontsPrefetch() {
  const [done, setDone] = useState(false);

  useOnMount(() => {
    setDone(true);
  });

  if (done) return null;

  return (
    <>
      {fontList.map((fontFamily) => (
        <div
          key={fontFamily}
          style={{ fontFamily, top: -99999, position: 'fixed' }}
        >
          X
        </div>
      ))}
    </>
  );
}
