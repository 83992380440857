import { config } from '@float/libs/config';

export type ViewType = 'people' | 'projects';

export type InsightType = 'schedule' | 'projectPlan' | 'logTeam';

export const getIsProjectsView = (viewType: ViewType | undefined) => {
  return viewType === 'projects';
};

export const getRelevantInsightType: (
  viewType: ViewType | undefined,
  isLogTimeView: boolean,
) => InsightType = (viewType, isLogTimeView) => {
  if (isLogTimeView) return 'logTeam';
  if (viewType === 'people') return 'schedule';
  if (viewType === 'projects') return 'projectPlan';

  return 'schedule';
};

export const convertNumberToLocaleString = (
  value: number,
  options?: Intl.NumberFormatOptions,
) => {
  return value.toLocaleString(config.locale);
};

/**
 * Calculates the utulization
 *
 * It rounds the closest digit after point
 */

export const getUtilization = ({
  scheduled,
  totalCapacity,
}: {
  scheduled: number;
  totalCapacity: number;
}) => {
  return totalCapacity === 0
    ? 0
    : Math.round((1000 * scheduled) / totalCapacity) / 10;
};
