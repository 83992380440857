import React, { forwardRef } from 'react';

import { CellItem } from '@float/types';

import { ScheduleActions } from '../../types';
import { BaseEntityLayer, BaseEntityLayerProps } from '../BaseEntityLayer';

import * as styles from './styles.css';

type GhostLayerProps = {
  isStart?: boolean;
  isEnd?: boolean;
  dimensions: React.CSSProperties;
  item: CellItem<'timeoff'> | CellItem<'task'> | CellItem<'loggedTime'>;
  tooltipProps?: BaseEntityLayerProps['tooltipProps'];
  actions: ScheduleActions;
};

const GhostLayer = forwardRef<HTMLDivElement, GhostLayerProps>((props, ref) => {
  const { isStart, isEnd, dimensions, item, tooltipProps, actions } = props;

  const onClick = () => {
    actions.onItemClick(item);
  };

  const onMouseDown = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <>
      <BaseEntityLayer
        type="ghost"
        ref={ref}
        data-floatid={item.key}
        isEnd={isEnd}
        isStart={isStart}
        onClick={onClick}
        onMouseDown={onMouseDown}
        rounded
        style={dimensions}
        tooltipProps={tooltipProps}
      >
        <div
          className={styles.ghostBox}
          data-is-start={isStart}
          data-is-end={isEnd}
        />
      </BaseEntityLayer>
    </>
  );
});

export default GhostLayer;
