import { filter, max, min } from 'lodash';

export function getDownstreamLinkedTasks(tasks, taskId) {
  const res = [];
  const parentIds = [taskId];

  let added = true;
  while (added) {
    // task_ids are a mix of strings and numbers, so we have to check with ==
    const newChildren = filter(
      tasks,
      (t) =>
        parentIds.some((id) => id == t.parent_task_id) &&
        !res.some((r) => r == t),
    );
    added = newChildren.length > 0;
    res.push(...newChildren);
    parentIds.push(...newChildren.map((t) => t.task_id));
  }

  return res;
}

export function unlink(tasks, newRootTaskIds = []) {
  newRootTaskIds.forEach((newRootId) => {
    tasks[newRootId].root_task_id = newRootId;
    tasks[newRootId].parent_task_id = null;

    getDownstreamLinkedTasks(tasks, newRootId).forEach((downstreamTask) => {
      downstreamTask.root_task_id = newRootId;
      downstreamTask.parent_task_id = newRootId;
    });
  });
}

export function getLinkParent(cells, maps, bimaps, item) {
  // Logged time cells are never candidates for link arrows
  let candidateCells = bimaps.task
    .getFwd(String(item.entity.parent_task_id))
    .filter((c) => !c.startsWith('logged_time'));

  const parentEntity = maps.task[item.entity.parent_task_id];
  if (parentEntity?.people_ids?.length > 1) {
    const targetRow = `person-${parentEntity.people_ids[0]}`;
    candidateCells = candidateCells.filter((c) => c.startsWith(targetRow));
  }

  const parentCell = cells[candidateCells[candidateCells.length - 1]];
  const parentItem = parentCell?.items.find(
    (i) => i.entity.task_id == item.entity.parent_task_id && i.isEnd,
  );

  return parentItem;
}

export function getLinkSourceItem(cells, bimaps, item) {
  // We want the right-most item on the same row the user is hovering on
  const candidateCells = bimaps.task
    .getFwd(String(item.entity.task_id))
    .filter((ck) => ck.startsWith(item.rowId));
  const cell = cells[max(candidateCells)];
  return cell.items.find((i) => i.entity.task_id == item.entity.task_id);
}

export function getLinkTargetItem(cells, bimaps, item) {
  // We want the left-most item on the same row the user is hovering on
  const candidateCells = bimaps.task
    .getFwd(String(item.entity.task_id))
    .filter((ck) => ck.startsWith(item.rowId));
  const cell = cells[min(candidateCells)];
  return cell.items.find((i) => i.entity.task_id == item.entity.task_id);
}
