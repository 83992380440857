import React, { CSSProperties } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
`;

const Inner = styled.div`
  width: 120px;
  height: 120px;
`;

export default ({
  className = '',
  small,
  hidden,
  style,
}: {
  style?: CSSProperties;
  className?: string;
  small?: boolean;
  hidden?: boolean;
}) => {
  if (small) {
    className += ' small';
  }
  if (hidden) {
    className += ' hidden';
  }
  return (
    <Wrapper className={className} style={style}>
      <Inner className="wrap">
        <svg
          id="float-logo-anim"
          viewBox="0 0 500 500"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="200.413"
            height="64.2857"
            rx="32.1428"
            transform="matrix(-0.731943 -0.681366 0.684371 -0.729135 276.299 295.312)"
            fill="#86BFF4"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M174.886 125C157.134 125 142.743 139.391 142.743 157.143C142.743 174.895 157.134 189.286 174.886 189.286H389.171C406.923 189.286 421.314 174.895 421.314 157.143C421.314 139.391 406.923 125 389.171 125H174.886ZM109.886 250C109.886 232.248 124.277 217.857 142.029 217.857H274.886C292.638 217.857 307.029 232.248 307.029 250C307.029 267.752 292.638 282.143 274.886 282.143H142.028C124.276 282.143 109.886 267.752 109.886 250ZM78.4571 342.857C78.4571 325.105 92.8479 310.714 110.6 310.714H160.6C178.352 310.714 192.743 325.105 192.743 342.857C192.743 360.609 178.352 375 160.6 375H110.6C92.848 375 78.4571 360.609 78.4571 342.857Z"
            fill="#2E5FE8"
          />
        </svg>
      </Inner>
    </Wrapper>
  );
};
