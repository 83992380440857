import { keyBy, map } from 'lodash';

import type { StatusType } from '@float/types';

import {
  STATUS_TYPE_UPDATED,
  STATUS_TYPES_LOAD_FAILED,
  STATUS_TYPES_LOAD_FINISH,
} from '../actions';

export type StatusTypesState = {
  statusTypes: Record<string, StatusType>;
  loadState: 'UNLOADED' | 'LOADED' | 'LOAD_FAILED';
};

const DEFAULT_STATE: StatusTypesState = {
  statusTypes: {},
  loadState: 'UNLOADED',
};

export type StatusTypesAction =
  | {
      type: typeof STATUS_TYPES_LOAD_FAILED;
    }
  | {
      type: typeof STATUS_TYPES_LOAD_FINISH;
      statusTypes: StatusType[];
    }
  | {
      type: typeof STATUS_TYPE_UPDATED;
      statusType: StatusType;
    };

const getStatusTypeModel = (statusType: StatusType) => ({
  ...statusType,
  status_type_name: statusType.status_type_name || 'Custom',
});

const statusTypes = (
  state = DEFAULT_STATE,
  action: StatusTypesAction,
): StatusTypesState => {
  switch (action.type) {
    case STATUS_TYPES_LOAD_FAILED: {
      return {
        ...state,
        loadState: 'LOAD_FAILED',
        statusTypes: {},
      };
    }

    case STATUS_TYPES_LOAD_FINISH: {
      const types = map(action.statusTypes, getStatusTypeModel);
      return {
        ...state,
        loadState: 'LOADED',
        statusTypes: keyBy(types, 'status_type_id'),
      };
    }

    case STATUS_TYPE_UPDATED: {
      const { statusType } = action;
      if (!statusType) {
        return state;
      }

      return {
        ...state,
        statusTypes: {
          ...state.statusTypes,
          [statusType.status_type_id]: getStatusTypeModel(statusType),
        },
      };
    }

    default:
      return state;
  }
};

export default statusTypes;
