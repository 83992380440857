import { ajaxForm } from './ajax-form';
import { send } from './send';

export function form(url, form, opts = {}) {
  const params = {
    method: opts.method || 'post',
    body: form,
  };
  if (!opts.onProgress) {
    return send(url, params, opts);
  }
  return ajaxForm(url, params, opts);
}
