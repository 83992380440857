import { useQuery } from '@tanstack/react-query';

import { config } from '@float/libs/config';
import { SharePageData, SharePageNotFound } from '@float/types/pageData';

import { requestSharePageData } from '.';
import { Resources } from '../constants';

export const useRequestSharePageData = () => {
  const sharedPageLinkToken = config.sharedViewToken;

  return useQuery<SharePageData, SharePageNotFound>({
    queryKey: [Resources.SharePageData],
    queryFn: () => requestSharePageData(sharedPageLinkToken ?? ''),
    enabled: Boolean(sharedPageLinkToken),
  });
};
