import React, { ForwardedRef, forwardRef, MouseEvent } from 'react';
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import { RecipeVariants } from '@vanilla-extract/recipes';
import cn from 'classnames';

import * as styles from './styles.css';

export type ScrollAreaProps = ScrollAreaPrimitive.ScrollAreaProps;

export const Root = forwardRef(
  (props: ScrollAreaProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { children, className, ...otherProps } = props;

    return (
      <ScrollAreaPrimitive.Root
        asChild
        scrollHideDelay={300}
        {...otherProps}
        className={cn(styles.scrollAreaRoot, className)}
      >
        <div ref={ref} className={styles.scroller}>
          {children}
        </div>
      </ScrollAreaPrimitive.Root>
    );
  },
);

export const Content = forwardRef(
  (
    props: ScrollAreaPrimitive.ScrollAreaViewportProps & {
      appearance?: NonNullable<
        RecipeVariants<typeof styles.scrollAreaScrollbar>
      >['appearance'];
      onClickScrollbar?: (e: MouseEvent<HTMLDivElement>) => void;
    },
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const { appearance, children, className, onClickScrollbar, ...rest } =
      props;

    return (
      <>
        <ScrollAreaPrimitive.Viewport
          {...rest}
          ref={ref}
          className={cn(styles.scrollAreaViewport, className)}
        >
          {children}
        </ScrollAreaPrimitive.Viewport>

        <ScrollAreaPrimitive.Scrollbar
          className={styles.scrollAreaScrollbar({ appearance })}
          forceMount
          onClick={onClickScrollbar}
          orientation="vertical"
          // to be able to tweak the positioning of the scrollbar via CSS Variables on a parent element
          style={{
            bottom: 'invalidate',
            top: 'invalidate',
            right: 'invalidate',
          }}
        >
          <ScrollAreaPrimitive.Thumb className={styles.scrollAreaThumb} />
        </ScrollAreaPrimitive.Scrollbar>
        <ScrollAreaPrimitive.Scrollbar
          className={styles.scrollAreaScrollbar({ appearance })}
          orientation="horizontal"
        >
          <ScrollAreaPrimitive.Thumb className={styles.scrollAreaThumb} />
        </ScrollAreaPrimitive.Scrollbar>
        <ScrollAreaPrimitive.Corner />
      </>
    );
  },
);

export const ScrollArea = { Root, Content };
