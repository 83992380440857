import { useState } from 'react';

import { useModalOpenListener } from '../lib/useModalOpenListener';
import { usePathnameChangeListener } from '../lib/usePathnameChangeListener';
import { PanelType } from './types';
import { useSidePanelConflictManager } from './useSidePanelConflictManager';

export function useSidePanelCloseInteractionController(
  onClose: () => void,
  enabled: boolean,
  panelType: PanelType,
) {
  const handleClose = () => {
    if (!enabled) {
      onClose();
    }
  };

  const modal = useModalOpenListener(handleClose);
  const navigation = usePathnameChangeListener(handleClose, enabled);
  const conflict = useSidePanelConflictManager(enabled, panelType);

  const [closeButtonBlocked, setCloseButtonBlocked] = useState(false);
  const handleCloseButtonClick = () => {
    if (enabled) {
      setCloseButtonBlocked(true);
    } else {
      onClose();
    }
  };

  function handleLeavePromptCancel() {
    modal.hideOpenModal();
    navigation.cancel();
    conflict.cancel();
    setCloseButtonBlocked(false);
  }

  function handleLeavePromptConfirm() {
    navigation.resume();

    if (!conflict.active) {
      onClose();
    } else {
      conflict.commit();
    }
  }

  const isBlocked =
    (modal.isModalOpen && !modal.skipSidePanelAutoClose) ||
    navigation.isBlocked ||
    closeButtonBlocked ||
    conflict.active;

  return {
    isBlocked,
    handleLeavePromptCancel,
    handleLeavePromptConfirm,
    handleCloseButtonClick,
  };
}
