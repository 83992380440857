import { t } from '@lingui/macro';

import { IconFilter } from '@float/ui/icons/essentials/IconFilter';

import { useProjectScheduleFilter } from '../../../../hooks/useProjectScheduleFilter';
import { useProject } from './useProject';

export function useViewUsingFilterMenuItem() {
  const project = useProject();

  const showOnSchedule = useProjectScheduleFilter({
    key: 'project',
    value: project?.project_name,
  });

  return {
    value: t`View using filter`,
    icon: IconFilter,
    onClick: showOnSchedule,
  };
}
