import React from 'react';

import { StyledTable } from './styles';
import Tbody from './Tbody';
import Thead from './Thead';

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.infiniteScrollRowRef = this.infiniteScrollRowRef.bind(this);
  }

  componentWillUnmount() {
    this.componentWillUnmountInfiniteScroll();
  }

  componentDidMount() {
    this.componentDidUpdateInfiniteScroll();
  }

  componentDidUpdate() {
    this.componentDidUpdateInfiniteScroll();
  }

  componentWillUnmountInfiniteScroll() {
    if (!this.intersectionObserver) return;
    this.intersectionObserver.disconnect();
  }

  componentDidUpdateInfiniteScroll() {
    const { onLastRowVisible } = this.props;
    if (!onLastRowVisible) return;
    if (!this.intersectionObserver) {
      this.intersectionObserver = new IntersectionObserver(
        (entries) => this.infiniteScrollCallback(entries),
        {
          // eslint-disable-line
          threshold: 1.0,
        },
      );
    }
    if (this.infiniteScrollRow) {
      this.intersectionObserver.disconnect();
      this.intersectionObserver.observe(this.infiniteScrollRow);
    }
  }

  infiniteScrollRowRef(node) {
    this.infiniteScrollRow = node;
  }

  infiniteScrollCallback(entries) {
    const { onLastRowVisible } = this.props;
    if (!onLastRowVisible || !Array.isArray(entries)) return;

    // expect one entry
    entries.forEach((entry) => {
      if (
        entry.target === this.infiniteScrollRow &&
        entry.intersectionRatio > 0.9
      ) {
        onLastRowVisible();
      }
    });
  }

  render() {
    const {
      className = '',
      headNames,
      headNameKeys,
      rows,
      totalCount,
      displayRowCount,
      sort,
      onClickHeader,
      rowActions,
      columnsWidth,
      ...rest
    } = this.props;

    const displayRows = rest.filter ? rows.filter(rest.filter) : rows;
    const hasRows = displayRows.length > 0;

    return (
      <>
        <StyledTable className={className}>
          <Thead
            names={headNames}
            nameKeys={headNameKeys}
            rowCount={displayRowCount && totalCount}
            sort={sort}
            onClickHeader={onClickHeader}
            rowActions={rowActions}
            visible={hasRows}
            columnsWidth={columnsWidth}
          />
          <Tbody
            rows={displayRows}
            infiniteScrollRowRef={this.infiniteScrollRowRef}
            rowActions={rowActions}
            {...rest}
          />
        </StyledTable>
      </>
    );
  }
}

export default Table;
