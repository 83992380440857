import { MODAL_DEFAULT_WIDTH } from '@float/ui/deprecated/Modal/ModalDialog/ModalDialog';
import { SIDE_PANEL_WIDTH } from '@float/web/components/SidePanel/styles.css';

// The desired gap between the modal and the side panel
const DESIRED_GAP = 80;

export function getModalCenterOffset(props: {
  sidePanelOpen?: boolean;
  sidePanelWidth?: number;
  modalWidth?: number;
  screenWidth: number;
}) {
  if (!props.sidePanelOpen) return 0;

  const screenWidth = props.screenWidth ?? 0;
  const modalWidth = props.modalWidth ?? MODAL_DEFAULT_WIDTH;
  const sidePanelWidth = props.sidePanelWidth ?? SIDE_PANEL_WIDTH;

  // Calculate the right edge of the viewport when the side panel is opened
  const viewportRightEdge = screenWidth - sidePanelWidth;

  // Calculate the desired right edge of the modal
  const modalDesiredRightEdge = viewportRightEdge - DESIRED_GAP;

  // Calculate the current right edge of the modal
  const modalCurrentRightEdge = screenWidth / 2 + modalWidth / 2;

  // Calculate how much to move the modal to the left
  let moveLeft = modalCurrentRightEdge - modalDesiredRightEdge;

  // Ensure the modal's left edge never goes out of the visible viewport
  if (modalDesiredRightEdge - modalWidth < 0) {
    moveLeft = modalCurrentRightEdge - modalWidth;
  }

  // If there's already enough room, don't move the modal
  if (moveLeft <= 0) {
    return 0;
  }

  return -1 * moveLeft;
}
