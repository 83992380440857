import React, { ReactNode, useEffect } from 'react';
import { Trans } from '@lingui/macro';
import * as Dialog from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { usePresence } from 'framer-motion';

import { FormType } from '../panelViews/Project/types';

import * as styles from './styles.css';

type SidePanelDialogProps = {
  children: ReactNode;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  animate?: boolean;
  formType: FormType;
};

export const SidePanel: React.FC<SidePanelDialogProps> = ({
  children,
  isOpen,
  onOpenChange,
  animate = true,
  formType,
}) => {
  const [isPresent, safeToRemove] = usePresence();

  // onAnimationEnd is not fired when closing after a navigation
  // So we are resorting to a timeout to syncronize with the animation end
  useEffect(() => {
    if (!isPresent) {
      const timer = setTimeout(
        safeToRemove,
        styles.SIDE_PANEL_CLOSE_ANIMATION_TIME,
      );

      return () => {
        clearTimeout(timer);
      };
    }
  });

  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange} modal={false}>
      <Dialog.Portal>
        <Dialog.Content
          aria-describedby={undefined}
          data-testid="sidePanel"
          data-animate={animate}
          className={styles.sidePanel}
          onInteractOutside={(event) => event.preventDefault()}
          onEscapeKeyDown={(event) => event.preventDefault()}
        >
          <VisuallyHidden>
            <Dialog.Title>
              <Trans>{formType} form</Trans>
            </Dialog.Title>
          </VisuallyHidden>
          {children}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
