import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { t } from '@lingui/macro';
import isNil from 'lodash/isNil';

import { useModalControls } from '@float/ui/deprecated/Modal/hooks';
import { IconCalendarPlus } from '@float/ui/icons/essentials/IconCalendarPlus';
import { IconTrash2 } from '@float/ui/icons/essentials/IconTrash2';
import { ConfirmModal } from 'components/modals/ConfirmModal';

import { SidePanelMeatballMenu } from '../../components/SidePanelMeatballMenu';
import { useFieldArrayContext } from '../../hooks/useFieldArrayContext';
import { useProjectOrPhaseFieldValue } from '../../hooks/useProjectOrPhaseFieldValue';
import { ProjectFormData } from '../../types';

type ProjectMilestoneMenuProps = {
  index: number;
  showingDateRange: boolean;
  toggleShowDateRange: () => void;
};

export const ProjectMilestoneMenu: React.FC<ProjectMilestoneMenuProps> = ({
  index,
  showingDateRange,
  toggleShowDateRange,
}) => {
  const confirmDeleteModal = useModalControls();
  const { remove } = useFieldArrayContext<ProjectFormData, 'milestones'>();
  const { setValue, clearErrors, trigger } = useFormContext<ProjectFormData>();
  const isActive = useProjectOrPhaseFieldValue('active');
  const milestone = useWatch({ name: `milestones.${index}` });
  const isShowingDuration = !isNil(milestone.duration);

  const onDelete = () => {
    remove(index);
    clearErrors('milestones');
    trigger('milestones');
  };

  const toggleDateRange = () => {
    setValue(`milestones.${index}.end_date`, milestone.date);
    toggleShowDateRange();
  };

  const items = [
    {
      value: showingDateRange ? t`Remove date range` : t`Add date range`,
      onClick: toggleDateRange,
      disabled: !isActive || isShowingDuration,
      icon: IconCalendarPlus,
    },
    {
      value: t`Delete`,
      onClick: confirmDeleteModal.openModal,
      disabled: !isActive,
      icon: IconTrash2,
    },
  ];

  return (
    <React.Fragment>
      <SidePanelMeatballMenu
        disabled={!milestone.name?.trim() && !milestone.milestone_id}
        vertical
        items={items}
        trigger={{
          label: t`Milestone options`,
        }}
      />
      {confirmDeleteModal.isModalVisible && (
        <ConfirmModal
          onClose={confirmDeleteModal.closeModal}
          onConfirm={onDelete}
          title={t`Delete this milestone?`}
          message={t`Are you sure you want to delete this milestone?`}
          confirmLabel={t`Delete`}
          confirmAppearance="danger"
        />
      )}
    </React.Fragment>
  );
};
