import React, { ReactNode } from 'react';
import * as Select from '@radix-ui/react-select';
import cn from 'classnames';

import { IconCheck } from '@float/ui/icons/essentials/IconCheck';
import { IconChevronDropdown } from '@float/ui/icons/essentials/IconChevronDropdown';

import * as styles from './DropdownSelect.css';

// TODO https://linear.app/float-com/issue/FT-1593/technical-debt-refactor-the-dropdown-to-use-the-radix-primitive
export type DropdownSelectProps<V extends string> = {
  options: DropdownSelectOption<V>[];
  value: string;
  onChange: (option: DropdownSelectOption<V>) => void;
  'aria-label'?: string;
  placeholder?: string;
};

type DropdownSelectOption<V extends string> = {
  value: V;
  label: ReactNode;
};

export const DropdownSelect = <V extends string>(
  props: DropdownSelectProps<V>,
) => {
  function handleValueChange(value: string) {
    const option = props.options.find((option) => option.value === value);

    if (option) {
      props.onChange(option);
    }
  }

  return (
    <Select.Root value={props.value} onValueChange={handleValueChange}>
      <Select.Trigger
        className={styles.button}
        aria-label={props['aria-label']}
      >
        <Select.Value placeholder={props.placeholder} />
        <Select.Icon className={styles.icon}>
          <IconChevronDropdown size={16} />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content
          className={styles.content}
          position="popper"
          align="start"
        >
          <Select.Viewport>
            {props.options.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

const SelectItem = React.forwardRef<HTMLDivElement, Select.SelectItemProps>(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <Select.Item
        className={cn(styles.item, className)}
        {...props}
        ref={forwardedRef}
      >
        <Select.ItemText>{children}</Select.ItemText>
        <Select.ItemIndicator className={styles.icon}>
          <IconCheck size={16} />
        </Select.ItemIndicator>
      </Select.Item>
    );
  },
);
