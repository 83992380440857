import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { stopPropagation } from '@float/libs/utils/events/stopPropagation';
import { TextTooltip } from '@float/ui/deprecated/Tooltip/TextTooltip';

import * as styled from './styles';

type HoverLinkIconProps = {
  to: Location | string;
  className?: string;
  icon?: ReactNode;
  children?: ReactNode;
  label?: ReactNode;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
};

const HoverLinkIcon: React.FC<HoverLinkIconProps> = ({
  to,
  className,
  icon,
  label,
  children,
  disabled,
  onClick,
}) => {
  if (children && to) {
    throw Error('Cannot specify both children and to props');
  }

  const element = (
    <styled.HoverLinkIcon
      className={cn(className || '', disabled && 'disabled')}
      onClick={(e) => {
        stopPropagation(e);
        if (onClick) onClick(e);
      }}
    >
      {children || (
        <Link to={to}>
          {icon}
          {label && <span className="mobile">{label}</span>}
        </Link>
      )}
    </styled.HoverLinkIcon>
  );

  if (disabled) {
    return element;
  }

  return (
    <TextTooltip content={label} className="hint" delay={250}>
      {element}
    </TextTooltip>
  );
};

export default HoverLinkIcon;
