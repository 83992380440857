import {
  ADD_HOLIDAY,
  ADD_HOLIDAY_FAILURE,
  ADD_HOLIDAY_SUCCESS,
  DELETE_HOLIDAY_SUCCESS,
  UPDATE_HOLIDAY,
  UPDATE_HOLIDAY_FAILURE,
  UPDATE_HOLIDAY_SUCCESS,
} from '@float/common/actions';
import { moment } from '@float/libs/moment';

import {
  FETCH_HOLIDAYS,
  FETCH_HOLIDAYS_FAILURE,
  FETCH_HOLIDAYS_SUCCESS,
  SORT_HOLIDAYS,
} from '../actions/holidays';
import {
  ACCOUNT_SETTINGS_HOLIDAYS_TABLE,
  DEFAULT_SORT_TABLE,
  getInitialSortableTableState,
  getInitialSortPropsState,
  getInitialStateCreator,
  reducerCreator,
} from './reducerHelpers';

export const lastFetchError = (state) => state.lastFetchError;

export const areBeingFetched = (state) => state.pagesBeingFetched.length > 0;

export const getHolidaysYears = (state) =>
  state.entities.reduce((years, entity) => {
    const year = moment(entity.date).format('Y');
    if (!years.includes(year)) {
      years.push(year);
    }
    years.sort();
    return years;
  }, []);

export const isHolidayInYear = (entity, year) => {
  const entityYear = moment(entity.date).format('Y');
  return entityYear == year;
};

const getInitialState = getInitialStateCreator(
  Object.assign(
    {
      sortableTables: {
        [DEFAULT_SORT_TABLE]: getInitialSortableTableState('date', 'asc'),
        [ACCOUNT_SETTINGS_HOLIDAYS_TABLE]: getInitialSortableTableState(
          'date',
          'asc',
        ),
      },
    },
    getInitialSortPropsState(['name', 'date', 'end_date']),
  ),
);

// must be enclosed in a function for constants to be available for evaluation
const holidays = reducerCreator(
  {
    ADD_ENTITY: ADD_HOLIDAY,
    ADD_ENTITY_SUCCESS: ADD_HOLIDAY_SUCCESS,
    ADD_ENTITY_FAILURE: ADD_HOLIDAY_FAILURE,
    DELETE_ENTITY_SUCCESS: DELETE_HOLIDAY_SUCCESS,
    UPDATE_ENTITY: UPDATE_HOLIDAY,
    UPDATE_ENTITY_SUCCESS: UPDATE_HOLIDAY_SUCCESS,
    UPDATE_ENTITY_FAILURE: UPDATE_HOLIDAY_FAILURE,
    SORT_ENTITIES: SORT_HOLIDAYS,
    FETCH_ENTITIES: FETCH_HOLIDAYS,
    FETCH_ENTITIES_SUCCESS: FETCH_HOLIDAYS_SUCCESS,
    FETCH_ENTITIES_FAILURE: FETCH_HOLIDAYS_FAILURE,
  },
  'holiday_id',
  getInitialState,
);

export default holidays;
