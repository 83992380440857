import React, { PropsWithChildren } from 'react';
import cn from 'classnames';

import * as styles from './styles.css';

type SidePanelTableHeadingProps = {
  className?: string;
  // inactive means we don't render the element
  active?: boolean;
  // hide sets visibility to hidden
  hide?: boolean;
};

export const SidePanelTableHeading: React.FC<
  PropsWithChildren<SidePanelTableHeadingProps>
> = ({ className, active = true, hide = false, children }) => {
  return active ? (
    <div
      role="columnheader"
      className={cn(styles.tableItemWrapper, styles.tableHeading, className)}
      aria-hidden={hide}
    >
      {children}
    </div>
  ) : null;
};
