import styled from 'styled-components';

export const StyledContainer = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
`;

export const StyledButtonsWrapper = styled.div`
  margin-top: 40px;

  > button:first-child {
    margin-right: 5px;
  }
`;
