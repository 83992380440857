import { plural, t } from '@lingui/macro';

import { capitalize } from '@float/libs/utils/string/capitalize';
import { Person } from '@float/types/person';
import {
  TimeoffBalanceType,
  TimeoffType,
  TimeoffTypeBalance,
} from '@float/types/timeoffType';

export const formatTimeoffTypeAsOption = (
  type: TimeoffType,
  timeoffTypesBalance: TimeoffTypeBalance[] | undefined,
  personId: Person['people_id'] | undefined,
) => {
  const hasPerson = Boolean(personId);
  const currentTimeoffTypeBalance = timeoffTypesBalance?.find(
    (balance) => balance.id === type.timeoff_type_id,
  );

  const description = getDescription(
    type,
    currentTimeoffTypeBalance,
    hasPerson,
  );

  // if personId is not defined, the time off types balance will always be
  // undefined so we turn off the loading state
  // https://linear.app/float-com/issue/EXP-601
  const isLoadingDescription = hasPerson && !description;

  return {
    label: capitalize(type.timeoff_type_name),
    value: type.timeoff_type_id,
    description,
    isLoadingDescription,
  };
};

const getDescription = (
  type: TimeoffType,
  balance: TimeoffTypeBalance | undefined,
  hasPerson: boolean,
) => {
  if (!hasPerson) return undefined;

  if (type.balance_type === TimeoffBalanceType.Unlimited) {
    return t`Unlimited`;
  }

  if (balance) {
    return plural(balance.daysAvailable, {
      one: '# day available',
      other: '# days available',
    });
  }

  return undefined;
};
