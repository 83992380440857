import { connect } from 'react-redux';

import { changeAccountOwner } from '@float/common/actions/currentUser';
import { fetchAllAccounts } from '@float/web/settingsV2/actions/accounts';

import withOnMount from '../../../decorators/withOnMount';
import AccountOwner from './component';

const mapAccountsToDropdown = (accounts) =>
  accounts.map((account) => ({
    label: account.name,
    value: account.account_id,
  }));

const mapStateToProps = (state) => ({
  account_owner: {
    label: state.currentUser.name,
    value: state.currentUser.admin_id,
  },
  companyId: state.currentUser.cid,
  accounts: mapAccountsToDropdown(state.settingsAccounts.entities),
});

const mapDispatchToProps = (dispatch) => ({
  onComponentDidMount: () => {
    dispatch(fetchAllAccounts());
  },
  onSubmit: (id) => dispatch(changeAccountOwner(id)),
});

const StatefulAccountOwner = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOnMount(AccountOwner));

export default StatefulAccountOwner;
