import React, { ReactNode } from 'react';

import { FloatLogo } from '@float/ui/components/FloatLogo';

import * as styles from './styles.css';

type AppErrorPageProps = { children: ReactNode };

const AppErrorPage = ({ children }: AppErrorPageProps) => (
  <div className={styles.appErrorWrapper}>
    <div className={styles.logo}>
      <FloatLogo height={26} />
    </div>

    <div className={styles.appErrorContent}>{children}</div>
  </div>
);

export { AppErrorPage };
