import { isFullDayTimeoff } from '@float/common/lib/timeoffs';
import { config } from '@float/libs/config';

import { MainCellItem } from '../BoxElements/types';

function getHours(entity: MainCellItem['entity']) {
  if ('hours' in entity) {
    return entity.hours ?? -1;
  }

  return -1;
}

export function getNumRows(entity: MainCellItem['entity'], hourHeight: number) {
  if (isFullDayTimeoff(entity)) return 5;

  const targetHours = config.fixedHourHeight ?? getHours(entity);

  if (hourHeight === 8) {
    if (targetHours >= 13) return 5;
    if (targetHours >= 10) return 4;
    if (targetHours >= 8.5) return 3;
    if (targetHours >= 6.75) return 2;
    if (targetHours >= 4.75) return 1;
    return 0;
  }

  if (hourHeight === 16) {
    if (targetHours >= 5.5) return 5;
    if (targetHours >= 5.25) return 4;
    if (targetHours >= 4.5) return 3;
    if (targetHours >= 3.5) return 2;
    if (targetHours >= 2.5) return 1;
    return 0;
  }

  if (hourHeight === 56 || hourHeight === 64) {
    if (targetHours >= 1.75) return 5;
    if (targetHours >= 1.5) return 4;
    if (targetHours >= 1.25) return 3;
    if (targetHours >= 1) return 2;
    if (targetHours >= 0.75) return 1;
    return 0;
  }

  throw new Error(`Unknown hourheight ${hourHeight}`);
}
