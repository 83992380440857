import React, { useMemo, useState } from 'react';
import { t } from '@lingui/macro';
import useUpdateEffect from 'react-use/esm/useUpdateEffect';

import { PeopleProjectViewStatus } from '@float/common/actions';
import { TextToggle } from '@float/ui/deprecated/Earhart/Toggles';

export type ActiveArchivedFilterOption = {
  value: PeopleProjectViewStatus;
  label: string;
};

export type ActiveArchivedFilterProps = {
  activeCount: number;
  archivedCount: number;
  onChange: (option: ActiveArchivedFilterOption) => void;
  value: ActiveArchivedFilterOption['value'];
};

const clampCount = (count: number) => {
  if (count > 500) {
    return '500+';
  }
  return count.toString();
};

export const ActiveArchivedFilter = (props: ActiveArchivedFilterProps) => {
  const { value, onChange, activeCount = 0, archivedCount = 0 } = props;

  const currentActiveCount = clampCount(activeCount);
  const currentArchivedCount = clampCount(archivedCount);

  const options: ActiveArchivedFilterOption[] = useMemo(
    () => [
      {
        value: PeopleProjectViewStatus.Active,
        label: t`${currentActiveCount} Active`,
      },
      {
        value: PeopleProjectViewStatus.Archived,
        label: t`${currentArchivedCount} Archived`,
      },
      { value: PeopleProjectViewStatus.All, label: t`All` },
    ],
    [currentActiveCount, currentArchivedCount],
  );

  const [selectedOption, setSelectedOption] = useState(
    options.find(
      (option) => option.value === value,
    ) as ActiveArchivedFilterOption,
  );

  const onToggleChange = (option: ActiveArchivedFilterOption) => {
    setSelectedOption(option);
    onChange(option);
  };

  useUpdateEffect(() => {
    setSelectedOption(
      options.find(
        (option) => option.value === value,
      ) as ActiveArchivedFilterOption,
    );
  }, [value]);

  return (
    <TextToggle
      onChange={onToggleChange}
      options={options}
      value={selectedOption.value}
      variant="nav"
    />
  );
};
