import React from 'react';

export default function () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
    >
      <g fill="#FFF" fillRule="evenodd">
        <path d="M5.15 0h1.9v12h-1.9z" />
        <path d="M11.8 5v2H.4V5z" />
      </g>
    </svg>
  );
}
