import React from 'react';

export default function ({ style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      style={style}
    >
      <path
        fill="#ABDDF9"
        fillRule="nonzero"
        d="M9 0C4.037 0 0 4.037 0 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9zm0 1.5c4.151 0 7.5 3.349 7.5 7.5s-3.349 7.5-7.5 7.5A7.488 7.488 0 0 1 1.5 9c0-4.151 3.349-7.5 7.5-7.5zm0 3c-1.65 0-3 1.35-3 3h1.5C7.5 6.662 8.162 6 9 6s1.5.662 1.5 1.5c0 .574-.37 1.084-.914 1.266l-.305.093a1.516 1.516 0 0 0-1.031 1.43v.961h1.5v-.96l.305-.095A2.855 2.855 0 0 0 12 7.5c0-1.65-1.35-3-3-3zM8.25 12v1.5h1.5V12h-1.5z"
      />
    </svg>
  );
}
