import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';

import { ReportQuerySettings } from './selectors/getReportsFetchUrl/types';
import { useReportsFetchUrlRemote } from './useReportsFetchUrlRemote';
import { useSearchSelectorWithParams } from './useSearchSelectorWithParams';

export function useReportsFetchUrl(
  settings: ReportQuerySettings,
  baseUrl: string,
  enabled?: boolean,
) {
  const isSBLEnabled = featureFlags.isFeatureEnabled(
    FeatureFlag.SearchBeyondLimits,
  );
  const isUrlResolutionEnabled = enabled === undefined || enabled === true;

  const locallyResolvedUrl = useSearchSelectorWithParams(
    'selectReportsFetchUrl',
    [settings, baseUrl],
    null,
    !isSBLEnabled && isUrlResolutionEnabled,
  );

  const remotelyResolvedUrl = useReportsFetchUrlRemote(
    settings,
    baseUrl,
    isSBLEnabled && isUrlResolutionEnabled,
  );

  const url = isSBLEnabled ? remotelyResolvedUrl : locallyResolvedUrl;

  return isUrlResolutionEnabled ? url : null;
}
