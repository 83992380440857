import { memoizeWithArgs } from 'proxy-memoize';
import { createSelector } from 'reselect';

import { Project, TaskMeta } from '@float/types';

import { sortByName } from '../lib/itemSort';
import { isTaskMetaUserCreated } from '../lib/scheduleUtils';
import { ReduxState, ReduxStateStrict } from '../reducers/lib/types';

export type TaskMetaOption = ReturnType<typeof getTaskMetasOptions>[0];

export function toMetaOption(meta: TaskMeta) {
  return {
    billable: meta.billable,
    budget: meta.budget,
    max_integration_status: meta.max_integration_status,
    min_integration_status: meta.min_integration_status,
    count_logged_time: meta.count_logged_time,
    count_tasks: meta.count_tasks,
    phase_id: meta.phase_id,
    project_id: meta.project_id,
    task_meta_id: meta.task_meta_id,
    task_name: meta.task_name,
  };
}

export const getProjectTaskMetas = createSelector(
  [
    (state: ReduxStateStrict) => state.taskMetas.taskMetas,
    (state: ReduxStateStrict, projectId?: Project['project_id']) => projectId,
  ],
  (taskMetasByProjectId, projectId) => {
    if (!projectId) return [];

    return taskMetasByProjectId[projectId];
  },
);

export const getTaskMetasOptions = memoizeWithArgs(
  (
    taskMetas: ReduxState['taskMetas']['taskMetas'],
    projectId: number | undefined,
    phaseId: number | string | undefined,
    originalTaskUnmodifiedName: string | null,
  ) => {
    const taskMetasByProject = taskMetas[projectId!] || [];

    const normalizedPhaseId = Number(phaseId || 0);

    return sortByName(
      taskMetasByProject
        .filter((taskMeta) => Number(taskMeta.phase_id) === normalizedPhaseId)
        .filter(
          (taskMeta) =>
            isTaskMetaUserCreated(taskMeta) ||
            taskMeta.task_name === originalTaskUnmodifiedName,
        ),
      'task_name',
    ).map(toMetaOption);
  },
);

export const getTaskMetasLoadStateByProject = (
  state: ReduxState,
  projectId: number,
) => state.taskMetas.loadState[projectId];
