import { getUser } from '@float/common/selectors/currentUser';
import { getPeopleMap } from '@float/common/selectors/people';
import { useAppSelectorStrict } from '@float/common/store';
import { CurrentUser } from '@float/types/account';
import { Timeoff } from '@float/types/timeoff';

import { isRequestMode } from '../../timeoffRequest';

/**
 * This hook is used to determine if the time off entry should be treated as a
 * "request" or "planned" time off when showing the time off balance.
 *
 * "planned" or "requested" is used based on the time off approval state and the
 * role of the person allocating the time off. Only members need to request time
 * off.
 *
 * @param {Timeoff | null} timeoff - The time off entry to check.
 * @param {boolean} [isReadOnly=false] - Whether the current context is
 * read-only.
 * @param {boolean} [isApproveRejectMode=false] - Whether the approve/reject
 * mode is enabled (useful in mobile).
 * @returns {boolean} - Returns `true` if the time off should be considered as
 * requested, otherwise `false`.
 */

export const useIsTimeoffRequest = (
  timeoff: Timeoff | null,
  isReadOnly: boolean = false,
  isApproveRejectMode: boolean = false,
) => {
  const user: CurrentUser = useAppSelectorStrict(getUser);
  const peopleMap = useAppSelectorStrict(getPeopleMap);
  const isTimeoffMode = true; // always true because this hook is only used within time off

  if (!timeoff || !user.timeoff_approvals) return false;

  const isRequestedTimeoff = Boolean(
    timeoff.timeoff_id && (timeoff.status_creator_id || timeoff.status_request),
  );

  const isRequestingTimeoff = isRequestMode(
    timeoff,
    user,
    peopleMap,
    isTimeoffMode,
  );

  return (
    isRequestedTimeoff ||
    isApproveRejectMode ||
    (isRequestingTimeoff && !isReadOnly)
  );
};
