import { noop } from '@float/libs/utils/noop';

import API3 from '../../api3';

export const getApiMethod = (verb, noun) =>
  verb + noun.charAt(0).toUpperCase() + noun.slice(1);

const PREFIXED_NAMES = [
  'accessRights',
  'jwt',
  'billingInfo',
  'clients',
  'projects',
  'people',
  'accounts',
  'timeoffTypes',
  'holidays',
  'publicHolidays',
  'myCompanies',
  'accountTypes',
  'invoices',
  'timezones',
  'currencies',
];

export const getMappedPluralName = (name) => {
  if (PREFIXED_NAMES.includes(name)) {
    return `settings${name.charAt(0).toUpperCase()}${name.slice(1)}`;
  }
  return name;
};

export const addEntityCreator =
  (type, singularName, successAction, failureAction) => (data) => {
    return (dispatch, getState) => {
      dispatch({
        type,
      });
      return API3[getApiMethod('create', singularName)]({
        jwt: getState().jwt,
        data,
      })
        .then((json) => dispatch(successAction(json, data)))
        .catch((error) => {
          console.log('error', error);
          dispatch(failureAction(error));
          throw error;
        });
    };
  };

export const addedEntityCreator =
  (
    type,
    extendEntity,
    needsAdditionalCall = false,
    singularName,
    idProp,
    trackingFn = noop,
  ) =>
  (payload, requestData) => {
    if (needsAdditionalCall) {
      return (dispatch, getState) => {
        return API3[getApiMethod('get', singularName)]({
          jwt: getState().jwt,
          id: payload[idProp],
        }).then((fullEntity) => {
          payload = extendEntity
            ? extendEntity(fullEntity, getState())
            : fullEntity;
          return dispatch({ type, payload });
        });
      };
    }
    payload = extendEntity ? extendEntity(payload) : payload;

    trackingFn(payload, requestData);

    return {
      type,
      payload,
    };
  };

export const failedAddingEntityCreator = (type) => (error) => {
  if (error instanceof Response) {
    return (dispatch, getState) => {
      return error.json().then((json) => dispatch({ type, error: json }));
    };
  }
  return {
    type,
    error,
  };
};

export const deleteEntityCreator =
  (type, singularName, pluralName, successAction, failureAction, idProp) =>
  (id) => {
    return (dispatch, getState) => {
      dispatch({
        type,
        id,
      });

      const reduxState = getState();

      return API3[getApiMethod('delete', singularName)]({
        jwt: reduxState.jwt,
        id,
      })
        .then(() => {
          return dispatch(successAction(id));
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(failureAction(id, error));
          throw error;
        });
    };
  };

export const deletedEntityCreator =
  (type, trackingFn = noop) =>
  (id) => {
    trackingFn(id);

    return {
      type,
      id,
    };
  };

export const failedDeletingEntityCreator = (type) => (id, error) => {
  return {
    type,
    id,
    error,
  };
};

export const updateEntityCreator =
  (type, singularName, successAction, failureAction) => (id, data) => {
    return (dispatch, getState) => {
      dispatch({
        type,
        id,
      });
      return (
        API3[getApiMethod('update', singularName)]({
          jwt: getState().jwt,
          id,
          data,
        })
          // In some cases the successAction requires access to the current state
          // to track things like rate changes, so, we're passing a state getter as an additional param
          .then((json) => dispatch(successAction(id, json, data, getState)))
          .catch((error) => {
            console.log('error', error);

            dispatch(failureAction(id, error));
            throw error;
          })
      );
    };
  };

export const updatedEntityCreator =
  (
    type,
    extendEntity,
    needsAdditionalCall = false,
    singularName,
    trackingFn = noop,
  ) =>
  (id, payload, inputData, getState) => {
    if (needsAdditionalCall) {
      return (dispatch, getState) => {
        return API3[getApiMethod('get', singularName)]({
          jwt: getState().jwt,
          id,
        }).then((fullEntity) => {
          fullEntity = {
            ...payload,
            ...fullEntity,
          };
          payload = extendEntity
            ? extendEntity(fullEntity, getState(), payload)
            : fullEntity;
          return dispatch({ type, id, payload, inputData });
        });
      };
    }
    payload = extendEntity ? extendEntity(payload) : payload;

    // We also pass the state getter function to tracking function
    // So the tracking function can know what was the state of the entity before it was updated
    trackingFn(inputData, getState);

    return {
      type,
      id,
      payload,
      inputData,
    };
  };

export const failedUpdatingEntityCreator = (type) => (id, error) => {
  return {
    type,
    id,
    error,
  };
};
