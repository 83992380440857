import React from 'react';
import { t } from '@lingui/macro';

import { ConfirmModal } from 'components/modals/ConfirmModal';

export function UnsavedChangesConfirmModal(props: {
  onConfirm: () => void;
  onCancel: () => void;
  confirmAppearance?: 'primary' | 'secondary' | 'danger';
}) {
  return (
    <ConfirmModal
      cancelLabel={t`Cancel`}
      confirmLabel={t`Discard changes`}
      message={t`Are you sure you want to leave?`}
      onCancel={props.onCancel}
      onConfirm={props.onConfirm}
      title={t`You have unsaved changes`}
      confirmAppearance={props.confirmAppearance || 'primary'}
      center={true}
    />
  );
}
