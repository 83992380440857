import React from 'react';

import { Card } from '@float/ui/deprecated/Earhart/Cards';

import { StyledRow } from '../styles';

type SectionProps = {
  heading: React.ReactNode;
  children: React.ReactNode;
};

export function Section({ heading, children }: SectionProps) {
  return (
    <Card>
      <StyledRow>
        <h4>{heading}</h4>
      </StyledRow>

      {React.Children.toArray(children).map((child, key) => {
        if (React.isValidElement(child) && child.props.noParent) {
          return child;
        }

        return <StyledRow key={key}>{child}</StyledRow>;
      })}
    </Card>
  );
}
