import React from 'react';
import { Controller, FieldPath } from 'react-hook-form';

import { ColorPickerDropdown, ColorTrigger } from '@float/ui/deprecated';

import { ProjectFormData } from '../types';

type ColorFieldProps = {
  'aria-label': string;
  autoFocus?: boolean;
  name: FieldPath<ProjectFormData>;
  className?: string;
  size?: 'sm' | 'lg';
  clientColors?: string[];
  recentColors?: string[];
  defaultColor?: string;
  modal?: boolean;
};

export function ColorField(props: ColorFieldProps) {
  const {
    className,
    name,
    size = 'lg',
    recentColors,
    clientColors,
    defaultColor,
  } = props;
  return (
    <Controller
      name={name}
      render={({ field }) => {
        const resolvedValue = field.value || defaultColor;
        return (
          <ColorPickerDropdown
            value={resolvedValue}
            onChange={field.onChange}
            clientColors={clientColors}
            recentColors={recentColors}
            onOpenChange={(open) => {
              if (!open) {
                field.onBlur();
              }
            }}
            modal={props.modal}
          >
            <ColorTrigger
              aria-label={props['aria-label']}
              autoFocus={props.autoFocus}
              size={size}
              color={resolvedValue}
              className={className}
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
          </ColorPickerDropdown>
        );
      }}
    />
  );
}
