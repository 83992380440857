import { connect } from 'react-redux';
import { compose } from 'redux';

import { updateSecurityPrefs } from '@float/common/actions/companyPrefs';
import { withSnackbar } from '@float/ui/deprecated';

import withOnMount from '../../../decorators/withOnMount';
import DomainsRestrictionSection from './component';

const mapStateToProps = (state) => {
  const hasTimeTracking = state.companyPrefs.time_tracking > 0;
  const hasPlusPack = state.companyPrefs.plus_pack > 0;

  return {
    defaultDomain: state.companyPrefs.default_domain ?? '',
    domains: state.companyPrefs.domains ?? [],
    hasPlusPack,
    hasTimeTracking,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: async (domains) => {
    const formData = new FormData();
    if (domains) {
      formData.append('company[domains]', domains.join(','));
    }
    return dispatch(updateSecurityPrefs(formData));
  },
});

const Component = compose(withOnMount, withSnackbar)(DomainsRestrictionSection);

const StatefulDomainsRestrictionSection = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default StatefulDomainsRestrictionSection;
