import React from 'react';
import { t, Trans } from '@lingui/macro';
import { ModalConfig, useManageModal } from 'modalManager/useManageModal';
import { useSidePanel } from 'sidePanel/useSidePanel';

import { deleteProjectV2 } from '@float/common/actions';
import { useAppDispatch } from '@float/common/store';
import useSnackbar from '@float/ui/deprecated/Snackbar/useSnackbar';
import { IconTrash2 } from '@float/ui/icons/essentials/IconTrash2';
import { useProjectACL } from 'components/panelViews/Project/hooks/useProjectACL';

import { useProject } from './useProject';

import { confirmBodyInner } from '../../styles.css';

type ProjectDeleteProps = {
  projectId: number;
  projectName?: string;
  manageModal: (config: ModalConfig) => void;
  afterDelete: () => void;
};

type ConfirmModalProps = { projectName?: string };

function ConfirmDeleteMessage({ projectName = 'project' }: ConfirmModalProps) {
  return (
    <div className={confirmBodyInner}>
      <p>
        <Trans>
          Deleting <strong>{projectName}</strong> will:
        </Trans>
      </p>
      <ul>
        <li>
          <Trans>
            Permanently delete all allocations, phases, and milestones
          </Trans>
        </li>
      </ul>
      <p>
        <Trans>Deleted projects cannot be resored.</Trans>
      </p>
    </div>
  );
}

export function useProjectDelete() {
  const dispatch = useAppDispatch();

  async function deleteProject(projectId: number, next?: () => void) {
    await dispatch(deleteProjectV2(projectId));
    if (next) next();
  }

  function confirmThenDelete(props: ProjectDeleteProps) {
    const { projectId, projectName, manageModal, afterDelete } = props;

    manageModal({
      visible: true,
      modalType: 'ConfirmModal',
      modalSettings: {
        title: t`Delete project`,
        message: <ConfirmDeleteMessage projectName={projectName} />,
        confirmLabel: t`Delete`,
        confirmAppearance: 'danger',
        onConfirm: () => deleteProject(projectId, afterDelete),
      },
    });
  }

  return { confirmThenDelete };
}

export function useDeleteMenuItem() {
  const project = useProject();
  const projectName = project.project_name;
  const { canDelete } = useProjectACL();
  const { confirmThenDelete } = useProjectDelete();
  const { manageModalFromPanel } = useManageModal();
  const { hideSidePanel } = useSidePanel();
  const { showSnackbar } = useSnackbar();

  function handleDelete() {
    confirmThenDelete({
      projectId: project.project_id,
      projectName: project.project_name,
      manageModal: manageModalFromPanel,
      afterDelete: () => {
        showSnackbar(t`"${projectName}" deleted.`);
        hideSidePanel();
      },
    });
  }

  if (!canDelete || project.active) return undefined;

  return {
    value: t`Delete`,
    icon: IconTrash2,
    onClick: handleDelete,
  };
}
