import React, { Component } from 'react';
import styled from 'styled-components';

import { withSnackbar } from '@float/ui/deprecated';
import slackImage from '@float/web/assets/images/slack_logo.svg';
import OnOffStatus from '@float/web/settingsV2/components/Integrations/OnOffStatus';
import {
  P,
  SubP,
  Subsection,
  SubsectionAction,
  SubsectionContent,
  SubsectionLogo,
  SubsectionMessage,
  SubsectionStatus,
} from '@float/web/settingsV2/components/Integrations/styles';

import SlackButton from './SlackButton';
import { slackConnect } from './slackRedux';

const SlackLogo = styled.img`
  display: block;
  max-width: 118px;
`;

const { protocol, host } = window.location;
const hostSplit = host.split('.');
let hostToUse = host;

hostSplit[0] = 'app';
hostToUse = hostSplit.join('.');

const redirectUri = `${protocol}//${hostToUse}/admin/api?integrate=slack`;

class Slack extends Component {
  constructor() {
    super();
    this.state = {
      channel: null,
      team: null,
    };
  }

  UNSAFE_componentWillMount() {
    const { integrate, code, state } = this.props.location.query;
    if (integrate === 'slack') {
      /*
        The state parameter should be used to avoid forgery attacks
        by passing in a value that's unique to the user you're
        authenticating and checking it when auth completes.
        https://api.slack.com/docs/oauth
      */
      if (code && state == this.props.cid) {
        this.slackRequestOAuth(code, redirectUri);
      }
      this.props.history.replace(window.location.pathname);
    } else {
      this.slackRequestOAuth();
    }
  }

  showNotificationsModal() {
    this.props.manageModal({
      visible: true,
      modalType: 'notificationSettingsModal',
    });
  }

  slackRequestOAuth(code, redirectUri) {
    this.props.slackRequestOAuth(code, redirectUri).then((res) => {
      if (res && ['success', 'error'].includes(res)) {
        this.props.showSnackbar(
          res === 'success'
            ? 'Slack app added.'
            : 'Slack app integration error - try again.',
        );
      }
    });
  }

  render() {
    const { response } = this.props.slack;
    const team = response && response.team_name;
    const channel = response && response.channel;

    return (
      <Subsection>
        <SubsectionLogo>
          <SlackLogo src={slackImage} />
        </SubsectionLogo>
        <SubsectionContent>
          <div style={{ flexDirection: 'column' }}>
            <P>
              Receive a message in a{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://slack.com/"
              >
                Slack
              </a>{' '}
              channel when any activity occurs in Float.
            </P>
            <SubP>
              Alternatively, each team member can use the personal Float app for
              Slack, located in their Float{' '}
              <a href="#" onClick={() => this.showNotificationsModal()}>
                Notifications menu
              </a>
              .
            </SubP>
            {team && (
              <SubsectionMessage>
                <span>
                  Connected to the <strong>{team}</strong> workspace{' '}
                  <strong>{channel}</strong> channel.
                </span>
              </SubsectionMessage>
            )}
          </div>
        </SubsectionContent>
        <SubsectionStatus>
          <OnOffStatus isOn={!this.props.slack.loading && team} />
        </SubsectionStatus>
        <SubsectionAction>
          <SlackButton
            className={this.props.className}
            redirectUri={redirectUri}
            loading={this.props.slack.loading}
            connected={!!team}
          />
        </SubsectionAction>
      </Subsection>
    );
  }
}

export default slackConnect(withSnackbar(Slack));
