import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M5.75 7.25h12.5c.552 0 1-.204 1-.455v-1.59c0-.252-.448-.455-1-.455H5.75c-.552 0-1 .204-1 .455v1.59c0 .252.448.455 1 .455Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M5.75 13.25h12.5c.552 0 1-.204 1-.454v-1.591c0-.252-.448-.455-1-.455H5.75c-.552 0-1 .204-1 .454v1.591c0 .252.448.455 1 .455Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M5.75 19.25h12.5c.552 0 1-.203 1-.454v-1.591c0-.252-.448-.455-1-.455H5.75c-.552 0-1 .203-1 .454v1.591c0 .252.448.455 1 .455Z"
    />
  </BaseIcon>
);
