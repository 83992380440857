import { StyleRule } from '@vanilla-extract/css';
import { css } from 'styled-components';

export const E500 = css`
  box-shadow:
    -16px -15px 36px rgba(165, 159, 190, 0.16),
    2px 9px 18px rgba(9, 30, 66, 0.1),
    0px 0px 1px rgba(9, 30, 66, 0.15);
`;

export const VE_E500: StyleRule = {
  boxShadow:
    '0px 18px 28px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(95, 106, 126, 0.15)',
};
