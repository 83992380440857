import { email as isValidEmail } from '@float/libs/validate';
import {
  areStringsSame,
  containsAnyOf,
  isNdigitOrLess,
  mapImportHeaderToKeys,
  parseRow,
  refineNumericalValue,
} from '@float/web/importCsv/validateImportRow';

const getKeyFromHeader = (key) => {
  const lower = key.toLowerCase().trim();
  if (lower.startsWith('name')) {
    return 'name';
  }

  if (lower.startsWith('email')) {
    return 'email';
  }

  if (lower.startsWith('job title') || lower.startsWith('role')) {
    return 'job_title';
  }

  if (lower.startsWith('department')) {
    return 'department';
  }

  if (lower.startsWith('tags')) {
    return 'tags';
  }

  if (containsAnyOf(lower, ['hourly', 'rate'])) {
    return 'hourly_rate';
  }

  return null;
};

const refineValue = (value, key) => {
  switch (key) {
    case 'tags':
      return value
        .split(',')
        .map((x) => x.trim())
        .filter((y) => y);
    case 'email':
      return value ? value.toLowerCase().trim() : null;
    case 'hourly_rate':
      return refineNumericalValue(value);
    default:
      return value.trim() || null;
  }
};

const isDuplicate = (row, people) => {
  let duplicate = null;
  people.some((x) => {
    if (areStringsSame(row.name, x.name)) {
      duplicate = x.name;
      return true;
    }
    if (row.email && areStringsSame(row.email, x.email)) {
      duplicate = x.email;
      return true;
    }
    return false;
  });
  return duplicate || false;
};

const getRowErrors = (row) => {
  const errors = [];

  if (!row.name) {
    errors.push('Please enter a name');
  }

  if (row.email && !isValidEmail(row.email)) {
    errors.push('Please enter a valid email');
  }

  if (row.hourly_rate && !isNdigitOrLess(row.hourly_rate, 6)) {
    errors.push('Hourly rate must be less than 6 numbers');
  }

  return errors;
};

export const mapPeopleHeaderToKeys = (headers) => {
  return mapImportHeaderToKeys({ headers, getKeyFromHeader });
};

export const validatePeopleRow = (row, { keyMap, people }) => {
  const data = parseRow({ row, keyMap, refineValue });
  const errors = getRowErrors(data);
  const duplicateRef = isDuplicate(data, people);
  return { data, errors, duplicateRef };
};
