import React from 'react';
import { Trans } from '@lingui/macro';

import { InsightsEntry } from '@float/common/reducers/timeRange';
import {
  Tooltip,
  TooltipProps,
  TooltipVariants,
} from '@float/ui/components/Tooltip';

import { convertNumberToLocaleString, getUtilization } from '../helpers';
import {
  InsightBadge,
  InsightBadgeProps,
} from '../subcomponents/InsightBadge/InsightBadge';

import * as styles from './InsightForProjectPlan.css';

type DisplayMetric = 'allocation' | 'utilization';

export type InsightForProjectPlanProps = {
  insight?: InsightsEntry;
  displayMetric: DisplayMetric;
  isForSingleProject?: boolean;
  isLoading?: boolean;
};

export const InsightForProjectPlan = ({
  insight,
  displayMetric,
  isForSingleProject,
  isLoading,
}: InsightForProjectPlanProps) => {
  if (!insight) return null;

  const { h: scheduled, totalCapacity } = insight;

  const utilization = getUtilization({
    scheduled,
    totalCapacity,
  });

  const isUtilizationMetric = displayMetric === 'utilization';
  const isAllocationMetric = displayMetric === 'allocation';
  const hasTotalCapacity = totalCapacity > 0;

  let value;
  let valueUnit;
  let valueText;

  if (isUtilizationMetric && !hasTotalCapacity) {
    value = utilization;
    valueText = '—';
  } else if (isUtilizationMetric && hasTotalCapacity) {
    value = utilization;
    valueText = utilization.toString();
    valueUnit = '%';
  } else if (isAllocationMetric) {
    value = scheduled;
    valueText = convertNumberToLocaleString(value);
    valueUnit = 'h';
  }

  const variant: InsightBadgeProps['state'] = 'neutral';
  const variantTooltip: TooltipProps['variant'] = TooltipVariants.default;

  return (
    <Tooltip
      content={
        <InsightForProjectPlanTooltipContent
          scheduled={scheduled}
          utilization={utilization}
          totalCapacity={totalCapacity}
          displayMetric={displayMetric}
          isForSingleProject={isForSingleProject}
        />
      }
      variant={variantTooltip}
      placement="right"
    >
      <div style={{ display: 'inline-flex' }}>
        <InsightBadge
          state={variant}
          value={valueText}
          unit={valueUnit}
          isLoading={isLoading}
        />
      </div>
    </Tooltip>
  );
};

export type InsightForProjectPlanTooltipContentProps = {
  scheduled: number;
  utilization: number;
  totalCapacity: number;
  displayMetric: DisplayMetric;
  isForSingleProject?: boolean;
};

export const InsightForProjectPlanTooltipContent = ({
  scheduled,
  utilization,
  totalCapacity,
  displayMetric,
  isForSingleProject = false,
}: InsightForProjectPlanTooltipContentProps) => {
  const isUtilizationMetric = displayMetric === 'utilization';
  const isAllocationMetric = displayMetric === 'allocation';
  const hasTotalCapacity = totalCapacity > 0;

  const utilizationLocalizedValue = convertNumberToLocaleString(utilization, {
    style: 'percent',
  });
  const utilizationLabelText = isForSingleProject
    ? 'utilized on this Project'
    : 'utilized';

  const allocationLocalizedValue = convertNumberToLocaleString(scheduled);
  const allocationLabelText = isForSingleProject
    ? 'scheduled on this Project'
    : 'scheduled';

  const utilizationEl = (
    <div>
      <Trans>
        <strong className={styles.tooltipContentBold}>
          {utilizationLocalizedValue}%
        </strong>{' '}
        {utilizationLabelText}
      </Trans>
    </div>
  );

  const scheduledEl = (
    <div>
      <Trans>
        <strong className={styles.tooltipContentBold}>
          {allocationLocalizedValue}h
        </strong>{' '}
        {allocationLabelText}
      </Trans>
    </div>
  );

  const capacityUnavailableEl = (
    <div>
      <Trans>
        <strong className={styles.tooltipContentBold}>0</strong> capacity for
        this date range
      </Trans>
    </div>
  );

  let content;

  if (isUtilizationMetric && !hasTotalCapacity) {
    content = capacityUnavailableEl;
  } else if (isUtilizationMetric && hasTotalCapacity) {
    content = utilizationEl;
  } else if (isAllocationMetric) {
    content = scheduledEl;
  }

  return <div style={{ textAlign: 'left' }}>{content}</div>;
};
