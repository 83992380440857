import React from 'react';

import BaseIcon, { BaseIconProps } from './BaseIcon';

export default (props: BaseIconProps) => (
  <BaseIcon {...props} viewBox="0 0 32 32">
    <path
      d="M25.6666 16C25.6666 21.3388 21.3387 25.6667 16 25.6667C10.6612 25.6667 6.33331 21.3388 6.33331 16C6.33331 10.6613 10.6612 6.33337 16 6.33337C21.3387 6.33337 25.6666 10.6613 25.6666 16Z"
      stroke="#344765"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 13.3334C13 13.3334 13.3333 10.3334 16 10.3334C18.6667 10.3334 19 12 19 13.3334C19 14.3352 18.4355 15.337 17.3064 15.7732C16.6195 16.0386 16 16.597 16 17.3334V17.6667"
      stroke="#344765"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.6666 21.3333C16.6666 21.7015 16.3682 22 16 22C15.6318 22 15.3333 21.7015 15.3333 21.3333C15.3333 20.9651 15.6318 20.6666 16 20.6666C16.3682 20.6666 16.6666 20.9651 16.6666 21.3333Z"
      stroke="#242C39"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </BaseIcon>
);
