import React from 'react';

import { Tag } from '@float/ui/deprecated/Earhart/Tags';

const TagCell = ({ cell }) => {
  const { text, ...rest } = cell;
  return (
    <td>
      <Tag {...rest}>{text}</Tag>
    </td>
  );
};

export default TagCell;
