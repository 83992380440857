export const weekdays = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  '00': 'Sunday',
};

export function parseEmailPrefs(nots) {
  let response = '';
  if (nots.people && nots.people.length) {
    nots.people.forEach((personId) => {
      if (response) {
        response += '&';
      }
      response += `pid[]=${personId}`;
      return response;
    });
  }

  [
    'range',
    'wkly_email',
    'sent_day',
    'auto_email',
    'msg',
    'start',
    'end',
  ].forEach((key) => {
    if (typeof nots[key] !== 'undefined') {
      response += `&${key}=${nots[key]}`;
    }
  });

  response += `&${nots.action}`;
  return response;
}

export const RECURRING_EMAIL = {
  0: 'Never',
  2: 'Daily',
  1: 'Weekly',
};
