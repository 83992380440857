import {
  CurrentUser,
  LoggedTime,
  Person,
  Project,
  ProjectStatus,
  SearchLoggedTime,
  SearchTask,
  SearchTimeoff,
  Task,
  Timeoff,
} from '@float/types';

import { normalize } from '../../../helpers';

type SearchValue = string | null;

export function matchString(
  attribute: SearchValue,
  searchedTerm: string,
  partial: boolean,
) {
  if (searchedTerm === '*') return true;

  const normalized = normalize(attribute);

  if (partial) {
    return normalized.includes(searchedTerm);
  }

  return normalized === searchedTerm;
}

export function matchStringWithoutNormalize(
  attribute: SearchValue,
  searchedTerm: string,
  partial: boolean,
) {
  if (!attribute) return false;
  if (searchedTerm === '*') return true;

  if (partial) {
    return attribute.includes(searchedTerm);
  }

  return attribute === searchedTerm;
}

export const UNNAMED_TASK = 'no task used';

export const matchTaskByName = (
  task: SearchTask | Task,
  value: string,
  partial: boolean,
) => {
  if (value === UNNAMED_TASK) return !task.name;

  return matchString(task.name, value, partial);
};

export const matchLoggedTaskByName = (
  task: LoggedTime | SearchLoggedTime,
  value: string,
  partial: boolean,
) => {
  if (value === UNNAMED_TASK) return !task.task_name;

  return matchString(task.task_name, value, partial);
};

const TASK_STATUSES = {
  billable: 'billable',
  nonBillable: 'non-billable',
  draft: 'draft',
  tentative: 'tentative',
  confirmed: 'confirmed',
  completed: 'completed',
} as const;

const TASK_STATUS_IDS: Record<string, number | undefined> = {
  [TASK_STATUSES.draft]: 0,
  [TASK_STATUSES.tentative]: 1,
  [TASK_STATUSES.confirmed]: 2,
  [TASK_STATUSES.completed]: 3,
};

export const matchTaskByStatus = (task: SearchTask | Task, value: string) => {
  const status = TASK_STATUS_IDS[value];
  const billable = value === TASK_STATUSES.billable;
  const nonBillable = value === TASK_STATUSES.nonBillable;

  if (status !== undefined) {
    if (status === task.status) {
      return true;
    }
  } else if (billable || nonBillable) {
    if (billable === Boolean(task.billable)) {
      return true;
    }
  }

  return false;
};

const TIMEOFF_STATUSES = {
  declined: 'declined',
  tentative: 'tentative',
  approved: 'approved',
  confirmed: 'confirmed',
} as const;

const TIMEOFF_STATUS_IDS: Record<string, number> = {
  [TIMEOFF_STATUSES.declined]: -1,
  [TIMEOFF_STATUSES.tentative]: 1,
  [TIMEOFF_STATUSES.approved]: 2,
  [TIMEOFF_STATUSES.confirmed]: 2,
};

export const matchTimeoffByStatus = (
  timeoff: SearchTimeoff | Timeoff | undefined,
  user: CurrentUser,
  value: string,
) => {
  if (
    !timeoff ||
    timeoff.data_type === 'search' || // Timeoff status filter doesn't work on search data (not enough info)
    !timeoff.timeoff_type_id ||
    timeoff.region_holiday_id
  ) {
    return false;
  }

  const status = TIMEOFF_STATUS_IDS[value];

  if (status !== undefined) {
    if (timeoff.status === status) {
      if (status === 2) {
        if (user.timeoff_approvals) {
          return value === TIMEOFF_STATUSES.approved;
        } else {
          return value === TIMEOFF_STATUSES.confirmed;
        }
      }

      return true;
    }
  }

  return false;
};

const PERSON_TYPES = {
  employee: 'employee',
  contractor: 'contractor',
  placeholder: 'placeholder',
  fullTime: 'full-time',
  partTime: 'part-time',
} as const;

const PERSON_TYPE_IDS: Record<string, number | undefined> = {
  [PERSON_TYPES.employee]: 1,
  [PERSON_TYPES.contractor]: 2,
  [PERSON_TYPES.placeholder]: 3,
};

const EMPLYEE_TYPE_IDS: Record<string, number | undefined> = {
  [PERSON_TYPES.partTime]: 0,
  [PERSON_TYPES.fullTime]: 1,
};

export const matchPersonByType = (person: Person, value: string) => {
  const peopleType = PERSON_TYPE_IDS[value];

  if (peopleType !== undefined) {
    return peopleType === person.people_type_id;
  }

  // We show placeholders only when the value is 'placeholder'
  if (person.people_type_id === PERSON_TYPE_IDS[PERSON_TYPES.placeholder]) {
    return false;
  }

  const employeeType = EMPLYEE_TYPE_IDS[value];

  if (employeeType !== undefined) {
    return employeeType === person.employee_type;
  }

  return false;
};

const PROJECT_STATUSES = {
  billable: 'billable',
  nonBillable: 'non-billable',
  tentative: 'tentative',
  scheduled: 'scheduled',
  confirmed: 'confirmed',
  draft: 'draft',
  active: 'active',
  archived: 'archived',
  mine: 'mine',
} as const;

export const matchProjectByStatus = (
  project: Project,
  user: CurrentUser,
  value: string,
) => {
  switch (value) {
    case PROJECT_STATUSES.nonBillable:
      return project.non_billable;
    case PROJECT_STATUSES.billable:
      return !project.non_billable;
    case PROJECT_STATUSES.tentative:
      return project.status === ProjectStatus.Tentative;
    case PROJECT_STATUSES.scheduled:
    case PROJECT_STATUSES.confirmed:
      return project.status === ProjectStatus.Confirmed;
    case PROJECT_STATUSES.draft:
      return project.status === ProjectStatus.Draft;
    case PROJECT_STATUSES.active:
      return project.active;
    case PROJECT_STATUSES.archived:
      return !project.active;
    case PROJECT_STATUSES.mine:
      return project.project_manager === user.account_id;
  }

  return false;
};
