import styled from 'styled-components';

import { Button } from '../../Buttons';
import * as Typography from '../../Typography';
import List from './List';

export const StyledContainer = styled.div`
  ${Typography.Label14.SB600};

  position: relative;
`;

export const StyledButton = styled(Button)`
  width: 100%;

  padding: 10px;

  justify-content: space-between;
`;

export const StyledList = styled(List)`
  position: absolute;

  top: 100%;
  left: 0;
`;
