import { getTimeBasedWorkHours } from '@float/common/serena/util/workHours';
import { getWeekDay } from '@float/libs/dates/getWeekDay';
import { CompanyPreferences } from '@float/types/companyPreferences';

export function getCompanyWorkHours(
  dates: DatesManager,
  company: CompanyPreferences,
  date: string,
) {
  const hours = getTimeBasedWorkHours(date, null, company);

  return hours[getWeekDay(dates, date)];
}
