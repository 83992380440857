import React from 'react';

import * as styles from '@float/ui/primitives/QuickInput/QuickInput.css';

import { QuickInputSpacingVariants } from '../QuickInput.types';

export type PopoutBorderProps = {
  variant: QuickInputSpacingVariants;
};

/**
 * Used to implement the "pop-out" effect when a "compact" Input is focused
 * and the border expands and covers surrounding content but doesn't change
 * the layout.
 */
export const PopoutBorder: React.FC<PopoutBorderProps> = ({ variant }) => (
  <div className={`${styles.popoutBorderWrapper}`}>
    <div
      className={`${styles.popoutBorder} ${styles.popoutBorderVariants[variant]}`}
    />
  </div>
);
