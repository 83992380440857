import React from 'react';
import { Trans } from '@lingui/macro';
import { isFunction } from 'lodash';

import { normalizeTime } from '@float/common/lib/time';
import { InsightsEntry } from '@float/common/reducers/timeRange';
import { TimeFormatValue } from '@float/types';
import { Tooltip } from '@float/ui/components/Tooltip';

import { convertNumberToLocaleString } from '../helpers';
import { InsightBadge } from '../subcomponents/InsightBadge/InsightBadge';

import * as styles from './InsightForLogTeam.css';

export type InsightForLogTeamProps = {
  insight?: InsightsEntry;
  hoursDisplayFormat: TimeFormatValue;
  isLoading: boolean;
  onClick?: () => void;
};

export const InsightForLogTeam = ({
  insight,
  hoursDisplayFormat,
  isLoading,
  onClick,
}: InsightForLogTeamProps) => {
  if (!insight) return null;

  const variant = insight.h > 0 ? 'info' : 'neutral';

  const value = normalizeTime(insight.h || insight.logged, hoursDisplayFormat);
  const valueText =
    typeof value === 'number' ? convertNumberToLocaleString(value) : value;

  const hasTimeLogged = insight.logged > 0;
  const hasTimeToLog = insight.h > 0;

  const hasTooltip = !isLoading && (hasTimeLogged || hasTimeToLog);

  const unit = hoursDisplayFormat === 'decimal' ? 'h' : '';

  const isInteractive = !isLoading && hasTimeToLog && isFunction(onClick);

  const onInsightBadgeClick = isInteractive ? onClick : undefined;

  return (
    <Tooltip
      open={hasTooltip ? undefined : false}
      content={
        <InsightForLogTeamTooltipContent
          logged={insight.logged}
          toLog={insight.h}
          hoursDisplayFormat={hoursDisplayFormat}
        />
      }
      placement="right"
    >
      <div style={{ display: 'inline-flex' }}>
        <InsightBadge
          state={variant}
          value={valueText}
          unit={unit ? unit : undefined}
          isLoading={isLoading}
          onClick={onInsightBadgeClick}
        />
      </div>
    </Tooltip>
  );
};

export type InsightForLogTeamTooltipContentProps = {
  logged: number;
  toLog: number;
  hoursDisplayFormat: TimeFormatValue;
};

export const InsightForLogTeamTooltipContent = ({
  logged,
  toLog,
  hoursDisplayFormat,
}: InsightForLogTeamTooltipContentProps) => {
  const valueLogged = normalizeTime(logged, hoursDisplayFormat);
  const valueToLog = normalizeTime(toLog, hoursDisplayFormat);

  const valueLoggedText =
    typeof valueLogged === 'number'
      ? convertNumberToLocaleString(valueLogged)
      : valueLogged;

  const valueToLogText =
    typeof valueToLog === 'number'
      ? convertNumberToLocaleString(valueToLog)
      : valueToLog;

  const unit = hoursDisplayFormat === 'decimal' ? 'h' : '';

  return (
    <div className={styles.tooltipContentBody}>
      {toLog > 0 && (
        <span className={styles.tooltipContentEmphasized}>
          <strong className={styles.tooltipContentBold}>
            <Trans>
              Log {valueToLogText}
              {unit}
            </Trans>
          </strong>
        </span>
      )}
      {toLog > 0 && logged > 0 && (
        <div className={styles.tooltipContentSeparator} />
      )}
      {logged > 0 && (
        <span>
          <Trans>
            <strong className={styles.tooltipContentBold}>
              {valueLoggedText}
              {unit}
            </strong>{' '}
            logged
          </Trans>
        </span>
      )}
    </div>
  );
};
