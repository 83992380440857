import React from 'react';

import { Tag } from '@float/ui/components/Tag';

import * as styles from './styles.css';

export type StatusTagProps = {
  className?: string;
  children: React.ReactNode;
};

export function StatusTag(props: StatusTagProps) {
  return (
    <Tag className={props.className}>
      <div className={styles.statusCircle} />
      {props.children}
    </Tag>
  );
}
