import { LoggedTimeCell } from '@float/types';

import { CellFns } from '../types';

export function sortLogTimePerTypeAndPriority(
  cell: LoggedTimeCell,
  getPriority: CellFns['getPriority'],
  collator: Intl.Collator,
) {
  const { rowId } = cell;

  cell.items.sort((aWrapper, bWrapper) => {
    // Put full-day items at the end
    if (!aWrapper.h && bWrapper.h) return 1;
    if (aWrapper.h && !bWrapper.h) return -1;
    if (!aWrapper.h && !bWrapper.h) return 0;

    const a: any = aWrapper.entity;
    const b: any = bWrapper.entity;

    // Timeoffs go to the bottom
    if (a.logged_time_id && b.timeoff_id) return -1;
    if (b.logged_time_id && a.timeoff_id) return 1;
    // LoggedTimes go above TaskReferences
    if (a.logged_time_id && b.logged_time_id) {
      if (a.isTaskReference && !b.isTaskReference) return 1;
      if (!a.isTaskReference && b.isTaskReference) return -1;
    }
    // Sort tasks/timeoffs with start time below ones that don't
    if (a.start_time && !b.start_time) {
      return 1;
    }
    if (!a.start_time && b.start_time) {
      return -1;
    }
    if (a.start_time && b.start_time) {
      return a.start_time.localeCompare(b.start_time);
    }
    // If one of the items has a lower value for priority, move it up
    const aPriority = getPriority(rowId, a);
    const bPriority = getPriority(rowId, b);
    if (aPriority !== bPriority) return aPriority - bPriority;
    // Default to creation date if everything else is the same
    return collator.compare(a.created, b.created);
  });
}
