import React from 'react';

import AvatarEditor from './AvatarEditor';
import AvatarImage from './AvatarImage';
import HiddenFileInput from './HiddenFileInput';
import {
  AvatarEditLink,
  AvatarEditLinkContainer,
  StyledAvatar,
} from './styles';

class Avatar extends React.Component {
  state = {
    isEditorOpen: false,
    imageUrl: null,
    newAvatarFile: null,
  };

  static defaultProps = {
    size: 'lg',
    tooltip: true,
  };

  doesAvatarExist = () => {
    return !!(this.props.imageUrl || this.state.imageUrl);
  };

  openEditor = () => {
    this.setState({ isEditorOpen: true });
  };

  closeEditor = () => {
    this.setState({ isEditorOpen: false, newAvatarFile: null });
  };

  onFileSelect = (newAvatarFile) => {
    this.setState({ newAvatarFile, isEditorOpen: true });
  };

  onChange = async (image) => {
    const { onChange } = this.props;
    if (typeof onChange === 'function') {
      await onChange(image);
      return;
    }

    this.setState({ imageUrl: image.dataUrl, isEditorOpen: false });
  };

  getAvatarImageProps = () => {
    const { name, size, style, tooltip, disabled, isNewPlaceholderType } =
      this.props;
    let { imageUrl } = this.props;
    if (typeof onChange !== 'function') {
      // uncontrolled component
      imageUrl = this.state.imageUrl || imageUrl;
    }
    return {
      name,
      imageUrl,
      size,
      style,
      tooltip,
      disabled,
      isNewPlaceholderType,
    };
  };

  render() {
    const avatarImageProps = this.getAvatarImageProps();
    if (this.props.readOnly) {
      return <AvatarImage {...avatarImageProps} />;
    }

    const avatarExists = this.doesAvatarExist();
    const { style, ...avatarImagePropsExceptStyle } = avatarImageProps;

    return (
      <>
        <AvatarEditLinkContainer
          style={style}
          size={avatarImageProps.size}
          onClick={avatarExists ? this.openEditor : undefined}
        >
          <AvatarImage {...avatarImagePropsExceptStyle} />
          <AvatarEditLink
            title={avatarImageProps.tooltip ? avatarImageProps.name : ''}
          >
            <span>{this.doesAvatarExist() ? 'Change' : 'Add photo'}</span>
            {!avatarExists && (
              <HiddenFileInput
                accept="image/png, image/jpg, image/jpeg"
                onChange={this.onFileSelect}
              />
            )}
          </AvatarEditLink>
        </AvatarEditLinkContainer>

        {this.state.isEditorOpen && (
          <AvatarEditor
            {...avatarImageProps}
            newAvatarFile={this.state.newAvatarFile}
            onSave={this.onChange}
            onClose={this.closeEditor}
          />
        )}
      </>
    );
  }
}

Avatar._styles = { StyledAvatar };

export default Avatar;
