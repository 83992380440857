import { trackOnboardingPrompt } from 'OnboardingManager/helpers/analytics';

import { PROMPTS } from '@float/constants/prompts';
import { CellChange, LoadDataType } from '@float/types/cells';

export const trackFirstTaskDuringOnboarding = (
  change: CellChange<LoadDataType>,
) => {
  if (change.type === 'task' && change.isFirstTask) {
    const action = change.isCreate ? 'created' : 'updated';

    trackOnboardingPrompt(
      PROMPTS.addFirstTask,
      { action, task_id: change.id },
      2,
    );
  }
};
