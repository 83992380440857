import React from 'react';

import { StyledBackground, StyledCompanyCard, StyledContent } from './styles';
import IconUserPlus from './svg/IconUserPlus';

function createNewTeam(params: { name: string; email: string }) {
  window.location.href = `/join?email=${params.email}&name=${params.name}`;
}

const AddCompanyCard = (props: { name: string; email: string }) => {
  return (
    <StyledCompanyCard className="add" onClick={() => createNewTeam(props)}>
      <StyledBackground />

      <StyledContent>
        <IconUserPlus />
        <h2>Create new team</h2>
      </StyledContent>
    </StyledCompanyCard>
  );
};

export default AddCompanyCard;
