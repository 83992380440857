import React from 'react';
import { Trans } from '@lingui/macro';

import { FiltersValidationError } from './validateFilters';

import * as styles from './styles.css';

export function FiltersValidationErrorMessage(props: {
  error: FiltersValidationError;
}) {
  const href =
    'https://support.float.com/en/articles/8282472-views#h_6aa9dc04d5';

  return (
    <>
      {props.error === 'no-or-between-different-types' ? (
        <Trans>Cannot save View with OR between different filter types</Trans>
      ) : (
        <Trans>
          Cannot save View with OR between different filter operators (is and is
          not)
        </Trans>
      )}
      <a
        className={styles.snackbarLink}
        href={href}
        target="_blank"
        rel="noreferrer"
      >
        <Trans>Learn more</Trans>
      </a>
    </>
  );
}
