import { useFormContext } from 'react-hook-form';
import { t } from '@lingui/macro';
import { useManageModal } from 'modalManager/useManageModal';

import { IconCopy } from '@float/ui/icons/essentials/IconCopy';

import { ProjectFormData } from '../../../../types';
import { useProject } from './useProject';

export function useDuplicateMenuItem() {
  const project = useProject();
  const { manageModalFromPanel } = useManageModal();
  const { trigger } = useFormContext<ProjectFormData>();

  async function handleDuplicate() {
    const isFormValid = await trigger();
    if (!isFormValid) return;

    manageModalFromPanel({
      visible: true,
      modalType: 'projectModal',
      modalSettings: {
        isAdding: true,
        duplicating: true,
        project,
      },
    });
  }

  return {
    value: t`Duplicate`,
    icon: IconCopy,
    onClick: handleDuplicate,
  };
}
