import React from 'react';

import { Card } from '@float/ui/deprecated/Earhart/Cards';
import { TextToggle } from '@float/ui/deprecated/Earhart/Toggles';

import { StyledRow } from '../styles';

const signInWithGoogleOptions = [
  { value: false, label: 'Not required' },
  { value: true, label: 'Required' },
];

const SignInWithGoogleRequiredSection = (props) => {
  const isEnabled = props.hasTimeTracking || props.hasPlusPack;

  return (
    <Card>
      <StyledRow>
        <h4>Sign in with Google</h4>
      </StyledRow>

      <StyledRow>
        <p>
          Require your team to sign in using a Google account. If not required,
          users can sign in with their Float credentials or their Google
          account.
        </p>
      </StyledRow>

      <StyledRow direction="row">
        <TextToggle
          options={signInWithGoogleOptions}
          value={props.signInWithGoogleRequired}
          disabled={!isEnabled}
          onChange={({ value }) => {
            props.onSubmit(value).then(() => {
              const state = value ? 'enabled' : 'disabled';

              props.showSnackbar(`Sign in with Google ${state}.`);
            });
          }}
        />
      </StyledRow>
    </Card>
  );
};

export default SignInWithGoogleRequiredSection;
