import { config } from '@float/libs/config';

import { formatToDbString as _formatToDbString } from '../normalizer';
import { getNumberSeparators } from './getNumberSeparators';

export const formatToDbString = (
  amount: number | string | null | undefined,
) => {
  const { decimal, thousand, minusSign } = getNumberSeparators(config.locale);
  return _formatToDbString(decimal, thousand, minusSign, amount);
};
