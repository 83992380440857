import React from 'react';
import styled from 'styled-components';

const ProgressBarContainer = styled.div`
  height: 10px;
  width: 160px;
  background-color: ${(p) => p.theme.grey};
  border-radius: 5px;
  position: relative;
  overflow: hidden;
`;

const ProgressIndicator = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: ${(p) => p.value}%;
  background-color: ${(p) => p.theme.blue};
  transition: width 0.25s ease-in-out;
  will-change: width;
`;

class ProgressBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: props.autotune ? 0 : props.value };
    this.step = 5;
  }

  UNSAFE_componentWillMount() {
    if (this.props.autotune) {
      this.beginProgress(this.props.value);
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.value > this.state.value) {
      this.onProgress(nextProps.value);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }

  beginProgress(progress = this.step) {
    this.onProgress(progress);
    if (!this.timer) {
      this.timer = setInterval(this.onProgress, 250);
    }
  }

  onProgress = (value) => {
    if (!value) {
      // Auto incrementing progress => meaning this is a simulated loading indicator and not actually tracking xhr.onProgress.
      // So we gradually take it up to 80% and then wait for request to complete for the final flourish.
      value = this.state.value + this.step;
      if (value > 90) {
        value = 90;
      }
    }

    if (value >= 100) {
      this.finishProgress();
      return;
    }

    this.setState({ value });
  };

  finishProgress() {
    clearInterval(this.timer);
    this.timer = null;
    this.setState({ value: 100 });
  }

  render() {
    return (
      <ProgressBarContainer style={this.props.style}>
        <ProgressIndicator value={this.state.value} />
      </ProgressBarContainer>
    );
  }
}

ProgressBar._styles = {
  ProgressBarContainer,
  ProgressIndicator,
};

ProgressBar.defaultProps = {
  style: {},
  autotune: true,
};

export default ProgressBar;
