import styled, { css } from 'styled-components';

import { Button, EH } from '@float/ui/deprecated';

export const StyledRow = styled.div`
  display: flex;
  flex-direction: ${({
    direction,
  }: {
    direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  }) => (direction ? direction : 'column')};
  color: ${EH.Colors.FIN.Lt.Emphasis.High};

  &:not(:first-child) {
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: ${EH.Colors.FIN.Lt.Stroke.Stroke2};
  }

  & + & {
    margin-top: 24px;
  }

  h4 {
    ${EH.Typography.TextXXL.SB600};
  }

  a {
    ${EH.Typography.TextM.SB600};

    color: ${EH.Colors.FIN.Lt.Buttons.Primary.Default.Bg};

    &:hover {
      color: ${EH.Colors.FIN.Lt.Buttons.Primary.Hover.Bg};
    }
  }
`;

export const Hr = styled.hr`
  margin-bottom: 25px;
`;

export const ZapierLogo = styled.img`
  display: block;
  max-width: 92px;
`;

export const Subsection = styled.div<{ noParent?: boolean }>`
  flex: 1;

  display: flex;
  align-items: flex-start;

  padding-top: 18px;

  &,
  & * {
    box-sizing: border-box;
  }
`;

export const SubsectionLogo = styled.div`
  display: flex;
  align-items: flex-start;

  width: 23.3%;
  max-width: 233px;
  padding-right: 20px;

  img {
    width: 100%;
  }
`;

export const SubsectionContent = styled.div`
  flex: 1;
  margin-right: 30px;

  p {
    max-width: 480px;
  }
`;

export const SubsectionStatus = styled.div`
  display: flex;
  align-items: center;

  min-width: 40px;
  min-height: 42px;

  margin: 0 8px;
`;

export const SubsectionAction = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100px;
  justify-content: flex-start;
  align-items: flex-end;

  // TS cannot infer StyledButton type from ForwardRefExoticComponent
  ${(Button as any).Styled} {
    & + ${(Button as any).Styled} {
      border: 4px solid hotpink !important;
      margin-top: 8px;
    }
  }
`;

export const SubheadingPS = styled.p<{ greyed: boolean }>`
  margin-left: 10px;
  color: ${(p) => (p.greyed ? '#aaa' : '#363d46')};
  font-size: 16px;
`;

export const SubheadingPSNote = styled(SubheadingPS)`
  font-size: 14px;
`;

export const SubheadingPSWarning = styled(SubheadingPS)`
  color: #e56f00;
  font-size: 14px;
`;

export const Text = styled.p`
  color: #363d46;
  font-size: 14px;
`;

export const DetailList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 218px;
  overflow: auto;
  padding-top: 16px;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
`;

export const DetailListItem = styled.div`
  display: flex;
  padding-bottom: 16px;
  padding-left: 34px;
`;

export const ListItemP = styled.p`
  color: #575f65;
  font-size: 14px;
  width: 45%;
  margin-right: 5%;
`;

export const ListItemPWarning = styled(ListItemP)`
  color: #e56f00;
`;

export const P = styled.p`
  ${EH.Typography.TextM.R400};

  color: ${EH.Colors.FIN.Lt.Emphasis.Medium};
`;

export const SubP = styled.p`
  ${EH.Typography.TextS.R400};

  display: block;

  color: ${EH.Colors.FIN.Lt.Emphasis.Low};

  padding-top: 9px;

  a {
    ${EH.Typography.TextS.SB600};
  }
`;

export const SubsectionMessage = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;

  min-height: 40px;

  color: ${EH.Colors.Primary.Flue.Light[11]};
  background-color: ${EH.Colors.Primary.Flue.Light[4]};

  border-radius: 6px;

  padding: 8px 18px;

  margin-top: 16px;

  ${(p: { isError: boolean }) =>
    p.isError &&
    css`
      color: ${EH.Colors.Secondary.Red.Light[11]};
      background-color: ${EH.Colors.Secondary.Red.Light[4]};
    `};

  &,
  span,
  ${SubP} {
    ${EH.Typography.Label12.M500};

    .text-grey {
      color: ${(p) => p.theme.blueGrey};
    }
  }

  span,
  ${SubP} {
    padding: 0;
  }
`;

export const ApiKeyLogoContainer = styled.div`
  ${EH.Typography.Label12.SB600};

  display: flex;
  align-items: center;

  border-radius: 5px;

  color: ${EH.Colors.FIN.Lt.Emphasis.Medium};
  background-color: ${EH.Colors.FIN.Lt.Surface.Surf4};

  padding: 8px 8px 8px 5px;

  svg {
    --svg-icon-color: ${EH.Colors.FIN.Lt.Emphasis.Medium};

    margin-right: 2px;
  }
`;
