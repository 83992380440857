import React from 'react';

export default function ({ size = 24 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="12" cy="12" r="10" fill="#868D92" />
        <circle cx="12" cy="12" r="4" fill="#FFF" />
      </g>
    </svg>
  );
}
