import { parseBudgetString } from '@float/common/lib/budget/helpers/parseBugdetString';
import {
  Phase,
  PhaseWithTemplateFields,
  ProjectPhaseRecord,
  ProjectStatus,
} from '@float/types';

import { determineEntityStatus } from '../lib/determineEntityStatus';
import type {
  PhaseApiPayload,
  PhaseInputDataWithTeam,
  PhaseTeam,
} from './phases';

function isEmpty<V extends Record<any, unknown>>(
  value: V | Record<string, never>,
): value is Record<string, never> {
  return Object.keys(value).length === 0;
}

export const normalizePhaseTeam = (value: PhaseTeam): PhaseTeam => {
  if (value && !Array.isArray(value) && isEmpty(value)) {
    return { set: [] };
  }
  return value;
};

export const mapPhaseToV3 = (
  newPhase: PhaseInputDataWithTeam,
  { isResize }: { isResize?: boolean } = {},
): Partial<PhaseApiPayload> => {
  if (isResize) {
    return {
      phase_id: newPhase.phase_id,
      start_date: newPhase.start_date,
      end_date: newPhase.end_date,
    };
  }

  return {
    phase_id: newPhase.phase_id,
    project_id: newPhase.project_id,
    color: newPhase.color,
    name: newPhase.phase_name,
    notes: newPhase.notes,
    notes_meta: newPhase.notes_meta,
    active: newPhase.active ? 1 : 0,
    non_billable: newPhase.non_billable ? 1 : 0,
    status: newPhase.status,
    tentative: newPhase.status === ProjectStatus.Tentative ? 1 : 0,
    phase_team: normalizePhaseTeam(newPhase.phase_team),
    budget_type: newPhase.budget_type,
    budget_total: newPhase.budget_total,
    default_hourly_rate: newPhase.default_hourly_rate,
    start_date: newPhase.start_date,
    end_date: newPhase.end_date,
  };
};

export function mapPhaseToPhaseRecord({
  phase_id,
  phase_name,
  color,
  start_date,
  end_date,
  budget_total,
  active,
  duration,
  parent_offset,
  template_phase_id,
  non_billable,
}: Phase): ProjectPhaseRecord {
  return {
    phase_id,
    phase_name,
    color,
    start_date,
    end_date,
    budget_total: parseBudgetString(budget_total),
    active: Boolean(active),
    duration,
    parent_offset,
    template_phase_id,
    non_billable,
  };
}

export function mapTemplatePhaseToPhaseRecord(data: PhaseWithTemplateFields) {
  const result: ProjectPhaseRecord = {
    phase_id: data.phase_id,
    phase_name: data.phase_name,
    color: data.color ?? null,
    start_date: '',
    end_date: '',
    non_billable: Boolean(data.non_billable),
    active: true,
    budget_total: parseBudgetString(data.budget_total),
    duration: data.duration,
    parent_offset: data.parent_offset,
    template_phase_id: data.template_phase_id,
  };

  result.fullPhaseData = {
    phase: {
      ...result,
      phase_id: data.phase_id,
      status: determineEntityStatus(data),
      default_hourly_rate: parseBudgetString(data.default_hourly_rate),
      notes: data.notes ?? '',
      notes_meta: [],
      project_id: 0,
    },
    team: [],
    tasks: [],
    milestones: [],
  };

  if (data.team_people?.length) {
    result.fullPhaseData.team = data.team_people.map((p) => ({
      people_id: p.id,
      hourly_rate: p.hourly_rate,
    }));
  }

  if (data.task_metas?.length) {
    result.fullPhaseData.tasks = data.task_metas.map((t) => ({
      ...t,
      budget: t.budget ?? null,
      count_logged_time: 0,
      count_tasks: 0,
    }));
  }

  if (data.milestones?.length) {
    result.fullPhaseData.milestones = data.milestones.map((m) => ({
      ...m,
      milestone_id: +m.milestone_id,
      date: '',
      end_date: '',
    }));
  }

  return result;
}
