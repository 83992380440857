import React, { useState } from 'react';
import { noop } from 'lodash';

import { addRole } from '@float/common/actions';
import { useAppDispatchStrict } from '@float/common/store';

import AddEntry from '../../Table/AddEntry';

export const AddRole = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatchStrict();

  return (
    <AddEntry
      placeholder="Add a Role"
      maxLength={255}
      isLoading={isLoading}
      onSubmit={async (name) => {
        try {
          setIsLoading(true);
          await dispatch(
            addRole({ name }, { from: 'roles-management', trackEvent: true }),
          );
          setIsLoading(false);
        } catch {
          setIsLoading(false);
          throw new Error(`Error: "${name}" already exists.`);
        }
      }}
      showSnackbar={noop} // overwritten by withSnackbar
    />
  );
};
