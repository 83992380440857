import { AGClientSocket } from 'socketcluster-client';
import { EventObject, fromCallback, fromPromise } from 'xstate';

import { convertResolveResultsToSearchResults } from '../../api/helpers/convertResolveResultsToSearchResults';
import {
  resolveApi,
  SearchResolveParams,
  SearchResolveResult,
} from '../../api/resolveApi';

export const searchResolveApiLogic = fromPromise<
  SearchResolveResult,
  SearchResolveParams | null
>(({ input }) => {
  if (input === null) throw new Error("Shouldn't enter with a null value");

  return resolveApi(input);
});

export enum ContextEventTypes {
  Expired = 'com.float.search.context-expiry',
  Updated = 'com.float.search.context-update',
}

export type ContextSubscribeOutEvents =
  | { type: 'searchResultsUpdated'; payload: SearchResolveResult['result'] }
  | { type: 'searchContextExpired' };

export const contextSubscribeLogic = fromCallback<
  EventObject,
  { socket: AGClientSocket; contextId: string | null },
  ContextSubscribeOutEvents
>(({ input, sendBack }) => {
  const { socket, contextId } = input;

  if (!contextId) return;

  async function subscribe(contextId: string) {
    // Subscribe to the channel with the context ID being the channel name
    for await (const data of socket.subscribe(contextId)) {
      if (data.type === ContextEventTypes.Expired) {
        sendBack({
          type: 'searchContextExpired',
        });
      } else if (data.type === ContextEventTypes.Updated) {
        sendBack({
          type: 'searchResultsUpdated',
          payload: convertResolveResultsToSearchResults(data.data.result),
        });
      }
    }
  }

  subscribe(contextId);

  return () => {
    socket.unsubscribe(contextId);
  };
});
