import React from 'react';

export default function ({ size = 24 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
    >
      <circle
        cx="482"
        cy="320"
        r="11.5"
        fill="none"
        fillRule="evenodd"
        stroke="#C8C8C8"
        transform="translate(-470 -308)"
      />
    </svg>
  );
}
