import API3 from '../api3';
import { trackEvent } from '../lib/analytics';

export const FETCH_LOCK_LOGGED_TIME_CONFIG = 'FETCH_LOCK_LOGGED_TIME_CONFIG';
export const FETCH_LOCK_LOGGED_TIME_CONFIG_SUCCESS =
  'FETCH_LOCK_LOGGED_TIME_CONFIG_SUCCESS';
export const FETCH_LOCK_LOGGED_TIME_CONFIG_FAILURE =
  'FETCH_LOCK_LOGGED_TIME_CONFIG_FAILURE';
export const FETCH_LOCK_LOGGED_TIME_CONFIG_NOT_FOUND =
  'FETCH_LOCK_LOGGED_TIME_CONFIG_NOT_FOUND';
export const UPSERT_LOCK_LOGGED_TIME_CONFIG = 'UPSERT_LOCK_LOGGED_TIME_CONFIG';
export const UPSERT_LOCK_LOGGED_TIME_CONFIG_SUCCESS =
  'UPSERT_LOCK_LOGGED_TIME_CONFIG_SUCCESS';
export const UPSERT_LOCK_LOGGED_TIME_CONFIG_FAILURE =
  'UPSERT_LOCK_LOGGED_TIME_CONFIG_FAILURE';

export const fetchLockLoggedTimeConfig = () => {
  return (dispatch, getState) => {
    const { companyPrefs } = getState();
    const hasTimeTracking = companyPrefs?.time_tracking > 0;
    if (!hasTimeTracking) return null;

    dispatch({
      type: FETCH_LOCK_LOGGED_TIME_CONFIG,
    });

    return API3.fetchLockLoggedTimeConfig()
      .then((config) => {
        const { error } = config;

        if (!error) {
          dispatch({
            type: FETCH_LOCK_LOGGED_TIME_CONFIG_SUCCESS,
            payload: { config },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_LOCK_LOGGED_TIME_CONFIG_FAILURE,
          error,
        });
      });
  };
};

export const upsertLockLoggedTimeConfig = (active, interval, frequency) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPSERT_LOCK_LOGGED_TIME_CONFIG,
    });

    return API3.upsertLockLoggedTimeConfig(active, interval, frequency)
      .then((config) => {
        dispatch({
          type: UPSERT_LOCK_LOGGED_TIME_CONFIG_SUCCESS,
          payload: { config },
        });

        if (active) {
          trackEvent('Lock Logged dates', {
            lockInterval: interval,
            lockFreq: frequency,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: UPSERT_LOCK_LOGGED_TIME_CONFIG_FAILURE,
          error,
        });
      });
  };
};
