import React from 'react';
import { Trans } from '@lingui/macro';

import { isTimeTrackingEnabled } from '@float/common/selectors/companyPrefs';
import { ProjectStatus } from '@float/types/project';
import { getEntityStatusLabel } from '@float/ui/helpers/label/entityStatusLabel';
import { Modal } from '@float/ui/primitives/Modal';
import { useWebAppSelectorStrict } from '@float/web/lib/store';

import { getPhaseStatusMessages, getProjectStatusMessages } from './config';

export const EntityStatusMessage = ({
  prev,
  next,
  entity,
}: {
  prev: ProjectStatus;
  next: ProjectStatus;
  entity: 'project' | 'phase';
}) => {
  const timeTrackingEnabled = useWebAppSelectorStrict(isTimeTrackingEnabled);
  const prevStatus = getEntityStatusLabel(prev);
  const nextStatus = getEntityStatusLabel(next);
  const messages =
    entity === 'phase' ? getPhaseStatusMessages() : getProjectStatusMessages();
  const items = messages[`${prev}${next}` as keyof typeof messages];
  const showTimeTrackingMessage = Boolean(
    timeTrackingEnabled && items.timeTrackingEnabled,
  );
  return (
    <>
      <Modal.P>
        <Trans>
          Moving {entity} from {prevStatus} to {nextStatus} will:
        </Trans>
      </Modal.P>
      <Modal.List>
        {items.main.map((msg) => (
          <Modal.ListItem key={msg}>{msg}</Modal.ListItem>
        ))}
        {showTimeTrackingMessage ? (
          <Modal.ListItem>{items.timeTrackingEnabled}</Modal.ListItem>
        ) : null}
      </Modal.List>
      {items.accessNote && (
        <Modal.P>
          <Trans>
            Draft projects are not visible to Project Managers unless they are
            the Owner or ‘All Project Managers have edit rights’ is enabled.
          </Trans>
        </Modal.P>
      )}
    </>
  );
};
