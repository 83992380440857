import styled from 'styled-components';

export const TimeoffRequestHeaderWrapper = styled.div`
  margin-top: 18px;
  margin-bottom: 18px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const TimeoffRequestLabel = styled.h1`
  font-weight: 500;
  font-size: 16px;
  margin-left: 10px;
`;

export const TimeoffRequestSpan = styled.h1`
  font-weight: 400;
  font-size: 16px;
  margin-left: 4px;
`;
