import {
  DEFAULT_ENTERPRISE,
  PRO_MONTHLY,
  PRO_YEARLY,
  STARTER_MONTHLY,
  STARTER_YEARLY,
} from '@float/common/lib/pricing';
import { AddOnStatus } from '@float/types/account';

export const isPlanEnterprise = (
  id: string,
  isPaidPlan: boolean,
  hasBothAddons: boolean,
  isAnnualPlan: boolean,
) => {
  const planId = Number(id);

  // Current "Enterprise" plan
  if (planId === Number(DEFAULT_ENTERPRISE.plan_id)) {
    return true;
  }

  // Current Non-Enterprise plans
  if (
    planId === Number(PRO_MONTHLY.plan_id) ||
    planId === Number(PRO_YEARLY.plan_id) ||
    planId === Number(STARTER_MONTHLY.plan_id) ||
    planId === Number(STARTER_YEARLY.plan_id)
  ) {
    return false;
  }

  // All other legacy paid plans
  if (isPaidPlan) {
    return Boolean(hasBothAddons && isAnnualPlan);
  }

  // Trial plans are never "Enterprise"
  return false;
};

export const isPlanPro = (
  id: string,
  isPaidPlan: boolean,
  timeTracking: AddOnStatus,
  plusPack: AddOnStatus,
  isAnnualPlan: boolean,
) => {
  const planId = Number(id);
  const hasPlusPack = plusPack > 0;
  const hasTimeTracking = timeTracking > 0;
  const hasBothAddons = hasTimeTracking && hasPlusPack;
  const hasEitherPlan = hasTimeTracking || hasPlusPack;

  if (isPaidPlan) {
    if (
      planId === Number(PRO_MONTHLY.plan_id) ||
      planId === Number(PRO_YEARLY.plan_id)
    ) {
      return true;
    }

    // Customer on paid Starter plan, but is trialing Pro
    if (timeTracking === AddOnStatus.TRIAL && plusPack === AddOnStatus.TRIAL) {
      return true;
    }

    // Current Non-Pro plans
    if (
      planId === Number(DEFAULT_ENTERPRISE.plan_id) ||
      planId === Number(STARTER_MONTHLY.plan_id) ||
      planId === Number(STARTER_YEARLY.plan_id)
    ) {
      return false;
    }

    // Special case for customers with RP + PP (Beta)
    if (!hasTimeTracking && Number(plusPack) === AddOnStatus.BETA) {
      return false;
    }

    // Legacy paid plans where either addon is enabled
    // And not annual plans where both addons are enabled
    const isEnterprisePlan = isPlanEnterprise(
      id,
      isPaidPlan,
      hasBothAddons,
      isAnnualPlan,
    );
    return hasEitherPlan && !isEnterprisePlan;
  }

  // Trial plans considered "Pro" when both TT + PP addons enabled
  return Boolean(hasBothAddons);
};

export const isPlanStarter = (
  id: string | number | undefined,
  isPaidPlan: boolean,
  timeTracking: AddOnStatus,
  plusPack: AddOnStatus,
) => {
  const planId = Number(id);
  const hasPlusPack = plusPack > 0;
  const hasTimeTracking = timeTracking > 0;
  const hasNoAddons = !hasTimeTracking && !hasPlusPack;

  if (isPaidPlan) {
    // Customer on paid Starter plan, but is trialing Pro
    if (timeTracking === AddOnStatus.TRIAL && plusPack === AddOnStatus.TRIAL) {
      return false;
    }

    if (
      planId === Number(STARTER_MONTHLY.plan_id) ||
      planId === Number(STARTER_YEARLY.plan_id)
    ) {
      return true;
    }

    // Special case for customers with RP + PP (Beta)
    if (!hasTimeTracking && Number(plusPack) === AddOnStatus.BETA) {
      return true;
    }
  }

  // Legacy paid plans and Trial plans considered "Starter" when neither TT nor PP enabled
  return Boolean(hasNoAddons);
};

export const getUpgradePrice = ({
  upgradeFrom,
  isMonthly,
  isEnterpriseUpgrade,
}: {
  upgradeFrom: string;
  isMonthly?: boolean;
  isEnterpriseUpgrade?: boolean;
}) => {
  // upgrading from "Starter" to "Pro"
  if (!isEnterpriseUpgrade) {
    return isMonthly ? '$5' : '$4';
  }
  // upgrading from "Pro" to "Enterprise"
  if (upgradeFrom === 'pro' && isEnterpriseUpgrade) {
    return isMonthly ? '$3.50' : '$6';
  }
  // upgrading from "Starter" to "Enterprise"
  if (upgradeFrom === 'starter' && isEnterpriseUpgrade) {
    return isMonthly ? '$8.50' : '$10';
  }
};
