import React, { CSSProperties } from 'react';

export function LockOutline({
  style,
  className,
  size,
  color = '#344765',
}: {
  style?: CSSProperties;
  className?: string;
  size: number;
  color?: string;
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M3.8335 7.83335C3.8335 7.46516 4.13197 7.16669 4.50016 7.16669H11.5002C11.8684 7.16669 12.1668 7.46516 12.1668 7.83335V11.5C12.1668 12.2364 11.5699 12.8334 10.8335 12.8334H5.16683C4.43045 12.8334 3.8335 12.2364 3.8335 11.5V7.83335Z"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.16688 7.00002V6.89515C5.16688 5.85434 5.10426 4.69418 5.83119 3.94929C6.24574 3.52449 6.91655 3.16669 8.00021 3.16669C9.08387 3.16669 9.75469 3.52449 10.1692 3.94929C10.8962 4.69419 10.8335 5.85434 10.8335 6.89515V7.00002"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
