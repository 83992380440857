import styled, { css } from 'styled-components';

import * as Animation from '@float/ui/deprecated/Earhart/Animation';

export const StyledSVG = styled.svg`
  path {
    transition-property: stroke-dashoffset;
    transition-duration: 0.5s;
    transition-timing-function: ${Animation.Settings.Easing.DefaultIn};

    &:nth-child(1) {
      transition-delay: 0.05s;
    }

    &:nth-child(2) {
      transition-delay: 0s;
    }

    stroke-dasharray: 84;
    stroke-dashoffset: 84;

    ${({ $selected }) =>
      $selected &&
      css`
        transition-duration: 0.75s;
        transition-timing-function: ${Animation.Settings.Easing.DefaultOut};

        stroke-dashoffset: 0;

        &:nth-child(1) {
          transition-delay: 0s;
        }

        &:nth-child(2) {
          transition-delay: 0.05s;
        }
      `};
  }
`;
