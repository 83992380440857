import { CellsMap } from '@float/types';
import type { useScheduleRows } from '@float/common/serena/Data/useScheduleRows';

export type BuildWindowRowsParams = {
  rows: ReturnType<typeof useScheduleRows>;
  rowStart: number;
  rowStop: number;
  colStart: number;
  colStop: number;
  draggedRowId?: string | null;
  cells: CellsMap;
  overscan: number;
};

export type WindowColumn = {
  colIdx: number;
  key: `project:${string}` | `person:${string}` | `logged_time:${string}`;
};

export default function buildWindowRows(params: BuildWindowRowsParams) {
  const rowGroups = [];
  const cellKeys = new Set();

  const {
    cells,
    colStart,
    colStop,
    draggedRowId,
    overscan,
    rows,
    rowStart,
    rowStop,
  } = params;

  function buildRow(rowIdx: number, isDragging?: boolean) {
    function getCellKey(rowIdx: number, colIdx: number) {
      const key = `${rows[rowIdx].id}:${colIdx}` as `${
        | 'person'
        | 'project'
        | 'logged_time'}:${string}`;

      return key;
    }

    const columns = [];

    if (rowIdx >= rowStart && rowIdx < rowStop) {
      const colsRendered = new Set();

      for (let colIdx = colStart; colIdx < colStop; colIdx++) {
        if (colsRendered.has(colIdx)) continue;

        const cellKey = getCellKey(rowIdx, colIdx);
        columns.push({ colIdx, key: cellKey });
        colsRendered.add(colIdx);
        cellKeys.add(cellKey);

        const cell = cells[cellKey];

        if (cell?.dependsOnCols?.length) {
          cell.dependsOnCols.forEach((dc) => {
            if (!colsRendered.has(dc)) {
              const cellKey = getCellKey(rowIdx, dc);
              columns.push({ colIdx: dc, key: cellKey });
              colsRendered.add(dc);
            }
          });
        }
      }
    }

    return {
      rowIdx,
      columns,
      isDragging,
    };
  }

  // Build out the row groups
  if (draggedRowId) {
    // If we're currently dragging a row, we want to render it pinned to the
    // top of the scroll, regardless of its index. This ensures we never lose
    // reference to the dom element, even if the user scrolls far fast.
    const idx = rows.findIndex(
      (row) => 'key' in row && row.key === draggedRowId,
    );
    rowGroups.push(buildRow(idx, true));
  }

  for (
    let rowIdx = Math.max(0, rowStart - overscan);
    rowIdx < Math.min(rows.length, rowStop + overscan);
    rowIdx++
  ) {
    const row = rows[rowIdx];

    if ('key' in row && row.key === draggedRowId) continue; // eslint-disable-line
    rowGroups.push(buildRow(rowIdx, false));
  }

  return { data: rowGroups, cellKeys };
}
