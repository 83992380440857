export function getTextbox(container: HTMLElement) {
  return container.querySelector('input');
}

export function getOptions(container: HTMLElement) {
  return Array.from(
    container.querySelectorAll('[role="option"]'),
  ) as HTMLElement[];
}

export function getNextElement(
  container: HTMLElement,
  currentElement: HTMLElement | null,
) {
  if (!currentElement) return getTextbox(container);

  const options = getOptions(container);

  if (currentElement.matches('input')) {
    return options[0] || currentElement;
  }

  const index = options.indexOf(currentElement);

  return options[index + 1] || getTextbox(container);
}

export function getPrevElement(
  container: HTMLElement,
  currentElement: HTMLElement | null,
) {
  if (!currentElement) return getTextbox(container);

  const options = getOptions(container);

  if (currentElement.matches('input')) {
    return options[options.length - 1] || currentElement;
  }

  const index = options.indexOf(currentElement);

  return options[index - 1] || getTextbox(container);
}

export function isManagedElement(el: HTMLElement | null) {
  if (!el) return false;

  if (el.matches('input')) return true;

  return el.matches('[role="option"]');
}

export function focusElement(el: HTMLElement | null) {
  if (el) {
    el.focus();
  }
}
