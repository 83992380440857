import { useState } from 'react';

import { multiplyOperation } from '@float/libs/utils/floats';
import { TimeString } from '@float/types/datesManager';
import { AllocationType } from '@float/types/task';

import { getIsSpecificTimeSet } from '../EditTaskModal.helpers';
import { AllocationTimeSectionPayload } from '../EditTaskModal.types';

export const DEFAULT_START_TIME = '10:00';

export type UseAllocationTimeToggleParams = {
  hoursPerDay: number;
  startTime: TimeString | null;
  numberOfAllocationDays: number;
  isFixedEndDate?: boolean;
  onChange: (payload: AllocationTimeSectionPayload) => void;
};

export const useAllocationTimeToggle = ({
  startTime,
  hoursPerDay,
  numberOfAllocationDays,
  isFixedEndDate,
  onChange,
}: UseAllocationTimeToggleParams) => {
  // We need to preserve this value in state for switching from Hours/day to Specific time
  // because specific time for new task comes by default as one hour starting from 10:00
  // meanwhile the default value for hours per day is the minumum working
  // hours per day for the selected time range
  const [hoursPerDayInitialValue] = useState(() => hoursPerDay);

  // It is spefic time if the task start_time is defined
  const [isSpecificTime, setIsSpecificTime] = useState(() =>
    Boolean(startTime),
  );

  const isAllocationByTime = isSpecificTime;
  const isAllocationByHours = !isSpecificTime;

  const handleClickSetSpecificTimeButton = () => {
    const hoursPerDayValue = 1;
    const hoursTotalValue = numberOfAllocationDays * hoursPerDayValue;
    const startTimeValue = getIsSpecificTimeSet(startTime)
      ? startTime
      : DEFAULT_START_TIME;

    setIsSpecificTime(true);

    onChange({
      allocationType: AllocationType.Hours,
      hoursPerDay: hoursPerDayValue,
      hoursTotal: hoursTotalValue,
      startTime: startTimeValue,
    });
  };

  const handleClickSetTotalHoursButton = () => {
    const allocationType = isFixedEndDate
      ? AllocationType.HoursWithFixedDuration
      : AllocationType.Hours;

    setIsSpecificTime(false);

    onChange({
      allocationType,
      hoursPerDay: hoursPerDayInitialValue,
      hoursTotal: multiplyOperation(
        hoursPerDayInitialValue,
        numberOfAllocationDays,
      ),
      startTime: null,
    });
  };

  return {
    isAllocationByTime,
    isAllocationByHours,
    handleClickSetSpecificTimeButton,
    handleClickSetTotalHoursButton,
  };
};
