export const useScrollElementIntoView = () => {
  const scrollElementIntoView = (element: HTMLElement | null) => {
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  };

  return { scrollElementIntoView };
};
