import {
  bulkCreateLoggedTimes,
  createLoggedTime,
  fetchTask,
} from '@float/common/actions';
import { NOTES_EXCERPT_LENGTH } from '@float/common/lib/notes';
import { getTaskReferenceId } from '@float/common/serena/Data/useCells/helpers/getTaskReference';
import { AppDispatchStrict } from '@float/common/store';
import { LoggedTimeTaskReference } from '@float/types/loggedTime';
import { Task } from '@float/types/task';

import { BulkLoggedTimeSuggestion } from '../hooks/types';

const getFullLoggedTimeNotes = async (
  entity: Task | LoggedTimeTaskReference,
  reduxDispatch: AppDispatchStrict,
) => {
  if (
    entity.task_id &&
    entity.notes &&
    entity.notes.length === NOTES_EXCERPT_LENGTH
  ) {
    const task = await reduxDispatch(fetchTask(entity.task_id));
    return task.notes;
  }

  return entity.notes || '';
};

export async function persistLoggedTimeChanges(
  { reduxDispatch }: { reduxDispatch: AppDispatchStrict },
  changes: {
    entity: LoggedTimeTaskReference;
    originalEntity: LoggedTimeTaskReference;
    isCreate?: boolean;
  }[],
) {
  const result = [];

  for (const change of changes) {
    const { entity, originalEntity } = change;

    const isCreate =
      entity.isTaskReference ||
      !entity.logged_time_id ||
      entity.logged_time_id.includes('.');
    change.isCreate = isCreate;

    if (
      entity.isTaskReference &&
      changes.length > 1 &&
      !changes.every((c) => typeof c.entity.priority === 'undefined')
    ) {
      // If we have multiple task references with priorities, the user was
      // dragging an entity vertically. In this case, we don't want to log a
      // task reference incorrectly.
      return null;
    }

    const loggedTime = {
      logged_time_id: isCreate ? null : entity.logged_time_id,
      reference_date: entity.reference_date,
      people_id: entity.people_id,
      project_id: entity.project_id,
      phase_id: entity.phase_id,
      billable: entity.billable,
      priority: entity.priority,
      date: entity.date,
      hours: Number(entity.hours),
      task_id: entity.task_id || undefined,
      task_name: entity.task_name,
      task_meta_id: entity.task_meta_id,
      notes: await getFullLoggedTimeNotes(entity, reduxDispatch),
    };

    const r = await reduxDispatch(createLoggedTime(loggedTime, originalEntity));

    result.push(r);
  }

  return result;
}

export async function persistBulkLoggedTimes({
  reduxDispatch,
  changes,
}: {
  reduxDispatch: AppDispatchStrict;
  changes: BulkLoggedTimeSuggestion[];
}) {
  const tempIds: string[] = [];
  const loggedTimes = await Promise.all(
    changes.map(async (change) => {
      const { entity, date, person, priority } = change;

      tempIds.push(getTaskReferenceId(entity, person.people_id, date));

      const loggedTime = {
        reference_date: date,
        people_id: person.people_id,
        project_id: entity.project_id,
        phase_id: entity.phase_id,
        billable: entity.billable,
        priority,
        date,
        hours: Number(entity.hours),
        task_id: entity.task_id,
        task_name: entity.name,
        task_meta_id: entity.task_meta_id,
        notes: await getFullLoggedTimeNotes(entity, reduxDispatch),
      };

      return loggedTime;
    }),
  );

  return reduxDispatch(bulkCreateLoggedTimes(loggedTimes, tempIds));
}
