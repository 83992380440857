import { Milestone, RawDepartment } from '@float/types';

import { trackEvent } from '../lib/gtm';
import { makeRequest, Payload, PayloadAndID } from './makeRequest';

type DepartmentPayload = { name: string; parent_id?: number };

export const departments = {
  getMilestone(params: { id: number }) {
    return makeRequest<Milestone, undefined, number>({
      ...params,
      resource: 'milestones',
      method: 'GET',
    });
  },
  getAllDepartments() {
    return makeRequest<RawDepartment[]>({
      resource: 'departments',
      method: 'GET',
    });
  },
  createDepartment(params: Payload<DepartmentPayload>) {
    trackEvent('department-created', {
      subDepartment: Boolean(params.data.parent_id),
    });

    return makeRequest<RawDepartment, DepartmentPayload>({
      ...params,
      resource: 'departments',
      method: 'POST',
    });
  },
  updateDepartment(params: PayloadAndID<DepartmentPayload, number>) {
    return makeRequest<RawDepartment, DepartmentPayload, number>({
      ...params,
      resource: 'departments',
      method: 'PATCH',
    });
  },
  deleteDepartment(params: { id: number }) {
    trackEvent('department-deleted');

    return makeRequest<undefined, undefined, number>({
      ...params,
      resource: 'departments',
      method: 'DELETE',
    });
  },
};
