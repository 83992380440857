import React from 'react';
import { t } from '@lingui/macro';

import { DurationInputField } from '../components/DurationInputField';
import {
  DEFAULT_OFFSET,
  DEFAULT_PHASE_DURATION,
} from '../lib/projectValidationHelpers';

type DurationOffsetFieldProps = {
  name: 'phase.parent_offset' | 'phase.duration';
  label: string;
  placeholder: string;
};

function DurationOffsetField({
  name,
  label,
  placeholder,
}: DurationOffsetFieldProps) {
  return (
    <DurationInputField
      name={name}
      label={label}
      placeholder={placeholder}
      unit="days"
    />
  );
}

export function PhaseOffsetField() {
  return (
    <DurationOffsetField
      name="phase.parent_offset"
      label={t`Start after`}
      placeholder={`${DEFAULT_OFFSET}`}
    />
  );
}

export function PhaseDurationField() {
  return (
    <DurationOffsetField
      name="phase.duration"
      label={t`Duration`}
      placeholder={`${DEFAULT_PHASE_DURATION}`}
    />
  );
}
