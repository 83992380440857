import React from 'react';

export default function ({ size = '24', color = '#868D92' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
    >
      <path
        fill={color}
        fillRule="nonzero"
        d="M10 2v9H6l6 6 6-6h-4V2h-4zM2 18v4h20v-4h-2v2H4v-2H2z"
      />
    </svg>
  );
}
