import { cloneDeep } from 'lodash';

import {
  DEFAULT_SORT_TABLE,
  getEntityById,
  getSortData,
  getTableRowCreator,
  getTableRows,
} from './reducerHelpers_';

export {
  getSortData,
  getTableRowCreator,
  getTableRows,
  getEntityById,
  DEFAULT_SORT_TABLE,
};

export const ACCOUNT_SETTINGS_DEPARTMENTS_TABLE =
  'ACCOUNT_SETTINGS_DEPARTMENTS_TABLE';
export const ACCOUNT_SETTINGS_GUESTS_TABLE = 'ACCOUNT_SETTINGS_GUESTS_TABLE';
export const ACCOUNT_SETTINGS_INVITEES_TABLE =
  'ACCOUNT_SETTINGS_INVITEES_TABLE';
export const ACCOUNT_SETTINGS_TIMEOFF_TYPES_TABLE =
  'ACCOUNT_SETTINGS_TIMEOFF_TYPES_TABLE';
export const ACCOUNT_SETTINGS_HOLIDAYS_TABLE =
  'ACCOUNT_SETTINGS_HOLIDAYS_TABLE';
export const ACCOUNT_SETTINGS_CLIENTS_TABLE = 'ACCOUNT_SETTINGS_CLIENTS_TABLE';

export const lastFetchError = (state) => state.lastFetchError;

export const isBeingEdited = (entity, state, idProp) =>
  state.beingEditedIds.indexOf(entity[idProp]) > -1;

export const areBeingFetched = (state) => state.pagesBeingFetched.length > 0;

const concatEntities = (entities, newEntities, idProp) => {
  const ret = [...entities];

  if (!Array.isArray(newEntities)) return ret;

  newEntities.forEach((entity) => {
    const existing = getEntityById(entity[idProp], ret, idProp);
    if (existing) {
      const newEntity = cloneDeep(existing);
      Object.assign(newEntity, entity);
      ret.splice(ret.indexOf(existing), 1, newEntity);
    } else {
      ret.push(entity);
    }
  });

  return ret;
};

export const getInitialSortableTableState = (sortProp, sortDir) => ({
  sortProp,
  sortDir,
});

export const getInitialSortPropsState = (sortProps) => ({
  sortProps: sortProps.reduce((state, sortProp) => {
    return {
      ...state,
      [sortProp]: {
        asc: {
          lastFetchedPage: 0,
          ids: [],
        },
        desc: {
          lastFetchedPage: 0,
          ids: [],
        },
      },
    };
  }, {}),
});

export const getInitialStateCreator =
  (additionalState = {}) =>
  () => ({
    beingSubmitted: false,
    lastSubmitError: null,
    pageCount: -1,
    totalCount: -1,
    lastFetchedPage: 0,
    lastFetchError: null,
    pagesBeingFetched: [],
    entities: [],
    beingEditedIds: [],
    beingDeletedIds: [],
    lastDeleteError: null,
    ...additionalState,
  });

const getRefreshedSortPropIds = (sortProp, sortDir, state, idProp) => {
  const entities = state.entities.concat([]);
  entities.sort((a, b) => {
    let aSortProp = a[sortProp];
    let bSortProp = b[sortProp];
    if (typeof aSortProp === 'string' && typeof bSortProp === 'string') {
      aSortProp = aSortProp.toLowerCase();
      bSortProp = bSortProp.toLowerCase();
    }
    if (aSortProp > bSortProp) {
      return sortDir == 'asc' ? 1 : -1;
    } else if (aSortProp < bSortProp) {
      return sortDir == 'asc' ? -1 : 1;
    }
    return 0;
  });
  return entities.map((entity) => entity[idProp]);
};

const updateSortPropsIds = (idProp, state) => {
  const { sortProps } = state;
  const sortPropNames = Object.keys(sortProps);
  const updatedSortProps = sortPropNames.reduce(
    (newSortProps, sortPropName) => {
      const sortDirs = sortProps[sortPropName];
      const sortDirNames = Object.keys(sortDirs);
      newSortProps[sortPropName] = sortDirNames.reduce(
        (newSortDirs, sortDirName) => {
          const ids = getRefreshedSortPropIds(
            sortPropName,
            sortDirName,
            state,
            idProp,
          );
          return {
            ...newSortDirs,
            [sortDirName]: {
              ...sortDirs[sortDirName],
              ids,
            },
          };
        },
        {},
      );
      return newSortProps;
    },
    {},
  );
  return {
    ...state,
    sortProps: updatedSortProps,
  };
};

const addEntity = (state) => ({
  ...state,
  beingSubmitted: true,
});

export const addedEntity = (state, { payload }, idProp) => {
  return updateSortPropsIds(idProp, {
    ...state,
    beingSubmitted: false,
    lastSubmitError: null,
    entities: concatEntities(state.entities, [payload], idProp),
  });
};

const failedAddingEntity = (state, { error }) => {
  return {
    ...state,
    beingSubmitted: false,
    lastSubmitError: error,
  };
};

const deleteEntity = (state, { id }) => ({
  ...state,
  beingDeletedIds: [...state.beingDeletedIds, id],
  lastDeleteError: null,
});

export const deletedEntity = (state, { id }, idProp) => {
  const entities = state.entities.filter((entity) => entity[idProp] != id);
  return updateSortPropsIds(idProp, {
    ...state,
    entities,
    lastDeleteError: null,
  });
};

const failedDeletingEntity = (state, { id, error }) => {
  const beingDeletedIds = state.beingDeletedIds.filter(
    (deletedId) => deletedId !== id,
  );
  return {
    ...state,
    beingDeletedIds,
    lastDeleteError: error,
  };
};

const updateEntity = (state, { id }) => ({
  ...state,
  beingSubmitted: true,
});

export const updatedEntity = (state, { id, payload }, idProp) => {
  const entities = state.entities.map((entity) => {
    if (entity[idProp] == id) {
      return {
        ...entity,
        ...payload,
      };
    }
    return entity;
  });
  return updateSortPropsIds(idProp, {
    ...state,
    entities,
    beingSubmitted: false,
  });
};

const failedUpdatingEntity = (state, { error }) => {
  return {
    ...state,
    beingSubmitted: false,
    lastSubmitError: error,
  };
};

const updateSortProps = (sortProp, sortDir, idProp, state) => {
  const sortPropsState = state.sortProps;
  const sortPropState = sortPropsState[sortProp];
  const sortDirState = sortPropState[sortDir];
  const ids = getRefreshedSortPropIds(sortProp, sortDir, state, idProp);
  const { lastFetchedPage } = sortDirState;
  return {
    ...state,
    sortProps: {
      ...sortPropsState,
      [sortProp]: {
        ...sortPropState,
        [sortDir]: {
          ...sortDirState,
          lastFetchedPage: lastFetchedPage + 1,
          ids,
        },
      },
    },
  };
};

const updateSortableTables = (tableName, sortProp, sortDir, state) => {
  const { sortableTables } = state;
  const sortableTable = sortableTables[tableName];
  return {
    ...state,
    sortableTables: {
      ...sortableTables,
      [tableName]: {
        ...sortableTable,
        sortProp,
        sortDir,
      },
    },
  };
};

const sortEntities = (
  state,
  { property, direction, tableName = DEFAULT_SORT_TABLE },
  idProp,
) => {
  const sortProp = property;
  const sortDir = direction;
  return updateSortableTables(tableName, sortProp, sortDir, state);
};

const fetchEntities = (state, { page }) => {
  const pagesBeingFetched = state.pagesBeingFetched.concat([page]);
  return {
    ...state,
    pagesBeingFetched,
  };
};

const fetchedEntities = (
  state,
  { page, pageCount, totalCount, entities, tableName, freshFetch },
  idProp,
) => {
  if (!freshFetch) {
    entities = concatEntities(state.entities, entities, idProp);
  }
  const { sortProp, sortDir } = state.sortableTables[tableName];
  const pagesBeingFetched = state.pagesBeingFetched.filter(
    (item) => item !== page,
  );
  return updateSortProps(sortProp, sortDir, idProp, {
    ...state,
    entities,
    pageCount,
    totalCount,
    pagesBeingFetched,
  });
};

const refreshAllEntities = (state) => {
  return {
    ...state,
    ...getInitialStateCreator()(),
    ...getInitialSortPropsState(Object.keys(state.sortProps)),
  };
};

const failedFetchingEntities = (state, { page, error }) => {
  const pagesBeingFetched = state.pagesBeingFetched.filter(
    (item) => item !== page,
  );
  return {
    ...state,
    pagesBeingFetched,
    lastFetchError: error,
  };
};

export const reducerCreator =
  (actionTypes, idProp, getInitialState, defaultReducer) =>
  (state = getInitialState(), action) => {
    // console.log('REDUCER', state, action, actionTypes, idProp);
    switch (action.type) {
      case actionTypes.ADD_ENTITY:
        return addEntity(state);
      case actionTypes.ADD_ENTITY_SUCCESS:
        return addedEntity(state, action, idProp);
      case actionTypes.ADD_ENTITY_FAILURE:
        return failedAddingEntity(state, action);
      case actionTypes.DELETE_ENTITY:
        return deleteEntity(state, action);
      case actionTypes.DELETE_ENTITY_SUCCESS:
        return deletedEntity(state, action, idProp);
      case actionTypes.DELETE_ENTITY_FAILURE:
        return failedDeletingEntity(state, action);
      case actionTypes.UPDATE_ENTITY:
        return updateEntity(state, action, idProp);
      case actionTypes.UPDATE_ENTITY_SUCCESS:
        return updatedEntity(state, action, idProp);
      case actionTypes.UPDATE_ENTITY_FAILURE:
        return failedUpdatingEntity(state, action, idProp);
      case actionTypes.SORT_ENTITIES:
        return sortEntities(state, action, idProp);
      case actionTypes.FETCH_ENTITIES:
        return fetchEntities(state, action);
      case actionTypes.FETCH_ENTITIES_SUCCESS:
        return fetchedEntities(state, action, idProp);
      case actionTypes.FETCH_ENTITIES_FAILURE:
        return failedFetchingEntities(state, action);
      case actionTypes.CLEAR_ENTITIES:
        return refreshAllEntities(state, action);
      default:
        if (defaultReducer) {
          return defaultReducer(state, action);
        }
        return state;
    }
  };
