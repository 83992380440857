import React from 'react';

import { FIN } from '@float/common/earhart/colors';

export const ActiveCycleIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6 8C15.8209 8 16.001 8.1793 15.9902 8.39995C15.7818 12.6324 12.2842 16 8 16C3.58172 16 1.56562e-07 12.4183 3.49691e-07 8C5.36958e-07 3.71583 3.36758 0.218208 7.60006 0.00982248C7.8207 -0.00103986 8 0.179085 8 0.399999L8 1.6C8 1.82091 7.82026 1.99881 7.59966 2.01072C4.28061 2.18993 2 4.60041 2 8C2 11.5346 4.46538 14 8 14C11.3996 14 13.8101 11.7194 13.9893 8.40034C14.0012 8.17974 14.1791 8 14.4 8L15.6 8Z"
        fill={FIN.Lt.Icon.Default}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5587 7.16048C15.7953 7.16048 15.981 6.95584 15.9447 6.72211C15.4121 3.2953 12.7045 0.587993 9.27751 0.0558183C9.04379 0.0195238 8.83918 0.205219 8.83918 0.441739V1.68402C8.83918 1.88809 8.9935 2.05816 9.19492 2.09097C11.7224 2.50259 13.4968 4.27732 13.9086 6.80478C13.9414 7.00618 14.1115 7.16048 14.3155 7.16048H15.5587Z"
        fill={FIN.Lt.Icon.Brand}
      />
      <path
        d="M6.80636 4.88307L11.074 7.34704C11.4551 7.56698 11.4551 8.11683 11.074 8.33678L6.80636 10.8007C6.42541 11.0207 5.94922 10.7457 5.94922 10.3058V5.37795C5.94922 4.93807 6.42541 4.66313 6.80636 4.88307Z"
        fill={FIN.Lt.Icon.Default}
      />
    </svg>
  );
};
