import React from 'react';
import { Trans } from '@lingui/macro';
import { usePromptTracker } from 'OnboardingManager/hooks/usePromptTracker';

import { PROMPTS } from '@float/constants/prompts';
import { Button } from '@float/ui/components/Button';
import { AnchorData, Callout } from '@float/ui/deprecated';

const anchor: AnchorData = {
  anchors: [
    {
      id: 'project-plan-nav-callout',
      config: {
        position: 'right',
        alignment: 'start',
        arrowPosition: '32px',
        offset: {
          x: -8,
          y: 0,
        },
      },
    },
  ],
};

export function ProjectPlanInNavCallout() {
  const [done, setDone] = usePromptTracker(PROMPTS.projectPlanInNav);

  return (
    <Callout visible={!done} anchorData={anchor} width={465} onClose={setDone}>
      <Callout.Content>
        <section>
          <Callout.Title>
            <Trans>Project plan is now in the main navigation</Trans>
          </Callout.Title>

          <Callout.Paragraph>
            <Trans>
              Access project plans faster to break down timelines and budgets,
              and start planning resources.
            </Trans>
          </Callout.Paragraph>
          <Callout.Paragraph>
            <Trans>
              <strong>Tip:</strong> check out the new{' '}
              <a
                className="inline"
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.float.com/en/articles/9976502-draft-projects-phases-and-allocations"
              >
                draft project status
              </a>{' '}
              to scenario-plan projects before they appear on the Schedule.
            </Trans>
          </Callout.Paragraph>
        </section>
      </Callout.Content>

      <Callout.ActionBar>
        <Button appearance="primary" onClick={setDone}>
          <Trans>Okay</Trans>
        </Button>
      </Callout.ActionBar>
    </Callout>
  );
}
