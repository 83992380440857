import Rollbar from 'rollbar';

import { FloatDeploymentEnvironment } from '@float/constants/environment';

import { logger } from '..';
import { RollbarWebLoggerAdapter } from './rollbarWebLoggerAdapter.class';

export async function initializeRollbarWebLoggerClient(
  client: Rollbar,
  config: {
    environment: FloatDeploymentEnvironment;
    gitSha: string;
    supportedBrowsers: RegExp;
    isDevMode: boolean;
    onReady: () => void;
  },
) {
  await logger.initialize(
    new RollbarWebLoggerAdapter(
      client,
      config.environment,
      config.gitSha,
      config.supportedBrowsers,
    ),
    { isDevMode: config.isDevMode, onReady: config.onReady },
  );
}
