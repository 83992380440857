import { Resources } from './constants';
import { makeRequest, MakeRequestPagination } from './makeRequest';
import {
  fillLookupCache,
  getLookupAlg,
  getLookupCacheKey,
  getQueryAlg,
  lookupQueryClient,
} from './search.helpers';
import {
  MatchAlgorithm,
  PaginatedQueryResponse,
  QueryApiParams,
  QueryApiResult,
  RemoteEntityType,
  SearchResolveApiParams,
  SearchResolveResponse,
} from './search.types';

const API_VERSION = '1.0.0';

export const search = {
  query: async (data: QueryApiParams) => {
    const response = await makeRequest<
      PaginatedQueryResponse,
      QueryApiParams & { alg: MatchAlgorithm }
    >({
      version: API_VERSION,
      resource: Resources.SearchAutocompleteQuery,
      method: 'POST',
      data: {
        ...data,
        alg: getQueryAlg(),
      },
      pagination: MakeRequestPagination.On,
    });

    // Store the ids in the cache so if the user
    // selects one of these items we won't need to call
    // the API again to get the ids.
    fillLookupCache(response);

    return response;
  },
  lookup: (value: string, category: RemoteEntityType) => {
    async function queryFn() {
      const response = await makeRequest<
        QueryApiResult[],
        QueryApiParams & { alg: MatchAlgorithm }
      >({
        version: API_VERSION,
        resource: Resources.SearchAutocompleteQuery,
        method: 'POST',
        data: {
          query: value,
          filter: {
            key: 'category',
            value: category,
          },
          alg: getLookupAlg(),
        },
        pagination: MakeRequestPagination.Off,
      });

      return response.flatMap((item) => item.ids);
    }

    // Cache the result of the lookup query
    // so we can reuse the data fetched from the
    // autocomplete interaction
    return lookupQueryClient.fetchQuery({
      queryKey: getLookupCacheKey(value, category),
      queryFn,
    });
  },
  resolve: (data: SearchResolveApiParams) => {
    return makeRequest<SearchResolveResponse, typeof data>({
      version: API_VERSION,
      resource: Resources.SearchResolve,
      method: 'POST',
      data,
      pagination: MakeRequestPagination.Off,
    });
  },
};
