import React from 'react';
import { Trans } from '@lingui/macro';

import * as Accordion from '@float/ui/components/Accordion';

import { SectionCounterTag } from '../../components/SectionCounterTag';
import { SectionErrorTag } from '../../components/SectionErrorTag';
import { LockedTaskListField } from '../../fields/LockTaskListField';
import { AccordionEntry } from '../../hooks/useAccordionState';
import { UseFieldArrayProvider } from '../../hooks/useFieldArrayContext';
import { useIsPhasePanel } from '../../hooks/useIsPhasePanel';
import { ProjectTasksTable } from './ProjectTasksTable';

import {
  projectAccordionHeader,
  projectAccordionSummary,
  projectSectionHeader,
} from '../../styles.css';
import * as styles from './styles.css';

function ProjectTasksTitle() {
  return (
    <div className={projectSectionHeader}>
      <Trans>Tasks</Trans>
      <SectionCounterTag name="tasks" />
    </div>
  );
}

function InfoHeader() {
  return (
    <div className={projectAccordionSummary}>
      <SectionErrorTag fields={['tasks']} />
    </div>
  );
}

export const ProjectTasksSection = () => {
  const isPhasePanel = useIsPhasePanel();
  return (
    <Accordion.Item value={AccordionEntry.tasks}>
      <Accordion.Header
        className={projectAccordionHeader}
        info={<InfoHeader />}
      >
        <ProjectTasksTitle />
      </Accordion.Header>
      <Accordion.Content className={styles.projectTasksContainer}>
        <UseFieldArrayProvider name="tasks">
          <ProjectTasksTable />
        </UseFieldArrayProvider>
        {!isPhasePanel && (
          <>
            <hr className={styles.divider} />
            <LockedTaskListField />
          </>
        )}
      </Accordion.Content>
    </Accordion.Item>
  );
};
