import differenceInDays from 'date-fns/differenceInDays';
import isWithinInterval from 'date-fns/isWithinInterval';

import { Timeframe } from '@float/types/timeframes';

import { CycleVariant, StandardCycleConfig } from '../Cycles.types';
import { getEffectiveTimeframeStartDate } from './dateBoundaries';
import { getTotalDays } from './getTotalDays';

export const buildSingleCycle = (
  index: number,
  timeframes: Timeframe[],
  startDate: Date,
  today: Date,
): StandardCycleConfig => {
  const timeframe = timeframes[index];
  const name = timeframe.name;
  const key = timeframe.id;

  const totalDays = getTotalDays(timeframe.endDate, timeframe.startDate);
  const spanDays = totalDays - 2; // Remove one day each for the start and end indicators

  const previousTimeframe = timeframes[index - 1];

  const daysFromStart = differenceInDays(
    getEffectiveTimeframeStartDate(timeframe, startDate),
    startDate,
  );

  const daysFromStartOfPreviousCycle = previousTimeframe
    ? differenceInDays(
        timeframe.startDate,
        getEffectiveTimeframeStartDate(previousTimeframe, startDate),
      )
    : daysFromStart;

  const daysFromEndOfPreviousCycle = previousTimeframe
    ? differenceInDays(timeframe.startDate, previousTimeframe.endDate) - 1
    : daysFromStart;

  if (
    isWithinInterval(today, {
      start: timeframe.startDate,
      end: timeframe.endDate,
    })
  ) {
    return {
      key,
      daysFromStart,
      daysFromStartOfPreviousCycle,
      daysFromEndOfPreviousCycle,
      name,
      totalDays,
      spanDays,
      variant: CycleVariant.Current,
    };
  }

  const daysBetweenTodayAndStartOfTimeframe = differenceInDays(
    timeframe.startDate,
    today,
  );

  if (daysBetweenTodayAndStartOfTimeframe > 0) {
    return {
      key,
      daysFromStart,
      daysFromStartOfPreviousCycle,
      daysFromEndOfPreviousCycle,
      name,
      totalDays,
      spanDays: spanDays,
      variant: CycleVariant.Upcoming,
    };
  }

  return {
    key,
    daysFromStart,
    daysFromStartOfPreviousCycle,
    daysFromEndOfPreviousCycle,
    name,
    totalDays,
    spanDays: spanDays,
    variant: CycleVariant.Completed,
  };
};
