import React from 'react';
import { Trans } from '@lingui/macro';

import { NoResultsIllustration } from '@float/ui/deprecated/Illustrations/NoResultsIllustration';

import { ScheduleError } from '../../../ScheduleError';

const ErrorSearchNoResults = () => {
  return (
    <ScheduleError>
      <h2>
        <Trans>No results found</Trans>
      </h2>
      <p>
        <Trans>Please adjust your search filters</Trans>
      </p>
      <NoResultsIllustration />
    </ScheduleError>
  );
};

export { ErrorSearchNoResults };
