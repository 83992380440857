import jwt_decode from 'jwt-decode';

import { trackEvent } from '@float/common/lib/analytics';

function getKey(user) {
  const { admin_id, cid } = user;
  return `${cid || ''}::${admin_id || ''}::interactions`;
}

export function trackInteraction(
  feature,
  user,
  { pushToAnalytics = false } = {},
) {
  const key = getKey(user);
  const data = JSON.parse(localStorage.getItem(key) || '[]');
  if (!data.includes(feature)) {
    localStorage.setItem(key, JSON.stringify([...data, feature]));
    if (pushToAnalytics) {
      trackEvent(`Used ${feature}`);
    }
  }
}

export function trackSignedInEvent(accessToken, localStorage) {
  if (!accessToken || !localStorage) return;

  const currentCompanyId = localStorage.getItem('currentCompanyId');
  if (currentCompanyId) {
    trackEvent('Already signed in');
  } else {
    const types = ['other', 'password', 'google', 'saml', 'magic'];
    let tokenData;
    try {
      tokenData = jwt_decode(accessToken);
      const typeId = tokenData.account.login_method;
      localStorage.setItem('currentCompanyId', tokenData.company.id);
      trackEvent('Signed in', { type: types[typeId] });
    } catch (_) {
      // Ignore
    }
  }
}
