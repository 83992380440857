// From react-window

let size = -1;

export default function getScrollbarSize(recalculate = false) {
  if (size === -1 || recalculate) {
    const div = document.createElement('div');
    const { style } = div;
    style.width = '50px';
    style.height = '50px';
    style.overflow = 'scroll';

    document.body.appendChild(div);

    size = div.offsetWidth - div.clientWidth + 1;

    document.body.removeChild(div);
  }

  return size;
}
