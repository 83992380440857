import { createSelector } from 'reselect';

import { ReduxStateStrict } from '../../reducers/lib/types';
import { getProjectsMap } from './projects';

export const selectEnhancedProjectById = createSelector(
  [getProjectsMap, (_: ReduxStateStrict, id: number | undefined) => id],
  (projects, id) => {
    if (!id) return undefined;

    return projects[id];
  },
);
