import React from 'react';
import { reduce, sortBy } from 'lodash';

import PersonAvatar from '@float/common/components/elements/PersonAvatar';

export const avatarProps = {
  readOnly: true,
  size: 'xs',
};

export const getPeopleByDept = (people, deptMap, selectedIds) => {
  const peopleByDept = sortBy(
    reduce(
      people,
      (acc, p) => {
        if (selectedIds.find((id) => id == p.people_id)) return acc;

        const dept = p.department_id
          ? deptMap[p.department_id]
          : { id: 0, name: 'No department' };

        if (!acc[dept.name]) {
          acc[dept.name] = {
            name: dept.name,
            value: dept.id,
            options: [],
          };
        }

        acc[dept.name].options.push({
          value: p.people_id,
          label: p.name,
          icon: <PersonAvatar personId={p.people_id} {...avatarProps} />,
        });

        return acc;
      },
      {},
    ),
    (g) => g.name.toLowerCase(),
  );

  peopleByDept.forEach((dept) => {
    dept.options = sortBy(dept.options, (o) => o.label.toLowerCase());
  });

  if (peopleByDept.length) {
    peopleByDept.unshift({
      name: '',
      value: -1,
      options: [
        {
          label: 'All team members',
        },
      ],
    });
  }

  return peopleByDept;
};
