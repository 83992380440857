import React, { forwardRef } from 'react';
import cx from 'classnames';

import { StyledAnchor, StyledButton, StyledLink } from './styles';

const Button = forwardRef((props, ref) => {
  const {
    icon,
    children,
    appearance = 'primary',
    className,
    size = 'medium',
    alignment,
    disabled = false,
    trailingIcon = false,
    iconSize = 20,
    onClick,
    isActive = false,
    ...other
  } = props;

  let StyledTag = StyledButton;

  if (other.to) {
    StyledTag = StyledLink;
  } else if (other.href) {
    StyledTag = StyledAnchor;
  }

  const onClickHandler = (e) => {
    if (disabled) {
      return e.preventDefault();
    }

    if (onClick) onClick(e);
  };

  return (
    <StyledTag
      ref={ref}
      className={cx(className, {
        primary: appearance === 'primary',
        secondary: appearance === 'secondary',
        danger: appearance === 'danger',
        disabled,
        active: isActive,
      })}
      size={size}
      alignment={alignment}
      trailingIcon={trailingIcon}
      onClick={onClickHandler}
      {...other}
    >
      {icon && React.createElement(icon, { size: iconSize })}
      <span>{children}</span>
    </StyledTag>
  );
});

export default Button;
