import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { inputStyles } from './styles';

export const DateInputContainer = styled.div<{
  inputTextStyle?: FlattenSimpleInterpolation;
  inputWidth?: number;
  height?: number;
}>`
  position: relative;
  ${inputStyles};
  input {
    ${(p) => p.inputTextStyle ?? ''};
    cursor: pointer;
  }

  input {
    width: ${(p) => p.inputWidth ?? 130}px;
  }

  ${(p) =>
    p.height &&
    css`
      input {
        height: ${p.height}px;
      }
    `}
`;
