type LoadStatus = 'loading' | 'loaded' | 'error';

export type State<T> = {
  data: T;
  status: LoadStatus;
};

export type Action<T> =
  | { type: 'LOADING' | 'ERROR' }
  | { type: 'LOADED'; payload: T };

export function reducer<T>(state: State<T>, action: Action<T>): State<T> {
  switch (action.type) {
    case 'LOADING':
      return {
        data: state.data,
        status: 'loading',
      };
    case 'LOADED':
      return {
        data: action.payload,
        status: 'loaded',
      };
    case 'ERROR':
      return {
        data: state.data,
        status: 'error',
      };
    default:
      return state;
  }
}
