import React from 'react';

export default function ({ color = '#0B752E' }) {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 72C55.8823 72 72 55.8823 72 36C72 16.1177 55.8823 0 36 0C16.1177 0 0 16.1177 0 36C0 55.8823 16.1177 72 36 72ZM51.3231 28.1411C51.9529 27.4449 51.8991 26.3699 51.2028 25.7401C50.5066 25.1103 49.4316 25.1641 48.8018 25.8603L30.7527 45.8127L23.2212 37.1364C22.6057 36.4274 21.5321 36.3516 20.8231 36.967C20.1141 37.5824 20.0383 38.6561 20.6537 39.365L29.4428 49.49C29.7617 49.8574 30.2227 50.0705 30.7091 50.0755C31.1955 50.0805 31.6608 49.8768 31.9872 49.5161L51.3231 28.1411Z"
        fill={color}
      />
    </svg>
  );
}
