import React, { memo } from 'react';

import { useWebAppSelector } from '../lib/store';
import { getOnboardingCalloutsStatus } from '../store/onboardingManager/selectors/callouts';
import { getProductTourStatus } from '../store/onboardingManager/selectors/productTour';
import { getSurveyStatus } from '../store/onboardingManager/selectors/survey';
import { AddTask } from './components/callouts/AddTask';
import { HelpGuides } from './components/callouts/HelpGuides';
import { OnboardingSurvey } from './components/OnboardingSurvey';
import { ProductTour } from './components/ProductTour';
import { RenderFirst } from './helpers/RenderFirst';
import { useOnboardingManagerNextFunction } from './hooks/useOnboardingManagerNextFunction';

import * as styles from './styles.css';

export const OnboardingViewManager = memo(() => {
  const survey = useWebAppSelector(getSurveyStatus);
  const productTour = useWebAppSelector(getProductTourStatus);
  const callouts = useWebAppSelector(getOnboardingCalloutsStatus);

  const next = useOnboardingManagerNextFunction();

  // Replay of product tour
  if (productTour.data && productTour.data.fromReplay) {
    return <ProductTour next={next} replaying={true} />;
  }

  const showOnboardingSurvey = !survey.completed;
  const showProductTour = productTour.show;
  const showAddATask = callouts.showAddATask;
  const showHelpGuides = callouts.showHelpGuides;

  return (
    <div className={styles.onboardingManagerContainer}>
      <RenderFirst>
        {showOnboardingSurvey && (
          <OnboardingSurvey next={next} status={survey} />
        )}

        {
          // @ts-expect-error ProductTour has no types
          showProductTour && <ProductTour next={next} />
        }
        {
          // @ts-expect-error ProductTour has no types
          showAddATask && <AddTask next={next} />
        }
        {
          // @ts-expect-error ProductTour has no types
          showHelpGuides && <HelpGuides next={next} />
        }
      </RenderFirst>
    </div>
  );
});
