import { RawTaskMeta, TaskMeta } from '@float/types';

import request from '../lib/request';

export const getTaskMeta = async (
  params: {
    project_id?: number | string | null;
    phase_id?: number | string | null;
  },
  opts = {},
): Promise<RawTaskMeta[]> =>
  await request.get('task-meta/all', params, {
    version: 'f3',
    ...opts,
  });

export type CreateTaskMetaPayload = Pick<
  TaskMeta,
  'billable' | 'budget' | 'project_id' | 'task_name'
>;

export const createTaskMeta = (
  params: CreateTaskMetaPayload,
): Promise<RawTaskMeta> => request.post('task-meta', params, { version: 'f3' });

export type UpdateTaskMetaPayload = Pick<
  TaskMeta,
  'billable' | 'budget' | 'task_name'
>;

export const updateTaskMeta = (
  params: UpdateTaskMetaPayload,
  id: TaskMeta['task_meta_id'],
): Promise<RawTaskMeta> =>
  request.put(`task-meta/${id}`, params, { version: 'f3' });

export const deleteTaskMeta = (id: TaskMeta['task_meta_id']): Promise<void> =>
  request.del(`task-meta/${id}`, null, { version: 'f3' });

export const bulkDeleteTaskMeta = (
  task_meta_ids: TaskMeta['task_meta_id'][],
): Promise<void> =>
  request.post('task-meta/bulk-delete', { task_meta_ids }, { version: 'f3' });

export type MergeTaskMetaPayload = Pick<
  TaskMeta,
  'billable' | 'budget' | 'task_name'
> & {
  task_meta_ids: TaskMeta['task_meta_id'][];
};

export const mergeTaskMeta = (
  params: MergeTaskMetaPayload,
): Promise<RawTaskMeta> =>
  request.post('task-meta/merge', params, { version: 'f3' });

export const tasks = {
  getTaskMeta,
  createTaskMeta,
  updateTaskMeta,
  deleteTaskMeta,
  bulkDeleteTaskMeta,
  mergeTaskMeta,
};
