import React from 'react';

export default function ({ size = '24', color = '#E62768', style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      style={style}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill={color}
          fillRule="nonzero"
          d="M2.917 2.917c3.889-3.89 10.253-3.89 14.142 0 3.889 3.889 3.889 10.253 0 14.142-3.89 3.889-10.253 3.889-14.142 0-3.89-3.89-3.89-10.253 0-14.142z"
          transform="translate(3.012 2.012)"
        />
        <path
          fill="#FFFFFF"
          d="M9.988 8.574L6.452 5.038 5.038 6.452 8.574 9.988 5.038 13.523 6.452 14.938 9.988 11.402 13.523 14.938 14.938 13.523 11.402 9.988 14.938 6.452 13.523 5.038z"
          transform="translate(3.012 2.012)"
        />
      </g>
    </svg>
  );
}
