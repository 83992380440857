import React from 'react';
import { t } from '@lingui/macro';

import { PhaseInputData } from '@float/types/phase';

import { PanelType } from '../../../sidePanel/types';
import { useSidePanel } from '../../../sidePanel/useSidePanel';
import { useGetPhaseData } from './hooks/useGetPhaseData';
import { useHasChangedOnce } from './hooks/useHasChangedOnce';
import { useMilestoneSave } from './hooks/useMilestoneSave';
import { usePhaseFormValues } from './hooks/usePhaseFormValues';
import { usePhaseFormValuesOverrides } from './hooks/usePhaseFormValuesOverrides';
import { getDefaultPhaseName, usePhaseSave } from './hooks/usePhaseSave';
import { useProjectSave } from './hooks/useProjectSave';
import { useSidePanelSnackbar } from './hooks/useSidePanelSnackbar';
import { useTaskSave } from './hooks/useTaskSave';
import { PhasePanelView } from './PhasePanelView';
import { PhaseEditData, ProjectFormData } from './types';

export const PhasePanelController: React.FC = () => {
  const { showSnackbar, showSuccessSnackbar } = useSidePanelSnackbar();
  const {
    panelPayload,
    sidePanelOpen,
    closeCurrentPanel,
    showPhaseSidePanel,
    openPanels,
  } = useSidePanel<PhaseEditData>(PanelType.PhasePanel);
  const isFirstPanel = openPanels.length === 1;
  const phaseId = panelPayload?.phaseId;

  const { data, isLoading } = useGetPhaseData(panelPayload);
  const values = usePhaseFormValues(data, panelPayload);

  const projectSave = useProjectSave();
  const phaseSave = usePhaseSave();
  const taskSave = useTaskSave();
  const milestoneSave = useMilestoneSave();

  const isEditing = Boolean(phaseId);

  const overrideValues = usePhaseFormValuesOverrides(panelPayload);

  function handleAfterSave(phaseId: number, data: ProjectFormData) {
    if (!data.phase) return;

    const { projectId, team, tasks, milestones } = data;
    const phase: PhaseInputData = {
      ...data.phase,
      phase_id: phaseId || data.phase.phase_id || null,
      project_id: projectId || null,
    };
    panelPayload?.afterSave?.({ phase, team, tasks, milestones });
  }

  async function handleSubmit(data: ProjectFormData) {
    const { projectId, phase, team, tasks, milestones, project } = data;

    if (!phase) return;

    if (!projectId) {
      // Adding/editing phase details for a new project.
      // Simply return the data to the invoker.
      handleAfterSave(0, data);
      return;
    }

    const phaseName = phase.phase_name || getDefaultPhaseName();

    if (panelPayload?.unsavedProject?.project) {
      try {
        // If the project form has unsaved changes (such as budget_type)
        // we need to persist those changes before saving the phase form.
        await projectSave.handleUpdate({
          projectId,
          update: {
            project: panelPayload.unsavedProject.project,
            teamDiff: panelPayload.unsavedProject.teamDiff,
            team: [],
          },
        });
      } catch (err) {
        const errorMessage = !phaseId
          ? t`“${phaseName}” creation failed`
          : t`“${phaseName}” update failed`;
        showSnackbar(errorMessage);
        throw err;
      }
    }

    if (!phaseId) {
      try {
        const phaseId = await phaseSave.handleCreate({
          phase,
          project,
          projectId,
          team,
        });
        await Promise.all([
          milestoneSave.handleBulkMilestonesUpsert(
            milestones,
            projectId,
            phaseId,
          ),
          taskSave.handleBulkTasksUpsert(tasks, projectId, phaseId),
        ]);

        handleAfterSave(phaseId, data);

        const successMessage = t`“${phaseName}” added`;
        if (isFirstPanel) {
          showSuccessSnackbar(successMessage, {
            onLinkClick: () => {
              showPhaseSidePanel({ ...panelPayload, phaseId });
            },
          });
        } else {
          showSnackbar(successMessage);
        }
      } catch (err) {
        showSnackbar(t`“${phaseName}” creation failed`);
        throw err;
      }
    } else {
      try {
        await Promise.all([
          phaseSave.handleUpdate(phaseId, { project, phase, team }, values),
          taskSave.handleUpdate(tasks, values.tasks, projectId, phaseId),
          milestoneSave.handleUpdate(
            milestones,
            values.milestones,
            projectId,
            phaseId,
          ),
        ]);

        handleAfterSave(phaseId, data);

        const successMessage = t`“${phaseName}” updated`;
        if (isFirstPanel) {
          showSuccessSnackbar(successMessage, {
            onLinkClick: () => {
              showPhaseSidePanel(panelPayload);
            },
          });
        } else {
          showSnackbar(successMessage);
        }
      } catch (err) {
        showSnackbar(t`“${phaseName}” update failed`);
        throw err;
      }
    }
  }

  const idHasChanged = useHasChangedOnce(phaseId);

  return (
    <PhasePanelView
      key={values.phase?.phase_id || 'newPhase'} // Reset the component state when navigating phases
      animate={isFirstPanel && !idHasChanged} // We don't want to animate when navigating phases
      initialValuesFromProjectForm={overrideValues}
      isOpen={sidePanelOpen}
      isEditing={isEditing}
      isLoading={isLoading}
      onClose={closeCurrentPanel}
      values={values}
      onSubmit={handleSubmit}
    />
  );
};
