import React from 'react';

export default ({ size = '48', color, children, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      {...rest}
    >
      <defs>
        <linearGradient
          id="icon-feature-gradient-a"
          x1="15.189"
          x2="38.254"
          y1="15.108"
          y2="41.655"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5DD0CE" />
          <stop offset="1" stopColor="#1B7CEF" />
        </linearGradient>

        <linearGradient
          id="icon-feature-gradient-b"
          x1="15.189"
          x2="38.254"
          y1="15.108"
          y2="41.655"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5DD0CE" />
          <stop offset="1" stopColor="#1B7CEF" />
        </linearGradient>
      </defs>

      {children}
    </svg>
  );
};
