import { config } from '@float/libs/config';

export function ajaxForm(url, params, opts) {
  if (!opts) opts = {};
  return new Promise((res, rej) => {
    const xhr = new window.XMLHttpRequest();
    xhr.upload.addEventListener('progress', (evt) => {
      if (evt.lengthComputable) {
        const percents = (evt.loaded / evt.total) * 100;
        return opts.onProgress({
          percents,
          total: evt.total,
          loaded: evt.loaded,
        });
      }
      return null;
    });
    xhr.addEventListener('load', function onLoad(evt) {
      if (this.status >= 200 && this.status < 300) {
        return res(JSON.parse(xhr.responseText));
      }
      return rej(xhr);
    });
    xhr.addEventListener('error', (evt) => {
      return rej(xhr);
    });
    xhr.withCredentials = true;
    xhr.open('POST', `${config.api.hostname}/${url}`, true);
    return xhr.send(params.body);
  });
}
