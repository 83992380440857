import styled from 'styled-components';

import * as Typography from '@float/ui/deprecated/Earhart/Typography';

import EH from '../../Earhart';
import { Col } from '../../Layout/Layout';

export const StyledModalBody = styled.div`
  ${Typography.TextM.R400}

  padding: 20px 32px 0;
  position: relative;
  box-sizing: border-box;

  > p {
    margin-bottom: 13px;
  }

  > p:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .loader {
    margin-left: -50px;
    margin-top: -37px;
  }
`;

export const LoaderWrapper = styled.div`
  min-height: 300px;
`;

export const ModalContentPlaceholder = styled(Col)`
  ${EH.Typography.TextS14.R400};
  color: ${EH.Colors.TCore.Emp.Medium11};
  min-height: 152px;

  ${({ isCentered }) =>
    isCentered
      ? `
        align-items: center;
        justify-content: center;
      `
      : `
        align-items: flex-start;
        justify-content: flex-start;
      `}
`;
