import { TaskStatus, TaskStatusEnum } from '@float/types/taskStatus';
import { TimeoffStatus } from '@float/types/timeoffStatus';

export type ItemType = {
  type: 'task' | 'timeoff';
  entity?: {
    status: TaskStatus | TimeoffStatus;
    project_id?: number;
  };
};

export function isDraftTask(item: ItemType) {
  return item.type === 'task' && item.entity?.status === TaskStatusEnum.Draft;
}

// Ideally, items such as these should be filtered out at a higher layer.
// And draft tasks are actually filtered out in the tasks selector.
// This additional check is a "last line of defense" to make sure this data
// does not surface on the UI. E.g. without this check, we see a "flash of
// draft tasks" when the view type is changed from "Schedule" to "Project plan"
// before the selector re-runs and the data gets filtered out.
export function isInaccessibleDraftTask(
  item: ItemType,
  isProjectPlanView?: boolean,
) {
  if (isDraftTask(item)) {
    // Draft tasks are only accessible in the project plan view
    if (!isProjectPlanView || !item.entity?.project_id) return true;
  }

  return false;
}
