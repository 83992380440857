import { StyleRule } from '@vanilla-extract/css';
import { css } from 'styled-components';

export const R400 = css`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

export const VE_R400: StyleRule = {
  fontSize: 12,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 18,
};
