import React from 'react';

import { StepBase } from '../../StepBase';
import {
  StyledList,
  StyledListItem,
  StyledParagraph,
  StyledTitle,
} from '../../StepBase/styles';

const QuickAdd = (props) => {
  const { userSpecs, ...otherProps } = props;

  return (
    <StepBase {...otherProps}>
      <StyledTitle>Quick add</StyledTitle>
      <StyledParagraph>
        The easiest way to add to the schedule. Depending on your permissions,
        you can:
      </StyledParagraph>
      <StyledList>
        <StyledListItem>Allocate time</StyledListItem>
        {userSpecs.hasTimeTracking && (
          <StyledListItem>Log your time</StyledListItem>
        )}
        <StyledListItem>Schedule time off</StyledListItem>
        <StyledListItem>Add a new project</StyledListItem>
        <StyledListItem>Add someone to the team</StyledListItem>
      </StyledList>
    </StepBase>
  );
};

export default QuickAdd;
