export function lighten(
  [r, g, b]: [number, number, number],
  val: number,
): [number, number, number] {
  return [
    Math.round(r + (255 - r) * val),
    Math.round(g + (255 - g) * val),
    Math.round(b + (255 - b) * val),
  ];
}
