import { toDbFormat } from '@floatschedule/js-common-npm/dist/lib/notes';
import { isEmpty, isNaN, isUndefined, toNumber } from 'lodash';

import { formatToDbString } from '@float/common/lib/budget';
import { validateEmailV2, validatePassword } from '@float/libs/validate';

export const bindVal = (
  self,
  key,
  defaultValue,
  validationFn,
  getAdditionalFormState,
) => {
  return {
    value: self.state.form[key],

    onChange: (arg) => {
      let val;
      let notesMeta = {};

      if (arg && typeof arg === 'object' && arg.type === 'change') {
        // Normal input type fields
        val = arg.currentTarget.value;
      } else if (arg && typeof arg === 'object' && !isUndefined(arg.value)) {
        // VirtualSelect
        val = arg.value;
      } else if (arg && typeof arg === 'object' && !isUndefined(arg.text)) {
        const dbFormat = toDbFormat(arg.children);
        notesMeta = { notes_meta: dbFormat.notes_meta };
        val = arg.text;
      } else {
        // Default
        val = arg;
      }

      if (!val && typeof defaultValue !== 'undefined') {
        val = defaultValue;
      }

      if (typeof validationFn === 'function' && !validationFn(val)) return;

      const additionalFormState = getAdditionalFormState?.(val) || {};

      self.setState((ps) => ({
        form: {
          ...ps.form,
          [key]: val,
          ...additionalFormState,
          ...notesMeta,
        },
        formErrors: {
          ...ps.formErrors,
          [key]: [],
        },
      }));
    },

    errors: self.state.formErrors[key] || [],
  };
};

export const getErrors = {
  name: (value) => {
    const errors = [];
    if (!value || !value.length) {
      errors.push('Please enter a name.');
    }
    return errors;
  },

  email: (
    value,
    {
      currentUserAccount = {},
      existingAccounts = {},
      existingPeople = {},
      isRequired = true,
      skipDuplicateCheck = false,
    },
  ) => {
    const errors = [];
    const isRequiredAndMissing = isRequired && (!value || !value.length);
    const isEnteredAndInvalid = value && !validateEmailV2(value);
    if (isRequiredAndMissing) {
      errors.push('Please include an email address.');
    } else if (isEnteredAndInvalid) {
      errors.push('Please enter a valid email.');
    } else if (value && !skipDuplicateCheck) {
      const duplicateAccount = Object.values(existingAccounts).find(
        (a) =>
          a.email &&
          a.email.toLowerCase() === value.toLowerCase() &&
          a.account_id !== currentUserAccount.account_id,
      );

      if (duplicateAccount) {
        let by = '';
        if (!isEmpty(existingPeople)) {
          const isPerson = Object.values(existingPeople).find(
            (p) => p.account_id == duplicateAccount.account_id,
          );
          by = isPerson ? 'by a person ' : 'by a guest ';
        }
        errors.push(
          `Email already in use ${by}on this team. Please try another.`,
        );
      } else {
        const duplicatePerson =
          !isEmpty(existingPeople) &&
          Object.values(existingPeople).find(
            (p) =>
              p.email &&
              p.email.toLowerCase() === value.toLowerCase() &&
              p.people_id !== currentUserAccount.people_id,
          );
        if (duplicatePerson) {
          errors.push(
            `Email already in use by a person on this team. Please try another.`,
          );
        }
      }
    }
    return errors;
  },

  old_password: (value) => {
    const errors = [];
    if (!value || !value.length) {
      errors.push('Please enter the current password.');
    }
    return errors;
  },

  password: (value, confirmValue) => {
    const newErrors = [];
    const confirmErrors = [];

    const isEnteredAndInvalid = value && !validatePassword(value);

    if (isEnteredAndInvalid) {
      newErrors.push('(Min. 8 characters with a letter and a number)');
    } else if (value !== confirmValue) {
      confirmErrors.push('Please make sure the passwords match.');
    }

    if (!newErrors.length && !confirmErrors.length) {
      return {};
    }

    return {
      new_password: newErrors,
      password_confirm: confirmErrors,
    };
  },

  amount: (value) => {
    const errors = [];
    if (!value) {
      return errors;
    }

    const amountInDbFormat = formatToDbString(value);
    if (amountInDbFormat.endsWith('.') || isNaN(toNumber(amountInDbFormat))) {
      errors.push('Invalid amount.');
    }
    return errors;
  },

  access: (account) => {
    const errors = [];
    if (!account || !account.account_type) {
      errors.push('Please select a role.');
    }
    return errors;
  },
};
