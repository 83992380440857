import React, { CSSProperties, useEffect, useState } from 'react';
import { throttle } from 'lodash';

import {
  getProjectRowEntityHeight,
  getProjectRowEntityTop,
} from '@float/common/components/Schedule/Window/projectRow.helpers';
import { TIME_RANGE_HEIGHT_PX } from '@float/common/components/Schedule/Window/useRowsPositionManager';
import { isFullDayTimeoff } from '@float/common/lib/timeoffs';
import { config } from '@float/libs/config';
import { CellItem } from '@float/types/cells';

const MARGIN_VERTICAL = 3;
const MARGIN_HORIZONTAL = config.isNativeTimerApp ? 14 : 3;

export const PIN_LEFT_CLSS = 'pin-target-left';
export const PIN_RIGHT_CLSS = 'pin-target-right';

export const TOOLTIP_DELAY = 1000;

function getMilestoneDimensions(
  item: CellItem<'milestone' | 'phase'>,
  dayWidth: number,
  hourHeight: number,
) {
  const { x = 0, y, w } = item;

  return {
    top: getProjectRowEntityTop({ hourHeight, y }),
    height: getProjectRowEntityHeight({ hourHeight }),
    left: x * dayWidth,
    width: w * dayWidth,
  };
}

function getTimeRangeDimensions(item: CellItem, dayWidth: number) {
  const { x = 0, w } = item;

  return {
    bottom: 0,
    height: TIME_RANGE_HEIGHT_PX,
    left: x * dayWidth,
    width: w * dayWidth,
  };
}

function getFullDayDimensions(
  item: CellItem,
  dayWidth: number,
  suvSingleDay: string | null | undefined,
) {
  const { x = 0, w } = item;

  const dimensions: CSSProperties = {
    top: 0,
    height: 'calc(100% - 1px)',
    left: x * dayWidth + 1,
    width: w * dayWidth - 1,
  };

  if (suvSingleDay) {
    dimensions.left = 0;
    dimensions.width = '100%';
  }

  return dimensions;
}

function getNormalDimensions(
  item: CellItem,
  dayWidth: number,
  hourHeight: number,
  suvSingleDay: string | null | undefined,
) {
  const { h = 0, x = 0, w = 0, y = 0, isStart, isEnd } = item;

  let top = y * hourHeight + MARGIN_VERTICAL;
  let height = h * hourHeight - MARGIN_VERTICAL;
  let left = x * dayWidth + (isStart ? MARGIN_HORIZONTAL + 1 : 1);
  let width;

  if (isStart && isEnd) {
    width = w * dayWidth - MARGIN_HORIZONTAL * 2 - 1;
  } else if (isStart || isEnd) {
    width = w * dayWidth - MARGIN_HORIZONTAL - 1;
  } else {
    width = w * dayWidth - 1;
  }

  if (item.type === 'timeoff') {
    left = x * dayWidth + 1;
    width = item.w * dayWidth - 1;
    if (item.y === 0) {
      top = 0;
      height += MARGIN_VERTICAL;
    }
  }

  if (suvSingleDay && (item.type === 'task' || item.type === 'loggedTime')) {
    if (config.isNativeTimerApp) {
      width = 'calc(100% - 32px)';
    } else {
      width = 'calc(100% - 8px)';
    }
  }
  if (suvSingleDay && item.type === 'timeoff') {
    left = 0;
    width = '100%';
  }

  return { top, height, left, width };
}

export function getDimensions(
  item: CellItem,
  dayWidth = 0,
  hourHeight = 0,
  suvSingleDay: string | null | undefined = null,
) {
  if (item.type === 'milestone' || item.type === 'phase') {
    return getMilestoneDimensions(item, dayWidth, hourHeight);
  }

  const hasTimeRangeEntity =
    'entity' in item &&
    'time_range_id' in item.entity &&
    item.entity.time_range_id;

  if (item.type === 'timeRange' || hasTimeRangeEntity) {
    return getTimeRangeDimensions(item, dayWidth);
  }

  if (
    (item.type === 'timeoff' && isFullDayTimeoff(item.entity)) ||
    item.type === 'selection'
  ) {
    return getFullDayDimensions(item, dayWidth, suvSingleDay);
  }

  return getNormalDimensions(item, dayWidth, hourHeight, suvSingleDay);
}

export function getBorderRadius(isStart?: boolean, isEnd?: boolean) {
  const left = isStart ? 3 : 0;
  const right = isEnd ? 3 : 0;
  return `${left}px ${right}px ${right}px ${left}px`;
}

type PinnedTextParams = {
  item: CellItem;
  scrollWrapperRef: React.MutableRefObject<HTMLElement | undefined>;
  wrapperRef: React.RefObject<HTMLDivElement>;
  boxRef: React.RefObject<HTMLDivElement>;
  textRef?: React.RefObject<HTMLDivElement>;
  sideColumnSize?: number;
};

export function usePinnedText({
  item,
  scrollWrapperRef,
  wrapperRef,
  boxRef,
  textRef,
  sideColumnSize = 0,
}: PinnedTextParams) {
  const [pinned, setPinned] = useState(false);

  const itemVisibleLength =
    'entity' in item &&
    'visibleLength' in item.entity &&
    item.entity.visibleLength;

  useEffect(() => {
    let hasHadInitialPin = false;

    if (!item.isStart) return;
    if (!boxRef.current || !wrapperRef.current || !scrollWrapperRef.current)
      return;

    const scrollWrapper = scrollWrapperRef.current;
    const wrapper = wrapperRef.current;
    const box = boxRef.current;
    const text = textRef?.current;

    const isSmallBox = box.offsetWidth < 91;

    function getBoxPosition() {
      const boxLeft = wrapper.offsetLeft + box.offsetLeft;
      const boxCenter = boxLeft + box.offsetWidth / 2 - (isSmallBox ? 0 : 80);
      const boxEnd = boxLeft + box.offsetWidth;

      return {
        left: boxLeft,
        center: boxCenter,
        right: boxEnd,
      };
    }

    function getScrollViewport() {
      const endOfScrollViewport =
        scrollWrapper.scrollLeft + scrollWrapper.offsetWidth;

      return {
        left: scrollWrapper.scrollLeft,
        right: endOfScrollViewport - sideColumnSize,
      };
    }

    function _setPinTextWidth(params: { shouldPinRight?: boolean } = {}) {
      const { shouldPinRight } = params;
      if (text) {
        let widthRemaining;
        const boxPos = getBoxPosition();
        if (shouldPinRight) {
          const scrollViewportPos = getScrollViewport();
          widthRemaining = scrollViewportPos.right - boxPos.left - 3;
        } else {
          widthRemaining = boxPos.right - scrollWrapper.scrollLeft - 3;
        }

        const maxWidth = Math.max(0, widthRemaining);
        text.style.maxWidth = `${maxWidth}px`;
      }
    }

    function _pinText() {
      if (!box) return;

      const boxPos = getBoxPosition();
      const stickyReference = boxPos.left;

      let shouldPinLeft;

      if (itemVisibleLength && itemVisibleLength > 1) {
        shouldPinLeft =
          scrollWrapper.scrollLeft > stickyReference + 2 && PIN_LEFT_CLSS;
      }

      if (shouldPinLeft) {
        setPinned(true);
        _setPinTextWidth();
      } else {
        setPinned(false);
        if (text) {
          text.style.maxWidth = '';
        }
      }
    }

    const pinText = config.isSafari
      ? throttle(_pinText, 50, { trailing: true })
      : _pinText;
    scrollWrapper.addEventListener('scroll', pinText);

    if (!hasHadInitialPin) {
      hasHadInitialPin = true;
      setTimeout(pinText, 100);
    }

    return () => scrollWrapper.removeEventListener('scroll', pinText);
  }, [
    item.isStart,
    wrapperRef,
    scrollWrapperRef,
    boxRef,
    textRef,
    sideColumnSize,
    itemVisibleLength,
    pinned,
  ]);

  return pinned;
}
