import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import { omit } from 'lodash';
import { compose } from 'redux';
import {
  getDepartments,
  getPeopleByDepartment,
  getSubdepartments,
  getVisibleSettings,
} from 'selectors';

import { ensureAccountsLoaded } from '@float/common/actions/accounts';
import {
  addDepartment,
  deleteDepartment,
  ensureDepartmentsLoaded,
  updateDepartment,
} from '@float/common/actions/departments';
import { ensurePeopleLoaded } from '@float/common/actions/people';
import Loader from '@float/common/components/elements/Loader';
import { useAppDispatch, useAppSelector } from '@float/common/store';
import { Row, Spacer, withConfirm, withSnackbar } from '@float/ui/deprecated';
import { Card, CardRow } from '@float/ui/deprecated/Earhart/Cards';
import { IconArrowDownRight } from '@float/ui/deprecated/Earhart/Icons';
import withOnMount from '@float/web/settingsV2/decorators/withOnMount';
import withConfirmDelete from 'components/decorators/withConfirmDelete';

import Body from '../Body';
import RestrictedMessage from '../RestrictedMessage';
import AddEntry from '../Table/AddEntry';
import { VirtualizedRow } from '../Table/Row';
import { WindowScrollerTable } from '../Tags/WindowScrollerTable';

const mapStateToProps = (state) => {
  const states = [state.departments, state.people, state.accounts];
  const isLoading = states.map((state) => state.loadState).includes('LOADING');
  const loadError = states
    .map((state) => state.loadState)
    .includes('LOAD_FAILED');

  return {
    isLoading,
    loadError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onComponentDidMount: () => {
      return Promise.all([
        dispatch(ensureAccountsLoaded()),
        dispatch(ensurePeopleLoaded()),
        dispatch(ensureDepartmentsLoaded()),
      ]);
    },
  };
};

const getDepartmentRow = (department, peopleByDepartment) => {
  const departmentPeopleCount = peopleByDepartment[department.id]?.length || 0;
  const departmentNameEncoded = encodeURIComponent(department.name);

  const departmentLink =
    departmentPeopleCount > 0 ? (
      <Link to={`/people?department=${departmentNameEncoded}`}>
        {departmentPeopleCount}
      </Link>
    ) : (
      0
    );

  return {
    key: department.name,
    entity: department,
    data: [department.name, departmentLink],
  };
};

const ParentDepartmentRow = compose(
  withOnMount,
  withSnackbar,
  withConfirm,
  withConfirmDelete,
)((props) => {
  const dispatch = useAppDispatch();
  const subDepartments = useAppSelector(getSubdepartments);
  const departments = useAppSelector(getDepartments);
  const peopleByDepartment = useAppSelector(getPeopleByDepartment);

  const [isAddingChild, setIsAddingChild] = useState(false);
  const showAddSubDepartment = (shouldShow) => {
    setIsAddingChild(shouldShow);
  };

  const parentDepartmentId = props.row.entity.id;
  const parentDepartment = departments[parentDepartmentId];
  const departmentSubDepartments = subDepartments[parentDepartmentId];

  const isFirstRenderRef = useRef(true);
  useEffect(() => {
    if (!isFirstRenderRef.current) {
      props.recomputeHeights();
    }

    isFirstRenderRef.current = false;
  }, [departmentSubDepartments.length, isAddingChild]); // eslint-disable-line react-hooks/exhaustive-deps

  const actions = {
    create: async (department) => dispatch(addDepartment(department)),
    update: async (department) =>
      dispatch(updateDepartment(department.id, omit(department, ['id']))),
    delete: async (department) => dispatch(deleteDepartment(department.id)),
  };

  const handleErr = (err) => {
    const errors = Array.isArray(err) ? err : [err];
    errors.forEach((err) => {
      props.showSnackbar(`Error: ${err.message}`);
    });
  };

  return (
    <>
      <VirtualizedRow
        key={parentDepartment.id}
        {...props}
        row={getDepartmentRow(parentDepartment, peopleByDepartment)}
        rowActions={[
          {
            id: 'addchild',
            label: t`Add Sub-department`,
            onClick: (row) => showAddSubDepartment(true),
          },
          {
            id: 'edit',
            label: t`Edit`,
            onSubmit: async (row, editedRow) => {
              const name = editedRow[0];

              // no changes detected
              if (name === parentDepartment.name) return Promise.resolve();

              const isAlreadyDefined = Object.values(departments).find(
                (department) => department.name === name,
              );
              if (isAlreadyDefined) {
                return Promise.reject({
                  message: t`"${name}" already exists.`,
                });
              }

              return actions
                .update({
                  id: parentDepartment.id,
                  name,
                })
                .catch(handleErr);
            },
          },
          {
            id: 'delete',
            label: t`Delete`,
            onClick: async (row) => {
              const departmentHasPeople =
                peopleByDepartment[parentDepartment.id];
              if (departmentHasPeople) {
                return props.confirm({
                  title: t`Delete Department`,
                  message: (
                    <span>
                      <Trans>
                        <strong>{parentDepartment.name}</strong> Department
                        cannot be deleted because there are People assigned to
                        it or its Sub-departments. To move People to another
                        Department, go to People page, select the People you
                        wish to edit, and assign them a different Department.
                      </Trans>
                    </span>
                  ),
                  hideCancel: true,
                  confirmLabel: 'OK',
                });
              }

              return props.confirmDelete({
                title: t`Delete Department`,
                message: (
                  <span>
                    <Trans>
                      Delete Department <strong>{parentDepartment.name}</strong>
                      ?
                    </Trans>
                  </span>
                ),
                twoStep: false,
                onDelete: () => {
                  actions
                    .delete(parentDepartment)
                    .then(() =>
                      props.showSnackbar(`${parentDepartment.name} deleted.`),
                    )
                    .catch(handleErr);
                },
              });
            },
          },
        ]}
      />
      {isAddingChild && (
        <>
          <Spacer size={8} />
          <Row>
            <Spacer size={12} />
            <VirtualizedRow
              forceEditMode
              key={`${parentDepartment}-createSubDep`}
              {...props}
              row={{
                data: [''],
                editConfig: [
                  {
                    type: 'text',
                    placeholder: t`Enter Sub-department name`,
                    maxLength: 64,
                  },
                ],
              }}
              className="active"
              icon={<IconArrowDownRight size="24" />}
              rowActions={[
                {
                  id: 'edit',
                  label: t`Edit`,
                  config: {
                    confirmLabel: t`Add`,
                    disableDefaultSnackbar: true,
                    disableDefaultValidation: true,
                  },
                  onSubmit: async (row, editedRow) => {
                    const subDepartmentName = editedRow[0];
                    if (subDepartmentName.length === 0) {
                      return props.showError(
                        t`Error: Cannot create Department without name.`,
                      );
                    }

                    const isAlreadyDefined = Object.values(departments).find(
                      (department) => department.name === subDepartmentName,
                    );
                    if (isAlreadyDefined) {
                      return props.showError(
                        t`Error: "${subDepartmentName}" already exists.`,
                      );
                    }

                    return actions
                      .create({
                        name: subDepartmentName,
                        parent_id: parentDepartment.id,
                      })
                      .then(() => {
                        showAddSubDepartment(false);
                        props.showSnackbar(`${subDepartmentName} created.`);
                      })
                      .catch(handleErr);
                  },
                  onCancel: () => {
                    showAddSubDepartment(false);
                  },
                },
              ]}
            />
          </Row>
        </>
      )}
      {departmentSubDepartments.map((subDepartmentId) => {
        const subDepartment = departments[subDepartmentId];
        const subDepartmentName = subDepartment.name;

        return (
          <>
            <Spacer size={8} />
            <Row>
              <Spacer size={12} />
              <VirtualizedRow
                {...props}
                key={subDepartment.id}
                row={getDepartmentRow(subDepartment, peopleByDepartment)}
                icon={<IconArrowDownRight size="24" />}
                rowActions={[
                  {
                    id: 'edit',
                    label: t`Edit`,
                    onSubmit: async (row, editedRow) => {
                      const nextDepartmentName = editedRow[0];

                      // no changes detected
                      if (row.data[0] === nextDepartmentName)
                        return Promise.resolve();

                      const isAlreadyDefined = Object.values(departments).find(
                        (department) => department.name === nextDepartmentName,
                      );

                      if (isAlreadyDefined) {
                        return Promise.reject({
                          message: t`"${nextDepartmentName}" already exists.`,
                        });
                      }

                      return actions
                        .update({
                          id: subDepartment.id,
                          name: nextDepartmentName,
                        })
                        .catch(handleErr);
                    },
                  },
                  {
                    id: 'delete',
                    label: t`Delete`,
                    onClick: async (row) => {
                      const departmentHasPeople =
                        peopleByDepartment[subDepartment.id];
                      if (departmentHasPeople) {
                        return props.confirm({
                          title: t`Delete Sub-department`,
                          message: (
                            <span>
                              <Trans>
                                <strong>{subDepartmentName}</strong>{' '}
                                Sub-department cannot be deleted because there
                                are People assigned to it. To move People to
                                another Department, go to the People page,
                                select the People you wish to edit, and assign
                                them a different Department.
                              </Trans>
                            </span>
                          ),
                          hideCancel: true,
                          confirmLabel: t`OK`,
                        });
                      }

                      return props.confirmDelete({
                        title: t`Delete Sub-department`,
                        message: (
                          <span>
                            <Trans>
                              Delete Sub-department{' '}
                              <strong>{subDepartmentName}</strong>?
                            </Trans>
                          </span>
                        ),
                        twoStep: false,
                        onDelete: () => {
                          actions
                            .delete(subDepartment)
                            .then(() => {
                              props.showSnackbar(
                                t`${subDepartmentName} deleted.`,
                              );
                            })
                            .catch(handleErr);
                        },
                      });
                    },
                  },
                ]}
              />
            </Row>
          </>
        );
      })}
    </>
  );
});

const AddParentDepartmentField = () => {
  const departments = useAppSelector(getDepartments);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <AddEntry
      placeholder={t`Add a Department`}
      secondaryText={t`64 character Max`}
      maxLength={64}
      isLoading={isLoading}
      onSubmit={(name) => {
        const isAlreadyDefined = Object.values(departments).find(
          (department) => department.name === name,
        );
        if (isAlreadyDefined) {
          return Promise.reject(t`Error: "${name}" already exists.`);
        }

        setIsLoading(true);
        return dispatch(addDepartment({ name })).finally(() =>
          setIsLoading(false),
        );
      }}
    />
  );
};

const Departments = (props) => {
  const userCanSee = useAppSelector(getVisibleSettings);
  const departments = useAppSelector(getDepartments);
  const peopleByDepartment = useAppSelector(getPeopleByDepartment);

  const rows = Object.values(departments)
    .filter((department) => !department.parent_id)
    .map((department) => getDepartmentRow(department, peopleByDepartment));

  if (!userCanSee.departments) {
    return <RestrictedMessage />;
  }

  return (
    <Body
      header={t`Departments`}
      subheader={t`Manage the Departments you group your team into. Departments with no People can be removed.`}
    >
      {props.isLoading ? (
        <Card>
          <CardRow>
            <Loader style={{ position: 'relative', left: 0, marginLeft: 0 }} />
          </CardRow>
        </Card>
      ) : (
        <Card>
          <CardRow>
            <AddParentDepartmentField />
          </CardRow>
          <CardRow>
            <WindowScrollerTable
              windowScrollRef={props.pageBodyRef}
              emptyText={t`There are no Departments.`}
              rows={rows}
              tableHeaders={[
                { label: t`Departments`, key: 'name' },
                { label: t`People`, key: 'people_count' },
              ]}
              columnsWidth={['60%', '10%']}
              renderRow={(row, rowProps) => {
                return <ParentDepartmentRow {...rowProps} />;
              }}
            />
          </CardRow>
        </Card>
      )}
    </Body>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(compose(withOnMount)(Departments));
