import { logger } from '@float/libs/logger';

import { FloatStorageKey } from './Storage.constants';
import { FloatStorageClientAdapter } from './Storage.types';

export class FloatStorage {
  private _clientAdapter: FloatStorageClientAdapter | undefined;

  private get clientAdapter(): FloatStorageClientAdapter {
    if (!this._clientAdapter) {
      throw new Error(
        'The Storage instance is not initialized, you need to call `initialize` with the platform appropriate StorageClientAdapter first',
      );
    }

    return this._clientAdapter;
  }

  public async initialize(clientAdapter: FloatStorageClientAdapter) {
    if (this._clientAdapter) {
      logger.warn('The Storage instance has already been initialized');
      return;
    }

    this._clientAdapter = clientAdapter;
  }

  public async getItem(key: FloatStorageKey): Promise<string | null> {
    return this.clientAdapter.getItem(key);
  }

  /**
   * Get an item from the storage using an UNSAFE arbitrary key
   *
   * @deprecated
   * Use `getItem` instead
   */
  public async UNSAFE_getItem(key: string): Promise<string | null> {
    return this.clientAdapter.getItem(key as FloatStorageKey);
  }

  public async setItem(key: FloatStorageKey, value: string): Promise<void> {
    return this.clientAdapter.setItem(key, value);
  }

  /**
   * Set an item in the storage using an UNSAFE arbitrary key
   *
   * @deprecated
   * Use `setItem` instead. If you need to save arbitrary keys, serialize a JSON
   * object with the arbitrary keys and store in under a FloatStorageKey.
   */
  public async UNSAFE_setItem(key: string, value: string): Promise<void> {
    return this.clientAdapter.setItem(key as FloatStorageKey, value);
  }

  public async removeItem(key: FloatStorageKey): Promise<void> {
    return this.clientAdapter.removeItem(key);
  }

  /**
   * Remove an item from the storage using an UNSAFE arbitrary key
   *
   * @deprecated
   * Use `removeItem` instead
   */
  public async UNSAFE_removeItem(key: string): Promise<void> {
    return this.clientAdapter.removeItem(key as FloatStorageKey);
  }

  public async clear(): Promise<void> {
    return this.clientAdapter.clear();
  }
}
