import React, {
  MutableRefObject,
  ReactNode,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { useLayoutEffectOnMount } from '@float/libs/hooks/useOnMount';

function defaultState(filters: unknown[]): State {
  return {
    visible: filters.length,
    maxThreshold: Infinity,
  };
}

type Params<E extends HTMLElement> = {
  containerRef: MutableRefObject<E | null>;
  filters: unknown[];
  padding?: number;
};

type State = {
  visible: number;
  maxThreshold: number;
};

type GuardThresholds = 'min' | 'middle' | 'max';

// An hook to handle the "Show more" style tag components
// Using a resizeGuard it checks the available space to guess
// if we might need to hide more tags or show more of them.
//
// This hook is made with some assumptions in mind:
//   1. All the tags must be the containerRef direct childs
//   2. containerRef must contain only the tags elements
//   3. containerRef will overflow if the content exceeds the available space
//   4. renderHiddenFiltersMeasureContainer must be called with the hidden filters JSX
//      and the return value rendered as part of the component
//
// This hook is meant to be used only by the SearchFilterTokens component and reusing it
// on other places is not a great idea, since the logic is quite complex with a few edge cases.
export function useVisibleTokens<E extends HTMLElement>({
  containerRef,
  filters,
  padding = 0,
}: Params<E>) {
  const [state, setState] = useState(() => defaultState(filters));
  const hiddenFiltersRef = useRef<HTMLDivElement | null>(null);
  const thresholdRef = useRef<GuardThresholds | null>(null);
  const sizeGuardWidth = useRef(0);

  function updateVisibleTokens() {
    // maxThreshold is the value we use to track when there might be anough space to render another filter
    const { visible, maxThreshold } = getVisibleTokens(
      containerRef.current,
      hiddenFiltersRef.current,
      padding,
      sizeGuardWidth.current,
    );

    if (visible !== state.visible) setState({ visible, maxThreshold });
  }

  useLayoutEffect(() => {
    updateVisibleTokens();

    // Recalculate the visible filters when the filters are changing
  }, [filters]);

  function handleSizeChange(width: number) {
    sizeGuardWidth.current = width;
  }

  function handleThresholdChange(threshold: GuardThresholds) {
    thresholdRef.current = threshold;

    // When we move to the min threshold we recompute the visible tokens
    // only if we are showing some of them.
    // If none are visible there is nothing more to hide
    if (threshold === 'min' && state.visible > 0) {
      updateVisibleTokens();
    } else if (threshold === 'max') {
      updateVisibleTokens();
    }
  }

  const resizeGuard = (
    <SizeGuard
      min={0}
      max={state.maxThreshold}
      onSizeChange={handleSizeChange}
      onThresholdChange={handleThresholdChange}
    />
  );

  // We render the hidden filters outside the viewport to measure their size
  // and see if there is enough space to make them visible
  const renderHiddenFiltersMeasureContainer = (hiddenFilters: ReactNode) => (
    <div
      ref={hiddenFiltersRef}
      style={{
        position: 'fixed',
        top: -999,
        display: 'flex',
        flexWrap: 'nowrap',
      }}
    >
      {hiddenFilters}
    </div>
  );

  return {
    visible: state.visible,
    resizeGuard,
    renderHiddenFiltersMeasureContainer,
  };
}

function getVisibleTokens(
  el: HTMLElement | null,
  hiddenFiltersContainer: HTMLElement | null,
  padding: number,
  freeSpace: number,
): State {
  if (!el) return { visible: 0, maxThreshold: Infinity };

  const hiddenFilters = hiddenFiltersContainer
    ? Array.from(hiddenFiltersContainer.children)
    : [];

  const rect = el.getBoundingClientRect();

  let availableSpace = rect.width + freeSpace;

  let visible = 0;

  for (const child of Array.from(el.children).concat(hiddenFilters)) {
    const childRect = child.getBoundingClientRect();

    if (childRect.width + padding <= availableSpace) {
      visible++;
      availableSpace -= childRect.width;
    } else {
      return { visible, maxThreshold: childRect.width + padding };
    }
  }

  return { visible, maxThreshold: Infinity };
}

function SizeGuard(props: {
  min: number;
  max: number;
  onThresholdChange: (threshold: GuardThresholds) => void;
  onSizeChange: (width: number) => void;
}) {
  const ref = useRef<HTMLDivElement>(null);

  // Recreating a ResizeObserver might be slow so
  // we are using refs to update the informations inside
  // the ResizeObserver without re-triggering the effect
  const onThresholdChangeRef = useRef(props.onThresholdChange);
  const onSizeChangeRef = useRef(props.onSizeChange);
  const minRef = useRef(props.min);
  const maxRef = useRef(props.max);

  useLayoutEffect(() => {
    onThresholdChangeRef.current = props.onThresholdChange;
    onSizeChangeRef.current = props.onSizeChange;
    minRef.current = props.min;
    maxRef.current = props.max;
  });

  const thresholdRef = useRef<GuardThresholds | null>(null);

  // We want to create the ResizeObserver only on mount.
  // Any data/callback needs to be passed via ref.
  useLayoutEffectOnMount(() => {
    const el = ref.current;

    if (!el) return;

    function triggerChange(threshold: GuardThresholds, width: number) {
      onSizeChangeRef.current(width);

      if (threshold !== thresholdRef.current) {
        onThresholdChangeRef.current(threshold);
        thresholdRef.current = threshold;
      }
    }

    const observer = new ResizeObserver((entries) => {
      const { width } = entries[0].contentRect;

      if (width <= minRef.current) {
        triggerChange('min', width);
      } else if (width >= maxRef.current) {
        triggerChange('max', width);
      } else {
        triggerChange('middle', width);
      }
    });

    observer.observe(el);

    return () => {
      observer.unobserve(el);
    };
  });

  return (
    <div
      ref={ref}
      style={{
        flexGrow: 999,
      }}
    />
  );
}
