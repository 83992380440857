import React from 'react';
import { useController } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';
import cn from 'classnames';

import { Card } from '@float/ui/deprecated/Earhart/Cards';
import { OnOffToggle } from '@float/ui/deprecated/Earhart/Toggles/OnOffToggle';
import {
  adaptReactHookFormFieldError,
  Input,
} from '@float/ui/deprecated/Input';

import { P } from '../../styles';
import { LinearFormPayload } from '../hooks/useLinearIntegrationFormControls';
import { getFormControlsDisabledStates } from '../LinearCycleIntegration.helpers';
import { LastSyncedAt } from './LastSyncedAt';
import { SyncButton } from './SyncButton';

import * as integrationStyles from '../../shared/styles.css';
import * as styles from '../styles.css';

type LinearSectionViewProps = {
  hasConfig: boolean;
  isEnabled: boolean;
  isLoading: boolean;
  isValid: boolean;
  lastSyncTime?: string;
  lastSyncUserName?: string;
  onSyncClick: () => void;
  onToggle: () => void;
};

export const LinearIntegrationForm: React.FC<LinearSectionViewProps> = ({
  isEnabled,
  isLoading,
  isValid,
  hasConfig,
  lastSyncTime,
  lastSyncUserName,
  onSyncClick,
  onToggle,
}) => {
  const { field, fieldState } = useController<LinearFormPayload>({
    name: 'feedUrl',
    rules: { required: false },
  });
  const { ref: feedUrlRef, ...feedUrlRegister } = field;
  const { isInputDisabled, isSyncButtonDisabled, isToggleDisabled } =
    getFormControlsDisabledStates({ isLoading, field, hasConfig, isValid });

  return (
    <Card>
      <div className={integrationStyles.header}>
        <h4 className={integrationStyles.headerText}>
          <Trans>Linear cycle planning</Trans>
        </h4>

        <OnOffToggle
          disabled={isToggleDisabled}
          isOn={isEnabled}
          onChange={onToggle}
        />
      </div>

      <P className={integrationStyles.subsection}>
        <Trans>
          Sync your Linear team's cycle calendar to view and plan your team work
          on your Float schedule.
        </Trans>
      </P>

      <div className={integrationStyles.subsection}>
        <Input
          {...feedUrlRegister}
          disabled={isInputDisabled}
          errors={adaptReactHookFormFieldError(fieldState.error)}
          inputRef={feedUrlRef}
          label={t`Cycle feed URL`}
          placeholder={t`Input URL here`}
        />
      </div>

      <div
        className={cn([
          integrationStyles.subsection,
          styles.syncButtonSubsection,
        ])}
      >
        <SyncButton
          disabled={isSyncButtonDisabled}
          hasSyncedAtLeastOnce={Boolean(lastSyncTime)}
          isUrlDefined={Boolean(field.value)}
          onClick={onSyncClick}
        />

        <LastSyncedAt
          lastSyncTime={lastSyncTime}
          lastSyncUserName={lastSyncUserName}
        />
      </div>
    </Card>
  );
};
