import React from 'react';

export default function ({ size = '24', color, style }) {
  return (
    <svg
      style={style}
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M12.5 8.75V12L14.75 14.25"
        stroke={color ?? '#8290AA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M19.75 12C19.75 16.0041 16.5041 19.25 12.5 19.25C8.49594 19.25 5.25 16.0041 5.25 12C5.25 7.99594 8.49594 4.75 12.5 4.75C16.5041 4.75 19.75 7.99594 19.75 12Z"
        stroke={color ?? '#8290AA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M19.25 4.75L19.75 5.25"
        stroke={color ?? '#8290AA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M5.8 4.75L5.3 5.25"
        stroke={color ?? '#8290AA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
