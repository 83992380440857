export default function determineActivityItemsSeen({
  items = [],
  allSeenItems,
  currentUserAccountId,
}) {
  let allSeen = true;
  const processedItems = items.map((item) => {
    if (allSeenItems) {
      const byAuthUser =
        `${item.actioned_by.account_id}` === `${currentUserAccountId}`;
      const byFetched = !!allSeenItems[item.activity_id];
      item.seenLocalStorage = byAuthUser || byFetched;
      allSeen = allSeen && item.seenLocalStorage;
    }
    return item;
  });
  return {
    items: processedItems,
    allSeen,
  };
}
