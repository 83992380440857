import {
  sortByDateAndName,
  sortByOffsetAndName,
} from '@float/common/lib/itemSort';
import { Milestone } from '@float/types/milestone';

import { createEmptyMilestoneRecord } from '../createRowEntities';

export function getProjectMilestonesFromTemplateMilestones(
  milestones: Milestone[] | undefined,
  includeOffsets: boolean,
): Milestone[] {
  if (!milestones?.length) return [];

  const result: Milestone[] = [];
  for (const milestone of milestones) {
    if (!milestone.name) continue;

    const item: Milestone = {
      ...milestone,
      ...createEmptyMilestoneRecord(),
      name: milestone.name,
    };

    if (includeOffsets) {
      item.date = '';
      item.end_date = '';
      item.duration = milestone.duration;
      item.parent_offset = milestone.parent_offset;
      item.template_milestone_id = `${milestone.milestone_id}`;
    }

    result.push(item);
  }

  return includeOffsets
    ? sortByOffsetAndName(result, 'name')
    : sortByDateAndName(result, 'date', 'name');
}
