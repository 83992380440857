import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import DateRangePicker from '@float/common/components/DatePicker/DateRangePicker';
import InputHandler from '@float/common/components/DatePicker/InputHandler';
import KeyHandler from '@float/common/components/DatePicker/KeyHandler';
import { moment } from '@float/libs/moment';
import { DropdownSelect } from '@float/ui/deprecated/DropdownSelect';
import { FieldLabel } from '@float/ui/deprecated/Label';
import { Popover } from '@float/ui/deprecated/Tooltip/Popover';

import * as Animation from '../Earhart/Animation';
import * as Colors from '../Earhart/Colors';
import * as Icons from '../Earhart/Icons';
import * as Typography from '../Earhart/Typography';
import { getNextPrevRange } from './getNextPrevRange';

export const IconButton = styled.div`
  transform: rotate(${({ left }) => (left ? 90 : -90)}deg);
  cursor: pointer;
  height: 34px;
`;

const PaginationArrowContainer = styled.div`
  transform: rotate(${({ rotate }) => rotate}deg);
  left: ${({ left }) => left}px;
  padding-top: 14px;
  cursor: pointer;
  display: block;
  top: 0;
  position: absolute;
  white-space: nowrap;
  z-index: 1;
  outline: none;

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    cursor: default;
    opacity: 0.25;
  `};
`;

const TooltipToggleArrow = styled.div`
  display: flex;

  cursor: pointer;

  svg {
    --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Primary};
  }
`;

const leftIconStyle = {
  border: '1px solid #e2e2e2',
  padding: '4px',
  borderRadius: '0 0 4px 4px',
};

const rightIconStyle = {
  border: '1px solid #e2e2e2',
  padding: '4px',
  borderRadius: '0 0 4px 4px',
};

export function PaginationArrow({ left, isEndCalendar, onClick, disabled }) {
  let leftOffset = 11;
  if (!left) leftOffset += 195;
  if (isEndCalendar) leftOffset += 269;
  return (
    <PaginationArrowContainer
      left={leftOffset}
      rotate={left ? 90 : -90}
      disabled={disabled}
      onClick={onClick}
    >
      <Icons.IconArrowDown />
    </PaginationArrowContainer>
  );
}

const RangeSelectorLabel = styled.span`
  ${Typography.Label14.R400};

  margin-right: 5px;
`;

const RangeSelectorContainer = styled.div`
  display: flex;
  align-items: center;

  padding-bottom: 15px;

  border-bottom: 1px solid ${({ theme }) => theme.black12};
`;

const RangeLabelContainer = styled.div``;

class DateRangePickerWrapper extends React.Component {
  static defaultProps = {
    maximumDays: undefined,
  };

  state = {
    maximumDate: undefined,
    minimumDate: undefined,
  };

  onSelectStart = (date) => {
    this.setState({
      maximumDate: date.clone().add(this.props.maximumDays, 'days').toDate(),
      minimumDate: date
        .clone()
        .subtract(this.props.maximumDays, 'days')
        .toDate(),
    });
  };

  render() {
    return (
      <div style={{ width: '510px' }} ref={this.props.refPicker}>
        <RangeSelectorContainer>
          <RangeSelectorLabel>Date range:</RangeSelectorLabel>
          {this.props.rangeSelector}
        </RangeSelectorContainer>
        <DateRangePicker
          firstOfWeek={this.props.firstOfWeek || 0}
          numberOfCalendars={2}
          paginationArrowComponent={PaginationArrow}
          selectionType="range"
          singleDateRange={true}
          maximumDate={this.state.maximumDate}
          minimumDate={this.state.minimumDate}
          value={this.props.value}
          onSelect={this.props.onSelect}
          onSelectStart={
            this.props.maximumDays ? this.onSelectStart : undefined
          }
        />
      </div>
    );
  }
}

const DateInputWrapper = styled.div`
  ${Typography.Label16.SB600};

  position: relative;

  display: flex;
  align-items: center;

  color: ${Colors.FIN.Lt.Emphasis.High};

  > * {
    position: relative;

    z-index: 1;
  }

  &:before {
    position: absolute;

    top: -5px;
    left: -8px;

    // 10px and 9px harcoded based on what looks visually right
    width: calc(100% + 10px);
    height: calc(100% + 9px);

    content: '';

    border-width: 1px;
    border-style: solid;
    border-color: ${Colors.FIN.Lt.Emphasis.Primary};
    border-radius: 4px;

    background-color: ${Colors.Primary.Flue.Light[3]};

    opacity: 0;

    transition-property: opacity, background-color;
    transition-duration: ${Animation.Settings.Duration.Short};
    transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

    z-index: 0;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }

  &:active,
  &.active {
    &:before {
      opacity: 1;
      background-color: ${Colors.Primary.Flue.Light[5]};
    }
  }

  input {
    max-width: 116px;
    height: 100%;

    border: none;

    text-align: center;

    color: ${Colors.FIN.Lt.Emphasis.Primary};
    background-color: transparent;

    box-sizing: border-box;

    ${(p) => p.noBorder && `border-color: transparent !important;`};
  }
`;

const DateInputContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DateHyphenGap = styled.div`
  text-align: center;
  pointer-events: none;
`;

export class DateRangePickerWithInput extends React.Component {
  constructor(props) {
    super(props);
    this.dateInputWrapperRef = React.createRef();
    this.tooltipArrowRef = React.createRef();
    this.startKeyHandler = KeyHandler(
      'start',
      'startInputValue',
      this.isValidRange,
    ).bind(this);
    this.endKeyHandler = KeyHandler(
      'end',
      'endInputValue',
      this.isValidRange,
    ).bind(this);
    this.startInputHandler = InputHandler(
      'start',
      'startInputValue',
      this.isValidRange,
    ).bind(this);
    this.endInputHandler = InputHandler(
      'end',
      'endInputValue',
      this.isValidRange,
    ).bind(this);
    this.state = this.defaultState();
  }

  defaultState() {
    return {
      start: this.props.value.start,
      startInputValue: this.format(this.props.value.start),
      end: this.props.value.end,
      endInputValue: this.format(this.props.value.end),
      rangeMode: null,
      localChanges: false,
    };
  }

  checkNewRangeMode(prevState, newState) {
    const rangeChanged =
      prevState.start != newState.start || prevState.end != newState.end;

    if (rangeChanged) {
      // If no matches, we set rangeMode to custom

      const rangeOpts = this.buildRangeOpts();
      let matchedRangeOpt = 'custom';
      rangeOpts.forEach((opt) => {
        if (!opt.start) return;
        const startDiff = newState.start
          .startOf('day')
          .diff(opt.start().startOf('day'));
        const startEqual = startDiff === 0;
        const endDiff = newState.end
          .startOf('day')
          .diff(opt.end().startOf('day'));
        const endEqual = endDiff === 0;
        if (startEqual && endEqual) {
          matchedRangeOpt = opt.value;
        }
      });
      this.setState({ rangeMode: matchedRangeOpt, localChanges: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.value.isSame(this.props.value)) {
      this.setState(this.defaultState());
      return;
    }
    this.checkNewRangeMode(prevState, this.state);
  }

  format = (momentObj) => {
    return momentObj.format('DD MMM YYYY');
  };

  isValidRange = (attr, value) => {
    const start = attr === 'start' ? value : this.state.start;
    const end = attr === 'end' ? value : this.state.end;
    return start.diff(end) < 0;
  };

  submit = () => {
    this.props.onChange(this.state.start, this.state.end, this.state.rangeMode);
  };

  momentRange = () => {
    const { start, end } = this.state;

    return moment.range(
      start.clone().startOf('day'),
      end.clone().startOf('day'),
    );
  };

  setNewRange = (start, end, callback) => {
    this.setState(
      {
        start,
        startInputValue: this.format(start),
        end,
        endInputValue: this.format(end),
      },
      () => {
        setTimeout(callback, 10);
      },
    );
  };

  handleSelect = ({ start, end }) => {
    this.setNewRange(start, end, this.hide);
  };

  buildRangeOpts = () => {
    const { rangeOpts } = this.props;
    let { rangeMode } = this.props;
    const builtRangeOpts = [...rangeOpts];

    // Changed locally while DatePicker open
    if (this.state.rangeMode) {
      rangeMode = this.state.rangeMode;
    }

    if (!rangeMode || rangeMode === 'custom') {
      builtRangeOpts.unshift({ value: 'custom', label: 'Custom' });
    }

    return builtRangeOpts;
  };

  handlePrevRange = () => {
    const { newStart, newEnd } = getNextPrevRange('backward', {
      ...this.state,
    });
    this.setNewRange(newStart, newEnd, this.submit);
  };

  handleNextRange = () => {
    const { newStart, newEnd } = getNextPrevRange('forward', {
      ...this.state,
    });
    this.setNewRange(newStart, newEnd, this.submit);
  };

  showBothInputs = () => {
    // Popover open, always show dual inputs
    if (this.state.tipShown) return true;
    // If user has selected one day (same date for both inputs)
    if (
      this.state.start.startOf('day').diff(this.state.end.startOf('day')) === 0
    ) {
      return false;
    }
    return true;
  };

  setToggleArrowRef = (el) => {
    this.toggleArrow = el;
  };

  toggle = () => {
    if (this.state.tipVisible) {
      this.hide();
    } else {
      this.show();
    }
  };

  show = () => {
    this.setState({ tipVisible: true, tipShown: true });
  };

  hide = () => {
    this.setState({
      tipVisible: false,
      tipShown: false,
      // Ensure inputs match last valid dates
      startInputValue: this.format(this.state.start),
      endInputValue: this.format(this.state.end),
    });

    if (this.state.localChanges) {
      this.submit();
    }
  };

  handlePopoverTriggerClick = (evt) => {
    // Block the popover onOpenChange trigger
    evt.preventDefault();

    const toggleBtn = this.tooltipArrowRef.current;

    if (toggleBtn.contains(evt.target)) {
      this.toggle();
    } else {
      this.show();
    }
  };

  render() {
    const {
      label,
      hidePrevNext,
      style,
      maximumDays,
      component,
      fullWidth,
      placement = 'bottom-start',
      tippyClassName,
    } = this.props;
    let { rangeMode } = this.props;
    if (this.state.rangeMode) {
      rangeMode = this.state.rangeMode;
    }
    if (!rangeMode) rangeMode = 'custom';
    const rangeOpts = this.buildRangeOpts();

    const foundRangeOpt =
      rangeOpts.find((opt) => opt.value === rangeMode) || {};
    const rangeModeLabel = foundRangeOpt.label;
    const inner = component || (
      <DateInputWrapper
        noBorder={!this.state.tipVisible}
        className={classNames({ active: this.state.tipVisible })}
      >
        <RangeLabelContainer>{rangeModeLabel}:</RangeLabelContainer>
        <input
          aria-label="Start"
          type="text"
          value={this.state.startInputValue}
          onKeyDown={this.startKeyHandler}
          onChange={this.startInputHandler}
          onFocus={this.show}
          data-lpignore
        />
        {this.showBothInputs() && (
          <>
            {' '}
            <DateHyphenGap>-</DateHyphenGap>
            <input
              aria-label="End"
              type="text"
              value={this.state.endInputValue}
              onKeyDown={this.endKeyHandler}
              onChange={this.endInputHandler}
              onFocus={this.show}
              data-lpignore
            />
          </>
        )}
        <button
          aria-label="Toggle date picker"
          ref={this.tooltipArrowRef}
          onClick={this.hide}
        >
          <TooltipToggleArrow ref={this.setToggleArrowRef}>
            <Icons.IconArrowDown />
          </TooltipToggleArrow>
        </button>
      </DateInputWrapper>
    );

    const dropdown = (
      <Popover
        className={tippyClassName}
        placement={placement}
        maxWidth="600px"
        distance={6}
        open={this.state.tipVisible}
        onOpenAutoFocus={(evt) => {
          evt.preventDefault();
        }}
        onOpenChange={(open) => {
          if (open) {
            this.show();
          } else {
            this.hide();
          }
        }}
        content={
          <DateRangePickerWrapper
            refPicker={this.dateInputWrapperRef}
            rangeSelector={
              <DropdownSelect
                value={rangeMode}
                align={'bottom'}
                options={rangeOpts}
                onChange={(rangeOpt) => {
                  if (rangeOpt.start) {
                    this.setNewRange(
                      rangeOpt.start(),
                      rangeOpt.end(),
                      this.hide,
                    );
                  } else {
                    this.hide();
                  }
                }}
              />
            }
            maximumDays={maximumDays}
            value={this.momentRange()}
            onSelect={this.handleSelect}
          />
        }
      >
        <div
          data-testid="date-range-picker-trigger"
          style={{
            outline: 'none',
            margin: '0 auto',
            width: fullWidth ? '100%' : undefined,
          }}
          onClick={this.handlePopoverTriggerClick}
        >
          {inner}
        </div>
      </Popover>
    );

    if (component) {
      return dropdown;
    }

    return (
      <DateInputContainer style={style}>
        {label && <FieldLabel>{label}</FieldLabel>}
        {!hidePrevNext && (
          <>
            <IconButton left onClick={this.handlePrevRange}>
              <Icons.IconArrowDown style={leftIconStyle} />
            </IconButton>
            <IconButton
              style={{ marginRight: 14 }}
              onClick={this.handleNextRange}
            >
              <Icons.IconArrowDown style={rightIconStyle} />
            </IconButton>
          </>
        )}
        {dropdown}
      </DateInputContainer>
    );
  }
}
export default DateRangePickerWithInput;
