import React from 'react';
import { useWatch } from 'react-hook-form';

import { determineEntityStatus } from '@float/common/actions/lib/determineEntityStatus';
import { Project } from '@float/types/project';
import { getEntityStatusLabel } from '@float/ui/helpers/label/entityStatusLabel';

import { StatusTag } from '../StatusTag';

import * as styles from './styles.css';

export type EntityStatusTagProps = {
  className?: string;
  entity: 'project' | 'phase';
};

export function EntityStatusTag(props: EntityStatusTagProps) {
  // We'll be deprecating the tentative field soon
  const tentative = useWatch({ name: `${props.entity}.tentative` });
  const status = useWatch({ name: `${props.entity}.status` });

  const entityStatus = determineEntityStatus({ tentative, status } as Project);
  const label = getEntityStatusLabel(entityStatus);

  return (
    <StatusTag
      className={styles.entityStatusTag({
        status: entityStatus,
      })}
    >
      {label}
    </StatusTag>
  );
}
