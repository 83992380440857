import { ModalClose } from './components/ModalClose';
import { ModalDescription } from './components/ModalDescription';
import { ModalButtonGroup } from './components/ModalLayout/ModalButtonGroup';
import { ModalFooter } from './components/ModalLayout/ModalFooter';
import { ModalHeader } from './components/ModalLayout/ModalHeader';
import { ModalList, ModalListItem } from './components/ModalList';
import { ModalParagraph } from './components/ModalParagraph';
import { ModalRoot } from './components/ModalRoot';
import { ModalTitle } from './components/ModalTitle';

export const Modal = {
  Close: ModalClose,
  Description: ModalDescription,
  Layout: {
    ButtonGroup: ModalButtonGroup,
    Footer: ModalFooter,
    Header: ModalHeader,
  },
  List: ModalList,
  ListItem: ModalListItem,
  P: ModalParagraph,
  Root: ModalRoot,
  Title: ModalTitle,
};

export type * from './components/ModalClose';
export type * from './components/ModalDescription';
export type * from './components/ModalLayout/ModalFooter';
export type * from './components/ModalLayout/ModalHeader';
export type { ModalProps } from './components/ModalRoot';
export type * from './components/ModalTitle';
