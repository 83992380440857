import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m4.75 19.25 4.25-1 9.563-9.563a2.298 2.298 0 1 0-3.25-3.25L5.75 15l-1 4.25Z"
    />
  </BaseIcon>
);
