import React from 'react';
import { t, Trans } from '@lingui/macro';

import { Tooltip } from '@float/ui/components/Tooltip';
import { TextToggle } from '@float/ui/deprecated/Earhart/Toggles/TextToggle';
import { IconClock } from '@float/ui/icons/essentials/IconClock';
import { IconPercentage } from '@float/ui/icons/essentials/IconPercentage';
import type { TextToggleOption } from '@float/ui/deprecated/Earhart/Toggles/TextToggle';

import * as styles from './InsightsControlUnit.css';

export type InsightsUnit = 'hours' | 'percentage';

type ControlOption = {
  value: InsightsUnit;
  label: string;
  labelElement: React.ReactNode;
  hasTooltip: boolean;
};

const getOptions = (): ControlOption[] => [
  {
    value: 'hours',
    label: t`Hours`,
    labelElement: (
      <Tooltip placement="bottom" content={t`Hours`}>
        <div className={styles.iconWrapper} aria-label={t`Hours`}>
          <IconClock />
        </div>
      </Tooltip>
    ),
    hasTooltip: true,
  },
  {
    value: 'percentage',
    label: t`Percentage`,
    labelElement: (
      <Tooltip placement="bottom" content={t`Percentage`}>
        <div className={styles.iconWrapper} aria-label={t`Percentage`}>
          <IconPercentage />
        </div>
      </Tooltip>
    ),
    hasTooltip: true,
  },
];

export type InsightsControlUnitProps = {
  value: InsightsUnit;
  onChange: (value: InsightsUnit) => void;
};

export const InsightsControlUnit = (props: InsightsControlUnitProps) => {
  const { value, onChange } = props;

  const handleToggleChange = (
    option: TextToggleOption<InsightsUnit>,
    _name: string,
  ) => {
    onChange(option.value);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>
        <Trans>Show utilization in</Trans>
      </div>

      <div className={styles.toggleWrapper}>
        <TextToggle<InsightsUnit>
          name="timeRangeInsightsUnit"
          variant="tiny"
          value={value}
          options={getOptions()}
          toggle={false}
          onChange={handleToggleChange}
        />
      </div>
    </div>
  );
};
