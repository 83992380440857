import { useCallback, useMemo } from 'react';
import { FieldPath, FieldValue } from 'react-hook-form';

import { GroupedOptions } from '@float/types/options';

import { ProjectFormData } from '../../types';

export function useGetSelectedValueFromComboBox<
  N extends FieldPath<ProjectFormData>,
>(
  readOnly: boolean,
  options?: { value: FieldValue<ProjectFormData>[N]; label: string }[],
  groupedOptions?: GroupedOptions<FieldValue<ProjectFormData>[N]>[],
) {
  const flattenedOptions = useMemo(() => {
    if (options && !groupedOptions) {
      return options;
    }
    return groupedOptions?.reduce(
      (acc, curr) => {
        return acc.concat(curr.options);
      },
      [] as GroupedOptions<FieldValue<ProjectFormData>[N]>['options'],
    );
  }, [options, groupedOptions]);

  const getSelectedOption = useCallback(
    (value: string | number) => {
      if (!readOnly) return undefined;
      const option = flattenedOptions?.find((opt) => opt.value === value);
      return option;
    },
    [flattenedOptions, readOnly],
  );

  return getSelectedOption;
}
