import React from 'react';
import { t } from '@lingui/macro';
import { AnimatePresence, motion } from 'framer-motion';

import { IconSearch } from '@float/ui/icons/essentials/IconSearch';
import { IconSpinner } from '@float/ui/icons/essentials/IconSpinner';

import * as styles from './styles.css';

export function ListSearchFieldIcon({ isLoading }: { isLoading: boolean }) {
  return (
    <div className={styles.iconContainer}>
      <AnimatePresence mode="sync" initial={false}>
        {isLoading ? (
          <motion.div
            key="icon-spinner"
            animate={{ opacity: 1 }}
            exit={{ opacity: 0.1, scale: 0.9, x: 1 }}
          >
            <IconSpinner
              animated
              className={styles.icon}
              size={20}
              title={t`Loading`}
            />
          </motion.div>
        ) : (
          <motion.div
            key="icon-search"
            initial={{ opacity: 0.1 }}
            animate={{ opacity: 1 }}
          >
            <IconSearch className={styles.icon} size={20} title={t`Search`} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
