import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { CompanyPreferences } from '@float/types/companyPreferences';
import { Person } from '@float/types/person';
import { hasReachedPeopleSeatsLimit } from 'components/modals/helpers/hasReachedPeopleSeatsLimit';
import { hasReachedPlaceholderSeatsLimit } from 'components/modals/helpers/hasReachedPlaceholderSeatsLimit';

// import { hasReachedPlaceholderSeatsLimit } from 'components/modals/helpers/hasReachedPlaceholderSeatsLimit';

const countPeopleAndPlaceholders = (people: Person[]) => {
  let peopleToActivateCount = 0;
  let placeholdersToActivateCount = 0;

  people.forEach((person) => {
    if (person.people_type_id === 3) {
      placeholdersToActivateCount++;
    } else {
      peopleToActivateCount++;
    }
  });

  return {
    peopleToActivateCount,
    placeholdersToActivateCount,
  };
};

const canBulkActivateConsideringPlaceholders = (
  peopleToActivateCount: number,
  placeholdersToActivateCount: number,
  companyPrefs: CompanyPreferences,
) => {
  const {
    placeholder_people: currentPlaceholderCount,
    placeholder_limit: currentPlaceholderLimit,
  } = companyPrefs;

  const freePlaceholdersAvailable =
    currentPlaceholderLimit - currentPlaceholderCount;

  const paidPlaceholders = Math.max(
    0,
    placeholdersToActivateCount - freePlaceholdersAvailable,
  );
  const peopleToActivateTotal = peopleToActivateCount + paidPlaceholders;

  if (!peopleToActivateTotal) {
    return !hasReachedPlaceholderSeatsLimit(
      companyPrefs,
      placeholdersToActivateCount,
    );
  }

  return !hasReachedPeopleSeatsLimit(companyPrefs, peopleToActivateTotal);
};

const canBulkActivatePeopleOnly = (
  peopleToActivateCount: number,
  companyPrefs: CompanyPreferences,
) => {
  return !hasReachedPeopleSeatsLimit(companyPrefs, peopleToActivateCount);
};

export const canBulkActivate = (
  people: Person[],
  companyPrefs: CompanyPreferences,
) => {
  const { peopleToActivateCount, placeholdersToActivateCount } =
    countPeopleAndPlaceholders(people);

  if (featureFlags.isFeatureEnabled(FeatureFlag.PlaceholdersPackaging)) {
    return canBulkActivateConsideringPlaceholders(
      peopleToActivateCount,
      placeholdersToActivateCount,
      companyPrefs,
    );
  }

  return canBulkActivatePeopleOnly(
    peopleToActivateCount + placeholdersToActivateCount,
    companyPrefs,
  );
};
