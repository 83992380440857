import React from 'react';
import { t } from '@lingui/macro';
import { format, parseISO } from 'date-fns';

import { projectEditable } from '@float/common/lib/rights';
import { DEFAULT_PALETTE } from '@float/constants/colors';
import { moment } from '@float/libs/moment';
import { capitalize } from '@float/libs/utils/string/capitalize';
import { CurrentUser, Person, Project, Task } from '@float/types';

import { getAllChangedFields } from './getAllChangedFields';

export { getAllChangedFields };
export { getErrorMessageFromException } from './getErrorMessageFromException';

const formattedCache: { [key: string]: string } = {};
export const formatDate = (
  dateParam: string | Date,
  formatValue = 'yyyy-MM-dd',
) => {
  let date: Date;

  if (typeof dateParam === 'string') {
    date = new Date(dateParam);
    // Ensure result is timezone agnostic,
    // i.e. 2021-06-30 always formats to 30 Jun.
    date = new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
  } else {
    date = dateParam;
  }

  const str = `${+date}_${formatValue}`;
  if (formattedCache[str]) {
    return formattedCache[str];
  }

  formattedCache[str] = format(date as Date, formatValue);
  return formattedCache[str];
};

export function getDateRangeText({
  start,
  end,
  includeYear = false,
  dateFormat = 'd MMM',
  fullDateFormat = 'd MMM yy',
}: {
  start: string;
  end: string;
  includeYear?: boolean;
  dateFormat?: string;
  fullDateFormat?: string;
}) {
  const currentYear = new Date().getFullYear();
  const startYear = +start.split('-')[0];
  const endYear = end ? +end.split('-')[0] : startYear;
  const startFormat = startYear === endYear ? dateFormat : fullDateFormat;
  const endFormat =
    endYear !== currentYear || endYear !== startYear
      ? fullDateFormat
      : dateFormat;
  const areDatesNotInCurrentYear =
    startFormat === fullDateFormat || endFormat === fullDateFormat;
  if (areDatesNotInCurrentYear && !includeYear) {
    return 'Custom';
  }
  const startStr = formatDate(start, startFormat);
  const endStr = formatDate(end, endFormat);
  return startStr === endStr ? startStr : `${startStr} - ${endStr}`;
}

// returns a string such as "2 Jun", and includes year in the
// returned string only if the year is not the current year
export function formatDateWithYearIfNotCurrent(
  dateStr: string,
  formatWithoutYear: string = 'd MMM',
  formatWithYear: string = 'd MMM yyyy',
) {
  const date = parseISO(dateStr);
  const currentYear = new Date().getUTCFullYear();

  const dateFormat =
    date.getUTCFullYear() !== currentYear ? formatWithYear : formatWithoutYear;

  return format(date, dateFormat);
}

// returns a string such as "2 Jun - 10 Jul", and includes year in
// the returned string only if the year is not the current year
export function formatDateRangeWithYearIfNotCurrent(
  startDateStr: string | undefined,
  endDateStr: string | undefined,
  formatWithoutYear: string = 'd MMM',
  formatWithYear: string = 'd MMM yyyy',
) {
  if (startDateStr && endDateStr && startDateStr !== endDateStr) {
    return getDateRangeText({
      start: startDateStr,
      end: endDateStr,
      includeYear: true,
    });
  }

  if (startDateStr) {
    return formatDateWithYearIfNotCurrent(
      startDateStr,
      formatWithoutYear,
      formatWithYear,
    );
  }

  if (endDateStr) {
    return formatDateWithYearIfNotCurrent(
      endDateStr,
      formatWithoutYear,
      formatWithYear,
    );
  }
}

export const fieldsChanged = <V,>(
  next: { [key: string]: V },
  prev: { [key: string]: V },
) => {
  return getAllChangedFields(next, prev).length > 0;
};

export const capitalizeEvery = (str: string) => {
  return str
    .split(' ')
    .map((item) => capitalize(item))
    .join(' ');
};

const randomItemFrom = <V,>(targ: Array<V>): V =>
  targ[Math.floor(Math.random() * targ.length)];
export const randomColor = (): string => {
  return randomItemFrom(DEFAULT_PALETTE);
};

export const isActive = (pathname: string) =>
  window.location.pathname.indexOf(pathname) >= 0;

export const labelify = (value: string) =>
  value
    .split('_')
    .map((s) => s.slice(0, 1).toUpperCase() + s.slice(1))
    .join(' ')
    .replace(/_/g, ' ');

export const setTitle = (title: string) => {
  const titleElement = document?.querySelector('title');

  if (titleElement) {
    titleElement.innerHTML = title;
  }
};

export const splitNum = (num: number) => {
  const charsStr = `${num}`;
  const [int, float] = charsStr.split('.');
  const chars = int.split('');
  const result = chars.reverse().map((c, i) => {
    if (i !== 0 && +i % 3 === 0) {
      return `${c},`;
    }
    return `${c}`;
  });
  let joined = result.reverse().join('');

  if (float) {
    let parsedFloat: string | number = Math.round(
      Number(`${float.slice(0, 2)}.${float.slice(2)}`),
    );
    parsedFloat = `.${parsedFloat}`;
    joined += parsedFloat;
  }

  return joined;
};

export function splitUsingBr(text: string) {
  return text.split('\n').flatMap((line, i, lines) => {
    const isLast = i == lines.length - 1;
    if (isLast) {
      return [line];
    }

    return [line, <br key={i} />];
  });
}

export const isEditTheirTask = (user: CurrentUser, task: Task) => {
  if (!user) return false;

  return (
    user.account_tid == 4 &&
    task.people_ids.length == 1 &&
    user.people_id == task.people_ids[0]
  );
};

export const isEditableProject = (project: Project, usr: CurrentUser) => {
  return projectEditable(project, usr);
};

export const isPlaceholder = (person: Person) => {
  return person.people_type_id == 3;
};

export const getTimeAgo = (dateInput: string, initAsUtc = false) => {
  if (!dateInput) {
    return dateInput;
  }
  const date = initAsUtc ? moment.utc(dateInput) : moment(dateInput);
  let text = date.fromNow(); // NOTE: this wouldn't work if we wanted localization
  if (text === 'in a few seconds') {
    text = t`a few seconds ago`;
  }
  return text;
};

export const shouldShowActivityByDefault = () => {
  return window.location.search.includes('notificationFeed=1');
};

export function popupWindow(url: string, windowName: string, w = 680, h = 840) {
  const outerHeight = window?.top?.outerHeight ?? 0;
  const screenY = window?.top?.screenY ?? 0;
  const outerWidth = window?.top?.outerWidth ?? 0;
  const screenX = window?.top?.screenX ?? 0;

  const y = outerHeight / 2 + screenY - h / 2;
  const x = outerWidth / 2 + screenX - w / 2;
  const params = `width=${w}, height=${h}, top=${y}, left=${x}, toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no`;
  return window.open(url, windowName, params);
}

export const getDateString = (date: string | Moment | Date) =>
  moment(date).format('DD MMM YYYY');

export function roundToTwoDecimals(val: number) {
  return Math.round(val * 100) / 100;
}

const fontWidths = [
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0.2796875, 0.2765625, 0.3546875, 0.5546875, 0.5546875,
  0.8890625, 0.665625, 0.190625, 0.3328125, 0.3328125, 0.3890625, 0.5828125,
  0.2765625, 0.3328125, 0.2765625, 0.3015625, 0.5546875, 0.5546875, 0.5546875,
  0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875,
  0.2765625, 0.2765625, 0.584375, 0.5828125, 0.584375, 0.5546875, 1.0140625,
  0.665625, 0.665625, 0.721875, 0.721875, 0.665625, 0.609375, 0.7765625,
  0.721875, 0.2765625, 0.5, 0.665625, 0.5546875, 0.8328125, 0.721875, 0.7765625,
  0.665625, 0.7765625, 0.721875, 0.665625, 0.609375, 0.721875, 0.665625,
  0.94375, 0.665625, 0.665625, 0.609375, 0.2765625, 0.3546875, 0.2765625,
  0.4765625, 0.5546875, 0.3328125, 0.5546875, 0.5546875, 0.5, 0.5546875,
  0.5546875, 0.2765625, 0.5546875, 0.5546875, 0.221875, 0.240625, 0.5, 0.221875,
  0.8328125, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.3328125, 0.5,
  0.2765625, 0.5546875, 0.5, 0.721875, 0.5, 0.5, 0.5, 0.3546875, 0.259375,
  0.353125, 0.5890625,
];
const avg = 0.5279276315789471;

export function measureText(str: string, fontSize: number) {
  return (
    Array.from(str).reduce(
      (acc, cur) => acc + (fontWidths[cur.charCodeAt(0)] ?? avg),
      0,
    ) * fontSize
  );
}

export const generateId = () => {
  return `${Date.now()}${Math.random()}`;
};
