import { t } from '@lingui/macro';
import { forEach, isEmpty, map, pickBy } from 'lodash';
import { amountFormatter } from 'reports/helpers/tableFormatters';
import { getBudgetDataObject } from 'reports/ProjectsOverview/parser/table/helpers/budget';
import { getEmptyTaskLabel } from 'reports/TeamCapacity/parser/table/getEmptyTaskLabel';

import { formatAmount, toCents } from '@float/common/lib/budget';
import { BudgetPriority, BudgetType } from '@float/types/project';

function getBlankPhase() {
  return {
    budget_type: 0,
    budget: 0,
    scheduled: 0,
    feeCents: 0,
    logged: {
      scheduled: 0,
      feeCents: 0,
    },
    future: {
      scheduled: 0,
      feeCents: 0,
    },
    children: {},
  };
}

function breakdown(ctx, raw) {
  const { people, phases } = ctx;
  let byPhase = {};

  forEach(raw.phases, (phase, phaseId) => {
    const reduxPhase = phases[phaseId];
    if (!reduxPhase) return;

    byPhase[phaseId] = {
      ...getBlankPhase(),
      ...(raw.budgets?.[reduxPhase.project_id] && {
        budget_type: raw.budgets?.[reduxPhase.project_id]?.type,
      }),
      budget: phase.budget,
    };
  });

  raw.totals.forEach((item) => {
    if (item.type !== 'task' && item.type !== 'logged_time') return;

    if (!people[item.person_id]) {
      console.error('No person found', item);
      return;
    }

    if (!!item.billable !== !!ctx.billable) return;

    if (!item.name) {
      item.name = getEmptyTaskLabel(ctx);
    }

    if (!item.phase_id && !byPhase[0]) {
      byPhase[0] = getBlankPhase();
    }
    const record = byPhase[item.phase_id];

    // record may be missing if the phase has not been loaded
    // e.g. when the team has reached the MAX_LIMIT on phases
    // https://linear.app/float-com/issue/CS-1077/team-106838-unable-to-access-the-project-report
    if (!record) {
      console.warn(`Phase with the id ${item.phase_id} is missing`);
      return;
    }

    if (!record.children[item.name]) {
      record.children[item.name] = {
        scheduled: 0,
        feeCents: 0,
        logged: {
          scheduled: 0,
          feeCents: 0,
        },
        future: {
          scheduled: 0,
          feeCents: 0,
        },
      };
    }

    const child = record.children[item.name];

    const isTask = item.type === 'task';
    const isLoggedtime = item.type === 'logged_time';
    if (isTask || isLoggedtime) {
      if (isLoggedtime && item.date >= ctx.loggedTimeBoundary) {
        return;
      }

      const childRoot = isTask ? child : child.logged;
      const recordRoot = isTask ? record : record.logged;

      childRoot.scheduled += item.hours.scheduled;
      recordRoot.scheduled += item.hours.scheduled;
      record.future.scheduled += item.hours.future;

      recordRoot.feeCents += toCents(item.rate) * item.hours.scheduled;
      childRoot.feeCents += toCents(item.rate) * item.hours.scheduled;

      const rateCents = toCents(item.rate);
      record.future.feeCents += rateCents * item.hours.future;

      if (isTask) {
        child.future.feeCents += rateCents * item.hours.future;
        child.future.scheduled += item.hours.future;
      }
    }
  });

  byPhase = pickBy(byPhase, (val, phaseId) => {
    const reduxPhase = phases[phaseId];
    return (
      !isEmpty(val.children) || !reduxPhase?.non_billable == !!ctx.billable
    );
  });

  return byPhase;
}

export function getScheduledTable(ctx, raw) {
  const { project, phases } = ctx;
  const isSecondaryTable = !project.non_billable && !ctx.billable;
  const hasBudgetColumns =
    project.budget_priority === BudgetPriority.Phase && !isSecondaryTable;
  const hasFeeColumns =
    !isSecondaryTable &&
    (project.budget_type === BudgetType.TotalFee ||
      project.budget_type === BudgetType.HourlyFee);

  const headers = [
    {
      label: ctx.billable ? t`Billable` : t`Non-billable`,
      align: 'flex-start',
      grow: 1,
    },
    { label: t`Budget`, width: 130, formatter: amountFormatter },
    {
      label: '',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: 'Scheduled',
      width: 140,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
    { label: t`Remaining`, width: 130, formatter: amountFormatter },
  ];

  if (!hasBudgetColumns) {
    headers.splice(1, 1);
    headers.splice(headers.length - 1, 1);
  }

  if (!raw || !raw.totals) {
    return { headers, rows: [] };
  }

  const byPhase = breakdown(ctx, raw);

  const totals = {
    budget: 0,
    hours: 0,
    feeCents: 0,
  };

  const rows = [];
  forEach(byPhase, (o, phaseId) => {
    const fee = hasFeeColumns ? o.feeCents / 100 : '';
    const hours = o.scheduled;

    totals.hours += o.scheduled;
    totals.budget += o.budget;
    if (hasFeeColumns) totals.feeCents += o.feeCents;

    const phase = phases[phaseId];
    const phaseVal = {
      val: phase?.phase_name || t`No Phase`,
      sortVal: phase?.phase_name || '0000',
    };

    const data = [
      phaseVal,
      getBudgetDataObject(o),
      fee,
      hours,
      getBudgetDataObject({
        budget_type: o.budget_type,
        budget: (toCents(o.budget) - o.feeCents) / 100,
      }),
    ];

    if (!hasBudgetColumns) {
      data.splice(1, 1);
      data.splice(data.length - 1, 1);
    }

    rows.push({
      id: phaseId,
      data,
      children: map(o.children, (c, taskName) => {
        if (!ctx.billable && c.scheduled == 0) return null;

        const childBudget = getBudgetDataObject(c, {
          entityType: 'task',
        });
        const childFee = hasFeeColumns ? c.feeCents / 100 : '';
        const childHours = c.scheduled;

        const childData = [taskName, childBudget, childFee, childHours, ''];

        if (!hasBudgetColumns) {
          childData.splice(1, 1);
          childData.splice(childData.length - 1, 1);
        }

        return {
          data: childData,
        };
      }).filter(Boolean),
    });
  });

  const budgetRemaining = hasFeeColumns
    ? (toCents(totals.budget) - totals.feeCents) / 100
    : totals.budget - totals.hours;

  const footer = [
    { label: '' },
    {
      label: getBudgetDataObject({
        budget_type: project.budget_type,
        budget: totals.budget,
      }),
    },
    { label: totals.feeCents / 100 },
    { label: totals.hours },
    {
      label: getBudgetDataObject({
        budget_type: project.budget_type,
        budget: budgetRemaining,
      }),
    },
  ];

  if (!hasBudgetColumns) {
    footer.splice(1, 1);
    footer.splice(footer.length - 1, 1);
  }

  return { headers, rows, footer };
}

export function getLoggedTable(ctx, raw) {
  const { project, phases } = ctx;
  const isSecondaryTable = !project.non_billable && !ctx.billable;
  const hasBudgetColumns =
    project.budget_priority === BudgetPriority.Phase && !isSecondaryTable;
  const hasFeeColumns =
    !isSecondaryTable &&
    (project.budget_type === BudgetType.TotalFee ||
      project.budget_type === BudgetType.HourlyFee);

  const headers = [
    {
      label: ctx.billable ? t`Billable` : t`Non-billable`,
      align: 'flex-start',
      grow: 1,
    },
    { label: t`Budget`, width: 130, formatter: amountFormatter },
    {
      label: '',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: t`Logged`,
      width: 80,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
    { label: t`Remaining`, width: 130, formatter: amountFormatter },
  ];

  if (!hasBudgetColumns) {
    headers.splice(1, 1);
    headers.splice(headers.length - 1, 1);
  }

  if (!raw || !raw.totals) {
    return { headers, rows: [] };
  }

  const byPhase = breakdown(ctx, raw);

  const totals = {
    budget: 0,
    hours: 0,
    feeCents: 0,
  };

  const rows = [];
  forEach(byPhase, (o, phaseId) => {
    const fee = hasFeeColumns ? o.logged.feeCents / 100 : '';
    const hours = o.logged.scheduled;

    if (!ctx.billable && hours === 0) {
      return;
    }

    totals.hours += o.logged.scheduled;
    totals.budget += o.budget;

    if (hasFeeColumns) totals.feeCents += o.logged.feeCents;

    const phase = phases[phaseId];
    const phaseVal = {
      val: phase?.phase_name || t`No Phase`,
      sortVal: phase?.phase_name || '0000',
    };

    const data = [
      phaseVal,
      getBudgetDataObject(o),
      fee,
      hours,
      getBudgetDataObject({
        budget_type: project.budget_type,
        budget: (toCents(o.budget) - o.logged.feeCents) / 100,
      }),
    ];

    if (!hasBudgetColumns) {
      data.splice(1, 1);
      data.splice(data.length - 1, 1);
    }

    rows.push({
      id: phaseId,
      data,
      children: map(o.children, (c, taskName) => {
        if (!ctx.billable && c.logged.scheduled == 0) return null;

        const childBudget = getBudgetDataObject(c, {
          entityType: 'task',
        });
        const childFee = hasFeeColumns ? c.logged.feeCents / 100 : '';
        const childHours = c.logged.scheduled;
        const childData = [taskName, childBudget, childFee, childHours, ''];

        if (!hasBudgetColumns) {
          childData.splice(1, 1);
          childData.splice(childData.length - 1, 1);
        }

        return {
          data: childData,
        };
      }).filter(Boolean),
    });
  });

  const budgetRemaining = hasFeeColumns
    ? (toCents(totals.budget) - totals.feeCents) / 100
    : totals.budget - totals.hours;

  const footer = [
    { label: '' },
    {
      label: getBudgetDataObject({
        budget_type: project.budget_type,
        budget: totals.budget,
      }),
    },
    { label: totals.feeCents / 100 },
    { label: totals.hours },
    {
      label: getBudgetDataObject({
        budget_type: project.budget_type,
        budget: budgetRemaining,
      }),
    },
  ];

  if (!hasBudgetColumns) {
    footer.splice(1, 1);
    footer.splice(footer.length - 1, 1);
  }

  return { headers, rows, footer };
}

export function getCompareTable(ctx, raw) {
  const { project, phases } = ctx;
  const isSecondaryTable = !project.non_billable && !ctx.billable;
  const hasBudgetColumns =
    project.budget_priority === BudgetPriority.Phase && !isSecondaryTable;
  const hasFeeColumns =
    !isSecondaryTable &&
    (project.budget_type === BudgetType.TotalFee ||
      project.budget_type === BudgetType.HourlyFee);

  const headers = [
    {
      label: ctx.billable ? t`Billable` : t`Non-billable`,
      align: 'flex-start',
      grow: 1,
    },
    { label: t`Budget`, width: 130, formatter: amountFormatter },
    {
      label: '',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: 'Logged',
      width: 80,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
    {
      label: '',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: 'Scheduled',
      width: 80,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
    {
      label: '',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: 'Difference',
      width: 80,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
  ];

  if (!hasBudgetColumns) headers.splice(1, 1);

  if (!raw || !raw.totals) {
    return { headers, rows: [] };
  }

  const byPhase = breakdown(ctx, raw);

  const totals = {
    budget: 0,
    hours: 0,
    feeCents: 0,
    loggedHours: 0,
    loggedFeeCents: 0,
  };

  const rows = [];
  forEach(byPhase, (o, phaseId) => {
    const fee = hasFeeColumns ? o.feeCents / 100 : '';
    const hours = o.scheduled;
    const loggedFee = hasFeeColumns ? o.logged.feeCents / 100 : '';
    const loggedHours = o.logged.scheduled;
    const diffFee = hasFeeColumns ? (o.feeCents - o.logged.feeCents) / 100 : '';
    const diffHours = hours - loggedHours;

    totals.budget += o.budget;
    totals.hours += o.scheduled;
    totals.loggedHours += o.logged.scheduled;
    if (hasFeeColumns) {
      totals.feeCents += o.feeCents;
      totals.loggedFeeCents += o.logged.feeCents;
    }

    const phase = phases[phaseId];
    const phaseVal = {
      val: phase?.phase_name || t`No Phase`,
      sortVal: phase?.phase_name || '0000',
    };

    const data = [
      phaseVal,
      getBudgetDataObject(o),
      loggedFee,
      loggedHours,
      fee,
      hours,
      diffFee,
      diffHours,
    ];

    if (!hasBudgetColumns) data.splice(1, 1);

    rows.push({
      id: phaseId,
      data,
      children: map(o.children, (c, taskName) => {
        if (!ctx.billable && c.logged.scheduled == 0 && c.scheduled == 0)
          return null;

        const childBudget = getBudgetDataObject(c, {
          entityType: 'task',
        });
        const childFee = hasFeeColumns ? c.feeCents / 100 : '';
        const childHours = c.scheduled;
        const childLoggedFee = hasFeeColumns ? c.logged.feeCents / 100 : '';
        const childLoggedHours = c.logged.scheduled;
        const childDiffFee = hasFeeColumns
          ? (c.feeCents - c.logged.feeCents) / 100
          : '';
        const childDiffHours = childHours - childLoggedHours;
        const childData = [
          taskName,
          childBudget,
          childLoggedFee,
          childLoggedHours,
          childFee,
          childHours,
          childDiffFee,
          childDiffHours,
        ];

        if (!hasBudgetColumns) childData.splice(1, 1);

        return {
          data: childData,
        };
      }).filter(Boolean),
    });
  });

  const footer = [
    { label: '' },
    {
      label: getBudgetDataObject({
        budget_type: project.budget_type,
        budget: totals.budget,
      }),
    },
    { label: hasFeeColumns ? totals.loggedFeeCents / 100 : '' },
    { label: totals.loggedHours },
    { label: hasFeeColumns ? totals.feeCents / 100 : '' },
    { label: totals.hours },
    {
      label: hasFeeColumns
        ? (totals.feeCents - totals.loggedFeeCents) / 100
        : '',
    },
    { label: totals.hours - totals.loggedHours },
  ];

  if (!hasBudgetColumns) footer.splice(1, 1);

  return { headers, rows, footer };
}

export function getCombinedTable(ctx, raw) {
  const { project, phases } = ctx;
  const isSecondaryTable = !project.non_billable && !ctx.billable;
  const hasBudgetColumns =
    project.budget_priority === BudgetPriority.Phase && !isSecondaryTable;
  const hasFeeColumns =
    !isSecondaryTable &&
    (project.budget_type === BudgetType.TotalFee ||
      project.budget_type === BudgetType.HourlyFee);

  const headers = [
    {
      label: ctx.billable ? t`Billable` : t`Non-billable`,
      align: 'flex-start',
      grow: 1,
    },
    { label: t`Budget`, width: 130, formatter: amountFormatter },
    {
      label: '',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: t`Past logged`,
      width: 80,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
    {
      label: '',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: t`Future scheduled`,
      width: 80,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
    {
      label: '',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: t`Total`,
      width: 80,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
  ];

  if (!hasBudgetColumns) headers.splice(1, 1);

  if (!raw || !raw.totals) {
    return { headers, rows: [] };
  }

  const byPhase = breakdown(ctx, raw);

  const totals = {
    budget: 0,
    hours: 0,
    feeCents: 0,
    loggedHours: 0,
    loggedFeeCents: 0,
  };

  const rows = [];

  forEach(byPhase, (o, phaseId) => {
    const fee = hasFeeColumns ? o.future.feeCents / 100 : '';
    const hours = o.future.scheduled;
    const loggedFee = hasFeeColumns ? o.logged.feeCents / 100 : '';
    const loggedHours = o.logged.scheduled;
    const totalFee = hasFeeColumns
      ? (o.future.feeCents + o.logged.feeCents) / 100
      : '';
    const totalHours = hours + loggedHours;

    if (!ctx.billable && totalHours === 0) {
      return;
    }

    totals.budget += o.budget;
    totals.hours += o.future.scheduled;
    totals.loggedHours += o.logged.scheduled;

    if (hasFeeColumns) {
      totals.feeCents += o.future.feeCents;
      totals.loggedFeeCents += o.logged.feeCents;
    }

    const phase = phases[phaseId];
    const phaseVal = {
      val: phase?.phase_name || t`No Phase`,
      sortVal: phase?.phase_name || '0000',
    };

    const phaseBudget = getBudgetDataObject(o);

    const data = [
      phaseVal,
      phaseBudget,
      loggedFee,
      loggedHours,
      fee,
      hours,
      totalFee,
      totalHours,
    ];

    if (!hasBudgetColumns) data.splice(1, 1);

    rows.push({
      id: phaseId,
      data,
      children: map(o.children, (c, taskName) => {
        if (!ctx.billable && c.future.scheduled == 0 && c.logged.scheduled == 0)
          return null;

        const childBudget = getBudgetDataObject(c, {
          entityType: 'task',
        });
        const childFee = hasFeeColumns ? c.future.feeCents / 100 : '';
        const childHours = c.future.scheduled;
        const childLoggedFee = hasFeeColumns ? c.logged.feeCents / 100 : '';
        const childLoggedHours = c.logged.scheduled;
        const childTotalFee = hasFeeColumns
          ? (c.future.feeCents + c.logged.feeCents) / 100
          : '';
        const childTotalHours = childHours + childLoggedHours;
        const childData = [
          taskName,
          childBudget,
          childLoggedFee,
          childLoggedHours,
          childFee,
          childHours,
          childTotalFee,
          childTotalHours,
        ];

        if (!hasBudgetColumns) childData.splice(1, 1);

        return {
          data: childData,
        };
      }).filter(Boolean),
    });
  });

  const footer = [
    { label: '' },
    {
      label: getBudgetDataObject({
        budget_type: project.budget_type,
        budget: totals.budget,
      }),
    },
    { label: hasFeeColumns ? totals.loggedFeeCents / 100 : '' },
    { label: totals.loggedHours },
    { label: hasFeeColumns ? totals.feeCents / 100 : '' },
    { label: totals.hours },
    {
      label: hasFeeColumns
        ? (totals.feeCents + totals.loggedFeeCents) / 100
        : '',
    },
    { label: totals.hours + totals.loggedHours },
  ];

  if (!hasBudgetColumns) footer.splice(1, 1);

  return { headers, rows, footer };
}
