// Animation frame based implementation of setTimeout.
// Adapted from react-window, where it was inspired by Joe Lambert.
// https://gist.github.com/joelambert/1002116#file-requesttimeout-js

const hasNativePerformanceNow =
  typeof performance === 'object' && typeof performance.now === 'function';

export const now = hasNativePerformanceNow
  ? () => performance.now()
  : () => Date.now();

export function requestTimeout(callback, delay, ...callbackArgs) {
  const timer = {};

  function tick() {
    timer.type = requestAnimationFrame;
    // We wrap the callback inside a raf to reduce the possibilty
    // of layout reflow
    timer.id = requestAnimationFrame(() => {
      callback(...callbackArgs);
    });
  }

  timer.type = setTimeout;
  timer.id = setTimeout(tick, delay);
  return timer;
}

export function cancelTimeout(timer) {
  if (timer.type === requestAnimationFrame) {
    cancelAnimationFrame(timer.id);
  } else {
    clearTimeout(timer.id);
  }
}

export function requestInterval(callback, delay, ...callbackArgs) {
  const timer = {};

  function tick() {
    timer.type = requestAnimationFrame;
    timer.id = requestAnimationFrame(() => {
      // We schedule the next timeout before the callback to
      // make possible to clear it inside that callback
      timer.type = setTimeout;
      timer.id = setTimeout(tick, delay);
      callback(...callbackArgs);
    });
  }

  timer.type = setTimeout;
  timer.id = setTimeout(tick, delay);
  return timer;
}

export function cancelInterval(timer) {
  if (timer.type === requestAnimationFrame) {
    cancelAnimationFrame(timer.id);
  } else {
    clearTimeout(timer.id);
  }
}
