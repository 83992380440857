import styled, { css } from 'styled-components';

import * as Animation from '@float/ui/deprecated/Earhart/Animation';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Elevations from '@float/ui/deprecated/Earhart/Elevations';
import * as Icons from '@float/ui/deprecated/Earhart/Icons';

export const StyledNotification = styled.div`
  ${Elevations.E500};

  --border-width: 0px;
  --boder-color: transparent;
  --arrow-size: 18px;

  ${({ $borderWidth, $borderColor }) => {
    if ($borderWidth && $borderColor) {
      return css`
        --border-width: ${$borderWidth}px;
        --boder-color: ${$borderColor};
      `;
    }
  }}

  ${({ $arrowPosition }) => {
    if ($arrowPosition) {
      return css`
        --arrow-position: ${$arrowPosition};
      `;
    }
  }}

  display: flex;
  flex-direction: column;

  width: ${({ $width }) => $width}px;

  border-width: var(--border-width);
  border-radius: 8px;
  border-style: solid;
  border-color: var(--boder-color);

  background-color: ${({ $bgColor }) => $bgColor};

  &,
  & * {
    box-sizing: border-box;
  }

  &.left,
  &.right {
    transform: 500ms ease-out;
  }

  ${({ $position }) => {
    if ($position) {
      return css`
        &:before,
        &:after {
          display: block;
          position: absolute;
          content: '';
          top: var(--arrow-position, 50%);
          width: 0;
          height: 0;
        }

        &:before {
          margin-top: calc(var(--arrow-size) / 2 * -1);
          border-width: calc(var(--arrow-size) / 2);
          border-style: solid;
          border-color: transparent;
        }

        &:after {
          margin-top: calc((var(--arrow-size) / 2 + var(--border-width)) * -1);
          border-width: calc((var(--arrow-size) / 2) + var(--border-width));
          border-style: solid;
          border-color: transparent;
        }
      `;
    }
    return '';
  }};

  ${({ $position }) => {
    if ($position === 'right') {
      return css`
        &:before,
        &:after {
          left: calc((var(--arrow-size) + var(--border-width)) * -1);
        }

        &:before {
          border-right-color: var(--boder-color);
        }

        &:after {
          left: calc((var(--arrow-size) + var(--border-width) / 2) * -1);

          border-right-color: ${({ $bgColor }) => $bgColor};
        }
      `;
    } else if ($position === 'left') {
      return css`
        &:before,
        &:after {
          right: calc((var(--arrow-size) + var(--border-width)) * -1);
        }

        &:before {
          border-left-color: var(--boder-color);
        }

        &:after {
          right: calc((var(--arrow-size) + var(--border-width) / 2) * -1);

          border-left-color: ${({ $bgColor }) => $bgColor};
        }
      `;
    } else if ($position === 'bottom') {
      return css`
        &:before,
        &:after {
          top: calc(var(--arrow-size) * -1);
          left: var(--arrow-position, 50%);
        }

        &:before {
          margin-top: calc(var(--border-width) * -1);
          margin-left: calc(var(--arrow-size) / 2 * -1);
          border-bottom-color: var(--boder-color);
        }

        &:after {
          margin-top: calc(var(--border-width) / 2 * -1);
          margin-left: calc((var(--arrow-size) / 2 + var(--border-width)) * -1);
          border-bottom-color: ${({ $bgColor }) => $bgColor};
        }
      `;
    } else if ($position === 'top') {
      return css`
        &:before,
        &:after {
          top: auto;
          bottom: calc((var(--arrow-size) + var(--border-width)) * -1);
          left: var(--arrow-position, 50%);
        }

        &:before {
          margin-top: 0;
          margin-left: calc(var(--arrow-size) / 2 * -1);
          border-top-color: var(--boder-color);
        }

        &:after {
          margin-top: 0;
          margin-left: calc((var(--arrow-size) / 2 + var(--border-width)) * -1);
          border-top-color: ${({ $bgColor }) => $bgColor};
          bottom: calc((var(--arrow-size) + var(--border-width)) * -1);
        }
      `;
    }

    return '';
  }};
`;

export const StyledIconClose = styled(Icons.IconClose)`
  --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Medium};

  position: absolute;

  top: 4px;
  right: 4px;

  cursor: pointer;

  path {
    transition-property: stroke;
    transition-duration: ${Animation.Settings.Duration.Normal};
    transition-timing-function: ${Animation.Settings.Easing.InOutCurve};
  }

  &:hover {
    --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Low};
  }
`;
