import { t } from '@lingui/macro';
import { create, each, enforce, omitWhen, test } from 'vest';

import { config } from '@float/libs/config';
import { BudgetPriority, BudgetType } from '@float/types/project';

import { checkIsPhasePanel } from '../hooks/useIsPhasePanel';
import { checkIsTemplate } from '../hooks/useIsTemplate';
import { FormType, ProjectFormData } from '../types';
import {
  shouldRequireProjectDates,
  validateTaskName,
} from './projectValidationHelpers';

export const MAX_BUDGET_TOTAL = 1_000_000_000;
export const MAX_RATE = 1_000_000;

export const getMaxValueError = (value: number) => {
  const maxValue = value.toLocaleString(config.locale);

  return t`You can't submit a value higher or equal than ${maxValue}`;
};

function enforceDuration(value?: number | null, minDuration = 1) {
  if (value === undefined || value === null) {
    return enforce(value);
  }

  return enforce(value)
    .message('Required')
    .isNumeric()
    .message(t`Duration cannot be less than ${minDuration}`)
    .greaterThanOrEquals(minDuration);
}

function validateProjectForm(props: ProjectFormData) {
  const { projectId, project, team, phases, type } = props;

  const requiresBudgetTotal =
    project.budget_type === BudgetType.TotalFee ||
    project.budget_type === BudgetType.TotalHours;

  const requiresProjectBudget =
    requiresBudgetTotal && project.budget_priority === BudgetPriority.Project;

  const isNewProject = !projectId;
  const isTemplate = type === FormType.ProjectTemplate;

  const shouldValidateOffsets = isTemplate;

  omitWhen(isNewProject && !isTemplate, () => {
    test('project.project_name', t`Required`, () => {
      enforce(project.project_name).isNotEmpty();
    });
  });

  omitWhen(!requiresProjectBudget, () => {
    test('project.budget_total', () => {
      enforce(project.budget_total)
        .message(t`Required`)
        .isNumeric()
        .message(t`Project budget must be greater than 0`)
        .greaterThan(0)
        .message(getMaxValueError(MAX_BUDGET_TOTAL))
        .lessThan(MAX_BUDGET_TOTAL);
    });
  });

  omitWhen(!project.default_hourly_rate, () => {
    test('project.default_hourly_rate', () => {
      enforce(project.default_hourly_rate)
        .message(getMaxValueError(MAX_RATE))
        .isNumeric()
        .lessThan(MAX_RATE);
    });
  });

  omitWhen(!shouldValidateOffsets, () => {
    test('project.duration', () => {
      enforceDuration(project.duration);
    });
  });

  omitWhen(!shouldRequireProjectDates(props), () => {
    test('project.end_date', () => {
      enforce(project.end_date)
        .message(t`Setting dates is required to create the project`)
        .isNotEmpty();
    });
  });

  const requiresMemberHourlyRate =
    (project.budget_type === BudgetType.TotalFee ||
      project.budget_type === BudgetType.HourlyFee) &&
    typeof project.default_hourly_rate !== 'number';

  omitWhen(!requiresMemberHourlyRate, () => {
    each(team, (member, i) => {
      omitWhen(Boolean(member.isAssignedToPhaseOnly), () => {
        test(`team.${i}.hourly_rate`, () => {
          enforce(member.hourly_rate)
            .message(t`Required`)
            .isNumeric()
            .message(getMaxValueError(MAX_RATE))
            .lessThan(MAX_RATE);
        });
      });
    });
  });

  each(phases, (phase, i) => {
    test(`phases.${i}.phase_name`, t`Required`, () => {
      enforce(phase.phase_name).isNotEmpty();
    });

    omitWhen(typeof phase.budget_total !== 'number', () => {
      test(`phases.${i}.budget_total`, () => {
        enforce(phase.budget_total)
          .message(getMaxValueError(MAX_BUDGET_TOTAL))
          .lessThan(MAX_BUDGET_TOTAL);
      });
    });

    omitWhen(!shouldValidateOffsets, () => {
      test(`phases.${i}.duration`, () => {
        enforceDuration(phase.duration);
      });
    });
  });
}

function validatePhaseForm(props: ProjectFormData) {
  const { project, phase, team, type } = props;
  const phaseId = phase?.phase_id;

  const isNewPhase = !phaseId;
  const isEditingPhaseInNewProject = isNewPhase && !phase?.project_id;
  const isTemplate = type === FormType.PhaseTemplate;

  omitWhen(isNewPhase, () => {
    test('phase.phase_name', t`Required`, () => {
      enforce(phase?.phase_name).isNotEmpty();
    });
  });

  omitWhen(typeof phase?.budget_total !== 'number', () => {
    test('phase.budget_total', () => {
      enforce(phase?.budget_total)
        .message(getMaxValueError(MAX_BUDGET_TOTAL))
        .lessThan(MAX_BUDGET_TOTAL);
    });
  });

  omitWhen(!phase?.default_hourly_rate, () => {
    test('phase.default_hourly_rate', () => {
      enforce(phase?.default_hourly_rate)
        .message(getMaxValueError(MAX_RATE))
        .isNumeric()
        .lessThan(MAX_RATE);
    });
  });

  omitWhen(isTemplate || isEditingPhaseInNewProject, () => {
    test('phase.end_date', () => {
      enforce(phase?.end_date)
        .message(t`Setting dates is required to create the phase`)
        .isNotEmpty();
    });
  });

  const requiresMemberHourlyRate =
    (project.budget_type === BudgetType.TotalFee ||
      project.budget_type === BudgetType.HourlyFee) &&
    typeof phase?.default_hourly_rate !== 'number';

  omitWhen(!requiresMemberHourlyRate, () => {
    each(team, (member, i) => {
      test(`team.${i}.hourly_rate`, () => {
        enforce(member.hourly_rate)
          .message(t`Required`)
          .isNumeric()
          .message(getMaxValueError(MAX_RATE))
          .lessThan(MAX_RATE);
      });
    });
  });
}

export const getProjectFormValidationSuite = (
  type: ProjectFormData['type'],
) => {
  const isPhasePanel = checkIsPhasePanel(type);
  const isTemplate = checkIsTemplate(type);

  const shouldValidateOffsets = isTemplate;

  const validateForm = isPhasePanel ? validatePhaseForm : validateProjectForm;

  return create((props: ProjectFormData) => {
    const { milestones, tasks } = props;

    validateForm(props);

    each(milestones, (milestone, i) => {
      test(`milestones.${i}.name`, t`Required`, () => {
        enforce(milestone.name).isNotEmpty();
      });

      omitWhen(!shouldValidateOffsets, () => {
        test(`milestones.${i}.duration`, () => {
          enforceDuration(milestone.duration);
        });
      });
    });

    each(tasks, (task, i) => {
      test(`tasks.${i}.task_name`, () => {
        enforce(task.task_name)
          .message(t`Required`)
          .condition((val: string) => {
            return Boolean(val?.trim() || task.unnamedTask);
          })
          .message(t`Task name already exists`)
          .condition((val: string) => validateTaskName(i, val, tasks));
      });
    });
  });
};
