import React from 'react';

import { CycleConfig } from '../Cycles.types';
import { getIsCooldownCycle } from '../helpers/cycleVariants';
import { CooldownCycle } from './CooldownCycle';
import { Cycle } from './Cycle';

export type CycleListProps = {
  cycles: CycleConfig[];
  dayWidth: number;
};

export const CycleList: React.FC<CycleListProps> = ({ cycles, dayWidth }) => {
  return cycles.map((cycleConfig) => {
    if (getIsCooldownCycle(cycleConfig)) {
      return (
        <CooldownCycle
          key={cycleConfig.key}
          dayWidth={dayWidth}
          totalDays={cycleConfig.totalDays}
        />
      );
    }

    return (
      <Cycle
        key={cycleConfig.key}
        cycleId={cycleConfig.key}
        dayWidth={dayWidth}
        edgeCase={cycleConfig.edgeCase}
        spanDays={cycleConfig.spanDays}
        totalDays={cycleConfig.totalDays}
        variant={cycleConfig.variant}
      />
    );
  });
};
