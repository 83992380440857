import styled from 'styled-components';

export const DateContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 150px;
  margin-right: 20px;
  margin-top: 20px;

  input {
    font-size: 18px !important;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;

  & > div {
    margin-bottom: 0;
  }
`;

export const DeleteContainer = styled.div`
  margin-left: auto;

  button {
    margin-right: 0 !important;
  }
`;
