import React from 'react';

import TabBarFilters from './TabBarFilters/TabBarFilters';

function ReportsViewPicker(props) {
  return (
    <div style={{ display: 'flex', paddingTop: '9px' }}>
      <TabBarFilters
        timeoffApprovalsEnabled={props.timeoffApprovalsEnabled}
        {...{
          ...props.settings,
          updateSettings: props.updateSettings,
          memberViewSelf: props.memberViewSelf,
          hideTeamModeFilters: props.hideTeamModeFilters,
          isDraftProjectReport: props.isDraftProjectReport,
        }}
      />
    </div>
  );
}

export default ReportsViewPicker;
