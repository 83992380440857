import { SerenaState } from 'selectors';

import { findFirstTaskablePerson } from './findFirstTaskablePerson';
import { Row } from './findPersonRowMatch';
import { getDefaultPerson } from './getDefaultPerson';

export function getDefaultPersonForTaskModal(
  rows: Row[],
  serenaState: Pick<SerenaState, 'allPeople' | 'people' | 'user' | 'filters'>,
) {
  const firstTaskablePerson = findFirstTaskablePerson(rows, serenaState);

  return getDefaultPerson(firstTaskablePerson, serenaState);
}
