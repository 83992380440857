import { useWebAppSelector } from 'lib/store';
import manageModal from 'modalManager/manageModalActionCreator';
import { getOpenModal } from 'modalManager/selectors';
import { ModalType } from 'modalManager/types';
import useUpdateEffect from 'react-use/esm/useUpdateEffect';

import { useAppDispatchDecorator } from '@float/common/store';

export function useModalOpenListener(onModalOpen: () => void) {
  const openModal = useWebAppSelector(getOpenModal);
  const skipSidePanelAutoClose = openModal?.state.skipSidePanelAutoClose;

  const hideModal = useAppDispatchDecorator(
    ({ modalType }: { modalType: ModalType }) =>
      manageModal({
        visible: false,
        modalType,
        modalSettings: {},
      }),
  );

  const hideOpenModal = () => {
    if (openModal) {
      hideModal(openModal);
    }
  };

  const isModalOpen = Boolean(openModal);

  useUpdateEffect(() => {
    if (isModalOpen && !skipSidePanelAutoClose) {
      onModalOpen();
    }
  }, [isModalOpen]);

  return {
    hideOpenModal,
    isModalOpen,
    skipSidePanelAutoClose,
  };
}
