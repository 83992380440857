import request from '@float/common/lib/request';

const intServiceOpts = {
  hostname: '/svc/integ',
  version: '',
  headers: {
    'X-Token-Type': 'JWT',
  },
};

const requestOAuthLink = ({ type }) => {
  const opts = { ...intServiceOpts };
  return request.get(
    'integration/oauth_link',
    { type, origin: window.origin },
    opts,
    true,
  );
};

// external calendars

const requestCalendarList = ({ type }) => {
  const opts = { ...intServiceOpts };
  return request.get('available_calendars', { type }, opts, true);
};

const requestExtCalendars = ({ type }) => {
  const opts = { ...intServiceOpts };
  return request.get('ext_calendars', type ? { type } : null, opts, true);
};

const upsertExtCalendar = ({
  extCalendarId,
  projectId,
  importAllEvents,
  eventsFilterWord,
  hideTitleAndDesc,
  outboundType,
  outboundResourceId,
  inboundResourceId,
  type,
  deleteTasks,
  deleteEvents,
  version,
  ignoreCompanyTimezone,
}) => {
  const opts = {
    ...intServiceOpts,
    json: true,
    contentType: 'application/json',
  };
  const reqBody = {
    projectId,
    importAllEvents,
    eventsFilterWord,
    hideTitleAndDesc,
    outboundType,
    outboundResourceId,
    inboundResourceId,
    type,
    deleteTasks,
    deleteEvents,
    version,
    ignoreCompanyTimezone,
  };
  return request.put(
    `ext_calendar/${extCalendarId || 'new'}`,
    reqBody,
    opts,
    true,
  );
};

const resyncExtCalendar = ({ extCalendarId }) => {
  const opts = { ...intServiceOpts };
  return request.post(`ext_calendar/${extCalendarId}/resync`, {}, opts, true);
};

// company integrations

const requestCoInts = ({ type } = {}) => {
  const opts = { ...intServiceOpts };
  const params = type ? { type } : null;
  return request.get('company_ints', params, opts, true);
};

const addCoInt = ({ importExisting, syncType, type }) => {
  const opts = {
    ...intServiceOpts,
    json: true,
    contentType: 'application/json',
  };
  const reqBody = { syncType, importExisting, type };
  return request.post('company_int', reqBody, opts, true);
};

const removeCoInt = ({ coIntId, archiveData = false }) => {
  const opts = { ...intServiceOpts };
  const args = { mode: archiveData ? 'archive' : 'keep' };
  return request.del(`company_int/${coIntId}`, args, opts, true);
};

const retryPeopleSync = ({ coIntId }) => {
  const opts = {
    ...intServiceOpts,
    json: true,
    contentType: 'application/json',
  };
  const reqBody = { retry_type: 'people' };
  return request.post(
    `company_int/${coIntId}/retry_failed_syncs`,
    reqBody,
    opts,
    true,
  );
};

const requestPreload = ({ type, coIntId, token }) => {
  const opts = {
    ...intServiceOpts,
    json: true,
    contentType: 'application/json',
  };
  return request.post(
    'company_int/preload',
    { type, coIntId, token },
    opts,
    true,
  );
};

const getCoIntConfig = ({ coIntId }) => {
  const opts = { ...intServiceOpts };
  return request.get(`company_int/${coIntId}/config`, null, opts, true);
};

const updateCoIntConfig = ({
  coIntId,
  type,
  config,
  startInitialImport,
  resync,
  token,
}) => {
  const opts = {
    ...intServiceOpts,
    json: true,
    contentType: 'application/json',
  };
  return request.put(
    `company_int/${coIntId}/config`,
    { type, config, startInitialImport, resync, token },
    opts,
    true,
  );
};

const linkJiraAccount = ({ clientKey }) => {
  const opts = {
    ...intServiceOpts,
    json: true,
    contentType: 'application/json',
  };
  return request.post('jira/link_float', { clientKey }, opts, true);
};

const getSidebarData = ({ coIntId }) => {
  const opts = { ...intServiceOpts };
  return request.get(`company_int/${coIntId}/sidebar`, null, opts, true);
};

const createTaskPmLink = ({ coIntId, ...data }) => {
  return request.post(`company_int/${coIntId}/task_link`, data, intServiceOpts);
};

const getTaskLinks = (coIntId) => {
  return request.get(
    `company_int/${coIntId}/task_link`,
    null,
    intServiceOpts,
    true,
  );
};

const createTask = (coIntId, task, ext_resource_id) => {
  const opts = {
    ...intServiceOpts,
    json: true,
    contentType: 'application/json',
  };
  return request.post(
    `company_int/${coIntId}/tasks?extResourceId=${ext_resource_id}`,
    task,
    opts,
    true,
  );
};

const fetchIntegrationProjectMeta = (coIntId) => {
  return request.get(
    `company_int/${coIntId}/projects`,
    null,
    intServiceOpts,
    true,
  );
};

export default (accessToken) => {
  intServiceOpts.headers.Authorization = `Bearer ${accessToken}`;
  return {
    requestOAuthLink,
    requestCalendarList,
    requestExtCalendars,
    upsertExtCalendar,
    resyncExtCalendar,
    requestCoInts,
    addCoInt,
    removeCoInt,
    retryPeopleSync,
    requestPreload,
    getCoIntConfig,
    updateCoIntConfig,
    linkJiraAccount,
    getSidebarData,
    createTaskPmLink,
    getTaskLinks,
    createTask,
    fetchIntegrationProjectMeta,
  };
};
