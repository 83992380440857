import { useCallback } from 'react';
import useUpdateEffect from 'react-use/esm/useUpdateEffect';

import { trackSignedInEvent } from '@float/common/lib/trackInteraction';
import {
  getSubdomainPath,
  redirectToSubdomainIfNeeded,
} from '@float/common/lib/url';
import { useOnMount } from '@float/libs/hooks/useOnMount';

type AppControllerParams = {
  accessToken: string | null;
  companyHostname: string;
  fetchAllIntegrations: (opts: { getLinks: boolean }) => void;
  ping: () => void;
  isSharedLink: boolean;
};

export const useAppController = ({
  accessToken,
  companyHostname,
  fetchAllIntegrations,
  ping,
  isSharedLink,
}: AppControllerParams) => {
  const trackNewSignIn = useCallback(() => {
    if (!isSharedLink && accessToken) {
      trackSignedInEvent(accessToken, localStorage);
    }
  }, [accessToken, isSharedLink]);

  useOnMount(() => {
    if (redirectToSubdomainIfNeeded(companyHostname)) {
      return;
    }

    const redirectToLoginIfNeeded = (event: StorageEvent) => {
      if (event.key !== 'sign-out-event' || isSharedLink) {
        return;
      }

      const path = getSubdomainPath('/login', 'app');

      window.location.href = path;
    };

    window.addEventListener('storage', redirectToLoginIfNeeded);

    ping();
    fetchAllIntegrations({ getLinks: true });

    trackNewSignIn();

    return () => {
      window.removeEventListener('storage', redirectToLoginIfNeeded);
    };
  });

  useUpdateEffect(() => {
    trackNewSignIn();
  }, [trackNewSignIn]);
};
