import { prefetchTaskMetas as prefetchTaskMetasAction } from '@float/common/actions/taskMetas';
import { ReduxState } from '@float/common/reducers/lib/types';
import { getUser } from '@float/common/selectors/currentUser';
import { getLastProject } from '@float/common/selectors/projects';
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from '@float/common/store';

/**
 * Prefetch the task metas for a given project in order to not
 * show the loading state in the EditTaskModal and the LogTimeModal
 */
export function useTaskMetasPrefetch() {
  const dispatch = useAppDispatch();
  const store = useAppStore();

  const sharedLinkView = useAppSelector(
    (state: ReduxState) => getUser(state).shared_link_view,
  );

  function prefetchTaskMetas(projectId: number) {
    if (sharedLinkView) return;

    dispatch(prefetchTaskMetasAction(projectId));
  }

  function prefetchLastProjectTasks() {
    const lastProject = getLastProject(store.getState());

    if (lastProject?.project_id) {
      prefetchTaskMetas(lastProject.project_id);
    }
  }

  return {
    prefetchTaskMetas,
    prefetchLastProjectTasks,
  };
}
