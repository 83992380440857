import React from 'react';

import { Input } from '@float/ui/deprecated/Input';

import { normalizeHours } from '../../../../helpers/normalizeHours';

export type LogTimeHoursProps = {
  value: number | null;
  onChange(value: number | null): void;
  onSubmit(value: number): void;
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  label?: string;
  readOnly?: boolean;
  noBorder?: boolean;
};

export function LogTimeHours(props: LogTimeHoursProps) {
  const {
    autoFocus,
    className,
    disabled,
    label,
    onSubmit,
    onChange,
    readOnly,
    value,
    noBorder,
  } = props;

  const handleChange = (val: number | null) => {
    if (val !== null) {
      onChange(parseFloat(val.toFixed(2)));
    } else {
      onChange(null);
    }
  };

  return (
    <Input
      className={className}
      appearance="underline"
      type="number"
      label={label || 'Hours/day'}
      hideArrows
      selectTextOnFocus
      placeholder={8}
      value={value ?? ''}
      min={0.25}
      step={0.25}
      autoFocus={autoFocus}
      noBorder={noBorder}
      disabled={disabled}
      readOnly={readOnly}
      onChange={(opt: React.ChangeEvent<HTMLInputElement>) =>
        handleChange(opt.target.value ? parseFloat(opt.target.value) : null)
      }
      onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          e.stopPropagation();
          onSubmit(normalizeHours(value));
        }
      }}
      onBlur={() => handleChange(normalizeHours(props.value))}
      isStaticPlaceholder
    />
  );
}
