import { isNewPlaceholderType } from '@float/common/lib/people/isNewPlaceholderType';
import { Person } from '@float/types';

export const getNewPlaceholderType = (
  personId: number,
  people: Record<number, Person> | undefined,
) => {
  const person = people?.[personId];

  return Boolean(person && isNewPlaceholderType({ person }));
};
