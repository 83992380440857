import {
  getAccessibleProjects,
  getAccessRights,
  getActivePmIntegration,
} from 'selectors';

import { moment } from '@float/libs/moment';
import { ellipsis } from '@float/libs/utils/strings';

import { getItemPosition, getTrelloSearchContext } from '../trello';

const unknownProject = { project_name: 'Unknown project', color: 'ccc' };
const placeholderPerson = { name: 'Placeholder' };

export function addOption(options, optionsAddedMap, label, value) {
  if (!label) {
    return;
  }

  if (typeof value === 'undefined') {
    value = label;
  }

  if (!optionsAddedMap[value]) {
    options.push({
      label,
      value,
    });
    optionsAddedMap[value] = true;
  }
}

export function getSearchContext(params) {
  if (params.type === 'trello') {
    return getTrelloSearchContext(params);
  }

  const { myProjects, projectsOptions, itemsOptions, peopleOptions, type } =
    params;

  const searchContext = [];
  if (myProjects) {
    // TODO: check if members should strictly not be shown this
    searchContext.push({
      name: '',
      value: 'myProjects',
      options: [{ label: 'My projects', value: 'My projects' }],
    });
  }

  searchContext.push({
    name: '',
    value: 'assignedToMe',
    options: [{ label: 'Assigned to me', value: 'Assigned to me' }],
  });

  if (projectsOptions.length) {
    searchContext.push({
      name: 'Projects',
      value: 'projectName',
      options: projectsOptions,
    });
  }

  if (itemsOptions.length) {
    searchContext.push({
      name: type === 'jira' ? 'Issues' : 'Tasks',
      value: 'searchableName',
      options: itemsOptions,
    });
  }

  if (peopleOptions.length) {
    searchContext.push({
      name: 'Assignee',
      value: 'personName',
      options: peopleOptions,
    });
  }

  return searchContext;
}

function getDurationInDays(item) {
  const min = 1;
  const { startDate, dueDate } = item;
  if (!startDate || !dueDate) {
    return min;
  }

  return moment(dueDate).diff(moment(startDate), 'days') + min;
}

function getDurationString(item) {
  const days = item.days || getDurationInDays(item);
  return `${days} day${days === 1 ? '' : 's'}`;
}

const customizeSidebarItems = {
  jira: (item) => {
    item.key = item.extResourceId;
    item.searchableName = `${item.key}: ${item.name}`;
    return item;
  },
  trello: (item) => {
    if (item.list) {
      if (item.list.name) {
        item.fullKey = item.list.name;
        item.key = ellipsis(item.fullKey, 40);
      }
      item.position = getItemPosition(item);
    }
    return item;
  },
  asana: (item) => {
    if (item.extNotes) {
      item.description = item.extNotes;
    }
    return item;
  },
};

export default function processSidebarItems(response, fullState) {
  const {
    projects: { projects: allProjects },
    people: { people },
    currentUser: { admin_id, people_id },
  } = fullState;

  const currentUserAccountId = +admin_id;
  const currentUserPersonId = +people_id;
  const accessibleProjectIds = getAccessibleProjects(fullState).map(
    (x) => +x.project_id,
  );

  const accessRights = getAccessRights(fullState);
  const { type } = getActivePmIntegration(fullState);

  const items = [];
  const relevant = { projects: {}, people: {} };

  let item;
  let project;
  let person;
  let atLeastOneProjectOwnedByMe;

  const projectsOptions = [];
  const projectsIndexed = {};

  const peopleOptions = [];
  const peopleIndexed = {};

  const itemsOptions = [];
  const itemsIndexed = {};

  const keysOptions = [];
  const keysIndexed = {};

  const count = response.length;

  for (let i = 0; i < count; i++) {
    item = response[i];
    item.projectId = +item.projectId || null;
    if (!accessibleProjectIds.includes(item.projectId)) {
      continue;
    }

    project = allProjects[item.projectId] || unknownProject;
    item.projectName = project.project_name;
    item.projectColor = project.color;
    if (project.project_manager === currentUserAccountId) {
      item.myProject = true;
      atLeastOneProjectOwnedByMe = true;
    }
    item.projectModified = project.modified;

    item.assigneePeopleId = +item.assigneePeopleId || null;
    person = people[item.assigneePeopleId] || placeholderPerson;
    item.personName = person.name;
    if (item.assigneePeopleId === currentUserPersonId) {
      item.assignedToMe = true;
    }

    item.searchableName = item.name; // sometimes searchable name will include other attributes (e.g. jira item key)
    item.listDisplayName = ellipsis(item.name, 100);
    item.days = getDurationInDays(item);
    item.duration = getDurationString(item);
    item.isScheduled = Boolean(+item.scheduleStatus);

    if (item.extCreatedAt && typeof item.extCreatedAt === 'string') {
      item.extCreatedAt = parseInt(
        new Date(item.extCreatedAt).getTime() / 1000,
        10,
      );
    }

    if (customizeSidebarItems[type]) {
      customizeSidebarItems[type](item);
    }

    items.push(item);
    relevant.projects[item.projectId] = true;
    relevant.people[item.assigneePeopleId] = true;

    addOption(projectsOptions, projectsIndexed, item.projectName);
    addOption(peopleOptions, peopleIndexed, item.personName);
    addOption(itemsOptions, itemsIndexed, item.searchableName);
    if (type === 'trello' && item.fullKey) {
      addOption(keysOptions, keysIndexed, item.fullKey);
    }
  }

  const searchContext = getSearchContext({
    myProjects: atLeastOneProjectOwnedByMe || !accessRights.isMember,
    projectsOptions,
    peopleOptions,
    itemsOptions,
    keysOptions,
    type,
  });

  return { items, searchContext, relevant, type };
}
