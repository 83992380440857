import {
  clamp,
  endOfDay,
  format,
  getMinutes,
  isAfter,
  isBefore,
  isSameDay,
  setMinutes,
  startOfDay,
} from 'date-fns';

import { formatAsClockTime } from '@float/common/lib/timer/formatAsClockTime';
import { getTimeFormatPattern } from '@float/common/lib/timer/getTimeFormatPattern';
import { normalizeClockTime } from '@float/common/lib/timer/normalizeClockTime';
import { TimeFormat } from '@float/types';

function addMinutesToClockTime(
  value: string,
  timeFormat: TimeFormat,
  minutesToAdd: number = 1,
  wrap: boolean = false,
  min?: string | undefined,
  max?: string | undefined,
) {
  const time = normalizeClockTime(value);

  let newTime;

  if (time) {
    const pattern = getTimeFormatPattern(timeFormat);

    const minTime = (
      min
        ? normalizeClockTime(formatAsClockTime(min, timeFormat))
        : startOfDay(time)
    ) as Date;

    const maxTime = (
      max
        ? normalizeClockTime(formatAsClockTime(max, timeFormat))
        : endOfDay(time)
    ) as Date;

    // add minutes
    newTime = setMinutes(time, getMinutes(time) + minutesToAdd);

    // wrap time around min and max time values
    if (wrap) {
      if (isAfter(newTime, maxTime)) {
        if (isSameDay(newTime, maxTime)) {
          newTime = minTime;
        } else {
          newTime.setFullYear(
            time.getFullYear(),
            time.getMonth(),
            time.getDate(),
          );

          newTime = clamp(newTime, {
            start: minTime,
            end: maxTime,
          });
        }
      }

      if (isBefore(newTime, minTime)) {
        if (isSameDay(newTime, minTime)) {
          newTime = maxTime;
        } else {
          newTime.setFullYear(
            time.getFullYear(),
            time.getMonth(),
            time.getDate(),
          );

          newTime = clamp(newTime, {
            start: minTime,
            end: maxTime,
          });
        }
      }
    }

    newTime = clamp(newTime, {
      start: minTime,
      end: maxTime,
    });

    newTime = format(newTime, pattern);
  }

  return newTime || value;
}

export { addMinutesToClockTime };
