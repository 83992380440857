import React, { MouseEventHandler, PropsWithChildren, ReactNode } from 'react';
import { FieldArray, FieldArrayPath } from 'react-hook-form';
import cn from 'classnames';

import { useFieldArrayContext } from '../../hooks/useFieldArrayContext';
import { ProjectFormData } from '../../types';

import * as styles from './styles.css';

type Props<N extends FieldArrayPath<ProjectFormData>> = PropsWithChildren<{
  label: ReactNode;
  name?: string;
  className?: string;
  disabled?: boolean;
  data: () => FieldArray<ProjectFormData, N>;
}>;

export function SidePanelEmptyTable<N extends FieldArrayPath<ProjectFormData>>({
  label,
  className,
  disabled,
  data,
  name,
  children,
}: Props<N>) {
  const { append } = useFieldArrayContext<ProjectFormData, N>();
  const onAddHandler: MouseEventHandler = (e) => {
    e.preventDefault();
    append(data());
  };

  return (
    <div>
      <div className={styles.tableHeading}>{label}</div>
      <button
        data-testid={`emptyTable-${name || label}`}
        className={cn(styles.emptyMessageRow, className)}
        disabled={disabled}
        onClick={disabled ? undefined : onAddHandler}
      >
        {children}
      </button>
    </div>
  );
}
