export function upload(url, file, opts = {}) {
  const hostname = (opts.hostname && opts.hostname.replace(/\/$/, '')) || '';

  url = `${hostname}/${url}`;
  return new Promise((res, rej) => {
    let k, ref, v;
    const xhr = new window.XMLHttpRequest();
    if (opts && typeof opts.onProgress === 'function') {
      xhr.upload.addEventListener('progress', (e) => {
        let progress = 0;
        if (e.total !== 0) {
          progress = parseInt((e.loaded / e.total) * 100, 10);
        }
        opts.onProgress(progress);
      });
    }
    xhr.addEventListener('load', function (evt) {
      if (this.status >= 200 && this.status < 300) {
        return res(JSON.parse(xhr.responseText));
      }
      return rej(xhr);
    });
    xhr.addEventListener('error', (evt) => {
      return rej(xhr);
    });
    // xhr.withCredentials = opts.withCredentials
    xhr.open('POST', url, true);
    if (opts.headers) {
      ref = opts.headers;
      for (k in ref) {
        v = ref[k];
        xhr.setRequestHeader(k, v);
      }
    }
    return xhr.send(file);
  });
}
