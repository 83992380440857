import { getPersonDailyHours } from '@float/common/serena/util/workHours';
import { DateString, Person, Timeoff } from '@float/types';

export type ChildRecord = {
  scheduled: number;
  billable: number;
  nonbillable: number;
  tentative: number;
  logged: {
    scheduled: number;
    billable: number;
    nonbillable: number;
    overtime: number;
  };
  timeoff: number;
  timeoff_days: number;
  holiday: number;
  holiday_days: number;
  name?: string | null;
  type?: string;
};

export type ParentRecord = ChildRecord & {
  people_id: number;
  person_name: string;
  job_title: string;
  tags: string[];
  client: string;
  project: string;
  capacity: number;
  unscheduled: () => number;
  overtime: number;
  children: Record<string, ChildRecord>;
};

export const getBlankChildRecord = () => {
  return {
    scheduled: 0,
    billable: 0,
    nonbillable: 0,
    tentative: 0,
    logged: {
      scheduled: 0,
      billable: 0,
      nonbillable: 0,
      overtime: 0,
    },
    timeoff: 0,
    timeoff_days: 0,
    holiday: 0,
    holiday_days: 0,
  };
};

// Updated parsing of timeoff data to align with api3 data format from legacy.timeoff
export const parseHolidayTimeoffRecord = (
  to: Timeoff & { name?: string; date?: DateString },
  records: Record<number, ParentRecord>,
  people: Record<number, Person>,
  user: Person,
  startDate: DateString,
  endDate: DateString,
) => {
  // filter out timeoffs that are outside the date range
  if (to.start_date > endDate || to.end_date < startDate) return;

  // Custom holiday payloads don't have people_ids, but the holiday applies
  // to all employees so we just loop through the Ids in people.
  const peopleIds = to.people_ids || Object.keys(people);

  peopleIds.forEach((p_id) => {
    const record = records[p_id];
    // If there's no parent record the user is archived or excluded so we skip
    if (!record) return;
    const recordName = to.timeoff_notes || to.name;
    const key = `holiday:${recordName}`;
    if (!record.children[key]) {
      record.children[key] = getBlankChildRecord();
      record.children[key].type = 'holiday';
      record.children[key].name = recordName;
    }

    // Note: to.name indicates that it's a custom holiday
    const day = to.name ? 1 : to.full_day;
    const hours = day
      ? getPersonDailyHours(
          to.start_date || (to?.date as DateString),
          people[p_id],
          user,
        )
      : to.hours;

    const child = record.children[key];
    child.holiday += hours || 0;
    child.holiday_days += day || 0;

    record.holiday += hours || 0;
    record.holiday_days += day || 0;
  });
};
