import {
  ADD_TIMEOFF_TYPE,
  ADD_TIMEOFF_TYPE_FAILURE,
  ADD_TIMEOFF_TYPE_SUCCESS,
  DELETE_TIMEOFF_TYPE,
  DELETE_TIMEOFF_TYPE_FAILURE,
  DELETE_TIMEOFF_TYPE_SUCCESS,
  UPDATE_TIMEOFF_TYPE,
  UPDATE_TIMEOFF_TYPE_FAILURE,
  UPDATE_TIMEOFF_TYPE_SUCCESS,
} from '@float/common/actions';

import {
  addedEntityCreator,
  addEntityCreator,
  deletedEntityCreator,
  deleteEntityCreator,
  failedAddingEntityCreator,
  failedDeletingEntityCreator,
  failedFetchingEntitiesCreator,
  failedUpdatingEntityCreator,
  fetchedEntitiesCreator,
  fetchEntitiesCreator,
  shouldFetchEntitiesCreator,
  sortEntitiesCreator,
  updatedEntityCreator,
  updateEntityCreator,
} from './creatorHelpers';

export const FETCH_TIMEOFF_TYPES = 'FETCH_TIMEOFF_TYPES';
export const FETCH_TIMEOFF_TYPES_SUCCESS = 'FETCH_TIMEOFF_TYPES_SUCCESS';
export const FETCH_TIMEOFF_TYPES_FAILURE = 'FETCH_TIMEOFF_TYPES_FAILURE';

export const SORT_TIMEOFF_TYPES = 'SORT_TIMEOFF_TYPES';

const SINGULAR = 'timeoffType';
const PLURAL = 'timeoffTypes';
const ID_PROP = 'timeoff_type_id';

const addedTimeoffType = addedEntityCreator(ADD_TIMEOFF_TYPE_SUCCESS);

const failedAddingTimeoffType = failedAddingEntityCreator(
  ADD_TIMEOFF_TYPE_FAILURE,
);

export const addTimeoffType = addEntityCreator(
  ADD_TIMEOFF_TYPE,
  SINGULAR,
  addedTimeoffType,
  failedAddingTimeoffType,
);

const deletedTimeoffType = deletedEntityCreator(DELETE_TIMEOFF_TYPE_SUCCESS);

const failedDeletingTimeoffType = failedDeletingEntityCreator(
  DELETE_TIMEOFF_TYPE_FAILURE,
);

export const deleteTimeoffType = deleteEntityCreator(
  DELETE_TIMEOFF_TYPE,
  SINGULAR,
  PLURAL,
  deletedTimeoffType,
  failedDeletingTimeoffType,
  ID_PROP,
);

const updatedTimeoffType = updatedEntityCreator(UPDATE_TIMEOFF_TYPE_SUCCESS);

const failedUpdatingTimeoffType = failedUpdatingEntityCreator(
  UPDATE_TIMEOFF_TYPE_FAILURE,
);

export const updateTimeoffType = updateEntityCreator(
  UPDATE_TIMEOFF_TYPE,
  SINGULAR,
  updatedTimeoffType,
  failedUpdatingTimeoffType,
);

const fetchedTimeoffTypes = fetchedEntitiesCreator(
  FETCH_TIMEOFF_TYPES_SUCCESS,
  (timeoffTypes) => {
    return timeoffTypes.map((timeoffType) => ({
      ...timeoffType,
      ...(timeoffType.balance_days
        ? {
            balance_days: parseFloat(timeoffType.balance_days),
          }
        : {}),
      ...(timeoffType.carryover_days
        ? {
            carryover_days: parseFloat(timeoffType.carryover_days),
          }
        : {}),
    }));
  },
);

const failedFetchingTimeoffTypes = failedFetchingEntitiesCreator(
  FETCH_TIMEOFF_TYPES_FAILURE,
);

const shouldFetchTimeoffTypes = shouldFetchEntitiesCreator(PLURAL);

export const fetchTimeoffTypes = fetchEntitiesCreator(
  FETCH_TIMEOFF_TYPES,
  PLURAL,
  shouldFetchTimeoffTypes,
  fetchedTimeoffTypes,
  failedFetchingTimeoffTypes,
  { internal_pagination: true },
);

export const sortTimeoffTypes = sortEntitiesCreator(
  SORT_TIMEOFF_TYPES,
  fetchTimeoffTypes,
);

export function fetchAllTimeoffTypes() {
  return (dispatch, getState) => {
    return dispatch(fetchTimeoffTypes()).then(() => {
      const { pageCount, lastFetchedPage } = getState()[PLURAL];
      const fetchPromises = [];
      for (let page = lastFetchedPage + 1; page <= pageCount; page++) {
        fetchPromises.push(dispatch(fetchTimeoffTypes(undefined, page)));
      }
      return Promise.all(fetchPromises);
    });
  };
}
