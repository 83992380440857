import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Location } from 'history';

import { useCallbackRef } from '@float/libs/hooks/useCallbackRef';

type BlockedState = { location: Location; action: 'PUSH' | 'REPLACE' | 'POP' };

/**
 * Makes possible to listen to page navigation attempts and block them.
 *
 * @param onNavigation Handler triggered when a navigation changes the pathname
 * @param blockNavigation Flag to block every incoming navigation that changes the pathname
 * @returns
 */
export function usePathnameChangeListener(
  onNavigation: () => void,
  blockNavigation = false,
) {
  const history = useHistory();
  const [blocked, setBlocked] = useState<BlockedState | null>(null);
  const resumed = useRef<Location | null>(null);

  const onNavigationRef = useCallbackRef(onNavigation);

  useEffect(() => {
    const currentPathname = history.location.pathname;

    // Listens for navigation attempts and makes possible to block them
    // by returning false
    return history.block((location, action) => {
      if (currentPathname === location.pathname) {
        return;
      }

      const lastResumed = resumed.current;

      if (lastResumed && location.pathname === lastResumed.pathname) {
        resumed.current = null;
        return;
      }

      onNavigationRef();

      if (blockNavigation === false) {
        return;
      }

      setBlocked({ location, action });

      return false;
    });
  }, [blockNavigation, blocked, history, onNavigationRef]);

  const isBlocked = Boolean(blocked && blockNavigation);

  // Resets the block state, deleting the lates navigation attempt
  // Used when the user decides that prefers not lose focus on the current action (e.g. filling a form in the current page)
  const cancel = useCallback(() => {
    setBlocked(null);
  }, []);

  // Resume the last blocked navigation
  const resume = useCallback(() => {
    if (blocked) {
      resumed.current = blocked.location;

      // TODO Newer react-router versions support the resume natively --> https://github.com/remix-run/history/blob/main/docs/blocking-transitions.md
      // https://linear.app/float-com/issue/FT-2264/side-panel-interactions-upgrade-react-router-to-implement-the
      if (blocked.action === 'REPLACE') {
        history.replace(blocked.location);
      } else {
        history.push(blocked.location);
      }

      setBlocked(null);
    }
  }, [history, blocked]);

  return {
    cancel,
    resume,
    isBlocked,
  };
}
