import { useState } from 'react';
import { plural } from '@lingui/macro';

import { savePerson } from '@float/common/actions';
import { PersonFormData } from '@float/common/actions/people/helpers/mapV3PersonToSave';
import { trackEvent } from '@float/common/lib/analytics';
import { useAppDispatchStrict } from '@float/common/store';
import { logger } from '@float/libs/logger';
import { moment } from '@float/libs/moment';
import { Person } from '@float/types/person';
import { useSnackbar } from '@float/ui/deprecated';

import { InputItemState, QuickAddMethod } from './useInputList';

export type SubmitResult = {
  index: number;
  data: InputItemState;
  success?: boolean;
  error?: boolean;
};

export const useSubmit = (
  inputListState: InputItemState[],
  seatsAvailable: number,
  onClose: () => void,
  onChange: (isDirty: boolean) => void,
  addMethod: QuickAddMethod,
) => {
  const dispatch = useAppDispatchStrict();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { showSnackbar } = useSnackbar();

  const addPerson = async (
    data: InputItemState,
    index: number,
  ): Promise<SubmitResult> => {
    try {
      const person: Partial<Person> = {
        active: 1,
        employee_type: 1,
        name: data.value,
        people_type_id: 1,
        start_date: moment().startOf('year').format('YYYY-MM-DD'),
      };

      // @ts-expect-error savePerson expects dispatch of type AppDispatch
      await dispatch(savePerson(person as unknown as PersonFormData));

      return {
        index,
        data,
        success: true,
      };
    } catch (e) {
      logger.error('Failed to add person in the QuickAddModal', e);

      return {
        index,
        data,
        error: true,
      };
    }
  };

  const submit = async () => {
    if (isSubmitting) return;

    setIsSubmitting(true);

    const results = await Promise.all(
      inputListState
        .filter(
          (data, index) =>
            data.value.trim() !== '' &&
            !data.disabled &&
            // safe guard to make sure users do not by pass the check above somehow
            index < seatsAvailable,
        )
        .map(async (data, index) => await addPerson(data, index)),
    );

    setIsSubmitting(false);

    onSubmitComplete(results);
  };

  const onSubmitComplete = (results: SubmitResult[]) => {
    onChange(false);
    onClose();

    const peopleAdded = results.filter(
      (result) => result.success === true,
    ).length;

    trackEvent('Imported people', {
      successCount: peopleAdded,
      source: `quickadd ${addMethod}`,
    });

    showSnackbar(
      plural(peopleAdded, {
        one: `# person added`,
        other: '# people added',
      }),
    );
  };

  return { submit, isSubmitting };
};
