import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { AppDispatchStrict } from '@float/common/store';
import { logger } from '@float/libs/logger';

import API3 from '../../api3';

export const BUDGETS_LOAD_START = 'budgets/LOAD_START';
export const BUDGETS_LOAD_FAILED = 'budgets/LOAD_FAILED';
export const BUDGETS_LOAD_FINISH = 'budgets/LOAD_FINISH';

export type BudgetUsage<T> = {
  budget_total?: T;
  budget_used?: T;
  budget_logged?: T;
  budget_logged_remaining?: T;
  budget_remaining?: T;
};

export type BudgetUsageTask<T> = BudgetUsage<T> & { task_meta_id: number };

export type BudgetUsagePhase<T> = BudgetUsage<T> & {
  phase_id: number;
  tasks?: BudgetUsageTask<T>[];
};

export type BudgetUsageProject<T> = BudgetUsage<T> & {
  project_id: number;
  phases?: BudgetUsagePhase<T>[];
  tasks?: BudgetUsageTask<T>[];
};

export type BudgetsLoadStartAction = {
  type: typeof BUDGETS_LOAD_START;
  isFetchAll: boolean;
};

export type BudgetsLoadFailedAction = {
  type: typeof BUDGETS_LOAD_FAILED;
};

export type BudgetsLoadFinishActionType = {
  type: typeof BUDGETS_LOAD_FINISH;
  includeArchived: boolean;
  budgets?: BudgetUsageProject<string>[];
};

export type BudgetsAction =
  | BudgetsLoadStartAction
  | BudgetsLoadFailedAction
  | BudgetsLoadFinishActionType;

export const ensureBudgetsLoaded = (
  projectIds: number[] | null,
  opts?: {
    forceLoad?: boolean;
    notifyIfExceeded?: boolean;
    includeArchived?: boolean;
  },
) => {
  return async (
    dispatch: AppDispatchStrict,
    getState: () => ReduxStateStrict,
  ) => {
    if (getState()?.currentUser?.shared_link_view) {
      return;
    }
    const {
      forceLoad = false,
      notifyIfExceeded = false,
      includeArchived = false,
    } = opts || {};
    const { loadState } = getState().budgets;

    if (!forceLoad && loadState === 'LOADED') return;
    if (loadState === 'LOADING') return; // There's already an in-flight load request

    try {
      dispatch({ type: BUDGETS_LOAD_START, isFetchAll: !projectIds });
      // `notifyIfExceeded` is set to true if request is made due to a live
      //  update. It implies that a task was created or updated. In this
      //  scenario, we don't want to use local cache, instead fetch the latest
      //  budget usage.
      const budgets = await API3.fetchBudgetUsage(projectIds, {
        cache: !notifyIfExceeded,
        includeArchived,
      });
      dispatch({
        type: BUDGETS_LOAD_FINISH,
        budgets,
        notifyIfExceeded,
        includeArchived,
      });
    } catch (e) {
      logger.error('Failed to load budgets', e);
      dispatch({ type: BUDGETS_LOAD_FAILED });
    }
  };
};
