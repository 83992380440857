import React from 'react';
import { useFocused, useSelected } from 'slate-react';

import { StyledMention } from './styles';

const Mention = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();

  return (
    <StyledMention {...attributes} selected={selected} focused={focused}>
      {element.children[0].text}
      {children}
    </StyledMention>
  );
};

export default Mention;
