import { Range } from 'slate';

import { findWordBeginning } from './find-word-beginning';

/**
 * Find the underlying word under selection
 * @param {string} text
 * @param {Range} selection
 */
const getWordUnderCaret = (
  text: string,
  selection: Range,
): [string, number] => {
  const start = Range.isForward(selection) ? selection.anchor : selection.focus;
  const wordBeginningOffset = findWordBeginning(start.offset, text);
  return [text.slice(wordBeginningOffset, start.offset), wordBeginningOffset];
};

export { getWordUnderCaret };
