import React from 'react';
import { t } from '@lingui/macro';

import { ProjectStatus } from '@float/types';
import VirtualSelect from '@float/ui/deprecated/VirtualSelect/VirtualSelect';

import { useStatusDropdownOptions } from './StatusDropdown.hooks';

type StatusDropdownProps = {
  value: ProjectStatus;
  allowDraftOption: boolean;
  style?: React.CSSProperties;
  onChange: (value: ProjectStatus) => void;
};

export function StatusDropdown(props: StatusDropdownProps) {
  const { value, allowDraftOption, style, onChange } = props;
  const options = useStatusDropdownOptions(allowDraftOption);

  const handleChange = ({ value }: { value: ProjectStatus }) => {
    onChange(value);
  };

  return (
    <VirtualSelect
      // @ts-expect-error VirtualSelect doesn't have types
      label={t`Status`}
      placeholder={t`Empty`}
      visibleItems={6}
      creatable={false}
      searchable={false}
      clearInputOnDropdownOpen={false}
      nonNullable
      options={options}
      value={value}
      style={style}
      onChange={handleChange}
    />
  );
}
