import {
  addedEntityCreator,
  deletedEntityCreator,
  failedFetchingEntitiesCreator,
  fetchedEntitiesCreator,
  fetchEntitiesCreator,
  shouldFetchEntitiesCreator,
  updatedEntityCreator,
} from './creatorHelpers';

export const ADD_PERSON_SUCCESS = 'ADD_PERSON_SUCCESS';

export const DELETE_PERSON_SUCCESS = 'DELETE_PERSON_SUCCESS';

export const FETCH_PEOPLE = 'FETCH_PEOPLE';
export const FETCH_PEOPLE_SUCCESS = 'FETCH_PEOPLE_SUCCESS';
export const FETCH_PEOPLE_FAILURE = 'FETCH_PEOPLE_FAILURE';
// export const FETCH_SCHEDULE_PEOPLE = 'FETCH_SCHEDULE_PEOPLE';
// export const FETCH_SCHEDULE_PEOPLE_SUCCESS = 'FETCH_SCHEDULE_PEOPLE_SUCCESS';
// export const FETCH_SCHEDULE_PEOPLE_FAILURE = 'FETCH_SCHEDULE_PEOPLE_FAILURE';

export const UPDATE_PERSON_SUCCESS = 'UPDATE_PERSON_SUCCESS';

const PLURAL = 'people';

export const addedPerson = addedEntityCreator(ADD_PERSON_SUCCESS);

export const deletedPerson = deletedEntityCreator(DELETE_PERSON_SUCCESS);

export const updatedPerson = updatedEntityCreator(UPDATE_PERSON_SUCCESS);

export const fetchedPeople = fetchedEntitiesCreator(FETCH_PEOPLE_SUCCESS);

const failedFetchingPeople =
  failedFetchingEntitiesCreator(FETCH_PEOPLE_FAILURE);

const shouldFetchPeople = shouldFetchEntitiesCreator();

export const fetchPeople = fetchEntitiesCreator(
  FETCH_PEOPLE,
  PLURAL,
  shouldFetchPeople,
  fetchedPeople,
  failedFetchingPeople,
);

// export const fetchSchedulePeople = fetchEntitiesCreator(
//   FETCH_SCHEDULE_PEOPLE,
//   PLURAL,
//   shouldFetchPeople,
//   fetchedPeople,
//   failedFetchingPeople,
// );
