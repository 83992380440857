import React from 'react';
import cn from 'classnames';

import { FilterToken } from '@float/types';

import { getTokenMeta } from './helpers/getTokenMeta';

import * as styles from './styles.css';

export type FilterButtonProps = {
  filter?: FilterToken;
  active?: boolean;
  variant: 'default' | 'icon';
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLButtonElement>;

export const FilterButton = React.forwardRef<
  HTMLButtonElement,
  FilterButtonProps
>(({ active, filter, className, children, variant, ...props }, ref) => {
  const backgroundColor = filter ? getTokenMeta(filter).bg : undefined;

  return (
    <button
      ref={ref}
      data-active={active ? 'true' : null}
      className={cn(
        styles.filterButton({
          backgroundColor,
          variant,
        }),
        className,
      )}
      {...props}
    >
      {children}
    </button>
  );
});
