import React, { forwardRef, HTMLAttributes, memo } from 'react';
import cn from 'classnames';

import { FilterCategory } from '@float/common/search/types';

import { getCategoryConfig } from '../../../../helpers/getCategoryConfig';

import * as styles from './styles.css';

type SearchFilterCategoryProps = {
  type: FilterCategory;
  onClick: (value: FilterCategory) => void;
} & Pick<HTMLAttributes<HTMLButtonElement>, 'className'>;

const SearchFilterCategory = memo(
  forwardRef<HTMLButtonElement, SearchFilterCategoryProps>((props, ref) => {
    const { type, onClick, className } = props;

    const category = getCategoryConfig(type);

    if (!category) return null;

    const { icon: Icon, shortLabel, label, value } = category;

    return (
      <button
        className={cn(styles.searchFilterCategory, className)}
        onClick={() => onClick(value)}
        ref={ref}
      >
        {Icon && <Icon size={20} />}
        {shortLabel || label}
      </button>
    );
  }),
);

export { SearchFilterCategory };
