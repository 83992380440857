import React, { CSSProperties } from 'react';
import styled from 'styled-components';

import CheckerboardLight from '@float/common/assets/images/checkerboard-light.png';

const Container = styled.div`
  display: flex;
  margin: auto;
  height: 100%;
  width: 100%;
`;

type BarProps = {
  borderColor?: string;
  color: string;
  perc: number;
  backgroundImage?: string;
  leftBorder?: boolean;
};

const Bar = styled.div.attrs<BarProps>((props) => {
  const borderColor = props.borderColor
    ? props.theme[props.borderColor] || props.borderColor
    : props.theme[props.color] || props.color;

  const attrs: { style: CSSProperties } = {
    style: {
      width: `${props.perc}%`,
      backgroundColor:
        props.theme[props.color] || props.color || props.theme.blue,
      backgroundSize: '4px 4px',
      border: `${props.perc == 0 ? 0 : 1}px solid ${borderColor}`,
    },
  };

  if (props.color === 'dark-checker') {
    attrs.style.backgroundImage = `url(${CheckerboardLight}),${props.theme.teal}`;
    attrs.style.backgroundColor = props.theme.teal;
    attrs.style.backgroundRepeat = 'repeat';
    attrs.style.backgroundSize = '14px';
  }

  if (props.color === 'light-checker') {
    attrs.style.backgroundImage = `url(${CheckerboardLight}),${props.theme.midTeal}`;
    attrs.style.backgroundColor = props.theme.midTeal;
    attrs.style.backgroundRepeat = 'repeat';
    attrs.style.backgroundSize = '14px';
  }

  if (props.color === 'timeoff') {
    attrs.style.backgroundImage = 'none';
    attrs.style.backgroundColor = 'transparent';
  }

  return attrs;
})`
  height: 100%;
  position: relative;
  box-sizing: border-box;

  &:not(:first-child) {
    ${(p: BarProps) => !p.leftBorder && 'border-left: 0;'}
  }
`;

const VerticalDivider = styled.div`
  width: 2px;
  height: 100%;
  position: absolute;
  background-color: ${({ theme }) => theme.charcoalGrey};
  left: -2px;
  bottom: 0px;
`;

const getSeriesSum = (series: { value: number }[]) => {
  return series.reduce((total, bar) => bar.value + total, 0);
};

export type BarConfig = {
  backgroundImage?: string;
  borderColor?: string;
  color: string;
  divider: boolean;
  leftBorder?: boolean;
  perc: number;
  value: number;
};

type RangeBarProps = {
  series: BarConfig[];
  asPercent: boolean;
};

export const RangeBar: React.FC<RangeBarProps> = ({ series, asPercent }) => {
  let total: number;
  if (!asPercent) {
    total = getSeriesSum(series);
  }
  return (
    <Container>
      {series.map((bar, i) => {
        let perc = asPercent ? bar.value : 100 * (bar.value / total);

        if (bar.color === 'white') {
          // Since we're doing widths in percentages, the pixel value might not
          // end up as an integer. Adding 1 to the unscheduled range bar helps
          // in ensuring the capacity lines align with each other.
          perc += 1;
        }

        return (
          <Bar
            color={bar.color}
            borderColor={bar.borderColor}
            leftBorder={bar.leftBorder}
            perc={perc}
            key={i}
          >
            {bar.divider && <VerticalDivider />}
          </Bar>
        );
      })}
    </Container>
  );
};
