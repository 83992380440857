import { AccountType, CurrentUser } from '@float/types/account';
import type { Person } from '@float/types/person';
import type { Timeoff } from '@float/types/timeoff';

export const isTimeoffRequest = (
  timeoff: Timeoff,
  user: CurrentUser,
  timeoffMode = false,
) => {
  const isTimeoff = !!timeoff.timeoff_id || timeoffMode;
  const isFeatureEnabled = !!user.timeoff_approvals;
  return isFeatureEnabled && isTimeoff;
};

export const isOwnTask = (timeoff: Timeoff, user: CurrentUser) => {
  const peopleIds = timeoff.people_ids;
  return peopleIds.length == 1 && user.people_id == peopleIds[0];
};

export const isUserManaged = (
  user: CurrentUser,
  peopleMap: Record<string, Person>,
) => {
  if (!user.people_id) return false;
  const currentPerson = peopleMap[user.people_id];
  const isMember = user.account_type_id === AccountType.Member;
  if (isMember) {
    return true;
  }
  const managers = currentPerson.managers ?? [];
  return managers.filter((aid) => aid !== user.account_id).length > 0;
};

export const isRequestMode = (
  timeoff: Timeoff,
  user: CurrentUser,
  peopleMap: Record<string, Person>,
  timeoffMode = false,
) => {
  return (
    isTimeoffRequest(timeoff, user, timeoffMode) &&
    isOwnTask(timeoff, user) &&
    isUserManaged(user, peopleMap)
  );
};
