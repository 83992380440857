///<reference types="webpack-env" />

import { SearchService } from './SearchService';
import type { SearchWorkerApiLayer } from './worker/SearchWorkerApiLayer';

export const searchService = new SearchService();

// When something triggers the hot reload on the worker we
// lose the Redux state, breaking the search feature.
// To fix that issue we keep the worker instance between hot reloads.

// We have to filter by web app (the only platform using the worker)
// because this code breaks the storybook tests
if (process.env.CLIENT_ID === 'web-app' && module.hot) {
  let previousWorker: SearchWorkerApiLayer | null;

  const originalSetWorkerApi = searchService.setWorkerApi;

  // Intercept the worker setup
  searchService.setWorkerApi = (worker) => {
    previousWorker = worker;
    originalSetWorkerApi.call(searchService, worker);

    return worker;
  };

  module.hot.accept('./SearchService', async () => {
    if (previousWorker) {
      // Restore the previous worker instance
      searchService.setWorkerApi(previousWorker);
    }
  });
}
