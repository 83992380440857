import React from 'react';
import { find } from 'lodash';
import styled from 'styled-components';

import { StatusIcon } from '@float/common/components/StatusIcon/StatusIcon';
import { Card } from '@float/ui/deprecated/Earhart/Cards';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

import Body from '../Body';
import RestrictedMessage from '../RestrictedMessage';
import Table from '../Table';
import { StyledTd } from '../Timeoffs/styles';
import StatusTypeModal from './StatusTypeModal';
import { StyledRow } from './styles';

const StatusCell = ({ cell = [] }) => (
  <StyledTd>
    <StatusIcon size={20} color={cell[0]} />
    <span>{cell[1] || 'Custom'}</span>
  </StyledTd>
);

const CustomComent = styled.span`
  ${Typography.Label14.R400};
  color: ${Colors.Core.Lt.Emp.Medium};
`;

class Status extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editStatusType: null,
    };
  }

  componentDidMount() {
    this.props.onMount();
  }

  getStatusTypeById = (id) => {
    const { statusTypes } = this.props;
    return find(statusTypes, (statusType) => statusType.status_type_id === id);
  };

  render() {
    const { statusTypes, userCanSee } = this.props;
    const { editStatusType } = this.state;

    if (!userCanSee.status) {
      return <RestrictedMessage />;
    }

    const rows = Object.values(statusTypes).map((x) => {
      const isCustom = x.status_type_name === 'Custom';
      const statusLabel = isCustom ? (
        <span key="custom">
          <span>Custom </span>
          <CustomComent>
            (The color when a custom status is scheduled)
          </CustomComent>
        </span>
      ) : (
        x.status_type_name
      );

      return {
        data: [[x.color, statusLabel]],
        id: x.status_type_id,
      };
    });

    return (
      <Body
        header="Statuses"
        subheader="Manage the status types that your team uses on the schedule."
      >
        <Card>
          <StyledRow>
            <h4>Statuses</h4>
          </StyledRow>

          <StyledRow>
            <Table
              headNames={['Name']}
              recordNames={['status type', 'status types']}
              rows={rows}
              cellRenderers={[StatusCell]}
              onClickEdit={(row) => {
                const status = this.getStatusTypeById(row.id);
                this.setState({
                  editStatusType: status,
                });
              }}
              rowActions={[
                {
                  id: 'edit',
                  label: 'Edit',
                },
              ]}
            />
            {editStatusType && (
              <StatusTypeModal
                statusType={editStatusType}
                onClose={() => {
                  this.setState({
                    editStatusType: null,
                  });
                }}
              />
            )}
          </StyledRow>
        </Card>
      </Body>
    );
  }
}

Status.propTypes = {};

export default Status;
