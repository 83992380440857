import React from 'react';

import PersonAvatar from '@float/common/components/elements/PersonAvatar';
import { Activity } from '@float/types/activity';

import { StyledActionedByAvatar } from '../styles';

export const Avatar = ({ person }: { person: Activity['actioned_by'] }) => {
  return (
    <StyledActionedByAvatar>
      <PersonAvatar accountId={person.account_id} size="xs" readOnly />
      {person.name}
    </StyledActionedByAvatar>
  );
};
