import { connect } from 'react-redux';

import {
  cancelTimeTrackingTrial,
  startTimeTrackingTrial,
  updateCompanyPrefsNoRequest,
} from '@float/common/actions/companyPrefs';
import { ReduxStateStrict } from '@float/common/reducers/lib/types';

import withUserCanSee from '../../../components/decorators/withUserCanSee';
import { updateBillingPlan } from '../../actions/account/billingInfo';
import Billing from '../../components/Billing';

const mapStateToProps = (state: ReduxStateStrict) => {
  return {
    peopleCount: Number(state.companyPrefs.people_count),
    peopleLimit: Number(state.companyPrefs.p_limit),
    placeholderLimit: Number(state.companyPrefs.placeholder_limit),
    placeholderCount: Number(state.companyPrefs.placeholder_people),
    isPaidPlan: state.companyPrefs.is_paid_plan,
    hasPlusPack: state.companyPrefs.plus_pack > 0,
    plusPack: state.companyPrefs.plus_pack,
    timeTracking: state.companyPrefs.time_tracking,
    hasTimeTracking: state.companyPrefs.time_tracking > 0,
    billingInfo: state.settingsBillingInfo,
  };
};

const mapDispatchToProps = {
  startTimeTrackingTrial: startTimeTrackingTrial,
  cancelTimeTrackingTrial: cancelTimeTrackingTrial,
  updateBillingPlan: updateBillingPlan,
  updateCompanyPrefs: updateCompanyPrefsNoRequest,
};

const StatefulBilling = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withUserCanSee(Billing));

export default StatefulBilling;
