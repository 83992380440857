import React from 'react';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';

import BaseIcon, { BaseIconProps } from './BaseIcon';

export const IconWarningTriangle48x48 = (props: Partial<BaseIconProps>) => (
  <BaseIcon {...props} width="48" height="48" viewBox="0 0 48 48">
    <path
      d="M10.4041 32.7071L20.9301 11.7131C22.4059 8.76952 26.6075 8.77029 28.0822 11.7144L38.5986 32.7084C39.9309 35.3682 37.997 38.4999 35.0222 38.4999H13.9798C11.0043 38.4999 9.07045 35.367 10.4041 32.7071Z"
      stroke={Colors.Core.Text.Critical}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.5 20V24"
      stroke={Colors.Core.Text.Critical}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.5 32C25.5 32.5523 25.0523 33 24.5 33C23.9477 33 23.5 32.5523 23.5 32C23.5 31.4477 23.9477 31 24.5 31C25.0523 31 25.5 31.4477 25.5 32Z"
      stroke={Colors.Core.Text.Critical}
      strokeWidth="1.5"
    />
  </BaseIcon>
);
