export type UnsafeGlobalPageData = {
  currency: string;
};

let DO_NOT_USE_globalPageData: UnsafeGlobalPageData = {
  currency: 'USD',
};

export const DO_NOT_USE_getGlobalPageData = (): UnsafeGlobalPageData => {
  return DO_NOT_USE_globalPageData;
};

export const DO_NOT_USE_setGlobalPageData = (
  unsafeData: UnsafeGlobalPageData,
) => {
  DO_NOT_USE_globalPageData = unsafeData;
};
