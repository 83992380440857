import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';

// Note: These are defined in this file as there is no actions file associated
// with the Redux schedule state - instead, actions corresponding to these types
// are dispatched by the useScheduleDataFetcher hook.
export const FETCH_SCHED_RANGE = 'schedule/FETCH_SCHED_RANGE';
export const FETCH_SCHED_RANGE_SUCCESS = 'schedule/FETCH_SCHED_RANGE_SUCCESS';
export const FETCH_SCHED_RANGE_FAILURE = 'schedule/FETCH_SCHED_RANGE_FAILURE';
export const RESET_SCHED_RANGE = 'schedule/RESET_SCHED_RANGE';
export const SET_SCHEDULE_VISIBLE_DATA_WINDOW =
  'schedule/SET_SCHEDULE_VISIBLE_DATA_WINDOW';

export type ScheduleVisibleDataWindow = {
  start: number;
  end: number;
  peopleIds: Set<number>;
  projectsIds: Set<number>;
};

export type ScheduleState = {
  fetchedRanges: number[];
  fetchRequestedRanges: number[];
  startDate: null | string;
  endDate: null | string;
  visibleDataWindow: null | ScheduleVisibleDataWindow;
};

export type ScheduleActions =
  | {
      type: typeof FETCH_SCHED_RANGE;
      ranges: number[];
    }
  | {
      type: typeof FETCH_SCHED_RANGE_SUCCESS;
      startDate: string;
      endDate: string;
      ranges: number[];
    }
  | {
      type: typeof FETCH_SCHED_RANGE_FAILURE;
      ranges: number[];
    }
  | {
      type: typeof RESET_SCHED_RANGE;
      startDate: string;
      endDate: string;
    }
  | {
      type: typeof SET_SCHEDULE_VISIBLE_DATA_WINDOW;
      payload: ScheduleVisibleDataWindow;
    };

const DEFAULT_STATE: ScheduleState = {
  fetchedRanges: [],
  fetchRequestedRanges: [],
  startDate: null,
  endDate: null,
  visibleDataWindow: null,
};

// TODO: [APA 2020-05-27]: I don't think this is correct - it should find the
// min and max fetchedRanges and convert those to dates to get the outer bounds.
function getOverallStartEndDates(
  state: ScheduleState,
  action: { startDate: string; endDate: string },
) {
  let { startDate, endDate } = state;
  const { startDate: rangeStart, endDate: rangeEnd } = action;

  if (rangeStart && rangeEnd) {
    if (!startDate || isBefore(new Date(rangeStart), new Date(startDate))) {
      startDate = rangeStart;
    }

    if (!endDate || isAfter(new Date(rangeEnd), new Date(endDate))) {
      endDate = rangeEnd;
    }
  }

  return { startDate, endDate };
}

export default function reducer(
  state = DEFAULT_STATE,
  action: ScheduleActions,
): ScheduleState {
  switch (action.type) {
    case FETCH_SCHED_RANGE: {
      return {
        ...state,
        fetchRequestedRanges: [...state.fetchRequestedRanges, ...action.ranges],
      };
    }

    case FETCH_SCHED_RANGE_SUCCESS: {
      const { startDate, endDate } = getOverallStartEndDates(state, action);
      return {
        ...state,
        fetchedRanges: [...state.fetchedRanges, ...action.ranges],
        startDate,
        endDate,
      };
    }

    case FETCH_SCHED_RANGE_FAILURE: {
      const excludeRange = (r: number) => !action.ranges.includes(r);

      return {
        ...state,
        fetchedRanges: state.fetchedRanges.filter(excludeRange),
        fetchRequestedRanges: state.fetchRequestedRanges.filter(excludeRange),
      };
    }

    case RESET_SCHED_RANGE: {
      const { startDate, endDate } = getOverallStartEndDates(state, action);
      return {
        ...state,
        fetchedRanges: [],
        fetchRequestedRanges: [],
        startDate,
        endDate,
      };
    }

    case SET_SCHEDULE_VISIBLE_DATA_WINDOW: {
      return {
        ...state,
        visibleDataWindow: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
