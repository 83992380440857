import { omit } from 'lodash';

import { Person } from '@float/types';
import { Account } from '@float/types/account';

import {
  ACCOUNTS_DELETE,
  ACCOUNTS_LOAD_FAILED,
  ACCOUNTS_LOAD_FINISH,
  ACCOUNTS_LOAD_START,
  ACCOUNTS_UPDATE,
  PEOPLE_BULK_UPDATED,
} from '../actions';
import { checkIsPeopleManager } from '../lib/acl/access';

export type AccountsState = {
  accounts: Record<number, Account>;
  loadState: 'LOADING' | 'UNLOADED' | 'LOADED' | 'LOAD_FAILED';
};

export type AccountsAction =
  | {
      type: typeof ACCOUNTS_LOAD_START;
    }
  | {
      type: typeof ACCOUNTS_LOAD_FAILED;
    }
  | {
      type: typeof ACCOUNTS_LOAD_FINISH;
      accounts: AccountsState['accounts'];
    }
  | {
      type: typeof ACCOUNTS_UPDATE;
      account?: Account | Partial<Account>;
    }
  | {
      type: typeof ACCOUNTS_DELETE;
      account?: Account;
    }
  | {
      type: typeof PEOPLE_BULK_UPDATED;
      people: Person[];
      fields: any;
    };

const DEFAULT_STATE: AccountsState = {
  accounts: {},
  loadState: 'UNLOADED',
};

const isBulkAccountField = (field: string) =>
  ['access', 'management_group'].includes(field);

export default function reducer(
  state: AccountsState = DEFAULT_STATE,
  action?: AccountsAction,
): AccountsState {
  if (!action) return state;

  switch (action.type) {
    case ACCOUNTS_LOAD_FINISH: {
      return {
        ...state,
        loadState: 'LOADED',
        accounts: action.accounts,
      };
    }

    case PEOPLE_BULK_UPDATED: {
      const { people, fields } = action;
      const nextAccounts = { ...state.accounts };

      people.forEach((person) => {
        if (nextAccounts[person.account_id]) {
          const nextAccount = { ...nextAccounts[person.account_id] };

          Object.entries(fields).forEach(([field, update]) => {
            const skip = !isBulkAccountField(field);
            if (skip) return;

            if (field === 'access') {
              const { access, account_type_id, department_filter } =
                update as any;
              Object.assign(nextAccount, {
                access,
                account_type_id: account_type_id,
                account_type: account_type_id,
                department_filter,
              });

              if (!checkIsPeopleManager(nextAccount)) {
                nextAccount.management_group = {
                  departments: [],
                  people: [],
                };
              }
            } else if (field === 'management_group') {
              nextAccount.management_group = Object.assign(
                {
                  departments: [],
                  people: [],
                },
                nextAccount.management_group,
                update,
              );
            }
          });

          nextAccounts[person.account_id] = nextAccount;
        }
      });
      return {
        ...state,
        accounts: nextAccounts,
      };
    }

    case ACCOUNTS_UPDATE: {
      const id = action.account?.account_id;
      if (!id) return state;

      const account = {
        ...(state.accounts[id] || {}),
        ...action.account,
      } as Account;

      return {
        ...state,
        accounts: {
          ...state.accounts,
          [id]: account,
        },
      };
    }

    case ACCOUNTS_DELETE: {
      const id = action.account?.account_id;
      if (!id) return state;

      return {
        ...state,
        accounts: omit(state.accounts, id),
      };
    }

    case ACCOUNTS_LOAD_FAILED: {
      return {
        ...state,
        loadState: 'LOAD_FAILED',
        accounts: {},
      };
    }

    default: {
      return state;
    }
  }
}
