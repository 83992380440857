import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import styled from 'styled-components';

import PersonAvatar from '@float/common/components/elements/PersonAvatar';
import { isNewPlaceholderType } from '@float/common/lib/people/isNewPlaceholderType';
import { Col, Input, Modal, Row, Spacer } from '@float/ui/deprecated';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Icons from '@float/ui/deprecated/Earhart/Icons';

import { useEmailFieldState } from './hooks/useEmailFieldState';

const HeaderRow = styled(Row)`
  input {
    width: 100%;
  }
`;

const PersonModalHeader = forwardRef((props, ref) => {
  const { readOnly, form = {}, formErrors } = props;
  const personNameRef = useRef(null);
  const personAvatarRef = useRef(null);

  const { emailColor, isEmailDisabled } = useEmailFieldState({
    person: form,
  });

  const isPersonNewPlaceholderType = isNewPlaceholderType({
    person: form,
  });

  useImperativeHandle(ref, () => ({
    personAvatar: personAvatarRef?.current,
    focusOnInputName: () => {
      personNameRef.current?.focusInput();
    },
  }));

  return (
    <HeaderRow alignItems="flex-start">
      <Col alignItems="flex-start" justifyContent="flex-start">
        {readOnly ? (
          <Modal.Title style={{ lineHeight: '40px' }}>{form.name}</Modal.Title>
        ) : (
          <>
            <Input
              value={form.name}
              onChange={(value) => props.onFieldChange('name', value)}
              errors={formErrors.name}
              ref={personNameRef}
              placeholder="Name"
              placeholderIcon={
                <Icons.IconPencil
                  width={24}
                  height={24}
                  color={Colors.TCore.Emp.Low9}
                />
              }
              autoFocus
              noBorder
              size="xlarge"
              maxLength={125}
              style={{
                width: 418,
              }}
            />
            <Input
              value={form.email}
              noBackground
              onChange={(value) => props.onFieldChange('email', value)}
              type="email"
              placeholder="Email"
              minHeight={20}
              skipErrorsIcon={true}
              placeholderIcon={<Icons.IconPen width={16} height={16} />}
              inlineErrorIcon
              color={emailColor}
              errors={formErrors.email}
              maxLength={125}
              disabled={isEmailDisabled}
              size="medium"
              noBorder
              style={{
                width: 418,
              }}
            />
            <Spacer size={10} />
          </>
        )}
      </Col>
      <Col flex="0" style={{ position: 'relative', top: -4 }}>
        <PersonAvatar
          ref={personAvatarRef}
          personId={form.people_id}
          accountId={!form.people_id && form.account && form.account.account_id}
          // Provide a placeholder name to PersonAvatar to render initials
          // while person isn't created yet, or is being edited
          // https://app.asana.com/0/1201258914201276/1201309126810298/f
          overrideName={form.name || null}
          overrideIsNewPlaceholderType={isPersonNewPlaceholderType}
          size="md"
          readOnly={readOnly || isPersonNewPlaceholderType}
        />
      </Col>
    </HeaderRow>
  );
});

export default PersonModalHeader;
