import { css } from 'styled-components';

import EH from '@float/ui/deprecated/Earhart';

import colors from '../colors';

export const TippyStyle = css`
  .tippy-box[data-animation='fade'][data-state='hidden'] {
    opacity: 0;
  }
  [data-tippy-root] {
    max-width: calc(100vw - 10px);
  }
  .tippy-box {
    position: relative;
    background-color: white;
    text-align: center;
    color: ${colors.charcoalGrey};
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.4;
    outline: 0;
    transition-property: transform, visibility, opacity;
    box-shadow:
      0 5px 10px 0 rgba(110, 110, 113, 0.3),
      0 1px 3px 0 rgba(0, 0, 0, 0.2);
  }
  .tippy-box[data-placement^='top'] > .tippy-arrow {
    bottom: 0;
  }
  .tippy-box[data-placement^='top'] > .tippy-arrow:before {
    bottom: -7px;
    left: 0;
    border-width: 7px 8px 0;
    border-top-color: initial;
    transform-origin: center top;
  }
  .tippy-box[data-placement^='top'] > .tippy-arrow:after {
    bottom: -8px;
    left: 0;
    border-width: 7px 8px 0;
    border-top-color: rgba(0, 8, 16, 0.1);
    transform-origin: center top;
  }
  .tippy-box[data-placement^='bottom'] > .tippy-arrow {
    top: 0;
  }
  .tippy-box[data-placement^='bottom'] > .tippy-arrow:before {
    top: -7px;
    left: 0;
    border-width: 0 8px 7px;
    border-bottom-color: initial;
    transform-origin: center bottom;
  }
  .tippy-box[data-placement^='bottom'] > .tippy-arrow:after {
    top: -8px;
    left: 0;
    border-width: 0 8px 7px;
    border-bottom-color: rgba(0, 8, 16, 0.1);
    transform-origin: center bottom;
  }
  .tippy-box[data-placement^='left'] > .tippy-arrow {
    right: 0;
  }
  .tippy-box[data-placement^='left'] > .tippy-arrow:before {
    border-width: 8px 0 8px 7px;
    border-left-color: initial;
    right: -7px;
    transform-origin: center left;
  }
  .tippy-box[data-placement^='right'] > .tippy-arrow {
    left: 0;
  }
  .tippy-box[data-placement^='right'] > .tippy-arrow:before {
    left: -7px;
    border-width: 8px 7px 8px 0;
    border-right-color: initial;
    transform-origin: center right;
  }
  .tippy-box[data-inertia][data-state='visible'] {
    transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
  }
  .tippy-arrow {
    width: 16px;
    height: 16px;
    color: white;
  }
  .tippy-arrow:before {
    content: '';
    position: absolute;
    border-color: transparent;
    border-style: solid;
    z-index: 1;
  }
  .tippy-arrow:after {
    content: '';
    position: absolute;
    border-color: transparent;
    border-style: solid;
  }
  .tippy-content {
    position: relative;
    padding: 9px 14px;
    z-index: 1;
  }

  .tippy-box[data-theme~='hover'] {
    background-color: #333;
    color: #fff;
    font-size: 12px;
    box-shadow:
      0 5px 20px 0 rgba(0, 0, 0, 0.1),
      0 1px 10px 0 rgba(0, 0, 0, 0.1);

    .tippy-content {
      padding: 6px 9px;
    }

    .tippy-arrow:after {
      display: none;
    }

    &[data-placement^='top'] > .tippy-arrow::before {
      border-top-color: #333;
      border-width: 9px 8px 0;
    }
    &[data-placement^='bottom'] > .tippy-arrow::before {
      border-bottom-color: #333;
      border-width: 0 8px 9px;
    }
    &[data-placement^='left'] > .tippy-arrow::before {
      border-left-color: #333;
      border-width: 8px 0 8px 9px;
    }
    &[data-placement^='right'] > .tippy-arrow::before {
      border-right-color: #333;
      border-width: 8px 9px 8px 0;
    }
  }

  .tippy-box[data-theme~='task-edit-modes'] {
    background-color: #4c4c4c;
    color: #fff;
    font-size: 14px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
    left: 36px;

    .tippy-content {
      padding: 8px 14px 10px;
    }

    .tippy-arrow {
      left: -36px !important;
    }

    .tippy-arrow:after {
      display: none;
    }

    &[data-placement^='top'] > .tippy-arrow::before {
      border-top-color: #4c4c4c;
      border-width: 9px 8px 0;
    }
    &[data-placement^='bottom'] > .tippy-arrow::before {
      border-bottom-color: #4c4c4c;
      border-width: 0 8px 9px;
    }
    &[data-placement^='left'] > .tippy-arrow::before {
      border-left-color: #4c4c4c;
      border-width: 8px 0 8px 9px;
    }
    &[data-placement^='right'] > .tippy-arrow::before {
      border-right-color: #4c4c4c;
      border-width: 8px 9px 8px 0;
    }
  }

  .tippy-box[data-theme~='float-nav-dropdown'] {
    border-radius: 0;

    @media all and (-ms-high-contrast: none) {
      min-width: 190px;
    }

    .tippy-content {
      padding: 0;
    }
  }

  .tippy-box[data-theme~='task-chain'] {
    border-radius: 10px;
    box-shadow: 0 6px 30px 5px rgba(0, 0, 0, 0.12);

    & > .tippy-arrow:after {
      border-bottom-color: initial;
      top: -7px;
    }

    .tippy-content {
      padding: 16px;
      min-width: 200px;
      text-align: left;
    }
  }

  .tippy-box[data-theme~='hint'] {
    background-color: ${colors.emphasisHigh};
    border-radius: 6px;
    box-shadow: none;

    .tippy-content {
      padding: 10px;
      font-size: 14px;
      line-height: 14px;
      color: white;
    }

    &[data-placement^='top'] > .tippy-arrow::before {
      border-top-color: ${colors.emphasisHigh};
    }
    &[data-placement^='bottom'] > .tippy-arrow::before {
      border-bottom-color: ${colors.emphasisHigh};
    }
    &[data-placement^='left'] > .tippy-arrow::before {
      border-left-color: ${colors.emphasisHigh};
    }
    &[data-placement^='right'] > .tippy-arrow::before {
      border-right-color: ${colors.emphasisHigh};
    }
  }

  .tippy-box[data-theme~='clear'] {
    background-color: transparent;
  }

  .tippy-box[data-theme~='menu'] {
    ${EH.Elevations.E150};

    border-radius: 10px;

    background-color: ${EH.Colors.FIN.Lt.Emphasis.White};

    .tippy-content {
      padding: 0px;
      font-size: 14px;
      line-height: 14px;
      color: white;
      overflow: hidden;
      border-radius: inherit;
    }

    .tippy-arrow {
      z-index: 1; // fixes arrow displayed behind shadow
      &:before,
      &:after {
        transform: scale(0.9);
      }
    }
  }

  .tippy-box[data-theme~='menu'].datepicker {
    width: 300px;
    padding: 16px 24px 24px;

    &,
    & * {
      box-sizing: border-box;
    }

    .tippy-content {
      color: ${EH.Colors.FIN.Lt.Emphasis.High};
      overflow: visible;
    }
  }

  .tippy-box[data-theme~='overflow'] {
    .tippy-content {
      overflow: visible !important;
    }
  }

  .tippy-box[data-theme~='menu-E400'] {
    ${EH.Elevations.E400}

    border-radius: 10px;

    background-color: ${EH.Colors.FIN.Lt.Surface.Surf1};

    .tippy-content {
      padding: 0px;
      overflow: hidden;
      border-radius: inherit;
    }

    .tippy-arrow {
      z-index: 1; // fixes arrow displayed behind shadow
      &:before,
      &:after {
        transform: scale(0.9);
      }
    }
  }

  .tippy-box[data-theme~='menu-E300'] {
    ${EH.Elevations.E300};

    background-color: ${EH.Colors.FIN.Lt.Surface.Surf1};

    .tippy-content {
      padding: 0px;
      overflow: hidden;
      border-radius: inherit;
    }

    .tippy-arrow {
      z-index: 1; // fixes arrow displayed behind shadow
      &:before,
      &:after {
        transform: scale(0.9);
      }
    }
  }

  .tippy-box[data-theme~='milestone-header'] {
    pointer-events: all;
  }

  .tippy-box[data-theme~='more-filters'] {
    color: ${EH.Colors.FIN.Lt.Emphasis.Medium};
    pointer-events: all;
    border-radius: 6px;
    box-sizing: border-box;
    box-shadow:
      0px 18px 52px rgba(9, 30, 66, 0.35),
      0px 0px 1px rgba(95, 106, 126, 0.15);

    .tippy-content {
      padding: 0;
      text-align: left;
    }
  }

  .tippy-box[data-theme~='insight'] {
    .tippy-content {
      padding: 4px 10px;
    }

    .tippy-arrow:before {
      border-width: 7px 7px 7px 0;
    }
  }
`;
