import React, { ReactNode } from 'react';
import cn from 'classnames';

import { CycleEdgeCase, CycleVariant } from '../Cycles.types';

import * as styles from '../Cycles.css';

const getActiveStateVariant = (isActive: boolean) =>
  isActive ? 'active' : 'inactive';

const CycleSubsectionWrapper: React.FC<{
  children: ReactNode;
  width: number;
}> = ({ children, width }) => {
  return (
    <div
      style={{
        width,
        minWidth: width,
      }}
      className={cn(styles.cycleSubsectionWrapper)}
    >
      {children}
    </div>
  );
};

type CycleSpanProps = {
  dayWidth: number;
  isActive: boolean;
  totalDays: number;
};

export const CycleSpan: React.FC<CycleSpanProps> = ({
  dayWidth,
  totalDays,
  isActive,
}) => {
  const variant = getActiveStateVariant(isActive);
  const width = dayWidth * totalDays;

  return (
    <div
      style={{
        width,
        minWidth: width,
      }}
      className={styles.cycleIndicatorSpan[variant]}
    />
  );
};

const SingleDayCycleIndicator: React.FC<{
  dayWidth: number;
  isActive: boolean;
}> = ({ dayWidth, isActive }) => {
  const variant = getActiveStateVariant(isActive);

  return (
    <CycleSubsectionWrapper width={dayWidth}>
      <div className={styles.cycleLimitPointDot[variant]} />
    </CycleSubsectionWrapper>
  );
};

const StartOfCycleIndicator: React.FC<{
  dayWidth: number;
  isActive: boolean;
}> = ({ dayWidth, isActive }) => {
  const variant = getActiveStateVariant(isActive);

  return (
    <CycleSubsectionWrapper width={dayWidth}>
      <div className={styles.cycleLimitPointSpacer} />
      <div className={styles.cycleLimitPointDot[variant]} />
      <div className={styles.cycleLimitPointSpan[variant]} />
    </CycleSubsectionWrapper>
  );
};

const EndOfCycleIndicator: React.FC<{
  cycleId: string | number;
  dayWidth: number;
  isActive: boolean;
}> = ({ cycleId, dayWidth, isActive }) => {
  const variant = getActiveStateVariant(isActive);

  return (
    <CycleSubsectionWrapper width={dayWidth}>
      <div className={styles.cycleLimitPointSpan[variant]} />
      <div
        data-end-of-cycle={cycleId}
        className={styles.cycleLimitPointDot[variant]}
      />
      <div className={styles.cycleLimitPointSpacer} />
    </CycleSubsectionWrapper>
  );
};

export const EmptyCycles = () => (
  <div className={styles.allCyclesWrapper}></div>
);

export type CycleProps = {
  cycleId: string | number;
  dayWidth: number;
  edgeCase?: CycleEdgeCase;
  spanDays: number;
  totalDays: number;
  variant: Omit<CycleVariant, CycleVariant.Cooldown>;
};

/**
 * Renders a series of elements representing a single cycle over a
 * series of evenly sized days.
 *
 * Represented symbolically, if:
 *
 * 'o' is a single day marker
 * '<' is start day marker
 * '>' is end marker
 * '-' is a one day span
 *
 * Then:
 *
 * 1 day cycle: 'o'
 * 2 day cycle: '<>'
 * 3 day cycle: '<->'
 * 4 day cycle: '<-->'
 *
 *
 * NOTE: As of Mar 20, 2024 our Timeframes integration will not
 * generate single days cycles but we are supporting them here anyways
 * for the sake of future-proofing.
 */

export const Cycle: React.FC<CycleProps> = ({
  cycleId,
  dayWidth,
  edgeCase,
  spanDays,
  totalDays,
  variant,
}) => {
  const isCurrentCycle = variant === CycleVariant.Current;

  if (totalDays === 0) {
    return null;
  }

  if (totalDays === 1) {
    return (
      <div className={styles.singleCycleWrapper}>
        <SingleDayCycleIndicator
          dayWidth={dayWidth}
          isActive={isCurrentCycle}
        />
      </div>
    );
  }

  let markStartOfCycle = true;
  let markEndOfCycle = true;

  if (edgeCase === CycleEdgeCase.StartsOutOfBounds) {
    markStartOfCycle = false;
  } else if (edgeCase === CycleEdgeCase.EndsOutOfBounds) {
    markEndOfCycle = false;
  } else if (edgeCase === CycleEdgeCase.StartsAndEndsOutOfBounds) {
    markStartOfCycle = false;
    markEndOfCycle = false;
  }

  return (
    <div className={styles.singleCycleWrapper}>
      {markStartOfCycle && (
        <StartOfCycleIndicator dayWidth={dayWidth} isActive={isCurrentCycle} />
      )}
      {spanDays >= 1 && (
        <CycleSpan
          dayWidth={dayWidth}
          isActive={isCurrentCycle}
          totalDays={spanDays}
        />
      )}
      {markEndOfCycle && (
        <EndOfCycleIndicator
          cycleId={cycleId}
          dayWidth={dayWidth}
          isActive={isCurrentCycle}
        />
      )}
    </div>
  );
};
