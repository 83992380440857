import { insightsDisabled, insightsUpdated } from '../actions/timeRange';

export const DEFAULT_STATE: TimeRangeState = {
  loadState: 'INITIAL',
  insights: {
    byPerson: {},
    byProject: {},
    total: {
      capacity: 0,
      h: 0,
      logged: 0,
      overtime: 0,
      totalCapacity: 0,
    },
  },
};

export type InsightsEntry = {
  capacity: number;
  h: number;
  logged: number;
  overtime: number;
  totalCapacity: number;
};

export type TimeRangeState = {
  loadState: 'INITIAL' | 'LOADED';
  insights: {
    byPerson?: Record<number, InsightsEntry>;
    byProject?: Record<number, InsightsEntry & Record<number, InsightsEntry>>;
    total?: InsightsEntry;
  };
};

export type TimeRangeAction =
  | ReturnType<typeof insightsUpdated>
  | ReturnType<typeof insightsDisabled>;

export const timeRange = (
  state = DEFAULT_STATE,
  action: TimeRangeAction,
): TimeRangeState => {
  switch (action.type) {
    case insightsUpdated.type: {
      return { insights: action.payload, loadState: 'LOADED' };
    }

    case insightsDisabled.type: {
      return { insights: {}, loadState: 'INITIAL' };
    }

    default: {
      return state;
    }
  }
};
