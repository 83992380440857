import { t } from '@lingui/macro';

import {
  AsyncProjectApiPayload,
  mapPhaseRecordToPhasePayload,
  mapProjectToV3,
  RateType,
} from '@float/common/actions';
import { ProjectStatus } from '@float/types/project';

import { HandleProjectCreateProps, ProjectFormData } from '../types';

type NewPhase = AsyncProjectApiPayload['phases'][number];

export const getDefaultProjectName = (isTemplate?: boolean) =>
  isTemplate ? t`* New Template` : t`* New Project`;

function mapPhaseRecordsToPayload(
  phases?: ProjectFormData['phases'],
  project?: ProjectFormData['project'],
) {
  if (!phases) return [];
  return phases.map((projectPhaseRecord) => {
    const result: NewPhase = mapPhaseRecordToPhasePayload(projectPhaseRecord);

    // Attach data from phase panel if available
    if (projectPhaseRecord.fullPhaseData) {
      const { phase, team, tasks, milestones } =
        projectPhaseRecord.fullPhaseData;

      result.notes = phase.notes;
      result.notes_meta = phase.notes_meta;
      result.status = phase.status;
      result.tentative = phase.status === ProjectStatus.Tentative ? 1 : 0;
      result.budget_priority = phase.budget_priority;
      result.default_hourly_rate = phase.default_hourly_rate;

      result.phase_team = team;
      result.milestones = mapMilestoneRecordsToPayload(milestones);

      const isParentNonBillable = project?.non_billable || phase.non_billable;
      result.tasks = mapTaskRecordsToPayload(tasks, isParentNonBillable);
    }

    // phases in non-billable project must be non-billable
    if (project?.non_billable) result.non_billable = 1;

    // phases in a draft or tentative project must inherit the project status
    if (
      project?.status === ProjectStatus.Draft ||
      project?.status === ProjectStatus.Tentative
    ) {
      result.status = project.status;
      result.tentative = project.status === ProjectStatus.Tentative ? 1 : 0;
    }

    return result;
  });
}

function mapTaskRecordsToPayload(
  tasks?: ProjectFormData['tasks'],
  isParentNonBillable?: boolean,
) {
  if (!tasks) return [];
  const result: AsyncProjectApiPayload['tasks'] = tasks.map((t) => ({
    task_name: t.task_name,
    billable: isParentNonBillable ? 0 : t.billable,
    budget: t.budget,
  }));

  return result;
}

function mapMilestoneRecordsToPayload(
  milestones?: ProjectFormData['milestones'],
) {
  if (!milestones) return [];
  const result: AsyncProjectApiPayload['milestones'] = milestones.map((m) => ({
    name: m.name,
    date: m.date,
    end_date: m.end_date,
  }));

  return result;
}

export function mapToAsyncProjectApiPayload(
  props: HandleProjectCreateProps,
  clientId?: number,
): AsyncProjectApiPayload {
  const { project, team, phases, tasks, milestones } = props;
  const projectPayload = mapProjectToV3(project);
  return {
    ...projectPayload,
    rate_type:
      typeof projectPayload.default_hourly_rate === 'number'
        ? RateType.PerProject
        : RateType.PerPerson,
    client_id: clientId,
    name: project.project_name || getDefaultProjectName(),
    project_team: team,
    phases: mapPhaseRecordsToPayload(phases, project),
    tasks: mapTaskRecordsToPayload(tasks, project.non_billable),
    milestones: mapMilestoneRecordsToPayload(milestones),
  };
}
