export const FILTER_TYPES = {
  SEARCH: 'SEARCH',
  DROPDOWN: 'DROPDOWN',
};

export const LOAD_STATE = {
  UNLOADED: 'UNLOADED',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  LOAD_FAILED: 'LOAD_FAILED',
};
