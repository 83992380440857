export type IsLegacyCalendarEventProps = {
  extCalendar?: {
    version: number;
  };
  originalTask?: {
    ext_calendar_id?: string;
    ext_calendar_event_name?: string;
    name: string;
  };
};

// We support multiple calendar versions, but information is available only at calendar level, not task.
// We can infer legacy version based on task name and event name - newer versions have different values.
export function getIsLegacyCalendarEvent({
  extCalendar,
  originalTask,
}: IsLegacyCalendarEventProps): boolean {
  if (!originalTask?.ext_calendar_id) return false;

  return extCalendar
    ? extCalendar.version === 1
    : originalTask.name === originalTask.ext_calendar_event_name;
}
