import React from 'react';

export default function ({ size = '24', color = '#ffffff' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7068_13666)">
        <path
          d="M19.8501 12L7.3501 5.75V18.25L19.8501 12Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7068_13666">
          <rect width={size} height={size} fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}
