import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { SavedView } from '@float/types';

export function getViewPathname(view: SavedView) {
  const page = view.settings.page;

  switch (page) {
    case 'log-time':
    case 'schedule-people': {
      return '/';
    }
    case 'project-plan': {
      if (featureFlags.isFeatureEnabled(FeatureFlag.ProjectPlanInMainNav)) {
        return '/project-plan';
      }
      return '/';
    }
    case 'log-my-time': {
      return '/log-time';
    }
    case 'manage-people': {
      return '/people';
    }
    case 'manage-projects': {
      return '/projects';
    }
    case 'people-report':
    case 'projects-report': {
      return '/report';
    }
  }

  return '/';
}
