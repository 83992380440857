import { ChartComparisonModes } from '@float/ui/deprecated/Chart/types';

import * as departmentsTable from './table/departments';
import * as peopleTable from './table/people';
import * as projectsTable from './table/projects';
import * as rolesTable from './table/roles';
import * as tasksTable from './table/tasks';
import { getTables as getTimeoffsTables } from './table/timeoffs';
import getTimetrackingTable from './table/timetracking';
import {
  TeamCapacityTableContext,
  TeamCapacityTableDataRaw,
} from './table/types';

function getPeopleTable(
  ctx: TeamCapacityTableContext,
  raw: TeamCapacityTableDataRaw,
) {
  const { mode } = ctx;

  if (mode === ChartComparisonModes.SCHEDULED)
    return peopleTable.getScheduledTable(ctx, raw);
  if (mode === ChartComparisonModes.LOGGED)
    return peopleTable.getLoggedTable(ctx, raw);
  if (mode === ChartComparisonModes.COMPARE)
    return peopleTable.getCompareTable(ctx, raw);
  if (mode === ChartComparisonModes.COMBINED)
    return peopleTable.getCombinedTable(ctx, raw);
}

function getDepartmentsTable(
  ctx: TeamCapacityTableContext,
  raw: TeamCapacityTableDataRaw,
) {
  const { mode } = ctx;

  if (mode === ChartComparisonModes.SCHEDULED)
    return departmentsTable.getScheduledTable(ctx, raw);
  if (mode === ChartComparisonModes.LOGGED)
    return departmentsTable.getLoggedTable(ctx, raw);
  if (mode === ChartComparisonModes.COMPARE)
    return departmentsTable.getCompareTable(ctx, raw);
  if (mode === ChartComparisonModes.COMBINED)
    return departmentsTable.getCombinedTable(ctx, raw);
}

function getProjectsTable(
  ctx: TeamCapacityTableContext,
  raw: TeamCapacityTableDataRaw,
) {
  const { mode } = ctx;

  if (mode === ChartComparisonModes.SCHEDULED)
    return projectsTable.getScheduledTable(ctx, raw);
  if (mode === ChartComparisonModes.LOGGED)
    return projectsTable.getLoggedTable(ctx, raw);
  if (mode === ChartComparisonModes.COMPARE)
    return projectsTable.getCompareTable(ctx, raw);
  if (mode === ChartComparisonModes.COMBINED)
    return projectsTable.getCombinedTable(ctx, raw);
}

function getRolesTable(
  ctx: TeamCapacityTableContext,
  raw: TeamCapacityTableDataRaw,
) {
  const { mode } = ctx;

  if (mode === ChartComparisonModes.SCHEDULED)
    return rolesTable.getScheduledTable(ctx, raw);
  if (mode === ChartComparisonModes.LOGGED)
    return rolesTable.getLoggedTable(ctx, raw);
  if (mode === ChartComparisonModes.COMPARE)
    return rolesTable.getCompareTable(ctx, raw);
  if (mode === ChartComparisonModes.COMBINED)
    return rolesTable.getCombinedTable(ctx, raw);
}

function getTasksTable(
  ctx: TeamCapacityTableContext,
  raw: TeamCapacityTableDataRaw,
) {
  const { mode } = ctx;

  if (mode === ChartComparisonModes.SCHEDULED)
    return tasksTable.getScheduledTable(ctx, raw);
  if (mode === ChartComparisonModes.LOGGED)
    return tasksTable.getLoggedTable(ctx, raw);
  if (mode === ChartComparisonModes.COMPARE)
    return tasksTable.getCompareTable(ctx, raw);
  if (mode === ChartComparisonModes.COMBINED)
    return tasksTable.getCombinedTable(ctx, raw);
}

export default function parseTableData(
  ctx: TeamCapacityTableContext,
  raw: TeamCapacityTableDataRaw,
) {
  return {
    departments: getDepartmentsTable(ctx, raw),
    people: getPeopleTable(ctx, raw),
    projects: getProjectsTable(ctx, raw),
    roles: getRolesTable(ctx, raw),
    tasks: getTasksTable(ctx, raw),
    timeoffs: getTimeoffsTables(ctx, raw),
    timetracking: getTimetrackingTable(ctx, raw),
  };
}
