import { t } from '@lingui/macro';
import { get } from 'lodash';

import { TaskMetaOption } from '@float/common/selectors/taskMetas';
import { validateTaskName } from '@float/libs/tasks/validateTaskName';
import { AllocationType, Project } from '@float/types';

export type ValidateTaskParams = {
  peopleIds: number[];
  project?: Project;
  hoursPd: number | null;
  taskName: string;
  allocationType: AllocationType;
};

export type ValidateTaskError = Partial<
  Record<'assignedElem' | 'project' | 'hoursPd' | 'task', string[]>
>;
export function validateTask(
  taskMetas: TaskMetaOption[],
  { peopleIds, project, hoursPd, taskName, allocationType }: ValidateTaskParams,
) {
  const errors: ValidateTaskError = {};

  if (!peopleIds || !peopleIds.length) {
    errors.assignedElem = [t`Please select at least one person`];
  }

  if (
    allocationType === AllocationType.HoursWithFixedDuration &&
    peopleIds.length > 1
  ) {
    errors.assignedElem = [
      t`Allocations with a fixed end date can only have 1 assignee`,
    ];
  }

  if (!get(project, 'project_name')) {
    errors.project = [t`Please select a project`];
  }
  if (!hoursPd || hoursPd === 0) {
    errors.hoursPd = [t`Invalid`];
  }

  if (project?.locked_task_list == 1) {
    const taskNameError = validateTaskName({
      taskMetas,
      project,
      taskName,
    });

    if (taskNameError) {
      errors.task = taskNameError;
    }
  }

  if (Object.keys(errors).length > 0) {
    return errors;
  }
}
