import React from 'react';

export default function ({ size = 24, color = '#1A90DC' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="12" cy="12" r="12" fill={color} />
        <path fill="#FFF" d="M10 17.95L5 13l2-2 3 3 7-7 2 2z" />
      </g>
    </svg>
  );
}
