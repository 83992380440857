import React from 'react';
import { isEmpty } from 'lodash';
import ProjectColor from 'manage/projects-v2/ProjectColor';

import { getDefaultPhaseForDate } from '@float/common/lib/scheduleUtils';
import {
  Button,
  DatePicker,
  Input,
  Modal,
  ReadOnlyField,
  VirtualSelect,
} from '@float/ui/deprecated';
import * as Icons from '@float/ui/deprecated/Earhart/Icons';
import withListeners from 'components/decorators/withListeners';
import PhaseDropdown from 'components/elements/PhaseDropdown';

import {
  DateContainer,
  DeleteContainer,
  InputWrapper,
} from './MilestoneEditModal.styles';

class MilestoneEditModalBase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id,
      project_id: props.projectId,
      phase_id: props.phaseId,
      startDate: props.startDate,
      endDate: props.endDate,
      name: props.name,
      errors: {},
    };
  }

  componentDidMount() {
    if (this.props.allowPhaseSelection && this.hasPhases() && !this.state.id) {
      this.deriveDefaultPhase();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // The ability to choose a project implies the ability to choose a phase
    if (prevState.project_id !== this.state.project_id && this.hasPhases()) {
      this.deriveDefaultPhase();
    }
  }

  deriveDefaultPhase = () => {
    const phases = this.props.projectPhases[this.state.project_id];
    this.setState({
      phase_id: getDefaultPhaseForDate({ phases, start: this.state.startDate }),
    });
  };

  handleChangeName = (evt) => {
    const { value } = evt.currentTarget;
    this.setState({ name: value });
  };

  handleChangeStartDate = (date) => {
    this.setState((ps) => {
      const endDate = date.isAfter(ps.endDate) ? date.clone() : ps.endDate;

      return {
        startDate: date,
        endDate,
      };
    });
  };

  handleChangeEndDate = (date) => {
    this.setState((ps) => {
      const startDate = date.isBefore(ps.startDate)
        ? date.clone()
        : ps.startDate;

      return {
        startDate,
        endDate: date,
      };
    });
  };

  handleRemove = (e) => {
    e.preventDefault();
    this.props.onRemove();
  };

  submit = () => {
    const errors = {};

    if (!this.state.name) errors.name = ['Please enter a name'];
    if (!this.state.project_id) errors.project = ['Please select a project'];

    this.setState({ errors });

    if (isEmpty(errors)) {
      this.props.onUpdate(this.state);
    }
  };

  onProjectSelect = (opt) => {
    this.setState({ project_id: opt.value, phase_id: null });
  };

  onPhaseSelect = (opt) => {
    this.setState({ phase_id: opt.value == 0 ? null : opt.value });
  };

  getProjectName = () => {
    const project = this.props.projects[this.state.project_id];
    let projectName = project.project_name;
    if (!project.active) {
      projectName += ' (Archived)';
    }
    if (project.client_name && project.client_name !== 'No Client') {
      projectName = `${project.client_name} / ${projectName}`;
    }
    return projectName;
  };

  getProjectsOptions = () => {
    return this.props.projectsOptions.map((group) => ({
      ...group,
      options: group.options.map((o) => ({
        ...o,
        icon: <ProjectColor color={o.color} />,
      })),
    }));
  };

  hasPhases = () => {
    return this.props.projectPhases[this.state.project_id]?.length > 0;
  };

  renderReadOnly = () => {
    const wrapperStyle = { marginBottom: 21 };
    const DATE_FORMAT = 'DD MMM YYYY';
    return (
      <Modal.Body style={{ display: 'flex', flexWrap: 'wrap' }}>
        <InputWrapper>
          <ReadOnlyField
            label="Milestone name"
            value={this.state.name}
            wrapperStyle={wrapperStyle}
          />
        </InputWrapper>
        <InputWrapper>
          <ReadOnlyField
            label="Project"
            value={this.getProjectName()}
            wrapperStyle={wrapperStyle}
          />
          {this.state.phase_id && (
            <ReadOnlyField
              type="phase"
              value={
                this.props.phases[this.state.phase_id]?.phase_name || 'No phase'
              }
              style={wrapperStyle}
            />
          )}
        </InputWrapper>
        <DateContainer style={{ marginTop: 0, marginBottom: 30 }}>
          <ReadOnlyField
            label="From"
            value={this.state.startDate.format(DATE_FORMAT)}
            wrapperStyle={wrapperStyle}
          />
        </DateContainer>
        <DateContainer style={{ marginTop: 0, marginRight: 0 }}>
          <ReadOnlyField
            label="To"
            value={this.state.endDate.format(DATE_FORMAT)}
            wrapperStyle={wrapperStyle}
          />
        </DateContainer>
      </Modal.Body>
    );
  };

  render() {
    return (
      <Modal isOpen={true} onClose={this.props.onCancel}>
        <Modal.Header>
          <Modal.Title>
            {!this.props.readOnly && (this.state.id ? 'Edit ' : 'Add ')}
            Milestone
          </Modal.Title>
        </Modal.Header>
        {this.props.readOnly ? (
          this.renderReadOnly()
        ) : (
          <>
            <Modal.Body style={{ display: 'flex', flexWrap: 'wrap' }}>
              <InputWrapper>
                <Input
                  ref={this.milestoneNameRef}
                  label="Milestone name"
                  value={this.state.name}
                  onChange={this.handleChangeName}
                  autoFocus
                  errors={this.state.errors.name}
                />
              </InputWrapper>
              <InputWrapper style={{ marginTop: 20 }}>
                {this.props.allowProjectSelection ? (
                  <VirtualSelect
                    label="Project"
                    visibleItems={6}
                    placeholder=""
                    nonNullable
                    clearInputOnDropdownOpen={false}
                    value={this.state.project_id}
                    groupedOptions={this.getProjectsOptions()}
                    hideSelectedIcon
                    onChange={this.onProjectSelect}
                    errors={this.state.errors.project}
                  />
                ) : this.props.allowPhaseSelection && this.hasPhases() ? (
                  <ReadOnlyField
                    label="Project"
                    value={this.getProjectName()}
                  />
                ) : null}
                {this.props.allowPhaseSelection && (
                  <PhaseDropdown
                    options={this.props.phasesOptions[this.state.project_id]}
                    value={this.state.phase_id}
                    projectColor={
                      this.props.projects[this.state.project_id]?.color
                    }
                    onChange={this.onPhaseSelect}
                    style={{ marginTop: 5 }}
                  />
                )}
              </InputWrapper>
              <DateContainer>
                <DatePicker
                  label="From"
                  value={this.state.startDate}
                  disableDayFn={this.props.isNonWorkDay}
                  onChange={this.handleChangeStartDate}
                />
              </DateContainer>
              <DateContainer style={{ marginRight: 0 }}>
                <DatePicker
                  label="To"
                  value={this.state.endDate}
                  disableDayFn={this.props.isNonWorkDay}
                  onChange={(date) => {
                    this.handleChangeEndDate(date);
                  }}
                />
              </DateContainer>
            </Modal.Body>
            <Modal.Actions>
              <Button onClick={this.submit}>
                {this.state.id ? 'Update' : 'Create'}
              </Button>
              <Button appearance="secondary" onClick={this.props.onCancel}>
                Cancel
              </Button>
              {this.state.id && typeof this.props.onRemove === 'function' && (
                <DeleteContainer>
                  <Button
                    appearance="clear-danger"
                    iconRight={Icons.IconTrash}
                    tabIndex="-1"
                    onClick={this.handleRemove}
                  >
                    Delete
                  </Button>
                </DeleteContainer>
              )}
            </Modal.Actions>
          </>
        )}
      </Modal>
    );
  }
}
const enhance = withListeners([
  {
    eventType: 'keyup',
    testFn: (e) => e.keyCode === 13,
    handler: 'submit',
  },
]);

export const MilestoneEditModal = enhance(MilestoneEditModalBase);
