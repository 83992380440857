import { useFormContext } from 'react-hook-form';

import { useAppSelectorWithParams } from '@float/common/store';
import { ProjectTemplate } from '@float/types/project';
import { useTemplateOpen } from '@float/web/manage/projects-v2/templates/hooks/useTemplateOpen';
import { getTemplateById } from '@float/web/selectors';

import { ProjectFormData } from '../../../types';

export const useTemplateOpenHandler = () => {
  const handleUseTemplateOpen = useTemplateOpen();
  const { getValues } = useFormContext<ProjectFormData>();
  const templateId = getValues('templateId');
  const template: ProjectTemplate | undefined = useAppSelectorWithParams(
    getTemplateById,
    templateId,
  );
  const onUseTemplate = () => {
    if (!template) return;
    handleUseTemplateOpen(template);
  };

  return onUseTemplate;
};
