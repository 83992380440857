import React from 'react';

/**
 *
 * @param {{ className?: string, size?: string, color?: string | null }} param0
 * @returns
 */
export default function ({ className = '', size = '20', color = '#FFFFFF' }) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      version="1.1"
    >
      <g fill="none" fillRule="evenodd">
        <g id="loader" stroke={color} strokeWidth="2" strokeLinecap="round">
          <path
            d="M10,18 C14.418278,18 18,14.418278 18,10 C18,5.581722 14.418278,2 10,2 C5.581722,2 2,5.581722 2,10 C2,11.9600689 2.30606671,13.1458644 2.64664101,13.8586123"
            transform="translate(10, 10) rotate(-330) translate(-10, -10)"
            vectorEffect="non-scaling-stroke"
          />
        </g>
      </g>
    </svg>
  );
}
