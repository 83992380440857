import { differenceInDays } from 'date-fns';

import {
  formatDateRangeWithYearIfNotCurrent,
  formatDateWithYearIfNotCurrent,
} from '@float/common/lib/utils';
import {
  DAY_WIDTH,
  useScheduleContext,
} from '@float/common/serena/ScheduleContext';

import { getIsCompactDensity } from '../../../Window/projectRow.helpers';

export type ProjectRowDatesProps = {
  colIdx: number;
  dayWidth: number;
  endDate: string;
  startDate: string;
};

type ProjectRowDatesResult = {
  datesWithinViewPort: boolean;
  density: 'compact' | 'default';
  startDateText?: string;
  endDateText?: string;
  startPosition?: string;
  endPosition?: string;
};

// If there is not enough space to show the end date separately in month view
// (e.g. due to the project being 2 or 3 days long), then we show the range text
function shouldShowEndDateSeparately(
  startDate: string,
  endDate: string,
  dayWidth: number,
) {
  if (dayWidth === DAY_WIDTH.MONTH) {
    return differenceInDays(new Date(endDate), new Date(startDate)) > 3;
  }

  return startDate !== endDate;
}

export function useProjectRowDates(props: ProjectRowDatesProps) {
  const { colIdx, dayWidth, endDate, startDate } = props;
  const { dates, hourHeight } = useScheduleContext();
  const [minColIdx, minSubCol] = dates.toDescriptor(startDate);
  const [maxColIdx, maxSubCol] = dates.toDescriptor(endDate);

  const startsThisWeek = colIdx === minColIdx;
  const endsThisWeek = colIdx === maxColIdx;

  const result: ProjectRowDatesResult = {
    datesWithinViewPort: startsThisWeek || endsThisWeek,
    density: getIsCompactDensity(hourHeight) ? 'compact' : 'default',
  };

  if (!result.datesWithinViewPort) return result;

  const showEndDateSeparately = shouldShowEndDateSeparately(
    startDate,
    endDate,
    dayWidth,
  );

  if (startsThisWeek) {
    result.startPosition = `${minSubCol * dayWidth}px`;
    result.startDateText = showEndDateSeparately
      ? formatDateWithYearIfNotCurrent(startDate)
      : formatDateRangeWithYearIfNotCurrent(startDate, endDate);
  }

  if (endsThisWeek) {
    result.endPosition = `${(maxSubCol + 1) * dayWidth}px`;
    result.endDateText = showEndDateSeparately
      ? formatDateWithYearIfNotCurrent(endDate)
      : undefined;
  }

  return result;
}
