import {
  FilterMatcher,
  FiltersContext,
  FiltersEntity,
  FilterTypes,
} from '../../types';
import {
  getAccountsIdsByName,
  getClientsIdsByName,
  getDeparmentsIdsByName,
  getPeopleIdsByJobTitle,
  getPeopleIdsByManager,
  getPeopleIdsByName,
  getPeopleIdsByTag,
  getPeopleIdsByType,
  getPhasesIdsByName,
} from './lib/extractors';
import {
  getProjectPeopleIds,
  getProjectPhaseIds,
  getProjectTaskIds,
} from './lib/getters';
import {
  matchProjectByStatus,
  matchString,
  matchTaskByName,
  matchTaskByStatus,
} from './lib/matchers';

const FORCE_MATCH = new Set(['timeoff', 'timeoffStatus']);

export class ProjectsFilterMatcher implements FilterMatcher<'projects'> {
  ids: Record<string, Set<number | string | undefined> | undefined> = {};
  type: FilterTypes;
  context: FiltersContext<'projects'>;
  values: string[];
  partialMatch: boolean;
  forceMatch: boolean;

  constructor(
    context: FiltersContext<'projects'>,
    type: FilterTypes,
    values: string[],
    partialMatch = false,
  ) {
    this.context = context;
    this.type = type;
    this.values = values;
    this.partialMatch = partialMatch;
    this.forceMatch = FORCE_MATCH.has(type);
  }

  private getIdsByValue(value: string) {
    switch (this.type) {
      case 'client': {
        return getClientsIdsByName(
          this.context,
          value,
          this.partialMatch,
          false,
        );
      }
      case 'department': {
        return getDeparmentsIdsByName(this.context, value, this.partialMatch);
      }
      case 'jobTitle': {
        return getPeopleIdsByJobTitle(this.context, value, this.partialMatch);
      }
      case 'manager': {
        return getPeopleIdsByManager(this.context, value, this.partialMatch);
      }
      case 'person': {
        return getPeopleIdsByName(this.context, value, this.partialMatch);
      }
      case 'personTag': {
        return getPeopleIdsByTag(this.context, value, this.partialMatch);
      }
      case 'personType': {
        return getPeopleIdsByType(this.context, value);
      }
      case 'phase': {
        return getPhasesIdsByName(this.context, value, this.partialMatch);
      }
      case 'projectOwner': {
        return getAccountsIdsByName(this.context, value, this.partialMatch);
      }
      case 'me': {
        const people_id = this.context.user.people_id;
        return new Set<number>(people_id ? [people_id] : []);
      }
      case 'task':
      case 'taskStatus':
      case 'project':
      case 'projectStatus':
      case 'projectTag': {
        return new Set<number>();
      }
    }

    return new Set<number>();
  }

  matches(entity: FiltersEntity<'projects'>): boolean {
    for (const value of this.values) {
      if (!this.ids[value]) {
        this.ids[value] = this.getIdsByValue(value);
      }

      if (this.matchesByValue(entity, value)) {
        return true;
      }
    }

    return false;
  }

  private matchesByValue(
    project: FiltersEntity<'projects'>,
    value: string,
  ): boolean {
    const ids = this.ids[value];

    if (!ids) return true;

    switch (this.type) {
      case 'client': {
        return ids.has(project.client_id);
      }
      case 'department': {
        for (const id of getProjectPeopleIds(project, this.context.search)) {
          const person = this.context.people[id];

          if (person && ids.has(person.department_id)) return true;
        }

        return false;
      }
      case 'jobTitle':
      case 'me':
      case 'manager':
      case 'person':
      case 'personTag':
      case 'personType': {
        for (const id of getProjectPeopleIds(project, this.context.search)) {
          if (ids.has(id)) return true;
        }

        return false;
      }
      case 'phase': {
        for (const id of getProjectPhaseIds(project, this.context.search)) {
          if (ids.has(id)) return true;
        }

        return false;
      }
      case 'project':
        return matchString(project.project_name, value, this.partialMatch);
      case 'projectTag': {
        for (const tag of project.tags || []) {
          if (matchString(tag, value, this.partialMatch)) return true;
        }

        return false;
      }
      case 'projectOwner': {
        return ids.has(project.project_manager);
      }
      case 'projectStatus': {
        return matchProjectByStatus(project, this.context.user, value);
      }
      case 'task': {
        for (const id of getProjectTaskIds(project, this.context.search)) {
          const task = this.context.tasks[id];

          if (task && matchTaskByName(task, value, this.partialMatch))
            return true;
        }

        return false;
      }
      case 'taskStatus': {
        for (const id of getProjectTaskIds(project, this.context.search)) {
          const task = this.context.tasks[id];

          if (task && matchTaskByStatus(task, value)) return true;
        }

        return false;
      }
    }

    return true;
  }
}
