import { moment } from '@float/libs/moment';

export const startOf = (unit) => () => moment().startOf(unit);
export const endOf = (unit) => () => moment().endOf(unit);
export const endOfXUnits =
  (x = 2, units = 'weeks') =>
  () =>
    moment().startOf('week').add(x, units).subtract(1, 'day');

const rangeOpts = [
  {
    label: 'This week',
    value: '0',
    start: startOf('week'),
    end: endOf('week'),
    type: 'week',
    range: [1, 'week'],
  },
  {
    label: 'Next week',
    value: '1',
    start: () => startOf('week')().add(1, 'week'),
    end: endOfXUnits(),
    type: 'week',
    range: [1, 'week'],
  },
  {
    label: 'Next 2 weeks',
    value: '2',
    start: () => startOf('week')().add(1, 'week'),
    end: endOfXUnits(3, 'weeks'),
    type: 'week',
    range: [2, 'weeks'],
  },
];

export default rangeOpts;
