import { createSelector } from 'reselect';
import {
  getPeopleMap,
  getProjectsMap,
  getSearchFilteredActivePeopleMap,
} from 'selectors';

import { getSearchFilteredProjects } from '@float/common/search/selectors/projects';

export const getViewTypeCounts = createSelector(
  [
    getSearchFilteredActivePeopleMap,
    getSearchFilteredProjects,
    getPeopleMap,
    getProjectsMap,
  ],
  (people, projects, allPeople, allProjects) => {
    const projectsCount = projects.filter((p) => p.active).length;
    const peopleCount = Object.keys(people).length;
    const allPeopleCount = Object.keys(allPeople).length;
    const allProjectsCount = Object.keys(allProjects).length;

    return {
      people: peopleCount,
      projects: projectsCount,
      allPeople: allPeopleCount,
      allProjects: allProjectsCount,
    };
  },
);
