import * as Comlink from 'comlink';
import { Dispatch } from 'redux';

import { AllActions } from '@float/common/reducers';

import { useScheduleContext } from '../ScheduleContext';
import FetchWorker from './ScheduleDataFetcher/api.worker?worker';
import { useScheduleDataFetcherGeneric } from './useScheduleDataFetcherGeneric';
import type { ScheduleDataFetcherAPI } from './ScheduleDataFetcher/api';

const WEEKS_PER_FETCH = 4;

const api = Comlink.wrap<ScheduleDataFetcherAPI>(new FetchWorker());

export function useScheduleDataFetcherWeb(
  reduxDispatch: Dispatch<AllActions>,
  schedule: { fetchedRanges: number[] },
) {
  const { dates } = useScheduleContext();

  const scheduleDataFetcher = useScheduleDataFetcherGeneric(
    api,
    reduxDispatch,
    schedule,
    dates,
    WEEKS_PER_FETCH,
  );

  return scheduleDataFetcher;
}
