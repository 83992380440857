import { differenceInDays } from 'date-fns';

import { parseFloatDate } from '@float/libs/dates';
import { MAX_DAYS } from '@float/libs/timeRange';

import { getErrorMessage } from '../../../lib/errors';
import { GetAction, UseCellsReducerProps, UseCellsState } from './types';

export const SELECTION_ID = -1;

export function setSelection(
  props: UseCellsReducerProps,
  state: UseCellsState,
  action: GetAction<'SET_SELECTION'>,
): UseCellsState {
  const {
    maps,
    bimaps,
    cellHelpers: { findWorkDay, buildCells, getApplicableCellKeys },
  } = props;
  const { cells } = state;

  // Currently, we only support one active selection at a time, so we can
  // take a shortcut and just use a hardcoded SELECTION_ID. This could be
  // expanded to multiple selections, or selections on multiple people.
  const { rowId, entity } = action;
  const prev = maps.selection[SELECTION_ID];

  if (entity.people_id) {
    try {
      entity.start_date = findWorkDay(cells, rowId, entity.start_date, 'R');
      entity.end_date = findWorkDay(cells, rowId, entity.end_date, 'L');
    } catch (e) {
      if (getErrorMessage(e) === 'PERSON_DATE_OUT_OF_RANGE') {
        return state;
      }
      throw e;
    }
  }

  if (
    prev &&
    prev.start_date === entity.start_date &&
    prev.end_date === entity.end_date
  ) {
    return state;
  }

  // We enforce a valid date range (based on MAX_DAYS value) to all entities with time_range_id, which includes time range and milestones.
  // Milestones are created only if it's a date range of one day, so new restriction will be valid.
  // If date range is invalid, we update action's entity date range to latest valid value.
  if (
    prev &&
    entity.time_range_id &&
    differenceInDays(
      parseFloatDate(entity.end_date),
      parseFloatDate(entity.start_date),
    ) > MAX_DAYS
  ) {
    entity.start_date = prev.start_date;
    entity.end_date = prev.end_date;
  }

  const affectedCellKeys = [];

  if (prev) {
    const prevKeys = bimaps.selection.getFwd(SELECTION_ID);
    affectedCellKeys.push(...prevKeys);
  }

  const cellKeys = getApplicableCellKeys(action.entity);
  affectedCellKeys.push(...cellKeys);

  bimaps.selection.replace(SELECTION_ID, cellKeys);
  maps.selection[SELECTION_ID] = action.entity;

  buildCells(cells, affectedCellKeys, undefined);

  return { ...state, cells };
}

export function removeSelection(
  props: UseCellsReducerProps,
  state: UseCellsState,
): UseCellsState {
  const {
    maps,
    bimaps,
    cellHelpers: { buildCells },
  } = props;
  const { cells } = state;

  const cellKeys = bimaps.selection.delete(SELECTION_ID);

  // @ts-expect-error TODO The map type should accept the undefined values
  maps.selection[SELECTION_ID] = undefined;

  buildCells(cells, cellKeys, undefined);

  return { ...state, cells };
}
