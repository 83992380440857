import React, { useImperativeHandle, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import * as Animation from '../Earhart/Animation';
import * as Colors from '../Earhart/Colors';
import * as Typography from '../Earhart/Typography';
import { FieldWrapper } from '../Input/styles';
import { FieldLabel } from '../Label';
import Spinner from '../Loader/Spinner';
import TextArea from './TextArea';

const StyledTextAreaWrapper = styled.div`
  ${Typography.TextM.R400};

  position: relative;

  display: flex;
  flex-wrap: wrap;

  min-height: 40px;

  padding-left: 16px;
  padding-right: 10px;

  margin-top: 8px;

  box-sizing: border-box;

  & {
    cursor: text;
  }

  transition-property: color;
  transition-duration: ${Animation.Settings.Duration.Normal};
  transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

  // @todo: revisit states of TextAreaField
  // for now this removes padding on the sides to the readOnly text areas
  // so content aligns with label above https://app.asana.com/0/1200985541787534/1201302010197035/f
  ${(p) => {
    if (p.readOnly)
      return css`
        padding-left: 0;
        padding-right: 0;
      `;
  }}

  // Borders
  &:after,
  &:before {
    content: '';

    position: absolute;

    transition-property: transform, opacity, border-color;
    transition-duration: ${Animation.Settings.Duration.Normal};
    transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

    box-sizing: border-box;

    pointer-events: none;
  }

  // Inner border
  &:before {
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: ${Colors.FIN.Lt.Emphasis.White};

    border-width: ${(p) => (p.noBorder ? 0 : 1)}px;
    border-style: solid;
    border-color: ${Colors.FIN.Lt.Stroke.Stroke2};
    border-radius: 5px;

    z-index: 1;

    ${(p) => {
      if (p.hasError)
        return css`
          border-color: ${Colors.FIN.Lt.Danger.Default};
        `;
    }}
  }

  // Outer border
  &:after {
    top: -2px;
    left: -2px;
    bottom: -2px;
    right: -2px;

    border-width: 2px;
    border-style: solid;
    border-color: ${Colors.Primary.Flue.Light[6]};
    border-radius: 6px;

    background-color: ${Colors.Primary.Flue.Light[6]};

    opacity: 0;
    transform: scale(0.99, 0.9);

    z-index: 0;
  }

  // Interaction states

  ${(p) => {
    if (!p.readOnly)
      return css`
        &:hover {
          &:before {
            border-color: ${Colors.FIN.Lt.Emphasis.Primary};
          }
        }
      `;
  }}

  ${(p) => {
    if (p.isFocused)
      return css`
        &:after {
          opacity: 1;
          transform: none;
        }
      `;
  }}

  ${(p) =>
    p.isLoading &&
    css`
      & > p {
        display: inline;
      }
    `}
`;

const TextAreaFieldWithRef = React.forwardRef((props, ref) => {
  const { label, style, noBorder, loading, readOnly, ...rest } = props;
  const [isFocused, setIsFocused] = useState(false);

  const textAreaRef = useRef();
  const textAreaWrapperRef = useRef();
  useImperativeHandle(ref, () => textAreaRef.current);

  const handleOnFocus = () => {
    setIsFocused(true);
  };

  const handleOnBlur = () => {
    setIsFocused(false);
  };

  return (
    <FieldWrapper style={style}>
      <FieldLabel>{label}</FieldLabel>
      <StyledTextAreaWrapper
        ref={textAreaWrapperRef}
        noBorder={noBorder}
        isFocused={isFocused}
        isLoading={loading}
        readOnly={readOnly}
        onMouseDown={(event) => {
          if (event.target === textAreaWrapperRef.current) {
            setTimeout(() => {
              textAreaRef.current.focus();
            });
          }
        }}
      >
        <TextArea
          {...rest}
          ref={textAreaRef}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          readOnly={readOnly}
        />
        {loading && <Spinner style={{ marginLeft: 5 }} />}
      </StyledTextAreaWrapper>
    </FieldWrapper>
  );
});

export default TextAreaFieldWithRef;
