import { useCallback } from 'react';
import { QueryFunctionContext, useInfiniteQuery } from '@tanstack/react-query';

import { useAppSelectorWithParams } from '@float/common/store';
import { ACTIVITY_ITEMS_PER_PAGE } from '@float/constants/activity';
import { useWebAppDispatchStrict } from '@float/web/lib/store';
import { requestNotificationsFeedPage } from '@float/web/store/notifications/notifications.actions';
import { getNotificationFeed } from '@float/web/store/notifications/notifications.selectors';

import { NotificationsFilter } from '../NotificationsPanel.types';

export const useNotificationsData = (filter: NotificationsFilter) => {
  const dispatch = useWebAppDispatchStrict();

  const { notifications, currentPage, loadState } = useAppSelectorWithParams(
    getNotificationFeed,
    filter,
  );

  const fetchNotifications = useCallback(
    async (page: number) => {
      const data = await dispatch(
        requestNotificationsFeedPage({ page: page + 1 }),
      );

      return { data, nextOffset: page + 1 };
    },
    [dispatch],
  );

  const isLoadingFirstPage = loadState !== 'LOADED' && currentPage === 0;

  const { isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ['notifications'],
    queryFn: (ctx: QueryFunctionContext<string[], number>) => {
      return fetchNotifications(ctx.pageParam as number);
    },
    getNextPageParam: (lastPage) => {
      // if previous page returned less items than the limit
      // we reached the end of the feed
      if (lastPage.data.length < ACTIVITY_ITEMS_PER_PAGE) {
        return null;
      }

      return lastPage.nextOffset;
    },
    initialPageParam: 0,
  });

  const loadMore = () => {
    if (hasNextPage && !isFetchingNextPage) fetchNextPage();
  };

  return {
    isLoadingFirstPage,
    loadMore,
    notifications,
  };
};
