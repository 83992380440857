import styled, { css } from 'styled-components';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import { respondTo } from '@float/ui/deprecated/Theme/helpers/responsive/respondTo';

export const StyledHeader = styled.header`
  display: flex;

  &.centered {
    justify-content: center;
  }
`;

export const StyledHeaderContainer = styled.div`
  display: inline-flex;

  ${respondTo(
    'small',
    '<=',
  )(css`
    max-width: 400px;
  `)};
`;

export const StyledList = styled.ul`
  display: flex;

  justify-content: space-between;
`;

export const StyledNavigationSeparator = styled.span`
  --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Low};

  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 24px;

  ${respondTo(
    'small',
    '<=',
  )(css`
    margin: 0 4px;

    > svg {
      width: 18px;
      height: 18px;
    }
  `)};
`;
