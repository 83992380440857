import React from 'react';

export default function ({ style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="179"
      height="64"
      viewBox="0 0 179 64"
      style={style}
    >
      <defs>
        <linearGradient id="0uoj9waw6a" x1="50%" x2="100%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#F37070" />
          <stop offset="100%" stopColor="#6B7177" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                fill="#6B7177"
                stroke="#4E545A"
                d="M59.5.5v63H4c-.966 0-1.841-.392-2.475-1.025C.892 61.84.5 60.966.5 60h0V4c0-.966.392-1.841 1.025-2.475C2.16.892 3.034.5 4 .5h55.5zM59.5.5H118.5V63.5H59.5zM174 .5c.966 0 1.841.392 2.475 1.025.633.634 1.025 1.509 1.025 2.475h0v56c0 .966-.392 1.841-1.025 2.475-.634.633-1.509 1.025-2.475 1.025h0-55.5V.5z"
                transform="translate(-399 -211) translate(388.5 193) translate(11 18)"
              />
              <g>
                <path
                  fill="#75C9C0"
                  d="M113 24l-4-4H4l-4 4V3c0-1.657 1.343-3 3-3h107c1.657 0 3 1.343 3 3v21z"
                  transform="translate(-399 -211) translate(388.5 193) translate(11 18) translate(3 3)"
                />
                <text
                  fill="#FFF"
                  fontFamily="Inter"
                  fontSize="12"
                  fontWeight="400"
                  transform="translate(-399 -211) translate(388.5 193) translate(11 18) translate(3 3)"
                >
                  <tspan x="7" y="14">
                    Research
                  </tspan>
                </text>
              </g>
              <g>
                <path
                  fill="url(#0uoj9waw6a)"
                  d="M113 24l-4-4H4l-4 4V3c0-1.657 1.343-3 3-3h107c1.657 0 3 1.343 3 3v21z"
                  transform="translate(-399 -211) translate(388.5 193) translate(11 18) translate(62 30)"
                />
                <text
                  fill="#FFF"
                  fontFamily="Inter"
                  fontSize="12"
                  fontWeight="400"
                  transform="translate(-399 -211) translate(388.5 193) translate(11 18) translate(62 30)"
                >
                  <tspan x="7" y="14">
                    Design
                  </tspan>
                </text>
              </g>
              <g>
                <g>
                  <path
                    fill="#FFF"
                    fillRule="nonzero"
                    d="M7.138 12.492c.14.337.006 1.612-.738 1.908-.744.296-1.694 0-1.694 0L2.98 10.95 0 13.932V.47L9.131 9.6H5.73c.167.32 1.231 2.465 1.41 2.892z"
                    transform="translate(-399 -211) translate(388.5 193) translate(11 18) translate(157 31) translate(5.6 1.6)"
                  />
                  <path
                    fill="#000"
                    d="M.8 2.4V12l2.4-2.4 2 4s.541.173.8 0 .517-.52.4-.8c-.55-1.32-2-4-2-4h2.8L.8 2.4z"
                    transform="translate(-399 -211) translate(388.5 193) translate(11 18) translate(157 31) translate(5.6 1.6)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
