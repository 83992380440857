import { fastObjectSpread } from '@float/common/lib/fast-object-spread';
import { omit } from '@float/common/lib/omit';
import { Tag } from '@float/types';

import {
  TAGS_CREATED,
  TAGS_DELETED,
  TAGS_LOAD_FAILED,
  TAGS_LOAD_FINISH,
  TAGS_LOAD_START,
  TAGS_UPDATED,
} from '../actions';

export type TagsState = {
  tags: Record<number | string, Tag>;
  loadState: 'UNLOADED' | 'LOADED' | 'LOAD_FAILED' | 'LOADING';
};

export const DEFAULT_STATE: TagsState = {
  tags: {},
  loadState: 'UNLOADED',
};

export type TagAction =
  | {
      type: typeof TAGS_LOAD_START;
    }
  | {
      type: typeof TAGS_LOAD_FAILED;
    }
  | {
      type: typeof TAGS_LOAD_FINISH;
      tags: Tag[];
    }
  | {
      type: typeof TAGS_UPDATED;
      tags: Tag[];
    }
  | {
      type: typeof TAGS_CREATED;
      tags: Tag[];
    }
  | {
      type: typeof TAGS_DELETED;
      tags: Tag[];
    };

function assignTags(map: Record<number | string, Tag>, list: Tag[]) {
  for (const tag of list) {
    map[tag.tags_id] = tag;
  }

  return map;
}

export default function reducer(
  state = DEFAULT_STATE,
  action: TagAction,
): TagsState {
  switch (action.type) {
    case TAGS_LOAD_START: {
      return {
        ...state,
        loadState: 'LOADING',
      };
    }

    case TAGS_LOAD_FAILED: {
      return {
        ...state,
        loadState: 'LOAD_FAILED',
      };
    }

    case TAGS_LOAD_FINISH: {
      if (!action.tags?.length)
        return {
          ...state,
          loadState: 'LOADED',
        };

      return {
        ...state,
        loadState: 'LOADED',
        tags: assignTags(fastObjectSpread(), action.tags),
      };
    }

    case TAGS_UPDATED:
    case TAGS_CREATED: {
      return {
        ...state,
        tags: assignTags(fastObjectSpread(state.tags), action.tags),
      };
    }

    case TAGS_DELETED: {
      const tagsToRemove = action.tags.map((t) => t.tags_id.toString());

      return {
        ...state,
        tags: omit(state.tags, tagsToRemove),
      };
    }

    default: {
      return state;
    }
  }
}
