import React from 'react';
import { Plural, Trans } from '@lingui/macro';

import { getDateString } from '@float/common/lib/utils';
import { RepeatState } from '@float/types/repeatState';

import { getRepeatIntervalDescription } from '../../InputAllocationRepeat.helpers';

import * as styles from '../../InputAllocationRepeat.css';

export const InputAllocationRepeatReadOnlyControls: React.FC<{
  repeatState: RepeatState;
  repeatTimes: number;
  repeatEndDate: Date | null;
  isIntegrationSyncLocked: boolean;
  hasRecurringExternalCalendar: boolean;
}> = ({
  repeatState,
  repeatEndDate,
  repeatTimes,
  isIntegrationSyncLocked,
  hasRecurringExternalCalendar,
}) => {
  if (!repeatState) {
    if (isIntegrationSyncLocked && hasRecurringExternalCalendar) {
      return (
        <div className={styles.repeatControlsContainer}>
          <Trans>Repeat custom</Trans>
        </div>
      );
    }
    return null;
  }

  const repeatStateDescription = getRepeatIntervalDescription(repeatState);

  const untilDate =
    repeatEndDate instanceof Date ? getDateString(repeatEndDate) : '';

  return (
    <div className={styles.repeatControlsContainer}>
      <Trans>
        <div className={styles.readOnlyRepeatingName}>
          {repeatStateDescription}
        </div>
        <div className={styles.repeatUntilContainer}>
          <span className={styles.repeatUntilItem}>
            until {untilDate} ({repeatTimes}{' '}
            <Plural value={repeatTimes} one="time" other="times" />)
          </span>
        </div>
      </Trans>
    </div>
  );
};
