import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

export type RouteWithGuardProps = {
  guard: boolean;
  redirect: string;
} & RouteProps;

export function RouteWithGuard(props: RouteWithGuardProps) {
  const { guard, redirect, ...rest } = props;

  if (!guard) {
    return <Redirect to={redirect || '/'} />;
  }

  return <Route {...rest} />;
}
