import React from 'react';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';

import BaseIcon, { BaseIconProps } from './BaseIcon';

import * as styles from './styles/IconSpinner.css';

export const IconSpinner32x32 = (props: BaseIconProps) => (
  <BaseIcon viewBox="0 0 32 32" size={32} {...props}>
    <path
      className={styles.spinner}
      d="M26.1673 16.0007C26.1673 13.8554 25.4537 11.771 24.1388 10.0759C22.8239 8.38076 20.9826 7.17119 18.9047 6.63768C16.8268 6.10417 14.6304 6.27702 12.6616 7.12902C10.6927 7.98102 9.06319 9.46376 8.02969 11.3437C6.99618 13.2236 6.6174 15.394 6.953 17.5128C7.2886 19.6317 8.31951 21.5788 9.88336 23.0473C11.4472 24.5159 13.4552 25.4225 15.5909 25.6244C17.7267 25.8263 19.869 25.312 21.6803 24.1625"
      stroke={Colors.Core.Icon.Default}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </BaseIcon>
);
