import React from 'react';
import { t } from '@lingui/macro';
import { useFailsafeActionDecorator } from 'lib/useFailsafeActionDecorator';
import { SnackbarMessages } from 'lib/useSnackbarWithUndoableAction';

import { applyView, createView, updateView } from '@float/common/actions/views';
import { Rights } from '@float/common/lib/acl';
import { getIsStarterPlan } from '@float/common/selectors/companyPrefs';
import { getUser } from '@float/common/selectors/currentUser';
import { getIsUniqueViewName } from '@float/common/selectors/views';
import {
  useAppDispatchStrict,
  useAppSelector,
  useAppStore,
} from '@float/common/store';
import { ViewsModal } from 'components/modals/ViewsModal';
import {
  ViewsDefaultValues,
  ViewsFormPayload,
} from 'components/modals/ViewsModal/ViewsModal';

export type ViewsModalContainerProps = {
  defaultValues: ViewsDefaultValues;
  onOpenChange: (open: boolean) => void;
};

const SAVE_VIEW_MESSAGES: SnackbarMessages<ViewsFormPayload> = {
  error: () => t`There was an issue while trying to save the View`,
  success: ({ name: viewName }) => t`“${viewName}” View saved`,
  undoError: () => t`There was an issue while trying to delete the View`,
  undoLoading: ({ name: viewName }) => t`Deleting “${viewName}” View`,
  undoSuccess: ({ name: viewName }) => t`“${viewName}” View deleted`,
};

const UPDATE_VIEW_MESSAGES: SnackbarMessages<ViewsFormPayload> = {
  error: () => t`There was an issue while trying to update the View`,
  success: ({ name: viewName }) => t`“${viewName}” View updated`,
  undoError: () =>
    t`There was an issue while trying to revert the View changes`,
  undoLoading: () => t`Reverting the changes on the View`,
  undoSuccess: () => t`View changes reverted`,
};

export function ViewsModalContainer(props: ViewsModalContainerProps) {
  const user = useAppSelector(getUser);
  const dispatch = useAppDispatchStrict();
  const store = useAppStore();

  const viewId = props.defaultValues.id;

  const handleViewCreate = useFailsafeActionDecorator(
    SAVE_VIEW_MESSAGES,
    async function (data: ViewsFormPayload) {
      return dispatch(
        createView({
          filters: data.filters,
          name: data.name,
          personal: data.personal,
          pinned: false,
          settings: data.settings,
        }),
      );
    },
  );

  const handleViewUpdate = useFailsafeActionDecorator(
    UPDATE_VIEW_MESSAGES,
    async function (data: ViewsFormPayload) {
      if (viewId) {
        return dispatch(
          updateView(viewId, {
            filters: data.filters,
            name: data.name,
            personal: data.personal,
            pinned: false,
            settings: data.settings,
          }),
        );
      }
    },
  );

  async function handleSubmit(data: ViewsFormPayload) {
    if (viewId) {
      return handleViewUpdate(data);
    }

    const view = await handleViewCreate(data);

    dispatch(applyView(view));
  }

  const isStarterPlan = useAppSelector(getIsStarterPlan);
  const canShareView = Rights.canCreateView(user, {
    entity: {
      created_by: user.account_id,
      personal: false,
    },
  });

  const isShareEnabled = !isStarterPlan && canShareView;

  const isUniqueViewName = (name: string, personal: boolean) => {
    if (
      viewId &&
      name.toLowerCase() === props.defaultValues.name?.toLowerCase()
    )
      return true;

    const state = store.getState();

    return getIsUniqueViewName(state, name, personal);
  };

  return (
    <ViewsModal
      defaultValues={props.defaultValues}
      onOpenChange={props.onOpenChange}
      isShareEnabled={isShareEnabled}
      isUniqueViewName={isUniqueViewName}
      onSubmit={handleSubmit}
    />
  );
}
