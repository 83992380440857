import React from 'react';
import styled from 'styled-components';

import PersonAvatar from '@float/common/components/elements/PersonAvatar';
import { Input } from '@float/ui/deprecated';

const NameInputContainer = styled.div`
  flex-basis: 100%;
  padding-right: 72px;
  margin-bottom: 4px;
  padding-top: 8px;
  padding-bottom: 24px;

  input {
    width: 100%;
  }
`;

const PersonAvatarContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 30px;
`;

export default function MyInfoModalHeader() {
  const { newAccount, errors } = this.state;
  const { currentUser } = this.props;

  const onChangeInput = (e) => {
    const { value, dataset } = e.currentTarget;
    const { name } = dataset;

    this.handleChange(name, value);
  };

  return (
    <>
      <NameInputContainer>
        <Input
          placeholder="Name"
          noBorder
          size="xlarge"
          value={newAccount.name}
          errors={errors.name}
          maxLength={125}
          onChange={onChangeInput}
          autoSize={!currentUser.sso_email}
          readOnly={!!currentUser.sso_email}
          dataName="name"
        />
        <PersonAvatarContainer>
          <PersonAvatar
            ref={this.setPersonAvatarRef}
            accountId={this.props.currentUser.account_id}
            overrideName={newAccount.name}
            size="md"
          />
        </PersonAvatarContainer>
      </NameInputContainer>
    </>
  );
}
