import { createSelector } from 'reselect';

import {
  getUserAccess,
  userCanOnlyViewThemself,
  userCanUpdateThemself,
} from '../lib/rights';
import { getUser } from './currentUser';

// since getUserAccess returns a new object everytime it's called
// this selector is to memoize that call until getUser changes,
// so it prevents selectors that depend on getUserAccess data to re-run
const geAccessRights = createSelector([getUser], (user) => {
  return getUserAccess(user);
});

export const getUserAccessRights = createSelector(
  [getUser, geAccessRights],
  (user, accessRights) => {
    const hasTimeTracking = user.time_tracking > 0 && Boolean(+user.people_id);
    const hasManage = user.account_tid != 4;

    const canOnlyViewThemself = userCanOnlyViewThemself(user);
    const canUpdateThemself = userCanUpdateThemself(user);

    return {
      accessRights,

      hasTimeTracking,
      hasManage,

      canOnlyViewThemself,
      canUpdateThemself,
    };
  },
);
