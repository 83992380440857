import React from 'react';
import { useWatch } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';

import { SidePanelAddRowButton } from '../../components/SidePanelTableSection/SidePanelAddRowButton';
import { SidePanelEmptyTable } from '../../components/SidePanelTableSection/SidePanelEmptyTable';
import { SidePanelTableRows } from '../../components/SidePanelTableSection/SidePanelTableRows';
import { createEmptyMilestoneRecord } from '../../helpers/createRowEntities';
import { useIsTemplate } from '../../hooks/useIsTemplate';
import { useProjectOrPhaseFieldValue } from '../../hooks/useProjectOrPhaseFieldValue';
import { ProjectFormData } from '../../types';
import { TemplateMilestonesRows } from '../TemplateMilestonesSection/TemplateMilestonesRows';
import { ProjectMilestoneRow } from './ProjectMilestoneRow';
import { ProjectMilestonesTableHeader } from './ProjectMilestonesTableHeader';

import {
  emptyLine,
  sidePanelTableWrapper,
} from '../../components/SidePanelTableSection/styles.css';
import * as styles from './styles.css';

function EmptyMilestoneMessage() {
  return (
    <>
      <span className={styles.emptyMilestoneMessage}>
        <Trans>Add a milestone</Trans>
      </span>
      <div className={styles.emptyMilestoneColumn}>
        <div className={emptyLine} />
      </div>
    </>
  );
}

function ProjectMilestonesRows({ addDisabled }: { addDisabled: boolean }) {
  return (
    <>
      <ProjectMilestonesTableHeader />
      <SidePanelTableRows
        name={'milestones'}
        watchUpdate={'name'}
        rowRenderer={(field, index) => (
          <ProjectMilestoneRow
            key={field.id}
            index={index}
            addDisabled={addDisabled}
          />
        )}
      />
    </>
  );
}

export const ProjectMilestonesTable = () => {
  const isActive = useProjectOrPhaseFieldValue('active');
  const milestones = useWatch<ProjectFormData, 'milestones'>({
    name: 'milestones',
  });
  const noRows = !milestones.length;
  const addButtonDisabled =
    !isActive || (!noRows && !milestones[milestones.length - 1]?.name);
  const isTemplate = useIsTemplate();

  return (
    <div className={sidePanelTableWrapper}>
      {noRows ? (
        <SidePanelEmptyTable<'milestones'>
          label={<ProjectMilestonesTableHeader />}
          name="Milestone"
          data={createEmptyMilestoneRecord}
          className={styles.emptyMilestone}
          disabled={addButtonDisabled}
        >
          <EmptyMilestoneMessage />
        </SidePanelEmptyTable>
      ) : (
        <div role="table">
          {isTemplate ? (
            <TemplateMilestonesRows />
          ) : (
            <ProjectMilestonesRows addDisabled={addButtonDisabled} />
          )}
        </div>
      )}
      <SidePanelAddRowButton<'milestones'>
        data={createEmptyMilestoneRecord}
        buttonText={t`Add a milestone`}
        disabled={addButtonDisabled}
      />
    </div>
  );
};
