import React, { forwardRef } from 'react';
import cn from 'classnames';

import { BaseIconProps } from '@float/ui/deprecated/Earhart/Icons/Icon/BaseIcon';

import { ListItemData } from '../../../../types';

import * as styles from './styles.css';

export type ListItemIconProps = {
  icon?: ListItemData['icon'];
} & BaseIconProps;

const ListItemIcon = forwardRef<SVGSVGElement, ListItemIconProps>(
  (props, ref) => {
    const { className, icon: Icon, size, children } = props;

    return (
      <div className={cn(styles.icon, className)}>
        {Icon && <Icon size={size} ref={ref} />}
        {children && children}
      </div>
    );
  },
);

export { ListItemIcon };
