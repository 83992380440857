import { trackEvent } from '@float/common/lib/analytics';
import { logger } from '@float/libs/logger';
import { OnboardingSurveyStatus } from '@float/web/store/onboardingManager/types';

import { getPromptAnalyticsMetadata } from './data';

export const trackOnboardingPrompt = (
  prompt: OnboardingSurveyStatus['navigation']['prompt'],
  data = {},
  state = 0,
) => {
  const { key, dataFn } = getPromptAnalyticsMetadata(prompt, state);

  let value;

  if (dataFn) {
    value = dataFn(data);
  } else {
    value = data;
  }

  if (key) {
    logger.log('Analytics: trackOnboardingStep', { context: { key, value } });

    trackEvent(key, value);
  }
};
