import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import useWindowSize from '@float/libs/web/hooks/useWindowSize';
import { useOnClickOutside } from '@float/ui/deprecated/hooks/useOnClickOutside';

import { StyledIconClose, StyledNotification } from './styles';

const TooltipNotification = (props) => {
  const {
    children,
    className,
    style = {},
    width = 348,

    bgColor = 'white',
    borderWidth = 1,
    borderColor = null,

    arrowPosition = null,

    anchor = null,
    position = 'top',
    alignment = 'end',
    gap = 20,

    showClose = true,
    closeOnBgClick = false,

    onClick,
    onClose,

    show = true,

    ...otherProps
  } = props;

  const { rect } = anchor || {};

  const notificationRef = useRef();

  const [positionStyles, setPositionStyles] = useState();

  const screen = useWindowSize();

  const close = useCallback(() => {
    if (typeof onClose === 'function') {
      onClose();
    }
  }, [onClose]);

  const closeClick = (e) => {
    prevent(e);

    close();
  };

  const attachToAnchor = useCallback(() => {
    if (rect) {
      const aRect = rect;
      const nRect = notificationRef.current.getBoundingClientRect();

      let top = 0;
      let left = 0;

      if (position === 'top') {
        top = aRect.top - nRect.height - gap;
      }

      if (position === 'bottom') {
        top = aRect.top + aRect.height + gap;
      }

      if (position === 'left') {
        left = aRect.left - nRect.width - gap;
      }

      if (position === 'right') {
        left = aRect.left + aRect.width + gap;
      }

      // start alignment

      if (alignment === 'start') {
        if (position === 'top' || position === 'bottom') {
          left = aRect.left;
        }

        if (position === 'left' || position === 'right') {
          top = aRect.top;
        }
      }

      // center alignment

      if (alignment === 'center') {
        if (position === 'top' || position === 'bottom') {
          left = aRect.left - nRect.width / 2 + aRect.width / 2;
        }

        if (position === 'left' || position === 'right') {
          top = aRect.top - nRect.height / 2 + aRect.height / 2;
        }
      }

      // end alignment

      if (alignment === 'end') {
        if (position === 'top' || position === 'bottom') {
          left = aRect.left + aRect.width - nRect.width;
        }

        if (position === 'left' || position === 'right') {
          top = aRect.top + aRect.height - nRect.height;
        }
      }

      setPositionStyles({
        top,
        left,
      });
    }
  }, [rect, setPositionStyles, position, alignment, gap]);

  useEffect(() => {
    attachToAnchor();
  }, [anchor, attachToAnchor]);

  useEffect(() => {
    attachToAnchor();
  }, [screen.width, screen.height, attachToAnchor]);

  useOnClickOutside(() => {
    if (closeOnBgClick) {
      close();
    }
  }, [notificationRef]);

  return (
    <StyledNotification
      ref={notificationRef}
      className={classNames(className)}
      style={{ ...style, ...positionStyles }}
      onClick={onClick}
      $bgColor={bgColor}
      $arrowPosition={arrowPosition}
      $borderWidth={borderWidth}
      $borderColor={borderColor}
      $width={width}
      $position={position}
      show={show}
      {...otherProps}
    >
      {showClose && <StyledIconClose onClick={closeClick} />}

      {children}
    </StyledNotification>
  );
};

export default TooltipNotification;
