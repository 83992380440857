import { useEffect, useState } from 'react';

import { calculateDuration } from '@float/common/lib/timer/calculateDuration';
import { durationToHHMM } from '@float/common/lib/timer/durationToHHMM';
import { Timer } from '@float/types';

export function useActiveTimerDuration(timer: Timer | null) {
  const [now, setNow] = useState(() => Date.now());

  useEffect(() => {
    if (!timer?.start_timestamp) return;

    const interval = setInterval(() => {
      setNow(Date.now());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timer?.start_timestamp]);

  if (!timer) return '00:00';

  return durationToHHMM(calculateDuration(timer.start_timestamp, now));
}
