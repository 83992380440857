import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon {...props}>
    <path
      fill="url(#icon-feature-gradient-a)"
      d="M19 3a2 2 0 1 0 0 4h10a2 2 0 1 0 0-4H19Z"
    />

    <path
      fill="url(#icon-feature-gradient-b)"
      fillRule="evenodd"
      d="M7 27c0-9.367 7.633-17 17-17s17 7.633 17 17-7.633 17-17 17S7 36.367 7 27Zm23.29 6.698c.442 0 .867-.221 1.105-.629a1.258 1.258 0 0 0-.442-1.751l-5.27-3.145c-.527-.306-1.037-1.207-1.037-1.819v-6.97c0-.697-.578-1.275-1.275-1.275s-1.275.578-1.275 1.275v6.97c0 1.513.969 3.23 2.278 4.012l5.27 3.145c.204.136.425.187.646.187Z"
      clipRule="evenodd"
    />
  </BaseIcon>
);
