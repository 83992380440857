import { trackEvent } from '@float/common/lib/gtm';
import { getAllChangedFields } from '@float/common/lib/utils';
import { moment } from '@float/libs/moment';
import { NotesMeta, Task, Timeoff } from '@float/types';
import type { EditTaskModal } from 'components/schedule/task/EditTaskModal';

type Entity = Task | Timeoff;

/**
 * We skip these fields before the change detection because they don't reflect the user interaction
 * with the edit form (related to Approval state or the manager response)
 */
const DETECT_CHANGE_IGNORE = new Set([
  'status_note',
  'status',
  'timeoff_type_id',
  'timeoff_type_name',
]);

export function getTrackTimeoffData(
  modal: EditTaskModal,
  originalEntity: Entity,
  updatedEntity: Entity,
) {
  if (!modal.isTimeoffMode()) return null;

  return {
    // @ts-expect-error The types are inferred from js
    isNewTimeoff: modal.state.isNewTask,
    isRequestMode: modal.isRequestMode(),
    timeoffApprovalsEnabled: modal.timeoffApprovalsEnabled,
    // @ts-expect-error The types are inferred from js
    notes_meta: modal.state.notes_meta as NotesMeta | null,
    managerResponse: modal.state.status_note,
    // @ts-expect-error The types are inferred from js
    date: modal.state.startDate,
    isApproveRejectMode: modal.isApproveRejectMode(),
    isRejecting: modal.isRejecting,
    isApproving: modal.isApproving,
    originalEntity,
    updatedEntity,
  };
}

export function trackTimeoff(data: ReturnType<typeof getTrackTimeoffData>) {
  if (data === null) return;

  if (data.isNewTimeoff) {
    const usingMention = Boolean(
      data.notes_meta?.some((meta) => meta.type === '@'),
    );
    const timeoffApprovalsEnabled = data.timeoffApprovalsEnabled;

    if (data.isRequestMode) {
      const requestedAfter = moment().isAfter(moment(data.date), 'D');

      trackEvent('Request Timeoff', {
        timeoffApprovalsEnabled,
        usingMention,
        requestedAfter,
      });
    } else {
      trackEvent('Enter Timeoff', {
        timeoffApprovalsEnabled,
        usingMention,
      });
    }
  } else if (data.isApproveRejectMode) {
    const commentAdded = Boolean(data.managerResponse);

    if (data.isRejecting) {
      trackEvent('Reject Timeoff', {
        commentAdded,
      });
    } else if (data.isApproving) {
      const edited = getAllChangedFields(
        data.updatedEntity,
        data.originalEntity,
      ).some((field) => !DETECT_CHANGE_IGNORE.has(field));

      trackEvent('Approve Timeoff', {
        commentAdded,
        edited,
      });
    }
  }
}
