import { ReduxStateStrict } from '@float/common/reducers/lib/types';

/**
 * @deprecated This selector should only be used by the modules inside
 * "@float/common/search" and by useReportsFetchUrl (web app)
 *
 * The derived context will be removed when fully releasing Search beyond limits
 */
export const getSearchDerivedContext = (state: ReduxStateStrict) =>
  state.search._derivedContext;
