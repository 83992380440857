import { connect } from 'react-redux';

import { addClient } from '../../actions/clients';
import AddEntry from '../../components/Table/AddEntry';

const mapStateToProps = (state) => {
  const {
    settingsClients: { beingAdded: isLoading = false },
  } = state;
  return {
    isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: async (name) => {
      try {
        await dispatch(addClient({ name }));
      } catch {
        throw new Error(`Error: "${name}" already exists.`);
      }
    },
  };
};

const AddClient = connect(mapStateToProps, mapDispatchToProps)(AddEntry);

export default AddClient;
