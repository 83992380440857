import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  createElement,
  forwardRef,
  MouseEvent,
  StrictMode,
  useRef,
} from 'react';
import { t } from '@lingui/macro';
import cn from 'classnames';

import { useCombinedRefs } from '@float/libs/hooks/useCombinedRefs';
import { noop } from '@float/libs/utils/noop';
import { IconSpinner } from '@float/ui/icons/essentials/IconSpinner';

import { useButtonAutoFocus } from './hooks/useButtonAutoFocus';
import { ButtonProps } from './types';

import * as styles from './styles.css';

export const Button = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps
>((props, ref) => {
  const {
    appearance,
    children,
    className,
    iconEnd: IconEnd,
    iconOnlyLabel,
    iconStart: IconStart,
    isLoading,
    onClick = noop,
    size,
    ...buttonAttributes
  } = props;

  const buttonRef = useRef<HTMLElement>(null);
  const combinedRefs = useCombinedRefs([ref, buttonRef]);

  const onClickHandler = (
    e: MouseEvent<HTMLButtonElement & HTMLAnchorElement>,
  ) => {
    e.currentTarget.blur();

    onClick(e);
  };

  const isIconOnly = !children;
  const showIconStart = IconStart && !isIconOnly;
  const showIconEnd = IconEnd && !isLoading;

  const icon = isIconOnly ? 'only' : 'default';

  useButtonAutoFocus(buttonRef);

  return (
    <StrictMode>
      <ButtonOrLink
        {...buttonAttributes}
        ref={combinedRefs}
        className={cn(className, styles.button({ appearance, size, icon }))}
        onClick={onClickHandler}
        // needed for Safari keyboard navigation to work as per our expectations
        // https://linear.app/float-com/issue/EXP-452
        tabIndex={0}
      >
        {showIconStart && <IconStart noScalingStroke className={styles.icon} />}

        {children && <span className={styles.label}>{children}</span>}

        {showIconEnd && (
          <IconEnd
            noScalingStroke
            className={styles.icon}
            {...(isIconOnly &&
              iconOnlyLabel && { ['aria-label']: iconOnlyLabel })}
          />
        )}

        {isLoading && (
          <IconSpinner
            aria-label={t`loading`}
            animated={true}
            className={styles.icon}
            noScalingStroke
          />
        )}
      </ButtonOrLink>
    </StrictMode>
  );
});

const ButtonOrLink = forwardRef(
  (
    props: ButtonHTMLAttributes<HTMLButtonElement> &
      Pick<AnchorHTMLAttributes<HTMLAnchorElement>, 'target' | 'href'>,
    ref,
  ) => {
    if (props.href) {
      return createElement('a', { ref, ...props });
    }

    return createElement('button', { ref, ...props });
  },
);
