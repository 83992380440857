import { DEFAULT_TAG_COLOR } from 'selectors/tags';

export const getColoredTags = (
  tags: string[],
  colorByLabel: Record<string, { color: string }>,
) => {
  return tags.map((tag) => {
    const color = colorByLabel[tag]?.color
      ? `#${colorByLabel[tag].color}`
      : DEFAULT_TAG_COLOR;

    return {
      name: tag,
      value: tag,
      label: tag,
      type: 1,
      color,
    };
  });
};
