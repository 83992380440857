import React from 'react';

import { getDateString } from '@float/common/lib/utils';
import { moment } from '@float/libs/moment';
import { DatePicker } from '@float/ui/deprecated';

import {
  calculateRepeatTimes,
  calculateTotalHours,
} from '../helpers/timeReducers';

let etmThis;

function isNonWorkDay(date) {
  const ignoreTimeoff =
    etmThis.state.taskMode === 'timeoff' && etmThis.state.isFullDay
      ? { timeoff_id: etmThis.state.task.timeoff_id }
      : undefined;

  return !etmThis.props.isWorkDay(
    etmThis.state.peopleIds,
    date.format('YYYY-MM-DD'),
    ignoreTimeoff,
  );
}

const setStartDate = (momentValue) => {
  const newStartDate = momentValue.toDate();

  if (!etmThis.state.task) {
    return;
  }
  let newState = { ...etmThis.state, startDate: newStartDate };
  if (moment(etmThis.state.endDate).isBefore(moment(newStartDate))) {
    newState.endDate = newStartDate;
  }
  newState = calculateTotalHours(etmThis.props.calcEntityLength, newState);
  newState.repeatTimes = calculateRepeatTimes(etmThis.props.dates, newState);
  etmThis.setState(newState);

  if (etmThis.isTaskMode()) {
    // Update the correct default phase
    const { phaseId } = etmThis.deriveDefaultPhase(etmThis.state.project);
    etmThis.setPhaseId(phaseId);
  }
};

const setEndDate = (momentValue) => {
  const newEndDate = momentValue.toDate();
  if (!etmThis.state.task) {
    return;
  }
  let newState = { ...etmThis.state, endDate: newEndDate };
  newState = calculateTotalHours(etmThis.props.calcEntityLength, newState);
  newState.repeatTimes = calculateRepeatTimes(etmThis.props.dates, newState);
  etmThis.setState(newState);
};

const setStartDateRef = (el) => {
  etmThis.startDateRef = el;
};

export const getStartDateElem = (self) => {
  etmThis = self;
  const readOnly = etmThis.isReadOnly() || etmThis.state.integrationSyncLocked;
  return readOnly ? (
    etmThis.renderReadOnlyInput(null, getDateString(etmThis.state.startDate), {
      noMargin: true,
      style: { flex: 1 },
    })
  ) : (
    <DatePicker
      ref={setStartDateRef}
      disableDayFn={isNonWorkDay}
      height={40}
      inputWidth={120}
      value={moment(etmThis.state.startDate)}
      onChange={setStartDate}
    />
  );
};

export const getEndDateElem = (self) => {
  etmThis = self;

  const readOnly = etmThis.isReadOnly() || etmThis.state.integrationSyncLocked;
  return readOnly ? (
    etmThis.renderReadOnlyInput(null, getDateString(etmThis.state.endDate), {
      noMargin: true,
      style: { flex: 1 },
    })
  ) : (
    <DatePicker
      disableDayFn={isNonWorkDay}
      height={40}
      inputWidth={120}
      value={moment(etmThis.state.endDate)}
      minimumDate={etmThis.state.startDate}
      onChange={setEndDate}
    />
  );
};
