import differenceInDays from 'date-fns/differenceInCalendarDays';
import differenceInSeconds from 'date-fns/differenceInSeconds';

import { fetchUser } from '@float/common/actions/currentUser';
import { ensureHolidaysLoaded } from '@float/common/actions/holidays';
import { getJWTAccessToken } from '@float/common/actions/jwt';
import { fetchLoggedTimesWithDates } from '@float/common/actions/loggedTimes';
import { ensureMilestonesLoaded } from '@float/common/actions/milestones';
import { fetchOneOffs } from '@float/common/actions/oneOffs';
import { ensureContextLoaded } from '@float/common/actions/search';
import { fetchStatuses } from '@float/common/actions/statuses';
import { ensureStatusTypesLoaded } from '@float/common/actions/statusTypes';
import { fetchTasksWithDates } from '@float/common/actions/tasks';
import { fetchTimeoffsWithDates } from '@float/common/actions/timeoffs';
import { ensureTimeoffTypesLoaded } from '@float/common/actions/timeoffTypes';
import { ensureViewsLoaded } from '@float/common/actions/views/views';
import { trackEvent } from '@float/common/lib/gtm';
import { ReduxState } from '@float/common/reducers/lib/types';
import { getLocationPathname } from '@float/common/selectors/location';
import { AppDispatch, AppStore } from '@float/common/store';
import { ensureBudgetsLoaded } from '@float/common/store/budgets/budgets.actions';

import { requestNotifications } from '../store/notifications/notifications.actions';

export const RECONNECTION_SUCCESSFUL = 'liveUpdates/RECONNECTION_SUCCESSFUL';

const MIN_DOWNTIME_IN_SECONDS_FOR_RELOAD = 60;

export function happenedVeryRecently(
  eventTime: number | Date | undefined,
  threshold = MIN_DOWNTIME_IN_SECONDS_FOR_RELOAD,
) {
  if (!eventTime) {
    return false;
  }

  return differenceInSeconds(new Date(), eventTime) < threshold;
}

function trackReconnectionEvent(state: ReduxState, error: unknown) {
  const { startDate, endDate } = state.schedule;
  let scheduleDaysReloaded = 0;
  if (startDate && endDate) {
    scheduleDaysReloaded = differenceInDays(
      new Date(endDate),
      new Date(startDate),
    );
  }
  trackEvent('Reconnection', { error, scheduleDaysReloaded });
}

export function fetchUpdatesMissedWhileOffline() {
  return async (dispatch: AppDispatch, getState: AppStore['getState']) => {
    const state = getState();
    const { reconnectedAt } = state.app;
    if (happenedVeryRecently(reconnectedAt)) {
      return true;
    }

    const pathname = getLocationPathname(state);
    const isSharedLinkView = getState().currentUser.shared_link_view;
    const hasTimeTracking =
      !isSharedLinkView && getState().companyPrefs?.time_tracking > 0;

    if (pathname.startsWith('/me')) {
      return true;
    }

    let error = false;

    try {
      // Portal reload
      await getJWTAccessToken();
      await Promise.all(
        [
          !isSharedLinkView && dispatch(fetchUser()),
          dispatch(ensureContextLoaded({ forceLoad: true, rebuild: true })),
          dispatch(ensureTimeoffTypesLoaded(true)),
          !isSharedLinkView && dispatch(ensureStatusTypesLoaded(true)),
          !isSharedLinkView && dispatch(ensureViewsLoaded(true)),
        ].filter(Boolean),
      );

      // Schedule related reload
      await Promise.all([
        dispatch(ensureHolidaysLoaded(true)),
        dispatch(ensureMilestonesLoaded(true)),
      ]);

      const rebuild = true;

      // Schedule reload
      if (state.schedule.startDate && state.schedule.endDate) {
        const { startDate, endDate } = state.schedule;
        await Promise.all([
          dispatch(fetchTasksWithDates(startDate, endDate, rebuild)),
          dispatch(fetchTimeoffsWithDates(startDate, endDate, rebuild)),
          hasTimeTracking
            ? dispatch(fetchLoggedTimesWithDates(startDate, endDate, rebuild))
            : Promise.resolve(null),
          dispatch(fetchOneOffs(startDate, endDate, rebuild)),
          dispatch(fetchStatuses(startDate, endDate, rebuild)),
        ]);
      }

      dispatch(ensureBudgetsLoaded(null, { forceLoad: true }));
      dispatch(requestNotifications({ toClear: true }));
      dispatch({ type: RECONNECTION_SUCCESSFUL });
    } catch (err) {
      error = true;
    }

    trackReconnectionEvent(state, error);

    return !error;
  };
}
