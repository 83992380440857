import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon {...props}>
    <defs>
      <linearGradient
        id="icon-plus-pack-gradient"
        x1="13.54"
        x2="28.471"
        y1="14.694"
        y2="34.079"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5DD0CE" />
        <stop offset=".927" stopColor="#3167E6" />
        <stop offset="1" stopColor="#2E5FE8" />
      </linearGradient>
    </defs>

    <path
      fill="url(#icon-plus-pack-gradient)"
      fillRule="evenodd"
      d="M35.2 10.62 23.9 4.54a4.04 4.04 0 0 0-3.82 0L8.8 10.62a2.64 2.64 0 0 0-1.34 2.3c0 .98.5 1.86 1.34 2.3l11.3 6.08a4 4 0 0 0 3.8 0l11.3-6.08c.82-.44 1.34-1.32 1.34-2.3 0-.96-.52-1.84-1.34-2.3Zm-16.96 12.8-10.5-5.24c-.82-.42-1.74-.36-2.52.1-.76.48-1.22 1.3-1.22 2.2v9.92c0 1.72.96 3.26 2.5 4.04L17 39.68c.36.18.76.28 1.16.28.46 0 .94-.14 1.36-.38.76-.48 1.22-1.3 1.22-2.2v-9.92a4.582 4.582 0 0 0-2.5-4.04ZM40 25.4v-4.92c0-.9-.46-1.72-1.22-2.2-.78-.46-1.7-.52-2.52-.1l-10.5 5.24a4.526 4.526 0 0 0-2.48 4.04v9.92c0 .9.46 1.72 1.22 2.18.28.18.58.3.88.34-.26-.94-.38-1.92-.38-2.9 0-3.7 1.64-7.12 4.52-9.38A12.03 12.03 0 0 1 37 25c1.02 0 2.04.12 3 .4Z"
      clipRule="evenodd"
    />

    <path
      fill="#2E5FE8"
      fillRule="evenodd"
      d="M37.286 46.286a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm0-13.715c.434 0 .786.352.786.786V36.5h3.143a.786.786 0 0 1 0 1.571h-3.143v3.143a.786.786 0 1 1-1.572 0v-3.143h-3.142a.786.786 0 1 1 0-1.571H36.5v-3.143c0-.434.352-.786.786-.786Z"
      clipRule="evenodd"
    />
  </BaseIcon>
);
