import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { Trans } from '@lingui/macro';
import { LayoutProps, motion } from 'framer-motion';

import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { useOnMount } from '@float/libs/hooks/useOnMount';
import { TimeString } from '@float/types/datesManager';
import { RepeatState } from '@float/types/repeatState';
import { AllocationType } from '@float/types/task';
import { DropdownSelect } from '@float/ui/deprecated';
import { TextButton } from '@float/ui/deprecated/Earhart/Buttons';

import { AllocationByTotalHoursCallout } from '../../components/AllocationByTotalHoursCallout/AllocationByTotalHoursCallout';
import {
  InputAllocationDateRange,
  InputAllocationDateRangeProps,
} from '../../components/InputAllocationDateRange/InputAllocationDateRange';
import {
  InputAllocationHours,
  InputAllocationHoursProps,
} from '../../components/InputAllocationHours/InputAllocationHours';
import {
  InputAllocationPercentage,
  InputAllocationPercentageProps,
} from '../../components/InputAllocationPercentage/InputAllocationPercentage';
import { InputAllocationRepeat } from '../../components/InputAllocationRepeat';
import {
  InputAllocationTimeRange,
  InputAllocationTimeRangeProps,
} from '../../components/InputAllocationTimeRange/InputAllocationTimeRange';
import { getIsSpecificTimeSet } from '../../EditTaskModal.helpers';
import { AllocationTimeSectionPayload } from '../../EditTaskModal.types';
import {
  getAllocationCorrectedHoursUsingHoursPerDay,
  getAllocationCorrectedHoursUsingHoursTotal,
} from '../../helpers/getAllocationCorrectedHours';
import { useAllocationDaysHelpers } from '../../hooks/useAllocationDaysHelpers';
import { useAllocationRepeatControls } from '../../hooks/useAllocationRepeatControls';
import { useAllocationSettings } from '../../hooks/useAllocationSettings';
import { useAllocationTypeControls } from '../../hooks/useAllocationTypeControls';
import { useIsTotalHoursInputSubdued } from '../../hooks/useIsTotalHoursInputSubdued';

import * as styles from '../../EditTaskModal.css';

export type AllocationTimeSectionProps = {
  endDate: Date;
  hasRecurringExternalCalendar: boolean;
  hoursPerDay: number | null;
  hoursTotal: number | null;
  is24HoursTimeFormat: boolean;
  isIntegrationSyncLocked: boolean;
  isIntervalRepeatable: boolean;
  isReadOnly: boolean;
  layout: LayoutProps['layout'];
  onChange: (data: AllocationTimeSectionPayload) => void;
  offWorkDate: Date | undefined;
  peopleIds: number[];
  repeatState: RepeatState;
  repeatEndDate: Date | null;
  startDate: Date;
  startTime: TimeString | null;
  taskId: number | null;
  allocationType: AllocationType | null;
};

export const AllocationTimeSection = forwardRef<
  HTMLDivElement,
  AllocationTimeSectionProps
>((props, ref) => {
  const {
    allocationType,
    endDate,
    hasRecurringExternalCalendar,
    hoursPerDay,
    hoursTotal,
    is24HoursTimeFormat,
    isIntegrationSyncLocked,
    isIntervalRepeatable,
    isReadOnly,
    layout,
    onChange,
    offWorkDate,
    peopleIds,
    repeatEndDate,
    repeatState,
    startDate,
    startTime,
    taskId,
  } = props;

  const isAllocationByPercentageEnabled = featureFlags.isFeatureEnabled(
    FeatureFlag.AllocationByAvailabilityPercentage,
  );

  const { allocationSettings, setAllocationSettings } = useAllocationSettings();

  // If the task allocation type is set, use it as the value;
  // otherwise, use the value from the allocation settings
  const allocationTypeResolved =
    typeof allocationType === 'number'
      ? allocationType
      : allocationSettings.defaultAllocationType;

  const isNewTask = !taskId;
  const isSpecificTime = getIsSpecificTimeSet(startTime);
  const isPercentage = allocationType === AllocationType.Percentage;

  // We need to store this in the state, so the fixed end date value doesn't get lost
  const [isFixedEndDate, setIsFixedEndDate] = useState(
    () => allocationTypeResolved === AllocationType.HoursWithFixedDuration,
  );
  const isFixedEndDateToggleValue =
    isSpecificTime || isPercentage ? undefined : isFixedEndDate;

  const {
    getIsWorkDay,
    getNumberOfAllocationDays,
    getNumberOfWorkingHours,
    getEndDateFromTotalHours,
  } = useAllocationDaysHelpers({
    peopleIds,
  });

  const numberOfAllocationDays = useMemo(() => {
    return getNumberOfAllocationDays(startDate, endDate, offWorkDate);
  }, [startDate, endDate, offWorkDate, getNumberOfAllocationDays]);

  const numberOfWorkingHours = useMemo(() => {
    return getNumberOfWorkingHours(startDate, endDate);
  }, [startDate, endDate, getNumberOfWorkingHours]);

  const minimumHoursPerDayValue = 0.01;
  const maximumHoursPerDayValue = 24;

  // The total hours might need a re-computation on mount
  useOnMount(() => {
    const { hoursPerDayCorrected, hoursTotalCorrected } =
      getAllocationCorrectedHoursUsingHoursPerDay({
        hoursPerDay,
        hoursTotal,
        numberOfAllocationDays,
        minimumHoursPerDayValue,
        maximumHoursPerDayValue,
      });

    onChange({
      hoursPerDay: hoursPerDayCorrected,
      hoursTotal: hoursTotalCorrected,
      allocationType: allocationTypeResolved,
    });
  });

  const isTotalHoursInputSubdued = useIsTotalHoursInputSubdued(
    numberOfAllocationDays,
  );

  const getIsDateDisabled = useCallback(
    (date: Moment) => !getIsWorkDay(date.toDate()),
    [getIsWorkDay],
  );

  const {
    allocationTypeOptionSelectedValue,
    allocationTypeOptions,
    isAllocationByTime,
    isAllocationByHours,
    isAllocationByPercentage,
    selectHoursPerDay,
    selectSpecificTime,
    handleAllocationTypeChange,
  } = useAllocationTypeControls({
    allocationType,
    hoursPerDay: hoursPerDay || 0,
    numberOfAllocationDays,
    numberOfWorkingHours,
    startTime,
    peopleIds,
    isFixedEndDate,
    onChange,
  });

  const handleHoursPerDayChange: InputAllocationHoursProps['onChangeHoursPerDay'] =
    (hoursPerDay) => {
      onChange({
        hoursPerDay,
      });
    };

  const handleHoursTotalChange: InputAllocationHoursProps['onChangeHoursTotal'] =
    (hoursTotal) => {
      // Update the total hours value
      if (isFixedEndDate) {
        onChange({
          hoursTotal,
        });
      }
      // adjust the date range and update the total hours value
      else {
        const endDateRecomputed = getEndDateFromTotalHours({
          startDate,
          hoursPerDay: hoursPerDay || 0,
          hoursTotal: hoursTotal || hoursPerDay || 0,
        });

        onChange({
          hoursTotal,
          endDate: endDateRecomputed,
        });
      }
    };

  const handleHoursPerDayBlur = () => {
    const { hoursPerDayCorrected, hoursTotalCorrected } =
      getAllocationCorrectedHoursUsingHoursPerDay({
        hoursPerDay,
        hoursTotal,
        numberOfAllocationDays,
        minimumHoursPerDayValue,
        maximumHoursPerDayValue,
      });

    onChange({
      hoursPerDay: hoursPerDayCorrected,
      hoursTotal: hoursTotalCorrected,
    });
  };

  const handleHoursTotalBlur = () => {
    if (isFixedEndDate) {
      const { hoursPerDayCorrected, hoursTotalCorrected } =
        getAllocationCorrectedHoursUsingHoursTotal({
          hoursPerDay: Math.min(hoursPerDay || 0, hoursTotal || 0),
          hoursTotal,
          numberOfAllocationDays,
          minimumHoursPerDayValue,
          maximumHoursPerDayValue,
        });

      onChange({
        hoursPerDay: hoursPerDayCorrected,
        hoursTotal: hoursTotalCorrected,
      });
    } else {
      const { hoursPerDayCorrected, hoursTotalCorrected } =
        getAllocationCorrectedHoursUsingHoursPerDay({
          hoursPerDay: Math.min(hoursPerDay || 0, hoursTotal || 0),
          hoursTotal,
          numberOfAllocationDays,
          minimumHoursPerDayValue,
          maximumHoursPerDayValue,
        });

      onChange({
        hoursPerDay: hoursPerDayCorrected,
        hoursTotal: hoursTotalCorrected,
      });
    }
  };

  const handlePercentageChange: InputAllocationPercentageProps['onChange'] = (
    data,
  ) => {
    const { hoursPerDay, hoursTotal } = data;

    onChange({
      hoursPerDay,
      hoursTotal,
    });
  };

  const handleTimeRangeChange: InputAllocationTimeRangeProps['onChange'] = (
    data,
  ) => {
    const { startTime, hoursPerDay } = data;

    const { hoursPerDayCorrected, hoursTotalCorrected } =
      getAllocationCorrectedHoursUsingHoursPerDay({
        hoursPerDay,
        hoursTotal,
        numberOfAllocationDays,
        minimumHoursPerDayValue,
        maximumHoursPerDayValue,
      });

    onChange({
      startTime,
      hoursPerDay: hoursPerDayCorrected,
      hoursTotal: hoursTotalCorrected,
    });
  };

  const handleDateRangeChange: InputAllocationDateRangeProps['onChange'] = (
    data,
  ) => {
    const { startDate, endDate } = data;

    const numberOfAllocationDays = getNumberOfAllocationDays(
      startDate,
      endDate,
      offWorkDate,
    );

    // When the end date is fixed we want to correct the hours per day value from total value
    if (isFixedEndDate) {
      const { hoursPerDayCorrected, hoursTotalCorrected } =
        getAllocationCorrectedHoursUsingHoursTotal({
          hoursPerDay,
          hoursTotal,
          numberOfAllocationDays,
          minimumHoursPerDayValue,
          maximumHoursPerDayValue,
        });

      onChange({
        startDate,
        endDate,
        hoursPerDay: hoursPerDayCorrected,
        hoursTotal: hoursTotalCorrected,
      });
    }
    // When the end date is not fixed we want to correct the hours total value from hours per day value
    else {
      const { hoursPerDayCorrected, hoursTotalCorrected } =
        getAllocationCorrectedHoursUsingHoursPerDay({
          hoursPerDay,
          hoursTotal,
          numberOfAllocationDays,
          minimumHoursPerDayValue,
          maximumHoursPerDayValue,
        });

      onChange({
        startDate,
        endDate,
        hoursPerDay: hoursPerDayCorrected,
        hoursTotal: hoursTotalCorrected,
      });
    }
  };

  const handleIsFixedEndDateChange: InputAllocationDateRangeProps['onChangeIsFixedEndDate'] =
    (isFixedEndDate) => {
      const allocationType = isFixedEndDate
        ? AllocationType.HoursWithFixedDuration
        : AllocationType.Hours;

      setIsFixedEndDate(isFixedEndDate);

      onChange({
        allocationType,
      });

      // Preserve the
      if (isNewTask) {
        setAllocationSettings('defaultAllocationType', allocationType);
      }
    };

  const { repeatTimes, handleRepeatEndDateChange, handleRepeatIntervalChange } =
    useAllocationRepeatControls({
      startDate,
      repeatState,
      repeatEndDate,
      onChange,
    });

  const allocationTypeDropdown = (
    <DropdownSelect
      appearance="text"
      value={allocationTypeOptionSelectedValue}
      options={allocationTypeOptions}
      onChange={handleAllocationTypeChange}
    />
  );

  const allocationTypeSetSpecificTimeControl = (
    <div className={styles.timeSectionSpecificTimeToggleWrapper}>
      <TextButton appearance="flue-fill" onClick={selectSpecificTime}>
        <Trans>Specific time</Trans>
      </TextButton>
    </div>
  );

  const allocationTypeSetHoursPerDayControl = (
    <div className={styles.timeSectionTotalHoursToggleWrapper}>
      <TextButton appearance="flue-fill" onClick={selectHoursPerDay}>
        <Trans>Total hours</Trans>
      </TextButton>
    </div>
  );

  let allocationTypeControl = null;

  if (isReadOnly) {
    allocationTypeControl = null;
  } else if (isAllocationByPercentageEnabled) {
    allocationTypeControl = allocationTypeDropdown;
  } else if (isAllocationByHours) {
    allocationTypeControl = allocationTypeSetSpecificTimeControl;
  } else if (isAllocationByTime) {
    allocationTypeControl = allocationTypeSetHoursPerDayControl;
  }

  return (
    <motion.div
      layout={layout}
      className={
        isReadOnly
          ? styles.timeSectionReadOnlyWrapper
          : styles.timeSectionWrapper
      }
      ref={ref}
    >
      {isAllocationByHours && (
        <div className={styles.timeSectionColumn}>
          <InputAllocationHours
            hoursPerDay={hoursPerDay}
            hoursTotal={hoursTotal}
            isReadOnly={isReadOnly}
            isTotalHoursInputSubdued={isTotalHoursInputSubdued}
            hasVaryingDailyHours={false}
            onChangeHoursPerDay={handleHoursPerDayChange}
            onChangeHoursTotal={handleHoursTotalChange}
            onBlurHoursPerDay={handleHoursPerDayBlur}
            onBlurHoursTotal={handleHoursTotalBlur}
          />

          {allocationTypeControl}
        </div>
      )}

      {isAllocationByTime && (
        <div className={styles.timeSectionColumn}>
          <InputAllocationTimeRange
            hoursPerDay={hoursPerDay}
            startTime={startTime}
            isReadOnly={isReadOnly}
            is24HoursTimeFormat={is24HoursTimeFormat}
            onChange={handleTimeRangeChange}
          />

          {allocationTypeControl}
        </div>
      )}

      {isAllocationByPercentage && (
        <div className={styles.timeSectionColumn}>
          <InputAllocationPercentage
            hoursPerDay={hoursPerDay}
            hoursTotal={hoursTotal}
            isReadOnly={isReadOnly}
            onChange={handlePercentageChange}
            numberOfAllocationDaysInDateRange={numberOfAllocationDays}
            numberOfWorkingHoursInDateRange={numberOfWorkingHours}
            onBlurPercentage={() => {}}
            onBlurHoursTotal={() => {}}
          />

          {allocationTypeControl}
        </div>
      )}

      <div
        className={styles.dateRangeColumn}
        data-callout-id="allocation-by-total-hours-callout"
      >
        <InputAllocationDateRange
          startDate={startDate}
          endDate={endDate}
          numberOfAllocationDaysInDateRange={numberOfAllocationDays}
          isReadOnly={isReadOnly}
          isFixedEndDate={isFixedEndDateToggleValue}
          getIsDateDisabled={getIsDateDisabled}
          onChange={handleDateRangeChange}
          onChangeIsFixedEndDate={handleIsFixedEndDateChange}
        />
        {isIntervalRepeatable && (
          <InputAllocationRepeat
            getIsDateDisabled={getIsDateDisabled}
            hasRecurringExternalCalendar={hasRecurringExternalCalendar}
            isIntegrationSyncLocked={isIntegrationSyncLocked}
            isReadOnly={isReadOnly}
            minRepeatDate={endDate}
            onChangeRepeatEndDate={handleRepeatEndDateChange}
            onChangeRepeatInterval={handleRepeatIntervalChange}
            repeatEndDate={repeatEndDate}
            repeatState={repeatState}
            repeatTimes={repeatTimes}
            startDate={startDate}
          />
        )}
      </div>
      {!isReadOnly && <AllocationByTotalHoursCallout />}
    </motion.div>
  );
});
