import {
  ASANA_IMPORT_SUCCESS,
  CLEAR_ASANA_PRELOAD,
  FETCH_ASANA_CO_INTS,
  FETCH_ASANA_CO_INTS_FAILURE,
  FETCH_ASANA_CO_INTS_SUCCESS,
  FETCH_ASANA_CONFIG,
  FETCH_ASANA_CONFIG_FAILURE,
  FETCH_ASANA_CONFIG_SUCCESS,
  FETCH_ASANA_OAUTH_LINK,
  FETCH_ASANA_OAUTH_LINK_FAILURE,
  FETCH_ASANA_OAUTH_LINK_SUCCESS,
  FETCH_ASANA_PRELOAD,
  FETCH_ASANA_PRELOAD_FAILURE,
  FETCH_ASANA_PRELOAD_SUCCESS,
  REMOVE_ASANA_CO_INT,
  REMOVE_ASANA_CO_INT_FAILURE,
  REMOVE_ASANA_CO_INT_SUCCESS,
  RETRY_ASANA_PEOPLE_SYNC,
  RETRY_ASANA_PEOPLE_SYNC_FAILURE,
  RETRY_ASANA_PEOPLE_SYNC_SUCCESS,
  UPDATE_ASANA_CONFIG,
  UPDATE_ASANA_CONFIG_FAILURE,
  UPDATE_ASANA_CONFIG_SUCCESS,
} from '../actions/asana';

const DEFAULT_STATE = {
  oauthLink: null,
  coInts: null,
  preloadData: {},
};

export default function asana(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case FETCH_ASANA_OAUTH_LINK: {
      return {
        ...state,
        oauthLinkLoadState: 'LOADING',
      };
    }

    case FETCH_ASANA_OAUTH_LINK_SUCCESS: {
      return {
        ...state,
        oauthLinkLoadState: 'LOAD_SUCCESS',
        oauthLink: action.oauthLink,
      };
    }

    case FETCH_ASANA_OAUTH_LINK_FAILURE: {
      return {
        ...state,
        oauthLinkLoadState: 'LOAD_FAILED',
      };
    }

    case FETCH_ASANA_CO_INTS: {
      return {
        ...state,
        coIntsLoadState: 'LOADING',
      };
    }

    case FETCH_ASANA_CO_INTS_SUCCESS: {
      return {
        ...state,
        coIntsLoadState: 'LOAD_SUCCESS',
        coInts: action.coInts,
      };
    }

    case FETCH_ASANA_CO_INTS_FAILURE: {
      return {
        ...state,
        coIntsLoadState: 'LOAD_FAILED',
      };
    }

    /* ASANA IMPORT BEHAVIORS */
    case ASANA_IMPORT_SUCCESS: {
      return {
        ...state,
        coIntsLoadState: 'LOAD_SUCCESS',
        coInts: action.coInts,
        initialImportStarted: false,
      };
    }

    /* FETCH PRELOAD DATA */

    case FETCH_ASANA_PRELOAD: {
      return {
        ...state,
        preloadLoadState: 'LOADING',
      };
    }

    case FETCH_ASANA_PRELOAD_SUCCESS: {
      return {
        ...state,
        preloadLoadState: 'LOAD_SUCCESS',
        preloadData: action.preloadData,
      };
    }

    case CLEAR_ASANA_PRELOAD: {
      return {
        ...state,
        preloadLoadState: null,
        preloadData: null,
      };
    }

    case FETCH_ASANA_PRELOAD_FAILURE: {
      return {
        ...state,
        preloadLoadState: 'LOAD_FAILED',
      };
    }

    /* FETCH ASANA CONFIG */

    case FETCH_ASANA_CONFIG: {
      return {
        ...state,
        fetchConfigLoadState: 'LOADING',
      };
    }

    case FETCH_ASANA_CONFIG_SUCCESS: {
      return {
        ...state,
        fetchConfigLoadState: 'LOAD_SUCCESS',
        config: action.config,
      };
    }

    case FETCH_ASANA_CONFIG_FAILURE: {
      return {
        ...state,
        fetchConfigLoadState: 'LOAD_FAILED',
      };
    }

    /* UPDATE ASANA CONFIG */

    case UPDATE_ASANA_CONFIG: {
      return {
        ...state,
        updateConfigLoadState: 'LOADING',
        initialImportStarted: action.startInitialImport,
      };
    }

    case UPDATE_ASANA_CONFIG_SUCCESS: {
      return {
        ...state,
        updateConfigLoadState: 'LOAD_SUCCESS',
        config: action.config,
      };
    }

    case UPDATE_ASANA_CONFIG_FAILURE: {
      return {
        ...state,
        updateConfigLoadState: 'LOAD_FAILED',
      };
    }

    /* REMOVE ASANA COINT */

    case REMOVE_ASANA_CO_INT: {
      return {
        ...state,
        removeCoIntLoadState: 'LOADING',
        initialImportStarted: undefined,
      };
    }

    case REMOVE_ASANA_CO_INT_SUCCESS: {
      return {
        ...state,
        removeCoIntLoadState: 'LOAD_SUCCESS',
      };
    }

    case REMOVE_ASANA_CO_INT_FAILURE: {
      return {
        ...state,
        removeCoIntLoadState: 'LOAD_FAILED',
      };
    }

    /* RETRY PEOPLE SYNC */

    case RETRY_ASANA_PEOPLE_SYNC: {
      return {
        ...state,
        retryPeopleSyncLoadState: 'LOADING',
      };
    }

    case RETRY_ASANA_PEOPLE_SYNC_SUCCESS: {
      return {
        ...state,
        retryPeopleSyncLoadState: 'LOAD_SUCCESS',
      };
    }

    case RETRY_ASANA_PEOPLE_SYNC_FAILURE: {
      return {
        ...state,
        retryPeopleSyncLoadState: 'LOAD_FAILED',
      };
    }

    default: {
      return state;
    }
  }
}
