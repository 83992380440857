import { createSelector } from 'reselect';

import { Project } from '@float/types';

import { ReduxStateStrict } from '../reducers/lib/types';
import { getProjectsSortedByCreation } from './projects';

export const MAXIMUM_COLORS = 8;

export const getClientColors = createSelector(
  [
    getProjectsSortedByCreation,
    (_: ReduxStateStrict, id: Project['client_id']) => id,
  ],
  (projects, id) => {
    if (!id) return [];

    const colors = new Set<string>();
    // Color picker only needs 8 colors
    for (const p of projects) {
      if (p.client_id === id && p.color) {
        colors.add(p.color);
      }
      if (colors.size === MAXIMUM_COLORS) {
        break;
      }
    }
    return Array.from(colors);
  },
);

export const getRecentColors = createSelector(
  [getProjectsSortedByCreation],
  (projects) => {
    const colors = new Set<string>();
    for (const p of projects) {
      if (p.color) {
        colors.add(p.color);
      }
      if (colors.size === MAXIMUM_COLORS) {
        break;
      }
    }
    return Array.from(colors);
  },
);
