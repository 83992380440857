import React, { MouseEvent, useRef } from 'react';
import { t } from '@lingui/macro';
import cn from 'classnames';

import { isNot, isOr } from '@float/common/search/helpers';
import { FilterToken as FilterTokenValue } from '@float/types';
import { EH, Icons } from '@float/ui/deprecated';

import { FilterLabel } from './components/FilterLabel';
import { LogicalConjunctionButton } from './components/LogicalConjunctionButton';
import { SearchValues } from './components/SearchValues';
import { getTokenMeta } from './helpers/getTokenMeta';
import { isLogicalConjunctionChangeBlocked } from './helpers/isLogicalConjunctionChangeBlocked';

import * as styles from './styles.css';

export type FilterTokenProps = {
  index: number;
  filter: FilterTokenValue;
  prevFilter?: FilterTokenValue;
  onChange?: (
    filter: FilterTokenValue,
    operator: FilterTokenValue['operator'],
    index: number,
  ) => void;
  onRemove?: () => void;
  onClick?: (params: {
    event: MouseEvent<HTMLButtonElement>;
    filter: FilterTokenValue;
    index: number;
    targetEl?: HTMLButtonElement | null;
    expandCategory?: boolean;
  }) => void;
  wrapValues?: boolean;
  disabled?: boolean;
  forceNoOrBetweenTypes?: boolean;
};

export function FilterToken({
  index,
  filter,
  disabled,
  wrapValues,
  prevFilter,
  onChange,
  onRemove,
  onClick,
  forceNoOrBetweenTypes,
}: FilterTokenProps) {
  const filterKeyRef = useRef<HTMLButtonElement>(null);
  const showSeparator = index > 0;

  const meta = getTokenMeta(filter);

  const isContainsFilterType = meta.category === 'contains';

  function handleKeyClick(event: MouseEvent<HTMLButtonElement>) {
    onClick?.({ event, filter, index });
  }

  function handleValueClick(event: MouseEvent<HTMLButtonElement>) {
    onClick?.({
      event,
      filter,
      index,
      targetEl: filterKeyRef.current,
      expandCategory: !isContainsFilterType,
    });
  }

  function handleLogicalConjunctionClick() {
    let operator: FilterTokenValue['operator'] = isOr(filter.operator)
      ? ''
      : '|';

    if (isNot(filter.operator)) operator = `${operator}-`;

    onChange?.(filter, operator, index);
  }

  function handleEqualityOperatorClick() {
    let operator: FilterTokenValue['operator'] = isNot(filter.operator)
      ? ''
      : '-';

    if (isOr(filter.operator)) operator = `|${operator}`;

    onChange?.(filter, operator, index);
  }

  const isMeFilterType = filter.type === 'me';

  return (
    <>
      {showSeparator && (
        <LogicalConjunctionButton
          isChangeBlocked={isLogicalConjunctionChangeBlocked(
            filter,
            prevFilter,
            forceNoOrBetweenTypes,
          )}
          onClick={handleLogicalConjunctionClick}
          disabled={disabled}
        >
          {meta.logicalConjunction}
        </LogicalConjunctionButton>
      )}
      <div className={styles.tokenContainer}>
        <button
          type="button"
          ref={filterKeyRef}
          className={styles.token({ backgroundColor: meta.bg })}
          data-filter-index={index}
          data-filter-part="key"
          data-testid="FilterKey"
          onClick={handleKeyClick}
          disabled={disabled}
        >
          {meta.icon && <meta.icon className={styles.categoryIcon} />}
          <FilterLabel filter={filter} />
        </button>
        {!isMeFilterType && !isContainsFilterType && (
          <button
            type="button"
            className={styles.token({
              backgroundColor: meta.bg,
              textColor: meta.bg,
            })}
            onClick={handleEqualityOperatorClick}
            disabled={disabled}
          >
            {isNot(filter.operator) ? t`is not` : t`is`}
          </button>
        )}
        {!isMeFilterType && (
          <button
            type="button"
            className={cn(
              styles.token({
                backgroundColor: meta.bg,
                flexWrap: wrapValues ? 'wrap' : 'nowrap',
              }),
              'value',
            )}
            data-filter-index={index}
            data-filter-part="value"
            data-testid="FilterValue"
            onClick={handleValueClick}
            disabled={disabled}
          >
            <SearchValues filter={filter} color={meta.bg} />
          </button>
        )}
        {onRemove && (
          <button
            type="button"
            className={styles.token({ backgroundColor: meta.bg })}
            onClick={onRemove}
            data-testid="ClearFilters"
            disabled={disabled}
          >
            <Icons.CloseSmall
              className={styles.removeIcon}
              color={EH.Colors.FIN.Lt.Emphasis.High}
            />
          </button>
        )}
      </div>
    </>
  );
}
