import { logger } from '@float/libs/logger';

import { formatTaskId } from '../../lib/formatters';
import { batch } from '../../lib/reduxBatch';
import request from '../../lib/request';
import { FETCH_TIMEOFFS_SUCCESS } from '../timeoffsConstants';
import { formatTaskForRequest } from './helpers/formatTaskForRequest';

export * from './updateTask';

export const FETCH_TASKS = 'FETCH_TASKS';
export const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS';
export const FETCH_TASKS_FAILURE = 'FETCH_TASKS_FAILURE';
export const CREATE_TASK = 'CREATE_TASK';
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';
export const CREATE_TASK_FAILURE = 'CREATE_TASK_FAILURE';
export const SPLIT_TASK_START = 'SPLIT_TASK_START';
export const SPLIT_TASK = 'SPLIT_TASK';
export const SPLIT_TASK_DELETE_SUCCESS = 'SPLIT_TASK_DELETE_SUCCESS';
export const DELETE_TASK = 'DELETE_TASK';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const DELETE_TASK_FAILURE = 'DELETE_TASK_FAILURE';
export const INSERT_TASK = 'INSERT_TASK';
export const INSERT_TASK_SUCCESS = 'INSERT_TASK_SUCCESS';
export const INSERT_TASK_FAILURE = 'INSERT_TASK_FAILURE';
export const REPLACE_TASK = 'REPLACE_TASK';
export const REPLACE_TASK_SUCCESS = 'REPLACE_TASK_SUCCESS';
export const REPLACE_TASK_FAILURE = 'REPLACE_TASK_FAILURE';
export const BATCH_UPSERT_TASK_SUCCESS = 'BATCH_UPSERT_TASK_SUCCESS';
export const LINKED_TASKS_UPDATE_SUCCESS = 'LINKED_TASKS_UPDATE_SUCCESS';
export const BATCH_DELETE_TASK_SUCCESS = 'BATCH_DELETE_TASK_SUCCESS';

export const CREATE_TASK_META_SUCCESS = 'CREATE_TASK_META_SUCCESS';
export const UPDATE_TASK_META_SUCCESS = 'UPDATE_TASK_META_SUCCESS';
export const DELETE_TASK_META_SUCCESS = 'DELETE_TASK_META_SUCCESS';
export const MERGE_TASK_META_SUCCESS = 'MERGE_TASK_META_SUCCESS';

export const formatTaskForCreateRequest = (task) => {
  const payload = formatTaskForRequest(task);
  delete payload.task_id;
  delete payload.integration_status;
  return payload;
};

const fetchedTasks = (items, rebuild) => ({
  type: FETCH_TASKS_SUCCESS,
  items,
  rebuild,
});

const failedFetchedTasks = (error) => ({
  type: FETCH_TASKS_FAILURE,
  error,
});

export const fetchTasks = () => ({
  type: FETCH_TASKS,
});

export const fetchTasksWithDates =
  (start, end, rebuild) => async (dispatch) => {
    dispatch(fetchTasks());

    try {
      const res = await request.get(
        'tasks/all',
        {
          start_date:
            typeof start === 'string' ? start : start.format('YYYY-MM-DD'),
          end_date: typeof end === 'string' ? end : end.format('YYYY-MM-DD'),
        },
        { version: 'f3', includeHeaders: true },
      );

      dispatch(fetchedTasks(res.data, rebuild));
    } catch (error) {
      dispatch(failedFetchedTasks(error));
      logger.error('Failed to fetch tasks with dates', error);
    }
  };

export const fetchLinkedEntities = (taskId) => async (dispatch, getState) => {
  const tasks = getState().tasks;
  const rootId = tasks.tasks[taskId].root_task_id;

  if (!rootId) return;
  if (tasks.fetchedRootIds[rootId]) return;

  const res = await request.get(`linked-tasks/${rootId}`, null, {
    version: 'f3',
  });

  batch(() => {
    dispatch({
      type: FETCH_TASKS_SUCCESS,
      items: res.tasks,
      rootTaskId: rootId,
    });

    if (res.timeoffs.length) {
      dispatch({ type: FETCH_TIMEOFFS_SUCCESS, items: res.timeoffs });
    }
  });
};

export const updateLinkedTasks = (change) => async (dispatch) => {
  if (change.type !== 'linkedTasks') throw Error('Unsupported');

  const payload = change.group.map((c, idx) => {
    if (idx === 0) {
      return formatTaskForRequest(c.entity);
    }

    return {
      task_id: c.entity.task_id,
      start_date: c.entity.start_date,
      end_date: c.entity.end_date,
    };
  });

  const undoPayload = change.group.map((c) => {
    return {
      entity: c.originalEntity,
      originalEntity: c.entity,
    };
  });

  const res = await request.post(`linked-tasks`, payload, {
    version: 'f3',
  });

  dispatch({
    type: LINKED_TASKS_UPDATE_SUCCESS,
    item: {
      tasks: res,
    },
    undoPayload,
  });
};

const deleteTaskBegin = (item) => ({
  type: DELETE_TASK,
  item,
});

const deleteTaskSuccess = (item, undoPayload) => ({
  type: DELETE_TASK_SUCCESS,
  item,
  undoPayload,
});
const deleteSplitTaskSuccess = (item, undoPayload) => ({
  type: SPLIT_TASK_DELETE_SUCCESS,
  item,
  undoPayload,
});

const deleteTaskFailure = (item) => ({
  type: DELETE_TASK_FAILURE,
  item,
});

export const deleteTask =
  (task, { isSplitTask = false } = {}) =>
  (dispatch, getState) => {
    const id = formatTaskId(task);
    dispatch(deleteTaskBegin(task));

    const undoPayload = { task };

    if (task.root_task_id) {
      undoPayload.task = {
        ...task,
        root_task_id: undefined,
        parent_task_id: undefined,
      };

      undoPayload.linkedTasks = Object.values(getState().tasks.tasks).filter(
        (link) =>
          link.task_id === task.task_id ||
          link.parent_task_id === task.task_id ||
          link.task_id === task.parent_task_id,
      );
    }

    return request.del(`tasks/${id}`, null, { version: 'f3' }).then(
      () => {
        // separate delete success callback for split tasks to allow
        // for separate reverse action (undo/redo)
        if (isSplitTask) {
          return dispatch(deleteSplitTaskSuccess(task, undoPayload));
        }

        dispatch(deleteTaskSuccess(task, undoPayload));
      },
      () => dispatch(deleteTaskFailure(task)),
    );
  };

const insertOrReplaceTask = (task, mode, err, taskWasntUpdated) => {
  return request.post(
    `schedule/${mode}/task`,
    formatTaskForCreateRequest(task),
    {
      version: 'f3',
    },
  );
};

const insertTaskBegin = (task) => ({
  type: INSERT_TASK,
  task,
});

const insertTaskSuccess = (response, task) => ({
  type: INSERT_TASK_SUCCESS,
  response,
  item: task,
});

const insertTaskFailure = (error, onError) => {
  if (typeof onError === 'function') {
    onError();
  }
  return { type: INSERT_TASK_FAILURE, error };
};

export const insertTask = (task, onError) => (dispatch) => {
  dispatch(insertTaskBegin(task));
  return insertOrReplaceTask(task, 'insert', onError).then(
    (response) => dispatch(insertTaskSuccess(response, task)),
    (error) => {
      dispatch(insertTaskFailure(error, onError));
      return Promise.reject(error);
    },
  );
};

const replaceTaskBegin = (task) => ({
  type: REPLACE_TASK,
  task,
});

export const replaceTaskSuccess = (response, task) => ({
  type: REPLACE_TASK_SUCCESS,
  response,
  item: task,
});

const replaceTaskFailure = (error, onError) => {
  if (typeof onError === 'function') {
    onError();
  }
  return { type: REPLACE_TASK_FAILURE, error };
};

export const replaceTask = (task, onError) => (dispatch) => {
  dispatch(replaceTaskBegin(task));
  return insertOrReplaceTask(task, 'replace', onError).then(
    (response) => dispatch(replaceTaskSuccess(response, task)),
    (error) => {
      dispatch(replaceTaskFailure(error, onError));
      return Promise.reject(error);
    },
  );
};

export const fetchTask = (id) => (dispatch) => {
  return request.get(`tasks/${id}`, null, { version: 'f3' }).then((task) => {
    dispatch(fetchedTasks([task], true));
    return task;
  });
};
