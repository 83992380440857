import styled, { css } from 'styled-components';

import * as Animation from '@float/ui/deprecated/Earhart/Animation';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

export const StyledContent = styled.div`
  position: relative;

  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: space-between;

  pointer-events: none;

  z-index: 1;

  input {
    position: relative;

    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;

    border-radius: 6px;

    box-shadow: inset 0 0 0 1.5px ${Colors.FIN.Lt.Emphasis.Low};

    margin-left: 10px;
    margin-top: 4px;

    appearance: none;

    transition-property: box-shadow;
    transition-duration: ${Animation.Settings.Duration.Normal};
    transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

    &:before {
      position: absolute;

      top: 50%;
      left: 50%;

      width: 6px;
      height: 6px;

      border-radius: 50%;

      background-color: ${Colors.FIN.Lt.Emphasis.Low};

      transform: translate(-50%, -50%) scale(0);
      transform-origin: 50%;

      content: '';

      transition-property: transform, background-color;
      transition-duration: ${Animation.Settings.Duration.Normal};
      transition-timing-function: ${Animation.Settings.Easing.InOutCurve},
        ${Animation.Settings.Easing.InOutCurve};
    }
  }
`;

export const StyledLabel = styled.label`
  ${Typography.Label14.SB600};

  color: ${Colors.FIN.Lt.Emphasis.High};
`;

export const StyledSubLabel = styled.div`
  ${Typography.Label12.R400};

  color: ${Colors.FIN.Lt.Emphasis.Medium};

  margin-top: 4px;
`;

export const StyledBg = styled.div`
  &:after,
  &:before {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border-radius: 8px;

    box-sizing: border-box;

    pointer-events: none;

    content: '';

    z-index: 1;
  }

  &:after {
    background-color: ${Colors.FIN.Lt.Surface.Surf3};

    transition-property: background-color;
    transition-duration: ${Animation.Settings.Duration.Normal};
    transition-timing-function: ${Animation.Settings.Easing.InOutCurve};
  }

  &:before {
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border-radius: 8px;

    box-shadow: 0 0 0 1.5px ${Colors.FIN.Lt.Emphasis.Low};

    opacity: 0;
    transform: scale(0.9);
    transform-origin: 50%;

    transition-property: border-color, opacity, transform;
    transition-duration: ${Animation.Settings.Duration.Normal};
    transition-timing-function: ${Animation.Settings.Easing.InOutCurve},
      ${Animation.Settings.Easing.InOutCurve},
      ${Animation.Settings.Easing.InOutCurve};

    z-index: 0;
  }
`;

export const StyledRadioButton = styled.div`
  position: relative;

  display: inline-flex;
  flex-flow: column;

  border-radius: 8px;

  padding: 12px 16px 12px 18px;

  outline: none;

  cursor: pointer;

  backface-visibility: hidden;

  ${({ $selected }) =>
    !$selected &&
    css`
      &:hover,
      &:focus,
      &:active {
        ${StyledBg} {
          &:after {
            background-color: ${Colors.FIN.Lt.Surface.Surf5};
          }
        }

        ${StyledContent} {
          input {
            box-shadow: inset 0 0 0 1.5px ${Colors.FIN.Lt.Emphasis.Medium};
          }
        }
      }

      &:focus {
        ${StyledBg} {
          &:before {
            box-shadow: 0 0 0 1.5px ${Colors.FIN.Lt.Stroke.Stroke3};

            opacity: 1;
            transform: scale(1);
          }
        }
      }

      &:active {
        ${StyledBg} {
          &:after {
            background-color: ${Colors.FIN.Lt.Surface.Surf6};
          }
        }
      }
    `};

  ${({ $selected }) =>
    $selected &&
    css`
      &:focus {
        ${StyledBg} {
          &:before {
            box-shadow: 0 0 0 1.5px ${Colors.FIN.Lt.Stroke.Stroke4};

            opacity: 1;
            transform: scale(1);
          }
        }
      }

      ${StyledBg} {
        &:after {
          background-color: ${Colors.FIN.Lt.Surface.Surf5};
        }

        &:before {
          box-shadow: 0 0 0 1.5px ${Colors.FIN.Lt.Emphasis.Primary};

          opacity: 1;
          transform: scale(1);
        }
      }

      ${StyledContent} {
        input {
          box-shadow: inset 0 0 0 1.5px ${Colors.FIN.Lt.Emphasis.Primary};

          &:before {
            background-color: ${Colors.FIN.Lt.Emphasis.Primary};
            transform: translate(-50%, -50%) scale(1);
          }
        }
      }
    `};
`;
