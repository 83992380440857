import { CellsMap } from '@float/types';

import { buildProjectRowSortData } from './buildProjectRowSortData';

export function getProjectRowSortData(
  cells: CellsMap,
  projectId: number | string,
) {
  let sortData = cells._projectRowSortData[projectId];

  if (sortData === undefined) {
    const milestones = cells._helperData.byProject.milestones[projectId] || [];
    const phases = cells._helperData.byProject.phases[projectId] || [];

    sortData = buildProjectRowSortData([...phases, ...milestones]);
    cells._projectRowSortData[projectId] = sortData;
  }

  return sortData;
}
