import React from 'react';

export default ({ color, size }) => (
  <svg
    width={size || '32'}
    height={size || '32'}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.60004 8.07999C8.60004 9.04648 7.81653 9.82999 6.85004 9.82999C5.88355 9.82999 5.10004 9.04648 5.10004 8.07999C5.10004 7.1135 5.88355 6.32999 6.85004 6.32999C7.81653 6.32999 8.60004 7.1135 8.60004 8.07999Z"
      fill={color ?? '#8290AA'}
      className="fill"
    />
    <path
      d="M8.60004 16.08C8.60004 17.0463 7.81653 17.83 6.85004 17.83C5.88355 17.83 5.10004 17.0463 5.10004 16.08C5.10004 15.1136 5.88355 14.33 6.85004 14.33C7.81653 14.33 8.60004 15.1136 8.60004 16.08Z"
      fill={color ?? '#8290AA'}
      className="fill"
    />
    <path
      d="M8.60004 24.08C8.60004 25.0464 7.81653 25.83 6.85004 25.83C5.88355 25.83 5.10004 25.0464 5.10004 24.08C5.10004 23.1137 5.88355 22.33 6.85004 22.33C7.81653 22.33 8.60004 23.1137 8.60004 24.08Z"
      fill={color ?? '#8290AA'}
      className="fill"
    />
    <path
      d="M13 8L25.2 8"
      className="stroke"
      stroke={color ?? '#8290AA'}
      strokeWidth="3.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 16L25.2 16"
      className="stroke"
      stroke={color ?? '#8290AA'}
      strokeWidth="3.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 24L25.2 24"
      className="stroke"
      stroke={color ?? '#8290AA'}
      strokeWidth="3.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
