import { isEqual } from 'lodash';

type Entity = any;

export type BulkEditModalProps = {
  fields: Record<
    string,
    {
      defaultValue: (entity: Entity) => unknown | string | undefined;
      getDefaultValueForMixedSelection?: () => unknown;
      getDefaultValue?: (entity: Entity) => unknown;
      handleMismatch: Record<
        string,
        (
          firstEntity: Entity,
          currentEntity: Entity,
          initialValue: Entity,
        ) => unknown
      >;
    }
  >;
  ids: number[];
  entities: Record<number, Record<string, unknown>>;
};

export const getDefaultValue = (key: string, props: BulkEditModalProps) => {
  const { fields, ids, entities } = props;

  const fieldConfig = fields[key];
  const configType = typeof fieldConfig.defaultValue;

  if (!['undefined', 'function'].includes(configType)) {
    return fieldConfig.defaultValue;
  }

  const getValue = (entity: Record<string, unknown>): Entity => {
    if (fieldConfig.getDefaultValue) {
      return fieldConfig.getDefaultValue(entity);
    }
    return entity[key];
  };

  const firstEntity = entities[ids[0]];
  const initialValue = getValue(firstEntity);

  const matchesAllEntities =
    initialValue &&
    ids.every((id) => {
      const currentEntity = entities[id];
      const currentValue = getValue(currentEntity);

      if (typeof initialValue === 'object') {
        for (const attribute in initialValue) {
          if (!isEqual(currentValue[attribute], initialValue[attribute])) {
            if (fieldConfig.handleMismatch[attribute]) {
              fieldConfig.handleMismatch[attribute](
                firstEntity,
                currentEntity,
                initialValue,
              );
            } else {
              delete initialValue[attribute];
            }
          }
        }

        return true;
      }

      const isMatchngFirstValue = currentValue === initialValue;
      return isMatchngFirstValue;
    });

  if (matchesAllEntities) {
    return configType === 'function'
      ? fieldConfig.defaultValue(initialValue)
      : initialValue;
  } else if (fieldConfig && fieldConfig.getDefaultValueForMixedSelection) {
    return fieldConfig.getDefaultValueForMixedSelection();
  }

  return null;
};
