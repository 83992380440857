import { RawView } from '@float/types';

import { makeRequest, Payload, PayloadAndID } from './makeRequest';

export type ViewPayload = Pick<
  RawView,
  'filters' | 'name' | 'personal' | 'pinned' | 'settings'
> & {
  account_ids?: {
    add: number[];
  };
};

export const views = {
  getView(params: { id: number }) {
    return makeRequest<RawView, undefined, number>({
      ...params,
      resource: 'views',
      method: 'GET',
    });
  },
  async getAllViews() {
    const [views] = await makeRequest<
      [data: RawView[], pageCount: number, total: number]
    >({
      resource: 'views',
      method: 'GET',
    });

    return views;
  },
  createView(params: Payload<ViewPayload>) {
    return makeRequest<RawView, ViewPayload>({
      ...params,
      resource: 'views',
      method: 'POST',
    });
  },
  updateView(params: PayloadAndID<ViewPayload, number>) {
    return makeRequest<RawView, ViewPayload, number>({
      ...params,
      resource: 'views',
      method: 'PATCH',
    });
  },
  deleteView(params: { id: number }) {
    return makeRequest<undefined, undefined, number>({
      ...params,
      resource: 'views',
      method: 'DELETE',
    });
  },
};
