import styled from 'styled-components';

export const StyledForm = styled.form`
  display: flex;
  align-items: flex-end;

  > *:not(:last-child) {
    width: 50%;
  }

  > * + * {
    margin-left: 10px;
  }
`;
