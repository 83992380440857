import React from 'react';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import cn from 'classnames';

import * as styles from '@float/ui/deprecated/ColorPicker/styles.css';

type ColorPickerSwatchProps = {
  autoFocus?: boolean;
  className?: string;
  color: string;
  // Use this to pass any click event handlers to the color button
  onSelect?: () => void;
  // Popover trigger works by taking the button below and passing it's own props to a cloned version.
  // If you set your own onClick prop it overrides this and makes the trigger not work.
  // This is in the type but is passed from the popover code so don't pass click handlers. Use onSelect
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  size?: 'sm' | 'lg';
};

// Must be a forward ref to receive ref from parent component
export const ColorPickerSwatch = React.forwardRef<null, ColorPickerSwatchProps>(
  (props, ref) => {
    return (
      <button
        {...props}
        ref={ref}
        className={cn(styles.colorPickerSwatch, props.className)}
        style={assignInlineVars({
          [styles.selectedColorVar]: `#${props.color}`,
        })}
        data-size={props.size}
        onClick={(event) => {
          event.stopPropagation();
          props.onClick && props.onClick(event);
          props.onSelect && props.onSelect();
        }}
      />
    );
  },
);
