import { useState } from 'react';
import { t } from '@lingui/macro';

import { HttpError } from '@float/common/api3/makeRequest';
import { useAppDispatchDecorator } from '@float/common/store';
import { TimeoffType } from '@float/types/timeoffType';
import { ModalConfirmProps } from '@float/ui/components/Modals/ModalConfirm/types';
import { useSnackbar } from '@float/ui/deprecated';
import { deleteTimeoffType } from '@float/web/settingsV2/actions/timeoffTypes';

export function useDeleteTimeoffType(
  timeoffType: TimeoffType,
  originalName: string,
  close: () => void,
) {
  const [open, setOpen] = useState(false);
  const { showSnackbar, showError } = useSnackbar();

  const dispatchDeleteTimeoffType = useAppDispatchDecorator(deleteTimeoffType);

  const typeName = timeoffType.timeoff_type_name;

  const confirmProps = {
    title: t`Delete time off type`,
    description: t`Are you sure? This will delete all the assigned
${originalName} time off. This action cannot be undone`,
    onClickConfirm: () =>
      dispatchDeleteTimeoffType(timeoffType.timeoff_type_id).then(
        () => {
          close();
          showSnackbar(t`${typeName} deleted.`);
        },
        (err: HttpError) => {
          if (err.status === 403 && err.message.includes('public holidays')) {
            showError(
              t`You cannot delete this time off type because it is required for public holidays.`,
            );
          } else {
            showError(t`Failed to delete ${typeName}`);
          }
        },
      ),
    onOpenChange: setOpen,
    modalProps: { open: true },
  } satisfies ModalConfirmProps;

  return { confirmProps, open, setOpen };
}
