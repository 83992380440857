import styled, { css } from 'styled-components';

import * as Animation from '@float/ui/deprecated/Earhart/Animation';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';
import VirtualList from '@float/ui/deprecated/VirtualList';

export const StyledInputContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledInput = styled.div`
  position: relative;

  z-index: 1;

  > [contenteditable='true'] {
    user-select: text;
  }
`;

export const StyledTextAreaWrapper = styled.div<{
  readOnly?: boolean;
  background?: string;
  textColor?: string;
  withValue?: boolean;
  hasError?: boolean;
}>`
  ${Typography.TextM.R400};

  position: relative;

  min-height: 40px;

  padding: 8px 10px 8px 16px;

  box-sizing: border-box;

  transition-property: color;
  transition-duration: ${Animation.Settings.Duration.Normal};
  transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

  cursor: ${(p: { readOnly?: boolean }) => (p.readOnly ? 'auto' : 'text')};
  ${(p: { textColor?: string }) =>
    p.textColor &&
    css`
      color: ${p.textColor} !important;
    `}

  // Borders
  &:after,
  &:before {
    content: '';

    position: absolute;

    transition-property: transform, opacity, border-color;
    transition-duration: ${Animation.Settings.Duration.Normal};
    transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

    box-sizing: border-box;

    pointer-events: none;
  }

  // Inner border
  &:before {
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: ${(p) =>
      p.background ? p.background : Colors.FIN.Lt.Emphasis.White};

    border-width: 1px;
    border-style: solid;
    border-color: ${Colors.FIN.Lt.Stroke.Stroke2};
    border-radius: 5px;

    z-index: 1;
  }

  // Outer border
  &:after {
    top: -2px;
    left: -2px;
    bottom: -2px;
    right: -2px;

    border-width: 2px;
    border-style: solid;
    border-color: ${Colors.Primary.Flue.Light[6]};
    border-radius: 6px;

    background-color: ${Colors.Primary.Flue.Light[6]};

    opacity: 0;
    transform: scale(0.99, 0.9);

    z-index: 0;
  }

  ${(p: { hasError?: boolean }) => {
    if (p.hasError)
      return css`
        &:before {
          border-color: ${Colors.FIN.Lt.Danger.Default};
        }
      `;
  }}

  ${({ readOnly }: { readOnly?: boolean; background?: string }) => {
    if (!readOnly) {
      return css`
        &:hover {
          &:before {
            border-color: ${Colors.FIN.Lt.Emphasis.Primary};
          }
        }
      `;
    }

    if (readOnly) {
      return css`
        padding: 0;

        ${(p: { background?: string; withValue?: boolean }) =>
          p.background && p.withValue
            ? ''
            : `
          &:before {
            display: none;
          }
        `}
      `;
    }
  }};

  &.focused {
    &:before {
      background-color: ${(p: { background?: string }) =>
        p.background ? p.background : Colors.FIN.Lt.Emphasis.White};
    }

    &:after {
      opacity: 1;
      transform: none;
    }
  }
`;

export const StyledVirtualList = styled(VirtualList)`
  --top: 0;
  --left: 0;

  position: absolute;

  width: 250px;

  top: calc(var(--top) * 1px + 2px);
  left: calc(var(--left) * 1px);

  z-index: 2010;

  &.virtual-list-enter {
    opacity: 0;
    transform-origin: 0 0;
    transform: scale(0.95) translateY(-5px);
  }

  &.virtual-list-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition-property: opacity, transform;
    transition-timing-function: ${Animation.Settings.Easing.InOutCurve},
      ${Animation.Settings.Easing.OutBounce};
    transition-duration: ${Animation.Settings.Duration.Slow};
  }

  &.virtual-list-exit {
    opacity: 1;
  }

  &.virtual-list-exit-active {
    opacity: 0;
    transform: scale(0.95);
    transition-duration: 100ms;
  }
`;
