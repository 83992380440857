import { toDash } from '@float/libs/utils/strings';
import { Client } from '@float/types';

import { makeRequest, MakeRequestParams } from './makeRequest';

const RESOURCE = 'clients';

/**
 * Retrieve a client
 * @param id: client id
 */
export const clients = {
  getClient: async (
    params: MakeRequestParams<undefined, Client['client_id']>,
  ): Promise<Client> =>
    makeRequest({
      ...params,
      resource: toDash(RESOURCE),
      method: 'GET',
    }),

  getClients: async (
    params: MakeRequestParams<undefined, undefined>,
  ): Promise<Client[]> => {
    return makeRequest({
      ...params,
      resource: toDash(RESOURCE),
      method: 'GET',
    });
  },

  /**
   * Creates new client
   * @param data: new client attributes
   */
  createClient: async (
    params: MakeRequestParams<Partial<Client>>,
  ): Promise<Client> =>
    makeRequest({
      ...params,
      resource: toDash(RESOURCE),
      method: 'POST',
    }),

  /**
   * Updates client
   * @param id: id of client to update
   * @param data: new client attributes
   */
  updateClient: async (
    params: MakeRequestParams<Partial<Client>, Client['client_id']>,
  ): Promise<Client> =>
    makeRequest({
      ...params,
      resource: toDash(RESOURCE),
      method: 'PATCH',
    }),

  /**
   * Deletes client
   * @param id: id of client to delete
   */
  deleteClient: async (
    params: MakeRequestParams<undefined, Client['client_id']>,
  ): Promise<void> =>
    makeRequest({
      ...params,
      resource: toDash(RESOURCE),
      method: 'DELETE',
    }),
};
