import React from 'react';

import { IconPlaceholder } from '@float/ui/icons/essentials/IconPlaceholder';

import * as styles from './styles.css';

export const PlaceholderAvatar = () => {
  return (
    <div className={styles.placeholderAvatar}>
      <IconPlaceholder className={styles.svg} noScalingStroke={true} />
    </div>
  );
};
