import React, { StrictMode } from 'react';

import { TagsV2 } from '@float/web/settingsV2/components/TagsV2/TagsV2';

import { useData } from './useData';

export const TagsV2Container = () => {
  const { isLoading, numTagsPeople, numTagsProjects, tagsGroups } = useData();

  return (
    <StrictMode>
      <TagsV2
        numTagsPeople={numTagsPeople}
        numTagsProjects={numTagsProjects}
        isLoading={isLoading}
        tagsGroups={tagsGroups}
      />
    </StrictMode>
  );
};
