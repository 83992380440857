import API3 from '@float/common/api3';
import { CreateTimerParameters } from '@float/common/api3/timers/types';
import { logger } from '@float/libs/logger';
import { Timer } from '@float/types';

import { LOGGED_TIME_CREATED } from '../loggedTimes';
import { TIMER_CREATE } from './constants';
import { Dispatch, LoggedTimeCreatedAction, TimerCreateAction } from './types';

export const createTimer = (timer: CreateTimerParameters) => {
  return async (
    dispatch: Dispatch<TimerCreateAction | LoggedTimeCreatedAction>,
  ) => {
    try {
      const createdTimer = await API3.createTimer(timer);

      dispatch({
        type: LOGGED_TIME_CREATED,
        loggedTime: {
          ...createdTimer,
          start_date: createdTimer.date,
          end_date: createdTimer.date,
        },
      });
      dispatch({
        type: TIMER_CREATE,
        timer: createdTimer.timer,
      });
    } catch (e) {
      logger.error(
        'There was an error while stopping the active timers, as part of starting a new timer',
        e,
      );
    }
  };
};

export const createTimerFromLiveUpdate = (timer: Timer) => {
  return async (dispatch: Dispatch<TimerCreateAction>) => {
    dispatch({
      type: TIMER_CREATE,
      timer,
    });
  };
};
