import { FilteredEntities } from '../types';

export function matchFilteredEntity(
  filteredEntities: FilteredEntities,
  type: keyof FilteredEntities,
  id: string | number | null,
) {
  if (id === null) return false;

  return filteredEntities[type].has(String(id));
}
