import { ReduxStateStrict } from '@float/common/reducers/lib/types';

type PetIsTagsLoadingProps = {
  loadStatePeople: ReduxStateStrict['people']['loadState'];
  loadStateProjects: ReduxStateStrict['projects']['loadState'];
  loadStateTags: ReduxStateStrict['tags']['loadState'];
  loadStateTagsGroups: ReduxStateStrict['tagsGroups']['loadState'];
};

export const getIsTagsPageLoading = ({
  loadStatePeople,
  loadStateProjects,
  loadStateTags,
  loadStateTagsGroups,
}: PetIsTagsLoadingProps) => {
  const isLoadingPeople = loadStatePeople === 'LOADING';
  const isLoadingProjects = loadStateProjects === 'LOADING';
  const isLoadingTags = loadStateTags === 'LOADING';
  const isLoadingTagsGroups = loadStateTagsGroups === 'LOADING';
  const isLoading =
    isLoadingPeople ||
    isLoadingProjects ||
    isLoadingTags ||
    isLoadingTagsGroups;

  return isLoading;
};
