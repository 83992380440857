import React, { useState } from 'react';

import { trackCompanyData } from '@float/common/lib/intercom';
import { getUser } from '@float/common/selectors/currentUser';
import { useAppSelectorStrict } from '@float/common/store';
import Body from '@float/web/settingsV2/components/Body';

import {
  saveLogTimeEmailPreferences,
  saveScheduleEmailPreferences,
} from './actions';
import { RECURRING_EMAIL, weekdays } from './helpers';
import ScheduleEmails from './ScheduleEmails';
import TimeTrackingReminder from './TimeTrackingReminder';

const Notifications = ({
  companyPrefs,
  timeTrackingEnabled,
  updatePeopleNotificationPrefs,
  updateCompanyNotificationPrefs,
}) => {
  const { email_notice_day: emailDayProp, email_notice_active } = companyPrefs;
  const emailFrequencyProp =
    email_notice_active == -1 ? 0 : Number(email_notice_active);
  const {
    logtime_email_day: logTimeDayProp = 1, // monday by default
    logtime_email_active = 0, // disabled by default
  } = companyPrefs;
  const logTimeFrequencyProp =
    logtime_email_active == -1 ? 0 : Number(logtime_email_active);

  const user = useAppSelectorStrict(getUser);
  const [emailFrequency, setEmailFrequency] = useState(emailFrequencyProp);
  const [emailDay, setEmailDay] = useState(emailDayProp);
  const [logTimeFrequency, setLogTimeFrequency] =
    useState(logTimeFrequencyProp);
  const [logTimeDay, setLogTimeDay] = useState(logTimeDayProp);

  const saveEmailPrefs = ({
    newFrequency = emailFrequency,
    newDay = emailDay,
  }) => {
    saveScheduleEmailPreferences({
      wkly_email: newFrequency,
      sent_day: newDay,
      action: 'yt1=Save',
    });

    updatePeopleNotificationPrefs({ auto_email: newFrequency ? 1 : -1 });
    updateCompanyNotificationPrefs({
      email_notice_day: newDay,
      email_notice_active: newFrequency,
    });
  };

  const saveLogTimePrefs = ({
    newFrequency = logtime_email_active,
    newDay = logTimeDay,
  }) => {
    saveLogTimeEmailPreferences({
      logtime_email_day: newDay,
      logtime_email_active: newFrequency,
    });

    updateCompanyNotificationPrefs({
      logtime_email_day: newDay,
      logtime_email_active: newFrequency,
    });
  };

  const onEmailFrequencyChange = (value) => {
    setEmailFrequency(value);
    saveEmailPrefs({ newFrequency: value });
    trackCompanyData('recurring_email', RECURRING_EMAIL[value], user.cid);
  };

  const onEmailDayChange = ({ value }) => {
    setEmailDay(value);
    saveEmailPrefs({ newDay: value });
  };

  const onLogTimeFrequencyChange = (value) => {
    setLogTimeFrequency(value);
    saveLogTimePrefs({ newFrequency: value || -1 });
    trackCompanyData('log_time_reminder', RECURRING_EMAIL[value], user.cid);
  };

  const onLogTimeDayChange = ({ value }) => {
    setLogTimeDay(value);
    saveLogTimePrefs({ newDay: value });
  };

  return (
    <Body
      header="Notifications"
      subheader="Set up automatic schedule emails and time tracking reminders."
    >
      <ScheduleEmails
        emailFrequency={emailFrequency}
        emailDay={emailDay}
        weekdays={weekdays}
        onEmailFrequencyChange={onEmailFrequencyChange}
        onEmailDayChange={onEmailDayChange}
      />

      {timeTrackingEnabled && (
        <TimeTrackingReminder
          logTimeFrequency={logTimeFrequency}
          logTimeDay={logTimeDay}
          weekdays={weekdays}
          onLogTimeFrequencyChange={onLogTimeFrequencyChange}
          onLogTimeDayChange={onLogTimeDayChange}
        />
      )}
    </Body>
  );
};

export default Notifications;
