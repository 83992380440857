import React from 'react';

import { SavedView } from '@float/types/view';

import { ViewTypeFilter } from '../hooks/useViewsOptions';
import { AccessControlProps } from './types';
import { ViewOption } from './ViewOption';
import { ViewsEmptyState } from './ViewsEmptyState';

import * as styles from './ViewsSelectorContent.css';

type ViewsSelectorContentType = {
  acl: AccessControlProps;
  loading: boolean;
  onAddFilterClick: () => void;
  onDelete: (view: SavedView) => void;
  onEdit: (view: SavedView) => void;
  onPersonalChange: (view: SavedView, personal: boolean) => void;
  onPinChange: (view: SavedView, pinned: boolean) => void;
  onViewSelect: (view: SavedView) => void;
  selected?: number;
  options: SavedView[];
  viewNameFilter: string;
  viewTypeFilter: ViewTypeFilter;
};

export const ViewsSelectorContent = (props: ViewsSelectorContentType) => {
  const {
    acl,
    loading,
    onAddFilterClick,
    onDelete,
    onEdit,
    onPersonalChange,
    onPinChange,
    onViewSelect,
    options,
    selected,
    viewNameFilter,
    viewTypeFilter,
  } = props;

  if (!options.length) {
    return (
      <ViewsEmptyState
        viewNameFilter={viewNameFilter}
        viewTypeFilter={viewTypeFilter}
        onAddFilterClick={onAddFilterClick}
        loading={loading}
      />
    );
  }

  return options.map((view) => (
    <ViewOption
      className={styles.item}
      key={view.id}
      view={view}
      acl={acl}
      selected={selected === view.id}
      onSelect={onViewSelect}
      onPersonalChange={onPersonalChange}
      onDelete={onDelete}
      onEdit={onEdit}
      onPinChange={onPinChange}
    />
  ));
};
