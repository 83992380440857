import { find, forEach } from 'lodash';

import {
  NOTIFICATION_FEED_CLOSED,
  NOTIFICATIONS_FETCH_BEGIN,
  NOTIFICATIONS_FETCH_SUCCESS,
  NOTIFICATIONS_SEEN,
} from '@float/web/store/notifications/notifications.actions';

const DEFAULT_STATE = {
  items: {},
  loadState: 'UNLOADED',
  page: 0,
  new: 0,
  unread: false,
  hasMore: true,
  pendingUpdate: null,
};

export const notificationsReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case NOTIFICATIONS_FETCH_BEGIN:
      return {
        ...state,
        loadState: 'LOADING',
      };

    case NOTIFICATIONS_FETCH_SUCCESS: {
      const { items, page, perPage, isLiveUpdate } = action;
      const newItems = page === 1 ? {} : { ...state.items };
      const newState = {};
      if (page) {
        newState.page = page;
      }
      if (isLiveUpdate) {
        newState.new = (state.new || 0) + 1;
      } else {
        newState.loadState = 'LOADED';
        newState.hasMore = items.length >= perPage;
      }
      if (!state.unread) {
        newState.unread = !!items.find((i) => !i.seen);
      }

      items.forEach((n) => {
        if (n._id) {
          newItems[n._id] = {
            nf_id: n._id,
            af_id: n.activity_id,
            seen: n.seen,
          };
        }
      });
      return {
        ...state,
        ...newState,
        items: newItems,
      };
    }

    case NOTIFICATIONS_SEEN: {
      const { ids, isLiveUpdate } = action;
      if (!ids || !ids.length) {
        return state;
      }

      if (isLiveUpdate) {
        const newItems = { ...state.items };
        ids.forEach((id) => {
          if (newItems[id]) {
            newItems[id] = {
              ...newItems[id],
              seen: true,
            };
          }
        });
        return {
          ...state,
          new: 0,
          unread: !!find(newItems, (i) => !i.seen),
          items: newItems,
        };
      }

      const { items } = state;
      const newItems = {};
      ids.forEach((id) => {
        if (items[id]) {
          newItems[id] = {
            ...items[id],
            seen: true,
          };
        }
      });

      return {
        ...state,
        new: 0,
        pendingUpdate: {
          unread: false,
          items: newItems,
        },
      };
    }

    case NOTIFICATION_FEED_CLOSED: {
      if (!state.pendingUpdate) {
        return state;
      }

      const { items, ...rest } = state.pendingUpdate;
      const newItems = { ...state.items };

      forEach(items, (item, key) => {
        newItems[key] = item;
      });

      return {
        ...state,
        ...rest,
        items: newItems,
        pendingUpdate: null,
      };
    }

    default:
      return state;
  }
};
