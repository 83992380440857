import { connect } from 'react-redux';

import withOnMount from '@float/web/settingsV2/decorators/withOnMount';
import {
  areBeingFetched as holidaysAreBeingFetched,
  lastFetchError as holidaysLastFetchError,
} from '@float/web/settingsV2/reducers/holidays';
import {
  ACCOUNT_SETTINGS_HOLIDAYS_TABLE,
  getSortData,
} from '@float/web/settingsV2/reducers/reducerHelpers';

import { fetchHolidays, sortHolidays } from '../../actions/holidays';
import FilteredByYearTable from './HolidaysFilteredByYearTable';

const isLoading = (state) => holidaysAreBeingFetched(state.settingsHolidays);

const loadError = (state) => holidaysLastFetchError(state.settingsHolidays);

const mapStateToProps = (state) => {
  return {
    sort: getSortData(state.settingsHolidays, ACCOUNT_SETTINGS_HOLIDAYS_TABLE),
    holidaysState: state.settingsHolidays,
    isLoading: isLoading(state),
    companyId: state.currentUser.cid,
    loadError: loadError(state),
    hasHiddenBody:
      isLoading(state) && state.settingsHolidays.entities.length == 0,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onComponentDidMount: () =>
      dispatch(fetchHolidays(ACCOUNT_SETTINGS_HOLIDAYS_TABLE)),
    onClickHeader: (property, direction) =>
      dispatch(
        sortHolidays(property, direction, ACCOUNT_SETTINGS_HOLIDAYS_TABLE),
      ),
    onSubmit: () => null,
  };
};

const HolidaysTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOnMount(FilteredByYearTable));

export default HolidaysTable;
