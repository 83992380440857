import format from 'date-fns/format';

import { DEFAULT_DATESTRING_FORMAT } from '@float/constants/dates';

import {
  EditTaskModalEntityLengthGetter,
  EditTaskModalPartialStateForCallbacks,
  EditTaskModalState,
} from '../../schedule/task/EditTaskModal.types';
import { getWorkDays } from './getWorkDays';

export const getTotalHours = (hoursPd: number, length: number) =>
  parseFloat((hoursPd * length).toFixed(2));

/**
 * @deprecated
 * this function uses the modal state as param
 * */
export const calculateRepeatTimes = (
  dates: DatesManager,
  state: EditTaskModalPartialStateForCallbacks,
) => {
  const { repeatState } = state;

  if (!repeatState) {
    return 0;
  }

  let start = format(state.startDate, DEFAULT_DATESTRING_FORMAT);

  const repeatEnd = format(state.repeatEnd, DEFAULT_DATESTRING_FORMAT);

  if (state.task.allInstances?.length) {
    // If we're editing a repeating task, we want to count repeats
    // from the first task instance
    start = state.task.allInstances[0].start_date;
  }

  return dates.getRepeatStarts(repeatState, start, repeatEnd).length + 1;
};

export const calculateWorkDays = (
  getEntityLength: EditTaskModalEntityLengthGetter,
  newState: EditTaskModalPartialStateForCallbacks,
) => {
  if (newState.task) {
    newState.length = getWorkDays(getEntityLength, {
      start_date: newState.startDate,
      end_date: newState.endDate,
      people_ids: newState.peopleIds,
      timeoff_id:
        'timeoff_id' in newState.task ? newState.task.timeoff_id : undefined,
    });
  }

  return newState;
};

export const calculateTotalHours = (
  getEntityLength: EditTaskModalEntityLengthGetter,
  newState: EditTaskModalState,
) => {
  calculateWorkDays(getEntityLength, newState);
  const { hoursPd, length } = newState;

  newState.totalHours = getTotalHours(hoursPd, length);

  return newState;
};
