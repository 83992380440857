import { useState } from 'react';

import { ModalProps } from '@float/ui/primitives/Modal';

export type RenderProps<R> = R | null;

/**
 * Custom hook to manage the state and behavior of a modal.
 *
 * If you need to control the visibility of modal locally you can use this hook.
 * This hook works well with our Modal Primitive (@ui/src/primitives/Modal).
 *
 * Modals based on the Modal primitive have entry and exit animations, so one
 * particularity of this hook is the 'present' variable that let us control
 * when it's safe to mount/unmount a modal component.
 *
 * For examples on how to use it you can refer to the stories in
 * @ui/src/primitives/Modal.
 *
 * @template R - The type of render properties.
 * @param {Omit<ModalProps, 'children'>} [props={}] - The modal properties,
 * excluding 'children'.
 * @returns {IUseModalLocal<R>} - An object containing modal controls,
 * properties, and render properties.
 */

export const useModalLocal = <R>(props: Omit<ModalProps, 'children'> = {}) => {
  const [open, setOpen] = useState(props.defaultOpen || props.open || false);
  const [present, setPresent] = useState(open);
  const [renderProps, setRenderProps] = useState<RenderProps<R>>();

  const closeModal = () => {
    setOpen(false);
  };

  const openModal = (renderProps: RenderProps<R> = null) => {
    setRenderProps(renderProps);
    setOpen(true);
    setPresent(true);
  };

  const destroyModal = () => {
    setOpen(false);
    setPresent(false);
  };

  const onOpenChange = (state: boolean) => {
    if (state) {
      openModal();
    } else {
      closeModal();
    }

    if (props.onOpenChange) props.onOpenChange(state);
  };

  const onExitComplete = () => {
    if (!open) setPresent(false);

    if (props.onExitComplete) props.onExitComplete();
  };

  return {
    controls: {
      openModal,
      closeModal,
      destroyModal,
      present,
    },
    props: {
      ...props,
      onExitComplete,
      onOpenChange,
      open,
    },
    renderProps,
  };
};

export type IUseModalLocal<R = unknown> = ReturnType<typeof useModalLocal<R>>;
