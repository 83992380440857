import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { addMilliseconds, endOfDay, getDay, parse, startOfDay } from 'date-fns';

import { useStateCallback } from '@float/common/lib/hooks/useStateCallback';
import { formatClockTimeAsMs } from '@float/common/lib/time';
import { getTimeFormatPattern } from '@float/common/lib/timer/getTimeFormatPattern';
import { parseTimer } from '@float/common/lib/timer/parseTimer';
import { resetSecondsAndMilliseconds } from '@float/common/lib/timer/resetSecondsAndMilliseconds';
import { TimeFormat, Timer, TimerTimes } from '@float/types';

const WITH_DEBUG = false;

const debugDates = (dates: object) => {
  if (!WITH_DEBUG) return;

  const a = Object.values(dates).map((v, index) => [
    Object.keys(dates)[index],
    new Date(v).toLocaleString(),
  ]);

  console.log(...a.flat());
};

export const useTimerEntryRow = (
  timer: Timer,
  timeFormat: TimeFormat,
  active: boolean,
  disabled: boolean,
  deleteTimer: (id: string) => void,
  updateTimer: (timer: Timer) => void,
  onDidStartEditing: () => void,
  onDidStopEditing: () => void,
) => {
  const originalTimer = useMemo(() => {
    const {
      logged = '00:00',
      start = '00:00',
      end = '00:00',
    } = parseTimer(timer, timeFormat) || {};

    return { logged, start, end };
  }, [timer, timeFormat]);

  const editableTimer = useRef<Timer | null>(null);

  const [timerLogged, setTimerLogged] = useState(originalTimer.logged);
  const [timerStart, setTimerStart] = useState(originalTimer.start);
  const [timerEnd, setTimerEnd] = useState(originalTimer.end);

  const [editMode, setEditMode] = useStateCallback(active && !disabled);
  const [error] = useStateCallback<{ message: string } | null>(null);
  const [isSubmitting] = useStateCallback(false);

  const isEditing = (editMode || active) && !disabled;
  const isDisabled = disabled;

  const didStartEditing = useCallback(() => {
    if (onDidStartEditing) onDidStartEditing();
  }, [onDidStartEditing]);

  const didStopEditing = useCallback(() => {
    if (onDidStopEditing) onDidStopEditing();
  }, [onDidStopEditing]);

  const discardChanges = useCallback(() => {
    if (isSubmitting) return;

    editableTimer.current = null;

    setEditMode(false, didStopEditing);
    setTimerLogged(originalTimer.logged);
    setTimerStart(originalTimer.start);
    setTimerEnd(originalTimer.end);
  }, [
    didStopEditing,
    isSubmitting,
    setEditMode,
    originalTimer.logged,
    originalTimer.start,
    originalTimer.end,
  ]);

  const onClickEdit = useCallback(() => {
    setEditMode(true, didStartEditing);
  }, [didStartEditing, setEditMode]);

  const onClickCancel = useCallback(() => {
    discardChanges();
  }, [discardChanges]);

  const onClickConfirm = useCallback(() => {
    setEditMode(false, didStopEditing);
    if (editableTimer.current) {
      updateTimer(editableTimer.current);
    }
  }, [didStopEditing, setEditMode, updateTimer]);

  const onClickDelete = useCallback(
    () => deleteTimer(timer._id),
    [deleteTimer, timer],
  );

  const recalculateTimer = (newTimer: Timer, format: TimeFormat) => {
    const currentDay = getDay(timer.start_timestamp!);
    const startTimeDay = getDay(newTimer.start_timestamp!);
    const endTimeDay = getDay(newTimer.end_timestamp!);

    if (
      newTimer.end_timestamp &&
      newTimer.start_timestamp! > newTimer.end_timestamp!
    ) {
      newTimer.start_timestamp = newTimer.end_timestamp;
    }

    if (endTimeDay > currentDay) {
      newTimer.end_timestamp = endOfDay(timer.start_timestamp!).getTime();
    }

    if (startTimeDay < currentDay) {
      newTimer.start_timestamp = startOfDay(timer.start_timestamp!).getTime();
    }

    // re-calculate timer
    const { logged, start, end } = parseTimer(
      newTimer,
      timeFormat,
    ) as TimerTimes;

    debugDates({
      start: newTimer.start_timestamp,
      end: newTimer.end_timestamp,
    });

    if (logged) setTimerLogged(logged);
    if (start) setTimerStart(start);
    if (end) setTimerEnd(end);

    editableTimer.current = newTimer;
  };

  const onLoggedTimeChange = (value: string, isCompleted: boolean) => {
    setTimerLogged(value);

    if (!isEditing || !isCompleted) return;

    const newTimer = Object.assign({}, timer, editableTimer.current);

    // convert logged time to ms
    const loggedTimeTs = formatClockTimeAsMs(value);
    // set secs and ms to 0
    const startTimeTs = resetSecondsAndMilliseconds(newTimer.start_timestamp!);
    // calculate new end time based on new logged time
    const endTimeTs = addMilliseconds(startTimeTs, loggedTimeTs).getTime();

    // add new values to new timer
    newTimer.start_timestamp = startTimeTs;
    newTimer.end_timestamp = endTimeTs;

    recalculateTimer(newTimer, timeFormat);
  };

  const onStartTimeChange = (value: string, isCompleted: boolean) => {
    setTimerStart(value);

    if (!isEditing || !isCompleted) return;

    const newTimer = Object.assign({}, timer, editableTimer.current);

    // calculate the new timerStart value on current timer's date
    const startTimeDate = parse(
      value,
      getTimeFormatPattern(timeFormat),
      resetSecondsAndMilliseconds(newTimer.start_timestamp!),
    );
    const startTimeTs = startTimeDate.getTime();

    // convert current timerLogged to ms
    const loggedTimeTs = formatClockTimeAsMs(timerLogged);

    // calculate new timerEnd by adding current logged time to new start time
    const endTimeTs = startTimeTs + loggedTimeTs;

    // add new values to new timer
    newTimer.start_timestamp = startTimeTs;
    newTimer.end_timestamp = endTimeTs;

    recalculateTimer(newTimer, timeFormat);
  };

  const onEndTimeChange = (value: string, isCompleted: boolean) => {
    setTimerEnd(value);

    if (!isEditing || !isCompleted) return;

    const newTimer = Object.assign({}, timer, editableTimer.current);

    // calculate the new timerEnd value
    const endTimeDate = parse(
      value,
      getTimeFormatPattern(timeFormat),
      // make sure end time is of the same day as the start time
      // https://linear.app/float-com/issue/CS-1524/issue-editing-time-on-timer-entry
      resetSecondsAndMilliseconds(newTimer.start_timestamp!),
    );

    const endTimeTs = endTimeDate.getTime();
    let startTimeTs = resetSecondsAndMilliseconds(newTimer.start_timestamp!);

    if (endTimeTs < startTimeTs) {
      startTimeTs = endTimeTs;
    }

    // add new values to new timer
    newTimer.start_timestamp = startTimeTs;
    newTimer.end_timestamp = endTimeTs;

    recalculateTimer(newTimer, timeFormat);
  };

  useEffect(() => {
    function onKeyDown(e: KeyboardEvent) {
      if (isEditing) {
        if (e.key === 'Escape') {
          e.stopPropagation();

          onClickCancel();
        }

        if (
          e.key === 'Enter' &&
          document.activeElement?.tagName.toLowerCase() === 'input'
        ) {
          onClickConfirm();
        }
      }
    }

    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [isEditing, onClickCancel, onClickConfirm]);

  // update fields if there are external changes
  // to the original timer (e.g., live updates)
  useUpdateEffect(() => {
    setTimerLogged(originalTimer.logged);
    setTimerStart(originalTimer.start);
    setTimerEnd(originalTimer.end);
  }, [originalTimer]);

  return {
    error,
    isDisabled,
    isEditing,
    isSubmitting,
    onClickCancel,
    onClickConfirm,
    onClickDelete,
    onClickEdit,
    onEndTimeChange,
    onLoggedTimeChange,
    onStartTimeChange,
    timerEnd,
    timerLogged,
    timerStart,
  };
};
