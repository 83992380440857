import { compact } from 'lodash';

import { FilterCategory, VirtualFilterTypes } from '@float/common/search/types';

import IconClient from '../icons/IconClient';
import IconDepartment from '../icons/IconDepartment';
import IconPerson from '../icons/IconPerson';
import IconProject from '../icons/IconProject';
import IconStar from '../icons/IconStar';
import IconTask from '../icons/IconTask';
import IconTimeOff from '../icons/IconTimeOff';

const categories = [
  {
    type: 'savedSearches',
    bg: 'Violet',
    icon: IconStar,
    categories: [
      { shortLabel: 'Saved', label: 'Saved filter', value: 'savedSearches' },
    ],
  },
  {
    type: 'departments',
    bg: 'Yellow',
    icon: IconDepartment,
    categories: [{ label: 'Department', value: 'departments' }],
  },
  {
    type: 'people',
    bg: 'Blue',
    icon: IconPerson,
    categories: compact([
      { label: 'Me', value: 'me', plural: 'Me' },
      { label: 'Person', value: 'people', plural: 'People' },
      { label: 'Role', value: 'jobTitles' },
      { label: 'Person tag', value: 'personTags' },
      { label: 'Person type', value: 'personTypes' },
      { label: 'Manager', value: 'managers' },
    ]),
  },
  {
    type: 'timeoffs',
    bg: 'Orange',
    icon: IconTimeOff,
    categories: [
      { label: 'Time off', value: 'timeoffs' },
      {
        label: 'Time off status',
        value: 'timeoffStatuses',
        plural: 'Time off statuses',
      },
    ],
  },
  {
    type: 'clients',
    bg: 'Teal',
    icon: IconClient,
    categories: [{ label: 'Client', value: 'clients' }],
  },
  {
    type: 'projects',
    bg: 'Pink',
    icon: IconProject,
    categories: [
      { label: 'Project', value: 'projects' },
      { label: 'Phase', value: 'phases' },
      { label: 'Project tag', value: 'projectTags' },
      { label: 'Project owner', value: 'projectOwners' },
      {
        label: 'Project status',
        value: 'projectStatuses',
        plural: 'Project statuses',
      },
    ],
  },
  {
    type: 'tasks',
    bg: 'Tomato',
    icon: IconTask,
    categories: [
      { label: 'Task', value: 'tasks' },
      {
        label: 'Allocation status',
        value: 'taskStatuses',
        plural: 'Allocation statuses',
      },
    ],
  },
  {
    type: 'contains',
    bg: 'Gray',
    icon: null,
    categories: [{ label: 'Contains', value: 'contains' }],
    hideInAllView: true,
  },
] as const;

export function getSubCategoryConfig(
  type: VirtualFilterTypes | FilterCategory,
) {
  for (const parent of categories) {
    for (const category of parent.categories) {
      if (category.value === type) {
        const plural = 'plural' in category ? category.plural : undefined;

        return {
          type,
          bg: parent.bg,
          icon: parent.icon,
          categories: [category],
          label: category.label,
          value: category.value,
          plural,
          isParent: parent.type === category.value,
        };
      }
    }
  }

  // to debug the occurence of https://rollbar.com/float/fe-web-app/items/4879/
  throw new Error('No category found for ' + type);
}
