import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { Person } from '@float/types/person';
import { Placeholder } from '@float/types/placeholder';

export type IsLegacyPlaceholderType = {
  person: Person;
};

export const isLegacyPlaceholderType = (props: IsLegacyPlaceholderType) => {
  const { person } = props;

  const isPlaceholdersPackagingFeatureEnabled = featureFlags.isFeatureEnabled(
    FeatureFlag.PlaceholdersPackaging,
  );
  const isPlaceholder = person.people_type_id === 3;
  const isLegacyPlaceholder =
    person.new_role_placeholder === Placeholder.Legacy;

  return (
    isPlaceholdersPackagingFeatureEnabled &&
    isPlaceholder &&
    isLegacyPlaceholder
  );
};
