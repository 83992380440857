import { createSelector } from 'reselect';

import { getPhasesMapRaw } from './phases';

export const getPhaseById = createSelector(
  [getPhasesMapRaw, (_, id: number | undefined) => id],
  (phases, id) => {
    if (!id) return null;

    return phases[id] ?? null;
  },
);
