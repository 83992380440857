import {
  FETCH_APIKEY_FAILURE,
  FETCH_APIKEY_SUCCESS,
} from '../../actions/account/jwt';

const getInitialState = () => {
  const jwtCookie = document.cookie
    .split(';')
    .map((cookie) => {
      const [name, value] = cookie.trim().split('=');
      return { name, value };
    })
    .find((cookie) => cookie.name === 'float_jwt');
  return {
    value: jwtCookie ? jwtCookie.value : '',
    apiKey: null,
  };
};

const jwt = (state = getInitialState(), action) => {
  switch (action.type) {
    case FETCH_APIKEY_SUCCESS:
      return {
        apiKey: action.payload.token,
      };
    case FETCH_APIKEY_FAILURE:
    default:
      return state;
  }
};

export default jwt;
