import {
  ADD_INVOICE_SUCCESS,
  FETCH_INVOICES,
  FETCH_INVOICES_FAILURE,
  FETCH_INVOICES_SUCCESS,
  SORT_INVOICES,
} from '../../actions/account/invoices';
import {
  DEFAULT_SORT_TABLE,
  getInitialSortableTableState,
  getInitialSortPropsState,
  getInitialStateCreator,
  reducerCreator,
} from '../reducerHelpers';

export const lastFetchError = (state) => state.lastFetchError;

export const areBeingFetched = (state) => state.pagesBeingFetched.length > 0;

const getInitialState = getInitialStateCreator(
  Object.assign(
    {
      sortableTables: {
        [DEFAULT_SORT_TABLE]: getInitialSortableTableState(
          'invoice_id',
          'desc',
        ),
      },
    },
    getInitialSortPropsState([
      'invoice_id',
      'billed_on',
      'due_on',
      'state',
      'total',
      'download_link',
    ]),
  ),
);

const invoices = reducerCreator(
  {
    ADD_ENTITY_SUCCESS: ADD_INVOICE_SUCCESS,
    SORT_ENTITIES: SORT_INVOICES,
    FETCH_ENTITIES: FETCH_INVOICES,
    FETCH_ENTITIES_SUCCESS: FETCH_INVOICES_SUCCESS,
    FETCH_ENTITIES_FAILURE: FETCH_INVOICES_FAILURE,
  },
  'invoice_id',
  getInitialState,
);

export default invoices;
