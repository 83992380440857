import React from 'react';
import { Trans } from '@lingui/macro';

import { NavIconBtn } from '@float/common/components/NavIconBtn';
import { IconWarningTriangle48x48 } from '@float/ui/deprecated/Earhart/Icons/Icon/IconWarningTriangle48x48';
import { List } from '@float/ui/primitives/List';

import * as styles from './ViewsErrorScreen.css';

type ViewsErrorScreenProps = {
  onRetry: () => void;
};

export const ViewsErrorScreen = React.forwardRef<
  HTMLDivElement,
  ViewsErrorScreenProps
>(({ onRetry, ...props }, ref) => {
  return (
    <List.Box {...props} ref={ref} className={styles.container}>
      <IconWarningTriangle48x48 />
      <div className={styles.text}>
        <Trans>An error occurred loading Views. Please try again.</Trans>
      </div>
      <NavIconBtn isPrimary onClick={onRetry}>
        <Trans>Retry</Trans>
      </NavIconBtn>
    </List.Box>
  );
});
