import { ReduxState } from '@float/common/reducers/lib/types';
import { SavedViewSettings } from '@float/types';

import {
  selectIsLogTimeViewType,
  selectScheduleViewType,
} from '../appInfo/scheduleView';
import { getLocationPathname } from '../location';

export const getActivePage = (state: ReduxState): SavedViewSettings['page'] => {
  const location = getLocationPathname(state);
  const viewType = selectScheduleViewType(state);

  if (location === '/log-time') return 'log-my-time';
  if (location === '/people') return 'manage-people';
  if (location === '/projects') return 'manage-projects';
  if (location === '/report') {
    if (viewType === 'projects') {
      return 'projects-report';
    }

    return 'people-report';
  }

  const logTimeView = selectIsLogTimeViewType(state);

  if (logTimeView) return 'log-time';

  if (viewType === 'projects') return 'project-plan';

  return 'schedule-people';
};
