import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  updateCompanyNameHostname,
  validateCompanyHostname,
} from '@float/common/actions/companyPrefs';
import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { AppDispatchStrict } from '@float/common/store';
import { withSnackbar } from '@float/ui/deprecated';

import withOnMount from '../../../decorators/withOnMount';
import TeamNameURL from './component';

const mapStateToProps = (state: ReduxStateStrict) => ({
  name: state.companyPrefs.company_name,
  hostname: state.companyPrefs.company_hostname,
  companyId: state.currentUser.cid,
});

const mapDispatchToProps = (dispatch: AppDispatchStrict) => ({
  validateUrl: (url: string) => dispatch(validateCompanyHostname(url)),
  onSubmit: (data: { name: string; hostname: string }) =>
    dispatch(updateCompanyNameHostname(data)),
});

const Component = compose(withOnMount, withSnackbar)(TeamNameURL);
const StatefulTeamNameAndURL = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default StatefulTeamNameAndURL;
