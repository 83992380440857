import React, { ForwardedRef, forwardRef, SVGProps, useId } from 'react';

export interface BaseIconProps extends React.PropsWithChildren<SVGProps<{}>> {
  ref?: ForwardedRef<SVGSVGElement | null>;
  size?: number | string;
  color?: string;
  iconNoScalingStroke?: boolean;
  title?: string;
}

export default forwardRef(
  (props: BaseIconProps, ref: ForwardedRef<SVGSVGElement>) => {
    const {
      viewBox = '0 0 24 24',
      size = '24',
      color,
      children,
      iconNoScalingStroke,
      ...rest
    } = props;

    const titleId = `svg-title-${useId()}`;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox={viewBox}
        fill="none"
        {...rest}
        ref={ref}
        aria-labelledby={props.title ? titleId : undefined}
      >
        {props.title && <title id={titleId}>{props.title}</title>}
        {React.Children.map(children, (child: any, index) => {
          if (!child) return null;

          const childProps = child.props as SVGProps<{}>;
          const style: React.CSSProperties = {};

          let vectorEffect = '';

          if (childProps.stroke) {
            style.stroke = `var(--svg-icon-color, ${
              color || childProps.stroke
            })`;

            if (iconNoScalingStroke) vectorEffect = 'non-scaling-stroke';
          }

          if (childProps.fill) {
            style.fill = `var(--svg-icon-color, ${color || childProps.fill})`;
          }

          return React.cloneElement(child, {
            ...childProps,
            key: index,
            fillprop: null,
            style,
            vectorEffect,
          });
        })}
      </svg>
    );
  },
);
