import styled from 'styled-components';

import EH from '@float/ui/deprecated/Earhart';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

export const StyledActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-top: 20px;

  min-height: 32px;
`;

export const StyledCard = styled(EH.Cards.Card)`
  display: flex;
  flex-direction: column;
`;

export const StyledRow = styled.div`
  display: flex;

  &:nth-child(2) {
    margin-left: ${48 + 24}px;
    margin-top: 10px;
  }
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;

  ${(p) => p.alignItems && `align-items: ${p.alignItems}`};
  ${(p) => p.justifyContent && `justify-content: ${p.justifyContent}`};
  ${(p) => p.stretch && 'flex: 1'};

  & + & {
    margin-left: 24px;
  }
`;

export const StyledTitle = styled.h3`
  ${Typography.Label16.SB600};

  display: flex;
  align-items: center;
  margin-bottom: 5px;

  color: ${Colors.Primary.Flue.Light[11]};
`;

export const StyledLabel = styled.span`
  ${(p) => (p.secondary ? Typography.Label12.M500 : Typography.Label13.R400)};

  line-height: 1.5;

  color: ${(p) =>
    p.secondary
      ? Colors.FIN.Lt.Emphasis.Medium
      : Colors.Primary.Flue.Light[11]};
`;

export const StyledLink = styled.a`
  ${Typography.Label13.SB600};

  color: ${Colors.FIN.Lt.Emphasis.Primary};

  &:hover {
    color: ${Colors.FIN.Lt.Buttons.Primary.Hover.Bg};
  }

  &:active {
    color: ${Colors.FIN.Lt.Buttons.Primary.Pressed.Bg};
  }
`;

export const StyledIconContainer = styled.div`
  display: flex;

  margin-right: 24px;
`;

export const StyledModalTitle = styled.h1`
  ${Typography.DisplayXS.SB600};

  color: ${Colors.FIN.Lt.Emphasis.High};

  margin-top: 24px;

  text-align: center;
`;

export const StyledModalRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  &:first-of-type {
    margin-top: 40px;
    margin-left: 24px;
    margin-right: 24px;
  }

  & + & {
    margin-top: 16px;
  }

  &:last-child {
    margin-bottom: 35px;
  }

  ${StyledActions} {
    justify-content: center;

    margin-top: 0;
  }
`;

export const StyledModalFeatureBlock = styled.div`
  flex: 0.333;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  & + & {
    margin-left: 32px;
  }
`;

export const StyledModalSubTitle = styled.h2`
  ${Typography.Label18.SB600};

  color: ${Colors.FIN.Lt.Emphasis.High};

  margin-top: 12px;
  margin-bottom: 12px;
`;

export const StyledModalText = styled.span`
  ${Typography.TextM.R400};

  color: ${Colors.FIN.Lt.Emphasis.Medium};
`;

export const StyledModalList = styled.ul`
  ${Typography.TextM.R400};

  color: ${Colors.FIN.Lt.Emphasis.Medium};

  margin-top: 6px;

  li {
    position: relative;

    list-style: none;
    list-style-position: inside;

    padding-left: 20px;

    &:before {
      position: absolute;

      left: 5px;

      content: '•';
    }
  }
`;

export const StyledModalBody = styled.div`
  h3 {
    font-size: 18px;
    font-weight: 600;

    color: ${(p) => p.theme.flayLight12};
  }

  > strong {
    font-weight: 600;

    color: ${(p) => p.theme.flayLight11};
  }

  ul {
    font-size: 16px;
    line-height: 21px;
    margin-top: 9px;
    margin-left: 19px;

    li {
      list-style-type: initial;
      margin-top: 10px;
    }
  }

  > a {
    color: ${(p) => p.theme.blue};
    font-size: 16px;
    text-decoration: none;
    margin-top: 8px;
    font-size: 16px;
    display: block;
  }

  > p {
    color: ${(p) => p.theme.flayLight11};
    font-size: 14px;
    letter-spacing: -0.2px;
    margin-top: 24px;
    margin-bottom: -18px;
  }
`;

export const StyledModalHeader = styled.div`
  p {
    margin-top: 12px;
    color: ${EH.Colors.Core.Text.Secondary};

    ${EH.Typography.TextXL.R400};
  }
`;
