import React, { forwardRef, useRef } from 'react';
import { Transition, TransitionRef } from 'TransitionManager';

import { OnboardingPrompt } from '@float/web/store/onboardingManager/types';

import * as styles from './styles.css';

export const GetToKnowYouAside = forwardRef<TransitionRef, OnboardingPrompt>(
  (props, ref) => {
    const { transitionId } = props;

    const contentRef = useRef(null);

    return (
      <Transition ref={ref} id={transitionId}>
        <div className={styles.illustration} ref={contentRef}>
          <svg
            className={styles.svg}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 469 585"
          >
            <g>
              <rect
                vectorEffect="non-scaling-stroke"
                width="219.2"
                height="214"
                x="249.2"
                y=".5"
                fill="#fff"
                stroke="#000"
                rx="5.5"
              />
              <rect
                vectorEffect="non-scaling-stroke"
                width="66.8"
                height="64.2"
                x="237.5"
                y="137.3"
                fill="#96C7F2"
                stroke="#000"
                rx="4.5"
              />
              <rect
                vectorEffect="non-scaling-stroke"
                width="96.7"
                height="31.6"
                x="290.9"
                y="156.9"
                fill="#fff"
                stroke="#000"
                rx="3.5"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#FFED67"
                stroke="#000"
                d="M369.2 174.6a1 1 0 0 1 1.4-.6l12.2 4.5a1.2 1.2 0 0 1-.9 2.3l-4.6-1.5-7.5-3.3a1 1 0 0 1-.6-1.4Z"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#FFED67"
                stroke="#000"
                d="M368.3 172.3c.5-.8 1.6-1.1 2.4-.6l11 6.3a1 1 0 0 1-1 1.7l-12.3-7a.3.3 0 0 1-.1-.4Z"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#FFED67"
                stroke="#000"
                d="M371 172.3c.5-.8 1.5-1.1 2.4-.7l9.3 5a1 1 0 1 1-1 2l-10.6-5.9a.3.3 0 0 1-.1-.4Z"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#FFED67"
                stroke="#000"
                d="M376.1 174.9c.5-.9 1.6-1.2 2.4-.7l8.3 4.4a1 1 0 0 1-1 1.9l-9.6-5.2a.3.3 0 0 1 0-.4Z"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#FFED67"
                fillRule="evenodd"
                d="m402.9 178.4-11.6 3.7c-2 .7-4.2.6-6.2 0l-5-1.9 3-1.3 4-.2-6.5-3.5c1.2-.1 5.4-.9 7.2-2.7l.2-.2-8.2-2.4a2.3 2.3 0 0 1-1.6-2.8c.1-.4.6-.7 1-.6l5 1.5c1.4 0 3.2-.4 5-1.1 3.7-1.5 5.5-2.2 11 1.5h5.7v10h-3Z"
                clipRule="evenodd"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#000"
                d="m391.3 182.1.1.5-.1-.5Zm11.6-3.7.1.5.4-.1v-.4h-.5ZM385 182l.1-.5-.1.5Zm-5-1.8-.2-.4-1.2.5 1.2.4.2-.5Zm3-1.3v-.5h-.2l.2.5Zm4-.2v.5h1.8l-1.6-1-.2.5Zm-6.5-3.5v-.5l-1.7.2 1.5.8.2-.5Zm7.2-2.7.4.3-.4-.3Zm.2-.2.3.3.5-.5-.7-.2-.1.4Zm-8.2-2.4-.2.5.2-.5Zm-1.6-2.8-.5-.2.5.2Zm1-.6.1-.4-.1.4Zm5 1.5-.2.5h.2v-.5Zm5-1.1-.3-.5.2.5Zm11 1.5-.3.4v.1h.2v-.5Zm5.7 0h.5v-.5h-.5v.5Zm0 10v.5h.5v-.5h-.5Zm-3 0v-.5h-.6v.6h.6Zm-11.5 4.2L403 179l-.3-1-11.6 3.8.3 1Zm-6.5-.1c2.1.7 4.4.8 6.5.1l-.3-1c-1.9.7-4 .6-5.9 0l-.3.9Zm-5-1.8 5 1.8.3-1-5-1.7-.3.9Zm3-2.2-3 1.3.4.9 3-1.3-.4-1Zm4.2-.3-4 .2v1l4-.2v-1Zm-6.7-2.5 6.5 3.5.4-1-6.5-3.4-.4.9Zm7-3.6a9 9 0 0 1-3.6 2c-1.4.3-2.7.6-3.3.6l.1 1c.7 0 2-.3 3.5-.7a10 10 0 0 0 4-2.2l-.6-.7Zm.2 0h-.1l.7.7.1-.2-.7-.6Zm-8-1.7 8.2 2.4.3-1-8.2-2.4-.3 1Zm-1.9-3.5c-.4 1.5.4 3 2 3.5l.2-1c-1-.2-1.5-1.2-1.2-2.2l-1-.3Zm1.6-.8c-.6-.2-1.4.2-1.6.8l1 .3.3-.2.3-1Zm5 1.4-5-1.4-.3 1 5 1.4.3-1Zm4.6-1c-1.6.6-3.4 1-4.8 1v1c1.6 0 3.4-.4 5.2-1.1l-.4-1Zm11.5 1.5a13.5 13.5 0 0 0-6.4-2.8c-1.7-.1-3.2.5-5 1.2l.3 1c2-.8 3.2-1.3 4.7-1.2 1.4 0 3.1.8 5.9 2.6l.5-.8Zm5.5 0H400v1h5.8v-1Zm.5 10.4v-10h-1v10h1Zm-3.5.5h3v-1h-3v1Zm.5-.5-1 .1 1-.1Z"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#FFED67"
                d="m393.4 179.2-5-3.3-5.5 2.6-4.3-.7a8 8 0 0 0 4.5 3l1.8.4 8.5-2Z"
              />
              <path
                vectorEffect="non-scaling-stroke"
                stroke="#000"
                d="M396.6 175.6s-2.2-1-4.3 0c-2.7 1.1-5.4 2.8-8.2 1.6"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#E5484D"
                stroke="#000"
                d="M378.7 158.2c1 1.3 5 4.3 6.8 5.7l3.7-2.8c1.2-1 3.5-3.1 4.6-4.8 1.1-1.6 2.5-6.5-1.9-8.1a5.1 5.1 0 0 0-6.4 2.3c-1.4-3.3-6.8-3.8-8.8-.5-1.9 3.3.5 6.7 2 8.2Z"
              />
              <circle
                vectorEffect="non-scaling-stroke"
                cx="263.1"
                cy="13"
                r="3.9"
                fill="#96C7F2"
              />
              <circle
                vectorEffect="non-scaling-stroke"
                cx="274.8"
                cy="13"
                r="3.9"
                fill="#96C7F2"
              />
              <circle
                vectorEffect="non-scaling-stroke"
                cx="285.2"
                cy="13"
                r="3.9"
                fill="#96C7F2"
              />
              <rect
                vectorEffect="non-scaling-stroke"
                width="66.8"
                height="64.2"
                x="237.5"
                y="29.2"
                fill="#96C7F2"
                stroke="#000"
                rx="4.5"
              />
              <rect
                vectorEffect="non-scaling-stroke"
                width="111.1"
                height="29"
                x="290.9"
                y="48.7"
                fill="#fff"
                stroke="#000"
                rx="3.5"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#242C39"
                d="M300.8 56h24.8v1.3h-24.8zm0 5.2h66.5v1.3h-66.5zm0 3.9h66.5v1.3h-66.5zm0 4h56v1.3h-56zm0 95.1h66.5v1.3h-66.5zm0 5.2h66.5v1.3h-66.5zm0 5.2h66.5v1.3h-66.5zm0 5.2h66.5v1.3h-66.5z"
              />
              <rect
                vectorEffect="non-scaling-stroke"
                width="83.7"
                height="35.5"
                x="-.5"
                y=".5"
                fill="#fff"
                stroke="#000"
                rx="3.5"
                transform="matrix(-1 0 0 1 430.2 96.4)"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#242C39"
                d="M359.5 110.8h30v1.3h-30zm0 3.9h30v1.3h-30zm0 3.9h30v1.3h-30z"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#FFED67"
                stroke="#242C39"
                d="m410 105 1.7-7.8a4.6 4.6 0 0 0-3.7-5.4l-2-.4c-2.5-.5-5 1-5.6 3.4l-1.2 4.6-1.3 4.8a2 2 0 0 0 1.3 2.3l2 .7c1.3.5 2.7.8 4.1 1l4 .3.7-3.5Z"
              />
              <rect
                vectorEffect="non-scaling-stroke"
                width="2.3"
                height="15.1"
                x="-.6"
                y=".4"
                fill="#FFED67"
                stroke="#242C39"
                rx="1.2"
                transform="matrix(-.99103 -.22605 -.23795 .95408 413.8 84.5)"
              />
              <rect
                vectorEffect="non-scaling-stroke"
                width="2.3"
                height="9.8"
                x="-.7"
                y=".1"
                fill="#FFED67"
                stroke="#242C39"
                rx="1.2"
                transform="matrix(-.8159 -.59484 -.6114 .77917 415.8 94.1)"
              />
              <rect
                vectorEffect="non-scaling-stroke"
                width="2.3"
                height="15.1"
                x="-.6"
                y=".4"
                fill="#FFED67"
                stroke="#242C39"
                rx="1.2"
                transform="matrix(-.99103 -.22605 -.23795 .95408 411.2 83.1)"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#96C7F2"
                stroke="#262626"
                d="m417.1 81-2 1c-.3.1-.3.5-.1.7l3 3c.3.2.8 0 .7-.5l-1-4c0-.3-.4-.4-.6-.3Zm5.5-.1-2.2-.6c-.3 0-.5.1-.5.4l.1 4.2c0 .5.6.6.8.2l2-3.6c.2-.2 0-.5-.2-.6Zm3.8 5.1-.6-2.1c0-.3-.4-.4-.6-.2l-3.6 2.2c-.3.3-.1.8.3.8h4.1c.3 0 .5-.4.4-.6Z"
              />
              <rect
                vectorEffect="non-scaling-stroke"
                width="2.3"
                height="15.1"
                x="-.6"
                y=".4"
                fill="#FFED67"
                stroke="#242C39"
                rx="1.2"
                transform="matrix(-.99103 -.22605 -.23795 .95408 407.9 83.8)"
              />
              <rect
                vectorEffect="non-scaling-stroke"
                width="2.3"
                height="15.1"
                x="-.6"
                y=".4"
                fill="#FFED67"
                stroke="#242C39"
                rx="1.2"
                transform="matrix(-.99103 -.22605 -.23795 .95408 404.6 84.8)"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#FFED67"
                d="m400.5 95.8-1 4-.5 1.8 10 1.4v4.7l1.7-6.8 2-5-1.2.1-2 .3-7.3-2-1 1.6h-.7Z"
              />
              <path
                vectorEffect="non-scaling-stroke"
                stroke="#242C39"
                d="M407.5 100.3c0-.6.2-2.1 1.9-3 2.1-1.3 2.5-.7 3.2-2"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#FFED67"
                stroke="#242C39"
                d="m419.5 112.6-3.2-1.7 4.1-10.9a4.6 4.6 0 0 1 6-2.5l2 .7c2.4 1 3.7 3.6 2.9 6l-3.1 9.1a2 2 0 0 1-2.3 1.3l-1.8-.4a17 17 0 0 1-4.6-1.6Z"
              />
              <rect
                vectorEffect="non-scaling-stroke"
                width="2.3"
                height="15.1"
                x=".3"
                y=".6"
                fill="#FFED67"
                stroke="#242C39"
                rx="1.2"
                transform="matrix(.95413 .35054 -.32043 .92963 424.8 87.7)"
              />
              <rect
                vectorEffect="non-scaling-stroke"
                width="2.3"
                height="9.8"
                x=".5"
                y=".5"
                fill="#FFED67"
                stroke="#242C39"
                rx="1.2"
                transform="matrix(1.00827 -.0541 .088 .9865 417.4 95)"
              />
              <rect
                vectorEffect="non-scaling-stroke"
                width="2.3"
                height="15.1"
                x=".3"
                y=".6"
                fill="#FFED67"
                stroke="#242C39"
                rx="1.2"
                transform="matrix(.95413 .35054 -.32043 .92963 427.7 88)"
              />
              <rect
                vectorEffect="non-scaling-stroke"
                width="2.3"
                height="15.1"
                x=".3"
                y=".6"
                fill="#FFED67"
                stroke="#242C39"
                rx="1.2"
                transform="matrix(.95413 .35054 -.32043 .92963 430.1 90.4)"
              />
              <rect
                vectorEffect="non-scaling-stroke"
                width="2.3"
                height="15.1"
                x=".3"
                y=".6"
                fill="#FFED67"
                stroke="#242C39"
                rx="1.2"
                transform="matrix(.95413 .35054 -.32043 .92963 432.3 93)"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#FFED67"
                d="m431.1 103.4-1.2 3.7-1.9 5-8.6-5.8-1.8 2.7 1.8-5 .6-6.1 1.1 1.1 1.5 1.4 7.3 2.2-.1 2 1.3-1.2Z"
              />
              <path
                vectorEffect="non-scaling-stroke"
                stroke="#242C39"
                d="M422.2 105c.4-.5 1-1.8.1-3.6-1.1-2.2-1.7-2.6-1.7-3.5"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#96C7F2"
                fillRule="evenodd"
                stroke="#000"
                strokeLinecap="round"
                d="M378.8 36.4a7.5 7.5 0 0 0-2.4 4.4 2.6 2.6 0 1 1-5.2-.6 13 13 0 0 1 4.2-7.8c2.7-2.3 6.5-3.3 11.1-1.5 4.7 1.9 6.5 6.6 5.9 11-.6 4-3 7.9-7.5 10.1v6.1a2.6 2.6 0 0 1-5.2 0v-9.7l1.7-.6c3.6-1.3 5.4-4 5.8-6.6.4-2.7-.8-4.7-2.6-5.5-2.7-1-4.5-.4-5.8.7Z"
                clipRule="evenodd"
              />
              <circle
                vectorEffect="non-scaling-stroke"
                cx="382.3"
                cy="64.6"
                r="2.1"
                fill="#96C7F2"
                stroke="#000"
              />
            </g>
          </svg>

          <svg
            className={styles.svg}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 469 585"
          >
            <g>
              <path
                vectorEffect="non-scaling-stroke"
                fill="#FEF2A4"
                stroke="#000"
                strokeWidth=".7"
                d="m386.6 190.9 32.7 25.6-73.5 93.6-32.6-25.6z"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#FEF2A4"
                stroke="#000"
                strokeWidth=".7"
                d="m396.1 198.3 13.3 10.4-73.4 93.7-13.3-10.5z"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#F3C6E2"
                stroke="#000"
                strokeWidth=".7"
                d="M399.9 174a20.8 20.8 0 1 1 32.7 25.6l-9.4 12-32.7-25.6 9.4-12Z"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#fff"
                stroke="#242C39"
                strokeWidth=".7"
                d="m313.2 284.5-14.3 52 47-26.3-32.7-25.7Z"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#242C39"
                d="m299 336.3 2.9-11.8 7.9 6.2-10.8 5.6Z"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#DFE3E6"
                stroke="#000"
                strokeWidth=".7"
                d="m391 185.3 32.7 25.6-4.3 5.5-32.6-25.7z"
              />
            </g>
          </svg>

          <svg
            className={styles.svg}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 469 585"
          >
            <g>
              <path
                vectorEffect="non-scaling-stroke"
                fill="#FFE1CC"
                stroke="#000"
                strokeWidth="1.1"
                d="M401.6 530.7c0 6.7-5.4 13.3-15.5 19.4a165.3 165.3 0 0 1-43 16.2c-36.3 9.3-86.5 15-142 15s-105.7-5.7-142-15a165.4 165.4 0 0 1-43-16.2C5.9 544.1.5 537.4.5 530.7c0-6.8 5.4-13.4 15.6-19.5 10-6.1 24.8-11.6 43-16.2 36.3-9.3 86.5-15 142-15s105.7 5.7 142 15a165.4 165.4 0 0 1 43 16.2c10.1 6 15.5 12.7 15.5 19.5Z"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#242C39"
                d="M234.2 246.5c0 37.3 14.5 56.9-39 43.3-65.4 10.2-31.5-10.2-34-43.3-3.4-26.4 18.3-42.5 40-42.5 21.5 0 33 18.5 33 42.5Z"
              />
              <circle
                vectorEffect="non-scaling-stroke"
                cx="197.4"
                cy="237.4"
                r="32.3"
                fill="#242C39"
              />
              <rect
                vectorEffect="non-scaling-stroke"
                width="27.6"
                height="46.5"
                x="182"
                y="257.8"
                fill="#A35840"
                stroke="#242C39"
                strokeWidth="1.6"
                rx="13.8"
                transform="rotate(3.6 182 257.8)"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#C57055"
                stroke="#242C39"
                strokeWidth="1.6"
                d="M228.4 256.5a30.3 30.3 0 0 1-38.4 19c-8-2.7-13.4-6.8-16.5-13-3-6.1-3.9-14.4-2.5-25.4 3.5-12.7 9.1-18.7 15.7-21 6.7-2.2 14.6-.7 22.7 2a30.3 30.3 0 0 1 19 38.4Z"
              />
              <circle
                vectorEffect="non-scaling-stroke"
                r="8.6"
                fill="#C57055"
                transform="scale(-1 1) rotate(-18.6 659 651.6)"
              />
              <path
                vectorEffect="non-scaling-stroke"
                stroke="#242C39"
                strokeLinecap="round"
                strokeWidth="1.6"
                d="M173.6 252.5a8 8 0 0 1-8.5-6.2c-2-7 5.4-14.8 14-6.6"
              />
              <path
                vectorEffect="non-scaling-stroke"
                stroke="#242C39"
                strokeLinecap="round"
                strokeWidth="1.6"
                d="M173.9 240.2c-1.1-.6-3.6-.9-4.6 2.2m4.6 2.1c.4-.7.6-2.2-1.4-3"
              />

              <path
                vectorEffect="non-scaling-stroke"
                fill="#242C39"
                d="M199.2 224.7a26 26 0 0 0-19.6 15 13 13 0 0 0-9-3.7l-.5-1.3 4.5-11.9c3-2 10-6.9 15.5-10.9 6.8-5 10.5 2.3 21.8 5 9.1 2.2 30.8 13.4 13 48.2 11-37.3-12.6-43.4-25.7-40.4Z"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#FEF2A4"
                stroke="#000"
                strokeLinecap="round"
                strokeWidth="1.1"
                d="M134.6 311.1c-15 13.3-33.8 78.7-9 119.3 11.1 18.3-60.7 41.2 64.4 49a134 134 0 0 0 93.4-28.5c-3.7-19 17.4-81.7-13.7-133.4-24-40-106.1-32.2-135.1-6.4Z"
              />
              <path
                vectorEffect="non-scaling-stroke"
                stroke="#000"
                strokeLinecap="round"
                strokeWidth="1.1"
                d="M125.6 430.4a78 78 0 0 0 23.2 12.7m97.7-101.9c1 6.3 3 20.1 3 25"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#2E5FE8"
                stroke="#000"
                strokeWidth="1.1"
                d="M308.2 494.8H91.8v-48.5l77.6 11.1c19.3 2.7 38.8 2.8 58 .3l85-11.4-4.2 48.5Z"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#DFE3E6"
                stroke="#000"
                strokeWidth="1.1"
                d="m306.2 551.3-31.6-11-81.2-19a14.5 14.5 0 1 1 7.9-28l60.7 20.4c5.3 1.8 11 1.4 16-1 6-2.9 13-.8 16.5 4.8l7.1 11.6c2.7 4.4 6.3 8.1 10.7 10.8l22.2 14c3.8 2.4 1 8.2-3.2 6.7l-25-9.3Z"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#2E5FE8"
                stroke="#000"
                strokeWidth="1.1"
                d="M62.7 458.3a26 26 0 0 1 33.2-15.9l147.6 52.2-17.4 49.1-147.6-52.2a26 26 0 0 1-15.8-33.2Z"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#DFE3E6"
                stroke="#000"
                strokeWidth="1.1"
                d="m97 551.3 31.6-11 81.3-19a14.4 14.4 0 1 0-8-27.7l-63 22.3c-4.8 1.7-10.2 2-15.3 1-7-1.4-14.1 1.5-18.1 7.3l-4.4 6.3a33.3 33.3 0 0 1-9 8.8l-19.4 12.9c-3.6 2.4-.8 8 3.3 6.6l21-7.5Z"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#2E5FE8"
                stroke="#000"
                strokeWidth="1.1"
                d="M340.5 458.3a26 26 0 0 0-33.3-15.9l-147.5 52.2 17.4 49.1 147.5-52.2a26 26 0 0 0 15.9-33.2Z"
              />

              <path
                vectorEffect="non-scaling-stroke"
                fill="#FEF2A4"
                stroke="#000"
                strokeWidth="1.1"
                d="M175.2 274.8c0-2.5 2.2-4.5 4.7-4.2a155 155 0 0 0 39.1.1h.9a4.3 4.3 0 0 1 4.9 4.3v15.2c0 2.3-1.7 4.3-4 4.6l-1 .1a164.6 164.6 0 0 1-40.7-.1 4.4 4.4 0 0 1-4-4.4v-15.6Z"
              />
              <path
                vectorEffect="non-scaling-stroke"
                stroke="#000"
                strokeLinecap="round"
                strokeWidth="1.1"
                d="M180.5 273.1v19m10-17.4v19m20-19v19m8.5-20v19m-19-18v19m-50.1 47.5v53.3c0 7.6 12.6 18.7 19 23.2"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#D0E8FF"
                stroke="#000"
                strokeWidth="1.1"
                d="M189.4 367.6c.9-2.2 3-3.7 5.4-3.7h121.5c4.1 0 7 4.1 5.4 8L295 440.3c-.9 2.2-3 3.7-5.4 3.7H168.1c-4 0-6.9-4-5.4-7.9l26.7-68.6Z"
              />
              <path
                vectorEffect="non-scaling-stroke"
                fill="#D0E8FF"
                stroke="#000"
                strokeWidth="1.1"
                d="M159.4 443h134a6.9 6.9 0 0 1-6.9 6.4H159.4V443Zm-42.8 6.4a6.9 6.9 0 0 1-6.8-6.3h49.6v6.3h-42.8Z"
              />
            </g>
          </svg>
        </div>
      </Transition>
    );
  },
);
