import { getModelFromActivity } from '@floatschedule/activity-format-npm';

import { mapRawTaskMetaToTaskMeta } from '@float/common/actions/taskMetas';
import { Milestone } from '@float/types/milestone';
import { Phase, RawPhase } from '@float/types/phase';
import { Project, RawProject } from '@float/types/project';
import { RawTaskMeta } from '@float/types/task';

type PhaseResult = RawPhase & {
  tasks?: RawTaskMeta[];
  milestones?: Milestone[];
};

export type ProjectCreatedResult = {
  processId: string;
  success: boolean;
  errors?: { message: string }[];
  data: RawProject & {
    phases?: PhaseResult[];
    tasks?: RawTaskMeta[];
    milestones?: Milestone[];
  };
};

function parseProjectFromProjectCreatedResult(data: RawProject): Project {
  return getModelFromActivity({ item_type: 'project', after_data: data });
}

function parsePhasesFromProjectCreatedResult(
  rawPhases?: PhaseResult[],
): Phase[] {
  if (!rawPhases) return [];

  return rawPhases.map((rawPhase) => {
    const { tasks, milestones, ...phase } = rawPhase;
    return getModelFromActivity({ item_type: 'phase', after_data: phase });
  });
}

function parseAllMilestonesFromProjectCreatedResult(
  projectMilestones?: Milestone[],
  phases?: PhaseResult[],
) {
  const milestones = projectMilestones ? projectMilestones.slice() : [];
  phases?.forEach((phase) => {
    if (!phase.milestones?.length) return;

    milestones.push(...phase.milestones);
  });

  return milestones.map((milestone) =>
    getModelFromActivity({ item_type: 'milestones', after_data: milestone }),
  );
}

function parseAllTasksFromProjectCreatedResult(
  projectTasks?: RawTaskMeta[],
  phases?: PhaseResult[],
) {
  const tasks = projectTasks ? projectTasks.slice() : [];
  phases?.forEach((phase) => {
    if (!phase.tasks?.length) return;

    tasks.push(...phase.tasks);
  });

  return tasks.map(mapRawTaskMetaToTaskMeta);
}

export function parseAsyncProjectCreatedResult(
  data: ProjectCreatedResult['data'],
) {
  const {
    phases: rawPhases,
    tasks: rawTasks,
    milestones: rawMilestones,
    ...rawProject
  } = data;

  const project = parseProjectFromProjectCreatedResult(rawProject);
  const phases = parsePhasesFromProjectCreatedResult(rawPhases);
  const milestones = parseAllMilestonesFromProjectCreatedResult(
    rawMilestones,
    rawPhases,
  );
  const tasks = parseAllTasksFromProjectCreatedResult(rawTasks, rawPhases);

  return { project, phases, milestones, tasks };
}
