import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Trans } from '@lingui/macro';
import cs from 'classnames';

import { BudgetPriority } from '@float/types/project';

import { SidePanelTableHeaderRow } from '../../components/SidePanelTableSection/SidePanelTableHeaderRow';
import { SidePanelTableHeading } from '../../components/SidePanelTableSection/SidePanelTableHeading';
import { ProjectFormData } from '../../types';
import { DeleteTasks } from './DeleteTasks';
import { useSelectedTasks } from './index.hooks';
import { MergeTasks } from './MergeTasks';
import { TaskBulkCheckbox } from './TaskBulkCheckbox';

import * as styles from './styles.css';

export const ProjectTasksTableHeader = () => {
  const { getValues } = useFormContext<ProjectFormData>();

  const budgetPriority = getValues('project.budget_priority');

  const tasks = useWatch<ProjectFormData, 'tasks'>({
    name: 'tasks',
  });
  const selected = useSelectedTasks(tasks);

  const hasBudgetFields = budgetPriority === BudgetPriority.Task;
  const hasSelectedTasks = selected.indices.length > 0;

  return (
    <SidePanelTableHeaderRow
      className={cs(
        hasSelectedTasks
          ? styles.tasksGridTemplateColumnsHeaderWithSelectedTasks
          : styles.tasksGridTemplateColumnsHeader,
      )}
    >
      <SidePanelTableHeading
        active={hasSelectedTasks}
        className={styles.bulkCheckbox}
      >
        <TaskBulkCheckbox />
      </SidePanelTableHeading>
      <SidePanelTableHeading>
        <Trans>Task</Trans>
      </SidePanelTableHeading>
      <SidePanelTableHeading active={hasBudgetFields && !hasSelectedTasks}>
        <Trans>Budget</Trans>
      </SidePanelTableHeading>
      <SidePanelTableHeading hide={!hasSelectedTasks}>
        <div className={styles.projectTaskTableMenu}>
          <MergeTasks />
          <DeleteTasks />
        </div>
      </SidePanelTableHeading>
    </SidePanelTableHeaderRow>
  );
};
