import React from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import createClass from 'create-react-class';

import DateRangePickerBemMixin from './utils/DateRangePickerBemMixin';

const PaginationArrow = createClass({
  mixins: [DateRangePickerBemMixin, PureRenderMixin],
  displayName: 'PaginationArrow',

  getDefaultProps() {
    return {
      disabled: false,
    };
  },

  render() {
    const { disabled, direction, onTrigger, ...props } = this.props;
    const modifiers = { [direction]: true };
    const states = { disabled };

    const elementOpts = {
      modifiers,
      states,
    };

    const iconOpts = {
      element: 'PaginationArrowIcon',
      modifiers,
      states,
    };

    return (
      <div className={this.cx(elementOpts)} {...props} onClick={onTrigger}>
        <div className={this.cx(iconOpts)} />
      </div>
    );
  },
});

export default PaginationArrow;
