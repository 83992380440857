import React from 'react';
import { Trans } from '@lingui/macro';

import { Button } from '@float/ui/deprecated';
import { NoResultsIllustration } from '@float/ui/deprecated/Illustrations/NoResultsIllustration';

import { AppErrorPage } from '../AppErrorPage';

type AppErrorDataProps = {
  onClickTryAgain: () => void;
  isRetrying: boolean | undefined;
};

const AppErrorData = (props: AppErrorDataProps) => (
  <AppErrorPage>
    <NoResultsIllustration />
    <h2>
      <Trans>We're having trouble loading your data.</Trans>
    </h2>
    <p>
      <Trans>
        Check your network connection, if the error persists, please contact our
        support team.
      </Trans>
    </p>
    <Button onClick={props.onClickTryAgain} loader={props.isRetrying}>
      <Trans>Try again</Trans>
    </Button>
  </AppErrorPage>
);

export { AppErrorData };
