import { useCallback } from 'react';

import { selectPersonById } from '@float/common/selectors/people/people';
import { selectEntityTotalWorkHoursGetter } from '@float/common/selectors/schedule/getEntityTotalWorkHours';
import { selectEntityWorkDaysDurationGetter } from '@float/common/selectors/schedule/getEntityWorkDaysDuration';
import { selectMinWorkHoursInRangeGetter } from '@float/common/selectors/schedule/getMinWorkHoursInRange';
import { selectIsWorkDayGetter } from '@float/common/selectors/schedule/isWorkDay';
import { useAppSelectorStrict, useAppStoreStrict } from '@float/common/store';
import { Task } from '@float/types/task';
import { Timeoff } from '@float/types/timeoff';
import { selectDatesManager } from '@float/web/selectors';

export function useEditTaskModalController() {
  const store = useAppStoreStrict();

  const getMinWorkHoursInRangeApi = useCallback(
    (params: {
      start_date: string;
      end_date: string;
      people_ids: number[];
    }) => {
      const getMinWorkHoursInRange = selectMinWorkHoursInRangeGetter(
        store.getState(),
      );

      return getMinWorkHoursInRange(
        params.people_ids,
        params.start_date,
        params.end_date,
      );
    },
    [store],
  );

  const isWorkDayApi = useCallback(
    (peopleIds: number[], date: string, ignoreTimeoff?: number) => {
      const isWorkDay = selectIsWorkDayGetter(store.getState());

      return peopleIds.some((id) => {
        const person = selectPersonById(store.getState(), id);

        if (!person) {
          return false;
        }

        return isWorkDay(person, date, ignoreTimeoff);
      });
    },
    [store],
  );

  const dates = useAppSelectorStrict(selectDatesManager);

  const calcEntityLength = useCallback(
    (entity: Task | Timeoff) => {
      const getEntityWorkDaysDuration = selectEntityWorkDaysDurationGetter(
        store.getState(),
      );

      let maxLength: number | undefined = undefined;

      for (const peopleId of entity.people_ids) {
        const person = selectPersonById(store.getState(), peopleId);

        if (person) {
          const length = getEntityWorkDaysDuration(entity, person);
          maxLength = Math.max(length, maxLength ?? 0);
        }
      }

      // if allocating a one-off on a non working day we need to default to full day length
      // https://linear.app/float-com/issue/CS-1989
      if (entity.people_ids.length && !maxLength) return 1;

      return maxLength;
    },
    [store],
  );

  const calcEntityTotalHours = useAppSelectorStrict(
    selectEntityTotalWorkHoursGetter,
  );

  return {
    getMinWorkHoursInRange: getMinWorkHoursInRangeApi,
    isWorkDay: isWorkDayApi,
    calcEntityLength,
    calcEntityTotalHours,
    dates,
  };
}
