import useUnmount from 'react-use/esm/useUnmount';

import { useWebAppDispatchStrict } from '@float/web/lib/store';
import { closeNotificationFeed } from '@float/web/store/notifications/notifications.actions';

export const useMarkAsSeenOnUnmount = () => {
  const dispatch = useWebAppDispatchStrict();

  const markAsSeen = () => {
    dispatch(closeNotificationFeed());
  };

  useUnmount(() => {
    markAsSeen();
  });
};
