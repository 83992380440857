import React from 'react';
import { t } from '@lingui/macro';

import { TIME_FORMAT } from '@float/common/lib/time';
import { CellItem } from '@float/types';
import { IconActiveTimer } from '@float/ui/deprecated/Earhart/Icons/Icon/IconActiveTimer';
import IconLogged from '@float/ui/deprecated/Earhart/Icons/Icon/IconLogged';
import IconSerenaNotes from '@float/ui/deprecated/Icons/icon-serena-notes';

import { formatHours } from '../utils/formatHours';

import { hourElement, singleRowIcons } from '../../EntityItemStyles.css';

interface SingleRowLoggedTimeProps {
  item: CellItem<'task'> | CellItem<'timeoff'> | CellItem<'loggedTime'>;
  color: string | undefined;
  suvSingleDay: string | null | undefined;
  displayFormat: keyof typeof TIME_FORMAT;
}

export const SingleRowLoggedTime: React.FC<SingleRowLoggedTimeProps> = ({
  item,
  color,
  suvSingleDay,
  displayFormat,
}) => {
  const { entity, hasActiveTimer, isTaskReference } = item;

  const hasNotes = 'notes' in entity && entity.notes;

  if (hasActiveTimer) {
    return (
      <div className={hourElement}>
        <IconActiveTimer color={color} size={15} data-testid="icon-clock" />
        {t`Timer running...`}
      </div>
    );
  }

  if (isTaskReference) {
    return null;
  }

  return (
    <>
      <div className={hourElement}>
        <IconLogged color={color} size={15} data-testid="icon-clock" />
        {formatHours(entity, false, displayFormat)}
      </div>

      <div className={singleRowIcons}>
        {hasNotes && (
          <IconSerenaNotes
            color={color}
            size={suvSingleDay ? 15 : 12}
            data-testid="icon-notes"
          />
        )}
      </div>
    </>
  );
};
