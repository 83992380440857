import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon {...props}>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M17.87 4.932a3.21 3.21 0 0 0-4.521 0l-7.067 7.065a4.409 4.409 0 0 0 0 6.217 4.408 4.408 0 0 0 6.218 0l6.5-6.5-1.13-1.13-6.5 6.5a2.785 2.785 0 0 1-3.957 0 2.786 2.786 0 0 1 0-3.957l7.065-7.065a1.586 1.586 0 0 1 2.26 0c.632.631.632 1.63 0 2.261l-5.932 5.934a.388.388 0 0 1-.565 0 .388.388 0 0 1 0-.565l5.37-5.369-1.131-1.13-5.37 5.369a2.01 2.01 0 0 0 0 2.826 2.01 2.01 0 0 0 2.827 0l5.934-5.935a3.21 3.21 0 0 0 0-4.521Z"
      clipRule="evenodd"
    />
  </BaseIcon>
);
