import React, { useCallback, useState } from 'react';

import {
  PRO_MONTHLY,
  PRO_YEARLY,
  STARTER_MONTHLY,
  STARTER_YEARLY,
} from '@float/common/lib/pricing';
import { useSnackbar } from '@float/ui/deprecated';
import { IconWarningTriangle } from '@float/ui/deprecated/Earhart/Icons';

import { UpdateBillingCycleModal } from '../PlanDetails/UpdateBillingCycleModal';
import { UpdateSeatsModal } from '../PlanDetails/UpdateSeatsModal';
import { getUpgradePath } from './BillingDetails.helpers';
import { PaidBillingDetails } from './PaidBillingDetails';
import { StyledBillingDetailsHeader, StyledCard, StyledRow } from './styles';
import { TrialBillingDetails } from './TrialBillingDetails';

type BillingDetailsProps = {
  canEditBillingCycle: boolean;
  isTrial: boolean;
  isPlanBilledMonthly: boolean;
  maskedCardNumber: string;
  nextChargeDate: string;
  isEnterprisePlan: boolean;
  isProPlan: boolean;
  isStarterPlan: boolean;
  totalPayment: string;
  totalSeats: number;
  usedSeats: number;
  placeholderSeats: number;
  placeholderUsedSeats: number;
  trialDays: number;
  trialDaysRemaining: number;
  updateBillingPlan: (formData: FormData) => Promise<void>;
  updateCompanyPrefs: (prefs: { [key: string]: unknown }) => Promise<void>;
};

export const BillingDetails = ({
  isTrial,
  isPlanBilledMonthly,
  maskedCardNumber,
  nextChargeDate,
  isEnterprisePlan,
  isProPlan,
  isStarterPlan,
  totalPayment,
  totalSeats,
  usedSeats,
  placeholderSeats,
  placeholderUsedSeats,
  trialDays,
  trialDaysRemaining,
  updateBillingPlan,
  updateCompanyPrefs,
}: BillingDetailsProps) => {
  const [modalOpened, setModalOpened] = useState<null | string>(null);
  const upgradePath = getUpgradePath(isEnterprisePlan, isProPlan);
  const { showSnackbar } = useSnackbar();

  const showError = useCallback(
    (message: string) =>
      showSnackbar(message, {
        className: 'error',
        iconLeft: IconWarningTriangle,
        showClose: true,
      }),
    [showSnackbar],
  );

  const onCloseModals = useCallback(() => {
    setModalOpened(null);
  }, []);

  const onClickEditBillingCycle = useCallback(() => {
    setModalOpened('update-billing');
  }, []);

  const onClickEditSeats = useCallback(() => {
    setModalOpened('update-seats');
  }, []);

  const handleUpdateSeats = useCallback(
    async (totalSeats?: number) => {
      const formData = new FormData();

      formData.append('Company[people_limit]', `${totalSeats}`);

      try {
        await updateBillingPlan(formData);
        await updateCompanyPrefs({ p_limit: totalSeats });

        setModalOpened(null);
      } catch {
        showError('Failed to update seat count');
        setModalOpened(null);
      }
    },
    [updateBillingPlan, updateCompanyPrefs, showError],
  );

  const handleUpdateBillingCycle = useCallback(
    async (isMonthly?: boolean) => {
      const formData = new FormData();

      if (isProPlan) {
        if (isMonthly) {
          formData.append('Company[plan_id]', PRO_MONTHLY.plan_id);
        } else {
          formData.append('Company[plan_id]', PRO_YEARLY.plan_id);
        }
      } else if (isStarterPlan) {
        if (isMonthly) {
          formData.append('Company[plan_id]', STARTER_MONTHLY.plan_id);
        } else {
          formData.append('Company[plan_id]', STARTER_YEARLY.plan_id);
        }
      }

      try {
        await updateBillingPlan(formData);
        setModalOpened(null);
      } catch {
        showError('Failed to update billing plan');
        setModalOpened(null);
      }
    },
    [updateBillingPlan, isStarterPlan, isProPlan, showError],
  );

  return (
    <>
      {modalOpened === 'update-billing' && (
        <UpdateBillingCycleModal
          onClose={onCloseModals}
          onSubmit={handleUpdateBillingCycle}
          totalSeats={totalSeats}
          initialIsMonthly={isPlanBilledMonthly}
          isEnterprisePlan={isEnterprisePlan}
          isProPlan={isProPlan}
        />
      )}
      {modalOpened === 'update-seats' && (
        <UpdateSeatsModal
          onClose={onCloseModals}
          initialSeats={totalSeats}
          usedSeats={usedSeats}
          onSubmit={handleUpdateSeats}
          isMonthly={isPlanBilledMonthly}
          isEnterprisePlan={isEnterprisePlan}
          isProPlan={isProPlan}
        />
      )}
      <StyledCard>
        <StyledBillingDetailsHeader>Billing details</StyledBillingDetailsHeader>
        <StyledRow>
          {isTrial ? (
            <TrialBillingDetails
              trialDays={trialDays}
              trialDaysRemaining={trialDaysRemaining}
              usedSeats={usedSeats}
              placeholderSeats={placeholderSeats}
              placeholderUsedSeats={placeholderUsedSeats}
              isProPlan={isProPlan}
            />
          ) : (
            <PaidBillingDetails
              canEditBillingCycle={!isEnterprisePlan}
              isPlanBilledMonthly={isPlanBilledMonthly}
              maskedCardNumber={maskedCardNumber}
              nextChargeDate={nextChargeDate}
              onClickEditBillingCycle={onClickEditBillingCycle}
              onClickEditSeats={onClickEditSeats}
              totalPayment={totalPayment}
              totalSeats={totalSeats}
              usedSeats={usedSeats}
              placeholderSeats={placeholderSeats}
              placeholderUsedSeats={placeholderUsedSeats}
              upgradePath={upgradePath}
            />
          )}
        </StyledRow>
      </StyledCard>
    </>
  );
};
