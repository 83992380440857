import { t } from '@lingui/macro';

import { isOr, TYPE_TO_CATEGORY } from '@float/common/search/helpers';
import { FilterCategory } from '@float/common/search/types';
import { FilterToken } from '@float/types';
import { SVGIconComponent } from '@float/ui/primitives/SVGIcon';

import { getCategoryConfig } from '../../SearchFilterDropdown/helpers/getCategoryConfig';

import { PaletteColor } from '../styles.css';

export function getTokenMeta(filter: FilterToken): {
  icon?: SVGIconComponent;
  category: FilterCategory;
  bg: PaletteColor;
  logicalConjunction: string;
} {
  const category: FilterCategory =
    filter.type === 'me' ? 'me' : TYPE_TO_CATEGORY[filter.type];

  const config = getCategoryConfig(category);
  const icon = config.icon;
  const bg: PaletteColor = 'ActionFlay';

  const logicalConjunction = isOr(filter.operator) ? t`Or` : t`And`;

  return {
    icon,
    category,
    bg,
    logicalConjunction,
  };
}
