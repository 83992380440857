import { getModelFromActivity } from '@floatschedule/activity-format-npm';

import { sanitizeFetchedPerson } from '@float/common/actions/people/helpers/sanitizeFetchedPerson';
import { generateId } from '@float/common/lib/utils';

// Workaround for https://app.rollbar.com/a/float/fix/item/fe-web-app/2718
// TODO https://linear.app/float-com/issue/FT-910/bug-malformed-management-group-form-the-live-updates-when-people-is
function patchManagementGroup(model) {
  if (
    typeof model.management_group?.people === 'string' &&
    typeof model.management_group?.departments === 'string'
  ) {
    model.management_group = {
      people: JSON.parse(model.management_group.people),
      departments: JSON.parse(model.management_group.departments),
    };
  }

  return model;
}

const actionByModel = {
  people: ({ actionType, model, fullState }) => {
    const person =
      actionType === 'PEOPLE/DELETED'
        ? { people_id: model.people_id }
        : sanitizeFetchedPerson(model, fullState);
    return { person };
  },
  project: ({ model, fullState }) => {
    const { project_id: projectId } = model;
    return {
      project: model,
      prevProject: fullState.projects.projects[projectId],
      projectId,
    };
  },
  phase: ({ model, fullState }) => {
    const { phase_id: phaseId } = model;
    return {
      phases: [model],
      prevPhases: [fullState.phases.phases[phaseId]],
      phaseIds: [phaseId],
    };
  },
  timeoff_type: ({ model }) => {
    return { payload: model, id: model.timeoff_type_id };
  },
  timeoff: ({ model }) => ({ item: model }),
  task: ({ model }) => ({ item: { ...model, createdTs: generateId() } }),
  status: ({ model, afItem }) => ({ response: model, id: afItem.item_id }),
  status_type: ({ model }) => ({ statusType: model }),
  logged_time: ({ model: loggedTime }) => {
    let id;
    let task_id;
    if (loggedTime.length === 1) {
      id = loggedTime[0].logged_time_id;
      task_id = loggedTime[0].task_id;
    }
    return { loggedTime, id, task_id };
  },
  department: ({ model }) => ({ payload: model, id: model.department_id }),
  holidays: ({ model }) => ({ payload: model, id: model.holiday_id }),
  oneoff_days: ({ model }) => ({ oneOff: model }),
  milestones: ({ model }) => ({ milestone: model, milestoneId: model.id }),
  account_co: ({ model }) => ({ account: patchManagementGroup(model) }),
  project_template: ({ model }) => ({
    template: model,
    templateId: model.project_template_id,
  }),
};

export default function getAction(params) {
  if (!params.afItem) {
    return;
  }

  const fn = actionByModel[params.afItem.item_type];
  if (!fn) {
    return;
  }

  if (
    params.afItem.activity_type === 'delete' &&
    params.afItem.item_type === 'task' &&
    params.afItem.before_data
  ) {
    params.afItem.after_data = null;
  }

  params.model = getModelFromActivity(params.afItem);
  const action = fn(params);
  action.type = params.actionType;
  action.isLiveUpdate = true;
  return action;
}
