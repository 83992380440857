import { DataMap, RowId, Task, TaskReference } from '@float/types';

import { RowMetas } from '../../useRowMetas.helpers';
import { BiMaps, CellFns, DateUtils } from '../types';

export const getTaskReferenceId = (
  task: Task,
  personId: number | undefined,
  date: string,
) => {
  const fallbackPersonId = `${Math.random()}-${Date.now()}`;

  // Using the . as separator as some helpers rely on it to identify task references
  return `ref-${task.task_id}.${personId ?? fallbackPersonId}.${date}`;
};

export function isTaskReferenceId(id: string) {
  return id.startsWith('ref-');
}

function getTaskReference(
  task: Task,
  x: number,
  taskReferenceWeakMap: DataMap['taskReference'],
  loggedTimeMap: DataMap['loggedTime'],
  loggedTimeBimap: BiMaps['loggedTime'],
  rowId: RowId,
  colIdx: number,
  dates: DateUtils,
  rowMetas: RowMetas,
  isInLockPeriod: boolean,
  getApplicableCellKeys: CellFns['getApplicableCellKeys'],
) {
  // TaskReferences are ephemeral - they're generated from the existing
  // tasks when the user clicks into log time view. However, users can
  // interact with TaskReferences (drag, resize), which means we have to hold
  // some type of state for them.
  //
  // We do this here, with a WeakMap. This means that we'll automatically
  // regenerate TaskReferences when the underlying Task changes, and we'll
  // also free up any previously generated TaskReferences for GC once the
  // task is GCed.
  let refs = taskReferenceWeakMap.get(task)!;

  if (!refs) {
    refs = Object.create(null);
    taskReferenceWeakMap.set(task, refs);
  }

  const key = `${rowId}:${colIdx}:${x}`;

  const taskRef = refs[key];

  if (!taskRef) {
    const date = dates.fromDescriptor(colIdx, x);
    const personId = rowMetas.get(rowId)?.personId;

    const id = getTaskReferenceId(task, personId, date);

    const taskRef: TaskReference = {
      isTaskReference: true,
      logged_time_id: id,
      people_id: personId,
      project_id: task.project_id,
      phase_id: task.phase_id,
      billable: task.billable,
      task_name: task.name,
      task_id: task.task_id,
      task_meta_id: task.task_meta_id,
      hours: task.hours,
      priority_info: task.priority_info,
      date,
      start_date: date,
      end_date: date,
      reference_date: date,
      start_time: task.start_time,
      isInLockPeriod,
      notes: task.notes,
    };

    refs[key] = taskRef;
    taskRef.allInstances = [taskRef];

    loggedTimeMap[id] = taskRef;
    loggedTimeBimap.replace(id, getApplicableCellKeys(taskRef));

    return taskRef;
  } else if (!loggedTimeMap[taskRef.logged_time_id]) {
    // This fixes the loggedTimeMap state after the bulk log undo
    // see https://app.asana.com/0/1202477614994746/1202979692749653/f
    const id = taskRef.logged_time_id;

    loggedTimeMap[id] = taskRef;
    loggedTimeBimap.replace(id, getApplicableCellKeys(taskRef));
  }

  return taskRef;
}

export { getTaskReference };
