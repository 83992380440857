export function omitOne<T extends Record<any, any>>(
  input: T,
  keyToDelete: string,
) {
  const result = Object.create(null) as T;

  for (const key of Object.keys(input)) {
    if (keyToDelete !== key) {
      result[key as keyof T] = input[key];
    }
  }

  return result;
}

export function omit<T extends Record<any, any>>(
  input: T,
  keysToDelete: string[],
) {
  const result = Object.create(null) as T;

  for (const key of Object.keys(input)) {
    if (!keysToDelete.includes(key)) {
      result[key as keyof T] = input[key];
    }
  }

  return result;
}

export function omitBy<V extends any>(
  input: Record<any, V>,
  predicate: (value: V, key: string) => boolean,
) {
  const result = Object.create(null) as Record<any, V>;

  for (const key of Object.keys(input)) {
    const value = input[key];
    if (predicate(value, key) !== true) {
      result[key] = value;
    }
  }

  return result;
}
