import React, { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { SidePanelTableRows } from '../../components/SidePanelTableSection/SidePanelTableRows';
import { ProjectFormData } from '../../types';
import { TemplateMilestoneRow } from './TemplateMilestoneRow';
import { TemplateMilestonesTableHeader } from './TemplateMilestonesTableHeader';

export function TemplateMilestonesRows() {
  const { getValues } = useFormContext<ProjectFormData>();
  const [rowsWithVisibleDurations, setRowsWithVisibleDurations] = useState(
    () => {
      const result = new Set<number>();
      getValues('milestones').forEach((milestone, index) => {
        if (
          milestone.duration !== null &&
          milestone.duration !== undefined &&
          milestone.duration !== 1 // default value if editing a saved milestone
        ) {
          result.add(index);
        }
      });
      return result;
    },
  );

  const toggleDurationForRow = useCallback((index: number) => {
    setRowsWithVisibleDurations((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      return newSet;
    });
  }, []);

  return (
    <>
      <TemplateMilestonesTableHeader
        showingDuration={rowsWithVisibleDurations.size > 0}
      />
      <SidePanelTableRows
        name={'milestones'}
        watchUpdate={'name'}
        rowRenderer={(field, index) => (
          <TemplateMilestoneRow
            key={field.id}
            index={index}
            showingDuration={rowsWithVisibleDurations.has(index)}
            toggleDuration={toggleDurationForRow}
          />
        )}
      />
    </>
  );
}
