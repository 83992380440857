import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Transition } from 'TransitionManager/Transition';

import { StyledCheckSVG, StyledIllustration } from './styles';

import illustrationSrc from './assets/illustration.svg';

export const AddPeopleAside = forwardRef((props, ref) => {
  const { transitionId, peopleCount } = props;

  const transitionRef = useRef();
  const elRef = useRef();

  useImperativeHandle(ref, () => ({
    ...transitionRef.current,
  }));

  return (
    <Transition ref={transitionRef} id={transitionId}>
      <StyledIllustration ref={elRef}>
        <StyledCheckSVG selected={peopleCount > 0} />
        <StyledCheckSVG selected={peopleCount > 1} />
        <StyledCheckSVG selected={peopleCount > 2} />

        <img src={illustrationSrc} width="470" height="443" />
      </StyledIllustration>
    </Transition>
  );
});
