import {
  FETCH_CURRENCIES,
  FETCH_CURRENCIES_FAILURE,
  FETCH_CURRENCIES_SUCCESS,
} from '../actions/currencies';
import {
  DEFAULT_SORT_TABLE,
  getInitialSortableTableState,
  getInitialSortPropsState,
  getInitialStateCreator,
  reducerCreator,
} from './reducerHelpers';

export const lastFetchError = (state) => state.lastFetchError;

export const areBeingFetched = (state) => state.pagesBeingFetched.length > 0;

const getInitialState = getInitialStateCreator(
  Object.assign(
    {
      sortableTables: {
        [DEFAULT_SORT_TABLE]: getInitialSortableTableState(
          'currency_id',
          'asc',
        ),
      },
    },
    getInitialSortPropsState(['currency_id']),
  ),
);

const currencies = reducerCreator(
  {
    FETCH_ENTITIES: FETCH_CURRENCIES,
    FETCH_ENTITIES_SUCCESS: FETCH_CURRENCIES_SUCCESS,
    FETCH_ENTITIES_FAILURE: FETCH_CURRENCIES_FAILURE,
  },
  'currency_id',
  getInitialState,
);

export default currencies;
