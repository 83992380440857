const ARRAY_KEY = 'fieldLinks';

/**
 * @deprecated
 */
const linkState = (
  component,
  attr,
  viewStateValueMapper,
  isBoolean = false,
) => {
  const errorAttr = `${attr}Error`;
  const checks = [];
  const link = {
    at: attr,
    value: component.state[attr],
    error: '',
    onChange(e) {
      this.set(isBoolean ? e.target.checked : e.target.value, true, {
        onChange: true,
      });
    },
    set(x, isRaw = true, attrs = {}) {
      if (isRaw) {
        this.validate(x, attrs);
        x = viewStateValueMapper ? viewStateValueMapper(x) : x;
      }
      component.setState({ [attr]: x });
      this.value = x;
    },
    setError(error) {
      this.error = error;
      component.setState({ [errorAttr]: this.error });
    },
    validate(x = this.value, attrs = {}) {
      this.error = checks.reduce((message, check) => {
        if (attrs.onChange && check.options.triggerOnChange === false)
          return message;
        if (message) return message;
        if (!check.predicate(x)) {
          return typeof check.message === 'function'
            ? check.message()
            : check.message;
        }
        return message;
      }, '');
      component.setState({ [errorAttr]: this.error });
      return this.error;
    },
    check(predicate, message, options = {}) {
      checks.push({
        predicate,
        message,
        options,
      });
      return this;
    },
  };
  link.set = link.set.bind(link);
  link.onChange = link.onChange.bind(link);
  if (!component[ARRAY_KEY]) {
    component[ARRAY_KEY] = [];
  }
  component[ARRAY_KEY].push(link);
  return link;
};

export const validateLinks = (component) =>
  component[ARRAY_KEY].reduce(
    (isValid, link) => isValid && !link.validate(link.value),
    true,
  );

export const linkAt = (component, name) =>
  component[ARRAY_KEY].find((link) => link.at === name);

export const isValid = (component) =>
  component[ARRAY_KEY].reduce(
    (itemIsValid, link) => itemIsValid && !link.error,
    true,
  );

export default linkState;
