import { trackEvent } from '@float/common/lib/gtm';
import { SavedView } from '@float/types';

export type ViewTrackingEvent =
  | 'view-added'
  | 'view-applied'
  | 'view-pinned'
  | 'view-unpinned'
  | 'view-removed';

export function trackViewEvent(name: ViewTrackingEvent, view: SavedView) {
  trackEvent(name, {
    view_id: view.id,
    personal: view.personal,
    current_page: view.settings.page,
  });
}

enum ViewUpdateEventChange {
  personalToShared = 'personal-to-shared',
  sharedToPersonal = 'shared-to-personal',
  noVisibilityChange = 'no-visibility-change',
}
export function trackViewUpdate(view: SavedView, prev: SavedView) {
  let change = ViewUpdateEventChange.noVisibilityChange;

  if (view.personal !== prev.personal) {
    if (view.personal) {
      change = ViewUpdateEventChange.sharedToPersonal;
    } else {
      change = ViewUpdateEventChange.personalToShared;
    }
  }

  trackEvent('view-updated', {
    view_id: view.id,
    change,
    current_page: view.settings.page,
  });
}
