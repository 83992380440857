import React from 'react';
import { map } from 'lodash';

export default function JoinComponents({ components, separator = '/' }) {
  return (
    <>
      {map(components, (item, index) => {
        return (
          <span key={index}>
            {!!index && ` ${separator} `}
            {item}
          </span>
        );
      })}
    </>
  );
}
