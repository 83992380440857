import { get, isEmpty } from 'lodash';
import { createSelector } from 'reselect';

import { userCanUpdateThemself } from '@float/common/lib/rights';
import { getUser } from '@float/common/selectors/currentUser';
import {
  InboundCalendarVersion,
  integrationTitles,
} from '@float/web/pmSidebar/constants';

import { LOAD_STATE } from '../actions/constants';

export const getIsPmSidebarLoaded = createSelector(
  [(state) => state.pmSidebar.loadState],
  (loadState) =>
    [LOAD_STATE.LOADED, LOAD_STATE.LOAD_FAILED].includes(loadState),
);

export const getIsPmSidebarLoading = createSelector(
  [(state) => state.pmSidebar.loadState],
  (loadState) => loadState === LOAD_STATE.LOADING,
);

export const getDidPmSidebarLoadingFail = createSelector(
  [(state) => state.pmSidebar.loadState, (state) => state.pmSidebar.items],
  (loadState, items) => loadState === LOAD_STATE.LOAD_FAILED && !items.length,
);

export const getPmTaskLinks = (state) => state.pmSidebar.taskLinks;

export const getIsPmSidebarFiltered = createSelector(
  [
    (state) => state.pmSidebar.filter,
    (state) => state.pmSidebar.collapsedGroups,
  ],
  (filter, collapsedGroups) => {
    return (
      Object.values(filter).some((keyVal) => !isEmpty(keyVal)) ||
      Object.keys(collapsedGroups).length
    );
  },
);

const doesUserHavePmSidebarAccess = createSelector([getUser], (user) => {
  const canEditSchedule = userCanUpdateThemself(user);
  return canEditSchedule;
});

export const getActivePmIntegration = createSelector(
  [
    (state) => state.integrations.jira.coInts,
    (state) => state.integrations.teamwork.coInts,
    (state) => state.integrations.asana.coInts,
    (state) => state.integrations.trello.coInts,
  ],
  (jiraCoInts, teamworkCoInts, asanaCoInts, trelloCoInts) => {
    if (jiraCoInts && jiraCoInts.length) {
      const integration = jiraCoInts[0];
      const coIntId = integration.integrations_co_id;
      const baseUrl = `${get(integration, 'ancillary.baseUrl')}/browse`;
      return { type: 'jira', coIntId, baseUrl };
    }

    if (teamworkCoInts && teamworkCoInts.length) {
      const integration = teamworkCoInts[0];
      const coIntId = integration.integrations_co_id;
      const baseUrl = `${get(integration, 'ancillary.baseUrl')}/#/tasks`;
      return { type: 'teamwork', coIntId, baseUrl };
    }

    if (asanaCoInts && asanaCoInts.length) {
      const integration = asanaCoInts[0];
      const coIntId = integration.integrations_co_id;
      const baseUrl = get(integration, 'ancillary.baseUrl');
      return { type: 'asana', coIntId, baseUrl };
    }

    if (trelloCoInts && trelloCoInts.length) {
      const integration = trelloCoInts[0];
      const coIntId = integration.integrations_co_id;
      const baseUrl = true;
      return { type: 'trello', coIntId, baseUrl };
    }

    return {};
  },
);

export const getActiveIntegrations = createSelector(
  [(state) => state.integrations],
  (integrations) => {
    return Object.entries(integrations).reduce((acc, [key, value]) => {
      if (value && value.coInts && value.coInts.length) {
        const { integrations_co_id: coIntId } = value.coInts[0];
        acc.push({ type: key, label: integrationTitles[key], coIntId });
      }
      return acc;
    }, []);
  },
);

export const getActiveCalendarIntegration = createSelector(
  [
    (state) => state.integrations.calendar.extCalendars,
    (state) => state.integrations.calendar.calendarList,
  ],
  (extCalendars, calendarList) => {
    if (extCalendars && extCalendars.length) {
      const extCalendar = extCalendars[0];
      const name = calendarList
        ? calendarList.find(
            (cal) => cal.value === extCalendar.inboundResourceId,
          )?.label
        : null;
      return {
        ...extCalendar,
        name,
      };
    }
    return null;
  },
);

export const allowCalendarLockedProjects = createSelector(
  [getActiveCalendarIntegration],
  (extCalendar) => {
    if (extCalendar && extCalendar.inboundResourceId) {
      return extCalendar?.version === InboundCalendarVersion.V2;
    }
    return true;
  },
);

export const shouldShowPmSidebar = createSelector(
  [getActivePmIntegration, doesUserHavePmSidebarAccess],
  (activeIntegration, hasAccess) => {
    return activeIntegration && activeIntegration.coIntId && hasAccess;
  },
);

export const getPmSidebarVisibility = createSelector(
  [
    shouldShowPmSidebar,
    (state) => state.pmSidebar.visible,
    (state) => state.activityData.visible,
  ],
  (isSidebarEnabled, isSidebarOpen, isActivitySidebarOpen) => ({
    isSidebarEnabled,
    isSidebarOpen: isSidebarOpen || isActivitySidebarOpen,
  }),
);
