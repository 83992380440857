import React, { HTMLAttributes } from 'react';
import cn from 'classnames';

import * as styles from './styles.css';

type ListSeparatorProps = HTMLAttributes<HTMLDivElement>;

const ListSeparator = React.forwardRef<HTMLDivElement, ListSeparatorProps>(
  (props, forwardedRef) => {
    const { className, ...rest } = props;

    return (
      <div
        {...rest}
        ref={forwardedRef}
        className={cn(styles.separator, className)}
      />
    );
  },
);

export { ListSeparator };
