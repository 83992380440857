import { saveAs } from 'file-saver';
import { getTaskStatusString } from 'reports/components/TabBarFilters/StatusFilter';
import { getTeamModeString } from 'reports/components/TabBarFilters/TeamMode';

import {
  getPercentage as _getPercentage,
  formatAmount,
} from '@float/common/lib/budget';
import { moment } from '@float/libs/moment';
import { UNITS } from '@float/ui/deprecated/Chart/constants';

// For the purposes of CSV export, we always want the '%' symbol
function getPercentage(a, b) {
  return _getPercentage(a, b, true);
}

function formatDate(date, ctx) {
  if (
    ctx.settings.timeUnit === UNITS.DAY ||
    ctx.settings.timeUnit === UNITS.WEEK
  ) {
    return moment(date).format('DD MMM YYYY');
  }

  return moment(date).format('MMMM YYYY');
}

const COLUMNS = [
  ['Date', (d, ctx) => formatDate(d.date, ctx), (t) => 'Total'],
  ['Capacity hrs', (d) => d.capacity],
  ['Scheduled hrs', (d) => d.scheduled],
  ['Scheduled %', (d) => getPercentage(d.scheduled, d.capacity)],
  ['Scheduled Billable hrs', (d) => d.billable],
  ['Scheduled Billable %', (d) => getPercentage(d.billable, d.scheduled)],
  ['Scheduled Non-billable hrs', (d) => d.nonbillable],
  [
    'Scheduled Non-billable %',
    (d) => getPercentage(d.nonbillable, d.scheduled),
  ],
  [
    'Scheduled Tentative (all) hrs',
    (d) => d.tentative.billable + d.tentative.nonbillable,
  ],
  [
    'Scheduled Tentative (all) %',
    (d) =>
      getPercentage(
        d.tentative.billable + d.tentative.nonbillable,
        d.scheduled,
      ),
  ],
  ['Unscheduled hrs', (d) => d.unscheduled],
  ['Logged hrs', (d) => d.logged.total],
  ['Logged %', (d) => getPercentage(d.logged.total, d.capacity)],
  ['Logged Billable hrs', (d) => d.logged.billable],
  [
    'Logged Billable %',
    (d) => getPercentage(d.logged.billable, d.logged.total),
  ],
  ['Logged Non-billable hrs', (d) => d.logged.nonbillable],
  [
    'Logged Non-billable %',
    (d) => getPercentage(d.logged.nonbillable, d.logged.total),
  ],
  ['Scheduled Overtime hrs', (d) => d.overtime],
  ['Logged Overtime hrs', (d) => d.logged.overtime],
  ['Time off / Holiday hrs', (d) => d.timeoff + d.holiday],
  [
    'Time off / Holiday days',
    (d) => formatAmount(null, d.timeoff_days + d.holiday_days),
  ],
];

function getSheetData(ctx, chartData, chartTotals) {
  const hasLogged = ctx.timeTrackingEnabled;
  const columns = hasLogged
    ? COLUMNS
    : COLUMNS.filter((c) => !c[0].includes('Logged'));

  const headers = columns.map((c) => c[0]);
  const result = [headers];

  chartData.forEach((d) => {
    const row = columns.map((c) => c[1](d, ctx) || 0);
    row.forEach((r, idx) => {
      if (idx === 0) return;
    });
    result.push(row);
  });

  result.push(
    columns.map(
      (c) => (typeof c[2] !== 'undefined' ? c[2] : c[1])(chartTotals, ctx) || 0,
    ),
  );
  result.push([], []);

  result.push(
    [
      'Date range',
      moment(ctx.settings.startDate).format('DD MMM YYYY'),
      moment(ctx.settings.endDate).format('DD MMM YYYY'),
    ],
    ['Tasks', getTaskStatusString(ctx.settings)],
    ['People', getTeamModeString(ctx.settings)],
  );

  if (ctx.searchFiltersString) {
    result.push([ctx.searchFiltersString.replace('Filters:', 'Filters')]);
  }

  return result;
}

export default async function exportChartCsv(ctx, chartData, chartTotals) {
  return import('xlsx').then((XLSX) => {
    const { singlePerson, settings } = ctx;
    const startDate = moment(settings.startDate).format('YYYYMMDD');
    const endDate = moment(settings.endDate).format('YYYYMMDD');

    const filename = `${
      singlePerson || 'People'
    }-Chart-${startDate}-${endDate}.csv`;

    const sheetData = getSheetData(ctx, chartData, chartTotals);

    const ws = XLSX.utils.aoa_to_sheet(sheetData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Float');
    const wbout = XLSX.write(wb, { type: 'array', bookType: 'csv' });

    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), filename);
  });
}
