import React from 'react';

import { StyledSVG } from './styles';

export const CheckSvg = (props) => {
  const { className, selected } = props;

  return (
    <StyledSVG
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 53 44"
      $selected={selected}
    >
      <path
        stroke="#242C39"
        strokeLinecap="round"
        strokeWidth="8"
        d="M4.5 25 18 37A57 57 0 0 1 48.5 4.5"
      />
      <path
        stroke="#FEF2A4"
        strokeLinecap="round"
        strokeWidth="6"
        d="M4.5 25 18 37A57 57 0 0 1 48.5 4.5"
      />
    </StyledSVG>
  );
};
