import React, { useId, useRef } from 'react';
import { Controller, FieldPath, FieldValue } from 'react-hook-form';
import { t } from '@lingui/macro';

import { GroupedOptions } from '@float/types/options';
import VirtualSelect from '@float/ui/deprecated/VirtualSelect/VirtualSelect';

import { ProjectFormData } from '../../types';
import { FormField } from '../FormFieldsSection';
import { ReadOnlyOptions } from './ReadOnlyOption';
import { useGetSelectedValueFromComboBox } from './useGetSelectedValueFromComboBox';

export type ComboboxFieldProps<N extends FieldPath<ProjectFormData>> = {
  name: N;
  label: string;
  autoFocus?: boolean;
  options:
    | { value: FieldValue<ProjectFormData>[N]; label: string }[]
    | undefined;
  groupedOptions?: GroupedOptions<FieldValue<ProjectFormData>[N]>[] | undefined;
  onSelectGroupAll?: (
    opt: GroupedOptions<FieldPath<ProjectFormData>>['options'],
  ) => void;
  creatable?: boolean;
  nonNullable?: boolean;
  placeholder?: string;
  size?: string;
  onChange?: (value: FieldValue<ProjectFormData>[N]) => void;
  onBlur?: (value?: FieldValue<ProjectFormData>[N]) => void;
  onEnterSelect?: () => void;
  optionsPlacement?: string;
  hideLabel?: boolean;
  className?: string;
  searchable?: boolean;
  clearInputOnDropdownOpen?: boolean;
  readOnly?: boolean;
  'data-testid'?: string;
  gapBetweenItems?: number;
  calloutComponent?: React.ReactNode;
  onOpenChange?: (open: boolean) => void;
  forcedOpen?: boolean;
};

export function ComboboxField<N extends FieldPath<ProjectFormData>>(
  props: ComboboxFieldProps<N>,
) {
  const id = useId();
  const ref = useRef<HTMLDivElement>(null);
  const getSelectedOption = useGetSelectedValueFromComboBox(
    Boolean(props.readOnly),
    props.options,
    props.groupedOptions,
  );
  return (
    <FormField
      ref={ref}
      label={props.label}
      htmlFor={id}
      hideLabel={props.hideLabel}
      className={props.className}
    >
      <Controller
        name={props.name}
        render={({ field }) =>
          props.readOnly ? (
            <ReadOnlyOptions option={getSelectedOption(field.value)} />
          ) : (
            // TODO Create a new combobox component
            // https://linear.app/float-com/issue/FT-2003/spike-define-a-strategy-the-new-combobox-variants
            <VirtualSelect
              // @ts-expect-error VirtualSelect doesn't have types
              autoFocus={props.autoFocus}
              size={props.size}
              visibleItems={6}
              optionsPlacement={props.optionsPlacement}
              creatable={props.creatable}
              containerRef={ref}
              nonNullable={props.nonNullable}
              placeholder={props.placeholder || t`Empty`}
              groupedOptions={props.groupedOptions}
              options={props.options}
              value={field.value}
              onEnterSelect={props.onEnterSelect}
              onChange={({ value }: typeof field.value) => {
                field.onChange(value ?? null);
                if (props.onChange) {
                  props.onChange(value);
                }
              }}
              searchable={props.searchable}
              clearInputOnDropdownOpen={props.clearInputOnDropdownOpen}
              disabled={props.readOnly}
              onInputBlur={field.onBlur}
              inputId={id}
              onSelectGroupAll={props.onSelectGroupAll}
              data-testid={props['data-testid']}
              gapBetweenItems={props.gapBetweenItems}
              onOpenChange={props.onOpenChange}
              forcedOpen={props.forcedOpen}
            />
          )
        }
      />
    </FormField>
  );
}
