import React, { useState } from 'react';

import { formatToFloatDate } from '@float/libs/dates';
import { useEventListenerOnElement } from '@float/libs/hooks/useEventListenerOnElement';

export type UseActiveMonthProps = {
  baseColOffset: number;
  dates: DatesManager;
  dayWidth: number;
  numDays: number;
  scrollWrapperRef: React.MutableRefObject<HTMLElement | null | undefined>;
  today?: Date;
};

export const useActiveMonth = ({
  baseColOffset,
  dates,
  dayWidth,
  numDays,
  scrollWrapperRef,
  today = new Date(),
}: UseActiveMonthProps) => {
  const [activeMonth, setActiveMonth] = useState(formatToFloatDate(today));

  useEventListenerOnElement(
    scrollWrapperRef,
    'scroll',
    () => {
      const scrollLeft = scrollWrapperRef?.current?.scrollLeft ?? 0;
      const colIndex = baseColOffset + scrollLeft / (numDays * dayWidth);
      const subColIndex = Math.floor((colIndex % 1) * numDays);

      const currentColumnDate = dates.fromDescriptor(
        Math.floor(colIndex),
        subColIndex,
      );

      // `fromDescriptor` can return undefined, even though it's not currently
      // reflected in the type signature
      if (!currentColumnDate) {
        return;
      }

      setActiveMonth(currentColumnDate);
    },
    { passive: true },
  );

  return { activeMonth, setActiveMonth };
};
