import { clamp, union } from 'lodash';

import {
  calculateMaxEndDate,
  calculateMinStartDate,
} from '@float/libs/timeRange';
import {
  CellChange,
  CellItem,
  CellKey,
  RowId,
  TimeRange,
} from '@float/types/cells';

import { getEntityMeta, setEntityMeta } from './entityMeta';
import { getCellKeyRowId } from './helpers/getCellKeyRowId';
import { GetAction, UseCellsReducerProps, UseCellsState } from './types';

export function resizeEntityVertical(
  props: UseCellsReducerProps,
  state: UseCellsState,
  action: GetAction<'RESIZE_ENTITY_VERTICAL'>,
): UseCellsState {
  const {
    bimaps,
    cellHelpers: {
      buildCells,
      getMinWorkHoursInRange,
      unapplyChanges,
      applyChanges,
    },
  } = props;
  const { cells } = state;

  const { item, originalEntity, hourDelta } = action;

  const cellKeys = bimaps[item.type].getFwd(item.entityId) as RowId[];

  let maxItemHeight = 24;
  const hoursStep = 0.25;
  if (item.type === 'timeoff') {
    maxItemHeight = Math.min(
      maxItemHeight,
      getMinWorkHoursInRange(item.entity),
    );
  }

  setEntityMeta(item.entity, 'isResizingVertically', true);
  const roundUnit = 1 / hoursStep;
  const clamped = clamp(
    Math.round((originalEntity.hours! + hourDelta) * 100) / 100,
    hoursStep,
    maxItemHeight,
  );
  item.entity.hours = Math.floor(clamped * roundUnit) / roundUnit;

  const pendingChanges: CellChange[] = [];
  const affectedCellKeys = union(
    cellKeys,
    unapplyChanges(state.pendingChanges),
    applyChanges(cells, cellKeys, item.entity, pendingChanges, {
      dayDelta: 0,
    }),
  );
  buildCells(cells, affectedCellKeys, undefined);

  return { ...state, cells };
}

function setMinEndDate(
  maps: UseCellsReducerProps['maps'],
  item: CellItem<'phase'>,
) {
  const { phase_id } = item.entity;

  let minEnd: string | null = null;
  for (const task of Object.values(maps.task)) {
    if (task.phase_id !== phase_id) continue;

    const instance =
      task.allInstances && task.allInstances[task.allInstances.length - 1];

    if (instance !== undefined) {
      if (!minEnd || instance.end_date > minEnd) {
        minEnd = instance.end_date;
      }
    }
  }

  for (const milestone of Object.values(maps.milestone)) {
    if (milestone.phase_id !== phase_id) continue;

    if (!minEnd || milestone.end_date > minEnd) {
      minEnd = milestone.end_date;
    }
  }

  setEntityMeta(item.entity, 'min_end_date', minEnd);
}

function setMaxStartDate(
  maps: UseCellsReducerProps['maps'],
  item: CellItem<'phase'>,
) {
  const { phase_id } = item.entity;

  let maxStart: string | null = null;
  for (const task of Object.values(maps.task)) {
    if (task.phase_id !== phase_id) continue;

    const instance = task.allInstances && task.allInstances[0];
    if (instance !== undefined) {
      if (!maxStart || instance.start_date < maxStart) {
        maxStart = instance.end_date;
      }
    }
  }

  for (const milestone of Object.values(maps.milestone)) {
    if (milestone.phase_id !== phase_id) continue;

    if (!maxStart || milestone.start_date < maxStart) {
      maxStart = milestone.start_date;
    }
  }

  setEntityMeta(item.entity, 'max_start_date', maxStart);
}

export function resizeEntityHorizontal(
  props: UseCellsReducerProps,
  state: UseCellsState,
  action: GetAction<'RESIZE_ENTITY_HORIZONTAL'>,
): UseCellsState {
  const {
    dates,
    maps,
    bimaps,
    cellHelpers: {
      buildCells,
      isWorkDay,
      calcEntityLength,
      unapplyChanges,
      applyChanges,
      getApplicableCellKeys,
      countWorkDays,
    },
  } = props;
  const { cells } = state;

  const { item, originalEntity, dayDelta, edge } = action;
  const { start_date: originalStartDate, end_date: originalEndDate } =
    originalEntity;
  const id = item.entityId;
  const rowId = getCellKeyRowId(item.cellKey as CellKey);
  const entity = maps[item.type][id];
  const considerWorkDays = !/(milestone|phase|timeRange)/.test(item.type);

  if (!getEntityMeta(entity, 'adjusted')) {
    entity.start_date = item.entity.start_date;
    entity.end_date = item.entity.end_date;
    setEntityMeta(entity, 'repeatInstances', undefined);
    setEntityMeta(entity, 'adjusted', true);

    if (item.type === 'phase') {
      if (edge === 'R') {
        setMinEndDate(props.maps, action.item as CellItem<'phase'>);
      } else {
        setMaxStartDate(props.maps, action.item as CellItem<'phase'>);
      }
    }
  }

  if (item.type === 'timeRange') {
    const timeRange = entity as TimeRange;
    timeRange.range_mode = 'custom';
    if (edge === 'R') {
      timeRange.max_end_date = calculateMaxEndDate(timeRange.start_date);
    } else {
      timeRange.min_start_date = calculateMinStartDate(timeRange.end_date);
    }
  }

  setEntityMeta(item.entity, 'isResizingHorizontally', true);

  // Don't take any action if the user is resizing to length 0 or on top
  // of a non-work day.
  if (edge === 'R') {
    if (entity.start_date > dates.addDays(originalEndDate, dayDelta)) {
      return state;
    }
    if (
      considerWorkDays &&
      !isWorkDay(
        cells,
        rowId,
        dates.addDays(originalEndDate, dayDelta),
        'timeoff_id' in entity ? entity : undefined,
      )
    ) {
      return state;
    }
  } else {
    if (entity.end_date < dates.addDays(originalStartDate, dayDelta)) {
      return state;
    }

    if (
      considerWorkDays &&
      !isWorkDay(
        cells,
        rowId,
        dates.addDays(originalStartDate, dayDelta),
        'timeoff_id' in entity ? entity : undefined,
      )
    ) {
      return state;
    }
  }

  const oldCellKeys = bimaps[item.type].delete(id);

  if (edge === 'R') {
    entity.end_date = dates.addDays(originalEndDate, dayDelta);
    const minEndDate = getEntityMeta(entity, 'min_end_date');
    if (minEndDate) {
      entity.end_date = dates.max(entity.end_date, minEndDate);
    }
    const maxEndDate = getEntityMeta(entity, 'max_end_date');
    if (maxEndDate) {
      entity.end_date = dates.min(entity.end_date, maxEndDate);
    }
  } else {
    entity.start_date = dates.addDays(originalStartDate, dayDelta);
    const minStartDate = getEntityMeta(entity, 'min_start_date');
    if (minStartDate) {
      entity.start_date = dates.max(entity.start_date, minStartDate);
    }
    const maxStartDate = getEntityMeta(entity, 'max_start_date');
    if (maxStartDate) {
      entity.start_date = dates.min(entity.start_date, maxStartDate);
    }

    if ('parent_task_id' in entity && entity.parent_task_id) {
      const parentEntity = maps.task[entity.parent_task_id];

      if (parentEntity) {
        entity.start_date = dates.max(
          parentEntity.start_date,
          entity.start_date,
        );
      }
    }
  }

  if (considerWorkDays) {
    // @entity.length
    entity.length = calcEntityLength(cells, rowId, entity);
  } else {
    // @entity.length
    entity.length = dates.addDays(
      entity.end_date,
      -getEntityMeta(entity, 'start')! + 1,
    );
  }

  // Figure out how many work days the activeEntity was adjusted by.
  // This is the number of work days that we want to adjust the other
  // multiselected entities by as well.
  let activeEntityWorkDayStartDelta = countWorkDays(
    cells,
    item.rowId!,
    dates.min(entity.end_date, originalEndDate),
    dates.max(entity.end_date, originalEndDate),
  );
  if (entity.end_date < originalEndDate) {
    activeEntityWorkDayStartDelta *= -1;
  }

  const newCellKeys = getApplicableCellKeys(entity);
  bimaps[item.type].assoc(id, newCellKeys);

  // If we have any pending changes, unapply them. Then, regenerate any
  // necessary pending changes based on the current state. This is a bit
  // heavy-handed, but it's easier to reason about just generating changes
  // based on a current state than tracking the in-between deltas.
  const pendingChanges: CellChange[] = [];
  const affectedCellKeys = union(
    oldCellKeys,
    newCellKeys,
    unapplyChanges(state.pendingChanges),
    applyChanges(cells, newCellKeys, entity, pendingChanges, {
      dayDelta: activeEntityWorkDayStartDelta,
    }),
  );

  buildCells(cells, affectedCellKeys, undefined);

  return { ...state, cells, pendingChanges };
}

export function resizeEntityStop(
  props: UseCellsReducerProps,
  state: UseCellsState,
  action: GetAction<'RESIZE_ENTITY_STOP'>,
): UseCellsState {
  const {
    maps,
    bimaps,
    cellHelpers: { buildCells },
  } = props;
  const { cells } = state;

  const { item, originalEntity } = action;
  const id = item.entityId;
  const entity = maps[item.type][id];

  const isResizingHorizontally = getEntityMeta(
    item.entity,
    'isResizingHorizontally',
  );

  if (isResizingHorizontally && item.entity !== entity) {
    // If we're resizing a repeat instance of a task, we track the live
    // modifications on the entity but need to copy them over to this
    // specific instance for persistence.
    item.entity.start_date = entity.start_date;
    item.entity.end_date = entity.end_date;
  }

  setEntityMeta(entity, 'adjusted', undefined);
  setEntityMeta(entity, 'min_end_date', undefined);
  setEntityMeta(entity, 'max_end_date', undefined);
  setEntityMeta(entity, 'min_start_date', undefined);
  setEntityMeta(entity, 'max_start_date', undefined);
  setEntityMeta(entity, 'isResizingVertically', undefined);
  setEntityMeta(entity, 'isResizingHorizontally', undefined);

  const affectedCellKeys = bimaps[item.type].getFwd(item.entityId);

  buildCells(cells, affectedCellKeys, undefined);

  const newChange = {
    type: item.type,
    id,
    entity: item.entity,
    originalEntity,
  };

  return {
    ...state,
    cells,
    pendingChanges: [],
    changes: [...state.pendingChanges, newChange],
  };
}
