import React, { ChangeEvent, Fragment, KeyboardEvent, useId } from 'react';
import { t, Trans } from '@lingui/macro';

import Button from '@float/ui/deprecated/Button/Button';
import * as styles from '@float/ui/deprecated/ColorPicker/styles.css';

import { ColorPickerInput } from '../ColorPickerInput';
import { ColorPickerSwatch } from './ColorPickerSwatch';

import ImgColorPalette from './color-palette.svg';

type ColorPickerMenuProps = {
  value?: string;
  disableCustomColors?: boolean;
  colors?: string[];
  clientColors?: string[];
  recentColors?: string[];
  onChange: (value: string) => void;
  onSelect: (value: string) => void;
  handleKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
};

function isNonEmptyArray<T>(value: undefined | T[]): value is T[] {
  return Array.isArray(value) && value.length > 0;
}

const IconColorPalette = () => (
  <div className={styles.colorPalette}>
    <img className={styles.colorPaletteImage} src={ImgColorPalette} />
  </div>
);

export const ColorPickerMenu = (props: ColorPickerMenuProps) => {
  const valueLower = props.value?.toLowerCase();

  const onNativeColorPickerChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.value.slice(1));
  };

  const createHandleSelect = (color: string) => () => {
    props.onChange(color);
    props.onSelect(color);
  };

  const { clientColors, recentColors } = props;

  const colorPickerInputId = `color-picker-${useId()}`;

  return (
    <div className={styles.colorPickerMenuContainer}>
      <div className={styles.colorPickerDropdownContainer}>
        {[
          !props.disableCustomColors && (
            <>
              <label
                htmlFor={colorPickerInputId}
                className={styles.colorPickerLabel}
              >
                <Trans>HEX #</Trans>
              </label>
              <ColorPickerInput
                id={colorPickerInputId}
                value={props.value}
                onChange={props.onChange}
                onKeyDown={props.handleKeyDown}
              />
            </>
          ),
          <>
            <span className={styles.colorPickerLabel}>
              <Trans>Default colors</Trans>
            </span>

            <div className={styles.colorPickerColorsContainer}>
              {props.colors?.map((c, i) => (
                <ColorPickerSwatch
                  key={`${c}_${i}`}
                  color={c}
                  aria-label={t`Select the ${c} color`}
                  onSelect={createHandleSelect(c)}
                  aria-selected={valueLower === c}
                />
              ))}
            </div>
          </>,
          isNonEmptyArray(clientColors) && (
            <>
              <span className={styles.colorPickerLabel}>
                <Trans>Used by client before</Trans>
              </span>
              <div className={styles.colorPickerColorsContainer}>
                {clientColors.slice(0, 8).map((c, i) => (
                  <ColorPickerSwatch
                    key={`${c}_${i}`}
                    color={c}
                    aria-label={t`Select the ${c} color`}
                    aria-selected={valueLower === c}
                    onSelect={createHandleSelect(c)}
                  />
                ))}
              </div>
            </>
          ),
          isNonEmptyArray(recentColors) && (
            <>
              <span className={styles.colorPickerLabel}>
                <Trans>Recently used</Trans>
              </span>
              <div className={styles.colorPickerColorsContainer}>
                {recentColors.slice(0, 8).map((c, i) => (
                  <ColorPickerSwatch
                    key={`${c}_${i}`}
                    color={c}
                    aria-label={t`Select the ${c} color`}
                    aria-selected={valueLower === c}
                    onSelect={createHandleSelect(c)}
                  />
                ))}
              </div>
            </>
          ),
          !props.disableCustomColors && (
            <Button
              className={styles.colorPickerCustomColor}
              appearance="secondary"
              icon={IconColorPalette}
            >
              <input
                type="color"
                className={styles.colorPickerInputColor}
                value={`#${props.value}`}
                onChange={onNativeColorPickerChange}
              />
              <Trans>Set custom color...</Trans>
            </Button>
          ),
        ]
          .filter(Boolean)
          .map((item, i, { length }) => (
            <Fragment key={i}>
              {item}
              {i !== length - 1 && (
                <hr className={styles.colorPickerMenuDivider} />
              )}
            </Fragment>
          ))}
      </div>
    </div>
  );
};
