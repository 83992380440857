import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { t } from '@lingui/macro';
import useMount from 'react-use/esm/useMount';

import {
  AccessPermissionAction,
  AccessPermissionItem,
} from '@float/common/api3/accessPermissions';
import CompanySelect from '@float/common/components/CompanySelect/CompanySelect';
import { SignupExist } from '@float/web/components/auth/SignupExist';
import withTitle from '@float/web/components/decorators/withTitle';

import ReactivateSubscription from '../components/auth/ReactivateSubscription/ReactivateSubscription';
import { PermissionGuard } from '../components/PermissionGuard';

import './AuthRouter.css';

import { Routes } from './constants';

export const AuthRouter = () => {
  useMount(() => {
    const root = document.querySelector('.app');

    if (root) {
      root.classList.add('scrollable-wrap');

      return () => {
        root.classList.remove('scrollable-wrap');
      };
    }
  });

  return (
    <Switch>
      <Route
        path={Routes.SignupExist}
        component={withTitle(t`Account exists | Float`, SignupExist)}
      />
      <Route
        path={Routes.ReactivateSubscription}
        component={withTitle(t`Reactivate subscription | Float`, () => (
          <PermissionGuard
            action={AccessPermissionAction.View}
            item={AccessPermissionItem.UIAdminBillingResumeSubscription}
            shouldRedirectIfNotPermitted
          >
            <ReactivateSubscription />
          </PermissionGuard>
        ))}
      />
      <Route
        path={Routes.SelectCompany}
        component={withTitle(t`Select company | Float`, CompanySelect)}
      />
    </Switch>
  );
};
