import { throttle } from 'lodash';

import { exposeGlobal, readGlobal } from '@float/libs/web/globals';
import { passiveEvent } from '@float/libs/web/passiveEvent';

// to avoid duplicated events when HMR is enabled, we keep track
// of the current callback and make sure there's only one active
if (readGlobal('ResizeServiceCallback')) {
  window.removeEventListener('resize', readGlobal('ResizeServiceCallback'));
}

const ResizeService = (function () {
  const all = [];

  let size = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  const onResize = throttle(
    () => {
      const newSize = { width: window.innerWidth, height: window.innerHeight };

      if (newSize.width === size.width && newSize.height === size.height) {
        return;
      }

      size = newSize;

      all.map((handler) => handler());
    },
    600,
    { trailing: true },
  );

  function getSize() {
    return size;
  }

  window.addEventListener('resize', onResize, passiveEvent);

  exposeGlobal('ResizeServiceCallback', onResize);

  return {
    add(handler) {
      all.push(handler);
    },

    remove(handler) {
      all.splice(all.indexOf(handler) >>> 0, 1);
    },

    size: () => getSize(),
  };
})();

export default ResizeService;
