import React from 'react';
import { t } from '@lingui/macro';
import { DialogClose } from '@radix-ui/react-dialog';

import { IconClose } from '@float/ui/icons/essentials/IconClose';

import * as styles from './styles.css';

type SidePanelCloseProps = {
  onClose?: () => void;
};

export const SidePanelClose: React.FC<SidePanelCloseProps> = ({ onClose }) => {
  return (
    <DialogClose
      className={styles.sidePanelCloseButton}
      aria-label={t`Close the side panel`}
      onClick={onClose}
    >
      <IconClose />
    </DialogClose>
  );
};
