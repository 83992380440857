import React from 'react';

export default function ({ color }) {
  return (
    <svg
      height="12px"
      version="1.1"
      viewBox="0 0 18 12"
      width="18px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g
          fill={color || '#ffffff'}
          transform="translate(-87.000000, -342.000000)"
        >
          <g transform="translate(87.000000, 342.000000)">
            <path d="M0,12 L18,12 L18,10 L0,10 L0,12 L0,12 Z M0,7 L18,7 L18,5 L0,5 L0,7 L0,7 Z M0,0 L0,2 L18,2 L18,0 L0,0 L0,0 Z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
