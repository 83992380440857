import React from 'react';

import { FeatureFlag, isFeatureEnabled } from '@float/libs/featureFlags';

import { WelcomeWithIllustration } from './WelcomeWithIllustration';
import { WelcomeWithVideo } from './WelcomeWithVideo';

export type WelcomeProps = {
  goNext: () => void;
  skipTour: () => void;
};

export const Welcome = (props: WelcomeProps) => {
  return isFeatureEnabled(FeatureFlag.OnboardingWelcomeVideo) ? (
    <WelcomeWithVideo {...props} />
  ) : (
    <WelcomeWithIllustration {...props} />
  );
};
