import { cloneDeep } from 'lodash';

import {
  LOGGED_TIME_BULK_CREATED,
  LOGGED_TIME_BULK_CREATED_UNDO,
  LOGGED_TIME_CREATED,
  LOGGED_TIME_DELETED,
  LOGGED_TIME_UPDATED,
} from '@float/common/actions/loggedTimes';
import {
  PHASES_UPDATED,
  SHIFT_PHASE_TIMELINE,
} from '@float/common/actions/phases';
import {
  CREATE_TASK_SUCCESS,
  DELETE_TASK_SUCCESS,
  LINKED_TASKS_UPDATE_SUCCESS,
  SPLIT_TASK,
  SPLIT_TASK_DELETE_SUCCESS,
  SPLIT_TASK_START,
  UPDATE_TASK_SUCCESS,
} from '@float/common/actions/tasks';
import {
  CREATE_TIMEOFF_SUCCESS,
  DELETE_TIMEOFF_SUCCESS,
  UPDATE_TIMEOFF_SUCCESS,
} from '@float/common/actions/timeoffsConstants';
import {
  VIEW_CREATED,
  VIEW_DELETED,
  VIEW_UPDATED,
} from '@float/common/actions/views';
import { formatTask } from '@float/common/lib/formatters';
import { AllActions } from '@float/common/reducers';
import { SplitTaskChanges } from '@float/types';

import { REDO_BEGIN, SET_UNDO_STACK_MODE, UNDO_BEGIN } from './actions';
import {
  ACTIONS_SUPPORTING_UNDO,
  ACTIONS_SUPPORTING_UNDO_FAILURE,
} from './constants';

const MAX_STACK_SIZE = 25;

export type UndoReducerState = {
  undoable: (AllActions & { batchId: number })[];
  redoable: (AllActions & { batchId: number })[];
  processing: boolean | 'undo' | 'redo';
  processingBatchId: null | number;
  mode: 'schedule' | 'logTime';
  splitTaskChanges: [] | SplitTaskChanges;
};

export const DEFAULT_STATE: UndoReducerState = {
  undoable: [],
  redoable: [],
  processing: false,
  processingBatchId: null,
  mode: 'schedule',
  splitTaskChanges: [],
};

const getItemFromAction = (action: AllActions) => {
  switch (action.type) {
    case CREATE_TASK_SUCCESS:
    case UPDATE_TASK_SUCCESS: {
      const { item, previous } = action;
      return {
        itemId: item.task_id,
        itemType: 'task',
        item: item && formatTask(item),
        previous: previous && formatTask(previous),
      };
    }
    case DELETE_TASK_SUCCESS: {
      const { undoPayload } = action;

      return {
        itemType: 'task',
        undoPayload,
      };
    }
    case SPLIT_TASK: {
      const { newTask, previous } = action;
      return {
        itemType: 'task',
        newTask: newTask,
        previous: previous && formatTask(previous),
      };
    }
    case SPLIT_TASK_START: {
      // Store split task changes in state to enable resplitting of tasks
      return {
        changes: action.changes,
      };
    }
    case SPLIT_TASK_DELETE_SUCCESS: {
      const { item, previous, undoPayload } = action;

      return {
        itemId: item.task_id,
        itemType: 'task',
        item: item && formatTask(item),
        previous: previous && formatTask(previous),
        undoPayload: undoPayload,
      };
    }
    case CREATE_TIMEOFF_SUCCESS:
    case UPDATE_TIMEOFF_SUCCESS:
    case DELETE_TIMEOFF_SUCCESS: {
      const { item, previous } = action;
      return {
        itemId: item.timeoff_id,
        itemType: 'timeoff',
        item,
        previous,
      };
    }

    case LOGGED_TIME_CREATED:
    case LOGGED_TIME_UPDATED:
    case LOGGED_TIME_DELETED: {
      const { loggedTime: item, previous } = action;
      return {
        itemType: 'loggedTime',
        item: cloneDeep(item),
        previous: cloneDeep(previous),
      };
    }

    case SHIFT_PHASE_TIMELINE: {
      const { newPhase, originalEntity } = action;
      return { newPhase, originalEntity };
    }

    case PHASES_UPDATED: {
      // We've already validated that phases and prevPhases each are
      // of length 1 in undo/constants.
      const { phases, prevPhases } = action;
      return { newPhase: phases[0], originalEntity: prevPhases[0] };
    }

    case LINKED_TASKS_UPDATE_SUCCESS: {
      return action;
    }

    case LOGGED_TIME_BULK_CREATED: {
      return action;
    }

    case VIEW_CREATED:
    case VIEW_UPDATED:
    case VIEW_DELETED: {
      return action;
    }

    default:
      return {};
  }
};

const getHistoryItemFromAction = (action: AllActions) => ({
  ...getItemFromAction(action),
  actionType: action.type,
  batchId: action.undoBatchId,
});

const didUndoFail = (action: AllActions) =>
  ACTIONS_SUPPORTING_UNDO_FAILURE.includes(action.type);

const undoCommandStream = (
  state = DEFAULT_STATE,
  action: AllActions,
): UndoReducerState => {
  switch (action.type) {
    case SET_UNDO_STACK_MODE:
      if (state.mode === action.mode) return state;
      return { ...DEFAULT_STATE, mode: action.mode };
    case UNDO_BEGIN:
      return {
        ...state,
        processing: 'undo',
        processingBatchId: action.batchId,
      };
    case REDO_BEGIN:
      return {
        ...state,
        processing: 'redo',
        processingBatchId: action.batchId,
      };
    case LOGGED_TIME_BULK_CREATED_UNDO:
      return { ...state, processing: false };
    default:
      break;
  }

  if (didUndoFail(action)) {
    if (!state.processing) {
      return state;
    }
    return { ...state, processing: false, processingBatchId: null };
  }

  if (
    !ACTIONS_SUPPORTING_UNDO.some((a) => {
      if (typeof a === 'function') return a(action);
      return a === action.type;
    })
  ) {
    return state;
  }

  if (action.isLiveUpdate) {
    return state;
  }

  const isProcessingBatchUndoOrRedo =
    !action.undoBatchId && state.processingBatchId;
  if (isProcessingBatchUndoOrRedo) {
    action.undoBatchId = state.processingBatchId;
  }

  const historyItem = getHistoryItemFromAction(action);

  if (state.processing === 'undo') {
    let { redoable } = state;
    if (redoable.length >= MAX_STACK_SIZE) {
      redoable = redoable.slice(1);
    }

    return {
      ...state,
      processing: false,
      processingBatchId: null,
      redoable: redoable.concat(historyItem),
    };
  }

  let { undoable } = state;

  if (undoable.length >= MAX_STACK_SIZE) {
    undoable = undoable.slice(1);
  }

  if (state.processing === 'redo') {
    return {
      ...state,
      processing: false,
      processingBatchId: null,
      undoable: undoable.concat(historyItem),
    };
  }

  const splitTaskChanges = historyItem?.changes?.length
    ? historyItem.changes
    : state.splitTaskChanges;

  return {
    ...state,
    undoable: undoable.concat(historyItem),
    redoable: [], // clear the Redo stack if pushing another command
    splitTaskChanges,
  };
};

export default undoCommandStream;
