import React, { StrictMode } from 'react';
import cx from 'classnames';

import * as styles from './TabsNav.css';

export type TabsNavProps = {
  children: React.ReactNode;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const TabsNav = ({ className, children, ...rest }: TabsNavProps) => (
  <StrictMode>
    <nav className={cx(styles.tabsNav, className)} {...rest}>
      <ul className={styles.tabsNavList}>{children}</ul>
    </nav>
  </StrictMode>
);
