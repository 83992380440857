import { sortBy } from 'lodash';
import { createSelector } from 'reselect';

import { getUser } from '../currentUser';
import { getPeople } from './people';

export const getDepartmentFilteredActivePeople = createSelector(
  [getPeople, getUser],
  (people, user) => {
    return sortBy(
      people.filter((p) => {
        const matchesDept = user.department_filter_all?.length
          ? user.department_filter_all.includes(p.department_id)
          : true;

        return matchesDept && p.active;
      }),
      (p) => p.name.toLowerCase(),
    );
  },
);
