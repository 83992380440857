import { useFormContext } from 'react-hook-form';

import { ProjectFormData } from '../types';
import { useSetPersonDefaultHourlyRate } from './useSetPersonDefaultHourlyRate';

export function usePopulateTeamDefaultHourlyRates() {
  const { getValues } = useFormContext<ProjectFormData>();
  const { setPersonDefaultHourlyRate } = useSetPersonDefaultHourlyRate();

  const populateTeamDefaultHourlyRates = () => {
    const team = getValues('team');

    team.forEach((member, i) => {
      if (member.people_id && !member.isAssignedToPhaseOnly) {
        setPersonDefaultHourlyRate(i, member.people_id);
      }
    });
  };

  return { populateTeamDefaultHourlyRates };
}
