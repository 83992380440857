import React from 'react';

import BaseIcon, { BaseIconProps } from './BaseIcon';

const IconPin = (props: BaseIconProps) => {
  return (
    <BaseIcon {...props}>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M14.22 4.22a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06l-.293-.293-3.499 6.38.852.853a.75.75 0 1 1-1.06 1.06l-1.239-1.238a.609.609 0 0 1-.024-.024L11 14.061l-5.72 5.72a.75.75 0 0 1-1.06-1.061L9.94 13l-2.458-2.457a.66.66 0 0 1-.024-.024L6.22 9.28a.75.75 0 0 1 1.06-1.06l.853.852 6.38-3.499-.293-.293a.75.75 0 0 1 0-1.06Zm1.397 2.458-6.38 3.499 2.293 2.292v.001l2.293 2.293 3.499-6.38-1.705-1.705Z"
        clipRule="evenodd"
      />
    </BaseIcon>
  );
};

export { IconPin };
