import { uniqBy } from 'lodash';

import {
  ADD_EDIT_EXT_CALENDAR,
  ADD_EDIT_EXT_CALENDAR_FAILURE,
  ADD_EDIT_EXT_CALENDAR_SUCCESS,
  FETCH_CALENDAR_LIST,
  FETCH_CALENDAR_LIST_FAILURE,
  FETCH_CALENDAR_LIST_SUCCESS,
  FETCH_CALENDAR_OAUTH_LINK,
  FETCH_CALENDAR_OAUTH_LINK_FAILURE,
  FETCH_CALENDAR_OAUTH_LINK_SUCCESS,
  FETCH_EXT_CALENDARS,
  FETCH_EXT_CALENDARS_FAILURE,
  FETCH_EXT_CALENDARS_SUCCESS,
  RESYNC_EXT_CALENDAR,
  RESYNC_EXT_CALENDAR_FAILURE,
  RESYNC_EXT_CALENDAR_SUCCESS,
} from '../actions/calendar';

const DEFAULT_STATE = {
  oauthLink: null,
  oauthLinks: {},
  calendarList: undefined,
};

export default function calendar(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    /* FETCH OAUTH LINK */

    case FETCH_CALENDAR_OAUTH_LINK: {
      return {
        ...state,
        oauthLinkLoadState: 'LOADING',
      };
    }

    case FETCH_CALENDAR_OAUTH_LINK_SUCCESS: {
      return {
        ...state,
        oauthLinkLoadState: 'LOAD_SUCCESS',
        oauthLinks: action.oauthLinks,
      };
    }

    case FETCH_CALENDAR_OAUTH_LINK_FAILURE: {
      return {
        ...state,
        oauthLinkLoadState: 'LOAD_FAILED',
      };
    }

    /* FETCH CALENDAR LIST */

    case FETCH_CALENDAR_LIST: {
      return {
        ...state,
        calendarListLoadState: 'LOADING',
      };
    }

    case FETCH_CALENDAR_LIST_SUCCESS: {
      return {
        ...state,
        calendarListLoadState: 'LOAD_SUCCESS',
        calendarList: action.calendarList,
      };
    }

    case FETCH_CALENDAR_LIST_FAILURE: {
      return {
        ...state,
        calendarListLoadState: 'LOAD_FAILED',
      };
    }

    /* FETCH EXTERNAL CALENDARS */

    case FETCH_EXT_CALENDARS: {
      return {
        ...state,
        fetchExtCalendarsLoadState: 'LOADING',
      };
    }

    case FETCH_EXT_CALENDARS_SUCCESS: {
      return {
        ...state,
        fetchExtCalendarsLoadState: 'LOAD_SUCCESS',
        extCalendars: action.extCalendars,
      };
    }

    case FETCH_EXT_CALENDARS_FAILURE: {
      return {
        ...state,
        fetchExtCalendarsLoadState: 'LOAD_FAILED',
      };
    }

    /* ADD EXT CALENDAR */

    case ADD_EDIT_EXT_CALENDAR: {
      return {
        ...state,
        addEditExtCalendarLoadState: 'LOADING',
      };
    }

    case ADD_EDIT_EXT_CALENDAR_SUCCESS: {
      return {
        ...state,
        addEditExtCalendarLoadState: 'LOAD_SUCCESS',
        extCalendars: uniqBy(
          [action.extCalendar, ...state.extCalendars],
          'ext_calendar_id',
        ),
      };
    }

    case ADD_EDIT_EXT_CALENDAR_FAILURE: {
      return {
        ...state,
        addEditExtCalendarLoadState: 'LOAD_FAILED',
      };
    }

    /* RESYNC EXT CALENDAR */

    case RESYNC_EXT_CALENDAR: {
      const extCalendars = [...state.extCalendars];
      if (extCalendars && extCalendars[0]) {
        extCalendars[0].status = 2;
        extCalendars[0].requiresReauth = false;
      }
      return {
        ...state,
        resyncExtCalendarLoadState: 'LOADING',
        extCalendars,
      };
    }

    case RESYNC_EXT_CALENDAR_SUCCESS: {
      return {
        ...state,
        resyncExtCalendarLoadState: 'LOAD_SUCCESS',
      };
    }

    case RESYNC_EXT_CALENDAR_FAILURE: {
      return {
        ...state,
        resyncExtCalendarLoadState: 'LOAD_FAILED',
      };
    }

    default: {
      return state;
    }
  }
}
