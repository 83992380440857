import bemCx from './bemCx';

const DateRangePickerBemMixin = {
  cx(options = {}) {
    const opts = {
      namespace: null,
      element: this.constructor.displayName,
      block: 'DateRangePicker',
    };

    Object.assign(opts, options);
    return bemCx(opts);
  },
};

export default DateRangePickerBemMixin;
