import styled, { css } from 'styled-components';

import * as Animation from '../../../Animation';
import * as Colors from '../../../Colors';
import * as Elevations from '../../../Elevations';

export const StyledList = styled.ul`
  ${Elevations.E150};

  display: inline-flex;
  flex-direction: column;

  border-radius: 6px;

  background-color: ${Colors.FIN.Lt.Emphasis.White};

  padding: 6px;

  opacity: 1;

  transform: none;
  transform-origin: left top;

  transition-property: transform, opacity;
  transition-duration: ${Animation.Settings.Duration.Short};
  transition-timing-function: ${Animation.Settings.Easing.InOutBounce};

  ${({ isOpen }) =>
    !isOpen &&
    css`
      opacity: 0;
      transform: scaleY(0.85);

      pointer-events: none;
    `};
`;
