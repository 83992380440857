import { RefObject, useCallback, useLayoutEffect, useState } from 'react';
import useEffectOnce from 'react-use/esm/useEffectOnce';

const initialState = { width: 0, height: 0, x: 0, y: 0 };

type UseContainerSizeResponse = [
  number,
  number,
  number,
  number,
  (ev?: Event, opt?: { hide?: boolean }) => void,
];

export default function useContainerSize(
  containerRef: RefObject<HTMLElement | undefined>,
): UseContainerSizeResponse {
  const [size, setSize] = useState(initialState);

  const handleResize = useCallback(
    (ev?: Event, opt?: { hide?: boolean }) => {
      if (!containerRef || !containerRef.current) {
        if (opt?.hide) setSize(initialState);
        return;
      }

      const rect = containerRef.current.getBoundingClientRect();
      const { width, height } = rect;
      const y = rect.top || rect.y;
      const x = rect.left || rect.x;
      setSize({ width, height, x, y });
    },
    [containerRef],
  );

  useLayoutEffect(() => {
    // Set the initial dimensions in a layout effect so that it's measured by
    // the time the first render happens.
    const tm = setTimeout(handleResize, 100);
    return () => {
      clearTimeout(tm);
    };
    // Only run on initial render.
  }, []);

  useEffectOnce(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return [size.width, size.height, size.y, size.x, handleResize];
}
