import styled, { css } from 'styled-components';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';

import { InputWrapper } from '../../Input/styles';
import { StyledTab, TabLabel } from '../../Tab/Tab';

export const StyledModalHeader = styled.div<{ withCloseIcon?: boolean }>`
  background-color: ${(props) => props.color};
  color: ${(props) => props.theme.textColorFor(props.color)};
  padding: 24px 70px 3px 32px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  box-sizing: border-box;
  border-radius: 10px 10px 0 0;

  ${(p) =>
    p.withCloseIcon &&
    css`
      align-items: center;
      justify-content: space-between;
    `}

  ${StyledTab} {
    font-size: 16px;
  }

  ${TabLabel} {
    font-weight: 500;
  }

  ${InputWrapper} {
    padding-left: 0;
  }
`;

export const TopGreySection = styled.div`
  background-color: ${Colors.TCore.Surface.Surf2};
  padding: 20px 16px 24px;
  margin: 0 -16px 20px;
  border-radius: 6px;
`;

export const StyledModalEmptyMsg = styled.div`
  margin: auto;
  color: ${(p) => p.theme.blueGrey};
  text-align: center;
  line-height: 150px;
`;
