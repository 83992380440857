import {
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

/**
 * This is meant to mimic useState but with the ability
 * to pass a callback individually for each state update.
 *
 * We could use a useEffect, but that would run for every
 * state update, making it difficult to target behaviours to
 * specific updates.
 *
 * See for example the scenario, where you have an input field (which
 * value is mapped to state) and a button to clear to empty the input field.
 * The button onClick would setValue(''), and now you need to focus the input field.
 * The cleanest way to ensure you focus the input field only after it has been updated
 * from the "Clear" button is to be able to provide a callback to the setValue function,
 * like in React classes setState:
 *
 *   setState('', () => { input.focus(); })
 */

type DispatchWithCallback<S> = (
  value: SetStateAction<S>,
  callback?: Callback<S>,
) => void;
type Callback<S> = (state: S) => void;

function useStateCallback<S>(
  initialState: S | (() => S),
): [S, DispatchWithCallback<S>] {
  const [state, setState] = useState(initialState);
  const cbRef = useRef<((state: S) => void) | null>(null); // init mutable ref container for callbacks

  const setStateCallback = useCallback<DispatchWithCallback<S>>((state, cb) => {
    cbRef.current = cb ?? null; // store current, passed callback in ref
    setState(state);
  }, []); // keep object reference stable, exactly like `useState`

  useEffect(() => {
    // cb.current is `null` on initial render,
    // so we only invoke callback on state *updates*
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null; // reset callback after execution
    }
  }, [state]);

  return [state, setStateCallback];
}

export { useStateCallback };
