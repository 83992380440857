import styled from 'styled-components';

import * as Animation from '@float/ui/deprecated/Earhart/Animation';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';
import { IconKebabDots } from '@float/ui/icons/essentials/IconKebabDots';

export const StyledProjectTask = styled.li`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  height: 58px;

  border-bottom: 1px solid ${Colors.FIN.Lt.Stroke.Stroke1};

  overflow: hidden;
`;

export const StyledName = styled.div`
  ${Typography.TextL.R400};

  position: relative;

  color: ${Colors.FIN.Lt.Emphasis.High};

  padding: 0 20px 0 28px;

  white-space: nowrap;

  text-overflow: ellipsis;

  overflow: hidden;

  z-index: 1;
`;

export const StyledHr = styled.hr`
  position: absolute;

  top: 50%;
  left: 0;

  display: block;

  width: 100%;
  height: 12px;

  border-radius: 6px;

  padding: 0;
  margin: 0;

  background-color: ${({ $primary }) =>
    $primary ? Colors.FIN.Lt.Emphasis.Primary : Colors.Primary.Flay.Light[3]};

  transform: translateY(-50%);

  transition-property: background-color;
  transition-duration: ${Animation.Settings.Duration.Normal};
  transition-delay: ${({ $primary }) => ($primary ? '0.325s' : '0s')};
  transition-timing-function: ${Animation.Settings.Easing.DefaultOut};

  z-index: 0;
`;

export const StyledIconKebabDots = styled(IconKebabDots)`
  --svg-icon-color: ${Colors.Primary.Flay.Light[8]};

  position: absolute;

  right: 0;
`;
