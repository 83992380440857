import { Offset } from '@float/types';

import { FormType, ProjectFormData, ProjectTaskRecord } from '../types';

export const DEFAULT_PHASE_DURATION = 28;
export const DEFAULT_MILESTONE_DURATION = 1;
export const DEFAULT_OFFSET = 0;

export function validateTaskName(
  index: number,
  value: ProjectTaskRecord['task_name'],
  tasks: ProjectTaskRecord[],
) {
  const normalizedValue = value.toLowerCase();
  const i = tasks.findIndex(
    (t: ProjectTaskRecord) => t.task_name.toLowerCase() === normalizedValue,
  );
  return i === index;
}

export type GetMinDurationProps = {
  initial?: Offset;
  phases?: Offset[];
  milestones?: Offset[];
};

export function getMinimumRequiredDuration(props: GetMinDurationProps) {
  const largest = {
    parent_offset: props.initial?.parent_offset ?? 0,
    duration: props.initial?.duration ?? 1,
  };

  const updateLargest = (
    entity: Offset,
    defaultDuration: number = DEFAULT_MILESTONE_DURATION,
  ) => {
    const offset = entity.parent_offset ?? 0;
    const duration = entity.duration ?? defaultDuration;

    if (offset + duration > largest.parent_offset + largest.duration) {
      largest.parent_offset = offset;
      largest.duration = duration;
    }
  };

  props.phases?.forEach((phase) =>
    updateLargest(phase, DEFAULT_PHASE_DURATION),
  );
  props.milestones?.forEach(updateLargest);

  return largest.parent_offset + largest.duration;
}

function doPhasesOrMilestonesHaveOffsets({
  milestones,
  phases,
}: {
  milestones: ProjectFormData['milestones'];
  phases: ProjectFormData['phases'];
}) {
  return (
    milestones.some((m) => m.duration || m.parent_offset) ||
    phases.some((p) => p.duration || p.parent_offset)
  );
}

// When a project is created from a template, if it has a duration set (e.g. 100 days)
// or if it has phases or milestones (which would also imply a duration is set), then
// we require the project start date to derive the other dates. However, if user creates
// a template without any phases, milestones, or duration set, BE sets the duration to
// 1 day by default. The following check ensures that we don't mandate a date if project
// is being created from a template of this kind.
export function shouldRequireProjectDates(props: ProjectFormData) {
  const { project, milestones, phases, type } = props;

  // ensure we're in project form
  if (type !== FormType.Project) return false;

  // ensure template is being used to create a project
  if (typeof project.duration !== 'number' || !project.duration) return false;

  return (
    // template being used has a custom duration set
    project.duration > 1 ||
    // template being used has at least one phase or milestone
    doPhasesOrMilestonesHaveOffsets({ milestones, phases })
  );
}
