import React from 'react';
import { getTimeAgoCompact } from '@floatschedule/activity-format-npm';
import { format } from 'date-fns';

import { TimeFormatPreference } from '@float/types/timeFormatPreference';

interface Props {
  timestamp: number;
  className?: string;
  timeFormat?: TimeFormatPreference;
}

export const TimeAgo = (props: Props) => {
  const { className, timeFormat, timestamp } = props;

  const is24HourFormat =
    timeFormat === TimeFormatPreference.TwentyFourHourFormat;

  const hourFormat = is24HourFormat ? 'HH:mm' : 'h:mma';
  const timeAgo = getTimeAgoCompact(timestamp);
  const title = format(new Date(timestamp), `MMM dd yyyy 'at' ${hourFormat}`);

  return (
    <span className={className} title={title}>
      {timeAgo}
    </span>
  );
};
