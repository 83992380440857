import { SearchAutocompleteQueryItem } from '.';
import { Candidate } from './types';

type TruncateOptions = {
  key: string;
  candidates: SearchAutocompleteQueryItem[];
  expandedCategory?: string;
  maxPerCategory: number;
};

const truncatePeopleOrProjects = (options: TruncateOptions) => {
  const { candidates, maxPerCategory } = options;

  const data = candidates as Extract<
    Candidate,
    { type: 'person' | 'project' }
  >[];

  // People and projects have an active status indicating whether or not
  // the entity is archived. For these two keys, we always want to suppress
  // archived results unless the filtered results are exclusively archived
  const hasActiveRecords = data.some((c) => c.isActive);
  const indexFirstArchived = data.findIndex((c) => !c.isActive);
  if (hasActiveRecords && indexFirstArchived > -1) {
    // Note: We assume that candidates are already sorted (active first)
    return {
      data: candidates,
      truncateAt: Math.min(indexFirstArchived, maxPerCategory),
    };
  }

  return { data, truncateAt: maxPerCategory };
};

const truncateDepartments = (options: TruncateOptions) => {
  const { candidates, maxPerCategory } = options;

  let maxDepartmentsPerCategory = maxPerCategory;

  // When truncating, we need to make sure we don't separate
  // a sub-department from its parent deparment

  const departmentBeforeMax = candidates[maxPerCategory - 1] as
    | Extract<Candidate, { type: 'department' }>
    | undefined;
  const departmentAtMax = candidates[maxPerCategory] as
    | Extract<Candidate, { type: 'department' }>
    | undefined;

  const isSubDepartmentAtMax = Boolean(departmentAtMax?.parent_id);
  const isParentDepartmentBeforeMax = Boolean(
    departmentAtMax?.parent_id === departmentBeforeMax?.id,
  );

  if (isSubDepartmentAtMax && isParentDepartmentBeforeMax) {
    maxDepartmentsPerCategory = maxDepartmentsPerCategory + 1;
  }

  return { data: candidates, truncateAt: maxDepartmentsPerCategory };
};

export const truncateCandidates = (options: TruncateOptions) => {
  const { candidates, expandedCategory, key, maxPerCategory } = options;

  // do not truncate if candidates are inferior to max or it's current expanded category
  const areCandidatesExceedingMax = candidates.length > maxPerCategory;
  const isExpandedCategory = expandedCategory === key;

  if (!areCandidatesExceedingMax || isExpandedCategory) return candidates;

  let result = { data: candidates, truncateAt: maxPerCategory };

  if (key === 'people' || key === 'projects') {
    result = truncatePeopleOrProjects(options);
  }

  if (key === 'departments') {
    result = truncateDepartments(options);
  }

  return result.data.slice(0, result.truncateAt);
};
