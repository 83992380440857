import React, { useState } from 'react';
import { Trans } from '@lingui/macro';

import { NavIconBtn } from '@float/common/components/NavIconBtn';
import IconSearchFilter from '@float/ui/deprecated/Earhart/Icons/Icon/IconSearchFilter';
import { Popover } from '@float/ui/deprecated/Tooltip/Popover';
import { SEARCH_AUTOCOMPLETE_ID } from '@float/web/searchV2/components/SearchAutocomplete.constants';

import { SearchFilterTokensProps } from '../types';
import { ResponsiveFilterTokensContent } from './ResponsiveFilterTokensContent';

export const ResponsiveFilterTokens = (props: SearchFilterTokensProps) => {
  const { filters } = props;

  const [open, setOpen] = useState(false);

  function handleOpenChange(open: boolean) {
    setOpen(open);

    if (!open) {
      props.closeAllDropdowns();
    }
  }

  // This is a hack to prevent the popover from closing when clicking on the SearchAutocomplete
  // @TODO(FT-1834) Remove this when migrating the SearchAutocomplete to Radix
  function handleOutsideInteraction(e: CustomEvent) {
    const target = e.target;

    if (!target) return;

    const autocomplete = document.getElementById(SEARCH_AUTOCOMPLETE_ID);

    if (autocomplete?.contains(target as Node)) {
      e.preventDefault();
    }
  }

  let filtersCount = filters.length;

  if (props.shouldRenderMeFilter) {
    filtersCount++;
  }

  return (
    <Popover
      content={<ResponsiveFilterTokensContent {...props} />}
      arrow={false}
      distance={10}
      onOpenChange={handleOpenChange}
      onFocusOutside={handleOutsideInteraction}
      onPointerDownOutside={handleOutsideInteraction}
      onContentClick={props.closeAllDropdowns}
      open={open}
      zIndex={1}
    >
      <NavIconBtn isPrimary={false} iconLeft={<IconSearchFilter />}>
        {filtersCount ? (
          <Trans>Filter ({filtersCount})</Trans>
        ) : (
          <Trans>Filter</Trans>
        )}
      </NavIconBtn>
    </Popover>
  );
};
