import React, { useEffect, useRef } from 'react';

import { moment } from '@float/libs/moment';
import { RepeatState } from '@float/types/repeatState';
import { DatePicker } from '@float/ui/deprecated';

import * as styles from '../../InputAllocationRepeat.css';

export const InputAllocationRepeatEndDatePicker = ({
  getIsDateDisabled,
  minRepeatDate,
  repeatEndDate,
  repeatState,
  onChange,
  isAutofocusEnabled,
}: {
  getIsDateDisabled: (date: Moment) => boolean;
  minRepeatDate: Date;
  onChange: (repeatEnd: Moment) => void;
  repeatEndDate: Date | null;
  repeatState: RepeatState;
  isAutofocusEnabled: boolean;
}) => {
  const ref = useRef<DatePicker>(null);

  // When the repeat state is changing we need to trigger
  // open the calendar
  useEffect(() => {
    // There a previous repeat state,
    if (isAutofocusEnabled) {
      ref.current?.focusInput();
    }
  }, [isAutofocusEnabled, repeatState]);

  return (
    <div className={styles.repeatEndDateContainer}>
      <DatePicker
        ref={ref}
        inputClassname={styles.repeatEndDateInput}
        disableDayFn={getIsDateDisabled}
        value={moment(repeatEndDate)}
        minimumDate={minRepeatDate}
        onChange={onChange}
      />
    </div>
  );
};
