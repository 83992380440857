import { sortByDateAndName } from '@float/common/lib/itemSort';
import { getTaskMetasOptions } from '@float/common/selectors/taskMetas';
import { AppStore } from '@float/common/store';
import { TaskMeta } from '@float/types/task';
import {
  getPhasesByProjectId,
  getProjectById,
  getProjectMilestonesByProjectId,
} from '@float/web/selectors';

import {
  FormType,
  ProjectFormInitialData,
  ProjectIdOrTemplateId,
} from '../types';

export function getProjectData(
  store: AppStore,
  ids?: ProjectIdOrTemplateId,
): ProjectFormInitialData {
  const state = store.getState();
  const projectId = ids?.projectId;

  const project = getProjectById(state, projectId);
  const phases = getPhasesByProjectId(state, projectId);
  const milestones = sortByDateAndName(
    getProjectMilestonesByProjectId(state, projectId),
    'date',
    'name',
  );
  const tasks = getTaskMetasOptions(
    state.taskMetas.taskMetas,
    projectId,
    undefined,
    null,
  );

  return {
    type: FormType.Project,
    project,
    phases,
    milestones,
    tasks: tasks as TaskMeta[],
    templateId: ids?.templateId,
  };
}
