import { useEffect } from 'react';
import { VirtualItem } from '@tanstack/react-virtual';

export function useSearchFilterInfiniteScroll(params: {
  data: unknown[];
  isLoading?: boolean;
  fetchNextPage?: () => void;
  virtualItems: VirtualItem[];
}) {
  const { data, isLoading, fetchNextPage, virtualItems } = params;

  const lastItemIndex = virtualItems.at(-1)?.index;

  useEffect(() => {
    if (lastItemIndex === undefined) {
      return;
    }

    if (lastItemIndex >= data.length - 1 && !isLoading) {
      fetchNextPage?.();
    }
  }, [fetchNextPage, data.length, isLoading, lastItemIndex]);
}
