import styled from 'styled-components';

import { CheckSvg } from './CheckSvg';

export const StyledIllustration = styled.div`
  position: relative;
`;

export const StyledCheckSVG = styled(CheckSvg)`
  position: absolute;

  width: 53px;
  height: 44px;

  &:nth-child(1) {
    top: 83px;
    left: 3px;
  }

  &:nth-child(2) {
    top: 0px;
    left: 205px;
  }

  &:nth-child(3) {
    top: 232px;
    left: 201px;
  }

  z-index: 1;
`;
