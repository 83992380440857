import React, { useMemo } from 'react';
import styled from 'styled-components';

import { DATE_FORMAT } from '@float/libs/dates';
import { moment } from '@float/libs/moment';

const Wrapper = styled.div`
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 13px;
  padding-right: 16px;
  background-color: ${(p) => p.theme.orangeBg};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Text = styled.span`
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
  color: ${(p) => p.theme.orangeText};
`;

export const AssociatedChanges = ({
  task,
  startDate,
  endDate,
  peopleId,
  getAssociatedChangesForEntity,
  getExportedTaskCopiedFromState,
}) => {
  // User can skip warning for future updates
  const isHidden = useMemo(() => {
    const originalTaskData = getExportedTaskCopiedFromState.call(this, {
      ...task,
    });

    const changes = getAssociatedChangesForEntity({
      ...originalTaskData,
      status: 2,
      start_date: moment(startDate).format(DATE_FORMAT),
      end_date: moment(endDate).format(DATE_FORMAT),
      people_id: peopleId,
      isTimeoff: true,
    });
    return !changes.length;
  }, [
    task,
    startDate,
    endDate,
    peopleId,
    getAssociatedChangesForEntity,
    getExportedTaskCopiedFromState,
  ]);

  if (isHidden) {
    return null;
  }

  return (
    <Wrapper>
      <div>
        <Text>⚠️</Text>
        <Text>Scheduled tasks already exist on this day</Text>
      </div>
    </Wrapper>
  );
};
