import React, { useEffect, useMemo } from 'react';
import { filter, map, some } from 'lodash';
import styled from 'styled-components';

import { unlink } from '@float/common/serena/util/linkedTasks';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { getIsDebugTaskNamesEnabled } from '@float/libs/web/devtools/debugTaskNames';
import { Icons } from '@float/ui/deprecated';

import { NoTaskAllocation } from './NoTaskAllocation';

const Line = styled.div`
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.charcoalGrey};
  font-size: 14px;
  height: 34px;

  margin-left: ${(p) => (p.offset == 2 && p.isChild ? 26 : p.offset * 8)}px;

  &.sz-large {
    font-size: 16px;
    font-weight: bold;
    margin-left: ${(p) => p.offset * 5}px;
  }
`;

const Square = styled.div`
  background-color: #${(p) => p.color};
  border-radius: 2.3px;
  width: 12px;
  height: 12px;
  margin-right: 10px;
  z-index: 1;

  .sz-large & {
    width: 18px;
    height: 18px;
    border-radius: 3.4px;
  }
`;

const Connector = styled.div`
  border-bottom: 2px solid #e1e1e1;
  border-left: 2px solid #e1e1e1;
  height: 34px;
  width: 11px;
  transform: translate(0px, -16px);

  svg {
    transform: translate(-7px, ${(p) => (p.isSibling ? 9 : 12)}px);
  }

  .sz-large & {
    width: 12px;

    svg {
      transform: translate(-6px, 9px);
    }
  }

  svg.icon-close-small {
    background: #cccccc;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
      background: black;
    }
  }
`;

const ImpliedConnector = styled.div`
  position: absolute;
  border: 0;
  border-style: inset;
  border-bottom: 2px dotted #e1e1e1;
  height: 1px;
  width: 6px;
  transform: ${(p) =>
    p.vertical
      ? 'translate(14.5px,9px) rotate(90deg)'
      : 'translate(-6px, -1px)'};
`;

function getTaskChain({ tasks, phases, projects, ignoreTaskIds }, taskId) {
  function getColor(task) {
    if (!task) return '';
    return phases?.[task.phase_id]?.color || projects[task.project_id].color;
  }

  if (!tasks[taskId]) {
    // The task is process of being deleted - the optimistic update removes it
    // from the Redux store
    return {};
  }

  const self = {
    task: tasks[taskId],
    color: getColor(tasks[taskId]),
    offset: 0,
  };

  const parent =
    self.task.root_task_id == taskId
      ? null
      : {
          task: tasks[self.task.parent_task_id],
          color: getColor(tasks[self.task.parent_task_id]),
        };

  if (parent) {
    self.offset++;
  }

  const children = map(
    filter(tasks, (t) => t.parent_task_id == taskId),
    (task) => {
      return { task, color: getColor(task) };
    },
  );

  children.forEach((child) => {
    child.hasChild = some(tasks, (t) => t.parent_task_id == child.task.task_id);
  });

  return { self, parent, children };
}

function Row({
  data,
  size = 'normal',
  offset = 0,
  isSibling = false,
  isParent = false,
  isChild = false,
  readOnly = false,
  onConnectorClick,
}) {
  if (!data.task) return null;

  const isRoot = data.task.task_id == data.task.root_task_id;

  return (
    <Line className={`sz-${size}`} offset={offset} isChild={isChild}>
      {offset == 0 ? (
        isRoot ? null : (
          <ImpliedConnector />
        )
      ) : (
        <Connector isRoot={isRoot} isSibling={isSibling} offset={offset}>
          {!readOnly && (
            <Icons.CloseSmall
              fillColor="white"
              size={12}
              onClick={(evt) => {
                prevent(evt);
                if (typeof onConnectorClick === 'function') {
                  onConnectorClick(data.task.task_id);
                }
              }}
            />
          )}
        </Connector>
      )}
      <Square color={data.color} />
      {getIsDebugTaskNamesEnabled()
        ? data.task.task_id
        : data.task.name || <NoTaskAllocation />}
      {isChild && data.hasChild ? <ImpliedConnector vertical /> : null}
    </Line>
  );
}

export default function TaskChain({
  ctx: originalCtx,
  taskId,
  unlinkTask,
  onAllUnlinked,
  readOnly,
}) {
  // Since ctx.tasks comes from a Redux selector, and we only want these pending
  // changes to visually show in this component, we want to copy them before
  // applying the adjustments.

  const ctx = useMemo(() => {
    // shallow clone of the tasks
    const tasks = Object.fromEntries(
      Object.entries(originalCtx.tasks).map(([id, task]) => [id, { ...task }]),
    );

    unlink(tasks, originalCtx.ignoreTaskIds);

    return {
      ...originalCtx,
      tasks,
    };
  }, [originalCtx]);

  const chain = getTaskChain(ctx, taskId);

  useEffect(() => {
    if (!chain.parent && !chain.children.length) {
      onAllUnlinked?.();
    }
  }, [chain.parent, chain.children, onAllUnlinked]);

  return (
    <>
      {chain.parent && (
        <Row
          onConnectorClick={unlinkTask}
          data={chain.parent}
          offset={0}
          isParent
          readOnly={readOnly}
        />
      )}

      <Row
        size="large"
        onConnectorClick={unlinkTask}
        data={chain.self}
        offset={chain.self.offset}
        readOnly={readOnly}
      />

      {chain.children.map((child, idx) => (
        <Row
          key={child.task.task_id}
          onConnectorClick={unlinkTask}
          offset={chain.self.offset + 1}
          isSibling={idx > 0}
          isChild
          data={child}
          readOnly={readOnly}
        />
      ))}
    </>
  );
}
