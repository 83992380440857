import { useCallback, useMemo } from 'react';

import {
  hasMoreThanThreeDecimalPlaces,
  hasMoreThanTwoDecimalPlaces,
} from '@float/libs/utils/number/decimals';

const MAX_DAYS = 365;
const MIN_DAYS = 0;

export const useDaysInputFormat = (
  days,
  { max = MAX_DAYS, min = MIN_DAYS, use3Digits } = {},
) => {
  const handleChange = useCallback(
    (e) => {
      let val = e.currentTarget.value;
      const checkDecimalPlaces = use3Digits
        ? hasMoreThanThreeDecimalPlaces
        : hasMoreThanTwoDecimalPlaces;
      if (checkDecimalPlaces(val)) {
        return;
      }
      if (+val > max) {
        val = max.toString();
      }

      days.onChange({ value: val });
    },
    [days, max, use3Digits],
  );

  const handleBlur = useCallback(() => {
    if (/.?(\.|,)(0{1,2})?$/.test(days.value)) {
      days.onChange({
        value: days.value
          .replace(use3Digits ? /(\.|,)(0{1,2,3})?$/ : /(\.|,)(0{1,2})?$/, '')
          .replace(
            use3Digits ? /^(\.|,)(0{1,2,3})?$/ : /^(\.|,)(0{1,2})?$/,
            `${min}`,
          ),
      });
      return;
    }
    if (days.value === '') {
      days.onChange({ value: `${min}` });
      return;
    }
    if (parseFloat(days.value) < min) {
      days.onChange({ value: `${min}` });
      return;
    }
  }, [days, min, use3Digits]);

  return useMemo(() => {
    return {
      handleChange,
      handleBlur,
    };
  }, [handleChange, handleBlur]);
};
