import { serialize } from './common/serialize';
import { send } from './send';

export function del(url, args, opts = {}) {
  if (!opts) opts = {};
  const params = {
    method: 'delete',
    headers: { ...opts.headers },
  };
  const st = url.indexOf('?') >= 0 ? '&' : '?';
  if (args) {
    url += `${st}${serialize(args)}`;
  }
  if (args) {
    params.headers.Accept = 'application/json, text/javascript, */*; q=0.01';
  }
  return send(url, params, opts);
}
