import { union } from 'lodash';

import { logger } from '@float/libs/logger';
import { Timeoff } from '@float/types/timeoff';

import { getTempId } from '../../../lib/scheduleUtils';
import { getEntityMeta, setEntityMeta } from './entityMeta';
import { GetAction, UseCellsReducerProps, UseCellsState } from './types';

export function split(
  props: UseCellsReducerProps,
  state: UseCellsState,
  action: GetAction<'SPLIT'>,
): UseCellsState {
  const {
    dates,
    maps,
    bimaps,
    cellHelpers: { buildCells, loadData, findWorkDay, getApplicableCellKeys },
  } = props;
  const { cells } = state;

  const { item, duplicateItem: dupItem } = action;
  let { day } = action;

  if (day && !maps[dupItem.type][dupItem.entityId]) {
    loadData(cells, {
      type: 'LOAD_DATA',
      dataType: dupItem.type,
      data: {
        [getEntityMeta(dupItem.entity, 'temporaryId')!]: dupItem.entity,
      },
      ignoreMissing: true,
    });
  }

  const entity = maps[item.type][item.entityId];
  const dupEntity = dupItem && maps[dupItem.type][dupItem.entityId];

  // The user most likely hovered on a split chunk that hasn't yet been
  // saved to the DB and thus does not exist in this map. Prevent the
  // error, but don't try to recover (too complicated).
  if (!entity) return state;

  let affectedCellKeys: string[] = [];

  if (day === entity.start_date) {
    // While we usually split to the left of the user's cursor, we split to
    // the right if they're hovering on the very first day.
    day = dates.addDays(day, 1);
  }

  const hasSplit = day && day > entity.start_date;

  if (hasSplit) {
    setEntityMeta(entity, 'isSplitting', true);
    entity.end_date = findWorkDay(
      cells,
      item.rowId,
      dates.addDays(day, -1),
      'L',
      item.type === 'timeoff' ? (entity as Timeoff) : undefined,
    );

    setEntityMeta(dupEntity, 'isSplitting', true);
    dupEntity.start_date = findWorkDay(
      cells,
      item.rowId,
      day,
      'R',
      item.type === 'timeoff' ? (entity as Timeoff) : undefined,
    );

    if (dupEntity.start_date > dupEntity.end_date) {
      dupEntity.start_date = dupEntity.end_date;
    }

    const newCellKeys = getApplicableCellKeys(entity);
    bimaps[item.type].replace(item.entityId, newCellKeys);

    const newDupCellKeys = getApplicableCellKeys(dupEntity);
    bimaps[dupItem.type].replace(dupItem.entityId, newDupCellKeys);

    affectedCellKeys = union(affectedCellKeys, newCellKeys, newDupCellKeys);
  } else if (dupEntity) {
    setEntityMeta(entity, 'isSplitting', false);
    entity.end_date = dupEntity.end_date;

    const newCellKeys = getApplicableCellKeys(entity);
    bimaps[item.type].replace(item.entityId, newCellKeys);

    delete maps[dupItem.type][dupItem.entityId];
    const oldDupCellKeys = bimaps[dupItem.type].delete(dupItem.entityId);

    affectedCellKeys = union(affectedCellKeys, newCellKeys, oldDupCellKeys);
  }

  buildCells(cells, affectedCellKeys, { useSplitSort: hasSplit });

  return { ...state, cells };
}

export function splitConfirm(
  props: UseCellsReducerProps,
  state: UseCellsState,
  action: GetAction<'SPLIT_CONFIRM'>,
): UseCellsState {
  const { maps } = props;
  const { cells } = state;

  const { item, duplicateItem: dupItem } = action;

  const entity = maps[item.type][item.entityId];
  const dupEntity = maps[dupItem.type][dupItem.entityId];
  const undoBatchId = getTempId();

  if (!dupEntity.start_date) {
    // Let's track this exception to investigate https://rollbar.com/float/fe-web-app/items/5093/
    logger.error('The split date is missing', {
      context: {
        entity,
        dupEntity,
      },
    });
  }

  return {
    ...state,
    cells,
    pendingChanges: [],
    changes: [
      ...state.pendingChanges,
      {
        type: item.type,
        id: item.entityId,
        entity,
        originalEntity: action.originalEntity,
        undoBatchId,
      },
      {
        type: dupItem.type,
        id: dupItem.entityId,
        entity: dupEntity,
        isCreate: true,
        isSplit: true,
        undoBatchId,
      },
    ],
  };
}
