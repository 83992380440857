import React, { useMemo } from 'react';

import Body from '../Body';
import { SUPPORT_ARTICLES } from '../constants';
import { PlanUpsellBanner } from '../PlanUpsellBanner';
import RestrictedMessage from '../RestrictedMessage';
import DomainRestrictionSection from './DomainRestictionSection';
import SamlAuthenticationSection from './SamlAuthenticationSection';
import SignInWithGoogleRequiredSection from './SignInWithGoogleRequiredSection';

const Security = ({ userCanSee }) => {
  const planUpsellBanner = useMemo(
    () => (
      <PlanUpsellBanner
        header="Need more security controls?"
        subheader="Try our Pro plan now"
        canModifySubheader
        learnMoreUrl={SUPPORT_ARTICLES.security}
      />
    ),
    [],
  );

  if (!userCanSee.security) {
    return <RestrictedMessage />;
  }

  return (
    <Body
      header="Security"
      subheader="Control how your team signs in to their Float account."
      planUpsellBanner={planUpsellBanner}
    >
      <SamlAuthenticationSection />
      <SignInWithGoogleRequiredSection />
      <DomainRestrictionSection />
    </Body>
  );
};

export default Security;
