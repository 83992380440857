import { uniqBy } from 'lodash';
import { Action } from 'redux';

import { Timer } from '@float/types';

import {
  TIMER_CREATE,
  TIMER_DELETE,
  TIMER_FETCH_BEGIN,
  TIMER_FETCH_SUCCESS,
  TIMER_START,
  TIMER_STOP,
  TIMER_UPDATE,
  TIMER_WARNING_HIDE,
  TIMER_WARNING_SHOW,
  TimerCreateAction,
  TimerDeleteAction,
  TimerFetchBeginAction,
  TimerFetchedRange,
  TimerFetchErrorAction,
  TimerFetchSuccessAction,
  TimerStartAction,
  TimerStopAction,
  TimerUpdateAction,
} from '../actions';
import { getMaxDate } from '../lib/timer/getMaxDate';
import { getMinDate } from '../lib/timer/getMinDate';

export const DEFAULT_STATE = {
  fetchedRange: { start_date: null, end_date: null },
  timers: [],
  loadState: 'UNLOADED' as const,
  warning: null,
};

export type TimerState = {
  timers: Timer[];
  loadState: 'UNLOADED' | 'LOADED' | 'LOADING' | 'LOAD_FAILED';
  fetchedRange: TimerFetchedRange;
  warning: string | null;
};

type TimerActions =
  | TimerFetchBeginAction
  | TimerFetchErrorAction
  | TimerFetchSuccessAction
  | TimerStartAction
  | TimerStopAction
  | TimerCreateAction
  | TimerUpdateAction
  | TimerDeleteAction
  | Action<typeof TIMER_WARNING_HIDE>
  | { type: typeof TIMER_WARNING_SHOW; warning: string };

const timer = (state: TimerState = DEFAULT_STATE, action: TimerActions) => {
  switch (action.type) {
    case TIMER_FETCH_BEGIN:
      return {
        ...state,
        loadState: 'LOADING' as const,
      };

    case TIMER_START: {
      const { timer } = action;

      return {
        ...state,
        loadState: 'LOADED' as const,
        timers: [...state.timers, timer],
      };
    }

    case TIMER_CREATE: {
      const { timer } = action;

      return {
        ...state,
        timers: [...state.timers, timer],
      };
    }

    case TIMER_UPDATE: {
      const { timer } = action;

      const timers = state.timers.map((t) => {
        return t._id === timer._id ? timer : t;
      });

      return {
        ...state,
        timers,
      };
    }

    case TIMER_DELETE: {
      const { timerId } = action;

      const timers = state.timers.filter((t) => {
        return t._id !== timerId;
      });

      return {
        ...state,
        timers,
      };
    }

    case TIMER_FETCH_SUCCESS: {
      const { fetchedRange: currentFetchedRange } = state;
      const { timers, shouldRefresh, fetchedRange: newFetchedRange } = action;

      // Save fetchedRange
      const fetchedRange = {
        start_date: getMinDate(
          currentFetchedRange?.start_date,
          newFetchedRange?.start_date!,
        ),
        end_date: getMaxDate(
          currentFetchedRange?.end_date,
          newFetchedRange?.end_date!,
        ),
      };

      if (shouldRefresh) {
        return {
          ...state,
          fetchedRange,
          loadState: 'LOADED' as const,
          timers,
        };
      }

      return {
        ...state,
        fetchedRange,
        loadState: 'LOADED' as const,
        // TODO replace the timers data structure into a map, to make the duplicate check faster
        // https://linear.app/float-com/issue/FT-1510/performance-store-the-timers-state-inside-a-record
        timers: uniqBy([...state.timers, ...timers], (timer) => timer._id),
      };
    }

    case TIMER_STOP: {
      const { timerId, timer } = action;

      return {
        ...state,
        loadState: 'LOADED' as const,
        timers: state.timers.filter((t) => t._id !== timerId).concat(timer),
      };
    }

    case TIMER_WARNING_SHOW: {
      const { warning } = action;

      return {
        ...state,
        warning,
      };
    }

    case TIMER_WARNING_HIDE: {
      return {
        ...state,
        warning: null,
      };
    }

    default:
      return state;
  }
};

export { timer };
