import React from 'react';
import styled from 'styled-components';

import { StyledTd } from './styles';

const Swatch = styled.span`
  position: relative;

  display: ${({ isBlock }) => (isBlock ? 'block' : 'inline-block')};
  flex: 0 0 auto;

  width: 20px;
  height: 20px;

  border-radius: 50%;

  margin: ${({ grouped }) => (grouped ? '2px' : '0')};

  background-color: #${({ color }) => color};

  cursor: ${({ isStatic }) => (isStatic ? 'default' : 'pointer')};

  pointer-events: ${({ isStatic }) => (isStatic ? 'none' : 'all')};
`;

const ColorNameTableCell = ({ cell = [] }) => (
  <StyledTd>
    <Swatch color={cell[0]} isStatic={true} />
    <span>{cell[1]}</span>
  </StyledTd>
);

export default ColorNameTableCell;
