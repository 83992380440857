import { useEffect } from 'react';

import { trackEvent } from '@float/common/lib/gtm';
import { Notification, NotificationTypes } from '@float/types/notification';

export const useNotificationsPanelAnalytics = (
  notifications: Notification<NotificationTypes>[],
) => {
  // Track notification feed open
  useEffect(() => {
    const unseen = notifications.filter((n) => n.unseen);

    if (unseen && unseen.length) {
      trackEvent('Notification feed opened', {
        unseen: unseen ? unseen.length : 0,
      });
    }
  }, [notifications]);
};
