import React from 'react';
import { createPortal } from 'react-dom';

import { StyledModal, StyledModalBg } from './styles';

export default function ModalBg() {
  return createPortal(
    <StyledModal>
      <StyledModalBg />
    </StyledModal>,
    document.body,
  );
}
