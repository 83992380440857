import { createSelector } from 'reselect';

import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { getSearchFilteredPeopleIds } from '@float/common/search/selectors/people';
import { getFullLoggedTimesList } from '@float/common/selectors/loggedTimes';
import { FullLoggedTime } from '@float/types/loggedTime';

import { TimeRangeInsightsParams } from '../types';

export const getTimeRangeLoggedTimesByPerson = createSelector(
  [
    getFullLoggedTimesList,
    getSearchFilteredPeopleIds,
    (_: ReduxStateStrict, params: Omit<TimeRangeInsightsParams, 'type'>) =>
      params,
  ],
  (loggedTimes, peopleIds, { startDate, endDate }) => {
    const result = new Map<number, FullLoggedTime[]>();

    for (const loggedTime of loggedTimes) {
      const dateInRange =
        loggedTime.date >= startDate && loggedTime.date <= endDate;

      // Since task suggestions checks rely on the reference date we have to include
      // all the logged times that have a `reference_date` inside the selected time range
      const referenceDateInRange =
        loggedTime.reference_date &&
        loggedTime.reference_date >= startDate &&
        loggedTime.reference_date <= endDate;

      if (!dateInRange && !referenceDateInRange) {
        continue;
      }

      const peopleId = loggedTime.people_id;

      if (peopleIds.has(peopleId)) {
        const list = result.get(peopleId);

        if (list) {
          list.push(loggedTime);
        } else {
          result.set(peopleId, [loggedTime]);
        }
      }
    }

    return result;
  },
);
