import React from 'react';
import styled from 'styled-components';

import SUVPeopleSelector from '@float/common/components/SUVPeopleSelector';

const Wrapper = styled.div`
  height: 44px;
  width: 100%;
  background: ${(p) => p.theme.superLightGrey};
`;

export default function TopPersonPicker(props) {
  const { activePeople, suvPersonId, setSuvPersonId } = props;

  return (
    <Wrapper>
      <SUVPeopleSelector
        isMobile
        prefix="Viewing: "
        activePeople={activePeople}
        suvPersonId={suvPersonId}
        setSuvPersonId={setSuvPersonId}
      />
    </Wrapper>
  );
}
