import { StyleRule } from '@vanilla-extract/css';
import { css } from 'styled-components';

const fontFamily =
  'Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji';

export const M500 = css`
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Helvetica,
    Arial,
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 1.36;
  letter-spacing: -0.2px;
`;

export const SB600 = css`
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Helvetica,
    Arial,
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 1.36;
  letter-spacing: -0.2px;
`;

export const VE_M500: StyleRule = {
  fontFamily,
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 28,
  lineHeight: 1.36,
  letterSpacing: -0.2,
};

export const VE_SB600: StyleRule = {
  fontFamily,
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: 28,
  lineHeight: 1.36,
  letterSpacing: -0.2,
};
