import React from 'react';
import { t } from '@lingui/macro';

import { MeatballMenu } from '@float/ui/components/MeatballMenu';

import { useMenuItems } from './hooks/useMenuItems';

import * as styles from '../styles.css';

export function ProjectActionsMenu() {
  const items = useMenuItems();

  return (
    <MeatballMenu
      className={styles.projectHeaderMenuButton}
      vertical
      items={items}
      trigger={{
        label: t`Project options`,
      }}
    />
  );
}
