import React from 'react';
import createClass from 'create-react-class';

import DateRangePickerBemMixin from '../utils/DateRangePickerBemMixin';
import PureRenderMixin from '../utils/PureRenderMixin';

const CalendarHighlight = createClass({
  mixins: [DateRangePickerBemMixin, PureRenderMixin],
  displayName: 'CalendarHighlight',

  render() {
    const { modifier } = this.props;
    const modifiers = { [modifier]: true };
    const states = {};

    return <div className={this.cx({ states, modifiers })} />;
  },
});

export default CalendarHighlight;
