import React from 'react';

export default function ({ size = '24', color = '#E62768', className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className || ''}
      viewBox={`0 0 ${size} ${size}`}
    >
      <g fill="none" fillRule="evenodd">
        <circle cy="10" cx="10" r="10" fill="#fff" />
        <path
          fillRule="nonzero"
          fill={color}
          d="m0 10c0-5.5 4.5-10 10-10s10 4.5 10 10-4.5 10-10 10-10-4.5-10-10zm9-5v7h2v-7h-2zm0 8v2h2v-2h-2z"
        />
      </g>
    </svg>
  );
}
