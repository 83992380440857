import React from 'react';

export default ({ color = 'black', size = 20, style, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      style={style}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.4168 13.9583V15.2083H16.6668V13.9583H15.4168ZM4.5835 15.2083V13.9583H3.3335V15.2083H4.5835ZM4.79183 15.4167C4.67677 15.4167 4.5835 15.3234 4.5835 15.2083H3.3335C3.3335 16.0137 3.98641 16.6667 4.79183 16.6667V15.4167ZM15.4168 15.2083C15.4168 15.3234 15.3236 15.4167 15.2085 15.4167V16.6667C16.0139 16.6667 16.6668 16.0137 16.6668 15.2083H15.4168ZM15.2085 13.75C15.3236 13.75 15.4168 13.8432 15.4168 13.9583H16.6668C16.6668 13.1529 16.0139 12.5 15.2085 12.5V13.75ZM4.79183 12.5C3.98641 12.5 3.3335 13.1529 3.3335 13.9583H4.5835C4.5835 13.8432 4.67677 13.75 4.79183 13.75V12.5Z"
        fill={color}
      />
      <path
        d="M4.1665 12.7083C4.1665 13.0535 4.44633 13.3333 4.7915 13.3333C5.13668 13.3333 5.4165 13.0535 5.4165 12.7083H4.1665ZM5.4165 12.7083V10.8333H4.1665V12.7083H5.4165ZM9.99984 6.25C11.752 6.25 13.2746 7.23322 14.0458 8.67804L14.9608 7.7631C13.9321 6.10455 12.095 5 9.99984 5V6.25ZM9.99984 5C6.77818 5 4.1665 7.61168 4.1665 10.8333H5.4165C5.4165 8.30203 7.46853 6.25 9.99984 6.25V5Z"
        fill={color}
      />
      <path
        d="M9.99984 4.16659V4.99992C10.4601 4.99992 10.8332 4.62682 10.8332 4.16659H9.99984ZM9.99984 4.16659H9.1665C9.1665 4.62682 9.53959 4.99992 9.99984 4.99992V4.16659ZM9.99984 4.16659V3.33325C9.53959 3.33325 9.1665 3.70635 9.1665 4.16659H9.99984ZM9.99984 4.16659H10.8332C10.8332 3.70635 10.4601 3.33325 9.99984 3.33325V4.16659Z"
        fill={color}
      />
      <line
        x1="17"
        y1="5.88388"
        x2="3.88388"
        y2="19"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
      />
      <rect x="4.5" y="12.5" width="5" height="1.25" fill={color} />
      <path d="M13.7202 12.5H15.2202V13.75H12.5L13.7202 12.5Z" fill={color} />
      <rect x="4.7002" y="15.4199" width="2" height="1.25" fill={color} />
      <path
        d="M11.2202 15.4199H15.2202V16.6699H10L11.2202 15.4199Z"
        fill={color}
      />
    </svg>
  );
};
