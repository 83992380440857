import React from 'react';
import calendar from 'calendar';
import createClass from 'create-react-class';
import Immutable from 'immutable';
import styled from 'styled-components';

import { moment } from '@float/libs/moment';
import Icons from '@float/ui/deprecated/Icons';
import Input from '@float/ui/deprecated/Input/Input';
import VirtualSelect from '@float/ui/deprecated/VirtualSelect/VirtualSelect';

import DateRangePickerBemMixin from '../utils/DateRangePickerBemMixin';
import isMomentRange from '../utils/isMomentRange';
import PureRenderMixin from '../utils/PureRenderMixin';

const HeaderContainer = styled.span`
  display: inline-block;
  position: relative;

  ${Input._styles.InputWrapper} {
    min-height: 0 !important;
  }
`;

const HeaderMonth = styled(HeaderContainer)`
  min-width: 30px;
  margin-right: 5px;
`;

const HeaderYear = styled(HeaderContainer)``;

const EmptyIcon = styled.div`
  width: 24px;
  height: 24px;
`;

const virtualSelectProps = {
  height: 300,
  width: 180,
  nonNullable: true,
  noBorder: true,
  noMargin: true,
  autoSize: true,
  hideDropdownIcon: true,
  hideSelectedIcon: true,
  clearInputOnDropdownOpen: false,
  hideUnmatchedOptionsOnFilter: false,
};

const CalendarMonth = createClass({
  mixins: [DateRangePickerBemMixin, PureRenderMixin],
  displayName: 'CalendarMonth',

  getInitialState() {
    const { locale } = this.props;
    this.setLocale(locale);
    return null;
  },

  setLocale(locale) {
    moment.locale(locale);
    this.WEEKDAYS = Immutable.List(moment.weekdays()).zip(
      Immutable.List(moment.weekdaysShort()),
    );
    this.MONTHS = Immutable.List(moment.months());
  },

  componentDidUpdate(prevProps) {
    const { locale } = prevProps;
    if (locale !== this.props.locale) {
      this.setLocale(locale);
    }
  },

  renderDay(date, i) {
    const {
      dateComponent: CalendarDate,
      value,
      highlightedDate,
      highlightedRange,
      hideSelection,
      enabledRange, // eslint-disable-line
      hasLockPeriodAccess, // eslint-disable-line
      ...props
    } = this.props;
    const d = moment(date).locale(this.props.locale);

    let isInSelectedRange;
    let isSelectedDate;
    let isSelectedRangeStart;
    let isSelectedRangeEnd;

    if (
      !hideSelection &&
      value &&
      moment.isMoment(value) &&
      value.isSame(d, 'day')
    ) {
      isSelectedDate = true;
    } else if (
      !hideSelection &&
      value &&
      isMomentRange(value) &&
      value.contains(d)
    ) {
      isInSelectedRange = true;

      isSelectedRangeStart = value.start.isSame(d, 'day');
      isSelectedRangeEnd = value.end.isSame(d, 'day');
    }

    return (
      <CalendarDate
        key={i}
        isToday={d.isSame(moment(), 'day')}
        isDisabled={this.props.isDateDisabled(d)}
        isHighlightedDate={
          !!(highlightedDate && highlightedDate.isSame(d, 'day'))
        }
        isHighlightedRangeStart={
          !!(highlightedRange && highlightedRange.start.isSame(d, 'day'))
        }
        isHighlightedRangeEnd={
          !!(highlightedRange && highlightedRange.end.isSame(d, 'day'))
        }
        isInHighlightedRange={
          !!(highlightedRange && highlightedRange.contains(d))
        }
        isSelectedDate={isSelectedDate}
        isSelectedRangeStart={isSelectedRangeStart}
        isSelectedRangeEnd={isSelectedRangeEnd}
        isInSelectedRange={isInSelectedRange}
        isInLockPeriod={
          this.props.isDateInLockPeriod && this.props.isDateInLockPeriod(d)
        }
        hasLockPeriodAccess={hasLockPeriodAccess && hasLockPeriodAccess(d)}
        lockPeriod={this.props.lockPeriod}
        date={d}
        {...props}
      />
    );
  },

  renderWeek(dates, i) {
    const days = dates.map(this.renderDay);
    return (
      <tr className={this.cx({ element: 'Week' })} key={i}>
        {days.toJS()}
      </tr>
    );
  },

  renderDayHeaders() {
    const { firstOfWeek } = this.props;
    const indices = Immutable.Range(firstOfWeek, 7).concat(
      Immutable.Range(0, firstOfWeek),
    );

    const headers = indices.map(
      function (index) {
        const weekday = this.WEEKDAYS.get(index);
        return (
          <th
            className={this.cx({ element: 'WeekdayHeading' })}
            key={weekday}
            scope="col"
          >
            <abbr title={weekday[0]}>{weekday[1][0]}</abbr>
          </th>
        );
      }.bind(this),
    );

    return (
      <tr className={this.cx({ element: 'Weekdays' })}>{headers.toJS()}</tr>
    );
  },

  handleYearChange({ value }) {
    this.props.onYearChange(parseInt(value, 10), this.props.isEndCalendar);
  },

  getYearOption(year) {
    const { enabledRange, firstOfMonth } = this.props;

    if (year < enabledRange.start.year()) {
      return null;
    }

    if (year > enabledRange.end.year()) {
      return null;
    }

    return {
      label: moment(year, 'YYYY').locale(this.props.locale).format('YYYY'),
      value: year,
      icon: firstOfMonth.year() === year ? <Icons.Tick /> : <EmptyIcon />,
    };
  },

  renderHeaderYear() {
    const { firstOfMonth } = this.props;
    const y = firstOfMonth.year();
    const years = Immutable.Range(y - 5, y).concat(Immutable.Range(y, y + 10));
    const options = years
      .map(this.getYearOption)
      .filter((x) => x)
      .toArray();

    return (
      <HeaderYear>
        {this.props.disableNavigation ? null : (
          <VirtualSelect
            {...virtualSelectProps}
            data-testid="year-dropdown"
            rowLabelSize="large"
            alignOffset={-40}
            width={120}
            value={y}
            options={options}
            onChange={this.handleYearChange}
          />
        )}
      </HeaderYear>
    );
  },

  handleMonthChange({ value }) {
    this.props.onMonthChange(parseInt(value, 10), this.props.isEndCalendar);
  },

  getMonthOption(month, i) {
    const { firstOfMonth, enabledRange } = this.props;
    const year = firstOfMonth.year();
    let disabled = false;

    if (
      moment({ years: year, months: i + 1, date: 1 }).unix() <
      enabledRange.start.unix()
    ) {
      disabled = true;
    }

    const firstOfIteratingMonth = moment({ years: year, months: i, date: 1 });
    if (firstOfIteratingMonth.unix() > enabledRange.end.unix()) {
      disabled = true;
    }

    return {
      label: month,
      value: i,
      selectedLabel: firstOfIteratingMonth
        .locale(this.props.locale)
        .format('MMMM'),
      disabled,
      icon: firstOfMonth.month() === i ? <Icons.Tick /> : <EmptyIcon />,
    };
  },

  renderHeaderMonth() {
    const { firstOfMonth } = this.props;
    const options = this.MONTHS.map(this.getMonthOption).toArray();

    return (
      <HeaderMonth>
        {this.props.disableNavigation ? null : (
          <VirtualSelect
            {...virtualSelectProps}
            data-testid="month-dropdown"
            rowLabelSize="large"
            alignOffset={-35}
            value={firstOfMonth.month()}
            options={options}
            onChange={this.handleMonthChange}
          />
        )}
      </HeaderMonth>
    );
  },

  renderHeader() {
    return (
      <>
        {this.renderHeaderMonth()}
        {this.renderHeaderYear()}
      </>
    );
  },

  render() {
    const { firstOfWeek, firstOfMonth } = this.props;

    const cal = new calendar.Calendar(firstOfWeek);
    const monthDates = Immutable.fromJS(
      cal.monthDates(firstOfMonth.year(), firstOfMonth.month()),
    );
    const weeks = monthDates.map(this.renderWeek);

    return (
      <div className={this.cx({ element: 'Month' })}>
        {this.renderHeader()}
        <table className={this.cx({ element: 'MonthDates' })}>
          <thead>{this.renderDayHeaders()}</thead>
          <tbody>{weeks.toJS()}</tbody>
        </table>
      </div>
    );
  },
});

export default CalendarMonth;
