import { useWatch } from 'react-hook-form';

import { getPhaseById } from '@float/common/selectors/phases';
import { useAppSelectorWithParams } from '@float/common/store';

import { useDisabledDates } from '../hooks/useDisabledDates';
import { useUpdateDates } from '../hooks/useUpdateDates';
import { ProjectFormData } from '../types';

export type PhaseFieldName = `phases.${number}` | 'phase';

export const usePhaseDates = (name: PhaseFieldName) => {
  const values = useWatch<ProjectFormData, typeof name>({
    name,
  });

  const phase = useAppSelectorWithParams(
    getPhaseById,
    values?.phase_id ?? undefined,
  );

  // Update the phase dates values if they change as a result of schedule actions
  useUpdateDates({
    startKey: `${name}.start_date`,
    endKey: `${name}.end_date`,
    watchStartDate: phase?.start_date,
    watchEndDate: phase?.end_date,
  });

  return useDisabledDates({
    startDate: values?.start_date,
    endDate: values?.end_date,
    calculatedStartDate: phase?.calculated_start_date,
    calculatedEndDate: phase?.calculated_end_date,
  });
};
