import React from 'react';

export default function ({
  className = '',
  size = '12',
  color = '#FFF',
  style = {},
}) {
  return (
    <svg
      className={className}
      width={`${size}px`}
      height={`${size}px`}
      viewBox={`0 0 ${size} ${size}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g fill="none" fillRule="evenodd" stroke={color} strokeLinecap="round">
        <path strokeLinejoin="round" d="M10.5 4.5l-3-3" />
        <path d="M10.5 7.5h-9" />
        <path strokeLinejoin="round" d="M4.5 10.5l-3-3" />
        <path d="M1.5 4.5h9" />
      </g>
    </svg>
  );
}
