import React, { useMemo } from 'react';
import { t } from '@lingui/macro';

import { BudgetType } from '@float/types/project';

import { ToggleField } from '../components/ToggleField';
import { useProjectACL } from '../hooks/useProjectACL';

export function BudgetTypeField(props: {
  onChange: (value: BudgetType) => void;
}) {
  const acl = useProjectACL();

  const options = useMemo(
    () => [
      {
        value: BudgetType.Disabled,
        label: t`No budget`,
      },
      {
        value: BudgetType.TotalHours,
        label: t`Hours-based`,
      },
      {
        value: BudgetType.TotalFee,
        label: t`Fee-based`,
        disabled: !acl.canSeeBudget,
      },
      {
        value: BudgetType.HourlyFee,
        label: t`Hourly fee`,
        disabled: !acl.canSeeBudget,
      },
    ],
    [acl.canSeeBudget],
  );

  return (
    <ToggleField
      name="project.budget_type"
      label={t`Type`}
      options={options}
      onChange={props.onChange}
    />
  );
}
