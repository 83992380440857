import React from 'react';
import { bisector, max } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { scaleLinear, scaleTime } from 'd3-scale';
import * as d3Selection from 'd3-selection';
import { line as createLine } from 'd3-shape';
import { timeDay, timeMonth, timeSaturday, timeSunday } from 'd3-time';
import { isEmpty } from 'lodash';
import ReactDOMServer from 'react-dom/server';
import { withTheme } from 'styled-components';
import tippy, { createSingleton, hideAll } from 'tippy.js';

import DayDots from '@float/common/components/DayDots';
import DayTooltip from '@float/common/components/DayTooltip';
import { moment } from '@float/libs/moment';

import { PURPLE, TEAL, UNITS } from '../constants';
import { createDropShadowFilter } from '../svg-utils';
import { LineChartContainer } from './styles';

const MARGIN = {
  top: 25,
  right: 30,
  bottom: 40,
  left: 80,
};

const HEIGHT = 275 - MARGIN.top - MARGIN.bottom;

const COLORS = [TEAL, PURPLE];

function getColor(i = 0) {
  if (i < COLORS.length) {
    return COLORS[i];
  }

  return COLORS[0];
}

function drawLine({
  svg,
  datapoints,
  line,
  color = TEAL,
  dashed = 'none',
  strokeWidth = 2,
}) {
  svg
    .append('path')
    .datum(datapoints)
    .attr('fill', 'none')
    .attr('stroke', color)
    .attr('stroke-width', strokeWidth)
    .attr('stroke-dasharray', dashed)
    .attr('stroke-linejoin', 'round')
    .attr('d', line);
}

function drawCircle({
  svg,
  datapoints,
  cx,
  cy,
  offset = 0,
  radius = 5,
  color = COLORS[0],
  tippyFn,
  shouldRenderTooltip,
}) {
  svg
    .selectAll()
    .data(datapoints)
    .enter()
    .append('circle')
    .attr('class', (d, i) => {
      return `data-circle circle-${i + offset} ${
        shouldRenderTooltip(d) ? 'data-circle-tooltip' : ''
      }`;
    })
    .attr('stroke', color)
    .attr('stroke-width', '2px')
    .attr('fill', '#fff')
    // .style('filter', 'url(#drop-shadow)')
    .attr('r', radius)
    .attr('cx', cx)
    .attr('cy', cy)
    .attr('data-tippy-content', tippyFn);
}

function getMaxValueFromDataPoint(d) {
  if (typeof d.values === 'undefined') {
    return Number(d.value);
  }

  return Math.max.apply(null, d.values);
}

function refineTicks({ allDps, unit, xMaxTicks, weekStart, hasLoggedTime }) {
  // Note that we use this weird .filter method from d3 time to ensure that
  // the default behavior of showing ticks on pre-determined d3 intervals
  // doesn't happen and instead we only show the ticks we explicitly want.
  // See: https://stackoverflow.com/questions/49178363

  let datapoints;
  let xTicks;

  if (unit === UNITS.DAY) {
    // Show evenly spaced ticks up to xMaxTicks
    datapoints = allDps;
    const xTickEvery = Math.ceil(datapoints.length / xMaxTicks);
    xTicks = timeDay.filter((d) => timeDay.count(0, d) % xTickEvery === 0);
  }

  if (unit === UNITS.WEEK) {
    // Show evenly spaced ticks on the week end dates (either Saturday
    // or Sunday depending on the company week start setting)
    const weekEnd = weekStart === 0 ? 6 : 0;
    datapoints = allDps.filter((d) => moment(d.date).day() === weekEnd);
    const xTickEvery = Math.ceil(datapoints.length / xMaxTicks);
    const time = weekStart === 0 ? timeSaturday : timeSunday;
    xTicks = timeDay.filter((d) => {
      const md = moment(d);
      return md.day() === weekEnd && time.count(0, d) % xTickEvery === 0;
    });
  }

  if (unit === UNITS.MONTH) {
    /* In month mode, the ticks and hover values do not necessarily line up.
     *
     * We want to show hover points for:
     *  - The first datapoint
     *  - The last datapoint
     *  - Yesterday's datapoint (only when the logged time is rendered)
     *  - Datapoints on the last day of each month
     *
     * Each of those datapoints (except for "yesterday") is a candidate for
     * an accompanying tick - however, we sometimes suppress the last day of
     * month ticks if it overlaps with the first or last tick.
     */

    datapoints = allDps.filter((d, idx) => {
      const md = moment(d.date);
      return (
        idx === 0 ||
        idx === allDps.length - 1 ||
        (md.isSame(moment().add(-1, 'day'), 'day') && hasLoggedTime) ||
        md.daysInMonth() === md.date()
      );
    });

    const xTickEvery = Math.ceil(datapoints.length / xMaxTicks);
    const firstTick = datapoints[0].key;
    const lastTick = datapoints[datapoints.length - 1].key;

    const ignoreTicks = [];
    if (moment(firstTick).date() > 23) {
      ignoreTicks.push(moment(firstTick).endOf('month').format('YYYY-MM-DD'));
    }
    if (moment(lastTick).date() < 7) {
      ignoreTicks.push(
        moment(lastTick).startOf('month').add(-1, 'day').format('YYYY-MM-DD'),
      );
    }

    xTicks = timeDay.filter((d) => {
      const md = moment(d);
      const date = md.format('YYYY-MM-DD');

      if (date == firstTick || date == lastTick) {
        return true;
      }

      return (
        md.daysInMonth() === md.date() &&
        timeMonth.count(0, d) % xTickEvery === 0 &&
        !ignoreTicks.includes(date)
      );
    });
  }

  if (isEmpty(datapoints) && !isEmpty(allDps)) {
    // The user chose week/month, but the date range for the report doesn't
    // cover either the start of any week or the start of any month. In this
    // case, let's just default to day for now.
    datapoints = allDps;
    const xTickEvery = Math.ceil(datapoints.length / xMaxTicks);
    xTicks = timeDay.filter((d) => timeDay.count(0, d) % xTickEvery === 0);
  }

  const singlePointMode = datapoints.length === 1;
  if (singlePointMode) {
    datapoints = [
      {
        ...datapoints[0],
        date: moment(datapoints[0].date).subtract(1, 'second').toDate(),
      },
      datapoints[0],
      {
        ...datapoints[0],
        date: moment(datapoints[0].date).add(1, 'second').toDate(),
      },
    ];
  }

  if (!datapoints) throw Error(`Unknown unit ${unit}`);

  return { datapoints, xTicks };
}

class LineChart extends React.PureComponent {
  tooltipsRenderedForDates = {};
  tooltipKeys = [];

  state = { activeIndex: null };

  setActiveIndex(val) {
    let prevIndex;

    this.setState(
      (s) => {
        if (s.activeIndex !== val) {
          prevIndex = s.activeIndex;
          return { activeIndex: val };
        }

        return null;
      },
      () => {
        if (typeof prevIndex === 'undefined') return;

        // We'll manually set the opacity here to prevent needing to re-draw
        // the entire SVG as the user mouses over the chart.
        if (this.d3ContainerRef) {
          const oldCircle = this.d3ContainerRef.getElementsByClassName(
            `circle-${prevIndex}`,
          );
          for (let k = 0; k < oldCircle.length; k++) {
            oldCircle[k].style.opacity = 0;
          }

          const oldLine = this.d3ContainerRef.getElementsByClassName(
            `line-vertical-${prevIndex}`,
          );
          for (let k = 0; k < oldLine.length; k++) {
            oldLine[k].style.opacity = 0;
          }

          const newCircle = this.d3ContainerRef.getElementsByClassName(
            `circle-${val}`,
          );
          for (let k = 0; k < newCircle.length; k++) {
            newCircle[k].style.opacity = 1;
          }

          const newLine = this.d3ContainerRef.getElementsByClassName(
            `line-vertical-${val}`,
          );
          for (let k = 0; k < newLine.length; k++) {
            newLine[k].style.opacity = 1;
          }
        }
      },
    );
  }

  componentWillUnmount() {
    if (!isEmpty(this.tippies)) {
      this.tippies.forEach((i) => {
        i.reference.dispatchEvent(new MouseEvent('mouseleave'));
        i.destroy();
      });
    }

    if (!isEmpty(this.dayTippies)) {
      this.dayTippies.forEach((i) => {
        i.reference.dispatchEvent(new MouseEvent('mouseleave'));
        i.destroy();
      });
    }
  }

  setD3ContainerRef = (ref) => {
    this.d3ContainerRef = ref;

    if (!this.svgRendered) {
      // Since this rendering happens outside of React, we don't really support
      // updating individual datapoints. Instead, this component is marked Pure
      // and rendered with a key to ensure that anytime data changes, we fully
      // re-draw it.
      this.renderSvg();
    }
  };

  getWidth() {
    return this.props.width - MARGIN.left - MARGIN.right;
  }

  shouldRenderTooltip(d, currentValue) {
    if (this.tooltipsRenderedForDates[d.key]) {
      return false;
    }

    const isHighest = `${getMaxValueFromDataPoint(d)}` == currentValue;
    if (isHighest) {
      this.tooltipsRenderedForDates[d.key] = true;
      this.tooltipKeys.push(d.key);
      return true;
    }

    return false;
  }

  renderSvg() {
    const {
      data: {
        datapoints: allDps,
        xTickFormat,
        xMaxTicks,
        yTickFormat,
        TippyContent,
        referenceLine,
      },
      unit,
      weekStart,
      forecast = false,
    } = this.props;

    if (!allDps) return;

    // Mode based datapoint filtering / ticks ----------------------------------

    const multipleLines = typeof allDps[0].values !== 'undefined';
    let numberOfLines = multipleLines ? allDps[0].values.length : 1;
    if (forecast) {
      numberOfLines = 2;
    }

    const { xTicks, datapoints } = refineTicks({
      allDps,
      unit,
      xMaxTicks,
      weekStart,
      hasLoggedTime: numberOfLines > 1,
    });
    this.datapoints = datapoints;

    // Helpers -----------------------------------------------------------------

    const x = scaleTime()
      .domain([datapoints[0].date, datapoints[datapoints.length - 1].date])
      .range([0, this.getWidth()]);

    let maxY = max(datapoints, getMaxValueFromDataPoint);
    if (referenceLine) maxY = Math.max(referenceLine.value, maxY);

    const y = scaleLinear().domain([0, maxY]).range([HEIGHT, 0]);

    const bisectDate = bisector((d) => d.date).left;

    // Main container ----------------------------------------------------------

    const svg = d3Selection
      .select(this.d3ContainerRef)
      .append('svg')
      .attr('width', this.getWidth() + MARGIN.left + MARGIN.right)
      .attr('height', HEIGHT + MARGIN.top + MARGIN.bottom)
      .append('g')
      .attr('transform', `translate(${MARGIN.left},${MARGIN.top})`);

    // X-Axis ------------------------------------------------------------------

    const renderedAxis = svg
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(0,${HEIGHT + 10})`)
      .call(
        axisBottom(x).ticks(xTicks).tickSizeOuter(0).tickFormat(xTickFormat),
      );

    const today = moment().format('DD MMM');
    renderedAxis.selectAll('text').each(function makeTodayBold() {
      if (this.innerHTML == today) {
        this.classList.add('today-label');
      }
    });

    renderedAxis.select('.domain').remove();

    // Y-Axis ------------------------------------------------------------------
    const yAxis = axisLeft(y).ticks(4);

    svg
      .append('g')
      .attr('class', 'y axis')
      .attr('transform', `translate(-10,0)`)
      .call(yAxis.tickFormat(yTickFormat))
      .select('.domain')
      .remove();

    // Main line & circles ------------------------------------------------------

    const cx = (d) => x(d.date);
    const cy = (d) => y(d.value);
    const line = createLine().x(cx).y(cy);

    const tippyFn = (d) =>
      ReactDOMServer.renderToString(<TippyContent data={d} />);
    const singlePointMode = datapoints.length === 1;
    const datapointsForMode = singlePointMode ? [datapoints[1]] : datapoints;
    const getValue = (d, i) => (multipleLines ? d.values[i] : d.value);

    if (forecast) {
      const showHistoryTill = moment().add(-1, 'day');
      const datapointsHistory = datapoints.filter((d) =>
        moment(d.date).isSameOrBefore(showHistoryTill, 'day'),
      );
      const datapointsForecast = datapoints.filter((d) =>
        moment(d.date).isSameOrAfter(showHistoryTill, 'day'),
      );
      drawLine({
        svg,
        datapoints: datapointsHistory,
        line,
        color: PURPLE,
        strokeWidth: 4,
      });
      drawLine({
        svg,
        datapoints: datapointsForecast,
        line,
        color: TEAL,
      });

      drawCircle({
        svg,
        datapoints: datapointsHistory,
        color: PURPLE,
        cx,
        cy,
        shouldRenderTooltip: (d) => this.shouldRenderTooltip(d, d.value),
        tippyFn,
      });

      drawCircle({
        svg,
        offset: datapointsHistory.length - 1,
        datapoints: datapointsForecast,
        color: this.props.color,
        cx,
        cy,
        shouldRenderTooltip: (d) => this.shouldRenderTooltip(d, d.value),
        tippyFn,
      });
    } else {
      const datapointsHistory = numberOfLines > 1 ? datapoints : null;
      for (let i = 0; i < numberOfLines; i++) {
        const color = this.props.color || getColor(i);
        const mainLine = createLine()
          .x(cx)
          .y((d) => y(getValue(d, i)));

        drawLine({
          svg,
          datapoints: i === 1 ? datapointsHistory : datapoints, // TODO: Refactor - ugly hack
          line: mainLine,
          color,
        });

        drawCircle({
          svg,
          datapoints: i === 1 ? datapointsHistory : datapointsForMode,
          color,
          cx,
          cy: (d) => y(getValue(d, i)),
          shouldRenderTooltip: (d) =>
            this.shouldRenderTooltip(d, getValue(d, i)),
          tippyFn,
        });
      }
    }

    // Grid lines --------------------------------------------------------------

    svg
      .append('g')
      .attr('class', 'grid')
      .attr('transform', `translate(0,0)`)
      .call(yAxis.tickSize(-this.getWidth()).tickFormat(''));

    // Budget reference line ---------------------------------------------------

    if (referenceLine) {
      svg
        .append('path')
        .datum([
          { date: datapoints[0].date, value: Number(referenceLine.value) },
          {
            date: datapoints[datapoints.length - 1].date,
            value: Number(referenceLine.value),
          },
        ])
        .attr('fill', 'none')
        .attr('stroke', 'black')
        .attr('stroke-width', 1)
        .attr('stroke-linejoin', 'round')
        .attr('d', line);

      const textElem = svg
        .append('text')
        .attr('class', 'reference-label')
        .attr('id', 'ReferenceLabel')
        .attr('transform', `translate(0, ${y(Number(referenceLine.value))})`)
        .attr('dy', '0.3em')
        .attr('dx', '1.5em')
        .attr('text-anchor', 'start')
        .text(referenceLine.text);

      const dim = textElem.node().getBBox();

      svg
        .append('rect')
        .attr('x', dim.x - 10)
        .attr('y', dim.y - 1)
        .attr('transform', `translate(0, ${y(Number(referenceLine.value))})`)
        .attr('width', dim.width + 20)
        .attr('height', dim.height + 4)
        .attr('rx', 4)
        .attr('fill', '#575f65');

      textElem.raise();
    }

    // Hover popup -------------------------------------------------

    createDropShadowFilter(svg);

    const mousemove = () => {
      const { event } = d3Selection;

      // Find the hovered datapoint
      let dateIndex;
      let tippyIndex;

      if (singlePointMode) {
        dateIndex = 0;
        tippyIndex = 0;
      } else {
        // event.offsetX value in Firefox differs from other browsers
        const rect = event.target.getBoundingClientRect();
        const mouseX = event.clientX - rect.left; // event.offsetX - MARGIN.left;

        const x0 = x.invert(mouseX);
        const i = bisectDate(datapoints, x0, 1);
        const d0 = datapoints[i - 1];
        const d1 = datapoints[i];
        // This (d1.date) appears to throw a breaking error when future dates are looked up in the line chart report
        dateIndex = x0 - d0.date > d1?.date - x0 ? i : i - 1;

        // In case of multiple lines, tooltip is to be shown above the highest point.
        // `this.tooltipKeys` array stores the order in which tooltips are stored.
        tippyIndex = this.tooltipKeys.indexOf(
          dateIndex === i ? d1.key : d0.key,
        );
      }

      if (this.state.activeIndex !== dateIndex) {
        this.setActiveIndex(dateIndex);

        if (this.tippies[tippyIndex]) {
          // See: https://github.com/atomiks/tippyjs/issues/611
          this.tippyIndex = tippyIndex;
          this.tippies[tippyIndex].reference.dispatchEvent(
            new MouseEvent('mouseenter'),
          );
        }
      }
    };

    svg
      .append('rect')
      .attr('class', 'overlay')
      .attr('width', this.getWidth())
      .attr('height', HEIGHT)
      .on('mouseout', () => {
        if (typeof this.tippyIndex !== 'undefined') {
          // If a tippy was in the process of showing, stop it
          this.tippies[this.tippyIndex].reference.dispatchEvent(
            new MouseEvent('mouseleave'),
          );
        }
        this.setActiveIndex(null);
        hideAll();
      })
      .on('mousemove', mousemove);

    if (multipleLines) {
      const yTicks = y.ticks(4);
      const highestYTickValue = yTicks[yTicks.length - 1];
      svg
        .selectAll()
        .data(datapoints)
        .enter()
        .append('line')
        .attr('class', (d, i) => `data-line-vertical line-vertical-${i}`)
        .attr('stroke', '#e0e0e0')
        .attr('stroke-width', 1)
        .attr('stroke-dasharray', '3,3')
        .attr('x1', cx)
        .attr('y1', y('0'))
        .attr('x2', cx)
        .attr('y2', y(`${highestYTickValue}`));
    }

    this.tippies = tippy('svg circle.data-circle-tooltip');

    createSingleton(this.tippies, {
      delay: [75, 75],
      allowHTML: true,
      arrow: true,
      placement: 'top',
    });

    // Markers and marker ranges -----------------------------------------------

    const firstDay = datapoints[0].key;
    const lastDay = datapoints[datapoints.length - 1].key;
    const numDays = moment(lastDay).diff(moment(firstDay), 'day');
    const dayWidth = this.getWidth() / numDays;

    svg
      .selectAll('.marker')
      .data(this.props.highlights)
      .enter()
      .append('svg:foreignObject')
      .attr('class', 'marker')
      .attr('x', (h) => x(moment(h.date).toDate()) - dayWidth / 2)
      .attr('y', HEIGHT + 40)
      .attr('width', dayWidth)
      .attr('height', 40)
      .append('xhtml:div')
      .attr('class', 'marker-content')
      .attr('data-tippy-content', (d, i) => {
        const markers = this.props.highlights[i].highlights;
        if (markers && markers.length) {
          return ReactDOMServer.renderToString(
            <DayTooltip highlights={markers} />,
          );
        }

        return undefined;
      })
      .html((d, i) => {
        const markers = this.props.highlights[i].highlights;
        if (markers && markers.length) {
          return ReactDOMServer.renderToString(
            <DayDots highlights={markers} dayWidth={dayWidth} reportsView />,
          );
        }
        return '';
      });

    this.dayTippies = tippy('.marker-content[data-tippy-content]', {});

    createSingleton(this.dayTippies, {
      delay: [150, 150],
      allowHTML: true,
      interactive: true,
      placement: 'bottom',
      appendTo: document.body,
    });

    this.svgRendered = true;
  }

  getFilteredDatapoints() {
    return this.datapoints;
  }

  render() {
    return <LineChartContainer ref={this.setD3ContainerRef} />;
  }
}

export default withTheme(LineChart);
