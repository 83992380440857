import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  @-webkit-keyframes kf_el_lTPU3GuFI7_an_YOGP3M1Zg {
    0% {
      opacity: 0;
    }
    66.67% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  width: ${(p) => p.width}px;
  @-webkit-keyframes kf_el_lTPU3GuFI7_an_YOGP3M1Zg {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes kf_el_lTPU3GuFI7_an_YOGP3M1Zg {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes kf_el_rQKO6N6LiC_an_ycTSZK6xL {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes kf_el_rQKO6N6LiC_an_ycTSZK6xL {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes kf_el_2LAEG8WMuA_an_F-iO7F0p6 {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes kf_el_2LAEG8WMuA_an_F-iO7F0p6 {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  & * {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
    animation-timing-function: cubic-bezier(0, 0, 1, 1);
  }
  #el_8AlwyAHlW5 {
    stroke: none;
    stroke-width: 1;
    fill: none;
  }
  #el_UJJgU5mQUO {
    fill: #ffd0d4;
  }
  #el_a1Y8hsO19G {
    -webkit-transform: translate(28px, 0px);
    transform: translate(28px, 0px);
    fill: #acd5f0;
  }
  #el_OBp4zzzXWG {
    fill: #038ede;
  }
  #el_2LAEG8WMuA {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    opacity: 0;
    -webkit-animation-name: kf_el_2LAEG8WMuA_an_F-iO7F0p6;
    animation-name: kf_el_2LAEG8WMuA_an_F-iO7F0p6;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
    animation-timing-function: cubic-bezier(0, 0, 1, 1);
  }
  #el_rQKO6N6LiC {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    opacity: 0;
    -webkit-animation-name: kf_el_rQKO6N6LiC_an_ycTSZK6xL;
    animation-name: kf_el_rQKO6N6LiC_an_ycTSZK6xL;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
    animation-timing-function: cubic-bezier(0, 0, 1, 1);
  }
  #el_lTPU3GuFI7 {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    opacity: 0;
    -webkit-animation-name: kf_el_lTPU3GuFI7_an_YOGP3M1Zg;
    animation-name: kf_el_lTPU3GuFI7_an_YOGP3M1Zg;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
    animation-timing-function: cubic-bezier(0, 0, 1, 1);
  }
`;

export default function ({ width = 81 }) {
  return (
    <Svg
      viewBox="0 0 81 90"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      id="el_QZdH5F4nP"
      width={width}
      height={90}
    >
      <g id="el_8AlwyAHlW5" fillRule="evenodd">
        <g id="el_yOO0QVhzLD">
          <path
            d="M73.064,43.935 L50.548,43.935 C53.783,38.295 54.941,29.828 52.691,24.025 C51.041,19.757 47.886,17.267 43.801,17.015 L43.737,17.009 C41.078,16.849 38.777,18.79 38.557,21.377 C37.975,27.132 35.387,37.311 31.682,40.927 C28.559,43.968 27.511,45.749 23.082,47.861 C22.44,48.165 21.742,48.498 21,48.859 C21.008,48.962 21.008,61.054 21,85.133 C21.534,85.311 22.061,85.489 22.58,85.661 C29.89,88.12 34.605,90 44.264,90 L62.562,90 C67.557,90 70.171,86.243 70.171,82.532 C70.171,81.432 69.941,80.327 69.487,79.315 C71.149,79.025 72.607,78.245 73.686,77.052 C74.909,75.697 75.586,73.894 75.586,71.976 C75.586,70.879 75.356,69.774 74.904,68.766 C78.913,68.094 81,64.738 81,61.42 C81,59.498 80.296,57.561 78.926,56.142 C80.294,54.724 80.998,52.789 80.998,50.864 C80.998,47.154 78.057,43.935 73.064,43.935 Z"
            id="el_UJJgU5mQUO"
          />
          <g id="el_a1Y8hsO19G" fillRule="nonzero">
            <polygon
              id="el_rQKO6N6LiC"
              points="14.267 0 17.956 0 17.956 9.184 14.266 9.184"
            />
            <polygon
              id="el_lTPU3GuFI7"
              points="29.769 5.51 32.422 8.062 27.142 13.637 24.489 11.085"
            />
            <polygon
              id="el_2LAEG8WMuA"
              points="5.635 13.637 8.289 11.085 3.009 5.51 0.356 8.062"
            />
          </g>
          <polygon id="el_OBp4zzzXWG" points="0 46 4 87 21 87 21 46" />
        </g>
      </g>
    </Svg>
  );
}
