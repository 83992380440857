import { useMemo } from 'react';

import { DEFAULT_COLOR } from '@float/constants/colors';
import { Phase, Project, ProjectStatus } from '@float/types';

import { useCellStyles } from '../../../lib/cellStylesManager/hooks/useCellStyles';

export function getStatus(project?: Project, phase?: Phase) {
  return phase?.status ?? project?.status ?? ProjectStatus.Confirmed;
}

function getColor(project?: Project, phase?: Phase) {
  return `#${phase?.color || project?.color || DEFAULT_COLOR}`;
}

export function useProjectColor(project?: Project, phase?: Phase) {
  const color = getColor(project, phase);
  const status = getStatus(project, phase);

  const cellItem = useMemo(
    () => ({ type: 'project' as const, entity: { status } }),
    [status],
  );
  const styles = useCellStyles(cellItem, color);
  const { backgroundColor: background, color: foreground } = styles;
  let borderColor = background;

  if ([ProjectStatus.Draft, ProjectStatus.Tentative].includes(status)) {
    // use a darker border in this scenario
    borderColor = foreground;
  }

  return { backgroundColor: background, borderColor, textColor: foreground };
}
