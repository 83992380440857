import { difference } from 'lodash';
import { Dispatch } from 'redux';

import { AllActions } from '@float/common/reducers';
import { ReduxState } from '@float/common/reducers/lib/types';
import { FloatAppPlatform } from '@float/constants/app';
import { config } from '@float/libs/config';
import { DatesManager, DateString } from '@float/types';

import {
  FETCH_TASKS_SUCCESS,
  FETCH_TIMEOFFS_SUCCESS,
  getJWTAccessToken,
  LOGGED_TIME_LOAD_FINISH,
  ONEOFFS_LOAD_FINISH,
  STATUSES_LOAD_FINISH,
  TIMER_FETCH_SUCCESS,
} from '../../../actions';
import { batch } from '../../../lib/reduxBatch';
import {
  FETCH_SCHED_RANGE,
  FETCH_SCHED_RANGE_SUCCESS,
} from '../../redux/reducers';
import { getRangeStart } from './helpers';
import type { ScheduleDataFetcherAPI, ScheduleDataFetcherPayload } from './api';

type WorkerResponsePayload = {
  isError?: boolean;
  tasks?: unknown;
  timeoffs?: unknown;
  statuses?: unknown;
  oneOffs?: unknown;
  loggedTimes?: unknown;
  timers?: unknown;
};

export type DateRange = {
  startDate: DateString;
  endDate: DateString;
  ranges: Array<number>;
};

export class ScheduleDataFetcher {
  responseFunctions: {
    [id: string]: (responsePayload: WorkerResponsePayload) => unknown;
  } = {};
  api: ScheduleDataFetcherAPI;
  dates: DatesManager;
  fetchedRangesRef: { current: number[] };
  weeksPerFetch: number;
  reduxDispatch: Dispatch<AllActions>;

  nextId = 0;

  constructor(
    api: ScheduleDataFetcherAPI,
    dates: DatesManager,
    fetchedRangesRef: { current: number[] },
    weeksPerFetch: number,
    reduxDispatch: Dispatch<AllActions>,
  ) {
    this.api = api;
    this.dates = dates;
    this.fetchedRangesRef = fetchedRangesRef;
    this.weeksPerFetch = weeksPerFetch;
    this.reduxDispatch = reduxDispatch;
  }

  setDates(dates: DatesManager) {
    this.dates = dates;
  }

  private workerFetch = async (
    payload: ScheduleDataFetcherPayload,
  ): Promise<WorkerResponsePayload> => {
    const api = this.api;

    const [tasks, timeoffs, oneOffs, statuses, loggedTimes, timers] =
      await Promise.all([
        api.fetchTasks(payload),
        api.fetchTimeoffs(payload),
        api.fetchOneOffs(payload),
        api.fetchStatuses(payload),
        api.fetchLoggedTimes(payload),
        api.fetchTimers(payload),
      ]);

    return {
      tasks,
      timeoffs,
      oneOffs,
      statuses,
      loggedTimes,
      timers,
    };
  };

  private fetchScheduleRanges = (
    ranges: number[],
    personId?: number | null,
    withLoggedTimes?: boolean,
    canBeRefreshed?: boolean,
  ) => {
    // Note: This function is async because it potentially needs to fetch a new
    // JWT, but it will return the (potentially empty) list of ranges that need
    // to be fetched before they are actually fetched.
    return async (
      dispatch: Dispatch<AllActions>,
      getState: () => ReduxState,
    ): Promise<Array<DateRange>> => {
      const { fetchRequestedRanges, fetchedRanges } = getState().schedule;
      const { shared_link_view: sharedLink } = getState().currentUser;

      const rangesToFetch = difference(ranges, fetchRequestedRanges).sort();
      if (!rangesToFetch.length) {
        // Data was loaded in between the request to load more and the firing
        // of this Redux action. This has never been observed, but is a possible
        // edge case.
        console.log('Canceling range loading', ranges);
        return [];
      }

      const jwtAccessToken = await getJWTAccessToken()(dispatch, getState);

      // We want to minimize the number of network calls for loading range, so
      // we group up all contiguous ranges and fetch those instead.
      const contiguousRanges = [];
      let currentRange = null;
      for (let i = 0; i < rangesToFetch.length; i++) {
        if (!currentRange) {
          currentRange = {
            start: rangesToFetch[i],
            stop: rangesToFetch[i],
            ranges: [rangesToFetch[i]],
          };

          continue;
        }

        if (rangesToFetch[i] === currentRange.stop + this.weeksPerFetch) {
          currentRange.stop = rangesToFetch[i];
          currentRange.ranges.push(rangesToFetch[i]);
          continue;
        }

        contiguousRanges.push(currentRange);
        currentRange = {
          start: rangesToFetch[i],
          stop: rangesToFetch[i],
          ranges: [rangesToFetch[i]],
        };
      }
      if (currentRange) {
        contiguousRanges.push(currentRange);
      }

      const dateRanges = contiguousRanges.map((cr): DateRange => {
        return {
          startDate: this.dates.fromNum(cr.start * 7),
          endDate: this.dates.fromNum((cr.stop + this.weeksPerFetch) * 7),
          ranges: cr.ranges,
        };
      });

      dispatch({ type: FETCH_SCHED_RANGE, ranges: rangesToFetch });

      dateRanges.forEach(async (r: DateRange) => {
        const { startDate, endDate, ranges } = r;

        const res = await this.workerFetch({
          startDate,
          endDate,
          personId,
          withLoggedTimes,
          withTimers: Boolean(withLoggedTimes),
          jwtAccessToken,
          sharedLink,
          isNativeTimerApp: config.isNativeTimerApp,
          origin:
            config.platform === FloatAppPlatform.Web
              ? window.location.origin
              : config.api.hostname,
        });

        batch(() => {
          if (res.tasks) {
            dispatch({
              type: FETCH_TASKS_SUCCESS,
              skipFormatting: true,
              shouldRefresh: canBeRefreshed && !fetchedRanges.length,
              items: res.tasks,
            });
          }

          if (res.timeoffs) {
            dispatch({
              type: FETCH_TIMEOFFS_SUCCESS,
              skipFormatting: true,
              shouldRefresh: canBeRefreshed && !fetchedRanges.length,
              items: res.timeoffs,
            });
          }

          if (res.statuses) {
            dispatch({
              type: STATUSES_LOAD_FINISH,
              shouldRefresh: canBeRefreshed && !fetchedRanges.length,
              statuses: res.statuses,
            });
          }

          if (res.oneOffs) {
            dispatch({
              type: ONEOFFS_LOAD_FINISH,
              shouldRefresh: canBeRefreshed && !fetchedRanges.length,
              oneOffs: res.oneOffs,
            });
          }

          if (res.loggedTimes) {
            dispatch({
              type: LOGGED_TIME_LOAD_FINISH,
              shouldRefresh: canBeRefreshed && !fetchedRanges.length,
              loggedTimes: res.loggedTimes,
            });
          }

          if (res.timers) {
            dispatch({
              type: TIMER_FETCH_SUCCESS,
              shouldRefresh: canBeRefreshed && !fetchedRanges.length,
              timers: res.timers,
            });
          }

          dispatch({
            type: FETCH_SCHED_RANGE_SUCCESS,
            ranges,
            startDate,
            endDate,
          });
        });
      });

      return dateRanges;
    };
  };

  public ensureRangeFetched = async ({
    colStart,
    colStop,
    personId,
    withLoggedTimes,
    canBeRefreshed,
  }: {
    colStart: number;
    colStop: number;
    personId?: number | null;
    withLoggedTimes?: boolean;
    canBeRefreshed?: boolean;
  }): Promise<Array<DateRange>> => {
    if (colStart === 0 && colStop === 0) return [];

    // TODO: Investigate limiting what the server responds with if we
    // already have some fetched data. For example, if we have tasks from
    // 2019-03-01 through 2019-04-01, that could include a task that ranges
    // from 2019-02-25 through 2019-03-05. If we scroll left and need to
    // fetch 2019-02-01 through 2019-03-01, we don't need to re-process the
    // above example task at all.
    const start = getRangeStart(Math.max(0, colStart), this.weeksPerFetch);
    const stop = getRangeStart(colStop, this.weeksPerFetch);

    let cur = start;
    const ranges: Array<number> = [];

    while (cur <= stop) {
      if (!this.fetchedRangesRef.current.includes(cur)) {
        ranges.push(cur);
      }
      cur += this.weeksPerFetch;
    }

    if (!ranges.length) return [];

    this.fetchedRangesRef.current.push(...ranges);

    const fetchRangesAction = this.fetchScheduleRanges(
      ranges,
      personId,
      withLoggedTimes,
      canBeRefreshed,
    );

    // TODO(PI-42): The typings are not correct here – manual validation shows
    //              that the coerced typings are correct. The `await` is also
    //              required, even though `ts` flags it as un-necessary.
    const result = (await this.reduxDispatch(
      fetchRangesAction,
    )) as unknown as Array<DateRange>;

    return result;
  };
}
