import { INTERACTIVITY_IDLE_CALLBACK_TIMEOUT } from './constants';
import { performanceMarkStart, performanceMeasure } from './helpers';
import { RumEventCallback } from './types';

// Measures the interactivity of a user flow

type InteractionEntry = {
  start: number;
  lbt: number; // Longest Blocking Task
  inp: number; // Longest input delay
};

type InteractivityEventData = {
  interactionId: string;
  longestBlockingTask: number;
  inp: number;
  duration: number;
};

declare global {
  function addEventListener(
    type: 'interaction-completed',
    listener: (event: CustomEvent<InteractivityEventData>) => void,
  ): void;
  function removeEventListener(
    type: 'interaction-completed',
    listener: (event: CustomEvent<InteractivityEventData>) => void,
  ): void;
}

const isSupported = () =>
  typeof PerformanceObserver !== 'undefined' &&
  PerformanceObserver.supportedEntryTypes?.includes('longtask') &&
  PerformanceObserver.supportedEntryTypes?.includes('event');

export class MeasureInteractivity {
  interactions = new Map<string, { entry: InteractionEntry }>();
  observer: PerformanceObserver | undefined;

  constructor() {
    if (!isSupported()) return;

    const observer = new PerformanceObserver((result) => {
      if (this.interactions.size > 0) {
        for (const entry of result.getEntries()) {
          this.processEntry(entry);
        }
      }
    });

    observer.observe({
      type: 'longtask',
    });

    observer.observe({
      type: 'event',
    });

    this.observer = observer;
  }

  processEntry({ entryType, duration }: PerformanceEntry) {
    for (const { entry } of this.interactions.values()) {
      if (entryType === 'longtask') {
        entry.lbt = Math.max(entry.lbt, duration);
      } else if (entryType === 'event') {
        entry.inp = Math.max(entry.inp, duration);
      }
    }
  }

  disconnect() {
    this.observer?.disconnect();
  }

  start(id: string) {
    if (!isSupported()) return false;
    if (this.interactions.has(id)) return false;

    performanceMarkStart(id);
    const entry = { start: performance.now(), lbt: 0, inp: 0 };

    this.interactions.set(id, { entry });

    return true;
  }

  complete(id: string) {
    // We wait for the next frame to catch the latest blocking tasks
    requestIdleCallback(
      () => {
        const now = performance.now();
        const interaction = this.interactions.get(id);

        // Using measure to make the tracked interaction visible on the profiler
        performanceMeasure(id);

        if (interaction) {
          dispatchEvent(
            new CustomEvent<InteractivityEventData>('interaction-completed', {
              detail: {
                interactionId: id,
                longestBlockingTask: interaction.entry.lbt,
                inp: interaction.entry.inp,
                duration: now - interaction.entry.start,
              },
            }),
          );
        }

        this.interactions.delete(id);
      },
      {
        // We add a timeout to avoid that the idle callback is delayed too much
        timeout: INTERACTIVITY_IDLE_CALLBACK_TIMEOUT,
      },
    );
  }

  trackSingleInteraction(id: string) {
    const started = this.start(id);

    if (started) {
      setTimeout(() => this.complete(id));
    }
  }
}
export const measureInteractivity = new MeasureInteractivity();

export function trackInteractions(callback: RumEventCallback) {
  function handleInteractionCompleted(
    evt: CustomEvent<InteractivityEventData>,
  ) {
    const { interactionId, longestBlockingTask, inp, duration } = evt.detail;

    callback({
      name: `${interactionId}:longest_blocking_task`,
      value: longestBlockingTask,
      delta: longestBlockingTask,
    });

    callback({
      name: `${interactionId}:inp`,
      value: inp,
      delta: inp,
    });

    callback({
      name: `${interactionId}:duration`,
      value: duration,
      delta: duration,
    });
  }

  addEventListener('interaction-completed', handleInteractionCompleted);

  return () => {
    removeEventListener('interaction-completed', handleInteractionCompleted);
  };
}
