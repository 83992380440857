import {
  useAppDispatchDecorator,
  useAppSelectorStrict,
} from '@float/common/store';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { useOnMount } from '@float/libs/hooks/useOnMount';
import { updatePrompts } from '@float/web/store/onboardingManager/actions';
import {
  EXCLUDE_FOR_LIGHT_ONBOARDING,
  EXCLUDE_FOR_NEW_USERS,
} from '@float/web/store/onboardingManager/constants';
import { getOnboardingManagerStatus } from '@float/web/store/onboardingManager/selectors/manager';

export function useExcludePromptsForNewUsers() {
  const onboardingStatus = useAppSelectorStrict(getOnboardingManagerStatus);

  const hideNewFeaturesCallouts = useAppDispatchDecorator(() => {
    const prompts = EXCLUDE_FOR_NEW_USERS;

    if (featureFlags.isFeatureEnabled(FeatureFlag.LightOnboarding)) {
      prompts.push(...EXCLUDE_FOR_LIGHT_ONBOARDING);
    }

    return updatePrompts(prompts, true);
  });

  useOnMount(() => {
    if (!onboardingStatus.completed) {
      hideNewFeaturesCallouts();
    }
  });
}
