import React from 'react';

export default function () {
  return (
    <svg width="402" height="124" viewBox="0 0 402 124">
      <defs>
        <circle id="b" cx="40" cy="39" r="6" />
        <filter
          id="a"
          width="200%"
          height="200%"
          x="-50%"
          y="-25%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="1.5"
          />
          <feComposite
            in="shadowBlurOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
        </filter>
        <path
          id="d"
          d="M78.5 34.5H44.25L40 39l-4.25-4.5H1.5A1.5 1.5 0 0 1 0 33V1.5A1.5 1.5 0 0 1 1.5 0h77A1.5 1.5 0 0 1 80 1.5V33a1.5 1.5 0 0 1-1.5 1.5z"
        />
        <filter
          id="c"
          width="143.7%"
          height="189.7%"
          x="-21.9%"
          y="-32.1%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="5" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="5"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter2" />
          <feGaussianBlur
            in="shadowOffsetOuter2"
            result="shadowBlurOuter2"
            stdDeviation="2"
          />
          <feColorMatrix
            in="shadowBlurOuter2"
            result="shadowMatrixOuter2"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="shadowMatrixOuter2" />
          </feMerge>
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#99E4DA"
          d="M1 121l62.921-14.34 82.897-14.34 66.417-32.263 73.907-8.066 38.398-20.053L401 26v95z"
        />
        <path
          fill="#000"
          fillOpacity=".12"
          d="M1 120h400v1H1zM1 80h400v1H1zM1 40h400v1H1z"
        />
        <path
          stroke="#00BCA3"
          strokeLinecap="round"
          strokeWidth="2"
          d="M1 120l62.921-14.34 82.897-14.34 66.417-32.263 73.907-8.066 38.398-20.053L401 25"
        />
        <g transform="translate(161 34)">
          <use fill="#000" filter="url(#a)" href="#b" />
          <circle
            cx="40"
            cy="39"
            r="5"
            fill="#FFF"
            stroke="#00BCA3"
            strokeLinejoin="square"
            strokeWidth="2"
          />
          <use fill="#000" filter="url(#c)" href="#d" />
          <use fill="#FFF" href="#d" />
          <path fill="#D8D8D8" d="M7 8h33v4H7z" />
          <path fill="#868D92" d="M7 21h66v4H7z" />
          <circle cx="41" cy="87" r="3" fill="#363D46" />
        </g>
        <path fill="#363D46" d="M1 0h400v2H1z" />
      </g>
    </svg>
  );
}
