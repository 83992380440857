import styled, { css } from 'styled-components';

export const StyledDotsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 18px;
`;

export const StyledDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  cursor: ${(p) => (p.isActive || p.disabled ? 'default' : 'pointer')};
  background-color: ${(p) =>
    p.isActive ? p.theme.charcoalGrey : p.theme.grey};
  margin-right: 6px;

  &:last-child {
    margin-right: 0;
  }
`;

export const StyledStepContainer = styled.div`
  position: relative;
`;

const slideTransition = css`
  transition: transform 400ms ease-out;
`;

const fadeTransition = css`
  transition: opacity 400ms ease-out;
`;

export const StyledStep = styled.div`
  width: 100%;

  &.step-right-enter {
    transform: translate3d(-100%, 0, 0);
  }

  &.step-right-enter-active {
    position: absolute;
    transform: translate3d(0, 0, 0);
    ${slideTransition}
  }

  &.step-right-exit {
    transform: translate3d(0, 0, 0);
  }

  &.step-right-exit-active {
    position: absolute;
    transform: translate3d(100%, 0, 0);
    ${slideTransition}
  }

  &.step-left-enter {
    transform: translate3d(100%, 0, 0);
  }

  &.step-left-enter-active {
    transform: translate3d(0, 0, 0);
    ${slideTransition}
  }

  &.step-left-exit {
    transform: translate3d(0, 0, 0);
  }

  &.step-left-exit-active {
    position: absolute;
    transform: translate3d(-100%, 0, 0);
    ${slideTransition}
  }

  &.fade-enter {
    opacity: 0;
  }

  &.fade-enter-active {
    position: absolute;
    height: 100%;
    opacity: 1;
    ${fadeTransition}
  }

  &.fade-exit {
    opacity: 1;
  }

  &.fade-exit-active {
    position: absolute;
    height: 100%;
    opacity: 0;
    ${fadeTransition}
  }
`;
