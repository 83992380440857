import React, { useMemo } from 'react';
import { FormProvider, useForm, UseFormSetValue } from 'react-hook-form';
import { vestResolver } from '@hookform/resolvers/vest';
import { t, Trans } from '@lingui/macro';
import useMount from 'react-use/esm/useMount';
import { updatePreventPanelOverride } from 'sidePanel/actions';
import { useSidePanelCloseInteractionController } from 'sidePanel/useSidePanelCloseInteractionController';

import { useAppDispatchDecorator } from '@float/common/store';
import { AccordionRoot } from '@float/ui/components/Accordion/AccordionRoot';
import { Button } from '@float/ui/deprecated';
import { PanelType } from '@float/web/sidePanel/types';
import { SidePanel } from 'components/SidePanel/SidePanel';
import { SidePanelActions } from 'components/SidePanel/SidePanelActions';
import { SidePanelContent } from 'components/SidePanel/SidePanelContent';

import { UnsavedChangesConfirmModal } from '../../modals/UnsavedChangesConfirmModal';
import { useAccordionState } from './hooks/useAccordionState';
import { UseFieldArrayProvider } from './hooks/useFieldArrayContext';
import { usePanelViewVisibility } from './hooks/usePanelViewVisibility';
import { getProjectFormValidationSuite } from './lib/projectFormValidationSuite';
import { ProjectPanelViewProps } from './ProjectPanelView';
import { PhaseHeaderSection } from './sections/PhaseHeaderSection';
import { PhaseBudgetSection } from './sections/ProjectBudgetSection/PhaseBudgetSection';
import { PhaseInfoSection } from './sections/ProjectInfoSection/PhaseInfoSection';
import { ProjectMilestonesSection } from './sections/ProjectMilestonesSection';
import { ProjectTasksSection } from './sections/ProjectTasksSection';
import { ProjectTeamSection } from './sections/ProjectTeamSection';
import { ProjectFormData } from './types';

import * as styles from './styles.css';

export type PhasePanelViewProps = ProjectPanelViewProps & {
  initialValuesFromProjectForm?: Partial<ProjectFormData['phase']>;
  onSubmit: (data: ProjectFormData) => Promise<void>;
};

function setPhaseFormValues(
  setValue: UseFormSetValue<ProjectFormData>,
  overrides?: PhasePanelViewProps['initialValuesFromProjectForm'],
) {
  if (!overrides) return;

  const phaseKeys = [
    'color',
    'phase_name',
    'budget_total',
    'start_date',
    'end_date',
    'non_billable',
    'status',
    'active',
  ] as const;

  phaseKeys.forEach((key) => {
    if (overrides[key] === undefined) return;

    setValue(`phase.${key}`, overrides[key], { shouldDirty: true });
  });
}

export const PhasePanelView: React.FC<PhasePanelViewProps> = ({
  values,
  openSections = [],
  animate,
  isEditing = false,
  isLoading = false,
  isOpen,
  initialValuesFromProjectForm,
  onClose,
  onSubmit,
}) => {
  const updateOverride = useAppDispatchDecorator(updatePreventPanelOverride);

  const resolver = useMemo(
    () =>
      vestResolver<ProjectFormData, undefined>(
        getProjectFormValidationSuite(values.type),
        undefined,
        {
          mode: 'sync',
        },
      ),
    [values.type],
  );

  const methods = useForm<ProjectFormData>({
    defaultValues: values,
    values,
    resetOptions: {
      keepDirtyValues: true,
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    resolver,
  });

  useMount(() => {
    setPhaseFormValues(methods.setValue, initialValuesFromProjectForm);
  });

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting },
    trigger,
  } = methods;

  const shouldBlockCloseInteraction = isDirty;

  const { isBudgetSectionVisible, isTemplate } = usePanelViewVisibility({
    type: values.type,
    budgetType: values.project.budget_type,
    budgetPriority: values.project.budget_priority,
    control: methods.control,
  });

  const closeInteraction = useSidePanelCloseInteractionController(
    onClose,
    shouldBlockCloseInteraction,
    PanelType.PhasePanel,
  );

  const handleFormSubmit = handleSubmit(async (data: ProjectFormData) => {
    try {
      await onSubmit(data);
    } catch {
      // Do not close side panel if there are errors
      return;
    }

    // Check if the form is valid
    // we can't rely on formState as it doesn't have getters
    if (await trigger()) {
      updateOverride(false, PanelType.PhasePanel);
      onClose();
    }
  });

  function handleOpenChange(isOpen: boolean) {
    if (!isOpen) {
      closeInteraction.handleCloseButtonClick();
    }
  }

  const accordion = useAccordionState(openSections);
  const showSubmitActions = !isLoading;
  const isArchived = !values.phase?.active;
  const isInUnsavedProject = !values.projectId;

  return (
    <>
      <SidePanel
        onOpenChange={handleOpenChange}
        isOpen={isOpen}
        animate={animate}
        formType={values.type}
      >
        <FormProvider {...methods}>
          <div className={styles.projectFormWrapper}>
            <SidePanelContent>
              <form onSubmit={handleFormSubmit} autoComplete="off">
                <PhaseHeaderSection
                  isEditing={isEditing}
                  isLoading={isLoading}
                  isArchived={isArchived}
                  onClose={closeInteraction.handleCloseButtonClick}
                />
              </form>
              <div
                className={styles.projectAccordionWrapper}
                data-loading={isLoading}
              >
                <AccordionRoot
                  type="multiple"
                  value={accordion.value}
                  onValueChange={accordion.onValueChange}
                >
                  <form onSubmit={handleFormSubmit} autoComplete="off">
                    <PhaseInfoSection />
                  </form>
                  {isBudgetSectionVisible && (
                    <form onSubmit={handleFormSubmit} autoComplete="off">
                      <PhaseBudgetSection />
                    </form>
                  )}
                  <UseFieldArrayProvider name="team">
                    <ProjectTeamSection isLoading={isLoading} />
                  </UseFieldArrayProvider>
                  <ProjectTasksSection />
                  <ProjectMilestonesSection />
                </AccordionRoot>
              </div>
            </SidePanelContent>
            {showSubmitActions ? (
              <SidePanelActions className={styles.sidePanelActions}>
                <Button
                  size="large"
                  type="button"
                  appearance="secondary"
                  onClick={closeInteraction.handleCloseButtonClick}
                >
                  <Trans>Cancel</Trans>
                </Button>
                <Button
                  size="large"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={handleFormSubmit}
                >
                  <SubmitLabel
                    isEditing={isEditing || isInUnsavedProject}
                    isTemplate={isTemplate}
                  />
                </Button>
              </SidePanelActions>
            ) : (
              // To enable submit on enter when an input is on focus
              <input
                type="submit"
                className={styles.hidden}
                disabled={isSubmitting}
              />
            )}
          </div>
        </FormProvider>
        {closeInteraction.isBlocked && (
          <UnsavedChangesConfirmModal
            onConfirm={closeInteraction.handleLeavePromptConfirm}
            onCancel={closeInteraction.handleLeavePromptCancel}
          />
        )}
      </SidePanel>
    </>
  );
};

function SubmitLabel(props: { isEditing: boolean; isTemplate: boolean }) {
  const { isEditing, isTemplate } = props;

  if (isTemplate) {
    return t`Save to template`;
  }

  return isEditing ? t`Update phase` : t`Create phase`;
}
