import { BulkLoggedTimeSuggestion } from '../types';

/**
 *  Sorts the suggestions following the same logic applied on the Schedule sorting
 *  (see: common/src/serena/Data/useCells/helpers/sortLogTimeCellPerTypeAndPriority.ts)
 *
 * Logged times don't have the concept of priority and they get ordered by creation date.
 *
 * So we sort them here to send the batch in an ordered way, so the creation date matches
 * the priority set on the task.
 */
export function sortSuggestions(suggestions: BulkLoggedTimeSuggestion[]) {
  suggestions.sort((aWrapper, bWrapper) => {
    const a = aWrapper.entity;
    const b = bWrapper.entity;

    // Sort tasks/timeoffs with start time below ones that don't
    if (a.start_time && !b.start_time) {
      return 1;
    }
    if (!a.start_time && b.start_time) {
      return -1;
    }
    if (a.start_time && b.start_time) {
      return a.start_time.localeCompare(b.start_time);
    }

    // If one of the items has a lower value for priority, move it up
    if (aWrapper.priority !== bWrapper.priority)
      return aWrapper.priority - bWrapper.priority;

    // Default to creation date if everything else is the same
    return a.created.localeCompare(b.created);
  });

  return suggestions;
}
