import { keyBy } from '@float/common/lib/keyBy';
import { getTagsList, TAG_TYPE } from '@float/common/selectors/tags';
import { useAppStore } from '@float/common/store';
import { Person, Project, Tag, TagGroup } from '@float/types';

type Entity = Person | Project;

type TagWithEntities = {
  tag: Tag;
  entities: Entity[];
};

type TagsWithIntitiesByGroupIdMap = Map<number | null, TagWithEntities[]>;

type AdminTagGroup =
  | {
      id: number;
      tagsGroup: null;
      tagsWithEntities: TagWithEntities[];
    }
  | {
      id: number;
      tagsGroup: TagGroup;
      tagsWithEntities: TagWithEntities[];
    };

type useAdminTagsGroupsProps = {
  tagsType: (typeof TAG_TYPE)[keyof typeof TAG_TYPE];
};

/**
 *
 * ToDo: extract the computation logic to a selector
 * https://linear.app/float-com/project/tag-groups-tags-page-and-components-0d4285ed2a3d
 *
 * @returns AdminTagRoup[]
 */
export function useAdminTagsGroups({
  tagsType,
}: useAdminTagsGroupsProps): AdminTagGroup[] {
  const store = useAppStore();
  const state = store.getState();

  const tagsList = getTagsList(state).filter((tag) => tag.type === tagsType);
  const tagsByName = keyBy(tagsList, 'name');
  const tagsGroupsById = state.tagsGroups.tagsGroups;

  const peopleById = state.people.people;
  const projectsById = state.projects.projects;

  const entitiesById =
    tagsType === TAG_TYPE.PROJECT ? projectsById : peopleById;

  const entitiesList: Entity[] = Object.values(entitiesById);
  const entitiesByTagName: Record<string, Entity[]> = {};

  entitiesList.forEach((entity) => {
    const entityTags = entity.tags;

    entityTags.forEach((entityTag) => {
      // Project tags are stored as array of strings
      // People  tags are stored as array of objects with name
      const tagName =
        typeof entityTag === 'string' ? entityTag : entityTag.name;
      const tag = tagsByName[tagName];

      if (!tag) return;

      entitiesByTagName[tagName] = entitiesByTagName[tagName] || [];
      entitiesByTagName[tagName].push(entity);
    });
  });

  const tagsWithEntitiesList: TagWithEntities[] = tagsList.map((tag) => ({
    tag,
    entities: entitiesByTagName[tag.name] || [],
  }));

  const tagsWithEntitiesByGroupIdMap = tagsWithEntitiesList.reduce(
    (byGroupId: TagsWithIntitiesByGroupIdMap, tagWithEntity) => {
      const groupId = tagWithEntity.tag.group_id;

      const val = byGroupId.get(groupId) || [];
      val.push(tagWithEntity);

      byGroupId.set(groupId, val);

      return byGroupId;
    },
    new Map(),
  );

  const adminTagsGroups: AdminTagGroup[] = [];

  tagsWithEntitiesByGroupIdMap.forEach((tagsWithEntities, groupId) => {
    const id = groupId || -1;
    const tagsGroup = groupId ? tagsGroupsById[groupId] : null;

    adminTagsGroups.push({
      id,
      tagsGroup,
      tagsWithEntities,
    });
  });

  return adminTagsGroups;
}
