import React from 'react';
import { useMutation } from '@tanstack/react-query';

import API3 from '@float/common/api3';
import { trackEvent } from '@float/common/lib/analytics';
import { trackCompanyData } from '@float/common/lib/intercom';
import { getUser } from '@float/common/selectors/currentUser';
import { useAppSelectorStrict } from '@float/common/store';
import { Button, Modal, ModalHeader, ModalTitle } from '@float/ui/deprecated';

import { ErrorMessage, StyledModalBody, StyledRow } from './styles';
import { getRequestError } from './utils';

export type ConfirmPauseSubscriptionProps = {
  onClose: () => void;
  noAnimation?: boolean;
};

const getPauseSubscription = (cid: number) => async () => {
  await API3.pauseSubscription();

  trackEvent('company-paused');
  trackCompanyData('company_paused', true, cid);

  location.assign('/logout');
};

export function ConfirmPauseSubscription(props: ConfirmPauseSubscriptionProps) {
  const user = useAppSelectorStrict(getUser);

  const pauseSubscriptionMutation = useMutation({
    mutationFn: getPauseSubscription(user.cid),
  });

  const handlePauseSubscription = () => {
    pauseSubscriptionMutation
      .mutateAsync()
      // Avoid to trigger unhandledRejection
      .catch(() => {});
  };

  return (
    <Modal
      isOpen
      shouldCloseOnBgClick={false}
      shouldCloseOnEsc
      onClose={props.onClose}
      noAnimation={props.noAnimation}
    >
      <ModalHeader>
        <ModalTitle>Pause your subscription</ModalTitle>
      </ModalHeader>
      <StyledModalBody>
        <StyledRow $margin={16}>
          You will no longer be billed for this subscription. To reactivate,
          sign in and follow the prompts.
        </StyledRow>
        {pauseSubscriptionMutation.error && (
          <StyledRow $margin={16}>
            <ErrorMessage>
              {getRequestError(pauseSubscriptionMutation.error)}
            </ErrorMessage>
          </StyledRow>
        )}
        <StyledRow $margin={32}>
          <Button
            loader={pauseSubscriptionMutation.isPending}
            onClick={handlePauseSubscription}
          >
            Proceed
          </Button>{' '}
          <Button appearance="secondary" onClick={props.onClose}>
            Cancel
          </Button>
        </StyledRow>
      </StyledModalBody>
    </Modal>
  );
}
