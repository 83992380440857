import { isValid, parse } from 'date-fns';

import { parseInputTime } from './parseInputTime';

/**
 * This helper transforms a string value in the format HH:MM
 * to a valid Date object (as long as, the initial string is
 * an actual valid time).
 *
 * The Date object can be useful to then make further Date operations
 * (e.g., adding / subtracting minutes)
 *
 * Check the unit tests created for this helper for examples.
 */

function normalizeClockTime(value: string) {
  const { hours, minutes, period } = parseInputTime(value);

  let pattern;
  let time;

  if (period && hours <= 12) {
    pattern = 'h:maaa';
    time = `${hours}:${minutes}${period}`;
  } else {
    pattern = 'H:m';
    time = `${hours}:${minutes}`;
  }

  const parsed = parse(time, pattern, new Date());

  if (isValid(parsed)) {
    return parsed;
  }

  return null;
}

export { normalizeClockTime };
