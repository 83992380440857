import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { t } from '@lingui/macro';

import { SidePanelMeatballMenu } from '../../components/SidePanelMeatballMenu';
import { useFieldArrayContext } from '../../hooks/useFieldArrayContext';
import { ProjectFormData } from '../../types';

type TemplateMilestoneMenuProps = {
  index: number;
  showingDuration: boolean;
  toggleDuration: () => void;
};

export function TemplateMilestoneMenu({
  index,
  showingDuration,
  toggleDuration,
}: TemplateMilestoneMenuProps) {
  const { remove } = useFieldArrayContext<ProjectFormData, 'milestones'>();
  const { clearErrors, setValue, trigger } = useFormContext<ProjectFormData>();
  const milestone = useWatch({ name: `milestones.${index}` });

  const handleDelete = () => {
    remove(index);
    clearErrors('milestones');
    trigger('milestones');
  };

  const toggleDurationVisibility = () => {
    setValue(`milestones.${index}.duration`, null);
    toggleDuration();
  };

  const items = [
    {
      value: showingDuration ? t`Remove duration` : t`Add duration`,
      onClick: toggleDurationVisibility,
    },
    {
      value: t`Delete`,
      onClick: handleDelete,
    },
  ];

  return (
    <SidePanelMeatballMenu
      disabled={!milestone.name?.trim() && !milestone.milestone_id}
      vertical
      items={items}
      trigger={{
        label: t`Milestone options`,
      }}
    />
  );
}
