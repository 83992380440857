import React from 'react';
import { useFormContext } from 'react-hook-form';
import { t } from '@lingui/macro';

import { useCurrencyProps } from '@float/common/hooks/useCurrencyProps';
import { formatAmount } from '@float/common/lib/budget/helpers/formatAmount';
import { formatReadOnlyCurrency } from '@float/common/lib/currency';
import { config } from '@float/libs/config';
import { BudgetPriority, BudgetType } from '@float/types/project';

import { FormField } from '../components/FormFieldsSection';
import { isBudgetNotAccessible, ProjectFormData } from '../types';

import * as styles from './PhaseProjectBudgetField.css';

function ReadOnlyField(props: { value: string }) {
  return (
    <FormField label={t`Project budget`}>
      <span className={styles.projectBudgetValue}>{props.value}</span>
    </FormField>
  );
}

export function PhaseProjectBudgetField() {
  const { getValues } = useFormContext<ProjectFormData>();
  const budgetType = getValues('project.budget_type');
  const currencyProps = useCurrencyProps();

  if (isBudgetNotAccessible(budgetType)) return null;

  if (budgetType === BudgetType.Disabled) {
    return <ReadOnlyField value={t`No budget`} />;
  }

  if (budgetType === BudgetType.HourlyFee) {
    return <ReadOnlyField value={t`Hourly fee`} />;
  }

  const budgetPriority = getValues('project.budget_priority');

  const isBudgetPerPhase = budgetPriority === BudgetPriority.Phase;

  if (isBudgetPerPhase) {
    return (
      <ReadOnlyField
        value={
          budgetType === BudgetType.TotalFee
            ? t`Fee by phase`
            : t`Hours by phase`
        }
      />
    );
  }

  const isBudgetPerTask = budgetPriority === BudgetPriority.Task;
  if (isBudgetPerTask) {
    return (
      <ReadOnlyField
        value={
          budgetType === BudgetType.TotalFee ? t`Fee by task` : t`Hours by task`
        }
      />
    );
  }

  if (budgetType === BudgetType.TotalHours) {
    const budgetTotal = getValues('project.budget_total');
    const value = formatAmount(budgetType, budgetTotal, {
      inLocale: false,
      locale: config.locale,
      currency: currencyProps.currency,
    });

    return !value ? null : <ReadOnlyField value={value} />;
  }

  if (budgetType === BudgetType.TotalFee) {
    const budgetTotal = getValues('project.budget_total');
    const value = formatReadOnlyCurrency(budgetTotal ?? 0, currencyProps);
    return !value ? null : <ReadOnlyField value={value} />;
  }

  return null;
}
