import { getPeopleMap } from '@float/common/selectors/people';
import { useAppStoreStrict } from '@float/common/store';

export const useGetPersonDefaultRate = () => {
  const store = useAppStoreStrict();
  const people = getPeopleMap(store.getState());

  const getPersonDefaultRate = (personId: number) => {
    const person = people[personId];

    // Person hourly rate
    if (
      person?.default_hourly_rate !== undefined &&
      person?.default_hourly_rate !== null
    ) {
      return parseFloat(person.default_hourly_rate);
    }

    // Person role hourly rate
    if (
      person?.role &&
      person?.role.default_hourly_rate !== undefined &&
      person?.role.default_hourly_rate !== null
    ) {
      return parseFloat(person.role.default_hourly_rate);
    }

    return null;
  };

  return getPersonDefaultRate;
};
