import styled, { css } from 'styled-components';

import { Appearance, Size } from '.';
import * as Animation from '../../Animation';
import * as Colors from '../../Colors';
import { FOCUS_OUTLINE } from '../../Focus';
import * as Typography from '../../Typography';

export const StyledSpan = styled.span<{
  trailingIcon?: boolean;
  isClickable: boolean;
  isClickableIcon: boolean;
  appearance: Appearance;
  size: Size;
  color: string;
  withIcon: boolean;
}>`
  display: inline-flex;
  align-items: center;

  transition-property: background-color, color;
  transition-duration: ${Animation.Settings.Duration.Short};
  transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

  max-width: 100%;

  box-sizing: border-box;

  a {
    color: inherit;
  }

  svg {
    width: 24px;
    height: 24px;

    transition-property: stroke, fill;
    transition-duration: ${Animation.Settings.Duration.Short};
    transition-timing-function: ${Animation.Settings.Easing.InOutCurve};
  }

  span:empty {
    display: none;
  }

  span:not(:empty) + span:not(:empty) {
    margin-left: 4px;
  }

  &:focus-visible {
    ${FOCUS_OUTLINE}
  }

  ${({ trailingIcon }) =>
    trailingIcon &&
    css`
      flex-direction: row-reverse;

      span:not(:empty) + span:not(:empty) {
        margin-left: 0px;
        margin-right: 2px;
      }
    `};

  ${({ isClickable }) =>
    isClickable &&
    css`
      cursor: pointer;
    `}

  ${({ isClickableIcon }) =>
    isClickableIcon &&
    css`
      svg {
        cursor: pointer;

        &:hover {
          --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Low};
        }
      }
    `}

  ${({ appearance, color }) =>
    appearance === 'static' &&
    css`
      border-radius: 50px;

      ${color === 'flay' &&
      css`
        color: ${Colors.FIN.Lt.Stroke.Stroke4};

        background-color: ${Colors.FIN.Lt.Surface.Surf4};

        svg {
          --svg-icon-color: ${Colors.FIN.Lt.Stroke.Stroke4};
        }
      `}

      ${color === 'flue' &&
      css`
        color: ${Colors.Primary.Flue.Light[9]};

        background-color: ${Colors.Primary.Flue.Light[4]};

        svg {
          --svg-icon-color: ${Colors.Primary.Flue.Light[9]};
        }
      `}

      ${color === 'green' &&
      css`
        color: ${Colors.Secondary.Green.Light[11]};

        background-color: ${Colors.Secondary.Green.Light[4]};

        svg {
          --svg-icon-color: ${Colors.Secondary.Green.Light[11]};
        }
      `}


      ${color === 'violet' &&
      css`
        color: ${Colors.Secondary.Violet.Light[11]};

        background-color: ${Colors.Secondary.Violet.Light[4]};

        svg {
          --svg-icon-color: ${Colors.Secondary.Violet.Light[11]};
        }
      `}
    `}

  ${({ appearance, color }) =>
    appearance === 'dynamic' &&
    css`
      border-radius: 4px;

      // Colors
      ${color === 'flay' &&
      css`
        color: ${Colors.FIN.Lt.Emphasis.Medium};

        background-color: ${Colors.FIN.Lt.Surface.Surf3};

        svg {
          --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Medium};
        }

        &:hover {
          background-color: ${Colors.FIN.Lt.Buttons.Secondary.Hover.Bg};
        }

        &.clickable {
          &:active {
            background-color: ${Colors.FIN.Lt.Buttons.Secondary.Pressed.Bg};
          }
        }
      `}

      ${color === 'flue' &&
      css`
        color: ${Colors.FIN.Lt.Buttons.Tertiary.Default.Label};

        background-color: ${Colors.FIN.Lt.Buttons.Tertiary.Default.Bg};

        svg {
          --svg-icon-color: ${Colors.FIN.Lt.Buttons.Tertiary.Default.Label};
        }

        &:hover {
          color: ${Colors.FIN.Lt.Buttons.Tertiary.Hover.Label};

          background-color: ${Colors.FIN.Lt.Buttons.Tertiary.Hover.Bg};
        }

        &.clickable {
          &:active {
            background-color: ${Colors.FIN.Lt.Buttons.Tertiary.Pressed.Bg};
            color: ${Colors.FIN.Lt.Buttons.Tertiary.Pressed.Label};
          }
        }
      `}

      ${color === 'green' &&
      css`
        color: ${Colors.Secondary.Green.Light[11]};

        background-color: ${Colors.Secondary.Green.Light[4]};

        svg {
          --svg-icon-color: ${Colors.Secondary.Green.Light[11]};
        }

        &:hover {
          background-color: ${Colors.Secondary.Green.Light[5]};
        }
      `}
    `}

  ${({ size, withIcon, trailingIcon }) =>
    size === 'xxsmall' &&
    css`
      ${Typography.Label11.M500};

      padding: 3px 4px;
      max-height: 16px;

      ${withIcon
        ? trailingIcon
          ? css`
              padding-right: 2px;
            `
          : css`
              padding-left: 2px;
            `
        : ''}

      svg {
        width: 14px;
        height: 14px;
      }
    `}

  ${({ size, withIcon, trailingIcon }) =>
    size === 'xsmall' &&
    css`
      ${Typography.Label12.M500};
      line-height: 1.2;

      padding: 4px 6px;
      max-height: 20px;

      ${withIcon
        ? trailingIcon
          ? css`
              padding-right: 4px;
            `
          : css`
              padding-left: 4px;
            `
        : ''}

      svg {
        width: 14px;
        height: 14px;
      }
    `}

  ${({ size, withIcon, trailingIcon, appearance }) =>
    size === 'small' &&
    css`
      ${Typography.Label12.M500};
      line-height: 1.3;

      padding: 2px ${appearance === 'static' ? '8px' : '6px'};
      max-height: 24px;

      ${withIcon
        ? trailingIcon
          ? css`
              padding-right: 4px;
            `
          : css`
              padding-left: 4px;
            `
        : ''}

      svg {
        width: 14px;
        height: 14px;
      }
    `}

  ${({ size, withIcon, trailingIcon, appearance }) =>
    size === 'medium' &&
    css`
      ${Typography.Label14.M500};

      padding: ${appearance === 'static' ? '4px' : '7px'} 8px;
      max-height: 28px;

      ${withIcon
        ? trailingIcon
          ? css`
              padding-right: 6px;
            `
          : css`
              padding-left: 6px;
            `
        : ''}
    `}

  ${({ size, withIcon, trailingIcon }) =>
    size === 'large' &&
    css`
      ${Typography.Label14.M500};

      padding: 6px 12px;
      max-height: 32px;

      ${withIcon
        ? trailingIcon
          ? css`
              padding-right: 8px;
            `
          : css`
              padding-left: 8px;
            `
        : ''}
    `}

  ${({ color }) => {
    const getTagColorCSS = (textColor: string, backgroundColor: string) => {
      return `
          color: ${textColor};
          background-color: ${backgroundColor};

          svg {
            --svg-icon-color: ${textColor};
          }
      `;
    };

    const LabelColorByBgColor: Record<string, string> = {
      '#E1F0FF': '#173074',
      '#E7E5EB': '#344765',
      '#FFE8D7': '#BD4B00',
      '#FFF8BB': '#946800',
      '#DDF3E4': '#18794E',
      '#EEEADD': '#776750',
      '#FCE5F3': '#CD1D8D',
      '#D8F3F6': '#067A6F',
      '#F3E7FC': '#793AAF',
      '#F4E9DD': '#886349',
      '#E4F7C7': '#5D770D',
    };

    if (LabelColorByBgColor[color]) {
      return getTagColorCSS(LabelColorByBgColor[color], color);
    }

    switch (color) {
      case 'pink':
        return getTagColorCSS('#cd1d8d', '#fce5f3');
      case 'blue':
        return getTagColorCSS('#006adc', '#e1f0ff');
      case 'tomato':
        return getTagColorCSS('#ca3214', '#ffe6e2');
      case 'teal':
        return getTagColorCSS('#067a6f', '#d9f3ee');
      case 'orange':
        return getTagColorCSS('#bd4b00', '#ffe8d7');
      case 'yellow':
        return getTagColorCSS('#946800', '#fffce8');
      case 'brown':
        return getTagColorCSS('#886349', '#f4e9dd');
      default:
        return '';
    }
  }}
`;

export const StyledIcon = styled.span`
  display: flex;
`;

export const StyledLabel = styled.span`
  display: block;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledTooltipLabel = styled.span`
  ${Typography.Label12.M500};

  color: ${Colors.FIN.Lt.Stroke.Stroke4};

  a {
    color: inherit;

    pointer-events: none;
  }
`;
