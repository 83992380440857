import { Variants } from 'framer-motion';

export const animationVariants: Variants = {
  visible: {
    borderRadius: 8,
    width: 'auto',
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 30,
      opacity: {
        duration: 0,
      },
    },
  },
  hidden: {
    borderRadius: 8,
    width: 1,
    opacity: 0,
    transition: { duration: 0.1, delay: 0.1, opacity: { duration: 0.25 } },
  },
};

export const textAnimationVariants: Variants = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};
