import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M10.542 12.73a3.646 3.646 0 0 0 4.193 1.233c.49-.183.935-.469 1.305-.839l2.187-2.19a3.651 3.651 0 0 0-2.59-6.184 3.644 3.644 0 0 0-2.565 1.024l-1.254 1.248"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M13.458 11.27a3.646 3.646 0 0 0-5.497-.394l-2.188 2.19a3.651 3.651 0 0 0 2.59 6.184 3.644 3.644 0 0 0 2.565-1.024l1.247-1.248"
    />
  </BaseIcon>
);
