import { ErrorInfo } from 'react';

import { noop } from '@float/libs/utils/noop';

import { LoggerClientAdapter, LoggerEventExtras } from './logger.types';

type LoggerInitOptions = { isDevMode?: boolean; onReady?: () => void };

export class Logger {
  private _clientAdapter: LoggerClientAdapter | undefined;

  private get clientAdapter(): LoggerClientAdapter {
    if (this.isInitializing)
      throw new Error('The logger client is still initializing');

    if (!this._clientAdapter)
      throw new Error(
        'The logger client has not started initializing, you need to call `initialize`',
      );

    return this._clientAdapter;
  }

  private isDevMode: boolean = false;
  private isInitializing = false;

  public get isReady() {
    return Boolean(this._clientAdapter?.isReady && !this.isInitializing);
  }

  public async initialize(
    clientAdapter: LoggerClientAdapter,
    { isDevMode = false, onReady = noop }: LoggerInitOptions = {},
  ) {
    if (this.isInitializing || this.isReady) {
      this.log('The Logger service has already been initialized', {
        level: 'warn',
      });

      return;
    }

    this.isInitializing = true;
    this.isDevMode = isDevMode;
    this._clientAdapter = clientAdapter;

    await clientAdapter.initialize();

    this.isInitializing = false;

    onReady();

    return this;
  }

  public identify({
    id = 'unidentified',
    companyId = 'Company not selected',
    companyName = 'Company not selected',
  }: {
    id: string | number | undefined;
    companyId?: string | number;
    companyName?: string | number;
  }) {
    // We need to do some data security auditing on Sentry/Rollbar before
    // we can pass more identifying data
    this.clientAdapter.identify({ id, companyId, companyName });
  }

  public debug(
    message: string,
    error?: unknown | undefined,
    extras?: LoggerEventExtras,
  ) {
    const logInProduction = extras?.logInProduction;

    if (logInProduction || this.isDevMode) {
      console.debug(message, extras);
    }

    if (this.isDevMode) {
      return;
    }

    this.clientAdapter.debug(message, error, extras);
  }

  public error(
    message: string,
    error?: unknown | undefined,
    extras?: LoggerEventExtras,
  ) {
    if (this.isDevMode) {
      console.error(message, error, extras);
      return;
    }

    this.clientAdapter.error(message, error ?? new Error(message), extras);
  }

  public info(message: string, extras?: LoggerEventExtras) {
    if (this.isDevMode) {
      console.info(message, extras);
      return;
    }

    this.clientAdapter.info(message, extras);
  }

  /**
   * For internal logging – if the data needs to be propagated to the
   * logging service, pass { capture: true }
   */
  public log(message: string, extras?: LoggerEventExtras) {
    if (this.isDevMode) {
      const logLevel = extras?.level ?? 'log';
      console[logLevel](message, extras);

      return;
    }

    if (extras?.capture) {
      const { capture, ...rest } = extras;
      this.clientAdapter.log(message, rest);
    }
  }

  public warn(message: string, extras?: LoggerEventExtras) {
    if (this.isDevMode) {
      console.warn(message, extras);
      return;
    }

    this.clientAdapter.warn(message, extras);
  }

  public getReactErrorBoundaryHandler(
    boundaryName: string,
    additionalInfo: Record<string, unknown> = {},
  ) {
    return (e: Error, info: ErrorInfo) => {
      this.error(
        `An error was captured at a React Error Boundary: ${boundaryName}`,
        e,
        {
          context: {
            ...info,
            ...additionalInfo,
          },
        },
      );
    };
  }
}
