import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';

const VIEW_TYPE = {
  PROJECTS: 'projects',
  PEOPLE: 'people',
} as const;

export type GetViewTypeResult = (typeof VIEW_TYPE)[keyof typeof VIEW_TYPE];

export type GetViewTypeParams = {
  logTimeView: boolean;
  path: string;
  viewType?: GetViewTypeResult;
  viewTypePref: GetViewTypeResult;
};

export function getViewType(params: GetViewTypeParams): GetViewTypeResult {
  const { logTimeView, path, viewType, viewTypePref } = params;

  if (logTimeView) {
    return VIEW_TYPE.PEOPLE;
  }

  let result = viewType ?? VIEW_TYPE.PEOPLE;

  if (viewTypePref && viewTypePref !== viewType) {
    result = viewTypePref;
  }

  if (featureFlags.isFeatureEnabled(FeatureFlag.ProjectPlanInMainNav)) {
    if (path === '/project-plan') {
      return VIEW_TYPE.PROJECTS;
    }

    // If "Project plan" is lifted to main nav, then always render the
    // "People" view on "Schedule" page; since "Project plan" view will
    // have its own dedicated page at the `/project-plan` path.
    if (result === VIEW_TYPE.PROJECTS) {
      return VIEW_TYPE.PEOPLE;
    }
  }

  return result;
}
