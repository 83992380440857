import { t } from '@lingui/macro';
import { forEach, map, sortBy } from 'lodash';
import { createSelector } from 'reselect';

import { newAccountIsEqualOrLess } from '@float/common/lib/acl/specification/rights.helpers';
import { ReduxState } from '@float/common/reducers/lib/types';
import {
  AccountType,
  getAccountTypeToLabelMap,
} from '@float/constants/accounts';
import { getPersonTypeToLabelMap } from '@float/constants/people';
import { CurrentUser, Person } from '@float/types';

import { getUser } from '../currentUser';
import { getDepartments } from '../departments';
import { getPeopleTagsValues } from '../tags';
import { getPeopleMapRaw } from './helpers';

const getAccountTypeDescriptionsMapping = () => ({
  [AccountType.Member]: t`Can view schedule and optionally manage their own tasks and/or time off`,
  [AccountType.Manager]: t`Can manage specific departments, people and/or projects`,
  [AccountType.Admin]: t`Can manage all people, projects and team settings`,
  [AccountType.Billing]: t`Can manage billing, people, projects & team settings`,
});

export const getDepartmentOptions = createSelector(
  [getUser, getDepartments],
  (currentUser, departmentsState) => {
    const departments = Object.values(departmentsState);
    const sortedByName = sortBy(departments, (department) => department.name);

    departments.forEach((department) => {
      if (department.parent_id) {
        const idx = sortedByName.findIndex((d) => d.id === department.id);
        const [extractedDep] = sortedByName.splice(idx, 1);

        const parentIdx = sortedByName.findIndex(
          (d) => d.id === department.parent_id,
        );
        sortedByName.splice(parentIdx + 1, 0, extractedDep);
      }
    });

    const visibleDepartments = sortedByName.filter((department) => {
      const actorLimitedByDept = currentUser.department_filter_all?.length > 0;
      if (!actorLimitedByDept) {
        return true;
      }

      return currentUser.department_filter_all.includes(department.id);
    });

    return visibleDepartments.map((d) => ({ value: d.id, label: d.name }));
  },
);

export const getJobTitleOptions = createSelector(
  [getPeopleMapRaw],
  (people) => {
    const options: Array<{
      value: Person['job_title'];
      label: Person['job_title'];
    }> = [];
    const jobTitles: Array<Person['job_title']> = [];

    forEach(people, (person) => {
      const title = (person?.job_title || '').toLowerCase();
      if (title && !jobTitles.includes(title)) {
        jobTitles.push(title);
        options.push({ value: person.job_title, label: person.job_title });
      }
    });

    return sortBy(options, (jt) => jt.label?.toLowerCase());
  },
);

export const getPeopleTagOptions = createSelector(
  [getPeopleTagsValues],
  (personTags) => {
    return personTags.map((t) => ({ label: t, value: t }));
  },
);

export const getPersonTypeOptions = createSelector(() => {
  return map(getPersonTypeToLabelMap(), (label, id) => ({
    value: Number(id),
    label,
  }));
});

export const getMemberViewRightsOptions = createSelector(
  [getDepartmentOptions],
  (departmentOptions) => {
    const ungroupedOptions = [
      { value: 1, label: "Everyone's schedule" },
      { value: 0, label: 'Their schedule' },
    ];

    return [
      { name: '', options: ungroupedOptions },
      { name: 'Department', options: departmentOptions },
    ];
  },
);

export const getProjectManagerViewRightsOptions = createSelector(
  [getDepartmentOptions],
  (departmentOptions) => {
    const ungroupedOptions = [{ value: 1, label: "Everyone's schedule" }];

    return [
      { name: '', options: ungroupedOptions },
      { name: 'Department', options: departmentOptions },
    ];
  },
);

export const getAccessOptions = createSelector(
  (state: ReduxState) => state.currentUser,
  (currentUser: CurrentUser) => {
    const options = [
      AccountType.Member,
      AccountType.Manager,
      AccountType.Admin,
      AccountType.Billing,
    ] as const;
    const accountTypeDescriptions = getAccountTypeDescriptionsMapping();

    return options
      .filter((x) =>
        newAccountIsEqualOrLess(currentUser, {
          account: { account_type: x },
        }),
      )
      .map((value) => ({
        value,
        label: getAccountTypeToLabelMap()[value],
        description: accountTypeDescriptions[value],
      }));
  },
);
