import { List } from '@floatschedule/react-virtualized';
import styled, { css } from 'styled-components';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Elevations from '@float/ui/deprecated/Earhart/Elevations';
import * as Scrollbar from '@float/ui/deprecated/Earhart/Scrollbar';

import { StyledItem } from './VirtualListItem/styles';

export const StyledListWrapper = styled.div`
  outline: none;

  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
    `}

  ${({ $appearance }) => {
    if ($appearance === 'flay') {
      return css`
        --list-item-default-label-color: ${Colors.FIN.Lt.Emphasis.Medium};
        --list-item-default-bg-color: rgba(0, 0, 0, 0);

        --list-item-hover-label-color: ${Colors.FIN.Lt.Emphasis.High};
        --list-item-hover-bg-color: ${Colors.FIN.Lt.Surface.Surf4};

        --list-item-pressed-label-color: ${Colors.FIN.Lt.Emphasis.High};
        --list-item-pressed-bg-color: ${Colors.FIN.Lt.Surface.Surf6};

        --list-item-active-label-color: ${Colors.FIN.Lt.Emphasis.High};
        --list-item-active-bg-color: rgba(0, 0, 0, 0);
      `;
    }

    if ($appearance === 'flue') {
      return css`
        --list-item-default-label-color: ${Colors.FIN.Lt.Emphasis.Medium};
        --list-item-default-bg-color: rgba(0, 0, 0, 0);

        --list-item-hover-label-color: ${Colors.Primary.Flue.Light[9]};
        --list-item-hover-bg-color: ${Colors.Primary.Flue.Light[4]};

        --list-item-pressed-label-color: ${Colors.Primary.Flue.Light[11]};
        --list-item-pressed-bg-color: ${Colors.Primary.Flue.Light[6]};

        --list-item-active-label-color: ${Colors.Primary.Flue.Light[9]};
        --list-item-active-bg-color: rgba(0, 0, 0, 0);
      `;
    }
  }};
`;

export const StyledList = styled(List)`
  ${Scrollbar.List};

  &:not(:empty) {
    ${Elevations.E150};

    border-radius: 6px;

    background-color: ${Colors.FIN.Lt.Emphasis.White};

    padding: calc(var(--padding, 0) * 1px);
  }

  .ReactVirtualized__Grid__innerScrollContainer {
    ${StyledItem} {
      margin-bottom: calc(var(--item-gutter) * 1px);
    }
  }
`;
