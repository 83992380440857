import {
  FEATURE_FLAG_VARIANTS,
  TargetedDebugLogFlagVariants,
} from './constants';
import { FeatureFlagVariantNames } from './types';

export const isFeatureFlagVariant = (
  string: string | FeatureFlagVariantNames,
): string is FeatureFlagVariantNames => {
  return FEATURE_FLAG_VARIANTS.includes(string as FeatureFlagVariantNames);
};

export const isTargetedDebugLogFlagVariants = (
  string: string | TargetedDebugLogFlagVariants,
): string is TargetedDebugLogFlagVariants => {
  return Object.values(TargetedDebugLogFlagVariants).includes(
    string as TargetedDebugLogFlagVariants,
  );
};
