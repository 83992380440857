import { useMemo } from 'react';

import { measureText } from '@float/common/lib/utils';

const PADDING = 12;
const FONT_SIZE = 16;
const MARGIN = 4;
export const useVisibleTags = ({ tags, containerWidth }) => {
  return useMemo(() => {
    // Container width wasn't calculated
    if (!containerWidth) {
      return { visible: [], hidden: tags };
    }
    const visible = [];
    const hidden = [];
    let sum = 0;
    for (let i = 0; i < tags.length; i++) {
      const tag = tags[i];
      const margin = i === 0 ? 0 : MARGIN;
      // Measure tag element width ahead of compilation
      sum += measureText(tag.name ?? tag.label, FONT_SIZE) + PADDING + margin;
      if (sum < containerWidth) {
        visible.push(tag);
      } else {
        hidden.push(tag);
      }
    }
    // If any tag is too wide, instead of showing just +1, we'll show first one with ellipsis.
    if (!visible.length && hidden.length) {
      const firstTag = hidden.pop();
      firstTag.maxWidth = hidden.length
        ? containerWidth -
          (measureText(`+${hidden.length}`, FONT_SIZE) + PADDING + MARGIN)
        : containerWidth;
      visible.push(firstTag);
    }
    return { visible, hidden };
  }, [tags, containerWidth]);
};
