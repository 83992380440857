import React from 'react';

import { FIN } from '@float/common/earhart/colors';

export const CompletedCycleIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="cycle-icon">
        <path
          id="Vector"
          d="M6.80636 4.88307L11.074 7.34704C11.4551 7.56698 11.4551 8.11683 11.074 8.33678L6.80636 10.8007C6.42541 11.0207 5.94922 10.7457 5.94922 10.3058V5.37795C5.94922 4.93807 6.42541 4.66313 6.80636 4.88307Z"
          fill={FIN.Lt.Icon.Subdued}
        />
        <path
          id="Subtract"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.84973 13.7538C9.27599 13.915 8.65644 14 8 14C4.46538 14 2 11.5346 2 8C2 4.46538 4.46538 2 8 2C11.5346 2 14 4.46538 14 8C14 8.65632 13.915 9.27578 13.7539 9.84943C14.4269 10.0668 14.9993 10.5081 15.3833 11.0854C15.7806 10.136 16 9.09361 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C9.09375 16 10.1362 15.7805 11.0858 15.3832C10.5084 14.9992 10.0672 14.4268 9.84973 13.7538Z"
          fill={FIN.Lt.Icon.Subdued}
        />
        <path
          id="Subtract_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5 16C14.433 16 16 14.433 16 12.5C16 10.567 14.433 9 12.5 9C10.567 9 9 10.567 9 12.5C9 14.433 10.567 16 12.5 16ZM11.5507 12.53L13.4296 10.6511C13.7645 10.3162 14.3073 10.3162 14.6422 10.6511C14.977 10.9859 14.977 11.5288 14.6422 11.8636L12.157 14.3488C11.8221 14.6837 11.2793 14.6837 10.9444 14.3488L9.95034 13.3547C9.61551 13.0199 9.61551 12.477 9.95034 12.1422C10.2852 11.8074 10.8281 11.8074 11.1629 12.1422L11.5507 12.53Z"
          fill={FIN.Lt.Icon.Subdued}
        />
      </g>
    </svg>
  );
};
