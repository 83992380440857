import React from 'react';
import styled from 'styled-components';

import { FIN } from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

const StyledPricePerSeatWrapper = styled.div`
  align-items: flex-end;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin: 8px 0px 16px;
  text-align: center;

  color: ${FIN.Lt.Emphasis.Medium};
`;

const StyledCurrency = styled.span`
  font-family: 'Lexend';
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  height: 28px;
  line-height: 26px;

  color: ${FIN.Lt.Emphasis.High};
`;

const StyledPriceWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  height: 28px;
  justify-content: center;
  padding: 0px;
`;

const StyledPrice = styled.span`
  ${Typography.DisplayS.M500};

  color: ${FIN.Lt.Emphasis.High};
`;

const StyledDetails = styled.span`
  ${Typography.Label12.SB600};

  margin: 0 0.5em;
`;

export const PricePerSeat = ({ price }: { price: string }) => (
  <StyledPricePerSeatWrapper>
    <StyledCurrency>$</StyledCurrency>
    <StyledPriceWrapper>
      <StyledPrice>{price}</StyledPrice>
    </StyledPriceWrapper>
    <StyledDetails>USD per person/month</StyledDetails>
  </StyledPricePerSeatWrapper>
);
