import React from 'react';
import { connect } from 'react-redux';

import * as styled from './styles';

function PmSidebarGroup({ item, isFirstGroup, collapsedGroups, onClick }) {
  const isCollapsed = collapsedGroups[item.projectId];
  return (
    <styled.ListItemGroup
      collapsed={isCollapsed}
      firstGroup={isFirstGroup}
      onClick={() => onClick(item.projectId)}
    >
      <styled.GroupIcon>
        {isCollapsed ? <span>&#x25B8;</span> : <span>&#x25BE;</span>}
      </styled.GroupIcon>
      {item.projectName}
    </styled.ListItemGroup>
  );
}

const mapStateToProps = (state) => ({
  collapsedGroups: state.pmSidebar.collapsedGroups,
});

export default connect(mapStateToProps)(PmSidebarGroup);
