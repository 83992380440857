import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon {...props}>
    <defs>
      <linearGradient
        id="icon-shield-tick-gradient"
        x1="17.09"
        x2="35.691"
        y1="18.081"
        y2="36.123"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5DD0CE" />
        <stop offset="1" stopColor="#1B7CEF" />
      </linearGradient>
    </defs>

    <path
      fill="url(#icon-shield-tick-gradient)"
      fillRule="evenodd"
      d="M24.008 8.818a1.25 1.25 0 0 0-.816 0l-12.6 4.35a1.25 1.25 0 0 0-.842 1.182V24.5c0 4.952 3.557 8.9 6.766 11.486a35.419 35.419 0 0 0 6.515 4.128l.032.015.01.004.003.002L23.6 39l-.523 1.136a1.25 1.25 0 0 0 1.046 0L23.6 39l.523 1.135h.002l.003-.002.009-.004.032-.015.116-.056c.1-.048.243-.118.424-.21.36-.184.872-.455 1.483-.807a35.419 35.419 0 0 0 4.492-3.055c3.21-2.585 6.766-6.534 6.766-11.486V14.35a1.25 1.25 0 0 0-.842-1.182l-12.6-4.35Zm.937 28.056c-.555.32-1.018.566-1.345.733-.327-.167-.79-.413-1.345-.733a32.926 32.926 0 0 1-4.17-2.835c-3.092-2.49-5.835-5.791-5.835-9.539v-9.26l11.35-3.918 11.35 3.919V24.5c0 3.748-2.743 7.05-5.834 9.54a32.926 32.926 0 0 1-4.17 2.834Zm4.568-16.642a1.25 1.25 0 0 0-2.026-1.464l-5.558 7.695L20.46 24.7a1.25 1.25 0 0 0-1.92 1.6l2.5 3a1.25 1.25 0 0 0 1.973-.068l6.5-9Z"
      clipRule="evenodd"
    />
  </BaseIcon>
);
