import { t } from '@lingui/macro';

import { ProjectStatus } from '@float/types/project';
import {
  IconStatusConfirmed,
  IconStatusDraft,
  IconStatusTentative,
} from '@float/ui/components/StatusIcons';

export function getEntityStatusLabel(status: ProjectStatus) {
  const entityStatusLabels = {
    [ProjectStatus.Draft]: t`Draft`,
    [ProjectStatus.Tentative]: t`Tentative`,
    [ProjectStatus.Confirmed]: t`Confirmed`,
  };
  return entityStatusLabels[status];
}

export function getEntityStatusIcon(status: ProjectStatus) {
  const entityStatusLabels = {
    [ProjectStatus.Draft]: IconStatusDraft,
    [ProjectStatus.Tentative]: IconStatusTentative,
    [ProjectStatus.Confirmed]: IconStatusConfirmed,
  };
  return entityStatusLabels[status];
}
