import { proxy } from 'comlink';

import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { AppStoreStrict } from '@float/common/store';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';

import { queryApi } from '../api/queryApi';
import {
  getSearchAutocompleteResults,
  SearchAutocompleteParams,
} from '../selectors/getSearchAutocompleteResults';
import { deriveContextMiddleware } from './deriveContextMiddleware';
import {
  searchRemoteResolveListener,
  startListeningSearchRemoteResolve,
} from './searchRemoteResolve/searchRemoteResolveListener';
import { searchWorkerResultMiddleware } from './searchWorkerResultMiddleware';
import { getSelectorValue } from './worker/getSelectorValue';
import {
  SearchWorkerSelectors,
  SearchWorkerSelectorsKeys,
  SelectorParameters,
} from './worker/searchSelectors';
import { SearchWorkerApiLayer } from './worker/SearchWorkerApiLayer';
import { subscribeToSelector } from './worker/subscribeToSelector';

export class SearchService {
  #workerApi: SearchWorkerApiLayer | null = null;

  setWorkerApi(workerApi: SearchWorkerApiLayer) {
    this.#workerApi = workerApi;
  }

  getReduxMiddleware(nonCloneableActions: Set<string> = new Set()) {
    if (featureFlags.isFeatureEnabled(FeatureFlag.SearchBeyondLimits)) {
      startListeningSearchRemoteResolve();

      return searchRemoteResolveListener.middleware;
    }

    if (this.#workerApi) {
      return searchWorkerResultMiddleware(this.#workerApi, nonCloneableActions);
    }

    // On the main thread we want to debounce the deriveContext more because otherwise
    // it would have a bigger impact on performance
    return deriveContextMiddleware(300);
  }

  /**
   * Makes possible to subscribe a selector which processing happens inside the
   * worker (if the worker is provided)
   *
   * The selector is selected via string value, and must be defined in ./worker/searchSelectors
   *
   * Meant to be used through useSearchSelectorWithParams and never directly
   */
  async subscribeToSelector<S extends SearchWorkerSelectorsKeys>(
    store: AppStoreStrict,
    selectorKey: S,
    callback: (value: ReturnType<SearchWorkerSelectors[S]>) => void,
    args: SelectorParameters<SearchWorkerSelectors[S]>,
  ) {
    if (this.#workerApi) {
      return this.#workerApi.subscribeToSelector(
        selectorKey,
        proxy(callback),
        args,
      );
    }

    return subscribeToSelector(store, selectorKey, callback, args);
  }

  /**
   * Makes possible to process a selector behind the worker.
   *
   * If the worker is not configured the processing happens in
   * the main thread (that's why the main thread state must be provided).
   */
  async getSelectorValue<S extends SearchWorkerSelectorsKeys>(
    state: ReduxStateStrict,
    selectorKey: S,
    args: SelectorParameters<SearchWorkerSelectors[S]>,
  ) {
    if (this.#workerApi) {
      return this.#workerApi.getSelectorValue(selectorKey, args);
    }

    return getSelectorValue(state, selectorKey, args);
  }

  async getSearchAutocompleteResults(
    state: ReduxStateStrict,
    params: SearchAutocompleteParams,
  ) {
    if (featureFlags.isFeatureEnabled(FeatureFlag.SearchBeyondLimits)) {
      const queryResult = await queryApi({
        query: params.rawInput,
        category: params.expandedCategory,
      });

      return getSearchAutocompleteResults(state, {
        ...params,
        remoteQueryResult: queryResult,
      });
    }

    return this.getSelectorValue(state, 'getSearchAutocompleteResults', [
      params,
    ]);
  }
}
