import { Milestone, Phase, TaskMeta } from '@float/types/index';

import { stringCompare } from './sort';

type ItemType = Partial<Phase | Milestone | TaskMeta>;

/**
 * Takes an object type and a type condition, and returns a new object whose
 * value is either equal to the key or never, based on whether or not the value
 * extends the type of the condition.
 */
type NeverIfNotMatch<T, Cond> = {
  [P in keyof T]: T[P] extends Cond ? P : never;
};

/**
 * Returns all non-`never` keys of a type passed through `NeverIfMatch`.
 */
type FilteredKeys<T, Cond> = NeverIfNotMatch<T, Cond>[keyof T];

export const sortByName = <T extends ItemType>(
  list: T[] | undefined,
  nameKey: FilteredKeys<T, string>,
) => {
  if (!list) return [];
  return list.slice().sort((a, b) => {
    return stringCompare(
      (a[nameKey] as string).toLowerCase(),
      (b[nameKey] as string).toLowerCase(),
    );
  });
};

export const sortByDateAndName = <T extends ItemType>(
  list: T[] | undefined,
  dateKey: FilteredKeys<T, string>,
  nameKey: FilteredKeys<T, string>,
) => {
  if (!list) return [];
  return list.slice().sort((a, b) => {
    // If the start dates are the same, sort by name
    const dateCompare = (a[dateKey] as string).localeCompare(
      b[dateKey] as string,
    );
    if (!dateCompare) {
      return stringCompare(
        (a[nameKey] as string).toLowerCase(),
        (b[nameKey] as string).toLowerCase(),
      );
    }
    return dateCompare;
  });
};

type OffsetType = Partial<Phase | Milestone>;

export const sortByOffsetAndName = <T extends OffsetType>(
  list?: T[],
  nameKey?: keyof T,
) => {
  if (!list || !nameKey) return [];
  return list.slice().sort((a, b) => {
    // If the start dates are the same, sort by name
    const offsetCompare = a.parent_offset! - b.parent_offset!;
    if (!offsetCompare) {
      return stringCompare(
        (a[nameKey] as string).toLowerCase(),
        (b[nameKey] as string).toLowerCase(),
      );
    }
    return offsetCompare;
  });
};
