import React from 'react';

import { capitalize } from '@float/libs/utils/string/capitalize';

import {
  EmptyMessageBody,
  EmptyMessageTitle,
  EmptyMessageWrapper,
  NoTaskBackground,
  NoTaskNamesWrapper,
} from './EmptyTaskList.styles';
import { MockTaskItem } from './MockTaskItem';

export function EmptyTaskList({ entityType }) {
  return (
    <NoTaskNamesWrapper>
      <NoTaskBackground>
        <MockTaskItem name="eg. Planning" />
      </NoTaskBackground>
      <EmptyMessageWrapper>
        <EmptyMessageTitle>
          {capitalize(entityType)} has no tasks
        </EmptyMessageTitle>
        <EmptyMessageBody>
          You can still allocate this {entityType} on the schedule, however you
          won’t be able to assign specific tasks.
        </EmptyMessageBody>
      </EmptyMessageWrapper>
    </NoTaskNamesWrapper>
  );
}
