import React from 'react';
import { useWatch } from 'react-hook-form';
import { t } from '@lingui/macro';

import { DurationInputField } from '../../components/DurationInputField';
import { SidePanelTableColumn } from '../../components/SidePanelTableSection/SidePanelTableColumn';
import { SidePanelTableRow } from '../../components/SidePanelTableSection/SidePanelTableRow';
import { MilestoneNameField } from '../../fields/MilestoneNameField';
import { useRemoveOnBlur } from '../../hooks/useRemoveOnBlur';
import { TemplateMilestoneMenu } from './TemplateMilestoneMenu';

import * as styles from '../ProjectMilestonesSection/styles.css';

type TemplateMilestoneRowProps = {
  index: number;
  showingDuration: boolean;
  toggleDuration: (index: number) => void;
};

export function TemplateMilestoneRow(props: TemplateMilestoneRowProps) {
  const { index, showingDuration, toggleDuration } = props;
  const name = `milestones.${index}.name` as const;
  const milestone = useWatch({ name: `milestones.${index}` });
  const { ref: blurRef, onBlurHandler } = useRemoveOnBlur({
    isEmpty: !milestone.name?.trim() && !milestone.milestone_id,
    index,
  });

  return (
    <SidePanelTableRow
      data-testid={`milestone_${index}`}
      ref={blurRef as React.RefObject<HTMLDivElement>}
      className={styles.templateMilestoneGridTemplateColumns}
      onBlur={onBlurHandler}
    >
      <SidePanelTableColumn>
        <MilestoneNameField name={name} autoFocus={!milestone?.name} />
      </SidePanelTableColumn>
      <SidePanelTableColumn>
        <DurationInputField
          name={`milestones.${index}.parent_offset`}
          label={t`Start after`}
          unit="days"
          hideLabel
        />
      </SidePanelTableColumn>
      <SidePanelTableColumn>
        {showingDuration && (
          <DurationInputField
            name={`milestones.${index}.duration`}
            label={t`Duration`}
            placeholder={t`1`}
            unit="days"
            hideLabel
            compact
          />
        )}
      </SidePanelTableColumn>
      <SidePanelTableColumn>
        <TemplateMilestoneMenu
          index={index}
          showingDuration={showingDuration}
          toggleDuration={() => toggleDuration(index)}
        />
      </SidePanelTableColumn>
    </SidePanelTableRow>
  );
}
