import { isOr } from '@float/common/search/helpers';
import { BaseFilterToken } from '@float/types';

export function isLogicalConjunctionChangeBlocked(
  filter: BaseFilterToken,
  prevFilter: BaseFilterToken | undefined,
  forceNoOrBetweenTypes: boolean | undefined,
) {
  if (!forceNoOrBetweenTypes) return false;
  if (!prevFilter) return false;
  if (isOr(filter.operator)) return false;

  return filter.type !== prevFilter.type;
}
