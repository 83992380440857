import { ProjectOptions } from '@float/types';

export function addToAllProjects(
  projectIds: number[],
  options: ProjectOptions[],
) {
  // Start from the current state to avoid the delete of
  // the archived projects
  // https://linear.app/float-com/issue/FT-1281/bug-assigning-all-projects-to-a-person-removes-them-from-all-archived
  const set = new Set(projectIds);

  for (const group of options) {
    for (const { value } of group.options) {
      set.add(parseInt(value));
    }
  }

  return Array.from(set);
}
