import type * as React from 'react';

/**
 * This hook is intended to be used when you
 * have a component forwarding a certain ref
 * that you also need to use locally.
 *
 * e.g., https://itnext.io/reusing-the-ref-from-forwardref-with-react-hooks-4ce9df693dd
 */

const useCombinedRefs = <T = unknown>(
  refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>,
): React.RefCallback<T> => {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref !== null) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
};

export { useCombinedRefs };
