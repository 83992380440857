import { BudgetType, Project } from '@float/types/project';

export const getProjectHasTotalBudgetSupport = (project?: Project) => {
  if (!project) return false;

  return (
    project.budget_type === BudgetType.TotalFee ||
    project.budget_type === BudgetType.TotalHours
  );
};
