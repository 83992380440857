import { WebAppState } from 'reducers/types';
import { createSelector } from 'reselect';

import { ModalType } from './types';

export function getModalManager(state: WebAppState) {
  return state.modalManager;
}

export const getOpenModal = createSelector(
  [getModalManager],
  (modalManager) => {
    for (const [modalType, state] of Object.entries(modalManager)) {
      if (state.showing) {
        return {
          modalType: modalType as ModalType,
          state: state,
        };
      }
    }

    return null;
  },
);
