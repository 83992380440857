import { keyBy } from 'lodash';
import { createSelector } from 'reselect';

import { Rights } from '@float/common/lib/acl/specification/rights';
import { getSearchFilteredPeople } from '@float/common/search/selectors/people';

import { getUser } from '../currentUser';

export const getSearchFilteredActivePeople = createSelector(
  [getSearchFilteredPeople],
  (people) => people.filter((p) => p.active),
);

export const getSearchFilteredActivePeopleWithEmailMap = createSelector(
  [getSearchFilteredPeople],
  (people) =>
    keyBy(
      people.filter((p) => p.active && p.email),
      'people_id',
    ),
);

export const getSearchFilteredActivePeopleMap = createSelector(
  [getSearchFilteredActivePeople],
  (people) => keyBy(people, 'people_id'),
);

export const getLoggableSearchFilteredActivePeople = createSelector(
  [getUser, getSearchFilteredActivePeople],
  (user, people) => {
    return people.filter((person) => {
      return Rights.canCreateLoggedTime(user, {
        person,
        ignore: [
          'isLoggedTimeProjectOwner',
          'onLoggedTimeProjectTeam',
          'loggedTimeProjectIsCommon',
        ],
      });
    });
  },
);
