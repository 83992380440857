import React, { useState } from 'react';
import { useWatch } from 'react-hook-form';

import { SidePanelTableColumn } from '../../components/SidePanelTableSection/SidePanelTableColumn';
import { SidePanelTableRow } from '../../components/SidePanelTableSection/SidePanelTableRow';
import { MilestoneDatesField } from '../../fields/MilestoneDatesField';
import { MilestoneNameField } from '../../fields/MilestoneNameField';
import { createEmptyMilestoneRecord } from '../../helpers/createRowEntities';
import { useAddOnEnterKeyDown } from '../../hooks/useAddOnEnterKeyDown';
import { useRemoveOnBlur } from '../../hooks/useRemoveOnBlur';
import { ProjectMilestoneMenu } from './ProjectMilestoneMenu';

import * as styles from './styles.css';

type ProjectMilestoneProps = {
  index: number;
  addDisabled: boolean;
};

export const ProjectMilestoneRow: React.FC<ProjectMilestoneProps> = ({
  index,
  addDisabled,
}) => {
  const name = `milestones.${index}.name` as const;
  const milestone = useWatch({ name: `milestones.${index}` });
  const [forceShowDateRange, setForceShowDateRange] = useState(
    milestone.date !== milestone.end_date,
  );

  // If any of the milestones have distinct start and end dates, then From and To is shown
  const showDateRange =
    forceShowDateRange || milestone.date !== milestone.end_date;
  const isEmpty = !milestone.name?.trim() && !milestone.milestone_id;

  const { ref: blurRef, onBlurHandler } = useRemoveOnBlur({
    isEmpty,
    index,
  });
  // Adds a new row when the user presses enter
  const { onEnterKeyDown } = useAddOnEnterKeyDown({
    canAdd: !addDisabled && !isEmpty,
    data: createEmptyMilestoneRecord,
  });

  return (
    <SidePanelTableRow
      data-testid={`milestone_${index}`}
      ref={blurRef as React.RefObject<HTMLDivElement>}
      onBlur={onBlurHandler}
      onKeyDown={onEnterKeyDown}
      className={styles.milestoneGridTemplateColumns}
    >
      <SidePanelTableColumn>
        <MilestoneNameField name={name} autoFocus={!milestone?.name} />
      </SidePanelTableColumn>
      <SidePanelTableColumn>
        <MilestoneDatesField
          index={index}
          container={blurRef.current}
          showingDateRange={showDateRange}
        />
      </SidePanelTableColumn>
      <SidePanelTableColumn>
        <ProjectMilestoneMenu
          index={index}
          showingDateRange={showDateRange}
          toggleShowDateRange={() => setForceShowDateRange((prev) => !prev)}
        />
      </SidePanelTableColumn>
    </SidePanelTableRow>
  );
};
