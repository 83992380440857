import React from 'react';

export default ({
  color,
  size,
}: {
  color?: string;
  size?: string | number;
}) => (
  <svg
    width={size || '32'}
    height={size || '32'}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.3334 12.67L24.3334 25.34"
      className="stroke"
      stroke={color ?? '#8290AA'}
      strokeWidth="3.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.66998 16.67L7.66998 25.34"
      className="stroke"
      stroke={color ?? '#8290AA'}
      strokeWidth="3.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 7L16 25.33"
      className="stroke"
      stroke={color ?? '#8290AA'}
      strokeWidth="3.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
