import { createSelector } from 'reselect';

import { getPhases } from './phases';

export const selectProjectsIdsWithPhases = createSelector(
  [getPhases],
  (phases) => {
    return new Set(phases.map((phase) => phase.project_id));
  },
);
