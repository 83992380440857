import { createSelector } from 'reselect';

import { getCompanyPrefs } from '@float/common/selectors/companyPrefs';
import { getHolidaysRawList } from '@float/common/selectors/holidays';
import { CompanyPreferences } from '@float/types/companyPreferences';
import { CustomHoliday } from '@float/types/customHoliday';

import { selectDatesManager } from '../currentUser';
import { getCompanyWorkHours } from './getCompanyWorkHours';

function isCompanyWorkDay(
  dates: DatesManager,
  company: CompanyPreferences,
  holidays: CustomHoliday[],
  date: string,
) {
  const hours = getCompanyWorkHours(dates, company, date);

  if (hours === 0) return false;

  const isHoliday = holidays.some(
    ({ date: holidayDate }) => date === holidayDate,
  );

  if (isHoliday) return false;

  return true;
}

export const selectIsCompanyWorkDayGetter = createSelector(
  [selectDatesManager, getCompanyPrefs, getHolidaysRawList],
  (dates, company, holidays) =>
    isCompanyWorkDay.bind(null, dates, company, holidays),
);
