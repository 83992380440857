import { LoggedTimesFilterMatcher } from './loggedTimes';
import { PeopleFilterMatcher } from './people';
import { ProjectsFilterMatcher } from './projects';
import { TasksFilterMatcher } from './tasks';
import { TimeoffsFilterMatcher } from './timeoffs';

export const FILTER_MATCHERS = {
  tasks: TasksFilterMatcher,
  loggedTimes: LoggedTimesFilterMatcher,
  people: PeopleFilterMatcher,
  projects: ProjectsFilterMatcher,
  timeoffs: TimeoffsFilterMatcher,
};

export { ContainsMatcher } from './contains';
