import React from 'react';

import { useIsTimeoffRequest } from '@float/common/lib/timeoff/hooks/useIsTimeoffRequest';
import { Person } from '@float/types/person';
import { RepeatState } from '@float/types/repeatState';
import { Timeoff } from '@float/types/timeoff';
import { TimeoffType } from '@float/types/timeoffType';

import { TimeoffBalance } from '../TimeoffBalance/TimeoffBalance';
import { TimeoffSelectReadOnly } from '../TimeoffSelectReadOnly';

import * as styles from './TimeoffApproveRejectHeader.css';

export type TimeoffApproveRejectHeaderProps = {
  endDate: Date;
  hoursPd: number;
  ignoreTimeoffId: Timeoff['timeoff_id'];
  isReadOnly: boolean;
  personId: Person['people_id'];
  personStartDate: Person['start_date'];
  repeatState: RepeatState;
  repeatEndDate: Date | null;
  startDate: Date;
  timeoff: Timeoff;
  timeoffType: TimeoffType;
};

export const TimeoffApproveRejectHeader = (
  props: TimeoffApproveRejectHeaderProps,
) => {
  const {
    endDate,
    hoursPd,
    ignoreTimeoffId,
    isReadOnly,
    personId,
    personStartDate,
    repeatEndDate,
    repeatState,
    startDate,
    timeoff,
    timeoffType,
  } = props;

  const isTimeoffRequest = useIsTimeoffRequest(timeoff, isReadOnly);

  return (
    <div className={styles.inlineTimeoffTypeWrapper}>
      <TimeoffSelectReadOnly
        className={styles.timeoffType}
        size="small"
        timeoffType={timeoffType}
      />
      <TimeoffBalance
        endDate={endDate}
        hoursPd={hoursPd}
        ignoreTimeoffId={ignoreTimeoffId}
        isTimeoffRequest={isTimeoffRequest}
        personId={personId}
        personStartDate={personStartDate}
        repeatState={repeatState}
        repeatEndDate={repeatEndDate}
        startDate={startDate}
        timeoffType={timeoffType}
      />
    </div>
  );
};
