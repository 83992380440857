import React, { Fragment, useCallback, useState } from 'react';
import styled from 'styled-components';

import { getUser } from '@float/common/selectors/currentUser';
import {
  useAppDispatchDecorator,
  useAppSelectorStrict,
} from '@float/common/store';
import { DEFAULT_COLOR } from '@float/constants/colors';
import { moment } from '@float/libs/moment';
import { ModalConfirm } from '@float/ui/components/Modals/ModalConfirm';
import {
  Button,
  Checkbox,
  ColorPicker,
  DatePicker,
  Input,
  Label,
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  ModalTitle,
  Spacer,
  useSnackbar,
  VirtualSelect,
  withConfirm,
} from '@float/ui/deprecated';
import * as Icons from '@float/ui/deprecated/Earhart/Icons';
import { required, useInput } from '@float/ui/deprecated/helpers/formHooks';
import {
  addTimeoffType,
  updateTimeoffType,
} from '@float/web/settingsV2/actions/timeoffTypes';

import { TimeoffTypeActionsMenu } from './TimeoffTypeActionsMenu';
import { useDeleteTimeoffType } from './TimeoffTypeModal.hooks';
import { useDaysInputFormat } from './useDaysInputFormat';

const MAX_DAYS = 366;

export const balanceOptions = [
  { value: 0, label: 'Unlimited' },
  { value: 1, label: 'Accrued per calendar year' },
  { value: 2, label: 'Accrued per work anniversary' },
  { value: 3, label: 'Granted upfront per calendar year' },
  { value: 4, label: 'Granted upfront per work anniversary' },
];

function useColor(defaultColor) {
  const [value, setValue] = useState(defaultColor);
  const onChange = (newColor) => setValue(newColor);
  return { value, setValue, onChange };
}

export const BalanceRow = styled.div`
  display: flex;
`;

const DateContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 150px;

  input {
    font-size: 18px !important;
  }
`;

const CarryOverLabel = styled(Label)`
  padding-top: 0;
`;

function useDate(defaultDate) {
  const [value, setValue] = useState(defaultDate);
  const onChange = (newDate) => setValue(newDate);
  return { value, setValue, onChange };
}

const TimeoffTypeModal = ({ timeoffType, newTimeoffType, confirm, close }) => {
  let originalName;
  if (!timeoffType) {
    timeoffType = {
      timeoff_type_name: '',
      color: DEFAULT_COLOR,
      balance_type: 0,
      balance_days: null,
      carryover_days: null,
      effective_date: moment().startOf('year').toDate(),
    };
  } else {
    originalName = timeoffType.timeoff_type_name;
  }
  const { showSnackbar } = useSnackbar();
  const dispatchUpdateTimeoffType = useAppDispatchDecorator((data, id) =>
    id ? updateTimeoffType(id, data) : addTimeoffType(data),
  );

  const startWorkWeek = useAppSelectorStrict(
    (state) => getUser(state).start_work_week,
  );
  const name = useInput(timeoffType.timeoff_type_name, {
    validate: required('Name is required.'),
  });
  const balanceType = useInput(timeoffType.balance_type ?? 0, {
    validate: required('Balance type is required.'),
  });
  const validateDays = (val) => {
    if (val === '' || val == 0) {
      return [''];
    }
    return [];
  };
  const balanceDays = useInput(
    `${
      typeof timeoffType.balance_days !== 'undefined'
        ? parseFloat(timeoffType.balance_days)
        : 20
    }`,
    {
      validate: balanceType.value !== 0 ? validateDays : null,
    },
  );
  const carryOverDays = useInput(
    timeoffType.carryover_days ? `${timeoffType.carryover_days}` : null,
    {
      validate: validateDays,
    },
  );
  const effectiveDate = useDate(
    moment(timeoffType.effective_date ?? moment().startOf('year')),
  );
  const color = useColor(timeoffType.color || DEFAULT_COLOR);
  const title = newTimeoffType ? 'Add new time off' : 'Edit time off';

  const handleErr = useCallback(
    (err) => {
      const errors = Array.isArray(err) ? err : [err];
      errors.forEach((err) => {
        switch (err.field) {
          case 'timeoff_type_name': {
            return name.setErrors([err.message]);
          }
          case 'balance_type': {
            return balanceDays.setErrors([err.message]);
          }
          case 'carryover_days': {
            return carryOverDays.setErrors([err.message]);
          }
          default:
            showSnackbar(err.message);
        }
      });
    },
    [name, balanceDays, carryOverDays, showSnackbar],
  );

  const shouldShowDays = balanceType.value !== 0;
  const [shouldShowCarryOverInput, setShouldShowCarryOverInput] = useState(
    !!carryOverDays.value,
  );
  const toggleCarryOverMode = useCallback(() => {
    const nextValue = !shouldShowCarryOverInput;
    carryOverDays.onChange({
      value: nextValue ? carryOverDays.value ?? balanceDays.value : null,
    });
    setShouldShowCarryOverInput(nextValue);
  }, [shouldShowCarryOverInput, balanceDays.value, carryOverDays]);

  function submit() {
    if (!name.validate()) return;
    if (!balanceType.validate()) return;
    if (!balanceDays.validate()) return;
    if (!carryOverDays.validate()) return;
    const id = !newTimeoffType && timeoffType.timeoff_type_id;
    dispatchUpdateTimeoffType(
      {
        timeoff_type_name: name.value,
        balance_type: balanceType.value,
        balance_days: balanceType.value ? +balanceDays.value : null,
        carryover_days: carryOverDays.value ? +carryOverDays.value : null,
        effective_date: balanceType.value
          ? moment(effectiveDate.value).format('YYYY-MM-DD')
          : null,
        color: color.value,
      },
      id,
    )
      .then(() => {
        close();
        showSnackbar(`${name.value} ${id ? 'updated' : 'added'}.`);
      })
      .catch(handleErr);
  }

  function archive() {
    dispatchUpdateTimeoffType({ active: 0 }, timeoffType.timeoff_type_id)
      .then(() => {
        close();
        showSnackbar(`${timeoffType.timeoff_type_name} moved to archived.`);
      })
      .catch(handleErr);
  }

  function activate() {
    dispatchUpdateTimeoffType({ active: 1 }, timeoffType.timeoff_type_id)
      .then(() => {
        close();
        showSnackbar(`${timeoffType.timeoff_type_name} moved to active.`);
      })
      .catch(handleErr);
  }

  const { handleChange: handleChangeDays, handleBlur: handleBlurDays } =
    useDaysInputFormat(balanceDays, {
      min: 0.001,
      max: 366,
      use3Digits: true,
    });
  const {
    handleChange: handleChangeCarryOverDays,
    handleBlur: handleBlurCarryOverDays,
  } = useDaysInputFormat(carryOverDays, {
    min: 0.001,
    max: 366,
    use3Digits: true,
  });

  const deleteConfirm = useDeleteTimeoffType(timeoffType, originalName, close);

  return (
    <Modal isOpen={true} onEnter={submit} onClose={close}>
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form>
          <section className="inputs">
            <Input
              autoFocus
              label="Name *"
              style={{ marginBottom: '20px' }}
              {...name}
            />
            <ColorPicker
              {...color}
              highContrast
              style={{ marginBottom: '20px' }}
            />
            <BalanceRow>
              <VirtualSelect
                label="Balance"
                clearInputOnDropdownOpen={false}
                nonNullable
                visibleItems={6}
                options={balanceOptions}
                style={{
                  marginBottom: '7px',
                  ...(shouldShowDays ? { width: '70%', marginRight: 40 } : {}),
                }}
                {...balanceType}
              />
              {shouldShowDays && (
                <Input
                  autoFocus
                  label="Days"
                  type="number"
                  min={0.001}
                  max={MAX_DAYS}
                  step={0.001}
                  style={{ marginBottom: '7px' }}
                  hideArrows
                  {...balanceDays}
                  value={balanceDays.value}
                  onChange={handleChangeDays}
                  onBlur={handleBlurDays}
                />
              )}
            </BalanceRow>
            {balanceType.value !== 0 && (
              <BalanceRow>
                <Checkbox
                  label="Carry over unused days"
                  value={shouldShowCarryOverInput}
                  onChange={toggleCarryOverMode}
                />
                {shouldShowCarryOverInput && (
                  <Fragment>
                    <CarryOverLabel style={{ marginLeft: 3, marginRight: 3 }}>
                      {' '}
                      up to{' '}
                    </CarryOverLabel>
                    <Input
                      type="number"
                      min={0.001}
                      max={MAX_DAYS}
                      step={0.001}
                      minHeight={20}
                      maxWidth={48}
                      hideArrows
                      color="#868d92"
                      style={{ marginRight: 4 }}
                      skipErrorsIcon
                      inputStyle={{
                        fontSize: 14,
                        marginBottom: -4,
                        textAlign: 'end',
                        height: 24,
                      }}
                      {...carryOverDays}
                      value={carryOverDays.value}
                      onChange={handleChangeCarryOverDays}
                      onBlur={handleBlurCarryOverDays}
                    />
                    <CarryOverLabel>days</CarryOverLabel>
                  </Fragment>
                )}
              </BalanceRow>
            )}
            {balanceType.value !== 0 && (
              <DateContainer>
                <DatePicker
                  height={40}
                  label="Effective date"
                  {...effectiveDate}
                  value={effectiveDate.value}
                  onChange={(date) => {
                    effectiveDate.onChange(date);
                  }}
                  firstOfWeek={startWorkWeek}
                />
              </DateContainer>
            )}
          </section>
        </form>
      </ModalBody>
      <ModalActions>
        <Button onClick={submit}>
          {!newTimeoffType ? 'Update' : 'Add New'}
        </Button>
        <Button appearance="secondary" onClick={close}>
          Cancel
        </Button>
        {!newTimeoffType &&
          (timeoffType.active ? (
            <>
              <Spacer axis="x" />
              <Button
                appearance="clear"
                icon={Icons.IconArchive}
                onClick={() => {
                  confirm({
                    title: `Move ${timeoffType.timeoff_type_name} to archive?`,
                    message: `
                      Archiving Time off types makes them unavailable for new Time offs
                      without deleting any of your historical Time off data.
                    `,
                    confirmLabel: 'Move to archive',
                    cancelLabel: 'Cancel',
                    onConfirm: archive,
                    onCancel: close,
                  });
                }}
              >
                Archive
              </Button>
            </>
          ) : (
            <TimeoffTypeActionsMenu
              onActiveClick={activate}
              onDelete={() => deleteConfirm.setOpen(true)}
            />
          ))}
      </ModalActions>
      {deleteConfirm.open && <ModalConfirm {...deleteConfirm.confirmProps} />}
    </Modal>
  );
};

export default withConfirm(TimeoffTypeModal);
