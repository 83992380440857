import * as Colors from '@float/ui/deprecated/Earhart/Colors';

export const DEFAULT_MAX_LENGTH = 255;
export const DEFAULT_TOOLTIP_PLACEMENT = 'right';
export const TOOLTIP_DELAY = 0;
export const TOOLTIP_DISTANCE = 4;

export const THEME_CONTRACT = {
  base: {
    backgroundColor: '',
    borderColor: '',
    placeholderColor: '',
    textColor: '',
    iconColor: '',
  },
  hover: {
    backgroundColor: '',
    borderColor: '',
    placeholderColor: '',
    textColor: '',
  },
  focus: {
    backgroundColor: '',
    borderColor: '',
    placeholderColor: '',
    textColor: '',
  },
  disabled: {
    backgroundColor: '',
    borderColor: '',
    textColor: '',
  },
  readonly: {
    backgroundColor: '',
    borderColor: '',
    textColor: '',
  },
};

export const BASE_INPUT_THEME = {
  base: {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    placeholderColor: Colors.Core.Text.Subdued,
    textColor: Colors.Core.Text.Default,
    iconColor: Colors.Core.Icon.Secondary,
  },
  hover: {
    backgroundColor: Colors.Core.ActionFlay.Hovered,
    borderColor: Colors.Core.ActionFlay.Hovered,
    placeholderColor: Colors.Core.Text.Subdued,
    textColor: Colors.Core.Text.Default,
  },
  focus: {
    backgroundColor: Colors.FIN.Lt.Surface.Surf1,
    borderColor: Colors.Core.ActionFlay.Hovered,
    placeholderColor: Colors.Core.Text.Subdued,
    textColor: Colors.Core.Text.Default,
  },
  disabled: {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    textColor: Colors.Core.Text.Disabled,
  },
  readonly: {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    textColor: Colors.Core.Text.Subdued,
  },
};

export const BASE_TAG_THEME = {
  base: {
    borderColor: Colors.Radix.Primary.Flay[4],
    backgroundColor: Colors.Radix.Primary.Flay[4],
    placeholderColor: Colors.Core.Text.Subdued,
    textColor: Colors.Core.Text.Default,
    iconColor: Colors.Core.Icon.Secondary,
  },
  hover: {
    backgroundColor: Colors.Core.ActionFlay.Hovered,
    borderColor: Colors.Core.ActionFlay.Hovered,
    placeholderColor: Colors.Core.Text.Subdued,
    textColor: Colors.Core.Text.Default,
  },
  focus: {
    backgroundColor: Colors.FIN.Lt.Surface.Surf1,
    borderColor: Colors.Core.ActionFlay.Hovered,
    placeholderColor: Colors.Core.Text.Subdued,
    textColor: Colors.Core.Text.Default,
  },
  disabled: {
    borderColor: Colors.Radix.Primary.Flay[4],
    backgroundColor: Colors.Radix.Primary.Flay[4],
    textColor: Colors.Core.Text.Disabled,
  },
  readonly: {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    textColor: Colors.Core.Text.Subdued,
  },
};
