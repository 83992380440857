import React, { ComponentPropsWithRef, forwardRef, useCallback } from 'react';
import cn from 'classnames';

import { FilterCategory } from '@float/common/search/types';
import { IconArrowLeft } from '@float/ui/icons/essentials/IconArrowLeft';

import { SearchFilterCategory } from './components/SearchFilterCategory';
import { SearchFilterToggleIsNot } from './components/SearchFilterToggleIsNot';

import * as styles from './styles.css';

type SearchFilterBreadcrumbProps = {
  onClick: (value: FilterCategory) => void;
  onOperatorChange: (operator: string) => void;
  operator: string | undefined;
  type: FilterCategory;
} & Omit<ComponentPropsWithRef<'div'>, 'onClick'>;

const SearchFilterBreadcrumb = forwardRef<
  HTMLDivElement,
  SearchFilterBreadcrumbProps
>((props, ref) => {
  const { onClick, type, operator, onOperatorChange, className } = props;

  const handleSettingsNavClick = useCallback(
    () => onClick(type),
    [onClick, type],
  );

  return (
    <div className={cn(styles.searchFilterBreadcrumb, className)} ref={ref}>
      <button
        className={styles.button}
        onClick={handleSettingsNavClick}
        type="button"
      >
        <IconArrowLeft size={20} />
      </button>

      <SearchFilterCategory
        className={styles.category}
        onClick={onClick}
        type={type}
      />

      {type !== 'savedSearches' && (
        <SearchFilterToggleIsNot value={operator} onChange={onOperatorChange} />
      )}
    </div>
  );
});

export { SearchFilterBreadcrumb };
