import React from 'react';

import { CellItem } from '@float/types';

import * as styles from './OneOffItem.css';

type OneOffItemProps = {
  item: CellItem<'oneOff'>;
  dayWidth: number;
  suvSingleDay?: string | null;
};

function OneOffItem({ item, dayWidth, suvSingleDay }: OneOffItemProps) {
  const { x = 0, w } = item;

  const left = x * dayWidth + 1;
  let width: string | number = w * dayWidth - 1;

  if (suvSingleDay) {
    width = 'calc(100% - 1px)';
  }

  return (
    <div
      className={styles.box}
      style={{
        left,
        top: suvSingleDay ? 0 : -1,
        width,
        height: '100%',
      }}
    />
  );
}

export default OneOffItem;
