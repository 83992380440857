import { css } from 'styled-components';

import ResizeService from '@float/libs/web/resizeService';

import { Breakpoint, breakpoints } from './breakpoints';

let bodyComputedStyle: CSSStyleDeclaration;
let currentBreakpoint: Breakpoint;

const mediaBridge = (): Breakpoint => {
  bodyComputedStyle = window.getComputedStyle(document.body);

  return bodyComputedStyle
    .getPropertyValue('--current-breakpoint')
    ?.replace(/"|'/g, '') as Breakpoint;
};

const updateBodyComputedStyle = (): void => {
  currentBreakpoint = mediaBridge();
};

const updateWindowHeight = (): void => {
  const { documentElement } = document;
  documentElement.style.setProperty(
    '--screen-height',
    `${window.innerHeight}px`,
  );
};

updateBodyComputedStyle();
updateWindowHeight();

// update on window resize
ResizeService.add(updateBodyComputedStyle);
ResizeService.add(updateWindowHeight);

export const media = Object.keys(breakpoints).reduce(
  (
    accumulator: Record<
      string,
      (styles: TemplateStringsArray) => ReturnType<typeof css>
    >,
    label: string,
  ) => {
    accumulator[label] = (styles: TemplateStringsArray) => {
      return css`
        @media ${breakpoints[label as keyof typeof breakpoints]} {
          ${styles.join('')}
        }
      `;
    };

    return accumulator;
  },
  {},
);

export const getMedia = (): Breakpoint => currentBreakpoint || mediaBridge();

export const isMedia = (media: Breakpoint): boolean => getMedia() === media;
export const isMediaSmall = (): boolean => getMedia() === 'small';
export const isMediaMedium = (): boolean => getMedia() === 'medium';
export const isMediaLarge = (): boolean => getMedia() === 'large';
export const isMediaXLarge = (): boolean => getMedia() === 'xlarge';
