import React from 'react';
import styled from 'styled-components';

import { moment } from '@float/libs/moment';

const BottomDay = styled.div`
  color: ${(p) => (p.isActive ? 'black' : p.theme.medGrey)};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
`;

const DayOfWeek = styled.div`
  font-size: 12px;
  text-transform: uppercase;
`;

const DayNumber = styled.div`
  font-size: 18px;
`;

export default function BottomWeekDays(props) {
  const { days = [], activeDay, setActiveDay } = props;

  return days.map((dayStr) => {
    const day = moment(dayStr);
    return (
      <BottomDay
        key={dayStr}
        isActive={dayStr === activeDay}
        onClick={(evt) => setActiveDay(dayStr)}
      >
        <DayOfWeek>{day.format('ddd')}</DayOfWeek>
        <DayNumber>{day.format('D')}</DayNumber>
      </BottomDay>
    );
  });
}
