import { motion } from 'framer-motion';
import styled from 'styled-components';

import { LinkLabel, ModalActions, ModalHeader } from '@float/ui/deprecated';
import EH from '@float/ui/deprecated/Earhart';
import { FieldWrapper } from '@float/ui/deprecated/Input/styles';

export const StyledModalHeader = styled(motion(ModalHeader))`
  width: 100%;

  align-items: center;
  justify-content: space-between;

  padding: 24px 30px 3px 30px;
`;

export const StyledModalActions = motion(ModalActions);

export const StyledHeaderDate = styled.div`
  ${EH.Typography.TextL.R400};
`;

export const StyledProjectAndPhase = styled.div<{ $loading: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 26px;
  margin-bottom: 26px;
  opacity: ${({ $loading }) => ($loading ? 0.6 : 1)} ${FieldWrapper} {
    width: 100%;
  }
`;

export const StyledSection = styled(motion.div)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 22px;
`;

export const StyledSectionLink = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 10px 0 0 0;
`;

export const StyledViewInPM = styled(LinkLabel)`
  color: ${(p) => p.theme.blueGrey} !important;
`;

export const StyledCarryOverText = styled.span`
  ${EH.Typography.Label12.R400};
  color: ${EH.Colors.TCore.Emp.Medium11};
  margin-right: 1px;
`;

export const StyledInputsWrapper = styled(motion.div)`
  position: relative;
  z-index: 1;
`;
