import { SkedZoom } from '@float/types/account';

export const SHARE_PAGE_PATHNAME = 'share';

export const getIsCurrentPageASharedLink = () =>
  Boolean(
    typeof window !== 'undefined' &&
      window.location &&
      window.location.pathname.startsWith(`/${SHARE_PAGE_PATHNAME}`),
  );

/**
 * If the current page is a shared page link, returns the token at the end of the path
 * e.g. if the URL is "https://app.float.com/share/SOME_TOKEN" it will return "SOME_TOKEN".
 *
 * If the page is not a share page or is missing a path entry after "/share" it will return undefined.
 */
export const getSharedPageLinkToken = () => {
  const isShareLink = getIsCurrentPageASharedLink();

  if (isShareLink) {
    const endOfPath = window.location.pathname.split('/').at(-1);
    const endsWithToken = endOfPath !== SHARE_PAGE_PATHNAME;

    if (endsWithToken) {
      return endOfPath;
    }
  }

  return undefined;
};

/**
 * If the current page is a shared page link, returns the schedule density at the end of the path
 * e.g. if the URL is "https://app.float.com/share/SOME_TOKEN?sz=1" it will return 1.
 *
 * Returns 0 if `sz` is not present.
 */
export const getScheduleDensityFromSharedLink = (): SkedZoom => {
  const scheduleZoom = Number(
    (window.location.search?.match(/(?:sz=)(\d)/) || [])[1] ?? 0,
  );

  return scheduleZoom;
};
