import React from 'react';
import { Trans } from '@lingui/macro';

import { updatePersonProjects } from '@float/common/actions/people';

export default function removeFromProjects({
  reduxDispatch,
  reduxData,
  confirmDelete,
}) {
  return (person, projects) => {
    const projectIds = projects.map((p) => p.project_id);

    const processUnassign = async () => {
      if (projects.length) {
        await reduxDispatch(
          updatePersonProjects(person.people_id, {
            del: projects.map((p) => p.project_id),
            add: [],
          }),
        );
      }
    };

    const hasTaskInProjects = () => {
      const tasks = reduxData?.tasks || {};
      for (const key in tasks) {
        const task = tasks[key];
        if (!task.people_ids.includes(person.people_id)) continue;
        if (projectIds.includes(task.project_id)) {
          return true;
        }
      }
      return false;
    };

    if (!hasTaskInProjects()) {
      processUnassign();
      return;
    }

    const these = projects.length === 1 ? 'this project' : 'these projects';
    const title = projects.length === 1 ? 'project' : 'projects';

    confirmDelete({
      title: `Remove from ${title}?`,
      message: (
        <>
          <p>
            <span>
              <Trans>You're about to remove</Trans>{' '}
            </span>
            <strong>{person.name}</strong>
            <span>
              {' '}
              <Trans>from</Trans> {these}:
            </span>
          </p>
          <ul style={{ margin: '1em 0' }}>
            {projects.map((pr) => (
              <li key={pr.project_id}>
                <b>{pr.project_name}</b>
              </li>
            ))}
          </ul>
          <p>
            <Trans>
              All tasks allocated to this person for {these} will be deleted.
            </Trans>
          </p>
        </>
      ),
      onDelete: processUnassign,
    });
  };
}
