import { FormEvent, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { getUser } from 'selectors/currentUser';

import { useAppSelector } from '@float/common/store';

import { useFieldArrayContext } from '../../hooks/useFieldArrayContext';
import { ProjectFormData, ProjectTaskRecord } from '../../types';

export function useTaskDeleteImpact(
  items: ProjectTaskRecord | ProjectTaskRecord[],
) {
  const currentUser = useAppSelector(getUser);
  const tasks = Array.isArray(items) ? items : [items];
  let scheduledTasks = 0;
  let loggedTasks = 0;
  const isTimeTrackingEnabled = currentUser.time_tracking > 0;

  tasks.forEach((task) => {
    if (task.count_tasks) {
      scheduledTasks += +task.count_tasks;
    }
    if (isTimeTrackingEnabled && task.count_logged_time) {
      loggedTasks += +task.count_logged_time;
    }
  });

  return scheduledTasks || loggedTasks
    ? { scheduledTasks, loggedTasks }
    : false;
}

export const useSelectedTasks = (tasks: ProjectTaskRecord[]) => {
  const data = useMemo(() => {
    return tasks.reduce<{
      tasks: ProjectTaskRecord[];
      indices: number[];
    }>(
      (acc, curr, index) => {
        if (curr.isSelected) {
          acc.tasks.push(curr);
          acc.indices.push(index);
        }
        return acc;
      },
      {
        tasks: [],
        indices: [],
      },
    );
  }, [tasks]);
  return data;
};

export const useToggleSelectAllHandler = () => {
  const { setValue } = useFormContext();

  const tasks = useWatch<ProjectFormData, 'tasks'>({
    name: 'tasks',
  });
  const selectedTasks = useSelectedTasks(tasks);
  const someRowsSelected = Boolean(selectedTasks.indices.length);

  const onToggleSelectAll = (event?: React.MouseEvent) => {
    event?.preventDefault();
    // If one or more rows are selected -> unselect them
    // Otherwise select all rows
    if (!someRowsSelected) {
      tasks.forEach((_t, index) => {
        setValue(`tasks.${index}.isSelected`, true);
      });
    } else {
      selectedTasks.indices.forEach((index) => {
        setValue(`tasks.${index}.isSelected`, false);
      });
    }
  };

  return {
    onToggleSelectAll,
    someRowsSelected,
  };
};

export const useMergeTasks = (
  newTaskName: string,
  isUnnamedTask: boolean,
  onDone: () => void,
) => {
  const { remove, append } = useFieldArrayContext<ProjectFormData, 'tasks'>();
  const tasks = useWatch<ProjectFormData, 'tasks'>({
    name: 'tasks',
  });
  const selected = useSelectedTasks(tasks);

  const callback = async (event?: FormEvent) => {
    event?.preventDefault();
    event?.stopPropagation();
    const data: Pick<
      ProjectTaskRecord,
      'billable' | 'budget' | 'count_logged_time' | 'count_tasks'
    > = {
      count_logged_time: 0,
      count_tasks: 0,
      billable: 0,
      budget: null,
    };
    const toRemove: number[] = [];
    selected.tasks.forEach((task, index) => {
      if (task.task_meta_id) {
        // FE only data can just be removed
        toRemove.push(task.task_meta_id);
      }
      data.count_logged_time += task.count_logged_time;
      data.count_tasks += task.count_tasks;
      data.billable = data.billable || task.billable;
    });
    remove(selected.indices);
    append({
      isSelected: false,
      // the row with mergeCreate will contain the data that the merge api requires
      // If there are no tasks to remove in the BE, then we can just create this
      // task as normal with no api calls by setting this to false
      merge: toRemove.length ? toRemove : undefined,
      task_meta_id: 0,
      task_name: newTaskName.trim(),
      unnamedTask: isUnnamedTask,
      ...data,
    });
    onDone();
  };

  return callback;
};
