import ModalActions from './ModalActions/ModalActions';
import ModalBody from './ModalBody/ModalBody';
import { ModalContentPlaceholder } from './ModalBody/styles';
import ModalBg from './ModalDialog/ModalBg';
import BaseModal from './ModalDialog/ModalDialog';
import ModalHeader from './ModalHeader/ModalHeader';
import {
  TopGreySection as ModalTopGreySection,
  StyledModalEmptyMsg,
} from './ModalHeader/styles';
import ModalTitle from './ModalTitle/ModalTitle';
import withConfirm from './withConfirm';

type ModalSubComponents = {
  Header: typeof ModalHeader;
  TopGreySection: typeof ModalTopGreySection;
  EmptyMessage: typeof StyledModalEmptyMsg;
  Title: typeof ModalTitle;
  Body: typeof ModalBody;
  Actions: typeof ModalActions;
  Bg: typeof ModalBg;
};

const Modal = BaseModal as typeof BaseModal & ModalSubComponents;

Modal.Header = ModalHeader;
Modal.TopGreySection = ModalTopGreySection;
Modal.EmptyMessage = StyledModalEmptyMsg;
Modal.Title = ModalTitle;
Modal.Body = ModalBody;
Modal.Actions = ModalActions;
Modal.Bg = ModalBg;

export {
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalActions,
  ModalBg,
  Modal,
  ModalTopGreySection,
  ModalContentPlaceholder,
  withConfirm,
};
