import { TimeoffStatuses } from '@float/types/timeoffStatus';

export const isFullDayTimeoff = (timeoff) => {
  return timeoff.full_day;
};

export const isTentativeTimeoff = (timeoff) => {
  return timeoff.status === TimeoffStatuses.TENTATIVE;
};

export const isRejectedTimeoff = (timeoff) => {
  return timeoff.status === TimeoffStatuses.DECLINED;
};

export const isApprovedTimeoff = (timeoff) => {
  return timeoff.status === TimeoffStatuses.APPROVED;
};

export const isConfirmedTimeoff = (timeoff) => {
  return timeoff.status === TimeoffStatuses.CONFIRMED;
};
