import { useState } from 'react';
import { t } from '@lingui/macro';

import { LOGGED_TIME_BULK_CREATED } from '@float/common/actions';
import { persistBulkLoggedTimes } from '@float/common/components/Schedule/actions/persistLoggedTimeChanges';
import { asyncProcessEvent } from '@float/common/lib/asyncProcess';
import { selectPersonById } from '@float/common/selectors/people/people';
import { selectIteratorOverAllLoggedTimeSuggestions } from '@float/common/serena/Data/insights/selectors/selectIteratorOverAllLoggedTimeSuggestions';
import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import { useAppDispatch, useAppStore } from '@float/common/store';
import { logger } from '@float/libs/logger';
import { LoggedTime } from '@float/types/loggedTime';

import { getPriority } from './helpers/getPriority';
import { sortSuggestions } from './helpers/sortSuggestions';
import { BulkLoggedTimeSuggestion } from './types';

// Using a global mutable source because:
// - We want to block concurrent requests on a global app scale
//   (a user can hit a single person bulk log and then the global one)
// - This an api lock, so it is ok to have it as a mutable value
const processingPeopleIds = new Set<number>();

export function useLogAllTasksInTimeRange() {
  const { timeRange } = useScheduleContext();

  const store = useAppStore();
  const reduxDispatch = useAppDispatch();

  const [isLoading, setLoading] = useState(false);

  const logAllTasksInTimeRange = ({
    peopleIds,
    start = timeRange.start_date,
    end = timeRange.end_date,
  }: {
    peopleIds: number[];
    start?: string;
    end?: string;
  }) => {
    // Is there a lock on any of these people?
    const peopleIdsToLog = peopleIds.filter(
      (id) => !processingPeopleIds.has(id),
    );

    if (peopleIdsToLog.length === 0) return;

    // Lock the people
    peopleIdsToLog.forEach((id) => {
      processingPeopleIds.add(id);
    });

    const forEachLoggedTimeSuggestion =
      selectIteratorOverAllLoggedTimeSuggestions(store.getState(), {
        startDate: start,
        endDate: end,
      });

    const changes: BulkLoggedTimeSuggestion[] = [];

    for (const personId of peopleIdsToLog) {
      const person = selectPersonById(store.getState(), personId);

      if (!person) continue;

      forEachLoggedTimeSuggestion(person, (entity, date) => {
        changes.push({
          entity,
          date,
          person,
          priority: getPriority(entity, person),
        });
      });
    }

    setLoading(true);

    if (!changes.length) {
      return Promise.resolve();
    }

    return (
      persistBulkLoggedTimes({
        changes: sortSuggestions(changes),
        reduxDispatch,
      })
        .then((res) => {
          let time = 0;
          const peopleIdMap: Record<number, boolean> = {};
          if (res?.loggedTime) {
            res.loggedTime.forEach((lt: LoggedTime) => {
              time += lt.hours || 0;
              peopleIdMap[lt.people_id] = true;
            });
          }
          asyncProcessEvent({
            processId: 'bulk-log',
            processType: LOGGED_TIME_BULK_CREATED,
            time,
            people: Object.keys(peopleIdMap).length,
          });
        })
        .catch((err) => {
          logger.error(
            'An error occurred while persisting bulk logged times',
            err,
          );

          let title = t`An error occurred`;
          let message = t`Please reload your browser and try again.`;
          if (err?.message && err.message.startsWith('Max request size')) {
            title = t`Too many tasks`;
            message = t`Please try logging fewer tasks. You could shorten the time range or apply a filter to reduce scope.`;
          }

          reduxDispatch({
            type: `modalManager_SHOW`,
            skipSidePanelAutoClose: false,
            modalType: 'ConfirmModal',
            modalSettings: {
              title,
              message,
              hideCancel: true,
            },
          });
        })
        // The time range insights are updated using a debounce system, so when the
        // action is completed the button that has triggered this action will take some
        // extra time to be updated
        // To avoid showing an active state before showing the correct "everything is logged" state
        // we have added a 1s delay here before turning off the loading state.
        // TODO: Remove the update debounce on TimeRangeInsights
        // https://linear.app/float-com/issue/PI-115/ux-remove-the-debounce-on-the-time-range-insights-update
        .then(() => new Promise((resolve) => setTimeout(resolve, 1000)))
        .then(() => {
          setLoading(false);

          // Release the lock
          peopleIdsToLog.forEach((id) => {
            processingPeopleIds.delete(id);
          });
        })
    );
  };

  return {
    logAllTasksInTimeRange,
    isLoading, // to update the UI state
  };
}
