import {
  ADD_ROLE_SUCCESS,
  addRoleSuccess,
  deleteRoleSuccess,
  updateRoleSuccess,
} from '@float/common/actions';
import { AppDispatchStrict } from '@float/common/store';
import { LiveUpdateNotification } from '@float/types';

export function handleRoleLiveUpdate(
  notification: LiveUpdateNotification,
  dispatch: AppDispatchStrict,
) {
  if (notification.type !== 'role') return;

  switch (notification.action) {
    case 'bulk_update': {
      for (const role of notification.result) {
        dispatch({
          type: ADD_ROLE_SUCCESS,
          payload: role,
        });
      }

      break;
    }
    case 'create': {
      const role = notification.af_item.after_data;

      dispatch(addRoleSuccess(role));

      break;
    }
    case 'update': {
      const role = notification.af_item.after_data;

      dispatch(updateRoleSuccess(role));

      break;
    }
    case 'delete': {
      const roleId = notification.af_item.before_data.id;

      dispatch(deleteRoleSuccess(roleId));

      break;
    }
  }
}
