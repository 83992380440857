import React, { Component, createContext } from 'react';
import { createPortal } from 'react-dom';

import IconWarningTriangle from '@float/ui/deprecated/Earhart/Icons/Icon/IconWarningTriangle';

import Snackbar from './Snackbar';

export const SnackbarContext = createContext(null);

class SnackbarProvider extends Component {
  constructor(props) {
    super(props);

    this.contextValue = {
      showSnackbar: this.show,
      updateSnackbar: this.update,
      closeSnackbar: this.close,
      showError: this.showError,
    };

    this.state = {
      snackbars: [],
    };
  }

  show = (message, options) => {
    options = options || {};
    const id = options.id || new Date().getTime() + Math.random();
    if (options.id) {
      let updated = false;
      const newSnackbars = this.state.snackbars.map((x) => {
        if (x.id === options.id) {
          updated = true;
          return { ...x, ...options, message };
        }
        return x;
      });
      if (updated) {
        this.setState({ snackbars: newSnackbars });
        return;
      }
    }

    const newSnackbar = { ...options, id, message };
    this.setState({
      snackbars: [...this.state.snackbars, newSnackbar],
    });
    return id;
  };

  // This method is used to update an existing snackbar.
  // If the snackbar does not exist, it will be a no-op.
  update = (message, options) => {
    if (!options?.id) return;

    let updated = false;
    const newSnackbars = this.state.snackbars.map((x) => {
      if (x.id === options.id) {
        updated = true;
        return { ...x, ...options, message };
      }
      return x;
    });

    if (updated) {
      this.setState({ snackbars: newSnackbars });
    }
  };

  showError = (message, options) => {
    this.show(message, {
      className: 'error',
      iconLeft: IconWarningTriangle,
      showClose: true,
      ...options,
    });
  };

  close = (id) => {
    const { snackbars } = this.state;
    const newSnackbars = snackbars.map((x) =>
      x.id === id ? { ...x, triggerClose: true } : x,
    );
    this.setState({ snackbars: newSnackbars });
  };

  remove = (id) => {
    const { snackbars } = this.state;
    const newSnackbars = snackbars.filter((x) => x.id !== id);
    if (newSnackbars.length === snackbars.length) {
      return;
    }
    this.setState({ snackbars: newSnackbars });
  };

  render() {
    const snackbars = this.state.snackbars.map((props, i) => (
      <Snackbar {...props} key={props.id} index={i} onClose={this.remove} />
    ));

    return (
      <SnackbarContext.Provider value={this.contextValue}>
        {this.props.children}
        {createPortal(snackbars, document.body)}
      </SnackbarContext.Provider>
    );
  }
}

export default SnackbarProvider;
