import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { ProjectStatus } from '@float/types/project';
import { useModalLocal } from '@float/ui/hooks/useModalLocal';

export const useEntityStatusConfirmModal = (entity: 'project' | 'phase') => {
  const { setValue, getValues } = useFormContext();
  const isNew = Boolean(
    !getValues(entity === 'phase' ? 'phase.phase_id' : 'projectId'),
  );
  const [{ prev, next }, setStatuses] = useState({
    prev: ProjectStatus.Confirmed,
    next: ProjectStatus.Confirmed,
  });
  const modal = useModalLocal();
  const onStatusChange = (prev: ProjectStatus, next: ProjectStatus) => {
    // Don't show modal for new entities being created
    if (isNew) return;
    setStatuses({ prev, next });
    modal.controls.openModal();
  };

  const onStatusChangeConfirm = () => {
    modal.controls.closeModal();
  };

  const onStatusChangeCancel = (state: boolean) => {
    if (!state) {
      setValue(`${entity}.status`, prev, {
        shouldDirty: true,
      });
    }
  };

  return {
    modalProps: modal.props,
    onStatusChange,
    onStatusChangeCancel,
    onStatusChangeConfirm,
    prev,
    next,
  };
};
