import React from 'react';
import { useWatch } from 'react-hook-form';

import { Tag } from '@float/ui/components/Tag';

import { ProjectFormData } from '../../types';

import * as styles from './styles.css';

type Keys = Exclude<
  keyof ProjectFormData,
  'phase' | 'project' | 'projectId' | 'templateId' | 'type'
>;

export type SectionCounterTagProps<K extends Keys> = {
  name: K;
  filter?: (value: ProjectFormData[K][0]) => boolean;
};

export function SectionCounterTag<K extends Keys>(
  props: SectionCounterTagProps<K>,
) {
  const list = useWatch<ProjectFormData, K>({
    name: props.name,
  });
  const filter = props.filter;

  const count = filter
    ? list.filter((item) => filter(item as ProjectFormData[K][0])).length
    : list.length;

  const isEmpty = count === 0;

  return (
    <Tag
      color={isEmpty ? 'default' : 'primary'}
      size="compact"
      className={isEmpty ? styles.empty : ''}
      data-testid={`section-counter-${props.name}`}
    >
      {count}
    </Tag>
  );
}
