import { connect } from 'react-redux';
import { compose } from 'redux';

import { updateSecurityPrefs } from '@float/common/actions/companyPrefs';
import { withSnackbar } from '@float/ui/deprecated';

import withOnMount from '../../../decorators/withOnMount';
import SignInWithGoogleRequiredSection from './component';

const mapStateToProps = (state) => {
  const hasTimeTracking = state.companyPrefs.time_tracking > 0;
  const hasPlusPack = state.companyPrefs.plus_pack > 0;

  return {
    signInWithGoogleRequired: state.companyPrefs.sso_required === 'google',
    hasPlusPack,
    hasTimeTracking,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (signInWithGoogleRequired) => {
    const formData = new FormData();
    formData.append(
      'company[sso_required]',
      signInWithGoogleRequired ? 'google' : null,
    );
    return dispatch(updateSecurityPrefs(formData));
  },
});

const Component = compose(
  withOnMount,
  withSnackbar,
)(SignInWithGoogleRequiredSection);

const StatefulSignInWithGoogleRequiredSection = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default StatefulSignInWithGoogleRequiredSection;
