import { createSelector } from 'reselect';

import { getCurrentUserRaw } from '../currentUser';
import { getActivePage } from './getActivePage';
import { isPeopleScheduleType } from './isPeopleScheduleType';

export const getActiveSortOptions = createSelector(
  [getActivePage, getCurrentUserRaw],
  (page, currentUser) => {
    if (isPeopleScheduleType(page)) {
      const { sked_sort_by, sked_sort_dir, sked_custom_sort } =
        currentUser.prefs || {};

      return {
        sortBy: sked_sort_by || 'name',
        sortDir: sked_sort_dir || 'asc',
        customSort: Boolean(sked_custom_sort),
      };
    }

    if (page === 'project-plan') {
      const { sked_projview_sort_by, sked_projview_sort_dir } =
        currentUser.prefs || {};

      return {
        sortBy: sked_projview_sort_by || 'project_name',
        sortDir: sked_projview_sort_dir || 'asc',
        customSort: false,
      };
    }

    return null;
  },
);
