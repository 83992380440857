import React from 'react';

import BaseIcon, { BaseIconProps } from './BaseIcon';

const IconPlay = (props: BaseIconProps) => (
  <BaseIcon {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M20 11.5 5 4v15l15-7.5Z"
    />
  </BaseIcon>
);

export { IconPlay };
