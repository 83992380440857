import {
  PUBLIC_HOLIDAYS_LOAD_FAILURE,
  PUBLIC_HOLIDAYS_LOAD_START,
  PUBLIC_HOLIDAYS_LOAD_SUCCESS,
} from '../actions';

const DEFAULT_STATE = {
  regions: [],
  loadState: 'UNLOADED',
};

export default function reducer(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case PUBLIC_HOLIDAYS_LOAD_START: {
      return {
        ...state,
        loadState: 'LOADING',
      };
    }

    case PUBLIC_HOLIDAYS_LOAD_FAILURE: {
      return {
        ...state,
        loadState: 'LOAD_FAILED',
      };
    }

    case PUBLIC_HOLIDAYS_LOAD_SUCCESS: {
      return {
        ...state,
        loadState: 'LOADED',
        regions: action.response,
      };
    }

    default: {
      return state;
    }
  }
}
