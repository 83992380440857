import {
  toFriendlyTime as _toFriendlyTime,
  toLongFriendlyTime as _toLongFriendlyTime,
} from '@floatschedule/activity-format-npm';
import parseDate from 'date-fns/parse';
import isString from 'lodash/isString';

import { TIME_FORMAT, TIME_FORMAT_MAP } from '@float/constants/time';
import { TimeFormat, TimeFormatValue } from '@float/types';
import { CompanyPreferences } from '@float/types/companyPreferences';
import {
  TimeFormatPreference,
  UserTimeFormatPreference,
} from '@float/types/timeFormatPreference';

import { padTo2Digits } from './number/padTo2Digits';
import { formatDecimalHoursAsClockTime } from './timer/formatDecimalHoursAsClockTime';

export { TIME_FORMAT, TIME_FORMAT_MAP };

// time_format_24h exists on both the company level and the user level, with
// the user one taking precedence. The company pref is a boolean value, while
// the user pref is a numeric value represented by:
// 0: Unset - fall back to company pref
// 1: 12 hour format
// 2: 24 hour format
let userTimeFormatPref = {
  value: TimeFormatPreference.Unset,
  label: 'Unset',
};

export function setUserTimeFormatPref(val: UserTimeFormatPreference) {
  userTimeFormatPref = val;
}

export function getUse24HourFormat(companyPrefs?: CompanyPreferences) {
  if (userTimeFormatPref?.value > 0) {
    return (
      userTimeFormatPref.value == TimeFormatPreference.TwentyFourHourFormat
    );
  }

  return (companyPrefs?.time_format_24h as unknown) == 1;
}

export const getTimeFormat = (companyPrefs: CompanyPreferences): TimeFormat =>
  getUse24HourFormat(companyPrefs) ? '24' : '12';

export const parse = (
  str: string,
  format = 'yyyy-MM-dd',
  base = new Date(),
) => {
  return parseDate(str, format, base);
};

export {
  addMinutes,
  getTimeAgoCompact,
  roundToTwoDecimals,
  toDbTime,
} from '@floatschedule/activity-format-npm';

export function toFriendlyTime(
  dbTime: string,
  companyPrefs: CompanyPreferences,
  is24HourFormat = getUse24HourFormat(companyPrefs),
) {
  return _toFriendlyTime(dbTime, is24HourFormat);
}

export function toLongFriendlyTime(
  dbTime: string,
  companyPrefs: CompanyPreferences,
  is24HourFormat = getUse24HourFormat(companyPrefs),
) {
  return _toLongFriendlyTime(dbTime, is24HourFormat);
}

export function normalizeTime(
  timeValue: number | string = 0,
  format: TimeFormatValue = TIME_FORMAT.decimal,
) {
  if (format === TIME_FORMAT.decimal) {
    return timeValue;
  }

  // str can be with 'h' sign
  const parsedHours = isString(timeValue)
    ? Number(timeValue.replace('h', ''))
    : timeValue;

  return formatDecimalHoursAsClockTime(parsedHours);
}

// Given milliseconds, returns the hours and minutes as strings padded to two digits.
// The hours are returned in 24-hour format, and are rolled over if they exceed 24.
export function convertMsToPaddedHoursAndMinutesWithRollover(
  milliseconds: number,
): { hours: string; minutes: string } {
  const sanitizedMilliseconds = milliseconds || 0; // Handle NaN
  const seconds = Math.floor(sanitizedMilliseconds / 1000);
  let minutes: number | string = Math.floor(seconds / 60);
  let hours: number | string = Math.floor(minutes / 60);

  minutes = minutes % 60;
  hours = hours % 24;

  hours = padTo2Digits(hours);
  minutes = padTo2Digits(minutes);

  return { hours, minutes };
}

export function formatClockTimeAsMs(hhmm: string) {
  const [hours, minutes] = hhmm.split(':');
  return (
    parseInt(hours, 10) * 60 * 60 * 1000 + parseInt(minutes, 10) * 60 * 1000
  );
}

export function formatClockTimeAsHours(hhmm: string) {
  const ms = formatClockTimeAsMs(hhmm);
  const hours = ms / 1000 / 60 / 60;

  return hours;
}

export function hoursToMs(hours: number) {
  return hours * 60 * 60 * 1000;
}

export function roundTime(timeString: string) {
  const hoursMinutes = timeString.split(/[.:]/);
  let hours = parseInt(hoursMinutes[0], 10);
  let minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;

  if (minutes >= 60) {
    hours = hours + 1;
    minutes = 0;
  }

  return {
    hours,
    minutes,
  };
}
