import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon {...props} viewBox="0 0 20 20">
    <path
      d="M4.12669 13.6281L8.51254 4.8806C9.12747 3.65413 10.8781 3.65445 11.4926 4.88115L15.8744 13.6287C16.4295 14.7369 15.6237 16.0418 14.3842 16.0418H5.61658C4.3768 16.0418 3.57102 14.7364 4.12669 13.6281Z"
      stroke="#DC3D43"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 8.33398V10.0007"
      stroke="#DC3D43"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4168 13.3327C10.4168 13.5628 10.2303 13.7493 10.0002 13.7493C9.77004 13.7493 9.5835 13.5628 9.5835 13.3327C9.5835 13.1026 9.77004 12.916 10.0002 12.916C10.2303 12.916 10.4168 13.1026 10.4168 13.3327Z"
      stroke="#DC3D43"
    />
  </BaseIcon>
);
