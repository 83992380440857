import styled from 'styled-components';

import EH from '@float/ui/deprecated/Earhart';

export const StyledContainer = styled.div`
  border-width: 1px;
  border-style: solid;
  border-color: ${EH.Colors.Primary.Flue.Light[9]};
  border-radius: 6px;

  background-color: ${EH.Colors.Primary.Flue.Light[4]};

  padding: 16px;

  p {
    ${EH.Typography.Label13.R400};

    color: ${EH.Colors.Primary.Flue.Light[10]};

    padding: 0;
    margin: 0;

    text-align: center;
  }

  p + a {
    display: block;

    margin-top: 10px;
  }
`;
