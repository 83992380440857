import {
  createTaskMetaAction,
  deleteTaskMetaAction,
  mergeTaskMetasAction,
  updateTaskMetaAction,
} from '@float/common/actions/taskMetas';
import { diffEntityListPayload } from '@float/common/lib/diffEntityPayload';
import { useAppDispatchStrict } from '@float/common/store';
import { Phase, Project, TaskMeta } from '@float/types';

import { ProjectTaskRecord } from '../types';

export const useTaskSave = () => {
  const dispatch = useAppDispatchStrict();

  const handleTaskMerge = (task: ProjectTaskRecord) => {
    const data = {
      task_meta_ids: task.merge!,
      task_name: task.task_name,
      billable: task.billable,
      budget: task.budget,
    };
    return dispatch(mergeTaskMetasAction(data));
  };

  const handleTaskUpsert = (
    task: ProjectTaskRecord,
    project_id: Project['project_id'],
    phase_id?: Phase['phase_id'],
  ): Promise<TaskMeta> => {
    if (!task.task_meta_id) {
      return dispatch(
        createTaskMetaAction({
          task_name: task.task_name,
          billable: task.billable,
          budget: task.budget,
          project_id,
          phase_id,
        }),
      );
    }
    return dispatch(
      updateTaskMetaAction(
        {
          task_name: task.task_name,
          billable: task.billable,
          budget: task.budget,
        },
        task.task_meta_id,
      ),
    );
  };

  const handleBulkTasksUpsert = async (
    tasks: ProjectTaskRecord[],
    project_id: Project['project_id'],
    phase_id?: Phase['phase_id'],
  ) => {
    const errors = [];

    // The upsert calls are being processed sequentially in order to retain the given order
    // (The api3 response is sorted sequentially by id, which is incrementals)
    for (const task of tasks) {
      try {
        await handleTaskUpsert(task, project_id, phase_id);
      } catch (_err) {
        errors.push(_err);
      }
    }

    if (errors.length) {
      throw new AggregateError(errors, 'Bulk tasks upsert failed');
    }
  };

  const handleBulkTasksDelete = (tasks: number[]) => {
    return Promise.all(tasks.map((id) => dispatch(deleteTaskMetaAction(id))));
  };

  const handleUpdate = (
    update: ProjectTaskRecord[],
    currentValues: ProjectTaskRecord[],
    projectId: Project['project_id'],
    phaseId?: Phase['phase_id'],
  ) => {
    const diff = diffEntityListPayload(update, currentValues, 'task_meta_id');
    const merged = new Set<number>();
    const promises = [];

    function addToMerged(list: number[]) {
      list.forEach((v) => merged.add(v));
    }

    if (diff) {
      if (diff.add) {
        const addTasks: ProjectTaskRecord[] = [];
        for (const record of diff.add) {
          if (record.merge) {
            promises.push(handleTaskMerge(record as ProjectTaskRecord));
            // Filling the set is O(N) where N is the number of the merged tasks
            addToMerged(record.merge as number[]);
          } else {
            addTasks.push(record as ProjectTaskRecord);
          }
        }
        promises.push(handleBulkTasksUpsert(addTasks, projectId, phaseId));
      }
      if (diff.del) {
        // checking against a set is O(1)
        promises.push(
          handleBulkTasksDelete(diff.del.filter((id) => !merged.has(id))),
        );
      }
    }

    return Promise.all(promises);
  };

  return {
    handleTaskUpsert,
    handleBulkTasksUpsert,
    handleUpdate,
  };
};
