// const emailRe = /.{1}@.{2}/i;
const emailRe = /\S+@\S+\.\S+/i;
export const email = (str: string) => str && emailRe.test(str);

// https://app.asana.com/0/388486760637802/1202216765826677/f
const emailRe2 =
  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
export const validateEmailV2 = (str: string) => str && emailRe2.test(str);

const passwordRe = /(?=.*[A-Za-z])(?=.*\d).{8,}/;
export const validatePassword = (str: string) => str && passwordRe.test(str);

// regexr.com/70244
const domainRe =
  /^(((?!-))(xn--)?[a-z0-9\-_]{0,61}[a-z0-9]{1,1}\.)*(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30})\.[a-z]{2,}$/;
export const validateDomain = (str: string) => str && domainRe.test(str);
