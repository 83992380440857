import { t } from '@lingui/macro';
import addDays from 'date-fns/addDays';
import formatDate from 'date-fns/format';
import startOfWeek from 'date-fns/startOfWeek';
import { memoize } from 'lodash';
import { DurationInputArg1, DurationInputArg2, unitOfTime } from 'moment';

import { moment } from '@float/libs/moment';
import { CurrentUser } from '@float/types/account';

import { formatToFloatDate, parseFloatDate } from './dates';

export const startOf = (unit: unitOfTime.StartOf) => () =>
  moment().startOf(unit);
export const endOf = (unit: unitOfTime.StartOf) => () => moment().endOf(unit);

export const prevRangeStart = function (this: {
  range: readonly [DurationInputArg1, DurationInputArg2];
}) {
  const [count, type] = this.range;
  return moment().subtract(count, type).startOf(type);
};
export const prevRangeEnd = function (this: {
  range: readonly [DurationInputArg1, DurationInputArg2];
  start: () => Moment;
}) {
  const [, type] = this.range;
  return this.start().endOf(type);
};

const nextRangeStart = function (this: {
  range: readonly [DurationInputArg1, DurationInputArg2];
}) {
  const [count, type] = this.range;
  return moment().add(count, type).startOf(type);
};
const nextRangeEnd = function (this: {
  range: readonly [DurationInputArg1, DurationInputArg2];
  start: () => Moment;
}) {
  const [, type] = this.range;
  return this.start().endOf(type);
};

export const getToday = () =>
  ({
    label: t`Today`,
    value: '0',
    start: startOf('day'),
    end: endOf('day'),
    type: 'day',
    range: [1, 'day'],
  }) as const;

export const getThisWeek = () =>
  ({
    label: t`This week`,
    value: '1',
    start: startOf('week'),
    end: endOf('week'),
    type: 'week',
    range: [1, 'week'],
  }) as const;

export const getThisMonth = () =>
  ({
    label: t`This month`,
    value: '2',
    start: startOf('month'),
    end: endOf('month'),
    type: 'month',
    range: [1, 'month'],
  }) as const;

export const getThisQuarter = () =>
  ({
    label: t`This quarter`,
    value: '3',
    start: startOf('quarter'),
    end: endOf('quarter'),
    type: 'quarter',
    range: [1, 'quarter'],
  }) as const;

export const getLastWeek = () =>
  ({
    label: t`Last week`,
    value: '5',
    start: prevRangeStart,
    end: prevRangeEnd,
    type: 'week',
    range: [1, 'week'],
  }) as const;

export const getLastMonth = () =>
  ({
    label: t`Last month`,
    value: '6',
    start: prevRangeStart,
    end: prevRangeEnd,
    type: 'month',
    range: [1, 'month'],
  }) as const;

export const getLastQuarter = () =>
  ({
    label: t`Last quarter`,
    value: '7',
    start: prevRangeStart,
    end: prevRangeEnd,
    type: 'quarter',
    range: [1, 'quarter'],
  }) as const;

export const getNextWeek = () =>
  ({
    label: t`Next week`,
    value: '8',
    start: nextRangeStart,
    end: nextRangeEnd,
    type: 'week',
    range: [1, 'week'],
  }) as const;

export const getNextMonth = () =>
  ({
    label: t`Next month`,
    value: '9',
    start: nextRangeStart,
    end: nextRangeEnd,
    type: 'month',
    range: [1, 'month'],
  }) as const;

export const getTimeRangeOpts = memoize(() => {
  return [
    getThisWeek(),
    getNextWeek(),
    getLastWeek(),
    getThisMonth(),
    getNextMonth(),
    getLastMonth(),
    {
      label: t`Custom`,
      value: 'custom',
    } as const,
  ];
});

type TimeRangeOption = ReturnType<typeof getTimeRangeOpts>[0];

export const MAX_DAYS = 92;

export function calculateMaxEndDate(startDate: string) {
  return formatToFloatDate(addDays(parseFloatDate(startDate), MAX_DAYS));
}

export function calculateMinStartDate(endDate: string) {
  return formatToFloatDate(addDays(parseFloatDate(endDate), -MAX_DAYS));
}

export function getTimeRangeCacheKey(
  user: Pick<CurrentUser, 'admin_id' | 'cid'>,
) {
  const { admin_id: accountId, cid: companyId } = user;
  return `${companyId}:${accountId}:timeRange`;
}

function ensureDates(
  result: { start_date?: string; end_date?: string },
  mondayStart?: boolean,
) {
  if (!result.start_date) {
    const format = 'yyyy-MM-dd';
    const weekStart = startOfWeek(new Date(), {
      weekStartsOn: mondayStart ? 1 : 0,
    });
    result.start_date = formatDate(weekStart, format);
    result.end_date = formatDate(addDays(weekStart, 6), format);
  }
  return result as { start_date: string; end_date: string };
}

export function getTimeRangeCacheValue(
  user: Pick<CurrentUser, 'admin_id' | 'cid'>,
) {
  const key = getTimeRangeCacheKey(user);
  const value = localStorage.getItem(key);
  const result = value ? JSON.parse(value) : { range_mode: '1' };
  return result.disabled ? result : ensureDates(result);
}

export function getInitialTimeRange(
  user: Pick<CurrentUser, 'admin_id' | 'cid'>,
  mondayStart?: boolean,
) {
  const format = 'YYYY-MM-DD';
  const result = getTimeRangeCacheValue(user);
  if (result.disabled) return result;

  result.time_range_id = 1;
  const rangeMode = result.range_mode || 'custom';
  if (rangeMode !== 'custom') {
    const range = getTimeRangeOpts().find((x) => x.value === rangeMode);
    if (range && range.value !== 'custom') {
      result.start_date = range.start().format(format);
      result.end_date = range.end().format(format);
    }
  }
  return ensureDates(result, mondayStart);
}

export function getTimeRangeMode({
  start_date,
  end_date,
}: {
  start_date: string;
  end_date: string;
}): TimeRangeOption['value'] {
  let rangeMode: TimeRangeOption['value'] = 'custom';

  getTimeRangeOpts().some((opt) => {
    if (!('start' in opt)) return;
    const startDiff = moment(start_date)
      .startOf('day')
      .diff(opt.start().startOf('day'));
    const startEqual = startDiff === 0;
    const endDiff = moment(end_date)
      .startOf('day')
      .diff(opt.end().startOf('day'));
    const endEqual = endDiff === 0;
    if (startEqual && endEqual) {
      rangeMode = opt.value;
      return true;
    }
    return false;
  });

  return rangeMode;
}
