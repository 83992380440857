export enum LockLoggedTimeFrequency {
  week = 'week',
  month = 'month',
  year = 'year',
}

export type LockLoggedTimeSettings = {
  isActive: boolean; // Is Lock Logged Time enabled?
  interval: number; // Integer – How many days after the end of the period is time logging locked?
  frequency: LockLoggedTimeFrequency; // At what frequency is time locked?
};

export type LockLoggedTimeItem = {
  triggerDate: string;
  endDate: string;
};
