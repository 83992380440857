import { isBulkOp } from '@floatschedule/activity-format-npm';
import {
  getMilestonesMap,
  getPeopleMap,
  getPhasesMapRaw,
  getProjectsMap,
  getStatuses,
  getTasksMap,
  getTimeoffsMap,
} from 'selectors';

const getItemId = (item, type) => {
  if (item.item_id) {
    return item.item_id;
  }
  if (item.after_data && item.after_data[`${type}_id`]) {
    return item.after_data[`${type}_id`];
  }
  return null;
};

const getTaskId = (item) => getItemId(item, 'task');

const getTimeoffId = (item) => getItemId(item, 'timeoff');

const getStatusId = (item) => getItemId(item, 'status');

export function isClickableActivity(item, store) {
  if (isBulkOp(item)) return false;
  const state = store.getState();
  const { item_type: itemType, item_id: itemId } = item;
  switch (itemType) {
    case 'timeoff':
      return Boolean(getTimeoffsMap(state)[getTimeoffId(item)]);
    case 'status':
      return Boolean(getStatuses(state)[getStatusId(item)]);
    case 'task':
      return Boolean(getTasksMap(state)[getTaskId(item)]);
    case 'people':
      return Boolean(getPeopleMap(state)[itemId]);
    case 'account':
      return Boolean(
        Object.values(getPeopleMap(state)).find((p) => p.account_id === itemId),
      );
    case 'project':
      return Boolean(getProjectsMap(state)[itemId]);
    case 'phase':
      return Boolean(getPhasesMapRaw(state)[itemId]);
    case 'milestones':
      return Boolean(getMilestonesMap(state)[itemId]);
    case 'holiday':
    case 'holidays':
    case 'user':
      return true;
    default:
      return false;
  }
}

// Code copied from web/src/activity/components/ActivityItemClick.js
// Original file will be deleted when all users are migrated to Team Activity
export default function clickActivity(
  item,
  manageModal,
  _updateDates,
  _retry,
  _history,
  showSnackbar,
  showProjectSidePanel,
  store,
) {
  if (isBulkOp(item)) {
    return;
  }

  const state = store.getState();
  let model, editing;
  switch (item.item_type) {
    case 'account':
    case 'people': {
      const peopleMap = getPeopleMap(state);
      model =
        item.item_type === 'people'
          ? peopleMap[item.item_id]
          : Object.values(peopleMap).find((p) => p.account_id === item.item_id);

      if (!model) {
        showSnackbar('Person not found.');
        return;
      }
      editing = item.activity_type !== 'delete' && model.canEdit;

      // This is an ugly situation.
      // people needs to be wrapped like this
      // for application logic and markup like this elsewhere:
      // (people) => { const {people} = people; };
      let people = model || item.after_data;
      if (!people.people) {
        people = { people };
      }
      manageModal({
        visible: true,
        modalType: 'personModal',
        modalSettings: {
          person: model,
          isEditable: editing,
        },
      });
      break;
    }

    case 'project': {
      model = getProjectsMap(state)[item.item_id];
      if (!model) {
        showSnackbar('Project not found.');
        return;
      }
      showProjectSidePanel({
        projectId: model.project_id,
      });
      break;
    }

    case 'phase': {
      model = getPhasesMapRaw(state)[item.item_id];
      if (!model) {
        showSnackbar('Phase not found.');
        return;
      }

      const project = getProjectsMap(state)[model.project_id];
      manageModal({
        visible: true,
        modalType: 'phaseModal',
        modalSettings: {
          phase: model,
          projectId: model.project_id,
          editing: project?.canEdit,
        },
      });
      break;
    }

    case 'milestones': {
      model = getMilestonesMap(state)[item.item_id];
      if (!model) {
        showSnackbar('Milestone not found');
        return;
      }

      manageModal({
        visible: true,
        modalType: 'milestoneModal',
        modalSettings: {
          milestone: model,
        },
      });
      break;
    }

    case 'holiday':
    case 'holidays':
      return window.open('/admin/timeoffs', '_blank');
    case 'user':
      return window.open('/admin/guests', '_blank');
    default:
      return console.warn('Unhandled action');
  }
}
