import React, { CSSProperties } from 'react';

type DownSmallProps = {
  style?: CSSProperties;
  color?: string;
  className?: string;
  onClick?: (event: React.MouseEvent<SVGSVGElement>) => void;
};

export const DownSmall = React.forwardRef<SVGSVGElement, DownSmallProps>(
  ({ style, color = '#868D92', className, onClick }, ref) => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
        className={`icon-chevron-down-sm ${className || ''}`}
        ref={ref}
        onClick={onClick}
      >
        <path
          d="M12.7084 8.95831L10.0001 11.875L7.29175 8.95831"
          className="stroke"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

export default DownSmall;
