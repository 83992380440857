import React from 'react';
import { Plural, t, Trans } from '@lingui/macro';

import { Person } from '@float/types/person';

export type GetRoleChangeConfirmDataProps = {
  ids: number[];
  entities: Record<number, Person>;
  fields: {
    role_id: Person['role_id'];
    default_hourly_rate?: Person['default_hourly_rate'];
  };
};

export const getRoleChangeConfirmData = ({
  ids,
  entities,
  fields,
}: GetRoleChangeConfirmDataProps) => {
  const peopleSelected = ids
    .map((id) => entities[id])
    .filter((person) => !!person);

  const peopleWithRoleChange = peopleSelected.filter(
    (person) => person.role_id !== fields.role_id,
  );

  const peopleWithRateChange =
    typeof fields.default_hourly_rate !== 'undefined'
      ? peopleSelected.filter(
          (person) => person.default_hourly_rate !== fields.default_hourly_rate,
        )
      : [];

  const numPeopleWithRoleChange = peopleWithRoleChange.length;
  const numPeopleWithRateChange = peopleWithRateChange.length;

  const hasPeopleWithRoleChange = numPeopleWithRoleChange > 0;
  const hasPeopleWithRateChange = numPeopleWithRateChange > 0;

  // When there are people with rate change prefer that message over the role change
  if (hasPeopleWithRateChange) {
    return {
      title: t`Confirm rate change`,
      message: (
        <p>
          <Trans>
            You are changing the rate for {numPeopleWithRateChange}{' '}
            <Plural
              value={numPeopleWithRateChange}
              one="Person"
              other="People"
            />{' '}
            to the default hourly rate of their new Role. <br />
            <br />
            This change will not override rates set at the Project, or Phase
            level.
          </Trans>
        </p>
      ),
    };
  }

  // Show the role confirmation
  if (hasPeopleWithRoleChange) {
    return {
      title: t`Confirm role change`,
      message: (
        <p>
          You are changing the Role for {numPeopleWithRoleChange}{' '}
          <Plural value={numPeopleWithRoleChange} one="Person" other="People" />
          .
        </p>
      ),
    };
  }

  // No any confirmation data available
  return null;
};
