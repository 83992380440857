import styled from 'styled-components';

import LockIndicator from '@float/common/components/LockIndicator';

export default styled(LockIndicator)`
  svg {
    width: 9px;
    height: 9px;
  }

  position: absolute;
  bottom: 0px;
  left: calc(50% - 4px);
`;
