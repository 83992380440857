import React from 'react';
import { t } from '@lingui/macro';

import { InputField } from '../components/InputField';

export function TaskNameField(props: {
  autoFocus: boolean;
  unnamedTask?: boolean;
  index: number;
}) {
  const name = `tasks.${props.index}.task_name` as const;
  return (
    <InputField
      name={name}
      label={t`Task name`}
      hideLabel
      readOnly={Boolean(props.unnamedTask)}
      placeholder={
        props.unnamedTask ? t`No task allocation` : t`Add a task name`
      }
      autoFocus={props.autoFocus}
      padding="emptySides"
    />
  );
}
