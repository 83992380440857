import { format } from 'date-fns';

import { TimeFormat } from '@float/types';

import { getTimeFormatPattern } from './getTimeFormatPattern';
import { normalizeClockTime } from './normalizeClockTime';

function formatAsClockTime(value: string, timeFormat: TimeFormat) {
  const time = normalizeClockTime(value);

  let newTime;

  if (time) {
    newTime = time;
  } else {
    newTime = new Date(0, 0, 0, 0, 0, 0);
  }

  const pattern = getTimeFormatPattern(timeFormat);

  newTime = format(newTime, pattern);

  return newTime;
}

export { formatAsClockTime };
