import styled from 'styled-components';

export const StyledOverlay = styled.div`
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  box-sizing: border-box;

  background-color: rgba(159, 155, 181, 0.4);

  z-index: 0;

  &.with-overlay {
    border-width: 2px;
    border-style: solid;
    border-color: #2e5fe8;
    border-radius: 10px;

    box-shadow: rgba(159, 155, 181, 0.4) 0px 0px 0px 5000px;

    background-color: transparent;
  }

  /**
   * Animation
   */

  &.enter,
  &.appear {
    opacity: 0;
  }

  &.enter-active,
  &.appear-active {
    opacity: 1;
    transition: opacity 200ms;
  }

  &.exit {
    opacity: 1;
  }

  &.exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
`;
