import React from 'react';

import { border } from './styles.css';

type BorderProps = {
  'data-testid': string;
  left: string;
};

export function Border(props: BorderProps) {
  return (
    <div
      className={border}
      data-testid={props['data-testid']}
      style={{ left: props.left }}
    />
  );
}
