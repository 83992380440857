import React, { useMemo } from 'react';
import { FieldPath } from 'react-hook-form';
import { Trans } from '@lingui/macro';

import PersonAvatar from '@float/common/components/elements/PersonAvatar';
import { useCurrencyProps } from '@float/common/hooks/useCurrencyProps';
import { formatRateWithCurrencySymbol } from '@float/common/lib/rates/rates';
import { getUser } from '@float/common/selectors/currentUser';
import { getPeople } from '@float/common/selectors/people';
import { useAppSelectorStrict } from '@float/common/store';
import { CurrencyProps, Person, ProjectTeamMemberData } from '@float/types';
import { GroupedOptions } from '@float/types/options';

import { useFieldArrayContext } from '../hooks/useFieldArrayContext';
import { useGetPersonDefaultRate } from '../hooks/useGetPersonDefaultRate';
import { ProjectFormData } from '../types';

export function usePeopleByDept(
  team: ProjectFormData['team'],
  showingRates = false,
) {
  const people = useAppSelectorStrict(getPeople);
  const { department_filter_all } = useAppSelectorStrict(getUser);
  const currencyConfig = useCurrencyProps();

  const peopleOptionsByDept = useMemo(() => {
    return getPeopleOptionsByDept(
      team,
      people,
      department_filter_all,
      showingRates,
      currencyConfig,
    );
  }, [team, people, department_filter_all, showingRates, currencyConfig]);

  return peopleOptionsByDept;
}

export const useAddDepartment = (onAddDepartment: () => void) => {
  const { append } = useFieldArrayContext<ProjectFormData, 'team'>();
  const getPersonDefaultRate = useGetPersonDefaultRate();

  const addDepartment = (
    group: GroupedOptions<FieldPath<ProjectFormData>>['options'],
  ) => {
    const people = group.map((opt) => ({
      people_id: opt.value,
      hourly_rate: getPersonDefaultRate(Number(opt.value!)),
    })) as unknown as ProjectTeamMemberData[];
    append(people);
    // calling onAddDepartment in this block causes setState conflict with the hourly rate quick input field and causes an infinite loop
    // wrapping in setTimeout so that it gets called in the next event cycle instead.
    setTimeout(onAddDepartment);
  };

  return addDepartment;
};

function getPeopleOptionsByDept(
  team: ProjectFormData['team'],
  people: Person[],
  department_filter_all: number[],
  showingRates: boolean,
  currencyConfig: CurrencyProps,
) {
  const avatarProps = {
    readOnly: true,
    size: 'xs',
  };
  const peopleByDeptMap = people.reduce(
    (acc: Record<string, GroupedOptions<Person['people_id']>>, p) => {
      if (!p.active || team?.find((m) => m.people_id === p.people_id))
        return acc;
      if (
        department_filter_all?.length &&
        !department_filter_all.includes(p.department_id!)
      ) {
        return acc;
      }

      const dept = p.department?.name || 'No department';
      const deptId = p.department?.department_id || 0;
      const defaultHourlyRate =
        p.default_hourly_rate || p.role?.default_hourly_rate || null;

      if (!acc[dept]) {
        acc[dept] = {
          name: dept,
          value: deptId,
          options: [],
        };
      }

      const formattedRate = formatRateWithCurrencySymbol(
        defaultHourlyRate,
        currencyConfig,
      );

      acc[dept].options.push({
        value: p.people_id,
        label: p.name,
        icon: <PersonAvatar personId={p.people_id} {...avatarProps} />,
        iconRight:
          showingRates && defaultHourlyRate ? (
            <span>
              <Trans>
                {formattedRate}
                /hr
              </Trans>
            </span>
          ) : null,
      });

      return acc;
    },
    {},
  );

  const entries = Object.entries(peopleByDeptMap);

  entries.sort((a, b) => a[0].toLowerCase().localeCompare(b[0].toLowerCase()));
  entries.forEach(([, dept]) => {
    dept.options.sort((a, b) =>
      a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
    );
  });

  // TODO FT-2282
  // Add ability to add entire team from side panel
  // https://linear.app/float-com/issue/FT-2282/ux-add-ability-to-add-all-team-members-in-side-panel-project-form
  // Commenting out for now as it's not implemented
  /*
  if (entries.length) {
    entries.unshift(['All', {
      name: '',
      value: -1,
      options: [
        {
          label: 'All team members',
        },
      ],
    }]);
  }
  */
  return entries.map((e) => e[1]);
}
