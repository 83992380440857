import { MINUTE_TO_DECIMAL_HOUR } from './constants';

export function minutesToDecimalHours(minutes: number) {
  // remember if minutes is negative (-1) or positive (1)
  const predictor = minutes < 0 ? -1 : 1;

  // calculate decimal hours using the absolute value
  const minutesAbs = Math.abs(minutes);
  const fullHours = Math.trunc(minutesAbs / 60);
  const restInMinutes = Math.floor(minutesAbs) % 60;
  const hours = MINUTE_TO_DECIMAL_HOUR[restInMinutes] + fullHours;

  // return decimal hours with the same sign as the original value
  return hours * predictor;
}
