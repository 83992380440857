import React from 'react';

export default function ({ color, style }) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        className="stroke"
        d="M6.25 19.2502H9.75C10.3023 19.2502 10.75 18.8025 10.75 18.2502V5.75C10.75 5.19772 10.3023 4.75 9.75 4.75H6.25C5.69772 4.75 5.25 5.19772 5.25 5.75V18.2502C5.25 18.8025 5.69772 19.2502 6.25 19.2502Z"
        stroke={color ?? '#2E5FE8'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M15.25 10.2502H18.75C19.3023 10.2502 19.75 9.80253 19.75 9.25025V5.75C19.75 5.19772 19.3023 4.75 18.75 4.75H15.25C14.6977 4.75 14.25 5.19772 14.25 5.75V9.25025C14.25 9.80253 14.6977 10.2502 15.25 10.2502Z"
        stroke={color ?? '#2E5FE8'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M15.25 19.2502H18.75C19.3023 19.2502 19.75 18.7619 19.75 18.1594V14.3411C19.75 13.7386 19.3023 13.2502 18.75 13.2502H15.25C14.6977 13.2502 14.25 13.7386 14.25 14.3411V18.1594C14.25 18.7619 14.6977 19.2502 15.25 19.2502Z"
        stroke={color ?? '#2E5FE8'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
