import { useCallback, useMemo } from 'react';

import { storeNewTag } from '@float/common/actions';
import { Rights } from '@float/common/lib/acl';
import { getUser } from '@float/common/selectors/currentUser';
import {
  DEFAULT_TAG_COLOR,
  getSortedTagsList,
} from '@float/common/selectors/tags';
import { useAppDispatchStrict, useAppSelector } from '@float/common/store';

type Option = {
  value: string;
  label: string;
  color: string;
};

export function useProjectTagsOptions() {
  const tagsList = useAppSelector(getSortedTagsList);

  const options = useMemo(
    () =>
      tagsList.map(({ name, color }) => ({
        value: name,
        label: name,
        color: color ? `#${color}` : DEFAULT_TAG_COLOR,
      })),
    [tagsList],
  );

  const getSelectedOptions = useMemo(() => {
    // PERF: Since the tags list can be huge
    // we are using a map to make the selected values lookup faster.
    const map = new Map<string, Option>();

    for (const option of options) {
      map.set(option.value, option);
    }

    return (value: string[]) => {
      return value.map(
        (tag) =>
          map.get(tag) || {
            value: tag,
            label: tag,
            color: DEFAULT_TAG_COLOR,
          },
      );
    };
  }, [options]);

  return {
    options,
    getSelectedOptions,
  };
}

export function useProjectTagsACL() {
  const currentUser = useAppSelector(getUser);
  const canCreateTags = Rights.canCreateTags(currentUser, { tag: { type: 1 } });

  return { canCreateTags };
}

export function useProjectTagCreate() {
  const dispatch = useAppDispatchStrict();
  return useCallback(
    (value: string) => {
      dispatch(storeNewTag(value));
    },
    [dispatch],
  );
}
