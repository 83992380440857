import { FieldPath, useFormContext } from 'react-hook-form';

import { ProjectFormData } from '../types';

export function useDateRange(opts: {
  startKey: FieldPath<ProjectFormData>;
  endKey: FieldPath<ProjectFormData>;
}) {
  const { getValues, setValue } = useFormContext<ProjectFormData>();

  function handleStartChange(value: string, forceEndDateChange = false) {
    const end = getValues(opts.endKey) as string;

    if (forceEndDateChange || (end && end < value)) {
      setValue(opts.endKey, value, {
        shouldDirty: true,
      });
    }
  }

  function handleEndChange(value: string) {
    const start = getValues(opts.startKey) as string;

    if (start && start > value) {
      setValue(opts.startKey, value, {
        shouldDirty: true,
      });
    }
  }

  return { onStartChange: handleStartChange, onEndChange: handleEndChange };
}
