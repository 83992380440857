import React from 'react';
import { t, Trans } from '@lingui/macro';

import { getIsStarterPlan } from '@float/common/selectors/companyPrefs';
import { useAppSelector } from '@float/common/store';

import { StepBase, StepBaseProps } from '../../StepBase';
import {
  StyledImage,
  StyledParagraph,
  StyledTitle,
} from '../../StepBase/styles';

import ViewsSelectorPreview from './ViewsSelectorPreview.png';
import ViewsSelectorPreview2x from './ViewsSelectorPreview@2x.png';
import ViewsSelectorPreviewStarter from './ViewsSelectorPreviewStarter.png';
import ViewsSelectorPreviewStarter2x from './ViewsSelectorPreviewStarter@2x.png';

const previewSrcSetWithSharedViews = `${ViewsSelectorPreview} 1x, ${ViewsSelectorPreview2x} 2x`;
const previewSrcSetStarterPlan = `${ViewsSelectorPreviewStarter} 1x, ${ViewsSelectorPreviewStarter2x} 2x`;

export function ViewsSelectorStep(props: StepBaseProps) {
  const isStarterPlan = useAppSelector(getIsStarterPlan);

  const previewSrcSet = isStarterPlan
    ? previewSrcSetStarterPlan
    : previewSrcSetWithSharedViews;

  return (
    <StepBase {...props}>
      <StyledImage>
        <img
          width={300}
          height={182}
          src={ViewsSelectorPreview}
          srcSet={previewSrcSet}
          alt={t`Views preview`}
        />
      </StyledImage>

      <StyledTitle>
        <Trans>Save custom Views</Trans>
      </StyledTitle>

      <StyledParagraph>
        <Trans>
          Set up a View then save it so you can return to it quickly.
        </Trans>
      </StyledParagraph>

      {isStarterPlan ? (
        <StyledParagraph>
          <Trans>
            The Views selector lists all your Views. Pin your favorites to the
            top for easy access.
          </Trans>
        </StyledParagraph>
      ) : (
        <StyledParagraph>
          <Trans>
            The Views selector lists all your personal saved Views plus any
            shared team Views.
          </Trans>
        </StyledParagraph>
      )}
    </StepBase>
  );
}
