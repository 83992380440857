import React, { CSSProperties, KeyboardEvent, RefObject } from 'react';

import {
  Candidate,
  FilteredContext,
} from '@float/common/search/selectors/getSearchAutocompleteResults/types';
import { FilterCategory } from '@float/common/search/types';
import { List } from '@float/ui/primitives/List';
import { SEARCH_AUTOCOMPLETE_ID } from '@float/web/searchV2/components/SearchAutocomplete.constants';

import { SearchFilterBreadcrumb } from './components/SearchFilterBreadcrumb';
import { SearchFilterInput } from './components/SearchFilterInput';
import { SearchFilterList } from './components/SearchFilterList';
import { SearchFilterResults } from './components/SearchFilterResults';
import { AddFilter } from './types';

import * as styles from './styles.css';

type SearchFilterDropdownProps = {
  addFilter: AddFilter;
  addValue: (item: Candidate) => void;
  currentInput: string;
  deleteSavedSearch: (item: Candidate) => void;
  disableMultiSelect: boolean;
  editingFilter: { key: string; operator: string; type: string; val: string };
  expandCategory: (value: FilterCategory) => void;
  expandedCategory: FilterCategory;
  filteredContext: FilteredContext;
  isLoading?: boolean;
  getPlaceholder: () => string;
  hideMeFilter: boolean;
  hidePeopleFilters: boolean;
  highlightedIndex: number;
  inputRef: RefObject<HTMLInputElement>;
  listPopoverRef: RefObject<HTMLDivElement> | undefined;
  onInputKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
  onOperatorChange: (operator: string) => void;
  operator: string;
  removeValue: (item: Candidate) => void;
  replaceValue: (args: {
    type: Candidate['type'];
    add: string[];
    remove: string[];
  }) => void;
  selectedValues: string[];
  setHighlightedIndex: (index: number) => void;
  setInputValue: (currentInput: string) => void;
  style: CSSProperties;
  fetchNextPage?: () => void;
  totalResultsCount?: number;
};

const SearchFilterDropdown = (props: SearchFilterDropdownProps) => {
  const {
    addFilter,
    addValue,
    currentInput,
    deleteSavedSearch,
    disableMultiSelect,
    editingFilter,
    expandCategory,
    expandedCategory,
    fetchNextPage,
    filteredContext,
    getPlaceholder,
    totalResultsCount,
    isLoading,
    hideMeFilter,
    hidePeopleFilters,
    highlightedIndex,
    inputRef,
    listPopoverRef,
    onInputKeyDown,
    onOperatorChange,
    removeValue,
    replaceValue,
    selectedValues,
    setHighlightedIndex,
    setInputValue,
    style,
  } = props;

  const { operator, categorySizes, input } = filteredContext;
  const { savedSearches } = categorySizes;
  const hasSavedFilters = Boolean(savedSearches && savedSearches.total > 0);

  const showFilterCategories = Boolean(!input && !expandedCategory);
  const showFilterBreadcrumb = Boolean(expandedCategory);
  const showFilterResults = Boolean(input || expandedCategory);

  return (
    <List.Box
      role="listbox"
      className={styles.searchDropdown}
      id={SEARCH_AUTOCOMPLETE_ID}
      ref={listPopoverRef}
      style={style}
    >
      <SearchFilterInput
        onKeyDown={onInputKeyDown}
        placeholder={getPlaceholder()}
        isLoading={isLoading}
        ref={inputRef}
        setInputValue={setInputValue}
        value={currentInput}
      />

      <List.Separator />

      {showFilterCategories && (
        <SearchFilterList
          currentCategoryType={editingFilter?.type}
          hasSavedFilters={hasSavedFilters}
          hideMeFilter={hideMeFilter}
          hidePeopleFilter={hidePeopleFilters}
          onClick={expandCategory}
        />
      )}

      {showFilterBreadcrumb && (
        <SearchFilterBreadcrumb
          className={styles.breadcrumb}
          onClick={expandCategory}
          onOperatorChange={onOperatorChange}
          operator={operator}
          type={expandedCategory}
        />
      )}

      {showFilterResults && (
        <SearchFilterResults
          addFilter={addFilter}
          addValue={addValue}
          deleteSavedSearch={deleteSavedSearch}
          disableMultiSelect={disableMultiSelect}
          expandCategory={expandCategory}
          expandedCategory={expandedCategory}
          filteredContext={filteredContext}
          highlightedIndex={highlightedIndex}
          removeValue={removeValue}
          replaceValue={replaceValue}
          selectedValues={selectedValues}
          setHighlightedIndex={setHighlightedIndex}
          isLoading={isLoading}
          fetchNextPage={fetchNextPage}
          totalResultsCount={totalResultsCount}
        />
      )}
    </List.Box>
  );
};

export { SearchFilterDropdown };
