import { groupBy } from 'lodash';

import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { AppDispatchStrict } from '@float/common/store';
import { Placeholder } from '@float/types/placeholder';

import { PEOPLE_BULK_UPDATED } from './types';

type NewRolePlaceholderChange = {
  people_id: number;
  new_role_placeholder: Placeholder;
};

export type BulkUpdatePeopleResponse = {
  fields: {
    new_role_placeholder: NewRolePlaceholderChange[] | Placeholder | null;
    people_type_id: number;
  };
  people_ids: number[];
};

export const updateNewRolePlaceholder =
  (response: BulkUpdatePeopleResponse) =>
  (dispatch: AppDispatchStrict, getState: () => ReduxStateStrict) => {
    const {
      people: { people: allPeople },
    } = getState();

    const { fields, people_ids } = response;

    if (Array.isArray(fields.new_role_placeholder)) {
      // group affected people by new_role_placeholder
      const peopleByNewRolePlaceholder = groupBy(
        fields.new_role_placeholder,
        'new_role_placeholder',
      );

      // iterate over each people group to bulk update the new_role_placeholder
      Object.keys(peopleByNewRolePlaceholder).forEach((newRolePlaceholder) => {
        const peopleIds = peopleByNewRolePlaceholder[newRolePlaceholder].map(
          (item) => item.people_id,
        );
        const fields = { new_role_placeholder: Number(newRolePlaceholder) };
        const people = peopleIds.map((id) => allPeople[id]);

        dispatch({
          type: PEOPLE_BULK_UPDATED,
          people,
          fields,
        });
      });
    } else if (fields.new_role_placeholder !== undefined) {
      const people = people_ids.map((id) => allPeople[id]);

      dispatch({
        type: PEOPLE_BULK_UPDATED,
        people,
        fields,
      });
    }
  };
