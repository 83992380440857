import React, { useEffect, useState } from 'react';
import { getAvailablePlans } from 'selectors/pricing';

import { calculatePrice } from '@float/common/lib/pricing';
import { useAppSelector } from '@float/common/store';
import {
  Button,
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  ModalTitle,
  withConfirm,
} from '@float/ui/deprecated';
import * as Icons from '@float/ui/deprecated/Earhart/Icons';
import { WithConfirmExtraProps } from '@float/ui/deprecated/Modal/withConfirm';
import { onChatClick } from 'components/Nav/helpers';

import { StyledModalBody } from './_styles';

export type TrialModalProps = {
  user: {
    is_paid_plan: boolean;
    plan_type: 'yearly' | 'monthly';
  };
  onSubmit: () => Promise<void>;
  close: () => void;
} & WithConfirmExtraProps;

export const TrialModal = withConfirm(
  ({ user, onSubmit, close, confirm }: TrialModalProps) => {
    const [loading, setLoading] = useState(false);
    const plans = useAppSelector(getAvailablePlans);
    const pricing = calculatePrice({ plans, hasTimeTracking: false });

    async function submit() {
      setLoading(true);

      try {
        await onSubmit();
        close();
      } catch {
        confirm?.({
          title: `Start Trial`,
          message: `Sorry, there was an error enabling time tracking. Please
          refresh the page to try again. If the error persists, please contact
          our support team.`,
          hideCancel: true,
          onConfirm: close,
        });
      }
    }

    const price =
      user.plan_type === 'yearly'
        ? `$${pricing.perPersonPerMonthAnnualWithTimeTracking} per person per month, billed annually`
        : `$${pricing.perPersonMonthlyWithTimeTracking} per person per month`;

    useEffect(() => {
      if (!user.is_paid_plan) {
        onSubmit();
      }
    }, []); // eslint-disable-line

    if (!user.is_paid_plan) {
      return null;
    }

    return (
      <Modal isOpen={true} onEnter={submit} onClose={close}>
        <ModalHeader>
          <ModalTitle>Try Time tracking, free for 30 days</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <StyledModalBody>
            <h3>At the end of the trial...</h3>
            <ul>
              <li>
                You’ll be moved to{' '}
                <strong>Resource planning + Time tracking</strong> ({price})
              </li>
              <li>
                You’ll be charged the cost of the plan minus any prorated credit
                based on your current plan
              </li>
              <li>You can cancel your trial at any time</li>
            </ul>
            <a
              href="https://support.float.com/en/articles/3616990-time-tracking"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </a>
            <p>Note: prices exclude taxes and discounts.</p>
          </StyledModalBody>
        </ModalBody>
        <ModalActions>
          <Button loader={loading} onClick={submit}>
            Start my free trial
          </Button>
          <Button appearance="secondary" onClick={close}>
            Cancel
          </Button>
          <Button
            appearance="clear"
            style={{ marginLeft: 'auto', marginRight: 0 }}
            icon={Icons.IconUser}
            onClick={onChatClick}
          >
            Message support
          </Button>
        </ModalActions>
      </Modal>
    );
  },
);
