import { Dispatch } from 'react';

import API3 from '@float/common/api3';
import { logger } from '@float/libs/logger';

import { LOGGED_TIME_UPDATED } from '../loggedTimes';
import { TIMER_DELETE } from './constants';
import { LoggedTimeUpdatedAction, TimerDeleteAction } from './types';

export const deleteTimer = (id: string) => {
  return async (
    dispatch: Dispatch<TimerDeleteAction | LoggedTimeUpdatedAction>,
  ) => {
    try {
      const loggedTime = await API3.deleteTimer(id);

      dispatch({ type: LOGGED_TIME_UPDATED, loggedTime });

      dispatch({
        type: TIMER_DELETE,
        timerId: id,
      });
    } catch (e) {
      logger.error('There was an error while deleting a timer', e);
    }
  };
};

export const deleteTimerFromLiveUpdate = (id: string) => {
  return async (dispatch: Dispatch<TimerDeleteAction>) => {
    dispatch({
      type: TIMER_DELETE,
      timerId: id,
    });
  };
};
