import { keyBy } from 'lodash';

import { addOption, getSearchContext } from '../actions/processSidebarItems';

const getRelevantChanges = (state, changes) => {
  const { projectUpdates, projectDeletes } = changes || {};
  const { relevant } = state;
  const relevantChanges = {};

  if (projectUpdates) {
    relevantChanges.projectUpdates = projectUpdates.filter(
      (x) => x.projectId && relevant.projects[x.projectId],
    );
    if (!relevantChanges.projectUpdates.length) {
      delete relevantChanges.projectUpdates;
    } else {
      relevantChanges.projectUpdates = keyBy(
        relevantChanges.projectUpdates,
        'projectId',
      );
    }
  }

  if (projectDeletes) {
    relevantChanges.projectDeletes = projectDeletes.filter(
      (x) => relevant.projects[x],
    );
    if (!relevantChanges.projectDeletes.length) {
      delete relevantChanges.projectDeletes;
    }
  }

  return relevantChanges;
};

const getCurrentUser = ({ admin_id, account_tid }) => {
  return {
    accountId: +admin_id,
    isMember: +account_tid === 4,
  };
};

export const getChangesFromProjectUpdate = (
  action,
  { admin_id, account_tid },
) => {
  const delta = {};
  const { project, prevProject } = action;
  if (!project || !prevProject) {
    return null;
  }

  const { project_id: projectId } = project;
  if (prevProject.active && !project.active) {
    // Currently, issues do not re-appear if a project is unarchived
    return { projectDeletes: [projectId] };
  }

  delta.projectId = projectId;
  const newName = project.name || project.project_name;
  const oldName = prevProject.name || prevProject.project_name;
  if (newName !== oldName) {
    delta.projectName = newName;
  }

  if (project.color !== prevProject.color) {
    delta.projectColor = project.color;
  }

  const { accountId } = getCurrentUser({ admin_id, account_tid });
  if (project.project_manager !== prevProject.project_manager) {
    if (project.project_manager === accountId) {
      delta.myProject = true;
    } else if (prevProject.project_manager === accountId) {
      delta.myProject = false;
    }
  }

  return { projectUpdates: [delta] };
};

export const processChanges = (state, changes, { admin_id, account_tid }) => {
  if (!state.items || !state.items.length) {
    return state;
  }

  const relevantChanges = getRelevantChanges(state, changes);

  const { projectUpdates, projectDeletes } = relevantChanges;
  if (!projectUpdates && !projectDeletes) {
    return state;
  }

  const updateItem = (item) => {
    if (projectUpdates && projectUpdates[item.projectId]) {
      return {
        ...item,
        ...projectUpdates[item.projectId],
      };
    }
    return item;
  };

  const shouldDeleteItem = (item) =>
    projectDeletes && projectDeletes.includes(item.projectId);

  const newState = { ...state };

  const count = newState.items.length;
  const filteredCount = newState.filteredItems.length;
  const items = [];
  const filteredItems = [];

  let atLeastOneProjectOwnedByMe;
  const projectsOptions = [];
  const projectsIndexed = {};

  const peopleOptions = [];
  const peopleIndexed = {};

  const itemsOptions = [];
  const itemsIndexed = {};
  const relevant = { people: {}, projects: {} };

  for (let i = 0; i < count; i++) {
    const item = updateItem(newState.items[i]);
    if (!shouldDeleteItem(item)) {
      if (item.myProject) {
        atLeastOneProjectOwnedByMe = true;
      }

      items.push(item);
      relevant.projects[item.projectId] = true;
      relevant.people[item.assigneePeopleId] = true;

      addOption(projectsOptions, projectsIndexed, item.projectName);
      addOption(peopleOptions, peopleIndexed, item.personName);
      addOption(itemsOptions, itemsIndexed, item.searchableName);
    }

    if (i < filteredCount) {
      const filteredItem = updateItem(newState.filteredItems[i]);
      if (!shouldDeleteItem(filteredItem)) {
        filteredItems.push(filteredItem);
      }
    }
  }

  newState.items = items;
  newState.filteredItems = filteredItems;
  newState.searchContext = getSearchContext({
    myProjects:
      atLeastOneProjectOwnedByMe ||
      getCurrentUser({ admin_id, account_tid }).isMember,
    projectsOptions,
    itemsOptions,
    peopleOptions,
    type: newState.type,
  });
  newState.relevant = relevant;

  return newState;
};
