import React, { useState } from 'react';
import { t } from '@lingui/macro';

import PersonAvatar from '@float/common/components/elements/PersonAvatar';
import { Person } from '@float/types/person';

import { ArchivedTag } from '../../components/ArchivedTag';
import { StatusIndicator } from '../../components/StatusIndicator';
import { ProjectTeamMemberField } from '../../fields/ProjectTeamMemberField';
import {
  useCheckPersonAllocation,
  useShowPersonModal,
} from './ProjectTeamSection.hooks';

import * as styles from './styles.css';

export const ProjectTeamMemberInfo: React.FC<{
  onEnterSelect: () => void;
  index: number;
  person: Person | null;
  showingRates: boolean;
}> = ({ index, person, showingRates, onEnterSelect }) => {
  const showPersonModal = useShowPersonModal(person);
  const hasAllocations = useCheckPersonAllocation(person?.people_id);

  // After selecting a person we restore focus on the row
  // otherwise when removing the input the focus would go to the window
  const [autoFocus, setAutofocus] = useState(false);

  function handlePersonChange() {
    setAutofocus(true);
  }

  function handleButtonFocus() {
    setAutofocus(false);
  }

  return person ? (
    <button
      type="button"
      className={styles.projectTeamMemberInfo}
      onClick={showPersonModal}
      autoFocus={autoFocus}
      onFocus={handleButtonFocus}
    >
      <PersonAvatar personId={person.people_id} readOnly size="xs" />
      <StatusIndicator
        active={hasAllocations}
        activeTooltip={t`Time assigned`}
        inactiveTooltip={t`No time assigned`}
      />
      <span
        className={styles.projectTeamMemberName({
          color: person?.active ? 'default' : 'readonly',
        })}
      >
        {person.name}
      </span>
      {!person.active && (
        <ArchivedTag data-testid={`personArchive_${index}`} size="small" />
      )}
    </button>
  ) : (
    <ProjectTeamMemberField
      index={index}
      showingRates={showingRates}
      onChange={handlePersonChange}
      onEnterSelect={onEnterSelect}
    />
  );
};
