import { t } from '@lingui/macro';

import { getRoundedPercentage } from '@float/common/lib/budget/helpers/getPercentage';
import { BudgetType } from '@float/types/project';
import { ProjectPercentageModes } from '@float/ui/deprecated/Chart/types';

import { SimplifiedReportsProject } from '../types';

type GetBudgetDataObjectEntity = {
  budget: number | null | undefined;
  budget_type?: number | null;
};

type GetBudgetDataObjectOptions = {
  entityType: 'common' | 'task';
};

export function getBudgetDataObject(
  { budget_type, budget }: GetBudgetDataObjectEntity,
  { entityType }: GetBudgetDataObjectOptions = { entityType: 'common' },
) {
  let val: string | number | null = '-';

  // Ensure task budgets are not appearing as zeros
  if (entityType === 'task') {
    return { val, sortVal: 1, type: budget_type };
  }

  if (budget_type === BudgetType.HourlyFee) {
    val = t`Hourly fee`;
  } else if (budget_type !== BudgetType.Disabled) {
    val = budget ?? 0;
  }

  // We multiply hour-based budgets by a really large number to hack the sort
  // into grouping hour budgets and money budgets.
  // TODO: Update the sorting logic in AccordionTable to allow something better
  const sortVal =
    typeof val === 'string'
      ? Number.POSITIVE_INFINITY
      : val * (budget_type === 1 ? 1000000000000 : 1);

  return { sortVal, val, type: budget_type };
}

export function getRelevantBreakdownEntry(
  report: SimplifiedReportsProject,
  {
    percentageMode,
    useBillable = false,
    useFees = false,
  }: {
    percentageMode: ProjectPercentageModes;
    useBillable?: boolean;
    useFees?: boolean;
  },
) {
  const breakdown =
    percentageMode === ProjectPercentageModes.SCHEDULED
      ? report.scheduled
      : report.logged;

  if (useFees) {
    return useBillable ? breakdown.billableFee : breakdown.fee;
  }

  return useBillable ? breakdown.billableHours : breakdown.hours;
}

export function getFeeOrHoursAsPercentageOfBudget(
  report: SimplifiedReportsProject,
  {
    percentageMode,
    useBillable = false,
    useFees = false,
  }: {
    percentageMode: ProjectPercentageModes;
    useBillable?: boolean;
    useFees?: boolean;
  },
) {
  const { budget, budget_type } = report;

  const hasBudgetSupport =
    budget_type === BudgetType.TotalHours ||
    budget_type === BudgetType.TotalFee;

  if (!hasBudgetSupport || !budget) return '';

  const feesOrHours = getRelevantBreakdownEntry(report, {
    percentageMode,
    useBillable,
    useFees,
  });

  return getRoundedPercentage(feesOrHours, budget);
}
