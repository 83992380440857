import {
  createLoggedTime,
  deleteLoggedTime,
  LOGGED_TIME_BULK_CREATED,
  LOGGED_TIME_BULK_CREATED_UNDO,
  LOGGED_TIME_CREATED,
  LOGGED_TIME_DELETED,
  LOGGED_TIME_UPDATED,
  undoBulkCreateLoggedTimes,
} from '@float/common/actions/loggedTimes';
import {
  PHASES_UPDATED,
  SHIFT_PHASE_TIMELINE,
  shiftPhaseTimeline,
  updatePhase,
} from '@float/common/actions/phases';
import {
  CREATE_TASK_SUCCESS,
  DELETE_TASK_SUCCESS,
  deleteTask,
  LINKED_TASKS_UPDATE_SUCCESS,
  SPLIT_TASK,
  SPLIT_TASK_DELETE_SUCCESS,
  UPDATE_TASK_SUCCESS,
  updateLinkedTasks,
  updateTask,
} from '@float/common/actions/tasks';
import {
  createTimeoff,
  deleteTimeoff,
  updateTimeoff,
} from '@float/common/actions/timeoffs';
import {
  CREATE_TIMEOFF_SUCCESS,
  DELETE_TIMEOFF_SUCCESS,
  UPDATE_TIMEOFF_SUCCESS,
} from '@float/common/actions/timeoffsConstants';
import {
  VIEW_CREATED,
  VIEW_DELETED,
  VIEW_UPDATED,
} from '@float/common/actions/views';
import { asyncProcessEvent } from '@float/common/lib/asyncProcess';
import { AllActions } from '@float/common/reducers';
import { AppDispatch, AppDispatchStrict, AppStore } from '@float/common/store';
import { LoggedTime } from '@float/types';

import { handleViewActions } from './handleViewActions';
import { revertDeleteSplitTask, revertDeleteTask } from './revertDeleteTask';

export const UNDO_BEGIN = 'UNDO/begin';
export const REDO_BEGIN = 'REDO/begin';
export const SET_UNDO_STACK_MODE = 'UNDO/set_stack_mode';

const beginUndo = (batchId: number) => ({ type: UNDO_BEGIN, batchId });
const beginRedo = (batchId: number) => ({ type: REDO_BEGIN, batchId });

const reverseAction =
  (action: AllActions) =>
  (dispatch: AppDispatchStrict, getState: AppStore['getState']) => {
    switch (action.actionType) {
      case SPLIT_TASK_DELETE_SUCCESS:
        return revertDeleteSplitTask(action, dispatch, getState);
      case CREATE_TASK_SUCCESS:
        return dispatch(deleteTask(action.item));
      case DELETE_TASK_SUCCESS:
        return revertDeleteTask(action, dispatch, getState);
      case UPDATE_TASK_SUCCESS:
        return dispatch(updateTask(action.previous));
      case SPLIT_TASK:
        return Promise.all([
          dispatch(deleteTask(action.newTask, { isSplitTask: true })),
          dispatch(
            updateTask(action.previous, {
              originalEntity: action.newTask,
              isSplitTask: true,
            }),
          ),
        ]);
      case CREATE_TIMEOFF_SUCCESS:
        return dispatch(deleteTimeoff(action.item));
      case DELETE_TIMEOFF_SUCCESS:
        return dispatch(createTimeoff(action.item));
      case UPDATE_TIMEOFF_SUCCESS:
        return dispatch(updateTimeoff(action.previous));
      case LOGGED_TIME_CREATED:
        return dispatch(deleteLoggedTime(action.item));
      case LOGGED_TIME_UPDATED:
        if (action.previous) {
          return dispatch(createLoggedTime(action.previous, action.item));
        } else {
          return dispatch(deleteLoggedTime(action.item));
        }
      case LOGGED_TIME_DELETED: {
        const isRedo = true;
        return dispatch(createLoggedTime(action.item, null, isRedo));
      }
      case LOGGED_TIME_BULK_CREATED: {
        return dispatch(
          undoBulkCreateLoggedTimes(
            action.loggedTime.map((x: LoggedTime) => x.logged_time_id),
          ),
        ).then(() => {
          asyncProcessEvent({
            processId: 'bulk-log',
            processType: LOGGED_TIME_BULK_CREATED_UNDO,
          });
        });
      }
      case SHIFT_PHASE_TIMELINE: {
        return dispatch(
          shiftPhaseTimeline(action.originalEntity, {
            originalEntity: action.newPhase,
          }),
        );
      }
      case PHASES_UPDATED: {
        return dispatch(
          updatePhase(action.originalEntity, {
            originalEntity: action.newPhase,
            opts: undefined,
          }),
        );
      }
      case LINKED_TASKS_UPDATE_SUCCESS: {
        return dispatch(
          updateLinkedTasks({
            type: 'linkedTasks',
            group: action.undoPayload,
          }),
        );
      }
      case VIEW_CREATED:
      case VIEW_UPDATED:
      case VIEW_DELETED: {
        return handleViewActions(action, dispatch);
      }
      default:
        return Promise.resolve(false);
    }
  };

export const undo =
  () => async (dispatch: AppDispatch, getState: AppStore['getState']) => {
    const { processing, undoable } = getState().undoCommandStream;
    if (processing || !undoable) {
      return;
    }

    const undoAction = undoable.pop();
    if (!undoAction) {
      return;
    }

    dispatch(beginUndo(undoAction.batchId));
    await dispatch(reverseAction(undoAction));

    if (undoAction.batchId) {
      const latestAction = undoable.length
        ? undoable[undoable.length - 1]
        : null;
      if (!latestAction || latestAction.batchId !== undoAction.batchId) return;
      dispatch(undo());
    }
  };

export const redo =
  () => async (dispatch: AppDispatch, getState: AppStore['getState']) => {
    const { processing, redoable } = getState().undoCommandStream;
    if (processing === 'redo' || !redoable) {
      return;
    }

    const redoAction = redoable.pop();
    if (!redoAction) {
      return;
    }

    dispatch(beginRedo(redoAction.batchId));
    await dispatch(reverseAction(redoAction));

    if (redoAction.batchId) {
      const latestAction = redoable.length
        ? redoable[redoable.length - 1]
        : null;
      if (!latestAction || latestAction.batchId !== redoAction.batchId) return;
      dispatch(redo());
    }
  };

export const setUndoStackMode =
  (mode: 'logTime' | 'schedule') => (dispatch: AppDispatch) => {
    dispatch({ type: SET_UNDO_STACK_MODE, mode });
  };
