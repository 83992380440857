import React, { StrictMode, useState } from 'react';
import { t } from '@lingui/macro';

import {
  deleteRole,
  ensureRolesLoaded,
  updateRole,
} from '@float/common/actions';
import { useCurrencyProps } from '@float/common/hooks/useCurrencyProps';
import { getErrorKey } from '@float/common/lib/errors';
import { useAppDispatchStrict } from '@float/common/store';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { useOnMount } from '@float/libs/hooks/useOnMount';
import { promiseWithResolvers } from '@float/libs/utils/promiseWithResolvers';
import { Role } from '@float/types';
import { useSnackbar } from '@float/ui/deprecated';
import { Card, CardRow } from '@float/ui/deprecated/Earhart/Cards';

import { NavigationGuard } from '../../../components/NavigationGuard';
import Body from '../../components/Body';
import { AddRole } from '../../components/Roles/AddRole/AddRole';
import { ModalCannotDeleteRole } from '../../components/Roles/ModalCannotDeleteRole/ModalCannotDeleteRole';
import { ModalConfirmDeleterRole } from '../../components/Roles/ModalConfirmDeleteRole/ModalConfirmDeleteRole';
import { ModalConfirmRateChange } from '../../components/Roles/ModalConfirmRateChange/ModalConfirmRateChange';
import { useModal } from '../../components/Roles/useModal';
import Table from '../../components/Table';
import { type EditEntryRow } from '../../components/Table/EditEntry';
import { useRolesTableData } from './useRolesTableData';
import { TableSortOptions, useRowsTableSort } from './useRolesTableSort';

export const Roles = () => {
  const isRolesRatesFeatureEnabled = featureFlags.isFeatureEnabled(
    FeatureFlag.RolesRates,
  );

  const dispatch = useAppDispatchStrict();

  const currencyConfig = useCurrencyProps();

  const { tableSort, setTableSort } = useRowsTableSort();

  const tableSortOptions = {
    property: tableSort?.sortBy,
    direction: tableSort?.sortDir,
  };

  const { tableHeadNames, tableHeadNameKeys, tableColumnsWidth, tableRows } =
    useRolesTableData({
      tableSort,
      isRolesRatesFeatureEnabled,
    });

  const { showSnackbar, showError } = useSnackbar();

  const modalConfirmDelete = useModal();
  const modalConfirmRateChange = useModal();
  const modalCannotDeleteRole = useModal();

  const [inTableEditMode, setInTableEditMode] = useState<number | null>(null);

  const enableTableEditMode = (id: Role['id']) => {
    setInTableEditMode(id);
  };
  const disableTableEditMode = () => {
    setInTableEditMode(null);
  };

  const handleClickHeader = (
    sortBy: TableSortOptions['sortBy'],
    sortDir: TableSortOptions['sortDir'],
  ) => {
    setTableSort({
      sortBy,
      sortDir,
    });
  };

  const handleDelete = ({ entity }: { entity: Role }) => {
    const roleName = entity.name;

    // Only allow Role deletions when no people are attached to role
    if (entity.people_count !== 0) {
      modalCannotDeleteRole.open(
        <ModalCannotDeleteRole
          roleName={roleName}
          onClose={modalCannotDeleteRole.close}
        />,
      );

      return;
    }

    modalConfirmDelete.open(
      <ModalConfirmDeleterRole
        roleName={roleName}
        onConfirm={() => {
          modalConfirmDelete.close();
          handleDeleteConfirmed(entity);
        }}
        onCancel={modalConfirmDelete.close}
      />,
    );
  };

  const handleDeleteConfirmed = async (entity: Role | null) => {
    if (!entity) return;

    const entityName = entity.name;

    try {
      await dispatch(
        deleteRole(entity.id, {
          trackEvent: true,
        }),
      );

      showSnackbar(t`${entityName} deleted.`);
    } catch {
      showError(t`Failed to delete ${entityName}.`);
    }
  };

  /**
   * This one is bit tricky, because Table component assumes
   *
   * - onSubmit is a promise
   * - row edit mode is disabled once onSubmit is resolved
   *
   * Since here we're inroducing an intermediate confirm modal step when
   * role rate is changed and it's affecting any people, we're using a deferred promise pattern,
   * so that the table row will remain in edit mode if the submit action wasn't successful
   *
   * Ideally the Table component should be re-implemented
   */
  const handleSubmit = async (row: EditEntryRow<Role>, newData: string[]) => {
    const { id, data, entity } = row;
    const [, roleDefaultHourlyRateOriginal] = data;
    const [roleNameEdited, roleDefaultHourlyRateEdited] = newData;

    const submitPayload = {
      name: roleNameEdited,
      default_hourly_rate: roleDefaultHourlyRateEdited || null,
      entity,
    };

    if (
      isRolesRatesFeatureEnabled &&
      entity.people_count &&
      roleDefaultHourlyRateOriginal !== roleDefaultHourlyRateEdited
    ) {
      const deferred = promiseWithResolvers();

      const rateFromString =
        typeof roleDefaultHourlyRateOriginal === 'number'
          ? roleDefaultHourlyRateOriginal.toString()
          : roleDefaultHourlyRateOriginal;

      const rateToString = roleDefaultHourlyRateEdited;

      modalConfirmRateChange.open(
        <ModalConfirmRateChange
          rateFrom={rateFromString}
          rateTo={rateToString}
          numPeopleAffected={entity.people_count}
          currencyConfig={currencyConfig}
          onCancel={modalConfirmRateChange.close}
          onConfirm={() => {
            modalConfirmRateChange.close();
            handleSubmitConfirmed(id, submitPayload, deferred);
          }}
        />,
      );

      return deferred.promise;
    }

    return handleSubmitConfirmed(id, submitPayload);
  };

  const handleSubmitConfirmed = async (
    id: number,
    payload: {
      name: string;
      default_hourly_rate: string | null;
    },
    deferred?: PromiseWithResolvers<unknown>,
  ) => {
    const payloadName = payload.name;
    try {
      await dispatch(
        updateRole(id, payload, {
          trackEvent: true,
        }),
      );

      if (deferred) deferred.resolve(true);

      disableTableEditMode();
      showSnackbar(t`"${payloadName}" updated.`);
    } catch (err) {
      const errorField = getErrorKey(err, 'field');

      let errorMessage = '';

      if (errorField === 'name') {
        errorMessage = t`Role update failed: "${payloadName}" already exists.`;
      } else if (errorField === 'default_hourly_rate') {
        errorMessage = t`Role update failed: invalid hourly rate value`;
      } else {
        errorMessage = t`Role update failed`;
      }

      showError(errorMessage);

      if (deferred) return deferred?.reject();
    }
  };

  useOnMount(() => {
    dispatch(ensureRolesLoaded());
  });

  return (
    <StrictMode>
      <Body
        header={t`Roles`}
        subheader={t`Add, edit and remove Roles for People in your organization.`}
      >
        <Card>
          <CardRow>
            <AddRole />
          </CardRow>
          <NavigationGuard
            title={t`Unsaved edits`}
            message={t`You have unsaved edits, are you sure you want to continue?`}
            cancelLabel={t`Go back`}
            confirmLabel={t`Continue without saving`}
            shouldShowPromptOnNavigation={Boolean(inTableEditMode)}
          />
          <CardRow>
            <Table
              recordNames={['role', 'roles']}
              headNames={tableHeadNames}
              headNameKeys={tableHeadNameKeys}
              deleteLabel={t`Delete`}
              enableTableEditMode={enableTableEditMode}
              disableTableEditMode={disableTableEditMode}
              columnsWidth={tableColumnsWidth}
              inTableEditMode={inTableEditMode}
              onClickHeader={handleClickHeader}
              onSubmit={handleSubmit}
              onClickDelete={handleDelete}
              rows={tableRows}
              sort={tableSortOptions}
              rowActions={[
                {
                  id: 'edit',
                  label: t`Edit`,
                  config: {
                    disableDefaultSnackbar: true,
                  },
                },
                {
                  id: 'delete',
                  label: t`Delete`,
                  config: {
                    disableDefaultSnackbar: true,
                  },
                },
              ]}
              displayRowCount={false}
              isLoading={false}
            />

            {modalConfirmDelete.render()}
            {modalConfirmRateChange.render()}
            {modalCannotDeleteRole.render()}
          </CardRow>
        </Card>
      </Body>
    </StrictMode>
  );
};
