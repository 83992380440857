import React from 'react';

import BaseIcon, { BaseIconProps } from './BaseIcon';

const IconStop = (props: BaseIconProps) => (
  <BaseIcon {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M5 5.2C5 4.54 5.54 4 6.2 4h12.6c.66 0 1.2.54 1.2 1.2v12.6c0 .66-.54 1.2-1.2 1.2H6.2A1.2 1.2 0 0 1 5 17.8V5.2Z"
    />
  </BaseIcon>
);

export { IconStop };
