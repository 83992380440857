import React from 'react';

import PersonPeople from '../PersonModal/PersonPeople';

export default function Manages({ value, onChange }) {
  return (
    <PersonPeople
      account={value || {}}
      onlyDepartments
      onChange={(nextAccount) => {
        onChange({
          ...value,
          ...nextAccount,
        });
      }}
    />
  );
}
