import React from 'react';
import { Link } from 'react-router-dom';
import { t } from '@lingui/macro';
import { useWebAppSelectorStrict } from 'lib/store';
import { orderBy } from 'lodash';

import { useCurrencyProps } from '@float/common/hooks/useCurrencyProps';
import {
  formatRate,
  formatRateWithCurrencySymbol,
} from '@float/common/lib/rates/rates';
import { getUser } from '@float/common/selectors/currentUser';
import { getPeopleByRoleId } from '@float/common/selectors/people/people';

import { TableSortOptions } from './useRolesTableSort';

type UseRolesTableDataProps = {
  isRolesRatesFeatureEnabled: boolean;
  tableSort?: TableSortOptions;
};

/**
 * Returns roles table data formatted for table consumption
 */
export const useRolesTableData = ({
  isRolesRatesFeatureEnabled,
  tableSort,
}: UseRolesTableDataProps) => {
  const stateRoles = useWebAppSelectorStrict((state) => state.roles);
  const statePeopleByRoleId = useWebAppSelectorStrict(getPeopleByRoleId);
  const currentUser = useWebAppSelectorStrict(getUser);
  const currencyConfig = useCurrencyProps();

  let tableHeadNames: string[];
  let tableHeadNameKeys: string[];
  let tableColumnsWidth: string[] | undefined;

  const inputPlaceholderRoleName = t`Role name`;
  const inputPlaceholderRoleRate = '0';

  if (isRolesRatesFeatureEnabled) {
    tableHeadNames = [t`Roles`, t`Hourly rate`, t`People`];
    tableHeadNameKeys = ['name', 'default_hourly_rate', 'people_count'];
    tableColumnsWidth = ['50%', '20%', '30%'];
  } else {
    tableHeadNames = [t`Roles`, t`People`];
    tableHeadNameKeys = ['name', 'people_count'];
    tableColumnsWidth = undefined;
  }

  const tableRowsUnsorted = Object.values(stateRoles.roles)
    .map((role) => ({
      ...role,
      people_count: statePeopleByRoleId[role.id]?.length || 0,
    }))
    .map((role) => {
      const roleName = role.name;
      const rolePeopleCount = role.people_count;
      const roleDefaultHourlyRateFormatted =
        typeof role.default_hourly_rate === 'string' &&
        role.default_hourly_rate.length > 0
          ? formatRate(role.default_hourly_rate)
          : null;

      let data: unknown[] = [];
      let display: unknown[] = [];
      let editConfig: unknown[] = [];

      const roleNameEncoded = encodeURIComponent(role.name);
      const rolePeopleCountLink =
        role.people_count > 0 ? (
          <Link to={`/people?jobTitle=${roleNameEncoded}`}>
            {role.people_count}
          </Link>
        ) : (
          0
        );

      if (isRolesRatesFeatureEnabled) {
        data = [roleName, roleDefaultHourlyRateFormatted, rolePeopleCount];
        display = [
          null,
          formatRateWithCurrencySymbol(
            role.default_hourly_rate,
            currencyConfig,
          ),
          rolePeopleCountLink,
        ];
        editConfig = [
          {
            type: 'text',
            placeholder: inputPlaceholderRoleName,
          },
          {
            type: 'currency',
            placeholder: inputPlaceholderRoleRate,
            currentUser,
          },
          null,
        ];
      } else {
        data = [roleName, rolePeopleCount];
        display = [null, rolePeopleCountLink];
        editConfig = [
          {
            type: 'text',
            placeholder: inputPlaceholderRoleName,
          },
          null,
        ];
      }

      return {
        data,
        display,
        editConfig,
        entity: role,
        id: role.id,
        isDeletable: true,
        isEdited: false,
      };
    });

  const tableRows = orderBy(
    tableRowsUnsorted,
    (row) => {
      if (!tableSort) return row.entity.name;

      const value = row.entity[tableSort['sortBy']];

      if (tableSort.sortBy === 'default_hourly_rate')
        return parseFloat((value as string | null) || '-1'); // the unset values should come befor '0' values

      if (typeof value === 'string') return value.toLowerCase();

      return value;
    },
    tableSort?.sortDir,
  );

  return {
    tableSort,
    tableRows,
    tableHeadNames,
    tableHeadNameKeys,
    tableColumnsWidth,
  };
};
