import { format } from 'date-fns';

import { TimeFormat, Timer, TimerTimes } from '@float/types';

import { TWELVE_HOUR_FORMAT } from './constants';
import { formatAsClockTime } from './formatAsClockTime';
import { formatMsAsClockTime } from './formatMsAsClockTime';
import { getTimestampDelta } from './getTimestampDelta';

function parseTimer(timer: Timer, timeFormat: TimeFormat): TimerTimes | null {
  const { start_timestamp, end_timestamp } = timer;

  if (!end_timestamp) return null;

  const timestampDelta = getTimestampDelta(start_timestamp, end_timestamp);

  const logged = formatMsAsClockTime(timestampDelta);
  const start = formatAsClockTime(
    format(start_timestamp, TWELVE_HOUR_FORMAT),
    timeFormat,
  );
  const end = formatAsClockTime(
    format(end_timestamp, TWELVE_HOUR_FORMAT),
    timeFormat,
  );

  return { logged, start, end };
}

export { parseTimer };
