import { ReactNode } from 'react';

import IconWarningTriangle from '@float/ui/deprecated/Earhart/Icons/Icon/IconWarningTriangle';

import useSnackbar from './useSnackbar';

export function useWarningSnackbar() {
  const { showSnackbar } = useSnackbar();

  return (message: ReactNode) => {
    showSnackbar(message, {
      iconLeft: IconWarningTriangle,
    });
  };
}
