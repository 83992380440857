import { ReduxStateStrict } from '@float/common/reducers/lib/types';

import { getLoggedTimeRangeInsights } from './selectors/getLoggedTimeRangeInsights';
import { getTimeRangeInsights } from './selectors/getTimeRangeInsights';
import { TimeRangeInsightsParams } from './types';

export function getTimeRangeInsightsData(
  state: ReduxStateStrict,
  params: TimeRangeInsightsParams,
) {
  if (params.type === 'loggedTime') {
    return getLoggedTimeRangeInsights(state, params);
  }

  return getTimeRangeInsights(state, params);
}
