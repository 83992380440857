const { isArray } = Array;

export default function BiMap<K = string, V = string>() {
  const fwd = new Map<K, V[]>();
  const rev = new Map<V, K[]>();

  /**
   * assoc will ADD a given key:val pair to the current bimap.
   *
   * Note: It will NOT check for uniqueness among the vals that exist and are
   * passed in - it's up to the caller to prevent duplication
   */
  function assoc(key: K, val: V | V[]) {
    const valArr = isArray(val) ? val : [val];

    if (!fwd.has(key)) {
      fwd.set(key, valArr);
    } else {
      const arr = fwd.get(key)!;
      for (const v of valArr) {
        if (!arr.includes(v)) {
          arr.push(v);
        }
      }
    }

    for (const v of valArr) {
      if (!rev.has(v)) {
        rev.set(v, [key]);
      } else {
        const arr = rev.get(v)!;
        if (!arr.includes(key)) {
          arr.push(key);
        }
      }
    }
  }

  /**
   * replace will DELETE any previous values associated with the given key
   * and then set the passed in val(s) to the key.
   *
   * Note: Same uniqueness constraint from assoc applies here
   */
  function replace(key: K, val: V | V[]) {
    const valArr = isArray(val) ? val : [val];

    if (fwd.has(key)) {
      const arr = fwd.get(key)!;
      for (const v of arr) {
        const keys = rev.get(v)!;
        const idx = keys.indexOf(key);
        keys.splice(idx, 1);
      }
    }

    fwd.set(key, valArr);

    for (const v of valArr) {
      if (!rev.has(v)) {
        rev.set(v, [key]);
      } else {
        const arr = rev.get(v)!;
        if (!arr.includes(key)) {
          arr.push(key);
        }
      }
    }
  }

  function getFwd(key: K) {
    return fwd.get(key) || [];
  }

  function getRev(val: V) {
    return rev.get(val) || [];
  }

  // delete is a reserved keyword, but we want to use it for symmetry with Map
  function _delete(key: K) {
    if (!fwd.get(key)) return [];

    const arr = fwd.get(key)!;

    for (const v of arr) {
      const revArr = rev.get(v)!;
      if (revArr.length === 1) {
        rev.delete(v);
      } else {
        const idx = revArr.indexOf(key);
        revArr.splice(idx, 1);
      }
    }

    fwd.delete(key);

    return arr || [];
  }

  return {
    getFwd,
    getRev,
    assoc,
    replace,
    delete: _delete,
    forEachFwd: fwd.forEach.bind(fwd),
    forEachRev: rev.forEach.bind(rev),
  };
}
