import React from 'react';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import BaseIcon from '@float/ui/deprecated/Earhart/Icons/Icon/BaseIcon';

export default function ({
  children,
  className,
  color = Colors.FIN.Lt.Emphasis.High,
  size = 24,
}) {
  return (
    <BaseIcon className={className} color={color} size={size}>
      {children}
    </BaseIcon>
  );
}
