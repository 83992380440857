import React, { useCallback } from 'react';
import modalManagerHoc from 'modalManager/modalManagerHoc';
import styled from 'styled-components';

import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { Button, Modal } from '@float/ui/deprecated';

const MODAL_TYPE = 'integrationCalendarDisconnectedModal';

const BodyTitle = styled.p`
  font-weight: 600;
`;

function IntegrationCalendarDisconnectedModal(props) {
  const { manageModal } = props;
  const hide = useCallback(
    (evt) => {
      prevent(evt);
      manageModal({
        visible: false,
        modalType: MODAL_TYPE,
      });
    },
    [manageModal],
  );

  return (
    <Modal isOpen onClose={hide}>
      <Modal.Header>
        <Modal.Title>Disconnect calendar</Modal.Title>
      </Modal.Header>
      <form onSubmit={hide}>
        <Modal.Body>
          <BodyTitle>Calendar disconnected.</BodyTitle>
          <p>
            Some tasks from your calendar have previously been unsynced in
            Float. These will remain on your Float schedule.
          </p>
        </Modal.Body>

        <Modal.Actions>
          <Button type="submit">Ok</Button>
        </Modal.Actions>
      </form>
    </Modal>
  );
}

export default modalManagerHoc({
  Comp: IntegrationCalendarDisconnectedModal,
  modalType: MODAL_TYPE,
});
