import React from 'react';
import { t } from '@lingui/macro';

import { SavedView } from '@float/types';
import { IconCheck } from '@float/ui/icons/essentials/IconCheck';
import { IconLayers } from '@float/ui/icons/essentials/IconLayers';
import { IconPin } from '@float/ui/icons/essentials/IconPin';
import { IconShare } from '@float/ui/icons/essentials/IconShare';
import { List } from '@float/ui/primitives/List';

import { AccessControlProps } from './types';
import { ViewEditActions } from './ViewEditActions';

import * as styles from './ViewOption.css';

export type ViewOptionProps = {
  view: SavedView;
  acl: AccessControlProps;
  selected?: boolean;
  onSelect: (view: SavedView) => void;
  onEdit: (view: SavedView) => void;
  onPersonalChange: (view: SavedView, personal: boolean) => void;
  onDelete: (view: SavedView) => void;
  onPinChange: (view: SavedView, pinned: boolean) => void;
  className?: string;
};

export function ViewOption({
  acl,
  className,
  onDelete,
  onEdit,
  onPersonalChange,
  onPinChange,
  onSelect,
  selected = true,
  view,
}: ViewOptionProps) {
  function handleClick() {
    onSelect(view);
  }

  return (
    <List.Item.Root
      className={className}
      role="option"
      selected={selected}
      onClick={handleClick}
    >
      <List.Item.Icon>
        {view.personal ? (
          <IconLayers title={t`Personal View`} size={20} />
        ) : (
          <IconShare title={t`Shared View`} size={20} />
        )}
      </List.Item.Icon>

      <List.Item.Value className={styles.viewOptionValue}>
        {view.name}
      </List.Item.Value>

      {view.pinned && (
        <List.Item.Icon className={styles.viewOptionPinIcon}>
          <IconPin size={20} />
        </List.Item.Icon>
      )}

      {selected && (
        <List.Item.Icon>
          <IconCheck size={20} />
        </List.Item.Icon>
      )}
      {!selected && (
        <ViewEditActions
          acl={acl}
          iconSize={20}
          onDelete={onDelete}
          onEdit={onEdit}
          onPersonalChange={onPersonalChange}
          onPinChange={onPinChange}
          sideOffset={10}
          view={view}
        />
      )}
    </List.Item.Root>
  );
}
