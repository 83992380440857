import React, { ComponentPropsWithRef } from 'react';
import cn from 'classnames';

import * as styles from './styles.css';

export type ModalButtonGroupProps = ComponentPropsWithRef<'div'>;

export const ModalButtonGroup = (props: ModalButtonGroupProps) => {
  const { children, className, ...attributes } = props;

  return (
    <div className={cn(styles.buttonGroup, className)} {...attributes}>
      {children}
    </div>
  );
};
