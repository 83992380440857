import { PERSON_RELATED_KEYS } from '@float/common/search/helpers';

import { SearchFilterDropdownCategoryItem } from '../types';

export function filterSearchCategories(
  data: Array<SearchFilterDropdownCategoryItem>,
  personRelatedKeys: typeof PERSON_RELATED_KEYS,
  predicate: {
    hideMeFilter: boolean;
    hidePeopleFilter: boolean;
    hasSavedFilters: boolean;
  },
) {
  const { hideMeFilter, hidePeopleFilter, hasSavedFilters } = predicate;

  return data.filter((category) => {
    const { hideInAllView, value } = category;

    const skipCurrentFilter = hideInAllView;
    const skipMeFilter = hideMeFilter && value === 'me';
    const skipPersonFilter =
      hidePeopleFilter && personRelatedKeys.includes(category.type);
    const skipSavedFilter =
      category.type === 'savedSearches' && !hasSavedFilters;

    return !(
      skipSavedFilter ||
      skipPersonFilter ||
      skipMeFilter ||
      skipCurrentFilter
    );
  });
}
