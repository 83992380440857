import React, { MouseEvent } from 'react';
import { t } from '@lingui/macro';

import { PeopleProjectViewStatus, updateUserPref } from '@float/common/actions';
import { useAppDispatchStrict } from '@float/common/store';
import { CheckboxCheckedState } from '@float/ui/components/Checkbox/types';
import { IconUserPlus } from '@float/ui/icons/essentials/IconUserPlus';
import { ManageQuickFilters } from '@float/web/components/ManageQuickFilters';
import { ActiveArchivedFilterOption } from '@float/web/components/ManageQuickFilters/components/ActiveArchivedFilter';

export type PeopleQuickFiltersProps = {
  activeCount: number;
  archivedCount: number;
  canCreate: boolean;
  currentFilterValue: PeopleProjectViewStatus;
  currentCheckboxValue: boolean;
  onCheckboxChange: (checked: boolean) => void;
  onClickCreate: (e: MouseEvent) => void;
};

export const PeopleQuickFilters = (props: PeopleQuickFiltersProps) => {
  const {
    activeCount,
    archivedCount,
    canCreate,
    currentCheckboxValue,
    currentFilterValue,
    onCheckboxChange,
    onClickCreate,
  } = props;

  const dispatch = useAppDispatchStrict();

  const onFilterChangeHandler = (option: ActiveArchivedFilterOption) => {
    dispatch(updateUserPref('people_view', option.value));
  };

  const onCheckboxChangeHandler = (checked: CheckboxCheckedState) => {
    onCheckboxChange(Boolean(checked));
  };

  return (
    <ManageQuickFilters
      activeCount={activeCount}
      archivedCount={archivedCount}
      canCreate={canCreate}
      checkboxLabel={t`Only show people with access rights`}
      createLabel={t`Add person`}
      currentCheckboxValue={currentCheckboxValue}
      currentFilterValue={currentFilterValue}
      iconCreate={IconUserPlus}
      onCheckboxChange={onCheckboxChangeHandler}
      onClickCreate={onClickCreate}
      onFilterChanged={onFilterChangeHandler}
    />
  );
};
