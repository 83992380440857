import React from 'react';

import { TextTooltip } from '@float/ui/deprecated';
import * as Icons from '@float/ui/deprecated/Earhart/Icons';

import { StyledAction, StyledColumnAction } from './styles';

import * as styles from './Table.css';

type EditEntryActionsProps = {
  onClickSave: () => void;
  onClickCancel: () => void;
  virtualized: boolean;
  confirmLabel?: string;
};

export const EditEntryActions: React.FC<EditEntryActionsProps> = ({
  onClickSave,
  onClickCancel,
  virtualized,
  confirmLabel = 'Save',
}) => {
  return (
    <StyledColumnAction className="cell" as={virtualized ? 'div' : 'td'}>
      <div className={styles.columnActionContainer}>
        <TextTooltip content={confirmLabel} className="hover">
          <StyledAction
            className="update"
            onClick={onClickSave}
            aria-label={confirmLabel}
          >
            <Icons.IconCheck />
          </StyledAction>
        </TextTooltip>

        <TextTooltip content="Cancel" className="hover">
          <StyledAction className="cancel" onClick={onClickCancel}>
            <Icons.IconClose />
          </StyledAction>
        </TextTooltip>
      </div>
    </StyledColumnAction>
  );
};
