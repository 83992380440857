import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon {...props} viewBox="0 0 20 20">
    <path
      d="M10.0002 16.0423C13.3369 16.0423 16.0418 13.3374 16.0418 10.0007C16.0418 6.66393 13.3369 3.95898 10.0002 3.95898C6.66344 3.95898 3.9585 6.66393 3.9585 10.0007C3.9585 13.3374 6.66344 16.0423 10.0002 16.0423Z"
      stroke="#242C39"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.7082 10.0007C12.7082 13.7507 11.0353 16.0423 9.99984 16.0423C8.96434 16.0423 7.2915 13.7507 7.2915 10.0007C7.2915 6.25065 8.96434 3.95898 9.99984 3.95898C11.0353 3.95898 12.7082 6.25065 12.7082 10.0007Z"
      stroke="#242C39"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.1665 10H9.99984H15.8332"
      stroke="#242C39"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </BaseIcon>
);
