import React from 'react';
import { useHistory } from 'react-router-dom';
import { modalManagerConnect } from 'modalManager/modalManagerHoc';

import { getSubdomainPath } from '@float/common/lib/url';
import { isTimeTrackingEnabled } from '@float/common/selectors/companyPrefs';
import {
  getShowUpgradeNotice,
  getUser,
} from '@float/common/selectors/currentUser';
import { useAppSelector } from '@float/common/store';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import useMedia from '@float/libs/web/hooks/useMedia';
import { EH, Row, Spacer } from '@float/ui/deprecated';
import {
  IconCheck,
  IconCog,
  IconPlus,
} from '@float/ui/deprecated/Earhart/Icons';
import colors from '@float/ui/deprecated/Theme/colors';

import { MenuSection, MenuSectionRow, MenuTitle, MenuWrapper } from '../styles';

export const canSeeTeamSettings = (user) => {
  const { account_type_id } = user;
  return [1, 2, 5].includes(account_type_id);
};

const changeCompany = async (company) => {
  window.location.href = getSubdomainPath(`/`, company.company_hostname);
};

const createCompany = async (user) => {
  const url = getSubdomainPath(
    `/join?email=${user.email}&name=${user.name}`,
    'app',
  );

  window.location.href = url;
};

function getDefaultSection(accountTypeId) {
  let defaultSection = 'preferences';
  const isOwnerOrBilling = [1, 5].includes(+accountTypeId);
  if (isOwnerOrBilling) {
    const canAccessBilling = isOwnerOrBilling;
    defaultSection = canAccessBilling ? 'billing' : 'general';
  }
  return defaultSection;
}

const TeamSettingsMenu = ({ onClick }) => {
  const isMobile = useMedia() === 'small';
  const history = useHistory();
  const { expiringSoon } = useAppSelector(getShowUpgradeNotice);
  const hasTimeTracking = useAppSelector(isTimeTrackingEnabled);
  const upgradePath = `/thankyou?plan=${hasTimeTracking ? 'pro' : 'starter'}`;

  const user = useAppSelector(getUser);
  const hasSettingAccess = canSeeTeamSettings(user);
  const activeCompany = user.myCompanies.companies.find(
    (c) => c.company_id == user.cid,
  );
  const otherCompanies = user.myCompanies.companies.filter(
    (c) => c.company_id != user.cid && c.status === 'active',
  );

  const createCompanyHandler = (event) => {
    prevent(event);
    if (onClick) {
      onClick();
    }
    createCompany(user);
  };

  if (isMobile) {
    return (
      <MenuWrapper className="mobile">
        <MenuTitle>Team</MenuTitle>
        <MenuSection>
          <MenuSectionRow color={colors.blueGrey}>
            {activeCompany.company_name}
            <Spacer axis="x" />
            <Row flexShrink="0">
              <IconCheck size={24} />
            </Row>
          </MenuSectionRow>
        </MenuSection>
        <MenuSection>
          {otherCompanies.map((company) => {
            return (
              <MenuSectionRow
                key={company.company_id}
                onClick={(event) => {
                  prevent(event);
                  if (onClick) {
                    onClick();
                  }
                  changeCompany(company);
                }}
              >
                {company.company_name}
              </MenuSectionRow>
            );
          })}
          <MenuSectionRow onClick={createCompanyHandler}>
            <IconPlus />
            <Spacer size={12} />
            Create new Team
          </MenuSectionRow>
        </MenuSection>
      </MenuWrapper>
    );
  }

  return (
    <EH.List
      title={
        <Row justifyContent="space-between">
          <span>Team</span>
          {expiringSoon && (
            <EH.Tags.Tag
              as="a"
              appearance="dynamic"
              color="flue"
              href={upgradePath}
            >
              Upgrade
            </EH.Tags.Tag>
          )}
        </Row>
      }
      options={[
        {
          name: activeCompany.company_name,
          selected: true,
          clickable: false,
        },
        // add Team settings conditionally based on whether
        // user has access to it or not
        ...[
          {
            icon: <IconCog />,
            name: 'Team settings',
            onClick: () =>
              history.push(`/admin/${getDefaultSection(user.account_type_id)}`),
          },
        ].filter(() => hasSettingAccess),

        EH.List.Separator,
        ...otherCompanies.map((company, i) => {
          return {
            name: company.company_name,
            onClick: () => {
              if (onClick) onClick();
              changeCompany(company);
            },
          };
        }),
        ...[otherCompanies.length > 0 && EH.List.Separator].filter(Boolean),
        {
          icon: <IconPlus />,
          name: 'Create new team',
          onClick: createCompanyHandler,
        },
      ]}
      onClick={({ onClick }) => onClick()}
      minWidth="262px"
    />
  );
};

export default modalManagerConnect(TeamSettingsMenu);
