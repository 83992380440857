import { toDash } from '@float/libs/utils/strings';
import {
  Milestone,
  Phase,
  Project,
  ProjectOffsets,
  RawProject,
  RawTask,
} from '@float/types';

import { AsyncProjectApiPayload, ProjectApiPayload } from '../actions/projects';
import request from '../lib/request';
import { makeRequest, MakeRequestParams } from './makeRequest';

const RESOURCE = 'projects';

export const projects = {
  /**
   * Retrieve a project
   * @param id: project id
   */
  getProject: async (
    params: MakeRequestParams<undefined, Project['project_id']>,
  ): Promise<RawProject> =>
    makeRequest({
      ...params,
      resource: toDash(RESOURCE),
      method: 'GET',
    }),

  /**
   * Retrieve projects
   */
  getProjects: async (
    params: MakeRequestParams<undefined, undefined>,
  ): Promise<RawProject[]> => {
    return makeRequest({
      ...params,
      resource: toDash(RESOURCE),
      method: 'GET',
    });
  },

  /**
   * Retrieves all projects
   * @param archived boolean set to true to get only archived events
   */
  getAllProjects: ({ archived = false }: { archived?: boolean } = {}): Promise<
    RawProject[]
  > =>
    request.get(
      `projects/all?expand=people_ids&lean=1${archived ? '&archived=1' : ''}`,
      null,
      {
        version: 'f3',
      },
    ),

  /**
   * Creates new project
   * @param data: new project attributes
   */
  createProject: async (
    params: MakeRequestParams<ProjectApiPayload>,
  ): Promise<RawProject> =>
    makeRequest({
      ...params,
      resource: toDash(RESOURCE),
      method: 'POST',
    }),

  createProjectFromTemplate: async (
    params: MakeRequestParams<AsyncProjectApiPayload>,
  ) => request.post('projects/from-template', params.data, { version: 'f3' }),

  /**
   * Updates project
   * @param id: id of project to update
   * @param data: new project attributes
   */
  updateProject: async (
    params: MakeRequestParams<
      Partial<ProjectApiPayload>,
      Project['project_id']
    >,
  ): Promise<RawProject> =>
    makeRequest({
      ...params,
      resource: toDash(RESOURCE),
      method: 'PATCH',
    }),

  /**
   * Deletes project
   * @param id: id of project to delete
   */
  deleteProject: async (
    params: MakeRequestParams<undefined, Project['project_id']>,
  ): Promise<void> =>
    makeRequest({
      ...params,
      resource: toDash(RESOURCE),
      method: 'DELETE',
    }),

  /**
   * Clones the project using the bulk-clone endpoint
   * @param id: project id
   * @returns Map containing the new project
   */
  cloneProject: (
    params: MakeRequestParams<undefined, Project['project_id']>,
  ): Promise<{ result: Record<Project['project_id'], RawProject[]> }> =>
    request.post(
      'projects/bulk-clone',
      { project_ids: [params.id], count: 1 },
      { version: 'f3' },
    ),

  /**
   * Shifts the timeline of the project. Tasks and other items
   * that are already completed are not affected
   * @param data.start_date new start date
   * @param data.end_date new end date
   * @param id: project id
   * @returns data that have been affected (milestone, phases, tasks that are copied over)
   */
  shiftProject: ({
    id,
    data: { start_date, end_date },
  }: MakeRequestParams<
    { start_date: string | null; end_date: string | null },
    Project['project_id']
  >): Promise<{ milestone: Milestone[]; phase: Phase[]; task: RawTask[] }> => {
    const dates: {
      start_date?: string;
      end_date?: string;
    } = {};
    if (start_date) {
      dates.start_date = start_date;
    }
    if (end_date) {
      dates.end_date = end_date;
    }
    return request.post(`projects/${id}/shift`, dates, { version: 'f3' });
  },

  /**
   *
   * @param data.start_date start date of the new duplicated project
   * @param data.end_date end date of the new duplicated project
   * @param data.attributes data to overwrite the old project settings when creating the new one
   * @param id project_id of the project to copy
   * @return string id for the duplication job
   */
  duplicateProject: ({
    id,
    data,
  }: MakeRequestParams<
    {
      start_date: string | null;
      end_date: string | null;
      attributes: Omit<ProjectApiPayload, 'all_pms_schedule' | 'project_team'>;
    },
    Project['project_id']
  >): Promise<string> => {
    return request.post(`projects/duplicate/${id}`, data, {
      version: 'f3',
    });
  },

  /**
   * Retrieves offsets and durations based on all date based entities in the project
   * @param projectId id of the project to use as reference for generating offsets
   */
  getOffsets: async (
    projectId: Project['project_id'],
  ): Promise<ProjectOffsets> => {
    return request.get(`projects/${projectId}/template-data`, null, {
      version: 'f3',
    });
  },
};
