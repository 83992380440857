import React from 'react';
import { t, Trans } from '@lingui/macro';
import { ConfirmConfig } from 'manage/people-v2/People';

import { CompanyPreferences } from '@float/types/companyPreferences';
import { Person } from '@float/types/person';

import { canActivatePerson } from './helpers/canActivatePerson';

export async function toggleArchiveStatus({
  companyPrefs,
  confirm,
  newPerson,
  onLimitReached,
  personNameElement,
  triggerSave,
}: {
  companyPrefs: CompanyPreferences;
  confirm: (confirmConfig: ConfirmConfig) => void;
  newPerson: Person;
  onLimitReached: () => void;
  personNameElement?: { focusInput: () => void };
  triggerSave: (person: Person) => Promise<void>;
}) {
  const isActive = newPerson.active;

  if (!isActive && !canActivatePerson(newPerson, companyPrefs)) {
    onLimitReached();
    return;
  }

  const newPersonName = newPerson.name;
  const value = isActive ? 0 : 1;
  const title = isActive ? t`Archive ${newPersonName}?` : t`Move to active`;
  const message = isActive ? (
    <>
      <p>
        <Trans>What this means:</Trans>
      </p>
      <ul>
        <li>
          <Trans>All historical data will be retained in Float</Trans>
        </li>
        <li>
          <Trans>
            Any access is removed, so they will no longer be able to sign in
          </Trans>
        </li>
      </ul>
      <p>
        <Trans>Anyone you archive can be restored from the People page.</Trans>
      </p>
    </>
  ) : (
    <p>
      <Trans>
        <span>Move </span>
        <strong>{newPersonName}</strong>
        <span> to active?</span>
      </Trans>
    </p>
  );
  const confirmLabel = isActive ? t`Archive ${newPersonName}` : title;
  const confirmButtonAppearance = isActive ? 'danger' : undefined;

  confirm({
    title,
    message,
    confirmLabel,
    confirmButtonAppearance,
    onCancel: personNameElement?.focusInput,
    onConfirm: () => triggerSave({ ...newPerson, active: value }),
  });
}
