import styled from 'styled-components';

import VirtualSelect from '@float/ui/deprecated/VirtualSelect/VirtualSelect';

// @ts-expect-error - VirtualSelect has not been converted to TS
const { RowLabel } = VirtualSelect._styles;

export const PercentageSelectWrapper = styled.div`
  font-size: 16px;
  width: 225px;

  ${RowLabel} {
    padding: 0 15px;
    &:hover {
      background-color: ${(p) => p.theme.superLightGrey};
    }
  }
`;

export const PercentageSelectContainer = styled.div`
  display: flex;
  align-items: center;
  outline: none;
`;

export const IconWrapper = styled.div`
  border: 1px solid ${(p) => p.theme.midGrey};
  border-radius: 4px;
  margin-left: 8px;
  height: 20px;
  width: 20px;
  display: flex;

  svg.icon-chevron-down-sm {
    min-width: 18px;
    transform: rotate(0);
  }
`;
