import React, { Component } from 'react';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from '@floatschedule/react-virtualized';

import IconNoResults from '../icons/icon-no-results';
import PmSidebarGroup from './PmSidebarGroup';
import PmSidebarItem from './PmSidebarItem';
import * as styled from './styles';

const wrapperStyle = { overflow: 'hidden', height: '100%' };

const createCellMeasurerCache = () =>
  new CellMeasurerCache({
    defaultHeight: 63,
    fixedWidth: true,
  });

function NoResults({ searchTerm, error, title = 'items' }) {
  let innerContent;
  if (searchTerm) {
    innerContent = (
      <>
        <div>Sorry, there are no results{searchTerm ? ' for' : ''}</div>
        {searchTerm && <div className="search-term">‘{searchTerm}’</div>}
      </>
    );
  } else if (error) {
    innerContent = (
      <div>
        Sorry, an error occurred
        <br />
        while fetching data
      </div>
    );
  } else {
    innerContent = <div>There are no {title}</div>;
  }

  return (
    <styled.NoResults>
      <IconNoResults />
      {innerContent}
    </styled.NoResults>
  );
}

export default class extends Component {
  state = {
    cache: createCellMeasurerCache(),
  };

  componentDidMount() {
    this.updateCache();
  }

  componentDidUpdate(prevProps) {
    if (this.props.items !== prevProps.items) {
      this.updateCache();
    }
  }

  updateCache = ({ immediate = false } = {}) => {
    if (immediate) {
      this.setState({ cache: createCellMeasurerCache() });
      return;
    }

    setTimeout(() => {
      this.setState({ cache: createCellMeasurerCache() });
    }, 100);
  };

  rowRenderer = ({ key, index, style, parent }) => {
    const { items, loading, onGroupClick } = this.props;
    const item = items[index];
    const isNewGroup =
      index === 0 || item.projectId !== items[index - 1].projectId;

    return (
      <CellMeasurer
        cache={this.state.cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        <div style={style}>
          {isNewGroup && (
            <PmSidebarGroup
              item={item}
              isFirstGroup={index === 0}
              onClick={onGroupClick}
            />
          )}
          <PmSidebarItem key={item.extResourceId} item={item} />
          {loading && index === items.length - 1 && <styled.Loader />}
        </div>
      </CellMeasurer>
    );
  };

  render() {
    const { items, title, loading, searchTerm, error } = this.props;
    const noItems = !items || !items.length;
    const firstLoadInProgress = loading && noItems;
    const reultsEmpty = !loading && noItems;

    return (
      <styled.List className="pm-sidebar-items">
        <div style={wrapperStyle}>
          {firstLoadInProgress && (
            <styled.Loader
              className={firstLoadInProgress ? 'first-load' : undefined}
            />
          )}
          {reultsEmpty && (
            <NoResults title={title} searchTerm={searchTerm} error={error} />
          )}
          <AutoSizer>
            {({ width, height }) => (
              <List
                ref={(el) => {
                  this.list = el;
                }}
                rowCount={items.length}
                deferredMeasurementCache={this.state.cache}
                rowHeight={this.state.cache.rowHeight}
                rowRenderer={this.rowRenderer}
                width={width}
                height={height}
              />
            )}
          </AutoSizer>
        </div>
      </styled.List>
    );
  }
}
