import React from 'react';
import styled, { css } from 'styled-components';

import ReportsNoResults from '@float/web/assets/images/reports-no-results.png';

import ProjectDetailsNoResults from './ProjectDetailsNoResults';

export const Container = styled.div`
  margin: 112px auto 56px;
  text-align: center;
  pointer-events: none;

  h1 {
    color: ${(p) => p.theme.charcoalGrey};
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -0.3px;
    margin-top: 22px;
  }

  h2 {
    color: ${(p) => p.theme.charcoalGrey};
    font-size: 16px;
    font-weight: normal;
    margin-top: 5px;
  }

  button {
    margin-top: 25px;
    pointer-events: all;
  }

  ${(p) =>
    p.useGridStyles &&
    css`
      position: absolute;
      top: 56px;
      left: 0;
      width: 100%;
      z-index: 2;
      box-sizing: border-box;

      @media (max-width: 640px) {
        top: 112px;
      }
    `}
`;

const Image = ({ isProjectDetails }) =>
  isProjectDetails ? (
    <ProjectDetailsNoResults />
  ) : (
    <img src={ReportsNoResults} />
  );

export const NoResults = ({ isProjectDetails }) => {
  return (
    <Container>
      <Image isProjectDetails={isProjectDetails} />
      {isProjectDetails ? (
        <h1>Search for a project to see how it's tracking</h1>
      ) : (
        <>
          <h1>No results found</h1>
          <h2>Please try another search</h2>
        </>
      )}
    </Container>
  );
};

export const BadFilters = ({ isProjectDetails }) => (
  <Container>
    <Image isProjectDetails={isProjectDetails} />
    <h1>No results found</h1>
    <h2>Please adjust your search to include only one project</h2>
  </Container>
);

export const NoData = ({
  isProjectDetails,
  title = 'No results found',
  subtitle = 'The selected project has no activity',
}) => (
  <Container>
    <Image isProjectDetails={isProjectDetails} />
    <h1>{title}</h1>
    <h2>{subtitle}</h2>
  </Container>
);
