export const PROJECTS_UPDATED = 'projects/UPDATED';
export const PROJECTS_DELETED = 'projects/DELETED';
export const PROJECTS_LOAD_START = 'projects/LOAD_START';
export const PROJECTS_LOAD_FAILED = 'projects/LOAD_FAILED';
export const PROJECTS_LOAD_FINISH = 'projects/LOAD_FINISH';
export const PROJECT_LOADED = 'project/LOADED';
export const PROJECTS_BULK_UPDATED = 'projects/BULK_UPDATED';
export const PROJECTS_BULK_DELETED = 'projects/BULK_DELETED';
export const PROJECTS_BULK_CLONED = 'projects/BULK_CLONED';
export const PROJECTS_IMPORTED = 'projects/IMPORTED';
export const PROJECTS_DATES_UPDATED = 'projects/DATES_UPDATED';
export const PROJECTS_LOAD_SYNC_ICONS = 'projects/LOAD_SYNC_ICONS';
export const PROJECTS_SET_SYNC_ICONS = 'projects/SET_SYNC_ICONS';
export const PROJECTS_LOAD_SYNC_ICON_FAILED = 'projects/LOAD_FAILED_SYNC_ICONS';
export const PROJECT_UPDATE_FAILED = 'project/UPDATE_FAILED';

// templates
export const TEMPLATES_LOAD_START = 'templates/LOAD_START';
export const TEMPLATES_LOAD_FAILED = 'templates/LOAD_FAILED';
export const TEMPLATES_LOAD_FINISH = 'templates/LOAD_FINISH';
export const TEMPLATES_UPDATED = 'templates/UPDATED';
export const TEMPLATES_DELETED = 'templates/DELETED';
export const TEMPLATE_LOADED = 'templates/LOADED';
