import React, { useRef, useState } from 'react';
import classNames from 'classnames';

import { StatusIcon } from '@float/common/components/StatusIcon/StatusIcon';
import { userCanViewOthers } from '@float/common/lib/rights';
import { CellItem, CurrentUser } from '@float/types';
import { TextTooltip } from '@float/ui/deprecated';

import { ScheduleActions } from '../types';

import * as styles from './StatusItem.css';

function getTooltipContent(item: CellItem<'status'>) {
  const { entity } = item;
  return (
    <div className={styles.bottomHoverTooltip}>
      <header className={styles.bottomHoverTooltipHeader}>Status</header>
      <section className={styles.bottomHoverTooltipSection}>
        <StatusIcon
          className={styles.bottomHoverTooltipStatusIcon}
          color={entity.color}
          size={10}
        />
        {entity.status_name || entity.status_type_name}
      </section>
    </div>
  );
}

type Props = {
  className?: string;
  actions: ScheduleActions;
  dayWidth: number;
  item: CellItem<'status'>;
};

const StatusItem = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { item, dayWidth, actions, ...rest } = props;
  const { x = 0, w, entity } = item;
  const didMouseDown = useRef(false);

  const width = w * dayWidth - 1;
  const left = x * dayWidth + width - 20;

  return (
    <div
      {...rest}
      className={classNames(rest.className, styles.baseLayer)}
      ref={ref}
      onMouseDown={(event) => {
        event.stopPropagation();
        if (event.button !== 0) return;
        didMouseDown.current = true;
      }}
      onMouseUp={() => {
        if (!didMouseDown.current) return;
        didMouseDown.current = false;
        actions.onItemClick(item);
      }}
      style={{ left }}
    >
      <StatusIcon className={styles.statusIcon} color={entity.color} size={6} />
    </div>
  );
});

function StatusItemWithTooltip(
  props: Props & {
    user: CurrentUser;
    boundaryRef: React.MutableRefObject<Element | Element[] | undefined>;
  },
) {
  const { actions, item, user, boundaryRef } = props;

  const [open, setOpen] = useState(false);

  return (
    <TextTooltip
      content={getTooltipContent(item)}
      placement={userCanViewOthers(user) ? 'bottom' : 'top'}
      delay={250}
      distance={5}
      collisionBoundary={boundaryRef?.current}
      open={open}
      onOpenChange={(open) => {
        if (open) {
          if (actions.isItemTooltipEnabled(item)) {
            setOpen(true);
          }
        } else {
          setOpen(false);
        }
      }}
    >
      {React.createElement(StatusItem, props)}
    </TextTooltip>
  );
}

export default StatusItemWithTooltip;
