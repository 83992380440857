import React, { MouseEventHandler, StrictMode } from 'react';
import { t } from '@lingui/macro';

import { preventDefaultAndStopPropagation } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { Phase } from '@float/types/phase';
import { Project } from '@float/types/project';
import { IconChevronDown } from '@float/ui/icons/essentials/IconChevronDown';

import * as styles from './styles.css';

export const PhaseExpand = (props: {
  project: Project;
  phases: Phase[] | undefined;
  isOpen: boolean;
  onExpand: (projectId: Project['project_id']) => void;
}) => {
  if (!props.phases?.length) return;
  const {
    isOpen,
    project: { project_name: projectName },
  } = props;
  const onClickHandler: MouseEventHandler = (event) => {
    if (!props.project.active) return;
    preventDefaultAndStopPropagation(event);
    props.onExpand(props.project.project_id);
  };
  const state = isOpen ? 'Hide' : 'Show';
  const title = t`${state} phases for ${projectName}`;
  return (
    <StrictMode>
      <div
        role="button"
        title={title}
        className={styles.phaseExpandDropdown({
          color: props.project.active ? 'default' : 'disabled',
        })}
        onClick={onClickHandler}
      >
        {props.phases.length}
        <IconChevronDown
          className={styles.phaseExpandIcon({
            stroke: props.project.active ? 'default' : 'disabled',
            transform: props.isOpen ? 'isOpen' : 'default',
          })}
        />
      </div>
    </StrictMode>
  );
};
