import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M19.5 11.525h-2.9l-2.175 6.525L10.075 5 7.9 11.525H5"
    />
  </BaseIcon>
);
