import { useCallback } from 'react';
import useLocalStorage from 'react-use/esm/useLocalStorage';

import { trackEvent } from '@float/common/lib/gtm';
import { FloatStorageKey } from '@float/libs/storage';

export type TimeRangeSettings = {
  insightsPreferredUnit: 'hours' | 'percentage';
};

export const TIME_RANGE_SETTINGS_DEFAULT_VALUE: TimeRangeSettings = {
  insightsPreferredUnit: 'hours',
};

export const useTimeRangeSettings = () => {
  const [timeRangeSettingsCached, setTimeRangeSettingsCached] = useLocalStorage(
    FloatStorageKey.TimeRangeSettingsCache,
    TIME_RANGE_SETTINGS_DEFAULT_VALUE,
  );

  const timeRangeSettings =
    timeRangeSettingsCached || TIME_RANGE_SETTINGS_DEFAULT_VALUE;

  const setTimeRangeSetting = useCallback(
    (
      key: keyof TimeRangeSettings,
      value: TimeRangeSettings[keyof TimeRangeSettings],
    ) => {
      trackEvent('Toggle date range insights metric', {
        value,
      });

      setTimeRangeSettingsCached({
        ...timeRangeSettings,
        [key]: value,
      });
    },
    [timeRangeSettings, setTimeRangeSettingsCached],
  );

  return {
    timeRangeSettings,
    setTimeRangeSetting,
  };
};
