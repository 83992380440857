import React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { RecipeVariants } from '@vanilla-extract/recipes';
import {
  AnimatePresence,
  AnimatePresenceProps,
  motion,
  Variants,
} from 'framer-motion';

import { modalMotionConfig, overlayMotionConfig } from './ModalRoot.motion';

import * as styles from './styles.css';

export type ModalVariants = NonNullable<
  RecipeVariants<typeof styles.overlayWrapper>
> &
  NonNullable<RecipeVariants<typeof styles.content>>;
export type ModalAlignVariants = ModalVariants['align'];
export type ModalAppearanceVariants = ModalVariants['appearance'];
export type ModalProps = DialogPrimitive.DialogProps & {
  align?: ModalAlignVariants;
  animateBackground?: boolean;
  appearance?: ModalAppearanceVariants;
  closeOnClickOutside?: boolean;
  closeOnEsc?: boolean;
  height?: number;
  onExitComplete?: AnimatePresenceProps['onExitComplete'];
  onOpenAutoFocus?: ModalContentProps['onOpenAutoFocus'];
  width?: number;
};
export type ModalContentProps = DialogPrimitive.DialogContentProps;

export const ModalRoot = (props: ModalProps) => {
  const {
    align,
    animateBackground = true,
    appearance,
    children,
    closeOnClickOutside = true,
    closeOnEsc = true,
    height,
    onOpenAutoFocus,
    width,
    ...dialogProps
  } = props;

  const open =
    dialogProps.open ||
    (dialogProps.open === undefined && dialogProps.defaultOpen);

  const onEscapeKeyDownHandler = (e: KeyboardEvent) => {
    if (!closeOnEsc) e.preventDefault();
  };

  const onInteractOutsideHandler: ModalContentProps['onInteractOutside'] = (
    e,
  ) => {
    if (!closeOnClickOutside) e.preventDefault();
  };

  return (
    <DialogPrimitive.Root {...dialogProps}>
      <AnimatePresence
        onExitComplete={dialogProps.onExitComplete}
        custom={{ animateBackground }}
      >
        {open && (
          <DialogPrimitive.Portal forceMount>
            <DialogPrimitive.Overlay
              className={styles.overlayWrapper({ align })}
            >
              <motion.div
                key="overlay"
                className={styles.overlay}
                variants={overlayMotionConfig as Variants}
                initial={'initial'}
                animate={'animate'}
                exit={'exit'}
                custom={{ animateBackground }}
              />

              <DialogPrimitive.Content
                onEscapeKeyDown={onEscapeKeyDownHandler}
                onInteractOutside={onInteractOutsideHandler}
                onOpenAutoFocus={onOpenAutoFocus}
                asChild
              >
                <div className={styles.wrapper}>
                  <motion.div
                    key="content"
                    className={styles.content({ appearance })}
                    style={assignInlineVars(
                      width && height
                        ? {
                            [styles.modalWidth]: `${width}px`,
                            [styles.modalHeight]: `${height}px`,
                          }
                        : {},
                    )}
                    {...modalMotionConfig}
                  >
                    {children}
                  </motion.div>
                </div>
              </DialogPrimitive.Content>
            </DialogPrimitive.Overlay>
          </DialogPrimitive.Portal>
        )}
      </AnimatePresence>
    </DialogPrimitive.Root>
  );
};
