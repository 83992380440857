import React from 'react';
import styled from 'styled-components';

import { useFixAtValueWhenReached } from '@float/common/lib/hooks/useFixAtValueWhenReached';
import { Col } from '@float/ui/deprecated';
import { TextButton } from '@float/ui/deprecated/Earhart/Buttons';

import { getWorkingDays } from './datesAndRepeat';
import {
  blurHoursPd,
  getHoursPerDayElem,
  getTotalHoursElem,
} from './hoursElems';
import { getEndTimeElem, getStartTimeElem } from './timePickers';

const TotalHoursOrSpecificTime = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  width: 210px;
`;

export const StyledTextButton = styled(TextButton)`
  margin-top: 8px;
`;

let etmThis;

const getTaskHours = () => {
  const { task: { hours_pd, hours } = {} } = etmThis.state;
  return +hours_pd || +hours;
};

const setSpecificTime = (e) => {
  e.preventDefault();

  etmThis.setState(
    {
      editingTimeOfDay: true,
      hoursPd: 1,
      startTime: '10:00',
      endTime: '11:00',
      isFullDay: false,
    },
    () => {
      etmThis.startTimeRef.focusInput();
    },
  );
};

export const setTotalHours = (e) => {
  if (e) e.preventDefault();

  etmThis.setState(
    {
      editingTimeOfDay: false,
      hoursPd: getTaskHours(etmThis.state.task),
      isFullDay: true,
      startTime: '',
      endTime: '',
    },
    () => {
      blurHoursPd();
      etmThis.hoursPerDayRef?.focusInput();
    },
  );
};

const TotalHours = ({ readOnly }) => {
  const workingDays = getWorkingDays();
  const isMultiDay = workingDays > 1;
  const showTotalHours = useFixAtValueWhenReached(true, isMultiDay);

  return (
    <>
      <Col alignItems="flex-start">
        {getHoursPerDayElem(etmThis)}
        {!readOnly && (
          <StyledTextButton appearance="flue-fill" onClick={setSpecificTime}>
            Specific time
          </StyledTextButton>
        )}
      </Col>
      {showTotalHours && getTotalHoursElem(etmThis)}
    </>
  );
};

const SpecificTime = ({ readOnly, companyPrefs }) => (
  <>
    {getStartTimeElem(etmThis, companyPrefs)}
    {getEndTimeElem(etmThis, companyPrefs)}
    {!readOnly && (
      <StyledTextButton appearance="flue-fill" onClick={setTotalHours}>
        Total hours
      </StyledTextButton>
    )}
  </>
);

const getTotalHoursOrSpecificTime = (self, companyPrefs) => {
  etmThis = self;
  const readOnly = etmThis.isReadOnly() || etmThis.state.integrationSyncLocked;

  return (
    <TotalHoursOrSpecificTime>
      {etmThis.state.editingTimeOfDay ? (
        <SpecificTime readOnly={readOnly} companyPrefs={companyPrefs} />
      ) : (
        <TotalHours readOnly={readOnly} />
      )}
    </TotalHoursOrSpecificTime>
  );
};

export default getTotalHoursOrSpecificTime;
