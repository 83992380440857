import React from 'react';

import { withComponents } from '../../lib/monorepo-helpers';

const HideableListWrapper = ({
  hideableItems,
  onActionClick,
  components: {
    HideableList,
    HideableListItem,
    ActivityItemTitle,
    ActivityItemHistoryLine,
  },
}) => {
  if (!hideableItems || !hideableItems.length) {
    return null;
  }

  return (
    <HideableList role="list">
      {hideableItems
        .map((dataLayer, i) => {
          if (!dataLayer.length) return null;
          const item = dataLayer[0];
          return (
            <HideableListItem
              key={`historyLineItem_${item.sharedId}_${i}`}
              role="list-item"
            >
              {item.shouldRenderItemTitle ? (
                <ActivityItemTitle
                  {...item.baseTitleArgs}
                  onClick={() => onActionClick(item)}
                />
              ) : (
                <ActivityItemHistoryLine dataLayer={dataLayer} />
              )}
            </HideableListItem>
          );
        })
        .filter(Boolean)}
    </HideableList>
  );
};

export default withComponents(HideableListWrapper);
