import {
  PhaseEditData,
  ProjectEditData,
  TemplateData,
} from '../components/panelViews/Project/types';
import {
  addPanel,
  commitPanelTentativeOverride,
  hideCurrentPanel,
  hideCurrentPanelWithWarning,
  hideSidePanel,
  removePanelTentativeOverride,
  showSidePanel,
  updatePreventPanelOverride,
} from './actions';

export type ShowSidePanelPayload = {
  panelType: PanelType;
  panelPayload?: PanelPayload;
};

export enum PanelType {
  ProjectPanel = 'projectPanel',
  TemplatePanel = 'templatePanel',
  PhasePanel = 'phasePanel',
}

export type PanelPayload = ProjectEditData | TemplateData | PhaseEditData;

export type SidePanelAction =
  | ReturnType<typeof showSidePanel>
  | ReturnType<typeof hideSidePanel>
  | ReturnType<typeof updatePreventPanelOverride>
  | ReturnType<typeof removePanelTentativeOverride>
  | ReturnType<typeof commitPanelTentativeOverride>
  | ReturnType<typeof addPanel>
  | ReturnType<typeof hideCurrentPanel>
  | ReturnType<typeof hideCurrentPanelWithWarning>;

export type SidePanelStatus = {
  preventOverride?: boolean;
  tentativeOverride?:
    | ShowSidePanelPayload
    | {
        isTentativeClose: true;
      };
};

export type SidePanelState = {
  // sometimes the side panel is hidden from view but not unmounted so that it can be retrieved
  // This boolean determines whether it's hidden from view
  open: boolean;
  openPanels: {
    panelType: PanelType;
    panelPayload?: PanelPayload;
    panelStatus: SidePanelStatus;
  }[];
};
