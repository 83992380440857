import React, { ComponentPropsWithoutRef } from 'react';
import { t } from '@lingui/macro';
import cn from 'classnames';
import { motion } from 'framer-motion';

import * as styles from './styles.css';

export type ButtonPlayProps = ComponentPropsWithoutRef<'button'>;

export const ButtonPlay = (props: ButtonPlayProps) => {
  const { className, onClick } = props;

  return (
    <button
      type="button"
      className={cn(styles.buttonPlay, className)}
      onClick={onClick}
      data-testid="play-button"
      aria-label={t`Play video`}
    >
      <motion.div
        className={styles.backdrop}
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.25 }}
      />

      <div className={styles.icon}>
        <motion.div
          className={styles.circle}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
        />

        <motion.svg
          fill="#000"
          viewBox="0 0 24 32"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.svg}
          initial={{ scale: 0 }}
          animate={{ scale: 1, transition: { delay: 0.15 } }}
        >
          <path d="M0.25 29.1692V2.83101C0.25 1.00674 2.3035 -0.062427 3.798 0.983721L22.611 14.1528C23.8934 15.0505 23.8934 16.9497 22.611 17.8474L3.798 31.0165C2.3035 32.0627 0.25 30.9935 0.25 29.1692Z" />
        </motion.svg>
      </div>
    </button>
  );
};
