import {
  ADD_JIRA_CO_INT,
  ADD_JIRA_CO_INT_FAILURE,
  ADD_JIRA_CO_INT_SUCCESS,
  CLEAR_JIRA_PRELOAD,
  FETCH_JIRA_CO_INTS,
  FETCH_JIRA_CO_INTS_FAILURE,
  FETCH_JIRA_CO_INTS_SUCCESS,
  FETCH_JIRA_CONFIG,
  FETCH_JIRA_CONFIG_FAILURE,
  FETCH_JIRA_CONFIG_SUCCESS,
  FETCH_JIRA_PRELOAD,
  FETCH_JIRA_PRELOAD_FAILURE,
  FETCH_JIRA_PRELOAD_SUCCESS,
  JIRA_IMPORT_SUCCESS,
  LINK_JIRA,
  LINK_JIRA_FAILURE,
  LINK_JIRA_SUCCESS,
  REMOVE_JIRA_CO_INT,
  REMOVE_JIRA_CO_INT_FAILURE,
  REMOVE_JIRA_CO_INT_SUCCESS,
  UPDATE_JIRA_CONFIG,
  UPDATE_JIRA_CONFIG_FAILURE,
  UPDATE_JIRA_CONFIG_SUCCESS,
} from '../actions/jira';

const DEFAULT_STATE = {
  oauthLink: null,
  coInts: [],
  preloadData: {},
};

export default function teamwork(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    /* FETCH COINT LIST */

    case FETCH_JIRA_CO_INTS: {
      return {
        ...state,
        coIntsLoadState: 'LOADING',
      };
    }

    case FETCH_JIRA_CO_INTS_SUCCESS: {
      return {
        ...state,
        coIntsLoadState: 'LOAD_SUCCESS',
        coInts: action.coInts,
      };
    }

    case FETCH_JIRA_CO_INTS_FAILURE: {
      return {
        ...state,
        coIntsLoadState: 'LOAD_FAILED',
      };
    }

    /* JIRA IMPORT BEHAVIORS */
    case JIRA_IMPORT_SUCCESS: {
      return {
        ...state,
        coIntsLoadState: 'LOAD_SUCCESS',
        coInts: action.coInts,
        showImport: action.initialImport,
        initialImportStarted: false,
      };
    }

    /* FETCH PRELOAD DATA */

    case FETCH_JIRA_PRELOAD: {
      return {
        ...state,
        preloadLoadState: 'LOADING',
      };
    }

    case FETCH_JIRA_PRELOAD_SUCCESS: {
      return {
        ...state,
        preloadLoadState: 'LOAD_SUCCESS',
        preloadData: action.preloadData,
      };
    }

    case CLEAR_JIRA_PRELOAD: {
      return {
        ...state,
        preloadLoadState: null,
        preloadData: null,
      };
    }

    case FETCH_JIRA_PRELOAD_FAILURE: {
      return {
        ...state,
        preloadLoadState: 'LOAD_FAILED',
      };
    }

    /* LINK JIRA ACCOUNT */

    case LINK_JIRA: {
      return {
        ...state,
        linkJiraLoadState: 'LOADING',
      };
    }

    case LINK_JIRA_SUCCESS: {
      return {
        ...state,
        linkJiraLoadState: 'LOAD_SUCCESS',
      };
    }

    case LINK_JIRA_FAILURE: {
      return {
        ...state,
        linkJiraLoadState: 'LOAD_FAILED',
      };
    }

    /* FETCH JIRA CONFIG */

    case FETCH_JIRA_CONFIG: {
      return {
        ...state,
        fetchConfigLoadState: 'LOADING',
      };
    }

    case FETCH_JIRA_CONFIG_SUCCESS: {
      return {
        ...state,
        fetchConfigLoadState: 'LOAD_SUCCESS',
        jiraConfig: action.jiraConfig,
      };
    }

    case FETCH_JIRA_CONFIG_FAILURE: {
      return {
        ...state,
        fetchConfigLoadState: 'LOAD_FAILED',
      };
    }

    /* UPDATE JIRA CONFIG */

    case UPDATE_JIRA_CONFIG: {
      return {
        ...state,
        updateConfigLoadState: 'LOADING',
        initialImportStarted: action.startInitialImport,
      };
    }

    case UPDATE_JIRA_CONFIG_SUCCESS: {
      return {
        ...state,
        updateConfigLoadState: 'LOAD_SUCCESS',
        jiraConfig: action.jiraConfig,
      };
    }

    case UPDATE_JIRA_CONFIG_FAILURE: {
      return {
        ...state,
        updateConfigLoadState: 'LOAD_FAILED',
      };
    }

    /* ADD JIRA COINT */

    case ADD_JIRA_CO_INT: {
      return {
        ...state,
        addCoIntLoadState: 'LOADING',
      };
    }

    case ADD_JIRA_CO_INT_SUCCESS: {
      return {
        ...state,
        addCoIntLoadState: 'LOAD_SUCCESS',
      };
    }

    case ADD_JIRA_CO_INT_FAILURE: {
      return {
        ...state,
        addCoIntLoadState: 'LOAD_FAILED',
      };
    }

    case REMOVE_JIRA_CO_INT: {
      return {
        ...state,
        removeCoIntLoadState: 'LOADING',
      };
    }

    case REMOVE_JIRA_CO_INT_SUCCESS: {
      return {
        ...state,
        removeCoIntLoadState: 'LOAD_SUCCESS',
      };
    }

    case REMOVE_JIRA_CO_INT_FAILURE: {
      return {
        ...state,
        removeCoIntLoadState: 'LOAD_FAILED',
      };
    }

    default: {
      return state;
    }
  }
}
