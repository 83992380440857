export type TimeFormatValue = 'decimal' | 'time';

export const TIME_FORMAT: Record<TimeFormatValue, TimeFormatValue> = {
  decimal: 'decimal',
  time: 'time',
} as const;

export const TIME_FORMAT_MAP: Record<number, TimeFormatValue> = {
  0: 'decimal',
  1: 'time',
} as const;
