import React from 'react';

import { TextTooltip, TextTooltipProps } from './TextTooltip';

export type BottomHoverTooltipProps = Omit<TextTooltipProps, 'content'> & {
  title?: string;
};

const BottomHoverTooltip: React.FC<BottomHoverTooltipProps> = ({
  title,
  children,
  ...rest
}: BottomHoverTooltipProps) => {
  if (!title) {
    return <>{children}</>;
  }

  return (
    <TextTooltip className="hover" content={title} placement="bottom" {...rest}>
      {children}
    </TextTooltip>
  );
};

export default BottomHoverTooltip;
