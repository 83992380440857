import { t } from '@lingui/macro';

export type ValidateTaskNameParams = {
  project?: { locked_task_list: number };
  taskName: string;
  taskMetas: { task_name: string }[];
};

export function validateTaskName({
  project,
  taskName,
  taskMetas,
}: ValidateTaskNameParams) {
  if (project?.locked_task_list === 1) {
    if (!taskName) {
      const allowEmpty = taskMetas.every((option) => option.task_name === '');

      if (!allowEmpty) {
        return [t`Please select a task`];
      }
    }
  }
}
