import { useFormContext, useWatch } from 'react-hook-form';

import { getProjectById } from '@float/common/selectors/projects/getProjectsMapRaw';
import { useAppSelectorWithParams } from '@float/common/store';

import { useDisabledDates } from '../hooks/useDisabledDates';
import { useUpdateDates } from '../hooks/useUpdateDates';
import { ProjectFormData } from '../types';

export const useProjectDates = () => {
  const { getValues } = useFormContext<ProjectFormData>();
  const startDate = useWatch<ProjectFormData, 'project.start_date'>({
    name: 'project.start_date',
  });
  const endDate = useWatch<ProjectFormData, 'project.end_date'>({
    name: 'project.end_date',
  });

  const id = getValues('projectId');
  const project = useAppSelectorWithParams(getProjectById, id);

  useUpdateDates({
    startKey: 'project.start_date',
    endKey: 'project.end_date',
    watchStartDate: project?.start_date,
    watchEndDate: project?.end_date,
  });

  const disabledDateFunctions = useDisabledDates({
    startDate,
    endDate,
    calculatedStartDate: project?.calculated_start_date,
    calculatedEndDate: project?.calculated_end_date,
  });

  return {
    set_dates: project?.set_dates,
    calculated_start_date: project?.calculated_start_date,
    calculated_end_date: project?.calculated_end_date,
    ...disabledDateFunctions,
  };
};
