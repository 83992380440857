import React from 'react';
import { connect } from 'react-redux';
import { getProjectTagOptions } from 'selectors';

import Tags from 'components/elements/BulkEditModal/Tags';

const ProjectTags = React.forwardRef((props, ref) => {
  const { projects, options, ...rest } = props;
  return (
    <Tags
      ref={ref}
      type="project"
      entities={projects}
      options={options}
      {...rest}
    />
  );
});

const mapStateToProps = (state) => ({
  options: getProjectTagOptions(state),
  projects: state.projects.projects,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  ProjectTags,
);
