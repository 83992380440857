import styled from 'styled-components';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  height: 48px;

  border-radius: 6px;

  padding-left: 14px;
  padding-right: 6px;

  background-color: ${({ index }) =>
    index % 2 === 0 ? Colors.FIN.Lt.Surface.Surf2 : 'transparent'};
`;

export const Space = styled.div`
  display: flex;
  flex: 1 0 auto;
`;

export const Title = styled.label`
  ${Typography.TextM.R400};

  color: ${Colors.FIN.Lt.Emphasis.High};

  margin-left: 8px;
`;
