import React from 'react';
import { Trans } from '@lingui/macro';

import { Rights } from '@float/common/lib/acl';
import { CurrentUser, Project } from '@float/types';
import { Button } from '@float/ui/deprecated/Button/Button';
import {
  DropdownActions,
  pushToRight,
} from '@float/ui/deprecated/DropdownActions';
import { IconArrowDown, IconTrash } from '@float/ui/deprecated/Earhart/Icons';

export type ProjectActionsMenuProps = {
  project: Project;
  currentUser: CurrentUser;
  onDuplicateProject: () => void;
  onCreateTemplate: () => void;
  onActiveChange: (active: boolean) => void;
  onDelete: () => void;
};

export function ProjectActionsMenu(props: ProjectActionsMenuProps) {
  const { currentUser, project } = props;

  const isProjectActive = project.active;

  const canDeleteProject = Rights.canDeleteProject(currentUser, {
    project,
  });

  return (
    <DropdownActions
      side="top"
      align="end"
      className={pushToRight}
      content={
        <>
          <DropdownActions.Item onClick={props.onDuplicateProject}>
            <Trans>Duplicate project</Trans>
          </DropdownActions.Item>
          <DropdownActions.Item onClick={props.onCreateTemplate}>
            <Trans>Create template from project</Trans>
          </DropdownActions.Item>
          <DropdownActions.Item
            onClick={() => props.onActiveChange(!isProjectActive)}
          >
            {isProjectActive ? (
              <Trans>Move to archive</Trans>
            ) : (
              <Trans>Move to active</Trans>
            )}
          </DropdownActions.Item>
          {canDeleteProject && (
            <DropdownActions.Item appearance="danger" onClick={props.onDelete}>
              <Trans>Delete</Trans> <IconTrash />
            </DropdownActions.Item>
          )}
        </>
      }
    >
      <Button appearance="clear" iconRight={IconArrowDown}>
        <Trans>Actions</Trans>
      </Button>
    </DropdownActions>
  );
}
