import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';

import { BudgetPriority, BudgetType } from '@float/types/project';
import * as Accordion from '@float/ui/components/Accordion';
import { formatNumber } from '@float/ui/helpers/number/numberParser';

import { CurrencyInputField } from '../../components/CurrencyInputField';
import { DurationInputField } from '../../components/DurationInputField';
import { FormFieldsSection } from '../../components/FormFieldsSection';
import { AccordionEntry } from '../../hooks/useAccordionState';
import { useProjectACL } from '../../hooks/useProjectACL';
import { ProjectFormData } from '../../types';
import { getPhaseCalculatedBudget } from './PhaseBudgetSection.helperts';
import { BudgetHeader } from './ProjectBudgetSection';

import * as styles from '../../styles.css';

export const PhaseBudgetSection = () => {
  const acl = useProjectACL();

  const { getValues } = useFormContext<ProjectFormData>();

  const isProjectNonBillable = useWatch<
    ProjectFormData,
    'project.non_billable'
  >({
    name: 'project.non_billable',
  });

  const budgetType = getValues('project.budget_type');

  const budgetPriority = getValues('project.budget_priority');

  const tasks = useWatch<ProjectFormData, 'tasks'>({
    name: 'tasks',
    disabled: budgetPriority !== BudgetPriority.Task,
  });

  const isFeeBudget = budgetType === BudgetType.TotalFee;
  const isHoursBudget = budgetType === BudgetType.TotalHours;

  const isBudgetPerPhase = budgetPriority === BudgetPriority.Phase;
  const isBudgetByTask = budgetPriority === BudgetPriority.Task;

  const hasBudgetAccess = (isFeeBudget && acl.canSeeBudget) || isHoursBudget;

  const isCalculatedBudget = isBudgetByTask;

  if (!isBudgetPerPhase && !isBudgetByTask) return null;

  const budgetTotalCalculated = getPhaseCalculatedBudget({
    isProjectNonBillable,
    budgetPriority,
    formTasks: tasks,
  });

  return (
    <Accordion.Item value={AccordionEntry.budget}>
      <Accordion.Header
        className={styles.projectAccordionHeader}
        info={<BudgetHeader name="phase.budget_total" />}
      >
        <Trans>Budget</Trans>
      </Accordion.Header>
      <Accordion.Content>
        <FormFieldsSection>
          {hasBudgetAccess && budgetType === BudgetType.TotalHours && (
            <DurationInputField
              name={'phase.budget_total'}
              label={t`Hours`}
              readOnly={isCalculatedBudget}
              valueOverride={
                isCalculatedBudget ? formatNumber(budgetTotalCalculated) : ''
              }
            />
          )}
          {hasBudgetAccess && budgetType === BudgetType.TotalFee && (
            <CurrencyInputField
              name={'phase.budget_total'}
              label={t`Fee`}
              readOnly={isCalculatedBudget}
              valueOverride={
                isCalculatedBudget ? formatNumber(budgetTotalCalculated) : ''
              }
            />
          )}
        </FormFieldsSection>
      </Accordion.Content>
    </Accordion.Item>
  );
};
