import {
  FETCH_ACCOUNT_TYPES,
  FETCH_ACCOUNT_TYPES_FAILURE,
  FETCH_ACCOUNT_TYPES_SUCCESS,
} from '../actions/accountTypes';
import {
  DEFAULT_SORT_TABLE,
  getInitialSortableTableState,
  getInitialSortPropsState,
  getInitialStateCreator,
  reducerCreator,
} from './reducerHelpers';

export const lastFetchError = (state) => state.lastFetchError;

export const areBeingFetched = (state) => state.pagesBeingFetched.length > 0;

export const isBillingAccountType = (entity) => entity.account_type_id == 5;

export const isAdminAccountType = (entity) => entity.account_type_id == 2;

export const isOwnerAccountType = (entity) => entity.account_type_id == 1;

export const isMemberAccountType = (entity) => entity.account_type_id == 4;

const getInitialState = getInitialStateCreator(
  Object.assign(
    {
      sortableTables: {
        [DEFAULT_SORT_TABLE]: getInitialSortableTableState(
          'account_type_id',
          'asc',
        ),
      },
    },
    getInitialSortPropsState(['account_type_id']),
  ),
);

const accountTypes = reducerCreator(
  {
    FETCH_ENTITIES: FETCH_ACCOUNT_TYPES,
    FETCH_ENTITIES_SUCCESS: FETCH_ACCOUNT_TYPES_SUCCESS,
    FETCH_ENTITIES_FAILURE: FETCH_ACCOUNT_TYPES_FAILURE,
  },
  'account_type_id',
  getInitialState,
);

export default accountTypes;
