import React from 'react';
import { Prompt, useHistory } from 'react-router';

import { useModalControls } from '@float/ui/deprecated/Modal/hooks';

import { ConfirmModal, ConfirmModalProps } from '../modals/ConfirmModal';
import { useNavigationGuardController } from './useNavigationGuardController';

/**
 * Shows a confirmation modal on navigation when the
 * `shouldShowPromptOnNavigation` prop is true
 */
export const NavigationGuard = ({
  shouldShowPromptOnNavigation,
  ...modalProps
}: { shouldShowPromptOnNavigation?: boolean } & Omit<
  ConfirmModalProps,
  'onClose'
>) => {
  const history = useHistory();
  const { isModalVisible, openModal, closeModal } = useModalControls();
  const { handleNavigationConfirm, handleBlockedNavigation } =
    useNavigationGuardController({
      history,
      openModal,
      closeModal,
    });

  return (
    <>
      <Prompt
        when={shouldShowPromptOnNavigation}
        message={handleBlockedNavigation}
      />
      {isModalVisible && (
        <ConfirmModal
          onConfirm={handleNavigationConfirm}
          onClose={closeModal}
          {...modalProps}
        />
      )}
    </>
  );
};
