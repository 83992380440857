import React from 'react';

import { FilterToken as FilterTokenType } from '@float/types';
import { FilterToken, MoreFilters } from 'components/SearchFilters';

import * as styles from './styles.css';

export type SearchFilterTokensProps = {
  filters: FilterTokenType[];
};

const VISIBLE_TOKENS = 3;

export const FiltersField = ({ filters }: SearchFilterTokensProps) => {
  const visibleFilters = filters.slice(0, VISIBLE_TOKENS);

  return (
    <div className={styles.filters}>
      {visibleFilters.map((filter, i) => (
        <FilterToken key={i} filter={filter} index={i} wrapValues disabled />
      ))}
      <MoreFilters filters={filters} visibleCount={VISIBLE_TOKENS} disabled />
    </div>
  );
};
