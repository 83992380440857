import API3 from '@float/common/api3';
import { StartTimerParameters } from '@float/common/api3/timers/types';
import { TimerGlobalState } from '@float/common/reducers/lib/types';
import { AppDispatch } from '@float/common/store';
import { logger } from '@float/libs/logger';
import { Timer } from '@float/types';

import { LOGGED_TIME_CREATED } from '../loggedTimes';
import { TIMER_START } from './constants';
import { stopTimer } from './stopTimer';

export const startTimer = (timer: StartTimerParameters) => {
  return async (dispatch: AppDispatch, getState: () => TimerGlobalState) => {
    try {
      const timers = getState().timer.timers;
      const accountId = getState().currentUser.account_id;

      const activeTimers = timers.filter(
        (timer) => timer.active && timer.external_meta.account_id === accountId,
      );

      try {
        await Promise.all(
          activeTimers.map((timer) => dispatch(stopTimer(timer._id!))),
        );
      } catch (e) {
        logger.error(
          'There was an error while stopping the active timers, as part of starting a new timer',
          e,
        );
      }

      const startedTimer = await API3.startTimer(timer);
      dispatch({
        type: TIMER_START,
        timer: {
          ...timer,
          ...startedTimer.timer,
          _id: startedTimer.timer._id,
          start_timestamp: startedTimer.timer.start_timestamp,
        },
      });
      dispatch({
        type: LOGGED_TIME_CREATED,
        loggedTime: {
          ...startedTimer,
          start_date: startedTimer.date,
          end_date: startedTimer.date,
        },
      });
    } catch (e) {
      logger.error('There was an error while starting a timer', e);
    }
  };
};

export const startTimerFromLiveUpdate = (updatedTimer: Timer) => {
  return (dispatch: AppDispatch, getState: () => TimerGlobalState) => {
    dispatch({
      type: TIMER_START,
      timer: updatedTimer,
    });

    dispatch({
      type: LOGGED_TIME_CREATED,
      loggedTime: {
        ...updatedTimer,
      },
    });
  };
};
