import { debounce } from 'lodash';
import useMount from 'react-use/esm/useMount';

import { UseCellsAction } from '@float/common/serena/Data/useCells/types';
import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import { AppStore, useAppStore } from '@float/common/store';
import { CellHelperData, Timer } from '@float/types';

import { handleActiveTimerChanged } from './handleActiveTimerChanged';
import {
  handleCellsBulkDataLoad,
  LastBulkDataLoad,
} from './handleCellsBulkDataLoad';
import { handleCellsHelpersDataLoad } from './handleCellsHelpersDataLoad';
import { handleSetFetchedRanges } from './handleSetFetchedRanges';

/**
 * Listen to the store changes and dispatch the data to `useCells`
 */
function subscribeCellsToStoreChanges(
  store: AppStore,
  dispatch: (action: UseCellsAction) => void,
) {
  let lastBulkDataLoad: LastBulkDataLoad = {};
  let lastHelperData: Partial<CellHelperData> = {};
  let lastFetchedRanges: number[] = [];
  let lastActiveTimer: Timer | undefined = undefined;

  const onStateChange = () => {
    const state = store.getState();

    // Loads helper data into cells
    // Helper data isn't directly related to the cells generation, but used
    // as a support for some relations based checks
    //
    // We want to make sure that the helper data is loaded first so that
    // it can be used in the subsequent LOAD_DATA calls.
    lastHelperData = handleCellsHelpersDataLoad(
      state,
      dispatch,
      lastHelperData,
    );

    // Loads the fetched ranges data from Redux to the cells state
    lastFetchedRanges = handleSetFetchedRanges(
      state,
      dispatch,
      lastFetchedRanges,
    );

    // Load the data required for the cells generation
    lastBulkDataLoad = handleCellsBulkDataLoad(
      state,
      dispatch,
      lastBulkDataLoad,
    );

    lastActiveTimer = handleActiveTimerChanged(
      state,
      dispatch,
      lastActiveTimer,
    );
  };

  // Applying a debounce to batch the updates happening syncronously
  return store.subscribe(debounce(onStateChange, 0));
}

export function useCellDataLoadEffect() {
  const store = useAppStore();
  const { cellsWrapper } = useScheduleContext();

  useMount(() => {
    return subscribeCellsToStoreChanges(store, cellsWrapper.dispatch);
  });
}
