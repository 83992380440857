import React from 'react';
import styled, { css } from 'styled-components';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Elevations from '@float/ui/deprecated/Earhart/Elevations';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';
import { respondTo } from '@float/ui/deprecated/Theme/helpers/responsive/respondTo';

import CloudsIllustration from './assets/CloudsIllustration.svg';
import KeyholeIllustration from './assets/KeyholeIllustration.svg';

export const StyledContainer = styled.div`
  --layout-safe-margins: 36px;

  position: absolute;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: 100%;

  background-color: ${Colors.FIN.Lt.Emphasis.White};

  color: ${Colors.FIN.Lt.Emphasis.High};

  box-sizing: border-box;

  ${respondTo(
    'small',
    '<=',
  )(css`
    --layout-safe-margins: 20px;
  `)}

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
`;

export const StyledDialog = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - var(--layout-safe-margins) * 2);
  max-width: 464px;
  padding: 32px;
  border-radius: 8px;
  z-index: 1;
  gap: 20px;
  text-align: center;

  background-color: ${Colors.FIN.Lt.Emphasis.White};

  &:before {
    ${Elevations.E150};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    content: '';
    z-index: -1;
  }

  h1 {
    ${Typography.DisplayXS.SB600}
  }

  .small {
    ${Typography.TextXS.R400}
  }

  button,
  a {
    width: 100%;
  }
`;

export const StyledHeader = styled.header`
  position: absolute;

  top: 0;
  left: 0;

  z-index: 100;

  display: flex;

  width: 100%;

  padding: var(--layout-safe-margins);

  box-sizing: border-box;
`;

export const StyledBackground = styled.div`
  position: absolute;

  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  overflow: hidden;

  z-index: 0;
`;

export const StyledCloudsIllustration = styled((props) => (
  <img className={props.className} src={CloudsIllustration} />
))`
  opacity: 0.35;

  min-width: 820px;
  min-height: 600px;

  ${respondTo(
    'small',
    '<=',
  )(css`
    min-width: 722px;
    min-height: 528px;
  `)}
`;

export const StyledKeyholeIllustration = styled((props) => (
  <img className={props.className} src={KeyholeIllustration} />
))`
  position: absolute;
  top: 50%;
  left: 50%;

  width: 339px;
  height: 863px;
  transform: translate(calc(-50% + 450px), calc(-50% + 250px));

  ${respondTo(
    'small',
    '<=',
  )(css`
    display: none;
  `)}
`;
