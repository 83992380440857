import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeWidth="1.5"
      d="M18.298 7.25a7.25 7.25 0 1 0-9.33 10.466"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeWidth="1.5"
      d="M12.25 7.25v4l-2.5 1.5"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.3"
      d="M13.25 15.146c0-.26.21-.471.47-.471h4.945c.26 0 .47.21.47.47v2.59c0 .52-.421.942-.941.942h-4.002a.942.942 0 0 1-.942-.942v-2.59Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.3"
      d="M14.191 14.557v-.074c0-.735-.044-1.554.47-2.08.292-.3.766-.553 1.531-.553.766 0 1.24.253 1.532.553.514.526.47 1.345.47 2.08v.074"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m18.75 3.75.5.5"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m5.3 3.75-.5.5"
    />
  </BaseIcon>
);
