import { memoize } from 'lodash';

import { BitsHandler } from './BitsHandler';

export const checkMatchRequiredBits = memoize(
  (current, required) => {
    const currentBits = BitsHandler.from(32, current);
    const requiredBits = BitsHandler.from(32, required);

    const currentBitsArr = currentBits.toArray();
    const requiredBitsArr = requiredBits.toArray();

    return requiredBitsArr.every((bit, i) => {
      return !bit || currentBitsArr[i] === bit;
    });
  },
  (current, required) => `${current}-${required}`,
);

export const checkHasRequiredBit = memoize(
  (current, requiredBitIndex) => {
    const currentBits = BitsHandler.from(32, current);
    return currentBits.get(requiredBitIndex);
  },
  (current, required) => `${current}-${required}`,
);
