import React, { forwardRef } from 'react';

import { IconProjectConfirmed } from '@float/ui/icons/essentials/IconProjectConfirmed';
import { IconProjectDraft } from '@float/ui/icons/essentials/IconProjectDraft';
import { IconProjectTentative } from '@float/ui/icons/essentials/IconProjectTentative';
import { SVGIconComponent } from '@float/ui/primitives/SVGIcon';

import * as styles from './index.style.css';

export const IconStatusDraft: SVGIconComponent = forwardRef((props, ref) => {
  return (
    <IconProjectDraft
      {...props}
      ref={ref}
      className={styles.iconProjectDraft}
    />
  );
});

export const IconStatusTentative: SVGIconComponent = forwardRef(
  (props, ref) => {
    return (
      <IconProjectTentative
        {...props}
        ref={ref}
        className={styles.iconProjectTentative}
      />
    );
  },
);

export const IconStatusConfirmed: SVGIconComponent = forwardRef(
  (props, ref) => {
    return (
      <IconProjectConfirmed
        {...props}
        ref={ref}
        className={styles.iconProjectConfirmed}
      />
    );
  },
);
