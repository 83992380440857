import React, { useEffect, useRef } from 'react';
import useUnmount from 'react-use/esm/useUnmount';
import { getUser } from 'selectors';

import { Detector } from '@float/common/lib/connectionStatusDetectors';
import socket, {
  ensureConnectionClosed,
} from '@float/common/lib/liveUpdates/socket';
import { useAppSelectorStrict, useAppStoreStrict } from '@float/common/store';
import { config } from '@float/libs/config';
import { useSnackbar } from '@float/ui/deprecated';

import { useWebAppDispatchStrict } from '../lib/store';
import { useManageModal } from '../modalManager/useManageModal';
import { onSocketMessageReceive } from './actions';
import { ConnectionStatus } from './ConnectionStatus';

const polling = {
  interval: config.isDevBuildMode ? 10 * 60 * 1000 : 20000, // 20 seconds (10 min on local)
  urls: [
    'https://connectivitycheck.gstatic.com/generate_204',
    'https://float-fe.global.ssl.fastly.net/public/up.html',
  ],
  // Forcing polling to not rely on browser online/offline events
  // https://github.com/chrisbolin/react-detect-offline/issues/45
  enabled: true,
  maxOnlineDurationWithoutPing: 3600000, // 1 hour
  timeout: 5000,
};

export function LiveUpdates() {
  const accessToken = useAppSelectorStrict((state) => state.jwt.accessToken);
  const store = useAppStoreStrict();
  const dispatch = useWebAppDispatchStrict();

  const { showSnackbar } = useSnackbar();
  const { manageModal } = useManageModal();

  const initialized = useRef(false);

  useEffect(() => {
    if (!accessToken) return;
    if (initialized.current) return;

    function dispatchMessage(message: string) {
      dispatch(onSocketMessageReceive(message, manageModal, showSnackbar));
    }

    socket.init({
      jwt: accessToken,
      currentUser: getUser(store.getState()),
      onSocketMessageReceive: dispatchMessage,
    });

    initialized.current = true;
  });

  useUnmount(() => {
    ensureConnectionClosed();
  });

  return (
    <Detector
      polling={polling}
      render={({ online, connectionLostAt }) => (
        <ConnectionStatus online={online} connectionLostAt={connectionLostAt} />
      )}
    />
  );
}
