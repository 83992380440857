import { makeRequest } from '../makeRequest';

// Given a resource and an initial query fetches all the resource pages
// useful when the resource may reach the 25k items limit
export async function fetchAllPages<V>(
  resource: string,
  query: Record<string, string>,
) {
  const queryCopy: Record<string, string> = {
    ...query,
    ['internal_pagination']: '1',
  };

  const [result, pagesCount] = (await makeRequest({
    resource,
    query: queryCopy,
    method: 'GET',
  })) as [V[], number];

  let page = 1;

  while (page < pagesCount) {
    queryCopy['page'] = String(++page);

    const [chunk] = (await makeRequest({
      resource,
      query: queryCopy,
      method: 'GET',
    })) as [V[]];

    for (const value of chunk) {
      result.push(value);
    }
  }

  return result as V[];
}
