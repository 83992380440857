import React from 'react';
import { Trans } from '@lingui/macro';

import { InsightsEntry } from '@float/common/reducers/timeRange';
import {
  Tooltip,
  TooltipProps,
  TooltipVariants,
} from '@float/ui/components/Tooltip';

import { convertNumberToLocaleString, getUtilization } from '../helpers';
import {
  InsightBadge,
  InsightBadgeProps,
} from '../subcomponents/InsightBadge/InsightBadge';

import * as styles from './InsightForSchedule.css';

type DisplayMetric = 'capacity' | 'utilization';

export type InsightForScheduleProps = {
  insight?: InsightsEntry;
  isLoading?: boolean;
  displayMetric: DisplayMetric;
  displayUtilizationOvertime?: boolean;
};

export const InsightForSchedule = ({
  insight,
  isLoading,
  displayMetric,
  displayUtilizationOvertime = true,
}: InsightForScheduleProps) => {
  if (!insight) return null;

  const {
    h: scheduled,
    capacity: unscheduled,
    totalCapacity,
    overtime,
  } = insight;

  const utilization = getUtilization({
    scheduled,
    totalCapacity,
  });

  const isUtilizationMetric = displayMetric === 'utilization';
  const isCapacityMetric = displayMetric === 'capacity';
  const hasTotalCapacity = totalCapacity > 0;
  const hasOvertime = overtime !== 0;
  const hasUnscheduledTime = unscheduled > 0;

  let value;
  let valueUnit = '';
  let valueText = '';

  if (isUtilizationMetric && !hasTotalCapacity) {
    value = utilization;
    valueText = '—';
  } else if (isUtilizationMetric && hasTotalCapacity) {
    value = utilization;
    valueText = convertNumberToLocaleString(value, {
      style: 'percent',
    });
    valueUnit = '%';
  } else if (isCapacityMetric && hasOvertime && !hasUnscheduledTime) {
    value = overtime;
    valueText = convertNumberToLocaleString(value);
    valueUnit = 'h';
  } else if (isCapacityMetric) {
    value = unscheduled;
    valueText = convertNumberToLocaleString(value);
    valueUnit = 'h';
  }

  // Insight and tooltip variants (neutral by default)
  let variant: InsightBadgeProps['state'] = 'neutral';
  let variantTooltip: TooltipProps['variant'] = TooltipVariants.default;

  // The insight is considered critical when is displayed as utilization and is more than 100%
  // or is a capacity and has overtime and there are no more unscheduled hours left
  const isCriticalInsight =
    (displayMetric === 'utilization' && utilization > 100) ||
    (displayMetric === 'capacity' && overtime !== 0 && unscheduled <= 0);

  // The insight is warning when there is an overtime (both for utilization and capacity cases)
  const isWarningInsight = overtime !== 0;

  if (isCriticalInsight) {
    variant = 'critical';
    variantTooltip = TooltipVariants.error;
  } else if (isWarningInsight) {
    variant = 'warning';
    variantTooltip = TooltipVariants.warning;
  }

  return (
    <Tooltip
      content={
        <InsightForScheduleTooltipContent
          scheduled={scheduled}
          unscheduled={unscheduled}
          overtime={overtime}
          totalCapacity={totalCapacity}
          utilization={utilization}
          displayMetric={displayMetric}
          displayUtilizationOvertime={displayUtilizationOvertime}
        />
      }
      variant={variantTooltip}
      placement="right"
    >
      <div style={{ display: 'inline-flex' }}>
        <InsightBadge
          state={variant}
          value={valueText}
          unit={valueUnit}
          isLoading={isLoading}
        />
      </div>
    </Tooltip>
  );
};

export type InsightForScheduleTooltipContentProps = {
  scheduled: number;
  unscheduled: number;
  overtime: number;
  totalCapacity: number;
  utilization: number;
  displayMetric: DisplayMetric;
  displayUtilizationOvertime?: boolean;
};

export const InsightForScheduleTooltipContent = ({
  scheduled,
  unscheduled,
  overtime,
  totalCapacity,
  utilization,
  displayMetric,
  displayUtilizationOvertime,
}: InsightForScheduleTooltipContentProps) => {
  const utilizationLocalizedValue = convertNumberToLocaleString(utilization);
  const unscheduledLocalizedValue = convertNumberToLocaleString(unscheduled);
  const scheduledLocalizedValue = convertNumberToLocaleString(scheduled);
  const overtimeLocalizedValue = convertNumberToLocaleString(
    Math.abs(overtime),
  );

  const isUtilizationMetric = displayMetric === 'utilization';
  const isCapacityMetric = displayMetric === 'capacity';
  const hasTotalCapacity = totalCapacity > 0;
  const hasOvertime = overtime !== 0;
  const hasScheduledTime = scheduled > 0;
  const hasUnscheduledTime = unscheduled > 0;

  const utilizationEl = (
    <div>
      <Trans>
        <strong className={styles.tooltipContentBold}>
          {utilizationLocalizedValue}%
        </strong>{' '}
        utilized
      </Trans>
    </div>
  );

  const unscheduledEl = (
    <div>
      <Trans>
        <strong className={styles.tooltipContentBold}>
          {unscheduledLocalizedValue}h
        </strong>{' '}
        unscheduled
      </Trans>
    </div>
  );

  const scheduledEl = (
    <div>
      <Trans>
        <strong className={styles.tooltipContentBold}>
          {scheduledLocalizedValue}h
        </strong>{' '}
        scheduled
      </Trans>
    </div>
  );

  const overtimeEl = (
    <div>
      <Trans>
        <strong className={styles.tooltipContentBold}>
          {overtimeLocalizedValue}h
        </strong>{' '}
        overtime
      </Trans>
    </div>
  );

  const capacityUnavailableEl = (
    <div>
      <Trans>
        <strong className={styles.tooltipContentBold}>0</strong> capacity for
        this date range
      </Trans>
    </div>
  );

  let content;

  if (isUtilizationMetric && !hasTotalCapacity) {
    content = capacityUnavailableEl;
  } else if (isUtilizationMetric && hasTotalCapacity) {
    content = (
      <>
        {utilizationEl}
        {displayUtilizationOvertime && overtime !== 0 && overtimeEl}
      </>
    );
  }
  // When there is no capacity we always show unscheduled hours if other
  // entries are missing
  else if (isCapacityMetric && !hasTotalCapacity) {
    // We show this element if ther is unscheduled value,
    // or the other two labels are missing
    const hasUnscheduledEl =
      hasUnscheduledTime || (!hasScheduledTime && !hasOvertime);

    content = (
      <>
        {hasUnscheduledEl && unscheduledEl}
        {hasScheduledTime && scheduledEl}
        {hasOvertime && overtimeEl}
      </>
    );
  } else if (isCapacityMetric && hasTotalCapacity) {
    content = (
      <>
        {hasUnscheduledTime && unscheduledEl}
        {hasScheduledTime && scheduledEl}
        {hasOvertime && overtimeEl}
      </>
    );
  }

  return <div style={{ textAlign: 'left' }}>{content}</div>;
};
