import { config } from '@float/libs/config';
import { CellItem, CellsMap, ProjectRowId } from '@float/types/cells';
import { Milestone } from '@float/types/milestone';
import { Phase } from '@float/types/phase';

import { getEntityMeta } from './entityMeta';
import { getSortKey } from './helpers/buildProjectRowSortData';
import { getProjectRowSortData } from './helpers/getProjectRowSortData';
import { SELECTION_ID } from './selection';
import { CellFns, CellHelpersProps } from './types';

// Using a collator is a bit faster than directly calling .localeCompare
const collator = new Intl.Collator(config.locale);

export function buildProjectCell(
  props: CellHelpersProps,
  cells: CellsMap,
  cellKey: ProjectRowId,
  fns: CellFns, // Move these functions in a module
) {
  const { dates, maps, bimaps, leftHiddenDays, rightHiddenDays } = props;
  const {
    milestone: milestonesMap,
    selection: selectionsMap,
    phase: phasesMap,
  } = maps;
  const {
    milestone: milestonesBimap,
    selection: selectionsBimap,
    phase: phasesBimap,
  } = bimaps;
  const { getHorizontalDimensions } = fns;

  const cell = cells[cellKey];
  const { colIdx } = cell;
  const firstCellDay = colIdx * 7 + leftHiddenDays;
  const lastCellDay = (colIdx + 1) * 7 - 1 - rightHiddenDays;
  const maxVisibleSubCol = 7 - rightHiddenDays - leftHiddenDays - 1;
  const projectId = cell.rowId.substring('project-'.length);
  const sortData = getProjectRowSortData(cells, projectId);

  function setVisibleLength(entity: Milestone | Phase) {
    const [endCol, endSubCol] = dates.toDescriptor(entity.end_date);
    const [startCol, startSubCol] = dates.toDescriptor(entity.start_date);

    let visibleLength = dates.diffDays(entity.start_date, entity.end_date) + 1;

    for (let col = startCol; col <= endCol; col++) {
      // Any middle column will have all of its left and right days suppressed
      if (startCol < col) visibleLength -= leftHiddenDays;
      if (endCol > col) visibleLength -= rightHiddenDays;

      if (col === startCol) {
        if (startSubCol < 0) visibleLength += startSubCol;
        if (startSubCol > maxVisibleSubCol) {
          // We already subtracted the right hidden days for this col, but we
          // might have been overly aggressive. Adjust.
          visibleLength += startSubCol - 1 - maxVisibleSubCol;
        }
      }

      if (col === endCol) {
        if (endSubCol < 0) visibleLength -= endSubCol + 1;
        if (endSubCol > maxVisibleSubCol) {
          visibleLength -= endSubCol - maxVisibleSubCol;
        }
      }
    }

    entity.visibleLength = visibleLength;
  }

  milestonesBimap.getRev(cellKey).forEach((id) => {
    const m = milestonesMap[id];
    const { x, w } = getHorizontalDimensions(m, firstCellDay, lastCellDay);
    const [startCol, startSubCol] = dates.toDescriptor(m.start_date);

    // @entity.length
    if (!m.length) {
      m.length = dates.diffDays(m.start_date, m.end_date) + 1;
    }

    setVisibleLength(m);

    const isShiftedByWeekend = startSubCol > maxVisibleSubCol;

    const isStart =
      (colIdx === startCol && startSubCol <= maxVisibleSubCol) ||
      (colIdx === startCol + 1 && isShiftedByWeekend);

    const cellItem: CellItem<'milestone'> = {
      cellKey,
      key: `${cellKey}:milestone:${m.milestone_id}`,
      type: 'milestone',
      entity: m,
      entityId: m.milestone_id,
      x,
      w,
      isStart,
      isEnd: isStart,
      isPlaceholder: getEntityMeta(m, 'isPlaceholder'),
    };

    if (w > 0) cell.items.push(cellItem);

    if (!isStart) {
      const [startCol] = dates.toDescriptor(m.start_date);
      cell.dependsOnCols.push(isShiftedByWeekend ? startCol + 1 : startCol);
    }
  });

  phasesBimap.getRev(cellKey).forEach((id) => {
    const p = phasesMap[id];
    const { x, w } = getHorizontalDimensions(p, firstCellDay, lastCellDay);
    const [startCol, startSubCol] = dates.toDescriptor(p.start_date);

    // @entity.length
    if (!p.length) {
      p.length = dates.diffDays(p.start_date, p.end_date) + 1;
    }

    const isShiftedByWeekend = startSubCol > maxVisibleSubCol;

    setVisibleLength(p);
    const isStart =
      (colIdx === startCol && startSubCol <= maxVisibleSubCol) ||
      (colIdx === startCol + 1 && isShiftedByWeekend);

    const cellItem: CellItem<'phase'> = {
      cellKey,
      key: `${cellKey}:phase:${p.phase_id}`,
      type: 'phase',
      entity: p,
      entityId: p.phase_id,
      x,
      w,
      isStart,
      isEnd: isStart,
      isPlaceholder: getEntityMeta(p, 'isPlaceholder'),
    };

    if (w > 0) cell.items.push(cellItem);

    if (!isStart) {
      const [startCol] = dates.toDescriptor(p.start_date);
      cell.dependsOnCols.push(isShiftedByWeekend ? startCol + 1 : startCol);
    }
  });

  // Project row items are sorted project-wide, not per cell, in
  // useCells/_helpers::buildProjectRowSortData. The appropriate y values to use
  // come from the sortData map created there.
  cell.items.forEach((ci) => {
    if (ci.type === 'selection') {
      return;
    }

    const sortKey = getSortKey(ci.entity);

    if (typeof sortData[sortKey] === 'undefined') {
      console.log('Missing sort data for item', sortData, ci);
      ci.y = 0;
      return;
    }

    ci.y = sortData[sortKey] as number;

    if (ci.y + 1 > cell.height) {
      cell.height = ci.y + 1;
    }
  });

  // Selections don't impact the milestone y calculations, so add them to the
  // cell.items array after we've arranged them.
  selectionsBimap.getRev(cellKey).forEach((id) => {
    const s = selectionsMap[id];
    const { x, w } = getHorizontalDimensions(s, firstCellDay, lastCellDay);

    const cellItem: CellItem<'selection'> = {
      cellKey,
      key: `${cellKey}:selection:${SELECTION_ID}`,
      type: 'selection',
      entity: s,
      entityId: SELECTION_ID,
      x,
      w,
    };

    if (w > 0) cell.items.push(cellItem);
  });

  cell.items.sort((a, b) => collator.compare(a.key, b.key));

  return cell;
}
