import styled, { css } from 'styled-components';

const slideTransition = css`
  transition: transform 500ms ease-out;
`;

const fadeTransition = css`
  transition: opacity 250ms ease-out;
`;

const zoomTransition = css`
  transition:
    opacity 200ms ease-out,
    transform 200ms ease-out;
`;

export const StyledNotification = styled.div`
  display: flex;
  position: absolute;
  z-index: 10005;
  background-color: ${(p) => (p.dark ? p.theme.charcoalGrey : '#fff')};
  border: 1px solid ${(p) => (p.dark ? p.theme.charcoalGrey : p.theme.blueGrey)};
  border-radius: 5px;
  box-shadow:
    0 10px 20px 0 rgba(0, 0, 0, 0.1),
    0 1px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 14px 14px;
  font-size: 14px;
  line-height: 1.29;
  width: ${(p) =>
    typeof p.width === 'string' ? p.width : `${p.width || 320}px`};
  color: ${(p) => (p.dark ? '#fff' : p.theme.charcoalGrey)};
  cursor: ${(p) => (typeof p.onClick === 'function' ? 'pointer' : 'default')};
  ${(p) => p.theme.antialias}

  &, & * {
    box-sizing: border-box;
  }

  &.bottom {
    bottom: 20px;
    top: auto;
  }

  &.top {
    top: 20px;
    bottom: auto;
  }

  &.middle {
    top: 50%;
    bottom: auto;
    transform: translate(0, -50%);
  }

  &.left {
    left: 20px;
    right: auto;
  }

  &.right {
    right: 20px;
    left: auto;
  }

  &.center {
    left: 50%;
    right: auto;
    transform: translate(-50%, 0);
  }

  strong {
    line-height: 1.43;
  }

  .icon-close-small {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    display: block !important;
  }

  ${({ x, y, animation }) => {
    if (animation === 'slide') {
      const xTransform = x === 'center' ? '-50%' : '0';
      const yTransform = y === 'middle' ? '-50%' : '0';
      return css`
        &.left {
          &-enter {
            transform: translate3d(-120%, ${yTransform}, 0);
          }

          &-enter-active {
            transform: translate3d(${xTransform}, ${yTransform}, 0);
            ${slideTransition}
          }

          &-exit-active {
            transform: translate3d(-120%, ${yTransform}, 0);
            ${slideTransition}
          }
        }

        &.right {
          &-enter {
            transform: translate3d(120%, ${yTransform}, 0);
          }

          &-enter-active {
            transform: translate3d(${xTransform}, ${yTransform}, 0);
            ${slideTransition}
          }

          &-exit-active {
            transform: translate3d(120%, ${yTransform}, 0);
            ${slideTransition}
          }
        }
      `;
    }
    if (animation === 'zoom') {
      return css`
        &.left,
        &.right {
          &-enter {
            opacity: 0;
            transform: scale3d(0.9, 0.9, 0.9);
          }

          &-enter-active {
            opacity: 1;
            transform: scale3d(1, 1, 1);
            ${zoomTransition}
          }

          &-exit-active {
            opacity: 0;
            transform: scale3d(0.9, 0.9, 0.9);
            ${zoomTransition}
          }
        }
      `;
    }
    if (animation === 'fade') {
      return css`
        &.left,
        &.right {
          &-enter {
            opacity: 0;
          }

          &-enter-active {
            opacity: 1;
            ${fadeTransition}
          }

          &-exit-active {
            opacity: 0;
            ${fadeTransition}
          }
        }
      `;
    }
    return '';
  }}

  ${({ arrow }) => {
    if (arrow) {
      return css`
        &:before,
        &:after {
          display: block;
          position: absolute;
          content: '';
          top: 50%;
          width: 0;
          height: 0;
        }

        &:before {
          margin-top: -10px;
          border: 10px solid transparent;
        }

        &:after {
          margin-top: -12px;
          border: 12px solid transparent;
        }
      `;
    }
    return '';
  }}

  ${({ arrow }) => {
    if (arrow === 'left') {
      return css`
        &:before,
        &:after {
          left: -22px;
        }

        &:before {
          border-right-color: ${(p) =>
            p.dark ? p.theme.charcoalGrey : p.theme.blueGrey};
        }

        &:after {
          border-right-color: ${(p) =>
            p.dark ? p.theme.charcoalGrey : '#fff'};
        }
      `;
    } else if (arrow === 'right') {
      return css`
        &:before,
        &:after {
          right: -20px;
        }

        &:before {
          border-left-color: ${(p) =>
            p.dark ? p.theme.charcoalGrey : p.theme.blueGrey};
        }

        &:after {
          border-left-color: ${(p) => (p.dark ? p.theme.charcoalGrey : '#fff')};
        }
      `;
    } else if (arrow === 'top') {
      return css`
        &:before,
        &:after {
          top: -20px;
          left: 50%;
        }

        &:before {
          margin-top: 0;
          margin-left: -10px;
          border-bottom-color: ${(p) =>
            p.dark ? p.theme.charcoalGrey : p.theme.blueGrey};
        }

        &:after {
          margin-top: -3px;
          margin-left: -12px;
          border-bottom-color: ${(p) =>
            p.dark ? p.theme.charcoalGrey : '#fff'};
        }
      `;
    } else if (arrow === 'bottom') {
      return css`
        &:before,
        &:after {
          bottom: -20px;
          left: 50%;
          top: auto;
        }

        &:after {
          margin-top: -3px;
          margin-left: -12px;
          border-top-color: ${(p) => (p.dark ? p.theme.charcoalGrey : '#fff')};
        }
      `;
    }
    return '';
  }}
`;
