import { keyBy } from 'lodash';

import API3 from '../api3';
import { ReduxStateStrict } from '../reducers/lib/types';
import { AppDispatchStrict } from '../store';

export const ACCOUNTS_UPDATE = 'accounts/UPDATE';
export const ACCOUNTS_DELETE = 'accounts/DELETE';
export const ACCOUNTS_LOAD_START = 'accounts/LOAD_START';
export const ACCOUNTS_LOAD_FAILED = 'accounts/LOAD_FAILED';
export const ACCOUNTS_LOAD_FINISH = 'accounts/LOAD_FINISH';

const fetchAccounts = async () => {
  const res = await API3.getAllAccounts();
  return keyBy(res, 'account_id');
};

export const ensureAccountsLoaded = (forceLoad = false) => {
  return async (
    dispatch: AppDispatchStrict,
    getState: () => ReduxStateStrict,
  ) => {
    const { loadState: currentLoadState } = getState().accounts;

    if (!forceLoad && currentLoadState === 'LOADED') return;
    if (currentLoadState === 'LOADING') return; // There's already an in-flight load request

    try {
      dispatch({ type: ACCOUNTS_LOAD_START });
      const accounts = await fetchAccounts();
      dispatch({ type: ACCOUNTS_LOAD_FINISH, accounts });
    } catch (e) {
      console.error(e);
      dispatch({ type: ACCOUNTS_LOAD_FAILED });
    }
  };
};
