import { createSelector } from 'reselect';

import { fastDictionary } from '@float/common/lib/fast-object-spread';
import { Phase, Project } from '@float/types';

import { getPhases } from '../phases';
import { getProjectsRawList } from '../projects/getProjectsRawList';

type Entry = { project_ids: number[]; active_project_ids: number[] };
type VisitStrategy = (entry: Entry, entity: Project | Phase) => void;

export const getProjectIdsForPerson = createSelector(
  [getProjectsRawList, getPhases],
  (projects, phases: Phase[]) => {
    // OPTIMIZATION: using a fastDictionary to make read/writes faster
    const total: Record<number, Entry> = fastDictionary();
    const projectAddedToPerson: Record<
      number,
      Record<number, boolean>
    > = fastDictionary();

    const createPersonIdEntityVisitor =
      (visitStrategy: VisitStrategy) =>
      (personId: number, entity: Project | Phase) => {
        if (!personId) {
          return;
        }

        // OPTIMIZATION: Storing the record in a var in order to reduce the dictionary reads
        let entry = total[personId];
        let projectsAdded = projectAddedToPerson[personId];

        if (entry === undefined) {
          projectsAdded = projectAddedToPerson[personId] = fastDictionary();
          entry = total[personId] = {
            project_ids: [],
            active_project_ids: [],
          };
        }

        const projectId = Number(entity.project_id);
        if (!projectsAdded[projectId]) {
          visitStrategy(entry, entity);
          projectsAdded[projectId] = true;
        }
      };

    const visitProject = createPersonIdEntityVisitor((entry, project) => {
      entry.project_ids.push(Number(project.project_id));
      entry.active_project_ids.push(Number(project.project_id));
    });

    const visitPhase = createPersonIdEntityVisitor((entry, phase) => {
      entry.project_ids.push(Number(phase.project_id));

      if (phase.active) {
        entry.active_project_ids.push(phase.project_id);
      }
    });

    for (const project of projects) {
      for (const personId of project.people_ids) {
        visitProject(personId, project);
      }
    }

    for (const phase of phases) {
      for (const personId of phase.people_ids) {
        visitPhase(personId, phase);
      }
    }

    return total;
  },
);
