import React from 'react';
import { useFormContext } from 'react-hook-form';
import { t } from '@lingui/macro';

import { ComboboxField } from '../components/ComboboxField';
import { FormField } from '../components/FormFieldsSection';
import { useProjectACL } from '../hooks/useProjectACL';
import { ProjectFormData } from '../types';
import { useProjectOwnerOptions } from './ProjectOwnerField.hooks';

export function ProjectOwnerField() {
  const { getValues } = useFormContext<ProjectFormData>();
  const options = useProjectOwnerOptions();
  const acl = useProjectACL();

  if (!acl.canChangeEditRights) {
    const option = options.find(
      (o) => o.value === getValues('project.project_manager'),
    );

    if (option) {
      return (
        <FormField label={t`Owner`}>
          {option.icon} {option.label}
        </FormField>
      );
    }

    return null;
  }

  return (
    <ComboboxField
      name="project.project_manager"
      label={t`Owner`}
      options={options}
      nonNullable
    />
  );
}
