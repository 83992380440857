import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Plural, t, Trans } from '@lingui/macro';

import { deletePhase, updatePhaseFromSidePanel } from '@float/common/actions';
import { useAppDispatchStrict } from '@float/common/store';
import { useModalControls } from '@float/ui/deprecated/Modal/hooks';
import { PanelType } from '@float/web/sidePanel/types';
import { useSidePanel } from '@float/web/sidePanel/useSidePanel';
import { ConfirmDeleteModal } from 'components/modals/ConfirmDeleteModal';
import { ConfirmModal } from 'components/modals/ConfirmModal';

import { SidePanelMeatballMenu } from '../../components/SidePanelMeatballMenu';
import { useFieldArrayContext } from '../../hooks/useFieldArrayContext';
import { useIsPhasePanel } from '../../hooks/useIsPhasePanel';
import { useIsTemplate } from '../../hooks/useIsTemplate';
import { useSidePanelSnackbar } from '../../hooks/useSidePanelSnackbar';
import { PhaseEditData, ProjectFormData } from '../../types';
import { useTaskDeleteImpact } from '../ProjectTasksSection/index.hooks';
import { usePhaseTeamUpdateHandler } from './hooks/usePhaseTeamUpdateHandler';
import { usePhaseMenuItems, usePhaseTasks } from './ProjectPhasesMenu.hooks';

import * as styles from './styles.css';

const PhaseDeleteMessage = (props: {
  numPhaseTasks: number;
  phaseName: string;
}) => {
  const { numPhaseTasks, phaseName } = props;
  if (!numPhaseTasks) {
    return (
      <div>
        <Trans>
          Delete <strong>"{phaseName}"</strong>?
        </Trans>
      </div>
    );
  }
  return (
    <div>
      <Trans>
        Delete <strong>"{phaseName}"</strong> including at least{' '}
        <strong>
          {numPhaseTasks} scheduled{' '}
          <Plural value={numPhaseTasks} one="task" other="tasks" />
        </strong>
        ?
      </Trans>
    </div>
  );
};

const PhaseArchiveMessage = ({
  phaseScheduledTasks,
  phaseLoggedTime,
}: {
  phaseScheduledTasks: number;
  phaseLoggedTime: number;
}) => {
  return (
    <React.Fragment>
      <p>
        <Trans>What this means:</Trans>
      </p>
      <p>
        {phaseScheduledTasks !== 0 && (
          <>
            &nbsp;&bull;&nbsp;
            <Trans>
              {phaseScheduledTasks} scheduled{' '}
              <Plural value={phaseScheduledTasks} one="task" other="tasks" />{' '}
              will be archived
            </Trans>
            <br />
          </>
        )}
        {phaseLoggedTime !== 0 && (
          <>
            &nbsp;&bull;&nbsp;
            <Trans>
              {phaseLoggedTime}{' '}
              <Plural
                value={phaseLoggedTime}
                one="logged time"
                other="logged times"
              />{' '}
              will be archived
            </Trans>
            <br />
          </>
        )}
        &nbsp;&bull;&nbsp;
        <Trans>All historical data will be retained in Float</Trans>
        <br />
        &nbsp;&bull;&nbsp;
        <Trans>You will no longer be able to make changes to this phase</Trans>
        <br />
      </p>
      <p>
        <Trans>
          Any archived phase can be restored from the original project it was
          created in.
        </Trans>
      </p>
    </React.Fragment>
  );
};

type PhaseMenuProps = {
  className?: string;
  index: number;
  name: `phases.${number}` | 'phase';
  handleActivate: () => void;
  handleArchive: () => void;
  handleDelete: () => void;
  handleEditPhaseDetails?: () => void;
};

function PhaseMenu({
  className,
  index,
  name,
  handleActivate,
  handleArchive,
  handleDelete,
  handleEditPhaseDetails,
}: PhaseMenuProps) {
  const { getValues } = useFormContext<ProjectFormData>();
  const isTemplate = useIsTemplate();
  const isPhasePanel = useIsPhasePanel();
  const phase = getValues(name);

  const phaseId = phase?.phase_id ?? undefined;
  const phaseActive = phase?.active;
  const phaseTasks = usePhaseTasks(getValues('projectId'), Number(phaseId));
  const impact = useTaskDeleteImpact(phaseTasks);
  const confirmArchiveModal = useModalControls();
  const confirmDeleteModal = useModalControls();

  const items = usePhaseMenuItems({
    index,
    isTemplate,
    isPhasePanel,
    phaseActive,
    phaseId,
    phaseName: phase?.phase_name,
    handleActivate,
    handleArchive: confirmArchiveModal.openModal,
    handleDelete: phaseId ? confirmDeleteModal.openModal : handleDelete,
    handleEditPhaseDetails,
  });

  if (!phase) return null;

  const phaseName = phase.phase_name;

  return (
    <React.Fragment>
      <SidePanelMeatballMenu
        className={className}
        disabled={!phaseId && !phaseName.trim()}
        vertical
        items={items}
        trigger={{
          label: t`Phase actions menu`,
        }}
      />
      {confirmArchiveModal.isModalVisible && (
        <ConfirmModal
          onClose={confirmArchiveModal.closeModal}
          onConfirm={handleArchive}
          title={
            <span className={styles.confirmArchiveHeader}>
              <Trans>Archive "{phaseName}" Phase?</Trans>
            </span>
          }
          message={
            <PhaseArchiveMessage
              phaseScheduledTasks={impact ? impact.scheduledTasks : 0}
              phaseLoggedTime={impact ? impact.loggedTasks : 0}
            />
          }
          confirmAppearance="danger"
          confirmLabel={t`Archive ${phaseName}`}
        />
      )}
      {confirmDeleteModal.isModalVisible && (
        <ConfirmDeleteModal
          onCancel={confirmDeleteModal.closeModal}
          onDelete={handleDelete}
          title={t`Delete phase`}
          deleteLabel={t`Delete`}
          confirmLabel={t`Click to confirm you want to proceed`}
          message={
            <PhaseDeleteMessage
              numPhaseTasks={impact ? impact.scheduledTasks : 0}
              phaseName={phaseName}
            />
          }
          stopPropagation
        />
      )}
    </React.Fragment>
  );
}

export function PhasePanelMenu({ className }: { className?: string }) {
  const dispatch = useAppDispatchStrict();
  const { hideSidePanel, showPhaseSidePanel, openPanels, panelPayload } =
    useSidePanel<PhaseEditData>(PanelType.PhasePanel);
  const { showSnackbar, showSuccessSnackbar } = useSidePanelSnackbar();
  const { getValues } = useFormContext<ProjectFormData>();
  const phaseId = getValues('phase.phase_id');
  const phaseName = getValues('phase.phase_name');

  if (!phaseId) return null;

  const isFirstPanel = openPanels.length === 1;

  const handleDelete = async () => {
    try {
      await dispatch(deletePhase(phaseId));
    } catch {
      showSnackbar(t`"${phaseName}" deletion failed.`);
      return;
    }
    showSnackbar(t`"${phaseName}" deleted.`);
    hideSidePanel();
  };

  const afterActiveArchiveChange = (message: string) => {
    if (isFirstPanel) {
      showSuccessSnackbar(message, {
        onLinkClick: () => {
          showPhaseSidePanel(panelPayload);
        },
      });
    } else {
      showSnackbar(message);
    }
    hideSidePanel();
  };

  const handleArchive = async () => {
    try {
      await dispatch(updatePhaseFromSidePanel(phaseId, { active: false }));
    } catch {
      showSnackbar(t`"${phaseName}" archival failed.`);
      return;
    }
    afterActiveArchiveChange(t`"${phaseName}" archived.`);
  };

  const handleActivate = async () => {
    try {
      await dispatch(updatePhaseFromSidePanel(phaseId, { active: true }));
    } catch {
      showSnackbar(t`"${phaseName}" activation failed.`);
      return;
    }
    afterActiveArchiveChange(t`"${phaseName}" activated.`);
  };

  return (
    <PhaseMenu
      index={0}
      name="phase"
      className={className}
      handleDelete={handleDelete}
      handleActivate={handleActivate}
      handleArchive={handleArchive}
    />
  );
}

export function ProjectPhasesMenu({
  index,
  handleEditPhaseDetails,
}: {
  index: number;
  handleEditPhaseDetails: () => void;
}) {
  const { clearErrors, trigger, setValue } = useFormContext<ProjectFormData>();
  const { remove } = useFieldArrayContext();
  const handlePhaseTeamUpdate = usePhaseTeamUpdateHandler();

  const handleDelete = () => {
    remove(index);
    clearErrors('phases');
    trigger('phases');
    handlePhaseTeamUpdate();
  };

  const handleArchive = () => {
    setValue(`phases.${index}.active`, false);
  };

  const handleActivate = () => {
    setValue(`phases.${index}.active`, true);
  };

  return (
    <PhaseMenu
      name={`phases.${index}`}
      index={index}
      handleDelete={handleDelete}
      handleActivate={handleActivate}
      handleArchive={handleArchive}
      handleEditPhaseDetails={handleEditPhaseDetails}
    />
  );
}
