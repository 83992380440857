function parseInputTime(value: string): {
  hours: number;
  minutes: number;
  period: string | undefined;
} {
  let time;
  let period;

  if (/[ap]/.test(value)) {
    let index = value.indexOf('a');
    if (index < 0) index = value.indexOf('p');

    time = value.substring(0, index);
    period = value.substring(index, index + 2);

    if (period !== 'am' && period !== 'pm')
      period = value.substring(index, index + 1);
  } else {
    time = value.replace('m', '');
  }

  let [hours = 0, minutes = 0] = time.split(':').map(Number);

  hours = isNaN(hours) ? 0 : hours;
  minutes = isNaN(minutes) ? 0 : minutes;

  if (minutes >= 60) minutes = 59;
  if (hours === 24) hours = 0;

  return { hours, minutes, period };
}

export { parseInputTime };
