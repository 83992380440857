import type { SettingsBillingInfoState } from '@float/common/reducers/lib/types';

import {
  FETCH_BILLING_INFO,
  FETCH_BILLING_INFO_FAILURE,
  FETCH_BILLING_INFO_SUCCESS,
  UPDATE_BILLING_PLAN_SUCCESS,
} from '../../actions/account/billingInfo';

const getInitialState = (): SettingsBillingInfoState => ({
  isLoading: false,
  isLoaded: false,
  plan: {},
  available_plans: [],
  inactive_plans: [],
  trial_days_left: undefined,
});

// TODO: Update action type
export const billingInfo = (state = getInitialState(), action: any) => {
  switch (action.type) {
    case FETCH_BILLING_INFO:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_BILLING_INFO_SUCCESS:
      return { ...state, isLoading: false, isLoaded: true, ...action.payload };
    case FETCH_BILLING_INFO_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_BILLING_PLAN_SUCCESS:
      return {
        ...state,
        date_next_bill: action.payload.date_next_bill,
        plan: {
          ...state.plan,
          ...action.payload.plan,
        },
      };
    default:
      return state;
  }
};
