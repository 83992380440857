import {
  FETCH_TIMEZONES,
  FETCH_TIMEZONES_FAILURE,
  FETCH_TIMEZONES_SUCCESS,
} from '../actions/timezones';
import {
  DEFAULT_SORT_TABLE,
  getInitialSortableTableState,
  getInitialSortPropsState,
  getInitialStateCreator,
  reducerCreator,
} from './reducerHelpers';

export const lastFetchError = (state) => state.lastFetchError;

export const areBeingFetched = (state) => state.pagesBeingFetched.length > 0;

const getInitialState = getInitialStateCreator(
  Object.assign(
    {
      sortableTables: {
        [DEFAULT_SORT_TABLE]: getInitialSortableTableState(
          'timezone_id',
          'asc',
        ),
      },
    },
    getInitialSortPropsState(['timezone_id']),
  ),
);

const timezones = reducerCreator(
  {
    FETCH_ENTITIES: FETCH_TIMEZONES,
    FETCH_ENTITIES_SUCCESS: FETCH_TIMEZONES_SUCCESS,
    FETCH_ENTITIES_FAILURE: FETCH_TIMEZONES_FAILURE,
  },
  'timezone_id',
  getInitialState,
);

export default timezones;
