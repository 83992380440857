import React from 'react';

import { CellItem } from '@float/types';

import { overtimeItemBox } from './OvertimeItem.css';

type OvertimeItemProps = {
  item: CellItem<'overtime'>;
  dayWidth: number;
  hourHeight: number;
  isForced?: boolean;
  suvSingleDay?: string | null;
};

function OvertimeItem({
  item,
  dayWidth,
  hourHeight,
  isForced,
  suvSingleDay,
}: OvertimeItemProps) {
  const { x = 0, w, y = 0 } = item;

  let left = x * dayWidth;
  let width: string | number = w * dayWidth;
  let bottom = 1;

  if (suvSingleDay) {
    left = 0;
    width = '100%';
    bottom = 0;
  }

  return (
    <div
      className={overtimeItemBox}
      data-testid="overtime-item"
      data-is-forced={isForced}
      style={{
        left,
        top: y * hourHeight,
        bottom,
        width,
      }}
    />
  );
}

export default OvertimeItem;
