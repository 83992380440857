import { ControllerRenderProps } from 'react-hook-form';

import { LinearCyclesIntegrationConfig } from '@float/types/linearCyclesIntegration';

import { LinearFormPayload } from './hooks/useLinearIntegrationFormControls';

export const getLastSyncDetails = (
  integrationConfig: LinearCyclesIntegrationConfig | null | undefined,
  userNamesByAccountIds: Record<number, string>,
) => {
  const lastSyncUserName =
    (integrationConfig && userNamesByAccountIds[integrationConfig?.resyncBy]) ??
    undefined;

  const lastSyncTime = integrationConfig?.resyncAt;

  return {
    lastSyncUserName,
    lastSyncTime,
  };
};

export const getFormControlsDisabledStates = ({
  isLoading,
  field,
  hasConfig,
  isValid,
}: {
  field: ControllerRenderProps<LinearFormPayload, 'feedUrl'>;
  hasConfig: boolean;
  isLoading: boolean;
  isValid: boolean;
}) => {
  const isInputDisabled = isLoading || field.disabled;
  const isSyncButtonDisabled =
    isLoading || !isValid || (!field.value && !hasConfig);
  const isToggleDisabled = isLoading || !hasConfig;

  return {
    isInputDisabled,
    isSyncButtonDisabled,
    isToggleDisabled,
  };
};
