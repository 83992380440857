import { StyleRule } from '@vanilla-extract/css';
import { css } from 'styled-components';

export const E100 = css`
  box-shadow:
    0px 1px 1px rgba(95, 106, 126, 0.2),
    0px 0px 9px rgba(9, 30, 66, 0.08);
`;

export const VE_E100: StyleRule = {
  boxShadow:
    '0px 1px 1px rgba(95, 106, 126, 0.2), 0px 0px 9px rgba(9, 30, 66, 0.08)',
};
