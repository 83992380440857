import { createContext } from 'react';

import { noop } from '@float/libs/utils/noop';

import { spawnCellStylesWorker } from '../cellColors/worker';
import { GeneratedColors } from '../cellColors/worker/cellColors';
import { getTaskStyles } from './styles/taskStyles';

export class CellStylesManager {
  queue: Record<
    string,
    Array<{
      callback: (data: GeneratedColors) => void;
      cancelled: boolean;
    }> | null
  > = {};
  worker;

  constructor(worker = spawnCellStylesWorker()) {
    this.worker = worker;
  }

  queueCellColorsCalculation(
    color: string,
    callback: (data: GeneratedColors) => void,
  ) {
    const item = { callback, cancelled: false };
    const { queue, worker } = this;

    function cancel() {
      item.cancelled = true;
    }

    function onComplete(data: GeneratedColors) {
      queue[color]?.forEach((item) => {
        const { callback, cancelled } = item ?? {
          cancelled: true,
          callback: noop,
        };

        if (!cancelled) {
          callback(data);
        }
      });

      queue[color] = null;
    }

    const entry = queue[color];

    if (!entry) {
      queue[color] = [item];

      worker.generateColors(color).then(onComplete);
    } else {
      entry.push(item);
    }

    return { cancel };
  }

  getCellStyles(
    cellColorsData: GeneratedColors,
    item: unknown,
    config: { cursor: string | null } = { cursor: null },
  ) {
    const colors = cellColorsData.cellColors.task;

    // get cell type specific styles
    const styles = getTaskStyles(colors, item, config);

    // mix it with common styles
    // @ts-expect-error - getTaskStyles has not been converted to TS
    styles.cursor = config.cursor;

    return styles;
  }
}

export const CellStylesManagerContext = createContext(new CellStylesManager());
