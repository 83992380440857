import { Control } from 'react-hook-form';

import { BudgetPriority, BudgetType } from '@float/types/project';

import { FormType, isBudgetNotAccessible, ProjectFormData } from '../types';
import { checkIsPhasePanel } from './useIsPhasePanel';
import { checkIsTemplate } from './useIsTemplate';
import { useProjectACL } from './useProjectACL';

type UsePanelViewVisibilityProps = {
  type: FormType;
  budgetType: BudgetType | undefined;
  budgetPriority: BudgetPriority | undefined;
  control: Control<ProjectFormData>;
};

export function usePanelViewVisibility(props: UsePanelViewVisibilityProps) {
  const { type, budgetType, control } = props;
  const acl = useProjectACL(control);
  const isPhase = checkIsPhasePanel(type);
  const isTemplate = checkIsTemplate(type);

  let isBudgetSectionVisible =
    acl.canSeeBudget || isBudgetNotAccessible(budgetType) === false;

  let isPhasesSectionVisible = true;

  if (isPhase) {
    isPhasesSectionVisible = false;
    isBudgetSectionVisible =
      isBudgetSectionVisible &&
      (props.budgetPriority === BudgetPriority.Phase ||
        props.budgetPriority === BudgetPriority.Task);
  }

  return {
    isPhase,
    isTemplate,
    isBudgetSectionVisible,
    isPhasesSectionVisible,
  };
}
