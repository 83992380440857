import React from 'react';
import { get, isEmpty } from 'lodash';
import styled, { css } from 'styled-components';

import { TextButton } from '@float/ui/deprecated/Earhart/Buttons';
import VirtualSelect from '@float/ui/deprecated/VirtualSelect/VirtualSelect';

const StyledProjectModalLink = styled(TextButton)`
  display: flex;
  margin-left: auto !important;
`;

const ProjectContainer = styled.div`
  position: relative;
  width: 100%;

  ${(p) =>
    p.hasError &&
    css`
      ${StyledProjectModalLink} {
        bottom: 32px;
      }
    `}
`;

export default class ProjectsDropdown extends React.Component {
  render() {
    const { canModifyProjects } = this.props;
    const projectId = get(this.props.project, 'project_id', null);

    return (
      <ProjectContainer
        canModifyProjects={canModifyProjects}
        hasError={!isEmpty(this.props.errors)}
      >
        <VirtualSelect
          label="Project"
          placeholder=""
          creatable={canModifyProjects}
          nonNullable
          clearInputOnDropdownOpen={false}
          visibleItems={6}
          style={{ flexBasis: '100%', marginBottom: 24 }}
          value={projectId}
          groupedOptions={this.props.projectsOptions}
          hideSelectedIcon
          onChange={this.onProjectSelect}
          errors={this.props.errors}
        />
      </ProjectContainer>
    );
  }

  onProjectSelect = ({ value }) => {
    const project = this.props.projectsMap[value];
    if (project) {
      return this.props.selectProject(project);
    }
    this.addNewProjectModal({ project_name: value });
  };

  addNewProjectModal = (newProject) => {
    const project = { ...newProject, canEdit: true };
    this.props.manageModal({
      visible: true,
      modalType: 'projectModal',
      modalSettings: {
        project,
        isAdding: true,
        editing: true,
        hideToggle: true,
        hideDelete: true,
        hideTasksTab: false,
        postSave: (project) => {
          this.props.selectProject(project);
        },
      },
    });
  };
}
