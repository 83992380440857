import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { WebAppGlobalState } from 'types';

import {
  cancelProTrial,
  startProTrial,
} from '@float/common/actions/companyPrefs';
import { calculatePrice } from '@float/common/lib/pricing';
import { getUser } from '@float/common/selectors/currentUser';
import { moment } from '@float/libs/moment';
import { AddOnStatus } from '@float/types';
import { updateBillingPlan } from '@float/web/settingsV2/actions/account/billingInfo';

import withOnMount from '../../../decorators/withOnMount';
import { AccountFeaturesModal } from './AccountFeaturesModal';
import { EnterprisePlan } from './EnterprisePlan';
import { isPlanEnterprise, isPlanPro, isPlanStarter } from './helpers';
import { ProPlan } from './ProPlan';
import { StarterPlan } from './StarterPlan';
import { StyledTeamAccountFeaturesWrapper } from './styles';

export type TeamAccountFeaturesProps = {
  updateBillingPlan: (formData: FormData) => void;
  startProTrial: () => void;
  cancelProTrial: () => void;
  billingInfo: {
    is_trial: boolean;
    plan: {
      is_monthly: boolean;
      plan_id: string;
      [key: string]: unknown;
    };
    [key: string]: unknown;
  };
  user: {
    time_tracking: number;
    plus_pack: number;
    plus_pack_end?: string;
    is_pro_trial: AddOnStatus | null;
    pro_trial_end: string | null;
    [key: string]: unknown;
  };
};

const TeamAccountFeaturesInner = ({
  billingInfo,
  user,
}: TeamAccountFeaturesProps) => {
  const {
    time_tracking: timeTracking,
    plus_pack: plusPack,
    is_pro_trial: isProTrial,
    pro_trial_end: proTrialEnd,
  } = user;

  const {
    is_trial: isTrialPlan,
    plan: { is_monthly: isMonthly, plan_id: planId },
  } = billingInfo;

  const hasTimeTracking = timeTracking > 0;
  const hasPlusPack = plusPack > 0;
  const hasBothAddons = hasTimeTracking && hasPlusPack;

  const canPaidCustomerTrialPro = Boolean(
    !isTrialPlan &&
      (isProTrial === AddOnStatus.NOT_ENABLED || isProTrial === null),
  );

  const isPaidCustomerTrialingPro = Boolean(
    !isTrialPlan && isProTrial === AddOnStatus.TRIAL && proTrialEnd,
  );

  const daysRemaining = isPaidCustomerTrialingPro
    ? moment(proTrialEnd).diff(moment(), 'day')
    : null;

  const pricing = calculatePrice({
    peopleLimit: 1,
    hasTimeTracking,
    hasPlusPack,
    isMonthly,
    plans: {}, // only calculates new plan prices
  });

  const isEnterprisePlan = isPlanEnterprise(
    planId,
    !isTrialPlan,
    hasBothAddons,
    !isMonthly,
  );
  const isProPlan = isPlanPro(
    planId,
    !isTrialPlan,
    timeTracking,
    plusPack,
    !isMonthly,
  );
  const isStarterPlan = isPlanStarter(
    planId,
    !isTrialPlan,
    timeTracking,
    plusPack,
  );

  const starterPrice = isMonthly
    ? pricing.perPersonMonthly
    : pricing.perPersonPerMonthAnnual;
  const proPrice = isMonthly
    ? pricing.perPersonMonthlyWithTimeTracking
    : pricing.perPersonPerMonthAnnualWithTimeTracking;
  const enterprisePrice = pricing.perPersonPerMonthAnnualEnterprise;

  const [modalOpened, setModalOpened] = useState<string | null>(null);

  const onClickCloseModal = useCallback(() => {
    setModalOpened(null);
  }, []);

  return (
    <StyledTeamAccountFeaturesWrapper>
      <AccountFeaturesModal
        modalOpened={modalOpened}
        onClose={onClickCloseModal}
        isMonthly={isMonthly}
      />
      <StarterPlan
        isActivePlan={isStarterPlan}
        isFreeTrial={isTrialPlan}
        isPaidTrial={isPaidCustomerTrialingPro}
        isHigherTierThanActivePlan={false}
        isLowerTierThanActivePlan={!isStarterPlan}
        price={starterPrice ?? '6'}
        openModal={setModalOpened}
      />
      <ProPlan
        canStartPaidTrial={canPaidCustomerTrialPro}
        daysRemaining={daysRemaining}
        isActivePlan={isProPlan}
        isFreeTrial={isTrialPlan}
        isPaidTrial={isPaidCustomerTrialingPro}
        isHigherTierThanActivePlan={isStarterPlan}
        isLowerTierThanActivePlan={isEnterprisePlan}
        price={proPrice ?? '10'}
        openModal={setModalOpened}
      />
      <EnterprisePlan
        isActivePlan={isEnterprisePlan}
        isFreeTrial={isTrialPlan}
        isHigherTierThanActivePlan={!isEnterprisePlan}
        isLowerTierThanActivePlan={false}
        price={enterprisePrice ?? '16'}
        openModal={setModalOpened}
      />
    </StyledTeamAccountFeaturesWrapper>
  );
};

const mapStateToProps = (state: WebAppGlobalState) => ({
  user: getUser(state),
  companyId: state.currentUser.cid,
  billingInfo: state.settingsBillingInfo,
});

const mapDispatchToProps = {
  startProTrial,
  cancelProTrial,
  updateBillingPlan,
};

export const TeamAccountFeatures = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOnMount(TeamAccountFeaturesInner));
