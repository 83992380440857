import {
  fetchCompanyPrefs,
  SET_PLUS_PACK,
  SET_TIME_TRACKING,
} from '@float/common/actions/companyPrefs';
import { getJWTAccessToken } from '@float/common/actions/jwt';
import { ensureViewsLoaded } from '@float/common/actions/views';
import API3 from '@float/common/api3';
import { moment } from '@float/libs/moment';

import { addedInvoice } from './invoices';

export const FETCH_BILLING_INFO = 'FETCH_BILLING_INFO';
export const FETCH_BILLING_INFO_SUCCESS = 'FETCH_BILLING_INFO_SUCCESS';
export const FETCH_BILLING_INFO_FAILURE = 'FETCH_BILLING_INFO_FAILURE';

export const UPDATE_BILLING_PLAN = 'UPDATE_BILLING_PLAN';
export const UPDATE_BILLING_PLAN_SUCCESS = 'UPDATE_BILLING_PLAN_SUCCESS';
export const UPDATE_BILLING_PLAN_FAILURE = 'UPDATE_BILLING_PLAN_FAILURE';

const fetchedBillingInfo = (json) => ({
  type: FETCH_BILLING_INFO_SUCCESS,
  payload: json,
});

const failedFetchingBillingInfo = (error) => ({
  type: FETCH_BILLING_INFO_FAILURE,
  error,
});

export const fetchBillingInfo = () => (dispatch, getState) => {
  const { isLoading, isLoaded } = getState().settingsBillingInfo;

  if (isLoading || isLoaded) return;

  dispatch({
    type: FETCH_BILLING_INFO,
  });
  return API3.getBillingInfo()
    .then((json) => {
      dispatch(fetchedBillingInfo(json));
    })
    .catch((error) => {
      dispatch(failedFetchingBillingInfo(error));
      // propagate error further
      throw new Error(`Failed fetching billing info: ${error}.`);
    });
};

const updatedBillingPlan = (json) => ({
  type: UPDATE_BILLING_PLAN_SUCCESS,
  payload: json,
});

const failedUpdatingBillingPlan = (error) => ({
  type: UPDATE_BILLING_PLAN_FAILURE,
  error,
});

export const updateBillingPlan = (data) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_BILLING_PLAN,
  });

  return API3.updateBillingPlan({ data })
    .then((json) => {
      if (
        data.get('Company[time_tracking]') !== null ||
        data.get('Company[plus_pack]') !== null
      ) {
        getJWTAccessToken(true)(dispatch, getState);
      }

      if (data.get('Company[time_tracking]') !== null) {
        dispatch({
          type: SET_TIME_TRACKING,
          time_tracking: Number(data.get('Company[time_tracking]')),
        });
      }

      if (data.get('Company[plus_pack]') !== null) {
        const action = {
          type: SET_PLUS_PACK,
          plus_pack: Number(data.get('Company[plus_pack]')),
        };

        // if starting trial of plus_pack set plus_pack_end on the client side for initial render
        // https://app.asana.com/0/0/1201343011582208/f
        if (action.plus_pack == 2) {
          action.plus_pack_end = moment().add(31, 'day').format('YYYY-MM-DD');
        }

        dispatch(action);
      }

      if (json.invoice) {
        dispatch(addedInvoice(json.invoice));
      }

      return Promise.all([
        dispatch(fetchCompanyPrefs()), // Updates the plan_type on company prefs
        dispatch(ensureViewsLoaded(true)), // refresh the Shared state according the current
        dispatch(updatedBillingPlan(json.billing_info)),
      ]);
    })
    .catch((error) => {
      dispatch(failedUpdatingBillingPlan(error));
      // propagate error further
      throw new Error(`Failed updating billing plan:`, error);
    });
};
