import {
  AutoLogTimeFrequency,
  AutoLogTimeSettings,
} from '@float/types/autoLogTime';

import { HttpError, makeRequest } from './makeRequest';

export type AutoLogTimeSettingsRaw = {
  active: 0 | 1; // Is Auto Log Time enabled?
  interval: number; // Integer – How many days after the end of the period is time auto-logged?
  frequency: AutoLogTimeFrequency; // At what frequency is time auto-logged?
};
export type AutoLogTimeSettingsGetResponse = [
  AutoLogTimeSettingsRaw,
  undefined,
  undefined,
];

// @test-export
export const sanitizeAutoLogTimeSettingsPayload = ({
  active,
  interval,
  frequency,
}: AutoLogTimeSettingsRaw): AutoLogTimeSettings => ({
  isActive: Boolean(active),
  interval,
  frequency,
});

// @test-export
export const sanitizeAutoLogTimeSettingsRequest = ({
  isActive,
  interval,
  frequency,
}: AutoLogTimeSettings): AutoLogTimeSettingsRaw => ({
  active: isActive ? 1 : 0,
  interval,
  frequency,
});

export const defaultInitialAutoLogTimeSettings = {
  isActive: false,
  frequency: AutoLogTimeFrequency.week,
  interval: 1,
};

export const autoLogTime = {
  getAutoLogTimeSettings: async () => {
    try {
      const [payload] = await makeRequest<AutoLogTimeSettingsGetResponse>({
        resource: 'auto-log-time',
        method: 'GET',
      });

      return sanitizeAutoLogTimeSettingsPayload(payload);
    } catch (err) {
      // non intialized auto-logtime returns 404
      if (err instanceof HttpError && err.status === 404) {
        return defaultInitialAutoLogTimeSettings;
      }

      throw err;
    }
  },

  postAutoLogTimeSettings: (settings: AutoLogTimeSettings) =>
    makeRequest<AutoLogTimeSettingsRaw, AutoLogTimeSettingsRaw>({
      data: sanitizeAutoLogTimeSettingsRequest(settings),
      resource: 'auto-log-time',
      method: 'POST',
    }).then(sanitizeAutoLogTimeSettingsPayload),
};
