import React from 'react';

export default function () {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.0909 5L11 14.8182V29.5455C11 43.1682 20.4255 55.9073 33.0909 59C45.7564 55.9073 55.1818 43.1682 55.1818 29.5455V14.8182L33.0909 5ZM33.0909 19.7273C36.5273 19.7273 39.9636 22.4273 39.9636 25.8636V29.5455C41.4364 29.5455 42.9091 31.0182 42.9091 32.7364V41.3273C42.9091 42.8 41.4364 44.2727 39.7182 44.2727H26.2182C24.7455 44.2727 23.2727 42.8 23.2727 41.0818V32.4909C23.2727 31.0182 24.7455 29.5455 26.2182 29.5455V25.8636C26.2182 22.4273 29.6545 19.7273 33.0909 19.7273ZM33.0909 22.6727C31.1273 22.6727 29.4091 23.9 29.4091 25.8636V29.5455H36.7727V25.8636C36.7727 23.9 35.0545 22.6727 33.0909 22.6727Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="13.2092"
          y1="4.66234"
          x2="58.3387"
          y2="53.6083"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7C71EC" />
          <stop offset="1" stopColor="#C17DCE" />
        </linearGradient>
      </defs>
    </svg>
  );
}
