import { getPercentage } from '@float/common/lib/budget/helpers/getPercentage';

import { RoleChildRow, RowData } from '../../types';

export function getBalanceRangeBar(o: { days: number; balance: number }) {
  return o.days > o.balance
    ? [{ color: 'red', value: 100 }]
    : [
        {
          color: 'teal',
          leftBorder: (o.days ?? 0) === 0,
          value: getPercentage(o.days, o.balance),
        },
        {
          color: 'grey',
          value: 100 - Number(getPercentage(o.days, o.balance)),
        },
      ];
}

export function getScheduledRangeBar(o: RowData | RoleChildRow) {
  return o.scheduled > o.capacity
    ? [
        { color: 'red', value: 100 },
        { divider: true, color: 'timeoff', value: 0 },
      ]
    : [
        { color: 'teal', value: o.billable - o.tentative.billable },
        {
          color: 'dark-checker',
          value: o.tentative.billable || 0,
        },
        { color: 'midTeal', value: o.nonbillable - o.tentative.nonbillable },
        {
          color: 'light-checker',
          value: o.tentative.nonbillable || 0,
        },
        {
          color: 'white',
          borderColor: 'grey',
          leftBorder: (o.scheduled ?? 0) === 0,
          value: o.capacity - o.scheduled,
        },
        { divider: true, color: 'timeoff', value: o.timeoff },
      ];
}

export function getLoggedRangeBar(o: RowData | RoleChildRow) {
  return o.logged.scheduled > o.capacity
    ? [
        { color: 'red', value: 100 },
        { divider: true, color: 'timeoff', value: 0 },
      ]
    : [
        { color: 'purple', value: o.logged.billable },
        { color: 'lightPurple', value: o.logged.nonbillable },
        {
          color: 'white',
          borderColor: 'grey',
          leftBorder: (o.logged.scheduled ?? 0) === 0,
          value: o.capacity - o.logged.scheduled,
        },
        { divider: true, color: 'timeoff', value: o.timeoff },
      ];
}

export function getCombinedRangeBar(o: RowData | RoleChildRow) {
  return o.combined.scheduled > o.capacity
    ? [
        { color: 'red', value: 100 },
        { divider: true, color: 'timeoff', value: 0 },
      ]
    : [
        { color: 'purple', value: o.logged.billable },
        { color: 'lightPurple', value: o.logged.nonbillable },
        {
          color: 'teal',
          value: o.future.billable - (o?.future?.tentative?.billable ?? 0),
        },
        {
          color: 'dark-checker',
          value: o.future?.tentative?.billable || 0,
        },
        {
          color: 'midTeal',
          value: o.future.nonbillable - (o.future?.tentative?.nonbillable ?? 0),
        },
        {
          color: 'light-checker',
          value: o?.future?.tentative?.nonbillable || 0,
        },
        {
          color: 'white',
          borderColor: 'grey',
          leftBorder:
            (o.scheduled ?? 0) === 0 || (o.logged.scheduled ?? 0) === 0,
          value: o.capacity - o.combined.scheduled,
        },
        { divider: true, color: 'timeoff', value: o.timeoff },
      ];
}
