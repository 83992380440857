import React from 'react';
import { t } from '@lingui/macro';
import cx from 'classnames';

import API3 from '@float/common/api3';

import SVGIllustration from './SvgIllustration';
import TeamExistHeader from './TeamExistHeader/TeamExistHeader';

class TeamExist extends React.Component {
  state = {
    email: this.props.email ?? '',
    password: '',
    error: null,
    loading: false,
  };

  componentDidMount() {
    // redirectToSubdomainIfNeeded('signup')
    this.isGoogle = !!+this.props.location.query.google;
  }

  onSet = (type, value) => {
    // On set email force to set the previous email.
    if ('email' == type && this.props.email !== value) {
      return this.setState({ [type]: this.props.email });
    }
    return this.setState({ [type]: value });
  };

  setBadCredsError = (errMessage) => {
    const error = {
      message: errMessage ?? 'Incorrect email or password.',
      targ: 'both',
    };
    this.setState({ loading: false });
    this.setState({ error });
  };

  validate = (email, password) => {
    const emailValidator = {
      re: /\S+@\S+\.\S+/,
      message: t`Email is not a valid email address.`,
    };

    if (!email || !emailValidator.re.test(email)) {
      const error = { message: emailValidator.message, targ: 'email' };
      this.setState({ error, loading: false });
      return false;
    } else if (!password) {
      const error = { message: 'Password is required', targ: 'password' };
      this.setState({ error, loading: false });
      return false;
    }
    return true;
  };

  loginAndJoin = async () => {
    this.setState({ loading: true });
    window.location.replace('/join/index?multi-account=1');
  };

  createTeam = async (evt) => {
    evt.preventDefault();
    this.setState({ loading: true });
    const { email, password } = this.state;

    if (this.validate(email, password)) {
      const result = this.isGoogle ? { google: 1 } : { email, password };
      try {
        const addToCompanyReply = await API3.addToCompany(result);
        if (addToCompanyReply.success) {
          this.loginAndJoin();
        } else if (addToCompanyReply.redirect) {
          window.location.replace(addToCompanyReply.redirect);
        } else if (addToCompanyReply.message) {
          this.setBadCredsError(addToCompanyReply.message);
        }
      } catch (e) {
        this.setBadCredsError();
      }
    }
  };

  render() {
    let fields = [];

    const { error } = this.state;

    // Redirect to JOIN page in case the email is not filled.
    if (!this.props.email) {
      window.location.replace('/join');
      return;
    }

    if (!this.isGoogle) {
      const emailField = (
        <div
          key="email"
          className={cx('field', {
            error: error && error.targ === 'email',
          })}
        >
          <label>Email</label>
          <div className="input-wrapper">
            <input
              autoFocus
              type="email"
              value={this.state.email}
              onChange={(evt) => this.onSet('email', evt.target.value)}
              readOnly
            />
            {error && error.targ === 'email' ? (
              <span className="error-message">{error.message}</span>
            ) : null}
          </div>
        </div>
      );

      const passwordField = (
        <div
          key="password"
          className={cx('field', {
            error: error && error.targ === 'password',
          })}
        >
          <label>Password</label>
          <div className="input-wrapper">
            <input
              type="password"
              value={this.state.password}
              onChange={(evt) => this.onSet('password', evt.target.value)}
            />
            {error && error.targ === 'password' ? (
              <span className="error-message">{error.message}</span>
            ) : null}
          </div>
        </div>
      );

      fields = fields.concat([emailField, passwordField]);
    }

    return (
      <>
        <TeamExistHeader />

        <section className="dialog">
          <form onSubmit={this.createTeam} className="auth-form">
            <h1>Welcome back!</h1>

            {error && error.targ === 'both' ? (
              <h3 className="global-error">{error.message}</h3>
            ) : null}

            <h3>
              We noticed you're already part of a Float team.
              <br />
              Are you trying to sign in to that team?{' '}
              <a
                className="button-text flay-underline"
                href="/login"
                title="Sign in"
                target="_self"
              >
                Sign in
              </a>
            </h3>
            <h3>
              <strong>Looking to create a new team?</strong>
              <br />
              {this.isGoogle ? '' : 'Enter your Float details...'}
            </h3>

            {fields}

            <button
              type="submit"
              className={cx('button', 'large-44')}
              onClick={this.createTeam}
            >
              Create new team{' '}
              {this.state.loading ? (
                <span
                  className="the-loader"
                  style={{
                    marginTop: '5px',
                  }}
                />
              ) : null}
            </button>
          </form>

          <form
            name="signupform"
            id="signupform"
            method="post"
            action="/join/index?multi-account=1"
          />
        </section>
        <SVGIllustration />
      </>
    );
  }
}

export default TeamExist;
