import { useEffect, useState } from 'react';

export const useIsTotalHoursInputSubdued = (numberOfAllocationDays: number) => {
  // We want to keep the enabled state, otherwise total input will become disabled when
  // user will type into total hours and the number of allocation days temporarily will become 1
  const [isTotalHoursInputRegular, setIsTotalHoursInputRegular] = useState(
    () => numberOfAllocationDays > 1,
  );

  // When number of allocation days becomes more than one day
  // We enable the input
  useEffect(() => {
    if (numberOfAllocationDays > 1 && !isTotalHoursInputRegular) {
      setIsTotalHoursInputRegular(true);
    }
  }, [
    numberOfAllocationDays,
    isTotalHoursInputRegular,
    setIsTotalHoursInputRegular,
  ]);

  const isTotalHoursInputSubdued = !isTotalHoursInputRegular;

  return isTotalHoursInputSubdued;
};
