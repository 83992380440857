import { css } from 'styled-components';

import * as Colors from '../Colors';

export const FOCUS_OUTLINE = css`
  box-shadow:
    0px 0px 0px 2.5px ${Colors.Primary.Flue.Light['6']},
    0px 0px 0px 1px #fff;
`;

export const VE_FOCUS_OUTLINE = {
  boxShadow: `0px 0px 0px 2.5px ${Colors.Primary.Flue.Light['6']}, 0px 0px 0px 1px #FFF;`,
};
