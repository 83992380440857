import React from 'react';
import { Trans } from '@lingui/macro';

import { Tag, TagProps } from '@float/ui/components/Tag';

export const ArchivedTag = React.forwardRef<HTMLSpanElement, TagProps>(
  (props) => {
    return (
      <Tag {...props} color="secondary">
        <Trans>Archived</Trans>
      </Tag>
    );
  },
);
