import { RowsPositionEntry } from '../types';

const ROW_OVERSCAN = 1;

export function createRangeGetter(
  rowsPositionList: RowsPositionEntry[],
  containerHeight: number,
) {
  return function getRowRange(scrollTop: number) {
    if (!containerHeight) {
      return {
        rowStart: 0,
        rowStop: 0,
      };
    }

    const bottom = scrollTop + containerHeight;

    // We want to find the first item above the scroll top
    const rowStart = Math.max(
      rowsPositionList.findIndex((cb) => cb.offset >= scrollTop) - 1,
      0,
    );

    // We want to find the first item below the viewport bottom
    let rowStop = rowsPositionList.findIndex(
      (cb, i) => i > rowStart && cb.offset >= bottom,
    );

    if (rowStop === -1) {
      rowStop = rowsPositionList.length;
    }

    return {
      rowStart: Math.max(0, rowStart - ROW_OVERSCAN),
      rowStop: Math.min(rowsPositionList.length, rowStop + ROW_OVERSCAN),
    };
  };
}
