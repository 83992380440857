import React from 'react';

export default function ({ size = '14', color = '#1A90DC' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 14 14"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M11 0c1.657 0 3 1.343 3 3v8c0 1.657-1.343 3-3 3H3c-1.657 0-3-1.343-3-3V3c0-1.657 1.343-3 3-3h8zm-1 3.5L5.5 8 4 6.5 2.5 8l3 3 6-6L10 3.5z"
      />
    </svg>
  );
}
