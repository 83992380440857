import { createTimerFromLiveUpdate } from '@float/common/actions/timer/createTimer';
import { deleteTimerFromLiveUpdate } from '@float/common/actions/timer/deleteTimer';
import { startTimerFromLiveUpdate } from '@float/common/actions/timer/startTimer';
import { stopTimerFromLiveUpdate } from '@float/common/actions/timer/stopTimer';
import { updateTimerFromLiveUpdate } from '@float/common/actions/timer/updateTimer';
import { AppDispatch } from '@float/common/store';
import { LiveUpdate } from '@float/types';

import { isTimerLiveUpdate } from './helpers';

export const handleTimerStartAndStopUpdates = ({
  data,
  dispatch,
}: {
  data: LiveUpdate;
  dispatch: AppDispatch;
}) => {
  if (isTimerLiveUpdate(data)) {
    if (data.event.type === 'start') {
      dispatch(startTimerFromLiveUpdate(data.event.payload));
      return;
    }

    if (data.event.type === 'stop') {
      dispatch(stopTimerFromLiveUpdate(data.event.payload));
    }
  }
};

export const handleTimerAddAndDeleteUpdates = ({
  data,
  dispatch,
}: {
  data: LiveUpdate;
  dispatch: AppDispatch;
}) => {
  if (isTimerLiveUpdate(data)) {
    if (data.event.type === 'create') {
      dispatch(createTimerFromLiveUpdate(data.event.payload));
      return;
    }

    if (data.event.type === 'delete') {
      dispatch(deleteTimerFromLiveUpdate(data.event.payload._id));
    }
  }
};

export const handleTimerUpdateUpdates = ({
  data,
  dispatch,
}: {
  data: LiveUpdate;
  dispatch: AppDispatch;
}) => {
  if (isTimerLiveUpdate(data)) {
    if (data.event.type === 'edit') {
      dispatch(updateTimerFromLiveUpdate(data.event.payload));
    }
  }
};
