import {
  PUBLIC_HOLIDAYS_LOAD_FAILURE,
  PUBLIC_HOLIDAYS_LOAD_START,
  PUBLIC_HOLIDAYS_LOAD_SUCCESS,
} from '@float/common/actions/settings-legacy';
import API3 from '@float/common/api3';
import { mapHolidayV1ToV3 } from '@float/common/lib/formatters';

import {
  failedFetchingEntitiesCreator,
  fetchedEntitiesCreator,
  fetchEntitiesCreator,
} from './creatorHelpers';
import { addedHoliday } from './holidays';

// for settings reducer
export const FETCH_PUBLIC_HOLIDAYS = 'FETCH_PUBLIC_HOLIDAYS';
export const FETCH_PUBLIC_HOLIDAYS_SUCCESS = 'FETCH_PUBLIC_HOLIDAYS_SUCCESS';
export const FETCH_PUBLIC_HOLIDAYS_FAILURE = 'FETCH_PUBLIC_HOLIDAYS_FAILURE';

// for publicHolidays reducer

const PLURAL = 'publicHolidays';

const fetchedPublicHolidays = fetchedEntitiesCreator(
  FETCH_PUBLIC_HOLIDAYS_SUCCESS,
  (entities, getState, page, queryParams) =>
    entities.map((entity) => ({
      ...entity,
      // create a unique id for these records
      holiday_id: `${Object.values(queryParams).join('_')}_${entity.date}_${
        entity.label
      }`,
      // include params like calendar and year as entity attributes
      // as server does not return them
      ...queryParams,
    })),
);

const failedFetchingPublicHolidays = failedFetchingEntitiesCreator(
  FETCH_PUBLIC_HOLIDAYS_FAILURE,
);

const shouldFetchPublicHolidays = (state, page, queryParams) =>
  !state.entities.find(
    (entity) =>
      entity.country == queryParams.country && entity.year == queryParams.year,
  );

export const fetchPublicHolidays = fetchEntitiesCreator(
  FETCH_PUBLIC_HOLIDAYS,
  PLURAL,
  shouldFetchPublicHolidays,
  fetchedPublicHolidays,
  failedFetchingPublicHolidays,
);

export const importPublicHolidays = (holidays) => {
  return (dispatch) => {
    const data = new FormData();
    holidays.forEach((holiday) => {
      data.append('dates[]', holiday.date);
      data.append('names[]', holiday.label);
    });
    return API3.importPublicHolidays({ data })
      .then((json) => {
        json.holidays
          .map(mapHolidayV1ToV3)
          .forEach((holiday) => dispatch(addedHoliday(holiday)));
      })
      .catch((e) => {
        console.error('importPublicHolidays', e);
        throw e;
      });
  };
};

export const ensurePublicHolidaysLoaded = (forceLoad = false) => {
  return async (dispatch, getState) => {
    const { loadState: currentLoadState } = getState().publicHolidays;

    if (!forceLoad && currentLoadState === 'LOADED') return;
    if (currentLoadState === 'LOADING') return; // There's already an in-flight load request

    try {
      dispatch({ type: PUBLIC_HOLIDAYS_LOAD_START });
      const response = await API3.getPublicHolidays();
      dispatch({ type: PUBLIC_HOLIDAYS_LOAD_SUCCESS, response });
    } catch (e) {
      console.error(e);
      dispatch({ type: PUBLIC_HOLIDAYS_LOAD_FAILURE });
    }
  };
};
