import React, { ForwardedRef } from 'react';

import BaseIcon, { BaseIconProps } from './BaseIcon';

export default React.forwardRef(
  (
    props: BaseIconProps & { showIndicator?: boolean },
    ref: ForwardedRef<SVGSVGElement>,
  ) => {
    const { showIndicator, ...rest } = props;
    return (
      <BaseIcon {...rest} ref={ref} viewBox="0 0 32 32">
        <path
          d="M23 16V13.3334C23 9.46738 19.866 6.33337 16 6.33337C12.134 6.33337 8.99998 9.46738 8.99998 13.3334V16L6.33331 21.6667H25.6666L23 16Z"
          stroke="#344765"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 22.3334C12 22.3334 12 25.6667 16 25.6667C20 25.6667 20 22.3334 20 22.3334"
          stroke="#344765"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        {showIndicator && (
          <g transform="matrix(0.795438,0,0,0.795438,4.90949,1.6365)">
            <circle cx="24" cy="8" r="5" fill={'rgb(255,202,105)'} />
          </g>
        )}
      </BaseIcon>
    );
  },
);
