import { createSelector } from 'reselect';

import { ReduxStateStrict } from '../reducers/lib/types';

export const getCompanyPrefs = (state: ReduxStateStrict) => state.companyPrefs;

export const selectCompanyHostName = (state: ReduxStateStrict) =>
  state.companyPrefs.company_hostname;

export const isPaidPlan = createSelector([getCompanyPrefs], (companyPrefs) => {
  return Boolean(companyPrefs.is_paid_plan);
});
export const isTimeTrackingEnabled = createSelector(
  [getCompanyPrefs],
  (companyPrefs) => {
    return Number(companyPrefs.time_tracking) > 0;
  },
);
export const isPlusPackEnabled = createSelector(
  [getCompanyPrefs],
  (companyPrefs) => {
    return Number(companyPrefs.plus_pack) > 0;
  },
);

const getCurrentPlanLevel = (state: ReduxStateStrict) =>
  state.companyPrefs.plan_level;

export const getIsStarterPlan = (state: ReduxStateStrict) =>
  getCurrentPlanLevel(state) === 'starter';
