import React, { useRef } from 'react';
import styled from 'styled-components';

import { Button, Input, useSnackbar } from '@float/ui/deprecated';

import { SectionContent, SectionHeading } from './Integrations.styles';

const IcalInputContainer = styled.div`
  margin: 20px 0 30px;
  display: flex;

  ${Input._styles.FieldWrapper} {
    flex-grow: 1;
  }
`;

export default function IntegrationIcal({ ical }) {
  const { showSnackbar } = useSnackbar();
  const el = useRef(null);

  if (!ical) {
    return null;
  }

  const copyToClipboard = () => {
    const node = el.current && el.current.input;
    if (!node) {
      return;
    }

    node.select();
    document.execCommand('copy');
    showSnackbar('iCal schedule link copied to clipboard.');
  };

  return (
    <>
      <SectionHeading>Using another calendar?</SectionHeading>
      <SectionContent>
        Send your Float tasks to your favorite calendar app using iCal.
        <br />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://support.float.com/account-settings/third-party-integrations/subscribe-to-ical"
        >
          Learn more
        </a>
        .
        <IcalInputContainer>
          <Input ref={el} value={ical} readOnly noPointerEvents />
          {document.queryCommandSupported &&
            document.queryCommandSupported('copy') && (
              <Button
                appearance="clear"
                title="Copy link"
                onClick={copyToClipboard}
                style={{
                  marginLeft: '10px',
                }}
              >
                Copy
              </Button>
            )}
        </IcalInputContainer>
      </SectionContent>
    </>
  );
}
