import React from 'react';

export default function ({ size = '24', color = '#c8c8c8', style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      style={style}
    >
      <g transform="translate(10.000000, 4.000000)" fill={color}>
        <circle cx="2" cy="8" r="2" />
        <circle cx="2" cy="2" r="2" />
        <circle cx="2" cy="14" r="2" />
      </g>
    </svg>
  );
}
