/**
 * Takes a color in RGBA format and calculates the full opaque
 * RGB version of the original color with the correspondent
 * transparency as if it was on top of a white background.
 *
 * @param {number} r The Red value
 * @param {number} g The Green value
 * @param {number} b The Blue value
 * @param {number} a The Alpha value
 *
 * @returns {Array} The RGB value of the new color
 *
 * @example
 * // returns [154, 168, 217]
 * fakeOpacity(52, 81, 178, 0.5)
 *
 * @example
 * // returns [52, 81, 178]
 * fakeOpacity(52, 81, 178, 1)
 */

export function fakeOpacity(r, g, b, a) {
  return [
    Math.round((1 - a) * 255 + a * r),
    Math.round((1 - a) * 255 + a * g),
    Math.round((1 - a) * 255 + a * b),
  ];
}
