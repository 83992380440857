import { css } from 'styled-components';

export const PrintStyle = css`
  @page {
    size: landscape;
    margin: 0.5cm;
  }

  @media print {
    * {
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
      color-adjust: exact;
    }
    html {
      overflow: auto !important;
      background: #00f;
    }
    html,
    body {
      max-width: 100%;
      height: 100%;
      background-color: #fff;
      overflow: visible;
    }
    html,
    body,
    .app.content-wrapper {
      height: initial !important;
      position: relative;
    }
    .app-wrapper {
      background-color: #fff;
    }
    .app-inner {
      position: static !important;
    }
    .canvalendar-controls {
      display: none !important;
    }
    #canv-prevs {
      background-color: #fff;
    }
    .my-schedule #canv-prevs {
      margin-top: 0px !important;
      top: 80px !important;
      position: fixed !important;
    }
    .global-header {
      background-color: transparent !important;
    }
    .global-header h1,
    .global-header h1 a {
      color: #000 !important;
      font-size: 20px;
    }
    .token-search {
      padding-top: 10px;
      height: 40px;
    }
    .token-search-wrap {
      margin-left: 0 !important;
    }
    .token-search-wrap .schedule-search {
      display: none;
    }
    #show-more,
    #columnTitlesWrapper,
    #header-nav,
    .popover,
    .popup,
    .clear-search,
    .context-menu-list,
    #popupBackground {
      display: none;
    }
    .toolbar {
      background-color: #fff;
    }
    .content-wrapper {
      width: 100%;
      page-break-after: always;
      position: relative;
    }
    .grid-head .flexible-container {
      width: 100% !important;
      display: flex !important;
      justify-content: space-around !important;
      align-items: center !important;
    }
    .grid-head .flexible-container .right:not(.reports-table-column) {
      display: none !important;
    }
    .grid-body.flexible-container {
      width: 100% !important;
    }
    .project-cards,
    .people-cards {
      width: 100% !important;
      outline: none;
    }
    .project-cards > .card-item,
    .people-cards > .card-item {
      width: calc(25% - 10px) !important;
    }
    .project-cards .card-item,
    .people-cards .card-item {
      vertical-align: top !important;
      page-break-inside: avoid;
      -webkit-region-break-inside: avoid;
      region-break-inside: avoid;
      min-height: 104px;
      margin-bottom: 10px !important;
    }
    .app .scrollable {
      position: initial;
      overflow: visible;
    }
    .app .scrollable .right:not(.reports-table-column),
    .app .scrollable #multi-selection-options .dropdown {
      display: none;
    }
    .canvas-scroll-wrapper {
      display: none;
    }
    .canvas-preview,
    .reports-table-row,
    .reports-table-body {
      page-break-inside: avoid;
    }
  }
`;
