import React from 'react';
import {
  getAccounts,
  getAccurateNameByAccountId,
  getDirectManagersByPeopleId,
  getUser,
} from 'selectors';
import styled from 'styled-components';

import { getUserAccess } from '@float/common/lib/rights';
import { useAppSelector } from '@float/common/store';

const Wrapper = styled.div`
  font-size: 14px;
  margin-bottom: 14px;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

export const RequestManagersLabel = ({ peopleId }: { peopleId: number }) => {
  const accountsMap = useAppSelector(getAccounts);
  const user = useAppSelector(getUser);
  const access = getUserAccess(user);

  const accountNameByAccountId = useAppSelector(getAccurateNameByAccountId);
  const directManagers = useAppSelector(getDirectManagersByPeopleId) as Record<
    number,
    number[]
  >;
  const managers: number[] = directManagers[peopleId] ?? [];

  const managersName = managers
    .filter(
      (managerId) => !!accountsMap[managerId] && managerId !== user.account_id,
    )
    .map((managerId) => accountNameByAccountId[managerId]);

  if (!managers.length) {
    if (access.isMember()) {
      return (
        <Wrapper>Your request will need to be approved by an Admin</Wrapper>
      );
    }
    return null;
  }

  const shouldHideExtraPeople = managersName.length > 3;
  const managersText = shouldHideExtraPeople
    ? `${managersName[0]}, ${managersName[1]} and ${
        managersName.length - 2
      } more`
    : managersName.join(', ');
  return (
    <Wrapper>
      Your request will be to sent to your manager
      {managersName.length > 1 ? 's' : ''} <BoldText>{managersText}</BoldText>
    </Wrapper>
  );
};
