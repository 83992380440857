import { createSelector } from 'reselect';

import { Project, SearchTask, Task, TaskStatusEnum } from '@float/types';

import { ReduxState } from '../../reducers/lib/types';
import { getIsProjectPlanView } from '../appInfo/scheduleView';
import { getProjectsMap } from '../projects';
import { getTasksList } from './getTasksList';

// OPTIMIZATION: By removing the search tasks from the task list
// the app logic will handle only the tasks related to the current range
export const getFullTasksList = createSelector(
  [getTasksList],
  (tasks) => tasks.filter((task) => task.data_type === 'full') as Task[],
);

export const getFullTasksMap = createSelector(
  [getFullTasksList, getIsProjectPlanView],
  (tasks, isProjectPlanView) => {
    const tasksMap: Record<string, Task> = Object.create(null);

    for (const task of tasks) {
      if (task.status === TaskStatusEnum.Draft && !isProjectPlanView) continue;

      tasksMap[task.task_id] = task;
    }

    return tasksMap;
  },
);

export const getTasksMap = createSelector(
  [getTasksList, getProjectsMap],
  (tasks, projects) => {
    const tasksMap: Record<
      string,
      (Task & { project: Project }) | (SearchTask & { project: Project })
    > = Object.create(null);

    for (const task of tasks) {
      tasksMap[task.task_id] = {
        ...task,
        project: projects[task.project_id] || {},
      };
    }

    return tasksMap;
  },
);

export const getIsScheduleLoading = (state: ReduxState) =>
  state.search.contextState !== 'LOADED' ||
  state.people.loadState !== 'LOADED' ||
  state.tasks.isLoading ||
  state.timeoffs.isLoading ||
  state.oneOffs.loadState !== 'LOADED';
