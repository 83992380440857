import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon {...props} viewBox="0 0 24 24">
    <path
      d="M12 9.75V13L14.25 15.25"
      stroke="#242C39"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.25 13C19.25 17.0041 16.0041 20.25 12 20.25C7.99594 20.25 4.75 17.0041 4.75 13C4.75 8.99594 7.99594 5.75 12 5.75C16.0041 5.75 19.25 8.99594 19.25 13Z"
      stroke="#344765"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 5V3.25M12 3.25H9.75M12 3.25H14.25"
      stroke="#344765"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </BaseIcon>
);
