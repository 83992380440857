import styled, { css } from 'styled-components';

import { StyledAvatar } from '@float/common/components/Avatar/styles';
import AvatarGroup from '@float/common/components/AvatarGroup';
import { Button } from '@float/ui/deprecated';
import { IconButton } from '@float/ui/deprecated/DateRangePicker/DateRangePicker';
import * as Animation from '@float/ui/deprecated/Earhart/Animation';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Elevations from '@float/ui/deprecated/Earhart/Elevations';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

import Table from '../Table';
import { StyledNoRows, StyledRow } from '../Table/styles';

export const MainFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;

  width: 100%;

  margin-top: 30px;
  margin-bottom: 30px;

  ${IconButton} {
    width: 34px;
    height: 34px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

export const DropdownFilters = styled(MainFilters)`
  ${Elevations.E300};

  display: ${(props) => (props.hide ? 'none' : 'flex')};

  width: auto;

  border-width: 1px;
  border-style: solid;
  border-color: ${Colors.FIN.Lt.Stroke.Stroke2};
  border-radius: 6px;

  background-color: ${Colors.FIN.Lt.Surface.Surf1};

  padding: 32px;

  margin-bottom: 40px;

  & > *:not(:last-child) {
    flex: 0.25;
  }

  > * + * {
    margin-left: 16px;
  }
`;

export const CustomButton = styled(Button)`
  ${(props) => props.floatRight && `float: right;`}
`;

export const StyledActionedByAvatar = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  padding-left: 30px;

  > *:first-child {
    position: absolute;

    top: 0;
    left: 0;
  }
`;

export const StyledPersonAvatar = styled.div`
  position: relative;

  display: inline-flex;

  margin-left: 4px;
  margin-right: 6px;

  vertical-align: bottom;

  cursor: ${({ onClick }) => (onClick ? 'pointer' : null)};

  ${StyledAvatar} {
    pointer-events: none;

    transform: translateZ(0);

    &:after {
      --border-size: 1px;

      position: absolute;

      content: '';

      top: calc(var(--border-size) * -1);
      left: calc(var(--border-size) * -1);

      width: calc(100% + (var(--border-size) * 2));
      height: calc(100% + (var(--border-size) * 2));

      border-width: var(--border-size);
      border-style: solid;
      border-color: ${Colors.Primary.Flue.Light[9]};
      border-radius: 50%;

      box-sizing: border-box;

      transform: scale(0.8);

      transition-property: transform;
      transition-duration: ${Animation.Settings.Duration.Normal};
      transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

      z-index: -1;
    }
  }

  ${({ onClick }) =>
    onClick &&
    css`
      &:hover {
        ${StyledAvatar} {
          &:after {
            transform: none;
          }
        }
      }
    `}
`;

export const StyledIcon = styled.span`
  --svg-icon-color: ${Colors.FIN.Lt.Emphasis.High};

  font-weight: 500;

  svg {
    margin: 0 6px;

    vertical-align: bottom;
  }
`;

export const StyledCalendarIcon = styled(StyledIcon)`
  margin-left: 4px;
`;

export const StyledTable = styled(Table)`
  &:first-child {
    margin-top: 24px;
  }

  tr {
    &${StyledRow} {
      td {
        padding-right: 10px;

        white-space: nowrap;

        &:nth-child(3) {
          white-space: normal;
        }
      }
    }

    &:not(${StyledNoRows}) {
      td {
        vertical-align: top;

        color: ${Colors.FIN.Lt.Emphasis.High};

        &:nth-child(1) {
          color: ${Colors.FIN.Lt.Emphasis.Medium};
        }

        &:not(:nth-child(1)),
        &:not(:nth-child(2)) {
          ${Typography.TextM.R400};
        }

        &:nth-child(1),
        &:nth-child(2) {
          ${Typography.TextM.M500};
        }
      }
    }
  }

  a {
    font-weight: 500;

    color: ${Colors.FIN.Lt.Emphasis.High};

    &:hover {
      color: ${Colors.FIN.Lt.Emphasis.Medium};

      text-decoration: none;
    }
  }
`;

export const StyledActivityAvatarGroup = styled(AvatarGroup)`
  margin-left: 4px;
  margin-right: 6px;

  vertical-align: bottom;
`;
