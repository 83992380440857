import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';

import * as Animation from '../../Animation';
import * as Typography from '../../Typography';

const styles = css`
  display: inline-flex;
  align-items: center;
  justify-content: ${({ alignment }) => {
    if (alignment === 'left') return 'flex-start';
    if (alignment === 'center') return 'center';
    if (alignment === 'right') return 'flex-end';
  }};

  border-radius: 5px;

  text-align: center;

  transition-property: background-color, color;
  transition-duration: ${Animation.Settings.Duration.Short};
  transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

  //
  // Appearance
  //

  // Primary
  &.primary {
    color: ${Colors.FIN.Lt.Emphasis.White};
    background-color: ${Colors.FIN.Lt.Buttons.Primary.Default.Bg};

    > svg {
      --svg-icon-color: ${Colors.FIN.Lt.Emphasis.White};
    }

    &:hover {
      background-color: ${Colors.FIN.Lt.Buttons.Primary.Hover.Bg};
    }

    &:active,
    &.active {
      background-color: ${Colors.FIN.Lt.Buttons.Primary.Pressed.Bg};
    }

    &.disabled {
      color: ${Colors.FIN.Lt.Buttons.Primary.Disabled.Label};
      background-color: ${Colors.FIN.Lt.Buttons.Primary.Disabled.Bg};

      cursor: not-allowed;

      > svg {
        --svg-icon-color: ${Colors.FIN.Lt.Buttons.Primary.Disabled.Label};
      }

      &:hover {
        background-color: ${Colors.FIN.Lt.Buttons.Primary.Disabled.Bg};
      }
    }
  }

  // Secondary
  &.secondary {
    color: ${Colors.FIN.Lt.Buttons.Secondary.Default.Label};
    background-color: ${Colors.FIN.Lt.Buttons.Secondary.Default.Bg};

    > svg {
      --svg-icon-color: ${Colors.FIN.Lt.Buttons.Secondary.Default.Label};
    }

    &:hover {
      background-color: ${Colors.FIN.Lt.Buttons.Secondary.Hover.Bg};
    }

    &:active,
    &.active {
      background-color: ${Colors.FIN.Lt.Buttons.Secondary.Pressed.Bg};
    }

    &.disabled {
      color: ${Colors.FIN.Lt.Buttons.Secondary.Disabled.Label};
      background-color: ${Colors.FIN.Lt.Buttons.Secondary.Disabled.Bg};

      cursor: not-allowed;

      > svg {
        --svg-icon-color: ${Colors.FIN.Lt.Buttons.Secondary.Disabled.Label};
      }

      &:hover {
        background-color: ${Colors.FIN.Lt.Buttons.Secondary.Disabled.Bg};
      }
    }
  }

  // Danger
  &.danger {
    color: ${Colors.FIN.Lt.Buttons.Danger.Default.Label};
    background-color: ${Colors.FIN.Lt.Danger.Default};

    > svg {
      --svg-icon-color: ${Colors.FIN.Lt.Buttons.Danger.Default.Label};
    }

    &:hover {
      background-color: ${Colors.FIN.Lt.Buttons.Danger.Hover.Bg};
    }

    &:active,
    &.active {
      background-color: ${Colors.FIN.Lt.Buttons.Danger.Pressed.Bg};
    }

    &.disabled {
      color: ${Colors.FIN.Lt.Buttons.Danger.Disabled.Label};
      background-color: ${Colors.FIN.Lt.Buttons.Danger.Disabled.Bg};

      cursor: not-allowed;

      > svg {
        --svg-icon-color: ${Colors.FIN.Lt.Buttons.Danger.Disabled.Label};
      }

      &:hover {
        background-color: ${Colors.FIN.Lt.Buttons.Danger.Disabled.Bg};
      }
    }
  }

  > svg {
    path {
      transition-property: stroke, fill;
      transition-duration: ${Animation.Settings.Duration.Short};
      transition-timing-function: ${Animation.Settings.Easing.InOutCurve};
    }
  }

  > svg + span {
    margin-left: 8px;
    margin-right: 6px;

    &:empty {
      display: none;
    }
  }

  ${({ trailingIcon }) =>
    // Trailing icon
    trailingIcon &&
    css`
      flex-direction: row-reverse;

      > svg + span:not(:empty) {
        margin-right: 8px;
        margin-left: 6px;
      }
    `};

  ${({ size }) =>
    // Size is Small
    size === 'small' &&
    css`
      ${Typography.Label12.SB600};

      padding: 8px 12px;
    `};

  ${({ size }) =>
    // Size is Medium
    size === 'medium' &&
    css`
      ${Typography.Label14.SB600};

      padding: 10px 16px;
    `}

  ${({ size }) =>
    // Size is Large
    size === 'large' &&
    css`
      ${Typography.Label16.SB600};

      padding: 12px 18px;
    `};
`;

export const StyledLink = styled(Link)`
  ${styles}
`;
export const StyledButton = styled.button`
  ${styles}
`;
export const StyledAnchor = styled.a`
  ${styles}
`;
