import React from 'react';

import { TextToggle } from '@float/ui/deprecated/Earhart/Toggles';

const options = [
  { value: 0, label: 'Show weekends' },
  { value: 1, label: "Don't show weekends" },
];

export default function ShowWeekendsField({ fieldLink }) {
  return (
    <TextToggle
      options={options}
      value={fieldLink.value}
      toggle
      onChange={({ value }) => {
        fieldLink.onChange({ target: { value } });
      }}
    />
  );
}
