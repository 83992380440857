import { RumEventCallback } from './types';

const trackList = [
  { name: 'projects-api', pattern: '/api3/v3/projects' },
  { name: 'search-api', pattern: '/svc/search/search' },
];

export function trackResourceTimings(callback: RumEventCallback) {
  try {
    const po = new PerformanceObserver((result) => {
      for (const entry of result.getEntries() as PerformanceResourceTiming[]) {
        const item = trackList.find((item) =>
          entry.name.includes(item.pattern),
        );

        if (item) {
          const value = entry.responseEnd - entry.fetchStart;

          callback({
            name: `${item.name}:response-time`,
            value,
            delta: value,
          });
        }
      }
    });

    po.observe({
      type: 'resource',
    });

    // unsubscribe
    return () => {
      po.disconnect();
    };
  } catch (err) {
    // Safari doesn't like type: 'resource' https://rollbar.com/float/fe-web-app/items/5083/
    return () => {};
  }
}
