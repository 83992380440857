import { useCallback } from 'react';

import { PhaseFormData } from '@float/types/phase';

import { usePhaseInfoUpdateHandler } from './usePhaseInfoUpdateHandler';
import { usePhaseTeamUpdateHandler } from './usePhaseTeamUpdateHandler';

export function usePhaseDetailUpdateHandler(phaseRowIndex: number) {
  const handlePhaseInfoUpdate = usePhaseInfoUpdateHandler();
  const handlePhaseTeamUpdate = usePhaseTeamUpdateHandler();

  const handlePhaseDetailUpdate = useCallback(
    (data: PhaseFormData) => {
      handlePhaseInfoUpdate(phaseRowIndex, data);
      handlePhaseTeamUpdate();
    },
    [phaseRowIndex, handlePhaseInfoUpdate, handlePhaseTeamUpdate],
  );

  return handlePhaseDetailUpdate;
}
