import { MouseEvent, useEffect, useReducer, useState } from 'react';

import { Timer } from '@float/types';

import { ViewMoreActionType, viewMoreReducer } from './reducer';

export type TimersWithViewMoreControllerProps = {
  timers: Timer[];
  maxVisible: number;
  firstItem?: Element | null;
};

export const useTimersWithViewMoreController = ({
  timers,
  maxVisible,
  firstItem,
}: TimersWithViewMoreControllerProps) => {
  const [wrapperHeight, setWrapperHeight] = useState(0);

  const [state, dispatch] = useReducer(viewMoreReducer, {
    animating: false,
    closed: true,
    open: false,
  });

  const hiddenTimersCount = timers.length - maxVisible;

  const visibleTimersCount = state.open
    ? timers.length
    : Math.min(maxVisible, timers.length);

  useEffect(() => {
    if (firstItem) {
      const itemHeight = firstItem.getBoundingClientRect().height;
      const height = itemHeight + (1 + itemHeight) * (visibleTimersCount - 1);

      setWrapperHeight(height);

      dispatch({ type: ViewMoreActionType.TRANSITION_FINISHED });
    } else {
      setWrapperHeight(0);
    }
  }, [firstItem, visibleTimersCount]);

  const onClickViewMore = (e: MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.blur();

    dispatch({ type: ViewMoreActionType.TOGGLE });
  };

  return {
    wrapperHeight,
    onClickViewMore,
    hiddenTimersCount,
    visibleTimersCount,
    state,
  };
};
