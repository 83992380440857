import { keyBy } from 'lodash';
import { createSelector } from 'reselect';

import { ReduxStateStrict } from '../reducers/lib/types';

export const getDepartments = (state: ReduxStateStrict) =>
  state.departments.departments;

export const getDepartmentsByName = createSelector(
  [getDepartments],
  (departments) => {
    return keyBy(Object.values(departments), 'name');
  },
);

export const getSubdepartments = createSelector(
  [getDepartments],
  (departments) => {
    const subDepartments: Record<number, number[]> = {};

    Object.values(departments).forEach((department) => {
      if (!department) return;

      if (!subDepartments[department.id]) {
        subDepartments[department.id] = [];
      }

      const parentDep =
        department.parent_id && departments[department.parent_id];
      if (parentDep) {
        if (!subDepartments[parentDep.id]) {
          subDepartments[parentDep.id] = [];
        }

        subDepartments[parentDep.id].push(department.id);
      }
    });

    return subDepartments;
  },
);

export const getParentDepartments = createSelector(
  [getSubdepartments],
  (subDepartments) => {
    return Object.entries(subDepartments).reduce(
      (acc, [departmentId, subDepartmentIds]) => {
        subDepartmentIds.forEach((subDepId) => {
          if (!acc[subDepId]) acc[subDepId] = [];

          const alreadyAdded = acc[subDepId].includes(Number(departmentId));
          if (!alreadyAdded) {
            acc[subDepId].push(Number(departmentId));
          }
        });
        return acc;
      },
      {} as Record<number, number[]>,
    );
  },
);
