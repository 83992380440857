import { t } from '@lingui/macro';

type CurrenciesFromAPI = {
  [isoCode: string]: {
    name: string;
    symbol: string;
  };
};

export const NO_CURRENCY_ISO_CODE = 'XXX';

export const currencies = {
  getCurrencies: async () => {
    const res = await fetch('/static/currency.json');
    const currenciesFromAPI: CurrenciesFromAPI = await res.json();

    const currencies = Object.entries(currenciesFromAPI).map(
      ([isoCode, { name, symbol }]) => {
        const isNoCurrency = isoCode === NO_CURRENCY_ISO_CODE;

        const label = isNoCurrency ? t`No Currency` : `${isoCode} - ${name}`;

        return {
          currency_id: isoCode,
          label,
          symbol,
        };
      },
    );

    return [currencies];
  },
};
