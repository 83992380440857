import { Rights } from '@float/common/lib/acl';
import { CurrentUser, Person, Timeoff, TimeoffType } from '@float/types';

export function canDeleteTimeoff(
  user: CurrentUser,
  timeoff: Timeoff,
  ctx: {
    people: Record<number, Person>;
    timeoffTypes: Record<number, TimeoffType>;
  },
) {
  const people = timeoff.people_ids
    .map((id) => ctx.people[id])
    .filter(Boolean) as Person[];

  const timeoffType = ctx.timeoffTypes[timeoff.timeoff_type_id];

  return Rights.canDeleteTimeoff(user, {
    entity: timeoff,
    people,
    timeoffType,
  });
}
