import React from 'react';

export default () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 18.0208V21L23.0625 23.0625"
      className="stroke"
      stroke="#344765"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.6458 21C27.6458 24.6704 24.6704 27.6458 21 27.6458C17.3296 27.6458 14.3541 24.6704 14.3541 21C14.3541 17.3296 17.3296 14.3542 21 14.3542C24.6704 14.3542 27.6458 17.3296 27.6458 21Z"
      className="stroke"
      stroke="#344765"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 14H10"
      className="stroke"
      stroke="#344765"
      strokeWidth="3.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 7L22 7"
      className="stroke"
      stroke="#344765"
      strokeWidth="3.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
