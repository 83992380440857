import useEvent from 'react-use/esm/useEvent';

export function usePrintStylesOverride<E>(
  ref: { current: E },
  applyStyle: (el: NonNullable<E>) => void,
  removeStyle: (el: NonNullable<E>) => void,
) {
  useEvent('beforeprint', () => {
    const el = ref.current;

    if (!el) return;

    applyStyle(el);
  });

  useEvent('afterprint', () => {
    const el = ref.current;

    if (!el) return;

    removeStyle(el);
  });
}
