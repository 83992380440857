import React, { ComponentPropsWithRef } from 'react';
import cn from 'classnames';

import { List } from '@float/ui/primitives/List';

import * as styles from './styles.css';

const SeparatorItem = (props: ComponentPropsWithRef<'div'>) => {
  const { className, ...rest } = props;

  return (
    <List.Separator className={cn(styles.separator, className)} {...rest} />
  );
};

export { SeparatorItem };
