import React from 'react';
import { t } from '@lingui/macro';

import { CurrencyInputField } from '../components/CurrencyInputField';
import { ProjectFormData } from '../types';

export const ProjectTeamMemberRateField = (props: {
  name: string;
  label: string;
  disabled: boolean;
}) => {
  return (
    <CurrencyInputField
      name={props.name as keyof ProjectFormData}
      label={props.label}
      hideLabel
      placeholder={t`$0`}
      disabled={props.disabled}
    />
  );
};
