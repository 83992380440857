import { useCallback, useState } from 'react';

import api3 from '@float/common/api3';
import { defaultInitialAutoLogTimeSettings } from '@float/common/api3/autoLogTime';
import {
  AutoLogTimeFrequency,
  AutoLogTimeSettings,
} from '@float/types/autoLogTime';
import { LockLoggedTimeSettings } from '@float/types/lockLoggedTime';
import { IconWarningTriangle } from '@float/ui/deprecated/Earhart/Icons';
import { SnackbarOptions } from '@float/ui/deprecated/Snackbar/useSnackbar';

import { getAreTimeSettingsValid } from './helpers';

export const useTimeTrackingController = ({
  initialAutoLogTimeSettings = defaultInitialAutoLogTimeSettings,
  lockLogTimeSettings,
  mutateAutoLogTimeSettings,
  showSnackbar,
}: {
  initialAutoLogTimeSettings: AutoLogTimeSettings | undefined;
  lockLogTimeSettings: LockLoggedTimeSettings;
  mutateAutoLogTimeSettings: (autoLogTimeSettings: AutoLogTimeSettings) => void;
  showSnackbar: (message: string, options?: SnackbarOptions) => void;
}) => {
  const [autoLogTimeSettings, setAutoLogTimeSettings] = useState(
    initialAutoLogTimeSettings,
  );

  const showError = useCallback(
    (message: string) =>
      showSnackbar(message, {
        className: 'error',
        iconLeft: IconWarningTriangle,
        showClose: true,
      }),
    [showSnackbar],
  );

  const updateAutoLogTimeSettings = useCallback(async () => {
    const isValid = getAreTimeSettingsValid(
      autoLogTimeSettings,
      lockLogTimeSettings,
    );

    if (!isValid) {
      showError(
        'Automatic time logging must match or be more frequent than the Lock logged time setting.',
      );

      return;
    }

    try {
      const newAutoLogTimeSettings =
        await api3.postAutoLogTimeSettings(autoLogTimeSettings);
      mutateAutoLogTimeSettings(newAutoLogTimeSettings);
      showSnackbar('Settings updated.');
    } catch {
      showError('Settings update failed.');
    }
  }, [
    autoLogTimeSettings,
    lockLogTimeSettings,
    mutateAutoLogTimeSettings,
    showError,
    showSnackbar,
  ]);

  const handleAutoLogTimeActiveStateChange = useCallback(
    async (newActiveState: boolean) => {
      if (newActiveState === autoLogTimeSettings.isActive) {
        return;
      }

      const newAutoLogTimeSettings = {
        ...autoLogTimeSettings,
        isActive: newActiveState,
      };

      // Optimistically set state
      setAutoLogTimeSettings(newAutoLogTimeSettings);

      try {
        await api3.postAutoLogTimeSettings(newAutoLogTimeSettings);
        mutateAutoLogTimeSettings(newAutoLogTimeSettings);

        showSnackbar(
          `Automatic time logging ${
            newActiveState ? 'activated' : 'deactivated'
          }`,
        );
      } catch {
        // Reset to the previous active state
        setAutoLogTimeSettings(autoLogTimeSettings);
        showError('Automatic time logging activation failed.');
      }
    },
    [autoLogTimeSettings, showError, showSnackbar, mutateAutoLogTimeSettings],
  );

  const handleAutoLogTimeFrequencyChange = useCallback(
    ({ value: frequency }: { value: AutoLogTimeFrequency }) => {
      if (frequency !== autoLogTimeSettings.frequency) {
        setAutoLogTimeSettings({
          ...autoLogTimeSettings,
          frequency,
        });
      }
    },
    [autoLogTimeSettings],
  );

  const handleAutoLogTimeIntervalChange = useCallback(
    (interval: number) => {
      if (interval !== autoLogTimeSettings.interval) {
        setAutoLogTimeSettings({
          ...autoLogTimeSettings,
          interval,
        });
      }
    },
    [autoLogTimeSettings],
  );

  const haveAutoLogTimeSettingsChanged =
    Number(initialAutoLogTimeSettings.interval) !==
      Number(autoLogTimeSettings.interval) ||
    initialAutoLogTimeSettings.frequency !== autoLogTimeSettings.frequency;

  const isAutoLogTimeSettingsUpdateEnabled =
    autoLogTimeSettings.isActive && haveAutoLogTimeSettingsChanged;

  return {
    autoLogTimeSettings,
    setInterval,
    showError,
    isAutoLogTimeSettingsUpdateEnabled,
    updateAutoLogTimeSettings,
    handleAutoLogTimeActiveStateChange,
    handleAutoLogTimeFrequencyChange,
    handleAutoLogTimeIntervalChange,
  };
};
