import {
  failedFetchingEntitiesCreator,
  fetchedEntitiesCreator,
  fetchEntitiesCreator,
  shouldFetchEntitiesCreator,
} from './creatorHelpers';

export const FETCH_CURRENCIES = 'FETCH_CURRENCIES';
export const FETCH_CURRENCIES_SUCCESS = 'FETCH_CURRENCIES_SUCCESS';
export const FETCH_CURRENCIES_FAILURE = 'FETCH_CURRENCIES_FAILURE';

const PLURAL = 'currencies';

const fetchedCurrencies = fetchedEntitiesCreator(FETCH_CURRENCIES_SUCCESS);

const failedFetchingCurrencies = failedFetchingEntitiesCreator(
  FETCH_CURRENCIES_FAILURE,
);

const shouldFetchCurrencies = shouldFetchEntitiesCreator(PLURAL);

export const fetchCurrencies = fetchEntitiesCreator(
  FETCH_CURRENCIES,
  PLURAL,
  shouldFetchCurrencies,
  fetchedCurrencies,
  failedFetchingCurrencies,
);
