import { updateProjectTeam } from '@float/common/actions/projects/updateProjectTeam';
import { useAppDispatchStrict } from '@float/common/store';
import {
  BudgetType,
  Project,
  ProjectTeamMemberData,
} from '@float/types/project';

function getScrollWrapper() {
  return document.getElementById('schedule-scroll-wrapper');
}

export const useAddFormSubmit = ({
  onSubmit,
  projectId,
}: {
  onSubmit: () => void;
  projectId: number;
}) => {
  const dispatch = useAppDispatchStrict();

  // Updates the project team locally and sends the update to the server.
  // Resets the form.
  // Scrolls down after the add in order to maintain positioning.
  const handleSubmit = async (values: { team: ProjectTeamMemberData[] }) => {
    if (values.team.length) {
      const add = values.team
        .filter((member) => member.people_id)
        .map((member) => ({
          ...member,
          hourly_rate: member.hourly_rate || 0,
        }));

      await dispatch(
        updateProjectTeam(projectId, {
          add,
        }),
      );

      const scrollWrapper = getScrollWrapper();

      if (scrollWrapper) {
        window.requestIdleCallback(() => {
          scrollWrapper.scrollTo({
            top: scrollWrapper.scrollHeight - scrollWrapper.clientHeight,
          });
        });
      }

      onSubmit?.();
    }
  };

  return handleSubmit;
};

export const getShowingRates = (project: Project, canSeeBudget: boolean) => {
  // 1. Needs ACL
  // 2. Needs to be a budget type that supports rates
  // 3. Rate needs to be individually set
  return (
    canSeeBudget &&
    (project.budget_type === BudgetType.TotalFee ||
      project.budget_type === BudgetType.HourlyFee) &&
    typeof project.default_hourly_rate !== 'string'
  );
};
