import React from 'react';
import { compose } from 'redux';

import { Button, withConfirm, withSnackbar } from '@float/ui/deprecated';
import { Card } from '@float/ui/deprecated/Earhart/Cards';

import linkState, { linkAt, validateLinks } from '../form/linkState';
import CurrencyField from './CurrencyField';
import { getStartWeekOnSelectOptions } from './Preferences.helpers';
import ShowWeekendsField from './ShowWeekendsField';
import StartWeekOnField from './StartWeekOnField';
import { StyledColumn, StyledRow } from './styles';
import TimeFormatField from './TimeFormatField';
import TimezoneField from './TimezoneField';
import WorkDaysField from './WorkDaysField';

const ID_PREFIX = 'flt-as-preferences-form-';

class PreferencesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultFieldState();
    this.onSubmit = this.onSubmit.bind(this);
    this.mapTimezoneViewState = this.mapTimezoneViewState.bind(this);
    this.mapCurrencyViewState = this.mapCurrencyViewState.bind(this);
    this.mapStartWeekOnViewState = this.mapStartWeekOnViewState.bind(this);
    linkState(this, 'timezone', this.mapTimezoneViewState);
    linkState(this, 'currency', this.mapCurrencyViewState);
    linkState(this, 'start_work_week', this.mapStartWeekOnViewState);
    linkState(this, 'hide_non_wk_days');
    linkState(this, 'time_format_24h');
    this.sourceWorkDayHoursResetCount = 0;
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { editedCompanyPrefs } = newProps;
    const timezoneWasChanged =
      editedCompanyPrefs.timezone !== this.props.editedCompanyPrefs.timezone;
    const currencyWasChanged =
      editedCompanyPrefs.currency !== this.props.editedCompanyPrefs.currency;
    if (
      (this.props.timezones.length == 0 && newProps.timezones.length) ||
      timezoneWasChanged
    ) {
      let tzChoice = this.mapTimezoneViewState(
        editedCompanyPrefs.timezone,
        newProps,
      );
      if (!tzChoice) {
        tzChoice = this.mapTimezoneViewState('America/New_York', newProps);
      }
      linkAt(this, 'timezone').set(tzChoice, false);
    }
    if (
      (this.props.currencies.length == 0 && newProps.currencies.length) ||
      currencyWasChanged
    ) {
      let curChoice = this.mapCurrencyViewState(
        editedCompanyPrefs.currency,
        newProps,
      );
      if (!curChoice) {
        curChoice = this.mapCurrencyViewState('USD', newProps);
      }
      linkAt(this, 'currency').set(curChoice, false);
    }
    if (
      this.props.editedCompanyPrefs.start_work_week !==
      newProps.editedCompanyPrefs.start_work_week
    ) {
      const nextWeekStart = this.mapStartWeekOnViewState(
        newProps.editedCompanyPrefs.start_work_week,
      );
      linkAt(this, 'start_work_week').set(nextWeekStart, false);
    }
    if (
      this.props.editedCompanyPrefs.hide_non_wk_days !==
      newProps.editedCompanyPrefs.hide_non_wk_days
    ) {
      linkAt(this, 'hide_non_wk_days').set(
        newProps.editedCompanyPrefs.hide_non_wk_days,
        false,
      );
    }
    if (
      this.props.editedCompanyPrefs.time_format_24h !==
      newProps.editedCompanyPrefs.time_format_24h
    ) {
      linkAt(this, 'time_format_24h').set(
        newProps.editedCompanyPrefs.time_format_24h,
        false,
      );
    }

    if (
      this.props.editedCompanyPrefs.work_days_hours !==
        newProps.editedCompanyPrefs.work_days_hours ||
      this.props.editedCompanyPrefs.work_days_hours_history !==
        newProps.editedCompanyPrefs.work_days_hours_history
    ) {
      const newPrefs = newProps.editedCompanyPrefs;
      this.setWorkDaysHours({
        allDays: newPrefs.work_days_hours_history
          ? null
          : newPrefs.work_days_hours,
        history: newPrefs.work_days_hours_history,
      });
      this.sourceWorkDayHoursResetCount++;
    }
  }

  mapTimezoneViewState(id, props) {
    props = props || this.props;
    return props.timezones.find((timezone) => timezone.value == id);
  }

  mapCurrencyViewState(id, props) {
    props = props || this.props;
    return props.currencies.find((currency) => currency.value == id);
  }

  mapStartWeekOnViewState(id) {
    return getStartWeekOnSelectOptions().find((day) => day.value == id);
  }

  /**
   * Maps local CompanyPrefs names to old form names.
   * @param {Event} e
   */
  onSubmit(e) {
    e.preventDefault();
    if (!validateLinks(this)) {
      return;
    }
    if (this.state.workDaysHours.hasNonWorkDayBeenAdded) {
      this.props.confirm({
        title: `Update work days`,
        message: `Are you sure? Removing work days means that any allocations
        that were scheduled on these days will be deleted forever.`,
        onConfirm: this.prepareFormDataAndSubmit,
      });
      return;
    }
    this.prepareFormDataAndSubmit();
  }

  prepareFormDataAndSubmit = () => {
    const { onSubmit } = this.props;
    const {
      timezone,
      currency,
      start_work_week,
      hide_non_wk_days,
      time_format_24h,
      workDaysHours,
    } = this.state;

    const formData = new FormData();
    formData.append('timezones', timezone.value);
    formData.append('currency', currency.value);
    formData.append('start_work_week', start_work_week.value);
    formData.append('hide_non_wk_days', hide_non_wk_days);
    formData.append('time_format_24h', time_format_24h);

    if (workDaysHours.allDays) {
      workDaysHours.allDays.forEach((wkDay, index) => {
        formData.append('work_days_hours[]', wkDay);
      });
    } else {
      const historyAsObj = workDaysHours.history.reduce((acc, h) => {
        acc[h[0]] = h[1];
        return acc;
      }, {});
      formData.append('work_days_hours_history', JSON.stringify(historyAsObj));
    }

    this.setState({ isLoading: true });
    return onSubmit(formData)
      .then(() => {
        this.setState({ isLoading: false });
        this.props.showSnackbar('Preferences updated.');
      })
      .catch(() => linkAt(this, 'timezone').setError('Something went wrong.'));
  };

  getDefaultCompanyPrefs() {
    return this.props.editedCompanyPrefs;
  }

  setWorkDaysHours = (val) => {
    return this.setState({ workDaysHours: val });
  };

  getDefaultFieldState() {
    const {
      timezone,
      currency,
      start_work_week,
      hide_non_wk_days,
      work_days_hours,
      work_days_hours_history,
      time_format_24h,
    } = this.getDefaultCompanyPrefs();

    return {
      timezone: this.mapTimezoneViewState(timezone),
      currency: this.mapCurrencyViewState(currency),
      start_work_week: this.mapStartWeekOnViewState(start_work_week),
      hide_non_wk_days,
      time_format_24h,
      popupFor: false,
      workDaysHours: {
        allDays: work_days_hours_history ? null : work_days_hours,
        history: work_days_hours_history,
      },
    };
  }

  render() {
    const { timezones, currencies } = this.props;
    return (
      <form noValidate onSubmit={this.onSubmit}>
        <Card>
          <StyledRow>
            <h4>Work schedule</h4>
          </StyledRow>

          <StyledRow direction="row">
            <StyledColumn>
              <TimezoneField
                idPrefix={ID_PREFIX}
                fieldLink={linkAt(this, 'timezone')}
                options={timezones}
              />
            </StyledColumn>
            <StyledColumn>
              <StartWeekOnField
                idPrefix={ID_PREFIX}
                fieldLink={linkAt(this, 'start_work_week')}
                people={this.props.people}
                workDaysHours={this.state.workDaysHours}
              />
            </StyledColumn>
          </StyledRow>

          <StyledRow>
            <WorkDaysField
              firstDay={this.state.start_work_week.value}
              workDaysHours={this.state.workDaysHours}
              setWorkDaysHours={this.setWorkDaysHours}
              sourceWorkDayHoursResetCount={this.sourceWorkDayHoursResetCount}
            />
          </StyledRow>

          <StyledRow direction="row">
            <ShowWeekendsField
              idPrefix={ID_PREFIX}
              fieldLink={linkAt(this, 'hide_non_wk_days')}
            />
          </StyledRow>

          <StyledRow>
            <TimeFormatField
              idPrefix={ID_PREFIX}
              fieldLink={linkAt(this, 'time_format_24h')}
            />
          </StyledRow>

          <StyledRow>
            <CurrencyField
              idPrefix={ID_PREFIX}
              fieldLink={linkAt(this, 'currency')}
              options={currencies}
              placement="top"
            />
          </StyledRow>

          <StyledRow direction="row">
            <Button
              loader={this.state.isLoading}
              disabled={this.state.isLoading}
            >
              Update
            </Button>
          </StyledRow>
        </Card>
      </form>
    );
  }
}

export default compose(withConfirm, withSnackbar)(PreferencesForm);
