import {
  DELETE_TASK,
  SPLIT_TASK_DELETE_SUCCESS,
  updateTask,
} from '@float/common/actions/tasks';
import { splitTask } from '@float/common/actions/tasks/splitTask';
import { formatTask } from '@float/common/lib/formatters';
import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { AppDispatchStrict } from '@float/common/store';
import { RawTask, Task } from '@float/types';
import { createTask } from '@float/web/actions/tasks';

export type DeleteAction = {
  actionType: typeof DELETE_TASK | typeof SPLIT_TASK_DELETE_SUCCESS;
  undoPayload: {
    task: Task;
    linkedTasks?: Task[];
  };
};

export async function revertDeleteTask(
  action: DeleteAction,
  dispatch: AppDispatchStrict,
  getState: () => ReduxStateStrict,
) {
  const createdTask: RawTask = await dispatch(
    createTask(action.undoPayload.task),
  );

  const { linkedTasks } = action.undoPayload;

  // Was the deleted task linked to other tasks?
  if (linkedTasks) {
    const deletedTask = action.undoPayload.task;

    const { tasks } = getState().tasks;

    const group = linkedTasks
      .map((task) => {
        if (deletedTask.task_id === task.task_id) {
          const formattedTask = formatTask(createdTask);

          // Restore the link
          formattedTask.parent_task_id = task.parent_task_id;
          formattedTask.root_task_id = task.root_task_id;

          task = formattedTask;
        } else {
          // The task could have been deleted
          if (!tasks[task.task_id]) {
            return;
          }

          task = { ...task };
        }

        // The parent_task_id could reference the deletedTask or a task that is no more in the state
        if (task.parent_task_id && !tasks[task.parent_task_id]) {
          if (task.parent_task_id === deletedTask.task_id) {
            task.parent_task_id = createdTask.task_id;
          } else {
            // This happens when the linked task has been deleted and then restored
            return;
          }
        }

        // The root_task_id could reference the deletedTask or a task that is no more in the state
        if (!task.root_task_id || !tasks[task.root_task_id]) {
          if (task.root_task_id === deletedTask.task_id) {
            task.root_task_id = createdTask.task_id;
          } else {
            // This happens when the linked task has been deleted and then restored
            return;
          }
        }

        return task;
      })
      .filter(Boolean) as Task[];

    // We must follow the reference order
    group.sort((a, b) => {
      if (a.root_task_id === a.task_id) return 1;
      if (b.parent_task_id === a.task_id) return 1;
      if (b.root_task_id === b.task_id) return -1;
      if (a.parent_task_id === b.task_id) return -1;

      return 0;
    });

    for (const task of group) {
      if (task.parent_task_id) {
        await dispatch(
          updateTask(task, {
            // The new created task is not inside the store
            // so to make the updateTask previous non-empty we fill up this value
            originalEntity: tasks[task.task_id] || task,
          }),
        );
      }
    }
  }

  return createdTask;
}

export async function revertDeleteSplitTask(
  action: DeleteAction,
  dispatch: AppDispatchStrict,
  getState: () => ReduxStateStrict,
) {
  const { splitTaskChanges } = getState().undoCommandStream;

  if (!splitTaskChanges || !splitTaskChanges.length) {
    return;
  }

  dispatch(splitTask(splitTaskChanges));
}
