import React, { useEffect } from 'react';
import { Trans } from '@lingui/macro';

import { PROMPTS } from '@float/constants/prompts';
import { Button } from '@float/ui/components/Button';
import { Callout } from '@float/ui/deprecated/Callout/Callout';
import { usePromptTracker } from '@float/web/OnboardingManager/hooks/usePromptTracker';

export const CalloutDraftStatus = ({
  onOpenChange,
}: {
  onOpenChange?: (open: boolean) => void;
}) => {
  const [done, setDone] = usePromptTracker(PROMPTS.draftStatusTour);

  useEffect(() => {
    if (onOpenChange) {
      onOpenChange(!done);
    }
  }, [done, onOpenChange]);

  return (
    <Callout
      visible={!done}
      anchorData={{
        anchors: [
          {
            id: 'draftStatusCallout',
            config: {
              position: 'right',
              alignment: 'start',
              arrowPosition: '32px',
              offset: { x: 0, y: 0 },
            },
          },
          {
            id: 'draftStatusOptionCallout',
            config: {
              position: 'left',
              alignment: 'start',
              arrowPosition: '32px',
              offset: { x: 0, y: 0 },
            },
          },
        ],
      }}
      onClose={setDone}
      width={425}
    >
      <Callout.Content>
        <section>
          <Callout.Title>
            <Trans>Project statuses</Trans>
          </Callout.Title>
          <Callout.Paragraph>
            <Trans>
              Statuses help you track and manage projects at every stage,
              ensuring proper visibility and control throughout the project
              lifecycle.
            </Trans>
          </Callout.Paragraph>
        </section>
      </Callout.Content>

      <Callout.ActionBar>
        <Button
          onClick={setDone}
          appearance="ghost-flue"
          href="https://support.float.com/en/articles/4806377-projects#h_e07eecca14"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Trans>Learn more</Trans>
        </Button>

        <Button appearance="primary" onClick={setDone}>
          <Trans>Okay</Trans>
        </Button>
      </Callout.ActionBar>
    </Callout>
  );
};
