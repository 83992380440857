import React from 'react';
import cs from 'classnames';

import * as styles from './FieldLabel.css';

export type FieldLabelProps = {
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLLabelElement>;

export const FieldLabel = ({
  children,
  className,
  ...attributes
}: FieldLabelProps) => {
  return (
    <label className={cs(styles.fieldLabel, className)} {...attributes}>
      {children}
    </label>
  );
};
