import { getJWTAccessToken } from '@float/common/actions/jwt';
import { trackEvent, trackIntegrationEvent } from '@float/common/lib/analytics';

import api from '../api';
import { createFetchOAuthLink } from './common';

export const FETCH_CALENDAR_OAUTH_LINK =
  'calendar_integration/FETCH_CALENDAR_OAUTH_LINK';
export const FETCH_CALENDAR_OAUTH_LINK_SUCCESS =
  'calendar_integration/FETCH_CALENDAR_OAUTH_LINK_SUCCESS';
export const FETCH_CALENDAR_OAUTH_LINK_FAILURE =
  'calendar_integration/FETCH_CALENDAR_OAUTH_LINK_FAILURE';

export const FETCH_CALENDAR_LIST = 'calendar_integration/FETCH_CALENDAR_LIST';
export const FETCH_CALENDAR_LIST_SUCCESS =
  'calendar_integration/FETCH_CALENDAR_LIST_SUCCESS';
export const FETCH_CALENDAR_LIST_FAILURE =
  'calendar_integration/FETCH_CALENDAR_LIST_FAILURE';

export const FETCH_EXT_CALENDARS = 'calendar_integration/FETCH_EXT_CALENDARS';
export const FETCH_EXT_CALENDARS_SUCCESS =
  'calendar_integration/FETCH_EXT_CALENDARS_SUCCESS';
export const FETCH_EXT_CALENDARS_FAILURE =
  'calendar_integration/FETCH_EXT_CALENDARS_FAILURE';

export const ADD_EDIT_EXT_CALENDAR =
  'calendar_integration/ADD_EDIT_EXT_CALENDAR';
export const ADD_EDIT_EXT_CALENDAR_SUCCESS =
  'calendar_integration/ADD_EDIT_EXT_CALENDAR_SUCCESS';
export const ADD_EDIT_EXT_CALENDAR_FAILURE =
  'calendar_integration/ADD_EDIT_EXT_CALENDAR_FAILURE';

export const REMOVE_EXT_CALENDAR = 'calendar_integration/REMOVE_EXT_CALENDAR';
export const REMOVE_EXT_CALENDAR_SUCCESS =
  'calendar_integration/REMOVE_EXT_CALENDAR_SUCCESS';
export const REMOVE_EXT_CALENDAR_FAILURE =
  'calendar_integration/REMOVE_EXT_CALENDAR_FAILURE';

export const RESYNC_EXT_CALENDAR = 'calendar_integration/RESYNC_EXT_CALENDAR';
export const RESYNC_EXT_CALENDAR_SUCCESS =
  'calendar_integration/RESYNC_EXT_CALENDAR_SUCCESS';
export const RESYNC_EXT_CALENDAR_FAILURE =
  'calendar_integration/RESYNC_EXT_CALENDAR_FAILURE';

export const fetchOAuthLinks = createFetchOAuthLink({
  types: ['google:calendar', 'office365:calendar'],
  fetchType: FETCH_CALENDAR_OAUTH_LINK,
  successType: FETCH_CALENDAR_OAUTH_LINK_SUCCESS,
  failureType: FETCH_CALENDAR_OAUTH_LINK_FAILURE,
});

export const fetchCalendarList = (type) => {
  return async (dispatch, getState) => {
    try {
      if (getState()?.currentUser?.shared_link_view) {
        return;
      }

      const loadState = getState().integrations.calendar.calendarListLoadState;
      if (loadState === 'LOADING' || !type) {
        return;
      }

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: FETCH_CALENDAR_LIST });
      const calendarList = await api(accessToken).requestCalendarList({ type });
      const mappedCalendarList = calendarList.map((cal) => ({
        label: cal.name,
        value: cal.id,
        ownCalendar: cal.ownCalendar,
        primary: cal.primary,
        writable: cal.writable,
      }));
      dispatch({
        type: FETCH_CALENDAR_LIST_SUCCESS,
        calendarList: mappedCalendarList,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: FETCH_CALENDAR_LIST_FAILURE });
    }
  };
};

export const fetchExtCalendars = ({ type, fetchName = false } = {}) => {
  return async (dispatch, getState) => {
    try {
      if (getState()?.currentUser?.shared_link_view) {
        return;
      }

      const loadState =
        getState().integrations.calendar.fetchExtCalendarsLoadState;
      if (loadState === 'LOADING') {
        return;
      }

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: FETCH_EXT_CALENDARS });
      const extCalendars = await api(accessToken).requestExtCalendars({ type });
      if (extCalendars && extCalendars.length && fetchName) {
        extCalendars.forEach((extCalendar) =>
          dispatch(fetchCalendarList(extCalendar.type)),
        );
      }

      dispatch({
        type: FETCH_EXT_CALENDARS_SUCCESS,
        extCalendars,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: FETCH_EXT_CALENDARS_FAILURE });
    }
  };
};

export const addEditExtCalendar = (formInput) => {
  return async (dispatch, getState) => {
    try {
      const {
        prevExtCalendarId,
        projectId,
        inboundResourceId,
        importAllEvents,
        eventsFilterWord,
        hideTitleAndDesc,
        outboundType,
        outboundResourceId,
        type,
        deleteTasks,
        deleteEvents,
        inboundOn,
        outboundOn,
        version,
        ignoreCompanyTimezone,
        prevIgnoreCompanyTimezone,
      } = formInput;
      const loadState =
        getState().integrations.calendar.addEditExtCalendarLoadState;
      if (loadState === 'LOADING') {
        return;
      }

      const user = getState().currentUser;

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: ADD_EDIT_EXT_CALENDAR });

      // if it is an edit to only the projectId, update projectId only
      const { extCalendar } = await api(accessToken).upsertExtCalendar({
        extCalendarId: prevExtCalendarId,
        projectId: inboundOn ? projectId : null,
        importAllEvents,
        eventsFilterWord,
        hideTitleAndDesc,
        outboundType: outboundOn ? outboundType : null,
        outboundResourceId: outboundOn ? outboundResourceId : null,
        inboundResourceId: inboundOn ? inboundResourceId : null,
        type,
        deleteTasks,
        deleteEvents,
        version,
        ignoreCompanyTimezone,
      });
      dispatch({
        type: ADD_EDIT_EXT_CALENDAR_SUCCESS,
        extCalendar: extCalendar || {},
      });
      if (!extCalendar) {
        dispatch(fetchExtCalendars());
      }
      if (!prevExtCalendarId) {
        trackIntegrationEvent(
          type,
          'Integration completed',
          {
            inboundOn,
            outboundOn,
            importAllEvents,
            hideTitleAndDesc,
          },
          user.cid,
        );
      }
      // we only emit event if value changes
      const timezoneChanged =
        prevIgnoreCompanyTimezone !== ignoreCompanyTimezone;
      if (timezoneChanged) {
        const value = ignoreCompanyTimezone ? 'Calendar TZ' : 'Team TZ';
        trackEvent('Toggle calendar integration TZ setting', { value });
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: ADD_EDIT_EXT_CALENDAR_FAILURE });
    }
  };
};

export const ensureCalendarExtLoaded = () => {
  return (dispatch, getState) => {
    const loadState =
      getState().integrations.calendar.fetchExtCalendarsLoadState;
    if (loadState === 'LOADED') {
      return;
    }

    return dispatch(fetchExtCalendars());
  };
};

export const resyncExtCalendar = ({ extCalendarId }) => {
  return async (dispatch, getState) => {
    try {
      const loadState =
        getState().integrations.calendar.resyncExtCalendarLoadState;
      if (loadState === 'LOADING') {
        return;
      }

      const accessToken = await dispatch(getJWTAccessToken());

      dispatch({ type: RESYNC_EXT_CALENDAR });
      await api(accessToken).resyncExtCalendar({ extCalendarId });
      dispatch({
        type: RESYNC_EXT_CALENDAR_SUCCESS,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: RESYNC_EXT_CALENDAR_FAILURE });
    }
  };
};
