import { moment } from '@float/libs/moment';

import { rangeOpts } from './config';

const rangeMap = rangeOpts.reduce(
  (all, opt) => ({ ...all, [opt.value]: opt }),
  {},
);

const isFullUnit = (range, unit) => {
  const unitStart = range.start.isSame(
    range.start.clone().startOf(unit),
    'day',
  );
  const unitEnd = range.end.isSame(range.end.clone().endOf(unit), 'day');
  const sameUnit = range.start.isSame(range.end, unit);
  return unitStart && unitEnd && sameUnit;
};

const isFullMonth = (range) => {
  return isFullUnit(range, 'month');
};

const isFullWeek = (range) => {
  return isFullUnit(range, 'week');
};

const getInterval = (momentRange, rangeType) => {
  let count, value;
  if (rangeType) {
    return rangeType.range;
  } else {
    if (isFullMonth(momentRange)) {
      count = 1;
      value = 'month';
    } else if (isFullWeek(momentRange)) {
      count = 1;
      value = 'week';
    } else {
      count = momentRange.end.diff(momentRange.start, 'days');
      value = 'days';
    }
  }
  return [count, value];
};

const calcBackwardRange = (momentRange, rangeType) => {
  const [count, value] = getInterval(momentRange, rangeType);
  const newEnd = momentRange.start.clone().subtract(1, 'day');
  const calcFrom = count > 1 || value === 'days' ? newEnd : momentRange.start;
  const newStart = calcFrom.clone().subtract(count, value).startOf(value);
  return [newStart, newEnd];
};

const calcForwardRange = (momentRange, rangeType) => {
  const [count, value] = getInterval(momentRange, rangeType);
  const newStart = momentRange.end.clone().add(1, 'day');
  const calcFrom = count > 1 || value === 'days' ? newStart : momentRange.start;
  const newEnd = calcFrom.clone().add(count, value).endOf(value);
  return [newStart, newEnd];
};

export const getNextPrevRange = (type, state) => {
  const { rangeMode, start, end } = state;
  const momentRange = new moment.range(start, end);
  const isBackward = type === 'backward';
  const rangeType = rangeMap[rangeMode];
  const argList = [momentRange, rangeType];
  const [newStart, newEnd] = isBackward
    ? calcBackwardRange(...argList)
    : calcForwardRange(...argList);
  return { newStart, newEnd };
};
