import { useCallback } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import gsap from 'gsap';

export const Transition = (props) => {
  const { children, id, onEntered, onExited } = props;

  const addEndListener = useCallback((node, done) => {
    let transition;

    if (node.classList.contains('appear') || node.classList.contains('enter')) {
      transition = 'Enter';
    } else if (node.classList.contains('exit')) {
      transition = 'Exit';
    }

    const timeout =
      parseFloat(
        node.dataset[`transition${transition}Timeout`] ||
          node.dataset[`transitionTimeout`],
        10,
      ) || 1;

    gsap.delayedCall(timeout, done);
  }, []);

  return (
    <SwitchTransition>
      <CSSTransition
        key={id || 'defaultCSSTransition'}
        addEndListener={addEndListener}
        onEnter={onEntered}
        onExited={onExited}
        appear
        unmountOnExit
      >
        <>
          {children}
          <div data-exit>&nbsp;</div>
        </>
      </CSSTransition>
    </SwitchTransition>
  );
};
