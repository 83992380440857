import { createSelector } from 'reselect';

import { getUser } from '@float/common/selectors/currentUser';
import { getOldOnboardingStatus } from '@float/web/store/legacyOnboarding/selectors';

import { OnboardingManagerStatus } from '../types';
import { getOnboardingCalloutsStatus } from './callouts';
import { getProductTourStatus } from './productTour';
import { getSurveyStatus } from './survey';

export const getOnboardingManagerStatus = createSelector(
  [
    getUser,
    getOldOnboardingStatus,
    getSurveyStatus,
    getProductTourStatus,
    getOnboardingCalloutsStatus,
  ],
  (
    user,
    oldOnboardingStatus,
    surveyStatus,
    productTourStatus,
    calloutsStatus,
  ): OnboardingManagerStatus => {
    // Onboarding is complete if the:
    //   - old onboarding was completed
    //   or the
    //   - survey is completed, and
    //   - product tour is completed, and
    //   - section call outs are completed

    const oldOnboardingCompleted = oldOnboardingStatus.completed;
    const onboardingCompleted =
      surveyStatus.completed &&
      productTourStatus.completed &&
      calloutsStatus.completed;

    const active = user.accountLoaded;
    const completed = oldOnboardingCompleted || onboardingCompleted;
    const replaying =
      productTourStatus.data && productTourStatus.data.fromReplay;

    // @ts-expect-error – There are some issues resolving the `replaying` type
    return { active, completed, replaying };
  },
);
