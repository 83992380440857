import { RefObject, useState } from 'react';

import { useLayoutEffectOnMount } from '@float/libs/hooks/useOnMount';
import { useResizeObserver } from '@float/libs/web/hooks/useResizeObserver';

export const useRect = <T extends RefObject<HTMLDivElement> | null>(
  element: T,
): DOMRect | undefined => {
  const [rect, setRect] = useState<DOMRect | undefined>(
    element?.current?.getBoundingClientRect(),
  );

  const update = () => {
    const rect = element?.current?.getBoundingClientRect();

    setRect(rect);
  };

  useResizeObserver(element, update, { wait: 0 });
  useLayoutEffectOnMount(update);

  return rect;
};
