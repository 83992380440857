import { useCallback, useEffect } from 'react';

// keys e.g. [{ keyCode: 27, handler: () => {} }]
export default function useKeyPress(keys) {
  const onKeyUp = useCallback(
    (e) => {
      const key = keys.find((k) => k.keyCode === e.keyCode);
      if (key) {
        key.handler();
      }
    },
    [keys],
  );

  useEffect(() => {
    document.addEventListener('keyup', onKeyUp);
    return () => {
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [onKeyUp]);

  return null;
}
