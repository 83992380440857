import styled from 'styled-components';

import { Theme } from '@float/ui/deprecated';

export const ActivityListItem = styled.div`
  padding: 14px 20px 0 50px;
  position: relative;
`;

export const ActivityEmptyLine = styled.div`
  padding-top: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid ${Theme.grey};
`;

export const SubItemWrap = styled.div`
  padding: 4px 20px 14px 50px;
  margin: -14px -20px 0 -50px;
  border-bottom: 1px solid ${Theme.grey};
`;
