import { useRef, useState } from 'react';
import useUpdateEffect from 'react-use/esm/useUpdateEffect';

import { getButtonAppearance } from '../helpers/getButtonAppearance';
import { ModalConfirmProps } from '../types';

export const useModalConfirm = (props: ModalConfirmProps) => {
  const {
    appearance,
    modalProps: { onOpenChange, open },
    onClickConfirm,
    twoStepConfirmation,
  } = props;

  const buttonCancelRef = useRef<HTMLButtonElement | null>(null);

  const [error, setError] = useState();
  const [isTwoStepConfirmationChecked, setIsTwoStepConfirmationChecked] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const buttonAppearance = getButtonAppearance(appearance);

  const onClickTwoStepConfirmation = () => {
    setIsTwoStepConfirmationChecked(!isTwoStepConfirmationChecked);
  };

  const onClickConfirmHandler = () => {
    if (isLoading) return;

    const confirmFn = onClickConfirm && onClickConfirm();
    const isConfirmFnPromise = confirmFn instanceof Promise;

    if (isConfirmFnPromise) {
      setIsLoading(true);

      confirmFn
        .then(() => {
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          if (e && e.message) setError(e.message);
        });
    }
  };

  const onOpenChangeHandler = (state: boolean) => {
    if (isLoading) return;
    if (props.onOpenChange) props.onOpenChange(state);
    if (onOpenChange) onOpenChange(state);
  };

  const onOpenAutoFocusHandler = (e: Event) => {
    // Radix dialog will automatically focus the first interactive element in the dialog.
    // Given the order of our CTAs it will focus the primary CTA on open, but to keep
    // it consistent with production behaviour we want to auto focus the secondary CTA instead (cancel button)
    // https://linear.app/float-com/issue/EXP-464/manage-projects-update-confirmation-modal-when-deleting-projects#comment-081d79d8
    if (!twoStepConfirmation) {
      e.preventDefault();
      buttonCancelRef.current?.focus({ preventScroll: true });
    }
  };

  useUpdateEffect(() => {
    // reset state when modal is closed
    if (!open) {
      setError(undefined);
      setIsTwoStepConfirmationChecked(false);
    }
  }, [open]);

  return {
    buttonAppearance,
    buttonCancelRef,
    error,
    isLoading,
    isTwoStepConfirmationChecked,
    onClickConfirmHandler,
    onClickTwoStepConfirmation,
    onOpenAutoFocusHandler,
    onOpenChangeHandler,
    setIsTwoStepConfirmationChecked,
  };
};
