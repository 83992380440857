import { connect } from 'react-redux';
import { getPeople, getUser } from 'selectors';

import { updateCompanyPrefs } from '@float/common/actions/companyPrefs';

import Form from '../../components/Preferences/PreferencesForm';
import withOnMount from '../../decorators/withOnMount';
import { areBeingFetched as currenciesAreBeingFetched } from '../../reducers/currencies';
import { areBeingFetched as timezonesAreBeingFetched } from '../../reducers/timezones';

const mapTimezonesForDropdown = (timezones) => {
  return timezones.map((timezone) => ({
    label: timezone.label,
    value: timezone.timezone_id,
  }));
};

const mapCurrenciesForDropdown = (currencies) => {
  return currencies.map((currency) => ({
    label: currency.label,
    value: currency.currency_id,
  }));
};

const mapStateToProps = (state) => {
  const editedCompanyPrefs = {
    ...getUser(state),
    timezone: state.companyPrefs.timezone,
  };
  return {
    isFetching:
      timezonesAreBeingFetched(state.settingsTimezones) ||
      currenciesAreBeingFetched(state.settingsCurrencies),
    timezones: mapTimezonesForDropdown(state.settingsTimezones.entities),
    currencies: mapCurrenciesForDropdown(state.settingsCurrencies.entities),
    companyId: state.currentUser.cid,
    editedCompanyPrefs,
    people: getPeople(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (data) => dispatch(updateCompanyPrefs(data)),
});

const StatefulForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOnMount(Form));

export default StatefulForm;
