import styled, { css } from 'styled-components';

import EH from '@float/ui/deprecated/Earhart';
import Spinner from '@float/ui/deprecated/Loader/Spinner';

export const Insights = styled.div<{
  btn?: boolean;
  warn?: boolean;
  error?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4px;
  min-height: 24px;
  min-width: 24px;
  border-radius: 6px;
  background-color: ${(p) => p.theme.surface2};
  color: ${(p) => p.theme.emphasisMedium};
  user-select: none;
  cursor: default;
  position: relative;

  svg {
    width: 16px;
    height: 16px;
  }

  path {
    stroke: ${(p) => p.theme.emphasisMedium};
  }

  ${Spinner._styles.SpinnerContainer} {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  ${(p) =>
    p.btn &&
    css`
      background-color: ${(p) => p.theme.secondaryHover};
      border: 1px solid ${(p) => p.theme.primary};
      color: ${(p) => p.theme.primary};
      cursor: pointer;

      path {
        stroke: ${(p) => p.theme.primary};
      }
    `}

  ${(p) =>
    p.warn &&
    !p.btn &&
    css`
      background-color: ${(p) => p.theme.orangeBg};
      color: ${(p) => p.theme.orangeText};

      path {
        stroke: ${(p) => p.theme.orangeText};
      }
    `}

  ${(p) =>
    p.error &&
    !p.btn &&
    css`
      background-color: ${(p) => p.theme.secondaryRedLight4};
      color: ${(p) => p.theme.secondaryRedLight11};

      path {
        stroke: ${(p) => p.theme.secondaryRedLight11};
      }
    `}
`;

export const StatValue = styled.div`
  ${EH.Typography.Label13.M500};
  letter-spacing: -0.2px;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StatUnit = styled.span`
  font-size: 9px;
  font-weight: 600;
`;

export const TimeRangePickerBg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9998;
`;

export const DateRangePickerWrapper = styled.div`
  background-color: #fff;
  border: 0.5px solid ${(p) => p.theme.stroke1};
  box-shadow:
    0px 3px 5px rgba(9, 30, 66, 0.15),
    0px 0px 6px rgba(9, 30, 66, 0.04);
  border-radius: 5px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 12px 16px 0;
  height: 300px;
  box-sizing: border-box;
  z-index: 2;

  @media (max-width: 1010px) {
    right: auto;
    left: 10px;
  }
`;

export const TimeRange = styled.div<{ isCustom: boolean }>`
  ${(p) =>
    p.isCustom ? EH.Typography.Label11.M500 : EH.Typography.Label13.M500};
  color: ${(p) => p.theme.emphasisHigh};
  background-color: ${(p) => p.theme.white};
  border: 1px solid ${(p) => p.theme.stroke3};
  display: flex;
  align-items: center;
  letter-spacing: -0.2px;
  padding: 0 0 0 7px;
  border-radius: 6px;
  cursor: pointer;
  white-space: nowrap;
  min-height: 28px;

  svg {
    position: relative;
    top: 1px;
  }

  path {
    stroke: ${(p) => p.theme.emphasisHigh};
  }

  &:hover {
    background-color: ${(p) => p.theme.surface4};
  }
`;

export const TimeRangeText = styled.div`
  max-width: 104px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Invisible = styled.div`
  visibility: hidden;
`;
