import { useEffect, useLayoutEffect, useRef } from 'react';

import getScrollbarSize from '@float/common/serena/util/getScrollbarSize';

export const SCROLL_ADJUSTMENT_COLS = 50;

export default function useInfiniteSizerEffect({
  containerWidth,
  colWidth,
  scrollWrapperRef,
  numCols,
  setNumCols,
  baseColOffset,
  setBaseColOffset,
  logMyTimeView,
}) {
  const leftResizeMeta = useRef({
    needsResize: false,
    mouseDown: false,
  });

  useLayoutEffect(() => {
    if (logMyTimeView) return;
    if (!scrollWrapperRef.current) return;

    // Increased data to the left means that we need to readjust the current
    // scrollLeft so that the user's visible columns don't change.
    const ref = scrollWrapperRef.current;

    const left = SCROLL_ADJUSTMENT_COLS * colWidth + ref.scrollLeft + 1;
    scrollWrapperRef.current.scrollTo({ left });
  }, [setNumCols, baseColOffset, logMyTimeView]); // eslint-disable-line
  // Note: We don't want to extend based on colWidth changing

  // This effect's goal is to prevent infinitely loading left if the user clicks
  // and drags on the scrollbar. Instead, it will defer the resize until a
  // mouseup happens. We could potentially show a "release scrollbar to load
  // more data" message in this scenario, but it seems better than infinitely
  // triggering left expansions.
  useEffect(() => {
    if (logMyTimeView) return;
    if (!scrollWrapperRef.current) return;

    const ref = scrollWrapperRef.current;

    const onMouseDown = (e) => {
      if (e.clientY > window.innerHeight - getScrollbarSize()) {
        leftResizeMeta.current.mouseDown = true;
      }
    };

    const onMouseUp = (e) => {
      leftResizeMeta.current.mouseDown = false;
      if (leftResizeMeta.current.needsResize) {
        setBaseColOffset((o) => Math.max(0, o - SCROLL_ADJUSTMENT_COLS));
        leftResizeMeta.current.needsResize = false;
      }
    };

    ref.addEventListener('mousedown', onMouseDown);
    ref.addEventListener('mouseup', onMouseUp);

    return () => {
      ref.removeEventListener('mousedown', onMouseDown);
      ref.removeEventListener('mouseup', onMouseUp);
    };
  }, [scrollWrapperRef.current, setBaseColOffset, logMyTimeView]); // eslint-disable-line

  useEffect(() => {
    if (logMyTimeView) return;
    if (!scrollWrapperRef.current) return;

    const ref = scrollWrapperRef.current;

    const onScroll = (e) => {
      // Scrolling to the right is a simple addition that doesn't change
      // any existing rendered items, so we can just do it.
      if (ref.scrollLeft + containerWidth + 2 * colWidth > colWidth * numCols) {
        setNumCols((c) => c + SCROLL_ADJUSTMENT_COLS);
      }

      // A left resize requires adjusting the left pixel offset for everything
      // that's rendered, and will cause a re-render.
      if (ref.scrollLeft < 2 * colWidth) {
        if (leftResizeMeta.current.mouseDown) {
          leftResizeMeta.current.needsResize = true;
        } else {
          setBaseColOffset((o) => Math.max(0, o - SCROLL_ADJUSTMENT_COLS));
        }
      }
    };

    ref.addEventListener('scroll', onScroll);

    return () => ref.removeEventListener('scroll', onScroll);
    // eslint-disable-next-line
  }, [
    scrollWrapperRef.current,
    colWidth,
    containerWidth,
    numCols,
    setNumCols,
    setBaseColOffset,
    logMyTimeView,
  ]);
}
