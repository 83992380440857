import { createSelector } from 'reselect';

import { getUser } from '@float/common/selectors/currentUser';

export const isEnterprisePlan = (state) =>
  state.settingsBillingInfo?.plan?.plan_type === 'enterprise';
export const isProPlan = (state) =>
  state.settingsBillingInfo?.plan?.plan_type === 'pro';
export const isStarterPlan = (state) =>
  state.settingsBillingInfo?.plan?.plan_type === 'starter';

export const getAvailablePlans = createSelector(
  [
    (state) => state.settingsBillingInfo?.plan?.plan_id,
    (state) => state.settingsBillingInfo?.available_plans,
    (state) => state.settingsBillingInfo?.inactive_plans,
    (state) => getUser(state).time_tracking > 0,
  ],
  (currentPlanId, availablePlans, inactivePlans, hasTimeTracking) => {
    const isCurrentPlanInactive = inactivePlans?.find(
      (p) => p.plan_id === currentPlanId,
    );
    const pool = isCurrentPlanInactive ? inactivePlans : availablePlans;
    const monthly = pool?.find((p) => p.is_monthly);
    const annual = pool?.find((p) => !p.is_monthly);
    return { monthly, annual };
  },
);
