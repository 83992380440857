import { createSelector } from 'reselect';

import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { getMeFilter } from '@float/common/selectors/search';
import { getActiveFilters } from '@float/common/selectors/views';

import { getSearchDerivedContext } from '../derivedContext';
import { buildReportsURL } from './buildReportsURL';
import { getReportQueryFromFilters } from './getReportQueryFromFilters';
import { ReportQuerySettings } from './types';

export const selectReportsFetchUrl = createSelector(
  [
    getActiveFilters,
    getMeFilter,
    getSearchDerivedContext,
    (_: ReduxStateStrict, settings: ReportQuerySettings) => settings,
    (_: ReduxStateStrict, __: ReportQuerySettings, url: string) => url,
  ],
  (searchFilters, meFilter, searchContext, settings, baseUrl) => {
    const reportQuery = getReportQueryFromFilters(
      searchFilters,
      searchContext,
      settings,
      meFilter,
    );

    return buildReportsURL(baseUrl, reportQuery);
  },
);
