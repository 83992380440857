export function distance(x1, y1, x2, y2) {
  return Math.hypot(x2 - x1, y2 - y1);
}

export const norm = (value, min, max) => (value - min) / (max - min);

export const lerp = (norm, min, max) => (max - min) * norm + min;

export const clamp = (value, min, max) =>
  Math.min(Math.max(value, Math.min(min, max)), Math.max(min, max));

export const map = (
  value,
  sourceMin,
  sourceMax,
  destMin,
  destMax,
  clamped = false,
) => {
  if (clamped) value = clamp(value, sourceMin, sourceMax);
  if (sourceMin === sourceMax) {
    return destMax;
  }
  return lerp(norm(value, sourceMin, sourceMax), destMin, destMax);
};
