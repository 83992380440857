import { forEach, isUndefined, map } from 'lodash';
import { getEmptyTaskLabel } from 'reports/TeamCapacity/parser/table/getEmptyTaskLabel';

import { formatAmount, toCents } from '@float/common/lib/budget';
import { BudgetPriority, BudgetType } from '@float/types/project';

export function breakdown(ctx, raw) {
  const byTask = {};

  raw.totals.forEach((item) => {
    if (item.type !== 'task' && item.type !== 'logged_time') return;

    if (!item.name) {
      item.name = getEmptyTaskLabel(ctx);
    }

    const key = `${item.name}::${item.phase_id}`;

    if (!byTask[key]) {
      byTask[key] = {
        name: item.name,
        phase_id: item.phase_id,
        isBillable: undefined,
        budget: 0,
        scheduled: 0,
        billable: 0,
        nonbillable: 0,
        feeCents: 0,
        logged: {
          scheduled: 0,
          billable: 0,
          nonbillable: 0,
          feeCents: 0,
        },
        combined: {
          scheduled: 0,
          billable: 0,
          nonbillable: 0,
          feeCents: 0,
        },
        future: {
          scheduled: 0,
          billable: 0,
          nonbillable: 0,
          feeCents: 0,
          tentative: {
            billable: 0,
            nonbillable: 0,
          },
        },
        tentative: {
          scheduled: 0,
          billable: 0,
          nonbillable: 0,
        },
        children: {},
      };
    }

    const record = byTask[key];

    if (!record.children[item.person_id]) {
      record.children[item.person_id] = {
        person_id: item.person_id,
        scheduled: 0,
        billable: 0,
        nonbillable: 0,
        feeCents: 0,
        tentative: 0,
        logged: {
          scheduled: 0,
          billable: 0,
          nonbillable: 0,
          feeCents: 0,
        },
        combined: {
          scheduled: 0,
          billable: 0,
          nonbillable: 0,
          feeCents: 0,
        },
        future: {
          scheduled: 0,
          billable: 0,
          nonbillable: 0,
          feeCents: 0,
        },
      };
    }

    const child = record.children[item.person_id];

    // Add budgets
    if (item.type === 'task') {
      record.budget = raw?.tasks?.[item.task_meta_id]?.budget;
    }

    if (item.type === 'task' || item.type === 'logged_time') {
      if (item.type === 'logged_time' && item.date >= ctx.loggedTimeBoundary) {
        return;
      }

      const childRoot = item.type === 'task' ? child : child.logged;
      const recordRoot = item.type === 'task' ? record : record.logged;

      childRoot.scheduled += item.hours.scheduled;
      recordRoot.scheduled += item.hours.scheduled;
      record.combined.scheduled +=
        item.type === 'task' ? item.hours.future : item.hours.scheduled;
      record.future.scheduled += item.hours.future;
      child.combined.scheduled +=
        item.type === 'task' ? item.hours.future : item.hours.scheduled;

      recordRoot.feeCents += toCents(item.rate) * item.hours.scheduled;
      childRoot.feeCents += toCents(item.rate) * item.hours.scheduled;

      const rateCents = toCents(item.rate);
      record.combined.feeCents +=
        rateCents *
        (item.type === 'task' ? item.hours.future : item.hours.scheduled);
      record.future.feeCents += rateCents * item.hours.future;
      child.combined.scheduled +=
        rateCents *
        (item.type === 'task' ? item.hours.future : item.hours.scheduled);

      if (item.type === 'task') {
        child.future.feeCents += rateCents * item.hours.future;
      }

      if (item.tentative) {
        child.tentative += item.hours.scheduled;
      }

      // Do not throw on billable mismatch, instead take task billable
      // status as source of truth.
      if (isUndefined(record.isBillable) || item.type === 'task') {
        record.isBillable = item.billable;
      }

      if (item.billable) {
        childRoot.billable += item.hours.scheduled;
        recordRoot.billable += item.hours.scheduled;
        record.combined.billable +=
          item.type === 'task' ? item.hours.future : item.hours.scheduled;
        record.future.billable += item.hours.future;
        child.combined.billable +=
          item.type === 'task' ? item.hours.future : item.hours.scheduled;

        if (item.tentative) {
          record.tentative.scheduled += item.hours.scheduled;
          record.tentative.billable += item.hours.scheduled;
          record.future.tentative.billable +=
            item.type === 'task' ? item.hours.future : item.hours.scheduled;
        }

        if (item.type === 'task') {
          child.future.billable += item.hours.future;
        }
      } else {
        childRoot.nonbillable += item.hours.scheduled;
        recordRoot.nonbillable += item.hours.scheduled;
        record.combined.nonbillable +=
          item.type === 'task' ? item.hours.future : item.hours.scheduled;
        record.future.nonbillable += item.hours.future;
        child.combined.nonbillable +=
          item.type === 'task' ? item.hours.future : item.hours.scheduled;

        if (item.tentative) {
          record.tentative.scheduled += item.hours.scheduled;
          record.tentative.nonbillable += item.hours.scheduled;
          record.future.tentative.nonbillable +=
            item.type === 'task' ? item.hours.future : item.hours.scheduled;
        }

        if (item.type === 'task') {
          child.future.nonbillable += item.hours.future;
        }
      }
    }
  });

  return byTask;
}

export function getScheduledTable(ctx, raw) {
  const { billable, project, people, phases, hasPhases } = ctx;
  const hasFeeColumns =
    project.budget_type === BudgetType.TotalFee ||
    project.budget_type === BudgetType.HourlyFee;
  const hasBudgetSupport = project.budget_priority === BudgetPriority.Task;

  const headers = [
    {
      label: billable ? 'Billable' : 'Non-billable',
      align: 'flex-start',
      grow: 1,
    },
    {
      label: '',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: 'Scheduled',
      width: 140,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
  ];

  if (hasBudgetSupport) {
    headers.splice(1, 0, {
      label: 'Budget',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    });
  }

  if (hasPhases) {
    headers.splice(1, 0, {
      label: 'Phase',
      align: 'flex-start',
      grow: 1,
    });
  }

  if (!raw || !raw.totals) {
    return { headers, rows: [] };
  }

  const byTask = breakdown(ctx, raw);

  const totals = {
    hours: 0,
    feeCents: 0,
  };

  const rows = [];
  forEach(byTask, (o, key) => {
    const fee = hasFeeColumns ? o.feeCents / 100 : '';
    const hours = billable ? o.billable : o.nonbillable;

    if (o.isBillable != billable) return;

    totals.hours += hours;
    if (hasFeeColumns) totals.feeCents += fee * 100;

    const data = [o.name, fee, hours];

    if (hasBudgetSupport) {
      data.splice(1, 0, o.budget);
    }

    if (hasPhases) {
      data.splice(1, 0, phases[o.phase_id]?.phase_name || 'No Phase');
    }

    rows.push({
      id: key,
      data,
      children: map(o.children, (c, personId) => {
        const childBudget = '';
        const childFee = hasFeeColumns ? c.feeCents / 100 : '';
        const childHours = billable ? c.billable : c.nonbillable;

        const childData = [people[personId].name, childFee, childHours];

        if (hasBudgetSupport) {
          childData.splice(1, 0, childBudget);
        }

        if (hasPhases) {
          childData.splice(1, 0, '');
        }

        return { data: childData };
      }),
    });
  });

  const footer = [
    { label: '' },
    { label: totals.feeCents / 100 },
    { label: totals.hours },
  ];

  if (hasBudgetSupport) {
    footer.splice(1, 0, { label: '' });
  }

  if (hasPhases) {
    footer.splice(1, 0, { label: '' });
  }

  return { headers, rows, footer };
}

export function getLoggedTable(ctx, raw) {
  const { billable, project, people, phases, hasPhases } = ctx;
  const hasFeeColumns =
    project.budget_type === BudgetType.TotalFee ||
    project.budget_type === BudgetType.HourlyFee;
  const hasBudgetSupport = project.budget_priority === BudgetPriority.Task;

  const headers = [
    {
      label: billable ? 'Billable' : 'Non-billable',
      align: 'flex-start',
      grow: 1,
    },
    {
      label: '',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: 'Logged',
      width: 80,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
  ];

  if (hasBudgetSupport) {
    headers.splice(1, 0, {
      label: 'Budget',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    });
  }

  if (hasPhases) {
    headers.splice(1, 0, {
      label: 'Phase',
      align: 'flex-start',
      grow: 1,
    });
  }

  if (!raw || !raw.totals) {
    return { headers, rows: [] };
  }

  const byTask = breakdown(ctx, raw);

  const totals = {
    hours: 0,
    feeCents: 0,
  };

  const rows = [];
  forEach(byTask, (o, key) => {
    const fee = hasFeeColumns ? o.logged.feeCents / 100 : '';
    const hours = billable ? o.logged.billable : o.logged.nonbillable;

    if (o.isBillable != billable) return;

    totals.hours += hours;
    if (hasFeeColumns) totals.feeCents += fee * 100;

    const data = [o.name, fee, hours];

    if (hasBudgetSupport) {
      data.splice(1, 0, o.budget);
    }

    if (hasPhases) {
      data.splice(1, 0, phases[o.phase_id]?.phase_name || 'No Phase');
    }

    rows.push({
      id: key,
      data,
      children: map(o.children, (c, personId) => {
        const childBudget = '';
        const childFee = hasFeeColumns ? c.logged.feeCents / 100 : '';
        const childHours = billable ? c.logged.billable : c.logged.nonbillable;
        const childData = [people[personId].name, childFee, childHours];

        if (hasBudgetSupport) {
          childData.splice(1, 0, childBudget);
        }

        if (hasPhases) {
          childData.splice(1, 0, '');
        }

        return { data: childData };
      }),
    });
  });

  const footer = [
    { label: '' },
    { label: totals.feeCents / 100 },
    { label: totals.hours },
  ];

  if (hasBudgetSupport) {
    footer.splice(1, 0, { label: '' });
  }

  if (hasPhases) {
    footer.splice(1, 0, { label: '' });
  }

  return { headers, rows, footer };
}

export function getCompareTable(ctx, raw) {
  const { billable, project, people, phases, hasPhases } = ctx;
  const hasFeeColumns =
    project.budget_type === BudgetType.TotalFee ||
    project.budget_type === BudgetType.HourlyFee;
  const hasBudgetSupport = project.budget_priority === BudgetPriority.Task;

  const headers = [
    {
      label: billable ? 'Billable' : 'Non-billable',
      align: 'flex-start',
      grow: 1,
    },
    {
      label: '',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: 'Logged',
      width: 80,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
    {
      label: '',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: 'Scheduled',
      width: 80,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
    {
      label: '',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: 'Difference',
      width: 80,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
  ];

  if (hasBudgetSupport) {
    headers.splice(1, 0, {
      label: 'Budget',
      width: 140,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    });
  }

  if (hasPhases) {
    headers.splice(1, 0, {
      label: 'Phase',
      align: 'flex-start',
      grow: 1,
    });
  }

  if (!raw || !raw.totals) {
    return { headers, rows: [] };
  }

  const byTask = breakdown(ctx, raw);

  const totals = {
    hours: 0,
    feeCents: 0,
    loggedHours: 0,
    loggedFeeCents: 0,
  };

  const rows = [];
  forEach(byTask, (o, key) => {
    const fee = hasFeeColumns ? o.feeCents / 100 : '';
    const hours = billable ? o.billable : o.nonbillable;
    const loggedFee = hasFeeColumns ? o.logged.feeCents / 100 : '';
    const loggedHours = billable ? o.logged.billable : o.logged.nonbillable;
    const diffFee = hasFeeColumns ? (o.feeCents - o.logged.feeCents) / 100 : '';
    const diffHours = hours - loggedHours;

    if (o.isBillable != billable) return;

    totals.hours += hours;
    totals.loggedHours += loggedHours;

    if (hasFeeColumns) {
      totals.feeCents += fee * 100;
      totals.loggedFeeCents += loggedFee * 100;
    }

    const data = [
      o.name,
      loggedFee,
      loggedHours,
      fee,
      hours,
      diffFee,
      diffHours,
    ];

    if (hasBudgetSupport) {
      data.splice(1, 0, o.budget);
    }

    if (hasPhases) {
      data.splice(1, 0, phases[o.phase_id]?.phase_name || 'No Phase');
    }

    rows.push({
      id: key,
      data,
      children: map(o.children, (c, personId) => {
        const childBudget = '';
        const childFee = hasFeeColumns ? c.feeCents / 100 : '';
        const childHours = billable ? c.billable : c.nonbillable;
        const childLoggedFee = hasFeeColumns ? c.logged.feeCents / 100 : '';
        const childLoggedHours = billable
          ? c.logged.billable
          : c.logged.nonbillable;
        const childDiffFee = hasFeeColumns
          ? (c.feeCents - c.logged.feeCents) / 100
          : '';
        const childDiffHours = childHours - childLoggedHours;

        const childData = [
          // sometimes the id isn't found in the peopleMap
          // https://rollbar.com/float/fe-web-app/items/4968/
          people[personId]?.name || '',
          childLoggedFee,
          childLoggedHours,
          childFee,
          childHours,
          childDiffFee,
          childDiffHours,
        ];

        if (hasBudgetSupport) {
          childData.splice(1, 0, childBudget);
        }

        if (hasPhases) {
          childData.splice(1, 0, '');
        }

        return { data: childData };
      }),
    });
  });

  const footer = [
    { label: '' },
    { label: hasFeeColumns ? totals.loggedFeeCents / 100 : '' },
    { label: totals.loggedHours },
    { label: hasFeeColumns ? totals.feeCents / 100 : '' },
    { label: totals.hours },
    {
      label: hasFeeColumns
        ? (totals.feeCents - totals.loggedFeeCents) / 100
        : '',
    },
    { label: totals.hours - totals.loggedHours },
  ];

  if (hasBudgetSupport) {
    footer.splice(1, 0, { label: '' });
  }

  if (hasPhases) {
    footer.splice(1, 0, { label: '' });
  }

  return { headers, rows, footer };
}

export function getCombinedTable(ctx, raw) {
  const { billable, project, people, phases, hasPhases } = ctx;
  const hasFeeColumns =
    project.budget_type === BudgetType.TotalFee ||
    project.budget_type === BudgetType.HourlyFee;

  const headers = [
    {
      label: billable ? 'Billable' : 'Non-billable',
      align: 'flex-start',
      grow: 1,
    },
    {
      label: '',
      width: 200,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: 'Past logged',
      width: 140,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
    {
      label: '',
      width: 200,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: 'Future scheduled',
      width: 140,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
    {
      label: '',
      width: 200,
      formatter: (x) => (x == '' ? '' : formatAmount(project.budget_type, x)),
    },
    {
      label: 'Total',
      width: 140,
      allowOverflow: true,
      formatter: (x) => (x === '' ? '' : formatAmount(1, x)),
    },
  ];

  if (hasPhases) {
    headers.splice(1, 0, {
      label: 'Phase',
      align: 'flex-start',
      grow: 1,
    });
  }

  if (!raw || !raw.totals) {
    return { headers, rows: [] };
  }

  const byTask = breakdown(ctx, raw);
  const totals = {
    hours: 0,
    feeCents: 0,
    loggedHours: 0,
    loggedFeeCents: 0,
  };

  const rows = [];
  forEach(byTask, (o, key) => {
    const fee = hasFeeColumns ? o.future.feeCents / 100 : '';
    const hours = billable ? o.future.billable : o.future.nonbillable;
    const loggedFee = hasFeeColumns ? o.logged.feeCents / 100 : '';
    const loggedHours = billable ? o.logged.billable : o.logged.nonbillable;
    const totalFee = hasFeeColumns
      ? (o.future.feeCents + o.logged.feeCents) / 100
      : '';
    const totalHours = hours + loggedHours;

    if (o.isBillable != billable) return;

    totals.hours += hours;
    totals.loggedHours += loggedHours;
    if (hasFeeColumns) {
      totals.feeCents += fee * 100;
      totals.loggedFeeCents += loggedFee * 100;
    }

    const data = [
      o.name,
      loggedFee,
      loggedHours,
      fee,
      hours,
      totalFee,
      totalHours,
    ];
    if (hasPhases) {
      data.splice(1, 0, phases[o.phase_id]?.phase_name || 'No Phase');
    }

    rows.push({
      id: key,
      data,
      children: map(o.children, (c, personId) => {
        const childFee = hasFeeColumns ? c.future.feeCents / 100 : '';
        const childHours = billable ? c.future.billable : c.future.nonbillable;
        const childLoggedFee = hasFeeColumns ? c.logged.feeCents / 100 : '';
        const childLoggedHours = billable
          ? c.logged.billable
          : c.logged.nonbillable;
        const childTotalFee = hasFeeColumns
          ? (c.future.feeCents + c.logged.feeCents) / 100
          : '';
        const childTotalHours = childHours + childLoggedHours;

        const childData = [
          people[personId].name,
          childLoggedFee,
          childLoggedHours,
          childFee,
          childHours,
          childTotalFee,
          childTotalHours,
        ];

        if (hasPhases) {
          childData.splice(1, 0, '');
        }

        return { data: childData };
      }),
    });
  });

  const footer = [
    { label: '' },
    { label: hasFeeColumns ? totals.loggedFeeCents / 100 : '' },
    { label: totals.loggedHours },
    { label: hasFeeColumns ? totals.feeCents / 100 : '' },
    { label: totals.hours },
    {
      label: hasFeeColumns
        ? (totals.feeCents + totals.loggedFeeCents) / 100
        : '',
    },
    { label: totals.hours + totals.loggedHours },
  ];

  if (hasPhases) {
    footer.splice(1, 0, { label: '' });
  }

  return { headers, rows, footer };
}
