import { trackOnboardingPrompt } from 'OnboardingManager/helpers/analytics';

import { PROMPTS } from '@float/constants/prompts';

import { ProductTourStep } from '../config';

export type ProductTourTrackParams = {
  clickedX?: boolean;
  title: ProductTourStep['title'];
  dismissed?: boolean;
  replaying?: boolean;
  step: number;
};
const track = (params: ProductTourTrackParams) => {
  const {
    clickedX = false,
    title,
    dismissed = false,
    replaying = false,
    step,
  } = params;

  // if dismissing we want to use step 1 of the analytics data
  // app/OnboardingManager/helpers/analytics/data.js
  const productTourAnalyticsStep = dismissed ? 1 : 0;

  // only for when dismissing a prompt
  const interaction = dismissed ? clickedX : null;
  trackOnboardingPrompt(
    PROMPTS.productTour,
    { step, title, interaction, replaying },
    productTourAnalyticsStep,
  );
};

export { track };
