import { useCallback } from 'react';
import {
  addDays,
  differenceInCalendarDays,
  isAfter,
  isBefore,
  isSameDay,
} from 'date-fns';
import { max } from 'lodash';

import { selectEntityWorkDaysDurationGetter } from '@float/common/selectors/schedule/getEntityWorkDaysDuration';
import { selectMinWorkHoursInRangeGetter } from '@float/common/selectors/schedule/getMinWorkHoursInRange';
import { getWorkHours } from '@float/common/selectors/schedule/getWorkHours';
import { selectIsWorkDayGetter } from '@float/common/selectors/schedule/isWorkDay';
import { useAppSelectorStrict } from '@float/common/store';
import { formatToFloatDate } from '@float/libs/dates';
import {
  getPeopleMapRaw,
  getUser,
  selectDatesManager,
} from '@float/web/selectors';

export type UseAllocationDaysHelpersParams = {
  peopleIds: number[];
  timeoffId?: number;
};

export const useAllocationDaysHelpers = ({
  peopleIds,
  timeoffId,
}: UseAllocationDaysHelpersParams) => {
  const currentUser = useAppSelectorStrict(getUser);

  const peopleMap = useAppSelectorStrict(getPeopleMapRaw);

  const dates = useAppSelectorStrict(selectDatesManager);

  const getMinimumWorkHoursInRange = useAppSelectorStrict(
    selectMinWorkHoursInRangeGetter,
  );

  const getEntityWorkDaysDurationWithParams = useAppSelectorStrict(
    selectEntityWorkDaysDurationGetter,
  );

  const getIsWorkDayWithParams = useAppSelectorStrict(selectIsWorkDayGetter);

  const getEntityWorkDaysDuration = useCallback(
    ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
      const workDaysDurations = peopleIds.map((personId) => {
        const person = peopleMap[personId];

        if (!person) return 0;

        return getEntityWorkDaysDurationWithParams(
          {
            start_date: formatToFloatDate(startDate),
            end_date: formatToFloatDate(endDate),
            timeoff_id: timeoffId,
          },
          person,
        );
      });

      return max(workDaysDurations) || 0;
    },
    [peopleIds, peopleMap, timeoffId, getEntityWorkDaysDurationWithParams],
  );

  /**
   * Returns if the date is a working date for at least one person
   */
  const getIsWorkDay = useCallback(
    (date: Date) => {
      return peopleIds.some((personId) => {
        const person = peopleMap[personId];

        if (!person) return false;

        return getIsWorkDayWithParams(
          person,
          formatToFloatDate(date),
          timeoffId,
        );
      });
    },
    [peopleMap, peopleIds, timeoffId, getIsWorkDayWithParams],
  );

  /**
   * When we select multiple people in a date range we need to check if
   * they have different working hours during the selected date range
   *
   * This is used for example in timeoff allocation view when we
   * want represent the inputs initial values as "-" when people have different working hours
   */
  const getHasDifferentDailyWorkingHours = ({
    startDate,
    endDate,
  }: {
    startDate: Date;
    endDate: Date;
  }) => {
    let workHoursValue = undefined;

    for (
      let date = startDate;
      isBefore(date, endDate) || isSameDay(date, endDate);
      date = addDays(date, 1)
    ) {
      const dateFormatted = formatToFloatDate(date);

      for (const personId of peopleIds) {
        const person = peopleMap[personId];

        if (!person) continue;

        const workHours = getWorkHours(
          dates,
          currentUser,
          person,
          dateFormatted,
        );

        // Exclude non-working days
        if (workHours === 0) continue;

        if (typeof workHoursValue === 'undefined') workHoursValue = workHours;

        // There is at least one person with different daily working hours
        if (workHours !== workHoursValue) return true;
      }
    }

    return false;
  };

  // This function calculates number of allocation days (including non-working days if needed)
  // Number of allocation days represents the number of days to which the allocation would be done
  // In some cases the allocation migh be scheduled on a non-working days,
  // so better calling it as a numberOfAllocationDays
  const getNumberOfAllocationDays = useCallback(
    (startDate: Date, endDate: Date, offWorkDate?: Date) => {
      const isPersonSelected = peopleIds.length > 0;

      // There is no person selected, return the difference between dates
      if (!isPersonSelected)
        return differenceInCalendarDays(endDate, startDate) + 1;

      const numberOfAllocationDaysInDateRange = getEntityWorkDaysDuration({
        startDate,
        endDate,
      });

      if (timeoffId) {
        return numberOfAllocationDaysInDateRange;
      }

      // The allocation is scheduled from non-working day
      if (offWorkDate) {
        const isOffWorkDateInDateRange =
          isAfter(offWorkDate, startDate) && isBefore(offWorkDate, endDate);

        // Add an extra day when the allocation is scheduled on non-working day
        // and the day is in between the selected date range
        if (
          isOffWorkDateInDateRange ||
          isSameDay(offWorkDate, startDate) ||
          isSameDay(offWorkDate, endDate)
        ) {
          return numberOfAllocationDaysInDateRange + 1;
        }
      }

      return numberOfAllocationDaysInDateRange;
    },
    [peopleIds, timeoffId, getEntityWorkDaysDuration],
  );

  /**
   * Returns number of working hours in the selected time range
   */
  const getNumberOfWorkingHours = useCallback(
    (startDate: Date, endDate: Date) => {
      let totalWorkHoursValue = 0;

      for (
        let date = startDate;
        isBefore(date, endDate) || isSameDay(date, endDate);
        date = addDays(date, 1)
      ) {
        const dateFormatted = formatToFloatDate(date);

        for (const personId of peopleIds) {
          const person = peopleMap[personId];

          if (!person) continue;

          totalWorkHoursValue += getWorkHours(
            dates,
            currentUser,
            person,
            dateFormatted,
          );
        }
      }

      return totalWorkHoursValue;
    },
    [peopleIds, currentUser, peopleMap, dates],
  );

  const getEndDateFromTotalHours = useCallback(
    ({
      startDate,
      hoursPerDay,
      hoursTotal,
    }: {
      startDate: Date;
      hoursPerDay: number;
      hoursTotal: number;
    }) => {
      const numberOfAllocationDaysRequired = Math.floor(
        hoursTotal / hoursPerDay,
      );

      let endDate = startDate;
      let length = 1;

      while (numberOfAllocationDaysRequired - length > 0) {
        endDate = addDays(endDate, 1);

        if (getIsWorkDay(endDate)) {
          length += 1;
        }
      }

      return endDate;
    },
    [getIsWorkDay],
  );

  return {
    getIsWorkDay,
    getHasDifferentDailyWorkingHours,
    getNumberOfAllocationDays,
    getNumberOfWorkingHours,
    getEndDateFromTotalHours,
    getMinimumWorkHoursInRange,
  };
};
