import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Transition } from 'TransitionManager/Transition';

export const AsideBase = forwardRef((props, ref) => {
  const { transitionId } = props;

  const transitionRef = useRef();
  const elRef = useRef();

  useImperativeHandle(ref, () => ({
    ...transitionRef.current,
  }));

  return (
    <Transition ref={transitionRef} id={transitionId}>
      <div ref={elRef} />
    </Transition>
  );
});
