import React from 'react';
import styled from 'styled-components';

import { TASK_EDIT_MODES } from '@float/common/components/Schedule/util/ContextMenu';
import { EH, FieldLabel, Icons } from '@float/ui/deprecated';

import { getEndDateElem, getStartDateElem } from './datePickers';
import { getRepeatControls } from './repeatElems';

const DatesContainer = styled.div`
  display: inline-flex;
  width: 280px;
  flex-direction: column;
`;

const DateGap = styled.div`
  width: 20px;
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  margin-right: 4px;

  svg {
    height: 10px;
    width: auto;
  }
`;

const DurationValue = styled.span`
  color: ${(p) => p.theme.emphasisMedium};
`;

const DatesWrapper = styled.div`
  display: flex;
`;

let etmThis;

const canBeRepeated = () => {
  const { isOffWork, people, mode, task } = etmThis.state;
  if (
    isOffWork ||
    !people ||
    [TASK_EDIT_MODES.INSERT, TASK_EDIT_MODES.REPLACE].includes(mode) ||
    task.root_task_id
  ) {
    return false;
  }
  return true;
};

export const getWorkingDays = () => {
  return Math.round(etmThis.state.length);
};

const getDatesAndRepeat = (self) => {
  etmThis = self;
  const workingDays = getWorkingDays();
  return (
    <DatesContainer>
      <FieldLabel>
        Duration
        {workingDays > 1 ? (
          <DurationValue>: {workingDays} days</DurationValue>
        ) : null}
      </FieldLabel>
      <DatesWrapper>
        {getStartDateElem(etmThis)}
        <DateGap>
          <Icons.Right color={EH.Colors.Core.Text.Secondary} />
        </DateGap>
        {getEndDateElem(etmThis)}
      </DatesWrapper>
      {canBeRepeated() && getRepeatControls(etmThis)}
    </DatesContainer>
  );
};

export default getDatesAndRepeat;
