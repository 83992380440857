import React, { JSXElementConstructor } from 'react';

import { SnackbarContext } from './SnackbarProvider';
import { SnackbarMethods } from './useSnackbar';

type SnackBarContext = {
  showSnackbar: SnackbarMethods['showSnackbar'];
  closeSnackbar: SnackbarMethods['closeSnackbar'];
  showError: SnackbarMethods['showError'];
};

export const withSnackbar = <R, P>(
  Component: JSXElementConstructor<P & SnackBarContext>,
) => {
  return React.forwardRef<R, P>((props, ref) => (
    <SnackbarContext.Consumer>
      {(context) => (
        <Component
          {...props}
          ref={ref}
          // @ts-expect-error `context` is the wrong type here - converting SnackbarProvider to TSX may fix it
          showSnackbar={(context as SnackBarContext).showSnackbar}
          // @ts-expect-error `context` is the wrong type here - converting SnackbarProvider to TSX may fix it
          closeSnackbar={(context as SnackBarContext).closeSnackbar}
          // @ts-expect-error `context` is the wrong type here - converting SnackbarProvider to TSX may fix it
          showError={(context as SnackBarContext).showError}
        />
      )}
    </SnackbarContext.Consumer>
  ));
};

export default withSnackbar;
