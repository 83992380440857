import { createSelector } from 'reselect';

import {
  isViewPageAccessibleByMembers,
  userCanOnlyViewThemself,
} from '@float/common/lib/rights';
import { AccountType, SavedView } from '@float/types';

import { ReduxStateStrict as ReduxState } from '../../reducers/lib/types';
import { getUser } from '../currentUser';

const getViewsRawList = (state: ReduxState) => state?.views?.views;

const EMPTY_VIEWS_LIST: SavedView[] = [];

export const getViewsList = createSelector(
  [getViewsRawList, getUser],
  (views = EMPTY_VIEWS_LIST, user) => {
    if (user.account_type_id === AccountType.Member) {
      if (userCanOnlyViewThemself(user)) {
        return views.filter((view) => view.personal);
      }

      return views.filter((view) => isViewPageAccessibleByMembers(view));
    }

    return views;
  },
);

export const getViewsLoadState = (state: ReduxState) => state?.views?.loadState;

export const getViewsIsLoading = (state: ReduxState) => {
  const loadState = getViewsLoadState(state);

  return loadState === 'INITIAL' || loadState === 'LOADING';
};

export const getViewsIsLoadingFailed = (state: ReduxState) => {
  const loadState = getViewsLoadState(state);

  return loadState === 'LOAD_FAILED';
};

export const getViewById = (state: ReduxState, id: number) => {
  return getViewsList(state).find((view) => view.id === id) ?? null;
};
