import React, { useEffect } from 'react';
import { forEach } from 'lodash';
import { getSearchFilteredActivePeople, getUser } from 'selectors';

import Avatar from '@float/common/components/Avatar/Avatar';
import { Rights } from '@float/common/lib/acl';
import { useAppSelector } from '@float/common/store';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { moment } from '@float/libs/moment';
import {
  Icons,
  MultiSelect,
  ReadOnlyField,
  VirtualSelect,
} from '@float/ui/deprecated';

import { calculateWorkDays, getTotalHours } from '../helpers/timeReducers';
import {
  ALL_VIEWABLE_VALUE,
  getLengthAndTotalHours,
  mapPersonToOption,
  useAssignablePeopleOptions,
} from './AssigneesField.helpers';

let etmThis;
const allViewableLabel = 'Select all';

const getAssignedElemReadOnly = () => {
  const actualNumValues = etmThis.state.task.people_ids.length;
  const values = etmThis.state.peopleIds
    // sometimes the id isn't found in the peopleMap
    // https://rollbar.com/float/fe-web-app/items/4880/
    .map((id) => etmThis.props.peopleMap[id]?.name)
    .filter(Boolean)
    .sort((a, b) => {
      return String(a).toLowerCase().localeCompare(String(b).toLowerCase());
    });

  const others = actualNumValues - values.length;

  let label = 'Assigned to';
  if (values.length > 1) {
    label += `: ${actualNumValues} people`;
  }

  let text = values.join(', ');
  if (others > 0) {
    text += `${values.length > 1 ? ',' : ''} and ${others} other${
      others == 1 ? '' : 's'
    }`;
  }

  return (
    <ReadOnlyField
      type="scrollableText"
      label={label}
      value={text}
      wrapperStyle={{ marginBottom: 20 }}
    />
  );
};

const shouldShowSingleSelectDropdown = (self) => {
  const { taskMode, isOffWork } = self.state;

  if (isOffWork) return true;

  const singleSelectFields = ['status'];
  const isSingleSelectField = singleSelectFields.includes(taskMode);
  return isSingleSelectField;
};

export const getAssignedElem = (self) => {
  etmThis = self;

  if (
    etmThis.isReadOnly() ||
    etmThis.isMember() ||
    etmThis.state.integrationSyncLocked
  ) {
    return getAssignedElemReadOnly();
  }

  return <AssigneesField self={etmThis} />;
};

export const AssigneesField = (props) => {
  const { self } = props;
  const { peopleIds, originalTaskUnmodified } = self.state;

  const assignablePeopleOptions = useAssignablePeopleOptions(self.state);
  const isSingleAssignee = shouldShowSingleSelectDropdown(self);

  function handleChange(peopleIds) {
    etmThis.setState((ps) => {
      const { calcEntityLength } = etmThis.props;

      if (
        featureFlags.isFeatureEnabled(
          FeatureFlag.EditTaskModalAssigneesFieldTotalHoursUpdate,
        )
      ) {
        const { length, totalHours } = getLengthAndTotalHours(
          calcEntityLength,
          ps,
          peopleIds,
        );

        return {
          peopleIds,
          length,
          totalHours,
          errors: {
            ...ps.errors,
            assignedElem: null,
          },
        };
      }

      return {
        peopleIds,
        errors: {
          ...ps.errors,
          assignedElem: null,
        },
      };
    });
  }

  if (isSingleAssignee) {
    // If the user removed all people while in the task/timeoff tab and switch
    // to status, default to the original person.
    const value = peopleIds[0] ?? originalTaskUnmodified.people_ids[0];

    return (
      <VirtualSelect
        label="Assigned to"
        style={{ marginBottom: 16 }}
        value={value}
        options={assignablePeopleOptions}
        visibleItems={6}
        nonNullable
        clearInputOnDropdownOpen={false}
        onChange={(val) => {
          handleChange([val.value]);
        }}
        errors={etmThis.state.errors.assignedElem}
      />
    );
  }

  const values = etmThis.state.peopleIds
    .map((id) => {
      const person = etmThis.props.peopleMap[id];

      if (!person) return undefined;

      return {
        label: person.name,
        value: person.people_id,
      };
    })
    .filter(Boolean);

  const withSelectAll = values.length < assignablePeopleOptions.length;
  const { calcEntityLength } = etmThis.props;

  return (
    <MultiSelect
      label={`Assigned to${
        values.length > 1 ? `: ${values.length} people` : ''
      }`}
      options={[
        ...assignablePeopleOptions,
        ...(withSelectAll
          ? [
              mapPersonToOption({
                name: allViewableLabel,
                people_id: ALL_VIEWABLE_VALUE,
              }),
            ]
          : []),
      ]}
      values={values}
      style={{ marginBottom: 16 }}
      keyboardHighlight
      color="charcoalGrey"
      visibleItems={6}
      onAdd={(opt) => {
        if (opt.value === ALL_VIEWABLE_VALUE) {
          const peopleIds = assignablePeopleOptions.map((o) => o.value);
          handleChange(peopleIds);
        } else {
          const peopleIds = [...self.state.peopleIds, opt.value];
          handleChange(peopleIds);
        }
      }}
      onRemove={(opt) => {
        const peopleIds = self.state.peopleIds.filter((id) => id !== opt.value);
        handleChange(peopleIds);
      }}
      onReplace={(prevOption, newOption) => {
        if (newOption.value === ALL_VIEWABLE_VALUE) {
          const peopleIds = assignablePeopleOptions.map((o) => o.value);
          handleChange(peopleIds);
        } else {
          const peopleIds = self.state.peopleIds
            .filter((id) => id !== prevOption.value)
            .concat([newOption.value]);
          handleChange(peopleIds);
        }
      }}
      errors={etmThis.state.errors.assignedElem}
    />
  );
};
