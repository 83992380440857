import styled from 'styled-components';

import EH from '@float/ui/deprecated/Earhart';

export const StyledH2 = styled.h2`
  ${EH.Typography.DisplayS.SB600}

  color: ${EH.Colors.FIN.Lt.Emphasis.High};

  & + h4 {
    margin-top: 12px;
  }
`;

export const StyledH4 = styled.h4`
  ${EH.Typography.TextL.R400}

  color: ${EH.Colors.FIN.Lt.Emphasis.High};
`;
