import React from 'react';
import cn from 'classnames';

import { Button } from '@float/ui/components/Button';
import { IconChevronLeft } from '@float/ui/icons/essentials/IconChevronLeft';
import { IconChevronRight } from '@float/ui/icons/essentials/IconChevronRight';

import { arrow } from './styles.css';

type ArrowProps = {
  className?: string;
  direction?: 'left' | 'right';
  label?: string;
  onClick?: (e: React.MouseEvent) => void;
};

export function Arrow({
  className,
  direction = 'left',
  label,
  onClick,
}: ArrowProps) {
  return (
    <Button
      className={cn(arrow, className)}
      size="xxsmall"
      iconEnd={direction === 'right' ? IconChevronRight : IconChevronLeft}
      iconOnlyLabel={label}
      appearance="ghost-flay"
      onClick={onClick}
    />
  );
}
