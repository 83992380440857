import React from 'react';
import * as Select from '@radix-ui/react-select';

import { List } from '../../primitives/List';

import * as styles from './styles.css';

export function Separator() {
  return (
    <Select.Separator asChild>
      <List.Separator className={styles.separator} />
    </Select.Separator>
  );
}
