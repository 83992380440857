import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon {...props}>
    <path
      fill="#000"
      d="M2 12C2 6.5 6.5 2 12 2s10 4.5 10 10-4.5 10-10 10S2 17.5 2 12Zm9-5v7h2V7h-2Zm0 8v2h2v-2h-2Z"
    />
  </BaseIcon>
);
