import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M5.75 9.75h12.5c.552 0 1-.407 1-.91V5.66c0-.502-.448-.909-1-.909H5.75c-.552 0-1 .407-1 .909v3.182c0 .502.448.909 1 .909Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M5.75 19.25h12.5c.552 0 1-.407 1-.91V15.16c0-.502-.448-.909-1-.909H5.75c-.552 0-1 .407-1 .91v3.181c0 .502.448.909 1 .909Z"
    />
  </BaseIcon>
);
