import { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';

import { useAppDispatch, useAppStore } from '@float/common/store';
import {
  requestActivity,
  updateActivityPage,
} from '@float/web/activity/actions';
import { useWebAppSelector } from '@float/web/lib/store';
import { WebAppState } from '@float/web/reducers/types';

import { parseActivityItemToRow } from './parseActivityItemToRow';
import { ItemClickCallback } from './parseActivityItemToRow/types';

const specialName = 'mainActivityFeed';
const perPage = 200;

const mainActivityFeedSelector = (state: WebAppState) =>
  state.activityData.activityCollections.mainActivityFeed;

const activityItemsSelector = createSelector(
  [
    mainActivityFeedSelector,
    (_: WebAppState, createdDateFilter: string) => createdDateFilter,
  ],
  (mainActivityFeed, createdDateFilter) => {
    if (!mainActivityFeed?.items) return [];

    return mainActivityFeed.items.filter(
      (item) => item.activity_type && item.createdAt <= createdDateFilter,
    );
  },
);

export function useActivityTableItems(
  onItemClick: ItemClickCallback,
  specialFilterer: unknown,
) {
  const dispatch = useAppDispatch();

  const [sortOrder, setSortOrder] = useState('desc');

  // We use a created date filter to filter out all the items coming
  // from the live updates
  const [createdDateFilter, setCreatedDateFilter] = useState(() =>
    new Date().toJSON(),
  );

  const items = useWebAppSelector((state) =>
    activityItemsSelector(state, createdDateFilter),
  );

  const isFetching = useWebAppSelector(
    (state) => mainActivityFeedSelector(state)?.isFetching ?? false,
  );
  const hasMore = useWebAppSelector(
    (state) => mainActivityFeedSelector(state)?.hasMore ?? false,
  );
  const page = useWebAppSelector(
    (state) => mainActivityFeedSelector(state)?.page ?? 1,
  );

  const fetchNextPage = useCallback(() => {
    if (!hasMore || isFetching) return;

    dispatch(updateActivityPage({ specialName, page: page + 1 }));
    dispatch(
      requestActivity({
        specialName,
        specialFilterer: specialFilterer ?? {},
        perPage,
        groupBatchOnly: true,
        sortOrder,
      }),
    );
  }, [dispatch, page, hasMore, isFetching, specialFilterer, sortOrder]);

  const updateSortOrder = useCallback((property: string, direction: string) => {
    // we only support sorting on `createdAt` property
    if (property === 'createdAt') {
      setSortOrder(direction);
    }
  }, []);

  useEffect(() => {
    setCreatedDateFilter(new Date().toJSON());

    // always expecting date filters
    if (specialFilterer && !isEmpty(specialFilterer)) {
      dispatch(
        requestActivity({
          specialName,
          toClear: true,
          specialFilterer: specialFilterer ?? {},
          perPage,
          groupBatchOnly: true,
          sortOrder,
        }),
      );
    }
  }, [dispatch, specialFilterer, sortOrder]);

  const store = useAppStore();

  const rows = useMemo(
    () =>
      items
        .map((item) => parseActivityItemToRow(item, onItemClick, store))
        .filter(Boolean),
    [items, onItemClick, store],
  );

  return {
    sortOrder,
    updateSortOrder,
    fetchNextPage,
    rows,
    isFetching,
    hasMore,
  };
}
