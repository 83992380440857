import request from '@float/common/lib/request';
import { config } from '@float/libs/config';

const getSlackAppUrl = () =>
  `https://slack-app-notifications-dot-float-${
    config.isStagingOrProduction ? 'production' : 'development'
  }.appspot.com`;

const getClientId = () =>
  `client_id=${
    config.isStagingOrProduction
      ? '4133336183.892119071603'
      : '4133336183.1050602447250'
  }`;

export function disconnect() {
  return request.post(`disconnect`, null, {
    version: '',
    hostname: getSlackAppUrl(),
    credentials: 'omit',
    jwt: true,
  });
}

export function checkIsConnected() {
  return request.get(`is_connected`, null, {
    version: '',
    hostname: getSlackAppUrl(),
    credentials: 'omit',
    jwt: true,
  });
}

export function getOauthUrl() {
  const scopes = [
    'im:history',
    'im:read',
    'im:write',
    'team:read',
    'users:read',
    'users:read.email',
    'users:write',
    'chat:write',
    'commands',
  ];

  const userScopes = [
    'users.profile:read',
    'users.profile:write',
    'emoji:read',
  ];
  const scopeString = `&scope=${scopes.join()}&user_scope=${userScopes.join()}`;
  return `https://slack.com/oauth/v2/authorize?${getClientId()}${scopeString}`;
}
