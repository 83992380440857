import { DEFAULT_COLOR } from '@float/constants/colors';

import { hexToRgb } from '../conversion/hexToRgb';

export function normalizeColor(value?: string) {
  if (!value) return DEFAULT_COLOR;

  let result = value.trim();

  if (result.startsWith('#')) result = result.slice(1);
  if (result.length > 6) result = result.slice(0, 6);
  if (hexToRgb(result) === null) result = DEFAULT_COLOR;

  return result;
}
