import { AppDispatchStrict, AppStoreStrict } from '@float/common/store';
import { RawTask, SplitTaskChanges } from '@float/types';

import { SPLIT_TASK, SPLIT_TASK_START } from '.';
import request from '../../lib/request';

export const splitTask =
  (changes: SplitTaskChanges) =>
  async (dispatch: AppDispatchStrict, getState: AppStoreStrict['getState']) => {
    const id = changes[0].entity.task_id;

    // Sometimes we get double calls on the same task
    // https://app.rollbar.com/a/float/fix/item/fe-web-app/11850/
    if (getState().tasks.splitting[id]) {
      return;
    }

    dispatch({
      type: SPLIT_TASK_START,
      taskId: id,
      changes,
    });

    const date = changes[1].entity.start_date;

    if (!date) {
      // This shouldn't happen, but when it happen better avoid
      // sending invalid requests to the server
      throw new Error('Missing split date');
    }

    const res = await request.put(
      `split/task/${id}`,
      { date },
      {
        version: 'f3',
      },
    );

    const undoBatchId = changes[0].undoBatchId;
    const previous = changes[0].originalEntity;

    const existingTasks = getState().tasks.tasks;
    let updatedTask: RawTask | undefined;
    let newTask: RawTask | undefined;
    const otherAffectedTasks: RawTask[] = [];

    res.task.forEach((task: RawTask) => {
      if (task.task_id == id) {
        // the existing task which was split
        updatedTask = task;
      } else if (existingTasks[task.task_id]) {
        // other tasks that were affected by the split;
        // e.g. linked tasks whose parent changed
        otherAffectedTasks.push(task);
      } else {
        // newly created task from the split
        newTask = task;
        newTask.createdTs = changes[1].entity.task_id;
      }
    });

    dispatch({
      type: SPLIT_TASK,
      newTask,
      updatedTask,
      updatedTaskId: id,
      previous,
      otherAffectedTasks,
      undoBatchId,
    });

    return res;
  };
