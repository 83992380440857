import { normalize } from '@float/common/search/helpers';
import { Client, FilterToken, Project } from '@float/types';

function getFilterTokenValue(token: FilterToken | undefined) {
  if (!token) return null;

  return normalize(Array.isArray(token.val) ? token.val[0] : token.val);
}

function matchClientFilter(project: Project, client: Client | null) {
  return !client || project.client_id === client.client_id;
}

function getClientFilterResult(params: GetDefaultProjectForTaskModalParams) {
  const clientFilterValue = getFilterTokenValue(
    params.searchFilters.find((f) => f.type === 'client'),
  );

  return clientFilterValue
    ? params.clients.find(
        (client) => clientFilterValue === normalize(client.client_name),
      ) ?? null
    : null;
}

function getProjectFilterResult(
  params: GetDefaultProjectForTaskModalParams,
  filteredClient: Client | null,
) {
  const projectFilterValue = getFilterTokenValue(
    params.searchFilters.find((f) => f.type === 'project'),
  );

  return (
    projectFilterValue &&
    params.accessibleProjects.find(
      (project) =>
        projectFilterValue === normalize(project.project_name) &&
        matchClientFilter(project, filteredClient),
    )
  );
}
function getLastSelectedProject(
  params: GetDefaultProjectForTaskModalParams,
  filteredClient: Client | null,
) {
  const lastProjectId = params.lastProject?.project_id;

  if (!lastProjectId) return;

  const project = params.accessibleProjects.find(
    (project) => project.project_id === lastProjectId,
  );

  if (project && matchClientFilter(project, filteredClient)) {
    return project;
  }
}

export type GetDefaultProjectForTaskModalParams = {
  project?: Project;
  clients: Client[];
  // The list of the projects that the current user can assign to a task
  accessibleProjects: Project[];
  projects: Project[];
  searchFilters: FilterToken[];
  lastProject?: { project_id: number };
};

export function getDefaultProjectForTaskModal(
  params: GetDefaultProjectForTaskModalParams,
) {
  const filteredClient = getClientFilterResult(params);

  // 1. if there is already a default project, return it directly
  if (params.project) return { filteredClient, defaultProject: params.project };

  // 2. check if there is a project filter enabled that returns a project accessible by the user
  const filteredProject = getProjectFilterResult(params, filteredClient);

  if (filteredProject) {
    return { filteredClient, defaultProject: filteredProject };
  }

  // 3. check if the last selected project is available and accessible to the user
  const lastSelectedProject = getLastSelectedProject(params, filteredClient);

  if (lastSelectedProject) {
    return {
      filteredClient,
      defaultProject: lastSelectedProject,
      isLastProject: true,
    };
  }

  // 4. if the last selected project is not active, return the first accessible project without a client
  const lastProjectId = params.lastProject?.project_id;

  if (lastProjectId && !lastSelectedProject) {
    const project = params.projects.find(
      (project) => project.project_id === lastProjectId,
    );

    if (project && !project.active) {
      const defaultProject = params.accessibleProjects.find(
        (project) => !project.client_id,
      );

      return { filteredClient, defaultProject };
    }
  }

  // 5. no candidate found, return undefines
  return { filteredClient, defaultProject: undefined };
}
