import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { positioner } from '@float/common/components/Schedule/Cell/MainCell/styles.css';
import { PROMPTS } from '@float/constants/prompts';

import Notification from '../InAppNotifications/Notification';
import AddPhaseImage from './images/add-phase';
import { pulsateBorder } from './styles';

const ScheduleOutline = styled.div`
  position: absolute;
  top: 52px;
  left: 260px;
  width: calc(100vw - 320px);
  pointer-events: none;

  @media screen and (max-width: 1010px) {
    left: 64px;
    width: calc(100vw - 124px);
  }

  @media screen and (max-width: 960px) {
    width: calc(100vw - 64px);
  }

  @media screen and (max-width: 600px) {
    .add-task-tip {
      left: 224px !important;
    }
  }

  @media screen and (max-width: 460px) {
    .add-task-tip {
      left: 132px !important;
    }
  }

  ${pulsateBorder}
`;

const StyledTip = styled.div`
  background: ${(p) => p.theme.charcoalGrey};
  font-size: 12px;
  font-weight: normal;
  line-height: 1.23;
  word-break: break-word;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    margin-top: 5px;
    font-weight: 600;
    color: white;
    cursor: pointer;
  }
`;

const style = {
  border: 0,
  bottom: 'auto',
  textAlign: 'center',
  transform: 'translate(-50%, 10px)',
  padding: 10,
  cursor: 'default',
  borderRadius: 5,
  top: '100%',
  left: 313,
  pointerEvents: 'bounding-box',
};

function AddPhase({
  markAsSeen,
  alreadySeen,
}: {
  markAsSeen: (propmt: number) => void;
  alreadySeen?: boolean;
}) {
  const [height, setHeight] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      const el = document.querySelector<HTMLElement>(`.${positioner}`);

      if (el) {
        setHeight(el.offsetHeight);
      }
    }, 0);
  }, [setHeight]);

  if (alreadySeen || !height) {
    return null;
  }

  return (
    <ScheduleOutline style={{ height }}>
      <Notification
        dark
        id={PROMPTS.welcomeAddPhase}
        className="add-task-tip"
        width={200}
        animation="fade"
        style={style}
        // @ts-expect-error JS component
        arrow="top"
        showClose={false}
        closeOnBgClick={true}
      >
        <StyledTip>
          <AddPhaseImage style={{ marginBottom: 10 }} />
          Click and drag to add a phase
          <button onClick={() => markAsSeen(PROMPTS.welcomeAddPhase)}>
            Got it!
          </button>
        </StyledTip>
      </Notification>
    </ScheduleOutline>
  );
}

export default AddPhase;
