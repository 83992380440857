import React, { useState } from 'react';
import { connect } from 'react-redux';

import { updateStatusType } from '@float/common/actions/statusTypes';
import {
  Button,
  ColorPicker,
  Input,
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  ModalTitle,
  useSnackbar,
} from '@float/ui/deprecated';
import { required, useInput } from '@float/ui/deprecated/helpers/formHooks';

function useColor(defaultColor) {
  const [value, setValue] = useState(defaultColor);
  const onChange = (newColor) => setValue(newColor);
  return { value, setValue, onChange };
}

function StatusTypeModal({ statusType, onClose, update }) {
  const name = useInput(statusType.status_type_name, {
    validate: required('Name is required.'),
  });
  const color = useColor(statusType.color.replace('#', ''));
  const [saving, setSaving] = useState(false);
  const { showSnackbar } = useSnackbar();

  const isCustom = () => statusType.status_type_name === 'Custom';

  async function submit() {
    if (!name.validate()) return;

    setSaving(true);
    const data = {
      status_type_id: statusType.status_type_id,
      color: color.value,
      sort_order: statusType.sort_order,
    };
    if (!isCustom()) {
      data.status_type_name = name.value;
    }

    const err = await update(data);
    setSaving(false);

    if (err) {
      showSnackbar(err || 'An error occurred.');
      return;
    } else {
      showSnackbar(`${data.status_type_name || 'Custom'} updated.`);
    }

    onClose();
  }

  return (
    <Modal isOpen onEnter={submit} onClose={onClose}>
      <ModalHeader style={{ paddingTop: 21, paddingBottom: 28 }}>
        <ModalTitle>Edit status</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form>
          <section className="inputs">
            {statusType.status_type_name && !isCustom() && (
              <Input
                autoFocus
                label="Name *"
                style={{ marginBottom: '20px' }}
                {...name}
              />
            )}
            <ColorPicker {...color} highContrast />
          </section>
        </form>
      </ModalBody>
      <ModalActions>
        <Button onClick={submit} loader={saving}>
          Update
        </Button>
        <Button appearance="secondary" onClick={onClose}>
          Cancel
        </Button>
      </ModalActions>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) => ({
  update: async (data) => dispatch(updateStatusType(data)),
});

export default connect(undefined, mapDispatchToProps)(StatusTypeModal);
