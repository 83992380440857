import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';

import { NavIconBtn } from '@float/common/components/NavIconBtn';
import { Tooltip } from '@float/ui/components/Tooltip';
import { useModalControls } from '@float/ui/deprecated/Modal/hooks';

import { useIsPhasePanel } from '../../hooks/useIsPhasePanel';
import { ProjectFormData } from '../../types';
import { useSelectedTasks } from './index.hooks';
import { MergeTasksModal } from './MergeTasksModal';

export const MergeTasks = () => {
  const { getValues } = useFormContext<ProjectFormData>();
  const tasks = useWatch<ProjectFormData, 'tasks'>({
    name: 'tasks',
  });
  const isPhasePanel = useIsPhasePanel();
  const parent = isPhasePanel ? 'Phase' : 'Project';
  const parentActive = getValues(
    `${parent.toLowerCase() as 'phase' | 'project'}.active`,
  );
  const selected = useSelectedTasks(tasks);
  const mergeTasksModal = useModalControls();

  const onOpenMergeModal: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    mergeTasksModal.openModal();
  };

  return (
    <React.Fragment>
      <Tooltip
        open={parentActive ? false : undefined}
        content={t`${parent} is archived`}
      >
        <NavIconBtn
          type="button"
          aria-label={t`Merge selected tasks`}
          disabled={selected.indices.length < 2 || !parentActive}
          onClick={onOpenMergeModal}
        >
          <Trans>Merge</Trans>
        </NavIconBtn>
      </Tooltip>
      {mergeTasksModal.isModalVisible && (
        <MergeTasksModal
          tasks={selected.tasks}
          onClose={mergeTasksModal.closeModal}
        />
      )}
    </React.Fragment>
  );
};
