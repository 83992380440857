import {
  ADD_TEAMWORK_CO_INT,
  ADD_TEAMWORK_CO_INT_FAILURE,
  ADD_TEAMWORK_CO_INT_SUCCESS,
  CLEAR_TEAMWORK_PRELOAD,
  FETCH_TEAMWORK_CO_INTS,
  FETCH_TEAMWORK_CO_INTS_FAILURE,
  FETCH_TEAMWORK_CO_INTS_SUCCESS,
  FETCH_TEAMWORK_CONFIG,
  FETCH_TEAMWORK_CONFIG_FAILURE,
  FETCH_TEAMWORK_CONFIG_SUCCESS,
  FETCH_TEAMWORK_OAUTH_LINK,
  FETCH_TEAMWORK_OAUTH_LINK_FAILURE,
  FETCH_TEAMWORK_OAUTH_LINK_SUCCESS,
  FETCH_TEAMWORK_PRELOAD,
  FETCH_TEAMWORK_PRELOAD_FAILURE,
  FETCH_TEAMWORK_PRELOAD_SUCCESS,
  REMOVE_TEAMWORK_CO_INT,
  REMOVE_TEAMWORK_CO_INT_FAILURE,
  REMOVE_TEAMWORK_CO_INT_SUCCESS,
  RETRY_PEOPLE_SYNC,
  RETRY_PEOPLE_SYNC_FAILURE,
  RETRY_PEOPLE_SYNC_SUCCESS,
  TEAMWORK_IMPORT_SUCCESS,
  UPDATE_TEAMWORK_CONFIG,
  UPDATE_TEAMWORK_CONFIG_FAILURE,
  UPDATE_TEAMWORK_CONFIG_SUCCESS,
} from '../actions/teamwork';

const DEFAULT_STATE = {
  oauthLink: null,
  coInts: null,
  preloadError: null,
};

export default function teamwork(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    /* FETCH OAUTH LINK */

    case FETCH_TEAMWORK_OAUTH_LINK: {
      return {
        ...state,
        oauthLinkLoadState: 'LOADING',
      };
    }

    case FETCH_TEAMWORK_OAUTH_LINK_SUCCESS: {
      return {
        ...state,
        oauthLinkLoadState: 'LOAD_SUCCESS',
        oauthLink: action.oauthLink,
      };
    }

    case FETCH_TEAMWORK_OAUTH_LINK_FAILURE: {
      return {
        ...state,
        oauthLinkLoadState: 'LOAD_FAILED',
      };
    }

    /* FETCH CALENDAR LIST */

    case FETCH_TEAMWORK_CO_INTS: {
      return {
        ...state,
        coIntsLoadState: 'LOADING',
      };
    }

    case FETCH_TEAMWORK_CO_INTS_SUCCESS: {
      return {
        ...state,
        coIntsLoadState: 'LOAD_SUCCESS',
        coInts: action.coInts,
      };
    }

    case FETCH_TEAMWORK_CO_INTS_FAILURE: {
      return {
        ...state,
        coIntsLoadState: 'LOAD_FAILED',
      };
    }

    /* ADD EXT CALENDAR */

    case ADD_TEAMWORK_CO_INT: {
      return {
        ...state,
        addCoIntLoadState: 'LOADING',
      };
    }

    case ADD_TEAMWORK_CO_INT_SUCCESS: {
      return {
        ...state,
        addCoIntLoadState: 'LOAD_SUCCESS',
      };
    }

    case ADD_TEAMWORK_CO_INT_FAILURE: {
      return {
        ...state,
        addCoIntLoadState: 'LOAD_FAILED',
      };
    }

    case REMOVE_TEAMWORK_CO_INT: {
      return {
        ...state,
        removeCoIntLoadState: 'LOADING',
      };
    }

    case REMOVE_TEAMWORK_CO_INT_SUCCESS: {
      return {
        ...state,
        removeCoIntLoadState: 'LOAD_SUCCESS',
      };
    }

    case REMOVE_TEAMWORK_CO_INT_FAILURE: {
      return {
        ...state,
        removeCoIntLoadState: 'LOAD_FAILED',
      };
    }

    case RETRY_PEOPLE_SYNC: {
      return {
        ...state,
        retryPeopleSyncLoadState: 'LOADING',
      };
    }

    case RETRY_PEOPLE_SYNC_SUCCESS: {
      return {
        ...state,
        retryPeopleSyncLoadState: 'LOAD_SUCCESS',
      };
    }

    case RETRY_PEOPLE_SYNC_FAILURE: {
      return {
        ...state,
        retryPeopleSyncLoadState: 'LOAD_FAILED',
      };
    }

    case FETCH_TEAMWORK_PRELOAD: {
      return {
        ...state,
        preloadLoadState: 'LOADING',
        preloadError: null,
      };
    }

    case FETCH_TEAMWORK_PRELOAD_SUCCESS: {
      return {
        ...state,
        preloadLoadState: 'LOAD_SUCCESS',
        preloadData: action.preloadData,
        preloadError: null,
      };
    }

    case FETCH_TEAMWORK_PRELOAD_FAILURE: {
      return {
        ...state,
        preloadLoadState: 'LOAD_FAILED',
        preloadError: action.error,
      };
    }

    case CLEAR_TEAMWORK_PRELOAD: {
      return {
        ...state,
        preloadLoadState: null,
        preloadData: null,
      };
    }

    /* TEAMWORK IMPORT BEHAVIORS */
    case TEAMWORK_IMPORT_SUCCESS: {
      return {
        ...state,
        coIntsLoadState: 'LOAD_SUCCESS',
        coInts: action.coInts,
        showImport: action.initialImport,
        initialImportStarted: false,
      };
    }

    /* FETCH TEAMWORK CONFIG */

    case FETCH_TEAMWORK_CONFIG: {
      return {
        ...state,
        fetchConfigLoadState: 'LOADING',
      };
    }

    case FETCH_TEAMWORK_CONFIG_SUCCESS: {
      return {
        ...state,
        fetchConfigLoadState: 'LOAD_SUCCESS',
        config: action.config,
      };
    }

    case FETCH_TEAMWORK_CONFIG_FAILURE: {
      return {
        ...state,
        fetchConfigLoadState: 'LOAD_FAILED',
      };
    }

    /* UPDATE TEAMWORK CONFIG */

    case UPDATE_TEAMWORK_CONFIG: {
      return {
        ...state,
        updateConfigLoadState: 'LOADING',
        initialImportStarted: action.startInitialImport,
      };
    }

    case UPDATE_TEAMWORK_CONFIG_SUCCESS: {
      return {
        ...state,
        updateConfigLoadState: 'LOAD_SUCCESS',
        config: action.config,
      };
    }

    case UPDATE_TEAMWORK_CONFIG_FAILURE: {
      return {
        ...state,
        updateConfigLoadState: 'LOAD_FAILED',
      };
    }

    default: {
      return state;
    }
  }
}
