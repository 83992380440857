import Activity from './icon-activity';
import Add from './icon-add';
import AddBold from './icon-add-bold';
import AddMedium from './icon-add-medium';
import AddPerson from './icon-add-person';
import AddProject from './icon-add-project';
import AddSmall from './icon-add-small';
import Alert from './icon-alert';
import AllViewable from './icon-all-viewable';
import Approvals from './icon-approvals';
import Arrow from './icon-arrow';
import Attach from './icon-attach';
import Back from './icon-back';
import Bell from './icon-bell';
import Bell2, { Bell2WithIndicator } from './icon-bell2';
import Bell3 from './icon-bell3';
import Bell3Reject from './icon-bell3-reject';
import Bin from './icon-bin';
import Calendar from './icon-calendar';
import Canvas from './icon-canvas';
import ChartBar from './icon-chart-bar';
import ChartLine from './icon-chart-line';
import Check from './icon-check';
import Down from './icon-chevron-down';
import Left from './icon-chevron-left';
import Right from './icon-chevron-right';
import CircleCheckOff from './icon-circlecheck-off';
import CircleCheckOn from './icon-circlecheck-on';
import CircleCheckOnBig from './icon-circlecheck-on-big';
import CloseSmall, { Close, CloseCircle } from './icon-close';
import CloseDynamic from './icon-close-dynamic';
import Collapse, { CollapseSmall } from './icon-collapse';
import Copy from './icon-copy';
import CopyRound from './icon-copy-round';
import Cross from './icon-cross';
import CrossCircle from './icon-cross-circle';
import CrossThick from './icon-cross-thick';
import Download from './icon-download';
import ErrorTriangle from './icon-error-triangle';
import Expand, { ExpandSmall } from './icon-expand';
import Export from './icon-export';
import Google from './icon-google';
import Help from './icon-help';
import HelpChat from './icon-help-chat';
import HelpShortcuts from './icon-help-shortcuts';
import HelpVideos from './icon-help-videos';
import HideVertical from './icon-hide-vertical';
import HorizontalDensity from './icon-horizontal-density';
import Import from './icon-import';
import Info from './icon-info';
import Integration from './icon-integration';
import Kebab from './icon-kebab';
import LikeAnimated from './icon-like-animated';
import Link from './icon-link';
import Lock from './icon-lock';
import Menu from './icon-menu';
import More from './icon-more';
import NavLogTime from './icon-nav-log-time';
import NavLoggedTimeline from './icon-nav-logged-timeline';
import NavManage from './icon-nav-manage';
import NavReports from './icon-nav-reports';
import NavTimeline from './icon-nav-timeline';
import Notes from './icon-notes';
import NotesHollow from './icon-notes-hollow';
import NotesLarge from './icon-notes-large';
import Notification from './icon-notification';
import Person from './icon-person';
import Play from './icon-play';
import Plus from './icon-plus';
import RadioOff from './icon-radio-off';
import RadioOn from './icon-radio-on';
import Reports from './icon-reports';
import Schedule from './icon-schedule';
import ScheduleExtended from './icon-schedule-extended';
import ScheduleSmall from './icon-schedule-small';
import Search from './icon-search';
import Security from './icon-security';
import SecurityBig from './icon-security-big';
import SerenaComplete from './icon-serena-complete';
import SerenaNotes from './icon-serena-notes';
import SerenaRepeat from './icon-serena-repeat';
import Settings from './icon-settings';
import Share from './icon-share';
import Sort from './icon-sort';
import SortAsc from './icon-sort-asc';
import SortCombo from './icon-sort-combo';
import SortDesc from './icon-sort-desc';
import Spinner from './icon-spinner';
import Square from './icon-square';
import SquareCheck from './icon-square-check';
import Star from './icon-star';
import Stop from './icon-stop';
import Stopwatch from './icon-stopwatch';
import SyncWhite from './icon-sync-white';
import SyncWhiteLrg from './icon-sync-white-lrg';
import TaskComplete from './icon-task-complete';
import TaskCompleteHollow from './icon-task-complete-hollow';
import TaskRepeat from './icon-task-repeat';
import TeamSettings from './icon-team-settings';
import TemplatePlus from './icon-template-plus';
import Tick from './icon-tick';
import TickAnimated from './icon-tick-animated';
import TickGreen from './icon-tick-green';
import TickThick from './icon-tick-thick';
import TimeTracking from './icon-time-tracking';
import TimeTrackingExtended from './icon-time-tracking-extended';
import UnlockOutline from './icon-unlock-outline';
import ViewControl, {
  CompactControl,
  ConfortableControl,
  SpaciousControl,
} from './icon-view-control';
import WarningCircle from './icon-warning-circle';
import Zoom from './icon-zoom';
import { DownSmall } from './iconChevronDownSmall';
import { LockOutline } from './IconLockOutline';
import Pencil from './IconPencil';
import { IconQuestion as Question } from './IconQuestion';
import PlusPackBlock from './plus-pack-block';

const Icons = {
  Activity,
  Add,
  AddBold,
  AddMedium,
  AddPerson,
  AddProject,
  AddSmall,
  Alert,
  AllViewable,
  Approvals,
  Attach,
  Arrow,
  Back,
  Bell,
  Bell2,
  Bell2WithIndicator,
  Bell3,
  Bell3Reject,
  Bin,
  Calendar,
  Canvas,
  ChartBar,
  Check,
  ChartLine,
  CircleCheckOff,
  CircleCheckOn,
  CircleCheckOnBig,
  Close,
  CloseCircle,
  CloseSmall,
  CloseDynamic,
  Collapse,
  CollapseSmall,
  Copy,
  CopyRound,
  CompactControl,
  ConfortableControl,
  SpaciousControl,
  Cross,
  CrossCircle,
  CrossThick,
  Down,
  DownSmall,
  Download,
  ErrorTriangle,
  Expand,
  ExpandSmall,
  Export,
  Google,
  Help,
  HelpChat,
  HelpShortcuts,
  HelpVideos,
  HideVertical,
  HorizontalDensity,
  Import,
  Info,
  Integration,
  Kebab,
  Left,
  LikeAnimated,
  Link,
  Lock,
  LockOutline,
  Menu,
  More,
  NavLogTime,
  NavManage,
  NavReports,
  NavTimeline,
  NavLoggedTimeline,
  Notes,
  NotesHollow,
  NotesLarge,
  Notification,
  Pencil,
  Person,
  Play,
  Stop,
  Plus,
  PlusPackBlock,
  Question,
  RadioOff,
  RadioOn,
  Reports,
  Right,
  Schedule,
  ScheduleExtended,
  ScheduleSmall,
  Security,
  SecurityBig,
  Search,
  SerenaComplete,
  SerenaNotes,
  SerenaRepeat,
  Settings,
  Share,
  Sort,
  SortAsc,
  SortCombo,
  SortDesc,
  Spinner,
  Square,
  SquareCheck,
  Star,
  Stopwatch,
  SyncWhite,
  SyncWhiteLrg,
  TaskComplete,
  TaskCompleteHollow,
  TaskRepeat,
  TeamSettings,
  TemplatePlus,
  Tick,
  TimeTracking,
  TimeTrackingExtended,
  TickAnimated,
  TickGreen,
  TickThick,
  UnlockOutline,
  WarningCircle,
  ViewControl,
  Zoom,
};

export default Icons;
