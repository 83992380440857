import { format, parse } from 'date-fns';

import { DateString } from '@float/types';

export const DATEFNS_DATE_FORMAT = 'yyyy-MM-dd';

export function parseFloatDate(date: DateString): Date {
  return parse(date, DATEFNS_DATE_FORMAT, new Date());
}

export function formatToFloatDate(date: Date): DateString {
  return format(date, DATEFNS_DATE_FORMAT);
}
