import { useSyncExternalStore } from 'react';

import { CellsMap } from '@float/types/cells';

/**
 * Hook that subscribes to the cells heights updates
 */
export function useCellsHeights(cells: CellsMap) {
  return useSyncExternalStore(
    cells._subscribeToHeightsUpdate,
    () => cells._heightsSnapshot,
  );
}
