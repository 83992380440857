import { useEffect, useState } from 'react';
import { debounce } from 'lodash';

export default function useWindowSize(debounced = false, debounceDelay = 0) {
  const [windowSize, setWindowSize] = useState<{
    height: undefined | number;
    width: undefined | number;
  }>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    let handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    handleResize();

    if (debounced) {
      handleResize = debounce(handleResize, debounceDelay, { trailing: true });
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [debounced, debounceDelay]);

  return windowSize;
}
