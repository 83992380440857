import { RumEventCallback } from './types';

declare global {
  interface Performance {
    // The memory type is missing in the TS dom typings
    memory: {
      usedJSHeapSize: number;
    };
  }
}

const getUsedJSHeapSizeMb = () =>
  Math.round(performance.memory.usedJSHeapSize / (1024 * 1024));

export function trackMemoryUsage(callback: RumEventCallback) {
  if (!('memory' in performance)) return () => {};

  const onPageHide = () => {
    if (document.visibilityState === 'hidden') {
      const memory = getUsedJSHeapSizeMb();

      callback({
        name: 'memory-usage',
        value: memory,
        delta: memory,
      });
    }
  };

  addEventListener('visibilitychange', onPageHide);

  // unsubscribe
  return () => {
    removeEventListener('visibilitychange', onPageHide);
  };
}
