import { getJWTAccessToken } from '@float/common/actions/jwt';
import socket from '@float/common/lib/liveUpdates/socket';
import { convertToReadOnlyPath } from '@float/common/lib/request/common/convertToReadOnlyPath';
import { config } from '@float/libs/config';
import { logger } from '@float/libs/logger';

import { getEndpointConfig, normalizeServerError } from './makeRequest.helpers';

const MAX_ATTEMPTS = 2;

const LOGIN_PATH = '/login';

export enum MakeRequestPagination {
  Auto = 2,
  On = 1,
  Off = 0,
}

export type MakeRequestParams<Data = undefined, Id = undefined> = {
  version?: string;
  attempt?: number;
  resource?: string;
  method?: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
  query?: Record<string, string>;
  pagination?: MakeRequestPagination;
  body?: BodyInit;
} & (Data extends Record<any, any> ? { data: Data } : { data?: undefined }) &
  (Id extends string | number ? { id: Id } : { id?: undefined });

export type Payload<D> = {
  data: D;
};

export type PayloadAndID<D, I> = {
  data: D;
  id: I;
};

export class HttpError extends Error {
  status: number;

  constructor(message: string, status: number) {
    super(message);
    this.status = status;
  }
}

const handleError = <D, I>(err: any = {}, params: MakeRequestParams<D, I>) => {
  const statusCode = err.status || err.statusCode;

  logger.log(
    `Encountered an error while making a request to: ${params.resource}`,
    {
      level: 'error',
      context: {
        error: err,
      },
    },
  );

  if (err.timeout || [401, 403].includes(statusCode)) {
    if (params.attempt !== undefined && params.attempt < MAX_ATTEMPTS) {
      params.attempt += 1;
      return makeRequest(params);
    } else if (err.timeout) {
      return Promise.reject(new Error('timeout'));
    }
  }
  throw err;
};

export async function makeRequest<R, D = undefined, I = undefined>(
  params: MakeRequestParams<D, I>,
): Promise<R> {
  const {
    body,
    resource,
    method,
    query,
    id,
    pagination = MakeRequestPagination.Auto,
  } = params;
  let { data } = params;

  params.attempt = params.attempt || 1;

  const { apiOrigin, shouldUseAccessToken } = getEndpointConfig(
    config.api.preventRelativeHostname,
    config.api.hostname,
    config.api.apiHostname,
    resource,
  );

  const queryString =
    query &&
    Object.keys(query)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`,
      )
      .join('&');

  let uri = `${apiOrigin}${resource}${id ? `/${id}` : ''}${
    queryString ? `?${queryString}` : ''
  }`;

  const headers: Record<string, string> = {
    Accept: 'application/json',
    'notify-uuid': socket.uuid ?? '',
  };

  if (!body) {
    headers['Content-Type'] = 'application/json';
  }

  if (params.version) {
    headers['Accept-Version'] = params.version;
  }

  const opts: RequestInit = {
    method,
    credentials: shouldUseAccessToken ? 'omit' : 'same-origin',
    headers,
  };

  if (shouldUseAccessToken) {
    const accessToken = await window.reduxStoreDispatch(
      getJWTAccessToken(params.attempt > 1),
    );
    headers['X-Token-Type'] = 'JWT';
    headers.Authorization = `Bearer ${accessToken}`;
  }

  if (data) {
    opts.body = JSON.stringify(data);
  } else if (body) {
    opts.body = body;
  }

  const errorHandler = async (err: unknown) => {
    await handleError(err, params);
  };

  if (config.isSharedView) {
    uri = convertToReadOnlyPath(uri);
  }

  return fetch(uri, opts)
    .then(async (response: Response) => {
      if (!response.ok) {
        if (typeof response.json !== 'function') {
          throw new Error('API3 responded with status other than 2xx.');
        }

        const serverError = await response.json();

        if (serverError === null) {
          return Promise.reject(
            new HttpError(
              `Response status: ${response.status}`,
              response.status,
            ),
          );
        }

        const normalizedServerError = normalizeServerError(serverError);

        return await Promise.reject({
          ...normalizedServerError,
          status: response.status,
        });
      }

      // Handle `login` page redirects
      if (response.redirected && response.url.endsWith(LOGIN_PATH)) {
        window.location.replace(response.url);
        return Promise.resolve();
      }

      if (response.status === 204) {
        return Promise.resolve();
      }

      let withPagination = false;

      if (pagination === MakeRequestPagination.Off) {
        withPagination = false;
      } else if (pagination === MakeRequestPagination.On) {
        withPagination = true;
      } else if (method === 'GET' && !id) {
        withPagination = true;
      }

      if (withPagination) {
        return Promise.all([
          response.json(),
          parseInt(response.headers.get('x-pagination-page-count')!, 10),
          parseInt(response.headers.get('x-pagination-total-count')!, 10),
        ]);
      }

      return response.json();
    })
    .catch(errorHandler);
}
