import React from 'react';
import * as Styled from 'styled-components';

import Theme, { GlobalStyles } from '@float/ui/deprecated/Theme';

/**
 * @deprecated - Can be removed when color tokens have been replaced with `@float/theme`
 */
export const ThemeProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  return (
    <Styled.ThemeProvider theme={Theme}>
      <GlobalStyles />
      {children}
    </Styled.ThemeProvider>
  );
};
