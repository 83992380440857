import { t } from '@lingui/macro';

import { Rights } from '@float/common/lib/acl';
import { AnchorItem } from '@float/libs/web/hooks/useAnchor';
import { CurrentUser } from '@float/types/account';

import { StepBaseProps } from './components/StepBase';
import QuickAdd from './components/steps/QuickAdd';
import QuickControls from './components/steps/QuickControls';
import Schedule from './components/steps/ScheduleStep';
import SideNavigation from './components/steps/SideNavigation';
import { ViewsSelectorStep } from './components/steps/ViewsSelectorStep';
import { Welcome } from './components/WelcomeTour';

export type ProductTourStep = {
  id: string;
  title: string;
  anchors: AnchorItem[];
  component: React.FC<StepBaseProps>;
  isDisabled?: (user: CurrentUser) => boolean;
};

export const getProductTourConfig = (): ProductTourStep[] => [
  {
    id: 'welcome',
    title: t`Welcome to Float`,
    anchors: [{ id: 'welcome' }],
    component: Welcome,
  },

  {
    id: 'navigation',
    title: t`Start navigating`,
    anchors: [
      {
        id: 'navigation-desktop',
        config: {
          position: 'right',
          alignment: 'start',
          arrowPosition: '25px',
        },
      },
      {
        id: 'navigation-mobile',
        config: {
          position: 'right',
          alignment: 'start',
          arrowPosition: '18px',
          padding: [10, 10],
        },
      },
    ],
    component: SideNavigation,
  },

  {
    id: 'schedule',
    title: t`Set your focus`,
    anchors: [
      {
        id: 'schedule-desktop',
        config: {
          position: 'bottom',
          alignment: 'start',
          arrowPosition: '75px',
        },
      },
      {
        id: 'schedule-mobile',
        config: {
          position: 'right',
          alignment: 'start',
          arrowPosition: '18px',
          padding: [10, 10],
        },
      },
    ],
    component: Schedule,
  },

  {
    id: 'quick-controls',
    title: t`Customise your view`,
    anchors: [
      {
        id: 'quick-controls',
        config: {
          position: 'bottom',
          alignment: 'end',
          padding: [7, 7],
          arrowPosition: '324px',
        },
      },
    ],
    component: QuickControls,
  },

  {
    id: 'views',
    title: t`Save custom Views`,
    anchors: [
      {
        id: 'views-selector',
        config: {
          position: 'bottom',
          alignment: 'start',
          padding: [7, 12],
          arrowPosition: '20px',
        },
      },
    ],
    component: ViewsSelectorStep,
  },

  {
    id: 'add-button',
    title: t`Quick add`,
    anchors: [
      {
        id: 'add-button',
        config: {
          position: 'bottom',
          alignment: 'end',
          padding: [5, 5],
          arrowPosition: '326px',
        },
      },
    ],
    component: QuickAdd,
    isDisabled: (user) =>
      // the quick add step will be disabled if
      // user can't do any of the actions below
      // this logic is borrowed from getGlobalAddActions() @ web/src/components/Nav/Nav.js
      // which is how we set the visibility of the quick actions button
      !Rights.canCreateTask(user) &&
      !Rights.canCreateLoggedTime(user) &&
      !Rights.canCreateTimeoff(user) &&
      !Rights.canCreateProject(user) &&
      !Rights.canCreatePeople(user),
  },
];
