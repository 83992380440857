import { useWatch } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { ProjectFormData } from '../types';
import { useIsPhasePanel } from './useIsPhasePanel';

type FilterType = 'person' | 'task' | 'phase' | 'project';

function getPhaseFilter(props: {
  phaseName: string;
  isPhase: boolean;
  key: FilterType;
}) {
  const { phaseName, isPhase, key } = props;
  const isFilteringPersonOrTask = key === 'person' || key === 'task';
  const shouldAppendPhaseFilter = isPhase && isFilteringPersonOrTask;
  return shouldAppendPhaseFilter
    ? `&phase=${encodeURIComponent(phaseName)}`
    : '';
}

export const useProjectScheduleFilter = ({
  key,
  value,
}: {
  key: FilterType;
  value: string | undefined;
}) => {
  const isPhase = useIsPhasePanel();
  const projectName = useWatch<ProjectFormData, 'project.project_name'>({
    name: 'project.project_name',
  });
  const phaseName = useWatch<ProjectFormData, 'phase.phase_name'>({
    name: 'phase.phase_name',
  });
  const history = useHistory();
  const handler = () => {
    if (!value) return;

    const phaseFilter = getPhaseFilter({ phaseName, isPhase, key });
    const projectFilter =
      key === 'project' ? '' : `&project=${encodeURIComponent(projectName)}`;

    history.push(
      `?${key}=${encodeURIComponent(value)}${projectFilter}${phaseFilter}`,
    );
  };
  return handler;
};
