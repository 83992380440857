import { connect } from 'react-redux';

import withOnMount from '../../decorators/withOnMount';
import FreeTrialUpgradeComponent from './component';

const mapStateToProps = (state) => {
  const {
    plan = {},
    is_trial: isTrial,
    trial_days_left: daysBeforeExpire,
  } = state.settingsBillingInfo;
  return {
    isTrial,
    planUnknown: plan.plan_id == 0,
    hasTimeTracking: state.companyPrefs.time_tracking > 0,
    companyId: state.currentUser.cid,
    daysBeforeExpire,
  };
};

const FreeTrialUpgradeWithOnMount = withOnMount(FreeTrialUpgradeComponent);
const StatefulFreeTrialUpgrade = connect(mapStateToProps)(
  FreeTrialUpgradeWithOnMount,
);

export default StatefulFreeTrialUpgrade;
