import React from 'react';

export default function () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <g stroke="#868D92" strokeWidth="2">
          <g>
            <g transform="translate(-410 -1073) translate(299 1061) translate(50 11)">
              <g>
                <path
                  d="M20 7l-6.39 7.302c-.344.394-.933.455-1.352.142L9.76 12.57c-.426-.32-1.027-.25-1.368.16L4 18h0"
                  transform="translate(61 1)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
