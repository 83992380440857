import React, { StrictMode } from 'react';
import { Link } from 'react-router-dom';
import { t } from '@lingui/macro';

import { determineEntityStatus } from '@float/common/actions/lib/determineEntityStatus';
import { appendFilter } from '@float/common/lib/url';
import { stopPropagation } from '@float/libs/utils/events/stopPropagation';
import { Phase } from '@float/types/phase';
import { Project } from '@float/types/project';
import { Tag } from '@float/ui/components/Tag';
import { Tooltip } from '@float/ui/components/Tooltip';
import {
  getEntityStatusIcon,
  getEntityStatusLabel,
} from '@float/ui/helpers/label/entityStatusLabel';

import * as styles from './styles.css';

export const EntityStatusTag = (props: {
  entity: Project | Phase;
  location: { query: { access: number }; pathname: string };
}) => {
  const status = determineEntityStatus(props.entity);
  const label = getEntityStatusLabel(status);
  const icon = getEntityStatusIcon(status);
  const to = appendFilter({ projectStatus: label }, props.location);
  const title = t`Filter by ${label} status`;
  return (
    <StrictMode>
      <Tooltip content={label} placement="left">
        <Link to={to} title={title} onClick={stopPropagation}>
          <Tag className={styles.entityStatusTag} size="icon">
            {React.createElement(icon)}
          </Tag>
        </Link>
      </Tooltip>
    </StrictMode>
  );
};
