import { TaskMeta } from '@float/types';

import {
  TASK_META_LOAD_FAILED,
  TASK_META_LOAD_FINISH,
  TASK_META_LOAD_START,
  TaskMetaLoadFailedAction,
  TaskMetaLoadFinishAction,
  TaskMetaLoadStartAction,
} from '../actions/taskMetas';
import { DELETE_TASK_META_SUCCESS } from '../actions/tasks';
import { TaskAction } from './tasks';

export type TaskMetasState = {
  taskMetas: Record<number, TaskMeta[]>;
  loadState: Record<number, 'LOAD_FAILED' | 'LOADING' | 'LOADED'>;
  lastDeletedIds: Set<number> | null;
};

export type TaskMetasAction =
  | TaskMetaLoadStartAction
  | TaskMetaLoadFailedAction
  | TaskMetaLoadFinishAction
  | Extract<TaskAction, { type: typeof DELETE_TASK_META_SUCCESS }>;

export const DEFAULT_STATE: TaskMetasState = {
  taskMetas: {},
  loadState: {},
  lastDeletedIds: null,
};

/**
 * This reducer has been designed to work as a "cache" for the
 * taskMetas in the TaskEditModal and LogTimeModal and enable
 * support for the live updates on the task options.
 */
export function taskMetasReducer(
  state = DEFAULT_STATE,
  action: TaskMetasAction,
) {
  switch (action.type) {
    case TASK_META_LOAD_START: {
      const loadState = { ...state.loadState };

      loadState[action.projectId] = 'LOADING';

      return {
        ...state,
        loadState,
      };
    }

    case TASK_META_LOAD_FINISH: {
      const loadState = { ...state.loadState };
      const taskMetas = { ...state.taskMetas };

      loadState[action.projectId] = 'LOADED';
      taskMetas[action.projectId] = action.taskMetas;

      return {
        ...state,
        loadState,
        taskMetas,
      };
    }

    case TASK_META_LOAD_FAILED: {
      const loadState = { ...state.loadState };

      loadState[action.projectId] = 'LOAD_FAILED';

      return {
        ...state,
        loadState,
      };
    }

    case DELETE_TASK_META_SUCCESS: {
      const taskMetas = { ...state.taskMetas };

      const ids = new Set(action.ids);

      for (const [projectId, list] of Object.entries(taskMetas)) {
        if (list.some((meta) => ids.has(meta.task_meta_id))) {
          taskMetas[Number(projectId)] = list.filter(
            (meta) => ids.has(meta.task_meta_id) === false,
          );
        }
      }

      return {
        ...state,
        taskMetas,
        lastDeletedIds: ids,
      };
    }

    default: {
      return state;
    }
  }
}
