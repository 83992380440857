import React from 'react';

export const IconHelpCenter = () => (
  <svg
    fill="none"
    width={48}
    height={48}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="24.67"
      height="23.67"
      x="40.834"
      y="27.835"
      fill="#F76808"
      stroke="#242C39"
      strokeWidth=".33"
      rx="1.335"
      transform="rotate(179.764 40.834 27.835)"
    />
    <rect
      width="24.67"
      height="32.67"
      x="7.165"
      y="11.165"
      fill="#fff"
      stroke="#242C39"
      strokeWidth=".33"
      rx="1.335"
    />
    <rect
      width="19.67"
      height="4.67"
      x="9.847"
      y="14.361"
      fill="#F9C6C6"
      stroke="#242C39"
      strokeWidth=".33"
      rx=".585"
    />
    <path
      fill="#40C4AA"
      stroke="#000"
      strokeWidth=".33"
      d="M21.34 39.223c.21-.35.317-.737.323-1.155v-.005a2.236 2.236 0 0 0-.674-1.606c-.44-.442-.96-.67-1.55-.67-.617 0-1.148.226-1.585.671v.001c-.43.445-.644.984-.639 1.606a2.244 2.244 0 0 0 .638 1.623l.001.002c.437.445.968.67 1.585.67.396 0 .764-.102 1.099-.308l.002-.001c.33-.21.598-.486.8-.828Zm0 0-.142-.084.142.084Zm-3.698-4.352v.103c0 .202.163.365.365.365h2.851a.365.365 0 0 0 .365-.365v-.101c.006-.568.076-1.036.205-1.41.136-.375.338-.704.607-.99.28-.288.64-.57 1.083-.842a7.308 7.308 0 0 0 1.43-1.136h.001c.409-.43.726-.92.951-1.472.233-.562.347-1.2.347-1.91 0-1.062-.256-1.98-.775-2.746-.516-.762-1.227-1.34-2.128-1.736-.9-.402-1.93-.6-3.087-.6-1.056 0-2.025.196-2.906.59-.886.392-1.6.99-2.138 1.79-.242.362-.314.631-.292.835a.476.476 0 0 0 .203.356l.015.01.007.004 2.296 1.556a.408.408 0 0 0 .382.03.505.505 0 0 0 .27-.246c.054-.117.13-.236.207-.349.028-.042.059-.085.088-.126a5.12 5.12 0 0 0 .12-.175c.197-.308.446-.536.75-.687l.002-.001a2.1 2.1 0 0 1 .98-.24c.358 0 .68.078.969.23.294.153.527.369.7.65.17.28.258.607.258.989 0 .36-.077.682-.23.97-.155.29-.366.556-.634.798a7.954 7.954 0 0 1-.932.721 6.125 6.125 0 0 0-1.25 1.058c-.358.402-.625.923-.808 1.556v.001c-.177.636-.266 1.478-.272 2.52Z"
    />
  </svg>
);
