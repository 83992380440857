import {
  FETCH_PUBLIC_HOLIDAYS,
  FETCH_PUBLIC_HOLIDAYS_FAILURE,
  FETCH_PUBLIC_HOLIDAYS_SUCCESS,
} from '../actions/publicHolidays';
import {
  DEFAULT_SORT_TABLE,
  getInitialSortableTableState,
  getInitialSortPropsState,
  getInitialStateCreator,
  reducerCreator,
} from './reducerHelpers';

const getInitialState = getInitialStateCreator(
  Object.assign(
    {
      sortableTables: {
        [DEFAULT_SORT_TABLE]: getInitialSortableTableState('date', 'asc'),
      },
    },
    getInitialSortPropsState(['date']),
  ),
);

// must be enclosed in a function for constants to be available for evaluation
const publicHolidays = reducerCreator(
  {
    FETCH_ENTITIES: FETCH_PUBLIC_HOLIDAYS,
    FETCH_ENTITIES_SUCCESS: FETCH_PUBLIC_HOLIDAYS_SUCCESS,
    FETCH_ENTITIES_FAILURE: FETCH_PUBLIC_HOLIDAYS_FAILURE,
  },
  'holiday_id',
  getInitialState,
);

export default publicHolidays;
