import React from 'react';

export default class OutsideHandler extends React.Component {
  static defaultProps = {
    // Introduced during Earhart filters, to minimize event listeners.
    // Defaulting to true for backward compatibility.
    enable: true,
    onClickOutside: () => {},
  };

  componentDidMount() {
    if (this.props.enable) {
      this.addMouseDownEventListener();
    }
  }

  componentDidUpdate(prevProps) {
    const { enable } = this.props;
    if (prevProps.enable !== enable) {
      if (enable) this.addMouseDownEventListener();
      else this.removeEventListeners();
    }
  }

  componentWillUnmount() {
    this.removeEventListeners();
  }

  addMouseDownEventListener = () => {
    document.addEventListener('mousedown', this.onMouseDown);
  };

  removeEventListeners = () => {
    document.removeEventListener('mousedown', this.onMouseDown);
    document.removeEventListener('mouseup', this.onMouseUp);
  };

  isTargetExcluded = (target) => {
    const { excludeRef } = this.props;
    let exclusions = [];
    if (excludeRef) {
      exclusions = Array.isArray(excludeRef) ? excludeRef : [excludeRef];
    }

    return exclusions.some((x) => {
      const el = x?.current;
      return el?.contains && el.contains(target);
    });
  };

  isDescendantOfRoot = (target) => {
    return this.wrapperRef && this.wrapperRef.contains(target);
  };

  // Use mousedown/mouseup to enforce that clicks remain outside the root's
  // descendant tree, even when dragged. This should also get triggered on
  // touch devices.
  onMouseDown = (e) => {
    if (!this.isDescendantOfRoot(e.target)) {
      document.addEventListener('mouseup', this.onMouseUp);
    }
  };

  onMouseUp = (e) => {
    const { onClickOutside } = this.props;

    const ignoreClick =
      this.isDescendantOfRoot(e.target) || this.isTargetExcluded(e.target);

    document.removeEventListener('mouseup', this.onMouseUp);

    if (!ignoreClick) {
      onClickOutside(e);
    }
  };

  setWrapperRef = (ref) => {
    this.wrapperRef = ref;
  };

  render() {
    if (this.props.noWrapper) {
      return React.cloneElement(this.props.children, {
        ref: this.setWrapperRef,
      });
    }

    return (
      <div ref={this.setWrapperRef} style={this.props.style}>
        {this.props.children}
      </div>
    );
  }
}
