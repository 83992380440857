import React, { useMemo, useState } from 'react';
import { Trans } from '@lingui/macro';
import * as Popover from '@radix-ui/react-popover';

import { getSearchFilteredProjects } from '@float/common/search/selectors/projects';
import { getUser } from '@float/common/selectors/currentUser';
import { userCanSeeBudgets } from '@float/common/selectors/projects';
import { useAppSelectorStrict } from '@float/common/store';
import { Button } from '@float/ui/components/Button';
import { IconPlusCircle } from '@float/ui/icons/essentials/IconPlusCircle';

import { usePeopleByDept } from '../../panelViews/Project/fields/ProjectTeamMemberField.hooks';
import { TeamMemberQuickAddForm } from './TeamMemberQuickAddForm';
import { getShowingRates } from './TeamMemberQuickAddForm.hooks';

import * as styles from './AssignTeamMemberDropdown.css';

export const AssignTeamMemberDropdown = ({
  totalHeight,
}: {
  totalHeight: number;
}) => {
  const [open, setOpen] = useState(false);
  const style = useMemo(() => {
    return { top: totalHeight + 57 };
  }, [totalHeight]);
  const projects = useAppSelectorStrict(getSearchFilteredProjects);
  const project = projects[0];
  const projectTeam = project.people_ids.map((id) => ({ people_id: id }));

  const canSeeBudget = useAppSelectorStrict((state) =>
    userCanSeeBudgets(getUser(state)),
  );
  const showingRates = useMemo(
    () => getShowingRates(project, canSeeBudget),
    [project, canSeeBudget],
  );
  const peopleOptionsByDept = usePeopleByDept(projectTeam, showingRates);
  const isDisabled = useMemo(() => {
    return (
      peopleOptionsByDept.reduce(
        (acc, dept) => acc + dept.options.length,
        0,
      ) === 0
    );
  }, [peopleOptionsByDept]);
  if (open && isDisabled) {
    setOpen(false);
  }
  return (
    <div className={styles.wrapper} style={style}>
      <Popover.Root modal open={open} onOpenChange={setOpen}>
        <Popover.Trigger asChild>
          <Button
            size="xsmall"
            appearance="clear-flay"
            iconStart={IconPlusCircle}
            disabled={isDisabled}
          >
            <Trans>Assign a team member</Trans>
          </Button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content align="start" sideOffset={4} hideWhenDetached>
            <TeamMemberQuickAddForm
              projectId={project.project_id}
              peopleOptionsByDept={peopleOptionsByDept}
            />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};
