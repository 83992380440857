import styled from 'styled-components';

import { LIGHT_PURPLE, MID_TEAL, PURPLE, TEAL } from '../constants';

export const BarChartContainer = styled.div`
  margin-top: 24px;

  & > svg {
    overflow: visible;
  }

  .axis path,
  .axis line {
    fill: none;
    stroke: #000;
    shape-rendering: crispEdges;
  }

  .bar {
    fill: ${TEAL};
  }

  .bar-billable {
    fill: ${TEAL};
  }

  .bar-nonbillable {
    fill: ${MID_TEAL};
  }

  .bar-unscheduled,
  .bar-logged-unscheduled {
    fill: white;
    stroke: ${(p) => p.theme.grey};
    stroke-width: 1px;
  }

  .bar-tentative-billable,
  .bar-tentative-nonbillable {
    fill: url('#tentative-checkerboard');
    opacity: 0.6;
  }

  .bar-timeoff-bg,
  .bar-logged-timeoff-bg {
    fill: white;
  }

  .bar-timeoff,
  .bar-logged-timeoff {
    fill: url('#timeoff-stripe');
    opacity: 0.5;
  }

  @media print {
    .bar-timeoff,
    .bar-logged-timeoff {
      fill: ${(p) => p.theme.grey};
      opacity: 0.5;
    }
  }

  .bar-scheduled {
    fill: #ccc;
  }

  .bar-available,
  .bar-logged-available {
    fill: #363d46;
  }

  .bar-logged {
    fill: #ccc;
  }

  .bar-logged-billable {
    fill: ${PURPLE};
  }

  .bar-logged-nonbillable {
    fill: ${LIGHT_PURPLE};
  }

  .bar-logged,
  .bar-logged-billable,
  .bar-logged-available,
  .bar-logged-timeoff {
    pointer-events: none;
  }

  .bar-handler,
  .bar-logged-handler {
    outline: none;
    fill: transparent;
    stroke: 0;
  }

  .bar-today {
    fill: #008ce3;
    pointer-events: none;
  }

  text {
    font-family: 'urw-din', 'Helvetica Neue', 'Helvetica', sans-serif;
    font-size: 14px;
  }

  .x.axis path {
    display: none;
  }

  .x.axis .tick line {
    display: none;
  }

  .x.axis .tick text {
    fill: #868d92;
  }

  .x.axis .tick text.today-label {
    fill: #363d46;
    font-weight: bold;
  }

  .y.axis .tick:first-child {
    display: none;
  }

  .y.axis.axis-lines .tick text {
    display: none;
  }

  .y.axis.axis-lines .tick line {
    stroke: rgba(0, 0, 0, 0.12);
  }

  .y.axis.axis-labels .tick text {
    fill: ${(p) => p.theme.medGrey};
  }

  .y.axis.axis-labels .tick line {
    display: none;
  }

  .axis text {
    fill: #000;
  }

  .d3-tip {
    line-height: 1;
    font-weight: bold;
    padding: 14px;
    background: #fff;
    color: #fff;
    border-radius: 2px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    pointer-events: none !important;
  }

  .d3-tip:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.5em;
    bottom: -2em;
    left: 45% !important;
    top: 100%;
    box-sizing: border-box;
    border: 7px solid #000;
    border-color: transparent transparent #fff #fff;
    transform-origin: 0 0;
    transform: rotate(-45deg);
    box-shadow: -2px 2px 1px 0 rgba(0, 0, 0, 0.15);
  }

  .d3-tip .row.centered {
    text-align: center;
  }

  .d3-tip strong {
    color: #000;
    font-size: 15px;
  }

  .d3-tip .tip-circle {
    position: absolute;
    height: 8px;
    width: 8px;
    border-radius: 4px;
    left: 47% !important;
    background: #363d46;
    top: calc(100% + 27px);
  }

  .d3-tip .tip-header {
    font-size: 18px;
    font-weight: bold;
    color: #363d46;
  }

  .d3-tip .tip-table {
    margin: 12px auto 0 auto;
    flex-direction: row;
  }

  .d3-tip .tip-table-left,
  .d3-tip .tip-table-right {
    display: inline-block;
  }

  .d3-tip .tip-table-left .row {
    overflow: hidden;
  }

  .d3-tip .tip-table-left label {
    width: 100px;
  }

  .d3-tip .tip-table-left span {
    display: inline-block;
    float: right;
  }

  .d3-tip .tip-table-right {
    vertical-align: top;
  }

  .d3-tip .tip-table-right .row {
    margin-left: 6px;
  }

  .d3-tip .tip-table-right .row,
  .d3-tip .tip-table-right span {
    text-align: right;
  }

  .d3-tip .tip-table .row {
    margin-top: 3px;
    position: relative;
  }

  .d3-tip .tip-table .row.empty {
    height: 14px;
  }

  .d3-tip .tip-table .row .red {
    color: #f00;
  }

  .d3-tip .tip-table .row .desc {
    font-weight: 300;
    color: #939a9d;
  }

  .d3-tip .tip-table .row label,
  .d3-tip .tip-table .row span {
    font-size: 14px;
    display: inline-block;
    box-sizing: border-box;
  }

  .d3-tip .tip-table .row label {
    display: inline-block;
  }

  .d3-tip .tip-table .row span {
    text-align: right;
    color: #000;
    font-weight: 700;
  }

  .d3-tip label,
  .d3-tip span {
    color: #a6a2a4;
    font-weight: 400;
  }

  .d3-tip label {
    width: 70px;
    display: inline-block;
  }
  /* Creates a small triangle extender for the tooltip */
  .y.axis path.domain {
    display: none;
  }

  @media (max-width: 618px) {
    .x.axis text {
      display: none;
    }
  }
  /* Style northward tooltips differently */
  .d3-tip.n:after {
    margin: -1px 0 0 0;
    top: 100%;
    left: 0;
  }

  .marker {
    text-align: center;
    overflow: visible;
  }

  .marker-content {
    outline: none;
  }
`;
