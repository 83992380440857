import { createSelector } from 'reselect';

import { accountPeopleInManagementGroup } from '@float/common/lib/acl';
import { CurrentUser } from '@float/types/account';
import {
  Notification,
  NotificationsState,
  NotificationStateItem,
  NotificationType,
  NotificationTypes,
} from '@float/types/notification';
import { Person } from '@float/types/person';
import { Timeoff } from '@float/types/timeoff';
import { TimeoffStatuses } from '@float/types/timeoffStatus';
import { NotificationsFilter } from '@float/web/components/NotificationsPanel/NotificationsPanel.types';
import { WebAppStateStrict } from '@float/web/reducers/types';
import { getPeopleMap, getTimeoffsMap, getUser } from '@float/web/selectors';

const getNotificationFeedFromActivityData = (state: WebAppStateStrict) =>
  state.activityData.activityCollections.notificationFeed?.items || [];

const getAccounts = (state: WebAppStateStrict) => state.accounts.accounts;
const getNotificationsState = (state: WebAppStateStrict) => state.notifications;
const getFilter = (_: WebAppStateStrict, filter: NotificationsFilter) => filter;

export const getNotifications = createSelector(
  [getNotificationsState],
  (notifications) => Object.values(notifications.items),
);

function parseNotification(
  item: Notification<NotificationTypes>,
  notification: NotificationStateItem,
  accounts: Record<string, { avatar?: string | null }>,
): Notification<NotificationTypes> {
  return {
    ...item,
    unseen: !notification.seen,
    actioned_by: {
      ...item.actioned_by,
      avatar: accounts[item.actioned_by.account_id]?.avatar || null,
    },
  };
}

function filterTimeoffRequests(
  notification: Notification<NotificationTypes>,
  isTimeoffApprovalsEnabled: boolean,
  timeoffs: Record<string, Timeoff>,
  user: CurrentUser,
  people: Record<string, Person>,
): boolean {
  if (notification.item_type !== NotificationType.Timeoff) return false;

  const notificationData = notification.after_data as Timeoff;
  if (!isTimeoffApprovalsEnabled || !notificationData) return false;

  const timeoff = timeoffs[notificationData.timeoff_id];
  if (!timeoff || !timeoff.status_request) return false;

  const peopleId = notificationData.people_ids?.[0];
  if (!peopleId) return false;

  const isMyOwn = user.people_id === peopleId;
  const isDirectManagement = accountPeopleInManagementGroup(user, {
    person: people[peopleId],
  });

  return isMyOwn || isDirectManagement;
}

function handleTentativeTimeoffs(
  feed: Notification<NotificationTypes>[],
  timeoffs: Record<string, Timeoff>,
): Notification<NotificationTypes>[] {
  const pending: Notification<NotificationTypes>[] = [];

  const filteredFeed = feed.filter((notification) => {
    const timeoff = timeoffs[(notification.after_data as Timeoff)?.timeoff_id];
    if (timeoff?.status === TimeoffStatuses.TENTATIVE) {
      pending.push(notification);
      return false;
    }
    return true;
  });

  return [...pending, ...filteredFeed];
}

export const getNotificationFeed = createSelector(
  [
    getNotificationFeedFromActivityData,
    getNotificationsState,
    getTimeoffsMap,
    getUser,
    getAccounts,
    getFilter,
    getPeopleMap,
  ],
  (
    notificationFeed: Notification<NotificationTypes>[] = [],
    notifications: NotificationsState,
    timeoffs: Record<string, Timeoff>,
    user: CurrentUser,
    accounts: Record<string, { avatar?: string | null }>,
    filter: NotificationsFilter,
    people: Record<string, Person>,
  ): {
    notifications: Notification<NotificationTypes>[];
    currentPage: number;
    loadState: NotificationsState['loadState'];
  } => {
    const isTimeoffApprovalsEnabled = Boolean(user.timeoff_approvals);
    const { page: currentPage, loadState } = notifications;
    const items = Object.values(notifications.items);

    let feed = notificationFeed.map((item) => {
      const notification =
        items.find((n) => n.af_id === item._id) ||
        ({} as NotificationStateItem);
      return parseNotification(item, notification, accounts);
    });

    if (filter === NotificationsFilter.Requests) {
      feed = feed.filter((notification) =>
        filterTimeoffRequests(
          notification,
          isTimeoffApprovalsEnabled,
          timeoffs,
          user,
          people,
        ),
      );
      feed = handleTentativeTimeoffs(feed, timeoffs);
    }

    return { notifications: feed, currentPage, loadState };
  },
);
