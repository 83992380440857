import styled, { keyframes } from 'styled-components';

import * as Animation from '@float/ui/deprecated/Earhart/Animation';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Elevations from '@float/ui/deprecated/Earhart/Elevations';

const pulse = keyframes`
  0% { transform: translate(-50%, -50%) scale(1); }
  50% { transform: translate(-50%, -50%) scale(0.9); }
  100% { transform: translate(-50%, -50%) scale(1); }
`;

export const StyledDotCalloutContainer = styled.div`
  position: relative;

  div {
    position: absolute;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    &:nth-child(1) {
      ${Elevations.E500};

      width: 26px;
      height: 26px;

      border-radius: 11px;

      background-color: rgba(255, 255, 255, 0.7);
    }

    &:nth-child(2) {
      width: 16px;
      height: 16px;

      border-radius: 8px;

      background-color: ${Colors.FIN.Lt.Emphasis.Primary};
    }
  }

  /**
   * Animation
   */

  &.enter,
  &.appear {
    div {
      opacity: 0;

      transform: translate(-50%, -50%) scale(0);
    }
  }

  &.enter-active,
  &.appear-active {
    div {
      opacity: 1;

      transform: translate(-50%, -50%);

      transition-property: opacity, transform;
      transition-duration: 0.25s, 0.35s;
      transition-timing-function: ${Animation.Settings.Easing.DefaultOut},
        ${Animation.Settings.Easing.OutBounce};

      &:nth-child(2) {
        transition-delay: 0.05s;
      }
    }
  }

  &.enter-done,
  &.appear-done {
    div {
      animation: ${pulse} 2000ms ease-out infinite;

      &:nth-child(1) {
        animation-delay: 0.25s;
      }
    }
  }

  &.exit {
    opacity: 1;

    transform: translate(-50%, -50%);
  }

  &.exit-active {
    opacity: 0;

    transform: translate(-50%, -50%) scale(0);

    transition-property: opacity, transform;
    transition-duration: 0.25s;
    transition-timing-function: ${Animation.Settings.Easing.DefaultOut};
  }
`;
