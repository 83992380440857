import { useState } from 'react';
import { forEach, isNil } from 'lodash';

export const required =
  (msg = 'This field is required.') =>
  (value) => {
    const invalid = isNil(value) || value === '';
    if (invalid) {
      return [msg];
    }
    return [];
  };

export const useInput = (initialValue = '', opts) => {
  const [value, setValue] = useState(initialValue);
  const [errors, setErrors] = useState([]);

  const validate = () => {
    const { validate: validateInput } = opts || {};
    let newErrors;
    if (typeof validateInput === 'function') {
      newErrors = validateInput(value);
    } else {
      newErrors = [];
    }
    setErrors(newErrors);
    return !newErrors.length;
  };

  const onChange = (e) => {
    const value = e.target ? e.target.value : e.value;
    setValue(value);
    setErrors([]);
  };

  return {
    value,
    errors,
    onChange,
    validate,
    setErrors,
  };
};

export const useForm = (fields) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = true;
    forEach(fields, (field) => {
      if (typeof field.validate === 'function') {
        isValid = field.validate() && isValid;
      }
    });
    return isValid;
  };
  return {
    handleSubmit,
  };
};

export const useLoader = (
  initialState = { loading: false, loaded: false, active: false },
) => {
  const [loader, setLoader] = useState(initialState);
  const setLoading = (value = true) => {
    setLoader({ loading: value, loaded: false, active: value });
  };
  const setLoaded = (value = true) => {
    setLoader({ loaded: value, loading: false, active: value });

    // clear loaded after 3 seconds
    setTimeout(() => {
      setLoader({ loaded: false, loading: false, active: false });
    }, 3000);
  };
  return {
    loader,
    setLoading,
    setLoaded,
  };
};
