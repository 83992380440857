import React from 'react';

const RestrictedMessage = () => (
  <h2>
    Sorry, you don't have access to this section. Please navigate away using
    links on the left.
  </h2>
);

export default RestrictedMessage;
