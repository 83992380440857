import React from 'react';
import { cloneDeep } from 'lodash';

import { moment } from '@float/libs/moment';
import { Icons } from '@float/ui/deprecated';

import {
  HistoryRemoveIconWrapper,
  HistoryTooltipContainer,
  HistoryTooltipDates,
  HistoryTooltipDays,
} from './WorkDays.styled';

function formatWorkDays(days) {
  const initials = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  return days.map((d, idx) => `${initials[idx]}${d}`).join(', ');
}

export default function HistoryTooltip(props) {
  const history = cloneDeep(props.history);

  const lines = history
    .map(([date, hours], idx) => {
      if (!hours) return null;

      const isRootHistory = date === '1970-01-01';
      const toDate =
        idx !== 0 &&
        moment(history[idx - 1][0])
          .add(-1, 'day')
          .format('MMM D, yyyy');
      const fromDate = !isRootHistory && moment(date).format('MMM D, yyyy');

      const isRemovable = !isRootHistory && date !== props.currentApplyToDate;

      const removeIcon = isRemovable ? (
        <HistoryRemoveIconWrapper onClick={() => props.removeEntry(date)}>
          <Icons.CloseSmall />
        </HistoryRemoveIconWrapper>
      ) : (
        <HistoryRemoveIconWrapper />
      );

      if (isRootHistory) {
        return (
          <React.Fragment key={date}>
            <HistoryTooltipDays isRemovable={isRemovable}>
              {formatWorkDays(hours)}
            </HistoryTooltipDays>
            <HistoryTooltipDates isRemovable={isRemovable}>
              to {toDate}
            </HistoryTooltipDates>
            {removeIcon}
          </React.Fragment>
        );
      }

      if (fromDate && !toDate) {
        return (
          <React.Fragment key={date}>
            <HistoryTooltipDays isRemovable={isRemovable}>
              {formatWorkDays(hours)}
            </HistoryTooltipDays>
            <HistoryTooltipDates isRemovable={isRemovable}>
              from {fromDate}
            </HistoryTooltipDates>
            {removeIcon}
          </React.Fragment>
        );
      }

      if (fromDate && toDate) {
        return (
          <React.Fragment key={date}>
            <HistoryTooltipDays isRemovable={isRemovable}>
              {formatWorkDays(hours)}
            </HistoryTooltipDays>
            <HistoryTooltipDates isRemovable={isRemovable}>
              from {fromDate} to {toDate}
            </HistoryTooltipDates>
            {removeIcon}
          </React.Fragment>
        );
      }

      return null;
    })
    .filter(Boolean);

  return <HistoryTooltipContainer>{lines}</HistoryTooltipContainer>;
}
