import React, { useMemo, useState } from 'react';

const NavStateContext = React.createContext();
const NavUpdaterContext = React.createContext();

function NavProvider(props) {
  const [quickActionCtrls, setQuickActionCtrls] = useState([]);
  const [leftControls, setLeftControls] = useState([]);

  const state = useMemo(
    () => ({ quickActionCtrls, leftControls }),
    [quickActionCtrls, leftControls],
  );
  const updater = useMemo(
    () => ({
      registerQuickActionCtrls: setQuickActionCtrls,
      registerLeftControls: setLeftControls,
    }),
    [],
  );

  return (
    <NavStateContext.Provider value={state}>
      <NavUpdaterContext.Provider value={updater}>
        {props.children}
      </NavUpdaterContext.Provider>
    </NavStateContext.Provider>
  );
}

function useNavState() {
  const navState = React.useContext(NavStateContext);
  if (typeof navState === 'undefined') {
    throw new Error('useNavState must be used within a NavProvider');
  }
  return navState;
}

function useNavUpdater() {
  const updater = React.useContext(NavUpdaterContext);
  if (typeof updater === 'undefined') {
    throw new Error('useNavUpdater must be used within a NavProvider');
  }
  return updater;
}

export { NavProvider, useNavState, useNavUpdater };
