import { RepeatState } from './repeatState';
import { TaskStatus } from './taskStatus';

export enum TaskMode {
  Task = 'task',
  Timeoff = 'timeoff',
  Status = 'status',
}

export enum AllocationType {
  /** Allocation based on hours with a flexible duration */
  Hours = 0,
  /** Allocation based on hours with a fixed duration */
  HoursWithFixedDuration = 1,
  /** Allocation based on availability percentage */
  Percentage = 2,
}

export type NotesMeta = {
  type: '@';
  locations: { start: number; end: number }[];
  data: { name: string; account_id: number; people_id?: number };
}[];

export type Task = {
  action: unknown;
  billable: 0 | 1;
  created_by: number;
  created: string;
  data_type: 'full'; // flag that says this is not a search-only entity
  end_date: string;
  ext_calendar_event_id: string | null;
  ext_calendar_event_name: null; // TODO: TBD
  ext_calendar_id: string | null;
  ext_calendar_recur_id: string | null;
  hours: number;
  integration_status: number;
  modified_by: number;
  modified: string;
  name: string;
  notes_meta: NotesMeta | null;
  notes: string;
  parent_task_id: string | null;
  people_ids: number[];
  phase_id: number | null;
  priority_info: Record<number, number>;
  project_id: number;
  repeat_end_date: string | null;
  repeat_end?: string | null;
  repeat_state: RepeatState;
  root_task_id: string | null;
  start_date: string;
  start_time: string | null;
  status: TaskStatus;
  task_id: string;
  task_meta_id: number;
  allocation_type: AllocationType;

  // Metadata that should be managed through weakmap
  replacesTempId?: string | number;

  // Metadata that should be managed through derivation
  length?: number;
  repeatInstances?: { start_date: string; end_date: string }[]; // `_allInstancesMemoKey` is identical
  instanceCount: number;
  allInstances?: Task[];
  isSidebarItem?: boolean;
  originalId?: string;
  temporaryId?: string;
};

export type RawTask = {
  action: unknown;
  actionMode?: any; // included in formatTaskForRequest
  billable: 0 | 1;
  created_by: number;
  created: string;
  createdTs: number | string;
  end_date: string;
  ext_calendar_event_id: string | null;
  ext_calendar_event_name: null; // TODO: TBD
  ext_calendar_id: string | null;
  ext_calendar_recur_id: string | null;
  hours: number;
  hours_pd?: string;
  integration_status: number;
  modified_by: number;
  modified: string;
  name?: string;
  task_name?: string;
  notes_meta: NotesMeta | null;
  notes?: string;
  task_notes?: string;
  parent_task_id: string | null;
  people_id?: number;
  people_ids?: number[];
  phase_id: number | null;
  priority_info: Record<number, number>;
  priority?: number;
  project_id: number;
  repeat_end?: string;
  repeat_end_date?: string | null;
  repeat_state: number;
  root_task_id: string | null;
  start_date: string;
  start_time: string | null;
  status: TaskStatus;
  task_id: string;
  task_meta_id: number;
  temporaryId?: string;
  priority_update?: { timeoffs: Record<number, number> };
  allocation_type: AllocationType;
};

// Tasks coming from the Search API
export type SearchTask = Pick<
  Task,
  | 'billable'
  | 'name'
  | 'people_ids'
  | 'phase_id'
  | 'project_id'
  | 'status'
  | 'task_id'
> & {
  data_type: 'search'; // flag that says this is a search-only entity
};

// TODO: Omit all relevant fields.
export type NewTask = Omit<
  Task,
  | 'task_id'
  | 'billable'
  | 'priority_info'
  | 'created'
  | 'created_by'
  | 'modified'
  | 'modified_by'
  | 'ext_calendar_event_id'
  | 'ext_calendar_event_name'
  | 'ext_calendar_id'
  | 'ext_calendar_recur_id'
  | 'project'
  | 'phase'
  | 'client'
  | 'repeatInstances'
  | 'instanceCount'
  | 'allInstances'
>;

export type TaskMeta = {
  billable: 0 | 1;
  budget: number | null;
  company_id: number;
  count_logged_time: number;
  count_tasks: number;
  created: string;
  max_integration_status: string | null;
  min_integration_status: string | null;
  modified: string;
  phase_id: number;
  project_id: number;
  task_meta_id: number;
  task_name: string;
};

export type RawTaskMeta = {
  billable: 0 | 1;
  budget: string | null;
  company_id: number;
  count_logged_time: number;
  count_tasks: number;
  created: string;
  max_integration_status: string | null;
  min_integration_status: string | null;
  modified: string;
  phase_id: number;
  project_id: number;
  task_meta_id: number;
  task_name: string;
};

export type TaskMergeParams = {
  task_meta_ids: number[];
  task_name: string;
  billable: 0 | 1;
};

export type ProjectTaskRecord = {
  isSelected?: boolean;
  merge?: number[];
  unnamedTask?: boolean;
} & Pick<
  TaskMeta,
  | 'billable'
  | 'budget'
  | 'count_logged_time'
  | 'count_tasks'
  | 'task_name'
  | 'task_meta_id'
>;

type TaskChange = {
  entity: Task;
  undoBatchId: unknown;
  originalEntity: Task;
};

export type SplitTaskChanges = [TaskChange, { entity: Task }];

export type TemplateTask = {
  task_meta_id: number;
  task_name: string;
  billable: 0 | 1;
  budget?: number;
};
