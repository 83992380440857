import React from 'react';
import { useFormContext } from 'react-hook-form';
import { t } from '@lingui/macro';

import {
  getClientColors,
  getRecentColors,
} from '@float/common/selectors/colors';
import { useAppSelectorWithParams } from '@float/common/store';
import { TextButton } from '@float/ui/deprecated/Earhart/Buttons';
import { PanelType } from '@float/web/sidePanel/types';
import { useSidePanel } from '@float/web/sidePanel/useSidePanel';
import { SidePanelHeader } from 'components/SidePanel/SidePanelHeader';

import { SidePanelClose } from '../../../../SidePanel/SidePanelClose';
import { ArchivedTag } from '../../components/ArchivedTag';
import {
  ShimmerCircle,
  ShimmerContainer,
  ShimmerLine,
} from '../../components/Shimmer';
import { ColorField } from '../../fields/ColorField';
import { NameField } from '../../fields/NameField';
import { useIsTemplate } from '../../hooks/useIsTemplate';
import { ProjectFormData } from '../../types';
import { PhasePanelMenu } from '../ProjectPhasesSection/ProjectPhasesMenu';
import { PhaseNavigation } from './PhaseNavigation';

import * as styles from './styles.css';

export function PhaseHeaderSection(props: {
  isEditing: boolean;
  isLoading?: boolean;
  isArchived?: boolean;
  onClose: () => void;
}) {
  const { backToProjectPanel } = useSidePanel(PanelType.PhasePanel);
  const isTemplate = useIsTemplate();
  const { getValues } = useFormContext<ProjectFormData>();
  const clientId = getValues('project.client_id');
  const projectName = getValues('project.project_name');
  const projectColor = getValues('project.color');
  const projectId = getValues('projectId');
  const phaseName = getValues('phase.phase_name');

  const handleBreadcrumbClick = () => {
    if (projectId) {
      backToProjectPanel(projectId);
      return;
    }

    // If we don't have a projectId, we're creating a new project
    // and editing a phase detail in that new project. In this case,
    // clicking the project name in breadcrumb should close the panel.
    props.onClose();
  };

  const isCreating = !props.isEditing;
  const isArchivedPhase = props.isArchived && !isTemplate;
  const clientColors = useAppSelectorWithParams(
    getClientColors,
    clientId as number,
  );
  const recentColors = useAppSelectorWithParams(getRecentColors);

  if (props.isLoading) {
    return (
      <SidePanelHeader>
        <ShimmerContainer className={styles.phaseShimmerWrapper}>
          <ShimmerCircle />
          <ShimmerLine />
        </ShimmerContainer>
      </SidePanelHeader>
    );
  }

  return (
    <div className={styles.sidePanelHeader}>
      <div className={styles.phaseProjectNavRow}>
        <div className={styles.breadcrumb}>
          {projectName && (
            <>
              <TextButton
                className={styles.phaseProjectNav}
                onClick={handleBreadcrumbClick}
              >
                <span className={styles.phaseProjectName}>{projectName}</span>
              </TextButton>
              {'/ '}
            </>
          )}
          {phaseName}
        </div>
        <div className={styles.phasePanelActions}>
          {props.isEditing && (
            <PhasePanelMenu className={styles.phasePanelMenu} />
          )}
          <SidePanelClose />
        </div>
      </div>

      <div className={styles.phaseNameRow}>
        <ColorField
          aria-label={t`Phase color`}
          autoFocus={!isCreating}
          name="phase.color"
          clientColors={clientColors}
          recentColors={recentColors}
          defaultColor={projectColor}
        />
        <NameField
          autoFocus={isCreating}
          name="phase.phase_name"
          placeholder={t`Phase name`}
        />
        {isArchivedPhase && (
          <ArchivedTag data-testid="phase-archive-tag" size="large" />
        )}
        <PhaseNavigation projectId={projectId} />
      </div>
    </div>
  );
}
