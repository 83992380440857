import useLocalStorage from 'react-use/esm/useLocalStorage';

import { trackEvent } from '@float/common/lib/gtm';
import { AllocationType } from '@float/types/task';

export type AllocationSettings = {
  defaultAllocationType: AllocationType;
};

export const ALLOCATION_SETTINGS_CACHE_KEY = 'allocationSettings';
export const ALLOCATION_SETTINGS_DEFAULT_VALUE: AllocationSettings = {
  defaultAllocationType: AllocationType.Hours,
};

export const useAllocationSettings = () => {
  const [allocationSettingsCached, setAllocationSettingsCached] =
    useLocalStorage(
      ALLOCATION_SETTINGS_CACHE_KEY,
      ALLOCATION_SETTINGS_DEFAULT_VALUE,
    );

  const allocationSettings: AllocationSettings = {
    ...ALLOCATION_SETTINGS_DEFAULT_VALUE,
    ...allocationSettingsCached,
  };

  const setAllocationSettings = (
    key: keyof AllocationSettings,
    value: AllocationSettings[keyof AllocationSettings],
  ) => {
    if (key === 'defaultAllocationType') {
      trackEvent('Toggle allocation fixed end date', {
        value,
      });
    }

    setAllocationSettingsCached({
      ...allocationSettings,
      [key]: value,
    });
  };

  return {
    allocationSettings,
    setAllocationSettings,
  };
};
