import { useMemo } from 'react';

import { getDateString } from '@float/common/lib/utils';
import { moment } from '@float/libs/moment';

import { InputAllocationDateRangeProps } from '../InputAllocationDateRange';

export type UseInputAllocationDateRangeParams = Pick<
  InputAllocationDateRangeProps,
  'startDate' | 'endDate' | 'onChange'
>;

export const useInputAllocationDateRange = ({
  startDate,
  endDate,
  onChange,
}: UseInputAllocationDateRangeParams) => {
  const startDateMoment = useMemo(() => moment(startDate), [startDate]);
  const startDateFormatted = useMemo(
    () => getDateString(startDate),
    [startDate],
  );

  const endDateMoment = useMemo(() => moment(endDate), [endDate]);
  const endDateFormatted = useMemo(() => getDateString(endDate), [endDate]);

  const handleStartDateChange = (value: string) => {
    const startDateMoment = moment(value).startOf('day');
    const endDateMoment = moment(endDate).startOf('day');

    const isStartDateValueAfterEndDate = startDateMoment.isAfter(endDateMoment);

    const startDateValue = startDateMoment.toDate();

    const endDateValue = isStartDateValueAfterEndDate
      ? startDateMoment.clone().toDate()
      : endDate;

    onChange({
      startDate: startDateValue,
      endDate: endDateValue,
    });
  };

  const handleEndDateChange = (value: string) => {
    onChange({
      startDate,
      endDate: moment(value).startOf('day').toDate(),
    });
  };

  return {
    startDateMoment,
    startDateFormatted,
    endDateMoment,
    endDateFormatted,
    handleStartDateChange,
    handleEndDateChange,
  };
};
