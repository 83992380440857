export enum CycleVariant {
  Cooldown = 'cooldown',
  Current = 'current',
  Completed = 'completed',
  Upcoming = 'upcoming',
}

export enum CycleEdgeCase {
  StartsOutOfBounds = 'startsOutOfBounds',
  EndsOutOfBounds = 'endsOutOfBounds',
  StartsAndEndsOutOfBounds = 'startsAndEndsOutOfBounds',
}

export type CooldownCycleConfig = {
  key: string;
  totalDays: number;
  variant: CycleVariant.Cooldown;
  edgeCase?: CycleEdgeCase;
};

export type StandardCycleConfig = {
  daysFromStart: number;
  daysFromStartOfPreviousCycle: number;
  daysFromEndOfPreviousCycle: number;
  edgeCase?: CycleEdgeCase;
  key: string | number;
  name: string | number;
  totalDays: number;
  /**
   * `spanDays` represents the length of the cycle excluding
   * the start and end day markers (if they exists)
   */
  spanDays: number;
  variant: Omit<CycleVariant, CycleVariant.Cooldown>;
};

export type CycleConfig = StandardCycleConfig | CooldownCycleConfig;
