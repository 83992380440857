import React from 'react';

export default function ({ size = '14', color = '#1A90DC' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 14 14"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M11 0c1.657 0 3 1.343 3 3v8c0 1.657-1.343 3-3 3H3c-1.657 0-3-1.343-3-3V3c0-1.657 1.343-3 3-3h8zM7 3H4.5l-.144.007C3.595 3.08 3 3.72 3 4.5V7l.007.144C3.08 7.905 3.72 8.5 4.5 8.5H6V10c0 .552.448 1 1 1h3c.552 0 1-.448 1-1V7c0-.552-.448-1-1-1H8.5V4.5l-.007-.144C8.42 3.595 7.78 3 7 3zm0 1l.09.008c.233.042.41.247.41.492V6H7c-.552 0-1 .448-1 1v.5H4.5l-.09-.008C4.177 7.45 4 7.245 4 7V4.5l.008-.09C4.05 4.177 4.255 4 4.5 4H7z"
      />
    </svg>
  );
}
