import React, { CSSProperties, ReactNode } from 'react';

import Loader from '@float/common/components/elements/Loader';

import { LoaderWrapper, StyledModalBody } from './styles';

const ModalBody = ({
  className,
  isLoading,
  children,
  style = {},
}: {
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
  isLoading?: boolean;
}) => {
  return (
    <StyledModalBody className={className} style={style}>
      {isLoading ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        children
      )}
    </StyledModalBody>
  );
};

export default ModalBody;
