import React, { MouseEvent } from 'react';
import { t } from '@lingui/macro';

import { PeopleProjectViewStatus, updateUserPref } from '@float/common/actions';
import { useAppDispatchStrict } from '@float/common/store';
import { CheckboxCheckedState } from '@float/ui/components/Checkbox/types';
import { IconFolderPlus } from '@float/ui/icons/essentials/IconFolderPlus';
import { ManageQuickFilters } from '@float/web/components/ManageQuickFilters';
import { ActiveArchivedFilterOption } from '@float/web/components/ManageQuickFilters/components/ActiveArchivedFilter';

export type ProjectQuickFiltersProps = {
  activeCount: number;
  archivedCount: number;
  canCreate: boolean;
  currentFilterValue: PeopleProjectViewStatus;
  currentCheckboxValue: boolean;
  onCheckboxChange: (checked: boolean) => void;
  onClickCreate: (e: MouseEvent) => void;
};

export const ProjectQuickFilters = (props: ProjectQuickFiltersProps) => {
  const {
    activeCount,
    archivedCount,
    canCreate,
    currentCheckboxValue,
    currentFilterValue,
    onCheckboxChange,
    onClickCreate,
  } = props;

  const dispatch = useAppDispatchStrict();

  const updateUserPreferences = (projectView: PeopleProjectViewStatus) => {
    dispatch(updateUserPref('proj_view', projectView));
  };

  const onFilterChangeHandler = (option: ActiveArchivedFilterOption) => {
    updateUserPreferences(option.value);
  };

  const onCheckboxChangeHandler = (checked: CheckboxCheckedState) => {
    onCheckboxChange(Boolean(checked));
  };

  return (
    <ManageQuickFilters
      activeCount={activeCount}
      archivedCount={archivedCount}
      canCreate={canCreate}
      checkboxLabel={t`Only show my projects`}
      createLabel={t`Add project`}
      currentCheckboxValue={currentCheckboxValue}
      currentFilterValue={currentFilterValue}
      iconCreate={IconFolderPlus}
      onCheckboxChange={onCheckboxChangeHandler}
      onClickCreate={onClickCreate}
      onFilterChanged={onFilterChangeHandler}
    />
  );
};
