import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  clearAsanaPreload,
  fetchAsanaCoInts,
  removeAsanaCoInt,
  updateAsanaConfig,
} from 'integrations/actions/asana';
import { get, isEmpty } from 'lodash';

import Setup from '../shared/Setup';

function AsanaSetup(props) {
  const { integration, fetchCoInts, ...others } = props;
  const coInt = get(integration, 'coInts[0]');
  useEffect(() => {
    /* eslint-disable no-use-before-define */
    if (
      isEmpty(integration.coInts) &&
      integration.coIntsLoadState !== 'LOADING'
    ) {
      fetchCoInts();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Setup
      type="asana"
      label="Asana"
      preloadData={integration.preloadData}
      config={integration.config}
      coInt={coInt}
      isUpdating={integration.updateConfigLoadState === 'LOADING'}
      showEstimateSection={!!integration.preloadData?.estimateFields}
      {...others}
    />
  );
}

const mapStateToProps = (state) => ({
  integration: state.integrations.asana,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCoInts: () => dispatch(fetchAsanaCoInts()),
  updateConfig: (coInt, config, options) =>
    dispatch(updateAsanaConfig(coInt, config, options)),
  removeCoInt: (coIntId, archiveData) =>
    dispatch(removeAsanaCoInt(coIntId, archiveData)),
  clearPreload: () => dispatch(clearAsanaPreload()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AsanaSetup);
