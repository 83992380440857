import { getTagLabel, isObjectTag } from '../../selectors/tags';

const getEntitiesWithTag = (entities, tagMatchSet) => {
  return Object.values(entities).filter((entity) => {
    return entity.tags.some((tag) => tagMatchSet.has(getTagLabel(tag)));
  });
};

export const applyTagNameUpdate = (
  state,
  entityType,
  idKey,
  tagMatchSet,
  getPatchedTags,
) => {
  const hasNameUpdates = tagMatchSet.size > 0;
  if (!hasNameUpdates) {
    return state;
  }

  const entities = getEntitiesWithTag(state[entityType], tagMatchSet);
  if (!entities.length) {
    return state;
  }

  const updatedEntities = {};
  entities.forEach((entity) => {
    const nextTags = getPatchedTags(entity);
    const entityId = entity[idKey];
    updatedEntities[entityId] = {
      ...entity,
      tags: nextTags,
    };
  });

  return {
    ...state,
    [entityType]: {
      ...state[entityType],
      ...updatedEntities,
    },
  };
};

export const handleUpdateTag = (state, action, { key, id, type }) => {
  const tagsOfType = action.tags.filter((tag) => tag.type === type);

  const nextTagNameByOldTagName = tagsOfType.reduce((acc, tag) => {
    const previousName = getTagLabel(action.allTags[tag.tags_id]);
    const nextName = tag.name;
    if (previousName !== nextName) {
      acc[previousName] = nextName;
    }

    return acc;
  }, {});

  const tagsNamesToUpdate = new Set(Object.keys(nextTagNameByOldTagName));

  return applyTagNameUpdate(state, key, id, tagsNamesToUpdate, (entity) => {
    const doesEntityHasObjectTags = isObjectTag(entity.tags[0]);

    return entity.tags.map((tag) => {
      const tagLabel = getTagLabel(tag);
      const nextTagName = nextTagNameByOldTagName[tagLabel] || tagLabel;

      return doesEntityHasObjectTags
        ? {
            ...tag,
            name: nextTagName,
          }
        : nextTagName;
    });
  });
};

export const handleRemoveTag = (state, action, { key, id, type }) => {
  if (action.tags.some((tag) => tag === null || tag === undefined)) {
    throw new Error('Tags cannot contain null or undefined values');
  }

  const tagsOfType = action.tags.filter((tag) => tag.type === type);
  const tagsNamesToUpdate = new Set(tagsOfType.map((tag) => tag.name));

  return applyTagNameUpdate(state, key, id, tagsNamesToUpdate, (entity) => {
    return entity.tags.filter((tag) => {
      const tagLabel = getTagLabel(tag);
      return !tagsNamesToUpdate.has(tagLabel);
    });
  });
};
