import React from 'react';

import { AppStore } from '@float/common/store';
import { Activity } from '@float/types/activity';
import { Tag } from '@float/ui/deprecated/Earhart/Tags/Tag';

import { getActivityType } from '../types/data';
import { Avatar } from './Avatar';
import { formatActivityText } from './formatActivityText';
import { formatTimestamp } from './formatTimestamp';
import { ItemClickCallback } from './types';

export function parseActivityItemToRow(
  item: Activity,
  onItemClick: ItemClickCallback,
  store: AppStore,
) {
  const result = formatActivityText(item, onItemClick, store);

  if (!result) return null;

  const { companyPrefs } = store.getState();

  const { text: plainText, element: textElem } = result;

  const type = getActivityType(item.item_type);

  const row = {
    id: item._id,
    data: [
      formatTimestamp(item.actioned_timestamp, companyPrefs),
      <Avatar key={item._id} person={item.actioned_by} />,
      textElem,
      type ? (
        <Tag key={item._id} size="small" color={type.color}>
          {type.label}
        </Tag>
      ) : (
        '-'
      ),
      plainText,
    ],
    isDeletable: false,
    isEdited: false,
  };

  return row;
}
