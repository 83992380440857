import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash';

import { trackEvent } from '@float/common/lib/gtm';
import { getFiltersAnalyticsProps } from '@float/common/search/helpers/getFiltersAnalyticsProps';
import { getMeFilter } from '@float/common/selectors/search';
import { getActiveFilters } from '@float/common/selectors/views/getActiveFilters';
import { useAppSelector } from '@float/common/store';

export type SearchTrackingOpts = {
  enabled?: boolean;
  additionalProps?: Record<string, unknown>;
};

export function useSearchFiltersAnalyticsTracking(
  eventName: string,
  opts: SearchTrackingOpts,
) {
  const filters = useAppSelector(getActiveFilters);
  const me = useAppSelector(getMeFilter);

  const previous = useRef<Record<string, unknown>>({});

  const { enabled = true, additionalProps } = opts;

  useEffect(() => {
    if (!enabled) {
      // resend the events when the hook is re-enabled
      return () => {
        previous.current = {};
      };
    }

    const filtersProps = getFiltersAnalyticsProps(filters, me);

    const data = additionalProps
      ? {
          ...filtersProps,
          ...additionalProps,
        }
      : filtersProps;

    // If the props are empty does not fire the event, skip
    // we want to track when filters/props are applied
    if (data.filters.length === 0) return;

    // If the payload is equal to the previous value, skip
    // we want to track when the filters/props are changing
    if (isEqual(data, previous.current)) return;

    previous.current = data;

    trackEvent(eventName, {
      ...data,
      version: 2,
    });
  }, [eventName, filters, me, enabled, additionalProps]);
}
