import React, { forwardRef, HTMLAttributes } from 'react';
import cn from 'classnames';

import * as styles from './styles.css';

export type ListBoxProps = HTMLAttributes<HTMLDivElement>;

const ListBox = forwardRef<HTMLDivElement, ListBoxProps>((props, ref) => {
  const { children, className, ...rest } = props;

  return (
    <div {...rest} ref={ref} className={cn(styles.wrapper, className)}>
      {children}
    </div>
  );
});

export { ListBox };
