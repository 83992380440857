import { useEffect } from 'react';

import { ensureTagsLoaded } from '@float/common/actions/tags';
import { ensureTagsGroupsLoaded } from '@float/common/actions/tagsGroups';
import {
  getAdminPeopleTags,
  getAdminProjectTags,
} from '@float/common/selectors/tags';
import { useAppDispatchStrict, useAppStore } from '@float/common/store';

import { fetchPeople } from '../../actions/people';
import { fetchProjects } from '../../actions/projects';
import { getIsTagsPageLoading } from './TagsV2.helpers';
import { useAdminTagsGroups } from './useAdminTagsGroups';
import { useAdminTagsType } from './useAdminTagsType';

export function useData() {
  const store = useAppStore();
  const dispatch = useAppDispatchStrict();
  const state = store.getState();

  const isLoading = getIsTagsPageLoading({
    loadStatePeople: state.people.loadState,
    loadStateProjects: state.projects.loadState,
    loadStateTags: state.tags.loadState,
    loadStateTagsGroups: state.tagsGroups.loadState,
  });

  const tagsPeople = getAdminPeopleTags(state);
  const tagsProjects = getAdminProjectTags(state);

  const numTagsPeople = tagsPeople.length;
  const numTagsProjects = tagsProjects.length;

  /**
   * ToDo: convert those hooks to helper functions
   *
   * https://linear.app/float-com/project/tag-groups-tags-page-and-components-0d4285ed2a3d
   */
  const tagsType = useAdminTagsType();
  const tagsGroups = useAdminTagsGroups({
    tagsType,
  });

  useEffect(() => {
    dispatch(fetchPeople());
    dispatch(fetchProjects());
    dispatch(ensureTagsLoaded());
    dispatch(ensureTagsGroupsLoaded());
  }, [dispatch]);

  return {
    isLoading,
    tagsPeople,
    tagsProjects,
    tagsGroups,
    numTagsPeople,
    numTagsProjects,
  };
}
