import React from 'react';

export default function ({ size = '24', color = '#00AC00', style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      style={style}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M10 16.95L5 12 7 10 10 13 17 6 19 8z"
      />
    </svg>
  );
}
