import { QueryClient } from '@tanstack/react-query';

import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';

import { normalize } from '../search/helpers';
import {
  MatchAlgorithm,
  PaginatedQueryResponse,
  RemoteEntityType,
} from './search.types';

export function getQueryAlg() {
  return featureFlags.isFeatureEnabled(FeatureFlag.SearchBeyondLimitsNewAlg)
    ? MatchAlgorithm.CONTAINS
    : MatchAlgorithm.FULL_TEXT_WILDCARD;
}

export function getLookupAlg() {
  return featureFlags.isFeatureEnabled(FeatureFlag.SearchBeyondLimitsNewAlg)
    ? MatchAlgorithm.EXACT
    : MatchAlgorithm.FULL_TEXT_EXACT;
}

export const lookupQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 1, // 1 minute
      gcTime: 1000 * 60 * 5, // 5 minutes
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
});

export function getLookupCacheKey(value: string, category: RemoteEntityType) {
  return ['lookup', normalize(value), category];
}

export function fillLookupCache([items, pageCount]: PaginatedQueryResponse) {
  // If the results are paginated we don't
  // not know if we have all the ids of a given
  // label.
  if (pageCount !== 1) {
    return;
  }

  const buckets = new Map<RemoteEntityType, Map<string, number[]>>();

  for (const item of items) {
    const map = buckets.get(item.type);

    if (!map) {
      buckets.set(item.type, new Map([[item.name, item.ids.slice()]]));
      continue;
    }

    const ids = map.get(item.name);

    if (!ids) {
      map.set(item.name, item.ids.slice());
    } else {
      for (const id of item.ids) {
        ids.push(id);
      }
    }
  }

  for (const [category, map] of buckets) {
    for (const [value, ids] of map) {
      lookupQueryClient.setQueryData(getLookupCacheKey(value, category), ids);
    }
  }
}
