import { Rights } from '@float/common/lib/acl';
import { CurrentUser, Person } from '@float/types';

export function canSeeStatusTab(user: CurrentUser, people: Person[]) {
  if (people.length === 0) {
    return true;
  }

  return people.some((person) => {
    return Rights.canCreateStatus(user, { person });
  });
}
