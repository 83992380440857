import {
  Phase,
  Project,
  ProjectStatus,
  TaskStatus,
  TaskStatusEnum,
} from '@float/types';

export function getTaskStatusOnSave(
  newStatus: TaskStatus,
  project?: Project,
  phase?: Phase,
): TaskStatus {
  const parentToInheritFrom = phase || project;
  if (parentToInheritFrom?.status === ProjectStatus.Draft) {
    return TaskStatusEnum.Draft;
  }
  const isParentTentative = parentToInheritFrom?.tentative;
  return isParentTentative ? TaskStatusEnum.Tentative : newStatus;
}
