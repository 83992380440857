import React, { forwardRef } from 'react';

import * as styles from './styles.css';

export const SuccessIllustration = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div className={styles.illustration} ref={ref}>
      <div className={styles.emoji} data-animation="emoji">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          width={120}
          height={121}
          viewBox="0 0 120 121"
        >
          <path
            fill="#F3C6E2"
            stroke="#242C39"
            strokeWidth="2.1"
            d="M114.6 1.5c2-1.3 4.6.3 4.2 2.7l-10.5 63.4c-.3 2-2.4 2.9-4 1.9L62.1 42.9a2.7 2.7 0 0 1-.1-4.5l52.5-36.9Z"
          />
          <path
            fill="#2E5FE8"
            d="m115.6 29.9 1.3-8-10.2-16.1-5 3.5 13.9 20.6ZM113 46l1.2-7.6-17-26-4.5 3.2L113 46.1Zm-2.7 15.8 1.3-7.9-23.5-35-5 3.5 27 39.8.2-.4ZM78.6 25.6 74 28.8l25.5 38.5.6-8.7-21.6-33Z"
          />
          <path
            fill="#242C39"
            d="m98 66.8 2.5 1.6.7-10L79.4 25l-1.7 1.2 21.4 32.7-.4 5.2-23.8-36-1.7 1.2L98 66.8ZM82.2 23 110 64v-.1l.8-4.1-.6.7L84 21.8 82.2 23Zm6.8-4.7 22.9 34.1-.5 3-24.1-36 1.7-1.1Zm2.9-2.1 20.8 31.4.5-3L93.6 15l-1.7 1.2Zm6.1-4.3 16.4 25-.5 3L96.4 13l1.7-1.2Zm2.9-2 14.4 21.5.5-3-13.2-19.7-1.7 1.2Zm6.7-4.7 9.6 15.1-.5 3-10.9-16.9 1.8-1.2Z"
          />
          <path
            stroke="#242C39"
            strokeWidth="2.1"
            d="M114.6 1.5c2-1.3 4.6.3 4.2 2.7l-10.5 63.4c-.3 2-2.4 2.9-4 1.9L62.1 42.9a2.7 2.7 0 0 1-.1-4.5l52.5-36.9Z"
          />
          <path
            fill="#FEF2A4"
            stroke="#242C39"
            strokeWidth="2.1"
            d="M112 80.8A41.8 41.8 0 1 1 28.5 75a41.8 41.8 0 0 1 83.5 5.8Z"
          />
          <path
            fill="#F5D90A"
            fillRule="evenodd"
            stroke="#000"
            strokeLinecap="round"
            strokeWidth="2.1"
            d="M79.8 62.6a10 10 0 0 1 10.2 6 2.2 2.2 0 1 0 4-1.7c-1.4-3.3-6-9.5-14.7-8.6a2.2 2.2 0 0 0 .5 4.3Zm-17.2-1.4a10 10 0 0 0-10.9 4.5c-.6 1-2 1.3-3 .7-1-.6-1.3-1.9-.7-3 1.7-3 7.3-8.4 15.7-6.4a2.2 2.2 0 0 1-1 4.2Z"
            clipRule="evenodd"
          />
          <path
            stroke="#000"
            strokeLinecap="round"
            strokeWidth="2.1"
            d="M84.6 77.4a8.8 8.8 0 0 0-11.1-1.7m-9.1-.9a8.8 8.8 0 0 0-11.2 0"
          />
          <path
            fill="#D0E8FF"
            stroke="#000"
            strokeWidth="2.1"
            d="M62.2 91.7c0 .9.3 1.7.7 2.5l-1.8 1.2-6.6-.5.3-3.7 7.4.5Z"
          />
          <path
            fill="#2E5FE8"
            stroke="#000"
            strokeWidth="2.1"
            d="m51.3 90.2 3.6.3-.3 5.1-3.7-.2.4-5.2Z"
          />
          <path
            fill="#D0E8FF"
            stroke="#000"
            strokeLinecap="round"
            strokeWidth="2.1"
            d="M17.8 88.3 51 90.6l-.3 4.3-28-2c-4.3-.3-12.7-.3-17-4-4.4-3.9-3.8-8-3-9.7L4 81.5c3.2 6 10.4 6.5 14 6.8Z"
          />
          <path
            stroke="#000"
            strokeLinecap="round"
            strokeWidth="2.1"
            d="M70.5 89.4c-2.3-1-5.8-2.4-7.2 0-1.4 2.2.2 4.4 1 5-1.3.3-3.7 1.6-2.8 3.8.9 2.2 6 1.3 8.4.6"
          />
        </svg>
      </div>

      <svg
        className={styles.svg}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 1095 434"
      >
        <g id="success-illu-girl">
          <path
            fill="#FFCCA7"
            vectorEffect="non-scaling-stroke"
            stroke="#000"
            strokeWidth="1.5"
            d="m547.7 73.6 1 42a5.5 5.5 0 1 0 11-.6L556 73.1a4.2 4.2 0 0 0-8.3.5Z"
          />
          <path
            fill="#FFCCA7"
            vectorEffect="non-scaling-stroke"
            stroke="#000"
            strokeWidth="1.5"
            d="m547.4 77.7 2 50.3c.3 3.8 2.2 7.4 5.3 9.7l33 24.2c2 1.4 4.5-.9 3.2-3l-29.4-47.7a14.2 14.2 0 0 1-2-5.9L556.3 77a4.5 4.5 0 0 0-9 .7Z"
          />
          <path
            fill="#FFCCA7"
            vectorEffect="non-scaling-stroke"
            stroke="#000"
            strokeWidth="1.5"
            d="m561.8 134.2-6.5-31a4.3 4.3 0 0 0-8.5 1.3l1.3 12.8c.1 1.4 0 3-.3 4.4l-.7 3.6-.3 3 .2 7.7c.1 2 .7 4 1.6 5.8l10.9 19.6c.3.6 1.3.4 1.3-.3l1.2-23.6c0-1 0-2.2-.2-3.3Z"
          />
          <path
            fill="#FFCCA7"
            d="M547.8 130.4c0 14.2 14.8 32.6 21.8 34.8l16.4-11.3-18.7-31.8-5.7-9.5c-10.4 9.3-13.7 12.3-13.8 17.8Z"
          />
          <path
            fill="#40C4AA"
            vectorEffect="non-scaling-stroke"
            stroke="#000"
            strokeWidth="1.5"
            d="m847.6 210.9.3.3.5-.2 42.6-15.8 81 18a45.2 45.2 0 0 1 32 27c14.4 35.2 21 73.2 19.2 111.2l-3.8 81.8H817.7l-9.4-147v-.6l-.5-.1L702.4 250c-1.8-.6-3.6-1.4-5.2-2.4l-139.8-81.3a6.6 6.6 0 0 1-1.6-10l-.6-.4.6.5 18-21a14.2 14.2 0 0 1 17.1-3.6l115.9 56.4 2.3 1 53.7 16.3a157 157 0 0 0 78.2 3.4 7 7 0 0 1 6.6 1.9Z"
          />
          <path
            fill="#FFCCA7"
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            strokeWidth="1.5"
            d="m879.4 155.2 14-.9c7.6-.4 14.3 5.4 14.8 13.2l2.6 38.6a14 14 0 0 1-13 14.8l-14 .8a14 14 0 0 1-14.9-13.1l-2.5-38.6a14 14 0 0 1 13-14.8Z"
          />
          <path
            fill="#FFCCA7"
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            strokeWidth="1.5"
            d="M895 179.4c-23.7 7.6-46.4-5.6-54.4-28.4-5.2-14.5-4-26.3 1-35.2 5-9 14-15.2 24.4-18.6 12-3.8 23.9-6 34-2.7 10 3.2 18.4 11.8 23.5 29.9 2.2 15.7 1 27.6-3.7 36.4-4.7 8.8-12.8 14.7-24.8 18.6Z"
          />
          <path
            fill="#FFCCA7"
            d="M931.7 130.8a12.1 12.1 0 0 1-8 15.6 13 13 0 0 1-16.4-7.7 12.1 12.1 0 0 1 8.1-15.6 13 13 0 0 1 16.3 7.7Z"
          />
          <path
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            strokeLinecap="round"
            strokeWidth="1.5"
            d="M919.3 146.4a12 12 0 0 0 12.6-8.8c3-9.8-8-21-20.9-9.4"
          />
          <path
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            strokeLinecap="round"
            strokeWidth="1.5"
            d="M918.9 128.9c1.6-.8 5.3-1.2 6.8 3.2m-6.8 2.9c-.6-1-1-3.1 2.1-4.1"
          />
          <path
            fill="#FFE3A2"
            vectorEffect="non-scaling-stroke"
            stroke="#000"
            strokeWidth="1.5"
            d="M919 123.6c-38 0-47.4-17.7-50-24a47.9 47.9 0 0 1-18.6 27.6c-14.4 9-10.8 23-4.2 34.4a48.2 48.2 0 0 0 21.6 17.5c0-.4.3 2.8 1.2 19.2 1.2 20.5-2 18.7 9.4 48.8 9.2 24.1-5.6 38.6-9.4 42.8h-51a40.8 40.8 0 0 0 3.5-42.8c-12-22.9 23.5-42.1 12-72.9-11.4-30.7-25.7-76.6 26-97C899 61.6 933.8 85 940 120l1.6 45.2a35 35 0 0 0 15.6 27.8 35 35 0 0 1 13.2 41.7l-1.1 3c-3.1 8-.5 17.2 6.4 22.4l1.4 1.1a44 44 0 0 1 16.9 28.7h-97.4l8-18.2A43.5 43.5 0 0 0 902 232a36 36 0 0 1-5.5-19.6c2.2-55.3 46.1-88.9 22.4-88.9Z"
          />
        </g>

        <g id="success-illu-boy">
          <path
            fill="#D98447"
            vectorEffect="non-scaling-stroke"
            stroke="#000"
            strokeWidth="1.5"
            d="m536 74 2.3 48.4a13 13 0 0 1-6.9 12l-28 14.4 21.6-35.1c1-1.8 1.8-3.8 2-6l3.7-34a2.7 2.7 0 0 1 5.3.3Z"
          />
          <path
            fill="#D98447"
            vectorEffect="non-scaling-stroke"
            stroke="#000"
            strokeWidth="1.5"
            d="m540.9 70.3-.2 49.3a5.4 5.4 0 1 1-10.7-.3l2.8-49.2a4 4 0 0 1 8 .2Z"
          />
          <path
            fill="#D98447"
            vectorEffect="non-scaling-stroke"
            stroke="#000"
            strokeWidth="1.5"
            d="m544.6 75.4-1 56a5.4 5.4 0 1 1-10.9-.3l3.3-56a4.3 4.3 0 0 1 8.6.3Z"
          />
          <path
            fill="#D98447"
            vectorEffect="non-scaling-stroke"
            stroke="#000"
            strokeWidth="1.5"
            d="m547.9 83.4-.7 49a5.4 5.4 0 1 1-10.8-.4l2.9-49a4.3 4.3 0 0 1 8.6.4Z"
          />
          <path
            fill="#D98447"
            vectorEffect="non-scaling-stroke"
            stroke="#000"
            strokeWidth="1.5"
            d="m548 125.1-.9 11.8-.2 1.6c-.5 4.3-3.1 8-7 9.9l-11 5.5a.7.7 0 0 1-1-.8l2.5-11.9c.3-1.2.7-2.4 1.3-3.4l5.8-10.7c.7-1.3 1.2-2.8 1.5-4.3l3.3-19a3.7 3.7 0 0 1 7.3 1L548 125Z"
          />
          <path
            fill="#D98447"
            d="M545.6 125.8c.5 13.8-17.8 18.9-18.4 19.5l-7.4-2.4 5.2-25.7 4.4-12.4c19.7 12.2 15.6 4.7 16.2 21Z"
          />
          <path
            fill="#96C7F2"
            vectorEffect="non-scaling-stroke"
            stroke="#000"
            strokeWidth="1.5"
            d="m239.1 176.5-.6-2 39.2 14.8c9 3.3 18.3 5 27.8 5l123.4-12h.1l.2-.1 75.1-52.9h.1l9.6-9a6.6 6.6 0 0 1 9.6.7l22.6 27.5c.2.3.2.8-.1 1l-97.9 80.9a28.5 28.5 0 0 1-13.4 6.1l-134.3 22.6-.6.1V433H111.8l-9-62.5A291 291 0 0 1 116 234.1l4-11.8a50.3 50.3 0 0 1 36-32.6l77.5-18.2 3.6 3.2 2 1.8Z"
          />
          <path
            fill="#D98447"
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            strokeWidth="1.5"
            d="m224.5 125.8 14.8.9a14 14 0 0 1 13 13.9v24.1c0 5.7.7 11.4 2 17l1.9 7.7c.7 3 0 6.2-2 8.7a31.7 31.7 0 0 1-52-3 10 10 0 0 1-.7-9.2l1-2.4a73.9 73.9 0 0 0 5.7-24l1.3-20.5c.5-7.8 7.2-13.7 15-13.2Z"
          />
          <path
            fill="#374253"
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            strokeWidth="1.5"
            d="m281.2 110.7-.1.1v.2a46.2 46.2 0 0 1-47.3 45.1 43.1 43.1 0 0 1-30.7-10.5 39 39 0 0 1-11-29.3c0-12.5 2.7-26.5 8.6-36.4 2.9-5 6.6-9 11-11.3 4.5-2.3 9.9-3 16.1-1.4a224 224 0 0 0 46.3 6.6h.4l5.7.4c3.8.3 6.9.6 9.2 1.2 2.3.7 3.7 1.5 4.5 2.8.7 1.2.9 3 .3 5.6-.6 2.6-2 6-4.1 10.4-2.2 4.4-5.1 9.8-8.9 16.5Z"
          />
          <path
            fill="#D98447"
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            strokeWidth="1.5"
            d="M277.2 137.1c-8 22.8-30.7 36-54.4 28.3-12-3.8-20.2-9.7-24.8-18.5-4.7-8.9-6-20.7-3.8-36.4 5.2-18.1 13.6-26.7 23.6-30 10-3.2 21.9-1 34 2.8a41.7 41.7 0 0 1 24.3 18.5c5 9 6.2 20.8 1 35.3Z"
          />
          <ellipse
            fill="#D98447"
            rx="12.8"
            ry="12.3"
            transform="matrix(-.9521 -.30577 -.33277 .943 198.2 120.8)"
          />
          <path
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            strokeLinecap="round"
            strokeWidth="1.5"
            d="M198.4 132.5a12 12 0 0 1-12.6-8.8c-3-9.8 8-21 21-9.4"
          />
          <path
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            strokeLinecap="round"
            strokeWidth="1.5"
            d="M198.9 115c-1.7-.8-5.3-1.2-6.9 3.2m6.9 2.8c.6-1 1-3-2.1-4"
          />
          <path
            fill="#374253"
            d="M236.5 93c-19.5 4-26.4 15.4-29 21.3a20 20 0 0 0-13.6-5.2l-.7-2 6.6-19c4.3-2.8 15-7.5 23.2-13.2 10.1-7.1 15.5 3.2 32.4 7 13.6 3.2 20.6 16 22.4 22a48.5 48.5 0 0 0-41.3-11Z"
          />
          <path
            fill="#374253"
            d="M228 113a29 29 0 0 0 20-19.1c-2.2-6.4-6.7-5.7-9-6.4l-33.6 17 2.2 10.8c1.5.7 7.7 1.3 20.2-2.1Z"
          />
          <path
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            strokeLinecap="round"
            strokeWidth="1.5"
            d="M247.8 91.7c-1.5 14.5-21.6 27.6-40.8 22.8"
          />
          <path
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            strokeWidth="1.5"
            d="M249 91.5c6.8.7 22 4.2 28.4 13.2"
          />
        </g>

        <g id="success-illu-check" strokeWidth="1.5">
          <path
            fill="#fff"
            vectorEffect="non-scaling-stroke"
            stroke="#000"
            d="M1068.3 207a24.3 24.3 0 1 1-48.6 0 24.3 24.3 0 0 1 48.6 0Z"
            opacity=".9"
          />
          <path
            fill="#40C4AA"
            fillRule="evenodd"
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            strokeLinecap="round"
            d="M1058.9 195.7c.4 1.3-.3 2.7-1.5 3.1-2.9 1-6.3 4-9.4 8a38 38 0 0 0-6.2 10.8l-1 3.4-10.9-9c-1-.8-1.2-2.4-.4-3.5.8-1 2.3-1.2 3.3-.4l5.8 4.8c1.4-3 3.4-6.3 5.8-9.3 3.2-4 7.3-8 11.5-9.5 1.2-.4 2.6.3 3 1.6Z"
            clipRule="evenodd"
          />
        </g>

        <g id="success-illu-light" strokeWidth="1.5">
          <path
            fill="#A5E4D4"
            vectorEffect="non-scaling-stroke"
            stroke="#000"
            d="M80.7 194.6a36.3 36.3 0 1 1-69.4-21.2 36.3 36.3 0 0 1 69.4 21.2Z"
            opacity=".9"
          />
          <path
            fill="#fff"
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            d="m35 203 12.3 3c.4 0 .7.5.6.9L46 214c0 .4-.5.7-.9.6l-12.4-3a.7.7 0 0 1-.5-1l1.8-7.1c0-.4.5-.6.9-.6Z"
          />
          <path
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            strokeLinecap="round"
            d="m34.2 207.4 12.7 3.2"
          />
          <path
            fill="#fff"
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            d="M54.3 195.3c3-1.9 5.2-4.8 6-8.4 1.9-7.5-2.8-15-10.5-17a14.1 14.1 0 0 0-17.2 10.2 14 14 0 0 0 1.5 10.3c.2.2.3.5.3.7.4 1.4.5 3.9.6 6v1c.1 1.3 1 2.3 2.2 2.6l8.8 2c1.6.4 3.1-.7 3.8-2.1a11.5 11.5 0 0 1 4.5-5.3Z"
          />
          <path
            fill="#F5D90A"
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            d="m57 159.6-1 4a3.3 3.3 0 0 1-6.4-1.6l1-4a3.3 3.3 0 1 1 6.5 1.6Zm12.2 8.9-3.9 3.6a3 3 0 0 1-4 0 3 3 0 0 1 .1-4l3.9-3.6a3 3 0 0 1 4 .1c1 1.1 1 2.8-.1 3.9ZM35.5 164l-1.7-4.6c-.5-1.6.3-3.2 1.8-3.7a3 3 0 0 1 3.8 1.9l1.7 4.7c.5 1.5-.3 3.1-1.9 3.6a3 3 0 0 1-3.7-1.9Z"
          />
          <path
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m42.7 186.6.3 5.6 2.6.7 3-4.8c.5-.8 0-2-1-2.2l-3-.8c-1-.2-2 .5-1.9 1.5Z"
          />
          <path
            fill="#F5D90A"
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            d="m40.2 201 2.3-9.1 3.8 1-2.3 9-3.8-1Z"
          />
        </g>

        <g id="success-illu-cup" strokeWidth="1.5">
          <path
            fill="#fff"
            vectorEffect="non-scaling-stroke"
            stroke="#000"
            d="M413.3 51.5a32.8 32.8 0 1 1-65.6 0 32.8 32.8 0 0 1 65.6 0Z"
            opacity=".9"
          />
          <path
            fill="#F5D90A"
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            d="M380.8 67.7c3.5-4.2 1.4-10.4 0-13l2.8-1.5c4.6 6.9 9.3 7.2 11 6.5l-13.8 8Z"
          />
          <path
            fill="#F5D90A"
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            d="m378.6 68.8 18.2-10.4 3.2 5.7-18.1 10.4-3.3-5.7ZM384 52a2.5 2.5 0 1 1-4.4 2.5 2.5 2.5 0 0 1 4.3-2.4Z"
          />
          <path
            fill="#F5D90A"
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            d="m366.9 46.6-5.3-9.3 16.5-9.5 5.4 9.3c2.2 3.2 4.9 10.6-2.1 14.6s-12.6-1.8-14.5-5.1Z"
          />
          <path
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            d="m379.8 30.5 4.8-2.7c4.3 4.8 9.7 16.5-3.6 24.1"
          />
          <path
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            d="m363.2 40-4.9 2.8c2.6 6.2 10.8 16.7 22.7 9.1"
          />
        </g>

        <g id="success-illu-hands">
          <path
            fill="#fff"
            vectorEffect="non-scaling-stroke"
            stroke="#111"
            strokeWidth="1.5"
            d="M723.3 102.5a29.8 29.8 0 1 1-59.6 0 29.8 29.8 0 0 1 59.6 0Z"
            opacity=".9"
          />

          <path
            fill="#F5D90A"
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            strokeWidth="1.5"
            d="m697.1 97.1 11.8 9.2-2.2 3.5a30 30 0 0 1-5.9 7l-.4.3a3.6 3.6 0 0 1-4.6.2l-8.9-6.7a8.4 8.4 0 0 1-1.4-11.8c2.9-3.7 8-4.5 11.6-1.7Z"
          />
          <path
            fill="#F5D90A"
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            strokeWidth="1.5"
            d="m694.7 100.3-14-11.6c-.6-.5-.7-1.6-.1-2.3.6-.8 1.6-.9 2.3-.3l14 11.6c.6.5.7 1.6 0 2.3-.5.8-1.6.9-2.2.3Z"
          />
          <path
            fill="#F5D90A"
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            strokeWidth="1.5"
            d="m697.8 101.1-4.6-9.5c-.4-.8 0-1.8.8-2.2.8-.5 1.8-.1 2.2.7l4.6 9.4c.4.8 0 1.8-.8 2.3-.8.4-1.8 0-2.2-.7Zm-6.6 1.7-13.9-11.5c-.7-.6-.8-1.7-.1-2.4.6-.8 1.6-.8 2.3-.3l13.9 11.6c.7.5.8 1.6.2 2.3-.7.8-1.7.9-2.4.3Zm-1.2 4.7L676.1 96c-.7-.6-.8-1.7-.2-2.4.6-.8 1.7-.8 2.4-.3l13.9 11.6c.6.5.8 1.6.1 2.3-.6.8-1.6.9-2.3.3Zm-.8 4.8-13.9-11.6c-.7-.5-.8-1.6-.2-2.4.6-.7 1.7-.8 2.4-.2l13.9 11.5c.6.6.7 1.7.1 2.4s-1.6.8-2.3.3Z"
          />
          <path
            fill="#F5D90A"
            d="m687 109.9 8.8 7 5.8-14 .3-2.2-1-.8-6.3-6-.4 1.4-.9 2.7-7.4 8 1.6 2.3-.6 1.6Z"
          />
          <path
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            strokeWidth="1.5"
            d="M697.3 103.4c-1-.3-2.7-1.5-2.9-4.3-.2-3.5 0-4.5-.8-5.6"
          />
          <path
            fill="#F5D90A"
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            strokeWidth="1.5"
            d="m701.7 97.3 9.2 7.2a3.7 3.7 0 0 1 .8 5l-.4.5a30 30 0 0 1-6 7l-.3.4a3.6 3.6 0 0 1-4.6.2l-9-6.8a8.4 8.4 0 0 1-1.3-11.8c2.8-3.7 8-4.5 11.6-1.7Z"
          />
          <path
            fill="#F5D90A"
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            strokeWidth="1.5"
            d="m699.2 100.5-13.9-11.6c-.7-.5-.8-1.6-.2-2.3.7-.8 1.7-.9 2.4-.3l13.9 11.6c.7.5.8 1.6.1 2.3-.6.8-1.6.9-2.3.3Z"
          />
          <path
            fill="#F5D90A"
            vectorEffect="non-scaling-stroke"
            stroke="#242C39"
            strokeWidth="1.5"
            d="m702.4 101.3-4.7-9.5c-.4-.7 0-1.8.8-2.2.9-.4 1.8-.1 2.2.7l4.6 9.4c.4.8 0 1.8-.8 2.3-.8.4-1.8.1-2.1-.7Zm-6.6 1.7-14-11.5c-.6-.6-.7-1.7 0-2.4.5-.7 1.6-.8 2.2-.3l14 11.6c.6.5.7 1.6.1 2.4-.6.7-1.6.8-2.3.2Zm-1.2 4.7-14-11.5c-.6-.6-.7-1.7-.1-2.4s1.6-.8 2.3-.3l14 11.6c.6.5.7 1.6.1 2.4-.6.7-1.7.8-2.3.2Zm-.8 4.8-14-11.6c-.6-.5-.7-1.6-.1-2.3.6-.8 1.6-.9 2.3-.3l14 11.5c.6.6.7 1.7 0 2.4-.5.8-1.6.8-2.2.3Z"
          />
          <path
            fill="#F5D90A"
            d="m688.8 107.4 4 3.6 7.1 5.8 6.3-13.6h1.7l-3.3-3.2-6.1-3.6h-.8l.2 1.8-7.6 8.4 1.8 2-3.3-1.2Z"
          />
        </g>
      </svg>
    </div>
  );
});
