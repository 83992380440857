import {
  LoggedTime,
  LoggedTimeDeletePlaceholder,
  RawLoggedTime,
  SearchLoggedTime,
} from '@float/types/loggedTime';
import { TaskMeta } from '@float/types/task';

import {
  DELETE_TASK_META_SUCCESS,
  DELETE_TASK_SUCCESS,
  LOGGED_TIME_BULK_CREATED,
  LOGGED_TIME_BULK_CREATED_UNDO,
  LOGGED_TIME_CREATED,
  LOGGED_TIME_DELETED,
  LOGGED_TIME_HOURS_LOAD_FAIL,
  LOGGED_TIME_HOURS_LOAD_FINISH,
  LOGGED_TIME_HOURS_LOAD_START,
  LOGGED_TIME_LOAD_FAILED,
  LOGGED_TIME_LOAD_FINISH,
  LOGGED_TIME_LOAD_START,
  LOGGED_TIME_UPDATED,
  MERGE_TASK_META_SUCCESS,
  PHASES_DELETED,
  PROJECTS_BULK_DELETED,
  PROJECTS_DELETED,
  REPLACE_TASK_SUCCESS,
  REPLACE_TIMEOFF_SUCCESS,
  SEARCH_CONTEXT_LOAD_FINISH,
  UPDATE_TASK_META_SUCCESS,
} from '../actions';
import { fastDictionary, fastObjectSpread } from '../lib/fast-object-spread';
import { formatTaskId, toNumberList } from '../lib/formatters';

type AnyLoggedTimeType =
  | LoggedTime
  | LoggedTimeDeletePlaceholder
  | SearchLoggedTime;

type LoggedTimesMap = Record<string, AnyLoggedTimeType>;

export type LoggedTimesState = {
  loggedTimes: LoggedTimesMap;
  fetchedLoggedTime: Record<string, number>;
  loggedHours: Record<string, string | number>;
  loadingLoggedHours: Record<string, 'FETCHING' | 'FETCHED' | 'FAILED'>;
  loadState: 'LOADED' | 'LOADING' | 'UNLOADED' | 'LOAD_FAILED';
  lastCreatedAt?: string;
};

export const DEFAULT_STATE: LoggedTimesState = {
  loggedTimes: {},
  fetchedLoggedTime: {},
  loggedHours: {},
  loadingLoggedHours: {},
  loadState: 'UNLOADED',
};

export function isFullLoggedTime(
  lt: LoggedTime | SearchLoggedTime | LoggedTimeDeletePlaceholder,
): lt is LoggedTime {
  return lt.data_type === 'full';
}

function merge(current: LoggedTimesMap, loggedTimes: RawLoggedTime[]) {
  const result = fastObjectSpread(current);

  for (const lt of loggedTimes) {
    const currentLt = current[lt.logged_time_id];
    let created = lt.created;

    if (currentLt && isFullLoggedTime(currentLt)) {
      created = currentLt.created;
    }

    result[lt.logged_time_id] = {
      ...lt,
      data_type: 'full',
      created,
    };
  }

  return result;
}

function groupHours(loggedHours: { date: string; hours: string }[]) {
  return loggedHours.reduce(
    (total, hoursObject) => {
      total[hoursObject.date] = hoursObject.hours
        ? parseFloat(hoursObject.hours)
        : hoursObject.hours;
      return total;
    },
    {} as LoggedTimesState['loggedHours'],
  );
}

function parentEntityDeleted(
  state: LoggedTimesState,
  ids: Array<string | number>,
  idType: 'phase_id' | 'task_meta_id' | 'project_id' = 'project_id',
) {
  const numericIds = new Set(toNumberList(ids));
  if (!numericIds.size) return state;

  const toDelete: LoggedTimesMap = {};

  for (const lt of Object.values(state.loggedTimes)) {
    if (isFullLoggedTime(lt)) {
      const parentId = Number(lt[idType]);

      if (parentId && numericIds.has(parentId)) {
        toDelete[lt.logged_time_id] = {
          logged_time_id: lt.logged_time_id,
          replacesTempId: lt.logged_time_id,
          data_type: 'delete_placeholder' as const,
        };
      }
    }
  }

  if (Object.keys(toDelete).length === 0) return state;

  return {
    ...state,
    loggedTimes: fastObjectSpread(state.loggedTimes, toDelete),
  };
}

function projectsDeleted(state: LoggedTimesState, ids: Array<string | number>) {
  return parentEntityDeleted(state, ids);
}

function phasesDeleted(state: LoggedTimesState, ids: Array<string | number>) {
  return parentEntityDeleted(state, ids, 'phase_id');
}

function taskMetaDeleted(state: LoggedTimesState, ids: Array<string | number>) {
  return parentEntityDeleted(state, ids, 'task_meta_id');
}

// TODO: Handle BATCH_DELETE_TASK_SUCCESS? (integration initiated task deletes)
function tasksDeleted(state: LoggedTimesState, taskIds: string[]) {
  const numericTaskIds = new Set(toNumberList(taskIds));

  if (!numericTaskIds.size) return state;

  let updated = false;
  const updatedLoggedTimes: LoggedTimesMap = fastDictionary();

  for (const item of Object.values(state.loggedTimes)) {
    if (!isFullLoggedTime(item)) {
      updatedLoggedTimes[item.logged_time_id] = item;
      continue;
    }

    if (numericTaskIds.has(Number(item.task_id))) {
      updated = true;
      updatedLoggedTimes[item.logged_time_id] = { ...item, task_id: null };
    } else {
      updatedLoggedTimes[item.logged_time_id] = item;
    }
  }

  if (!updated) return state;

  return {
    ...state,
    loggedTimes: updatedLoggedTimes,
  };
}

function taskMetaUpdated(state: LoggedTimesState, action: { item?: TaskMeta }) {
  if (!action.item) {
    return state;
  }
  const taskMetaId = Number(action.item.task_meta_id || 0);

  if (!taskMetaId) {
    return state;
  }

  let updated = false;

  const actionItem = action.item;

  const loggedTimes: LoggedTimesMap = fastObjectSpread(state.loggedTimes);

  for (const item of Object.values(state.loggedTimes)) {
    if (!isFullLoggedTime(item)) {
      continue;
    }

    if (Number(item.task_meta_id) === taskMetaId) {
      updated = true;
      loggedTimes[item.logged_time_id] = {
        ...item,
        task_meta_id: actionItem.task_meta_id,
        task_name: actionItem.task_name,
        billable: actionItem.billable,
      };
    }
  }

  return updated ? { ...state, loggedTimes } : state;
}

function taskMetaRemovedOrReplaced(
  state: LoggedTimesState,
  action: { item?: TaskMeta; ids?: string[] },
) {
  // For some reason when the merged task has no name the
  // returned IDs are in object format
  if (!action.ids) return state;
  const ids = new Set(
    (!Array.isArray(action.ids) ? Object.values(action.ids) : action.ids).map(
      (x) => Number(x),
    ),
  );

  if (!ids.size) {
    return state;
  }

  if (!action.item) {
    return state;
  }

  let updated = false;
  const actionItem = action.item;

  const loggedTimes: LoggedTimesMap = fastObjectSpread(state.loggedTimes);

  for (const item of Object.values(state.loggedTimes)) {
    if (!isFullLoggedTime(item)) {
      continue;
    }

    if (ids.has(Number(item.task_meta_id))) {
      updated = true;
      loggedTimes[item.logged_time_id] = {
        ...item,
        task_meta_id: actionItem.task_meta_id,
        task_name: actionItem.task_name,
        billable: actionItem.billable,
      };
    }
  }

  return updated ? { ...state, loggedTimes } : state;
}

/* ******* NOTE ******* */
// Due to the ephemeral nature of task references, we never delete things
// from the loggedTimes object. If something needs to be removed, it should be
// marked with a delete placeholder.
/* ******************** */

export type LoggedTimeAction =
  | {
      type: typeof SEARCH_CONTEXT_LOAD_FINISH;
      context: {
        loggedTimes: Omit<SearchLoggedTime, 'data_type'>[];
      };
      rebuild?: boolean;
    }
  | {
      type: typeof LOGGED_TIME_LOAD_START | typeof LOGGED_TIME_LOAD_FAILED;
    }
  | {
      type: typeof LOGGED_TIME_LOAD_FINISH;
      rebuild?: boolean;
      shouldRefresh?: boolean;
      fetchKey: string;
      loggedTimes: RawLoggedTime[];
    }
  | {
      type:
        | typeof LOGGED_TIME_HOURS_LOAD_START
        | typeof LOGGED_TIME_HOURS_LOAD_FAIL;
      fetchKey: string;
    }
  | {
      type: typeof LOGGED_TIME_HOURS_LOAD_FINISH;
      loggedHours: {
        date: string;
        hours: string;
      }[];
      fetchKey: string;
    }
  | {
      type:
        | typeof LOGGED_TIME_UPDATED
        | typeof LOGGED_TIME_CREATED
        | typeof LOGGED_TIME_BULK_CREATED;
      loggedTime: RawLoggedTime | RawLoggedTime[];
      createdAt: string;
    }
  | {
      type: typeof LOGGED_TIME_DELETED;
      id: string;
    }
  | {
      type:
        | typeof LOGGED_TIME_BULK_CREATED_UNDO
        | typeof PROJECTS_BULK_DELETED
        | typeof DELETE_TASK_META_SUCCESS;
      ids: string[];
    }
  | {
      type: typeof PROJECTS_DELETED;
      projectId: string;
    }
  | {
      type: typeof PHASES_DELETED;
      phaseIds: string[];
    }
  | {
      type: typeof UPDATE_TASK_META_SUCCESS;
      item?: TaskMeta;
    }
  | {
      type: typeof MERGE_TASK_META_SUCCESS;
      item?: TaskMeta;
      ids?: string[];
    }
  | {
      type: typeof DELETE_TASK_SUCCESS;
      item: {
        task_id: string | number;
      };
    }
  | {
      type: typeof REPLACE_TIMEOFF_SUCCESS | typeof REPLACE_TASK_SUCCESS;
      response?: {
        delete?: {
          logged_time?: { logged_time_id: string }[];
          task?: { task_id: string }[];
        };
      };
    };

export default function reducer(
  state = DEFAULT_STATE,
  action: LoggedTimeAction,
): LoggedTimesState {
  switch (action.type) {
    case SEARCH_CONTEXT_LOAD_FINISH: {
      const loggedTimes = action.rebuild ? {} : { ...state.loggedTimes };

      const loggedTimesSearchData = action.context.loggedTimes;

      if (loggedTimesSearchData && loggedTimesSearchData.length > 0) {
        for (const loggedTime of loggedTimesSearchData) {
          if (!loggedTimes[loggedTime.logged_time_id]) {
            loggedTimes[loggedTime.logged_time_id] = {
              data_type: 'search' as const,
              ...loggedTime,
            };
          }
        }
      }

      return {
        ...state,
        loggedTimes,
      };
    }

    case LOGGED_TIME_LOAD_START: {
      return {
        ...state,
        loadState: 'LOADING',
      };
    }

    case LOGGED_TIME_LOAD_FAILED: {
      return {
        ...state,
        loadState: 'LOAD_FAILED',
      };
    }

    case LOGGED_TIME_LOAD_FINISH: {
      const prevLoggedTimes = action.shouldRefresh ? {} : state.loggedTimes;
      const loggedTimes = action.rebuild
        ? action.loggedTimes
        : merge(prevLoggedTimes, action.loggedTimes);

      return {
        ...state,
        loadState: 'LOADED',
        loggedTimes,
        fetchedLoggedTime: {
          ...state.fetchedLoggedTime,
          [action.fetchKey]: Date.now(),
        },
      };
    }

    case LOGGED_TIME_HOURS_LOAD_START: {
      return {
        ...state,
        loadingLoggedHours: {
          ...state.loadingLoggedHours,
          [action.fetchKey]: 'FETCHING',
        },
      };
    }

    case LOGGED_TIME_HOURS_LOAD_FINISH: {
      const loggedHours = groupHours(action.loggedHours);
      return {
        ...state,
        loadingLoggedHours: {
          ...state.loadingLoggedHours,
          [action.fetchKey]: 'FETCHED',
        },
        loggedHours: {
          ...state.loggedHours,
          ...loggedHours,
        },
      };
    }

    case LOGGED_TIME_HOURS_LOAD_FAIL: {
      return {
        ...state,
        loadingLoggedHours: {
          ...state.loadingLoggedHours,
          [action.fetchKey]: 'FAILED',
        },
      };
    }

    case LOGGED_TIME_UPDATED:
    case LOGGED_TIME_CREATED:
    case LOGGED_TIME_BULK_CREATED: {
      const loggedTimes = Array.isArray(action.loggedTime)
        ? action.loggedTime
        : [action.loggedTime];
      return {
        ...state,
        loggedTimes: merge(state.loggedTimes, loggedTimes),
        lastCreatedAt: action.createdAt,
      };
    }

    case LOGGED_TIME_DELETED: {
      const loggedTimes = fastObjectSpread(state.loggedTimes);

      loggedTimes[action.id] = {
        data_type: 'delete_placeholder' as const,
        logged_time_id: action.id,
        replacesTempId: action.id,
      };

      return {
        ...state,
        loggedTimes,
      };
    }

    case LOGGED_TIME_BULK_CREATED_UNDO: {
      if (!action.ids?.length) return state;

      const loggedTimes = fastObjectSpread(state.loggedTimes);

      for (const id of action.ids) {
        loggedTimes[id] = {
          data_type: 'delete_placeholder' as const,
          logged_time_id: id,
          replacesTempId: id,
        };
      }

      return {
        ...state,
        loggedTimes,
      };
    }

    case REPLACE_TIMEOFF_SUCCESS:
    case REPLACE_TASK_SUCCESS: {
      const deletedLoggedTimes = action.response?.delete?.logged_time;
      const deletedTasks = action.response?.delete?.task;

      let updatedState = state;

      if (deletedLoggedTimes?.length) {
        const loggedTimes = fastObjectSpread(state.loggedTimes);

        for (const { logged_time_id } of deletedLoggedTimes) {
          loggedTimes[logged_time_id] = {
            data_type: 'delete_placeholder' as const,
            logged_time_id: logged_time_id,
            replacesTempId: logged_time_id,
          };
        }

        updatedState = {
          ...state,
          loggedTimes,
        };
      }

      if (deletedTasks?.length) {
        return tasksDeleted(
          updatedState,
          deletedTasks.map((x) => x.task_id),
        );
      }

      return updatedState;
    }

    case PROJECTS_BULK_DELETED:
      return projectsDeleted(state, action.ids);

    case PROJECTS_DELETED:
      return projectsDeleted(state, [action.projectId]);

    case PHASES_DELETED:
      return phasesDeleted(state, action.phaseIds);

    case DELETE_TASK_META_SUCCESS:
      return taskMetaDeleted(state, action.ids);

    case UPDATE_TASK_META_SUCCESS:
      return taskMetaUpdated(state, action);

    case MERGE_TASK_META_SUCCESS:
      return taskMetaRemovedOrReplaced(state, action);

    case DELETE_TASK_SUCCESS: {
      return tasksDeleted(state, [formatTaskId(action.item)]);
    }

    default: {
      return state;
    }
  }
}
