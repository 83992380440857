export enum FeatureFlag {
  ActiveandArchivedManageFilter = 'active-and-archived-manage-filter',
  CreateLayeredProject = 'create-layered-project',
  DraftStatus = 'draft-status',
  LinearIntegration = 'linear-integration',
  ManageQuickAdd = 'manage-quick-add',
  OnboardingExampleData = 'onboarding-example-data',
  OnboardingWelcomeVideo = 'onboarding-welcome-video',
  PhaseSidePanel = 'phase-side-panel',
  PlaceholdersPackaging = 'placeholders-packaging',
  ProjectBudgetByTasks = 'project-budget-by-tasks',
  RolesRates = 'role-rates',
  ScheduleDataWindowing = 'schedule-data-windowing',
  SearchBeyondLimits = 'search-beyond-limits',
  SearchBeyondLimitsNewAlg = 'search-beyond-limits-new-alg',
  SearchBeyondLimitsDisableRevalidation = 'search-beyond-limits-disable-revalidation',
  SidePanelBesidesAllocationModal = 'side-panel-besides-allocation-modal',
  TagGroups = 'tag-groups',
  TargetedDebugLogs = 'rollbar-debug',
  AllocationByTotalHours = 'allocation-by-total-hours',
  WebSocketMonitoring = 'websocket-monitoring',
  ProjectPlanInMainNav = 'project-plan-in-main-nav',
  AllocationByAvailabilityPercentage = 'allocation-by-availability-percentage',
  EditTaskModalAssigneesFieldTotalHoursUpdate = 'edit-task-modal-assignees-field-total-hours-update',
  LightOnboarding = 'activation-squad-light-onboarding',
  PasswordStrengthMeter = 'activation-squad-password-strength-meter',
}

export const FEATURE_FLAG_VARIANTS = [
  'on',
  'gentle-refresh',
  'strong-refresh',
  'off',
] as const;

// See "Debug Code in Production" in the `float-javascript` github wiki
// https://github.com/floatschedule/float-javascript/wiki/Customer-Issues#2-debug-code-in-production
export enum TargetedDebugLogFlagVariants {
  Enabled = 'Enabled',
  Disabled = 'Disabled',
  PerformanceMonitoring = 'performanceMonitoring',
  // Extend as needed with variations names
}

export const LAUNCH_DARKLY_CLIENT_ID = {
  production: '634d5f3676538411510c5c28',
  staging: '64ef1f0a1398ce1235c3a3b7',
  develop: '634d5f3676538411510c5c27',
} as const;
