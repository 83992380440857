import { isNil } from 'lodash';

import { BudgetType } from '@float/types/project';

import { formatAmountDEPRECATED } from './helpers/formatAmount';
import { formatToDbString as _formatToDbString } from './helpers/formatToDbString';
import { getBudgetTypeText as _getBudgetTypeText } from './helpers/getBudgetTypeText';
import { getPercentage as _getPercentage } from './helpers/getPercentage';

/**
 * @deprecated Import from helpers/getPercentage instead
 */
export const getPercentage = _getPercentage;

/**
 * @deprecated Import from helpers/formatToDbString instead
 */
export const formatToDbString = _formatToDbString;

/**
 * @deprecated Import from helpers/formatAmount instead
 */
export const formatAmount = formatAmountDEPRECATED;

/**
 * @deprecated Import from helpers/getBudgetTypeText instead
 */
export const getBudgetTypeText = _getBudgetTypeText;

/**
 *
 * @param {*} param0
 * @returns
 */
export const getExpandedInfo = ({
  budget_total,
  budget_remaining,
  budget_used,
}) => {
  budget_total = Number(budget_total);
  budget_remaining = Number(budget_remaining);

  const overBudget = budget_remaining < 0;
  const percentage = overBudget
    ? (((-1 * budget_remaining) / budget_total) * 100).toFixed(0)
    : ((budget_remaining / budget_total) * 100).toFixed(0);
  const percentageUsed = ((budget_used / budget_total) * 100).toFixed(0);

  return { overBudget, percentage, percentageUsed };
};

// TODO: Use better floatig point precision datatype.
// E.g. http://jsfromhell.com/classes/bignumber

// Do not use parseInt below. E.g. parseInt(16.83 * 100, 10) is 1682,
// which throws off currency calculations.
export const toCents = (str) => Math.round(str * 100, 10);

export const toNum = (str) => Number(formatToDbString(str));

export function getProjectBudgetStatusText({
  canSeeBudget,
  budget_type,
  budget_remaining,
  budget_total,
  suppressPercentage,
}) {
  if (!budget_type) return null;
  if (budget_type === BudgetType.HourlyFee) return 'Hourly fee';
  if (!canSeeBudget) return null;

  if (typeof budget_total === 'string') {
    budget_total = toNum(budget_total);
  }

  if (typeof budget_remaining === 'string') {
    budget_remaining = toNum(budget_remaining);
  }

  budget_remaining = isNil(budget_remaining) ? budget_total : budget_remaining;

  const { overBudget, percentage: pct } = getExpandedInfo({
    budget_total,
    budget_remaining,
  });

  let linkText;
  const pctText = ` (${pct === 'NaN' ? 0 : pct}%)`;
  if (overBudget) {
    const amt = formatAmount(budget_type, -budget_remaining);
    linkText = `${amt}${suppressPercentage ? '' : pctText} over`;
  } else {
    const amt = formatAmount(budget_type, budget_remaining);
    linkText = `${amt}${suppressPercentage ? '' : pctText} remaining`;
  }
  return linkText;
}

export function isAmountEmpty(value, allowZero = true) {
  const isZero = value == '0';
  return isNil(value) || (isZero && !allowZero) || !value;
}
