import styled, { css } from 'styled-components';

import { Input } from '@float/ui/deprecated';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';
import { respondTo } from '@float/ui/deprecated/Theme/helpers/responsive/respondTo';

export const StyledInputContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;

  margin-top: 32px;
`;

export const StyledLabel = styled.h3`
  ${Typography.Label12.M500};

  color: ${Colors.FIN.Lt.Emphasis.Medium};

  margin-bottom: 12px;
`;

export const StyledInput = styled(Input)`
  width: 100%;
  max-width: 420px;

  ${respondTo(
    'small',
    '<=',
  )(css`
    max-width: none;
  `)};
`;
