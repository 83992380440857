import React from 'react';
import { Trans } from '@lingui/macro';

import { Rights } from '@float/common/lib/acl';
import { CurrentUser, ProjectTemplate } from '@float/types';
import { Button } from '@float/ui/deprecated';
import {
  DropdownActions,
  pushToRight,
} from '@float/ui/deprecated/DropdownActions';
import { IconArrowDown, IconTrash } from '@float/ui/deprecated/Earhart/Icons';

export type TemplateActionsMenuProps = {
  projectTemplate: ProjectTemplate;
  currentUser: CurrentUser;
  onDelete: () => void;
};

export function TemplateActionsMenu(props: TemplateActionsMenuProps) {
  const { currentUser, projectTemplate, onDelete } = props;

  const canDelete = Rights.canDeleteProjectTemplate(currentUser, {
    template: projectTemplate,
  });

  if (!canDelete) return null;

  return (
    <DropdownActions
      side="top"
      align="end"
      className={pushToRight}
      content={
        <>
          {canDelete && (
            <DropdownActions.Item appearance="danger" onClick={onDelete}>
              <Trans>Delete template</Trans> <IconTrash />
            </DropdownActions.Item>
          )}
        </>
      }
    >
      <Button appearance="clear" iconRight={IconArrowDown}>
        <Trans>Actions</Trans>
      </Button>
    </DropdownActions>
  );
}
