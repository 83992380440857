import React from 'react';
import { t } from '@lingui/macro';

import { StatusIndicator } from '../../components/StatusIndicator';
import { ProjectTaskRecord } from '../../types';
import { useTaskDeleteImpact } from './index.hooks';

export const ProjectTaskIndictator: React.FC<{
  task: ProjectTaskRecord;
}> = ({ task }) => {
  const taskInUse = useTaskDeleteImpact(task);
  return (
    <StatusIndicator
      active={!!taskInUse}
      activeTooltip={t`Time assigned`}
      inactiveTooltip={t`No time assigned`}
    />
  );
};
