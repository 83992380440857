import React, { MouseEvent, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { noop } from '@float/libs/utils/noop';
import { IconStop } from '@float/ui/deprecated/Earhart/Icons';

import { StyledButton, StyledIconPlay } from './styles';

type TimerButtonProps = {
  disabled?: boolean;
  notAllowed?: boolean;
  started?: boolean;
} & React.HTMLProps<HTMLButtonElement>;

const TimerButton = (props: TimerButtonProps) => {
  const {
    disabled = false,
    notAllowed = false,
    onClick = noop,
    started = false,
  } = props;

  const [hasStarted, setHasStarted] = useState(started);

  const onButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.blur();

    onClick(e);
  };

  useUpdateEffect(() => {
    setHasStarted(started);
  }, [started]);

  return (
    <StyledButton
      aria-disabled={disabled}
      aria-label={hasStarted ? 'Stop timer' : 'Start timer'}
      data-testid="timer-button"
      disabled={disabled}
      notAllowed={notAllowed}
      onClick={onButtonClick}
    >
      {!hasStarted && <StyledIconPlay data-testid="timer-button-play-icon" />}
      {hasStarted && <IconStop data-testid="timer-button-stop-icon" />}
    </StyledButton>
  );
};

export { TimerButton };
