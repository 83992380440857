import { ReactElement, ReactNode, useContext } from 'react';

import { BaseIconProps } from '../Earhart/Icons/Icon/BaseIcon';
import { SnackbarContext } from './SnackbarProvider';

export type SnackbarOptions = {
  id?: string;
  index?: number;
  showClose?: boolean;
  triggerClose?: boolean;
  persist?: boolean;
  loader?: boolean;
  className?: string;
  iconLeft?: (props: BaseIconProps) => ReactElement;
  iconRight?: (props: BaseIconProps) => ReactElement;
  linkAriaLabel?: string;
  linkText?: string;
  linkTo?: string;
  autoCloseMs?: number;
  closeOnLinkClick?: boolean;
  placement?: string;
  sidebarWidth?: number;
  style?: React.CSSProperties;
  space?: number;
  onLinkClick?: () => void;
  onClose?: (id: string) => void;
};

export type SnackbarMethods = {
  showSnackbar: (
    message: string | ReactNode,
    options?: SnackbarOptions,
  ) => string;
  updateSnackbar: (
    message: string | ReactNode,
    options?: SnackbarOptions & { id: string },
  ) => string;
  closeSnackbar: (id: string) => void;
  showError: (message: string | ReactNode, options?: SnackbarOptions) => void;
};

export function useSnackbar(): SnackbarMethods {
  const ctx = useContext(SnackbarContext);

  if (!ctx) {
    throw Error('useSnackbar must be used in a SnackbarProvider');
  }

  return ctx;
}

export default useSnackbar;
