import React from 'react';

export const IconProductTour = () => (
  <svg
    fill="none"
    width={48}
    height={48}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="31.1"
      height="20.3"
      x="8.4"
      y="13.1"
      fill="#D0E8FF"
      stroke="#242C39"
      strokeWidth=".3"
      rx="1.8"
    />
    <rect
      width="31.2"
      height="20.7"
      x="8.4"
      y="12.9"
      fill="#fff"
      stroke="#242C39"
      strokeWidth=".3"
      rx="1.3"
    />
    <path
      fill="#A5E4D4"
      stroke="#242C39"
      strokeWidth=".3"
      d="M9.8 13H15c.7 0 1.3.5 1.3 1.3v19.3H9.7c-.7 0-1.3-.6-1.3-1.4v-18c0-.7.6-1.3 1.3-1.3Z"
    />
    <circle
      cx="8.9"
      cy="29.1"
      r="3.5"
      fill="#2E5FE8"
      stroke="#242C39"
      strokeWidth=".3"
    />
    <circle
      cx="31.4"
      cy="33.6"
      r="3.5"
      fill="#2E5FE8"
      stroke="#242C39"
      strokeWidth=".3"
    />
    <circle
      cx="16.5"
      cy="13.4"
      r="3.5"
      fill="#2E5FE8"
      stroke="#242C39"
      strokeWidth=".3"
    />
    <path
      stroke="#242C39"
      strokeWidth=".3"
      d="M19.5 18.8h15.8M19.5 21h15.8m-15.8 2.3h15.8m-15.8 2.2h15.8m-15.8 2.3h15.8"
    />
    <circle
      cx="41.1"
      cy="23.1"
      r="3.5"
      fill="#2E5FE8"
      stroke="#242C39"
      strokeWidth=".3"
    />
  </svg>
);
