import { useCallback, useMemo } from 'react';

import { moment } from '@float/libs/moment';

import { ProjectFormData } from '../types';

type StartDate =
  | ProjectFormData['project']['start_date']
  | ProjectFormData['phases'][0]['start_date'];
type EndDate =
  | ProjectFormData['project']['end_date']
  | ProjectFormData['phases'][0]['end_date'];

// Should apply for both projects and phases
const MAXIMUM_PROJECT_DURATION_YRS = 10;

export const useDisabledDates = (props: {
  startDate: StartDate;
  endDate: EndDate;
  calculatedStartDate?: string | null;
  calculatedEndDate?: string | null;
}) => {
  const { startDate, endDate, calculatedEndDate, calculatedStartDate } = props;

  // Checks to see whether the date falls within allocations which are already set
  const isAllocated = useCallback(
    (date: Moment) => {
      if (!calculatedStartDate) return false;
      const format = date.format('YYYY-MM-DD');
      return (
        format > calculatedStartDate &&
        (!calculatedEndDate || format < calculatedEndDate)
      );
    },
    [calculatedEndDate, calculatedStartDate],
  );

  const isPastMaximumEnd = useCallback(
    (date: Moment) => {
      if (!startDate) return false;
      // Checking to see if the selected date is past the 3 year range
      return (
        startDate &&
        moment(startDate)
          .add(MAXIMUM_PROJECT_DURATION_YRS, 'years')
          .isBefore(date)
      );
    },
    [startDate],
  );

  const isBeforeMinimumStart = useCallback(
    (date: Moment) => {
      if (!endDate) return false;
      return (
        endDate &&
        moment(endDate)
          .subtract(MAXIMUM_PROJECT_DURATION_YRS, 'years')
          .isAfter(date)
      );
    },
    [endDate],
  );

  // Dates are disabled in one of 3 scenarios
  // 1. Date falls between the earliest and latest date calculcated on the BE based on allocations, etc.
  // 2. If a start date is selected, the project end date cannot go past 3 years
  // 3. Vice versa for start dates
  const disableDayFn = useMemo(() => {
    return (date: Moment) => {
      return Boolean(
        isAllocated(date) ||
          isPastMaximumEnd(date) ||
          isBeforeMinimumStart(date),
      );
    };
  }, [isAllocated, isBeforeMinimumStart, isPastMaximumEnd]);

  // Start dates can only be selected before or on the earliest date of allocation as calculcated on the BE
  const isStartDateSelectable = useMemo(() => {
    return (date: Moment) => {
      if (!calculatedStartDate) return true;
      const format = date.format('YYYY-MM-DD');
      return format <= calculatedStartDate;
    };
  }, [calculatedStartDate]);

  // End dates can only be selected after or on the latest date of allocation as calculcated on the BE
  const isEndDateSelectable = useMemo(() => {
    return (date: Moment) => {
      if (!calculatedEndDate) return true;
      const format = date.format('YYYY-MM-DD');
      return format >= calculatedEndDate;
    };
  }, [calculatedEndDate]);

  return {
    disableDayFn,
    isStartDateSelectable,
    isEndDateSelectable,
  };
};
