import React from 'react';
import { Route } from 'react-router-dom';

import { Button } from '@float/ui/deprecated';
import { Card } from '@float/ui/deprecated/Earhart/Cards';

import AccountGuestInvitesTable from '../../containers/Guests/InvitesTable';
import AccountGuestsTable from '../../containers/Guests/Table';
import GuestAccountModal from '../../GuestAccountModal/GuestAccountModal';
import Body from '../Body';
import { TabsLink } from '../Common/TabsLink/TabsLink';
import { TabsNav } from '../Common/TabsNav/TabsNav';
import RestrictedMessage from '../RestrictedMessage';
import { StyledRow, StyledTabItem } from './styles';

class Guests extends React.Component {
  constructor(props) {
    super(props);
    this.onClickInviteButton = this.onClickInviteButton.bind(this);
  }

  onClickInviteButton = () => {
    this.props.manageModal({
      visible: true,
      modalType: 'guestAccountModal',
      modalSettings: {
        accountId: null,
      },
    });
  };

  onClickEditButton = (accountId) => {
    this.props.manageModal({
      visible: true,
      modalType: 'guestAccountModal',
      modalSettings: {
        accountId,
      },
    });
  };

  render() {
    const { guestsCount, guestInvitesCount, userCanSee } = this.props;

    if (!userCanSee.guests) {
      return <RestrictedMessage />;
    }

    return (
      <Body
        header="Guests"
        subheader="Guests have account access but do not appear in the schedule. Guest access is free."
      >
        <GuestAccountModal onDelete={this.props.onClickConfirmDelete} />
        <Card>
          <StyledRow>
            <TabsNav>
              <li>
                <TabsLink to="/admin/guests">
                  Guests <span>{guestsCount}</span>
                </TabsLink>
              </li>

              <li>
                <TabsLink to="/admin/guests/invites">
                  Pending invites <span>{guestInvitesCount}</span>
                </TabsLink>
              </li>

              <StyledTabItem>
                <Button size="small" onClick={this.onClickInviteButton}>
                  Invite
                </Button>
              </StyledTabItem>
            </TabsNav>
          </StyledRow>

          <StyledRow>
            <Route
              exact
              path="/admin/guests"
              render={(routerProps) => (
                <AccountGuestsTable
                  {...routerProps}
                  onClickEdit={(row) => this.onClickEditButton(row.id)}
                />
              )}
            />
            <Route
              path="/admin/guests/invites"
              render={(routerProps) => (
                <AccountGuestInvitesTable
                  {...routerProps}
                  onClickEdit={(row) => this.onClickEditButton(row.id)}
                />
              )}
            />
          </StyledRow>
        </Card>
      </Body>
    );
  }
}

Guests.propTypes = {};

export default Guests;
