import { StyleRule } from '@vanilla-extract/css';
import { css } from 'styled-components';

export const E200 = css`
  box-shadow:
    0px 3px 5px rgba(9, 30, 66, 0.15),
    0px 0px 6px rgba(9, 30, 66, 0.04);
`;

export const VE_E200: StyleRule = {
  boxShadow:
    '0px 3px 5px rgba(9, 30, 66, 0.15), 0px 0px 6px rgba(9, 30, 66, 0.04)',
};
