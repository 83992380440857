import { Editor, Element } from 'slate';

const isLinkActive = (editor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) => Element.isElementType(n, 'link'),
  });
  return !!link;
};

export { isLinkActive };
