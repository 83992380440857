import differenceInDays from 'date-fns/differenceInDays';

/**
 * Returns an array of objects representing the first day of each month
 * between a `startDate` and `endDate` as well as the offset in days from the
 * `startDate`
 */
export const buildMonthOffsets = (
  startDate: Date,
  endDate: Date,
): Array<{ offset: number; firstDayOfMonth: Date }> => {
  const monthOffsets: Array<{ offset: number; firstDayOfMonth: Date }> = [];

  const operativeDate = new Date(startDate);

  // If start date is after the start of the month, skip it
  if (operativeDate.getDate() > 1) {
    // Move to the next month
    operativeDate.setMonth(operativeDate.getMonth() + 1);
    operativeDate.setDate(1);
  }

  while (operativeDate <= endDate) {
    const offset = differenceInDays(operativeDate, startDate);
    const firstDayOfMonth = new Date(operativeDate);
    firstDayOfMonth.setDate(1);

    monthOffsets.push({ offset, firstDayOfMonth });

    // Move to the next month
    operativeDate.setMonth(operativeDate.getMonth() + 1);
    operativeDate.setDate(1);
  }

  return monthOffsets;
};
