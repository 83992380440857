import {
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
  ReactElement,
  useCallback,
  useImperativeHandle,
  useMemo,
} from 'react';
import gsap from 'gsap';

import { TransitionProps, TransitionRef } from './types';

export const Transition = forwardRef<TransitionRef, TransitionProps>(
  (props, ref) => {
    const { id } = props;

    const children = useMemo(() => {
      return Children.map(props.children, (child) => {
        if (child && isValidElement(child)) {
          return cloneElement(child as ReactElement, {
            'data-transition-id': id,
          });
        }

        return child;
      });
    }, [props, id]);

    const onEnter = useCallback<TransitionRef['onEnter']>(
      ({ isAppearing, duration, ease, delay }) => {
        const isAside = id.toString().indexOf('-aside') > -1;

        const inProps = { opacity: 0, x: 0 };
        const outProps = { opacity: 1, x: 0 };

        if (isAppearing) {
          inProps.x = -20;

          outProps.x = 0;

          if (isAside) {
            inProps.x *= -1;
          }
        }

        return new Promise((success) => {
          // @todo: if we want to abstract this HOC a little more
          // we shouldn't expect that children[0] has a ref
          // to apply a default animation
          const child = children[0] as ReactElement & {
            ref: React.RefObject<HTMLElement>;
          };
          const content = child.ref?.current;

          gsap.killTweensOf(content);

          gsap.set(content, { ...inProps });

          window.requestIdleCallback(() => {
            gsap.to(content, {
              ...outProps,
              duration,
              delay,
              ease,
              onComplete: success,
            });
          });
        });
      },
      [children, id],
    );

    const onExit = useCallback(() => {
      const duration = 0.35;
      const ease = 'power3.out';

      return new Promise<void>((success) => {
        const child = children[0] as ReactElement & {
          ref: React.RefObject<HTMLElement>;
        };
        const content = child.ref.current;

        gsap.killTweensOf(content);

        window.requestIdleCallback(() => {
          gsap.to(content, {
            opacity: 0,
            duration,
            ease,
            onComplete: success,
          });
        });
      });
    }, [children]);

    useImperativeHandle(ref, () => ({
      transitionId: id,
      onEnter,
      onExit,
    }));

    return children;
  },
);
