import { useCallback } from 'react';

import { useAppDispatchStrict } from '@float/common/store';
import { ProjectTemplate } from '@float/types/project';
import { showProjectSidePanel } from '@float/web/sidePanel/actions';

export const useTemplateOpen = () => {
  const dispatch = useAppDispatchStrict();
  return useCallback(
    (template: ProjectTemplate, hideParent?: () => void) => {
      if (hideParent) {
        hideParent();
      }

      dispatch(
        // @ts-expect-error showTemplateSidePanel still using AppDispatch
        showProjectSidePanel({
          templateId: template.project_template_id,
        }),
      );
    },
    [dispatch],
  );
};
