import styled, { createGlobalStyle, css } from 'styled-components';

import { Button } from '@float/ui/deprecated';
import { PURPLE } from '@float/ui/deprecated/Chart';
import { RowLabel } from '@float/ui/deprecated/VirtualSelect/styles';

export const IconButton = styled.div<{ left?: boolean }>`
  transform: rotate(${({ left }) => (left ? 90 : -90)}deg);
  cursor: pointer;
  height: 34px;
`;

export const ModeSelection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: ${({ theme }) => theme.blueGrey};
`;

export const ModeItem = styled.div<{ active?: boolean }>`
  cursor: pointer;
  padding: 0 2px;
  ${({ active }) => active && 'text-decoration: underline;'}
`;

export const ReportsTotalsContainer = styled.div`
  display: flex;
  white-space: nowrap;
  margin: 20px 0px 20px 0px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.grey};
  border-radius: 6px;
  min-height: 90px;
`;

export const ReportsTotalsCard = styled.div<{
  noFlex?: boolean;
  flexGrow?: number;
  margin?: number;
  width?: number;
  borderRight?: boolean;
  rightAlign?: boolean;
}>`
  display: ${({ noFlex }) => (noFlex ? 'block' : 'flex')};
  padding: 10px 20px 12px 20px;
  flex-grow: ${({ flexGrow }) => flexGrow};
  margin: ${({ margin }) => margin};
  border-right: ${({ borderRight, theme }) =>
    borderRight && `1px solid ${theme.black12}`};
  ${(p) => (p.width ? `width: ${p.width};` : '')}
  ${(p) => (p.rightAlign ? 'text-align: right;' : '')}
`;

export const ReportsTotalsCardMobileWrap = styled(ReportsTotalsCard)`
  flex-wrap: wrap;
`;

export const ReportsTotalsItem = styled.div<{ width?: string }>`
  min-width: ${(p) => p.width || '130px'};
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 1098px) {
    max-width: 145px;
    margin-right: 0;
  }
`;

export const ItemLabel = styled.div`
  display: inline;
  font-size: 14px;
  color: ${({ theme }) => theme.charcoalGrey};
`;

export const ItemSubLabel = styled.div<{ noMargin?: boolean }>`
  display: inline;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.2px;
  color: ${(p) => p.theme.charcoalGrey};
  margin-left: ${(p) => (p.noMargin ? 0 : 10)}px;

  ${ItemLabel} + & {
    color: ${(p) => p.theme.blueGrey};
  }

  .red {
    color: ${(p) => p.theme.red};
  }

  .see-why {
    margin-left: 5px;
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const ItemHeader = styled.div`
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -0.5px;
  color: ${(p) => (p.color ? p.theme[p.color] : p.theme.charcoalGrey)};
  margin-top: 4px;
`;

export const ItemUnit = styled.span`
  font-size: 24px;
`;

export const LegendKeySquare = styled.div<{ color: string; outline?: boolean }>`
  position: relative;
  padding: 1px;
  width: 12px;
  height: 12px;
  margin: 4px 5px;
  box-sizing: border-box;
  overflow: hidden;
  background: ${({ theme, color }) => theme[color] || color};
  border-radius: 2px;

  @media (max-width: 1070px) {
    margin-left: 0;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid ${({ theme }) => theme.black25};
    box-sizing: border-box;
    border-radius: 2px;
  }

  &.combined {
    border-top-color: #8358af;
    border-left-color: #8358af;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: ${PURPLE};
      transform: skewY(-45deg);
      transform-origin: left top;
    }
  }
`;

export const HeaderLegend = styled(LegendKeySquare)`
  display: inline-block;
  border: 0;
  margin: 0;
  margin-right: 10px;
  vertical-align: -1px;

  ${(p) =>
    p.outline &&
    css`
      border: 2px solid ${p.color};
      background: none;
    `}
`;

export const ExportOptionLabel = RowLabel;
export const ExportOptionContainer = styled.div`
  font-size: 16px;
  width: 205px;

  ${ExportOptionLabel} {
    padding: 0 15px;
    &:hover {
      background-color: ${(p) => p.theme.superLightGrey};
    }
  }
`;

export const TabContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.midGrey};
  @media print {
    display: none;
  }
`;

export const LoaderContainer = styled.div`
  position: relative;
  height: 303px;
  left: 0;
  right: 0;
`;

export const ReportsPrintGlobalStyles = createGlobalStyle`
  body {
    height: 0 !important;
  }

  .app-inner.scrollable {
    overflow: hidden;
  }

  nav#main-nav {
    display: none;
  }
`;

export const ReportsContent = styled.div`
  width: calc(100% - 40px);
  max-width: 1280px;
  min-width: 1280px;
  margin: auto;
  margin-top: 24px;

  @media print {
    height: inherit;
    width: 10.25in;
    min-width: 940px;
  }
`;

export const PrintSummaryBar = styled.div`
  width: 100%;
  display: flex;

  div.title {
    flex-grow: 1;

    div.report-name {
      font-size: 24px;
      font-weight: 500;
      color: ${(p) => p.theme.charcoalGrey};
    }
    div.filters {
    }
  }

  div.settings {
    display: flex;
    flex-direction: column;
    text-align: right;

    div.date {
      font-size: 18px;
      color: ${(p) => p.theme.charcoalGrey};
      font-weight: 500;
    }
  }
`;

export const BackgroundColor = createGlobalStyle`
  html {
    background-color: white !important;
  }
`;

export const QuickActionButton = styled(Button)`
  @media (max-width: 767px) {
    display: none;
  }
`;
