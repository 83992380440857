import {
  ADD_ACCOUNT,
  ADD_ACCOUNT_FAILURE,
  ADD_ACCOUNT_SUCCESS,
  CLEAR_FETCHED_ACCOUNTS,
  DELETE_ACCOUNT_SUCCESS,
  FETCH_ACCOUNTS,
  FETCH_ACCOUNTS_FAILURE,
  FETCH_ACCOUNTS_SUCCESS,
  SORT_ACCOUNTS,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_FAILURE,
  UPDATE_ACCOUNT_SUCCESS,
} from '../actions/accounts';
import {
  ACCOUNT_SETTINGS_DEPARTMENTS_TABLE,
  ACCOUNT_SETTINGS_GUESTS_TABLE,
  ACCOUNT_SETTINGS_INVITEES_TABLE,
  DEFAULT_SORT_TABLE,
  getInitialSortableTableState,
  getInitialSortPropsState,
  getInitialStateCreator,
  reducerCreator,
} from './reducerHelpers';

export const lastFetchError = (state) => state.lastFetchError;

export const areBeingFetched = (state) => state.pagesBeingFetched.length > 0;

export const filterAcceptedGuests = (entities) => {
  return entities.filter((entity) => entity.accepted == 1 && entity.guest == 1);
};

export const filterInvitedGuests = (entities) => {
  return entities.filter((entity) => !+entity.accepted && +entity.guest);
};

export const isOwnerAccount = (account) => account.account_type == 1;

export const isAccountCurrentUser = (account, state) =>
  account.account_id == state.currentUser.admin_id;

const getInitialState = getInitialStateCreator(
  Object.assign(
    {
      sortableTables: {
        [DEFAULT_SORT_TABLE]: getInitialSortableTableState('name', 'asc'),
        [ACCOUNT_SETTINGS_GUESTS_TABLE]: getInitialSortableTableState(
          'name',
          'asc',
        ),
        [ACCOUNT_SETTINGS_DEPARTMENTS_TABLE]: getInitialSortableTableState(
          'name',
          'asc',
        ),
        [ACCOUNT_SETTINGS_INVITEES_TABLE]: getInitialSortableTableState(
          'name',
          'asc',
        ),
      },
    },
    getInitialSortPropsState([
      'name',
      'account_type_name',
      'project_count',
      'created',
    ]),
  ),
);

const accounts = () =>
  reducerCreator(
    {
      ADD_ENTITY: ADD_ACCOUNT,
      ADD_ENTITY_SUCCESS: ADD_ACCOUNT_SUCCESS,
      ADD_ENTITY_FAILURE: ADD_ACCOUNT_FAILURE,
      DELETE_ENTITY_SUCCESS: DELETE_ACCOUNT_SUCCESS,
      UPDATE_ENTITY: UPDATE_ACCOUNT,
      UPDATE_ENTITY_SUCCESS: UPDATE_ACCOUNT_SUCCESS,
      UPDATE_ENTITY_FAILURE: UPDATE_ACCOUNT_FAILURE,
      SORT_ENTITIES: SORT_ACCOUNTS,
      FETCH_ENTITIES: FETCH_ACCOUNTS,
      FETCH_ENTITIES_SUCCESS: FETCH_ACCOUNTS_SUCCESS,
      FETCH_ENTITIES_FAILURE: FETCH_ACCOUNTS_FAILURE,
      CLEAR_ENTITIES: CLEAR_FETCHED_ACCOUNTS,
    },
    'account_id',
    getInitialState,
  );

export default accounts;
