import formatDate from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { moment } from '@float/libs/moment';

import request from '../lib/request';
import { cleanUpRepeatState } from './tasks';

export const STATUSES_LOAD_START = 'statuses/LOAD_START';
export const STATUSES_LOAD_FAILED = 'statuses/LOAD_FAILED';
export const STATUSES_LOAD_FINISH = 'statuses/LOAD_FINISH';
export const STATUS_UPDATED = 'statuses/UPDATED';
export const STATUS_DELETED = 'statuses/DELETED';

const DATEFNS_DATE_FORMAT = 'yyyy-MM-dd';
const MOMENT_DATE_FORMAT = 'YYYY-MM-DD';

export function getPeopleIdFromStatus(status) {
  if (status.people_id) {
    return status.people_id;
  }

  if (status.people_ids?.length) {
    // A status should only have one assignee.
    // We still need this because appendRepeatChanges function (in persistChanges)
    // clones originalEntity when updating the first instance of a repeated entity,
    // which contains people_ids instead of people_id.
    return status.people_ids[0];
  }

  return undefined;
}

function getApiModel(status) {
  const model = {
    start_date: formatDate(parseISO(status.start_date), DATEFNS_DATE_FORMAT),
    end_date: formatDate(parseISO(status.end_date), DATEFNS_DATE_FORMAT),
    // TODO: Check why `status.name` is used here
    status_name: status.name || status.status_name,
    status_type_id: status.status_type_id,
    people_id: getPeopleIdFromStatus(status),
    repeat_state: status.repeat_state,
    repeat_end: status.repeat_end_date
      ? formatDate(parseISO(status.repeat_end_date), DATEFNS_DATE_FORMAT)
      : null,
  };

  if (status.status_id) {
    model.status_id = status.status_id;
  }

  if (moment(model.repeat_end).isSameOrBefore(model.end_date)) {
    model.repeat_state = 0;
    model.repeat_end = null;
  }

  return model;
}

function onError(err) {
  if (err && err.length) return err;
  return (err && err.message) || 'An error occurred.';
}

export const fetchStatuses =
  (start, end, rebuild) => async (dispatch, getState) => {
    const startDate =
      typeof start === 'string' ? start : start.format(MOMENT_DATE_FORMAT);
    const endDate =
      typeof end === 'string' ? end : end.format(MOMENT_DATE_FORMAT);

    try {
      dispatch({ type: STATUSES_LOAD_START });

      const res = await request.get(
        `status?start_date=${startDate}&end_date=${endDate}&per-page=5000`,
        undefined,
        {
          version: 'f3',
        },
      );

      dispatch({ type: STATUSES_LOAD_FINISH, statuses: res, rebuild });
    } catch (e) {
      console.error(e);
      dispatch({ type: STATUSES_LOAD_FAILED });
    }
  };

export const createStatus = (status) => async (dispatch) => {
  return request
    .post('status', getApiModel(status), {
      version: 'f3',
    })
    .then((response) => {
      return dispatch({ type: STATUS_UPDATED, response });
    })
    .catch((err) => {
      return Promise.reject(onError(err));
    });
};

export const updateStatus = (status) => async (dispatch) => {
  status = cleanUpRepeatState(status);
  return request
    .put(`status/${status.status_id}`, getApiModel(status), {
      version: 'f3',
    })
    .then((response) => {
      return dispatch({ type: STATUS_UPDATED, response });
    })
    .catch((err) => {
      return Promise.reject(onError(err));
    });
};

export const removeStatus = (status) => async (dispatch) => {
  const id = status.status_id;
  if (!id) {
    return Promise.reject('Status not found.');
  }

  return request
    .del(`status/${id}`, null, {
      version: 'f3',
    })
    .then(() => {
      return dispatch({ type: STATUS_DELETED, id });
    })
    .catch((err) => {
      return Promise.reject(onError(err));
    });
};

export const fetchStatus = (id) => (dispatch) => {
  return request.get(`status/${id}`, null, { version: 'f3' }).then((status) => {
    dispatch({ type: STATUSES_LOAD_FINISH, statuses: [status] });
    return status;
  });
};
