import { getIsCurrentUserGuest } from '@float/common/selectors/currentUser';
import { SavedView } from '@float/types';

import { isPeopleScheduleType } from '../../selectors/views';
import { AppDispatchStrict, AppStoreStrict } from '../../store';
import { updateMultiUserPrefs, updateUserPref } from '../currentUser';
import { trackViewEvent } from './lib/tracking';
import { viewLastUsedStorage } from './lib/viewLastUsedStorage';
import { VIEW_APPLIED } from './views';

export const applyView =
  (view: SavedView) =>
  async (dispatch: AppDispatchStrict, getState: AppStoreStrict['getState']) => {
    const meFilter = view.filters.find((filter) => filter.type === 'me');

    const promises: Promise<unknown>[] = [];

    const isGuest = getIsCurrentUserGuest(getState());

    if (!isGuest) {
      // Not awaiting the dispatch here in order to make the view apply feel 'instant'
      promises.push(dispatch(updateUserPref('me_filter', Boolean(meFilter))));
    }

    const { page, scheduleSort } = view.settings;

    if (scheduleSort) {
      if (isPeopleScheduleType(page)) {
        promises.push(
          dispatch(
            updateMultiUserPrefs({
              sked_view_type: 'people',
              sked_sort_by: scheduleSort.sortBy,
              sked_sort_dir: scheduleSort.sortDir,
              sked_custom_sort: scheduleSort.customSort ? 1 : 0,
            }),
          ),
        );
      }

      if (page === 'project-plan') {
        promises.push(
          dispatch(
            updateMultiUserPrefs({
              sked_view_type: 'projects',
              sked_projview_sort_by: scheduleSort.sortBy,
              sked_projview_sort_dir: scheduleSort.sortDir,
            }),
          ),
        );
      }
    }

    dispatch({
      type: VIEW_APPLIED,
      view,
    });

    viewLastUsedStorage.update(view.id);

    trackViewEvent('view-applied', view);

    return Promise.all(promises);
  };
