import { useState } from 'react';
import type { ReactNode } from 'react';

export const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [element, setElement] = useState<ReactNode | undefined>();

  const open = (element: React.ReactNode) => {
    setIsOpen(true);
    setElement(element);
  };

  const close = () => {
    setIsOpen(false);
    setElement(undefined);
  };

  const render = () => {
    return isOpen && element;
  };

  return {
    isOpen,
    open,
    close,
    render,
  };
};
