import { useEffect } from 'react';
import { connect } from 'react-redux';

import { config } from '@float/libs/config';

function setFavicon({ favIconSuffix, defaultPageTitle }) {
  // As Safari does not have favicon turned on by default, we're handling its tab
  //  indicator by using a bullet in the title instead.
  if (config.isSafari && defaultPageTitle) {
    const bullet = '● ';
    document.title = `${favIconSuffix ? bullet : ''}${defaultPageTitle}`;
    return;
  }

  document
    .getElementById('favicon')
    ?.setAttribute('href', `/images/favicon${favIconSuffix}.png`);
  document
    .getElementById('favicon2x')
    ?.setAttribute('href', `/images/favicon${favIconSuffix}-2x.png`);
}

function BrowserTabNotification({ unread, defaultPageTitle }) {
  useEffect(() => {
    setFavicon({ favIconSuffix: unread ? '-notify' : '', defaultPageTitle });
  }, [unread]); // eslint-disable-line

  return null;
}

const mapStateToProps = (state) => {
  const { unread, pendingUpdate } = state.notifications;
  const { unread: pendingUnread } = pendingUpdate || {};

  return {
    unread: typeof pendingUnread === 'undefined' ? unread : pendingUnread,
    defaultPageTitle: state.app.pageTitle,
  };
};
export default connect(mapStateToProps)(BrowserTabNotification);
