import { t } from '@lingui/macro';

import { sortByName } from '@float/common/lib/itemSort';
import { AppStore } from '@float/common/store';
import { Milestone } from '@float/types/milestone';
import { Phase } from '@float/types/phase';
import { Project, ProjectOffsets } from '@float/types/project';
import { TaskMeta } from '@float/types/task';

import { FormType, ProjectFormInitialData } from '../types';
import { getProjectDataWithPhaseDetails } from './getProjectDataWithPhaseDetails';

function matchesPhaseIdFilter(entity: Milestone | TaskMeta, phaseId?: number) {
  if (!phaseId) {
    const isProjectLevelEntity = !entity.phase_id;
    return isProjectLevelEntity;
  }

  return entity.phase_id === phaseId;
}

function getTemplateTasksFromProjectTasks(
  tasks: ProjectFormInitialData['tasks'],
  isParentNonBillable?: boolean,
  phaseId?: number,
) {
  if (!tasks?.length) return [];

  const result = [];
  for (const task of tasks) {
    if (!task.task_name) continue;

    if (!matchesPhaseIdFilter(task, phaseId)) continue;

    result.push({
      task_meta_id: 0,
      task_name: task.task_name,
      billable: isParentNonBillable ? 0 : task.billable,
      budget: task.budget || null,
    });
  }

  return sortByName(result as TaskMeta[], 'task_name');
}

function getTemplateMilestonesFromProjectMilestones(
  milestones: ProjectFormInitialData['milestones'],
  projectOffsets: ProjectOffsets | undefined,
  phaseId?: number,
) {
  if (!milestones?.length) return [];

  // use phase offsets if we're getting phase milestones
  const offsets = phaseId ? projectOffsets?.phases?.[phaseId] : projectOffsets;

  const result = [];
  for (const milestone of milestones) {
    if (!matchesPhaseIdFilter(milestone, phaseId)) continue;

    const milestoneOffsetData = offsets?.milestones?.[milestone.milestone_id];

    result.push({
      milestone_id: 0,
      name: milestone.name,
      duration: milestoneOffsetData?.duration ?? null,
      parent_offset: milestoneOffsetData?.parent_offset ?? null,
    });
  }

  return result;
}

function getTemplatePhaseTeamFromPhase(phase: Phase) {
  return phase?.people_ids?.map((id) => ({
    id,
    hourly_rate: phase.people_rates?.[id] ?? undefined,
  }));
}

function getTemplatePhasesFromProjectPhases(
  projectData: ProjectFormInitialData,
  offsets?: ProjectOffsets,
) {
  const { phases, tasks, milestones } = projectData;
  if (!phases?.length) return [];

  const result = [];
  for (const phase of phases) {
    const phaseOffsetData = offsets?.phases?.[phase.phase_id];

    result.push({
      ...phase,
      phase_id: 0,
      phase_name: phase.phase_name,
      color: phase.color,
      budget_total: phase.budget_total,
      active: true,
      duration: phaseOffsetData?.duration ?? null,
      parent_offset: phaseOffsetData?.parent_offset ?? null,
      team_people: getTemplatePhaseTeamFromPhase(phase),
      task_metas: getTemplateTasksFromProjectTasks(
        tasks,
        phase.non_billable,
        phase.phase_id,
      ),
      milestones: getTemplateMilestonesFromProjectMilestones(
        milestones,
        offsets,
        phase.phase_id,
      ),
    });
  }

  return result;
}

export function getTemplateDataFromProject(
  store: AppStore,
  projectId: number,
  offsets?: ProjectOffsets,
): ProjectFormInitialData {
  const projectData = getProjectDataWithPhaseDetails(store, projectId);
  const { project, tasks, milestones } = projectData;

  const projectName = project?.project_name;
  return {
    type: FormType.ProjectTemplate,
    project: {
      ...project,
      project_id: 0,
      project_name: t`${projectName} - Template`,
      duration: offsets?.duration ?? null,
    } as Project,
    tasks: getTemplateTasksFromProjectTasks(
      tasks,
      project?.non_billable,
    ) as TaskMeta[],
    milestones: getTemplateMilestonesFromProjectMilestones(
      milestones,
      offsets,
    ) as Milestone[],
    phases: getTemplatePhasesFromProjectPhases(projectData, offsets),
  };
}
