import { trackEvent } from '@float/common/lib/gtm';

export enum ProjectPlanViewSource {
  MainNav = 'main-nav',
  Snackbar = 'snackbar',
  ProjectsList = 'projects-list',
  FirstDraftModal = 'first-draft-modal',
}

export function trackProjectPlanView(ref: ProjectPlanViewSource) {
  trackEvent('Project plan viewed', { ref });
}
