import { createSelector } from 'reselect';

import { ReduxStateStrict } from '../../reducers/lib/types';

export const getProjectsMapRaw = (state: ReduxStateStrict) =>
  state.projects.projects;

export const getProjectById = createSelector(
  [getProjectsMapRaw, (_: ReduxStateStrict, id: number | undefined) => id],
  (projects, id) => {
    if (!id) return undefined;

    return projects[id];
  },
);
