import { StyleRule } from '@vanilla-extract/css';
import { css } from 'styled-components';

export const R400 = css`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 1;
  letter-spacing: -0.2px;
`;

export const M500 = css`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 1;
  letter-spacing: -0.2px;
`;

export const SB600 = css`
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 1;
  letter-spacing: -0.2px;
`;

export const VE_R400: StyleRule = {
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 11,
  lineHeight: 1,
  letterSpacing: -0.2,
};

export const VE_M500: StyleRule = {
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 11,
  lineHeight: 1,
  letterSpacing: -0.2,
};

export const VE_SB600: StyleRule = {
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: 11,
  lineHeight: 1,
  letterSpacing: -0.2,
};
