import { useLayoutEffect, useRef } from 'react';

import { usePrintContext } from '@float/common/contexts/PrintContext';
import { isShallowEqualSet } from '@float/common/selectors/lib/createSelectorWithShallowEqualSetResultCheck';
import { selectVisibleDataWindow } from '@float/common/serena/Data/useCellsDataLoadEffect/helpers/selectVisibleDataWindow';
import { SET_SCHEDULE_VISIBLE_DATA_WINDOW } from '@float/common/serena/redux/reducers';
import { useAppStoreStrict } from '@float/common/store';
import { useCallbackRef } from '@float/libs/hooks/useCallbackRef';
import { ScheduleRowList } from '@float/types/rows';

import { VisibleWindowRange } from './useWindow';

function getDataWindowPayload(
  range: VisibleWindowRange,
  rows: ScheduleRowList,
  isPrinting: boolean,
) {
  const peopleIds = new Set<number>();
  const projectsIds = new Set<number>();

  // When printing the schedule we want to render all the rows
  const start = isPrinting ? 0 : range.rowStart;
  const end = isPrinting ? rows.length - 1 : range.rowStop;

  for (let i = start; i <= end; i++) {
    const row = rows[i];

    if (!row) continue;

    if (row.type === 'person') {
      peopleIds.add(row.peopleId);

      if ('projectId' in row) {
        projectsIds.add(row.projectId);
      }
    } else {
      // The row is a ProjectRow, so we track it's project id
      projectsIds.add(row.data.project_id);

      // Mark the people as visible to precalculate
      // their cells and avoid glitches during the
      // project row expansion/collapse
      for (const personId of row.data.people_ids) {
        peopleIds.add(personId);
      }
    }
  }

  return {
    start: range.colStart * 7,
    end: (range.colStop + 1) * 7,
    peopleIds,
    projectsIds,
  };
}

// An hook that updates the visible data window Redux state
// according to the current rows and range
export function useVisibleDataWindow(rows: ScheduleRowList) {
  const store = useAppStoreStrict();

  const lastRangeRef = useRef<VisibleWindowRange | null>(null);

  const { isPrinting } = usePrintContext();

  const handleVisibleRangeChange = useCallbackRef(
    (range: VisibleWindowRange) => {
      // Storing the range to be able to update the state when
      // the rows are updated without scrolling
      // [Example scenario]: The project plan rows are expanded/collapsed
      lastRangeRef.current = range;
      store.dispatch({
        type: SET_SCHEDULE_VISIBLE_DATA_WINDOW,
        payload: getDataWindowPayload(range, rows, isPrinting),
      });
    },
  );

  useLayoutEffect(() => {
    const lastRange = lastRangeRef.current;
    if (!lastRange) return;

    const dataWindow = selectVisibleDataWindow(store.getState());
    const nextDataWindow = getDataWindowPayload(lastRange, rows, isPrinting);

    // OPTIMIZATION: Trigger an update only if something has changed
    if (
      dataWindow &&
      isShallowEqualSet(dataWindow.peopleIds, nextDataWindow.peopleIds) &&
      isShallowEqualSet(dataWindow.projectsIds, nextDataWindow.projectsIds)
    ) {
      return;
    }

    store.dispatch({
      type: SET_SCHEDULE_VISIBLE_DATA_WINDOW,
      payload: nextDataWindow,
    });
  }, [rows, store, isPrinting]);

  return { handleVisibleRangeChange };
}
