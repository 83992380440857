import React from 'react';
import { Trans } from '@lingui/macro';

import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';

import { ChangePlanTierButtons } from './ChangePlanTierButtons';
import { ExternalLink } from './ExternalLink';
import { HintPrompt } from './HintPromp';
import { PlanCheckIcon } from './PlanCheckIcon';
import { PlanHeader } from './PlanHeader';
import { PricePerSeat } from './PricePerSeat';
import {
  StyledEnterprisePlanWrapper,
  StyledFeatureList,
  StyledFeatureListHeader,
  StyledFeatureListSection,
  StyledPlanSubTitle,
} from './styles';

import enterpriseIcon from './assets/enterpriseIcon.svg';

export type EnterprisePlanProps = {
  isActivePlan: boolean;
  isFreeTrial: boolean;
  isHigherTierThanActivePlan: boolean;
  isLowerTierThanActivePlan: boolean;
  price: string;
  openModal: (modal: string) => void;
};

export const EnterprisePlan = ({
  isActivePlan,
  isFreeTrial,
  isHigherTierThanActivePlan,
  isLowerTierThanActivePlan,
  price,
  openModal,
}: EnterprisePlanProps) => {
  return (
    <>
      <StyledEnterprisePlanWrapper aria-current={isActivePlan}>
        <img src={enterpriseIcon} alt="Enterprise Plan Icon" />

        <PlanHeader isActivePlan={isActivePlan}>Enterprise</PlanHeader>

        <StyledPlanSubTitle>Best for larger teams</StyledPlanSubTitle>

        <PricePerSeat price={price} />

        <ChangePlanTierButtons
          isHigherTierThanActivePlan={isHigherTierThanActivePlan}
          isLowerTierThanActivePlan={isLowerTierThanActivePlan}
          isFreeTrial={isFreeTrial}
          isEnterpriseUpgrade
        />

        <StyledFeatureListSection>
          <StyledFeatureListHeader>
            All the Pro features plus
          </StyledFeatureListHeader>

          <StyledFeatureList>
            <li>
              <PlanCheckIcon />
              <span>Custom contract</span>
              <HintPrompt content="Address procurement and compliance needs" />
            </li>
            <li>
              <PlanCheckIcon />
              <span>
                Service Level Agreements (SLA)
                <HintPrompt content="Document expected service performance" />
              </span>
            </li>
            {featureFlags.isFeatureEnabled(
              FeatureFlag.PlaceholdersPackaging,
            ) && (
              <li>
                <PlanCheckIcon />
                <span>
                  <Trans>Custom Placeholder limit</Trans>
                </span>
              </li>
            )}
            <li>
              <PlanCheckIcon />
              <span>Personalized training</span>
            </li>
            <li>
              <PlanCheckIcon />
              <span>
                Priority support
                <HintPrompt content="Get first access to our support team and personalized training" />
              </span>
            </li>
            <li>
              <PlanCheckIcon />
              <span>
                Manual payment options
                <HintPrompt content="Pay by wire transfer" />
              </span>
            </li>
          </StyledFeatureList>

          <ExternalLink href="https://www.float.com/pricing">
            View full feature list
          </ExternalLink>
        </StyledFeatureListSection>
      </StyledEnterprisePlanWrapper>
    </>
  );
};
