import { useAppSelectorStrict } from '@float/common/store';
import { CurrentUser } from '@float/types/account';
import {
  Notification,
  NotificationType,
  NotificationTypes,
} from '@float/types/notification';
import { Timeoff } from '@float/types/timeoff';
import { TimeoffStatuses } from '@float/types/timeoffStatus';
import { getTimeoffsMap, getUser } from '@float/web/selectors';

export const useTimeoffPendingRequestTag = (
  notification: Notification<NotificationTypes>,
) => {
  const user = useAppSelectorStrict(getUser) as CurrentUser;
  const timeoffs = useAppSelectorStrict(getTimeoffsMap);

  const isTimeoffApprovalsEnabled = Boolean(user.timeoff_approvals);
  const isTimeoffType = notification.item_type === NotificationType.Timeoff;
  const notificationData = notification.after_data as Timeoff;

  if (isTimeoffApprovalsEnabled && isTimeoffType && notificationData) {
    const timeoffId = notificationData.timeoff_id;
    const timeoff = timeoffs[timeoffId];

    // confirm timeoff allocation is available (e.g., it could have been deleted)
    if (timeoff) {
      const isTentative = timeoff.status === TimeoffStatuses.TENTATIVE;
      const isPendingRequest = Boolean(timeoff?.status_request);

      return isTentative && isPendingRequest;
    }
  }

  return false;
};
