/* eslint-disable react-compiler/react-compiler */
/** We wanto to avoid using the compiler here because it would break the hook */

import { useCallback, useRef } from 'react';

export const useDebouncedCallback = (callback, delay, dependencies = []) => {
  const timeout = useRef();
  const callbackFn = useCallback(callback, dependencies); // eslint-disable-line react-hooks/exhaustive-deps

  return useCallback(
    (...args) => {
      if (timeout.current != null) {
        clearTimeout(timeout.current);
      }

      timeout.current = setTimeout(() => {
        callbackFn(...args);
      }, delay);
    },
    [delay, callbackFn],
  );
};
