import styled from 'styled-components';

import * as Animation from '@float/ui/deprecated/Earhart/Animation';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';
import TooltipNotification from '@float/ui/deprecated/TooltipNotification';

const offset = 10;

const offsetPerPosition: Record<string, string> = {
  top: `translateY(${offset * -1}%)`,
  right: `translateX(${offset}%)`,
  bottom: `translateY(${offset}%)`,
  left: `translateX(${offset * -1}%)`,
};

export const StyledTooltipContent = styled.div`
  position: relative;
  display: flex;

  ${Typography.TextS14.M500};
  padding: 16px 24px 16px 24px;
  color: ${Colors.Radix.Primary.Flue[11]};

  a {
    display: block;
    text-decoration: underline;
    font-weight: 600;
    color: ${Colors.FIN.Lt.Emphasis.Primary};
  }
`;

export const StyledTooltipNotification = styled(TooltipNotification)`
  position: absolute;
  z-index: 2002;
  pointer-events: auto;

  /**
   * Animation
   */
  &.enter,
  &.appear {
    opacity: 0;
    transform: ${({ position }) => offsetPerPosition[position]};
  }
  &.enter-active,
  &.appear-active {
    opacity: 1;
    transform: none;
    transition-delay: 0.45s;
    transition-property: opacity, transform;
    transition-duration: 0.25s, 0.35s;
    transition-timing-function: ${Animation.Settings.Easing.DefaultOut},
      ${Animation.Settings.Easing.OutBounce};
  }
  &.exit {
    opacity: 1;
    transform: none;
  }
  &.exit-active {
    opacity: 0;
    transform: ${({ position }) => offsetPerPosition[position]};
    transition-property: opacity, transform;
    transition-duration: 0.15s;
    transition-timing-function: ${Animation.Settings.Easing.DefaultOut};
  }
`;

export const StyledTooltipTitle = styled.div`
  ${Typography.TextXXL.SB600};
  font-family: Lexend;

  color: ${Colors.Core.Text.Flue};
  line-height: 30px;
  margin-top: 8px;
  padding-bottom: 8px;
`;

export const StyledTooltipPararaph = styled.p`
  ${Typography.TextS14.R400};
  color: ${Colors.FIN.Lt.Emphasis.Medium};
  margin-bottom: 10px;

  a.inline {
    display: inline;
  }
`;

export const StyledTooltipClose = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
`;
