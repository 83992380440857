import React from 'react';
import { useWatch } from 'react-hook-form';
import { t } from '@lingui/macro';

import { ComboboxField } from '../components/ComboboxField';
import { useSetPersonDefaultHourlyRate } from '../hooks/useSetPersonDefaultHourlyRate';
import { ProjectFormData } from '../types';
import {
  useAddDepartment,
  usePeopleByDept,
} from './ProjectTeamMemberField.hooks';

export function ProjectTeamMemberField(props: {
  index: number;
  showingRates: boolean;
  onChange?: (entityId: number) => void;
  onEnterSelect: () => void;
}) {
  const team = useWatch<ProjectFormData, 'team'>({ name: 'team' });
  const peopleByDept = usePeopleByDept(team, props.showingRates);

  const addDepartment = useAddDepartment(props.onEnterSelect);
  const { setPersonDefaultHourlyRate } = useSetPersonDefaultHourlyRate();
  return (
    <ComboboxField
      optionsPlacement="top"
      size="medium"
      autoFocus
      groupedOptions={peopleByDept}
      options={undefined}
      name={`team.${props.index}.people_id` as const}
      placeholder={t`Search team members`}
      label={`Team member search field`}
      hideLabel
      onEnterSelect={props.onEnterSelect}
      onChange={(personId) => {
        setPersonDefaultHourlyRate(props.index, personId as number);
        props.onChange?.(personId as number);
      }}
      onSelectGroupAll={addDepartment}
    />
  );
}
