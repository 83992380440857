import styled from 'styled-components';

import { getStatusColor } from '@float/common/lib/colors/utils/getStatusColor';

export const StatusIcon = styled.div<{ size?: number }>`
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 ${(p) => `${p.size || 18}px ${p.size || 18}px`};
  border-color: transparent transparent ${(p) => getStatusColor(p.color)}
    transparent;
`;
