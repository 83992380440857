import React from 'react';

import { useAppSelectorStrict } from '@float/common/store';
import { ProjectStatus } from '@float/types';
import { StatusDropdown } from '@float/web/components/panelViews/Project/components/StatusDropdown';
import { getUser } from '@float/web/selectors';

import { useShouldAllowDraftStatus } from './BulkEditStatus.hooks';

type Props = {
  ids: number[]; // List of selected project ids
  value: ProjectStatus;
  onChange: (status: ProjectStatus) => void;
};

export function BulkEditStatus(props: Props) {
  const { value, ids, onChange } = props;
  const currentUser = useAppSelectorStrict(getUser);
  const allowDraftOption = useShouldAllowDraftStatus(ids, currentUser);

  return (
    <StatusDropdown
      value={value}
      allowDraftOption={allowDraftOption}
      onChange={onChange}
    />
  );
}
