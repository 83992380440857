import React, { StrictMode } from 'react';
import { t, Trans } from '@lingui/macro';

import { Person, Project, Tag, TagGroup } from '@float/types';
import { Card, CardRow } from '@float/ui/deprecated/Earhart/Cards';

import PageHeader from '../Body/PageHeader';
import { StyledBody, StyledContent } from '../Body/styles';
import { TabsLink } from '../Common/TabsLink/TabsLink';
import { TabsNav } from '../Common/TabsNav/TabsNav';
import { AdminTagsGroup } from './components/AdminTagsGroup/AdminTagGroup';

export type TagsV2Props = {
  numTagsPeople: number;
  numTagsProjects: number;
  tagsGroups: {
    id: number;
    tagsGroup: TagGroup | null;
    tagsWithEntities: {
      tag: Tag;
      entities: (Person | Project)[];
    }[];
  }[];
  isLoading: boolean;
};

export const TagsV2 = (props: TagsV2Props) => {
  const { numTagsPeople, numTagsProjects, tagsGroups } = props;

  return (
    <StrictMode>
      <StyledBody fluid={false}>
        <PageHeader
          header={t`Tags`}
          subheader={t`Manage the tags you filter by. Tags are applied on the People and Projects pages.`}
        />

        <StyledContent>
          <Card>
            <CardRow>
              <TabsNav>
                <li>
                  <TabsLink to="/admin/tags">
                    <Trans>
                      People <span>{numTagsPeople}</span>
                    </Trans>
                  </TabsLink>
                </li>

                <li>
                  <TabsLink to="/admin/tags/projects">
                    <Trans>
                      Projects <span>{numTagsProjects}</span>
                    </Trans>
                  </TabsLink>
                </li>
              </TabsNav>
            </CardRow>

            {tagsGroups.map((group) => (
              <CardRow key={group.id}>
                <AdminTagsGroup {...group} />
              </CardRow>
            ))}
          </Card>
        </StyledContent>
      </StyledBody>
    </StrictMode>
  );
};
