import React, { useCallback, useEffect, useState } from 'react';
import {
  add,
  format,
  lastDayOfMonth,
  lastDayOfWeek,
  lastDayOfYear,
  set,
  sub,
} from 'date-fns';

import { StyledBanner } from './styles';

const LockPeriodSummary = ({ interval, frequency, weekStartsOn, disabled }) => {
  const [initialLockDate, setInitialLockDate] = useState(null);

  const calculateInitialLockDate = useCallback(() => {
    // Get the current date expressed as in UTC, since we're locking periods
    // in the back end on the same principle
    const currentDate = new Date(new Date().toUTCString().slice(0, -4));
    const rewindCursorDate = (cursorDate) => {
      // Subtract the smaller unit first, to generate the correct date
      cursorDate = sub(cursorDate, {
        days: interval,
      });

      cursorDate = sub(cursorDate, {
        [`${frequency}s`]: 1,
      });

      const composerMap = {
        week: (date) => {
          return lastDayOfWeek(date, { weekStartsOn });
        },
        month: lastDayOfMonth,
        year: lastDayOfYear,
      };

      return set(composerMap[frequency](cursorDate), {
        hours: 23,
        minutes: 59,
        seconds: 59,
      });
    };

    let lockDate = rewindCursorDate(currentDate);
    const referenceWindowEndDate = set(
      add(lockDate, {
        days: interval,
      }),
      {
        hours: 23,
        minutes: 59,
        seconds: 59,
      },
    );

    if (
      currentDate.getTime() > lockDate.getTime() &&
      currentDate.getTime() <= referenceWindowEndDate.getTime()
    ) {
      lockDate = rewindCursorDate(lockDate);
    }

    setInitialLockDate(
      format(
        add(lockDate, {
          days: 1,
        }),
        'd MMM yyyy',
      ),
    );
  }, [interval, frequency, weekStartsOn]);

  useEffect(() => {
    calculateInitialLockDate();
  }, [calculateInitialLockDate]);

  return (
    <StyledBanner disabled={disabled}>
      <p>
        All dates and time logged prior to {initialLockDate} are locked for
        Members
      </p>
    </StyledBanner>
  );
};

export default LockPeriodSummary;
