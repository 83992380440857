import React, { useMemo } from 'react';
import styled from 'styled-components';

import EH from '@float/ui/deprecated/Earhart';

const StyledList = styled(EH.List)`
  ${EH.Elevations.E150};
  position: absolute;
  top: 0;
  background-color: white;
  white-space: nowrap;
  z-index: 10000;
`;

function SidebarMenu(props) {
  const options = useMemo(
    () =>
      props.options.map((o) => ({
        name: o.title,
        value: o.title,
        onClick: o.action,
        icon: o.icon,
      })),
    [props.options],
  );

  const onClickOption = ({ onClick }) => {
    if (onClick) onClick();
  };

  return (
    <StyledList
      appearance="flay"
      options={options}
      onClick={onClickOption}
      onMouseLeave={props.close}
    />
  );
}

export default SidebarMenu;
