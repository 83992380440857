import { WebAppDispatch } from 'lib/store';

import { MODAL_MANAGER_UPDATE } from './modalManagerReducers';
import { ModalConfig } from './useManageModal';

export function updateModal(modalConfig: Partial<ModalConfig>) {
  return (dispatch: WebAppDispatch) => {
    return dispatch({
      type: MODAL_MANAGER_UPDATE,
      modalType: modalConfig.modalType,
      modalConfig,
    });
  };
}

export default function manageModal({
  visible = true,
  skipSidePanelAutoClose = false,
  modalType,
  modalSettings,
}: ModalConfig) {
  const suffix = visible ? 'SHOW' : 'HIDE';
  return (dispatch: WebAppDispatch) => {
    return dispatch({
      type: `modalManager_${suffix}`,
      skipSidePanelAutoClose,
      modalType,
      modalSettings,
    });
  };
}
