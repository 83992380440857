import { add } from 'date-fns';
import { forEach, isEmpty } from 'lodash';

import {
  divisionOperation as divOp,
  subtractOperation as subOp,
  sumOperation as sumOp,
} from '@float/libs/utils/floats';
import {
  CellItemWithEntity,
  DateString,
  LoggedTimeCell,
  Person,
  PersonCell,
  ProjectCell,
  RowId,
} from '@float/types';

import { isDateInLockPeriod } from '../util/lockPeriod';
import { getTimeBasedWorkHours } from '../util/workHours';
import { groupScheduledItemsPerDay } from './transformers/helpers';

// These functions exist in this file instead of useRowMetas because they're
// used by the CSV export functionality, which runs in a web worker, and we
// don't want to require importing React there.

const loggedTimeLength = 'logged_time-'.length;
function loggedTimeKeyToPerson(key: string): RowId {
  return `person-${key.slice(loggedTimeLength)}`;
}

type Keys = string;

export type LockedPeriods = {
  latest: string | null;
  next: string | null;
};

export type RowNonWorkDay = {
  type: 'nonWorkDay';
  key: string;
  x: number;
  w: number;
};

export type RowOvertime = {
  type: 'overtime';
  key: string;
  x: number;
  w: number;
  y: number;
};

export type RowLockedDay = {
  type: 'lockedDay';
  key: string;
  x: number;
  w?: number;
};

type SerializedParams = {
  person: Person | null;
  mondayStart: boolean;
  leftHiddenDays: number;
  rightHiddenDays: number;
  user: Person;
};

export type RowMetaItem = {
  personId: number;
  version: number;
  start_date: string | null;
  end_date: string | null;
  people_type_id: number;
  getDailyWorkHours: (day: DateString) => number[];
  getNonWorkDayItems: (day: DateString) => RowNonWorkDay[];
  getOvertimeItems: (
    day: DateString,
    cell?: PersonCell | ProjectCell | LoggedTimeCell,
  ) => RowOvertime[];
  getLockedDayItems: (
    day: DateString,
    lockPeriods: LockedPeriods,
  ) => RowLockedDay[];
  work_days_hours: Person['work_days_hours'];
  work_days_hours_history: Person['work_days_hours_history'];
  _serializedParams: SerializedParams;
};

export class RowMetas {
  map: Record<Keys, RowMetaItem>;

  constructor(base?: Record<Keys, RowMetaItem>) {
    this.map = Object.create(null);

    if (base) Object.assign(this.map, base);
  }

  get(key?: Keys) {
    if (!key) {
      return undefined;
    }

    if (key.startsWith('logged_time-')) {
      return this.map[loggedTimeKeyToPerson(key)];
    }

    return this.map[key];
  }

  getKeys() {
    return Object.keys(this.map) as Keys[];
  }

  isEmpty() {
    return isEmpty(this.map);
  }

  forEach(cb: (value: RowMetaItem, index: Keys) => void) {
    return forEach(this.map, cb);
  }

  set(key: Keys, val: RowMetaItem) {
    this.map[key] = val;
  }

  delete(key: Keys) {
    delete this.map[key];
  }

  clone() {
    return new RowMetas(this.map);
  }

  serialize() {
    type SerializedRowMeta = Omit<
      RowMetaItem,
      | 'getNonWorkDayItems'
      | 'getDailyWorkHours'
      | 'getOvertimeItems'
      | 'getLockedDayItems'
    >;

    const serializedRowMetas: Partial<Record<Keys, SerializedRowMeta>> = {};

    this.getKeys().forEach((k) => {
      serializedRowMetas[k] = {
        ...this.get(k),
        getNonWorkDayItems: undefined,
        getDailyWorkHours: undefined,
        getOvertimeItems: undefined,
        getLockedDayItems: undefined,
      } as SerializedRowMeta;
    });

    return serializedRowMetas;
  }
}

export function createGetNonWorkDayItems(meta: {
  getDailyWorkHours: (day: DateString) => number[];
}) {
  return (day: DateString) => {
    const dailyWorkHours = meta.getDailyWorkHours(day);
    const items: RowNonWorkDay[] = [];

    for (let i = 0; i < dailyWorkHours.length; i++) {
      if (dailyWorkHours[i] === 0) {
        items.push({
          type: 'nonWorkDay',
          key: `nonWorkDay:${i}`,
          x: i,
          w: 1,
        });
      }
    }

    return items;
  };
}

export function createGetOvertimeItems(meta: {
  getDailyWorkHours: (day: DateString) => number[];
  people_type_id?: number;
}) {
  return (
    day: DateString,
    cell?: PersonCell | ProjectCell | LoggedTimeCell,
  ) => {
    const items: RowOvertime[] = [];

    if (!cell) {
      return items;
    }

    if (meta.people_type_id === 3) return [];

    const dailyWorkHours = meta.getDailyWorkHours(day);

    // group scheduled items per day
    const scheduledItemsPerDay = groupScheduledItemsPerDay(cell.items);

    // calculate where overtime starts per day
    // based on scheduled hours and max working hours
    for (let i = 0; i < dailyWorkHours.length; i++) {
      const totalWorkHours = dailyWorkHours[i];

      let overtimeAt = 0;

      // if there are scheduled items
      if (scheduledItemsPerDay[i]) {
        // sort scheduled items to match how they appear in the scheduled
        const scheduledItems = scheduledItemsPerDay[i].sort(
          (a, b) => a.sortIdx! - b.sortIdx!,
        );

        // calculate first overtime item
        let totalScheduledHours = 0;
        let overtimeItem: CellItemWithEntity;

        scheduledItems.some((item) => {
          const hours = 'hours' in item.entity ? item.entity.hours ?? 0 : 0;

          totalScheduledHours = sumOp(totalScheduledHours, hours);

          const hasOvertime = totalScheduledHours > totalWorkHours;

          if (hasOvertime) overtimeItem = item;

          return hasOvertime;
        });

        // if it has an item causing overtime
        // place the overtime item accordingly
        if (overtimeItem!) {
          const hours =
            'hours' in overtimeItem.entity ? overtimeItem.entity.hours ?? 0 : 0;

          const overtimeDiff = subOp(totalScheduledHours, totalWorkHours);
          const diffRatio = divOp(overtimeDiff, hours);

          overtimeAt =
            overtimeItem.y! + overtimeItem.h! - overtimeItem.h! * diffRatio;
        } else {
          // otherwise either set the overtime at the max hours
          // or at the edge of the last item in case they take more space
          // than the totalWorkHours
          const lastItem = scheduledItems[scheduledItems.length - 1];
          overtimeAt = Math.max(totalWorkHours, lastItem.y! + lastItem.h!);
        }
      }

      items.push({
        type: 'overtime',
        key: `overtime:${i}`,
        x: i,
        w: 1,
        y: overtimeAt,
      });
    }

    return items;
  };
}

export function createGetDailyWorkHours(meta: {
  _serializedParams: SerializedParams;
}): RowMetaItem['getDailyWorkHours'] {
  const {
    person: p,
    mondayStart,
    leftHiddenDays,
    rightHiddenDays,
    user,
  } = meta._serializedParams;

  // using a named function declaration to simplify profiling
  function createGetDailyWorkHoursInner(day: DateString) {
    const hours = getTimeBasedWorkHours(day, p, user);

    const start = leftHiddenDays || 0;
    const end = rightHiddenDays ? hours.length - rightHiddenDays : hours.length;

    const size = end - start;

    if (size <= 0) return [];

    const result = new Array<number>(size);
    for (let i = 0; i < size; i++) {
      result[i] = hours[mondayStart ? i + start + 1 : i + start];
    }

    // we early return when the size is 0 to skip this instruction
    if (mondayStart && !rightHiddenDays) {
      result[result.length - 1] = hours[0];
    }

    return result;
  }

  return createGetDailyWorkHoursInner;
}

export function createLockedDayItems(meta: {
  getDailyWorkHours: (day: DateString) => number[];
}) {
  return (day: DateString, loggedTimeLockPeriodDates?: LockedPeriods) => {
    const items: RowLockedDay[] = [];

    if (loggedTimeLockPeriodDates?.latest) {
      const dailyWorkHours = meta.getDailyWorkHours(day);
      const lockPeriodEndDate = new Date(loggedTimeLockPeriodDates.latest);

      for (let i = 0; i < dailyWorkHours.length; i++) {
        const cursorDate = add(new Date(day), {
          days: i,
        });
        const isLockedDay = isDateInLockPeriod(cursorDate, lockPeriodEndDate);

        if (isLockedDay && dailyWorkHours[i] > 0) {
          items.push({
            type: 'lockedDay',
            key: `lockedDay:${i}`,
            x: i,
          });
        }
      }
    }

    return items;
  };
}
