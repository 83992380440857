import { accumulateDecimalHours } from '@float/common/lib/timer/accumulateDecimalHours';
import { matchFilteredEntity } from '@float/common/search/helpers/matchFilteredEntity';
import { FilteredEntities } from '@float/common/search/types';
import { CellItem, CellItemWithEntity, CellsMap } from '@float/types/cells';
import { Project } from '@float/types/project';
import { BaseFilterToken } from '@float/types/view';

import { getRangeArray } from '../../../lib/array';
import { BreakdownData, ItemData, ProjectsBreakdownData } from './types';

export function buildDayHeader(
  startCol: number,
  endCol: number,
  dates: DatesManager,
  payload: {
    leftHiddenDays: number;
    rightHiddenDays: number;
    startDate: string;
    endDate: string;
    filters: BaseFilterToken[];
  },
) {
  const numCellDays = 7 - payload.leftHiddenDays - payload.rightHiddenDays;

  const reportDates = [];
  for (let i = startCol; i <= endCol; i++) {
    for (let d = 0; d < numCellDays; d++) {
      const date = dates.fromDescriptor(i, d);
      if (date < payload.startDate) continue;
      if (date > payload.endDate) continue;
      if (!date) continue;

      reportDates.push(date);
    }
  }

  return {
    filters: payload.filters,
    dates: reportDates,
  };
}

export function buildWeekHeader(
  startCol: number,
  endCol: number,
  dates: DatesManager,
  payload: {
    filters: BaseFilterToken[];
  },
) {
  const reportDates = [];
  for (let i = startCol; i <= endCol; i++) {
    reportDates.push(dates.fromDescriptor(i, 0));
  }

  return {
    filters: payload.filters,
    dates: reportDates,
  };
}

export function isFilteredOut(
  payload: {
    filters: BaseFilterToken[];
    filteredEntities: FilteredEntities;
  },
  item:
    | Pick<CellItem<'task'>, 'entity' | 'type' | 'entityId'>
    | Pick<CellItem<'timeoff'>, 'entity' | 'type' | 'entityId'>,
) {
  const {
    filters = [],
    filteredEntities = {
      task: new Set(),
      timeoff: new Set(),
      loggedTime: new Set(),
    },
  } = payload;

  if (!filters || !filters.length) return false;

  return !matchFilteredEntity(filteredEntities, item.type, item.entityId);
}

function getItemType(
  item:
    | Pick<CellItem<'task'>, 'entity' | 'type'>
    | Pick<CellItem<'timeoff'>, 'entity' | 'type'>,
) {
  if (item.type === 'task') {
    return 'tasks';
  }

  if (item.type === 'timeoff' && !item.entity.region_holiday_id) {
    return 'timeoffs';
  }

  throw Error('Unknown item type');
}

function getItemKey(
  item:
    | Pick<CellItem<'task'>, 'entity' | 'type'>
    | Pick<CellItem<'timeoff'>, 'entity' | 'type'>,
) {
  if (item.type === 'task') {
    return [
      item.entity.project_id || '',
      item.entity.name || '',
      item.entity.notes || '',
    ].join(':');
  }

  if (item.type === 'timeoff' && !item.entity.region_holiday_id) {
    return [
      item.entity.timeoff_type_id || '',
      item.entity.timeoff_notes || '',
    ].join(':');
  }

  throw Error('Unknown item type');
}

export function addHours(itemData: ItemData, date: string, hours: number) {
  if (!itemData.dateData[date]) {
    itemData.dateData[date] = 0;
  }

  itemData.dateData[date] += hours;
}

/**
 * Add hours to itemData on the given date by safely
 * managing the decimal hours
 */
export function addHoursWithDecimalHoursAccumulation(
  itemData: ItemData,
  date: string,
  hours: number,
) {
  if (!itemData.dateData[date]) {
    itemData.dateData[date] = 0;
  }

  itemData.dateData[date] = accumulateDecimalHours(
    itemData.dateData[date],
    hours,
  );
}

export function registerItem(
  payload: {
    projects: Record<number, Project>;
    filteredEntities: FilteredEntities;
    filters: BaseFilterToken[];
  },
  data: BreakdownData,
  item:
    | Pick<CellItem<'task'>, 'entity' | 'type' | 'entityId'>
    | Pick<CellItem<'timeoff'>, 'entity' | 'type' | 'entityId'>,
) {
  const type = getItemType(item);

  const key = isFilteredOut(payload, item) ? 'OTHERS' : getItemKey(item);

  if (!data[type][key]) {
    const entry: ItemData = { dateData: {} };

    if (item.type === 'task') {
      if (key === 'OTHERS') {
        entry.name = '(All others)';
        entry.project = '';
        entry.client = '';
        entry.notes = '';
      } else {
        entry.name = item.entity.name;
        entry.project = payload.projects[item.entity.project_id].project_name;
        entry.client = payload.projects[item.entity.project_id].client_name;
        entry.notes = item.entity.notes;

        if (entry.client === 'No Client') {
          entry.client = '';
        }
      }
    }

    if (item.type === 'timeoff') {
      if (key === 'OTHERS') {
        entry.name = '(All others)';
        entry.notes = '';
      } else {
        entry.name = item.entity.timeoff_type_id;
        entry.notes = item.entity.timeoff_notes;
      }
    }

    data[type][key] = entry;
  }

  return data[type][key];
}

export type HolidayEntry = {
  name: string | null;
  start_date: string;
  end_date: string;
};

export function buildHolidays(
  cells: CellsMap,
  startCol: number,
  endCol: number,
) {
  const publicHolidays: Record<number, HolidayEntry> = {};
  const holidays: Record<string, HolidayEntry> = {};

  for (let i = startCol; i <= endCol; i++) {
    const cell = cells[`top:${i}`];

    if (cell && cell.highlights) {
      cell.highlights.forEach((group) => {
        if (!group) {
          // There are no highlights on the given day
          return;
        }

        group.forEach((item) => {
          if (
            'holiday_id' in item &&
            item.holiday_id &&
            !holidays[item.holiday_id]
          ) {
            holidays[item.holiday_id] = {
              name: item.name,
              start_date: item.date,
              end_date: item.end_date,
            };
          }

          if (
            'timeoff_id' in item &&
            item.region_holiday_id &&
            !publicHolidays[item.timeoff_id]
          ) {
            publicHolidays[item.timeoff_id] = {
              name: item.timeoff_notes,
              start_date: item.start_date,
              end_date: item.end_date,
            };
          }
        });
      });
    }
  }

  const allHolidays = [
    ...Object.values(holidays),
    ...Object.values(publicHolidays),
  ];

  allHolidays.sort((a, b) => {
    if (a.start_date !== b.start_date) {
      return String(a.start_date).localeCompare(b.start_date);
    }

    if (a.end_date !== b.end_date) {
      return -1 * String(a.end_date).localeCompare(b.end_date);
    }

    return String(a.name)
      .toLowerCase()
      .localeCompare(String(b.name).toLowerCase());
  });

  return allHolidays;
}

export type MilestonesEntry = {
  name: string;
  project_id: number;
  start_date: string;
  end_date: string;
};

export function buildMilestones(
  cells: CellsMap,
  startCol: number,
  endCol: number,
  data: ProjectsBreakdownData,
) {
  const milestones: Record<number, MilestonesEntry> = {};

  for (let i = startCol; i <= endCol; i++) {
    const cell = cells[`top:${i}`];

    if (cell && cell.highlights) {
      cell.highlights.forEach((group) => {
        if (!group) {
          // There are no highlights on the given day
          return;
        }

        group.forEach((item) => {
          if (
            'milestone_id' in item &&
            item.milestone_id &&
            !milestones[item.milestone_id] &&
            data.projects[item.project_id]
          ) {
            milestones[item.milestone_id] = {
              name: item.name,
              project_id: item.project_id,
              start_date: item.date,
              end_date: item.end_date,
            };
          }
        });
      });
    }
  }

  const allMilestones = Object.values(milestones);
  allMilestones.sort((a, b) => {
    if (a.start_date !== b.start_date) {
      return String(a.start_date).localeCompare(b.start_date);
    }

    if (a.end_date !== b.end_date) {
      return -1 * String(a.end_date).localeCompare(b.end_date);
    }

    return String(a.name)
      .toLowerCase()
      .localeCompare(String(b.name).toLowerCase());
  });

  return allMilestones;
}

export function groupScheduledItemsPerDay(items: CellItemWithEntity[]) {
  return items.reduce(
    (accum, item) => {
      // get items that extend into multiple days
      // e.g., x = 0 and w = 2
      // means that an item is extended and covering current and next day
      // indexes in this case will be equal to [0,1]
      const indexes = getRangeArray(
        item.x,
        Math.max(item.x! + item.w - 1, item.x!),
        1,
      );

      // add this item to the relevant days
      // above example item will be added to day 0 and day 1
      indexes.forEach((index) => {
        if (!accum[index]) {
          accum[index] = [];
        }

        accum[index].push(item);
      });

      return accum;
    },
    {} as Record<number, CellItemWithEntity[]>,
  );
}
