import React, { memo } from 'react';
import { t } from '@lingui/macro';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { motion } from 'framer-motion';

import { BlinkingTime } from '@float/common/components/BlinkingTime';
import { useActiveTimerDuration } from '@float/common/lib/hooks/timer/useActiveTimerDuration';
import { moment } from '@float/libs/moment';
import { Timer } from '@float/types';

import { animationVariants, textAnimationVariants } from './styles.motion';
import { useTimerLoggedTime } from './useTimerLoggedTime';
import { useLoggedTimeStyles } from './useTimerStyles';

import * as styles from './styles.css';

export type GlobalTimerProps = {
  timer: Timer;
};

const GlobalTimer = memo(({ timer }: GlobalTimerProps) => {
  const loggedTime = useTimerLoggedTime(timer);
  const timerDuration = useActiveTimerDuration(timer || null);
  const { backgroundColor, color } = useLoggedTimeStyles(loggedTime);
  const style = assignInlineVars({
    [styles.bgColor]: backgroundColor || '',
    [styles.fgColor]: color || '',
  });

  let title;
  const date = moment(loggedTime?.date).format('LL');
  const taskName = loggedTime?.task_name;
  title = t`Timer running on ${date} for ${taskName}`;

  return (
    <div className={styles.globalTimer} title={title}>
      <motion.div
        className={styles.wrapper}
        variants={animationVariants}
        initial="hidden"
        exit="hidden"
        animate="visible"
        layout
        style={style}
      >
        <span className={styles.centred}>
          <motion.div
            layout="position"
            className={styles.container}
            variants={textAnimationVariants}
          >
            <BlinkingTime time={timerDuration} />
          </motion.div>
        </span>
      </motion.div>
    </div>
  );
});

export { GlobalTimer };
