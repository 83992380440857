export const FETCH_TRELLO_OAUTH_LINK =
  'trello_integration/FETCH_TRELLO_OAUTH_LINK';
export const FETCH_TRELLO_OAUTH_LINK_SUCCESS =
  'trello_integration/FETCH_TRELLO_OAUTH_LINK_SUCCESS';
export const FETCH_TRELLO_OAUTH_LINK_FAILURE =
  'trello_integration/FETCH_TRELLO_OAUTH_LINK_FAILURE';
export const FETCH_TRELLO_CO_INTS = 'trello_integration/FETCH_CO_INTS';
export const FETCH_TRELLO_CO_INTS_SUCCESS =
  'trello_integration/FETCH_CO_INTS_SUCCESS';
export const TRELLO_IMPORT_SUCCESS = 'trello_integration/TRELLO_IMPORT_SUCCESS';
export const FETCH_TRELLO_CO_INTS_FAILURE =
  'trello_integration/FETCH_TRELLO_CO_INTS_FAILURE';
export const FETCH_TRELLO_PRELOAD = 'trello_integration/FETCH_TRELLO_PRELOAD';
export const CLEAR_TRELLO_PRELOAD = 'trello_integration/CLEAR_TRELLO_PRELOAD';
export const FETCH_TRELLO_PRELOAD_SUCCESS =
  'trello_integration/FETCH_TRELLO_PRELOAD_SUCCESS';
export const FETCH_TRELLO_PRELOAD_FAILURE =
  'trello_integration/FETCH_TRELLO_PRELOAD_FAILURE';
export const LINK_TRELLO = 'trello_integration/LINK_TRELLO';
export const LINK_TRELLO_SUCCESS = 'trello_integration/LINK_TRELLO_SUCCESS';
export const LINK_TRELLO_FAILURE = 'trello_integration/LINK_TRELLO_FAILURE';
export const FETCH_TRELLO_CONFIG = 'trello_integration/FETCH_TRELLO_CONFIG';
export const FETCH_TRELLO_CONFIG_SUCCESS =
  'trello_integration/FETCH_TRELLO_CONFIG_SUCCESS';
export const FETCH_TRELLO_CONFIG_FAILURE =
  'trello_integration/FETCH_TRELLO_CONFIG_FAILURE';
export const UPDATE_TRELLO_CONFIG = 'trello_integration/UPDATE_TRELLO_CONFIG';
export const UPDATE_TRELLO_CONFIG_SUCCESS =
  'trello_integration/UPDATE_TRELLO_CONFIG_SUCCESS';
export const UPDATE_TRELLO_CONFIG_FAILURE =
  'trello_integration/UPDATE_TRELLO_CONFIG_FAILURE';
export const ADD_TRELLO_CO_INT = 'trello_integration/ADD_TRELLO_CO_INT';
export const ADD_TRELLO_CO_INT_SUCCESS =
  'trello_integration/ADD_TRELLO_CO_INT_SUCCESS';
export const ADD_TRELLO_CO_INT_FAILURE =
  'trello_integration/ADD_TRELLO_CO_INT_FAILURE';
export const REMOVE_TRELLO_CO_INT = 'trello_integration/REMOVE_TRELLO_CO_INT';
export const REMOVE_TRELLO_CO_INT_SUCCESS =
  'trello_integration/REMOVE_TRELLO_CO_INT_SUCCESS';
export const REMOVE_TRELLO_CO_INT_FAILURE =
  'trello_integration/REMOVE_TRELLO_CO_INT_FAILURE';
