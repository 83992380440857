import { getTimeBasedWorkHours } from '@float/common/serena/util/workHours';
import { getWeekDay } from '@float/libs/dates/getWeekDay';
import { CurrentUser } from '@float/types/account';
import { Person } from '@float/types/person';

// Return the person work hours for a given date
export function getWorkHours(
  dates: DatesManager,
  user: CurrentUser,
  person: Person,
  date: string,
) {
  const hours = getTimeBasedWorkHours(date, person, user);

  return hours[getWeekDay(dates, date)];
}
