import { addMonths } from 'date-fns';
import addYears from 'date-fns/addYears';

import { TASK_EDIT_MODES } from '@float/common/components/Schedule/util/ContextMenu';
import { DEFAULT_DATESTRING_FORMAT_MOMENT } from '@float/constants/dates';
import { formatToFloatDate, parseFloatDate } from '@float/libs/dates';
import { moment } from '@float/libs/moment';
import { RepeatState } from '@float/types/repeatState';

import {
  calculateRepeatTimes,
  calculateWorkDays,
} from '../../taskModals/helpers/timeReducers';
import {
  EditTaskModalEntityLengthGetter,
  EditTaskModalPartialStateForCallbacks,
  EditTaskModalSetState,
  EditTaskModalState,
} from './EditTaskModal.types';

type GetMinWorkHoursInRange = (options: {
  start_date: string;
  end_date: string;
  people_ids: number[];
}) => number;

type GetEntityEndDate = (options: {
  length: number;
  start_date: string;
  people_ids: number[];
  timeoffId?: number;
}) => DateString;

/**
 * @deprecated
 *
 * this handler is used only for non-refactored time sections
 */
const getNewEndDate = (
  getEntityEndDate: GetEntityEndDate,
  newState: EditTaskModalPartialStateForCallbacks,
): EditTaskModalPartialStateForCallbacks => {
  const newEnd = getEntityEndDate({
    // TODO: Once the newState.startDate is ensured to be instance of Date
    // we need to replace it with formatToFloatDate(), but now sometimes the startDate is moment instance
    start_date: moment(newState.startDate).format(
      DEFAULT_DATESTRING_FORMAT_MOMENT,
    ),
    length: Math.floor(newState.totalHours / newState.hoursPd),
    people_ids: newState.peopleIds,
    timeoffId:
      'timeoff_id' in newState.task ? newState.task.timeoff_id : undefined,
  });

  newState.endDate = parseFloatDate(newEnd);

  return newState;
};

/**
 * @deprecated
 *
 * this handler is used only for non-refactored time sections
 */
export const getTotalHoursBlurHandler =
  (
    getMinWorkHoursInRange: GetMinWorkHoursInRange,
    datesManager: DatesManager,
    getEntityEndDate: GetEntityEndDate,
    getEntityLength: EditTaskModalEntityLengthGetter,
    isTimeoff: boolean,
    state: EditTaskModalState,
    setState: EditTaskModalSetState,
  ) =>
  () => {
    let newState: EditTaskModalPartialStateForCallbacks = { ...state };
    let { totalHours, hoursPd } = newState;

    //if below or equal to zero, set it to nearest step
    if (totalHours <= 0) {
      totalHours = 0.1;
    }

    //convert hours per day to number
    hoursPd = Number(hoursPd);

    //if total hours is less than hours per day, change hours per day to match
    if (totalHours <= hoursPd) {
      hoursPd = totalHours;
    } else {
      //Else, round total hours to the nearest increment of hours per day
      totalHours = totalHours - (totalHours % hoursPd);
    }
    //changing hoursPd
    newState.hoursPd = hoursPd;
    //changing totalHours
    newState.totalHours = totalHours;
    //changes endDate, relies on totalHours and hoursPd
    newState = getNewEndDate(getEntityEndDate, newState);
    //changes length, relies on endDate
    newState = calculateWorkDays(getEntityLength, newState);
    //changes repeatTimes, relies on endDate
    newState.repeatTimes = calculateRepeatTimes(datesManager, newState);

    const minHours = getMinWorkHoursInRange({
      start_date: formatToFloatDate(newState.startDate),
      end_date: formatToFloatDate(newState.endDate),
      people_ids: newState.peopleIds,
    });

    newState.isFullDay = minHours <= hoursPd;
    if (isTimeoff && newState.isFullDay) {
      newState.hoursPd = minHours;
      newState.totalHours = minHours * newState.length;
    }

    setState(newState);
  };

export const getIsIntervalRepeatable = (state: EditTaskModalState) => {
  const { isOffWork, people, mode, task } = state;
  if (
    isOffWork ||
    !people ||
    [TASK_EDIT_MODES.INSERT, TASK_EDIT_MODES.REPLACE].includes(mode) ||
    ('root_task_id' in task && task.root_task_id)
  ) {
    return false;
  }
  return true;
};

export const getNextRepeatStartDateStringified = (
  startDate: Date,
  mode: RepeatState,
  dates: DatesManager,
) => {
  const start = formatToFloatDate(startDate);

  // The dates manager relies on the day of week when searching for next repeat dates
  // We need to extend the search range by a month for safity
  let endDate = addYears(startDate, 1);
  endDate = addMonths(endDate, 1);
  const end = formatToFloatDate(endDate);

  const repeatStarts = dates.getRepeatStarts(mode, start, end);
  const nextRepeatStart = repeatStarts[0];

  // Fallback to the start date if not found to avoid of runtime errors
  return nextRepeatStart || start;
};

export const getNextRepeatStartDate = (
  startDate: Date,
  mode: RepeatState,
  dates: DatesManager,
) => {
  const nextRepeatStartDateStringified = getNextRepeatStartDateStringified(
    startDate,
    mode,
    dates,
  );
  const nextRepeatStartDate = parseFloatDate(nextRepeatStartDateStringified);

  return nextRepeatStartDate;
};

export const getNumberOfRepeatTimes = (
  entityStartDate: Date,
  repeatEndDate: Date | null,
  repeatState: RepeatState,
  dates: DatesManager,
) => {
  let repeatTimes = 1;

  if (repeatEndDate && repeatState) {
    const startDateFormatted = formatToFloatDate(entityStartDate);
    const repeatEndDateFormatted = formatToFloatDate(repeatEndDate);

    repeatTimes =
      dates.getRepeatStarts(
        repeatState,
        startDateFormatted,
        repeatEndDateFormatted,
      ).length + 1;
  }

  return repeatTimes;
};

export const getIsSpecificTimeSet = (startTime: string | null) => {
  return typeof startTime === 'string' && startTime.trim().length > 0;
};
