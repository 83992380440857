import React from 'react';

export default function ErrorTriangle({
  color = '#C1C1C1',
  width = 45,
  height = 40,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 45 40"
    >
      <path
        fill={color}
        d="M22.5 0c.889 0 1.711.472 2.16 1.24l20 35c.451.774.454 1.729.009 2.505C44.224 39.521 43.395 40 42.5 40h-40c-.896 0-1.721-.479-2.167-1.255-.446-.776-.443-1.731.008-2.505l20-35C20.79.472 21.611 0 22.5 0zm-.008 30.337c-.382 0-.725.062-1.03.177-.302.117-.566.281-.78.491-.217.207-.386.455-.503.74-.12.289-.179.596-.179.933 0 .336.059.643.179.923.116.282.285.524.504.735.213.208.477.37.78.49.304.117.646.174 1.03.174.382 0 .727-.057 1.035-.172.312-.12.576-.283.79-.49.217-.212.386-.454.503-.736.12-.28.179-.587.179-.924 0-.337-.059-.644-.179-.933-.116-.285-.285-.533-.504-.74-.213-.21-.477-.374-.79-.491-.307-.115-.652-.177-1.035-.177zM24.875 12.5h-4.767l.526 15h3.716l.525-15z"
      />
    </svg>
  );
}
