import React from 'react';
import { t } from '@lingui/macro';

import { MultiSelectField } from '../components/MultiSelectField';
import {
  useProjectTagCreate,
  useProjectTagsACL,
  useProjectTagsOptions,
} from './ProjectTagsField.hooks';

export function ProjectTagsField() {
  const { options, getSelectedOptions } = useProjectTagsOptions();
  const { canCreateTags } = useProjectTagsACL();
  const createProjectTag = useProjectTagCreate();

  return (
    <MultiSelectField
      name="project.tags"
      label={t`Tags`}
      options={options}
      mapSelectedOptions={(value) => getSelectedOptions(value)}
      creatable={canCreateTags}
      onCreate={createProjectTag}
    />
  );
}
