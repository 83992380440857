import React from 'react';
import { useWatch } from 'react-hook-form';
import { t } from '@lingui/macro';

import {
  getClientColors,
  getRecentColors,
} from '@float/common/selectors/colors';
import { useAppSelectorWithParams } from '@float/common/store';

import { ColorField } from '../../fields/ColorField';
import { ProjectFormData } from '../../types';

import * as styles from './styles.css';

export const PhaseColor: React.FC<{
  index: number;
}> = ({ index }) => {
  const clientId = useWatch<ProjectFormData, 'project.client_id'>({
    name: 'project.client_id',
  });
  const projectColor = useWatch<ProjectFormData, 'project.color'>({
    name: 'project.color',
  });
  const clientColors = useAppSelectorWithParams(
    getClientColors,
    clientId as number,
  );
  const recentColors = useAppSelectorWithParams(getRecentColors);

  return (
    <ColorField
      aria-label={t`Phase color`}
      className={styles.phaseColorField}
      name={`phases.${index}.color`}
      size="sm"
      clientColors={clientColors}
      recentColors={recentColors}
      defaultColor={projectColor}
      modal
    />
  );
};
