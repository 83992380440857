import React from 'react';

export default function ({ size = '24', color = '#B21047', style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      style={style}
    >
      <path
        fill={color}
        d="M17 5l2 2-5.001 4.999L19 17l-2 2-5-5.001L7 19l-2-2 5-5.001L5 7l2-2 5 5 5-5z"
      />
    </svg>
  );
}
