import React from 'react';

export const IconSupport = () => (
  <svg
    fill="none"
    width={48}
    height={48}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#iconHelpCenterMask)">
      <path
        fill="#F9B6A1"
        stroke="#242C39"
        strokeWidth=".385"
        d="m26.126 22.712 1.156.206a4.068 4.068 0 0 1 3.335 4.384l-.133 1.424a2.346 2.346 0 0 0 1.272 2.31.564.564 0 0 1 .155.89l-1.16 1.228a8.38 8.38 0 0 1-6.28 2.623 4.685 4.685 0 0 1-1.405-.25l-.678-.23a7.221 7.221 0 0 1-4.327-4.03l-.431-1.023a1.397 1.397 0 0 1 .86-1.872l.099-.032a4.509 4.509 0 0 0 2.9-2.87 4.124 4.124 0 0 1 4.637-2.758Z"
      />
      <path
        fill="#fff"
        d="M27.68 34.167c-4.152.438-7.65-3.733-8.879-5.873-3.46.683-8.883 3.47-10.631 5.873-3.573 4.913-3.96 8.558-5.51 14.76h31.3V46.6c.633 1.116.954 1.55.954 2.325h9.777c0-4.263-4.716-13.53-5.673-14.759-.765-.983-6.01-2.96-7.923-3.825.592 1.093.738 3.388-3.415 3.825Z"
      />
      <path
        stroke="#242C39"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".3"
        d="M33.96 46.601c.633 1.116.954 1.55.954 2.325h9.777c0-4.263-4.716-13.53-5.673-14.759-.765-.983-6.01-2.96-7.923-3.825.592 1.093.738 3.388-3.415 3.825-4.152.438-7.65-3.733-8.879-5.873-3.46.683-8.883 3.47-10.631 5.873-3.573 4.913-3.96 8.558-5.51 14.76h31.3V46.6Zm0 0c-1.867-1.548 1.311 1.42 0 0Z"
      />
      <path
        stroke="#242C39"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".385"
        d="M17.862 37.3c0 .13 1.786 7.443 2.325 11.626m-13.177 0a16.087 16.087 0 0 1-.566-2.337"
      />
      <path
        fill="#F9B6A1"
        stroke="#242C39"
        strokeWidth=".33"
        d="m30.766 10.235.031.008.033-.003c1.483-.148 2.847.785 3.749 2.528.9 1.739 1.32 4.255.915 7.179-.356 2.577-.929 4.549-2.047 5.862-1.107 1.3-2.775 1.984-5.401 1.927-2.093-.045-4.502-1.374-6.335-3.478-1.829-2.1-3.06-4.948-2.822-7.995.019-.244.068-.492.138-.732 1.015-3.449 2.722-5.101 4.759-5.739 2.056-.643 4.49-.267 6.98.443Z"
      />
      <path
        fill="#471E15"
        stroke="#242C39"
        strokeWidth=".33"
        d="M21.325 20.353c1.25-.687 6.672-5.628 9.226-8.012.74-.687 1.038-2.671 1.095-3.577-1.668-1.67-8.262-4.646-13.392-.64-6.412 5.008 0 10.588-3.753 15.883-1.372 1.936-1.407 3.797 0 7.44.94 2.432-5.26 2.189-1.564 6.118 1.471 1.564 3.128 2.861 4.066 4.435 6.725-2.861.256-6.338 3.227-9.486 2.377-2.518 2.346-5.723 2.033-7.01-.834-1.431-2.19-4.464-.939-5.151Z"
      />
      <path
        fill="#471E15"
        stroke="#242C39"
        strokeWidth=".33"
        d="M35.613 18.427c-.5-6.181-3.44-6.772-4.848-6.295.75-1.03.313-3.434 0-4.579 6.255 3.148 7.038 6.01 6.1 12.448-.94 6.438-2.19 7.583 2.97 12.447 4.129 3.892 2.346 7.026.939 8.981-1.303.334-3.722.801-2.971 0 .75-.801-.313-2.242-.939-2.861-.5 2.403-3.336 2.909-4.691 2.861.834-.858 2.001-1.798 0-5.69-2.002-3.892-1.773-7.058-1.408-8.155 3.628-1.488 4.744-6.725 4.848-9.157Z"
      />
      <path
        fill="#242C39"
        stroke="#242C39"
        strokeWidth=".33"
        d="M17.457 18.13c.034.876.324 1.647.758 2.186.433.539 1 .837 1.598.8.597-.035 1.14-.4 1.529-.988.39-.588.619-1.39.585-2.267-.034-.876-.324-1.647-.758-2.186-.433-.538-1-.836-1.598-.8-.598.036-1.14.4-1.53.988-.389.589-.618 1.39-.584 2.267Z"
      />
      <path
        stroke="#242C39"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".33"
        d="m18.775 20.398.617 2.73c1.05.567 6.4 1.153 9.384 1.044"
      />
      <path
        fill="#93C4EC"
        stroke="#242C39"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".33"
        d="M25.635 8.348c-5.095 1.223-5.998 6.167-5.952 8.777l-.559 1.164-.687-1.45c-.281-4.753 1.449-9.428 7.265-9.911 4.652-.387 7.567 1.561 8.437 2.702-.732-.503-3.719-2.43-8.504-1.282Z"
      />
      <path
        fill="#93C4EC"
        stroke="#242C39"
        strokeWidth=".33"
        d="M16.314 18.084c.034.877.324 1.648.757 2.186.434.54 1.001.837 1.599.801.597-.036 1.139-.4 1.529-.989.39-.588.618-1.39.584-2.266-.034-.876-.324-1.647-.757-2.186-.434-.539-1.001-.837-1.599-.8-.597.035-1.139.4-1.529.988-.39.588-.618 1.39-.584 2.266Z"
      />
      <rect
        width="3.812"
        height="1.738"
        x="-.173"
        y=".158"
        fill="#242C39"
        stroke="#242C39"
        strokeWidth=".33"
        rx=".308"
        transform="matrix(-.99923 -.03917 -.04679 .9989 31.87 23.332)"
      />
    </g>
    <defs>
      <clipPath id="iconHelpCenterMask">
        <path fill="#fff" d="M3 0h42v42H3z" />
      </clipPath>
    </defs>
  </svg>
);
