import React from 'react';

import { getHoursDisplayFormat } from '@float/common/lib/timer/displayHoursFormat';
import { getUser } from '@float/common/selectors/currentUser';
import { ScheduleDataFetcher } from '@float/common/serena/Data/ScheduleDataFetcher';
import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import { useAppSelectorStrict } from '@float/common/store';

import { getRelevantInsightType } from './helpers';
import { InsightForLogTeam } from './InsightForLogTeam/InsightForLogTeam';
import { InsightForProjectPlan } from './InsightForProjectPlan/InsightForProjectPlan';
import { InsightForSchedule } from './InsightForSchedule/InsightForSchedule';
import { useTimeRangeInsightsProcessing } from './useTimeRangeInsightsProcessing';

type TimeRangeInsightsProps = {
  fetcher: ScheduleDataFetcher;
  isDragging: boolean;
  onClick: () => void;
  showLoader?: boolean;
};

export function TimeRangeInsights({
  fetcher,
  isDragging,
  onClick,
  showLoader,
}: TimeRangeInsightsProps) {
  const {
    cellsWrapper: { cells },
    timeRange,
    timeRangeSettings,
    logTimeViewType: isLogTimeView,
    viewType,
  } = useScheduleContext();

  const relevantInsightType = getRelevantInsightType(viewType, isLogTimeView);

  const hoursDisplayFormat = useAppSelectorStrict((state) =>
    getHoursDisplayFormat(getUser(state).prefs),
  );

  const insights = useTimeRangeInsightsProcessing({
    end: timeRange.end_date,
    fetchedRanges: cells._fetchedRanges,
    fetcher,
    // Do not process the insights while dragging to avoid impacts on the
    // dragging performance
    isDisabled: isDragging || !cells._allLoaded,
    isLogTimeView,
    start: timeRange.start_date,
    viewType,
  });

  const isLoading = insights.isLoading || showLoader === true;

  function handleLogTimeClick() {
    if (!isLogTimeView) return;

    onClick();
  }

  return (
    <>
      {relevantInsightType === 'schedule' && (
        <InsightForSchedule
          insight={insights.value.total}
          isLoading={isLoading}
          displayMetric={
            timeRangeSettings.insightsPreferredUnit === 'percentage'
              ? 'utilization'
              : 'capacity'
          }
          displayUtilizationOvertime={true}
        />
      )}
      {relevantInsightType === 'projectPlan' && (
        <InsightForProjectPlan
          insight={insights.value.total}
          isLoading={isLoading}
          displayMetric={
            timeRangeSettings.insightsPreferredUnit === 'percentage'
              ? 'utilization'
              : 'allocation'
          }
        />
      )}
      {relevantInsightType === 'logTeam' && (
        <InsightForLogTeam
          insight={insights.value.total}
          hoursDisplayFormat={hoursDisplayFormat}
          isLoading={isLoading}
          onClick={handleLogTimeClick}
        />
      )}
    </>
  );
}
