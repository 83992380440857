import bg27x1 from './27x1.png';
import bg27x2 from './27x2.png';
import bg27x3 from './27x3.png';
import bg27x4 from './27x4.png';
import bg27x5 from './27x5.png';
import bg27x6 from './27x6.png';
import bg27x7 from './27x7.png';
import bg90x1 from './90x1.png';
import bg90x2 from './90x2.png';
import bg90x3 from './90x3.png';
import bg90x4 from './90x4.png';
import bg90x5 from './90x5.png';
import bg90x6 from './90x6.png';
import bg90x7 from './90x7.png';
import bg245x1 from './245x1.png';
import bg245x2 from './245x2.png';
import bg245x3 from './245x3.png';
import bg245x4 from './245x4.png';
import bg245x5 from './245x5.png';
import bg245x6 from './245x6.png';
import bg245x7 from './245x7.png';

export default {
  '27x1': bg27x1,
  '27x2': bg27x2,
  '27x3': bg27x3,
  '27x4': bg27x4,
  '27x5': bg27x5,
  '27x6': bg27x6,
  '27x7': bg27x7,
  '90x1': bg90x1,
  '90x2': bg90x2,
  '90x3': bg90x3,
  '90x4': bg90x4,
  '90x5': bg90x5,
  '90x6': bg90x6,
  '90x7': bg90x7,
  '245x1': bg245x1,
  '245x2': bg245x2,
  '245x3': bg245x3,
  '245x4': bg245x4,
  '245x5': bg245x5,
  '245x6': bg245x6,
  '245x7': bg245x7,
};
