import { createAction } from '@reduxjs/toolkit';

import { SearchResultsState } from './searchResults.types';

export const searchResultsUpdateAction = createAction<
  Partial<SearchResultsState>,
  'SEARCH_RESULTS_UPDATE'
>('SEARCH_RESULTS_UPDATE');

export type SearchResultsUpdateAction = ReturnType<
  typeof searchResultsUpdateAction
>;

export const searchResolveStartAction = createAction<void, 'SEARCH_START'>(
  'SEARCH_START',
);
export type SearchResolveStartAction = ReturnType<
  typeof searchResolveStartAction
>;
