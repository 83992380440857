import { ReduxState } from '@float/common/reducers/lib/types';

export class FetchFailedError extends Error {
  failedData: string[];
  retry: () => Promise<unknown>;

  constructor(failed: string[], retry: () => Promise<unknown>) {
    super('Fetch Failed');

    this.failedData = failed;
    this.retry = retry;
  }
}

// This selector is used to check if all the data required
// by Float to work properly has been successfully fetched
export const getPortalFailedDataFetch = (state: ReduxState) => {
  const failed: Array<keyof ReduxState> = [];

  if (state.jwt.loadState === 'LOAD_FAILED') {
    failed.push('jwt' as const);
  }

  if (state.search.contextState === 'LOAD_FAILED') {
    failed.push('search' as const);
  }

  if (state.projects.loadState === 'LOAD_FAILED') {
    failed.push('projects' as const);
  }

  if (state.people.loadState === 'LOAD_FAILED') {
    failed.push('people' as const);
  }

  if (state.accounts.loadState === 'LOAD_FAILED') {
    failed.push('accounts' as const);
  }

  if (state.phases.loadState === 'LOAD_FAILED') {
    failed.push('phases' as const);
  }

  if (state.timeoffTypes.loadState === 'LOAD_FAILED') {
    failed.push('timeoffTypes' as const);
  }

  if (state.departments.loadState === 'LOAD_FAILED') {
    failed.push('departments' as const);
  }

  return failed;
};
