import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';

import { useScheduleContext } from '@float/common/serena/ScheduleContext';

type Props = {
  loaded: boolean;
  showEditTaskModalForId: (value: string, type: string) => void;
  showNotificationsModal: () => void;
};

const parseHash = (hash: string) => {
  if (!hash) return { type: null, value: null };
  const [type, ...rest] = hash.substring(1).split('-');
  const value = rest.join('-');
  return { type, value };
};

export const useLocationHashNavigation = (props: Props) => {
  const { loaded, showEditTaskModalForId, showNotificationsModal } = props;

  const history = useHistory();
  const { dates, logMyTimeView, setSuvWeek } = useScheduleContext();
  const { type, value } = parseHash(history.location.hash);

  const handleTaskNavigation = useCallback(
    (value: string, type: string) => {
      history.push({ pathname: '/', search: history.location.search });
      showEditTaskModalForId(value, type);
    },
    [history, showEditTaskModalForId],
  );

  const handleDateNavigation = useCallback(
    (value: string) => {
      if (!logMyTimeView) return;

      history.push({ pathname: '/log-time', search: history.location.search });

      try {
        const [colIdx] = dates.toDescriptor(value);
        setSuvWeek(colIdx);
      } catch (err) {
        return;
      }
    },
    [history, logMyTimeView, dates, setSuvWeek],
  );

  const handleNotificationNavigation = useCallback(() => {
    history.push({ pathname: '/', search: history.location.search });
    showNotificationsModal();
  }, [history, showNotificationsModal]);

  const navigate = useCallback(() => {
    if (!type || !value) return;

    switch (type) {
      case 'task':
      case 'timeoff':
      case 'status':
        handleTaskNavigation(value, type);
        break;
      case 'date':
        handleDateNavigation(value);
        break;
      case 'notification':
        if (value === 'settings') {
          handleNotificationNavigation();
        }
        break;
      default:
        break;
    }
  }, [
    type,
    value,
    handleTaskNavigation,
    handleDateNavigation,
    handleNotificationNavigation,
  ]);

  useEffect(() => {
    if (loaded) {
      navigate();
    }

    // Only re-run this effect specifically `loaded` or the `location.hash`
    // changes. Adding `history` as a dependency would cause an infinite loop,
    // as `history` is mutable and may be updated outside of this hook (e.g.,
    // through route changes).
    // https://linear.app/float-com/issue/EXP-621/bug-clicking-on-a-notification-with-active-filters-causes-the-app-to
  }, [loaded, history.location.hash]);
};
