export type RemoteEntityType =
  | 'client'
  | 'department'
  | 'manager'
  | 'people'
  | 'peopleTag'
  | 'peopleType'
  | 'role'
  | 'timeOffStatus'
  | 'project'
  | 'phase'
  | 'projectOwner'
  | 'projectTag'
  | 'projectStatus'
  | 'task'
  | 'timeOffType'
  | 'taskStatus';
type EntityMeta = {
  parent_department_id?: null | number;
  isActive?: 0 | 1;
};

export type QueryApiResult = {
  ids: number[];
  name: string;
  type: RemoteEntityType;
  score: number;
  meta?: Record<string, EntityMeta>;
};

export type QueryApiParams = {
  query: string;
  filter?: {
    key: 'category';
    value: string;
  };
  pagination?: {
    page: number;
    pageSize: number;
  };
};
export type PaginatedQueryResponse = [QueryApiResult[], number, number];
interface SearchResolveContainsFilter {
  operator: 'contains';
  field: null;
  value: string;
}
interface SearchResolveIsFilter {
  operator: 'is' | 'isNot';
  field: RemoteEntityType;
  value: number | number[];
  skip?: SearchResolveFieldValues[];
}

export type SearchResolveFilter =
  | SearchResolveContainsFilter
  | SearchResolveIsFilter;
type SearchResolveLogicalOperator = 'and' | 'or';

export interface SearchResolveQuery {
  filters: (SearchResolveFilter | SearchResolveQuery)[];
  logicalOperator: SearchResolveLogicalOperator;
}

export type SearchResolveContext = 'schedule' | 'logged_time';

export const SearchResolveField = {
  PEOPLE: 'people',
  PROJECT: 'project',
  LOGGED_TIME: 'loggedTime',
  TASK: 'task',
  TIME_OFF: 'timeOff',
} as const;

export type SearchResolveFieldValues =
  (typeof SearchResolveField)[keyof typeof SearchResolveField];

export interface SearchResolveApiParams {
  query: SearchResolveQuery;
  context: SearchResolveContext;
  fields?: SearchResolveFieldValues[];
  contextId?: string;
}

export type SearchResolveEntityKey = keyof SearchResolveResponse['result'];

export interface SearchResolveResponse {
  contextId: string; // UUID
  result: {
    loggedTime: string[];
    people: number[];
    project: number[];
    task: number[];
    timeOff: number[];
  };
}
export enum MatchAlgorithm {
  CONTAINS = 'contains',
  EXACT = 'exact',
  FULL_TEXT_EXACT = 'full_text_exact',
  FULL_TEXT_WILDCARD = 'full_text_wildcard',
}
