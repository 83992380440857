import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const sharedCss = css`
  font-weight: 600;
  color: inherit;
  overflow-wrap: anywhere;

  &:hover {
    color: ${(p) => p.theme.blue};
    text-decoration-line: underline;
  }
`;

const StyledLink = styled(Link)`
  ${sharedCss}
`;

const StyledA = styled.a`
  ${sharedCss}
`;

function ActivityLink({ item, onItemClick, children }) {
  const { item_type: type, item_id: id } = item;
  const addLink = ['task', 'timeoff', 'status'].includes(type) && id;

  return addLink ? (
    <StyledLink
      to={`/#${type}-${id}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </StyledLink>
  ) : (
    <StyledA onClick={() => onItemClick(item)}>{children}</StyledA>
  );
}

export default ActivityLink;
