import { Account, AccountType } from '@float/types';

import { getManagerAccess, getMemberAccess } from './access';

export function getIsFullMemberOrPeopleManager(account?: Account) {
  if (
    account?.account_type !== AccountType.Member &&
    account?.account_type !== AccountType.Manager
  )
    return false;
  if (account.account_type === AccountType.Member) {
    return getMemberAccess(account)?.canEdit;
  }
  if (account.account_type === AccountType.Manager) {
    return !getManagerAccess(account)?.isProjectManager;
  }
}
