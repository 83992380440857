import { StyleRule } from '@vanilla-extract/css';
import { css } from 'styled-components';

export const M500 = css`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.1px;
`;

export const SB600 = css`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.1px;
`;

export const VE_M500: StyleRule = {
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '18px',
  lineHeight: '1.11',
  letterSpacing: '-0.1px',
};

export const VE_SB600: StyleRule = {
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '18px',
  lineHeight: '1.11',
  letterSpacing: '-0.1px',
};
