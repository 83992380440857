import { saveAs } from 'file-saver';
import { compact, forEach, sortBy, transform } from 'lodash';
import { getTaskStatusString } from 'reports/components/TabBarFilters/StatusFilter';
import { getTeamModeString } from 'reports/components/TabBarFilters/TeamMode';
import { parseTags } from 'reports/helpers/tags';

import { getPercentage as _getPercentage } from '@float/common/lib/budget';
import { moment } from '@float/libs/moment';

import {
  getBlankChildRecord,
  parseHolidayTimeoffRecord,
} from './table.helpers';

// For the purposes of CSV export, we always want the '%' symbol
function getPercentage(a, b) {
  return _getPercentage(a, b, true);
}

export const getExportDepartmentName = (ctx, personId) => {
  const { people, departments, parentDepartments } = ctx;
  const person = people[personId];
  const department = person && departments[person.department_id];
  if (!department) {
    return '';
  }

  const parentDepartmentIds =
    (department && parentDepartments[department.id]) || [];
  const departmentName = compact(
    [...parentDepartmentIds, person.department_id].map(
      (depId) => departments[depId]?.name,
    ),
  ).join(' / ');

  return departmentName;
};

const COLUMNS = [
  ['Person', (d, r) => r.person_name],
  ['Role', (d, r) => r.job_title || ' '],
  ['Department', (d, r, ctx) => getExportDepartmentName(ctx, r.people_id)],
  ['Tags', (d, r) => r.tags || ' '],
  ['Capacity hrs', (d, r) => r.capacity],
  ['Client', (d) => d.client?.replace(/\r|\n/g, '') || ' '], // filter new line characters
  ['Project', (d) => d.project || ' '],
  [
    'Task',
    (d) => (d.type === 'timeoff' || d.type === 'holiday' ? ' ' : d.name || ' '),
  ],
  [
    'Time off',
    (d, r) => (d === r || d.type === 'timeoff' ? d.name || ' ' : ' '),
  ],
  [
    'Holiday',
    (d, r) => (d === r || d.type === 'holiday' ? d.name || ' ' : ' '),
  ],
  ['Scheduled hrs', (d) => d.scheduled],
  ['Scheduled billable hrs', (d) => d.billable],
  ['Scheduled non-billable hrs', (d) => d.nonbillable],
  ['Scheduled tentative (all) hrs', (d) => d.tentative],
  ['Logged hrs', (d) => d.logged.scheduled],
  ['Logged billable hrs', (d) => d.logged.billable],
  ['Logged non-billable hrs', (d) => d.logged.nonbillable],
  ['Scheduled overtime hrs', (d, r) => (d === r ? r.overtime : '')],
  ['Logged overtime hrs', (d, r) => (d === r ? r.logged.overtime : '')],
  ['Unscheduled hrs', (d, r) => (d === r ? r.unscheduled : '')],
  ['Time off hrs', (d) => d.timeoff],
  ['Time off days', (d) => d.timeoff_days],
  ['Holiday hrs', (d) => d.holiday],
  ['Holiday days', (d) => d.holiday_days],
  ['Scheduled % of capacity', (d, r) => getPercentage(d.scheduled, r.capacity)],
  [
    'Scheduled billable % of capacity',
    (d, r) => getPercentage(d.billable, r.capacity),
  ],
  [
    'Scheduled billable % of scheduled',
    (d) => getPercentage(d.billable, d.scheduled),
  ],
  [
    'Logged % of capacity',
    (d, r) => getPercentage(d.logged.scheduled, r.capacity),
  ],
  [
    'Logged billable % of capacity',
    (d, r) => getPercentage(d.logged.billable, r.capacity),
  ],
  [
    'Logged billable % of logged',
    (d) => getPercentage(d.logged.billable, d.logged.scheduled),
  ],
];

function breakdown(ctx, raw) {
  const { projects, people } = ctx;

  const byPeople = transform(raw.capacity, (acc, cap, personId) => {
    const person = people[personId];

    acc[personId] = {
      people_id: personId,
      person_name: person.name,
      job_title: person.job_title,
      tags: parseTags(person.tags),
      client: 'All',
      project: 'All',
      name: 'All',
      capacity: cap,
      scheduled: 0,
      get unscheduled() {
        return Math.max(0, this.capacity + this.overtime - this.scheduled);
      },
      billable: 0,
      nonbillable: 0,
      tentative: 0,
      logged: {
        scheduled: 0,
        billable: 0,
        nonbillable: 0,
        overtime: 0,
      },
      timeoff: 0,
      timeoff_days: 0,
      holiday: 0,
      holiday_days: 0,
      overtime: 0,
      children: {},
    };
  });

  raw.totals.forEach((item) => {
    const record = byPeople[item.person_id];

    if (item.project_id && !projects[item.project_id]) {
      console.error('No project found', { item });
      return;
    }

    // We want to aggregate the children by either the task or timeoff name.
    let key;
    if (item.type === 'timeoff') {
      key = `timeoff:${item.name}`;
    } else if (item.type === 'task' || item.type === 'logged_time') {
      key = `task:${item.project_id}:${item.name}`;
    } else {
      throw Error('Unknown type', item);
    }

    if (!record.children[key]) {
      const project = projects[item.project_id];

      record.children[key] = getBlankChildRecord();
      record.children[key].type = item.type;
      record.children[key].name = item.name;

      if (project) {
        record.children[key].client = project.client_name;
        record.children[key].project = project.project_name;
      }
    }

    const child = record.children[key];

    // Record refers to the parent row (people).
    // Child refers to the child row (project/timeoff).
    // The "root" suffix is used to get either the task/logged time data
    if (item.type === 'timeoff') {
      child.timeoff += item.hours.scheduled;
      record.timeoff += item.hours.scheduled;

      const timeoffRecord = raw.paidTimeoff.find((t) => t.id == item.id);
      if (timeoffRecord && timeoffRecord.people[item.person_id]) {
        child.timeoff_days += timeoffRecord.people[item.person_id].days;
        record.timeoff_days += timeoffRecord.people[item.person_id].days;
      }
    } else if (item.type === 'task' || item.type === 'logged_time') {
      const childRoot = item.type === 'task' ? child : child.logged;
      const recordRoot = item.type === 'task' ? record : record.logged;

      childRoot.scheduled += item.hours.scheduled;
      recordRoot.scheduled += item.hours.scheduled;

      if (item.tentative) {
        if (item.billable) {
          childRoot.tentative += item.hours.scheduled;
          recordRoot.tentative += item.hours.scheduled;
        } else {
          childRoot.tentative += item.hours.scheduled;
          recordRoot.tentative += item.hours.scheduled;
        }
      }

      if (item.billable) {
        childRoot.billable += item.hours.scheduled;
        recordRoot.billable += item.hours.scheduled;
      } else {
        childRoot.nonbillable += item.hours.scheduled;
        recordRoot.nonbillable += item.hours.scheduled;
      }
    }
  });

  raw.timeoff.forEach((to) =>
    parseHolidayTimeoffRecord(
      to,
      byPeople,
      people,
      ctx.user,
      ctx.settings.startDate,
      ctx.settings.endDate,
    ),
  );

  forEach(raw.overtime, (ot, personId) => {
    const person = byPeople[personId];
    person.overtime = ot.total;
    person.logged.overtime = ot.logged;
  });

  const data = sortBy(Object.values(byPeople), 'name');
  data.forEach((d) => {
    d.children = sortBy(Object.values(d.children), [
      'client',
      'project',
      'name',
    ]);
  });
  return data;
}

function getSheetData(ctx, rawTableData) {
  const data = breakdown(ctx, rawTableData);

  const columns = ctx.timeTrackingEnabled
    ? [...COLUMNS]
    : COLUMNS.filter((c) => !c[0].includes('Logged'));

  if (!ctx.singlePerson) {
    columns.unshift(['', (d) => ' ']);
  }

  const headers = columns.map((c) => c[0]);
  const result = [headers];

  data.forEach((d) => {
    d.children.forEach((child) => {
      const row = columns.map((c) => c[1](child, d, ctx) || 0);
      result.push(row);
    });

    const totalsRow = columns.map((c) => c[1](d, d, ctx) || 0);
    totalsRow[0] = 'Total';
    result.push(totalsRow);
  });

  result.push([], []);

  result.push(
    [
      'Date range',
      moment(ctx.settings.startDate).format('DD MMM YYYY'),
      moment(ctx.settings.endDate).format('DD MMM YYYY'),
    ],
    ['Tasks', getTaskStatusString(ctx.settings)],
    ['People', getTeamModeString(ctx.settings)],
  );

  if (ctx.searchFiltersString) {
    result.push([ctx.searchFiltersString.replace('Filters:', 'Filters')]);
  }

  return result;
}

export default async function exportTableCsv(ctx, rawTableData) {
  return import('xlsx').then((XLSX) => {
    const { singlePerson, settings } = ctx;
    const startDate = moment(settings.startDate).format('YYYYMMDD');
    const endDate = moment(settings.endDate).format('YYYYMMDD');

    const filename = `${
      singlePerson || 'People'
    }-Table-${startDate}-${endDate}.csv`;

    const sheetData = getSheetData(ctx, rawTableData);

    const ws = XLSX.utils.aoa_to_sheet(sheetData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Float');
    const wbout = XLSX.write(wb, { type: 'array', bookType: 'csv' });

    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), filename);
  });
}
