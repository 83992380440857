export const FETCH_TEAMWORK_OAUTH_LINK =
  'teamwork_integration/FETCH_TEAMWORK_OAUTH_LINK';
export const FETCH_TEAMWORK_OAUTH_LINK_SUCCESS =
  'teamwork_integration/FETCH_TEAMWORK_OAUTH_LINK_SUCCESS';
export const FETCH_TEAMWORK_OAUTH_LINK_FAILURE =
  'teamwork_integration/FETCH_TEAMWORK_OAUTH_LINK_FAILURE';

export const FETCH_TEAMWORK_CO_INTS = 'teamwork_integration/FETCH_CO_INTS';
export const FETCH_TEAMWORK_CO_INTS_SUCCESS =
  'teamwork_integration/FETCH_CO_INTS_SUCCESS';
export const FETCH_TEAMWORK_CO_INTS_FAILURE =
  'teamwork_integration/FETCH_CO_INTS_FAILURE';

export const TEAMWORK_IMPORT_SUCCESS =
  'teamwork_integration/TEAMWORK_IMPORT_SUCCESS';

export const FETCH_TEAMWORK_PRELOAD =
  'teamwork_integration/FETCH_TEAMWORK_PRELOAD';
export const FETCH_TEAMWORK_PRELOAD_SUCCESS =
  'teamwork_integration/FETCH_TEAMWORK_PRELOAD_SUCCESS';
export const FETCH_TEAMWORK_PRELOAD_FAILURE =
  'teamwork_integration/FETCH_TEAMWORK_PRELOAD_FAILURE';
export const CLEAR_TEAMWORK_PRELOAD =
  'teamwork_integration/CLEAR_TEAMWORK_PRELOAD';

export const FETCH_TEAMWORK_CONFIG =
  'teamwork_integration/FETCH_TEAMWORK_CONFIG';
export const FETCH_TEAMWORK_CONFIG_SUCCESS =
  'teamwork_integration/FETCH_TEAMWORK_CONFIG_SUCCESS';
export const FETCH_TEAMWORK_CONFIG_FAILURE =
  'teamwork_integration/FETCH_TEAMWORK_PRELOAD_FAILURE';

export const UPDATE_TEAMWORK_CONFIG =
  'teamwork_integration/UPDATE_TEAMWORK_CONFIG';
export const UPDATE_TEAMWORK_CONFIG_SUCCESS =
  'teamwork_integration/UPDATE_TEAMWORK_CONFIG_SUCCESS';
export const UPDATE_TEAMWORK_CONFIG_FAILURE =
  'teamwork_integration/UPDATE_TEAMWORK_CONFIG_FAILURE';

export const ADD_TEAMWORK_CO_INT = 'teamwork_integration/ADD_TEAMWORK_CO_INT';
export const ADD_TEAMWORK_CO_INT_SUCCESS =
  'teamwork_integration/ADD_TEAMWORK_CO_INT_SUCCESS';
export const ADD_TEAMWORK_CO_INT_FAILURE =
  'teamwork_integration/ADD_TEAMWORK_CO_INT_FAILURE';

export const REMOVE_TEAMWORK_CO_INT =
  'teamwork_integration/REMOVE_TEAMWORK_CO_INT';
export const REMOVE_TEAMWORK_CO_INT_SUCCESS =
  'teamwork_integration/REMOVE_TEAMWORK_CO_INT_SUCCESS';
export const REMOVE_TEAMWORK_CO_INT_FAILURE =
  'teamwork_integration/REMOVE_TEAMWORK_CO_INT_FAILURE';

export const RETRY_PEOPLE_SYNC = 'teamwork_integration/RETRY_PEOPLE_SYNC';
export const RETRY_PEOPLE_SYNC_SUCCESS =
  'teamwork_integration/RETRY_PEOPLE_SYNC_SUCCESS';
export const RETRY_PEOPLE_SYNC_FAILURE =
  'teamwork_integration/RETRY_PEOPLE_SYNC_FAILURE';
