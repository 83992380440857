import React, { MutableRefObject } from 'react';

import AccordionTable from '@float/ui/deprecated/AccordionTable/AccordionTable';
import {
  AccordionTableData,
  AccordionTableSortConfig,
} from '@float/ui/deprecated/AccordionTable/types';

import { AccordionTableList } from '../../components/AccordionTableList';

export type TimeoffTableProps = {
  wrapperRef: MutableRefObject<HTMLElement | null>;
  printMode: boolean;
  tableData: { timeoffs: AccordionTableData[] };
  isTableLoading: boolean;
  createSortConfig: (key: string) => AccordionTableSortConfig;
};

export function TimeoffTable(props: TimeoffTableProps) {
  const { wrapperRef, printMode, tableData, isTableLoading, createSortConfig } =
    props;

  const zoom = printMode ? 0.77 : 1;

  return (
    <AccordionTableList
      top={() => (
        <AccordionTable
          wrapperRef={wrapperRef}
          style={{ zoom }}
          data={tableData.timeoffs[1]}
          sortConfig={createSortConfig('tc-timeoffs')}
          noResultsMessage={
            !isTableLoading && 'No time offs scheduled in this date range'
          }
        />
      )}
      bottom={(ref) => (
        <AccordionTable
          ref={ref}
          wrapperRef={wrapperRef}
          style={{ marginTop: 30, zoom }}
          data={tableData.timeoffs[0]}
          sortConfig={createSortConfig('tc-holidays')}
          noResultsMessage={
            !isTableLoading && 'No holidays scheduled in this date range'
          }
        />
      )}
    />
  );
}
