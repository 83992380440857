import React, { forwardRef, HTMLAttributes } from 'react';
import cn from 'classnames';

import * as styles from './styles.css';

export type ListItemValueProps = HTMLAttributes<HTMLElement>;

const ListItemValue = forwardRef<HTMLDivElement, ListItemValueProps>(
  (props, ref) => {
    const { children, className } = props;

    return (
      <div ref={ref} className={cn(className, styles.value)}>
        {children}
      </div>
    );
  },
);

export { ListItemValue };
