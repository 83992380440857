import { isEmpty, sortBy } from 'lodash';

import { CurrentUser } from '@float/types/account';
import { Person } from '@float/types/person';

export function getProjectPeopleIdsFromTemplatePeopleIds(
  templatePeopleIds: number[],
  people: Record<number, Person>,
  currentUser: CurrentUser,
): number[] {
  if (isEmpty(templatePeopleIds)) return [];

  const activePeople = templatePeopleIds
    .map((id) => people[id])
    .filter((p) => {
      // Filter out inactive people
      if (!p || !p.active) return false;

      // Include all active people if the current user has no department filter
      if (!currentUser.department_filter_all?.length) return true;

      // Filter out people who are not in the current user's department filter
      return (
        p.department_id &&
        currentUser.department_filter_all.includes(p.department_id)
      );
    });

  // Sorting logic carried forward from old project modal
  const sortedPeople = sortBy(activePeople, (p) => p.name.toLowerCase());

  return sortedPeople.map((p) => p.people_id);
}
