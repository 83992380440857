import React from 'react';

import {
  Button,
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '@float/ui/deprecated';

import { REMOVE_MODE } from './constants';

const descriptions = {
  [REMOVE_MODE.INBOUND]:
    'Disconnecting your calendar from Float will remove any tasks on your Float schedule that are currently synced.',
  [REMOVE_MODE.OUTBOUND]:
    'Disconnecting your calendar from Float will remove any tasks on your calendar that are currently synced.',
  [REMOVE_MODE.BOTH]:
    'Disconnecting your calendar from Float will remove any tasks on your calendar or your Float schedule that are currently synced. The original calendar event(s) and/or Float task(s) will be retained.',
};

class IntegrationCalendarRemoveModal extends React.Component {
  state = {};
  close = () => {
    this.props.onClose();
  };

  remove = () => {
    this.props.onRemove(true);
  };

  render() {
    const { removeMode } = this.props;
    return (
      <Modal isOpen onClose={this.close}>
        <ModalHeader>
          <ModalTitle>Disconnect calendar</ModalTitle>
        </ModalHeader>

        <ModalBody>{descriptions[removeMode]}</ModalBody>
        <ModalActions>
          <Button onClick={() => this.remove()}>Disconnect</Button>
          <Button appearance="secondary" onClick={this.close}>
            Cancel
          </Button>
        </ModalActions>
      </Modal>
    );
  }
}

export default IntegrationCalendarRemoveModal;
