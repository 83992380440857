import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { WebAppGlobalState } from 'types';

import {
  cancelProTrial,
  startProTrial,
} from '@float/common/actions/companyPrefs';
import {
  DEFAULT_ENTERPRISE,
  PRO_MONTHLY,
  PRO_YEARLY,
  STARTER_MONTHLY,
  STARTER_YEARLY,
} from '@float/common/lib/pricing';
import { getUser } from '@float/common/selectors/currentUser';
import { AddOnStatus, CurrentUser } from '@float/types';
import { updateBillingPlan } from '@float/web/settingsV2/actions/account/billingInfo';

import withOnMount from '../../../decorators/withOnMount';
import { DowngradeModal as DowngradeProModal } from '../../Enterprise/DowngradeModal';
import { DisableModal } from '../TimeTrackingSection/DisableModal';
import { DowngradeModal } from '../TimeTrackingSection/DowngradeModal';
import { TrialModal } from '../TimeTrackingSection/TrialModal';
import UpgradeModal from '../TimeTrackingSection/UpgradeModal';
import { isPlanEnterprise, isPlanPro } from './helpers';

export type AccountFeaturesModalProps = {
  modalOpened: string | null;
  onClose: () => void;
  startProTrial: () => Promise<void>;
  cancelProTrial: () => Promise<void>;
  updateBillingPlan: (formData: FormData) => Promise<void>;
  user: CurrentUser;
  billingInfo: {
    is_trial: boolean;
    plan: {
      is_monthly?: boolean;
      plan_id: string;
      type?: string;
      [key: string]: unknown;
    };
  };
};

const AccountFeaturesModalComponent = ({
  modalOpened,
  user,
  onClose,
  updateBillingPlan,
  startProTrial,
  cancelProTrial,
  billingInfo,
}: AccountFeaturesModalProps) => {
  const { time_tracking: timeTracking, plus_pack: plusPack } = user;
  const hasTimeTracking = timeTracking > 0;
  const hasPlusPack = plusPack > 0;
  const hasBothAddons = hasTimeTracking && hasPlusPack;
  const {
    is_trial: isTrialPlan,
    plan: { is_monthly: isMonthly, plan_id: planId },
  } = billingInfo;
  const isEnterprisePlan = isPlanEnterprise(
    planId,
    !isTrialPlan,
    hasBothAddons,
    !isMonthly,
  );
  const isProPlan = isPlanPro(
    planId,
    !isTrialPlan,
    timeTracking,
    plusPack,
    !isMonthly,
  );

  const upgradingFrom = isProPlan ? 'pro' : 'starter';

  const handleUpdateToEnterprise = useCallback(() => {
    const formData = new FormData();
    formData.append('Company[time_tracking]', `${AddOnStatus.ENABLED}`);
    formData.append('Company[plus_pack]', `${AddOnStatus.ENABLED}`);

    formData.append('Company[plan_id]', DEFAULT_ENTERPRISE.plan_id);

    return updateBillingPlan(formData);
  }, [updateBillingPlan]);

  const handleUpdateToPro = useCallback(() => {
    const formData = new FormData();
    formData.append('Company[time_tracking]', `${AddOnStatus.ENABLED}`);
    formData.append('Company[plus_pack]', `${AddOnStatus.ENABLED}`);
    if (isMonthly) {
      formData.append('Company[plan_id]', PRO_MONTHLY.plan_id);
    } else {
      formData.append('Company[plan_id]', PRO_YEARLY.plan_id);
    }
    return updateBillingPlan(formData);
  }, [updateBillingPlan, isMonthly]);

  const handleUpdateToStarter = useCallback(() => {
    const formData = new FormData();
    formData.append('Company[time_tracking]', `${AddOnStatus.NOT_ENABLED}`);
    formData.append('Company[plus_pack]', `${AddOnStatus.NOT_ENABLED}`);
    if (isMonthly) {
      formData.append('Company[plan_id]', STARTER_MONTHLY.plan_id);
    } else {
      formData.append('Company[plan_id]', STARTER_YEARLY.plan_id);
    }
    return updateBillingPlan(formData);
  }, [updateBillingPlan, isMonthly]);

  return (
    <>
      {modalOpened === 'trial' && (
        <TrialModal user={user} close={onClose} onSubmit={startProTrial} />
      )}
      {modalOpened === 'disable' && (
        <DisableModal user={user} close={onClose} onSubmit={cancelProTrial} />
      )}
      {modalOpened === 'upgrade-pro' && (
        <UpgradeModal
          user={user}
          close={onClose}
          onSubmit={handleUpdateToPro}
          isMonthly={isMonthly}
          upgradeFrom={upgradingFrom}
          isEnterpriseUpgrade={false}
        />
      )}
      {modalOpened === 'trial-pro-paid' && (
        <UpgradeModal
          user={user}
          close={onClose}
          onSubmit={startProTrial}
          isMonthly={isMonthly}
          upgradeFrom={upgradingFrom}
          isEnterpriseUpgrade={false}
          isPaidTrial
        />
      )}
      {modalOpened === 'upgrade-enterprise' && (
        <UpgradeModal
          user={user}
          close={onClose}
          onSubmit={handleUpdateToEnterprise}
          isMonthly={isMonthly}
          upgradeFrom={upgradingFrom}
          isEnterpriseUpgrade
        />
      )}
      {modalOpened === 'downgrade-pro' && (
        <DowngradeProModal
          user={user}
          close={onClose}
          onSubmit={handleUpdateToPro}
          skipConfirm
        />
      )}
      {modalOpened === 'disable-paid-pro-trial' && (
        <DowngradeModal
          user={user}
          close={onClose}
          onSubmit={cancelProTrial}
          isEnterprisePlan={false}
          skipConfirm
        />
      )}
      {modalOpened === 'downgrade-starter' && (
        <DowngradeModal
          user={user}
          close={onClose}
          onSubmit={handleUpdateToStarter}
          isEnterprisePlan={isEnterprisePlan}
          skipConfirm
        />
      )}
    </>
  );
};

const mapStateToProps = (state: WebAppGlobalState) => ({
  user: getUser(state),
  billingInfo: state.settingsBillingInfo,
});

const mapDispatchToProps = {
  startProTrial,
  cancelProTrial,
  updateBillingPlan,
};

export const AccountFeaturesModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOnMount(AccountFeaturesModalComponent));
