import { makeRequest } from './makeRequest';

export const exampleData = {
  createExampleData() {
    return makeRequest<void, undefined, undefined>({
      resource: 'example-data',
      method: 'GET',
    });
  },
};
