import React, { Dispatch, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import { updateTimeTracking } from '@float/common/actions/companyPrefs';
import { upsertLockLoggedTimeConfig } from '@float/common/actions/lockLoggedTime';
import { usePrevious } from '@float/common/lib/hooks/usePrevious';
import { AllActions } from '@float/common/reducers';
import { ReduxState } from '@float/common/reducers/lib/types';
import { isTimeTrackingEnabled } from '@float/common/selectors/companyPrefs';
import {
  LockLoggedTimeFrequency,
  LockLoggedTimeSettings,
} from '@float/types/lockLoggedTime';
import useSnackbar from '@float/ui/deprecated/Snackbar/useSnackbar';

import { isPlanStarter } from '../Billing/TeamAccountFeaturesNew/helpers';
import Body from '../Body';
import { SUPPORT_ARTICLES } from '../constants';
import { PlanUpsellBanner } from '../PlanUpsellBanner';
import { AdminTimeTrackingControlCard } from './AdminTimeTrackingControlCard';
import { AutoTimeLoggingSettingsCard } from './AutoTimeLoggingSettingsCard';
import { LockTimeLoggingSettingsCard } from './LockTimeLoggingSettingsCard';
import { useGetAutoLogTimeSettings } from './useAutoLogTimeAPI';
import { useTimeTrackingController } from './useTimeTrackingController';

type WeekStartsOn = 0 | 1;

const TimeTrackingPlanUpsellBanner = () => (
  <PlanUpsellBanner
    header="Want to add time tracking?"
    subheader="Try our Pro plan now"
    canModifySubheader
    learnMoreUrl={SUPPORT_ARTICLES.timeTracking}
  />
);

type TimeTrackingProps = {
  frequency: LockLoggedTimeFrequency;
  lockLogTimeSettings: LockLoggedTimeSettings;
  upsertLockLoggedTimeConfig: (
    active: boolean,
    interval: number,
    frequency: LockLoggedTimeFrequency,
  ) => void;
  weekStartsOn: WeekStartsOn;
  isTimeTrackingEnabled: boolean;
  updateTimeTracking: ({ isEnabled }: { isEnabled: boolean }) => void;
  companyPrefs: ReduxState['companyPrefs'];
};

// @test-export
export const TimeTrackingInner = ({
  lockLogTimeSettings,
  upsertLockLoggedTimeConfig,
  weekStartsOn,
  isTimeTrackingEnabled,
  updateTimeTracking,
  companyPrefs,
}: TimeTrackingProps) => {
  const {
    data: initialAutoLogTimeSettings,
    mutate: mutateAutoLogTimeSettings,
    error: autoLogTimeSettingsError,
  } = useGetAutoLogTimeSettings();

  const initialAutoLogTimeSettingsError = usePrevious(autoLogTimeSettingsError);
  const isStarterPlan = isPlanStarter(
    companyPrefs.plan_id,
    companyPrefs.is_paid_plan,
    companyPrefs.time_tracking,
    companyPrefs.plus_pack,
  );

  const { showSnackbar } = useSnackbar();

  const {
    autoLogTimeSettings,
    handleAutoLogTimeActiveStateChange,
    handleAutoLogTimeFrequencyChange,
    handleAutoLogTimeIntervalChange,
    isAutoLogTimeSettingsUpdateEnabled,
    updateAutoLogTimeSettings,
    showError,
  } = useTimeTrackingController({
    initialAutoLogTimeSettings,
    lockLogTimeSettings,
    mutateAutoLogTimeSettings,
    showSnackbar,
  });

  // Top-level effect to surface errors once, and not on subsequent rerenders.
  useEffect(() => {
    const shouldDisplayGetError =
      autoLogTimeSettingsError &&
      autoLogTimeSettingsError.status !==
        initialAutoLogTimeSettingsError?.status;

    if (shouldDisplayGetError) {
      showError('Failed to fetch settings');
    }
  }, [autoLogTimeSettingsError, initialAutoLogTimeSettingsError, showError]);

  const toggleTimeTracking = useCallback(async () => {
    try {
      await updateTimeTracking({ isEnabled: !isTimeTrackingEnabled });

      showSnackbar(
        `Log time ${isTimeTrackingEnabled ? 'deactivated' : 'activated'}`,
      );
    } catch (error: unknown) {
      if (error instanceof Error && error.message) {
        showError(error.message);
      }
    }
  }, [isTimeTrackingEnabled, updateTimeTracking, showError, showSnackbar]);

  return (
    <Body
      header="Time tracking"
      subheader="Compare estimated vs. actual hours."
      planUpsellBanner={<TimeTrackingPlanUpsellBanner />}
    >
      <AdminTimeTrackingControlCard
        isDisabled={isStarterPlan || !companyPrefs.is_paid_plan}
        isTimeTrackingEnabled={isTimeTrackingEnabled}
        onTimeTrackingChange={toggleTimeTracking}
      />

      <AutoTimeLoggingSettingsCard
        frequency={autoLogTimeSettings?.frequency}
        interval={autoLogTimeSettings?.interval}
        initialInterval={initialAutoLogTimeSettings?.interval ?? 1}
        isActive={autoLogTimeSettings?.isActive}
        isDisabled={isStarterPlan}
        isUpdateEnabled={isAutoLogTimeSettingsUpdateEnabled}
        onActiveStateChange={handleAutoLogTimeActiveStateChange}
        onFrequencyChange={handleAutoLogTimeFrequencyChange}
        onIntervalChange={handleAutoLogTimeIntervalChange}
        onUpdateClicked={updateAutoLogTimeSettings}
      />

      <LockTimeLoggingSettingsCard
        initialActive={lockLogTimeSettings.isActive}
        isDisabled={isStarterPlan}
        initialInterval={lockLogTimeSettings.interval}
        initialFrequency={lockLogTimeSettings.frequency}
        weekStartsOn={weekStartsOn}
        updateConfig={upsertLockLoggedTimeConfig}
      />
    </Body>
  );
};

const mapStateToProps = (state: ReduxState) => {
  return {
    lockLogTimeSettings: {
      isActive: !!state.lockLoggedTime?.config?.active,
      interval: state.lockLoggedTime?.config?.interval ?? 1,
      frequency:
        state.lockLoggedTime?.config?.frequency ??
        LockLoggedTimeFrequency.month,
    },
    weekStartsOn: Number(state.companyPrefs.start_work_week) as WeekStartsOn,
    isTimeTrackingEnabled: isTimeTrackingEnabled(state),
    companyPrefs: state.companyPrefs,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AllActions>) => ({
  upsertLockLoggedTimeConfig: (
    active: boolean,
    interval: number,
    frequency: LockLoggedTimeFrequency,
  ) => dispatch(upsertLockLoggedTimeConfig(active, interval, frequency)),
  updateTimeTracking: ({ isEnabled }: { isEnabled: boolean }) =>
    dispatch(updateTimeTracking({ isEnabled })),
});

export const TimeTracking = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TimeTrackingInner);
