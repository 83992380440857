import { css } from 'styled-components';

const antialias = css`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
`;

const small = css`
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0;
`;

const medium = css`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.3px;
`;

const large = css`
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  letter-spacing: -0.3px;
`;

const xl = css`
  font-weight: 500;
  font-size: 24px;
  line-height: 34px; // using 28px here (like in zeplin) cuts off the bottom curves in some letters, like "g"
  letter-spacing: -0.3px;
`;

export default {
  antialias,
  text: {
    small,
    medium,
    large,
    xl,
  },
};
