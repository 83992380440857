import React, { ReactNode } from 'react';
import styled from 'styled-components';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import { IconClose } from '@float/ui/deprecated/Earhart/Icons';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

const Root = styled.div`
  position: absolute;

  width: 100%;
  height: 100%;

  border-radius: 5px;

  padding: 1px;

  background-color: #2e5fe8;

  box-shadow: 0 0 0 1px #2e5fe8;
  z-index: 1;
`;

const Content = styled.div`
  ${Typography.TextS.M500};

  position: absolute;

  top: 3px;
  left: 6px;

  color: ${Colors.FIN.Lt.Emphasis.White};

  > a {
    color: ${Colors.FIN.Lt.Emphasis.White};
    text-decoration: underline;
    font-weight: 600;
  }
`;

const Dismiss = styled.button`
  position: absolute;

  top: 0;
  right: 3px;

  --svg-icon-color: ${Colors.FIN.Lt.Emphasis.White};
`;

type InputAlertProps = {
  className?: string;
  children: ReactNode;
  onClose?: () => void;
};

const InputAlert = ({ className, children, onClose }: InputAlertProps) => {
  return (
    <Root className={className}>
      <Content>{children}</Content>
      {onClose && (
        <Dismiss
          onClick={(evt) => {
            evt.preventDefault();
            onClose();
          }}
        >
          <IconClose />
        </Dismiss>
      )}
    </Root>
  );
};

export default InputAlert;
