import { updateFilter as updateFilterAction } from '@float/common/actions/search';
import { useAppDispatchDecorator } from '@float/common/store';

export const useUpdateProjectNameFilter = () => {
  const updateFilter = useAppDispatchDecorator(updateFilterAction);
  const updateProjectNameFilter = (projectName: string) => {
    updateFilter('project', projectName);
  };
  return updateProjectNameFilter;
};
