import React, { forwardRef } from 'react';
import cs from 'classnames';

import Spinner from '@float/ui/deprecated/Loader/Spinner';

import * as styles from './InsightBadge.css';

export type InsightBadgeProps = {
  state: 'neutral' | 'warning' | 'critical' | 'info';
  value: React.ReactNode;
  unit?: string;
  isLoading?: boolean;
  onClick?: () => void;
};

export const InsightBadge = forwardRef(
  (props: InsightBadgeProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const { state, value, unit, isLoading, onClick, ...rest } = props;

    const isInteractive = Boolean(onClick);

    return (
      <button
        className={cs(
          styles.variants[state],
          isInteractive ? styles.clickable : styles.notClickable,
        )}
        onClick={onClick}
        ref={ref}
        disabled={!isInteractive}
        {...rest}
      >
        {isLoading && (
          <Spinner width="16px" height="16px" className={styles.spinner} />
        )}

        <div className={cs(styles.body, isLoading && styles.bodyLoading)}>
          {value}
          {unit && <span className={styles.unit}>{unit}</span>}
        </div>
      </button>
    );
  },
);
