import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getActivePmIntegration, shouldShowPmSidebar } from 'selectors';

import { togglePmSidebar } from '@float/web/pmSidebar/actions';

import NavIcon from '../components/Nav/NavIcon';
import IconPmIntegration from './icons/icon-pm-integration';
import { integrationTypes } from './integrationTypes';

let clicked = false;

function PmSidebarNavItem({
  location,
  title,
  visible,
  shouldShowPmSidebar,
  toggle,
}) {
  useEffect(() => {
    if (!['/', '/log-time'].includes(location.pathname) && visible) {
      if (!clicked) {
        toggle();
      }
    }
    clicked = false;
  }, [location, visible, toggle]);

  if (!shouldShowPmSidebar) {
    return null;
  }

  const onToggleClick = () => {
    clicked = true;
    toggle();
  };

  return (
    <NavIcon title={title} className="no-hover" onClick={onToggleClick}>
      <IconPmIntegration className={visible ? 'active' : undefined} />
    </NavIcon>
  );
}

const mapStateToProps = (state) => {
  const { type } = getActivePmIntegration(state);
  const { title } = integrationTypes[type] || {};

  return {
    title,
    visible: state.pmSidebar.visible,
    shouldShowPmSidebar: shouldShowPmSidebar(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggle: () => dispatch(togglePmSidebar()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PmSidebarNavItem);
