import React from 'react';

import { Tooltip } from '@float/ui/components/Tooltip';

import { CycleVariant, StandardCycleConfig } from '../Cycles.types';
import {
  getCycleTitleIconAndActiveState,
  getTitleDimensions,
} from './CycleTitle.helpers';

import * as styles from '../Cycles.css';

// @test-export
export const ConditionalCycleTitleTooltip: React.FC<{
  children: React.ReactNode;
  shouldRenderTooltip: boolean;
  content: string | number;
}> = ({ children, shouldRenderTooltip: shouldRender, content }) => {
  if (!shouldRender) {
    return children;
  }

  return (
    <Tooltip content={content} placement={'bottom'} distance={-2}>
      {children}
    </Tooltip>
  );
};

// @test-export
export const CycleIconAndTitle: React.FC<{
  name: string | number;
  showTitle?: boolean;
  variant: Omit<CycleVariant, CycleVariant.Cooldown>;
}> = ({ name, showTitle = true, variant }) => {
  const { CycleIcon, activeVariant } = getCycleTitleIconAndActiveState(variant);

  return (
    <div className={styles.cycleTitle[activeVariant]}>
      <CycleIcon />
      {showTitle && <span className={styles.cycleTitleText}>{name}</span>}
    </div>
  );
};

// Used to space the Cycle Titles relative to each other. Unfortunately
// we can't use `absolute` positioning as it breaks the sticky styles.
const CycleTitleSpacer = ({
  spaceBetweenCycles,
}: {
  spaceBetweenCycles: number;
}) => (
  <div
    style={{
      minWidth: spaceBetweenCycles,
      maxWidth: spaceBetweenCycles,
    }}
    className={styles.cycleTitleSpacer}
  />
);

type StickyCycleTitleProps = {
  cycleConfig: StandardCycleConfig;
  dayWidth: number;
  leftStickyBoundary: number;
};

export const StickyCycleTitle: React.FC<StickyCycleTitleProps> = ({
  cycleConfig,
  dayWidth,
  leftStickyBoundary,
}) => {
  const {
    compensatedLeftStickyBoundary,
    spaceBetweenCycles,
    widthOfCycle,
    cycleIsLongEnoughToRenderTitle,
    cycleIsLongEnoughToRenderIcon,
  } = getTitleDimensions(cycleConfig, dayWidth, leftStickyBoundary);

  return (
    <>
      {spaceBetweenCycles > 0 && (
        <CycleTitleSpacer spaceBetweenCycles={spaceBetweenCycles} />
      )}
      {widthOfCycle > 0 && (
        // If the cycle is too small to render any part of the title
        // place the tooltip on the cycle itself
        <ConditionalCycleTitleTooltip
          shouldRenderTooltip={!cycleIsLongEnoughToRenderIcon}
          content={cycleConfig.name}
        >
          {/* We render this title wrapper even if we're not rendering the 
              title contents, as the cycle still takes up horizontal space 
              which is used for positioning the proceeding Titles */}
          <div
            style={{
              minWidth: widthOfCycle,
              maxWidth: widthOfCycle,
            }}
          >
            {cycleIsLongEnoughToRenderIcon && (
              <ConditionalCycleTitleTooltip
                shouldRenderTooltip
                content={cycleConfig.name}
              >
                <div
                  style={{
                    left: compensatedLeftStickyBoundary,
                    marginRight: dayWidth,
                    marginLeft: dayWidth,
                  }}
                  className={styles.cycleTitleStickyWrapper}
                >
                  <CycleIconAndTitle
                    name={cycleConfig.name}
                    variant={cycleConfig.variant}
                    showTitle={cycleIsLongEnoughToRenderTitle}
                  />
                </div>
              </ConditionalCycleTitleTooltip>
            )}
          </div>
        </ConditionalCycleTitleTooltip>
      )}
    </>
  );
};
