import React from 'react';
import createClass from 'create-react-class';

import DateLockIndicator from '../../DateLockIndicator';
import DateRangePickerBemMixin from '../utils/DateRangePickerBemMixin';
import lightenDarkenColor from '../utils/lightenDarkenColor';
import PureRenderMixin from '../utils/PureRenderMixin';
import CalendarDatePeriod from './CalendarDatePeriod';
import CalendarHighlight from './CalendarHighlight';
import CalendarSelection from './CalendarSelection';

const CalendarDate = createClass({
  mixins: [DateRangePickerBemMixin, PureRenderMixin],
  displayName: 'CalendarDate',

  getInitialState() {
    return {
      mouseDown: false,
    };
  },

  componentWillUnmount() {
    this.isUnmounted = true;
    document.removeEventListener('mouseup', this.mouseUp);
  },

  mouseUp() {
    this.props.onSelectDate(this.props.date);

    if (this.isUnmounted) {
      return;
    }

    if (this.state.mouseDown) {
      this.setState({
        mouseDown: false,
      });
    }

    document.removeEventListener('mouseup', this.mouseUp);
  },

  mouseDown() {
    this.setState({
      mouseDown: true,
    });

    document.addEventListener('mouseup', this.mouseUp);
  },

  mouseEnter() {
    this.props.onHighlightDate(this.props.date);
  },

  mouseLeave() {
    if (this.state.mouseDown) {
      this.props.onSelectDate(this.props.date);

      this.setState({
        mouseDown: false,
      });
    }
    this.props.onUnHighlightDate(this.props.date);
  },

  getBemModifiers() {
    const { date, firstOfMonth, isToday: today } = this.props;

    let otherMonth = false;
    let weekend = false;

    if (date.month() !== firstOfMonth.month()) {
      otherMonth = true;
    }

    if (date.day() === 0 || date.day() === 6) {
      weekend = true;
    }

    return { today, weekend, otherMonth };
  },

  getBemStates() {
    const {
      isSelectedDate,
      isInSelectedRange,
      isInHighlightedRange,
      isHighlightedDate: highlighted,
      isDisabled: disabled,
      isInLockPeriod,
      hasLockPeriodAccess,
    } = this.props;

    const selected =
      isSelectedDate || isInSelectedRange || isInHighlightedRange;

    const locked = isInLockPeriod && !disabled;
    const unselectable = isInLockPeriod && !hasLockPeriodAccess;

    return { disabled, highlighted, selected, locked, unselectable };
  },

  render() {
    const {
      date,
      dateRangesForDate,
      isSelectedDate,
      isSelectedRangeStart,
      isSelectedRangeEnd,
      isInSelectedRange,
      isHighlightedDate,
      isHighlightedRangeStart,
      isHighlightedRangeEnd,
      isInHighlightedRange,
    } = this.props;

    const bemModifiers = this.getBemModifiers();
    const bemStates = this.getBemStates();
    const pending = isInHighlightedRange;

    let color;
    let amColor;
    let pmColor;
    const states = dateRangesForDate(date);
    const numStates = states.count();
    let cellStyle = {};
    let style = {};

    let highlightModifier;
    let selectionModifier;

    if (
      isSelectedDate ||
      (isSelectedRangeStart && isSelectedRangeEnd) ||
      (isHighlightedRangeStart && isHighlightedRangeEnd)
    ) {
      selectionModifier = 'single';
    } else if (isSelectedRangeStart || isHighlightedRangeStart) {
      selectionModifier = 'start';
    } else if (isSelectedRangeEnd || isHighlightedRangeEnd) {
      selectionModifier = 'end';
    } else if (isInSelectedRange || isInHighlightedRange) {
      selectionModifier = 'segment';
    }

    if (isHighlightedDate) {
      highlightModifier = 'single';
    }

    if (numStates === 1) {
      // If there's only one state, it means we're not at a boundary
      color = states.getIn([0, 'color']);

      if (color) {
        style = {
          backgroundColor: color,
        };
        cellStyle = {
          borderLeftColor: lightenDarkenColor(color, -10),
          borderRightColor: lightenDarkenColor(color, -10),
        };
      }
    } else {
      amColor = states.getIn([0, 'color']);
      pmColor = states.getIn([1, 'color']);

      if (amColor) {
        cellStyle.borderLeftColor = lightenDarkenColor(amColor, -10);
      }

      if (pmColor) {
        cellStyle.borderRightColor = lightenDarkenColor(pmColor, -10);
      }
    }

    const currentDate = date.format('YYYY-MM-DD');
    // In the event there is a custom selection component, the text needs to be lighter.
    // Text doesn't sit inside the selection component.
    const textColor =
      this.props.customSelectionComponent &&
      ['single', 'start', 'end'].includes(selectionModifier)
        ? '#fff'
        : '';
    const Selection = textColor
      ? this.props.customSelectionComponent
      : CalendarSelection;

    return (
      <td
        className={this.cx({
          element: 'Date',
          modifiers: bemModifiers,
          states: bemStates,
        })}
        style={cellStyle}
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseLeave}
        onMouseDown={this.mouseDown}
        data-testid={`calendar-date-${currentDate}`}
      >
        <div className="hover-bg" />
        {numStates > 1 && (
          <div className={this.cx({ element: 'HalfDateStates' })}>
            <CalendarDatePeriod period="am" color={amColor} />
            <CalendarDatePeriod period="pm" color={pmColor} />
          </div>
        )}
        {numStates === 1 && (
          <div
            className={this.cx({ element: 'FullDateStates' })}
            style={style}
          />
        )}
        <span
          className={this.cx({ element: 'DateLabel' })}
          style={{ color: textColor }}
        >
          {date.format('D')}
        </span>
        {selectionModifier ? (
          <Selection modifier={selectionModifier} pending={pending} />
        ) : null}
        {highlightModifier ? (
          <CalendarHighlight modifier={highlightModifier} />
        ) : null}
        <DateLockIndicator
          currentDate={currentDate}
          lockPeriodLatest={this.props.lockPeriod?.latest}
          lockPeriodNext={this.props.lockPeriod?.next}
        />
      </td>
    );
  },
});

export default CalendarDate;
