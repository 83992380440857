import React, { useRef, useState } from 'react';
import { Trans } from '@lingui/macro';

import { trackEvent } from '@float/common/lib/analytics';
import { Button } from '@float/ui/deprecated';
import { IconClose } from '@float/ui/deprecated/Earhart/Icons';

import { WelcomeProps } from '.';
import { WelcomeVideo, WelcomeVideoRef } from './components/WelcomeVideo';

import * as styles from './styles.css';

export const WelcomeWithVideo = (props: WelcomeProps) => {
  const { goNext, skipTour } = props;

  const videoRef = useRef<WelcomeVideoRef>(null);
  const [, setIsVideoPlaying] = useState(false);

  const onClickStartTour = () => {
    if (goNext) goNext();
  };

  const onClickClose = () => {
    if (skipTour) skipTour();
  };

  const onClickPlay = () => {
    trackEvent('onboarding_product_tour_welcome_video_start_play');

    videoRef.current?.play();
    setIsVideoPlaying(true);
  };

  const onVideoEnded = () => {
    trackEvent('onboarding_product_tour_welcome_video_ended');
  };

  return (
    <div
      className={styles.welcomeWrapper}
      data-transition-enter-timeout="0.85"
      data-transition-exit-timeout="0.35"
    >
      <IconClose className={styles.iconClose} onClick={onClickClose} />

      <h1 className={styles.h1}>
        <Trans>Welcome to Float</Trans>
      </h1>

      <p className={styles.p}>
        <Trans>
          Before diving in, take a short tour to discover key functionality.
        </Trans>
      </p>

      <WelcomeVideo
        ref={videoRef}
        className={styles.welcomeVideo}
        onClickPlay={onClickPlay}
        onVideoEnded={onVideoEnded}
      />

      <Button appearance="primary" onClick={onClickStartTour}>
        <Trans>Skip video & start tour</Trans>
      </Button>
    </div>
  );
};
