import React from 'react';

import { FilterCategory } from '@float/common/search/types';

import { getCategoryConfig } from '../../../../helpers/getCategoryConfig';

import * as styles from './styles.css';

type ItemCategoryProps = {
  type: FilterCategory;
  onClick?: (value: FilterCategory) => void;
  onMouseEnter?: () => void;
};
export const ItemCategory = (props: ItemCategoryProps) => {
  const { type, onClick, onMouseEnter } = props;

  const category = getCategoryConfig(type);

  if (category) {
    const { icon: Icon, value } = category;

    const onClickHandler = () => {
      if (onClick) onClick(value as FilterCategory);
    };

    return (
      <button
        className={styles.itemCategory}
        onClick={onClickHandler}
        onMouseEnter={onMouseEnter}
        disabled={Boolean(!onClick)}
      >
        {Icon && <Icon size={20} />}

        <span className={styles.label}>
          {category.shortLabel || category.label}
        </span>
      </button>
    );
  }

  return null;
};
