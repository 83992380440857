import React, {
  createContext,
  MutableRefObject,
  ReactNode,
  useEffect,
  useRef,
} from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import styled, { keyframes } from 'styled-components';

import { useSafeContext } from '@float/libs/hooks/useSafeContext';
import colors from '@float/ui/deprecated/Theme/colors';

const DEFAULT_DELAY = 400;

export type TextTooltipProps = {
  className?: string;
  content?: ReactNode;
  children: ReactNode;
  placement?: TooltipPrimitive.TooltipContentProps['side'];
  distance?: TooltipPrimitive.TooltipContentProps['sideOffset'];
  collisionBoundary?: TooltipPrimitive.TooltipContentProps['collisionBoundary'];
  maxWidth?: number;
  zIndex?: number;
  delay?: number;
  open?: boolean;
  onOpenChange?: TooltipPrimitive.TooltipProps['onOpenChange'];
  textAlign?: React.CSSProperties['textAlign'];
  arrowContent?: ReactNode;
};

const ActiveElementContext =
  createContext<MutableRefObject<EventTarget | null> | null>(null);

/**
 * @deprecated use `fin/components/Tooltip` instead
 */
export const TooltipProvider = ({ children }: { children: ReactNode }) => {
  const prevActiveElement = useRef<EventTarget | null>(null);

  useEffect(() => {
    const handleFocus = (evt: Event) => {
      const { target } = evt;

      prevActiveElement.current = target;
    };

    window.addEventListener('focusin', handleFocus);

    return () => {
      window.removeEventListener('focusin', handleFocus);
    };
  }, []);

  return (
    <TooltipPrimitive.Provider>
      <ActiveElementContext.Provider value={prevActiveElement}>
        {children}
      </ActiveElementContext.Provider>
    </TooltipPrimitive.Provider>
  );
};

/**
 * @deprecated use `fin/components/Tooltip` instead
 */
export const TextTooltip = ({
  className,
  arrowContent,
  children,
  collisionBoundary,
  content,
  delay = DEFAULT_DELAY,
  distance,
  maxWidth,
  onOpenChange,
  open,
  placement,
  textAlign,
  zIndex,
}: TextTooltipProps) => {
  const prevActiveElement = useSafeContext(
    ActiveElementContext,
    'ActiveElementContext',
  );

  return (
    <TooltipPrimitive.Root
      open={open}
      onOpenChange={onOpenChange}
      delayDuration={delay}
    >
      <TooltipPrimitive.Trigger
        asChild
        onFocus={(evt: React.FocusEvent) => {
          // Blocks the tooltip open during the page visibility change
          // based on the assumption that we want to show the tooltip only when the focus
          // is triggered on a new element
          // https://github.com/radix-ui/primitives/issues/1800
          if (prevActiveElement.current === evt.target) {
            evt.preventDefault();
          }
        }}
      >
        {children}
      </TooltipPrimitive.Trigger>
      <TooltipPrimitive.Portal>
        <TooltipContent
          className={className}
          side={placement}
          sideOffset={distance}
          style={{
            maxWidth: maxWidth,
            zIndex: zIndex,
            textAlign: textAlign,
          }}
          collisionBoundary={collisionBoundary}
        >
          {content}
          {arrowContent ?? <TooltipArrow className={className} />}
        </TooltipContent>
      </TooltipPrimitive.Portal>
    </TooltipPrimitive.Root>
  );
};

const fade = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const TooltipContent = styled(TooltipPrimitive.Content)`
  background-color: white;
  text-align: center;
  color: ${colors.charcoalGrey};
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  outline: 0;
  filter: drop-shadow(0px 5px 10px rgba(110, 110, 113, 0.3));
  padding: 9px 14px;

  animation-name: ${fade};
  animation-duration: 275ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  user-select: none;
  will-change: transform, opacity;
  z-index: 9999;

  &.hover {
    background-color: #333;
    color: #fff;
    font-size: 12px;
    filter: drop-shadow(0 5px 20px rgba(0, 0, 0, 0.1));
    padding: 6px 9px;
  }

  &.hint {
    background-color: ${colors.emphasisHigh};
    border-radius: 6px;
    box-shadow: none;
    padding: 10px;
    font-size: 14px;
    line-height: 14px;
    color: #fff;
  }

  &.clear {
    background-color: transparent;
  }

  &.insight {
    padding: 4px 10px;
  }

  &.interactive {
    pointer-events: all;
  }

  &.task-chain {
    border-radius: 10px;
    filter: drop-shadow(0px 6px 30px rgba(0, 0, 0, 0.12));
    padding: 16px;
    min-width: 200px;
    text-align: left;
  }
`;

const TooltipArrow = styled(TooltipPrimitive.Arrow)`
  fill: white;

  &.hover {
    fill: #333;
  }

  &.hint {
    fill: ${colors.emphasisHigh};
  }

  &.clear {
    fill: transparent;
  }
`;
