import { Project, ProjectOption } from '@float/types';

export const getProjectOption = (proj: Project): ProjectOption => ({
  label: proj.project_name,
  value: `${proj.project_id}`,
  client_name: proj.client_name,
  selectedLabel: proj.client_id
    ? `${proj.client_name} / ${proj.project_name}`
    : proj.project_name,
  color: proj.color,
});
