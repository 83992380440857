import { fastDictionary } from '@float/common/lib/fast-object-spread';
import { config } from '@float/libs/config';
import { sumOperation } from '@float/libs/utils/floats';
import {
  CellItem,
  LoggedTime,
  LoggedTimeCell,
  TaskReference,
} from '@float/types';

import { getEntityMeta } from '../entityMeta';
import { CellRenderArgs } from '../types';
import { isInLockPeriod } from './isInLockPeriod';

const isRemovedLoggedTime = (lt: LoggedTime | TaskReference) => {
  return lt.hours === 0;
};

export function addLoggedTime(args: CellRenderArgs) {
  const {
    cellKey,
    cells,
    firstCellDay,
    fns,
    lastCellDay,
    loggedTime = [],
    minItemHeightHours,
    props,
    timersList,
  } = args;

  const cell = args.cell as LoggedTimeCell;

  const { dates, lockPeriodDates, maps } = props;

  const { colIdx, rowId } = cell;
  const { isLoggedTimeWorkDay, getHorizontalDimensions } = fns;
  const { task: tasksMap } = maps;

  const timersByTask: Record<string, CellItem<'loggedTime'>> = fastDictionary();

  loggedTime.forEach((lt) => {
    const { x, w } = getHorizontalDimensions(lt, firstCellDay, lastCellDay);

    const inLock = isInLockPeriod(x, lockPeriodDates, dates, colIdx);
    lt.isInLockPeriod = inLock;

    const hasTask = lt.task_id && tasksMap[lt.task_id];

    const hasActiveTimer = Boolean(
      timersList?.some((timer) => {
        // We are showing active timers for the ones with association and each timer for group logic.
        return (
          timer.active &&
          timer.external_meta?.id === lt.logged_time_id &&
          timer.external_meta.account_id === cells._helperData.user.account_id
        );
      }),
    );

    const isRemoved = isRemovedLoggedTime(lt);
    const shouldShow = lt.logged_time_id ? hasActiveTimer : hasTask;

    if (isRemoved && !shouldShow) return;

    const dayTaskKey = `${lt.logged_time_id ?? lt.task_id ?? ''}-${x}-${
      lt.people_id
    }`;
    if (timersByTask[dayTaskKey]) {
      return;
    }

    const cellItem: CellItem<'loggedTime'> = {
      cellKey,
      rowId,
      key: `${cellKey}:hours:${lt.logged_time_id}`,
      type: 'loggedTime',
      isTimer: true,
      entity: lt,
      entityId: lt.logged_time_id,
      isStart: true,
      isEnd: true,
      isPlaceholder: getEntityMeta(lt, 'isPlaceholder'),
      date: dates.fromNum(firstCellDay + x),
      x,
      w,
      h: config.fixedHourHeight ?? Math.max(lt.hours, minItemHeightHours!),
      isInLockPeriod: inLock,
      hasActiveTimer,
    };

    if (w > 0) {
      timersByTask[dayTaskKey] = cellItem;
      cell.dayHours[x] = sumOperation(cell.dayHours[x], lt.hours);
      (cell as LoggedTimeCell).loggedHours[x] += lt.hours;

      if (!isLoggedTimeWorkDay(cells, rowId, dates.fromNum(firstCellDay + x))) {
        if (!cell.items.some((i) => i.type === 'oneOff' && i.x === x)) {
          cell.items.push({
            cellKey,
            key: `${cellKey}:oneOff:${x}`,
            type: 'oneOff',
            entity: {},
            x,
            w,
          } as CellItem<'oneOff'>);
        }
      }
    }
  });

  cell.items.push(...Object.values(timersByTask));
}
