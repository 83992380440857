import React, { useCallback } from 'react';
import { t, Trans } from '@lingui/macro';
import { DialogTitle } from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

import { updateUserPref } from '@float/common/actions/currentUser';
import {
  useAppDispatchStrict,
  useAppSelectorStrict,
} from '@float/common/store';
import { ProjectStatus } from '@float/types/project';
import { Checkbox } from '@float/ui/components/Checkbox';
import { Modal } from '@float/ui/primitives/Modal';
import { ModalDescription } from '@float/ui/primitives/Modal/components/ModalDescription';
import { ScrollArea } from '@float/ui/primitives/ScrollArea';
import { ManageTemplatesList } from '@float/web/manage/projects-v2/templates/ManageTemplatesList';
import { IWithModal, withModal } from '@float/web/modalManager/withModal';
import { getPrefs } from '@float/web/selectors';
import { showProjectSidePanel } from '@float/web/sidePanel/actions';

import { NewProjectCards } from './NewProjectCards';

import * as styles from './styles.css';

const MODAL_SIZE = {
  WIDTH: 910,
  HEIGHT: 550,
};

const ModalComponent = (props: IWithModal<void>) => {
  const { manageModal, modalProps } = props;

  const dispatch = useAppDispatchStrict();
  const prefs = useAppSelectorStrict(getPrefs);

  const handleHide = useCallback(() => {
    manageModal({
      visible: false,
      modalType: 'ModalProjectFromTemplate',
    });
  }, [manageModal]);

  const handleCreateProject = useCallback(
    (status: ProjectStatus) => {
      handleHide();

      // @ts-expect-error showProjectSidePanel is still using AppDispatch
      dispatch(showProjectSidePanel({ status }));
    },
    [handleHide, dispatch],
  );

  const toggleStartFromScratch = useCallback(async () => {
    dispatch(
      updateUserPref('project_from_scratch', !prefs.project_from_scratch),
    );
  }, [prefs.project_from_scratch, dispatch]);

  return (
    <Modal.Root
      appearance="custom"
      width={MODAL_SIZE.WIDTH}
      height={MODAL_SIZE.HEIGHT}
      {...modalProps}
    >
      <VisuallyHidden>
        <DialogTitle>
          <Trans>Create project</Trans>
        </DialogTitle>
      </VisuallyHidden>
      <ModalDescription hidden>
        <Trans>Create a new project</Trans>
      </ModalDescription>
      <div className={styles.wrapper}>
        <div className={styles.wrapperPart({ side: 'left' })}>
          <h1 className={styles.title}>
            <Trans>New blank project</Trans>
          </h1>
          <h4 className={styles.subTitle}>
            <Trans>Select project status</Trans>
          </h4>

          <NewProjectCards handleCreateProject={handleCreateProject} />

          <div className={styles.checkboxWrapper}>
            <Checkbox
              label={t`Always start from a confirmed project`}
              checked={prefs.project_from_scratch}
              onChange={toggleStartFromScratch}
              className={styles.checkbox}
              size="small"
            />
          </div>
        </div>

        <div className={styles.wrapperPart({ side: 'right' })}>
          <ScrollArea.Root>
            <ScrollArea.Content className={styles.scrollAreaContent}>
              <h1 className={styles.title}>
                <Trans>Use a template</Trans>
              </h1>
              <h4 className={styles.subTitle}>
                <Trans>Select template</Trans>
              </h4>
              <ManageTemplatesList
                isColumn
                skipEdit
                skipNew
                hideParent={handleHide}
              />
            </ScrollArea.Content>
          </ScrollArea.Root>
        </div>
      </div>
    </Modal.Root>
  );
};

export const ModalProjectFromTemplate = withModal({
  Comp: ModalComponent,
  modalType: 'ModalProjectFromTemplate',
});
