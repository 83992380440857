import React, { useMemo } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { getActivePmIntegration, getIsPmSidebarLoaded } from 'selectors';

import { togglePmSidebar } from '@float/web/pmSidebar/actions';

import PmSidebarItemDragTip from '../../components/legacyOnboarding/InAppNotifications/PmSidebarItemDragTip';
import PmSidebar from './PmSidebar';
import { SidebarContext } from './PmSidebar.context';
import * as styled from './styles';

function PmSidebarWrapper({
  type,
  visible,
  isLoaded,
  portal = true,
  toggle,
  actions,
}) {
  const contextValue = useMemo(() => ({ type, actions }), [type, actions]);

  const content = (
    <styled.Sidebar visible={visible}>
      {visible && (
        <SidebarContext.Provider value={contextValue}>
          <PmSidebar onClose={toggle} />
          {isLoaded && <PmSidebarItemDragTip />}
        </SidebarContext.Provider>
      )}
    </styled.Sidebar>
  );

  return portal ? createPortal(content, document.body) : content;
}

const mapStateToProps = (state) => {
  const { type } = getActivePmIntegration(state);
  return {
    type,
    visible: state.pmSidebar.visible,
    isLoaded: getIsPmSidebarLoaded(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggle: () => dispatch(togglePmSidebar()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PmSidebarWrapper);
