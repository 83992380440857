import { splitUsingBr } from './utils';

export const NOTES_EXCERPT_LENGTH = 250;

// Checking if the length of the current 'notes' value is NOTES_EXCERPT_LENGTH
// to see if it's an excerpt is a fragile logic, since that might be
// the actual length of the notes value.
// Ideally this should be done via another property, part of the people
// object, which we use to know if there's more to be fetched or not.
// But for now, I am keeping this logic (which is borrowed from the Task notes):
export const isExcerpt = (notes: string) =>
  Boolean(notes && notes.length === NOTES_EXCERPT_LENGTH);

export {
  toDbFormat,
  toUiFormat,
} from '@floatschedule/js-common-npm/dist/lib/notes';

export function getNotesExcerpt({
  notes,
  max = NOTES_EXCERPT_LENGTH,
}: {
  notes: string;
  max: number;
}) {
  notes = notes || '';

  const trimmedNotes =
    notes.length >= max ? `${notes.substr(0, max)}...` : notes;

  return trimmedNotes;
}

export function getDisplayNotesExcerpt({
  notes,
  max = NOTES_EXCERPT_LENGTH,
}: {
  notes: string;
  max?: number;
}) {
  const notesExcerpt = getNotesExcerpt({ notes, max });
  return splitUsingBr(notesExcerpt);
}
