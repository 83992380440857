import React, { StrictMode } from 'react';
import { t, Trans } from '@lingui/macro';

import { ConfirmModal } from 'components/modals/ConfirmModal';

export type ModalConfirmDeleterRoleProps = {
  roleName: string;
  onCancel: () => void;
  onConfirm: () => void;
};

export const ModalConfirmDeleterRole = (
  props: ModalConfirmDeleterRoleProps,
) => {
  const { roleName, onConfirm, onCancel } = props;

  return (
    <StrictMode>
      <ConfirmModal
        title={t`Delete Role`}
        message={
          <p>
            <Trans>
              Delete Role "<strong>{roleName}</strong>"?
            </Trans>
          </p>
        }
        confirmLabel={t`Delete`}
        cancelLabel={t`Cancel`}
        confirmAppearance="danger"
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    </StrictMode>
  );
};
