export default [
  '007bff',
  '14bfff',
  '75c997',
  'ff6d00',
  'dc164d',
  'ff28ce',
  'bd00d2',
  '8129ff',
];
