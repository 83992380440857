import styled, { css } from 'styled-components';

import * as Animation from '@float/ui/deprecated/Earhart/Animation';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

const {
  Settings: { Duration, Easing },
} = Animation;

export const StyledItem = styled.div`
  --list-item-label-color: var(--list-item-default-label-color);
  --list-item-bg-color: var(--list-item-default-bg-color);

  --height: 40;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  height: calc(var(--height) * 1px);

  padding-left: 10px;
  padding-right: 10px;

  > * + * {
    margin-left: 10px;
  }

  &:after {
    content: '';

    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border-radius: 6px;

    background-color: var(--list-item-bg-color);

    transition-property: transform, background-color, opacity;
    transition-duration: ${Duration.Normal}, ${Duration.Short},
      ${Duration.Short};
    transition-timing-function: ${Easing.InOutCurve};

    opacity: 0;

    transform: scale(0.9);

    z-index: -1;
  }

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;

      &:active {
        --list-item-label-color: var(--list-item-pressed-label-color);
        --list-item-bg-color: var(--list-item-pressed-bg-color);

        &:after {
          opacity: 1;
          transform: scale(1);
        }
      }
    `};

  ${({ $selected }) =>
    $selected &&
    css`
      --list-item-label-color: var(--list-item-active-label-color);
      --list-item-bg-color: var(--list-item-active-bg-color);

      &:after {
        opacity: 1;
        transform: scale(1);
      }
    `};

  ${({ $highlighted }) =>
    $highlighted &&
    css`
      --list-item-label-color: var(--list-item-hover-label-color);
      --list-item-bg-color: var(--list-item-hover-bg-color);

      &:after {
        opacity: 1;
        transform: scale(1);
      }
    `};
`;

export const StyledLabel = styled.div`
  ${Typography.Label14.M500};

  flex: 1;

  color: var(--list-item-label-color);

  transition-property: color;
  transition-duration: ${Duration.Short};
  transition-timing-function: ${Easing.InOutCurve};

  white-space: nowrap;

  text-overflow: ellipsis;

  overflow: hidden;
`;
