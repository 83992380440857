import styled, { css } from 'styled-components';

import * as Animation from '@float/ui/deprecated/Earhart/Animation';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

export const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;

    path {
      transition-property: stroke, fill;
      transition-duration: ${Animation.Settings.Duration.Short};
      transition-timing-function: ${Animation.Settings.Easing.InOutCurve};
    }
  }
`;

export const StyledSpinnerContainer = styled.div`
  display: flex;
  align-items: center;

  margin-left: 4px;

  svg {
    width: 12px;
    height: 12px;

    animation: rotate 0.6s linear infinite;

    transition-property: stroke, fill;
    transition-duration: ${Animation.Settings.Duration.Short};
    transition-timing-function: ${Animation.Settings.Easing.InOutCurve};
  }
`;

export const StyledTextButton = styled.button<{
  loader?: { loading: boolean };
  $iconLeft?: boolean;
  $iconRight?: boolean;
  $flayFill: boolean;
  $flueFill: boolean;
  $flayUnderline: boolean;
  $flueUnderline: boolean;
  $disabled?: boolean;
}>`
  ${Typography.Label12.SB600};

  display: inline-flex;
  align-items: center;
  justify-content: center;

  border-radius: 4px;

  text-decoration-color: rgba(0, 0, 0, 0);

  outline: none;

  box-sizing: border-box;

  cursor: ${(p) => (p.loader?.loading ? 'default' : 'pointer')};

  transition-property: background-color, color, text-decoration-color;
  transition-duration: ${Animation.Settings.Duration.Normal};
  transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

  ${({ $iconLeft }) =>
    $iconLeft &&
    css`
      ${StyledIconContainer} {
        margin-right: 3px;
      }
    `};

  ${({ $iconRight }) =>
    $iconRight &&
    css`
      ${StyledIconContainer} {
        margin-left: 3px;
      }
    `};

  ${({ $flayFill, $flueFill, $iconLeft, $iconRight, $disabled }) =>
    ($flayFill || $flueFill) &&
    css`
      color: ${Colors.FIN.Lt.Emphasis.Medium};
      background-color: rgba(0, 0, 0, 0);

      padding: 2px 6px;

      ${StyledIconContainer} {
        svg {
          --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Medium};
        }
      }

      ${StyledSpinnerContainer} {
        svg {
          g {
            stroke: ${Colors.FIN.Lt.Emphasis.Medium};
          }
        }
      }

      // With icons

      ${$iconLeft &&
      css`
        padding-left: 2px;
      `};

      ${$iconRight &&
      css`
        padding-right: 2px;
      `};

      // interaction states

      ${!$disabled &&
      css`
        &:hover,
        &:focus,
        &.active {
          color: ${Colors.FIN.Lt.Emphasis.High};
          background-color: ${Colors.FIN.Lt.Buttons.Secondary.Hover.Bg};

          ${StyledIconContainer} {
            svg {
              --svg-icon-color: ${Colors.FIN.Lt.Emphasis.High};
            }
          }

          ${StyledSpinnerContainer} {
            svg {
              g {
                stroke: ${Colors.FIN.Lt.Emphasis.High};
              }
            }
          }
        }

        &:active,
        &.active {
          background-color: ${Colors.FIN.Lt.Buttons.Secondary.Pressed.Bg};
        }
      `};
    `};

  ${({ $flayUnderline, $flueUnderline, $disabled }) =>
    ($flayUnderline || $flueUnderline) &&
    css`
      color: ${Colors.FIN.Lt.Emphasis.Medium};

      text-decoration: underline;
      text-decoration-color: rgba(0, 0, 0, 0);

      ${StyledIconContainer} {
        svg {
          --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Medium};
        }
      }

      ${StyledSpinnerContainer} {
        svg {
          g {
            stroke: ${Colors.FIN.Lt.Emphasis.Medium};
          }
        }
      }

      // interaction states

      ${!$disabled &&
      css`
        &:hover,
        &:focus {
          text-decoration-color: ${Colors.FIN.Lt.Emphasis.Medium};
        }

        &:active,
        &.active {
          color: ${Colors.FIN.Lt.Emphasis.High};

          text-decoration-color: ${Colors.FIN.Lt.Emphasis.High};

          ${StyledIconContainer} {
            svg {
              --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Medium};
            }
          }

          ${StyledSpinnerContainer} {
            svg {
              g {
                stroke: ${Colors.FIN.Lt.Emphasis.High};
              }
            }
          }
        }
      `};
    `};

  ${({ $flueFill, $disabled }) =>
    $flueFill &&
    css`
      color: ${Colors.FIN.Lt.Emphasis.Primary};

      ${StyledIconContainer} {
        svg {
          --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Primary};
        }
      }

      ${StyledSpinnerContainer} {
        svg {
          g {
            stroke: ${Colors.FIN.Lt.Emphasis.Primary};
          }
        }
      }

      // interaction states

      ${!$disabled &&
      css`
        &:hover {
          color: ${Colors.FIN.Lt.Buttons.Tertiary.Hover.Label};
          background-color: ${Colors.FIN.Lt.Buttons.Tertiary.Hover.Bg};

          ${StyledIconContainer} {
            svg {
              --svg-icon-color: ${Colors.FIN.Lt.Buttons.Tertiary.Hover.Label};
            }
          }

          ${StyledSpinnerContainer} {
            svg {
              g {
                stroke: ${Colors.FIN.Lt.Buttons.Tertiary.Hover.Label};
              }
            }
          }
        }

        &:active,
        &.active {
          color: ${Colors.FIN.Lt.Buttons.Tertiary.Pressed.Label};
          background-color: ${Colors.FIN.Lt.Buttons.Tertiary.Pressed.Bg};

          ${StyledIconContainer} {
            svg {
              --svg-icon-color: ${Colors.FIN.Lt.Buttons.Tertiary.Pressed.Label};
            }
          }

          ${StyledSpinnerContainer} {
            svg {
              g {
                stroke: ${Colors.FIN.Lt.Buttons.Tertiary.Pressed.Label};
              }
            }
          }
        }
      `};
    `};

  ${({ $flueUnderline, $disabled }) =>
    $flueUnderline &&
    css`
      color: ${Colors.FIN.Lt.Emphasis.Primary};

      text-decoration: underline;
      text-decoration-color: rgba(0, 0, 0, 0);

      ${StyledIconContainer} {
        svg {
          --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Primary};
        }
      }

      ${StyledSpinnerContainer} {
        svg {
          g {
            stroke: ${Colors.FIN.Lt.Emphasis.Primary};
          }
        }
      }

      // interaction states

      ${!$disabled &&
      css`
        &:hover {
          text-decoration-color: ${Colors.FIN.Lt.Emphasis.Primary};
        }

        &:active,
        &.active {
          color: ${Colors.FIN.Lt.Buttons.Tertiary.Pressed.Label};

          text-decoration-color: ${Colors.FIN.Lt.Buttons.Tertiary.Pressed
            .Label};

          ${StyledIconContainer} {
            svg {
              --svg-icon-color: ${Colors.FIN.Lt.Buttons.Tertiary.Pressed.Label};
            }
          }

          ${StyledSpinnerContainer} {
            svg {
              g {
                stroke: ${Colors.FIN.Lt.Buttons.Tertiary.Pressed.Label};
              }
            }
          }
        }
      `};
    `};

  ${({ $disabled, $flayUnderline, $flueUnderline, $flayFill, $flueFill }) =>
    $disabled &&
    css`
      cursor: not-allowed;

      ${($flayUnderline || $flueUnderline) &&
      css`
        color: ${Colors.FIN.Lt.Emphasis.Disabled};

        ${StyledIconContainer} {
          svg {
            --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Disabled};
          }
        }

        ${StyledSpinnerContainer} {
          svg {
            g {
              stroke: ${Colors.FIN.Lt.Emphasis.Disabled};
            }
          }
        }
      `};

      ${($flayFill || $flueFill) &&
      css`
        color: ${Colors.FIN.Lt.Emphasis.Disabled};

        ${StyledIconContainer} {
          svg {
            --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Disabled};
          }
        }

        ${StyledSpinnerContainer} {
          svg {
            g {
              stroke: ${Colors.FIN.Lt.Emphasis.Disabled};
            }
          }
        }
      `};
    `};
`;
