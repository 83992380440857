import styled, { css, keyframes } from 'styled-components';

import * as Animation from '@float/ui/deprecated/Earhart/Animation';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import * as Typography from '@float/ui/deprecated/Earhart/Typography';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const effect = '225ms cubic-bezier(0, 0, 0.2, 1)';

export const Container = styled.div`
  ${Typography.Label14.M500};

  position: fixed;
  bottom: ${({ index }) => 40 + (40 * index + 4 * index)}px;
  left: 102px; // 40px + sidenav width + 2px to align with FreeTrialUpgradeWrapper when is visible
  ${({ placement, space }) => {
    return css`
      ${placement}: ${({ index }) => space + (40 * index + 4 * index)}px;
    `;
  }}}

  height: 40px;
  border-radius: 5px;
  background-color: ${Colors.FIN.Lt.Emphasis.High};
  color: #fff;
  padding: 0 ${({ hasRightIcon }) => (hasRightIcon ? 40 : 16)}px 0
    ${({ hasLeftIcon }) => (hasLeftIcon ? 10 : 16)}px;
  display: flex;
  align-items: center;
  z-index: 10006;
  will-change: transform, bottom, background-color;
  transition: transform ${effect} 0ms, bottom ${effect},
    background-color ${effect};
  animation: ${({ closing }) =>
    closing ? fadeOut : fadeIn} ${effect} forwards;

  box-sizing: border-box;

  &,
  & * {
    box-sizing: border-box;
  }

  &.mobile {
    left: 40px;
  }

  a {
    color: ${Colors.Radix.Primary.Flue[7]};
  }

  &.warning {
    background-color: ${(p) => p.theme.darkOrange};
  }

  &.error {
    background-color: ${(p) => p.theme.red};
  }

  &.success {
    background-color: #0bab03;
  }

  &.info {
    background-color: ${Colors.FIN.Lt.Emphasis.Primary};

    a {
      color: ${Colors.FIN.Lt.Emphasis.White};
      text-decoration: underline;

      transition-property: color;
      transition-duration: ${Animation.Settings.Duration.Short};
      transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

      &:hover {
        color: ${Colors.Primary.Flue.Light[4]};
      }
    }
  }
`;

export const IconLeft = styled.div`
  --svg-icon-color: ${Colors.FIN.Lt.Emphasis.White};

  display: flex;

  width: 20px;
  height: 20px;

  margin-right: 8px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const IconRight = styled.div`
  position: absolute;

  top: 8px;
  right: 8px;

  width: 24px;
  height: 24px;

  cursor: pointer;

  transition-property: opacity;
  transition-duration: ${Animation.Settings.Duration.Short};
  transition-timing-function: ${Animation.Settings.Easing.InOutCurve};

  &:hover {
    opacity: 0.5;
  }
`;

export const Link = styled.button`
  margin-left: 16px;
  cursor: pointer;

  &,
  a {
    ${Typography.Label14.M500};
    color: ${Colors.Radix.Primary.Flue[7]};
  }
`;

export const Text = styled.span`
  position: relative;
  white-space: pre;
`;

export const Spinner = styled.div`
  display: inline-block;
  margin-right: 10px;
  margin-left: -10px;
  vertical-align: -6px;
  height: 24px;
  svg {
    animation: rotate 0.6s linear infinite;
    width: 24px;
    height: 24px;
  }
`;
