import { InsightsEntry } from '../types';

export function createInsightsEntry(): InsightsEntry {
  return {
    capacity: 0,
    h: 0,
    logged: 0,
    overtime: 0,
    totalCapacity: 0,
  };
}
