import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getPhasesByProjectId } from '@float/web/selectors';
import { PanelType } from '@float/web/sidePanel/types';
import { useSidePanel } from '@float/web/sidePanel/useSidePanel';

import { PhaseEditData, ProjectFormData } from '../types';

export function usePhaseNavigation(projectId: ProjectFormData['projectId']) {
  const { addPanel, panelPayload } = useSidePanel<PhaseEditData>(
    PanelType.PhasePanel,
  );
  const { phaseId } = panelPayload;
  const phases = useSelector((state) => getPhasesByProjectId(state, projectId));
  const index = phases.findIndex((phase) => phase.phase_id === phaseId);
  const onClickNextPhase = useCallback(
    (e: React.MouseEvent) => {
      if (index === phases.length - 1) return;
      // Leaving this commented in since we're undecided on the product choice
      // const nextPhaseId = index === phases.length - 1 ? phases[0].phase_id : phases[index + 1].phase_id;
      const nextPhaseId = phases[index + 1].phase_id;
      addPanel({
        panelType: PanelType.PhasePanel,
        panelPayload: {
          phaseId: nextPhaseId,
          projectId: projectId!,
        },
      });
    },
    [addPanel, index, phases, projectId],
  );

  const onClickPrevPhase = useCallback(
    (e: React.MouseEvent) => {
      if (index === 0) return;
      // Leaving this commented in since we're undecided on the product choice
      // const nextPhaseId = index === 0 ? phases[phases.length - 1].phase_id : phases[index - 1].phase_id;
      const prevPhaseId = phases[index - 1].phase_id;
      addPanel({
        panelType: PanelType.PhasePanel,
        panelPayload: {
          phaseId: prevPhaseId,
          projectId: projectId!,
        },
      });
    },
    [addPanel, index, phases, projectId],
  );
  return {
    index: index + 1,
    phaseId,
    phases,
    onClickNextPhase,
    onClickPrevPhase,
  };
}
