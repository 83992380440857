import { clamp as clampDate, endOfDay, format, startOfDay } from 'date-fns';
import { clamp } from 'lodash';

import { formatClockTimeAsMs } from '@float/common/lib/time';
import { formatAsDuration } from '@float/common/lib/timer/formatAsDuration';
import { formatMsAsClockTime } from '@float/common/lib/timer/formatMsAsClockTime';
import { getTimeFormatPattern } from '@float/common/lib/timer/getTimeFormatPattern';
import { normalizeClockTime } from '@float/common/lib/timer/normalizeClockTime';
import { TimeFormat } from '@float/types';

import { InputTimeType } from '../types';

function clampTime(
  value: string,
  timeType: InputTimeType,
  timeFormat: TimeFormat,
  min: string | undefined,
  max: string | undefined,
) {
  const time = normalizeClockTime(value);

  let newTime;

  if (time) {
    if (timeType === 'clock') {
      const pattern = getTimeFormatPattern(timeFormat);

      let minTime = min && normalizeClockTime(min);
      let maxTime = max && normalizeClockTime(max);

      if (!minTime) minTime = startOfDay(time);
      if (!maxTime) maxTime = endOfDay(time);

      newTime = clampDate(time, {
        start: minTime,
        end: maxTime,
      });

      newTime = format(newTime, pattern);
    } else if (timeType === 'duration') {
      const time = formatAsDuration(value, timeFormat);
      const timeInMs = formatClockTimeAsMs(time);

      const maxTimeFormatHours = parseInt(timeFormat, 10);
      const maxTimeFormatHoursInMs = maxTimeFormatHours * 60 * 60 * 1000;

      const minTimeInMs = min ? formatClockTimeAsMs(min) : 0;
      const maxTimeInMs = max
        ? formatClockTimeAsMs(max)
        : maxTimeFormatHoursInMs;

      newTime = clamp(timeInMs, minTimeInMs, maxTimeInMs);

      newTime = formatMsAsClockTime(newTime);
    }
  }

  return newTime || value;
}

export { clampTime };
