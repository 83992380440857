import { useFormContext } from 'react-hook-form';

import { ProjectFormData } from '../types';
import { useGetPersonDefaultRate } from './useGetPersonDefaultRate';

export function useSetPersonDefaultHourlyRate() {
  const { setValue, register, getValues } = useFormContext<ProjectFormData>();
  const getPersonDefaultRate = useGetPersonDefaultRate();

  const setPersonDefaultHourlyRate = (index: number, value: number) => {
    const key = `team.${index}.hourly_rate` as const;
    const hourlyRate = getValues(key);

    if (hourlyRate === null || hourlyRate === undefined || hourlyRate === '') {
      // setValue doesn't work on unregistered fields
      // and hourly rate many not be mounted when setting this value
      register(key);
      setValue(key, getPersonDefaultRate(value), { shouldDirty: true });
    }
  };

  return { setPersonDefaultHourlyRate };
}
