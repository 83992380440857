import React from 'react';
import { t, Trans } from '@lingui/macro';

import { getPeopleMap } from '@float/common/selectors/people';
import { getTimeoffTypesMap } from '@float/common/selectors/timeoffTypes';
import { useAppSelector } from '@float/common/store';
import { CurrentUser, Status, Task, Timeoff } from '@float/types';
import { Button } from '@float/ui/deprecated';
import {
  DropdownActions,
  pushToRight,
} from '@float/ui/deprecated/DropdownActions';
import { IconArrowDown, IconTrash } from '@float/ui/deprecated/Earhart/Icons';

import { canDeleteTimeoff } from './acl/canDeleteTimeoff';

export type TaskActionsMenuProps = {
  entity: Task | Timeoff | Status;
  isTimeoff: boolean;
  currentUser: CurrentUser;
  onCopyURL: (url: string) => void;
  onDelete: () => void;
};

const buildLink = (type: string, id: string | number) =>
  `${window.location.href}#${type.toLowerCase().replace(' ', '')}-${id}`;

const getItemLink = (entity: Task | Timeoff | Status) => {
  if ('task_id' in entity && entity.task_id) {
    return {
      itemType: t`allocation`,
      href: buildLink('Task', entity.task_id),
    };
  } else if ('timeoff_id' in entity && entity.timeoff_id) {
    return {
      itemType: t`time off`,
      href: buildLink('Time off', entity.timeoff_id),
    };
  } else if ('status_id' in entity && entity.status_id) {
    return {
      itemType: t`status`,
      href: buildLink('Status', entity.status_id),
    };
  }

  return null;
};

export function TaskActionsMenu(props: TaskActionsMenuProps) {
  const { currentUser, entity, isTimeoff } = props;

  const link = getItemLink(entity);

  const people = useAppSelector(getPeopleMap);
  const timeoffTypes = useAppSelector(getTimeoffTypesMap);

  const canDelete = isTimeoff
    ? canDeleteTimeoff(currentUser, entity as Timeoff, {
        people,
        timeoffTypes,
      })
    : true;

  if (!canDelete && !link) return null;

  const itemType = link?.itemType.toLowerCase();

  return (
    <DropdownActions
      side="top"
      align="end"
      className={pushToRight}
      content={
        <>
          {link && (
            <DropdownActions.Item onClick={() => props.onCopyURL(link.href)}>
              <Trans>Copy {itemType} URL</Trans>
            </DropdownActions.Item>
          )}
          {canDelete && (
            <DropdownActions.Item
              appearance="danger"
              onClick={() => props.onDelete()}
            >
              <Trans>Delete</Trans> <IconTrash />
            </DropdownActions.Item>
          )}
        </>
      }
    >
      <Button appearance="clear" iconRight={IconArrowDown}>
        <Trans>Actions</Trans>
      </Button>
    </DropdownActions>
  );
}
