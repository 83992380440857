import React, { ReactNode } from 'react';
import {
  booleanLabels,
  noInfoKeys,
  removedWithValues,
} from '@floatschedule/activity-format-npm';
import { isArray, isEmpty, isString } from 'lodash';

import PersonAvatar from '@float/common/components/elements/PersonAvatar';
import { capitalize } from '@float/libs/utils/string/capitalize';
import { GenericActivity } from '@float/types';
import EH from '@float/ui/deprecated/Earhart';
import { IconBuilding } from '@float/ui/icons/essentials/IconBuilding';
import { IconTasks } from '@float/ui/icons/essentials/IconTasks';

import ActivityLink from '../ActivityLink';
import {
  StyledActivityAvatarGroup,
  StyledCalendarIcon,
  StyledIcon,
  StyledPersonAvatar,
} from '../styles';
import { ItemClickCallback } from './types';

function asTextElement(
  text?: string | null | { name: string },
): ActivityElement {
  if (typeof text === 'object' && text !== null) {
    return { type: 'string' as const, text: text.name };
  }

  return { type: 'string' as const, text };
}

function asSeparatorElement(text?: string | null): ActivityElement {
  return { type: 'separator' as const, text };
}

function asJsxElement(
  text?: string | null,
  element?: ReactNode,
): ActivityElement {
  return { type: 'jsx' as const, text, element };
}

type ActivityElement = {
  type: 'string' | 'separator' | 'jsx';
  text: string | null | undefined;
  element?: ReactNode;
};

type IsClickableActivityFn = (
  item: Pick<GenericActivity, 'item_id' | 'item_type'> &
    Partial<Pick<GenericActivity, 'activity_type'>>,
) => boolean;

const asInlineAvatar = (
  item: Pick<GenericActivity, 'item_id' | 'item_type'> &
    Partial<Pick<GenericActivity, 'activity_type'>>,
  personId: number,
  onItemClick: ItemClickCallback,
  getIsClickableActivity: IsClickableActivityFn,
) => {
  const onClick = getIsClickableActivity(item) ? () => onItemClick(item) : null;

  if (onClick) {
    return asJsxElement(
      '',
      <StyledPersonAvatar onClick={onClick}>
        <PersonAvatar personId={personId} size="xs" readOnly />
      </StyledPersonAvatar>,
    );
  }

  // use Person icon for people that was removed
  let Icon: React.FC = EH.Icons.IconUser;
  if (item.activity_type === 'create') Icon = EH.Icons.IconUserPlus;
  if (item.activity_type === 'delete') Icon = EH.Icons.IconUserMinus;

  return asJsxElement(
    '',
    <StyledIcon>
      <Icon />
    </StyledIcon>,
  );
};

function processLink(
  text: string,
  item: Pick<GenericActivity, 'item_id' | 'item_type'>,
  onItemClick: ItemClickCallback,
  getIsClickableActivity: IsClickableActivityFn,
) {
  // we need to ensure text won't break UI if it's too long
  // so, if text is not clickable, we still need to apply overflow-wrap on it
  return asJsxElement(
    text,
    getIsClickableActivity(item) ? (
      <ActivityLink item={item} onItemClick={onItemClick}>
        {text}
      </ActivityLink>
    ) : (
      <span style={{ overflowWrap: 'anywhere' }}>{text}</span>
    ),
  );
}

function getBulkUpdateSuffix(
  activityType: GenericActivity['activity_type'],
  fieldKey: string,
) {
  let suffix;
  if (
    activityType === 'bulk_update' &&
    !['added_', 'tentative', 'non_billable', 'common'].some((x) =>
      fieldKey.startsWith(x),
    )
  ) {
    suffix = 'updated:';
  } else if (activityType === 'bulk_delete') {
    suffix = 'deleted:';
  }
  return suffix;
}

const customArrayDisplayHistory = {
  project_names: {
    namesKey: 'project_names_array',
    idsKey: 'project_ids',
    type: 'project',
  },
  people_names: {
    namesKey: 'people_names_array',
    idsKey: 'people_ids',
    type: 'people',
  },
  assignee_added: {
    namesKey: 'assignee_added_array',
    idsKey: 'assignee_added_ids',
    type: 'people',
  },
  assignee_removed: {
    namesKey: 'assignee_removed_array',
    idsKey: 'assignee_removed_ids',
    type: 'people',
  },
} as const;

type CustomArrayDisplayKey = keyof typeof customArrayDisplayHistory;
type RefData = Record<string, any>;

const isCustomArrayDisplayKey = (key: string): key is CustomArrayDisplayKey =>
  Object.keys(customArrayDisplayHistory).includes(key);

function isCustomArrayDisplay(
  fieldKey: string,
  refData: RefData,
): fieldKey is CustomArrayDisplayKey {
  return (
    isCustomArrayDisplayKey(fieldKey) &&
    refData[customArrayDisplayHistory[fieldKey].idsKey] &&
    refData[customArrayDisplayHistory[fieldKey].idsKey].length &&
    refData[customArrayDisplayHistory[fieldKey].namesKey]
  );
}

function createCustomArrayDisplayElements(
  fieldKey: CustomArrayDisplayKey,
  refData: RefData,
  onItemClick: ItemClickCallback,
  getIsClickableActivity: IsClickableActivityFn,
) {
  const { idsKey, namesKey, type } = customArrayDisplayHistory[fieldKey];
  const result = [];

  // create avatar group before list of people
  if (type === 'people') {
    // remove deleted people from the list to avoid this:
    // https://app.asana.com/0/1201258914201276/1201415790780685/f
    const filterActivePeople = refData[idsKey].filter((id: number) => {
      const item = { item_type: type, item_id: id };
      return getIsClickableActivity(item);
    });

    const people = filterActivePeople.map((id: number) => {
      const item = { item_type: type, item_id: id } as const;

      return {
        personId: id as number,
        onClick: () => onItemClick(item),
      };
    });

    result.push(
      asJsxElement(
        null,
        <StyledActivityAvatarGroup size="xs" people={people} max={3} />,
      ),
    );
  }

  refData[idsKey].forEach((id: number, idx: number) => {
    const item = { item_type: type, item_id: id };

    // do not render undefined people to avoid this (e.g. removed people):
    // https://app.asana.com/0/1201258914201276/1201415790780685/f
    if (refData[namesKey][idx]) {
      result.push(
        processLink(
          refData[namesKey][idx] as string,
          item,
          onItemClick,
          getIsClickableActivity,
        ),
        asSeparatorElement(','),
      );
    }
  });

  result.pop();

  return result;
}

const customSingleDisplayHistory = {
  project_manager: {
    nameKey: 'project_manager',
    idKey: 'creator_id',
    type: 'account',
  },
} as const;

type CustomSingleDisplayKey = keyof typeof customSingleDisplayHistory;

const isCustomSingleDisplayKey = (key: string): key is CustomSingleDisplayKey =>
  Object.keys(customSingleDisplayHistory).includes(key);

function isCustomSingleDisplay(
  fieldKey: string,
  refData: RefData,
): fieldKey is CustomSingleDisplayKey {
  return (
    isCustomSingleDisplayKey(fieldKey) &&
    refData[customSingleDisplayHistory[fieldKey].idKey] &&
    refData[customSingleDisplayHistory[fieldKey].nameKey]
  );
}

function createCustomSingleDisplayElement(
  fieldKey: 'project_manager',
  refData: RefData,
  onItemClick: ItemClickCallback,
  getIsClickableActivity: IsClickableActivityFn,
) {
  const { idKey, nameKey, type } = customSingleDisplayHistory[fieldKey];
  const item = { item_type: type, item_id: refData[idKey] as number } as const;
  return processLink(
    refData[nameKey],
    item,
    onItemClick,
    getIsClickableActivity,
  );
}

type DataLayerItem = {
  fieldKey: string;
  nextPrevData?: {
    nextPrettier?: string | { name: string };
    prevPrettier?: string | { name: string };
  };
  prevPeopleName?: string;
  nextPeopleName?: string;
  template?: string;
  prettierKey: string;
  activityType: GenericActivity['activity_type'];
  prettyPhrase?: string;
};

function formatDisplayHistory(
  dataLayer: DataLayerItem[],
  _beforeData: GenericActivity['before_data'],
  _afterData: GenericActivity['after_data'],
  onItemClick: ItemClickCallback,
  getIsClickableActivity: IsClickableActivityFn,
) {
  const beforeData = _beforeData || {};
  const afterData = _afterData || {};
  const result = dataLayer.reduce<ActivityElement[]>((acc, item) => {
    const {
      fieldKey,
      nextPrevData,
      prevPeopleName,
      nextPeopleName,
      template,
      prettierKey,
      activityType,
      prettyPhrase,
    } = item;

    const { nextPrettier, prevPrettier } = nextPrevData || {};
    if (fieldKey === 'people_name') return acc;

    const innerContent = [];
    switch (template) {
      case 'personUpdate': {
        innerContent.push(
          prevPeopleName
            ? asTextElement(`from ${prevPeopleName} to ${nextPeopleName}`)
            : asTextElement(`to ${nextPeopleName}`),
        );
        break;
      }
      case 'usePrettyPhrase': {
        innerContent.push(asTextElement(prettyPhrase));
        break;
      }
      case 'fromTo': {
        if (noInfoKeys.includes(fieldKey)) {
          innerContent.push(asTextElement('updated'));
        } else {
          const fromElement = isCustomSingleDisplay(fieldKey, beforeData)
            ? createCustomSingleDisplayElement(
                fieldKey,
                beforeData,
                onItemClick,
                getIsClickableActivity,
              )
            : asTextElement(prevPrettier);
          const toElement = isCustomSingleDisplay(fieldKey, afterData)
            ? createCustomSingleDisplayElement(
                fieldKey,
                afterData,
                onItemClick,
                getIsClickableActivity,
              )
            : asTextElement(nextPrettier);
          innerContent.push(
            asTextElement('from'),
            fromElement,
            asTextElement('to'),
            toElement,
          );
        }
        break;
      }
      case 'adding': {
        let suffix = getBulkUpdateSuffix(activityType, fieldKey);
        if (!suffix) {
          suffix = booleanLabels[fieldKey]
            ? booleanLabels[fieldKey][0]
            : 'added';
        }
        innerContent.push(asTextElement(suffix));
        if (!noInfoKeys.includes(fieldKey)) {
          if (isCustomArrayDisplay(fieldKey, afterData)) {
            innerContent.push(
              ...createCustomArrayDisplayElements(
                fieldKey,
                afterData,
                onItemClick,
                getIsClickableActivity,
              ),
            );
          } else if (isCustomSingleDisplay(fieldKey, afterData)) {
            innerContent.push(
              createCustomSingleDisplayElement(
                fieldKey,
                afterData,
                onItemClick,
                getIsClickableActivity,
              ),
            );
          } else if (isString(nextPrettier) && !isEmpty(nextPrettier)) {
            innerContent.push(asTextElement(nextPrettier));
          }
        }
        break;
      }
      case 'removing': {
        const prefix = booleanLabels[fieldKey]
          ? booleanLabels[fieldKey][1]
          : 'removed';
        innerContent.push(asTextElement(prefix));
        if (!booleanLabels[fieldKey] && removedWithValues.includes(fieldKey)) {
          const prevPrettierName =
            typeof prevPrettier === 'object' ? prevPrettier.name : prevPrettier;
          innerContent.push(asTextElement(prevPrettierName));
        }
        break;
      }
    }

    if (innerContent.length) {
      if (prettierKey) acc.push(asTextElement(prettierKey));
      acc.push(...innerContent, asSeparatorElement(','));
    }
    return acc;
  }, []);
  // remove last separator
  result.pop();
  return result;
}

function createActionTargetElements(
  actionTarget: string,
  item: GenericActivity,
  onItemClick: ItemClickCallback,
  getIsClickableActivity: IsClickableActivityFn,
) {
  // check if actionTarget matches people name.
  // In this case, we can generate links for each person
  if (actionTarget && item.people_name === actionTarget) {
    // parse ids and names, as `people_id` is not always an array (eg, status)
    const peopleIds = isArray(item.people_id)
      ? (item.people_id as number[])
      : [item.people_id as number];
    const peopleNames = isArray(item.people_name_array)
      ? item.people_name_array
      : [item.people_name];

    // only generate links if ids and names are consistents (same length)
    if (peopleIds.length === peopleNames.length) {
      return peopleIds.reduce<ActivityElement[]>((acc, id, idx) => {
        if (idx > 0) acc.push(asSeparatorElement(','));

        const peopleItem = { item_id: id, item_type: 'people' } as const;

        acc.push(
          asInlineAvatar(peopleItem, id, onItemClick, getIsClickableActivity),
        );

        acc.push(
          processLink(
            peopleNames[idx],
            peopleItem,
            onItemClick,
            getIsClickableActivity,
          ),
        );
        return acc;
      }, []);
    }
  }

  // actionTarget can't be used to generate links, so just expose as text
  return [asTextElement(actionTarget)];
}

function toRow(elements: ActivityElement[]) {
  const compactedElements = elements
    // normalize - temporary
    .map((item) => (isString(item) ? asTextElement(item) : item))
    // merge strings in one element
    .reduce<ActivityElement[]>((acc, item) => {
      if (!acc.length) {
        acc.push(item);
        return acc;
      }
      const last = acc[acc.length - 1];
      if (last.type === 'jsx') {
        // if item is separator, we just change it to text type
        if (item.type === 'separator') item.type = 'string';
        else if (item.type === 'string') item.text = ` ${item.text}`;
        acc.push(item);

        // last type is string (we ensure no separators are added to `acc`), so let's check item
      } else if (item.type === 'jsx') {
        // just add a whitespace in last
        last.text = `${last.text} `;
        acc.push(item);
      } else if (item.type === 'separator') {
        // just append item without whitespace
        last.text = `${last.text}${item.text}`;
      } else if (item.type === 'string') {
        // merge two items in one as both are strings
        last.text = `${last.text} ${item.text}`;
      }
      return acc;
    }, []);
  return (
    <>
      {compactedElements.map((elem, i) => (
        <span key={i}>{elem.element || elem.text}</span>
      ))}
    </>
  );
}

export function formatGenericActivityText(
  item: GenericActivity,
  onItemClick: ItemClickCallback,
  getIsClickableActivity: IsClickableActivityFn,
) {
  const {
    baseTitleArgs,
    item_type: itemType,
    before_data: beforeData,
    after_data: afterData,
    batch_id: batchId,
    history,
    activity_type: activityType,
  } = item;

  const {
    actionLabel,
    actionLink,
    actionTarget,
    withFor,
    dates,
    actionFrom,
    postFix,
  } = baseTitleArgs!;

  const parts = [asTextElement(capitalize(actionLabel))];
  const actionTargetElems = createActionTargetElements(
    actionTarget,
    item,
    onItemClick,
    getIsClickableActivity,
  );
  if (actionLink) {
    const actionLinkElem = [
      processLink(actionLink, item, onItemClick, getIsClickableActivity),
    ];

    if (itemType === 'people') {
      if (
        afterData &&
        afterData.people_id &&
        typeof afterData.people_id === 'number'
      ) {
        actionLinkElem.unshift(
          asInlineAvatar(
            item,
            afterData.people_id,
            onItemClick,
            getIsClickableActivity,
          ),
        );
      } else {
        let Icon: React.FC = EH.Icons.IconUser;
        if (activityType === 'create') Icon = EH.Icons.IconUserPlus;
        if (activityType === 'delete') Icon = EH.Icons.IconUserMinus;

        actionLinkElem.unshift(
          asJsxElement(
            '',
            <StyledIcon>
              <Icon />
            </StyledIcon>,
          ),
        );
      }
    }

    if (
      itemType === 'project' ||
      itemType === 'phase' ||
      itemType === 'milestones'
    ) {
      let Icon: React.FC = EH.Icons.IconFolder;
      if (activityType === 'create') Icon = EH.Icons.IconFolderPlus;
      if (activityType === 'delete') Icon = EH.Icons.IconFolderMinus;

      actionLinkElem.unshift(
        asJsxElement(
          '',
          <StyledIcon>
            <Icon />
          </StyledIcon>,
        ),
      );
    }

    if (itemType === 'client') {
      let Icon: React.FC = EH.Icons.IconInstitution;
      if (activityType === 'create') Icon = EH.Icons.IconInstitutionPlus;
      if (activityType === 'delete') Icon = EH.Icons.IconInstitutionMinus;

      actionLinkElem.unshift(
        asJsxElement(
          '',
          <StyledIcon>
            <Icon />
          </StyledIcon>,
        ),
      );
    }

    if (itemType === 'department') {
      let Icon: React.FC = IconBuilding;
      if (activityType === 'create') Icon = EH.Icons.IconBuildingPlus;
      if (activityType === 'delete') Icon = EH.Icons.IconBuildingMinus;

      actionLinkElem.unshift(
        asJsxElement(
          '',
          <StyledIcon>
            <Icon />
          </StyledIcon>,
        ),
      );
    }

    if (itemType === 'person') {
      let Icon: React.FC = EH.Icons.IconUser;
      if (activityType === 'create') Icon = EH.Icons.IconUserPlus;
      if (activityType === 'delete') Icon = EH.Icons.IconUserMinus;

      actionLinkElem.unshift(
        asJsxElement(
          '',
          <StyledIcon>
            <Icon />
          </StyledIcon>,
        ),
      );
    }

    if (itemType === 'task') {
      let Icon: React.FC = IconTasks;
      if (activityType === 'create') Icon = EH.Icons.IconTasksPlus;
      if (activityType === 'delete') Icon = EH.Icons.IconTasksMinus;

      actionLinkElem.unshift(
        asJsxElement(
          '',
          <StyledIcon>
            <Icon />
          </StyledIcon>,
        ),
      );
    }

    if (withFor) {
      parts.push(
        ...actionTargetElems,
        asTextElement(withFor),
        ...actionLinkElem,
      );
    } else {
      parts.push(
        ...actionTargetElems,
        asSeparatorElement(':'),
        ...actionLinkElem,
      );
    }
  } else {
    parts.push(...actionTargetElems);
  }
  if (dates) {
    const text = `${dates.length > 1 ? 'from' : 'on'} ${dates.join(' - ')}`;
    const _isClickable = getIsClickableActivity(item);

    let dateElement = (
      <StyledCalendarIcon>
        {dates.length > 1 ? 'from' : 'on'}
        <EH.Icons.IconCalendar />
        {dates.join(' - ')}
      </StyledCalendarIcon>
    );

    if (_isClickable) {
      dateElement = (
        <ActivityLink item={item} onItemClick={onItemClick}>
          {dateElement}
        </ActivityLink>
      );
    }

    parts.push(asJsxElement(text, dateElement));
  }
  if (actionFrom) {
    parts.push(asTextElement(`from ${actionFrom}`));
  }
  if (postFix) {
    parts.push(asTextElement(postFix));
  }

  // add batch information
  if (batchId && history && history.length) {
    parts.push(asSeparatorElement(':'));
    (history as GenericActivity[]).forEach((histElem) => {
      const { baseTitleArgs } = histElem;

      if (baseTitleArgs) {
        parts.push(
          processLink(
            baseTitleArgs.actionLink,
            histElem,
            onItemClick,
            getIsClickableActivity,
          ),
          asSeparatorElement(','),
        );
      }
    });
    // remove last separator
    parts.pop();
  }

  // add bulk_delete information
  // cleaner than using `formatDisplayHistory` function
  if (activityType === 'bulk_delete') {
    const { item_type: type, after_data: data } = item;
    if (data) {
      const text = data[`${type}_names`];
      if (text) {
        parts.push(asSeparatorElement(':'), asTextElement(text));
      }
    }
  }

  // add information to update events
  if (activityType.endsWith('update')) {
    const { displayHistory } = item;
    if (displayHistory && displayHistory.length) {
      const details = formatDisplayHistory(
        displayHistory[0],
        beforeData,
        afterData,
        onItemClick,
        getIsClickableActivity,
      );
      if (details && details.length) {
        parts.push(asTextElement(' - '), ...details);
      }
    }
  }

  return {
    text: parts.map((item) => item.text).join(' '),
    element: toRow(parts),
  };
}
