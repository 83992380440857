import API3 from '@float/common/api3';
import { AppDispatchStrict } from '@float/common/store';
import { logger } from '@float/libs/logger';
import { UserCompany } from '@float/types/account';

import { FETCH_COMPANIES_SUCCESS } from './constants';

export type FetchCompaniesAction = {
  type: typeof FETCH_COMPANIES_SUCCESS;
  companies: UserCompany[];
};

export const fetchCompanies = () => {
  return async (dispatch: AppDispatchStrict) => {
    try {
      const companies: UserCompany[] = await API3.getMyCompanies();

      dispatch({
        type: FETCH_COMPANIES_SUCCESS,
        companies,
      });
    } catch (e) {
      logger.error('Failed to fetch companies', e);
    }
  };
};
