import React, { useId } from 'react';
import { Controller } from 'react-hook-form';

import { ToggleButton, ToggleGroup } from '@float/ui/deprecated/Toggle';

import { ProjectFormData } from '../../types';
import { FormField } from '../FormFieldsSection';

export type ToggleFields =
  | 'project.budget_type'
  | 'project.budget_priority'
  | 'project.non_billable'
  | 'phase.non_billable';

type ToggleValue = {
  'project.budget_type': ProjectFormData['project']['budget_type'];
  'project.budget_priority': ProjectFormData['project']['budget_priority'];
  'project.non_billable': ProjectFormData['project']['non_billable'];
  'phase.non_billable': ProjectFormData['project']['non_billable'];
};

export type TextToggleFieldProps<N extends ToggleFields> = {
  name: N;
  label: string;
  options: {
    label: string;
    value: Exclude<ToggleValue[N], undefined>;
    disabled?: boolean;
  }[];
  onChange?: (value: Exclude<ToggleValue[N], undefined>) => void;
};

export function ToggleField<N extends ToggleFields>(
  props: TextToggleFieldProps<N>,
) {
  const id = useId();

  return (
    <FormField label={props.label} htmlFor={id}>
      <Controller
        name={props.name}
        render={({ field }) => (
          <ToggleGroup<Exclude<ToggleValue[N], undefined>>
            type="single"
            size="tiny"
            value={field.value}
            disableUnselect
            aria-labelledby={id}
            onChange={(value) => {
              if (value !== undefined) {
                field.onChange(value);
                props.onChange?.(value);
              }
            }}
          >
            {props.options.map((option, i) => (
              <ToggleButton
                key={i}
                value={option.value}
                disabled={option.disabled}
              >
                {option.label}
              </ToggleButton>
            ))}
          </ToggleGroup>
        )}
      />
    </FormField>
  );
}
